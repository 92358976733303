<div class="BGoverlay" attr.automationId='Btn_{{automationId}}_Close' (click)="closeWindow()"></div>

<section class="more-dialog-container" #moreSection [style.top.px]="top" [style.left.px]="left">

  <section mat-dialog-title class="more-header dialog-container__header">
    <div [formGroup]="searchForm">
      <div class="header-wrapper">   
        <mat-form-field class=" search-box" floatLabel="never">        
          <input attr.automationId='Txt_{{automationId}}_searchText' type="text" [placeholder]="searchHeaderOption.searchPlaceHolder" matInput formControlName="searchText"
            (input)="searchValueChange()" autocomplete="off">
          <i  aria-hidden="true" [ngClass]="hasValue ? 'icon-Cancel': 'icon-search'" (click)="hasValue && clearText()"></i>
        </mat-form-field>
      </div>
    </div>  
    <section class="more-alphabets-wrapper">
      <section class="more-alphabets">
        <swiper >
          <span attr.automationId='Btn_{{automationId}}_more{{i}}' class="charSpan" *ngFor="let char of charArr;let i = index" (click)="charClicked(char,i)"
            [ngClass]="[disableFirstLetter[i] ? '':'disableChar',changeCharBg == i ? 'add-bgcolor':'']">
            {{char}}
          </span>
        </swiper>
      </section>
    </section>
    <span attr.automationId='Btn_{{automationId}}_close' class="dialog-container__header--close icon-Cancel" (click)="close()"></span>
  </section>

  <section class="more-container">
    <golfcustom-checkbox [automationId]="automationId" *ngFor="let filteredData of filteredDataArr;let i = index"
      [customCBxDisabled]="filteredData.moreCustomCBxDisabled" [customCBxChecked]="filteredData.moreCustomCBxChecked"
      [customCBxId]="filteredData.moreCustomCBxId" [customCBxwrapperClass]="filteredData.moreCustomCBxwrapperClass"
      [customCBxlabelClass]="filteredData.moreCustomCBxlabelClass"
      [customCBxInputClass]="filteredData.moreCustomCBxInputClass"
      [customCBxlabelText]="filteredData.moreCustomCBxlabelText"
      (changeEvent)="filteredCheckboxClicked(i,filteredData,$event)" class="golfcustom-checkbox"></golfcustom-checkbox>
  </section>

  <section class="more-footer">
      <mat-dialog-actions class="dialog-container__actions">
          <div>
            <button attr.automationId='Btn_{{automationId}}_save' mat-button mat-flat-button color="primary" [disabled]="isDisabled" class="actions__save"
              (click)="getApplyEmitvalue($event)">{{saveButtonText}}</button>
            <button attr.automationId='Btn_{{automationId}}_cancel' mat-button class="actions__cancel" (click)="getCancelEmitvalue($event)">{{cancelButtonText}}</button>
          </div>
        </mat-dialog-actions>  
  </section>
</section>