import { Component,  Input, ViewEncapsulation } from '@angular/core';
import { TeeSlotInfo } from '../../shared-models';
import { PlayerCategory } from 'src/app/common/enums/shared-enums';
import { BookingSource, TeeTimeFormat } from '../../models/teesheet.form.models';

@Component({
  selector: 'app-tee-slot-info',
  templateUrl: './tee-slot-info.component.html',
  styleUrls: ['./tee-slot-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TeeSlotInfoComponent   {
  @Input() info: TeeSlotInfo;
  @Input() customTableData;
  playerCategory = PlayerCategory;
  teeTimeFormat = TeeTimeFormat;
  bookingSource = BookingSource;
}
