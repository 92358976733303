import { TeeSheetSkeletonData } from 'src/app/shared/models/teesheet.form.models';
import { PlayerStayDetail, OverrideDetails, PlayerAdditionalDetails, ScheduledTeeTimePlayerFeeDynamicPricing } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { ContactDetails, API } from 'src/app/retail/shared/shared.modal';
import { CaddyType } from 'src/app/shared/models/teesheet.api.models';

export interface BulkTeeTimeConfig {
    courseId: number;
    scheduleTeeTime: Date | string;
    allocationBlockId: number;
    playerTypeId: number;
    rateTypeId: number;
    playersPerGroup: number;
    contactInfo: ScheduledTeeTimePlayerProfile;
    playerTeeTimeSlots: PlayerTeeTimeSlot[];
    paymentReferenceId: number;
    playerStayDetail: PlayerStayDetail;
    confirmationNumber: string;
    bookingId: string;
    caddyTypeId: number;
    teeTimeComment: string;
    startHole: string;
    cartType: string;
    cartId: string;
    noofHoles: number;
    greenFee: number | string;
    cartFee: number | string;
    negotiateGreenFee: boolean;
    negotiatedGreenFee: number;
    negotiateCartFee: boolean;
    negotiatedCartFee: number;
    isDynamicPrice?: boolean;
    dynamicPricingId?:number;
    scheduledTeeTimePlayerFeeDynamicPricing? : ScheduledTeeTimePlayerFeeDynamicPricing;
    applyCustomFee?: boolean;
}

export interface ScheduledTeeTimePlayerProfile {
    firstName: string;
    lastName: string;
    pronounced: string;
    phoneContactType: ContactType;
    phoneNumber: string;
    emailContactType: ContactType;
    emailAddress: string;
    customField1: string;
    customField2: string;
    customField3: string;
    customField4: string;
    customField5: string;
    playerInfoSource: PlayerInformationSource;
    playerLinkId: string;
    imageReferenceId: string;
    bagNumber?: string;
    contactInformation: ContactDetails[];
    playerAddress: API.PlayerAddress;
    extProfileId?: string;
    platformUuId?: string;
    vipType: string;
}

export enum ContactType {
    None = 0,
    Home = 1,
    Mobile = 2,
    Office = 3
}

export enum PlayerInformationSource {
    None = 0,
    Newplayer = 1,
    Member = 2,
    PMS = 3,
    Acounting = 4
}

export interface TimeWithActualSkeletonData {
    time: string;
    actualData: TeeSheetSkeletonData;
}

export interface PlayerInfo {
    allocationCode: AllocationCode;
    course: Course;
    customField1: string;
    customField2: string;
    customField3: string;
    customField4: string;
    customField5: string;
    date: Date | string;
    device: string;
    firstName: string;
    lastName: string;
    playerType: PlayerType;
    players: number;
    playersPerGroup: number;
    rateType: RateType;
    time: string;
    timewithData: TimeWithActualSkeletonData;
    typeOfPlayer: number;
    hole: string;
    paymentReferenceId: number;
    playerLinkId: string;
    playerStayDetail: PlayerStayDetail;
    imageReferenceId: string;
    bagNumber?: string;
    contactinfo: ContactDetails[];
    confirmationNumber: string;
    bookingId: string;
    caddyType: CaddyType;
    teeTimeComment: string;
    startHole: string;
    originalHoleNumber: string;
    playerTypeDaysOutOverrideduserID: number;
    isPlayerTypeDaysOutOverrided: boolean;
    rateTypeDaysOutOverrideduserID: number;
    isRateTypeDaysOutOverrided: boolean;
    cartType?: string;
    cartNumber?: string;
    address?: AddressDetail[];
    state?: string;
    city?: string;
    country?: string;
    countryCode?: string;
    postal_code?: string;
    isUpdatePlayerProfile?: boolean;
    phoneNumber?: string;
    emailId?: string;
    phoneType?: number;
    playerAdditionalDetails?: PlayerAdditionalDetails[];
    extProfileId?: string;
    platformUuId?: string;
    noofHoles: string;
    greenFee: string | number;
    cartFee: string | number;
    negotiateGreenFee: boolean;
    negotiatedGreenFee: string;
    negotiateCartFee: boolean;
    negotiatedCartFee: string;
    vipType: string;
}

export interface AddressDetail {
    addressDetails: string;
}

export interface AllocationCode {
    id: number;
    allocationBlockCode: string;
    allocationBlockName: string;
    colorCode: string;
    daysOut: number
}

export interface Course {
    id: number;
    name: string;
    defaultOutletId: number;
    avgPlay9Holes: number;
    avgPlay18Holes: number;
}

export interface PlayerType {
    id: number;
    type: string;
    daysOutStart: number;
    daysOutEnd: number;
    isActive: boolean;
}

export interface RateType {
    id: number;
    type: string;
    daysOutStart: number;
    daysOutEnd: number;
}

export interface PlayerTeeTimeSlot {
    courseId: number;
    playerId: number;
    scheduledDateTime: string;
    playerSlotPosition: number;
    holeNumber: string;
    isSqueezed: boolean;
    destinationScheduledTeeTimeId: number;
    isValid?: boolean;
    isDestinationSlotRateChanged?: boolean;
    sourceScheduledTeeTimeId?: number;
    originalHoleNumber: string;
    overrideDetails: OverrideDetails[];
    playerTypeId: number;
    rateTypeId: number;
    useNewRateType: boolean;
    multiPackTransactionDetailId : number;
    multiPackGreenFeeValue : number;
    multiPackCartFeeValue : number;
}

export interface TeeSlotDetails {
    TeeSlots: TeeSheetSkeletonData[];
    outstandingPlayersCount: number;
    autoSqueezeIndex: number;
    playersPerGroup: number;
}

export interface Bulkstatus {
    status: status,
    maxSqueezeMins: number;
    teeSlotDetails: TeeSlotDetails;
}

export enum status {
    success,
    failure,
    squeeze,
    allocationCodeAccessFailure
}