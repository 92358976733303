import { GolfAsideFilterComponent } from './components/aside-filter/aside-filter.component';
import { GolfButtonComponent } from './components/button/button.component';
import { GolfCheckboxComponent } from './components/checkbox/checkbox.component';
import { CommonModule } from '@angular/common';
import { CommonPopupComponent } from './components/common-popup/common-popup.component';
import { GolfDialogFooterComponent } from './components/dialog-footer/dialog-footer.component';
import { GolfDialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { GolfActionsComponent } from './components/golf-actions/golf-actions.component';
import { GolfDatePickerComponent } from './components/golf-date-picker/golf-date-picker.component';
import { GolfTimePickerComponent } from './components/golf-time-picker/golf-time-picker.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from '../material-module';
import { ModuleNavigatorComponent } from './components/module-navigator/module-navigator.component';
import { MultiSelectGolfButtonComponent } from './components/multi-select-button/multi-select-button.component';
import { NgModule } from '@angular/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ObjectindexofPipe } from './components/multi-select-button/objectindexof.pipe';
import { PopoverModule } from 'ngx-smart-popover';
import { RadioTableComponent } from './components/radio-table/radio-table.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RecurringComponent } from './components/recurring/recurring.component';
import { RouterModule } from '@angular/router';
import { SideNavBarComponent } from './components/side-nav-bar/side-nav-bar.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { SubModuleNavigatorComponent } from './components/sub-module-navigator/sub-module-navigator.component';
import { GolfTableSearchHeaderComponent } from './components/table-search-header/table-search-header.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AlertPopupComponent } from './components/alert-popup/alert-popup.component';
import { AttributeDirective, LessonHeightDirective, MultiLineEllipsisDirective } from './directives/attribute.directive';
import { TablevirtualscrollComponent } from './tablevirtualscroll/tablevirtualscroll.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { checkorderExistPipe } from './tablevirtualscroll/pipes/checkobjexist.pipe';
import { GolfChipSearchComponent } from './components/chip-search/chip-search.component';
import { GolfSplitGolfButtonComponent } from '../settings/golf-split-button/golf-split-button.component';
import { GolfManagementCommunication } from './communication/services/golfmanagement.service';
import { GolfSimpleSearchComponent } from './components/simple-search/simple-search.component';
import { PlayerInfoCardComponent } from './components/player-info-card/player-info-card.component';
import { ImageThumbnailComponent } from './components/image-thumbnail/image-thumbnail.component';
import { NummaxLength } from './utilities/num-maxlength.directive';
import { NoteComponent } from './components/note/note.component';
import { GolfMoreComponent } from './components/more/more.component';
import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { GolfToggleComponent } from './components/golf-toggle/golf-toggle.component';
import { inputtypeDirective } from './utilities/inputtype.directive';
import { CurrencyFormatterDirective } from '../core/localization/currency.directive';
import { PlatformModule } from '@angular/cdk/platform';
import { AuthenticationCommunication } from './communication/services/authentication.service';
import { ViewmoreDirective } from './directives/viewmore.directive';
import { RetailmanagementCommunication } from './communication/services/retailmanagement.service';
import { CheckboxTableComponent } from './components/checkbox-table/checkbox-table.component';
import { TeeGridComponent } from './components/tee-grid/tee-grid.component';
import { TeePlayerDetailsComponent } from './components/tee-grid/tee-player-details/tee-player-details.component';
import { TeeGridService } from './components/tee-grid/tee-grid.service';
import { FormatTimePipe } from './pipes/format-time.pipe';
import { MoreLegendComponent } from './components/more-legend/more-legend.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { TeeSlotInfoComponent } from './components/tee-slot-info/tee-slot-info.component';
import { LocalizeTimePipe } from '../core/localization/localize-time.pipe';
import { LocalizeDatePipe } from '../core/localization/localize-date.pipe';
import { IsEllipsisDirective } from './utilities/isellipsis.directive';
import { TextMaskDirective } from './utilities/mask.directive';
import { numFormat } from './utilities/num-formatter.directive';
import { CustomCurrencyPipe } from '../core/localization/currency.pipe';
import { LocalizeDateTimePipe } from '../core/localization/localize-dateTime.pipe';
import { TenantManagementCommunication } from 'src/app/common/communication/services/tenantmanagement-communication-service';
import { GolfGlobalSearchComponent, highlightSearchTextPipe } from './components/global-search/global-search.component';
import { DataFilterPipe } from './pipes/data-filter.pipe';
import { PlayerTypeService } from './data-services/golfmanagement/playertype.data.service';
import { RateTypeDataService } from './data-services/golfmanagement/ratetype.data.service';
import { NoDataFoundComponent } from './components/no-data-found/no-data-found.component';
import { AgGolfPhoneNumberComponent } from './components/ag-phone-number/ag-phone-number.component';
import { AgGolfAddressComponent } from './components/ag-address/ag-address.component';
import { AgGolfCurrencyComponent } from './components/ag-currency/ag-currency.component';
import { AgGolfPercentageComponent } from './components/ag-percentage/ag-percentage.component';
import { AgGolfPostalCodeComponent } from './components/ag-postal-code/ag-postal-code.component';
import { AgCreditCardComponent } from './components/ag-credit-card/ag-credit-card.component';
import { AgGolfImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { CustomFieldInfoComponent } from './components/custom-field-info/custom-field-info.component';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapter, MY_DATE_FORMATS } from '../core/localization/custom.dateAdapter';
import { GolfLocalization } from '../core/localization/golf-localization';
import { GoogleMapsModule } from '@angular/google-maps';
import { nowhitespaceDirective } from './utilities/nowhitespace.directive';
import { FilterLengthPipe } from './components/aside-filter/aside-filter.pipe';
import { DuplicateCheckDirective } from './components/ag-phone-number/ag-duplicate-contact-directive';
import { GolfGatewayCommunication } from './communication/services/golfGateway';
import { ImgGolfThumbnailComponent } from './components/img-thumbnail/img-thumbnail.component';
import { GetTimePipe } from './pipes/get-time.pipe';
import { BulkPlayerListComponent } from './components/bulk-player-list/bulk-player-list.component';
import { ItneraryPipe } from './pipes/itnerary.pipe';
import { ColorCheckerPipe } from './pipes/color-checker.pipe';
import { AllocationBlockDataService } from 'src/app/shared/data-services/golfschedule/allocationblock.data.service';
import { PlanMoreDirective } from './directives/plan-more.directive';
import { MinutessqueezeComponent } from './components/minutessqueeze/minutessqueeze.component';
import { CaptureCardComponent } from './components/capture-card/capture-card.component';
import { CardTypeComponent } from './components/card-type/card-type.component';
import { AgPlayerTypeSearchComponent } from './components/ag-player-type-search/ag-player-type-search.component';
import { RetailSharedModule } from '../retail/shared/retail-shared.module';

import { LoadDecimalValuePipe } from './pipes/load-decimal-value.pipe';
import { TeeSheetUtilities } from './utilities/teesheet.utilities';
import { CardSwipePopupComponent } from './components/card-swipe-popup/card-swipe-popup.component';
import { DisableDoubleClickDirective } from './directives/disable-double-click.directive';
import { GolfImageService } from './data-services/Image/golf.Image.service';
import { ImageDataService } from './data-services/Image/Image.data.services';
import { SendNotificationMailSmsComponent } from './components/send-notification-mail-sms/send-notification-mail-sms.component';
import { FormatNamePipe } from './pipes/format-name.pipe';
import { AlertMessagePopupComponent } from './alert-message-popup/alert-message-popup.component';
import { CommonSharedModule } from '../common/shared/shared/shared.module';
import { PlayerProfileDataService } from './data-services/golfmanagement/PlayerProfile.data.services';
import { TeetimeSearchDataService } from './data-services/golfschedule/teetimesearch.data.services';
import { TeeTimeSearchBusiness } from '../tee-time/search/tee-time-search.business';
import { MenuComponent } from './components/menu/menu.component';
import { SortOrderPipe } from './pipes/sort-order.pipe';
import { TemplatesModule } from '../common/templates/templates.module';

import { PlayerInformationComponent } from 'src/app/shared/create-player/player-information/player-information.component';
import { AdditionalInformationComponent } from 'src/app/shared/create-player/additional-information/additional-information.component';
import { HistoryComponent } from 'src/app/shared/create-player/history/history.component';
import { ItneraryComponent } from 'src/app/shared/create-player/itnerary/itnerary.component';
import { SortQuantityPipe } from 'src/app/shared/create-player/history/sort-quantity.pipe';
import { AgGolfDropdownComponent } from './ag-dropdown/ag-dropdown.component';
import { HttpCancelService } from './service/httpcancel.service';
import { ManageHttpInterceptor } from './service/managehttp.interceptor';
import { TeeTimeBusinessService } from '../settings/system-setup/tee-times/tee-times.business.service';
import { AllowedSpecialCharacterDirective } from './directives/allowedSpecialCharacter.directive';
import { AgGolfMailIdComponent } from './components/ag-mail-id/ag-mail-id.component';
import { GuaranteeTypeValidator } from './utilities/guaranteeType.Validator';
import { PaymentServiceCommunication } from './communication/services/payment-communication-services';
import { RetailIntegrationLogService } from '../retail/shared/service/retail-integrationLog.service';
import { CaddyTypeSelectionPopupComponent } from '../tee-time-actions/caddytype-selection-modal/caddytype-selection.component';
import { PlayerDetailComponent } from './components/player-detail/player-detail.component';
import { PlayerPaymentInfoComponent } from './components/player-payment-info/player-payment-info.component';
import { PlayerContactSharedComponent } from './components/player-contact-shared/player-contact-shared.component';
import { GuestPlayersService } from '../guest/players/players.service';
import { PlayersBusiness } from '../guest/players/players.business';
import { SelectOutletPopupComponent } from './components/select-outlet-popup/select-outlet-popup.component';
import { AgPlayerDetailComponent } from './components/ag-player-detail/ag-player-detail.component';
import { GuestPolicyWrapperComponent } from './create-player/additional-information/guest-policy-wrapper/guest-policy-wrapper.component';
import { ContactLogWrapperComponent } from './create-player/contact-log-wrapper/contact-log-wrapper.component';
import { ContactLogComponent } from 'src/app/common/contact-log/contact-log.component';
import { CreateContactLogComponent } from 'src/app/common/contact-log/create-contact-log/create-contact-log.component';
import { CreatePlayerComponent } from './create-player/create-player.component';
import { OverLappingPlayerDetailsComponent } from './components/tee-grid/tee-player-details/over-lapping-player-details/over-lapping-player-details.component';
import { ConfirmPlayersPopupComponent } from './create-player/confirm-players-popup/confirm-players-popup.component';
import { UpdatePlayerDetailsComponent } from './components/update-player-details/update-player-details.component';
import { PlayerTypeRateTypeChangeService } from './service/playerTypeRateTypeChange.service';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  keyboard: {
    enabled: true,
  },
  mousewheel: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  slideToClickedSlide: true,
  loop: false,
  observer: true
};
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
    declarations: [
        AgGolfDropdownComponent,
        AgGolfPhoneNumberComponent,
        AgGolfMailIdComponent,
        AgGolfAddressComponent,
        AgGolfCurrencyComponent,
        AgGolfPercentageComponent,
        AgGolfPostalCodeComponent,
        AgCreditCardComponent,
        AlertPopupComponent,
        GolfAsideFilterComponent,
        GolfButtonComponent,
        GolfCheckboxComponent,
        GolfChipSearchComponent,
        CommonPopupComponent,
        GolfDialogFooterComponent,
        GolfDialogHeaderComponent,
        GolfActionsComponent,
        GolfDatePickerComponent,
        GolfSplitGolfButtonComponent,
        GolfTimePickerComponent,
        GolfToggleComponent,
        ModuleNavigatorComponent,
        MultiSelectGolfButtonComponent,
        PlayerInfoCardComponent,
        RadioTableComponent,
        RecurringComponent,
        SideNavBarComponent,
        GolfSimpleSearchComponent,
        StepperComponent,
        SubModuleNavigatorComponent,
        GolfTableSearchHeaderComponent,
        TablevirtualscrollComponent,
        TeeSlotInfoComponent,
        GolfChipSearchComponent,
        inputtypeDirective,
        GolfMoreComponent,
        ImageThumbnailComponent,
        NummaxLength, NoteComponent,
        CurrencyFormatterDirective,
        ViewmoreDirective,
        CheckboxTableComponent,
        TeeGridComponent,
        TeePlayerDetailsComponent,
        OverLappingPlayerDetailsComponent,
        MoreLegendComponent,
        GolfGlobalSearchComponent,
        NoDataFoundComponent,
        AgGolfImageUploaderComponent,
        ImgGolfThumbnailComponent,
        // Added for Player Lookup
        CreatePlayerComponent,
        PlayerInformationComponent, AdditionalInformationComponent, HistoryComponent, ItneraryComponent, SortQuantityPipe,
        // Directives
        AttributeDirective,
        CurrencyFormatterDirective,
        CustomCurrencyPipe,
        IsEllipsisDirective,
        AllowedSpecialCharacterDirective,
        LessonHeightDirective,
        MultiLineEllipsisDirective,
        numFormat,
        TextMaskDirective,
        ViewmoreDirective,
        nowhitespaceDirective,
        DuplicateCheckDirective,
        // Pipes
        checkorderExistPipe,
        LocalizeDatePipe,
        LocalizeTimePipe,
        LocalizeDateTimePipe,
        ObjectindexofPipe,
        FormatTimePipe,
        FormatNamePipe,
        DataFilterPipe,
        FilterLengthPipe,
        CustomFieldInfoComponent,
        GolfGlobalSearchComponent,
        highlightSearchTextPipe,
        LoadDecimalValuePipe,
        NoDataFoundComponent,
        BulkPlayerListComponent,
        GetTimePipe,
        ItneraryPipe,
        ColorCheckerPipe,
        SortOrderPipe,
        PlanMoreDirective,
        MinutessqueezeComponent,
        CaptureCardComponent,
        CardTypeComponent,
        AgPlayerTypeSearchComponent,
        CardSwipePopupComponent,
        DisableDoubleClickDirective,
        SendNotificationMailSmsComponent,
        AlertMessagePopupComponent,
        MenuComponent,
        CaddyTypeSelectionPopupComponent,
        PlayerDetailComponent,
        PlayerPaymentInfoComponent,
        PlayerContactSharedComponent,
        SelectOutletPopupComponent,
        AgPlayerDetailComponent,
        GuestPolicyWrapperComponent,
        ContactLogWrapperComponent,
        ContactLogComponent,
        CreateContactLogComponent,
        ConfirmPlayersPopupComponent,
        UpdatePlayerDetailsComponent
    ],
    imports: [
        CommonModule,
        //HttpClientModule,
        MaterialModule,
        NgxMaterialTimepickerModule,
        PlatformModule,
        PopoverModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        UiSwitchModule,
        UiSwitchModule.forRoot({
            size: 'small'
        }),
        PlatformModule,
        SwiperModule,
        VirtualScrollerModule,
        GoogleMapsModule,
        GooglePlaceModule,
        RetailSharedModule,
        CommonSharedModule,
        TemplatesModule,
        RetailSharedModule
    ],
    exports: [
        AgGolfDropdownComponent,
        AgGolfImageUploaderComponent,
        AgGolfPhoneNumberComponent,
        AgGolfMailIdComponent,
        AgGolfAddressComponent,
        AgGolfCurrencyComponent,
        AgGolfPercentageComponent,
        AgPlayerTypeSearchComponent,
        AgGolfPostalCodeComponent,
        AgCreditCardComponent,
        VirtualScrollerModule,
        GoogleMapsModule,
        GooglePlaceModule,
        SideNavBarComponent,
        GolfAsideFilterComponent,
        GolfButtonComponent,
        GolfCheckboxComponent,
        GolfChipSearchComponent,
        CurrencyFormatterDirective,
        CheckboxTableComponent,
        GolfDialogFooterComponent,
        GolfDialogHeaderComponent,
        GolfActionsComponent,
        GolfDatePickerComponent,
        GolfSplitGolfButtonComponent,
        GolfTimePickerComponent,
        GolfToggleComponent,
        MaterialModule,
        ModuleNavigatorComponent,
        MultiSelectGolfButtonComponent,
        PlatformModule,
        PlayerInfoCardComponent,
        PopoverModule,
        RadioTableComponent,
        ReactiveFormsModule,
        RecurringComponent,
        SideNavBarComponent,
        GolfSimpleSearchComponent,
        StepperComponent,
        SubModuleNavigatorComponent,
        GolfTableSearchHeaderComponent,
        TeeSlotInfoComponent,
        PlayerContactSharedComponent,
        PlayerPaymentInfoComponent,
        SelectOutletPopupComponent,
        TranslateModule,
        // UiSwitchModule,
        GolfChipSearchComponent,
        PlayerDetailComponent,
        TablevirtualscrollComponent,
        ImageThumbnailComponent, NoteComponent,
        NummaxLength,
        GolfMoreComponent,
        SwiperModule,
        TeeGridComponent,
        TeePlayerDetailsComponent,
        OverLappingPlayerDetailsComponent,
        MoreLegendComponent,
        GolfGlobalSearchComponent,
        NoDataFoundComponent,
        ImgGolfThumbnailComponent,
        MinutessqueezeComponent,
        CaptureCardComponent,
        CardTypeComponent,
        SendNotificationMailSmsComponent,
        MenuComponent,
        AttributeDirective,
        CurrencyFormatterDirective,
        CustomCurrencyPipe,
        LessonHeightDirective,
        MultiLineEllipsisDirective,
        numFormat,
        inputtypeDirective,
        ViewmoreDirective,
        nowhitespaceDirective,
        DuplicateCheckDirective,
        PlanMoreDirective,
        DisableDoubleClickDirective,
        AllowedSpecialCharacterDirective,
        // Pipes
        checkorderExistPipe,
        LocalizeDatePipe,
        LocalizeDateTimePipe,
        LocalizeTimePipe,
        ObjectindexofPipe,
        FormatTimePipe,
        FormatNamePipe,
        DataFilterPipe,
        MoreLegendComponent,
        LoadDecimalValuePipe,
        TextMaskDirective,
        CustomFieldInfoComponent,
        BulkPlayerListComponent,
        GetTimePipe,
        ItneraryPipe,
        ColorCheckerPipe,
        SortOrderPipe,
        CommonSharedModule,
        RetailSharedModule,
      CaddyTypeSelectionPopupComponent
    ],
    providers: [NgxImageCompressService,
        GolfManagementCommunication, AuthenticationCommunication, RetailmanagementCommunication, PaymentServiceCommunication, TeeGridService, LocalizeTimePipe, GuestPlayersService, PlayersBusiness,
        TenantManagementCommunication, PlayerTypeService, RateTypeDataService, GolfGatewayCommunication, TeeSheetUtilities, AllocationBlockDataService,
        PlayerProfileDataService, TeetimeSearchDataService, TeeTimeSearchBusiness,
        { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
        {
            provide: DateAdapter, useClass: CustomDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS
        },
        GolfImageService, ImageDataService,
        HttpCancelService,
        { provide: HTTP_INTERCEPTORS, multi: true, useClass: ManageHttpInterceptor },
        TeeTimeBusinessService,
        GuaranteeTypeValidator,
        RetailIntegrationLogService,
        PlayerTypeRateTypeChangeService
    ]
})
export class SharedModule {
  constructor(private adapter: DateAdapter<any>, public localization: GolfLocalization) {
    this.adapter.setLocale(localization.localeCode);
  }
}
