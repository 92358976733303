import { Injectable } from "@angular/core";
import { TableHeaderOptions, TableOptions } from "src/app/common/Models/ag-models";
import { ActionTypeEnum, aligment, HdrActionTypeEnum, SorTypeEnum } from "src/app/common/components/cdkvirtual/cdkvirtual.model";
import { Localization } from "src/app/common/localization/localization";
import { viewBy, viewRefundDeposit } from "./view-refund-deposit.model";
import { UnPaidPlayersBusiness } from 'src/app/shared/data-services/golfschedule/unpaid-players.business';
import { ScheduledTeeTimeUnPaidPlayer } from "src/app/shared/models/unpaid-players.model";
import { PlayerPaymentstatus } from "src/app/retail/shared/shared.modal";
import { PlayerTypeRateTypeChangeService } from "src/app/shared/service/playerTypeRateTypeChange.service";
import { FromAction } from "src/app/shared/global.constant";
import { GolfUtilities } from "src/app/shared/utilities/golf-utilities";
import { AlertType, ButtonType } from "src/app/shared/shared-models";


@Injectable()
export class ViewRefundDepositBusiness {
    captions: any;
    depositCaption: any;
    
    UnPaidPlayers: ScheduledTeeTimeUnPaidPlayer[];
  playerTableContent: viewRefundDeposit[];
  ticketTableContent: viewRefundDeposit[];
  AlertPopupVisible: boolean = false;
    constructor(private localization: Localization, private _unPaidPlayersBusiness: UnPaidPlayersBusiness , private _playerTypeRateTypeChangeService: PlayerTypeRateTypeChangeService, private _utils: GolfUtilities) {
        this.captions = this.localization.captions;
    }

    getTicketTableOptions(): TableOptions {
      return {
        defaultSortOrder: SorTypeEnum.asc,
        defaultsortingColoumnKey: 'ticket',
        isRowClick: true,
        uniqueKey: 'ticket',
      };
    }

    getPlayerTableOptions(): TableOptions {
      return {
        defaultsortingColoumnKey: 'playerName',
        defaultSortOrder: SorTypeEnum.asc,
        showTotalRecords: false
      };
    }
    getTicketHeaderOptions(): TableHeaderOptions[] {
        return [
          {
            key: 'checked',
            displayName: '',
            //hdrtemplateName: HdrActionTypeEnum.hdrCheckbox,
            templateName: ActionTypeEnum.radioButton,
            isCheckboxDisabled : false
          },
          {
            key: 'ticket',
            displayName: this.captions.lbl_ticket,
            displayNameId: 'lbl_ticket',
            sorting: true,
          },
          {
            key: 'playerName',
            displayName: this.captions.tbl_lbl_playerName,
            displayNameId: 'tbl_lbl_playerName',
            sorting: true,
            templateName: ActionTypeEnum.custom
          },
          {
            key: 'transactionDate',
            displayName: this.captions.lbl_transactionDate,
            displayNameId: 'lbl_transactionDate',
            sorting: true
          },
          {
            key: 'playerType',
            displayName: this.captions.playerType,
            displayNameId: 'playerType',
            sorting: true,
          },
          {
            key: 'rateType',
            displayName: this.captions.rateType,
            displayNameId: 'rateType',
            sorting: true,
          },
          {
            key: 'packageCode',
            displayName: this.captions.lbl_packageCode,
            displayNameId: 'lbl_packageCode',
            sorting: true,
          },
          {
            key: 'depositCollectedString',
            displayName: this.localization.replacePlaceholders(this.captions.lbl_depositCollected, ["option"], [this.localization.currencySymbol]),
            displayNameId: 'lbl_depositCollected',
            sorting: true,
            alignment: aligment.right
          },
          {
            key: 'remainingAmountString',
            displayName: this.localization.replacePlaceholders(this.captions.lbl_remainingAmount, ["option"], [this.localization.currencySymbol]),
            displayNameId: 'lbl_remainingAmount',
            sorting: true,
            alignment: aligment.right
          },
          {
            key: 'refundedAmountString',
            displayName: this.localization.replacePlaceholders(this.captions.lbl_refundedAmount, ["option"], [this.localization.currencySymbol]),
            displayNameId: 'lbl_refundedAmount',
            sorting: true,
            alignment: aligment.right
          }
        ];
    }
    getPlayerHeaderOptions(): TableHeaderOptions[] {
      return [{
        key: 'checked',
        displayName: '',
        displayNameId: '',
        templateName: ActionTypeEnum.radioButton,
        radioKey: 'id'
      },
      {
        key: 'playerName',
        displayName: this.captions.tbl_lbl_playerName,
        displayNameId: 'tbl_lbl_playerName',
        sorting: true,
        templateName: ActionTypeEnum.custom
      },
      {
        key: 'ticket',
        displayName: this.captions.lbl_ticket,
        displayNameId: 'lbl_ticket',
        sorting: true,
      },
      {
        key: 'transactionDate',
        displayName: this.captions.lbl_transactionDate,
        displayNameId: 'lbl_transactionDate',
        sorting: true
      },
      {
        key: 'playerType',
        displayName: this.captions.playerType,
        displayNameId: 'playerType',
        sorting: true,
      },
      {
        key: 'rateType',
        displayName: this.captions.rateType,
        displayNameId: 'rateType',
        sorting: true,
      },
      {
        key: 'packageCode',
        displayName: this.captions.lbl_packageCode,
        displayNameId: 'lbl_packageCode',
        sorting: true,
      },
      {
        key: 'depositCollectedString',
        displayName: this.localization.replacePlaceholders(this.captions.lbl_depositCollected, ["option"], [this.localization.currencySymbol]),
        displayNameId: 'lbl_depositCollected',
        sorting: true,
        alignment: aligment.right
      },
      {
        key: 'remainingAmountString',
        displayName: this.localization.replacePlaceholders(this.captions.lbl_remainingAmount, ["option"], [this.localization.currencySymbol]),
        displayNameId: 'lbl_remainingAmount',
        sorting: true,
        templateName: ActionTypeEnum.custom,
        alignment: aligment.right
      },
      {
        key: 'refundedAmountString',
        displayName: this.localization.replacePlaceholders(this.captions.lbl_refundedAmount, ["option"], [this.localization.currencySymbol]),
        displayNameId: 'lbl_refundedAmount',
        sorting: true,
        alignment: aligment.right
      }
    ];
  }

  async GetDepositPlayers(data){
    this.UnPaidPlayers = await this._unPaidPlayersBusiness.getPlayers(data?.course?.id, data?.dateTime, PlayerPaymentstatus.unPaid | PlayerPaymentstatus.refund, false, true, false);
    let unPaidDepositPlayers = this.UnPaidPlayers.filter(x => x.deposits.length > 0);
    this.playerTableContent = this.MapDepositByPlayer(unPaidDepositPlayers);
    this.ticketTableContent = this.MapDepositByTicket(unPaidDepositPlayers);
  }
  MapDepositByPlayer(unPaidDepositPlayers: ScheduledTeeTimeUnPaidPlayer[]): viewRefundDeposit[] {
    let depositPlayers: viewRefundDeposit[] = []
    let idValue = 1;
    unPaidDepositPlayers.map(x => {
      if(x.deposits.length > 0){
        x.deposits.forEach(deposit => {
          let depositPlayer: viewRefundDeposit = {
            id: idValue,
            checked: x.checked,
            ticket: deposit.depositTicketNumber,
            playerName: [x.playerName],
            depositCollected: deposit.amount,
            depositCollectedString: this.localization.localizeCurrency(deposit.amount, false, 2),
            transactionDate: this.localization.LocalizeShortDate(new Date(deposit.depositDate)),
            playerType: x.playerType,
            rateType: x.rateType,
            packageCode: x.packageCode,
            remainingAmount: deposit.amount + deposit.refundAmount,
            refundedAmount: deposit.refundAmount,
            refundedAmountString: this.localization.localizeCurrency(Math.abs(deposit.refundAmount), false, 2),            
            remainingAmountString: this.localization.localizeCurrency(deposit.amount + deposit.refundAmount, false, 2),
            playerId: [x.playerId],
            type: viewBy.byPlayer
          }
          idValue += 1;
          depositPlayers.push(depositPlayer);
        })
      }
    });
    return depositPlayers;
  }

  MapDepositByTicket(unPaidDepositPlayers: ScheduledTeeTimeUnPaidPlayer[]): viewRefundDeposit[] {
    let depositPlayers: viewRefundDeposit[] = []
    unPaidDepositPlayers.map(x => {
      if(x.deposits.length > 0){
        x.deposits.forEach(deposit => {
          if (depositPlayers.length > 0 && depositPlayers.find(x => x.ticket == deposit.depositTicketNumber)){
            depositPlayers.forEach(depositPlayer => {
              if (depositPlayer.ticket == deposit.depositTicketNumber){
                depositPlayer.playerName.push(x.playerName);
                depositPlayer.playerId.push(x.playerId);
                depositPlayer.depositCollected += deposit.amount;
                depositPlayer.refundedAmount += deposit.refundAmount;
                depositPlayer.remainingAmount += (deposit.amount + deposit.refundAmount);
                depositPlayer.refundedAmountString = this.localization.localizeCurrency(Math.abs(depositPlayer.refundedAmount), false, 2),
                depositPlayer.remainingAmountString = this.localization.localizeCurrency(depositPlayer.remainingAmount, false, 2),
                depositPlayer.depositCollectedString = this.localization.localizeCurrency(depositPlayer.depositCollected, false, 2);
              }
            })
          }
          else{
            let depositPlayer: viewRefundDeposit = {
              id: 0,
              checked: x.checked,
              ticket: deposit.depositTicketNumber,
              playerName: [x.playerName],
              depositCollected: deposit.amount,
              depositCollectedString: this.localization.localizeCurrency(deposit.amount, false, 2),
              transactionDate: this.localization.LocalizeShortDate(new Date(deposit.depositDate)),
              playerType: x.playerType,
              rateType: x.rateType,
              packageCode: x.packageCode,
              refundedAmount: deposit.refundAmount,
              remainingAmount: deposit.amount + deposit.refundAmount,
              refundedAmountString: this.localization.localizeCurrency(Math.abs(deposit.refundAmount), false, 2),
              remainingAmountString: this.localization.localizeCurrency(deposit.amount + deposit.refundAmount, false, 2),
              playerId: [x.playerId],
              type: viewBy.byTicket
            }
            depositPlayers.push(depositPlayer);
          }
        })
      }
    });
    return depositPlayers;
  }

  getSelectedUnpaidPlayer(selectedCustomer: viewRefundDeposit) {
    var selectedPlayers = this.UnPaidPlayers.filter(y => selectedCustomer.playerId.includes(y.playerId));
    selectedPlayers.forEach(s => {
      s.deposits = s.deposits.filter(d => d.depositTicketNumber == selectedCustomer.ticket);
      s.deposit = selectedCustomer.type == viewBy.byTicket ? s.deposits.reduce((sum, current) => sum + (current.amount + current.refundAmount), 0) : selectedCustomer.remainingAmount;
      s.dependentTransactionIds = { [s.playerId] : s.deposits.map(d => d.depositTransactionId) } as any;
      s.scheduledTeeTimePlayerCustomFees = [];
    });
    return selectedPlayers;
  }

  async proceedToDepositRefund(selectedCustomer, data)
  {
    let refundData = this.getSelectedUnpaidPlayer(selectedCustomer);
    await this._playerTypeRateTypeChangeService.ProceedDepositRefund(refundData, data?.course?.id, data?.dateTime, true, null, null, FromAction.DepositRefund);
  }

  RefreshGrid(res, gridData){

  }

  CheckDepositExceedsAmountOwed(players: viewRefundDeposit[]) {
    return (players.some(p => p.remainingAmount > p.depositCollected && p.depositCollected < (p.remainingAmount + p.refundedAmount)));
  }

  ShowValidationPopup() {
    if (!this.AlertPopupVisible) {
      this.AlertPopupVisible = true;
      this._utils.showAlert(this.localization.captions.shop.DepositValidation, AlertType.Warning, ButtonType.Ok, (res) => {
        this.AlertPopupVisible = false;
      });
    }

  }
}