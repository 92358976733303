import { Injectable } from "@angular/core";
import { GolfUtilities } from '../../utilities/golf-utilities';
import { AlertType, ButtonType, ComponentDetails } from '../../shared-models';
import { MatDialog } from '@angular/material/dialog';
import { MinutessqueezeComponent } from '../minutessqueeze/minutessqueeze.component';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { ButtonAction } from '../../global.constant';
import { TeeTimeFormat } from '../../models/teesheet.form.models';

@Injectable()
export class TeeGridService {
    selectedPlayers: any = [];
    rowDataObject: any;
    popUpComponentDetails: ComponentDetails;
    captions: any;
    $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(public _Utilities: GolfUtilities, public _dialog: MatDialog, public _Localization: GolfLocalization) {
        this.captions = this._Localization.captions;
    }

    getRestrictedDropSlots() {
        return ['HOLD'];
    }

    isDropAllowedOnBulkMove(dragPlayerList, dropSlotData) {
        let isBulkMoveAllowed = true;
        if(dragPlayerList[0].tournamentId > 0 && dropSlotData.length == 0){
        isBulkMoveAllowed = false;
        this._Utilities.showAlert(this._Localization.captions.teetime.TournamentProceedActionAlert, AlertType.Warning, ButtonType.Ok);
        return isBulkMoveAllowed;
        }

        for (let i = 0; i < dragPlayerList.length; i++) {
            if (dropSlotData.length > 0) {           
            
                let HasGroupAvailable: boolean = dropSlotData.some(x => x.teeTimeFormat === TeeTimeFormat.GroupTee);                
                let HasTournamentAvailable: boolean = dropSlotData.some(x => x.teeTimeFormat === TeeTimeFormat.Tournament);
                let IsAllGroupPlayers: boolean = dropSlotData.map(o => { return { teeTimeFormat: o.teeTimeFormat, bookingId: o.bookingId } }).every(x => x.teeTimeFormat === TeeTimeFormat.GroupTee && x.bookingId == dragPlayerList[i].bookingId);
                let isTournametMismatch: boolean = dropSlotData.some(x => x.tournamentId != dragPlayerList[i].tournamentId);

                if (dragPlayerList[i].teeTimeFormat == TeeTimeFormat.None) {
                    if (HasGroupAvailable || HasTournamentAvailable) {
                        isBulkMoveAllowed = false;
                        let message = HasGroupAvailable ? this._Localization.captions.teetime.GroupProceedActionAlert : this._Localization.captions.teetime.TournamentProceedActionAlert;
                        this._Utilities.showAlert(message, AlertType.Warning, ButtonType.Ok);
                        break;
                    }
                }

                else if (dragPlayerList[i].teeTimeFormat == TeeTimeFormat.BulkTee) {
                    if (HasGroupAvailable || HasTournamentAvailable) {
                        isBulkMoveAllowed = false;
                        this._Utilities.showAlert(this._Localization.captions.teetime.BulkProceedActionAlert, AlertType.Warning, ButtonType.Ok);
                        break;
                    }
                }

                else if (dragPlayerList[i].teeTimeFormat == TeeTimeFormat.GroupTee) {
                    if (!IsAllGroupPlayers) {
                        isBulkMoveAllowed = false;
                        this._Utilities.showAlert(this._Localization.captions.teetime.GroupProceedActionAlert, AlertType.Warning, ButtonType.Ok);
                        break;
                    }
                }

                else if (isTournametMismatch) {
                    isBulkMoveAllowed = false;
                    this._Utilities.showAlert(this._Localization.captions.teetime.TournamentProceedActionAlert, AlertType.Warning, ButtonType.Ok);
                    break;
                }

            }
            else {
                return isBulkMoveAllowed;
            }
        }  
        return isBulkMoveAllowed;
    }

    squeezeTeeRows(squeezeMinutes, fromComponent?, callback?) {
        this.popUpComponentDetails = {
            componentName: MinutessqueezeComponent,
            popUpDetails: {
                isStepper: false,
                eventName: 'notifyParent',
                bindData: squeezeMinutes
            }
        };
               this._dialog.open(MinutessqueezeComponent, {
            width: "40%",
            height: "40%",
            panelClass: "",
            data: {
                title: this.captions.teetime.squeeze,
                save: this.captions.teetime.PopUpOkay,
                cancel: this.captions.teetime.cancel,
                componentDetails: this.popUpComponentDetails,
                info: squeezeMinutes,
                showStaticBool: false,
                fromComponent: fromComponent,
                actionType: ButtonAction.save

            },
            disableClose: true
        }).afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(result => {
            if (callback) {
                callback(result);
            }
        });
    }


    scrollTeeGrid(e, customTableData, teeSheetID, popLevel?, teeSheetLeftID?, teeSheetRightID?) {    
        let scrollSpeed = 100;    // bug 58749
        let popupLevel = 0;
        if (popLevel) {
            popupLevel = popLevel;
        }
        // Grid Scroll in Popup
        if (customTableData.isLoadedinPopup) {
            let gridStartingPoint = document.getElementsByClassName(teeSheetID.tableID)[0].getBoundingClientRect()['top'];
            let gridEndingPoint = document.getElementsByClassName('dialog-container__actions')[popupLevel]['offsetTop'];
            let popupscrollElement = document.getElementsByClassName('dialog-container__content')[popupLevel];
            if (e.pointerPosition.y > gridEndingPoint) {
                popupscrollElement.scrollTop = popupscrollElement.scrollTop + scrollSpeed;
            } else if (e.pointerPosition.y < gridStartingPoint) {
                popupscrollElement.scrollTop = popupscrollElement.scrollTop - scrollSpeed;
            }
        } else { // Grid Scroll in Normal Page
            // let scrollSpeed = 180; 
            if (customTableData.isMultiView) {
                let windowWidth = window.innerWidth;
                let asideFilterWidth = document.getElementsByClassName('aside-filter')[0].clientWidth;
                let windowmidX = (windowWidth / 2) + asideFilterWidth;
                if (e.pointerPosition.x < windowmidX) {
                    if (teeSheetLeftID && teeSheetLeftID) {
                        this.scrollTeeGridNormalMode(e, scrollSpeed, teeSheetLeftID)
                    }
                }
                else {
                    if (teeSheetRightID && teeSheetRightID) {
                        this.scrollTeeGridNormalMode(e, scrollSpeed, teeSheetRightID);
                    }
                }
            }
            else {
                if (teeSheetID && teeSheetID.tableID) {
                    this.scrollTeeGridNormalMode(e, scrollSpeed, teeSheetID.tableID);
                }
            }
        }
    }

    scrollTeeGridNormalMode(e, scrollSpeed, teeSheetID) {
        let scrollElement = document.getElementById(teeSheetID);
        let gridStartingPoint = scrollElement.offsetTop + 60; // 60 is constant value that is added for table head section
        let gridEndingPoint = scrollElement.offsetTop + scrollElement.clientHeight - 20; // Default 20px is set to scroll just behind the end of the bottom
        if (e.pointerPosition.y < gridStartingPoint) {
            scrollElement.scrollTop -= scrollSpeed;
        } else if (e.pointerPosition.y > gridEndingPoint) {
            scrollElement.scrollTop += scrollSpeed;
        }
    }
}
