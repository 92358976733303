import { Injectable } from '@angular/core';
import { PrintRentalAgreement } from 'src/app/shared/shared-models';
import { PrintManagerDataService } from 'src/app/common/dataservices/printmanager.data.services';
import { FastReportBusinessService } from 'src/app/retail/retail-reports/fast-report/fastreport.business.service';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RentalAgreement, SettingScreen } from 'src/app/shared/global.constant';
import { AllReports, ReportAPIOptions, ReportParams } from 'src/app/retail/shop/shop.modals';
import { TenantConfigurationDataService } from 'src/app/retail/shared/service/data- services/tenantConfiguration.data.service';
import { TemplateDataService } from 'src/app/retail/shared/service/data- services/template.data.service';
import { API as setting } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { PrintInformationType } from 'src/app/common/Models/printer-default-configuration.model';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';

@Injectable({
	providedIn: 'root'
})
export class AgreementBusinessService {
	constructor(
		private _fastReportBusinessService: FastReportBusinessService
		, private localization: RetailLocalization
		 ,private _printManager:PrintManagerDataService
		 ,private tenantConfig:TenantConfigurationDataService,
		 private _templateDataService :TemplateDataService,
		 private utilities : CommonUtilities) { }

		 public printRentalAgreement(agreementNum: PrintRentalAgreement) {
			let agreementCode: AllReports;
			switch (agreementNum.RentalAgreement) {
				case RentalAgreement.CartAgreement:
					agreementCode = AllReports.CartRentalAgreement;
					break;
				case RentalAgreement.ClubAgreement:
					agreementCode = AllReports.GolfClubRentalAgreement;
					break;
				case RentalAgreement.ShoeAgreement:
					agreementCode = AllReports.ShoeRentalAgreement;
					break;
			}
			let reportOptions: ReportAPIOptions = this.formReportAPIOptions(agreementCode);
			this._fastReportBusinessService.downloadReport('PDF', reportOptions, true);
		}
	
		private formReportAPIOptions(agreementCode: AllReports): ReportAPIOptions {
			return {
				code: agreementCode,
				params: this.formReportParams(),
				URIParams: [],
				filters: {},
				format: 'PDF',
				pageBreak: true
			};		
		}
	
		private formReportParams(): ReportParams[] {
			return [
				{ pPropertyName: this.localization.GetPropertyInfo('PropertyName') },
				{ pDate: this.localization.ConvertDateToISODateTime(new Date()) }
			];
		}	
		//Feature 57221: Cart, Shoe, Club Agreement ,Retail sale Chit , Retail sale chit Not Implemented
		//Take Configurated Printer from specific services
		public async printRentalAgreementFromTemplate(playerId:number [],c:PrintRentalAgreement) {
			let jsonData= {
				playerId: playerId ? playerId : 0
			};
				const setting = JSON.parse(sessionStorage.getItem('GOLFSETTING'));
				let url = setting.printerManagerURI ;
				let agreementPrinter = 	await this.utilities.getPrinterConfigurationToPrint(PrintInformationType.CartAgreement);
				let config=this.tenantConfig.getTenantConfiguration();
				let printLetter= this._templateDataService.PrintLetter(jsonData,c.RetailItemId,c.RentalAgreement,0);
			   const promiseResolve= await Promise.all([config,printLetter]);
			  promiseResolve[1].forEach(r => {
				this._printManager.Print(url,agreementPrinter);
			})	
		}
}
