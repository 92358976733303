import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { ContactType, ExistingPlayerTypes, HotelReservationTypes, InitiateSearch, MailTypes, PhoneTypes, playerTypes, SettingScreen } from '../../global.constant';
import { SearchType } from '../../shared-models';
import { PlayerDataService } from '../../data-services/golfmanagement/player.data.service';
import { GolfUtilities } from '../../utilities/golf-utilities';
import { MemberBusinessService } from 'src/app/retail/shared/business/Member-business.service';
import { GolfChipSearchComponent } from '../chip-search/chip-search.component';
import { AgPlayerTypeSearchService } from './ag-player-type-search.business';
import { defaultValues, TeeTimeData } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { TeeTimeBusinessService } from 'src/app/settings/system-setup/tee-times/tee-times.business.service';
import { Subject } from 'rxjs';
import { OnDestroy } from '@angular/core';
import * as SettingAPI from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { AgToggleConfig } from 'src/app/common/Models/ag-models';
import { AlertMessagePopupComponent } from 'src/app/shared/alert-message-popup/alert-message-popup.component';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { throttleTime } from 'rxjs/operators';
import { PlayersBusiness } from 'src/app/guest/players/players.business';
import { DefaultsSettingConfig } from '../../models/default-settings.models';
import { PlayerProfileUI } from '../../create-player/create-player.model';
@Component({
  selector: 'app-ag-player-type-search',
  templateUrl: './ag-player-type-search.component.html',
  styleUrls: ['./ag-player-type-search.component.scss'],
  providers: [PlayerDataService, AgPlayerTypeSearchService],
  encapsulation: ViewEncapsulation.None
})
export class AgPlayerTypeSearchComponent implements OnInit, OnDestroy {
  @ViewChild('chipSearch') chipSearch: GolfChipSearchComponent;
  playerTypeForm: UntypedFormGroup;
  searchPlaceholder: string;
  controlName: string;
  showSearch: boolean;
  captions: any;
  errorCaption: any;
  playerCategories: { id: playerTypes; name: string; description: any; }[];
  GuestplayerTypes = playerTypes;
  autoCompleteKeys: string[] = ['firstName', 'lastName'];
  selectedChipKey: string[] = ['firstName', 'lastName'];
  searchKey: string[] = ['firstName', 'lastName', 'confirmationNumber'];
  searchList: any[] = [];
  selectedChipData: any;
  searchByCardNo: boolean = false;
  showToggle: boolean = true;
  memberCardNo: string;
  scheduleTime: Date;
  timer = null;
  teeTimeSettingValue: SettingAPI.API.Settings<SettingAPI.API.TeeTimeConfig>;
  @Output() CategoryChange: EventEmitter<any> = new EventEmitter();
  @Output() playerSelect: EventEmitter<any> = new EventEmitter();
  @Output() ChipRemove: EventEmitter<any> = new EventEmitter();
  @Output() CreatePlayer: EventEmitter<any> = new EventEmitter();
  @Output() editGuestEmitter: EventEmitter<any> = new EventEmitter();
  @Output() editPlayerEmitter: EventEmitter<any> = new EventEmitter();
  @Output() updatePlayerEmitter: EventEmitter<any> = new EventEmitter();
  @Input() isViewOnly;
  @Input() disabled;
  showMoreData: boolean;
  teeTimeConfigSettings: TeeTimeData;
  showSeperator = true;
  searchTextinputType: string;
  public cancellationNotifier: Subject<void> = new Subject<void>();
  autoCompleteKeysSeperator = ' | ';
  hotelReservationsTypes: { id: HotelReservationTypes; name: string; description: string; }[];
  selectedHotelReservationType: HotelReservationTypes;
  existingPlayerTypes: { id: ExistingPlayerTypes; name: string; description: string; }[];
  selectedHotelReservationsType = 1;
  selectedExistingPlayerType = 0;
  initiateSearchLength: number;
  selectedGuestType = playerTypes;
  selectedPlayerCategory = 0;
  editGuestInput: AgToggleConfig;
  editPlayerInput: AgToggleConfig;
  updatePlayerProfileInput: AgToggleConfig;
  isExternalGuestSearch: boolean = false;
  showExternalGuestSearch: boolean = false;
  isPlatformGuestSearch: boolean = false;
  showPlatformGuestSearch: boolean = false;
  defaultSettings: any;
  defaultExistingPlayerCategory;
  searchValue: string = "";
  @Input() defaultHotelReservationCategory;
  @Input() isTeeTimeBook;
  @Input('playerSearchInfo')
  set PlayerSearchInfo(value) {
    if (value) {
      this.playerTypeForm = value.form;
      this.controlName = value.controlName;
      this.scheduleTime = value.scheduleDate;
      this.HideShowSearchControl();
    }
  }
  @Input('resetPlayerSearchCategory')
  set resetPlayerSearchCategory(value: boolean) {
    this.isDataSelected = false;
    this.ResetPlatformGuestSearchToDefault();
    this.selectedHotelReservationsType = this.defaultHotelReservationCategory && this.defaultHotelReservationCategory != "" && this.defaultHotelReservationCategory != null ? this.defaultHotelReservationCategory : 0;
    this.selectedExistingPlayerType = this.defaultExistingPlayerCategory && this.defaultExistingPlayerCategory != null && this.defaultExistingPlayerCategory != "" ? this.defaultExistingPlayerCategory : 0;
    this.clearPlayerSearchInput();
    if (this.selectedPlayerCategory === playerTypes.player) {
      var isContainsExternalOptions = this.isExternalGuestSearch && this.existingPlayerTypes.filter(x => x.id === this.defaultExistingPlayerCategory).length > 0 ? true : false;
      this.selectedExistingPlayerType = this.defaultExistingPlayerCategory && this.defaultExistingPlayerCategory != null && this.defaultExistingPlayerCategory != ""
        && !this.isExternalGuestSearch ? this.defaultExistingPlayerCategory :
        (isContainsExternalOptions ? this.existingPlayerTypes.filter(x => x.id === this.defaultExistingPlayerCategory)[0].id : this.existingPlayerTypes[0].id);
      this.ExistingPlayerTypeChange({ value: this.selectedExistingPlayerType });
    }
  }
  @Input('selectedData')
  set selectedChip(value) {
    this.selectedChipData = [...value];
    if (value && value.length > 0) {
      this.isDataSelected = true;
      this.clearPlayerSearchInput();
    }
    this.HideShowSearchControl();
  }
  @Input('teeTimeSetting') set setting(value) {
    if (value) {
      this.teeTimeSettingValue = value;
    }
  };
  @Input('showToggle') set toggleValue(value) {
    this.showToggle = value;
  }
  @Input() excludeItems: playerTypes[];
  pmsSystem: string;
  isWithCGPS: boolean;
  useStayAPI: boolean = false;
  isDataSelected = false;
  constructor(private localization: GolfLocalization, private utils: GolfUtilities, private _memberService: MemberBusinessService,
    private _playerDataService: PlayerDataService, private _agPlayerTypeSearchService: AgPlayerTypeSearchService, private _propertyInformation: GolfPropertyInformation, private dialog: MatDialog
    , private _teeTimeBusiness: TeeTimeBusinessService
    , private _PlayersBusiness: PlayersBusiness) {
    this.captions = this.localization.captions.common;
    this.errorCaption = this.localization.captions.teetime;
    const jsonPropConfig = sessionStorage.getItem('propConfig');
    this.pmsSystem = jsonPropConfig ? JSON.parse(jsonPropConfig)?.PMSSystem : null;
    this.isWithCGPS = this.localization.IsPlatformGuestSearchConfigured();
    this.useStayAPI = jsonPropConfig && JSON.parse(jsonPropConfig)?.UseStayAPI?.toLowerCase() == "true";

  }

  ngOnInit() {
    this.defaultSettings = JSON.parse(sessionStorage.getItem('defaultSettings'));
    this.ResetPlatformGuestSearchToDefault();
    this.editGuestInput = {
      group: this.playerTypeForm,
      horizontal: false,
      label: this.captions.EditGuest,
      formControlName: 'isEditGuest',
      disabled: false
    };
    this.editPlayerInput = {
      group: this.playerTypeForm,
      horizontal: false,
      label: this.captions.EditPlayer,
      formControlName: 'isEditPlayer',
      disabled: false
    };
    this.updatePlayerProfileInput = {
      group: this.playerTypeForm,
      horizontal: false,
      label: this.captions.UpdateInfo,
      formControlName: 'isUpdatePlayerProfile',
      disabled: false
    };
    this.showExternalGuestSearch = this.localization.ShowExternalGuestSearch();
    this.showPlatformGuestSearch = this.localization.IsPlatformGuestSearchConfigured();
    let searchJson = this._agPlayerTypeSearchService.fetchGolfSearchJson();
    this._agPlayerTypeSearchService.golfSearchFilter = searchJson;
    this.playerCategories = this._agPlayerTypeSearchService.getTypesOfPlayers(this.pmsSystem);
    this.hotelReservationsTypes = this._agPlayerTypeSearchService.getTypesOfHotelReservation(this.pmsSystem);
    this.existingPlayerTypes = this.localization.IsExternalGuestSearchConfigured() ? this._agPlayerTypeSearchService.getExistingPlayerTypes(this.pmsSystem) : this._agPlayerTypeSearchService.getExistingPlayerTypes();
    if (this.excludeItems) {
      this.playerCategories = this.playerCategories.filter(item => this.excludeItems.every(id => id !== item.id))
    }
    if (this.localization.IsExternalGuestSearchConfigured()) {
      this.isExternalGuestSearch = true;
    }
    this.getDefaultValuesInGuestSearch();
    this.selectedExistingPlayerType = this.defaultSettings?.defaultExistingPlayerCategory;
    this.defaultExistingPlayerCategory = this.defaultSettings?.defaultExistingPlayerCategory;
  }


  async getDefaultValuesInGuestSearch() {
    this.teeTimeConfigSettings = this._teeTimeBusiness.mapToUI(JSON.parse(sessionStorage.getItem(SettingScreen.TeeTime)));
  }

  playerCategoryChange(arg) {
    this.selectedPlayerCategory = arg && arg.value ? arg.value : 0;
    this.isDataSelected = false;
    this.searchTextinputType = '';
    this.ResetPlatformGuestSearchToDefault();
    this.showSearch = this.ShowUserSearch(arg.value);
    this.setSearchFilter(arg.value, true);
    this.clearPlayerSearchInput();
    this.CategoryChange.emit(arg);
  }
  ResetPlatformGuestSearchToDefault() {
    this.isPlatformGuestSearch = this.defaultSettings?.enableExtentedSearchByDefault && !this.isExternalGuestSearch ? true : false;
  }

  async selectedChipDataEmit(arg) {
    if (arg && arg.length > 0 && this.playerTypeForm.get(this.controlName).value == playerTypes.member) {
      let _memberCardNo = this.searchByCardNo ? this.memberCardNo : arg[0].playerLinkId;
      let referenceNo = arg[0].referenceNoWithOutConcat;
      this.utils.ToggleLoaderWithMessage(true, this.captions.fetchingMemberDetailsMsg);
      this._memberService.getMemberInfo(_memberCardNo, this.localization.convertDateToAPIdate(this.scheduleTime), true, true, false, arg[0].corpId).then(result => {
        this.utils.ToggleLoaderWithMessage(false);
        if (result) {
          // Map corpId, corpName, corpCode
          if (result.corpId == 0 || result.corpName == null || result.corpCode == null) {
            result.corpId = arg[0].corpId ? arg[0].corpId : '';
            result.corpName = arg[0].corpName ? arg[0].corpName : '';
            result.corpCode = arg[0].corpCode ? arg[0].corpCode : '';
          }
          const resArry = [];
          result.referenceNo = referenceNo;
          resArry.push(result);
          this.playerSelect.emit(resArry);
        }
        else {
          this.selectedChipData = [];
        }
      }).catch(error => {
        console.error(error);
        this.utils.ToggleLoaderWithMessage(false);
      });
    }
    else if (arg && arg.length > 0 && this.playerTypeForm.get(this.controlName).value == playerTypes.player && this.isPlatformGuestSearch == true) {
      if (arg[0].platformUuId && arg[0].platformUuId != null && arg[0].platformUuId != "") {
        let profileUIData = await this._PlayersBusiness.GetGuestProfileByPlatformGuid(arg[0].platformUuId)
        if (profileUIData) {
          arg[0].paymentReferenceId = profileUIData.paymentReferenceId;
        }
        this.playerSelect.emit(arg);
      }
      else {
        this.playerSelect.emit(arg);
      }
    }
    else {
      let showPopup = (arg[0]?.playerContactLogs && arg[0]?.playerContactLogs.length > 0);
      if (showPopup) {
        // this.loadPlayerContactLogs(arg[0].playerContactLogs)
      }
      this.playerSelect.emit(arg);
    }
    this.searchValue = "";
    this.isDataSelected = true;
  }

  loadPlayerContactLogs(playerContactLogs) {
    let filteredContactLog: any[] = [];
    playerContactLogs.forEach((contactLog, i) => {
      let productId = parseInt(this.localization.GetPropertyInfo('ProductId'));
      let dueDate = contactLog.dueDate ? this.utils.GetDateWithoutTime(this.utils.getDate(contactLog.dueDate)) : null;
      let expiryDate = contactLog.expiryDate ? this.utils.GetDateWithoutTime(this.utils.getDate(contactLog.expiryDate)) : null;
      let propertyDate = this.utils.GetDateWithoutTime(this._propertyInformation.CurrentDate);
      if (contactLog.isAlert && dueDate && (expiryDate >= propertyDate || expiryDate == null)) {
        if (contactLog.isPrivate && contactLog.productId == productId && dueDate.toDateString() == propertyDate.toDateString()) {//&& contactLog.productId != 1) || !contactLog.isPrivate
          filteredContactLog.push(contactLog);
        }
        else if (!contactLog.isPrivate && dueDate.toDateString() == propertyDate.toDateString()) {
          filteredContactLog.push(contactLog);
        }
      }
      else if (contactLog.isAlert && contactLog.dueDate == null && (expiryDate >= propertyDate || expiryDate == null)) {
        if (contactLog.isPrivate && contactLog.productId == productId) {
          filteredContactLog.push(contactLog);
        }
        else if (!contactLog.isPrivate) {
          filteredContactLog.push(contactLog);
        }
      }
    });
    let isContactLogShow = filteredContactLog.length > 0;
    if (isContactLogShow) {
      let data;
      data = { headername: this.captions.KindInformationAlart, headerIcon: 'icon-info-icon', buttonName: this.localization.captions.common.OK, type: 'CL', isPurged: false, contactLogs: filteredContactLog };
      const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
        width: '60%',
        height: '60%',
        hasBackdrop: true,
        panelClass: 'small-popup',
        data: data,
        disableClose: true,
      });
    }
  }

  onChipRemoveEvent(arg) {
    this.searchList = [];
    this.searchValue = "";
    this.isDataSelected = false;
    this.ChipRemove.emit(arg);
  }
  SetExternalGuestSearch(event: boolean) {
    this.existingPlayerTypes = event ? this._agPlayerTypeSearchService.getExistingPlayerTypes(this.pmsSystem) : this._agPlayerTypeSearchService.getDefaultExistingPlayerTypes();
    this.isExternalGuestSearch = event;
    this.isPlatformGuestSearch = false;
    var isContainsExternalOptions = this.isExternalGuestSearch && this.existingPlayerTypes.filter(x => x.id === this.defaultExistingPlayerCategory).length > 0 ? true : false;
    this.selectedExistingPlayerType = this.defaultExistingPlayerCategory && this.defaultExistingPlayerCategory != null && this.defaultExistingPlayerCategory != ""
      && !this.isExternalGuestSearch ? this.defaultExistingPlayerCategory :
      (isContainsExternalOptions ? this.existingPlayerTypes.filter(x => x.id === this.defaultExistingPlayerCategory)[0].id : this.existingPlayerTypes[0].id);
    this.ExistingPlayerTypeChange({ value: this.selectedExistingPlayerType });
  }
  SetPlatformGuestSearch(event: boolean) {
    this.isPlatformGuestSearch = event;
    this.isExternalGuestSearch = false;
    this.existingPlayerTypes = this._agPlayerTypeSearchService.getDefaultExistingPlayerTypes();
    var isContainsExternalOptions = this.isExternalGuestSearch && this.existingPlayerTypes.filter(x => x.id === this.defaultExistingPlayerCategory).length > 0 ? true : false;
    this.selectedExistingPlayerType = this.defaultExistingPlayerCategory && this.defaultExistingPlayerCategory != null && this.defaultExistingPlayerCategory != ""
      && !this.isExternalGuestSearch ? this.defaultExistingPlayerCategory :
      (isContainsExternalOptions ? this.existingPlayerTypes.filter(x => x.id === this.defaultExistingPlayerCategory)[0].id : this.existingPlayerTypes[0].id);
    this.ExistingPlayerTypeChange({ value: this.selectedExistingPlayerType });
  }
  SearchTextHandler(arg) {
    this.searchList = null;
    if (!arg) {
      this.searchValue = "";
      this.searchList = [];
      return;
    }
    this.searchValue = arg;
    const selectedCategory = this.playerTypeForm.get(this.controlName).value;
    this.showMoreData = false;
    if (this.initiateSearch(arg, selectedCategory)) {
      let searchType;
      this.searchByCardNo = false;
      this.memberCardNo = '';
      switch (selectedCategory) {
        case playerTypes.hotelReservation:
          this.InvokeInHouseSearch(arg);
          return;
        case playerTypes.member:
          searchType = SearchType.Member
          break;
        case playerTypes.player:
          searchType = SearchType.Player;
          break;
        default:
          break;
      }

      if (this.playerTypeForm.get(this.controlName).value == playerTypes.member) {
        this.InvokeMemberSearch(arg, searchType);
        this.showMoreData = true;
      }
      else {
        this.showMoreData = true;
        this.InvokeGuestSearch(arg, searchType);
      }
    }
  }

  initiateSearch(value, selectedCategory) {
    if (selectedCategory == playerTypes.hotelReservation) {
      if (this.selectedHotelReservationsType == HotelReservationTypes.confirmationNumber) {
        return value.length >= this._agPlayerTypeSearchService.getSearchKeyLengthForExternalPMSIntegration(selectedCategory, this.selectedHotelReservationsType, this.pmsSystem);
      }
      else if (this.selectedHotelReservationsType == HotelReservationTypes.roomNumber) {
        return value.length >= this._agPlayerTypeSearchService.getSearchKeyLengthForExternalPMSIntegration(selectedCategory, this.selectedHotelReservationsType, this.pmsSystem);
      }
      else {
        return value.length > 2;
      }
    }
    else {
      return value.length === 0 || value.length > 2;
    }
  }

  private InvokeGuestSearch(arg, searchType) {
    if (this.timer) {
      clearTimeout(this.timer); //cancel the previous timer.
      this.timer = null;
    }
    this.CancelPreviousSearchCalls();
    this.timer = setTimeout(this.GuestSearch.bind(this, [arg, searchType]), 1000);
  }

  private GuestSearch(arg) {
    let GuestDetails = [];
    this.autoCompleteKeys = ['formattedPronounced', 'name', 'formattedDateOfBirth', 'formattedPatronId', 'formattedRank'
      , 'phone', 'Cell', 'Home', 'Work', 'BusinessPhone', 'Workphone', 'email', 'Personal', 'Office', 'HomeEmail', 'BusinessEmail', 'Address', 'city', 'state', 'country', 'zip'];
    this.getSearchData(arg);
  }

  private InvokeMemberSearch(arg, searchType) {
    if (this.timer) {
      clearTimeout(this.timer); //cancel the previous timer.
      this.timer = null;
    }
    this.timer = setTimeout(this.MemberSearch.bind(this, [arg, searchType]), 1000);
  }

  private MemberSearch(arg) {
    if (arg) {
      this._memberService.searchPlayer(arg[0], arg[1]).then(result => {
        this.autoCompleteKeys = ['name', 'phone', 'Cell', 'Home', 'Work', 'email', 'Personal', 'Office', 'memberNumber', 'referenceNo', 'corpName'];
        const formattedData = this.formatSearchResult(result);
        this.searchList = formattedData;
      });
    }
  }
  private InvokeMemberInfo(arg) {
    if (this.timer) {
      clearTimeout(this.timer); //cancel the previous timer.
      this.timer = null;
    }
    this.timer = setTimeout(this.MemberInfo.bind(this, [arg]), 1000);
  }

  private MemberInfo(arg) {
    this._memberService.getMemberInfo(arg, this.localization.convertDateToAPIdate(this.scheduleTime)).then(result => {
      if (result) {
        this.autoCompleteKeys = ['name', 'phone', 'Cell', 'Home', 'Work', 'email', 'Personal', 'Office', 'memberNumber', 'referenceNo', 'corpName'];
        const formattedData = this.formatSearchResult(result);
        this.searchList = formattedData;
      }
      else {
        this.searchList = [];
        this.clearPlayerSearchInput();
      }
    });
  }

  private CancelPreviousSearchCalls() {
    if (this.cancellationNotifier) {
      this.cancellationNotifier.next();
      this.cancellationNotifier.complete();
      this.cancellationNotifier.unsubscribe();
    }
    this.cancellationNotifier = new Subject<void>();
  }
  private InvokeInHouseSearch(arg) {
    if (this.timer) {
      clearTimeout(this.timer); //cancel the previous timer.
      this.timer = null;
    }
    this.CancelPreviousSearchCalls();
    this.showMoreData = true
    this.timer = setTimeout(this.InHouseSearch.bind(this, [arg]), 1000);
  }

  private InHouseSearch(arg) {
    this.autoCompleteKeys = [];

    let searchFilter = this.selectedHotelReservationsType;

    this.autoCompleteKeys = ['formattedPronounced', 'name', 'formattedPmsStayConfirmationNumber', 'formattedDateOfBirth', 'formattedPatronId', 'formattedRank'
      , 'phone', 'Cell', 'Home', 'Work', 'email', 'Personal', 'Office', 'Address', 'city', 'state', 'country', 'zip', 'packageCode', 'roomNumber', 'linkId', 'formattedArrivalDate', 'formattedDepartureDate'];
    if (this.selectedHotelReservationsType === HotelReservationTypes.roomNumber || this.selectedHotelReservationsType === HotelReservationTypes.linkCode || arg[0].length > 2) {
      //const pmsSystem = sessionStorage.getItem('pmsSystem');
      const jsonPropConfig = sessionStorage.getItem('propConfig');
      const pmsSystem = jsonPropConfig ? JSON.parse(jsonPropConfig)?.PMSSystem : null;
      if (pmsSystem && pmsSystem.toLowerCase() == "stay" && (this.useStayAPI || !this.isWithCGPS)) {
        this._agPlayerTypeSearchService.fetchInStayPlayers(arg[0], this.scheduleTime, searchFilter, this.cancellationNotifier).then(result => {
          if (result && result.length === 0) {
            this.searchList = [];
          } else {
            const formattedData = this.formatSearchResult(result);
            this.searchList = formattedData;
          }
        });
      }
      else {
        this._agPlayerTypeSearchService.fetchInHousePlayers(arg[0], this.scheduleTime, searchFilter, this.cancellationNotifier).then(result => {
          if (result && result.length === 0) {
            this.searchList = [];
          } else {
            const formattedData = this.formatSearchResult(result);
            this.searchList = formattedData;
          }
        });
      }
    }
  }

  private HideShowSearchControl() {
    const ctrl = this.playerTypeForm && this.playerTypeForm.get(this.controlName);
    if (ctrl) {
      this.showSearch = this.ShowUserSearch(ctrl.value);
      this.setSearchFilter(ctrl.value, false);
    }
  }

  private ShowUserSearch(type): boolean {
    let flag = false;
    switch (type) {
      case playerTypes.hotelReservation:
      case playerTypes.member:
      case playerTypes.player:
        flag = true;
        break;
      default:
        flag = false;
        break;
    }
    return flag;
  }

  private setSearchFilter(selectedType, fromPlayerCategoryCahnge) {
    switch (selectedType) {
      case playerTypes.hotelReservation:
        this.autoCompleteKeys = ['name', 'formattedPmsStayConfirmationNumber'];
        this.selectedChipKey = ['firstName', 'lastName'];
        this.searchKey = ['firstName', 'lastName', 'pmsStayConfirmationNumber'];
        this.setPlaceHolder();
        break;
      case playerTypes.member:
        this.autoCompleteKeys = ['name'];
        this.selectedChipKey = ['firstName', 'lastName'];
        this.searchKey = ['firstName', 'lastName'];
        this.initiateSearchLength = InitiateSearch.default;
        this.searchPlaceholder = this.captions.searchByMemberNoAndMemberName;
        break;
      case playerTypes.player:
        this.autoCompleteKeys = ['name', 'formattedPatronId'];
        this.selectedChipKey = ['firstName', 'lastName'];
        this.searchKey = ['firstName', 'lastName'];
        this.initiateSearchLength = InitiateSearch.default;
        // this.searchPlaceholder = this.captions.searchByPlayerName;
        this.setPlaceHolderForExistingPlayer();
        if (fromPlayerCategoryCahnge)
          this.SetExternalGuestSearch(this.localization.IsExternalGuestSearchConfigured());
        break;
      default:
        break;
    }
    this.showSeperator = false;
  }

  getSearchData(arg) {
    if (arg) {
      let searchValue = arg[0];

      let searchFilter = this.selectedExistingPlayerType;
      let isTeeTimeBook: boolean = true;
      this._playerDataService.getExistingGuestsInfo(searchValue, searchFilter, this.isExternalGuestSearch, isTeeTimeBook, this.isPlatformGuestSearch, this.cancellationNotifier).then(result => {
        const formattedData = this.formatSearchResult(result);
        this.searchList = formattedData;
      });
    }
  }

  populateSearchDetails() {
    let searchValue: string = this.searchValue.trim().replace(',', ' ');
    if (this.playerTypeForm.get(this.controlName).value == playerTypes.player && searchValue && searchValue.length > 0) {
      switch (this.selectedExistingPlayerType) {
        case ExistingPlayerTypes.firstName:
          return { firstName: searchValue.slice(0, 256) };
        case ExistingPlayerTypes.lastName:
          return { lastName: searchValue.slice(0, 256) };
        case ExistingPlayerTypes.fullName:
          let name = searchValue.split(/\s+/);
          let nameLength = name.length;
          let data: any = {};

          if (nameLength == 1) {
            data.firstName = name[0].trim().slice(0, 256);
          }
          else {
            data.lastName = name[nameLength - 1].trim().slice(0, 256);
            name.pop();
            data.firstName = name.join(" ").slice(0, 256);
          }
          return data;
        case ExistingPlayerTypes.phoneNumber:
          let phoneArray: PlayerProfileUI.Phone[] = [];
          let phone = searchValue.split("|");
          if (phone.length > 2 || !(/^\d+$/.test(phone[0])) || (phone.length == 2 && !(/^\d+$/.test(phone[1])))) {
            return '';
          }
          phoneArray.push({
            id: 0,
            contactType: ContactType.phone,
            phoneType: this?.defaultSettings && this.defaultSettings?.defaultPhoneTypeOption ? this?.defaultSettings?.defaultPhoneTypeOption : PhoneTypes.mobile,
            phoneLabel: '',
            countryCode: phone.length == 2 ? phone[0].slice(0, 3) : (this?.defaultSettings && this.defaultSettings?.defaultCountryCode ? this?.defaultSettings?.defaultCountryCode : ''),
            phoneNumber: phone.length == 2 ? phone[1].slice(0, 10) : phone[0].slice(0, 10),
            extension: '',
            privateInformtionPhone: false,
            primaryPhone: false,
            platformContactUuid: ''
          });

          return { phone: phoneArray };
        case ExistingPlayerTypes.email:
          let emailArray: PlayerProfileUI.Email[] = [];
          emailArray.push({
            id: 0,
            contactType: ContactType.email,
            emailType: (this?.defaultSettings && this.defaultSettings?.defaultEmailTypeOption) ? this.defaultSettings.defaultEmailTypeOption : MailTypes.personal,
            emailLabel: '',
            emailId: searchValue.slice(0, 75),
            privateInformtionEmail: false,
            primaryEmail: false,
            platformContactUuid: ''
          });

          return { email: emailArray };

      }
    }
    return '';
  }

  formatSearchResult(result) {
    let GuestDetails = [];
    this.showSeperator = false;
    if (!this.teeTimeConfigSettings)
      this.getDefaultValuesInGuestSearch();
    var defaultValuesToShowInSearch = this.teeTimeConfigSettings.defaultValues;
    result.forEach(element => {
      let loyaltyDetailChecker = (element['loyaltyDetail'] && element['loyaltyDetail'][0] ? this.autoCompleteKeysSeperator + this.localization.captions.guest.players.PatronId + ': ' + element['loyaltyDetail'][0].patronId : '');
      const patronId = defaultValuesToShowInSearch.patronId ? loyaltyDetailChecker : '';
      const memberNumber = element.playerLinkId ? this.autoCompleteKeysSeperator + this.localization.captions.guest.players.MemberNumber + ': ' + element.playerLinkId : '';
      const referenceNumber = element.playerLinkId ? this.autoCompleteKeysSeperator + this.localization.captions.guest.players.ReferenceNumber + ': ' + element.referenceNo : '';
      let dobChecker = (element.dateOfBirth ? this.autoCompleteKeysSeperator + this.localization.captions.guest.players.DOB + ': ' + this.localization.localizeDisplayDate(element.dateOfBirth) : '');
      const dateOfBirth = defaultValuesToShowInSearch.dateOfBirth ? dobChecker : '';
      let pronouncedChecker = (element.pronounced ? this.autoCompleteKeysSeperator + this.localization.captions.guest.players.pronounced + ': ' + element.pronounced : '');
      const pronounced = defaultValuesToShowInSearch.pronounced ? pronouncedChecker : '';
      const name = (element.firstName || element.lastName ? this.utils.formatGuestName(element.firstName, element.lastName) + '' : '');
      let rankChecker = (element['loyaltyDetail'] && element['loyaltyDetail'][0] ? this.autoCompleteKeysSeperator + this.localization.captions.guest.players.Rank + ': ' + element['loyaltyDetail'][0].rank : '');
      const rank = defaultValuesToShowInSearch.rank ? rankChecker : '';
      let packageNameChecker = (element.packageName ? this.autoCompleteKeysSeperator + this.localization.captions.guest.players.PackageCode + ': ' + element.packageName : '');
      const packageCode = defaultValuesToShowInSearch.packageCode ? packageNameChecker : '';
      let roomNumberChecker = (element.roomNumber ? this.autoCompleteKeysSeperator + this.localization.captions.guest.players.RoomNumber + ': ' + element.roomNumber : '');
      const roomNumber = defaultValuesToShowInSearch.roomNumber ? roomNumberChecker : '';
      const confirmationNumber = (element.pmsStayConfirmationNumber ? this.autoCompleteKeysSeperator + this.localization.captions.guest.players.ConfirmationNumber + ': ' + element.pmsStayConfirmationNumber : '');
      const corpName = (element.corpName && element.corpName.length > 0) ? this.autoCompleteKeysSeperator + this.localization.captions.guest.players.CorpName + ': ' + element.corpName : '';

      let Cell = '';
      let Home = '';
      let Work = '';
      let BusinessPhone = '';
      let WorkPhone = '';
      let Personal = '';
      let Office = '';
      let HomeEmail = '';
      let BusinessEmail = '';
      let corpId = 0;
      let primarycell = element.contactInformation ? element.contactInformation.some(info => info.type === PhoneTypes.mobile && info.isPrimary) && defaultValuesToShowInSearch.primaryPhone : false;
      let primaryhome = element.contactInformation ? element.contactInformation.some(info => info.type === PhoneTypes.home && info.isPrimary) && defaultValuesToShowInSearch.primaryPhone : false;
      let primarywork = element.contactInformation ? element.contactInformation.some(info => info.type === PhoneTypes.office && info.isPrimary) && defaultValuesToShowInSearch.primaryPhone : false;
      let primarybusinessPhone = element.contactInformation ? element.contactInformation.some(info => info.type === PhoneTypes.business && info.isPrimary) && defaultValuesToShowInSearch.primaryPhone : false;
      let primaryworkPhone = element.contactInformation ? element.contactInformation.some(info => info.type === PhoneTypes.work && info.isPrimary) && defaultValuesToShowInSearch.primaryPhone : false;
      let primaryPersonal = element.contactInformation ? element.contactInformation.some(info => info.type === MailTypes.personal && info.isPrimary) && defaultValuesToShowInSearch.primaryEmail : false;
      let primayOffice = element.contactInformation ? element.contactInformation.some(info => info.type === MailTypes.office && info.isPrimary) && defaultValuesToShowInSearch.primaryEmail : false;
      let primaryhomeEmail = element.contactInformation ? element.contactInformation.some(info => info.type === MailTypes.home && info.isPrimary) && defaultValuesToShowInSearch.primaryPhone : false;
      let primarybusinessEmail = element.contactInformation ? element.contactInformation.some(info => info.type === MailTypes.business && info.isPrimary) && defaultValuesToShowInSearch.primaryPhone : false;
      let phoneTemp = element.contactInformation ? element.contactInformation.some(info => (defaultValuesToShowInSearch.cell && info.type === PhoneTypes.mobile) || (defaultValuesToShowInSearch.home && info.type === PhoneTypes.home) || (defaultValuesToShowInSearch.work && info.type === PhoneTypes.office) || (defaultValuesToShowInSearch.businessPhone && info.type == PhoneTypes.business) || (defaultValuesToShowInSearch.workPhone && info.type == PhoneTypes.work) || (primarycell || primaryhome || primarywork || primarybusinessPhone || primaryworkPhone) && info.value) ? this.autoCompleteKeysSeperator + this.localization.captions.guest.players.phone + ': ' : '' : null;
      const Phone = element.contactInformation ? phoneTemp : '';
      let emailTemp = element.contactInformation ? element.contactInformation.some(info => (defaultValuesToShowInSearch.personal && info.type === MailTypes.personal) || (defaultValuesToShowInSearch.office && info.type === MailTypes.office) || (defaultValuesToShowInSearch.homeEmail && info.type == MailTypes.home) || (defaultValuesToShowInSearch.businessEmail && info.ty == MailTypes.business) || (primaryPersonal || primayOffice || primaryhomeEmail || primarybusinessEmail) && info.value) ? this.autoCompleteKeysSeperator + this.localization.captions.guest.players.email + ': ' : '' : null;
      const Email = element.contactInformation ? emailTemp : '';
      corpId = element.corpId && element.corpId > 0 ? element.corpId : 0;

      let Address = (defaultValuesToShowInSearch.address) && (element['playerAddress'] && (element['playerAddress'].addressLine1
        || element['playerAddress'].addressLine2 || element['playerAddress'].addressLine3)) ? this.localization.captions.guest.players.address
      + ': ' : '';
      let tempAdd1 = (element['playerAddress'] && element['playerAddress'].addressLine1 ? element['playerAddress'].addressLine1 : '');
      const addressLine1 = (defaultValuesToShowInSearch.address) ? tempAdd1 : '';
      let tempAdd2 = (element['playerAddress'] && element['playerAddress'].addressLine2 ? ' ' + element['playerAddress'].addressLine2 : '');
      const addressLine2 = (defaultValuesToShowInSearch.address) ? tempAdd2 : '';
      let tempAdd3 = (element['playerAddress'] && element['playerAddress'].addressLine3 ? ' ' + element['playerAddress'].addressLine3 : '');
      const addressLine3 = (defaultValuesToShowInSearch.address) ? tempAdd3 : '';
      Address = Address + addressLine1 + addressLine2 + addressLine3;
      let cityChecker = (element['playerAddress'] && element['playerAddress'].city ? this.localization.captions.guest.players.city + ': ' + element['playerAddress'].city : '');
      const city = (defaultValuesToShowInSearch.city) ? cityChecker : '';
      let countryChecker = (element['playerAddress'] && element['playerAddress'].country ? this.localization.captions.guest.players.country + ': ' + element['playerAddress'].country : '');
      const country = (defaultValuesToShowInSearch.country) ? countryChecker : '';
      let stateChecker = (element['playerAddress'] && element['playerAddress'].state ? this.localization.captions.guest.players.state + ': ' + element['playerAddress'].state : '');
      const state = (defaultValuesToShowInSearch.state) ? stateChecker : '';
      let zipChecker = (element['playerAddress'] && element['playerAddress'].zip ? this.localization.captions.guest.players.postalCode + ': ' + element['playerAddress'].zip : '');
      const zip = (defaultValuesToShowInSearch.postelCode) ? zipChecker : '';
      let extProfileId = element.extProfileId ? element.extProfileId : '';
      let platformUuId = element.platformUuId ? element.platformUuId : null;
      let arrivalDate = (element.arrivalDate ? this.autoCompleteKeysSeperator + this.localization.captions.guest.players.arrivalDate + ': ' + this.localization.localizeDisplayDate(element.arrivalDate) : '');
      let departureDate = (element.departureDate ? this.autoCompleteKeysSeperator + this.localization.captions.guest.players.departureDate + ': ' + this.localization.localizeDisplayDate(element.departureDate) : '');
      const formattedArrivalDate = defaultValuesToShowInSearch.stayDates ? arrivalDate : '';
      const formattedDepartureDate = defaultValuesToShowInSearch.stayDates ? departureDate : '';
      if (element.contactInformation) {
        element.contactInformation.forEach(details => {
          let detailChecker = (details.value ? details.value : '');
          switch (details.type) {
            case PhoneTypes.mobile: {
              Cell = defaultValuesToShowInSearch.cell || (defaultValuesToShowInSearch.primaryPhone && primarycell) ? (this.localization.captions.contactTypes.cell + ': ' + detailChecker) : '';
              break;
            }
            case PhoneTypes.home: {
              let cellChecker = (Cell ? this.autoCompleteKeysSeperator : '');
              Home = defaultValuesToShowInSearch.home || (defaultValuesToShowInSearch.primaryPhone && primaryhome) ? (cellChecker + this.localization.captions.contactTypes.home + ': ' + detailChecker) : '';
              break;
            }
            case PhoneTypes.office: {
              let cellOrHomeChecker = (Cell || Home ? this.autoCompleteKeysSeperator : '');
              Work = defaultValuesToShowInSearch.work || (defaultValuesToShowInSearch.primaryPhone && primarywork) ? (cellOrHomeChecker + this.localization.captions.contactTypes.work + ': ' + detailChecker) : '';
              break;
            }
            case PhoneTypes.business: {
              let cellOrHomeorOfficeChecker = (Cell || Home || Work ? this.autoCompleteKeysSeperator : '');
              BusinessPhone = defaultValuesToShowInSearch.businessPhone || (defaultValuesToShowInSearch.primaryPhone && primarybusinessPhone) ? (cellOrHomeorOfficeChecker + this.localization.captions.contactTypes.businessPhone + ': ' + detailChecker) : '';
              break;
            }
            case PhoneTypes.work: {
              let cellOrHomeorOfficeorbusinessChecker = (Cell || Home || Work || BusinessPhone ? this.autoCompleteKeysSeperator : '');
              WorkPhone = defaultValuesToShowInSearch.workPhone || (defaultValuesToShowInSearch.primaryPhone && primaryworkPhone) ? (cellOrHomeorOfficeorbusinessChecker + this.localization.captions.contactTypes.workPhone + ': ' + detailChecker) : '';
              break;
            }
            case MailTypes.personal: {
              let checker = (Personal ? this.autoCompleteKeysSeperator : '');
              Personal += defaultValuesToShowInSearch.personal || (defaultValuesToShowInSearch.primaryEmail && primaryPersonal) ? (checker + this.localization.captions.contactTypes.personal + ': ' + detailChecker) : '';
              break;
            }
            case MailTypes.office: {
              let personalChecker = ((Personal || Office) ? this.autoCompleteKeysSeperator : '');
              Office += defaultValuesToShowInSearch.office || (defaultValuesToShowInSearch.primaryEmail && primayOffice) ? (personalChecker + this.localization.captions.contactTypes.office + ': ' + detailChecker) : '';
              break;
            }
            case MailTypes.home: {
              let mailChecker = ((Personal || Office || HomeEmail) ? this.autoCompleteKeysSeperator : '');
              HomeEmail += defaultValuesToShowInSearch.homeEmail || (defaultValuesToShowInSearch.primaryEmail && primaryhomeEmail) ? (mailChecker + this.localization.captions.contactTypes.homeEmail + ': ' + detailChecker) : '';
              break;
            }
            case MailTypes.business: {
              let mailChecker = ((Personal || Office || HomeEmail || BusinessEmail) ? this.autoCompleteKeysSeperator : '');
              BusinessEmail += defaultValuesToShowInSearch.businessEmail || (defaultValuesToShowInSearch.primaryEmail && primarybusinessEmail) ? (mailChecker + this.localization.captions.contactTypes.businessEmail + ': ' + detailChecker) : '';
              break;
            }
          }
        });
      }
      GuestDetails.push({
        name: name,
        firstName: element.firstName,
        lastName: element.lastName,
        pronounced: element.pronounced,
        patronId: element.patronId,
        rank: element.rank,
        pmsStayConfirmationNumber: element.pmsStayConfirmationNumber,
        dateOfBirth: element.dateOfBirth,
        formattedPatronId: patronId,
        formattedDateOfBirth: dateOfBirth,
        formattedRank: rank,
        formattedPronounced: pronounced,
        phone: Phone,
        email: Email,
        Cell: Cell,
        Home: Home,
        Work: Work,
        BusinessPhone: BusinessPhone,
        WorkPhone: WorkPhone,
        Personal: Personal,
        Office: Office,
        HomeEmail: HomeEmail,
        BusinessEmail: BusinessEmail,
        Address: Address,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        addressLine3: addressLine3,
        city: city,
        country: country,
        state: state,
        zip: zip,
        packageCode: packageCode,
        packageName: element.packageName,
        roomNumber: roomNumber,
        bagNumber: element.bagNumber,
        confirmationNumber: element.confirmationNumber,
        formattedPmsStayConfirmationNumber: confirmationNumber,
        contactInformation: element.contactInformation,
        customField1: element.customField1,
        customField2: element.customField2,
        customField3: element.customField3,
        customField4: element.customField4,
        customField5: element.customField5,
        loyaltyDetail: element['loyaltyDetail'],
        gender: element.gender,
        id: element.id,
        imageReferenceId: element.imageReferenceId,
        paymentReferenceId: element.paymentReferenceId,
        playerAddress: element.playerAddress,
        playerCategoryId: element.playerCategoryId,
        playerLinkId: element.playerLinkId,
        rateType: element.rateType,
        playerType: element.playerType,
        vipType: element.vipType,
        memberNumber: memberNumber,
        referenceNo: referenceNumber,
        referenceNoWithOutConcat: element.referenceNo,
        playerStayDetail: element.playerStayDetail,
        extProfileId: extProfileId,
        platformUuId: platformUuId,
        playerContactLogs: element.playerContactLogs,
        formattedArrivalDate: formattedArrivalDate,
        formattedDepartureDate: formattedDepartureDate,
        corpId: corpId,
        corpName: corpName
      });
    });
    GuestDetails.sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase(), "de", { sensitivity: "base" }));
    return GuestDetails;
  }

  ngOnDestroy(): void {
    this.CancelPreviousSearchCalls();
  }

  HotelReservationTypeChange(event) {
    this.selectedHotelReservationsType = event.value;
    this.clearPlayerSearchInput();
    this.setPlaceHolder();
  }

  private setPlaceHolder() {
    this.initiateSearchLength = this._agPlayerTypeSearchService.getSearchKeyLengthForExternalPMSIntegration(playerTypes.hotelReservation, this.selectedHotelReservationsType, this.pmsSystem);
    switch (this.selectedHotelReservationsType) {
      case HotelReservationTypes.confirmationNumber:
        this.searchPlaceholder = this.errorCaption.searchByHotelReservationNo;
        break;
      case HotelReservationTypes.roomNumber:
        this.searchPlaceholder = this.errorCaption.searchByRoomNo;
        break;
      case HotelReservationTypes.guestName:
        this.searchPlaceholder = this.errorCaption.searchByGuestName;
        break;
      case HotelReservationTypes.linkCode:
        this.searchPlaceholder = this.errorCaption.searchByLinkId;
        break;
      case HotelReservationTypes.contactDetail:
        this.searchPlaceholder = this.errorCaption.searchByGuestContact;
        break;
    }
  }

  CreatePlayerPopup() {
    let data = this.populateSearchDetails();
    this.CreatePlayer.emit(data);
  }

  ExistingPlayerTypeChange(event) {
    this.selectedExistingPlayerType = event.value;
    this.clearPlayerSearchInput();
    this.setPlaceHolderForExistingPlayer();
    if (this.selectedExistingPlayerType == ExistingPlayerTypes.phoneNumber) {
      this.searchTextinputType = 'onlynumber,nonnegative,nodecimal';
    } else {
      this.searchTextinputType = '';
    }
  }

  private setPlaceHolderForExistingPlayer() {
    switch (this.selectedExistingPlayerType) {
      case ExistingPlayerTypes.firstName:
        this.searchPlaceholder = this.errorCaption.searchByFirstName;
        break;
      case ExistingPlayerTypes.lastName:
        this.searchPlaceholder = this.errorCaption.searchByLastName;
        break;
      case ExistingPlayerTypes.email:
        this.searchPlaceholder = this.errorCaption.searchByEmailAddress;
        break;
      case ExistingPlayerTypes.phoneNumber:
        this.searchPlaceholder = this.errorCaption.searchByPhoneNumber;
        break;
      case ExistingPlayerTypes.fullName:
        this.searchPlaceholder = this.errorCaption.searchBy + ' ' + this.localization.captions.settings.FirstName + ' ' + this.localization.captions.settings.LastName;
        break;
    }
  }
  private clearPlayerSearchInput() {
    if (this.chipSearch && this.chipSearch.chipCtrl) {
      this.searchValue = "";
      this.chipSearch.chipCtrl.setValue(null);
      this.chipSearch.chipInput.nativeElement.value = '';
      this.chipSearch.filteredData = [];
    }
  }

  editGuest(eve) {
    this.editGuestEmitter.emit(eve);
  }

  updatePlayerProfile(eve) {
    this.updatePlayerEmitter.emit(eve);
  }

  editPlayer(eve) {
    this.editPlayerEmitter.emit(eve);
  }

}
