import { Injectable } from '@angular/core';
import { HistoryService } from './history.service';
import { PlayerProfileDataService } from 'src/app/shared/data-services/golfmanagement/PlayerProfile.data.services';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import {  PlayerActivities } from 'src/app/shared/models/player.model';
import { Observable, from, forkJoin } from 'rxjs';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { HISTORY_DETAILS_DAYS_RANGE } from 'src/app/shared/global.constant';
import { Transaction } from 'src/app/common/shared/shared/business/shared.modals'
import { MultpackHistoryRequest,MultipackAPIModel,MultipackUIModel, RentalsHistoryUIModel } from './history.model';
import { DefaultGUID } from 'src/app/common/shared/shared/globalsContant';
import { SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import moment from 'moment';
import { RetailItemType } from 'src/app/retail/retail.modals';


@Injectable()
export class HistoryBusiness {

  salesHistory = [];
  clientAppointments: PlayerActivities[];
  allTherapists: any = [];
  allServices: any = [];
  frequentlyPurchasedItem: any[]=[];
  captions: any;

  constructor(private _HistoryService: HistoryService, private _playerProfileDataService: PlayerProfileDataService,
    public localization: GolfLocalization, public _utilities: GolfUtilities, private _propertyInformation: GolfPropertyInformation) {
      this.captions = this.localization.captions
     }

  GetCaptions() {
    return this._HistoryService.getCaptions();
  }

  async InitializeData(playerGuid:string)
  {      
      if(playerGuid && playerGuid != DefaultGUID)
      {
        var result :Observable<any>[] =[];
        result.push( from (this.GetSalesHistoryTransactionByGuestGuids(playerGuid)));
        result.push( from ( this.GetAppointmentsByStatus(playerGuid)));      
        forkJoin(result);      
     }
  }


public async GetAppointmentsByStatus(clientId: string) {
  const startDate :Date = this._propertyInformation.CurrentDTTM.addDays(-HISTORY_DETAILS_DAYS_RANGE);
  const endDate :Date = this._propertyInformation.CurrentDTTM.addDays(HISTORY_DETAILS_DAYS_RANGE);
  var result:any= await this._playerProfileDataService.GetAppointmentsByStatus( clientId,startDate,endDate);
  let appointments = [];
  if(result.length>0){
    for (let index = 0; index < result.length; index++) {
      let detail = result[index];
      let therapist = result[index].appointmentTherapists;
      let therapistNames: string[] = [];
      if(therapist){
        therapist.forEach(therap => {
          therapistNames.push(this.getTherapistName(therap.therapistId))
        });
      }
      let appTime: string = this.localization.LocalizeShortDateTime(detail.startDateTime);
      let appointment = {
        "id": detail.id,
        "appointmentTime": appTime,
        "service":detail.packageName?detail.packageName:detail.description,
        "therapist": detail.therapistName,
        "cancelComments": detail.cancelComments,
        "status": detail.status,
        "cancelId": detail.cancelId,
        "transaction": detail.transcation,
        "activityType": detail.activityType,
        "activityDetailDescription": detail.activityDetailDescription
      }
      appointments.push(appointment);
    }
  }
    this.clientAppointments = appointments;
  }

  public async GetGuestSalesHistoryTransaction(clientId: string) {
    let appointments = [];
    var result = await this._playerProfileDataService.GetGuestSalesHistoryTransaction( clientId);
    let res:any = result;
    let responseResult: any = res;
    let transactionDetail:any;
    let items :any[]=[];
    for (let index1 = 0; index1 < responseResult.length; index1++) {
      let transactionNumber = responseResult[index1].transaction ;
      let TransactionDate = responseResult[index1].date ;
      let TotalPrice = Number(responseResult[index1].grandTotal).customToFixed();
      let TotalAmount = Number(responseResult[index1].subTotal).customToFixed();
      let totalGratuity = (responseResult[index1].gratuity);
      let TotalTax = Number(responseResult[index1].tax).customToFixed();
      let totalDiscount = Number(responseResult[index1].discount).customToFixed();
       let itemDescription;
       let itemId ;
      for(let index2 = 0; index2 < responseResult[index1].transactionDetails.items.length;index2++)
     {
       let QuantitySold = Number(responseResult[index1].transactionDetails.items[index2].quantity);
       let unitPrice = Number(responseResult[index1].transactionDetails.items[index2].price).customToFixed();
       itemDescription = responseResult[index1].transactionDetails.items[index2].name;
       itemId =  responseResult[index1].transactionDetails.items[index2].itemId;
       items.push({ "name": itemDescription, "quantity": QuantitySold, "price": unitPrice});
       var indexOfItem = this.frequentlyPurchasedItem.findIndex(i=>i.itemNumber== itemId);
       if(indexOfItem>-1)
       {
        this.frequentlyPurchasedItem[indexOfItem].quantity = Number(this.frequentlyPurchasedItem[indexOfItem].quantity) + Number(QuantitySold);
       }
       else
       {
        this.frequentlyPurchasedItem.push({ "description": itemDescription, "quantity": QuantitySold, "itemNumber": itemId})
       }

       transactionDetail = {
         items: items
      }
      }
      
      let guestHistory = {
      "date": this.localization.localizeDisplayDate(TransactionDate),
      "transaction": transactionNumber,
      "grandTotal": TotalPrice,
      "subTotal": TotalAmount,
      "tax": TotalTax,
      "gratuity":totalGratuity,
      "discount": totalDiscount,
      "transactionDetails":transactionDetail
     }

     appointments.push(guestHistory);
     items = [];
    }
    this.frequentlyPurchasedItem = this.frequentlyPurchasedItem.sort(this.compareItem).reverse();
    this.salesHistory = appointments;
    }
    public async GetSalesHistoryTransactionByGuestGuids(clientId: string) {
      let appointments = [];
      let arrClientds : string [] = [clientId];
      var result : Transaction[] = await this._playerProfileDataService.GetSalesHistoryTransactionByGuestGuids(arrClientds);
      let responseResult: Transaction[] = [...result];
      let transactionDetail:any;
      let items :any[]=[];
      for (let result of responseResult) {
        let transactionNumber = result.transactionData.ticketNumber ;
        let TransactionDate = result.transactionData.transactionDate ;
        let TotalPrice = Number(result.transactionData.totalPrice).customToFixed();
        let TotalAmount = Number(result.transactionData.totalAmount).customToFixed();
        let totalGratuity = (result.transactionData.gratuity);
        let TotalTax = Number(result.transactionData.totalTax).customToFixed();
        let totalDiscount = Number(result.transactionData.discount).customToFixed();
         let itemDescription;
         let itemId ;
         for (let TransDetails of result.transactionDetails)
       {
         let QuantitySold = Number(TransDetails.quantitySold);
         let unitPrice = Number(TransDetails.unitPrice).customToFixed();
         itemDescription = TransDetails.itemDescription;
         itemId =  TransDetails.itemId;
         items.push({ "name": itemDescription, "quantity": QuantitySold, "price": unitPrice});
         var indexOfItem = this.frequentlyPurchasedItem.findIndex(i=>i.itemNumber== itemId);
         if(indexOfItem>-1)
         {
          this.frequentlyPurchasedItem[indexOfItem].quantity = Number(this.frequentlyPurchasedItem[indexOfItem].quantity) + Number(QuantitySold);
         }
         else
         {
          this.frequentlyPurchasedItem.push({ "description": itemDescription, "quantity": QuantitySold, "itemNumber": itemId})
         }
  
         transactionDetail = {
           items: items
        }
        }
        
        let guestHistory = {
        "date": this.localization.localizeDisplayDate(TransactionDate),
        "transaction": transactionNumber,
        "grandTotal": TotalPrice,
        "subTotal": TotalAmount,
        "tax": TotalTax,
        "gratuity":totalGratuity,
        "discount": totalDiscount,
        "transactionDetails":transactionDetail
       }
  
       appointments.push(guestHistory);
       items = [];
      }
      this.frequentlyPurchasedItem = this.frequentlyPurchasedItem.sort(this.compareItem).reverse();
      this.salesHistory = appointments;
      }
    compareItem(a, b) {
      const item1 = a.quantity;
      const item2 = b.quantity;
    
      let comparison = 0;
      if (item1 > item2) {
        comparison = 1;
      } else if (item1 < item2) {
        comparison = -1;
      }
      return comparison;
    }

    getServiceName(serviceId: any) {     
      let services = this.allServices;
      let service = services.find(x => x.id == serviceId);
      return service ? service.description : ''
    }
    getTherapistName(therapistId: any) {    
      let therapists = this.allTherapists;
      let therapist = therapists.find(x => x.id == therapistId);
      return therapist ? `${therapist.firstName} ${therapist.lastName}` : ''
    }


GetHistoryTypes() {
    return this._HistoryService.getHistoryTypes();
  }
  GetFrequentlyPurchasedData() {
    return this.frequentlyPurchasedItem;
  }
  GetSalesHistory() {
    return this.salesHistory;
  }

  GetAppointments() : PlayerActivities[]
  {
    return this.clientAppointments;
  }

  public async GetMultipackDetails(clientId: string) {
    const request =<MultpackHistoryRequest>{
      "GuestGuid": clientId,
      "IsIncludeExpiredMultipacks":true
    }
    
    var result : MultipackAPIModel[] = await this._HistoryService.GetClientMultiPacks(request);
    return result;
  }

  public mapMultipackToUI(multipacks: MultipackAPIModel[],showMultipackExpired): MultipackUIModel[]{
    if(multipacks && multipacks.length > 0){
      let result = multipacks.map(x=> {
        return <MultipackUIModel>{
          DateRedeemed: x.redeemedSessions == 0?this.localization.LocalizeShortDateTime(x.clientMultiPackSaleDateTime):
           this.localization.LocalizeShortDateTime(x.clientMultiPackRedeemDateTime),
          DateOfSale: this.localization.LocalizeShortDateTime(x.clientMultiPackSaleDateTime),
          DateOfExpiry: this.localization.LocalizeShortDate(x.multipackExpirytDate),
          MultipackDescription: x.multiPackName,
          RedeemedCount: x.redeemedSessions.toString(),
          RemainingCount: x.isUnlimitedMultipack == true?this.GetCaptions().NotAvailable:x.remainingSessions.toString(),
          IsMultipackExpired: x.isMultiPackExpired,
          Product: x.productName,
        }
      });
 
  
      if(!showMultipackExpired){           
        result = result.filter(x=>x.IsMultipackExpired == false);
      }

      const sortedArray = this.sortDateArrayDescending(result);
      return sortedArray;
    }else
    {
      return new Array<MultipackUIModel>();
    }

  }

  sortDateArrayDescending(multipacks){
    return multipacks.sort((a, b) => {
      if(a.hasOwnProperty('DateRedeemed')){
        return <any>this._utilities.getDate(b.DateRedeemed) - <any>this._utilities.getDate(a.DateRedeemed);
      }else{
        return true;
      }  
    });
  }
  getRentalsTableHeaderOptions(){
    return[
      {
        key: 'Date',
        displayName: this.captions.lbl_date,
        displayNameId: 'tbl_hdr_vendorNumber',
      },
      {
        key: 'RentalItemName',
        displayName: this.captions.lbl_rentalItem,
        displayNameId: 'rentalItemName',
      },
      {
        key: 'TeeTime',
        displayName: this.captions.lbl_teetime,
        displayNameId: 'teetime',
      },
      {
        key: 'TimeOfUsage',
        displayName: this.captions.timeOfItemUsage,
        displayNameId: 'timeOfItemUsage',
      },
      {
        key: 'CourseName',
        displayName: this.captions.lbl_courses,
        displayNameId: 'tbl_hdr_course',
      }
    ]
  }
  getRentalsTableOptions() {
    return {
      defaultSortOrder: SorTypeEnum.asc,
      defaultsortingColoumnKey: '',
      ignoreSort: true
    }
  }
  async getRentalsTableContent(playerLinkIds:string[]): Promise<RentalsHistoryUIModel[]>{
    var result  = await (await this._HistoryService.GetScheduledTeeTimePlayerRetailItemsForByLinkIds(playerLinkIds)).filter(x=>x.retailItemType == RetailItemType.PMSAddOnsRentalItem);
    var mappedResult = this.mapRentalsHistoryToUI(result);
    return mappedResult;
  }

  private mapRentalsHistoryToUI(scheduledPlayerRetailItems:any[]): RentalsHistoryUIModel[] {
    let rentalHistorys: RentalsHistoryUIModel[] = [];
    scheduledPlayerRetailItems.forEach(item => {
      let timeOfUsage = this.localization.getTime(item.startTime,this.localization.getTimeFormat(),true) + ' - '+this.localization.getTime(item.endTime,this.localization.getTimeFormat(),true);
      let teeTime = this.localization.getTime(item.scheduledTeeTime,this.localization.getTimeFormat(),true);
      if (rentalHistorys.length > 0 && rentalHistorys.some(x => x.RentalItemName == item.itemDescription && x.TimeOfUsage === timeOfUsage && x.TeeTime === teeTime)){
        rentalHistorys.find(x => x.RentalItemName == item.itemDescription && x.TimeOfUsage === timeOfUsage && x.TeeTime === teeTime).Quantity += item.quantity;
      }
      else{
        let rentalHistory: RentalsHistoryUIModel = {
          Date: this.localization.localizeDisplayDate(item.scheduledTeeTime),
          CourseName: item.courseName,
          Quantity: item.quantity,
          RentalItemName: item.itemDescription,
          TeeTime: teeTime,
          TimeOfUsage: timeOfUsage,
        };
        rentalHistorys.push(rentalHistory);
      }
    });

    rentalHistorys.forEach(x => {
      x.RentalItemName = x.RentalItemName + ' ' + `(${x.Quantity})`;
    })

    return rentalHistorys;
}

localizedDate(value): string {
  return moment(value).format('DD MMM YYYY');
}

}
