import { TeeTimeFormat } from 'src/app/shared/models/teesheet.form.models';

export enum SlotType {
    selectedSlot = 1,
    allSlot = 2
}

export interface TeeTimePlayer {
    firstName: string;
    lastName: string;
    playerId: number;
    teeTimeFormat: TeeTimeFormat;
    playerPos : number;
    selected: boolean;
}

export interface SlotPlayer {
    bookedPlayer: TeeTimePlayer[];
    blockablePlayer: TeeTimePlayer[];
}
