import { Component, OnInit, Input } from '@angular/core';
import { ItneraryService } from './itnerary.service';
import { ItneraryBusiness } from './itnerary.business';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SelectionButton, TimeInterval } from './itnerary.model';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { DateInput,DaysModel } from 'src/app/shared/shared-models';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-itnerary',
  templateUrl: './itnerary.component.html',
  styleUrls: ['./itnerary.component.scss'],
  providers: [ItneraryBusiness, ItneraryService]
})
export class ItneraryComponent implements OnInit {
  captions: any;
  itneraryFormGrp: UntypedFormGroup;
  selectionButtons: SelectionButton[];
  dateInputs: DateInput;
  calenderValue: any;
  calenderDate: Date;
  fromDate: Date;
  toDate: Date = null;
  weekStart: any;
  weekEnd: any;
  sidebarArray: any = [];
  timeIntervals: TimeInterval[];
  WeekArray: DaysModel[];
  dateArray = [];
  itenaryarrayList = [];
  itenaryarrayListFull = [];
  playerGuid: string;
  weekChgObj: { start: any; end: any; };

  @Input('inputData')
  set formData(value) {
    if (value) {
      this.playerGuid = value.id;
      this.fromDate = this._propertyInfo.CurrentDate;
      this.toDate = this.fromDate.addDays(1);
      this.setItneraryData();
    }
  }
  constructor(private _ItneraryBusiness: ItneraryBusiness, private Form: UntypedFormBuilder, private _Utilities: GolfUtilities,
    private _propertyInfo: GolfPropertyInformation) { }

  ngOnInit() {
    this.captions = this._ItneraryBusiness.GetCaptions();
    this.generateItneraryFormGrp();
    this.getSelectionButtons();
    this.initializeInputs();
    this.calenderValue = this._ItneraryBusiness.GetDateDisplayFormat(this._propertyInfo.CurrentDate);
    this.getTimeInterval();
    this.WeekArray = this._ItneraryBusiness.GetDays();
  }

  async setItneraryData() {
    await this._ItneraryBusiness.GetItneraryforPlayer(this.playerGuid, this.fromDate, this.toDate);
    this.itenaryarrayList = this._ItneraryBusiness.itenaryarrayListFull.filter(x => {
      return this._Utilities.GetDateWithoutTime(x.orgStartDateTime).getTime() == this._Utilities.GetDateWithoutTime(this._propertyInfo.CurrentDate).getTime(); });
    this.itenaryarrayList = this.sortTimeData(this.itenaryarrayList);
    //While loading to set the current PropDate activity history
  }

  sortTimeData(timeData) {
      return _.orderBy(timeData, ['orgStartDateTime'], ['asc']);
  }

  generateItneraryFormGrp() {
    this.itneraryFormGrp = this.Form.group({
      searchParams: '',
      date: this._propertyInfo.CurrentDTTM
    });
  }

  getSelectionButtons() {
    this.selectionButtons = this._ItneraryBusiness.GetSelectionButtons();
    this.itneraryFormGrp.get('searchParams').patchValue(this.selectionButtons[0]);
    this.itenaryarrayList = this._ItneraryBusiness.itenaryarrayListFull;
    this.itenaryarrayList = this.sortTimeData(this.itenaryarrayList);
  }

  initializeInputs() {
    this.dateInputs = {
      className: 'golf__date-picker',
      form: this.itneraryFormGrp,
      formControlName: 'date',
      placeHolder: this.captions.dateOfBirth
    };
  }

  async onDateChange(event) {
    await this.selectionButtonClick(this.itneraryFormGrp.value.searchParams, this.itneraryFormGrp.value.date);
  }
  async selectionButtonClick(button, inputDate?) {
    this.itneraryFormGrp.get('searchParams').patchValue(button);
    const date = inputDate ? inputDate : this._propertyInfo.CurrentDate;
    if (!inputDate) {
      this.itneraryFormGrp.get('date').patchValue(date);
    }
    this.sidebarArray = (button.id === 2) ? this.WeekArray : this.dateArray;    
    switch (button.id) {
      case 1:
        this.calenderValue =  this._ItneraryBusiness.GetDateDisplayFormat(date);
        this.fromDate = this.calenderDate = date; this.toDate = this.fromDate.addDays(1);        
        break;
      case 2:
        this.weekStart = moment(date).startOf('week');
        this.weekEnd = moment(date).endOf('week');
        this.calenderValue = this.weekStart.format('DD MMM, YYYY') + '-' + this.weekEnd.format('DD MMM, YYYY');
        this.weekChgObj = { start: this.weekStart.toDate(), end: this.weekEnd.toDate() };
        this.fromDate = this.weekChgObj.start ;
        this.toDate =  this.weekChgObj.end.addDays(1);
        break;
      case 3:
        this.sidebarArray = this._ItneraryBusiness.GetSideBarArray(date);
        this.calenderValue = moment(date).format('MMM, YYYY');
        this.calenderDate = date;
        this.fromDate = moment(date).startOf('month').toDate();
        this.toDate = moment(date).endOf('month').toDate().addDays(1);       
        break;
    }  
    await this._ItneraryBusiness.GetItneraryforPlayer(this.playerGuid, this.fromDate, this.toDate);
    this.itenaryarrayList = this._ItneraryBusiness.itenaryarrayListFull;
    this.itenaryarrayList = this.sortTimeData(this.itenaryarrayList);
  }
  async changemonthdateYear(e) {
    const preNext = (e === 'next') ? 1 : -1;
    let result;
    switch (this.itneraryFormGrp.value.searchParams.id) {
      case 1:
        result = await this._ItneraryBusiness.DateChanged(preNext, this.calenderValue, this.playerGuid);
        this.calenderValue = result.value;
        break;
      case 2:
        result = await this._ItneraryBusiness.WeekChanged(preNext, this.weekStart, this.weekEnd, this.playerGuid);
        this.calenderValue = result.value;
        this.weekStart = result.weekStart;
        this.weekEnd = result.weekEnd;
        this.weekChgObj = { start: result.weekStart, end: result.weekEnd };
        break;
      case 3:
        result = await this._ItneraryBusiness.MonthChanged(preNext, this.calenderValue, this.playerGuid);
        this.calenderValue = result.value;
        this.calenderDate = result.calenderDate;
        this.sidebarArray = result.sidebarArray;
        break;
    }

    this.itenaryarrayList = this._ItneraryBusiness.itenaryarrayListFull;
    this.itenaryarrayList = this.sortTimeData(this.itenaryarrayList);
  }

  getTimeInterval() {
    this.timeIntervals = this._ItneraryBusiness.GetTimeInterval(this._propertyInfo.CurrentDate, 1, 23);
  }
}
