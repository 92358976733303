<section class="ReportSection d-block h-100" [ngClass]="[AllowJasperReport?'jasperEnabled':'']" *ngIf="!reportViewDisable">
  <div class="report-container-row" *ngIf="this.pages.length != 0">
    <div class="left-container">
      <div class="report-print" (click)="reportDownloadandPrint()"><i matTooltip="{{captions.Print}}"
        class="material-icons" aria-hidden="true">print</i></div>
      <div class="report-print" (click)="reportDownload()"><i [ngClass]="{'active-print': ifPrintable}"
          matTooltip="{{captions.Save}}" class="material-icons" aria-hidden="true">save</i></div>      
      <div class="download-container LW12" *ngIf="this.pages.length != 0 && ifPrintable"
        [ngClass]="{'active-printable': ifPrintable}">
        <div *ngIf="!AllowJasperReport">
          <mat-radio-group class="radio-class" aria-label="Select an option">
            <mat-radio-button *ngIf="!downloadOptions?.pdf" value="1" (click)="downloadReport('PDF')">{{captions.pdf}}</mat-radio-button>
            <mat-radio-button *ngIf="!downloadOptions?.word" value="2" (click)="downloadReport('WORD')">{{captions.word}}</mat-radio-button>
            <mat-radio-button *ngIf="!downloadOptions?.excel" value="3" (click)="downloadReport('EXCEL')">{{captions.excel}}</mat-radio-button>
            <mat-radio-button *ngIf="!downloadOptions?.reportData" value="4" (click)="downloadReport('RAWDATA')">{{captions.reportData}}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="AllowJasperReport">
          <ag-dropdown class="customJPR" [automationId] = "'Dd_createVendorItem_unit'" [placeholder]="'Select Format'" floatLabel="never" [showEmptyOption]='false' [source]="formats" (selectionChange)="downloadReportData($event)" required> </ag-dropdown>
        </div>
      </div>
      <div *ngIf="isSearchAvailable" class="report-search">
        <input [ngClass]="{'add-width': ifInputShow}" [(ngModel)]="findText" (keyup)="searchtext(findText)"
          type="text" />
        <i *ngIf="!ifCancelBool" class="material-icons" aria-hidden="true" matTooltip="{{captions.Search}}"
          (click)="searchContent()">search</i>
        <i *ngIf="ifCancelBool" class="material-icons" aria-hidden="true" matTooltip="{{captions.Cancel}}"
          (click)="searchContent(); searchtext('')">cancel</i>
      </div>
    </div>
    <div class="right-container">
      <div class="report-prev" matTooltip="{{captions.Previous}}" (click)="setPage(this.getPageNo('PREVIOUS'))"><i
          [ngClass]="{'report-active-icon': ispageReference == 'PREVIOUS' }" class="material-icons" aria-hidden="true">arrow_upward</i></div>
      <div class="report-next" matTooltip="{{captions.Next}}" (click)="setPage(this.getPageNo('NEXT'))"><i
          [ngClass]="{'report-active-icon': ispageReference == 'NEXT' }" class="material-icons" aria-hidden="true">arrow_downward</i></div>
      <div class="report-pagestart">
        <mat-form-field [floatLabel]="floatLabel" class="layout">
          <input matInput (scrollState)="setPage(pageNumber)" (keyup.enter)="setPage(pageNumber)" (keyup)="validatePage(pageNumber)"
            golfinputtype="onlynumber,nonnegative" [(ngModel)]="pageNumber"  (blur)="defaultFirstSelection(pageNumber)">
        </mat-form-field>
      </div>
      <div class="report-pageend">{{this.pages.length - 1}}</div>
      <div class="report-zoomout" (click)="zoom('OUT')">
        <i [ngClass]="{'report-active-icon': isZoomReference == 'OUT' }" aria-hidden="true" class="material-icons"
          matTooltip="{{captions.ZoomOut}}">
          remove
        </i>
      </div>
      <div class="report-zoomin" (click)="zoom('IN')">
        <i [ngClass]="{'report-active-icon': isZoomReference == 'IN' }" class="material-icons" aria-hidden="true"
          matTooltip="{{captions.ZoomIn}}">
          add
        </i>
      </div>
    </div>
  </div>

  <div class="report d-flex justify-content-center" id="report" [ngClass]="reportHTML ? 'bg-grey' : '' "
    *ngIf="reportHTML">
    <div class="textcenter-Web" id="textcenter-Web">
      <ng-scrollbar [trackX]="true" [trackY]="true" [autoHide]="true" #scrollable (wheel)="moveToNextPage($event)">
        <div class="reportData" id="reportData" [innerHtml]="reportHTML" style="background: white" [ngClass]="{'Zoom13':AllowJasperReport}"></div>
      </ng-scrollbar>
    </div>
  </div>
  <div class="ag_display--none" id="jasperreportdata"></div>
  <div class="norecords-alignment" *ngIf="reportHTML == ''" id="no-data">
    <div class="textcenter-Web text-align-center nodata">
      {{this.captions.NoRecordsToDisplay}}
    </div>
  </div>
</section>
