import { Injectable } from '@angular/core';
import { RateModalService } from './rate-modal-service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { RatesData, ApiRatesData } from './rate-model';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';


@Injectable()

export class RateModalBussiness {
     public readonly captions: any;
     overrideRateTypeForDynamicAvailability: boolean = false;

     constructor(private _rateModalService: RateModalService, private _localization: GolfLocalization, private _userAccessBusiness:UserAccessBusiness) {
          this.captions = this._localization.captions.teetime;
     }


     async getRatesByCourseIdAndDateTime(courseId: number, dateTime: string): Promise<RatesData[]> {

          const dateTimeIso =  this._localization.ConvertDateToISODateTime(this._localization.getDate(dateTime));
          const apiData: ApiRatesData[] = await this._rateModalService.getRatesByCourseIdAndDateTime(courseId, dateTimeIso);

          return this.mapToUiModel(apiData);
     }

     private mapToUiModel(apiData: ApiRatesData[]): RatesData[] {
          let decimalDigits = Number(sessionStorage.getItem('noOfDecimalDigits'));
          return apiData.map( o => {
               return {
                    description : o.description,
                    greenFee: this._localization.currencySymbol + '  ' +this._localization.localizeCurrency(o.greenFee, false, decimalDigits),
                    cartFee: this._localization.currencySymbol + '  ' +this._localization.localizeCurrency(o.cartFee, false, decimalDigits),
                    customFee: this._localization.currencySymbol + '  ' +this._localization.localizeCurrency(o.customFee,false, decimalDigits),
                    total: this._localization.currencySymbol + '  ' + this._localization.localizeCurrency(o.total, false, decimalDigits),
                    greenTitle: this.captions.greenFeeTitle,
                    cartFeeTile: this.captions.cartFeeTitle,
                    customFeeTitle: this.captions.customFeeTitle,
                    stopAvailability : this.overrideRateTypeForDynamicAvailability ? false : o.stopavailability
               } as RatesData;
          });
     }
     
     public async validateBreakPointAccess(breakPointNumber: number, showPopup: boolean = true): Promise<any> {
           await this._userAccessBusiness.getUserAccess(breakPointNumber, showPopup).then(x => {
               this.overrideRateTypeForDynamicAvailability = x.isAllow;
           });
     }
}
