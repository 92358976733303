<section class="distribution-list-container newVersionWrapper distribution-list-wrapper">
  <ng-container *ngIf="hideCrudDLWindow">
    <div class="CustomTableGrid h-100">
        <button [attr.automationId]="'Btn_distributionList_createNew'" [ngClass]="IsViewOnly ? 'button_invalidbgcolor button--disabled' : 'body-bgcolor whitecolor'"
        class="float-right mr-2 createNewBtn text-capital mt18" mat-raised-button
        (click)="cruddistributionList()" [disabled]="IsViewOnly">{{captions.createNew}}</button>

      <app-common-table [attr.automationId]="'Tbl_distributionList_distributionList'" [options]="tableoptions" (deleteEvt)="DeleteDistributionList($event)" (editEvt)="EditDistributionList($event)">
    </app-common-table>
    </div>
  </ng-container>
  <ng-container *ngIf="!hideCrudDLWindow">
    <app-crud-distribution-list [showTableRecord]="showTableRecords" [distributionData]='editDistributionListData' [isEditEnabled]='IsEditMode' (emittedDLobj)="returnedDLdata($event)" [isViewOnly]="IsViewOnly"></app-crud-distribution-list>
  </ng-container>
</section>
