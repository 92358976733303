<section [formGroup]="eformSearchForm" class="search-eform-container newVersionWrapper" [ngClass]="dialogData.title ? 'h-100' : '' ">
    <app-dialog-header *ngIf="dialogData.title" [title]="title" (closeDialog)="close()"></app-dialog-header>
    <div [ngClass]="dialogData.title ? 'bg-color' : '' ">
        <div class="ag_container--padding-lg search-eform" [ngClass]="dialogData.title ? 'fromDialog' : '' ">
            <div class="pb-3">
                <app-simple-search [placeholder]="captions.lbl_search_eforms" [value]="searchText1" (searchChange)="searchValueChange($event)">
                </app-simple-search>
             </div>
                <mat-radio-group formControlName="templates" [(ngModel)]="selectedForm" [attr.automationId]="'Rdo_arPaymentMaintenance_status'" (change)="registerChange($event)">
                    <mat-radio-button class='d-block ag_form--radio-button pb-2'
                        *ngFor='let status of clonedTemplateOptions' [value]='status.id'>{{status.label}}
                    </mat-radio-button>
                </mat-radio-group>
        </div>
    </div>
    <div class="ag_footer--actions ag-pt-5" *ngIf="dialogData.title">
        <app-button [buttontype]="saveButton" (valueChange)='onSave()'></app-button>
        <app-button [buttontype]="cancelButton" (valueChange)='onCancel()'></app-button>
    </div>
    
</section>