import { Injectable } from '@angular/core';
import { GolfRoutes } from 'src/app/core/extensions/golf-route';
import { GolfManagementCommunication } from '../../communication/services/golfmanagement.service';
import { CancelReason } from 'src/app/settings/golf-setup/code-setup/cancel-reasons/cancel-reasons.model';
@Injectable()
export class CancelReasonsDataService{
    constructor(private _http: GolfManagementCommunication){

    }

    public async GetCancelReasons(showInactive: boolean):Promise<CancelReason[]>
    {
        return this._http.getPromise<CancelReason[]>({
            route:GolfRoutes.GetCancelReasons,
            uriParams :{showInactive : showInactive }
        });
    }

    public async CreateCancelReason(cancelReasonObj : CancelReason):Promise<number>
    {
        return this._http.postPromise<number>({
            route:GolfRoutes.CreateCancelReason,
            body:cancelReasonObj
        });
    }

    public async DeleteCancelReason(_id : number):Promise<CancelReason[]>
    {
        return this._http.deletePromise<CancelReason[]>({
            route:GolfRoutes.DeleteCancelReason,
            uriParams: {id :_id}
        });
    }

    public async UpdateCancelReason(_reason : CancelReason):Promise<CancelReason[]>
    {
        return this._http.putPromise<CancelReason[]>({
            route:GolfRoutes.UpdateCancelReason,
            body:_reason
        }); 
    }
}