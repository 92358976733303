<form [formGroup]="playerTypeForm" class="player-type-search">
  <div class="ag_display--flex-wrap align">
    <mat-radio-group attr.automationId='Rdo_PlayerTypeSearch_playerCategories' class="golf__radio d-block" [formControlName]="controlName" (change)="playerCategoryChange($event)">
      <mat-radio-button class="ag-mt-4 golf--form-radio-button golf-radio--horizontal"
        *ngFor="let playerCategory of playerCategories" [value]="playerCategory.id">
        {{playerCategory?.description}}
      </mat-radio-button>
      <mat-error *ngIf="playerTypeForm.get(controlName).hasError('required')">{{errorCaption.missingPlayerType}}
      </mat-error>
    </mat-radio-group>
    <div *ngIf="showExternalGuestSearch && playerTypeForm.controls[controlName].value === selectedGuestType.player" class="ag_display--flex external align">
      <label class="golf-form--label ag_mr--5 ag_mb--0">{{errorCaption.opera}}</label>
      <ui-switch attr.automationId='Tog_PlayerTypeSearch_isExternalGuestSearch' [checkedLabel]="errorCaption.yes" [checked]='isExternalGuestSearch' (change)="SetExternalGuestSearch($event)" formControlName="isExternalGuestSearch" [uncheckedLabel]="errorCaption.no">
      </ui-switch>
    </div>
        <div *ngIf="showPlatformGuestSearch && playerTypeForm.controls[controlName].value === selectedGuestType.player"
          class="ag_display--flex external align">
          <label class="golf-form--label ag_mr--2 ag_mb--0">{{errorCaption.platformGuestSearch}}</label>
          <ui-switch attr.automationId='Tog_PlayerTypeSearch_isPlatformGuestSearch' [checkedLabel]="errorCaption.yes" (change)="SetPlatformGuestSearch($event)"
            formControlName="isPlatformGuestSearch" [checked]='isPlatformGuestSearch' [uncheckedLabel]="errorCaption.no">
          </ui-switch>
        </div>
  </div>  
  <br/>
  <mat-radio-group attr.automationId='Rdo_PlayerTypeSearch_hotelReservationsTypes' class="golf__radio d-block footer-button--actions" *ngIf="playerTypeForm.value[controlName] === GuestplayerTypes.hotelReservation" [value]="selectedHotelReservationsType" (change)="HotelReservationTypeChange($event)">
    <mat-radio-button class="ag-mt-4 golf--form-radio-button golf-radio--horizontal"
      *ngFor="let reservationTypes of hotelReservationsTypes" [value]="reservationTypes.id">
      {{reservationTypes?.description}}
    </mat-radio-button>
  </mat-radio-group>
  <mat-radio-group attr.automationId='Rdo_PlayerTypeSearch_existingPlayerTypes' class="golf__radio d-block footer-button--actions" *ngIf="playerTypeForm.value[controlName] === GuestplayerTypes.player" [value]="selectedExistingPlayerType" (change)="ExistingPlayerTypeChange($event)">
    <mat-radio-button class="ag-mt-4 golf--form-radio-button golf-radio--horizontal"
      *ngFor="let existingPlayerType of existingPlayerTypes" [value]="existingPlayerType.id">
      {{existingPlayerType?.description}}
    </mat-radio-button>
  </mat-radio-group>
  <!-- Reverted commit d73dd254 - search optimization for members  -->
  <app-golfchip-search #chipSearch [disabled]="disabled" class='in-block golf-form-control--lg' [allData]="searchList" [selectedData]="selectedChipData" [showSeperator]="showSeperator"
    [placeholderText]="searchPlaceholder" [autoCompleteKeys]="autoCompleteKeys" *ngIf="showSearch" [showMoreData]="showMoreData" [directiveType]="searchTextinputType"
    [selectedChipKey]="selectedChipKey" [searchKey]="searchKey" (selectedChipDataEmit)="selectedChipDataEmit($event)" [allowPaste]=true
        (searchTextEmit)="SearchTextHandler($event)" (onChipRemove)="onChipRemoveEvent($event)"
    [initiateSearchValue] ="initiateSearchLength">
  </app-golfchip-search>
  <a attr.automationId='Btn_PlayerTypeSearch_reatePlayerPopup' class="golf__link create-player-link"
    [ngClass]="{'ag_section--disable': isDataSelected}"
  (click)="CreatePlayerPopup()" href="javascript:void(0)">
  <span *ngIf="playerTypeForm.controls[controlName].value === selectedGuestType.player">{{captions.CreatePlayer}}</span></a>
 
  <div *ngIf="showToggle == true">
  <div class="ag_display--flex" *ngIf="((playerTypeForm.controls[controlName].value === selectedGuestType.hotelReservation) && selectedChipData?.length > 0)">
    <app-ag-toggle attr.automationId='Btn_PlayerTypeSearch_updatePlayerProfile' class="ag_form-control--xs" [toggleInputs]="updatePlayerProfileInput" (valueChange)="updatePlayerProfile($event)">
    </app-ag-toggle>
    <app-ag-toggle attr.automationId='Btn_PlayerTypeSearch_editGuest' class="ag_form-control--xs" [toggleInputs]="editGuestInput" (valueChange)="editGuest($event)">
    </app-ag-toggle>
  </div>
  <div class="ag_display--flex" *ngIf="playerTypeForm.controls[controlName].value === selectedGuestType.player && selectedChipData?.length > 0">
    <app-ag-toggle attr.automationId='Btn_PlayerTypeSearch_updatePlayerProfile' class="ag_form-control--xs" [toggleInputs]="updatePlayerProfileInput" (valueChange)="updatePlayerProfile($event)">
    </app-ag-toggle>
    <app-ag-toggle attr.automationId='Btn_PlayerTypeSearch_editGuest' class="ag_form-control--xs" [toggleInputs]="editPlayerInput" (valueChange)="editPlayer($event)">
    </app-ag-toggle>
  </div>
</div>
</form>