import { routeJSON } from 'src/app/routeJson';
import { GolfRoutes, MsalConfiguration } from './golf-route';
declare let $: any;
/* Tea pot !!! */
export const GolfDummyVariable: string = "I'm a teapot!";


declare global {

    export const GolfApiRoute: typeof GolfRoutes;
    export const MsalConfig: MsalConfiguration;
    export const RouteJsonConfig: typeof Object;
    export interface Window {
        GolfApiRoute: typeof GolfRoutes;
        MsalConfig: MsalConfiguration;
        RouteJsonConfig : any;
    }
}
function loadRouteJson() {
    let routes;
    // $.ajax({
    //     url: './assets/route.json',
    //     async: false,
    //     success: function (result) {
    //         routes = result;
    //     },
    //     error: function(result){
    //         console.error(result);
    //     }
    // });
    routes = routeJSON;
    return routes;
}


/* Register Routes and API */
Window.prototype.GolfApiRoute = GolfRoutes;
Window.prototype.RouteJsonConfig = loadRouteJson();
