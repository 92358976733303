
import {debounceTime} from 'rxjs/operators';
import { Component, OnInit, Inject, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Localization } from "src/app/common/localization/localization";
import { ContactLogService } from 'src/app/common/contact-log/contact-log.service';
@Component({
  selector: 'app-alert-message-popup',
  templateUrl: './alert-message-popup.component.html',
  styleUrls: ['./alert-message-popup.component.scss'],
  encapsulation:ViewEncapsulation.None,
  providers: [ContactLogService]
})
export class AlertMessagePopupComponent implements OnInit {
  noButton: boolean = false;
  captions:any;
  IDTHiddenField:string;
  dataform:UntypedFormGroup;
  @ViewChild('cardfield') cardfield: ElementRef;
  inputSubscription$: any;
  showContact:boolean=true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialogRef<AlertMessagePopupComponent>,private localization: Localization) {
    this.captions = this.localization.captions
    this.noButton = data.noButton ? data.noButton : false;
   }

  ngOnInit() {
    this.dataform= new UntypedFormGroup({
      carddata:new UntypedFormControl('')
      })
      this.inputSubscription$ = this.dataform.valueChanges.pipe(debounceTime(1000))
      .subscribe(val => {
        console.log(val);
        this.dialog.close(val.carddata);
      });
  }

  ngAfterViewInit() {
    if (this.data.isHiddenFieldRequired &&  this.cardfield) {
      this.cardfield.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    this.inputSubscription$.unsubscribe();
  }

  DialogClose = (result) => {
    this.dialog.close(result);
  }
}
