<div class="submodule-container">
  <app-table-search-header [searchHeaderOption]="searchHeaderOption" (searchChange)="searchChange($event)"
  (showInactive)="showInactiveToggler($event)" (createType)="createEvent($event)">
  </app-table-search-header>
  <div class="table-container">
      <app-cdkvirtual [IsEditModeEnabled]="IsEditModeEnabledFlag"
       [headerOptions]="headerOptions" [tableContent]="tableContent" [options]="tableOptions"
          [searchOptions]="searchText" (EmittedData)='tableAction($event)'></app-cdkvirtual>
  </div>
</div>
<div *ngIf="CreationToggler">
  <app-create-modal [input]="createModalData" (clickEvent)="back($event)">
      <app-sftp [crudInput]="crudActionInput"
      (handleClickEvent)="handleClick($event)" (formReady)='formReady($event)'></app-sftp>
  </app-create-modal>
</div>
