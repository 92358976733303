import { Component, EventEmitter, OnInit, Output, Input, ViewChild, OnDestroy, SimpleChanges } from '@angular/core';
import { ButtonValue, DropdownOptions, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Localization } from 'src/app/common/localization/localization';
import { ReplaySubject } from 'rxjs';
import { AlertAction, AlertType, ButtonType, GuaranteeTypes, PaymentModalType } from '../../shared-models';
import { PlayerTypeRateTypeChangeService } from '../../service/playerTypeRateTypeChange.service';
import { OriginalFeeDetail, ScheduledTeeTimeUnPaidPlayer } from '../../models/unpaid-players.model';
import { GolfUtilities } from '../../utilities/golf-utilities';
import { DomainRetailItem } from 'src/app/settings/settings-shared/assign-retail-item/assign-retail-item.model';
import { DynamicPricingPlayerFee } from 'src/app/tee-time-actions/teetime/player-details/player-info.model';
import { TeeTimeFormat } from '../../models/teesheet.form.models';
import { playerTypes } from '../../global.constant';
import { guestType } from 'src/app/lessons/lessons-modal/player-info/player-info.model';
import { cloneDeep } from 'lodash';
import { UserAccessBusiness } from '../../data-services/authentication/useraccess.business';
import { UserAccessBreakPoints } from '../../constants/useraccess.constants';
import { ValidationFrom } from '../../utilities/guaranteeType.Validator';

@Component({
  selector: 'app-update-player-details',
  templateUrl: './update-player-details.component.html',
  styleUrls: ['./update-player-details.component.scss']
})
export class UpdatePlayerDetailsComponent implements OnInit, OnDestroy {

  commonCaptions: any;
  saveButton: ButtonValue;
  updateButton: ButtonValue;
  savePayButton: ButtonValue;
  saveShopButon: ButtonValue;
  cancelButton: ButtonValue;
  playerTypeOptions: DropdownOptions[];
  rateTypeOptions: DropdownOptions[];
  payForm: UntypedFormGroup;
  isNegotiateGreenFee:boolean=false;
  isNegotiateCartfee:boolean = false;
  originalFeeDetail:OriginalFeeDetail = {} as OriginalFeeDetail;
  errorCaptions:any;
  totalFee: any;
  captions:any;
  isNegotiateDisabled:boolean = false;
  @Input() from : string;
  @Output() isPayFormDirty = new EventEmitter<boolean>();
  @Output() onActionEmit = new EventEmitter<any>();
  $destroy: ReplaySubject<boolean> = new ReplaySubject(1);
  paymentModalType = PaymentModalType;
  @Input() rowIdx;
  rowObject:ScheduledTeeTimeUnPaidPlayer;
  backUpRowObject:ScheduledTeeTimeUnPaidPlayer;
  triggerCurrencyGreenFee: number;
  triggerCurrencyCartFee: number;
  enablePay: boolean = true;
  applicableGuarantee:GuaranteeTypes[] = [];
  playerInfo: string = "";
  @Input('rowData')
  set rowDataToFields(value){
    console.log(value);
    if(value){
      this.rowObject = value;
      this.backUpRowObject = cloneDeep(value);
      this.setInitialValues();
      this.initialize();
    }
  }
  items = [
    {name: 'test'}
  ]

  constructor(
    private fb: UntypedFormBuilder,
    public localization: Localization, 
    private _playerTypeRateTypeChangeService:PlayerTypeRateTypeChangeService,
    private _Utilities: GolfUtilities,
    private _userAccess: UserAccessBusiness,
  ) {
    this.captions = this.localization.captions.teetime;
    this.commonCaptions = this.localization.captions;
    this.errorCaptions = this.localization.captions.settings;
   }

  ngOnInit(): void {
      this.initialize();
  }

  enableSaveButtons(value = null)
  {
    this.saveButton.disabledproperty = (this.rowObject?.isRateTypeRequired && value !=null) ?  !(this.payForm.valid && value.rateType && value.rateType > 0 && this.payForm.dirty) : !(this.payForm.valid && this.payForm.dirty) ;
    this.saveButton = {...this.saveButton};
    this.updateButton.disabledproperty = (this.rowObject?.isRateTypeRequired && value !=null) ?  !(this.payForm.valid && value.rateType && value.rateType > 0 && this.payForm.dirty) : !(this.payForm.valid && this.payForm.dirty) ;
    this.updateButton = {...this.updateButton};
    this.isPayFormDirty.emit(this.payForm.dirty);
    if(this.payForm.dirty && (!this.rowObject?.isAllowPay? !this.rowObject?.isAllowPay: false)) {
      if(this.payForm.valid){
        this.savePayButton.label = this.commonCaptions.btn_saveAndPay;
        this.saveShopButon.label = this.commonCaptions.btn_saveAndShop; 
      }
      else {
        this.savePayButton.label = this.commonCaptions.btn_payNow;
        this.saveShopButon.label = this.commonCaptions.btn_Shop; 
      }
    }
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   console.log(this.rowObject);
  //   if(this.rowObject){
  //     this.setInitialValues();
  //     this.initialize();
  //   }
  // }

  ngOnDestroy() {
    if (this.$destroy) {
      this.$destroy.next(true);
      this.$destroy.complete();
    }
  }

  setGuarantee(){
    let playerCategoryId =  this.rowObject?.playerCategoryId; 
    let paymentReferenceId = this.rowObject?.tokenTransId;
    this.applicableGuarantee.push(GuaranteeTypes.None);
    if(paymentReferenceId != 0){
      this.applicableGuarantee.push(GuaranteeTypes.CreditCard);
    }
    if(playerCategoryId == playerTypes.member){
      this.applicableGuarantee.push(GuaranteeTypes.Member);
    }
    else if(playerCategoryId == playerTypes.hotelReservation){
      this.applicableGuarantee.push(GuaranteeTypes.Resort);
    }
  }

  setInitialValues(){
    this.isNegotiateDisabled = !this._playerTypeRateTypeChangeService.isNegotiateAccess;
    this.setGuarantee();
    this.rowObject.allocationBlockWithPlayerTypes = this._playerTypeRateTypeChangeService.allocationBlockWithPlayerTypes.find(ab=> ab.id == this.rowObject.allocationBlockId);
    this.updateAllocationCodeMappingBasedOnRole();
    let playerTypeIds = this.rowObject.allocationBlockWithPlayerTypes?.playerTypes.map(b=> b.id);
    this.rowObject.playerTypeInfo = {
      value: this.rowObject.playerTypeId,
      options: this.rowObject.allocationBlockWithPlayerTypes ? this._playerTypeRateTypeChangeService.getPlayerTypeOptions(this.rowObject.allocationBlockWithPlayerTypes.playerTypes.filter(x=> this.applicableGuarantee.some(y=> y == <GuaranteeTypes>(x.guaranteeType)) && x.isActive), this.rowObject.playerTypeId) : this._playerTypeRateTypeChangeService.getPlayerTypeOptions(this._playerTypeRateTypeChangeService.playerTypes.filter(x=> this.applicableGuarantee.some(y=> y == <GuaranteeTypes>(x.guaranteeType)) && x.isActive), this.rowObject.playerTypeId),
      filteroptions: this.rowObject.allocationBlockWithPlayerTypes ? this._playerTypeRateTypeChangeService.getPlayerTypeOptions(this.rowObject.allocationBlockWithPlayerTypes.playerTypes.filter(x=> this.applicableGuarantee.some(y=> y == <GuaranteeTypes>(x.guaranteeType)) && x.isActive)) : this._playerTypeRateTypeChangeService.getPlayerTypeOptions(this._playerTypeRateTypeChangeService.playerTypes.filter(x=> this.applicableGuarantee.some(y=> y == <GuaranteeTypes>(x.guaranteeType)) && x.isActive))
    }
    this.rowObject.playerTypeRateTypes = this._playerTypeRateTypeChangeService.playerTypeRateTypes.filter(p=> playerTypeIds?.filter(pt=> pt== p.id)?.length>0);
    this.rowObject.rateTypeInfo = {
      value: this.rowObject.rateTypeId,
      options: this._playerTypeRateTypeChangeService.playerTypeRateTypes.filter(p=> p.id == this.rowObject.playerTypeId).length > 0 ?  this._playerTypeRateTypeChangeService.getRateTypeOptions(this._playerTypeRateTypeChangeService.playerTypeRateTypes.filter(p=> p.id == this.rowObject.playerTypeId)[0].rateTypes.filter(x=> this.applicableGuarantee.some(y=> y == <GuaranteeTypes>(x.guaranteeType)) && x.isActive ), this.rowObject.rateTypeId) : this._playerTypeRateTypeChangeService.getRateTypeOptions(this._playerTypeRateTypeChangeService.rateTypes.filter(x=> this.applicableGuarantee.some(y=> y == <GuaranteeTypes>(x.guaranteeType)) && x.isActive), this.rowObject.rateTypeId),
      filteroptions: this._playerTypeRateTypeChangeService.playerTypeRateTypes.filter(p=> p.id == this.rowObject.playerTypeId).length > 0 ?  this._playerTypeRateTypeChangeService.getRateTypeOptions(this._playerTypeRateTypeChangeService.playerTypeRateTypes.filter(p=> p.id == this.rowObject.playerTypeId)[0].rateTypes.filter(x=> this.applicableGuarantee.some(y=> y == <GuaranteeTypes>(x.guaranteeType)) && x.isActive)) : this._playerTypeRateTypeChangeService.getRateTypeOptions(this._playerTypeRateTypeChangeService.rateTypes.filter(x=> this.applicableGuarantee.some(y=> y == <GuaranteeTypes>(x.guaranteeType)) && x.isActive))
    };
    this.SetDyanmicAvailability();
    if(!this.isNegotiateDisabled)this.SetOriginalFees();
    this.totalFee = this.rowObject ? this.localization.localizeCurrency(this.rowObject.greenFee + this.rowObject.cartFee,false, 2) : 0;
    this.initialPlayerTypeRateTypeValidation();
  }

  private updateAllocationCodeMappingBasedOnRole() {
    if (this.rowObject && this.rowObject.allocationBlockWithPlayerTypes && this._playerTypeRateTypeChangeService.isAllocationCodePermissionByRoleCheck) {
        if (this._playerTypeRateTypeChangeService.allocationCodePermissions && this._playerTypeRateTypeChangeService.allocationCodePermissions.length > 0) {
          let allowedPlayerType = this._playerTypeRateTypeChangeService.allocationCodePermissions.filter(a => a.allocationBlockId == this.rowObject.allocationBlockWithPlayerTypes.id).map(a => a.playerTypeId);  
          if (allowedPlayerType && allowedPlayerType.length > 0) {
                this.rowObject.allocationBlockWithPlayerTypes.playerTypes = this.rowObject.allocationBlockWithPlayerTypes.playerTypes.filter(r => allowedPlayerType.some(ap => ap == r.id));
            }
            else {
              this.rowObject.allocationBlockWithPlayerTypes.playerTypes = [];
            }
        } else {
          this.rowObject.allocationBlockWithPlayerTypes.playerTypes = [];
        }
    }
}

  async initialPlayerTypeRateTypeValidation(){
    if(this.rowObject.playerTypeId !=0 && this.rowObject.rateTypeId !=0){
      let playerType = this.rowObject.playerTypeInfo.options.find(x=> x.id==this.rowObject.playerTypeId)?.value ;
      let rateType = this.rowObject.rateTypeInfo.options.find(x=> x.id==this.rowObject.rateTypeId)?.value;
      if(playerType && rateType){
        let isAllowed = await this._playerTypeRateTypeChangeService.validatePlayerTypeRateTypeDaysOut(this.rowObject.scheduledAPIDateTime, this.rowObject, playerType, rateType);
        if(!isAllowed){
          this.resetPlayerType();
        }
      }
    }
    else if (this.rowObject.playerTypeId !=0 ){
      let playerType = this.rowObject.playerTypeInfo.options.find(x=> x.id==this.rowObject.playerTypeId)?.value ;
      if(playerType){
        let isAllowed = await this._playerTypeRateTypeChangeService.validatePlayerTypeDaysOut(this.rowObject.scheduledAPIDateTime, this.rowObject, playerType);
        if(!isAllowed) this.resetPlayerType();
      }
    }
    else if(this.rowObject.rateTypeId !=0 ){
      let rateType = this.rowObject.rateTypeInfo.options.find(x=> x.id==this.rowObject.rateTypeId)?.value;
      if(rateType){
        let isAllowed = await this._playerTypeRateTypeChangeService.validateRateTypeDaysOut(this.rowObject.scheduledAPIDateTime, this.rowObject, rateType);
        if(!isAllowed) this.resetRate();
      }
    }
  }

  resetPlayerType()
  {
    this.rowObject.playerTypeId = 0;
    this.payForm.get('playerType').setValue('');
    this.rateTypeOptions = this._playerTypeRateTypeChangeService.getRateTypeOptions(this._playerTypeRateTypeChangeService.rateTypes.filter(x=> this.applicableGuarantee.some(y=> y == <GuaranteeTypes>(x.guaranteeType))));
    this.resetRate(true);
  }

  SetOriginalFees(){
    this._Utilities.ToggleLoader(true);
    this._playerTypeRateTypeChangeService.getTeeFeesForCourseAndDate(this.rowObject.courseId, this.localization.getDate(this.rowObject.scheduledAPIDateTime)).then(async(res) => {
      this._Utilities.ToggleLoader(false);
      if(res && res.filter(x=> x.rateTypeId == this.rowObject.rateTypeId).length>0){
        let DynamicPricingForSelectedRateType:DynamicPricingPlayerFee ;
        let rateSetup = res.find(x=> x.rateTypeId == this.rowObject.rateTypeId);
        if(this._playerTypeRateTypeChangeService.isDynamicPricingEnabled){
          this._Utilities.ToggleLoader(true);
          let dynamicPricing = await this._playerTypeRateTypeChangeService.getDynamicPricing(this.rowObject.courseId, this.localization.getDate(this.rowObject.scheduledAPIDateTime));
          this._Utilities.ToggleLoader(false);
          DynamicPricingForSelectedRateType =  dynamicPricing && dynamicPricing.length > 0 ? dynamicPricing.find(x => x.rateTypeId === Number(this.rowObject.rateTypeId)) : null;
        }
        this.originalFeeDetail.greenFee = (!this.rowObject.isGreenFeeNegotiated? this.rowObject.greenFee :  ( DynamicPricingForSelectedRateType &&  DynamicPricingForSelectedRateType.dynamicPricingId != 0 ? DynamicPricingForSelectedRateType.greenFee : rateSetup.greenFee));
        this.originalFeeDetail.cartFee = (!this.rowObject.isCartFeeNegotiated? this.rowObject.cartFee :  (DynamicPricingForSelectedRateType  &&  DynamicPricingForSelectedRateType.dynamicPricingId != 0  && this._playerTypeRateTypeChangeService.isIncludeCartFeeForRateCalculation ? DynamicPricingForSelectedRateType.cartFee : rateSetup.cartFee));
        this._Utilities.ToggleLoader(true);
        let retailItems = await this._playerTypeRateTypeChangeService.getRetailItems([rateSetup.greenFeeRetailItemId,rateSetup.cartFeeRetailItemId, rateSetup.entryFeeRetailItemId ]); 
        this._Utilities.ToggleLoader(false);
        if(retailItems.length > 0){
          this.originalFeeDetail.greenFeeRetailItem = retailItems.find(r=> r.id == rateSetup.greenFeeRetailItemId);
          this.originalFeeDetail.greenFeeRetailItemId = rateSetup.greenFeeRetailItemId;
          this.originalFeeDetail.cartFeeRetailItem = retailItems.find(r=> r.id == rateSetup.cartFeeRetailItemId);
          this.originalFeeDetail.cartFeeRetailItemId = rateSetup.cartFeeRetailItemId;
        }
      }
      else{
        this.originalFeeDetail.greenFee = !this.rowObject.isGreenFeeNegotiated ? this.rowObject.greenFee : 0;
        this.originalFeeDetail.greenFeeRetailItem = !this.rowObject.isGreenFeeNegotiated ? this.rowObject.greenFeeRetailItem : this.rowObject.negotiateGreenFeeRetailItem ;
        this.originalFeeDetail.greenFeeRetailItemId = !this.rowObject.isGreenFeeNegotiated ? this.rowObject.greenFeeRetailItemId : this.rowObject.negotiateGreenFeeRetailItemId ;
        this.originalFeeDetail.cartFee = !this.rowObject.isCartFeeNegotiated ? this.rowObject.cartFee : 0;
        this.originalFeeDetail.cartFeeRetailItem = !this.rowObject.isCartFeeNegotiated ? this.rowObject.cartFeeRetailItem : this.rowObject.negotiateCartFeeRetailItem ;
        this.originalFeeDetail.cartFeeRetailItemId = !this.rowObject.isCartFeeNegotiated ? this.rowObject.cartFeeRetailItemId : this.rowObject.negotiateCartFeeRetailItemId ;
      }
    }).catch(e=>{
      this._Utilities.ToggleLoader(false);
    });
  }

  SetDyanmicAvailability(){
    if(!this._playerTypeRateTypeChangeService.overrideRateTypeForDynamicAvailability) {
      this._Utilities.ToggleLoader(true);
      this._playerTypeRateTypeChangeService.getDynamicPricing(this.rowObject.courseId, this.localization.getDate(this.rowObject.scheduledAPIDateTime)).then(res => {
        this._Utilities.ToggleLoader(false);
        if(res){
          let stopAvailabilityRateTypeIds = res.filter(x=> x.stopAvailability == true).map(y=> y.rateTypeId);
          this.rowObject.rateTypeInfo.options.forEach(x => {
            x.disabled = stopAvailabilityRateTypeIds.includes(Number(x.id))? true : false;
          });
        }
      }).catch(e=>{
        this._Utilities.ToggleLoader(false);
      });
    }
  }

  initialize() {
    this.payForm = this.fb.group({
      playerType: this.rowObject ?  (this.rowObject.isPlayerTypeRequired ? [this.rowObject.playerTypeId, Validators.required] : [this.rowObject.playerTypeId] ): '',
      rateType: this.rowObject ? (this.rowObject.isRateTypeRequired ? [this.rowObject.rateTypeId, Validators.required] : [this.rowObject.rateTypeId]) : '',
      greenFee: this.rowObject? this.rowObject.greenFee : 0,
      negotiateGreenFee: this.rowObject? this.rowObject.isGreenFeeNegotiated : false,
      cartFee: this.rowObject? this.rowObject.cartFee : 0,
      negotiateCartFee:this.rowObject? this.rowObject.isCartFeeNegotiated : false
    });
    this.playerTypeOptions = this.rowObject?.playerTypeInfo ?  this.rowObject.playerTypeInfo.options : [];
    this.rateTypeOptions = this.rowObject?.rateTypeInfo ? this.rowObject.rateTypeInfo.options : [];
    //this.playerInfo = this.getPlayerName(); //commentted for future update
    if(!this.isNegotiateDisabled){
      this.isNegotiateGreenFee = this.rowObject ? this.rowObject.isGreenFeeNegotiated : false;
      this.isNegotiateCartfee = this.rowObject ? this.rowObject.isCartFeeNegotiated : false;
    }
    this.saveButton = {
      type: 'primary',
      label: this.commonCaptions.btn_save,
      disabledproperty: true
    };
    this.updateButton = {
      type: 'primary',
      label: this.commonCaptions.btn_updateARDiscountCategory,
      disabledproperty: true
    };
    this.savePayButton = {
      type: 'secondary',
      label: this.commonCaptions.btn_payNow,
      disabledproperty: this.rowObject?.isAllowPay ? this.rowObject?.isAllowPay: false
    };
    this.saveShopButon = {
      type: 'secondary',
      label: this.commonCaptions.btn_Shop,
      disabledproperty: this.rowObject?.isAllowPay ? this.rowObject?.isAllowPay: false
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.commonCaptions.btn_cancel,
      disabledproperty: false
    };
    this.payForm.valueChanges.pipe(takeUntil(this.$destroy)).subscribe(value => {
      // this.payForm.markAsDirty();
      this.enableSaveButtons(value);
      this.totalFee = this.localization.localizeCurrency(this.payForm.controls.greenFee.value + this.payForm.controls.cartFee.value,false, 2);
      if(this.rowObject){
        this.rowObject.isDirty = true;
        this.rowObject.greenFee = this.payForm.controls.greenFee.value;
        this.rowObject.cartFee = this.payForm.controls.cartFee.value;
      }
    });
  }

  getPlayerName(){
    if(this.rowObject){
      let formattedFullname = this._Utilities.formatGuestName(this.rowObject.playerName.split(' ')[0], this.rowObject.playerName.split(' ')[1], false);
      formattedFullname = formattedFullname.length>30 ? formattedFullname.slice(0,29) + ' ...' : formattedFullname;
      return " - " + formattedFullname + " - "+ this.rowObject.scheduledDateTime;
    }
    else return '';
  }

  async playerTypeChange(eve){
    console.log(eve);
    this.payForm.markAsDirty();
    if(eve.value){
      this.payForm.controls.rateType.setValue('');
      let playerTypeInfo = this.rowObject.playerTypeInfo;
      let seletedPlayerTypeId = this.payForm.get('playerType').value;
      this.rowObject.playerTypeId = seletedPlayerTypeId ? seletedPlayerTypeId : 0;
      this.rowObject.isDirty = true;
      if(seletedPlayerTypeId!="" && seletedPlayerTypeId > 0 && await this._playerTypeRateTypeChangeService.validatePlayerTypeDaysOut(this.rowObject.scheduledAPIDateTime, this.rowObject, playerTypeInfo.options.filter(x=> x.id==seletedPlayerTypeId)[0].value)){
        let playerTypeRateType = this.rowObject.playerTypeRateTypes.find((x)=> x.id === parseInt(seletedPlayerTypeId));
        this.rowObject.rateTypeInfo.options = this._playerTypeRateTypeChangeService.getRateTypeOptions(playerTypeRateType.rateTypes.filter(x=> this.applicableGuarantee.some(y=> y == <GuaranteeTypes>(x.guaranteeType))));
        this.rowObject.rateTypeInfo.value = null;
        this.SetDyanmicAvailability();
        this.rateTypeOptions = this.rowObject.rateTypeInfo.options;
      }
      else {
        this.payForm.get('playerType').setValue('');
        this.rowObject.playerTypeId = 0;
        this.rateTypeOptions = this._playerTypeRateTypeChangeService.getRateTypeOptions(this._playerTypeRateTypeChangeService.rateTypes.filter(x=> this.applicableGuarantee.some(y=> y == <GuaranteeTypes>(x.guaranteeType))));
      }
    }else {
      this.rowObject.playerTypeId = 0;
    }
    this.resetRate(true);
  }

  async rateTypeChange(eve){
    console.log(eve);
    this.payForm.markAsDirty();
    let message = this.errorCaptions.overWriteFees;
    this.rowObject.isDirty = true;
    if(eve){
      this._Utilities.showAlert(message, AlertType.Warning, ButtonType.YesNo, async (res) => {
        try{
          if (res === AlertAction.NO) {
            this.payForm.get('rateType').setValue(this.rowObject.rateTypeId);
          }
          else {
            this._Utilities.ToggleLoader(true);
            let rateTypeInfo = this.rowObject.rateTypeInfo;
            let selectedRateTypeId = this.payForm.get('rateType').value;
            selectedRateTypeId = selectedRateTypeId ? selectedRateTypeId : 0;
            if(selectedRateTypeId !="" && selectedRateTypeId > 0 && await this._playerTypeRateTypeChangeService.validateRateTypeDaysOut(this.rowObject.scheduledAPIDateTime, this.rowObject, rateTypeInfo.options.filter(x=> x.id==selectedRateTypeId)[0].value))
            {
              this._Utilities.ToggleLoader(true);
              if(selectedRateTypeId!=null && selectedRateTypeId>0)
              {
                  var rateSetups = await this._playerTypeRateTypeChangeService.getTeeFeesForCourseAndDate(this.rowObject.courseId, this.localization.getDate(this.rowObject.scheduledAPIDateTime));
                  console.log(rateSetups);
                  this._Utilities.ToggleLoader(true);
                  if(rateSetups && rateSetups.filter(x=> x.rateTypeId == selectedRateTypeId).length>0){
                    this.rowObject.rateTypeId = selectedRateTypeId;
                    let DynamicPricingForSelectedRateType:DynamicPricingPlayerFee ;
                    let rateSetup = rateSetups.find(x=> x.rateTypeId == selectedRateTypeId);
                    if(this._playerTypeRateTypeChangeService.isDynamicPricingEnabled){
                      let dynamicPricing = await this._playerTypeRateTypeChangeService.getDynamicPricing(this.rowObject.courseId, this.localization.getDate(this.rowObject.scheduledAPIDateTime));
                      this._Utilities.ToggleLoader(true);
                      DynamicPricingForSelectedRateType =  dynamicPricing && dynamicPricing.length > 0 ? dynamicPricing.find(x => x.rateTypeId === Number(selectedRateTypeId)) : null;
                      if(DynamicPricingForSelectedRateType && DynamicPricingForSelectedRateType.dynamicPricingId != 0){
                        this.rowObject.isDynamicPricingApplied=true;
                        this.rowObject.dynamicPricing= DynamicPricingForSelectedRateType;
                      }
                    }
                    let greenFee =  DynamicPricingForSelectedRateType &&  DynamicPricingForSelectedRateType.dynamicPricingId != 0 ? DynamicPricingForSelectedRateType.greenFee : rateSetup.greenFee;
                    let cartFee = DynamicPricingForSelectedRateType  &&  DynamicPricingForSelectedRateType.dynamicPricingId != 0  && this._playerTypeRateTypeChangeService.isIncludeCartFeeForRateCalculation ? DynamicPricingForSelectedRateType.cartFee : rateSetup.cartFee;
                    if(!this.isNegotiateDisabled){
                      this.originalFeeDetail.greenFee = DynamicPricingForSelectedRateType &&  DynamicPricingForSelectedRateType.dynamicPricingId != 0 ? DynamicPricingForSelectedRateType.greenFee : rateSetup.greenFee;
                      this.originalFeeDetail.cartFee = DynamicPricingForSelectedRateType  &&  DynamicPricingForSelectedRateType.dynamicPricingId != 0  && this._playerTypeRateTypeChangeService.isIncludeCartFeeForRateCalculation ? DynamicPricingForSelectedRateType.cartFee : rateSetup.cartFee;              
                    }
                    this.rowObject.greenFee = greenFee;
                    this.rowObject.cartFee = cartFee;
                    this.payForm.get('greenFee').setValue(greenFee);
                    this.payForm.get('cartFee').setValue(cartFee);
                    this.triggerCurrencyGreenFee = Math.random();
                    this.triggerCurrencyCartFee = Math.random();
                    this.totalFee =  this.localization.localizeCurrency(greenFee + cartFee,false, 2);
                    this.resetNegotiate();
                    if(this._playerTypeRateTypeChangeService.isCustomFeeEnabled){
                      await this.applyCustomFee(true);
                    }
                    this.rowObject.multiPackTransactionDetailId = 0;
                    this.rowObject.multiPackRetailItem = [];
                    this.rowObject.isMultiPackUpdated = true;
                    this.rowObject.isMultiPackApplied = false;
                    let retailItems = await this._playerTypeRateTypeChangeService.getRetailItems([rateSetup.greenFeeRetailItemId,rateSetup.cartFeeRetailItemId, rateSetup.entryFeeRetailItemId ]); 
                    if(retailItems.length > 0){
                      this.rowObject.greenFeeRetailItem = retailItems.find(r=> r.id == rateSetup.greenFeeRetailItemId);
                      this.rowObject.greenFeeRetailItemId = rateSetup.greenFeeRetailItemId;
                      this.rowObject.cartFeeRetailItem = retailItems.find(r=> r.id == rateSetup.cartFeeRetailItemId);
                      this.rowObject.cartFeeRetailItemId = rateSetup.cartFeeRetailItemId;
                      if(!this.isNegotiateDisabled){
                        this.originalFeeDetail.greenFeeRetailItem = retailItems.find(r=> r.id == rateSetup.greenFeeRetailItemId);
                        this.originalFeeDetail.greenFeeRetailItemId = rateSetup.greenFeeRetailItemId;
                        this.originalFeeDetail.cartFeeRetailItem = retailItems.find(r=> r.id == rateSetup.cartFeeRetailItemId);
                        this.originalFeeDetail.cartFeeRetailItemId = rateSetup.cartFeeRetailItemId;
                      }
                      if(this.rowObject.tournamentId > 0){
                        this.rowObject.entryFeeRetailItem = retailItems.find(r=> r.id == rateSetup.entryFeeRetailItemId);
                        this.rowObject.entryFeeRetailItemId = rateSetup.entryFeeRetailItemId;
                      }
                    }
                    if(this._playerTypeRateTypeChangeService.isMultipackAccess && this.rowObject.playerLinkId && this.rowObject.teeTimeFormat != TeeTimeFormat.BulkTee && this.rowObject.teeTimeFormat != TeeTimeFormat.GroupTee && this.rowObject.teeTimeFormat != TeeTimeFormat.Tournament ){
                      await this.applyGuestMultipackitems( rateSetup,retailItems)
                    }
                    if(this.rowObject.overrideDetail.length>0) this.rowObject.overrideDetail = eve.overrideDetail;
                    this._Utilities.ToggleLoader(false);
                    this.enableSaveButtons(this.payForm.value);
                  }
                  else{
                    this.resetRate(true);
                    this._Utilities.showError(this.errorCaptions.noRateSetup);
                  }
                  this._Utilities.ToggleLoader(false);
              }
            } else this.resetRate(true);
            this._Utilities.ToggleLoader(false);
          }
        }
        catch(ex){
          console.log(ex);
          this._Utilities.ToggleLoader(false);
        }
      });
    } else this.resetRate(true);
    
  }

  async applyCustomFee(isLoader = false){
    let greenFeeValue = this.payForm.get('greenFee').value;
    let cartFeeValue = this.payForm.get('cartFee').value;
    let customFees = await this._playerTypeRateTypeChangeService.getCustomFees(this.localization.getDate(this.rowObject.scheduledAPIDateTime), this.rowObject.scheduledTeeTimePlayerFeeId, greenFeeValue + cartFeeValue);
    this._Utilities.ToggleLoader(isLoader);
    this.rowObject.scheduledTeeTimePlayerCustomFees = customFees ? customFees : [];
  }

  async applyGuestMultipackitems(rateSetupItem, retailItems){
    const playerCategoryId = this.rowObject.playerCategoryId;
    let multipackDetail = await this._playerTypeRateTypeChangeService.GetMultiPackDetails(
        [{ 
           guestId: this.rowObject.playerLinkId,
           guestType: playerCategoryId == playerTypes.member ? guestType.member : guestType.guest
        }]
    );

    const scheduleDate = this.localization.getDate(this.localization.convertDateToAPIdate(this.rowObject.scheduledAPIDateTime)).getTime();
    let mretailItemIds = [];
    let applicableMultipackList = [];

    multipackDetail = multipackDetail.sort(
        (objA, objB) => this.localization.getDate(objA.clientMultiPacks[0].expirationDate).getTime() - this.localization.getDate(objB.clientMultiPacks[0].expirationDate).getTime(),
    );

    multipackDetail = multipackDetail.filter(f => scheduleDate <= this.localization.getDate(f.clientMultiPacks[0].expirationDate).getTime() &&
        scheduleDate >= this.localization.getDate(f.clientMultiPacks[0].startDate).getTime());

    multipackDetail.forEach(element => {
        let dretailItemIds = [];
        element.clientMultiPacks.forEach(res => {
            dretailItemIds.push(res.linkedRetailItemId)
        });
        if (dretailItemIds && dretailItemIds.length != 0 && !element.clientMultiPacks[0].isReturned && (element.clientMultiPacks[0].remaining > 0 || element.clientMultiPacks[0].remaining == -1))
            mretailItemIds.push({ tranDetailId: element.transactionDetailId, retailIds: dretailItemIds, remaining: element.clientMultiPacks[0].remaining });
    });

    //check is the selected rateType retailItemIds exist in mretailItemIds
    let multipackTranId = 0;
    let greenfeeRetailId = 0;
    let cartFeeRetailId = 0;

    if (rateSetupItem) {
        greenfeeRetailId = rateSetupItem.greenFeeRetailItemId;
        cartFeeRetailId = rateSetupItem.cartFeeRetailItemId;
        let netAvailableRounds = 0;

        //check is the existing retail item exist in multipack
        mretailItemIds.forEach(element => {
          if (element.retailIds.includes(greenfeeRetailId) && (cartFeeRetailId == 0 || (cartFeeRetailId != 0 && element.retailIds.includes(cartFeeRetailId)))) {
            if (multipackTranId == 0)
                multipackTranId = element.tranDetailId;

            const multipackItem = multipackDetail.find(w => w.transactionDetailId == element.tranDetailId);
            applicableMultipackList.push({
                multipackTranId: element.tranDetailId,
                remainingCount: element.remaining,
                greenFeePrice: multipackItem.clientMultiPacks.find(e => e.linkedRetailItemId == greenfeeRetailId).salePrice,
                cartFeePrice: cartFeeRetailId != 0 ? (multipackItem.clientMultiPacks.find(e => e.linkedRetailItemId == cartFeeRetailId).salePrice) : 0
            });

            if (element.remaining != -1 && netAvailableRounds != -1)
                netAvailableRounds += element.remaining;
            else if (element.remaining == -1 && netAvailableRounds != -1)
                netAvailableRounds = -1;
          }
        });
        const appliedMultipackTranId = this.rowObject.multiPackTransactionDetailId;
        if (multipackTranId != 0 && (netAvailableRounds == -1 || (netAvailableRounds > 0))) {
              this._Utilities.showAlert(this.commonCaptions.guest.players.linkMultipack, AlertType.Warning, ButtonType.YesNo, async (res) => {
                  if (res === AlertAction.YES) {
                    this._Utilities.ToggleLoader(true);
                    const greenFeePrice = applicableMultipackList.find(r => r.multipackTranId == multipackTranId).greenFeePrice;
                    const cartFeePrice = applicableMultipackList.find(r => r.multipackTranId == multipackTranId).cartFeePrice;
                    let multiPackDetails = await this._playerTypeRateTypeChangeService.GetTeeTimeMultiPack(multipackTranId);
                    this._Utilities.ToggleLoader(true);
                    let multiPackRetailItem = await this._playerTypeRateTypeChangeService.getRetailItems([multiPackDetails[0].multiPackRetailItemId]);
                    this._Utilities.ToggleLoader(true);
                    let multiPackRetailItems:DomainRetailItem[]  = [];
                    const multipack = cloneDeep(multiPackDetails.find(x => x.multiPackTransactionDetailId === multipackTranId));
                    const parentMultiPack = cloneDeep(multiPackRetailItem[0].id === multipack.multiPackRetailItemId ? multiPackRetailItem[0] : {});
                    parentMultiPack.salesPrice = multipack.multiPackPerSalePrice * -1;
                    parentMultiPack.itemDescription = this.localization.captions.shop.unpaidPlayers.RedeemingMultipack + ' - ' + parentMultiPack.itemDescription;
                    multiPackRetailItems.push(parentMultiPack);
                    multiPackRetailItems.push(...multipack.clientMultiPacks.map(x => {
                      let mulltiPackRetailItem = cloneDeep(retailItems.find(item => item.id === x.linkedRetailItemId));
                      mulltiPackRetailItem.salesPrice = x.salePrice;
                      mulltiPackRetailItem.gratuity = x.gratuity;
                      mulltiPackRetailItem.serviceCharge = x.serviceCharge;
                      return mulltiPackRetailItem;
                    }));
                    this.rowObject.greenFeeRetailItem = {} as DomainRetailItem;
                    this.rowObject.greenFeeRetailItemId = 0;
                    this.rowObject.cartFeeRetailItem = {} as DomainRetailItem;
                    this.rowObject.cartFeeRetailItemId = 0;
                    this.rowObject.entryFeeRetailItem = {} as DomainRetailItem;
                    this.rowObject.entryFeeRetailItemId = 0;
                    this.rowObject.multiPackRetailItem = multiPackRetailItems;
                    this.rowObject.greenFee = greenFeePrice;
                    this.rowObject.cartFee = cartFeePrice;
                    this.rowObject.multiPackTransactionDetailId = multipackTranId;
                    this.rowObject.isMultiPackApplied = true;
                    this.totalFee = greenFeePrice + cartFeePrice;
                    this.payForm.get('greenFee').setValue(greenFeePrice);
                    this.payForm.get('cartFee').setValue(cartFeePrice);
                    this.triggerCurrencyGreenFee = Math.random();
                    this.triggerCurrencyCartFee = Math.random();
                    if(this._playerTypeRateTypeChangeService.isCustomFeeEnabled){
                      await this.applyCustomFee();
                    }
                    this._Utilities.ToggleLoader(false);
                  }
              });
        } else if ((appliedMultipackTranId && appliedMultipackTranId > 0) && multipackTranId != 0 && netAvailableRounds > 0 ) {
          this._Utilities.showAlert(this.captions.guest.players.insufficientRedeemCountMsg, AlertType.Info, ButtonType.Ok);
        }
      }
  }

  resetRate(isResetForm = false)
  {
    this.rowObject.rateTypeId = 0;
    this.rowObject.greenFeeRetailItem = {} as DomainRetailItem;
    this.rowObject.greenFeeRetailItemId = 0;
    this.rowObject.cartFeeRetailItem = {} as DomainRetailItem;
    this.rowObject.cartFeeRetailItemId = 0;
    this.rowObject.entryFeeRetailItem = {} as DomainRetailItem;
    this.rowObject.entryFeeRetailItemId = 0;
    this.rowObject.greenFee = 0.00;
    this.rowObject.cartFee = 0.00;
    this.rowObject.scheduledTeeTimePlayerCustomFees =[];
    this.rowObject.isMultiPackUpdated = true;
    this.rowObject.isMultiPackApplied = false;
    this.rowObject.multiPackTransactionDetailId = 0;
    this.rowObject.multiPackRetailItem = [];
    this.rowObject.isDirty = true;
    this.totalFee = 0.00;
    this.resetOriginalDetail();
    this.payForm.markAsDirty();
    if(isResetForm)this.resetForm();
    this.enableSaveButtons(this.payForm.value);
  }

  resetOriginalDetail(){
    this.originalFeeDetail.greenFee = 0.00;
    this.originalFeeDetail.cartFee = 0.00;
    this.originalFeeDetail.greenFeeRetailItem = {} as DomainRetailItem;
    this.originalFeeDetail.greenFeeRetailItemId = 0;
    this.originalFeeDetail.cartFeeRetailItem = {} as DomainRetailItem;
    this.originalFeeDetail.cartFeeRetailItemId = 0;
  }

  resetForm(){
    this.payForm.get('rateType').setValue('');
    this.payForm.get('greenFee').setValue(0);
    this.payForm.get('cartFee').setValue(0);
    this.triggerCurrencyGreenFee = Math.random();
    this.triggerCurrencyCartFee = Math.random();
    this.resetNegotiate();
    this.enableNegotiate();
  }

  resetNegotiate(){
    this.payForm.get('negotiateGreenFee').setValue(false);
    this.payForm.get('negotiateCartFee').setValue(false);
    this.isNegotiateGreenFee = false;
    this.isNegotiateCartfee = false;
    this.rowObject.isGreenFeeNegotiated = false;
    this.rowObject.isCartFeeNegotiated = false;
  }
  enableNegotiate(){
    if (!this.isNegotiateDisabled && !this.rowObject.isMultiPackApplied) {
      this.payForm.get('negotiateGreenFee').setValue(true);
      this.payForm.get('negotiateCartFee').setValue(true);
      this.isNegotiateGreenFee = true;
      this.isNegotiateCartfee = true;
      this.rowObject.isGreenFeeNegotiated = true;
      this.rowObject.isCartFeeNegotiated = true;
      this.negotiateGfee(true);
      this.negotiateCfee(true);
    }
  }
  onNegotiatingFee()
  {
    this.calculateFee();
    this.payForm.markAsDirty();
    this.enableSaveButtons(this.payForm.value);
  }
  getCurrencyValue(value:number)
  {
    return this.localization.localizeCurrency(value, false, 2);
  }

  updateIsNegotiate(){
    this.rowObject.isNegotiated = this.rowObject.isGreenFeeNegotiated || this.rowObject.isCartFeeNegotiated;
  }
  
  isNegotiateAllow(){
    let selectedRateTypeId = this.payForm.get('rateType').value;
    console.log(selectedRateTypeId);
    if (this.isNegotiateDisabled && !this.rowObject.isMultiPackApplied) {
      let bpMessage = this._userAccess._localization.captions.breakpoint[UserAccessBreakPoints.NEGOTIATEDRATE];
      this._userAccess.showBreakPointPopup(bpMessage, undefined);
      return;
    }
    else if(selectedRateTypeId=="" || selectedRateTypeId==0){
      return false;
    }
  }

  negotiateGfee(eve) {
    this.rowObject.isDirty = true;
    this.rowObject.isGreenFeeNegotiated = eve;
    this.isNegotiateGreenFee = eve;
    if(eve){
      this.rowObject.greenFeeRetailItemId = this.rowObject.negotiateGreenFeeRetailItemId;
      this.rowObject.greenFeeRetailItem = this.rowObject.negotiateGreenFeeRetailItem;
      this.payForm.get('greenFee').setValue(0);
    }
    else if(!eve){
      this.rowObject.greenFeeRetailItemId = this.originalFeeDetail.greenFeeRetailItemId;
      this.rowObject.greenFeeRetailItem = this.originalFeeDetail.greenFeeRetailItem;
      this.payForm.get('greenFee').setValue(this.originalFeeDetail.greenFee);
    }
    this.triggerCurrencyGreenFee = Math.random();
    this.calculateFee();
    this.payForm.markAsDirty();
    this.enableSaveButtons(this.payForm.value);
  }
  negotiateCfee(eve) {
    this.rowObject.isDirty = true;
    this.rowObject.isCartFeeNegotiated = eve;
    this.isNegotiateCartfee = eve;
    if(eve){
      this.rowObject.cartFeeRetailItemId = this.rowObject.negotiateCartFeeRetailItemId;
      this.rowObject.cartFeeRetailItem = this.rowObject.negotiateCartFeeRetailItem;
      this.payForm.get('cartFee').setValue(0);
    }
    else if(!eve){
      this.rowObject.cartFeeRetailItemId = this.originalFeeDetail.cartFeeRetailItemId;
      this.rowObject.cartFeeRetailItem = this.originalFeeDetail.cartFeeRetailItem;
      this.payForm.get('cartFee').setValue(this.originalFeeDetail.cartFee);
    }
    this.triggerCurrencyCartFee = Math.random();
    this.calculateFee();
    this.payForm.markAsDirty();
    this.enableSaveButtons(this.payForm.value);
  }

  calculateFee(){
    this.totalFee = this.localization.localizeCurrency(this.payForm.controls.greenFee.value + this.payForm.controls.cartFee.value,false, 2);
    this.rowObject.isDirty = true;
    this.rowObject.greenFee = this.payForm.controls.greenFee.value;
    this.rowObject.cartFee = this.payForm.controls.cartFee.value;
  }

  updateRowFeeDetails(){
    this.rowObject.playerType = (this.payForm.get('playerType').value && this.rowObject.playerTypeInfo?.options?.find(x=> x.id == this.payForm.get('playerType').value)) ? this.rowObject.playerTypeInfo?.options?.find(x=> x.id == this.payForm.get('playerType').value)?.viewValue : '';
    this.rowObject.rateType = (this.payForm.get('rateType').value && this.rowObject.rateTypeInfo?.options?.find(x=> x.id == this.payForm.get('rateType').value)) ? this.rowObject.rateTypeInfo?.options?.find(x=> x.id == this.payForm.get('rateType').value)?.viewValue : '' ; 
    this.rowObject.greenFeeCurrency = this.localization.localizeCurrency(this.payForm.controls.greenFee.value,false, 2);
    this.rowObject.cartFeeCurrency =  this.localization.localizeCurrency(this.payForm.controls.cartFee.value,false, 2);
    this.rowObject.totalFeeCurrency = this.localization.localizeCurrency(this.payForm.controls.greenFee.value + this.payForm.controls.cartFee.value ,false, 2);
    this.updateIsNegotiate();
  }

  async onAction(eve, type) {
    if((PaymentModalType.cancel==type||PaymentModalType.close==type) || !this.payForm.valid){
      this.removeClass();
      this.onActionEmit.emit({
        emitFrom: type,
        form: this.payForm,
        rowIdx: this.rowIdx,
        rowObject:  this.backUpRowObject 
      });
    }
    else {
      this.updateRowFeeDetails();
      if(this._playerTypeRateTypeChangeService.isCustomFeeEnabled){
        this._Utilities.ToggleLoader(true);
        await this.applyCustomFee();
      }
      this.removeClass();
      this.onActionEmit.emit({
        emitFrom: type,
        form: this.payForm,
        rowIdx: this.rowIdx,
        rowObject: this.rowObject
      });
    }

  }
  removeClass() {
    // Select all elements with the class 'example-class'
    const elements = document.querySelectorAll('.cdk-overlay-backdrop');
    
    // Iterate over the selected elements and remove the class
    elements.forEach(element => {
        element.classList.remove('disable-backdrop');
    });
  }

}
