/**
 * Golf API Routes
 * 
 * Group the routes based on the feature;
 * ie., don't mix your routes with other's features
 * common route to be added at the bottom
 */

/**
 * Golf API Routes
 *
 * Group the routes based on the feature;
 * ie., don't mix your routes with other's features
 * common route to be added at the bottom
 */


export class MsalConfiguration {
  AppId: string;
  RedirectUri: string;
  Scopes: string[];
}

export enum GolfRoutes {
  // Allocation block
  GetAllocationBlock = 'allocationblocks/{id}',
  GetAllAllocationBlocks = 'allocationblocks?includeInactive={includeInactive}',
  CreateAllocationBlock = 'allocationblocks',
  UpdateAllocationBlock = 'allocationblocks',
  DeleteAllocationBlock = 'allocationblocks/{id}',
  GetAllocationBlockPlayerTypePermissions = 'AllocationBlocks/permissions',
  CreateAllocationBlockPlayerTypePermissions = 'AllocationBlocks/permissions',
  DeleteAllocationBlockPlayerTypePermissions = 'AllocationBlocks/permissions',
  CopyRolesAllocationBlockPalyerTypePermission = 'AllocationBlocks/permissions/copy/fromrole/{fromRoleId}/torole/{toRoleId}',
  GetMaxListOrder = 'allocationblocks/getmaxlistorder',
  // Instructor Confuguration
  GetInstructors = 'instructors',
  GetInstructor = 'instructors/{instructorId}',
  CreateInstructor = 'instructors',
  UpdateInstructor = 'instructors',
  // Tee color
  GetTournamentFormatGrid = 'api/tournamentformat',
  GetTees = 'api/tees/{startDate}/{active}',
  // common route
  ValidateLogin = 'validatelogin',
  Login = 'Property/GetLoginDetails',
  LoginEncrypted = 'Property/GetEncLoginDetails',
  GetEncryptKey = 'Login/encValue',
  LogOut = 'User/LogOutByUserId/{Username}/{TenantId}/{PropertyId}',
  SavePassword = 'User/SavePassword/{UserId}/{NewPassword}/{TenantId}/{PropertyId}',
  CreateSession = 'User/session',
  UpdateSession = 'User/session/sessionId/{sessionId}',
  CheckPassword = 'User/CheckPasswordExists/{UserId}/{NewPassword}/{TenantId}',
  VerifyPassword = 'User/VerifyPassword/{UserId}/{NewPassword}/{TenantId}',
  CheckPasswordPut = 'User/CheckPasswordExists',
  VerifyPasswordPut = 'User/VerifyPassword',
  SavePasswordPost = 'User/SavePassword',
  AuthorizeBySession = 'User/AuthorizeBySession',
  PasswordSetting = 'Property/GetPasswordSettings/{TenantId}',
  UserToken = 'Property/UpdatePayload',
  EnvironmentConfig = 'Login/api/Configuration',
  EatecToken = 'Login/Token/Eatec',
  AcesToken = 'Login/Token/Aces',
  QuickLoginByUserId = 'User/Login',
  QuickLoginByQuickId = 'Login/LoginByQuickId',
  // UserToken = "Property/UserToken",
  // system Setup
  GetSettings = 'settings/{module}/{screen}',
  // Rate Type
  GetAllRateTypes = 'RateTypes?showInactive={showInactive}',
  GetRateType = 'RateTypes/{id}',
  GetNextListOrder = 'RateTypes/NextListOrder',
  CreateRateType = 'RateTypes',
  UpdateRateType = 'RateTypes',
  DeleteRateType = 'RateTypes/{id}',
  GetTeeSettings = 'settings/systemsetup/teetimesetting',
  GetDefaultsSettings = 'settings/systemsetup/defaultssetting',
  GetGolfSettings = 'settings/systemsetup/golfsetting',
  UpdateSettings = 'settings',
  // Property
  GetProperty = 'property/getPropertyInfoByPropertyId/{id}',
  UpdateProperty = 'property/UpdatePropertyInfo',
  UpdatePropertySetting = 'property/PatchUpdatePropertySetting/{PropertyId}',
  GetAllPropertySettings = 'property/GetPropertySetting/{propertyId}',
  GetPaymentConfigurationByProperty = "Payment/GetPaymentConfigurationByProperty/{propertyId}",
  //Language
  GetAllLanguages = 'Language/GetAllLanguages',
  // Code Linking
  GetAllocationBlockPlayerTypes = 'allocationblocks/playerTypes',
  GetPlayerTypeRateTypes = 'playerTypes/rateTypes',
  CreateAllocationBlockPlayerType = 'allocationblocks/playerTypes/{id}',
  CreatePlayerTypeRateType = 'playerTypes/rateTypes/{id}',
  //Package Code Mapping
  GetPackageMapping = 'pmsPackage',

  //Tournament Format
  GetTournamentFormats = 'TournamentFormats',
  GetTournamentFormat = 'TournamentFormats/{id}',
  CreateTournamentFormat = 'TournamentFormats',
  UpdateTournamentFormat = 'TournamentFormats',
  DeleteTournamentFormat = 'TournamentFormats/{id}',
  //Tournament Package
  GetTournamentPackage = 'TournamentPackage',
  GetTournamentPackageByOutlet = 'TournamentPackage/outlet/{outletId}',
  CreateTournamentPackage = 'TournamentPackage',
  UpdateTournamentPackage = 'TournamentPackage',
  DeleteTournamentPackage = 'TournamentPackage/{id}',
  //PlayerType
  GetPackageLinkedPlayerTypesRateTypes = 'PMSPackage/packageCode/{code}',
  GetAllPlayerTypes = 'PlayerTypes/playertypes/active/{isActive}',
  GetPlayerType = 'PlayerTypes/{id}',
  CreatePlayerType = 'PlayerTypes',
  UpdatePlayerType = 'PlayerTypes',
  DeletePlayerType = 'PlayerTypes/{id}',
  GetNextAvailableListOrder = 'PlayerTypes/nextlistorder',
  AllowToInactive = 'TeeTimes/IsPlayerTypeAssociatedToTeeTime/{playerTypeId}',
  // Course
  GetCourses = 'Courses/active/{includeInactive}',
  GetCoursesWithAccess = 'Courses/active/{includeInactive}?filterUserAccessedCourse={filterUserAccessedCourse}',
  GetCourse = 'Courses/{id}',
  CreateCourse = 'Courses',
  UpdateCourse = 'Courses',
  DeleteCourse = 'Courses/{id}',
  GetNextCourseListOrder = 'Courses/NextListOrder',
  //Season
  GetSeason = 'Season?showInactive={showInactive}',
  GetSeasonUsage = 'Season/{id}',
  CreateSeason = 'Season',
  UpdateSeason = 'Season',
  DeleteSeason = 'Season/{id}',
  GetNextSeasonListOrder = 'Season/NextListOrder',
  //Tee Color
  GetAllTeeColors = 'TeeColors',
  GetTeeColor = 'TeeColors/{id}',
  CreateTeeColor = 'TeeColors',
  UpdateTeeColor = 'TeeColors',
  DeleteTeeColor = 'TeeColors/{id}',
  GetTeeColorsByCourseId = 'TeeColors/Course/{id}',
  //Retail Item
  GetOutletRetailItems = 'RetailItems/outlet/{outletId}',
  GetRetailItemsByPagination = 'RetailManagementService/RetailItems/items/PresentInAllOutlets?outletId={outletId}&includeInactive={includeInactive}&filterByPropertyAndProduct={filterByPropertyAndProduct}&pageStart={pageStart}&pageLength={pageLength}&sortBy={sortBy}&isSortByAscending={isSortByAscending}&searchText={searchText}&category={category}&subcategory1={subcategory1}&subcategory2={subcategory2}&subcategory3={subcategory3}&subcategory4={subcategory4}&subcategory5={subcategory5}',
  GetAllActiveRetailItems = 'RetailItems/active',
  GetRetailItemById = 'RetailItems/{itemId}',
  GetAllCategories = 'Categories/active',
  GetAllSubCategories = 'SubCategories/active',
  GetAllActiveRetailItemsByIds = 'RetailItems/query',
  GetRetailItemDetailedInfo = 'retailitems/detailed-info?id={id}',
  GetRetailItemDetailedInfoList = "retailitems/detailed-infolist",
  GetDiscountConfigurationsForRetailItem = "RetailManagementService/DiscountConfigurations/{date}/{retailItemId}/{type}",
  //TournamentComponent
  CreateTournamentComponent = 'Tournament/Component',
  GetAllTournamentComponent = 'Tournament/Component',
  UpdateTournamentComponent = 'Tournament/Component',
  DeleteTournamentComponent = 'Tournament/Component/{id}',
  //PayAgent
  GetHandles = 'GetHandles',
  CreateToken = 'CreateToken',
  Sale = 'sale',
  Credit = 'Credit',
  GetUserSessionConfiguration = 'UserSessionConfiguration/user/{userId}',
  //Lesson Location
  GetLessonLocations = 'LessonLocations?showInactive={showInactive}',
  CreateLessonLocation = 'LessonLocations/{lessonLocation}',
  UpdateLessonLocation = 'LessonLocations',
  DeleteLessonLocation = 'LessonLocations/{id}',

  //Cancel Reasons

  GetCancelReasons = 'CancelReasons?showInactive={showInactive}',
  CreateCancelReason = 'CancelReasons',
  DeleteCancelReason = 'CancelReasons/{id}',
  UpdateCancelReason = 'CancelReasons',

  
  //Lost Or Denied Reason
  GetLostOrDeniedReasons = 'LostDeniedReasons?IncludeInactive={showInactive}',
  CreateLostOrDeniedReason = 'LostDeniedReasons',
  DeleteLostDeniedReason = 'LostDeniedReasons/{id}',
  UpdateLostOrDeniedReason = 'LostDeniedReasons/{id}',


  //CourseComment
  GetCourseComment = 'CourseComments?inclHistDate={inclHistDate}',
  CreateCourseComment = 'CourseComments',
  UpdateCourseComment = 'CourseComments',
  DeleteCourseComment = 'CourseComments/{id}',
  GetCourseCommentById = 'CourseComments/{id}',
  GetCourseCommentByCourseIdAndScheduleDate = 'CourseComments/course-comment?courseId={courseId}&scheduleDate={scheduleDate}',
  //Payment
  StoreToken = 'Payment/StoreToken',
  GetCardInfo = 'Payment/GetCardInfoByTransaction/{tokenTransId}',
  AutoRetailTransaction = 'RetailInterface/InitiateAutoRetailTransaction',
  GetTransactionDepositDetails = "PointOfSaleService/RetailInterface/GetTransactionDepositDetails",
  MarkAsPaidPlayer = 'TeeTimes/Action/Player/MarkAsPaid',
  //Credicard Configuration
  GatewayConfiguration = 'GatewayConfiguration',
  PaymentMethod = 'GetPaymentMethodsByProductId',
  GetTeeTimeMultiPack = 'PointOfSaleService/MultiPack/GetMultipleItemMultipacksByTransactionDetailIds',
  GetRetailItemByItemType = 'retailitems/type/{type}',
  GetAllTransactions = 'transaction/{status}/{outletId}/{transactionDate}',
  GetTransactionDetails = 'transaction/GetTransactionDetails/{transactionId}/productId/{productId}',
  GetTransactionById = 'transaction/transactionlist/{transactionId}',
  GetTransactionsByIds = 'transaction/GetTransactionsByIds',
  GetTransactionStatusById = 'transaction/{transactionId}/CheckTicketStatus',
  PaymentHistory = 'transaction/{transactionId}/payment/history',
  GetPlayerNamesByTransactionId = 'PlayerTransactions/{transactionId}',
  GetPlayerTransactionDetailListById = 'PlayerTransactions/list',
  //Tee Time Allocation Blocks
  GetTeeTimeAllocationBlocks = 'TeeTimeAllocationBlocks',
  GetTeeTimeAllocationBlocksById = 'TeeTimeAllocationBlocks/{id}',
  GetTeeTimeAllocationBlocksByDateRange = 'TeeTimeAllocationBlocks/{courseId}/{startDate}/{endDate}',
  GetTeeTimeAllocationBlocksByCourseDateRange = 'TeeTimeAllocationBlocks/allocationBlock/{courseId}/{startDate}/{endDate}',
  GetTeeTimeAllocationBlockByCourseIdAllocationDateTime = 'TeeTimeAllocationBlocks/course/{courseId}/{allocatedDateTime}/{holeNumber}',
  SaveTeeTimeAllocationBlocks = 'TeeTimeAllocationBlocks?teeTimeAllocationBlockEvent={teeTimeAllocationBlockEvent}',
  UpdateDefaultTeeTimeAllocationBlocks = 'TeeTimeAllocationBlocks/defaultBlocks/{allocationBlockId}',
  getTeeTimeAllocationBlocksForBulkTeeTime = 'TeeTimeAllocationBlocks/GetTeeTimeAllocationBlock',
  // Tournament Player
  GetAllTournamentPlayers = 'tournamentplayers',
  GetTournamentPlayers = 'tournamentplayers/tournament/{tournamentId}?includeDummy={includeDummy}',
  GetTournamentPlayer = 'tournamentplayers/{tournamentPlayerId}',
  CreateTournamentPlayer = 'tournamentplayers',
  UpdateTournamentPlayer = 'tournamentplayers',
  BulkAddTournamentPlayers = 'tournamentplayers/bulk',
  DeleteTournamentPlayer = 'tournamentplayers/{tournamentPlayerId}',
  CopyTournamentPlayer = 'tournamentplayers/copy/{tournamentPlayerId}',
  SaveTournamentPlayers = 'tournamentplayers/save',
  DeleteAllTournamentPlayers = 'tournamentplayers/deleteAllTournamentPlayers/{tournamentId}',
  //Rate Setup
  GetRateCourseTeeFees = 'CourseTeeFees',
  GetRateCourseTeeFee = 'CourseTeeFees/{id}',
  GetRateCourseTeeFeesByDateFilter = 'CourseTeeFees/teefee?fromDate={fromDate}&toDate={toDate}',
  GetRateCourseTeeFeesByDateCourseRateTypeFilter = 'CourseTeeFees/teefee?fromDate={fromDate}&toDate={toDate}',
  CreateRateCourseTeeFee = 'CourseTeeFees',
  CreateRateMultiCourseTeeFee = 'CourseTeeFees/multiCourse',
  UpdateRateCourseTeeFee = 'CourseTeeFees',
  DeleteRateCourseTeeFee = 'CourseTeeFees/{id}',
  GetTeeFeeForCourseAndDate = 'CourseTeeFees/teefees/CourseTeeFeeForCourseIdAndDate?date={date}&courseId={courseId}',
  GetCourseTeeFeeForCourseDateAndRateType = 'CourseTeeFees/teefees/CourseTeeFeeForCourseIdDateAndRateTypeId?date={date}&courseId={courseId}&rateTypeId={rateTypeId}',
  MultipleDeleteRateCourseTeeFee = 'CourseTeeFees',
  //Dynamic Pricing
  GetDynamicPricingById = 'DynamicPricing/{groupId}/override/{overrideGroupId}',
  CreateDynamicPricing = 'DynamicPricing',
  UpdateDynamicPricing = 'DynamicPricing',
  GetDynamicPricingCoursesAndSeasons = 'DynamicPricing',
  GetDynamicPricingCoursesAndSeasonsRate = 'DynamicPricing/{courseId}/{seasonId}/{rateTypeId}?withRateFilter={withRateFilter}',
  GetDynamicPricingName = 'DynamicPricing/{courseId}/{seasonId}?rateTypeId={rateTypeId}',
  DeleteDynamicPricingRules = 'DynamicPricing/Rules?date={date}&deleteUsedPricing={deleteUsedPricing}',
  DeleteDynamicPricingByCourse = 'DynamicPricing?courseId={courseId}&seasonId={seasonId}&rateTypeId={rateTypeId}&deleteUsedPricing={deleteUsedPricing}',
  DeleteDynamicPricingByGroupId = 'DynamicPricing/Group/{dynamicPricingGroupId}?deleteUsedPricing={deleteUsedPricing}',
  DeleteDynamicPricing = 'DynamicPricing/{id}?deleteUsedPricing={deleteUsedPricing}',
  DeleteOverrideDynamicPricingByGroupId = 'DynamicPricing/Group/{overrideGroupId}/{dynamicPricingGroupId}?deleteUsedPricing={deleteUsedPricing}',
  //Custom Field
  GetAllCustomFieldsAndValues = 'CustomFields',
  GetCustomFieldAndValuesById = 'CustomFields/{id}',
  UpdateCustomFieldAndValues = 'CustomFields',
  UpdateCustomFieldsIsUtilized = 'CustomFields/utilized',
  //UnPaidPlayers
  GetScheduledTeeTimePlayersByPaymentStatus = 'TeeTimes/Players/{courseId}/{scheduledDate}/{status}/{userDefaultOutletId}/{isFromMarkAsPaid}/{isFromDeposit}/{isFromPaymentModal}',
  GetScheduledTeeTimePlayersPaidAndUnPaid = 'TeeTimes/Players/course/{courseId}/{scheduledDate}/{userDefaultOutletId}/{teeTimeAction}',
  GetScheduledTeeTimePlayerByPaymentStatus = 'TeeTimes/Player/{playerId}/{courseId}/{scheduledDate}/{playerStatus}?userDefaultOutletId={userDefaultOutletId}',
  //Tee Time Actions
  GetRatesByCourseId = 'TeeTimes/courses/{courseId}/datetime/{dateTime}',
  UpdateTeeTimePlayerDeposit = 'TeeTimes/UpdateTeeTimePlayerDeposit',
  //Void Player Transactions
  VoidPlayerTransactions = 'TeeTimes/Player/Void/Transaction/{transactionId}/Ticket/{ticketNumber}',
  //Cancel Player Transactions
  CancelPlayerTransactions = 'PlayerTransactions/Cancel/{transactionId}',
  //SyncUp Transaction
  SyncUpTransactions = 'PlayerTransactions/SyncUp',
  //Plan Components
  GetAllPlanComponensByComponentIds = 'PlanComponents/GetPlansByComponents/{outletId}',
  GetAllPlanComponentAggregate = 'PlanComponents',
  CreatePlanComponent = 'PlanComponents',
  RemovePlanComponent = 'PlanComponents/{componentId}/{planId}',
  //Tee Time Config
  CreateTeeTimeConfig = 'TeeTimeConfigs?overWrite={overWrite}',
  GetAllTeeTimeConfig = 'TeeTimeConfigs',
  GetTeeTimeConfigByID = 'TeeTimeConfigs/{id}',
  UpdateTeeTimeConfig = 'TeeTimeConfigs?overWrite={overWrite}',
  DeleteTeeTimeConfig = 'TeeTimeConfigs/{id}',
  DeleteTeeTimeById = 'TeeTimeConfigs/{id}',
  GetAllTeeTimeConfigByCourseID = 'TeeTimeConfigs/{courseId}/{startDate}/{endDate}',
  // Tournament Scorecard
  GetTournamentScores = 'TournamentScore?tournamentId={tournamentId}',
  UpdateTournamentScores = 'TournamentScore',
  GetTournamentWinners = 'TournamentScore/Winner?tournamentId={tournamentId}',
  DeleteTournamentScores = 'TournamentScore',
  GetTournamentScoresByPlayerID = 'TournamentScore/{playerId}',
  // Tenant management
  GetAllBreakPoint = 'user/GetAllUserClaimsAsync',
  GetBreakPoint = 'user/GetUserClaimsAsync/{roleID}?{bkpn:QueryString}',
  GetAllUserRole = 'userRole/GetUserRoleByTenantId/{tenantId}',
  GetUserClaimsForRoleAsync = 'user/GetUserClaimsForRoleAsync/{RoleId}',
  GetUserRoleById = 'UserRole/GetUserCountsById/{id}',
  GetActiveUserRole = 'UserRole/GetActiveUserRoleByTenantId/{tenantId}/{includeInActive}',
  GetActiveUserRolesByPropertyId = 'UserRole/GetActiveUserRolesByPropertyId/{propertyId}/{includeInActive}',
  GetUserRoleByPropertyId = 'UserRole/GetUserRoleByPropertyId/{propertyId}',
  UpdateUserRole = 'UserRole/UpdateUserRole',
  CreateUserRole = 'UserRole/CreateUserRole',
  DeleteUserRole = 'userRole/DeleteUserRole',
  //New api CheckDupicateUserRoleExist has been added with input as property id instead of tenant id and to validate role code and name for Tenant, Golf and Spa
  CheckUserRoleExist = 'userRole/CheckUserRoleExist/{propertyId}/{roleName}',
  CheckDupicateUserRoleExist = 'UserRole/CheckDupicateUserRoleExist/{propertyId}/{roleCode}/{roleName}',
  GetUserCountsByRoleId = 'userRole/GetUserCountsByRoleId/{roleId} ',
  GetUserRoleConfiguration = 'userRole/GetUserClaimsByRoleId/{userRoleId}',
  GetProducts = 'Product/GetAllProducts',
  GetStandAloneProducts = 'Product/GetAllStandAloneProducts',
  GetProductsByPropertyId = 'Product/GetProductsByPropertyId/{propertyId}',
  GetOutlets = 'Outlets/property/{propertyId}',
  GetAllUsers = 'User/GetAllUserbyTenantId/{tenantId}?inActive={inActive}&includeServiceUsers={includeServiceUsers}',
  GetAllUsersByPropertyId = 'User/GetAllUserbyPropertyId/{propertyId}/{productId}',
  GetAllUsersbyUserIds = 'User/GetAllUserbyUserIds/{tenantId}?{ids:QueryString}',
  GetPropLanguages = 'Property/GetAllLanguageByPropertyId/{propertyId}',
  GetUserRetailConfiguration = 'UserRetailConfiguration/{id}',
  GetAllUserRetailConfiguration = 'UserRetailConfiguration/all',
  CreateUser = 'user/CreateUser/{PropertyId}',
  UpdateUser = 'user/UpdateUser/{PropertyId}',
  CreateUserRetailConfig = 'UserRetailConfiguration/create',
  UpdateUserRetailConfig = 'UserRetailConfiguration/update',
  CreateUserOutletAccess = 'UserOutlets',
  UpdateUserOutletAccess = 'UserOutlets',
  GetDuplicateUserByName = 'user/GetDuplicateUserByName/{userId}?{tenantId:QueryString}&{userName:QueryString}',
  GetDuplicateUserByQuickId = 'user/GetDuplicateUserByQuickId/{userId}?{tenantId:QueryString}&{quickId:QueryString}',
  BlockUserProfile = 'user/BlockUserProfile?{userId:QueryString}&{accountBlocked:QueryString}',
  GetStoreTerminal = "Outlets/GetStoreTerminal/{outletId}",
  //outlet
  // // GetOutletsByProperty = 'Property/GetSubPropertiesByPropertyId/{PropertyId}',
  // // AddOutletsToProperty = 'Property/AddSubPropertyByPropertyId',
  // // UpdateOutletsOfProperty = 'Property/UpdateSubPropertyByPropertyId',
  // // RemoveOutletsOfProperty = 'Property/RemoveSubPropertyByPropertyId/{propertyId}/SubPropertyId/{subPropertyId}',
  // // GetSubPropertyAccessByUser = 'User/Outlet/{propertyId}/user/{userId}',
  GetOutletsByProperty = 'Outlets',
  AddOutletsToProperty = 'Outlets',
  UpdateOutletsOfProperty = 'Outlets/{id}',
  RemoveOutletsOfProperty = 'Outlets/{id}',
  GetSubPropertyAccessByUser = 'UserOutlets/user/{userId}',
  GetOutletsAccessByPropertyId = 'UserOutlets/property',
  //Tournament
  GetTournamentsByDateFilter = 'Tournaments/search/{fromDate}/{toDate}',
  CreateTournament = 'Tournaments',
  UpdateTournament = 'Tournaments',
  GetTournamentById = 'Tournaments/GetTournamentById?tournamentId={tournamentId}',
  GetTournamentDetailsBySearchParameters = 'Tournaments/{fromDate}/{toDate}/{searchKey}/{searchValue}',
  GetTournamentByEventCode = 'Tournaments/GetTournamentByEventCode?eventCode={eventCode}',
  UpdateTournamentContactInfo = 'Tournaments/UpdateTournamentContact?playerId={playerId}&tournamentId={tournamentId}',
  DeleteTournament = 'Tournaments/{tournamentId}',
  // Golf users
  GetGolfUserConfig = 'UserConfiguration/{id}',
  CreateGolfUserConfig = 'UserConfiguration',
  UpdateGolfUserConfig = 'UserConfiguration',
  DeleteGolfUserConfig = 'UserConfiguration/{userId}',
  getAllUserCourses = 'UserConfiguration/{userId}/course',
  // Rain Check Setup
  GetAllRainCheckSetup = 'RainCheckSetup',
  GetRainCheckSetup = 'RainCheckSetup/{courseId}',
  CreateRainCheckSetup = 'RainCheckSetup',
  UpdateRainCheckSetup = 'RainCheckSetup',
  CopyRainCheckSetup = 'RainCheckSetup/CopyRainCheck/{from}/{to}/{isAllowCopy}',
  //Member
  SearchMembers = 'Membership/SearchGuests/{pattern}/{searchType}',
  SearchMembersByKeyAndValue = 'Membership/GetMemberByKeyAndValue/{searchKey}/{searchValue}',
  GetMemberInfoByCardNo = 'Membership/GetMemberByCardNo/{cardNo}/{scheduleDateTime}?corpId={corpId}',
  GetMemberToken = 'Membership/GetAcesToken',
  GetAllPaymentRecords = 'Membership/GetAllPaymentRecords',
  GetPaymentRecordsbyTransactionId = 'Membership/GetPaymentRecord/{TransactionId}',
  CreateACESPaymentRecord = 'Membership/CreatePaymentRecord',
  ARPost = 'Membership/ARPost',
  RedeemPoint = 'Membership/RedeemPoint',
  MemberSummary = "Membership/CreateACESPaymentSummary",
  GetMember = "transaction/GetPaymentById/{transactionId}",
  // Rain Check Issue
  GetRainCheckIssueByTeeTime = 'RainCheck/{teeTimeId}',
  GetAllRainCheckIssueByTeeTime = 'RainCheck/All/{teeTimeId}',
  GetRainCheckPlayerTaxes = 'RainCheck/GetPlayersTaxInfo/{teeTimeId}',
  CreateIssueRainCheck = 'RainCheck',
  DeleteIssueRainCheck = 'RainCheck',
  GetRainChecks = 'RainCheck/GetRainChecks/{issuedDate}?searchText={searchText}',
  UpdateRainCheck = 'RainCheck',
  UnRedeemRainCheck = 'RainCheck/UnReedemRainChecks/{ticketNumber}',
  UnRedeemRainCheckByIds = 'RainCheck/UnRedeemRainChecks',
  //TeeTimes Actions
  BookTeeTime = 'TeeTimes',
  UpdateTeeTime = 'TeeTimes',
  TempHoldTeeTime = 'TeeTimes/TempHold',
  ReleaseTempHoldTeeTime = 'TeeTimes/ReleaseTempHold',
  TempHoldTeeTimes = 'TeeTimes/TempHolds',
  ReleaseTempHoldTeeTimes = 'TeeTimes/ReleaseTempHolds',
  IsTempHoldExist = 'TeeTimes/IsTempHold?courseId={courseId}&scheduleDateTime={scheduleDateTime}&originalHoleNumber={originalHoleNumber}',
  HoldTeeTime = 'TeeTimes/Courses/{courseId}/schedules/{scheduledDateTime}/holes/{holeNumber}/original/{originalHoleNumber}/hold',
  ReleaseTeeTime = 'TeeTimes/courses/{courseId}/schedules/{scheduledDateTime}/holes/{holeNumber}/release',
  MoveTeeTime = 'TeeTimes/schedules/{scheduleId}/players/Move',
  CopyTeeTime = 'TeeTimes/schedules/{scheduleId}/players/Copy',
  ExtendTempHold = 'TeeTimes/TempHolds?holdMinutes={holdMinutes}',
  SqueezeTeeTime = 'SqueezeTeeTime',
  ClearPlayerTeeTime = 'TeeTimes/players/Clear',
  CancelTeeTimePlayers = 'TeeTimes/players/Cancel',
  BulkCancelTeeTimePlayers = 'TeeTimes/players/BulkCancel',
  ReinstatePlayers = 'TeeTimes/schedules/players/reinstate',
  NoShowTeeTimePlayer = 'TeeTimes/schedules/{scheduleId}/players/{playerId}/noshow',
  NoShowTeeTimePlayerForMultiple = 'TeeTimes/schedules/players/noshow',
  ReBookTeeTimePlayerForMultiple = 'TeeTimes/schedules/players/rebook',
  ReBookTeeTimePlayer = 'TeeTimes/schedules/{scheduleId}/players/{playerId}/rebook',
  ReleaseTeeTimes = 'TeeTimes/schedules/releaseteetimes/{holdDuration}',
  ReleaseTeeTimeHold = 'TeeTimes/release/{courseId}/{scheduledDateTime}/{holeNumber}',
  RemoveAutoRelease = 'TeeTimes/schedules/removeautorelease',
  ValidateGuaranteeRule = 'TeeTimes/validate/guarantee',
  UpdateTeeTimePlayerToGuest = 'TeeTimes/UpdateTeeTimePlayerToGuest',
  UpdateCheckInOutStatus = "TeeTimes/Action/{teeTimeAction}/Update/Player",
  InsertTeeTimeDeletedSlot = "TeeTimes/Action/Delete/Slot",
  GetTeeTimesByActitivyId = 'TeeTimes/scheduleTeeTime/activityId/{activityId}',
  ValidateBookTeeTimeRules = 'TeeTimes/ValidateBookTeeTimeRules?ModuleCode={ModuleCode}',
  OverLappingTeeTimeSlotsForGuest = 'TeeTimes/OverlappingSlotsForGuest/{timeOfHours}/{bookingDateTime}/{courseId}',
  //Tee Time Search
  GetTeeTimeById = 'TeeTimes/{scheduleId}',
  GetScheduledTeeTimesByDateRange = 'TeeTimes?courseId={courseId}&scheduledStartDate={startDate}&scheduledEndDate={endDate}',
  GetTeeTimesofCourseByDate = 'TeeTimes/{courseId}/date/{scheduledDate}',
  GetTeeTimesScheduleSearch = 'TeeTimes/{courseId}/{playerstatus}/{fromDate}/{toDate}/{isChangePlayerCategory}?pmsActivityId={pmsActivityId}',
  GetTeeTimesDetails = 'TeeTimes/{courseId}/{playerId}/{date}',
  //Bulk Actions
  GetTeeTimesSlotDetailsByDateRange = 'BulkActions?scheduledStartDate={startDate}&scheduledEndDate={endDate}&courseId={courseId}',
  HoldBulkTeeTime = "BulkActions/BulkHolds",
  ReleaseBulkTeeTime = "BulkActions/BulkHoldRelease",
  BlockBulkTeeTime = "BulkActions/BlockBulkTeeTime",
  UnBlockBulkTeeTime = "BulkActions/UnBlockBulkTeeTime",
  //Tee Sheet
  GetTeeSlots = 'TeeSheets?date={date}&courseId={courseId}',
  GetTeeTimes = 'TeeSheets/teetimes?date={date}&courseId={courseId}&isAdvancedView={isAdvancedView}',
  GetAllMemberTeeTimesOnDate = 'TeeTimes/GetMemberPlayerDetailsByDateAndCourseId?payDate={date}&courseId={courseId}',
  GetTeeTimesByDate = 'TeeSheets/teetimes?date={date}',
  GetTeeTimesSlotByDate = 'TeeSheets/teetimesslot?date={date}&courseId={courseId}',
  GetCourseCommentByCourseIdAndDate = 'CourseComments?courseId={courseId}&scheduleDate={scheduleDate}',
  GetTeeTimesByCourse = 'TeeTimes/courses/{courseId}',
  UpdateUserRoles = 'user/UpdateUserClaimsAsync',
  CopyUserRoles = 'user/CopyUserClaimsAsync/{from}/{to}',
  GetAllPlayers = 'players',
  GetPlayerById = 'players/{id}',
  GetPlayerByPlayerLinkId = 'players/playerLinkId/{id}/{isTeeTimeBook}',
  GetPlayersByIds = 'players/GetPlayersByIds',
  GetPlayerContactInfoByPlayerIdList = 'players/ContactInfoByPlayerIdlist',
  CreatePlayer = 'players',
  UpdatePlayer = 'players',
  DeletePlayer = 'players/{id}',
  SearchGuests = 'Players/search/{key}/{searchType}?isExternalGuestSearch={isExternalGuestSearch}&isPlatformGuestSearch={isPlatformGuestSearch}',
  UpdateGuestRequest = 'Players/UpdateGuestRequest/{guestId}/{playerId}',
  SearchExistingGuests = 'Players/searchExistingGuest/{key}/{searchCriteria}/{isTeeTimeBook}?isExternalGuestSearch={isExternalGuestSearch}&isPlatformGuestSearch={isPlatformGuestSearch}',
  //PlayerProfile
  CreatePlayerProfile = 'playerProfiles',
  UpdatePlayerProfile = 'playerProfiles',
  GetPlayerProfileById = 'playerProfiles/{id}',
  GetAppointmentsByStatus = 'playerProfiles/GetItinerary/{id}?fromDate={fromDate}&toDate={toDate}',
  GetGuestSalesHistoryTransaction = 'playerProfiles/GetSalesHistory/{id}',
  GetItneraryforPlayer = 'playerProfiles/GetItinerary/{id}?fromDate={fromDate}&toDate={toDate}',
  GetTeeTimeForPlayer = 'playerProfiles/GetTeeTime/{id}',
  SearchPlayerProfileByName = 'playerProfiles/search/{pattern}?isExternalGuestSearch={isExternalGuestSearch}&isPlatformGuestSearch={isPlatformGuestSearch}',
  SearchPlayerProfileByKeyAndValue = 'playerProfiles/search/{searchKey}?IncludePurgedData={IncludePurgedData}&isExternalGuestSearch={isExternalGuestSearch}&isPlatformGuestSearch={isPlatformGuestSearch}',
  SearchInHouseGuests = 'Players/searchInHouseGuests?key={key}&PMSStayDate={PMSStayDate}&searchFilter={searchFilter}',
  SearchInStayGuests = 'Players/SearchInStayGuests',
  GetGuestStayDetails = 'Itinerary/clients/{clientId}/stay',
  getMatchingGuests = 'playerProfiles/GetMatchingGuests',
  //FrostDelay
  frostDelayTeeTime = 'TeeTimes/courses/{courseId}/schedules/{scheduledDateTime}/holes/{holeNumber}/frostdelay',
  //TournamentTeeTimes
  GetTournamentTeeTimesByTournamentId = 'TournamentTeeTimes/teetimes/{tournamentId}',
  BookTournamentTeeTime = 'TournamentTeeTimes/{isProxyPlayers}',
  DeleteTournamentTeeTimes = 'TournamentTeeTimes/{tournamentId}',
  //Caddy Type
  GetAllCaddyType = 'CaddyTypes?showInActive={showInActive}',
  GetAllCaddyTypesByDate = 'CaddyTypes/requirements/{courseId}/{slotTime}/{showInActive}',
  CreateCaddyType = 'CaddyTypes',
  UpdateCaddyType = 'CaddyTypes',
  DeleteCaddyType = 'CaddyTypes/{id}',
  GetNextListOrder_CaddyTypes = 'CaddyTypes/NextListOrder',
  //Report
  GetReport = 'report',
  GetTemplateReport = "report/templatereport",
  GetAllTransactionLogs = 'Report/TransactionLogs/{scheduleTeeTimeId}/{isOpenSlot}/{courseId}/{scheduledDateTime}/{originalHoleNumber}',
  GetAuditTrailDataForOpenSlots = 'Report/TransactionLogsForOpenSlots/{courseId}/{scheduledDateTime}/{originalHoleNumber}',
  //Instructor Schedule
  CreateInstructorSchedule = 'InstructorSchedule/{isOverwriteExisting}',
  GetInstructorSchedule = 'InstructorSchedule/{instructorId}/{startDate}/{endDate}',
  GetInstructorScheduleById = 'InstructorSchedule/instructorAvailability/{instructorId}/{noOfDays}',
  GetAllInstructorSchedule = 'InstructorSchedule/{startDate}/{endDate}',
  //caddy Setup
  GetCaddies = 'Caddies/{includeInactive}',
  GetActiveCaddies = 'Caddies/false',
  CreateCaddySetup = 'Caddies',
  UpdateCaddySetup = 'Caddies',
  UpdateCaddySetupStatus = 'Caddies/{id}/{isActive}',
  GetCaddyStatus = 'TeeTimes/caddy/{caddyId}/{scheduledDate}',
  //Caddy Schedule
  GetCaddySchedule = 'CaddySchedule/{caddyId}/{startDate}/{endDate}',
  CreateCaddySchedule = 'CaddySchedule/{isOverwriteExisting}',
  GetAllCaddySchedule = 'CaddySchedule/{startDate}/{endDate}',
  GetAllCaddyScheduleForTeeTime = 'CaddySchedule/scheduleForTeetime?date={date}&courseId={courseId}&scheduleId={scheduleId}',
  //drag drop tee sheet
  ValidateTeeTimeAction = 'TeeTimes/schedules/{scheduleId}/players/teeActions/{teeAction}/Validate',
  //Dashboard
  GetCourseCount = 'DashBoard/getCourseCount',
  GetTeeTimesCount = 'DashBoard/{dateTime}/getTeeTimesCount',
  GetPlayersCount = 'DashBoard/{dateTime}/getPlayersCount',
  GetPlayerTeeTimeCount = 'DashBoard/{dateTime}/getPlayerTeeTimeCount?isRepeatPlayersCountRequired={isRepeatPlayersCountRequired}',
  GetDashboardLessons = 'DashBoard/{dateTime}/getLessons',
  GetUpComingTournaments = 'DashBoard/{dateTime}/getUpComingTournaments',
  GetDashBoardWaitlists = 'DashBoard/{dateTime}/getWaitlists',
  GetDashBoardTeeSheet = 'DashBoard/{date}/getTeeSheet{courseId}',
  GetCourseUtilization = 'DashBoard/getCourseUtilization/course/{courseId}/date/{dateTime}/time/{startTime}/{endTime}',
  GetItemSaleDetail = 'Transaction/getItemSale/{startDate}/{endDate}',
  GetOutletSaleDetail = 'Transaction/getOutletSale/{startDate}/{endDate}',
  GetCategorySaleDetail = 'Transaction/getCategorySale/{startDate}/{endDate}',
  GetTransactionSaleDetail = 'Transaction/getTransactionSale/{startDate}/{dataFormat}',
  //Default User Configuration
  CreateDefaultUserConfiguration = 'UserSessionConfiguration/create',
  UpdateDefaultUserConfiguration = 'UserSessionConfiguration/update',
  GetDefaultUserConfiguration = 'UserSessionConfiguration/user/{userId}',
  // Global search
  GlobalSearch = 'globalsearch/{pattern}?isExternalGuestSearch={isExternalGuestSearch}',
  SearchInstructor = 'Instructors/search/{pattern}',
  SearchRetailItem = 'retailitems/search/name',
  SearchPlayer = 'Players/search/{pattern}',
  SearchTeeTime = 'TeeTimes/search/{pattern}',
  //WaitList
  CreateWaitList = 'WaitList',
  UpdateWaitList = 'WaitList',
  DeleteWaitList = 'WaitList/{id}',
  GetWaitListByCourseId = 'WaitList?dateTime={dateTime}&courseId={courseId}',
  //Cancel and Reinstate Players
  GetCancelTeeTimePlayers = 'CancelPlayers/courses/{courseId}/bookedDateTime/{bookedDateTime}',
  SearchScheduledPlayersByDateAndCourse = 'TeeTimes/SearchScheduledPlayers/{scheduledDate}',
  GetScheduledPlayersByDateAndCourse = 'TeeTimes/scheduledPlayersbycoursesanddaterange/{startDate}/{endDate}',
  GetReinstatePlayers = 'CancelPlayers/reinstate/courses/{courseId}/bookedDateTime/{bookedDateTime}/pmsActivityId/{pmsActivityId}',
  GetInstructorsByDate = 'LessonBooking/{scheduleStarteDate}/{scheduleEndDate}',
  //Lesson
  BookLesson = 'LessonBooking',
  UpdateLesson = 'LessonBooking',
  CancelLesson = 'LessonBooking/CancelLesson',
  GetLessonBookingTimeInterval = 'LessonBooking',
  GetLessonBooking = 'LessonBooking/{lessonScheduledId}',
  GetInstructorForLesson = 'LessonBooking/ScheduledInstructors/{scheduledDate}',
  GetAllLessionBooking = 'LessonBooking/ScheduledLessons/{scheduledDate}',
  SearchScheduledLesson = 'LessonBooking/search/{pattern}',
  IsLessonBookedforInstructor = 'LessonBooking/LessonBookedforInstructor/{instructorId}',
  GetLessonBookingDetails = 'LessonBookingDetails/{scheduledDate}',
  BlockLesson = 'LessonBooking/block',
  UnblockLesson = 'LessonBooking/{id}',
  BookRecurringLesson = 'LessonBooking/Recurring',
  UpdateRecurringLesson = 'LessonBooking/Recurring',
  CancelRecurringBooking = 'LessonBooking/CancelRecurringLesson',
  GenerateNewId = 'LessonBooking/GenerateGuid',
  BookBulkLesson = 'LessonBooking/BulkLesson',
  EditBulkLesson = 'LessonBooking/EditBulkLesson',
  CancelBulkLesson = 'LessonBooking/CancelBulkLesson',
  IsLessonOverlap = 'LessonBooking/IsLessonOverlap',
  GetScheduledLessonsByBulkGroupId = 'LessonBooking/ScheduledLessonsByBulkGroupId/{bulkGroupId}',
  // Day-End Validations
  PerformDayEnd = 'DayEnd/process/date/{currentDate}/{isAllowFutureDate}',
  //BulkTeeTime
  BulkBookTeeTime = 'BulkTeeTimeSchedules',
  BulkEditTeeTime = 'BulkTeeTimeSchedules/canEditCaddy/{canEditCaddy}',
  GetScheduledTeeTimePlayers = 'TeeTimes/players/bookingId/{bookingId}?isSortRequired={isSortRequired}',
  GetScheduledTeeTimePlayersByScheduledId = 'TeeTimes/players/scheduledId/{scheduleId}',
  GetTeeTimePlayersByDateAndCourse = 'TeeTimes/Players/Course/{courseId}/{scheduledDate}/{scheduleStatus}',
  BulkMoveTeeTime = 'BulkTeeTimeSchedules/players/move',
  BulkCopyTeeTime = 'BulkTeeTimeSchedules/players/copy',
  ValidateBulkMoveTeeTime = 'BulkTeeTimeSchedules/players/move/Validate',
  ValidateBulkTeeTimeAction = 'BulkTeeTimeSchedules/players/validate/{teeAction}',
  BulkSendMailNotification = 'BulkTeeTimeSchedules/sendMailNotification/{eMailId}/{phoneNumber}/{teeAction}/{bookingId}',
  SearchBulkPlayersByConfirmationNumberOrPlayerName = 'BulkTeeTimeSchedules/courseId/{courseId}/scheduledDateTime/{scheduledDateTime}/pattern/{pattern}',
  //Lost And Found
  UpdateClaimForLostAndFound = 'LostAndFound/UpdateClaimForLostAndFound',
  CreateLostAndFound = 'LostAndFound',
  DeleteLostAndFound = 'LostAndFound/{id}',
  GetAllLostAndFound = 'LostAndFound',
  GetLostAndFound = 'LostAndFound/{id}',
  UpdateLostAndFound = 'LostAndFound/updateLostAndFound',
  GetLostAndFoundByDateFilter = 'LostAndfound/search/{fromDate}/{toDate}',
  //GroupTeeTime
  BookGroupTeeTime = 'GroupTeeTimeSchedules',
  GroupTeeTime = 'GroupTeeTimeSchedules/{bookData}',
  GroupPlayers = 'GroupTeeTimeSchedules/GroupPlayers?bookingId={bookingId}&bookingName={bookingName}',
  //Image
  saveImage = 'v2/Images',
  updateImage = 'v2/Images',
  DeleteImageByReference = 'v2/images/{guid}',
  GetImagesByReferenceId = 'v2/images?guid={imageReferenceId}&isThumbnailOnly={isThumbnailOnly}',
  GetAllImagesByReferenceId = 'v2/images/list?isThumbnailOnly={isThumbnailOnly}',
  //Deposit
  GetDepositInfo = 'TeeSheets/Deposits?scheduleTeeTimeId={scheduleTeeTimeId}',
  //GetTeeSlotInfo
  GetSlotPlayerDetails = "TeeSheets/GetSlotPlayerDetails?date={date}&courseId={courseId}&teeSlotNavigation={teeSlotNavigation}&activityId={activityId}&originalHoleNumber={originalHoleNumber}",
  //Receipt Configuration
  GetReceiptInfo = 'ReceiptConfiguration',
  CreateReceipt = 'ReceiptConfiguration',
  ApplyCancellationCharges  = 'CancellationNoShowFee/ApplyCancellationFees',
  ApplyNoShowCharges  = 'CancellationNoShowFee/ApplyNoShowFees/{playerId}/{outletId}',
  //PlayerStayDetail
  GetPlayerStayDetail = 'Players/StayDetail?guestId={guestId}&scheduleDateTime={scheduleDateTime}&confirmationNumber={confirmationNumber}&activityId={activityId}',
  // NegotiatedFee
  GetNegotiatedFeeByOutletId = "NegotiatedFee/{outletId}",
  AcesRefund = 'ACESPayment/ACESRefund/{transactionId}/{pin}',
  GetConfirmationNo = 'TeeTimes/ConfirmationNumber',
  GetConfirmationNumberByPlayerIds = 'TeeTimes/ConfirmationNumber',
  //SendNotification
  SendManualNotification = 'EmailConfiguration/{CanSendEmail}/{CanSendSMS}/{bSaveOrUpdateContact}/{teeTimeAction}/{canSendAuthorize}',

  //SendGuestExportNotification
  SendGuestExportNotification = 'EmailConfiguration/Notification/GuestExport',

  //SendBulkNotification
  getBulkNotifyPlayerDetails = 'TeeTimes/BulkNotify/{scheduledDate}/{eventName}',
  //sendMassNotification
  sendMassNotification = 'EmailConfiguration/SendMassNotification/{eventName}/{templateId}',
  //Property Configuration 
  ApplicationFormatConfiguration = "PropertyConfiguration/configurationName/{configurationName}/productId/{productId}",
  UpdatePropertyConfiguration = 'PropertyConfiguration',

  // Tenant Configuration
  TenantConfiguration = "TenantConfiguration/configurationName/{configurationName}",

  //GetPlayerContactInfo
  GetContactInformationByPlayerIds = "TeeTimes/GetContactInfo",
  GetPlayerUsingPatronID = "PlayerProfiles/getPlayerByPatronId/{patronId}",

  //Documentation auth
  CreateHelpSession = "Login/?product={product}",
  RemoveHelpSession = "Logout/?product={product}",

  UpdateInventoryAuditOnDayEnd = 'Inv/property/{propertyId}/dayend/audit',
  SyncItemAndTax = 'Inventory/Syncup/outlets/{outletId}/type/{type}/operation/{operation}/id/{id}',

  //Get CEDS
  GetCEDSByOutletId = "Outlets/GetCEDSByOutletId/{outletId}",

  //NotifyDayEnd
  NotifyDayEnd = "RevenuePosting/NotifyDayEnd",

  //BlockSlots
  BlockTeeTimeSlots = "TeeTimes/schedules/blockSlot",
  UpdateTeeTimeSlots = "TeeTimes/schedules/update",


  // AllocationBlock Permission
  GetAllocationBlockPermissionByRole = "AllocationBlocks/permissions/role/{roleId}",

  GetCaddyRequirements = "CaddyRequirements/active/{includeInactive}",
  CaddyRequirements = "CaddyRequirements",
  DeleteCaddyRequirements = "CaddyRequirements/{id}",

  // Combine Guests
  GetGuestInformation = "GuestCombine/guestsearch",
  GetDedupeGuestInformation = "GuestCombine/DuplicateGuestGroups",
  CombineGuestInformation = "GuestCombine/{primaryGuestId}",
  GetGuestInfoByGuid = "GuestCombine/{id}",
  UpdateGuestInformation = "GuestCombine/updateguest",
  SendRetailNotification = "EmailConfiguration/RetailNotification",
  BatchMemberPayment = "Member/TeeTimeMemberPayment",
  TaxConfiguration = "TaxConfiguration/grid",
  GetPropertyConfiguration = "PropertyConfiguration/configurationName/{configName}/propertyId/{propertyId}/productId/{productId}",

  GetSupportedPMAgentVersionByProperty = 'Payment/GetPMAgentVersion/{propertyId}',
  GetSalesHistoryTransactionByGuestGuids = 'Transaction/GetSalesHistoryTransactionByGuestGuids',

  //CGPS Failed Profie
  GetAllFailedGuestProfile = 'GuestCombine/GetAllFailedGuestProfile',
  ProfileSyncManaulTrigger = 'GuestCombine/ProfileSyncManualTrigger',

  PayPlayersTempHold = 'TeeTimes/PayPlayersTempHold',
  CheckPlayersTempHold = 'TeeTimes/CheckPlayersTempHold',
  ReleasePlayerTempHold = 'TeeTimes/ReleasePlayerTempHold',
  GetPlayerMultiPack = 'PointOfSaleService/MultiPack/getMultipleItemMultipacks',
  LinkMultiPack = 'PointOfSaleService/MultiPack/redeemMultipleItemMultiPack',
  UnLinkMultiPack = 'PointOfSaleService/MultiPack/reinstateMultipleItemMultiPackSession',
  UpdatePlayerMultipack = 'TeeTimes/UpdateMultipackLinkId',
  GetMultipacksByTransactionId = 'PointOfSaleService/MultiPack/GetMultiPacksByTransactionId/TransactionId/{transactionId}',
  UpdateMultipack = "PointOfSaleService/MultiPack/UpdateMultiPack",
  GetMultiPackRedeemHistoryDetails = "MultiPack/GetMultiPackRedeemHistoryDetails",
  GetADB2CEnableConfig = "Adb2CAuthentication/GetADB2CEnableConfig/{tenantId}",
  GetScheduledTeeTimePlayerByPlayerIds = 'TeeTimes/GetScheduledTeeTimePlayerByPlayerIds',

  // dataMagine
  GetDataMagineConfiguration = "DataMagine/GetDmConfig",
  CreateDataMagineConfiguration = "DataMagine/CreateDmConfig",
  UpdateDataMagineConfiguration = "DataMagine/UpdateDmConfig",
//dynamic pricing
  GetDynamicPricingForCourseDateAndTeeFee = 'DynamicPricing?courseId={courseId}&scheduleDate={scheduleDate}',
  GetDynamicPricingForCourseAndScheduledDate = 'DynamicPricing?courseId={courseId}&scheduleDate={scheduleDate}',
  GetUserByTenantId = 'User/GetUserByTenantId/{UserName}/{tenantId}',
  GetPropertyDetails = 'Property/GetPropertyDetails',
  GetTenantGroupDetails = 'TenantOrganization/GetTenantGroupDetails',
  GetTenantGroupDetailByProductId = 'TenantOrganization/GetTenantGroupDetailByProductId?productId={productId}',
  GetPropertyDetailsByProductId = 'Property/GetPropertyDetailsByProductId?productId={productId}',
  ValidateUserByProductTenantAndEmail = 'User/ValidateUserByProductTenantAndEmail?productId={productId}&tenantId={tenantId}&emailId={emailId}',
  GetExternalGuestProfile = "PlayerProfiles/externalguest?guestId={guestId}&createGuestProfile={createGuestProfile}",
  UpdatePlayerProfileLink = "PlayerProfiles/PlayerProfileLink",
  GetPlayerProfilebyPlatformGuid = "PlayerProfiles/platformGuid/{platformGuid}",
  GetPlayerbyPlatformGuid = "Players/platformGuid/{platformGuid}",

  GetPlayerByGuestId = "players/guest/{guestId}",
  
  //Update PlayerType And RateType Fees
  UpdatePlayerFees = 'TeeTimes/UpdatePlayerFee',

  // PlayerRetailItems

  CreateRetailItemsForPlayer = 'PlayerRetailItems',
  UpdateRetailItemsForPlayers = 'PlayerRetailItems',
  GetScheduledTeeTimePlayerRetailItemsForPlayers = 'PlayerRetailItems/ScheduledPlayerRetailItems/scheduledTeeTimePlayers',
  GetScheduledTeeTimePlayerRetailItemsForPlayersByActivityId = 'PlayerRetailItems/ScheduledPlayerRetailItems/scheduledTeeTimePlayersByActivityIds',
  GetPlayerRetailItemsForPlayers = 'PlayerRetailItems/scheduledTeeTimePlayers',
  GetScheduledTeeTimePlayerRetailItemsForByLinkIds = 'PlayerRetailItems/ScheduledTeeTimePlayerRetailItemsForByLinkIds',
  ValidateOutletReference ='Management/OutletValidation/{id}',

  //CancellationNoShowFee

  CreateCancellationNoShowPolicy = 'CancellationNoShowPolicy',
  UpdateCancellationNoShowPolicy = 'CancellationNoShowPolicy',
  GetAllCancellationNoShowPolicy = 'CancellationNoShowPolicy/includeInactive/{includeInactive}',
  GetCancellationNoShowPolicyById = 'CancellationNoShowPolicy/{id}',
  DeleteCancellationNoShowPolicy = 'CancellationNoShowPolicy/{id}',
  SearchCancellationNoShowPolicy = 'CancellationNoShowPolicy/search/{searchText}',
  UpdateActiveCancellationNoShowPolicy = 'CancellationNoShowPolicy/UpdatePolicyActive/{policyId}',
  GetAllCancellationNoShowPolicyByDate = 'CancellationNoShowPolicy/date/{date}',


  CreateCancellationNoShowPolicyMapping = 'CancellationNoShowPolicyMapping',
  UpdateCancellationNoShowPolicyMapping = 'CancellationNoShowPolicyMapping',
  GetAllCancellationNoShowPolicyMapping = 'CancellationNoShowPolicyMapping/includeInactive/{includeInactive}',
  GetCancellationNoShowPolicyMappingById = 'CancellationNoShowPolicyMapping/{groupId}',
  DeleteCancellationNoShowPolicyMapping = 'CancellationNoShowPolicyMapping/{groupId}',
  GetCancellationNoShowLedger = 'CancellationNoShowLedger/date/{date}',
  UpdateCancellationNoShowLedger ='CancellationNoShowLedger',
  UpdateCancellationNoShowLedgerStatus = 'CancellationNoShowLedger/UpdateCancellationNoShowLedgerStatus/playerId/{playerId}/status/{status}/ticketNumber/{ticketNumber}',
  //CustomFee
  GetCustomFees = 'GolfScheduledTeeTimeCustomFee/GetCustomFees',
  CreateCancellationNoShowLedger = 'CancellationNoShowLedger',
}
export enum PropertyApiRoutes {
  changePropertyNewTab = '{hostName}/setProperty?token={token}&propertyId={propertyId}&propertyName={propertyName}&routeParam={routeParam}',
  changePropertySameTab = '/setProperty'

}

export enum WeatherApiRoutes {
  OpenWeatherApi30 = 'https://api.openweathermap.org/data/3.0/onecall',
  OpenWeatherApi25 = 'https://api.openweathermap.org/data/2.5/onecall',
  OpenWeatherGeo = 'https://api.openweathermap.org/geo/1.0/direct',
  OpenWeatherKey = '11799837ce10ac16e440599559bc0856'
}
