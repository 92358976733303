import { Injectable } from "@angular/core";
import { ReinstateTeeTimeService } from './reinstate-teetime-service';
import { GolfLocalization } from '../../core/localization/golf-localization';
import { TeeSheetReinstate } from 'src/app/tee-time/shared/tee-sheet/tee-sheet.reinstate';
import { PlayerRateType, TeeSheetSkeletonData } from 'src/app/shared/models/teesheet.form.models';
import { CourseOption } from 'src/app/settings/rate-setup/rate-setup.model';
import { CourseDataService } from 'src/app/shared/data-services/golfmanagement/course.data.service';
import * as _ from 'lodash';
import { PlayerTeeTimeSlot, ReinstateResult, ReinstatePlayerAndSlotDetail } from '../reinstate-modal/reinstate-player-model';
import { TeeTimesActionBusiness } from 'src/app/tee-time/shared/tee-action.business';
import { TeeTimesActionService } from 'src/app/shared/data-services/golfschedule/teeTimesAction.data.service';
import { RuleResult } from 'src/app/shared/models/teesheet.api.models';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { PlayerTypeService } from "src/app/shared/data-services/golfmanagement/playertype.data.service";
import { RateType } from "../teetime/player-details/player-info.model";

@Injectable()

export class ReinstateTeeTimeBusiness {

    public readonly reinstatePlayerCaption: any;
    allCourses: any;
    constructor(private _reinstateTeeTimeService: ReinstateTeeTimeService,
        private _teeSheetReinstate: TeeSheetReinstate,
        private _teeTimesActionService: TeeTimesActionService,
        private _courseDataService: CourseDataService,
        private _teeTimesActionBusiness: TeeTimesActionBusiness,
        private _localization: GolfLocalization,
        private _utils: GolfUtilities,
        private _playerTypeService: PlayerTypeService,
        ) {
        this.reinstatePlayerCaption = this._localization.captions.teetime;
    }

    public getTeeSheeetID() {
        return this._reinstateTeeTimeService.getTeeSheeetID();
    }

    public getTeeSheetCustomTableData() {
        return this._reinstateTeeTimeService.getTeeSheetCustomTableData();
    }

    public getPlayerList(playerList, ratetypes) {
        return this._reinstateTeeTimeService.getPlayerList(this.mapToPlayerDetail(playerList, ratetypes));
    }

    public getInitialTeeTimes(): TeeSheetSkeletonData[] {
        return this._teeSheetReinstate.getInitialTeeSlots();
    }

    getTeeSheet(course: number, date: Date, allRateTypes: any) {
        return this._teeSheetReinstate.getTeeSheet(course, date,undefined, this.allCourses,undefined,allRateTypes);
    }

    public updateTeeSlots(slots: TeeSheetSkeletonData[]): void {
        this._teeSheetReinstate.addTeeSlots(_.cloneDeep(slots));
    }

    public generateModifiedPlayersTeeSlots(initialTeeSlots: TeeSheetSkeletonData[], modifiedTeeSlots: TeeSheetSkeletonData[], ): PlayerTeeTimeSlot[] {
        return this._teeTimesActionBusiness.generateModifiedPlayersTeeSlots(initialTeeSlots, modifiedTeeSlots);
    }

    public async getCourses(): Promise<CourseOption[]> {
        this.allCourses = await this._courseDataService.getCourses();

        return this.allCourses.map(c => {
            return {
                id: c.id,
                name: c.name,
                description: c.name
            }
        });        
    }

    public async reinstateWithNewCombination(validTeeTimes: PlayerTeeTimeSlot[]): Promise<boolean> {
        let resp$: Promise<ReinstateResult[]>;
        const reinstateDetails: ReinstatePlayerAndSlotDetail = {
            playersTeeTimeSlot: validTeeTimes,
            isNewCombination: true
        }
        resp$ = this._teeTimesActionService.reinstateCancelledTeeTimePlayers(reinstateDetails).finally(() => { this._teeTimesActionBusiness.setEmptyDraggedList(); });
        const resp: ReinstateResult[] = await resp$;
        const failedRules = resp.filter(r => !r.result);
        if (failedRules && failedRules.length > 0) {
            this.showRuleErrors(failedRules[0].ruleResults);
            return false;
        } else
            return true;
    }

    public showRuleErrors(ruleResults: RuleResult[]): void {
        let error: string;
        if (ruleResults) {
            let ruleResult = ruleResults.filter(x => !x.isValid);
            if (ruleResult.length > 0) {
                let failedRuleResult = ruleResult[0];
                error = this._localization.getError(failedRuleResult.errorCodes[0]);
                if (failedRuleResult.errorCodes[0] == 30401) // players day out rule
                {
                    error = this._localization.getError(failedRuleResult.data.DaysOutData[0].errorCode);
                    error = error.interpolate({ date: this._localization.localizeDisplayDate(failedRuleResult.data.DaysOutData[0].date) });
                }
                this._utils.showError(error);
            }
        }
        else {
            const error: string = this._localization.getError(-2);
            this._utils.showError(error);
        }
    }

    private mapToPlayerDetail(playerList, rateTypes) {
        return playerList.map(p => {
            return {
                deposits: [],
                bookingId: "00000000-0000-0000-0000-000000000000",
                amountPaid: 0,
                firstName: p.playerFirstName,
                lastName: p.playerLastName,
                gender: "",
                memberNumber: p.uniqueCancellationNumber,
                playPos: 0,
                playerComment: "",
                playerId: p.id,
                playerStatus: "16",
                playersPerGroup: 0,
                teeTimeFormat: 0,
                tournamentId: 0,
                transactionId: 0,
                isDragDisabled: false,
                playerTypeId : p.playerTypeId,
                rateType : rateTypes ? this.assignRateType(p.rateTypeId ? p.rateTypeId : 0, rateTypes) : p.rateType,
                linkingId: p.linkingId != null ? p.linkingId : '',
                playerCategoryId: p.playerCategoryId != null ? p.playerCategoryId : 1,
                time: p.bookedDateTime != null ? p.bookedDateTime : 1,
                courseId: p.courseId != null ? p.courseId : 0,
            }
        });
       
    }

    private assignRateType(
        id: number,
        allActiveRateType: RateType[]
      ): PlayerRateType {
        let rateType: PlayerRateType = {
          id: 0,
          name: "",
          daysOutStart: 0,
          daysOutEnd: 0,
        };
        const _type = allActiveRateType.find((r) => r.id === id);
        if (_type) {
          rateType = {
            id: id,
            name: _type.type,
            daysOutStart: _type.daysOutStart,
            daysOutEnd: _type.daysOutEnd,
          };
        }
        return rateType;
      }
    public async getAllPlayerTypes(){
       return await this._playerTypeService.getAllPlayerTypes();
    }

}