import { UntypedFormGroup } from '@angular/forms';
import { DialogOverviewExampleDialog } from '../dialog-popup/dialogPopup-componenet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {cloneDeep,forEach,includes,map,} from 'lodash';
import { HttpServiceCall, HttpMethod } from '../service/http-call.service';
import * as GlobalConst from '../globalsContant';
import { DaysLocaleSorted_app_multiple, Calendar, SystemConfig } from '../business/shared.modals';
import { NavigationEnd, Router } from '@angular/router';
import { MoreSectionServiceService } from '../more-section/more-section-service.service';
import { CommonPropertyInformation } from '../../services/common-property-information.service';
import moment from 'moment';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { CommonAlertMessagePopupComponent } from '../alert-message-popup/alert-message-popup.component';
import { FormatText } from '../pipes/formatText-pipe.pipe';
import { AlertType, AlertAction, ButtonTypes, PrintDocumentInput } from 'src/app/common/Models/common.models';
import { CommonAlertPopupComponent } from '../common-alert-popup/common-alert-popup.component';
import { Localization } from 'src/app/common/localization/localization';
import { AuthenticationParameters } from 'msal';
import { filter } from 'rxjs/operators';
import { ButtonType as newButtonType } from 'src/app/common/enums/shared-enums';
import { DeleteDependencyAlertComponent } from 'src/app/common/components/delete-dependency-alert/delete-dependency-alert.component';
import { CardSwipePopupComponent } from '../card-swipe-popup/card-swipe-popup.component';
import { PhoneTypes, SnackBarType } from '../enums/enums';
import { MatSnackBar,MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Product as products} from '../../../shared/shared/globalsContant';
import {  API, PrintInformationType } from 'src/app/common/Models/printer-default-configuration.model';
import countrylist from '../../../../common/components/ag-phone-number/country-code.json';
import { findIana } from 'windows-iana';
import { ServiceParams } from 'src/app/common/Models/http.model';
import { AgSnackBarComponent } from 'src/app/common/components/ag-snack-bar/ag-snack-bar.component';
import { CompareKey } from 'src/app/common/constants';
import { HttpErrorResponse } from '@angular/common/http';
import { StatusMessages } from 'src/app/common/constants';
import { TableGridOptions, TableHeaderOptions } from 'src/app/common/Models/ag-models';

export enum RedirectToModules {
    retail = 1,
    order,
    appointment,
    settings,
    Utilities,
    exchange,
    Dayend,
    home,
    RevenuePostingsLog
}

export enum ContactType {
    phone = 1,
    email = 2,
    office = 3
}

export interface PatchJson {
    op: PatchOperation;
    path: string;
    value: any;
}

export interface AppointmentColors {
    BackGround: string;
    BackGroundLight: string;
    Color: string;
    ColorLight: string;
    Border: string;
}

export enum PatchOperation {
    add = 'add',
    remove = 'remove',
    replace = 'replace',
    copy = 'copy',
    move = 'move',
    test = 'test'
}

export enum RecurringType {
    Daily = 0,
    Weekly = 1,
    Monthly = 2,
    Yearly = 3
}

export enum AppointmentColorConfiguration {
    'CKIN' = 'APPOINMENT_CHECKEDIN_COLOR',
    'RESV' = 'APPOINMENT_SCHEDULED_COLOR',
    'NOSHOW' = 'APPOINMENT_NOSHOW_COLOR',
    'CKOUT' = 'APPOINMENT_CHECKEDOUT_COLOR',
    'CANC' = 'APPOINMENT_CANCEL_COLOR',
    'WAIT' = 'APPOINMENT_WAIT_COLOR',
    'OPEN' = 'APPOINMENT_OPEN_COLOR',
    'CLOSED' = 'APPOINMENT_CLOSED_COLOR',
    'TEMP' = 'APPOINMENT_TEMP_COLOR',
    'BREAK' = 'APPOINMENT_BREAK_COLOR',
    'SETUP' = 'APPOINMENT_SETUP_COLOR',
    'BREAKDOWN' = 'APPOINMENT_BREAKDOWN_COLOR',
    'ONCALL' = 'APPOINMENT_ONCALL_COLOR'
}

export enum InputTypeNumbers {
    NUMBERS = 'onlynumber',
    ONLYPOSITIVE = 'nonnegative',
    ONLYNEGATIVE = 'onlynegative',
    NODECIMAL = 'nodecimal',
    DECIMAL = 'decimal',
    ROUNDOFF = 'roundoff2',
    PERCENT = 'validPercentage',
    POSITIVEDECIMAL = 'onlyPositiveDecimal',
    POSITIVEDECIMALORNUMERIC = 'PositiveDecimalOrNumeric',
    NUMBERWITHSEPARATOR = 'numberWithSeparator',
    PREMAXDECIMAL = 'preMaxdecimal'
}

export enum InputTypeText {
    CAP = 'capitalise',
    TEXT = 'textonly',
    NOSPL = 'nospecailchar',
    NOSPACE = 'notallowspace',
    EMAIL = 'email',
    FIRST_CHAR_CAP = 'firstcharcapitalise',
    NOPRESPACE = 'noprespace',
    WEBSITE = 'website',
    RESERVEDCHAR = 'reservedchar',
    HYPHENBRACES = "hyphenbraces",
    CODEINPUTTYPE = "noprespace,notallowspace",
    NAMEINPUTTYPE = "noprespace",
    HYPHEN="hyphen",
    RESTRICTSCRIPT = "restrictscript"
}

export enum Product {
    SPA = 1,
    RETAIL = 2,
    GOLF = 3,
    COMMON = 4,
    SNC = 5,
    PMS = 6,
    Tenant = 7,
    EnhancedInventory = 8,
    HouseKeeping = 9,
    Accounting = 10
}
export enum SignalRMessages {
    ChangeSystemDate = "Change System Date",
    NoteSent = "Note Sent",
    PlatformSyncFailed = "PlatformSyncFailed"
}

export interface CssProp {
    property: string;
    value: string;
}


export function stringFormat(input: string, appendBy?: string) {
    if (appendBy) {
        const tempTrue = (input == '' ? '' : input + appendBy);
        return input ? tempTrue : '';
    } else {
        const tempFalse = (input == '' ? '' : input);
        return input ? tempFalse : '';
    }
}



export function convertPhoneNoToUiNo(num: string): string {
    if (num != null || num == '') {
        return (
            num.substring(3, 0) +
            ' - ' +
            num.substring(6, 3) +
            ' - ' +
            num.substring(num.length, 6)
        );
    } else {
        return '';
    }
}

export function tConvert(tt) {
    let time = tt.substring(tt.indexOf('T') + 1, tt.length);
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
        time
    ];

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
}

export function addMinutesToGivenTime(time, minTobeAdded) {
    const dummyDate: Date = this.getDate('2019-01-01T' + time);
    const dateTimeValue = dummyDate.setMinutes(
        dummyDate.getMinutes() + minTobeAdded
    );
    const dateTimeWithAddedMinutes = this.getDate(dateTimeValue);

    const hours = dateTimeWithAddedMinutes.getHours();
    const min = dateTimeWithAddedMinutes.getMinutes();

    return (hours < 9 ? '0' + hours.toString() : hours.toString()) + ':' + (min < 9 ? '0' + min.toString() : min.toString());
}
export abstract class CommonUtilities {
    private _previousURL: string;
    private _currentURL: string;
    public isFromJobScheduler: boolean = false;
    constructor(public localization: Localization, public dialog: MatDialog, public httpServiceCall: HttpServiceCall, public route: Router,
        public _MoreSectionServiceService: MoreSectionServiceService, public PropertyInfo: CommonPropertyInformation, public formatphno: FormatText,
        protected snackBar?: MatSnackBar) {

        this.route.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
                this._previousURL = this._currentURL;
                this._currentURL = urlAfterRedirects;
            });
    }
    subscription: ISubscription;

    DateDiff = {
        inHours(d1, d2) {
            let t2 = d2.getTime();
            let t1 = d1.getTime();

            return Math.round((t2 - t1) / (3600 * 1000));
        },
        inDays(d1, d2) {
            let t2 = d2.getTime();
            let t1 = d1.getTime();

            return Math.round((t2 - t1) / (24 * 3600 * 1000));
        },

        inWeeks(d1, d2) {
            let t2 = d2.getTime();
            let t1 = d1.getTime();

            return Math.round((t2 - t1) / (24 * 3600 * 1000 * 7));
        },

        inMonths(d1, d2) {
            let d1Y = d1.getFullYear();
            let d2Y = d2.getFullYear();
            let d1M = d1.getMonth();
            let d2M = d2.getMonth();

            return Math.round((d2M + 12 * d2Y) - (d1M + 12 * d1Y));
        },

        inYears(d1, d2) {
            return Math.round(d2.getFullYear() - d1.getFullYear());
        }
    };

    public GetLocalStorageValue(key: string, name: string) {
        const nameEQ = name + '=';
        const propertyInfo = sessionStorage.getItem(key);
        if (propertyInfo != null) {
            const ca = propertyInfo.split(';');

            for (let info of ca) {
                let c = info.trim();
                while (c.charAt(0) == ' ') { c = c.substring(1, c.length); }
                if (c.indexOf(nameEQ) == 0) { return c.substring(nameEQ.length, c.length); }
            }
        }
        return null;
    }

    public minCharSearch():number{
        const minCharSearch = this.GetPropertyInfo('MincharSearch');
        const data: number = (parseInt(minCharSearch) ? parseInt(minCharSearch) : 3);
        return data;
       }

    getDirtyPatchArray(
        exist: any[],
        edited: any[],
        compareKey: string,
        isObject: boolean = false
    ): any {
        const result: PatchJson[] = [];
        let existArr: any[] = [];
        let editedArr: any[] = [];

        if (isObject) {
            if (exist.length > 0) {
                for (let ex of exist) {
                    existArr.push(ex[compareKey]);
                }
            }
            if (edited.length > 0) {
                for (let edit of edited) {
                    editedArr.push(edit[compareKey]);
                }
            }
        } else {
            existArr = exist;
            editedArr = edited;
        }

        const removed = existArr.filter(value => editedArr.indexOf(value) == -1);
        const newlyAdded = editedArr.filter(value => existArr.indexOf(value) == -1);

        for (let rm of removed) {
            result.push(
                this.preparePatchJson(PatchOperation.remove, compareKey, rm)
            );
        }

        for (let add of newlyAdded) {
            result.push(
                this.preparePatchJson(PatchOperation.add, compareKey, add)
            );
        }

        return result;
    }

    preparePatchJson(
        operation: PatchOperation,
        path: string,
        value: any
    ): PatchJson {
        return {
            op: operation,
            path: '/' + path,
            value
        };
    }
    /**
    * Converts a javascript date to Invariant date time format string (C# API can understand this format).
    * @param Date javascript date or Javascript ISO string.*
    */
    convertDateFormat(dt: Date): string {
        return this.localization.ConvertDateToISODateTime(dt);
    }

    getTime(dt: Date, format: number) {
        return this.localization.getTime(dt, format);
    }

    getLocalizeTime(dt: Date) {
        return this.localization.LocalizeTime(dt);
    }
    /**
    * Converts a javascript date to Invariant date format string (C# API can understand this format).
    * @param Date javascript date or Javascript ISO string.    *
    */
    formatDate(dt: Date): string {
        return this.localization.ConvertDateToISODate(dt);
    }

    GetFormattedDate(dt: Date) {
        return this.localization.GetFormattedDate(dt);
    }

    GetFormattedDateDDMMYY(dt: Date) {
        return this.localization.GetFormattedDateDDMMYY(dt);
    }

    GetFullDate(dt: Date) {
        return this.localization.GetFullDate(dt);
    }

    formatAMPM(date: Date, isCapital = false, isHours2Digit = true) {
        return this.localization.formatAMPM(date, isCapital, isHours2Digit);
    }

    AddMinutes(date: Date, minutes: number) {
        return this.localization.AddMinutes(date, minutes);
    }

    AddMins(date: Date, minutes: number) {
        return this.localization.AddMins(date, minutes);
    }

    AddDays(date: Date, days: number) {
        return this.localization.AddDays(date, days);
    }

    format24HrTime(date: any): string {
        return this.localization.format24HrTime(date);
    }

    generateModelWithId<T>(array: Array<T>, identityProperty, descriptionProperty): Array<T> {
        const returnArr: T[] = [];
        let tempObj: any = {};
        for (let i = 0; i < array.length; i++) {
            const element = array[i];
            tempObj[identityProperty] = i + 1;
            tempObj[descriptionProperty] = element;
            returnArr.push(tempObj);
            tempObj = {};
        }
        return returnArr;
    }

    public hideOverlays() {
        const LoaderElement = document.getElementById('cover-spin');
        const customElement = document.getElementById('custom-cover-spin');
        if (LoaderElement) {
            LoaderElement.style.display = 'none';
        }
        if (customElement) {
            customElement.style.display = 'none';
        }
    }

    ToggleLoader(loader) {
        const loadingContainer = document.getElementById('cover-spin');
        if (loadingContainer) {
            if (loader) {
                loadingContainer.style.display = 'block';
                document.getElementsByTagName('body')[0].classList.remove("backdrop-loading-off");
                document.getElementsByTagName('body')[0].classList.add("backdrop-loading-on");
            } else {
                loadingContainer.style.display = 'none';
                document.getElementsByTagName('body')[0].classList.remove("backdrop-loading-on");
                document.getElementsByTagName('body')[0].classList.add("backdrop-loading-off");
            }
        }
    }

    addoverLay(overlay)
    {

        if(overlay)
        {
            document.getElementsByTagName('body')[0].classList.add('show-custom-overlay');
        }else{
            document.getElementsByTagName('body')[0].classList.remove('show-custom-overlay');
        }

    }

    ToggleLoaderWithMessage(loader, loaderMessage = '', maxLoaderTime?: number) {
        const loadingContainer = document.getElementById('custom-cover-spin');
        const loadingContainerMessage = document.getElementById('custom-cover-message');
        if (loadingContainer) {
            if (loader) {
                loadingContainer.style.display = 'block';
                document.getElementsByTagName('body')[0].classList.remove("backdrop-loading-off");
                document.getElementsByTagName('body')[0].classList.add("backdrop-loading-on");
            } else {
                loadingContainer.style.display = 'none';
                document.getElementsByTagName('body')[0].classList.remove("backdrop-loading-on");
                document.getElementsByTagName('body')[0].classList.add("backdrop-loading-off");
            }
            if (loaderMessage) {
                loadingContainerMessage.innerText = loaderMessage;
            } else {
                loadingContainerMessage.innerText = '';
            }
            if (maxLoaderTime) {
                setTimeout(() => {
                    loadingContainer.style.display = loader ? 'none' : '';
                    loadingContainerMessage.innerText = '';
                }, maxLoaderTime * 1000);
            }
        }
    }



    public ShowError(title: string, message: any, popButtonType: GlobalConst.ButtonType = GlobalConst.ButtonType.Ok, callback?: (result: string, extraParams?: any[]) => void, extraParams?: any[] | any, closebool = true) {
        this.hideOverlays();
        const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
            height: 'auto',
            width: '40%',
            data: { headername: title, closebool, templatename: 'In the Given Data', datarecord: message, buttonType: popButtonType },
            panelClass: 'small-popup',
            disableClose: true,
            hasBackdrop: true,
            backdropClass: 'Overlay-Alerts'
        });
        this.subscription = dialogRef.afterClosed().subscribe(res => {
            if (callback) {
                callback(res, extraParams);
            }
        });
        return dialogRef;
    }

    ShowErrorPopup(errArr: number[]) {
        if (errArr && errArr.length > 0) {
            let errorTxt = '';
            for (let error of errArr) {
                errorTxt += '<span>' + this.localization.getError(error) + '</span></br></br>';
            }
            this.ShowError(this.localization.captions.common.Error, errorTxt, GlobalConst.ButtonType.Ok);
        }
    }

    CloseAllOpenedPopups() {
        this.dialog.closeAll();
    }

    // Generate Time Array starts
    dayTimeArray(strt: Date, end: Date, incby, typ, isTimeRounded = false) {
        return this.localization.dayTimeArray(strt, end, incby, typ, isTimeRounded);
    }

    getShortDaysOfWeek(): string[] {
        return this.localization.getShortDaysOfWeek();
    }

    getLongDaysOfWeek(): string[] {
        return this.localization.getLongDaysOfWeek();
    }

    /**
     * Locale sorted short days of week for app-multipleDays component
     */
    getShortDaysOfWeekModel(setSelected: boolean): DaysLocaleSorted_app_multiple[] {
        const arrOfShortDays: string[] = this.getShortDaysOfWeek();
        const returnArr: DaysLocaleSorted_app_multiple[] = [];
        returnArr.push({ id: 0, name: this.localization.captions.common.all, setSelected });
        for (let i = 0; i < arrOfShortDays.length; i++) {
            const day = arrOfShortDays[i];
            returnArr.push({ id: i + 1, name: day, setSelected });
        }
        return returnArr;
    }

    capitalizeFirstLetter(inputString: string) {
        inputString = inputString && inputString.length > 0 ? inputString.trim() : '';
        return inputString && inputString.length > 0 ?
            inputString.charAt(0).toUpperCase() + inputString.slice(1) :
            inputString;
    }

    getShortWeekArrayLocaleSorted() {
        const ShortWeekArr = this.getShortDaysOfWeek(); // locale sorted by default
        const returnArr = [];
        const localizedCalender: Calendar = this.localization.captions.calendar;

        returnArr.push({ id: 7, name: this.localization.captions.common.AllDays });
        for (let week of ShortWeekArr) {
            const localeDay = week;
            switch (localeDay) {
                case localizedCalender.Mon:
                    returnArr.push({ id: 1, name: localeDay });
                    break;
                case localizedCalender.Tue:
                    returnArr.push({ id: 2, name: localeDay });
                    break;
                case localizedCalender.Wed:
                    returnArr.push({ id: 3, name: localeDay });
                    break;
                case localizedCalender.Thu:
                    returnArr.push({ id: 4, name: localeDay });
                    break;
                case localizedCalender.Fri:
                    returnArr.push({ id: 5, name: localeDay });
                    break;
                case localizedCalender.Sat:
                    returnArr.push({ id: 6, name: localeDay });
                    break;
                case localizedCalender.Sun:
                    returnArr.push({ id: 0, name: localeDay });
                    break;
                default:
                    break;
            }
        }

        return returnArr;
    }

getLongWeekArrayLocale() {
        const longWeekArr = this.getLongDaysOfWeek(); // locale sorted by default
        const returnArr = [];
        const localizedCalender: Calendar = this.localization.captions.calendar;
        for (let lweek of longWeekArr) {
            const localeDay = lweek;
            switch (localeDay) {
                case localizedCalender.Monday:
                    returnArr.push({ id: 1, name: localeDay });
                    break;
                case localizedCalender.Tuesday:
                    returnArr.push({ id: 2, name: localeDay });
                    break;
                case localizedCalender.Wednesday:
                    returnArr.push({ id: 3, name: localeDay });
                    break;
                case localizedCalender.Thursday:
                    returnArr.push({ id: 4, name: localeDay });
                    break;
                case localizedCalender.Friday:
                    returnArr.push({ id: 5, name: localeDay });
                    break;
                case localizedCalender.Saturday:
                    returnArr.push({ id: 6, name: localeDay });
                    break;
                case localizedCalender.Sunday:
                    returnArr.push({ id: 0, name: localeDay });
                    break;
                default:
                    break;
            }
        }

        return returnArr;
    }


    // public weekArray:any = [{"id":0,"name":"All Days"},{"id":1,"name":"Monday"},{"id":2,"name":"TuesDay"},{"id":3,"name":"Wednesday"},{"id":4,"name":"Thursday"},{"id":5,"name":"Friday"},{"id":6,"name":"Saturday"},{"id":7,"name":"Sunday"}];
    getLongWeekArrayLocaleSorted() {
        const longWeekArr = this.getLongDaysOfWeek(); // locale sorted by default
        const returnArr = [];
        const localizedCalender: Calendar = this.localization.captions.calendar;

        returnArr.push({ id: 7, name: this.localization.captions.common.AllDays });
        for (let lweek of longWeekArr) {
            const localeDay = lweek;
            switch (localeDay) {
                case localizedCalender.Monday:
                    returnArr.push({ id: 1, name: localeDay });
                    break;
                case localizedCalender.Tuesday:
                    returnArr.push({ id: 2, name: localeDay });
                    break;
                case localizedCalender.Wednesday:
                    returnArr.push({ id: 3, name: localeDay });
                    break;
                case localizedCalender.Thursday:
                    returnArr.push({ id: 4, name: localeDay });
                    break;
                case localizedCalender.Friday:
                    returnArr.push({ id: 5, name: localeDay });
                    break;
                case localizedCalender.Saturday:
                    returnArr.push({ id: 6, name: localeDay });
                    break;
                case localizedCalender.Sunday:
                    returnArr.push({ id: 0, name: localeDay });
                    break;
                default:
                    break;
            }
        }

        return returnArr;
    }

    getTimeDifference(fromtime, toTime, type) {
        return this.localization.getTimeDifference(fromtime, toTime, type);
    }

    getAge(d1) {
        if (typeof d1 == 'string') {
            d1 = this.getDate(d1)
        }
        let d2 = this.PropertyInfo.CurrentDate;
        let diff = d2.getTime() - d1.getTime();
        return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
    }

    /**
     * removes the duplicate values in the given array
     * @param arrWithDuplicates
     */
    removeDuplicates(arrWithDuplicates): any[] {
        let uniqueArr = [];
        uniqueArr = arrWithDuplicates.filter(
            function (item, position) {
                return arrWithDuplicates.indexOf(item) == position;
            }
        );
        return uniqueArr;
    }

    getGuid(callDesc = 'GetGuid', successCallback, errorCallback, extraParams) {
        this.httpServiceCall.CallApiWithCallback<any>({
            host: GlobalConst.Host.schedule,
            success: successCallback,
            error: errorCallback,
            callDesc,
            method: HttpMethod.Get,
            showError: true,
            extraParams
        });
    }

    isExist(coll, obj) {
        let index = -1;
        if (coll) {
            index = coll.findIndex(x => ((x.id || x.id == 0) ? x.id : x) == obj.id);
        }
        return index;
    }

    setUserAccessSettings = (allAvailableSettings, selectedSettings) => {
        forEach(allAvailableSettings, setting => {
            const selectedSettingIds = map(selectedSettings, selectedOutlet => selectedOutlet.id);
            includes(selectedSettingIds, setting.id) ? setting.isSelected = true : setting.isSelected = false;
        });
    }

    getToggleFilter(DataArr, SelectedArr, gv, isWeekLocalized = false) {

        if (gv.id == 0 && !isWeekLocalized) {
            if (SelectedArr.indexOf(gv.id) == -1) {
                DataArr.forEach(result => {
                    if (SelectedArr.indexOf(result.id) == -1) {
                        SelectedArr.push(result.id)
                    }
                });
            }
            else {
                SelectedArr = [];
            }
            return SelectedArr;
        }

        if (SelectedArr.indexOf(gv.id) == -1) {
            SelectedArr.push(gv.id);
            if (SelectedArr.length == DataArr.length - 1 && this.isExist(DataArr, { id: 0 }) != -1) {
                SelectedArr = [];
                DataArr.forEach(result => { SelectedArr.push(result.id); });
            }
        } else {
            SelectedArr.splice(SelectedArr.indexOf(gv.id), 1);
            if (SelectedArr.length == DataArr.length - 1) {
                if (SelectedArr.findIndex(x => x == 0) != -1) {
                    SelectedArr.splice(SelectedArr.findIndex(x => x == 0), 1);
                }
            } else {
                if (SelectedArr.findIndex(x => x == 0) != -1) {
                    SelectedArr.splice(SelectedArr.findIndex(x => x == 0), 1);
                }
            }
        }

        if (isWeekLocalized && SelectedArr.length != DataArr.length) {
            SelectedArr = SelectedArr.filter(x => x != 7);
        }
        return SelectedArr;
    }


    public getOutletFilterObject(DataArr, SelectedArr, gv) {



        const selectedIds: number[] = SelectedArr.map(x => x.id);
        if (selectedIds.indexOf(gv.id) == -1) {
            SelectedArr.push(gv);
            if (SelectedArr.length == DataArr.length - 1 && this.isExist(DataArr, { id: 0 }) != -1) {
                SelectedArr = [];
                DataArr.forEach(result => { SelectedArr.push(result); });
            }
        } else {
            SelectedArr.splice(selectedIds.indexOf(gv.id), 1);
            if (SelectedArr.length == DataArr.length - 1) {
                if (SelectedArr.findIndex(x => x == 0) != -1) {
                    SelectedArr.splice(SelectedArr.findIndex(x => x.id == 0), 1);
                }
            } else {
                if (SelectedArr.findIndex(x => x.id == 0) != -1) {
                    SelectedArr.splice(SelectedArr.findIndex(x => x.id == 0), 1);
                }
            }
        }

        return SelectedArr;
    }

    getToggleAllFilter(DataArr, SelectedArr) {
        if (SelectedArr.length != DataArr.length) {
            SelectedArr = [];
            DataArr.forEach(result => { SelectedArr.push(result.id); });
        } else {
            SelectedArr = [];
        }
        return SelectedArr;

    }

    getToggleSingleSelectFilter(Arr, value) {
        Arr.splice(0, 1);
        Arr.push(value);
        return Arr;
    }

    getToggleSingleFilter(ga, gv) {
        if (ga.indexOf(gv) == -1) {
            ga.splice(0, 1);
            ga.push(gv);
        } else {
            ga.splice(0, 1);
        }
        return ga;
    }
    // This function will return only date with 00:00:00 time
    GetDateWithoutTime(input: Date) {
        if (typeof (input) == 'string') {
            input = this.getDate(input);
        }
        input = cloneDeep(input);
        input.setHours(0, 0, 0, 0);
        return input;
    }
    // Validates only date without time - Returns true if both dates are same
    ValidateDatesAreEqual(date1: Date, date2: Date) {
        return this.GetDateWithoutTime(date1).valueOf() == this.GetDateWithoutTime(date2).valueOf();
    }

     // Validates only date with time - Returns true if both dates are same
     ValidateDatesAreEqualWithTime(date1: Date, date2: Date) {
        return this.GetDateWithTime(date1).valueOf() == this.GetDateWithTime(date2).valueOf();
    }


    // This function will return only date with time
    GetDateWithTime(input: Date) {
        if (typeof (input) == 'string') {
            input = this.getDate(input);
        }
        return input;
    }
    // Validates only date with time - Returns true if both dates are same
    ValidateDateTimeLessThan(date1: Date, date2: Date) {
        return this.GetDateWithTime(date1).valueOf() <= this.GetDateWithTime(date2).valueOf();
    }

    ValidateDateTimeGreaterThan(date1: Date, date2: Date) {
        return this.GetDateWithTime(date1).valueOf() >= this.GetDateWithTime(date2).valueOf();
    }

    // Formats money based on given language and currency code
    FormatMoney(value: any, languageCode: string, currencyCode: string, includeCurrencysymbol: boolean = false) {

        let formattedMoney: string = value.toLocaleString(languageCode, {
            style: 'currency',
            currency: currencyCode,
        });
        if (formattedMoney) {
            if (!includeCurrencysymbol) {
                formattedMoney = formattedMoney.substring(1);
            }
        } else {
            formattedMoney = value;
        }

        return formattedMoney;
    }

    // Disables given Form group controls

    disableFormControls(formGroup: UntypedFormGroup, disableControls: string[]){
        disableControls = disableControls ? disableControls : [];
         for (let ctrl of disableControls) {
           let control: string = ctrl;
           formGroup.controls[control].disable({ emitEvent: false });
         }
    }

    /**
     * Disables Form Group controls( applies Read only )
     * @param FormGroup Form Group with Valid Form Controls.
     * @param excludedControls Exceptional controls.
     */
    disableControls(formGroup: UntypedFormGroup, excludedControls?: string[]) {
        excludedControls = excludedControls ? excludedControls : [];
        const allControls: string[] = Object.keys(formGroup.controls);
        for (let ctrl of allControls) {
            let _control: string = ctrl;
            if (!excludedControls.includes(_control)) {
                formGroup.controls[_control].disable({ emitEvent: false });
            }
        }

    }

    /**
     * Enables Form Group controls( applies Read only )
     * @param FormGroup Form Group with Valid Form Controls.
     * @param excludedControls Exceptional controls.
     */
    enableControls(formGroup: UntypedFormGroup, excludedControls?: string[]) {
        excludedControls = excludedControls ? excludedControls : [];
        const allControls: string[] = Object.keys(formGroup.controls);
        for (let ctrl of allControls) {
            let _control: string = ctrl;
            if (!excludedControls.includes(_control)) {
                formGroup.controls[_control].enable();
            }
        }

    }

    escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    public formURL(baseURL: string, params: ServiceParams): string {
        let url: string = `${baseURL}/`;
        if (params.uriParams != undefined && params.uriParams != null && typeof params.uriParams == "object") {
          let route: string = params.route;
          let keys: string[] = Object.keys(params.uriParams);
          for (let key of keys) {
            const escapedKey = this.escapeRegExp(key);
            var regEx = new RegExp("{" + escapedKey + "}", "ig");
            route = route.replace(regEx, params.uriParams[key]);
          }
          url += route;
        } else {
          url += params.route;
        }
        url = this.formatQueryString(url, params);
        if (!(url.indexOf("http://") > -1 || url.indexOf("https://") > -1))
          url = window.location.origin + url;
        return url;
      }


      private formatQueryString(url: string, params: ServiceParams): string {

        let queryParams: string[] = this.matchQueryStringRegex(url);
        for (var queryParam of queryParams) {
          var paramName = queryParam.split(":")[0];
          paramName = paramName ? paramName : "";
        //   paramName = paramName.replace("{", "");
        paramName = paramName.replace(/\{/g, '');
          var qParamValue = params.uriParams[paramName];
          var qParamString = "";
          if (typeof qParamValue == "object" && qParamValue && qParamValue.length > 0) {
            for (var value of qParamValue) {
              qParamString += `${paramName}=${value}&`
            }
            // To remove last &
            qParamString = qParamString.substr(0, qParamString.length - 1);
          }
          else {
            qParamString = `${paramName}=${qParamValue}`;
          }
          url = url.replace(queryParam, qParamString);
        }
        return url;
      }

      private matchQueryStringRegex(url: string): string[] {
        var regex = /{[A-Z]+:QueryString}/gi;
        var expMatch: RegExpExecArray;
        var result: string[] = [];
        while ((expMatch = regex.exec(url)) !== null) {
          // This is necessary to avoid infinite loops with zero-width matches
          if (expMatch.index === regex.lastIndex) {
            regex.lastIndex++;
          }

          // The result can be accessed through the `m`-variable.
          expMatch.forEach((match, groupIndex) => {
            result.push(match);
          });
        }
        return result;
      }

    getRecurringDates(type: RecurringType, startDate: Date, endDate: Date, everyRecurringNumber: number,
        days?: number[], monthlyDate?: Date, monthlyDay?: number, month?: number): Date[] {
        const dates: Date[] = [];
        days = days ? days : [];
        days = days.filter(x => (x || x == 0));
        const dayObject = [];
        startDate = this.GetDateWithoutTime(startDate);
        endDate = this.GetDateWithoutTime(endDate);
        let dateDiff = this.DateDiff.inDays(startDate, endDate) + 1;
        if (type == RecurringType.Daily) {
            // check zero
            everyRecurringNumber = everyRecurringNumber ? everyRecurringNumber : 1;
            dateDiff = Math.round(dateDiff / everyRecurringNumber);
            for (let i = 0; i <= dateDiff; i++) {
                if (startDate.getTime() <= endDate.getTime()) {
                    dates.push(startDate);
                    startDate = this.AddDays(startDate, everyRecurringNumber);
                }
            }
        } else if (type == RecurringType.Weekly) {
            const daysArrayInGivenPeriod = [];
            let dayOccurIndex = 0;
            for (let index = 0; index < dateDiff; index++) {
                const curday: number = this.AddDays(startDate, index).getDay();
                if (curday == 0 && index != 0) {
                    dayOccurIndex++;
                }
                dayObject.push({
                    date: this.AddDays(startDate, index),
                    dayIndex: dayOccurIndex,
                    day: curday,
                    added: false
                });
                if (!daysArrayInGivenPeriod.includes(curday) && days.includes(curday)) {
                    daysArrayInGivenPeriod.push(curday);
                }
            }
            for (let i = 0; i < dateDiff; i++) {
                const curDate = this.AddDays(startDate, i);
                const flg = dayObject.filter(x => x.day == curDate.getDay() && !x.added && (x.dayIndex % everyRecurringNumber) == 0);
                if (flg.length > 0) {
                    flg[0].added = true;
                }
                if (daysArrayInGivenPeriod.includes(curDate.getDay()) && (everyRecurringNumber == 1 || flg.length > 0)) {
                    const dt = flg.length > 0 ? flg[0].date : curDate;
                    if (dt.getTime() <= endDate.getTime()) {
                        dates.push(dt)
                    }
                }
            }

        } else if (type == RecurringType.Monthly) {
            // days
            if (days && days.length > 0) {
                const daysArrayInGivenPeriod = [];
                let dayOccurIndex = 0, monthOccurIndex = 0;
                for (let index = 0; index < dateDiff; index++) {
                    const curday: number = this.AddDays(startDate, index).getDay();
                    const curDate: number = this.AddDays(startDate, index).getDate();
                    if (curDate == 1 && index != 0) {
                        monthOccurIndex++;
                    }
                    dayObject.push({
                        monthIndex: monthOccurIndex,
                        date: this.AddDays(startDate, index),
                        dayIndex: this.getDayIndexOfTheMonth(this.AddDays(startDate, index)),
                        day: curday,
                        added: false
                    });
                    if (!daysArrayInGivenPeriod.includes(curday) && days.includes(curday)) {
                        daysArrayInGivenPeriod.push(curday);
                    }
                }

                for (let i = 0; i < dateDiff; i++) {
                    const curDate = this.AddDays(startDate, i);
                    const flg = dayObject.filter(x => {
                        return x.day == curDate.getDay() && !x.added && x.dayIndex == monthlyDay && (x.monthIndex % everyRecurringNumber) == 0;
                    });
                    if (flg.length > 0) {
                        flg[0].added = true;
                    }
                    if (daysArrayInGivenPeriod.includes(curDate.getDay()) && flg.length > 0) {
                        const dt = flg.length > 0 ? flg[0].date : curDate;
                        if (dt.getTime() <= endDate.getTime()) {
                            dates.push(dt);
                        }
                    }
                }

            } else {
                const monthDiff = this.DateDiff.inMonths(startDate, endDate) + 1;
                for (let i = 0; i < monthDiff; i++) {
                    if ((everyRecurringNumber == 1 || (i % everyRecurringNumber) == 0) && startDate.getTime() <= endDate.getTime()) {
                        let monthlyDate1 = this.GetDateWithoutTime(new Date(startDate.getFullYear(), startDate.getMonth(), 1));

                        monthlyDate1.setMonth(startDate.getMonth() + i);
                        let m_startDate = cloneDeep(monthlyDate1);

                        const lastDate = new Date(m_startDate.getFullYear(), m_startDate.getMonth() + 1, 0).getDate();
                        if (lastDate < monthlyDate.getDate()) {
                            m_startDate.setDate(lastDate);
                        } else {
                            m_startDate.setDate(monthlyDate.getDate());
                        }
                        m_startDate = this.GetDateWithoutTime(m_startDate);
                        if (m_startDate.getTime() >= startDate.getTime() && m_startDate.getTime() <= endDate.getTime()) {
                            dates.push(m_startDate);
                        }
                    }
                }
            }
        } else if (type == RecurringType.Yearly) {
            const yearDiff = this.DateDiff.inYears(startDate, endDate) + 1;
            // Loop through years
            for (let i = 0; i < yearDiff; i++) {
                let y_startDate = this.localization.dateAdd.AddYears(startDate, i);
                let y_year = y_startDate.getFullYear();
                let y_month = everyRecurringNumber;
                let weekArr: any[] = this.getWeekFirstArrayOfMonth(new Date(y_year, y_month, 1));
                // loop through the days
                for (let day of days) {
                    let firstIndex = weekArr.map(a => a.day).indexOf(day);
                    let firstOccuranceOfDay = weekArr[firstIndex];
                    let finalDate = firstOccuranceOfDay.dateOnly && (firstOccuranceOfDay.dateOnly + (7 * monthlyDay));

                    let sch_date = this.GetDateWithoutTime(new Date(y_year, y_month, finalDate));
                    if (sch_date.getTime() >= startDate.getTime() && sch_date.getTime() <= endDate.getTime()) {
                        dates.push(sch_date)
                    }
                }
            }
        }
        return dates;
    }

    getDayIndexOfTheMonth(dt: Date): number {
        const startDate = cloneDeep(dt);
        startDate.setDate(1);
        return Math.floor(this.DateDiff.inDays(startDate, dt) / 7);
    }

    getWeekFirstArrayOfMonth(dt: Date): any[] {
        dt.setDate(1);
        let weekArr = [];
        for (let index = 0; index < 7; index++) {
            let dt1 = this.AddDays(dt, index);
            weekArr.push({
                day: dt1.getDay(),
                dateTime: dt1,
                dateOnly: dt1.getDate()
            });
        }
        return weekArr;
    }

    getSystemConfigJSON(configs: SystemConfig[]) {
        const result = {};
        configs.forEach(x => result[x.switch] = x.value);
        return result;
    }

    getConfiguration(successCallback, errorCallBack, moduleName: string, switchName: string) {
        this.httpServiceCall.CallApiWithCallback<any>({
            host: GlobalConst.Host.spaManagement,
            success: successCallback,
            error: errorCallBack,
            callDesc: 'GetConfiguration',
            method: HttpMethod.Get,
            uriParams: { module: moduleName, Switch: switchName },
            showError: true,
            extraParams: []
        });
    }

    CheckForMaxvalue(value: any, maxlengthPositive: any = 999.99, maxlengthNegative: any = -999.99): boolean {
        return (value > maxlengthPositive || value < maxlengthNegative);
    }
    FormatValueForMaxLength(value: any, maxlengthPositive: any = 999.99, maxlengthNegative: any = -999.99): number {
        if (value > maxlengthPositive) {
            value = maxlengthPositive;
        } else if (value < maxlengthNegative) {
            value = maxlengthNegative;
        }
        return value;
    }

    RedirectTo(redirectToModules: RedirectToModules): void {
        let url = '';
        switch (redirectToModules) {
            case RedirectToModules.retail:
                url = '/shop/viewshop';
                break;
            case RedirectToModules.order:
                url = '/shop/viewshop/order';
                break;
            case RedirectToModules.appointment:
                url = '/appointment/newappointment';
                break;
            case RedirectToModules.settings:
                url = '/setting';
                break;
            case RedirectToModules.exchange:
                url = '/shop/exchange';
                break;
            case RedirectToModules.Dayend:
                url = '/audit/dayend';
                break;
            case RedirectToModules.home:
                url = '/home/dashboard';
                break;
            case RedirectToModules.RevenuePostingsLog:
                    url='/shop/viewshop/retailtransactions/revenuepostingslog'
                    break;
            default:
                break;
        }
        this.route.navigateByUrl(url);
    }

    sortObj(list, key) {
        function compare(a, b) {
            a = a[key];
            b = b[key];
            let type = (typeof (a) === 'string' ||
                typeof (b) === 'string') ? 'string' : 'number';
            let result;
            if (type === 'string') { result = a.localeCompare(b); }
            else { result = a - b; }
            return result;
        }
        return list.sort(compare);
    }

    /**
    * Returns date object with property date and given time.
    * @param string ISO time format (HH:mm or HH:mm A)*
    */
    TimeToPropertyDateTime(value: string): Date {
        return this.getDate(this.localization.ConvertDateToISODate(this.PropertyInfo.CurrentDate) + 'T' + value);
    }

    // This method should only be used if the Input field does not have a time as Date() will include the timezone also.
    // For Eg, if the application is browsed from any location which has a Negative GMT (like PST or EST),
    // the Date("YYYY-MM-DD") will return 1 day less
    // Hence, we will append the hours and create a date
    parseDateToDateWithHours(date: string): Date {
        return this.getDate(date + 'T00:00:00');
    }

    // This will work only in linux timezone format
    getPropertyTime(): string {
        let propertyCurDateTime = '';
        let timeZoneStr = '';
        try {
            let propertyTimeZone = this.GetPropertyInfo('TimeZone');
            const result = findIana(propertyTimeZone.trim());
            if(result && result.length > 0){
                timeZoneStr = result[0];// gets relevant IANA Time zone for Windows Time zone
            }else
            {
                timeZoneStr = propertyTimeZone;//sets IANA Time zone
            }
            propertyCurDateTime = this.getCurrentDate().toLocaleString('en-US', { timeZone: timeZoneStr });
        } catch (e) {
            propertyCurDateTime = this.getCurrentDate().toLocaleString();
        }
        return propertyCurDateTime;
    }

    public GetPropertyInfo(name: string) {
        let nameEQ = name + '=';
        let propertyInfo = sessionStorage.getItem('propertyInfo');
        if (propertyInfo != null) {
            let ca = propertyInfo.split(';');

            for (let data of ca) {
                let c = data.trim();
                while (c.charAt(0) == ' ') { c = c.substring(1, c.length); }
                if (c.indexOf(nameEQ) == 0) { return c.substring(nameEQ.length, c.length); }
            }
        }
        return '';
    }

    // Wrapper for new Date("dateString")
    getDate(input: any): Date {
        return this.localization.getDate(input);
    }

    getMinDate(...params: Date[]) {
        const firstDate = this.GetDateWithoutTime(params[0]);
        let minDate = cloneDeep(firstDate);
        for (const element of params) {
            const date = this.GetDateWithoutTime(element);
            if (minDate > date) {
                minDate = cloneDeep(date);
            }
        }
        return minDate;
    }

    getMaxDate(...params: Date[]) {
        const firstDate = this.GetDateWithoutTime(params[0]);
        let maxDate = cloneDeep(firstDate);
        for (const element of params) {
            const date = this.GetDateWithoutTime(element);
            if (maxDate < date) {
                maxDate = cloneDeep(date);
            }
        }
        return maxDate;
    }

    // Wrapper for new Date()
    getCurrentDate(): Date {
        return this.localization.getCurrentDate();
    }

    getFullMonth(dt: Date): string {
        return (dt.getMonth() + 1 > 9 ? dt.getMonth() + 1 : '0' + (dt.getMonth() + 1)).toString();
    }
    getFullDate(dt: Date): string {
        return (dt.getDate() > 9 ? dt.getDate() : '0' + dt.getDate()).toString();
    }

    GetClientTimeZone(): string {
        let zone = '';
        try {
            zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch (e) { }
        return zone;
    }
    GetCurrentUserSessionId(): number {
        let sessionId = 0;
        let session = sessionStorage.getItem('userSession');
        if (session) {
            sessionId = Number(session);
        }
        return sessionId;
    }

    checkDateWithLocaleFormat(dateString: string): boolean {
        return moment(dateString, this.localization.dateFormat, true).isValid();

    }

    convertLocalizedDateToAPIDateInput(dateString: string): Date {
        return this.getDate(moment(dateString, this.localization.dateFormat));
    }

    getDateFromLocalized(dateString: string): Date {
        return this.getDate(moment(dateString, 'DD MM YYYY', this.localization.localeCode));
    }

    roomOpenDialog(popupData: any, callback?: (x: any) => void): MatDialogRef<any, any> {
        const data = {
            headername: popupData.headertext,
            headerIcon: popupData.icon,
            headerMessage: popupData.text,
            buttonName: popupData.buttonname,
            type: 'message',
            isloaderenable: popupData.isloaderenable,
            isHiddenFieldRequired: popupData.isHiddenFieldRequired,
            cardpayment: popupData.cardpayment
        };
        const dialogRef = this.dialog.open(CommonAlertMessagePopupComponent, {
            // width: '350px',
            // height: '300px',
            width: '350px',
            hasBackdrop: true,
            panelClass: 'small-popup',
            data,
            disableClose: true
        });
        const subscription = dialogRef.afterClosed().subscribe((x) => {
            if (callback) {
                callback(x);
            }
            if (subscription) {
                subscription.unsubscribe();
            }
        });
        return dialogRef;
    }



    isIpad() {
        const userAgent = navigator.userAgent || navigator.vendor;
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            return true;
        }
        return false;
    }

    getFormattedPhNo(PhoneNo: any): any {
        // ADDING FOR EXTENSION FIELD
        let _number: any;
        let _onlynumber: any;
        let countryCode = '';
        if (PhoneNo.number != undefined && PhoneNo.number != '') {
            if (PhoneNo.contactTypeId == '3') {
                let PhnoExt = [];
                PhnoExt = PhoneNo.number.split(':');
                if (PhnoExt.length > 1) {
                    PhoneNo.extension = PhnoExt[0];
                    PhoneNo.number = PhnoExt[1];
                    countryCode = this.splitCountryCode(PhnoExt[1]);
                    _onlynumber = this.splitPhoneNo(PhnoExt[1]);
                } else {
                    PhoneNo.extension = PhoneNo.extension ? PhoneNo.extension : '';
                    countryCode = this.splitCountryCode(PhnoExt[0]);
                    _onlynumber = this.splitPhoneNo(PhnoExt[0]);
                }
            } else {
                countryCode = this.splitCountryCode(PhoneNo.number);
                _onlynumber = this.splitPhoneNo(PhoneNo.number);
            }
            const PhoneFormat = this.getPhoneFormat();
            const ExtPhoneFormat = this.getExtPhoneFormat();
            const phoneNumber = PhoneNo.extension ? this.formatphno.transform(`${PhoneNo.extension}:${_onlynumber}`, ExtPhoneFormat) : this.formatphno.transform(_onlynumber, PhoneFormat);
            _number = countryCode + phoneNumber;
            return _number;
        }
    }


    getFormattedPhoneNumber(PhoneNo: string): string {
        // ADDING FOR EXTENSION FIELD
        let _number: string;
        let _onlynumber: string;
        let countryCode = '';

        let extension = '';
        let phoneNumber2 = '';
        if (PhoneNo != undefined && PhoneNo != '') {

            let PhnoExt = [];
            PhnoExt = PhoneNo.split(':');
            if (PhnoExt.length > 1) {
                extension = PhnoExt[0];
                phoneNumber2 = PhnoExt[1];
                countryCode = this.splitCountryCode(PhnoExt[1]);
                _onlynumber = this.splitPhoneNo(PhnoExt[1]);
            } else {
                countryCode = this.splitCountryCode(PhnoExt[0]);
                _onlynumber = this.splitPhoneNo(PhnoExt[0]);
            }
        }
        const PhoneFormat = this.getPhoneFormat();
        const ExtPhoneFormat = this.getExtPhoneFormat();
        const phoneNumber = extension ? this.formatphno.transform(`${extension}:${_onlynumber}`, ExtPhoneFormat) : this.formatphno.transform(_onlynumber, PhoneFormat);
        _number = countryCode + phoneNumber;
        return _number;

    }
    splitPhoneNo(phNo) {
        let phoneNumber = phNo;
        if (phNo.indexOf('|') != -1) {
            const splitNo = phNo.split('|');
            phoneNumber = splitNo[1];
        }
        return phoneNumber;
    }

    splitCountryCode(phNo) {
        let phoneNumber;
        if (phNo.indexOf('|') != -1) {
            const splitNo = phNo.split('|');
            phoneNumber = splitNo[0] ? splitNo[0] + ' - ' : '';
        } else {
            phoneNumber = '';
        }
        return phoneNumber;
    }


    GetPercentageId(percent: number): number {
        let percentageId = 0;
        if (percent == 10) {
            percentageId = GlobalConst.ServiceChargeGratuityPercentId.TenPercent;
        } else if (percent == 15) {
            percentageId = GlobalConst.ServiceChargeGratuityPercentId.FifteenPercent;
        } else if (percent == 20) {
            percentageId = GlobalConst.ServiceChargeGratuityPercentId.TwentyPercent;
        } else if (percent == 25) {
            percentageId = GlobalConst.ServiceChargeGratuityPercentId.TwentyFivePercent;
        }
        return percentageId;
    }

    RoundOff2DecimalPlaces(value: number): number {
        return Number(value.customToFixed());
    }

    // Can be used for percentage
    // percentageRoundOff2DecimalPlaces(value: number): number {
    //     return Math.round(value * 100) / 100;
    // }

    MidPointRoundOffTwo(Amount): number {
        let amount = Number(Amount);
        Amount = +(Number(amount.customToFixed()));
        return Amount;
      }

    GetGuestIdbyTitle(title: string): number {
        let id = 0;
        if (!title) {
            return id;
        }
        title = title.replace('.', '').toLowerCase();
        switch (title) {
            case 'dr': id = 1; break;
            case 'fr': id = 2; break;
            case 'miss': id = 3; break;
            case 'mr': id = 4; break;
            case 'mrs': id = 5; break;
            case 'ms': id = 6; break;
            case 'prof': id = 7; break;
            case 'rev': id = 8; break;
        }
        return id;
    }

    GetGuestTitlebyId(id: number): string {
        let title = '';
        switch (id) {
            case 1: title = 'DR'; break;
            case 2: title = 'FR'; break;
            case 3: title = 'MISS'; break;
            case 4: title = 'MR'; break;
            case 5: title = 'MRS'; break;
            case 6: title = 'MS'; break;
            case 7: title = 'PROF'; break;
            case 8: title = 'REV'; break;
        }
        return title;
    }    

    GetNetAmountFromGross(grossPrice: number, vatPercentage): number {
        return Number((grossPrice / 1 + (vatPercentage / 100)).customToFixed());
    }

    GetVATAmountFromGross(grossPrice: number, vatPercentage): number {
        return Number((grossPrice - this.GetNetAmountFromGross(grossPrice, vatPercentage)).customToFixed());
    }

    GetPercentageFromPercentageAmount(actualPrice: number, percentagePrice: number): number {
        return Number((percentagePrice / actualPrice * 100).customToFixed());
    }

    public GetUserInfo(name: string) {
        return this.localization.GetsessionStorageValue('_userInfo', name);
    }

    public validateString(input: string) {
        if (input != 'null' && input != null && input != undefined && input != '') {
            return true;
        }
        return false;
    }

    /**
 * Shows Error Popup
 * @param {string} message content
 * @memberof Utilities
 */
    public showError(message: string, isUnhandledError?, referenceIdText?): void {
        this.showCommonAlert(message, AlertType.Error, ButtonTypes.Ok,undefined, undefined, undefined, isUnhandledError, referenceIdText);
    }

     /**
 * Shows Error Popup as warning
 * @param {string} message content
 * @memberof Utilities
 */
     public showErrorAsWarning(message: string, isUnhandledError?, referenceIdText?): void {
        this.showCommonAlert(message, AlertType.Warning, ButtonTypes.Ok,undefined, undefined, undefined, isUnhandledError, referenceIdText);
    }

    /**
   * Alert popup to show 'Warning' , 'Error' , 'Success'
   * @param {string} message  - Content
   * @param {AlertType} type  - Type of Popup
   * @param {ButtonType} [btnType=ButtonType.Ok] - Button Actions Type( by default 'Ok')
   * @param {(result: string, extraParams?: any[]) => void} [callback] - CallBack ( optional )
   * @returns - Dialog Reference of the modal with Result of enum AlertAction
   * @memberof Utilities
   */
    public showCommonAlert(message: string, type: AlertType, btnType: ButtonTypes = ButtonTypes.Ok,
        callback?: (result: AlertAction, extraParams?: any[]) => void, extraParams?: any[], headerText?: string, isUnhandledError?, referenceIdText?) {

        const dialogRef = this.dialog.open(CommonAlertPopupComponent, {
            height: 'auto',
            width: '300px',
            data: { type, message, buttontype: btnType, header: headerText, isUnhandledError:isUnhandledError,referenceIdText:referenceIdText },
            panelClass: 'small-popup',
            disableClose: true,
            hasBackdrop: true,
            backdropClass: 'Overlay-Alerts'
        });
        this.subscription = dialogRef.afterClosed().subscribe(res => {
            if (callback) {
                callback(res, extraParams);
            }
        });
        return dialogRef;
    }

    public showAlert(message: string, type: AlertType, btnType: any | newButtonType = newButtonType.Ok,
        callback?: (result: AlertAction | any, extraParams?: any[]) => void, extraParams?: any[], headerText?: string,
        additionalInfo?: { message: string, class: string }, moreOptionsLabel?: string, moreOptions?: any) {
        throw new Error('Not Implemented');
    }

    public showAccessError(title: string, message: any, popButtonType: ButtonTypes = ButtonTypes.Ok, callback?: (result: string, extraParams?: any[]) => void, extraParams?: any[] | any, closebool = true) {
        this.hideOverlays();
        const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
            height: 'auto',
            width: '40%',
            data: { headername: title, closebool, templatename: 'In the Given Data', datarecord: message, buttonType: popButtonType },
            panelClass: 'small-popup',
            disableClose: true,
            hasBackdrop: true
        });
        this.subscription = dialogRef.afterClosed().subscribe(res => {
            if (callback) {
                callback(res, extraParams);
            }
        });
        return dialogRef;
    }

    showToastMessage(
        snackMessage: string,
        snackType: SnackBarType = SnackBarType.Info,
        timeout: number = 5000,
        horizontalPos: MatSnackBarHorizontalPosition = 'right',
        verticalPos: MatSnackBarVerticalPosition = 'top',
        iconName: string = '',
        minWidth: number = 0
    ) {
        return this.snackBar.openFromComponent(AgSnackBarComponent, {
            duration: timeout,
            horizontalPosition: horizontalPos,
            verticalPosition: verticalPos,
            data: {
              message: snackMessage,
              type: snackType,
              icon: iconName,
              minWidth: minWidth
            },
            panelClass: ["ag-snackbar-container"],
          });
    }

    setSession(key: string, value: string) {
        sessionStorage.setItem(key, value);
    }
    setLocalStore(key: string, value: string) {
        sessionStorage.setItem(key, value);
    }
    getSessionValue<T>(key: string): T {
        let sessionData: T;
        const session = sessionStorage.getItem(key);
        if (session) {
            sessionData = JSON.parse(session);
        }
        return sessionData;
    }
    removeSession(key: string) {
        sessionStorage.removeItem(key);
    }

    getAPIDateTimeFromDateAndTimeControls(dateControlValue: string, timeControlValue: string): string {
        let givenDate = this.localization.getDate(dateControlValue);
        return (this.convertDateFormat(this.getDate(
            givenDate.getFullYear() +
            "-" +
            this.getFullMonth(givenDate) +
            "-" +
            this.getFullDate(givenDate) +
            "T" +
            this.localization.DeLocalizeTime(timeControlValue)
        )));
    }


    getMsalAuthParams(): AuthenticationParameters {
        return {
            scopes: Window.prototype.MsalConfig.Scopes
        };
    }

    public safeAlert(value: string): void {

    }

    // Validates only date without time - Returns true if date is greater that other date
    ValidateDatesGreaterThan(date1: Date | string, date2: Date | string) {
        return this.GetDateWithoutTime(this.localization.getDate(date1)).valueOf() > this.GetDateWithoutTime(this.localization.getDate(date2)).valueOf();
    }

    addMinsInTime(time: string, minutesToBeAdded: number) {
        return moment(time, 'hh:mm a').add(minutesToBeAdded, 'minutes').format('hh:mm a');
    }


    subMinsInTime(time: string, minutesToBeSubtracted: number) {
        return moment(time, "hh:mm a")
          .subtract(minutesToBeSubtracted, "minutes")
          .format("hh:mm a");
    }

    /**
     * Function to reset time part of date to 00:00:000
     * @param {Date} date
     * @returns {Date}
     * @memberof Utilities
     */
    resetTime(date: Date): Date {
        return this.localization.getDate(date.setHours(0, 0, 0, 0));
    }
    /**
    *
    * @param Properties This method creates a css class with given styles and returns the class name
    * @param uniqueString unique name to append in class name
    * @param Type belong to
    */
    CreateCssClass(Properties: CssProp[], uniqueString: string, Type: string, deleteClass: boolean = false): string {
        if (!Properties || Properties.length === 0) { return ''; }

        const className = `css-class-${Type}-${uniqueString.replace('#', '-')}`;

        if (document.getElementsByClassName(className).length > 0 && !deleteClass) {
            return className;
        }
        let styleDivElem = document.getElementsByClassName('Style-Div');
        if (!styleDivElem || styleDivElem.length === 0) {
            const styleDiv = document.createElement('div');
            styleDiv.classList.add('Style-Div');
            document.body.appendChild(styleDiv);
            styleDivElem = document.getElementsByClassName('Style-Div');
        }

        const style = document.createElement('style');
        style.classList.add(className);
        style.type = 'text/css';
        let styleStr = `.${className} { `;

        for (const propertyStyle of Properties) {
            styleStr += ` ${propertyStyle.property}:${propertyStyle.value}; `;
        }

        styleStr += ` }`;

        // style.innerHTML = styleStr;
        styleDivElem[0].appendChild(style);
        this.localization.decodeHTMLEntityByTagName(styleStr,style);
        return className;
    }

    RgbaToRgb(RGBA) {
        const alpha = 1 - RGBA.alpha;
        const bg = { red: 255, green: 255, blue: 255 };
        const RGB = { red: 0, green: 0, blue: 0 };
        RGB.red = Math.round((RGBA.alpha * (RGBA.red / 255) + (alpha * (bg.red / 255))) * 255);
        RGB.green = Math.round((RGBA.alpha * (RGBA.green / 255) + (alpha * (bg.green / 255))) * 255);
        RGB.blue = Math.round((RGBA.alpha * (RGBA.blue / 255) + (alpha * (bg.blue / 255))) * 255);
        return `rgb(${RGB.red},${RGB.green},${RGB.blue})`;
    }

    hexToRgbA(hex: string, resetOpacity?: number): string {
        let c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length === 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            resetOpacity = resetOpacity ? resetOpacity : 1;

            return this.RgbaToRgb({ red: (c >> 16) & 255, green: (c >> 8) & 255, blue: c & 255, alpha: resetOpacity });
        }
        return '';
    }

    /**
* Checks whether given date lies in range
* @param {Date} dateToCheck
* @param {Date} from
* @param {Date} to
* @returns {boolean}
* @memberof Utilities
*/
    public isDateInGivenRange(dateToCheck: Date, from: Date, to: Date): boolean {
        return (dateToCheck.getTime() <= to.getTime() && dateToCheck.getTime() >= from.getTime());
    }

    public appendFormat(value: string | number, format: string): string {
        if (!value && value === '') {// for null exception
            return '';
        }
        const userInput = value.toString();
        let returnVal = '';
        let indexOfUserValue = 0;
        if (format && format === '') {
            return value.toString();
        }
        for (let frmt of format) {
            const char = frmt;
            const charCode = char.toString().charCodeAt(0);
            const IsNumber: boolean = ((charCode >= 48) && (charCode <= 57));
            if (!IsNumber) {
                returnVal = returnVal + frmt;
            } else {
                if (userInput[indexOfUserValue]) {
                    returnVal = returnVal + userInput[indexOfUserValue];
                    indexOfUserValue++;
                } else {
                    break;
                }
            }
        }
        return returnVal;
    }

    public phoneNumberFormatWithExtension(value: string) {
        if (!value || value == "") {
            return "";
        }
        else {
            return this.formatphno.transform(value, '');
        }

    }

    public formatPhoneNumber(value: string): string {
        value = value ? value : '';
        value = value.toString();
        const PhoneFormat = this.getPhoneFormat();
        return this.appendFormat(value, PhoneFormat) as string;
    }

    public formatPhoneExtention(value: string): string {
        value = value ? value : '';
        value = value.toString();
        const ExtPhoneFormat = this.getExtPhoneFormat();
        return this.appendFormat(value, ExtPhoneFormat) as string;
    }

    public removePhoneFormat(value: string) {
        value = value ? value : '';
        value = value.toString();
        return value.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
    }

    public getDateValue(date: string | Date): number {
        let dateValue: number;

        if (date && typeof date == 'object') {
            dateValue = date.getTime();
        } else if (date && typeof date == 'string') {
            dateValue = this.localization.getDate(date).getTime();
        } else {
            dateValue = date as any;
        }
        return dateValue;
    }


    calcAge(dateString) {
        return dateString != null ? ~~((Date.now() - +this.localization.getDate(dateString)) / (31557600000)) : 0;
    }

    findUserAgent() {
        const objAgent = navigator.userAgent;
        let objbrowserName = navigator.appName;

        if ((objAgent.indexOf('Edge')) !== -1) {
            objbrowserName = 'Microsoft Edge';
        } else if ((objAgent.indexOf('Chrome')) !== -1) {
            objbrowserName = 'Chrome';
        } else if ((objAgent.indexOf('Trident/7.0')) !== -1) {
            objbrowserName = 'IE11 ';
        } else if ((objAgent.indexOf('Trident/6.0')) !== -1) {
            objbrowserName = 'Internet Explorer 10';
        } else if ((objAgent.indexOf('Trident/5.0')) !== -1) {
            objbrowserName = 'Internet Explorer 9';
        } else if ((objAgent.indexOf('Trident/4.0')) !== -1) {
            objbrowserName = 'Internet Explorer 8';
        } else if ((objAgent.indexOf('Firefox')) !== -1) {
            objbrowserName = 'Firefox';
        } else if ((objAgent.indexOf('Safari')) !== -1) {
            objbrowserName = 'Safari';
        }
        return objbrowserName;
    }


    public toggleScreenMode() {
        const bodyTag = document.getElementsByTagName('body')[0];
        bodyTag.classList.toggle('full-screen');
    }

    getNumberString(input: string): string {
        return input.replace(/[^0-9]/g, '');
    }

    buildPhoneNumber(phoneType: number, extension: string, phoneNumber: string, countrycode?: string | number): string {
        phoneNumber = this.localization.validateString(phoneNumber) ? phoneNumber : '';
        if (countrycode) {
            countrycode = countrycode.toString();
            return (phoneType == ContactType.office) && extension ? extension + ":" + this.getNumberString(countrycode) + "|" + this.getNumberString(phoneNumber) : this.getNumberString(countrycode) + "|" + this.getNumberString(phoneNumber);
        } else {
            return (phoneType == ContactType.office) && extension ? extension + ":" + this.getNumberString(phoneNumber) : this.getNumberString(phoneNumber);
        }
    }

    getPhoneNumberFromValue(phoneType: number, phoneNumber: string): string | number {
        const PhoneFormat = this.getPhoneFormat();
        if(phoneType == PhoneTypes.others) {
            return phoneNumber;
        }
        return ((phoneType === ContactType.office && phoneNumber.split(':')[1]) ? (phoneNumber.split(':')[1].split('|')[1]) ? this.appendFormat(phoneNumber.split(':')[1].split('|')[1], PhoneFormat)
        : this.appendFormat(phoneNumber.split(':')[1], PhoneFormat)
        : phoneNumber.split('|')[1] ? this.appendFormat(phoneNumber.split('|')[1], PhoneFormat) : this.appendFormat(phoneNumber, PhoneFormat));
    }

    getExtensionFromValue(phoneType: number, phoneNumber: string): string {
        const tempPhone = phoneNumber.split(':')[1] ? phoneNumber.split(':')[0] : '';
        return phoneType === ContactType.office ? tempPhone : '';
    }
    getCountryCodeFromValue(phoneType: number, phoneNumber: string): string {
        if (phoneNumber && phoneType != PhoneTypes.others) {
            let result: string = '';
            if (phoneNumber.indexOf('|') == -1) {
                result = "00";
            } else {
                result = ((phoneType === ContactType.office && phoneNumber.split(':')[1]) ? phoneNumber.split(':')[1].split('|')[0]
                    : phoneNumber.split('|')[0]);
            }
            return result;
        }
        return "";
    }

    public getDatesRange(startDate: string | Date, stopDate: string | Date): Date[] {

        const _startDate: Date = this.localization.getDate((this.getDateValue(startDate)));
        const _stopDate: Date = this.localization.getDate((this.getDateValue(stopDate)));
        let dateArray = new Array<Date>();
        let currentDate = _startDate;
        while (currentDate <= _stopDate) {
            dateArray.push(this.localization.getDate(currentDate));
            currentDate = this.localization.AddDays(currentDate, 1);
        }
        return dateArray;
    }

    generateGUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            let r = this.getRandomDecimal() * 16 | 0;
            let v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    convertDateObjToAPIdate(value: Date | string, separator: string = "-") {
        return this.localization.convertDateObjToAPIdate(value, separator);
    }

    getLocalizedMonthAndDate(value: string) {
        var date = this.getDate(value);
        var localizedMonth = this.localization.getLocalizedMonthShort(date);
        return localizedMonth + " " + date.getDate();
    }

    localizeUTCDate(date, isTime: boolean = false) {
        return this.localization.localizeUTCDate(date, isTime);
    }

    convertLocalTimeToUTCDateTime(date, isTime: boolean = false) {
        return this.localization.convertLocalTimeToUTCDateTime(date, isTime);
    }

    GetPreviousURL() {
        return this._previousURL;
    }

    /**
   * @description generates RandomNumber based on number of digits
   * @param noOfDigits
   */
    GetRandomNumber(noOfDigits: number): number {
        if (noOfDigits) {
            var incrementer = new Array(noOfDigits - 1);
            let min = Number("1" + incrementer.fill(0).join(''));
            let max = Number("9" + incrementer.fill(0).join(''));
            return (Math.floor(this.getRandomDecimal() * max) + min);
        }
    }

    public showDependencyAlert(lable: string, message: string[], subHeader?: string, buttonType?: GlobalConst.ButtonType | any,
        callback?: (result: AlertAction | any, extraParams?: any[], additionalInfo?: { message: string, class: string }) => void) {

        const dialogRef = this.dialog.open(DeleteDependencyAlertComponent, {
            height: 'auto',
            width: '500px',
            data: { lable, message, subHeader, buttonType },


            panelClass: 'small-popup',
            disableClose: true,
        });
        this.subscription = dialogRef.afterClosed().subscribe(res => {
            if (callback) {
                callback(res);
            }
        });
        return dialogRef;
    }


    /**
  *
  * @param popupData
  * @param callback
  * @returns - Dialog Reference of the modal with Result
  */
    OpenCardSwipeDialog(popupData: any, callback?: (x: any) => void): MatDialogRef<any, any> {
        const data = {
            headername: popupData.headertext,
            headerIcon: popupData.icon,
            headerMessage: popupData.text,
            buttonName: popupData.buttonname,
            type: 'message',
            isloaderenable: popupData.isloaderenable,
            isHiddenFieldRequired: popupData.isHiddenFieldRequired,
            cardpayment: popupData.cardpayment,
            isManualEntry: popupData.isManualEntry
        }
        const dialogRef = this.dialog.open(CardSwipePopupComponent, {
            width: '350px',
            hasBackdrop: true,
            panelClass: 'small-popup',
            data: data,
            disableClose: true,
            backdropClass: 'Overlay-Alerts'
        });
        const subscription = dialogRef.afterClosed().subscribe((x) => {
            if (callback) {
                callback(x);
            }
            if (subscription) {
                subscription.unsubscribe();
            }
        });
        return dialogRef;
    }

    onCancelAlert(form: UntypedFormGroup, yesCallBack: Function, noCallBack?: Function) {
        let captions = this.localization.captions;
        if (form.dirty) {
            this.showAlert(captions['warn_datalost'], AlertType.Warning, GlobalConst.ButtonType.YesNo, (res => {
                if (res == AlertAction.YES) {
                    yesCallBack();
                } else if (noCallBack) {
                    noCallBack();
                }
            }));
        } else {
            yesCallBack();
        }
    }

    getRandomDecimal(): number {
        const crypto = window.crypto;
        return crypto.getRandomValues(new Uint32Array(1))[0]/2**32;
    }

    getPhoneFormat(): string {
        const UseUSPhoneFormat = sessionStorage.getItem("UseUSPhoneFormat");
        let PhoneFormat = this.localization.captions.common.PhoneFormat;
        if(UseUSPhoneFormat && UseUSPhoneFormat=="true"){
            PhoneFormat = "(999) 999-999999";
        }
        return PhoneFormat;
    }

    getExtPhoneFormat(): string {
        const UseUSPhoneFormat = sessionStorage.getItem("UseUSPhoneFormat");
        let ExtensionFormat = this.localization.captions.common.ExtensionFormat;
        if(UseUSPhoneFormat && UseUSPhoneFormat=="true"){
            ExtensionFormat = "(999) 999-999999 ext: 99";
        }
        return ExtensionFormat;
    }
      FilterCountry(value: string, countryDetails) {
        const filterValue = value.toLowerCase();
        return countryDetails.filter(state => state.CountryName.toLowerCase().indexOf(filterValue) === 0);
      }

      FilterCountryByName(value: string, countryDetails) {
        const filterValue = value.toLowerCase();
        return countryDetails.filter(state => state.CountryName.toLowerCase().indexOf(filterValue) === 0);

        // return countryDetails.filter(state => {
        //     return ((state.CountryName.toLowerCase() === filterValue) ||
        //             (state.Alpha2Code.toLowerCase() === filterValue) ||
        //             (state.Alpha3Code.toLowerCase() === filterValue))
        // });
      }

      FilterCountryByGlobalSearch(value: string, countryDetails) {
        const filterValue = value.toLowerCase();
        return countryDetails.filter(state => {
            return ((state.CountryName.toLowerCase() === filterValue) ||
                    (state.Alpha2Code.toLowerCase() === filterValue) ||
                    (state.Alpha3Code.toLowerCase() === filterValue))
        });
      }

      getLinkedRetailItems(cartData){
        return cartData && cartData.linkedRetailItemDetails ? JSON.parse(cartData.linkedRetailItemDetails) : [];
      }
     getPrinterConfigurationToPrint( printInformationType : PrintInformationType)
      {
        let printerDocumentData : PrintDocumentInput;
        let printerData : API.MachinePrinterConfiguration;
        let machinePrinterConfiguration : API.MachinePrinterConfiguration[] = this.localization.GetMachinePrinterConfig();
        if(machinePrinterConfiguration && machinePrinterConfiguration.length > 0)
        {
        printerData = machinePrinterConfiguration?.find(x=>x.printInformationType == printInformationType);
        printerDocumentData = printerData ? this.printDocumentInputMapper(printerData) : undefined;
        }
	    return printerDocumentData;
      }
      printDocumentInputMapper(data : API.MachinePrinterConfiguration) : PrintDocumentInput
      {
        return {
            printerName : data.printerName,
            noOfCopies : data.defaultNoofCopies
        }
      }
      async getByteData(res)
    {
        let byte: unknown;
        var arrayPromise = new Promise(async function(resolve) {
            var reader = new FileReader();

            reader.onloadend = function() {
                resolve(reader.result);
            };

            reader.readAsDataURL(res);
        });

        await arrayPromise.then(function(array) {
        //get the filestream data for print without getting the type
         var byteData:string[] = array.toString().split(',');
         byte = byteData.length == 2? byteData[1]:byteData[0];
        });
        return byte;
    }

    MaskCreditCardData(item: any): string {
        let card = '';
        if (item?.cardNumber) {
          card = 'XXXX-XXXX-XXXX-' + item?.cardNumber?.substring(item?.cardNumber?.length - 4, item?.cardNumber?.length);
        }
        if (item?.issuerType) {
            let cardIssuerType = '';
            switch (item?.issuerType)
            {
                case "visa": cardIssuerType = "VISA"; break;
                case "maestro": cardIssuerType = "Maestro"; break;
                case "mastercard": cardIssuerType = "Master Card"; break;
                case "jcb": cardIssuerType = "JCB"; break;
                case "interac": cardIssuerType = "Interac"; break;
                case "discover": cardIssuerType = "Discover"; break;
                case "dinersclub": cardIssuerType = "Diners Club"; break;
                case "chinaunionpay": cardIssuerType = "China Union Pay"; break;
                case "americanexpress": cardIssuerType = "American Express"; break;
                default: cardIssuerType = item?.issuerType?.toUpperCase(); break;
            }
            card += ' | ' + cardIssuerType;
        }
        if (item?.cardHolderName) {
          card += ' | ' + item?.cardHolderName;
        }

        return card;
    }
    MaskCreditCardDataWithCardExpDate(item: any,IncludeExpDate:boolean): string {
        var card=this.MaskCreditCardData(item);
        if(IncludeExpDate && item?.cardExpiration){
                var cardExpiration:string = item.cardExpiration;
                if (cardExpiration && cardExpiration.length === 6) {
                    card += ' | ' + cardExpiration.substring(4, 6) + '/' + cardExpiration.substring(2, 4); // MM/YY
                }
        }
        return card;
    }

    async getByte64Data(res)
    {
        let byte: unknown;
        let byteNumbers: unknown;
        var arrayPromise = new Promise(async function(resolve) {
            var reader = new FileReader();

            reader.onloadend = function() {
                resolve(reader.result);
            };

            reader.readAsDataURL(res);
        });

        await arrayPromise.then(function(array) {
        //get the filestream data for print without getting the type
         var byteData:string[] = array.toString().split(',');
         byte = byteData.length == 2? byteData[1]:byteData[0];


          byteNumbers = new Array(byteData[1].length);
for (let i = 0; i < byteData[1].length; i++) {
    byteNumbers[i] = byteData[1].charCodeAt(i);
 }
// console.log('bbb');
// console.log(byteNumbers);

        });
        return byteNumbers;
    }

    // takes phone number as input and returns country code - input format - +(91)99999 99999
    countryCodeParser(phoneNumber)
    {
        let countryCode = null;
        let firstCharacter = phoneNumber? phoneNumber.charAt(0):null;
        if (phoneNumber && firstCharacter == '+')
        {
        let openBracesSplitter = phoneNumber.split('(');
            if(openBracesSplitter.length > 1)
            {
                let closeBracesSplitter =  openBracesSplitter[1].split(')');
                countryCode = closeBracesSplitter[0]
            }
        }

        return countryCode;
    }

    //// takes phone number as input and returns country code - input format - 91|99999 99999
    countryCodeParserWithPipe(phoneNumber)
    {
        let countryCode = null;
        if (phoneNumber)
        {
        let countryCodeSplitter = phoneNumber.split('|');
            if(countryCodeSplitter.length > 0)
            {
                countryCode = countryCodeSplitter[0];
            }
        }

        return countryCode;
    }

    // takes phone number and country code as input and returns phone number alone - Phone number input format - +(91)99999 99999
    phoneNumberParser(phNumber,cCode)
    {
        let phoneNumber = null;
        if(phNumber) {
        let firstCharacter = phNumber.charAt(0);
        let countryCode = '('+cCode+')';
        if (phNumber && firstCharacter == '+')
        {
            let countryCodeSplitter = phNumber.split(countryCode);
            if(countryCodeSplitter.length > 1) {
                phoneNumber = countryCodeSplitter[1].trim();
            }
        }
        }
        return phoneNumber;

    }

    // takes countrycode as input and returns placeholder
    countryCodePlaceholder(countryCode)
    {

      return (countrylist.find(result => result.dialCode == countryCode)) ?  countrylist.find(result => result.dialCode == countryCode).placeHolder : null ;
    }

    // coverts phone number into required format
    appendphoneFormat(value:string, format:string){
        if (!value && value == "") {
            return "";
        }
        let userInput = value.toString();
        userInput = userInput.replace(/[^0-9]/g,'')
        let returnVal:string ="";
        let indexOfUserValue:number = 0;
        if (format && format == "") {
            return value;
        }
        userInput = (userInput.length > 1 && userInput[0] === '0' && format[1] === '0' ? userInput.replace(/[0]/, '') : userInput);
        for (let type of format) {
            const char = type;
            let charCode = char.toString().charCodeAt(0);
            const IsFormatNumber:boolean = (charCode === 57);
            if (!IsFormatNumber) {
               returnVal = returnVal+type;
            }else{
                if (userInput[indexOfUserValue]) {
                    returnVal = returnVal+userInput[indexOfUserValue];
                    indexOfUserValue++;
                }else{
                    break;
                }
            }
        }

        return returnVal;
      }

      // phone number and country code as input and returns formatted phonenumber- Phone number format - +(91)99999 99999
      viewPhoneNumberFormat(phnNumber:string,cCode?:string) : string {
        let formatPhoneNumber = phnNumber;
        let countryCode = cCode ? cCode : this.countryCodeParser(phnNumber) ;
        if(countryCode) {
            let countryFormat = this.countryCodePlaceholder(countryCode);
            let phoneNumber = this.phoneNumberParser(phnNumber,countryCode);
            if(countryFormat) {
            formatPhoneNumber = '+(' + countryCode + ') ' + this.appendphoneFormat(phoneNumber,countryFormat);
            }
        }
        return formatPhoneNumber;
    }


    //phone number as input and returns formatted phonenumber- Phone number format - ext:91|99999 99999 (or) :91|99999 99999
    viewPhoneNumberFormatWithPipe(phnNumber:string, isEditMode?:boolean) : string {
        let isViewScreen = true;
        if(isEditMode){
            isViewScreen = false;
        }
        let formatPhoneNumber = phnNumber;
        let splitPhoneNum = [] ;
        if(phnNumber) {
            splitPhoneNum = phnNumber.split('|');
        }
        let countryCode = '';
        if((splitPhoneNum.length > 0) && (splitPhoneNum[0].indexOf(':') > -1)){
            let semicolonSeperator = splitPhoneNum[0].split(':');
            countryCode = (semicolonSeperator.length > 1) ? semicolonSeperator[1] : '';
        } else {
            countryCode = (splitPhoneNum.length > 0) ? splitPhoneNum[0] : '' ;
        }
        let phoneNumber = (splitPhoneNum.length > 0) ? splitPhoneNum[1] : '' ;
        if(countryCode) {
            let countryFormat = this.countryCodePlaceholder(countryCode);
            if(countryFormat) {
            formatPhoneNumber = isViewScreen? '+(' + countryCode + ') ' +  this.appendphoneFormat(phoneNumber,countryFormat): this.appendphoneFormat(phoneNumber,countryFormat);
            }
        }

        return formatPhoneNumber;
    }
    getColors() {
        return [{
          color: '#000000',
          label: 'Black'
        },
        {
          color: '#4d4d4d',
          label: 'Dim grey'
        },
        {
          color: '#999999',
          label: 'Grey'
        },
        {
          color: '#e6e6e6',
          label: 'Light grey'
        },
        {
          color: '#ffffff',
          label: 'White',
          hasBorder: true
        },
        {
          color: '#e64c4c',
          label: 'Red'
        },
        {
          color: '#e6994c',
          label: 'Orange'
        },
        {
          color: '#e6e64c',
          label: 'Yellow'
        },
        {
          color: '#99e64c',
          label: 'Light green'
        },
        {
          color: '#4ce64c',
          label: 'Green'
        },
        {
          color: '#4ce699',
          label: 'Aquamarine'
        },
        {
          color: '#4ce6e6',
          label: 'Turquoise'
        },
        {
          color: '#4c99e6',
          label: 'Light blue'
        },
        {
          color: '#4c4ce6',
          label: 'Blue'
        },
        {
          color: '#994ce6',
          label: 'Purple'
        }];
      }

      getFontSize() {
        return [
          8
          , 9
          , 10
          , 11
          , 12
          , 14
          , 16
          , 18
          , 20
          , 22
          , 24
          , 26
          , 28
          , 30
        ];
      }
    getCssStringForCkEditor(){
        return '.image {width: 100%!important} table{display: table;width:unset;border-collapse: collapse;margin: .9em auto;border: #b3b3b3;} table td, table th {padding: .75rem;vertical-align: top;border: 1px solid #b3b3b3;}.image-style-side img{float: right!important;max-width: 50%;clear: both;display: table;margin: .9em auto;min-width: 50px;} .image img{clear: both;display: table;margin: .9em auto;min-width: 50px;text-align: center;}'
    }
    getCkeditorImgWidth(getValue?){
        let widthHeight = document.getElementsByClassName('ck-content')[0]?.getElementsByTagName('img');
        let elem= document.createElement("div");
        elem.innerHTML = getValue;
        let images: any = elem.getElementsByTagName("img");
        for(let i=0; i<widthHeight?.length; i++){
          images[i].style.width = widthHeight[i].offsetWidth+'px';
          images[i].style.height =widthHeight[i].offsetHeight+'px';
        }
        return elem.innerHTML;
    }
    /**
     * 
     * @param date1 javascript date with time
     * @param date2 javascript date with time
     * @returns '0 if date1 and date2 are equal, 1 if date1 is lesser and 2 if date1 is greater'
     */
    ValidateDatesWithTime(date1: Date, date2: Date): Number {
        let compareDateWithTime = CompareKey.invalid;
        if(this.GetDateWithTime(date1).valueOf() == this.GetDateWithTime(date2).valueOf()){
            compareDateWithTime = CompareKey.equal;
        } else if(this.GetDateWithTime(date1).valueOf() <= this.GetDateWithTime(date2).valueOf()){
            compareDateWithTime = CompareKey.lesser;
        } else if(this.GetDateWithTime(date1).valueOf() >= this.GetDateWithTime(date2).valueOf()){
            compareDateWithTime = CompareKey.greater;
        } 
    return compareDateWithTime;   
    }
    /**
     * 
     * @param date1 javascript date
     * @param date2 javascript date
     * @returns '0 if date1 and date2 are equal, 1 if date1 is lesser and 2 if date1 is greater'
     */
    ValidateDates(date1: Date, date2: Date) : Number {
        let compareDate = CompareKey.invalid;
        if(this.GetDateWithoutTime(this.localization.getDate(date1)).valueOf() == this.GetDateWithoutTime(this.localization.getDate(date2)).valueOf()){
            compareDate = CompareKey.equal;
        } else if(this.GetDateWithoutTime(this.localization.getDate(date1)).valueOf() <= this.GetDateWithoutTime(this.localization.getDate(date2)).valueOf()){
            compareDate = CompareKey.lesser;
        } else if(this.GetDateWithoutTime(this.localization.getDate(date1)).valueOf() >= this.GetDateWithoutTime(this.localization.getDate(date2)).valueOf()){
            compareDate = CompareKey.greater;
        } 
        return compareDate;   
    }

    ValidateTime(time1: string, time2: string): Number {
        let compareDateWithTime = CompareKey.invalid;
        if (this.GetDateWithTime(this.localization.TimeToDate(time1)).valueOf() == this.GetDateWithTime(this.localization.TimeToDate(time2)).valueOf()) {
          compareDateWithTime = CompareKey.equal;
        } else if (this.GetDateWithTime(this.localization.TimeToDate(time1)).valueOf() <= this.GetDateWithTime(this.localization.TimeToDate(time2)).valueOf()) {
          compareDateWithTime = CompareKey.lesser;
        } else if (this.GetDateWithTime(this.localization.TimeToDate(time1)).valueOf() >= this.GetDateWithTime(this.localization.TimeToDate(time2)).valueOf()) {
          compareDateWithTime = CompareKey.greater;
        }
        return compareDateWithTime;
      
    }

    encodePassword(text: string): string {
        try {
            return btoa(new TextEncoder().encode(text).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        } catch {
            return text;
        }
    }
    
    decodePassword(text: string): string {
        try {
            return new TextDecoder().decode(Uint8Array.from(atob(text), c => c.charCodeAt(0)));
        } catch {
            return text;
        }
    }

    public processUnHandledExpection(err: HttpErrorResponse): { errorMessage: string, correlationId: string } {  
        var message_Obj:{ errorMessage: string, correlationId: string };
        var status_Code_Message : string = StatusMessages[err.status];
        var correlation_Id : string = err.headers.get('CorrelationId') ? "Ref Id: "+err.headers.get('CorrelationId') : "";

        if(status_Code_Message && correlation_Id){
            message_Obj = { errorMessage: status_Code_Message, correlationId: correlation_Id }
        }
        else if(correlation_Id && !status_Code_Message){
            message_Obj = { errorMessage: err.message, correlationId: correlation_Id }
        }
        else if(!correlation_Id && !status_Code_Message){
            message_Obj = { errorMessage: err.message, correlationId: "" }
        }
        else if(!correlation_Id && status_Code_Message){
            message_Obj = { errorMessage: err.message, correlationId: status_Code_Message }
        } 
        return message_Obj;
    }
   
}
