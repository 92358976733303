<section cdkDropList id="{{PlayerCollection.id}}" class="player-card-details" (cdkDropListDropped)="drop($event)"
  [cdkDropListData]="PlayerCollection['playerDetail']" cdkDropListOrientation="horizontal"
  [cdkDropListConnectedTo]="PlayerCollectionList">
  <div *ngIf="PlayerCollection.playerDetail.length > 0">
    <ng-container *ngIf="!customTableData.isadvDetailView">
      <div [attr.automationId]="'Btn_TeeSheetDetails_drag'" *ngFor="let player of PlayerCollection.playerDetail;let Oi= index" class="player-tee-button"
        [ngClass]="{'teegrid-shorthand-view' : customTableData.isMultiView && !customTableData.isDetailView, 'cursor-move' : !customTableData.isDragDisabled}"
        cdkDrag [cdkDragDisabled]="!player.isBlocked ? player.isDragDisabled : true"
        cdkDragBoundary="customTableData.dragBoundary" [popover]="infoPopover"
        [popoverOnHover]="(!customTableData.isDetailView && !customTableData.isTooltipDisabled)? true:false"
        (mouseover)="mouseOver($event,player)" (onShown)="onShown($event)"
        [popoverDisabled]="((customTableData.isDetailView) || (customTableData.isTooltipDisabled))" #popOverTarget
        (cdkDragStarted)="onDragStart($event, player, PlayerCollection)" (cdkDragMoved)="onTeeGridDragScroll($event)"
        style="pointer-events: all !important;">
        <div *ngIf="customTableData.isMultiView && !customTableData.isDetailView;else other_content">
          <div *ngIf="!player.isBlocked" class="hint-container compare-view-hint" [ngClass]="{'multi-course-width': isMultiCourse}">
            <!-- <div class="player-group" [ngStyle]="{'background-color': player.playGroup?.colorCode}"></div> -->
            <div class="hint-section align-top " [ngStyle]="{'background-color': player.playGroup?.colorCode}">
              {{getLocalizedPlayerPosition(player.playGroup?.listOrder)}}</div>
          </div>
          <span class="description-section" [ngClass]="{'multi-course-width': isMultiCourse}"  [ngStyle]="{'background-color': getColorcode(player)}">
            <div class="lbl compare-view-label">
              <span *ngIf="!player.isBlocked;else block_content" class="playerLabel">{{getplayerName(player)}}</span>
              <a *ngIf="(!player?.isPaidPlayer && !player?.isAllowPay && player.playerStatus != 2)" class="mr-2 icon-gratuity" [matTooltip]="commonCaptions.teetime.Pay" [ngClass]="{'ag_section--disable': player?.isAllowPay}" (click)="payNow(player)"></a>
              <a [attr.automationId]="'Btn_TeeSheetDetails_edit'" [matTooltip]="commonCaptions.edit" *ngIf="fromTeeSheet" class="icon-edit-new ag_ml--1" [ngClass]="{'ag_section--disable': isEditDisabled}"
                (click)="editPlayer(player, Oi)"></a>
              <ng-template #block_content>
                <span class="block-content">{{this.captions.lblBlocked}}</span>
              </ng-template>
              <a *ngIf="!isHistoricDate && player.isRemovable" class="remove-player icon-Cancel" (click)="removePlayer(player)"></a>
            </div>
          </span>
        </div>

        <ng-template #other_content>
          <div>
            <div *ngIf="!player.isBlocked" class="hint-container" [ngClass]="{'multi-course-width': isMultiCourse}">
              <!-- <div class="player-group" [ngStyle]="{'background-color': player.playGroup?.colorCode}"></div> -->
              <div class="hint-section-detailed align-top"
                [ngStyle]="{'background-color': player.playGroup?.colorCode}">
                {{getLocalizedPlayerPosition(player.playGroup?.listOrder)}}</div>
            </div>
            <span class="description-section"   [ngStyle]="{'background-color': getColorcode(player)}"
              [ngClass]="[isMultiCourse ? 'multi-course-width' : '', !fromTeeSheet ? 'description-hover-disable': '']">
              <div class="lbl">
                <div *ngIf="!player.isBlocked;else block_content"
                  class="ag_w--100 player-name-section ag_display--flex text-ellipsis justify-content-between">
                  <!-- <span class="player-edit-section"> -->

                  <!-- </span> -->
                  <span *ngIf="!customTableData.isDetailView" class="player-name">{{getplayerName(player)}}</span>
                  <span *ngIf="customTableData.isDetailView" class="player-name" [matTooltip]="getplayerName(player)">{{getplayerName(player)}}</span>
                  <div>
                    <a *ngIf="(player?.isAllowPlayerTypeRateTypeChange && !player?.isPaidPlayer && player.playerStatus != 2)" [ngClass]="{'ag_section--disable': player?.isDisablePlayerTypeRateTypeChange || player?.isPaidPlayer }" class="mr-2 ag_link player-type-section ml-auto" [matMenuTriggerFor]="menu" (click)="isUpdatePlayerPopover(player)">{{(player?.scheduledTeeTimePlayerFee?.greenFee + player?.scheduledTeeTimePlayerFee?.cartFee | Currency : true)}}</a>  
                    <a *ngIf="(!player?.isPaidPlayer && !player?.isAllowPay && player.playerStatus != 2)" class="mr-2 icon-gratuity" [matTooltip]="commonCaptions.teetime.Pay" [ngClass]="{'ag_section--disable': player?.isAllowPay}" (click)="payNow(player)"></a>
                    <a [attr.automationId]="'Btn_TeeSheetDetails_edit'" [matTooltip]="commonCaptions.edit" *ngIf="fromTeeSheet" class="icon-edit-new " (click)="editPlayer(player, Oi)"></a>
                  </div>
                  
                </div>
                <ng-template #block_content>
                  <span class="block-content">{{this.captions.lblBlocked}}</span>
                </ng-template>
                <div class="player-notifications"
                  *ngIf="customTableData?.isPlayerDetailIconsEnabled && !player.isBlocked">
                  <i [attr.automationId]="'Icn_TeeSheetDetails_checkIn'" tee-player-details class="icon-checkIn" *ngIf="player && player.isCheckedIn"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_checkOut'" class="icon-checkOut" *ngIf="player && player.isCheckedOut"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_dashboardflag'" class="icon-dashboard-flag" *ngIf="player && player.isFlagDay"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_adddeposit1'" class="icon-adddeposit1"
                    *ngIf="(((player && player.deposits && player.deposits.length) > 0)  && !player.isPaidPlayer) || (player.multiPackTransactionDetailId && player.multiPackTransactionDetailId != 0)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_rate'" *ngIf="player && player.isPaidPlayer"
                    [ngClass]="player.isMarkAsPaid?'icon-Mark-Paid':''"></i><!--icon-rate-->
                  <i [attr.automationId]="'Icn_TeeSheetDetails_Membernew'" class="icon-Member-new" *ngIf="player && (player.memberNumber != '')"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_tournamentplayer'" class="icon-tournament-player" *ngIf="player && (player.tournamentId > 0)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_sphere'" class="icon-sphere"
                    *ngIf="player && (player.bookingSource === bookingSource.sourcerGuestBook)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_GolfNow'" class="icon-GolfNow" *ngIf="player && (player.bookingSource === bookingSource.golfNow)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_bagnumber'" class="icon-bag-number" *ngIf="player && player.bagNumber">{{player?.bagNumber}}</i>
                  <!-- This condition is set to false, because we are not receiving any bagnumbers from the API-->
                  <i [attr.automationId]="'Icn_TeeSheetDetails_BulkTeeTime'" class="icon-Bulk-Tee-Time" *ngIf="player && (player.teeTimeFormat == teeTimeFormat.BulkTee)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_GroupPlayer'" class="icon-Group-Player" *ngIf="player && (player.teeTimeFormat == teeTimeFormat.GroupTee)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_noshow'" class="icon-no-show" *ngIf="player && (player && player.playerStatus == 2)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_caddynew'" class="icon-caddy_new" *ngIf="player && (player && player.caddyType != null)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_golfcart'" class="icon-golf-cart" *ngIf="player && (player && player.cartType?.length > 0)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_Guest'" class="icon-Hotel_Guest" *ngIf="player && (player && player.playerCategoryId == playerCategory.HotelReservation)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_Comments'" class="icon-Player_Comments" *ngIf="player && (player && player.playerComment != null && player.playerComment != '')"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_EForm'" class="icon-View-E-Form" *ngIf="isEformsEnabled && player && (player && player.eformCount && player.eformCount!= '0/0' )">{{player?.eformCount}}</i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_RetailItems'" class="icon-add_retail_item_24px" *ngIf="player && (player && player.isRetailItemsAvailable)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_RentalItems'" class="icon-Rentals" *ngIf="player && (player && player.isRentalItemsAvailable)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_VIP'" class="icon-vip" *ngIf="player && (player && player.vip != null && player.vip != '')"></i>
                </div>
                <a [attr.automationId]="'Btn_TeeSheetDetails_removeplayer'" *ngIf="!isHistoricDate && player.isRemovable" class="remove-player icon-Cancel" (click)="removePlayer(player)"></a>
              </div>
              <div *ngIf="customTableData.isDetailView" class="sublbl">{{player?.rateType.name}}</div>
              <div *ngIf="customTableData.isDetailView" class="sublbl">{{player?.confirmationNumber}}</div>
              <div *ngIf="customTableData.isDetailView && player && player.isPaidPlayer && player.ticketNumber"
                class="sublbl">{{this.captions.ticketNumber + ':'}}&nbsp;
                <span [style.color]="'green'">{{' ' + player.ticketNumber}}</span>
              </div>
              <div *ngIf="getIsOpenTransactionExist(customTableData.isDetailView,player)" class="sublbl">
                {{this.captions.UnPaid+':'}}&nbsp;<span [style.color]="'green'">{{' ' + player.ticketNumber}}</span>
              </div>
              <div *ngIf="customTableData.isDetailView" class="sublbl">{{player && (this.captions.greenFee + ': ' +
                (player.scheduledTeeTimePlayerFee.greenFee | Currency : true))}}</div>
              <div *ngIf="customTableData.isDetailView" class="sublbl">{{player && (this.captions.cartFeeTitle + ': ' +
                (player.scheduledTeeTimePlayerFee.cartFee | Currency : true))}}</div>
              <div *ngIf="customTableData.isDetailView" class="sublbl">{{(player && player.tournamentId > 0) ?
                (this.captions.entryFee + ': ' + (player.entryFee | Currency : true)) : ''}}</div>
              <div *ngIf="customTableData.isDetailView" class="sublbl">{{(player && player.cartType != null &&
                player.cartType != "" && player.cartType != "0") ? (this.captions.cartType + ': ' + (player.cartType)) :
                ''}}</div>
              <div *ngIf="customTableData.isDetailView" class="sublbl">{{(player && player.cartId != "" && player.cartId
                != null && player.cartId != "0") ? (this.captions.cartNumber + ': ' + (player.cartId)) : ''}}</div>
              <div *ngIf="customTableData.isDetailView" class="sublbl">{{(player && player.caddyType != null &&
                player.caddyType != "" && player.caddyType != "0") ? (this.captions.caddyType + ': ' +
                (player.caddyType)) : ''}}</div>
              <div *ngIf="customTableData.isDetailView" class="sublbl">{{(player && player.caddy != "" && player.caddy
                != null && player.caddy != "0") ? (this.captions.caddy + ': ' + (player.caddy)) : ''}}</div>
              <div
                *ngIf="customTableData.isDetailView && player && player.playerStayDetail && (player.teeTimeFormat == teeTimeFormat.GroupTee)"
                class="sublbl">{{player.playerStayDetail.groupBookingName != null ?
                player.playerStayDetail.groupBookingName :''}}</div>
            </span>
          </div>
        </ng-template>
        <div *ngIf="customTableData.isDetailView">
          <div *ngIf="!player.isBlocked" class="hint-container" [ngClass]="{'multi-course-width': isMultiCourse}">
            <!-- <div class="player-group" [ngStyle]="{'background-color': player.playGroup?.colorCode}"></div> -->
          <span class="hint-section" [ngStyle]="{'background-color': player.playGroup?.colorCode}"
            [ngClass]="{'' : player.gender == gender.none,'icon-male-staff' : player.gender == gender.male, 'icon-female-staff' : player.gender == gender.female, 'icon-kids-couple' : player.gender == gender.junior, 'multi-course-width': isMultiCourse}"></span>
          </div>
          <span class="description-section" [ngClass]="{'multi-course-width': isMultiCourse}"   [ngStyle]="{'background-color': getColorcode(player)}">
            <div class="lbl font-bold">{{captions.comments}}</div>
            <div class="sublbl breakWord">{{player && player.playerComment ? player.playerComment: '-'}}</div>
            <!-- <div class="player-edit-section">
            <a class="icon-edit-new" (click)="editPlayer(player)"></a>
          </div> -->
          </span>
        </div>
        <ng-container *ngIf="player && player.isPaidPlayer"><div class="paidsec"></div><span class="paidsectext">{{captions.Paid}}</span></ng-container>
        <div *ngIf="!isHistoricDate && player.playerStatus != 2 && customTableData.isDeletePlayerAllowed" class="player-delete-section">
          <a [attr.automationId]="'Btn_TeeSheetDetails_deletePlayer'" class="remove-player icon-Cancel whitecolor" (click)="deletePlayer(player, PlayerCollection)"></a>
        </div>
      </div>
    </ng-container>
    <!-- advance view Start-->
    <ng-container *ngIf="customTableData.isadvDetailView">
      <section *ngIf="PlayerCollection && PlayerCollection.comments">
        <div><span class="TT_comment" [matTooltip]="PlayerCollection.comments">{{PlayerCollection && PlayerCollection.comments ?
          PlayerCollection.comments : ''}}</span></div>
      </section>
      <div *ngFor="let player of PlayerCollection.playerDetail;let Oi= index" class="TS_detailedView"
        [ngClass]="{'teegrid-shorthand-view' : customTableData.isMultiView && !customTableData.isadvDetailView && !customTableData.isDetailView, 'cursor-move' : !customTableData.isDragDisabled}"
        cdkDrag [cdkDragDisabled]="!player.isBlocked ? player.isDragDisabled : true"
        cdkDragBoundary="customTableData.dragBoundary" [popover]="infoPopover"
        [popoverOnHover]="(!customTableData.isadvDetailView && !customTableData.isTooltipDisabled)? true:false"
        (mouseover)="mouseOver($event,player)" (onShown)="onShown($event)"
        [popoverDisabled]="((customTableData.isadvDetailView) || (customTableData.isTooltipDisabled))" #popOverTarget
        (cdkDragStarted)="onDragStart($event, player, PlayerCollection)" (cdkDragMoved)="onTeeGridDragScroll($event)"
        style="pointer-events: all !important;">
        <div class="position-relative">
          <div class="DV_row1">
            <div>
              <span class="DVR_Lsec1 d-inline-block" [ngStyle]="{'background-color': player.playGroup?.colorCode}"> <i
                class="icon-vip1"  *ngIf="player.vip"></i> {{getLocalizedPlayerPosition(player.playGroup?.listOrder)}}</span>
              <span class="DVR_Lsec2 d-inline-block" [matTooltip]="getplayerName(player)">
                <span *ngIf="player && player.gender==1" class="icon-male-staff d-inline-block"></span>
                <span *ngIf="player && player.gender==2" class="icon-female-staff d-inline-block"></span>
                {{getplayerName(player)}}</span>
            </div>
            <div class="DVR_Lsec3">
              <span class="icon-Days-in-Advance" [matTooltip]="captions.lbl_daysInAdvance"></span>
              <span class="ag_font--bold ag_ml--2">{{player.daysInAdvanceCount > 0 ? player.daysInAdvanceCount : 0}}</span>
            </div>
            <div  class="player-delete" *ngIf="!isHistoricDate">
              <a [attr.automationId]="'Btn_TeeSheetDetails_deletePlayer'" class="remove-player icon-Cancel" (click)="deletePlayer(player, PlayerCollection)"></a>
            </div>

            <div class="DVR_Rsec player-notifications"
              *ngIf="customTableData?.isPlayerDetailIconsEnabled && !player.isBlocked">
              <i [attr.automationId]="'Icn_TeeSheetDetails_checkIn'" class="icon-checkIn" *ngIf="player && player.isCheckedIn"></i>
              <i [attr.automationId]="'Icn_TeeSheetDetails_checkOut'" class="icon-checkOut" *ngIf="player && player.isCheckedOut"></i>
              <i [attr.automationId]="'Icn_TeeSheetDetails_dashboardflag'" class="icon-dashboard-flag" *ngIf="player && player.isFlagDay"></i>
              <i [attr.automationId]="'Icn_TeeSheetDetails_adddeposit1'" class="icon-adddeposit1"
                *ngIf="(((player && player.deposits && player.deposits.length) > 0)  && !player.isPaidPlayer) || (player.multiPackTransactionDetailId && player.multiPackTransactionDetailId != 0)"></i>
              <i [attr.automationId]="'Icn_TeeSheetDetails_rate'" *ngIf="player && player.isPaidPlayer" [ngClass]="player.isMarkAsPaid?'icon-Mark-Paid':''"></i><!--icon-rate-->
              <i [attr.automationId]="'Icn_TeeSheetDetails_Membernew'" class="icon-Member-new" *ngIf="player && (player.memberNumber != '')"></i>
              <i [attr.automationId]="'Icn_TeeSheetDetails_tournamentplayer'"class="icon-tournament-player" *ngIf="player && (player.tournamentId > 0)"></i>
              <i [attr.automationId]="'Icn_TeeSheetDetails_sphere'" class="icon-sphere" *ngIf="player && (player.bookingSource === bookingSource.sourcerGuestBook)"></i>
              <i [attr.automationId]="'Icn_TeeSheetDetails_GolfNow'"class="icon-GolfNow" *ngIf="player && (player.bookingSource === bookingSource.golfNow)"></i>
              <i [attr.automationId]="'Icn_TeeSheetDetails_bagnumber'" class="icon-bag-number" *ngIf="player && player.bagNumber">{{player?.bagNumber}}</i>
              <!-- This condition is set to false, because we are not receiving any bagnumbers from the API -->
              <i [attr.automationId]="'Icn_TeeSheetDetails_BulkTeeTime'"  class="icon-Bulk-Tee-Time" *ngIf="player && (player.teeTimeFormat == teeTimeFormat.BulkTee)"></i>
              <i [attr.automationId]="'Icn_TeeSheetDetails_GroupPlayer'" class="icon-Group-Player" *ngIf="player && (player.teeTimeFormat == teeTimeFormat.GroupTee)"></i>
              <i [attr.automationId]="'Icn_TeeSheetDetails_noshow'" class="icon-no-show" *ngIf="player && (player && player.playerStatus == 2)"></i>
              <i [attr.automationId]="'Icn_TeeSheetDetails_caddynew'" class="icon-caddy_new" *ngIf="player && (player && player.caddyType != null)"></i>
              <i [attr.automationId]="'Icn_TeeSheetDetails_golfcart'" class="icon-golf-cart" *ngIf="player && (player && player.cartType?.length > 0)"></i>
              <i [attr.automationId]="'Icn_TeeSheetDetails_Guest'" class="icon-Hotel_Guest" *ngIf="player && (player && player.playerCategoryId == playerCategory.HotelReservation)"></i>
              <i [attr.automationId]="'Icn_TeeSheetDetails_Comments'" class="icon-Player_Comments" *ngIf="player && (player && player.playerComment != null && player.playerComment != '')"></i>
              <i [attr.automationId]="'Icn_TeeSheetDetails_EForm'"  class="icon-View-E-Form" *ngIf="isEformsEnabled && player && (player && player.eformCount && player.eformCount != '0/0')">{{player?.eformCount}}</i>
            </div>
          </div>
          <ng-container *ngIf="player && player.isPaidPlayer"><div class="paidsec"></div><span class="paidsectextADTView">{{captions.Paid}}</span></ng-container>
          <div class="DV_mainContent description-section">
            <div class="F_sec">
              <div class="sec-wid">
                <span class="F_sec1 elipsis" [matTooltip]="player.confirmationNumber">{{player && player.confirmationNumber ? player.confirmationNumber : ''}}</span>
                <span *ngIf="player && player.ticketNumber" class="F_sec2 ticketElip" [matTooltip]="player.ticketNumber">{{player.ticketNumber}}</span>
                <span *ngIf="player && (player.patronId)" class="F_sec2 elipsis" [matTooltip]="player.patronId">
                 <span class="icon-Player-worth_enabled"><span class="path1"></span><span class="path2"></span></span>{{player.patronId}}</span>
              </div>
             
              
              <div class="ag_ml--auto ag_display--flex">
                <span [attr.automationId]="'Btn_TeeSheetDetails_editPlayer'" *ngIf="player && player.playerComment" style="line-height: 1.15;" class="F_sec3" [matTooltip]="player.playerComment">{{player && player.playerComment ? player.playerComment: ''}}</span>
                <a *ngIf="(player?.isAllowPlayerTypeRateTypeChange && !player?.isPaidPlayer && player.playerStatus != 2)" style="line-height: 1.15;" [ngClass]="{'ag_section--disable': player?.isDisablePlayerTypeRateTypeChange || player?.isPaidPlayer }" class="mr-2 ag_link player-type-section ml-auto" [matMenuTriggerFor]="menu" (click)="isUpdatePlayerPopover(player)">{{(player?.scheduledTeeTimePlayerFee?.greenFee + player?.scheduledTeeTimePlayerFee?.cartFee | Currency : true)}}</a>  
                <a *ngIf="(!player?.isPaidPlayer && !player?.isAllowPay && player.playerStatus != 2)" class="icon-gratuity ag_mr--2" [matTooltip]="commonCaptions.teetime.Pay"  [ngClass]="{'ag_section--disable': player?.isAllowPay}" (click)="payNow(player)"></a>
                <a *ngIf="fromTeeSheet" class="icon-edit-new" [matTooltip]="commonCaptions.edit" (click)="editPlayer(player, Oi)"></a>  
              </div>
             
            </div>
            <div class="M_sec">
              <div class="M_sec1 Msec_cnt">
                <div class="M_sec_icon"><span class="icon-ratetype"></span></div>
                <div class="w-100">
                  <section class="w-100" *ngIf="player && (player.playerType || player.rateType)">
                    <span class="M_sec_info d-block primHDr" matTooltip="{{(player && player.playerType ? player.playerType.name :
                      '')+' | '+(player && player.rateType ? player.rateType.name : '')}}">{{(player && player.playerType ? player.playerType.name :
                      '')+' | '+(player && player.rateType ? player.rateType.name : '')}}</span>
                  </section>
                  <section *ngIf="player && player.memberNumber">
                    <span class="M_sec_info d-block secHdr" [matTooltip]="player.memberNumber">{{player.memberNumber}}</span>
                  </section>
                </div>
              </div>
              <div class="M_sec2 Msec_cnt" *ngIf="player && (player.caddy || player.caddyType)">
                <section class="w-100">
                <div class="M_sec_icon"><span class="icon-caddy_new"></span></div>
                <div class="w-100">
                  <span class="M_sec_info d-block primHDr" [matTooltip]="player.caddy">{{player && player.caddy ? player.caddy: ''}}</span>
                  <span class="M_sec_info d-block secHdr" [matTooltip]="player.caddyType">{{player && player.caddyType ? player.caddyType: ''}}</span>
                </div>
              </section>
              </div>
              <div class="M_sec3 Msec_cnt" *ngIf="player && (player.caddyType != null || player.cartType?.length > 0)">
                <section class="w-100">
                  <div class="M_sec_icon"><span class="icon-golf-cart"></span></div>
                  <div class="w-100">
                    <span class="M_sec_info d-block primHDr" [matTooltip]="player.cartId">{{player && player.cartId ? player.cartId : ''}}</span>
                    <span class="M_sec_info d-block secHdr" [matTooltip]="player.cartType">{{player && player.cartType ? player.cartType : ''}}</span>
                  </div>
                </section>
              </div>
              <div class="M_sec4 Msec_cnt">
                <div class="M_sec_icon"><span class="icon-paid1"></span></div>
                <div class="w-100">
                  <span class="M_sec_info d-block primHDr" matTooltip="{{player && (this.captions.greenFee + ': ' +
                  (player.scheduledTeeTimePlayerFee.greenFee | Currency : true))}}">{{player && (this.captions.greenFee + ': ' +
                    (player.scheduledTeeTimePlayerFee.greenFee | Currency : true))}}</span>
                  <span class="M_sec_info d-block secHdr" matTooltip="{{player && (this.captions.cartFeeTitle + ': ' +
                  (player.scheduledTeeTimePlayerFee.cartFee | Currency : true))}}">{{player && (this.captions.cartFeeTitle + ': ' +
                    (player.scheduledTeeTimePlayerFee.cartFee | Currency : true))}}</span>
                </div>
              </div>
              <div class="M_sec5 Msec_cnt" *ngIf="isEformsEnabled && player && (player && player.eformCount && player.eformCount != '0/0')">
                <div class="M_sec_icon"><span class="icon-View-E-Form"></span></div>
                <div>
                  <span class="M_sec_info d-block primHDr" matTooltip="{{player && (this.captions.eformCount + ': ' +
                  (player.eformCount))}}">{{player && (this.captions.eformCount + ': ' +
                    (player.eformCount))}}</span>
                </div>
              </div>
            </div>
            <div class="L_sec">
              <section class="Lsec_cnt" *ngIf="player && ((player.playerStayDetail && player.playerStayDetail.reservationNumber != null)
              || (player.roomNumber!='' && player.roomNumber!= null)) && isHoteReserv">
                <div class="L_sec1">
                  <div class="primHDr">
                    <span *ngIf="player && player.playerStayDetail && player.playerStayDetail.reservationNumber != null" [matTooltip]="player.playerStayDetail.reservationNumber">{{player.playerStayDetail.reservationNumber}}</span>
                    <span *ngIf="player && player.roomNumber" [matTooltip]="player.roomNumber">{{player.roomNumber}}</span>
                  </div>
                  <div *ngIf="player && player.arrivalDate && player.departureDate" class="secHdr">{{(LocalizeDate(player.arrivalDate)  +' - '+ LocalizeDate(player.departureDate) )}}</div>
                  <div *ngIf="player && player.packageCode != null">{{player?.packageCode}}</div>
                </div>
              </section>
              <section class="Lsec_cnt customFldSec" *ngIf="player && (player.customField1|| player.customField2  || player.customField3 || player.customField4 || player.customField5) && isCustomFiels">
                <div class="L_sec2">
                  <div [matTooltip]="player.customField1" *ngIf="player && player.customField1" class="secHdr">{{player.customField1}}</div>
                  <div [matTooltip]="player.customField2" *ngIf="player && player.customField2" class="secHdr">{{player.customField2}}</div>
                  <div [matTooltip]="player.customField3" *ngIf="player && player.customField3" class="secHdr">{{player.customField3}}</div>
                  <div [matTooltip]="player.customField4" *ngIf="player && player.customField4" class="secHdr">{{player.customField4}}</div>
                  <div [matTooltip]="player.customField5" *ngIf="player && player.customField5" class="secHdr">{{player.customField5}}</div>
                </div>
             </section>
             <section class="Lsec_cnt rentalItems" *ngIf="player && (player.isRentalItemsAvailable || player.isRetailItemsAvailable)">
              <div class="l_sec3">
                <div class="ag_link" (click)="getRetailOrRentalItems(player)" [matMenuTriggerFor]="rentalMatMenu">{{isRentalEnabled ? commonCaptions.lbl_viewRentalorRetail : commonCaptions.lbl_viewRetail}}</div>
                <mat-menu #rentalMatMenu="matMenu" class="update-Player"  [hasBackdrop]="true">
                  <div class="ag_p--4" >
                      <div class="align-items-center ag_display--flex">
                            <h6 class="ag_font--bold">{{isRentalEnabled ? commonCaptions.lbl_viewRentalorRetailItems : commonCaptions.lbl_viewRetail}}</h6>
                            <h6 class="icon-close ag_cursor--pointer ml-auto LWB11 pl-4"></h6>
                      </div>
                      <div class="ag_display--flex">
                        <div class="ag_pr--7 rentals-sec">
                          <div class="ag_font--bold">{{commonCaptions.lbl_retail}}</div>
                          <div *ngFor="let item of retailItems">{{item.itemDescription}} {{item?.quantity}}</div>
                        </div>
                        <div class="rentals-sec" *ngIf="isRentalEnabled">
                          <div class="ag_font--bold">{{captions.rentals}}</div>
                          <div *ngFor="let item of rentalItems">{{item.itemDescription}} {{item?.quantity}}</div>
                        </div>
                      </div>
                  </div>
              </mat-menu>
              </div>
             </section>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="caption"
    *ngIf="(PlayerCollection['playerDetail'].length <= 0) && !(restrictedSlots.indexOf(PlayerCollection.status.scheduleStatus) > -1)">
    <span
      [ngClass]="(PlayerCollection.type != 5 && (PlayerCollection.allocation?.closed || PlayerCollection.allocation?.displayOnTeesheet)) ? 'align-to-middle' : '' ">{{getTeeSlotText(PlayerCollection)}}</span>
  </div>
</section>
<popover-content #infoPopover placement="bottom" [animation]="true" [closeOnClickOutside]="true" class="infoPopover"
  container=".player-card-details">
  <app-tee-slot-info [info]="currentSlotInfo" [customTableData]="customTableData"></app-tee-slot-info>
</popover-content>
<mat-menu #rentalMatMenu="matMenu" class="update-Player" [hasBackdrop]="true">
  <div class="ag_p--4" >
      <div class="align-items-center ag_display--flex">
            <h6 class="ag_font--bold">{{commonCaptions.lbl_viewRentalorRetailItems}}</h6>
            <h6 class="icon-close ag_cursor--pointer float-right ml-4"></h6>
      </div>
      <div class="ag_display--flex">
        <div class="ag_pr--7 rentals-sec">
          <div class="ag_font--bold">{{captions.rentals}}</div>
          <div *ngFor="let item of rentalItems">{{item.name}}</div>
        </div>
        <div class="rentals-sec">
          <div class="ag_font--bold">{{commonCaptions.lbl_retail}}</div>
          <div *ngFor="let item of retailItems">{{item.name}}</div>
        </div>
      </div>
  </div>
</mat-menu>
<ng-container>
  <mat-menu #menu="matMenu" class="update-Player" [hasBackdrop]="true" [backdropClass]="'disable-backdrop'">
    <ng-template matMenuContent>
      <app-update-player-details (isPayFormDirty)="payForm($event)" [rowData]="palyerInfo" [from]="'fromTeeGrid'" (onActionEmit)="onAction($event)"></app-update-player-details>
    </ng-template>
  </mat-menu>
</ng-container>

