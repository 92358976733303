import { Injectable } from '@angular/core';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';
import { TeeTimeDetail, TeeSheet } from '../../models/teesheet.api.models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { Subject } from 'rxjs';
import { memberPlayerData, TeetimeMemberDetail } from 'src/app/tee-time-actions/redeem-round/redeem-model';

@Injectable(
    { providedIn: 'root' }
)
export class TeeSheetsDataService {


    constructor(private _golfScheduleCommunication: GolfScheduleCommunication, private localization: GolfLocalization) {
    }

    /**
     * Gets Tee Sheet Layout Slots information as Slots array.
     * @param {number} courseId
     * @param {Date} date
     * @returns {Promise<TeeSheetSlot[]>}
     * @memberof TeeSheetsDataService
     */
    public async getTeeSlots(courseId: number, date: Date): Promise<TeeSheet> {
        const _date: string = this.localization.convertDateObjToAPIdate(date);

        return this._golfScheduleCommunication.getPromise<TeeSheet>({
            route: GolfApiRoute.GetTeeSlots,
            uriParams: { date: _date, courseId: courseId }
        }, false);
        
    }

    /**
    * Gets Tee Sheet Layout Slots information as Slots array.
    * @param {number} courseId
    * @param {Date} date
    * @returns {Promise<TeeSheetSlot[]>}
    * @memberof TeeSheetsDataService
    */
    public async getTeeSlotsCancellable(courseId: number, date: Date, notifier: Subject<void>): Promise<TeeSheet> {
        const _date: string = this.localization.convertDateObjToAPIdate(date);

        return this._golfScheduleCommunication.getPromiseCancellable<TeeSheet>({
            route: GolfApiRoute.GetTeeSlots,
            uriParams: { date: _date, courseId: courseId }
        }, false, notifier);       
    }

    /**
     * Get Tee Time Details with Player Info   
     * @param {number} courseId
     * @param {Date} date
     * @returns {Promise<TeeTime[]>}
     * @memberof TeeSheetsDataService
     */
    public getTeeTimesByDate(date: Date): Promise<TeeTimeDetail[]> {
        const _date: string = this.localization.convertDateObjToAPIdate(date);
        return this._golfScheduleCommunication.getPromise<TeeTimeDetail[]>({
            route: GolfApiRoute.GetTeeTimesByDate,
            uriParams: { date: _date }
        });      
    }


    /**
     * Get Tee Time Details with Player Info   
     * @param {number} courseId
     * @param {Date} date
     * @returns {Promise<TeeTimeDetail[]>}
     * @memberof TeeSheetsDataService
     */
    public getTeeTimes(courseId: number, date: Date, isAdvancedView: boolean = false): Promise<TeeTimeDetail[]> {
        const _date: string = this.localization.convertDateObjToAPIdate(date);
        return this._golfScheduleCommunication.getPromise<TeeTimeDetail[]>({
            route: GolfApiRoute.GetTeeTimes,
            uriParams: { date: _date, courseId: courseId , isAdvancedView: isAdvancedView}
        });       
    }

    /**
     * Get Tee Time Details with Player Info   
     * @param {number} courseId
     * @param {Date} date
     * @returns {Promise<TeeTime[]>}
     * @memberof TeeSheetsDataService
     */
    public getTeeTimesCancellable(courseId: number, date: Date, notifier: Subject<void>, isAdvancedView: boolean = false): Promise<TeeTimeDetail[]> {
        const _date: string = this.localization.convertDateObjToAPIdate(date);
        return this._golfScheduleCommunication.getPromiseCancellable<TeeTimeDetail[]>({
            route: GolfApiRoute.GetTeeTimes,
            uriParams: { date: _date, courseId: courseId, isAdvancedView: isAdvancedView }
        }, true, notifier);      
    }


    public iscaddyAssignedToTeeTime(caddyId: number, date: Date | string): Promise<boolean> {
        const _date: string = this.localization.convertDateObjToAPIdate(date);
        return this._golfScheduleCommunication.getPromise<boolean>({
            route: GolfApiRoute.GetCaddyStatus,
            uriParams: { caddyId: caddyId, scheduledDate: _date }
        });       
    }

    /**
     * Get Tee Time Details with Player Info   
     * @param {number} courseId
     * @param {Date} date
     * @returns {Promise<TeeTime[]>}
     * @memberof TeeSheetsDataService
     */
     public getAllMemberTeeTimesOnDate(courseId: number, date: Date): Promise<TeetimeMemberDetail[]> {
        const _date: string = this.localization.convertDateObjToAPIdate(date);
        return this._golfScheduleCommunication.getPromise<TeetimeMemberDetail[]>({
            route: GolfApiRoute.GetAllMemberTeeTimesOnDate,
            uriParams: { date: _date, courseId: courseId }
        });       
    }
    public GetCancellationNoShowLedger(date: Date) {
        const _date: string = this.localization.convertDateObjToAPIdate(date);
        return this._golfScheduleCommunication.getPromise<any>({
            route: GolfApiRoute.GetCancellationNoShowLedger,
            uriParams: { date: _date }
        });      
    }
    public UpdateCancellationNoShowLedger(cancellationNoShowLedger: any) {
        return this._golfScheduleCommunication.putPromise<any>({
            route: GolfApiRoute.UpdateCancellationNoShowLedger,
            body: cancellationNoShowLedger
        });      
    }
    /**
     * Get Tee Time Details with Player Info   
     * @param {number} courseId
     * @param {Date} date
     * @returns {Promise<TeeTime[]>}
     * @memberof TeeSheetsDataService
     */
     public getTeeTimesSlotsByDate(date: Date, courseId: number): Promise<TeeTimeDetail[]> {
        const _date: string = this.localization.convertDateObjToAPIdate(date);
        return this._golfScheduleCommunication.getPromise<TeeTimeDetail[]>({
            route: GolfApiRoute.GetTeeTimesSlotByDate,
            uriParams: { date: _date, courseId: courseId }
        });      
    }
}
