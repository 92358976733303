import { Injectable } from '@angular/core';
import { GolfManagementCommunication } from '../../communication/services/golfmanagement.service';
import { API, AllocationBlockWithPlayerType } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import { GolfUtilities } from '../../utilities/golf-utilities';
import { allocationBlockPlayerTypePermission } from 'src/app/settings/user-setup/booking-permission/booking-permission.model';

@Injectable({providedIn: 'root'})
export class AllocationBlockDataService {

    constructor(private _http: GolfManagementCommunication, private utilitites : GolfUtilities) {

    }

    async updateDefaultTeeAllocationBlocks(_allocationBlockId: number): Promise<boolean> {
        return await this._http.getPromise<boolean>({
            route: GolfApiRoute.UpdateDefaultTeeTimeAllocationBlocks,
            uriParams: {"allocationBlockId": _allocationBlockId}
        });
    }

    async createAllocationBlock(_allocationBlock: API.TeeTimeAllocationBlock): Promise<API.TeeTimeAllocationBlock> {
        return await this._http.postPromise<API.TeeTimeAllocationBlock>({
            route: GolfApiRoute.CreateAllocationBlock,
            body: _allocationBlock
        }, false);
    }

    async getAllocationBlocks(_includeInactive: boolean): Promise<API.TeeTimeAllocationBlock[]> {
        return await this._http.getPromise<API.TeeTimeAllocationBlock[]>({
            route: GolfApiRoute.GetAllAllocationBlocks,
            uriParams: { includeInactive: _includeInactive + '' }
        });        
     
    }

    async getAllocationBlockById(_id: number): Promise<API.TeeTimeAllocationBlock> {
        return await this._http.getPromise<API.TeeTimeAllocationBlock>({
            route: GolfApiRoute.GetAllocationBlock,
            uriParams: { id: _id }
        });
    }

    async updateAllocationBlock(_allocationBlock: API.TeeTimeAllocationBlock) {
        return await this._http.putPromise<API.TeeTimeAllocationBlock>({
            route: GolfApiRoute.UpdateAllocationBlock,
            body: _allocationBlock
        }, false);
    }

    async deleteAllocationBlock(_id: number): Promise<void> {
        await this._http.deletePromise<void>({
            route: GolfApiRoute.DeleteAllocationBlock,
            uriParams: { id: _id }
        });
    }

    async getAllocationsBlockWithPlayerTypes(): Promise<AllocationBlockWithPlayerType[]> {
        return await this._http.getPromise<AllocationBlockWithPlayerType[]>({
            route: GolfApiRoute.GetAllocationBlockPlayerTypes
        });
    }


    async getMaxListOrder(): Promise<number> {
        return await this._http.getPromise<number>({
            route: GolfApiRoute.GetMaxListOrder
        });
    }

    async getAllocationBlockPermissionByRole(roleId?: string): Promise<allocationBlockPlayerTypePermission[]> {        
        return await this._http.getPromise<allocationBlockPlayerTypePermission[]>({
            route: GolfApiRoute.GetAllocationBlockPermissionByRole,
            uriParams : {roleId : roleId ? roleId : this.utilitites.GetUserInfo('roleId')}
        });        
    }
    
    async GetAllocationBlockPlayerTypePermissions():Promise<allocationBlockPlayerTypePermission[]> {
        return await this._http.getPromise<allocationBlockPlayerTypePermission[]>({
            route: GolfApiRoute.GetAllocationBlockPlayerTypePermissions
        });
    }

    async CreateAllocationBlockPlayerTypePermissions(allocationBlockPlayerTypePermission):Promise<allocationBlockPlayerTypePermission[]> {
        return await this._http.postPromise<allocationBlockPlayerTypePermission[]>({
            route: GolfApiRoute.CreateAllocationBlockPlayerTypePermissions,
            body: allocationBlockPlayerTypePermission
        });
    }

    async DeleteAllocationBlockPlayerTypePermissions(allocationBlockPlayerTypePermission):Promise<allocationBlockPlayerTypePermission[]> {
        return await this._http.deletePromise<allocationBlockPlayerTypePermission[]>({
            route: GolfApiRoute.DeleteAllocationBlockPlayerTypePermissions,
            body: allocationBlockPlayerTypePermission
        });
    }
}
