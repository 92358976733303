
import { Injectable } from '@angular/core';
import { TeeSheetBase } from '../tee-sheet/common/tee-sheet.base';
import { TeeSheetsDataService } from 'src/app/shared/data-services/golfschedule/teesheets.data.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { AllocationBlockDataService } from 'src/app/shared/data-services/golfschedule/allocationblock.data.service';
import { CourseDataService } from 'src/app/shared/data-services/golfschedule/course.data.service';
import { TeeSheetSkeletonData, ScheduleStatus } from 'src/app/shared/models/teesheet.form.models';
import _ from 'lodash';
import { RateTypeDataService } from 'src/app/shared/data-services/golfschedule/ratetype.data.service';
import { Guid } from 'guid-typescript';
import { PlayerTypeService } from 'src/app/shared/data-services/golfschedule/playertype.data.service';
@Injectable()
export class TeeSheetBulk extends TeeSheetBase {

    constructor(public _teeSheetsDataService: TeeSheetsDataService, public _localization: GolfLocalization, public _utilities: GolfUtilities, public _allocationBlockDataService: AllocationBlockDataService, public _courseDataService: CourseDataService, public _playerTypeDataService: PlayerTypeService, public _rateTypeDataService: RateTypeDataService) {
        super(_teeSheetsDataService, _localization, _utilities, _allocationBlockDataService, _courseDataService, _playerTypeDataService, _rateTypeDataService);

    }

    public createSlot(sourceSlot : TeeSheetSkeletonData, time : Date) : TeeSheetSkeletonData{
        let newSlot = _.cloneDeep(sourceSlot);  
        newSlot.time = this._localization.convertDateTimeToAPIDateTime(time);
        newSlot.id = Guid.create().toString();
        newSlot.status.scheduleStatus = ScheduleStatus.open;
        newSlot.scheduledTeeTimeId = 0;
        newSlot.playerDetail = [];
        newSlot.originalHoleNumber = sourceSlot.hole;
        return newSlot;
    }

}