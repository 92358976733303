<section class="quick-login">
    <form [formGroup]="quickLoginForm">
        <div class="">
            <mat-radio-group  class="radio-class golf__radio" formControlName="quicklogintype">
                <mat-radio-button value="quickid" [checked]="!isHideQuickId" *ngIf = "!isHideQuickId" class="golf--form-radio-button">{{this.captions.quickId}}</mat-radio-button>
                <mat-radio-button value="userid"  [checked]="isHideQuickId"  *ngIf = "!isHideQuickId" class="golf--form-radio-button">{{this.captions.userid}}</mat-radio-button>
              </mat-radio-group>
        </div>

        <div [ngClass]="quickLoginForm.controls.quicklogintype.value == 'quickid' ? 'display-block' : 'display-none'">
                <mat-form-field  [floatLabel]="floatLabel" class="w-50">
    
                    <input #quickIdFocus matInput  [type]="hideQuickId ? 'text' : 'password'" class="text-uppercase"
                    [placeholder]="captions.quickId" formControlName="quickId" [maxlength]="6" 
                    (keydown.enter)="saveOnEnterKeyDown('login')"
                    (keydown.esc)="closeOnEscapekeyDown()"
                    inputtype="nonnegative,nodecimal" cdkFocusInitial>
                <i  aria-hidden="true" matSuffix class="IC18"
                    [ngClass]="hideQuickId ? 'icon-password-show' : 'icon-password-hide '"
                     (click)="hideQuickId=!hideQuickId" ></i>
                    <mat-error *ngIf="quickLoginForm.get('quickId').hasError('required')">
                        {{errorMessage.quickId}}
                    </mat-error>
                      
                   
                </mat-form-field>
                <div >
                       
                    <span *ngIf="quickIDError"
                        class="errorText">{{quickIDErrorTxt || loginErrorMessage}}</span>
                </div>
                <div>
                    <span *ngIf="quickIDBreakPointError" class="errorText">{{this.breakPointErrorText}}</span>            
                </div>
            </div>
        <div [ngClass]="quickLoginForm.controls.quicklogintype.value == 'userid'  ? 'display-block': 'display-none'">
        <div>
            <mat-form-field [floatLabel]="floatLabel" class="w-50">

                <input #userIdFocus matInput type="text" [placeholder]="captions.userid"
                    class="text-uppercase" inputtype="capitalise,notallowspace"
                    (keydown.enter)="saveOnEnterKeyDown('login')"
                    (keydown.esc)="closeOnEscapekeyDown()"
                    formControlName="userId" cdkFocusInitial>
                <mat-error *ngIf="quickLoginForm.get('userId').hasError('required')">
                    {{errorMessage.userId}}
                </mat-error>
            </mat-form-field>
        </div>
        <div> 
            <mat-form-field [floatLabel]="floatLabel" class="w-50">

                <input matInput [type]="hidePassword ? 'text' : 'password'"
                    [placeholder]="captions.password" formControlName="password"
                    (keydown.enter)="saveOnEnterKeyDown('login')"
                    (keydown.esc)="closeOnEscapekeyDown()"
                    autocomplete="new-password">
                <i  aria-hidden="true" matSuffix class="IC18"
                    [ngClass]="hidePassword ? 'icon-password-show' : 'icon-password-hide '"
                     (click)="hidePassword=!hidePassword"  ></i>
                <mat-error *ngIf="quickLoginForm.get('password').hasError('required')">
                    {{errorMessage.password}}
                </mat-error>
            </mat-form-field>
        </div>
        <div >
                       
            <span *ngIf="userIDError"
                class="errorText">{{userIDErrorTxt || userIdLoginErrorMessage}}</span>
        </div>
        <div>
            <span *ngIf="userIDBreakPointError" class="errorText">{{this.breakPointErrorText}}</span>            
        </div>
        </div>
    </form>
</section>