export interface Captions {
    playerType?: string;
    rateType?: string;
    price?: string;
    allocationCode?: string;
    lessonLocation?: string;
    bookLessonOnTeeSheet?: string;
    yes?: string;
    no?: string;
    firstName?: string;
    lastName?: string;
    pronounced?: string;
    searchText?: string;
    hotelReservationPlaceholder?: string;
    memberPlaceholder?: string;
    playerPlaceholder?: string;
    searchByConfNoAndGuestName?:string;
    searchByPlayerName?:string;
    availableRounds : string;
    MembershipExpiryDate : string;
    MembershipRenewalDate : string;
    MembershipType : string;
    MembershipStatus : string;
    startDate: string;
    endDate: string;
}

export interface ErrorCaptions {
    firstNameError?: string;
    lastNameError?: string;
    playerTypeError?: string;
    rateTypeError?: string;
    allocationCodeError?: string;
    startDateError?: string;
    endDateError?: string;
    invalidDateError?: string;
    allocationMappingMissing?: string; 
}

export interface AllocationCodes {
    id: number;
    Description: string;
}
export interface LessonLocation {
    id: number;
    Description: string;
}
export interface PlayerTypeList {
    id: number;
    value: string;
    viewValue: string;
    checked: boolean;
}

export enum playerCategory{
    newPlayer = 1,
    hotelReservation=2,
    member=3,
    player=4,
    guest = 5
}

export enum guestType{    
    guest = 2,
    member = 3
}


