<section class="golf-container--padding" id="clearPlayers" #infoElement>
  <div class="message-notification">
    <app-note [automationId]="automationId"  [noteMessage]="notifierBar" *ngIf="notifierBar && notifierBar.value.length>0 && !allowCancellationOfGolfNowBooking && isGolfNowEnabled">
    </app-note>
  </div>
  <div class="player-list--wrapper" *ngIf="mixedBulkPlayerGroup && !isPlayerSelected">
    <label class="golf-form--label">{{playerCaptions}}</label>
    <section class="players-list">
      <div attr.automationId='Btn_{{automationId}}_selectedPlayer{{i}}' class="players-list--item" *ngFor="let player of playerList;let i=index"
        [ngClass]="selectedPlayer && selectedPlayer.playerId == player.playerId ? 'clicked': ''" (click)="CanChangeBulkView(player);">
        <ag-img-golfthumbnail [imageContent]="player.profileImage" [thumbnail]="true" imageType="client">
        </ag-img-golfthumbnail>
        <label class="player-name" matTooltip="{{player | formatName: 'firstName': 'lastName' }}">{{player | formatName: 'firstName': 'lastName'}}</label>
        <i  aria-hidden="true" class="icon-Bulk-Tee-Time" *ngIf="player && (player.teeTimeFormat == teeTimeFormat.BulkTee)"></i>
      </div>
    </section>  
  </div>  
  <app-checkbox-table [automationId]="automationId"  [isDisableEnabled]=true [tableForm]="clearPlayerForm" [headers]="headerOption" [tableData]="tableData">
  </app-checkbox-table>
</section>