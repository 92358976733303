import { Injectable } from '@angular/core';
import { AuthenticationCommunication } from '../../communication/services/authentication.service';
import { API } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { PatchOperation, PatchJson } from '../../global.constant';
import { PaymentServiceCommunication } from '../../communication/services/payment-communication-services';
import { PropertyConfiguration } from 'src/app/retail/shared/business/view-settings.modals';

@Injectable()
export class PropertySettingDataService {

    constructor(private _authenticationCommunication: AuthenticationCommunication, private _paymentCommunication: PaymentServiceCommunication) { }

    public getAllPropertySetting(_propertyId: number): Promise<API.PropertySetting> {
        return this._authenticationCommunication.getPromise<API.PropertySetting>(
            { route: GolfApiRoute.GetAllPropertySettings, uriParams: { propertyId: _propertyId } });         
    }

    public async GetPaymentConfigurationByProperty(propertyId : number): Promise<API.PaymentConfiguration[]> {
        return this._paymentCommunication.getPromise<API.PaymentConfiguration[]>(
            { route: GolfApiRoute.GetPaymentConfigurationByProperty, uriParams: { propertyId: propertyId } });
        
    }


    public  patchPropertySetting(_propertyId: number, body: Partial<API.PropertySetting>): Promise<boolean> {
        let patchJson: PatchJson = {
            op: PatchOperation.replace,
            path: "/ActivateRetailInterface",
            value: body.activateRetailInterface
        };      

        return this._authenticationCommunication.patchPromise<boolean>({ route: GolfApiRoute.UpdatePropertySetting,
            uriParams:{propertyId:_propertyId}
            , body: [patchJson] });        
    }

    public async getPropConfig(propertyId : number): Promise<PropertyConfiguration<string>> {

            return this._authenticationCommunication.getPromise<PropertyConfiguration<string>>(
                // tslint:disable-next-line:max-line-length
                { route: GolfApiRoute.GetPropertyConfiguration, uriParams: { propertyId: propertyId, productId: 0, configName: 'PROPERTYCONFIGURATION' } });

         
    }

    public async GetSupportedPMAgentVersionByPropertyID(propertyId : number): Promise<any> {
        let result = await this._paymentCommunication.getPromise<any>(
            { route: GolfApiRoute.GetSupportedPMAgentVersionByProperty, uriParams: { propertyId: propertyId } });
        return result;
    }
   
}