import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginCommunicationService } from './login-communication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private loginService: LoginCommunicationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (sessionStorage.getItem('_jwt') != null && sessionStorage.getItem('_jwt') != "undefined" && sessionStorage.getItem('_jwt') != undefined && sessionStorage.getItem('propertyDate')!=null)
      return true;
    if(sessionStorage.getItem('supportUserMailId')){
      this.router.navigate(['/supportlogin']);  
      return false;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
