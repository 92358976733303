<section [formGroup]="NoShowForm" class="h-100 noShowModal-container"  id="NoShowModal">
    <section class="golf-container--padding ag-pt-0  ag-pb-0 tableContainer">

        <app-checkbox-table class="customStyle" [tableForm]="NoShowForm" [headers]="headerOption"
            [tableData]="tableData" [childTemplate]="childTemplate" (unCheckedData)="checkBoxChange($event,'uncheck')" (checkedData)="checkBoxChange()">
        </app-checkbox-table>
    </section>
</section>

<ng-template #childTemplate let-element="element" let-key="key" let-data="data" let-row="row" let-index="idx">
    <ng-container [ngSwitch]="key">
      <ng-container *ngSwitchCase="'action'">
        <i class="icon-waive-off" [ngClass]="[row.checked ? 'ag_cursor--pointer' : 'ag_link--disabled', (row.checked && row.isIconClicked) ? 'ag_link' : '']"
        (click)="iconClick(row)"></i>
        <!-- <a attr.automationId='Tog_{{automationId}}_release{{idx}}' (click)='row.isReleaseAllow && activityAction(row)'
           *ngIf="row.isHold"
           [ngClass]="{'cust-button-disabled':!row.isReleaseAllow} ">{{captions.teetime.release}}</a> -->
      </ng-container>
      <ng-container *ngSwitchCase="'charges'">
        <i class="icon-alert1" *ngIf="row.charges == null" (click)="alertIconClick(row)"></i>
      </ng-container>
    </ng-container>
  </ng-template>