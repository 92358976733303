import { Injectable } from '@angular/core';
import { ContactInformation } from 'src/app/tee-time/waitlist/waitlist.model';
import { CourseDataService } from 'src/app/shared/data-services/golfschedule/course.data.service';
import { WaitlistService } from './waitlist.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import * as WaitlistInterface from './waitlist.model';
import { Course } from 'src/app/settings/golf-setup/code-setup/course/create-course-modal/create-course-model';
import { PlayerTypeService } from 'src/app/shared/data-services/golfschedule/playertype.data.service';
import { PlayerType } from 'src/app/settings/golf-setup/code-setup/player-type/player-type.modal';
import { RateType } from 'src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal';
import { RateTypeDataService } from 'src/app/shared/data-services/golfschedule/ratetype.data.service';
import { Options } from 'src/app/dynamicforms/form-type.entity';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { ContactType, playerTypes, GUID_REGEXP } from 'src/app/shared/global.constant';
import { TeeTimeService } from 'src/app/tee-time-actions/teetime/tee-time.service';
import { TeeTimeModel } from 'src/app/tee-time-actions/teetime/tee-time.model';


@Injectable()
export class WaitlistBusiness {

  constructor(private _courseDataService: CourseDataService, private _WaitListService: WaitlistService, private _localization: GolfLocalization
    , private _PlayerTypeService: PlayerTypeService,
    private _RateTypeDataService: RateTypeDataService,
    private _utils: GolfUtilities,
    private teeTimeService: TeeTimeService,
    private rateTypeDataService: RateTypeDataService
  ) { }


  public async getCourses(): Promise<Options[]> {
    let courses = await this._courseDataService.getCoursesWithUserAccess();
    return courses.map(c => {
      return {
        key: c.id, label: c.name
      }
    });
  }

  public GetAllRateTypes(showInactiveRateTypes: boolean): Promise<RateType[]> {
    return this.rateTypeDataService.getAllRateTypes(showInactiveRateTypes);
  }

  async GetWaitListByDate(scheduleDateTime: string, courseId: number, originalHoleNumber?: string): Promise<WaitlistInterface.WaitListViewModel[]> {
    let waitListViewModel: WaitlistInterface.WaitListViewModel[] = [];

    waitListViewModel = await this.GetWaitList(scheduleDateTime, courseId);
    if (waitListViewModel != null && waitListViewModel.length > 0) {
      let scheduleTeetimeList: TeeTimeModel[] = await this.teeTimeService.GetTeeTimesofCourseByDate(courseId, scheduleDateTime);
      let scheduleTeetime = scheduleTeetimeList.find(x => x.scheduleStatus == 1 && (originalHoleNumber!=null && originalHoleNumber!="" && originalHoleNumber!= undefined ? x.originalHoleNumber == originalHoleNumber:true));
      if (scheduleTeetime && scheduleTeetime.scheduledTeeTimePlayers) {
        const count = scheduleTeetime ? scheduleTeetime.scheduledTeeTimePlayers.length : 0;
        let finalcount = 4 - Number(count);
        if(finalcount<1)
          waitListViewModel = [];     
      }
    }

    return waitListViewModel;
  }

  
  async GetWaitList(scheduledDate: string, courseId: number): Promise<WaitlistInterface.WaitListViewModel[]> {
    const _date: string = this._localization.convertDateObjToAPIdate(scheduledDate);
    let waitList: WaitlistInterface.WaitList[] = await this._WaitListService.GetWaitList(_date, courseId);
    let courseList: Course[] = await this._courseDataService.getCoursesWithUserAccess();
    let playerList: PlayerType[] = await this._PlayerTypeService.getAllPlayerTypes();
    let rateList: RateType[] = await this._RateTypeDataService.getAllRateTypes(true);
    let waitListViewModel: WaitlistInterface.WaitListViewModel[] = [];
    if (waitList) {
      waitList.forEach(x => {
        if (x) {
          let courseObj = courseList.find(y => y.id == x.courseId);
          let playerObj = playerList.find(y => y.id == x.playerTypeId);
          let rateObj = rateList.find(y => y.id == x.rateTypeId);
          waitListViewModel.push({
            playerName: this._utils.formatGuestName(x.firstName, x.lastName),
            memberId: x.playerCategoryId == playerTypes.member ? x.playerLinkId : "",
            requestedTime: this._localization.LocalizeShortDateTime(this._localization.getDate(x.requestedDateTime)).toUpperCase(),
            numberOfPlayers: x.numberOfPlayers,
            originalNumberOfPlayers: x.originalNumberOfPlayers,
            course: courseObj ? courseObj.name : '',
            courseId: courseObj? courseObj.id :0,
            //remove when getphonetype is moved to common
            phoneNumber: this.getContact(x.contactInformation, ContactType.phone),
            emailId: this.getContact(x.contactInformation, ContactType.email),
            comments: x.comment,
            playerType: playerObj ? playerObj.type : '',
            rateType: rateObj ? rateObj.type : '',
            hotelReservationId: x.playerCategoryId == playerTypes.hotelReservation && !RegExp(GUID_REGEXP).test(x.playerLinkId) ? x.playerLinkId : "",
            madeOn: this._localization.LocalizeShortDateTime(this._localization.getDate(x.madeOn)).toUpperCase(),
            apiData: x,
            id: x.id
          })
        }
      });

    }
    this._utils.ToggleLoader(false)

    return waitListViewModel;
  }



  public ConvertWaitList(obj): WaitlistInterface.ConvertWaitlistApiData {
    let phoneTypeSetter = obj.phoneNumber == '' ? '' : obj.apiData.contactInformation.find(y => y.value == obj.phoneNumber).type;
    let phoneType = obj ? phoneTypeSetter : 1;
    let playerTypeId = (obj && obj.apiData) ? parseInt(obj.apiData.playerTypeId, 0) : 0;
    let rateTypeId = (obj && obj.apiData) ? parseInt(obj.apiData.rateTypeId, 0) : 0;
    let countryCodeSetter = (obj.phoneNumber == '' ? '' : (this._utils.getCountryCodeFromValue(phoneType, obj.phoneNumber)).toString());
    let phoneNumberSetter = (obj.phoneNumber == '' ? '' : (this._utils.getPhoneNumberFromValue(phoneType, obj.phoneNumber)).toString());
    let extensionSetter = (obj.extension == '' ? '' : (this._utils.getExtensionFromValue(phoneType, obj.phoneNumber)));
    let emailTypeSetter = obj.emailId == '' ? '' : obj.apiData.contactInformation.find(y => y.value == obj.emailId).type;
    return {
      editToggle: (playerTypeId > 0 && rateTypeId > 0) ? false : true,
      firstName: obj ? obj.apiData.firstName : '',
      lastName: obj ? obj.apiData.lastName : '',
      phoneType: phoneType,
      countryCode: obj ? countryCodeSetter : '',
      phoneNumber: obj ? phoneNumberSetter : '',
      extension: obj ? extensionSetter : '',
      emailType: obj ? emailTypeSetter : 1,
      emailId: obj ? obj.emailId : '',
      playerType: obj ? obj.apiData.playerTypeId : '',
      rateType: obj ? obj.apiData.rateTypeId : '',
      course: obj ? obj.apiData.courseId : '',
      requestedDate: obj ? obj.apiData.requestedDateTime : '',
      requestedTime: obj ? this._localization.LocalizeTime(obj.apiData.requestedDateTime) : '',
      noOfPlayers: obj ? obj.numberOfPlayers : '',
      originalNoOfPlayers: obj ? obj.originalNumberOfPlayers: '',
      comments: obj ? obj.apiData.comment : '',
      staticDate: obj ? this._localization.localizeDisplayDate(this._localization.getDate(obj.apiData.requestedDateTime)) : '',
      staticTime: obj ? this._localization.LocalizeTime(obj.apiData.requestedDateTime) : '',
      staticName: obj.apiData.firstName + ' ' + obj.apiData.lastName,
      staticCourse: obj.course,
      staticPlayers: obj.numberOfPlayers,
      staticComments: obj.comments,
      id: obj ? obj.apiData.id : 0,
      playerTypeRadio: obj ? obj.apiData.playerCategoryId : '',
      playerLinkId: obj ? obj.apiData.playerLinkId : '',
      paymentReferenceId: obj ? obj.apiData.paymentReferenceId : '',
      addressId: obj && obj.apiData && obj.apiData.playerAddress ? obj.apiData.playerAddress.id : 0,
      playerId: obj && obj.apiData && obj.apiData.playerAddress ? obj.apiData.playerAddress.playerId : 0,
      address: obj && obj.apiData && obj.apiData.playerAddress ? this.addressBindValue(obj.apiData.playerAddress) : null,
      city: obj && obj.apiData && obj.apiData.city ? obj.apiData.city : '',
      state: obj && obj.apiData && obj.apiData.state ? obj.apiData.state : '',
      country: obj && obj.apiData && obj.apiData.country ? obj.apiData.country : '',
      postal_code: obj && obj.apiData && obj.apiData.zip ? obj.apiData.zip : '',
      playerAdditionalDetails: obj && obj.apiData && obj.apiData?.playerAdditionalDetails ? obj.apiData?.playerAdditionalDetails : null,
      vipType: obj ? obj.apiData.vipType : null,
      playerStayDetail: obj && obj.apiData ? obj.apiData.playerStayDetail : null
    }
  }

  public getDefaultDate(): Date {
    return this._localization.getDate(new Date);
  }

  public getDefaultCourse(courses: Options[]): number {
    const userDefaultCourse: number = courses[0].key;
    const defaultKey: string = courses.find(c => c.key === userDefaultCourse).key;
    return parseInt(defaultKey);
  }


  getContact(contacts: ContactInformation[], type): string {
    let result = '';
    if (contacts && contacts.length > 0) {
      const contact: ContactInformation = contacts.find(y => y.name === type);
      if (contact) {
        result = contact.value;
      }
    }

    return result;
  }

  async DeleteWaitList(id: number, callback) {
    await this._WaitListService.DeleteWaitList(id);
    callback();
  }
  addressBindValue(arg) {
    let _addresslines = [];
    if (arg != null && arg.addressLine1 != null && arg.addressLine1.trim().length > 0) {
      _addresslines.push({ addressDetails: arg.addressLine1 });
    }
    if (arg != null && arg.addressLine2 != null && arg.addressLine2.trim().length > 0) {
      _addresslines.push({ addressDetails: arg.addressLine2 });
    }
    if (arg != null && arg.addressLine3 != null && arg.addressLine3.trim().length > 0) {
      _addresslines.push({ addressDetails: arg.addressLine3 });
    }
    return _addresslines;
  }
}

