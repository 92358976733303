

        <mat-form-field class="search-dropdown-wrap" [formGroup]="form" [floatLabel]="floatLabel" [ngClass]="className" >
            <mat-label>{{placeholderName}}</mat-label>
            <input  type="text" class="ag_pr--5"
                   [placeholder]="inputplaceholderName"
                   [disabled]="isdisabled"
                   matInput
                   [matAutocomplete]="auto"
                   [formControlName]="formControlName"
                   (input)='filterOptionschange($event)' (blur)='setSelected()'
                   [required]="this.showRequired">
                   <span class="icon-filled-down-arrow"></span>
            <mat-autocomplete (closed)="onClosed($event)" (opened)="onOpen($event)" autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="optionSelected($event,formControlName)">
              <mat-option *ngFor="let obj of filteredOptions" [value]="obj.value" [disabled]="obj?.isoptionDisabled" [matTooltip]="obj.value" >
                {{obj.value}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="form.controls[formControlName].hasError('required')">
              {{errorMessage}}
          </mat-error>
          </mat-form-field>
          
