import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { RateTypeDataService } from 'src/app/shared/data-services/golfmanagement/ratetype.data.service';
import { RedeemModalBussiness } from './redeem-round-bussiness';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { batchMemberPayemnt, MemberInfo, memberPlayer, memberPlayerData } from './redeem-model';
import { PlayerDetail } from '../teetime/tee-time.model';
import _, { cloneDeep } from 'lodash';
import { StepperService } from 'src/app/shared/components/stepper/stepper.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertType, ButtonType, DialogCloseObj } from 'src/app/shared/shared-models';
import { ACESPaymentService } from 'src/app/shared/data-services/golfschedule/acespayment.data.service';
import { CommonPopupComponent } from 'src/app/shared/components/common-popup/common-popup.component';
import { PlayerPaymentstatus } from 'src/app/tee-time/search/search-model';
import { AlertAction } from 'src/app/common/Models/common.models';
import { RedeemProgressBarComponent } from './redeem-progress-bar/redeem-progress-bar.component';
import { RedeemService } from './redeem-round.service';
import { RateType } from 'src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal';
import { ScheduledTeeTimeUnPaidPlayer } from 'src/app/shared/models/unpaid-players.model';
import { Course } from 'src/app/settings/golf-setup/code-setup/course/create-course-modal/create-course-model';
import { DefaultsSettingConfig } from '../teetime/player-details/player-info.model';
import { playerCategory } from 'src/app/lessons/lessons-modal/player-info/player-info.model';
import { TeeTimesActionService } from '../../shared/data-services/golfschedule/teeTimesAction.data.service';

@Component({
  selector: 'app-redeem-round',
  templateUrl: './redeem-round.component.html',
  styleUrls: ['./redeem-round.component.scss'],
  providers: [RedeemModalBussiness, RateTypeDataService, RedeemService],
  encapsulation: ViewEncapsulation.None
})
export class RedeemRoundComponent implements OnInit, OnDestroy {
  captions: any;
  searchText = '';
  RedeemForm: UntypedFormGroup;
  headerOptions: TableHeaderOptions[];
  tableOptions: TableOptions;
  originalData$;
  originalData;
  wholeDayOriginalData;
  tableContent: memberPlayerData[];
  checkedData: memberPlayerData[];
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() notifyParent = new EventEmitter();
  data: any;
  teeTime: any;
  course: any;
  courseId: number;
  teeTimeId: any;
  date: Date;
  playerDetail: PlayerDetail[];
  rateTypes: RateType[]  = [];
  dropOptions: { id: string; value: string | number; viewValue: string }[];
  disableActionBtn: boolean = false;
  disableSwitch: boolean = false;
  response;
  progress = 10;
  isZeroMemberAllDayEnabled = false;
  progressInfo = {
    totalCount: 0,
    processedCount: 0,
    successCount: 0,
    failureCount: 0
  };
  unPaidPlayers: ScheduledTeeTimeUnPaidPlayer[];
  defaultSetting: DefaultsSettingConfig;
  courseDetail: Course;
  packageRetailItems = [];
  floatLabel: string;
  corporatesApplicable:any = undefined;


  constructor(private dialog: MatDialog
    , private localization: GolfLocalization
    , public _StepperService: StepperService
    , private bussiness: RedeemModalBussiness
    , private redeemService: RedeemService
    , @Inject(MAT_DIALOG_DATA) public dialogData
    , public dialogRef: MatDialogRef<CommonPopupComponent>
    , private _Utilities: GolfUtilities
    , private _acesDataService: ACESPaymentService
    , private _FormBuilder: UntypedFormBuilder
    , private _teeTimeActionService: TeeTimesActionService  ) {
      this.floatLabel = this.localization.setFloatLabel;
    this.captions = this.localization.captions;
    this.data = _.cloneDeep(dialogData.info);
    this.dropOptions = this.data.playerDetail.filter(y => y.memberNumber != "" && y.amountPaid == 0).map(x => {
      return { id: x.memberNumber, value: this._Utilities.formatGuestName(x.firstName, x.lastName) }
    });
  }

  ngOnDestroy(): void {
    this._StepperService.enableCancel = true;
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }


  async ngOnInit() { 
    this.buildForm();  
    this.course = this.data.course.course;
    this.courseId = this.data.course.id;
    this.date = this.localization.getDate(this.data.time);
    [this.unPaidPlayers,this.defaultSetting, this.courseDetail] = await Promise.all([
      this.bussiness.GetAllUnPaidPlayers(this.courseId, this.date), 
      this.bussiness.getDefaultSettings(),
      this.bussiness.GetCourseDetailsById(this.courseId)
    ]);
    this.teeTime = this.localization.LocalizeShortDateTime(this.date).toUpperCase();
    this.teeTimeId = this.data.scheduledTeeTimeId;
    this.originalData = this.data.playerDetail.filter(x => this.unPaidPlayers.filter(c => c.playerCategoryId == playerCategory.member).map(c => c.playerId).includes(x.playerId));
    this.refreshGrid(this.originalData);
    this.tableOptions = this.bussiness.getTableOptions();
    this.headerOptions = this.bussiness.getHeaderOptions(this.dropOptions);
    this._StepperService.enableCancel = true;
    this._StepperService.valueChange.pipe(takeUntil(this.$destroyed)).subscribe((x: DialogCloseObj) => {
      if (x.type === 'save') {
        this.submitForm();
      }
    });
  }


  ngAfterViewInit() {
    console.log('test');
  }

  ngDestroy() {
    if (this.$destroyed) {
      this.$destroyed.next(true);
      this.$destroyed.complete();
    }
  }

  buildForm() {
    this.RedeemForm = this._FormBuilder.group({
      paymentComment : ['']
    });
    this.RedeemForm.valueChanges.subscribe(() => {
      const checked = this.tableContent && this.tableContent.length > 0 ? this.tableContent.filter(x => x.checked) : [];
      this.checkedData = checked;
      if (checked && checked.length && checked.every(x => x.member !== "")  && this.RedeemForm.valid) {
        this.notifyParent.emit(true);
      } else {
        this.notifyParent.emit(false);
      }      
    })
  }

  async zeroMemberChange(eve) {
    this.isZeroMemberAllDayEnabled = eve;
    if(this.isZeroMemberAllDayEnabled) {
      const result: Promise<any>[] = [] 
      result.push(this.bussiness.GetAllMemberPlayerDate(this.courseId,this.date));
      if(this.rateTypes && this.rateTypes.length == 0) {
        result.push(this.bussiness.getRateTypes());
      }
      Promise.all(result).then(res=> {
        const allMemberPlayeroftheDay = res[0];
        if(this.rateTypes && this.rateTypes.length == 0) {
          this.rateTypes = res[1];
        }
        const tabledata = this.bussiness.mapToUIData(allMemberPlayeroftheDay,this.rateTypes,this.course);
        this.wholeDayOriginalData = tabledata;
        this.refreshGridWithAllMember(tabledata);
      });
    } else {
      this.refreshGrid(this.originalData);
    } 
  }

  refreshGrid(data) {
    let memberConfiguration = JSON.parse(sessionStorage.getItem('memberConfiguration'));
    if(memberConfiguration && memberConfiguration.corpIdNameMap != null && memberConfiguration.corpIdNameMap != ''){
      this.corporatesApplicable = JSON.parse(memberConfiguration.corpIdNameMap);
      if(this.corporatesApplicable != undefined)
      {
        data.forEach(
          ele => {
            let unpaidPlayer = this.unPaidPlayers.filter(o => o.playerId == ele.playerId);
            if(unpaidPlayer){
              ele.corpName = this.corporatesApplicable[Number(unpaidPlayer[0].corpId)];
              ele.corpId = unpaidPlayer[0].corpId;
            }
          }
        )
      }
    }
    this.tableContent = data.filter(player => player.amountPaid == 0 && 
      (player.playerStatus == (PlayerPaymentstatus.booked | PlayerPaymentstatus.unPaid)) || (player.playerStatus == (PlayerPaymentstatus.booked | PlayerPaymentstatus.refund))).map(x => {
      return {
        memberCardNumber: x.memberNumber,
        member: x.memberNumber,
        course: this.course,
        teeTime: this.teeTime,
        teeTimeId: this.teeTimeId,
        playerName: this._Utilities.formatGuestName(x.firstName, x.lastName),
        playerId: x.playerId,
        rateType: x.rateType.name,
        holes: x.holes,
        teeTimeApiDate: this.data.time,
        checked: (x.memberNumber && x.memberNumber !== '') ? true : false,
        playPos: x.playGroup ? x.playGroup.listOrder : x.playPos,
        playerTypeId : x.playerTypeId,
        corpName: x.corpName,
        corpId: x.corpId 
      }
    });
    if (this.tableContent && this.tableContent.length > 0) {
      const checked = this.tableContent.filter(x => x.checked);
      this.checkedData = checked;
      if (checked && checked.length && checked.every(x => x.member !== "")  && this.RedeemForm.valid) {
        this.notifyParent.emit(true);
      }
    }
    this._StepperService.enableCancel = true;
  }

  refreshGridWithAllMember(data) {
    if(this.corporatesApplicable != undefined){
      data.forEach(x => {
        x.corpName = this.corporatesApplicable[Number(x.corpId)]
      });
    }   
    this.tableContent = data;
    if (this.tableContent && this.tableContent.length > 0) {
      const checked = this.tableContent.filter(x => x.checked);
      this.checkedData = checked;
      if (checked && checked.length && checked.every(x => x.member !== "") && this.RedeemForm.valid) {
        this.notifyParent.emit(true);
      }
    }
    this._StepperService.enableCancel = true;
  }


  async submitForm() {
    
    let corpIds = _.uniq(this.checkedData.map(o => o.corpId));
    corpIds = corpIds.filter(o => o != null && o != '');
    if(corpIds && corpIds.length > 1){
      this._Utilities.showAlert(this.captions.teetime.memberdifferentcorporates,AlertType.Error);
      return false;
    }
    this.notifyParent.emit(false);
    this._StepperService.enableCancel = false;
    let retailItemIdsTemp = [];
    this.packageRetailItems = [];
    const greenItemIdsTemp = this.unPaidPlayers.filter(c => c.greenFeeRetailItemId != 0).map(x => x.greenFeeRetailItemId);
    const cartItemIdsTemp = this.unPaidPlayers.filter(c => c.cartFeeRetailItemId != 0).map(x => x.cartFeeRetailItemId);
    const entryItemIdsTemp = this.unPaidPlayers.filter(c => c.entryFeeRetailItemId != 0).map(x => x.entryFeeRetailItemId);
    retailItemIdsTemp = [...greenItemIdsTemp, ...cartItemIdsTemp, ...entryItemIdsTemp];
    retailItemIdsTemp = retailItemIdsTemp.distinct();
    if (retailItemIdsTemp?.length > 0) {
      this.packageRetailItems = await this.bussiness.GetPackageItemsByRetailItemIds(_.uniq(retailItemIdsTemp));
    }
    if (!this.isZeroMemberAllDayEnabled) {
      let memberPlayer: memberPlayer[] = this.checkedData.map(x => {
        return {
          playerId: x.playerId,
          playerName: x.playerName,
          rateType: x.rateType,
          holes: x.holes,
          memberCardNumber: x.memberCardNumber,
          memberNumber: x.member,
          playerTypeId: x.playerTypeId,
          retailItemIds: this.getRetailItemIds(this.teeTimeId, x),
          corpId: Number(x.corpId) && Number(x.corpId) > 0 ? Number(x.corpId) : 0
        }
      });
      let batchMemberPayemnt: batchMemberPayemnt = {
        course: this.course,
        teeTime: this.data.time,
        teeTimeId: this.teeTimeId,
        outletId: this.courseDetail.defaultOutletId,
        paymentMethod: 8,
        paymentComments: this.RedeemForm.value.paymentComment,
        memberPlayers: memberPlayer
      }
      if (this.checkedData && this.checkedData.length) {
        this.disableSwitch = true;
        await this._acesDataService.BatchMemberPayment(batchMemberPayemnt).then(res => {
          this.response = res;
          if (this.response.length === this.checkedData.length) {
            this._Utilities.showAlert(this.localization.captions.teetime.Redeemprocess, AlertType.Success, ButtonType.Ok, async (res) => {
              this.checkOutMembers(memberPlayer.map(x => x.playerId));
              if (res === AlertAction.CONTINUE) {
                this.dialogRef.close();
              }
            })
          } else if ((this.response.length !== 0) && (this.response.length < this.checkedData.length)) {
            let players = this.originalData$.filter(x => res.includes(x.playerId));
            let names = players.map(x => x.fullName);
            let msg = this.localization.replacePlaceholders(this.localization.captions.teetime.RedeemPartiallyProcessed, ['players'], [names]);
            this._Utilities.showAlert(msg, AlertType.Success, ButtonType.Ok, async (res) => {
              if (res === AlertAction.CONTINUE) {
                this.originalData = this.originalData.filter(x => !this.response.map(f => f.playerId).includes(x.playerId));
                this.originalData = [... this.originalData];
                if (this.originalData.length > 0) {
                  this.refreshGrid(this.originalData);
                }
              }
            })
          } else {
            this._Utilities.showAlert(this.localization.captions.teetime.Redeemnotprocessed, AlertType.Error, ButtonType.Ok, async (res) => {
              if (res === AlertAction.CONTINUE) {
                if (this.originalData.length > 0) {
                  this.refreshGrid(this.originalData);
                }
              }
            });
          }
        });
      }
    } else {
      this.disableSwitch = true;
      this.openProgressBar();
      let allMemberPlayer = _.chain(this.checkedData).groupBy('teeTimeId').map((value, key) => ({
        teetimeId: key,
        memberDetail: value
      })).value();
      this.processZeroMemberForWholeDay(allMemberPlayer);
    }
  }


  tableAction(e) {
    switch (e.fromType) {
      case FromTypeEnum.allcheckbox: {
        this.settableData(e);
        break;
      }
      case FromTypeEnum.rowcheck: {
        this.settableData(e)
        break;
      }
      case FromTypeEnum.input: {
        this.settableData(e)
        break;
      }
      case FromTypeEnum.dropdown: {
        this.settableData(e)
        break;
      }
    }
  }

  settableData(e) {
    this.tableContent = e.array;
    let echeckeddata = e.array.filter(x => x.checked);
    let notifyParentFlag = echeckeddata && echeckeddata.every(x => x.member !== "") ? echeckeddata.length > 0 : false;     
    this.checkedData = [];
    this.checkedData = echeckeddata;
    this.notifyParent.emit(notifyParentFlag);
  }

  emitDropdownData(e, i, k) {
    if (i.member !== "") {
      let idx = this.checkedData.findIndex(x => x.playerId === i.playerId);
      this.checkedData[idx] = i;
      let notifyParentFlag = this.checkedData && this.checkedData.every(x => x.member !== "") ? this.checkedData.length > 0 : false;  
      this.notifyParent.emit(notifyParentFlag);
    }
  }

  async processZeroMemberForWholeDay(data) {
    let processArray = data;
    this.progressInfo.totalCount = this.checkedData.length;
    this.progressInfo.processedCount= 0;
    this.progressInfo.successCount= 0;
    this.progressInfo.failureCount= 0;    
    while(processArray.length != 0) {
      const totalCount = Math.floor(processArray.length/4);
      let processedArray: Promise<any>[] = [];
      let proccessTimePlayer = [];
      if(totalCount >= 1) {
        for(let i=0; i<4; i++) {
          const value = processArray.splice(0,1);
          proccessTimePlayer = proccessTimePlayer.concat(value[0].memberDetail.map(x=>{
              return {
                teeTimeId: x.teeTimeId,
                playerId: x.playerId
              }
            })
          )
          processedArray.push(this.zeroMemeberPayment(value[0].memberDetail));
        }
      } else {
        while(processArray.length != 0) {
          const value = processArray.splice(0,1);
          proccessTimePlayer = proccessTimePlayer.concat(value[0].memberDetail.map(x=>{
              return {
                teeTimeId: x.teeTimeId,
                playerId: x.playerId
              }
            })
          )
          processedArray.push(this.zeroMemeberPayment(value[0].memberDetail));
        }
      }
      await Promise.all(processedArray).then((result)=> {
        const res = result.flat();
        proccessTimePlayer.forEach(value => {
          this.progressInfo.processedCount += 1;
          const isProcessed = res.some(x=> (x.playerId == value.playerId && x.teeTimeId==value.teeTimeId));
          if(isProcessed) {
            this.progressInfo.successCount += 1;
          } else {
            this.progressInfo.failureCount += 1;
          }
        });
        if(this.progressInfo.successCount>0){
          this.wholeDayOriginalData=this.wholeDayOriginalData.filter(x => !res.map(f=>f.playerId).includes(x.playerId)) ;
          this.wholeDayOriginalData = [... this.wholeDayOriginalData];
        }

        if (processArray.length == 0) {
          this.checkOutMembers(res.map(x => x.playerId));
          this.zeroMemberSummary();
        }
        this.emitProgressInfo();
      },
      (err)=> {
      });
    }
  }

  openProgressBar() {
    this.redeemService.invokeProgress();
    const progressBar = this.dialog.open(RedeemProgressBarComponent, {
      width: '30%',
      height: '30%',
      data: {
        service: this.redeemService
      },
      disableClose: true
    });
    progressBar.afterOpened().subscribe(res=>{
      this.emitProgressInfo();
    })
    progressBar.afterClosed().subscribe(res=>{
      this.redeemService.completeProgress();
    });
  }

  zeroMemeberPayment(memberDetail):Promise<any> {    
    let batchMemberPayemnt: batchMemberPayemnt = {
      course: this.course,
      teeTime: memberDetail[0].teeTime,
      teeTimeId: memberDetail[0].teeTimeId,
      outletId: this.courseDetail.defaultOutletId,
      paymentMethod: 8,
      paymentComments: this.RedeemForm.value.paymentComment,
      memberPlayers: memberDetail.map(x => { 
        return { 
          playerId: x.playerId, 
          playerName: x.playerName, 
          rateType: x.rateType??"", 
          holes: x.holes, 
          memberCardNumber: x.memberCardNumber, 
          memberNumber: x.member, 
          playerTypeId : x.playerTypeId,
          retailItemIds : this.getRetailItemIds(memberDetail[0].teeTimeId, x)
        } 
      })
    };
    console.log(batchMemberPayemnt);
    return this._acesDataService.BatchMemberPayment(batchMemberPayemnt);
  }

  zeroMemberSummary() {
    let message = this.progressInfo.failureCount == 0 ? this.localization.captions.teetime.Redeemprocess: 
                  this.progressInfo.successCount == 0 ? this.localization.captions.teetime.Redeemnotprocessed: 
                  this.localization.captions.teetime.RedeemProcessedPartially;
    let alertType = this.progressInfo.failureCount == 0 ? AlertType.Success : AlertType.Error;
    this._Utilities.showAlert(message, alertType, ButtonType.Ok, async(res) => {
      if (res === AlertAction.CONTINUE) {
        if(this.progressInfo.failureCount == 0) {
          this.dialogRef.close();
        } else {
          this.refreshGridWithAllMember(this.wholeDayOriginalData);
        }
      }
    })
  }

  emitProgressInfo() {
    this.redeemService.progressData$.next(this.progressInfo);
  }

  private getRetailItemIds(teeTimeId: number, memberDetail):number[] {
    const unPaidPlayer = this.unPaidPlayers.find(x=>x.scheduledTeeTimeId === teeTimeId && x.playerId === memberDetail.playerId);
    let retailItemIds = [];
    if(unPaidPlayer.greenFeeRetailItemId) {
      const packageItem = this.packageRetailItems.filter(c=>c.retailItemId == unPaidPlayer.greenFeeRetailItemId);
      if(packageItem && packageItem.length > 0)
      {
        packageItem.forEach(d=>
          {
            retailItemIds.push(d.parentItemId);
          })
      }
      else
      {
        retailItemIds.push(unPaidPlayer.greenFeeRetailItemId);
      }
    }
    if(unPaidPlayer.cartFeeRetailItemId) {
      const packageItem = this.packageRetailItems.filter(c=>c.retailItemId == unPaidPlayer.cartFeeRetailItemId);
      if(packageItem && packageItem.length > 0){
        packageItem.forEach(d=>{
            retailItemIds.push(d.parentItemId);
          })
      }
      else
      {
        retailItemIds.push(unPaidPlayer.cartFeeRetailItemId);
      }
    }
    if(unPaidPlayer.entryFeeRetailItemId) {
      const packageItem = this.packageRetailItems.filter(c=>c.retailItemId == unPaidPlayer.entryFeeRetailItemId);
      if(packageItem && packageItem.length > 0)
      {
        packageItem.forEach(d=>
          {
            retailItemIds.push(d.parentItemId);
          })
      }
      else
      {
        retailItemIds.push(unPaidPlayer.entryFeeRetailItemId);
      }
    }
    return retailItemIds;
  }
  private checkOutMembers(arrPlayerId: any = []) {
    if (arrPlayerId.length > 0) {
      var teeTimeSetting = JSON.parse(sessionStorage.getItem('TEETIMESETTING'));
      if (teeTimeSetting && teeTimeSetting != null && teeTimeSetting?.autoCheckInCheckOutZeroCharges)
        var indexOfS = Object.values(PlayerPaymentstatus).indexOf(PlayerPaymentstatus.CheckOut as unknown as PlayerPaymentstatus);
      let strCheckOut = Object.keys(PlayerPaymentstatus)[indexOfS];
      this._teeTimeActionService.checkInOutStatus(strCheckOut, arrPlayerId)
    }
  }
}
