import { Injectable } from '@angular/core';
import { HttpMethodModel } from '../models';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map, shareReplay, share } from 'rxjs/operators';
import * as CONSTANT from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
import { RouteNavigateService } from 'src/app/eatecui/source/shared/services/route-navigate.service';
import { Params } from '@angular/router';
import { EnvService } from 'src/app/eatecui/source/config.service';
import { StateService } from './state.service';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json; odata.metadata=minimal; odata.streaming=true',
        'Accept': 'application/json',
    }),
    params: null
};

@Injectable({
    providedIn: 'root',
})

export class HttpService implements HttpMethodModel {
    constructor(private http: HttpClient, private stateService: StateService, private envService: EnvService) { }

    /**
     * @meyhod GethttpMethod (RequestUrl: string)
     * @param RequestUrl;
     * @description Get http request method retrive data from the server
     */
    GethttpMethod(RequestUrl: string, isSupressed = null): Observable<Array<any>> {
        try {
            httpOptions.headers= this.setHeaders(RequestUrl);
            RequestUrl = this.getAbsoluteApiURL(RequestUrl);
            if (httpOptions.headers.has('isactive')) {
                httpOptions.headers = httpOptions.headers.delete('isactive');
            }
            const options = httpOptions;
            if (RequestUrl.includes('?$')) {
                const queryparams = '$' + RequestUrl.split('?$')[1];
                RequestUrl = RequestUrl.split('?$')[0];
                if (!options.headers.has('oquery')) {
                    options.headers = options.headers.append('oquery', queryparams);
                } else {
                    // httpOptions.headers.delete('oquery');
                    options.headers = options.headers.set('oquery', queryparams);
                }
                if (!options.headers.has('supressed')) {
                    options.headers.append('supressed', 'true');
                }
            } else if (RequestUrl.includes('?%24')) {
                const queryparams = '$' + RequestUrl.split('?%24')[1];
                RequestUrl = RequestUrl.split('?%24')[0];
                if (!options.headers.has('oquery')) {
                    options.headers = options.headers.append('oquery', queryparams);
                } else {
                    // httpOptions.headers.delete('oquery');
                    options.headers = options.headers.set('oquery', queryparams);
                }
                if (!options.headers.has('supressed')) {
                    options.headers.append('supressed', 'true');
                }
            } else {
                if (options.headers.has('oquery')) {
                    options.headers = options.headers.delete('oquery');
                }
                if (options.headers.has('supressed')) {
                    options.headers.append('supressed', 'true');
                }
            }
            options.headers = options.headers.set('supressed', (isSupressed) ? isSupressed.toString() : 'false');
            
            return this.http.get<any>(RequestUrl, options)
                .pipe(
                    retry(0),
                    shareReplay({ bufferSize: 1, refCount: true }),
                    catchError(this.handleError)
                );
        } catch (error) {
            console.log('erorr occured GethttpMethod', error);
        }
    }
    /**
     * @method GetHttpHealthCheckMethod
     * @param RequestUrl 
     * @returns 
     */
    GetHttpHealthCheckMethod(RequestUrl: string, key: string): Observable<Array<any>> {
        try {
            let healthCheckUrl = this.getAbsoluteApiURL(RequestUrl);
            if (key === 'notification' || key === 'reportdata' || key === 'publisher') {
                healthCheckUrl = window.origin + RequestUrl;
            }
            const options = httpOptions;
            return this.http.get<any>(healthCheckUrl, options)
                    .pipe(
                        retry(0),
                        shareReplay({ bufferSize: 1, refCount: true }),
                        catchError(this.handleError)
                    );
        } catch (error) { 
            console.log(error);
        }
        
    }
    GethttpBlobMethod(RequestUrl: string, CustomHeaderOptions = null): Observable<Blob> {
      try {
        
          RequestUrl = this.getAbsoluteApiURL(RequestUrl);

            httpOptions.headers['headers'].delete('content-type')
            httpOptions.headers['headers'].set('content-type',['application/octet-stream;']);
            httpOptions.headers['headers'].delete('accept')
            httpOptions.headers['headers'].set('accept',['application/octet-stream;']);
           const value=CustomHeaderOptions.Value;
            httpOptions.headers['headers'].set('Range',[value]);
            httpOptions.headers['normalizedNames'].set('Range','Range');
            let  headers = new HttpHeaders();

            headers=httpOptions.headers;
          return this.http.get(RequestUrl, {  headers, observe: 'body', responseType: 'blob'} )
              .pipe(
                  retry(0),
                  shareReplay({ bufferSize: 1, refCount: true }),
                  catchError(this.handleError)
              );
      } catch (error) {
          console.log('erorr occured GethttpMethod', error);
      }
  }
    /**
     * @method GetHttpRequestId (RequestUrl: string)
     * @param RequestUrl;
     * @description Get http request method retrive data from the server
     */
    GetHttpRequestId(RequestUrl: string, RequestParams: Params): Observable<any> {
        try {
            RequestUrl = this.getAbsoluteApiURL(RequestUrl);
            if (httpOptions.headers.has('oquery')) {
                httpOptions.headers = httpOptions.headers.delete('oquery');
            }
            if (httpOptions.headers.has('isactive')) {
                httpOptions.headers = httpOptions.headers.delete('isactive');
            } 
            return this.http.get<any>(RequestUrl, { headers: httpOptions.headers, params: RequestParams })
                .pipe(
                    retry(0),
                    shareReplay({ bufferSize: 1, refCount: true }),
                    catchError(this.handleError)
                );
        } catch (error) {
            console.error(error);
        }

    }
    /**
     * @method integrationDeleteMethod
     */
    IntegrationDeleteMethod(RequestUrl: string, options: any): Observable<any> {
        try {
            RequestUrl = this.getAbsoluteApiURL(RequestUrl);
            return this.http.delete<any>(RequestUrl, options)
                .pipe(
                    retry(0),
                    catchError(this.handleError)
                );
        } catch (error) {
            console.log('Error Occured in Delete');
        }
    }
    /**
     * @method HttpClientDelete
     */
    HttpClientDelete(RequestUrl: string, options: any): Observable<any> {
        try {
            return this.http.delete<any>(RequestUrl, options)
            .pipe(
                retry(0),
                catchError(this.handleError)
            );
        } catch (error) {
            console.log(error);
        }
    }
    /**
     * @method IntegrationPostMethod
     */
    IntegrationPostMethod(RequestURL: string, options: any): Observable<any> {
        try {
            RequestURL = this.getAbsoluteApiURL(RequestURL);
            return this.http.post<any>(RequestURL, options)
                .pipe(
                    retry(0),
                    catchError(this.handleError)
                );
        } catch (error) {
            console.log('Error Occured');
        }
    }
    /**
     * @method HttpClientPost()
     */
    HttpClientPost(RequestURL: string, options: any): Observable<any> {
        try {
            return this.http.post<any>(RequestURL, options)
            .pipe(
                retry(0),
                catchError(this.handleError)
            );
        } catch (error) {
            console.log('Error Occured');
        }
    }

    HttpClientGetMethod(RequestURL: string): Observable<any> {
        try {
            return this.http.get<any>(RequestURL).pipe(
                retry(0),
                catchError(this.handleError)
            );
        } catch (error) {
            console.log('Error Occured');
        }
    }
    /**
     * @method HttpClientGet()
     */
    HttpClientGet(RequestURL: string, RequestObject: any, Requestparams: any = null, headers: any = null): Observable<any> {
       try {
        return this.http.get<any>(RequestURL, headers)
        .pipe(
            retry(0),
            shareReplay({ bufferSize: 1, refCount: true }),
            catchError(this.handleError)
        );
       } catch (error) {
          console.log(error);
       }
    }
    /**
     * @functiond DeletehttpMethod (RequestUrl: string, RequestId: any)
     * @param RequestUrl;
     * @description Get http request method retrive data from the server
     */
    DeletehttpMethod(RequestUrl: string): Observable<Array<any>> {
        try {            
            if (httpOptions.headers.has('oquery')) {
                httpOptions.headers = httpOptions.headers.delete('oquery');
            }
            if (httpOptions.headers.has('isactive')) {
                httpOptions.headers = httpOptions.headers.delete('isactive');
            } 
            RequestUrl = this.getAbsoluteApiURL(RequestUrl);
            return this.http.delete<any>(RequestUrl, httpOptions)
                .pipe(
                    retry(0),
                    catchError(this.handleError)
                );
        } catch (error) {
            console.log('erorr occured DeletehttpMethod', error);
        }

        
    }

     getBoundary(formData: FormData): string | null {
        // Convert FormData to string
        const formDataString = formData.toString();
     
        // Extract boundary from FormData string
        const match = formDataString.match(/boundary=(.+)/);
        if (match && match[1]) {
            return match[1];
        } else {
            return null;
        }
    }
      /**
    * @method PostFilehttpMethod (RequestUrl: string, RequsetObject: any)
    * @param RequestUrl;
    * @description File Upload Method Post
    */
       PostFilehttpMethod(RequestUrl: string, RequestObject: any, Requestparams: any = null, headers: any = null,
        contentType: string = null): Observable<any> {
            try {
                let httpOption;
                RequestUrl = this.getAbsoluteApiURL(RequestUrl);
                if (Requestparams) {
                    httpOptions.params = Requestparams;
                    
                }                
                if (httpOptions.headers.has('oquery')) {
                    httpOptions.headers = httpOptions.headers.delete('oquery');
                }
                if (httpOptions.headers.has('isactive')) {
                    httpOptions.headers = httpOptions.headers.delete('isactive');
                } 
                
                if (headers) {
                    const headerContentType = ( contentType && contentType !== null ) ? contentType :
                    'application/json; odata.metadata=minimal; odata.streaming=true';
                
                    httpOptions.headers['headers'].delete('content-type')
                    httpOptions.headers['headers'].set('content-type',['multipart/form-data;boundary=boundary-edge']);
                   
                    httpOptions.headers['headers'].set('Enctype',['multipart/form-data']);
                    httpOptions.headers['normalizedNames'].set('Enctype','Enctype');
                    httpOptions.headers.set('Enctype','multipart/form-data');
                    httpOption=httpOptions;

                    
                } else {
                    console.log('httpOptions',httpOptions)
                    httpOption = httpOptions;
                }
                return this.http.post<any>(RequestUrl, RequestObject, httpOption)
                    .pipe(
                        retry(0),
                        share(),
                        catchError(this.handleError)
                    );


            } catch (error) {
                console.log('erorr occured PosthttpMethod', error);
            }
        }

    /**
    * @method PosthttpMethod (RequestUrl: string, RequsetObject: any)
    * @param RequestUrl;
    * @description Get http request method retrive data from the server
    */
    PosthttpMethod(RequestUrl: string, RequestObject: any, Requestparams: any = null, headers: any = null,
    contentType: string = null): Observable<any> {
        try {
            let httpOption;
            RequestUrl = this.getAbsoluteApiURL(RequestUrl);
            if (Requestparams) {
                httpOptions.params = Requestparams;
                // httpOption = httpOptions;
            }            
            if (httpOptions.headers.has('oquery')) {
                httpOptions.headers = httpOptions.headers.delete('oquery');
            }
            if (httpOptions.headers.has('isactive')) {
                httpOptions.headers = httpOptions.headers.delete('isactive');
            } 
            if (headers) {
                const headerContentType = ( contentType && contentType !== null ) ? contentType :
                'application/json; odata.metadata=minimal; odata.streaming=true';
                httpOption = {
                    headers: new HttpHeaders({
                        'Content-Type': headerContentType,
                        'Accept': 'application/json',
                    }),
                    params: null,
                    responseType: 'blob',
                    observe: 'response'
                };
            } else {
                httpOption = httpOptions;
            }
            return this.http.post<any>(RequestUrl, RequestObject, httpOption)
                .pipe(
                    retry(0),
                    share(),
                    catchError(this.handleError)
                );


        } catch (error) {
            console.log('erorr occured PosthttpMethod', error);
        }
    }
    PutthttpMethod(RequestUrl: string, RequestObject: any, Requestparams: any = null, headers: any = null,
        contentType: string = null): Observable<any> {
            try {
                let httpOption;
                httpOptions.headers= this.setHeaders(RequestUrl);
                RequestUrl = this.getAbsoluteApiURL(RequestUrl);
                if (Requestparams) {
                    httpOptions.params = Requestparams;
                    // httpOption = httpOptions;
                }            
                if (httpOptions.headers.has('oquery')) {
                    httpOptions.headers = httpOptions.headers.delete('oquery');
                }
                if (httpOptions.headers.has('isactive')) {
                    httpOptions.headers = httpOptions.headers.delete('isactive');
                } 
                if (headers) {
                    const headerContentType = ( contentType && contentType !== null ) ? contentType :
                    'application/json; odata.metadata=minimal; odata.streaming=true';
                    httpOption = {
                        headers: new HttpHeaders({
                            'Content-Type': headerContentType,
                            'Accept': 'application/json',
                        }),
                        params: null,
                        responseType: 'blob',
                        observe: 'response'
                    };
                    httpOption.headers= this.setHeaders(RequestUrl);
                } else {
                    httpOption = httpOptions;
                }
                return this.http.put<any>(RequestUrl, RequestObject, httpOption)
                    .pipe(
                        retry(0),
                        share(),
                        catchError(this.handleError)
                    );
    
    
            } catch (error) {
                console.log('erorr occured PosthttpMethod', error);
            }
        }
   IntegrationPosthttpMethod(RequestUrl: string, RequestObject: any, Requestparams: any = null, headers: any = null,
        contentType: string = null): Observable<any> {
            try {
                let httpOption;
                RequestUrl = RequestUrl;
                if (Requestparams) {
                    httpOptions.params = Requestparams;
                    // httpOption = httpOptions;
                }                
                if (httpOptions.headers.has('oquery')) {
                    httpOptions.headers = httpOptions.headers.delete('oquery');
                }
                if (httpOptions.headers.has('isactive')) {
                    httpOptions.headers = httpOptions.headers.delete('isactive');
                } 
                if (headers) {
                    const headerContentType = ( contentType && contentType !== null ) ? contentType :
                    'application/json; odata.metadata=minimal; odata.streaming=true';
                    httpOption = {
                        headers: new HttpHeaders({
                            'Content-Type': headerContentType,
                            'Accept': 'application/json',
                        }),
                        params: null,
                        responseType: 'blob',
                        observe: 'response'
                    };
                } else {
                    httpOption = httpOptions;
                }
                return this.http.post<any>(RequestUrl, RequestObject, httpOption)
                    .pipe(
                        retry(0),
                        share(),
                        catchError(this.handleError)
                    );
    
    
            } catch (error) {
                console.log('erorr occured PosthttpMethod', error);
            }
        }
    PosthttpFileMethod(RequestUrl: string, RequestObject: any, Requestparams: any = null, headers: any = null,
        contentType: string = null): Observable<any> {
            try {
                // eslint-disable-next-line prefer-const
                let httpOption;
                RequestUrl = this.getAbsoluteApiURL(RequestUrl);
                if (Requestparams) {
                    httpOptions.params = Requestparams;
                }
                if (httpOptions.headers.has('oquery')) {
                    httpOptions.headers = httpOptions.headers.delete('oquery');
                }
                if (httpOptions.headers.has('isactive')) {
                    httpOptions.headers = httpOptions.headers.delete('isactive');
                } 
                return this.http.post<any>(RequestUrl, RequestObject, httpOption)
                    .pipe(
                        retry(0),
                        share(),
                        catchError(this.handleError)
                    );


            } catch (error) {
                console.log('erorr occured PosthttpMethod', error);
            }
        }
        ItemParsPosthttpFileMethod(RequestUrl: string, RequestObject: any, Requestparams: any = null, headers: any = null,
            contentType?: string): Observable<any> {
                try {
                    // eslint-disable-next-line prefer-const
                    let httpOption;
                    RequestUrl = this.getAbsoluteApiURL(RequestUrl);
                    if (Requestparams) {
                        httpOptions.params = Requestparams;
                    }                    
                    if (httpOptions.headers.has('oquery')) {
                        httpOptions.headers = httpOptions.headers.delete('oquery');
                    }
                    if (httpOptions.headers.has('isactive')) {
                        httpOptions.headers = httpOptions.headers.delete('isactive');
                    } 
                    if (headers) {
                        httpOption = {
                            // headers: new HttpHeaders({
                            //     'Accept': 'text/plain'
                            // }),
                            responseType: 'text',
                            observe: 'response' 
                        };
                    }
    
                    return this.http.post<any>(RequestUrl, RequestObject, httpOption)
                        .pipe(
                            retry(0),
                            share(),
                            catchError(this.handleError)
                        );
    
    
                } catch (error) {
                    console.log('erorr occured PosthttpMethod', error);
                }
            }
    /**
    * @method PosthttpMethod (RequestUrl: string, RequsetObject: any)
    * @param RequestUrl;
    * @description Get http request method retrive data from the server
    */
    PostTokenhttpMethod(RequestUrl: string, RequsetObject: any, clientToken: any): Observable<any> {
        try {
            RequestUrl = this.getAbsoluteApiURL(RequestUrl);
            // const httpTokenOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         'No-Auth': 'True',
            //         'odata-version': '4.0',
            //         'Accept': 'application/json',
            //         'server': 'Kestrel'
            //     })
            // };
            // RequsetObject = 'grant_type=EatecCredential&client_id=EatecClient&client_secret=SuperSecretPassword&Tenant='
            //     + this.stateService.tenant + '&clienttoken=' + encodeURIComponent(clientToken.toString());

            RequsetObject['granttype'] = 'EatecCredential';
            RequsetObject['clientid'] = 'EatecClient';
            RequsetObject['clientsecret'] = 'SuperSecretPassword';
            RequsetObject['Tenant'] = sessionStorage.getItem('TenantIdentifier');
            RequsetObject['clienttoken'] = clientToken?.toString();
            return this.http.post<any>(RequestUrl, RequsetObject)
                .pipe(
                    retry(0),
                    catchError(this.handleError)
                );

        } catch (error) {
            console.log('erorr occured PosthttpMethod', error);
        }
    }

      /**
    * @method PosthttpMethod (RequestUrl: string, RequsetObject: any)
    * @param RequestUrl;
    * @description Get http request method retrive data from the server
    */
       PostSSOTokenhttpMethod(RequestUrl: string, RequsetObject: any, clientToken: any, expiryTime: string): Observable<any> {
        try {
            RequestUrl = this.getAbsoluteApiURL(RequestUrl);
            const httpTokenOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'No-Auth': 'True',
                    'odata-version': '4.0',
                    'Accept': 'application/json',
                    'server': 'Kestrel'
                })
            };
            RequsetObject = 'grant_type=EatecCredential&client_id=EatecClient&client_secret=SuperSecretPassword&Tenant='
                + this.stateService.tenant + '&clienttoken=' + encodeURIComponent(clientToken.toString())
                + '&expirytime=' + encodeURIComponent(expiryTime);
            return this.http.post<any>(RequestUrl, RequsetObject, httpTokenOptions)
                .pipe(
                    retry(0),
                    catchError(this.handleError)
                );

        } catch (error) {
            console.log('erorr occured PosthttpMethod', error);
        }
    }


    /**
    * @functions PatchhttpMethod (RequestUrl: string, RequestId: any)
    * @param RequestUrl;
    * @description Get http request method retrive data from the server
    */
    PatchhttpMethod(RequestUrl: string, RequestData: any, SendRequestAsHeader = false): Observable<Array<any>> {
        try {
            RequestUrl = this.getAbsoluteApiURL(RequestUrl);    
            if (httpOptions.headers.has('oquery')) {
                httpOptions.headers = httpOptions.headers.delete('oquery');
            } 
            const options = httpOptions;
            if (SendRequestAsHeader) {
                if (!options.headers.has('isactive')) {
                    options.headers = options.headers.append('isactive', RequestData.IsActive.toString());
                } else {
                    options.headers = options.headers.set('isactive', RequestData.IsActive.toString());
                }
            }
            return this.http.patch<any>(RequestUrl, RequestData , options)
                .pipe(
                    retry(0),
                    catchError(this.handleError)
                );
        } catch (error) {
            console.log('erorr occured PatchhttpMethod', error);
        }
    }

    /**
  * @method PosthttpMethod (RequestUrl: string, RequsetObject: any)
  * @param RequestUrl;
  * @description Get http request method retrive data from the server
  */
    PuthttpMethod(RequestUrl: string, RequsetObject: any): Observable<any> {
        try {
            RequestUrl = this.getAbsoluteApiURL(RequestUrl);            
            if (httpOptions.headers.has('oquery')) {
                httpOptions.headers = httpOptions.headers.delete('oquery');
            }
            if (httpOptions.headers.has('isactive')) {
                httpOptions.headers = httpOptions.headers.delete('isactive');
            } 
            return this.http.put<any>(RequestUrl, RequsetObject, httpOptions)
                .pipe(
                    retry(0),
                    catchError(this.handleError)
                );

        } catch (error) {
            console.log('erorr occured PosthttpMethod', error);
        }
    }

    private handleError(error: HttpErrorResponse) {
        let errorReposnse = '';
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            errorReposnse = error.error;
        }
        // Return an observable with a user-facing error message.
        return throwError(
            errorReposnse);
    }



    private getAbsoluteApiURL(url: string) {
        const BaseUrl = this.envService.apiUrl;
        // this.replaceBaseUrl(BaseUrl, url);
        // return `${BaseUrl}${url}`;
        return this.replaceBaseUrl(BaseUrl, url);
    }
    
    replaceBaseUrl(Baseurl: string, endPointUrl: string ): string {
        try {
           let customBaseUrl = `${Baseurl}${endPointUrl}`;
           const getServiceName = endPointUrl.split('/')[1];
           const replacableText = `/${getServiceName}/`;
           const replaceServiceUrl = endPointUrl.replace(replacableText, '/');
           switch ( getServiceName.toString().toLowerCase()) {
               case 'inventorys':
                   const localInventory = 'https://localhost:44353';
                   customBaseUrl = localInventory + replaceServiceUrl;
                   break;
               case 'reportdatas':
                   const localTenantS = 'http://localhost:5000';
                   customBaseUrl = localTenantS + replaceServiceUrl;
                   break;
                case 'usermgmts':
                    const localUserMgmt = 'http://localhost:57784';
                    customBaseUrl = localUserMgmt + replaceServiceUrl;
                    break;
                default:
                customBaseUrl = `${Baseurl}${endPointUrl}`;
          }
           return customBaseUrl;
        } catch ( error ) {
            console.error(error);
        }
    }
    private setHeaders(header:any, RequestUrl?:string): HttpHeaders {
        let token = sessionStorage.getItem("_jwt");
        let userSessionId = sessionStorage.getItem('userSession'); 
        return new HttpHeaders()
            .set('Accept-Language', navigator.language)
            .set('Content-Type', 'application/json; odata.metadata=minimal; odata.streaming=true')
            .set("Authorization", token ? 'Bearer ' + token : "")
            .set("SessionId", userSessionId ? userSessionId : "")
            .set('Accept', 'application/json')
            // .set("isLoaderRequired", isLoaderRequired? "true": "false")  
            // .set("isSessionCheckNotRequired", isSessionCheckNotRequired? "true":"false")  
    }
   
}

