import { Component, Input, AfterViewChecked, ViewChild, Output, EventEmitter, OnDestroy, OnChanges, ViewEncapsulation, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HTMLDomElement, ReportAPIOptions, ReportAPIModel, ReportDownloadFormat, DownloadOptions, JasperReportDetails, ReportSelector_ } from '../report.model';
import * as saveAs from 'file-saver'
import { GolfLocalization } from '../../core/localization/golf-localization';
import { GolfUtilities } from '../../shared/utilities/golf-utilities';
import { GolfRoutes } from 'src/app/core/extensions/golf-route';
import { ReportCommunication } from 'src/app/shared/communication/services/report.service';
import { SubscriptionLike } from 'rxjs';
import { ScrollbarComponent } from 'ngx-scrollbar';
import { AlertType } from 'src/app/shared/shared-models';
import { environment } from 'src/environments/environment';
import { ReportSelectorBuilder } from '../common/report.selector';
import { JasperReportBusiness } from 'src/app/common/JaspersoftReport/jasperreport.business';
import { ReportDownloadFormatOptions } from 'src/app/common/JaspersoftReport/jasperreport.model';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { jsonpFactory } from '@angular/http/src/http_module';


export const saveFile = (blobContent: Blob, fileName: string) => {
  const blob = new Blob([blobContent], { type: 'application/octet-stream' });
  saveAs(blob, fileName);
  document.getElementById('custom-cover-spin').style.display = 'none';
};
@Component({
  selector: 'app-report',
  templateUrl: './fastreport.component.html',
  styleUrls: ['./fastreport.component.scss'],
  providers:[JasperReportBusiness],
  encapsulation: ViewEncapsulation.None
})
export class FastReportComponent implements AfterViewChecked, OnChanges, OnDestroy {
  pageNumber: number = 1;
  zoomLevel: number = 1;
  reportDomStringData: string;
  reportQuerySubscription: SubscriptionLike;
  blobSubscription: SubscriptionLike;
  floatLabel: string;
  formats: DropdownOptions[];
  downlodformat: ReportDownloadFormat;
  reportresourcepath: string ;
  constructor(private http: ReportCommunication, private _sanitizer: DomSanitizer, private localization: GolfLocalization, private utils: GolfUtilities,private jasperbusiness:JasperReportBusiness) {
    this.floatLabel = this.localization.setFloatLabel;
   }
  xhr = new XMLHttpRequest();
  activeReportURL: string;
  activeReportAPIModel: ReportAPIModel;
  activeReportCode: string;
  reportHTML: SafeHtml = "";
  reportDOM: Document;
  fileTypeDropDown: string = '';
  options: ReportAPIOptions;
  @Input('options')
  set setValue(value) {
    this.options = value;
  }
  @ViewChild('scrollable') scrollRef: ScrollbarComponent;
  @Output() disableGenerateEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Input() reportViewDisable: boolean = false;
  downloadOptions: DownloadOptions;
  @Input('downloadOptions')
   set downloadOptionValue(value){
     this.downloadOptions = value;
   }
  public pages: string[] = [];
  private currentPageNo: number = 0;
  FileTypes: any[] = [{ id: 1, value: "PDF" }, { id: 2, value: "WORD" }, { id: 3, value: "EXCEL" }, { id: 4, value: "RAWDATA" }];
  findText: string = "";
  captions: any = this.localization.captions.reports;
  ifInputShow: boolean = false;
  ifCancelBool: boolean = false;
  ispageReference: string;
  isZoomReference: string;
  ifPrintable: boolean = false;
  ifSelectPrintable: boolean = false;
  isSearchAvailable: boolean = false;
  JasperReportDetails : JasperReportDetails; 
  jreportURL : string;
  AllowJasperReport : boolean = true;
  jreportseletor : ReportSelector_[];
  reportselector : any;
  isJasperEnabled : boolean =false;


  ngOnChanges() {
    this.ifPrintable = false;
    this.ispageReference = "";
    this.isZoomReference = "";
    this.findText = '';
    this.ifCancelBool = false;
    this.ifInputShow = false;
    if (this.options != undefined) {
      if (!this.options.format || this.options.format == "HTML") {
        this.reportViewDisable = false;
        this.reportDomStringData = null;
        this.JasperReportDetails = this.GetJasperReportDetails(this.options.code);
        this.generateReportHTML();
      } else {
        this.generateAndDownloadReport();
      }
    }
  }

  private async generateReportHTML() {
    this.utils.ToggleLoader(true); 
    //before report html load
    this.resetScrollBar();
    this.resetOldReport();
    this.disableGenerateEvtEmitter.emit(true);

    let body = this.createAPIOptions(this.options, "HTML");
    this.activeReportAPIModel = body;
    //to check isjasperreport
    
    if (this.AllowJasperReport) {
      if(document.getElementById('jasperreportdata'))
      // document.getElementById('jasperreportdata').innerHTML = '';
      this.localization.decodeHTMLEntityByID('','jasperreportdata') 
   await this.jasperbusiness.sendJasperReportRequest(body,this.JasperReportDetails, environment["Report"]).then(x => {
      this.reportDomStringData = x;
      this.utils.ToggleLoader(false);
    })

    let parser = new DOMParser();
    this.reportDOM = parser.parseFromString(this.reportDomStringData, "text/html");
    // document.getElementById('jasperreportdata').innerHTML = this.reportDomStringData;
    this.localization.decodeHTMLEntityByID(this.reportDomStringData,'jasperreportdata') 
    //once html is loaded
    this.pages = [];
    this.seperatePagesForJasper();
    this.setPage(1);
    this.disableGenerateEvtEmitter.emit(false);
  }   
  else{
    this.reportDomStringData = await this.sendReportRequest(body);

    let parser = new DOMParser();
    this.reportDOM = parser.parseFromString(this.reportDomStringData, "text/html");

    //once html is loaded
    this.pages = [];
    this.separatePages();
    this.setPage(1);
    this.disableGenerateEvtEmitter.emit(false);   
  }
  this.utils.ToggleLoader(false);  
}

  private generateAndDownloadReport(): void {
    this.downloadReport(this.options.format);
  }
  downloadReportData(_eve, showPrintDialog: boolean = false) {
    this.downlodformat = this.formats.filter(x => x.id == _eve.value)[0].value;
    let downloadURL = this.GetReportAPIURL(this.downlodformat);
    this.downloadFile(downloadURL, this.downlodformat, showPrintDialog);
  }
  // Add reports below that cant be downloaded in word/excel format

  private async sendReportRequest(body:ReportAPIModel): Promise<string> {
    let response$: Promise<string> = this.http.putHTTPBlobData({
      route: environment["Report"] + '/' + GolfRoutes.GetReport,
      body: body
    }, 'text').toPromise();
    response$.catch(err => {
      this.utils.ToggleLoader(false); 
      let errorTxt: string = "";
      let errorObjStr: string = err.error;
      let errorCode = JSON.parse(errorObjStr).errorCode;
      this.disableGenerateEvtEmitter.emit(false);
      errorTxt = this.localization.getError(errorCode);
      //TO DO - need to remove common translog err
      if (errorCode.toString() == "-118" && this.options.code == "TransLog") {
        errorTxt = this.localization.getError(10723);
      }
      this.utils.showAlert(errorTxt,AlertType.Info);
    });
    this.activeReportAPIModel = body;
    return await response$;
  }

  searchContent() {
    this.ifInputShow = !this.ifInputShow;
    if (this.ifInputShow) {
//searchContent
    }
  }
  reportDownload() {
    this.formats = this.jasperbusiness.getDownloadoptions(this.AllowJasperReport);
    this.ifPrintable = !this.ifPrintable;
  }

  reportDownloadandPrint() {
    this.downloadReport('PDF', true);
  }

  private createAPIOptions(options: ReportAPIOptions, _format: ReportDownloadFormat): ReportAPIModel {

    return {
      code: options.code,
      format: _format,
      downloadFileName: "Download",
      parameters: this.arrayToObject(options.params),
      uRIParams: this.arrayToObject(options.URIParams),
      filterBody: options.filters,
      dateFormat: this.localization.dateFormat
    }    
  }

  private arrayToObject(objectArr: any[]): { [key: string]: string } {
    var result = {};
    objectArr.forEach(o => {
      result[Object.keys(o)[0]] = Object.values(o)[0];
    });
    return result;   //Dictionary<string,string>
  }

  ngOnDestroy() {
    if (this.reportQuerySubscription) {
      this.reportQuerySubscription.unsubscribe();
    }
    if (this.blobSubscription) {
      this.blobSubscription.unsubscribe();
    }
  }

  private resetOldReport(): void {
    this.reportHTML = "";
    this.pages = [];
  }

  private resetScrollBar(): void {
    if (this.scrollRef) {
      this.scrollRef.scrollToTop();
      this.scrollRef.scrollToLeft();
    }
  }

  ngAfterViewChecked() {
    setTimeout(() => {
      this.calculateWidthHeight();
    }, 1);
  }

  private separatePages(): void {
    let finalHtml: string = "";
    let bodyArr: HTMLCollectionOf<Element> = this.reportDOM.getElementsByTagName('body')[0].children;
    for (let i = 0; i < bodyArr.length; i++) {
      const element: HTMLDomElement = bodyArr[i];
      let currentHTML: string = "";
      if (element.name && element.name.includes('PageN')) {
        this.pages.push(finalHtml);
        finalHtml = "";
      } else {
        currentHTML = element.outerHTML;
        finalHtml = finalHtml + currentHTML;
      }
    }
    this.pages.push(finalHtml);
  }

  getPageNo(operation: "NEXT" | "PREVIOUS" | "FIRST" | "LAST"): number {
    this.ispageReference = operation;
    switch (operation) {
      case "NEXT":
        if (this.currentPageNo < (this.pages.length - 1)) {
          this.currentPageNo++;
        }
        break;
      case "PREVIOUS":
        if (this.currentPageNo > 1) {
          this.currentPageNo--;
        }
        break;
      case "FIRST":
        this.currentPageNo == 0;
        break;
      default:
        break;
    }
    return this.currentPageNo;
  }


  public setPage(pageNo: number): void {
    pageNo = parseInt(pageNo.toString().replace(/^0+/, ''));
    this.resetScrollBar();
    this.pageNumber = pageNo;
    if (pageNo != 0 && pageNo < this.pages.length) {
      // this.reportHTML = this._sanitizer.sanitize(SecurityContext.HTML,this._sanitizer.bypassSecurityTrustHtml(this.getPageContent(pageNo)));
      this.reportHTML = this.getPageContent(pageNo);
    } else {
      this.setPage(this.pages.length - 1);
    }
  }

  moveToNextPage(e: any): void {
    if ((this.scrollRef['_trackTopMax'] && this.scrollRef['_currYPos']) >= 0) {
      const scrollyPosition = Math.ceil(this.scrollRef['_currYPos']);
      const topMax = Math.ceil(this.scrollRef['_trackTopMax']);
      if (scrollyPosition >= topMax && (e.wheelDeltaY < 0)) {
        if (this.pageNumber < this.pages.length - 1) {
          this.setPage(this.getPageNo('NEXT'));
          this.scrollRef.scrollToTop();
        }
      } else if (this.scrollRef['_currYPos'] == 0 && (e.wheelDeltaY > 0)) {
        if (this.pageNumber != 1) {
          this.setPage(this.getPageNo('PREVIOUS'));
          this.scrollRef.scrollToBottom();
        }
      }
    }
  }

  downloadReport(format: ReportDownloadFormat, showPrintDialog: boolean = false): void {
    let downloadURL = this.GetReportAPIURL(format);
    this.downloadFile(downloadURL, format, showPrintDialog);
  }

  GetReportAPIURL(format: ReportDownloadFormat) {
    if (!this.AllowJasperReport) {
      return environment["Report"] + GolfRoutes.GetReport;
    }
  }


  private downloadFile(url: any, type: ReportDownloadFormat, showPrintDialog: boolean): void {
    this.utils.ToggleLoader(true);
    let fileName: string;
    if (this.AllowJasperReport) {
      fileName = this.jasperbusiness.GetFileNameWithExtensionSUffixDate(type, this.activeReportAPIModel.code);
    }
    else {
      let currentDate: Date = this.localization.getCurrentDate();
      let dateTime: string = currentDate.toLocaleDateString(this.localization.localeCode);
      let downloadSuffixDate: string = dateTime.replace(/\D/g, '');
      document.getElementById('custom-cover-spin').style.display = 'block'
      if (type == "PDF") {
        fileName = `${this.activeReportAPIModel.code}_${downloadSuffixDate}.pdf`
      }
      else if (type == "WORD") {
        fileName = `${this.activeReportAPIModel.code}_${downloadSuffixDate}.docx`
      } else if (type == "EXCEL" || type == "RAWDATA") {
        fileName = `${this.activeReportAPIModel.code}_${downloadSuffixDate}.xlsx`
      } else if (type == "IMAGE") {
        fileName = `${this.activeReportAPIModel.code}_${downloadSuffixDate}.jpeg`
      }
    }
    this.activeReportAPIModel.format = type;

    if (!this.AllowJasperReport) {
      this.blobSubscription = this.http.putHTTPBlobData({
        route: environment["Report"] + '/' + GolfRoutes.GetReport,
        body: this.activeReportAPIModel
      }, 'blob').subscribe(res => {
        this.ShowOrSaveFile(res,fileName,showPrintDialog)
      })
    }
    else {
      this.jasperbusiness.sendJasperReportRequest(this.activeReportAPIModel,this.JasperReportDetails, environment["Report"]).then(res=>{
        this.ShowOrSaveFile(res,fileName,showPrintDialog)
      })
    }
  }

  ShowOrSaveFile(reportblobData:Blob,fileName:string,showPrintDialog){
    this.utils.ToggleLoader(false);
    if (showPrintDialog) {
      this.showFile(reportblobData);
    } else {
      saveFile(reportblobData, fileName);
    }
  }

  private showFile(blob) {
    var newBlob = new Blob([blob], { type: "application/pdf" })
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(newBlob);
      return;
    }
    const data = window.URL.createObjectURL(newBlob);
    let newTab: Window = window.open(data);
    newTab.focus();
    newTab.print();
    document.getElementById('custom-cover-spin').style.display = 'none'
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }


  zoom(action: "IN" | "OUT"): void {
    if(this.AllowJasperReport!=true){
      this.isZoomReference = action;
      if (action == 'IN') {
        this.zoomLevel >= 1 && this.zoomLevel <= 4 ? this.zoomLevel += 0.5 : this.zoomLevel;
        document.getElementById("reportData").style['zoom'] = this.zoomLevel + "";
      }
      else if (action == 'OUT') {
        this.zoomLevel > 1 ? this.zoomLevel -= 0.5 : this.zoomLevel;
        document.getElementById("reportData").style['zoom'] = this.zoomLevel + "";
      }
    }
    else{
      if (action == 'IN') {
        if (this.zoomLevel >= 1.3 && this.zoomLevel <= 4.3) this.zoomLevel += 0.5;
        // @ts-ignore
        document.getElementById("reportData").style.zoom = this.zoomLevel + "";
      }
      else if (action == 'OUT') {
        if (this.zoomLevel > 1.3) this.zoomLevel -= 0.5;
        // @ts-ignore
        document.getElementById("reportData").style.zoom = this.zoomLevel + "";
      }
    }
  }

  print(): boolean {
    let mywindow = window.open('', 'PRINT');
    mywindow.document.write(this.reportDomStringData);
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();
    return true;
  }

  searchtext(searchdata: string): void {
    let content = document.getElementById('reportData').innerHTML;
    let regex = new RegExp("<span style=\"background-color:yellow\">", "gi");
    content = content.replace(regex, "");
    regex = new RegExp("</span>", "gi");
    content = content.replace(regex, "");
    if (searchdata) {
      this.ifCancelBool = true;
      regex = new RegExp(searchdata, "gi");
      content = content.replace(regex, "<span style='background-color:yellow'>" + searchdata + "</span>");
      // document.getElementById('reportData').innerHTML = content;
      this.localization.decodeHTMLEntityByID(content,'reportData') 
    }
    else {
      this.findText = '';
      this.ifCancelBool = false;
      // document.getElementById('reportData').innerHTML = content;
      this.localization.decodeHTMLEntityByID(content,'reportData') 
    }
  }

  private getPageContent(pageNo: number): string {
    let _allStylesContent: string = this.extractAllStyles();
    let _headerHTMLContent: string = '<html><body>' + (this.AllowJasperReport? '' : this.pages[0]) + _allStylesContent;
    let _currentContent: string = "";
    _currentContent = this.AllowJasperReport ? this.pages[pageNo - 1] : this.pages[pageNo];
    this.currentPageNo = pageNo;
    return _headerHTMLContent + _currentContent + '</body></html>';
  }

  private extractAllStyles(): string {
    let _allStylesFromDom: any = this.reportDOM.getElementsByTagName('style');
    let _concatedstyles: string = "";
    for (let i = 0; i < _allStylesFromDom.length; i++) {
      const element = _allStylesFromDom[i].outerHTML;
      _concatedstyles = _concatedstyles + element;
    }
    return _concatedstyles;
  }

  calculateWidthHeight(): void {
      if(this.AllowJasperReport){
        if(document.getElementById('JR_PAGE_ANCHOR_0_1')){
          let reportWidth = document.getElementById('JR_PAGE_ANCHOR_0_1');
          if (reportWidth) {
            let dataWidth = reportWidth.style.cssText.split(";")[1].split(":")[1];
            let reportwidth = Number(dataWidth.replace(" ","").replace('px',''))
            if(reportWidth.closest("#textcenter-Web"))
              reportWidth.closest("#textcenter-Web")['style']['width'] = reportwidth<600? reportwidth+ 203 + 'px':reportwidth+ 300 +'px';
          }
        }
      }
      else{
        let reportWidth = document.getElementsByClassName('frpage')[0];
        if (reportWidth) {
          let dataWidth = reportWidth['offsetWidth'];
          reportWidth.closest("#textcenter-Web")['style']['width'] = dataWidth + 77 + 'px';
        }
    }
  }

  validatePage(pageNo){
    if(pageNo != ''){
      this.setPage(pageNo);
    }
  }
  defaultFirstSelection(pageNo){
    if(pageNo == ''){
      this.pageNumber = 1;
      this.reportHTML = this.getPageContent(this.pageNumber);
    }
  }
//#region Jaspersoft Changes
getReportsSelectorData()
{
  this.reportselector = new ReportSelectorBuilder(this.localization);
    this.jreportseletor = this.reportselector.getAllReportSelections().filter(x=>x.enableJasperReports);
}
GetJasperReportDetails(reportcode:string): JasperReportDetails{
  this.AllowJasperReport = false;
 if (this.jreportseletor == undefined || this.jreportseletor.length == 0) 
 {
   this.getReportsSelectorData();
 }
 let data = this.jreportseletor.find((x) => x.code.toString() == reportcode);
 this.isJasperEnabled = JSON.parse(sessionStorage.propConfig).EnableJasperReports == 'true' ? true : false;
 if(data!=undefined || data!=null){
  this.zoomLevel = 1.3;
  this.AllowJasperReport = true && this.isJasperEnabled;
  var selectedreports =  {
    reportCode : data.code,
    isJaspersoftReport : data.enableJasperReports,
    reportUrlPath : data.reportUrlpath
    } as JasperReportDetails
    this.jreportURL = selectedreports.reportUrlPath
  return selectedreports;
 }
 else{
  this.zoomLevel = 1;
  return ;
 }

}

seperatePagesForJasper(): void {
  let finalHtml: string = "";
  let i = 1;
  while(1){
    const element: HTMLDomElement = document.getElementById('JR_PAGE_ANCHOR_0_' + i);
    let currentHTML: string = "";
    currentHTML = element.outerHTML;
    finalHtml = finalHtml + currentHTML;
    this.pages.push(finalHtml);
    finalHtml = '';
    i++;
    if(!document.getElementById('JR_PAGE_ANCHOR_0_' + i)){
      break;
    }
  }
  this.pages.push(finalHtml);
}


//#endregion
}
