<div class="vendor-container create-retail-vendor-wrapper h-100 newVersionWrapper">
    <form class="d-flex" [formGroup]="vendorForm" autocomplete="off">
        <div class="left-section">
            <ng-scrollbar [autoHide]="true">
                <div class="p-4 retail-vendor-container">
                    <div class="d-flex">
                        <div class="ag_form-control--sm pr-3">
                            <label class="d-block LW12 label-color ag_form--label">{{captions.lbl_autoGenerateNumber}}</label>
                            <app-retail-toggle-switch [attr.automationId]="'Txt_createRetailVendorSetup_autoGenerateNo'" formControlName="autoGenerateNumber" [disableToggle]='isEditFlag'
                                (changeToggleEvent)="toggleChange($event)"></app-retail-toggle-switch>
                        </div>
                        <mat-form-field class="ag_form-control--sm vendorType" [floatLabel]="floatLabel">
                            <mat-select [attr.automationId]="'Dd_createRetailVendorSetup_description'" [placeholder]="captions.lbl_vendorType"  formControlName="vendorType">
                                <mat-option [title]="type.description"  *ngFor='let type of vendorTypes' [value]="type.id">{{type.description}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                {{captions.err_missing_vendorType}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="mt-2">
                        <mat-form-field class="ag_form-control--sm pr-3" [floatLabel]="floatLabel">
                            <input [attr.automationId]="'Txt_createRetailVendorSetup_vendorCode'" matInput [placeholder]="captions.lbl_vendorCode" required="true" inputtype="nospecailchar,notallowspace"
                                [minLength]="3" [maxLength]="15" formControlName="vendorCode">
                                <mat-error *ngIf="vendorForm.get('vendorCode').hasError('required')">
                                    {{captions.err_missing_vendorCode}}
                                </mat-error>
                                <mat-error *ngIf="vendorForm.get('vendorCode').hasError('pattern')">
                                    {{captions.err_minLength}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="d-flex">
                        <mat-form-field class="ag_form-control--sm pr-3" [floatLabel]="floatLabel">
                            <input [attr.automationId]="'Txt_createRetailVendorSetup_vendorName'" matInput [placeholder]="captions.lbl_vendorName" required="true" inputtype="noprespace" (blur)="vendorNameOnBlurMethod()" [minLength]="3" [maxLength]="50"
                                formControlName="vendorName">
                            <mat-error *ngIf="vendorForm.get('vendorName').hasError('required')">
                                {{captions.err_missing_vendorName}}
                            </mat-error>
                            <mat-error *ngIf="vendorForm.get('vendorName').hasError('pattern')">
                                {{captions.err_minLength}}
                            </mat-error>
                        </mat-form-field>
                        <div class="ag_form-control--sm">
                            <label class="d-block LW12 label-color ag_form--label">{{captions.lbl_active}}</label>
                            <app-retail-toggle-switch [attr.automationId]="'Txt_createRetailVendorSetup_active'" formControlName="active"></app-retail-toggle-switch>
                        </div>
                    </div>
                    <div>
                        <h4 class="LW14">{{captions.hdr_vendorRepresentative}}</h4>
                        <mat-form-field class="ag_form-control--sm pr-3" [floatLabel]="floatLabel">
                            <input [attr.automationId]="'Txt_createRetailVendorSetup_firstName'" matInput [placeholder]="captions.lbl_firstName"   inputtype="nospecailchar,noprespace"
                                [minLength]="3" [maxLength]="50" formControlName="firstName">
                            <mat-error *ngIf="vendorForm.get('firstName').hasError('required')">
                                {{captions.err_missing_firstName}}
                            </mat-error>
                            <mat-error *ngIf="vendorForm.get('firstName').hasError('pattern')">
                                {{captions.err_minLength}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="ag_form-control--sm" [floatLabel]="floatLabel">
                            <input [attr.automationId]="'Txt_createRetailVendorSetup_lastName'" matInput [placeholder]="captions.lbl_lastName"  inputtype="nospecailchar,noprespace"
                                [minLength]="3" [maxLength]="50" formControlName="lastName">
                            <mat-error *ngIf="vendorForm.get('lastName').hasError('required')">
                                {{captions.err_missing_lastName}}
                            </mat-error>
                            <mat-error *ngIf="vendorForm.get('lastName').hasError('pattern')">
                                {{captions.err_minLength}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <app-agys-address [attr.automationId]="'Txt_createRetailVendorSetup_address'" [inputs]="addressInput" [values]="addressValue" [isViewOnly]="isViewOnly"
                            (onAddressChange)="addresschange($event)">
                        </app-agys-address>
                    </div>

                    <div>
                        <mat-form-field class="ag_form-control--sm pr-3" [floatLabel]="floatLabel">
                            <input matInput [attr.automationId]="'Txt_createRetailVendorSetup_city'"  [placeholder]="captions.lbl_city" [minlength]="1" [maxlength]="25"
                                name="city" formControlName="city">
                        </mat-form-field>
                        <mat-form-field class="ag_form-control--sm" [floatLabel]="floatLabel">
                            <input matInput [attr.automationId]="'Txt_createRetailVendorSetup_state'"  [placeholder]="captions.lbl_state" [minlength]="2"
                                [maxlength]="25" name="state" formControlName="state">
                             <mat-error *ngIf="vendorForm.get('state').hasError('pattern')">
                                 {{captions.Atleast2charactersneedtobeentered}}
                             </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                      <mat-form-field class="ag_form-control--sm pr-3" [floatLabel]="floatLabel">
                        <input matInput formControlName="country" [attr.automationId]="'Txt_createRetailVendorSetup_country'" [matAutocomplete]="auto"
                          [placeholder]="captions.lbl_country" autocomplete="off" [minlength]="2" [maxlength]="25"
                          name="Country">
                        <mat-error *ngIf="vendorForm.get('country').hasError('pattern')">
                          {{captions.Atleast2charactersneedtobeentered}}
                        </mat-error>
                        <mat-autocomplete #auto="matAutocomplete">
                          <mat-option *ngFor="let country of filteredCountries | async" [value]="country.CountryName" [matTooltip]="country.CountryName">
                            <span>{{country.CountryName}}</span>
                          </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="!vendorForm.get('country').value && vendorForm.get('country').hasError('required')">
                            {{captions.missingCountryName}}
                          </mat-error>
                          <mat-error *ngIf="vendorForm.get('country').value && vendorForm.get('country').hasError('invalid')">
                            {{captions.invalidCountryName}}
                          </mat-error>
                      </mat-form-field>

                      <mat-form-field class="ag_form-control--sm" [floatLabel]="floatLabel">
                            <input [attr.automationId]="'Txt_createRetailVendorSetup_postalCode'" matInput inputtype="nospecailchar,notallowspace"
                                [placeholder]="captions.lbl_postalCode" name="postalCode" [minlength]="4"
                                [maxlength]="12" formControlName="postal_code">
                             <mat-error *ngIf="vendorForm.get('postal_code').hasError('pattern')">
                                 {{MaximumPostalLengthError}}
                             </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <app-agys-phone-number [attr.automationId]="'Txt_createRetailVendorSetup_phoneNo'"  [inputs]="phoneInputs" [values]="phoneInfo" [isViewOnly]="isViewOnly">
                        </app-agys-phone-number>
                    </div>
                    <div>
                        <app-agys-mail-id [attr.automationId]="'Txt_createRetailVendorSetup_mailInfo'" [inputs]="mailInputs" [values]="mailInfo" [isViewOnly]="isViewOnly">
                        </app-agys-mail-id>
                    </div>
                </div>
            </ng-scrollbar>
        </div>
        <div class="right-section">
            <mat-form-field class="w-100 txt-area-height" [floatLabel]="floatLabel">
                <textarea [attr.automationId]="'Ta_createRetailVendorSetup_comments'" matInput [placeholder]="captions.lbl_comments" [maxLength]="255"
                    formControlName="comments"></textarea>
            </mat-form-field>
            <mat-form-field class="w-100 pt-3" [floatLabel]="floatLabel">
                <input [attr.automationId]="'Txt_createRetailVendorSetup_terms'" matInput [placeholder]="captions.lbl_terms" [maxLength]="15" inputtype="onlynumber,nodecimal" formControlName="terms">
            </mat-form-field>
        </div>
        <input [attr.automationId]="'Txt_createRetailVendorSetup_save'" type="submit"  [disabled]="isSaveDisabled" class="hidden-submit"
        tabindex="-1"  (click)="onSave($event)"/>
    </form>
    <mat-dialog-actions class="dialog_actions_style">
        <div class="pop-button">
            <button [attr.automationId]="'Btn_createRetailVendorSetup_save'" mat-button class="ml-4" [disabled]="isSaveDisabled"
                [ngClass]="isSaveDisabled ? 'spa-primary-disabled' : 'spa-primary-btn-default'"
                (click)="onSave($event)">{{buttonText}}</button>
            <button [attr.automationId]="'Btn_createRetailVendorSetup_cancel'" mat-button class="spa-button-cancel ml-2"
                (click)="onCancel($event)">{{captions.lbl_cancelText}}</button>
        </div>
    </mat-dialog-actions>
</div>
