import { Injectable } from '@angular/core'; 
import { TeeTimeService } from '../teetime/tee-time.service';
import { notifyService } from './notify.service';
import { PlayersWithContactInformation, EformLinkMapping } from './notify.model';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { ContactDetails } from 'src/app/shared/shared-models';


@Injectable()

export class notifyBusinessService {


    constructor(public _teeTimeService : TeeTimeService, public _notifyService : notifyService, private _utilities : GolfUtilities) {}

    public GetTeeTimeByScheduleId(teeTimeId: number) : any {
        return this._teeTimeService.GetTeeTimeByScheduleId(teeTimeId);
    }

    public GetContactInfo(playerIds: number[]) : Promise<ContactDetails[]> {
        return this._teeTimeService.GetContactInformationByPlayerIds(playerIds);
    }

    public sendNotification(players : any[], enableSendEmail : boolean, enableSendSMS : boolean, bSaveOrUpdateContact : boolean, action : string, eformLinkList: EformLinkMapping[], canSendAuthorize: boolean = false) {
        let playersToNotify = this.mapToAPI(players, enableSendEmail, enableSendSMS, eformLinkList);
        return this._notifyService.sendNotification(playersToNotify,enableSendEmail,enableSendSMS, bSaveOrUpdateContact, action, canSendAuthorize);
    }

    public mapToAPI(players: any[] , enableSendEmail : boolean, enableSendSMS : boolean, eformLinkList:EformLinkMapping[] ) : PlayersWithContactInformation[]{

        return players.map(r=> {
            return {
                playerId: r.id,
                action: r.action,
                scheduledTeeTime : null,
                cancelTeeTimePlayer : null,
                emailAddress : (enableSendEmail) ? [r.emailId] : null,
                phoneNumber : (enableSendSMS) ?  [this._utilities.getNumberString(r.countryCode) + "|" + this._utilities.getNumberString(r.phoneNo)] :null ,// Need to add country code with pipe
                eformLinks: eformLinkList
            }
            });          
    }
}
