<div class="move-players--wrapper golf-container--padding newVersionWrapper" #infoElement>
  <div class="notification-message" [ngClass]="{'bulk-on': teeTimeFormat == 2}" *ngIf="notificationFlag">
    <app-note [noteMessage]="notificationbar" (removeNotification)="removeNotification($event)"></app-note>
  </div>
  <app-players-info [playerInputData]="playerDetails"></app-players-info>

  <div class="message-notification" *ngIf="notifierBar && notifierBar.value.length>0">
    <app-note [noteMessage]="notifierBar">
    </app-note>
</div>

  <div class="move-players-form--wrapper">
    <form [formGroup]="movePlayersForm" >
      <app-golf-date-picker [inputs]="dateInputs" (datePickerChange)="dateChanged($event)"></app-golf-date-picker>
      <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--sm">
        <mat-select [attr.automationId]="'Dd_MovePlayer_course'" placeholder="{{captions.course}}" name="Course" formControlName="courseControl" [matTooltip]="courseTooltip" (selectionChange) ="courseChanged($event.value)" >
          <mat-option *ngFor="let course of courses | async" [value]="course.id">
            {{course.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <app-players-list [automationId]="'MovePlayer'"  class="app-players-list" [playersList]="playerList" [ngClass]="{'info-strip':infoStripAvailable}" [action] ="action" *ngIf="teeTimeFormat !== bulkTee" [teeSheetID]="teeSheetID" [customTableData]="customTableData" [playerCollectionList]="playerCollectionList" (onDragPlayers)="onDragPlayers($event)" (IsBulk)="CanChangeBulkView($event)" [normalPlayerUserAccess]="normalPlayerUserAccess"
  [bulkPlayerUserAccess]="bulkPlayerUserAccess" [mixedPlayerSlot] = "mixedPlayerSlot" (onSelectAll)="selectAll($event)">
  </app-players-list>
  <div class="golf-tee-sheet--wrapper" id={{teeSheetID?.tableID}} [ngClass]="notificationFlag? 'notification-on': ''">
    <app-bulk-player-list [automationId]="'MovePlayer'"  class="app-bulk-player-list" [ngClass]="{'golf-section__disable': isBulkSectionDisabled,'info-strip-grid': infoStripAvailable}" *ngIf="teeTimeFormat == 2" [teeSheetID]="teeSheetID" [customTableData]="customTableData" [isAutoBookAvailable]="isAutoBookAvailable" [playersList]="playerList" [playerCollectionList]="playerCollectionList" (onDragPlayers)="onDragPlayers($event)"></app-bulk-player-list>
    <app-tee-grid class="app-tee-grid" [ngClass]="{'bulk-teesheet': teeTimeFormat == 2, 'info-strip-grid': infoStripAvailable, 'has-no-player': playerList?.length == 0}" [teeSheetID]="teeSheetID" [customTableData]="customTableData" [teeData]="skeletonData | async" [isFromMove]="isFromMove"
       (moreEvent)="onmoreEvent($event)" (dragDropEvent)="onDragDrop($event)" (onDragGridPlayers)="onDragGridPlayers($event)"
      (onRemovePlayer)="onRemovePlayer($event)" [courseId] = "courseId" (onPlayerCollectionListEmit)="onPlayerListEmit($event)"></app-tee-grid>
  </div>
</div>
