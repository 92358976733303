import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicFormBuilderComponent } from './dynamic-forms.component';
import { FieldBuilderComponent } from './field-builder/field-builder.component';
import { TextBoxComponent } from './atoms/textbox/textbox.component';
import { DropDownComponent } from './atoms/dropdown/dropdown.component';
import { GolfCheckboxComponent } from './atoms/checkbox/check-box.component';
import { RadioComponent } from './atoms/radio/radio.component';
import { FileComponent } from './atoms/files/files.component';
import { MaterialModule } from '../material-module';
import { TextAreaComponent } from './atoms/text-area/text-area.component';
import { MultipleFieldComponent } from './atoms/multiple-field/multiple-field.component';
import { FormIconsComponent } from './actions/form-icons/form-icons.component';
import { DatePickerComponent } from './atoms/date-picker/date-picker.component';
import { ToggleGroupComponent } from './atoms/toggle-group/toggle-group.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { SwitchComponent } from './atoms/switch/switch.component';
import { GroupedItemsComponent } from './atoms/grouped-items/grouped-items.component';
import { TimePickerComponent } from './atoms/time-picker/time-picker.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SearchBoxComponent } from './atoms/search-box/search-box.component';
import { GroupedIteampipe } from './pipe/grouped-class.pipe';
import { AdDirective } from './directives/agi-ad.directive';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    UiSwitchModule,
    NgxMaterialTimepickerModule,
    SharedModule
  ],
  declarations: [
    DynamicFormBuilderComponent,
    FieldBuilderComponent,
    TextBoxComponent,
    DropDownComponent,
    GolfCheckboxComponent,
    FileComponent,
    RadioComponent,
    TextAreaComponent,
    MultipleFieldComponent,
    FormIconsComponent,
    DatePickerComponent,
    ToggleGroupComponent,
    SwitchComponent,
    FormIconsComponent,
    GroupedItemsComponent,
    TimePickerComponent,
    SearchBoxComponent,
    GroupedIteampipe,
    AdDirective
  ],
  exports: [DynamicFormBuilderComponent, FormIconsComponent],
  providers: []
})
export class DynamicFormBuilderModule { }
