<div class="dialog-container">
    <app-golfdialog-header [automationId]="automationId" [title]="dialogData.title" (closeDialog)="closeHandler($event)"></app-golfdialog-header>
    <div class="dialog-container__content">
      <div [formGroup]="emailsmsForm" class="email-sms-wrapper">
        <div>
           
            <mat-form-field class="w-90" [floatLabel]="floatLabel">
                <input  attr.automationId='Txt_{{automationId}}_email' matInput golfinputtype="email" [placeholder]="captions.Email" formControlName="emailId"
                    maxlength="75">
                <mat-error class=""
                    *ngIf="emailsmsForm.controls['emailId'].touched && !emailsmsForm.controls['emailId'].valid">
                    {{captions.InvalidEmailFormat}}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-25 mr-1" [floatLabel]="floatLabel">
              <span *ngIf="CountryCodeRef.value" class="position-absolute">+</span>
             <input attr.automationId='Txt_{{automationId}}_countryCode' matInput [ngClass]="{'countryCodeInput': CountryCodeRef.value}" name="countryCode" formControlName="countryCode" [placeholder]="captions.CountryCode"  [maxlength]="3" golfinputtype='nonnegative' (input)="setmandatoryField($event,'countryCode','phoneNo',emailsmsForm)" #CountryCodeRef>
             <mat-error  *ngIf="emailsmsForm.get('countryCode').hasError('required')"  >
              {{captions.Missing}} {{captions.CountryCode}}
            </mat-error>
            </mat-form-field>
             <mat-form-field class="w-66" [floatLabel]="floatLabel">
             <input attr.automationId='Txt_{{automationId}}_phoneNo' matInput [placeholder]="captions.Phone" formControlName="phoneNo"  [textmask]="textMaskPhone" (input)="setmandatoryField($event,'phoneNo','countryCode',emailsmsForm)">
             <mat-error  *ngIf="emailsmsForm.get('phoneNo').hasError('required')"  >
              {{captions.Missing}} {{captions.Phone}}
            </mat-error>
            </mat-form-field>

        </div>        
      </div>    
    </div>
    <app-golfdialog-footer [automationId]="automationId"  [buttonObj]="buttonObj" (save)="saveHandler($event)" (cancel)="closeHandler($event)" [clickThrottleTime]=1>
    </app-golfdialog-footer>
  </div>
  