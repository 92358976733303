import { ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { GuestMemberPayeeInfo, MemberBusinessService, MemberPayeeInfo } from '../../shared/business/Member-business.service';
import { ButtonOptions, ButtonType, Product } from '../../shared/globalsContant';
import { AlertType, MemberDiscountedItems, RetailItemType, SearchType, SelectedProducts } from '../../shared/shared.modal';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import * as _ from 'lodash';
import { PayeeInfo, PaymentMethods } from '../../shared/business/shared.modals';
import { CommonVariablesService, Ticket } from '../../shared/service/common-variables.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { PaymentHistory } from '../../shared/service/payment/payment-business.model';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { TransactionEngineBusiness } from '../../retail-transaction-engine/transaction-engine-business';
import { ApplyDiscountService } from '../../shop/apply-discount/appply-discount.service';
@Component({
  selector: 'app-member-selection-popup',
  templateUrl: './member-selection-popup.component.html',
  styleUrls: ['./member-selection-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MemberSelectionPopupComponent implements OnInit {

  popupInput: any;
  shopCaption: any;
  caption: any;
  memberSearchText = "";
  memberSearchResult = [];
  selectedMemberFromSearch = [];
  selectedMemberList = [];
  remainingAmount = 0;
  amountEntered = 0;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = false;
  separatorKeysCodes = [ENTER];
  subscription: Subscription;
  searchTextChanged = new Subject<string>();
  memberSelectionForm: UntypedFormGroup;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  tempRemainingAmt: number;
  _memberService:MemberBusinessService;
  isDragging: boolean = false;
  memberAssociatedItems = [];
  memberItemsList = [];
  selectedItemsList: any = [];
  floatLabel: string;
  currentMemberIndex = 0;
  memberMappedItemDrag: boolean = false;
  currentMember: any;
  currentFormGrp: any;
  showAvailableRounds: boolean = false;
  originalSelectedProducts : SelectedProducts[] = [];
  originalTicket : Ticket = null; 
  searchLength = 3;

  get enableSave() {
    if(!this.IsTeeTimePayment){
      return this.memberSelectionForm.valid && this.popupInput?.members?.some(x => x.selected) && (this.amountEntered > 0 
      || (this.popupInput.totalAmount == 0 && this.memberSelectionForm.value.memberSelectionArr.some( x => x.memberSelection == true))
      || (this.popupInput.members.some(x => x.associatedItems.length > 0))) ? true : false;
    }
    return (this.popupInput?.members?.some(m => m.selected) ? this.memberSelectionForm.valid : false);
  }
  availableGuestMember:GuestMemberPayeeInfo[] = [];
  @ViewChild('memberSearch') memberSearchEle: ElementRef;
  get IsPinRequired() {
    return this._memberService?.RequirePin;
  }
  get IsDeposit() {
    return ((this._ss.isFromDeposit || this._ss.depositFlag) && this._ss.selectedProducts.some(p => p.payeeId));
  }
  get IsTeeTimePayment() {
    return ((this._ss.isFromEditTeeTime || this._ss.isFromUnpaidPlayer) && this._ss.selectedProducts.some(p => p.payeeId))
  }
  get IsAppointmentPayment() {
    return (this._ss.isFromAppointment && this._ss.selectedProducts.some(p => p.payeeId))
  }
  get IsAllItemsSelected() {
    return this.retailItems.every(x => x.selected)
  }
  @ViewChild('trigger', { read: MatAutocompleteTrigger })
  autoTrigger: MatAutocompleteTrigger;
  retailItems: SelectedProducts[] = [];
  settlementHistory: PaymentHistory[] = [];
  dragDropConnectedList = [];
  activeDiscountReasons = [];
  miscSettings = [];
  applyMemberDiscountAutomatically : boolean = false;
  overrideWithMemberDiscount : boolean = false;
  memberDiscountedItems : MemberDiscountedItems[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any
    , private dialogRef: MatDialogRef<MemberSelectionPopupComponent>
    , public localization: RetailLocalization
    , private _utils: RetailUtilities
    , private fb: UntypedFormBuilder
    , private _ss: CommonVariablesService
    , private _propInfoService: RetailPropertyInformation
    , private transactionEngineBusiness: TransactionEngineBusiness
    , private applyDiscountService: ApplyDiscountService,
  ) {
    this.floatLabel = this.localization.setFloatLabel;
    this.popupInput = data.datarecord;
    this._memberService = data.datarecord.scope;
    this.caption = localization.captions;
    this.shopCaption = localization.captions.shop;
    this.remainingAmount = this.popupInput.remainingAmount;
    this.amountEntered = this.popupInput.amountEntered;
    this.settlementHistory = data.datarecord.settlementHistory;
    this.memberSelectionForm = this.fb.group({
      memberSearch: "",
      memberSelectionArr: this.fb.array([
        this.fb.group({
          memberSelection: false,
          memberAmount: 0,
          memberPin: ""
        })
      ])
    });
    this.showAvailableRounds = this._ss.ProductId == Product.GOLF ? true : false;
    this.InitializeMemberFormsArray();
    this.initializeSearchSubscription();  
    this.AutomaticMemberDiscount();     
  }

  ngOnInit(): void { 
    this.GetMemberDetails();
  }

  async AutomaticMemberDiscount() {
    try {
      const memberConfig = this._propInfoService.getMemberConfig;
      this.applyMemberDiscountAutomatically = memberConfig?.applyMemberDiscountAutomatically?.toLowerCase() === 'true' ?? false;
      this.overrideWithMemberDiscount = memberConfig?.overrideWithMemberDiscounts?.toLowerCase() === 'true' ?? false;
      if (this.applyMemberDiscountAutomatically) {
        this.originalSelectedProducts = _.cloneDeep(this._ss.selectedProducts);
        this.originalTicket = _.cloneDeep(this._ss.Ticket);
        this.activeDiscountReasons = await this.applyDiscountService.getActiveDiscountReasons();
        this.miscSettings = await this._ss.storeMiscSetting;
      }
    }
    catch (error) {
      console.log("AutomaticMemberDiscount - Error in getting member discount configurations, error :" + error);
    }
  }

  ngAfterViewInit() {    
  }

  InitializeMemberFormsArray() {
    this.availableGuestMember = this.popupInput.guests;
    let frmGrp = [], ind = 0;
    this.popupInput.members.map(m => {
      frmGrp.push(this.createMemberGrp(m));      
      ind++;
    });    
    this.memberSelectionForm = this.fb.group({
      memberSearch: "",
      memberSelectionArr: this.fb.array(frmGrp)
    });
    this.memberSelectionForm.controls.memberSelectionArr.valueChanges.subscribe(x=>{
      this.memberSelectionForm.updateValueAndValidity();
    }); 
    this.memberSelectionForm.controls.memberSearch.valueChanges.subscribe(x =>
      this.searchEvent(x)
    );
    this.retailItems = this._ss.selectedProducts.filter(p => !p.payeeId && !p.isReturn);
    this.setItemTax();    
  }

  createMemberGrp(member) {
    const memAmt = !this.popupInput?.allowAmountTender ? member.memberAmount : 0;
    let IsPinValid = this.IsPinRequired;
    return this.fb.group({
      memberSelection: member.selected,
      memberAmount: [{ value: this.localization.localizeCurrency(memAmt, false), disabled: !this.popupInput?.allowAmountTender }, [Validators.required]],
      memberPin: ["", IsPinValid ? Validators.required : null]
    })
  }

  initializeSearchSubscription() {
    this.subscription = this.searchTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      takeUntil(this.destroyed$)).subscribe(searchKey => {
        if (searchKey && searchKey.length >= 3)
          this.memberSearch(searchKey);
        else {
          this.memberSearchResult = [];
        }
      });
  }
  
  drop(event: CdkDragDrop<string[]>, member, memberFormGrp) {
    if (member.disabled) return;
    let discountedAmount : number = 0;
    let memDiscountItems : any[] = this.memberDiscountedItems.find(x => x.memberCardNumber == member.memberCardNumber)?.retailItems;
    if (this.applyMemberDiscountAutomatically) {
      this.retailItems.forEach(item => {
        if (item.selected) {
          let memDiscountItem = memDiscountItems?.find(x => x.ItemId == item.ItemId);
          if (memDiscountItem) {
            discountedAmount = discountedAmount + (item.SalesPrice - memDiscountItem.SalesPrice);
            item.Discount = memDiscountItem.Discount;
            item.DiscountPercentage = memDiscountItem.DiscountPercentage;
            item.DiscountTypeId = memDiscountItem.DiscountTypeId;
            item.SalesPrice = memDiscountItem.SalesPrice;
          }
        }
      });
    }
    if (this.retailItems.filter(r => r.selected)?.length == 1 || this.memberMappedItemDrag) {
      if (this.memberMappedItemDrag) {
        this.CalcRemainingAndUpdateFieldValidation(this.currentMember, this.currentFormGrp);
      }
      
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
        );
        if (this.applyMemberDiscountAutomatically && this.memberMappedItemDrag) {  
          let currentMemberItems = this.popupInput.members.find(x => x.memberCardNumber === member.memberCardNumber).associatedItems;
          currentMemberItems.forEach(item => {
            let memDiscountItem = memDiscountItems?.find(x => x.ItemId == item.ItemId);
            if (memDiscountItem) {
              discountedAmount = discountedAmount + (item.SalesPrice - memDiscountItem.SalesPrice);
              item.Discount = memDiscountItem.Discount;
              item.DiscountPercentage = memDiscountItem.DiscountPercentage;
              item.DiscountTypeId = memDiscountItem.DiscountTypeId;
              item.SalesPrice = memDiscountItem.SalesPrice;
            }
          });        
        }
      }
      this.memberMappedItemDrag = false;  
    } else {
      member.associatedItems = member.associatedItems.concat(this.selectedItemsList);      
      this.selectedItemsList.map(i => {
        this.retailItems = this.retailItems.filter(r => !(r.ItemId == i.ItemId && r.LineNumber == i.LineNumber));
      });
    }
    this.popupInput.totalAmount = this.popupInput.totalAmount - discountedAmount;
    this.popupInput.remainingAmount = this.popupInput.remainingAmount - discountedAmount;
    this.CalcRemainingAndUpdateFieldValidation(member, memberFormGrp);
    const isFlatAmountInTicketLevelApplied : boolean = this.originalTicket?.discounts?.length > 0 ? !this.originalTicket.discounts[0].isPercentage : false;
    if(isFlatAmountInTicketLevelApplied)
    this.CalculateAndUpdateFieldIfFlatTicketleveldiscount()
  }

  async CalculateAndUpdateFieldIfFlatTicketleveldiscount() {
    try {
      this._utils.ToggleLoader(true);
      let memberRetailItems = this.popupInput.members.flatMap(x => x.associatedItems);
      let retailItemsFinal = this.retailItems;
      let serviceProducts = _.cloneDeep(this.originalSelectedProducts.filter(x => x.payeeId > 0 && !x.isReturn && !(x.ItemType == RetailItemType.Deposit) && !x.MultiPack));
      let selectedProductsToBeAdded : SelectedProducts[] ;
      selectedProductsToBeAdded = [...serviceProducts, ...memberRetailItems, ...retailItemsFinal];
      let lineNumber = selectedProductsToBeAdded.map(x => x.LineNumber);
      let ticket = await this.transactionEngineBusiness.CreateTicket(this._ss.SelectedOutletId, selectedProductsToBeAdded, this._ss.settleOpenTransaction, this._ss.ticketDiscount);
      ticket?.lineItems.map((value, index) => {
        value.index = lineNumber[index];
      });
      let discountedAmount = 0;
      memberRetailItems.forEach(item => {
        let SalesPriceBeforeDiscount = item.SalesPrice;
        this.setItemPriceAndTaxes(item, true, ticket)
        discountedAmount = discountedAmount + this._utils.RoundOff(item.SalesPrice - SalesPriceBeforeDiscount);
      });

      this.retailItems.forEach(item => {
        let SalesPriceBeforeDiscount = item.SalesPrice;
        this.setItemPriceAndTaxes(item, true, ticket)
        discountedAmount = discountedAmount + this._utils.RoundOff(item.SalesPrice - SalesPriceBeforeDiscount);
      });

      this.popupInput.totalAmount = this.popupInput.totalAmount + discountedAmount;
      this.popupInput.remainingAmount = this.popupInput.remainingAmount + discountedAmount;
      let memberFormArr = this.memberSelectionForm.get("memberSelectionArr")['controls'];
      let memInd = 0;
      _.forEach(memberFormArr, (x) => {
        this.CalcRemainingAndUpdateFieldValidation(this.popupInput.members[memInd], x);
        memInd++;
      });
      this._utils.ToggleLoader(false);
    }
    catch (ex) {
      this._utils.ToggleLoader(false);
      console.log("Error while calculating member amount when flat amount ticket level is included, error: " + ex);
    }
  }

  async removeItemFromMember(member, itemInd, item, memberFormGrp) {
    if (this.applyMemberDiscountAutomatically && (this.overrideWithMemberDiscount || !item.isAutoMemDiscRemoved)) {
      let salesPriceBeforeDiscountRemoval = item.SalesPrice;
      await this.removeAutomaticMemberDiscountApply(item);
      this.setItemPriceAndTaxes(item);
      let diffAmount = item.SalesPrice - salesPriceBeforeDiscountRemoval;
      this.popupInput.remainingAmount = this.popupInput.remainingAmount + diffAmount;
      this.popupInput.totalAmount = this.popupInput.totalAmount + diffAmount;
    }
    item.selected = false;
    this.retailItems.push(item);
    this.retailItems = _.sortBy(this.retailItems, "LineNumber");
    member.associatedItems.splice(itemInd, 1);
    this.CalcRemainingAndUpdateFieldValidation(member, memberFormGrp);
  }


    removeAutomaticMemberDiscountApply(item) {
      try {
        if (this.applyMemberDiscountAutomatically && (this.overrideWithMemberDiscount || !item.isAutoMemDiscRemoved)) {
          item.ProductPrice = item.isReturn ? item.ProductPrice * -1 : item.ProductPrice;
          item.DiscountPercentage = 0;
          item.DiscountTypeId = 0;
          item.discountReason = 0
          item.discountComments = "";
          item.Discount = 0;
          item.Discount = item.DiscountPercentage > 0
            ? this._utils.RoundOff2DecimalPlaces((item.Noofitems * item.ProductPrice) * item.DiscountPercentage / 100)
            : item.Discount;
        }
      }
      catch (error) {
        console.log("Error in removing member discount for an item, error :" + error)
      }
  }


  setMaxAmountValidator(memberGrp) {
    if (this.remainingAmount) {
      this.tempRemainingAmt = this.remainingAmount;
      const prefilledAmt = this.localization.currencyToSQLFormat(memberGrp.controls.memberAmount.value);
      if (prefilledAmt > 0) {
        memberGrp.controls.memberAmount.setValidators([Validators.required, Validators.max(prefilledAmt + this.tempRemainingAmt)]);
      } else {
        memberGrp.controls.memberAmount.setValidators([Validators.required, Validators.max(this.tempRemainingAmt)]);
      }
    }
  }

  checkValidator(memberFormGrp) {
    memberFormGrp.controls.memberAmount.updateValueAndValidity();
  }

  AutoSelectMembersAndGuest() {
    this.InitializeMemberFormsArray();
    //Auto selection
    //1. Select all if everyone is a member or Only One member 
    this.popupInput.members.map(m => m.selected = this.IsTeeTimePayment ? (m.membershipStatus?.toUpperCase() == "ACTIVE" && m.cardStatus?.toUpperCase() == "ACTIVE") : true);
    let memberFormArr = this.memberSelectionForm.get("memberSelectionArr")['controls'];
    let memInd = 0;
    _.forEach(memberFormArr, (x) => {
      x.controls.memberSelection.setValue(this.popupInput.members[memInd].selected);
      memInd++;
    });
    
    //2. Map Guests to first member if only one member
    if (this.popupInput.members.length == 1 && this.popupInput.members[0].selected) this.availableGuestMember.map(g => {
      g.selected = true;
      g.selectedMemberId = this.popupInput.members[0].memberCardNumber;
      g.selectedPlayerId = this.popupInput.members[0].playerId;
      this.popupInput.members[0].selected = true;
      this.memberSelectionForm.get("memberSelectionArr")['controls'][0]?.controls?.memberSelection?.setValue(true);
    });
    //3. If Only one guest then map it to First member
    if (this.popupInput.guests.length == 1) {
      this.popupInput.members[0].selected = true;
      this.availableGuestMember[0].selected = this.popupInput.members[0].selected;
      this.availableGuestMember[0].selectedMemberId = this.popupInput.members[0].selected ? this.popupInput.members[0]?.memberCardNumber : 0;
      this.availableGuestMember[0].selectedPlayerId = this.popupInput.members[0].selected ? this.popupInput.members[0]?.playerId : 0;     
    }
    //4. Two guests and Two or more members
    if (this.popupInput.guests.length <= this.popupInput.members.length) {
      let memInd = 0;
      this.availableGuestMember.map(g => {
        if (this.popupInput.members[memInd].selected) {
          g.selected = true;
          g.selectedMemberId = this.popupInput.members[memInd]?.memberCardNumber;
          g.selectedPlayerId = this.popupInput.members[memInd]?.playerId;
        }
        memInd++;
      })
    }
    this.formAvailableMemberGuests();
  }

  MapRetailItems() {
    if (this.popupInput.members?.length == 1 && this.retailItems?.length > 0) {
      if (!this.popupInput.allowAmountTender) {
        this.popupInput.members[0].associatedItems = _.cloneDeep(this.retailItems);
        this.retailItems = [];
      }
    }
    else {
      if (this.applyMemberDiscountAutomatically) {
        this.removeMemberDiscountsForAllItems();
        this.remainingAmount = this.popupInput.remainingAmount
      }     
    }
  }

  PopulatePaymentAmountsBasedOnSelection() {
    let memberTotalAmount = 0;
    let memInd = 0;
    if (this.popupInput.settledAmt == 0) {
      this.popupInput.members.map(x => {
        if (x.selected) {
          const prefillAmt = this._utils.RoundOff(x.memberAmount + this.CalcSelectedGuestAndRetailItemAmount(x));
          this.memberSelectionForm.get("memberSelectionArr")['controls'][memInd++].controls.memberAmount.setValue(this.localization.localizeCurrency(prefillAmt, false));
          memberTotalAmount = this._utils.RoundOff(memberTotalAmount + prefillAmt);
        }
      });
    }
    this.remainingAmount = this._utils.RoundOff(this.remainingAmount - memberTotalAmount);
    this.amountEntered = memberTotalAmount;
  }

  ResetSearchList() {
    this.memberSearchResult = [];
    this.selectedMemberList = [];
    this.clearSearch();
  }

  searchEvent(arg) {
    this.memberSearchResult = null;
    this.searchTextChanged.next(arg);
  }

   pay() {
    let memberselectionArr = this.memberSelectionForm.get("memberSelectionArr")['controls'] as UntypedFormArray;
    let memInd = 0;
    this.popupInput.members.map(m => {
      m.memberPin = memberselectionArr[memInd].controls.memberPin.value;
      m.memberAmount = memberselectionArr[memInd].controls.memberAmount.value;
      memInd++;
    });
    this.popupInput.selectedMemberFromSearch = this.selectedMemberFromSearch;
    this.popupInput.availableGuestMember = this.availableGuestMember;
    if (this.applyMemberDiscountAutomatically) {
      this._utils.ToggleLoader(true);
      let memberRetailItems = this.popupInput.members.flatMap(x => x.associatedItems);
      let retailItemsFinal = this.retailItems;
      let selectedProductsToBeAdded = memberRetailItems.concat(retailItemsFinal);
      selectedProductsToBeAdded.forEach(product => {
        const index = this._ss.selectedProducts.findIndex(x => x.ItemId == product.ItemId && x.LineNumber == product.LineNumber);
        this._ss.selectedProducts[index] = product;
      }
      );
      if (selectedProductsToBeAdded && selectedProductsToBeAdded.length > 0) {
        this._ss.isAutomaticMemberDiscFlow = true;
        this._ss.triggerFormOrderSummary.next(this.MemberDiscountFlowPay.bind(this));
      }
      else {
        this.MemberDiscountFlowPay();
      }
    }
    else {
      this.MemberDiscountFlowPay();
    }
  }


  MemberDiscountFlowPay() {
    if (!this.CheckIfMemberAlreadyPaid()) {
      this.dialogRef.close(this.popupInput);
    }
  }


  CheckIfMemberAlreadyPaid() {
    //Need to validate only if it is for Golf Teetime payment, to avoid duplicate round redemptions
    if (this._ss.ProductId == Product.RETAIL || this._ss.ProductId == Product.SPA) return false;
    let memberAlreadyPaid = false;
    const memberSettlements = this.settlementHistory.filter(x => x.paymentMethodId == PaymentMethods.ARPost && !x.isReversed);
    if (this.settlementHistory?.length > 0 && memberSettlements.length > 0) {
      const paidMemberPlayerInfo: MemberPayeeInfo[] = <any>memberSettlements.map(x => x.additionalDetails);
      let paidPlayers: { playerId: number, playerName: string }[] = [];
      paidMemberPlayerInfo.map(p => {
        paidPlayers.push({
          playerId: p.playerId,
          playerName: p.memberName
        })
        paidPlayers = paidPlayers.concat(p.associatedMembers?.map(am => {
          return {
            playerId: am.playerId,
            playerName: am.guestName
          }
        }
        )).concat(p.associatedGuests?.map(ag => {
          return {
            playerId: ag.playerId,
            playerName: ag.guestName
          }
        }));
      });
      const selectedPlayerIds: number[] = this.popupInput.members.filter(x => x.selected)?.map(x => x.playerId);
      if (selectedPlayerIds.some(s => paidPlayers.some(x => x.playerId == s))) {
        memberAlreadyPaid = true;
        let memberBulletList = [];
        selectedPlayerIds.map(p => {
          const player = paidPlayers.find(x => x.playerId == p);
          if (player)
            memberBulletList.push(`<li>${player?.playerName}</li>`)
        });
        let memberListCaption = this.localization.replacePlaceholders(
          this.shopCaption.MemberSelectionPopup.MembersAlreadyPaidConfirmationMsg,
          ['paidMembers'],
          [`<ul>${memberBulletList.join('')}</ul>`]
        )
        this._utils.ShowErrorMessage(
          this.localization.captions.common.Warning,
          memberListCaption,
          ButtonType.YesNo,
          this.ProceedPay.bind(this)
        );
      }
    }
    return memberAlreadyPaid;
  }

  ProceedPay(result: string) {
    if (result.toLowerCase() == ButtonOptions.Yes.toLowerCase()) {
      this.dialogRef.close(this.popupInput);
    }
  }

  close() {
    if (this.applyMemberDiscountAutomatically) {
      this._ss.Ticket = _.cloneDeep(this.originalTicket);
      this._ss.selectedProducts = _.cloneDeep(this.originalSelectedProducts);
    }
    this.dialogRef.close();
  }

  selectMember(member, event, memberFormGrp) {
    if (event.checked && !this.ValidateAvailableRoundsAndStatus(member)) {
      event.preventDefault();
      return;
    }
    if (member.selectedFromSearch) {
      this.selectMemberFromSearch(member, event, memberFormGrp);
      return;
    }
    member.selected = event.checked;
    //Reverting Patched Amount if this member is already associated to another member
    if (!event.checked && this.availableGuestMember.some(a => a.guestId == member.memberCardNumber && a.playerId == member.playerId && a.selectedMemberId != "0" && a.selectedPlayerId != 0)) {
      const prevAssociatedMemberId = this.availableGuestMember.find(a => a.guestId == member.memberCardNumber && a.playerId == member.playerId).selectedMemberId;
      const prevAssociatedPlayerId = this.availableGuestMember.find(a => a.guestId == member.memberCardNumber && a.playerId == member.playerId).selectedPlayerId;
      this.availableGuestMember.find(a => a.guestId == member.memberCardNumber && a.playerId == member.playerId).selectedMemberId = "0";
      this.availableGuestMember.find(a => a.guestId == member.memberCardNumber && a.playerId == member.playerId).selectedPlayerId = 0;
      const prevMem = this.popupInput.members.find(m => m.memberCardNumber == prevAssociatedMemberId && m.playerId == prevAssociatedPlayerId);
      let memberselectionArr = this.memberSelectionForm.get("memberSelectionArr")['controls'] as UntypedFormArray;
      const amtWihtoutThisMem = this._utils.RoundOff(prevMem.memberAmount + this.CalcSelectedGuestAndRetailItemAmount(prevMem));
      memberselectionArr[this.popupInput.members.indexOf(prevMem)].controls.memberAmount.setValue(this.localization.localizeCurrency(amtWihtoutThisMem, false));
    }    
    if (member.selected) {
      this.CalcRemainingAndUpdateFieldValidation(member, memberFormGrp);
      memberFormGrp.controls.memberAmount.setValidators([Validators.required, Validators.max(this.remainingAmount)]);
      if (this.IsPinRequired)
      memberFormGrp.controls.memberPin.setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(4)]);
      memberFormGrp.controls.memberPin.updateValueAndValidity();

    } else {      
      memberFormGrp.controls.memberAmount.setValue(this.localization.localizeCurrency(0, false));      
      this.availableGuestMember.filter(g => g.selectedMemberId == member.memberCardNumber && g.selectedPlayerId == member.playerId)?.map(x => {
        if (x.isMember)
          this.popupInput.members.find(m => m.memberCardNumber == x.guestId && m.playerId == x.playerId).disabled = false;
        x.selected = false;
      });
      memberFormGrp.controls.memberAmount.setValidators(null);
      memberFormGrp.controls.memberAmount.setErrors(null);
      memberFormGrp.controls.memberPin.setValidators(null);
      memberFormGrp.controls.memberPin.setErrors(null);
    }
    this.calculateRemaining();
    memberFormGrp.updateValueAndValidity();
    this.memberSelectionForm.updateValueAndValidity();    
    this.formAvailableMemberGuests();
  }

  ValidateAvailableRoundsAndStatus(member) {
    let allowMember = true;
    if (member?.membershipStatus?.toUpperCase() != "ACTIVE" || member?.cardStatus?.toUpperCase() != "ACTIVE") {
      this._utils.showAlert(this.localization.captions.common.InActiveMember, AlertType.Info, ButtonType.Ok);
      allowMember = false;
    }
    return allowMember;
  }

  CalcSelectedGuestAndRetailItemAmount(member) {
    let overAllSelectedGuestsAmt = 0, totalItemPrice = 0;
    this.availableGuestMember.map(g => {
      if (g.selected && g.selectedPlayerId == member.playerId && g.selectedMemberId == member.memberCardNumber) {
        overAllSelectedGuestsAmt = this._utils.RoundOff((!this.popupInput?.allowAmountTender ? g.amount : 0) + overAllSelectedGuestsAmt);
      }
    });
    member.associatedItems.map(i => totalItemPrice = this._utils.RoundOff(totalItemPrice + i.SalesPrice));
    return this._utils.RoundOff(overAllSelectedGuestsAmt + totalItemPrice);
  }

  selectGuest(guest, member, memberGrp) {
    if (guest) {
      //If Guest is selected in any other member reset the values
      if (guest.selected && guest.selectedMemberId && (guest.selectedPlayerId != member.playerId || guest.selectedMemberId != member.memberCardNumber)) {
        guest.selected = true;
        const alreadyMappedInTeetimePlayer = this.popupInput.members.some(m => m.playerId == guest.selectedPlayerId && m.memberCardNumber == guest.selectedMemberId);
        if (alreadyMappedInTeetimePlayer) {
          let prevselectedMem = this.popupInput?.members?.find(m => m.playerId == guest.selectedPlayerId && m.memberCardNumber == guest.selectedMemberId);
          const premMemInd = this.popupInput?.members.indexOf(prevselectedMem);
          guest.selectedMemberId = member.memberCardNumber;
          guest.selectedPlayerId = member.playerId;
          const patchAmt = this._utils.RoundOff(prevselectedMem?.memberAmount + this.CalcSelectedGuestAndRetailItemAmount(prevselectedMem));
          this.memberSelectionForm.get("memberSelectionArr")['controls'][premMemInd]?.controls?.memberAmount?.setValue(this.localization.localizeCurrency(patchAmt, false));
        }
      } else {
        guest.selected = !guest.selected;
        guest.selectedMemberId = guest.selected ? member.memberCardNumber : 0;
        guest.selectedPlayerId = guest.selected ? member.playerId : 0;
      }
      //Reset selected Guests if Member is selected in Another Member
      if (guest.isMember && this.popupInput?.members?.some(m => m.playerId == guest.playerId && m.memberCardNumber == guest.guestId)) {
        let unselectedMem = this.popupInput?.members?.find(m => m.playerId == guest.playerId && m.memberCardNumber == guest.guestId);
        if (guest.isMember && unselectedMem) {
          unselectedMem.disabled = guest.selected;
          this.availableGuestMember.filter(g => g.selectedPlayerId == unselectedMem.playerId && g.selectedMemberId == unselectedMem.memberCardNumber)?.map(x => x.selected = false);
        }
      }      
      this.CalcRemainingAndUpdateFieldValidation(member, memberGrp);
    }
  }

  CalcRemainingAndUpdateFieldValidation(member, memberFormGrp) {
    const amtToBePatched = this._utils.RoundOff((!this.popupInput?.allowAmountTender ? member.memberAmount : 0) + this.CalcSelectedGuestAndRetailItemAmount(member));
    memberFormGrp.controls.memberAmount.setValue(this.localization.localizeCurrency(amtToBePatched, false));
    this.calculateRemaining();
    const maxAmt = this.remainingAmount > 0 ? this.remainingAmount : amtToBePatched;
    memberFormGrp.controls.memberAmount.setValidators([Validators.required, Validators.max(maxAmt)]);
  }

  calculateRemaining() {
    this.remainingAmount = this.popupInput.remainingAmount;
    let filledAmt = 0;
    let memberFormArr = this.memberSelectionForm.get("memberSelectionArr")['controls'];
    _.forEach(memberFormArr, (x) => {
      if (x && x.controls && x.controls.memberSelection.value)
        filledAmt = this._utils.RoundOff(filledAmt + this.localization.currencyToSQLFormat(x.controls.memberAmount.value));
    });
    this.remainingAmount = this._utils.RoundOff(this.remainingAmount - filledAmt);
    this.amountEntered = filledAmt;
  }

  async memberSearch(memberSearchText) {
    if (memberSearchText) {
      try {
        this._utils.ToggleLoader(true, this.shopCaption.MemberSelectionPopup.fetchingMemberDetailsMsg);        
        let result = await this._memberService.searchGuest(memberSearchText, SearchType.Member);
        this._utils.ToggleLoader(false);
        const memberConfig = this._propInfoService.getMemberConfig;
        if (memberConfig?.applicableCorporates) {
          const corpId = this._ss.sourceCorpId ? this._ss.sourceCorpId : 0;
          result[1] = result[1].filter(x => x.corpId === corpId);
        }  
        console.log(result[1]);
        const payeeResult = result[1];
        if (payeeResult) {
          this.formAvailableMemberGuests();
          this.memberSearchResult = [].concat(this.selectedMemberFromSearch);
          payeeResult.map(p => {
            if (!this.memberSearchResult.some(m => m.memberCardNumber == p.guestProfileId)) {
              this.memberSearchResult.push(
                {
                  memberName: p.name,
                  memberCardNumber: p.guestProfileId,
                  selected: false,
                  memberAmount: 0,
                  memberPin: "",
                  availableRounds: 0,
                  membershipStatus: "",
                  cardStatus: "",
                  payeeInfo: p,
                  associatedItems: [],
                  playerId: 0
                }
              );
            }
          });
          if (this.autoTrigger) {
            this.autoTrigger.openPanel();
          }
        }
      } catch (error) {
        this._utils.ToggleLoader(false);
        this.memberSearchResult = [];
        this.memberSearchResult = this.memberSearchResult.concat(this.selectedMemberFromSearch);        
        console.log(error);
      }
    }
  }

  formAvailableMemberGuests() {
    if (!(this.IsTeeTimePayment || this.IsDeposit || this.IsAppointmentPayment)) return
    const unselectedMembers = this.popupInput.members.filter(x => !x.selected && !x.selectedFromSearch);
    const selectedMembers = this.popupInput.members.filter(x => x.selected && !x.selectedFromSearch);
    this.availableGuestMember = this.availableGuestMember.filter(a => !selectedMembers.some(s => s.playerId == a.playerId && s.memberCardNumber == a.guestId));
    unselectedMembers.map(x => {
      if (!this.availableGuestMember.some(a => a.playerId == x.playerId && a.guestId == x.memberCardNumber)){
        this.availableGuestMember.push({
          guestId: x.memberCardNumber,
          guestName: x.memberName,
          selected: false,
          selectedMemberId: "0",
          isMember: true,
          payeeInfo: x.payeeInfo,
          amount: x.memberAmount,
          playerId: x.playerId,
          availableRounds: 0,
          selectedPlayerId: 0
        });
      }
    });
  }

  async chooseMemberPayee(member) {
    try {
      if (this.selectedMemberFromSearch.some(m => m.memberCardNumber == member.memberCardNumber)
        || this.popupInput.members.some(m => m.memberCardNumber == member.memberCardNumber)) {
        this._utils.showAlert(this.shopCaption.MemberSelectionPopup.memberAlreadySelected, AlertType.Info, ButtonType.Ok);
        this.ResetSearchList();
        return;
      }
      this._utils.ToggleLoader(true, this.shopCaption.MemberSelectionPopup.fetchingMemberDetailsMsg);   
      let memberInfo: PayeeInfo = await this._memberService.getMemberInfo(
        member.memberCardNumber,
        this._memberService.getScheduleDateInUTC(this._ss.selectedProducts),
        false,
        false
      );
      this._utils.ToggleLoader(false);
      if (memberInfo) {
        member.availableRounds = memberInfo.golfPoints?.length > 0 ? memberInfo.golfPoints?.[0]?.eligibleRounds : 0;
        member.membershipStatus = memberInfo.membershipStatus?.toUpperCase();
        member.payeeInfo = this._memberService.MapToPayeeInfo(memberInfo);
        member.payeeInfo.guestProfileId = member.memberCardNumber;
        member.cardStatus = memberInfo.cardStatus.toUpperCase();    
        if (this.applyMemberDiscountAutomatically) {
          let memberInfos = [];
          memberInfos.push(memberInfo);
          this.AutomaticMemberDiscountApplyToAllMembers(memberInfos);
        }
      }
      this.ResetSearchList();
      if (!this.ValidateAvailableRoundsAndStatus(member)) { return; }
      this.selectMemberFromSearch(member, { checked: true }, null);
    } catch (error) {
      this._utils.ToggleLoader(false);
    }
  }

  selectMemberFromSearch(member, event, memberFormGrp) {
    try {      
      member.selected = event.checked;
      if (!this.selectedMemberFromSearch.some(m => m.memberCardNumber == member.memberCardNumber)) {
        this.selectedMemberFromSearch.push(member);
        let formArr = this.memberSelectionForm.controls.memberSelectionArr as UntypedFormArray;
        formArr.push(this.createMemberGrp(member));
        member.selectedFromSearch = true;
        this.popupInput.members.push(member);
        this.dragDropConnectedList.push(`member-sec-${this.popupInput.members.length - 1}`);
      }      
      if (memberFormGrp) {
        if (member.selected) {
          this.CalcRemainingAndUpdateFieldValidation(member, memberFormGrp);
          memberFormGrp.controls.memberAmount.setValidators([Validators.required, Validators.max(this.remainingAmount)]);
          if (this.IsPinRequired)
            memberFormGrp.controls.memberPin.setValidators([Validators.required]);
        } else {
          memberFormGrp.controls.memberAmount.setValue(this.localization.localizeCurrency(0, false));
          memberFormGrp.controls.memberAmount.setValidators(null);
          memberFormGrp.controls.memberAmount.setErrors(null);
          memberFormGrp.controls.memberPin.setValidators(null);
          memberFormGrp.controls.memberPin.setErrors(null);          
          this.availableGuestMember.filter(g => g.selectedMemberId == member.memberCardNumber && g.selectedPlayerId == member.playerId)?.map(x => {
            if (x.isMember)
              this.popupInput.members.find(m => m.memberCardNumber == x.guestId && m.playerId == x.playerId).disabled = false;
            x.selected = false;
          });
        }
        this.calculateRemaining();
        memberFormGrp.updateValueAndValidity();
      }
    } catch (error) {
      this._utils.ToggleLoader(false);
      console.error("Error Occurred while fetching Member details" + error);
    }
  }

  removeMember(member, index) {
    this.popupInput.members = this.popupInput.members.filter(x => x.memberCardNumber != member.memberCardNumber);
    this.selectedMemberFromSearch = this.selectedMemberFromSearch.filter(x => x.memberCardNumber != member.memberCardNumber);
    let formArr = this.memberSelectionForm.get("memberSelectionArr") as UntypedFormArray
    formArr.removeAt(index);
    let guestsMemberSelected = this.availableGuestMember.filter(x => x.isMember && x.selectedMemberId == member.memberCardNumber);
    if (guestsMemberSelected) {
      guestsMemberSelected.map(g => {
        let parentMember = this.popupInput.members.find(m => m.memberCardNumber == g.guestId && m.playerId == g.playerId);
        parentMember.disabled = false;
        g.selected = false;
        g.selectedMemberId = "0";
        g.selectedPlayerId = 0;
      })
    }
    this.retailItems = this.retailItems.concat(member.associatedItems);
    this.removeMemberDiscountsForAllItems();
    this.calculateRemaining();
  }

  clearSearch() {
    this.memberSelectionForm.controls['memberSearch'].setValue('');
    if (this.memberSearchEle && this.memberSearchEle.nativeElement) {
      this.memberSearchEle.nativeElement.value = "";
    }
  }

  async GetMemberDetails() {
    try {
      if (this.popupInput.members) {
        let taskList = [];
        let uniqMembers: string[] = Array.from(new Set(this.popupInput.members.map(m => m.memberCardNumber)));
        uniqMembers.map(m => {
          taskList.push(
            this._memberService.getMemberInfo(
              m,
              this._memberService.getScheduleDateInUTC(this._ss.selectedProducts),
              false,
              false
            )
          )
        });
        this._utils.ToggleLoader(true, this.shopCaption.MemberSelectionPopup.fetchingMemberDetailsMsg);
        const _memberInfo: PayeeInfo[] = await Promise.all(taskList);
        this._utils.ToggleLoader(false);
        if (_memberInfo && _memberInfo?.length > 0) {
          if (this.applyMemberDiscountAutomatically)
            this.AutomaticMemberDiscountApplyToAllMembers(_memberInfo);
          let ind = 0;
          this.popupInput.members.map(m => {
            const currMem = _memberInfo.find(x => x.guestProfileId == m.memberCardNumber);
            m.payeeInfo = currMem;
            m.availableRounds = currMem.golfPoints.length > 0 ? currMem.golfPoints?.[0]?.eligibleRounds : 0;
            m.membershipStatus = currMem.membershipStatus?.toUpperCase();
            m.cardStatus = currMem.cardStatus?.toUpperCase();
            this.dragDropConnectedList.push(`member-sec-${ind}`);
            ind++;
          });
          this.dragDropConnectedList.unshift('retailItemsList');
          this.AutoSelectMembersAndGuest();
          this.MapRetailItems();
          this.PopulatePaymentAmountsBasedOnSelection();
        }
      }
    } catch (error) {
      this._utils.ToggleLoader(false);
      console.error("Error Occurred while fetching Member details" + error);
    }
  }

  async AutomaticMemberDiscountApplyToAllMembers(memInfo) {
    try{
      memInfo.forEach(member => {
        let memberDiscountedItem: MemberDiscountedItems = {
          memberCardNumber: "0",
          retailItems: []
        };
        memberDiscountedItem.memberCardNumber = member.guestProfileId;
        let selectedProductsCopy = _.cloneDeep(this._ss.selectedProducts.filter(
          (x) => (x.payeeId == undefined && x.playerName == undefined && x.ItemType == RetailItemType.RetailItemRetailPOSOnly) || (x.isFromTeeTimeAddRetailItem && !(x.ItemType === RetailItemType.PMSAddOnsRentalItem))
        ));
        let memberDiscType = member.discountType;
        let i = 0;
        selectedProductsCopy.map(x => x.LineNumber = ++i);
        selectedProductsCopy.forEach(item => {
          if (this.overrideWithMemberDiscount || !item.isAutoMemDiscRemoved) {
            item.Discount = 0;
            this._ss.ApplyMemberDiscountAutomatically(item, this.miscSettings, this.activeDiscountReasons, this.applyDiscountService.AllCategoryDiscountList, this.applyDiscountService.DiscountTypes, memberDiscType);
          }
          item.Discount = item.DiscountPercentage > 0
            ? this._utils.RoundOff2DecimalPlaces((item.Noofitems * item.ProductPrice) * item.DiscountPercentage / 100)
            : item.Discount;
        });
        this.transactionEngineBusiness.CreateTicket(this._ss.SelectedOutletId, selectedProductsCopy, this._ss.settleOpenTransaction, this._ss.ticketDiscount).then(ticket => {
          selectedProductsCopy.forEach(item => {
            let orgAmount = 0, itemTax = 0, gratuityTax = 0, serviceChargeTax = 0, gratuity = 0, servCharge = 0, ticketLevelWeightedDiscount = 0;
            const totalprice = (item.Noofitems * item.ProductPrice);
            const checkItem = ticket?.lineItems?.find(x => x.itemId == item.ItemId && item.LineNumber == x.index);
            if (checkItem) {
              itemTax = checkItem?.tax;
              gratuityTax = checkItem?.gratuityTax;
              serviceChargeTax = checkItem?.serviceChargeTax;
              gratuity = checkItem?.gratuity;
              servCharge = checkItem?.serviceCharge;
              ticketLevelWeightedDiscount = checkItem?.weightedDiscount ?? 0;
            }

            if (item.Discount) {
              let perItemDiscount = item.DiscountPercentage > 0 ? this._utils.MidPointRoundOffTwo(item.DiscountPercentage * totalprice / 100) : item.Discount;
              perItemDiscount = (perItemDiscount > totalprice) ? totalprice : perItemDiscount;
              orgAmount = totalprice - perItemDiscount;
            }
            else {
              orgAmount = totalprice;
            }
            orgAmount = orgAmount - ticketLevelWeightedDiscount;
            orgAmount = this._utils.MidPointRoundOffTwo(orgAmount + gratuity + servCharge);
            itemTax = this._utils.MidPointRoundOffTwo(itemTax + gratuityTax + serviceChargeTax);
            item.SalesPrice = !this.popupInput.isTaxExempted ? this._utils.MidPointRoundOffTwo(orgAmount + itemTax) : orgAmount;
            memberDiscountedItem.retailItems.push(item);
          });
          this.memberDiscountedItems.push(memberDiscountedItem);
        }
        );
      }
      );
    }
      catch (error) {
        console.log("Error in getting memberdiscount for all items, error :"+ error);
      }
    }

  setItemTax() {
    if (this.retailItems?.length > 0) {
      this.retailItems.map(r => {
        const selectedRetailItem: SelectedProducts = r;
        let orgAmount = 0, itemTax = 0, gratuityTax = 0, serviceChargeTax = 0, gratuity = 0, servCharge = 0, ticketLevelWeightedDiscount = 0;
        const totalprice = (selectedRetailItem.Noofitems * selectedRetailItem.ProductPrice);
        const checkItem = this._ss.Ticket?.lineItems?.find(x => x.itemId == selectedRetailItem.ItemId && r.LineNumber == x.index);
        if (checkItem) {
          itemTax = checkItem?.tax;
          gratuityTax = checkItem?.gratuityTax;
          serviceChargeTax = checkItem?.serviceChargeTax;
          gratuity = checkItem?.gratuity;
          servCharge = checkItem?.serviceCharge;
          ticketLevelWeightedDiscount = checkItem?.weightedDiscount ?? 0;
        }

        if (selectedRetailItem.Discount) {
          let perItemDiscount = selectedRetailItem.DiscountPercentage > 0 ? this._utils.MidPointRoundOffTwo(selectedRetailItem.DiscountPercentage * totalprice / 100) : selectedRetailItem.Discount;
          perItemDiscount = (perItemDiscount > totalprice) ? totalprice : perItemDiscount;
          orgAmount = totalprice - perItemDiscount;
        }
        else {
          orgAmount = totalprice;
        }
        orgAmount = orgAmount - ticketLevelWeightedDiscount;
        orgAmount = this._utils.MidPointRoundOffTwo(orgAmount + gratuity + servCharge);
        itemTax = this._utils.MidPointRoundOffTwo(itemTax + gratuityTax + serviceChargeTax);
        selectedRetailItem.SalesPrice = !this.popupInput.isTaxExempted ? this._utils.MidPointRoundOffTwo(orgAmount + itemTax) : orgAmount;
        selectedRetailItem.selected = false;
      })
    }
  }

  onDragStart(e, item) {
    this.isDragging = true;
    item.selected = true;
  }

  onDragDropped(e) {
    this.isDragging = false;
  }

  selectItems(item) {
    item.selected = !item.selected;
    this.selectedItemsList = this.retailItems.filter(s => s.selected);
  }

  removeAllItemsFromMember(member, memberFormGrp) {
    member.associatedItems.map(i => i.selected = false);
    this.retailItems = this.retailItems.concat(member.associatedItems);
    this.removeMemberDiscountsForAllItems()
    this.retailItems = _.sortBy(this.retailItems, "LineNumber");
    member.associatedItems = [];
    this.CalcRemainingAndUpdateFieldValidation(member, memberFormGrp);
  }

  removeMemberDiscountsForAllItems() {
    if (this.applyMemberDiscountAutomatically) {
      this.retailItems.forEach(item => {
        let salesPriceBeforeDiscountRemoval = item.SalesPrice;
        this.removeAutomaticMemberDiscountApply(item);
        this.setItemPriceAndTaxes(item);
        let diffAmount = item.SalesPrice - salesPriceBeforeDiscountRemoval;
        this.popupInput.remainingAmount = this.popupInput.remainingAmount + diffAmount;
        this.popupInput.totalAmount = this.popupInput.totalAmount + diffAmount;
      }
      );
    }
  }

  setItemPriceAndTaxes(item, isFromFlatTicketFlow : boolean = false, ticket : any = null) {
    const ticketDetail = isFromFlatTicketFlow ? ticket : this._ss.Ticket;
    let orgAmount = 0, itemTax = 0, gratuityTax = 0, serviceChargeTax = 0, gratuity = 0, servCharge = 0, ticketLevelWeightedDiscount = 0;
    const totalprice = (item.Noofitems * item.ProductPrice);
    const checkItem = ticketDetail?.lineItems?.find(x => x.itemId == item.ItemId && item.LineNumber == x.index);
    if (checkItem) {
      itemTax = checkItem?.tax;
      gratuityTax = checkItem?.gratuityTax;
      serviceChargeTax = checkItem?.serviceChargeTax;
      gratuity = checkItem?.gratuity;
      servCharge = checkItem?.serviceCharge;
      ticketLevelWeightedDiscount = checkItem?.weightedDiscount ?? 0;
    }
    if (item.Discount) {
      let perItemDiscount = item.DiscountPercentage > 0 ? this._utils.MidPointRoundOffTwo(item.DiscountPercentage * totalprice / 100) : item.Discount;
      perItemDiscount = (perItemDiscount > totalprice) ? totalprice : perItemDiscount;
      orgAmount = totalprice - perItemDiscount;
    }
    else {
      orgAmount = totalprice;
    }
    orgAmount = orgAmount - ticketLevelWeightedDiscount;
    orgAmount = this._utils.MidPointRoundOffTwo(orgAmount + gratuity + servCharge);
    itemTax = this._utils.MidPointRoundOffTwo(itemTax + gratuityTax + serviceChargeTax);
    item.SalesPrice = !this.popupInput.isTaxExempted ? this._utils.MidPointRoundOffTwo(orgAmount + itemTax) : orgAmount;
  }


  selectAllItems() {
    this.selectedItemsList = [];
    this.retailItems.map(r => {
      r.selected = true;
      this.selectedItemsList.push(r)
    });
  }

  deSelectAllItems(){
    this.selectedItemsList = [];
    this.retailItems.map(r => r.selected = false);
  }

  onMemberItemDragStart(e,item,member,memberFormGrp){
    let memClone = _.cloneDeep(member);
    memClone.associatedItems = memClone.associatedItems.filter(s => !(s.ItemId == item.ItemId && s.LineNumber == item.LineNumber));
    this.currentMember = memClone;
    this.currentFormGrp = memberFormGrp;
    this.memberMappedItemDrag = true;
  }
  onMemberItemDragEnd(e){   
  }

}
