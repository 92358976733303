import { AbstractValidator, ValidationResult } from 'fluent-ts-validator';

export class BaseValidator<T> extends AbstractValidator<T>
{

    constructor() {
        super();
    }

    validate(instance: T): ValidationResult {
        return super.validate(instance);
    }

    async validateAsync(instance: T): Promise<ValidationResult> {
        return await super.validateAsync(instance);
    }
}
