import { Injectable } from '@angular/core';
import { GolfManagementCommunication } from 'src/app/shared/communication/services/golfmanagement.service';
import { API } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { SettingModule, SettingScreen } from '../../global.constant';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';
import { API as PackageAPI } from '../../../settings/golf-setup/code-setup/packagemapping/package-mapping.modal';

@Injectable(
    { providedIn: 'root' }
)
export class SettingsDataService {

    constructor(private _golfManagementCommunication: GolfManagementCommunication, private _golfScheduleCommunication: GolfScheduleCommunication) {

    }

    /**
     * Tee Time Settings of Module SYSTEMSETUP
     * @returns {Promise<API.TeeTimeConfig>}
     * @memberof SettingsDataService
     */
    public async getTeeTimeSetting(): Promise<API.TeeTimeConfig> {
        const result = this._golfManagementCommunication.getPromise<API.Settings<API.TeeTimeConfig>>(
            { route: GolfApiRoute.GetTeeSettings });
        const settings: API.Settings<API.TeeTimeConfig> = await result;
        return settings.configValue;
    }

    /**
     * rguest Golf Defaults Settings of Module SYSTEMSETUP
     * @returns {Promise<API.DefaultsSettingConfig>}
     * @memberof SettingsDataService
     */
    public async getDefaultsSetting(): Promise<API.DefaultsSettingConfig> {
        const result = this._golfManagementCommunication.getPromise<API.Settings<API.DefaultsSettingConfig>>(
            { route: GolfApiRoute.GetDefaultsSettings });
        const settings: API.Settings<API.DefaultsSettingConfig> = await result;
        return settings.configValue;

    }

    public getSettings<T>(moduleValue: SettingModule, screenValue: SettingScreen): Promise<API.Settings<T>> {
        return this._golfManagementCommunication.getPromise<API.Settings<T>>(
            { route: GolfApiRoute.GetSettings, uriParams: { module: moduleValue, screen: screenValue } });
       
    }

    public updateSettings<T>(data: API.Settings<T>): Promise<boolean> {
        const setting: API.Settings<string> = {
            id: data.id, moduleName: data.moduleName,
            screenName: data.screenName,
            configValue: JSON.stringify(data.configValue),
            defaultValue: JSON.stringify(data.defaultValue)
        };
        return this._golfManagementCommunication.putPromise<boolean>(
            { route: GolfApiRoute.UpdateSettings, body: setting });
      
    }

    public releaseTeeTime(holdDuration: number): Promise<boolean> {
        return this._golfScheduleCommunication.putPromise({
            route: GolfApiRoute.ReleaseTeeTimes, uriParams: { "holdDuration": holdDuration }
        });       
    }

    public removeAutoRelease(): Promise<boolean> {
        return this._golfScheduleCommunication.putPromise({
            route: GolfApiRoute.RemoveAutoRelease
        });        
    }


    async getPmsPackages(): Promise<PackageAPI.PackageBlockPlayerType[]> {
        return this._golfManagementCommunication.getPromise<PackageAPI.PackageBlockPlayerType[]>({
            route: GolfApiRoute.GetPackageMapping
        });
    }

    async deletePmsPackages(id): Promise<boolean> {
        return this._golfManagementCommunication.deletePromise<boolean>({
            route: GolfApiRoute.GetPackageMapping + "/" + id,
        });
    }

    async addPmsPackages(data: any): Promise<PackageAPI.PackageBlockPlayerType> {
        return this._golfManagementCommunication.postPromise<PackageAPI.PackageBlockPlayerType>({
            route: GolfApiRoute.GetPackageMapping,
            body: data
        });
    }

}

