import { Injectable } from "@angular/core";
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { ActionTypeEnum, HdrActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { GolfLocalization } from "src/app/core/localization/golf-localization";
import { AlertType, ButtonType, ContactDetails } from "src/app/shared/shared-models";
import { TeeTimeService } from "../teetime/tee-time.service";
import { API } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { SettingsDataService } from 'src/app/shared/data-services/golfmanagement/settings.data.service';
import { SettingModule, SettingScreen, CheckBoxTableTemplate, GolfSessionKey } from 'src/app/shared/global.constant';
import { PlayerDeposit } from "../add-deposite-modal/add-deposite-model";
import { UnPaidPlayersBusiness } from 'src/app/shared/data-services/golfschedule/unpaid-players.business';
import { PlayerPaymentstatus } from "src/app/tee-time/search/search-model";
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { ScheduledTeeTimeUnPaidPlayer } from "src/app/shared/models/unpaid-players.model";
import { TeeTimeCustomFeeUtilities } from "../tee-time-custom-fee.utilities";

@Injectable()

export class AuthorizeBusiness {
    captions: any;
    propertyConfig: any;
    currencySymbol: string;
    constructor(
      private localization: GolfLocalization
      , public _teeTimeService : TeeTimeService
      , private _settingsDataService: SettingsDataService
      , private _unPaidPlayersBusiness: UnPaidPlayersBusiness
      , private _utils: GolfUtilities
      , private _customFeeUtiles: TeeTimeCustomFeeUtilities) {
        this.captions = this.localization.captions;
        this.currencySymbol = this.localization.currencySymbol;
    }
    teeTimeConfig: API.Settings<API.TeeTimeConfig>;
    UnPaidPlayers: ScheduledTeeTimeUnPaidPlayer[];

    getTableOptions(): TableOptions {
      return {
        defaultSortOrder: SorTypeEnum.asc,
        defaultsortingColoumnKey: 'name',
        isHeaderCheckboxAllowed: true,
        isRowClick: true,
        uniqueKey: 'name',
      }
  }
    getHeaderOptions(email,phone): TableHeaderOptions[] {
        const headers: TableHeaderOptions[] = [
          {
            key: 'checked',
            displayName: '',
            templateName: ActionTypeEnum.checkbox,
            hdrtemplateName: HdrActionTypeEnum.hdrCheckbox
          },
          {
            key: 'name',
            displayName: this.captions.lbl_name,
            displayNameId: 'tbl_lbl_name',
            sortingKey:'name',
          },
          {
            key: 'emailId',
            displayName: this.captions.lbl_emailAddress,
            displayNameId: 'lbl_emailAddress',
            sortingKey:'emailId',
            templateName: ActionTypeEnum.custom,
            isDisabled :email
          },
          {
            key: 'countryCode',
            displayName: this.captions.common.countryCode,
            displayNameId: 'tbl_hdr_countryCode',
            sortingKey: 'countryCode',
            templateName: ActionTypeEnum.custom,
            isDisabled :phone
          },
          {
            key: 'phoneNo',
            displayName: this.captions.lbl_phoneNumber,
            displayNameId: 'tbl_lbl_phoneNumber',
            sortingKey: 'phoneNo',
            templateName: ActionTypeEnum.custom,
            isDisabled :phone
          },
          {
            key: 'amount',
            displayName: this.captions.lbl_amount+ ' ' + '(' + this.currencySymbol + ')',
            displayNameId: 'tbl_lbl_amount',
            sortingKey: 'amount'
          },
          {
            key: 'balanceDue',
            displayName: this.captions.lbl_balanceDue+ ' ' + '(' + this.currencySymbol + ')',
            displayNameId: 'tbl_lbl_balanceDue',
            sortingKey: 'balanceDue'
          },

        ]
        return headers;
      }

      public GetContactInfo(playerIds: number[]) : Promise<ContactDetails[]> {
        return this._teeTimeService.GetContactInformationByPlayerIds(playerIds);
    }

    // changed the below code as it is not setting the value for 'teetimeConfig'
    async GetTeeTimeConfig() {
      this.teeTimeConfig = await this._settingsDataService.getSettings<API.TeeTimeConfig>(SettingModule.SystemSetup, SettingScreen.TeeTime);
    }

    async GetDepositPlayers(data): Promise<PlayerDeposit[]> {

      await this.GetTeeTimeConfig();
      this._utils.ToggleLoader(true);
      let DepositplayerList: PlayerDeposit[] = [];
      this.UnPaidPlayers = await this._unPaidPlayersBusiness.getPlayers(data.course.id, this.localization.convertDateTimeToAPIDateTime(data.time), PlayerPaymentstatus.unPaid | PlayerPaymentstatus.refund);
      this._utils.ToggleLoader(false);
      this.UnPaidPlayers.forEach(element => {
        element.id = element.playerId;
      });
      let unpaidPlayersData = this.UnPaidPlayers.filter(p => p.scheduledTeeTimeId == data.scheduledTeeTimeId);
      DepositplayerList = this.MapDepositForPlayer(unpaidPlayersData, 0, data.createdDateTime);
      const filteredObj = unpaidPlayersData.find(x => x.isTournamentPlayersUnPaid && x.tournamentId > 0);
      if (filteredObj) {
        const tournamentPlayers = this.MapDepositForPlayer(this.UnPaidPlayers, filteredObj.tournamentId, data.createdDateTime);
        this._utils.showAlert(this.localization.captions.teetime.PayByTournamentConfirmationMsg, AlertType.Info, ButtonType.YesNo, null, tournamentPlayers);
      }
      return DepositplayerList;
    }

    MapDepositForPlayer(unpaidPlayersData, tournamentId: number, createdDateTime: string) {
      const DepositplayerList: PlayerDeposit[] = [];
      let teeTimeSettings = JSON.parse(sessionStorage.getItem('TEETIMESETTING'));
      if (unpaidPlayersData.length > 0) {
        unpaidPlayersData = tournamentId ? unpaidPlayersData.filter(x => x.tournamentId == tournamentId) : unpaidPlayersData;
        unpaidPlayersData.map(p => {
          if (teeTimeSettings && teeTimeSettings?.requireCheckInCheckOut && ((p.playerStatus & PlayerPaymentstatus.CheckIn) != 0 || (p.playerStatus & PlayerPaymentstatus.CheckOut) != 0)) {
            return
          }
          const prefilValues = this.GetDepositValues(p, createdDateTime);
          let Depositplayer: PlayerDeposit = {
            id: p.playerId,
            player: p.playerName,
            playerType: p.playerType,
            playerCategoryId: p.playerCategoryId,
            rateTypeId: p.rateTypeId,
            rateType: p.rateType,
            amountWithoutTax: prefilValues[0],
            amount: prefilValues[1],
            deposit: p.multiPackTransactionDetailId != 0 ? this.localization.localizeCurrency(0, false, 2) : this.localization.localizeCurrency(prefilValues[2], false, 2),
            balance: p.multiPackTransactionDetailId != 0 ? this.localization.localizeCurrency(0, false, 2) : prefilValues[3],
            ticketNumber: p.ticketNumber,
            transactionId: p.transactionId,
            playerName:  this._utils.formatGuestName(p.firstName, p.lastName),
            totalTax: prefilValues[4]
          };
            Depositplayer.customFee = prefilValues[5] ?  this.localization.localizeCurrency(prefilValues[5], false, 2) : '';
            p.scheduledTeeTimePlayerCustomFees?.forEach(cstmFee => {
              cstmFee.amount = this.localization.localizeCurrency(cstmFee.amount,false, (Number(sessionStorage.getItem(GolfSessionKey.NoOfDecimalDigits)) ?? 0));
            });
            Depositplayer.customFeeDetails = p.scheduledTeeTimePlayerCustomFees;

          DepositplayerList.push(Depositplayer);
        });
      }
      return DepositplayerList;
    }

    GetDepositValues(player: ScheduledTeeTimeUnPaidPlayer, createdDateTime: string): [string,string, number, string, string, number] {
      let TotalAmountWithoutTax: number, TotalAmountWithTax: number, CustomFee:number = 0,
      Deposit: number, BalanceDue: number, ExistingDeposit: number = 0, TotalTax: number;
      player.deposits.forEach(d => ExistingDeposit += d.amount);
      const prefilValues = this.GetTotalAmountToBePaid(player);
      TotalAmountWithTax = prefilValues[0];
      TotalAmountWithoutTax = prefilValues[1];
      CustomFee = prefilValues[3];
      TotalTax = prefilValues[2];
      TotalAmountWithTax += CustomFee;
      if (this.teeTimeConfig) {
        let NoDToPlay = this.localization.getDaysDifference(this.localization.getCurrentDate(), this.localization.getDate(player.scheduledAPIDateTime));
        let createdDate = createdDateTime ? this.localization.getDate(createdDateTime) : this.localization.getCurrentDate();
        createdDate = this.ConvertFromUTCDate(createdDate);
        let NoDAfterBooking = this.localization.getDaysDifference(this.localization.getCurrentDate(), createdDate);
        const firstDepositDue = this.teeTimeConfig.configValue.firstDeposit.dueDays;
        const secondDepositDue = this.teeTimeConfig.configValue.secondDeposit.dueDays;
        let firstDeposit = 0;
        let secondDeposit = 0;
        if (!firstDepositDue || NoDAfterBooking >= firstDepositDue || NoDToPlay <= secondDepositDue) {
          firstDeposit = this.CalculateDeposit(TotalAmountWithTax, this.teeTimeConfig.configValue.firstDeposit);
        }
        if (NoDToPlay <= secondDepositDue) {
          secondDeposit = this.CalculateDeposit(TotalAmountWithTax, this.teeTimeConfig.configValue.secondDeposit);
        }

        Deposit = (firstDeposit + secondDeposit) - ExistingDeposit;
        Deposit = Deposit < 0 ? 0 : Deposit;
      }
      BalanceDue = TotalAmountWithTax - ExistingDeposit;
      return [this.localization.localizeCurrency(TotalAmountWithoutTax, false, 2),
        this.localization.localizeCurrency(TotalAmountWithTax, false, 2),
        Deposit,
        this.localization.localizeCurrency(BalanceDue, false, 2),
        this.localization.localizeCurrency(TotalTax, false, 2),
        CustomFee];
    }

    // converting UTC date to  current date
    ConvertFromUTCDate(utcDate: Date): Date {
      return new Date(utcDate.toString() + "UTC");
    }

    GetTotalAmountToBePaid(player: ScheduledTeeTimeUnPaidPlayer): [number,number,number,number] {
      let TotalAmountWithTax: number = 0, TotalAmountWithoutTax: number = 0, TotalTax: number = 0,customFee: number = 0;
      if (player) {
        if (player.tournamentId > 0 && player.greenFee && player.cartFee) {

            TotalAmountWithTax = (player.walk.toUpperCase() == "YES" || player.trail.toUpperCase() == "YES") ? (player.greenFee + player.entryFee + player.greenFeeTax + player.entryFeeTax) : (player.greenFee + player.cartFee + player.entryFee + player.greenFeeTax + player.cartFeeTax + player.entryFeeTax);
            player.otherItems.forEach(item => TotalAmountWithTax += item.price);
            TotalAmountWithoutTax = (player.walk.toUpperCase() == "YES" || player.trail.toUpperCase() == "YES") ? (player.greenFee + player.entryFee) : (player.greenFee + player.cartFee + player.entryFee);
            TotalTax = (player.walk.toUpperCase() == "YES" || player.trail.toUpperCase() == "YES") ? (player.greenFeeTax + player.entryFeeTax) : (player.greenFeeTax + player.cartFeeTax + player.entryFeeTax);
        } else
        {
          TotalAmountWithTax = (player.walk.toUpperCase() == "YES" || player.trail.toUpperCase() == "YES") ? player.greenFee + player.greenFeeTax : (player.greenFee + player.cartFee + player.greenFeeTax + player.cartFeeTax);
          TotalAmountWithoutTax = (player.walk.toUpperCase() == "YES" || player.trail.toUpperCase() == "YES") ? player.greenFee : (player.greenFee + player.cartFee);
          TotalTax =  (player.walk.toUpperCase() == "YES" || player.trail.toUpperCase() == "YES") ? player.greenFeeTax : (player.greenFeeTax + player.cartFeeTax);
        }
        customFee = this._customFeeUtiles.GetCustomFeeForPlayer(player);
        TotalTax += player.totalCustomFeeTax ?? 0;
        TotalAmountWithTax += player.totalCustomFeeTax ?? 0;
      }
      return [TotalAmountWithTax,TotalAmountWithoutTax,TotalTax,customFee];
    }

    CalculateDeposit(TotalAmount, depositConfig) {
      return TotalAmount * (depositConfig.depositPercentage / 100);
    }
 }
