import { PMSField } from 'src/app/common/Models/common.models';
export interface BaseResponse<T> extends BaseError {
    result: T;
    successStatus: boolean;
    propertyId: number;
    outletId: number;
}

export interface BaseError {
    errorCode: number;
    errorDescription: string;
}

export interface DmIntegrationResponse {
    DocViewCodeAndConfig: DocViewCodeAndConfig[],
    documentTypes: AdditionalInfo[],
    availableDocument: DocInfoResponse[],
    apiInformation: ApiInformation[]
}

export interface ApiInformation {
    serviceName: string
    additionalInfo: AdditionalInfo[]
}

export interface DocViewConfig extends BaseModel {
    searchEnabled: boolean;
    identifier: boolean;
    userClass: boolean;
    docViewCodeId: number;

}

export interface DocViewCodeAndConfig {
    IsSearchEnabled: boolean
    CodeId: number
    Code: string
    Description: string
    UserClass: boolean
    Identifier: string
    ConfigId: number
}
export interface BaseModel {
    id: number
}

export interface AdditionalInfo {
    key: string
    value: string
}

export interface DocInfoResponse extends BaseModel {
    documentName: string
    documentType: string
    folderId: number
    documentId: number
    indexInfo: string
    docViewCodeId: number
    docViewCode: DocViewCode
}

export interface DocViewCode extends BaseModel {
    code: string
    description: string
    docViewConfig: DocViewConfig[]
}

export interface DmTokenInfo {
    StatusCode: number,
    Message: string,
    Result: {
        access_token: string,
        expires_in: number,
        token_type: string,
        refresh_token: string,
        scope: string
    }
}

export enum Status {
    Added,
    Indexed,
    Completed,
    Failed,
    SearchResult
}

export interface DmFile {
    content: any,
    fileName: string,
    fileType: string,
}

export interface DmIndexResponse {
    StatusCode: number,
    Message: string,
    Result: string[]
}
export interface DmDocIndexResponse {
    response: DmIndexResponse;
    request: string;
    fileName: string;
    fileExtension: string;
}

export interface DocumentMappingModel {
    indexName: string;
    pmsField: PMSField;
    showinForm: {
        value: boolean;
        isDisabled: boolean;
    };
    indexType?: string;
}
export enum Menutype {
    dm = 'dm',
    signature = 'signature',
    idscan = 'idscan'
}

export interface SignatureCaptureRequest {
    propertyName: string;
    resid: string;
    machineID: string;
    transactionId: string;
    callBackURL: string;
    callBackWithSignature: string;
    port: number;
    deviceId: string;
    tenantId: number;
    formSet: string;
    tenantCode: string;
}

export interface EformRenderRequest {
    url: string;
}

export interface SigCapRequestWithoutCallback {    
    transactionId: string;
    callBackWithPDF: string;
    callBackWithSignature: string;
    formSet: string;
    pageData: string[];
    labels: string[];
    indexes: string[];
}

export interface SignatureCaptureResponse {
    code: number;
    message: string;
}
export interface SigCapResponseWithSignature {
    status:           string;
    transactionId:    string;
    resId:            string;
    signatureFile:    string;
    code:             number;
    message:          string;
    signatureFileExt: string;
    pdf:              string;
}

export interface DataMagineTokenRequest {
    clientID: string;
    clientSecret: string;
    authURL: string;
}

export interface GetEformsResponse {
    id: string;
    dataMagineClientId: string;
    tenantId: string;
    propertyId: string;
    formBusiness: string;
    eFormId: string;
}

export interface GetEforms {
    result: GetEformsResponse[];
    errorMessage: any;
    timeGenerated: Date;
}

export interface EformEmailTemplate {
    linksCount: number;
    id: number;
    templateId: number;
    isDefault: boolean;
    isActive: boolean;
    listOrder: number;
    templateCode: string;
    templateName: string;
    typeId: number;
    eventId: number;
  }

  export interface EformLinkMapping {
    linkName: string;
    link: string; 
  }

  export interface SPAEformLinkMapping {
    eFormId: string;
    eFormName: string;
    link: string; 
  }

  export interface EformEmailRequest {
    refId: string;
    emailTemplateId: string;
    senderEmail: string;
    senderName: string;
    toEmail: string;
    mailSubject: string;
    linkMappings: EformLinkMapping[];
    ToEmailIds? :any[];
    RefIds? :any[];
    LanguageId? :number;
  }

  export interface GuestContactInformation {
      guestId: string;
      contactDetails: ContactDetail[]
  }

  export interface ContactDetail {
      type: number;
      name: string;
      description: string;
      value: string;
      isPrimary: boolean;
      isPrivate: boolean;
  }

  export interface DMReceiptPosting{
    receipt:any;
    indexValueList:any[];
    receiptInput:string;
    receiptResponse:string;
    TransactionId:number;
    PCname:string;

  }

  export interface SigcapLog{

        TransactionId:number;
        PropertyDateTimeOfPosting:Date
        TicketNumber:string; 
        TransactionType:string 
        TransactionDate:Date;
        ClerkId:number;
        ErrorMessage:string;
        RetryCount:number; 
        Status:string; 
        BatchProcessId:number;
        CloseComment:string;
        ClosedDate:Date;
}