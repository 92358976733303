import { Injectable } from '@angular/core';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';
import { RateSetupData } from 'src/app/settings/rate-setup/rate-setup.model';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Injectable({providedIn: 'root'})
export class RateSetupDataService{
    constructor(private _http: GolfScheduleCommunication, private localization: GolfLocalization) {

    }
    public async getTeeFeesForCourseAndDate(courseId: number, date: Date): Promise<RateSetupData[]> {
        const _date: string = this.localization.ConvertDateToISODateTime(date);
        return await this._http.getPromise<RateSetupData[]>(
            { route: GolfApiRoute.GetTeeFeeForCourseAndDate,
                uriParams: { date: _date, courseId: courseId } });
       
    } 
    public async GetCourseTeeFeeForCourseDateAndRateType(courseId: number, date: Date, rateTypeId: number): Promise<RateSetupData> {
        const _date: string = this.localization.ConvertDateToISODateTime(date);
        return await this._http.getPromise<RateSetupData>(
            { route: GolfApiRoute.GetCourseTeeFeeForCourseDateAndRateType,
                uriParams: { date: _date, courseId: courseId , rateTypeId: rateTypeId} 
        });       
    }    
    
}