<section class="ag_pl--5 ag_pr--5 player-info--wrapper" [ngClass]="{'disabled': viewOnly}">
    <div class="golf-player-info--formwrapper">
      <div [formGroup]="playerInfoForm" #playerForm>
        <div class="golf-player-info-section--wrapper">
          <div *ngIf="!hideHeader">
            <h4 class="golf-page--headers">{{allCaptions.lbl_bookingDetails}}</h4>
          </div>        
          <div>
            <mat-form-field class="golf-form-control--xs">
              <mat-select placeholder="{{captions.allocationCode}}" name="AllocationCode"
                 formControlName="allocationCode">
                <mat-option *ngFor="let allocationCode of allocationCodes" [value]="allocationCode.id">
                  {{allocationCode.name}}
                </mat-option>
              </mat-select>
              <mat-error>{{errorCaptions.allocationCodeError}}</mat-error>
            </mat-form-field>
            <mat-form-field class="golf-form-control--xs">
              <mat-select placeholder="{{captions.lessonLocation}}" name="LessonLocation"
                formControlName="lessonLocation">
                <mat-option *ngFor="let lessonLocation of lessonLocations" [value]="lessonLocation.id">
                  {{lessonLocation.location}}
                </mat-option>
              </mat-select>
            </mat-form-field>      
          </div>
          <div class="switchesWrapper">
            <div class="ag-mr-6" *ngIf="displayToggle">
              <label class="golf-form--label">{{captions.bookLessonOnTeeSheet}}</label>
              <ui-switch formControlName="bookLessonOnTeeSheet" [checkedLabel]="captions.yes"
                [uncheckedLabel]="captions.no" (change)="onChangeEvent($event)"></ui-switch>
            </div>
            <div *ngIf="displayRecurring && displayToggle" [ngClass]="{'ag_section--disable': isRecurringDisable}">
              <label class="golf-form--label">{{allCaptions.bookAppointment.Recurring}}</label>
              <ui-switch formControlName="recurring" [checkedLabel]="captions.yes"
              [uncheckedLabel]="captions.no" (change)="onRecurringChangeEvent($event)"></ui-switch>
            </div>
          </div>
          <ng-container *ngIf="playerInfoForm.controls['recurring'].value">
            <div class="recurring-date" [ngClass]="{'ag_section--disable': isRecurringDisable}">
              <app-golf-date-picker  class="w-25" [inputs]="startDateInputs" (datePickerChange)="startDateChanged($event)"></app-golf-date-picker>
              <app-golf-date-picker  class="w-25" [inputs]="endDateInputs" (datePickerChange)="endDateChanged($event)"></app-golf-date-picker>
            </div>
            <div class="ag-pb-4">
            <!-- <app-recurring
             [ngClass]="{'ag_section--disable recurring-disable': isRecurringDisable}"
              [recurringData]="recurringData"
              [recurringInfo]="recurringInfo"
              [maxValidator]="setMaxValidator"
              [startDate]="recurringStartDate" 
              [endDate]="recurringEndDate" 
              (recurringSelected)="recurringSelected($event)"
            ></app-recurring> -->
          </div>
          </ng-container>
          <div class="createPlayerwrapper">
            <h4 class="golf-page--headers">{{allCaptions.lbl_playerDetails}}</h4>
            <!-- <a class="ag_link ag_ml--6 ag_cursor--pointer" (click)="openAddplayer()">{{allCaptions.lbl_CreatePlayer}}</a> -->
          </div>
         
          <div class="info-section">
            <app-ag-player-type-search [showToggle]="false" [playerSearchInfo]="playerCategoryInput"  
            [defaultHotelReservationCategory]="defaultHotelReservationCategory" [resetPlayerSearchCategory]="resetPlayerSearchCategory" 
            [selectedData]="selectedData" (CreatePlayer)="CreatePlayerPopup($event)" (CategoryChange)="playerCategoryChange($event)" 
            (playerSelect)="selectedChipDataEmit($event)" (ChipRemove)="onChipRemove($event)">
            </app-ag-player-type-search>
          </div>
          <div class="ag-pt-5">
            <div>
              <mat-form-field class="golf-form-control--xs"
                [ngClass]="{'golf-section__disable': isSearchResultNameFieldDisabled}">
                <input type="text" matInput maxlength="50" [placeholder]="captions.firstName"
                  golfinputtype="firstcharcapitalise" name="FirstName" required formControlName="firstName">
                <mat-error>{{errorCaptions.firstNameError}}</mat-error>
              </mat-form-field>
              <mat-form-field class="golf-form-control--xs"
                [ngClass]="{'golf-section__disable': isSearchResultNameFieldDisabled}">
                <input type="text" matInput maxlength="50" [placeholder]="captions.lastName"
                  golfinputtype="firstcharcapitalise" name="LastName" formControlName="lastName" required>
                <mat-error>{{errorCaptions.lastNameError}}</mat-error>
              </mat-form-field>
              <mat-form-field class="golf-form-control--xs"
                [ngClass]="{'golf-section__disable': isSearchResultNameFieldDisabled}">
                <input type="text" matInput maxlength="50"  [placeholder]="captions.pronounced" name="Pronounced"
                  formControlName="pronounced">
              </mat-form-field>
            </div>
  
            <div *ngIf='playerInfoForm.controls.playerTypeRadio.value === selectedGuestType.member && memberShipDetails'
              class="playerDetail-form-label">           
              <div class="member-details">
                <label class="golf-form--label textellipsis"
                  [matTooltip]="settingsCaptions.MembershipStatus">{{settingsCaptions.MembershipStatus}}</label>
                <div>{{memberShipDetails.membershipStatus}}</div>
              </div>
              <div class="member-details">
                <label class="golf-form--label textellipsis"
                    [matTooltip]="settingsCaptions.MembershipType">{{settingsCaptions.MembershipType}}</label>
                <div>{{memberShipDetails.membershipType}}</div>
              </div>              
            </div>
          
            <div *ngIf='playerInfoForm.controls.playerTypeRadio.value === selectedGuestType.member && memberShipDetails'
              class="playerDetail-form-label">
              <div class="member-details">
                <label class="golf-form--label textellipsis"
                  [matTooltip]="settingsCaptions.MembershipExpiryDate">{{settingsCaptions.MembershipExpiryDate}}</label>
                <div>{{memberShipDetails.membershipExpiryDate}}</div>
              </div>
              <div class="member-details">
                <label class="golf-form--label textellipsis"
                  [matTooltip]="settingsCaptions.MembershipRenewalDate">{{settingsCaptions.MembershipRenewalDate}}</label>
                <div>{{memberShipDetails.membershipRenewalDate}}</div>
              </div>
            </div>
  
            <div *ngIf='playerInfoForm.controls.playerTypeRadio.value === selectedGuestType.member && memberShipDetails'
            class="playerDetail-form-label">
            <div class="member-details">
              <label class="golf-form--label textellipsis"
                [matTooltip]="settingsCaptions.creditBookBalance">{{settingsCaptions.creditBookBalance}}</label>
              <div>{{memberShipDetails.creditBookBalance | Currency}}</div>
            </div>
            <div class="member-details">
              <label class="golf-form--label textellipsis"
                [matTooltip]="settingsCaptions.availableRounds">{{settingsCaptions.availableRounds}}</label>
              <div>{{memberShipDetails.golfPoints}}</div>
            </div>
          </div>
  
          <div>          
            <mat-form-field class="golf-form-control--xs">
              <mat-select [placeholder]="captions.playerType" name="PlayerType" formControlName="playerType"
                (selectionChange)="playerTypeChanged($event)">
                <mat-option *ngFor="let playerType of bindPlayerTypes()" [matTooltip]="playerType.name"
                  [value]="playerType.id">
                  {{playerType.name}}
                </mat-option>
              </mat-select>
              <mat-error>{{errorCaptions.playerTypeError}}</mat-error>
            </mat-form-field>
            <mat-form-field class="golf-form-control--xs">
              <mat-select [placeholder]="captions.rateType" name="RateType" formControlName="rateType"
                (selectionChange)="rateTypeChanged($event)">
                <mat-option
                  *ngFor="let rateType of (packageCode && packageCode != '' && packageRateTypes && packageRateTypes.length>0 ? packageRateTypes:rateTypes)"
                  [value]="rateType.id">
                  {{rateType.type }}
                </mat-option>
              </mat-select>
              <mat-error>{{errorCaptions.rateTypeError}}</mat-error>
            </mat-form-field>   
  
          </div>
            <div
              *ngIf="playerInfoForm.controls.playerTypeRadio.value === selectedGuestType.hotelReservation || playerInfoForm.controls.playerTypeRadio.value === selectedGuestType.player">
              <div class="patron-section">
                <mat-form-field class="golf-form-control--xs"
                  [ngClass]="playerInfoForm.controls.patronid.value ? 'button_invalid' : ''" class="button_invalid">
                  <input matInput [placeholder]="playerCaptions.PatronId" formControlName="patronid">
                </mat-form-field>            
                <button mat-button (click)="playerWorthDetails($event)" class="player-worth cursor"
                  [ngClass]="!playerInfoForm.controls.patronid.value || !cmsConfigured ? 'button_invalid' : ''"
                  matTooltip="{{playerCaptions.PlayerWorth}}">
                  <ng-container *ngIf="playerInfoForm.controls.patronid.value">
                    <span class="icon-Player-worth_enabled"><span class="path1"></span><span class="path2"></span></span>
                  </ng-container>
                  <ng-container *ngIf="!playerInfoForm.controls.patronid.value">
                    <span class="icon-Player-worth_disabled"><span class="path1"></span><span class="path2"></span></span>
                  </ng-container>
                </button>
              </div>
            </div>
  
            <!-- <app-retail-capture-card [isShowPMAgentValidationMessage]="false" [PaymentReferenceID]="PaymentReferenceID"
              [disableCardCapture]="this._lessonModalBusiness.isViewOnly || this.isCardOnFileDisabled"
              (NewPaymentReferenceID)="SaveReferenceId($event)"></app-retail-capture-card> -->
          </div>
  
  
        </div>
      </div>
    </div>
  </section>