import { Injectable } from '@angular/core';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';
import { CourseComment } from 'src/app/settings/golf-setup/course-comment/course-comment-modal/course-comment-model';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Injectable()
export class CourseCommentDataService {


    constructor(public _golfScheduleCommunication: GolfScheduleCommunication, public _localization: GolfLocalization) {
    }

    public getCourseCommentByCourseIdandDate(courseId: number, scheduleDate: Date): Promise<CourseComment> {
        let _date: string = this._localization.convertDateObjToAPIdate(scheduleDate);
        return this._golfScheduleCommunication.getPromise<CourseComment>(
                { route: GolfApiRoute.GetCourseCommentByCourseIdAndDate, uriParams: { 'courseId': courseId, 'scheduleDate': _date } });
         
    }
}