<div class="preview-wrapper" *ngIf="isAPILoaded">
        <section class="ReportSection d-block h-100" *ngIf="!reportViewDisable">
                <div class="report-container-row" *ngIf="this.pages.length != 0">
                        <div class="left-container">
                                <div class="report-print" (click)="reportDownload()"><i
                                                [ngClass]="{'active-print': ifPrintable}" aria-hidden="true" matTooltip="{{captions.Save}}"
                                                class="material-icons">save</i>
                                </div>                                
                                <div class="report-print" (click)="reportDownloadandPrint()">
                                        <i  class="icon-print ag_cursor--pointer"  matTooltip="{{captions.Print}}"></i>
                                      <!-- <i matTooltip="{{captions.Print}}" aria-hidden="true" class="material-icons">print</i> -->
                                </div>
                                <div class="report-print" (click)="downloadReportforPrint()"*ngIf="enableEmail">
                                        <i class="icon-email ag_cursor--pointer" (click)="reportEmail()"></i>
                        </div>
                                <div>
                        </div>
                        
                                <div class="download-container LW12" *ngIf="this.pages.length != 0 && ifPrintable"
                                        [ngClass]="{'active-printable': ifPrintable}">
                                        <mat-radio-group class="radio-class" aria-label="Select an option">
                                                <mat-radio-button value="1" (click)="downloadReport('PDF')">PDF
                                                </mat-radio-button>
                                                <mat-radio-button value="2" (click)="downloadReport('WORD')">
                                                        WORD
                                                </mat-radio-button>
                                                <mat-radio-button value="3" (click)="downloadReport('EXCEL')">
                                                        EXCEL
                                                </mat-radio-button>
                                                <mat-radio-button *ngIf="!isJasper" value="4" (click)="downloadReport('RAWDATA')">
                                                        EXPORT DATA
                                                </mat-radio-button>
                                        </mat-radio-group>
                                </div>
                                <div *ngIf="isSearchAvailable" class="report-search">
                                        <input [ngClass]="{'add-width': ifInputShow}" [(ngModel)]="findText"
                                                (keyup)="searchtext(findText)" type="text" />
                                        <i *ngIf="!ifCancelBool" class="material-icons" aria-hidden="true" matTooltip="{{captions.Search}}"
                                                (click)="searchContent()">search</i>
                                        <i *ngIf="ifCancelBool" class="material-icons" aria-hidden="true" matTooltip="{{captions.Cancel}}"
                                                (click)="searchContent(); searchtext('')">cancel</i>
                                </div>
                        </div>
                        <div class="right-container">
                                <div class="report-prev" matTooltip="{{captions.Previous}}"
                                        (click)="setPage(this.getPageNo('PREVIOUS'))"><i
                                                [ngClass]="{'active-icon': ispageReference == 'PREVIOUS' }"
                                                class="material-icons" aria-hidden="true">arrow_upward</i></div>
                                <div class="report-next" matTooltip="{{captions.Next}}"
                                        (click)="setPage(this.getPageNo('NEXT'))"><i
                                                [ngClass]="{'active-icon': ispageReference == 'NEXT' }"
                                                class="material-icons" aria-hidden="true">arrow_downward</i></div>
                                <div class="report-pagestart">
                                        <mat-form-field [floatLabel]="floatLabel" >
                                                <input matInput (scrollState)="setPage(pageNumber)"
                                                        (keyup.enter)="setPage(pageNumber)"
                                                        inputtype="onlynumber,nonnegative" [(ngModel)]="pageNumber">
                                        </mat-form-field>
                                </div>
                                <div class="report-pageend">{{this.pages.length -1}}</div>
                                <div class="report-zoomout" (click)="zoom('OUT')">
                                        <i [ngClass]="{'active-icon': isZoomReference == 'OUT' }" class="material-icons"
                                        aria-hidden="true"   matTooltip="{{captions.ZoomOut}}">
                                                remove
                                        </i>
                                </div>
                                <div class="report-zoomin" (click)="zoom('IN')">
                                        <i [ngClass]="{'active-icon': isZoomReference == 'IN' }" class="material-icons"
                                        aria-hidden="true"  matTooltip="{{captions.ZoomIn}}">
                                                add
                                        </i>
                                </div>
                        </div>
                </div>

                <div class="report d-flex justify-content-center" id="report" [ngClass]="[reportHTML ? 'bg-grey' : '',isJasper ? 'jasperHtml' :'']"
                        *ngIf="reportHTML">
                        <!-- <div class="textcenter-Web" id="textcenter-Web">
                                <ng-scrollbar [trackX]="true" [trackY]="true" [autoHide]="true" #scrollable
                                        (wheel)="moveToNextPage($event)">
                                        <div class="reportData" id="reportData" [innerHtml]="reportHTML"
                                                style="background: white"></div>
                                </ng-scrollbar>
                        </div> -->
                        <div class="textcenter-Web" id="textcenter-Web" #reportWrapper (scroll)="scrollPage($event)" 
                        (mouseup)="stopscrolling=false" (mousedown)="stopscrolling=true">
                                <div class="reportData" id="reportData" [innerHtml]="reportHTML"
                                style="background: white" ></div>
                        </div>
                </div>
                <div class="ag_display--none" id="jasperreportdata"></div>
                <div class="norecords-alignment" *ngIf="(reportHTML == '' && isAPILoaded && !isJasper) || (isJasper && isAPILoaded && jasperreportdata=='')">
                        <div class="textcenter-Web text-align-center nodata"
                                [attr.LiteralID]="'captions.NoRecordsToDisplay'">
                                {{this.captions.NoRecordsToDisplay}}
                        </div>
                </div>
        </section>
</div>
<div id="preview-cover-spin" *ngIf="!isAPILoaded">
        <div *ngIf="currentProductId!=versaProductId" id="cover-spin-retail">
                <div class="customspinnerimg">
                  <img src="./assets/images/agil_favicon.ico" style="width: 100%;">
                </div>
                <div id="default-message">{{captions.lbl_processing}}</div>
        </div>
       
        <div *ngIf="currentProductId==versaProductId" id="cover-spin-retail" class="giffy-loader" >
          <div class="customspinnerimg">
                            <ng-container >
                                <ng-container>
                                        <img class="defautTheme-ldr" alt="No Data" src="./assets/images/Versa-Generic-Loader-Versa.gif">
                                        <img class="spaTheme-ldr" alt="No Data" src="./assets/images/Versa-Generic-Loader-Spa.gif">
                                        <img class="golfTheme-ldr" alt="No Data" src="./assets/images/Versa-Generic-Loader-Golf.gif">
                                        <img class="blackwhiteTheme-ldr" alt="No Data" src="./assets/images/Versa-Generic-Loader-BW.gif">
                                        <img class="darkTheme-ldr" alt="No Data" src="./assets/images/Versa-Generic-Loader-Dark.gif">
                                </ng-container>  
                            </ng-container>
                            <div id="default-message">{{captions.lbl_processing}}</div>
           </div>
        </div>
        
</div>
