import { Injectable } from '@angular/core';
import { HandleRequest, HandleResponse, TokentransactionInfo, CreateTokenRequest, StoreTokenRequest, PaymentBaseResponse, CardInfo, SaleRequest, PaymentErrorCodes, SaleResponse } from '../../payment-model';
import { GolfRoutes } from 'src/app/core/extensions/golf-route';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfUtilities } from '../../utilities/golf-utilities';
import { DomSanitizer } from '@angular/platform-browser';
import { PayAgentCommunication } from '../../communication/services/payagent.service';
import { RetailmanagementCommunication } from '../../communication/services/retailmanagement.service';
import { RetailPosCommunication } from '../../communication/services/retailpos.service';
import { DomainRetailItem } from 'src/app/settings/settings-shared/assign-retail-item/assign-retail-item.model';
import { PaymentMethod, UserSessionConfiguration } from 'src/app/retail/retail.modals';
import { PaymentServiceCommunication } from '../../communication/services/payment-communication-services';
import { MultiPackDetails, DiscountConfiguration } from '../../models/unpaid-players.model';
import { Observable } from 'rxjs';
import { GatewayConfiguration } from 'src/app/retail/shared/service/payment/payment-model';
@Injectable({ providedIn: "root" })
export class PaymentBusinessService {
    private readonly payAgentURI: string = 'PAYAGENTURI';
    constructor(
        private _localization: GolfLocalization,
        private utils: GolfUtilities,
        private _payAgentHttp: PayAgentCommunication,
        private _paymentHttp: PaymentServiceCommunication,
        private _retailhttp: RetailmanagementCommunication,
        private _retailPOS: RetailPosCommunication,
        private _sanitizer: DomSanitizer
    ) { }

    private CEDS_Store = new Map<number, string>();

    private async TryGetCEDSValueAsync(defaultOutletId: number): Promise<string> {
        try {
            if (this.CEDS_Store.has(defaultOutletId)) {
                return this.CEDS_Store.get(defaultOutletId);
            } else {
                let response = await this._retailhttp.getPromise<any>({
                    route: GolfApiRoute.GetCEDSByOutletId,
                    uriParams: { outletId: defaultOutletId }
                });
                const CEDS_Value: string = response;
                this.CEDS_Store.set(defaultOutletId, CEDS_Value);
                return CEDS_Value;
            }
        } catch (error) {
            console.log(`Error occurred while retrieving CEDS value err:${error}`);
        }
    }

    async FormTenderId(tender: string, outletId: number) {
        const CEDS = await this.TryGetCEDSValueAsync(outletId);
        return (`${CEDS}.${tender}`);
    }

    /**
     * @function GetHandles async
     * @returns HandleResponse
     * @param handleRequestBody HandleRequest
     * @param outletId number
     * @description Returns the list of available devices
     */
    async GetHandles(handleRequestBody: HandleRequest, outletId = 0, handleErr: boolean = false): Promise<HandleResponse> {
        handleRequestBody.tenderId = await this.FormTenderId(handleRequestBody.tenderId, outletId);
        let uri = this.BuildPMAgentURI(APIAction.GetHandles);
        return this._payAgentHttp.postPromise<HandleResponse>({
            route: uri,
            body: handleRequestBody,
            uriParams: { outletId: outletId }
        }, handleErr);
    }

    /**
     * @function CreateToken async
     * @returns TokentransactionInfo
     * @param handle string
     * @param tenderID number
     * @param outletId number
     * @description Communicates with PMAgent to lights up the device for capturing the card
     */
    async CreateToken(handle: string, tenderID: number, outletId = 0, manualCardEntry = false, isPartialTenderAllowed = false): Promise<TokentransactionInfo> {
        let requestBody: CreateTokenRequest;
        requestBody = { handle: handle, inquirerInfo: { TenderId: tenderID.toString(), manualCardEntry: manualCardEntry, isPartialTenderAllowed: isPartialTenderAllowed } };
        requestBody.inquirerInfo.TenderId = await this.FormTenderId(requestBody.inquirerInfo.TenderId, outletId);
        let uri = this.BuildPMAgentURI(APIAction.CreateToken);
        return this._payAgentHttp.postPromise<any>({
            route: uri,
            body: requestBody,
            uriParams: { outletId: outletId }
        }, false);
    }

    /**
     * @function StoreToken async
     * @returns PaymentBaseResponse
     * @param storeTokenRequest StoreTokenRequest
     * @description Communicates with Payment microservice for storing the token retrieved from PMAgent/Device
     */
    async StoreToken(storeTokenRequest: StoreTokenRequest): Promise<PaymentBaseResponse> {
        const response: any = await this._paymentHttp.postPromise<any>({
            route: GolfApiRoute.StoreToken,
            body: storeTokenRequest
        });
        if (response) {
            return response;
        }
        return null;
    }

    /**
     * @function GetCardInfo async
     * @returns CardInfo
     * @param tokenRefId number
     * @description Communicates with Payment microservice to get the token based on the reference ID
     */
    async GetCardInfo(tokenRefId: number): Promise<CardInfo> {
        const response: any = await this._paymentHttp.getPromise<any>({
            route: GolfApiRoute.GetCardInfo,
            uriParams: { tokenTransId: tokenRefId }
        });
        return response ? response : null;
    }

    /**
     * @function RequestSale async
     * @returns SaleResponse
     * @param payRequest SaleRequest
     * @param outletId number
     * @description Communicates with PMAgent to light up the device for sale
     */
    async RequestSale(payRequest: SaleRequest, outletId = 0): Promise<SaleResponse> {
        payRequest.inquirerInfo.tenderId = await this.FormTenderId(payRequest.inquirerInfo.tenderId, outletId);
        let uri = this.BuildPMAgentURI(APIAction.RequestSale);
        return this._payAgentHttp.postPromise<any>({
            route: uri,
            body: payRequest,
            uriParams: { outletId: outletId }
        });
    }

    /**
     * @function MaskCreditCardNumber
     * @returns string
     * @param cardNo string
     * @description Formats the card number for display purpose
     */
    MaskCreditCardNumber(cardNo: string): string {
        cardNo = 'XXXX-XXXX-XXXX-' + cardNo.substring(cardNo.length - 4, cardNo.length);
        return cardNo;
    }

    /**
     * @function ValidateCreditCard
     * @returns boolean
     * @param cardInfo CardInfo
     * @description Validates the given card details for its validity, based on the expiry date
     */
    ValidateCreditCard(cardInfo: CardInfo): boolean {
        if (cardInfo.cardExpiration && cardInfo.cardExpiration.length === 6) { // Check the returned date is of format YYYYMM
            // Check Expiry date
            const cardMonth = Number(cardInfo.cardExpiration.substring(4, 6)) - 1;
            const cardYear = Number(cardInfo.cardExpiration.substring(0, 4));
            const currentDate = this.utils.getCurrentDate();
            if (new Date(cardYear, cardMonth, 0).getTime() < new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getTime()) {
                let errMsg = this._localization.getError(10720); //BUG - 27519 - Changes
                this.utils.showError(errMsg);
                return false;
            }
        }
        return true;
    }

    /**
     * @function formatCreditCardExpiryDate
     * @returns string
     * @param date string
     * @description formats the given expiry date for display purpose
     */
    formatCreditCardExpiryDate(date: string): string {
        if (date && date.length === 6) {
            return date.substring(4, 6) + '/' + date.substring(2, 4); // MM/YY
        }
        return date;
    }

    /**
     * @function BuildPMAgentURI
     * @returns GolfRoutes
     * @param action APIAction
     * @param outletId number
     * @description constructs the URI based on the API Action specified
     */
    private BuildPMAgentURI(action: APIAction): GolfRoutes {
        let completeURI = '';
        switch (action) {
            case APIAction.RequestSale:
                completeURI = GolfApiRoute.Sale;
                break;
            case APIAction.CreateToken:
                completeURI = GolfApiRoute.CreateToken;
                break;
            case APIAction.GetHandles:
                completeURI = GolfApiRoute.GetHandles;
                break;
            case APIAction.Credit:
                completeURI = GolfApiRoute.Credit;
                break;
        }
        return completeURI as GolfRoutes;
    }

    /**
     * @function PaymentErrorPrompt
     * @param errcodes PaymentErrorCodes
     * @description Displays the error popup based on the error code
     */
    PaymentErrorPrompt(errcodes) {
        switch (parseInt(errcodes)) //TODO
        {
            case PaymentErrorCodes.ClientInvalidRequest:
                return this.utils.showError(this._localization.captions.shop.PMInvalidRequest);
            case PaymentErrorCodes.DeviceNotAvailable:
                return this.utils.showError(this._localization.captions.shop.PMDeviceNotAvailable);
            case PaymentErrorCodes.DeviceNotReady:
                return this.utils.showError(this._localization.captions.shop.PMDeviceBusy);
            case PaymentErrorCodes.DeviceUserPressedCancel:
                return this.utils.showError(this._localization.captions.shop.PMDeviceCancel);
            case PaymentErrorCodes.TransactionTimeOut:
                return this.utils.showError(this._localization.captions.shop.PMTransactionTimeOut);
            case PaymentErrorCodes.DeviceError:
            case PaymentErrorCodes.CardErrorInvalidAccountNumber:
            case PaymentErrorCodes.CardErrorInvalidExpirationDate:
            case PaymentErrorCodes.CardError:
            case PaymentErrorCodes.CardErrorUnsupportedCardType:
            case PaymentErrorCodes.CardErrorUnsupportedCardIssuer:
                return this.utils.showError(this._localization.getError(10721));
            case PaymentErrorCodes.DeviceOperationAborted:
                return this.utils.showError(this._localization.captions.shop.PMDeviceTimeOut);
            case PaymentErrorCodes.CardDeclinedLimit:
                return this.utils.showError(this._localization.captions.shop.InsufficientFunds);
            case PaymentErrorCodes.GatewayAuthenticationFailed:
                return this.utils.showError(this._localization.captions.shop.PMAuthFailed);
            case PaymentErrorCodes.CardDeclinedChipDecline:
                return this.utils.showError(this._localization.captions.shop.PMcardDeclinedChipDecline);
            case PaymentErrorCodes.PaymentManagerTimedOut:
                return this.utils.showError(this._localization.captions.shop.PMAgentUnavailable);
            case PaymentErrorCodes.DeviceInvalidData:
            default:
                return this.utils.showError(this._localization.captions.shop.PMUnexpectedError);
        }
    }

    /**
     * @function RequestCredit async
     * @returns SaleResponse
     * @param payRequest SaleRequest
     * @param outletId number
     * @description Communicates with PMAgent to light up the device for credit
     */
    async RequestCredit(payRequest: SaleRequest, outletId = 0): Promise<any> {
        payRequest.inquirerInfo.tenderId = await this.FormTenderId(payRequest.inquirerInfo.tenderId, outletId);
        let uri = this.BuildPMAgentURI(APIAction.Credit);
        return this._payAgentHttp.postPromise<any>({
            route: uri,
            body: payRequest,
            uriParams: { outletId: outletId }
        });
    }

    GetSanitizedCardInfoHTML(CurrentActiveCard: CardInfo) {
        let CardOnFileTemplate = this._localization.captions.shop.ProceedWithCardOnFile + `</br>
                                <div class="saved-card">
                                    <div class="saved-card-details">
                                        <span>${CurrentActiveCard.cardNumber}</span>
                                    </div>
                                </div>`
        // return this._sanitizer.bypassSecurityTrustHtml(CardOnFileTemplate);
        return CardOnFileTemplate;
    }

    /**
     * @description Retrieves the Credit card configuration details from Payment Microservice
     * @returns GatewayConfiguration
     */
    public async getCreditCardConfiguration(): Promise<GatewayConfiguration> {
        let result: GatewayConfiguration = await this._paymentHttp.getPromise<GatewayConfiguration>({
            route: GolfApiRoute.GatewayConfiguration
        })
        result.gatewayValues = result.gatewayValues || [];
        return result;
    }

    /**
     * @description Creates or Updates the Credit card configuration details into Payment Microservice
     * @returns GatewayConfiguration
     */
    public async createOrUpdateCreditCardConfiguration(body: GatewayConfiguration): Promise<GatewayConfiguration> {
        return await this._paymentHttp.putPromise<GatewayConfiguration>({
            route: GolfApiRoute.GatewayConfiguration,
            body: body
        });
    }

    /**
     * @description Returns the retail items by Ids
     * @param retailItemIds
     */
    async GetRetailItemsByIds(retailItemIds: number[]) {
        return this._retailhttp.putPromise<any[]>({
            route: GolfApiRoute.GetAllActiveRetailItemsByIds,
            body: retailItemIds
        });
    }

    async GetRetailItemsDetailedInfoByIds(retailItemIds: number[]) {
        return this._retailhttp.putPromise<any[]>({
            route: GolfApiRoute.GetRetailItemDetailedInfoList,
            body: retailItemIds
        });
    }

    /**
    * @description Retrieves the Multi Pack Retail Items
    */
    async GetTeeTimeMultiPack(multiPackTransactionDetailId: number[]): Promise<MultiPackDetails[]> {
        return await this._retailhttp.putPromise<MultiPackDetails[]>({
            route: GolfApiRoute.GetTeeTimeMultiPack,
            body: multiPackTransactionDetailId
        });
    }

    /**
     * @description Retrieves the Shop items based on item type
     */
    async GetRetailItemByItemType(itemType: number) {
        return await this._retailhttp.getPromise<any>({
            route: GolfApiRoute.GetRetailItemByItemType,
            uriParams: { type: itemType }
        });
    }

    async getAllTransactions(status: TransactionStatus, date: string) {
        return await this._retailhttp.getPromise<any>({
            route: GolfApiRoute.GetAllTransactions,
            uriParams: { status: status, outletId: 0, transactionDate: date }
        });

    }

    async getPackageItemsforItem(itemids: number[]) {
        return await this._retailhttp.putPromise<any>({
            route: GolfApiRoute.GetRetailItemDetailedInfoList,
            body: itemids
        });
    }


    /**
     * @description Retrieves the Shop items based on transactionId
     */
    async GetTransactionDetails(transactionId: number, productId: number) {
        return await this._retailhttp.getPromise<any>({
            route: GolfApiRoute.GetTransactionDetails,
            uriParams: { transactionId: transactionId, productId: productId },
        });
    }

    /**
     * @description Retrieves the retail transaction based on transactionId
     * @param transactionId number
     */
    async GetTransactionById(transactionId: number) {
        return await this._retailPOS.getPromise<any>({
            route: GolfApiRoute.GetTransactionById,
            uriParams: { transactionId: transactionId },
        });
    }

    /**
     * @description Retrieves the retail transaction based on transactionIds
     * @param transactionIds number[]
     */
    async GetTransactionsByIds(transactionIds: number[]) {
        return await this._retailPOS.putPromise<any>({
            route: GolfApiRoute.GetTransactionsByIds,
            body: transactionIds
        });
    }

    async GetPaymentHistory(transactionId: number) {
        return await this._retailPOS.getPromise<any>({
            route: GolfApiRoute.PaymentHistory,
            uriParams: { transactionId: transactionId },
        });
    }

    async getRetailItemById(id: number): Promise<DomainRetailItem> {
        return await this._retailPOS.getPromise<DomainRetailItem>({
            route: GolfApiRoute.GetRetailItemById,
            uriParams: { "itemId": id }
        });
    }

    async getUserSessionConfiguration(userId: number): Promise<UserSessionConfiguration> {
        let result = await this._retailhttp.getPromise<UserSessionConfiguration>({
            route: GolfApiRoute.GetUserSessionConfiguration,
            uriParams: { "userId": userId }
        });
        if (result === null) {
            result = {
                id: 0,
                userId: userId,
                defaultOutletId: 0,
                defaultCourseId: 0,
                defaultPaymentDevice: '',
                defaultDeviceName: '',
                isIdtechSred: false,
                smallStickersPrinter: '',
                hangingTicketsPrinter: '',
                propertyId: 0
            }
        }
        return result;
    }
    async getCCConfiguration() {
        return await this._paymentHttp.getPromise<GatewayConfiguration[]>({
            route: GolfApiRoute.GatewayConfiguration
        })
    }

    async GetPaymentMethods(): Promise<any[]> {
        return await this._paymentHttp.getPromise<any[]>({
            route: GolfApiRoute.PaymentMethod
        });
    }

    public getUserDefinedPaymentMethod(): Observable<PaymentMethod[]> {

        return this._paymentHttp.getObservable<PaymentMethod[]>({
            route: GolfApiRoute.PaymentMethod
        });
    }

    public async MarkAsPaid(lstMarkAsPaid: any): Promise<any[]> {
        return await this._retailPOS.postPromise<any[]>(
            {
                route: GolfApiRoute.AutoRetailTransaction,
                body: lstMarkAsPaid
            });
    }
    public async GetTransactionDepositDetails(transactionIds: any): Promise<any[]> {
        return await this._retailPOS.postPromise<any[]>(
            {
                route: GolfApiRoute.GetTransactionDepositDetails,
                body: transactionIds
            });
    }
    public async GetDiscountConfigurations(date: string, retailItemId: number, type: string): Promise<DiscountConfiguration>{
        return await this._retailhttp.getPromise<DiscountConfiguration>(
            {
                route: GolfRoutes.GetDiscountConfigurationsForRetailItem,
                uriParams: {date: date, retailItemId: retailItemId, type: type}
            }
        )
    }

}

type TransactionStatus = 'OPEN' | 'CLOSED';

enum APIAction {
    RequestSale = 1,
    GetHandles,
    CreateToken,
    Credit
}
