import { IAppService,Hosts } from './common/app-service';
import { Product } from './shared/global.constant';
import { EventActorsDataProvider } from './common/Models/notification.model';
import { GolfEventActorDataProvider } from './golf-eventactor-dataprovider';
import { GolfUtilities } from './shared/utilities/golf-utilities';
import { GolfLocalization } from './core/localization/golf-localization';
import { environment } from 'src/environments/environment';

export class GolfAppService implements IAppService {

    constructor(private utilities: GolfUtilities, private golfLocalization:GolfLocalization) {
    }

    get hosts(): Hosts {
        return <Hosts>{
            TenantManagement: environment["TenantManagement"],
            Report: environment["Report"],
            Common:environment["Common"],
            Payment: environment["Payment"],
            CommonGateway: environment["CommonGateway"],
            RetailManagement: environment["retailManagement"]
        }
    }
    
    get productId(): number {
        return Product.GOLF;
    }

    get notificationEventDataProvider(): EventActorsDataProvider {
        return { providers: new GolfEventActorDataProvider(this.utilities,this.golfLocalization).providers };
    }

   }
