import { Component, OnInit, Output, EventEmitter, Inject, ViewEncapsulation, ChangeDetectorRef, ViewChild, Renderer2 } from '@angular/core';
import { MovePlayerBusiness } from './move-player-modal.business';
import { MovePlayerService } from './move-player-modal.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { DatePickerInputs, Captions, PlayerDetails, PlayerTeeTimeSlot } from './move-player-modal.model';
import { ReplaySubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StepperService } from 'src/app/shared/components/stepper/stepper.service';
import { TeeSheetTableID, TeeSheetCustomData, TeeSheetSkeletonData, TeeSheetGridContent, TeeSheetPlayerDetails, TeeSheetDragDropEvt, TeeSheetModications, DragOrigin, TeeTimeFormat, ScheduledPlayer, MoveRateTypeChangeSelection } from 'src/app/shared/models/teesheet.form.models';
import * as _ from 'lodash';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogCloseObj, SqueezeRefType, GolfPMSSessionInfo, AlertType, ButtonType, AlertAction } from 'src/app/shared/shared-models';
import { Course } from 'src/app/settings/golf-setup/code-setup/course/create-course-modal/create-course-model'
import { CourseDataService } from 'src/app/shared/data-services/golfschedule/course.data.service';
import { TeeSheetMove } from 'src/app/tee-time/shared/tee-sheet/tee-sheet.move';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { TeeTimesActionService } from 'src/app/shared/data-services/golfschedule/teeTimesAction.data.service';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { TeeGridService } from 'src/app/shared/components/tee-grid/tee-grid.service';
import { TeeTimeAction, ValidationResult, CopyMoveResult, PlayersNewRate, PlayersCaddyInfo } from 'src/app/shared/models/teesheet.api.models';
import { TeeTimesActionBusiness } from 'src/app/tee-time/shared/tee-action.business';
import { NotifierBar } from 'src/app/shared/components/note/note.model';
import { TeeTimesBulkGroupBusiness } from 'src/app/tee-time/shared/tee-bulkGroup.business';
import { AllocationCode, status, Bulkstatus } from 'src/app/tee-time/tee-sheet/bulk-tee-time/bulk-tee-time.model';
import { TeeSheetBulk } from 'src/app/tee-time/shared/tee-sheet/tee-sheet.bulk';
import { TeeTimeDataService } from 'src/app/shared/data-services/golfschedule/TeeTime.data.service';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { ButtonAction,SettingModule,SettingScreen } from 'src/app/shared/global.constant';
import { GolfImageService } from 'src/app/shared/data-services/Image/golf.Image.service';
import { SendNotificationMailSmsComponent } from 'src/app/shared/components/send-notification-mail-sms/send-notification-mail-sms.component';
import { SettingsDataService } from 'src/app/shared/data-services/golfschedule/settings.data.service';
import { BulkTeeTimeService } from 'src/app/tee-time/tee-sheet/bulk-tee-time/bulk-tee-time.service';
import { TeeTimeService } from '../teetime/tee-time.service';
import { NotificationConfigurationService } from 'src/app/common/templates/notification-configuration/notification-configuration.service';
import { EventNotificationGroup, EventNotification } from 'src/app/common/templates/notification-configuration/notification-configuration.model';
import { PMSAction } from 'src/app/common/external-request/pms-request.model';
import { TeeSheetUtilities } from 'src/app/shared/utilities/teesheet.utilities';
import { PlayerTypes as PlayerType, RateSetupData, RateType } from '../teetime/player-details/player-info.model';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import { CourseCommentAPI } from 'src/app/settings/golf-setup/course-comment/course-comment-modal/course-comment-model';
import { CourseCommentDataService } from 'src/app/shared/data-services/golfschedule/courseComment.data.service';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { allocationBlockPlayerTypePermission } from 'src/app/settings/user-setup/booking-permission/booking-permission.model';
import { AllocationBlockWithPlayerType } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import { OverrideDetails, OverrideType } from '../teetime/tee-time.model';
import { UserAccessModel } from 'src/app/common/dataservices/authentication/useraccess-model.model';
import { CodeLinkingDataService } from 'src/app/shared/data-services/golfschedule/codelinking.data.service';
import { RateSetupDataService } from 'src/app/shared/data-services/golfschedule/rate-setup.data.service';
import { PlayerRateModalComponent } from '../player-rate-modal/player-rate-modal.component';
import { guestType, playerCategory } from 'src/app/lessons/lessons-modal/player-info/player-info.model';
import { LinkMultipackBusiness } from '../link-multipack/link-multipack-business';
import { ButtonTypes } from 'src/app/common/enums/shared-enums';
import { RetailBussinessService } from 'src/app/shared/retail.bussiness';
import { PlayerPaymentstatus } from '../../tee-time/search/search-model';
import { TeeSheetSharedBusiness } from 'src/app/tee-time/shared/teesheet.shared.business';
import { CartUtilities } from 'src/app/common/components/menu/vcart/cart.utilities';
import { PlayerRetailItem } from '../edit-retail-item/edit-retail-item-model';



@Component({
  selector: 'app-move-player-modal',
  templateUrl: './move-player-modal.component.html',
  styleUrls: ['./move-player-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MovePlayerBusiness, MovePlayerService, CourseDataService, TeeSheetMove, TeeSheetBulk, TeeTimesActionService, TeeTimesBulkGroupBusiness
    , TeeTimeDataService, TeeGridService, SettingsDataService, BulkTeeTimeService, TeeTimeService, NotificationConfigurationService, TeeSheetUtilities, CourseCommentDataService,
    CodeLinkingDataService, RateSetupDataService]
})
export class MovePlayerModalComponent implements OnInit {
  @Output() notifyParent = new EventEmitter();
  captions: Captions;
  movePlayersForm: UntypedFormGroup;
  courses: Promise<Course[]>;
  dateInputs: DatePickerInputs;
  date: Date | string;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  minDate: Date;
  maxDate: Date;
  playerDetails: PlayerDetails[];
  playerList: TeeSheetPlayerDetails[];
  teeSheetID: TeeSheetTableID;
  customTableData: TeeSheetCustomData;
  skeletonData: Observable<TeeSheetSkeletonData[]>;
  playerData: Observable<TeeSheetGridContent[]>;
  draggedPlayersList: any;
  courseId: number;
  PlayerTeeTimeSlot: PlayerTeeTimeSlot[];
  selectedPlayers: any = [];
  existingDate: any;
  existingCourse: any;
  lblcaptions: any;
  mapVal = new Map();
  playerCollectionList: any[] = [];
  notificationbar: NotifierBar;
  notificationFlag: boolean = false;
  isAutoBookAvailable: boolean = false;
  teeTimeFormat: TeeTimeFormat = TeeTimeFormat.None;
  scheduledPlayers: ScheduledPlayer[];
  bulkTee: TeeTimeFormat = TeeTimeFormat.BulkTee;
  bookingId: string;
  modifiedSlots: TeeSheetSkeletonData[] = [];
  playersPerGroup: number = 4;
  lastUpdatedSlots: TeeSheetSkeletonData[] = [];
  courseTooltip: string;
  bFailure: boolean = false;
  playerInfo: TeeSheetPlayerDetails[];
  unHandledErrorCode: -2;
  bulkStatus: Bulkstatus;
  squeezeSlots: TeeSheetSkeletonData[];
  dragDropObj: any;
  isBulkSectionDisabled: boolean = false;
  popUpComponentDetails: { componentName: any; popUpDetails: { isStepper: boolean; eventName: string; bindData: any; }; };
  eventNotificationGroup: EventNotificationGroup[] = [];
  action: TeeTimeAction;
  mixedPlayerSlot: boolean = false;
  bulkPlayerUserAccess: UserAccessModel.BreakPointResult;
  normalPlayerUserAccess: UserAccessModel.BreakPointResult;
  infoStripAvailable: boolean = false;
  @ViewChild('infoElement') infoEle: any;
  courseComments: Promise<CourseCommentAPI[]>;
  selectedCourseComment: string;
  notifierBar: NotifierBar;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  allocationCodePermissions: allocationBlockPlayerTypePermission[] = [];
  isAllocationBlockEnabled: boolean;
  allocationBlockPlayerTypes: AllocationBlockWithPlayerType[] = []
  overridePlayerTypeDaysoutUserAccess: UserAccessModel.BreakPointResult;
  overrideRateTypeDaysoutUserAccess: UserAccessModel.BreakPointResult;
  playerTypes: PlayerType[] = [];
  quickloginUserId: number;
  modifiedPlayerTeeTimelots: PlayerTeeTimeSlot[];
  playersNewRate: PlayersNewRate[] = [];
  useUpdatedCaddyInfo: boolean = false;
  playersCaddyInfo: PlayersCaddyInfo[] = [];
  multiPackResponse: any;
  playerResponse: any;
  rateSetup: RateSetupData[];
  floatLabel: string;
  rateTypes: RateType[];
  playerRetailItems : PlayerRetailItem[];
  isFromMove : boolean = true;
  constructor(private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public dialogData: any, private _MovePlayerBusiness: MovePlayerBusiness, private _StepperService: StepperService
    , private _localization: GolfLocalization, private _utilities: GolfUtilities, public _TeeGridService: TeeGridService, private _teeTimesActionBusiness: TeeTimesActionBusiness, private cdr: ChangeDetectorRef, private _PropertyInformation: GolfPropertyInformation, private _imageService: GolfImageService,
    private _NotificationConfigurationService: NotificationConfigurationService, public _dialog: MatDialog, public _teeSheetUtilities: TeeSheetUtilities,
    private rendrer: Renderer2, public quickLoginUtils: QuickLoginUtilities, private propertyInformation: RetailPropertyInformation,
    private _linkMultipackBusiness: LinkMultipackBusiness, 
    private _retailBusinessService : RetailBussinessService,
    private _teeSheetSharedBusiness: TeeSheetSharedBusiness,
    private cartUtils: CartUtilities,
    public dialogRef: MatDialogRef<MovePlayerModalComponent>) {
    this.floatLabel = this._localization.setFloatLabel;
  }

  async ngOnInit() {
    this.createMovePlayersForm();
    this.captions = this._MovePlayerBusiness.getCaptions();
    if (this.dialogData.info.playerDetail && this.dialogData.info.playerDetail.length > 0) {
      this.dialogData.info.playerDetail.forEach(element => {
        element.isDragDisabled = false;
      });
    }
    this.lblcaptions = this._localization.captions.teetime;
    this.rateTypes = await this._MovePlayerBusiness.getRateTypes();
    this.loadAllocationPermissions();
    this.playerDetails = this._MovePlayerBusiness.getPlayersDetails(_.cloneDeep(this.dialogData.info));
    let bulktee = this.dialogData.info.playerDetail.filter(x => !x.isBlocked && x.teeTimeFormat === TeeTimeFormat.BulkTee);
    let normalTee = this.dialogData.info.playerDetail.filter(x => !x.isBlocked && x.teeTimeFormat !== TeeTimeFormat.BulkTee);
    const distinctThings = _.uniq(_.map(this.dialogData.info.playerDetail.filter(x => !x.isBlocked), 'confirmationNumber'));
    this.bulkPlayerUserAccess = this.dialogData.bulkPlayerUserAccess;
    this.normalPlayerUserAccess = this.dialogData.normalPlayerUserAccess;
    if (bulktee.length > 0 && normalTee.length > 0 || (bulktee.length > 0 && distinctThings && distinctThings.length > 1)) {
      this.mixedPlayerSlot = true;
      this.teeTimeFormat = TeeTimeFormat.None;
    }
    else if (bulktee.length > 0 && normalTee.length == 0) {
      this.teeTimeFormat = TeeTimeFormat.BulkTee;
      this.playersPerGroup = bulktee[0].playersPerGroup;
    }
    this.minDate = this._PropertyInformation.CurrentDTTM;
    this.existingDate = this.date = this._localization.getDate(this.dialogData.info.time);
    
    const pMSRequestInfo: GolfPMSSessionInfo = this._utilities.getPMSSessionInfo();
    this.preLoadControlsForPMSRequest(pMSRequestInfo);
    this.dateInputs = {
      className: 'golf-form-control--sm',
      form: this.movePlayersForm,
      formControlName: 'dateControl',
      minDate: this.minDate,
      maxDate: this.maxDate,
      placeHolder: this.captions.date,
      value: this._PropertyInformation.CurrentDTTM,
      automationId:"MovePlayer"
    };
    await this.getPlayers();
    this.selectPlayers(true);
    await this.getMasterData();
    this.existingCourse = this.courseId = this.dialogData.info.course.id;
    this.courses = this._MovePlayerBusiness.getCourses();
    this.teeSheetID = this._MovePlayerBusiness.getTeeSheeetID();
    this.customTableData = this._MovePlayerBusiness.getTeeSheetCustomTableData(this.dialogData.info);
    this.playerResponse = await this._linkMultipackBusiness.GetPlayersByIds(this.dialogData.info.playerDetail.map(c => c.playerId));
    this.playerResponse = this.playerResponse.filter(c => c.playerCategoryId != playerCategory.newPlayer);
    this.playerResponse = [...this.playerResponse];
    this.multiPackResponse = await this.getMultiPackDetails(this.playerResponse);
    this.pageSubscriptions();
    this.onPageLoad();
    this.notificationbar = {
      class: '',
      value: this.lblcaptions.playersMoveUpdatednotifications,
      color: "#fff16e",
      isRemovable: true
    }
    if (this.teeTimeFormat === TeeTimeFormat.BulkTee) {
      this.customTableData.isBulkMoveEnabled = true;
      this.customTableData = { ... this.customTableData };
    }

    setInterval(() => {
      this.notificationFlag = false;
    }, 7000);
    this.action = TeeTimeAction.move;
  }

  async getMultiPackDetails(player: any) {
    const session: any[] = [];
    player.forEach(element => {
      session.push(
        {
          guestId: element.playerLinkId,
          guestType: element.playerCategoryId == 3 ? guestType.member : guestType.guest
        }
      );
    });
    return await this._linkMultipackBusiness.GetMultiPackDetails(session.distinct());
  }

  async getMasterData() {
    this.overridePlayerTypeDaysoutUserAccess = await this._teeTimesActionBusiness.validateBreakPointAccess(UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT, false);
    this.playerTypes = await this._teeTimesActionBusiness.getPlayerTypes();
    this.overrideRateTypeDaysoutUserAccess = await this._teeTimesActionBusiness.validateBreakPointAccess(UserAccessBreakPoints.OVERRIDERATETYPEDAYSOUT, false);
  }

  async loadAllocationPermissions() {
    this.isAllocationBlockEnabled = this._utilities.IsAllocationCodePermissionEnabled();
    if (this.isAllocationBlockEnabled) {
      this.allocationBlockPlayerTypes = await this._MovePlayerBusiness.GetAllocationsBlockWithPlayerTypes();
      this.allocationCodePermissions = this.dialogData.allocationCodePermissions;}
  }

  private preLoadControlsForPMSRequest(pMSRequestInfo: GolfPMSSessionInfo) {
    if (pMSRequestInfo && pMSRequestInfo.action == PMSAction.RescheduleReservation && pMSRequestInfo.stayFromDate && pMSRequestInfo.stayToDate) {
      this.minDate = pMSRequestInfo.stayFromDate;
      this.maxDate = pMSRequestInfo.stayToDate;
    }
  }

  async GetBulkPlayers(bookingId?: string) {
    this.bookingId = bookingId ? bookingId : this.dialogData.info.playerDetail.filter(x => !x.isBlocked && x.teeTimeFormat == TeeTimeFormat.BulkTee)[0].bookingId;
    await this.getBulkPlayers(this.bookingId);
    this.scheduledPlayers = _.sortBy(this.scheduledPlayers, function (obj) {
      return [new Date(obj.scheduledDateTime), obj.playerSlotPosition];
    });
    let bulkplayers = this._MovePlayerBusiness.mapTeeSheetplayerDetails(this.scheduledPlayers, this.rateTypes);
    this.playerInfo = this.playerList = this._MovePlayerBusiness.getPlayerList(bulkplayers);
  }

  async getPlayers(isSaveClicked?) {
    let players: TeeSheetPlayerDetails[];
    if (this.teeTimeFormat === TeeTimeFormat.BulkTee) {
      await this.GetBulkPlayers();
    }
    else {
      if (isSaveClicked && this.modifiedSlots && this.modifiedSlots.length > 0) {
        players = this.modifiedSlots.find(x => x.time === this.dialogData.info.time && x.course.id === this.dialogData.info.course.id
          && x.hole === this.dialogData.info.hole).playerDetail;
        players.forEach(x => x.isDragDisabled = false);
      }
      else {
        let fullPlayers = _.cloneDeep(this.dialogData.info.playerDetail);
        players = fullPlayers.filter(x => !x.isBlocked && !x.isCheckedIn && !x.isCheckedOut);
      }
      players.forEach(x=>x.time = this.dialogData.info.time);
      players.forEach(x=>x.courseId = this.dialogData.info.course.id);
      this.playerInfo = this.playerList = this._MovePlayerBusiness.getPlayerList(players);
      this.playerList.forEach(players => {
        players['playerClicked'] = false;
      });
    }
    this._StepperService.setBackEnable(false);
    this.playerList = _.cloneDeep(this.playerList);
    this.mixedPlayerSlot = _.cloneDeep(this.mixedPlayerSlot);
    // Call this method to get Profile Images - Parameters(list of player that needs profile images, reference id from API response)
    this._imageService.getProfileImages(this.playerList, "imageReferenceId");
  }

  ngAfterViewInit() {
    // By Default on Page Load Set NotifyParent to false to disable Save Button
    this.notifyParent.emit(false);
    this.updateUserAccessInfo();
  }

  onSqueezeClose(data) {
    if (data.fromtype === SqueezeRefType.squeeze) {
      let selectedMins = data.obj.value.squeezeminutes;
      this.squeezeSlots = this._MovePlayerBusiness.getSqueezeSlots(this.modifiedSlots, this.bulkStatus, selectedMins);
      if (this.squeezeSlots && this.squeezeSlots.length > 0) {
        const FinalSlots = this._MovePlayerBusiness.addNewSlots(this.squeezeSlots, this.modifiedSlots);
        if (FinalSlots && FinalSlots.length > 0) {
          this._MovePlayerBusiness.updateTeeSlots(FinalSlots);
          this.onDragDrop(this.dragDropObj);
        }
      }
      else {
        //to do error case
      }
    }
  }

  private onPageLoad(): void {
    
    this.movePlayersForm.controls["dateControl"].setValue(this.date);
    this.movePlayersForm.controls["courseControl"].setValue(this.courseId);
    this.getData(this.courseId, this.date);
  }

  async SetNormalView() {
    this.notifyParent.emit(false);
    this._StepperService.setBackEnable(false);
    this.playerInfo = this.playerList = [];
    //this.customTableData.isBulkMoveEnabled = false;//Bug 91541
    this.customTableData = { ... this.customTableData };
    await this.getData(this.courseId, this.date);
    await this.getPlayers();
    this.resetPlayerList();
  }

  saveTeeTime(x: any, modifiedSlots: PlayerTeeTimeSlot[]) {
    this.saveMovedTeeTimes(modifiedSlots).then(y => {
      if (y) {
        let message = this.lblcaptions.movesuccessmessage;
        this._utilities.showAlert(message, AlertType.Success, ButtonType.Ok, (res) => {
          if (res === AlertAction.CONTINUE) {
            /**Bug 75660: Tee sheet can be navigate to default time While moving a tee time slots */
            if (modifiedSlots?.length > 0) {
              var LastModifiedSlot = modifiedSlots[modifiedSlots.length - 1]
              this._teeSheetUtilities.setLastVisitedTeeSlot({
                courseId: LastModifiedSlot.courseId,
                date: this._localization.ConvertDateToISODate(new Date(LastModifiedSlot.scheduledDateTime)),
                hole: LastModifiedSlot.holeNumber,
                time: this._localization.LocalizeTime(LastModifiedSlot.scheduledDateTime)
              })
            }
            x.dialogRef.close(x.type);
            if (this.teeTimeFormat === TeeTimeFormat.BulkTee) {
              this.callNotificationPopup(modifiedSlots.map(f => f.playerId));
            }
          }
        });
      }
      else {
        this.bFailure = true;
      }
    }).catch(error => {
      this.bFailure = true;
      if (!(error.name != undefined && error.name === "HttpErrorResponse")) {
        const error: string = this._localization.getError(this.unHandledErrorCode);
        this._utilities.showError(error);
      }
    }).finally(() => {
      this.quickLoginUtils.resetQuickIdDetails();
      if (this.bFailure) this.handleFailure();
    });
  }

  GetOverrideDetails(userId: number): OverrideDetails[] {
    let overrideDetails: OverrideDetails[] = [];
    var playerTypeOverrideDetail: OverrideDetails = {
      id: 0,
      scheduledTeeTimePlayerId: 0,
      overrideType: OverrideType.playerType,
      userId: userId
    }
    overrideDetails.push(playerTypeOverrideDetail);
    return overrideDetails;
  }

  pageSubscriptions() {
    this.movePlayersForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe();
    this._StepperService.valueChange.pipe(takeUntil(this.destroyed$)).subscribe(async (x: DialogCloseObj) => {
      if (x.dialogRef.componentInstance.componentName.name !== "MinutessqueezeComponent") {
        if (x.type == 'backClick') {
          this.SetNormalView();
        }

        else if (x.type == ButtonAction.save) {
          let quickIdConfig = this.propertyInformation.getQuickIdConfig;
          this.notifyParent.emit(false); // Disable save once clicked
          this.squeezeSlots = [];
          let modifiedSlots: PlayerTeeTimeSlot[] = this._MovePlayerBusiness.generateModifiedPlayersTeeSlots(this.initialTeeTimes, this.lastUpdatedSlots, this.playerRetailItems);

          let result = await this._teeTimesActionBusiness.checkOverridePlayerTypeRateTypeDaysOut(null, null,this.playerTypes, modifiedSlots, this.overridePlayerTypeDaysoutUserAccess,this.overrideRateTypeDaysoutUserAccess, this.date);
          this.rateSetup = await this._linkMultipackBusiness.getTeeFeesForCourseAndDate(this.movePlayersForm.controls.courseControl.value, this._localization.getDate(this.movePlayersForm.controls.dateControl.value));
          if (!result) {
            this.handleFailure();
            return;
          }

          if (quickIdConfig && quickIdConfig.teeTimeMove) {
            const quickLoginDialogRef = this.quickLoginUtils.QuickLogin();
            quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
              if (quickLoginDialogResult.isLoggedIn) {
                let isAllocationNotAllowed = false;
                const quickRoleId = this.quickLoginUtils.GetQuickLoginRoleId();
                const roleId = this._localization.GetUserInfo("roleId");
                if (quickRoleId != roleId) {

                  let playerSchedules = this.getScheduledPlayers();
                  let sourceSlot = this._teeTimesActionBusiness.getSourceTeeTimeInfo(playerSchedules, this.draggedPlayersList);

                  const allocationCodePermissionsforQuickId = await this._MovePlayerBusiness.GetAllocationBlockPermissionByRole(quickRoleId);
                  const isDragAllocationAllowed = isAllocationNotAllowed = this._teeTimesActionBusiness.CheckAlloctionBlockAndPlayerTypePermissionOnSave(sourceSlot,
                    allocationCodePermissionsforQuickId, this.allocationBlockPlayerTypes, this._localization.captions.common.MoveNotAllowed);
                  if (!isDragAllocationAllowed) {
                    let destinationSlot = this._teeTimesActionBusiness.getDestinationTimeInfo(this.initialTeeTimes, this.squeezeSlots, playerSchedules, modifiedSlots);
                    isAllocationNotAllowed = this._teeTimesActionBusiness.CheckAlloctionBlockAndPlayerTypePermissionOnSave(destinationSlot, allocationCodePermissionsforQuickId,
                      this.allocationBlockPlayerTypes, this._localization.captions.common.MoveNotAllowed);
                  }
                }
                if (!isAllocationNotAllowed) {
                  this.saveTeeTime(x, modifiedSlots);
                }
                else {
                  this._StepperService.enableSave = true;
                }
              } else {
                this._StepperService.enableSave = true;
              }
            });
          }
          else {
            this.saveTeeTime(x, modifiedSlots);
          }
        }
      }
    });
  }

  async callNotificationPopup(playerIds) {
    this.eventNotificationGroup = await this._NotificationConfigurationService.GetEventNotificationGroupByEventId(EventNotification.BulkUpdate);
    if (this.eventNotificationGroup && this.eventNotificationGroup.length > 0) {
      this.calltriggeremailsmspopupfunction(playerIds);
    }
  }
  handleFailure() {

    this.getData(this.courseId, this.date);
    this.playerList = this.playerInfo;
    this.resetPlayerList();
    this.customTableData = { ... this.customTableData };
    this.notifyParent.emit(false);
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
    this.quickLoginUtils.resetQuickIdDetails();
  }

  async getBulkPlayers(bookingId: string) {
    this.scheduledPlayers = await this._MovePlayerBusiness.getBulkPlayers(bookingId);
    var checkInCheckOutCount = this.scheduledPlayers.filter(u => ((u.playerStatus & PlayerPaymentstatus.CheckIn) != 0 || (u.playerStatus & PlayerPaymentstatus.CheckOut) != 0))
    if (checkInCheckOutCount?.length === this.scheduledPlayers?.length) {
      this._utilities.showError(`${this._localization.captions.common.MoveNotAllowed} ${this.lblcaptions.CheckInCheckOutRestrictedValidation}`);
      this.dialogRef.close('saved');
    }
    else {
      this.scheduledPlayers = this.scheduledPlayers.filter(x => !x.isBlocked && (x.playerStatus & PlayerPaymentstatus.CheckIn) == 0 && (x.playerStatus & PlayerPaymentstatus.CheckOut) == 0);
    }
  }

  private initialTeeTimes: TeeSheetSkeletonData[] = [];


  async getData(course, date) {
    let courseList = await this.courses;
    this.courseTooltip = courseList.find(x => x.id === course).name;
    let comment = await this._MovePlayerBusiness.getCourseComments(course, date);
    this.selectedCourseComment = comment ? comment.comment : '';
    this.notifierBar = this._MovePlayerBusiness.getCourseComment(this.selectedCourseComment);
    this.skeletonData = this._MovePlayerBusiness.getTeeSheetSkeleton(course, date, courseList,this.rateTypes);
    this.skeletonData.pipe(takeUntil(this.destroyed$)).subscribe(s => {
      this.modifiedSlots = _.cloneDeep(s);
      this.initialTeeTimes = this._MovePlayerBusiness.getInitialTeeTimes();
    });
  }

  resetPlayerList() {
    this.selectPlayers(true);
  }

  createMovePlayersForm() {
    this.movePlayersForm = this.fb.group({
      dateControl: '',
      courseControl: ''
    });
  }

  showAlert(date, course) {
    let message = this.lblcaptions.PleaseSavethechanges;
    this._utilities.showAlert(message, AlertType.Warning, ButtonType.Ok, (res) => {

      this.date = this.existingDate;
      this.courseId = this.existingCourse;
      this.movePlayersForm.controls["dateControl"].setValue(this.date);
      this.movePlayersForm.controls["courseControl"].setValue(this.courseId);

    });
  }

  dateChanged(e) {
    let bModified: boolean = this.isTeeGridModified();
    const selectedDate = e[0].value.dateControl;
    if (this._teeTimesActionBusiness.validateUserDaysOut(selectedDate)) {
      if (selectedDate != this._localization.getDate(this.date) && bModified) {
        this.showAlert(selectedDate, this.courseId);
      }
      else {
        this.date = this.existingDate = selectedDate;
        this.getData(this.courseId, this.date);
      }
    }
    else {
      this._utilities.showError(this._localization.getError(30414));
      this.date = this.existingDate;
      this.courseId = this.existingCourse;
      this.movePlayersForm.controls["dateControl"].setValue(this.date);
      this.movePlayersForm.controls["courseControl"].setValue(this.courseId);
    }
  }

  courseChanged(e) {
    let bModified: boolean = this.isTeeGridModified();
    if (e != this.courseId && bModified) {
      this.showAlert(this.date, e);
    }
    else {
      this.courseId = this.existingCourse = e;
      this.getData(this.courseId, this.date);
    }
  }

  isTeeGridModified(): boolean {
    if (this.initialTeeTimes.length > 0) {
      let modifiedSlots: PlayerTeeTimeSlot[] = this._MovePlayerBusiness.generateModifiedPlayersTeeSlots(this.initialTeeTimes, this.modifiedSlots);
      if (modifiedSlots.length > 0) {
        return true;
      }
      else {
        return false;
      }
    }
    return false;

  }

  onRemovePlayer(playerDetails) {
    playerDetails.rowData.playerDetail = playerDetails.rowData.playerDetail.filter(x => x.playerId !== playerDetails.playerData.playerId);
    this.draggedPlayersList = this.draggedPlayersList.filter(x => x.playerId !== playerDetails.playerData.playerId);
    this.playersNewRate = this.playersNewRate && this.playersNewRate.length > 0 ? this.playersNewRate.filter(x => playerDetails.playerData.playerId != x.playerId)
      : []; //to do
    playerDetails.playerData.playerClicked = false;
    this.reOrderPlayers(playerDetails.rowData.playerDetail);
    this.removeCaddyInfo(playerDetails.playerData.playerId, playerDetails.playerData.scheduledDateTime, playerDetails.playerData.playerSlotPosition);
    this.playerList.push(playerDetails.playerData);
    this.teeSheetModifications = {
      initialState: playerDetails.initialState, currentState: playerDetails.currentState
    };
    this._MovePlayerBusiness.updateTeeSlots(playerDetails.currentState);
    this.lastUpdatedSlots = _.cloneDeep(playerDetails.currentState);
    let isModified = this.isTeeGridModified();
    this.notifyParent.emit(this.movePlayersForm.valid && isModified);
  }

  // Reorder Player Numbers Here
  reOrderPlayers(playersArray) {
    playersArray.forEach((element, index) => {
      element.playPos = index + 1;
    });
  }

  onmoreEvent(e) {
    console.log("on More Click", e);
  }

  IsDropAllowed(dragDropObj: any) {
    // check dragdropobj either bulk or normal
    // set teetimeformat or a new flag

    dragDropObj.dragData

  }

  async showDestinationSlotsRateTypes(courseId: number, date: string | Date, validationResult: CopyMoveResult[]): Promise<PlayersNewRate[]> {
    return await this._teeTimesActionBusiness.handleDestinationSlotWithDifferentRateTypes(courseId, date, validationResult);
  }


  async onDragDrop(dragDropObj: any) {
    try {
      console.log(this.playerRetailItems);
      this.playerRetailItems = dragDropObj.playerRetailItems;
      if(this.cartUtils.isEmbed()){
        this.lastUpdatedSlots = dragDropObj.currentState;
        let modifiedSlots: PlayerTeeTimeSlot[] = this._MovePlayerBusiness.generateModifiedPlayersTeeSlots(this.initialTeeTimes, this.lastUpdatedSlots);
        let dataForVCartSlot = [{}];
        dataForVCartSlot.push(
          {
            course:{
              id: modifiedSlots[0]?.courseId
            },
            time: modifiedSlots[0]?.scheduledDateTime,
            originalHoleNumber: modifiedSlots[0]?.originalHoleNumber
          }
        );
        let slotPresentInSlot = this._teeSheetSharedBusiness.IsSlotPresentInCart(dataForVCartSlot);
        if(slotPresentInSlot){
          this.resetTeeData(dragDropObj);
          return;
        }
      }
      this.dragDropObj = dragDropObj;
      this.dragDropObj.dragData = [_.cloneDeep(this.dragDropObj.dragData)];
      let draggedPlayers: PlayerTeeTimeSlot[];
      this.teeSheetModifications = {
        initialState: dragDropObj.initialState, currentState: dragDropObj.currentState
      };
      this.playersNewRate = this.playersNewRate && this.playersNewRate.length > 0 ? this.playersNewRate.filter(x => this.draggedPlayersList.some(y => y.playerId != x.playerId))
        : []; //to do
      //bulk drag and drop
      if (dragDropObj.dragOrigin === DragOrigin.playerlist && this.teeTimeFormat === TeeTimeFormat.BulkTee) {
        let isdropAllowed: boolean = this._MovePlayerBusiness.isDropAllowed(dragDropObj.dragData, dragDropObj.dropPositionObject.playerDetail);
        const isDropValid: boolean = this._teeTimesActionBusiness.validateDrop(dragDropObj.dropPositionObject, TeeTimeAction.move);
        if (!isdropAllowed || !isDropValid) {
          this.isBulkSectionDisabled = false;
          return;
        }
        const playerDetails = [];
        this.dragDropObj.dragData.forEach(x => {
          const playerDetail = this.scheduledPlayers.find(players => players.playerId == x.playerId);
          playerDetails.push(playerDetail);
        });
        const dragAllocationBlock = this.dialogData.info.allocation;
        if (dragAllocationBlock) {
          const dragValidation = this.checkAlloctionBlockAndPlayerTypePermission(dragAllocationBlock, playerDetails);
          if (dragValidation) {
            this.resetTeeData(dragDropObj);
            return;
          }
        }
        const dropAllocationBlock = this.dragDropObj.dropPositionObject.allocation;
        if (dropAllocationBlock) {
          const dropValidation = this.checkAlloctionBlockAndPlayerTypePermission(dropAllocationBlock, playerDetails);
          if (dropValidation) {
            this.resetTeeData(dragDropObj);
            return;
          }
        }
        this.isBulkSectionDisabled = true;
        await this.handleBulkMoveDrop(dragDropObj);
        this.isBulkSectionDisabled = false;
        return;
      }
      else { // Normal drag and internal grid drag goes here

        //normal drag and drop
        if (dragDropObj.dragOrigin === DragOrigin.playerlist) {
          dragDropObj.dragData = this.draggedPlayersList;
          dragDropObj.dragPositionObject = this.dialogData.info;
        }


        const isDropValid: boolean = this._teeTimesActionBusiness.validateDrop(dragDropObj.dropPositionObject, TeeTimeAction.move);
        if (!isDropValid) {
          this.resetTeeData(dragDropObj);
          return;
        }

        if (dragDropObj.dragData[0].allocationBlockId) {
          const dragValidation = this.checkAlloctionBlockAndPlayerTypePermission({ id: dragDropObj.dragData[0].allocationBlockId, name: '' }, dragDropObj.dragData);
          if (dragValidation) {
            this.resetTeeData(dragDropObj);
            return;
          }
        }
        if (dragDropObj.dropPositionObject.allocation) {
          const dropValidation = this.checkAlloctionBlockAndPlayerTypePermission(dragDropObj.dropPositionObject.allocation, dragDropObj.dropPositionObject.playerDetail);
          if (dropValidation) {
            this.resetTeeData(dragDropObj);
            return;
          }
        }
        this._MovePlayerBusiness.updateTeeSlots(dragDropObj.currentState);
        this.lastUpdatedSlots = dragDropObj.currentState;
        draggedPlayers = this._teeTimesActionBusiness.mapPlayerData(dragDropObj.dropPositionObject, dragDropObj.dragData, this.playerRetailItems);

        const teeTimeAction: TeeTimeAction = this.teeTimeFormat == TeeTimeFormat.BulkTee ? TeeTimeAction.bulkMove : TeeTimeAction.move;
        this._utilities.ToggleLoader(true);
        var validationResult = await this._teeTimesActionBusiness.validateTeeTimeAction(this.dialogData.info, teeTimeAction, draggedPlayers);
        this._utilities.ToggleLoader(false);
        if (validationResult && validationResult.result && validationResult.result.length > 0) {
          let playerNewRate: PlayersNewRate[] = [];
          let draggedPlayersCaddyInfo: PlayerTeeTimeSlot[] = [];
          draggedPlayers = await this.validateCourseFeeChange(validationResult, draggedPlayers,dragDropObj.dragData);
          draggedPlayersCaddyInfo = await this._teeSheetUtilities.showCaddyNotAvailableAlert(validationResult, draggedPlayers);
          this.playersCaddyInfo = this._teeTimesActionBusiness.updatePlayerCaddyInfo(draggedPlayersCaddyInfo, this.playersCaddyInfo);
          if (validationResult.result.some(x => x.isDestinationSlotHasDifferentRateType)) {
            validationResult.result = this._teeTimesActionBusiness.mapPlayerDetailsInPlayerTeeTimeSlot(draggedPlayers, validationResult.result);
            playerNewRate = await this.showDestinationSlotsRateTypes(this.courseId, draggedPlayers[0].scheduledDateTime, validationResult.result);
            this.playersNewRate = this._teeTimesActionBusiness.updatePlayerNewRate(playerNewRate, this.playersNewRate);
          }

          validationResult.result.forEach((element) => {
            let destinationSlotRateChanged = element.isDestinationSlotHasDifferentRateType && playerNewRate && playerNewRate.length > 0;
            if (element.result && draggedPlayersCaddyInfo.length != 0 && (!element.isDestinationSlotRateChanged || (element.isDestinationSlotRateChanged && (draggedPlayers.length != 0)))
              && (!element.isDestinationSlotHasDifferentRateType || (destinationSlotRateChanged))) {
              this.onDropSuccess(dragDropObj, element.playerTeeTimeSlot);
            } else {
              this.resetTeeData(dragDropObj, element.playerTeeTimeSlot);
            }
          });

        }
      }
    } catch (error) {

      this.isBulkSectionDisabled = false;
      if (!(error.name != undefined && error.name === "HttpErrorResponse")) {
        const error: string = this._localization.getError(this.unHandledErrorCode);
        this._utilities.showError(error);
      }
      this.resetTeeData(dragDropObj);
    }
    finally {
      this._utilities.ToggleLoader(false);
    }
  }



  private useDestinationCourseTeeFees: boolean;

  async validateCourseFeeChange(validationResult: ValidationResult<CopyMoveResult[]>, playersToBeMoved: PlayerTeeTimeSlot[],players = []): Promise<PlayerTeeTimeSlot[]> {
    let validatedPlayers = playersToBeMoved;
    let teeFeeGridContent = players;
    const isAnySourceRateDiffer = validationResult.result.some(x => x.isDestinationSlotRateChanged);
    if (isAnySourceRateDiffer) {
      const message = this.lblcaptions.DestinationRateChanged;
      teeFeeGridContent = this._teeSheetUtilities.mapPlayersTobeDroppedToTeeFeeGridData(validationResult,teeFeeGridContent);
      const playersHavingConflictingTeeFee = validationResult.result.filter(x => x.isDestinationSlotRateChanged).map(o => o.playerTeeTimeSlot.playerId);
      const disableUseDestinationTeeFee = playersToBeMoved.filter(p => p.isPaid || (p.multiPackTransactionDetailId && p.multiPackTransactionDetailId != 0)).some(o => playersHavingConflictingTeeFee.includes(o.playerId));
      await this._teeSheetUtilities.showTeeFeeAlert
      (message, 
        AlertType.Warning, 
        ButtonType.CourseFeeSelection,teeFeeGridContent, (res: any) => {
        if (res === MoveRateTypeChangeSelection.CancelMove) {
          validatedPlayers = []; // Abort Move
        } else if (res === MoveRateTypeChangeSelection.UseDestinationTeeFee) {
          // Override source with destination fees    
          validatedPlayers.forEach(o => o.useDestinationTeeFees = true);
          this.useDestinationCourseTeeFees = true;
        }
      }, disableUseDestinationTeeFee).afterClosed().toPromise();
    }
    const isAnySourceItemChanged = validationResult.result.some(x => x.isTeeItemChanged);
    if (isAnySourceItemChanged && playersToBeMoved.filter(c => c.multiPackTransactionDetailId).length > 0) {
      await this._utilities.showCommonAlert(
        this.lblcaptions.moveTeeTimeWithoutMultipack,
        AlertType.Info,
        ButtonTypes.Ok, (res: any) => {
          if (res === AlertAction.CONTINUE) {

            validatedPlayers.forEach(o => {
              o.multiPackTransactionDetailId = 0;
            });
          }
        }).afterClosed().toPromise();
    }
    return validatedPlayers;
  }
  async handleBulkMoveDrop(dragDropObj: any) {
    let draggedPlayers: PlayerTeeTimeSlot[];
    dragDropObj.dragData = this.draggedPlayersList;
    let allocationblock: AllocationCode = this._MovePlayerBusiness.mapAllocationBlockData(dragDropObj.dropPositionObject.allocation);
    let teeSlots = this._MovePlayerBusiness.buildTeeSlots(_.cloneDeep(this.modifiedSlots), dragDropObj.dragData, dragDropObj.dropPositionObject.time, this.playersPerGroup, allocationblock, dragDropObj.dropPositionObject.originalHoleNumber);
    if (teeSlots && teeSlots.TeeSlots) {
      this.bulkStatus = this._MovePlayerBusiness.getBulkStatus(teeSlots, _.cloneDeep(this.modifiedSlots));

      if (this.bulkStatus.status === status.failure) {
        this._MovePlayerBusiness.ShowError(this._localization.captions.teetime.SlotsNotAvailableForBulkBook,
          AlertType.Info, ButtonType.Ok);
      }

      else if (this.bulkStatus.status === status.squeeze) {
        let isAllow: boolean = await this._MovePlayerBusiness.validateBreakPointAccess(UserAccessBreakPoints.SQUEEZETEETIME, false);
        if (isAllow) {
          this._TeeGridService.squeezeTeeRows(this.bulkStatus.maxSqueezeMins, "Move Component", this.onSqueezeClose.bind(this));
        }
        else {
          this._MovePlayerBusiness.ShowError(this._localization.captions.teetime.SlotsNotAvailableForBulkBook,
            AlertType.Info, ButtonType.Ok);
        }
      }

      else {
        let modifiedTeeTimes = this._MovePlayerBusiness.buildTeeSheetSkeletonData(_.cloneDeep(this.modifiedSlots), teeSlots.TeeSlots, dragDropObj.dragData, this.playersPerGroup, allocationblock, true, this.squeezeSlots);
        this.lastUpdatedSlots = _.cloneDeep(modifiedTeeTimes);
        draggedPlayers = this._MovePlayerBusiness.generateModifiedPlayersTeeSlots(this.modifiedSlots, this.lastUpdatedSlots);

        draggedPlayers.forEach(x => x.overrideDetails = this.GetOverrideDetails(0));
        const teeTimeAction: TeeTimeAction = this.teeTimeFormat == TeeTimeFormat.BulkTee ? TeeTimeAction.bulkMove : TeeTimeAction.move;
        try {
          this._utilities.ToggleLoader(true);
          var validationResult = await this._teeTimesActionBusiness.validateTeeTimeAction(this.dialogData.info, teeTimeAction, draggedPlayers);
          this._utilities.ToggleLoader(false);
          if (validationResult && validationResult.result && validationResult.result.length > 0) {
            draggedPlayers = await this.validateCourseFeeChange(validationResult, draggedPlayers,dragDropObj.dragData);
            draggedPlayers = await this._teeSheetUtilities.showCaddyNotAvailableAlert(validationResult, draggedPlayers);
            this.playersCaddyInfo = this._teeTimesActionBusiness.updatePlayerCaddyInfo(draggedPlayers, this.playersCaddyInfo);
            const failedRules = validationResult.result.filter(o => !o.result).length > 0;
            this.resetPlayerList();
            if (failedRules || draggedPlayers.length == 0) {
              return;
            }
            this._MovePlayerBusiness.updateTeeSheetGridContent(this.lastUpdatedSlots);
            this.onDropSuccessForBulk(dragDropObj, draggedPlayers);
          }
        }
        finally {
          this._utilities.ToggleLoader(false);
        }
      }

    }
  }

  onDropSuccess(dragDropObj: TeeSheetDragDropEvt, validPlayer: PlayerTeeTimeSlot) {
    this.notifyParent.emit(this.movePlayersForm.valid);
    this._StepperService.setBackEnable(true);
    this.playerList = this.playerList.filter(p => p.playerId !== validPlayer.playerId);
    this._TeeGridService.selectedPlayers = [];
    if (dragDropObj.dragOrigin === DragOrigin.playerlist && this.playerList.length === 0) {
      this.notificationFlag = true;
    }
    this.resetPlayerList();
  }

  onDropSuccessForBulk(dragDropObj: TeeSheetDragDropEvt, validPlayer: PlayerTeeTimeSlot[]) {
    this.notifyParent.emit(this.movePlayersForm.valid);
    this._StepperService.setBackEnable(true);
    validPlayer.forEach(element => {
      this.playerList = this.playerList.filter(p => p.playerId !== element.playerId);
    });
    this._TeeGridService.selectedPlayers = [];
    if (dragDropObj.dragOrigin === DragOrigin.playerlist) {
      this.notificationFlag = true;
    }
    this.resetPlayerList();
  }


  resetTeeData(dragDropObj, resetData?: PlayerTeeTimeSlot) {
    if (dragDropObj.dragOrigin === DragOrigin.playerlist) {
      this.draggedPlayersList.forEach(element => {
        if (!(resetData) || element.playerId === resetData.playerId) {
          dragDropObj.currentState[dragDropObj.dropPositionRowIndex].playerDetail = dragDropObj.currentState[dragDropObj.dropPositionRowIndex].playerDetail.filter(x => x.playerId !== element.playerId);
        }
      });
      this.reOrderPlayers(dragDropObj.currentState[dragDropObj.dropPositionRowIndex].playerDetail);
    }
    if (dragDropObj.dragOrigin === DragOrigin.grid) {
      dragDropObj.dragData.forEach(element => {
        dragDropObj.currentState[dragDropObj.dragPositionRowIndex].playerDetail.push(element);
        dragDropObj.currentState[dragDropObj.dropPositionRowIndex].playerDetail = dragDropObj.currentState[dragDropObj.dropPositionRowIndex].playerDetail.filter(x => x.playerId !== element.playerId);
      });
      this.reOrderPlayers(dragDropObj.currentState[dragDropObj.dragPositionRowIndex].playerDetail);
    }
    this.resetNewRates(resetData, this.playersNewRate);
    this.resetPlayerList();
    this.resetNewRates(resetData, this.playersNewRate);
    this.resetCaddyInfo(resetData, this.playersCaddyInfo);
    this._MovePlayerBusiness.updateTeeSlots(dragDropObj.currentState);
  }

  resetNewRates(resetData: PlayerTeeTimeSlot, playersNewRate: PlayersNewRate[]) {
    if (resetData && playersNewRate && playersNewRate.length > 0) {
      playersNewRate = playersNewRate && playersNewRate.length > 0 ? playersNewRate.filter(x => x.playerId != resetData.playerId) : [];
    }
  }
  resetCaddyInfo(resetData: PlayerTeeTimeSlot, playersCaddyInfo: PlayersCaddyInfo[]) {
    if (resetData && playersCaddyInfo && playersCaddyInfo.length > 0) {
      playersCaddyInfo = playersCaddyInfo && playersCaddyInfo.length > 0 ? playersCaddyInfo.filter(x => x.playerId != resetData.playerId || (this._utilities.getDate(resetData.scheduledDateTime).getTime() != this._utilities.getDate(x.scheduledDateTime).getTime()) || resetData.playerSlotPosition != x.playerSlotPosition) : [];
    }
  }
  removeCaddyInfo(removeData: number, scheduledDateTime: string, playerSlotPosition : number) {
    if (removeData && this.playersCaddyInfo && this.playersCaddyInfo.length > 0) {
      let index = this.playersCaddyInfo.findIndex(x => x.playerId == removeData && (this._utilities.getDate(scheduledDateTime).getTime() == this._utilities.getDate(x.scheduledDateTime).getTime()) && playerSlotPosition == x.playerSlotPosition);
      this.playersCaddyInfo.splice(index, 1);
    }
  }
  onDragPlayers(playersDragged) {
    this.draggedPlayersList = playersDragged;
  }

  async CanChangeBulkView(arg) {
    let bResult: boolean;
    if (arg && arg.isBulk) {
      var response = this._utilities.showAlert(this.lblcaptions.changeBulkView, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res === AlertAction.YES) {
          bResult = true;
        }
      });
      await response.afterClosed().toPromise();
      if (bResult) {
        await this.SetDefaultValuesForBulkView(arg.player);
      }
    }
  }

  async SetDefaultValuesForBulkView(player) {
    this.notifyParent.emit(false);
    this._StepperService.setBackEnable(false);
    this.playerInfo = this.playerList = [];
    this.teeTimeFormat = TeeTimeFormat.BulkTee;
    this.playersPerGroup = player.playersPerGroup ? player.playersPerGroup : this.playersPerGroup;
    this.customTableData.isBulkMoveEnabled = true;
    this.customTableData = { ... this.customTableData };
    await this.getData(this.courseId, this.date);
    await this.GetBulkPlayers(player.bookingId);
    this._imageService.getProfileImages(this.playerList, "imageReferenceId");
    this.cdr.detectChanges();
  }

  onDragGridPlayers(e) {
    this._TeeGridService.selectedPlayers.forEach(element => {
      element.playerClicked = e;
    });
  }

  private teeSheetModifications: TeeSheetModications;

  compareDate(date1: Date, date2: Date) {
    return this.ValidateStartDate(date1, date2);
  }

  ValidateStartDate(date1: Date, date2: Date) {
    return this.GetDateWithoutTime(date1).valueOf() <= this.GetDateWithoutTime(date2).valueOf();
  }

  checkExpireDate(date1: Date, date2: Date) {
    return this.ValidateExpireDate(date1, date2);
  }

  ValidateExpireDate(date1: Date, date2: Date) {
    return this.GetDateWithoutTime(date1).valueOf() >= this.GetDateWithoutTime(date2).valueOf();
  }

  GetDateWithoutTime(input: Date) {
    if (typeof (input) == 'string') {
      input = this.getDate(input);
    }
    input = _.cloneDeep(input);
    input.setHours(0, 0, 0, 0);
    return input;
  }

  getDate(input: any): Date {
    return this._localization.getDate(input);
  }

  private saveMovedTeeTimes(modifiedSlots: PlayerTeeTimeSlot[]): Promise<boolean> {
    if (!this.teeSheetModifications) { return; }
    const teeTimeAction: TeeTimeAction = this.teeTimeFormat == TeeTimeFormat.BulkTee ? TeeTimeAction.bulkMove : TeeTimeAction.move;
    //let modifiedSlots: PlayerTeeTimeSlot[] = this._MovePlayerBusiness.generateModifiedPlayersTeeSlots(this.initialTeeTimes, this.lastUpdatedSlots);
    modifiedSlots.forEach(o => {
      o.useDestinationTeeFees = this.useDestinationCourseTeeFees;
      if (o.multiPackTransactionDetailId != 0) {
        let multipack = this.multiPackResponse.find(c => c.transactionDetailId == o.multiPackTransactionDetailId)?.clientMultiPacks;
        const rateTypeId = this.dialogData.info.playerDetail.find(c => c.playerId == o.playerId)?.rateType?.id
        if (multipack && multipack.length > 0) {
          const greenFeeId = this.rateSetup.find(c => c.rateTypeId == rateTypeId).greenFeeRetailItemId;
          const cartFeeId = this.rateSetup.find(c => c.rateTypeId == rateTypeId
          ).cartFeeRetailItemId;
          const greenFeeAvailable = multipack.filter(c => c.linkedRetailItemId == greenFeeId).length > 0 ? true : false;
          const cartFeeAvailable = cartFeeId == 0 ? true : (multipack.filter(c => c.linkedRetailItemId == cartFeeId).length > 0 ? true : false);
          if (!greenFeeAvailable || !cartFeeAvailable) {
            o.multiPackTransactionDetailId = 0;
            o.multiPackGreenFeeValue = 0;
            o.multiPackCartFeeValue = 0;
          }
          else {
            let greenFeeItem = multipack.filter(c => c.linkedRetailItemId == greenFeeId);
            let validMultipack = ((greenFeeItem[0].isReturned == false && greenFeeItem[0].remaining != '0' && this.compareDate(greenFeeItem[0].startDate, this.dialogData.info.time) && this.checkExpireDate(greenFeeItem[0].expirationDate, this.dialogData.info.time))) ? true : false;
            if (!validMultipack) {
              o.multiPackTransactionDetailId = 0;
              o.multiPackGreenFeeValue = 0;
              o.multiPackCartFeeValue = 0;
            }
          }
          if (o.multiPackTransactionDetailId != 0) {
            o.multiPackGreenFeeValue = multipack.find(c => c.linkedRetailItemId == greenFeeId).salePrice;
            o.multiPackCartFeeValue = cartFeeId != 0 ? multipack.find(c => c.linkedRetailItemId == cartFeeId).salePrice : 0;
          }
        }
      }
      if (this.playersCaddyInfo && this.playersCaddyInfo.length > 0) {
        let newCaddy = this.playersCaddyInfo.find(x => x.playerId == o.playerId && (this._utilities.getDate(o.scheduledDateTime).getTime() == this._utilities.getDate(x.scheduledDateTime).getTime()) && o.playerSlotPosition == x.playerSlotPosition);
        o.caddyId = newCaddy ? newCaddy.caddyId : o.caddyId;
        o.caddyTypeId = newCaddy ? newCaddy.caddyTypeId : o.caddyTypeId;
        o.isUpdateCaddy = newCaddy ? newCaddy.isUpdateCaddy : false;
      }
      if (this.teeTimeFormat != TeeTimeFormat.BulkTee && this.playersNewRate && this.playersNewRate.length > 0) {
        let newRate = this.playersNewRate.find(x => x.playerId == o.playerId);
        o.rateTypeId = newRate ? newRate.rateTypeId : 0;
        o.useNewRateType = o.rateTypeId > 0;
      }
    });
    return this._teeTimesActionBusiness.saveTeeTimeAction(this.dialogData.info, modifiedSlots, teeTimeAction);
  }
  onPlayerListEmit(playerCollectionList) {
    this.playerCollectionList = playerCollectionList;
    this.cdr.detectChanges();
  }

  selectAll(eve) {
    this.selectPlayers(eve);
  }

  removeNotification(event) {
    this.notificationFlag = event;
  }

  async calltriggeremailsmspopupfunction(playerids) {

    let userinfo = playerids != null && playerids?.length > 0 ? await this._retailBusinessService.GetPlayerContactInfoByPlayerIdList(playerids) : null;
    let objPhone: any = userinfo != null && userinfo?.length > 0 ? this._utilities.GetPhoneNumber(userinfo) : null;
    let email = userinfo != null && userinfo?.length > 0 ? this._utilities.GetEmail(userinfo) : null;

    this.popUpComponentDetails = {
      componentName: SendNotificationMailSmsComponent,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent',
        bindData: ''
      }
    };
    this._dialog.open(SendNotificationMailSmsComponent, {
      width: "40%",
      height: "49%",
      panelClass: "",
      data: {
        title: this._localization.captions.common.notificationTitle,
        save: this._localization.captions.common.PopUpSend,
        cancel: this._localization.captions.common.PopUpSkip,
        componentDetails: this.popUpComponentDetails,
        info: this.bookingId,
        mailId: email && email != null && email?.value ? email.value : null,
        countryCode: objPhone && objPhone != null && objPhone != "" && objPhone?.value && objPhone?.value != null && objPhone?.value != "" ? this._utilities.getCountryCodeFromValue(Number(objPhone?.type), objPhone?.value) : null,
        phone: objPhone && objPhone != null && objPhone != "" && objPhone?.value && objPhone?.value != null && objPhone?.value != "" ? this._utilities.getPhoneNumberFromValue(Number(objPhone?.type), objPhone?.value) : null,
        action: "BulkUpdate",
        showStaticBool: false,
        fromComponent: 'fromComponent',
        actionType: ButtonAction.save
      },
      disableClose: true
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe();
  }

  private updateUserAccessInfo() {
    if (this.mixedPlayerSlot && this.bulkPlayerUserAccess && this.normalPlayerUserAccess && (!this.bulkPlayerUserAccess.isAllow || !this.normalPlayerUserAccess.isAllow)) {
      const parentEl = this.infoEle.nativeElement;
      this.infoStripAvailable = true;
      let infoWrapper;
      if (!this.bulkPlayerUserAccess.isAllow) {
        const message = this._localization.captions.breakpoint[UserAccessBreakPoints.BULKMOVE];
        infoWrapper = this._utilities.GetInfoStrip(message);
      } else if (!this.normalPlayerUserAccess.isAllow) {
        const message = this._localization.captions.breakpoint[UserAccessBreakPoints.Move];
        infoWrapper = this._utilities.GetInfoStrip(message);
      }
      this.rendrer.insertBefore(parentEl, infoWrapper, parentEl.childNodes[0]);
    }
  }

  private checkAlloctionBlockAndPlayerTypePermission(allocationBlock: { id: number, name: string }, playerDetails: TeeSheetPlayerDetails[]): boolean {
    let res = false;
    if (this.isAllocationBlockEnabled) {
      if (allocationBlock && allocationBlock.id) {
        const allocationBlocks = this.allocationCodePermissions.filter(x => x.allocationBlockId == allocationBlock.id);
        const allocationBlockPlayerTypes = this.allocationBlockPlayerTypes.find(x => x.id == allocationBlock.id);
        if (allocationBlocks.length == 0) {
          this._utilities.showAllocationCodePermissionDeniedPopup(allocationBlock.name, this._localization.captions.common.MoveNotAllowed);
          res = true;
        } else {
          let nonaccessiblePlayer = [];
          playerDetails.forEach(playerInfo => {
            if (playerInfo.playerTypeId) {
              const allocationPlayer = allocationBlocks.filter(x => x.playerTypeId == playerInfo.playerTypeId);
              if (allocationPlayer.length == 0) {
                const playerType = allocationBlockPlayerTypes.playerTypes.find(x => x.id === playerInfo.playerTypeId);
                if (playerType) {
                  nonaccessiblePlayer.push(playerType.type);
                }
              }
            }
          });
          if (nonaccessiblePlayer.length > 0) {
            const uniqueNonAccessPlayer = _.uniq(nonaccessiblePlayer);
            let name = '';
            if (uniqueNonAccessPlayer.length > 0) {
              name = uniqueNonAccessPlayer.join(', ')
            }
            this._utilities.showAllocationCodePermissionDeniedPopup(name, this._localization.captions.common.MoveNotAllowed);
            res = true;
          }
        }
      }
    }
    return res;
  }

  getScheduledPlayers(): TeeSheetPlayerDetails[] {
    let allScheduleInfo: TeeSheetPlayerDetails[] = [];
    if (this.scheduledPlayers && this.scheduledPlayers.length > 0) {
      let bulkplayers = this._MovePlayerBusiness.mapTeeSheetplayerDetails(this.scheduledPlayers, this.rateTypes);
      allScheduleInfo.push(...this._MovePlayerBusiness.getPlayerList(bulkplayers));
    }
    if (this.playerInfo && this.playerInfo.length > 0) {
      allScheduleInfo.push(...this.playerInfo);
    }
    return allScheduleInfo;
  }

  private selectPlayers(value: boolean) {
    this._TeeGridService.selectedPlayers = [];
    if (value) {
      if (this.playerList.length > 0) {
        const isAllBulk =
          (this.playerList.filter(x => x.teeTimeFormat == TeeTimeFormat.BulkTee).length == this.playerList.length
            && this.teeTimeFormat == TeeTimeFormat.BulkTee);
        if (isAllBulk) {
          this.playerList.forEach(element => {
            element['playerClicked'] = true;
            this._TeeGridService.selectedPlayers.push(element);
          });
        } else {
          this.playerList.forEach(element => {
            if (element.teeTimeFormat == TeeTimeFormat.None || element.teeTimeFormat == TeeTimeFormat.GroupTee || element.teeTimeFormat == TeeTimeFormat.Tournament) {
              element['playerClicked'] = true;
              this._TeeGridService.selectedPlayers.push(element);
            } else {
              element['playerClicked'] = false;
            }
          });
        }
      }
    } else {
      this.playerList.forEach(element => {
        element['playerClicked'] = false;
      });
    }
  }

  openPlayerRateModal(e) {
    let playerRates = [
      {
        courseId: 1,
        date: '',
        playerId: 1,
        firstName: 'Test Player',
        lastName: '1',
        playerTypeId: 1,
        destinationSlotRates: [{
          rateTypeId: 1,
          rateTypeName: 'P1 Test1',
          greenFee: 20,
          cartFee: 30
        },
        {
          rateTypeId: 2,
          rateTypeName: 'P1 Test2',
          greenFee: 50,
          cartFee: 60
        },
        {
          rateTypeId: 3,
          rateTypeName: 'P1 Test3',
          greenFee: 50,
          cartFee: 60
        }, {
          rateTypeId: 4,
          rateTypeName: 'P1 Test4',
          greenFee: 50,
          cartFee: 60
        }, {
          rateTypeId: 5,
          rateTypeName: 'P1 Test5',
          greenFee: 50,
          cartFee: 60
        }, {
          rateTypeId: 6,
          rateTypeName: 'P1 Test6',
          greenFee: 50,
          cartFee: 60
        }, {
          rateTypeId: 7,
          rateTypeName: 'P1 Test7',
          greenFee: 50,
          cartFee: 60
        }, {
          rateTypeId: 8,
          rateTypeName: 'P1 Test8',
          greenFee: 50,
          cartFee: 60
        }]
      },
      {
        courseId: 1,
        date: '',
        playerId: 2,
        firstName: 'Test Player',
        lastName: '2',
        playerTypeId: 2,
        destinationSlotRates: [{
          rateTypeId: 1,
          rateTypeName: 'P2 Test1',
          greenFee: 20,
          cartFee: 30
        },
        {
          rateTypeId: 2,
          rateTypeName: 'P2 Test2',
          greenFee: 50,
          cartFee: 60
        }]
      },
      {
        courseId: 1,
        date: '',
        playerId: 3,
        firstName: 'Test Player',
        lastName: '3',
        playerTypeId: 3,
        destinationSlotRates: [{
          rateTypeId: 1,
          rateTypeName: 'P3 Test1',
          greenFee: 20,
          cartFee: 30
        },
        {
          rateTypeId: 2,
          rateTypeName: 'P3 Test2',
          greenFee: 50,
          cartFee: 60
        }]
      }
    ];
    this._dialog.open(PlayerRateModalComponent, {
      width: "80%",
      height: "80%",
      data: {
        playerRateDetails: playerRates
      },
      disableClose: true
    }).afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(result => {
      if (result) {
        console.log(result);
      }
    });
  } 

}






