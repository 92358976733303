import { NativeDateAdapter } from '@angular/material/core';
import { GolfLocalization } from './golf-localization';
import { GolfPropertyInformation } from '../services/golf-property-information.service';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import moment from 'moment';
import { Platform } from '@angular/cdk/platform';

const dateFormat = 'l';
const calenderDateFormat = 'MMM YYYY';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
};
@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  constructor(
    public localization: GolfLocalization, private PropertyInfo: GolfPropertyInformation, @Inject(PLATFORM_ID) platformId: Object) {
    super(localization.localeCode, new Platform(platformId));
  }
  format(date: Date, displayFormat: any): string {    
    if (displayFormat === 'input') {
      return moment(date).format(this.localization.inputDateFormat);
    } else {
      return moment(date).format(this.localization.inputDateFormat);
    }
  }
  getFirstDayOfWeek(): number {
    return this.localization.getFirstDayOfWeek();
  }  
  parse(value: any): Date | null {
    if(value){
        if (!value.match(/[0-9-/.]/)) {
          return null;
        } else {
          const date = moment(value, this.localization.inputDateFormat);
          return date? date.toDate(): null;
        }
       
    }
    return null;
}
}
