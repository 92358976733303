import { Injectable, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBarRef } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";
import { AgShortcutKeyGuideComponent } from "../components/ag-shortcut-key-guide/ag-shortcut-key-guide.component";
import { AgSnackBarComponent } from "../components/ag-snack-bar/ag-snack-bar.component";
import { routingMenuService } from "../components/menu/menu.service";
import { Localization } from "../localization/localization";
import { GuideItem, KeyBoardGrouping, MenuItem } from "../Models/ag-models";
import { SortOrderPipe } from "../pipes/sort-order.pipe";


@Injectable({
  providedIn: "root",
})
export class KeyboardMenuNavigationService implements OnDestroy {
  mainMenuList: MenuItem[];
  secondaryMenuList: MenuItem[];
  currentMenu: MenuItem;
  focusedMenu: MenuItem;
  previousMenu: MenuItem;
  showedMainMenu: MenuItem[] = [];
  revrtShowMenu: MenuItem[] = [];
  sortPipe: SortOrderPipe;
  shortCutKey = "";
  setShownMenu$: BehaviorSubject<MenuItem[]> = new BehaviorSubject(
    this.showedMainMenu
  );
  inSecondaryList: boolean = false;
  toRemoveClassEle: HTMLElement;
  selectedRow: any;
  public snackBarRef: MatSnackBarRef<AgSnackBarComponent>;
  captions: any;
  modelClosed = true;
  shortCutListKeys: { [key: string | number]: GuideItem };
  shortCutKeys: KeyBoardGrouping[];
  TIMEOUT_MS = 1000;
  timeoutId: NodeJS.Timeout;
  private closeSelectSubject = new Subject<void>();
  closeSelect$ = this.closeSelectSubject.asObservable();
  constructor(
    public _routingMenuService: routingMenuService,
    private router: Router,
    private dialog: MatDialog,
    private localization: Localization
  ) {
    this.captions = this.localization.captions;
    this.getData();
  }
  ngOnDestroy() {
    this.modelClosed = true;
    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
  }

  getData() {
    this.mainMenuList = this.getSessionStorage("mainMenuList") || [];
    this.secondaryMenuList = this.getSessionStorage("menuList") || [];
    this.currentMenu = this.getSessionStorage("currentMenu");
    this.showedMainMenu = this.getSessionStorage("showedMainMenu") || [];
    this.revrtShowMenu = [...this.showedMainMenu];
    this.shortCutListKeys = this.getSessionStorage("shortCutListKeys") || [];
    this.shortCutKeys = this.getSessionStorage("shortCutKeys") || [];
  }
  getSessionStorage(key: string) {
    return JSON.parse(sessionStorage.getItem(key));
  }
  shortCutKeyMaker(event: KeyboardEvent) {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.shortCutKey = `${this.shortCutKey}${event.key}`;
    // to catch three combined keys here using timeout 0.7ms
    this.timeoutId = setTimeout(() => {
      this.shortCutDecider(event);
    }, this.TIMEOUT_MS);
  }
  switchOffShortcutMode() {
    document.getElementById("ag-snackbar-keyBoard").style.display = "none";
    this.shortCutKey = "";
    this.modelClosed = true;
  }
  shortCutDecider(event: KeyboardEvent) {
    if (!this.modelClosed) {
      this.scrollInGuideModel(event);
    } else if (this.modelClosed) {
      this.getKeyboardMode(event);
    } else {
      this.shortCutKey = "";
    }
  }

  scrollInGuideModel(event: KeyboardEvent) {
    const targetElement = document.getElementById(
      `to${this.shortCutKey.toLowerCase()}`
    );
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      this.getKeyboardMode(event);
    }
    this.shortCutKey = "";
  }
  shortCutFunctions(event) {
    const keyMap = {
      up: "ArrowUp",
      down: "ArrowDown",
      left: "ArrowLeft",
      right: "ArrowRight",
      escape: "Escape",
      one: "1",
      // editAction: "e",
    };
    if (!event.ctrlKey && event.key !== "Control") {
      this.keyBoardPlay(event, keyMap);
    } else if (event.ctrlKey && event.key !== "Control" && this.modelClosed) {
      this.arrowKeyPlay(event, keyMap);
    } else {
      this.shortCutKey = "";
    }
  }
  keyBoardPlay(event: KeyboardEvent, keyMap: { [key: string]: string }) {
    const key = event.key.toLowerCase();
    switch (key) {
      case keyMap.escape.toLowerCase():
        this.setKeyboardMode(false);
        this.dialog.closeAll();
        break;
      // case keyMap.one.toLowerCase():
      //   // will use it once table action enables
      //   // this.focusTables(1);
      //   break;
      // case keyMap.editAction.toLowerCase():
      //   this.tableActionTrigger();
      //   break;
      default:
        this.routeByShortcut(this.shortCutKey);
        break;
    }

    event.preventDefault();
    this.shortCutKey = "";
  }

  arrowKeyPlay(event: KeyboardEvent, keyMap: { [key: string]: string }) {
    switch (event.key) {
      case keyMap.up:
        this.ctrlNavigateUp();
        event.preventDefault();
        break;
      case keyMap.down:
        this.ctrlNavigateDown();
        event.preventDefault();
        break;
      case keyMap.left:
        this.ctrlNavigateRightLeft(false);
        event.preventDefault();
        break;
      case keyMap.right:
        this.ctrlNavigateRightLeft(true);
        event.preventDefault();
        break;
      default:
        break;
    }
    this.shortCutKey = "";
  }

  private focusTables(tablePos: number) {
    // based on table index go to the table
    const targetTable = document.querySelectorAll("table")[tablePos - 1];
    const tbody = targetTable?.querySelector("tbody");
    if (tbody) {
      this.selectedRow = tbody.querySelector("tr:nth-of-type(1)");
      // get firstrow of table
      if (this.selectedRow) {
        this.selectedRow.focus();
        this.selectedRow.addEventListener("click", () => {
          // add css class on td
          const tds = this.selectedRow.querySelectorAll("td");
          tds.forEach((td: { classList: { add: (arg0: string) => void } }) => {
            td.classList.add("highlighted-row");
          });
        });
        const clickEvent = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        // trigger click event
        this.selectedRow.dispatchEvent(clickEvent);
      } else {
        console.error("Selected row not found.");
      }
    } else {
      console.error("Table body (<tbody>) not found.");
    }
  }

  tableActionTrigger() {
    if (this.selectedRow) {
      const actionButton = this.selectedRow.querySelector(
        'button[title="Edit"]'
      );
      // to trigger action button need a title for the button for example mentioned Edit replace it with common action
      if (actionButton) {
        actionButton.click();
      } else {
        console.error("Edit button not found in third row.");
      }
    }
  }

  private setKeyboardMode(mode: boolean) {
    if (!mode) {
      this.resetAll();
    } else if (mode && this.modelClosed) {
      this.getData();
      this.triggerKeyGuidePopup();
    }
  }

  // turn on keyboard mode on and off
  private getKeyboardMode(event: KeyboardEvent) {
    if (this.shortCutKey === "MetaShift" && this.modelClosed) {
      // comment with guide now
      // document.getElementById("ag-snackbar-keyBoard").style.display = "none";
      // this.setKeyboardMode(true);
      // event.preventDefault();
      // this.shortCutKey = "";
      return true;
    } else if (this.shortCutKey === "Escape") {
      this.shortCutKey = "";
      document.getElementById("ag-snackbar-keyBoard").style.display = "none";
      this.triggerModelAction();
      return false;
    } else if (this.shortCutKey === "ControlShift"  && this.modelClosed) {
      this.shortCutKey = "";
      this.getData();
      document.getElementById("ag-snackbar-keyBoard").style.display = "block";
      this.modelClosed = false;
      return false;
    } else {
      this.shortCutFunctions(event);
    }
  }

  // based on user key input redirect to the route path
  private routeByShortcut(key: string) {
    if (!this.modelClosed) {
      this.shortCutKey = "";
      const routePath = this.shortCutListKeys[key.toLowerCase()].RoutePath;
      if (routePath) {
        const data = { routePath: routePath };
        this.router.navigate([routePath], { state: { data } });
        document.getElementById("ag-snackbar-keyBoard").style.display = "none";
        this.triggerModelAction();
      }
    }
  }
  triggerEscape() {
    this.closeSelectSubject.next();
  }
  triggerModelAction() {
    this.modelClosed = true;
    this.dialog.closeAll();
    this.triggerEscape();
  }

  // due to nested array filter object based on id using recursive
  findObjectById(list: MenuItem[], id: number) {
    for (let obj of list) {
      if (obj.elementID === id) {
        return obj;
      }
      if (obj.linkedElement && obj.linkedElement.length > 0) {
        let found = this.findObjectById(obj.linkedElement, id); // Recursively search
        if (found) {
          return found;
        }
      }
    }
    return null;
  }

  private findSiblings(menuItems: MenuItem[], id: number) {
    let foundItem: MenuItem[] | undefined;
    menuItems.forEach((item) => {
      if (item.parentID === 0) {
        return menuItems;
      }
      if (item.parentID === id) {
        foundItem = [item];
      } else if (item.linkedElement.length > 0) {
        const nestedResult = this.findSiblings(item.linkedElement, id);
        if (nestedResult) {
          foundItem = nestedResult;
        }
      }
    });

    return foundItem;
  }

  resetAll() {
    this.toRemoveClassEle?.blur();
    this.toRemoveClassEle?.classList.remove("mat-tab-label-active");
    this.focusedMenu = null;
    this.inSecondaryList = false;
    this.getData();
  }

  focusElement(elementId: string, focus = true) {
    if (elementId) {
      const element = document.getElementById(elementId);
      const currEle = document.getElementById(
        this.previousMenu.elementID.toString()
      );
      let anchorElement: HTMLElement;
      if (element) {
        anchorElement = element.querySelector("a");
      }
      if (currEle) {
        currEle.classList.remove("mat-tab-label-active");
      }
      if (anchorElement) {
        this.focusBlur(anchorElement, focus);
      } else if (element) {
        element.classList.add("mat-tab-label-active");
        this.focusBlur(element, focus);
      }
    }
  }

  focusBlur(ele: HTMLAnchorElement | HTMLElement, focus: boolean) {
    if (focus) {
      this.toRemoveClassEle = ele;
      ele.focus();
    } else {
      ele.blur();
    }
  }

  ctrlNavigateUp() {
    if (this.currentMenu) {
      this.inSecondaryList = false;
      const idToFocus =
        this.currentMenu.parentID === 0
          ? this.currentMenu.elementID
          : this.currentMenu.parentID;
      this.focusedMenu = this.currentMenu;
      this.toRemoveClassEle?.classList.remove("mat-tab-label-active");
      this.focusElement(idToFocus.toString());
    }
  }

  ctrlNavigateDown() {
    this.getData();
    if (this.currentMenu.linkedElement.length > 0) {
      this.secondaryMenuList = this.currentMenu.linkedElement;
      sessionStorage.setItem(
        "menuList",
        JSON.stringify(this.secondaryMenuList)
      );
      if (this.secondaryMenuList.length > 0) {
        this.currentMenu.linkedElement = this.sortMenu(
          this.currentMenu.linkedElement
        );
        const childItem = this.currentMenu.linkedElement[0];
        if (childItem) {
          this.inSecondaryList = true;
          this.focusedMenu = childItem;
          this.focusElement(childItem.elementID.toString());
        }
      }
    }
  }

  ctrlNavigateRightLeft(right = true) {
    if (!this.focusedMenu && !this.currentMenu) return;
    this.getData();
    const focusMenu = this.focusedMenu || this.currentMenu;
    const menu =
      focusMenu.menuPosition === "Primary"
        ? this.mainMenuList
        : this.secondaryMenuList;

    let nextSibling = this.findSibilings(menu, focusMenu, right);
    if (
      this.previousMenu &&
      this.previousMenu.elementID ===
        this.showedMainMenu[this.showedMainMenu.length - 1]?.elementID &&
      menu[0].menuPosition === "Primary"
    ) {
      this.showedMainMenu.pop();
      if (!this.isObjectInArray(this.showedMainMenu, nextSibling.elementID)) {
        this.showedMainMenu.push(nextSibling);
      } else {
        this.showedMainMenu = [...this.revrtShowMenu];
      }
      sessionStorage.setItem(
        "showedMainMenu",
        JSON.stringify(this.showedMainMenu)
      );
      this.setShownMenu$.next(this.showedMainMenu);
    }
    this.previousMenu = focusMenu;
    this.focusedMenu = nextSibling;
    // menus not updated in Dom without using timeout so using 0.1ms
    setTimeout(() => {
      this.focusElement(nextSibling.elementID.toString());
    }, 100);
  }

  isObjectInArray(arr: MenuItem[], objectId: number): boolean {
    return arr.some((obj) => obj.elementID === objectId);
  }

  sortMenu(menu: MenuItem[]) {
    return menu.sort(
      (a: { order: number }, b: { order: number }) => a.order - b.order
    );
  }

  findSibilings(
    menu: MenuItem[],
    focusMenu: MenuItem,
    right: boolean
  ): MenuItem {
    if (!menu || menu.length === 0 || !focusMenu) {
      throw new Error("Invalid menu or focusMenu provided.");
    }
    menu = this.sortMenu(menu);
    let currentIndex = this.findIndex(menu, focusMenu);
    if (currentIndex === -1) {
      const parentMenu = menu.find(
        (item) => item.elementID === focusMenu.parentID
      )?.linkedElement;
      if (!parentMenu) {
        throw new Error("Parent menu not found for focusMenu.");
      }
      menu = this.sortMenu(parentMenu);
      currentIndex = this.findIndex(menu, focusMenu);
      if (currentIndex === -1) {
        throw new Error("FocusMenu not found in parent menu.");
      }
    }
    return this.findNextSibling(right, currentIndex, menu);
  }

  findNextSibling(
    right: boolean,
    currentIndex: number,
    menu: MenuItem[]
  ): MenuItem {
    if (
      !menu ||
      menu.length === 0 ||
      currentIndex < 0 ||
      currentIndex >= menu.length
    ) {
      throw new Error("Invalid menu or currentIndex provided.");
    }
    let nextSibling: MenuItem;
    if (right && currentIndex < menu.length - 1) {
      nextSibling = menu[currentIndex + 1];
    } else if (!right && currentIndex > 0) {
      nextSibling = menu[currentIndex - 1];
    } else if (menu[0].menuPosition !== "Primary") {
      nextSibling = right ? menu[0] : menu[menu.length - 1];
    } else if (
      menu[0].menuPosition === "Primary" &&
      right &&
      this.mainMenuList.length > 0
    ) {
      nextSibling = this.showedMainMenu[0];
    }
    if (!nextSibling) {
      throw new Error("Next sibling not found.");
    }
    return nextSibling;
  }

  findIndex(menu: MenuItem[], focusMenu: MenuItem) {
    return menu.findIndex((item) => item.elementID === +focusMenu.elementID);
  }
  triggerKeyGuidePopup() {
    this.modelClosed = false;
    this.shortCutKey = "";
    let data = {
      guideJson: this.shortCutKeys,
    };
    const dialogRef = this.dialog
      .open(AgShortcutKeyGuideComponent, {
        width: "100%",
        height: "100%",
        maxWidth: "100vw",
        hasBackdrop: true,
        panelClass: "shortcut-overlay",
        data,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        this.modelClosed = true;
      });
    return dialogRef;
  }
}
