import { Injectable } from '@angular/core'; 
import { GolfScheduleCommunication } from 'src/app/shared/communication/services/golfschedule.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { PlayersWithContactInformation } from './notify.model';
import { GuestExportNotificationModel } from 'src/app/common/shared/shared.modal';

@Injectable({ providedIn: "root"})

export class notifyService {


    constructor(private _golfScheduleCommunication: GolfScheduleCommunication, private _localization: GolfLocalization) { }

    public sendNotification(players : PlayersWithContactInformation[], CanSendEmail : boolean, CanSendSMS : boolean, bSaveOrUpdateContact : boolean, action: string, canSendAuthorize: boolean) {
        return this._golfScheduleCommunication.postPromise<PlayersWithContactInformation[]>(
            { route:  GolfApiRoute.SendManualNotification , 
              body: players , 
              uriParams :{CanSendEmail : CanSendEmail, CanSendSMS : CanSendSMS, bSaveOrUpdateContact : bSaveOrUpdateContact , teeTimeAction : action, canSendAuthorize: canSendAuthorize } });
    }

    public sendGuestExportNotification(notifyModel : GuestExportNotificationModel) {
        return this._golfScheduleCommunication.putPromise<boolean>(
            { route:  GolfApiRoute.SendGuestExportNotification , 
              body: notifyModel , 
            });
    }
}
