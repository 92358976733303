import { Injectable } from "@angular/core";
import { DepositeService } from './add-deposite-service';
import { SettingsDataService } from 'src/app/shared/data-services/golfmanagement/settings.data.service';
import { API } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { SettingModule, SettingScreen, CheckBoxTableTemplate, GolfSessionKey } from 'src/app/shared/global.constant';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { UnPaidPlayersBusiness } from 'src/app/shared/data-services/golfschedule/unpaid-players.business';
import { PlayerDeposit } from './add-deposite-model';
import { PaymentBusinessService } from 'src/app/shared/data-services/payment/payment-business.service';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonPopupComponent } from 'src/app/shared/components/common-popup/common-popup.component';
import { Router } from '@angular/router';
import { CommonVariablesService } from 'src/app/retail/shared/service/common-variables.service';
import { Deposit } from 'src/app/shared/payment-model';
import { AlertType, ButtonType, CheckboxTableHeader } from 'src/app/shared/shared-models';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { ScheduledTeeTimeUnPaidPlayer } from 'src/app/shared/models/unpaid-players.model';
import { RetailBussinessService } from 'src/app/shared/retail.bussiness';
import { RedirectToModules } from 'src/app/retail/shared/utilities/retail-utilities';
import { DepositEventModel } from 'src/app/retail/shared/events/event.model';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { DefaultOutletType } from 'src/app/common/shared/shared/enums/enums';
import { CourseDataService } from 'src/app/shared/data-services/golfschedule/course.data.service';
import { DefaultUserConfigDataService } from "src/app/settings/utilities/manager-utilities/default-user-config/default-user-config.data.service";
import { UserDataService } from "src/app/shared/data-services/tenantmanagement/user.data.service";
import { PlayerPaymentstatus } from "src/app/retail/shared/shared.modal";
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { TeeTimeCustomFeeUtilities } from "../tee-time-custom-fee.utilities";

@Injectable()

export class DepositeBusiness {
  public readonly depositCaption: any;
  constructor(
    private _addDepositService: DepositeService
    , private _settingsDataService: SettingsDataService
    , private _unPaidPlayersBusiness: UnPaidPlayersBusiness
    , private _paymentBusinessService: PaymentBusinessService
    , private _retailService: RetailSharedVariableService
    , private _retailBusinessService: RetailBussinessService
    , private _dialogRef: MatDialogRef<CommonPopupComponent>
    , private _localization: GolfLocalization
    , private _utils: GolfUtilities
    , private router: Router
    , private _propertyinfo: GolfPropertyInformation
    , private _courseDataService: CourseDataService
    , private _commonVariable: CommonVariablesService
    , private _defaultUserConfig: DefaultUserConfigDataService
    , private _userDataService: UserDataService
    , private dialog: MatDialog
    , private _PropertyInformation:RetailPropertyInformation
    , private _customFeeUtiles: TeeTimeCustomFeeUtilities
  ) {
    this.depositCaption = this._localization.captions.settings;
  }

  teeTimeConfig: API.Settings<API.TeeTimeConfig>;
  defaultConfig: API.Settings<API.DefaultsSettingConfig>;
  DepositRetailItemType: number = 6;
  UnPaidPlayers: ScheduledTeeTimeUnPaidPlayer[];
  AlertPopupVisible: boolean = false;

  getGeneralInformation() {
    return this._addDepositService.getInfo;
  }

  GetHeaderOption(): CheckboxTableHeader[] {
    let headeroptions =  [{ key: 'player', description: this.depositCaption.player, alignment: 'textLeft' },
    { key: 'playerType', description: this.depositCaption.cancelHeaderTitlePlayerType, alignment: 'textLeft' },
    { key: 'rateType', description: this.depositCaption.cancelHeaderTitleRateType, alignment: 'textLeft' },
    { key: 'amountWithoutTax', description: this._localization.replacePlaceholders(this.depositCaption.amount, ["option"], [this._localization.currencySymbol]), alignment: 'textRight' },
    { key: 'amount', description: this._localization.replacePlaceholders(this.depositCaption.itemized_Amount, ["option"], [this._localization.currencySymbol]), alignment: 'textRight', template:CheckBoxTableTemplate.fieldWithPopover },
    { key: 'deposit', description: this._localization.replacePlaceholders(this.depositCaption.deposit, ["option"], [this._localization.currencySymbol]), alignment: 'textRight', template: CheckBoxTableTemplate.inputText },
    { key: 'balance', description: this._localization.replacePlaceholders(this.depositCaption.balancedue, ["option"], [this._localization.currencySymbol]), alignment: 'textRight' }];
    
    const memberConfig = this._PropertyInformation.getMemberConfig;
    if(memberConfig && memberConfig.applicableCorporates!= null && memberConfig.applicableCorporates != '')
    {
      headeroptions.splice(3,0,{ key: 'corpName', description: this.depositCaption.corpName, alignment: 'textLeft' });
    }
    return headeroptions;
  }

  async GetTeeTimeConfig() {
    this.teeTimeConfig = await this._settingsDataService.getSettings<API.TeeTimeConfig>(SettingModule.SystemSetup, SettingScreen.TeeTime);
  }

  async GeDefaultConfig() {
    this.defaultConfig = await this._settingsDataService.getSettings<API.DefaultsSettingConfig>(SettingModule.SystemSetup, SettingScreen.Defaults);
  }

  async GetDepositPlayers(data, callBackFn): Promise<PlayerDeposit[]> {

    await this.GetTeeTimeConfig();

    let DepositplayerList: PlayerDeposit[] = [];
    this.UnPaidPlayers = await this._unPaidPlayersBusiness.getPlayers(data.course.id, this._localization.convertDateTimeToAPIDateTime(data.time), PlayerPaymentstatus.unPaid | PlayerPaymentstatus.refund, false, true);
    this.UnPaidPlayers.forEach(element => {
      element.id = element.playerId;
    });
    let unpaidPlayersData = this.UnPaidPlayers.filter(p => p.scheduledTeeTimeId == data.scheduledTeeTimeId);
    DepositplayerList = this.MapDepositForPlayer(unpaidPlayersData, 0, data.createdDateTime);
    // commented for bug 128106 -- for bulk, we are not showing popup, hence removed for tournament also
    // const filteredObj = unpaidPlayersData.find(x => x.isTournamentPlayersUnPaid && x.tournamentId > 0);
    // if (filteredObj) {
    //   const tournamentPlayers = this.MapDepositForPlayer(this.UnPaidPlayers, filteredObj.tournamentId, data.createdDateTime);
    //   this._utils.showAlert(this._localization.captions.teetime.PayByTournamentConfirmationMsg, AlertType.Info, ButtonType.YesNo, callBackFn, tournamentPlayers);
    // }
    return DepositplayerList;
  }

  MapDepositForPlayer(unpaidPlayersData, tournamentId: number, createdDateTime: string) {
    const DepositplayerList: PlayerDeposit[] = [];
    let teeTimeSettings = JSON.parse(sessionStorage.getItem('TEETIMESETTING'));
    if (unpaidPlayersData.length > 0) {
      unpaidPlayersData = tournamentId ? unpaidPlayersData.filter(x => x.tournamentId == tournamentId) : unpaidPlayersData;
      unpaidPlayersData.map(p => {
        if (teeTimeSettings && teeTimeSettings?.requireCheckInCheckOut && ((p.playerStatus & PlayerPaymentstatus.checkIn) != 0 || (p.playerStatus & PlayerPaymentstatus.checkOut) != 0)) {
          return
        }
        const prefilValues = this.GetDepositValues(p, createdDateTime);
        let Depositplayer: PlayerDeposit = {
          id: p.playerId,
          player: p.playerName,
          playerType: p.playerType,
          playerCategoryId: p.playerCategoryId,
          rateTypeId: p.rateTypeId,
          rateType: p.rateType,
          amountWithoutTax: prefilValues[0],
          amount: prefilValues[1],
          deposit: p.multiPackTransactionDetailId != 0 ? this._localization.localizeCurrency(0, false, 2) :  this._localization.localizeCurrency(prefilValues[2], false, 2) ,
          balance: p.multiPackTransactionDetailId != 0 ? this._localization.localizeCurrency(0, false, 2) : prefilValues[3],
          ticketNumber: p.ticketNumber,
          transactionId: p.transactionId,
          playerName:  this._utils.formatGuestName(p.firstName, p.lastName),
          totalTax: prefilValues[4],
          corpId: p.corpId,
          corpName: p.corpName
        };
        Depositplayer.customFee = prefilValues[5] ? this._localization.localizeCurrency(prefilValues[5], false, 2) :  ''; 
        p.scheduledTeeTimePlayerCustomFees?.forEach(cstmFee => {
          cstmFee.amount = this._localization.localizeCurrency(cstmFee.amount,false, (Number(sessionStorage.getItem(GolfSessionKey.NoOfDecimalDigits)) ?? 0));
        });
        Depositplayer.customFeeDetails = p.scheduledTeeTimePlayerCustomFees;        
        DepositplayerList.push(Depositplayer);
      });
    }


    return DepositplayerList;
  }

  GetDepositValues(player: ScheduledTeeTimeUnPaidPlayer, createdDateTime: string): [string, string, number, string, string, number] {
    let TotalAmountWithoutTax: number, TotalAmountWithTax: number, Deposit: number, BalanceDue: number, ExistingDeposit: number = 0, TotalTax: number, CustomFee = 0;
    player.deposits.forEach(d => ExistingDeposit += (d.amount+d.refundAmount));
    const prefilValues = this.GetTotalAmountToBePaid(player);
    TotalAmountWithTax = prefilValues[0];
    TotalAmountWithoutTax = prefilValues[1];
    TotalTax = prefilValues[2];
    CustomFee = prefilValues[3];
    TotalAmountWithTax += CustomFee;
    if (this.teeTimeConfig) {
      let NoDToPlay = this._localization.getDaysDifference(this._localization.getCurrentDate(), this._localization.getDate(player.scheduledAPIDateTime));
      let createdDate = createdDateTime ? this._localization.getDate(createdDateTime) : this._localization.getCurrentDate();
      createdDate = this.ConvertFromUTCDate(createdDate);
      let NoDAfterBooking = this._localization.getDaysDifference(this._localization.getCurrentDate(), createdDate);
      const firstDepositDue = this.teeTimeConfig.configValue.firstDeposit.dueDays;
      const secondDepositDue = this.teeTimeConfig.configValue.secondDeposit.dueDays;
      let firstDeposit = 0;
      let secondDeposit = 0;
      if (!firstDepositDue || NoDAfterBooking >= firstDepositDue || NoDToPlay <= secondDepositDue) {
        firstDeposit = this.CalculateDeposit(TotalAmountWithTax, this.teeTimeConfig.configValue.firstDeposit);
      }
      if (NoDToPlay <= secondDepositDue) {
        secondDeposit = this.CalculateDeposit(TotalAmountWithTax, this.teeTimeConfig.configValue.secondDeposit);
      }
         
      
      Deposit = (firstDeposit + secondDeposit) - ExistingDeposit;
      Deposit = Deposit < 0 ? 0 : Deposit;
    }
    BalanceDue = TotalAmountWithTax - ExistingDeposit;
    return [this._localization.localizeCurrency(TotalAmountWithoutTax, false, 2),
      this._localization.localizeCurrency(TotalAmountWithTax, false, 2),
      Deposit,
      this._localization.localizeCurrency(BalanceDue, false, 2),
      this._localization.localizeCurrency(TotalTax, false, 2),
      CustomFee];
  }

  // converting UTC date to  current date
  ConvertFromUTCDate(utcDate: Date): Date {
    return new Date(utcDate.toString() + "UTC");
  }

  GetTotalAmountToBePaid(player: ScheduledTeeTimeUnPaidPlayer): [number,number,number,number] {
    let TotalAmountWithTax: number = 0, TotalAmountWithoutTax: number = 0, TotalTax: number = 0, customFee = 0;
    if (player) 
    {
      if (player.tournamentId > 0 && player.greenFee && player.cartFee) 
      {  
          TotalAmountWithTax = (player.walk.toUpperCase() == "YES" || player.trail.toUpperCase() == "YES") ? (player.greenFee + player.entryFee + player.greenFeeTax + player.entryFeeTax) : (player.greenFee + player.cartFee + player.entryFee + player.greenFeeTax + player.cartFeeTax + player.entryFeeTax);
          player.otherItems.forEach(item => TotalAmountWithTax += item.price);
          TotalAmountWithoutTax = (player.walk.toUpperCase() == "YES" || player.trail.toUpperCase() == "YES") ? (player.greenFee + player.entryFee) : (player.greenFee + player.cartFee + player.entryFee);
          TotalTax = (player.walk.toUpperCase() == "YES" || player.trail.toUpperCase() == "YES") ? (player.greenFeeTax + player.entryFeeTax) : (player.greenFeeTax + player.cartFeeTax + player.entryFeeTax);
      } 
      else
      {      
        TotalAmountWithTax = (player.walk.toUpperCase() == "YES" || player.trail.toUpperCase() == "YES") ? player.greenFee + player.greenFeeTax : (player.greenFee + player.cartFee + player.greenFeeTax + player.cartFeeTax);      
        TotalAmountWithoutTax = (player.walk.toUpperCase() == "YES" || player.trail.toUpperCase() == "YES") ? player.greenFee : (player.greenFee + player.cartFee);
        TotalTax =  (player.walk.toUpperCase() == "YES" || player.trail.toUpperCase() == "YES") ? player.greenFeeTax : (player.greenFeeTax + player.cartFeeTax); 
      }
      customFee = this._customFeeUtiles.GetCustomFeeForPlayer(player);
      TotalTax += player.totalCustomFeeTax ?? 0;
      TotalAmountWithTax += player.totalCustomFeeTax ?? 0;
    }
    return [TotalAmountWithTax,TotalAmountWithoutTax,TotalTax,customFee];
  }

  CalculateDeposit(TotalAmount, depositConfig) {
    return TotalAmount * (depositConfig.depositPercentage / 100);
  }

  public async getRetailItemByItemType(depositItemType: any) {
    return await this._paymentBusinessService.GetRetailItemByItemType(depositItemType);
  }

  async GetDepositRetailItem(SelectedPlayers: PlayerDeposit[], data: any, shopItems: any) {

    if (!await this._retailBusinessService.CheckDepositItemsAreFromSelectedOutlet(shopItems)) {
      this._dialogRef.close();
      return;
    }
    let depositAll: Deposit[] = [];
    if (SelectedPlayers) {
      SelectedPlayers.forEach(player => {
        depositAll.push({
          playerId: player.id,
          amount: this._localization.currencyToSQLFormat(player.deposit),
          depositTransactionId: 0
        })
      })
    }
    this._retailService.depositArray = [];
    this._retailService.SelectedPlayers = this.UnPaidPlayers.filter(x => SelectedPlayers.some(p => p.id == x.playerId));
    this._retailService.payeeId = this._retailBusinessService.SetP1PlayerId();
    this._retailService.SelectedDate = data.time;
    this._retailService.SeletedCourseId = data.course.id;
    this._retailService.depositArray = this.MapToDepositModel(depositAll);
    this._retailService.isFromDeposit = true;
  }

  MapToDepositModel(playerDeposit: Deposit[]) {
    let result: DepositEventModel[] = [];
    playerDeposit.forEach((x) => {
      result.push({
        amount: x.amount,
        typeId: x.playerId,
        depositTransactionId: 0,
        gratuity: 0,
        isVoided: false,
        id: 0,
        refundAmount: 0,
        refundTransactionId: 0,
        serviceCharge: 0
      });
    });
    return result;
  }

  async setDefaultOutlet(courseId: number) {
    //Setting the outlet based on the teetime default
    await this.GeDefaultConfig();
    if (this.defaultConfig.configValue.defaultOutletType == DefaultOutletType.course) {
      let course = await this._courseDataService.getCourse(courseId);
      if (this._retailService.SelectedOutletId > 0 && this._retailService.SelectedOutletId != course.defaultOutletId) {
        //Resetting the SelectedTerminal since there is no terminal selection at course level
        this._commonVariable.SelectedTerminalId = this._retailService.SelectedTerminalId = 0;
      }
      this._commonVariable.SelectedOutletId = this._retailService.SelectedOutletId = course.defaultOutletId;
      this._retailService.isTeeTimeAsPaid = true;

    } else {
      await this._defaultUserConfig.GetDefaultUserConfiguration(this._localization.GetPropertyInfo("UserId"));

      this._retailService.isTeeTimeAsPaid = false;
    }
    this._retailBusinessService.ValidateSelectedTerminalExistsInSelectedOutlet();
  }

  async setSelectedProducts(shopItemData: any, isFromBook: boolean = false) {
    shopItemData = shopItemData.filter(x => x.outletItem.some(x => x.outletId == this._retailService.SelectedOutletId));

    if (shopItemData != null && this._retailService.depositArray && this._retailService.depositArray.length > 0) {
      this._retailService.selectedProducts = [];
      let playerIndex = 0;
      let lineNumber = 1;
      this._retailService.depositArray.forEach(deposit => {
        this._retailService.selectedProducts.push({
          ItemId: shopItemData[0].retailItemDetail.id,
          ExternalPOSItemId: shopItemData[0].retailItemDetail.externalPOSId,
          ItemDescription: shopItemData[0].retailItemDetail.itemDescription,
          ItemType: shopItemData[0].retailItemDetail.itemType,
          ProductName: shopItemData[0].retailItemDetail.itemDescription,
          ServiceId: 0,
          ProductPrice: deposit.amount,
          SalesPrice: deposit.amount,
          Noofitems: 1,
          Discount: 0,
          DiscountPercentage: 0,
          DiscountTypeId: 0,
          category: shopItemData[0].retailItemDetail.category,
          isEditDisabled: true,
          isModificationRestricted: true,
          isGroupingKey: shopItemData[0].retailItemDetail.isGroupingKey,
          isPackagedItem: shopItemData[0].retailItemDetail.isPackagedItem,
          PackageItemId: 0,
          MultiPack: shopItemData[0].retailItemDetail.isMultiPack,
          ClientMultiPackId: 0,
          PackageGroupId: 0,
          isOpenPricedItem: true,
          playerName: this._retailService.SelectedPlayers[playerIndex].playerName,
          id: this._retailService.SelectedPlayers[playerIndex].playerId,
          LineNumber: lineNumber,
          payeeId: this._retailService.SelectedPlayers[playerIndex].playerId,
          costPrice: shopItemData[0].retailItemDetail.costPrice,
          marginPercentage: shopItemData[0].retailItemDetail.marginPercentage,
          isRequireComments: shopItemData[0].retailItemDetail.isRequireComments,
          isRequestName: shopItemData[0].retailItemDetail.isRequestName,
          itemComments: '',
          isDepositOnly: true,
          allowEarn: shopItemData[0].retailItemDetail.allowEarn,
          discountComments: '',
          discountReason: 0
        });

        playerIndex++;
        lineNumber++;
      });
      if (isFromBook) {
        this.dialog.closeAll()
      }
      else
        this._dialogRef.close();
      this._retailService.isFromDeposit = true;
      this._retailService.isFromEditTeeTime = true;
      this._retailService.RedirectModule = RedirectToModules.TeeTime;
      if (this.router.url == '/tee-time/teesheet/graphicalView') {
        this._retailService.RedirectModule = RedirectToModules.TeeTimeGraphicalView;
      }
      this.router.navigate(['/shop/viewshop/order']);
    }
  }

  CheckDepositExceedsAmountOwed(players: PlayerDeposit[]) {
    return (players.length > 0 && players.some(p => this._localization.currencyToSQLFormat(p.balance) > 0 && (this._localization.currencyToSQLFormat(p.deposit) > this._localization.currencyToSQLFormat(p.balance))));
  }

  ShowValidationPopup() {
    if (!this.AlertPopupVisible) {
      this.AlertPopupVisible = true;
      this._utils.showAlert(this._localization.captions.shop.DepositValidation, AlertType.Warning, ButtonType.Ok, (res) => {
        this.AlertPopupVisible = false;
      });
    }

  }

}
