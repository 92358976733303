import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation, OnChanges, ChangeDetectorRef } from '@angular/core';
import { SlideInputs, ActionPageId, TeeTimeAllocationBlock, AlertAction } from 'src/app/shared/shared-models';
import { ButtonValue } from 'src/app/shared/models/button-type.model';
import { GolfUtilities } from '../../utilities/golf-utilities';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { ScheduleStatus } from '../../models/teesheet.form.models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { PlayerPaymentstatus } from 'src/app/tee-time/search/search-model';
import { GolfImageService } from '../../data-services/Image/golf.Image.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TeeSheetSharedBusiness } from 'src/app/tee-time/shared/teesheet.shared.business';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';
import { UserAccessBreakPoints } from '../../constants/useraccess.constants';
import { TeeTimeService } from 'src/app/tee-time-actions/teetime/tee-time.service';
import { TeeTimeModel } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { RetailBusiness } from 'src/app/retail/retail-code-setup/retail-category-group/retail-category-group.business';
import { RetailBussinessService } from '../../retail.bussiness';
import { result } from 'lodash';
import { ClientMultipack } from 'src/app/retail/retail.modals';
import { PaymentBusinessService } from '../../data-services/payment/payment-business.service';
import { MultiPackDetails } from '../../models/unpaid-players.model';
import { SelectOutletPopupComponent } from '../select-outlet-popup/select-outlet-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { ViewRefundDepositComponent } from 'src/app/tee-time-actions/view-refund-deposit/view-refund-deposit.component';

@Component({
  selector: 'app-golf-actions',
  templateUrl: './golf-actions.component.html',
  styleUrls: ['./golf-actions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GolfActionsComponent implements OnInit, OnChanges {

  actionButton: ButtonValue;
  dialogObj: MatDialog;
  addButton: ButtonValue;
  captions = {
    players: this._localization.captions.teetime.player,
    DepositAmount: this._localization.captions.teetime.depositeAmmount,
    startHole: this._localization.captions.setting.startHole,
    teetime: this._localization.captions.teetime
  };
  inputs: SlideInputs = {};
  showDeposit: boolean;
  showPlayers: boolean;
  @Output() closeSlide = new EventEmitter();
  @Output() emitActionClick = new EventEmitter();
  @Output() emitButtonClick = new EventEmitter();
  @Output() emitCheckInCheckOut = new EventEmitter();
  @Output() emitAddDeposit = new EventEmitter();
  @Output() emitAllocationChange = new EventEmitter();
  playersList: any;
  allocationForm: UntypedFormGroup;
  isAllocationEdit: boolean = false;
  isStartHoleEdit: boolean = false;
  isAllocationChanged: boolean = false;
  isStartHoleChanged: boolean = false;
  isReadOnly: boolean = false;
  scheduledTeeTime: TeeTimeModel;
  displayStartHole: boolean = true;
  floatLabel: string;
  isRequireCheckInCheckOut: boolean = false;
  showDepositAdd: boolean = true;
  isCurrentPropertyDate: boolean = false;
  addCheckInCheckOutClass: boolean = false;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  isDepositRefundEnabled: boolean;
  scheduleStatus = ScheduleStatus;

  @Input('menuInputs')
  set slideInput(value: SlideInputs) {
    if (value) {
      this.inputs = {} as SlideInputs;
      this.isReadOnly = true;
      this.addButton.disabledproperty = value.isDisable;
      this.inputs.pageId = value.pageId;
      this.inputs.status = value.status;
      this.inputs.scheduleStatus = value.scheduleStatus;
      this.inputs.statusColor = value.statusColor;
      this.inputs.details = value.details;
      this.inputs.dateTime = value.dateTime;
      this.inputs.actionTitle = value.actionTitle;
      this.inputs.actions = value.actions;
      this.inputs.buttonText = value.buttonText;
      this.inputs.players = value.players;
      this.inputs.data = value.data;
      this.inputs.details = value.details;
      this.inputs.viewedByStarter = value.viewedByStarter;
      this.isCurrentPropertyDate = this.inputs?.data?.time ? this._Utilities.ValidateDatesAreEqual(this._propertyInformation.CurrentDate, this._Utilities.getDate(this.inputs.data.time)) : false;
      if (this.isRequireCheckInCheckOut) {
        var isCheckedInOut = value.data.playerDetail.filter(x => x.isCheckedIn || x.isCheckedOut)?.length;
        this.showDepositAdd = isCheckedInOut == value.data.playerDetail?.length ? false : true;
        this.inputs.checkInCheckOutActions = value?.checkInCheckOutActions;
        this.addCheckInCheckOutClass = this.isRequireCheckInCheckOut && this.isCurrentPropertyDate && this.inputs?.checkInCheckOutActions && this.inputs.checkInCheckOutActions?.length > 0;
      }
      if (value.scheduleStatus == ScheduleStatus.hold) {
        this.displayStartHole = false;
        this.inputs.details = this.inputs.details.filter(x => x.label != this._localization.captions.settings.startHole);
      }
      this.inputs.allocationBlockOptions = (value.allocationBlockOptions && value.allocationBlockOptions.length > 0) ? value.allocationBlockOptions : [];
      this.inputs.originalHoleNumber = (value.originalHoleNumber ? value.originalHoleNumber : "1");
      this.inputs.holeNumber = (value.holeNumber ? value.holeNumber : "1");

      this.showPlayers = value.players && value.players.length > 0 ? true : false;
      this.setDefaultAllocation();
      this.setDefaultStartHole();
      this.showDeposit = value.pageId !== ActionPageId.lesson && value.data.playerDetail && value.data.playerDetail.length > 0 && value.data.playerDetail.filter(x => !x.isBlocked).some(p => (p.playerStatus & PlayerPaymentstatus.unPaid) != 0 || (p.playerStatus & PlayerPaymentstatus.refund) != 0);
      this.isDepositRefundEnabled = value.data.playerDetail && value.data.playerDetail.length > 0 && value.data.playerDetail.filter(p => !p.isPaidPlayer && p.deposits.length>0).length > 0;
      if (value.pageId !== ActionPageId.lesson) {
        this.inputs.deposit = this.CalculateTotalDepositAmount(value.data.playerDetail);
        this._imageService.getProfileImages(this.inputs.players, "imageReferenceId").then(() => {
          this.cdr.detectChanges();
        });
        this.GetTeeTimeAllocationBlock(value);
      }
    }
  }

  async ValidateBreakPoints(): Promise<boolean> {
    const result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.CHANGEALLOCATIONBLOCK, true);
    return result.isAllow;
  }


  async GetTeeTimeAllocationBlock(value: SlideInputs) {
    this.inputs.teeTimeAllocationBlock = await this._teeSheetSharedBusiness.GetTeeTimeAllocationBlockByCourseIdAllocationDateTime(value.data);
    if (value.scheduledTeeTimeId > 0) {
      this.scheduledTeeTime = await this._TeeTimeService.GetTeeTimeByScheduleId(value.scheduledTeeTimeId);
      const schedulePlayers = this.scheduledTeeTime.scheduledTeeTimePlayers;
      this.inputs.data?.playerDetail?.map(x => {
        x['isWalk'] = schedulePlayers.find(s => s.playerId === x.playerId)?.isWalk || false;
        x['isTrail'] = schedulePlayers.find(s => s.playerId === x.playerId)?.isTrail || false;
        x['packageCode'] = schedulePlayers.find(s => s.playerId === x.playerId)?.packageCode || '';
      });
    }
    else {
      this.scheduledTeeTime = null;
    }
    this.isReadOnly = value.isDisable ? value.isDisable : false;
    this.cdr.detectChanges();
  }

  constructor(private _Utilities: GolfUtilities, private fb: UntypedFormBuilder,
    private _propertyInformation: GolfPropertyInformation, private _localization: GolfLocalization,
    private _imageService: GolfImageService,
    private cdr: ChangeDetectorRef, private _teeSheetSharedBusiness: TeeSheetSharedBusiness,
    public _userAccessBusiness: UserAccessBusiness, private _TeeTimeService: TeeTimeService,
    private _paymentBusiness: PaymentBusinessService
    , public _retailBusinessService: RetailBussinessService, public dialog: MatDialog,
    public _retailSharedVariableService: RetailSharedVariableService, public _retailPropertyInformation: RetailPropertyInformation) {
    this.floatLabel = this._localization.setFloatLabel;
    this.allocationForm = this.fb.group({
      allocationCode: [''],
      startHole: ['', [Validators.max(18), Validators.min(1)]]
    })
    this.dialogObj = dialog;
  }

  comparetSelects = (val1, val2) => {
    return val1 && val2 && val1.id === val2.id;
  }

  /**
   * Calculates the total amount deposited for all the players playing in this Schedule
   * @param playerList
   */

  ngOnInit() {
    var teeTimeSettingData = JSON.parse(sessionStorage.getItem('TEETIMESETTING'))
    this.isRequireCheckInCheckOut = teeTimeSettingData && teeTimeSettingData?.requireCheckInCheckOut
    this.actionButton = {
      type: 'primary',
      label: this.inputs.buttonText
    };
    this.addButton = {
      type: 'tertiary',
      label: this._localization.captions.teetime.add,
      disabledproperty: this.isReadOnly
    };
  }

  async ngOnChanges() {
    if (this.actionButton) {
      this.actionButton.label = this.inputs.buttonText;
    }
    if (this.inputs && this.inputs.data && (this.inputs.pageId !== ActionPageId.lesson)) {
      this.isBookAppointmentAllowed(this.inputs.data.time);
    }

    if (this.inputs.pageId === ActionPageId.lesson) {
      this.actionButton.disabledproperty = (this._Utilities.GetDateWithoutTime(this._localization.getDate(this.inputs.data.time)) <
        this._Utilities.GetDateWithoutTime(this._propertyInformation.CurrentDate) || this.inputs.data.status === 2);
    }
  }

  CalculateTotalDepositAmount(playerList: any[]) {
    let totalDeposit = 0;
    if (playerList) {
      playerList.forEach(p => {
        p.deposits.map(d => totalDeposit += (d.amount+d.refundAmount));
        // if (p.multiPackTransactionDetailId != 0) {
        //   let transactionId : number[] = [];
        //   transactionId.push(p.multiPackTransactionDetailId);
        //   this.calc(transactionId).then(x => {
        //     totalDeposit += (x[0].multiPackPerSalePrice);
        //     this.inputs.deposit = this._localization.localizeCurrency((totalDeposit));
        //   });          
        // }
        if (p.multiPackTransactionDetailId != 0) {
          totalDeposit += p.scheduledTeeTimePlayerFee.greenFee + p.scheduledTeeTimePlayerFee.cartFee;
        }
      });
    }
    return this._localization.localizeCurrency(totalDeposit);
  }

  async calc(transactionId: number[]): Promise<MultiPackDetails[]> {
    let result = await this._paymentBusiness.GetTeeTimeMultiPack(transactionId);
    return result;
  }

  private isBookAppointmentAllowed(inputDte: string): void {
    const _inputDateTime: Date = this._Utilities.getDate(inputDte);
    const _currentDateTime: Date = this._propertyInformation.CurrentDate;

    const _inputDate: Date = this._Utilities.resetTime(_inputDateTime);     // ignores time gets date only
    const _currentDate: Date = this._Utilities.resetTime(_currentDateTime);
    /**Action button disabled for past date booking and enabled to view for edit & past date */
    let captions = this._localization.getCaptions();
    this.actionButton.disabledproperty = ((this.inputs?.buttonText && this.inputs?.buttonText.toLowerCase()) == (captions?.teetime?.bookTeeTimeSlider && captions?.teetime?.bookTeeTimeSlider.toLowerCase()) && _inputDate.getTime() < _currentDate.getTime()) && !this.inputs?.isRefundedPlayersAvailable;
    if ((this.inputs.scheduleStatus === ScheduleStatus.frostDelay) || (this.inputs.scheduleStatus === ScheduleStatus.tempHold)) {
      this.actionButton.disabledproperty = true;
    }
    if (this.inputs.scheduleStatus === ScheduleStatus.hold && _inputDate.getTime() < _currentDate.getTime()) {
      this.actionButton.disabledproperty = true;
    }
  }


  slideMenuClose() {
    this.resetAllocationForm();
    this.resetStartHole();
    this.closeSlide.emit();
    this.scheduledTeeTime = null;
  }

  actionClick(event) {
    this.emitActionClick.emit([event, this.inputs.data]);
    this.slideMenuClose();
  }

  buttonClick(event) {
    this.emitButtonClick.emit([this.actionButton.label, this.inputs.data]);
    this.slideMenuClose();
  }

  async viewRefundDeposit(eve) {
    const result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.DEPOSITREFUND, true);
    if (result.isAllow) {
      if (!await this._retailBusinessService.onClickValidateSelectedOutlet(this.inputs.teeTimeAllocationBlock.courseId)) {
        this.openDefaultOutletPopup(true);
        return;
      }
      this.openViewDepositRefundPopup();
    }
  }

  async addDeposit(event) {
    if (!await this._retailBusinessService.onClickValidateSelectedOutlet(this.inputs.teeTimeAllocationBlock.courseId)) {
      this.openDefaultOutletPopup();
      return;
    }
    this.emitAddDeposit.emit(this.inputs.data);
    this.slideMenuClose();
  }
  async onCheckInCheckOut(action) {
    this.emitCheckInCheckOut.emit([action, this.inputs.data]);
    this.slideMenuClose();
  }
  openDefaultOutletPopup(isFromViewDepositRefund = false) {
    let popUpComponentDetails = {
      componentName: SelectOutletPopupComponent,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent',
        bindData: this.inputs.data
      }
    };
    this.dialogObj.open(SelectOutletPopupComponent, {
      width: '35%',
      height: '45%',
      panelClass: "",
      data: {
        title: this.captions.teetime.selectOutletPopupTitle,
        update: this.captions.teetime.save,
        cancel: this.captions.players.cancel,
        componentDetails: popUpComponentDetails,
        info: this.inputs.data,
        showStaticBool: false,
        isSaveClose: true
      },
      disableClose: true
    }).afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(res => {
      console.log(res);
      if (res) {
        this._retailSharedVariableService.SelectedOutletId = res.outlet;
        this._retailPropertyInformation.SetDefaultOutlet(res.outlet);
        if (res.from === AlertAction.YES) {
          if (isFromViewDepositRefund){
            this.openViewDepositRefundPopup();
          }
          else{  
            this.emitAddDeposit.emit(this.inputs.data);
            this.slideMenuClose();
          }
        }
      }
    });

  }

  openViewDepositRefundPopup(){
    const dialogRef = this.dialog.open(ViewRefundDepositComponent, {
      width: '80%',
      height: '80%',
      disableClose: true,
      data: {
          dateTime : this.inputs?.data?.allocationDateTime,
          course : this.inputs?.data?.course
        }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(result => {
    });
  }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  setDefaultAllocation() {
    if (this.inputs.allocationBlockOptions && this.inputs.allocationBlockOptions.length > 0) {
      this.allocationForm.patchValue({
        allocationCode: this.inputs.allocationBlockOptions.find(x => x.id === this.inputs.data.allocation.id)
      });
    }
    else {
      this.allocationForm.patchValue({
        allocationCode: { id: 0 }
      });
    }
  }

  async editAllocationCode(e) {
    let canAccess = await this.ValidateBreakPoints();
    if (!this.isReadOnly && canAccess) {
      this.isAllocationEdit = true;
      this.cdr.detectChanges();
    }
  }

  async editStartHoleCode(e) {
    if (!this.isReadOnly) {
      this.isStartHoleEdit = true;
      this.cdr.detectChanges();
    }
  }

  allocationCodeChange(e) {
    this.isAllocationChanged = true;
  }

  startHoleChange(e) {
    if (e.target.value) {
      this.isStartHoleChanged = true;
    } else {
      this.isStartHoleChanged = false;
    }
  }

  saveStartHole(e) {
    this.isStartHoleChanged = false;
    this.isStartHoleEdit = false;
    let viewedByStarter = this.inputs.actions.find(f => f.id == "ViewedByStarter").isDisable;
    let holeValue = this.allocationForm.get('startHole').value;
    this.SaveTeeTimeAllocationCode(this.inputs.data.allocation, holeValue, viewedByStarter);
    this.resetStartHole();
    if (this.scheduledTeeTime && this.scheduledTeeTime != null) {
      this.scheduledTeeTime.holeNumber = holeValue;
      this._teeSheetSharedBusiness.updateTeeTime(this.scheduledTeeTime);
    }
    this.scheduledTeeTime = null;
  }

  SaveTeeTimeAllocationCode(allocationValue: any, startHole: string, viewedByStarter: boolean) {
    let teeTimeAllocationBlock: TeeTimeAllocationBlock;
    this.isAllocationEdit = false;
    this.isAllocationChanged = false;
    if (this.inputs.teeTimeAllocationBlock) {
      teeTimeAllocationBlock = this.inputs.teeTimeAllocationBlock;
      teeTimeAllocationBlock.allocationBlockId = allocationValue.id;
      teeTimeAllocationBlock.holeNumber = startHole;
      teeTimeAllocationBlock.isDefault = allocationValue.id == 0 ? true : false;
      teeTimeAllocationBlock.viewedByStarter = viewedByStarter;
    }
    else {
      teeTimeAllocationBlock = {
        id: 0,
        courseId: this.inputs.data.course.id,
        allocationBlockId: allocationValue.id,
        allocationDateTime: this.inputs.data.time,
        holeNumber: startHole,
        isDefault: allocationValue.id == 0 ? true : false,
        viewedByStarter: viewedByStarter,
        originalHoleNumber: this.inputs.originalHoleNumber // to do
      }
    }
    if (teeTimeAllocationBlock && teeTimeAllocationBlock != null) {
      this.emitAllocationChange.emit(teeTimeAllocationBlock);
    }
  }



  saveAllocationCode(e) {
    this.isAllocationEdit = false;
    this.isAllocationChanged = false;
    let viewedByStarter = this.inputs.actions?.find(f => f.id == "ViewedByStarter").isDisable;
    let allocationValue = this.allocationForm.get('allocationCode').value;
    this.SaveTeeTimeAllocationCode(allocationValue, this.inputs.holeNumber, viewedByStarter);
    this.resetAllocationForm();
    this.scheduledTeeTime = null;
  }

  resetAllocationForm() {
    this.allocationForm.controls.allocationCode.reset();
    this.isAllocationEdit = false;
    this.isAllocationChanged = false;
  }

  cancelAllocationCode(e) {
    this.resetAllocationForm();
    this.setDefaultAllocation();
  }

  resetStartHole() {
    this.allocationForm.controls.startHole.reset();
    this.isStartHoleChanged = false;
    this.isStartHoleEdit = false;
  }

  cancelStartHole(e) {
    this.resetStartHole();
    this.setDefaultStartHole();
  }

  setDefaultStartHole() {
    let startHole = (this.inputs.holeNumber && (this.inputs.holeNumber.includes('A') || this.inputs.holeNumber.includes('B'))) ? this.inputs.holeNumber.slice(0, -1) : this.inputs.holeNumber;
    startHole = startHole ? startHole : "";
    this.allocationForm.controls.startHole.setValue(startHole);
  }

}
