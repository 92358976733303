import { Injectable } from '@angular/core';
import { PaymentBusinessService } from './data-services/payment/payment-business.service';
import { Router } from '@angular/router';
import { GolfLocalization } from '../core/localization/golf-localization';
import { RetailSharedVariableService } from '../retail/shared/retail.shared.variable.service';
import { ClientMultipack, MultiPackReturn, Outlet, PackagedItem, PlayerPaymentstatus, RetailItemDetail } from '../retail/retail.modals';
import { ServiceParams } from './models/http.model';
import { AlertAction, ComponentDetails, PrintRentalAgreement } from 'src/app/shared/shared-models';
import {
  AlertType,
  Gratuity,
  ServiceCharge,
  SelectedProducts,
  Tournament,
  RainCheckIssue,
  SearchType,
  ACESPayment,
  TeeTimeConfig,
  ACESPaymentRecord,
  NotificationModel,
  ClientInfo
} from '../retail/shared/shared.modal';
import { ScheduledTeeTimeUnPaidPlayer, DiscountConfiguration } from './models/unpaid-players.model';
import { AuthenticationCommunication } from './communication/services/authentication.service';
import { GolfScheduleCommunication } from './communication/services/golfschedule.service';
import * as GlobalConst from '../retail/shared/globalsContant';
import { GolfPropertyInformation } from '../core/services/golf-property-information.service';
import { UserAccessBusiness } from './data-services/authentication/useraccess.business';
import { UserAccessBreakPoints } from './constants/useraccess.constants';
import { AgreementBusinessService } from '../reports/agreement/agreement-business.service';
import { UserdefaultsInformationService } from '../core/services/userdefaults-information.service';
import { RentalAgreement, ButtonAction, SettingModule, SettingScreen, ContactType, playerTypes } from './global.constant';
import { PlayerDataService } from './data-services/golfmanagement/player.data.service';
import { UnPaidPlayersBusiness } from './data-services/golfschedule/unpaid-players.business';
import { GolfUserConfigDataService } from './data-services/golfmanagement/golfuser.config.data';
import { API } from 'src/app/tee-time/tournaments/tournaments-modal/contact/contact.model';
import { BalanceList, ClientCreditCardInfo, PayeeInfo } from '../retail/shared/business/shared.modals';
import { RedeemRainCheckService } from './data-services/payment/raincheckredeem.data.service';
import { GolfUtilities } from './utilities/golf-utilities';
import { Player } from './models/player.model';
import { TeeTicketBusiness } from '../reports/business/golfReports/teetime/tee-ticket.business.service';
import { NegotiatedFeeDataService, NegotiatedFee } from './data-services/golfmanagement/negotiatedfee.data.service';
import { ACESPaymentService } from './data-services/golfschedule/acespayment.data.service';
import { RetailmanagementCommunication } from './communication/services/retailmanagement.service';
import { RouteLoaderService } from '../core/services/route-loader.service';
import { PlayerProfileDataService } from 'src/app/shared/data-services/golfmanagement/PlayerProfile.data.services';
import { CommonPopupComponent } from './components/common-popup/common-popup.component';
import { SharedService } from 'src/app/shared/shared.service';
import { PlayerInformationService } from '../common/shared/shared/service/player.information.service';
import { SettingsDataService } from './data-services/golfschedule/settings.data.service';
import { API as setting } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { GolfRoutes } from '../core/extensions/golf-route';
import { UserDataService } from './data-services/tenantmanagement/user.data.service';
import { RetailUtilities } from '../retail/shared/utilities/retail-utilities';
import { RateTypeDataService } from './data-services/golfmanagement/ratetype.data.service';
import { UserAccessModel } from '../common/dataservices/authentication/useraccess-model.model';
import { ButtonType, TransactionDetailAggregate } from '../shared/shared-models';
import _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { ContactDetails } from '../common/shared/shared.modal';
import { DefaultOutletType } from '../common/shared/shared/enums/enums';
import { CommonVariablesService } from '../retail/shared/service/common-variables.service';
import { API as settingsAPI } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { DefaultsSettingConfig } from 'src/app/tee-time-actions/teetime/player-details/player-info.model';
import { CourseDataService } from './data-services/golfschedule/course.data.service';
import { DefaultUserConfiguration } from 'src/app/settings/utilities/manager-utilities/default-user-config/default-user-config-model';
import { DefaultUserConfigDataService } from '../settings/utilities/manager-utilities/default-user-config/default-user-config.data.service';
import { CreatePlayerComponent } from './create-player/create-player.component';
import { TeeTimesActionService } from './data-services/golfschedule/teeTimesAction.data.service';
import { TeeTimeCustomFeeUtilities } from '../tee-time-actions/tee-time-custom-fee.utilities';
import { playerCategory } from '../lessons/lessons-modal/player-info/player-info.model';
import { TeeTimeTempHold } from '../tee-time-actions/teetime/tee-time.model';


@Injectable({ providedIn: 'root' })
export class RetailBussinessService {
  private captions: any;
  PackageGroupId: number = 0;
  componentDetails: ComponentDetails;
  tempPackageItems = new Map<number, PackagedItem[]>();
  constructor(
    private _paymentBusinessService: PaymentBusinessService,
    private _retailService: RetailSharedVariableService,
    private _golfAuthentication: AuthenticationCommunication,
    private _golfSchedule: GolfScheduleCommunication,
    private _retailmanagementCommunication: RetailmanagementCommunication,
    private _utils: GolfUtilities,
    private localization: GolfLocalization,
    private router: Router,
    private dialog: MatDialog,
    private userDefaultService: UserdefaultsInformationService,
    public _userAccessBusiness: UserAccessBusiness,
    private propertyInfo: GolfPropertyInformation,
    private _agreementBusiness: AgreementBusinessService,
    private _teeTicketBusiness: TeeTicketBusiness,
    private _playerDataService: PlayerDataService,
    private _unPaidPlayerBusiness: UnPaidPlayersBusiness,
    private _golfUserDataService: GolfUserConfigDataService,
    private _redeemRainCheckService: RedeemRainCheckService,
    private _negotiatedFeeDataService: NegotiatedFeeDataService,
    private _acespaymentservice: ACESPaymentService,
    private _routeLoaderService: RouteLoaderService,
    private _playerProfileDataService: PlayerProfileDataService,
    private _sharedService: SharedService,
    private _playerService: PlayerInformationService,
    private settingsDataService: SettingsDataService,
    private _userDataService: UserDataService,
    private _retailUtils: RetailUtilities,
    private _rateTypeDataService: RateTypeDataService, 
    private _commonVariable: CommonVariablesService, 
    private _courseDataService: CourseDataService, 
    private _defaultUserConfig: DefaultUserConfigDataService, 
    private _teeTimeActionService: TeeTimesActionService, 
    private _teeTimeCustomFeeUtiles: TeeTimeCustomFeeUtilities
  ) {
    this.captions = this.localization.captions.shop;
  }

  async GetPlayerById(playerId: number): Promise<PayeeInfo> {
    let playerDetail: API.Player = await this._playerDataService.GetPlayerById(playerId);
    return this.MapToPayeeInfo(playerDetail);
  }
  /**
   * GetPlayerContactInfoByPlayerIdList to get the firstordefault contact details from list of player id
   * @param lstPlayerId 
   * @returns contactDetails(Phone & email)
   */
  async GetPlayerContactInfoByPlayerIdList(lstPlayerId: number[]): Promise<ContactDetails[]> {
    let lstPlayerContactInfo = lstPlayerId && lstPlayerId != null && lstPlayerId?.length > 0 ? await this._playerDataService.GetPlayerContactInfoByPlayerIdList(lstPlayerId) : null;
    return lstPlayerContactInfo;
  }
  async GetMemberInfo(cardNo: string, date: string, corpId : number = 0): Promise<PayeeInfo> {
    let memberInfo: Tournament.UI.ContactInformation = await this._playerDataService.getMemberInfo(cardNo, date, corpId);
    if (memberInfo && memberInfo.cardStatus.toUpperCase() != 'ACTIVE') {
      this._utils.showAlert(this.localization.captions.common.InActiveMember, AlertType.Info, ButtonType.Ok);
      return null;
    } else return this.MapToPayeeInfo(memberInfo);
  }
  private GetCreditBookBalance(balanceList: BalanceList[]) {
    if (balanceList) {
      let bucket: BalanceList = balanceList.find(
        (x) => x.BucketName && x.BucketName.replace(/\s/g, '').toLowerCase() == 'creditbook'
      );
      return bucket ? bucket.AccrualCash : 0;
    }
    return 0;
  }

  MapToPayeeInfo(playerDetail: any): PayeeInfo {
    let payeeInfo: PayeeInfo = null;
    if (playerDetail) {
      payeeInfo = {
        name: playerDetail.firstName + ' ' + playerDetail.lastName,
        address:
          this.ReplaceEmptyIfNull(playerDetail.playerAddress, 'addressLine1') +
          ' ' +
          this.ReplaceEmptyIfNull(playerDetail.playerAddress, 'state'),
        country: this.ReplaceEmptyIfNull(playerDetail.playerAddress, 'country'),
        zip: this.ReplaceEmptyIfNull(playerDetail.playerAddress, 'zip'),
        city: this.ReplaceEmptyIfNull(playerDetail.playerAddress, 'city'),
        guestProfileId: playerDetail.guestId ? playerDetail.guestId : playerDetail.playerLinkId,
        cardInfo: [],
        arAccountNumber: playerDetail.arAccountNumber || 0,
        id: playerDetail.id,
        balanceList: playerDetail.balanceList,
        playerCategoryId: playerDetail.playerCategoryId,
        golfPoints: playerDetail.golfPoints,
        patronId:
          playerDetail.loyaltyDetail && playerDetail.loyaltyDetail.length > 0
            ? playerDetail.loyaltyDetail[0].patronId
            : '',
        rank:
          playerDetail.loyaltyDetail && playerDetail.loyaltyDetail.length > 0
            ? playerDetail.loyaltyDetail[0].patronId
            : '',
        lastName: playerDetail.lastName,
        firstName: playerDetail.firstName,
        creditBookBalance: this.GetCreditBookBalance(playerDetail.balanceList),
        photoUrl: playerDetail.photoUrl,
        platformGuestUuid: playerDetail.platformUuId,
        vip: playerDetail.vipType
      };
      let cardInfo: ClientCreditCardInfo = null;
      if (playerDetail.paymentReferenceId > 0) {
        cardInfo = {
          clientId: playerDetail.id,
          isActive: true,
          tokenTransId: playerDetail.paymentReferenceId
        };
        payeeInfo.cardInfo.push(cardInfo);
      }
      if (playerDetail?.contactInformation) {
        let lstPhone = this._utils.GetPhoneNumber(playerDetail?.contactInformation);
        payeeInfo.phoneNumber = lstPhone && lstPhone?.value ? lstPhone.value : null;
        let lstEmail = this._utils.GetEmail(playerDetail?.contactInformation);
        payeeInfo.emailId = lstEmail && lstEmail?.value ? lstEmail.value : null;
      }
    }
    return payeeInfo;
  }

  private ReplaceEmptyIfNull(obj: object, key: string) {
    if (obj) {
      let hasObjKey = (obj && obj[key] ? obj[key] : '');
      return obj ? hasObjKey : '';
    }
    return '';

  }

  async searchGuest(Name, type: number, requestUid, isPlatformGuestSearch: boolean = false, isExternalGuestSearch: boolean = false): Promise<[Tournament.UI.ContactInformation[], PayeeInfo[]]> {
    type = type == 1 ? SearchType.Member : SearchType.GuestAndPlayer;
    let guestInfo: Tournament.UI.ContactInformation[] = await this._playerDataService.getGuestsInfo(Name, type,isExternalGuestSearch);
    let payeeInfo: PayeeInfo[] = [];
    guestInfo.forEach((x) => {
      x.playerType = Number.parseInt(x.playerType) || 0;
      x.rateType = Number.parseInt(x.rateType) || 0;
      payeeInfo.push(this.MapToPayeeInfo(x));
    });
    return [guestInfo, payeeInfo];
  }

  async searchTransactionGuest(name: string): Promise<[API.Player[], PayeeInfo[]]> {
    let guestInfo: API.Player[] = await this._playerDataService.searchPlayer(name);
    let payeeInfo: PayeeInfo[] = [];
    guestInfo.forEach((x) => {
      payeeInfo.push(this.MapToPayeeInfo(x));
    });
    return [guestInfo, payeeInfo];
  }

  async GetUnpaidPlayers(courseId: number, date: string) {
    return await this._unPaidPlayerBusiness.getPlayers(courseId, date);
  }

  async GetUnpaidPlayersByPlayerStatus(courseId: number, date: string, playerStatus: PlayerPaymentstatus) {
    return await this._unPaidPlayerBusiness.getPlayers(courseId, date, playerStatus);
  }
  async TempHoldPlayers(playerIds: number[]) {
    return await this._teeTimeActionService.TempHoldPlayers(playerIds);
  }
  async GetTempHoldPlayers(playerIds: number[]) {
    return await this._teeTimeActionService.GetTempHoldPlayers(playerIds);
  }
  async ValidateBreakPoint(breakPointNumber: number, showPopup: boolean) {
    return await this._teeTimeActionService.ValidateBreakPoint(breakPointNumber, showPopup);
  }
  async ReleasePlayersInHold(playerIds: number[]) {
    await this._teeTimeActionService.releasePlayersInHold(playerIds);
  }

  async GetCourses() {
    return await this._unPaidPlayerBusiness.getCourses();
  }

  async GetUserCourse(userId) {
    return await this._golfUserDataService.getUserCourses(userId);
  }

  async GetRainChecks(issueddate: Date, pattern: string) {
    return await this._redeemRainCheckService.GetRainChecks(issueddate, pattern);
  }

  async UpdateRainCheck(settledRainCheck: RainCheckIssue) {
    return await this._redeemRainCheckService.UpdateRainCheck(settledRainCheck);
  }

  async GetRainChecksByTeeTime(teeTimeId: number) {
    return await this._redeemRainCheckService.GetRainChecksByTeeTime(teeTimeId);
  }

  async MarkAsPaidPlayer(lstMarkAsPaid: any[]) {
    return await this._paymentBusinessService.MarkAsPaid(lstMarkAsPaid);
  }
  async GetTransactionDepositDetails(lstTransactionId: any[]) {
    return await this._paymentBusinessService.GetTransactionDepositDetails(lstTransactionId);
  }

  async settleTransaction(
    tickekNumber: string,
    transactionId: number,
    outletId: number,
    guestId: number
  ): Promise<void> {
    let action: string = 'settle';

    this._retailService.selectedProducts = [];
    this._retailService.SelectedPlayers = [];
    this._retailService.TaxValue = 0;

    this._retailService.settleOpenTransaction = true;
    this._retailService.reOpenTransaction = false;

    let transactionDataTask = this.getTransactionById(transactionId);

    let retailItemTask = this.GetRetailItemByTransactionId(transactionId);
    await Promise.all([transactionDataTask, [], retailItemTask]);

    let selectedRetailItem = await transactionDataTask;
    this._retailService.payeeId = guestId;
    this._retailService.memberCardNumber = selectedRetailItem.memberId;
    this._retailService.transactionId = transactionId;
    this._retailService.selectedRetailItem = selectedRetailItem;
    this._retailService.SelectedOutletId = outletId;
    this._retailService.isFromDayEnd = true;
    this._retailService.settleOpenTransaction = true;
    this._retailService.selectedTransaction = selectedRetailItem;
    this._retailService.ticketNumber = tickekNumber;
    this._retailService.transactionId = transactionId;
    await this.LoadSelectedProducts(await retailItemTask, [], action);

    if (selectedRetailItem) {
      this._retailService.TaxValue = selectedRetailItem.totalTax;
    }

    this.router.navigate(['/shop/viewshop/order']);
  }

  async reopenTransaction(
    tickekNumber: string,
    transactionId: number,
    outletId: number,
    guestId: number
  ): Promise<void> {
    let action: string = 'reopen';

    this._retailService.selectedProducts = [];
    this._retailService.SelectedPlayers = [];
    this._retailService.TaxValue = 0;

    var paymentHistory = await this._paymentBusinessService.GetPaymentHistory(transactionId);

    if (paymentHistory && paymentHistory.length > 0) {
      this._utils.showError(this.captions.ReOpenNotAllowed);
      return;
    }

    this._retailService.settleOpenTransaction = false;
    this._retailService.reOpenTransaction = true;

    let transactionDataTask = this.getTransactionById(transactionId);

    let retailItemTask = this.GetRetailItemByTransactionId(transactionId);
    await Promise.all([transactionDataTask, retailItemTask]);

    let selectedRetailItem = await transactionDataTask;
    this._retailService.payeeId = guestId;
    this._retailService.transactionId = transactionId;
    this._retailService.selectedRetailItem = selectedRetailItem;
    this._retailService.SelectedOutletId = outletId;
    this._retailService.isFromDayEnd = true;
    this._retailService.payeeId = guestId;
    this._retailService.memberCardNumber = selectedRetailItem.memberId;
    await this.LoadSelectedProducts(await retailItemTask, [], action);

    this.router.navigate(['/shop/viewshop']);
  }

  private async GetRetailItemByTransactionId(transactionId: number) {
    return await this._paymentBusinessService.GetTransactionDetails(transactionId, 3);
  }


  private async getTransactionById(transactionId: number) {
    return await this._paymentBusinessService.GetTransactionById(transactionId);
  }

  /**
   * @description Retrieves all the outlest based on PropertyId and UserId
   */
  async GetUserOutlets(): Promise<Outlet[]> {
    const serviceParams: ServiceParams = {
      route: GolfApiRoute.GetSubPropertyAccessByUser,
      uriParams: {
        propertyId: this.localization.GetPropertyInfo('PropertyId'),
        userId: this.localization.GetPropertyInfo('UserId')
      }
    };
    return await this._retailmanagementCommunication.getPromise(serviceParams);
  }

  /**
   * @description Validates the user have the access to outlets
   *               and also checks for default outlet selection
   * @returns bool
   */
  async CheckForUserOutletAccessAndDefaultOutlet(dialogRef: any = '', isFromPopup: boolean = false, canNavigateToShop?: boolean, isFromEditPay: boolean = false): Promise<boolean> {
    let hasAccess: boolean = true;
    const accessibleOutlets = await this.GetUserOutlets();
    const userMachineConfig = this._retailUtils.getUserDefaults();
    const ResetProductsAndRedirect = () => {
      this._retailService.selectedProducts = [];
      if (dialogRef && !isFromPopup) {
        dialogRef.close();
      } else if (!isFromEditPay) {
        this.dialog.closeAll();
      }
      if (!canNavigateToShop) {
        this.router.navigate(['/shop/viewshop']);
      }
    };
    if (
      accessibleOutlets == null ||
      (accessibleOutlets.length > 0 && accessibleOutlets.filter((o) => o.isActive).length === 0) ||
      this._retailService.SelectedOutletId === 0 ||
      (this.propertyInfo.UseRetailInterface && Number(this._retailService.SelectedTerminalId) === 0) // With EmbededRetail terminal selection will not be required
    ) {
      ResetProductsAndRedirect();
      let alertMsg =
        this._retailService.SelectedOutletId === 0
          ? this.localization.captions.shop.PleaseSelectDefaultOutlet
          : this.localization.captions.shop.selectTerminal;
      if (!canNavigateToShop) {
        this._utils.showAlert(alertMsg, AlertType.Warning, ButtonType.Ok);
      }
      this._retailService.isPromptedForTerminalSelect = (Number(this._retailService.SelectedTerminalId) === 0);
      hasAccess = false;
    }
    else if (this._retailService.SelectedOutletId > 0 && (accessibleOutlets.length == 0 || !accessibleOutlets.some(f => f.subPropertyID == this._retailService.SelectedOutletId))) {
      ResetProductsAndRedirect();
      this._utils.showAlert(this.localization.captions.shop.CourseOutletAccess, AlertType.Warning, ButtonType.Ok);
      hasAccess = false;
    }
    if (this.propertyInfo.UseRetailInterface && this._retailService.SelectedTerminalId != 0 && this._retailService.SelectedOutletId != 0
      && accessibleOutlets && accessibleOutlets.length > 0) {
      const storeTerminalList = await this._userDataService.GetStoreTerminal(String(this._retailService.SelectedOutletId));
      if (storeTerminalList && storeTerminalList.length > 0 && !storeTerminalList.some(s => s.terminalId == this._retailService.SelectedTerminalId)) {
        hasAccess = false;
        this._retailService.SelectedTerminalId = userMachineConfig.defaultTerminalId > 0 && userMachineConfig.defaultTerminalId != this._retailService.SelectedTerminalId
          && storeTerminalList.some(s => s.terminalId == userMachineConfig.defaultTerminalId) ? userMachineConfig.defaultTerminalId : 0;
        this._retailService.isPromptedForTerminalSelect = true;
        ResetProductsAndRedirect();
        this._utils.showAlert(this.localization.captions.shop.selectTerminal, AlertType.Warning, ButtonType.Ok);
      } else {
        this._retailService.isPromptedForTerminalSelect = false;
      }
    }
    return hasAccess;
  }

  /**
   * @description Validates the deposit item is from the selected outlet
   * @returns bool
   */
  async CheckDepositItemsAreFromSelectedOutlet(shopitems): Promise<boolean> {
    const selectedOutletItems = shopitems.filter((si) =>
      si.outletItem.some((o) => o.outletId == this._retailService.SelectedOutletId)
    );
    let depositItemInSelectedOutletItem: boolean = selectedOutletItems.some(
      (x) => x.retailItemDetail.itemType == 6
    );
    if (!depositItemInSelectedOutletItem) {
      const errorMsg = this.localization.getError(10722);
      this._retailService.selectedProducts = [];
      this._utils.showAlert(errorMsg, AlertType.Warning, ButtonType.Ok);
    }
    return depositItemInSelectedOutletItem;
  }

  /**
   * @description Validates the item is from the selected outlet
   * @returns bool
   */
  async CheckItemsAreFromSelectedOutlet(shopitems, isFromTeeSheetMove=false): Promise<boolean> {
    const uniqShopItems = _.uniqBy(shopitems, 'id');
    const selectedItemDetails = await this.GetShopInstance(uniqShopItems);
    let itemsAreValid = true;
    if (!selectedItemDetails.every(i => i.outletItem.some(o => o.outletId == this._retailService.SelectedOutletId))) {
      let errorMsg = '';
      if(isFromTeeSheetMove)
      {
        errorMsg = this.localization.getError(20702);
      } 
      else{
        errorMsg = this.localization.getError(10726);
      }
      this._utils.showAlert(errorMsg, AlertType.Warning, ButtonType.Ok);
      itemsAreValid = false;
    }
    return itemsAreValid;
  }

  async CheckItemsAreFromSelectedOutletMarkAsPaid(shopitems): Promise<any> {
    const uniqShopItems = _.uniqBy(shopitems, 'id');
    let selectedItemDetails = await this.GetShopInstance(uniqShopItems);
    var itemsNotInSelectedOutlet = selectedItemDetails.filter(i => i.outletItem.every(o => o.outletId != this._retailService.SelectedOutletId)).map(c => c.id);
    return itemsNotInSelectedOutlet;
  }

  CheckPlayersHaveAnyPendingSettlements(selectedPlayers: ScheduledTeeTimeUnPaidPlayer[]): boolean {
    let playersHavePendingSettlements = false;
    if (selectedPlayers && selectedPlayers.length > 0) {
      if (selectedPlayers.some((p) => p.ticketNumber != '')) {
        playersHavePendingSettlements = true;
        this.validationOnPendingSettlement(selectedPlayers);
      }
    }
    return playersHavePendingSettlements;
  }
  checkPlayersHavePendingSettlementInDeposit(selectedPlayers): boolean {
    let playersHavePendingSettlements = false;
    if (selectedPlayers && selectedPlayers.length > 0) {
      if (selectedPlayers.some((p) => p.ticketNumber != '')) {
        playersHavePendingSettlements = true;
        this.validationOnPendingSettlement(selectedPlayers);
      }
    }
    return playersHavePendingSettlements;
  }
  validationOnPendingSettlement(selectedPlayers) {
    let pendingPlayers = selectedPlayers.filter((p) => p.ticketNumber != '');
    let playerNamesWithTickets: string = '';
    pendingPlayers.forEach((p) => {
      playerNamesWithTickets += `<b>${p.playerName}</b> - ${p.ticketNumber}<br/>`;
    });
    let alertMsg =
      this.localization.captions.teetime.PlayerHasPendingSettlemtWarningMsg +
      '<br>' +
      playerNamesWithTickets;
    this._utils.showAlert(alertMsg, AlertType.Info, ButtonType.Ok);
  }
  async GetPlayerDetailsByIds(playerIds: number[]): Promise<PayeeInfo[]> {
    let result: Player[] = await this._golfSchedule.postPromise<Player[]>({
      route: GolfApiRoute.GetPlayersByIds,
      body: playerIds
    });
    return this.MapPlayerWithPayee(result);
  }

  MapPlayerWithPayee(players: Player[]) {
    return players.map((p) => {
      let email = this._utils.GetEmail(p?.contactInformation)
      return {
        id: p.id,
        name: p.firstName + ' ' + p.lastName,
        playerCategoryId: p.playerCategoryId,
        emailId: email && email?.value ? email.value : null,
        guestId: p.id.toString(),
      } as PayeeInfo;
    });
  }

  private async GetShopInstance(shopitems) {
    const id = shopitems.map(x => x.id);
    return await this._paymentBusinessService.GetRetailItemsDetailedInfoByIds(id);
  }

  GetDefaultCourses() {
    return this.userDefaultService.GetDefaultCourses();
  }

  GetCustomStaffId(serviceChargeGratuity: any): string {
    let customId: string = '';

    if (serviceChargeGratuity.staffType == GlobalConst.THERAPIST) {
      customId = `T${serviceChargeGratuity.therapistId}`;
    }
    else if (serviceChargeGratuity.staffType == GlobalConst.USER) {
      customId = `U${serviceChargeGratuity.therapistId}`;
    }
    return customId;
  }

  SetCustomStaffIdForCommission(commission: any[]): any[] {
    if (!commission || commission.length == 0) {
      return commission;
    }
    commission.forEach((c) => {
      if (c.staffType.toUpperCase() == GlobalConst.THERAPIST) {
        c.id = `T${c.staffId}`;
      } else if (c.staffType.toUpperCase() == GlobalConst.USER) {
        c.id = `U${c.staffId}`;
      }
    });
    return commission;
  }

  async LoadSelectedProducts(transactionData: any, allItems: any[], action: string) {
    let excludeDiscount = action && action.toLowerCase() == 'correct' && this.propertyInfo.UseRetailInterface;

    const retailItemId = transactionData.map(x => x.itemId);
    allItems = await this._paymentBusinessService.GetRetailItemsDetailedInfoByIds(retailItemId);

    for (let transaction of transactionData) {
      let item = allItems.filter((x) => x.id == transaction.itemId);
      let unitOfMeasureDispValue: string = '';
      if (transaction.unitOfMeasureId && transaction.unitOfMeasureId > 0) {
        let unitOfMeasure =
          this._retailService.unitOfMeasures && this._retailService.unitOfMeasures.length > 0
            ? this._retailService.unitOfMeasures.find((x) => x.id == transaction.unitOfMeasureId)
            : [];
        unitOfMeasureDispValue = unitOfMeasure ? unitOfMeasure.name : '';
      }
      let serviceCharge = 0,
        gratuity = 0;
      if (this._retailService.settleOpenTransaction) {
        this._retailService.TaxValue += transaction.tax;
      }
      if (transaction.serviceChargeGratuity && transaction.serviceChargeGratuity.length > 0) {
        let gratuityObj = transaction.serviceChargeGratuity.find((x) => x.totalGratuity != 0);
        let serviceChargeObj = transaction.serviceChargeGratuity.find((x) => x.totalServiceCharge != 0);
        gratuity = gratuityObj ? gratuityObj.gratuity : 0;
        serviceCharge = serviceChargeObj ? serviceChargeObj.serviceCharge : 0;
      }
      let newGratuity: Gratuity[] = [];
      let newServiceCharge: ServiceCharge[] = [];
      if (transaction.serviceChargeGratuity && transaction.serviceChargeGratuity.length > 0) {
        let gratuityRows = transaction.serviceChargeGratuity.filter(a => a.totalGratuity > 0);
        for (let grat of gratuityRows) {
          newGratuity.push({
            Id: this.GetCustomStaffId(grat),
            TransactionDetailId: grat.transactionDetailId,
            TherapistId: grat.therapistId,
            Percentage: grat.gratuityPercent,
            PercentageId: 0,
            PercOrAmount: grat.gratuityPercent > 0 ? 1 : 2,
            Amount: grat.gratuity,
            gratuity: grat.gratuity,
            StaffType: grat.staffType,
            GratuityTax: grat.gratuityTax,
            additionalGratuity: grat.additionalGratuity,
            customAmount: grat.additionalGratuity,
            customPercentage: grat.additionalGratuityPercent,
            customPercOrAmount: grat.additionalGratuityPercent > 0 ? 1 : 2
          });
        }

        let scRows = transaction.serviceChargeGratuity.filter(a => a.totalServiceCharge > 0);
        for (let sc of scRows) {
          newServiceCharge.push({
            Id: this.GetCustomStaffId(sc),
            TransactionDetailId: sc.transactionDetailId,
            TherapistId: sc.therapistId,
            Percentage: sc.serviceChargePercent,
            PercentageId: 0,
            PercOrAmount: sc.serviceChargePercent > 0 ? 1 : 2,
            Amount: sc.serviceCharge,
            ServiceCharge: sc.serviceCharge,
            StaffType: sc.staffType,
            ServiceChargeTax: sc.serviceChargeTax,
            additionalServiceCharge: sc.additionalServiceCharge,
            customAmount: sc.additionalServiceCharge,
            customPercentage: sc.additionalServiceChargePercent,
            customPercOrAmount: sc.additionalServiceChargePercent > 0 ? 1 : 2
          });
        }

      }
      this._retailService.selectedProducts.push({
        playerName: transaction.playerName ? transaction.playerName : '',
        ItemDescription: transaction.customFeeId > 0 ? transaction.itemDescription : 
          transaction.clientMultiPackRedeemId > 0
            ? this.localization.captions.shop.RedeemingMultipack + item[0].retailItemDetail.itemDescription
            : item[0].retailItemDetail.itemDescription,
        ProductName: transaction.customFeeId > 0 ? transaction.itemDescription : 
          transaction.clientMultiPackRedeemId > 0
            ? this.localization.captions.shop.RedeemingMultipack + item[0].retailItemDetail.itemDescription
            : item[0].retailItemDetail.itemDescription,
        ServiceId: transaction.serviceId,
        ProductPrice: this.localization.currencyToSQLFormat(transaction.unitPrice),
        SalesPrice: this.localization.currencyToSQLFormat(transaction.totalAmount),
        ExternalPOSItemId: transaction.externalPOSId,
        ItemId: transaction.itemId,
        Noofitems: transaction.quantitySold,
        Discount: excludeDiscount ? 0 : transaction.discount,
        DiscountPercentage: excludeDiscount ? 0 : transaction.discountPercentage,
        DiscountTypeId: excludeDiscount ? 0 : transaction.discountTypeId,
        isEditDisabled: action == 'settle',
        isModificationRestricted: action == 'settle',
        Commission: this.SetCustomStaffIdForCommission(transaction.commission),
        isCommissionable: item[0].retailItemDetail.isCommissionable,
        Gratuity: newGratuity,
        ServiceCharge: newServiceCharge,
        isGroupingKey: item[0].retailItemDetail.isGroupingKey,
        isPackagedItem: transaction.packageItemId > 0 ? true : false,
        PackageItemId: transaction.packageItemId,
        MultiPack: transaction.clientMultiPackRedeemId > 0 ? true : false,
        ClientMultiPackId: 0,
        PackageGroupId: this.PackageGroupId + 1,
        LineNumber: transaction.lineNumber,
        Tax: transaction.tax,
        LinkedTax: transaction.linkedTax,
        BaseTax: transaction.baseTax,
        isOpenPricedItem: true, //Open Priced Item should always be true as the service may have different price than original price
        id: transaction.id,
        transactionDetailLinkId: transaction.transactionDetailLinkId ? transaction.transactionDetailLinkId : 0,
        isReturn: transaction.isReturn ? transaction.isReturn : false,
        category: item[0].retailItemDetail.category,
        isTaxExempt: transaction.isTaxExempt,
        retailItemType: item[0].retailItemDetail.itemType,
        scaledUnits: transaction.scaledUnit,
        unitOfMeasureId: transaction.unitOfMeasureId,
        uom: unitOfMeasureDispValue,
        costPrice: transactionData.costPrice,
        marginPercentage: transactionData.marginPercentage,
        allowEarn: item[0].retailItemDetail.allowEarn,
        discountComments: transactionData.discountComments,
        discountReason: transactionData.discountReason,
        Vat:transaction.vat,
        netUnitPrice: transaction.netUnitPrice,
        netPrice: transaction.netPrice,
        netUnitPriceWithoutDiscount: transaction.netUnitPriceWithoutDiscount,
        sourceType: transaction.sourceType,
        sourceTypeId: transaction.sourceTypeId,
        ItemType: item[0].retailItemDetail.itemType,
        customFee: transaction.transactionCustomFee,
        linkedItemLineNumber: transaction.linkedCustomFeeTransactionDetailId > 0 ? transactionData
        .find(x=>x.id == transaction.linkedCustomFeeTransactionDetailId)?.lineNumber : 0,      
      });
    }
  }

  private SetServiceChargeGratuityValue(serviceChargeGratuity: any[], newObject: any, type: string) {
    if (serviceChargeGratuity && serviceChargeGratuity.length > 0) {
      switch (type) {
        case 'SERVICECHARGE': {
          var sc = serviceChargeGratuity.find((r) => r.serviceCharge != 0);
          if (sc) {
            let percentageId = this.GetPercentageId(sc.serviceChargePercent);
            if (percentageId > 0) {
              newObject.PercentageId = percentageId;
              newObject.Percentage = sc.serviceChargePercent;
            } else if (sc.serviceChargePercent > 0) {
              newObject.PercOrAmount = GlobalConst.ServiceChargeGratuityValueType.CustomPercent;
              newObject.Amount = sc.serviceChargePercent;
            } else {
              newObject.PercOrAmount = GlobalConst.ServiceChargeGratuityValueType.CustomAmount;
              newObject.Amount = sc.serviceCharge;
            }
          }
          break;
        }
        case 'GRATUITY': {
          var sc = serviceChargeGratuity.find((r) => r.gratuity != 0);
          if (sc) {
            let percentageId = this.GetPercentageId(sc.gratuityPercent);
            if (percentageId > 0) {
              newObject.PercentageId = percentageId;
              newObject.Percentage = sc.gratuityPercent;
            } else if (sc.gratuityPercent > 0) {
              newObject.PercOrAmount = GlobalConst.ServiceChargeGratuityValueType.CustomPercent;
              newObject.Amount = sc.gratuityPercent;
            } else {
              newObject.PercOrAmount = GlobalConst.ServiceChargeGratuityValueType.CustomAmount;
              newObject.Amount = sc.gratuity;
            }
          }
          break;
        }
      }
    }
  }

  GetPercentageId(percent: number): number {
    let percentageId = 0;
    if (percent == 10) {
      percentageId = GlobalConst.ServiceChargeGratuityPercentId.TenPercent;
    } else if (percent == 15) {
      percentageId = GlobalConst.ServiceChargeGratuityPercentId.FifteenPercent;
    } else if (percent == 20) {
      percentageId = GlobalConst.ServiceChargeGratuityPercentId.TwentyPercent;
    } else if (percent == 25) {
      percentageId = GlobalConst.ServiceChargeGratuityPercentId.TwentyFivePercent;
    }
    return percentageId;
  }

  async getSelectedProducts(selectedPlayers: ScheduledTeeTimeUnPaidPlayer[],customFeeLinkedItemIds = []): Promise<SelectedProducts[]> {
    const selectedProducts: SelectedProducts[] = [];
    let isDepositPresentForPlayer: boolean = false;
    let customFeeRetailItems = [];
    let customFeeItemDetails;
   customFeeRetailItems = []
   if(customFeeLinkedItemIds?.length > 0)
    customFeeItemDetails = await this._commonVariable.getRetailItemsDetailedInfoByIds(customFeeLinkedItemIds); 
    if (selectedPlayers) {
      let lineNumber = this._retailService.selectedProducts.length;
      let retailPackagedItems = [];
      let retailItems = [];
      let discountConfigModal = [];
      const items = await this._paymentBusinessService.GetRetailItemByItemType(GlobalConst.DepositRetailItemType);
      const groupedItems = this.getPackageItemIds(selectedPlayers);
      if (groupedItems?.length > 0) {
        retailPackagedItems = await this._paymentBusinessService.GetRetailItemsDetailedInfoByIds(groupedItems);
        const packageItems = retailPackagedItems.map(x => x.packagedItem);
        const retailItemIds = [].concat(...packageItems).map(x => x.parentItemId);
        if (retailItemIds?.length > 0) {
          retailItems = await this._paymentBusinessService.GetRetailItemsByIds(_.uniq(retailItemIds));
        }
      }
      let members = selectedPlayers.filter(x => x.playerCategoryId == playerTypes.member && (x.discountType != null && x.discountType != undefined && x.discountType));
      if(members.length > 0) {
        for(let member of members){
          if(member.greenFeeRetailItemId !=0 && discountConfigModal?.filter(x => x.date == member.scheduledAPIDateTime && x.type == member.discountType && x.retailId == member.greenFeeRetailItemId)?.length == 0){
            let greenFeeConfig = await this._paymentBusinessService.GetDiscountConfigurations(member.scheduledAPIDateTime, member.greenFeeRetailItemId, member.discountType);
            discountConfigModal.push({date: member.scheduledAPIDateTime, type: member.discountType, retailId: member.greenFeeRetailItemId, config: greenFeeConfig});
          }
          if(member.cartFeeRetailItemId !=0 && discountConfigModal?.filter(x => x.date == member.scheduledAPIDateTime && x.type == member.discountType && x.retailId == member.cartFeeRetailItemId)?.length == 0){
            let cartFeeConfig = await this._paymentBusinessService.GetDiscountConfigurations(member.scheduledAPIDateTime, member.cartFeeRetailItemId, member.discountType);
            discountConfigModal.push({date: member.scheduledAPIDateTime, type: member.discountType, retailId: member.cartFeeRetailItemId, config: cartFeeConfig});
          }
        }
      }
      const RoundOff2 = (val: number) => Number(val.customToFixed());
      selectedPlayers.map((player) => {        
        const isMember = player.playerCategoryId == 3;
        if (player.greenFeeRetailItem && Object.keys(player.greenFeeRetailItem).length != 0) {
          const greenFeeRetailItem: any = player.greenFeeRetailItem;
          const price = player.greenFee;          
          if (greenFeeRetailItem.isGroupingKey) {
            const retailItemData = retailPackagedItems.find(x => x.id === greenFeeRetailItem.id);
            const isPriceNegotiated = player.greenFee !== retailItemData?.retailItemDetail?.salesPrice;
            const groupingUniqueIdentifier = this._utils.generateGUID();
            const packagedItems = retailItemData?.packagedItem;
            const groupingId = Number(greenFeeRetailItem.id);
            const groupingItemDescription = greenFeeRetailItem.itemDescription;
            let itemPrice = 0; let length = 0;
            for (const packagedItem of packagedItems) {
              lineNumber++; length++;
              const retailItem = retailItems.find(x => x.id === packagedItem.parentItemId);
              let packageItemPrice = isPriceNegotiated ?
                this.getPackageItemPrice(
                  this.getPrice(retailItemData?.retailItemDetail?.memberPrice, retailItemData?.retailItemDetail?.salesPrice, isMember),
                  price,
                  this.getPrice(packagedItem.memberPrice, packagedItem.price, isMember)) :
                packagedItem.price;
              if (length === packagedItems.length && isPriceNegotiated) {
                packageItemPrice = RoundOff2((Number(player.greenFee) - Number(itemPrice)));
              }
              else {
                itemPrice = RoundOff2(itemPrice + Number(packageItemPrice));
              }
              let selectedProduct = this.FormProductObj(player, retailItem, Number(packageItemPrice), lineNumber, false, true, groupingId, groupingItemDescription, groupingUniqueIdentifier);
              selectedProduct.DiscountTypeId = discountConfigModal?.find(x => x.type == player.discountType &&  x.retailId == player.greenFeeRetailItemId)?.config?.discountTypeId ?? 0;
              selectedProduct.DiscountPercentage = discountConfigModal?.find(x => x.type == player.discountType &&  x.retailId == player.greenFeeRetailItemId)?.config?.discountValue ?? 0;          
              selectedProduct.sourceType = GlobalConst.CustomFeeSourceType.RetailItem; // added to avoid mistake on calculation of custom fee when linked item is added for packaged items
              selectedProducts.push(selectedProduct);
            }
          }
          else {
            lineNumber++;            
            let selectedProduct = this.FormProductObj(player, greenFeeRetailItem, price, lineNumber, false, true);
            selectedProduct.DiscountTypeId = discountConfigModal?.find(x => x.type == player.discountType && x.retailId == player.greenFeeRetailItemId)?.config?.discountTypeId ?? 0;
            selectedProduct.DiscountPercentage = discountConfigModal?.find(x => x.type == player.discountType &&  x.retailId == player.greenFeeRetailItemId)?.config?.discountValue ?? 0;     
            selectedProducts.push(selectedProduct);
          }
          player.greenFeeRetailItem.lineNumber = lineNumber;
        }
        if (player.cartFeeRetailItem && Object.keys(player.cartFeeRetailItem).length != 0 && player.walk === "No" && player.trail === "No") {
          const cartFeeRetailItem: any = player.cartFeeRetailItem;
          const price = player.cartFee;
          if (cartFeeRetailItem.isGroupingKey) {
            const retailItemData = retailPackagedItems.find(x => x.id === cartFeeRetailItem.id);
            const isPriceNegotiated = player.cartFee !== retailPackagedItems.find(x => x.id === cartFeeRetailItem.id).retailItemDetail.salesPrice;
            const groupingId = Number(cartFeeRetailItem.id);
            const groupingItemDescription = cartFeeRetailItem.itemDescription;
            const groupingUniqueIdentifier = this._utils.generateGUID();
            const packagedItems = retailPackagedItems.find(x => x.id === cartFeeRetailItem.id).packagedItem;
            let itemPrice = 0; let length = 0;
            for (const packagedItem of packagedItems) {
              lineNumber++; length++;
              const retailItem = retailItems.find(x => x.id === packagedItem.parentItemId);
              let packageItemPrice = isPriceNegotiated ?
                this.getPackageItemPrice(
                  this.getPrice(retailItemData?.retailItemDetail?.memberPrice, retailItemData?.retailItemDetail?.salesPrice, isMember),
                  price,
                  this.getPrice(packagedItem.memberPrice, packagedItem.price, isMember)) :
                packagedItem.price;
              if (length === packagedItems.length && isPriceNegotiated) {
                packageItemPrice = RoundOff2(Number(player.cartFee) - Number(itemPrice));
              }
              else {
                itemPrice = RoundOff2(itemPrice + Number(packageItemPrice));
              }
              let selectedProduct = this.FormProductObj(player, retailItem, Number(packageItemPrice), lineNumber, true, true, groupingId, groupingItemDescription, groupingUniqueIdentifier);
              selectedProduct.DiscountTypeId = discountConfigModal?.find(x => x.type == player.discountType &&  x.retailId == player.cartFeeRetailItemId)?.config?.discountTypeId ?? 0;
              selectedProduct.DiscountPercentage = discountConfigModal?.find(x => x.type == player.discountType &&  x.retailId == player.cartFeeRetailItemId)?.config?.discountValue ?? 0;
              selectedProducts.push(selectedProduct);
            }
          }
          else {
            lineNumber++;
            let selectedProduct = this.FormProductObj(player, cartFeeRetailItem, price, lineNumber, true, true);
            selectedProduct.DiscountTypeId = discountConfigModal?.find(x => x.type == player.discountType &&  x.retailId == player.cartFeeRetailItemId)?.config?.discountTypeId ?? 0;
            selectedProduct.DiscountPercentage = discountConfigModal?.find(x => x.type == player.discountType &&  x.retailId == player.cartFeeRetailItemId)?.config?.discountValue ?? 0;           
            selectedProducts.push(selectedProduct);
          }
        }
        const depositItem = items.filter(
          (o) => o.retailItemDetail.itemType == GlobalConst.DepositRetailItemType && o.outletItem.some(y => y.outletId == this._retailService.SelectedOutletId)
        );
        if (player.deposits.length > 0 && depositItem && depositItem.length > 0) {
          let depositretailDetail = depositItem[0].retailItemDetail;
          isDepositPresentForPlayer = true;
          player.deposits.forEach((deposit) => {
            if (!deposit.isVoided) {
              lineNumber++;
              let price = -(deposit.amount+deposit.refundAmount);
              selectedProducts.push(this.FormProductObj(player, depositretailDetail, price, lineNumber, false, false));
            }
          });
        }
        if (player.entryFeeRetailItem && Object.keys(player.entryFeeRetailItem).length != 0) {
          const entryFeeRetailItem: any = player.entryFeeRetailItem;
          const price = player.entryFee;
          if (entryFeeRetailItem.isGroupingKey) {
            const retailItemData = retailPackagedItems.find(x => x.id === entryFeeRetailItem.id);
            const isPriceNegotiated = player.entryFee !== retailPackagedItems.find(x => x.id === entryFeeRetailItem.id).retailItemDetail.salesPrice;
            const groupingId = Number(entryFeeRetailItem.id);
            const groupingItemDescription = entryFeeRetailItem.itemDescription;
            const groupingUniqueIdentifier = this._utils.generateGUID();
            const packagedItems = retailPackagedItems.find(x => x.id === entryFeeRetailItem.id).packagedItem;
            let itemPrice = 0; let length = 0;
            for (const packagedItem of packagedItems) {
              lineNumber++; length++;
              const retailItem = retailItems.find(x => x.id === packagedItem.parentItemId);
              let packageItemPrice = isPriceNegotiated ?
                this.getPackageItemPrice(
                  this.getPrice(retailItemData?.retailItemDetail?.memberPrice, retailItemData?.retailItemDetail?.salesPrice, isMember),
                  price,
                  this.getPrice(packagedItem.memberPrice, packagedItem.price, isMember)) :
                packagedItem.price;
              if (length === packagedItems.length && isPriceNegotiated) {
                packageItemPrice = RoundOff2(Number(player.entryFee) - Number(itemPrice));
              }
              else {
                itemPrice = RoundOff2(itemPrice + Number(packageItemPrice));
              }
              selectedProducts.push(this.FormProductObj(player, retailItem, Number(packageItemPrice), lineNumber, false, true, groupingId, groupingItemDescription, groupingUniqueIdentifier));
            }
          }
          else {
            lineNumber++;
            selectedProducts.push(this.FormProductObj(player, entryFeeRetailItem, price, lineNumber, false, false));
          }
        }
        if (player.otherItems.length > 0) {
          player.otherItems.forEach((packageItem) => {
            if (packageItem) {
              lineNumber++;
              let tournamentItem: any = packageItem.retailItemDetail;
              let price = packageItem.price;
              selectedProducts.push(this.FormProductObj(player, tournamentItem, price, lineNumber, false, false));
            }
          });
        }
        if (player.multiPackRetailItem?.length > 0) {
          player.multiPackRetailItem.forEach((item: any) => {
            if (item) {
              lineNumber++;
              let multiPackRetailItem: any = item;
              let price = item.salesPrice;
              selectedProducts.push(this.FormProductObj(player, multiPackRetailItem, price, lineNumber, false, false));
            }
          });
        }
        if(player.scheduledTeeTimePlayerCustomFees?.length > 0)
        {
          customFeeRetailItems = this._teeTimeCustomFeeUtiles.FormCustomFeeObj(player,lineNumber,customFeeItemDetails,selectedProducts,isDepositPresentForPlayer);
          selectedProducts.push(...customFeeRetailItems);
        }
      });
    }

    return selectedProducts;
  }

  getPrice(memberPrice: number, salesPrice: number, isMember: boolean) {
    return isMember ? memberPrice : salesPrice;
  }

  getPackageItemPrice(groupItemPrice: number, negotiatedPrice: number, packagedItemPrice: number) {
    const weightedPrice = groupItemPrice > 0 ? packagedItemPrice / groupItemPrice : 0;
    return Number((negotiatedPrice * weightedPrice).customToFixed());
  }

  getPackageItemIds(selectedPlayers) {
    const groupedItems = [...selectedPlayers.filter(x => x.cartFeeRetailItem?.isGroupingKey).map(x => x?.cartFeeRetailItem?.id)]
      .concat([...selectedPlayers.filter(x => x.greenFeeRetailItem?.isGroupingKey).map(x => x?.greenFeeRetailItem?.id)])
      .concat([...selectedPlayers.filter(x => x.entryFeeRetailItem?.isGroupingKey).map(x => x?.entryFeeRetailItem?.id)]);
    return _.uniq(groupedItems);
  }

  /**
   * @description Returns an object matching the SelectedProduct interface with the values from params
   * @param player
   * @param retailItem
   * @param price
   * @param lineNumber
   */
  FormProductObj(player: ScheduledTeeTimeUnPaidPlayer, retailItem, price: number, lineNumber: number, isCartRetailItem?: boolean, IsIncludedInRound?: boolean,
    groupingId?: number, groupingItemDescription?: string, groupingUniqueIdentifier?: string) {
    return {
      id: player.playerId,
      payeeId: player.playerId,
      playerName: player.playerName,
      ItemId: retailItem.id,
      ItemType: retailItem.itemType,
      ExternalPOSItemId: retailItem.externalPOSId,
      ItemDescription: retailItem.itemDescription,
      ProductName: retailItem.itemDescription,
      ServiceId: 0,
      ProductPrice: price,
      SalesPrice: price,
      Noofitems: 1,
      Discount: 0,
      DiscountPercentage: 0,
      DiscountTypeId: 0,
      isEditDisabled: true,
      isDeleteDisabled: true,
      isGroupingKey: retailItem.isGroupingKey,
      isPackagedItem: retailItem.isPackagedItem,
      PackageItemId: 0,
      MultiPack: retailItem.isMultiPack,
      ClientMultiPackId: 0,
      PackageGroupId: 0,
      isOpenPricedItem: retailItem.isOpenItem,
      category: retailItem.category,
      LineNumber: lineNumber,
      isModificationRestricted: isCartRetailItem ? false : true,
      isCommissionable: retailItem.isCommissionable,
      isIncludedInRound: IsIncludedInRound ? true : false,
      costPrice: retailItem.costPrice,
      marginPercentage: retailItem.marginPercentage,
      isRequireComments: retailItem.isRequireComments,
      itemComments: "",
      isRequestName: retailItem.isRequestName,
      allowEarn: retailItem.allowEarn,
      discountComments: '',
      discountReason: 0,
      GroupingParentId: groupingId,
      GroupingUniqueIdentifier: groupingUniqueIdentifier,
      GroupingItemDescription: groupingItemDescription,
      sourceTypeId: player.playerId,
      sourceType: GlobalConst.CustomFeeSourceType.TeeTime,
      Gratuity: retailItem.gratuity ? [{
        Id: '',
        TransactionDetailId: 0,
        TherapistId: 0,
        Amount: retailItem.gratuity,
        PercOrAmount: 2,
        Percentage: 0,
        PercentageId: 0,
        gratuity: retailItem.gratuity,
      }] : [],
      ServiceCharge: retailItem.serviceCharge ? [{
        Id: '',
        TransactionDetailId: 0,
        TherapistId: 0,
        Amount: retailItem.serviceCharge,
        PercOrAmount: 2,
        Percentage: 0,
        PercentageId: 0,
        ServiceCharge: retailItem.serviceCharge,
      }] : [],
      multiPackTransactionDetailId: retailItem.isMultiPack ? player.multiPackTransactionDetailId : 0
    };
  }

  GetRetailItemsFromPlayers(SelectedPlayers) {
    let golfItems = [];
    if (SelectedPlayers) {
      SelectedPlayers.map((s) => {
        if (s.greenFeeRetailItem) {
          golfItems.push(s.greenFeeRetailItem);
        }
        if (s.cartFeeRetailItem) {
          golfItems.push(s.cartFeeRetailItem);
        }
        if (s.entryFeeRetailItem) {
          golfItems.push(s.entryFeeRetailItem);
        }
        if (s.tournamentId > 0 && s.otherItems.length > 0) {
          s.otherItems.map((item) => {
            if (item.retailItemDetail) {
              golfItems.push(item.retailItemDetail);
            }
          });
        }
        if (s.multiPackRetailItem && s.multiPackRetailItem.length > 0) {
          golfItems.push(...s.multiPackRetailItem);
        }
      });
    }
    return golfItems;
  }

  /**
   * @description This method will trigger an API which retrieves the players transaction details with PlayerNames
   * @param transactionId
   */
  async GetPlayerNamesByTransactionId(transactionId: Number): Promise<TransactionDetailAggregate> {
    return await this._golfSchedule.getPromise<TransactionDetailAggregate>({
      route: GolfApiRoute.GetPlayerNamesByTransactionId,
      uriParams: { transactionId: transactionId }
    });
  }

  /**
  * @description This method will trigger an API which creates the player
  * @param clientobject
  */
  async createClient(clientobj): Promise<any> {
    return await this._golfSchedule.postPromise({
      route: GolfApiRoute.CreatePlayer,
      body: clientobj
    });
  }
  async GetGuestByPlatformGuestGuid(platformGuestGuid: string): Promise<PayeeInfo> {
    let playerProfile = await this.GetPlayerbyPlatformGuestGuid(platformGuestGuid);
    return this.MapToPayeeInfo(playerProfile);
  }
  async GetPlayerbyPlatformGuestGuid(platformGuestGuid: string): Promise<API.Player> {
    return this._golfSchedule.getPromise({
      route: GolfApiRoute.GetPlayerbyPlatformGuid,
      uriParams: { platformGuid: platformGuestGuid }
    });
  }
  /**
   * @description This method will get the configuration for rental agreements
   */
  async getAgreementConfig(transactionId?: number): Promise<PrintRentalAgreement[]> {
    let agreementConfig: PrintRentalAgreement[] = [];
    let result = await this._userAccessBusiness.getUserAccess(
      UserAccessBreakPoints.PRINTINVENTRYITEMAGRREMENTS,
      false
    );
    if (result.isAllow) {
      let allProducts = [];
      if (transactionId == 0 && this._retailService.SelectedPlayers.length > 0) {
        this._retailService.SelectedPlayers.forEach((s) => allProducts.push(s.greenFeeRetailItem));
        this._retailService.SelectedPlayers.forEach((s) => allProducts.push(s.cartFeeRetailItem));
      } else if (transactionId > 0) {
        let transactionResult = await this._paymentBusinessService.GetTransactionDetails(
          transactionId,
          GlobalConst.Product.GOLF
        );
        if (transactionResult) {
          let retailItemIds: number[] = transactionResult.map((s) => s.itemId);
          var uniqueRetailItemId: number[] = [...new Set(retailItemIds)];
          allProducts = await this._paymentBusinessService.GetRetailItemsByIds(uniqueRetailItemId);
        }
      }
      const cartAgreementRequired = allProducts.some((p) => p.isPrintCartAgreement);
      const clubAgreementRequired = allProducts.some((p) => p.isPrintClubAgreement);
      const shoeAgreementRequired = allProducts.some((p) => p.isPrintShoeAgreement);
      if (cartAgreementRequired) {
        agreementConfig.push({ RentalAgreement: RentalAgreement.CartAgreement });
      }
      if (clubAgreementRequired) {
        agreementConfig.push({ RentalAgreement: RentalAgreement.ClubAgreement });
      }
      if (shoeAgreementRequired) {
        agreementConfig.push({ RentalAgreement: RentalAgreement.ShoeAgreement });
      }
    }
    //Feature 57221: Cart, Shoe, Club Agreement ,Retail sale Chit , Retail sale chit Not Implemented

    // 	allProducts.forEach(r=>
    // 		{
    // 			if(r.isPrintCartAgreement)
    // 			{
    // 				agreementConfig.push({
    // 					RentalAgreement: RentalAgreement.CartAgreement,
    // 					RetailItemId : r.id
    // 				});
    // 			}
    // 			if(r.isPrintClubAgreement)
    // 			{
    // 				agreementConfig.push({
    // 					RentalAgreement: RentalAgreement.ClubAgreement,
    // 					RetailItemId : r.id
    // 				});
    // 			}
    // 			if(r.isPrintShoeAgreement)
    // 			{
    // 				agreementConfig.push({
    // 					RentalAgreement: RentalAgreement.ShoeAgreement,
    // 					RetailItemId : r.id
    // 				});
    // 			}					
    // 		})
    // }
    return agreementConfig;
  }
  async printAgreements(transactionId?: number, playerId?: number[]) {
    let config = await this.getAgreementConfig(transactionId);
    if (config.length > 0) {
      config.forEach((c) => this._agreementBusiness.printRentalAgreement(c));
    }
  }
  async printCaddyShack(transactionId: number) {
    let allProducts = [];
    if (transactionId > 0) {
      let transactionResult = await this._paymentBusinessService.GetTransactionDetails(
        transactionId,
        GlobalConst.Product.GOLF
      );
      if (transactionResult) {
        let retailItemIds: number[] = transactionResult.map((s) => s.itemId);
        var uniqueRetailItemId: number[] = [...new Set(retailItemIds)];
        allProducts = await this._paymentBusinessService.GetRetailItemsByIds(uniqueRetailItemId);
      }
    }
    return allProducts;
  }
  SetP1PlayerId() {
    let firstPlayer = _.orderBy(this._retailService.SelectedPlayers, 'playerSlotPosition', 'asc');
    let memberIndex = firstPlayer.findIndex((member) => member.playerCategoryId === 3); //For finding member index
    this._retailService.selectedSlotFirstPlayerId =
      memberIndex != -1 ? firstPlayer[memberIndex].playerId
        : this._retailService.payeeId ? this._retailService.payeeId  // retaining prev payeeID for continue booking flow
          : firstPlayer[0].playerId;
    return this._retailService.selectedSlotFirstPlayerId;
  }

  ResetCourseTime() {
    this._retailService.SelectedDate = '';
    this._retailService.SeletedCourseId = 0;
  }

  async GetTransactionPlayerDetails(transactionId: number): Promise<TransactionDetailAggregate> {
    return await this.GetPlayerNamesByTransactionId(transactionId);
  }

  async printTeeTickets(courseIds: number[], scheduleTeeTimeIds: number[], playerIds: number[], dateTime: string) {
    return await this._teeTicketBusiness.printTeeTicketsOnPlayersPaid(
      courseIds,
      scheduleTeeTimeIds,
      playerIds,
      dateTime
    );
  }

  GetDefaultOutlet() {
    return this.userDefaultService.GetDefaultOutlet();
  }

  ShowConfirmation(errorMessage, callback) {
    return this._unPaidPlayerBusiness.ShowConfirmation(errorMessage, callback);
  }

  async createPlayer(playerobj, callback) {
    const playerData = await this.createClient(playerobj);
    callback(playerData);
  }

  //Added
  async getExistingPlayer(patronId) {
    let result: UserAccessModel.BreakPointResult
    result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.PLAYERPROFILE, true);
    if (result.isAllow || result.isViewOnly) {
      return this._playerProfileDataService.getExistingPlayerUsingPatronId(patronId);
    }
    else {
      return 1;
    }
  }

  async getExistingPlayerByGuestId(guestId: string): Promise<PayeeInfo> {
    let playerDetail: API.Player = await this._playerDataService.GetPlayerByPlayerLinkId(guestId);
    return this.MapToPayeeInfo(playerDetail);
  }

  async openGuestPatronPopup(e, callBack: Function, id?, guestId?) {
    if (e.toLowerCase() == "ordersummary") {
      this.createPlayers(this.localization.captions.guest.players.newPlayers, id);

    }
  }
  createPlayers(popUpTitle, id?) {
    this.componentDetails = {
      componentName: CreatePlayerComponent,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent',
        cmsPatronId: id
      }
    };
    const dialogRef = this.dialog.open(CommonPopupComponent, {
      maxWidth: '90vw',
      width: '90%',
      height: '90%',
      disableClose: true,
      data: {
        title: popUpTitle,
        update: popUpTitle === 'EDIT PLAYER' ? this.localization.captions.settings.editPopSave : this.localization.captions.settings.createPopSave,
        cancel: this.captions.cancel,
        componentDetails: this.componentDetails,
        actionType: popUpTitle === 'EDIT PLAYER' ? ButtonAction.update : ButtonAction.save
      }
    });
  }

  updateGuestProfile(guestId: string, playerId: number) {
    return this.updateGuest(guestId, playerId);
  }

  async updateGuest(guestId: string, playerId: number): Promise<any> {
    return await this._golfSchedule.putPromise({
      route: GolfApiRoute.UpdateGuestRequest,
      uriParams: { guestId: guestId, playerId: playerId }
    });
  }

  async getRainCheckRedeemIds(outletId: number) {
    let negotiatedFee: NegotiatedFee = await this._negotiatedFeeDataService.GetNegotiatedFee(outletId);
    if (negotiatedFee) {
      return { greenFeeItem: negotiatedFee.rainCheckGreenFeeRetailItemId, cartFeeItem: negotiatedFee.rainCheckCartFeeRetailItemId };
    }
    return null;
  }

  async ARPost(acesPayment: ACESPayment): Promise<any> {
    return this._acespaymentservice.ARPost(acesPayment);
  }

  async RedeemPoint(acesPayment: any): Promise<any> {
    return this._acespaymentservice.RedeemPoint(acesPayment);
  }

  async CreatePaymentRecord(paymentRecord: ACESPaymentRecord): Promise<ACESPaymentRecord> {
    return this._acespaymentservice.CreatePaymentRecord(paymentRecord);
  }

  async GetPaymentRecordByTransactionId(transactionId: string): Promise<ACESPaymentRecord[]> {
    return this._acespaymentservice.GetPaymentRecordsByTransactionId(transactionId);
  }

  async GetAllPaymentRecords(): Promise<ACESPaymentRecord[]> {
    return this._acespaymentservice.GetAllPaymentRecords();
  }

  async MemberorderSummary(paymentRecord: ACESPayment): Promise<any> {
    return this._acespaymentservice.MemberSummary(paymentRecord);
  }

  ACESRefund(transactionId: string, pin: string): Promise<any> {
    return this._acespaymentservice.ACESRefund(transactionId, pin);
  }

  async GetPlayers(courseId: number, date: string): Promise<ScheduledTeeTimeUnPaidPlayer[]> {
    return await this._unPaidPlayerBusiness.getPlayers(
      courseId,
      date,
      PlayerPaymentstatus.paid + PlayerPaymentstatus.unPaid
    );
  }

  GetChildMenu(url, menutype?) {
    return this._routeLoaderService.GetChildMenu(url, menutype);
  }

  async GetAllRoomsForGuest(clientId) {
    return await this._golfSchedule.getPromise<any[]>({
      route: GolfApiRoute.GetGuestStayDetails,
      uriParams: { clientId: clientId }
    });
  }

  async GetSettings(): Promise<TeeTimeConfig> {
    const result = await this.settingsDataService.getSettings<setting.TeeTimeConfig>(SettingModule.SystemSetup, SettingScreen.TeeTime);
    return Promise.resolve(this.mapToUI(result.configValue));
  }

  private mapToUI(apiData: setting.TeeTimeConfig): TeeTimeConfig {
    return {
      rainCheckNote: apiData.rainCheckNote,
      ticketNote: apiData.ticketNote,
      requirePlayerType: apiData.requirePlayerType,
      requireRateType: apiData.requireRateType,
      showMemberSignOnTeeGrid: apiData.showMemberSignOnTeeGrid,
      defaultToGraphicalTeeSheet: apiData.defaultToGraphicalTeeSheet,
      promptUserToPrintTeeTickets: apiData.promptUserToPrintTeeTickets,
      autoPrintTeeTickets: apiData.autoPrintTeeTickets,
      requireTeeTimesToPaid: apiData.requireTeeTimesToPaid,
      setPaidOnDayOfPlay: apiData.setPaidOnDayOfPlay,
      requireCheckInCheckOut: apiData.requireCheckInCheckOut,
      enableAutoCheck: apiData.enableAutoCheck,
      allowPaymentInCheckIn: apiData.allowPaymentInCheckIn,
      autoCheckInCheckOutZeroCharges: apiData.autoCheckInCheckOutZeroCharges,
    };
  }

  async getDefaultsSetting() {
    return await this.settingsDataService.getDefaultsSetting();

  }

  async getTeeTimeSetting() {
    return await this.settingsDataService.getTeeTimeSetting();

  }

  async GetCourse(courseId: number) {
    return await this._unPaidPlayerBusiness.getCourse(courseId);
  }

  async UnRedeemRainChecks(raincheckIds: number[]): Promise<boolean> {
    return await this._golfSchedule.putPromise({
      route: GolfRoutes.UnRedeemRainCheckByIds,
      body: raincheckIds
    });
  }

  async ValidateSelectedTerminalExistsInSelectedOutlet() {
    if (this.propertyInfo.UseRetailInterface && this._retailService.SelectedOutletId > 0 && this._retailService.SelectedTerminalId > 0) {
      const storeTerminalList = await this._userDataService.GetStoreTerminal(String(this._retailService.SelectedOutletId));
      const userMachineConfig = this._retailUtils.getUserDefaults();
      if (storeTerminalList && storeTerminalList.length > 0 && !storeTerminalList.some(s => s.terminalId == this._retailService.SelectedTerminalId)) {
        this._retailService.SelectedTerminalId = userMachineConfig.defaultTerminalId > 0 && userMachineConfig.defaultTerminalId != this._retailService.SelectedTerminalId
          && storeTerminalList.some(s => s.terminalId == userMachineConfig.defaultTerminalId) ? userMachineConfig.defaultTerminalId : 0;
        this._retailService.isPromptedForTerminalSelect = (Number(this._retailService.SelectedTerminalId) === 0);
      }
    }
  }

  async GetRateType() {
    return await this._rateTypeDataService.getAllRateTypes(false);
  }

  async SendRetailNotification(clientInfo: ClientInfo,emailid:any[],isDistributionListRequired:boolean = true) {
    let bodyObj: NotificationModel;
    bodyObj = {
      transactionId: clientInfo.transactionId,
      action: "RetailReceipts",
      playerIds: clientInfo.playerId,
      reportQuery: clientInfo.reportQuery,
      emailId: emailid,
      isRetailNotification: true,
      isDistributionListRequired: isDistributionListRequired
    }

    return this._golfSchedule.putPromise({
      route: GolfApiRoute.SendRetailNotification,
      body: bodyObj
    });

  }

  async ReleasePlayerTempHold(playerIds: number[]) {
    return await this._golfSchedule.putPromise<any>(
      { route: GolfApiRoute.ReleasePlayerTempHold, body: playerIds });
  }

  
  async ReleaseTempHoldTeeTime(teeTimeTempHold: TeeTimeTempHold) {
    return this._golfSchedule.postPromiseHandleValidation<any>(
          { route: GolfApiRoute.ReleaseTempHoldTeeTime, body: teeTimeTempHold }, false);

  }
  async getClietnMultiPacksBytransactionId(transactionId: number): Promise<ClientMultipack[]> {
    return await this._retailmanagementCommunication.getPromise<ClientMultipack[]>({
      route: GolfApiRoute.GetMultipacksByTransactionId,
      uriParams: { transactionId }
    });
  }
  async updateMultiPack(multipackreturn: MultiPackReturn) {
    const response = await this._retailmanagementCommunication.putPromise<MultiPackReturn>({ route: GolfApiRoute.UpdateMultipack, body: multipackreturn });
    return response;
  }



  async onClickValidateSelectedOutlet(courseId: number, canNavigateToShop: boolean = false, isFromEditPay: boolean = false, isLoader:boolean = false) {
    let hasAccess: boolean = true;
    try{
      let defaultConfig = await this.getDefaultSettings();
      if (defaultConfig.defaultOutletType == DefaultOutletType.course) {
        if(isLoader) this._retailUtils.ToggleLoader(true, this.captions.lbl_processing);
        let course = await this._courseDataService.getCourse(courseId);
        if(isLoader) this._retailUtils.ToggleLoader(true, this.captions.lbl_processing);
        this._retailService.SelectedOutletId = course.defaultOutletId;
        this.ValidateSelectedTerminalExistsInSelectedOutlet();
      } else {
        const defaultConfig = await this.getUserDefaultConfig(this.localization.GetPropertyInfo("UserId"));
        if(isLoader) this._retailUtils.ToggleLoader(true, this.captions.lbl_processing);
        let defaultOutletId = defaultConfig.defaultOutletId > 0 ? defaultConfig.defaultOutletId : this._retailService.SelectedOutletId;
        this._retailService.SelectedOutletId = defaultOutletId;
      }
      this._commonVariable.SelectedOutletId = this._retailService.SelectedOutletId;
      if (this._retailService.SelectedOutletId === 0) {
        canNavigateToShop = true;
      }
      hasAccess = await this.CheckForUserOutletAccessAndDefaultOutlet('', false, canNavigateToShop, isFromEditPay);
      if(isLoader) this._retailUtils.ToggleLoader(true, this.captions.lbl_processing);
    }
    catch(ex){
      this._retailUtils.ToggleLoader(false);
    }
    
    return hasAccess;
  }

  async getUserDefaultConfig(userId: string): Promise<DefaultUserConfiguration> {
    let userDefaultConfig = await this._defaultUserConfig.GetDefaultUserConfiguration(userId);
    if (userDefaultConfig) {
      return userDefaultConfig;
    }
    return undefined;
  }

  public getDefaultSettings(): DefaultsSettingConfig {
    let defaultsApiData: settingsAPI.DefaultsSettingConfig = JSON.parse(sessionStorage.getItem('defaultSettings'));
    return this.mapDefaultData(defaultsApiData);
  }
  private mapDefaultData(configValue: settingsAPI.DefaultsSettingConfig): DefaultsSettingConfig {
    return {
      blockingCode: configValue.blockingCode,
      memberStatus: configValue.memberStatus,
      memberPlayerType: configValue.memberPlayerType,
      memberRateType: configValue.memberRateType,
      nonMemberPlayerType: configValue.nonMemberPlayerType,
      nonMemberRateType: configValue.nonMemberRateType,
      resortPlayerType: configValue.resortPlayerType,
      resortRateType: configValue.resortRateType,
      memberGuestPlayerType: configValue.memberGuestPlayerType,
      memberGuestRateType: configValue.memberGuestRateType,
      memberGuestsToAdd: configValue.memberGuestsToAdd,
      memberGuestPlayerFirstName: configValue.memberGuestPlayerFirstName,
      memberGuestPlayerLastName: configValue.memberGuestPlayerLastName,
      defaultPlayerCategory: configValue.defaultPlayerCategory,
      defaultHotelReservationCategory: configValue.defaultHotelReservationCategory,
      defaultPhoneTypeOption: configValue.defaultPhoneTypeOption,
      defaultEmailTypeOption: configValue.defaultEmailTypeOption,
      defaultCountryCode: configValue.defaultCountryCode,
      defaultOutletType: configValue.defaultOutletType
    }
  }
}
