import {
  TeeSheetSkeletonData,
  TeeSheetGridContent,
  TeeSheetPlayerDetails,
  ScheduleStatus,
  Status,
  Allocation,
  PlayerRateType,
  PlayerSlotGroupInfo,
} from "src/app/shared/models/teesheet.form.models";
import { Guid } from "guid-typescript";
import {
  TeeTimeDetail,
  TeeTimePlayerDetail,
  TeeSheet,
  TeeSheetSlotAPI,
} from "src/app/shared/models/teesheet.api.models";
import { API } from "src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model";
import { Course } from "src/app/settings/golf-setup/code-setup/course/create-course-modal/create-course-model";
import { PlayerPaymentstatus } from "../../search/search-model";
import {
  DEFAULT_TEE_SHEET_SLOT_BACKGROUND_COLOR,
  SettingScreen,
} from "src/app/shared/global.constant";
import { RateType } from "src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal";
import { GolfLocalization } from "src/app/core/localization/golf-localization";
import _ from "lodash";
import { API as setting } from "src/app/settings/system-setup/tee-times/tee-times.modal";
import { DefaultsSettingConfig } from "src/app/shared/models/default-settings.models";
import { PlayerType } from "src/app/settings/golf-setup/code-setup/player-type/player-type.modal";

export class TeeSheetMapper {
  captions: any;
  allAllocations: API.TeeTimeAllocationBlock[];
  private slotGroupInfo: Map<string, PlayerSlotGroupInfo> = new Map<
    string,
    PlayerSlotGroupInfo
  >();
  private defaultSettings: DefaultsSettingConfig;

  constructor(public _localization: GolfLocalization) {
    this.captions = this._localization.captions.teetime;
  }

  public mapToTeeGridSkeleton(
    value: TeeSheet,
    allocations: API.TeeTimeAllocationBlock[],
    courses: Course[],
    cacheNeeded = true
  ): TeeSheetSkeletonData[] {
    this.defaultSettings = JSON.parse(
      sessionStorage.getItem("defaultSettings")
    );
    const _slots: TeeSheetSkeletonData[] = value.slots.map(
      (s: TeeSheetSlotAPI) => {
        const _data: TeeSheetSkeletonData = {
          id: Guid.create().toString(),
          time: s.scheduleDateTime,
          hole: s.holeNumber,
          teeGridSlotId: s.teeGridSlotId,
          allocationDateTime: s.allocationDateTime,
          viewedByStarter: s.viewedByStarter,
          type: s.scheduleType,
          playerDetail: [],
          slotBackgroundInfo: this.assignAllocation(
            allocations,
            s.allocationBlockId
          ),
          status: this.assignStatus(ScheduleStatus.open),
          allocation: this.assignAllocation(allocations, s.allocationBlockId),
          course: {
            id: value.courseId,
            course: this.getCourseName(courses, value.courseId),
          },
          comments: "",
          isDropAllowed: true,
          holes: 0,
          originalHoleNumber: s.originalHoleNumber ? s.originalHoleNumber : "1",
        };
        _data.unmodifiedSlotData = { ..._data };
        return _data;
      }
    );
    if (cacheNeeded) 
      this.cacheAllocationBlocks(allocations);

    return _slots;
  }

  private cacheAllocationBlocks(
    allocations: API.TeeTimeAllocationBlock[]
  ): void {
    this.allAllocations = allocations;
  }

  public getCourseName(courses: Course[], id: number): string {
    const _course = courses.find((c) => c.id === id);
    return _course ? _course.name : "";
  }

  public mapToTeeGridContent(
    apiValue: TeeTimeDetail[],
    allocations: API.TeeTimeAllocationBlock[],
    courses: Course[],
    rateTypes: RateType[],
    playerTypes: PlayerType[]
  ): TeeSheetGridContent[] {
    apiValue = _.sortBy(apiValue, function (dateObj) {
      return [
        new Date(dateObj.createdDateTime),
        new Date(dateObj.scheduleDateTime),
      ];
    });

    this.slotGroupInfo.clear();
    let _formValue: TeeSheetGridContent[] = apiValue.map((o) => {
      return {
        teeTimeId: o.id,
        time: o.scheduleDateTime, // redudant
        hole: o.holeNumber,
        type: "",
        date: o.scheduleDateTime,
        depositAmount: (150).toString(),
        playerDetail: this.mapPlayerDetails(
          o.playerDetail,
          rateTypes,
          playerTypes,
          o.id,
          o.courseId,
          o.scheduleDateTime
        ),
        status: this.assignStatus(o.scheduleStatus),
        course: {
          id: o.courseId,
          course: this.getCourseName(courses, o.courseId),
        },
        playingHole: 0, // redudant
        comments: o.comment,
        createdDateTime: o.createdDateTime,
        userName: o.userName,
        originalHoleNumber: o.originalHoleNumber,
        viewedByStarter: o.viewedByStarter,
      };
    });

    _formValue = _formValue.sort(
      (left, right) =>
        new Date(left.date).getTime() - new Date(right.date).getTime()
    );
    return _formValue;
  }

  /**
   * Merges and remaps Tee Sheet Slots with Tee Times ( Joining Skeleton API call and TeeSheetContent API call).
   * Can have funtionalites to overide skeleton with Tee Time data.
   * @param {TeeSheetSkeletonData[]} slots
   * @param {TeeSheetGridContent[]} teeTimeContent
   * @returns {TeeSheetSkeletonData[]}
   * @memberof TeeSheetMapper
   */
  public mergeTeeSlotsAndTeeTime(
    slots: TeeSheetSkeletonData[],
    teeTimeContent: TeeSheetGridContent[]
  ): TeeSheetSkeletonData[] {
    let mappedSlots: TeeSheetSkeletonData[] = [];

    slots.forEach((slot: TeeSheetSkeletonData) => {
      const teeTimes: TeeSheetGridContent[] = teeTimeContent.filter(
        (t) =>
          t.course.id === slot.course.id &&
          t.originalHoleNumber === slot.originalHoleNumber &&
          t.time === slot.time
      );
      mappedSlots = mappedSlots.concat(
        this.joinSlotAndTeeTimes(slot, teeTimes)
      );
    });
    return mappedSlots;
  }

  /**
   * Right join Tee Slot with Tee Times      *
   * @private
   * @param {TeeSheetSkeletonData} slot
   * @param {TeeSheetGridContent[]} teeTimes
   * @returns {TeeSheetSkeletonData[]}
   * @memberof TeeSheetMapper
   */
  private joinSlotAndTeeTimes(
    slot: TeeSheetSkeletonData,
    teeTimes: TeeSheetGridContent[]
  ): TeeSheetSkeletonData[] {
    let _mappedSlots: TeeSheetSkeletonData[] = [];
    slot.isTeeGridComplete = true;
    teeTimes.forEach((teeTime) => {
      slot = { ...slot };
      slot.id = Guid.create().toString();
      slot.status = this.assignStatus(teeTime.status.scheduleStatus);
      slot.slotBackgroundInfo = this.assignSlotBGColor(slot.status, slot); // overides slot background allocation color with status color
      slot.comments = teeTime.comments;
      slot.scheduledTeeTimeId = teeTime.teeTimeId;
      slot.playerDetail = teeTime.playerDetail;
      slot.holes = teeTime.playerDetail.map((p) => p.holes).max();
      slot.unmodifiedSlotData.playerDetail = teeTime.playerDetail;
      slot.unmodifiedSlotData.status = teeTime.status;
      slot.unmodifiedSlotData.course = teeTime.course;
      slot.createdDateTime = teeTime.createdDateTime;
      slot.userName = teeTime.userName;

      _mappedSlots.push(slot);
    });

    if (teeTimes.length === 0) {
      // if no tee time present, push the slot
      _mappedSlots.push(slot);
    }
    return _mappedSlots;
  }
  private overideAllocation(
    teeTime: TeeSheetGridContent,
    slot: TeeSheetSkeletonData
  ): Allocation {
    let _allocation = slot.allocation;

    if (
      (_allocation == null || _allocation.id == 0) &&
      teeTime.status.scheduleStatus == ScheduleStatus.booked &&
      teeTime.playerDetail.length > 0
    ) {
      let allocationBlockId: number =
        _allocation == null || _allocation.id == 0
          ? teeTime.playerDetail[0].allocationBlockId
          : _allocation.id;
      _allocation = this.assignAllocation(
        this.allAllocations,
        allocationBlockId
      );
    }
    return _allocation;
  }

  private assignSlotBGColor(
    status: Status,
    slot: TeeSheetSkeletonData
  ): Status {
    let bgColor: Status = status;
    if (status.scheduleStatus == ScheduleStatus.booked) {
      // Need to show allocation color in case of booked tee time slots.
      bgColor.colorCode = slot.allocation.colorCode;
    }
    return bgColor;
  }

  public mapPlayerDetails(
    apiValue: TeeTimePlayerDetail[],
    rateType: RateType[],
    playerTypes: PlayerType[],
    scheduleTeeTimeId: number,courseId = 0,scheduleDateTime = ""
  ): TeeSheetPlayerDetails[] {
    let playerDetails: TeeSheetPlayerDetails[] = [];
    if (apiValue) {
      const _mapped: TeeSheetPlayerDetails[] = apiValue.map((o) => {
        const _data: TeeSheetPlayerDetails = {
          playPos: o.playerPosition,
          firstName: o.firstName,
          lastName: o.lastName,
          gender: o.gender,
          amountPaid: o.amountPaid,
          memberNumber: o.memberNumber,
          playerComment: o.playerComment,
          playerId: o.teeTimePlayerId,
          linkingId: o.playerLinkId,
          tournamentId: o.tournamentId,
          fullName: o.firstName + " " + o.lastName,
          isDragDisabled: true,
          deposits: o.deposits,
          transactionId: o.transactionId,
          playerStatus: o.playerStatus,
          groupId: o.groupId,
          bookingId: o.bookingId,
          playersPerGroup: o.playersPerGroup,
          teeTimeFormat: o.gameFormat,
          playerCategoryId : o.playerCategoryId,
          isPaidPlayer: (o.playerStatus & PlayerPaymentstatus.paid) != 0,
          isCheckedIn: (o.playerStatus & PlayerPaymentstatus.CheckIn) != 0,
          isCheckedOut: (o.playerStatus & PlayerPaymentstatus.CheckOut) != 0,
          playerType: this.assignPlayerType(
            o.scheduledTeeTimePlayerFee.playerTypeId,
            playerTypes
          ),
          rateType: this.assignRateType(o.rateType, rateType),
          holes: o.holes,
          imageReferenceId: o.imageReferenceId,
          bookingSource: o.bookingSource,
          allocationBlockId: o.allocationBlockId,
          confirmationNumber: o.confirmationNumber,
          cartId: o.cartId,
          cartType: o.cartType,
          caddy: o.caddy,
          caddyType: o.caddyType,
          caddyId: o.caddyId,
          caddyTypeId: o.caddyTypeId,
          isFlagDay: o.isFlagDay,
          initialPlayerSlotPosition: o.playerPosition,
          scheduleTeeTimeId: scheduleTeeTimeId,
          isBlocked: o.isBlocked,
          ticketNumber: o.ticketNumber,
          scheduledTeeTimePlayerFee: o.scheduledTeeTimePlayerFee,
          entryFee: o.entryFee,
          playerTypeId: o.scheduledTeeTimePlayerFee.playerTypeId,
          playerStayDetail: o.playerStayDetail,
          multiPackTransactionDetailId: o.multiPackTransactionDetailId,
          multiPackGreenFeeValue: o.multiPackGreenFeeValue,
          multiPackCartFeeValue: o.multiPackCartFeeValue,
          isMarkAsPaid: o.isMarkAsPaid,
          patronId: o.patronId,
          roomNumber: o.roomNumber,
          arrivalDate: o.arrivalDate,
          departureDate: o.departureDate,
          customField1: o.customField1,
          customField2: o.customField2,
          customField3: o.customField3,
          customField4: o.customField4,
          customField5: o.customField5,
          eformCount: o.eformCount,
          eformTotalCount: o.eformTotalCount,
          eformSubmittedCount: o.eformSubmittedCount,
          eformSubmitted: o.eformSubmitted,
          packageCode: o.packageCode,
          vip: o.vip,
          isTrail: o.isTrail != undefined ? o.isTrail : false,
          isWalk: o.isWalk != undefined ? o.isWalk : false,
          isExternal: o.scheduledTeeTimePlayerFee.isExternal,
          daysInAdvanceCount: o.daysInAdvanceCount,
          courseId: courseId,
          time: scheduleDateTime,
          scheduledTeeTimePlayerId: o.scheduledTeeTimePlayerId,
          isRetailItemsAvailable: o.isRetailItemsAvailable,
          isRentalItemsAvailable: o.isRentalItemsAvailable,
          tokenTransId: o.tokenTransId
        };
        if (_data.isBlocked) {
          _data.isRemovable = false;
          _data.isDragDisabled = false;
        }
        return _data;
      });
      playerDetails = this.assignPlayerPlayGroup(_mapped);
      const setting: setting.GolfSetting = JSON.parse(
        sessionStorage.getItem(SettingScreen.GolfSetting)
      );
      if (
        !(
          setting &&
          setting.enableGroupingOfPlayers &&
          !setting.playerPositionBySlot
        )
      ) {
        playerDetails = playerDetails.sort((p1, p2) => {
          return p1.playPos - p2.playPos;
        });
      }
    }

    return playerDetails;
  }
  private assignPlayerPlayGroup(
    playerDetails: TeeSheetPlayerDetails[]
  ): TeeSheetPlayerDetails[] {
    const confirmationNumberPropertyName: keyof TeeSheetPlayerDetails =
      "confirmationNumber";
    let groupedPlayers: _.Dictionary<TeeSheetPlayerDetails[]> = _.groupBy(
      playerDetails,
      confirmationNumberPropertyName
    );
    let groupedPlayerWithConfirmationNo: TeeSheetPlayerDetails[][] =
      Object.values(groupedPlayers);
    groupedPlayerWithConfirmationNo = groupedPlayerWithConfirmationNo.sort(
      (l, r) => this.sortByPlayPosition(l, r)
    );
    let existingBulkSlotGroup: boolean =
      groupedPlayerWithConfirmationNo &&
      groupedPlayerWithConfirmationNo.length > 1 &&
      groupedPlayerWithConfirmationNo.some((x) =>
        x.some((y) => this.slotGroupInfo.has(y.confirmationNumber))
      );
    let groupIndex: number = 1;
    groupedPlayerWithConfirmationNo.forEach(
      (g: TeeSheetPlayerDetails[], index) => {
        g = g.sort((p1, p2) => {
          return p1.playPos - p2.playPos;
        });
        let positionIndex: number = this.calculateInitialPositionIndex(g);
        g.forEach((o, playerindex) => {
          const isGroupAlreadyExist: boolean = this.slotGroupInfo.has(
            o.confirmationNumber
          );

          if (isGroupAlreadyExist) {
            const previousGroupInfo = this.slotGroupInfo.get(
              o.confirmationNumber
            );
            groupIndex = previousGroupInfo.lastGroupIndex;
          } else if (index == 0 && playerindex == 0 && existingBulkSlotGroup) {
            groupIndex++;
          }
          o.blockedSlotColor = this.getBlockedSlotColor();  
        
          o.playGroup = {
            id: positionIndex,
            colorCode: this.getColorCodeFromGolfSettingCache(groupIndex),
            name: "",
            icon: "",
            listOrder: this.assignSlotPlayerListOrder(
              groupIndex,
              positionIndex,
              o
            ),
          };
          positionIndex++;
        });
        this.slotGroupInfo.set(g[0].confirmationNumber, {
          lastGroupIndex: groupIndex,
          lastPlayerPosition: positionIndex - 1,
        });
        groupIndex++;
        groupIndex = groupIndex > 4 ? 1 : groupIndex; // Group cannot exceed 4, If exceeds, reset back to first group
      }
    );
    const _groupedPlayers: any = groupedPlayerWithConfirmationNo;
    return _groupedPlayers.flat();
  }
  assignSlotPlayerListOrder(
    groupIndex: number,
    positionIndex: number,
    playerDetails: TeeSheetPlayerDetails
  ): number {
    const setting: setting.GolfSetting = JSON.parse(
      sessionStorage.getItem(SettingScreen.GolfSetting)
    );
    let index: number = playerDetails.playPos;
    if (
      setting &&
      setting.enableGroupingOfPlayers &&
      !setting.playerPositionBySlot
    ) {
      index = positionIndex;
    }
    return index;
  }
  calculateInitialPositionIndex(g: TeeSheetPlayerDetails[]): number {
    let defaultPositionIndex = 1;
    if (this.slotGroupInfo.has(g[0].confirmationNumber)) {
      defaultPositionIndex =
        this.slotGroupInfo.get(g[0].confirmationNumber).lastPlayerPosition + 1;
    }
    return defaultPositionIndex;
  }

  private sortByPlayPosition(
    left: TeeSheetPlayerDetails[],
    right: TeeSheetPlayerDetails[]
  ) {
    return (
      Math.min(...left.map((o) => o.playPos)) -
      Math.min(...right.map((o) => o.playPos))
    );
  }

  private getColorCodeFromGolfSettingCache(id: number): string {
    let colorCode: string = "#607d8b"; // Default Color Code - Same as the Player position color(grey)
    const setting: setting.GolfSetting = JSON.parse(
      sessionStorage.getItem(SettingScreen.GolfSetting)
    );
    if (setting && setting.enableGroupingOfPlayers) {
      colorCode = setting.playerPlayGroups.find((s) => s.id == id).colorCode;
    }
    return colorCode;
  }

         
  private getBlockedSlotColor(): string{
    let colorCode: string = "#607d8b"; // Default Color Code - Same as the Player position color(grey)  
    const setting: setting.GolfSetting = JSON.parse(
      sessionStorage.getItem(SettingScreen.GolfSetting)
    ); 
    return setting.blockedSlotColorCode;
  }
  private assignPlayerType(
    id: number,
    allActivePlayerType: PlayerType[]
  ): PlayerRateType {
    let playerType: PlayerRateType = {
      id: 0,
      name: "",
      daysOutStart: 0,
      daysOutEnd: 0,
    };
    const _type = allActivePlayerType.find((r) => r.id === id);
    if (_type) {
      playerType = {
        id: id,
        name: _type.type,
        daysOutStart: _type.daysOutStart,
        daysOutEnd: _type.daysOutEnd,
      };
    }
    return playerType;
  }
  private assignRateType(
    id: number,
    allActiveRateType: RateType[]
  ): PlayerRateType {
    let rateType: PlayerRateType = {
      id: 0,
      name: "",
      daysOutStart: 0,
      daysOutEnd: 0,
    };
    const _type = allActiveRateType.find((r) => r.id === id);
    if (_type) {
      rateType = {
        id: id,
        name: _type.type,
        daysOutStart: _type.daysOutStart,
        daysOutEnd: _type.daysOutEnd,
      };
    }
    return rateType;
  }

  private assignAllocation(
    allActiveAllocations: API.TeeTimeAllocationBlock[],
    assignedAllocationId: number
  ): Allocation {
    const _allocation = allActiveAllocations.find(
      (a) => a.id === assignedAllocationId
    );
    let _defaultAllocation: API.TeeTimeAllocationBlock;
    if (this.defaultSettings && this.defaultSettings.blockingCode) {
      _defaultAllocation = allActiveAllocations.find(
        (a) => a.id === this.defaultSettings.blockingCode
      );
    }
    let defaultAllocation: Allocation = {
      id: _defaultAllocation ? _defaultAllocation.id : 0,
      name: _defaultAllocation ? _defaultAllocation.allocationBlockName : "",
      colorCode: _defaultAllocation ? _defaultAllocation.colorCode : DEFAULT_TEE_SHEET_SLOT_BACKGROUND_COLOR,
      icon: "",
      displayOnTeesheet: _defaultAllocation ? _defaultAllocation.displayOnTeesheet : false,
      closed: _defaultAllocation ? _defaultAllocation.closed : false,
    };
    if (_allocation) {
      defaultAllocation = {
        id: _allocation.id,
        name: _allocation.allocationBlockName,
        colorCode: _allocation.colorCode,
        icon: "",
        displayOnTeesheet: _allocation.displayOnTeesheet,
        closed: _allocation.closed,
      };
    }

    return defaultAllocation;
  }

 
  public assignStatus(value: ScheduleStatus): Status {
    let _status: Status;
    switch (value) {
      case ScheduleStatus.open:
        _status = {
          name: this._localization.captions.teetime.open,
          icon: "",
          colorCode: "",
          scheduleStatus: ScheduleStatus.open,
        };

        break;
      case ScheduleStatus.hold:
        _status = {
          name: this._localization.captions.teetime.onhold,
          icon: "icon-hold",
          colorCode: "#E39595",
          scheduleStatus: ScheduleStatus.hold,
        };

        break;
      case ScheduleStatus.frostDelay:
        _status = {
          name: this._localization.captions.teetime.frostDelay,
          icon: "icon-snow",
          colorCode: "#96D8D0",
          scheduleStatus: ScheduleStatus.frostDelay,
        };

        break;
      case ScheduleStatus.booked:
        _status = {
          name: this._localization.captions.teetime.Booked,
          icon: "",
          colorCode: "",
          scheduleStatus: ScheduleStatus.booked,
        };

        break;
      case ScheduleStatus.crossOverBlock:
        _status = {
          name: this._localization.captions.teetime.crossoverblock,
          icon: "",
          colorCode: "#CCEFF7",
          scheduleStatus: ScheduleStatus.crossOverBlock,
        };

        break;
      case ScheduleStatus.waitList:
        _status = {
          name: this._localization.captions.teetime.waitlist,
          icon: "",
          colorCode: "#CCEFF7",
          scheduleStatus: ScheduleStatus.waitList,
        };

        break;
      case ScheduleStatus.tempHold:
        _status = {
          name: this._localization.captions.teetime.temphold,
          icon: "icon-hold",
          colorCode: "#96D8D0",
          scheduleStatus: ScheduleStatus.tempHold,
        };

        break;
      case ScheduleStatus.cancelled:
        _status = {
          name: this._localization.captions.teetime.cancelled,
          icon: "",
          colorCode: "#CF000F",
          scheduleStatus: ScheduleStatus.cancelled,
        };

        break;
        case ScheduleStatus.noShow:
        _status = {
          name: this._localization.captions.teetime.noshow,
          icon: "",
          colorCode: "#C0C0C0",
          scheduleStatus: ScheduleStatus.noShow,
        };

        break;
      default:
        _status = {
          name: "",
          icon: "",
          colorCode: "",
          scheduleStatus: ScheduleStatus.open,
        };
        break;
    }
    return _status;
  }
}
