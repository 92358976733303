<div class="dialog-container">
  <app-golfdialog-header [title]="dialogData.title" (closeDialog)="closeHandler($event)"></app-golfdialog-header>
  <div class="dialog-container__content">
    <div [formGroup]="squeezeMinutesForm" class="squeeze-minutes-wrapper">
      <mat-form-field>
        <mat-select [placeholder]="captions.selectSqueezeMinutes" name="SqueezeMinutes" formControlName="squeezeminutes">
          <mat-option *ngFor="let minute of minutes" [value]="minute">
            {{minute}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>    
  </div>
  <app-golfdialog-footer [buttonObj]="buttonObj" (save)="saveHandler($event)" (cancel)="closeHandler($event)" [clickThrottleTime]=1>
  </app-golfdialog-footer>
</div>
