<section class="pop-container">
  <div class="pop-head">
    <label class="inner-header pl-4">{{captions.cardEntryMode}}</label>
    <i  aria-hidden="true" class="material-icons inner-close float-md-right cursPointer" (click)="onCancel()">{{captions.close}}</i>
  </div>
  <div class="pop-contents radio-class">
    <section class=" h-100">
      <form [formGroup]="cardEntryModeFormGroup" class="formheight" autocomplete="off">
        <mat-dialog-content class="p-4 m-0">
          <div class="mt-1 mb-3">
            <div class="card-input-radio-class">
              <mat-radio-group formControlName="cardInputMethod">
                <mat-radio-button *ngFor="let cardInputMethod of cardInputMethods" [value]="cardInputMethod.value">
                  {{cardInputMethod.viewValue}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </mat-dialog-content>
        <mat-dialog-actions class="pop-actions pl-4 m-0">
          <div class="pop-button">
            <button class="body-bgcolor whitecolor button_valid" mat-button color="primary" (click)="onSave()">{{captions.proceed}}
            </button>
            <button mat-button class="ml-2 spa-button-cancel " (click)="onCancel()">{{captions.cancel}}</button>
          </div>
        </mat-dialog-actions>
      </form>
    </section>
  </div>
</section>
