<section class="authorize p-4" [formGroup]="notifyplayers">
    <div class="dialog-container">
        <div class="content">
            <div class="ag_display--flex">
                <div class="yes-col pr-3">
                    <label class="LW12 ag_display--block">{{captions.lbl_email}}</label>
                    <ui-switch [attr.automationId]="'Tog_Authorize_enableSendEmail'" [checkedLabel]="captions.toggleYes"
                        [uncheckedLabel]="captions.toggleNo" (change)="emailChange($event)"
                        formControlName="enableSendEmail">
                    </ui-switch>
                </div>
                <div class="yes-col">
                    <label class="LW12 ag_display--block">{{captions.lbl_sms}}</label>
                    <ui-switch [attr.automationId]="'Tog_Authorize_enableSemdSMS'" [checkedLabel]="captions.toggleYes"
                        [uncheckedLabel]="captions.toggleNo" (change)="phoneChange($event)"
                        formControlName="enableSemdSMS">
                    </ui-switch>
                </div>
            </div>
            <div class="tableHeight mt-4">
                <app-cdkvirtual [attr.automationId]="'Tog_Authorize_authorizeTable'" [options]="tableOptions"
                    [headerOptions]="headerOptions" [tableContent]="tableContent" (rowClickEmit)='onRowClick($event)'
                    (EmittedData)='onTableAction($event)' [childTemplate]="childTemplate">
                </app-cdkvirtual>
                <ng-template #childTemplate let-element="element" let-key="key" let-index="idx">
                    <ng-container [ngSwitch]="key">
                        <ng-container *ngSwitchCase="'emailId'">
                            <input class='table-input alignRight' [(ngModel)]="element['emailId']" [ngModelOptions]="{standalone: true}" [(value)]="element['emailId']"
                                [ngClass]="element['emailId'] | ValidCheck: isEmailRequired: 'email'" [disabled]="disableEmail"
                                (input)="updateKeys($event,element, key)" (blur)='emitTableData($event,element, key)'
                                (keydown.enter)="$event.preventDefault()" />
                        </ng-container>
                        <ng-container *ngSwitchCase="'countryCode'">
                            <input class='table-input alignRight' [(ngModel)]="element['countryCode']" [ngModelOptions]="{standalone: true}" [(value)]="element['countryCode']"
                                [ngClass]="element['countryCode'] | ValidCheck: isPhoneRequired: 'ccode': element['phoneNo']" [disabled]="disablePhone"
                                (input)="updateKeys($event,element, key)" (blur)='emitTableData($event,element, key)'
                                (keydown.enter)="$event.preventDefault()" />
                        </ng-container>
                        <ng-container *ngSwitchCase="'phoneNo'">
                            <input class='table-input alignRight' [(ngModel)]="element['phoneNo']" [ngModelOptions]="{standalone: true}" [(value)]="element['phoneNo']"
                                [ngClass]="element['phoneNo'] | ValidCheck: isPhoneRequired: 'phone'" [disabled]="disablePhone"
                                inputtype="nonnegative,nodecimal" (input)="updateKeys($event,element, key)" minlength="8" maxlength="12"
                                (blur)='emitTableData($event,element, key)' (keydown.enter)="$event.preventDefault()" />
                        </ng-container>
                    </ng-container>
                </ng-template>
            </div>
        </div>
    </div>
</section>
<!-- <div class='ag_footer--actions ag_display--flex'>
    <app-button [ngClass]=" this.notifyplayers.controls['enableSemdSMS'].value || this.notifyplayers.controls['enableSendEmail'].value? 'button_valid whitecolor body-bgcolor': 'spa-primary-disabled button_invalid'" [buttontype]='proceedButton' (valueChange)='sendNotification()'></app-button>
    <app-button [buttontype]='cancelButton' (valueChange)='onCancel()'></app-button>
</div>   -->