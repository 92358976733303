
import { Injectable } from '@angular/core';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { CourseComment, CourseCommentAPI } from  'src/app/settings/golf-setup/course-comment/course-comment-modal/course-comment-model';
import { GolfManagementCommunication } from 'src/app/shared/communication/services/golfmanagement.service';


@Injectable()
export class CourseCommentDataService {

  constructor(private _golfManagementCommunication: GolfManagementCommunication, public _localization: GolfLocalization) { 
  }

  public  getCourseComment(inclHistDate:boolean = false) : Promise<CourseCommentAPI[]> {
    return  this._golfManagementCommunication.getPromise<CourseCommentAPI[]>(
              { route: GolfApiRoute.GetCourseComment,uriParams: {inclHistDate: inclHistDate} }, false);
         
  }

  public getCourseCommentByCourseIdandDate(courseId: number, scheduleDate: Date): Promise<CourseComment> {
    let _date: string = this._localization.convertDateObjToAPIdate(scheduleDate);
    return this._golfManagementCommunication.getPromise<CourseComment>(
            { route: GolfApiRoute.GetCourseCommentByCourseIdAndScheduleDate, uriParams: { 'courseId': courseId, 'scheduleDate': _date } });
     
}
 
  public  createCourseComment(body: CourseComment): Promise<CourseComment[]> {
    return  this._golfManagementCommunication.postPromise<CourseComment[]>(
            { route: GolfApiRoute.CreateCourseComment, body: body }, true);
      
  }
  public  updateCourseComment(body: CourseComment): Promise<CourseComment[]> {
    return this._golfManagementCommunication.putPromise<CourseComment[]>(
            { route: GolfApiRoute.UpdateCourseComment, body: body }, true);
      
  }
  public  deleteCourseComment(id : number): Promise<CourseComment[]> {
    return this._golfManagementCommunication.deletePromise<CourseComment[]>(
            { route: GolfApiRoute.DeleteCourseComment, uriParams: { "id": id }}, false);
      
  }
  public getCourseCommentById(id : number): Promise<CourseComment[]> {
    return  this._golfManagementCommunication.getPromise<CourseComment[]>(
        { route: GolfApiRoute.GetCourseCommentById, uriParams: { "id": id }}, false);
   
}
}
