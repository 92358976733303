import { Injectable } from '@angular/core';
import { AuthenticationCommunication } from '../../communication/services/authentication.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfUtilities } from '../../utilities/golf-utilities';
import { UserAccessModel } from 'src/app/common/dataservices/authentication/useraccess-model.model';
import { Product } from 'src/app/common/enums/shared-enums';


@Injectable(
    { providedIn: 'root' }
)
export class UserAccessDataService {

    constructor(private _authentication: AuthenticationCommunication
        , private _utilities: GolfUtilities, private localization: GolfLocalization) { 
        }

  
        public async getUserAccess(breakPointNumber: number, roleId? : string): Promise<UserAccessModel.BreakPointResult> {
            // default value - allow view only
            let result: UserAccessModel.BreakPointResult = {
                isAllow: false,
                isViewOnly: true
            };
            const userRoleId = (roleId) ? roleId : this.localization.GetsessionStorageValue('_userInfo', 'roleId');
            let propConfig = JSON.parse(sessionStorage.getItem("propConfig"));
            let enableUICache = propConfig?.UICacheEnabled;      
            let accesses;
            if(enableUICache && enableUICache.toLowerCase() == "true"){
                accesses = await this.getAllUserAccess(userRoleId);
            } else {
                accesses = await this._authentication.getPromise<UserAccessModel.BreakPointAccess[]>({
                    route: GolfApiRoute.GetBreakPoint
                    , uriParams: { roleID: userRoleId, bkpn: breakPointNumber }
                });
            }
            accesses = accesses ? accesses : [];
            let access: UserAccessModel.BreakPointAccess = accesses.find(x => x.breakPointNumber == breakPointNumber);
            if (access) {
                result = {
                    isAllow: access.allow,
                    isViewOnly: access.view
                };
            }
            return result;
        }
    
        public async getUserAccesses(breakPointNumber: number[]): Promise<UserAccessModel.BreakPointResult[]> {
            let result: UserAccessModel.BreakPointResult[] = [];
            const userRoleId = this.localization.GetsessionStorageValue('_userInfo', 'roleId');
            let propConfig = JSON.parse(sessionStorage.getItem("propConfig"));
            let enableUICache = propConfig?.UICacheEnabled;      
            let accesses;
            if(enableUICache && enableUICache.toLowerCase() == "true"){
                accesses = await this.getAllUserAccess(userRoleId);
            } else{
                accesses = await this._authentication.getPromise<UserAccessModel.BreakPointAccess[]>({
                    route: GolfApiRoute.GetBreakPoint
                    , uriParams: { roleID: userRoleId, bkpn: breakPointNumber }
                });
            }
            
            accesses = accesses ? accesses : [];
            result = accesses.map(access => {
                return <UserAccessModel.BreakPointResult>{
                    isAllow: access.allow,
                    isViewOnly: access.view,
                    breakPointNumber: access.breakPointNumber
                };
            });
            return result;
        }
    
        public async getAllUserAccess(userRoleId): Promise<UserAccessModel.BreakPointAccess[]>{
            return await this._authentication.getPromise<UserAccessModel.BreakPointAccess[]>({
                route: GolfApiRoute.GetUserClaimsForRoleAsync
                , uriParams: { RoleId: userRoleId}
            });
        }

    public async isViewOnly(breakPointNUmber: number): Promise<boolean> {
        return true;
    }

    private getProductId() {
        return this.localization.GetsessionStorageValue('propertyInfo', 'ProductId');
    }

}
