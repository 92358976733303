import { Pipe, PipeTransform, Injectable } from "@angular/core";
import { GolfLocalization } from './golf-localization'

@Pipe({ name: "Currency" })
@Injectable()
export class CustomCurrencyPipe implements PipeTransform {
    private DECIMAL_SEPARATOR: string;
    private THOUSANDS_SEPARATOR: string;
    constructor(private language: GolfLocalization) {        
    }

    transform(value: any, currencySymbolRequired: boolean = true): string {       
        return this.language.localizeCurrency(value, currencySymbolRequired);        
    }
}