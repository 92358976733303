<div class="message-container">
  <div class="message-content" [ngClass]="data?.type == 'CP' ? 'h90' : ''">
    <div class="message-icon" *ngIf="data?.headerIcon">
      <i  aria-hidden="true" class="{{data.headerIcon}}"></i>
    </div>
    <div class="message-header" *ngIf="data?.headername">
      <label class="LW18">{{data.headername}}</label>
    </div>
    <div class="message-details" [ngSwitch]="data?.type">
      <ng-template [ngSwitchCase]="'message'">
        <div class="h-100">
          <div class="LW16 message-break">{{data.headerMessage}}</div>
          <div class="message-loader" *ngIf="data?.isloaderenable">
            <div class="spinner-container" *ngIf="!data?.cardpayment">
              <div id="cover-spin-retail">
                <div class="customspinnerimg">
                  <img src="./assets/images/agil_favicon.ico" style="width: 100%;">
                </div>
                <div id="default-message">{{captions.lbl_processing}}</div>
              </div>
            </div>
            <div *ngIf="data?.cardpayment">
              <img [class]="spinnerClass" [src]="cardEntryModeGif" alt="" />
              <div class="LW16 message-break warn-text">{{captions.shop.DoNotCloseBrowserMsg}}</div>
            </div>
          </div>
          <div id="progress-bar" *ngIf="data?.showProgress">
            <div class="meter">
              <div [style.width.%]="progressPercentage"></div>
            </div>
            <div class="progress-msg">{{ progressMsg }}</div>
          </div>
        </div>
        <form [formGroup]="dataform">
            <mat-form-field *ngIf="data?.isHiddenFieldRequired" style="pointer-events: none" [floatLabel]="floatLabel">
              <input type="password" #cardfield maxlength="1000" matInput formControlName="carddata" autofocus>
            </mat-form-field>
          </form>
        </ng-template>


    </div>
  </div>
  <div *ngIf="data.buttonName != ''" class="message-button d-flex justify-content-center">
    <button mat-button class="button--primary w150" (click)="DialogClose(data.buttonName)"
      [mat-dialog-close]="( data.dialogReturn ) ? true : false">{{data.buttonName | uppercase}}</button>
    <button *ngIf="noButton" mat-button class="spa-button-cancel w150 ml-3"
      (click)="DialogClose(data.noButtonName)">{{data.noButtonName | uppercase}}</button>
  </div>
</div>
