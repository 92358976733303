<section class='guest-history golf-container--padding bodyWrap'>
  <form [formGroup]="historyFormGrp" autocomplete="off">
    <div class="align-items-baseline ag_display--flex">
      <mat-form-field [floatLabel]="floatLabel" class='golf-form-control--xs'>
        <mat-select [attr.automationId]="'Dd_History_historyType'" id="selectHistoryType" #selectSetup (selectionChange)="changeType()" formControlName='historyType'>
          <mat-option *ngFor="let type of historyTypes" [value]="type">
            {{ type.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="ag_ml--auto ag_display--flex" *ngIf = "historyFormGrp.value.historyType.id == 6">
        <label class="mt-1 form-label-normal ag_mr--2">{{captions.expiredMultipacks}}</label>
        <app-ag-toggle class="ag_form-control--xs" [toggleInputs]="expiredToggleInput" (valueChange)="showExpiredMultipacks($event)">
        </app-ag-toggle>
      </div>
      <app-simple-search *ngIf = "historyFormGrp.value.historyType.id == 7" class='hist-searchbox' [placeholder]="captions.lbl_searchByRentalItemName"
      (searchChange)="searchChange($event)"></app-simple-search>
    </div>
  </form>
  <div class='table-wrapper'>
    
    
    <div class="transaction-div h-100 grid-table" *ngIf = "historyFormGrp.value.historyType.id != 7">
      <label for="transaction" class="float-left label-table mb-2" *ngIf = "historyFormGrp.value.historyType.id == 2">{{captions.Transactions}}</label>
      <div class='table-container'>
        <table [attr.automationId]="'Tbl_History_table'" id="table-{{historyFormGrp.value.historyType.id}}" class="" aria-describedby="history-grid-table">
          <thead>
          <th scope="col" *ngFor="let header of tableHeaderArray" class="" [ngStyle]="{'text-align':header.alignType}">
            <span>{{header.tableHeader}}</span></th>
          </thead>
          <tbody>
          <tr *ngFor="let displayData of tableDataArray | sortQuantity : 'quantity'" (click)="selectedRow(displayData)">           
            <td *ngFor="let header of tableHeaderArray" class="textellipsis"
            [ngStyle]="{'text-align':header.alignType}">
            <span
                [matTooltipDisabled]="displayData[header.keyValue] ? (displayData[header.keyValue].toString()).length < 40 : true"
                matTooltip="{{displayData[header.keyValue]}}" matTooltipClass="tooltipClass">
                  <span>{{displayData[header.keyValue]}}</span>
                </span>
              </td>
            </tr>
            <tr *ngIf="tableDataArray && tableDataArray.length == 0">
              <td class="text-center no-data" [attr.colspan]="tableHeaderArray.length">{{captions.NoDataFound}}
              </td>
          </tr>
        </tbody>
      </table>
    </div>

    </div>

    <section *ngIf = "historyFormGrp.value.historyType.id == 2" class="ml-2 mr-2 h-100 transaction-details">     
        <label for="transaction-details" class="label-table mb-2" *ngIf = "historyFormGrp.value.historyType.id == 2">{{captions.TransactionDetails}}</label>  
          <table [attr.automationId]="'Tbl_History_table'" class="" aria-describedby="history-table">
            <thead>
              <th scope="col">{{captions.Quantity}}</th>
              <th scope="col">{{captions.description}}</th>
              <th scope="col">{{captions.Price}} ({{localization.currencySymbol}})</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of itemArray">
                <td>{{item.quantity}}</td>
                <td>{{item.name?item.name:""}}</td>
                <td>{{localization.localizeCurrency(item.price,false)}}</td>
              </tr>
              <tr *ngIf="!itemArray || (itemArray && itemArray.length == 0)">
                  <td class="LW14 text-center no-data" [attr.colspan]="3">{{captions.NoDataFound}}</td>
              </tr>
            </tbody>
          </table>        
      </section>
      <section *ngIf = "historyFormGrp.value.historyType.id == 7" class="w-100">
        <app-cdkvirtual [headerOptions]="headerOptions" [tableContent]="tableContent" [options]="tableOptions" >
        </app-cdkvirtual>
      </section>
  </div>

</section>