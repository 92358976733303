import { Injectable } from '@angular/core';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { RetailmanagementCommunication } from 'src/app/shared/communication/services/retailmanagement.service';
import { ScheduledTeeTimePlayerRetailItem } from 'src/app/tee-time-actions/edit-retail-item/edit-retail-item-model';
import { MultpackHistoryRequest,MultipackAPIModel } from './history.model';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
@Injectable()
export class HistoryService {
  captions: any;

  constructor(private _Localization: GolfLocalization,private _retailmanagementCommunication : RetailmanagementCommunication,
              private _golfScheduleCommunication : GolfScheduleCommunication, private _retailFeatureFlag: RetailFeatureFlagInformationService) { }

  getCaptions() {
    this.captions = this._Localization.captions.guest.history;
    return this.captions;
  }

  public async GetClientMultiPacks(request: MultpackHistoryRequest): Promise<MultipackAPIModel[]> {
    return this._retailmanagementCommunication.putPromise({
        route: GolfApiRoute.GetMultiPackRedeemHistoryDetails,
        body: request
    });
}

  getHistoryTypes() {
    let history = [{ id: 1, name: this.captions.FrequentlyPurchasedItems },
    { id: 2, name: this.captions.SalesHistory },
    { id: 3, name: this.captions.AppointmentDetails },
    { id: 4, name: this.captions.CancellationsandNoShows },
    { id: 5, name: this.captions.TeeTimes },
    { id: 6, name: this.captions.multipack_details }]

    if (this._retailFeatureFlag.IsRentalEnabled){
      history.push({ id: 7, name: this.captions.rentalItems });
    }
    return history;
  }
  getFrequentlyPurchasedData() {
    return [
      {
        quantity: '10',
        itemNumber: '152',
        description: 'Accept Neutralizing Cleanser'
      },
      {
        quantity: '1',
        itemNumber: '12',
        description: 'Hair Wash'
      },
      {
        quantity: '25',
        itemNumber: '157',
        description: 'BL HX Pearl'
      },
      {
        quantity: '8',
        itemNumber: '75',
        description: 'Cart Fee 18r'
      },
      {
        quantity: '55',
        itemNumber: '142',
        description: 'Coconut Honey Scrub 50 min'
      }
    ]
  }

  getSalesHistory() {
    return [{
      date: '09/15/2017',
      transaction: '6565765',
      grandTotal: '7186.00',
      subTotal: '7186.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Facial Cream', quantity: '1', price: '2186.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '143659',
      grandTotal: '1784.00',
      subTotal: '1784.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [{ name: 'Hair Oil', quantity: '4', price: '1784.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '237489',
      grandTotal: '5698.00',
      subTotal: '5698.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items:  [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Cream', quantity: '2', price: '2698.00' }
        ]
      }
    },
    {
      date: '09/15/2017',
      transaction: '145895',
      grandTotal: '4152.00',
      subTotal: '4152.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Massage Cream', quantity: '1', price: '1500.00' },
          { name: 'Hair Accessories', quantity: '2', price: '652.00' }]
      }
    },{
      date: '09/15/2017',
      transaction: '6565765',
      grandTotal: '7186.00',
      subTotal: '7186.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Facial Cream', quantity: '1', price: '2186.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '143659',
      grandTotal: '1784.00',
      subTotal: '1784.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [{ name: 'Hair Oil', quantity: '4', price: '1784.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '237489',
      grandTotal: '5698.00',
      subTotal: '5698.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items:  [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Cream', quantity: '2', price: '2698.00' }
        ]
      }
    },
    {
      date: '09/15/2017',
      transaction: '145895',
      grandTotal: '4152.00',
      subTotal: '4152.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Massage Cream', quantity: '1', price: '1500.00' },
          { name: 'Hair Accessories', quantity: '2', price: '652.00' }]
      }
    },{
      date: '09/15/2017',
      transaction: '6565765',
      grandTotal: '7186.00',
      subTotal: '7186.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Facial Cream', quantity: '1', price: '2186.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '143659',
      grandTotal: '1784.00',
      subTotal: '1784.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [{ name: 'Hair Oil', quantity: '4', price: '1784.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '237489',
      grandTotal: '5698.00',
      subTotal: '5698.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items:  [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Cream', quantity: '2', price: '2698.00' }
        ]
      }
    },
    {
      date: '09/15/2017',
      transaction: '145895',
      grandTotal: '4152.00',
      subTotal: '4152.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Massage Cream', quantity: '1', price: '1500.00' },
          { name: 'Hair Accessories', quantity: '2', price: '652.00' }]
      }
    },{
      date: '09/15/2017',
      transaction: '6565765',
      grandTotal: '7186.00',
      subTotal: '7186.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Facial Cream', quantity: '1', price: '2186.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '143659',
      grandTotal: '1784.00',
      subTotal: '1784.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [{ name: 'Hair Oil', quantity: '4', price: '1784.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '237489',
      grandTotal: '5698.00',
      subTotal: '5698.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items:  [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Cream', quantity: '2', price: '2698.00' }
        ]
      }
    },
    {
      date: '09/15/2017',
      transaction: '145895',
      grandTotal: '4152.00',
      subTotal: '4152.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Massage Cream', quantity: '1', price: '1500.00' },
          { name: 'Hair Accessories', quantity: '2', price: '652.00' }]
      }
    },{
      date: '09/15/2017',
      transaction: '6565765',
      grandTotal: '7186.00',
      subTotal: '7186.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Facial Cream', quantity: '1', price: '2186.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '143659',
      grandTotal: '1784.00',
      subTotal: '1784.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [{ name: 'Hair Oil', quantity: '4', price: '1784.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '237489',
      grandTotal: '5698.00',
      subTotal: '5698.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items:  [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Cream', quantity: '2', price: '2698.00' }
        ]
      }
    },
    {
      date: '09/15/2017',
      transaction: '145895',
      grandTotal: '4152.00',
      subTotal: '4152.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Massage Cream', quantity: '1', price: '1500.00' },
          { name: 'Hair Accessories', quantity: '2', price: '652.00' }]
      }
    },{
      date: '09/15/2017',
      transaction: '6565765',
      grandTotal: '7186.00',
      subTotal: '7186.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Facial Cream', quantity: '1', price: '2186.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '143659',
      grandTotal: '1784.00',
      subTotal: '1784.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [{ name: 'Hair Oil', quantity: '4', price: '1784.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '237489',
      grandTotal: '5698.00',
      subTotal: '5698.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items:  [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Cream', quantity: '2', price: '2698.00' }
        ]
      }
    },
    {
      date: '09/15/2017',
      transaction: '145895',
      grandTotal: '4152.00',
      subTotal: '4152.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Massage Cream', quantity: '1', price: '1500.00' },
          { name: 'Hair Accessories', quantity: '2', price: '652.00' }]
      }
    },{
      date: '09/15/2017',
      transaction: '6565765',
      grandTotal: '7186.00',
      subTotal: '7186.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Facial Cream', quantity: '1', price: '2186.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '143659',
      grandTotal: '1784.00',
      subTotal: '1784.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [{ name: 'Hair Oil', quantity: '4', price: '1784.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '237489',
      grandTotal: '5698.00',
      subTotal: '5698.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items:  [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Cream', quantity: '2', price: '2698.00' }
        ]
      }
    },
    {
      date: '09/15/2017',
      transaction: '145895',
      grandTotal: '4152.00',
      subTotal: '4152.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Massage Cream', quantity: '1', price: '1500.00' },
          { name: 'Hair Accessories', quantity: '2', price: '652.00' }]
      }
    },{
      date: '09/15/2017',
      transaction: '6565765',
      grandTotal: '7186.00',
      subTotal: '7186.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Facial Cream', quantity: '1', price: '2186.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '143659',
      grandTotal: '1784.00',
      subTotal: '1784.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [{ name: 'Hair Oil', quantity: '4', price: '1784.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '237489',
      grandTotal: '5698.00',
      subTotal: '5698.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items:  [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Cream', quantity: '2', price: '2698.00' }
        ]
      }
    },
    {
      date: '09/15/2017',
      transaction: '145895',
      grandTotal: '4152.00',
      subTotal: '4152.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Massage Cream', quantity: '1', price: '1500.00' },
          { name: 'Hair Accessories', quantity: '2', price: '652.00' }]
      }
    },{
      date: '09/15/2017',
      transaction: '6565765',
      grandTotal: '7186.00',
      subTotal: '7186.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Facial Cream', quantity: '1', price: '2186.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '143659',
      grandTotal: '1784.00',
      subTotal: '1784.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [{ name: 'Hair Oil', quantity: '4', price: '1784.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '237489',
      grandTotal: '5698.00',
      subTotal: '5698.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items:  [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Cream', quantity: '2', price: '2698.00' }
        ]
      }
    },
    {
      date: '09/15/2017',
      transaction: '145895',
      grandTotal: '4152.00',
      subTotal: '4152.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Massage Cream', quantity: '1', price: '1500.00' },
          { name: 'Hair Accessories', quantity: '2', price: '652.00' }]
      }
    },{
      date: '09/15/2017',
      transaction: '6565765',
      grandTotal: '7186.00',
      subTotal: '7186.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Facial Cream', quantity: '1', price: '2186.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '143659',
      grandTotal: '1784.00',
      subTotal: '1784.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [{ name: 'Hair Oil', quantity: '4', price: '1784.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '237489',
      grandTotal: '5698.00',
      subTotal: '5698.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items:  [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Cream', quantity: '2', price: '2698.00' }
        ]
      }
    },
    {
      date: '09/15/2017',
      transaction: '145895',
      grandTotal: '4152.00',
      subTotal: '4152.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Massage Cream', quantity: '1', price: '1500.00' },
          { name: 'Hair Accessories', quantity: '2', price: '652.00' }]
      }
    },{
      date: '09/15/2017',
      transaction: '6565765',
      grandTotal: '7186.00',
      subTotal: '7186.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Facial Cream', quantity: '1', price: '2186.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '143659',
      grandTotal: '1784.00',
      subTotal: '1784.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [{ name: 'Hair Oil', quantity: '4', price: '1784.00' }]
      }
    },
    {
      date: '09/15/2017',
      transaction: '237489',
      grandTotal: '5698.00',
      subTotal: '5698.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items:  [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Cream', quantity: '2', price: '2698.00' }
        ]
      }
    },
    {
      date: '09/15/2017',
      transaction: '145895',
      grandTotal: '4152.00',
      subTotal: '4152.00',
      tax: 0.00,
      gratuity: '0.00',
      discount: '0.00',
      transactionDetails: {
        items: [
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Massage Cream', quantity: '1', price: '1500.00' },
          { name: 'Hair Accessories', quantity: '2', price: '652.00' }]
      }
    }];
  }

  getTeeTimes() {
    return [
      {
        date: '09/15/2017',
        time: '12:00',
        transaction: '6565765',
        course: 'Jones',
        status: 'No Show'
      },
      {
        date: '09/15/2017',
        time: '12:00',
        transaction: '4587219',
        course: 'Snead',
        status: 'Paid'
      },
      {
        date: '09/15/2017',
        time: '12:00',
        transaction: '1459863',
        course: 'Jones',
        status: 'Scheduled'
      },
      {
        date: '09/15/2017',
        time: '12:00',
        transaction: '1478653',
        course: 'Palmer',
        status: 'Scheduled'
      }]
  }
  public async GetScheduledTeeTimePlayerRetailItemsForByLinkIds(request: string[]): Promise<ScheduledTeeTimePlayerRetailItem[]> {
    return await this._golfScheduleCommunication.postPromise({
        route: GolfApiRoute.GetScheduledTeeTimePlayerRetailItemsForByLinkIds,
        body: request
    });
}
  getActivities() {
    return [
      {
        date: '09/15/2017',
        time: '12:00',
        transaction: '6565765',
        activity: 'Tennis Training',
        status: 'No Show'
      },
      {
        date: '09/15/2017',
        time: '12:00',
        transaction: '4587219',
        activity: 'Horseback Riding',
        status: 'Paid'
      },
      {
        date: '09/15/2017',
        time: '12:00',
        transaction: '1459863',
        activity: 'Tennis Training',
        status: 'Scheduled'
      },
      {
        date: '09/15/2017',
        time: '12:00',
        transaction: '1478653',
        activity: 'Horseback Riding',
        status: 'Cancelled'
      }];
  }

  getTransactionDetails() {
    return [
      {
        transaction: 6565765,
        items: [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Facial Cream', quantity: '1', price: '2186.00' }]
      },
      {
        transaction: 143659,
        items: [{ name: 'Hair Oil', quantity: '4', price: '1784.00' }]
      },
      {
        transaction: 237489,
        items: [
          { name: 'Accept Neutralizing Cleanser', quantity: '1', price: '3000.00' },
          { name: 'Hair Cream', quantity: '2', price: '2698.00' }
        ]
      },
      {
        transaction: 145895,
        items: [
          { name: 'Hair Lotion', quantity: '2', price: '2000.00' },
          { name: 'Massage Cream', quantity: '1', price: '1500.00' },
          { name: 'Hair Accessories', quantity: '2', price: '652.00' }]
      }
    ]
  }
}
