import { Injectable } from '@angular/core';
import {
	ReportDownloadFormat,
	ReportAPIOptions,
	TemplateParams	
} from 'src/app/reports/report.model';
import { ReportCode } from 'src/app/reports/common/report.constants';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { FastReportBusinessService } from 'src/app/reports/fast-report/fastreport.business.service';
import { SettingsDataService } from 'src/app/shared/data-services/golfschedule/settings.data.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ComponentDetails } from 'src/app/shared/shared-models';
import { ReplaySubject } from 'rxjs';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';


@Injectable({
	providedIn: 'root'
})
export class GolfGuestItineraryBuissiness {
	captions: any;
	printFormat: ReportDownloadFormat = 'PDF';
	_dialog: MatDialog;
	popUpComponentDetails: ComponentDetails;
	$destroyed: ReplaySubject<boolean> = new ReplaySubject(1);

	constructor(
		private _localization: GolfLocalization,
		private _fastReport: FastReportBusinessService,
		private _settingsDataService: SettingsDataService,
		private router: Router,
		private dialog: MatDialog,
		private _utils: GolfUtilities
	) {
		this._dialog = dialog;
		this.captions = this._localization.captions.teeActions;
	}

	printGuestItinerary(
		fromDate: string,
		endDate: string,
		playerId: number,
		fullname: string,
		templateData:any,
		playerLinkId:any,
		callBack?: any
	) {
		const propertyName = this._localization.GetPropertyInfo('PropertyName');
		// const propertyDate = this._localization.ConvertDateToISODateTime(this._localization.getCurrentDate());
		let _reportOptions: ReportAPIOptions = {
			code: ReportCode.GolfGuestItinerary,
			filters: {
				startDate: fromDate,
				endDate: endDate,
				playerId: playerId,
                guestName: fullname
			},
			format: this.printFormat,
			params: [
				{ pStartDate: fromDate },
				{ pPropertyName: propertyName },
				{ pPlayerId: playerId },
				{ pGuestName: fullname },
				{ pEndDate: endDate },
				{ pPlayerLinkId: playerLinkId}
			],
			templateParams:this.formTemplateReportParams(templateData),
			URIParams: null,
			pageBreak: true
		};

		this._fastReport.downloadReport(this.printFormat, _reportOptions, true, callBack);
	}

	private formTemplateReportParams(templateData:any): TemplateParams[]
	{
		let guestPolicy:Uint8Array,guestNotes:Uint8Array;

		if(templateData && templateData.length>0)
		{
			if(templateData.filter(a=>a.eventIds.some(e=>e==1))[0])
			{				
				guestPolicy=templateData.filter(a=>a.eventIds.some(e=>e==1))[0].value;
			}			
		}

		return [
			{
				servicePolicy:guestPolicy
			}			
		];
	}

}
