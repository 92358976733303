import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Device, GiftCardPMRequest, HttpResponseStatus, RGuestPayRequestBuildParams, RGuestPayRequestDetails, SaleResponse, ValidatePayResponse } from "../shared/service/payment/payment-model";
import { RetailRoutes } from "../retail-route";
import { CaptureCardRequest, CapturedCardResponse, GetDeviceInfoResponse, GetDeviceResponse, RGuestSaleResponse, TokenResponse, TransactionType, rGuestPayDevice } from "../shared/business/Giftcard/rGuestPayRequestModel";
import { DeviceStatus, RGUEST_DEVICE_SWIPE_TIMEOUT } from "../shared/business/Giftcard/rGuestPay-base.service";
import { IPMAgentActions } from "./IPMAgentActions";
import { HandleRequest, HandleResponse, TokentransactionInfo, StoreTokenRequest, PaymentBaseResponse, CardInfo, PaymentMethods, GiftCardHandle } from "../shared/business/shared.modals";
import { SaleRequest, ValidateAuthRequest } from "../shared/service/payment/payment-business.model";
import { PMAgentToRGuestPayRequestAdapter } from "./PMAgentToRGuestPayRequestAdapter";
import { RetailFeatureFlagInformationService } from "../shared/service/retail.feature.flag.information.service";
import { RetailIntegrationLogService } from "../shared/service/retail-integrationLog.service";
import { IntegrationOperationsLog, IntegrationOperationsStatus } from "src/app/common/Models/common.models";
import { PaymentCommunication } from "../payment-communication.service";
import { RetailUtilities } from "../shared/utilities/retail-utilities";
import { CaptureStatus, GiftCardCaptureResponse } from "../shared/business/Giftcard/IGiftcardActions";
import { timer } from "rxjs";
import { take, timeout } from "rxjs/operators";
import { rGuesPayRoutes } from "src/app/common/communication/common-route";
import { PayAgentService } from "../shared/service/payagent.service";
import { CancellationTimeInSeconds, CardDataSourceType, InvokeAgilysysPayViaWebProxy } from 'src/app/retail/shared/globalsContant';
import { RetailLocalization } from "../common/localization/retail-localization";
import { Router } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class NonPMAgentRequestHandler implements IPMAgentActions {

    private rGuestRequestCache_Store = new Map<string, string>();

    constructor(
        private http: HttpClient
        , public localization: RetailLocalization
        , private _retailComm: PaymentCommunication
        , private _reqAdapter: PMAgentToRGuestPayRequestAdapter
        , public _featureFlagService: RetailFeatureFlagInformationService
        , private _logService: RetailIntegrationLogService
        , private _utils: RetailUtilities
        , private _payAgent: PayAgentService
        , private _router: Router
    ) {
        this._featureFlagService?.LogoutTriggered?.subscribe(() => { this.rGuestRequestCache_Store.clear(); });
        this._featureFlagService.$scope = this;
    }

    private async GetOutletId(outletId: number) {
        if (outletId > 0) return outletId;
        else {
            const ceds = await this._payAgent.TryGetCEDSValueAsync(outletId)
            return ceds ? Number(ceds.split("_")[3]) : outletId;
        }
    }

    async GetHandles(handleRequestBody: HandleRequest, outletId: number, ceds: string = "", isShowValidatePayAgentMsg: boolean = true): Promise<HandleResponse> {
        const handleResponse: HandleResponse = {
            errorMessage: "",
            status: HttpResponseStatus.Failure,
            paymentHandle: []
        }
        try {
            if (handleRequestBody.tenderId == PaymentMethods.IDTECH.toString()) {
                handleResponse.status = HttpResponseStatus.Success;
                const idTechHandleWithData = {
                    inquiryInfo: handleRequestBody.inquiryInfo
                }
                handleResponse.paymentHandle.push({ handle: JSON.stringify(idTechHandleWithData) } as any);
            } else {
                outletId = await this.GetOutletId(outletId);
                let deviceList = await this.GetDevices(this._reqAdapter.MapHandleRequest(handleRequestBody, outletId));
                if (deviceList.length > 0) {
                    deviceList.map(d => {
                        const handle: any = {
                            handle: d.deviceGuid,
                            name: d.description,
                            deviceGuid: d.deviceGuid,
                            deviceId: d.deviceGuid,
                        }
                        handleResponse.paymentHandle.push(handle);
                    });
                    handleResponse.status = HttpResponseStatus.Success;
                } else {
                    handleResponse.errorMessage = this.localization.captions.shop.NoDevicesFound;
                    handleResponse.status = HttpResponseStatus.Failure;
                }
            }
        } catch (error) {
            handleResponse.errorMessage = error?.error?.result?.errorMessage || error.message;
            handleResponse.status = HttpResponseStatus.Failure;
        }
        return handleResponse;
    }

    async GetBalance(handleRequestBody: GiftCardPMRequest, outletId: number, ceds: string = ""): Promise<HandleResponse> {
        outletId = await this.GetOutletId(outletId);
        handleRequestBody.InquirerInfo.store = outletId;
        handleRequestBody["PaymentDetails"] = { screenRoute: this._router.url } as any;
        const response = await this._retailComm.postPromise<HandleResponse>({
            route: RetailRoutes.GetGiftcardBalance,
            body: handleRequestBody
        }, false);
        return response;
    }

    async CaptureGiftCard(selectedDeviceGuid: string, tenderId: number, outletId: number, isIDTech: boolean, isShift4Giftcard, encryptedData?: string, callBackFn?): Promise<GiftCardHandle> {
        if (!isIDTech) {
            const mapToCardCaptureResponse = (rGuestRes: CapturedCardResponse) => {                
                if (rGuestRes && rGuestRes.cardData) {
                    return {
                        inquiryData: {
                            id: "",
                            cardData: rGuestRes.cardData
                        },
                        cardNumber: this.ParseCardNumber(rGuestRes),
                        status: CaptureStatus.Success
                    }
                }
            };
            const noCardDataAvailable = 9207;
            let rguestErrorMsg = "";
            let rguestStatus = 0;
            let rguestException = false;
            let stopPooling = false;
            outletId = await this.GetOutletId(outletId);
            let res = this.LightUpTheDevice(selectedDeviceGuid, outletId, tenderId);
            const v1GiftcardPoolingTime = 1000;
            const v1GiftcardSwipeTimeout = 30000;
            res.then(async (result) => {
                //Read the card Info and Update the device state
                for (let timeout = v1GiftcardPoolingTime; (timeout <= v1GiftcardSwipeTimeout && !stopPooling); timeout = timeout + v1GiftcardPoolingTime) {
                    await timer(v1GiftcardPoolingTime).pipe(take(1)).toPromise();
                    await this.GetSwippedCardInfo(selectedDeviceGuid, outletId, tenderId).then(
                        (response) => {
                            if (response && response.cardData) {
                                rguestErrorMsg = "";
                                rguestStatus = CaptureStatus.Success;
                                callBackFn && callBackFn(mapToCardCaptureResponse(response));
                                stopPooling = true;
                            } else {
                                let failedRes: any = response;
                                rguestErrorMsg = failedRes.message;
                                rguestStatus = CaptureStatus.Failure;
                                if (failedRes.code != noCardDataAvailable) {
                                    stopPooling = true;
                                    rguestException = true;
                                    callBackFn && callBackFn({ cardNumber: "", handle: "", status: CaptureStatus.Failure, errorMsg: failedRes.message } as GiftCardCaptureResponse);
                                }
                            }
                        }
                    ).catch((errResponse: HttpErrorResponse) => {
                        this.GetDeviceInfo(selectedDeviceGuid, outletId, tenderId).then((device) => {
                            if (device.deviceInfo.deviceStatus != DeviceStatus.Available) {
                                this.SetDeviceToStandByMode(selectedDeviceGuid, outletId, tenderId);
                            }
                        });
                        stopPooling = true;
                        rguestException = true;
                        callBackFn && callBackFn({ cardNumber: "", handle: "", status: CaptureStatus.Failure, errorMsg: errResponse.error.message } as GiftCardCaptureResponse);
                    });
                }
                if (!rguestException) {
                    this.SetDeviceToStandByMode(selectedDeviceGuid, outletId, tenderId);
                    if (rguestStatus == CaptureStatus.Failure && rguestErrorMsg) {
                        callBackFn && callBackFn({ cardNumber: "", handle: "", status: CaptureStatus.Failure, errorMsg: rguestErrorMsg } as GiftCardCaptureResponse);
                    }
                }
            });
        } else {
            return this.ProcessIDTechCardData(encryptedData, isShift4Giftcard);
        }
    }

    ExtractCardNumber(giftcardNumber) {
        const startingSeqIdentifier = "B";
        const endingSeqIdentifier = "^";
        if (giftcardNumber && giftcardNumber.includes(startingSeqIdentifier) && giftcardNumber.includes(endingSeqIdentifier)) {
            let cardInfo = giftcardNumber.split(startingSeqIdentifier)[1].split(endingSeqIdentifier);
            giftcardNumber = cardInfo[0];
        }
        return giftcardNumber;
    }
    
    private ParseCardNumber(rGuestRes: CapturedCardResponse) {
        let cardNumber = rGuestRes?.cardData?.accountNumber ?? "";
        if (rGuestRes?.cardData) {
            try {
                const giftcardData = rGuestRes?.cardData;
                if (this._featureFlagService.CardDataSource) {
                    switch (this._featureFlagService.CardDataSource.toLowerCase()) {
                        case CardDataSourceType.track1:
                            cardNumber = giftcardData?.track1;
                            break;
                        case CardDataSourceType.track2:
                            cardNumber = giftcardData?.track2;
                            break;
                        case CardDataSourceType.accountnumber:
                            cardNumber = giftcardData?.accountNumber;
                            break;
                        default:
                            cardNumber = giftcardData?.accountNumber;
                            break;
                    }
                } else {
                    //If suppose cardDataSource not configured, we need to use existing code logic to extract cardnumber
                    cardNumber = rGuestRes?.cardData?.accountNumber ? rGuestRes?.cardData?.accountNumber : rGuestRes.cardData.track1;
                }
                if (this._featureFlagService.CardDataRegex) {
                    let cardNumResult = cardNumber.match(this._featureFlagService.CardDataRegex);
                    cardNumber = cardNumResult ? cardNumResult[0]?.toString() : cardNumber;
                }
            }
            catch (ex) {
                console.error("Error while parsing Giftcard Data" + ex);
                cardNumber = rGuestRes?.cardData?.accountNumber ? rGuestRes?.cardData?.accountNumber : rGuestRes.cardData.track1;
            }
        }
        cardNumber = this.ExtractCardNumber(cardNumber);
        //Card expiry will be in card data if swiped in MSR, So removing it
        if (cardNumber && cardNumber.includes("=")) {
            let EncryptedData: string[] = cardNumber.split('=');
            cardNumber = EncryptedData[0]; //Considering only the card number
        }
        return cardNumber;
    }

    private ProcessIDTechCardData(encryptedData: string, isShift4Giftcard: boolean): GiftCardHandle {        
        return {
            inquiryData: {
                id: encryptedData,
                type: isShift4Giftcard ? "01" : "",
                cardData: {
                    encryptedData: JSON.stringify({
                        deviceType: isShift4Giftcard ? "01" : 'idtechkb',
                        encryptedData: encryptedData
                    })
                }
            },
            status: CaptureStatus.Success
        }
    }

    private GetGiftcardRGPUrlFromStore(outletId: number, tenderId: number) {
        const outletKey = `${outletId}.${tenderId}`;
        let rGuestReqURLData: RGuestPayRequestDetails;
        let rGuestReqURL = "";
        if (this.rGuestRequestCache_Store.has(outletKey)) {
            rGuestReqURLData = JSON.parse(this.rGuestRequestCache_Store.get(outletKey));
            rGuestReqURL = rGuestReqURLData.url.replace(rGuesPayRoutes.GetDeviceList, "");
        }
        return rGuestReqURL;
    }

    GetDeviceInfo(selectedDeviceGuid:string, outletId: number, tenderId: number): Promise<GetDeviceInfoResponse> {        
        return this.http.get<GetDeviceInfoResponse>(this.GetGiftcardRGPUrlFromStore(outletId, tenderId) + rGuesPayRoutes.GetDeviceInfo.replace("{deviceGuid}", selectedDeviceGuid)).toPromise();
    }

    async LightUpTheDevice(selectedDeviceGuid: string, outletId: number, tenderId: number) {
        try {
            const reqBody: CaptureCardRequest = {
                amount: 0,
                currencyCulture: {
                    currencyCultureName: "en-US",
                    currencySymbol: "$"
                }
            }

            //Lights Up the device
            return this.http.post(
                this.GetGiftcardRGPUrlFromStore(outletId, tenderId) + rGuesPayRoutes.CardCapture.replace("{deviceGuid}", selectedDeviceGuid), reqBody
            ).pipe(timeout(RGUEST_DEVICE_SWIPE_TIMEOUT)).toPromise(); //Timeouts the req after this timeout

        } catch (error) {
            console.log(`Error occurred while CaptureCardWithIngenicoDevice ex: ${error}`);
        }
    }

    GetSwippedCardInfo(selectedDeviceGuid:string, outletId: number, tenderId: number) {
        return this.http.get<CapturedCardResponse>(this.GetGiftcardRGPUrlFromStore(outletId, tenderId) + rGuesPayRoutes.GetCapturedCardInfo.replace("{deviceGuid}", selectedDeviceGuid)).toPromise();
    }

    SetDeviceToStandByMode(selectedDeviceGuid:string, outletId: number, tenderId: number) {
        return this.http.post(this.GetGiftcardRGPUrlFromStore(outletId, tenderId) + rGuesPayRoutes.StandBy.replace("{deviceGuid}", selectedDeviceGuid), null).toPromise()
    }

    async LoadCard(handleRequestBody: GiftCardPMRequest, outletId: number, ceds: string = ""): Promise<ValidatePayResponse> {
        handleRequestBody.InquirerInfo.store = outletId;
        handleRequestBody.PaymentDetails.screenRoute = this._router.url;
        const response = await this._retailComm.postPromise<ValidatePayResponse>({
            route: RetailRoutes.LoadGiftcard,
            body: handleRequestBody
        }, false);
        return response;
    }

    async IssueCard(handleRequestBody: GiftCardPMRequest, outletId: number, ceds: string = ""): Promise<ValidatePayResponse> {
        handleRequestBody.InquirerInfo.store = outletId;
        handleRequestBody.PaymentDetails.screenRoute = this._router.url;
        const giftcardRoute = handleRequestBody.isCardActive ? RetailRoutes.LoadGiftcard : RetailRoutes.IssueGiftcard;
        const response = await this._retailComm.postPromise<ValidatePayResponse>({
            route: giftcardRoute,
            body: handleRequestBody
        }, false);
        return response;
    }

    GetHandlesWithTimeout(handleRequestBody: HandleRequest, outletId: number, timeout: number, ceds: string = ""): Promise<HandleResponse> {
        throw new Error("Method not implemented.");
    }

    private getCancellationTimeout(response: RGuestPayRequestDetails): number {
        return response?.headers?.[CancellationTimeInSeconds] ? Number(response?.headers?.[CancellationTimeInSeconds]) * 1000 : RGUEST_DEVICE_SWIPE_TIMEOUT;
    }

    async CreateToken(handle: string, tenderID: number, outletId: number, manualCardEntry: boolean, isPartialTenderAllowed: boolean, ceds?: string): Promise<TokentransactionInfo> {
        let tokenResponse: TokentransactionInfo;
        let rGPResponse: any;
        let requestId;
        try {
            outletId = await this.GetOutletId(outletId);
            const response = await this._retailComm.postPromise<RGuestPayRequestDetails>({
                route: RetailRoutes.BuildRGuestPayRequest,
                body: this._reqAdapter.MapTokenRequest(handle, tenderID, outletId, manualCardEntry)
            }, false);
            if (response) {
                let requestPayload = JSON.parse(response.requestPayload);
                requestId = requestPayload.RequestId;
                const isProxyRequired = response?.headers?.[InvokeAgilysysPayViaWebProxy]?.toLowerCase() === 'true' ? true : false;
                if (isProxyRequired) {
                    rGPResponse = await this._retailComm.postPromiseDirect<TokenResponse>({
                        route: RetailRoutes.ProxyCallToRGuestPay,
                        body: response,
                        uriParams: { proxyPaymentMethod: tenderID }
                    }, false)
                }
                else {
                    rGPResponse = await this.http.post<TokenResponse>(response.url + "?skipLog=true", requestPayload).pipe(timeout(this.getCancellationTimeout(response))).toPromise();
                }

                this.UpdateIntegrationLogForSuccess(requestPayload.RequestId, tenderID, rGPResponse, IntegrationOperationsStatus.Realized);
                tokenResponse = this._reqAdapter.MapTokenResponse(rGPResponse);
                tokenResponse.requestId = requestPayload.RequestId;
                tokenResponse.gatewayResponse = rGPResponse;
            }
        } catch (error) {
            this.UpdateIntegrationLogForFailure(requestId, tenderID, error);
            console.error("Error while Create Token, err:" + error.message);
            const errorRes: any = {
                status: HttpResponseStatus.Failure,
                errorMessage: error?.error?.reason
            }
            tokenResponse = errorRes;
            throw error;
        }
        return tokenResponse;
    }

    async StoreToken(storeTokenRequest: StoreTokenRequest): Promise<PaymentBaseResponse> {
        storeTokenRequest.outletId = await this.GetOutletId(storeTokenRequest.outletId);
        const response: any = await this._retailComm.postPromise<PaymentBaseResponse>({
            route: RetailRoutes.RetailStoreTokenV2,
            body: storeTokenRequest
        }, false);
        return response ?? null;
    }

    async FolioStoreToken(storeTokenRequest: StoreTokenRequest): Promise<PaymentBaseResponse> {
        storeTokenRequest.outletId = await this.GetOutletId(storeTokenRequest.outletId);
        const response: any = await this._retailComm.postPromise<PaymentBaseResponse>({
            route: RetailRoutes.FolioStoreTokenV2,
            body: storeTokenRequest
        }, false);
        return response ?? null;
    }

    GetCardInfo(tokenRefId: number): Promise<CardInfo> {
        throw new Error("Method not implemented.");
    }

    FolioGetCardInfo(tokenRefId: number): Promise<CardInfo> {
        throw new Error("Method not implemented.");
    }

    async RequestSale(payRequest: SaleRequest, outletId: number, ceds: string = ""): Promise<any> {
        let saleResponse: SaleResponse;
        let rGPsaleResponse: any;
        try {
            const response = await this._retailComm.postPromise<RGuestPayRequestDetails>({
                route: RetailRoutes.BuildRGuestPayRequest,
                body: this._reqAdapter.MapSaleRequest(payRequest, outletId)
            }, false);
            if (response) {
                let requestPayload = JSON.parse(response.requestPayload);
                payRequest.requestId = requestPayload.RequestId;
                const isProxyRequired = response?.headers?.[InvokeAgilysysPayViaWebProxy]?.toLowerCase() === 'true' ? true : false;
                if (isProxyRequired) {
                    rGPsaleResponse = await this._retailComm.postPromiseDirect<RGuestSaleResponse>({
                        route: RetailRoutes.ProxyCallToRGuestPay,
                        body: response,
                        uriParams: { proxyPaymentMethod: payRequest.paymentMethodId }
                    }, false)
                }
                else {
                    rGPsaleResponse = await this.http.post<RGuestSaleResponse>(response.url + "?skipLog=true", requestPayload).pipe(timeout(this.getCancellationTimeout(response))).toPromise();
                }

                this.UpdateIntegrationLogForSuccess(requestPayload.RequestId, payRequest.inquirerInfo.tenderId, rGPsaleResponse);
                saleResponse = this._reqAdapter.MapSaleResponse(payRequest, TransactionType.Sale, rGPsaleResponse, requestPayload);
            }
        } catch (error) {
            this.UpdateIntegrationLogForFailure(payRequest.requestId, payRequest.inquirerInfo.tenderId, error);
            console.error("Error while doing the sale by device, err:" + error.message);
            const errorRes: any = {
                status: HttpResponseStatus.Failure,
                errorMessage: error?.error?.reason
            }
            saleResponse = errorRes;
            throw error;
        }
        return saleResponse;
    }

    async RequestAuth(payRequest: SaleRequest, outletId: number, ceds: string = ""): Promise<SaleResponse> {
        let authResponse: SaleResponse;
        let rGPAuthResponse: any;
        try {
            outletId = await this.GetOutletId(outletId);
            const response = await this._retailComm.postPromise<RGuestPayRequestDetails>({
                route: RetailRoutes.BuildRGuestPayRequest,
                body: this._reqAdapter.MapAuthRequest(payRequest, outletId)
            }, false);
            if (response) {
                let requestPayload = JSON.parse(response.requestPayload);
                payRequest.requestId = requestPayload.RequestId;
                const isProxyRequired = response?.headers?.[InvokeAgilysysPayViaWebProxy]?.toLowerCase() === 'true' ? true : false;
                if (isProxyRequired) {
                    rGPAuthResponse = await this._retailComm.postPromiseDirect<RGuestSaleResponse>({
                        route: RetailRoutes.ProxyCallToRGuestPay,
                        body: response,
                        uriParams: { proxyPaymentMethod: payRequest.paymentMethodId }
                    }, false)
                }
                else {
                    rGPAuthResponse = await this.http.post<RGuestSaleResponse>(response.url + "?skipLog=true", JSON.parse(response.requestPayload)).pipe(timeout(this.getCancellationTimeout(response))).toPromise();
                }

                this.UpdateIntegrationLogForSuccess(requestPayload.RequestId, payRequest.inquirerInfo.tenderId, rGPAuthResponse);
                authResponse = this._reqAdapter.MapSaleResponse(payRequest, TransactionType.Auth, rGPAuthResponse, requestPayload);
            }
        } catch (error) {
            this.UpdateIntegrationLogForFailure(payRequest.requestId, payRequest.inquirerInfo.tenderId, error);
            console.error("Error while doing the auth by device, err:" + error.message);
            const errorRes: any = {
                status: HttpResponseStatus.Failure,
                errorMessage: error?.error?.reason
            }
            authResponse = errorRes;
            throw error;
        }
        return authResponse;
    }

    async ValidateAuth(validateAuthRequest: ValidateAuthRequest, OutletId: number, ceds: string = ""): Promise<any> {
        OutletId = await this.GetOutletId(OutletId);
        const response: any = await this._retailComm.postPromiseDirect<PaymentBaseResponse>({
            route: RetailRoutes.ValidateAuthV2,
            body: validateAuthRequest,
            uriParams: { outletId: OutletId }
        }, false);
        return response ?? null;
    }

    async FolioValidateAuth(validateAuthRequest: ValidateAuthRequest, OutletId: number, ceds: string = ""): Promise<any> {
        OutletId = await this.GetOutletId(OutletId);
        const response: any = await this._retailComm.postPromiseDirect<PaymentBaseResponse>({
            route: RetailRoutes.FolioValidateAuthV2,
            body: validateAuthRequest,
            uriParams: { outletId: OutletId }
        }, false);
        return response ?? null;
    }

    async RequestCredit(payRequest: SaleRequest, outletId: number, ceds: string = ""): Promise<any> {
        let saleResponse: SaleResponse;
        let rGPsaleResponse: RGuestSaleResponse;
        try {
            const response = await this._retailComm.postPromise<RGuestPayRequestDetails>({
                route: RetailRoutes.BuildRGuestPayRequest,
                body: this._reqAdapter.MapCreditRequest(payRequest, outletId)
            }, false);
            if (response) {
                let requestPayload = JSON.parse(response.requestPayload);
                payRequest.requestId = requestPayload.RequestId;
                const isProxyRequired = response?.headers?.[InvokeAgilysysPayViaWebProxy]?.toLowerCase() === 'true' ? true : false;
                if (isProxyRequired) {
                    rGPsaleResponse = await this._retailComm.postPromiseDirect<RGuestSaleResponse>({
                        route: RetailRoutes.ProxyCallToRGuestPay,
                        body: response,
                        uriParams: { proxyPaymentMethod: payRequest.paymentMethodId }
                    }, false)
                }
                else {
                    rGPsaleResponse = await this.http.post<RGuestSaleResponse>(response.url + "?skipLog=true", JSON.parse(response.requestPayload)).pipe(timeout(this.getCancellationTimeout(response))).toPromise();
                }

                this.UpdateIntegrationLogForSuccess(requestPayload.RequestId, payRequest.inquirerInfo.tenderId, rGPsaleResponse);
                saleResponse = this._reqAdapter.MapSaleResponse(JSON.parse(response.requestPayload), TransactionType.Credit, rGPsaleResponse, requestPayload);
            }
        } catch (error) {
            this.UpdateIntegrationLogForFailure(payRequest.requestId,payRequest.inquirerInfo.tenderId, error);
            console.error("Error while doing the credit by device, err:" + error.message);
            const errorRes: any = {
                status: HttpResponseStatus.Failure,
                errorMessage: error?.error?.reason
            }
            saleResponse = errorRes;
            throw error;
        }
        return saleResponse;
    }

    async GetDevices(request: RGuestPayRequestBuildParams) {
        let deviceList: rGuestPayDevice[] = [];
        let GetDeviceResponse: GetDeviceResponse
        try {
            let rGuestReq: RGuestPayRequestDetails;
            const outletKey = `${request.outletId}.${request.tenderId}`;
            if (this.rGuestRequestCache_Store.has(outletKey)) {
                rGuestReq = JSON.parse(this.rGuestRequestCache_Store.get(outletKey))
            } else {
                rGuestReq = await this._retailComm.postPromise<RGuestPayRequestDetails>({
                    route: RetailRoutes.BuildRGuestPayRequest,
                    body: request
                }, false);
                this.rGuestRequestCache_Store.set(outletKey, JSON.stringify(rGuestReq));
            }
            if (rGuestReq) {
                const isProxyRequired = rGuestReq?.headers?.[InvokeAgilysysPayViaWebProxy]?.toLowerCase() === 'true' ? true : false;
                if (isProxyRequired) {
                    GetDeviceResponse = await this._retailComm.postPromiseDirect<GetDeviceResponse>({
                        route: RetailRoutes.ProxyCallToRGuestPay,
                        body: rGuestReq,
                        uriParams: { proxyPaymentMethod: request.tenderId }
                    }, false)

                }
                else {
                    GetDeviceResponse = await this.http.get<GetDeviceResponse>(rGuestReq.url + "?skipLog=true").toPromise();
                }
                console.log(GetDeviceResponse);
                if (GetDeviceResponse) {                    
                    let integrationOperationsLog: IntegrationOperationsLog = {
                        route: this._router.url,
                        request: JSON.stringify(rGuestReq),
                        requestId: this._utils.generateGUID(),
                        response: JSON.stringify(GetDeviceResponse),
                        status: IntegrationOperationsStatus.Realized,
                        tenderId: Number(request.tenderId)
                    }
                    this._logService.UpsertIntergrationOPLog(integrationOperationsLog);
                    deviceList = GetDeviceResponse.devices?.filter(x => x.deviceStatus == DeviceStatus.Available);
                    console.log(deviceList);
                }
            }
        }
         catch (error) {
            console.error("Error while fetching device list, err:" + error);
            // this._utils.showError(error?.error?.errorDescription);
            throw error;
        }
        return deviceList;
    }
    

    private UpdateIntegrationLogForSuccess(requestId, tenderId, rGPsaleResponse, status = IntegrationOperationsStatus.Completed) {
        let integrationOperationsLog: IntegrationOperationsLog = {
            requestId: requestId,
            response: JSON.stringify(rGPsaleResponse),
            status: status,
            tenderId: Number(tenderId)
        }
        this._logService.UpsertIntergrationOPLog(integrationOperationsLog);
    }

    private UpdateIntegrationLogForFailure(requestId, tenderId, error) {
        let integrationOperationsLog: IntegrationOperationsLog = {
            requestId: requestId,
            response: JSON.stringify(error),
            status: IntegrationOperationsStatus.Initiated,
            tenderId: Number(tenderId)
        }
        this._logService.UpsertIntergrationOPLog(integrationOperationsLog);
    }    

}