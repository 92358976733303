import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input, ViewChild, AfterViewInit, ElementRef, Pipe, PipeTransform } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { searchtitleenum, GlobalSearchModel, GlobalSearchData, GlobalSearchTitle } from './global-search.model';
import { GlobalSearchBusiness } from './global.search.business';
import { InstructorSetupDataService } from '../../data-services/golfmanagement/instructor-setup.data.service';
import { RetailItemDataService } from '../../data-services/retailmanagement/retailitem.data.service';
import { TeeTimeDataService } from '../../data-services/golfschedule/TeeTime.data.service';
import { Router } from '@angular/router';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { LessonBookingDataService } from '../../data-services/golfschedule/lesson-booking.data.service';
import { PlayerProfileDataService } from '../../data-services/golfmanagement/PlayerProfile.data.services';
import { ContactService } from 'src/app/tee-time/tournaments/tournaments-modal/contact/contact.service';
import { GlobalSearchDataService } from './global-search.data.service';
import { GolfUtilities } from '../../utilities/golf-utilities';
import _ from 'lodash';

@Component({
  selector: 'app-golfglobal-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [GlobalSearchBusiness
    , InstructorSetupDataService
    , RetailItemDataService
    , LessonBookingDataService
    , PlayerProfileDataService
    , ContactService
    , TeeTimeDataService
    , GlobalSearchDataService
  ]
})

export class GolfGlobalSearchComponent implements OnInit, AfterViewInit {

  filterData: any = []; // local filter array
  timer = null;
  requestUid=null;
  searchGroupOptions: Promise<{isSearched: boolean, data: GlobalSearchModel[]}>;
  thumbnail = true;

  golfForm: UntypedFormGroup;
  @Input() open: boolean = false;
  @Output() onSearch = new EventEmitter();
  @Output() OnOptionSelected = new EventEmitter();
  titleEnum = searchtitleenum;
  @ViewChild("searchText") searchText: ElementRef;
  captions: any;

  constructor(private _formBuilder: UntypedFormBuilder
    , private _router: Router
    ,private _util: GolfUtilities
    , private _globalSearchBusiness: GlobalSearchBusiness
    , private _localization: GolfLocalization) { }

  ngOnInit() {

    this.captions = this._localization.captions.common;
    this.golfForm = this._formBuilder.group({
      searchGroup: '',
    });

  }

  ngAfterViewInit() {
    if (this.searchText && this.searchText.nativeElement)
      this.searchText.nativeElement.focus();
  }

  public async filterGroup(value: string) {
    this.filterData = [];
    value = value ? value.toString() : "";
    value = value.trim();
    if (value.length >= 3) {
      this.InvokeSearch(value);
    }
    else if (value.length <= 2) {
      this.filterData = [];
      this.searchGroupOptions = Promise.resolve({isSearched: false, data: []});
    }
  }
  private InvokeSearch(searchValue){
    this.requestUid = Date.now() + "" + this._util.getRandomDecimal() * 10000;
    if (this.timer) {
      clearTimeout(this.timer); //cancel the previous timer.
      this.timer = null;
    }
    this.timer = setTimeout(async (uid) =>{
      let searchGroupOptions = this._globalSearchBusiness.globalSearch(searchValue);
      this.bindRecentData(uid, searchGroupOptions);
    }, 1000, this.requestUid);
  }
  private bindRecentData(responseUid:string, searchGroupOptions:Promise<{isSearched: boolean, data: GlobalSearchModel[]}>){
    if(responseUid == this.requestUid){
      this.searchGroupOptions = searchGroupOptions;
      this.searchGroupOptions.then(x=>{
        console.log("testData",x);
      })
    }
  }
  getImgUrl(value) {
    if (value && value.length > 0 && !_.isEmpty(value[0]) && value[0].contentType) {
      // Image thumbail component will only use thumbnail data to show the images.
      // so the below line is changed to use thumbnail
      this.thumbnail = true;
      let imageUrl = `data:${value[0].contentType};base64,${value[0].thumbnailData}`;
      // this.url = this.domSanitizer.sanitize(SecurityContext.URL,this.domSanitizer.bypassSecurityTrustUrl(imageUrl));
      return imageUrl;
      
    }  else {      
        return 'assets/images/user.png';
    }
  }
  onInput(e) {
    let inputValue = e.target.value;
    if (inputValue.length > 3) {
      console.log(e.target.value);
    }
    this.onSearch.emit();
  }

  addPlayer(e) {
    var query = this._util.getRandomDecimal() * 10;
    this._router.navigate([`/guest/players/players/`], { queryParams: { action: 'add', query: query } });
  }

  filter = (opt: any[], value: string): any[] => {
    const filterValue = value.toLowerCase();
    return opt.filter(item => {
      for (var key in item) {
        if (key == searchtitleenum.region || key == searchtitleenum.code || key == searchtitleenum.course || key == searchtitleenum.item || key == searchtitleenum.timestamp) {
          let returnValue = (item[key].toLowerCase().indexOf(filterValue) === 0);
          if (returnValue) {
            return returnValue;
          }
        }
      }
    });
  };

  linkClicked(title: GlobalSearchTitle, data: GlobalSearchData, e) {
    // Random number - To refresh the page everytime
    var query = this._util.getRandomDecimal()* 10;
    switch (title.name) {
      case searchtitleenum.Instructors:
        this._router.navigate([`settings/instructorsetup/instructorsSetup`], { queryParams: { id: data.id, query: query } });
        break;
      case searchtitleenum.RetailItems:
        this._router.navigate([`/shop/viewshop/`], { queryParams: { description: data.item, id: data.id, query: query } });
        break;
      case searchtitleenum.TeeTimes:
        this._router.navigate([`tee-time/teesheet/teeSheet`], { queryParams: { id: data.id, course: data.data.courseId, date: data.data.teeTimeDate, query: query, playerId: data.data.teeTimeSchedulePlayerId, action: "FROMGLOBALSEARCH",courseName:data.data.course} });
        break;
      case searchtitleenum.PlayerName:
        this._router.navigate([`/guest/players/players/`], { queryParams: { id: data.id, query: query } });
        break;
      case searchtitleenum.Lessons:
        var date = data.data ? data.data.date : "";
        this._router.navigate([`/lessons/`], { queryParams: { id: data.id, query: query, date: date } });
        break;
      case searchtitleenum.Members:        
        break;
      default:
        break;
    }
    this.searchGroupOptions = Promise.resolve({isSearched: false, data: []});
    this.OnOptionSelected.emit();
  }

  valueMapper() {
    return '';
  }

}


@Pipe({
  name: 'highlightSearchText'
})
export class highlightSearchTextPipe implements PipeTransform {

  transform(value: string, searchtxt: string, vipType : string = ''): any {
    let newtext = value;
    searchtxt = searchtxt ? searchtxt.toString() : "";
    if (value && searchtxt) {
      let searchText = searchtxt.trim().toLowerCase();
      let text = value;
      searchText = searchText.replace(/\*/g, "/\*");
      let regex = new RegExp('(' + searchText + ')', 'ig');
      text = text.replace(regex, '<b>$&</b>');
      newtext = text;
    }
    if(vipType != null && vipType != '')
    {
      let vipIcon = "<i class='icon-vip'></i>";
      newtext = `${newtext}&nbsp;&nbsp;${vipIcon}`;
    }
    
    return newtext;
  } 

}