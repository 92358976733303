import { Component, OnInit, Output, EventEmitter, AfterViewInit, Input, ViewEncapsulation } from '@angular/core';
import { AdditionalInformationBusiness } from './additional-information.business';
import { AdditionalInformationService } from './additional-information.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { Device, CardInfo } from 'src/app/shared/payment-model';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { PaymentBusinessService } from 'src/app/shared/data-services/payment/payment-business.service';
import { GuestPolicyWrapperComponent } from './guest-policy-wrapper/guest-policy-wrapper.component';
import { GuestPolicyDetail, PolicyCategoryType, PolicyType } from 'src/app/common/shared/shared.modal';
import { ApplyPolicy } from 'src/app/common/consent-management/consent-management.model';
import { Localization } from 'src/app/common/localization/localization';
import { VIPTypeBusiness } from 'src/app/common/components/vip-type/vip-type.business';

@Component({
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [AdditionalInformationService, AdditionalInformationBusiness]
})
export class AdditionalInformationComponent implements OnInit, AfterViewInit {
  availableDevices: Device[];
  captions: any;
  additionalInfoFormGrp: UntypedFormGroup;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  selectedDevice: Device;
  displayCardInfo: CardInfo;
  cardConnectDialog: MatDialogRef<any, any>;
  IDTechCardSwipePopupClosed: boolean;
  EncryptedCardData: any;
  PaymentReferenceID: number = 0;
  guestGuidId: string = '';
  guestData: any;
  showClose: boolean = true;
  guestPolicyDetail : GuestPolicyDetail = new GuestPolicyDetail;
  allCaptions: any;
  isGuestRecordPurged: boolean = false;
  noftificationConfig : any;
  checkBoxnNotiPref: any;
  vipVal: any;
  vipType : any;
  vipTypes: any[] = [];
  floatLabel: string;

  @Input() IsGDPREnabled : boolean = false;
  @Input() policyType : number = 0;
  @Input('inputData')
  set formData(value) {
    if (value && value.id) {
      this.PaymentReferenceID = value.paymentReferenceId;
      this.guestGuidId = value.id;
      this.guestData = value;
      this.vipVal = value.vipType;
      if(this.vipVal && this.vipTypes && this.vipTypes.length > 0)
      {
        this.assignVIPType();
      }
      this.emitValueChange();
      this.isGuestRecordPurged = value?.isPurged;
    }
  }

  @Output() AdditionalInfoFormEmit = new EventEmitter();
  @Output() RemoveCard = new EventEmitter();

  constructor(private _AdditionalInformationBusiness: AdditionalInformationBusiness
    , private Form: UntypedFormBuilder
    , private _localization: GolfLocalization
    , private localization:Localization
    , private _vipTypeBusiness: VIPTypeBusiness
    , public dialog: MatDialog)
    { 
      this.floatLabel = this._localization.setFloatLabel;
    }

  ngOnInit() {
    this.captions = this._AdditionalInformationBusiness.GetCaptions();
    this.getVIPTypes();
    this.generateAdditionalInfoFormGrp();
    this.allCaptions = this.localization.captions;
    this.noftificationConfig= this.getNotificationData();
    this.checkBoxnNotiPref = this.getcheckBoxnNotiPref();
  }


  getNotificationData(){
    return [
      {
          id: 0,
          value: this.captions.dontsend,
          viewValue: this.captions.dontsend,
      },
      {
          id: 1,
          value: this.captions.viaCell,
          viewValue: this.captions.viaCell
      },
      {
          id: 2,
          value: this.captions.viaEmail,
          viewValue: this.captions.viaEmail,
      },
      {
          id: 3,
          value: this.captions.sendToall,
          viewValue: this.captions.sendToall
      },
  ]
  }
  getcheckBoxnNotiPref(){
    return [
      {
          name: this.captions.profileForMarketing,
          control : 'profileForMarketing',
          isSelected: false,
      },
      {
          name: this.captions.shareProfile,
          control : 'shareProfile',
          isSelected: false,
      }
  ]
  }

  async getVIPTypes()
  {
    await this._vipTypeBusiness.getTableContent(false).then(res=>
    {
      this.vipTypes = res;
      this.assignVIPType();
    });
  }
  assignVIPType()
  {
    this.vipType = this.vipTypes.find(x=> x. code == this.vipVal);
    this.additionalInfoFormGrp.controls.vipType.setValue(this.vipType);
  }

  comparetSelects = (val1, val2) => {
    return val1 && val2 && val1.id === val2.id;
  }

  SaveReferenceId(event: number) {
    this.additionalInfoFormGrp.markAsDirty();
    this.additionalInfoFormGrp.markAsTouched();
    this.PaymentReferenceID = event;
    this.additionalInfoFormGrp.controls.tokenReferenceId.setValue(event);
  }

  RemoveCardDetail(event){
    this.additionalInfoFormGrp.markAsDirty();
    this.additionalInfoFormGrp.markAsTouched();
    this.PaymentReferenceID = 0;
    this.additionalInfoFormGrp.controls.tokenReferenceId.setValue(0);
    this.RemoveCard.emit(event);
  }

  ngAfterViewInit()
  {
    this.additionalInfoFormGrp.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      this.vipType = this.additionalInfoFormGrp.controls['vipType'].value;
      this.emitValueChange();
    });
  }

  generateAdditionalInfoFormGrp() {
    this.additionalInfoFormGrp = this.Form.group({
      vipType: '',
      tokenReferenceId: [''],
      // notification : 0,
      // profileForMarketing: '',
      // shareProfile :''
    });
    if(this.isGuestRecordPurged){
      this.additionalInfoFormGrp.disable();
    }
  }

  closeWarning(){
    this.showClose = false;
  }

  emitValueChange(guestPolicyDetail : ApplyPolicy = null) {
    let additionalInfoFormData = { 
      paymentReferenceID : this.PaymentReferenceID,
      guestPolicyDetail : guestPolicyDetail,
      vipType : this.vipType != undefined &&  this.vipType != '' ? this.vipType.code : null
    }
    this.AdditionalInfoFormEmit.emit([additionalInfoFormData, true, 'additionalInfo', this.additionalInfoFormGrp]);
  }
  openGuestPolicyDialog(popupType) {
    this.guestPolicyDetail.id = this.guestData ? this.guestData.id : this.guestPolicyDetail.id;
    this.guestPolicyDetail.lastName = this.guestData ? this.guestData.lastName : this.guestPolicyDetail.lastName;
    this.guestPolicyDetail.consentDate = this.guestData ? this.guestData.consent : this.guestPolicyDetail.consentDate,
    this.guestPolicyDetail.consentExpiryDate = this.guestData? this.guestData.consentExpiryDate : this.guestPolicyDetail.consentExpiryDate,
    this.guestPolicyDetail.consentPolicyId = this.guestData? this.guestData.consentPolicyId : this.guestPolicyDetail.consentPolicyId,
    this.guestPolicyDetail.comments = "",
    this.guestPolicyDetail.isPurged=this.guestData? this.guestData.isPurged : this.guestPolicyDetail.isPurged,
    this.guestPolicyDetail.email = this.guestData ? this.guestData.email : this.guestPolicyDetail.email;
    this.guestPolicyDetail.phone = this.guestData ? this.guestData.phone : this.guestPolicyDetail.phone;
    this.guestPolicyDetail.policyCategoryType = PolicyCategoryType.Guest
    this.dialog.open(GuestPolicyWrapperComponent, {
      width: popupType== 3 ?'40%' : '80%',
      height: popupType== 3 ?'35%' : '80%',
      disableClose: true,
      data: {
        guestPolicyDetail : this.guestPolicyDetail,
        popupType: popupType
      },
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if(res != undefined && popupType == 1)
      {
        let guestPolicyDetail : ApplyPolicy = res as ApplyPolicy
        this.policyType = PolicyType.ConsentPolicy;
        this.guestPolicyDetail.consentPolicyId = guestPolicyDetail.policyId;
        this.guestPolicyDetail.consentDate = new Date(guestPolicyDetail.consentDate);
        this.guestPolicyDetail.consentExpiryDate = guestPolicyDetail.consentExpiryDate ? new Date(guestPolicyDetail.consentExpiryDate) : null;
        this.guestPolicyDetail.id = guestPolicyDetail.guestId;
        if(this.guestData)
        {
          this.guestData.consent = this._localization.convertDateTimeToAPIDateTimeSec(new Date(guestPolicyDetail.consentDate));
          this.guestData.consentExpiryDate = guestPolicyDetail.consentExpiryDate ?  this._localization.convertDateTimeToAPIDateTimeSec(new Date(guestPolicyDetail.consentExpiryDate)): null;
          this.guestData.consentPolicyId = guestPolicyDetail.policyId;
          return;
        }
        this.emitValueChange(guestPolicyDetail);
      }
      else if(res != undefined && popupType == 2 )
      {
        this.dialog.closeAll();
      }
    });
  }
}
