<div class="cancellation-policy-wrapper newVersionWrapper">
    <div class="cancellation-formwrapper">
        <form class="ag_container--padding-md" [formGroup]="cancellationForm" autocomplete="off">
            <div class="basic-details bodyWrap">
                <h4 class="ag_group--headers">{{captions.lbl_basicDetails}}</h4>
                <div class="ag_display--flex">
                    <ag-textbox class="ag_form-control--1" [config]="policyCodeConfig"></ag-textbox>
                    <ag-textbox class="ag_form-control--1" [config]="policyNameConfig"></ag-textbox>
                    <!-- <ag-textbox class="ag_form-control--2" [config]="policyDescriptionConfig"></ag-textbox> -->
                    <mat-form-field [floatLabel]="floatLabel" class="txt-area-height ag_w--33 ag_mb--4">
                        <textarea matInput formControlName="policyDescription" [placeholder]="captions.policyDescription"
                            [maxLength]="200" row="6" [attr.automationId]="'Cancellationwrapper_policydescription_comments'"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="ag_display--flex policy-type-sect">
                <div class="ag_form-control--1">
                    <label class="text-label-new mb-1">{{captions.lbl_policyType}}</label>
                    <mat-button-toggle-group attr.automationId="'Tog_cancellationPolicy_policyType'" class="matGroup w-100" formControlName='policyType' (change)="policyTypeChange($event)">
                        <mat-button-toggle class="toggle w-50" [value]='PolicyTypeEnum.Cancellation'>{{captions.lbl_cancellation}}
                        </mat-button-toggle>
                        <mat-button-toggle class="toggle w-50" [value]='PolicyTypeEnum.NoShow'>{{captions.lbl_noShow}}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <div>
                    <label class="ag_display--block text-label-new mb-1">{{captions.lbl_activePolicy}}</label>
                    <app-toggle-switch *ngIf = "isCancellationTabSelected" [attr.automationId]="'Tog_cancellationPolicy_active'"
                    formControlName="isCancellationActive" (changeToggleEvent)="onActiveChange($event, true)">
                    </app-toggle-switch>
                    <app-toggle-switch *ngIf = "!isCancellationTabSelected" [attr.automationId]="'Tog_cancellationPolicy_active'"
                    formControlName="isNoShowActive" (changeToggleEvent)="onActiveChange($event, false)">
                    </app-toggle-switch>
                </div>
            </div>
            <div class="charge-setup bodyWrap">
                <div class="align-items-center">
                    <h4 class="ag_group--headers">{{captions.lbl_chargeSetup}}</h4>
                    <div class="ag_display--flex align-items-baseline">
                        <app-ag-dropdown *ngIf = "isCancellationTabSelected" class="dropdownmenu ag_form-control--1" [inputs]="cancellationDropDownInput">
                        </app-ag-dropdown>
                        <app-ag-dropdown *ngIf = "!isCancellationTabSelected"class="dropdownmenu ag_form-control--1" [inputs]="noShowDropDownInput">
                        </app-ag-dropdown>
                        <app-button *ngIf="isCancellationTabSelected" class="add-charge-btn ag_ml--auto" [attr.automationId]="'Btn_cancellationPolicy_addCharge'" [buttontype]="addChargeButton" (valueChange)='addCharge($event)'></app-button>
                    </div>
                </div>
                <div class="cancellation-sect" *ngIf="isCancellationTabSelected">
                    <div>
                        <table aria-describedby="" class="rightTable w-100" [attr.automationId]="'Tbl_SpaPackage_table'">
                            <thead>
                              <tr>
                                <th  scope='col'>
                                  <label>{{captions.lbl_applyWithin}}</label>
                                </th>
                                <th  scope='col'>
                                  <label>{{captions.applywithinHours}}</label>
                                </th>
                                <th  scope='col'>
                                  <label>{{captions.lbl_CancellationNoShowPrice}}</label>
                                </th>
                                <th  scope='col'>
                                  <label>{{captions.lbl_percentAmount}}</label>
                                </th>
                                <th  scope='col'>
                                  <label>{{captions.lbl_charge}}</label>
                                </th>
                                <th  scope='col'>
                                  <label>{{captions.lbl_actions}}</label>
                                </th>
                              </tr>
                            </thead>
                            <tbody formArrayName="charges">
                                <tr *ngFor="let array of cancellationForm.get('charges')['controls']; let i = index;let first = first;let last = last" [formGroupName]="i" [ngClass]="{'borderBtm' : !last}">
                                    <td class="pl-2">
                                        <mat-button-toggle-group attr.automationId="'Tog_cancellationPolicy_applyWithin'" class="matGroup mb-0" formControlName='applyWithin' (change)="cancellationApplyWithinChange(i)">
                                            <mat-button-toggle class="toggle" [value]='ApplyWithinEnum.Days'>{{captions.lbl_days}}
                                            </mat-button-toggle>
                                            <mat-button-toggle class="toggle" [value]='ApplyWithinEnum.Hours'>{{captions.lbl_hours}}
                                            </mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </td>
                                    <td>
                                        <mat-form-field [floatLabel]="floatLabel" class="ag_w--70" [ngClass] = "{'mandatoryError' : (cancellationForm.get('charges').value | duplicate: i)}">
                                            <input type="number" autocomplete="off" [attr.automationId]="'Txt_cancellationPolicy_applyWithinHours'" matInput formControlName="applyWithinHoursDays" 
                                            inputtype = "nodecimal, nonnegative" 
                                            (keydown)="restrictApplyWithinLength($event)" numMaxlength maxLength="3" max="999"> 
                                        </mat-form-field> 
                                    </td>
                                    <td>
                                        <mat-button-toggle-group attr.automationId="'Tog_cancellationPolicy_teetimePrice'" (change)="teeTimeSelectionChange($event,i)" class="matGroup mb-0 w-80" formControlName='priceType'>
                                            <mat-button-toggle class="toggle w-50" [value]='TeeTimePriceEnum.FullPrice'>{{captions.lbl_fullPrice}}
                                            </mat-button-toggle>
                                            <mat-button-toggle class="toggle w-50" [value]='TeeTimePriceEnum.CustomPrice'>{{captions.lbl_customPrice}}
                                            </mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </td>
                                    <td>
                                        <mat-button-toggle-group [attr.automationId]="'Tog_cancellationPolicy_percentAmount'" class="matGroup mb-0" formControlName='chargeType'
                                        [ngClass]="{'ag_section--disable': array.controls.priceType.value == TeeTimePriceEnum.FullPrice}" (change)="cancellationPriceTypeChange(i)">
                                            <mat-button-toggle class="toggle" [value]='ChargeTypeEnum.Percentage'><span class="icon-percent"></span>
                                            </mat-button-toggle>
                                            <mat-button-toggle class="toggle" [value]='ChargeTypeEnum.Amount'><span class="icon-Flat-Rate"></span>
                                            </mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </td>
                                    <td>
                                        <mat-form-field [floatLabel]="floatLabel" class="matIcon" *ngIf="array.controls.chargeType.value == ChargeTypeEnum.Percentage"
                                        [ngClass]="{'ag_section--disable': array.controls.priceType.value == TeeTimePriceEnum.FullPrice}">
                                            <input autocomplete="off" CurrencyFormatter [attr.automationId]="'Txt_cancellationPolicy_percent'" matInput formControlName="charge">
                                            <i aria-hidden="true" class="icon-percent"></i>    
                                        </mat-form-field> 
                                        <mat-form-field [floatLabel]="floatLabel" class="matIcon" *ngIf="array.controls.chargeType.value == ChargeTypeEnum.Amount"
                                        [ngClass]="{'ag_section--disable': array.controls.priceType.value == TeeTimePriceEnum.FullPrice}">
                                            <input autocomplete="off" [attr.automationId]="'Txt_cancellationPolicy_amount'" matInput formControlName="charge" CurrencyFormatter>
                                            <i aria-hidden="true" class="icon-Flat-Rate"></i>     
                                        </mat-form-field> 
                                    </td>
                                    <td>
                                        <i [matTooltip]="captions.lbl_delete" [ngClass]="{'ag_section--disable':isViewOnly || cancellationForm.get('charges')['controls'].length == 1 || !cancellationForm.controls.isCancellationActive.value}" class="icon-Group-140603 cursor font-weight-bold" (click)="onDeleteRow(i)"></i>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="no-show ag_display--flex align-items-center" *ngIf="!isCancellationTabSelected">
                    <div class="ag_form-control--1">
                        <label class="text-label-new mb-1">{{captions.lbl_CancellationNoShowPrice}}</label>
                        <mat-button-toggle-group attr.automationId="'Tog_cancellationPolicy_teetimePrice'" class="matGroup w-100" formControlName='noShowPriceType'>
                            <mat-button-toggle class="toggle w-50" [value]='TeeTimePriceEnum.FullPrice'>{{captions.lbl_fullPrice}}
                            </mat-button-toggle>
                            <mat-button-toggle class="toggle w-50" [value]='TeeTimePriceEnum.CustomPrice'>{{captions.lbl_customPrice}}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div class="ag_form-control--1" [ngClass]="{'ag_section--disable' :cancellationForm.controls.noShowPriceType.value==TeeTimePriceEnum.FullPrice }">
                        <label class="text-label-new mb-1">{{captions.lbl_percentAmount}}</label>
                        <mat-button-toggle-group [attr.automationId]="'Tog_cancellationPolicy_percentAmount'" class="matGroup w-100" formControlName='noShowChargeType'  (change)="noShowPriceTypeChange()">
                            <mat-button-toggle class="toggle w-50" [value]='ChargeTypeEnum.Percentage'><span class="icon-percent"></span>
                            </mat-button-toggle>
                            <mat-button-toggle class="toggle w-50" [value]='ChargeTypeEnum.Amount'><span class="icon-Flat-Rate"></span>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--1 matIcon" *ngIf="cancellationForm.controls.noShowChargeType.value==ChargeTypeEnum.Percentage" [ngClass]="{'ag_section--disable' :cancellationForm.controls.noShowPriceType.value==TeeTimePriceEnum.FullPrice }">
                        <input autocomplete="off" (input)="checkValue()" [placeholder]="captions.lbl_charge" CurrencyFormatter [attr.automationId]="'Txt_cancellationPolicy_percent'" matInput formControlName="noShowCharge">
                        <i aria-hidden="true" class="icon-percent"></i>    
                    </mat-form-field> 
                    <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--1 matIcon" *ngIf="cancellationForm.controls.noShowChargeType.value==ChargeTypeEnum.Amount" [ngClass]="{'ag_section--disable' :cancellationForm.controls.noShowPriceType.value==TeeTimePriceEnum.FullPrice }">
                        <input autocomplete="off" (input)="checkValue()" [placeholder]="captions.lbl_charge"  [attr.automationId]="'Txt_cancellationPolicy_amount'" matInput formControlName="noShowCharge" CurrencyFormatter>
                        <i aria-hidden="true" class="icon-Flat-Rate"></i>     
                    </mat-form-field> 
                </div>
            </div>
        </form>
    </div>
    <div class="ag_footer--actions">
        <app-button [attr.automationId]="'Btn_cancellationPolicy_action'" [buttontype]="actionButton" (valueChange)='onAction($event)'></app-button>
        <app-button [attr.automationId]="'Btn_cancellationPolicy_cancel'" [buttontype]="cancelButton" class="ag-ml-2" (valueChange)='onCancel($event)'></app-button>
    </div>
</div>