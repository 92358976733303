export namespace UI {
    export interface Template {
      checked: boolean;
      code: string;
      rcode: string;
      type: number;
      name: string;
      templateData: string;
      active: boolean;
      listOrder: number;
      id: number;
      value: string;
      default:boolean;
    }
    export interface Letter {
      id: number;
      cardCode: string;
      cardName: string;
      dateOfGenerated: string;
      date: string;
  
    }
  
  }
export namespace API {
    export interface GuestSearchFields {
      lastName: string;
      firstName: string;
      cityName: string;
      stateCode: string;
      postalCode: string;
      stateName: string;
      county: string;
      countryName: string;
      nationality: string;
      phone: string;
      socialSecurityNumber: string;
      emailAddress: string;
      driversLicense: string;
      playerId: string;
      memberTypeId: number;
      includeInactive: boolean;
      initial :string;
      PMSGuestSearchCriteria: PMSSearchCriteria;
      AdditionalSearchCriteria  : GuestAdditionalSearchInfo;
    }
    export interface GuestAdditionalSearchInfo {
      companyName: string;
        guestID: string;
        clubMember: string;
        memberNumber: string;
        memberType: string;
        memberCategory: string;
        ownerRoomNo: string;
        ownerContractNo: string;
        ownerARNo: string;
        createdOn: string;
        updatedOn: string;
    }
    export interface PMSSearchCriteria {
      guestTypeId: number;
      originId: number;
      segment1Id: number;
      segment2Id: number;
      marketChannelId: number;
      aRNumber: string;
      accountNumber: string;
      preferenceCategoryId:number;
    }
    export interface Guest {
      guestId?: string;
      lastChangeId?: string;
      pmsGuest: {
        guestId?: string,
        isCondoOwner: boolean,
        vipTypeId: number,
        guestTypeId: number,
        originId: number,
        segment1Id: number,
        segment2Id: number,
        marketChannelId: number,
        guestPreference: {
          guestId: number,
          notificationMethod: number,
          isNotifyStaffOnArrival: boolean,
          isNotifyVIPArrival: boolean,
          guestSpecialRequirementId: number,
          preferredLanguageId: number,
          isRequestSmoking: boolean,
          preferenceList: guestpreference[],
          id: number
        },
        accountNumber: string,
        isRegFlagComment: boolean,
        comment: string,
        arAccount: string,
        paymentTypeId: number,
        paymentTransactionId: number,
        createdDTM?: Date,
        createdBy?: number,
        modifiedDTM?: Date,
        modifiedBy?: number,
        id: number
      };
      lastName: string;
      firstName: string;
      initial: string;
      title: string;
      companyName: string;
      alias: string;
      pronounced: string;
      guestProfileAddress: [
        {
          addressLine1: string,
          addressLine2: string,
          addressLine3: string,
          city: string,
          state: string,
          country: string,
          county: string,
          postalCode: string,
          isPrivate: boolean,
          platformAddressUuid?: string;
        }
      ];
      guestProfileContact: GuestProfileContact[];
      dateOfBirth?: Date | string,
      fax: string,
      gender: string,
      jobTitle: string,
      nationality: string,
      passportNumber: string,
      socialSecurityNumber: string,
      driversLicense: string,
      patronId: string,
      rank: string,
      clubCardNumber: string,
      memberNumber: string,
      memberFamilyType: string,
      primaryMembership: string,
      id: number,
      anniversaryDate?: Date | string
      ,paymentMethodId: number
      ,guestProfileCreditCardDetail: guestCreditCardDetail [];
      //   {
      //     tokenTransId: number,
      //     isActive: boolean,
      //     isPrimary: boolean;
      //   }
      // ];
    }
  
    export interface guestCreditCardDetail{
      tokenTransId: number,
      isActive: boolean,
      isPrimary: boolean;
    }
    
    export interface GuestProfileContact {
      playerId?: number;
      type: number;
      name: string;
      description: string;
      value: string;
      isPrivate: boolean;
      isPrimary: boolean;
      platformContactUuid?: string;
    }
  
  
    export interface guestpreference {
      sourceType: number;
      sourceRefId: number;
      preferenceId: number;
      id: number;
    }
  
    export interface UpdateAdditionalNamesRequest{
      stayGuestOccupancyInfo: StayGuestOccupanyInfo[];
      ageCategoryCountInfo: AgeCategoryCountInfo[];
    }
    
    export interface AgeCategoryCountInfo {
      ageCategoryId: AgeCategory;
      guestCount: number;
    }
  
    export enum AgeCategory { // TODO need to change to Category 1 2 3
        Youth = 1,
        Child = 2,
        Kid = 3,
        Infant = 4,
        Adult = 5,
        Senior = 6
      }
      
    export interface StayGuestOccupanyInfo {
      id:number;
      stayId: number;
      guestId: string;
      addToGuestCount: boolean;
      emailId: string;
      phoneNumber: string;
      firstName: string;
      lastName: string;    
      walkInDate: string;
      walkOutDate: string;
      guestInformationId : number;
      ageCategoryId : number;
    }
    export interface StayGuestActivityInfo {
      id:number;
      confirmationNumber :string;
      stayId: number;
      firstName: string;
      lastName: string;    
      guestId: string;
      walkInDate: string;
      walkOutDate: string;
    }
  
    export interface StayInfo {
      reservationId: number;
      stayId: number;
      arrivalDate: Date;
      departureDate: Date;
      primaryGuestId: string;
      guestCount: string;
      roomType: number;
      numberOfNights:number;
      roomCount : number;
      status: number,
      confirmationNumber: string;
      stayRoomOccupancyInfo: StayRoomOccupancyInfo[];
    }

    export interface StayRoomOccupancyInfo {
        id: number;
        stayRoomInfoId: number;
        ageCategoryId: AgeCategory;
        guestCount: number;
      }
      
  
  }