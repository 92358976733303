<div class="ag_display--flex server-paginator--wrapper">
    <div class="items-per-page ag_mr--4">
      <label class="ag_mr--4 font-color">Items per page</label>
      <mat-form-field class="pagesize">
        <mat-select [(ngModel)]="serverPageSize"
          (selectionChange)="pageSizeChange($event)">
          <mat-option [value]="5">5</mat-option>
          <mat-option [value]="10">10</mat-option>
          <mat-option [value]="15">15</mat-option>
          <mat-option [value]="20">20</mat-option>
          <mat-option [value]="50">50</mat-option>
          <mat-option [value]="100">100</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="ag_mr--4 font-color" *ngIf="totalRecordsCount > 0">
      {{from}} to {{(to > totalRecordsCount ) ? totalRecordsCount : to  }} of {{totalRecordsCount}}
    </div>
    <div class="ag_mr--4 font-color" *ngIf="totalRecordsCount === 0">
      {{totalRecordsCount}} of {{totalRecordsCount}}
    </div>
    <div>
      <span class="ag_mr--4" [ngClass]="{'disable-button': isFirst, 'ag_cursor--pointer': !isFirst}" (click)="first()"><span class="icon-Previous"></span></span>
      <span class="ag_mr--4" [ngClass]="{'disable-button': isFirst, 'ag_cursor--pointer': !isFirst}" (click)="previous()"><span class="icon-right-arrow1"></span></span>
      <span class="ag_mr--4" [ngClass]="{'disable-button': isLast, 'ag_cursor--pointer': !isLast}" (click)="next()"><span class="icon-left-arrow1"></span></span>
      <span class="ag_mr--4" [ngClass]="{'disable-button': isLast, 'ag_cursor--pointer': !isLast}" (click)="last()"><span class="icon-Last"></span></span>
    </div>
  </div>