import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommissionDetails, BaseResponse, popupConfig } from '../shared.modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpServiceCall, HttpMethod } from '../service/http-call.service';
import { Host, Maxlength, RetailFunctionalities } from '../globalsContant';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { ViewSettingClientBusiness } from '../business/view-settings.business';
import { CommissionSetupService } from '../../commission-setup/commission-setup.service';
import { CommissionDetailsUI, CommissionSetupRequest } from '../../commission-setup/commission-setup.modals';
import { cloneDeep } from 'lodash';
import { RetailUtilities } from '../utilities/retail-utilities';
import { RETAILSETUP } from '../../retail.modals';
import { AlertMessagePopupComponent } from '../alert-message-popup/alert-message-popup.component';

@Component({
  selector: 'app-retail-commission-values',
  templateUrl: './commission-values.component.html',
  styleUrls: ['./commission-values.component.scss']
})
export class RetailCommissionValuesComponent implements OnInit {
  @Input() dataInput;
  @Input() popupConfigs: popupConfig;
  clickbutton: string;
  FormGrp: UntypedFormGroup;
  localize: any;
  captions: any;
  fromScreen: string;
  private commissionData: any;
  commissionMaxLength = Maxlength.PERCENTAGE;
  currentcommissionId = 0;
  currentGridIndex = 0;
  inputData : any;
  showOutletsSelection: boolean;
  functionalities: { [key: string]: boolean} = {};
  recordsPerAPICall = 20;
  floatLabel: string;
  @Input() throttleTime: number = 3000;



  constructor(public dialog: MatDialog, 
    public dialogRef: MatDialogRef<RetailCommissionValuesComponent>,
    private Form: UntypedFormBuilder,
    private http: HttpServiceCall,
    public localization: RetailLocalization, private vsBusiness: ViewSettingClientBusiness,
    private utilities: RetailUtilities, private commissionService: CommissionSetupService) {
    this.FormGrp = this.Form.group({
      itemDescription: ['', Validators.required],
      locationDescription: ['', Validators.required],
      threshold: ['', Validators.required],
      flatAmount: ['', Validators.required],
      percentage: ['', Validators.required],
      commissionon: ['', Validators.required],
      isAfterDiscount: ['', Validators.required]
    });
    this.captions = this.localization.captions;
    this.vsBusiness.activeFormGroup = this.FormGrp;
    this.floatLabel = this.localization.setFloatLabel;
  }
  ngOnInit() {
    this.currentGridIndex = this.commissionService.commissionGridData.indexOf(this.dataInput);
    this.inputData = cloneDeep(this.dataInput);
    if(this.popupConfigs.operation == "new"){
      this.clickbutton =  this.captions.setting.save;      
      if(this.inputData){
      this.inputData.id = 0;
      this.inputData.flatAmount = 0.00;
      this.inputData.threshold = 0.00;
      this.inputData.percentage = 0.00;
      }
    }
    else{
      this.clickbutton =  this.captions.setting.update;
    }   
    this.fromScreen = this.popupConfigs.fromScreen;
    if (typeof this.inputData !== 'undefined') {
      this.inputData.flatAmount = parseFloat(this.inputData.flatAmount as string);
      this.inputData.threshold = parseFloat(this.inputData.threshold as string);
      this.inputData.percentage = parseFloat(this.inputData.percentage as string);
      this.FormGrp.patchValue(this.inputData);
      this.commissionData = this.inputData as CommissionDetailsUI;
      this.currentcommissionId = this.inputData.id;
    }
    this.showOutletsSelection = this.functionalities[RetailFunctionalities.ShowOutletSelectionFieldInCommisionSetupScreen] || false;
  }
  cancel(): void {
    this.vsBusiness.promptUserDialog(this.dialogRef);
  }

  async UpdateCommissions() {
    let bodyObj: CommissionDetails;
    const bodyObjArr: CommissionDetails[] = [];

    let flatAmount: any = this.FormGrp.controls["flatAmount"].value;
    let percentage: any = this.FormGrp.controls["percentage"].value;
    let threshold: any = this.FormGrp.controls["threshold"].value;


    const isDuplicateRecord = await this.CheckCommissionSetupDuplicateData(this.commissionData.itemId, this.commissionData.outletId, flatAmount, percentage, threshold);
     
    if(!isDuplicateRecord){
      bodyObj = {
        id: this.commissionData.id,
        itemId: this.commissionData.itemId,
        categoryId: this.commissionData.categoryId,
        itemDescription: this.commissionData.itemDescription,
        locationDescription: this.commissionData.locationDescription,
        classId: this.commissionData.classId,
        flatAmount: isNaN(flatAmount) ? this.localization.currencyToSQLFormat(flatAmount) : flatAmount,
        percentage: isNaN(percentage) ? this.localization.currencyToSQLFormat(percentage) : percentage,
        threshold: isNaN(threshold) ? this.localization.currencyToSQLFormat(threshold) : threshold,
        isAfterDiscount: this.FormGrp.controls["isAfterDiscount"].value,
        isTotalRevenue: this.FormGrp.controls["commissionon"].value == this.captions.setting.Revenue ? true : false,
        isItemOnly: this.FormGrp.controls["commissionon"].value == this.captions.setting.Items ? true : false,
        productId: Number(this.utilities.GetPropertyInfo('ProductId')),
        outletId: this.commissionData.outletId
      };
  
      bodyObjArr.push(bodyObj);
      if (this.fromScreen == 'commission') {
        this.http.CallApiWithCallback<any[]>({
          host: Host.commission,
          success: this.successCallback.bind(this),
          error: this.errorCallback.bind(this),
          callDesc: "UpdateCommission",
          uriParams: { classId: bodyObj.classId },
          method: HttpMethod.Put,
          body: bodyObjArr,
          showError: true,
          extraParams: []
        });
      } else if (this.fromScreen == 'THERAPIST'|| this.fromScreen === RETAILSETUP) {
        let gridData: CommissionDetailsUI = {
          id: this.currentcommissionId,
          itemId: this.inputData.itemId,
          classId: this.commissionData.classId,
          itemDescription: this.inputData.itemDescription,
          isAfterDiscount: bodyObj.isAfterDiscount,
          isItemOnly: bodyObj.isItemOnly,
          flatAmount: bodyObj.flatAmount,
          threshold: bodyObj.threshold,
          outletId: this.inputData.outletId,
          locationDescription: this.inputData.locationDescription,
          categoryId: this.inputData.categoryId,
          commissionon: bodyObj.isItemOnly ? this.captions.setting.Items : this.captions.setting.Revenue,
          percentage: bodyObj.percentage,
          isTotalRevenue: bodyObj.isTotalRevenue,
          staffId: this.inputData.staffId,
          staffType: this.inputData.staffType,
          isModified : true
        }
        if (this.popupConfigs.operation == "new") {
          this.commissionService.commissionGridData.splice(this.currentGridIndex, 0, gridData);
        } else {
          this.commissionService.commissionGridData.splice(this.currentGridIndex, 1, gridData);
         }
        this.dialogRef.close({ action: this.popupConfigs.operation, result: bodyObj });
      }
    } 
    else{
      this.openAlertPopup(this.captions.common.duplicateCommissionRecord, this.captions.common.OK, false);
    } 
    
  }

  KeyDownEvent(e, type) {
    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) { // reset only when entered values is numeric
      let defaultvalue = this.localization.localizeCurrency("0", false);
      if (type == "flat") {
        this.FormGrp.get('percentage').setValue(defaultvalue);
      } else if (type == "percentage") {
        this.FormGrp.get('flatAmount').setValue(defaultvalue);
      }
    }
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams?: any[]): void {
    if (callDesc == "UpdateCommission") {
      let response: any = result.result;
      if (response && response.length > 0 && this.currentGridIndex > -1) {
        let updatedRecord = response[0];
        if (this.popupConfigs.operation != "new") {
          let currentRow = this.commissionService.commissionGridData[this.currentGridIndex];
          currentRow = {
            id: updatedRecord.id,
            itemId: updatedRecord.itemId,
            classId: updatedRecord.classId,
            itemDescription: currentRow.itemDescription,
            isAfterDiscount: updatedRecord.isAfterDiscount,
            isItemOnly: updatedRecord.isItemOnly,
            flatAmount: updatedRecord.flatAmount,
            threshold: updatedRecord.threshold,
            outletId: updatedRecord.outletId,
            locationDescription: currentRow.locationDescription,
            categoryId: currentRow.categoryId,
            commissionon: updatedRecord.isItemOnly ? this.captions.setting.Items : this.captions.setting.Revenue,
            percentage: updatedRecord.percentage,
            isTotalRevenue: updatedRecord.isTotalRevenue,
            staffId: updatedRecord.staffId,
            staffType: updatedRecord.staffType,
            isModified : false
          }
          this.commissionService.commissionGridData.splice(this.currentGridIndex, 1, currentRow);
        }
        else { // Add a new row
          let gridData: CommissionDetailsUI = {
            id: updatedRecord.id,
            itemId: updatedRecord.itemId,
            classId: updatedRecord.classId,
            itemDescription: this.inputData.itemDescription,
            isAfterDiscount: updatedRecord.isAfterDiscount,
            isItemOnly: updatedRecord.isItemOnly,
            flatAmount: updatedRecord.flatAmount,
            threshold: updatedRecord.threshold,
            outletId: updatedRecord.outletId,
            locationDescription: this.inputData.locationDescription,
            categoryId: this.inputData.categoryId,
            commissionon: updatedRecord.isItemOnly ? this.captions.setting.Items : this.captions.setting.Revenue,
            percentage: updatedRecord.percentage,
            isTotalRevenue: updatedRecord.isTotalRevenue,
            staffId: this.inputData.staffId,
            staffType: this.inputData.staffType,
            isModified : false
          }
          this.commissionService.commissionGridData.splice(this.currentGridIndex, 0, gridData);
        }
      }
      this.dialogRef.close(response);
    }
  }
  errorCallback() {
    console.error('error');
   }

   openAlertPopup(headerMessage: string, buttonName: string, noButton: boolean) {
    return this.dialog.open(AlertMessagePopupComponent, {
      width: '305px',
      height: '350px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: {
        headername: this.captions.common.Warning, headerIcon: 'icon-warning-icon', headerMessage: headerMessage, buttonName: buttonName, noButton: noButton, noButtonName: this.captions.common.No, type: 'message'
      },
      disableClose: true,
    }); 
  }

  async CheckCommissionSetupDuplicateData(itemID, outletId, flatAmount, percentage, threshold): Promise<Boolean> {
    let isDuplicate: boolean = false;
    if(this.fromScreen === RETAILSETUP){
      flatAmount = isNaN(flatAmount) ? this.localization.currencyToSQLFormat(flatAmount) : flatAmount;
      percentage = isNaN(percentage) ? this.localization.currencyToSQLFormat(percentage) : percentage;
      threshold  = isNaN(threshold)  ? this.localization.currencyToSQLFormat(threshold)  : threshold;
      const response = this.commissionService.commissionGridData;
     
      const isAfterDiscountFrm = this.FormGrp.controls["isAfterDiscount"].value;
      const isItemOnlyFrm = this.FormGrp.controls["commissionon"].value == this.captions.setting.Items ? true : false;

      if(response && response.length > 0){
        if(this.popupConfigs.operation == "new" && response.filter(s => s.itemId == this.commissionData.itemId && s.outletId == outletId && s.threshold == threshold).length > 0){
          isDuplicate = true; 
        }
        else{          
          if(response.filter(s =>  s.itemId == this.commissionData.itemId && s.outletId == outletId && s.threshold == threshold && s.isItemOnly == isItemOnlyFrm).length > 1){
            isDuplicate = true;
          }
          else{
            if(response.some(s =>  s.itemId == this.commissionData.itemId && s.outletId == outletId && s.flatAmount == flatAmount && s.percentage == percentage && s.isItemOnly == isItemOnlyFrm
               && s.isAfterDiscount === isAfterDiscountFrm )){
              isDuplicate = true;
            }
          }
        }
      }
    }
    else if(this.fromScreen == 'commission'){
      try {
        let body: any = {
          recordsToSkip: 0,
          recordsToFetch: this.recordsPerAPICall,
          classId: String(this.commissionData.classId),
          outletId: [this.inputData.outletId],
          categoryId: [],
          searchText: '',
          flatAmount: null,//isNaN(flatAmount) ? this.localization.currencyToSQLFormat(flatAmount) : flatAmount,
          percentage: null,//isNaN(percentage) ? this.localization.currencyToSQLFormat(percentage) : percentage,
          threshold: null,//isNaN(threshold) ? this.localization.currencyToSQLFormat(threshold) : threshold,
          commissionOn: 0,
          afterDiscount: 0,
          sortBy: 0,
          sortOrder: 0,
          staffId: 0,
          staffType:  null,
          itemId: [this.commissionData.itemId],
          onlyCommissionable: true
        };
        let response = await this.commissionService.getCommissionSetup(body);
        flatAmount = isNaN(flatAmount) ? this.localization.currencyToSQLFormat(flatAmount) : flatAmount;
        percentage = isNaN(percentage) ? this.localization.currencyToSQLFormat(percentage) : percentage;
        threshold  = isNaN(threshold)  ? this.localization.currencyToSQLFormat(threshold)  : threshold;
        
        
        const isAfterDiscountFrm = this.FormGrp.controls["isAfterDiscount"].value;
        const isItemOnlyFrm = this.FormGrp.controls["commissionon"].value == this.captions.setting.Items ? true : false;

        if(response && response.length > 0){
          if(this.popupConfigs.operation == "new" && response.filter(s =>  s.itemId == this.commissionData.itemId && s.outletId == outletId && s.threshold == threshold && s.classId == this.commissionData.classId).length > 0){
            isDuplicate = true; 
          }
          else{   
            if(response.filter(s => s.itemId == this.commissionData.itemId && s.outletId == outletId && s.threshold == threshold && s.classId == this.commissionData.classId && s.isItemOnly == isItemOnlyFrm).length > 1){
              isDuplicate = true;
            }  
            else{
              if(response.some(s =>  s.itemId == this.commissionData.itemId && s.outletId == outletId && s.flatAmount == flatAmount && s.percentage == percentage && s.threshold == threshold &&
                 s.isItemOnly == isItemOnlyFrm && s.classId == this.commissionData.classId  
                 && s.isCommissionAfterDiscount === isAfterDiscountFrm)){
                isDuplicate = true;
              }
            }
          }
        }
      } catch (ex) {
        console.error(ex);
      }
    }
    else if(this.fromScreen == 'THERAPIST'){
      try {
        let body: any = {
          recordsToSkip: 0,
          recordsToFetch: this.recordsPerAPICall,
          classId: String(this.commissionData.classId),
          outletId: [this.inputData.outletId],
          categoryId: [],
          searchText: '',
          flatAmount: null, //isNaN(flatAmount) ? this.localization.currencyToSQLFormat(flatAmount) : flatAmount,
          percentage: null, //isNaN(percentage) ? this.localization.currencyToSQLFormat(percentage) : percentage,
          threshold:  null, //isNaN(threshold) ? this.localization.currencyToSQLFormat(threshold) : threshold,
          commissionOn: 0,
          afterDiscount: 0,
          sortBy: 0,
          sortOrder: 0,
          staffId: 0,
          staffType:  null,
          itemId: [this.commissionData.itemId],
          onlyCommissionable: true
        };
        let response = await this.commissionService.getCommissionSetup(body);
        flatAmount = isNaN(flatAmount) ? this.localization.currencyToSQLFormat(flatAmount) : flatAmount;
        percentage = isNaN(percentage) ? this.localization.currencyToSQLFormat(percentage) : percentage;
        threshold  = isNaN(threshold)  ? this.localization.currencyToSQLFormat(threshold)  : threshold;

        const isAfterDiscountFrm = this.FormGrp.controls["isAfterDiscount"].value;
        const isItemOnlyFrm = this.FormGrp.controls["commissionon"].value == this.captions.setting.Items ? true : false;

        if(response && response.length > 0){
          if(this.popupConfigs.operation == "new" &&  response.filter(s => s.itemId == this.commissionData.itemId && s.outletId == outletId && s.threshold == threshold && s.classId == this.commissionData.classId).length > 0){
            isDuplicate = true; 
          }
          else{
            if(response.filter(s => s.itemId == this.commissionData.itemId && s.itemId == this.commissionData.itemId && s.outletId == outletId && s.threshold == threshold && s.classId == this.commissionData.classId && s.isItemOnly == isItemOnlyFrm).length > 1){
              isDuplicate = true;
            }  
            else{                      
              if(response.some(s => s.itemId == this.commissionData.itemId && s.outletId == outletId && s.flatAmount == flatAmount && s.percentage == percentage && s.threshold == threshold &&
                 s.isItemOnly == isItemOnlyFrm && s.classId == this.commissionData.classId && s.isCommissionAfterDiscount === isAfterDiscountFrm)){
                isDuplicate = true;
              }
            }
          }
          if(!isDuplicate){
            flatAmount = isNaN(flatAmount) ? this.localization.currencyToSQLFormat(flatAmount) : flatAmount;
            percentage = isNaN(percentage) ? this.localization.currencyToSQLFormat(percentage) : percentage;
            threshold  = isNaN(threshold)  ? this.localization.currencyToSQLFormat(threshold)  : threshold;
            const response = this.commissionService.commissionGridData.filter(s => s.flatAmount == flatAmount && s.percentage == percentage && s.threshold == threshold);
            
            if(response && response.length > 0){
              if(this.popupConfigs.operation == "new" && response.filter(s => s.itemId == this.commissionData.itemId && s.outletId == outletId && s.threshold == threshold && s.classId == this.commissionData.classId).length > 0){
                isDuplicate = true; 
              }
              else{          
              const isAfterDiscountFrm = this.FormGrp.controls["isAfterDiscount"].value;
              const isItemOnlyFrm = this.FormGrp.controls["commissionon"].value == this.captions.setting.Items ? true : false;
                if(response.filter(s => s.itemId == this.commissionData.itemId && s.outletId == outletId && s.threshold == threshold && s.classId == this.commissionData.classId && s.isItemOnly == isItemOnlyFrm).length > 1){
                  isDuplicate = true;
                }
                else{
                  if(response.some(s => s.itemId == this.commissionData.itemId && s.outletId == outletId && s.flatAmount == flatAmount && s.percentage == percentage && s.threshold == threshold && s.isItemOnly == isItemOnlyFrm
                     && s.classId == this.commissionData.classId && s.isAfterDiscount === isAfterDiscountFrm)){
                    isDuplicate = true;
                  }
                }
              }
            }
          }
        }
      } catch (ex) {
        console.error(ex);
      }
    }
    

    return isDuplicate;
  }
}
