<!-- temporarily commented -->
<!-- <section class="ag-pl-6 w-20 ag-pt-5" *ngIf="isMarkAsPaid">
    <mat-form-field appearance="fill">
        <mat-label>{{captions.teetime.rateType}}</mat-label>
        <mat-select [placeholder]="captions.teetime.rateType" (selectionChange)="OnRateTypeChange($event)">
            <mat-option></mat-option>
            <mat-option *ngFor="let rate of rateTypes" [value]="rate.id">
                {{rate.type}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</section> -->
<section [formGroup]="PaymentForm" class="h-100 paymentModal-container newVersionWrapper golf-container--padding">
  <div *ngIf="isMarkAsPaid" class="comments">
    <div class="w-33">
      <mat-form-field [floatLabel]="floatLabel" class="w-100">
        <input matInput [attr.automationId]="'Txt_paymentModal_paymentComment'" type="text" inputtype="noprespace" formControlName="paymentComment"
               [placeholder]="captions.settings.paymentComment" [required]="isCommentsRequired" maxlength="30">
        <mat-error *ngIf="isCommentsRequired && PaymentForm?.controls['paymentComment']?.touched && PaymentForm?.controls['paymentComment']?.hasError('required')">
          {{captions.settings.missingpaymentComment}}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="ag_display--flex space-between ag_align-items--center">
    <div class="div-players ag_display--flex ag_align-items--baseline" *ngIf="canShow && showSelectPlayer && !isEditPay">
      <label class="golf-form--label ag_mr--4">{{captions.teetime.selectPlayers}}</label>
      <ui-switch attr.automationId='Tog_{{automationId}}_autoSelectPlayers' #autoSelectPlayers [checkedLabel]="captions.teetime.toggleYes" [uncheckedLabel]="captions.teetime.toggleNo"
          (change)="autoSelectPlayertoggleChange($event)" formControlName="autoSelectPlayers">
      </ui-switch>
    </div>
    <div class="div-consent" *ngIf="isToShowConsentManagment">
      <div [attr.automationId]="'Icn_paymentModal_openConsentManagment'"  class="ag_display--flex ag_align-items--center div-icon-consent" (click)="openConsentManagment()">
        <span class="boxed-icon">
          <i class="icon-policy"></i>
        </span>
        <span class="span-label">{{captions.teetime.consentManagement}}</span>
      </div>
    </div> 
  </div>
  <section class="golf-container--padding ag-pt-0  ag-pb-0 tableContainer" [ngClass]="{'pending-payment1':(flagValue && !isMarkAsPaid) ,
                'pending-payment2':(!flagValue && isMarkAsPaid) ,
                'pending-payment3':(flagValue && isMarkAsPaid), 'notification-on':isNotificationOn }" id="PaymentModal">
      <app-note [noteMessage]="notifierBar" *ngIf="isNotificationOn && notifierBar && notifierBar.value && !isMarkAsPaid">
      </app-note>
      <h4 class="ag_mt--2 ag_group--headers">{{captions.lbl_date}}:  {{payDate}}</h4>
    <app-checkbox-table automationId="'paymentModal'" [tableForm]="PaymentForm" [childTemplate]="childTemplate" [headers]="headerOption"
                        [tableData]="tableData" (checkedData)="checkedData($event)" (unCheckedData)="unCheckedData($event)" (checkAllData)="checkAllData($event)" 
                        (dropdownChange)="onDropdownChange($event)" (updatePopoverEmitter)="updatePlayerEmit($event)">
    </app-checkbox-table>
  </section>
</section>


<ng-template #childTemplate let-element="element" let-key="key" let-data="data" let-row="row" let-index="idx">
  <ng-container [ngSwitch]="key">
    <ng-container *ngSwitchCase="'action'">
      <a attr.automationId='Lbl_paymentModal_activityAction{{idx}}' (click)='row.isReleaseAllow && activityAction($event,row)'
         *ngIf="row.isHold&&row.isNotDepositWithPendingSettlement"
         [ngClass]="{'cust-button-disabled':!row.isReleaseAllow} ">{{captions.teetime.release}}</a>
    </ng-container>
  </ng-container>
</ng-template>
