import { Injectable } from '@angular/core';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';
import { TeeTimeModel, TeeTimeSearchResult, PlayerDetail } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { ReinstatePlayer, ReinstatePlayerUI } from 'src/app/tee-time-actions/reinstate-modal/reinstate-player-model';
import { ScheduledPlayer } from '../../models/teesheet.form.models';
import { TeeSheetPlayerDetail, TeeSlotNavigation } from '../../models/teeSheetPlayerDetail.model';
import { CancelPlayers } from 'src/app/tee-time-actions/move-player-modal/move-player-modal.model';
import { PlayerCancellationFees } from 'src/app/tee-time-actions/cancel-player-modal/cancel-player-model';

@Injectable()
export class TeeTimeDataService {
    constructor(private _http: GolfScheduleCommunication) {

    }

    public async getScheduledTeeTime(scheduleId: number): Promise<TeeTimeModel> {
        return await this._http.getPromise<TeeTimeModel>(
            {
                route: GolfApiRoute.GetTeeTimeById,
                uriParams: { scheduleId: scheduleId }
            });
    }

    public async getScheduledPlayers(scheduleId: number): Promise<ScheduledPlayer[]> {
        return await this._http.getPromise<ScheduledPlayer[]>(
            {
                route: GolfApiRoute.GetScheduledTeeTimePlayersByScheduledId,
                uriParams: { scheduleId: scheduleId }
            });
    }

    public async GetTeeTimePlayersByDateAndCourse(courseId: number, scheduledDate: string, scheduleStatus: number): Promise<ScheduledPlayer[]> {
        return await this._http.getPromise<ScheduledPlayer[]>(
            {
                route: GolfApiRoute.GetTeeTimePlayersByDateAndCourse,
                uriParams: { courseId : courseId, scheduledDate : scheduledDate, scheduleStatus : scheduleStatus }
            });
    }

    public async getScheduledPlayersByBookingId(bookingId: string, isSortRequired: boolean = false): Promise<ScheduledPlayer[]> {        
        return await this._http.getPromise<ScheduledPlayer[]>(
            {
                route:  GolfApiRoute.GetScheduledTeeTimePlayers ,
                uriParams: { bookingId: bookingId, isSortRequired: isSortRequired }
            });
    }

    public async searchScheduledTeeTime(pattern: string): Promise<TeeTimeSearchResult[]> {
        return await this._http.getPromise<TeeTimeSearchResult[]>(
            {
                route: GolfApiRoute.SearchTeeTime,
                uriParams: { pattern: pattern }
            });
    }

    public async getCancelTeeTimePlayers(courseId: number, bookedDateTime: string): Promise<ReinstatePlayer[]> {
        return await this._http.getPromise<ReinstatePlayer[]>(
            {
                route: GolfApiRoute.GetCancelTeeTimePlayers,
                uriParams: { courseId: courseId, bookedDateTime: bookedDateTime }
            });
    }

    public async getReinstatePlayers(courseId: number, bookedDateTime: string, pmsActivityId: string): Promise<ReinstatePlayerUI[]> {
        return await this._http.getPromise<ReinstatePlayerUI[]>(
            {
                route: GolfApiRoute.GetReinstatePlayers,
                uriParams: { courseId: courseId, bookedDateTime: bookedDateTime, pmsActivityId: pmsActivityId }
            });
    }

    public UpdatePlayerToGPSInterface(playerDetail: PlayerDetail[]) {
        return this._http.putPromise<any>(
            { route: GolfApiRoute.UpdateTeeTimePlayerToGuest, body: playerDetail }, true);
    }

    public async getSlotScheduleTeeTimePlayers(date?: string, originalHoleNumber?: string,courseId?: number, teeSlotNavigation?: TeeSlotNavigation, activityId?: string): Promise<TeeSheetPlayerDetail[]> {
        return await this._http.getPromise<TeeSheetPlayerDetail[]>(
            {
                route: GolfApiRoute.GetSlotPlayerDetails,
                uriParams: { date: date, courseId: courseId, teeSlotNavigation: teeSlotNavigation, activityId: activityId, originalHoleNumber: originalHoleNumber}
            });
    }

    public async ApplyCancellationCharges(cancelPlayer: CancelPlayers) : Promise<PlayerCancellationFees>
    {
      const cancellationFees = await this._http.putPromise<PlayerCancellationFees>(
        {
          route: GolfApiRoute.ApplyCancellationCharges,
          body: cancelPlayer
        });
        return cancellationFees;
    }
    public async ApplyNoShowCharges(playerId, outletId) : Promise<PlayerCancellationFees>
    {
      const cancellationFees = await this._http.putPromise<PlayerCancellationFees>(
        {
          route: GolfApiRoute.ApplyNoShowCharges,
          uriParams: 
          {
            playerId: playerId,
            outletId: outletId
          }
        });
        return cancellationFees;
    }
}