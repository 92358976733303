<section class="new-notify-popup-wrapper ag_height--100">
    <ng-container>
            <app-dialog-header [count]="notificationData?.length" [title]="headername" (closeDialog)="close($event)"></app-dialog-header>
    </ng-container>
    <div class="ag_container--padding-md alert-content-wrapper" *ngIf="this.notificationData.length!=0" [ngClass]="{'alert-notify-wrapper': notifyFrom == 'notify'}">
        <div *ngFor='let category of notificationData' class="ag_pb--5" [ngClass]="{'border-notify': notifyFrom == 'notify'}">
            <div class="ag_display--flex ag_pt--4" *ngIf="notifyFrom == 'notify'">
                <div class='ag_display--block ag_pr--3'>
                    <span id="panel" class="panel"> {{category.notes}}
                    </span>
                    <span class="moreText">
                        <a id="more|less" class="ag_link"
                            (click)="toggleText(category);$event.preventDefault();"></a></span>
                </div>
                <span class="priority mr-4 ag_ml--auto"
                    [ngClass]="'_priority_'+category.priority">{{category.priorityName}}</span>
            </div>
            <div class="ag_display--flex custom-category-notes ag_pt--4" *ngIf="notifyFrom == 'notify'">
                <div class="cust-wd-60">
                    <span class="cust-wd-100 ag_display--flex over-wrapper"> <span class="snt-label">{{ captions.lbl_sent_by }}</span> - <span [matTooltip]="category?.sentBy" class="snt-cont">{{category.sentBy}}</span></span>
                    <span class="snt-on cust-wd-100">{{category.sentOn}}</span>
                </div>
                <div class="cust-wd-40 ag_display--flex">
                    <span class="ag_ml--auto ag_link custom-ack-link" *ngIf="!category.isAcknowledged" (click)="individualAcknowledge(category)">{{captions.acknowledge}}</span>
                </div>   
            </div>

            <div class="ag_display--flex ag_pt--4" *ngIf="notifyFrom != 'notify'">
                <mat-checkbox class='ag_display--block ag_pr--3' disableRipple="true" (click)="functionCheck(category)">
                    <span id="panel" class="panel"> {{category.notes}}
                    </span>
                    <span class="moreText">
                        <a id="more|less" class="ag_link"
                            (click)="toggleText(category);$event.preventDefault();"></a></span>
                </mat-checkbox>
                <span class="priority mr-4 ag_ml--auto"
                    [ngClass]="'_priority_'+category.priority">{{category.priorityName}}</span>
            </div>
            <div class="ag_display--flex ag_pt--4" *ngIf="notifyFrom != 'notify'">
                <span class="ag_pl--6">{{ captions.lbl_sent_by }} {{category.sentBy}}, </span>
                <span class="ag_pl--6">{{category.sentOn}}</span>
                <span class="ag_mr--3 ag_ml--auto" *ngIf="category.isSnoozed">{{category.snoozeTime}}</span><i
                    class="icon-snooze_off ag_ml--auto ag_mr--8 ag_cursor--pointer" [popover]="snoozePopover"
                    [popoverOnHover]="false" popoverPlacement="bottom" *ngIf="!category.isSnoozed"></i>
                <i class="icon-snooze_on ag_mr--6 ag_cursor--pointer" *ngIf="category.isSnoozed"
                    (click)="removeSnoozeTimer(category)"></i>
            </div>

            <popover-content #snoozePopover placement="bottom" class="user-popover" [animation]="true"
                [closeOnClickOutside]="true" id="snoozePopover" container='body' [animation]="true">
                <div *ngFor="let x of snoozerData">
                    <span class="snooze-popover-profile ag_cursor--pointer" (click)="setSnoozeTimer(category,x)">{{x.value}}</span>
                </div>
            </popover-content>
        </div>
    </div>

    <div class="ag_footer--actions" *ngIf="notifyFrom != 'notify' && this.notificationData.length!=0">
        <app-button [buttontype]="AcknowledgeButton" (valueChange)='onAckAction($event)'></app-button>
        <app-button [buttontype]="cancelButton" class="ag_ml--2" (valueChange)='onCancel($event)'></app-button>
    </div>
    <div *ngIf="this.notificationData.length==0" class="no-data-wrapper">
        <div class="no-data-img">
          <span>{{this.lbl_noDataFound}}</span>
        </div>
      </div>
</section>