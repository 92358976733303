import { Component,  Input} from '@angular/core';
import * as _ from 'lodash';
import { ImageData } from 'src/app/common/Models/ag-models';

@Component({
  selector: 'app-img-thumbnail',
  templateUrl: './image-thumbnail.component.html',
  styleUrls: ['./image-thumbnail.component.scss']
})
export class ImageThumbnailComponent  {
  @Input() imageContent: ImageData;
  @Input() thumbnail: boolean;
  @Input() imageType: string;
  @Input() selectImage: string;
  url: any;
  constructor() { }

  ngOnChanges() {
    if (this.imageContent && !_.isEmpty(this.imageContent) && this.imageContent.contentType) {
      let imageUrl = `data:${this.imageContent.contentType};base64,${this.imageContent.thumbnailData}`;
      // this.url = this.domSanitizer.sanitize(SecurityContext.URL,this.domSanitizer.bypassSecurityTrustUrl(imageUrl));
      this.url = imageUrl;
    } else {
      if (this.imageType === 'therapist') {
        this.url = 'assets/images/therapist.png';
      } else if (this.imageType === 'client') {
        this.url = 'assets/images/client.png';
      } else if (this.imageType === 'user') {
        this.url = 'assets/images/user.png';
      } else if (this.imageType === 'retailItem') {
        this.url = 'assets/images/shop/emptyshop.jpg';
      } else if (this.imageType === 'giftcard') {
        this.url = 'assets/images/shop/emptyshop.jpg';
      }
    }
  }
}
