import { Injectable } from '@angular/core';
import {  CaddyTypeValues } from 'src/app/settings/golf-setup/code-setup/caddy-type/caddy-type.modal';
import { GolfManagementCommunication } from '../../communication/services/golfmanagement.service';


@Injectable()
export class CaddyTypeDataService {

  constructor(private _http: GolfManagementCommunication) {
  }
  
  async getAllCaddyTypes(courseId, slotTime, bShowInActive: boolean = false): Promise<CaddyTypeValues> {
    return await this._http.getPromise<CaddyTypeValues>(
      { route: GolfApiRoute.GetAllCaddyTypesByDate, uriParams: { "courseId": courseId, "slotTime": slotTime, "showInActive": bShowInActive } });
    
  } 
}