import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Inject, ViewEncapsulation } from '@angular/core';
import { DateInput, DialogCloseObj, AlertType, ButtonType, AlertAction, GolfPMSSessionInfo } from '../../shared/shared-models';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ReinstateTeeTimeBusiness } from './reinstate-teetime-business';
import { ReinstateTeeTimeService } from './reinstate-teetime-service';
import { TeeSheetTableID, TeeSheetCustomData, TeeSheetPlayerDetails, TeeSheetSkeletonData, TeeSheetGridContent, DragOrigin, TeeSheetModications, TeeSheetDragDropEvt } from 'src/app/shared/models/teesheet.form.models';
import { TeeGridService } from 'src/app/shared/components/tee-grid/tee-grid.service';
import { Observable, ReplaySubject } from 'rxjs';
import * as _ from 'lodash';
import { TeeSheetReinstate } from '../../tee-time/shared/tee-sheet/tee-sheet.reinstate';
import { takeUntil } from 'rxjs/operators';
import { StepperService } from '../../shared/components/stepper/stepper.service';
import { CourseDataService } from '../../shared/data-services/golfmanagement/course.data.service';
import { ReinstatePlayerUI, PlayerTeeTimeSlot } from '../reinstate-modal/reinstate-player-model';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CourseOption } from 'src/app/settings/rate-setup/rate-setup.model';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { TeeTimesActionBusiness } from 'src/app/tee-time/shared/tee-action.business';
import { TeeTimeAction } from 'src/app/shared/models/teesheet.api.models';
import { ButtonAction } from 'src/app/shared/global.constant';
import { NotifierBar } from 'src/app/shared/components/note/note.model';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { PMSAction } from 'src/app/common/external-request/pms-request.model';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { AllocationBlockDataService } from 'src/app/shared/data-services/golfmanagement/allocationblock.data.service';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import { PlayerTypes as PlayerType, RateType} from '../teetime/player-details/player-info.model';
import { UserAccessModel } from 'src/app/shared/data-services/authentication/useraccess-model.model';

@Component({
  selector: 'app-reinstate-teetime-modal',
  templateUrl: './reinstate-teetime-modal.component.html',
  styleUrls: ['./reinstate-teetime-modal.component.scss'],
  providers: [ReinstateTeeTimeBusiness, ReinstateTeeTimeService, TeeSheetReinstate, CourseDataService],
  encapsulation: ViewEncapsulation.None
})
export class ReinstateTeetimeModalComponent implements OnInit {
  _dialog: MatDialog;
  captions: any;
  courseType: Promise<CourseOption[]>;
  reinstateTeeTimeForm: UntypedFormGroup;
  requestedDateInputs: DateInput;

  teeSheetID: TeeSheetTableID;
  customTableData: TeeSheetCustomData;
  playerList: TeeSheetPlayerDetails[];
  skeletonData: Observable<TeeSheetSkeletonData[]>;
  playerData: Observable<TeeSheetGridContent[]>;
  draggedPlayersList: any;
  playerCollectionList: any[] = [];
  selectedPlayersInfo: ReinstatePlayerUI[];
  existingCourseId: number;
  existingBookingDateTime: Date;
  courseId: number;
  notificationbar: NotifierBar;
  notificationFlag: boolean = false;
  bookingDateTime: Date;
  lastUpdatedSlots: TeeSheetSkeletonData[];
  overridePlayerTypeDaysoutUserAccess : UserAccessModel.BreakPointResult;
  overrideRateTypeDaysoutUserAccess: UserAccessModel.BreakPointResult;
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  playerTypes : PlayerType[] = [];
  rateTypes : RateType[] = [];
  @Output() notifyParent = new EventEmitter();

  constructor(private _FormBuilder: UntypedFormBuilder,
    private _reinstateTeeTimeBusiness: ReinstateTeeTimeBusiness,
    private _teeGridService: TeeGridService,
    private cdr: ChangeDetectorRef,
    public _StepperService: StepperService,
    private _localization: GolfLocalization,
    private _utilities: GolfUtilities,
    private _teeTimesActionBusiness: TeeTimesActionBusiness,
    private _propertyInformation: GolfPropertyInformation,
    private propertyInformation: RetailPropertyInformation,
    public quickLoginUtils: QuickLoginUtilities,  
    private _utils: GolfUtilities,
    private _allocationBlockDataService: AllocationBlockDataService,
    @Inject(MAT_DIALOG_DATA) public dialogData) { }

  async ngOnInit() {
    this.generateForm();
    this.selectedPlayersInfo = this.dialogData.selectedPlayerIds;
    this.existingCourseId = this.courseId = this.selectedPlayersInfo[0].courseId;
    this.existingBookingDateTime = this.bookingDateTime = this.selectedPlayersInfo[0].bookedDateTime;
    this.courseType = this._reinstateTeeTimeBusiness.getCourses();
    this.captions = this._reinstateTeeTimeBusiness.reinstatePlayerCaption;
    this.rateTypes = await this._teeTimesActionBusiness.getRateTypes();
    this.initializeInputs();
    this.teeSheetID = this._reinstateTeeTimeBusiness.getTeeSheeetID();
    this.customTableData = this._reinstateTeeTimeBusiness.getTeeSheetCustomTableData();
    this.notifyParent.emit(false);
    this.getMasterData();
    this.reinstateTeeTimeForm.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe();

    this._StepperService.valueChange.pipe(takeUntil(this.$destroyed)).subscribe(async (x: DialogCloseObj) => {
      if (x.type === ButtonAction.save) {
        let modifiedSlots: PlayerTeeTimeSlot[] = this._reinstateTeeTimeBusiness.generateModifiedPlayersTeeSlots(this.initialTeeTimes, this.teeSheetModifications.currentState);
        let result =await this._teeTimesActionBusiness.checkOverridePlayerTypeRateTypeDaysOut(null, null, this.playerTypes, modifiedSlots, this.overridePlayerTypeDaysoutUserAccess,this.overrideRateTypeDaysoutUserAccess, this.bookingDateTime);
        if(!result){
          this.getData(this.courseId, this.bookingDateTime);
          this.getPlayers();
          return;
        }

        let quickIdConfig = this.propertyInformation.getQuickIdConfig;
        this.notifyParent.emit(false); // Disable save once clicked
        if (quickIdConfig && quickIdConfig.teeTimeReinstate) {
          const quickLoginDialogRef = this.quickLoginUtils.QuickLogin();
          quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
            if (quickLoginDialogResult.isLoggedIn) {
              this.reinstateTeeTime(x, modifiedSlots);
            }
            else {
              this._StepperService.enableSave = true;
            }
          });
        }
        else {
          this.reinstateTeeTime(x, modifiedSlots);
        }

      }
    });

    this.playerList = this._reinstateTeeTimeBusiness.getPlayerList(this.selectedPlayersInfo, this.rateTypes);
    this.onPageLoad();
    this.notificationbar = {
      class: '',
      value: this.captions.playersMoveUpdatednotifications,
      color: "#fff16e",
      isRemovable: true
    }
    setInterval(() => {
      this.notificationFlag = false;
    }, 7000);
  }

  async getMasterData() {
    this.overridePlayerTypeDaysoutUserAccess = await this._teeTimesActionBusiness.validateBreakPointAccess(UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT, false);
    this.playerTypes = await this._teeTimesActionBusiness.getPlayerTypes();
    this.overrideRateTypeDaysoutUserAccess = await this._teeTimesActionBusiness.validateBreakPointAccess(UserAccessBreakPoints.OVERRIDERATETYPEDAYSOUT, false);
  }  

  reinstateTeeTime(x: any,  modifiedSlots: PlayerTeeTimeSlot[]) {
    this.saveMovedTeeTimes(modifiedSlots).then(y => {
      if (y) {
        let message = this.captions.reinstateSuccessMsg;
        this._utilities.showAlert(message, AlertType.Success, ButtonType.Ok, (res) => {
          if (res === AlertAction.CONTINUE) {
            if (this.playerList && this.playerList.length > 0) {
              this.getData(this.courseId, this.bookingDateTime);
              this.getPlayers(true);
            }
            else
              x.dialogRef.close(x.type);
          }
        });
      }
      else {
        this.getData(this.courseId, this.bookingDateTime);
        this.getPlayers();
      }
      this.notifyParent.emit(true); // Enable Save once API is completed
    }).catch(e => {
     // Enable Save once API is completed with Errors
      this._utilities.showError(this._localization.getError(-2));
      this.getData(this.courseId, this.bookingDateTime);
      this.getPlayers();
    }).finally(() => {
      this.quickLoginUtils.resetQuickIdDetails();
    });
  }

  onPageLoad() {
    this.reinstateTeeTimeForm.controls.courses.setValue(this.courseId);
    this.reinstateTeeTimeForm.controls.selectDate.setValue(this._localization.getDate(this.bookingDateTime));
    this.getData(this.existingCourseId, this.existingBookingDateTime);
  }

  getData(course: number, bookingDateTime: Date) {
    this.resetPlayerList();
    this.skeletonData = this._reinstateTeeTimeBusiness.getTeeSheet(course, bookingDateTime,this.rateTypes);
    this.skeletonData.subscribe(s => {
      this.lastUpdatedSlots = s;
      this.initialTeeTimes = this._reinstateTeeTimeBusiness.getInitialTeeTimes();
    });
  }

  async getPlayers(isSaveClicked?) {
    let players: TeeSheetPlayerDetails[];
    if (isSaveClicked) {
      players = this.lastUpdatedSlots.find(x => x.time === this._localization.ConvertDateToISODateTime(this.selectedPlayersInfo[0].bookedDateTime))[0].playerDetail;
      this.playerList = this._reinstateTeeTimeBusiness.getPlayerList(players, null);
    }
    else {
      this.playerList = this._reinstateTeeTimeBusiness.getPlayerList(this.selectedPlayersInfo, this.rateTypes);
    }
    this.playerList.forEach(x=>x.time = this.dialogData.info.time);
    this.playerList.forEach(x=>x.courseId = this.dialogData.info.course.id);
  }

  resetPlayerList() {
    if(this.playerList) {
      this.playerList.forEach(element => {
        element['playerClicked'] = false;
      });
      this._teeGridService.selectedPlayers = [];
    }    
  }

  initializeInputs() {
    this.requestedDateInputs = {
      className: 'golf-form-control--lg',
      form: this.reinstateTeeTimeForm,
      formControlName: 'selectDate',
      placeHolder: this.captions.selectDate,
      errorMessage: "Enter Select Date",
      minDate: this._propertyInformation.CurrentDTTM,
      value: this._propertyInformation.CurrentDTTM
    };
    const pMSRequestInfo: GolfPMSSessionInfo = this._utilities.getPMSSessionInfo();
    if (pMSRequestInfo && pMSRequestInfo.action == PMSAction.ReInstateReservation && pMSRequestInfo.stayFromDate && pMSRequestInfo.stayToDate) {
      this.requestedDateInputs.minDate = pMSRequestInfo.stayFromDate;
      this.requestedDateInputs.maxDate = pMSRequestInfo.stayToDate;
      this.reinstateTeeTimeForm.controls.selectDate.setValue(this.bookingDateTime);   
      this.dateChanged([
        {
          value: {
            selectDate: this.bookingDateTime
          }
        }
      ]);
      // this.cdr.detectChanges();
      this.requestedDateInputs = _.cloneDeep(this.requestedDateInputs);
    }
  }

  generateForm() {
    this.reinstateTeeTimeForm = this._FormBuilder.group({
      selectDate: '',
      courses: ''
    });
  }

  dateChanged(e) {
    let bModified: boolean = this.isTeeGridModified();
    const selectedDate = e[0].value.selectDate;
    if (this._teeTimesActionBusiness.validateUserDaysOut(selectedDate)) {
      if (selectedDate != this._localization.getDate(this.bookingDateTime) && bModified) {
        this.showAlert(selectedDate, this.courseId);
      }
      else {
        this.bookingDateTime = this.existingBookingDateTime = selectedDate;
        this.getData(this.courseId, this.bookingDateTime);
      }
    }
    else {
      this._utilities.showError(this._localization.getError(30414));
      this.bookingDateTime = this.existingBookingDateTime;
      this.courseId = this.existingCourseId;
      this.reinstateTeeTimeForm.controls.selectDate.setValue(this.bookingDateTime);
      this.reinstateTeeTimeForm.controls.courses.setValue(this.courseId);
    }
  }

  courseChanged(e) {
    let bModified: boolean = this.isTeeGridModified();
    if (e != this.courseId && bModified) {
      this.showAlert(this.bookingDateTime, e);
    }
    else {
      this.courseId = this.existingCourseId = e;
      this.getData(this.courseId, this.bookingDateTime);
    }
  }

  private initialTeeTimes: TeeSheetSkeletonData[] = [];

  private teeSheetModifications: TeeSheetModications;

  showAlert(date, course) {
    let message = this.captions.PleaseSavethechanges;
    this._utilities.showAlert(message, AlertType.Warning, ButtonType.Ok, (res) => {
      this.bookingDateTime = this.existingBookingDateTime;
      this.courseId = this.existingCourseId;
      this.reinstateTeeTimeForm.controls["selectDate"].setValue(this.bookingDateTime);
      this.reinstateTeeTimeForm.controls["courses"].setValue(this.courseId);
    });
  }

  // Tee Sheet Function Starts
  onRemovePlayer(playerDetails) {
    playerDetails.rowData.playerDetail = playerDetails.rowData.playerDetail.filter(x => x.playerId !== playerDetails.playerData.playerId);
    this.draggedPlayersList = this.draggedPlayersList.filter(x => x.playerId !== playerDetails.playerData.playerId);
    playerDetails.playerData.playerClicked = false;
    this.reOrderPlayers(playerDetails.rowData.playerDetail);
    this.playerList.push(playerDetails.playerData);
    this.teeSheetModifications = {
      initialState: playerDetails.initialState, currentState: playerDetails.currentState
    };
    this._reinstateTeeTimeBusiness.updateTeeSlots(playerDetails.currentState);
    let isModified = this.isTeeGridModified();
    this.notifyParent.emit(this.reinstateTeeTimeForm.valid && isModified);
  }

  // Reorder Player Numbers Here
  reOrderPlayers(playersArray) {
    playersArray.forEach((element, index) => {
      element.playPos = index + 1;
    });
  }

  async onDragDrop(dragDropObj) {
    let draggedPlayers: PlayerTeeTimeSlot[];
    let isDestinationSlotRateChanged: boolean = false;
    this.teeSheetModifications = {
      initialState: dragDropObj.initialState, currentState: dragDropObj.currentState
    };
    this.initialTeeTimes = this._reinstateTeeTimeBusiness.getInitialTeeTimes();

    //normal drag and drop
    if (dragDropObj.dragOrigin === DragOrigin.playerlist) {
      dragDropObj.dragData = this.draggedPlayersList;
      dragDropObj.dragPositionObject = this.selectedPlayersInfo[0];
    }
    //move within grid
    else {
      dragDropObj.dragData = new Array(dragDropObj.dragData);
    }

    const isDropValid: boolean = this._teeTimesActionBusiness.validateDrop(dragDropObj.dropPositionObject, TeeTimeAction.reinstate);
    if (!isDropValid) {
      this.resetTeeData(dragDropObj);
      return;
    }

    if (this._utils.IsAllocationCodePermissionEnabled() && !await this.hasPermissionForAllocationBlock(dragDropObj)) {
      this.resetTeeData(dragDropObj);
      return;
    }
    try {
      this._reinstateTeeTimeBusiness.updateTeeSlots(dragDropObj.currentState);
      this.lastUpdatedSlots = dragDropObj.currentState;
      draggedPlayers = this._teeTimesActionBusiness.mapPlayerData(dragDropObj.dropPositionObject, dragDropObj.dragData);
      this._utilities.ToggleLoader(true);
      var validationResult = await this._teeTimesActionBusiness.validateReinstateAction(TeeTimeAction.reinstate, draggedPlayers);
      this._utilities.ToggleLoader(false);
      if (validationResult && validationResult.result && validationResult.result.length > 0) {
        isDestinationSlotRateChanged = await this.showRateChangeAlert(validationResult);

        validationResult.result.forEach((element) => {
          if (element.result && (!element.isDestinationSlotRateChanged || (element.isDestinationSlotRateChanged && !isDestinationSlotRateChanged))) {
            this.onDropSuccess(dragDropObj, element.playerTeeTimeSlot);
          } else {
            this.resetTeeData(dragDropObj, element.playerTeeTimeSlot);
          }
        });
      }
    } catch (error) {
      this.resetTeeData(dragDropObj);
    }
    finally {
      this._utilities.ToggleLoader(false);
    } 
  }


  async hasPermissionForAllocationBlock(dragDropObj) {
    let hasPermission = true;
    let allocation = dragDropObj.dropPositionObject.allocation;
    if ( allocation && allocation.id > 0) {
      let [allocationPermissions, teeTimeInfo, allPlayerTypes] = await Promise.all([this._allocationBlockDataService.getAllocationBlockPermissionByRole(),
      this._teeTimesActionBusiness.getCancelTeeTimePlayers(dragDropObj.dragPositionObject.courseId, dragDropObj.dragPositionObject.bookedDateTime),
      this._reinstateTeeTimeBusiness.getAllPlayerTypes()]);
      allocationPermissions = allocationPermissions ? allocationPermissions : [];

      let destinationAllocPermissions = allocationPermissions.filter(r => r.allocationBlockId == allocation.id);
      if (!destinationAllocPermissions || destinationAllocPermissions.length == 0 || !destinationAllocPermissions[0]) {
        this._utilities.showAllocationCodePermissionDeniedPopup(allocation.name, this._localization.captions.common.ReInstateNotAllowed);
        return false;
      }
      else if (teeTimeInfo) {
        let invalidPlayerTypes = [];
        for (let palyer of dragDropObj.dragData) {
          let teePlayer = teeTimeInfo.find(t => t.id == palyer.playerId && t.playerTypeId > 0);
          if (teePlayer && !allocationPermissions.some(al => al.playerTypeId == teePlayer.playerTypeId)) {
            invalidPlayerTypes.push(teePlayer.playerTypeId);
          }
        }
        if (invalidPlayerTypes && invalidPlayerTypes[0]) {
          this._utilities.showAllocationCodePermissionDeniedPopup(this._utilities.getPlayerTypeNameByIds(invalidPlayerTypes, allPlayerTypes), this._localization.captions.common.ReInstateNotAllowed);
          return false;
        }
      }
    }
    return hasPermission;
  }

  ngAfterViewInit() {
    // By Default on Page Load Set NotifyParent to false to disable Save Button
    this.notifyParent.emit(false);
  }

  onDropSuccess(dragDropObj: TeeSheetDragDropEvt, validPlayer: PlayerTeeTimeSlot) {
    this.notifyParent.emit(this.reinstateTeeTimeForm.valid);
    this.playerList = this.playerList.filter(p => p.playerId !== validPlayer.playerId);
    this._teeGridService.selectedPlayers = [];
    if (dragDropObj.dragOrigin === DragOrigin.playerlist && this.playerList.length === 0) {
      this.notificationFlag = true;
    }
  }

  resetTeeData(dragDropObj, resetData?: PlayerTeeTimeSlot) {
    if (dragDropObj.dragOrigin === DragOrigin.playerlist) {
      this.draggedPlayersList.forEach(element => {
        if (!(resetData) || element.playerId === resetData.playerId) {
          _.remove(dragDropObj.currentState[dragDropObj.dropPositionRowIndex].playerDetail, element);
        }
      });
    }
    if (dragDropObj.dragOrigin === DragOrigin.grid) {
      this.draggedPlayersList.forEach(element => {
        dragDropObj.currentState[dragDropObj.dragPositionRowIndex].playerDetail.push(element);
        _.remove(dragDropObj.currentState[dragDropObj.dropPositionRowIndex].playerDetail, element);
      });
    }

    this._reinstateTeeTimeBusiness.updateTeeSlots(dragDropObj.currentState);
  }

  onDragPlayers(playersDragged) {
    this.draggedPlayersList = playersDragged;
  }

  onDragGridPlayers(e) {
    this._teeGridService.selectedPlayers.forEach(element => {
      element.playerClicked = e;
    });
  }

  onPlayerListEmit(playerCollectionList) {
    this.playerCollectionList = playerCollectionList;
    this.cdr.detectChanges();
  }

  isTeeGridModified(): boolean {
    let isModified = false as boolean;
    if (this.initialTeeTimes.length > 0) {
      let modifiedSlots: PlayerTeeTimeSlot[] = this._reinstateTeeTimeBusiness.generateModifiedPlayersTeeSlots(this.initialTeeTimes, this.lastUpdatedSlots);
      if (modifiedSlots.length > 0) {
        isModified = true;
      }
    }
    return isModified;
  }

  async showRateChangeAlert(validationResult): Promise<boolean> {
    // check destination change 
    let isDestinationSlotRateChanged: boolean = false;
    let destinationChanged = validationResult.result.filter(x => x.isDestinationSlotRateChanged);
    if (destinationChanged && destinationChanged.length > 0) {
      let message = this.captions.DestinationRateChanged;
      var res = this._utilities.showAlert(message, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res === AlertAction.NO) {
          isDestinationSlotRateChanged = true;
        }
      });
      await res.afterClosed().toPromise();
    }
    return isDestinationSlotRateChanged;
  }

  private saveMovedTeeTimes(modifiedSlots: PlayerTeeTimeSlot[]): Promise<boolean> {
    if (!this.teeSheetModifications) { return; }   
    return this._reinstateTeeTimeBusiness.reinstateWithNewCombination(modifiedSlots);
  }

  removeNotification(event) {
    this.notificationFlag = event;
  }

  ngOnDestroy(): void {
    this.$destroyed.next(true);
    this.$destroyed.complete();
    this.quickLoginUtils.resetQuickIdDetails();
  }
}
