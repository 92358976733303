import { Injectable } from '@angular/core';
import { RetailBussinessService } from '../../retail.bussiness';
@Injectable()

export class SelectOutletPopupBusiness {
    constructor(private service: RetailBussinessService) {
    }

    public async getOutlets() {
        let apiResponse = this.service.GetUserOutlets();
        // return (await apiResponse).map(x => this.uiMapper(x));
        return apiResponse;
    }

    uiMapper(data) {
        return {
            id: data.subPropertyID,
            value: data.subPropertyCode,
            viewValue: data.subPropertyName
        }
    }

   
}