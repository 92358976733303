

export interface Course {
    courseId: number;
    courseName: string;
}

export interface BlockType {
    blockId: number;
    blockName: string;
    checked: boolean;
}

export interface AllocationCode {
    id: string
    allocationBlockName: string;
    colorCode: string;
}

export interface Dates {
    date: string;
    day: string;
}

export interface Slots {
    players: string;
    allocationCode: number;
    date: Date;
}

export interface TeeTimes {
    slotTime: string;
    slots: Slots[];
}

// export enum allocationCodeColor {
//     default = '#fff',
//     earlyBird = '#00aed8',
//     twilight = '#00397c',
//     tournament = '#cc974e',
//     open = '#42a046',
//     buffer = '#3be7ff',
//     maintenance = '#4eccc3'
// }



// export enum allocationCodeColor {
//     "#0d22e7" = 10,
//     "#e3ce66" = 11,
//     "#85f111" = 12,
//     "#ff221a" = 14
// }

export interface Days {
    value: string;
    viewValue: string;
}


export class TeeTimeAllocationBlock {
    id: number;
    courseId: number;
    allocationBlockId: number;
    allocationDateTime: Date;
    holeNumber : string;
    isDefault:boolean;
    viewedByStarter:boolean;
    originalHoleNumber : string;
}

export enum DaysSelector {
    allDays = 'allDays',
    oddDays = 'oddDays',
    evenDays = 'evenDays',
    custom = 'custom'
}


export interface TeeTimeSlots {
    date: Date;
    teeSlots: TeeSlots[];
}
export interface TeeSlots {
    id: number;
    courseId: number;
    allocationBlockId: number;
    allocationDateTime: Date;
    playerCount: number;
    isAllSlot? : boolean; 
    oldAllocationBlockId : number;
    holeNumber : string;
    isDefault:boolean;
    originalHoleNumber : string;
}

export interface Checkboxes {
    checked: boolean;
    checkedCount?:number;
    dateCount?:number;
}

export interface PlayersCount {
    id: number;
    courseId: number;
    scheduleDateTime: Date;
    playerCount: number;
}

