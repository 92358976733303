<section id="teeGrid" *ngIf="teeData && teeData.length > 0"> 
  <table [attr.automationId]="'Tbl_TeeGrid_teeGridTable'" aria-describedby="tee-grid-table">
    <thead>
      <tr>
        <th scope="col" class="coloumn left-align">{{captions?.time}}</th>
        <th scope="col" class="coloumn left-align">{{captions?.hole}}</th>
        <th scope="col" class="coloumn left-align {{teeSheetID.tableID}}">{{captions?.playerInformation}}</th>
      </tr>
    </thead>    
    <tbody cdkDropListGroup>        
      <tr *ngFor="let rowdata of teeData | dataFilter:filterExpression;let i=index" id="{{rowdata.time | localizeTime}}{{rowdata.hole}}">      
        <td class="coloumn">{{rowdata.time | localizeTime}}</td>
        <td class="coloumn center-align">{{rowdata.hole}}
          <div *ngIf="rowdata.viewedByStarter"><span class="icon-Viewed-By-Starter-Fill"></span>            
          </div>
        </td>
        <td class="coloumn" [ngStyle]="{'background-color': rowdata.slotBackgroundInfo?.colorCode}" [ngClass]="[!rowdata.isDropAllowed? 'drop-disabled':'', (rowdata.slotBackgroundInfo?.colorCode | colorChecker)? 'dark-bg-light-color': 'light-bg-dark-color', (customTableData.isMultiView && !customTableData.isDetailView)? 'compare-view-column': '']">
          <div class="players-data" [ngClass]="{'droppable-slot-height':!(restrictedSlots.indexOf(rowdata.status.scheduleStatus) > -1)}" *ngIf="rowdata['playerDetail']">
            <app-tee-player-details [fromTeeSheet]="fromTeeSheet" class=" app-tee-player-details"  [teeSheetID]="teeSheetID" [customTableData]="customTableData" [PlayerCollection]="rowdata"
            [overrideRateTypePerDynamicAvailability]="overrideRateTypePerDynamicAvailability"
             [FiltSec]="filtervalue" [PlayerCollectionList]="playerCollectionList" [isMultiCourse]="isMultiCourse" [isFromMove]="isFromMove" (DrapDropHandler)="dragDropHandler($event)" (OnGridDragStart)="onGridDragStart($event)"
              (OnRemovePlayer)="removePlayer($event,rowdata)" [courseId] = "courseId" (onDeletePlayer)="deletePlayer($event,rowdata)" (OnDropFailed)="dropFailed($event)"
              (OnEditPlayer)="editPlayer($event, rowdata)" (gridRefresh)="gridRefresh($event)" (RefreshGrid)="refreshGrid($event)" (isPayFormDirty)="payFormDirty($event)" (updatePopoverEmitter)="updatePopoverEmit($event)">
            </app-tee-player-details>
          </div>
          <div class="tee-icons" [ngClass]="{'compare-view': customTableData.isMultiView && !customTableData.isDetailView}" *ngIf = "!(rowdata?.playerDetail?.length<=0 && rowdata.allocation?.closed)">
            <span *ngIf="customTableData.isHoleNotificationEnabled && rowdata?.holes"><i  aria-hidden="true" 
                class='icon-course-flag ag-mr-1'></i>{{rowdata?.holes}}</span>
            <span *ngIf="rowdata.status.scheduleStatus!=scheduleStatus.hold && customTableData.isCommentNotificationEnabled && (rowdata.comments && (rowdata.comments.length > 0))"
              class="icon-information" [popover]="commentsPopover"
              [popoverOnHover]="(rowdata.comments != '')? true:false"
              (mouseover)="mouseOver($event,rowdata.comments)" #commentsPopOverTarget (onShown)="onShown($event)"></span>
            <span *ngIf="customTableData.isActionAvailable && customTableData.isMenuEnabledOnEllipsis && (rowdata?.playerDetail[0]?.tournamentId>0 || rowdata?.playerDetail?.length<=0 || rowdata?.playerDetail[0]?.teeTimeFormat === 2 || rowdata?.playerDetail[0]?.teeTimeFormat === 3) && rowdata?.status.scheduleStatus!=scheduleStatus.tempHold && rowdata?.type != scheduleType.crossOverBlock" class="tee-actions icon-more"
              [matMenuTriggerFor]="menu" (click)="setRowObject(rowdata)" [ngClass]="{'view-only':gridDisable }"></span>
            <span [attr.automationId]="'Btn_TeeGrid_openBookTeeTimeSlider'" *ngIf="customTableData.isActionAvailable && !customTableData.isMenuEnabledOnEllipsis && rowdata.type != scheduleType.crossOverBlock" class="tee-actions icon-more"
              (click)="openBookTeeTimeSlider(rowdata)" [ngClass]="{'view-only':gridDisable }"></span>
          </div>
          <div class="tee-status" *ngIf="restrictedSlots.indexOf(rowdata.status.scheduleStatus) > -1">
            <i  aria-hidden="true" class="ag-mr-1" [ngClass]="rowdata.slotBackgroundInfo? rowdata.slotBackgroundInfo.icon: ''"></i>
            <span class="align-to-middle">{{rowdata.slotBackgroundInfo?.name}}</span>
            <span  class="bulk-hold-comment align-to-middle text-ellipsis" *ngIf="rowdata.status.scheduleStatus==3 && ((rowdata.comments && rowdata.comments.length > 0)||(rowdata.userName && rowdata.userName.length>0))" [matTooltip]="rowdata.comments + ' ( '+ rowdata.userName+ ' )' "> {{rowdata.comments}} ({{rowdata.userName}})</span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <mat-menu #menu="matMenu" xPosition="before">
    <button [attr.automationId]="'Btn_TeeGrid_bookTeeTime'" mat-menu-item (click)="bookTeeTime()" *ngIf="!customTableData?.isBookHidden" [disabled]="customTableData.isBookDisabled">{{captions?.book}}</button>
    <button [attr.automationId]="'Btn_TeeGrid_resetTeeTime'" mat-menu-item (click)="resetTeeTime()" *ngIf="!customTableData?.isResetHidden" [disabled]="customTableData.isResetDisabled">{{captions?.reset}}</button>
  </mat-menu>
</section>
<section id="teeGrid" *ngIf="!teeData || (teeData && teeData.length <= 0)" class="height-100 norecords-alignment">
    <div class= 'no-schedule-img' *ngIf = '!hideNoSchedule'>
        <div>
            <span class="icon-no-teetime"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span><span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span><span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span><span class="path29"></span><span class="path30"></span><span class="path31"></span><span class="path32"></span><span class="path33"></span><span class="path34"></span><span class="path35"></span><span class="path36"></span><span class="path37"></span><span class="path38"></span><span class="path39"></span><span class="path40"></span><span class="path41"></span><span class="path42"></span><span class="path43"></span><span class="path44"></span><span class="path45"></span><span class="path46"></span><span class="path47"></span><span class="path48"></span><span class="path49"></span><span class="path50"></span><span class="path51"></span><span class="path52"></span><span class="path53"></span><span class="path54"></span><span class="path55"></span><span class="path56"></span><span class="path57"></span><span class="path58"></span><span class="path59"></span><span class="path60"></span></span>
            <p class="text-center" *ngIf='captions'>{{captions?.noTeeTime}}</p>
        </div>
      </div>
</section>

<popover-content #commentsPopover placement="bottom" [animation]="true" [closeOnClickOutside]="true"
  class="commentsPopover" container="#teeGrid">
  <div class="comments-header">Comments</div>
  <div class="comments-body">{{teeComments}}</div>
</popover-content>