import { Injectable } from '@angular/core';

@Injectable()
export class BulkMoveModalService {
 

  getCaptions() {
    return {
        date: 'Select Date',
        course: 'Course',
        allPlayers: 'All Players'
    };
}

getPlayersDetails() {
    return [
        {
            label: 'Course',
            value: 'Jones'
        },
        {
            label: 'Tee Time',
            value: '9:24AM'
        },
        {
            label: 'Players',
            value: 4
        }
    ];
}



getCourses() {
    return [
        {
            value: 'jones',
            viewValue: 'Jones'
        },
        {
            value: 'jones',
            viewValue: 'Jones'
        },
        {
            value: 'jones',
            viewValue: 'Jones'
        },

    ];
}
getPlayerInformation() {
    return [
        {
            playerName: 'Brad AdamonisBrad AdamonisBrad AdamonisBrad AdamonisBrad AdamonisBrad Adamonis',
            playerId: 1
        },
        {
            playerName: 'Jordan Spieth',
            playerId: 2
        },
        {
            playerName: 'Tiger Woods',
            playerId: 3
        },
        {
            playerName: 'Rory Mcllory',
            playerId: 4
        },
        {
            playerName: 'Dustin Johnson',
            playerId: 5
        },
        {
            playerName: 'Patrick Reed',
            playerId: 6
        },
        {
            playerName: 'Webb Simpson',
            playerId: 7
        },
        {
            playerName: 'Henrik Stenson',
            playerId: 8
        },
        {
            playerName: 'Catherine Statucki',
            playerId: 9
        },
        {
            playerName: 'Ian Poulter',
            playerId: 10
        },
    ]
}


}
