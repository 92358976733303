<section id="consent-management-wrapper" class="h-100">
    <ng-container>
        <app-dialog-header [automationId]="automationId" [title]="captions.lbl_consentPolicy" (closeDialog)="onCancel($event)">
        </app-dialog-header>
    </ng-container>
    <div *ngIf="showComment || showConsentComment" class="message-notification">
        <app-warning-bar [message]="message" (closeWarning)="hideWarning()" [showClose]="true"></app-warning-bar>
    </div>
    <div class="table_wrapper" [ngClass]="{'warning-table-wrapper' : showComment || showConsentComment }">
        <div class="table-container h-100">
            <app-cdkvirtual attr.automationId='Tbl_{{automationId}}_consentManagementTable' [headerOptions]="headerOptions" [options]="options" [tableContent]="tableContent"
                [isViewMode]="guestPolicyDetail?.isPurged" (EmittedData)='tableAction($event)'
                [childTemplate]="childTemplate">
            <div class="no-data-img">
                <img src="./assets/images/SearchIllustration.png" alt="No Data"></div>
            </app-cdkvirtual>
        </div>
    </div>
    <div class="create-guest-footer ag_footer--actions">
        <app-button [automationId]="automationId" [buttontype]="actionButton"  (valueChange)='onSave($event)'></app-button>
        <app-button [automationId]="automationId" [buttontype]="cancelButton"  class="ag_ml--2" (valueChange)='onCancel($event)'></app-button>
    </div>
</section>

<ng-template #childTemplate let-element="element" let-data="data" let-key="key" let-index="idx">

    <ng-container [ngSwitch]="key">
        <ng-container *ngSwitchCase="'fromDate'">
            <mat-form-field class="datepicker-container" [floatLabel]="floatLabel"
                [ngClass]="{'ag_section--disable': ! element.checked}">
                <input attr.automationId='Txt_{{automationId}}_fromDate' matInput class="table-input alignCenter" dateRestricter [matDatepicker]="picker"
                    [(ngModel)]="element.fromDate.value" (dateChange)="emitTableData($event,element,data,key)"
                    [disabled]="!element.checked || guestPolicyDetail?.isPurged" [min]="element.fromDate.minDate"
                    [max]="element.fromDate.maxDate" >
                <mat-datepicker-toggle matSuffix [for]="picker"
                    [disabled]="guestPolicyDetail?.isPurged"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="'toDate'">
            <mat-form-field class="datepicker-container" [floatLabel]="floatLabel"
                [ngClass]="{'ag_section--disable': ! element.checked}">
                <input matInput class="table-input alignCenter" dateRestricter [matDatepicker]="picker1"
                    [(ngModel)]="element.toDate.value" [min]="element.fromDate.value" [max]="element.toDate.maxDate"
                    [disabled]="!element.checked || guestPolicyDetail.isPurged"
                    (dateChange)="emitTableData($event,element,data,key)" attr.automationId='Txt_{{automationId}}_toDate'>
                <mat-datepicker-toggle matSuffix [for]="picker1"
                    [disabled]="guestPolicyDetail?.isPurged"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>

        </ng-container>
        <ng-container *ngSwitchCase="'comments'">
            <input class='table-input alignRight' [disabled]="!element.checked" [(ngModel)]="element.comments"
                (blur)='emitTableData($event,data, key)' attr.automationId='Txt_{{automationId}}_comments'>
        </ng-container>
    </ng-container>
</ng-template>