import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { HoldConfirmationTeeTimeBusiness } from './hold-confirmation-tee-time-bussiness'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { takeUntil } from 'rxjs/operators';
import { StepperService } from 'src/app/shared/components/stepper/stepper.service';
import { of, ReplaySubject } from 'rxjs';
import { Localization } from 'src/app/common/localization/localization';
import { ButtonAction } from 'src/app/shared/global.constant';
import { DialogCloseObj } from 'src/app/shared/shared-models';

@Component({
  selector: 'app-hold-confirmation-tee-time',
  templateUrl: './hold-confirmation-tee-time.component.html',
  styleUrls: ['./hold-confirmation-tee-time.component.scss'],
  providers: [HoldConfirmationTeeTimeBusiness]
})

export class HoldConfirmationTeeTimeComponent implements OnInit {
  holdTeeTimeForm: UntypedFormGroup;
  untills: Promise<DropdownOptions[]>;
  untillHoles: Promise<DropdownOptions[]>;
  @Output() notifyParent = new EventEmitter(); 
  time: string;
  data: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  captions:any;
  selectedScheduleDate: Date;
  floatLabel: string;
  constructor(private fb: UntypedFormBuilder, private _HoldTeeTimeBusiness : HoldConfirmationTeeTimeBusiness,
    public dialogRef: MatDialogRef<HoldConfirmationTeeTimeComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData, public stepperService: StepperService, private localization: Localization) { 
      this.floatLabel = this.localization.setFloatLabel;
    }

  ngOnInit(): void {
    this.data = this.dialogData;
    this.captions = this.localization.captions;
    this.time = this.data.info.time;
    this.selectedScheduleDate= this.localization.getDate(this.time);
    this.createHoldTeeTimeForm();
    this.untills = this._HoldTeeTimeBusiness.GetUntills(this.data.info.course.id,this.selectedScheduleDate);
    this.untills.then(f=>{
      let selectedSlotHoles=this._HoldTeeTimeBusiness.untillHoles.map(a => {
        return <DropdownOptions>{
          value: a,
          viewValue: a.toString(),
        }
      });     
      this.untillHoles = of(selectedSlotHoles).toPromise();
    })
    this.stepperService.valueChange.pipe(takeUntil(this.destroyed$)).subscribe((data: DialogCloseObj) => {
      //save goes here
      if(data.type == ButtonAction.save){
        this.stepperService.enableSave = false;
        this._HoldTeeTimeBusiness.saveBulkHoldTeeTime(this.holdTeeTimeForm.value,this.selectedScheduleDate,this.data.info).then(e=>{
          data.dialogRef.close(this.holdTeeTimeForm.value);
        });            
      }
    })
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }


  createHoldTeeTimeForm() {
    this.holdTeeTimeForm = this.fb.group({
      untill: [0],
      //untillHole: [0],
      comment: ['']
    });
    this.stepperService.enableSave = this.holdTeeTimeForm.valid;
  } 
}
