import { Injectable } from "@angular/core";
import { refund } from './refund-model';

@Injectable()

export class RefundService
{  

    
    refundArray:refund[]=[
        {
          id:"1",
          value:"Refund",
          checked:true
        },
        {
          id:"2",
          value:"Refund with exchange",
          checked:false
        }
      ]
}