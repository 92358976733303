import { Injectable } from '@angular/core';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { SlotDetails } from 'src/app/tee-time/tee-sheet/hold-tee-time/hold-tee-time.model';
import { HoldTeeTimeService } from 'src/app/tee-time/tee-sheet/hold-tee-time/hold-tee-time.service';
import { Caption, DeleteTeeTimeSlotModel, ModalInfo } from './delete-tee-time-slot.modal.model';

@Injectable({
  providedIn: 'root'
})
export class DeleteTeeTimeSlotBusiness {
  private caption: any;
  openedSlotToDelete: DeleteTeeTimeSlotModel[] = [];
  constructor(private _localization: GolfLocalization,
    private _bulkService: HoldTeeTimeService) {
    this.caption = this._localization.captions.teetime;
  }
  /**
   * GetCaption to fetch the caption
   * @returns 
   */
  GetCaption(): Caption {
    return {
      showAllOpenSlots: this.caption.showAllOpenSlots,
      deleteTeeTimeSlotWarning: this.caption.deleteTeeTimeSlotWarning,
      noOpenSlotExistsWarning: this.caption.noOpenSlotExistsWarning,
      deleteTeeTimeSlotSuccessMessage:this.caption.deleteTeeTimeSlotSuccessMessage
    };
  }
  /**
   * GetModalHeaderInfo method to get the header info to bind
   * @param modalInfo 
   * @returns ModalInfo array
   */
  GetModalHeaderInfo(modalInfo: any): ModalInfo[] {
    return [{
      label: "",
      value: modalInfo && modalInfo?.time && modalInfo?.originalHoleNumber ? `${this._localization.LocalizeTime(modalInfo.time)} (${modalInfo.originalHoleNumber})` : null,
      isTimeIcon: true
    }]
  }
  /**
   * GetCurrentCourseSlotModel method to map current slot info
   * @param modalInfo 
   * @returns 
   */
  GetCurrentCourseSlotModel(modalInfo: any): DeleteTeeTimeSlotModel {
    return modalInfo ? {
      id: 0,
      courseId: modalInfo?.course?.id,
      scheduleDateTime: modalInfo?.time,
      originalHoleNumber: modalInfo?.originalHoleNumber,
      created: null,
      createdDateTime: null,
      isSelected: true
    } : null
  }
  /**
   * GetOpenSlot method to fetch the opened slot info
   * @param courseId 
   * @param ScheduleStartDate 
   * @param ScheduleEndDate 
   * @returns 
   */
  async GetOpenSlot(currentSlotInfo: DeleteTeeTimeSlotModel): Promise<DeleteTeeTimeSlotModel[]> {
    let openSlots = await this._bulkService.GetScheduledTeeTimesSlotDetails(currentSlotInfo.courseId, currentSlotInfo.scheduleDateTime, currentSlotInfo.scheduleDateTime);
    openSlots = openSlots.filter(x => x.isAvailableForHold);
    return this.MapOpenedSlots(openSlots);
  }
  /**
   * MapOpenedSlots method to map the opened slots
   * @param slotDetails 
   * @returns 
   */
  MapOpenedSlots(slotDetails: SlotDetails[]): DeleteTeeTimeSlotModel[] {
    var openedSlot: DeleteTeeTimeSlotModel[] = [];
    slotDetails.map((x) => {
      openedSlot.push({
        id: 0,
        created: null,
        createdDateTime: null,
        courseId: x.courseId,
        originalHoleNumber: x.originalHoleNumber,
        scheduleDateTime: x.scheduleDateTime,
        isSelected: false,
        isTempHold: x.isTempHold
      });
    })
    return openedSlot;
  }
  /**
   * setOpenedSlotToDelete method to update the deleted tee time slot
   * @param data 
   * @param isToRemoveTheSlot 
   * @returns 
   */
  public setOpenedSlotToDelete(data: DeleteTeeTimeSlotModel, isToRemoveTheSlot: boolean = false) {
    if (!isToRemoveTheSlot) this.openedSlotToDelete.push(data);
    else {
      this.openedSlotToDelete = this.openedSlotToDelete.filter(x => !(x.courseId == data.courseId && x.scheduleDateTime == data.scheduleDateTime && x.originalHoleNumber && data.originalHoleNumber));
    }
    return this.openedSlotToDelete;
  }
  public getOpenedSlotToDelete() {
    return this.openedSlotToDelete;
  }
  /**
   * ResetList_deleteTeeTimeSlots method to reset the list with current selected slot
   * @param currentSlotToDelete 
   */
  public ResetList_deleteTeeTimeSlots(currentSlotToDelete: DeleteTeeTimeSlotModel) {
    this.openedSlotToDelete = this.openedSlotToDelete.filter(x => (x.courseId == currentSlotToDelete.courseId && x.scheduleDateTime == currentSlotToDelete.scheduleDateTime && x.originalHoleNumber && currentSlotToDelete.originalHoleNumber));
  }
  /**
   * RetainChosenOpenedSlot method to retain the slots selected by user, by comparing the existing with new opened list 
   * @param lstOpenedSlot 
   * @param currentCourseSlotInfo 
   * @returns 
   */
  public RetainChosenOpenedSlot(lstOpenedSlot: DeleteTeeTimeSlotModel[], currentCourseSlotInfo: DeleteTeeTimeSlotModel) {
    let openedSlotSelectedToDelete = this.getOpenedSlotToDelete();
    let arrUpdatedOpendSlotDetails = lstOpenedSlot.map(x => {
      openedSlotSelectedToDelete.find(y => {
        if ((x.courseId == y.courseId && x.scheduleDateTime == y.scheduleDateTime && x.originalHoleNumber == y.originalHoleNumber) && y.isSelected === true) {
          if (!x.isTempHold) x.isSelected = true;
          else x.isSelected = false;
        }
      });
      return x;
    });
    this.openedSlotToDelete = arrUpdatedOpendSlotDetails.filter(x => x?.isSelected === true);
    if (currentCourseSlotInfo && currentCourseSlotInfo != null)
      this.openedSlotToDelete.push(currentCourseSlotInfo)
    return { openedSlotDetails: arrUpdatedOpendSlotDetails, openedSlotToDelete: this.openedSlotToDelete };
  }
}
