import { Injectable } from '@angular/core';
import { GolfScheduleCommunication } from 'src/app/shared/communication/services/golfschedule.service';
import { TeeTimeModel } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { SlotDetails, TeeTimeBulkAction } from './hold-tee-time.model';

@Injectable({
  providedIn: 'root'
})
export class HoldTeeTimeService {

  constructor(private _golfScheduleCommunication : GolfScheduleCommunication) { }

  public GetScheduledTeeTimesSlotDetails(courseId: number, startDate: Date | string, endDate: Date | string): Promise<SlotDetails[]> {
    try {
        return this._golfScheduleCommunication.getPromise<SlotDetails[]>(
            {
                route: GolfApiRoute.GetTeeTimesSlotDetailsByDateRange,
                uriParams: { startDate, endDate, courseId }
            }, false);            
    } catch (error) {
        console.error(error);
    }
}

  public saveBulkHoldTeeTime(body: TeeTimeBulkAction[]){
    return this._golfScheduleCommunication.postPromise({
      route: GolfApiRoute.HoldBulkTeeTime, body : body
    });   
  }

  public saveBulkHoldReleseTeeTime(body: TeeTimeBulkAction[]){
    return this._golfScheduleCommunication.putPromise({
      route: GolfApiRoute.ReleaseBulkTeeTime, body : body
    });   
  }

  public BlockBulkTeeTime(body: TeeTimeModel[]): Promise<boolean> {
    return this._golfScheduleCommunication.putPromise<boolean>(
      { route: GolfApiRoute.BlockBulkTeeTime, body: body },false);
  }

  public UnBlockBulkTeeTime(playerIds: number[]): Promise<boolean> {
    return this._golfScheduleCommunication.putPromise<boolean>({
      route: GolfApiRoute.UnBlockBulkTeeTime,
      body: playerIds
    },false);
  }
}
