
<div [ngSwitch]="buttontype && buttontype.type" class="custom-button clear">
    <button attr.automationId='Btn_{{automationId}}_{{buttontype.label?.split(" ").join("").toLowerCase()}}' class="golfbutton" [ngClass]="buttontype.customclass" #dynamicbutton *ngSwitchCase="'primary'" [disabled]='buttontype.disabledproperty' mat-flat-button color="primary" [appDisableDoubleClick]="throttleTime" (throttleClick)="handleclick($event)">{{ buttontype.label }}</button>
    <button attr.automationId='Btn_{{automationId}}_{{buttontype.label?.split(" ").join("").toLowerCase()}}' class="golfbutton golf--secondarygreen" [ngClass]="buttontype.customclass" #dynamicbutton  *ngSwitchCase="'secondary'" [disabled]='buttontype.disabledproperty' mat-stroked-button [appDisableDoubleClick]="throttleTime" (throttleClick)="handleclick($event)">{{buttontype.label }}</button>
    <a attr.automationId='Btn_{{automationId}}_{{buttontype.label?.split(" ").join("").toLowerCase()}}' class="golfbutton"  [ngClass]="buttontype.disabledproperty ? 'stopclickfunc ' + buttontype.customclass :buttontype.customclass " #dynamicbutton *ngSwitchCase="'tertiary'" [disabled]='buttontype.disabledproperty' mat-button  href="javascript:void(0)" [appDisableDoubleClick]="throttleTime" (throttleClick)="handleclick($event)">{{buttontype.label }}</a>

    <button attr.automationId='Btn_{{automationId}}_{{buttontype.label?.split(" ").join("").toLowerCase()}}' class="golfbutton golf--secondarygreen" [ngClass]="buttontype.customclass" #dynamicbutton  *ngSwitchCase="'multiselect'" [disabled]='buttontype.disabledproperty' mat-stroked-button color="primary">{{buttontype.label }}</button>
</div>




