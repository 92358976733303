<div class="gift-card-search mt-2"
  [ngClass]="{'align-search':!issueScreen && !isOrderSummary,'space-around':isOrderSummary}">
  <div class="card-number-search" [class.increaseLen]="AllowNameSearch">
    <mat-form-field class="labelHeight"
      [ngClass]="{'mr-4 w-60': issueScreen,'mr-2 searchField spa-searchbox custSearchbox':!issueScreen, 'ht-3':isOrderSummary}"
      [floatLabel]="floatLabel">
      <input matInput [attr.automationId]="'Txt_giftCardsSearch_searchValue'" type="text"
        [placeholder]="fieldPlaceholder" class="searchbox-width" #giftcardSearch (input)="giftcardValChange($event)"
        (paste)="onPaste($event)" (keydown.enter)="PerformSearch(); $event.preventDefault()"
        [(ngModel)]="GiftCardNumber" maxlength="50" autocomplete="off" [disabled]="isTopUp">
    </mat-form-field>
    <span class="icon-border-section cursor" [ngClass]="isTopUp ? 'button_invalid':''" (click)="PerformSearch()">
      <i [attr.automationId]="'Icn_giftCardsSearch_searchIcon'" aria-hidden="true" class="icon-Search"></i>
    </span>
    <span [attr.automationId]="'Icn_giftCardsSearch_advancedSearch'"
      [matTooltip]="localization.captions.shop.GiftCard.GiftcardAdvancedSearchPopup.lbl_advanceSearch"
      class="icon-border-section cursor" *ngIf="AllowNameSearch" [ngClass]="isTopUp ? 'button_invalid':''"
      (click)="PerformAdvancedSearch()">
      <i aria-hidden="true" class="icon-Advanced-Search"></i>
    </span>
  </div>
  <div class="swipe-option-text">({{captions.OR}})</div>
  <app-giftcard-swipe class="app-giftcard-swipe"
    *ngIf="giftcardbusiness.IsGiftCardConfigLoaded && userSessionConfigLoaded" [screenType]="screenType"
    [isThirdPartyGiftcard]="CurrentThirdPartyValue" (GiftCardInfo)="HandleGiftCardData($event)" [isTopUp]="isTopUp"
    [userSessionConfiguration]="userSessionConfiguration" [outletId]="currentOutletId" [isRefund]="isRefund"
    [automationId]="'retailGiftCardsSearch'">
  </app-giftcard-swipe>
  <div class="d-flex" *ngIf="!issueScreen && !isOrderSummary">
    <div class="form-label-normal third-party-lbl d-block">{{captions.ThirdParty}}</div>
    <app-retail-toggle-switch [attr.automationId]="'Tog_giftCardsSearch_thirdParty'"
      [formControl]="giftCardsFormGroup.controls['thirdparty']"></app-retail-toggle-switch>
  </div>
</div>