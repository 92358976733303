import { Injectable } from '@angular/core';
import { RecurringType } from './recurring.modal';
import moment from 'moment';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
@Injectable()
export class RecurringService {
  commonCaptions: any;

  constructor(private localization: GolfLocalization) {
    this.commonCaptions = this.localization.captions.common;
    this.defaultRecurringData = { id: 1, name: this.commonCaptions.Daily, description: 'Daily' };
    this.weeklyData =  this.localization.daysArray.filter(x=>x.code!=='All').map(x=> {return { id: (x.id == 7) ? 0 : x.id, name:x.short, description: x.code, disabled: false}});
    this.months = this.localization.monthsArray.map(x=> {return { id:x.id, name:x.short, description: x.code}});


    this.monthSelectionType = [
      { id: 1, name: this.commonCaptions.Date, description: 'Date' },
      { id: 2, name: this.commonCaptions.Day, description: 'Day' }
    ];

    this.onoccurence = [
      { id: 0, name: this.commonCaptions.first, description: 'First' },
      { id: 1, name: this.commonCaptions.second, description: 'Second' },
      { id: 2, name: this.commonCaptions.third, description: 'Third' },
      { id: 3, name: this.commonCaptions.fourth, description: 'Fourth' }
    ];
    
  }
  defaultRecurringData: RecurringType;
  weeklyData: RecurringType[];
  monthSelectionType: RecurringType[];
  onoccurence: RecurringType[];
  months: RecurringType[];

  generateCaptions() {
    return {
      Every: this.commonCaptions.every,
      Days: this.commonCaptions.Days,
      Weeks: this.commonCaptions.Weeks,
      Months: this.commonCaptions.Months,
      Chooseadate: this.commonCaptions.chooseDate,
      Recurring: this.commonCaptions.recurring,
      On: this.commonCaptions.On
    }
  }
  generateErrorCaptions() {
    return {
      MissingRepeatDays: this.commonCaptions.MissingRepeatDays,
      MissingRepeatWeeks: this.commonCaptions.MissingRepeatWeeks,
      MissingDayofWeek: this.commonCaptions.MissingDayofWeek,
      MissingRepeatMonths: this.commonCaptions.MissingRepeatMonths,
      MissingRepeatDay: this.commonCaptions.MissingRepeatDay,
      MissingMonth: this.commonCaptions.MissingMonth,
      ValidRangeDaily: this.commonCaptions.ValidRangeDaily,
      ValidRangeWeekly: this.commonCaptions.ValidRangeWeekly,
      ValidRangeMonthly: this.commonCaptions.ValidRangeMonthly
    }
  }

  dateDiff(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    return end.diff(start, 'days');
  }

  monthDiff(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    return end.diff(start, 'month');
  }

  yearDiff(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    return end.diff(start, 'year');
  }
}
