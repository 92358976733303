
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FormChange } from "src/app/lessons/lessons.model";
import { Tournament } from 'src/app/tee-time/tournaments/tournaments.model';
import { RateType } from 'src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal';
import { PlayerType } from 'src/app/settings/golf-setup/code-setup/player-type/player-type.modal';
import { PlayerAdditionalDetails } from "src/app/tee-time-actions/teetime/tee-time.model";
@Injectable()
export class PlayerDetailService {
    lessonBookingFormValues: {
        playerInfoForm: any,
        playerContactForm: any,
        paymentInfoForm: any
    };
    lessonFormChange$: BehaviorSubject<FormChange>;
    existingPlayerAdditionalDetails: PlayerAdditionalDetails[] = [];
    playerAdditionalDetails: PlayerAdditionalDetails[] = [];
    actionId: any;
    endTime: any;    
    rateTypes: RateType[];
    allPlayerTypes:PlayerType[];
    playerInfo:Tournament.UI.ContactInformation;
    generatePlayerType() {
        return [
            { id: 1, Description: 'Local' }
        ];
    }
    generateRateType() {
        return [
            { id: 1, Description: 'Public 18' }
        ];
    }
    generateAllocationCode() {
        return [
            { id: 1, Description: '123456' }
        ];
    }
    generateLessonLocation() {
        return [
            { id: 1, Description: 'Lake View' }
        ];
    }

    public createLessonFormChange() {
        this.lessonFormChange$ = new BehaviorSubject<FormChange>(null);
    }
    public completeFormChange() {
        this.lessonFormChange$.next(null);
        this.lessonFormChange$.complete();
    }
}
