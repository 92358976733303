import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { TeeGridService } from './tee-grid.service';
import { TeeGridBusiness } from './tee-grid.business';
import { TeeSheetSkeletonData, ScheduleStatus,  ScheduleType, TeeSheetTableID, TeeSheetPlayerDetails, DeletePlayerEvent } from '../../models/teesheet.form.models';
import * as _ from 'lodash';
import {  ComponentDetails} from '../../shared-models';
import * as WaitlistInterface from 'src/app/tee-time/waitlist/waitlist.model';
import { ReplaySubject } from 'rxjs';
import { TeeTimeModel } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { UserAccessBreakPoints } from '../../constants/useraccess.constants';
import { TeeTimesActionBusiness } from 'src/app/tee-time/shared/tee-action.business';


@Component({
  selector: 'app-tee-grid',
  templateUrl: './tee-grid.component.html',
  styleUrls: ['./tee-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TeeGridBusiness],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class TeeGridComponent implements OnInit {
  @Input() teeData: TeeSheetSkeletonData[];
  @Input() customTableData;
  @Input() teeSheetID: TeeSheetTableID;
  @Input() gridDisable: false;
  @Input() defaultSelected: number;  
  @Input() isMultiCourse: false;
  @Input() fromTeeSheet: false;
  @Input() isFromMove : boolean;
  @Input ('AddFiltSec')
  set showAvailable(value) {
    this.filtervalue=value;
  }
  @Input('showAvailableSlots')
  set showAvailableSlots(value: boolean) {
    this._showAvailableSlots = value;
    this.filterExpression = this._TeeGridBusiness.applyTeeSheetFilter(this._hideCrossOverBlocks, this._showAvailableSlots);
  }

  @Input('showFullyAvailableSlots')
  set showFullyAvailableSlots(value: boolean) {
    this.filterExpression = this._TeeGridBusiness.applyFullyTeeSheetFilter(this._hideCrossOverBlocks, value);
  }

  @Input('hideCrossOverBlocks')
  set hideCrossOverBlocks(value: boolean) {
    this._hideCrossOverBlocks = value;
    this.filterExpression = this._TeeGridBusiness.applyTeeSheetFilter(this._hideCrossOverBlocks, this._showAvailableSlots);
  }
  @Input('sortGrid')
  set sortGrid(value: boolean) {
    this._sortGrid = value;
    this.sortTeeSlots(value);
  }
  @Output() moreEvent = new EventEmitter();
  @Output() dragDropEvent = new EventEmitter();
  @Output() onRemovePlayer = new EventEmitter();
  @Output() onDragGridPlayers = new EventEmitter();
  @Output() onPlayerCollectionListEmit = new EventEmitter();
  @Output() onDeletePlayer = new EventEmitter<DeletePlayerEvent>();
  @Output() OnEditPlayer = new EventEmitter();
  @Output() refreshGrid = new EventEmitter();
  @Output() onDropValidationFailed = new EventEmitter<boolean>();
  @Output() updatePayPopoverEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() singleViewDragList;
  @Input() multiViewDragList;
  @Input() teeTimeInfo: TeeTimeModel;
  @Input() courseId: number = 0;
  captions: any;
  bulkBookingId: string = '';
  restrictedDropSlots: string[] = [];
  selectedRowObject: any;
  teeComments: string;
  filterExpression: any;
  scheduleStatus = ScheduleStatus;
  scheduleType = ScheduleType;
  slotBoundaries: any; //event data
  playerCollectionList: any[] = [];
  restrictedSlots: any[] = [];
  playerIDs: any = [];
  popUpComponentDetails: ComponentDetails;
  waitList: WaitlistInterface.WaitListViewModel[];
  data: any; 
  course: number;
  date: string;
  currentDate:Date;
  filtervalue;
  overrideRateTypePerDynamicAvailability: boolean = false;
  private _hideCrossOverBlocks: boolean = false;
  private _showAvailableSlots: boolean = false;
  private _sortGrid: boolean = false;
  public $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);

  @ViewChild('commentsPopover') commentsPopover;
  @ViewChild('commentsPopOverTarget') commentsPopOverTarget;
  @Output() isPayFormDirty = new EventEmitter<boolean>();
  constructor(public _TeeGridService: TeeGridService, private _TeeGridBusiness: TeeGridBusiness, private ref: ChangeDetectorRef, private teeActionBusiness: TeeTimesActionBusiness) { }

  ngOnInit() {
    this.captions = this._TeeGridBusiness.getCaptions();
    if (this.scheduleStatus) {
      this.restrictedSlots = [this.scheduleStatus.hold, this.scheduleStatus.frostDelay];
    }
    this.teeActionBusiness.validateBreakPointAccess(UserAccessBreakPoints.OVERRIDE_UNAVAILABLE_RATETYPE_PER_DYNAMICAVAILABILITY,false).then(res => {
      this.overrideRateTypePerDynamicAvailability = res.isAllow;
    })
    //console.log("Filterr",this.AddFiltSec)
  }
  ngOnChanges() {
    this.restrictedDropSlots = this._TeeGridBusiness.getRestrictedDropSlots();
    this.bindPlayerDetails();
    
    this.applySort();
  }

  private applySort(): void {
    if (this._sortGrid) {
      this.sortTeeSlots(this._sortGrid);
    }
  }
  private sortTeeSlots(value: boolean): void {
    if (this.teeData) {
      this.teeData = _.cloneDeep(this.teeData.sort((left: TeeSheetSkeletonData, right: TeeSheetSkeletonData) =>
        this._TeeGridBusiness.sortTees(left, right, value ? 'HOLE' : 'TIME')));
    }
  }


  // Call this method to bind the API result playerData into Skeleton Data.
  bindPlayerDetails() {    
    if (this.teeData) {
      this.teeData.forEach(slot => {
        slot.playerDetail.forEach((p) => {
          if (p.isDragDisabled !== false) {
            p.isDragDisabled = this.customTableData.isDragDisabled;
          }
        });
      });
      this.bindRowId();


      // open pop-up by default
      if (this.defaultSelected) {
        let selectedRow = this.teeData.find(x => x.scheduledTeeTimeId == this.defaultSelected);
        if (selectedRow) this.moreEvent.emit(selectedRow);
      }
    }
    this.ref.detectChanges();
    
  }



  bindRowId() {
    try {
      if (this.customTableData.isMultiView) {
        this.playerCollectionList = [];
        this.playerCollectionList = [...this.singleViewDragList, ...this.multiViewDragList];
      }
      else {
        if (this.teeData) {
          let playerIDList = [];
          for (let index = 0; index < this.teeData.length; index++) {
            playerIDList.push(this.teeData[index].id);
          }
          this.playerCollectionList = playerIDList;
          this.onPlayerCollectionListEmit.emit(this.playerCollectionList);
        }
      }

    } catch{
      this.ref.detectChanges();
    }
  }

  dragDropHandler(dragDropObj) {    
    let dropPositionObject = {};
    let dragPositionObject = {};
    let dropRowIndex = -1;
    let dragRowIndex = -1;
    this.teeData.forEach((element, index) => {
      if (element.id === dragDropObj.dropPositionID) {
        dropPositionObject = element;
        dropRowIndex = index;
      }
      if (element.id === dragDropObj.dragPositionID) {
     
        dragRowIndex = index;
      }
    });

    this.dragDropEvent.emit({
      dragData: dragDropObj.dragData,
      dragPositionObject: dragDropObj.dragPositionObject,
      dropPositionObject: dropPositionObject,
      dragIndex: dragDropObj.dragIndex,
      dropIndex: dragDropObj.dropIndex,
      dragOrigin: dragDropObj.dragOrigin,
      currentState: this.teeData,
      initialState: undefined,
      dropPositionRowIndex: dropRowIndex,
      dragPositionRowIndex: dragRowIndex,
      playerRetailItems : dragDropObj.playerRetailItems
    });
  }

  onGridDragStart(e) {
    this.onDragGridPlayers.emit(e);
  }

  async removePlayer($event: TeeSheetPlayerDetails, rowObject: TeeSheetSkeletonData) {
    let playerDetails = {
      rowData: rowObject,
      playerData: $event,
      initialState: undefined,
      currentState: this.teeData
    }
    this.onRemovePlayer.emit(playerDetails);
  }

  async editPlayer($event: TeeSheetPlayerDetails, rowObject: TeeSheetSkeletonData, index: number) {
    this.OnEditPlayer.emit([rowObject, $event[1], $event[0]]);  
  }

  async deletePlayer($event: TeeSheetPlayerDetails, rowObject: TeeSheetSkeletonData) {
    const deleteEvt: DeletePlayerEvent = {
        teeSlotDetail: rowObject,
        playerDetail: $event
    }
    this.onDeletePlayer.emit(deleteEvt);  
  }

  dropFailed($event) {
    this.onDropValidationFailed.emit($event);
  }
 
 
  setRowObject(selectedTeeRow) {
    this.selectedRowObject = selectedTeeRow;
  }

  openBookTeeTimeSlider(selectedTeeRow) {
    // Open Slider
    this.moreEvent.emit(selectedTeeRow);
  }

  async bookTeeTime() {
    // Open Book Tee Time Popup
    this.moreEvent.emit({
      'from': 'book',
      'data': this.selectedRowObject
    });
  }

  resetTeeTime() {
    this.moreEvent.emit({
      'from': 'reset',
      'data': this.selectedRowObject
    });
  }

  mouseOver(e, info) {  
    this.teeComments = info;
    this.slotBoundaries = e.currentTarget.getBoundingClientRect();
    this.ref.detectChanges();
  }

  

  onShown(e) {
    let arrIndex = (this.teeSheetID.tableID === 'TableRight' ? 1 : 0);
    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;

    let popOverWidth = this.commentsPopover.element.nativeElement.childNodes[0].offsetWidth + 50;
    let popOverHeight = this.commentsPopover.element.nativeElement.childNodes[0].offsetHeight + 50;

    let actualLeft = e.content.left;

    let setLeftPosition = (this.slotBoundaries.left + (this.slotBoundaries.width / 2)) - (popOverWidth / 2);
    let setTopPosition = this.slotBoundaries.top + this.slotBoundaries.height;

    let spaceAcrossWidth = windowWidth - setLeftPosition;
    let spaceAcrossHeight = windowHeight - setTopPosition;

    let popOverTargetPosition = this.commentsPopOverTarget.nativeElement.getBoundingClientRect();
   
    let cdkOverlayPaneWidth = 0;
    let cdkOverlayPaneOffsetLeft = 0;
    let offsetRight = 0;   
    let cdkOverlayPane = document.getElementsByClassName('cdk-overlay-pane')[0] as HTMLElement;
    if (this.customTableData.isTabTooltip) {
      cdkOverlayPaneWidth = cdkOverlayPane.offsetWidth;
      cdkOverlayPaneOffsetLeft = cdkOverlayPane.offsetLeft;
      offsetRight = cdkOverlayPaneWidth + cdkOverlayPaneOffsetLeft;
    } else {
      offsetRight = windowWidth;
    }   





    if (this.customTableData.isTabTooltip) {
      if (actualLeft < 0) {
        e.content.left = 0;
        let calcArrow = 20;
        this.checkArrowTimeout(calcArrow, 0);
      } else if ((actualLeft + popOverWidth + cdkOverlayPaneOffsetLeft + 5) > offsetRight) {
        e.content.left = offsetRight - (popOverWidth + cdkOverlayPaneOffsetLeft);
       

        let calcArrow = (((setLeftPosition - e.content.left) / popOverWidth) - ((this.slotBoundaries.width / 2) / popOverWidth));
        if ((calcArrow * 100) < 10) {
          this.checkArrowTimeout((1 - ((popOverTargetPosition.width / 2) / popOverWidth)) * 100, 0);
        } else {
          this.checkArrowTimeout((calcArrow) * 100, 0);
        }
      }
    } else {
      if (actualLeft < 0 || setLeftPosition < 0 || (this.slotBoundaries.left < popOverWidth / 2)) {
        //check left position with window            
        e.content.left = 0;
        let calcArrow = ((this.slotBoundaries.left / popOverWidth) + ((this.slotBoundaries.width / 2) / popOverWidth));
        this.checkArrowTimeout(calcArrow * 100, arrIndex);
      } else if (spaceAcrossWidth < popOverWidth) {
        // check right position with window 
        e.content.left = windowWidth - popOverWidth;
        let calcArrow = (((windowWidth - this.slotBoundaries.left) / popOverWidth) - ((this.slotBoundaries.width / 2) / popOverWidth));
        if ((calcArrow * 100) < 10) {
          this.checkArrowTimeout((1 - ((this.slotBoundaries.width / 2) / popOverWidth)) * 100, arrIndex);
        } else {
          this.checkArrowTimeout((1 - calcArrow) * 100, arrIndex);
        }
      } else {
        e.content.left = setLeftPosition;
        this.checkArrowTimeout('50', arrIndex);
      }

      //check bottom position with window    
      if (spaceAcrossHeight < popOverHeight) {
        e.content.top = this.slotBoundaries.top - popOverHeight;
      } else {
        e.content.top = setTopPosition;
      }
    }


  }

  checkArrowTimeout(arrowPosition, arrIndex) {
    let checkArrow = setTimeout(() => {
      let popOverArrow = document.getElementsByClassName('commentsPopover')[arrIndex].querySelector('.arrow') as HTMLElement;
      if (popOverArrow) {
        popOverArrow.style.left = arrowPosition + '%';
        clearTimeout(checkArrow);
      }
    }, 0);
  }

  payFormDirty(eve) {
    this.isPayFormDirty.emit(eve)
  }
  gridRefresh(eve) {
    this.refreshGrid.emit(eve);
  }
  updatePopoverEmit(e){
    this.updatePayPopoverEmit.emit(e);
  }
}
