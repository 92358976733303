import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { guestType, playerCategory } from 'src/app/lessons/lessons-modal/player-info/player-info.model';
import { StepperService } from 'src/app/shared/components/stepper/stepper.service';
import { ButtonAction } from 'src/app/shared/global.constant';
import { DialogCloseObj } from 'src/app/shared/shared-models';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { ContactService } from 'src/app/tee-time/tournaments/tournaments-modal/contact/contact.service';
import { RateSetupData } from '../teetime/player-details/player-info.model';
import { LinkMultipackBusiness } from './link-multipack-business';
import * as _ from 'lodash';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { PlayerPaymentstatus } from 'src/app/tee-time/search/search-model';
import { AlertType, ButtonTypes } from 'src/app/common/enums/shared-enums';
@Component({
  selector: 'app-link-multipack',
  templateUrl: './link-multipack.component.html',
  styleUrls: ['./link-multipack.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LinkMultipackComponent implements OnInit {

  linkForm: UntypedFormGroup;
  captions: any;
  showExpiredToggle: boolean = true;
  Selectedpack: any = [];
  isMultiPackTouched: boolean = false;
  public selectedIndex: number;
  selectedClientId: number;
  public sessiondata: any = [];
  public allSessiondata: any = [];
  saveSubscriber: Subscription;
  multiPackResponse: any;
  playerResponse: any;
  shopItemResponse: any;
  //rateTypeResponse: any;
  playerLinkResponse: boolean = false;
  isFromUnlink: boolean = false;
  rateSetup: RateSetupData[];


  constructor(private fb: UntypedFormBuilder, public localization: GolfLocalization, public _StepperService: StepperService
    , private _linkMultipackBusiness: LinkMultipackBusiness
    , private _contactService: ContactService
    , private _utilities: GolfUtilities
    , private _PropertyInformation: GolfPropertyInformation
    , public dialogRef: MatDialogRef<LinkMultipackComponent>
    , @Inject(MAT_DIALOG_DATA) public dialogData) { }

  ngOnInit() {
    this.formGenerator();
    this.generateData();
    if (this.dialogData.teeTimeType != 'multipack') {      
      this.isFromUnlink = true;
      this.showExpiredToggle = false;
    }
    else {
      this.isFromUnlink = false;
      this.showExpiredToggle = true;
    }
    this._StepperService.enableSave = true;
    this.saveSubscriber = this._StepperService.valueChange.subscribe((x: DialogCloseObj) => {
      if (x.type == ButtonAction.save) {
        this.submitForm(x);
      }
    });
  }

  async getMultiPackDetails(player: any) {
    const session: any[] = [];
    player.forEach(element => {
      session.push(
        {
          guestId: element.playerLinkId,
          guestType: element.playerCategoryId == 3 ? guestType.member : guestType.guest
        }
      );
    });
    return await this._linkMultipackBusiness.GetMultiPackDetails(session);
  }

  buildClientRetailItems(clientMultipackItems, playerId, rateTypeId) {
    const data: any = [];
    clientMultipackItems.forEach(res => {
      const greenFeeId = this.rateSetup.find(c => c.rateTypeId == rateTypeId).greenFeeRetailItemId;
      const cartFeeId = this.rateSetup.find(c => c.rateTypeId == rateTypeId).cartFeeRetailItemId;
      var containsGreenFeeItem = false;
      var containsCartFeeItem = cartFeeId == 0 ? true : false;
      containsGreenFeeItem = res.filter(c => c.linkedRetailItemId == greenFeeId).length > 0 ? true : false;
      containsCartFeeItem = res.filter(c => c.linkedRetailItemId == cartFeeId).length > 0 || containsCartFeeItem ? true : false;
      res.forEach(element => {
        if (data && data.filter(c => c.transactionDetailId == element.transactionDetailId).length == 0) {
          const dateDifference = this.calculateDiff(element.expirationDate);
          if (dateDifference <= 365) {
            data.push(
              {
                id: element.transactionDetailId,
                name: this.shopItemResponse.find(c => c.id == element.retailItemId)?.itemDescription,
                SoldOn: this.localization.LocalizeDate(element.soldOn),
                ValidUpto: this.localization.LocalizeDate(element.expirationDate),
                RemainingSessions: Number(element.remaining),
                expired: !this.checkExpireDate(element.expirationDate, this.dialogData.info.time),
                SoldTo: this.playerResponse.find(c => c.playerLinkId == element.guestId).firstName + ' ' + this.playerResponse.find(c => c.playerLinkId == element.guestId).lastName,
                display: true,
                clientId: 0,
                enabled: (((containsGreenFeeItem && containsCartFeeItem) && element.isReturned == false && element.remaining != '0' && this.compareDate(element.startDate, this.dialogData.info.time) && this.checkExpireDate(element.expirationDate, this.dialogData.info.time)) || this.isFromUnlink) ? true : false,
                enabledOrder: (((containsGreenFeeItem && containsCartFeeItem) && element.isReturned == false && element.remaining != '0' && this.compareDate(element.startDate, this.dialogData.info.time) && this.checkExpireDate(element.expirationDate, this.dialogData.info.time)) || this.isFromUnlink) ? 0 : 1,
                isUnlimited: element.isUnlimited,
                isReturned: element.isReturned,
                salePrice: element.salePrice,
                serviceCharge: element.serviceCharge,
                gratuity: element.gratuity,
                linkedRetailItemId: element.retailItemId,
                retailItemId: element.retailItemId,
                transactionDetailId: element.transactionDetailId,
                playerId: playerId
              }
            );
          }
        }
      });
    })
    let returnData = _.sortBy(data,['enabledOrder','ValidUpto','RemainingSessions']);
    return returnData;
  }

  async getApiDatas() {
    this.rateSetup = await this._linkMultipackBusiness.getTeeFeesForCourseAndDate(this.dialogData.info.course.id, this.localization.getDate(this.dialogData.info.time));
      this.playerResponse = await this._linkMultipackBusiness.GetPlayersByIds(this.dialogData.componentDetails.popUpDetails.bindData.playerDetail.filter(d => !(d.playerStatus & PlayerPaymentstatus.paid)).map(c => c.playerId));
    this.playerResponse = this.playerResponse.filter(c => c.playerCategoryId != playerCategory.newPlayer);
    this.playerResponse = [...this.playerResponse];
    this.multiPackResponse = await this.getMultiPackDetails(this.playerResponse);
    let retailItemIds = [];
    this.multiPackResponse.forEach(element => {
      element.clientMultiPacks.forEach(res => {
        retailItemIds.push(res.retailItemId);
      });
    });
    retailItemIds = retailItemIds.distinct();
    this.shopItemResponse = await this._linkMultipackBusiness.GetRetailItemsByIds(retailItemIds);
  }

  async generateData() {
    await this.getApiDatas();
    var phoneIdList = this._contactService.getPhoneType().map(x => x.id);
    var mailIdList = this._contactService.getEmailType().map(x => x.id);
    this.playerResponse.forEach(element => {
      let schedulePlayer = this.dialogData.info.playerDetail.find(c => c.playerId == element.id);
      let phoneContactValue = element.contactInformation ? element.contactInformation.find(x => phoneIdList.includes(x.type)) : null;
      let mailContactValue = element.contactInformation ? element.contactInformation.find(x => mailIdList.includes(x.type)) : null;
      let phoneNo = phoneContactValue ? this._utilities.getPhoneNumberFromValue(phoneContactValue.type, phoneContactValue.value).toString() : '';
      let countryCode = phoneContactValue ? this._utilities.getCountryCodeFromValue(phoneContactValue.type, phoneContactValue.value).toString() : '';
      phoneNo = countryCode ? (countryCode + ' - ' + phoneNo) : phoneNo;
      let emailId = mailContactValue ? mailContactValue.value : '';
      if (schedulePlayer.scheduledTeeTimePlayerFee.rateTypeId != 0 && schedulePlayer.scheduledTeeTimePlayerFee.isCartFeeNegotiable == false &&
          schedulePlayer.scheduledTeeTimePlayerFee.isGreenFeeNegotiable == false) {
        this.allSessiondata.push(
          {
            serviceInfo: {
              name: schedulePlayer.rateType.name,
              date: this.localization.LocalizeShortDateTime(this.dialogData.info.time),
              cost: `${this.localization.localizeCurrency(schedulePlayer.amountPaid)}`
            },
            clientInfo: {
              firstName: element.firstName,
              lastName: element.lastName,
              gender: this.genderCheck(element),
              formattedEmail: emailId,
              formattedPhoneNo: phoneNo,
              genderPreference: this.genderCheck(element),
              playerId: element.id,
              playerLinkId: element.playerLinkId,
              playerPosition: 'P'+schedulePlayer.initialPlayerSlotPosition
            },
            sessionDetails: this.buildClientRetailItems(this.multiPackResponse.filter(c => c.guestId == element.playerLinkId).map(c => c.clientMultiPacks), element.id, schedulePlayer.scheduledTeeTimePlayerFee.rateTypeId),
            Selectedpack: schedulePlayer.multiPackTransactionDetailId,
            isExpired: false,
            playerId: element.id,
            rateTypeId: schedulePlayer.rateType.id
          }
        );
      }
    });
    if (this.isFromUnlink) {
      let filteredSessionDetails = [];
      this.allSessiondata.forEach(element => {
        if (element.Selectedpack != 0)
          filteredSessionDetails.push({
            serviceInfo: element.serviceInfo,
            clientInfo: element.clientInfo,
            sessionDetails: element.sessionDetails.filter(c => c.id == element.Selectedpack),
            Selectedpack: element.Selectedpack,
            isExpired: element.isExpired,
            playerId: element.playerId,
            rateTypeId: element.rateTypeId
          });
      });
      this.allSessiondata = [...filteredSessionDetails];
      this.sessiondata = [...filteredSessionDetails];
    }
    else {
      this.sessiondata = cloneDeep(this.allSessiondata.filter(c => c.Selectedpack == 0));
      this.sessiondata.forEach(element => {
        if(element.sessionDetails[0].enabled == true)
        {
          element.Selectedpack = element.sessionDetails[0].transactionDetailId;
        }
      });
      this.sessiondata = [...this.sessiondata];
    }
  }

  genderCheck(element) {
    switch(element.gender) {
      case 1: 
       return this.captions.Male;
       break;
      case 2:
        return this.captions.Female;
        break;
      default:
        return null;
        break;
    }
  }

  formGenerator() {
    this.linkForm = this.fb.group({
    })
    this.captions = this.localization.captions.bookAppointment;
  }
  Show(event: any, data) {
    data.isExpired = event;
    data.sessionDetails = [... this.getSessionData(data.isExpired, data.playerId)];
  }

  getSessionData(showExpired: boolean, playerId: number) {
    const data: any = [];
    let sessionList = this.allSessiondata.filter(c => c.playerId == playerId).map(c => c.sessionDetails);
    if (sessionList && sessionList.length > 0) {
      if (showExpired) {
        sessionList.forEach(element => {
          element.forEach(e => {
            data.push(e);
          });
        });
      }
      else {
        sessionList.forEach(element => {
          element.forEach(e => {
            if (!e.expired)
              data.push(e);
          });
        });
      }
    }
    return data;
  }

  getLinksToShow() {
    let sessionDetails = [];
    this.sessiondata.forEach(element => {
      sessionDetails.push({
        serviceInfo: element.serviceInfo,
        clientInfo: element.clientInfo,
        sessionDetails: this.getSessionData(element.isExpired, element.playerId),
        Selectedpack: element.Selectedpack,
        isExpired: element.isExpired,
        playerId: element.playerId
      });
    });
    this.sessiondata = [...sessionDetails];
  }

  async submitForm(x) {
    const linkData: any[] = [];
    const linkedPlayerData: any = [];
    this.sessiondata.forEach(c => {
      var find = false
      if (c && c.Selectedpack != 0) {
        linkData.forEach(l => {
          if (!find) {
            if (l.transactionDetailId == c.Selectedpack) {
              find = true;
              l.count = l.count + 1;
            }
            else {
              find = false;
            }
          }
        });
        if (!find) {
          linkData.push({
            transactionDetailId: c.Selectedpack,
            count: 1
          });
        }
      }
    });
    if (this.validateRedemptionCount(linkData)) {
      if (this.isFromUnlink) {
        this.playerLinkResponse = await this.unLinkMultiPack(linkData);
      }
      else {
        this.playerLinkResponse = await this.linkMultiPack(linkData);
      }
      if (this.playerLinkResponse) {
        const clientMultipacks: any[] = [];
        this.multiPackResponse.forEach(element => {
          element.clientMultiPacks.forEach(res => {
            clientMultipacks.push(res);
          });          
        });
        this.sessiondata.forEach(c => {
          if (c && c.Selectedpack != 0) {
            const selectTransactionDetailId = c.Selectedpack;
            const greenFeeId = this.rateSetup.find(r => r.rateTypeId == c.rateTypeId).greenFeeRetailItemId;
            const cartFeeId = this.rateSetup.find(r => r.rateTypeId == c.rateTypeId).cartFeeRetailItemId;
            const multiPackGreenFeeValue = clientMultipacks.find(c => c.transactionDetailId == selectTransactionDetailId && c.linkedRetailItemId == greenFeeId)?.salePrice;
            const multiPackCartFeeValue = cartFeeId != 0 ? clientMultipacks.find(c => c.transactionDetailId == selectTransactionDetailId && c.linkedRetailItemId == cartFeeId)?.salePrice : 0;
            const originalGreenFeeValue = this.rateSetup.find(r => r.rateTypeId == c.rateTypeId && r.greenFeeRetailItemId == greenFeeId)?.greenFee;
            const originalCartFeeValue = this.rateSetup.find(r => r.rateTypeId == c.rateTypeId && r.cartFeeRetailItemId == cartFeeId)?.cartFee;
            linkedPlayerData.push({
              multiPackTransactionDetailId: this.isFromUnlink ? 0 : c.Selectedpack,
              playerId: c.clientInfo.playerId,
              originalGreenFeeValue: originalGreenFeeValue,
              originalCartFeeValue: originalCartFeeValue,
              multiPackGreenFeeValue: multiPackGreenFeeValue,
              multiPackCartFeeValue: multiPackCartFeeValue
            });
          }
        });
        if (linkedPlayerData.length > 0) {
          await this._linkMultipackBusiness.UpdatePlayerMultipackDetails(linkedPlayerData);
        }
      }
      x.dialogRef.close();
    }
  }

  async linkMultiPack(params: any[]): Promise<boolean> {
    return await this._linkMultipackBusiness.LinkMultiPack(params);
  }

  async unLinkMultiPack(params: any[]): Promise<boolean> {
    return await this._linkMultipackBusiness.UnLinkMultiPack(params);
  }

  selectedItem(mainobj, session) {
    if (mainobj.Selectedpack != session.id)
      mainobj.Selectedpack = session.id;
    else
      mainobj.Selectedpack = 0;
    if (this.sessiondata.filter(c => c.Selectedpack != 0).length > 0) {
      this._StepperService.enableSave = true;
    }
    else {
      this._StepperService.enableSave = false;
    }
  }

  ngOnDestroy(): void {
    this.saveSubscriber.unsubscribe();
  }

  myComparer = function (a, b) {
    return this.compareProperty(a.SoldOn, b.SoldOn) || this.compareProperty(a.name, b.name);
  }

  validateRedemptionCount(linkData: any[]): boolean {
    var returnvalue = true;
    if (!this.isFromUnlink) {
      const allPackDetails: any[] = [];
      this.allSessiondata.map(c => c.sessionDetails).forEach(element => {
        element.forEach(res => {
          if (allPackDetails.filter(c => c.transactionDetailId == res.transactionDetailId).length == 0)
            allPackDetails.push(res);
        });
      });
      linkData.forEach(res => {
        if (returnvalue) {
          const linkCount = res.count;
          const originalCount = allPackDetails.find(c => c.transactionDetailId == res.transactionDetailId).RemainingSessions;
          if (linkCount > originalCount && originalCount > 0) {
            this._utilities.showCommonAlert(
              this.captions.RedemptionGreaterThanRemaining,
              AlertType.Info,
              ButtonTypes.Ok);
            returnvalue = false;
          }
        }
      });
    }
    return returnvalue;
  }

  compareDate(date1: Date, date2: Date) {
    return this.ValidateStartDate(date1, date2);
  }

  ValidateStartDate(date1: Date, date2: Date) {
    return this.GetDateWithoutTime(date1).valueOf() <= this.GetDateWithoutTime(date2).valueOf();
  }

  checkExpireDate(date1: Date, date2: Date) {
    return this.ValidateExpireDate(date1, date2);
  }

  ValidateExpireDate(date1: Date, date2: Date) {
    return this.GetDateWithoutTime(date1).valueOf() >= this.GetDateWithoutTime(date2).valueOf();
  }

  GetDateWithoutTime(input: Date) {
    if (typeof (input) == 'string') {
      input = this.getDate(input);
    }
    input = _.cloneDeep(input);
    input.setHours(0, 0, 0, 0);
    return input;
  }

  getDate(input: any): Date {
    return this.localization.getDate(input);
  }

  calculateDiff(dateSent) {
    let currentDate = new Date(this.dialogData.info.time);
    dateSent = new Date(dateSent);
    if (this.GetDateWithoutTime(dateSent).valueOf() >= this.GetDateWithoutTime(currentDate).valueOf()) {
      return 0;
    }
    else {
      return Math.floor((Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
    }
  }
}
