<aside class="aside-filter"  [ngClass]="{'hideMenu':togglePanel}">
  <i  aria-hidden="true" class="icon-filter_filled collapsible" (click)="Collapse()"></i>
  <div class="aside-filter__header">
    <span>{{_config?.filterText}}</span>
    <a attr.automationId='Btn_{{automationId}}_ResetFilterBtn' class="golf__link--sm" [ngClass]="{'golf__link--disabled': !(filterForm.dirty || _enableResetExternally || isFilterModified || filteredDataLength >  0)}"
      href="javascript:void(0)" (click)="resetFilter()">{{_config?.resetText}}</a>
  </div>
  <mat-accordion [multi]="true" displayMode="flat">
    <mat-expansion-panel class="aside-filter__panel" [expanded]="true" *ngIf="_dateSelection" #datePanel>
      <mat-expansion-panel-header [expandedHeight]="_panelHeight" [collapsedHeight]="_panelHeight" class="panel-header">
        <mat-panel-title class="panel-title">
          <span class="filter-title">{{_dateSelection.name}}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="filterForm">   
          <app-golf-date-picker [inputs]="_dateInputs[idx]" (datePickerChange)="onChange($event,datefilter.name,idx)" *ngFor="let datefilter of _dateSelection.filters; let idx=index">
          </app-golf-date-picker>
       
      </form>
    </mat-expansion-panel>
    <ng-container *ngIf="_config?.isMultiSelect">
      <ng-container *ngTemplateOutlet="matExpansionPanel"></ng-container>
    </ng-container>
  </mat-accordion>
  <section *ngIf="!_config?.isMultiSelect" [formGroup]="filterForm">
    <div *ngFor="let filterGroup of _filterGroups">
      <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--lg aside-filter__option--singleSelect">
        <mat-label>{{filterGroup.title}}</mat-label>
        <mat-select attr.automationId='Dd_{{automationId}}_{{filterGroup.name}}' (selectionChange)="applySingleFilter(filterGroup, $event)" [formControlName]="filterGroup.name">
          <mat-option *ngFor="let filter of filterGroup.filters" [value]="filter">
            {{filter[displayProp]}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <ng-container *ngIf="template" [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{data: dataContext}">
      </ng-container>
    </div>
  </section>
  <ng-template #matExpansionPanel>
    <mat-expansion-panel class="aside-filter__panel"
      *ngFor="let filterGroup of _filterGroups; let i = index; let first = first" [expanded]="(first && !_dateSelection)|| filterGroup.expand">
      <mat-expansion-panel-header [expandedHeight]="_panelHeight" [collapsedHeight]="_panelHeight">
        <mat-panel-title>
          <span class="filter-title" [matTooltip]="filterGroup.title"><span class="title-name">{{filterGroup.title}}</span>
            </span>
        </mat-panel-title>
        <mat-panel-description><span> ({{filterGroup.filtered | FilterLengthPipe: isChanged}})</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="filter-list">
        <div *ngFor="let filter of filterGroup.filters| slice:0:_filterLimit[i]; let j=index">
          <golfcustom-checkbox [automationId]="automationId" [customCBxlabelText]="filter[displayProp]" [customCBxChecked]="filter.isSelected" [customCBxDisabled]="filterGroup.isDisabled || filter.isDisabled"
            (changeEvent)="updateCategoryArr(filterGroup,filter,i)"></golfcustom-checkbox>
        </div>
        <div *ngIf="filterGroup.filters && filterGroup.filters.length > _filterLimit[i]">
          <a attr.automationId='Btn_{{automationId}}_ShowRemaining' href="javascript:void(0)" class="golf__link" (click)="ShowRemaining($event, filterGroup)">
            {{filterGroup.filters.length > _filterLimit[i] ? filterGroup.filters.length -
                        _filterLimit[i] : 0 }}
                        {{localization.captions.common.More}}</a>
        </div>
      </div>
    </mat-expansion-panel>
    <ng-content></ng-content>
  </ng-template>
</aside>


<!-- aside filter view component-->
<app-golfmore [automationId]="automationId" *ngIf="showMore" [moreOptions]="moreOptions" (moreApplyValueChange)="moreApplyValueChange($event)"
  (moreCancelValueChange)="moreCancelValueChange($event)" (closeMoreWindow)="closeMoreWindow($event)"></app-golfmore>
