
import { Injectable } from "@angular/core";
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { DaysSelector } from './golf-tee-time-allocation-block.model';
import { GolfManagementCommunication } from 'src/app/shared/communication/services/golfmanagement.service';
import { TeeTimeAllocationBlock } from '../../../settings/golf-setup/golf-tee-time-allocation-block/golf-tee-time-allocation-block.model';
import { HttpClient } from '@angular/common/http';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { allocationBlockPlayerTypePermission } from "../../user-setup/booking-permission/booking-permission.model";
import { API } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import { TeeTimeAllocationBlockEvent } from "src/app/tee-time/search/search-model";



@Injectable(
  { providedIn: 'root' }
)
export class GolfTeeTimeAllocationBlockService {

  constructor(private _golfManagementCommunication: GolfManagementCommunication, private httpclient: HttpClient, private _Utilities: GolfUtilities, public localization: GolfLocalization) {
  }


  public getTeeTimeAllocationBlocks(): Promise<TeeTimeAllocationBlock[]> {
    try {
      return this._golfManagementCommunication.getPromise<TeeTimeAllocationBlock[]>(
        { route: GolfApiRoute.GetTeeTimeAllocationBlocks }, false);      
    } catch (error) {
      console.error(error);
    }
  }
  public getTeeTimeAllocationBlocksById(id: number): Promise<TeeTimeAllocationBlock[]> {
    try {
      return this._golfManagementCommunication.getPromise<TeeTimeAllocationBlock[]>(
        { route: GolfApiRoute.GetTeeTimeAllocationBlocksById, uriParams: { "id": id } }, false);     
    } catch (error) {
      console.error(error);
    }
  }
  public getTeeTimeAllocationBlocksByDateRange(courseId: number, startDate: Date | string, endDate: Date | string): Promise<TeeTimeAllocationBlock[]> {
    try {
      return this._golfManagementCommunication.getPromise<TeeTimeAllocationBlock[]>(
        { route: GolfApiRoute.GetTeeTimeAllocationBlocksByDateRange, uriParams: { "courseId": courseId, "startDate": startDate, "endDate": endDate } }, true);
     
    } catch (error) {
      console.error(error);
    }
  }

  public getTeeTimeAllocationBlocksByCourseDateRange(courseId: number, startDate: Date | string, endDate: Date | string): Promise<TeeTimeAllocationBlock[]> {
    try {
      return this._golfManagementCommunication.getPromise<TeeTimeAllocationBlock[]>(
        { route: GolfApiRoute.GetTeeTimeAllocationBlocksByCourseDateRange, uriParams: { "courseId": courseId, "startDate": startDate, "endDate": endDate } }, true);
     
    } catch (error) {
      console.error(error);
    }
  }

  public getTeeTimeAllocationBlockByCourseIdAllocationDateTime(courseId: number, allocatedDateTime: Date | string, holeNumber: string): Promise<TeeTimeAllocationBlock> {
    try {
      return this._golfManagementCommunication.getPromise<TeeTimeAllocationBlock>(
        { route: GolfApiRoute.GetTeeTimeAllocationBlockByCourseIdAllocationDateTime, uriParams: { "courseId": courseId, "allocatedDateTime": allocatedDateTime, "holeNumber": holeNumber } }, true);
    
    } catch (error) {
      console.error(error);
    }
  }

  public getTeeTimeAllocationBlocksForBulkTeeTime(courseWithAllocatedDate): Promise<TeeTimeAllocationBlock[]> {
    try {
      return this._golfManagementCommunication.putPromise<TeeTimeAllocationBlock[]>(
        { route: GolfApiRoute.getTeeTimeAllocationBlocksForBulkTeeTime, body: courseWithAllocatedDate }, true);
      
    } catch (error) {
      console.error(error);
    }
  }

  public async saveTeeTimeAllocationBlock(body: TeeTimeAllocationBlock[], teeTimeAllocationBlockEvent: TeeTimeAllocationBlockEvent = TeeTimeAllocationBlockEvent.None) {
    try {
      return await this._golfManagementCommunication.postPromise<TeeTimeAllocationBlock[]>(
        { route: GolfApiRoute.SaveTeeTimeAllocationBlocks, body: body, uriParams: { teeTimeAllocationBlockEvent: teeTimeAllocationBlockEvent } }, true);
    } catch (error) {
      console.error(error);
    }
  }

  public async GetAllocationBlockPermissionByRole(roleId: number): Promise<allocationBlockPlayerTypePermission[]> {
    let uriParams = { roleId: roleId };
    return this._golfManagementCommunication.getPromise({ route: GolfApiRoute.GetAllocationBlockPermissionByRole, uriParams: uriParams });
   
  }
  
  async getAllocationBlocks(_includeInactive: boolean): Promise<API.TeeTimeAllocationBlock[]> {
    return await this._golfManagementCommunication.getPromise<API.TeeTimeAllocationBlock[]>({
        route: GolfApiRoute.GetAllAllocationBlocks,
        uriParams: { includeInactive: _includeInactive + '' }
    });
  }

  getCourse() {
    return [
      {
        courseId: 1,
        courseName: 'Jones'
      },
      {
        courseId: 2,
        courseName: 'Jones'
      },
      {
        courseId: 3,
        courseName: 'Jones'
      },
      {
        courseId: 4,
        courseName: 'Jones'
      },
      {
        courseId: 5,
        courseName: 'Jones'
      }
    ]
  }

  getBlockTypes() {
    return [
      {
        blockId: 1,
        blockName: 'General',
        checked: false
      },
      {
        blockId: 2,
        blockName: 'Resort',
        checked: false
      },
      {
        blockId: 3,
        blockName: 'Member',
        checked: false
      },
    ]
  }

  getAllocationCode() {
    return [
      {
        id: 'earlyBird',
        allocationBlockName: 'Early Bird',
        colorCode: '#00aed8'
      },
      {
        id: 'twilight',
        allocationBlockName: 'Twilight',
        colorCode: '#00397c'
      },
      {
        id: 'tournament',
        allocationBlockName: 'Tournament',
        colorCode: '#cc974e'
      },
      {
        id: 'open',
        allocationBlockName: 'Open',
        colorCode: '#42a046'
      },
      {
        id: 'buffer',
        allocationBlockName: 'Buffer',
        colorCode: '#3be7ff'
      },
      {
        id: 'maintenance',
        allocationBlockName: 'Maintenance',
        colorCode: '#4eccc3'
      }
    ]
  }

  getSlots(dateArray) {
    const slots = [];
    const slotTime = ['All Slots', '08:07 AM', '08:14 AM', '08:21 AM', '08:27 AM', '08:35 AM', '08:42 AM', '08:48 AM', '08:56 AM'];
    slotTime.forEach(element => {
      slots.push({
        slotTime: element,
        slots: this.getAllSlots(dateArray)
      });
    });
    console.log('slots ', slots);
    return slots;
  }

  getAllSlots(dateArr): any[] {

    return [
      {
        date: '9/24/2019',
        teeSlots: [
          {
            id: 1,
            courseId: 1,
            allocationBlockId: 0,
            oldAllocationBlockId: 0,
            allocationDateTime: '2019-09-24T02:35:00.000Z',
            playerCount: 0
          },
          {
            id: 2,
            courseId: 1,
            allocationBlockId: 2,
            oldAllocationBlockId: 2,
            allocationDateTime: '2019-09-24T02:40:00.000Z',
            playerCount: 0
          },
          {
            id: 1,
            courseId: 1,
            allocationBlockId: 2,
            oldAllocationBlockId: 2,
            allocationDateTime: '2019-09-24T02:45:00.000Z',
            playerCount: 0
          }
        ]
      },
      {
        date: '9/25/2019',
        teeSlots: [
          {
            id: 1,
            courseId: 1,
            allocationBlockId: 2,
            oldAllocationBlockId: 2,
            allocationDateTime: '2019-09-25T02:35:00.000Z',
            playerCount: 0
          },
          {
            id: 1,
            courseId: 1,
            allocationBlockId: 1,
            oldAllocationBlockId: 1,
            allocationDateTime: '2019-09-25T02:40:00.000Z',
            playerCount: 4
          },
          {
            id: 1,
            courseId: 1,
            allocationBlockId: 0,
            oldAllocationBlockId: 0,
            allocationDateTime: '2019-09-25T02:45:00.000Z',
            playerCount: 0
          }
        ]
      },
      {
        date: '9/26/2019',
        teeSlots: [
          {
            id: 1,
            courseId: 1,
            allocationBlockId: 2,
            oldAllocationBlockId: 2,
            allocationDateTime: '2019-09-26T02:35:00.000Z',
            playerCount: 3
          },
          {
            id: 1,
            courseId: 1,
            allocationBlockId: 2,
            oldAllocationBlockId: 2,
            allocationDateTime: '2019-09-26T02:40:00.000Z',
            playerCount: 0
          },
          {
            id: 1,
            courseId: 1,
            allocationBlockId: 2,
            oldAllocationBlockId: 2,
            allocationDateTime: '2019-09-26T02:45:00.000Z',
            playerCount: 0
          }
        ]
      },
      {
        date: '9/27/2019',
        teeSlots: [
          {
            id: 1,
            courseId: 1,
            allocationBlockId: 2,
            oldAllocationBlockId: 2,
            allocationDateTime: '2019-09-27T02:35:00.000Z',
            playerCount: 0
          },
          {
            id: 1,
            courseId: 1,
            allocationBlockId: 2,
            oldAllocationBlockId: 2,
            allocationDateTime: '2019-09-27T02:40:00.000Z',
            playerCount: 0
          },
          {
            id: 1,
            courseId: 1,
            allocationBlockId: 2,
            oldAllocationBlockId: 2,
            allocationDateTime: '2019-09-27T02:45:00.000Z',
            playerCount: 0
          }
        ]
      },
      {
        date: '9/28/2019',
        teeSlots: [
          {
            id: 1,
            courseId: 1,
            allocationBlockId: 2,
            oldAllocationBlockId: 2,
            allocationDateTime: '2019-09-28T02:35:00.000Z',
            playerCount: 0
          },
          {
            id: 1,
            courseId: 1,
            allocationBlockId: 2,
            oldAllocationBlockId: 2,
            allocationDateTime: '2019-09-28T02:40:00.000Z',
            playerCount: 1
          },
          {
            id: 1,
            courseId: 1,
            allocationBlockId: 2,
            oldAllocationBlockId: 2,
            allocationDateTime: '2019-09-28T02:45:00.000Z',
            playerCount: 0
          }
        ]
      }
    ];     
  }
  getDays() {
    return [
      {
        value: DaysSelector.allDays,
        viewValue: this.localization.captions.teetime.AllDays,
      },
      {
        value: DaysSelector.oddDays,
        viewValue: this.localization.captions.teetime.OddDays,
      },
      {
        value: DaysSelector.evenDays,
        viewValue: this.localization.captions.teetime.EvenDays,
      },
      {
        value: DaysSelector.custom,
        viewValue: this.localization.captions.teetime.Custom,
      }
    ]
  }

  getWeekArray() {
    return this._Utilities.getLongWeekArrayLocaleSorted();
  }
  saveAlloctionBlocks(data) {
    console.log('AllocationBlock ', data);
  }

  getPlayersCount() {
    return [

      {
        id: 1,
        courseId: 1,
        scheduleDateTime: "2019-09-22T08:00:00",
        numberOfPlayers: 4
      },
      {
        id: 2,
        courseId: 1,
        scheduleDateTime: "2019-09-24T06:00:00",
        numberOfPlayers: 4
      },
      {
        id: 3,
        courseId: 1,
        scheduleDateTime: "2019-09-24T06:15:00",
        numberOfPlayers: 3
      }     
    ];   
  }
}
