<ng-container [formGroup]="contactForm" *ngIf="arrayName; else singleEmail">
  <div [formArrayName]="arrayName" class="position--relative"
    *ngFor="let email of contactForm.get(arrayName)['controls']; let i= index; let first = first">
    <div [formGroupName]="i" class="mail-wrapper">
      <div class="fixed-width--wrapper">
        <mat-form-field [floatLabel]="floatLabel" [ngClass]="optionClass">
          <mat-select attr.automationId='Dd_{{automationId}}_email{{i}}' [placeholder]="placeHolder" id="email{{i}}" [formControlName]="typeControlName"
            (selectionChange)="typeChange($event,email)">
            <ng-container *ngIf="isEmpty">
              <mat-option></mat-option>
             </ng-container> 
            <mat-option *ngFor="let emailtype of options" [value]="emailtype.id">
              {{emailtype.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field [floatLabel]="floatLabel" [ngClass]="customClass">
          <input attr.automationId='Txt_{{automationId}}_email{{i}}' matInput [name]="controlName" [formControlName]="controlName" golfinputtype="email" [duplicateCheck]="controlName"
            [currentIndex]="i" [maxlength]="maxLength" [placeholder]="emailPlaceHolder">
          <mat-error *ngIf="isEmailRequired && email.get(controlName).hasError('required')">
              {{requiredErrorMessage}}
            </mat-error>
          <mat-error *ngIf="email.get(controlName).hasError('incorrect')">
            {{errorMessage}}
          </mat-error>
          <mat-error *ngIf="email.get(controlName).hasError('duplicate')">
            {{duplicateError}}
          </mat-error>
        </mat-form-field>
        <span attr.automationId='Icn_{{automationId}}_plus{{i}}' [ngClass]="{'ag_section--disable':isEmailDisabled}" class="icon-plus" (click)="addEmailItem(i,defaultMailTypeValue,'')" *ngIf="allowMultiple && first"></span>
        <span attr.automationId='Icn_{{automationId}}_minus{{i}}' [ngClass]="{'ag_section--disable':isEmailDisabled}" class="icon-minus" (click)="removeEmailItem(i)" *ngIf="allowMultiple && !first"></span>
      </div>
      <ng-container *ngIf="showSwitches">
        <div class="ag-mail-switch">
          <label class="golf-form--label">{{captions.PrimaryEmail}}</label>
          <ui-switch attr.automationId='Tog_{{automationId}}_primarySwitchName{{i}}' [ngClass]="{'ag_section--disable':isEmailDisabled}" [formControlName]="primarySwitchName" [checkedLabel]="captions.Yes" [uncheckedLabel]="captions.No"
            (change)="togglePrimaryContact(i)">
          </ui-switch>
        </div>
        <div class="ag-pl-5">
          <label class="golf-form--label">{{captions.PrivateInformation}}</label>
          <ui-switch attr.automationId='Tog_{{automationId}}_privateSwitchName{{i}}' [ngClass]="{'ag_section--disable':isEmailDisabled}" [formControlName]="privateSwitchName" [checkedLabel]="captions.Yes" [uncheckedLabel]="captions.No">
          </ui-switch>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #singleEmail>
  <ng-container [formGroup]="contactForm">
    <mat-form-field [floatLabel]="floatLabel" [ngClass]="optionClass">
      <mat-select attr.automationId='Dd_{{automationId}}_email' [placeholder]="placeHolder" [formControlName]="typeControlName"
        (selectionChange)="typeChange($event,contactForm)">
        <ng-container *ngIf="isEmpty">
          <mat-option></mat-option>
         </ng-container> 
        <mat-option *ngFor="let emailtype of options" [value]="emailtype.id">
          {{emailtype.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field [floatLabel]="floatLabel" [ngClass]="customClass" class="emailWidth">
      <input attr.automationId='Txt_{{automationId}}_email' matInput [name]="controlName" [formControlName]="controlName" golfinputtype="email" [maxlength]="maxLength">
      <mat-error *ngIf="contactForm.get(controlName).hasError('incorrect')">
        {{errorMessage}}
      </mat-error>
    </mat-form-field>
  </ng-container>
</ng-template>