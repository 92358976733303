<section class='playerrate-wrapper dialog-container ag_height--100'>
    <app-dialog-header [title]="captions.lbl_playerRates" (closeDialog)="close($event)"></app-dialog-header>
    <div class='dialog-content ag_m--0' mat-dialog-content [formGroup]="ratePlayersForm">
        <mat-tab-group class="ag_w--100 ag_height--100" *ngIf="playerRateDetails && playerRateDetails.length > 0">
            <mat-tab *ngFor="let player of playerRateDetails;let index = index;"
                [label]="player.firstName + ' ' + player.lastName">
                <mat-radio-group class="player-rate-item-wrapper ag_w--100" [formControlName]="'player_' + index">
                    <mat-radio-button *ngFor="let x of player.destinationSlotRates"
                        class='player-rate-item ag_mb--2' [value]="x">
                        <div class="rate-wrap">
                            <div class="left-aside">
                                <h4 class="golf-page--headers" [matTooltip]="x.rateTypeName">{{x.rateTypeName}}</h4>
                                <p>123</p>
                            </div>
                            <div class="right-aside">
                                <div class="inner-left-aside">
                                    <h4 class="golf-page--headers">Green Fee</h4>
                                    <p>{{x.greenFee}}</p>
                                </div>
                                <div class="inner-right-aside">
                                    <h4 class="golf-page--headers">Cart Fee</h4>
                                    <p>{{x.cartFee}}</p>
                                </div>
                            </div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </mat-tab>
        </mat-tab-group>
    </div>
    <app-dialog-footer [buttonObj]="buttonObj" (save)="savePlayerRate()" (cancel)="cancelPlayerRate($event)">
    </app-dialog-footer>
</section>