<section class="SSC">

    <form class='ag_w--100' [formGroup]='SSCform' autocomplete="off">
        <div class="ag_display--flex align-items-center">
            <mat-checkbox [attr.automationId]="'Chk_SourceSearch_UTSC'" disableRipple="true" class="ag_mr--8" (change)="criteria($event,'UTSC')"
                formControlName="UTSC">{{captions.lbl_usetargetsrchcriteria}}</mat-checkbox>
            <mat-checkbox [attr.automationId]="'Chk_SourceSearch_UOFC'" disableRipple="true" class="ag_mr--8" (change)="criteria($event,'UOFC')"
                formControlName="UOFC">{{captions.lbl_usefiltercriteria}}</mat-checkbox>
            <div class='d-inline-block ag_ml--auto nomarginright'>
                <button [attr.automationId]="'Btn_SourceSearch_Filter'" class="ag-button ag_button--secondary" color="secondary" mat-stroked-button
                [popover]="SSC_configurePopover" popoverPlacement="bottom" [popoverOnHover]="false"
                [popoverCloseOnMouseOutside]="false" [popoverCloseOnClickOutside]="true"
                >{{this.captions.btn_selectFieldsToFilter}}</button>
                <span class="custBadge" [ngClass]="{'opacity0':!showBadge}">&nbsp;</span>
            </div>
        </div>
        <section class="sscinfosec ag_mt--2">
            <div class="ag_p--3">
                <ag-textbox [config]='lastNameInput'></ag-textbox>
                <ag-textbox [config]='firstNameInput'></ag-textbox>
              
                <ag-textbox [config]='postalCodeInput'></ag-textbox>
                <ag-textbox [config]='patronIdInput'></ag-textbox>
                <app-button class='d-inline-block ag_w--20' [buttontype]="searchButton" (valueChange)='srchSSC($event)'>
                </app-button>
            </div>
        </section>
    </form>
    <section class="contentsec">
        <div class="gridsec d-inline-block">
            <div class="table-container h-100 ag_p--0"
                *ngIf="tableContent && tableContent.length !==0; else nodatafound">
                <app-cdkvirtual [attr.automationId]="'Btn_SourceSearch_Table'" [headerOptions]="headerOptions" [IsEditModeEnabled]="IsEditModeEnabledFlag"
                [options]="options" [tableContent]="tableContent"  (EmittedData)='tableAction($event)'
                    [isViewMode]='isViewOnly'></app-cdkvirtual>
            </div>
          
        </div>        
        <div class="matchsec d-inline-block" [ngClass]="{'ag_section--disable':isShowmatchDisabled}">
            <mat-checkbox [attr.automationId]="'Chk_SourceSearch_ShowMatches'" disableRipple="true" class="ag_mr--8 font-weight-bold"  (change)="showMatches($event)">
                {{captions.lbl_showMatchField}}</mat-checkbox>
            <div class="showmatchsection ag_mt--3" *ngIf="toggleshowmatch">
                <span *ngFor="let x of MatchFields;let f=first;let l=last" class="d-inline-block ag_pb--2">
                    <span>{{x}}</span>
                    <span *ngIf="!l"> , </span>
                </span>
            </div>
        </div>
    </section>
</section>
<ng-template #nodatafound>
    <div class="ag_text--center noDataDiv">
        <img src="./assets/images/Summary_Illustration.png" alt="No Summary">
        <div class="ag_mt--7 ag_mb--7" [attr.LiteralID]="'lbl_noResults'">
            {{captions.lbl_resultswillbedisplayedhere}}
        </div>
    </div>
</ng-template>

<popover-content #SSC_configurePopover class="configure-popover" title="" placement="bottom auto" [animation]="true"
    [closeOnClickOutside]="true">
    <ng-container cdkDropListGroup>
        <div class="configureCheckbox-wrapper">
            <div>
                <div *ngFor="let headerOption of _configureHeaderOptions;let i = index">
                    <div class="configureCheckbox-div" *ngIf="headerOption.show">

                        <mat-checkbox class="configureCheckbox" attr.automationId='Btn_SourceSearch_configureCheckbox{{i}}'
                            [ngClass]="{'ag_section--disable':headerOption.disabled}"
                            (change)="configureOptionClick($event,_configureHeaderOptions, headerOption, i)"
                            [disabled]="headerOption.disabled" [checked]="headerOption.checked">
                           
                            <span [matTooltip]="headerOption.displayName" [matTooltip]="headerOption.displayName">{{headerOption.displayName}}</span>
                        </mat-checkbox>
  
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</popover-content>