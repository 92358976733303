<section class="golf-instructor-convertWaitlist--wrapper">
  <div class="golf-instructor-convertWaitlist--formwrapper golf-container--padding" #infoElement>
    <div class="staticDiv" *ngIf='showStatic; else radioTable'>
      <div>
        <i  aria-hidden="true" class="icon-calendar calendar-i"></i>
        <label class="golf-form--text dateTimeLabel">{{staticDate}} {{staticTime}}</label>
      </div>
      <div class="content">
        <div class="content-wrapper">
            <div class="nameDiv">
                <label class="golf-form--label">{{captions.name}}</label>
                <label class="golf-form--text text">{{staticName}}</label>
              </div>
              <div class="courseDiv">
                <label class="golf-form--label">{{captions.course}}</label>
                <label class="golf-form--text text">{{staticCourse}}</label>
              </div>
              <div class="playerDiv">
                <label class="golf-form--label">{{captions.players}}</label>
                <label class="golf-form--text text">{{staticPlayers}}</label>
              </div>
        </div>
        <div class="comments-wrapper">
            <div class="commentsDiv">
                <label class="golf-form--label">{{captions.comments}}</label>
                <label class="golf-form--text text">{{staticComments}}</label>
              </div>
        </div>
       
      </div>
    </div>
    <ng-template #radioTable>
      <div class="golf-container--padding">
        <app-radio-table [automationId]="automationId"  [headers]="header" [tableData]="playerList" [selectedItemId]="selectedItemId" *ngIf="playerList"
          [radioForm]="radioTableForm" controlName="radioControl" (optionChange)="onSelectionChange($event)">
        </app-radio-table>
      </div>
    </ng-template>

    <div class="convertWaitlistFormDiv">
      <form [formGroup]="convertWaitlistForm" autocomplete="off">
        <div>         
          <label class="golf-form--label">{{captions.editWaitlistDetails}}</label>
          <ui-switch attr.automationId='Tog_{{automationId}}_editToggle' formControlName="editToggle" [checkedLabel]="captions.yes" [uncheckedLabel]="captions.no">
          </ui-switch>
        </div>
        <div *ngIf="convertWaitlistForm.value.editToggle">
          <div>
            <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--sm">
              <input attr.automationId='Txt_{{automationId}}_firstName' type="text" maxlength="25" matInput [placeholder]="captions.firstName"  name="firstName" 
                formControlName="firstName" required>
              <mat-error>{{captions.missingfirstName}}</mat-error>
            </mat-form-field>
            <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--sm">
              <input attr.automationId='Txt_{{automationId}}_lastName' type="text" maxlength="25" matInput [placeholder]="captions.lastName" name="lastName" 
                formControlName="lastName" required>
              <mat-error>{{captions.missinglastName}}</mat-error>
            </mat-form-field>
          </div>
          <div>
            <app-ag-golfphone-number [inputs]='phoneTypeInputs' [valuePatched]="valuePatched"></app-ag-golfphone-number>        
          </div>
          <div>
            <app-ag-golfmail-id [inputs]='mailInputs' [valuePatched]="valuePatched"></app-ag-golfmail-id>          
          </div>
          <div>
            <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--sm">
              <mat-select attr.automationId='Dd_{{automationId}}_playerType' name="playerType" formControlName="playerType" [placeholder]="captions.playerType"
                (selectionChange)="onPlayerTypeChange()" >
                <mat-option></mat-option>
                <mat-option *ngFor="let playerType of playerTypeList$ | async" [matTooltip]="playerType.label" [value]="playerType.key">
                  {{playerType.label}}
                </mat-option>
              </mat-select>   
              <mat-error *ngIf="convertWaitlistForm.get('playerType').hasError('required')">
                {{captions.missingPlayertype}}</mat-error>           
            </mat-form-field>

            <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--sm">
              <mat-select attr.automationId='Dd_{{automationId}}_rateType' name="rateType" formControlName="rateType" [placeholder]="captions.rateType" (selectionChange)="setGreencart();clearGreenCart()" >
                <mat-option ></mat-option>
                <mat-option *ngFor="let rateType of rateTypeList$" [value]="rateType.key" [disabled]="rateType?.disabled">
                  <span>{{rateType.label}}</span>
                  <span  *ngIf="rateType?.disabled" class="icon-Stop_availability float-right pt-3 ">{{captions.lbl_unavailable}}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="convertWaitlistForm.get('rateType').hasError('required')">
                {{captions.missingRatetype}}</mat-error>
            </mat-form-field>
          </div>

          <div class="greeFee-cartFee">
              <div *ngIf="isDiplayRateTrue==false">
                <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--sm"> 
                  <input attr.automationId='Txt_{{automationId}}_greenFee' CurrencyFormatter matInput [placeholder]="captions.greenFeeNegotiated"  name="greenFee" 
                    formControlName="greenFee">
                  <mat-error *ngIf="convertWaitlistForm.get('greenFee').hasError('required')"> {{captions.missinggreenFeeNegotiated}}</mat-error>
                </mat-form-field>
                <mat-form-field  [floatLabel]="floatLabel" class="golf-form-control--sm">
                  <input attr.automationId='Txt_{{automationId}}_cartFee' CurrencyFormatter matInput [placeholder]="captions.cartFeeNegotiated" name="cartFee"
                    formControlName="cartFee">
                  <mat-error *ngIf="convertWaitlistForm.get('cartFee').hasError('required')">{{captions.missingcartFeeNegotiated}}</mat-error>
                </mat-form-field>
              </div>
            </div>



          <div>
            <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--lg">
              <mat-select attr.automationId='Dd_{{automationId}}_course' [placeholder]="captions.course" name="course" formControlName="course" (selectionChange)="generateOptions()">
                <mat-option *ngFor="let course of courses" [value]="course.id">
                  {{course.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <div class="w-50 in-block">
              <app-golf-date-picker [inputs]="requestedDateInputs"
                (datePickerChange)="generateOptions($event,'requestedDate')">
              </app-golf-date-picker>
            </div>
            <div class="w-50 in-block">
              <app-ag-time-picker [inputs]="requestedTimeInputs"
                (timeChangeEvent)="timeChange($event, 'requestedTime')">
              </app-ag-time-picker>
            </div>
          </div>

          <div>
            <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--sm">
              <input attr.automationId='Txt_{{automationId}}_noOfPlayers' type="number" numberMaxlength min="1" max="50" (keyup)="noOfPlayersKeyup($event)" (input)="playerchange($event, 'noOfPlayers')"  matInput
                [placeholder]="captions.noOfPlayers" name="noOfPlayers" formControlName="noOfPlayers" required>
              <mat-error *ngIf="convertWaitlistForm.get('noOfPlayers').hasError('incorrect')">
                {{this.numberOfPlayersError}}</mat-error>
              <mat-error *ngIf="convertWaitlistForm.get('noOfPlayers').hasError('required')">
                {{captions.enterNumberofplayers}}</mat-error>
            </mat-form-field>
          </div> 

          <div>
            <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--lg mt-5">
              <input attr.automationId='Txt_{{automationId}}_comments' type="text" matInput [placeholder]="captions.comments" name="comments" formControlName="comments">
            </mat-form-field>
          </div>
        </div>

      </form>
    </div>
  </div>
</section>