import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from './core/core.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { StyleGuideComponent } from './style-guide/style-guide.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthGuardService } from './retail/common/services/auth-guard.service';
import { LoginModule } from './login/login.module';
import { AppService } from './common/app-service';
import { GolfAppService } from './golf-app-service';
import { GolfUtilities } from './shared/utilities/golf-utilities';
import { GolfLocalization } from './core/localization/golf-localization';
import { Localization } from './common/localization/localization';
import { CommonUtilities } from './common/shared/shared/utilities/common-utilities';
import { CommonPropertyInformation } from './common/shared/services/common-property-information.service';
import { GolfPropertyInformation } from './core/services/golf-property-information.service';
import { ServiceLocator } from './common/service.locator';
import { ErrorHandler, Inject } from '@angular/core';
import { Router, RouteReuseStrategy } from '@angular/router';
import { ApmErrorHandler, ApmModule } from '@elastic/apm-rum-angular';
import { ApmService } from '@elastic/apm-rum-angular';
import * as GlobalConst from 'src/app/shared/global.constant';
import { ElasticApm } from 'src/app/shared/global.constant';
import { GlobalErrorHandler } from './shared/service/global-error-handler.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { StoreModule } from '@ngrx/store';
import { appReducers } from './eatecui/source/store/reducers/app.reducer';
import { clearState } from './eatecui/source/store/reducers/login.reducer';
import { ToastrModule } from 'ngx-toastr';
import { EnvService } from './eatecui/source/config.service';
import { environment } from 'src/environments/environment';
import { CustomReuseStrategy } from '@shared/services/reuse-strategy';
import { PropertyDataService } from './settings/system-setup/property-info/property.data.service';
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


let AppServiceFactory = (utilities: GolfUtilities, localization:GolfLocalization) => {
  return new GolfAppService(utilities,localization);
};
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'app/eatecui/assets/i18n/', '.json');
}
export function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang('en');
    return translate.use('en').toPromise();
  };
}


export const OtherOptions: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 0,
  disableTooltipInteractivity: true,
}
@NgModule({
  declarations: [
    AppComponent,
    StyleGuideComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CoreModule,
    HttpClientModule,
    LoginModule,
    OAuthModule.forRoot(),
    ApmModule,
    StoreModule.forRoot(appReducers, { metaReducers: [clearState] }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot()
  ],

  providers: [AuthGuardService,
    {
      provide: AppService,
      useFactory: AppServiceFactory,
      deps: [GolfUtilities, GolfLocalization]
    },
    {
      provide: ApmService,
      useClass:ApmService,
      deps:[Router]
    },
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: OtherOptions},
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    // {
    //   provide: ErrorHandler,
    //   useClass: ApmErrorHandler
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EnvService) => () => envService.init(environment['EatecUi']),
      multi: true,
      deps: [EnvService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    {
      provide:Localization, useExisting:GolfLocalization
    },
    {
      provide: CommonUtilities, useClass: GolfUtilities
    },
    {
      provide: CommonPropertyInformation, useClass: GolfPropertyInformation
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy
    },PropertyDataService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { 

  constructor(private injector: Injector, private localization: GolfLocalization,  @Inject(ApmService) service: ApmService, translate: TranslateService) {
    ServiceLocator.injector = this.injector;
    translate.setDefaultLang('en');
    translate.use('en');

    if(sessionStorage.length > 0)
    {   
      let propertyConfig: string = sessionStorage.getItem('propConfig');
      if (propertyConfig && JSON.parse(propertyConfig)) 
      {
        const config = JSON.parse(propertyConfig);
        // tslint:disable-next-line:max-line-length
        if(config.UseApmServer !== undefined && config.UseApmServer === 'true' && config.ApmServerUrl !== undefined && config.DistributedTracingOrigins !== undefined)
        {
          const apm = service.init({
            serviceName: ElasticApm.ServiceName,
            serverUrl: config.ApmServerUrl,
            distributedTracingOrigins: config.DistributedTracingOrigins.split(',')
          })
          const userName = localization.GetUserInfo('userName');
          const propertyId = localization.GetPropertyInfo('PropertyId');
          if(userName != null){
            apm.setUserContext({
              'username': userName,
              'id': propertyId
            });
          }
        }
      }
    }
  }
}