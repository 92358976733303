import { Injectable } from "@angular/core";
import { AlertType, ButtonType, TeeTimeAllocationBlock } from 'src/app/shared/shared-models';
import { TeeTimesActionService } from 'src/app/shared/data-services/golfschedule/teeTimesAction.data.service';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { TeeTimeAllocationBlockEvent } from "src/app/tee-time/search/search-model";
import { TeeTimesActionBusiness } from "src/app/tee-time/shared/tee-action.business";
@Injectable()
export class ClearPlayerService {
  captions: any;
  constructor(private _localization: GolfLocalization, private _utilities: GolfUtilities, private _teeTimesActionService: TeeTimesActionService
    ,private _teeTimesActionBusiness : TeeTimesActionBusiness
    ) {
    this.captions = this._localization.captions.settings;
  }

  async clearPlayers(scheduleId: number, playerIds: number[], callback: any,data?:any, selectedScheduledTeeTimePlayerIds?:number[]): Promise<void> {
    await this._teeTimesActionService.clearTeeTimePlayers(scheduleId, playerIds).then((response) => {
      this._utilities.ToggleLoader(false);
      if (response) { 
        this._utilities.showAlert(
          this.captions.clearSuccessMessage,
          AlertType.WellDone,
          ButtonType.Ok,
          callback
        );
        this.SaveTeeTimeAllocation(playerIds,data)
        let deleteRentalItemBody = this._teeTimesActionBusiness.deleteRentalItems(selectedScheduledTeeTimePlayerIds);
        if(deleteRentalItemBody != null){
        this._teeTimesActionService.deleteRentalItems(deleteRentalItemBody);
        }
      }else{
        this._utilities.showAlert(this.captions.cancelNotAllowed, AlertType.Error, ButtonType.Ok);
      }
      
    });
  }

  async SaveTeeTimeAllocation(playerIds: number[], data?: any) {
    let playerId = data.playerDetail?.
      map(x => { return playerIds?.some(y => y == x.playerId) });
    const clearAllSlotPlayer = playerId?.every(x => x );
    if (data != null && data?.viewedByStarter && clearAllSlotPlayer) {
      let teeTimeAllocationBlock = new TeeTimeAllocationBlock();
      teeTimeAllocationBlock.id = data.teeGridSlotId;
      teeTimeAllocationBlock.viewedByStarter = false;
      teeTimeAllocationBlock.courseId = data.course?.id;
      teeTimeAllocationBlock.originalHoleNumber = data.originalHoleNumber;
      teeTimeAllocationBlock.allocationBlockId = data.allocation?.id;
      teeTimeAllocationBlock.allocationDateTime = data.allocationDateTime;
      await this._teeTimesActionService.saveTeeTimeAllocationBlock([teeTimeAllocationBlock], TeeTimeAllocationBlockEvent.FromTeeTime);
    }

  }
}