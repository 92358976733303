import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { AgContactFieldConfig, PhoneDetail } from '../../shared-models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { PhoneTypes, ContactType } from '../../global.constant';
import { uniquephoneValidator } from './ag-duplicate-contact-directive';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';

@Component({
  selector: 'app-ag-golfphone-number',
  templateUrl: './ag-phone-number.component.html',
  styleUrls: ['./ag-phone-number.component.scss']
})
export class AgGolfPhoneNumberComponent implements OnInit {
  customClass: string;
  customClass1: string;
  errorMessage: string;
  contactForm: UntypedFormGroup;
  controlName: string;
  placeHolder: string;
  value: string | number;
  typeControlName: string;
  typeClass: string;
  options: any[];
  arrayName: string;
  textMaskPhone: string;
  phoneTypes = PhoneTypes;
  captions: any;
  textMaskExtension: string;
  showSwitches: boolean;
  primarySwitchName: string;
  privateSwitchName: string;
  extensionClass: string;
  extensionPlaceHolder: string;
  duplicateError: string;
  extensionLength: number;
  countryCodeLength: number;
  countryCodePlaceHolder: string;
  isEmpty: boolean;
  fromScreen: string;
  propertyCaptions: any;
  phoneNumberFormat: any;
  floatLabel: string;
  isPhoneDisabled: boolean;
  code: any;
  defaultCountrydialCode: string;
  defaultphoneTypeObj = '';
  automationId: string = "";


  @Input('inputs')
  set inputOptions(value: AgContactFieldConfig) {
    this.errorMessage = value.errorMessage ? value.errorMessage : this.captions.invalidPhone;
    this.placeHolder = value.placeHolder ? value.placeHolder : this.captions.phone;
    this.extensionPlaceHolder = value.extnPlaceHolder ? value.extnPlaceHolder : this.captions.extension;
    this.countryCodePlaceHolder = this.localization.captions.teetime.countryCode;
    this.code = (this.localization.captions as any).lbl_code;
    this.controlName = value.formControlName ? value.formControlName : 'phoneNumber';
    this.typeControlName = value.typeControlName ? value.typeControlName : 'phoneType';
    this.customClass = 'w-50 ';
    this.customClass1 = 'w-20 mr-1';
    this.typeClass = value.typeClass ? value.typeClass : 'golf-form-control--xs';
    this.extensionClass = value.extensionClass ? value.extensionClass : 'golf-form-control--md';
    this.isPhoneDisabled = value.disabled ? value.disabled : false;
    this.automationId = value.automationId ? value.automationId : '';
    this.showSwitches = value.showSwitches;
    if (this.showSwitches) {
      this.primarySwitchName = value.isPrimaryName ? value.isPrimaryName : 'primaryPhone';
      this.privateSwitchName = value.isPrivateName ? value.isPrivateName : 'privateInformtionPhone';
    }

    this.contactForm = value.form;
    this.arrayName = value.formArrayName;
    this.fromScreen = value.fromScreen;
    if (!this.arrayName) {
      if (!this.contactForm.get('countryCode')) {
        this.contactForm.addControl('countryCode', new UntypedFormControl());
      }
      var countryCodeValue = this.contactForm.get('countryCode')?.value;
      countryCodeValue ? this.contactForm.get('countryCode').enable() : this.contactForm.get('countryCode').disable();
      countryCodeValue ? this.contactForm.get(this.controlName).enable() : this.contactForm.get(this.controlName).disable();
    } else {
      const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
      phone.controls.forEach((obj: UntypedFormGroup) => {
        if (!obj.get('countryCode')) {
          obj.addControl('countryCode', new UntypedFormControl());
        }
        if (!obj.value[this.typeControlName]) {
          obj.get('countryCode').disable();
          obj.get(this.controlName).disable();
        }
      });
    }
    const uiDefaultScreenValue = JSON.parse(sessionStorage.getItem('UiDefaultsSettingsValue'));
    if (uiDefaultScreenValue) {
      this.defaultCountrydialCode = uiDefaultScreenValue?.countryCode ? uiDefaultScreenValue.countryCode : ' ';
      let defaultPhoneType = uiDefaultScreenValue?.phoneType ? uiDefaultScreenValue.phoneType : '';
      this.setDefaultCountryCode(this.defaultCountrydialCode, this.getDefaultPhoneType(defaultPhoneType));
    }
  }

  @Input('valuePatched')
  set ControlState(value: boolean) {
    if (!this.contactForm.get('countryCode')) {
      this.contactForm.addControl('countryCode', new UntypedFormControl());
    }
    const countrycodeControl = this.contactForm.get('countryCode');
    const contactControl = this.contactForm.get(this.controlName);
    if (!this.arrayName && !this.isViewOnly && this.contactForm.get(this.typeControlName).value) {
      if (this.fromScreen != 'TT') {
        contactControl.enable();
        countrycodeControl.enable();
      }
      if (this.contactForm.controls[this.controlName].value && this.isCountryCodeRequired) {
        this.contactForm.controls['countryCode'].clearValidators();
        this.contactForm.controls['countryCode'].setValidators([Validators.required]);
      }
      if (this.isPhoneNumberRequired) {
        this.contactForm.controls[this.controlName].clearValidators();
        this.contactForm.controls[this.controlName].setValidators([Validators.required]);
      }
      this.contactForm.updateValueAndValidity();
      this.contactForm.markAsTouched();
    } else {
      contactControl.disable();
      countrycodeControl.disable();
    }
  }

  @Input('values')
  set SetFormArray(value: PhoneDetail[]) {

    console.log("values", value);
    if (value && this.arrayName) {
      this.patchOrAdd(value);
      this.phoneFieldDisabler(this.isPhoneDisabled);
      this.ChangePrimaryToggle();
    }
  }

  @Input('isEmtyreq')
  set emptyFunc(value) {
    this.isEmpty = value ? value : true;
  }

  @Input() isViewOnly: boolean = false;
  @Input() isCountryCodeRequired = true;
  @Input() isExtensionRequired = true;
  @Input() isPhoneNumberRequired = true;

  constructor(private fb: UntypedFormBuilder, private localization: GolfLocalization,

    private propertyInfo: GolfPropertyInformation) {
    this.floatLabel = this.localization.setFloatLabel;
    this.captions = this.localization.captions.common;
    this.duplicateError = this.captions.duplicatePhone;
    this.phoneNumberFormat = (this.fromScreen === 'PI' ? this.localization.propertyCaptions : this.localization.captions);
    this.options = this.getPhoneOptions();
  }

  ngOnInit() {
    this.phoneNumberFormat = (this.fromScreen === 'PI' ? this.localization.propertyCaptions : this.localization.captions);
    this.textMaskPhone = this.maskPhoneNo();
    this.textMaskExtension = this.maskExtension();
    this.extensionLength = 5;
    this.countryCodeLength = 3;
    this.isEmpty = true;
    this.ChangePrimaryToggle();
  }

  getDefaultPhoneType(defaultPhoneType) {
    switch (defaultPhoneType) {
      case this.phoneTypes.home:
        this.defaultphoneTypeObj = this.options.find(x => x.id === this.phoneTypes.home) ? this.options.find(x => x.id === this.phoneTypes.home).id : '';
        break;
      case this.phoneTypes.office:
        this.defaultphoneTypeObj = this.options.find(x => x.id === this.phoneTypes.office) ? this.options.find(x => x.id === this.phoneTypes.office).id : '';
        break;
      case this.phoneTypes.mobile:
        this.defaultphoneTypeObj = this.options.find(x => x.id === this.phoneTypes.mobile) ? this.options.find(x => x.id === this.phoneTypes.mobile).id : '';
        break;
      case this.phoneTypes.business:
        this.defaultphoneTypeObj = this.options.find(x => x.id === this.phoneTypes.business) ? this.options.find(x => x.id === this.phoneTypes.business).id : '';
        break;
      case this.phoneTypes.work:
        this.defaultphoneTypeObj = this.options.find(x => x.id === this.phoneTypes.work) ? this.options.find(x => x.id === this.phoneTypes.work).id : '';
        break;
    }
    return this.defaultphoneTypeObj;
  }
  setDefaultCountryCode(defaultCountryCode, defaultPhoneType) {
    if (this.arrayName) {
      if (this.contactForm.get('phone')) {
        this.contactForm.get('phone')['controls']?.forEach((element, index) => {
          if (element.get('countryCode').value === "" || element.get('countryCode').value === null) {
            element.get('countryCode').setValue(defaultCountryCode);
          }
          if (element.get(this.typeControlName).value === "" || element.get(this.typeControlName).value === null) {
            element.get(this.typeControlName).setValue(defaultPhoneType);
          }
          if (element.get('countryCode').value && element.get(this.typeControlName).value && !element.get(this.typeControlName).disabled) {
            element.get('countryCode').enable();
            element.get(this.controlName).enable();
          }
        })
      }
    } else {
      if (this.contactForm.get('countryCode').value === "" || this.contactForm.get('countryCode').value === null) {
        this.contactForm.get('countryCode').setValue(defaultCountryCode);
      }
      if (this.contactForm.get(this.typeControlName).value === "" || this.contactForm.get(this.typeControlName).value === null) {
        this.contactForm.get(this.typeControlName).setValue(defaultPhoneType);
      }
      if (this.contactForm.get('countryCode').value && this.contactForm.get(this.typeControlName).value && !this.contactForm.get(this.typeControlName).disabled) {
        this.contactForm.get('countryCode').enable();
        this.contactForm.get(this.controlName).enable();
      }
    }
  }

  setmandatoryField(curr, altcontrol, formGroup: UntypedFormGroup, i?) {
    if (i || i >= 0) {
      const arr = formGroup.get(this.arrayName) as UntypedFormArray;
      const group = arr.controls[i] as UntypedFormGroup;
      let curvalue = group.get(curr).value;
      if (curvalue != '' && this.isCountryCodeRequired && this.isPhoneNumberRequired) {
        if (curr == 'phoneNumber' && this.isPhoneNumberRequired) {
          group.controls[curr].clearValidators();
          group.controls[curr].setValidators([Validators.required, uniquephoneValidator('countryCode', this.controlName, i), Validators.minLength(3)]);
          group.controls[curr].markAsTouched();
        } else {
          group.controls[curr].clearValidators();
          group.controls[curr].setValidators([Validators.minLength(3)]);
          if (group.controls[this.typeControlName].value === PhoneTypes.mobile) {
            group.controls[curr].setValidators([Validators.required])
          }
          group.controls[curr].markAsTouched();
        }
      } else {
        group.controls[altcontrol].clearValidators();
      }
      group.controls[altcontrol].updateValueAndValidity();
      group.controls[curr].updateValueAndValidity();
    } else {
      let curvalue = formGroup.get(curr).value;
      if (curr == 'phoneNumber' && this.isPhoneNumberRequired) {
        formGroup.controls[curr].clearValidators();
        formGroup.controls[curr].setValidators([Validators.required]);
        formGroup.controls[curr].markAsTouched();
      }
      if (curvalue != '') {
        formGroup.controls[altcontrol].setValidators([Validators.required]);
        formGroup.controls[altcontrol].markAsTouched();
      } else {
        formGroup.controls[altcontrol].clearValidators();
      }
      formGroup.controls[curr].updateValueAndValidity();
      formGroup.controls[altcontrol].updateValueAndValidity();
    }
    if (this.isCountryCodeRequired) {
      this.countryCodeRequiredCheck(i);
    }
  }
  // Dynamic Phone field addition Logic
  addPhoneArray(index, phoneLabel, countryCode, phoneNumber, extn, isPrimary?, isPrivate?, platformContactUuid?): UntypedFormGroup {
    const currentForm = this.fb.group({
      contactType: ContactType.phone,
      extension: extn,
      id: index
    });
    currentForm.addControl(this.typeControlName, new UntypedFormControl(phoneLabel));
    currentForm.addControl(this.controlName, new UntypedFormControl({ value: phoneNumber, disabled: !phoneLabel }));
    currentForm.addControl('countryCode', new UntypedFormControl({ value: countryCode, disabled: !phoneLabel }));
    if (this.showSwitches) {
      currentForm.addControl(this.primarySwitchName, new UntypedFormControl(isPrimary));
      currentForm.addControl(this.privateSwitchName, new UntypedFormControl(isPrivate));
    }
    currentForm.addControl('platformContactUuid', new UntypedFormControl(platformContactUuid));
    return currentForm;
  }

  addPhoneItem(index, phoneLabel: string, countryCode: string | number, phoneNumber: string | number, extn: string | number, primaryPhone: string | boolean, privateInformtionPhone: string | boolean, platformContactUuid: string, idx: number | string): void {
    if (!this.isViewOnly) {
      const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
      phone.push(this.addPhoneArray(index, phoneLabel, countryCode, phoneNumber, extn, primaryPhone, privateInformtionPhone, platformContactUuid));
      if (idx) {
        const group = phone.controls[idx] as UntypedFormGroup;
        group.controls['countryCode'].setValidators([Validators.required]);
        group.controls['countryCode'].markAsTouched();
        group.controls['countryCode'].updateValueAndValidity();
        group.controls[this.controlName].setValidators([Validators.required, uniquephoneValidator('countryCode', this.controlName, idx)]);
      }
      this.ChangePrimaryToggle();
    }
  }

  removePhoneItem(index: number): void {
    if (!this.isViewOnly) {
      const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
      this.contactForm.markAsDirty();
      phone.removeAt(index);
      this.ChangePrimaryToggle();
    }
  }

  ChangePrimaryToggle() {
    let contactArray = this.contactForm.get(this.arrayName) as UntypedFormArray;
    if (contactArray?.value?.length > 0) {
      let isPrimaryAvailable = false;
      let index: number = 0;
      let firstPhoneisPrimary: boolean = false;
      contactArray.controls.forEach(x => {
        index++;
        const grp = x as UntypedFormGroup;
        if (grp.controls[this.primarySwitchName].value) {
          isPrimaryAvailable = true;
          firstPhoneisPrimary = index == 1;
          grp.controls[this.primarySwitchName].disable();
        }
      });
      const ctrl = contactArray.controls[0] as UntypedFormGroup;
      if (!isPrimaryAvailable) {
        ctrl.controls[this.primarySwitchName].setValue(true);
        ctrl.controls[this.primarySwitchName].disable();
      }
      else {
        if (!firstPhoneisPrimary) {
          ctrl.controls[this.primarySwitchName].setValue(false);
          ctrl.controls[this.primarySwitchName].enable();
        }
      }
    }
  }

  togglePrimaryContact(formGroupName: number) {
    const arr = this.contactForm.get(this.arrayName) as UntypedFormArray;
    const selectedctrl = arr.controls.filter((x, idx) => idx == formGroupName)?.[0];
    if (selectedctrl) {
      (selectedctrl as UntypedFormGroup).controls[this.primarySwitchName].disable();
      (selectedctrl as UntypedFormGroup).controls[this.primarySwitchName].setValue(true);
    }
    const ctrls = arr.controls.filter((x, idx) => idx !== formGroupName);
    ctrls.forEach(x => {
      const grp = x as UntypedFormGroup;
      grp.controls[this.primarySwitchName].setValue(false);
      grp.controls[this.primarySwitchName].enable();
    });
  }

  typeChange(arg, formGroup: UntypedFormGroup, i) {
    formGroup.get('countryCode').enable();
    formGroup.get(this.controlName).enable();
    formGroup.get(this.controlName).setValidators([Validators.minLength(3)]);
    let countryCode = formGroup.get('countryCode').value;
    let defaultCountryCode
    let defaultsettings = JSON.parse(sessionStorage.getItem('defaultSettings'));
    defaultCountryCode = defaultsettings?.defaultCountryCode
    // this.propertyInfo.GetDefaultCountryCode();
    if (!countryCode && defaultCountryCode && this.isCountryCodeRequired) {
      formGroup.get('countryCode').setValue(defaultCountryCode);
    }
    if (arg.value === this.phoneTypes.office && !formGroup.get('extension')) {
      formGroup.addControl('extension', new UntypedFormControl());
    }
    if (this.isCountryCodeRequired) {
      this.countryCodeRequiredCheck(i);
    }

    if (!arg.value) {
      formGroup.get('countryCode').reset();
      formGroup.get(this.controlName).reset();
      formGroup.get('countryCode').disable();
      formGroup.get(this.controlName).disable();
    }
  }

  countryCodeRequiredCheck(index) {
    if (this.contactForm.get(this.typeControlName)) {
      if ((this.fromScreen === 'TT') && this.contactForm.get(this.controlName).value) {
        this.contactForm.controls['countryCode'].setValidators([Validators.required]);
      } else {
        this.contactForm.controls['countryCode'].clearValidators();
      }
      this.contactForm.controls['countryCode'].updateValueAndValidity();
    } else if (this.contactForm.get('phone')) {
      const phoneControl = this.contactForm.controls.phone['controls'][index]['controls'];
      if (phoneControl[this.controlName].value) {
        phoneControl['countryCode'].setValidators([Validators.required]);
      } else {
        phoneControl['countryCode'].clearValidators();
      }
      phoneControl['countryCode'].updateValueAndValidity();
    }
  }

  private patchOrAdd(value) {
    console.log("value", value);
    const phone = this.contactForm.get(this.arrayName) as UntypedFormArray;
    value.forEach((obj, idx) => {

      console.log("value", obj);
      const group = phone.controls[idx] as UntypedFormGroup;
      if (group) {

        console.log("group", group);
        if (this.isCountryCodeRequired) {
          group.controls['countryCode'].setValidators([Validators.required]);
        }
        group.controls[this.controlName].setValidators([Validators.required, uniquephoneValidator('countryCode', this.controlName, idx)]);

        if (!group.get('id')) {
          group.addControl('id', new UntypedFormControl());
        }
        if (!group.get('contactType')) {
          group.addControl('contactType', new UntypedFormControl(ContactType.phone));
        }
        if (!group.get('countryCode')) {
          group.addControl('countryCode', new UntypedFormControl());
        }
        if (obj && obj[this.typeControlName] === this.phoneTypes.office) {
          group.addControl('extension', new UntypedFormControl());
        }
        if (obj && obj[this.typeControlName] && !this.isViewOnly) {
          group.get('countryCode').enable();
          group.get(this.controlName).enable();
        }
        group.patchValue(obj);
        group.markAsPristine();
        group.updateValueAndValidity();
      } else {
        const id = obj ? obj.id : '';
        const phoneType = obj ? obj[this.typeControlName] : '';
        const countryCode = obj ? obj.countryCode : '';
        const phoneNumber = obj ? obj[this.controlName] : '';
        const extension = obj ? obj.extension : '';
        const primaryPhone = obj ? obj.primaryPhone : '';
        const privateInformtionPhone = obj ? obj.privateInformtionPhone : '';
        const platformContactUuid = obj ? obj.platformContactUuid : '';
        this.addPhoneItem(id, phoneType, countryCode, phoneNumber, extension, primaryPhone, privateInformtionPhone, platformContactUuid, idx);
      }
    });
  }

  private maskPhoneNo(): string {
    return this.phoneNumberFormat.common.PhoneFormat ?
      this.phoneNumberFormat.common.PhoneFormat : '999999999999999999';
  }

  private maskExtension(): string {
    return this.phoneNumberFormat.common.ExtensionFormat ?
      this.phoneNumberFormat.common.ExtensionFormat : '9999999';
  }

  private getPhoneOptions() {
    return [
      { id: this.phoneTypes.home, description: this.phoneNumberFormat.teetime.home, type: ContactType.phone },
      { id: this.phoneTypes.office, description: this.phoneNumberFormat.teetime.office, type: ContactType.phone },
      { id: this.phoneTypes.mobile, description: this.phoneNumberFormat.teetime.cell, type: ContactType.phone },
      { id: this.phoneTypes.business, description: this.phoneNumberFormat.teetime.business, type: ContactType.phone },
      { id: this.phoneTypes.work, description: this.phoneNumberFormat.teetime.work, type: ContactType.phone }
    ];
  }

  phoneFieldDisabler(isDisable) {
    let phoneField = this.contactForm.get(this.arrayName);
    if (isDisable) {
      this.isPhoneDisabled = true;
      phoneField['controls'].forEach(element => {
        element.disable();
      });
    }
  }


}
