import { Directive, ElementRef, Input, AfterViewInit, OnChanges, Renderer2 } from '@angular/core';
import _ from 'lodash';

@Directive({
  selector: '[appBgColor]'
})
export class AttributeDirective implements AfterViewInit, OnChanges {
  @Input('appBgColor') bgColor: string;
  @Input() defaultColor: string;
  constructor(private el: ElementRef) {
  }

  ngAfterViewInit(): void {
    this.el.nativeElement.style.backgroundColor = this.bgColor ? this.bgColor : '';
  }
  ngOnChanges(): void {
    this.el.nativeElement.style.backgroundColor = this.bgColor ? this.bgColor : '';

  }


}

@Directive({
  selector: '[appHeight]'
})
export class LessonHeightDirective implements AfterViewInit, OnChanges {
  @Input('appHeight') rows: number;
  @Input() baseHeight: any;
  constructor(private el: ElementRef) {
  }

  ngAfterViewInit(): void {
    this.el.nativeElement.style.height = this.rows * this.baseHeight + 'px';
  }
  ngOnChanges(): void {
    this.el.nativeElement.style.height = this.rows * this.baseHeight + 'px';
  }
}

@Directive({
  selector: '[appMultiLineEllipsis]'
})
export class MultiLineEllipsisDirective implements AfterViewInit, OnChanges {
  constructor(private el: ElementRef, public renderer: Renderer2) {
  }
  actualWord: any = [];
  ngAfterViewInit(): void {
    this.addEllipsis();
  }
  ngOnChanges(): void {
    this.addEllipsis();
  }
  addEllipsis() {
    let words = this.el.nativeElement.innerText.split(' ');
    this.actualWord = _.cloneDeep(words);
    if (words.length === 1 && words[0].length > 10) {
      const newWord = [];
      let wordLength = 10;
      for (let i = 0; i < words[0].length; i = i + 10) {
        newWord.push(words[0].substring(i, wordLength - 1));
        wordLength = wordLength + 10;
      }
      words = newWord;
    }

    let content;
    const child1 = document.createElement('span');
    const child = document.createElement('span');
    this.renderer.setStyle(child1, 'display', 'inline-block');
    const lineHeightValue = Math.round(parseInt(getComputedStyle(this.el.nativeElement).fontSize, 10));
    this.renderer.setStyle(child1, 'fontSize', lineHeightValue);
    this.renderer.appendChild(this.el.nativeElement, child1);
    if (this.el.nativeElement.scrollHeight > this.el.nativeElement.clientHeight) {
      const height = this.el.nativeElement.offsetHeight - 10;
      for (const o of words) {
        content = `${o} `;
        child.innerHTML = child.innerHTML + content;
        child1.innerHTML = child1.innerHTML + content;
        if (child1.offsetHeight > height || child1.offsetHeight === height) {
          this.addTripleDot(child.innerText);
          if (this.el.nativeElement.offsetHeight >= height) {
            this.addTripleDot(this.el.nativeElement.innerText);
          }
        }
      }
    }
  }

  addTripleDot(text) {
    const str = text.trim().split(' ');
    if (this.actualWord.length > 1) {
    str.pop();
    }
    this.el.nativeElement.innerText = ' ';
    this.el.nativeElement.innerText = str.join(' ') + '...';
    if (this.actualWord.length === 1) {
      this.el.nativeElement.innerText = this.el.nativeElement.innerText.replace(/ /g, '');
    }

  }
}
