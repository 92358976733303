<section class="golf-player-contact--wrapper" [ngClass]="{'disabled': viewOnly}" >
    <div class="golf-player-contact--formwrapper">
      <div [formGroup]="playerContactForm">
        <!-- <h4 class="golf-page--headers">{{captions.contactLabel}}</h4> -->
        <div class="position--relative fixed-width--wrapper">
          <app-ag-golfaddress [inputs]="addressInput" [values]="addressValue"></app-ag-golfaddress>
        </div>
  
        <div class="w-100">
          <mat-form-field class="golf-form-control--xs">
            <input attr.automationId='Txt_{{automationId}}_postalCode' type="text" matInput [placeholder]="captions.postalCode" name="Postalcode"  maxlength="10"
              formControlName="postalCode">
          </mat-form-field>
          <mat-form-field class="golf-form-control--xs">
            <input attr.automationId='Txt_{{automationId}}_city' type="text"   matInput [placeholder]="captions.city" name="City" maxlength="50" formControlName="city">
          </mat-form-field>
          <mat-form-field class="golf-form-control--xs">
            <input attr.automationId='Txt_{{automationId}}_state' type="text"   matInput [placeholder]="captions.state" name="State" formControlName="state">
          </mat-form-field>
          <mat-form-field class="golf-form-control--xs">
            <input attr.automationId='Txt_{{automationId}}_country' matInput
                   formControlName="country"
                   type="text"
                   [matAutocomplete]="auto"
                   [placeholder]="captions.country"
                   autocomplete="off"
                   name="Country">
                 <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let country of filteredCountries | async" [value]="country.CountryName" [matTooltip]="country.CountryName">
                <span>{{country.CountryName}}</span>
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="!playerContactForm.get('country').value && playerContactForm.get('country').hasError('required')">
              {{captions.missingCountryName}}
            </mat-error>
            <mat-error *ngIf="playerContactForm.get('country').value && playerContactForm.get('country').hasError('invalid')">
              {{captions.invalidCountryName}}
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <app-ag-phone-number [inputs]="phoneInputs" [values]="phoneInfo"></app-ag-phone-number>
        </div>
        <div>
          <app-ag-mail-id [inputs]="mailInputs" [values]="mailInfo"></app-ag-mail-id>
        </div>
      </div>
    </div>
  </section>