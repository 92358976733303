import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { QuickLoginDialogResult } from "src/app/common/shared/shared/quick-login/quick-login.component";
import { QuickLoginUtilities } from "src/app/common/shared/shared/utilities/quick-login-utilities";
import { GolfLocalization } from "src/app/core/localization/golf-localization";
import { GolfPropertyInformation } from "src/app/core/services/golf-property-information.service";
import { RateType } from "src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal";
import { PlayerType } from "src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model";
import { UserAccessBreakPoints } from "src/app/shared/constants/useraccess.constants";
import { UserAccessModel } from "src/app/shared/data-services/authentication/useraccess-model.model";
import { PlayerTypeService } from "src/app/shared/data-services/golfmanagement/playertype.data.service";
import { RateTypeDataService } from "src/app/shared/data-services/golfmanagement/ratetype.data.service";
import { LessonBookingDataService } from "src/app/shared/data-services/golfschedule/lesson-booking.data.service";
import { MailTypes, OVERRIDEPLAYERTYPEANDRATETYPEDAYSOUTERROR, PhoneTypes, PlayerAdditionalDetailsEnum } from "src/app/shared/global.constant";
import { ContactDetails, LessonPlayer, ScheduledLesson, ScheduledLessonPlayerInformation } from "src/app/shared/models/lessons.modal";
import { PlayerDaysOutResut, RateTypeDaysOutResult } from "src/app/shared/models/teesheet.form.models";
import { AlertAction, AlertType, ButtonType, OVERRIDE_ALERT_WIDTH } from "src/app/shared/shared-models";
import { GolfUtilities } from "src/app/shared/utilities/golf-utilities";
import { TeeTimesActionBusiness } from "../shared/tee-action.business";
import { playerCategory } from "src/app/lessons/lessons-modal/player-info/player-info.model";
import { PlayerAdditionalDetails, PlayerStayDetail } from "src/app/tee-time-actions/teetime/tee-time.model";

@Injectable()
export class TeeSheetCommonBusiness {

  captions: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  overRideDetails = {
    isPlayerTypeDaysOutOverrided: false,
    playerTypeDaysOutOverrideduserID: 0,
    isRateTypeDaysOutOverrided: false,
    rateTypeDaysOutOverrideduserID: 0,
  }
  constructor(private _lessonBooking: LessonBookingDataService
    , private _teeTimesActionBusiness: TeeTimesActionBusiness
    , private playerTypeService: PlayerTypeService
    , private rateTypeService: RateTypeDataService
    , public quickLoginUtils: QuickLoginUtilities
    , private _PropertyInformation: GolfPropertyInformation
    , public _utilities: GolfUtilities
    , public _localization: GolfLocalization) {
    this.captions = this._localization.captions.settings;
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
    this.quickLoginUtils.resetQuickIdDetails();
  }

  getLessonBooking(id: number) {
    return this._lessonBooking.getLessonBookingById(id)
  }

  mapPlayerDateForTeeTime(scheduledLessonPlayerInformation: ScheduledLessonPlayerInformation) {
    let player = scheduledLessonPlayerInformation.player;
    const phoneType = [PhoneTypes.home, PhoneTypes.mobile, PhoneTypes.office];
    const mailTypes = [MailTypes.personal, MailTypes.office];
    let lessonPlayer = {} as LessonPlayer;
    lessonPlayer.contactInfo = [];
    lessonPlayer.firstName = player.firstName;
    lessonPlayer.lastName = player.lastName;
    lessonPlayer.pronounced = player.pronounced;
    lessonPlayer.playerCategory = player.playerCategoryId;
    lessonPlayer.allocationBlockId = scheduledLessonPlayerInformation.allocationBlockId;
    lessonPlayer.playerTypeId = scheduledLessonPlayerInformation.playerTypeId;
    lessonPlayer.rateTypeId = scheduledLessonPlayerInformation.rateTypeId;
    lessonPlayer.playerLinkId = player.playerLinkId;
    lessonPlayer.activityId = player.activityId;
    lessonPlayer.paymentReferenceId = player.paymentReferenceId;
    lessonPlayer.playerAddress = player.playerAddress;
    lessonPlayer.vipType = player.vipType;
    lessonPlayer.playerStayDetail = this.GetPlayerStayDetail(player.playerStayDetail);
    let emailInfo = player.contactInformation.filter(contact => {
      return mailTypes.indexOf(contact.type) >= 0
    });

    let phoneInfo = player.contactInformation.filter(contact => {
      return phoneType.indexOf(contact.type) >= 0
    });

    if (emailInfo && emailInfo.length > 0) {
      let primarEmail = this.GetEmail(emailInfo);
      lessonPlayer.contactInfo.push(primarEmail);
      lessonPlayer.mailType = primarEmail.type;
      lessonPlayer.mailId = primarEmail.value;
    }
    if (phoneInfo && phoneInfo.length > 0) {
      let primarPhone = this.GetPhoneNumber(phoneInfo);
      lessonPlayer.contactInfo.push(primarPhone);
      lessonPlayer.phoneType = primarPhone.type;
      lessonPlayer.phoneNumber = primarPhone.value;
    }
    if (player.loyaltyDetail && player.loyaltyDetail.length > 0) {
      lessonPlayer.patronId = player.loyaltyDetail[0].patronId;
      lessonPlayer.rank = player.loyaltyDetail[0].rank;
    }
    if (player.playerCategoryId == playerCategory.member && player.playerAdditionalDetails != null){
      lessonPlayer.playerAdditionalDetails = [];
      player.playerAdditionalDetails.map(x => {
        let playerdetail = {
          key : x.key,
          value : x.value,
          playerId: 0,
          id: 0
        } as PlayerAdditionalDetails;

        lessonPlayer.playerAdditionalDetails?.push(playerdetail);
      });

    }
    return lessonPlayer;
  }

  GetEmail(email: ContactDetails[]): any {
    let primaryEmail = email.find(x => x.isPrimary);
    let personalEmail = email.find(x => x.type == MailTypes.personal);
    let officeEmail = email.find(x => x.type == MailTypes.office);
    if (primaryEmail) {
      return primaryEmail;
    } else if (personalEmail) {
      return personalEmail;
    } else if (officeEmail) {
      return officeEmail;
    } else {
      return email[0];
    }
  }

  GetPhoneNumber(phoneNumber: ContactDetails[]): any {
    let primaryPhone = phoneNumber.find(x => x.isPrimary);
    let cellphone = phoneNumber.find(x => x.type == PhoneTypes.mobile);
    let homephone = phoneNumber.find(x => x.type == PhoneTypes.home);
    if (primaryPhone) {
      return primaryPhone;
    } else if (cellphone) {
      return cellphone;
    } else if (homephone) {
      return homephone;
    } else {
      return phoneNumber[0];
    }
  }
  GetPlayerStayDetail(playerStayDetail: PlayerStayDetail): PlayerStayDetail {
    if (!playerStayDetail)
    {
      return null;
    }
    return {
      activityStatus: playerStayDetail.activityStatus,
      bookEndDate: playerStayDetail.bookEndDate,
      bookStartDate: playerStayDetail.bookStartDate,
      groupBookingId: playerStayDetail.groupBookingId,
      groupBookingName  : playerStayDetail.groupBookingName,
      linkedPMSActivityId: playerStayDetail.linkedPMSActivityId,
      linkId: playerStayDetail.linkId,
      packageName: playerStayDetail.packageName,
      reservationNumber: playerStayDetail.reservationNumber,
      roomNumber: playerStayDetail.roomNumber,
      id: 0,
      playerId: 0
    }
   }
  updateLessonBooking(scheduleLesson: ScheduledLesson) {
    return this._lessonBooking.updateLessonBooking(scheduleLesson);
  }

  isPlayerDaysout(selectedOption, date): PlayerDaysOutResut {
    return this._teeTimesActionBusiness.validatePlayerDaysOut(this._localization.getDate(date), selectedOption?.daysOutStart, selectedOption?.daysOutEnd);
  }
  isRateDaysout(selectedOption, date): RateTypeDaysOutResult {
    return this._teeTimesActionBusiness.validateRateTypeDaysOut(this._localization.getDate(date), selectedOption?.daysOutStart, selectedOption?.daysOutEnd);
  }

  public async getAllPlayers(): Promise<PlayerType[]> {
    return await this.playerTypeService.getAllPlayerTypes();
  }
  public async getAllRateTypes(): Promise<RateType[]> {
    return await this.rateTypeService.getAllRateTypes(false);
  }
  getPlayerType(dragData: any, allPlayerTypes, date) {
    let selectedPlayerType;
    if (dragData?.length > 0) {
      dragData.some(player => {
        let playerType = allPlayerTypes.find(x => x.id == player.scheduledTeeTimePlayerFee.playerTypeId);
        let playerDaysOutResut = this.isPlayerDaysout(playerType, date);
        if (playerDaysOutResut && (playerDaysOutResut == PlayerDaysOutResut.StartDateFail || playerDaysOutResut == PlayerDaysOutResut.EndDateFail)) {
          selectedPlayerType = playerType;
          return true;
        } else {
          return false;
        }
      });
    }
    return selectedPlayerType;
  }
  getRateType(dragData: any, allRateTypes, date) {
    let selectedRateType;
    if (dragData?.length > 0) {
      dragData.some(player => {
        let rateType = allRateTypes.find(x => x.id == player.scheduledTeeTimePlayerFee.rateTypeId);
        let rateTypeDaysOutResult = this.isRateDaysout(rateType, date);
        if (rateTypeDaysOutResult && (rateTypeDaysOutResult == RateTypeDaysOutResult.StartDateFail || rateTypeDaysOutResult == RateTypeDaysOutResult.EndDateFail)) {
          selectedRateType = rateType;
          return true;
        } else {
          return false;
        }
      });
    }
    return selectedRateType;
  }

  async checkOverridePlayerTypeRateTypeDaysOut(selectedPlayerType, selectedRateType, overridePlayerTypeDaysoutUserAccess: UserAccessModel.BreakPointResult, overrideRateTypeDaysoutUserAccess: UserAccessModel.BreakPointResult, date): Promise<boolean> {
    let result = false;
    let playerDaysOutResut : PlayerDaysOutResut;
    if(selectedPlayerType)
    {
      playerDaysOutResut = this._teeTimesActionBusiness.validatePlayerDaysOut(this._localization.getDate(date), selectedPlayerType.daysOutStart, selectedPlayerType.daysOutEnd);
    }
    //get selectoption for ratetype
    let rateTypeDaysOutResult : RateTypeDaysOutResult;
    let rateTypeDaysOutValidation = true;
    if(selectedRateType)
    {
      rateTypeDaysOutResult = this._teeTimesActionBusiness.validateRateTypeDaysOut(this._localization.getDate(date), selectedRateType.daysOutStart, selectedRateType.daysOutEnd);
      rateTypeDaysOutValidation = this.validateRateTypeDaysOut(rateTypeDaysOutResult, overrideRateTypeDaysoutUserAccess);
    }
    if(playerDaysOutResut && (playerDaysOutResut == PlayerDaysOutResut.StartDateFail || playerDaysOutResut == PlayerDaysOutResut.EndDateFail)) {
      if (overridePlayerTypeDaysoutUserAccess && overridePlayerTypeDaysoutUserAccess.isAllow)
      {
        let userId = this._localization.GetUserInfo('userId');
        this.setOverrideValue(Number(userId), true);
        // check ratetypeDaysOut
        if(rateTypeDaysOutValidation)
        {
          result = true;
        }
        else
        {
          result = await this.handleRateTypeDaysOut(selectedRateType, rateTypeDaysOutResult);
        }
      }
      else
      {
        result = await this.handlePlayerTypeRateTypeDaysOut(selectedPlayerType, playerDaysOutResut, rateTypeDaysOutValidation);
      }
    }
    else
    {
      // check ratetypeDaysOut
      if(rateTypeDaysOutValidation)
      {
        result = true;
      }
      else
      {
        result = await this.handleRateTypeDaysOut(selectedRateType, rateTypeDaysOutResult);
      }
      
    }
    return result;
  }
  
  validateRateTypeDaysOut(rateTypeDaysOutResult: RateTypeDaysOutResult, overrideRateTypeDaysoutUserAccess)
  {
    let result = false;
    if (rateTypeDaysOutResult && (rateTypeDaysOutResult == RateTypeDaysOutResult.StartDateFail || rateTypeDaysOutResult == RateTypeDaysOutResult.EndDateFail))
    {
      if (overrideRateTypeDaysoutUserAccess && overrideRateTypeDaysoutUserAccess.isAllow) {
        let userId = this._localization.GetUserInfo('userId');
        this.setRateTypeOverrideValue(Number(userId), true);
        result = true;
      }
    }
    else {
      result = true;
    }
    return result;
  }


  async handlePlayerTypeRateTypeDaysOut(selectedOption, playerDaysOutResut: PlayerDaysOutResut, rateTypeDaysOutValidation: boolean): Promise<boolean> {
    let result = false;
    if(rateTypeDaysOutValidation)
    {
      const currentDate = this._PropertyInformation.CurrentDate;
      const daysOutStartDate = this._localization.GetFormattedDateDDMMYY(this._localization.AddDays(currentDate, selectedOption.daysOutStart));
      const daysOutEndDate = this._localization.GetFormattedDateDDMMYY(this._localization.AddDays(currentDate, selectedOption.daysOutEnd));
      const startErrorMessage: string = this.captions.playerDaysOutStartError + ' ' + daysOutStartDate;
      const endErrorMessage: string = this.captions.playerDaysOutEndError + ' ' + daysOutEndDate;
      let errorMessage = (playerDaysOutResut == PlayerDaysOutResut.StartDateFail) ? startErrorMessage : endErrorMessage;
      result = await this.showQuickIdPopup(errorMessage);
    }
    else
    {
      let errorMessage = this.captions.playerTypeRateTypeDaysOutError;
      result = await this.showPlayerTypeRateTypeQuickIdPopup(selectedOption.Id, errorMessage);
    }
    return result;
  }

  async showPlayerTypeRateTypeQuickIdPopup(playerTypeId: number, errorMessage: string): Promise<boolean> {
    const width = OVERRIDE_ALERT_WIDTH;
    return new Promise<boolean>(async (resolve, reject) => {
      let res = this._utilities.showAlertWithCustomWidth(errorMessage, AlertType.Info, ButtonType.OkOverride, width, (alertRes) => {
        if (alertRes === AlertAction.OVERRIDE) { // click override
          const quickLoginDialogRef = this.quickLoginUtils.QuickLogin({ breakPointNumber: UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT }, true, [UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT, UserAccessBreakPoints.OVERRIDERATETYPEDAYSOUT], OVERRIDEPLAYERTYPEANDRATETYPEDAYSOUTERROR);
          quickLoginDialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
            if (quickLoginDialogResult.isLoggedIn && quickLoginDialogResult.userDetails && quickLoginDialogResult.userDetails.breakPointAccess) {  //Quickid user has breakpoint #10195
              this.quickLoginUtils.resetQuickIdDetails();
              this.setOverrideValue(quickLoginDialogResult.userDetails.userId, true);
              this.setRateTypeOverrideValue(quickLoginDialogResult.userDetails.userId, true);
              resolve(true);
            } else { //Quickid user doesn't have access for #10195
              this.setOverrideValue(0, false);
              this.setRateTypeOverrideValue(0, false);
              resolve(false);
            }
          });
        } else { // click Ok
          this.setOverrideValue(0, false);
          this.setRateTypeOverrideValue(0, false);
          resolve(false);
        }
      });
      await res.afterClosed().toPromise();
    });
  }

  async handleRateTypeDaysOut(selectedOption, rateTypeDaysOutResult): Promise<boolean> {
    let result = false;
    const currentDate = this._PropertyInformation.CurrentDate;
    const daysOutStartDate = this._localization.GetFormattedDateDDMMYY(this._localization.AddDays(currentDate, selectedOption.daysOutStart));
    const daysOutEndDate = this._localization.GetFormattedDateDDMMYY(this._localization.AddDays(currentDate, selectedOption.daysOutEnd));
    const startErrorMessage: string = this.captions.rateDaysOutStartError + ' ' + daysOutStartDate;
    const endErrorMessage: string = this.captions.rateDaysOutEndError + ' ' + daysOutEndDate;
    let errorMessage = (rateTypeDaysOutResult == RateTypeDaysOutResult.StartDateFail) ? startErrorMessage : endErrorMessage;
    result = await this.showRateTypeQuickIdPopup(errorMessage);
    return result;
  }

  async showRateTypeQuickIdPopup(errorMessage: string): Promise<boolean> {
    const width = OVERRIDE_ALERT_WIDTH;
    return new Promise<boolean>(async (resolve, reject) => {
      let res = this._utilities.showAlertWithCustomWidth(errorMessage, AlertType.Info, ButtonType.OkOverride, width, (res) => {
        if (res === AlertAction.OVERRIDE) { // click override
          const quickLoginDialogRef = this.quickLoginUtils.QuickLogin({ breakPointNumber: UserAccessBreakPoints.OVERRIDERATETYPEDAYSOUT });

          quickLoginDialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
            if (quickLoginDialogResult.isLoggedIn && quickLoginDialogResult.userDetails && quickLoginDialogResult.userDetails.breakPointAccess) {  //Quickid user has breakpoint #OVERRIDERATETYPEDAYSOUT
              this.quickLoginUtils.resetQuickIdDetails();
              this.setRateTypeOverrideValue(quickLoginDialogResult.userDetails.userId, true);
              resolve(true);
            }
            else { //Quickid user doesn't have access for #OVERRIDERATETYPEDAYSOUT
              this.setRateTypeOverrideValue(0, false);
              resolve(false);
            }
          });
        }
        else { // click Ok
          this.setRateTypeOverrideValue(0, false);
          resolve(false);
        }
      });
      await res.afterClosed().toPromise();
    });
  }


  setRateTypeOverrideValue(userId: number, bFlag: boolean) {
    this.overRideDetails.isRateTypeDaysOutOverrided = bFlag;
    this.overRideDetails.rateTypeDaysOutOverrideduserID = userId;
  }
  
  async showQuickIdPopup( errorMessage: string): Promise<boolean> {
    const width = OVERRIDE_ALERT_WIDTH;
    return new Promise<boolean>(async (resolve, reject) => {
      let res = this._utilities.showAlertWithCustomWidth(errorMessage, AlertType.Info, ButtonType.OkOverride, width, (res) => {
        if (res === AlertAction.OVERRIDE) { // click override
          const quickLoginDialogRef = this.quickLoginUtils.QuickLogin({ breakPointNumber: UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT });
          quickLoginDialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
            if (quickLoginDialogResult.isLoggedIn && quickLoginDialogResult.userDetails && quickLoginDialogResult.userDetails.breakPointAccess) {
              this.quickLoginUtils.resetQuickIdDetails();
              this.setOverrideValue(quickLoginDialogResult.userDetails.userId, true);
              resolve(true);
            } else {
              this.setOverrideValue(0, false);
              resolve(false);
            }
          });
        } else {
          this.setOverrideValue(0, false);
          resolve(false);
        }
      });
      await res.afterClosed().toPromise();
    });
  }

  setOverrideValue(userId: number, bFlag: boolean) {
    this.overRideDetails.isPlayerTypeDaysOutOverrided = bFlag;
    this.overRideDetails.playerTypeDaysOutOverrideduserID = userId;
  }

}