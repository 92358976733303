import { Injectable } from "@angular/core";
import { TeeTimesActionService } from 'src/app/shared/data-services/golfschedule/teeTimesAction.data.service';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Injectable()
export class TeePlayerDetailsService{
    captions: any;
    constructor(private _localization: GolfLocalization, private _utilities: GolfUtilities, private _teeTimesActionService: TeeTimesActionService) {
      this.captions = this._localization.captions.settings;
    }
}
