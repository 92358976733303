<div class="dialog-container caddy-type-selection-wrapper"  #infoElement>
  <app-golfdialog-header [automationId]="automationId" [title]="dialogData.title" (closeDialog)="closeHandler($event)"></app-golfdialog-header>
  <form [formGroup]="form" class="golf-container--padding caddytypeform">
    <div class="notification-message" *ngIf="notificationFlag">
      <app-note [automationId]="automationId" [noteMessage]="notificationbar" (removeNotification)="removeNotification($event)"></app-note>
    </div>
    <section class="table-container">
      <table attr.automationId='Tbl_{{automationId}}_caddyInfo' formArrayName="caddyInfo" class="table-type--static" aria-describedby="general-information-table">
        <thead>
          <tr class="table-type--static-header">
            <th scope="col" *ngFor="let column of displayColumns" class="table-type--static-header-th"
              [ngClass]="isCaddyTypeColumnRequired(column)">{{column}}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="table-type--static-row" *ngFor="let row of form.get('caddyInfo')['controls']; let i = index"
            [formGroupName]="i">
            <td class="table-type--static-row-td">
              <span>{{row.value.player}}</span>
            </td>
            <td class="table-type--static-row-td">
              <span>{{row.value.confirmationNo}}</span>
            </td>
            <td class="table-type--static-row-td caddy-Type">
              <mat-form-field [floatLabel]="floatLabel" appearance="outline" class="td-select">
                <mat-select attr.automationId='Dd_{{automationId}}_caddyType{{i}}'  name="caddyType" formControlName="caddyType" (selectionChange)="caddyTypeChange($event, i)">
                  <mat-option *ngFor="let obj of caddyTypes?.caddyTypes" [value]="obj.id" [matTooltip]="obj.type">
                    {{obj.type}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td class="table-type--static-row-td">
              <ng-container
                *ngTemplateOutlet="row.value.caddySearch? clearCaddy: selectCaddy; context: {rowValue:row.value, idx: i}">
              </ng-container>
            </td>
            <td class="table-type--static-row-td">
              <span>{{row.value.caddyNo}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </form>
  <ng-template #selectCaddy let-rowValue="rowValue" let-idx="idx">
    <a attr.automationId='Icn_{{automationId}}_assignCaddy{{idx}}' class="golf__link--lg" [ngClass]="{'golf__link--disabled':!rowValue.caddyType}" href="javascript:void(0)"
      (click)="assignCaddy(idx)"><span>{{caption.selectCaddy}}</span>
      <i aria-hidden="true" class="icon-left-dashboard-arrow">
      </i></a>
  </ng-template>
  <ng-template #clearCaddy let-rowValue="rowValue" let-idx="idx">
    <a attr.automationId='Icn_{{automationId}}_removeCaddy{{idx}}' class="golf__link--lg" href="javascript:void(0)"
      (click)="assignCaddy(idx)"><span>{{rowValue.caddySearch}}</span></a>
    <i aria-hidden="true" class="icon-Cancel" (click)="removeCaddy(idx)">
    </i>
  </ng-template>
    <app-golfdialog-footer [automationId]="automationId" [buttonObj]="buttonObj" (save)="saveHandler($event)" (cancel)="closeHandler($event)"
      [clickThrottleTime]=1>
    </app-golfdialog-footer>
</div>