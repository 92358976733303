<section class="ContactLog newVersionWrapper">
  <div class="w-60 ag-p-4" *ngIf="isGuestRecordPurged && showClose">
    <app-warning-bar (closeWarning)="closeWarning()" [message]="messageForWarning" [showClose]="showClose">
    </app-warning-bar>
  </div>
  <div class="filters float-left" *ngIf="!this.fromAlert">
    <div class="filter-header form-label-normal d-flex px-3 py-3">
      <span class="spa-filter-title textellipsis"
        [isSpaEllipsis]="commonCaptions.filters">{{commonCaptions.filters}}</span>
      <a [attr.automationId]="'Lbl_contactLog_resetAll'" href="javascript:void(0)"
        class="text-color spa-filter-link reset-text ml-auto" (click)="ResetFilters()">{{captions.ResetAll}}</a>
    </div>
    <label class="blckClr range-head LW12 mb-0 accordian-label">{{captions.DateRange}}</label>
    <form [formGroup]="dateGrp" class="px-3">
      <div class="from-date">
        <span class="LW12 mb-1">{{commonCaptions.From}}</span>
        <div class="LW14 d-inline-block w-100 mt-1">
          <mat-form-field [attr.automationId]="'Txt_contactLog_fromDate'" [floatLabel]="floatLabel"
            class="LW14 d-inline-block w-100 date-picker-width" appearance="legacy">
            <mat-label></mat-label>
            <input [attr.automationId]="'Txt_contactLog_fromDate'" matInput [matDatepicker]="picker" dateRestricter formControlName="fromDate"
              [placeholder]="placeholderFormat">
            <mat-datepicker #picker></mat-datepicker>
            <i aria-hidden="true" matSuffix
              class="icon-Calender cursor fs-xlarge position-relative d-inline-block customCalendar"
              (click)="picker.open()"></i>
            <mat-error *ngIf="dateGrp.controls['fromDate'].hasError('matDatepickerParse')">{{commonCaptions.dateFormat}}
            </mat-error>
            <mat-error
              *ngIf="dateGrp.controls['fromDate'].hasError('required') && !dateGrp.controls['fromDate'].hasError('matDatepickerParse')">
              {{captions.Missing}} {{commonCaptions.From}}</mat-error>
            <mat-error *ngIf="dateGrp.controls['fromDate'].hasError('matDatepickerMin')">{{commonCaptions.minimum}}
              {{commonCaptions.From}}</mat-error>
            <mat-error *ngIf="dateGrp.controls['fromDate'].hasError('matDatepickerMax')">{{commonCaptions.maximum}}
              {{commonCaptions.From}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="to-date">
        <span class="LW12 mb-1">{{commonCaptions.To}}</span>
        <div class="LW14 d-inline-block w-100 mt-1">
          <mat-form-field [attr.automationId]="'Txt_contactLog_toDate'" [floatLabel]="floatLabel"
            class="LW14 d-inline-block w-100 date-picker-width" appearance="legacy">
            <mat-label></mat-label>
            <input [attr.automationId]="'Txt_contactLog_toDate'" matInput [matDatepicker]="picker1" dateRestricter formControlName="toDate" [min]="minToDate"
              [placeholder]="placeholderFormat">
            <mat-datepicker #picker1></mat-datepicker>
            <i aria-hidden="true" matSuffix
              class="icon-Calender cursor fs-xlarge position-relative d-inline-block customCalendar"
              (click)="picker1.open()"></i>
            <mat-error *ngIf="dateGrp.controls['toDate'].hasError('matDatepickerParse')">{{commonCaptions.dateFormat}}
            </mat-error>
            <mat-error
              *ngIf="dateGrp.controls['toDate'].hasError('required') && !dateGrp.controls['toDate'].hasError('matDatepickerParse')">
              {{captions.Missing}} {{commonCaptions.To}}</mat-error>
            <mat-error *ngIf="dateGrp.controls['toDate'].hasError('matDatepickerMin')">{{commonCaptions.minimum}}
              {{commonCaptions.To}}</mat-error>
            <mat-error *ngIf="dateGrp.controls['toDate'].hasError('matDatepickerMax')">{{commonCaptions.maximum}}
              {{commonCaptions.To}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div class="list-container">
    <button [attr.automationId]="'Btn_contactLog_createNewBtn'" [ngClass]="isGuestRecordPurged ||isViewOnly ? 'ag_link--disabled opacity-spa' : ''" *ngIf="!this.fromAlert"
      class="float-right body-bgcolor createNewBtn whitecolor text-capital" mat-raised-button
      (click)="openDialog()">{{captions.btn_create_log}}</button>
    <div class="table-section w-100 h-100" [attr.automationId]="'Tbl_contactLog_contactTable'">
      <!-- <app-table [ngClass]="isGuestRecordPurged  ? 'ag_link--disabled opacity-spa' : ''"
        [options]="this.contactLogService.tableoptions" (editEvt)="EditRecords($event)" (dragDropEvt)="dragDrop($event)"
        (deleteEvt)="DeleteLogList($event)"></app-table> -->

        <app-common-table [ngClass]="isGuestRecordPurged  ? 'ag_link--disabled opacity-spa' : ''"
        [options]="this.contactLogService.tableoptions"  (editEvt)="EditRecords($event)"  (dragDropEvt)="dragDrop($event)"
        (deleteEvt)="DeleteLogList($event)"
       ></app-common-table>
    </div>
  </div>
</section>
