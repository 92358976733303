

export interface TabsArray {
  [index: number]: TabsArrayObj;
}

export interface TabsArrayObj {
  label: string;
  component: any;
  link: string;
}

export namespace PlayerProfileAPI {
  export interface PlayerProfile {
    id?: string;
    lastChangeId?:string;
    firstName: string;
    lastName: string;
    pronounce: string;
    playerProfileAddress: PlayerProfileAddress;
    playerProfileAddressList?: PlayerProfileAddress[];
    playerProfileContactDetail: PlayerProfileContactDetails[];
    playerProfileContactLogList?:PlayerProfileContactLog[];
    title: string;
    dateOfBirth: Date;
    gender: string;
    paymentReferenceId?: number;
    updateExistingPaymentReferenceId: boolean;
    interfaceGuestId?:number;
    imageReferenceId: string;
    interfaces:Interfaces[];
    lastVisitedDate:Date;
    loyaltyDetail: LoyaltyDetail[];
    playerType:number,
    rateType:number,
    vipType: string,
    consent:Date;
    consentExpiryDate:Date;
    consentPolicyId:number;
    isPurged:boolean;
    comments : string;
    isExternalGuest: boolean;
    platformBussinessCardRevUuid: string;
    platformBussinessCardUuid: string;
    platformGuestUuid: string;
    platformRevUuid: string;
    externalReferenceNumber: string;
  }

  export interface LoyaltyDetail{
    patronId: string,
    rank: string
  }

  export interface Interfaces{
    name:string;
    interfaceGuestId:string;
    isSubscribed : boolean;
  }
  export interface PlayerProfileAddress {
    id: number;
    playerId?: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    isPrivate: boolean;
    platformAddressUuid: string;
  }

  export interface PlayerProfileContactDetails {
    id: number;
    playerId?: number;
    type: number;
    value: string;
    name: string;
    isPrivate: boolean;
    isPrimary: boolean;
    platformContactUuid: string;
  }

  export interface PlayerProfileContactLog {
    id : number;
    productId? : number;
    logTypeId : number;
    logNotes : string;
    logNotes_short:string;
    logDate : Date | string;
    dueDate? : Date | string;
    expiryDate? : Date | string;
    isAlert : boolean;
    isPrivate : boolean;
    isGroup : boolean;
  }
}

export namespace PlayerProfileUI {
  export interface Player {
    id?: string;
    lastChangeId?: string;
    firstName: string;
    lastName: string;
    phone: Phone[],
    email: Email[],
    addressId:number,
    address: Address[],
    addressList: [],
    postal_code: string;
    state: string;
    city: string;
    country: string;
    platformAddressUuid?: string;
    playerCategoryId: number;    
    title: string;
    dob: Date;
    pronounced: string;
    gender: string
    paymentReferenceId?: number;
    updateExistingPaymentReferenceId: boolean;
    url: string;
    imageId: number;
    interfaceGuestId?: number;
    imageReferenceId: string;
    privateAddress:boolean;
    interfaces:Interfaces[];
    lastVisitedDate:Date;
    patronid: string;
    rank: string;
    playerType:any;
    rateType:any;
    vipType: string;
    consent:Date;
    consentExpiryDate:Date;
    consentPolicyId:number;
    isPurged:boolean;
    comments : string;
    isExternalGuest: boolean;
    platformBussinessCardRevUuid: string;
    platformBussinessCardUuid: string;
    platformGuestUuid: string;
    platformRevUuid: string;
    externalReferenceNumber: string;
    playerContactLogList?:PlayerContactLog[];
  }
  export interface PlayerType {
    id: number;
    type: string;
    daysOutStart: number;
    daysOutEnd: number;
    guaranteeType: string;
    isActive: boolean;
}
export interface RateType {
  id: number;
  type: string;
  daysOutStart: number;
  daysOutEnd: number;
  guaranteeType: string;
  isActive: boolean;
  isSupressReceipt: boolean;
}

  export interface Interfaces{
    name:string;
    interfaceGuestId:string;
    isSubscribed : boolean;
  }
  export interface Phone {
    id: number;
    contactType: string,
    phoneType: number;
    phoneLabel: string;
    countryCode:string | number;
    phoneNumber: string;
    extension: string;
    privateInformtionPhone: boolean,
    primaryPhone: boolean,
    platformContactUuid?: string
  }
  export interface Email {
    id: number;
    contactType: string,
    emailType: number;
    emailLabel: string;
    emailId: string;
    primaryEmail: boolean,
    privateInformtionEmail: boolean,
    platformContactUuid?: string
  }
  export interface Address {
    addressDetails: string;
  }

  export interface PlayerContactLog {
    id : number;
    productId? : number;
    logTypeId : number;
    logNotes : string;
    logNotes_short:string;
    logDate : Date | string;
    dueDate? : Date | string;
    expiryDate? : Date | string;
    isAlert : boolean;
    isPrivate : boolean;
    isGroup : boolean;
  }
}


export interface PlayerProfileGridData {
  basicInformation: PlayerProfileBasicInformation;
  patronId: string;
  memberId: string;
  gender: string;
  dateOfBirth: string;
  orgDateOfBirth: Date;
  age:number;
  phoneNumber: string;
  profileStatus: string;
  playerType: any;
  memberStatus: string;
  rateType: any;
  address: string;
  lastVisitedDate: string;
  orgLastVisitedDate: Date;
  tooltipphone?: string[];
  tooltipemail?: string[];
  isExternalGuest : boolean;
  externalGuestId?: string;
  isLinkedWithExternalProfile?: boolean;
  platformBussinessCardRevUuid: string;
  platformBussinessCardUuid: string;
  platformGuestUuid: string;
  platformRevUuid: string;
  playerProfileAddress: PlayerProfileAPI.PlayerProfileAddress;
  playerProfileContactDetail: PlayerProfileAPI.PlayerProfileContactDetails[];
  playerProfileAddressList?: PlayerProfileAPI.PlayerProfileAddress[];
  externalReferenceNumber: string;
  vipType: string;
}


export interface PlayerProfileBasicInformation {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  image: any;
}
export enum contactType{
  phone = 1,
  email = 2,
  office=3
}
export enum SubscriptionType
{
  UnSubscribe = 0,
  Subscribe = 1
}
export interface SubscribeProfile{
  guestId: string;
  interfaceGuestId : string;
  interfaceName : string;
  subscriptionType : SubscriptionType
}
export enum GuestType{
  GolfGuest = 1,
  OperaGuest = 2
}