import { Component, EventEmitter, Inject, OnInit, Output, Pipe, PipeTransform, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { ButtonValue, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { StepperService } from 'src/app/shared/components/stepper/stepper.service';
import { AuthorizeTable, TeeSheetPlayerDetails } from 'src/app/shared/models/teesheet.form.models';
import { ContactDetails,DialogCloseObj } from 'src/app/shared/shared-models';
import { PlayerPaymentstatus } from 'src/app/tee-time/search/search-model';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { AuthorizeBusiness } from './authorize.business';
import { Actions } from '../notify/notify.model';
import { EmptyValueValidator } from 'src/app/common/log-type/empty-value.validator';
import { notifyBusinessService } from '../notify/notify.business';
import { notifyService } from '../notify/notify.service';
import { TeeTimeService } from '../teetime/tee-time.service';
import { ButtonAction } from 'src/app/shared/global.constant';
import { API } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { PlayerDeposit } from '../add-deposite-modal/add-deposite-model';


@Component({
  selector: 'app-authorize',
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.scss'],
  providers: [TeeTimeService, notifyBusinessService, notifyService, AuthorizeBusiness]
})
export class AuthorizeComponent implements OnInit {
  @Output() notifyParent = new EventEmitter();
  form: UntypedFormGroup;
  captions: any;
  floatLabel: string;
  tableOptions: TableOptions;
  headerOptions: TableHeaderOptions[];
  tableContent: AuthorizeTable[]=[];
  proceedButton: ButtonValue;
  cancelButton: ButtonValue; 
  playerdetail: TeeSheetPlayerDetails[];
  playerIds : number[] = [];
  contactInfo : ContactDetails[];
  isCancelPlayer : boolean;
  sum:number=0; 
  clientInfo:any;
  changedemailId: number;
  changedphoneId: number;
  extension: string = '';
  phone:string='e';
  name:string ='w';
  code:string='g';
  email:string='d';
  action: string;
  notifyplayers: UntypedFormGroup;
  teeTimeConfig: API.Settings<API.TeeTimeConfig>; 

  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  disableEmail: boolean = true;
  disablePhone: boolean = true;
  isEmailRequired:boolean = false;
  isPhoneRequired:boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: { info: any }, private formBuilder: UntypedFormBuilder,public _StepperService: StepperService,
  public localization: GolfLocalization, private _utilities:GolfUtilities, public dialog: MatDialog, public _Localization: GolfLocalization,
  private business:AuthorizeBusiness,private dialogRef: MatDialogRef<AuthorizeComponent>, public _notifyBusinessService: notifyBusinessService) { 
    this.floatLabel = this.localization.setFloatLabel;
    this.captions = this.localization.captions.teetime;

  }

  async ngOnInit() {
    
    this.playerdetail = this.dialogData?.info?.playerDetail;
    this.notifyplayers = this.formBuilder.group({
      enableSendEmail: false,
      enableSemdSMS: false,
      enableSaveEmail: false,
      playerEmailList: this.formBuilder.array([]),
    });
    if(this.dialogData.info.playerDetail){
      this.playerIds = this.dialogData.info.playerDetail.filter(x=> !x.isBlocked).map(x=> x.playerId);
      this.isCancelPlayer = false;
    }
    else if(this.dialogData.info.Obj && this.dialogData.info.Obj.status.toUpperCase() == "CANCELLED"){
      this.playerIds.push(this.dialogData.info.Obj.schedulePlayerID); 
      this.isCancelPlayer = true;
    }
    this.getTableData();

    this.initialize();
    this.intializeTableInputs();
    this.pageSubscriptions();
    this._StepperService.enableSave = false;
  }

  async getTableData()
  {
    let name : string;
    let status : PlayerPaymentstatus;
    let playerId : number;
    let confirmationNumber: string;
    let depositData : PlayerDeposit[] = [];
    if(this.playerIds.length > 0){
      this._utilities.ToggleLoader(true);
      this.contactInfo  = await this.getContactInfo(this.playerIds);
      this._utilities.ToggleLoader(true);
      depositData = await this.GetDepositPlayers();
      this._utilities.ToggleLoader(false);
        this.playerIds.forEach((x,i)=> {
          if(this.isCancelPlayer){
            name = this.dialogData.info.Obj.playerName;
            status = PlayerPaymentstatus.cancelled;
          }
          else{
            let playerDetail = this.dialogData.info.playerDetail.find(a=> a.playerId == x);
            if(playerDetail){
                name = this._utilities.formatGuestName(playerDetail.firstName, playerDetail.lastName);
              status = playerDetail.playerStatus; 
              playerId = playerDetail.playerId;
              confirmationNumber = playerDetail.confirmationNumber; 
            }                      
          }
          let emailInfo =this.contactInfo &&this.contactInfo.length>0 && this.contactInfo.find(y => y.name == "Email" && y.playerId == x);
          let phonenoInfo =this.contactInfo &&this.contactInfo.length>0 && this.contactInfo.find(y => y.name == "Phone" && y.playerId == x);
          let phoneExtension:any[]=phonenoInfo &&(phonenoInfo != null) ? phonenoInfo.value.split(':') : [];
          let hasPhoneExtension = (phoneExtension!=null && phoneExtension.length==1)?phoneExtension[0]:'';
          let phoneInfomArray=phoneExtension &&(phoneExtension!=null&&phoneExtension.length==2) ?
          phoneExtension[1] : hasPhoneExtension;
          let phoneInfoArray : any[] = phoneInfomArray &&(phoneInfomArray != null) ? phoneInfomArray.split('|') : [];
          let hasPhoneInfoArray = ((phoneInfoArray !=null && phoneInfoArray.length == 1) ? phoneInfoArray[0] : '');
          let phone = phoneInfoArray &&(phoneInfoArray != null && phoneInfoArray.length == 2) ? phoneInfoArray[1] : hasPhoneInfoArray;
          let countrycode = phoneInfoArray &&(phoneInfoArray != null && phoneInfoArray.length == 2) ? phoneInfoArray[0] : ''; 
          let email = emailInfo &&(emailInfo != null) ? emailInfo.value : '';
          this.action = this.getAction(status);
          let depositAmount = depositData.find(y=>y.id == x);
          this.tableContent.push(
            {
              checked : (email != '') || (phone != ''),
              name: name,
              emailId: email,
              countryCode: countrycode,
              phoneNo : phone,
              amount: depositAmount.amount,
              balanceDue: depositAmount.balance,
              id: playerId,
              action: this.action,
              confirmNumber:confirmationNumber
            });
        });
        this.tableContent = [...this.tableContent];
       
      }
  }

  getAction(playerstatus: PlayerPaymentstatus) {
    if ((PlayerPaymentstatus.paid & playerstatus) != 0) {
      return Actions.AUTHORIZEPAYMENT;
    } else if ((PlayerPaymentstatus.cancelled & playerstatus) != 0) {
      return Actions.TEETIMECANCELLATION;
    } else if ((PlayerPaymentstatus.noShow & playerstatus) != 0) {
      return  Actions.TEETIMENOSHOW;
    } else if (((PlayerPaymentstatus.booked & playerstatus) != 0) || ((PlayerPaymentstatus.refund & playerstatus) != 0)) {
      return Actions.AUTHORIZEPAYMENT;
    }
  }

  async GetDepositPlayers() {
    return await this.business.GetDepositPlayers(this.dialogData.info);
  }

  initialize(){
    this.form = this.formBuilder.group({
      linkCode: '',
      linkAppointments: '',
      email :'',
      sms: ''
    });
    this.proceedButton = {
      type: 'primary',
      label: this.captions.btn_proceed,
      disabledproperty: false
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.cancel
    }
    // this.notifyplayers.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe((x) => {
    //   // this._StepperService.valueChange.pipe(takeUntil(this.$destroyed)).subscribe(() => {
    //     this._StepperService.enableSave = (this.notifyplayers.controls['enableSemdSMS'].value || this.notifyplayers.controls['enableSendEmail'].value) && this.isTableContentInValid();
    //   // });
    // });
    this.isPageValid();
  }

  intializeTableInputs() {
    this.tableOptions = this.business.getTableOptions();
    this.headerOptions = this.business.getHeaderOptions(this.disableEmail, this.disablePhone);
    //this.tableContent = this.getTableContent();
  }
  pageSubscriptions() {
    this._StepperService.valueChange.pipe(takeUntil(this.$destroyed)).subscribe((x: DialogCloseObj) => {
      if (x.type == ButtonAction.save) {       
        this.sendNotification();
        x.dialogRef.close(x.type);
      }
      else {
        x.dialogRef.close(x.type);
      }
    });
  }

  async getContactInfo(playerIds : number[]) : Promise<ContactDetails[]>{
    return this.business.GetContactInfo(playerIds);
  }

  async sendNotification() {
    let checkedplayer = this.tableContent.filter(x=>x.checked);
   
    let enableSendEmail = this.notifyplayers.controls.enableSendEmail.value;
    let enableSemdSMS = this.notifyplayers.controls.enableSemdSMS.value;
    let enableSaveEmail = this.notifyplayers.controls.enableSaveEmail.value;
    await this._notifyBusinessService.sendNotification(checkedplayer, enableSendEmail,enableSemdSMS, enableSaveEmail, this.action, null, true);
  }

  async emitnotify() {
    let checkedplayer = this.tableContent.filter(x=>x.checked);
    this.notifyParent.emit(this.notifyplayers.valid && (checkedplayer.length > 0) && ((this.notifyplayers.controls['enableSendEmail'].value) || (this.notifyplayers.controls['enableSemdSMS'].value)));
  }

  checkclicked(e, i) {
    let currentControl = this.notifyplayers.controls['playerEmailList']['controls'][i];
    if (e) {
      if (this.notifyplayers.controls['enableSendEmail'].value) {
        currentControl.controls['emailId'].enable();
        currentControl.get("emailId").markAsTouched();
        currentControl.get("emailId").setValidators([Validators.required, EmptyValueValidator, Validators.email]);
        currentControl.get("emailId").updateValueAndValidity();
      } else {
        currentControl.controls['emailId'].disable();
      }
      if (this.notifyplayers.controls['enableSemdSMS'].value) {
        currentControl.controls['phoneNo'].enable();
        currentControl.get("phoneNo").markAsTouched();
        currentControl.get("phoneNo").setValidators([Validators.required]);
        currentControl.get("phoneNo").updateValueAndValidity();
        
        currentControl.controls['countryCode'].enable();
        currentControl.get("countryCode").markAsTouched();
        currentControl.get("countryCode").setValidators([Validators.required]);
        currentControl.get("countryCode").updateValueAndValidity();

        
      } else {
        currentControl.controls['countryCode'].disable();
        currentControl.controls['phoneNo'].disable();
      }
    } else {
      currentControl.controls['emailId'].disable();
      currentControl.controls['countryCode'].disable();
      currentControl.controls['phoneNo'].disable();
      currentControl.get("emailId").clearValidators();
      currentControl.get("emailId").updateValueAndValidity();
    }
  }

  private maskPhoneNo(): string {
    return this._Localization.captions.common.PhoneFormat ?
      this._Localization.captions.common.PhoneFormat : '999999999999999999';
  }

  onCancel(){
    this.dialogRef.close();
  }
  onAction(){
    
  }
  onRowClick(eve){

  }
  onTableAction(eve){
      switch (eve.fromType) {
        // case FromTypeEnum.input:
        //   this.tableContent.forEach(x=>{
        //     if(x.id == eve.Obj.id){
        //       x[eve.updatedKey] = eve.value;
        //     }
        //   })
        //   this.tableContent = [...this.tableContent];
        //   break;
        case FromTypeEnum.rowcheck:
              this.tableContent.forEach(x=>{
                if(x.confirmNumber == eve.Obj.confirmNumber){
                  x.checked = eve.Obj.checked;
                }
              })
              this.tableContent = [...this.tableContent]
            break;
      }
  }
  close(){

  }
  emailChange(eve){
    this.isEmailRequired = eve;
    eve ==true ? this.disableEmail = false : this.disableEmail = true;
    this.headerOptions = this.business.getHeaderOptions(this.disableEmail, this.disablePhone);
    this.isPageValid();
  }
  phoneChange(eve){
    this.isPhoneRequired = eve;
    eve ==true ? this.disablePhone = false : this.disablePhone = true;
    this.headerOptions = this.business.getHeaderOptions(this.disableEmail, this.disablePhone);
    this.isPageValid();
  }
  ngOnDestroy() {
    if (this.$destroyed) {
      this.$destroyed.next(true);
      this.$destroyed.complete();
    }
  }

  updateKeys(e, element, key){
    element[key] = e.target.value;
    this.isPageValid();
  }
  emitTableData(e, element, key){
    element[key] = e.target.value;
    this.tableContent.map((x)=>{
        if(x.id == element['id']){
          x[key] = e.target.value;   
        }
    });
    console.log('authorise', this.tableContent);
    this.isPageValid();
  }

  isPageValid(){
    setTimeout(()=>{
      this._StepperService.enableSave = (this.isEmailRequired || this.isPhoneRequired) && (document.getElementsByClassName('invalid-authorize').length > 0? false:true);
    },100);
  }
} 



@Pipe({
  name: 'ValidCheck'
})
export class ValidCheckPipe implements PipeTransform {
  transform(value: any, isReq:boolean, type:string, phone?): string {
    let isInvalid = false;
    if(isReq){
      if(type =='phone'){
        isInvalid = (value && this.isPhoneValid(value))? false: true;
      } else if(type == 'email'){
        isInvalid = (value && this.isEmailValid(value))? false: true;
      } else if(type =='ccode'){
        isInvalid = value? false: true;
      }
    } else{
      isInvalid = false;
    }
    return isInvalid? 'invalid-authorize': '';
  }

  isEmailValid(email){
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  isPhoneValid(phone){
    let phoneValid = false;
    phoneValid = phone && (phone.length >=8 && phone.length <=12);
    return phoneValid;
  }
}

