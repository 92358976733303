import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableHeaderOptions } from 'src/app/common/Models/ag-models';

import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { NotifierBar } from 'src/app/shared/components/note/note.model';
import { TeeFeeContent, TeeFeeGridContent } from 'src/app/shared/models/teesheet.form.models';
import { AlertType, ButtonType } from 'src/app/shared/shared-models';


@Component({
  selector: 'teefee-alert-popup',
  templateUrl: './teefee-component.html',
  styleUrls: ['./teefee.component.scss']
})
export class TeefeeAlertPopupComponent   {
  continueText = this._Localization.captions.alertPopup.continue;
  cancelText = this._Localization.captions.alertPopup.cancel;
  YES = this._Localization.captions.alertPopup.yes;
  NO = this._Localization.captions.alertPopup.no;
  okText = this._Localization.captions.alertPopup.okay;
  success = this._Localization.captions.alertPopup.success;
  warning = this._Localization.captions.alertPopup.warning;
  error = this._Localization.captions.alertPopup.error;
  wellDone = this._Localization.captions.alertPopup.welldone;
  info = this._Localization.captions.alertPopup.info;
  accessDenied = this._Localization.captions.alertPopup.accessDenined;
  public alertType = AlertType;
  public buttonType = ButtonType;
  productVersion: string;
  tableHeaders: TableHeaderOptions[] = [];
  tableContent = [];
  notificationbar: NotifierBar;
 
  useSourceRate:string = this._Localization.captions.teetime.UseSourceTeeFee;
  useDestinationRate:string = this._Localization.captions.teetime.UseDestinationTeeFee;
  cancelMove:string = this._Localization.captions.teetime.teetimecancel;
  disableUseDestination:boolean;
  destinationSlotFeeNotAllowed:string = this._Localization.captions.teetime.DestinationSlotFeeNotAllowed;
  rateDetails:string = this._Localization.captions.teetime.lbl_rateDetails
  teeFeeContent: TeeFeeContent[];
  teeFeeGridContent: TeeFeeGridContent[] = [];

  constructor(public dialogRef: MatDialogRef<TeefeeAlertPopupComponent>, @Inject(MAT_DIALOG_DATA) public data,private _Localization:GolfLocalization) {
    this.disableUseDestination = this.data.disableUseDestinationTeeFee;
    let appver = sessionStorage.getItem('productVersion');
    this.productVersion = appver? appver :'12.2';
    this.tableHeaders = data?.tableHeaders != null && data?.tableHeaders.length > 0 ? data?.tableHeaders : [];
    this.tableContent = data?.tableContent != null && data?.tableContent.length > 0 ? data?.tableContent : [];
    this.notificationbar = {
      class: '',
      value: data.message,
      color: "#fff16e",
      isRemovable: false
    }
  }

  DialogClose(result?: any): void {
    this.dialogRef.close(result);
  }

 


}
