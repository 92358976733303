import { Injectable } from '@angular/core';
import { PlayerType } from 'src/app/settings/golf-setup/code-setup/player-type/player-type.modal';
import { GolfManagementCommunication } from '../../communication/services/golfmanagement.service';
import { PlayerTypeGuarantee } from '../../shared-models';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';
import { API } from 'src/app/settings/golf-setup/code-linking/code-link-modal';
import { GuaranteeType } from '../../global.constant';
import { DropDownData } from 'src/app/reports/report.model';


@Injectable({
  providedIn:"root"
})

export class PlayerTypeService {

  constructor(
    private _golfManagementCommunication: GolfManagementCommunication,
    private _golfScheduleCommunication: GolfScheduleCommunication) {
  }

  guaranteeData: PlayerTypeGuarantee[] = [
    { description: 'None', id: 1, name: 'None', disabled: false },
    { description: 'Member', id: 2, name: 'Member', disabled: false },
    { description: 'Resort', id: 3, name: 'Resort', disabled: false },
    { description: 'Credit Card', id: 4, name: 'Credit Card', disabled: false }
  ];

  public async getAllPlayerTypes(bShowActivePlayerTypes: boolean = true): Promise<PlayerType[]> {
    return await this._golfManagementCommunication.getPromise<PlayerType[]>(
      { route: GolfApiRoute.GetAllPlayerTypes, uriParams: { "isActive": bShowActivePlayerTypes } });     
  }

  public  getAllPlayerTypesForRBook(bShowActivePlayerTypes: boolean = true): Promise<PlayerType[]>{
    return this._golfManagementCommunication.getPromise<PlayerType[]>(
      { route: GolfApiRoute.GetAllPlayerTypes, uriParams: { "isActive": bShowActivePlayerTypes } }); 
  }



  public async getAllPlayerTypesForDropdown(bShowActivePlayerTypes: boolean = true): Promise<DropDownData[]> {
    const playerType$ = this._golfManagementCommunication.getPromise<PlayerType[]>(
      { route: GolfApiRoute.GetAllPlayerTypes, uriParams: { "isActive": bShowActivePlayerTypes } });  
    return this.mapToDropDown<PlayerType>('id', 'type', playerType$, 'listOrder');
  }

  private async mapToDropDown<T>(identifier: keyof T, description: keyof T, source: Promise<T[]>, listOrder: keyof T = null, isActive: keyof T = null): Promise<DropDownData[]> {
    const data = await source;
    return data.map(d => {
      return {
        id: d[identifier] as unknown as number,
        description: d[description] as unknown as string,
        showInDropDown: true,
        isActive: (isActive != null) ? d[isActive] as unknown as boolean : true,
        listOrder: (listOrder != null) ? d[listOrder] as unknown as number : null
      } as DropDownData;
    });
  }

  public async getAllMemberPlayerTypes(bShowActivePlayerTypes: boolean = true): Promise<PlayerType[]> {
    let playerType =  await this._golfManagementCommunication.getPromise<PlayerType[]>(
      { route: GolfApiRoute.GetAllPlayerTypes, uriParams: { "isActive": bShowActivePlayerTypes } });   
      return playerType.filter(c=>c.guaranteeType == GuaranteeType.Member)  
  }

  public async getPlayerType(id: number): Promise<PlayerType> {
    return await this._golfManagementCommunication.getPromise<PlayerType>(
      { route: GolfApiRoute.GetPlayerType, uriParams: { "id": id } });
    
  }

  public createPlayerType(playerType: PlayerType): Promise<PlayerType[]> {
    return this._golfManagementCommunication.postPromise<PlayerType[]>({ route: GolfApiRoute.CreatePlayerType, body: playerType });
  }

  public updatePlayerType(playerType: PlayerType): Promise<PlayerType[]> {
    return this._golfManagementCommunication.putPromise<PlayerType[]>({ route: GolfApiRoute.UpdatePlayerType, body: playerType });
  }

  public async deletePlayerType(id: number): Promise<boolean> {
    return this._golfManagementCommunication.deletePromise<boolean>(
      { route: GolfApiRoute.DeletePlayerType, uriParams: { "id": id } });
  }

  public async getNextAvailableListOrder(): Promise<number> {
    return await this._golfManagementCommunication.getPromise<number>({ route: GolfApiRoute.GetNextAvailableListOrder });
     
  }

  public async isAllowToInactive(id: number): Promise<boolean> {
    return await this._golfScheduleCommunication.getPromise<boolean>({ route: GolfApiRoute.AllowToInactive, uriParams: { "playerTypeId": id } });
     
  }

  public async getPlayerTypeRateTypes(): Promise<API.PlayerTypeRateType[]> {
    return this._golfManagementCommunication.getPromise<API.PlayerTypeRateType[]>({
      route: GolfApiRoute.GetPlayerTypeRateTypes
    });
  }
}
