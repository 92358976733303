import { Injectable } from "@angular/core";
import { GolfLocalization } from "src/app/core/localization/golf-localization";

@Injectable({
  providedIn: "root"
})
export class TitleTooltip {
  // Common Global flag that should come from Default
  public allowTooltipGlobal = true;
  public toolTip = this._Localization.captions.tooltips;

  // titleTooltip object should contain screen name aith array of objects for every control.
  public titleTooltip: TooltipObject = {
    // Settings > System Setup > Defaults
    defaultSettings: [
      {
        allowTooltip: true,
        formControlName: "autotimehold",
        titleDescription: this.toolTip.defaultSettings.autotimehold
      },
      {
        allowTooltip: true,
        formControlName: "checkinginterval",
        titleDescription: this.toolTip.defaultSettings.checkinginterval
      }
    ],
    // Settings > System Setup > Property Information
    propertyInformationSetttings: [
      {
        allowTooltip: true,
        formControlName: "name",
        titleDescription: this.toolTip.propertyInformationSetttings.name
      },
      {
        allowTooltip: true,
        formControlName: "postalcode",
        titleDescription: this.toolTip.propertyInformationSetttings.postalcode
      },
      {
        allowTooltip: true,
        formControlName: "city",
        titleDescription: this.toolTip.propertyInformationSetttings.city
      },
      {
        allowTooltip: true,
        formControlName: "state",
        titleDescription: this.toolTip.propertyInformationSetttings.state
      },
      {
        allowTooltip: true,
        formControlName: "country",
        titleDescription: this.toolTip.propertyInformationSetttings.country
      },
      {
        allowTooltip: true,
        formControlName: "tenantId",
        titleDescription: this.toolTip.propertyInformationSetttings.tenantId
      },
      {
        allowTooltip: true,
        formControlName: "propCode",
        titleDescription: this.toolTip.propertyInformationSetttings.propCode
      }
    ],

    // Settings > System Setup > Tee Times
    teeTimesSettings: [
      {
        allowTooltip: true,
        formControlName: "minInput",
        titleDescription: this.toolTip.teeTimesSettings.minInput
      },
      {
        allowTooltip: true,
        formControlName: "rainCheckNote",
        titleDescription: this.toolTip.teeTimesSettings.rainCheckNote
      },
      {
        allowTooltip: true,
        formControlName: "teeTimeTicketNote",
        titleDescription: this.toolTip.teeTimesSettings.teeTimeTicketNote
      },
      {
        allowTooltip: true,
        formControlName: "firstDeposit",
        titleDescription: this.toolTip.teeTimesSettings.firstDeposit
      },
      {
        allowTooltip: true,
        formControlName: "secondDeposit",
        titleDescription: this.toolTip.teeTimesSettings.secondDeposit
      },
      {
        allowTooltip: true,
        formControlName: "depositRefund",
        titleDescription: this.toolTip.teeTimesSettings.depositRefund
      },
      {
        allowTooltip: true,
        formControlName: "dueDaysAfterBooking",
        titleDescription: this.toolTip.teeTimesSettings.dueDaysAfterBooking
      },
      {
        allowTooltip: true,
        formControlName: "dueDaysPriorToPlay1",
        titleDescription: this.toolTip.teeTimesSettings.dueDaysPriorToPlay1
      },
      {
        allowTooltip: true,
        formControlName: "dueDaysPriorToPlay2",
        titleDescription: this.toolTip.teeTimesSettings.dueDaysPriorToPlay2
      }
    ],

    // Settings > System Setup > Credit Cards
    creditCardSettings: [
      {
        allowTooltip: true,
        formControlName: "rGuestPayAgentUrl",
        titleDescription: this.toolTip.creditCardSettings.rGuestPayAgentUrl
      },
      {
        allowTooltip: true,
        formControlName: "rGuestPayGatewayID",
        titleDescription: this.toolTip.creditCardSettings.rGuestPayGatewayID
      },
      {
        allowTooltip: true,
        formControlName: "gateway_key",
        titleDescription: this.toolTip.creditCardSettings.gateway_key
      },
      {
        allowTooltip: true,
        formControlName: "gateway_value",
        titleDescription: this.toolTip.creditCardSettings.gateway_value
      }
    ],

    // Settings > Golf Setup > Course > Create Course
    createCourseSettings: [
      {
        allowTooltip: true,
        formControlName: "courseNameForm",
        titleDescription: this.toolTip.createCourseSettings.courseNameForm
      },
      {
        allowTooltip: true,
        formControlName: "durationPlayForm",
        titleDescription: this.toolTip.createCourseSettings.durationPlayForm
      },
      {
        allowTooltip: true,
        formControlName: "durationPlayEighteenForm",
        titleDescription: this.toolTip.createCourseSettings.durationPlayEighteenForm
      }
    ],

    // Settings > Golf Setup > Lesson Location
    lessonLocationSettings: [
      {
        allowTooltip: true,
        formControlName: "actionInput",
        titleDescription: this.toolTip.lessonLocationSettings.actionInput
      }
    ],

    // Settings > Golf Setup > Tee Color > Create Tee Color
    createTeeColorSettings: [
      {
        allowTooltip: true,
        formControlName: "teeColorForm",
        titleDescription: this.toolTip.createTeeColorSettings.teeColorForm
      },
      {
        allowTooltip: true,
        formControlName: "slopeForm",
        titleDescription: this.toolTip.createTeeColorSettings.slopeForm
      },
      {
        allowTooltip: true,
        formControlName: "ratingForm",
        titleDescription: this.toolTip.createTeeColorSettings.ratingForm
      }
    ],

    // Settings > Golf Setup > Tee Time Allocation Block > Create Allocation Block
    createAllocationBlockSettings: [
      {
        allowTooltip: true,
        formControlName: "allocationBlockCode",
        titleDescription: this.toolTip.createAllocationBlockSettings.allocationBlockCode
      },
      {
        allowTooltip: true,
        formControlName: "allocationBlockName",
        titleDescription: this.toolTip.createAllocationBlockSettings.allocationBlockName
      },
      {
        allowTooltip: true,
        formControlName: "daysOut",
        titleDescription: this.toolTip.createAllocationBlockSettings.daysOut
      },
      {
        allowTooltip: true,
        formControlName: "listOrder",
        titleDescription: this.toolTip.createAllocationBlockSettings.listOrder
      },
      {
        allowTooltip: true,
        formControlName: "colorOptions",
        titleDescription: this.toolTip.createAllocationBlockSettings.colorOptions
      }
    ],

    // Settings > Golf Setup > Player Type > Create Player Type
    createPlayerTypeSettings: [
      {
        allowTooltip: true,
        formControlName: "type",
        titleDescription: this.toolTip.createPlayerTypeSettings.type
      },
      {
        allowTooltip: true,
        formControlName: "daysOutStart",
        titleDescription: this.toolTip.createPlayerTypeSettings.daysOutStart
      },
      {
        allowTooltip: true,
        formControlName: "daysOutEnd",
        titleDescription: this.toolTip.createPlayerTypeSettings.daysOutEnd
      },
      {
        allowTooltip: true,
        formControlName: "listOrder",
        titleDescription: this.toolTip.createPlayerTypeSettings.listOrder
      }
    ],

    // Settings > Golf Setup > Rate Type > Create Rate Type
    createRateTypeSettings: [
      {
        allowTooltip: true,
        formControlName: "type",
        titleDescription: this.toolTip.createRateTypeSettings.type
      },
      {
        allowTooltip: true,
        formControlName: "listOrder",
        titleDescription: this.toolTip.createRateTypeSettings.listOrder
      }
    ],

    // Settings > Golf Setup > Caddy Type > Create Caddy Type
    createCaddyTypeSettings: [
      {
        allowTooltip: true,
        formControlName: "caddyType",
        titleDescription: this.toolTip.createCaddyTypeSettings.caddyType
      },
      {
        allowTooltip: true,
        formControlName: "listOrder",
        titleDescription: this.toolTip.createCaddyTypeSettings.listOrder
      }
    ],

    // Settings > Golf Setup > dropdown - Rain check setup - update
    updateRainCheckSetupSettings: [
      {
        allowTooltip: true,
        formControlName: "greenFee",
        titleDescription: this.toolTip.updateRainCheckSetupSettings.greenFee
      },
      {
        allowTooltip: true,
        formControlName: "cartFee",
        titleDescription: this.toolTip.updateRainCheckSetupSettings.cartFee
      }
    ],

    // Settings > Golf Setup > dropdown - Tee Time interval setup - create tee time interval
    createTeeTimeIntervalSettings: [
      {
        allowTooltip: true,
        formControlName: "interval",
        titleDescription: this.toolTip.createTeeTimeIntervalSettings.interval
      },
      {
        allowTooltip: true,
        formControlName: "intervalTwo",
        titleDescription: this.toolTip.createTeeTimeIntervalSettings.intervalTwo
      },
      {
        allowTooltip: true,
        formControlName: "holes",
        titleDescription: this.toolTip.createTeeTimeIntervalSettings.holes
      },
      {
        allowTooltip: true,
        formControlName: "doubleStarts",
        titleDescription: this.toolTip.createTeeTimeIntervalSettings.doubleStarts
      },
      {
        allowTooltip: true,
        formControlName: "startHole",
        titleDescription: this.toolTip.createTeeTimeIntervalSettings.startHole
      },
      {
        allowTooltip: true,
        formControlName: "minutes",
        titleDescription: this.toolTip.createTeeTimeIntervalSettings.minutes
      }
    ],

    // Settings > Golf Setup > dropdown - Tournament setup > tournament format > create tournament format
    createTournamentformatSettings: [
      {
        allowTooltip: true,
        formControlName: "formatName",
        titleDescription: this.toolTip.createTournamentformatSettings.formatName
      },
      {
        allowTooltip: true,
        formControlName: "scoringInput",
        titleDescription: this.toolTip.createTournamentformatSettings.scoringInput
      },
      {
        allowTooltip: true,
        formControlName: "CustomValue",
        titleDescription: this.toolTip.createTournamentformatSettings.CustomValue
      },
      {
        allowTooltip: true,
        formControlName: "scoresToUsePerTeam",
        titleDescription: this.toolTip.createTournamentformatSettings.scoresToUsePerTeam
      },
      {
        allowTooltip: true,
        formControlName: "points",
        titleDescription: this.toolTip.createTournamentformatSettings.points
      }
    ],

    // Settings > Golf Setup > dropdown - Tournament setup > tournament packages > create package plan
    createPackagePlanSettings: [
      {
        allowTooltip: true,
        formControlName: "planCode",
        titleDescription: this.toolTip.createPackagePlanSettings.planCode
      },
      {
        allowTooltip: true,
        formControlName: "planName",
        titleDescription: this.toolTip.createPackagePlanSettings.planName
      },
      {
        allowTooltip: true,
        formControlName: "planDescription",
        titleDescription: this.toolTip.createPackagePlanSettings.planDescription
      }
    ],

    //settings > golf setup > dropdown(tournaent setup) > tournamnet components > create component
    createComponentsSettings: [
      {
        allowTooltip: true,
        formControlName: "componentId",
        titleDescription: this.toolTip.createComponentsSettings.componentId
      },
      {
        allowTooltip: true,
        formControlName: "componentName",
        titleDescription: this.toolTip.createComponentsSettings.componentName
      },
      {
        allowTooltip: true,
        formControlName: "price",
        titleDescription: this.toolTip.createComponentsSettings.price
      },
      {
        allowTooltip: true,
        formControlName: "cost",
        titleDescription: this.toolTip.createComponentsSettings.cost
      },
      {
        allowTooltip: true,
        formControlName: "itemNumber",
        titleDescription: this.toolTip.createComponentsSettings.itemNumber
      }
    ],

    // Settings > golf setup > customfields > edit
    editCustomFieldsSettings: [
      {
        allowTooltip: true,
        formControlName: "fieldName",
        titleDescription: this.toolTip.editCustomFieldsSettings.fieldName
      }
    ],

    // Settings > Commission Setup
    commissionValuesSettings: [
      {
        allowTooltip: true,
        formControlName: "CommissionValue",
        titleDescription: this.toolTip.commissionValuesSettings.CommissionValue
      },
      {
        allowTooltip: true,
        formControlName: "ClassType",
        titleDescription: this.toolTip.commissionValuesSettings.ClassType
      }
    ],

    // Settings > Commission Setup > options > new
    createCommisionSettings: [
      {
        allowTooltip: true,
        formControlName: "threshold",
        titleDescription: this.toolTip.createCommisionSettings.threshold
      },
      {
        allowTooltip: true,
        formControlName: "flatAmount",
        titleDescription: this.toolTip.createCommisionSettings.flatAmount
      },
      {
        allowTooltip: true,
        formControlName: "percentage",
        titleDescription: this.toolTip.createCommisionSettings.percentage
      }
    ],

    // Settings > Instructor Setup > Create Instructor
    createInstructorSettings: [
      {
        allowTooltip: true,
        formControlName: "instructorCode",
        titleDescription: this.toolTip.createInstructorSettings.instructorCode
      },
      {
        allowTooltip: true,
        formControlName: "firstName",
        titleDescription: this.toolTip.createInstructorSettings.firstName
      },
      {
        allowTooltip: true,
        formControlName: "lastName",
        titleDescription: this.toolTip.createInstructorSettings.lastName
      },
      {
        allowTooltip: true,
        formControlName: "postalCode",
        titleDescription: this.toolTip.createInstructorSettings.postalCode
      },
      {
        allowTooltip: true,
        formControlName: "city",
        titleDescription: this.toolTip.createInstructorSettings.city
      },
      {
        allowTooltip: true,
        formControlName: "state",
        titleDescription: this.toolTip.createInstructorSettings.state
      },
      {
        allowTooltip: true,
        formControlName: "country",
        titleDescription: this.toolTip.createInstructorSettings.country
      }
    ],

    // Settings > Caddy Setup > Create Caddy
    createCaddySettings: [
      {
        allowTooltip: true,
        formControlName: "caddyNumber",
        titleDescription: this.toolTip.createCaddySettings.caddyNumber
      },
      {
        allowTooltip: true,
        formControlName: "firstName",
        titleDescription: this.toolTip.createCaddySettings.firstName
      },
      {
        allowTooltip: true,
        formControlName: "lastName",
        titleDescription: this.toolTip.createCaddySettings.lastName
      }
    ],

    // Settings > Rate Setup > Create Rate
    createRateSettings: [
      {
        allowTooltip: true,
        formControlName: "rateGreenFee",
        titleDescription:this._Localization.replacePlaceholders(this.toolTip.createRateSettings.rateGreenFee, ["option"], [this._Localization.currencySymbol]) 
      },
      {
        allowTooltip: true,
        formControlName: "rateCartFee",
        titleDescription:this._Localization.replacePlaceholders(this.toolTip.createRateSettings.rateCartFee, ["option"], [this._Localization.currencySymbol])  
      }
    ],

    // Settings > User Setup > Create User > User Settings
    createUserSettings: [
      {
        allowTooltip: true,
        formControlName: "firstName",
        titleDescription: this.toolTip.createUserSettings.firstName
      },
      {
        allowTooltip: true,
        formControlName: "lastName",
        titleDescription: this.toolTip.createUserSettings.lastName
      },
      {
        allowTooltip: true,
        formControlName: "userId",
        titleDescription: this.toolTip.createUserSettings.userId
      },
      {
        allowTooltip: true,
        formControlName: "quickId",
        titleDescription: this.toolTip.createUserSettings.quickId
      },
      {
        allowTooltip: true,
        formControlName: "emailAddress",
        titleDescription: this.toolTip.createUserSettings.emailAddress
      }
    ],

    // Settings > User Setup > Create User > Retail Settings
    createRetailSettings: [
      {
        allowTooltip: true,
        formControlName: "logOffafter",
        titleDescription: this.toolTip.createRetailSettings.logOffafter
      },
      {
        allowTooltip: true,
        formControlName: "commissionClass",
        titleDescription: this.toolTip.createRetailSettings.commissionClass
      }
    ],

    // Settings > User Setup > Create User > Golf Settings
    createGolfSettings: [
      {
        allowTooltip: true,
        formControlName: "logoffAfter",
        titleDescription: this.toolTip.createGolfSettings.logOffafter
      },
      {
        allowTooltip: true,
        formControlName: "daysOut",
        titleDescription: this.toolTip.createGolfSettings.daysOut
      }
    ],

    // Settings > User Setup > role setup
    createRoleSetupSettings: [
      {
        allowTooltip: true,
        formControlName: "actionInput",
        titleDescription: this.toolTip.createRoleSetupSettings.actionInput
      }
    ],

    // Settings > Retail > Credit Card Terminals (dropdown - Code Setup)
    creditCardTerminalsSettings: [
      {
        allowTooltip: true,
        formControlName: "terminalid",
        titleDescription: this.toolTip.creditCardTerminalsSettings.terminalid
      },
      {
        allowTooltip: true,
        formControlName: "merchantnumber",
        titleDescription: this.toolTip.creditCardTerminalsSettings.merchantnumber
      }
    ],

    // Settings > Retail > Outlets (dropdown - Code Setup)
    outletsSettings: [
      {
        allowTooltip: true,
        formControlName: "setupCodeName",
        titleDescription: this.toolTip.outletsSettings
          .setupCodeName
      }
    ],

    // Settings > Retail > Category Groups (dropdown - Code Setup)
    categoryGroupsSettings: [
      {
        allowTooltip: true,
        formControlName: "setupCodeName",
        titleDescription: this.toolTip.categoryGroupsSettings.setupCodeName
      }
    ],

    // Settings > Retail > Retail Categories (dropdown - Code Setup)
    retailCategoriesSettings: [
      {
        allowTooltip: true,
        formControlName: "categoryName",
        titleDescription: this.toolTip.retailCategoriesSettings.categoryName
      }
    ],

    // Settings > Retail > Retail Sub Categories (dropdown - Code Setup)
    retailSubCategoriesSettings: [
      {
        allowTooltip: true,
        formControlName: "subCategory",
        titleDescription: this.toolTip.retailSubCategoriesSettings.subCategory
      }
    ],

    // Settings > Retail > Unit of Measure (dropdown - Code Setup)
    unitOfMeasureSettings: [
      {
        allowTooltip: true,
        formControlName: "setupCodeName",
        titleDescription: this.toolTip.unitOfMeasureSettings.setupCodeName
      }
    ],

    // Settings > Retail > Taxes > Create New Tax (dropdown - Code Setup)
    taxesSettings: [
      {
        allowTooltip: true,
        formControlName: "taxname",
        titleDescription: this.toolTip.taxesSettings
          .taxname
      },
      {
        allowTooltip: true,
        formControlName: "taxAmount",
        titleDescription: this.toolTip.taxesSettings
          .taxAmount
      }
    ],

    // Settings > Retail > Discount Types (dropdown - Code Setup)
    discountTypesSettings: [
      {
        allowTooltip: true,
        formControlName: "setupCodeName",
        titleDescription: this.toolTip.discountTypesSettings.setupCodeName
      }
    ],

    // Settings > Retail > Payment Methods (dropdown - Code Setup)
    paymentMethodsSettings: [
      {
        allowTooltip: true,
        formControlName: "paymentMethod",
        titleDescription: this.toolTip.paymentMethodsSettings.paymentMethod
      }
    ],

    // Settings > Retail > Quick Sale Categories (dropdown - Code Setup)
    quickSaleCategoriesSettings: [
      {
        allowTooltip: true,
        formControlName: "setupCodeName",
        titleDescription: this.toolTip.quickSaleCategoriesSettings.setupCodeName
      }
    ],

    // Settings > Retail > (dropdown - Retail Setup) > Create Item > General
    createRetailItemGeneralSettings: [
      {
        allowTooltip: true,
        formControlName: "itemnumber",
        titleDescription: this.toolTip.createRetailItemGeneralSettings.itemnumber
      },
      {
        allowTooltip: true,
        formControlName: "itemdescription",
        titleDescription: this.toolTip.createRetailItemGeneralSettings.itemdescription
      },
      {
        allowTooltip: true,
        formControlName: "memberprice",
        titleDescription: this.toolTip.createRetailItemGeneralSettings.memberprice
      },
      {
        allowTooltip: true,
        formControlName: "salesprice",
        titleDescription: this.toolTip.createRetailItemGeneralSettings.salesprice
      },
      {
        allowTooltip: true,
        formControlName: "curitemcost",
        titleDescription: this._Localization.replacePlaceholders(this.toolTip.createRetailItemGeneralSettings.curitemcost, ["option"], [this._Localization.currencySymbol])
      },
      {
        allowTooltip: true,
        formControlName: "marginpercent",
        titleDescription: this.toolTip.createRetailItemGeneralSettings.marginpercent
      },
      {
        allowTooltip: true,
        formControlName: "barcode",
        titleDescription: this.toolTip.createRetailItemGeneralSettings.barcode
      },
      {
        allowTooltip: true,
        formControlName: "secbarcode",
        titleDescription: this.toolTip.createRetailItemGeneralSettings.secbarcode
      }
    ],

    // Settings > Retail > (dropdown - Retail Setup) > Create Item > Other Information
    otherInformationSettings: [
      {
        allowTooltip: true,
        formControlName: "gratuityValue",
        titleDescription: this.toolTip.otherInformationSettings.gratuityValue
      },
      {
        allowTooltip: true,
        formControlName: "serviceTaxValue",
        titleDescription: this.toolTip.otherInformationSettings.serviceTaxValue
      }
    ],

    // Settings > Retail > (dropdown - Retail Setup) > Create Item > Inventory
    inventorySettings: [
      {
        allowTooltip: true,
        formControlName: "UpdatedQuantity",
        titleDescription: this.toolTip.inventorySettings
          .UpdatedQuantity
      },
      {
        allowTooltip: true,
        formControlName: "ItemPar",
        titleDescription: this.toolTip.inventorySettings
          .ItemPar
      },
      {
        allowTooltip: true,
        formControlName: "UnitCost",
        titleDescription: this.toolTip.inventorySettings
          .UnitCost
      },
      {
        allowTooltip: true,
        formControlName: "ReasonforInventoryAdjustment",
        titleDescription: this.toolTip.inventorySettings
          .ReasonforInventoryAdjustment
      }
    ],

    // Settings > Retail > (dropdown - Retail Setup) > Create Item > Seasonal Price
    seasonalPriceSettings: [
      {
        allowTooltip: true,
        formControlName: "price",
        titleDescription: this._Localization.replacePlaceholders(this.toolTip.seasonalPriceSettings.price, ["option"], [this._Localization.currencySymbol]) 
      },
      {
        allowTooltip: true,
        formControlName: "memberPrice",
        titleDescription:this._Localization.replacePlaceholders(this.toolTip.seasonalPriceSettings.memberPrice, ["option"], [this._Localization.currencySymbol])  
      }
    ],

    // Settings > Retail > (dropdown - Commission Template) > Create New Template
    newTemplateSettings: [
      {
        allowTooltip: true,
        formControlName: "tempCode",
        titleDescription: this.toolTip.newTemplateSettings.tempCode
      },
      {
        allowTooltip: true,
        formControlName: "tempName",
        titleDescription: this.toolTip.newTemplateSettings.tempName
      },
      {
        allowTooltip: true,
        formControlName: "level",
        titleDescription: this.toolTip.newTemplateSettings.level
      },
      {
        allowTooltip: true,
        formControlName: "split",
        titleDescription: this.toolTip.newTemplateSettings.split
      }
    ],

    // Lessons > Book Lesson > Player Information
    playerInformationSettings: [
      {
        allowTooltip: true,
        formControlName: "price",
        titleDescription: this._Localization.replacePlaceholders(this.toolTip.playerInformationSettings.price, ["option"], [this._Localization.currencySymbol])  
      },
      {
        allowTooltip: true,
        formControlName: "firstName",
        titleDescription: this.toolTip.playerInformationSettings.firstName
      },
      {
        allowTooltip: true,
        formControlName: "lastName",
        titleDescription: this.toolTip.playerInformationSettings.lastName
      },
      {
        allowTooltip: true,
        formControlName: "pronounced",
        titleDescription: this.toolTip.playerInformationSettings.pronounced
      }
    ],

    // Lessons > Book Lesson > Player Contact Information
    playerContactInformationSettings: [
      {
        allowTooltip: true,
        formControlName: "postalCode",
        titleDescription: this.toolTip.playerContactInformationSettings.postalCode
      },
      {
        allowTooltip: true,
        formControlName: "city",
        titleDescription: this.toolTip.playerContactInformationSettings.city
      },
      {
        allowTooltip: true,
        formControlName: "state",
        titleDescription: this.toolTip.playerContactInformationSettings.state
      },
      {
        allowTooltip: true,
        formControlName: "country",
        titleDescription: this.toolTip.playerContactInformationSettings.country
      },
      {
        allowTooltip: true,
        formControlName: "controlName",
        titleDescription: this.toolTip.playerContactInformationSettings.controlName
      }
    ],

    // Lost & Found > Create
    createLostAndFoundSettings: [
      {
        allowTooltip: true,
        formControlName: "item",
        titleDescription: this.toolTip.createLostAndFoundSettings.item
      },
      {
        allowTooltip: true,
        formControlName: "location",
        titleDescription: this.toolTip.createLostAndFoundSettings.location
      },
      {
        allowTooltip: true,
        formControlName: "name",
        titleDescription: this.toolTip.createLostAndFoundSettings.name
      }
    ],

    // Guest > Create players > Personal Information
    personalInformationSettings: [
      {
        allowTooltip: true,
        formControlName: "firstName",
        titleDescription: this.toolTip.personalInformationSettings.firstName
      },
      {
        allowTooltip: true,
        formControlName: "lastName",
        titleDescription: this.toolTip.personalInformationSettings.lastName
      },
      {
        allowTooltip: true,
        formControlName: "pronounced",
        titleDescription: this.toolTip.personalInformationSettings.pronounced
      },
      {
        allowTooltip: true,
        formControlName: "postal_code",
        titleDescription: this.toolTip.personalInformationSettings.postal_code
      },
      {
        allowTooltip: true,
        formControlName: "city",
        titleDescription: this.toolTip.personalInformationSettings.city
      },
      {
        allowTooltip: true,
        formControlName: "state",
        titleDescription: this.toolTip.personalInformationSettings.state
      },
      {
        allowTooltip: true,
        formControlName: "country",
        titleDescription: this.toolTip.personalInformationSettings.country
      }
    ],

    // TeeTime > Tee Sheet > Book Tee Time > Player details
    playerDetailsSettings: [
      {
        allowTooltip: true,
        formControlName: "firstName",
        titleDescription: this.toolTip.playerDetailsSettings.firstName
      },
      {
        allowTooltip: true,
        formControlName: "lastName",
        titleDescription: this.toolTip.playerDetailsSettings.lastName
      },
      {
        allowTooltip: true,
        formControlName: "pronounced",
        titleDescription: this.toolTip.playerDetailsSettings.pronounced
      },
      {
        allowTooltip: true,
        formControlName: "negotiatedCartFee",
        titleDescription: this.toolTip.playerDetailsSettings.negotiatedCartFee
      },
      {
        allowTooltip: true,
        formControlName: "negotiatedGreenFee",
        titleDescription: this.toolTip.playerDetailsSettings.negotiatedGreenFee
      },
      {
        allowTooltip: true,
        formControlName: "customField4",
        titleDescription: this.toolTip.playerDetailsSettings.customField4
      },
      {
        allowTooltip: true,
        formControlName: "customField5",
        titleDescription: this.toolTip.playerDetailsSettings.customField5
      }
    ],

    // tee time > tournamnets > create tournament > tournament details
    tournamentDetailsSettings: [
      {
        allowTooltip: true,
        formControlName: "eventCode",
        titleDescription: this.toolTip.tournamentDetailsSettings.eventCode
      },
      {
        allowTooltip: true,
        formControlName: "eventName",
        titleDescription: this.toolTip.tournamentDetailsSettings.eventName
      }
    ],

    // tee time > tournamnets > create tournament > contact
    contactSettings: [
      {
        allowTooltip: true,
        formControlName: "firstName",
        titleDescription: this.toolTip.contactSettings.firstName
      },
      {
        allowTooltip: true,
        formControlName: "lastName",
        titleDescription: this.toolTip.contactSettings.lastName
      },
      {
        allowTooltip: true,
        formControlName: "postalCode",
        titleDescription: this.toolTip.contactSettings.postalCode
      },
      {
        allowTooltip: true,
        formControlName: "city",
        titleDescription: this.toolTip.contactSettings.city
      },
      {
        allowTooltip: true,
        formControlName: "state",
        titleDescription: this.toolTip.contactSettings.state
      },
      {
        allowTooltip: true,
        formControlName: "country",
        titleDescription: this.toolTip.contactSettings.country
      }
    ],

    // tee time > tournamnets > create tournament > Players
    playersSettings: [
      {
        allowTooltip: true,
        formControlName: "group",
        titleDescription: this.toolTip.playersSettings.group
      },
      {
        allowTooltip: true,
        formControlName: "cart",
        titleDescription: this.toolTip.playersSettings.cart
      },
      {
        allowTooltip: true,
        formControlName: "bulkNoOfPlayers",
        titleDescription: this.toolTip.playersSettings.bulkNoOfPlayers
      },
      {
        allowTooltip: true,
        formControlName: "firstName",
        titleDescription: this.toolTip.playersSettings.firstName
      },
      {
        allowTooltip: true,
        formControlName: "lastName",
        titleDescription: this.toolTip.playersSettings.lastName
      },
      {
        allowTooltip: true,
        formControlName: "pronounced",
        titleDescription: this.toolTip.playersSettings.pronounced
      },
      {
        allowTooltip: true,
        formControlName: "startHoleNumber",
        titleDescription: this.toolTip.playersSettings.startHoleNumber
      },
      {
        allowTooltip: true,
        formControlName: "teamNumber",
        titleDescription: this.toolTip.playersSettings.teamNumber
      },
      {
        allowTooltip: true,
        formControlName: "customField4",
        titleDescription: this.toolTip.playersSettings.customField4
      },
      {
        allowTooltip: true,
        formControlName: "customField5",
        titleDescription: this.toolTip.playersSettings.customField5
      }
    ],

    // tee time > WaitList > create new waitlist
    createNewWaitlistSettings: [
      {
        allowTooltip: true,
        formControlName: "firstName",
        titleDescription: this.toolTip.createNewWaitlistSettings.firstName
      },
      {
        allowTooltip: true,
        formControlName: "lastName",
        titleDescription: this.toolTip.createNewWaitlistSettings.lastName
      },
      {
        allowTooltip: true,
        formControlName: "memberNo",
        titleDescription: this.toolTip.createNewWaitlistSettings.memberNo
      },
      {
        allowTooltip: true,
        formControlName: "hotelReservationNo",
        titleDescription: this.toolTip.createNewWaitlistSettings.hotelReservationNo
      },
      {
        allowTooltip: true,
        formControlName: "noOfPlayers",
        titleDescription: this.toolTip.createNewWaitlistSettings.noOfPlayers
      }
    ],

    // teetime > tee sheet >group tee time > booking details
    bookingDetailsSettings: [
      {
        allowTooltip: true,
        formControlName: "bookingName",
        titleDescription: this.toolTip.bookingDetailsSettings.bookingName
      },
      {
        allowTooltip: true,
        formControlName: "bookingId",
        titleDescription: this.toolTip.bookingDetailsSettings.bookingId
      },
      {
        allowTooltip: true,
        formControlName: "noOfPlayer",
        titleDescription: this.toolTip.bookingDetailsSettings.noOfPlayer
      }
    ],

    //teetime > tee sheet > bulk tee time > player information
    bulkTeeTimePlayerInformationSettings: [
      {
        allowTooltip: true,
        formControlName: "firstName",
        titleDescription: this.toolTip.bulkTeeTimePlayerInformationSettings.firstName
      },
      {
        allowTooltip: true,
        formControlName: "lastName",
        titleDescription: this.toolTip.bulkTeeTimePlayerInformationSettings.lastName
      },
      {
        allowTooltip: true,
        formControlName: "players",
        titleDescription: this.toolTip.bulkTeeTimePlayerInformationSettings.players
      },
      {
        allowTooltip: true,
        formControlName: "playersPerGroup",
        titleDescription: this.toolTip.bulkTeeTimePlayerInformationSettings.playersPerGroup
      },
      {
        allowTooltip: true,
        formControlName: "customField4",
        titleDescription: this.toolTip.bulkTeeTimePlayerInformationSettings.customField4
      },
      {
        allowTooltip: true,
        formControlName: "customField5",
        titleDescription: this.toolTip.bulkTeeTimePlayerInformationSettings.customField5
      }
    ]
  };

  constructor(private _Localization: GolfLocalization) {}

  // Method that is invoked from the component setting the page name as parameter.
  setTitleDescription(tooltipPageObject: TooltipObjectItem[]) {
    if (this.allowTooltipGlobal) {
      this.titleGenerator(tooltipPageObject);
    }
  }

  // Method that is invoked to set the dynamic title for the form controls.
  titleGenerator(tooltipObjects: TooltipObjectItem[]) {
    if (tooltipObjects && tooltipObjects.length > 0) {
      tooltipObjects.forEach(element => {
        if (element.allowTooltip) {
          const formElement = document.querySelectorAll(
            "[formControlName=" + element.formControlName + "]"
          );
          if (formElement && formElement.length > 0) {
            formElement[0].setAttribute("title", element.titleDescription);
          }
        }
      });
    }
  }
}

interface TooltipObject {
  defaultSettings: TooltipObjectItem[];
  propertyInformationSetttings: TooltipObjectItem[];
  teeTimesSettings: TooltipObjectItem[];
  creditCardSettings: TooltipObjectItem[];
  createCourseSettings: TooltipObjectItem[];
  lessonLocationSettings: TooltipObjectItem[];
  createTeeColorSettings: TooltipObjectItem[];
  createAllocationBlockSettings: TooltipObjectItem[];
  createPlayerTypeSettings: TooltipObjectItem[];
  createRateTypeSettings: TooltipObjectItem[];
  createCaddyTypeSettings: TooltipObjectItem[];
  createInstructorSettings: TooltipObjectItem[];
  createCaddySettings: TooltipObjectItem[];
  createRateSettings: TooltipObjectItem[];
  createUserSettings: TooltipObjectItem[];
  createRetailSettings: TooltipObjectItem[];
  createGolfSettings: TooltipObjectItem[];
  creditCardTerminalsSettings: TooltipObjectItem[];
  outletsSettings: TooltipObjectItem[];
  categoryGroupsSettings: TooltipObjectItem[];
  retailCategoriesSettings: TooltipObjectItem[];
  retailSubCategoriesSettings: TooltipObjectItem[];
  unitOfMeasureSettings: TooltipObjectItem[];
  taxesSettings: TooltipObjectItem[];
  discountTypesSettings: TooltipObjectItem[];
  paymentMethodsSettings: TooltipObjectItem[];
  quickSaleCategoriesSettings: TooltipObjectItem[];
  createRetailItemGeneralSettings: TooltipObjectItem[];
  otherInformationSettings: TooltipObjectItem[];
  inventorySettings: TooltipObjectItem[];
  seasonalPriceSettings: TooltipObjectItem[];
  newTemplateSettings: TooltipObjectItem[];
  updateRainCheckSetupSettings: TooltipObjectItem[];
  createTeeTimeIntervalSettings: TooltipObjectItem[];
  createTournamentformatSettings: TooltipObjectItem[];
  createPackagePlanSettings: TooltipObjectItem[];
  commissionValuesSettings: TooltipObjectItem[];
  createCommisionSettings: TooltipObjectItem[];
  playerInformationSettings: TooltipObjectItem[];
  playerContactInformationSettings: TooltipObjectItem[];
  createLostAndFoundSettings: TooltipObjectItem[];
  personalInformationSettings: TooltipObjectItem[];
  playerDetailsSettings: TooltipObjectItem[];
  tournamentDetailsSettings: TooltipObjectItem[];
  contactSettings: TooltipObjectItem[];
  playersSettings: TooltipObjectItem[];
  createNewWaitlistSettings: TooltipObjectItem[];
  bookingDetailsSettings: TooltipObjectItem[];
  bulkTeeTimePlayerInformationSettings: TooltipObjectItem[];
  createComponentsSettings: TooltipObjectItem[];
  editCustomFieldsSettings: TooltipObjectItem[];
  createRoleSetupSettings: TooltipObjectItem[];
}

interface TooltipObjectItem {
  allowTooltip: boolean;
  formControlName: string;
  titleDescription: string;
}
