import { Injectable } from "@angular/core";
import { TeeSheetService } from './tee-sheet-booking-service';
import { FilterGroup, SlideInputs, LegendInfo, AlertType, ButtonType, AlertAction } from 'src/app/shared/shared-models';
import { Observable, ReplaySubject } from 'rxjs';
import { TeeSheetSkeletonData, TeeSheetGridContent, TimeRange, ScheduleStatus, TeeSheetPlayerDetails } from 'src/app/shared/models/teesheet.form.models';
import { AllocationBlockWithPlayerType, API } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import { MatDialogRef } from '@angular/material/dialog';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { CourseDataService } from 'src/app/shared/data-services/golfschedule/course.data.service';
import { Options } from 'src/app/dynamicforms/form-type.entity';
import { AllocationBlockDataService } from 'src/app/shared/data-services/golfschedule/allocationblock.data.service';
import { CourseComment, CourseCommentFormData } from 'src/app/settings/golf-setup/course-comment/course-comment-modal/course-comment-model';
import { CourseCommentDataService } from 'src/app/shared/data-services/golfschedule/courseComment.data.service';
import { TeeSheetSingleCourse } from '../../shared/tee-sheet/tee-sheet.singleCourse';
import { TeeSheetMultiCourse } from '../../shared/tee-sheet/tee-sheet.mulitCourse';
import { NotifierBar } from 'src/app/shared/components/note/note.model';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';
import { ContactDetails } from 'src/app/shared/models/lessons.modal';
import { MailTypes, PhoneTypes, SettingModule, SettingScreen } from 'src/app/shared/global.constant';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { TeeSheetSharedBusiness } from '../../shared/teesheet.shared.business';
import { UserdefaultsInformationService } from 'src/app/core/services/userdefaults-information.service';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { TeeTimesActionBusiness } from '../../shared/tee-action.business';
import { TeeTimesActionService } from 'src/app/shared/data-services/golfschedule/teeTimesAction.data.service';
import { AlertPopupComponent } from 'src/app/shared/components/alert-popup/alert-popup.component';
import { SettingsDataService } from 'src/app/shared/data-services/golfmanagement/settings.data.service';
import { TeeTimeActions } from 'src/app/tee-time-actions/tee-time-action.base';
import { API as setting } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { allocationBlockPlayerTypePermission } from "src/app/settings/user-setup/booking-permission/booking-permission.model";
import { AllocationBlockDataService as AllocationBlockManagementDataService } from 'src/app/shared/data-services/golfmanagement/allocationblock.data.service';
import { UserAccessModel } from "src/app/common/dataservices/authentication/useraccess-model.model";
import * as DashBoardInterface from 'src/app/home/dashboard-widgets-report/dashboard.modal';
import { Course } from 'src/app/settings/golf-setup/code-setup/course/create-course-modal/create-course-model';
import { GolfUserConfigDataService } from "src/app/shared/data-services/golfmanagement/golfuser.config.data";
import { UserConfiguration } from "src/app/shared/models/tenant.models";

import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { TeeTimeDataService } from "src/app/shared/data-services/golfschedule/TeeTime.data.service";
import { TeeSheetPlayerDetail } from "src/app/shared/models/teeSheetPlayerDetail.model";
import { CourseCommentDataService as ManagementCourseCommentDataService} from 'src/app/shared/data-services/golfmanagement/coursecomment.data.service';

@Injectable()
export class TeeSheetBusiness {
  _courses: Course[] = [];
  sliderInputs: SlideInputs;
  teeTimeConfig: setting.TeeTimeConfig;
  allocationBlocks: API.TeeTimeAllocationBlock[] = [];
  captions: any;
  responseCache: Observable<TeeSheetGridContent[]>;
  public isAllow: boolean = false;
  public isViewOnly: boolean = false;
  public selectedCourseId: number;
  public selectedDate: Date;
  public $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  $destroy: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private _TeeSheetService: TeeSheetService,
    private _allocationBlockDataService: AllocationBlockDataService,
    private _utilities: GolfUtilities, private _localization: GolfLocalization,
    private _teeSheetSingleCourse: TeeSheetSingleCourse, private _teeSheetMultiCourse: TeeSheetMultiCourse, private _courseDataService: CourseDataService,
    private _courseCommentDataService: CourseCommentDataService
    , public _userAccessBusiness: UserAccessBusiness, private _userdefaultsInformationService: UserdefaultsInformationService,
    private _propertyInformation: GolfPropertyInformation, private _teeSheetSharedBusiness: TeeSheetSharedBusiness, private _retailService: RetailSharedVariableService,
    private _teeTimesActionService: TeeTimesActionService,
    private _teeTimesActionBusiness: TeeTimesActionBusiness,
    private settingsDataService: SettingsDataService,
    private allocationCodeManagementService: AllocationBlockManagementDataService,
    private golfUserConfigDataService: GolfUserConfigDataService,
    private propertyInformation: RetailPropertyInformation,
    public quickLoginUtils: QuickLoginUtilities,
    private _teeTimeDataService: TeeTimeDataService,
    private _managementCourseCommentDataService: ManagementCourseCommentDataService
  ) {
    this.captions = this._localization.captions;
  }

  public async getCourses(): Promise<Options[]> {
    let courses = await this._courseDataService.getCoursesWithUserAccess();
    this._courses = courses;
    return courses.map(c => {
      return {
        key: c.id, label: c.name
      }
    });
  }

  public SetPaymentCourseAndTime() {
    if (this._retailService.SeletedCourseId != 0 && this._retailService.SelectedDate != "") {
      this.selectedCourseId = this._retailService.SeletedCourseId;
      this.selectedDate = this._localization.getDate(this._retailService.SelectedDate);
      this._retailService.SeletedCourseId = 0;
      this._retailService.SelectedDate = "";
    }
  }

  GetEmail(email: ContactDetails[]): any {
    let primaryEmail = email.find(x => x.isPrimary);
    let personalEmail = email.find(x => x.type == MailTypes.personal);
    let officeEmail = email.find(x => x.type == MailTypes.office);
    if (primaryEmail) {
      return primaryEmail;
    } else if (personalEmail) {
      return personalEmail;
    } else if (officeEmail) {
      return officeEmail;
    } else {
      return email[0];
    }
  }

  GetPhoneNumber(phoneNumber: ContactDetails[]): any {
    let primaryPhone = phoneNumber.find(x => x.isPrimary);
    let cellphone = phoneNumber.find(x => x.type == PhoneTypes.mobile);
    let homephone = phoneNumber.find(x => x.type == PhoneTypes.home);
    if (primaryPhone) {
      return primaryPhone;
    } else if (cellphone) {
      return cellphone;
    } else if (homephone) {
      return homephone;
    } else {
      return phoneNumber[0];
    }
  }



  getActionList() {
    return this._TeeSheetService.getActionList();
  }

  getFilterConfiguration() {
    return this._TeeSheetService.getFilterConfiguration();
  }


  getCaptions() {
    return this._TeeSheetService.captions;
  }

  public async GetAllocationBlocks(includeInActive: boolean = false): Promise<API.TeeTimeAllocationBlock[]> {
    return await this._allocationBlockDataService.getAllocationBlocks(includeInActive);
  }

  public async getLegendInfo(allAllocationBlocks: API.TeeTimeAllocationBlock[]): Promise<LegendInfo[]> {
    const allocationBlocks = (allAllocationBlocks != null && allAllocationBlocks.length > 0) ? allAllocationBlocks : await this._allocationBlockDataService.getAllocationBlocks(false);
    const allocationLegends: LegendInfo[] = allocationBlocks.map(a => {
      return {
        id: a.id,
        icon: 'icon-square',
        name: a.allocationBlockName,
        color: a.colorCode
      }
    })
    return allocationLegends.concat(this._utilities.getLegends());
  }

  getTeeSheeetID() {
    return this._TeeSheetService.getTeeSheeetID();
  }

  getTeeSheeetIDRightGrid() {
    return this._TeeSheetService.getTeeSheeetIDRightGrid();
  }

  getTeeSheetCustomTableData() {
    return this._TeeSheetService.getTeeSheetCustomTableData();
  }

  public async deletePlayer(slotDetail: TeeSheetSkeletonData, playerDetail: TeeSheetPlayerDetails): Promise<MatDialogRef<AlertPopupComponent, AlertAction> | boolean> {
    var isNotValid = await this._teeSheetSharedBusiness.showValidationCheckInCheckOut(playerDetail, this._localization.captions.common.ClearNotAllowed)
    if (isNotValid) return Promise.resolve(false);
    if (playerDetail.isPaidPlayer || playerDetail.deposits.length > 0) {
      this._utilities.showError(this.captions.teetime.deleteNoPlayersAvailable);
      return Promise.resolve(false);
    }
    if (this._teeTimesActionBusiness.checkSelectedPlayersInCart([playerDetail.playerId])) {
      this._utilities.showError(this.captions.settings.clearCartMessage);
      return Promise.resolve(false);
    }
    let quickIdConfig = this.propertyInformation.getQuickIdConfig;
    if (quickIdConfig && quickIdConfig.teeTimeClear) {
      const quickLoginDialogRef = this.quickLoginUtils.QuickLogin();
      quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroy)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
        if (quickLoginDialogResult.isLoggedIn) {
          const quickRoleId = this.quickLoginUtils.GetQuickLoginRoleId();
          const roleId = this._localization.GetUserInfo("roleId");
          let isAllocationAllowed = true;
          if (quickRoleId != roleId) {
            const allocationCodePermissionsforQuickId = await this.GetAllocationBlockPermissionByRole(quickRoleId);
            isAllocationAllowed = this.checkAllocationBlockPermission(playerDetail, allocationCodePermissionsforQuickId);
          }
          if (isAllocationAllowed) {
            return this._teeTimesActionService.deleteTeeTimePlayers(slotDetail.scheduledTeeTimeId, [playerDetail.playerId]).then(() => {
              return this._utilities.showAlert(
                this.captions.settings.clearSuccessMessage,
                AlertType.WellDone,
                ButtonType.Ok
              );
            });
          }
          this.quickLoginUtils.resetQuickIdDetails();
        }
      });
    } else {
      return this._teeTimesActionService.deleteTeeTimePlayers(slotDetail.scheduledTeeTimeId, [playerDetail.playerId]).then(() => {
        return this._utilities.showAlert(
          this.captions.settings.clearSuccessMessage,
          AlertType.WellDone,
          ButtonType.Ok
        );
      });
    }
    

  }


  private checkAllocationBlockPermission(event, allocationCodePermissions?: allocationBlockPlayerTypePermission[]): boolean {
    let res = true;
    let isAllocationBlockEnabled = this._utilities.IsAllocationCodePermissionEnabled();
    if (isAllocationBlockEnabled && event && allocationCodePermissions) {
      let nonaccessiblePlayer = [];
      let allocationBlocks = [];
      allocationBlocks = allocationCodePermissions.filter(x => x.allocationBlockId == event.allocationBlockId);
      const allocationPlayer = allocationBlocks.filter(x => x.playerTypeId == event.playerTypeId);
      if (allocationPlayer.length == 0) {
        nonaccessiblePlayer.push(event.playerType.name);
      }
      if (nonaccessiblePlayer.length > 0) {
        const uniqueNonAccessPlayer = _.uniq(nonaccessiblePlayer);
        let name = '';
        if (uniqueNonAccessPlayer.length > 0) {
          name = uniqueNonAccessPlayer.join(', ')
        }
        this._utilities.showAllocationCodePermissionDeniedPopup(name, this._localization.captions.common.ClearNotAllowed);
        res = false;
      }
    }
    return res;
  }

  public isHistoricalRecord(teeRowData: TeeSheetSkeletonData): boolean {

    var propertyCurrentDateTime = this._utilities.GetDateWithoutTime(this._propertyInformation.CurrentDate),
      teeRowDataDateTime = this._utilities.GetDateWithoutTime(this._localization.getDate(teeRowData.time));

    return propertyCurrentDateTime.getTime() <= teeRowDataDateTime.getTime() ? false : true;
  }

  sliderButtonTextGenerator(teeRowData) {
    if (teeRowData.unmodifiedSlotData.status.scheduleStatus === ScheduleStatus.hold) {
      return this.captions.releaseHold;
    }
    return teeRowData.playerDetail.length > 0 ? this.captions.editTeeTime : this.captions.bookTeeTimeSlider;
  }

  getPlayers(players) {
    if (!players) return [];  // need to modify
    const playersList = [];
    players.forEach(element => {
      playersList.push({ name: element.firstName + ' ' + element.lastName });
    });
    return playersList;
  }


  public getTeeSheetSkeleton(course: number, date: Date,isAdvancedView : boolean = false, allcourses?: Course[], allocationBlocks?: API.TeeTimeAllocationBlock[]): Observable<TeeSheetSkeletonData[]> {
    return this._teeSheetSingleCourse.getTeeSheet(course, date,isAdvancedView, allcourses, allocationBlocks);
  }

  public getTeeSheetSkeletonMultiCourse(course: number, date: Date,isAdvancedView : boolean = false): Observable<TeeSheetSkeletonData[]> {
    return this._teeSheetMultiCourse.getTeeSheet(course, date,isAdvancedView);
  }

  public async getTeeSheetFilters(): Promise<FilterGroup[]> {
    return this._teeSheetSingleCourse.getTeeSheetFilters();
  }

  async getCourseComment(courseId: number, scheduleDate: Date): Promise<NotifierBar> {
    let message: NotifierBar = {
      class: "icon-servicecharge",
      value: '',
      color: "#fff16e"
    }
    const comment: string = await this.getCourseCommentByCourseIdandDate(courseId, scheduleDate);
    message.value = comment ? comment : '';
    return message;

  }

  public getTeeSlots(course: number, date: Date, activeCourses?: any, allocationBlocks?: API.TeeTimeAllocationBlock[]) {
    return this._teeSheetSingleCourse.getTeeSheetSkeletonData(course, date, activeCourses, allocationBlocks);
}

  private async getCourseCommentByCourseIdandDate(courseId: number, scheduleDate: Date): Promise<string> {
    const courseComment: Promise<CourseComment> = this._courseCommentDataService.getCourseCommentByCourseIdandDate(courseId, scheduleDate);
    return await courseComment.then(c => { return c && c.comment });

  }

  public async getCourseCommentByCourseIdAndScheduleDat(courseId: number, scheduleDate: Date): Promise<CourseCommentFormData> {
    try{
      this._utilities.ToggleLoader(true);
      let courseComment = await this._managementCourseCommentDataService.getCourseCommentByCourseIdandDate(courseId, scheduleDate);
      this._utilities.ToggleLoader(false);
      return  this.mapToUICourseComment(courseComment, scheduleDate);
    }
    catch(ex){
      this._utilities.ToggleLoader(false);
    }
  }

  //For future use
  private mapToAPICourseComment(configValue: CourseCommentFormData): CourseComment {
    return {
      Id: configValue.id,
      CourseId: configValue.course,
      StartDate: this._localization.convertDateObjToAPIdate(configValue.startTime),
      EndDate: this._localization.convertDateObjToAPIdate(configValue.endTime),
      Comment: configValue.comment.trim(),
      isSingleDayComment: configValue.singleDayComment
    }   
  }

  private mapToUICourseComment(value: any, scheduleDate): CourseCommentFormData {
    return {
      id: (value.isSingleDayComment && value.id>0) ? value.id : 0,
      course: value.courseId,
      startTime: scheduleDate,
      endTime: scheduleDate,
      comment: value.comment.trim(),
      singleDayComment:  true
    }   
  }

  public filterByAllocation(arg: FilterGroup, source: TeeSheetSkeletonData[]): TeeSheetSkeletonData[] {
    if (arg.filtered.length === 0) {
      return source;                     // returns if nothing is selected
    }

    const selectedAllocations: number[] = arg.filtered.map(a => a.id);
    return source.filter(s => selectedAllocations.includes(s.allocation.id));
  }


  public filterByTimeRange(arg: FilterGroup, source: TeeSheetSkeletonData[]): TeeSheetSkeletonData[] {

    if (arg.filtered.length === 0) {
      return source;                     // returns if nothing is selected
    }

    // ranges selected by user in time range filter
    let selectedDateRanges: TimeRange[] = arg.filtered.map(s => s.extraParam);

    return source.filter((s: TeeSheetSkeletonData) => {
      // date to be checked
      let toCheckDte: Date = this._localization.getDate(s.time);

      // return true if date lies in the range
      return selectedDateRanges.some((range: TimeRange) => {

        if (!range) return false;

        // ignoring date considering time only
        toCheckDte = this.resetDate(toCheckDte);
        range.from = this.resetDate(range.from);
        range.to = this.resetDate(range.to);

        return this._utilities.isDateInGivenRange(toCheckDte, range.from, range.to)
      }
      );

    });

  }

  private resetDate(value: Date): Date {
    const currDte: Date = this._localization.getCurrentDate();
    value.setFullYear(currDte.getFullYear());
    value.setMonth(currDte.getMonth());
    value.setDate(currDte.getDate());

    return value;
  }

  public getDefaultCourse(allUserPermissableCourses: Options[]): number {
    const defaults = this._userdefaultsInformationService.GetDefaultCourses();
    let courseID: number = 0;

    // Checks default course is permissable to user
    if (defaults && allUserPermissableCourses.map(a => a.key).includes(defaults.id)) {
      courseID = defaults.id;
    } else {
      courseID = allUserPermissableCourses && allUserPermissableCourses.length > 0 && allUserPermissableCourses[0].key;
    }
    return courseID;
  }

  public getDefaultDate(): Date {
    return this._propertyInformation.CurrentDTTM;
  }

  public resetTeeSheet(): void {
    this._teeSheetSingleCourse.reset();
  }

  public async getGridRefreshTime$(date, allocationBlocks?: API.TeeTimeAllocationBlock[]): Promise<number> {
    return this._teeSheetSharedBusiness.getGridRefreshTime$(date, allocationBlocks);
  }

  public async validateBreakPoints(breakPointNumber, showPopup): Promise<UserAccessModel.BreakPointResult> {
    return await this._userAccessBusiness.getUserAccess(breakPointNumber, showPopup);

  }

  public async GetTeeTimeDefaults(): Promise<setting.TeeTimeConfig> {
    const apiData = await this.settingsDataService.getSettings<setting.TeeTimeConfig>(SettingModule.SystemSetup, SettingScreen.TeeTime);
    return Promise.resolve(this.mapToUI(apiData.configValue));
  }

  public async GetDefaultSettings(): Promise<setting.DefaultsSettingConfig> {
    const apiData = await this.settingsDataService.getSettings<setting.DefaultsSettingConfig>(SettingModule.SystemSetup, SettingScreen.Defaults);
    return Promise.resolve(apiData.configValue);
  }

  public CheckPayTeeTimeDayOfPlay(teeTimeAction: TeeTimeActions, isPayTeeTimeDayOfPlayEnabled, selectedDate: Date): boolean {
    let CaptionForInfo = teeTimeAction === TeeTimeActions.payment ? this.captions.teetime.dayOnPlayInfo : this.captions.teetime.CannotMarkAsPaidUntilDayOfPlay;

    if ((teeTimeAction === TeeTimeActions.payment || teeTimeAction === TeeTimeActions.markAsPaid) &&
      isPayTeeTimeDayOfPlayEnabled &&
      !this._utilities.IsPropertyDateGreaterThenDate(selectedDate)) {
      this._utilities.showAlert(CaptionForInfo, AlertType.Info);
      return true;
    }
    return false;
  }

  public async GetAllocationBlockPermissionByRole(roleId?: string): Promise<allocationBlockPlayerTypePermission[]> {
    return await this.allocationCodeManagementService.getAllocationBlockPermissionByRole(roleId);
  }

  public async GetAllocationsBlockWithPlayerTypes(): Promise<AllocationBlockWithPlayerType[]> {
    return await this.allocationCodeManagementService.getAllocationsBlockWithPlayerTypes();
  }

  private mapToUI(apiData: setting.TeeTimeConfig): setting.TeeTimeConfig {
    return {
      refreshTime: apiData.refreshTime,
      tempHoldDuration: apiData.tempHoldDuration,
      rainCheckNote: apiData.rainCheckNote,
      ticketNote: apiData.ticketNote,
      firstDeposit: apiData.firstDeposit,
      secondDeposit: apiData.secondDeposit,
      depositRefund: apiData.depositRefund,
      requirePlayerType: apiData.requirePlayerType,
      requireRateType: apiData.requireRateType,
      showMemberSignOnTeeGrid: apiData.showMemberSignOnTeeGrid,
      defaultToGraphicalTeeSheet: apiData.defaultToGraphicalTeeSheet,
      promptUserToPrintTeeTickets: apiData.promptUserToPrintTeeTickets,
      autoPrintTeeTickets: apiData.autoPrintTeeTickets,
      requireTeeTimesToPaid: apiData.requireTeeTimesToPaid,
      setPaidOnDayOfPlay: apiData.setPaidOnDayOfPlay,
      requireCheckInCheckOut: apiData.requireCheckInCheckOut,
      enableAutoCheck: apiData.enableAutoCheck,
      allowPaymentInCheckIn: apiData.allowPaymentInCheckIn,
      autoCheckInCheckOutZeroCharges: apiData.autoCheckInCheckOutZeroCharges,
      editCaddyInfoOnMoveCopy: apiData.editCaddyInfoOnMoveCopy,
      requireEformOnPaymentCheckIn: apiData.requireEformOnPaymentCheckIn,
      updateRatesForExistingBooking: apiData.updateRatesForExistingBooking
    };
  }

  public async getUserConfig(userId: number): Promise<UserConfiguration> {
    return await this.golfUserConfigDataService.getUserConfig(userId);
  }

  public async getTeeTimeAndPlayersCount(dateTime: Date, courseIds: number[]): Promise<DashBoardInterface.TeeAndPlayersCount> {
    return this._TeeSheetService.getTeeTimeAndPlayersCount(dateTime, courseIds);
  }

  cancelSingleCoursePreviousRequest() {
    this._teeSheetSingleCourse.unsubscribePending();
  }

  cancelMultiCoursePreviousRequest() {
    this._teeSheetMultiCourse.unsubscribePending();
  }
  public async getScheduleTeetimeByActivity(activityId: string) {
    return await this._TeeSheetService.GetScheduledTeeTimeByActivityId(activityId);
  }
  async getSlotScheduleTeeTimePlayers(activityId: string): Promise<TeeSheetPlayerDetail[]> {
    const result = await this._teeTimeDataService.getSlotScheduleTeeTimePlayers(undefined, "0",undefined, undefined,activityId);
    return result;
  }
}

