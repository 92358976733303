<section class="golf-container--padding newVersionWrapper" id="cancelPlayers" #infoElement>
  <div class="message-notification">
    <app-note [automationId]="automationId" [noteMessage]="notifierBar" *ngIf="notifierBar && notifierBar.value.length>0 && !allowCancellationOfGolfNowBooking && isGolfNowEnabled">
    </app-note>
  </div>
  <form [formGroup]="cancelPlayerForm" class="cancel-form">
    <div class="player-list--wrapper" *ngIf="mixedBulkPlayerGroup && !isPlayerSelected">
      <label class="golf-form--label">{{playerCaptions}}</label>
      <section class="players-list">
        <div [attr.automationId]="'Icn_cancelPlayerModal_CanChangeBulkView'" class="players-list--item" *ngFor="let player of playerList;let i=index"
          [ngClass]="selectedPlayer && selectedPlayer.playerId == player.playerId ? 'clicked': ''" (click)="CanChangeBulkView(player);">
          <ag-img-golfthumbnail [imageContent]="player.profileImage" [thumbnail]="true" imageType="client">
          </ag-img-golfthumbnail>
          <label class="player-name" matTooltip="{{player | formatName: 'firstName': 'lastName' }}">{{player | formatName: 'firstName': 'lastName'}}</label>
          <i  aria-hidden="true" class="icon-Bulk-Tee-Time" *ngIf="player && (player.teeTimeFormat == teeTimeFormat.BulkTee)"></i>
        </div>
      </section>  
    </div>  
    <section class="table_wrap">
      <app-checkbox-table [automationId]="automationId"  [tableForm]="cancelPlayerForm" [headers]="headerOption" 
      [tableData]="playersData" [childTemplate]="childTemplate" (unCheckedData)="checkBoxChange($event,'uncheck')" (checkedData)="checkBoxChange()">
      </app-checkbox-table>
    </section>
    <div class="ag-pt-4 ag_display--flex custom-align-center cancellation-field">
      <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--lg txt-area-height ag_form-control--1">
        <mat-label>{{captions.cancellation}}</mat-label>
        <mat-select [attr.automationId]="'Dd_cancelPlayerModal_cancelReason'" name="cancelReason" [ngModel]="defaultCancelReasonId" formControlName="cancelReason" (selectionChange)="reasonSelect($event)" [matTooltip]="reasonNameTitle">
            <mat-option *ngFor="let obj of cancelReasons" [value]="obj.id">
              {{obj.cancelReason}} 
            </mat-option> 
        </mat-select>
        <mat-error *ngIf="cancelPlayerForm.get('cancelReason').hasError('required')"> {{captions.missingReasonForCancel}} 
        </mat-error> 
      </mat-form-field>
      <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--lg txt-area-height ag_form-control--1 ml-5">
        <textarea [attr.automationId]="'Ta_cancelPlayerModal_comments'" matInput placeholder="{{captions.comments}}" name="cancellation" 
        formControlName="cancelComment" maxlength="255"
         ></textarea> 
      </mat-form-field>
    </div>
  </form>  
</section>

<ng-template #childTemplate let-element="element" let-key="key" let-data="data" let-row="row" let-index="idx">
  <ng-container [ngSwitch]="key">
    <ng-container *ngSwitchCase="'action'">
      <i class="icon-waive-off" [ngClass]="[row.checkBox ? 'ag_cursor--pointer' : 'ag_link--disabled', (row.checkBox && row.isIconClicked) ? 'ag_link' : '']"
      (click)="iconClick(row)"></i>
    </ng-container>
    <ng-container *ngSwitchCase="'cancellationCharge'">
      <i class="icon-alert1" *ngIf="row.cancellationCharge == null" (click)="alertIconClick(row)"></i>
    </ng-container>
  </ng-container>
</ng-template>