import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NotifierBar } from './note.model';


@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent  {
  @Input() noteMessage: NotifierBar;
  @Output() removeNotification = new EventEmitter();
  @Output() undoEmit = new EventEmitter();
  @Output() editCommentEmitter = new EventEmitter();
  @Input() fromTeeSheet;
  @Input() editCommentCaption;
  automationId:string="Note";
  @Input('automationId')
  set setAutomationId(value: string) {
    this.automationId = value ? value : '';
  }
  removeMessage($event){
    this.removeNotification.emit(false);
  }
  undo($event) {
    this.undoEmit.emit(false);
  }
  editComment(){
    this.editCommentEmitter.emit();
  }

}
