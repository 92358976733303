<section class="global-search">
<section class="globalSearchWrapper">
  <section class="globalSearch-input">
    <form [formGroup]="golfForm">
      <mat-form-field floatLabel="never" class="global-searchField">
        <input autofocus #searchText type="text" matInput formControlName="searchGroup" required [matAutocomplete]="autoGroup" (input)="filterGroup(searchText.value)">     
        <mat-autocomplete #autoGroup="matAutocomplete" [displayWith]="valueMapper" >
          <!-- <mat-option>{{searchGroupOptions|async|json}}</mat-option> -->
          <mat-option *ngIf="(searchGroupOptions | async)?.data.length == 0 && (searchGroupOptions | async)?.isSearched == true && searchText.value.length >= 3">
                {{this.captions.NoMatchesFound}}           
          </mat-option>
          <mat-option *ngIf="(searchGroupOptions | async)?.isSearched == false && searchText.value.length >= 3" disabled="true">
            {{this.captions.Loading}}
          </mat-option>
          <mat-optgroup class="global-search-optGroup" *ngFor="let group of (searchGroupOptions | async)?.data"
            [label]="group.displayname">                        
            <mat-option *ngFor="let data of group.dataCollection" [value]="data.id" (click)="linkClicked(group.title,data,$event)">
              <div class="rendering-option LW16" [ngSwitch]="group.title.name">
                <div *ngSwitchCase="titleEnum.TeeTimes" class="global-search-TeeTimes">               
                  <!-- <i  aria-hidden="true" class="client-image icon-player"></i> -->
                  <span class="imgstyle">
                    <img class="thumbnail_image" [src]="getImgUrl(data.image)" alt="">
                  </span>
                  <span innerHTML="{{data.region | highlightSearchText:searchText.value:data.vipType}}" > </span>
                  <span innerHTML="{{data.timestamp}}" ></span>
                  <span innerHTML="{{data.course}}" ></span>
                  <span innerHTML="#{{data.code | highlightSearchText:searchText.value}}" ></span>
                </div>
                <div *ngSwitchCase="titleEnum.PlayerName" class="global-search-PlayerName">               
                  <!-- <i  aria-hidden="true" class="client-image icon-player"></i> -->
                  <span class="imgstyle">
                    <img class="thumbnail_image" [src]="getImgUrl(data.image)" alt="">
                  </span>
                  <span innerHTML="{{data.region | highlightSearchText:searchText.value:data.vipType}}" ></span>
                  <span *ngIf="data.patronId" innerHTML="{{_localization.captions.bookAppointment.PatronID}}: {{data.patronId | highlightSearchText:searchText.value}}" ></span>                  
                  <span innerHTML="{{data.phNo}}" ></span>
                </div>
                <div *ngSwitchCase="titleEnum.Members" class="global-search-Members">                  
                  <!-- <i  aria-hidden="true" class="client-image icon-player"></i> -->
                  <span class="imgstyle">
                    <img class="thumbnail_image" [src]="getImgUrl(data.image)" alt="">
                  </span>
                  <span innerHTML="{{data.region | highlightSearchText:searchText.value}}"></span>
                  <span innerHTML="#{{data.code | highlightSearchText:searchText.value}}"></span>
                </div>
                <div *ngSwitchCase="titleEnum.Instructors" class="global-search-Instructors">                
                  <!-- <i  aria-hidden="true" class="client-image icon-player"></i> -->
                  <span class="imgstyle">
                    <img class="thumbnail_image" [src]="getImgUrl(data.image)" alt="">
                  </span>
                  <span innerHTML="{{data.region | highlightSearchText:searchText.value}}"></span>
                  <span innerHTML="{{this.captions.NoOfSessions}} : {{data.session}}"> </span>
                  <span innerHTML="{{data.timestamp}}"></span>
                </div>
                <div *ngSwitchCase="titleEnum.Lessons" class="global-search-Lessons">                
                  <!-- <i  aria-hidden="true" class="client-image icon-player"></i> -->
                  <span class="imgstyle">
                    <img class="thumbnail_image" [src]="getImgUrl(data.image)" alt="">
                  </span>
                  <span innerHTML="{{data.region | highlightSearchText:searchText.value:data.vipType}}"></span>
                  <span innerHTML="{{data.course}}"></span>
                  <span innerHTML="{{data.timestamp}}"></span>
                  <span innerHTML="{{ data.item}}"></span> <!-- Update Name Field Kevin Richardson -->
                </div>
                <div *ngSwitchCase="titleEnum.RetailItems" class="global-search-RetailItems">
                  <span  innerHTML="{{data.item | highlightSearchText:searchText.value}}"></span>
                </div>
              </div>
            </mat-option>
          </mat-optgroup>

        </mat-autocomplete>
        <button class="globalSearch-button" >
          <span (click)="addPlayer($event)">
            + {{captions.AddPlayer}}
          </span>
        </button>
      </mat-form-field>
    </form>
  </section>
</section>
