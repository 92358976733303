<div class='player-info'>
  <div class="player-info-header">
    <p class='golf-title'>{{captions.playerInformation}}</p>
    <div class="pb-3">
      <a attr.automationId='Lbl_{{automationId}}_autobook' class="golf__link--sm float-right" [ngClass]="[isAutoBookDisabled ? 'autobook-disable' :'',!(playersList?.length === _TeeGridService.selectedPlayers?.length)? 'autobook-disable':'']" *ngIf="isAutoBookAvailable || isFromGroupTeeTime" (click)="autoBook($event)">{{captions.autobook}}</a>
      <a attr.automationId='Lbl_{{automationId}}_selectAll' class="golf__link--sm float-right" [ngClass]="isFromGroupTeeTime? (isAutoBookDisabled?'mr-3 ag_section--disable':'mr-3'):''"  *ngIf="!isAutoBookAvailable && playersList?.length > 0 && !(playersList?.length === _TeeGridService.selectedPlayers?.length)" (click)="selectAll($event)">{{captions.selectAll}}</a>
      <a attr.automationId='Lbl_{{automationId}}_unSelectAll' class="golf__link--sm float-right" [ngClass]="isFromGroupTeeTime? (isAutoBookDisabled?'mr-3 ag_section--disable':'mr-3'):''"  *ngIf="!isAutoBookAvailable && playersList?.length > 0 && (playersList?.length === _TeeGridService.selectedPlayers?.length)" (click)="unSelectAll($event)">{{captions.unselectAll}}</a>
    </div>
  </div>

  <div class='player-list' cdkDropList [cdkDropListData]="playersList" [cdkDropListConnectedTo]="playerCollectionList" [ngClass]="isDragging||isDragDropNotAllowed? (isDragDropNotAllowed&&isDragging?'dragging-on ag_section--disable':(isDragDropNotAllowed?'ag_section--disable':'dragging-on')):''">
    <ng-container class='player-group' *ngFor='let playerGroup of playersListData; let playerGroupIndex = index'>
      <span *ngIf="playerGroupIndex == 0 || playerGroup.scheduledDateTime !== playersListData[playerGroupIndex - 1].scheduledDateTime">{{playerGroup.scheduledDateTime}}</span>
      <div attr.automationId='Btn_{{automationId}}_copyPlayers' class='player-detail' cdkDrag *ngFor='let player of playerGroup; let playerIndex = index'
           [ngClass]="player.playerClicked? 'clicked': ''" (click)="copyPlayers(player);" [cdkDragDisabled]="!player.playerClicked" (cdkDragStarted)="onDragStart($event, player)" (cdkDragEnded)="onDragDropped($event)" (cdkDragMoved)="onTeeGridDragMove($event)">
        <span class='player-index'>{{captions.p}}{{player.playerPos}}</span>
        <span class='player-name' [matTooltip]="player| formatName: 'firstName': 'lastName'">{{player| formatName: 'firstName': 'lastName'}}</span>
        <span class='player-date'>{{player.scheduledTime}}</span>
        <span class='player-icon'><i aria-hidden="true" class='icon-drag-drop'>&nbsp;</i></span>
        <span class="more-numbers" *ngIf="_TeeGridService.selectedPlayers && (_TeeGridService.selectedPlayers.length > 1)"> + {{_TeeGridService.selectedPlayers?.length - 1}}  more</span>
      </div>
    </ng-container>
  </div>
</div>
