import { Injectable } from "@angular/core";

@Injectable()

export class ReinstateTeeTimeService {

  getTeeSheeetID() {
    return {
      tableID: 'TableSearch'
    };
  }
  getTeeSheetCustomTableData() {
    return {
      isMultiView: false,
      isShowAvailableOnly: true,
      isHideCrossover: true,
      isDetailView: false,
      isOrderByHoleTime: true,
      isHoleNotificationEnabled: true,
      isCommentNotificationEnabled: false,
      isActionAvailable: false,
      isMenuEnabledOnEllipsis: false,
      isPlayerDetailIconsEnabled: false,
      isDragDisabled: true,
      isTooltipDisabled: true,
      isLoadedinPopup: true
    };
  }

  getPlayerList(playerList) {
    return playerList.map(players => {
      players['isRemovable'] = true;
      return players;
    });
  }


}