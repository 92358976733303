import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigModel, EnvironmentGlobal } from 'src/app/eatecui/environments/environment-global';
// import * as config from '../assets/json/config.json';
//import { SubPropertyDataService } from 'src/app/retail/retail-code-setup/retail-outlets/subproperty-data.service';

export enum Environment {
  Prod = 'prod',
  Staging = 'staging',
  Test = 'test',
  Dev = 'dev',
  Local = 'local',
  QA = 'qa',
  Release = 'release',
  ProdWestus = 'prodwestus'
}

@Injectable({ providedIn: 'root' })
export class EnvService {
  private _env: Environment;
  private _apiUrl: string;
  private _helpDocUrl: string;

  get env(): Environment {
    return this._env;
  }

  get apiUrl(): string {
    return this._apiUrl;
  }

  get helpDocUrl(): string {
    return this._helpDocUrl;
  }
  OutletIds : any;
  constructor( private http: HttpClient) {
    
  }

  init(envR): Promise<void> {
    return new Promise(async resolve => {
      // this.setEnvVariables();
      // const Environ = new EnvironmentGlobal();
      // const getTransaction = 'app/eatecui/assets/json/config.json';
      // const configData: ConfigModel = await this.http.get<ConfigModel>(getTransaction).toPromise();
      // const response = await fetch(getTransaction);
      // const configData = await response.json();
      // const EnvConfig: ConfigModel = Environ.setEnvVariables(configData);
      
      this._apiUrl = envR;
      // this._helpDocUrl = EnvConfig.helpDocUrl;
      // this._env = Environment[EnvConfig.stage];
      const settingUrl = 'app/eatecui/assets/json/app-settings.json';
      this.http.get<any>(settingUrl).subscribe((settingData) => {
        if ( settingData ) {
          const appSettingString = JSON.stringify(settingData);
         sessionStorage.setItem('appsetting', appSettingString);
        }
     });
     let currencyCode = this.GetPropertyInfo("Currency");
     let maxDecimalPlaces = this.GetPropertyInfo("MaxDecimalPlaces");
     let propertyId = this.GetPropertyInfo("PropertyId");
    
     sessionStorage.setItem('LocalCurrencyCode', (currencyCode ? currencyCode : 'USD'));
     sessionStorage.setItem('INICurrencyValue', (maxDecimalPlaces ? maxDecimalPlaces : '2'));
     sessionStorage.setItem('LoggedInSiteId', (propertyId ? propertyId : '0'));
      resolve();
    });
  }
  public GetPropertyInfo(name: string) {
    return this.GetsessionStorageValue("propertyInfo", name);
  }
  public GetsessionStorageValue(key: string, name: string) {
    var nameEQ = name + "=";
    var propertyInfo = sessionStorage.getItem(key);
    if (propertyInfo != null) {
      var ca = propertyInfo.split(";");
      for (let property of ca) {
        var c = property.trim();
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }
  

  // private setEnvVariables(): void {
  //   const hostname = window && window.location && window.location.hostname;
  //   if (/^.*localhost.*/.test(hostname)) {
  //     this._env = Environment.Local;
  //     this._apiUrl = config.local.apiUrl;
  //   } else if (/^eatec-webui-dev.rguest.com/.test(hostname)) {
  //     this._env = Environment.Dev;
  //     this._apiUrl = config.dev.apiUrl;
  //   } else if (/^eatec-webui-qa.rguest.com/.test(hostname)) {
  //     this._env = Environment.Test;
  //     this._apiUrl = config.qa.apiUrl;
  //   } else if (/^eatec-webui-release.rguest.com/.test(hostname)) {
  //     this._env = Environment.Release;
  //     this._apiUrl = config.release.apiUrl;
  //   } else if (/^eatec-webui-prod-westus.onagilysys.com/.test(hostname)) {
  //     this._env = Environment.ProdWestus;
  //     this._apiUrl = config.prodwestus.apiUrl;
  //   } else if (/^eatec.onagilysys.com/.test(hostname)) {
  //     this._env = Environment.ProdWestus;
  //     this._apiUrl = config.prodwestus.apiUrl;
  //   } else {
  //     console.warn(`Cannot find environment for host name ${hostname}`);
  //   }
  // }
}