import * as DefaultErrors from "../../../../assets/errors/error.en-US.json"; 
import { Injectable } from "@angular/core";
import moment from "moment";
import * as _ from "lodash";
import { Localization, JsonDataSourceType } from 'src/app/common/localization/localization';
import { localizationJSON,  DaysModel,
  Calendar,
  LocalizedMonthsModel,
  UserAlertsModal } from 'src/app/common/localization/localization.model';
import { JSONReaderService } from 'src/app/common/shared/services/load-json.service';
import { DropdownOptions, PropTimeFormat } from "src/app/common/Models/ag-models";

declare var $: any;
const DEFAULT_LOCALE = "en-US";
const DEFAULT_CURRENCY = "USD";
const DEFAULT_DECIMAL_SEP = ".";
const DEFAULT_THOUSAND_SEP = ","; 
export const enum DateTimeFormat {
  HHmmss = 1,
  HHmm
}



@Injectable({
  providedIn: 'root'
})
export class RetailLocalization extends Localization {
  public captions: any;
  public localeCode: string = DEFAULT_LOCALE;
  public currencyCode: string = DEFAULT_CURRENCY;
  public decimalSeparator: string = DEFAULT_DECIMAL_SEP;
  public thousandSeparator: string = DEFAULT_THOUSAND_SEP;
  public currencySymbol: string = "";
  public daysArray: DaysModel[] = [];
  public monthsArray: LocalizedMonthsModel[] = [];
  public shortDateFormat: string = "";
  public propertyCaptions: localizationJSON;
  public isFromJobScheduler:  boolean = false;
  public scheduleJobWildCards: DropdownOptions[];
  /**
   * Locale Days with Valid ID.
   */

  public LongDaysModel;
  public ShortDays;
  public LongDays;
  protected errorCaptions: any = {};
  protected userAlertsMsgs: UserAlertsModal[];
  ContactTypes: any;

  constructor(public jsonTextReader: JSONReaderService) {

    super(jsonTextReader);
    this.SetLocaleBasedProperties();
  }
 

  protected getJsonUrl(type: JsonDataSourceType): string {
    let url = '';
    if (type == JsonDataSourceType.ContactType) {
      url = `./assets/i18n/DataSource/${this.GetPropertyInfo("UserLanguage")}.ContactTypes.json`
    }

    return url;
  }

  /**
   * Returns the language based JSON
   */
  public getCaptions() {
    let userLanguage = this.GetPropertyInfo("UserLanguage");
    let languageJsonValue = (userLanguage == "" || userLanguage == null ? DEFAULT_LOCALE : userLanguage) + ".json";
    return this.parseCaptionsFromLanguageJSON(languageJsonValue);
  }

  protected getPropertyCaptions() {
    const propertyLanguage = this.GetPropertyInfo('Language');
    let languageJsonValue = (propertyLanguage == "" || propertyLanguage == null ? DEFAULT_LOCALE : propertyLanguage) + ".json";
    return this.parseCaptionsFromLanguageJSON(languageJsonValue);
  }


  public getUserAlertObj(code: number): UserAlertsModal {
    return this.userAlertsMsgs.find(message => message.id == code);
  }
  /**
   * Returns the Localized error string from Error.json file.
   * @param code Error Code (as in error.json)
   */
  public getError(code: number): string {
    if (!code || code == -1 || code == -2 || code == 0) {
      return this.getUnexpectedErrorMessage();
    } else {
      if (this.errorCaptions[code]) {
        return this.errorCaptions[code];
      } else {
        return this.getUnexpectedErrorMessage();
      }
    }
  }

  /**
   * This method returns list of error messages
   * @param errorCode Error code
   */
  public getErrorText(errorCode: number[]): string {
    let errorTxt = "";
    if (errorCode && errorCode.length > 0) {
      for (let error of errorCode) {
        errorTxt += "<span>" + this.getError(error) + "</span></br></br>";
      }
    }
    return errorTxt;
  }
  protected loadErrorJSON(errorJsonValue: string) {
    let errorJson = [];
    $.ajax({
      url: "./assets/errors/" + errorJsonValue,
      async: false,
      success: function (result) {
        if (typeof result == "object") {
          errorJson.push({
            "json": result,
            "success": true
          });
        }
        else {
          errorJson.push({
            "json": DefaultErrors,
            "success": false
          });
        }
      },
      error: function (result) {
        errorJson.push({
          "json": DefaultErrors,
          "success": false
        });
      }
    });
    return errorJson;
  }

  protected loadUserAlerts(userAlertJsonValue: string): UserAlertsModal[] {
    let userAlertsJSON: any = [];
    $.ajax({
      url: "./assets/userAlerts/" + userAlertJsonValue,
      async: false,
      success: function (result) {
        if (typeof result == "object") {
          userAlertsJSON.push({
            "json": result,
            "success": true
          });
        }
        else {
          userAlertsJSON.push({ 
            "success": false
          });
        }
      },
      error: function (result) {
        userAlertsJSON.push({ 
          "success": false
        });
      }
    });
    return userAlertsJSON;
  }
  protected ReadCookie(name: string) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";"); 
    for (let property of ca) {
      var c = property.trim();
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  public GetPropertyInfo(name: string) {
    return this.GetsessionStorageValue("propertyInfo", name);
  }

  public GetUserInfo(name: string) {
    return this.GetsessionStorageValue("_userInfo", name);
  }


  public GetsessionStorageValue(key: string, name: string) {
    var nameEQ = name + "=";
    var propertyInfo = sessionStorage.getItem(key);
    if (propertyInfo != null) {
      var ca = propertyInfo.split(";");

      for (let property of ca) {
        var c = property.trim();
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  protected setLocaleCode() {
    let localeCode = this.GetPropertyInfo("Language");
    this.localeCode = localeCode == null ? DEFAULT_LOCALE : localeCode;
    moment.locale(this.localeCode);
  }

  protected setLocaleCurrency() {
    let currencyCode = this.GetPropertyInfo("Currency");
    this.currencyCode = currencyCode == null ? DEFAULT_CURRENCY : currencyCode;
  }

  protected setCurrencySymbol() {
    const decimalNumber = 1;
    this.currencySymbol = decimalNumber
      .toLocaleString(this.localeCode, {
        style: "currency",
        currency: this.currencyCode,
        minimumFractionDigits: 0
      })
      .replace(/[\d]/g, "")
      .trim();
  }

  protected setDecimalSeparator(localeCode = null) {
    const decimalNumber = 1.1;
    this.decimalSeparator = decimalNumber
      .toLocaleString(localeCode ? localeCode : this.localeCode)
      .substring(1, 2);
  }

  protected setThousandSeparator(localeCode = null) {
    const decimalNumber = 1000;
    this.thousandSeparator = decimalNumber
      .toLocaleString(localeCode ? localeCode : this.localeCode)
      .substring(1, 2);
  }

  setLongDateForDial(){
    if (this.timeFormat == 'h:mm A') {
      const mnt: any = moment.localeData();
      mnt._longDateFormat.LT = 'hh:mm A';
    }
  }


  protected trimThousandSeparator(value: string): string {
    return value
      .toString()
      .split(this.thousandSeparator)
      .join("");
  }


  protected fillCalenderObject(): void {
    let ShortDaysOfWeek: string[] = moment.weekdaysShort(false); // locally sorted false starts with SUNDAY For any language.
    let ShortDaysOfWeek_Keys: string[] = [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ]; //Calendar Keys to be used across app

    let LongDaysOfWeek: string[] = moment.weekdays(false);
    let LongDaysOfWeek_Keys: string[] = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ]; //Calendar Keys to be used across app

    let monthsShort: string[] = moment.monthsShort();
    let monthsShortKeys: string[] = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    let monthsLong: string[] = moment.months();
    let monthsLongKeys: string[] = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    this.createCalendarObject(ShortDaysOfWeek_Keys, ShortDaysOfWeek);
    this.createCalendarObject(LongDaysOfWeek_Keys, LongDaysOfWeek);
    this.createCalendarObject(monthsLongKeys, monthsLong);
    this.createCalendarObject(monthsShortKeys, monthsShort);
  }

  protected createCalendarObject(keys: any[], values: any[]) {
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      this.captions.calendar[key] = values[i];
    }
  }

  protected generateMonthsArr(): LocalizedMonthsModel[] {
    let monthsShortKeys: string[] = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    let monthsLong: string[] = moment.months();
    let monthsShort: string[] = moment.monthsShort();
    let returnArr: LocalizedMonthsModel[] = [];
    for (let i = 0; i < 12; i++) {
      returnArr.push({
        id: i,
        short: monthsShort[i],
        long: monthsLong[i],
        code: monthsShortKeys[i]
      });
    }
    return returnArr;
  }

  /**
   *  Converts to localized currency
   * @param value Valid number with decimal seperator as '.'
   * @param currencySymbolRequired Flag to enable/disable currency symbol.
   * @param minFraction Defines the minimum fraction in localized currency.
   */

  public localizeCurrency(value: any, currencySymbolRequired: boolean = true, minFraction: number = 2, isFromRetailTax?:boolean,isFromTaxPercentage?: boolean): string {
    let decimalNumber: number;
    this.setMaximumDecimalValues();
    if(!isFromRetailTax){
      if(minFraction == 2)
      {
        minFraction = this.fractionLength;
      }
    } 
    this.setLocaleCurrency();
    this.setLocaleCode();
    if ((!value && value != "0") || value.length === 0 || isNaN(Number(value)))
      return "";
    decimalNumber = parseFloat(parseFloat(value).customToFixed(minFraction,isFromTaxPercentage));
    if (isNaN(decimalNumber)) decimalNumber = 0.0;
    return currencySymbolRequired
      ? decimalNumber.toLocaleString(this.localeCode, {
        style: "currency",
        currency: this.currencyCode,
        minimumFractionDigits: minFraction
      })
      : decimalNumber.toLocaleString(this.localeCode, {
        minimumFractionDigits: minFraction
      });
  }

  /**
  * Converts to localized number format without thousand separator
  * @param string A string with valid number. Decimal separator should be period (.)
  */
  localizePercentage(value: string) {
    let decimalNumber: number = 0;
    if ((!value && value != "0") || value.length === 0 || isNaN(Number(value)))
      return decimalNumber.toString();
    decimalNumber = parseFloat(value);
    if (isNaN(decimalNumber)) decimalNumber = 0;
    return decimalNumber.toLocaleString(this.localeCode, {
      minimumFractionDigits: 2
    }).split(this.thousandSeparator).join('');
  }

  /**
   * Converts  a formatted currency string to a floating-point number which can be saved into database
   * @param string A string can contain currency symbol, thousand separator, decimal separator etc.
   * eg: 1.500,50 will be converted to 1500.50
   */
  currencyToSQLFormat(value: string): number {
    if (!value || value.length === 0) return 0;
    let decimal: any;
    decimal = this.trimThousandSeparator(value);
    if (this.decimalSeparator == ",") {
      decimal = decimal.toString().replace(this.decimalSeparator, ".");
    }
    decimal = decimal.toString().replace(/[^\d.,-]/g, "");
    return isNaN(decimal) ? 0.0 : parseFloat(decimal);
  }

  /**
   * Converts a formatted currency string to a currency with decimal separator
   * @param string A string can contain currency symbol, thousand separator, decimal separator etc.
   */
  removeThousandSeparator(value: string): string {
    return value
      .toString()
      .replace(/[^\d.,-]/g, "")
      .split(this.thousandSeparator)
      .join("");
  }

  /**
   * Converts  a formatted currency string to a string with decimal separator for patch JSON model.
   * @param any[] Array of patched JSON .
   * @param string Key of patched element to be formatted.
   */
  currencyToSQLFormatForPatchedJSON(patchJson: any[], keys: string): any[] {
    let keyPath = "/" + keys;
    for (let json of  patchJson) {
      const patchedElement = json;
      if (patchedElement.path == keyPath) {
        patchedElement.value = this.currencyToSQLFormat(patchedElement.value);
        return patchedElement;
      }
    }
  }
  /**
   * Converts a moment date object to SQL date format yyyy-MM-ddTHH:ss:MM+05:30.
   * @param moment moment date object.
   * @param string Date separator.'-' will be used as default
   */
  convertMomentDateToSQLDate(value): string {
    if (value._isAMomentObject) {
      return value.format('YYYY-MM-DDTHH:mm:ss');
    } else return value;
  }

  /**
   * Converts a javascript datetime object to API datetime format YYYY-MM-DDTHH:mm.
   * @param Date javascript date object.
   */
  convertDateTimeToAPIDateTime(value: Date) {
    return moment(value).format("YYYY-MM-DDTHH:mm")
  }

  /**
* Converts a javascript date object to API date format yyyy-MM-dd.
* @param Date javascript date object.
* @param string Date separator.'-' will be used as default
*/
  localizeDisplayDate(value: Date | string, isDateTime: boolean = false): string {
    if (typeof value == "string") {
      value = this.getDate(value);
    }
    const displayStyle: string = isDateTime ? this.inputDateFormat : this.inputDateFormat;
    return moment(value).format(displayStyle);
  }

  /**
   * Converts a javascript date to localized date string.
   * @param Date javascript date.
   */
  LocalizeDate(value: Date | string): string {
    if (typeof value == "string") {
      value = this.getDate(moment(value).format("YYYY-MM-DDTHH:mm"));
    }
    return moment(value).format("DD MMM YYYY");
    
  }

  /**
   * Converts a javascript date to localized short date string.
   * @param Date javascript date.
   */
  LocalizeShortDate(value: Date): string {
    if (typeof value == "string") {
      value = this.getDate(moment(value).format("YYYY-MM-DDTHH:mm"));
    }
    return moment(value).format("ll");
  }

  /**
   * Converts a Localized Short date string to javascript date.
   * @param value Localized Short date.
   */
  DeLocalizeShortDate(value: string): Date {
    return this.getDate(moment(value, "ll").format("YYYY-MM-DDTHH:mm"));
  }

  /**
   * Converts a javascript date to localized short date time string.
   * @param Date javascript date.
   */
  LocalizeShortDateTime(value: Date): string {
    return this.LocalizeShortDate(value) + " " + this.LocalizeTime(value);
  }

  /**
   * Converts a javascript date to localized string of format 11-January-2019 11:11 am
   * @param Date javascript date.
   */
  LocalizeDateTimeFormatDDMMMMYYYY(value: Date): string {
    if (typeof value == 'string') value = this.getDate(value);
    let separator: string = "-";
    let localizedMonth: string = this.getLocalizedMonth(value);
    return value.getDate() + separator + localizedMonth + separator + value.getFullYear() + " " + this.LocalizeTime(value);
  }

  /**
   * Converts a javascript date to localized time string.
   * @param Date javascript date.    *
   */
  LocalizeTime(value: Date | string, isCapital = false): string {
    if (typeof value == "string") {
      value = this.getDate(moment(value).format("YYYY-MM-DDTHH:mm"));
    }
    if (isCapital) {
      return moment(value).format("LT");
    } else {
      return moment(value)
        .format("LT")
        .toLowerCase();
    }
  }
  /**
   * Converts localized time to ISO time format(24 hour).
   * @param string Localized time string*
   */
  DeLocalizeTime(value: string): string {
    if (value.trim() == "")
      return "";
    if(this.time24formatFlag == 24){
      return this.format24HrTime(this.TimeToDate(value));
    } else {
      return moment(value, this.timeFormat).format('HH:mm');
    }  
  }
  /**
   * Converts ISO time string to localized time string.
   * @example 13:00 to 1 PM for en-US locale
   * @param string time in AM PM format.*
   */
  LocalizeISOTime(value: string): string {
    return this.LocalizeTime(moment(value, "HH:mm").toDate());
  }
  /**
   * Returns 12 or 24 hour format.
   */
  getTimeFormat(): number {
    return (this.timeFormat.toLowerCase().indexOf('a')) > -1 ? 12 : 24; // a - means am/pm
  }
  getDefaultTime(): string {
    let date: Date = this.getCurrentDate();
    date.setHours(0, 0, 0, 0);
    return this.LocalizeTime(date);
  }

  getFirstDayOfWeek(): number {
    var localeData = moment.localeData();
    return localeData.firstDayOfWeek();
  }
  getShortDaysOfWeek(): string[] {
    return moment.weekdaysShort(true);
  }

  getLongDaysOfWeek(): string[] {
    return moment.weekdays(true);
  }

  getLocalizedDay(value: Date): string {
    return moment(value).format("dddd");
  }

  getLocalizedDayShort(value: Date): string {
    return moment(value).format("ddd");
  }

  getLocalizedMonth(value: Date): string {
    return moment(value).format("MMMM");
  }

  getLocalizedMonthShort(value: Date): string {
    return moment(value).format("MMM");
  }

  /**
   * Converts a javascript date to correponding day(Localized)
   * @param Date javascript date or Javascript ISO string.    *
   * check
   */
  getDayForDate(value: Date): string {
    return moment(value).format("ddd");
  }
  /**
   * Converts a javascript date to ISO date format string (C# API can understand this format).
   * @param Date javascript date or Javascript ISO string.    *
   */
  ConvertDateToISODate(dt: Date): string {
    try {
      if (typeof dt == "string") dt = this.getDate(moment(dt).format("YYYY-MM-DDTHH:mm"));
      return (
        dt.getFullYear() +
        "-" +
        (dt.getMonth() + 1 > 9
          ? dt.getMonth() + 1
          : "0" + (dt.getMonth() + 1)) +
        "-" +
        (dt.getDate() > 9 ? dt.getDate() : "0" + dt.getDate())
      );
    } catch (ee) { }
  }
  LocalizedTimeToISOTime(value: string): string {
    return this.ConvertDateToISOTime(
      this.getDate(moment(value, this.timeFormat).format("YYYY-MM-DDTHH:mm"))
    );
  }

  LocalizedTimeToISODateTime(value: string): string {
    return this.ConvertDateToISODateTime(
      this.getDate(moment(value, this.timeFormat).format("YYYY-MM-DDTHH:mm"))
    );
  }

  LocalizedTimeToISODate(value: string): string {
    return this.ConvertDateToISODate(
      this.getDate(moment(value, this.timeFormat).format("YYYY-MM-DDTHH:mm"))
    );
  }
  /**
   * Converts a javascript date to ISO date time format string (C# API can understand this format).
   * @param Date javascript date or Javascript ISO string.*
   */
  ConvertDateToISODateTime(dt: Date): string {
    if (typeof dt == "string") {
      dt = this.getDate(moment(dt).format("YYYY-MM-DDTHH:mm"));
    }
    if (!dt) return "";
    return (
      dt.getFullYear() +
      "-" +
      (dt.getMonth() + 1 < 10 ? "0" + (dt.getMonth() + 1) : dt.getMonth() + 1) +
      "-" +
      (dt.getDate() > 9 ? dt.getDate() : "0" + dt.getDate()) +
      "T" +
      (dt.getHours() > 9 ? dt.getHours() : "0" + dt.getHours()) +
      ":" +
      (dt.getMinutes() > 9 ? dt.getMinutes() : "0" + dt.getMinutes()) +
      ":" +
      (dt.getSeconds() > 9 ? dt.getSeconds() : "0" + dt.getSeconds())
    );
  }
  /**
   * Converts a javascript date to ISO date time format string (C# API can understand this format).
   * @param Date javascript date or Javascript ISO string.*
   */
  ConvertDateToISOTime(dt: Date): string {
    if (typeof dt == "string") {
      dt = this.getDate(moment(dt).format("YYYY-MM-DDTHH:mm"));
    }
    if (!dt) return "";
    return (
      (dt.getHours() > 9 ? dt.getHours() : "0" + dt.getHours()) +
      ":" +
      (dt.getMinutes() > 9 ? dt.getMinutes() : "0" + dt.getMinutes())
    );
  }
  /**
   * Adds Date and time from two different date objects.
   * @param Date javascript date or Javascript ISO string - date*
   * @param time javascript date or Javascript ISO string - time*
   */
  AddTimeToDate(date: any, time: any): Date {
    try {
      if (typeof date == "string") date = this.getDate(moment(date).format("YYYY-MM-DDTHH:mm"));
      if (typeof time == "string") time = this.getDate(moment(time).format("YYYY-MM-DDTHH:mm"));
      date =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "-" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate());
      time =
        (time.getHours() > 9 ? time.getHours() : "0" + time.getHours()) +
        ":" +
        (time.getMinutes() > 9 ? time.getMinutes() : "0" + time.getMinutes());
      return this.getDate(date + "T" + time);
    } catch (e) {
      return this.getCurrentDate();
    }
  }
  
  TimeToDateAllformat(value: string): Date {
    if (value) {
      let hourFormat
      const timeformat = value.split(' ');
      if (timeformat[1]) {
        hourFormat = '12';
      } else {
        hourFormat = '24';
      }
      if (hourFormat == '12') {
        return this.getDate(this.ConvertDateToISODate(this.getCurrentDate()) + "T" +  moment(value, 'hh:mm A').format('HH:mm'));
      }else{
        return this.getDate(this.ConvertDateToISODate(this.getCurrentDate()) + "T" + value);
      }

    }
  }

  getDaysModel(AddAllDays: boolean): DaysModel[] {
    let longWeekArr = this.getLongDaysOfWeek();
    let shortWeekArr = this.getShortDaysOfWeek();
    let returnArr: DaysModel[] = [];
    let localizedCalender: Calendar = this.captions.calendar;
    if (AddAllDays) {
      returnArr.push({
        id: 0,
        short: this.captions.common.all,
        long: this.captions.common.all,
        code: "All"
      });
    }
    for (let i = 0; i < longWeekArr.length; i++) {
      const localelongDay = longWeekArr[i];
      const localeShortDay = shortWeekArr[i];
      switch (localelongDay) {
        case localizedCalender.Monday:
          returnArr.push({
            id: 1,
            short: localeShortDay,
            long: localelongDay,
            code: "Mon"
          });
          break;
        case localizedCalender.Tuesday:
          returnArr.push({
            id: 2,
            short: localeShortDay,
            long: localelongDay,
            code: "Tue"
          });
          break;
        case localizedCalender.Wednesday:
          returnArr.push({
            id: 3,
            short: localeShortDay,
            long: localelongDay,
            code: "Wed"
          });
          break;
        case localizedCalender.Thursday:
          returnArr.push({
            id: 4,
            short: localeShortDay,
            long: localelongDay,
            code: "Thu"
          });
          break;
        case localizedCalender.Friday:
          returnArr.push({
            id: 5,
            short: localeShortDay,
            long: localelongDay,
            code: "Fri"
          });
          break;
        case localizedCalender.Saturday:
          returnArr.push({
            id: 6,
            short: localeShortDay,
            long: localelongDay,
            code: "Sat"
          });
          break;
        case localizedCalender.Sunday:
          returnArr.push({
            id: 7,
            short: localeShortDay,
            long: localelongDay,
            code: "Sun"
          });
          break;
        default:
          break;
      }
    }

    return returnArr;
  }
  /**
  * Returns date object with current date and given time.
  * @param string ISO time format (HH:mm or HH:mm A)*
  */
  TimeToDate(value: string): Date {
    return this.getDate(this.ConvertDateToISODate(this.getCurrentDate()) + "T" + value);
  }

  // Generate Time Array starts
  dayTimeArray(strt: Date, end: Date, incby, typ, isTimeRounded = false) {
    //isTimeRounded - Time given is rounded, like 9:00, 9:30.. Not like 8.59
    let adaytime = []; 
    let incrbyhrs;
    if (typ == "hours") {
      incrbyhrs = incby;
    } else {
      incrbyhrs = incby / 60;
    }

    let drstrt = moment(strt);
    let drend = moment(end);
    let timespan = drend.diff(drstrt, "hours") / incrbyhrs;
    if (timespan == 0) timespan = drend.diff(drstrt, "hours", true) / incrbyhrs;
    if (incrbyhrs < 1) {
      if (!isTimeRounded) timespan = timespan + 1 / incrbyhrs - 1;
      else timespan = timespan - 1;
    }
    for (let i = 0; i <= timespan; i++) {
      if (i == 0) {
        adaytime.push(
          this.LocalizeTime(
            this.getDate(
              moment(strt)
                .startOf(typ)
                .format('YYYY-MM-DDTHH:mm:ss')
            )
          )
        ); 
      } else {
        let localizedDate: any;
        strt = moment(strt)
          .startOf(typ)
          .add(incby, typ)
          .toDate();
        localizedDate = moment(strt).format('YYYY-MM-DDTHH:mm:ss'); 
        adaytime.push(this.LocalizeTime(this.getDate(localizedDate)));
      }
    }
    return adaytime;
  }

  /**
   * Generates Time Range between two times, in minutes.
   * @param startTime
   * @param endTime
   * @param increment incremented by time in minutes only.
   */
  public generateTimeRange(startTime: Date, endTime: Date, increment: number): string[] {
    let Type: any = "minutes";
    let rangeArr: string[] = [];
    let mStartTime: moment.MomentInput = moment(startTime);
    let mEndTime: moment.MomentInput = moment(endTime);
    let timeDiff: number = mEndTime.diff(mStartTime, Type, true);

    if (timeDiff != 0 && (timeDiff / increment > 0)) {

      let noOfIncrements = timeDiff / increment;
      let noOfIncrementsRounded = parseInt(noOfIncrements.toString().split(".")[0]);
      let _date: Date;
      for (let i = 0; i <= noOfIncrementsRounded; i++) {
        _date = moment(mStartTime).add((increment * i), Type).toDate();
        rangeArr.push(this.LocalizeTime(_date));
      }
    }
    return rangeArr;
  }

  ToDate(value: string, inputFormat: string): Date {
    return this.getDate(moment(value, inputFormat).format("YYYY-MM-DDTHH:mm"));
  }

  getTimeDifference(fromTime, endTime, type) {
    fromTime = moment(fromTime, this.timeFormat).toDate();
    endTime = moment(endTime, this.timeFormat).toDate();
    var difference = endTime.getTime() - fromTime.getTime(); // This will give difference in milliseconds
    let result = 0;
    if (type == "Min") {
      result = Math.round(difference / 60000);
    }
    return result;
  }


  AddMinutes(date: Date, minutes: number) {
    return this.getDate(date.getTime() + minutes * 60000);
  }

  AddMins(date: Date, minutes: number) {
    return moment(date)
      .add(minutes, "m")
      .toDate(); 
  }
  
  AddHours(date: Date, hours: number) {
    return this.getDate(date.getTime() + hours * 60 * 60* 1000);
  }

  SubMinutes(date: Date, minutes: number) {
    return this.getDate(date.getTime() - minutes * 60000);
  }

  SubMins(date: Date, minutes: number) {
    return moment(date)
      .subtract(minutes, "m")
      .toDate(); 
  }

  AddDays(date: Date, days: number) {
    var tempDate = _.cloneDeep(date);
    tempDate.setDate(date.getDate() + days);
    return tempDate;
  }

  format24HrTime(date: any): string {
    if (typeof date == "string") {
      date = this.getDate(date);
    }

    var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var minutes =
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    return hours + ":" + minutes;
  }

  dateAdd = {
    thisRef: this,
    Minutes: function (date: Date, minutes: number) {
      return this.thisRef.getDate(date.getTime() + minutes * 60000);
    },
    AddMins: function (date: Date, minutes: number) {
      return moment(date)
        .add(minutes, "m")
        .toDate(); 
    },
    AddDays: function (date: Date, days: number) {
      var tempDate = _.cloneDeep(date);
      tempDate.setDate(date.getDate() + days);
      return tempDate;
    },
    AddMonths: function (date: Date, months: number) {
      return moment(date)
        .add(months, "M")
        .toDate();
    },
    AddYears: function (date: Date, years: number) {
      return moment(date)
        .add(years, "y")
        .toDate();
    }
  };
  getTime(dt: Date, format: number) {
    if (format == 12) {
      return this.formatAMPM(dt, false);
    } else {
      return this.format24HrTime(dt);
    }
  }

  replacePlaceholders(caption: string, placeholders: string[], values: string[] | number[]): string {
    caption = caption.replace(/[{}]/g, "");
    for (let i = 0; i < placeholders.length; i++) {
      caption = caption.replace(placeholders[i], values[i].toString());
    }
    return caption;
  }

  convertAMPMTimeStringTo24Time(input: string): string {
    const matches = input.toLowerCase().match(/(\d{1,2}):(\d{2}) ([ap]m)/);
    const hours = this.getHour(matches); 
    return (hours.toString().length === 1 ? ('0' + hours.toString()) : hours.toString()) + ':' + matches[2] + ':00';
  }

  getHour(matches): number {
    if (matches[3] == 'am' && parseInt(matches[1]) === 12) {
      return 0
    }
    else if (matches[3] == 'am' && parseInt(matches[1]) < 12) {
      return parseInt(matches[1])
    }
    else if (matches[3] === 'pm' && parseInt(matches[1]) === 12) {
      return 12
    }
    else {
      return 12 + parseInt(matches[1])
    }
  }


  /** Verified */

  GetFormattedDate(dt: Date) {
    if (typeof dt == "string") dt = this.getDate(dt);
    let month = "" + (dt.getMonth() + 1);
    let day = "" + dt.getDate();
    let year = dt.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("/");
  }

  GetFormattedDateDDMMYY(dt: Date) {
    return this.localizeDisplayDate(dt);
  }

  GetFormattedDateDDMMYYYY(dt: Date) {

    if (typeof dt == "string") dt = this.getDate(dt);
    let month = "" + (dt.getMonth() + 1);
    let day = "" + dt.getDate();
    let year = dt.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  }

  GetFullDate(dt: Date) {
    let date = dt.getDate();
    if (date < 10) {
      return "0" + date;
    } else {
      return date;
    }
  }

  formatAMPM(date: Date, isCapital = false, isHours2Digit = true) {
    if (typeof date == "string") {
      date = this.getDate(date);
    }

    let Am_string = isCapital ? "AM" : "am";
    let Pm_string = isCapital ? "PM" : "pm";
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? Pm_string : Am_string;
    hours = hours % 12;
    hours = hours ? hours : 12;
    let minutestr = minutes < 10 ? "0" + minutes : minutes;
    const tempHours = isHours2Digit ? "0" + hours : hours;
    return   (hours > 9 ? hours : tempHours) +  ":" + minutestr + " " + ampm;
  }
  LocalizeGender(value: string): string {
    switch (value.toLowerCase()) {
      case "male":
      case "m":
        return this.captions.common.Male;
      case "female":
      case "f":
        return this.captions.common.Female;
    }
    return "";
  }

  // Wrapper for new Date("dateString")
  // Has implementation to support all browser engines
  getDate(input: any): Date {
    // Appending timezone to support Safari browser
    const timezoneOffset: string = this.getTimezoneOffset(input);

    if (typeof input === 'string') {

      // Including T in the ISO format if not present
      if (input.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/) || input.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)) {
        input = input.replace(' ', 'T');
      }
      let dateString: string = input;
      // yyyy-MM-ddTHH:mm or yyyy-MM-ddTHH:mm:ss
      if (input.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/) || input.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/)) {
        dateString = `${input}${timezoneOffset}`;
      }

      // yyyy-MM-dd
      else if (input.match(/^\d{4}-\d{2}-\d{2}$/)) {
        dateString = `${input}T00:00${timezoneOffset}`;
      }

      // yyyy-MM-ddTHH:mm [am/pm]
      else if (input.match(/^\d{4}-\d{2}-\d{2}T\d{1,2}:\d{1,2} [ap][m]$/i)) {
        const inputDate = input.split('T')[0];
        const inputTime = this.convertAMPMTimeStringTo24Time(input.split('T')[1]);
        dateString = `${inputDate}T${inputTime}${timezoneOffset}`;
      }

      return new Date(dateString);
    }
    if (typeof input === 'number') {
      // Custom logic for number param goes here
    }
    return new Date(input);
  }

  // Wrapper for new Date()
  getCurrentDate(): Date {
    return new Date();
  }

  // Returns client's timezone offset (eg: +05:30)
  getTimezoneOffset(dateVal?: Date): string {
    var date = dateVal? new Date(dateVal): this.getCurrentDate(), 
      timezoneOffset = date.getTimezoneOffset(),
      hours = ('00' + Math.floor(Math.abs(timezoneOffset / 60))).slice(-2),
      minutes = ('00' + Math.abs(timezoneOffset % 60)).slice(-2),
      string = (timezoneOffset >= 0 ? '-' : '+') + hours + ':' + minutes;
    return string;
  }

  /**
  * Returns formatted date and day as "Wednesday, April 17, 2019"
  * @param Date
  */
  getLocalizedDayMonthDateYear(value: Date): string {
    return `${this.getLocalizedDay(value)}, ${moment(value).format("LL")}`;
  }

  /**
   * Converts a javascript date to localized string of format 11-okt-2019
   * @param Date javascript date.
   */
  localizedDate(value: Date = this.getCurrentDate()): string {
    const separator = '-';
    const localizedMonth: string = this.getLocalizedMonthShort(value);
    return `${value.getDate()}${separator}${localizedMonth}${separator}${value.getFullYear()}`;
  }

  /**
   * Converts a javascript date to localized time string.
   * @param Date javascript date.
   */
  localizedTimeStamp(value: Date = this.getCurrentDate(), isCapital = true): string {
    let formattedDate;
    if(this.time24formatFlag == 24){
      formattedDate = this.format24HrLongTime(value);
    } else if(this.time24formatFlag == 12){
      formattedDate = this.formatAMPM(value);
    } else {
      formattedDate = moment(value).format('LTS');
    }
    return isCapital ? formattedDate : formattedDate.toLowerCase();
  }

  /**
   * Returns 'An unexpected error has occurred, on 11-Dec-2020 at 4:27:30 PM.

      Please contact your administrator.'
  */
  getUnexpectedErrorMessage() {
    let errorStringFormat = '';
    if (this.captions.common.unexpectedError) {
      errorStringFormat = this.captions.common.unexpectedError;
    } else {
      errorStringFormat = this.errorCaptions[-2];
    }
    const msg = this.replacePlaceholders(errorStringFormat, ['date', 'time'], [this.localizedDate(), this.localizedTimeStamp()]);    
    const tryAgain = this.captions.common.tryAgain;
    if (tryAgain) {
      return `${msg}<br><br>${tryAgain}`;
    } else {
      return `${msg}`;
    }
  }

  CompareDate(date1: Date, date2: Date, orEqual: boolean) {
    let result = false;
    if (orEqual) {
      result = new Date(date1) <= new Date(date2);
    }
    else {
      result = new Date(date1) < new Date(date2);
    }
    return result;
  }
  public curlyQuotesfix(value: string) {
    return value.replace(/[\u201C\u201D]/g, '"').replace(/[\u2018\u2019]/g, '\'');
  }

  GetHoursFromTime(value: string): number {

    let apiTime: number = 0;
    let pmConfig: number = 720;
    if (value) {
        const enUsTimeStr = this.getLocalizedTime(value);
        if (value.indexOf("PM") !== -1) {
            apiTime = enUsTimeStr.split(':')
                .map(time => parseInt(time.replace(/\D/g, '')))
                .reduce((hour, min) => (this.Get24Hr(hour,value) * 60) + min + pmConfig);
        }
        else {
            apiTime = enUsTimeStr.split(':')
                .map(time => parseInt(time.replace(/\D/g, '')))
                .reduce((hour, min) => this.Get24Hr(hour,value) * 60 + min);
        }
    }
    return apiTime;

}
getLocalizedTime(time: string): string {
  const timeFormat = this.getTimeFormat();
  
  if (timeFormat == 12) {
    return moment(time, 'hh:mm A').format('hh:mm A');
  }
  {
    return moment(time, 'HH:mm').format('HH:mm');
  }
}

private Get24Hr(hr: number, value: string){
  if(value.indexOf("AM") !== -1 || value.indexOf("PM") !== -1){
    return hr == 12 ? 0 : hr ;
  }
  else{
    return hr;
  }
}
}
