import { Injectable } from '@angular/core';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { GolfScheduleCommunication } from 'src/app/shared/communication/services/golfschedule.service';
import { BulkTeeTimeConfig } from './bulk-tee-time.model';
import { BulkBookTeeTimeResult } from 'src/app/shared/models/teesheet.api.models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Injectable({
  providedIn: 'root'
})
export class BulkTeeTimeService {

  constructor(private utils: GolfUtilities, private _http: GolfScheduleCommunication, private localization: GolfLocalization) { }

  getCaptions() {
    return this.localization.captions.BulkBookTeeTime;
}

showAlert(errorMessage, alertType, buttonType, callback) {
  this.utils.showAlert(errorMessage, alertType, buttonType, callback);
}

  public bulkBookTeeTime(body: BulkTeeTimeConfig): Promise<BulkBookTeeTimeResult> {
    return this._http.postPromise({route: GolfApiRoute.BulkBookTeeTime, body : body });     
  }

  }
