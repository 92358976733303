import { Injectable } from "@angular/core";
import { ReportDownloadFormat, ReportAPIOptions, ReportAPIModel,TemplateReportAPIModel } from "../report.model";
import { saveFile } from "./fastreport.component";
import { GolfLocalization } from "../../core/localization/golf-localization";
import { ReportCommunication } from 'src/app/shared/communication/services/report.service';
import { GolfRoutes } from 'src/app/core/extensions/golf-route';
import { GolfUtilities } from "src/app/shared/utilities/golf-utilities"
import { PrintManagerDataService } from "src/app/common/dataservices/printmanager.data.services";
import { environment } from "src/environments/environment";
import { ReportCode } from 'src/app/reports/common/report.constants';
import { JasperReportBusiness } from "src/app/common/JaspersoftReport/jasperreport.business";
import { PrintDocumentInput } from "src/app/common/Models/common.models";

@Injectable({
    providedIn: 'root'
})
export class FastReportBusinessService {

    constructor(private http: ReportCommunication, private localization: GolfLocalization, private utils: GolfUtilities,private jasperbusiness:JasperReportBusiness, private _printManager: PrintManagerDataService) {
    }
    isLatestPrintManager: boolean = false;
    public async downloadReport(format: ReportDownloadFormat, options: ReportAPIOptions, showPrintDialog: boolean = false, callBack?: any, isDownloadWithPreview?: boolean, receiptPrinter?: PrintDocumentInput): Promise<void> {
        if(options.code==ReportCode.GolfGuestItinerary)
        {
            let downloadURL = environment["Report"] + GolfRoutes.GetTemplateReport;
            this.downloadFile(downloadURL, format, options, showPrintDialog, callBack, isDownloadWithPreview);
        }
        else
        {
            let autoPrint =  await this.validatePrinterConfiguration(receiptPrinter);
           
            let downloadURL = environment["Report"] + GolfRoutes.GetReport;
            format = autoPrint ? (this.isLatestPrintManager ? 'IMAGE' : 'HTML') : format;
            receiptPrinter ? (this.isLatestPrintManager ? receiptPrinter.printFormat = "FRXIMAGE" : receiptPrinter.printFormat = "IMAGE") : undefined;
           
            this.downloadFile(downloadURL, format, options, showPrintDialog, callBack, isDownloadWithPreview,autoPrint,receiptPrinter);
        }
    }
    private async validatePrinterConfiguration(receiptPrinter?: PrintDocumentInput): Promise<boolean>
    {
        this.isLatestPrintManager = false;
        let printerManagerURI = this.localization.GetPrinterManagerURI();
        let autoPrint=true;
        if (!printerManagerURI || printerManagerURI == '' || !receiptPrinter || !receiptPrinter.printerName || receiptPrinter.printerName == '') {
            autoPrint=false;
        }
        if(autoPrint)
        {
            this.utils.ToggleLoader(true);
            try
            {
                try
                {
                    let isImageSupported = await this._printManager.IsImageSupportAvailable(printerManagerURI);
                    if(isImageSupported != undefined && isImageSupported)
                        this.isLatestPrintManager = true;
                }
                catch
                {}
                finally
                {
                    if(!this.isLatestPrintManager)
                    {
                        await this._printManager.PrinterManagerHeartBeat(printerManagerURI);
                    }
                    this.utils.ToggleLoader(false);
                    autoPrint = true;
                }
            }
            catch(e)
            {
                autoPrint = false;
                this.utils.ToggleLoader(false);
                this.utils.showError(this.localization.captions.common.NoPrinterFound);
            }

        }
        return autoPrint;
    }
    public printJasperReport(activeReportAPIModel,JasperReportDetails, callBack?: any)
    {
        this.jasperbusiness.sendJasperReportRequest(activeReportAPIModel,JasperReportDetails, environment["Report"]).then(res=>{
            this.utils.ToggleLoader(false);
            this.showFile(res);
            if (callBack) {
                callBack();
            }
        })
    }
    private downloadFile(url: string, type: ReportDownloadFormat, options: ReportAPIOptions, showPrintDialog: boolean, callBack?: any, isDownloadWithPreview?: boolean,autoPrint?:boolean, receiptPrinter?: PrintDocumentInput): void {
        let fileName: string;
        if (type == "PDF") {
            fileName = `${options.code}.pdf`
        }
        else if (type == "WORD") {
            fileName = `${options.code}.docx`
        } else if (type == "EXCEL"|| type == "RAWDATA") {
            fileName = `${options.code}.xlsx`
        } else if (type == "IMAGE") {
            fileName = `${options.code}.jpeg`
        }
        options.format = type;
        let apiModel: any;

        if(options.code == ReportCode.GolfGuestItinerary)
        {
            apiModel = this.createTemplateAPIOptions(options);
        }
        else
        {        
            apiModel= this.createAPIOptions(options);
        }
        this.http.putHTTPBlobData({
            route:  options.code==ReportCode.GolfGuestItinerary? environment["Report"] + '/' + GolfRoutes.GetTemplateReport: environment["Report"] + '/' + GolfRoutes.GetReport ,
            body: apiModel
        }, 'blob').subscribe(res => {
            if (showPrintDialog || isDownloadWithPreview) {
                this.showOrPrintFile(res,autoPrint, receiptPrinter, options.code);
                this.utils.ToggleLoader(false);
                if (callBack) {
                    callBack();
                }
            }
             if (!showPrintDialog || isDownloadWithPreview) {
                saveFile(res, fileName);
            }
        });
    }  
    
    private async showOrPrintFile(blob,autoPrint?:boolean, receiptPrinter?: PrintDocumentInput, fileName?: string) {
        let printerManagerURI = this.localization.GetPrinterManagerURI();
            if (!autoPrint) {
            this.showFile(blob);
            }
        else {
            let byte: any;
            byte = await this.utils.getByteData(blob);
            receiptPrinter.reportData = byte;
            receiptPrinter.documentName = fileName ? fileName : "";;
            this._printManager.Print(printerManagerURI, receiptPrinter);
            this.utils.ToggleLoader(false);
        }
    }

    private showFile(blob) {
        var newBlob = new Blob([blob], { type: "application/pdf" })
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
          (window.navigator as any).msSaveOrOpenBlob(newBlob);
          return;
        }
        const data = window.URL.createObjectURL(newBlob);
        let newTab: Window = window.open(data);
        newTab.focus();
        newTab.print();
        document.getElementById('custom-cover-spin').style.display = 'none'
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      }


    private createAPIOptions(options: ReportAPIOptions): ReportAPIModel {

        return {
            code: options.code,
            format: options.format ? options.format : "HTML",
            downloadFileName: "Download",
            parameters: this.arrayToObject(options.params),
            uRIParams: this.arrayToObject(options.URIParams),
            filterBody: options.filters,
            dateFormat: this.localization.dateFormat
        }        
    }

    private createTemplateAPIOptions(options: ReportAPIOptions): TemplateReportAPIModel {

        return {
            code: options.code,
            format: "PDF",
            downloadFileName: "Download",
            parameters: this.arrayToObject(options.params),
            uRIParams: this.arrayToObject(options.URIParams),
            templateParams:this.arrayToTemplateParms(options.templateParams),
            filterBody: options.filters,
            dateFormat: this.localization.dateFormat
        };
    }

    private arrayToObject(objectArr: any[]): { [key: string]: string } {
        var result = {};
        if (objectArr && objectArr.length > 0) {
            objectArr.forEach(o => {
                result[Object.keys(o)[0]] = Object.values(o)[0];
            });
        }
        return result;   //Dictionary<string,string>
    }

    private arrayToTemplateParms(objectArr: any[]): { [key: string]: Uint8Array } {
        var result = {};
        objectArr.forEach(o => {
            result[Object.keys(o)[0]] = Object.values(o)[0];
        });
        return result;   //Dictionary<string,Uint8Array>
    }


}
