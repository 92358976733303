import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { AgContactFieldConfig } from '../../shared-models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { MailTypes, ContactType } from '../../global.constant';

@Component({
  selector: 'app-ag-golfmail-id',
  templateUrl: './ag-mail-id.component.html',
  styleUrls: ['./ag-mail-id.component.scss']
})
export class AgGolfMailIdComponent implements OnInit {
  customClass: string;
  errorMessage: string;
  contactForm: UntypedFormGroup;
  controlName: string;
  placeHolder: string;
  emailPlaceHolder: string;
  value: string | number;
  options: { id: number, description: string, type: string }[];
  optionClass: string;
  typeControlName: string;
  arrayName: string;
  showSwitches: boolean;
  mailTypes = MailTypes;
  captions: any;
  primarySwitchName: string;
  privateSwitchName: string;
  duplicateError: string;
  maxLength = 75;
  isEmailRequired: boolean = false;
  requiredErrorMessage: string;
  isEmpty: boolean;
  floatLabel: string;
  isEmailDisabled: boolean;
  defaultMailTypeValue: string | number;
  automationId: string = "";

  @Input('inputs')
  set inputOptions(value: AgContactFieldConfig) {
    this.errorMessage = value.errorMessage ? value.errorMessage : this.captions.InvalidEmailFormat;
    this.placeHolder = value.placeHolder ? value.placeHolder : this.captions.email;
    this.controlName = value.formControlName ? value.formControlName : 'emailId';
    this.typeControlName = value.typeControlName ? value.typeControlName : 'emailType';
    this.customClass = value.className ? value.className : 'golf-form-control--md';
    this.optionClass = value.typeClass ? value.typeClass : 'golf-form-control--xs';
    this.showSwitches = value.showSwitches;
    this.emailPlaceHolder = value.emailPlaceHolder ? value.emailPlaceHolder : '';
    this.isEmailRequired = value.isEmailRequired ? value.isEmailRequired : false;
    this.requiredErrorMessage = value.requiredErrorMessage ? value.requiredErrorMessage : '';
    this.isEmailDisabled = value.disabled ? value.disabled : false;
    this.automationId = value.automationId ? value.automationId : '';
    if (this.showSwitches) {
      this.primarySwitchName = value.isPrimaryName ? value.isPrimaryName : 'primaryEmail';
      this.privateSwitchName = value.isPrivateName ? value.isPrivateName : 'privateInformtionEmail';
    }
    this.contactForm = value.form;
    this.arrayName = value.formArrayName;

    if (!this.arrayName) {

      var emailTypeValue = this.contactForm.get(this.typeControlName).value
      emailTypeValue ? this.contactForm.get(this.controlName).enable() : this.contactForm.get(this.controlName).disable();
    } else {
      const email = this.contactForm.get(this.arrayName) as UntypedFormArray;
      email.controls.forEach((obj) => {
        if (!obj.value[this.typeControlName] && obj.get(this.controlName)) {
          obj.get(this.controlName).disable();
        }
      });
    }
    const uiDefaultScreenValue = JSON.parse(sessionStorage.getItem('UiDefaultsSettingsValue'));
    let defaultMailType = uiDefaultScreenValue?.mailType ? uiDefaultScreenValue?.mailType : '';
    this.setDefaultMailType(this.getDefaultMailType(defaultMailType));
  }

  @Input('valuePatched')
  set ControlState(value: boolean) {
    if (!this.arrayName && !this.isViewOnly && this.contactForm.get(this.controlName).value) {
      this.contactForm.get(this.controlName).enable();
    } else {
      var emailTypeValue = this.contactForm.get(this.typeControlName).value
      emailTypeValue ? this.contactForm.get(this.controlName).enable() : this.contactForm.get(this.controlName).disable();
    }
  }

  @Input('values')
  set SetFormArray(value) {
    if (value && this.arrayName) {
      this.patchOrAdd(value);
      this.mailFieldDisabler(this.isEmailDisabled);
      this.ChangePrimaryToggle();
    }
  }

  @Input('isEmtyreq')
  set emptyFunc(value) {
    this.isEmpty = value ? value : true;
  }


  @Input() isViewOnly: boolean = false;
  @Input() allowMultiple: boolean = true;

  @Input() validateMail: boolean = false;

  constructor(private fb: UntypedFormBuilder, private localization: GolfLocalization) {
    this.floatLabel = this.localization.setFloatLabel;
    this.captions = this.localization.captions.common;
    this.duplicateError = this.captions.duplicateEmail;
    this.options = this.getMailOptions();
  }

  ngOnInit() {
    this.isEmpty = true;
  }
  getDefaultMailType(defaultMailType) {
    switch (defaultMailType) {
      case this.mailTypes.office:
        this.defaultMailTypeValue = this.options.find(x => x.id === this.mailTypes.office) ? this.options.find(x => x.id === this.mailTypes.office).id : '';
        break;
      case this.mailTypes.personal:
        this.defaultMailTypeValue = this.options.find(x => x.id === this.mailTypes.personal) ? this.options.find(x => x.id === this.mailTypes.personal).id : '';
        break;
      case this.mailTypes.home:
        this.defaultMailTypeValue = this.options.find(x => x.id === this.mailTypes.home) ? this.options.find(x => x.id === this.mailTypes.home).id : '';
        break;
      case this.mailTypes.business:
        this.defaultMailTypeValue = this.options.find(x => x.id === this.mailTypes.business) ? this.options.find(x => x.id === this.mailTypes.business).id : '';
        break;
    }
    return this.defaultMailTypeValue;
  }
  setDefaultMailType(defaultMailType) {
    if (this.arrayName) {
      this.contactForm.get(this.arrayName)['controls'].forEach((element, index) => {
        if (element.get(this.typeControlName).value === "" && defaultMailType) {
          element.get(this.typeControlName).setValue(defaultMailType);
          element.get(this.controlName).enable();
        }
      })
    } else {
      if (this.contactForm.get(this.typeControlName).value === "") {
        this.contactForm.get(this.typeControlName).setValue(defaultMailType);
        this.contactForm.get(this.controlName).enable();
      }
    }
  }
  // Dynamic Email field addition Logic
  addEmailArray(index, emailLabel, emailid, isprimary?: boolean, isPrivate?: boolean, platformContactUuid?: string): UntypedFormGroup {
    const currentForm = this.fb.group({
      contactType: ContactType.email,
      id: index
    });
    currentForm.addControl(this.typeControlName, new UntypedFormControl(emailLabel));
    currentForm.addControl(this.controlName, new UntypedFormControl({ value: emailid, disabled: !emailLabel }));
    if (this.showSwitches) {
      currentForm.addControl(this.primarySwitchName, new UntypedFormControl(isprimary));
      currentForm.addControl(this.privateSwitchName, new UntypedFormControl(isPrivate));
    }
    currentForm.addControl('platformContactUuid', new UntypedFormControl(platformContactUuid));
    return currentForm;
  }
  addEmailItem(index, emailLabel: any, emailid: any, primaryEmail, privateInformtionEmail, platformContactUuid): void {
    if (!this.isViewOnly) {
      const email = this.contactForm.get(this.arrayName) as UntypedFormArray;
      email.push(this.addEmailArray(index, emailLabel, emailid, primaryEmail, privateInformtionEmail, platformContactUuid));
      this.ChangePrimaryToggle();
    }
  }

  removeEmailItem(index: number): void {
    if (!this.isViewOnly) {
      const email = this.contactForm.get(this.arrayName) as UntypedFormArray;
      this.contactForm.markAsDirty();
      email.removeAt(index);
      this.checkIsDuplicate();
      this.ChangePrimaryToggle()
    }
  }

  checkIsDuplicate() {
    const emailArray = this.contactForm.get(this.arrayName) as UntypedFormArray;
    emailArray.value.forEach((email, idx) => {
      if (this.isDuplicate(email, idx)) {
        emailArray.controls[idx].get('emailId').setErrors({ duplicate: { value: emailArray.controls[idx].get('emailId').value } });
      } else {
        emailArray.controls[idx].get('emailId').setErrors(null);
      }
    });
  }

  isDuplicate(currentemail, idx) {
    const email = this.contactForm.get(this.arrayName) as UntypedFormArray;
    const emailMathces = email.value.filter(x => x.emailId === currentemail.emailId)
    const isDuplicate = emailMathces.length > 1 ? true : false;
    return isDuplicate;
  }

  togglePrimaryContact(formGroupName: number) {
    const arr = this.contactForm.get(this.arrayName) as UntypedFormArray;
    const selectedctrl = arr.controls.filter((x, idx) => idx == formGroupName)?.[0];
    if (selectedctrl) {
      (selectedctrl as UntypedFormGroup).controls[this.primarySwitchName].setValue(true);
      (selectedctrl as UntypedFormGroup).controls[this.primarySwitchName].disable();
    }
    const ctrls = arr.controls.filter((x, idx) => idx !== formGroupName);
    ctrls.forEach(x => {
      const grp = x as UntypedFormGroup;
      grp.controls[this.primarySwitchName].setValue(false);
      grp.controls[this.primarySwitchName].enable();
    });
  }

  typeChange(arg, formGroup: UntypedFormGroup) {
    formGroup.get(this.controlName).enable();

    //forclearingValues
    if (!arg.value) {
      formGroup.get(this.controlName).reset();
      formGroup.get(this.controlName).disable();
    }

  }

  private patchOrAdd(value) {
    const mail = this.contactForm.get(this.arrayName) as UntypedFormArray;
    value.forEach((obj, idx) => {
      const group = mail.controls[idx] as UntypedFormGroup;
      if (group) {
        if (!group.get('id')) {
          group.addControl('id', new UntypedFormControl());
        }
        if (!group.get('contactType')) {
          group.addControl('contactType', new UntypedFormControl(ContactType.email));
        }
        if (obj && obj[this.typeControlName] && !this.isViewOnly) {
          group.get(this.controlName).enable();
        }
        group.patchValue(obj);
        group.markAsPristine();
        group.updateValueAndValidity();
      } else {
        const id = obj ? obj.id : '';
        const emailType = obj ? obj[this.typeControlName] : '';
        const emailId = obj ? obj[this.controlName] : '';
        const primaryEmail = obj ? obj.primaryEmail : '';
        const privateInformtionEmail = obj ? obj.privateInformtionEmail : '';
        const platformContactUuid = obj ? obj.platformContactUuid : '';
        this.addEmailItem(id, emailType, emailId, primaryEmail, privateInformtionEmail, platformContactUuid);
      }
      if (this.validateMail && obj[this.controlName] != '' && !this.validateEmail(obj[this.controlName])) {
        group.controls[this.controlName].setErrors({ incorrect: true });
      }
    });
  }
  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  ChangePrimaryToggle() {
    let contactArray = this.contactForm.get(this.arrayName) as UntypedFormArray;
    if (contactArray?.value?.length > 0) {
      let isPrimaryAvailable = false;
      let index: number = 0;
      let firstPhoneisPrimary: boolean = false;
      contactArray.controls.forEach(x => {
        index++;
        const grp = x as UntypedFormGroup;
        if (grp.controls[this.primarySwitchName].value) {
          isPrimaryAvailable = true;
          firstPhoneisPrimary = index == 1;
          grp.controls[this.primarySwitchName].disable();
        }
      });
      const ctrl = contactArray.controls[0] as UntypedFormGroup;
      if (!isPrimaryAvailable) {        
        ctrl.controls[this.primarySwitchName].setValue(true);
        ctrl.controls[this.primarySwitchName].disable();
      }
      else {
        if(!firstPhoneisPrimary)
        {
          ctrl.controls[this.primarySwitchName].setValue(false);
          ctrl.controls[this.primarySwitchName].enable();
        }
      }
    }
  }

  private getMailOptions() {
    return [
      { id: this.mailTypes.office, description: this.localization.captions.teetime.office, type: ContactType.email },
      { id: this.mailTypes.personal, description: this.localization.captions.teetime.personal, type: ContactType.email },
      { id: this.mailTypes.home, description: this.localization.captions.teetime.home, type: ContactType.email },
      { id: this.mailTypes.business, description: this.localization.captions.teetime.business, type: ContactType.email }
    ];
  }
  mailFieldDisabler(isDisable) {
    let mailField = this.contactForm.get(this.arrayName);
    if (isDisable) {
      this.isEmailDisabled = true;
      mailField['controls'].forEach(element => {
        element.disable();
      });
    }
  }

}
