
import { UserModel} from '../../models/tenant.models';
import { Injectable } from '@angular/core';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { GolfUtilities } from '../../utilities/golf-utilities';
import { UserOutletAccessDataService } from '../retailmanagement/useroutletaccess.data.service';
import { StoreTerminal } from 'src/app/retail/shared/business/shared.modals';
import { RetailmanagementCommunication } from '../../communication/services/retailmanagement.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { TenantManagementCommunication } from 'src/app/common/communication/services/tenantmanagement-communication-service';


@Injectable({
    providedIn: 'root'
})
export class UserDataService {

    constructor(private _tenantManagement: TenantManagementCommunication
        , private _userOutletsService: UserOutletAccessDataService
        , private _retailMgtService: RetailmanagementCommunication
        , private _utils: GolfUtilities
        , private _propertyInformation: GolfPropertyInformation
        , private _localization: GolfLocalization) {

    }


    async getUsers(isActive = true, isIncludeServiceUsers = true): Promise<UserModel[]> {
        let tenantId: number = Number(this._localization.GetPropertyInfo("TenantId"));
        tenantId = tenantId ? tenantId : 1;
        var users: UserModel[] = await this._tenantManagement.getPromise({
            route: GolfApiRoute.GetAllUsers,
            uriParams: { tenantId: tenantId, inActive: isActive, includeServiceUsers: isIncludeServiceUsers }
        });
        users = await this.FillUserOutletsAccess(users);
        return users;
    }

    async getADB2CEnableConfig(): Promise<any> {
        let tenantId: number = Number(this._localization.GetPropertyInfo("TenantId"));
        tenantId = tenantId ? tenantId : 1;
        var adB2CEnableConfig: any = await this._tenantManagement.getPromise({
            route: GolfApiRoute.GetADB2CEnableConfig,
            uriParams: { tenantId: tenantId }
        });
        return adB2CEnableConfig;
    }

    async createUser(user: UserModel): Promise<number> {
        let propertyId: number = Number(this._localization.GetPropertyInfo("PropertyId"));
        propertyId = propertyId ? propertyId : 1;
        return await this._tenantManagement.postPromise({
            route: GolfApiRoute.CreateUser,
            uriParams: { PropertyId: propertyId },
            body: user
        });        
    }

    async updateUser(user: UserModel): Promise<number> {
        let propertyId: number = Number(this._localization.GetPropertyInfo("PropertyId"));
        propertyId = propertyId ? propertyId : 1;
        return await this._tenantManagement.putPromise({
            route: GolfApiRoute.UpdateUser,
            uriParams: { PropertyId: propertyId },
            body: user
        });       
    }

    async findDuplicateUserName(userId: number, tenantId: number, userName: string): Promise<UserModel> {
        return await this._tenantManagement.getPromise({
            route: GolfApiRoute.GetDuplicateUserByName,
            uriParams: { userId: userId, tenantId: tenantId, userName: userName }
        });        
    }

    async findDuplicateQuickId(userId: number, tenantId: number, quickId: string): Promise<UserModel> {
        return await this._tenantManagement.getPromise({
            route: GolfApiRoute.GetDuplicateUserByQuickId,
            uriParams: { userId: userId, tenantId: tenantId, quickId: quickId }
        });      
    }

    async blockUserProfile(userId: number, accountBlocked: boolean): Promise<UserModel> {
        return await this._tenantManagement.getPromise({
            route: GolfApiRoute.BlockUserProfile,
            uriParams: { userId: userId, accountBlocked: accountBlocked }
        });       
    }
    private async FillUserOutletsAccess(users: UserModel[])
    {
        var userOutlets = await this._userOutletsService.GetOutletsAccessByPropertyId();
        users.forEach(u => {
            u.userPropertyAccesses.forEach(p => {
                if(p.userSubPropertyAccess == null || p.userSubPropertyAccess == undefined) p.userSubPropertyAccess =[];
                p.userSubPropertyAccess= userOutlets.filter(uo => uo.userID == u.userId);
            });
        });
        return users;
    }
    async GetStoreTerminal(outletId: string): Promise<StoreTerminal[]> {
        return await this._retailMgtService.getPromise({
            route: GolfApiRoute.GetStoreTerminal,
            uriParams: { outletId: outletId}
        });       
    }

    async GetEncryptionKey() : Promise<any>{  

        return await this._tenantManagement.getPromise({
            route: GolfApiRoute.GetEncryptKey           
        });  
      }

}