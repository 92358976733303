import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';



@Component({
  selector: 'golfcustom-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class GolfCheckboxComponent   {

 
 @Input() customCBxDisabled:boolean=false;
 @Input() customCBxChecked:string;
 @Input() customCBxId:string='';
 @Input() customCBxwrapperClass:string='';
 @Input() customCBxlabelClass:string='';
 @Input() customCBxInputClass:string='';
 @Input() customCBxlabelText:string=''; 
 @Output() changeEvent: EventEmitter<any> = new EventEmitter();
 automationId:string="";
 @Input('automationId')
 set setAutomationId(value: string) {
   this.automationId = value ? value : '';
 }
 customCheckBox:boolean=true;
checkedChanged:boolean=false;

 
  customCheckboxChange(event){
    this.checkedChanged = true;
    this.changeEvent.emit(event);
  }

}
