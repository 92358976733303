<section id ="overlappingPlayers">
<app-dialog-header [title]="title" (closeDialog)="close()"></app-dialog-header>
<div class="overlapping-player-wrapper pl-3 pt-3 pr-3 overlay-div" >
    <app-cdkvirtual class="app-cdkvirtual" [headerOptions]="tableHeaders" [tableContent]="tableData">
    </app-cdkvirtual>
</div>
<div class="w-100 justify-content-between mb-3">
    <div class="text-center LW16 w-100">{{proceedMsg}}</div>
</div>
<app-dialog-footer [buttonObj]="buttonObj" (cancel)="close()" (save)="save($event)"></app-dialog-footer>            
</section>