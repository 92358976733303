import { Injectable, EventEmitter } from '@angular/core';
import { ACESPaymentRecord, ACESPayment, AlertType, Tournament, SearchType, BaseResponse, ACESTenderInfo, ACESCheckInfo, ACESItemInfo, ACESPaymentInfo, RateType, MemberItemInfo, MemberCheckInfo, MemberTenderInfo, MemberPaymentInfo, ACESMemberPayment, ACESFinancialbin, PostingInfo, ACESOutletInfo, ContactDetails, MailTypes } from 'src/app/retail/shared/shared.modal';
import { PayeeInfo, PaymentMethods, BalanceList, GolfPoints, GuaranteeTypes } from 'src/app/retail/shared/business/shared.modals';
import { RetailUtilities } from 'src/app/retail/shared/utilities/retail-utilities';
import { ButtonType, DefaultBucketName, Host, Product } from 'src/app/retail/shared/globalsContant';
import { HttpServiceCall, HttpMethod } from '../service/http-call.service';
import * as GlobalConst from '../../shared/globalsContant';
import * as _ from 'lodash';
import { RetailDataAwaiters } from '../events/awaiters/retail.data.awaiters';
import { DialogOverviewExampleDialog } from '../dialog-popup/dialogPopup-componenet';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { ShopBussinessService } from '../../shop/shop-business.service';
import { PaymentHistory, PaymentMethod, POSFinancialBin, TransactionInfoforCheckZoom } from '../service/payment/payment-business.model';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { PaymentComponent } from '../../payment/payment.component';
import { CommonVariablesService, SelectedProducts, Ticket } from '../service/common-variables.service';
import { MatDialog } from '@angular/material/dialog';
import { FinancialBinType } from './FinancialBin-business';
import { PaymentTransactionState } from '../../payment/PaymentFlowHelpers';
import { TransactionInput } from './AR-Posting.business.service';
import { RetailRoutes } from '../../retail-route';
import { MemberSelectionPopupComponent } from '../../payment/member-selection-popup/member-selection-popup.component';
import { PlayerCategory } from 'src/app/common/enums/shared-enums';
import { PaymentBusinessLogicHandler } from '../../payment/PaymentBusinessLogicHandler';
import { ConfigKeys, FeatureName } from '../service/retail.feature.flag.information.service';
import { PropertyFeature } from '../business/property-features.model';
import { FolioBusiness } from '../../Folio/folio-business';
import { MiscConfigurationSwitches, SettingModule, SettingScreen } from '../../Folio/Model/folioDetails-model';
import { RetailItemType } from '../../retail.modals';

@Injectable()
export class MemberBusinessService {
	constructor(
		private _utils: RetailUtilities
		, private localization: RetailLocalization
		, private httpserviceCall: HttpServiceCall
		, private _propInfoService: RetailPropertyInformation
		, public _ss: CommonVariablesService
		, public dialog: MatDialog
		, private _folioBusiness: FolioBusiness
	) { }
	RateTypes: RateType[] = [];
	ShopCaptions: any = this.localization.captions.shop;
	memberCardNumber = "0";
	memberAPIProgressNotifier = new EventEmitter<boolean>();
	callbackForMultipleMember?: (any) => void;
	public get enforceMemberPayment(): boolean {
		return (this._propInfoService.getMemberConfig && this._propInfoService.getMemberConfig.enforceMemberPayment || "").toUpperCase() == "TRUE";
	}

	public get redeemRoundsForGuests(): boolean {
		return (this._propInfoService.getMemberConfig && this._propInfoService.getMemberConfig.redeemRoundsForGuests || "").toUpperCase() == "TRUE";
	}

	get AllowZeroTenderForMember() {
		return (this._propInfoService.getMemberConfig && this._propInfoService.getMemberConfig.allowTenderAmountOverrideForMember || "")?.toUpperCase() == "TRUE";
	}

	get MemberBucketName() {
		return (this._propInfoService.getMemberConfig && this._propInfoService?.getMemberConfig?.memberBucketName || DefaultBucketName.memberBucketName)?.replace(/\s/g, '')?.toLowerCase();
	}

	get CreditBookBucketName() {
		return (this._propInfoService.getMemberConfig && this._propInfoService?.getMemberConfig?.creditBookBucketName || DefaultBucketName.creditBookBucketName)?.replace(/\s/g, '')?.toLowerCase();
	}

	get RequirePin() {
		return (this._propInfoService.getMemberConfig && this._propInfoService?.getMemberConfig?.requirePin?.trim()?.toLowerCase() === 'true');
	}

	async GetRateType() {
		return this.RateTypes = this.RateTypes.length <= 0 ? await RetailDataAwaiters.GetAllRateTypes() : this.RateTypes;
	}

	get AllowMultiMemberInTransaction() {
		return (this._propInfoService.getMemberConfig && this._propInfoService?.getMemberConfig?.enableMultipleMember?.trim()?.toLowerCase() === 'true');
	}

	get IsShowCurrentRoundsOnReprint() {
		return (this._propInfoService.getMemberConfig && this._propInfoService?.getMemberConfig?.showCurrentRoundsOnReprint?.trim()?.toLowerCase() === 'true');
	}

	get ACES_MembershipFeatureConfig() {
		let featureConfig: PropertyFeature;
		try {
		  let propertyFeatureStr: any = sessionStorage.getItem("PropertyFeatureConfigurations");
		  if (propertyFeatureStr) {
			let propertyFeatures: PropertyFeature[] = JSON.parse(propertyFeatureStr);
			featureConfig = propertyFeatures.find(p => p.featureName == FeatureName.ACES_Membership);
		  }
		}
		catch (err) {
		  console.log("Error Occurred while getting ACES_Membership Feature Config value");
		}
		return featureConfig;
	  }

	get IsMembershipEnabled(){
		return this.ACES_MembershipFeatureConfig?.isActive
	}

	get IsTeeTimePayment() {
		return ((this._ss.isFromEditTeeTime || this._ss.isFromUnpaidPlayer) && this._ss.selectedProducts.some(p => p.payeeId))
	}

	get DefaultLoyaltySourceCorpID() {
		return (this._propInfoService.getMemberConfig && Number(this._propInfoService?.getMemberConfig?.loyaltySourceCorpID?.trim()));
	}

	CalculateRedeemAmount(GridItems) {
		const roundedproducts = this._ss.selectedProducts.filter(
			(x) => x.payeeId != undefined && x.playerName != undefined
		);
		let redeemAmount = 0;
		if (GridItems) {
			for (var element of GridItems) {
				if (roundedproducts.find(x => x.LineNumber == element.lineNumber) && !element.element.isReturn) {
					const itemQuantity: number = element.quantity == '' ? 0 : Number(element.quantity);
					const itemPrice = parseFloat(element.unitprice) * itemQuantity;
					let itemAmount = itemPrice;
					if (itemPrice > 0) {
						itemAmount =
							itemAmount -
							((element.element.Discount || 0) > itemPrice ? itemPrice : element.element.Discount);
					}
					redeemAmount += itemAmount;
				}
			}
		}
		return redeemAmount;
	}

	CalculateRedeemAmountForEnforceMember(GridItems, isTaxExempted: boolean, productId: number) {

		let roundedproducts;
		if (productId != Product.SPA) {
			roundedproducts = this._ss.selectedProducts;
		} else {
			roundedproducts = GridItems;
		}

		let enforceMemberRedeemAmount = 0;
		if (roundedproducts && GridItems) {
			for (var element of GridItems) {
				if (roundedproducts.find(x => (x.lineNumber == element.lineNumber || x.LineNumber == element.lineNumber)) && !element.element.isReturn) {
					const itemQuantity: number = element.quantity == '' ? 0 : Number(element.quantity);
					let serviceCharge = 0;
					element.element.ServiceCharge && element.element.ServiceCharge.length && element.element.ServiceCharge.forEach(x => {
						serviceCharge += x.Amount;
					});
					let gratuity = 0;
					element.element.Gratuity && element.element.Gratuity.length && element.element.Gratuity.forEach(x => {
						gratuity += x.Amount;
					});
					const taxes = this._ss.Ticket && this._ss.Ticket.lineItems.find(x => x.index == element.lineNumber);
					let taxAmount = 0;
					let serviceChargeTax = 0;
                    let gratuityTax = 0;
					if (taxes && !isTaxExempted) {
						taxes.taxDetails && taxes.taxDetails.forEach(x => taxAmount += x.value);
						serviceChargeTax = taxes.serviceChargeTax;
                        gratuityTax = taxes.gratuityTax;
					}
					const itemPrice = (parseFloat(element.unitprice) * itemQuantity) + taxAmount + serviceCharge + gratuity + serviceChargeTax + gratuityTax;
					let itemAmount = itemPrice;
					if (itemPrice > 0) {
						itemAmount =
							itemAmount -
							((element.element.Discount || 0) > itemPrice ? itemPrice : element.element.Discount);
					}
					enforceMemberRedeemAmount += itemAmount;
				}
			}
		}
		return enforceMemberRedeemAmount;
	}


	public async getMemberInfo(cardNo: string, scheduleDateTime: string, selectedchip: boolean = false, validateStatus = true, isResortFinancePaymentFlow : boolean = false, corpId : number = 0): Promise<any> {
		const memberInfo: BaseResponse<Tournament.UI.ContactInformation> = await this.httpserviceCall.CallApiAsync({
			callDesc: isResortFinancePaymentFlow ? RetailRoutes.FolioGetMemberInfoByCardNo : RetailRoutes.GetMemberInfoByCardNo,
			host: Host.payment,
			method: HttpMethod.Get,
			uriParams: { cardNo: cardNo, scheduleDateTime: scheduleDateTime, isResortFinancePaymentFlow : isResortFinancePaymentFlow, corpId : corpId}
		});
		if (validateStatus && memberInfo && memberInfo.result && (memberInfo.result.membershipStatus && memberInfo.result.membershipStatus.toUpperCase() != "ACTIVE" || (memberInfo.result?.cardStatus && memberInfo.result.cardStatus.toUpperCase() != "ACTIVE"))) {
			this._utils.showAlert(this.localization.captions.common.InActiveMember, AlertType.Info, ButtonType.Ok);
			return null;
		}
		else if (selectedchip) {
			return memberInfo.result;
		}
		else
			return this.MapToPayeeInfo(memberInfo.result);
	}


	EnableDisableARPostandRedeemRoundPaymentMethod(paymentComponentScope: PaymentComponent) {
		const arPosts = paymentComponentScope.copiedPaymentMethods.filter((x) => x.paymentTypeId == PaymentMethods.ARPost);
		const creditBookmethods = paymentComponentScope.copiedPaymentMethods.filter((x) => x.paymentTypeId == PaymentMethods.CreditBook);
		const selectedPlayerRateTypeId: Array<number> = (paymentComponentScope._ss.SelectedPlayers || []).filter(x => (x || ({} as any)).rateTypeId > 0).map(x => x.rateTypeId);
		const settledAmountThroughAces = this.getSettlementAmountThroughAces(paymentComponentScope.SettlementHistory);		
		
		const removeMethod = (method: PaymentMethods) => {
			_.remove(paymentComponentScope.paymentMethods, x => this._utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == method);
			_.remove(paymentComponentScope.paymentMethodsClone, x => this._utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == method);				
		}

		if (paymentComponentScope._ss.isMemberNotActive && !paymentComponentScope.isComponentAccessLinkingFilterApplied) {
			paymentComponentScope.paymentMethodsClone = _.cloneDeep(paymentComponentScope.copiedPaymentMethods);
			paymentComponentScope.paymentMethods = _.cloneDeep(paymentComponentScope.copiedPaymentMethods);
		}

		if (paymentComponentScope._ss.memberCardNumber != "0" && paymentComponentScope._ss.isMemberNotActive == false) {
			const productID = this._ss.ProductId;
			const enforceMemberRedeemAmount = this.CalculateRedeemAmountForEnforceMember(paymentComponentScope.GridItems, paymentComponentScope.isTaxExempted, productID);
			paymentComponentScope.enforceMemberRedeemAmount = enforceMemberRedeemAmount;
			if ((selectedPlayerRateTypeId.length > 0 || productID == Product.SPA) && this.enforceMemberPayment) {
				this.EnforceMemberPayment(selectedPlayerRateTypeId
					, enforceMemberRedeemAmount
					, paymentComponentScope, productID);
			}

			const AddPaymentMethod = (type: PaymentMethods, method: PaymentMethod[]) => {
				if (!paymentComponentScope.paymentMethodsClone.some((r) => r.paymentTypeId == type) && method) {
					paymentComponentScope.paymentMethodsClone.push(...method);
				}
				if (!paymentComponentScope.paymentMethods.some((r) => r.paymentTypeId == type) && method) {
					paymentComponentScope.paymentMethods.push(...method);
				}
			}
			AddPaymentMethod(PaymentMethods.ARPost, arPosts);
			AddPaymentMethod(PaymentMethods.CreditBook, creditBookmethods);

			const removeMemberPaymentMethod = (paymentTypeId: number) => {
				_.remove(paymentComponentScope.paymentMethods, x => x.paymentTypeId == paymentTypeId);
				_.remove(paymentComponentScope.paymentMethodsClone, x => x.paymentTypeId == paymentTypeId);				
			}
			//Removing Post to Folio based on condition
			let sessionValue = sessionStorage.getItem('FolioConfigurationSwitches');		
			let config = sessionValue ? JSON.parse(sessionValue) : null;
			if (config?.configValue) {
				var configValues = JSON.parse(config.configValue),
					result = Object.keys(configValues).map(k => ({
						configurationKey: [k][0],
						configurationValue: configValues[k]
					}));
			}
			const isEngageFeatureOn: boolean = result?.find(x => x.configurationKey === MiscConfigurationSwitches.agilysysEngage)?.configurationValue ?? false;
			if (this._ss.ProductId != Product.SPA || (this._ss.ProductId == Product.SPA && !isEngageFeatureOn)) {
				removeMethod(PaymentMethods.PostToFolio);
			}
			paymentComponentScope.paymentMethodsClone = _.sortBy(paymentComponentScope.paymentMethodsClone, "listOrder");
			paymentComponentScope.paymentMethods = _.sortBy(paymentComponentScope.paymentMethods, "listOrder");

			let selectMemberPayment: number = 0;
			//Display member payments based on corpID
			const memberConfig = this._propInfoService.getMemberConfig;
			if (memberConfig?.applicableCorporates) {
				const memberPayments = [PaymentMethods.ARPost, PaymentMethods.CreditBook];
				const memberPaymentMethods = paymentComponentScope.paymentMethods.filter(x => memberPayments.includes(x.paymentTypeId) || memberPayments.includes(x.parentTypeId));

				//Mapping the configured CorpID to respective Method
				memberPaymentMethods.map(paymentMethod => {
					let additionalConfig = paymentMethod.additionalConfigurations ? JSON.parse(paymentMethod.additionalConfigurations) : null;
					const currentCorpConfigured = additionalConfig && additionalConfig.find(x => x.Key.trim().toUpperCase() === ConfigKeys.Member.LoyaltySourceCorpID.toUpperCase())?.Value;
					paymentMethod.corpId = currentCorpConfigured ? Number(currentCorpConfigured) : 0;
				});

				if (this._ss.sourceCorpId && memberPaymentMethods.some(x => x.corpId == this._ss.sourceCorpId)) {
					const methodTobeSelected = memberPaymentMethods.find(x => x.corpId == this._ss.sourceCorpId && (x.parentTypeId == PaymentMethods.ARPost || x.paymentTypeId == PaymentMethods.ARPost));
					selectMemberPayment = methodTobeSelected ? methodTobeSelected.paymentTypeId : selectMemberPayment;
					//Removing Member PaymentMethods configured to Other CorpIDs
					memberPaymentMethods.filter(x => x.corpId != this._ss.sourceCorpId)?.map(x => removeMemberPaymentMethod(x.paymentTypeId));
				} else if (memberPaymentMethods.some(x => x.corpId == this.DefaultLoyaltySourceCorpID && (x.parentTypeId == PaymentMethods.ARPost || x.paymentTypeId == PaymentMethods.ARPost))) {
					const methodTobeSelected = memberPaymentMethods.find(x => x.corpId == this.DefaultLoyaltySourceCorpID && (x.parentTypeId == PaymentMethods.ARPost || x.paymentTypeId == PaymentMethods.ARPost));
					selectMemberPayment = methodTobeSelected ? methodTobeSelected.paymentTypeId : selectMemberPayment;
					//Removing Member PaymentMethods configured to Other CorpIDs
					memberPaymentMethods.filter(x => x.corpId && x.corpId != this.DefaultLoyaltySourceCorpID)?.map(x => removeMemberPaymentMethod(x.paymentTypeId));
				} else {
					//Selecting Member Payment method which didn't map to any CorpId
					selectMemberPayment = memberPaymentMethods.find(x => x.corpId == 0 && (x.parentTypeId == PaymentMethods.ARPost || x.paymentTypeId == PaymentMethods.ARPost))?.paymentTypeId ?? selectMemberPayment;
					memberPaymentMethods.filter(x => x.corpId)?.map(x => removeMemberPaymentMethod(x.paymentTypeId));
				}
			}

			//Override Payment method selection even if default method configured - Bug - 75316
			const memberPaymentMethod = _.cloneDeep(paymentComponentScope.copiedPaymentMethods.find(x => x.paymentTypeId == selectMemberPayment));
			paymentComponentScope.selectedpayment = memberPaymentMethod
				&& paymentComponentScope.transactionState == PaymentTransactionState.NotStarted
				&& !(this._ss.selectedProducts.some(x => x.MultiPack) && paymentComponentScope.remainingAmount == 0) ?
				memberPaymentMethod : paymentComponentScope.selectedpayment;
			if (!this.AllowZeroTenderForMember) {
				paymentComponentScope.transitionDetails.controls.amounttendered.disable();
			}
			paymentComponentScope.EnableCloseTranBtn = true;

			if (selectedPlayerRateTypeId.length > 0 && this.enforceMemberPayment && paymentComponentScope.enforceMemberRedeemAmount > settledAmountThroughAces) {
				const associatedRateTypes = this.RateTypes.filter(x => selectedPlayerRateTypeId.includes(x.id));
				const memberGuaranteedRateType = associatedRateTypes && associatedRateTypes.length && associatedRateTypes.find(x => x.guaranteeType == GuaranteeTypes.Member);
				if (memberGuaranteedRateType) {
					removeMethod(PaymentMethods.CreditBook);
				}
			}
		} else {
			removeMethod(PaymentMethods.ARPost);
			removeMethod(PaymentMethods.CreditBook);
			removeMethod(PaymentMethods.RedeemPoint);		
		}
		paymentComponentScope.paymentMethods = [...paymentComponentScope.paymentMethods];
		paymentComponentScope.paymentMethodsClone = [...paymentComponentScope.paymentMethodsClone];

	}

	EnforceMemberPayment(rateTypeId: Array<number>, enforceMemberRedeemAmount: number, paymentCompScope: PaymentComponent, productId: number) {
		if (productId == Product.SPA) {
			this.FilterPaymentMethod(enforceMemberRedeemAmount, paymentCompScope);
		}
		else {
			this.GetRateType().then(resp => {
				const associatedRateTypes = this.RateTypes.filter(x => rateTypeId.includes(x.id));
				const memberGuaranteedRateType = associatedRateTypes && associatedRateTypes.length && associatedRateTypes.find(x => x.guaranteeType == GuaranteeTypes.Member);
				if (memberGuaranteedRateType) {
					this.FilterPaymentMethod(enforceMemberRedeemAmount, paymentCompScope);
				}
			});
		}
	}

	private FilterPaymentMethod(enforceMemberRedeemAmount: number, paymentCompScope: PaymentComponent) {
		const settledAmountThroughAces = this.getSettlementAmountThroughAces(paymentCompScope.SettlementHistory);
		if (enforceMemberRedeemAmount > settledAmountThroughAces) {
			if (paymentCompScope.paymentMethods.some(x => this._utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) != PaymentMethods.ARPost && this._utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) != PaymentMethods.CreditBook)) {
				paymentCompScope.paymentMethods = paymentCompScope.paymentMethodsClone = [];
				const memberPaymentMethods = paymentCompScope.copiedPaymentMethods.filter(x => this._utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == PaymentMethods.ARPost || this._utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) == PaymentMethods.CreditBook);
				if(memberPaymentMethods && memberPaymentMethods?.some(s => s.id > 0)){
					paymentCompScope.selectedpayment = {
						id: memberPaymentMethods[0].id,
						paymentTypeId: memberPaymentMethods[0].paymentTypeId, 
						parentTypeId: memberPaymentMethods[0].parentTypeId, 
						allowEarn: memberPaymentMethods[0].allowEarn,
						requireReceipts: memberPaymentMethods[0].requireReceipts
					};
				}
				
				paymentCompScope.EnableCloseTranBtn = true;
				if (!this.AllowZeroTenderForMember) {
					paymentCompScope.transitionDetails.controls.amounttendered.disable();
				}
				if (!paymentCompScope.paymentMethods.some((r) => this._utils.GetOriginalTenderId(r.paymentTypeId, r.parentTypeId) == PaymentMethods.ARPost) && memberPaymentMethods && memberPaymentMethods?.some(s => s.id > 0)) {
					paymentCompScope.paymentMethods.push(...memberPaymentMethods);
				}
			}
		}
		else {
			paymentCompScope.paymentMethods = paymentCompScope.paymentMethodsClone = !paymentCompScope.isComponentAccessLinkingFilterApplied ? _.cloneDeep(paymentCompScope.copiedPaymentMethods) : paymentCompScope.paymentMethodsClone;
		}
	}

	public getSettlementAmountThroughAces(SettlementHistory: PaymentHistory[]): number {
		const acesPayment = [PaymentMethods.CreditBook, PaymentMethods.RedeemPoint, PaymentMethods.ARPost];
		let settledAmountThroughAces = 0;
		SettlementHistory && SettlementHistory.length && SettlementHistory.forEach((x) => {
			if (acesPayment.includes(x.paymentMethodId) && !x.isReversed) {
				settledAmountThroughAces += x.amount;
			}
		});
		return settledAmountThroughAces;
	}

	openmembershippopup(paymentCompScope: PaymentComponent, callback?: (x: any, paymentCompScope) => void, isResortFinancePayFlow : boolean = false) {
		paymentCompScope.original_payeeInfo = _.cloneDeep(paymentCompScope.payeeInfo);
		//Open member pin popup only if its configured
		let requirePinFolioMemConfig = this._ss.folioMemberConfig?.find(x => x.configurationKey.toLowerCase() == ConfigKeys.Member.RequirePin.toLowerCase())?.configurationValue?.toLowerCase() == 'true' ? true : false;
		const requirePin = isResortFinancePayFlow ? requirePinFolioMemConfig : this.RequirePin; 
		if (!requirePin) {
			callback("", paymentCompScope);
			return;
		}
		const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
			height: 'auto',
			width: '30%',
			data: {
				headername: this.ShopCaptions.RedeemRoundsHeader,
				closebool: true,
				Type: 'RedeemRounds',
				buttonType: GlobalConst.ButtonType.OkCancel
			},
			panelClass: 'small-popup',
			disableClose: true,
			hasBackdrop: true
		});
		const subscription = dialogRef.afterClosed().subscribe((res) => {
			if (res.res == 'ok' && res.labelPosition) {
				if (callback) {					
					callback(res.labelPosition.toString(), paymentCompScope);
				}
			}
		});
	}

	setAvalableRounds(memInfo: PayeeInfo) {
		if (memInfo.golfPoints) {
			this._ss.availableRounds = 0;
			const uniqueBucketCode: GolfPoints[] = [];
			memInfo.golfPoints.forEach((value, key) => {
				if (uniqueBucketCode.find((x) => x.bucketCode == value.bucketCode) == undefined) {
					uniqueBucketCode.push(value);
					this._ss.availableRounds += value.eligibleRounds;
				}
			});
		}
	}
	public async searchMemberByKeyAndValue(searchKey : string, searchValue : string){
		const memberInfo: BaseResponse<Tournament.UI.ContactInformation> = await this.httpserviceCall.CallApiAsync({
			callDesc: "SearchMembersByKeyAndValue",
			host: Host.payment,
			method: HttpMethod.Get,
			uriParams: { searchKey: searchKey, searchValue: searchValue }
		});
		if(memberInfo != null)
			return memberInfo;
		else return [];
	}

	async SetMemberInfo() {
		if (this._ss.ProductId == Product.GOLF) {
			if (this._ss.SelectedPlayers != undefined && this._ss.memberCardNumber == "0") {
				const firstPlayer = _.orderBy(this._ss.SelectedPlayers, 'playerSlotPosition', 'asc');
				const memberIndex = firstPlayer.findIndex((member) => member.playerCategoryId === 3); //For finding member index
				this._ss.memberCardNumber =
					memberIndex != -1 && firstPlayer[memberIndex].playerLinkId
						? firstPlayer[memberIndex].playerLinkId
						: "0";
			}
		}
		else {
			if (this._ss.selectedappointments != undefined && this._ss.selectedappointments.length > 0 && this._ss.memberCardNumber == "0") {
				const firstclient = this._ss.selectedappointments;
				const memberIndex = firstclient.findIndex((t) => t.clienttype && t.clienttype == 2); // For finding Member index				
				//For finding member index
				this._ss.memberCardNumber = memberIndex != -1 &&
					firstclient[memberIndex].clientLinkId
					? firstclient[memberIndex].clientLinkId
					: "0";

			}
			else if (this._ss.cancellationNoShowCharge && this._ss.cancellationNoShowCharge.length > 0 && !(this._ss.cancelDepositAppointments && this._ss.cancelDepositAppointments.length > 0)) {
				this._ss.memberCardNumber = this._ss.cancellationNoShowCharge[0]?.clientInfo?.clientType == 2 ? this._ss.cancellationNoShowCharge[0]?.clientInfo.clientLinkId : "0";
			}
		}
		const corpId = this._ss.sourceCorpId ? this._ss.sourceCorpId : 0;
		if (this._ss.memberCardNumber != "0") {
			this.memberAPIProgressNotifier.emit(true);
			this._utils.ToggleLoader(true, this.ShopCaptions.MemberSelectionPopup.fetchingSingleMemberDetailsMsg);
			const memInfo = await this.getMemberInfo(
				this._ss.memberCardNumber,
				this.getScheduleDateInUTC(this._ss.SelectedPlayers), false, true, false, corpId
			);
			this._utils.ToggleLoader(false);
			this._ss.memberDiscountType = memInfo?.discountType ?? "";
			this._ss.membershipType = memInfo?.membershipType ?? "";
			this.memberAPIProgressNotifier.emit(false);
			if (memInfo) this.setAvalableRounds(memInfo);
		}
	}

	getScheduleDateInUTC(selectedPlayers: any[]) {
		let scheduleDt = this._propInfoService.CurrentDate;
		if (selectedPlayers && selectedPlayers?.length) {
			const firstPlayer = _.orderBy(selectedPlayers, 'playerSlotPosition', 'asc');
			const memberIndex = firstPlayer.findIndex((member) => member.playerCategoryId === 3); //For finding member index
			scheduleDt = memberIndex != -1 ? new Date(firstPlayer[memberIndex].scheduledAPIDateTime) : scheduleDt;
		}
		return scheduleDt.toISOString();
	}

	async ValidateInactiveMember(memberCardNumber, selectedPlayers, IsResortFinancePaymentFlow : boolean = false, corpId : number = 0): Promise<boolean> {
		let isActive: boolean = false;
		const result = await this.getMemberInfo(
			memberCardNumber != "0" && memberCardNumber, 
			this.getScheduleDateInUTC(selectedPlayers), false, true, IsResortFinancePaymentFlow, corpId);
		if (result && result != null) {
			isActive = true;
		}
		return isActive;
	}

	ValidateMemberRound(payeeInfo: PayeeInfo, courseName, noRounds, golfPoints: GolfPoints[]) {
		let isValid = false;
		var bucketInfo = golfPoints.find((x) => x.bucketName.toLowerCase() == courseName.toLowerCase());
		const Arpostbucket: BalanceList = payeeInfo.balanceList
			? payeeInfo.balanceList.find((x) => x.BucketName.replace(/\s/g, '').toLowerCase() == this.MemberBucketName)
			: null;

		if (bucketInfo && bucketInfo.eligibleRounds < noRounds) {
			this._utils.showAlert(
				this.localization.captions.common.MemberExceededAllowedRounds,
				AlertType.Info,
				ButtonType.Ok
			);
		} else if (bucketInfo == undefined || Arpostbucket == null) {
			this._utils.showAlert(
				this.localization.captions.common.CourseNotAvailableForMember,
				AlertType.Info,
				ButtonType.Ok
			);
		}
		else {
			isValid = true;
		}
		return isValid;
	}

	ValidateCreditBook(amount: number, bucket: BalanceList[], paymentMethod: PaymentMethod, isResortFinancePaymentFlow : boolean = false) {
		let isValid = false;
		if (isResortFinancePaymentFlow) {
			bucket = this._ss.selectedMemberInfo?.balanceList;
		}
		const creditBookBucket = bucket?.find((x) => x.BucketName && x.BucketName.replace(/\s/g, '').toLowerCase() == this.GetBucketName(paymentMethod, isResortFinancePaymentFlow));
		const bucketInfo = bucket?.find((x) => x.BucketCode.toLowerCase() == creditBookBucket?.BucketCode.toLowerCase());
		if(amount < 0 && this._ss.restrictNegativeRedeemption){
			this.dialog.closeAll();
			this._utils.showAlert(
				this.localization.captions.common.NegativeRedeemAmount,
				AlertType.Info,
				ButtonType.Ok
			);
			return isValid;
		}
		if (bucketInfo && bucketInfo.AccrualCash < amount) {
			this.dialog.closeAll();
			this._utils.showAlert(
				this.localization.captions.common.MemberExceededAllowedCredit,
				AlertType.Info,
				ButtonType.Ok
			);
		} else if (bucketInfo == undefined) {
			this.dialog.closeAll();
			this._utils.showAlert(
				this.localization.captions.common.CreditBookNotAvailableForMember,
				AlertType.Info,
				ButtonType.Ok
			);
		} else {
			isValid = true;
		}
		return isValid;
	}

	async MembershipACESCreditBook(memberPin, payeeInfo: PayeeInfo, GridItems, checkNumber: string
		, retailTicketNumber: string, paymentMethod: PaymentMethod, creditAmount: number, totalAmount: number
		, callback: Function, Compscope : any = null) {
		this.memberAPIProgressNotifier.emit(true);
		let isResortFinancePayFlow : boolean = Compscope?.$scope?.IsResortFinanceMakePaymentFlow ?? false;
		const corpId = this._ss.sourceCorpId ? this._ss.sourceCorpId : 0;
		let _memberInfo: PayeeInfo = await this.getMemberInfo(
			this._ss.memberCardNumber != "0" && this._ss.memberCardNumber,
			this.getScheduleDateInUTC(this._ss.SelectedPlayers), false, true, isResortFinancePayFlow, corpId);

		this.memberAPIProgressNotifier.emit(false);
		if (_memberInfo == null) { return; }
		_memberInfo.id = payeeInfo ? payeeInfo.id : 0;
		payeeInfo = _memberInfo;
		let tenderType = '';
		const bucket: BalanceList = payeeInfo.balanceList
			? payeeInfo.balanceList.find(
				(x) => x.BucketName && x.BucketName.replace(/\s/g, '').toLowerCase() == this.GetBucketName(paymentMethod, isResortFinancePayFlow)
			)
			: null;
		if (bucket != null) {
			tenderType = bucket.BucketCode;
		} else {
			this.dialog.closeAll();
			this._utils.ShowError(
				this.localization.captions.common.Error,
				this.localization.captions.common.CreditBookNotAvailableForMember
			);
			return;
		}

		if (!this.ValidateCreditBook(creditAmount, payeeInfo.balanceList, paymentMethod, isResortFinancePayFlow)) { return; }

		const itemInfo: ACESItemInfo[] = [];
		for (var element of GridItems) {
			const itemQuantity: number = element.quantity == '' ? 0 : Number(element.quantity);
			const gratuity =
				element.element.Gratuity && element.element.Gratuity.gratuity
					? parseFloat(element.element.Gratuity.gratuity)
					: 0;
			const servCharge =
				element.element.ServiceCharge && element.element.ServiceCharge.ServiceCharge
					? parseFloat(element.element.ServiceCharge.ServiceCharge)
					: 0;
			const itemPrice = parseFloat(element.unitprice) * itemQuantity + gratuity + servCharge;
			itemInfo.push({ ItemGroupName: element.itemName, ItemPrice: itemPrice, Quantity: itemQuantity});
		}
		const tenderInfo: ACESTenderInfo[] = [
			{
				TenderType: tenderType,
				Amount: creditAmount
			}
		];
		const rand = Math.floor(1000 + this._utils.getRandomDecimal() * 9000);
		if (isResortFinancePayFlow) {
			retailTicketNumber = Compscope?.$scope?.AdditionalInputs?.folioId;
		}
		const checkInfo: ACESCheckInfo = {
			CheckNumber: checkNumber + rand,
			AssociatedCheckNumber: checkNumber,
			TicketNumber: retailTicketNumber,
			PaymentAmount: creditAmount,
			TaxAmount: this._ss.Ticket ? this._ss.Ticket.checkData.totalTax : 0,
			Amount: totalAmount,
			ItemInfo: itemInfo
		};
		let redeemPointRequest: ACESPayment = null;
		redeemPointRequest = {
			SourceCorpId: corpId,
			SourceSiteId: 0,
			SourceSystemCode: '',
			GuestCardNo: this._ss.memberCardNumber,
			Pin: memberPin,
			CheckInfo: checkInfo,
			PaymentInfo: {
				TenderInfo: tenderInfo
			},
			IsResortFinancePaymentFlow: isResortFinancePayFlow
		};
		this.memberAPIProgressNotifier.emit(true);
		const Response = await this.RedeemPoint(redeemPointRequest);
		//response
		if (Response && Response.result && Response.result.SuccessCode == 1) {
			const ACESRecord: ACESPaymentRecord = {
				paymentTransactionId: 0,
				transactionId: checkNumber + rand,
				acesTransactionid: Response.result.TransactionID,
				arAccountNumber: _memberInfo.arAccountNumber,
				memberId: this._ss.memberCardNumber,
				tenderType: tenderType,
				amount: creditAmount,
				paymentMethodType: paymentMethod.paymentTypeId,
				memberPin: memberPin,
				tenderId: PaymentMethods.CreditBook,
				outletId: this._ss.SelectedOutletId,
				ticketNumber: retailTicketNumber,
				checkNumber: checkNumber,
				availableRounds: this._ss.availableRounds,
				memberName: _memberInfo.name,
				sourceCorpId: corpId
			};
			callback([ACESRecord], true);
			this.memberAPIProgressNotifier.emit(false);
		} else {
			this.memberAPIProgressNotifier.emit(false);
			if (Response && Response.result.ErrorMessage) {
				this.dialog.closeAll();
				this._utils.ShowError(this.localization.captions.common.Error, Response.result.ErrorMessage);
				return;
			} else {
				this.dialog.closeAll();
				this._utils.ShowError(
					this.localization.captions.common.Error,
					this.localization.replacePlaceholders(
						this.ShopCaptions.PaymentTransactionFailureMsg,
						['paymentmethod'],
						[this.localization.captions.shop.paymentMethods[paymentMethod.paymentTypeId]]
					)
				);
			}
			return;
		}
	}

	async getMemberGuaranteedPlayer() {
		const memberGuarnteeRateType = (await this.GetRateType()).filter(x => x.guaranteeType == GuaranteeTypes.Member).map(x => x.id);
		return this._ss.SelectedPlayers.find(x => memberGuarnteeRateType.includes(x.rateTypeId));
	}

	MapToPayeeInfo(playerDetail: any): PayeeInfo {
		let payeeInfo: PayeeInfo = null;
		if (playerDetail) {
			payeeInfo = {
				name: playerDetail.firstName + ' ' + playerDetail.lastName,
				firstName: playerDetail.firstName,
				lastName: playerDetail.lastName,
				address: (this.ReplaceEmptyIfNull(playerDetail.playerAddress, 'addressLine1') + ' ' + this.ReplaceEmptyIfNull(playerDetail.playerAddress, 'state')),
				country: this.ReplaceEmptyIfNull(playerDetail.playerAddress, 'country'),
				zip: this.ReplaceEmptyIfNull(playerDetail.playerAddress, 'zip'),
				city: this.ReplaceEmptyIfNull(playerDetail.playerAddress, 'city'),
				guestProfileId: playerDetail.guestId ? playerDetail.guestId : playerDetail.playerLinkId,
				cardInfo: [],
				id: playerDetail.id,
				balanceList: playerDetail.balanceList,
				playerCategoryId: playerDetail.playerCategoryId,
				golfPoints: playerDetail.golfPoints,
				membershipType: playerDetail.membershipType,
				membershipStatus: playerDetail.membershipStatus,
				cardStatus: playerDetail.cardStatus,
				membershipExpiryDate: playerDetail.membershipExpiryDate,
				membershipRenewalDate: playerDetail.membershipRenewalDate,
				isRoundRedeem: playerDetail.isRoundRedeem,
				creditBookBalance: this.GetCreditBookBalance(playerDetail.balanceList),
				arAccountNumber: playerDetail.arAccountNumber,
				emailId: playerDetail.contactInformation ? this.GetEmailId(playerDetail.contactInformation) : "",
				photoUrl: playerDetail.photoUrl,
				corpId: playerDetail.corpId,
				corpName: playerDetail.corpName,
				siteId: playerDetail.siteId,
				siteName: playerDetail.siteName,
				discountType: playerDetail.discountType
			}
		}
		return payeeInfo;
	}

	private GetEmailId(email : ContactDetails[])
	{
		if (email.some(x => x.type == MailTypes.personal || x.type == MailTypes.office)) {
			let primaryEmail = email.find(x => x.isPrimary && (x.type == MailTypes.personal || x.type == MailTypes.office));
			let personalEmail = email.find(x => x.type == MailTypes.personal);
			let officeEmail = email.find(x => x.type == MailTypes.office);
			if (primaryEmail) {
			  return primaryEmail.value;
			} else if (personalEmail) {
			  return personalEmail.value;
			} else if (officeEmail) {
			  return officeEmail.value;
			} else {
			  return email[0].value;
			}
		  }
		  else {
			return "";
		  }
	}

	private GetCreditBookBalance(balanceList: BalanceList[]) {
		if (balanceList) {
			const bucket: BalanceList = balanceList.find(
				(x) => x.BucketName && x.BucketName.replace(/\s/g, '').toLowerCase() == this.CreditBookBucketName
			);
			return bucket ? bucket.AccrualCash : 0;
		}
		return -1;
	}
	private ReplaceEmptyIfNull(obj: object, key: string) {
		if (obj) {
			const tempObj = obj[key] ? obj[key] : '';
			return obj ? tempObj : '';
		}
		return '';
	}

	public async searchPlayer(searchKey: string, type: any, isSearchNameOnly: boolean = false): Promise<any[]> {
		const resp: BaseResponse<any[]> = await this.httpserviceCall.CallApiAsync({
			callDesc: "SearchMembers",
			host: Host.payment,
			method: HttpMethod.Get,
			uriParams: { pattern: searchKey, searchType: type, isSearchNameOnly: isSearchNameOnly }
		});
		return resp.result;
	}

	async searchGuest(Name, type: number, isSearchNameOnly: boolean = false): Promise<[Tournament.UI.ContactInformation[], PayeeInfo[]]> {
		type = type == 1 ? SearchType.Member : SearchType.GuestAndPlayer;
		const guestInfo: Tournament.UI.ContactInformation[] = await this.searchPlayer(Name, type, isSearchNameOnly);
		const payeeInfo: PayeeInfo[] = [];
		guestInfo.forEach(x => {
			x.playerType = Number.parseInt(x.playerType) || 0;
			x.rateType = Number.parseInt(x.rateType) || 0;
			payeeInfo.push(this.MapToPayeeInfo(x));
		});
		return [guestInfo, payeeInfo];
	}

	ProcessOrderForMember(payeeInfo: PayeeInfo, gridItems, retailTicketNumber: string, saleAmount: number, totalAmount: number, settlementHistory: PaymentHistory[], transactionData: TransactionInfoforCheckZoom) {
		let totalRounds = 0, courseName: string = '', ratetype: string = '', playertype: string = '';
		if (this._ss.SelectedPlayers && payeeInfo && this._ss.SelectedPlayers.length > 0) {
			this._ss.SelectedPlayers.forEach((x) => {
				totalRounds += x.holes == 18 ? 1 : 0.5;
			});
			courseName = this._ss.SelectedPlayers[0].course;
			ratetype = this._ss.SelectedPlayers[0].rateType;
			playertype = this._ss.SelectedPlayers[0].playerType;

			if (payeeInfo.id > 0) {
				const player = this._ss.SelectedPlayers.find((x) => x.playerId == payeeInfo.id);
				if (player != undefined) {
					ratetype = player.rateType;
					playertype = player.playerType;
				}
			}
		}
		const itemInfo: ACESItemInfo[] = [];
		for (var element of gridItems) {
			if (!(element.isGroupingKey && !element.isPackagedItem)) {
				const itemQuantity: number = element.quantity == '' ? 0 : Number(element.quantity);
				const gratuity =
					element.element.Gratuity && element.element.Gratuity.gratuity
						? parseFloat(element.element.Gratuity.gratuity)
						: 0;
				const servCharge =
					element.element.ServiceCharge && element.element.ServiceCharge.ServiceCharge
						? parseFloat(element.element.ServiceCharge.ServiceCharge)
						: 0;
				const itemPrice = parseFloat(element.unitprice) * itemQuantity + gratuity + servCharge;
				itemInfo.push({
					ItemGroupName: element.itemName,
					ItemPrice: itemPrice,
					Quantity: itemQuantity
				});
			}
		}
		const acesCheckInfo: ACESCheckInfo = {
			CheckNumber: retailTicketNumber,
			PaymentAmount: saleAmount,
			TaxAmount: this._ss.Ticket ? this._ss.Ticket?.checkData?.totalTax : 0,
			Amount: totalAmount,
			ItemInfo: itemInfo
		};
		const tenderInfo: ACESTenderInfo[] = [];
		
		for (var item of settlementHistory) {
			if (item.paymentMethodId == PaymentMethods.ARPost) {
				const tendertype = payeeInfo?.balanceList?.find((x) => x.BucketName.replace(/\s/g, '').toLowerCase() == this.GetBucketName(item.paymentMethodInfo));
				tenderInfo.push({ TenderType: tendertype?.BucketCode, Amount: item.amount });
			} else if (item.paymentMethodId == PaymentMethods.RedeemPoint) {
				const tendertype = payeeInfo.golfPoints.find(
					(x) => x.bucketName.toLowerCase() == courseName.toLowerCase()
				).bucketCode;
				tenderInfo.push({ TenderType: tendertype, Amount: item.amount });
			} else if (item.paymentMethodId == PaymentMethods.CreditBook) {
				const tendertype = payeeInfo?.balanceList?.find(
					(x) => x.BucketName && x.BucketName.replace(/\s/g, '').toLowerCase() == this.GetBucketName(item.paymentMethodInfo)
				);
				tenderInfo.push({ TenderType: tendertype?.BucketCode, Amount: item.amount });
			} else {
				tenderInfo.push({ TenderType: item.paymentMethod, Amount: item.amount });
			}
		}

		const paymentInfo: ACESPaymentInfo = {
			TenderInfo: tenderInfo
		};
		const paymentsummary: ACESPayment = {
			SourceCorpId: 8,
			SourceSiteId: 7,
			SourceSystemCode: 'GOLF',
			GuestCardNo: this._ss.memberCardNumber,
			CheckInfo: acesCheckInfo,
			PaymentInfo: paymentInfo,
			NumberofRounds: totalRounds,
			PlayerType: playertype,
			RateType: ratetype,
			Course: courseName,
			TransactionInfo: transactionData
		};
		this.MemberOrderSummary(paymentsummary);
	}

	GetBucketName(paymentMethod: PaymentMethod, isResortFinancePaymentFlow : boolean = false) {
		if(paymentMethod.bucketCode && paymentMethod.bucketName){
			return paymentMethod.bucketName?.replace(/\s/g, '')?.toLowerCase();
		}
		let defaultBucketMap;
		if (isResortFinancePaymentFlow) {
			defaultBucketMap = {
				[PaymentMethods.ARPost]: this._ss.folioMemberConfig?.find(x => x.configurationKey.toLowerCase() == ConfigKeys.Member.MemberBucketName.toLowerCase())?.configurationValue ?? DefaultBucketName.memberBucketName,
				[PaymentMethods.CreditBook]: this._ss.folioMemberConfig?.find(x => x.configurationKey.toLowerCase() == ConfigKeys.Member.CreditBookBucketName.toLowerCase())?.configurationValue ?? DefaultBucketName.creditBookBucketName
			}
		}
		else {
			defaultBucketMap = {
				[PaymentMethods.ARPost]: this.MemberBucketName,
				[PaymentMethods.CreditBook]: this.CreditBookBucketName
			}
		}
		let currentBucketName = "";
		const paymentTypeId = paymentMethod.isCloned ? paymentMethod.parentTypeId : paymentMethod.paymentTypeId;
		if (paymentMethod?.additionalConfigurations && paymentMethod.additionalConfigurations != '') {
			const config = JSON.parse(paymentMethod?.additionalConfigurations);
			currentBucketName = config.find((x) => x.Key.toLowerCase() === 'bucketname')?.Value;
			currentBucketName = currentBucketName ? currentBucketName : defaultBucketMap[paymentTypeId];
		}
		else {
			currentBucketName = defaultBucketMap[paymentTypeId];
		}
		return currentBucketName?.replace(/\s/g, '')?.toLowerCase();
	}

	public async GetAllPaymentRecords(): Promise<ACESPaymentRecord[]> {
		const resp: BaseResponse<ACESPaymentRecord[]> = await this.httpserviceCall.CallApiAsync({
			callDesc: "GetAllPaymentRecords",
			host: Host.retailPOS,
			method: HttpMethod.Get
		});
		return resp.result;
	}

	public async GetPaymentRecordsByTransactionId(transactionid: string): Promise<ACESPaymentRecord[]> {
		const resp: BaseResponse<ACESPaymentRecord[]> = await this.httpserviceCall.CallApiAsync({
			callDesc: "GetPaymentRecordsbyTransactionId",
			host: Host.retailPOS,
			method: HttpMethod.Get,
			uriParams: { TransactionId: transactionid }
		});
		return resp.result;
	}

	public async GetACESPaymentRecordsByPaymentTransactionId(paymentTransactionId:number[]): Promise<ACESPaymentRecord[]>
	{
		const resp: BaseResponse<ACESPaymentRecord[]> = await this.httpserviceCall.CallApiAsync({
			callDesc: "ACESPaymentRecords",
			host: Host.payment,
			method: HttpMethod.Put,
			body: paymentTransactionId 
		});
		return resp.result;
	}

	public async ARPost(paymentModel: ACESPayment): Promise<any> {
		try {
			return await this.httpserviceCall.CallApiAsync<any>({
				host: Host.retailPOS,
				callDesc: "ARPost",
				method: HttpMethod.Post,
				body: paymentModel,
			});
		} catch (e) {
			this.httpserviceCall.exceptionHandle(e);
		}
	}

	public async MemberPayment(paymentModel: ACESMemberPayment): Promise<any> {
		try {
			return await this.httpserviceCall.CallApiAsync<any>({
				host: Host.retailPOS,
				callDesc: paymentModel?.IsResortFinancePaymentFlow ? RetailRoutes.FolioMemberPayment : RetailRoutes.MemberPayment,
				method: HttpMethod.Post,
				body: paymentModel,
			});
		} catch (e) {
			this.httpserviceCall.exceptionHandle(e);
		}
	}

	public async RedeemPoint(redeemPoint): Promise<any> {
		try {
			return await this.httpserviceCall.CallApiAsync<any>({
				host: Host.retailPOS,
				callDesc: redeemPoint?.IsResortFinancePaymentFlow ? RetailRoutes.FolioRedeemPoint : RetailRoutes.RedeemPoint,
				method: HttpMethod.Post,
				body: redeemPoint,
			});
		} catch (e) {
			this.httpserviceCall.exceptionHandle(e);
		}
	}

	public async MemberOrderSummary(paymentModel: ACESPayment): Promise<any> {
		try {
			return await this.httpserviceCall.CallApiAsync<any>({
				host: Host.retailPOS,
				callDesc: "MemberSummary",
				method: HttpMethod.Post,
				body: paymentModel,
			});
		} catch (e) {
			this.httpserviceCall.exceptionHandle(e);
		}
	}

	public async getACESPaymentRecords(Transactionid: number): Promise<any> {
		const resp: BaseResponse<any[]> = await this.httpserviceCall.CallApiAsync({
			callDesc: "GetMember",
			host: Host.retailPOS,
			method: HttpMethod.Get,
			uriParams: { transactionId: Transactionid }
		});
		return resp.result;
	}

	async getFinancialBin(request: TransactionInput): Promise<ACESFinancialbin[]> {
		const result = await this.httpserviceCall.CallApiAsync({
			callDesc: RetailRoutes.GetFinancialBins,
			method: HttpMethod.Put,
			host: GlobalConst.Host.retailPOS,
			body: request
		});
		const response = result && result.result ? result.result as [] : [];
		response.forEach((element) => { element.Id = Math.abs(element.Id); });
		return this.ConstructFinancialBinForPost(response);
	}

	public ValidateMembershipPaymentMethodActivation($scope: ShopBussinessService) {
		const RemovePaymentMethod = (paymentMethodId) => {
			$scope.paymentMethods = $scope.paymentMethods.filter(x => this._utils.GetOriginalTenderId(x.paymentTypeId, x.parentTypeId) != paymentMethodId);
		}
		
		const removeMemberPaymentMethod = (paymentTypeId: number) => {
			_.remove($scope.paymentMethods, x => x.paymentTypeId == paymentTypeId);		
		}

		const isCollectFlow: boolean = ($scope._ss.finalAmount > 0);
		const ActiveTenders = $scope.GetActiveTransactionPayments($scope.TransactionPayments);
		if ($scope.TransactionPayments && $scope.TransactionPayments.length > 0) {
			if (isCollectFlow || !ActiveTenders.some(x => this._utils.GetOriginalTenderId(Number(x.paymentMethod), x.parentTenderId) == PaymentMethods.ARPost)) {
				RemovePaymentMethod(PaymentMethods.ARPost);
			}
			if (isCollectFlow || !ActiveTenders.some(x => this._utils.GetOriginalTenderId(Number(x.paymentMethod), x.parentTenderId) == PaymentMethods.CreditBook)) {
				RemovePaymentMethod(PaymentMethods.CreditBook);
			}
			if (isCollectFlow || !ActiveTenders.some(x => this._utils.GetOriginalTenderId(Number(x.paymentMethod), x.parentTenderId) == PaymentMethods.RedeemPoint)) {
				RemovePaymentMethod(PaymentMethods.RedeemPoint);
			}
		}
		//Display member payments based on corpID
		const memberConfig = this._propInfoService.getMemberConfig;
		if (memberConfig?.applicableCorporates) {
			const memberPayments = [PaymentMethods.ARPost, PaymentMethods.CreditBook];
			const memberPaymentMethods = $scope.paymentMethods.filter(x => memberPayments.includes(x.paymentTypeId) || memberPayments.includes(x.parentTypeId));

			//Mapping the configured CorpID to respective Method
			memberPaymentMethods.map(paymentMethod => {
				let additionalConfig = paymentMethod.additionalConfigurations ? JSON.parse(paymentMethod.additionalConfigurations) : null;
				const currentCorpConfigured = additionalConfig && additionalConfig.find(x => x.Key.trim().toUpperCase() === ConfigKeys.Member.LoyaltySourceCorpID.toUpperCase())?.Value;
				paymentMethod.corpId = currentCorpConfigured ? Number(currentCorpConfigured) : 0;
			});

			if (this._ss.sourceCorpId && memberPaymentMethods.some(x => x.corpId == this._ss.sourceCorpId)) {
				//Removing Member PaymentMethods configured to Other CorpIDs
				memberPaymentMethods.filter(x => x.corpId != this._ss.sourceCorpId)?.map(x => removeMemberPaymentMethod(x.paymentTypeId));
			} else if (memberPaymentMethods.some(x => x.corpId == this.DefaultLoyaltySourceCorpID && (x.parentTypeId == PaymentMethods.ARPost || x.paymentTypeId == PaymentMethods.ARPost))) {
				//Removing Member PaymentMethods configured to Other CorpIDs
				memberPaymentMethods.filter(x => x.corpId && x.corpId != this.DefaultLoyaltySourceCorpID)?.map(x => removeMemberPaymentMethod(x.paymentTypeId));
			} else {
				memberPaymentMethods.filter(x => x.corpId)?.map(x => removeMemberPaymentMethod(x.paymentTypeId));
			}
		}
		
	}

	DisableMemberPaymentsIfSettled($scope: ShopBussinessService) {
		const ActiveTenders = $scope.GetActiveTransactionPayments($scope.TransactionPayments);
		const memberShipMethods = [PaymentMethods.ARPost, PaymentMethods.RedeemPoint];
		const allowedMembershipPayments = ActiveTenders.filter(x => memberShipMethods.includes(Number(x.paymentMethod)));
		allowedMembershipPayments.forEach(memberPayment => {
			if ($scope.SettlementHistory.some(x => x.paymentMethodId == Number(memberPayment.paymentMethod) && !x.isReversed)) {
				$scope.paymentMethods.find(x => x.paymentTypeId == Number(memberPayment.paymentMethod)).isDisabled = true;
			}
		});
	}

	DisableCreditBookIfSettled($scope: ShopBussinessService) {
		const ActiveTenders = $scope.GetActiveTransactionPayments($scope.TransactionPayments);
		const allowedMembershipPayments = ActiveTenders.filter(x => Number(x.paymentMethod) == PaymentMethods.CreditBook);
		allowedMembershipPayments.forEach(memberPayment => {
			if ($scope.SettlementHistory.some(x => x.paymentMethodId == Number(memberPayment.paymentMethod) && !x.isReversed)) {
				$scope.paymentMethods.find(x => x.paymentTypeId == Number(memberPayment.paymentMethod)).isDisabled = true;
			}
		});
	}

	CheckIfMemberPaymentsCanBeRefunded($scope: ShopBussinessService) {
		const allowedToProceed: boolean = true;
		let refundAmt = 0;
		let remainingAmt = 0;
		if ($scope.TransactionPayments.some(x => Number(x.paymentMethod) == $scope.selectedpayment.paymentTypeId)) {
			const originalTransactionHist = $scope.TransactionPayments.filter(x => Number(x.paymentMethod) == $scope.selectedpayment.paymentTypeId);
			let originallyPaidAmt = 0;
			originalTransactionHist.forEach(x => originallyPaidAmt += $scope.RoundOffTwo(x.paymentAmount));
			if ($scope.SettlementHistory.some(x => x.paymentMethodId == $scope.selectedpayment.paymentTypeId)) {
				const settledMemberPayment = $scope.SettlementHistory.filter(x => x.paymentMethodId == $scope.selectedpayment.paymentTypeId);
				let remainingMemberPayment = 0;
				settledMemberPayment.forEach(x => remainingMemberPayment += $scope.RoundOffTwo(x.amount));
				remainingAmt = $scope.RoundOffTwo(originallyPaidAmt + remainingMemberPayment);
				remainingAmt = remainingAmt > Math.abs($scope.remainingAmount) ? Math.abs($scope.remainingAmount) : remainingAmt;
			}
			else {
				remainingAmt = Math.abs($scope.remainingAmount);
			}
			refundAmt = remainingAmt < originallyPaidAmt ? remainingAmt : originallyPaidAmt;
		}
		return [allowedToProceed, refundAmt];
	}

	async RefundMembershipPayment(compScope, returnTransitionDetails, $scope: ShopBussinessService) {
		if ($scope.ReturnWithTicketResponse && $scope.ReturnWithTicketResponse.transactionData) {
			$scope._ams.loaderEnable.next($scope.ShopCaptions.RefundToOriginalSettlementsInProgress);
			const ActiveTenders = $scope.GetActiveTransactionPayments($scope.TransactionPayments);
			const originalSettlementsWithCurrentPaymentType = ActiveTenders.filter(x => Number(x.paymentMethod) == $scope.selectedpayment.paymentTypeId);
			const checkData = JSON.parse($scope.ReturnWithTicketResponse.transactionData.checkData);
			const uriParams = {
				transactionId: $scope.ReturnWithTicketResponse && $scope.ReturnWithTicketResponse.transactionData.id,
				ticketNumber: checkData && checkData.CheckHandle.CheckNumber,
				outletId: $scope._ss.SelectedOutletId,
				checkHandleGuid: $scope.ReturnWithTicketResponse.transactionData.checkHandleGuid,
				tenderId: $scope.selectedpayment.paymentTypeId,
				terminalId: checkData && checkData.Terminal.Id
			};
			let referenceIds = [], refundAmount = 0;
			if (originalSettlementsWithCurrentPaymentType && originalSettlementsWithCurrentPaymentType.length > 1) {
				originalSettlementsWithCurrentPaymentType.forEach(x => {
					refundAmount += $scope.RoundOffTwo(x.paymentAmount);
					referenceIds.push(x.paymentReferenceId);
				});
			} else {
				referenceIds.push(originalSettlementsWithCurrentPaymentType[0].paymentReferenceId);
				refundAmount = originalSettlementsWithCurrentPaymentType[0].paymentAmount;
			}

			const RefundResponse: any = this.httpserviceCall.CallApiAsync<any>({
				host: GlobalConst.Host.retailPOS,
				callDesc: RetailRoutes.MembershipRefundPayment,
				method: HttpMethod.Post,
				body: refundAmount,
				uriParams: uriParams,
				withQueryString: "paymentreferenceIds",
				queryString: { key: "paymentreferenceIds", value: referenceIds },
				showError: true
			});
			RefundResponse.then(response => {
				setTimeout(() => {
					$scope._ams.loaderEnable.next('');
				}, 1000);
				if (response && response.successStatus && response.result) {
					originalSettlementsWithCurrentPaymentType.forEach(o => {
						const settlement: PaymentHistory = {
							amount: -o.paymentAmount,
							paymentMethodId: Number(o.paymentMethod),
							parentTenderId: o?.parentTenderId,
							paymentMethod: `${this.localization.captions.shop.paymentMethods[$scope.selectedpayment.paymentTypeId]} | ${this._ss.selectedclient.arAccountNumber}`,
							isReversed: false,
							paymentMethodInfo: $scope.selectedpayment
						}
						$scope.SettlementHistory.unshift(settlement);
					});
					$scope.ShowSettlemtHist = true;
					$scope.AllowCancel = ($scope.IsRefundFlow && $scope._ss.settleRWTTransaction || !$scope.IsRefundFlow || !$scope.ReturnWithTicketResponse);
					$scope.CalculateSettledAmount(returnTransitionDetails);
					const selectedPaymentId = $scope.selectedpayment.paymentTypeId;
					if ($scope.remainingAmount != 0) {
						setTimeout(() => { $scope._ams.loaderEnable.next(''); }, 1000);
						$scope.CheckSettlementStatusAndUpdateButtons(compScope);
						$scope.SwitchToMultiPayment('', returnTransitionDetails, true);
					} else {
						//Close the loader
						setTimeout(() => { $scope._ams.loaderEnable.next("") }, 1000);

						compScope.TenderRetainData();
						//After adding settlement history update the buttons
						$scope.CheckSettlementStatusAndUpdateButtons(compScope);
					}
					if (selectedPaymentId == PaymentMethods.ARPost || selectedPaymentId == PaymentMethods.RedeemPoint) {
						this.DisableMemberPaymentsIfSettled($scope);
					}
					else if (selectedPaymentId == PaymentMethods.CreditBook) {
						this.DisableCreditBookIfSettled($scope);
					}
				}
			}).catch((err) => {
				setTimeout(() => { $scope._ams.loaderEnable.next("") }, 1000);
				$scope.HandlePostSaleFailure(err);
			});
		}
	}

	async SetMemberPin(memberpin, scope: PaymentComponent) {
		scope.Memberpin = memberpin;
		let requirePinFolioMemConfig = this._ss.folioMemberConfig?.find(x => x.configurationKey.toLowerCase() == ConfigKeys.Member.RequirePin.toLowerCase())?.configurationValue?.toLowerCase() == 'true' ? true : false;
		const requirePin = scope?.IsResortFinanceMakePaymentFlow ? requirePinFolioMemConfig : this.RequirePin; 
		if ((memberpin && memberpin.length === 4) || !requirePin) {
			scope.ConfirmationPopupShown = true;
			scope._pblh.MakeSale();
			return;
		}
	}

	async SetMemberPinValue(memberpin, scope) {
		scope.Memberpin = memberpin;
		if (!scope.IsProceedReturn && ((memberpin && memberpin.length == 4) || !this.RequirePin)) {
			scope.ReturnwithTicket();
			return;
		}
		if (scope.IsProceedReturn && ((memberpin && memberpin.length == 4) || !this.RequirePin)) {
			scope._sbs.MakeSale(scope, scope.returnTransitionDetails);
			scope.IsProceedReturn = false;
			return;
		}
	}

	async ACESMemberPayment(memberPin, payeeInfo: PayeeInfo, gridItems, arpostamount: number, retailTicketNumber: string, checkNumber: string
		, financialBins: any[], multiplePayment: boolean, settlementHistory: PaymentHistory[], paymentMethod: PaymentMethod, callBack: Function
		, isRWT: boolean = false, transactionInput: TransactionInput = null, multipleMemberPayment = false, selectedMember: MemberPayeeInfo = null
		, compScope = null, isTaxExempted = false, isNonShop = false) {
		const ticketData = this._ss.Ticket;
		let arposttendertype = '';
		let taxAmount = 0;
		const tenderinfo: MemberTenderInfo[] = [];
		const itemInfo: MemberItemInfo[] = [];
		this.memberAPIProgressNotifier.emit(true);
		if(this.memberCardNumber != "0"){
			this._ss.memberCardNumber = this.memberCardNumber;
		}
		const corpId = this._ss.sourceCorpId ? this._ss.sourceCorpId : 0;
		const memberCardNumber = multipleMemberPayment && selectedMember ? selectedMember.memberCardNumber : this._ss.memberCardNumber;
		let isResortFinancePayFlow : boolean = compScope?.$scope?.IsResortFinanceMakePaymentFlow ?? false;
		const _memberInfo: PayeeInfo = await this.getMemberInfo(
			memberCardNumber != "0" && memberCardNumber,
			this.getScheduleDateInUTC(this._ss.SelectedPlayers), false, true, isResortFinancePayFlow, corpId);
		
		let selectedOutlet;
		if (isResortFinancePayFlow) {
			retailTicketNumber = compScope?.$scope?.AdditionalInputs?.folioId;
			selectedOutlet = compScope?.$scope?.AdditionalInputs.selectedOutlet
		}
		else {
			selectedOutlet = this._ss.GetSelectedOutletDetail();
		}
		this.memberAPIProgressNotifier.emit(false);
		let holes = 0, teetimeId = 0, courseName = '', rateType = '', scheduleDate = '';

		this._ss.SelectedPlayers.forEach((x) => {
			if (payeeInfo && payeeInfo.id == x.playerId) {
				rateType = x.rateType;
				scheduleDate = (new Date(x.scheduledAPIDateTime || "")).toISOString()
				holes += x.holes;
				courseName = x.course;
				teetimeId = x.scheduledTeeTimeId
			}
		});

		if (_memberInfo == null) { return; }

		_memberInfo.id = payeeInfo ? payeeInfo.id : 0;
		payeeInfo = _memberInfo;		

		const bucket: BalanceList = payeeInfo.balanceList
			? payeeInfo.balanceList.find((x) => x.BucketName.replace(/\s/g, '').toLowerCase() === this.GetBucketName(paymentMethod, isResortFinancePayFlow))
			: null;

		if (bucket != null) {
			arposttendertype = bucket.BucketCode;
		} else {
			this.dialog.closeAll();
			this._utils.ShowError(
				this.localization.captions.common.Error,
				this.localization.captions.common.ARPostNotAvailableForMember
			);
			return;
		}

		const taxAmt = ticketData ? ticketData?.checkData?.totalTax : 0;
		if (this._ss.SelectedPlayers && this._ss.SelectedPlayers.length >= 1 && !multipleMemberPayment
			&& (!multiplePayment || (multiplePayment && settlementHistory.filter((x) => x.paymentMethodId === PaymentMethods.ARPost && !x.isReversed).length == 0))) {
			let round = 0;
			let courseDetails = '';
			this._ss.SelectedPlayers.forEach((x) => {
				if (x.playerId === this._ss.selectedPayeeId) {
					round += x.holes === 18 ? 1 : 0.5;
				}
				courseDetails = x.course;
			});
			const golfbucket = payeeInfo.golfPoints
				? payeeInfo.golfPoints.find((x) => x.bucketName.replace(/\s/g, '').toLowerCase() === courseDetails.replace(/\s/g, '').toLowerCase())
				: null;

			this._ss.SelectedPlayers.forEach(player => {
				const playergriditems = gridItems.filter(x => x.element.payeeId == player.playerId);
				let amount = 0;
				let tax = 0;
				const tenderInformation = [];
				for (const element of playergriditems) {
					const itemQuantity: number = element.quantity == '' ? 0 : Number(element.quantity);
					const gratuity =
						element.element.Gratuity && element.element.Gratuity.gratuity
							? parseFloat(element.element.Gratuity.gratuity)
							: 0;
					const servCharge =
						element.element.ServiceCharge && element.element.ServiceCharge.ServiceCharge
							? parseFloat(element.element.ServiceCharge.ServiceCharge)
							: 0;
					const itemPrice = parseFloat(element.unitprice) * itemQuantity + gratuity + servCharge;
					amount += itemPrice;
					const item = ticketData?.lineItems?.find(x => x.index == 1);
					tax += item ? item.tax : 0;
				}
				tenderInformation.push({ TenderType: arposttendertype, Amount: amount + tax, ReferenceId: null })
				const arpostpaymentinfo: MemberPaymentInfo = {
					TotalAmount: amount,
					TaxAmount: tax,
					Gratuity: 0.0,
					TenderInfo: tenderInformation
				}
				itemInfo.push({
					GuestCardNo: player.playerCategoryId === 3 ? player.playerLinkId : "-1",
					ItemGroupName: "",
					Price: amount + tax,
					Quantity: 1,
					TransactionRef: retailTicketNumber,
					ProductClass: "",
					ProductClassID: 0,
					RateType: player.rateType,
					CourseCode: golfbucket ? golfbucket.bucketCode : "",
					CourseName: player.course,
					ItemGroupID: 0,
					ItemId: 0,
					ItemName: "",
					Holes: player.holes,
					TeeTimeId: player.scheduledTeeTimeId,
					GuestName: player.playerName,
					PaymentInfo: arpostpaymentinfo
				})
			});
		}
		else if (this._ss.SelectedPlayers && this._ss.SelectedPlayers.length >= 1 && multipleMemberPayment
			&& (selectedMember.playerId > 0 || (selectedMember.playerId == 0 && (selectedMember.associatedMembers?.length > 0 || selectedMember.associatedGuests?.length > 0)))) {
			let playerIds: number[] = [selectedMember.playerId];
			if (selectedMember.associatedGuests?.length) {
				playerIds = [...playerIds, ...selectedMember.associatedGuests.map(x => x.playerId)];
			}
			if (selectedMember.associatedMembers?.length) {
				playerIds = [...playerIds, ...selectedMember.associatedMembers.map(x => x.playerId)];
			}

			const selectedPlayers = this._ss.SelectedPlayers.filter(x => playerIds.includes(x.playerId));
			if (!selectedPlayers) { return; }

			let round = 0;
			const courseDetails = selectedPlayers[0].course;

			playerIds.forEach(x => {
				const sp = selectedPlayers.find(s => s.playerId === x);
				if (sp) {
					round += sp.holes === 18 ? 1 : 0.5;
				}
			});

			const golfbucket = payeeInfo.golfPoints
				? payeeInfo.golfPoints.find((x) => x.bucketName.replace(/\s/g, '').toLowerCase() === courseDetails.replace(/\s/g, '').toLowerCase())
				: null;

			const lineNumbers: number[] = this._ss.selectedProducts.filter(x => playerIds.includes(x.payeeId)).map(x => x.LineNumber);
			if (lineNumbers && lineNumbers.length) {
				const items = this._ss.Ticket.lineItems.filter(s => lineNumbers.includes(s.index));
				taxAmount = items ? _.sum(items.map(x => x.tax)) : 0;
			}

			const tenderInformation = [];
			tenderInformation.push({ TenderType: arposttendertype, Amount: selectedMember.memberAmount, ReferenceId: null })
			const arpostpaymentinfo: MemberPaymentInfo = {
				TotalAmount: arpostamount - taxAmount,
				TaxAmount: taxAmount,
				Gratuity: 0.0,
				TenderInfo: tenderInformation
			}

			const player = this._ss.SelectedPlayers.find(x => x.playerId === selectedMember.playerId);
			if (player) {
				itemInfo.push({
					GuestCardNo: player.playerCategoryId === 3 ? player.playerLinkId : "-1",
					ItemGroupName: "",
					Price: this.getPlayerAmount(this._ss.selectedProducts, player.playerId, isTaxExempted),
					Quantity: 1,
					TransactionRef: retailTicketNumber,
					ProductClass: "",
					ProductClassID: 0,
					RateType: player.rateType,
					CourseCode: golfbucket ? golfbucket.bucketCode : "",
					CourseName: player.course,
					ItemGroupID: 0,
					ItemId: 0,
					ItemName: "",
					Holes: player.holes,
					TeeTimeId: player.scheduledTeeTimeId,
					GuestName: player.playerName,
					PaymentInfo: arpostpaymentinfo
				});
			}

			if (selectedMember.associatedGuests?.length > 0) {
				selectedMember.associatedGuests.forEach(guest => {
					itemInfo.push(this.mapItemInfoForAssociatedGuest(guest, selectedMember, retailTicketNumber, golfbucket, arpostpaymentinfo, isTaxExempted));
				});
			}

			if (selectedMember.associatedMembers?.length > 0) {
				selectedMember.associatedMembers.forEach(guest => {
					itemInfo.push(this.mapItemInfoForAssociatedMember(guest, selectedMember, retailTicketNumber, golfbucket, arpostpaymentinfo, isTaxExempted));
				});
			}
		}
		else {
			tenderinfo.push({ TenderType: arposttendertype, Amount: arpostamount, ReferenceId: null })
			const arpostpaymentinfo: MemberPaymentInfo = {
				TotalAmount: arpostamount,
				TaxAmount: ticketData ? ticketData?.checkData?.totalTax : 0,
				Gratuity: 0.0,
				TenderInfo: tenderinfo
			}
			itemInfo.push({
				GuestCardNo: memberCardNumber,
				ItemGroupName: "",
				Price: arpostamount,
				Quantity: 1,
				TransactionRef: retailTicketNumber,
				ProductClass: "",
				ProductClassID: 0,
				RateType: '',
				CourseCode: "",
				CourseName: '',
				ItemGroupID: 0,
				ItemId: 0,
				ItemName: "",
				Holes: 0,
				TeeTimeId: 0,
				GuestName: '',
				PaymentInfo: arpostpaymentinfo
			})
		}

		// financialBins
		let acesfinancialbin = [];
		if (financialBins?.length > 0 ) {
			financialBins.forEach((element) => { element.Id = Math.abs(element.Id); });
			acesfinancialbin = this.ConstructFinancialBinForPost(financialBins);
		} else {
			if(transactionInput){
				transactionInput.parentTenderId = compScope?.selectedPaymentMethod?.parentTypeId??0;
				acesfinancialbin = await this.getFinancialBin(transactionInput);
			}
		}

		const checkinfo: MemberCheckInfo = {
			PaymentAmount: arpostamount,
			TipAmount: 0.0,
			TerminalGroup: "",
			TaxAmount: multipleMemberPayment ? taxAmount : taxAmt,
			Amount: arpostamount - (multipleMemberPayment ? taxAmount : taxAmt),
			ItemInfo: itemInfo,
			FinancialBins: acesfinancialbin
		}
		const postingInfo: PostingInfo = {
			PostingId: retailTicketNumber,
			ClientId: (selectedOutlet) ? (selectedOutlet.subPropertyName) : '',
			PaymentMethodId: 7,
			PostToPropertyId: 0
		}
		const outletInfo: ACESOutletInfo = {
			Id: (selectedOutlet) ? (selectedOutlet.subPropertyID) : 0,
			Code: (selectedOutlet) ? (selectedOutlet.subPropertyCode) : '',
			Description: (selectedOutlet) ? (selectedOutlet.subPropertyName) : '',
		}
		const ACESPaymentDetails: ACESMemberPayment = {
			SourceCorpId: corpId,
			SourceSiteId: 0,
			SourceSystemCode: '',
			GuestCardNo: multipleMemberPayment ? selectedMember.memberCardNumber : this._ss.memberCardNumber,
			Pin: memberPin,
			ProfitCentre: (selectedOutlet) ? (selectedOutlet.profitCenter) : '',
			TerminalGroup: null,
			TerminalId: null,
			ServerEmpID: "",
			IsOffline: false,
			CheckInfo: checkinfo,
			PromoInfo: null,
			SiteID: 0,
			RoleID: 0,
			UserID: 0,
			CorpID: 0,
			StatusCode: 0,
			SaveType: 0,
			DeviceType: 0,
			PostingInfo: postingInfo,
			OutletInfo: outletInfo,
			IsResortFinancePaymentFlow: isResortFinancePayFlow,
			IsNonShop: isNonShop
		};

		this.memberAPIProgressNotifier.emit(true);
		const Response = await this.MemberPayment(ACESPaymentDetails);
		if (Response && Response.result && Response.result.SuccessCode === 1) {
			let memberInfo;
			if (this._ss.SelectedPlayers && this._ss.SelectedPlayers.length >= 1 && (!multiplePayment || (multiplePayment && settlementHistory.filter((x) =>
				x.paymentMethodId === PaymentMethods.ARPost && !x.isReversed).length === 0)) && !multipleMemberPayment) {
				this.memberAPIProgressNotifier.emit(true);
				const memInfo = await RetailDataAwaiters.getMemberInfo(
					this._ss.memberCardNumber,
					this.getScheduleDateInUTC(this._ss.SelectedPlayers), corpId
				);
				memberInfo = memInfo;
				this.memberAPIProgressNotifier.emit(false);
				this.setAvalableRounds(memInfo);
			} else if(multipleMemberPayment && compScope.$scope.memberGuestList?.length) {
				this.memberAPIProgressNotifier.emit(true);
				const date : Date = compScope.PropertyInfo.CurrentDate;
				const memInfo = await RetailDataAwaiters.getMemberInfo(
					memberCardNumber,
					date.toISOString(), corpId
				);
				memberInfo = memInfo;
				const member = compScope.$scope.memberGuestList.find(m => m.memberCardNumber === memberCardNumber);
				if(member){
					member['currentAvlRounds'] = memInfo && memInfo.golfPoints.length > 0 ? memInfo.golfPoints[0].eligibleRounds : 0;
				}
				this.memberAPIProgressNotifier.emit(false);
			}
			const ACESRecord: ACESPaymentRecord = {
				paymentTransactionId: 0,
				transactionId: retailTicketNumber,
				acesTransactionid: (Response.result.tranID || '').toString(),
				arAccountNumber: _memberInfo.arAccountNumber,
				memberId: multipleMemberPayment ? selectedMember.memberCardNumber : this._ss.memberCardNumber,
				tenderType: arposttendertype,
				amount: arpostamount,
				paymentMethodType: paymentMethod.paymentTypeId,
				memberPin: memberPin,
				tenderId: PaymentMethods.ARPost,
				outletId: this._ss.SelectedOutletId,
				ticketNumber: retailTicketNumber,
				checkNumber: checkNumber,
				availableRounds:  multipleMemberPayment ? (this._ss.SelectedPlayers && this._ss.SelectedPlayers.length >= 1) ? (memberInfo && memberInfo.golfPoints.length > 0) ? memberInfo.golfPoints[0].eligibleRounds : 0 : selectedMember.availableRounds 
				: (this._ss.SelectedPlayers && this._ss.SelectedPlayers.length >= 1) ? (memberInfo && memberInfo.golfPoints.length > 0) ? memberInfo.golfPoints[0].eligibleRounds : 0 : this._ss.availableRounds,
				memberName: _memberInfo.name,
				sourceCorpId: corpId
			};
			callBack([ACESRecord], true);
			this.memberAPIProgressNotifier.emit(false);

		} else {
			this.memberAPIProgressNotifier.emit(false);
			if (compScope) {
				compScope.$scope.ConfirmationPopupShown = false;
				compScope.$scope.multipleMemberPayment = false;
				compScope.$scope.payeeInfo = compScope.$scope.original_payeeInfo;
			}
			if (Response && Response.result?.ErrorMessage) {
				this.dialog.closeAll();
				this._utils.ShowError(this.localization.captions.common.Error, Response.result.ErrorMessage);
				return;
			} else {
				this.dialog.closeAll();
				this._utils.ShowError(
					this.localization.captions.common.Error,
					this.localization.replacePlaceholders(
						this.ShopCaptions.PaymentTransactionFailureMsg,
						['paymentmethod'],
						[this.localization.captions.shop.paymentMethods[paymentMethod.paymentTypeId]]
					)
				);
			}
		}
	}
	mapItemInfoForAssociatedGuest(guest: GuestMemberPayeeInfo, selectedMember: MemberPayeeInfo, retailTicketNumber: string, golfbucket: GolfPoints,
		arpostpaymentinfo: MemberPaymentInfo, isTaxExempted: boolean): MemberItemInfo {
		const member = this._ss.SelectedPlayers.find(x => x.playerId === guest.playerId);
		return {
			GuestCardNo: this.redeemRoundsForGuests ? selectedMember.memberCardNumber : member.playerCategoryId === 3 ? member.playerLinkId : "-1",
			ItemGroupName: "",
			Price: this.getPlayerAmount(this._ss.selectedProducts, member.playerId, isTaxExempted),
			Quantity: 1,
			TransactionRef: retailTicketNumber,
			ProductClass: "",
			ProductClassID: 0,
			RateType: member.rateType,
			CourseCode: golfbucket ? golfbucket.bucketCode : "",
			CourseName: member.course,
			ItemGroupID: 0,
			ItemId: 0,
			ItemName: "",
			Holes: member.holes,
			TeeTimeId: member.scheduledTeeTimeId,
			GuestName: member.playerName,
			PaymentInfo: arpostpaymentinfo
		} as MemberItemInfo;
	}

	mapItemInfoForAssociatedMember(guest: GuestMemberPayeeInfo, selectedMember: MemberPayeeInfo, retailTicketNumber: string, golfbucket: GolfPoints,
		arpostpaymentinfo: MemberPaymentInfo, isTaxExempted: boolean): MemberItemInfo {
		const member = this._ss.SelectedPlayers.find(x => x.playerId === guest.playerId);
		return {
			GuestCardNo: this.redeemRoundsForGuests ? selectedMember.memberCardNumber : member.playerCategoryId === 3 ? Number(member.playerLinkId) : -1,
			ItemGroupName: "",
			Price: this.getPlayerAmount(this._ss.selectedProducts, member.playerId, isTaxExempted),
			Quantity: 1,
			TransactionRef: retailTicketNumber,
			ProductClass: "",
			ProductClassID: 0,
			RateType: member.rateType,
			CourseCode: golfbucket ? golfbucket.bucketCode : "",
			CourseName: member.course,
			ItemGroupID: 0,
			ItemId: 0,
			ItemName: "",
			Holes: member.holes,
			TeeTimeId: member.scheduledTeeTimeId,
			GuestName: member.playerName,
			PaymentInfo: arpostpaymentinfo
		} as MemberItemInfo;
	}

	ConstructFinancialBinForPost(financialBin: any[]) {
		const financialBins: ACESFinancialbin[] = [];
		financialBin.forEach(x => {
			const bin: ACESFinancialbin = {
				amount: x.amount,
				typeId: x.id,
				classId: String(x.type)
			};
			financialBins.push(bin);
		});
		return financialBins;
	}

	getPlayerAmount(selectedProducts: SelectedProducts[], playerId: number, isTaxExempted: boolean): number {
		const lineNumbers: number[] = selectedProducts.filter(x => x.payeeId === playerId).map(x => x.LineNumber);
		if (!lineNumbers) { return 0 };
		const items = this._ss.Ticket.lineItems.filter(s => lineNumbers.includes(s.index));
		let playerAmt = 0;
		if (items) {
			playerAmt = !isTaxExempted ? _.sum(items.map(x => x.netPrice)) + _.sum(items.map(x => x.gratuity)) + _.sum(items.map(x => x.serviceCharge)) + _.sum(items.map(x => x.tax))
				+ _.sum(items.map(x => x.gratuityTax)) + _.sum(items.map(x => x.serviceChargeTax)) - items.reduce((ty, u) => ty + u.discount, 0) - items.reduce((ty, u) => ty + u.weightedDiscount, 0)
				: _.sum(items.map(x => x.netPrice)) + _.sum(items.map(x => x.gratuity)) + _.sum(items.map(x => x.serviceCharge))
				+ _.sum(items.map(x => x.gratuityTax)) + _.sum(items.map(x => x.serviceChargeTax)) - items.reduce((ty, u) => ty + u.discount, 0) - items.reduce((ty, u) => ty + u.weightedDiscount, 0)

		}
		return playerAmt;
	}

	ShowMemberSelectionAndPinPopup(compScope: PaymentBusinessLogicHandler, members: any[], totalAmount, currentSaleAmt, payeeInfo: PayeeInfo) {
		try {			
			const otherGuests = this._ss.SelectedPlayers.length > 0 ? this._ss.SelectedPlayers.filter(x => x.playerCategoryId !== PlayerCategory.Member)
				: this._ss.selectedProducts.filter(x => x.clientType != undefined  && x.clientType != 0 && x.clientType != GlobalConst.ClientType.Member && !(x.ItemType == RetailItemType.Deposit));
			const memberSelectionCaptions = this.localization.captions.shop.MemberSelectionPopup;
			const popupData = {
				totalAmount,
				remainingAmount: compScope.$scope.remainingAmount,
				amountEntered: currentSaleAmt,
				settledAmt: compScope.$scope.SettledAmt,
				members: [],
				guests: [],
				selectedMemberFromSearch: [],
				availableGuestMember: [],
				scope: this,
				allowAmountTender: (compScope.$scope.SettlementHistory.some(x => !x.isReversed)),
				isTaxExempted: compScope.$scope.isTaxExempted,
				settlementHistory: compScope.$scope.SettlementHistory
			}
			if (members.length == 0 && payeeInfo && payeeInfo?.playerCategoryId == PlayerCategory.Member) {
				popupData.members.push({
					playerId: payeeInfo.id,
					processed: false,
					memberName: payeeInfo.firstName + ' ' + payeeInfo.lastName,
					memberCardNumber: payeeInfo.guestProfileId,
					memberAmount: 0,
					memberPin: "",
					selected: false,
					disabled: false,
					payeeInfo: payeeInfo,
					associatedGuests: [],
					associatedMembers: [],
					associatedItems: []
				})
			}
			else {
				members.map(x =>
					popupData.members.push({
						playerId: x.playerId || x.clientId,
						processed: false,
						memberName: x.playerName || x.clientName,
						memberCardNumber: x.playerLinkId || x.clientLinkId,
						memberAmount: this.getPlayerAmount(compScope._ss.selectedProducts, x.playerId || x.clientId, compScope.$scope.isTaxExempted),
						memberPin: "",
						selected: false,
						disabled: false,
						payeeInfo: this.MapToPayeeInfo(x),
						associatedGuests: [],
						associatedMembers: [],
						associatedItems: []
					})
				);
				if (members.length > 0 && payeeInfo && payeeInfo?.playerCategoryId == PlayerCategory.Member && !popupData.members.some(x => x.memberCardNumber == payeeInfo.guestProfileId)) {
					popupData.members.push({
						playerId: 0,
						processed: false,
						memberName: payeeInfo.firstName + ' ' + payeeInfo.lastName,
						memberCardNumber: payeeInfo.guestProfileId,
						memberAmount: 0,
						memberPin: "",
						selected: false,
						disabled: false,
						payeeInfo: payeeInfo,
						associatedGuests: [],
						associatedMembers: [],
						associatedItems: []
					})
				}
			}
			
			otherGuests.map(x => popupData.guests.push({
				playerId: x.playerId || x.clientId,
				processed: false,
				guestId: x.playerId || x.clientId,
				guestName: x.playerName || x.clientName,
				selected: false,
				selectedMemberId: 0,
				selectedPlayerId: 0,
				isMember: false,
				payeeInfo: this.MapToPayeeInfo(x),
				amount: this.getPlayerAmount(compScope._ss.selectedProducts, x.playerId || x.clientId, compScope.$scope.isTaxExempted),
			}));
			const dialogRef = this.dialog.open(MemberSelectionPopupComponent, {
				width: '100%',
				height: '100%',
				maxWidth: '85vw',
				maxHeight: "85vh",
				hasBackdrop: true,
				panelClass: 'action-dialog-overlay',
				data: {
					headername: memberSelectionCaptions.lbl_header,
					closebool: true,
					isViewOnly: false,
					datarecord: popupData
				},
				disableClose: true
			});

			dialogRef.afterClosed().subscribe(result => {
				if (result) {
					this._utils.ToggleLoader(false)
					compScope.$scope.ConfirmationPopupShown = true;
					compScope.$scope.multiplePayment = true;
					compScope.$scope.multipleMemberPayment = true;
					console.log("Members result", result.members);
					compScope.$scope.original_payeeInfo = _.cloneDeep(compScope.$scope.payeeInfo);
					this.ProceedMemberPayments(compScope, result);
				} else {
					compScope.$scope.isAPICallInProgress = false;
				}
			});
		} catch (error) {
			console.error("Error occurred while processing Member selection" + error);
		}
	}

	AddselectedMembersFrmSearch(members: MemberPayeeInfo[], selectedMemberFromSearch: SelectedMemberFromSearch[]) {
		if (selectedMemberFromSearch && selectedMemberFromSearch.length) {
			selectedMemberFromSearch.forEach(m => {
				members.push({
					memberAmount: m.memberAmount,
					memberCardNumber: m.memberCardNumber,
					memberPin: m.memberPin,
					memberName: m.memberName,
					payeeInfo: m.payeeInfo,
					playerId: 0,
					processed: false,
					disabled: false,
					selected: m.selected,
					selectedFromSearch: false,
					isPlayer: false,
					associatedMembers: [],
					associatedGuests: [],
					associatedItems: m.associatedItems,
				    availableRounds: m.availableRounds
				})
			});
		}
	}

	ProceedMemberPayments(compScope, result: GuestMemberResult) {
		try {
			if (result) {
				const isMutliplePayment = compScope.$scope.multiplePayment && compScope.$scope.tempSettlementHistory.filter((x) => !x.isReversed);
				const memberGuestList = result.members.filter(x => x.selected && !x.selectedFromSearch);
				const selectedMemberFrmSearch = result.selectedMemberFromSearch?.some(x => x.selected);
				if (selectedMemberFrmSearch && ((result.availableGuestMember
					 && result.availableGuestMember.some(x => x.selected)
					|| result.selectedMemberFromSearch.some(x => x.selected && x.associatedItems?.length > 0)) || isMutliplePayment)) {
					this.AddselectedMembersFrmSearch(memberGuestList, result.selectedMemberFromSearch);
				}

				memberGuestList.forEach(m => {
					m.memberAmount = this.localization.currencyToSQLFormat(m.memberAmount.toString());
				});

				if (memberGuestList && result.availableGuestMember && result.availableGuestMember.some(x => x.selected)) {
					memberGuestList.map(m=>{
						m.associatedGuests = result.availableGuestMember.filter(x => x.selected && !x.isMember && x.selectedMemberId == m.memberCardNumber && x.selectedPlayerId == m.playerId);
						m.associatedMembers = result.availableGuestMember.filter(x => x.selected && x.isMember && x.selectedMemberId == m.memberCardNumber && x.selectedPlayerId == m.playerId);
					});
				}
				compScope.$scope.memberGuestList = memberGuestList;
				compScope.$scope.Memberpin = memberGuestList[0].memberPin;
				compScope.$scope.payeeInfo = memberGuestList[0].payeeInfo;
				compScope.$scope.selectedMember = memberGuestList[0];
				this.callbackForMultipleMember = compScope.$scope._memberService.ProceedMultiplePaymentsForMembers.bind(this);
				compScope.MakeSale()
			}

		} catch (error) {
			console.error("Error occurred while ProceedMemberPayments" + error);
		}
	}

	ProceedMultiplePaymentsForMembers(compScope) {
		try {
			const paymentHandler: PaymentBusinessLogicHandler = compScope;
			this.callbackForMultipleMember = null;
			const HandleMultipleMemberPayment = (payMember: MemberPayeeInfo) => {
				paymentHandler.switchToMultiMemberPayment(payMember);
			}
			const memberGuestList: MemberPayeeInfo[] = paymentHandler.$scope.memberGuestList;
			const memberToBeProcessed = memberGuestList.find(x => !x.processed);
			if (memberToBeProcessed) {
				HandleMultipleMemberPayment(memberToBeProcessed);
			} else {
				paymentHandler.$scope.selectedMember = null;
				return;
			}

		} catch (error) {
			console.error("Error occurred while ProceedMultiplePaymentsForMembers" + error);
		}
	}

}

export interface GuestMemberResult {
	guests: GuestMemberPayeeInfo[];
	members: MemberPayeeInfo[];
	availableGuestMember: GuestMemberPayeeInfo[];
	selectedMemberFromSearch: SelectedMemberFromSearch[];
}

export interface SelectedMemberFromSearch {
	memberAmount: number;
	memberCardNumber: string;
	memberName: string;
	memberPin: string;
	selected: boolean;
	availableRounds: number;
	payeeInfo: PayeeInfo;
	associatedItems?: SelectedProducts[];
}
export interface GuestMemberPayeeInfo {
	amount: number;
	guestId: number;
	guestName: string;
	payeeInfo: PayeeInfo;
	playerId: number;
	processed?: boolean;
	isMember: boolean;
	selected: boolean;
	availableRounds: number;
	selectedMemberId: string;
	selectedPlayerId?: number;
}

export interface MemberPayeeInfo {
	selectedFromSearch: boolean;
	disabled: boolean;
	memberAmount: number;
	memberCardNumber: string;
	memberPin: string;
	memberName: string;
	payeeInfo: PayeeInfo;
	playerId: number;
	processed: boolean;
	selected: boolean;
	isPlayer?: boolean;
	availableRounds: number;
	currentAvlRounds?: number;
	showAmountValidation?: boolean;
	associatedMembers?: GuestMemberPayeeInfo[];
	associatedGuests?: GuestMemberPayeeInfo[];
	associatedItems?: SelectedProducts[];
}


