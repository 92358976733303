<div class="v-cart-wrapper">
  <h4 class="ag_group--headers custom-align-header ag_display--flex justify-content-between align-items-center">
    <div class="ag_display--flex mt-1">
      <span class="ag_display--flex">{{captions.lbl_vcartTiltle}}<pre class="mb-0" *ngIf="activeConfirmationId"> - </pre> {{activeConfirmationId}}</span>
    </div>
    <div class="ag_display--flex align-items-center">
      <a class='ag_link mr-2 ag_cursor--pointer view-all-color-style'  *ngIf="hasAccess"
      (click)='viewAllItineraries()'>{{captions.lbl_view_all_itineraries}}</a>
      <i class="ag_pr--2 icon-reset ag_cursor--pointer" (click)="refreshCart()"></i>
      <i class="icon-maximize screen-expand-icon ag_cursor--pointer" (click)="expandCart()"></i>
    </div>
</h4>
  <!-- <div *ngIf="cartwarningList?.length>0" class="error-list-wrapper">
    <ul class="error-list-looper">
      <li *ngFor="let x of cartwarningList">
        <i class="icon-warning-icon"></i>{{x}}
      </li>
    </ul>
  </div> -->
  <div class="simple-search-section-wrapper">
    <app-simple-search [placeholder]="captions.lbl_CartItinerarySearch" (searchChange)="searchValueChange($event)" [automationId]="'vcart'">
    </app-simple-search>
  </div>
  <div class="accordian-sect-wrapper" cdkDropListGroup cdkScrollable>
    <ng-container *ngIf="itineraryList?.length>0">
      <div class="looper-sect" [ngClass]="[!x.isSearched?'hide-sect':'',x.iscartExpand?'loop-active':'']" 
        *ngFor="let x of itineraryList;let i = index"
        [ngStyle]="{'border-color':x.isMember?x.memberTypeColorCode:!x.isMember?x.memberLevelColorCode:'' }" 
        [ngClass]="[!x.isSearched?'hide-sect':'',x.iscartExpand?'loop-active':'']">
        <div class="ag_display--flex border-sect" [ngClass]="[x.expanded? 'currentActiveCart' : '']">
          <div class="ag_display--flex-wrap new-layout-header">
            <h4 (click)="opnExpand(x)" [ngClass]="x.iscartExpand?'expanded':''" attr.automationId='Icn_vcart_cartExpand{{i}}'
              class=" ag_cursor--pointer ag_group--headers ag_w--100 custom-headers">
              <i class="icon-filled-down-arrow"></i> <i class="icon-File-Path"></i> <span
                class="text-precies custom-text-precies" [matTooltip]="x.confirmationId">{{x.confirmationId}}</span>
              <i class=" ag_cursor--pointer ag_ml--1" [matTooltip]="'Activate Cart'"
                [ngClass]="[x.expanded?'icon-Activated_Cart_Selected':'icon-Activate_Cart_Unselected']"
                (click)="activateCart($event,x)" attr.automationId='Icn_vcart_cartExpand{{i}}'></i>
              <ng-container *ngIf="x.cartNotifications && x.cartNotifications.length > 0">
                <i class="icon-Payment-Link-Sent ag_ml--1" [matTooltip]="captions.lbl_paymentLink"></i>
              </ng-container>
              <ng-container *ngIf="x.activeCardOnFile">
                <div class='card-type-container ag_ml--1'>
                  <div class='card-type' attr.automationId='Icn_vcart_cardType{{i}}' (click)="opencardType(x)" [ngSwitch]="x.activeCardOnFile?.cardCode">
                    <span *ngSwitchCase="'visa'" [matTooltip]="x.activeCardOnFile?.cardMasked"
                      class="icon-visa"><span class="path1"></span><span class="path2"></span><span
                        class="path3"></span><span class="path4"></span><span class="path5"></span><span
                        class="path6"></span><span class="path7"></span></span>
                        <span *ngSwitchCase="'mastercard'" [matTooltip]="x.activeCardOnFile?.cardMasked"
                        class="icon-master-card"><span class="path1"></span><span class="path2"></span><span
                          class="path3"></span><span class="path4"></span><span class="path5"></span><span
                          class="path6"></span><span class="path7"></span><span class="path8"></span><span
                          class="path9"></span><span class="path10"></span><span class="path11"></span><span
                          class="path12"></span><span class="path13"></span><span class="path14"></span><span
                          class="path15"></span><span class="path16"></span><span class="path17"></span><span
                          class="path18"></span><span class="path19"></span><span class="path20"></span><span
                          class="path21"></span><span class="path22"></span></span>
                    <!-- <span *ngSwitchCase="'hsbc'" [matTooltip]="x.activeCardOnFile?.cardMasked" class="icon-hsbc"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span> -->
                    <span *ngSwitchCase="'maestro'" [matTooltip]="x.activeCardOnFile?.cardMasked"
                      class="icon-Maestro"><span class="path1"></span><span class="path2"></span><span
                        class="path3"></span><span class="path4"></span></span>
                    <span *ngSwitchCase="'jcb'" [matTooltip]="x.activeCardOnFile?.cardMasked"
                      class="icon-JCB"><span class="path1"></span><span class="path2"></span><span
                        class="path3"></span><span class="path4"></span><span class="path5"></span><span
                        class="path6"></span></span>
                    <span *ngSwitchCase="'interac'" [matTooltip]="x.activeCardOnFile?.cardMasked"
                      class="icon-Interac"><span class="path1"></span><span class="path2"></span><span
                        class="path3"></span><span class="path4"></span><span class="path5"></span><span
                        class="path6"></span><span class="path7"></span><span class="path8"></span><span
                        class="path9"></span><span class="path10"></span><span class="path11"></span><span
                        class="path12"></span><span class="path13"></span><span class="path14"></span><span
                        class="path15"></span><span class="path16"></span></span>
                    <span *ngSwitchCase="'discover'" [matTooltip]="x.activeCardOnFile?.cardMasked"
                      class="icon-Discover"><span class="path1"></span><span class="path2"></span><span
                        class="path3"></span><span class="path4"></span><span class="path5"></span><span
                        class="path6"></span><span class="path7"></span><span class="path8"></span><span
                        class="path9"></span><span class="path10"></span><span class="path11"></span><span
                        class="path12"></span><span class="path13"></span><span class="path14"></span><span
                        class="path15"></span><span class="path16"></span><span class="path17"></span><span
                        class="path18"></span><span class="path19"></span><span class="path20"></span><span
                        class="path21"></span><span class="path22"></span><span class="path23"></span><span
                        class="path24"></span><span class="path25"></span><span class="path26"></span><span
                        class="path27"></span><span class="path28"></span><span class="path29"></span></span>
                    <span *ngSwitchCase="'dinersclub'" [matTooltip]="x.activeCardOnFile?.cardMasked"
                      class="icon-Diners-Club"><span class="path1"></span><span class="path2"></span><span
                        class="path3"></span><span class="path4"></span><span class="path5"></span><span
                        class="path6"></span><span class="path7"></span><span class="path8"></span><span
                        class="path9"></span><span class="path10"></span><span class="path11"></span><span
                        class="path12"></span><span class="path13"></span><span class="path14"></span><span
                        class="path15"></span><span class="path16"></span><span class="path17"></span><span
                        class="path18"></span><span class="path19"></span><span class="path20"></span><span
                        class="path21"></span><span class="path22"></span><span class="path23"></span><span
                        class="path24"></span><span class="path25"></span><span class="path26"></span><span
                        class="path27"></span><span class="path28"></span><span class="path29"></span><span
                        class="path30"></span><span class="path31"></span><span class="path32"></span><span
                        class="path33"></span><span class="path34"></span><span class="path35"></span><span
                        class="path36"></span><span class="path37"></span><span class="path38"></span><span
                        class="path39"></span></span>
                    <span *ngSwitchCase="'chinaunionpay'" [matTooltip]="x.activeCardOnFile?.cardMasked"
                      class="icon-China-UnionPay"><span class="path1"></span><span class="path2"></span><span
                        class="path3"></span><span class="path4"></span><span class="path5"></span><span
                        class="path6"></span><span class="path7"></span><span class="path8"></span><span
                        class="path9"></span><span class="path10"></span><span class="path11"></span><span
                        class="path12"></span><span class="path13"></span><span class="path14"></span><span
                        class="path15"></span><span class="path16"></span><span class="path17"></span><span
                        class="path18"></span><span class="path19"></span></span>
                    <span *ngSwitchCase="'americanexpress'" [matTooltip]="x.activeCardOnFile?.cardMasked"
                      class="icon-AMEX"><span class="path1"></span><span class="path2"></span><span
                        class="path3"></span><span class="path4"></span><span class="path5"></span><span
                        class="path6"></span><span class="path7"></span><span class="path8"></span><span
                        class="path9"></span><span class="path10"></span></span>
                    <span *ngSwitchDefault><span class="icon-Unkown-Card"
                        [matTooltip]="x.activeCardOnFile?.cardMasked"><span class="path1"></span><span
                          class="path2"></span></span></span>
                  </div>
                </div>
              </ng-container>
              <ng-container [ngSwitch]="x?.statusId">
                <i *ngSwitchCase="30" class="icon-Failure_Vcart ag_ml--1"
                  [matTooltip]="captions.msg_CartDetailBookingFailes"></i>
                <i *ngSwitchCase="60" class="icon-Partially-Completed_Vcart ag_ml--1"></i>
              </ng-container>
              <ng-container *ngIf="x?.cartComments && x?.cartComments.trim().length > 0 ">
                <i class="icon-Cart-Comment ag_ml--1" [matTooltip]="x?.cartComments"></i>
              </ng-container>
              <ng-container *ngIf="x?.transferredUserId > 0">
                <i class="icon-transfer-this-record ag_ml--1"
                  [matTooltip]="captions.lbl_CartTransferredBy + ' ' + x.transferredUserName"></i>
              </ng-container>
              <ng-container *ngIf="x.isMember">
                <i class="icon-user ag_ml--1 active-color" [matTooltip]="x.memberDetails" *ngIf="x.membershipStatus == 'Active'"></i>
                <i class="icon-user ag_ml--1 inactive-color" [matTooltip]="x.memberDetails" *ngIf="x.membershipStatus != 'Active'"></i>
              </ng-container>
              <!-- <span class="nb-space">-</span> <span>  {{x.phNumber}}</span> -->
            </h4>
            <label class="ag_w--100" [matTooltip]="x.name">{{x.name}}</label>
            <span *ngIf="x.itineraryPackageCode && x.itineraryPackageCode.length > 0">
              <label class="pkglbl" [matTooltip]="x.itineraryPackageCode">{{captions.lbl_packageShortForm +": "+
                x.itineraryPackageCode}}</label>
            </span>
          </div>
          <div class="right-end-sect">
            <ng-container>
              <span class="cart-timer-sect ag_ml--auto" [ngClass]="[x.isStatusred?'bg-red':'']">
                <i aria-hidden="true" class="icon-clock" ngxMaterialTimepickerToggleIcon></i>
                 {{x.expirySeconds|minuteconverter:x}}
      
              </span>
            </ng-container>
          </div>
          <i class="icon-more ag_cursor--pointer ag_ml--auto" [matMenuTriggerFor]="menu"
            [matMenuTriggerData]="{'element': x}"></i>
        </div>
        <div class="show-content-section" *ngIf="x.iscartExpand">
          <div class="body-contents" cdkDropList [cdkDropListData]="x.cartDetails"
            (cdkDropListDropped)="drop($event,x)">
            <ng-container *ngIf="x.cartDetails.length>0">
              <div  cdkDragBoundary='.accordian-sect-wrapper' class="cart-item-wrapper" *ngFor="let y of x.cartDetails" (cdkDragStarted)="dragStart($event,x,y)"
                cdkDrag>
                <div class="ag_display--flex-wrap">
                  <div class="custom-cart-detail-icon">
                    <i *ngIf="y.cartPayload.productId == 3" class="icon-golf"></i>
                    <i *ngIf="y.cartPayload.productId == 1" class="icon-spa"></i>
                    <i *ngIf="y.cartPayload.productId == 6" class="icon-pms"></i>
                    <i *ngIf="y.cartPayload.productId == 13" class="icon-activity-none"></i>
                    <i *ngIf="y.cartPayload.productId == 12" class="icon-activity-none"></i>
                  </div>
                  <div class="ct-cartList-width">
                    <div class="ag_display--flex">
                      <div class="ag_font--bold hotl-sect text-precies" [matTooltip]="y.cartPayload.description">
                        {{y.cartPayload.description}}
                      </div>
                      <div class="hotl-actions ag_display--flex">
                        <ng-container *ngIf="y?.statusId != 20 && y?.statusId != 40">
                          <i class="icon-delete ag_ml--auto ag_cursor--pointer" attr.automationId='Icn_vcart_cartRemove{{i}}'
                            (click)="removeCartItem(y,x.cartDetails,x)"></i>&nbsp;&nbsp;
                          <i class="icon-Edit ag_cursor--pointer" (click)="cartItemEdited(x,y)"></i>
                        </ng-container>
                        <p class="ag_font--bold ag_ml--auto">{{y.cartPayload.price | Currency}}</p>
                      </div>

                    </div>
                    <div class="ag_display--flex al-flex-end">
                      <div class="ag_w--70 text-precies desc">
                        <div class="desc" [matTooltip]="y.allGuestsNames"> {{y.allGuestsNames}}</div>
                        <div class="desc" *ngIf="y.cartPayload.productId == 3">
                          {{localizeDateTime(y.cartPayload.startDate)}}
                        </div>
                        <div class="desc" *ngIf="y.cartPayload.productId == 1">
                          {{localizeDateTime(y.cartPayload.startDate)}}
                        </div>
                        <div class="desc" *ngIf="y.cartPayload.productId == 6">
                          {{y.cartPayload.startDate | localizeDate}} -
                          {{y.cartPayload.endDate | localizeDate}}
                        </div>
                        <div class="desc" *ngIf="y.cartPayload.productId == 12">
                          {{localizeDateTime(y.cartPayload.startDate)}}
                        </div>
                        <div class="desc" *ngIf="y.cartPayload.productId == 13">
                          {{localizeDateTime(y.cartPayload.startDate)}}
                        </div>
                      </div>
                      <div class="deposite-tot ag_display--flex">
                        <p class="dep-title ag_ml--auto">{{captions.lbl_deposit}}:</p>
                        <p class="dep-amount ">{{y.cartPayload.depositAmount | Currency}}</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </ng-container>
            <div class="no-cart-details" *ngIf="x.cartDetails.length==0">
              <img src="./assets/images/Processing_Illustration.png" alt="No Cart Detail">
              <p>{{captions.lbl_noCartDetailFound}}</p>
            </div>
            <div class="ag_display--flex-wrap custom-buton-sect">
              <div *ngIf="x?.cartDetails.length>0" class="total-section ag_w--100">
                <div class="ag_display--flex ag_w--100">
                  <p class="ag_w--50">{{captions.lbl_totalEstimatedamount}}</p>
                  <p class="ag_w--50">{{x|totalestimate:'price'}}</p>
                </div>
                <div class="ag_display--flex ag_w--100">
                  <p class="ag_w--50">{{captions.lbl_totalDepositAmount}}</p>
                  <p class="ag_w--50">{{x|totalestimate:'depositAmount'}}</p>
                </div>
              </div>
              <div class="ag_display--flex ag_w--100 ag_mt--2">
                <button class="ag_mr--2 ag_w--50" [disabled]="!(x.expanded && x.cartDetails.length > 0) "
                  mat-raised-button color="primary" (click)="book(x)" attr.automationId='Btn_vcart_book{{i}}'>
                  {{captions.btn_book}}
                </button>
                <button class="ag_w--50" (click)="hold(x,i)" [disabled]="!x.iscartExpand" mat-stroked-button
                attr.automationId='Btn_vcart_hold{{i}}'>
                  {{captions.btn_hold}}
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="no-data-img" *ngIf="itineraryList?.length==0 || dataLoadError">
      <img src="./assets/images/Search_Illustration.png" alt="No data">
      <span *ngIf='itineraryList?.length==0 && !dataLoadError'>
        <p>{{captions.lbl_no_ItinerarriesAddedYet}}</p>
      </span>
      <span *ngIf='dataLoadError'>
        <p>{{captions.lbl_errorLoadingItineraries}}</p>
      </span>

    </div>



  </div>
  <div class="need-itenaray">
    <button (click)="newItineraryClicked()" [attr.automationId]="'Btn_vcart_newItinerary'" mat-raised-button color="primary" mat-stroked-button>
      {{captions.btn_newItenarary}}
    </button>
  </div>

  <div id="vcart-cover-spin">
    <div class="customspinnerimg">
      <img src="./assets/images/agil_favicon.ico">
    </div>
    <div id="vcart-custom-cover-message"></div>
  </div>
</div>


<mat-menu #menu="matMenu" class='ag-split-button-menu'>
  <ng-template let-element="element" matMenuContent>
    <button (click)="moreOptionsemiter(options , element)" [attr.automationId]="'Btn_vcart_more'" *ngFor="let options of element.menuOptions" mat-menu-item
      class='split-button-list' [disabled]="options.disabled">{{options.value}}</button>
  </ng-template>
</mat-menu>