import { Injectable } from "@angular/core";
import { GolfLocalization } from "src/app/core/localization/golf-localization";
import {  TeeTimePlayer } from './block-tee-time.model';
import { TeeSheetPlayerDetails, TeeSheetSkeletonData } from 'src/app/shared/models/teesheet.form.models';
import { TeeTimeDataService } from 'src/app/shared/data-services/golfschedule/TeeTime.data.service';
import { TeeTimeModel, ScheduledTeeTimeTimePlayer, PlayerDetail, ScheduledTeeTimePlayerFee } from '../teetime/tee-time.model';
import { GolfScheduleCommunication } from 'src/app/shared/communication/services/golfschedule.service';

@Injectable()
export class BlockTeeTimeBusiness {
    captions: any;

    constructor(private localization: GolfLocalization, private _TeeTimeDataService: TeeTimeDataService, private _http: GolfScheduleCommunication) {
        this.captions = this.localization.captions.teetime;
    }

    public SetBookedPlayer(playerInfo: TeeSheetPlayerDetails[]): TeeTimePlayer[] {
       

        let players :TeeTimePlayer[] = [];

        for (let index = 1; index <= 4; index++) {
            let player = playerInfo.find(x => x.playPos == index);
            if (player) {
                players.push({
                            firstName: player.firstName == '' ? 'Blocked' : player.firstName,
                            lastName: player.lastName,
                            playerId: player.playerId,
                            teeTimeFormat: player.teeTimeFormat,
                            playerPos: player.playPos
                        } as TeeTimePlayer);
                }
            else {
                players.push({
                    firstName: 'P' + (index),
                    lastName: '',
                    playerId: 0,
                    teeTimeFormat: 0,
                    playerPos: index,
                    selected: false
                });
            }
        }
        return players;
    }

    public async getScheduledTeeTime(scheduledId: number): Promise<TeeTimeModel> {
        return await this._TeeTimeDataService.getScheduledTeeTime(scheduledId);
    }

    sortObject(obj: ScheduledTeeTimeTimePlayer[]) {
        obj.sort((a, b) => {
            return a.playerSlotPosition - b.playerSlotPosition;
        });
    }

    public async saveBlockedSlots(dialogData: TeeSheetSkeletonData, blockablePlayer :  TeeTimePlayer[], comment : string): Promise<boolean> {
        let teeTimeModel: TeeTimeModel;
        let selectedPlayers  = blockablePlayer.filter(x=> x.selected);
        if (dialogData.scheduledTeeTimeId > 0) {
            teeTimeModel = await this.getScheduledTeeTime(dialogData.scheduledTeeTimeId);
            let blockScheduledPlayers = this.getscheduledTeeTimePlayers(selectedPlayers, comment);
            teeTimeModel.scheduledTeeTimePlayers.push(...blockScheduledPlayers);
            this.sortObject(teeTimeModel.scheduledTeeTimePlayers);
        }
        else {
            teeTimeModel = this.mapEmptySlotToTeeTime(dialogData, selectedPlayers,comment);
        }
        return await this.saveBlockTeeTime(teeTimeModel);        
    }

    public mapEmptySlotToTeeTime(dialogData: TeeSheetSkeletonData, blockablePlayer :  TeeTimePlayer[], comment : string): TeeTimeModel {
        let teeTime: TeeTimeModel;
        const currentDate = this.localization.convertDateTimeToAPIDateTime(this.localization.getUTCDateTimeNow());
        teeTime = {
            id: 0,
            courseId: dialogData.course ? dialogData.course.id : 0,
            scheduleDateTime: dialogData.time,
            holeNumber: dialogData.hole ? dialogData.hole : "0",
            scheduleStatus: 1, // 1 denotes status as booked -- to do
            playerId: 0,
            tournamentId: 0,
            comment: "",
            scheduledTeeTimePlayers: this.getscheduledTeeTimePlayers(blockablePlayer, comment),
            createdDateTime :currentDate,
            ticketNumber : '',
            originalHoleNumber : dialogData.originalHoleNumber
        }
        return teeTime;
    }

    private getscheduledTeeTimePlayers(blockedPlayers: TeeTimePlayer[],comment: string): ScheduledTeeTimeTimePlayer[] {
        let teeTimePlayers: ScheduledTeeTimeTimePlayer[] = [];       
        const userId = Number(this.localization.GetPropertyInfo('UserId'));       
        blockedPlayers.forEach((player, index) => {
            const teeTimePlayer: ScheduledTeeTimeTimePlayer = {
                id: 0,
                isPlayerDetailModified: false,
                scheduledTeeTimeId: 0,
                playerId: 0,
                playerDetail: this.getPlayerDetail(),
                gameFormat: 0,
                caddyId: 0,
                caddyTypeId : 0,
                holes: 18,
                holesPlayed: 0,
                cartId: '0',
                isWalk: false,
                isTrail: false,
                playerComment: comment,
                packageCode: '',
                playerSlotPosition: player.playerPos,
                groupId: this.localization.getTicks(),
                bookingId: undefined,
                playersPerGroup: 0,
                scheduledTeeTimePlayerFee: {
                  id: 0,
                  scheduledTeeTimePlayerId: 0,
                  allocationBlockId: 0,
                  playerTypeId: 0,
                  rateTypeId: 0,
                  greenFee: 0,
                  isGreenFeeNegotiable: false,
                  cartFee: 0,
                  isCartFeeNegotiable: false,
                  viewedByStarter:false,
                },
                confirmationNumber: '',
                loyaltyDetail :  [
                  {
                    "patronId": "",
                    "rank": ""
                  }],
                  isBlocked : true,
                  createdBy : userId,
                  availableRounds : 0,
                  overrideDetails : []
              };
            teeTimePlayers.push(teeTimePlayer);
        });
        return teeTimePlayers;

    }

    private getPlayerDetail(): PlayerDetail {

        return {
            id: 0,
            firstName: '',
            lastName: '',
            pronounced: '',
            customField1: '',
            customField2: '',
            customField3: '',
            customField4: '',
            customField5: '',
            playerCategoryId: 0,
            paymentReferenceId:  0,
            gender:  0,
            playerLinkId: '',
            bagNumber: '',
            playerAddress: null,
            contactInformation: [],
            playerStayDetail: null,
            loyaltyDetail: [
              {
                "patronId": "",
                "rank": ""
              }
            ],
            activityId: '00000000-0000-0000-0000-000000000000',
            vipType: null
          }          
    }


    private getScheduledTeeTimePlayerFee(): ScheduledTeeTimePlayerFee {
        return {
            id: 0,
            scheduledTeeTimePlayerId: 0,
            allocationBlockId: 0,
            playerTypeId: 0,
            rateTypeId: 0,
            greenFee: 0,
            isGreenFeeNegotiable: false,
            cartFee: 0,
            isCartFeeNegotiable: false,
        } as ScheduledTeeTimePlayerFee;
    }

    public saveBlockTeeTime(body:TeeTimeModel): Promise<boolean> {
        return this._http.putPromise<boolean>(
            { route: GolfApiRoute.BlockTeeTimeSlots , body: body });
    }

}
