import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { RateModalBussiness } from './rate-modal-bussiness';
import { RateModalService } from './rate-modal-service';
import { RatesData } from './rate-model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TeeTimeCustomFeeUtilities } from '../tee-time-custom-fee.utilities';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';

@Component({
  selector: 'app-rate-modal',
  templateUrl: './rate-modal.component.html',
  styleUrls: ['./rate-modal.component.scss'],
  providers: [RateModalBussiness, RateModalService],
  encapsulation: ViewEncapsulation.None
})
export class RateModalComponent implements OnInit {

  data: Promise<RatesData[]>;
  IsCustomFeeConfigured: boolean = false;
  dateTime: string;
  courseId: number;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData, 
  private _rateModalBussiness: RateModalBussiness,
  private _teeTimeCustomFeeUtilities:TeeTimeCustomFeeUtilities) {
    this.courseId = dialogData.info.course.id;
    this.dateTime = dialogData.info.time;
  }

  ngOnInit() {
    this.initialize();
  }  

  async initialize() 
  {
    await this._rateModalBussiness.validateBreakPointAccess(UserAccessBreakPoints.OVERRIDE_UNAVAILABLE_RATETYPE_PER_DYNAMICAVAILABILITY,false);
    await this._teeTimeCustomFeeUtilities.IsCustomFeeConfigured().then(o => this.IsCustomFeeConfigured = o);
    this.data = this._rateModalBussiness.getRatesByCourseIdAndDateTime(this.courseId, this.dateTime);
  }  
}
