import { Injectable } from '@angular/core';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { DocumentationService } from 'src/app/shared/communication/services/documentation.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class HeaderService {

  constructor(private _Localization: GolfLocalization, private _docService: DocumentationService) { }

  getCaptions() {
    return this._Localization.captions.header;
  }

  async createHelpSession() : Promise<boolean>{
    return await this._docService.httpPost({
      route: GolfApiRoute.CreateHelpSession,
      uriParams: {product: 'golf'}
    })
  }

  async removeHelpSession(){
    this._docService.httpPost({
      route: GolfApiRoute.RemoveHelpSession,
      uriParams: {product: 'golf'}
    })
  }

  getDocumentationRoute(){
    return environment["Documentation"];
  }
}
