<section class="eform-sign-capture-wrapper newVersionWrapper">
    <app-dialog-header [title]="captions.hdr_dm_eforms_config" (closeDialog)="close()"></app-dialog-header>
    <div [formGroup]="signCaptureConfigForm" class="eform-sign-inner-wrapper">
    <div class="top-section">
        <mat-checkbox class="ag_form-control--2 pb-3" formControlName="useEformSignCapture" [attr.automationId]="'Chk_createCustomerMaintenanceprimaryInfo_applyFinanceCharge'">
            <span>{{captions.lbl_useEformSignCapture}} </span>
        </mat-checkbox>
        <mat-checkbox class="ag_form-control--2 pb-3" formControlName="showOnCheckin" [attr.automationId]="'Chk_createCustomerMaintenanceprimaryInfo_applyFinanceCharge'">
            <span>{{captions.lbl_showDuringCheckIn}} </span>
        </mat-checkbox>
        <mat-checkbox class="ag_form-control--2 pb-3" formControlName="defaultTemplate" (change)="defaultTemplateChange($event)"
        [attr.automationId]="'Chk_createCustomerMaintenanceprimaryInfo_applyFinanceCharge'">
            <span>{{captions.lbl_defaultTemplate}} </span>
        </mat-checkbox>
        <mat-checkbox class="ag_form-control--2 pb-3" formControlName="showOnPreview" [attr.automationId]="'Chk_createCustomerMaintenanceprimaryInfo_applyFinanceCharge'">
            <span>{{captions.lbl_ShowPreview}}</span>
        </mat-checkbox>
        <ag-textbox [config]="signCaptureEndPointConfig"></ag-textbox>
    </div>
    <div class="search-template" [ngClass]="!enableSearchEform ? 'ag_section--disable' : '' ">
        <app-search-eform-template [dataMagineConfigData]="DataMagineConfigData" [selectedForm]="selectedForm" (searchValue)="searchTextValue($event)"></app-search-eform-template>
    </div>
    </div>  
    <div class="ag_footer--actions">
        <app-button [buttontype]="saveButton" (valueChange)='onSave()'></app-button>
        <app-button [buttontype]="cancelButton" (valueChange)='onCancel()'></app-button>
    </div>
</section>
