import { Injectable } from "@angular/core";
import { CheckboxTableHeader, AlertType, ButtonType } from '../../shared/shared-models';
import { GolfLocalization } from '../../core/localization/golf-localization';
import { PlayerDataService } from 'src/app/shared/data-services/golfmanagement/player.data.service';
import { RateTypeDataService } from 'src/app/shared/data-services/golfmanagement/ratetype.data.service';
import { PlayerTypeService } from 'src/app/shared/data-services/golfmanagement/playertype.data.service';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { ReinstatePlayer, ReinstatePlayerUI, PlayerTeeTimeSlot, ReinstatePlayerAndSlotDetail, ReinstateResult } from './reinstate-player-model';
import { TeeTimeDataService } from 'src/app/shared/data-services/golfschedule/TeeTime.data.service';
import { TeeTimeSearchData } from 'src/app/tee-time/search/search-model';
import { TeeTimesActionService } from 'src/app/shared/data-services/golfschedule/teeTimesAction.data.service';
import { RuleResult } from 'src/app/shared/models/teesheet.api.models';
import { AllocationBlockDataService } from "src/app/shared/data-services/golfmanagement/allocationblock.data.service";


@Injectable()

export class ReinstateBusiness {

    public readonly reinstatePlayerCaption: any;
    
    constructor(private _rateTypeDataService: RateTypeDataService,
        private _playerTypeService: PlayerTypeService,
        private _playerDataService: PlayerDataService,
        private _teeTimeDataService: TeeTimeDataService,
        private _teeTimesActionService: TeeTimesActionService,
        private _localization: GolfLocalization,
        private _utils: GolfUtilities,
        private _allocationBlockDataService: AllocationBlockDataService) {
        this.reinstatePlayerCaption = this._localization.captions.teetime;
    }

    getHeaderOption(): CheckboxTableHeader[] {
        return [
            { key: 'playerFullName', description: this.reinstatePlayerCaption.reinstateHeaderPlayer, alignment: 'textLeft' },
            { key: 'bookedDateTimeFormatted', description: this.reinstatePlayerCaption.reinstateHeaderbookedDateTime, alignment: 'textLeft' },
            { key: 'playerType', description: this.reinstatePlayerCaption.reinstateHeaderPlayerType, alignment: 'textLeft' },
            { key: 'rateType', description: this.reinstatePlayerCaption.reinstateHeaderRateType, alignment: 'textLeft' },
            { key: 'course', description: this.reinstatePlayerCaption.reinstateHeaderCourseName, alignment: 'textLeft' }
        ];
    }

    public async getPlayerDetails(scheduleTeeTimeInfo: TeeTimeSearchData, pmsActivityId: string): Promise<ReinstatePlayerUI[]> {

        let reinstatePlayers = await this._teeTimeDataService.getReinstatePlayers(scheduleTeeTimeInfo.courseID, this._localization.ConvertDateToISODateTime(scheduleTeeTimeInfo.scheduleDateTime), pmsActivityId);
        return  reinstatePlayers.map(o => {
            return {
                id: o.id,
                playerFullName: this._utils.formatGuestName(o.playerFirstName, o.playerLastName),
                playerFirstName: o.playerFirstName,
                playerLastName: o.playerLastName,
                bookedDateTime: scheduleTeeTimeInfo.scheduleDateTime,
                bookedDateTimeFormatted: this._localization.LocalizeShortDateTime(scheduleTeeTimeInfo.scheduleDateTime),
                courseId: scheduleTeeTimeInfo.courseID,
                course: scheduleTeeTimeInfo.courseName,
                playerType: o.playerType,
                rateType: o.rateType,
                holeNumber: o.holeNumber,
                uniqueCancellationNumber: o.uniqueCancellationNumber,
                originalHoleNumber: o.originalHoleNumber,
                playerTypeId: o.playerTypeId,
                rateTypeId: o.rateTypeId,
                checked: true,
                bookingSource: o.bookingSource,
                linkingId: o.linkingId != null ? o.linkingId : '',
                playerCategoryId: o.playerCategoryId,
            }
        });
    }


    public async reinstateWithExistingCombination(selectedPlayersList: ReinstatePlayerUI[], roleId?: string, callback?: any): Promise<any> {

        if (this._utils.IsAllocationCodePermissionEnabled()) {
            let [allocationPermissions, teeTimeInfo, playerTypes] = await Promise.all([this._allocationBlockDataService.getAllocationBlockPermissionByRole(roleId),
            this.getCancelTeeTimePlayers(selectedPlayersList[0].courseId, selectedPlayersList[0].bookedDateTime), this._playerTypeService.getAllPlayerTypes()]);
            if (teeTimeInfo && teeTimeInfo.length > 0) {
                let arrSelectedTeeTimeInfo = Array.from(new Set(teeTimeInfo.filter(x => selectedPlayersList.some(y => y.id == x.id) && x.playerTypeId > 0)));
                let invalidaPlayerTypes = [];
                if (arrSelectedTeeTimeInfo && arrSelectedTeeTimeInfo.length > 0) {
                    allocationPermissions = allocationPermissions ? allocationPermissions : [];
                    for (let info of arrSelectedTeeTimeInfo) {
                        if (!allocationPermissions.some(al => al.allocationBlockId == info.allocationBlockId && al.playerTypeId == info.playerTypeId)) {
                            invalidaPlayerTypes.push(info.playerTypeId);
                        }
                    }
                }

                if (invalidaPlayerTypes.length > 0 && invalidaPlayerTypes[0]) {
                    this._utils.showAllocationCodePermissionDeniedPopup(this._utils.getPlayerTypeNameByIds(invalidaPlayerTypes.distinct(), playerTypes), this._localization.captions.common.ReInstateNotAllowed);
                    return;
                }
            }
        }

        let resp$: Promise<ReinstateResult[]>;
        const reinstateDetails: ReinstatePlayerAndSlotDetail = {
            playersTeeTimeSlot: this.mapToAPI(selectedPlayersList),
            isNewCombination: false
        }
        resp$ = this._teeTimesActionService.reinstateCancelledTeeTimePlayers(reinstateDetails, (err) => {
            /** callback to perform action after error catch */
            if (callback && typeof (callback) == 'function') {
                if (err && !err?.result) callback(err);
            }
        });
        const resp: ReinstateResult[] = await resp$;
        return resp;
    }

    public showRuleErrors(ruleResults: RuleResult[]): number {
        let error: string;
        if (ruleResults) {
            let ruleResult = ruleResults.filter(x => !x.isValid);
            if (ruleResult.length > 0) {
                let failedRuleResult = ruleResult[0];
                error = this._localization.getError(failedRuleResult.errorCodes[0]);
                if (failedRuleResult.errorCodes[0] == 30401) // players day out rule
                {
                    error = this._localization.getError(failedRuleResult.data.DaysOutData[0].errorCode);
                    error = error.interpolate({ date: this._localization.localizeDisplayDate(failedRuleResult.data.DaysOutData[0].date) });
                }
                else if (failedRuleResult.errorCodes[0] == 11010 || failedRuleResult.errorCodes[0] == 11015) {
                    error = error.interpolate({ user: failedRuleResult.data.UserName ? failedRuleResult.data.UserName : "" });
                }
                this._utils.showError(error);
                return failedRuleResult.errorCodes[0];
            }
        }
        else {
            const error: string = this._localization.getError(-2);
            this._utils.showError(error);
            return -2;
        }
    }

    private mapToAPI(reinstatePlayers: ReinstatePlayerUI[]): PlayerTeeTimeSlot[] {
        return reinstatePlayers.map(r => {            
            return {
                courseId: r.courseId,
                playerId: r.id,
                scheduledDateTime: this._localization.ConvertDateToISODateTime(r.bookedDateTime),
                playerSlotPosition: 0, //Default to 0 
                holeNumber: r.holeNumber,
                destinationScheduledTeeTimeId: 0,
                isValid: true,
                isDestinationSlotRateChanged: false,
                originalHoleNumber: r.originalHoleNumber,
                overrideDetails: r.overrideDetails ? r.overrideDetails : [],
                playerTypeId: r.playerTypeId,
                rateTypeId: r.rateTypeId,
                useNewRateType: false,
                multiPackTransactionDetailId: 0,
                multiPackCartFeeValue: 0,
                multiPackGreenFeeValue: 0
            }
        });
    }

    public async getCancelTeeTimePlayers(courseId: number, bookedDateTime): Promise<ReinstatePlayer[]> {
        return await this._teeTimesActionService.getCancelTeeTimePlayers(courseId, bookedDateTime);
    }
}