<div class="sftp-wrapper ag_height--100">
    <div class="ag_container--padding-md ag_modal--form-height">
        <!-- <app-warning-bar [message]="message"  (closeWarning)="hideWarning()" [showClose]="true">
        </app-warning-bar> -->
        <div>
            <form class="sftp-formwrapper" [formGroup]="form">

                <div class="cust-align ">
                    <h4 class="ag_group--headers ag_mt--4 ag_w--100">
                        {{captions.lbl_sftp_configuration}}
                    </h4>

                 </div>
                 <div class="ag_display--flex-wrap">
                    <!-- <mat-checkbox class="ag_mb--4" disableRipple="true" (change)="onChangeConfig($event)" formControlName="isEnabled">
                        {{captions.lbl_enableisEnabled}}</mat-checkbox> -->
                        <div class="w-307px"> <app-ag-dropdown [inputs]="protocolAgDropdownConfig"></app-ag-dropdown></div>
                        <div class="w-307px"><ag-textbox [config]="connectionNameTextInputConfig"  ></ag-textbox>  </div>

                        <div class="w-307px"><ag-textbox [config]="hostNameTextInputConfig" ></ag-textbox>  </div>

                        <div class="w-307px"><ag-textbox [config]="userNameTextInputConfig"></ag-textbox></div>

                        <!-- <div class="w-307px"><ag-textbox [config]="passwordTextInputConfig"></ag-textbox></div> -->
                        <div class="w-307px">
                        <mat-form-field class="ag_form-control--lg" [floatLabel]="floatLabel">
                            <input matInput  [type]="hidePassword ? 'text' : 'password'" [placeholder]="captions.Sftplbl_Password"
                              formControlName="password">
                              <i matSuffix class="IC18 password-icon-align"
                                                [ngClass]="hidePassword ? 'icon-password-show' : 'icon-password-hide '"
                                                 (click)="hidePassword=!hidePassword"  ></i>
                          </mat-form-field>
                        </div>
                        <div class="w-307px"><ag-textbox [config]="portTextInputConfig"></ag-textbox></div>

                        <div class="w-620px"><ag-textbox [config]="remotePathTextInputConfig"></ag-textbox></div>

                        <div> <mat-checkbox class="ag_mb--4" disableRipple="true"  formControlName="isEnabled">
                        {{captions.lbl_enableisEnabled}}</mat-checkbox>
                        </div>


                 </div>



                <input type="submit"  class="hidden-submit" tabindex="-1"  (click)="onAction($event)"/>
            </form>
        </div>
    </div>

    <div class="SpecialGuaranteeCode-footer ag_footer--actions">
        <app-button [buttontype]="actionButton" (valueChange)='onAction($event)'></app-button>
        <app-button [buttontype]="cancelButton" class="ag_ml--2" (valueChange)='onCancel($event)'></app-button>
    </div>
</div>

<ng-template #swap>
    <app-pms-merge-swap [data]="mergeReplaceInputs" [mergeOptions]="mergeOptions"
        (closeReplaceMerge)="closeReplaceMerge($event)">
    </app-pms-merge-swap>
</ng-template>
