<section class="golf-container--padding" id="rateModal">
  <div class="rate_wrapper fullheight">
    <div class="left-rate-wrap" *ngFor="let x of data | async;let i=index"  >
      <div class="left-aside">
        <h4 class="golf-page--headers w-100 textellipsis" >
          <div class="ag_display--flex align-items-end justify-content-between">
          <span [matTooltipClass]="'AgysMatCustTooltip'" [matTooltip]="x.description">{{x.description}}</span>
          <span *ngIf="x.stopAvailability" class="icon-Stop_availability"></span>
          </div>
        </h4>
        <p>{{x.total}}</p>
      </div>
      <div class="right-aside">
        <div class="ag_display--flex ag_w--100">
          <div  [ngClass]="IsCustomFeeConfigured ? 'ag_w--33' : 'ag_w--50' ">
            <h4 class="golf-page--headers">{{x.greenTitle}}</h4>
            <p>{{x.greenFee}}</p>
          </div>
          <div  [ngClass]="IsCustomFeeConfigured ? 'ag_w--33' : 'ag_w--50' ">
            <h4 class="golf-page--headers">{{x.cartFeeTile}}</h4>
            <p>{{x.cartFee}}</p>
          </div>
            <div *ngIf = "IsCustomFeeConfigured" class="ag_w--33" >
              <h4 class="golf-page--headers">{{x.customFeeTitle}}</h4>
              <p>{{x.customFee}}</p>
            </div>
        </div>
      </div>
    </div>
 
    <div *ngIf="(( data | async ).length <=0)" class="norecords-alignment">
        <app-no-data-found [isSearch] = "(( data | async ).length <=0)"></app-no-data-found> 
    </div>
  </div>
</section>