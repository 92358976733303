import { MultiCourseViewPlayerNameFormat } from "src/app/shared/models/default-settings.models";

export interface TeeTimeData {
  tableArray: tableArray[];
  fieldGroup: fieldGroup;
  toggleGroup: toggleGroup;
  defaultValues?: defaultValues;
}
export interface tableArray {
  id: number,
  autoRefresh: boolean,
  minInput: number,
}
export interface fieldGroup {
  "rainCheckNote": string,
  "teeTimeTicketNote": string,
  "firstDeposit": string,
  "secondDeposit": string,
  "depositRefund": string,
  "dueDaysAfterBooking": number,
  "dueDaysPriorToPlay1": number,
  "dueDaysPriorToPlay2": number,
  "tempHoldDuration": number,
}
export interface toggleGroup {
  "requirePlayerType": boolean,
  "requireRateType": boolean,
  "showMemberSignOnTeeGrid": boolean,
  "defaultToGraphicalTeeSheet": boolean,
  "editCaddyInfoOnMoveCopy": boolean;
  "promptUserToPrintTeeTickets": boolean,
  "autoPrintTeeTickets": boolean,
  "printTeeTicketsPaidAgainstOneSlot": boolean,
  "requireTeeTimesToBePaidNoShowedByDayEnd": boolean,
  "setPaidOnDayOfPlay": boolean,
  "requireCheckInCheckOut": boolean
  "enableAutoCheck": boolean,
  "allowPaymentInCheckIn": boolean
  "autoCheckInCheckOutZeroCharges": boolean,
  "requireEformOnPaymentCheckIn": boolean,
  "allowPlayerAllocationBlock": boolean,
  "cancelPlayerFromTeeSheet": boolean,
  "restrictPayAfterRefundForPreviousDay": boolean,
  "updateCustomFeeForExistingBookings": boolean,
  "updateRatesForExistingBooking": boolean,
  "includePlayersAcrossCoursesForCheckIn": boolean,
  "checkForTeeTimeOverlaps": boolean,
  "overlapHours": number,
  "allowNoShowPriortoPlay": boolean,
  "allowCancellationAcrossSlots": boolean,
  "isNoShowCancellationPolicyConfigured": boolean
}
export interface defaultValues {
  address: string;
  cell: string;
  city: string;
  country: string;
  dateOfBirth: string;
  home: string;
  office: string;
  packageCode: string;
  patronId: string;
  personal: string;
  postelCode: string;
  primaryEmail: string;
  primaryPhone: string;
  pronounced: string;
  rank: string;
  roomNumber: string;
  state: string;
  work: string;
  stayDates: string;
  businessPhone: string;
  workPhone: string;
  homeEmail: string;
  businessEmail: string;
}

export namespace API {

  export interface TeeTimeSetting {
    id: number;
    moduleName: string;
    screenName: string;
    configValue: TeeTimeConfig;
    defaultValue: string;
  }

  export interface Settings<T> {
    id: number;
    moduleName: string;
    screenName: string;
    configValue: T;
    defaultValue: string;
  }

  export interface PropertyConfig<T> {
    id: number;
    productId: number;
    configurationName: string;
    configValue: T;
    defaultValue: string;
    propertyId: number;
  }

  export interface TenantConfig<T> {
    id: number;
    tenantId: number;
    configurationName: string;
    configValue: T;
    defaultValue: string;
  }

  export interface TeeTimeConfig {
    refreshTime: RefreshTime[];
    rainCheckNote: string;
    tempHoldDuration: number;
    ticketNote: string;
    firstDeposit: Deposit;
    secondDeposit: Deposit;
    depositRefund: Deposit;
    requirePlayerType: boolean;
    requireRateType: boolean;
    showMemberSignOnTeeGrid: boolean;
    defaultToGraphicalTeeSheet: boolean;
    promptUserToPrintTeeTickets: boolean;
    autoPrintTeeTickets: boolean;
    printTeeTicketsPaidAgainstOneSlot?: boolean;
    requireTeeTimesToPaid: boolean;
    setPaidOnDayOfPlay?: boolean;
    requireCheckInCheckOut?: boolean;
    enableAutoCheck?: boolean;
    allowPaymentInCheckIn?: boolean;
    autoCheckInCheckOutZeroCharges?: boolean;
    updateRatesForExistingBooking: boolean;
    editCaddyInfoOnMoveCopy?: boolean;
    allowPlayerAllocationBlock?: boolean;
    cancelPlayerFromTeeSheet?: boolean;
    checkForTeeTimeOverlaps?: boolean;
    allowNoShowPriortoPlay?: boolean;
    allowCancellationAcrossSlots?: boolean;
    overlapHours?: number;
    address?: string;
    cell?: string;
    city?: string;
    country?: string;
    dateOfBirth?: string;
    home?: string;
    office?: string;
    packageCode?: string;
    patronId?: string;
    personal?: string;
    postelCode?: string;
    primaryEmail?: string;
    primaryPhone?: string;
    pronounced?: string;
    rank?: string;
    roomNumber?: string;
    state?: string;
    work?: string;
    requireEformOnPaymentCheckIn: boolean;
    stayDates?: string;
    restrictPayAfterRefundForPreviousDay?: boolean;
    includePlayersAcrossCoursesForCheckIn?: boolean;
    updateCustomFeeForExistingBookings?: boolean;
    isNoShowCancellationPolicyConfigured?: boolean;
    businessPhone?: string;
    workPhone?: string;
    businessEmail?: string;
    homeEmail?: string;
  }

  export interface Deposit {
    depositPercentage: number;
    dueDays: number;
    isApplicable: boolean;
  }

  export interface RefreshTime {
    dayOfWeek: number;
    dayOfWeekCode: string;
    autoRefresh: boolean;
    minutesToRefresh: number;
  }

  export interface DefaultsSetting {
    id: number;
    moduleName: string;
    screenName: string;
    configValue: DefaultsSettingConfig;
    defaultValue: string;
  }

  export interface GolfSetting {
    enableGroupingOfPlayers: boolean;
    playerPlayGroups: PlayerPlayGroupColor[];
    playerPositionBySlot: boolean;
    playerPositionByGroup: boolean;
    enableAddingCourseToCaddy: boolean;
    enableAllocationCodePermissionByRole: boolean;
    printerManagerURI: string;
    allowAllocationCodeOnReinstate: boolean;
    enableDynamicPricing: boolean;
    includeCartFeeForRateCalculation: boolean;
    calculateForPlayerBenefit: boolean;
    updateRatesForExistingBooking: boolean;
    isDynamicPricingBookingsIncludedForUpdateRates: boolean;
    blockedSlotColorCode: string;
  }

  export interface PlayerPlayGroupColor {
    id: number;
    colorCode: string;
  }

  export interface DefaultsSettingConfig {
    blockingCode: number;
    memberStatus: number;
    memberPlayerType: number;
    memberRateType: number;
    nonMemberPlayerType: number;
    nonMemberRateType: number;
    resortPlayerType: number;
    resortRateType: number;
    showConfirmationDialog?: boolean;
    showAvailableOnly?: boolean;
    autoSelectPlayers?: boolean;
    autoTeeTimeHoldConfig: AutoTeeTimeHoldConfig;
    requiredReasonTeeTimeCancellation?: boolean;
    enableExtentedSearchByDefault?: boolean;
    enableNonZeroRateForMarkAsPaid?: boolean;
    defaultOutletType?: number;
    memberGuestPlayerType: number;
    memberGuestRateType: number;
    memberGuestPlayerFirstName: string;
    memberGuestPlayerLastName: string;
    memberGuestsToAdd: number;
    defaultAllocationCodeSwitchTo: number;
    defaultPlayerCategory: number;
    defaultMovePlayerOption: number;
    defaultHotelReservationCategory: number;
    defaultPhoneTypeOption: number;
    defaultEmailTypeOption: number;
    defaultCountryCode: string;
    markAsPaidPaymentMethod: number;
    zeroMemberChargePaymentMethod: number;
    markAsPaidPaymentMethodReceiptComment: string;
    zeroMemberChargePaymentMethodReceiptComment: string;
    disableHolesWhenMemberEdit?: boolean;
    bulkPlayersPerGroup: number;
    rainCheckExpiry: number;
    enableHideCrossOverTeeSheet: boolean;
    enableHideCrossOverGraphicalView: boolean;
    defaultExistingPlayerCategory: number;
    defaultMultiCourseViewPlayerNameFormat: MultiCourseViewPlayerNameFormat
  }
  export interface AutoTeeTimeHoldConfig {
    isEnabled: boolean;
    holdReleaseMinutes: number;
    holdCheckingIntervalInSeconds: number;
  }

  export interface PropertySetting {
    id: number;
    propertyId: number;
    propertyDate: string;
    productVersion: string;
    userProductVersion: string;
    activateRetailInterface: boolean;
    defaultCountryCode: string;
    vatEnabled: boolean;
  }

  export interface PaymentConfiguration {
    propertyId: number;
    subPropertyId: number;
    outletId: number;
    configKey: string;
    configValue: string;
    isActive: boolean;
  }


  export interface TableData {
    tableArray: TableArray[];
    fieldGroup: FieldGroup;
    toggleGroup: ToggleGroup;
  }
  export interface TableArray {
    id: number,
    autoRefresh: boolean,
    minInput: number,
  }
  export interface FieldGroup {
    "rainCheckNote": string,
    "teeTimeTicketNote": string,
    "firstDeposit": number,
    "secondDeposit": number,
    "depositRefund": number,
    "dueDaysAfterBooking": number,
    "dueDaysPriorToPlay1": number,
    "dueDaysPriorToPlay2": number
  }
  export interface ToggleGroup {
    "requirePlayerType": boolean,
    "requireRateType": boolean,
    "showMemberSignOnTeeGrid": boolean,
    "defaultToGraphicalTeeSheet": boolean,
    "editCaddyInfoOnMoveCopy": boolean;
    "promptUserToPrintTeeTickets": boolean,
    "autoPrintTeeTickets": boolean,
    "requireTeeTimesToBePaidNoShowedByDayEnd": boolean,
    "cancelPlayerFromTeeSheet": boolean,
    "includePlayersAcrossCoursesForCheckIn": boolean,
    "checkForTeeTimeOverlaps": boolean,
    "overlapHours": number,
    "allowNoShowPriortoPlay": boolean,
    "allowCancellationAcrossSlots": boolean,
    "isNoShowCancellationPolicyConfigured": boolean
  }

  export interface EmailConfigurationSettingConfig {
    enableEmailConfiguration: boolean;
    type: number;
    senderEmailAddress: string;
    automaticMailOnTeeTimeConfirmation: boolean;
    automaticMailOnTeeTimeRemainder: boolean;
    teeTimeRemainderTime: number;
    automaticMailOnEditOrMoveTeeTime: boolean;
    automaticMailOnChangeInTeeTimesDateAndTime: boolean;
    automaticMailOnChangeInTeeTimeBookedCourse: boolean;
    automaticMailOnChangeInTeeTimeBookedRateType: boolean;
    automaticMailOnChangeInTeeTimeNegotiableFee: boolean;
    automaticMailOnTeeTimeCancellation: boolean;
    automaticMailOnTeeTimeNoShow: boolean;
    automaticSMSOnTeeTimeConfirmation: boolean;
    automaticSMSOnTeeTimeRemainder: boolean;
    automaticSMSOnRetailTransactionReceipts: boolean;
    automaticSMSOnEditOrMoveTeeTime: boolean;
    automaticSMSOnTeeTimeCancellation: boolean;
    automaticSMSOnTeeTimeNoShow: boolean;
    automaticSMSOnBulkBooking: boolean;
    automaticSMSOnGroupBooking: boolean;
    automaticMailOnBulkBooking: boolean;
    automaticMailOnGroupBooking: boolean;
    teeTimeNoShowMinutes: number;
    automaticMailOnRetailTransactionReceipts: boolean;
    bccEmailAddress: string[];
  }

  export interface ApplicationFormat {
    id: number;
    firstNameIndex: number;
    lastNameIndex: number;
    nameSeperator: string;
  }

}

export interface DefaultOutlet {
  id: number;
  value: string;
  viewValue: string;
}

export enum DefaultTeeSheetView {
  None = 0,
  DetailView,
  AdvanceDetailView
}
