<ng-container [formGroup]="contactForm" *ngIf="arrayName; else singleEmail">
  <div [formArrayName]="arrayName" class="mail-container ag_position--relative"
    *ngFor="let email of contactForm.get(arrayName)['controls']; let i= index; let first = first">
    <div [formGroupName]="i" class="mail-wrapper align-items-center">
      <div class="fixed-width--wrapper">
        <mat-form-field [ngClass]="optionClass" [floatLabel]="floatLabel">
          <mat-select [placeholder]="placeHolder" id="email{{i}}" [formControlName]="typeControlName"
            (selectionChange)="typeChange(email)">
            <mat-option *ngFor="let emailtype of options" [value]="emailtype.id">
              {{emailtype.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field [ngClass]="customClass" [floatLabel]="floatLabel">
          <input matInput [name]="controlName" [formControlName]="controlName" inputtype="email" [duplicateCheck]="controlName"
            [currentIndex]="i" [maxlength]="maxLength">
          <mat-error *ngIf="email.get(controlName).hasError('incorrect')">
            {{errorMessage}}
          </mat-error>
          <mat-error *ngIf="email.get(controlName).hasError('duplicate')">
            {{duplicateError}}
          </mat-error>
        </mat-form-field>
        <span class="icon-Plus"  [ngClass]="{'ag_section--disable':isEmailDisabled}" (click)="addEmailItem(i,'','')" *ngIf="first"></span>
        <span class="icon-Minus" [ngClass]="{'ag_section--disable':isEmailDisabled}" (click)="removeEmailItem(i)" *ngIf="!first"></span>
      </div>
      <ng-container *ngIf="showSwitches">
        <div class="ag-mail-switch primary-info-toggle">
          <label class="ag_form--label ag_common-label-width">{{primaryLabel}}</label>
          <ui-switch class="LW14 switch-toggle"  size="small" [formControlName]="primarySwitchName" [checkedLabel]="captions.Yes" [uncheckedLabel]="captions.No"
          (change)="togglePrimaryContact(i)">
        </ui-switch>
        </div>
        <div class="ag_pl--5 private-info-toggle">
          <label class="ag_form--label ag_common-label-width">{{privateLabel}}</label>
          <ui-switch class="LW14 switch-toggle"  size="small" [formControlName]="privateSwitchName" [checkedLabel]="captions.Yes" [uncheckedLabel]="captions.No">
          </ui-switch>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #singleEmail >
  <ng-container [formGroup]="contactForm">
    <mat-form-field [ngClass]="optionClass" [floatLabel]="floatLabel">
      <mat-select [placeholder]="placeHolder" [formControlName]="typeControlName"
        (selectionChange)="typeChange(contactForm)">
        <mat-option *ngFor="let emailtype of options" [value]="emailtype.id">
          {{emailtype.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field [ngClass]="customClass" [floatLabel]="floatLabel">
      <input matInput [name]="controlName" [formControlName]="controlName" inputtype="email" [maxlength]="maxLength">
      <mat-error *ngIf="contactForm.get(controlName).hasError('incorrect')">
        {{errorMessage}}
      </mat-error>
    </mat-form-field>
  </ng-container>
</ng-template>