import { Injectable } from '@angular/core';
import { ReportDownloadFormat, ReportAPIOptions, ReportParams, TeeTicketFilter } from 'src/app/reports/report.model';
import { ReportCode } from 'src/app/reports/common/report.constants';
import { PlayerPaymentstatus } from 'src/app/tee-time/search/search-model';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { FastReportBusinessService } from 'src/app/reports/fast-report/fastreport.business.service';
import { SettingsDataService } from 'src/app/shared/data-services/golfschedule/settings.data.service';
import { SettingModule, SettingScreen } from 'src/app/shared/global.constant';
import { API, TeeTimeData } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ComponentDetails, AlertType, ButtonType, AlertAction } from 'src/app/shared/shared-models';
import { ReplaySubject } from 'rxjs';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { AutoPilotReportDataService } from 'src/app/accounting/shared/dataservices/auto-pilot-report.data.service';
import { PrintInformationType } from "src/app/common/Models/printer-default-configuration.model";
import { RetailUtilities } from "src/app/retail/shared/utilities/retail-utilities";
import { PrintDocumentInput } from 'src/app/common/Models/common.models';

@Injectable({
  providedIn: 'root'
})
export class TeeTicketBusiness {
  captions: any;
  printFormat: ReportDownloadFormat = 'PDF';
  _dialog: MatDialog;
  popUpComponentDetails: ComponentDetails;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  teeTimeData: TeeTimeData;

  constructor(
    private _localization: GolfLocalization,
    private _fastReport: FastReportBusinessService,
    private _settingsDataService: SettingsDataService,
    private router: Router,
    private dialog: MatDialog,
    private _utils: GolfUtilities, private Utilites: RetailUtilities
  ) {
    this._dialog = dialog;
    this.captions = this._localization.captions.teeActions;
  }

  printTeeTickets(
    courseIds: number[],
    scheduleTeeTimeIds: number[],
    playerIds: number[],
    dateTime: string,
    isFromOrderSummary: boolean,
    callBack?: any
  ) {
    const propertyName = this._localization.GetPropertyInfo('PropertyName');
    const propertyDate = this._localization.ConvertDateToISODateTime(this._localization.getCurrentDate());
    let receiptPrinter: PrintDocumentInput = this.Utilites.getPrinterConfigurationToPrint(PrintInformationType.TeeTicket);
    
    scheduleTeeTimeIds = scheduleTeeTimeIds.distinct();    
    let isRequiredGroupingOfPlayers: boolean = this.teeTimeData?.toggleGroup?.printTeeTicketsPaidAgainstOneSlot && isFromOrderSummary && scheduleTeeTimeIds.length == 1 ;
    if(receiptPrinter?.printerName != "" && receiptPrinter != undefined && !isRequiredGroupingOfPlayers ){
     this.autoprint(courseIds,scheduleTeeTimeIds,playerIds,propertyName,propertyDate,receiptPrinter);
    }
    else{
      let _reportOptions: ReportAPIOptions = {
        code: ReportCode.TeeTicketReceipt,
        filters: this.formFilters(scheduleTeeTimeIds, playerIds, courseIds, isRequiredGroupingOfPlayers),
        format: this.printFormat,
        params: [{ pDate: propertyDate }, { pPropertyName: propertyName }],
        URIParams: this.formURIParams(),
        pageBreak: true
      };
  
      this._fastReport.downloadReport(this.printFormat, _reportOptions, true, callBack,false, receiptPrinter);  
    }    
  }

  private autoprint(courseIds: number[],
    scheduleTeeTimeIds: number[],
    playerIds: number[],propertyName: any,propertyDate: any,receiptPrinter: PrintDocumentInput)    
    {
       playerIds.forEach(async player => {
          let _reportOptions: ReportAPIOptions = {
            code: ReportCode.TeeTicketReceipt,
            filters: this.formFilters(scheduleTeeTimeIds, [player], courseIds),
            format: this.printFormat,
            params: [{ pDate: propertyDate }, { pPropertyName: propertyName }],
            URIParams: this.formURIParams(),
            pageBreak: true
          };    
        await this._fastReport.downloadReport(this.printFormat, _reportOptions, true, null,false, receiptPrinter);
    });
  }

  private formURIParams(): ReportParams[] {
    return [];
  }

  private formFilters(scheduleTeeTimeIds: number[], playerIds: number[], courseIds: number[], isRequiredGroupingOfPlayers: boolean = false): TeeTicketFilter {
    return {
      TeeTimeStatusIds: [PlayerPaymentstatus.booked | PlayerPaymentstatus.paid, PlayerPaymentstatus.booked | PlayerPaymentstatus.refund],
      ScheduleTeeTimeIds: scheduleTeeTimeIds,
      PlayerIds: playerIds,
      CourseIds: courseIds,
      IsRequiredGroupingOfPlayers: isRequiredGroupingOfPlayers
    };
  }

  async printTeeTicketsOnPlayersPaid(
    courseIds: number[],
    scheduleTeeTimeIds: number[],
    playerIds: number[],
    dateTime: string,
    callBack?: any
  ) {
     this.teeTimeData = await this.fetchTeeTimeData();
    if (this.teeTimeData.toggleGroup.autoPrintTeeTickets) {
      this.printTeeTickets(courseIds, scheduleTeeTimeIds, playerIds, dateTime, true, callBack);
    } else if (this.teeTimeData.toggleGroup.promptUserToPrintTeeTickets) {
      this._utils.showAlert(
        this.captions.PrintTeeTicketPromptMsg,
        AlertType.Info,
        ButtonType.YesNo,
        this.printTeeTicketCallback.bind(this),
        [courseIds, scheduleTeeTimeIds, playerIds, dateTime, true, callBack]
      );
    }
  }

  printTeeTicketCallback(res, params) {
    if (res === AlertAction.YES) this.printTeeTickets(params[0], params[1], params[2], params[3], params[4], params[5]);
  }

  public async fetchTeeTimeData(): Promise<TeeTimeData> {
    let apiData: API.Settings<API.TeeTimeConfig> = await this._settingsDataService.getSettings<API.TeeTimeConfig>(
      SettingModule.SystemSetup,
      SettingScreen.TeeTime
    );
    return this.mapToUI(apiData.configValue);

  }

  private mapToUI(apiData: API.TeeTimeConfig): TeeTimeData {
    return {
      tableArray: undefined,
      fieldGroup: {
        rainCheckNote: apiData.rainCheckNote,
        teeTimeTicketNote: apiData.ticketNote,
        tempHoldDuration: apiData.tempHoldDuration,
        firstDeposit: apiData.firstDeposit.isApplicable
          ? this._localization.localizePercentage(apiData.firstDeposit.depositPercentage.toString())
          : '',
        secondDeposit: apiData.secondDeposit.isApplicable
          ? this._localization.localizePercentage(apiData.secondDeposit.depositPercentage.toString())
          : '',
        depositRefund: apiData.depositRefund.isApplicable
          ? this._localization.localizePercentage(apiData.depositRefund.depositPercentage.toString())
          : '',
        dueDaysAfterBooking: apiData.firstDeposit.dueDays,
        dueDaysPriorToPlay1: apiData.secondDeposit.dueDays,
        dueDaysPriorToPlay2: apiData.depositRefund.dueDays
      },
      toggleGroup: {
        requirePlayerType: apiData.requirePlayerType,
        requireRateType: apiData.requireRateType,
        showMemberSignOnTeeGrid: apiData.showMemberSignOnTeeGrid,
        defaultToGraphicalTeeSheet: apiData.defaultToGraphicalTeeSheet,
        promptUserToPrintTeeTickets: apiData.promptUserToPrintTeeTickets,
        autoPrintTeeTickets: apiData.autoPrintTeeTickets,
        requireTeeTimesToBePaidNoShowedByDayEnd: apiData.requireTeeTimesToPaid, 
        printTeeTicketsPaidAgainstOneSlot: apiData.printTeeTicketsPaidAgainstOneSlot,
        setPaidOnDayOfPlay: apiData.setPaidOnDayOfPlay,
        requireCheckInCheckOut: apiData.requireCheckInCheckOut,
        enableAutoCheck: apiData.enableAutoCheck,
        allowPaymentInCheckIn: apiData.allowPaymentInCheckIn,
        autoCheckInCheckOutZeroCharges: apiData.autoCheckInCheckOutZeroCharges,       
        editCaddyInfoOnMoveCopy: apiData.editCaddyInfoOnMoveCopy,
        requireEformOnPaymentCheckIn: apiData.requireEformOnPaymentCheckIn,
        allowPlayerAllocationBlock:apiData.allowPlayerAllocationBlock,
        cancelPlayerFromTeeSheet: apiData.cancelPlayerFromTeeSheet,
        restrictPayAfterRefundForPreviousDay: apiData.restrictPayAfterRefundForPreviousDay,
        updateCustomFeeForExistingBookings:  apiData.updateCustomFeeForExistingBookings,
        updateRatesForExistingBooking: apiData.updateRatesForExistingBooking,
        includePlayersAcrossCoursesForCheckIn: apiData.includePlayersAcrossCoursesForCheckIn,
        checkForTeeTimeOverlaps: apiData.checkForTeeTimeOverlaps,
        overlapHours: apiData.overlapHours,
        allowNoShowPriortoPlay: apiData.allowNoShowPriortoPlay,
        allowCancellationAcrossSlots: apiData.allowCancellationAcrossSlots,
        isNoShowCancellationPolicyConfigured: apiData.isNoShowCancellationPolicyConfigured
      }
    };
  }
}
