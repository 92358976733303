import { API } from 'src/app/tee-time/tournaments/tournaments-modal/contact/contact.model';
import { TeeTimeSearchResult } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { ScheduledLessonSearchResult } from '../../models/lessons.modal';
import * as Instructor from '../../../settings/instructor-setup/instructor-setup/instructor-setup.model';

export enum searchtitleenum {
    region = 'region',
    item = 'item',
    course = 'course',
    timestamp = 'timestamp',
    code = 'code',
    TeeTimes = 'Tee Times',
    PlayerName = 'Players',
    Members = 'Members',
    Instructors = 'Instructors',
    Lessons = 'Lessons',
    RetailItems = 'Retail Items'

}


export interface GlobalSearchModel {
    displayname : string;
    title: GlobalSearchTitle;
    dataCollection: GlobalSearchData[];
}

export interface GlobalSearchTitle
{
    name : searchtitleenum;
}

export interface GlobalSearchData {
    id: number;
    timestamp?: string;
    code?: string;
    course?: string;
    region?: string;
    phNo?: string;
    item?: string;
    data?: any
    patronId?: string;
    vipType: string;
}

export interface GlobalSearchAggregate
{
    players: API.PlayerProfile[];
    teeTimes: TeeTimeSearchResult[];
    scheduledLesson: ScheduledLessonSearchResult[];
    instructor: Instructor.API.InstructorSearchResult[];
}