import { Injectable} from "@angular/core";
import { GolfManagementCommunication } from 'src/app/shared/communication/services/golfmanagement.service';
import { GolfScheduleCommunication } from 'src/app/shared/communication/services/golfschedule.service';
import { API } from '../../../settings/instructor-setup/instructor-setup/instructor-setup.model';
import { GolfLocalization } from '../../../core/localization/golf-localization';

@Injectable()
export class InstructorSetupDataService {
  constructor(private _golfManagementCommunication: GolfManagementCommunication,private _Localization:GolfLocalization,
    private _golfScheduleCommunication: GolfScheduleCommunication,) {
  }

  captionGenerator() {
    return this._Localization.captions.settings
  }

  public async getInstructorConfigurations(): Promise<API.InstructorConfiguration[]> {
    try {
      return this._golfManagementCommunication.getPromise<API.InstructorConfiguration[]>(
        { route: GolfApiRoute.GetInstructors });
    } catch (error) {
      console.error(error);
    }
  }
  public async getInstructorConfiguration(instructorId: number): Promise<API.InstructorConfiguration> {
    try {
      return this._golfManagementCommunication.getPromise<API.InstructorConfiguration>(
        { route: GolfApiRoute.GetInstructor, uriParams: { "instructorId": instructorId } });
    } catch (error) {
      console.error(error);
    }
  }
  public async createInstructorConfiguration(body: API.InstructorConfiguration): Promise<void> {
    try {
      return this._golfManagementCommunication.postPromise<void>(
        { route: GolfApiRoute.CreateInstructor, body: body }, true);
    } catch (error) {
      console.error(error);
    }
  }
  public async updateInstructorConfiguration(body: API.InstructorConfiguration): Promise<void> {
    try {
      return this._golfManagementCommunication.putPromise<void>(
        { route: GolfApiRoute.UpdateInstructor, body: body }, true);
    } catch (error) {
      console.error(error);
    }
  }
  public IsLessonBookedforInstructor(instructorId:number){
    try { 
      return this._golfScheduleCommunication.getPromise(
        { route: GolfApiRoute.IsLessonBookedforInstructor, uriParams: { "instructorId": instructorId } }, true);
    } catch (error) {
      console.error(error);
    }
  }

  public async searchInstructorConfiguration(pattern: string): Promise<API.InstructorSearchResult[]> {
    return this._golfManagementCommunication.getPromise<API.InstructorSearchResult[]>(
      { route: GolfApiRoute.SearchInstructor, uriParams: { pattern: pattern } }, true);
  }
}
