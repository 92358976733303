<ng-container >
<div class="template-sms-container newVersionWrapper template-sms-wrapper h-100">

    <div class="CustomTableGrid h-100">
        <button [attr.automationId]="'Btn_templateSms_createSmsTemplate'" [ngClass]="IsViewOnly || isToggleActive ? 'button_invalidbgcolor button--disabled' : 'body-bgcolor whitecolor'"
        class="float-right mr-2 createNewBtn text-capital mt18" mat-raised-button
        (click)="crudsmsTemplate()" [disabled]="IsViewOnly">{{smstemplateCaptions.createSmsTemplate}}</button>

        <div class="filter-btn">
          <i [ngClass]= "isToggleActive ? 'ag_section--disable': ''" aria-hidden="true" [popover]="myPopover" [popoverOnHover]="false" popoverPlacement="bottom" [popoverCloseOnMouseOutside]="true"
          class="icon-filter_filled cursor"></i>

        <popover-content #myPopover placement="bottom" [animation]="true" [closeOnClickOutside]="true"
          [closeOnMouseOutside]="true" >
          <div class="popover-section custpopHght">

            <mat-checkbox [attr.automationId]="'Chk_templateSms_description'" class="checkbox-container mr-2" (change)="filterTableData($event, defaultData)"
              [checked]="(SMSFilterselectionData.length == modifiedEvents.length )" title="defaultData.description">
              {{defaultData.description}}
            </mat-checkbox>
            <ng-container *ngFor="let filterdata of modifiedEvents; let i = index">
              <mat-checkbox [attr.automationId]="'Chk_templateSms_eventName'" class="checkbox-container mr-2" (change)="filterTableData($event, filterdata)"
                title="{{filterdata.eventName}}" [checked]="(SMSFilterselectionData.indexOf(filterdata) != -1)" >
                {{filterdata.eventName}}</mat-checkbox>
            </ng-container>

          </div>
          </popover-content>
        </div>

      <div class="mr-3 toggle-btn">
        <div class="ag_mb--1"><label
            class="LW12 pr-2 active-label label-color form-label-normal mb-0">{{smstemplateCaptions.ShowInactive}}</label>
        </div>
        <app-common-toggle-switch [attr.automationId]="'Txt_templateSms_showInactive'" [disabled]="isToggleActive" [ngModel]="showInactiveToggle" (changeToggleEvent)="showInactiveToggleEvent($event)"
          class="toggle-switch"></app-common-toggle-switch>
      </div>


        <app-common-table [attr.automationId]="'Tbl_templateSms_templateSms'" [options]="tableoptions" (deleteEvt)="DeleteRecords($event)" (editEvt)="EditRecords($event)"
        [isOnEditDisableFunctionAvailable]="onEditDisableFunction" (doneEvtEmitter)="DoneEvent($event)"
        (copyEmitter)="copyEvent($event)" (dragDropEvt)="DragDropEvent($event)" (toggleEvtEmitter) = "toggleEvtEmit($event)" (cancelEmitter) = "CancelEvent($event)"></app-common-table>
    </div>
</div>
</ng-container>

