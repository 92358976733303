import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfScheduleCommunication } from 'src/app/shared/communication/services/golfschedule.service';
import { DeleteTeeTimeSlotModel } from './delete-tee-time-slot.modal.model';

@Injectable({
  providedIn: 'root'
})
export class DeleteTeeTimeSlotService {
  constructor(private _golfScheduleCommunication: GolfScheduleCommunication, private _localization: GolfLocalization, private _http: GolfScheduleCommunication) { }
  public DeleteTeeTiemSlot(lstDeletedTeeTimeSlots: DeleteTeeTimeSlotModel[], callback?: any): Promise<boolean> {
    return this._http.postPromise<boolean>({
      route: GolfApiRoute.InsertTeeTimeDeletedSlot, body: lstDeletedTeeTimeSlots
    }, false, callback);
  }
  public async IsTempHoldExist(slotInfo: DeleteTeeTimeSlotModel) {
    var scheduledateTime = this._localization.ConvertDateToISODateTime(slotInfo.scheduleDateTime);
    return await this._golfScheduleCommunication.getPromise<boolean>({
      route: GolfApiRoute.IsTempHoldExist, uriParams: {
        "courseId": slotInfo.courseId, "scheduleDateTime": scheduledateTime, originalHoleNumber: slotInfo.originalHoleNumber
      }
    });
  }
}
