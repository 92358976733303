import { HttpCallService } from '../common/http-call.service';
import { ServiceParams } from '../../models/http.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfUtilities } from '../../utilities/golf-utilities';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class DocumentationService extends HttpCallService {

    constructor(httpclient: HttpClient, localization: GolfLocalization, utilities: GolfUtilities, PropertyInfo: GolfPropertyInformation) {
        super(environment["Documentation"], httpclient, localization, utilities, PropertyInfo);
    }

    public async httpPost(params: ServiceParams, handleErr: boolean = false) {
        let response : Promise<boolean> =  super.postPromise(params);
        return response;     
    }

}