import { HostListener, Injectable } from '@angular/core';
import { TeeTimeService } from './tee-time.service';
import { TeeTimeTabs, TeeTimeModel, ScheduledTeeTimeTimePlayer, PlayerDetail, TeeTimeTempHold ,TeeSlotNavigation} from './tee-time.model';
import { UntypedFormGroup } from '@angular/forms';
import { TeeSheetGridContent, Course, TeeTimeFormat, ScheduledPlayer,TeeSheetPlayerDetails,TeeSheetSkeletonData } from 'src/app/shared/models/teesheet.form.models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { UserDataService } from 'src/app/shared/data-services/tenantmanagement/user.data.service';
import { IssueRainCheckService } from 'src/app/shared/data-services/golfschedule/issueRainCheck.data.service';
import { FastReportBusinessService } from 'src/app/reports/fast-report/fastreport.business.service';
import { TeeSheetSharedBusiness } from 'src/app/tee-time/shared/teesheet.shared.business';
import { ReportAPIOptions } from 'src/app/shared/models/report.model';
import { RainCheckIssue, RainCheckReceiptPlayerDetail } from './fee-information/fee-information.model';
import { SettingsDataService } from 'src/app/shared/data-services/golfschedule/settings.data.service';
import { PhoneTypes, SettingModule, SettingScreen } from 'src/app/shared/global.constant';
import { API } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { LessonPlayer } from 'src/app/shared/models/lessons.modal';
import * as _ from 'lodash';
import { CustomFieldDataService } from 'src/app/shared/data-services/golfmanagement/customfield.data.service';
import { BookTeeTimesRuleResult } from 'src/app/shared/models/teesheet.api.models';
import { CourseDataService } from 'src/app/shared/data-services/golfschedule/course.data.service';
import { Course as CourseDetail } from 'src/app/settings/golf-setup/code-setup/course/create-course-modal/create-course-model';
import { BulkTeeTimeService } from 'src/app/tee-time/tee-sheet/bulk-tee-time/bulk-tee-time.service';
import { TeeTimeDataService } from 'src/app/shared/data-services/golfschedule/TeeTime.data.service';
import { TeeTimeAllocationBlock } from 'src/app/settings/golf-setup/golf-tee-time-allocation-block/golf-tee-time-allocation-block.model';
import { GolfTeeTimeAllocationBlockService } from 'src/app/settings/golf-setup/golf-tee-time-allocation-block/golf-tee-time-allocation-block.service';
import { AllocationBlockDataService as AllocationBlockManagementDataService } from 'src/app/shared/data-services/golfmanagement/allocationblock.data.service';
import { AllocationBlockWithPlayerType } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import { allocationBlockPlayerTypePermission } from 'src/app/settings/user-setup/booking-permission/booking-permission.model';
import { UserAccessModel } from 'src/app/common/dataservices/authentication/useraccess-model.model';
import { UpdatePlayerToGPSInterface } from './player-details/player-info.model';
import { environment } from 'src/environments/environment';
import { CartDetailUI, CartItineraryUI, CartPayload, CartWindowMessageType, GuestInfo, HeaderValue } from 'src/app/common/components/menu/vcart/vcart.modal';
import { ServiceParams } from '../../common/Models/http.model';
import { GolfRoutes } from '../../core/extensions/golf-route';
import { AlertType, PlayerTypes } from '../../shared/shared-models';
import { MailTypes } from '../../retail/shared/shared.modal';
import { CartUtilities } from 'src/app/common/components/menu/vcart/cart.utilities';
import { TeeTimeAllocationBlockEvent } from 'src/app/tee-time/search/search-model';
@Injectable()
export class TeeTimeBusiness {

  public isAllow = false;
  public isViewOnly = false;
  customFields: any;
  constructor(private _teeTimeService: TeeTimeService
    , private localization: GolfLocalization
    , private _userAccessBusiness: UserAccessBusiness
    , private _utilities: GolfUtilities
    , private _userDataService: UserDataService
    , private rainCheckService: IssueRainCheckService
    , private _fastReport: FastReportBusinessService
    , private _settingsDataService: SettingsDataService
    , private _customFieldService: CustomFieldDataService
    , public _courseDataService: CourseDataService
    , private _BulkTeeTimeService: BulkTeeTimeService
    , private _TeeTimeDataService: TeeTimeDataService
    , private _golfTeeTimeAllocationBlockService: GolfTeeTimeAllocationBlockService
    , private allocationCodeManagementService: AllocationBlockManagementDataService
    , private cartUtilities: CartUtilities
    ,private _teeSheetSharedBusiness :TeeSheetSharedBusiness) {
  }

  async validateBreakPoints(breakPointNumber: number, callback?: any, isGeneralInfoAccess?: any): Promise<UserAccessModel.BreakPointResult> {
    const isToShowError = isGeneralInfoAccess && isGeneralInfoAccess != null && (isGeneralInfoAccess.isAllow || isGeneralInfoAccess.isViewOnly) ? false : true;
    const result = await this._userAccessBusiness.getUserAccess(breakPointNumber, isToShowError, callback);
    this.isViewOnly = result.isViewOnly;
    this.isAllow = result.isAllow;
    return result;
  }

  async validateAllBreakPoints(breakPointNumbers: number[]) {
    return await this._userAccessBusiness.getUserAccesses(breakPointNumbers)
  }
  async ShowBreakpointError(breakPointNumber, result) {
    if (!result.isAllow && !result.isViewOnly) {
      let bpMessage = this.localization.captions.breakpoint[breakPointNumber];
      this._userAccessBusiness.showBreakPointPopup(bpMessage);
    }
  }

  async GetSlotPlayerDetails(scheduledTeeTimeDateTime ,originalHoleNumber, courseId ,slotNavigation:TeeSlotNavigation){
      var data = await this._teeTimeService.GetSlotPlayerDetails(scheduledTeeTimeDateTime,originalHoleNumber,courseId,slotNavigation);
      return await this._teeSheetSharedBusiness.MapSlotScheduledTeeTimeToBookTeeSheet(data);
  }
  GetCaptions() {
    return this._teeTimeService.getCaption();
  }
  MapTeeTimeInfo(data): TeeTimeModel{
    return {
      id: data.scheduledTeeTimeId,
      courseId: data.course.id,
      scheduleDateTime: data.allocationDateTime,
      holeNumber: data.holes,
      scheduleStatus:data.status,
      comment: data.comments,
      scheduledTeeTimePlayers: this.MapScheduledTeeTimePlayerDetails(data.playerDetail),
      createdDateTime: data.createdDateTime,
      originalHoleNumber: data.originalHoleNumber,
      viewedByStarter: data.viewedByStarter,
      playerId: 0
    }
  }


  MapScheduledTeeTimePlayerDetails(playerDetails: TeeSheetPlayerDetails[]):ScheduledTeeTimeTimePlayer[]{
    let teeTimePlayers: ScheduledTeeTimeTimePlayer[] = [];
    playerDetails.forEach((player, index) => {
        let teeTimePlayer: ScheduledTeeTimeTimePlayer = {
            id: player.scheduledTeeTimePlayerId,
            isPlayerDetailModified: false,
            scheduledTeeTimeId: player.scheduleTeeTimeId,
            playerId: player.playerId,
            playerDetail: this.getPlayerDetail(player),
            gameFormat: player.teeTimeFormat,
            caddyId: player.caddyId,
            gender: player.gender,
            caddyTypeId : player.caddyTypeId,
            holes: player.holes,
            holesPlayed: 0,
            cartId: player.cartId,
            cartType: player.cartType,
            isWalk: player.isWalk,
            isTrail: player.isTrail,
            playerComment: player.playerComment,
            packageCode: player.packageCode,
            groupId: player.groupId,
            bookingId: player.bookingId,
            playersPerGroup: player.playersPerGroup,
            playerSlotPosition: index + 1, // Players in sequence for slot position
            createdBy: player.createdBy,
            scheduledTeeTimePlayerFee:player.scheduledTeeTimePlayerFee,
            lastUpdatedDateTime: player.lastUpdatedDateTime,
            isBlocked: player.isBlocked,
            availableRounds : 0,
            overrideDetails : [],
            isMarkAsPaid : player.isMarkAsPaid,
            confirmationNumber: player.confirmationNumber,
            playerStatus: player.playerStatus

        }
        teeTimePlayers.push(teeTimePlayer);
    });
    return teeTimePlayers;
  }


  private getPlayerDetail(playerDetail): PlayerDetail {
    return {
        id: 0,
        firstName: playerDetail.firstName,
        lastName: playerDetail.lastName,
        pronounced: playerDetail.pronounced,
        gender: playerDetail.gender,
        customField1: playerDetail.customField1,
        customField2: playerDetail.customField2,
        customField3: playerDetail.customField3,
        customField4: playerDetail.customField4,
        customField5: playerDetail.customField5,
        playerCategoryId: playerDetail.playerCategoryId,
        playerAddress: playerDetail.playerAddress,
        contactInformation: playerDetail.contactInformation,
        paymentReferenceId: playerDetail.paymentReferenceId,
        extProfileId: playerDetail.extProfileId ? playerDetail.extProfileId : '',
        playerAdditionalDetails: playerDetail.playerAdditionalDetails,
        playerLinkId: playerDetail.playerLinkId,
        playerStayDetail: playerDetail.playerStayDetail,
        activityId: playerDetail.activityId,
        imageReferenceId: playerDetail.imageReferenceId,
        bagNumber: playerDetail.bagNumber
    } as PlayerDetail;
} 
  buildTeeTimeModelFromSlide(arg: TeeSheetGridContent): TeeTimeModel {
    return {
      id: 0,
      courseId: arg.course.id, // To do
      scheduleDateTime: this.localization.getDate(arg.time).toString(),
      holeNumber: arg.hole,
      scheduleStatus: 0,
      playerId: 0,
      tournamentId: 0,
      comment: '',
      scheduledTeeTimePlayers: [],
      originalHoleNumber: arg.originalHoleNumber


    };
  }
  GetAllCancellationNoShowPolicyByDate(date)
  {
    const _date: string = this.localization.convertDateObjToAPIdate(date);
    var data = this._teeTimeService.GetAllCancellationNoShowPolicyByDate(_date);
    return data;
  }
  GetDataFromSlideInput(popupData): TeeSheetGridContent {
    let obj: TeeSheetGridContent;
    if (popupData && popupData.bindData && popupData.bindData.length > 1) {
      obj = popupData.bindData[1].unmodifiedSlotData;
      obj.teeTimeId = popupData.bindData[1].scheduledTeeTimeId;
      obj.viewedByStarter = popupData.bindData[1]?.viewedByStarter ? popupData.bindData[1]?.viewedByStarter : false;
      obj.isBulkBooking = popupData.bindData[1].playerDetail ? popupData.bindData[1].playerDetail.filter(x => !x.isBlocked && x.teeTimeFormat == TeeTimeFormat.BulkTee).length > 0 : false;
    }
    return obj;
  }

  GetLessonPlayerDetail(popupData) {
    let obj: LessonPlayer;
    if (popupData && popupData.bindData && popupData.bindData.length > 1) {
      obj = popupData.bindData[1].lessonPlayerDetail;
    }
    return obj;
  }

  validateCurrentTab(tab: TeeTimeTabs, TeeForm: UntypedFormGroup, teeTimeInfo?: TeeTimeModel): boolean {
    let control;
    switch (tab) {
      case TeeTimeTabs.playerInformation:
        return teeTimeInfo && teeTimeInfo.scheduledTeeTimePlayers && teeTimeInfo.scheduledTeeTimePlayers.length > 0;
      case TeeTimeTabs.generalInformation: {
        control = TeeForm.get('generalInformation').get('generalInfo');
        if (TeeForm.getRawValue().generalInformation) {
          for (let i = 0; i < TeeForm.value.generalInformation.generalInfo.length; i++) {
            TeeForm.getRawValue().generalInformation.generalInfo[i].isEdited = control.controls[i].dirty;
          }
          this.updateGeneralInformation(teeTimeInfo, TeeForm.getRawValue().generalInformation.generalInfo, false);
        }
        return TeeForm.get('generalInformation').valid && control && control.value;
      }

      case TeeTimeTabs.feeInformation:
        control = TeeForm.get('feeInformation').get('rainChecks');
        return TeeForm.get('feeInformation').valid && control && control.value;
      case TeeTimeTabs.otherInformation: {
        control = TeeForm.get('otherInformation').get('otherInfo');
        if (TeeForm.getRawValue().otherInformation) {
          this.updateOtherInformation(teeTimeInfo, TeeForm.getRawValue().otherInformation);
        }

        return TeeForm.get('otherInformation').valid && control && control.value;
      }
    }
    return true;
  }

  FindNextTab(tab: TeeTimeTabs, TeeForm: UntypedFormGroup, hasUserAccess: boolean[], isSaveClose: boolean): TeeTimeTabs {
    const len = Object.keys(TeeTimeTabs).length / 2;
    let nextTab = tab + 1;
    for (let i = nextTab; i < len; i++) {
      if (isSaveClose) {
        nextTab = i;
        continue;
      } else if (hasUserAccess[i]) {
        return i;
      }
      nextTab = i;
    }

    return nextTab;
  }

  ReorderPlayerSlotsPositions(scheduledTeeTimePlayers: ScheduledTeeTimeTimePlayer[]) {
    if (scheduledTeeTimePlayers && scheduledTeeTimePlayers.length > 0) {
      const blockedPositions: number[] = scheduledTeeTimePlayers.filter(x => x.isBlocked).map(y => y.playerSlotPosition);
      const totalSlotPositions: number[] = [1, 2, 3, 4];
      const newPositions: number[] = totalSlotPositions.filter(x => !blockedPositions.some(y => y == x));
      let availabeSlotPositions: number[] = (blockedPositions && blockedPositions.length > 0) ? newPositions : totalSlotPositions;
      let slotPosition = 0;
      scheduledTeeTimePlayers.forEach((element, index) => {
        if (!element.isBlocked) {
          element.playerSlotPosition = availabeSlotPositions[slotPosition];
          slotPosition++;
        }
      });
    }

  }

  async Save(formData: any, tabData: TeeTimeModel, addToCart: boolean, editCartItemDetail: { cartItinerary: CartItineraryUI, cartDetail: CartDetailUI }) {
    let generalInformation;
    tabData.scheduleDateTime = this.localization.ConvertDateToISODateTime(this.localization.getDate(tabData.scheduleDateTime));
    if (tabData.scheduledTeeTimePlayers.length > 0) {
      const currentDate = this.localization.convertDateTimeToAPIDateTime(this.localization.getUTCDateTimeNow());
      for (let i = 0; i < tabData.scheduledTeeTimePlayers.length; i++) {
        if (!tabData.scheduledTeeTimePlayers[i].scheduledTeeTimePlayerFee.isCartFeeNegotiable) {
          tabData.scheduledTeeTimePlayers[i].scheduledTeeTimePlayerFee.isCartFeeNegotiable = false;
        }

        if (!tabData.scheduledTeeTimePlayers[i].scheduledTeeTimePlayerFee.isGreenFeeNegotiable) {
          tabData.scheduledTeeTimePlayers[i].scheduledTeeTimePlayerFee.isGreenFeeNegotiable = false;
        }

        if (!tabData.scheduledTeeTimePlayers[i].scheduledTeeTimePlayerFee.cartFee) {
          tabData.scheduledTeeTimePlayers[i].scheduledTeeTimePlayerFee.cartFee = 0;
        }

        if (!tabData.scheduledTeeTimePlayers[i].scheduledTeeTimePlayerFee.greenFee) {
          tabData.scheduledTeeTimePlayers[i].scheduledTeeTimePlayerFee.greenFee = 0;
        }

        tabData.scheduledTeeTimePlayers[i].createdDateTime = currentDate;
        tabData.scheduledTeeTimePlayers[i].lastUpdatedDateTime = currentDate;

      }
      this.ReorderPlayerSlotsPositions(tabData.scheduledTeeTimePlayers);
    }
    if (formData && formData.getRawValue().generalInformation) {
      generalInformation = formData.getRawValue().generalInformation.generalInfo;
      this.updateGeneralInformation(tabData, generalInformation, false);
    }

    if (formData && formData.getRawValue().otherInformation) {
      this.updateOtherInformation(tabData, formData.getRawValue().otherInformation);
    }
    tabData.scheduleStatus = 1;
    if (addToCart || editCartItemDetail) {

      let ruleResults: BookTeeTimesRuleResult[] = []; 
      const editTeeTime = tabData.scheduledTeeTimePlayers.find(o=> o.id > 0 ) ? true: false;
      if(editTeeTime)
      {
        // EDIT FLOW
        ruleResults = await this.ValidateBookTeeTimeRules(tabData,'EDIT_TEE_TIME');
      }
      else
      {
        ruleResults = await this.ValidateBookTeeTimeRules(tabData,'BOOK_TEE_TIME');
      }
      const failedRules = ruleResults.filter(o => o.ruleResults.filter(o => !o.isValid).length > 0);
      if(failedRules && failedRules.length)
      {
        // Do not add to the cart return the results to the component.ts to handle rule failure
        return ruleResults;
      }
      else{      
        return this.formVCartPayloadAndPostMessageToCart(tabData, editCartItemDetail, editTeeTime);
      }
    }else{
    return this._teeTimeService.Save(formData, tabData);
    }
  }
  public formVCartPayloadAndPostMessageToCart(tabData : TeeTimeModel,
    editCartItemDetail: { cartItinerary: CartItineraryUI, cartDetail: CartDetailUI } = null,
    updateTeeTime : boolean = false)
    {
    const teeTimeTempHolds = [];
    const dt = this.localization.ConvertDateToISODateTime(this._utilities.getDate(tabData.scheduleDateTime));
      const teeTimeTempHold: TeeTimeTempHold = {
        courseId: Number(tabData.courseId),
        createdBy: sessionStorage.getItem('quickIdUser') ? parseInt(sessionStorage.getItem('quickIdUser')) : Number(this.localization.GetPropertyInfo('UserId')),
        createdOn: dt,
        holeNumber: tabData.holeNumber,
        id: 0,
        releaseDateTime: dt,
        scheduleDateTime: dt,
        originalHoleNumber: tabData.originalHoleNumber
      };
      teeTimeTempHolds.push(teeTimeTempHold);
      let paramsReleaseHoldTeeTimeBody = {
        "courseId": tabData.courseId,
        "scheduledDateTime": tabData.scheduleDateTime,
        "holeNumber": tabData.holeNumber
      }
      let paramsTempHoldTeeTime = {
        route: GolfRoutes.ExtendTempHold,
        body: teeTimeTempHolds,
        uriParams: { "holdMinutes": 0 }
      }
      let paramsReleaseHoldTeeTime = {
        route: GolfRoutes.ReleaseTeeTimeHold,
        uriParams: {
          "courseId": tabData.courseId,
          "scheduledDateTime": tabData.scheduleDateTime,
          "holeNumber": tabData.holeNumber
        }
      }
      let paramsBookTeeTime = {
        route: GolfRoutes.BookTeeTime,
        body: tabData
      }
      let playerInfos : GuestInfo[];
      if(tabData.scheduledTeeTimePlayers && tabData.scheduledTeeTimePlayers.length > 0){
        playerInfos = this.getGuestInfoForVCart(tabData.scheduledTeeTimePlayers);
      }
      let payload: CartPayload = {
        userId: Number(this._utilities.GetPropertyInfo('UserId')),
        comments: '',
        description: this.localization.captions.teetime.teetime,
        activityType: 'TeeTime',
        cardInfos: null,
        price:
          tabData.scheduledTeeTimePlayers.filter(o=>o.id == 0).map(o => o.scheduledTeeTimePlayerFee.cartFee + o.scheduledTeeTimePlayerFee.greenFee).reduce((accumulator, obj) => {
            return accumulator + obj;
          }, 0),
        startDate: tabData.scheduleDateTime,
        endDate: tabData.scheduleDateTime,
        productCode: 'GOLF',
        guestInfos: playerInfos,
        depositAmount: 0,
        productId: parseInt(this._utilities.GetPropertyInfo('ProductId')),
        propertyId: parseInt(this._utilities.GetPropertyInfo('PropertyId')),
        tenantId: Number(this._utilities.GetPropertyInfo('TenantId')),
        bookAPI: {
          headers: this.getHeaders(),
          path: this.formURL(environment["GolfSchedule"] as unknown as string, paramsBookTeeTime),
          verb: updateTeeTime ?  'PUT' : 'POST',
          payload: JSON.stringify(tabData),
        },
        holdAPI: {
          headers: this.getHeaders(),
          path: this.formURL(environment["GolfSchedule"] as unknown as string, paramsTempHoldTeeTime),
          verb: 'PUT',
          payload: JSON.stringify(teeTimeTempHolds),
        },
        releaseAPI: {
          headers: this.getHeaders(),
          path: this.formURL(environment["GolfSchedule"] as unknown as string, paramsReleaseHoldTeeTime),
          verb: 'DELETE',
          payload: JSON.stringify(paramsReleaseHoldTeeTimeBody)
        },
        uiObject: null
      };
      this._utilities.showCommonAlert(this.localization.captions.settings.msg_TeeTimeAddedToCart, AlertType.Success);
      if (editCartItemDetail != null) {
        this.cartUtilities.pushMessageToParentWindow(CartWindowMessageType.UpdateCart,
          {
            cartDetailId: editCartItemDetail.cartDetail.id,
            cartItineraryId: editCartItemDetail.cartItinerary.id,
            cartPayload: payload
          });
      } else
        this.cartUtilities.pushMessageToParentWindow(CartWindowMessageType.AddToCart, payload);
      return Promise.resolve(null);
  }
  getGuestInfoForVCart(teetimeScheduledTeeTimePlayers : ScheduledTeeTimeTimePlayer[]) : GuestInfo[] {
    if(teetimeScheduledTeeTimePlayers && teetimeScheduledTeeTimePlayers.length > 0){
    const scheduledTeeTimePlayers : ScheduledTeeTimeTimePlayer[] = teetimeScheduledTeeTimePlayers.filter(player => player.id == 0);
    if(scheduledTeeTimePlayers && scheduledTeeTimePlayers.length > 0) {
      let guestInfos : GuestInfo[] = [];
      guestInfos = scheduledTeeTimePlayers.map(player => {
        return {
                firstName: player.playerDetail.firstName,
                lastName: player.playerDetail.lastName,
                guestId: (player.playerDetail.playerCategoryId == PlayerTypes.NonMember || player.playerDetail.playerCategoryId == PlayerTypes.Member) ? '00000000-0000-0000-0000-000000000000' : player.playerDetail.playerLinkId, // Default for Walkin Player
                platformGuestUuid:'00000000-0000-0000-0000-000000000000',
                email: player.playerDetail.contactInformation?.map(phnNum => {
                      if (phnNum.type == MailTypes.office || phnNum.type == MailTypes.personal) {
                        return {
                          isPrivate: phnNum.isPrivateInfo,
                          isPrimary: phnNum.isPrimary,
                          value: phnNum.value,
                          type: phnNum.type
                        }
                      }
                    }),
                phone: player.playerDetail.contactInformation?.map(phnNum => {
                  if (phnNum.type == PhoneTypes.home || phnNum.type == PhoneTypes.mobile || phnNum.type == PhoneTypes.office) {
                    return {
                        isPrivate: phnNum.isPrivateInfo,
                        isPrimary: phnNum.isPrimary,
                        value: phnNum.value,
                        type: phnNum.type
                      }
                    }
                  })
                }
              });
              if(guestInfos && guestInfos.length > 0)
                return guestInfos;
          }
    }
    return [];
  }
  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
  private formURL(baseURL: string, params: ServiceParams): string {
    let url: string = `${baseURL}/`;
    if (params.uriParams != undefined && params.uriParams != null && typeof params.uriParams == "object") {
      let route: string = params.route;
      let keys: string[] = Object.keys(params.uriParams);
      for (let key of keys) {
        const escapedKey = this.escapeRegExp(key);
        var regEx = new RegExp("{" + escapedKey + "}", "ig");
        route = route.replace(regEx, params.uriParams[key]);
      }
      url += route;
    } else {
      url += params.route;
    }
    url = this.formatQueryString(url, params);
    if (!(url.indexOf("http://") > -1 || url.indexOf("https://") > -1))
      //url = window.location.origin + url;
      url = sessionStorage.getItem('appurl') + url
    return url;
  }
  private formatQueryString(url: string, params: ServiceParams): string {

    let queryParams: string[] = this.matchQueryStringRegex(url);
    for (var queryParam of queryParams) {
      var paramName = queryParam.split(":")[0];
      paramName = paramName ? paramName : "";
      // paramName = paramName.replace("{", "");
      paramName = paramName.replace(/\{/g, '');
      var qParamValue = params.uriParams[paramName];
      var qParamString = "";
      if (typeof qParamValue == "object" && qParamValue && qParamValue.length > 0) {
        for (var value of qParamValue) {
          qParamString += `${paramName}=${value}&`
        }
        // To remove last &
        qParamString = qParamString.substr(0, qParamString.length - 1);
      }
      else {
        qParamString = `${paramName}=${qParamValue}`;
      }
      url = url.replace(queryParam, qParamString);
    }
    return url;
  }
  private matchQueryStringRegex(url: string): string[] {
    var regex = /{[A-Z]+:QueryString}/gi;
    var expMatch: RegExpExecArray;
    var result: string[] = [];
    while ((expMatch = regex.exec(url)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (expMatch.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      // The result can be accessed through the `m`-variable.
      expMatch.forEach((match, groupIndex) => {
        result.push(match);
      });
    }
    return result;
  }
  private getHeaders() {
    let headers: HeaderValue[] = [];
    let token = null;
    let userSessionId = null;
    if (sessionStorage.getItem("quickIdJwt")) {
      token = sessionStorage.getItem("quickIdJwt");
      userSessionId = sessionStorage.getItem("quickIdUserSession");
    }
    else {
      token = sessionStorage.getItem("_jwt");
      userSessionId = sessionStorage.getItem("userSession");
    }
    headers.push({ key: 'Accept-Language', value: navigator.language });
    headers.push({ key: 'Content-Type', value: 'application/json' });
    headers.push({ key: 'Authorization', value: token ? 'Bearer ' + token : "" });
    headers.push({ key: 'SessionId', value: userSessionId ? userSessionId : "" });
    return headers;
  }

  generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
  async Update(formData: any, tabData: TeeTimeModel, isBulkEdit: boolean, addToCart: boolean = false): Promise<any> {
    let generalInformation;
    let canEditCaddy = false;
    tabData.scheduleDateTime = this.localization.ConvertDateToISODateTime(this.localization.getDate(tabData.scheduleDateTime));
    if (tabData.scheduledTeeTimePlayers.length > 0) {
      const currentDate = this.localization.convertDateTimeToAPIDateTime(this.localization.getUTCDateTimeNow());
      for (let i = 0; i < tabData.scheduledTeeTimePlayers.length; i++) {
        if (!tabData.scheduledTeeTimePlayers[i].scheduledTeeTimePlayerFee.isCartFeeNegotiable) {
          tabData.scheduledTeeTimePlayers[i].scheduledTeeTimePlayerFee.isCartFeeNegotiable = false;
        }

        if (!tabData.scheduledTeeTimePlayers[i].scheduledTeeTimePlayerFee.isGreenFeeNegotiable) {
          tabData.scheduledTeeTimePlayers[i].scheduledTeeTimePlayerFee.isGreenFeeNegotiable = false;
        }

        if (!tabData.scheduledTeeTimePlayers[i].scheduledTeeTimePlayerFee.cartFee) {
          tabData.scheduledTeeTimePlayers[i].scheduledTeeTimePlayerFee.cartFee = 0;
        }

        if (!tabData.scheduledTeeTimePlayers[i].scheduledTeeTimePlayerFee.greenFee) {
          tabData.scheduledTeeTimePlayers[i].scheduledTeeTimePlayerFee.greenFee = 0;
        }
        tabData.scheduledTeeTimePlayers[i].createdDateTime = tabData.scheduledTeeTimePlayers[i].createdDateTime ? tabData.scheduledTeeTimePlayers[i].createdDateTime : currentDate;
        tabData.scheduledTeeTimePlayers[i].lastUpdatedDateTime = tabData.scheduledTeeTimePlayers[i].lastUpdatedDateTime == undefined || tabData.scheduledTeeTimePlayers[i].isPlayerDetailModified ? currentDate : tabData.scheduledTeeTimePlayers[i].lastUpdatedDateTime;

      }
      this.ReorderPlayerSlotsPositions(tabData.scheduledTeeTimePlayers);
    }
    if (formData && formData.getRawValue().generalInformation) {
      canEditCaddy = formData.getRawValue().generalInformation.canEditCaddy;
      generalInformation = formData.getRawValue().generalInformation.generalInfo;
      this.updateGeneralInformation(tabData, generalInformation, true);
    }
    if (formData && formData.getRawValue().otherInformation) {
      this.updateOtherInformation(tabData, formData.getRawValue().otherInformation);
    }
    tabData.scheduleStatus = 1;
    if (isBulkEdit) {
      if (!canEditCaddy) {
        canEditCaddy = this._teeTimeService.canEditCaddy;
      }
      return this._teeTimeService.UpdateBulkTeeTime(formData, tabData, canEditCaddy);

    } else {
      if(addToCart){
        const ruleResults = await this.ValidateBookTeeTimeRules(tabData, 'EDIT_TEE_TIME');
        const failedRules = ruleResults.filter(o => o.ruleResults.filter(o => !o.isValid).length > 0);
        if(failedRules && failedRules.length)
        {
          // Do not add to the cart return the results to the component.ts to handle rule failure
          return ruleResults;
        }else {
          return this.formVCartPayloadAndPostMessageToCart(tabData, null, true);
        }
      }
      else
        return this._teeTimeService.Update(formData, tabData);
    }

  }

  async showRuleFailure(bookTeeTimesRuleResult: BookTeeTimesRuleResult[], isBulkEdit: boolean): Promise<boolean> {
    let result = true;
    const failedSlots = bookTeeTimesRuleResult.filter(o => o.ruleResults.filter(o => !o.isValid).length > 0);
    if (failedSlots && failedSlots.length > 0) {
      const ruleResults = failedSlots[0].ruleResults;
      const ruleResult = ruleResults.filter(x => !x.isValid);
      if (ruleResult.length > 0) {
        result = false;
        const failedRuleResult = ruleResult[0];
        let error = this.localization.getError(failedRuleResult.errorCodes[0]);
        if (failedRuleResult.errorCodes[0] == 30401) {
          error = this.localization.getError(failedRuleResult.data.DaysOutData[0].errorCode);
          error = error.interpolate({ date: this.localization.localizeDisplayDate(failedRuleResult.data.DaysOutData[0].date) });
        }
        else if (failedRuleResult.errorCodes[0] == 11010 || failedRuleResult.errorCodes[0] == 11015) {
          error = error.interpolate({ user: failedRuleResult.data.UserName ? failedRuleResult.data.UserName : "" });
        }
        else if (failedRuleResult.errorCodes[0] == 30432) {
          result = true;
        }
        if (isBulkEdit) {
          const course: CourseDetail = await this._courseDataService.getCourse(failedSlots[0].courseId);
          this._utilities.showError(this.localization.captions.teetime.course + ': ' + (course ? course.name : '') + ' | ' + this.localization.captions.teetime.teetime + ': ' + this.localization.LocalizeDate(failedSlots[0].scheduleDateTime) + ' | ' + this.localization.captions.teetime.hole + ': ' + failedSlots[0].holeNumber + '<br><br>' + error);
        } else {
          this._utilities.showError(error);
        }
      }
    }
    return result;
  }

  getErrorCodesFromRuleResult(bookTeeTimesRuleResult: BookTeeTimesRuleResult[]): number {
    let errorCode = 0;
    const failedSlots = bookTeeTimesRuleResult.filter(o => o.ruleResults.filter(o => !o.isValid).length > 0);
    if (failedSlots && failedSlots.length > 0) {
      const ruleResults = failedSlots[0].ruleResults;
      const ruleResult = ruleResults.filter(x => !x.isValid);
      if (ruleResult.length > 0) {
        const failedRuleResult = ruleResult[0];
        errorCode = failedRuleResult.errorCodes[0];
      }
    }
    return errorCode;
  }
  GetTeeTimesofCourseByDate(id: number, date: string) {
    return this._teeTimeService.GetTeeTimesofCourseByDate(id, date);
  }

  GetTeeTimeByScheduleId(teeTimeId: number) {
    return this._teeTimeService.GetTeeTimeByScheduleId(teeTimeId);
  }

  GetAllUsers() {
    return this._userDataService.getUsers();
  }

  async printRainCheckReceipt(rainCheckIssue: RainCheckIssue[], tabData: TeeTimeModel, course: Course) {

    let _reportOptions: ReportAPIOptions = {
      code: 'RainCheckReceipt',
      params: await this.formReportParams(rainCheckIssue, tabData, course),
      URIParams: null,
      filters: null,
      pageBreak: true
    };
    this._fastReport.downloadReport('PDF', _reportOptions, true, null, true);
  }

  async formReportParams(rainCheckIssue: RainCheckIssue[], tabData: TeeTimeModel, course: Course) {
    const rainCheckReceiptPlayerDetail: RainCheckReceiptPlayerDetail[] = [];
    const apiData: API.Settings<API.TeeTimeConfig> = await this._settingsDataService.getSettings<API.TeeTimeConfig>(SettingModule.SystemSetup, SettingScreen.TeeTime);

    if (rainCheckIssue != null) {
      rainCheckIssue.forEach((rainCheck) => {
        const playerInfo = tabData.scheduledTeeTimePlayers.find((player) => {
          return player.playerId === rainCheck.playerId;
        });
        rainCheckReceiptPlayerDetail.push({
          firstName: playerInfo.playerDetail.firstName, lastName: playerInfo.playerDetail.lastName,
          issuingClerk: rainCheck.issuedBy,
          issuedDate: rainCheck.issuedDate,
          rainCheckId: rainCheck.number, value: parseFloat(rainCheck.rainCheckValue.toString()),
          fullName: this._utilities.formatGuestName(playerInfo.playerDetail.firstName, playerInfo.playerDetail.lastName)
        });
      });
    }
    return [{ pPropertyName: this.localization.GetPropertyInfo('PropertyName') },
    { pCourseName: course.course },
    { pRainCheckNote: apiData.configValue.rainCheckNote },
    { RainCheckReceiptPlayerDetail: JSON.stringify(rainCheckReceiptPlayerDetail) }
    ];
  }


  async saveRainChecks(tabData: TeeTimeModel, course: Course) {
    if (this._teeTimeService.deleteRainChecks && this._teeTimeService.deleteRainChecks.length > 0) {
      const result = await this.rainCheckService.DeleteIssuedRainCheck(this._teeTimeService.deleteRainChecks);
      if (result) {
        this._teeTimeService.deleteRainChecks.forEach(id => {
          const playerInfo = tabData.scheduledTeeTimePlayers.find(player => player.scheduledTeeTimePlayerFee.rainCheck === id);
          playerInfo.scheduledTeeTimePlayerFee.rainCheck = '';
        });
      }
      this._teeTimeService.deleteRainChecks = [];
    }
    if (this._teeTimeService.rainCheckIssues && this._teeTimeService.rainCheckIssues.length > 0) {
      const createList = this._teeTimeService.rainCheckIssues.filter(x => x.id === 0);
      this.rainCheckService.CreateIssuedRainCheck(createList).then(result => {
        let receiptData: any = [];
        result.forEach(raincheckIssue => {
          const playerInfo = tabData.scheduledTeeTimePlayers.find(player => player.playerId === raincheckIssue.playerId);
          playerInfo.scheduledTeeTimePlayerFee.rainCheck = raincheckIssue.id;
          let individualReciptData: any = raincheckIssue;
          individualReciptData.rainCheckValue = raincheckIssue.rainCheckValue.customToFixed();
          receiptData.push(individualReciptData);
        });
        this.printRainCheckReceipt(receiptData, tabData, course);
        this._teeTimeService.rainCheckIssues = [];
      });
    }
  }

  updateOtherInformation(tabData, otherInfoForm) {
    tabData.comment = otherInfoForm.teeTimeComment;
    const otherInformation = otherInfoForm.otherInfo;
    const scheduledTeeTimePlayers = tabData.scheduledTeeTimePlayers;
    const currentDate = this.localization.convertDateTimeToAPIDateTime(this.localization.getUTCDateTimeNow());
    const userId = Number(this.localization.GetPropertyInfo('UserId'));
    for (let i = 0; i < scheduledTeeTimePlayers.length; i++) {
      if (tabData.scheduledTeeTimePlayers[i].id > 0 && (tabData.scheduledTeeTimePlayers[i].playerComment !== otherInformation[i].playerComment || tabData.scheduledTeeTimePlayers[i].packageCode !== otherInformation[i].packageCode)) {
        tabData.scheduledTeeTimePlayers[i].lastUpdatedDateTime = currentDate;
        tabData.scheduledTeeTimePlayers[i].lastUpdatedBy = userId;
      }
      tabData.scheduledTeeTimePlayers[i].packageCode = otherInformation[i].packageCode;
      tabData.scheduledTeeTimePlayers[i].playerComment = otherInformation[i].playerComment;
    }
  }

  updateGeneralInformation(tabData, generalInformation, isUpdate) {
    const userId = Number(this.localization.GetPropertyInfo('UserId'));
    const currentDate = this.localization.convertDateTimeToAPIDateTime(this.localization.getUTCDateTimeNow());
    for (let i = 0; i < generalInformation.length; i++) {
      tabData.scheduledTeeTimePlayers[i].isTrail = generalInformation[i].trail ? generalInformation[i].trail : false;
      tabData.scheduledTeeTimePlayers[i].isWalk = generalInformation[i].walk ? generalInformation[i].walk : false;
      tabData.scheduledTeeTimePlayers[i].cartId = generalInformation[i].cart ? generalInformation[i].cart : 0;
      tabData.scheduledTeeTimePlayers[i].playerDetail.gender = generalInformation[i].gender;
      tabData.scheduledTeeTimePlayers[i].confirmationNumber = generalInformation[i].confirmationNo;
      tabData.scheduledTeeTimePlayers[i].lastUpdatedBy = isUpdate && tabData.scheduledTeeTimePlayers[i].isPlayerDetailModified ? userId : tabData.scheduledTeeTimePlayers[i].lastUpdatedBy;
      tabData.scheduledTeeTimePlayers[i].caddyId = generalInformation[i].caddyId ? generalInformation[i].caddyId : 0;
      tabData.scheduledTeeTimePlayers[i].caddyTypeId = generalInformation[i].caddyType ? generalInformation[i].caddyType : 0;
      tabData.scheduledTeeTimePlayers[i].cartType = generalInformation[i].cartType ? generalInformation[i].cartType : '';
      if (generalInformation[i].isEdited) {
        tabData.scheduledTeeTimePlayers[i].lastUpdatedDateTime = currentDate;
        tabData.scheduledTeeTimePlayers[i].lastUpdatedBy = userId;
      } else {
        tabData.scheduledTeeTimePlayers[i].createdBy = tabData.scheduledTeeTimePlayers[i].createdBy ? tabData.scheduledTeeTimePlayers[i].createdBy : userId;
      }
    }
    this.ReorderPlayerSlotsPositions(tabData.scheduledTeeTimePlayers);
  }

  async updateCustomFields(customFieldsNewData: any) {
    const selectedCustomFields = [];
    const customFieldsOldData = await this._customFieldService.GetAllCustomFieldsAndValues();
    if (customFieldsOldData && customFieldsNewData) {
      for (let i = 0; i < customFieldsOldData.length; i++) {
        if (!_.isEqual(customFieldsOldData[i], customFieldsNewData[i])) {
          selectedCustomFields.push(customFieldsNewData[i]);
        }
      }
    }
    if (selectedCustomFields.length > 0) {
      this._customFieldService.UpdateCustomFieldsIsUtilized(selectedCustomFields);
    }
  }
  async GetEmailConfigurationSetting(): Promise<API.Settings<API.EmailConfigurationSettingConfig>> {
    return this._settingsDataService.getSettings<API.EmailConfigurationSettingConfig>(SettingModule.SystemSetup, SettingScreen.EmailConfiguration);
  }

  async validateMixedBreakPoints(breakPointNumber: number, showUserMessage: boolean, callback?: any): Promise<UserAccessModel.BreakPointResult> {
    return await this._userAccessBusiness.getUserAccess(breakPointNumber, showUserMessage, callback);

  }

  async getBulkPlayers(bookingId: string): Promise<ScheduledPlayer[]> {
    return await this._TeeTimeDataService.getScheduledPlayersByBookingId(bookingId);
  }

  public async saveTeeTimeAllocationBlock(teeTimeAllocationBlock: TeeTimeAllocationBlock, teeTimeAllocationBlockEvent: TeeTimeAllocationBlockEvent) {
    return await this._golfTeeTimeAllocationBlockService.saveTeeTimeAllocationBlock([teeTimeAllocationBlock], teeTimeAllocationBlockEvent);

  }

  public async saveTeeTimeAllocationBlockList(teeTimeAllocationBlock: TeeTimeAllocationBlock[], teeTimeAllocationBlockEvent: TeeTimeAllocationBlockEvent) {
    return await this._golfTeeTimeAllocationBlockService.saveTeeTimeAllocationBlock(teeTimeAllocationBlock, teeTimeAllocationBlockEvent);

  }

  public async getTeeTimeAllocationBlock(teeTimeAllocationBlock) {
    return await this._golfTeeTimeAllocationBlockService.getTeeTimeAllocationBlocksForBulkTeeTime(teeTimeAllocationBlock);

  }

  async GetTeeTimeAllocationBlocksByDateRange(courseId: number, startTime: Date | string, endTime: Date | string): Promise<TeeTimeAllocationBlock[]> {
    return await this._golfTeeTimeAllocationBlockService.getTeeTimeAllocationBlocksByCourseDateRange(courseId, startTime, endTime);
  }

  public async GetAllocationsBlockWithPlayerTypes(): Promise<AllocationBlockWithPlayerType[]> {
    return await this.allocationCodeManagementService.getAllocationsBlockWithPlayerTypes();
  }

  public async GetAllocationBlockPermissionByRole(roleId?: string): Promise<allocationBlockPlayerTypePermission[]> {
    return await this.allocationCodeManagementService.getAllocationBlockPermissionByRole(roleId);
  }

  public UpdatePlayerToGPSInterface(tabData: TeeTimeModel, updatePlayerToGPSInterface: UpdatePlayerToGPSInterface[]) {
    if (tabData && updatePlayerToGPSInterface && updatePlayerToGPSInterface.length > 0) {
      let playerDetails: PlayerDetail[] = [];
      tabData.scheduledTeeTimePlayers.forEach(x => {
        if (updatePlayerToGPSInterface.find(y => y.slotPosition == x.playerSlotPosition)) {
          playerDetails.push(x.playerDetail);
        }
      });

      const response = this._teeTimeService.UpdatePlayerToGPSInterface(playerDetails);
      console.log('UpdatePlayerToGPSInterface', response);
    }
  }

  public async ValidateBookTeeTimeRules(tabData: TeeTimeModel, moduleCode: string) : Promise<BookTeeTimesRuleResult[]>{
    let results = await this._teeTimeService.ValidateBookTeeTimeRules(tabData, moduleCode);
      if(results){
        return results;
      }else
      {
        return [];
      }
  }
}
