import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { teeSheetMenu } from '../tee-sheet-constants';
import { GolfScheduleCommunication } from 'src/app/shared/communication/services/golfschedule.service';
import { TeeAndPlayersCount } from 'src/app/home/dashboard-widgets-report/dashboard.modal';



@Injectable()
export class TeeSheetService {
  constructor(private _HttpClient: HttpClient, private _localization: GolfLocalization, private _http: GolfScheduleCommunication) { }
  notifierBar = {
    class: "icon-servicecharge",
    value: "This course is under service from 1pm to 3pm today",
    color: "#fff16e"
  }
  captions: any = this._localization.captions.teetime;

  getCourses() {
    return [
      {
        value: 'jones',
        viewValue: 'Jones'
      },
      {
        value: 'jones',
        viewValue: 'Jones'
      },
      {
        value: 'jones',
        viewValue: 'Jones'
      },

    ];
  }

  getActionList() {
    return {
      buttonLabel: this.captions.AdditionalAction,
      actions: [{
        value: teeSheetMenu.groupTeeTime,
        label: this.captions.GroupTeeTime
      },
      {
        value: teeSheetMenu.bulkTeeTime,
        label: this.captions.BulkTeeTime
      },
      {
        value: teeSheetMenu.holdBlockTeeTime,
        label: this.captions.HoldBlockTeeTime
      },
      {
        value: teeSheetMenu.bulkNotify,
        label: this.captions.lbl_bulk_notify
      },
    ]
    };
  }
  getFilterConfiguration() {
    return {
      filterText: this.captions.filters,
      resetText: this.captions.resetAll,
      displayCount: 3,
      isMultiSelect: true,
      viewMoreConfig: {
        apply: this.captions.ok, //Updated for Bug ID: 24927 after the discussion with Dev and QA. This shouldn't be modified at any cost. 
        cancel: this.captions.cancel,
        alphabets: [this.captions.All,
          'A', 'B', 'C', 'D',
          'E', 'F', 'G', 'H',
          'I', 'J', 'K', 'L',
          'M', 'N', 'O', 'P',
          'Q', 'R', 'S', 'T',
          'U', 'V', 'W', 'X',
          'Y', 'Z'],
        searchByPlaceHolder: this.captions.searchBy
      }
    };
  }

  getFilterGroups() {
    return [
      {
        id: 1, name: 'Colors',
        title: 'Colors',
        filters: [{ id: 1, name: 'Red' },
        { id: 2, name: 'Green' },
        { id: 3, name: 'Maroon' },
        { id: 4, name: 'Yellow' },
        { id: 5, name: 'white' },
        { id: 6, name: 'Grey' },
        { id: 7, name: 'pink' },
        { id: 8, name: 'Violet' },
        { id: 9, name: 'Indigo' },
        { id: 10, name: 'Orange' }
        ]
      }
    ];
  }

  getDateFilter() {
    return {
      name: 'DateRange',
      filters: [{
        name: 'FromDate',
        placeholder: 'StartDate',
        defaultValue: new Date(),
        minDate: new Date(),
      },
      {
        name: 'ToDate',
        placeholder: 'EndDate',
        defaultValue: new Date(),
        minDate: new Date(),
      }
      ]
    };
  }

  getSliderInputs(teeRowData) {
    return {
      buttonText: this.captions.bookTeeTime,
      status: 'Open',
      statusColor: '1',
      details: [{
        label: 'Course',
        text: 'Jones'
      },
      {
        label: 'Allocation Block',
        text: 'Twilight',
        colorCode: '#00397c'
      }],
      dateTime: {
        dateLabel: 'Date',
        dateText: '21-Jul-2019',
        timeLabel: 'Time',
        timeText: '09.08'
      },
      players: [{
        name: 'Mark Henderson'
      },
      {
        name: 'Luka Modric'
      },
      {
        name: 'John Anderson'
      },
      {
        name: 'Dwyane Johnson'
      }],
      deposit: 100,
      actionTitle: 'Tee Actions',
      actions: [{
        icon: 'sqeeze',
        label: 'Squeeze',
        id: 'squeeze'
      },
      {
        icon: 'cancel',
        label: 'Cancel Players',
        id: 'cancelPlayers'
      },
      {
        icon: 'tee-time',
        label: 'Tee Time',
        id: 'teeTime'
      },
      {
        icon: 'frost-delay',
        label: 'Frost Delay',
        id: 'frostDelay'
      },
      {
        icon: 'recap',
        label: 'Print',
        id: 'print'
      },
      {
        icon: 'hold',
        label: 'Hold Tee Time',
        id: 'holdTeeTime'
      },
      {
        icon: 'grautity',
        label: 'Payment',
        id: 'payment'
      },
      {
        icon: 'rate',
        label: 'Rates',
        id: 'rate'
      },
      {
        icon: 'scheduled',
        label: 'Scheduled',
        id: 'scheduled'
      },
      {
        icon: 'recap',
        label: 'Recap',
        id: 'recap'
      },
      {
        icon: 'copy',
        id: 'copy',
        label: 'Copy'
      },
      {
        icon: 'noshows',
        id: 'noshows',
        label: 'No Show'
      },
      {
        icon: 'clear-players',
        id: 'clearPlayers',
        label: 'Clear Players'
      },
      {
        icon: 'move',
        id: 'move',
        label: 'Move'
      },
      {
        id: "refund",
        icon: 'move',
        label: 'Refund'
      },
      {
        icon: 'move',
        id: 'copyToMany',
        label: 'Copy to Many'
      },
      {
        icon: 'sendmail1',
        id: 'Notify',
        label: 'Notify'
      }]

    };
  }

  getTeeSheeetID() {
    return {
      tableID: 'TableTeeTime'
    };
  }

  getTeeSheeetIDRightGrid() {
    return {
      tableID: 'TableTeeTimeRight'
    };
  }

  getTeeSheetCustomTableData() {
    return {
      isMultiView: false,
      isShowAvailableOnly: true,
      isHideCrossover: true,
      isDetailView: false,
      isOrderByHoleTime: true,
      isHoleNotificationEnabled: true,
      isCommentNotificationEnabled: true,
      isActionAvailable: true,
      isMenuEnabledOnEllipsis: false,
      isPlayerDetailIconsEnabled: true,
      isDragDisabled: false,
      isTooltipDisabled: false,
      isDeletePlayerAllowed: true
    };
  }

  getTeeSheetSkeletonData() {
    return this._HttpClient.get('assets/json/tee-sheet-skeleton.json');
  }

  getTeeSheetSkeletonDataRightGrid() {
    return this._HttpClient.get('assets/json/tee-sheet-skeletonright.json');
  }

  getTeeSheetPlayerData() {
    return this._HttpClient.get('assets/json/tee-sheet-playerdata.json');
  }

  getTeeSheetPlayerDataRightGrid() {
    return this._HttpClient.get('assets/json/tee-sheet-playerdataright.json');
  }
  public getTeeTimeAndPlayersCount<T>(dateTime: Date, courseIds: number[], isRepeatPlayersCountRequired : boolean = false): Promise<TeeAndPlayersCount> {
    const _date: string = this._localization.ConvertDateToISODateTime(dateTime);
    return this._http.putPromise<TeeAndPlayersCount>(
      { route: GolfApiRoute.GetPlayerTeeTimeCount, uriParams: { dateTime: _date, isRepeatPlayersCountRequired : isRepeatPlayersCountRequired }, body: courseIds });
  }
  public async GetScheduledTeeTimeByActivityId<T>(activityId): Promise<any> {
    return this._http.getPromise<any>(
      { route: GolfApiRoute.GetTeeTimesByActitivyId, uriParams: { activityId: activityId } });
  }
}
