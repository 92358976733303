import { HttpCallService } from '../common/http-call.service';
import { ServiceParams, BaseResponse } from '../../models/http.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfUtilities } from '../../utilities/golf-utilities';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { environment } from 'src/environments/environment';

@Injectable()
/**
 * Communication layer for golf management micro service
 * HttpCalls can be overriden here
**/
export class GolfManagementCommunication extends HttpCallService {

    constructor(httpclient: HttpClient, localization: GolfLocalization, utilities: GolfUtilities, PropertyInfo: GolfPropertyInformation) {
        super(environment["GolfManagement"], httpclient, localization, utilities, PropertyInfo);
    }

    public async getPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.getPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async putPromise<T>(params: ServiceParams, handleErr: boolean = true) {
        const response$: Promise<BaseResponse<T>> = super.putPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async postPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.postPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async postPromiseHandleValidation<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.postPromise<BaseResponse<T>>(params);
        // on error =>
        response$.catch(err => this.error(err, handleErr));
        // on success =>
        const response: BaseResponse<T> = await response$;
        return this.HandleValidationError(response);
    }

    public async putPromiseHandleValidation<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.putPromise<BaseResponse<T>>(params);
        // on error =>
        response$.catch(err => this.error(err, handleErr));
        // on success =>
        const response: BaseResponse<T> = await response$;
        return this.HandleValidationError(response);
    }

    public async deletePromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.deletePromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response ? response.result : undefined;
    }

    private error(err: HttpErrorResponse, handleErr: boolean) {
        if (handleErr) {
            super.errorHandler(err);
        } else {
            throw err;
        }
    }
    
    private HandleValidationError<T>(response: BaseResponse<T>) {
        if (response && response.errorCode) {
            const errorResponse: BaseResponse<T> = response;
            return Promise.reject(errorResponse);
        }
        return response.result;
    }


}

