import { DepositTransactionStatus } from "../retail/shared/service/payment/payment-business.model"

//-----------------------------------------------------------------------------------------
//Request Model for Auth/Sale:

export interface SaleRequest {
    handle: string; //(Required)(Encrypted data)
    amount: Amount; //(Required)
    inquirerInfo: InquirerInformation; //(Required)
}

export interface Amount {
    requestAmount: number; // (Required)
    authorisedAmount?: number;
    tipAmount?: number;
    taxAmount?: number;
    saleAmount?: number;
}

export interface InquirerInformation {
    terminalId: string; // (Required)
    store?: string;
    division?: string;
    enterprise?: string;
    customer?: string;
    mealPeriod?: string;
    coverCount?: string;
    orderType?: string;
    orderNumber: string; // (Required)
    employeeId?: string;
    profitCenter: string; //(Required)
    tenderId: string; //(Required)
    zipcode?: string;
    isPartialTenderAllowed: boolean;
    financialBins?: Array<FinancialBin>
}

export interface FinancialBin {
    Id: string,
    type: string,
    amt: string
}

//-----------------------------------------------------------------------------------------
//Response Model for Auth/Sale:

export interface SaleResponse {
    payAgentId: string;
    status: string;
    account: DisplayInformation;
    amount: Amount;
    transactionDetails: string; //(Encrypted)
    transactionKey: string;
    errorMessage: string;
    cardInfo: PayAgentCardInfo;
}

export interface DisplayInformation {
    id: string;
    name: string;
    type: string;
}

export interface TransactionDetails {
    handle: HandleData;
    configurationId: number;
    allowVoidSale: boolean;
    status: number;
    Amount: Amount;
    transactionTime: string
}

//-----------------------------------------------------------------------------------------

export interface PaymentMethod {
    id: number
    isActive?: boolean
    isDefault?: boolean
    listOrder?: number
    paymentMethod?: string
    paymentTypeId: number
    type?: string
}
export const PaymentMethodIconConst = {
    "1": "Cash",
    "2": "Card",
    "3": "hotel-guest",
    "4": "Others"
}
export const PaymentMethodDisplayLabel = {
    1: "Cash",
    2: "Credit Card",
    3: "Room Charge",
    5: "Credit Card",
    22: "CardOnFile"
}
export interface ValidateSaleRequest {
    tenderId: number;
    payAgentResponse: PayAgentResponse;
    tryPayResponse: TryPayResponse;
    cardInfo: CardInfo;
}

export interface PayAgentResponse {
    payAgentID: string,
    status: string,
    transactionDetails: string,
    transactionKey: string,
    errorMessage?: string
}

export const HttpResponseStatus = {
    Success: "success",
    Failure: "failure"
}

export interface TryPayRequest {
    transactionId: number;
    tenderId: number;
    amount: number;
}

export interface TryPayResponse {
    ticketNumber: string;
    checkHandleGuid: string;
    tenderId: number;
    amount: number;
    transactionId: number;
    transactionPaymentId: number;
    paymentReferenceId: number;
    checkJSON: string;
}


export interface GuestStayDetail {
    guestId: string;
    roomNo: string;
    confirmationNumber: string;
    status: ActivityStatus;
}


export enum ActivityStatus {
    Unknown = -1,
    Reserved,
    CheckedIn,
    CheckedOut,
    NoShow,
    Cancelled
}

export interface PaymentHistory {
    paymentMethodId: number;
    paymentMethod: string;
    issuerType?: string;
    cardNumber?: string;
    entryMode?: string;
    amount: number;
}

export interface DomainGatewayConfiguration {
    id: number;
    payAgentURL: string;
    payGatewayID: string;
    isInterfaceActive: boolean;
    isPartialPayAllowed: boolean;
    isAVSActive: boolean;
    gatewayValues: DomainGatewayValue[];
}

export interface DomainGatewayValue {
    id: number;
    configurationId: number;
    configKey: string;
    configValue: string;
    gatewayConfiguration: DomainGatewayConfiguration;
}

export interface SaleByTokenRequest {
    inquirerInfo: InquirerInformation;
    transactionId: number;
    amount: number;
}

export interface SaleByTokenResponse {
    transactionId?: number;
    status?: boolean;
    errorMessage?: string;
    errorCode?: string;
    paymentManagerResponse: PMPayResponse;
}

export interface ValidatePayRequest {
    tenderId: number;
    payAgentResponse: PayAgentResponse;
    cardInfo: CardInfo;
    amount: number;
}

export interface ValidatePayResponse {
    transactionId?: number;
    status?: boolean;
    errorMessage?: string;
    paymentManagerResponse: PMPayResponse;

}

export interface PMPayResponse {
    status?: string;
    transactionId?: string;
    transactionTime: Date | string;
    amount: Amount;
    inquirerInfo: InquirerInformation;
    clientInfo?: ClientInfo;
    errorMessage?: string;
}

export interface ClientInfo {
    accountName: string;
    accountNumber: string;
    authCode: string;
    roomNumber: string;
}

export interface PayRequest {
    payResponse: PMPayResponse;
    tryPayResponse: TryPayResponse;
}

export interface UserSessionConfiguration {
    defaultCourseId: number;
    defaultDeviceName: string;
    defaultOutletId: number;
    defaultPaymentDevice: string;
    hangingTicketsPrinter: string;
    id: number;
    isIdtechSred: boolean;
    propertyId: number;
    smallStickersPrinter: string;
    subPropertyId: number;
    userId: number;
}


//Request Model for GetPaymentHandles:
export interface HandleRequest {
    tenderId: string; // (Required)
    inquiryInfo?: Inquiry; // (Required)
}

export interface Inquiry {
    id?: string;
    type?: string; //(Required)
    name?: string;
    cardData?: CardData;
    TenderId?: string;
    manualCardEntry?: boolean;
    isPartialTenderAllowed?: boolean;
}

export interface CardData {
    track1?: string;
    track2?: string
    encryptedData: string;
}

//-----------------------------------------------------------------------------------------
//Response Model for GetPaymentHandles:

export interface HandleResponse {
    status: string;
    errorMessage: string;
    paymentHandle: PayHandle[];
}

export interface PayHandle {
    handle: string;
    inquiryInfo: Inquiry; //(as passed in request)
    name: string;
    type: string;
    balance: number;
    isBalanceVisible: boolean
    isPartialTenderAllowed: boolean;
    isRefundable: boolean;
    additionalAttributes: object;
    allowedAPIs: string[];
}

export interface HandleData {
    configurationId: number;
    adapter: string;
    id: string;
    type: string;
    name: string;
}

export interface ClientCreditCardInfo {
    id: number;
    clientId: number;
    tokenTransId: number;
    isActive: boolean;
    createdTime: Date | string | null;
}
export interface CreateTokenRequest {
    handle: string;
    inquirerInfo: Inquiry;
}

export interface TokentransactionInfo {
    payAgentId: string;
    status: string;
    account: Account;
    cardInfo: PayAgentCardInfo;
    transactionDetails: string;
    transactionKey: string;
}

export interface PaymentTransactionInfo {
    payAgentId: string;
    status: string;
    account: Account;
    transactionDetails: string;
    transactionKey: string;
}

export interface StoreTokenRequest {
    payAgentResponse: PaymentTransactionInfo;
    cardInfo: CardInfo;
    tenderId: number;
}

export interface TokenRequest {
    PayAgentResponse: TokentransactionInfo;
}

export interface Account {
    id: string;
    name: string;
    type: string;
}

export interface PayAgentCardInfo {
    entryMode: string;
    issuer: string;
    cardExpiration: string;
    cardType: string;
    token: string;
}

export interface CardInfo {
    cardNumber: string,
    cardHolderName: string,
    entryMode: string,
    issuerType: string,
    cardExpiration: string,
    cardType: string
}

export interface PaymentBaseResponse {
    transactionId: number,
    status: boolean,
    errorMessage: string
}

export enum PaymentMethods {
    "Cash" = 1,
    "CreditCard" = 2,
    "RoomCharge" = 3,
    "PendingSettlement" = 4,
    "IDTECH" = 5,
    "CardOnFile" = 22
}

export const IDTechHandle = {
    handle: "IDTech SRED Key",
    name: "IDTech SRED Key"
}

export const IDTech = {
    id: "idtech"
}

export enum PaymentErrorCodes {
    ClientInvalidRequest = 9000,
    ClientInvalidConfiguration = 9001,
    ClientInvalidDeviceGuid = 9002,
    ClientInvalidTransactionId = 9003,
    ClientInvalidToken = 9004,
    ClientInvalidTransactionState = 9005,
    ClientInvalidTransactionFollowOnData = 9006,
    ClientAbortedRequest = 9099,
    CardError = 9500,
    CardErrorUnsupportedCardType = 9501,
    CardErrorInvalidAccountNumber = 9502,
    CardErrorInvalidExpirationDate = 9503,
    CardErrorUnsupportedCardIssuer = 9504,
    CardDeclined = 9600,
    CardDeclinedExpired = 9601,
    CardDeclinedLimit = 9602,
    CardDeclinedReferral = 9603,
    CardDeclinedCvv = 9604,
    CardDeclinedAvs = 9605,
    CardDeclinedChipDecline = 9606,
    DeviceError = 9200,
    DeviceInvalidConfiguration = 9201,
    DeviceNotAvailable = 9202,
    DeviceNotReady = 9203,
    DeviceOperationUnsupported = 9204,
    DeviceOperationAborted = 9205,
    DeviceReadWithoutPrompt = 9206,
    DeviceNoDataAvailable = 9207,
    DeviceInvalidData = 9208,
    DeviceUserPressedCancel = 9209,
    GatewayError = 9300,
    GatewayAuthenticationFailed = 9301,
    GatewayUnavailable = 9302,
    GatewayProcessorUnavailable = 9303,
    GatewayUnsupportedRequest = 9304,
    ServerError = 9100,
    ServerUnsupportedRequest = 9101,
    ServerInvalidConfiguration = 9102,
    TransactionError = 9400,
    TransactionNotFound = 9401,
    TransactionInvalidState = 9402,
    TransactionDuplicate = 9403,
    //Added for IG PMAgent Timeout
    TransactionTimeOut = 408,
    PaymentManagerTimedOut = 10060
}

export interface Device {
    name: string,
    handle: string
}


//** Credit Card configuration Screen Models */

export interface GatewayConfiguration {
    id?: number,
    payAgentURL: string,
    payGatewayID: string,
    isInterfaceActive: boolean
    isPartialPayAllowed: boolean
    isAVSActive: boolean,
    gatewayValues: GatewayValues[]
}

export interface GatewayValues {
    configurationId: number,
    configKey: string,
    configValue: string
}

export interface Deposit
{
    id?: number;
    playerId: number;
    amount: number;
    gratuity?: number;
    serviceCharge?: number;
    refundAmount?: number;
    depositTransactionId: number;
    refundTransactionId?: number;
    isVoided?: boolean;
    status?: DepositTransactionStatus;
    depositTicketNumber?: string;
    depositDate?: string;
}

enum RefundMethod {
    ReturnWithoutExchange = 1,
    ReturnWithExchange
}
