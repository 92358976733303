import { Injectable } from '@angular/core';
import { CreatePlayerService } from './create-player.service';
import { PlayerProfileDataService } from 'src/app/shared/data-services/golfmanagement/PlayerProfile.data.services';

import { PlayerProfileUI, PlayerProfileAPI, SubscribeProfile, SubscriptionType } from './create-player.model';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { SharedService } from 'src/app/shared/shared.service';
import { PlayerInformationService } from 'src/app/common/shared/shared/service/player.information.service';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { ButtonAction, DEFAULT_GUID, PatronInfoSearchResultType } from 'src/app/shared/global.constant';
import { ContactService } from 'src/app/tee-time/tournaments/tournaments-modal/contact/contact.service';

import { PlayersBusiness } from 'src/app/guest/players/players.business';
import { ButtonType } from 'src/app/common/shared/shared/globalsContant';
import { Subscription } from 'rxjs';
import { PlayerTypeService } from 'src/app/shared/data-services/golfschedule/playertype.data.service';
import { RateTypeDataService } from 'src/app/shared/data-services/golfschedule/ratetype.data.service';
import * as RateTypeAPI from 'src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal';
import * as PlayerTypeAPI from 'src/app/settings/golf-setup/code-setup/player-type/player-type.modal';
import _, { Dictionary } from 'lodash';
import { GuestDataPolicyDataService } from 'src/app/common/dataservices/guest-datapolicy.data.service';
import { ApplyPolicy } from 'src/app/common/consent-management/consent-management.model';
import { EXTERNALGUESTINTERFACENAME } from 'src/app/common/constants';
import * as GlobalConstant from '../shared-models';
import { CodeLinkingDataService } from '../data-services/golfschedule/codelinking.data.service';
import { TeeTimeDataService } from '../data-services/golfschedule/TeeTime.data.service';
import { TeeSheetPlayerDetail } from '../models/teeSheetPlayerDetail.model';
import { DefaultsSettingConfig } from '../models/default-settings.models';
import { StepperService } from 'src/app/shared/components/stepper/stepper.service';
import { TeeTimesActionService } from '../data-services/golfschedule/teeTimesAction.data.service';
import { ScheduledTeeTimePlayerRetailItem } from 'src/app/tee-time-actions/edit-retail-item/edit-retail-item-model';

@Injectable()
export class CreatePlayerBusiness {

  allCaptions: any;
  captions: any;
  PlayerInfoInput: any;
  componentDetails: GlobalConstant.ComponentDetails;
  createPlayerPopupSubscription: Subscription;
  disableGuestLink = true;
  constructor(private _CreatePlayerService: CreatePlayerService,
    private _playerProfileDataService: PlayerProfileDataService,
    private _teeTimesActionService: TeeTimesActionService,
    private _localization: GolfLocalization,
    public _utilities: GolfUtilities,
    private _sharedService: SharedService,
    private _playerService: PlayerInformationService,
    private _retailFeature: RetailFeatureFlagInformationService,
    private _contactService: ContactService,
    private _playersBussiness: PlayersBusiness,
    private _playerTypeService: PlayerTypeService,
    private _rateTypeService: RateTypeDataService,
    private _codeLinkingDataService: CodeLinkingDataService,
    private _guestDataPolicyDataService: GuestDataPolicyDataService,
    private _teeTimeDataService: TeeTimeDataService,
    private _stepperService: StepperService) {
    this.captions = this.GetCaptions();
    this.allCaptions = this._localization.captions;
  }

  GetCaptions() {
    return this._CreatePlayerService.getCaptions();
  }


  async SavePlayer(details, action) {
    this._stepperService.closeplayerPopUp = true;
    var playerProfile = this.APIMapper(details)

    if(playerProfile.playerProfileContactDetail){
      playerProfile.playerProfileContactDetail.forEach(x => {
        x.isPrimary = x.isPrimary ? x.isPrimary : false
      });
    }

    if (action === ButtonAction.link || action === ButtonAction.unlink) {
      let guestId: string;
      const showAlertAndWaitForResponse = (message) => {
        return new Promise((resolve) => {
          this._utilities.showAlert(message, GlobalConstant.AlertType.Warning, GlobalConstant.ButtonType.ContinueCancel, (res) => resolve(res))
        });
      };
      if (!details.id || details.id == DEFAULT_GUID) {
        //popUp for new profile creation
        this._utilities.ToggleLoader(false);
        let res = await showAlertAndWaitForResponse(this.captions.newProfileAlert);
        if (res == GlobalConstant.AlertAction.CONTINUE)
          guestId = await this._playerProfileDataService.CreatePlayerProfile(playerProfile, false);
        else {
          this._stepperService.closeplayerPopUp = false;
          return;
        }
      }
      else if (!this._playersBussiness.isExistingGuestSelectedForLinking && action === ButtonAction.link) {
        //popUp for linking to already linked Guest
        this._utilities.ToggleLoader(false);
        let alertMessage = this._localization.replacePlaceholders(this.captions.linkToExisitngGuestAlert, ['guestName'], [details.firstName + ' ' + details.lastName]);
        let res = await showAlertAndWaitForResponse(alertMessage);
        if (res == GlobalConstant.AlertAction.CONTINUE)
          guestId = details.id;
        else {
          this._stepperService.closeplayerPopUp = false;
          return;
        }
      }
      else
        guestId = details.id;

      if (action === ButtonAction.link) {
        if (!details.id || details.id == DEFAULT_GUID) {
          let updatedPlayerProfile = await this._playerProfileDataService.GetPlayerProfileById(guestId);
          updatedPlayerProfile.playerProfileContactDetail = updatedPlayerProfile.playerProfileContactDetail == null ? [] : updatedPlayerProfile.playerProfileContactDetail;
          playerProfile = { ...updatedPlayerProfile };
        }
        playerProfile.interfaces.findIndex(i => i.name === this._localization.GetPropertyConfig(EXTERNALGUESTINTERFACENAME)) >= 0 ? playerProfile.interfaces.find(i => i.name === this._localization.GetPropertyConfig(EXTERNALGUESTINTERFACENAME)).isSubscribed = true : null;
      }
      var linkResponse = await this.UpdatePlayerProfileLink(action, guestId, playerProfile);

      if (linkResponse && action == ButtonAction.link) {
        playerProfile.isExternalGuest = true;
        await this._playerProfileDataService.UpatePlayerProfile(playerProfile, false);
      }
      else {
        this._stepperService.closeplayerPopUp = false;
      }

      this._stepperService.linkButtonText = (linkResponse && action === ButtonAction.link) ? ButtonAction.unlink : ButtonAction.link;
      this.disableGuestLink = linkResponse && action === ButtonAction.link;
      return linkResponse;
    }
    if (details.id && details.id != DEFAULT_GUID) {
      return this._playerProfileDataService.UpatePlayerProfile(playerProfile, false);
    }
    else {
      let guestId = await this._playerProfileDataService.CreatePlayerProfile(playerProfile, false);
      return guestId;
    }
  }

  async UpdatePlayerProfileLink(action: ButtonAction, guestId: string, playerProfile: PlayerProfileAPI.PlayerProfile) {
    let subscriptionAction: number = action === ButtonAction.link ? SubscriptionType.Subscribe : SubscriptionType.UnSubscribe;
    let subscribeProfile: SubscribeProfile = {
      guestId: guestId,
      interfaceGuestId: playerProfile.interfaces.find(i => i.name === this._localization.GetPropertyConfig(EXTERNALGUESTINTERFACENAME)).interfaceGuestId,
      interfaceName: playerProfile.interfaces.find(i => i.name === this._localization.GetPropertyConfig(EXTERNALGUESTINTERFACENAME)).name,
      subscriptionType: subscriptionAction
    };
    return await this._playerProfileDataService.UpdatePlayerProfileLink(subscribeProfile);
  }

  APIMapper(PlayerUIModel: PlayerProfileUI.Player): PlayerProfileAPI.PlayerProfile {
    let loyaltyInfo: PlayerProfileAPI.LoyaltyDetail[] = [];
    if (PlayerUIModel.patronid) {
      loyaltyInfo.push({
        patronId: PlayerUIModel.patronid,
        rank: PlayerUIModel.rank ? PlayerUIModel.rank : ''
      });
    }
    PlayerUIModel.dob = new Date(PlayerUIModel.dob == null ? '' : PlayerUIModel.dob);
    let result: PlayerProfileAPI.PlayerProfile = {
      firstName: PlayerUIModel.firstName,
      lastName: PlayerUIModel.lastName,
      pronounce: PlayerUIModel.pronounced ? PlayerUIModel.pronounced.trim() : '',
      playerProfileAddress: this.IsPlayerAddressValid(PlayerUIModel) ? this.AddressMapper(PlayerUIModel) : null,
      playerProfileAddressList: this.AddressListMapper(PlayerUIModel),
      playerProfileContactDetail: this.ContactMapper(PlayerUIModel),
      title: PlayerUIModel.title,
      dateOfBirth: PlayerUIModel.dob != null ? new Date(PlayerUIModel.dob.toString() + ' UTC') : null,
      gender: PlayerUIModel.gender,
      paymentReferenceId: PlayerUIModel.paymentReferenceId,
      updateExistingPaymentReferenceId: PlayerUIModel.updateExistingPaymentReferenceId,
      imageReferenceId: PlayerUIModel.imageReferenceId,
      interfaces: PlayerUIModel.interfaces,
      lastVisitedDate: PlayerUIModel.lastVisitedDate,
      loyaltyDetail: loyaltyInfo,
      playerType: (PlayerUIModel.playerType) ? PlayerUIModel.playerType.id : 0,
      rateType: (PlayerUIModel.rateType) ? PlayerUIModel.rateType.id : 0,
      vipType: PlayerUIModel.vipType,
      consent: PlayerUIModel.consent,
      consentPolicyId: PlayerUIModel.consentPolicyId,
      consentExpiryDate: PlayerUIModel.consentExpiryDate,
      comments: PlayerUIModel.comments,
      isPurged: PlayerUIModel.isPurged,
      isExternalGuest: PlayerUIModel.isExternalGuest,
      playerProfileContactLogList: PlayerUIModel.playerContactLogList,
      platformRevUuid: PlayerUIModel.platformRevUuid,
      platformBussinessCardRevUuid: PlayerUIModel.platformBussinessCardRevUuid,
      platformGuestUuid: PlayerUIModel.platformGuestUuid,
      platformBussinessCardUuid: PlayerUIModel.platformBussinessCardUuid,
      externalReferenceNumber: PlayerUIModel.externalReferenceNumber
    };
    if (PlayerUIModel.id)
      result.id = PlayerUIModel.id;
    if (PlayerUIModel.lastChangeId)
      result.lastChangeId = PlayerUIModel.lastChangeId;
    if (PlayerUIModel.interfaceGuestId)
      result.interfaceGuestId = PlayerUIModel.interfaceGuestId;
    return result;
  }
  IsPlayerAddressValid(PlayerUIModel: PlayerProfileUI.Player): boolean {
    if (PlayerUIModel.address) {
      var counter = 0;
      while (counter < PlayerUIModel.address.length) {
        if (PlayerUIModel.address[counter].addressDetails != "") {
          return true;
        }
        counter++;
      }
      if (PlayerUIModel.city == "" && PlayerUIModel.state == "" && PlayerUIModel.country == ""
        && PlayerUIModel.postal_code == "") {
        return false;

      }
      return true;
    }
  }
  ContactMapper(PlayerUIModel: PlayerProfileUI.Player): PlayerProfileAPI.PlayerProfileContactDetails[] {
    let contactList: PlayerProfileAPI.PlayerProfileContactDetails[] = [];

    if (PlayerUIModel.phone) {
      PlayerUIModel.phone.forEach(x => {
        if (x.phoneType && x.phoneNumber)
          contactList.push({
            id: x.id ? x.id : 0,
            playerId: 0,
            type: x.phoneType,
            value: this._utilities.buildPhoneNumber(Number(x.phoneType), x.extension, x.phoneNumber, x.countryCode),
            isPrimary: x.primaryPhone !== undefined ? x.primaryPhone : true,
            isPrivate: x.privateInformtionPhone ? x.privateInformtionPhone : false,
            name: 'Phone',
            platformContactUuid: x.platformContactUuid
          });
      });
    }
    if (PlayerUIModel.email) {
      PlayerUIModel.email.forEach(x => {
        if (x.emailType && x.emailId)
          contactList.push({
            id: x.id ? x.id : 0,
            playerId: 0,
            type: x.emailType,
            value: x.emailId,
            isPrimary: x.primaryEmail !== undefined ? x.primaryEmail : true,
            isPrivate: x.privateInformtionEmail ? x.privateInformtionEmail : false,
            name: 'Email',
            platformContactUuid: x.platformContactUuid
          });
      });
    }
    return contactList;
  }

  getPhoneType() {
    return this._localization.ContactTypes.Phone;
  }

  getEmailType() {
    return this._localization.ContactTypes.Email;
  }

  AddressMapper(player: PlayerProfileUI.Player): PlayerProfileAPI.PlayerProfileAddress {
    return {
      id: player.addressId,
      addressLine1: player.address[0] ? player.address[0].addressDetails : "",
      addressLine2: player.address[1] ? player.address[1].addressDetails : "",
      addressLine3: player.address[2] ? player.address[2].addressDetails : "",
      city: player.city,
      state: player.state,
      country: this._utilities.FilterCountryValueFromData(player.country),
      zipCode: player.postal_code,
      isPrivate: player.privateAddress,
      platformAddressUuid: player.platformAddressUuid ? player.platformAddressUuid : ''
    } as PlayerProfileAPI.PlayerProfileAddress
  }

  AddressListMapper(player: PlayerProfileUI.Player): PlayerProfileAPI.PlayerProfileAddress[] {
    let addressList: Array<any> = player.addressList;
    let mappedAddresList: PlayerProfileAPI.PlayerProfileAddress[] = [];
    if (addressList == null || addressList.length == 0) {
      if (this.IsPlayerAddressValid(player)) {
        let editedAddres = {
          id: player.addressId ? player.addressId : 0,
          addressLine1: player.address[0] ? player.address[0].addressDetails : "",
          addressLine2: player.address[1] ? player.address[1].addressDetails : "",
          addressLine3: player.address[2] ? player.address[2].addressDetails : "",
          city: player.city,
          state: player.state,
          country: this._utilities.FilterCountryValueFromData(player.country),
          zipCode: player.postal_code,
          isPrivate: player.privateAddress,
          platformAddressUuid: player.platformAddressUuid ? player.platformAddressUuid : ''
        } as PlayerProfileAPI.PlayerProfileAddress
        mappedAddresList.push(editedAddres);
      }
    }
    else {
      addressList.forEach(x => {
        if (x.platformAddressUuid == player.platformAddressUuid) {
          if (this.IsPlayerAddressValid(player)) {
            let editedAddres = {
              id: player.addressId ? player.addressId : 0,
              addressLine1: player.address[0] ? player.address[0].addressDetails : "",
              addressLine2: player.address[1] ? player.address[1].addressDetails : "",
              addressLine3: player.address[2] ? player.address[2].addressDetails : "",
              city: player.city,
              state: player.state,
              country: this._utilities.FilterCountryValueFromData(player.country),
              zipCode: player.postal_code,
              isPrivate: player.privateAddress,
              platformAddressUuid: player.platformAddressUuid ? player.platformAddressUuid : ''
            } as PlayerProfileAPI.PlayerProfileAddress
            mappedAddresList.push(editedAddres);
          }
        }
        else {
          let apiAddres = {
            id: player.addressId ? player.addressId : 0,
            addressLine1: x.addressDetails[0] ? x.addressDetails[0].addressDetails : "",
            addressLine2: x.addressDetails[1] ? x.addressDetails[1].addressDetails : "",
            addressLine3: x.addressDetails[2] ? x.addressDetails[2].addressDetails : "",
            city: x.city,
            state: x.state,
            country: this._utilities.FilterCountryValueFromData(x.country),
            zipCode: x.postalCode,
            isPrivate: x.isPrivate,
            platformAddressUuid: x.platformAddressUuid ? x.platformAddressUuid : ''
          } as PlayerProfileAPI.PlayerProfileAddress
          mappedAddresList.push(apiAddres);
        }

      });
    }

    return mappedAddresList;
  }



  async patronLookup(patronId: string, callBack: (result, data?) => void) {
    let existingPlayer = await this._playerProfileDataService.getExistingPlayerUsingPatronId(patronId);
    if (existingPlayer) {
      this._utilities.ShowError(this.allCaptions.common.Information, this.captions.EnteredPatronIDIsAlreadyAvailable, ButtonType.YesNo, this.editExistingPatronCallBack.bind(this), [existingPlayer, callBack]);
    }
    else {
      this._sharedService.loaderEnable.next(true);
      let cmsPlayer = await this._playerService.GetPlayerInformation(patronId);
      this._sharedService.loaderEnable.next(false);
      if (cmsPlayer && cmsPlayer.personalDetails) {
        if (cmsPlayer.personalDetails.dateOfBirth) {
          cmsPlayer.personalDetails.dateOfBirth = this._utilities.convertDateFormat(this._utilities.getDate(cmsPlayer.personalDetails.dateOfBirth));
        }
        if (this._retailFeature.UpdateGuestInfoAsPerCMS || !this._CreatePlayerService.isEdit) {
          callBack(PatronInfoSearchResultType.UPDATECMSDATAONEXISTING, [cmsPlayer.personalDetails])
        }
        else {
          callBack(PatronInfoSearchResultType.PATRONFOUND, [cmsPlayer.personalDetails]);
        }
      } else {
        this._utilities.ShowError(this.allCaptions.common.Error, this.captions.PatronNotFound)
        callBack(PatronInfoSearchResultType.PATRONNOTFOUND);
      }
    }
  }

  editExistingPatronCallBack(result: string, extraParams) {
    let callBack = extraParams[1];
    let existingPlayer = extraParams[0];
    if (result.toLowerCase() == 'yes') {
      let uiData = this._playersBussiness.mapPlayerProfileAPIToUI(existingPlayer);
      this.PlayerInfoInput = uiData;
      this._CreatePlayerService.isEdit = true;
      callBack(PatronInfoSearchResultType.EDITEXISTINGPATRON, uiData)
    }
    else {
      callBack(PatronInfoSearchResultType.PATRONNOTFOUND)
    }

  }

  async UpdateCMSDetailOnExistingGuest(existingPlayer) {
    let isGuestDataChanged: boolean = false;
    this._sharedService.loaderEnable.next(true);
    let defaultSettings: DefaultsSettingConfig = JSON.parse(sessionStorage.getItem('defaultSettings'));
    let cmsPlayer = await this._playerService.GetPlayerInformation(existingPlayer.patronid ? existingPlayer.patronid : existingPlayer.loyaltyDetail[0].patronId);
    this._sharedService.loaderEnable.next(false);
    if (cmsPlayer && cmsPlayer.personalDetails) {
      isGuestDataChanged = this.isCMSDataChanged(existingPlayer, cmsPlayer.personalDetails)
      if (this._retailFeature.UpdateGuestInfoAsPerCMS) {
        let playerDetail = cmsPlayer.personalDetails;
        existingPlayer.firstName = playerDetail.firstName;
        existingPlayer.lastName = playerDetail.lastName;
        existingPlayer.pronounced = playerDetail.pronounced;
        existingPlayer.rank = playerDetail.playerRank;
        existingPlayer.dob = this._utilities.convertDateFormat(this._utilities.getDate(playerDetail.dateOfBirth));
        if (playerDetail.gender && playerDetail.gender != 'U') {
          existingPlayer.gender = playerDetail.gender == 'M' ? 'Male' : 'Female';
        }
        if (playerDetail.address) {
          existingPlayer.address = [];
          existingPlayer.address.push({
            addressDetails: playerDetail.address.addressLine1
          })
        }

        existingPlayer.city = playerDetail.address.city;
        existingPlayer.country = playerDetail.address.countryName;
        existingPlayer.state = playerDetail.address.state;
        existingPlayer.postal_code = playerDetail.address.postalCode;

        if (playerDetail.phone && playerDetail.phone.length > 0) {
          existingPlayer.phone = [];
          var phoneTypes = this._contactService.getPhoneType();
          playerDetail.phone.forEach(element => {
            let number = (element.countryCode ? element.countryCode : '') + '|' + element.extension + element.phoneNumber
            existingPlayer.phone.push({
              contactType: element.phoneTypeId ? phoneTypes.filter(x => x.id == element.phoneTypeId)[0].description : '',
              countryCode: element.countryCode ? element.countryCode : (defaultSettings && defaultSettings.defaultCountryCode != '' ? defaultSettings.defaultCountryCode : ''),
              extension: element.extension,
              phoneLabel: String(element.phoneTypeId),
              phoneNumber: this._utilities.getPhoneNumberFromValue(element.phoneTypeId, number).toString(),
              phoneType: element.phoneTypeId,
              primaryPhone: element.isPrimary,
              privateInformtionPhone: false
            })
          });
        }

        if (playerDetail.email && playerDetail.email.length > 0) {
          existingPlayer.email = [];
          var mailTypes = this._contactService.getEmailType();
          playerDetail.email.forEach(element => {
            existingPlayer.email.push({
              contactType: element.emailTypeId ? mailTypes.filter(x => x.id == element.emailTypeId)[0].description : '',
              emailId: element.emailAddress,
              emailLabel: String(element.emailTypeId),
              emailType: element.emailTypeId,
              primaryEmail: false,
              privateInformtionEmail: false,
            })
          });
        }
      }
      else {
        existingPlayer.rank = cmsPlayer.personalDetails.playerRank;
      }
    }
    else {
      existingPlayer.patronid = '';
      existingPlayer.rank = '';
      isGuestDataChanged = true;
      this._utilities.ShowError(this.allCaptions.common.Error, this.captions.PatronNotFound);
    }
    return isGuestDataChanged;
  }

  isCMSDataChanged(existingData, cmsData): boolean {
    if (this._retailFeature.UpdateGuestInfoAsPerCMS) {
      if (existingData.firstName !== cmsData.firstName ||
        existingData.lastName !== cmsData.lastName ||
        existingData.pronounced !== cmsData.pronounced ||
        existingData.rank !== cmsData.playerRank ||
        this._utilities.GetFormattedDate(existingData.dob) != this._utilities.GetFormattedDate(cmsData.dateOfBirth)) {
        return true;
      }

      if (cmsData.gender !== 'U' && cmsData.gender !== String(existingData.gender).charAt(0)) {
        return true;
      }

      if (cmsData.address && (cmsData.address.addressLine1 != existingData.address[0]?.addressDetails ||
        cmsData.address.city != existingData.city ||
        cmsData.address.state != existingData.state ||
        cmsData.address.postalCode != existingData.postal_code ||
        cmsData.address.country != existingData.country)) {
        return true;
      }

      let guestPhone = _.orderBy(_.cloneDeep(existingData.phone), 'phoneNumber', 'asc');
      let formatedCMSPhone = cmsData.phone.map(x => {
        return {
          phoneNumber: x.phoneNumber,
          phoneTypeId: x.phoneTypeId,
          isPrimary: x.isPrimary,
          formattedPhone: this._utilities.getPhoneNumberFromValue(x.phoneTypeId, (x.countryCode ? x.countryCode : '') + '|' + x.extension + x.phoneNumber),
        }
      })
      formatedCMSPhone = _.orderBy(formatedCMSPhone, 'formattedPhone', 'asc')
      if (guestPhone && guestPhone.length == formatedCMSPhone.length) {
        for (let index = 0; index < guestPhone.length; index++) {
          if (guestPhone[index].phoneNumber != formatedCMSPhone[index].formattedPhone ||
            guestPhone[index].phoneType != formatedCMSPhone[index].phoneTypeId ||
            guestPhone[index].primaryPhone != formatedCMSPhone[index].isPrimary) {
            return true;
          }
        }
      } else {
        return true;
      }

      let guestEmail = _.orderBy(_.cloneDeep(existingData.email), 'emailId', 'asc');
      let cmsEmail = _.orderBy(cmsData.email, 'emailAddress', 'asc');
      if (cmsEmail && cmsEmail.length == guestEmail.length) {
        for (let index = 0; index < cmsEmail.length; index++) {
          if (cmsEmail[index].emailAddress != guestEmail[index].emailId ||
            cmsEmail[index].emailTypeId != guestEmail[index].emailType) {
            return true;
          }
        }
      } else {
        return true;
      }
    }
    else {
      return existingData.rank == cmsData.playerRank ? false : true;
    }
    return false;
  }



  public async getAllPlayerTypeWithRateType() {
    return await this._codeLinkingDataService.getPlayerTypeRateTypes();
  }
  public async getPlayerTypes(): Promise<PlayerProfileUI.PlayerType[]> {
    let playerTypeApiData: PlayerTypeAPI.PlayerType[] = await this._playerTypeService.getAllPlayerTypes();
    return playerTypeApiData.map(x => this.mapPlayerTypeData(x));

  }
  private mapPlayerTypeData(playerTypeApiData: PlayerTypeAPI.PlayerType): PlayerProfileUI.PlayerType {
    return {
      id: playerTypeApiData.id,
      type: playerTypeApiData.type,
      daysOutStart: playerTypeApiData.daysOutStart,
      daysOutEnd: playerTypeApiData.daysOutEnd,
      guaranteeType: playerTypeApiData.guaranteeType
    } as PlayerProfileUI.PlayerType;
  }

  public async getRateTypes(): Promise<PlayerProfileUI.RateType[]> {
    let rateTypeApiData: RateTypeAPI.RateType[] = await this._rateTypeService.getAllRateTypes(false);
    return rateTypeApiData.map(x => this.mapRateTypeData(x));
  }
  private mapRateTypeData(rateTypeApiData: RateTypeAPI.RateType): PlayerProfileUI.RateType {
    return {
      id: rateTypeApiData.id,
      type: rateTypeApiData.type,
      guaranteeType: rateTypeApiData.guaranteeType,
      daysOutStart: rateTypeApiData.daysOutStart,
      daysOutEnd: rateTypeApiData.daysOutEnd
    } as PlayerProfileUI.RateType;
  }
  async getIsGdprConfiguredFlag(): Promise<boolean> {
    var tenantId = Number(this._utilities.GetPropertyInfo("TenantId"));
    var isGdprConfigured = await this._guestDataPolicyDataService.GetDataRetentionPolicyConfiguredFlag(tenantId);
    return isGdprConfigured;
  }
  async getPolicyTypeUsingPolicyId(policyId: number): Promise<number> {
    var policyType = await this._guestDataPolicyDataService.GetPolicyTypeUsingPolicyId(policyId);
    return policyType;
  }
  async updatePolicyDetailsForGuestId(applyPolicy: ApplyPolicy): Promise<boolean> {
    const result = await this._playerProfileDataService.UpdatePolicyDetailsForGuestId(applyPolicy);
    return result;
  }
  async getSlotScheduleTeeTimePlayers(activityId: string): Promise<TeeSheetPlayerDetail[]> {
    const result = await this._teeTimeDataService.getSlotScheduleTeeTimePlayers(undefined, "0", undefined, undefined, activityId);
    return result;
  }
async getScheduledPlayerRetailItemsByActivityId(activityIds): Promise<ScheduledTeeTimePlayerRetailItem[]>{
  const result = await this._teeTimesActionService.getScheduledPlayerRetailItemsByActivityId(activityIds);
  return result;
}
}
