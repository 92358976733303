<section class="checkbox-table" [ngClass]="{'button_invalid': isSetDisable}" [formGroup]="tableForm" *ngIf="_tableData && _tableData.length" (scroll)="updateFromScroll($event)">
  <!-- <virtual-scroller #scroll [items]="tableForm.get('checkBoxItems').controls" class="height-100"> -->
  <table attr.automationId='Tbl_{{automationId}}_CheckBoxTable' class="table-type--static"
    aria-describedby="checkbox-table">
    <thead #header>
      <tr class="table-type--static-header">
        <th scope="col" class="table-type--static-header-th">
          <mat-checkbox attr.automationId='Chk_{{automationId}}_allCheckBox' formControlName='allCheckBox'
            (change)="checkAll($event)" [checked]="isAllChecked">
          </mat-checkbox>
        </th>
        <th scope="col" class="table-type--static-header-th" *ngFor="let header of headers"
          [ngClass]="header.alignment">
          {{header.description}}</th>
      </tr>
    </thead>
    <tbody #container class="checkbox-table__body" formArrayName="checkBoxItems">
      <tr class="table-type--static-row" [ngClass]="{'clear-player-disable':row.value.hasAccessDisabled}"
        *ngFor="let row of tableForm.get('checkBoxItems').controls | slice: 0:endValue; let idx = index; trackBy: trackByFn"
        [formGroupName]="row.value.i" [ngClass]="{'highlight-row': row && row.value['checkBox'] == true && row.value['playerId'] != undefined && row.value['playerId'] === this.updatedRow}">
        <td class="table-type--static-row-td">
          <mat-checkbox attr.automationId='Chk_{{automationId}}_checkBox{{idx}}' formControlName='checkBox'
            [disabled]="row.value['isHold']" (change)="check($event, row)"></mat-checkbox>
        </td>
        <td class="table-type--static-row-td" *ngFor="let header of headers" [ngClass]="header.alignment">
          <ng-container
            *ngTemplateOutlet="header.template? this[header.template] : defaultTemplate; context: {row:row.value,header:header.key, idx: idx}">
          </ng-container>
          <div [ngSwitch]="header.templateName" [class]="header.templateName">
            <ng-container *ngSwitchCase="'custom'">
              <ng-container
                *ngTemplateOutlet="childTemplate; context: { row:row.value, key: header.key, idx: idx, data: _tableData, header : header}">
              </ng-container>
            </ng-container>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- </virtual-scroller> -->
</section>

<ng-template #inputText let-header="header" let-group="idx">
  <ng-container [formGroup]="tableForm">
    <ng-container formArrayName="checkBoxItems">
      <ng-container [formGroupName]="group">
        <input attr.automationId='Chk_{{automationId}}_header{{idx}}' class="td-input" [formControlName]="header"
          [golfinputtype]="directiveType" *ngIf=!isCurrencyInput>
        <input attr.automationId='Chk_{{automationId}}_header{{idx}}' class="td-input" [formControlName]="header"
          CurrencyFormatter *ngIf=isCurrencyInput>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
<ng-template #fieldWithPopover let-header="header" let-group="idx" let-row="row">


  <!-- <ng-container [formGroup]="tableForm">
    <ng-container formArrayName="checkBoxItems">
      <ng-container [formGroupName]="group">
        <input class="td-input" [formControlName]="header" [golfinputtype]="directiveType" *ngIf=!isCurrencyInput>
        <input class="td-input" [formControlName]="header"  CurrencyFormatter *ngIf=isCurrencyInput>
      </ng-container>
    </ng-container>
  </ng-container> -->
  <span>{{row[header]}}</span>
  <span class="icon-alert1 pl-2" [matMenuTriggerFor]="itemizedAmount" [matMenuTriggerData]="{x: row['totalTax'],y:row['customFee'],
  customFeeDetails:row['customFeeDetails']}"></span>
</ng-template>
<ng-template #fieldWithPopover let-header="header" let-group="idx" let-row="row">
  <span>{{row[header]}}</span>
  <span class="icon-alert1 pl-2" [matMenuTriggerFor]="itemizedAmount"
    [matMenuTriggerData]="{x: row['totalTax']}"></span>
</ng-template>
<ng-template #fieldWithPopover let-header="header" let-group="idx" let-row="row">
  <span>{{row[header]}}</span>
  <span class="icon-alert1 pl-2" [matMenuTriggerFor]="itemizedAmount"
    [matMenuTriggerData]="{x: row['totalTax']}"></span>
</ng-template>
<ng-template #defaultTemplate let-row="row" let-header="header">
  <span [matTooltipClass]="'AgysMatCustTooltip'" [matTooltip]="row[header]">{{ row[header] }}</span>
</ng-template>
<mat-menu #itemizedAmount="matMenu">
  <ng-template let-x="x" let-customFeeDetails="customFeeDetails" let-y ="y" matMenuContent>
      <div class="p-2">
        <span class="pr-1">{{captions.tbl_hdr_tax}}:</span>
        <span>{{x}}</span>
        <div *ngIf="y != '' ">
          <span class="pr-1">{{captions.tbl_hdr_customFee}}:</span>
          <span>{{y}}</span>
        </div>
      </div>
  </ng-template>
</mat-menu>

<!-- <ng-template #dropdown let-header="header" let-group="idx" let-row="row" >
  <ng-container>
    <select class="player-dropdown" [(ngModel)]='row[header].value' [disabled]="row['isDisableDropdown']" (change)='emitDropdownData($event, row, header)'>
      <option></option>
      <option
        *ngFor='let option of row[header].options; trackBy:trackByFn'
        [value]='option.id'>
        {{option.viewValue}}</option>
    </select>
  </ng-container>
</ng-template>  -->

<ng-template #negotiate let-row="row" let-header="header">
  <div class="negotiate label-with-icon">
    <span class="type-item" [matTooltipClass]="'AgysMatCustTooltip'" [matTooltip]="row[header]">{{ row[header] }}</span>
    <span *ngIf="row['isNegotiated']" class="icon-negotiated_rates"></span>
  </div>
</ng-template>

<ng-template #playerdropdown let-header="header" let-group="idx" let-row="row">
        <div class="label-with-icon" (click)="OnPlayerTypeRateTypeDropdownClick(row)" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{'rowData': row, 'idx': idx}" [ngClass]="{'ag_section--disable': (row['checked'] && row['isDisableDropdown']) || (!row['checked'] && row['isDisableDropdown']) || (!row['checked'] && !row['isDisableDropdown']) }"><span class="icon-edit"></span><span class="type-item" [matTooltip]="row['playerType']">{{row['playerType']}}</span></div>
</ng-template>
<ng-template #ratedropdown let-header="header" let-group="idx" let-row="row">
        <div class="label-with-icon" (click)="OnPlayerTypeRateTypeDropdownClick(row)"  [matMenuTriggerFor]="menu" [matMenuTriggerData]="{'rowData': row, 'idx': idx}" [ngClass]="{'ag_section--disable': (row['checked'] && row['isDisableDropdown']) || (!row['checked'] && row['isDisableDropdown']) || (!row['checked'] && !row['isDisableDropdown'])}"><span class="icon-edit"></span><span class="type-item" [matTooltip]="row['rateType']">{{row['rateType']}}</span></div>
</ng-template>

  <mat-menu #menu="matMenu" class="update-Player" [hasBackdrop]="true" [backdropClass]="'disable-backdrop'">
    <ng-template let-rowData="rowData" let-idx="idx" matMenuContent>
      <app-update-player-details [from]="'fromPayment'" [rowIdx]="idx" [rowData]="rowData" (isPayFormDirty)="payForm($event)" (onActionEmit)="onAction($event)"></app-update-player-details>
    </ng-template>
  </mat-menu>