import { Injectable } from '@angular/core';
import { UserAccessDataService } from './useraccess.data.service';
import { GolfUtilities } from '../../utilities/golf-utilities';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { ButtonType, localizationJSON, AlertType } from '../../shared-models';
import { UserAccessModel } from 'src/app/common/dataservices/authentication/useraccess-model.model';

@Injectable(
    { providedIn: 'root' }
)
export class UserAccessBusiness {

    constructor(private _userAccessDataService: UserAccessDataService
        , public _utilities: GolfUtilities
        , public _localization: GolfLocalization) { }

    public userAccessBreakpoints: localizationJSON;

    public async getUserAccess(breakPointNumber: number, showUserMessage: boolean = true, callBack?: any): Promise<UserAccessModel.BreakPointResult> {
        let result = await this._userAccessDataService.getUserAccess(breakPointNumber);
        let bpMessage = this._localization.captions.breakpoint[breakPointNumber];
        if (!result.isAllow && !result.isViewOnly && showUserMessage) {
            this.showBreakPointPopup(bpMessage, callBack);
        }
        this._utilities.ToggleLoader(false)
        return result;
    }

    public showBreakPointPopup(functionName?: string, callBack?: any) {
        let message: string;
        message = `${this._localization.captions.common.BreakPointAccessDeniedMsg}
             <br><br>${this._localization.captions.common.Breakpoint}: ${functionName}`;

        this._utilities.showAlert(message, AlertType.AccessDenied, ButtonType.Ok, callBack);
    }

    public showBreakPointError(breakpoint: number): void {
        let message: string = this._localization.captions.breakpoint[breakpoint.toString()];
        this.showBreakPointPopup(message);
    }

    public showMutipleBreakPointError(breakpoints: number[]): void {
        let messages: string[] = [];
        breakpoints.forEach(breakpoint=>
            {
                messages.push(this._localization.captions.breakpoint[breakpoint.toString()])
            }
            );
        this.showMultipleBreakPointPopup(messages);
    }

    public showMultipleBreakPointPopup(functionName?: string[], callBack?: any) {
        let message: string;
        message = `${this._localization.captions.common.BreakPointAccessDeniedMsg}<br>`;
        functionName.forEach(x=>
            {
                message += `<br>${this._localization.captions.common.Breakpoint}: ${x}`;
            }
        )

        this._utilities.showAlert(message, AlertType.AccessDenied, ButtonType.Ok, callBack);
    }

    public getUserAccesses(breakpoints: number[]): Promise<UserAccessModel.BreakPointResult[]> {
        return this._userAccessDataService.getUserAccesses(breakpoints);
    }

}