<section class="CrudEmailSec crud-email-temp-wrapper newVersionWrapper h-100">

    <form [formGroup]="CrudEmmailTempForm" class="h-100" autocomplete="off">
        <div class="CrudEmailNav LW20">
            <i aria-hidden="true" class="icon-left-arrow cursor" (click)="promptForUnSavedChanges()"></i>
            <label class="pl-1 pt-1 text-capital cursor" (click)="promptForUnSavedChanges()">{{HeaderLabel}}</label>
        </div>


        <div class="CRUD-email-Template-wrapper ag_container--padding-xs">
            <div *ngIf="isShowCopy">
                <div class="warning-bar ag_p--3 ag_display--flex"><i aria-hidden="true"
                        class="icon-warning-icon ag_pr--2"></i><span [matTooltip]="message">{{message}}</span><i
                        aria-hidden="true" *ngIf="showClose" class="icon-close ag_ml--auto" (click)="closeBar()"></i>
                </div>
            </div>
            <div class="temp-details-section ag_container--padding-xs">
                <div class="ag_display--flex-wrap">
                    <h4 class="LWB16">{{this.crudemailtemplateCaptions.tempDet}}</h4>
                    <div class="ag_ml--auto ag_display--flex">
                        <div class="ag_mr--2 ag_display--flex">
                            <div><label class="LW12 pr-2 label-color toggle-label">{{crudemailtemplateCaptions.active}}</label></div>
                            <app-common-toggle-switch [ngClass]="isViewOnly? 'ag_section--disable' : ''" [attr.automationId]="'Txt_crudTemplateEmail_isActive'" formControlName="isActive" class="toggle-switch">
                            </app-common-toggle-switch>
                        </div>
                        <div class="ag_display--flex">
                            <div><label class="LW12 pr-2 label-color toggle-label">{{crudemailtemplateCaptions.setDefault}}</label></div>
                            <app-common-toggle-switch [ngClass]="isViewOnly? 'ag_section--disable' : ''" [attr.automationId]="'Txt_crudTemplateEmail_isDefault'" formControlName="isDefault" class="toggle-switch">
                            </app-common-toggle-switch>
                        </div>
                    </div>
                   
                </div>
                <div class="ag_display--flex-wrap">
                    <mat-form-field [floatLabel]="floatLabel" class="crud-w30">
                        <mat-select [attr.automationId]="'Dd_crudTemplateEmail_eventName'" multiple name="action" formControlName="eventId" required class="LW14"
                            [placeholder]="crudemailtemplateCaptions?.event" (selectionChange)="actionChanged($event)"
                            [disabled]="editableObj || fromBulkNotify">
                            <mat-option *ngFor="let type of events" [value]="type.id" [disabled]="type.disabled">
                                {{type.eventName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field [floatLabel]="floatLabel" class="crud-w30">
                        <input [attr.automationId]="'Txt_crudTemplateEmail_templateCode'" matInput class="LW14" [maxlength]="templateCodeMaxlength" inputtype="reservedchar" required
                            [placeholder]="crudemailtemplateCaptions?.emailTempCode"
                            name="templateCode" formControlName="templateCode">
                        <mat-error>
                            {{crudemailtemplateCaptions?.missingtempCode}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field [floatLabel]="floatLabel" class="crud-w30">
                        <input [attr.automationId]="'Txt_crudTemplateEmail_templateName'" matInput class="LW14" [maxlength]="50" inputtype="nospecailchar" required
                            [placeholder]="crudemailtemplateCaptions?.emailTempName"
                            name="templateName" formControlName="templateName">
                        <mat-error>
                            {{crudemailtemplateCaptions?.missingtempName}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field [floatLabel]="floatLabel" class="crud-w30">
                        <input [attr.automationId]="'Txt_crudTemplateEmail_listOrder'" matInput class="LW14" [placeholder]="crudemailtemplateCaptions?.listOrder" name="listOrder"
                            formControlName="listOrder" [maxlength]="4" inputtype="nonnegative,nodecimal">
                        <mat-error>
                            {{crudemailtemplateCaptions?.missinglistOrder}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
          
                <ng-container *ngIf="!enableMultiLanguage">
                    <div class="ag_display--flex-wrap ag_mt--4">
                        <h4 class="LWB16 mb-1 d-inline-block">{{crudemailtemplateCaptions.emailMsg}}</h4>
                        <a class="ag_link ag-ml-4 pl-3" (click)="showHtmlContent()">{{this.crudemailtemplateCaptions.preview}}</a>
                    </div>
                  
                    <div  class="editorsec" [ngClass]="isViewOnly? 'ag_section--disable' : ''"  >
                        <div class="leftSection h-100 d-inline-block">
                            <ckeditor class="crudTempEditor"  #ComposeEmailTemp [editor]="Editor" [config]="Config"
                                (ready)="onReady($event)" formControlName="htmlContent" (focus)="onChange(ComposeEmailTemp)">
                            </ckeditor>
                        </div>
                        <div class="rightSection h-100 d-inline-block">
                          <ng-container *ngIf="!isGroupingenabled">
                            <h4 class="LWB14 mb-1">{{this.crudemailtemplateCaptions.tags}}</h4>
                            <!-- ( {{filteredkeywords.length}}/{{Entire_keywords.length}} ) -->
                            <mat-form-field  class="spa-searchbox w-100" [floatLabel]="floatLabelNever" id="searchInput">
                                <input matInput autocomplete="off" [(ngModel)]="searchText" [attr.automationId]="'Txt_crudTemplateEmail_search'"
                                    [ngModelOptions]="{standalone: true}" type="text"
                                    [placeholder]="crudemailtemplateCaptions.srchByTag" (input)="enteredSrchText()">
                                <i aria-hidden="true" [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'" class="cursor"
                                    (click)="searchText='';enteredSrchText()"></i>
                            </mat-form-field>
                            <hr class="mt-0" />
                            <div class="keywordSec">
                                <button mat-raised-button *ngFor="let item of filteredkeywords" [matTooltip]="item.name" [attr.automationId]="'Btn_crudTemplateEmail_itemName'"
                                    class="crudDetails-btn body-bgcolor whitecolor textellipsis"
                                    (click)="elementClick(item.name)">
                                    {{item.description}}
                                </button>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="isGroupingenabled">
                            <h4 class="LWB14 mb-1">{{this.crudemailtemplateCaptions.tags}}</h4>
                            <!-- ( {{filteredkeywords.length}}/{{Entire_keywords.length}} ) -->
                            <mat-form-field class="spa-searchbox w-100" [floatLabel]="floatLabelNever"  id="searchInput">
                                <input [attr.automationId]="'Txt_crudTemplateEmail_searchText'" matInput autocomplete="off" [(ngModel)]="searchText"
                                    [ngModelOptions]="{standalone: true}" type="text"
                                    [placeholder]="crudemailtemplateCaptions.srchByTag" (input)="enteredgrpSrchText()">
                                <i aria-hidden="true" [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'" class="cursor"
                                    (click)="searchText='';enteredgrpSrchText()"></i>
                            </mat-form-field>
                            <hr class="mt-0" />
                            <div class="keywordSec grouping-overflow">
                                <ng-container *ngFor="let item of filteredkeywords|sortPipe : ordertype : groupBy;;let i = index">
                                    <label  class="w-100 d-block c-grp-label" (click)="openClose($event,item)" *ngIf="(i==0 || item['groupId']!==filteredkeywords[i-1]['groupId'])">{{item.groupNumber ? item.groupNumber : crudemailtemplateCaptions.General}} <i *ngIf="!item.isExpanded" aria-hidden="true" class="template-icon-plus">+</i> <i *ngIf="item.isExpanded"  class="template-icon-minus" aria-hidden="true">-</i> </label>
                                    <ng-container *ngIf="item.isExpanded">
                                        <button [attr.automationId]="'Btn_crudTemplateEmail_itemName1'" mat-raised-button [matTooltip]="item.description"
                                            class="crudDetails-btn body-bgcolor whitecolor textellipsis"
                                            (click)="elementClick(item.name)">
                                            {{item.description}}
                                        </button>
                                    </ng-container>
                              </ng-container>
                            </div>
                          </ng-container>
                        </div>
                    </div>
                </ng-container>
         
                     
            <div class="new-editor-section ag_mt--4" *ngIf="enableMultiLanguage">
                <app-language-template-editor (selectedtabindex)="selectedIndexEmit($event)" (RemovedLanguage)="DeleteNotificationTemplateContent($event)" [form]="CrudEmmailTempForm" [languages]="languages" [filteredkeywords]="filteredkeywords" [entireKeywords]="Entire_keywords"></app-language-template-editor> 
           </div> 
        </div>
        <div class="actions pl-4 pr-2">
            <button [attr.automationId]="'Btn_crudTemplateEmail_save'" mat-button class="text_capitalize LW14"
                [ngClass]="EnablesaveUpdBtn ? 'spa-primary-default' : 'spa-primary-disabled'"
                (click)="save()">{{saveUpdLabel}}</button>
            <button [attr.automationId]="'Btn_crudTemplateEmail_cancel'" mat-button class="ml-2 text_capitalize LW14 " (click)="promptForUnSavedChanges()">
                {{this.crudemailtemplateCaptions.cancel}}</button>
        </div>
    </form>
</section>
