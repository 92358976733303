<section class="h-100 CheckInCheckOutModal-container">  
  <div class="div-consent">    
      <div [formGroup]="checkInForm" class="div-players ag_display--flex ag_align-items--baseline" *ngIf="canShow">
        <label class="golf-form--label ag_mr--4">{{captions.teetime.selectPlayers}}</label>
        <ui-switch attr.automationId='Tog_{{automationId}}_autoSelectPlayers' #autoSelectPlayers [checkedLabel]="captions.teetime.toggleYes" [uncheckedLabel]="captions.teetime.toggleNo"
            (change)="autoSelectPlayertoggleChange($event)" formControlName="autoSelectPlayers">
        </ui-switch>
      </div>   
      <div class="div-icon-consent" *ngIf="isToShowConsentManagment" (click)="openConsentManagment()">
        <span class="boxed-icon">
          <i class="icon-policy"></i>
        </span>
        <span class="span-label">{{captions.teetime.consentManagement}}</span>
      </div>    
  </div>
  <section class="golf-container--padding ag-pt-0  ag-pb-0 tableContainer" [ngClass]="{'pending1':(flagValue) ,
                'pending2':(!flagValue)}" id="CheckInCheckOutModal">


    <app-checkbox-table [automationId]="automationId"  [tableForm]="checkInCheckOutFormGroup" [childTemplate]="childTemplate" [headers]="headerOption"
                        [tableData]="tableData" (checkedData)="checkedData($event)" (unCheckedData)="unCheckedData($event)" (checkAllData)="checkAllData($event)">
    </app-checkbox-table>
  </section>
</section>
<ng-template #childTemplate let-element="element" let-key="key" let-data="data" let-row="row" let-index="idx">
  <ng-container [ngSwitch]="key">
    <ng-container *ngSwitchCase="'action'">
      <a attr.automationId='Tog_{{automationId}}_release{{idx}}' (click)='row.isReleaseAllow && activityAction(row)'
         *ngIf="row.isHold"
         [ngClass]="{'cust-button-disabled':!row.isReleaseAllow} ">{{captions.teetime.release}}</a>
    </ng-container>
  </ng-container>
</ng-template>

