import { Injectable } from '@angular/core';
import { DefaultsSettingConfig } from 'src/app/shared/models/default-settings.models';
import { PlayerType } from 'src/app/settings/golf-setup/code-setup/player-type/player-type.modal';
import { RateType } from 'src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal';
import { API } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import { API as ttm } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { GolfManagementCommunication } from 'src/app/shared/communication/services/golfmanagement.service';
import { of } from 'rxjs';
import { SettingScreen, PropertyConfigurations } from 'src/app/shared/global.constant';
import { PropertyConfigurationDataService } from 'src/app/shared/data-services/tenantmanagement/propertyConfiguration.data.service';
import { SHOW_AVAILABLE_GV } from 'src/app/tee-time/tee-sheet/tee-sheet-constants';

@Injectable({
  providedIn: 'root'
})

export class SystemdefaultsInformationService {

  private defaultSettings: DefaultsSettingConfig;
  private playerTypes: PlayerType[];
  private rateTypes: RateType[];
  private allocationBlocks: API.TeeTimeAllocationBlock[];
  private golfSetting: ttm.GolfSetting;
  private teeTimeSettings : ttm.TeeTimeConfig;
  constructor(
    private _http: GolfManagementCommunication,
    private _propertyConfigurationDataService:PropertyConfigurationDataService  ) {
  }

  async syncDefaultSettings() {
    await this.getDefaultsSetting().then((d) => {
      this.defaultSettings = d;
      sessionStorage.setItem('defaultSettings', JSON.stringify(this.defaultSettings));
    });
    await this.getAllPlayerTypes().then((p) => {
      this.playerTypes = p;
      sessionStorage.setItem('playerTypes', JSON.stringify(this.playerTypes));
    });
    await this.getAllRateTypes().then((r) => {
      this.rateTypes = r;
      sessionStorage.setItem('rateTypes', JSON.stringify(this.rateTypes));
    });
    await this.getAllocationBlocks().then((a) => {
      this.allocationBlocks = a;
      sessionStorage.setItem('allocationBlocks', JSON.stringify(this.allocationBlocks));
    });
    await this.getGolfSetting().then((a)=>{
       this.golfSetting = a;
       sessionStorage.setItem(SettingScreen.GolfSetting, JSON.stringify(a));
    });
    await this.getApplicationFormatConfiguration().then((a)=>{    
      sessionStorage.setItem(PropertyConfigurations.ApplicationFormat, JSON.stringify(a));
   })
   await this.getTeeTimeSetting().then((t)=>
   {
     this.teeTimeSettings = t;
     sessionStorage.setItem(SettingScreen.TeeTime, JSON.stringify(t));
   })
  }
  async getApplicationFormatConfiguration() {
   const config = await this._propertyConfigurationDataService.getApplicationFormatConfiguration();
    return config.configValue;
  }

  GetDefaultsSetting(): Promise<ttm.DefaultsSettingConfig> {
    if (!this.defaultSettings) {
      this.getDefaultsSetting().then(r => {
        this.defaultSettings = r;
      });
    }
    return of(this.defaultSettings).toPromise();
  }
  GetTeeTimeSetting(): Promise<ttm.TeeTimeConfig> {
    if (!this.teeTimeSettings) {
      this.getTeeTimeSetting().then(r => {
        this.teeTimeSettings = r;
      });
    }
    return of(this.teeTimeSettings).toPromise();
  }
  private async getAllPlayerTypes(bShowActivePlayerTypes: boolean = true): Promise<PlayerType[]> {
    return await this._http.getPromise<PlayerType[]>(
      { route: GolfApiRoute.GetAllPlayerTypes, uriParams: { "isActive": bShowActivePlayerTypes } });
     
  }
  private async getAllRateTypes(showInactiveRateTypes: boolean = true): Promise<RateType[]> {
    return  this._http.getPromise<RateType[]>(
      { route: GolfApiRoute.GetAllRateTypes, uriParams: { showInactive: showInactiveRateTypes } });
     
  }
  private async getAllocationBlocks(_includeInactive: boolean = true): Promise<API.TeeTimeAllocationBlock[]> {
    return await this._http.getPromise<API.TeeTimeAllocationBlock[]>({
      route: GolfApiRoute.GetAllAllocationBlocks,
      uriParams: { includeInactive: _includeInactive + '' }
    });
  }
  private async getDefaultsSetting(): Promise<ttm.DefaultsSettingConfig> {
    const result = this._http.getPromise<ttm.Settings<ttm.DefaultsSettingConfig>>(
      { route: GolfApiRoute.GetDefaultsSettings });
    const settings: ttm.Settings<ttm.DefaultsSettingConfig> = await result;
    return settings.configValue;
  }
  private async getTeeTimeSetting(): Promise<ttm.TeeTimeConfig> {
    const result = this._http.getPromise<ttm.Settings<ttm.TeeTimeConfig>>(
      { route: GolfApiRoute.GetTeeSettings });
    const settings: ttm.Settings<ttm.TeeTimeConfig> = await result;
    return settings.configValue;
  }
  private async getGolfSetting(): Promise<ttm.GolfSetting> {
    const result = await this._http.getPromise<ttm.Settings<ttm.GolfSetting>>(
      { route: GolfApiRoute.GetGolfSettings });
    const settings: ttm.Settings<ttm.GolfSetting> = await result;
    return settings.configValue;
  }
  EnsureDataAvailable() {
    if (this.defaultSettings == undefined || this.defaultSettings == null) {
      this.defaultSettings = JSON.parse(sessionStorage.getItem('defaultSettings'));
    }
    if (this.allocationBlocks == undefined || this.allocationBlocks == null) {
      this.allocationBlocks = JSON.parse(sessionStorage.getItem('allocationBlocks'));
    }
    if (this.playerTypes  == undefined || this.playerTypes == null) {
      this.playerTypes = JSON.parse(sessionStorage.getItem('playerTypes'));
    }
    if (this.rateTypes  == undefined || this.rateTypes == null) {
      this.rateTypes = JSON.parse(sessionStorage.getItem('rateTypes'));
    }
    if (this.teeTimeSettings  == undefined || this.teeTimeSettings == null) {
      this.teeTimeSettings = JSON.parse(sessionStorage.getItem(SettingScreen.TeeTime));
    }
  }
  GetDefaultBlockingCode() {
    this.EnsureDataAvailable();
    let block = this.allocationBlocks ? this.allocationBlocks.find(r => r.id == this.defaultSettings.blockingCode) : null;
    return block ? { id: this.defaultSettings.blockingCode, value: { colorCode: block.colorCode, icon: block.allocationBlockCode, id: block.id, name: block.allocationBlockName } } : null;
  }

  GetDefaultMemberStatus() {
    this.EnsureDataAvailable();
    return this.defaultSettings.memberStatus;
  }
  GetDefaultMemberPlayerType() {
    this.EnsureDataAvailable();
    let description = this.playerTypes.find(r => r.id == this.defaultSettings.memberPlayerType)?.type;
    return { id: this.defaultSettings.memberPlayerType, type: description };
  }
  GetDefaultMemberRateType() {
    this.EnsureDataAvailable();
    let description = this.rateTypes.find(r => r.id == this.defaultSettings.memberRateType)?.type;
    return { id: this.defaultSettings.memberRateType, type: description };
  }
  GetDefaultNonMemberPlayerType() {
    this.EnsureDataAvailable();
    let _plyrType = this.playerTypes.find(r => r.id == this.defaultSettings.nonMemberPlayerType);
    let description = _plyrType ? _plyrType.type : '';
    return { id: this.defaultSettings.nonMemberPlayerType, type: description };

  }
  GetDefaultNonMemberRateType() {
    this.EnsureDataAvailable();
    let description = this.rateTypes && this.rateTypes.find(r => r.id == this.defaultSettings.nonMemberRateType) ? this.rateTypes.find(r => r.id == this.defaultSettings.nonMemberRateType).type : '';
    return { id: this.defaultSettings.nonMemberRateType, type: description };
  }
  GetDefaultResortPlayerType() {
    this.EnsureDataAvailable();
    let playerType = this.playerTypes.find(r => r.id == this.defaultSettings.resortPlayerType);
    const description = playerType?playerType.type:'';
    return { id: this.defaultSettings.resortPlayerType, type: description };
  }
  GetDefaultResortRateType() {
    this.EnsureDataAvailable();
    let description = this.rateTypes.find(r => r.id == this.defaultSettings.resortRateType)?.type;
    return { id: this.defaultSettings.resortRateType, type: description };
  }
  GetAutoTeeTimeHoldConfig() {
    this.EnsureDataAvailable();
    return this.defaultSettings.autoTeeTimeHoldConfig;
  }
  GetAutoTeeTimeHoldReleaseMinutes() {
    this.EnsureDataAvailable();
    return this.defaultSettings.autoTeeTimeHoldConfig.holdReleaseMinutes;
  }
  GetAutoTeeTimeHoldCheckingIntervalInSeconds() {
    this.EnsureDataAvailable();
    return this.defaultSettings.autoTeeTimeHoldConfig.holdCheckingIntervalInSeconds;
  }
  GetAutoTeeTimeHoldEnabledStatus() {
    this.EnsureDataAvailable();
    return this.defaultSettings.autoTeeTimeHoldConfig.isEnabled;
  }
  GetshowAvailableOnlyStatus() {
    this.EnsureDataAvailable();
    let availableStatus;
    let showavailable = sessionStorage.getItem(SHOW_AVAILABLE_GV);
    if(showavailable) {
      availableStatus = (showavailable == 'true');
    } else {
      availableStatus = this.defaultSettings.showAvailableOnly
    }
    return availableStatus;
  }

  SetShowAvailableOnlyStatus(value) {
    sessionStorage.setItem(SHOW_AVAILABLE_GV, value);
  }
  GetRequiredReasonTeeTimeCancellationStatus() {
    this.EnsureDataAvailable();
    return this.defaultSettings.requiredReasonTeeTimeCancellation;
  }
  UpdateDefaultSetting(settings:DefaultsSettingConfig){
    this.defaultSettings = settings;
    sessionStorage.setItem('defaultSettings', JSON.stringify(this.defaultSettings));
  }
  UpdateTeeTimeSetting(settings:ttm.TeeTimeConfig){
    this.teeTimeSettings = settings;
    sessionStorage.setItem(SettingScreen.TeeTime, JSON.stringify(this.teeTimeSettings));
  }
  GetEditCaddyInfoOnMoveCopySwitch() {
    this.EnsureDataAvailable();
    return this.teeTimeSettings.editCaddyInfoOnMoveCopy;
  }
}
