import { Component, OnInit, Output, EventEmitter, OnDestroy, Inject, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { PaymentModalBussiness } from './payment-modal-bussiness';
import { PaymentModalService } from './payment-modal-service';
import { StepperService } from '../../shared/components/stepper/stepper.service';
import { takeUntil } from 'rxjs/operators';
import { CheckboxTableHeader, DialogCloseObj, AlertAction, AlertType, ButtonType } from 'src/app/shared/shared-models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UnPaidPlayersBusiness } from 'src/app/shared/data-services/golfschedule/unpaid-players.business';
import { UnPaidPlayersService } from 'src/app/shared/data-services/golfschedule/unpaid-players.service';
import { AssignRetailItemService } from 'src/app/settings/settings-shared/assign-retail-item/assign-retail-item.service';
import { CommonPopupComponent } from 'src/app/shared/components/common-popup/common-popup.component';
import { RateSetupBusiness } from 'src/app/settings/rate-setup/rate-setup.business';
import { RateSetupDataService } from 'src/app/shared/data-services/golfmanagement/rate-setup.data.service';
import { RateTypeDataService } from 'src/app/shared/data-services/golfmanagement/ratetype.data.service';
import { PlayerTypeService } from 'src/app/shared/data-services/golfmanagement/playertype.data.service';
import { CourseDataService } from 'src/app/shared/data-services/golfmanagement/course.data.service';
import { ButtonAction, GuaranteeType, playerTypes } from 'src/app/shared/global.constant';
import { GolfUserConfigDataService } from 'src/app/shared/data-services/golfmanagement/golfuser.config.data';
import { RateType, PlayerTypes, DynamicPricingPlayerFee } from '../teetime/player-details/player-info.model';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { MemberBusinessService } from 'src/app/retail/shared/business/Member-business.service';
import { TeeTimeFormat } from 'src/app/shared/models/teesheet.form.models';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { TeeTimesActionService } from 'src/app/shared/data-services/golfschedule/teeTimesAction.data.service';
import { TeeTimeTempHoldResult } from '../teetime/tee-time.model';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { guestType, playerCategory } from 'src/app/lessons/lessons-modal/player-info/player-info.model';
import { DefaultsBusinessService } from 'src/app/settings/system-setup/defaults/defaults.business.service';
import { CommonVariablesService } from 'src/app/retail/shared/service/common-variables.service';
import { PlayerPaymentstatus, PolicyCategoryType } from '../../common/shared/shared.modal';
import { TeeTimesActionBusiness } from '../../tee-time/shared/tee-action.business';
import { ButtonTypes, PlayerCategory } from '../../common/enums/shared-enums';
import _, { cloneDeep } from 'lodash';
import { AllocationBlockWithPlayerType } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import { PlayerTypeRateType } from 'src/app/tee-time/tournaments/tournaments-modal/tournament-details/tournament-details.model';
import { DropdownOptions} from 'src/app/common/Models/ag-models';
import { PlayerTypeInfo, RateTypeInfo, ScheduledTeeTimeUnPaidPlayer } from 'src/app/shared/models/unpaid-players.model';
import { PlayerTypeRateTypeChangeConfig } from 'src/app/tee-time/tee-time-model';
import { PlayerTypeRateTypeChangeService } from 'src/app/shared/service/playerTypeRateTypeChange.service';
import { NotifierBar } from 'src/app/shared/components/note/note.model';
import { DomainRetailItem } from 'src/app/settings/settings-shared/assign-retail-item/assign-retail-item.model';


@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
  providers: [
    PaymentModalBussiness,
    PaymentModalService,
    UnPaidPlayersBusiness,
    UnPaidPlayersService,
    AssignRetailItemService,
    RateSetupBusiness,
    RateSetupDataService,
    RateTypeDataService,
    PlayerTypeService,
    CourseDataService,
    GolfUserConfigDataService,
    DefaultsBusinessService,
    PlayerTypeRateTypeChangeService
  ],
  encapsulation: ViewEncapsulation.None
})
export class PaymentModalComponent implements OnInit, OnDestroy {

  PaymentForm: UntypedFormGroup;
  @Output() notifyParent = new EventEmitter();
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  headerOption: CheckboxTableHeader[];
  tableData: ScheduledTeeTimeUnPaidPlayer[];
  originalTableData: ScheduledTeeTimeUnPaidPlayer[];
  tempHoldPlayer: TeeTimeTempHoldResult[];
  checkboxTrueArray: ScheduledTeeTimeUnPaidPlayer[];
  checkboxFalseArray: ScheduledTeeTimeUnPaidPlayer[];
  dateA: any;
  dateB: any;
  data: any;
  isEditPay:boolean = false;
  flagValue: boolean = false;
  isMarkAsPaid: boolean = false;
  isPlayerPayReleaseBP: boolean = false;
  disableActionBtn = false;
  rateTypes: RateType[] = [];
  playerTypes: PlayerTypes[] = [];
  playerTypeRateTypes: PlayerTypeRateType[] = [];
  allocationBlockWithPlayerTypes: AllocationBlockWithPlayerType[]= [];
  activityButton: ButtonValue;
  captions: any;
  defaultSettings: any;
  defaultMarkAsPaidPaymentMethod: any;
  isCommentsRequired: boolean = false;
  floatLabel: string;
  isToShowConsentManagment: boolean = false;
  canShow = false;
  popRes = false;
  bulkPlayerIds = [];
  playerTypeRateTypeChangeConfig: PlayerTypeRateTypeChangeConfig;
  errorCaptions:any;
  isSaveEnabled:boolean = false;
  notifierBar: NotifierBar;
  isNotificationOn = false;
  payDate:string="";
  isNavigateToOrderSummary:boolean = false;
  showSelectPlayer: boolean = false;
  constructor(private _FormBuilder: UntypedFormBuilder
    , private _payPlayerBusiness: PaymentModalBussiness
    , private _teeTimeActionService: TeeTimesActionService
    , public _StepperService: StepperService
    , @Inject(MAT_DIALOG_DATA) public dialogData
    , public dialogRef: MatDialogRef<CommonPopupComponent>
    , private _rateTypeDataService: RateTypeDataService
    , private _Utilities: GolfUtilities
    , private localization: GolfLocalization
    , private _memberService: MemberBusinessService
    , private _retailSharedVarService: RetailSharedVariableService
    , private _defaultsBusinessService: DefaultsBusinessService
    , public _ss: CommonVariablesService
    , private _teeTimeActionBusiness: TeeTimesActionBusiness
    , private _playerTypeService: PlayerTypeService
    , private _playerTypeRateTypeChangeService:PlayerTypeRateTypeChangeService
  ) {
    this.data = dialogData.info;
    this.data.isEditPay = dialogData?.isEditPay && dialogData?.isEditPay !=undefined  ? dialogData?.isEditPay : false;
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
    this.isEditPay = dialogData.isEditPay ? dialogData.isEditPay : false;
    this.errorCaptions = this.localization.captions.settings;
  }

  ngOnInit() {
    this._StepperService.fromPaymentActions = true;
    this._StepperService.paymentActionButton = this.captions.lbl_payNow;
    this._StepperService.paymentActionShop = this.captions.lbl_shop;
    this.payDate = this.localization.LocalizeShortDate(this.data.time);
    this.notifierBar = {
      class: '',
      value: this.captions.lbl_noPermission,
      color: '#efd8a6'
    }
    var teeTimeSettings = JSON.parse(sessionStorage.getItem('TEETIMESETTING'));
    if (this.dialogData.actionType == ButtonAction.markAsPaid) {
      this.isMarkAsPaid = true;
      this._StepperService.fromPaymentActions = false
      this.showSelectPlayer = false;
    }
    else {
      this.showSelectPlayer = true;
      let isGdprEnabled = JSON.parse(sessionStorage.getItem('isGdprEnabled'));
      this.isToShowConsentManagment = teeTimeSettings && teeTimeSettings != null && !teeTimeSettings?.requireCheckInCheckOut && isGdprEnabled
    }
    this.formGenerator();
    this.headerOption = this._payPlayerBusiness.GetHeaderOption(false, this.isMarkAsPaid);
    this.getPlayerProfileBreakPointAccess();
    if(!this.isMarkAsPaid) {
      this._playerTypeRateTypeChangeService.getPlayerTypeRateTypeBreakPoints().then(res=>{
        this._playerTypeRateTypeChangeService.setPlayerTypeRateTypeChange(this.data.time);
        this.headerOption = this._payPlayerBusiness.GetHeaderOption(false, this.isMarkAsPaid, this._playerTypeRateTypeChangeService.isPlayerTypeRateTypeChangeEnable);
        this.isNotificationOn = this._playerTypeRateTypeChangeService.isPlayerTypeRateTypeChangeEnable ? this._playerTypeRateTypeChangeService.isDisableDropdownEdit : false ; 
      });
    }
    this.canShow = teeTimeSettings.includePlayersAcrossCoursesForCheckIn;
    this.getUnPaidPlayers();
    this.notifyParent.emit(false);

    this.PaymentForm.statusChanges.pipe(takeUntil(this.$destroyed)).subscribe(x => {
      let checkBoxItems = this.PaymentForm.value.checkBoxItems;
      let isCheckBoxChecked = checkBoxItems && checkBoxItems?.length > 0 && checkBoxItems.some(y => y.checkBox) ? true : false;
      if(this.PaymentForm.dirty){
        this.isSaveEnabled = true;
      }
      if (this.PaymentForm.valid && isCheckBoxChecked && !this.disableActionBtn) {
        this.notifyParent.emit(true);
      }
      else {
        this.notifyParent.emit(false);
      }
    });
    this._StepperService.valueChange.pipe(takeUntil(this.$destroyed)).subscribe((x: DialogCloseObj) => {
      this.isNavigateToOrderSummary = (x.type.toLowerCase() == this.captions.lbl_payNow.toLowerCase() || x.type.toLowerCase() == this.captions.lbl_savepayNow.toLowerCase()) ?  true: false; 
      if ((x.type.toLowerCase() == this.captions.lbl_payNow.toLowerCase() || x.type.toLowerCase() == this.captions.lbl_savepayNow.toLowerCase() ||  x.type.toLowerCase() === ButtonAction.pay.toLowerCase() || x.type === ButtonAction.markAsPaid.toLowerCase()) && !this.isEditPay) {
        this._Utilities.ToggleLoader(true);
        this.addPlayersInHold().then(resp => {
          this._Utilities.ToggleLoader(false);
          if(resp == true)
          {
            this.submitForm();
          }     
          else
          {
            this.getUnPaidPlayers();
            this.tableData = [...this.tableData];
            if (this.isMarkAsPaid) {
              this.AssignOriginalTableData(this.tableData)
          }
          }    
        }).catch(err => {
          this._Utilities.ToggleLoader(false);
          this.getUnPaidPlayers();
          this.tableData = [...this.tableData];
          if (this.isMarkAsPaid) {
            this.AssignOriginalTableData(this.tableData)
          }
        });
      }
      else if(this.isEditPay){
        this.submitForm();
      }
    });
    this.activityButton = {
      label: this.captions.btn_bookActivity,
      type: 'primary'
    }

  }

  onDropdownChange(e){
    if(e){
      this.isSaveEnabled = true;
      this._StepperService.paymentActionButton = this.captions.lbl_savepayNow;
      this._StepperService.paymentActionShop = this.captions.lbl_saveshop;
    }
  }

  formGenerator() {
    this.PaymentForm = this._FormBuilder.group({
      autoSelectPlayers: false
    });
    if (this.isMarkAsPaid) {
      this.PaymentForm.addControl('paymentComment', new UntypedFormControl(''));
    }
  }

  async getHoldPlayers(playerIds: number[]) {
    this.tempHoldPlayer = await this._teeTimeActionService.GetTempHoldPlayers(playerIds);
    console.log(this.tempHoldPlayer)
  }

  async getPlayerProfileBreakPointAccess() {
    this._teeTimeActionService.ValidateBreakPoint(UserAccessBreakPoints.PLAYERPAYRELEASE, false).then(x => {
      this.isPlayerPayReleaseBP = x.isAllow;
    });
  }

  async getUnPaidPlayers() {
    try{
      document.getElementById('custom-cover-spin').style.display = 'block';
    let teeTimeSettings = JSON.parse(sessionStorage.getItem('TEETIMESETTING'));
    this.PaymentForm.controls.autoSelectPlayers.setValue(this.canShow);    
    this.PaymentForm.updateValueAndValidity();
    if (this.isMarkAsPaid) {
      if (!this.defaultSettings)
        this.defaultSettings = await this._payPlayerBusiness.getDefaultSettings();
      this._defaultsBusinessService.getUserDefinedPaymentMethods().subscribe(res => {
        this.defaultMarkAsPaidPaymentMethod = res.filter(x => x.paymentTypeId >= 1000);
        if (this.defaultMarkAsPaidPaymentMethod?.length > 0 && this.defaultMarkAsPaidPaymentMethod.find(c => c.paymentTypeId == this.defaultSettings.markAsPaidPaymentMethod)?.requireComments) {
          this.isCommentsRequired = true;
          this.PaymentForm.controls.paymentComment.setValidators([Validators.required]);
        }
        else {
          this.isCommentsRequired = false;
          this.PaymentForm.controls.paymentComment.clearValidators();
        }
        if (this.defaultSettings && this.defaultMarkAsPaidPaymentMethod.find(c => c.paymentTypeId == this.defaultSettings.markAsPaidPaymentMethod) && this.defaultSettings?.markAsPaidPaymentMethodReceiptComment) {
          this.PaymentForm.controls.paymentComment.setValue(this.defaultSettings.markAsPaidPaymentMethodReceiptComment);
        }
        this.PaymentForm.controls.paymentComment.updateValueAndValidity();
      });
    }
    let tableDatas = await this._payPlayerBusiness.GetPlayers(this.data, this.RefreshGrid.bind(this), this.isMarkAsPaid, this.dialogData.actionType == ButtonAction.pay);
    console.log(tableDatas);
    if (this.isMarkAsPaid) {
      var arrDepositTransactionDetail = await this._payPlayerBusiness.GetTransactionDepositDetails(tableDatas);
      if (this.isMarkAsPaid && arrDepositTransactionDetail && arrDepositTransactionDetail?.length > 0 && arrDepositTransactionDetail.some(x => x.isDepositTransaction == true && x.isTransactionClosed == false)) {
        let res = this._Utilities.showAlert(this.captions.teetime.DepositWithPendingSettlementValidation, AlertType.Warning, ButtonType.Ok);
        await res.afterClosed().toPromise();
        this._StepperService.enableCancel = true;
      }
      tableDatas = tableDatas.filter(c => c.playerCategoryId != playerCategory.member && c.multiPackTransactionDetailId == 0);
      tableDatas = [...tableDatas];
    }
    let playerIds = tableDatas.map(c => c.playerId);
    await this.getHoldPlayers(playerIds);
    const loginUserId = this.localization.GetUserInfo('userId');
    if ((!this.isMarkAsPaid && teeTimeSettings && teeTimeSettings != null && teeTimeSettings?.requireCheckInCheckOut && !teeTimeSettings?.allowPaymentInCheckIn) || (!this.isMarkAsPaid && teeTimeSettings && teeTimeSettings != null && teeTimeSettings?.requireCheckInCheckOut && teeTimeSettings?.allowPaymentInCheckIn && (this.dialogData?.info?.isFromSearch || this.dialogData?.info?.isToFilterCheckInPlayer))) {
      tableDatas = tableDatas.filter(c => (c.playerStatus & PlayerPaymentstatus.checkIn) != 0 || (c.playerStatus & PlayerPaymentstatus.checkOut) != 0);
      tableDatas = [...tableDatas];
    }
    if (this.tempHoldPlayer.length > 0) {
      tableDatas.forEach(a => {
        var isDepoistWithPendingSettlement: boolean = false;
        if (this.isMarkAsPaid && arrDepositTransactionDetail && arrDepositTransactionDetail?.length > 0) {
          isDepoistWithPendingSettlement = arrDepositTransactionDetail.some(x => x.transactionId == a.transactionId && x.isDepositTransaction == true && x.isTransactionClosed == false)
        }
        let playerLength = this.tempHoldPlayer.filter(c => c.playerId == a.playerId).length > 0 ? true : false;
        const blockByUserName = playerLength == true ? this.tempHoldPlayer.find(c => c.playerId == a.playerId).userName : "";
        const blockByUserId = playerLength == true ? this.tempHoldPlayer.find(c => c.playerId == a.playerId).createdBy : 0;
        a.blockByUser = blockByUserName;
        a.isHold = blockByUserName != "" && blockByUserName != undefined ? true : (isDepoistWithPendingSettlement ? true : false);
        a.isReleaseAllow = blockByUserId && blockByUserId.toString() == loginUserId ? true : this.isPlayerPayReleaseBP;
        a.isNotDepositWithPendingSettlement = a.isHold && isDepoistWithPendingSettlement ? false : true;
        a.checked = a.isHold == true ? false : a.checked;
      });
      console.log(tableDatas);
    }
    else if (this.isMarkAsPaid && arrDepositTransactionDetail && arrDepositTransactionDetail?.length > 0) {
      var isDepoistWithPendingSettlement: boolean = false;
      tableDatas.forEach(a => {
        if (this.isMarkAsPaid && arrDepositTransactionDetail && arrDepositTransactionDetail?.length > 0) {
          isDepoistWithPendingSettlement = arrDepositTransactionDetail.some(x => x.transactionId == a.transactionId && x.isDepositTransaction == true && x.isTransactionClosed == false)
        }
        a.isHold = (isDepoistWithPendingSettlement ? true : false);
        a.isNotDepositWithPendingSettlement = a.isHold && isDepoistWithPendingSettlement ? false : true;
        a.checked = a.isHold == true ? false : a.checked;
      });
    }
    if(!this.isMarkAsPaid && this._playerTypeRateTypeChangeService.isPlayerTypeRateTypeChangeEnable){
      if(this._playerTypeRateTypeChangeService.retailItems && this._playerTypeRateTypeChangeService.retailItems.length==0) this._playerTypeRateTypeChangeService.retailItems = [];
      console.log(this._playerTypeRateTypeChangeService.retailItems);
      console.log(this._playerTypeRateTypeChangeService.customFeeData);
      tableDatas.forEach(a => {
        this.updatePlayerTypeRateTypeTableDetails(a);
      });
      console.log(this._playerTypeRateTypeChangeService.customFeeData);
      tableDatas = [...tableDatas];
      console.log(tableDatas);
    }
    this.flagValue = tableDatas.filter(x => x.isHold && x?.isNotDepositWithPendingSettlement)?.length > 0 ? true : false;

    if (tableDatas != null) {
      this.checkboxTrueArray = tableDatas.filter((el) => {
        return el.checked;
      });
      this.sortArray(this.checkboxTrueArray);
      this.checkboxFalseArray = tableDatas.filter((el) => {
        return !el.checked;
      });
      this.sortArray(this.checkboxFalseArray);
      tableDatas = this.checkboxTrueArray.concat(this.checkboxFalseArray);
    }
    this.headerOption = this._payPlayerBusiness.GetHeaderOption(this.flagValue, this.isMarkAsPaid, this._playerTypeRateTypeChangeService.isPlayerTypeRateTypeChangeEnable);
    tableDatas.forEach(data=>{
      this.updateCurrency(data);
    });
    this.tableData = tableDatas;
    this.tableData = [...this.tableData];
    if (this.isMarkAsPaid) {
      this.AssignOriginalTableData(this.tableData)
    }
    else if(!this.isMarkAsPaid && this.canShow && this.PaymentForm.controls.autoSelectPlayers.value && !this.isEditPay){
      this.mapCheckedData(true);
    }
    if(this.dialogData.actionType == ButtonAction.pay && teeTimeSettings.includePlayersAcrossCoursesForCheckIn){
      let courseOrderedUnpaidPlayers = this.tableData?.sort((a,b) => a.courseListOrder - b.courseListOrder);
      let originCourseData = courseOrderedUnpaidPlayers.filter(x => x.courseId === this.data.course.id);
      let otherCoursedata = courseOrderedUnpaidPlayers.filter(x => x.courseId !== this.data.course.id);
      this.tableData = originCourseData.concat(otherCoursedata);
    }
    let checkedplayers = this.tableData.filter(player => player.checked);
    let uncheckedplayers = this.tableData.filter(player => !player.checked);
    this.tableData = checkedplayers.concat(uncheckedplayers);
    this.tableData = [...this.tableData];
    }
    finally {
      document.getElementById('custom-cover-spin').style.display = 'none';
    };
  }

  updateCurrency(data:ScheduledTeeTimeUnPaidPlayer){
    data.totalFeeCurrency = this.localization.localizeCurrency((data.greenFee + data.cartFee), false, 2)  ;
    data.greenFeeCurrency = this.localization.localizeCurrency(data.greenFee , false, 2) ;
    data.cartFeeCurrency = this.localization.localizeCurrency(data.cartFee, false, 2) ;
    data.walk_trial = data.walk + '/' + data.trail; 
    data.scheduledDateTime = this.localization.LocalizeLongTime(this.localization.getDate(data.scheduledAPIDateTime)).toUpperCase();
  }

  updatePlayerTypeRateTypeTableDetails(data:ScheduledTeeTimeUnPaidPlayer)
  {
    data.isDirty = false;
    data.isRateTypeDirty = false;
    data.isMultiPackUpdated = false;
    data.isMultiPackApplied = data.multiPackTransactionDetailId > 0;
    data.oldMultiPackTransactionDetailId = data.multiPackTransactionDetailId;
    data.isPlayerTypeRequired = this._playerTypeRateTypeChangeService.isRequirePlayerType;
    data.isRateTypeRequired = this._playerTypeRateTypeChangeService.isRequireRateType;
    data.allocationBlockWithPlayerTypes = {} as AllocationBlockWithPlayerType;
    data.playerTypeInfo = {} as PlayerTypeInfo;
    data.rateTypeInfo = {} as RateTypeInfo;
    data.playerTypeRateTypes = [];
    data.isDisableDropdown = this._playerTypeRateTypeChangeService.isDisableDropdownEdit; // (data.checked && !this._playerTypeRateTypeChangeService.isDisableDropdownEdit)? false: true;
    data.walk_trial = data.walk + '/' + data.trail; 
    data.pos_holes = data.playerPosition + '/' + data.holes;
    data.totalFeeCurrency = this.localization.localizeCurrency((data.greenFee + data.cartFee), false, 2)  ;
    data.greenFeeCurrency = this.localization.localizeCurrency(data.greenFee , false, 2) ;
    data.cartFeeCurrency = this.localization.localizeCurrency(data.cartFee, false, 2) ;
    data.scheduledDateTime = this.localization.LocalizeLongTime(this.localization.getDate(data.scheduledAPIDateTime)).toUpperCase();
    data.overrideDetail = data.overrideDetail ? data.overrideDetail : [];
    data.multiPackRetailItem = data.multiPackRetailItem ? data.multiPackRetailItem: []; 
    data.dynamicPricing = {} as DynamicPricingPlayerFee;
    data.isDynamicPricingApplied = false;
    data.dynamicPricing = {} as DynamicPricingPlayerFee;
    data.isCustomFeeEnabled = this._playerTypeRateTypeChangeService.isCustomFeeEnabled;
    this._playerTypeRateTypeChangeService.customFeeData.push(this._playerTypeRateTypeChangeService.mapCustomFeeData(this.localization.getDate(data.scheduledAPIDateTime), data.scheduledTeeTimePlayerFeeId, (data.greenFee + data.cartFee), data.scheduledTeeTimePlayerCustomFees));
    if(data.greenFeeRetailItemId == 0) data.greenFeeRetailItem = {} as DomainRetailItem;
    if(data.cartFeeRetailItemId == 0) data.cartFeeRetailItem = {} as DomainRetailItem;
    if(data.entryFeeRetailItemId == 0) data.entryFeeRetailItem = {} as DomainRetailItem;
    if(data.greenFeeRetailItemId > 0 && this._playerTypeRateTypeChangeService.retailItems.filter(x=> x.id == data.greenFeeRetailItemId).length==0)this._playerTypeRateTypeChangeService.retailItems.push(data.greenFeeRetailItem);
    if(data.cartFeeRetailItemId > 0 && this._playerTypeRateTypeChangeService.retailItems.filter(x=> x.id == data.cartFeeRetailItemId).length==0)this._playerTypeRateTypeChangeService.retailItems.push(data.cartFeeRetailItem);
    if(data.tournamentId > 0 && data.entryFeeRetailItemId > 0 && this._playerTypeRateTypeChangeService.retailItems.filter(x=> x.id == data.entryFeeRetailItemId).length==0)this._playerTypeRateTypeChangeService.retailItems.push(data.entryFeeRetailItem);
  }

  private sortArray(playerArray) {
    if (playerArray && playerArray.length) {
      playerArray.sort((a, b) => {
        this.dateA = new Date(a.scheduledAPIDateTime);
        this.dateB = new Date(b.scheduledAPIDateTime);
        return this.dateA - this.dateB || (a.playerPosition > b.playerPosition ? 1 : -1);
      });
    }
  }
  UpdateAutoSelect(val){
    if (this.canShow) {
      this.PaymentForm.controls.autoSelectPlayers.setValue(val);
      this.PaymentForm.updateValueAndValidity();
    }
  }

  async RefreshGrid(res, extraParam) {
    let unpaidPlayers = extraParam[0];
    if (res === AlertAction.YES) {
      if (unpaidPlayers.find(x => x.isTournamentPlayersUnPaid && x.tournamentId > 0)) {
        if (this.isMarkAsPaid) {
          var uncheckedplayers = unpaidPlayers.filter(player => !player.checked && player.tournamentId == 0);
          this.sortArray(uncheckedplayers);
        }
        unpaidPlayers.forEach(p => { p.checked = !(p.tournamentId != extraParam[1]) });
        unpaidPlayers = unpaidPlayers.filter(x => x.tournamentId == extraParam[1]);
       
        this.sortArray(unpaidPlayers);
        if (this.isMarkAsPaid) {
          unpaidPlayers = unpaidPlayers.concat(uncheckedplayers);
          this._StepperService.enableCancel = true;
        }
        else {
          this.UpdateAutoSelect(false);
          this.disableActionBtn = true;
          this._StepperService.enableCancel = false;
        }
      } else if (this.data.isFromSearch && this.data.playerDetail.find(x => x.teeTimeFormat === TeeTimeFormat.BulkTee && extraParam[1].map(s => s.playerId).includes(x.playerId))) {
        unpaidPlayers.map(p => { p.checked = (p.teeTimeFormat === TeeTimeFormat.BulkTee && extraParam[1].map(s => s.id).includes(p.id)); });
        let checkedplayers = unpaidPlayers.filter(player => player.checked);
        this.popRes = true;
        this.bulkPlayerIds = checkedplayers.map(x=> x.playerId);
        this.sortArray(checkedplayers);
        let uncheckedplayers = unpaidPlayers.filter(player => !player.checked);
        this.sortArray(uncheckedplayers);
        unpaidPlayers = checkedplayers.concat(uncheckedplayers);
        this.UpdateAutoSelect(false);
      } else if (this.data.playerDetail.find(x => x.teeTimeFormat === TeeTimeFormat.BulkTee && extraParam[1].map(s => s.confirmationNumber).includes(x.confirmationNumber))) {
        unpaidPlayers.map(p => { p.checked = (p.teeTimeFormat === TeeTimeFormat.BulkTee && extraParam[1].map(s => s.confirmationNumber).includes(p.confirmationNumber)); });
        let checkedplayers = unpaidPlayers.filter(player => player.checked);
        this.popRes = true;
        this.bulkPlayerIds = checkedplayers.map(x=> x.playerId);
        this.sortArray(checkedplayers);
        let uncheckedplayers = unpaidPlayers.filter(player => !player.checked);
        this.sortArray(uncheckedplayers);
        unpaidPlayers = checkedplayers.concat(uncheckedplayers);
        this.UpdateAutoSelect(false);
      }
      if(!this.isMarkAsPaid){
        unpaidPlayers.forEach(a=>{
          this.updatePlayerTypeRateTypeTableDetails(a);
        });
      }
      else if(this.isMarkAsPaid){
        unpaidPlayers.forEach(data=>{
          this.updateCurrency(data);
        });
      }
      this.tableData = unpaidPlayers;
      if (!this.isMarkAsPaid && this.validateMember(unpaidPlayers) && unpaidPlayers.find(x => x.isTournamentPlayersUnPaid && x.tournamentId > 0)) {
        await this._payPlayerBusiness.ProceedToOrderSummary(this);
      }
    } else {
      let checkedplayers = unpaidPlayers.filter(player => player.checked);
      this.sortArray(checkedplayers);
      let uncheckedplayers = unpaidPlayers.filter(player => !player.checked);
      this.sortArray(uncheckedplayers);
      unpaidPlayers = uncheckedplayers && uncheckedplayers.length ? checkedplayers.concat(uncheckedplayers) : checkedplayers;
      if(!this.isMarkAsPaid){
        unpaidPlayers.forEach(a=>{
          this.updatePlayerTypeRateTypeTableDetails(a);
        });
      }
      else if(this.isMarkAsPaid){
        unpaidPlayers.forEach(data=>{
          this.updateCurrency(data);
        });
      }
      this.tableData = unpaidPlayers;
    }
  }

  releaseSelectedPlayerHolds(){
    const checkedItems = this.PaymentForm.controls.checkBoxItems.value.filter(x => x.checked === true);
    this._teeTimeActionService.releasePlayersInHold(checkedItems.map(c => c.playerId));
  }

  async submitForm() {
    let allowPay:boolean = true;
    let saveData = this.PaymentForm.controls.checkBoxItems.value.filter(x=> x.checked && x.isDirty);
    //save flow
    if(this.isSaveEnabled && saveData.length>0){
      let allowsave = true;
      if(this._playerTypeRateTypeChangeService.isQuickIdPopupEnableForSave)allowPay = allowsave = await this._playerTypeRateTypeChangeService.showSaveQuickIdPopup(saveData);
      if(allowsave){
        let updatePlayerFees = this._playerTypeRateTypeChangeService.mapUpdatePlayerFee(saveData);
        this._Utilities.ToggleLoader(true);
        allowPay = await this._playerTypeRateTypeChangeService.UpdatePlayerFees(updatePlayerFees);
        this._Utilities.ToggleLoader(false);
        if(!allowPay) {
          this.releaseSelectedPlayerHolds();
          this._Utilities.showAlert(this.errorCaptions.playerTypeRateTypeUpdateError, AlertType.Error, ButtonType.Ok);
        }
      }
      else{
        this._Utilities.ToggleLoader(false);
        this.releaseSelectedPlayerHolds();
      }
    }
    //pay flow
    if (allowPay && this.validateMember()) {
      this.notifyParent.emit(false);
      this._StepperService.enableCancel = false;
      if (!this.isMarkAsPaid) {
        await this._payPlayerBusiness.ProceedToOrderSummary(this,this.isNavigateToOrderSummary,false,true);
        this._StepperService.enableCancel = true;
      }
      else {
        await this._payPlayerBusiness.ProceedMarkAsPaid(this, async () => {
          this.getUnPaidPlayers()
        });
        this._StepperService.enableCancel = true;
      }
    }
  }

  async addPlayersInHold() : Promise<boolean> {
    return await this._payPlayerBusiness.HoldPlayers(this);
  }
  async releasePlayersInHold(playerIds: number[]) {
    await this._payPlayerBusiness.releasePlayersInHold(playerIds);
    await this.resetRetailItemInShop(playerIds);
  }
  async resetRetailItemInShop(playerIds: number[]) {
    this._retailSharedVarService.SelectedPlayers = this._retailSharedVarService.SelectedPlayers.filter(x => !(playerIds.some(z => x.playerId == z)))
    this._retailSharedVarService.selectedProducts = this._retailSharedVarService.selectedProducts.filter(x => !(playerIds.some(z => x.payeeId == z)))
  }

  ngOnDestroy(): void {
    this.disableActionBtn = false;
    this._StepperService.enableCancel = true;
    this._StepperService.fromPaymentActions = false;
    this._StepperService.paymentActionButton = null;
    this._StepperService.paymentActionShop = null;
    this.isSaveEnabled = false;
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  validateMember(unpaidPlayers: Array<any> = null): boolean {
    const checkedItems = (unpaidPlayers ||
      this.PaymentForm.controls.checkBoxItems.value)
      .filter(x => x.hasOwnProperty('checkBox') ? x.checkBox === true : x.checked === true);

    if (this._ss.allowMultipleMembersInTransaction() && this._memberService.AllowMultiMemberInTransaction) {
      return true;
    }
    let corpIds = _.uniq(checkedItems.map(o => o.corpId));
    corpIds = corpIds.filter(o => o != null && o != '');
      if(corpIds && corpIds.length > 1){
        this._Utilities.showAlert(this.captions.teetime.memberdifferentcorporates,AlertType.Error);
        this._teeTimeActionService.releasePlayersInHold(checkedItems.map(c => c.playerId));
        return false;
      }

    if (checkedItems && checkedItems.length && this.rateTypes && this.rateTypes.length) {
      let totalMemberGuaranteedPlayerAssociated = 0;
      checkedItems.forEach(player => {
        const isValidMember = this.isValidMember(player.rateTypeId, player.playerCategoryId);
        if (isValidMember &&
          (++totalMemberGuaranteedPlayerAssociated) > 1
        ) {
          return false;
        }
      });

      // Continue booking scenario handling to restrict one member per transaction
      this._retailSharedVarService.SelectedPlayers.forEach(player => {
        const isValidMember = this.isValidMember(player.rateTypeId, player.playerCategoryId);
        if (isValidMember &&
          (++totalMemberGuaranteedPlayerAssociated) > 1
        ) {
          return false;
        }
      });
      if (totalMemberGuaranteedPlayerAssociated > 1) {
        if (this.disableActionBtn) {
          this.disableActionBtn = false;
          this._StepperService.enableCancel = true;
        }
        this._Utilities.showAlert(this.localization.captions.teetime.RestrictMultiMemberInATransaction, AlertType.Info, ButtonType.Ok);
        this._teeTimeActionService.releasePlayersInHold(checkedItems.map(c => c.playerId));
        return false;
      }
    }
    return true;
  }

  isValidMember(rateTypeId, playerCategoryId): boolean {
    return (rateTypeId > 0 && (this.rateTypes.find(y => y.id == rateTypeId) || ({} as any)).guaranteeType == GuaranteeType.Member)
      || (playerCategoryId === 3);
  }
  activityAction(e, data) {
    let playerIds = [];
    playerIds.push(data.playerId);
    this.releasePlayersInHold(playerIds);
    this.tableData.find(c => c.playerId == data.playerId).isHold = false;
    this.tableData.find(c => c.playerId == data.playerId).blockByUser = "";

    let checkedItems: any[] = this.PaymentForm.value.checkBoxItems;

    this.tableData.forEach(x => {
      x.checked = checkedItems.find(c => c.id == x.playerId).checkBox == true ? true : false;
    })
    this.tableData = [...this.tableData];
    if (this.isMarkAsPaid) {
      this.AssignOriginalTableData(this.tableData)
    }
    data.isHold = false;
    this.flagValue = this.tableData.filter(x => x.isHold && x?.isNotDepositWithPendingSettlement)?.length > 0 ? true : false;
    this.headerOption = this._payPlayerBusiness.GetHeaderOption(this.flagValue, this.isMarkAsPaid, this._playerTypeRateTypeChangeService.isPlayerTypeRateTypeChangeEnable);
  }
  //temporarily commented
  // OnRateTypeChange(event) {
  //   if (this.isMarkAsPaid && event && event?.value) {
  //     let updatedList = this.originalTableData.filter(x => x.rateTypeId == event.value).map(x => { x.checked = false; return x });
  //     this.tableData = [...updatedList]
  //   }
  //   else {
  //     this.tableData = [...this.originalTableData];
  //   }

  // }
  AssignOriginalTableData(data: ScheduledTeeTimeUnPaidPlayer[]) {
    if (this.defaultSettings && this.defaultSettings?.enableNonZeroRateForMarkAsPaid)
      this.tableData = data.filter(x => x.cartFee >= 0 || x.greenFee >= 0 || x.entryFee >= 0);
    else
      this.tableData = data.filter(x => x.cartFee == 0 && x.greenFee == 0 && x.entryFee == 0);
    this.tableData = [...this.tableData]
    if (!this.tableData || this.tableData.length == 0)
      this._Utilities.showAlert(this.captions.teetime.NoPlayersforMarkasPaid, AlertType.Info, ButtonType.Ok, (res) => {
        this.dialogRef.close(res);
      });
    this.originalTableData = data.map(x => Object.assign({}, x))
    this.originalTableData.map(x => { if (x.checked) x.checked = false; return x })
  }
  openConsentManagment() {
    var arrGuestId = [];
    let popupComment: string = '';
    this.PaymentForm.value.checkBoxItems.forEach(c => {
      if (c.checkBox) {
        if (c.playerLinkId && (c.playerCategoryId == PlayerCategory.ExistingPlayer || c.playerCategoryId == PlayerCategory.HotelReservation))
          arrGuestId.push(c.playerLinkId)
        else
          popupComment = this.localization.captions.consentCommentOnGuest
      }
    });
    arrGuestId = arrGuestId.distinct();
    if (arrGuestId?.length > 0)
      this._teeTimeActionBusiness.openConsentManagmentModal(arrGuestId, PolicyCategoryType.Guest, popupComment, (res) => {

      });
    else {
      this._Utilities.showCommonAlert(this.localization.captions.teetime.consentManagmentValidation, AlertType.Info, ButtonTypes.Ok
      )
    }
  }

  autoSelectPlayertoggleChange(eve) {
    this.PaymentForm.controls.autoSelectPlayers.setValue(eve);
    this._Utilities.ToggleLoader(true);
    if (this.canShow) {
      let tableFormArr = this.PaymentForm.get('checkBoxItems') as UntypedFormArray;
      if (eve) {
        let playerLinkIds = [];
        let linkIds = [];
        this.data.playerDetail.map(x => {
            if (x.playerCategoryId === 3) {
              linkIds.push(x.linkingId);
            } else {
              linkIds.push(x.imageReferenceId);
            }
            playerLinkIds = this._Utilities.getDistinctGUIDs(linkIds);       
        });
        

        tableFormArr.controls.map(element => {
          let isChecked = (element.get('scheduledTeeTimeId').value == this.data.scheduledTeeTimeId) || (playerLinkIds?.length > 0 && playerLinkIds.includes(element.get('playerLinkId').value));
          element.get('checked').setValue(isChecked);
          element.get('checkBox').setValue(isChecked);
          let isDisable = (element.get('checked').value && !this._playerTypeRateTypeChangeService.isDisableDropdownEdit) ? false: true;    
          element.get('isDisableDropdown').setValue(isDisable);
        });
      } else {
        tableFormArr.controls.map(element => {
          let isChecked = this.popRes ? this.bulkPlayerIds.includes(element.get('playerId').value) : (element.get('scheduledTeeTimeId').value == this.data.scheduledTeeTimeId);
          element.get('checked').setValue(isChecked);
          element.get('checkBox').setValue(isChecked);
          let isDisable = (element.get('checked').value && !this._playerTypeRateTypeChangeService.isDisableDropdownEdit) ? false: true;    
          element.get('isDisableDropdown').setValue(isDisable);
          return element;
        });
        //this.tableData = [...this.tableData];
      }
    }   
    this._Utilities.ToggleLoader(false);
  }

  checkAllData(eve)
  {
    if(!this.isMarkAsPaid){
      let tableFormArr = this.PaymentForm.get('checkBoxItems') as UntypedFormArray;
      tableFormArr.controls.forEach(c=>{
        let isDisable = (c.get('checked').value && !this._playerTypeRateTypeChangeService.isDisableDropdownEdit) ? false: true;
        c.get('isDisableDropdown').setValue(isDisable);
      })
    }
  }

  checkedData(eve, fromOnInit = false) {
    if (!this.isMarkAsPaid && this.canShow && this.PaymentForm.controls.autoSelectPlayers.value) {
      //this.mapCheckedData(fromOnInit);
      let tableFormArr = this.PaymentForm.get('checkBoxItems') as UntypedFormArray;
      let checkedItems = this.PaymentForm.value.checkBoxItems?.filter(x => x.checkBox);
      let otherCategoryCheckedList = checkedItems?.filter(x => x.playerCategoryId != 1 &&  x.playerCategoryId != 0 && x.playerLinkId != null);
      let playerCategoryCheckedList = checkedItems?.filter(x => x.playerCategoryId == 1 || x.playerCategoryId == 0);
      if (playerCategoryCheckedList?.length > 0 || otherCategoryCheckedList?.length > 0 ) {
        let checkedPlayerIds = playerCategoryCheckedList.map(x => x.playerId);
        let checkedLinkIds = otherCategoryCheckedList.map(x => x.playerLinkId);
        tableFormArr.controls.map(element => {
          if (!element.get('checked').value) {
            element.get('checked').setValue((checkedPlayerIds.includes(element.get('playerId').value)) || (checkedLinkIds.includes(element.get('playerLinkId').value)));
            element.get('checkBox').setValue((checkedPlayerIds.includes(element.get('playerId').value)) || (checkedLinkIds.includes(element.get('playerLinkId').value)));
            let isDisable = (element.get('checked').value && !this._playerTypeRateTypeChangeService.isDisableDropdownEdit) ? false: true;    
            element.get('isDisableDropdown').setValue(isDisable);
          }          
        });
      }   
    } 
    else if(eve != null && !this.isMarkAsPaid){
      console.log(eve);
      let rowidx = this.tableData.findIndex(x=> x.playerId == eve.value.playerId);
      let tableFormArr = this.PaymentForm.get('checkBoxItems') as UntypedFormArray;
      tableFormArr.controls[rowidx].get('checked').setValue(true);
      let isDisable = (tableFormArr.controls[rowidx].get('checked').value && !this._playerTypeRateTypeChangeService.isDisableDropdownEdit) ? false: true;   
      tableFormArr.controls[rowidx].get('isDisableDropdown').setValue(isDisable);
    }   
  }

  unCheckedData(eve) {
    if (!this.isMarkAsPaid && this.canShow && this.PaymentForm.controls.autoSelectPlayers.value) {
      console.log(eve);
      let tableFormArr = this.PaymentForm.get('checkBoxItems') as UntypedFormArray;
      let playerLinkId = eve.value.playerLinkId;
      if (playerLinkId) {
        tableFormArr.controls.map(element => {
          if(playerLinkId === element.get('playerLinkId').value) {
            element.get('checked').setValue(false);
            element.get('checkBox').setValue(false);
            if(!this._playerTypeRateTypeChangeService.isDisableDropdownEdit) element.get('isDisableDropdown').setValue(true); 
          }      
        });
      } else {
        tableFormArr.controls.map(element => {
          if(eve.value.playerId === element.get('playerId').value) {
            element.get('checked').setValue(false);
            element.get('checkBox').setValue(false);
            if(!this._playerTypeRateTypeChangeService.isDisableDropdownEdit) element.get('isDisableDropdown').setValue(true);  
          }     
        });
      }     
    } else if(!this.isMarkAsPaid){
      let rowidx = this.tableData.findIndex(x=> x.playerId == eve.value.playerId);
      let tableFormArr = this.PaymentForm.get('checkBoxItems') as UntypedFormArray;
      tableFormArr.controls[rowidx].get('checked').setValue(false);
      if(!this._playerTypeRateTypeChangeService.isDisableDropdownEdit) tableFormArr.controls[rowidx].get('isDisableDropdown').setValue(true); 
    }   
  }

  private mapCheckedData(fromOnInit) {
    let checkedItems = fromOnInit ?  this.tableData.filter(x => x.checked) : this.PaymentForm.value.checkBoxItems?.filter(x => x.checkBox);
    let otherCategoryCheckedList = checkedItems?.filter(x => x.playerCategoryId != 1 &&  x.playerCategoryId != 0 && x.playerLinkId != null);
    let playerCategoryCheckedList = checkedItems?.filter(x => x.playerCategoryId == 1 || x.playerCategoryId == 0);
    if (playerCategoryCheckedList?.length > 0 || otherCategoryCheckedList?.length > 0 ) {
      let checkedPlayerIds = playerCategoryCheckedList.map(x => x.playerId);
      let checkedLinkIds = otherCategoryCheckedList.map(x => x.playerLinkId);
      this.tableData.map(element => {
        if (!element.checked) {
          element.checked = (checkedPlayerIds.includes(element.playerId)) || (checkedLinkIds.includes(element.playerLinkId));
        }  
        element.isDisableDropdown = (element.checked && !this._playerTypeRateTypeChangeService.isDisableDropdownEdit) ? false: true;           
      });
    }
    this.tableData = [...this.tableData];    
  }

  updatePlayerEmit(e){
    if(e){
      this._StepperService.enableSave = false;
      this._StepperService.enableCancel = false;
    } else{
      this._StepperService.enableSave = true;
      this._StepperService.enableCancel = true;
    }
  }
}
