import { EventEmitter, Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class routingMenuService {
    selectedRoutingMenu:any;
    moreselectedText:string;
    triggerADB2CLogOut=new EventEmitter<boolean>();
    selectedRoutingPath: string;

    constructor()
    {
        
        this.selectedRoutingPath=sessionStorage.getItem('selectedRoutingPath');
    }
    findEachElement(elements: any[], id: number): any {
        for (const el of elements) {
          if (el.elementID === id) {
            return el;
          }
          if (el.linkedElement && el.linkedElement.length > 0) {
            const found = this.findEachElement(el.linkedElement, id);
            if (found) {
              return found;
            }
          }
        }
        return null;
      }
    
      // Function to find the topmost parent element
      public findTopmostParent(data: any[], childID: number): any {
        let element = this.findEachElement(data, childID);
    
        // Traverse up to the topmost parent
        while (element && element.parentID !== 0) {
          element = this.findEachElement(data, element.parentID);
        }
    
        return element;
      }
    setselectedRoutingMenu(obj,text:string='',isAlternateMenu = false,alternateObj?){
        this.selectedRoutingMenu = obj;
        if(obj?.routePath)
        {
            this.selectedRoutingPath=obj?.routePath;
            sessionStorage.setItem('selectedRoutingPath',obj?.routePath);
            sessionStorage.setItem(
                "currentMenu",
                JSON.stringify(obj)
              );
        }
        if (text) {
            this.moreselectedText = !isAlternateMenu ? obj : alternateObj;
        } else if (obj?.parentID === 0) {
            this.moreselectedText = '';
        }
    
    }

}
