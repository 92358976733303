import { Injectable } from "@angular/core";
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { allocationBlockPlayerTypePermission } from "src/app/settings/user-setup/booking-permission/booking-permission.model";
import { AllocationBlockDataService as AllocationBlockManagementDataService } from 'src/app/shared/data-services/golfmanagement/allocationblock.data.service';

@Injectable()
export class TeePlayerDetailsBusiness{
    captions: any;
    constructor( private _localization:GolfLocalization, private allocationCodeManagementService: AllocationBlockManagementDataService,){ 
        this.captions = this._localization.captions.settings;
    }

    getCaptions(){
        return this._localization.captions.teetime;
    }

    public async GetAllocationBlockPermissionByRole(roleId?: string): Promise<allocationBlockPlayerTypePermission[]> {
        return await this.allocationCodeManagementService.getAllocationBlockPermissionByRole(roleId);
    }
}