import { Injectable } from '@angular/core';
import * as WaitlistInterface from './waitlist.model';
import { GolfLocalization } from '../../core/localization/golf-localization';
import { GolfScheduleCommunication } from 'src/app/shared/communication/services/golfschedule.service';

@Injectable()
export class WaitlistService {

  captions: WaitlistInterface.Captions;
  convertWaitlistApiData: WaitlistInterface.ConvertWaitlistApiData;
  createWaitlistApiData: WaitlistInterface.CreateWaitlistApiData;
  course: WaitlistInterface.course[];

  constructor(private _Localization: GolfLocalization, private _http: GolfScheduleCommunication) { }
  captionsGenerator() {
    this.captions = this._Localization.captions.teetime
    return this.captions;
  }


  public async DeleteWaitList(_id: number) {
    return this._http.deletePromise({
      route: GolfApiRoute.DeleteWaitList,
      uriParams: { id: _id }
    });
  }

  public GetWaitList(_scheduledDate: string, _courseId: number): Promise<WaitlistInterface.WaitList[]> {
    return this._http.getPromise({
      route: GolfApiRoute.GetWaitListByCourseId,
      uriParams: { dateTime: _scheduledDate, courseId: _courseId }
    });
  }

}

