import { Injectable } from '@angular/core'
import { RetailEventParameters, VoidEventModel, DepositEventModel, TicketChangeEventModel, UpdateCancellationNoShowChargeEventModel } from 'src/app/retail/shared/events/event.model';
import { GolfScheduleCommunication } from '../communication/services/golfschedule.service';
import { Deposit } from '../payment-model';
import { PlayerRetailItem, TeeTimeLinkedRetailItemsData } from 'src/app/retail/shop/shop.modals';
import { RetailItemType } from 'src/app/retail/retail.modals';
import { OrderSummaryComponenet } from 'src/app/retail/shop/order-summary/order-summary.component';
import { CancellationNoShowChargeStatus, CancellationNoShowLedger, CancelPlayers } from 'src/app/retail/shared/shared.modal';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RentalEventService } from './rental-event-service';
import { FromAction } from '../global.constant';
import { TeeTimeCancellationNoShowFeeUtilities } from 'src/app/tee-time-actions/noshow-cancellation-fees-tee-time-utilities';

@Injectable({ providedIn: 'root' })
export class HandleRetailEvents {
    constructor(private _golfSchedule: GolfScheduleCommunication, 
        public localization: RetailLocalization,private _rentalEventService:RentalEventService,private _teeTimeCancellationNoShowFeeUtilities:TeeTimeCancellationNoShowFeeUtilities) 
        { }

    /**
     * @description Updates the player depos
     * @param event RetailEventParameters<T>
     */
    async UpdateTeeTimePlayerDeposit(event: RetailEventParameters<DepositEventModel[]>) {
        let reqBody: Deposit[] = this.MapDepositEventData(event.data);
        await this._golfSchedule.putPromise({
            route: GolfApiRoute.UpdateTeeTimePlayerDeposit,
            body: reqBody
        });
    }

    private MapDepositEventData(event: DepositEventModel[]): Deposit[] {
        let deposits: Deposit[] = [];
        event.forEach(x => {
            deposits.push({
                amount: x.amount,
                playerId: x.typeId,
                depositTransactionId: x.depositTransactionId,
                refundTransactionId: x.refundTransactionId,
                status: x.status
            });
        })
        return deposits;
    }

    /**
     * @description This method will trigger an API which reverts the players transactions, deposits and Unredeems the rain checks
     * @param transactionId number
     * @param ticketNumber number
     */
    async VoidPlayerTransactions(param: RetailEventParameters<VoidEventModel>): Promise<boolean> {
        let transactionId: Number = param.data.transactionId, ticketNumber: string = param.data.ticketNumber;
        return await this._golfSchedule.putPromise<boolean>({
            route: GolfApiRoute.VoidPlayerTransactions,
            uriParams: { transactionId: transactionId, ticketNumber: Number(ticketNumber) }
        });
    }

    /**
     * @description This method will trigger an API which reverts the players transactions, deposits and Unredeems the rain checks
     * @param transactionId number
     * @param ticketNumber number
     */
    async CorrectPlayerTransactions(param: RetailEventParameters<TicketChangeEventModel>): Promise<boolean> {
        let transactionId: Number = param.data.oldTransactionId, ticketNumber: string = param.data.oldTicketNumber;
        return await this._golfSchedule.putPromise<boolean>({
            route: GolfApiRoute.VoidPlayerTransactions,
            uriParams: { transactionId: transactionId, ticketNumber: Number(ticketNumber) }
        });
    }

    /**
     * @description This method will trigger an API which reverts the players transactions, deposits and Unredeems the rain checks
     * @param transactionId number
     * @param ticketNumber number
     */
    async CancelPlayerTransactions(param: RetailEventParameters<VoidEventModel>): Promise<boolean> {
        let transactionId: Number = param.data.transactionId;
        return await this._golfSchedule.postPromise<boolean>({
            route: GolfApiRoute.CancelPlayerTransactions,
            uriParams: { transactionId: transactionId },
            body: param.data.lstTransactionDetailId
        });
    }

    /**
     * @description This method will trigger an API which Performs the SyncUp Transactions with POS     
     */
    async SyncUpTransactions(): Promise<boolean> {
        return await this._golfSchedule.putPromise<boolean>({
            route: GolfApiRoute.SyncUpTransactions
        });
    }

    async AddRetailItemToTeeTime(param: RetailEventParameters<TeeTimeLinkedRetailItemsData>) {
        let playerRetailItems: PlayerRetailItem[] = [];
        const playerLinkedRetailItems = param.data.playerRetailItemDetails;
        playerLinkedRetailItems[0].forEach(x => {
            let playerRetailItem: PlayerRetailItem = {
                id: 0,
                scheduledTeeTimePlayerId: x.groupId,
                retailItems: x.retailItems.length > 0 ? JSON.stringify(x.retailItems) : "",
                retailItemType: RetailItemType.RetailItemRetailPOSOnly,
                outletId : param.data.outletId
            }
            playerRetailItems.push(playerRetailItem);
        });
        playerLinkedRetailItems[1].forEach(x => {
            let playerRetailItem: PlayerRetailItem = {
                id: 0,
                scheduledTeeTimePlayerId: x.groupId,
                retailItems: x.retailItems.length > 0 ? JSON.stringify(x.retailItems) : "",
                retailItemType: RetailItemType.PMSAddOnsRentalItem,
                outletId : param.data.outletId
            }
            playerRetailItems.push(playerRetailItem);
        });
        if (!param.data.isEdit) {
            return await this._golfSchedule.postPromise<boolean>({
                route: GolfApiRoute.CreateRetailItemsForPlayer,
                body: playerRetailItems
            });
        }
        else {
            return await this._golfSchedule.putPromise<boolean>({
                route: GolfApiRoute.UpdateRetailItemsForPlayers,
                body: playerRetailItems
            });
        }

    }
    async TeeTimeCancellation(params: RetailEventParameters<OrderSummaryComponenet>, fromAction: FromAction = FromAction.None) 
    {
        let comScope = params.data;
        let scheduledTeeTimePlayerId = params.data._ss.SelectedPlayers[0].id;
        let cancellationNoShowLedger = this._teeTimeCancellationNoShowFeeUtilities.CancellationNoShowLedger(comScope,FromAction.Cancellation);
        let playerIds = (fromAction == FromAction.Cancellation || fromAction == FromAction.NoShow) ? comScope?.playerIds : [comScope?.tempSelectedProducts[0].payeeId];  
        if(playerIds && playerIds.length > 0)
        {
            let cancelPlayers = this._teeTimeCancellationNoShowFeeUtilities.FormCancelPlayers(playerIds,cancellationNoShowLedger);
            cancelPlayers.applyCancellationCharges = true;
            await this._golfSchedule.putPromise<any>(
            {
                route: GolfApiRoute.BulkCancelTeeTimePlayers,
                body: cancelPlayers
            }).then(async response => 
                {
                    if(response) 
                    {
                        await this._rentalEventService.deleteRentalItems([scheduledTeeTimePlayerId]);
                    }
                });
        }
    }

    async TeeTimeNoShow(eventParams: RetailEventParameters<OrderSummaryComponenet>, fromAction: FromAction = FromAction.None, scheduledTeeTimePlayerId: number[])
    {
        let playerIds = (fromAction == FromAction.Cancellation || fromAction == FromAction.NoShow) ? eventParams.data?.playerIds : [eventParams.data?.tempSelectedProducts[0].payeeId];  
        let scheduledTeeTimePlayerIds = fromAction == FromAction.PendingSettlement ? scheduledTeeTimePlayerId : [eventParams.data._ss.SelectedPlayers[0].scheduledTeeTimePlayerId];
        let cancellationNoShowLedger = this._teeTimeCancellationNoShowFeeUtilities.CancellationNoShowLedger(eventParams.data, FromAction.NoShow);
        let cancelPlayers = this._teeTimeCancellationNoShowFeeUtilities.FormCancelPlayers(playerIds, cancellationNoShowLedger);
        await this._rentalEventService.RemoveRentalItems(scheduledTeeTimePlayerIds,cancelPlayers,FromAction.RetailTransaction);
    }
    async TeeTimePendingSettlement(eventParams: RetailEventParameters<UpdateCancellationNoShowChargeEventModel>)
    {
        let cancelPlayers: CancelPlayers;
        let cancellationNoShowLedger: CancellationNoShowLedger;
        let retailEventParams: RetailEventParameters<OrderSummaryComponenet> = {} as RetailEventParameters<OrderSummaryComponenet>;
        let compScope = eventParams.data.orderSummaryComponentScope;
        let cancellationNoShowProduct = eventParams.data.selecetedProducts && eventParams.data.selecetedProducts.length > 0 ? eventParams.data.selecetedProducts : eventParams.data.orderSummaryComponentScope._ss.selectedProducts;
        let noShowItem = cancellationNoShowProduct.find(o => o.ItemType == RetailItemType.NoShowFee);
        let cancellationItem = cancellationNoShowProduct.find(o => o.ItemType == RetailItemType.CancellationFee);
        let playerId = noShowItem ? [noShowItem.sourceTypeId] : [cancellationItem.sourceTypeId];
        
        let scheduledTeeTimePlayerId;
        let scheduledTeeTimePlayers = await this._golfSchedule.putPromise<any[]>({
            route: GolfApiRoute.GetScheduledTeeTimePlayerByPlayerIds,
            body: playerId
        });

        retailEventParams.data = compScope;
        if(scheduledTeeTimePlayers?.length > 0)
        {
            scheduledTeeTimePlayerId = [scheduledTeeTimePlayers.filter(o => o.playerId == eventParams.data.orderSummaryComponentScope.tempSelectedProducts[0].sourceTypeId)[0].id];
        }

        if(noShowItem) {
            cancellationNoShowLedger = this._teeTimeCancellationNoShowFeeUtilities.CancellationNoShowLedger(compScope, FromAction.NoShow);
            cancelPlayers = this._teeTimeCancellationNoShowFeeUtilities.FormCancelPlayers(compScope.playerIds, cancellationNoShowLedger);
            this.TeeTimeNoShow(retailEventParams, FromAction.PendingSettlement,scheduledTeeTimePlayerId);
            return;
        }
        else if(cancellationItem) {
            cancellationNoShowLedger = this._teeTimeCancellationNoShowFeeUtilities.CancellationNoShowLedger(compScope, FromAction.Cancellation);
            cancelPlayers = this._teeTimeCancellationNoShowFeeUtilities.FormCancelPlayers(compScope.playerIds, cancellationNoShowLedger);
            this.TeeTimeCancellation(retailEventParams, FromAction.PendingSettlement);
            return;
        }
    }
}