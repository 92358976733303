import { Component, OnInit, Inject, ViewChild, AfterViewInit, ChangeDetectorRef, Renderer2, ViewEncapsulation, OnDestroy, Output, EventEmitter, HostListener } from '@angular/core';
import { TabCaption, TeeTimeTabs, TeeTimeModel, PlayerStayDetail, ScheduledTeeTimePlayerFee, OverrideType, PlayerDetail, PlayerNameDetail,TeeSlotNavigation, OverrideDetails } from './tee-time.model';
import { TeeTimeService } from './tee-time.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTabGroup, MatTabHeader, MatTab } from '@angular/material/tabs';
import { SaveButtonObj, AlertAction, AlertType, ButtonType, GolfPMSSessionInfo } from 'src/app/shared/shared-models';
import { TeeTimeBusiness } from './tee-time.business';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { TeeSheetGridContent, TeeTimeFormat, ScheduledPlayer, PlayerDaysOutResut, RateTypeDaysOutResult } from 'src/app/shared/models/teesheet.form.models';
import { UserModel } from 'src/app/shared/models/tenant.models';
import { UserDataService } from 'src/app/shared/data-services/tenantmanagement/user.data.service';
import { IssueRainCheckService } from 'src/app/shared/data-services/golfschedule/issueRainCheck.data.service';
import { SettingsDataService } from 'src/app/shared/data-services/golfschedule/settings.data.service';
import {CacheConstants, playerTypes, SettingScreen} from 'src/app/shared/global.constant';
import { LessonPlayer } from 'src/app/shared/models/lessons.modal';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import { CustomFieldDataService } from 'src/app/shared/data-services/golfmanagement/customfield.data.service';
import { BookTeeTimesRuleResult } from 'src/app/shared/models/teesheet.api.models';
import { TempHoldStatus, ButtonAction, RuleCodes } from 'src/app/shared/global.constant';
import { TeeTimesActionBusiness } from 'src/app/tee-time/shared/tee-action.business';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { SendNotificationMailSmsComponent } from 'src/app/shared/components/send-notification-mail-sms/send-notification-mail-sms.component';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { API } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { NotificationConfigurationService } from 'src/app/common/templates/notification-configuration/notification-configuration.service';
import { EventNotificationGroup, EventNotification } from 'src/app/common/templates/notification-configuration/notification-configuration.model';
import { DefaultsSettingConfig } from 'src/app/shared/models/default-settings.models';
import { RateType } from 'src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal';
import { RateSetupData } from './player-details/player-info.model';
import { RateSetupDataService } from 'src/app/shared/data-services/golfschedule/rate-setup.data.service';
import _ from 'lodash';
import { TeeTimesActionService } from 'src/app/shared/data-services/golfschedule/teeTimesAction.data.service';
import { ActivatedRoute,Router } from '@angular/router';
import { ScheduledTeeTimeTimePlayer } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { ContactService } from 'src/app/tee-time/tournaments/tournaments-modal/contact/contact.service';
import { PlayersBusiness } from 'src/app/guest/players/players.business';
import { SharedService } from 'src/app/shared/shared.service';
import { GuestPlayersService } from 'src/app/guest/players/players.service';
import { PlayerProfileDataService } from 'src/app/shared/data-services/golfmanagement/PlayerProfile.data.services';
import { TeeTimeDataService } from 'src/app/shared/data-services/golfschedule/TeeTime.data.service';

import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { TeeTimeAllocationBlock } from 'src/app/settings/golf-setup/golf-tee-time-allocation-block/golf-tee-time-allocation-block.model';
import { GolfTeeTimeAllocationBlockService } from 'src/app/settings/golf-setup/golf-tee-time-allocation-block/golf-tee-time-allocation-block.service';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { allocationBlockPlayerTypePermission } from 'src/app/settings/user-setup/booking-permission/booking-permission.model';
import { AllocationBlockWithPlayerType, PlayerType } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import { UserAccessModel } from 'src/app/common/dataservices/authentication/useraccess-model.model';
import { TeeSheetCommonBusiness } from 'src/app/tee-time/tee-sheet/tee-sheet.business';
import { PlayerTypeService } from 'src/app/shared/data-services/golfmanagement/playertype.data.service';
import { LessonBookingDataService } from 'src/app/shared/data-services/golfschedule/lesson-booking.data.service';
import { PlayerPaymentstatus } from 'src/app/common/shared/retail.modals';
import { RetailBussinessService } from 'src/app/shared/retail.bussiness';
import { PaymentModalComponent } from '../payment-modal/payment-modal.component';
import { CommonPopupComponent } from 'src/app/shared/components/common-popup/common-popup.component';
import { SelectOutletPopupComponent } from 'src/app/shared/components/select-outlet-popup/select-outlet-popup.component';
import { TeeTimeActions } from '../tee-time-action.base';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { TeeSheetSharedBusiness } from '../../tee-time/shared/teesheet.shared.business';
import { CartUtilities } from 'src/app/common/components/menu/vcart/cart.utilities';
import { IcartDataService } from 'src/app/common/dataservices/icart.data.service';
import { DefaultGUID } from 'src/app/common/shared/shared/globalsContant';
import { TeeTimeAllocationBlockEvent } from 'src/app/tee-time/search/search-model';
import { SnackBarType } from 'src/app/common/shared/shared/enums/enums';
import { CreatePlayerService } from 'src/app/shared/create-player/create-player.service';
import { CreatePlayerBusiness } from 'src/app/shared/create-player/create-player.business';


@Component({
  selector: 'app-tee-time',
  templateUrl: './tee-time.component.html',
  styleUrls: ['./tee-time.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TeeTimeService, TeeTimeBusiness, TeeTimesActionBusiness, UserDataService, IssueRainCheckService, SettingsDataService, CustomFieldDataService, NotificationConfigurationService
    , TeeTimesActionService,
    CreatePlayerBusiness, CreatePlayerService, ContactService, PlayersBusiness, SharedService, GuestPlayersService, PlayerProfileDataService, TeeTimeDataService, GolfTeeTimeAllocationBlockService
    , PlayerTypeService, LessonBookingDataService, TeeSheetCommonBusiness]
})
export class TeeTimeComponent implements OnInit, AfterViewInit, OnDestroy {
  captions: TabCaption;
  payCaption: any;
  buttonObj: SaveButtonObj;
  slideInput: TeeSheetGridContent;
  lessonPlayerDetail: LessonPlayer;
  cancellationNoShowPolicies: any;
  TeeTimeFormGroup: UntypedFormGroup;
  teeTimeInfo: TeeTimeModel;
  teeTimeData: Boolean=false;
  utilizedCustomFields: any;
  selectedIndex = 0;
  isNextSlotAvailable: boolean=true;
  isPreviousSlotAvailable: boolean=true;
  slotDetails:any;
  isViewOnly = false;
  disableSaveOnly = false;
  Tabs = TeeTimeTabs;
  Users: UserModel[];
  hasUserAccess: boolean[] = [true, true, true, true, true];
  hasEditUserAccess: boolean[] = [false, false, false, false, false];
  isEdit: boolean;
  isBulkEdit: boolean;
  IsbulkEditPopupOpened: boolean = false;
  isTeeTimeDetail: boolean;
  popUpComponentDetails: { componentName: any; popUpDetails: { isStepper: boolean; eventName: string; bindData: string; }; };
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  bookingId: string = '';
  emailConfigurationSetting: API.Settings<API.EmailConfigurationSettingConfig>;
  isEmbedded: boolean = false;
  SPLITCHAR: string = '~^>';
  title = '';
  isEditCart = false;
  vcartObj: { isVcartEdit: boolean } = null;
  @ViewChild('tabGroup') tabs: MatTabGroup;
  @ViewChild('infoElement') infoEle: any;
  isSaveClose: boolean = false;
  bDirtyPlayerDetails: boolean = false;
  eventNotificationGroup: EventNotificationGroup[] = [];
  isPartialEdit = false;
  defaultSettings: DefaultsSettingConfig;
  rateTypes: RateType[];
  rateSetup: RateSetupData[];
  playerStayDetail: PlayerStayDetail;
  scheduledTeeTimePlayer: ScheduledTeeTimeTimePlayer;
  isMixedPlayersSlot: boolean = false;
  playersLoadedInPlayerCard: boolean = false;
  slideInputLoaded: boolean =false;
  bulkEditUserAccess: UserAccessModel.BreakPointResult;
  normalBookUserAccess: UserAccessModel.BreakPointResult;
  bulkPlayers: ScheduledPlayer[];
  quickIdPopUpEnable: boolean = false;
  isAnyOnePlayerDetailModified:boolean = false;
  teeTimeAllocationBlock: TeeTimeAllocationBlock;
  @Output() emitAllocationChange = new EventEmitter();
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  allocationcodeRolePermission: allocationBlockPlayerTypePermission[];
  allocation: any;
  allocationBlockPlayerTypes: AllocationBlockWithPlayerType[] = [];
  @ViewChild('playerDetailsTab', { static: true }) playerDetailsTab;
  overridePlayerTypeDaysoutUserAccess: UserAccessModel.BreakPointResult;
  overrideRateTypeDaysoutUserAccess: UserAccessModel.BreakPointResult;
  allPlayerTypes: PlayerType[];
  allRateTypes: RateType[];
  guestId = 0;
  fromTeeTime = true;
  teeTimePlayersGuestId: PlayerNameDetail[];
  enableItinerary = false;
  isRefundCheck = false;
  playerProfileBKAccess: UserAccessModel.BreakPointResult;
  tabAccessOnEdit: UserAccessModel.BreakPointResult[];
  isHitoricalOnEdit: boolean = false;
  defaultPlayerId: number = 0;
  bNotificationReturn: boolean = false;
  isEditFromGrid = false;
  slotChange =false;
  isFromTeeSheet: boolean=false;
  slotPlayerCount = 0;
  slotPosition: number;
  teeTimeSlotDetails: any;
  automationId:string="BookTeeTime";
  isPlayerCleared : boolean = false;
  showWaitlistPopup : boolean = false;
  overrideRateTypeForDynamicAvailability: boolean = false;
  rentalRetailItems = [];
  payIconEnable = true;
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData,
    private dialog: MatDialog,
    private rendrer: Renderer2,
    private _teeTimeBusiness: TeeTimeBusiness,
    private _teeTimesActionBusiness: TeeTimesActionBusiness,
    private dialogRef: MatDialogRef<TeeTimeComponent>,
    private _formBuilder: UntypedFormBuilder,
    private localization: GolfLocalization,
    private _utilities: GolfUtilities,
    private _NotificationConfigurationService: NotificationConfigurationService,
    public _dialog: MatDialog,
    private _Router: Router,
    private _rateSetupDataService: RateSetupDataService,
    private _TeeTimesActionService: TeeTimesActionService,
    private route: ActivatedRoute,
    private _teetimeService: TeeTimeService,
    private propertyInformation: RetailPropertyInformation,
    public quickLoginUtils: QuickLoginUtilities,
    private cdk: ChangeDetectorRef, public _retailSharedVariableService: RetailSharedVariableService,
    private _TeeSheetCommonBusiness: TeeSheetCommonBusiness, private _retailBusiness: RetailBussinessService,
    private _teeTimeSharedBusiness: TeeSheetSharedBusiness,
    private _cartUtilities: CartUtilities,
    private _icartDataService: IcartDataService
  ) {
    this.title = this.dialogData.title;
    this.isFromTeeSheet = this.dialogData.isFromTeeSheet;
    this.teeTimeSlotDetails = this.dialogData.teeTimeSlotDetails;
    this.slotPosition =this.teeTimeSlotDetails?.findIndex(x=>x.timeSlot==this.dialogData.componentDetails.popUpDetails.bindData[1].time && x.originalHoleNumber == this.dialogData.componentDetails.popUpDetails.bindData[1].originalHoleNumber );
    this.isPreviousSlotAvailable = this.teeTimeSlotDetails?.findIndex(x=>x.timeSlot==this.dialogData.componentDetails.popUpDetails.bindData[1].time && x.originalHoleNumber == this.dialogData.componentDetails.popUpDetails.bindData[1].originalHoleNumber )>0;
    console.log(this.teeTimeSlotDetails?.findIndex(x=>x.timeSlot==this.dialogData.componentDetails.popUpDetails.bindData[1].time && x.originalHoleNumber == this.dialogData.componentDetails.popUpDetails.bindData[1].originalHoleNumber));
    this.isNextSlotAvailable = this.teeTimeSlotDetails?.findIndex(x=>x.timeSlot==this.dialogData.componentDetails.popUpDetails.bindData[1].time && x.originalHoleNumber == this.dialogData.componentDetails.popUpDetails.bindData[1].originalHoleNumber)<this.teeTimeSlotDetails?.length-1;
    this.isBulkEdit = this.dialogData.isBulkEdit;
    this.isEditCart = this.dialogData && this.dialogData.isEditCart;
    if (this.isEditCart) {
      this.vcartObj = { isVcartEdit: this.isEditCart };
    }
    if(this.dialogData?.isEdit){
      this.automationId = "EditTeeTime"
    }
   }

  ngOnDestroy(): void {
    this._teetimeService.isBulkEdit = false;
    this._teetimeService.isBulkEditPopUpOpened = false;
    this._teetimeService.canEditCaddy = false;
    this.quickLoginUtils.resetQuickIdDetails();
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  async ngOnInit() {
    //this._utilities.ToggleLoader(true);
     this.triggerOnInit();
  }

  async triggerOnInit()
  {
    this.getPlayerProfileBreakPointAccess();
    console.log("Tee time opened")
    this.isEmbedded = this._cartUtilities.isEmbed();
    this.captions = this._teeTimeBusiness.GetCaptions();
    this.TeeTimeFormGroup = this._formBuilder.group({
      playerInformation: '',
      generalInformation: '',
      feeInformation: '',
      otherInformation: ''
    });
    this.setTitleForVcart();
    this.disableSave(true);
    this.isEdit = this.dialogData.isEdit;
    this.defaultPlayerId = this.dialogData.playerId;
    this.tabAccessOnEdit = this.dialogData.tabAccessOnEdit;
    this._teetimeService.isBulkEdit = this.dialogData.isBulkEdit;
    this.bulkEditUserAccess = this.dialogData.bulkEditUserAccess;
    this.normalBookUserAccess = this.dialogData.normalBookUserAccess;
    this.allocationcodeRolePermission = this.dialogData.allocationcodeRolePermission;
    this.overridePlayerTypeDaysoutUserAccess = this.dialogData.overridePlayerTypeDaysoutUserAccess;
    this.overrideRateTypeDaysoutUserAccess = this.dialogData.overrideRateTypeDaysoutUserAccess;
    this.allocation = this.dialogData.allocation;
    const isRefundPlayers = this.dialogData.componentDetails.popUpDetails.bindData[1].playerDetail.filter(x => ((x.playerStatus & PlayerPaymentstatus.refund) != 0) && this.isHistorical(this.dialogData.componentDetails.popUpDetails.bindData[1].time));
    if(this.isEdit){
      this.slotPlayerCount = this.dialogData.componentDetails.popUpDetails.bindData[1].playerDetail.length;
    }
    this.isRefundCheck = (isRefundPlayers.length > 0) ? true : false;
    this.slideInput = this._teeTimeBusiness.GetDataFromSlideInput(this.dialogData.componentDetails.popUpDetails);
    this.slideInput.isBulkEdit = this.isBulkEdit;
    this.isEditFromGrid = this.dialogData.isEditFromGrid ? this.dialogData.isEditFromGrid : false;
    const blockPlayers = this.slideInput.playerDetail.filter(x => !x.isBlocked);
    const isBulkBooking = (this.slideInput.playerDetail && blockPlayers && blockPlayers.length > 0) ? blockPlayers.map(x => x.teeTimeFormat).every(x => x == TeeTimeFormat.BulkTee) : false;
    const isNormalBooking = (this.slideInput.playerDetail && blockPlayers && blockPlayers.length > 0) ? blockPlayers.map(x => x.teeTimeFormat).every(x => x !== TeeTimeFormat.BulkTee) : false;
    const isBlockPlayers = this.slideInput.playerDetail.filter(x => x?.isBlocked);
    this.isHitoricalOnEdit = this.isHistorical(this.dialogData.componentDetails.popUpDetails.bindData[1]);
    if (isBlockPlayers && isBlockPlayers?.length === this._teetimeService.totalPlayerCount) this.disableSaveOnly = true;
    if (isBulkBooking || isNormalBooking || this.slideInput.playerDetail.length == 0) {
      var isEditGeneralAccess = this._teeTimesActionBusiness.ValidateGeneralInfoBreakPoint(UserAccessBreakPoints.EDITGENERALINFO, this.tabAccessOnEdit)
      isBulkBooking && this.slideInput.playerDetail.length > 0 ? this.validateUserAccess(UserAccessBreakPoints.BULKTEETIMESEDIT, isEditGeneralAccess) : this.normalBookUserAccess ? this.validateUserBreakpoint(UserAccessBreakPoints.BOOKTEETIME, this.normalBookUserAccess, isEditGeneralAccess) : this.validateUserAccess(UserAccessBreakPoints.BOOKTEETIME, isEditGeneralAccess);
    }
    else {
      this.isMixedPlayersSlot = true;
      this.validateMixedBreakPoints();
    }
    var teeTimeSetting = JSON.parse(sessionStorage.getItem('TEETIMESETTING'));
    this.teeTimeInfo = this._teeTimeBusiness.buildTeeTimeModelFromSlide(this.slideInput);
    if(teeTimeSetting && teeTimeSetting!=null && teeTimeSetting?.isNoShowCancellationPolicyConfigured)
        this.cancellationNoShowPolicies = await this._teeTimeBusiness.GetAllCancellationNoShowPolicyByDate(this.teeTimeInfo.scheduleDateTime);
    this.lessonPlayerDetail = this._teeTimeBusiness.GetLessonPlayerDetail(this.dialogData.componentDetails.popUpDetails);
    const courseName: string = this.dialogData.componentDetails.popUpDetails.bindData[1].course.course;
    this.isEdit = this.dialogData.title === `${this.captions.editTitle} - ${courseName}` || this.dialogData.title === `${this.captions.teeTimeDetail} - ${courseName}`;
    let quickIdConfig = this.propertyInformation.getQuickIdConfig;
    if (!this.isEdit && quickIdConfig && quickIdConfig.teeTimeBook) {
      this.quickIdPopUpEnable = true;
    }
    if (this.isEdit && quickIdConfig && quickIdConfig.teeTimeEdit) {
      this.quickIdPopUpEnable = true;
    }
    this.isTeeTimeDetail = this.dialogData.title === this.captions.teeTimeDetail;
    if (typeof this._utilities.getPMSSessionInfo() != "undefined") {
      this.scheduledTeeTimePlayer = _.cloneDeep(this.dialogData.scheduledTeeTimePlayer);
    }

    if (this.isEdit && !this.scheduledTeeTimePlayer) {
      await this.getData();
    }
    else if (this.isEdit && this.scheduledTeeTimePlayer) {
      await this.MapStayRateDetailsToPMSData().then(async res => {
          await this.getData(res);
        });
    }
    else if (!this.isEdit && this.scheduledTeeTimePlayer) {
      const pMSRequestInfo: GolfPMSSessionInfo = this._utilities.getPMSSessionInfo();
      this.isPartialEdit = true;
      await this.MapStayRateDetailsToPMSData(pMSRequestInfo.activityId).then(res => {
        if ((pMSRequestInfo.stayFromDate === undefined && pMSRequestInfo.stayToDate === undefined)
          || (res.playerDetail.playerStayDetail !== null))
          this.teeTimeInfo.scheduledTeeTimePlayers.push(res);
          this.teeTimeInfo = {...this.teeTimeInfo};
      });
      this.disableSave(true);
    } else if (this.dialogData.isEditCart && this.dialogData.cartEditDetail) {
      const teeTimeModel = JSON.parse(this.dialogData.cartEditDetail?.cartDetail?.cartPayload.bookAPI.payload) as TeeTimeModel
      await this.patchData(teeTimeModel);
    }
    if (this.isEdit) {
      if (!(isBulkBooking || isNormalBooking || this.slideInput.playerDetail.length == 0)) { this.isMixedPlayersSlot = true }
      {
          this.validateTabAccessandSetIndex();
      }
    }
    if (this.isTeeTimeDetail) {
      this.disableSave(true);
    }

    if (this.isEdit) {
      this.validateAllocationCodeUserPermission();
    }
    if (this.allocationcodeRolePermission && this.allocationcodeRolePermission.length > 0) {
      this.allocationBlockPlayerTypes = await this._teeTimeBusiness.GetAllocationsBlockWithPlayerTypes();
    }
    this.overridePlayerTypeDaysoutUserAccess = this.overridePlayerTypeDaysoutUserAccess ? this.overridePlayerTypeDaysoutUserAccess : await this._teeTimesActionBusiness.validateBreakPointAccess(UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT, false);
    this.overrideRateTypeDaysoutUserAccess = await this._teeTimesActionBusiness.validateBreakPointAccess(UserAccessBreakPoints.OVERRIDERATETYPEDAYSOUT, false);
    this.allPlayerTypes = await this._TeeSheetCommonBusiness.getAllPlayers();
    this.allRateTypes = await this._TeeSheetCommonBusiness.getAllRateTypes();
    this.validateRefundCheck();
    this._icartDataService.CartItinerarySelected.pipe(takeUntil(this.destroyed$)).subscribe(async x => {
      this.setTitleForVcart(x.confirmationId);
    });
   await this._teeTimesActionBusiness.validateBreakPointAccess(UserAccessBreakPoints.OVERRIDE_UNAVAILABLE_RATETYPE_PER_DYNAMICAVAILABILITY, false).then(x => {
    this.overrideRateTypeForDynamicAvailability = x.isAllow;
   });
   console.log(this.overrideRateTypeForDynamicAvailability);
  }
  validateTabAccessandSetIndex() {
    if (this.tabAccessOnEdit) {
      for (const [propertyKey, propertyValue] of Object.entries(TeeTimeTabs)) {
        let setIndex: boolean = true;
        if (!Number.isNaN(Number(propertyKey))) {
          continue;
        }
        let tabAccess = this._teeTimesActionBusiness.validateBreakPointForTabEdit(this.isBulkEdit, this.isMixedPlayersSlot, this.bulkEditUserAccess, this.normalBookUserAccess, this.tabAccessOnEdit, propertyValue as TeeTimeTabs);
        this.hasEditUserAccess[propertyValue] = tabAccess.isAllow;
        if (setIndex && !this.isMixedPlayersSlot && !this.isBulkEdit && (this.normalBookUserAccess.isAllow || this.normalBookUserAccess.isViewOnly)) {
          this.selectedIndex = Number(propertyValue);
          setIndex = false;
        }
        if (setIndex && !this.isMixedPlayersSlot && this.isBulkEdit && (this.bulkEditUserAccess.isAllow || this.bulkEditUserAccess.isViewOnly)) {
          this.selectedIndex = Number(propertyValue);
          setIndex = false;
        }
        if (setIndex && this.isMixedPlayersSlot && (this.bulkEditUserAccess.isAllow || this.bulkEditUserAccess.isViewOnly || this.normalBookUserAccess.isAllow || this.normalBookUserAccess.isViewOnly)) {
          this.selectedIndex = Number(propertyValue);
          setIndex = false;
        }
        if (setIndex && tabAccess && tabAccess.breakPointNumber && (tabAccess.isAllow || tabAccess.isViewOnly)) {
          this.selectedIndex = Number(propertyValue);
          //this.disableSaveOnly = true;          
          this.disableSaveOnly = (!this.isMixedPlayersSlot && this.bulkEditUserAccess && !this.bulkEditUserAccess.isAllow) || (!this.isMixedPlayersSlot && this.normalBookUserAccess && !this.normalBookUserAccess.isAllow) || (this.isMixedPlayersSlot && ((this.normalBookUserAccess && !this.normalBookUserAccess.isAllow) && (this.bulkEditUserAccess && !this.bulkEditUserAccess.isAllow))) ? true : false;
          setIndex = false;
        }
        if (!setIndex) break;
      }
    }
  }
  setTitleForVcart(cartConfirmationId: string = null) {
    if (this.isEmbedded) {
      if (cartConfirmationId)
        this.title = `${this.dialogData.title} - ${cartConfirmationId}`;
      else {
        var cartItinerary = this._cartUtilities.getActiveCartItinerary();
        if (cartItinerary) {
          this.title = `${this.dialogData.title} - ${cartItinerary.confirmationId}`;
        }
      }
    }
  }
  disableSave(flag: boolean) {
    if (this.playerDetailsTab.playerAddedOrUpdated && this.playerDetailsTab.playerListInfo.length > 0) {
      flag = false;
    }
    this.buttonObj = {
      isEdit:  this.isEdit,
      disabled: this.isTeeTimeDetail ? this.isTeeTimeDetail && this.selectedIndex === this.Tabs.playerInformation : flag ? flag : (this.isHitoricalOnEdit ? true : false),
      showAddToCartButton: this._cartUtilities.isEmbed() && this.slotPlayerCount < 4  
    };
    let originalPlayerCount = this.slideInput?.playerDetail?.filter(x => x?.isBlocked)?.length;
    let updatedPlayerCount = this.teeTimeInfo?.scheduledTeeTimePlayers?.filter(x => x?.isBlocked)?.length;
    if (updatedPlayerCount === this._teetimeService.totalPlayerCount) this.disableSaveOnly = true;
    else if ((originalPlayerCount === this._teetimeService?.totalPlayerCount) && (originalPlayerCount != updatedPlayerCount)) {
      this.disableSaveOnly = false;
    }
    setTimeout(() => this.cdk.detectChanges(), 1);
  }

  isHistorical(data) {
    var propertyCurrentDateTime = this._utilities.GetDateWithoutTime(this.propertyInformation.CurrentDate),
      teeRowDataDateTime = this._utilities.GetDateWithoutTime(this.localization.getDate(data.time));

    return propertyCurrentDateTime.getTime() <= teeRowDataDateTime.getTime() ? false : true;
  }
  disableSaveFromTeesheet(flag: boolean) {

    this.buttonObj = {
      isEdit: this.isEdit,
      disabled: this.isTeeTimeDetail ? this.isTeeTimeDetail && this.selectedIndex === this.Tabs.playerInformation : flag ? flag : (this.isHitoricalOnEdit ? true : false),
      showAddToCartButton: this._cartUtilities.isEmbed() && this.slotPlayerCount < 4
    };
    setTimeout(() => this.cdk.detectChanges(), 1);
  }

  async decreaseSlot(data){
    const bReturn = await this.showAlertToUpdatePlayer(this.bDirtyPlayerDetails);
    if (!bReturn) {
      this.getSlotDetails(TeeSlotNavigation.IsPrevious);
    };
  }

  async increaseSlot(data)
  {
    const bReturn = await this.showAlertToUpdatePlayer(this.bDirtyPlayerDetails);
    if (!bReturn) {
      this.getSlotDetails(TeeSlotNavigation.IsNext);
    };
  }


  async getSlotDetails(slotNavigation:TeeSlotNavigation)
  {
    this.playersLoadedInPlayerCard = false;
    this.slideInputLoaded =false;
    this._utilities.ToggleLoader(true);
    console.log(this.teeTimeInfo);
    this.slotPosition =this.teeTimeSlotDetails?.findIndex(x=> this.localization.convertDateTimeToAPIDateTime(new Date(x.timeSlot)) ==this.localization.convertDateTimeToAPIDateTime(new Date(this.teeTimeInfo.scheduleDateTime))
                                                      && this.teeTimeInfo.originalHoleNumber === x.originalHoleNumber);
    this.slotDetails =await this._teeTimeBusiness.GetSlotPlayerDetails(this.localization.convertDateTimeToAPIDateTime(new Date(this.teeTimeInfo.scheduleDateTime)),this.teeTimeSlotDetails[this.slotPosition].originalHoleNumber,this.teeTimeInfo.courseId,slotNavigation);
    console.log(this.slotDetails);
    this._Router = this.dialogData.teeSheetCallBack;
    this._utilities.ToggleLoader(false);
    await this._teeTimeSharedBusiness.bookTeeSheet([this.slotDetails.scheduledTeeTimeId!=0?this.captions.editTitle:this.captions.bookTeeTime,this.slotDetails],this._Router, undefined,undefined,undefined,undefined,true,undefined,true,this.teeTimeSlotDetails).then((result) =>{  
      if(result)
      {
        this.isPreviousSlotAvailable = this.slotDetails.isPreviousSlotAvailable;
        this.isNextSlotAvailable = this.slotDetails.isNextSlotAvailable;
        this._teeTimesActionBusiness.tempHoldTeeTime(this.teeTimeInfo, TempHoldStatus.release);  
        this.title = this.slotDetails.scheduledTeeTimeId!=0?this.captions.editTitle+ " - "+ this.slotDetails.course.course:this.captions.bookTeeTime+ " - "+ this.slotDetails.course.course; 
        this.dialogData = result;
        this.slotChange = true;
        this.triggerOnInit().then(x=>
          {
            this._utilities.ToggleLoader(true);
          // this.isEdit = slotDetails.scheduledTeeTimeId!=0?true:false;
           this.isNextSlotAvailable =this.slotDetails.isNextSlotAvailable;
           this.isPreviousSlotAvailable = this.slotDetails.isPreviousSlotAvailable;
          if(this.slotDetails.isSlotsSkipped)
          {
            this._utilities.showToastMessage(this.localization.captions.teetime.lbl_Slots_Skipped_Warning, SnackBarType.Success, 3000);
          }
          this.teeTimeInfo = this._teeTimeBusiness.MapTeeTimeInfo(this.slotDetails);
          this.teeTimeInfo = {...this.teeTimeInfo}; 
          this.teeTimeData = true;
          this.teeTimeData = {...this.teeTimeData}; 
          // this.teeTimePlayersGuestId = this.MapPlayertoPlayerNameDetail(this.teeTimeInfo.scheduledTeeTimePlayers.filter(x => (x.playerDetail.playerCategoryId ==  playerTypes.hotelReservation && x.playerDetail.playerLinkId!=null ) || x.playerDetail.playerCategoryId == playerTypes.player || (x.playerDetail.playerCategoryId == playerTypes.newPlayer && x.playerDetail.activityId != DefaultGUID)));
          // this.teeTimePlayersGuestId = this.teeTimePlayersGuestId.length>0?this.teeTimePlayersGuestId.distinct(c => c.playerLinkId):[];
          // this.teeTimeData = slotDetails.unmodifiedSlotData as TeeSheetGridContent;
          // this.teeTimeData = {...this.teeTimeData};  
          });    
        }   
      });  
    }

  async validateAllocationCodeUserPermission() {
    if (this._utilities.IsAllocationCodePermissionEnabled() && this.slideInput.allocation && this.slideInput.allocation.id) {
      const allocationPermission = this.allocationcodeRolePermission.filter(x => x.allocationBlockId == this.slideInput.allocation.id);
      if (allocationPermission.length == 0) {
        this._utilities.showAllocationCodePermissionDeniedPopup(this.slideInput.allocation.name);
        this.isViewOnly = true;
      }
    }
  }

  async getMasterData(): Promise<boolean> {
    this.defaultSettings = JSON.parse(sessionStorage.getItem('defaultSettings'));
    this.rateSetup = await this._rateSetupDataService.getTeeFeesForCourseAndDate(this.slideInput.course.id, this.localization.getDate(this.slideInput.time));
    return true;
  }

  async patchData(teeTimeData: TeeTimeModel) {
    this.teeTimeInfo = teeTimeData;
    this.teeTimeInfo = {...teeTimeData};
    this.Users = await this._teeTimeBusiness.GetAllUsers();   
    this.cdk.detectChanges();
      this.UpdatePlayerPosition(this.teeTimeInfo.scheduledTeeTimePlayers);
      const disabled = !(this.teeTimeInfo.scheduledTeeTimePlayers && this.teeTimeInfo.scheduledTeeTimePlayers.length > 0);
      if (this.Users) {
        this.teeTimeInfo.scheduledTeeTimePlayers.forEach(x => {
          const updatedUser = this.Users.find(user => x.lastUpdatedBy && user.userId === x.lastUpdatedBy);
          const createdUser = this.Users.find(user => x.createdBy && user.userId === x.createdBy);
          x.lastUpdatedByName = updatedUser && (this._utilities.formatGuestName(updatedUser.firstName, updatedUser.lastName));
          x.createdByName = createdUser && this._utilities.formatGuestName(createdUser.firstName, createdUser.lastName);
        });
  
    if (this.teeTimeInfo && this.teeTimeInfo.scheduledTeeTimePlayers && this.teeTimeInfo.scheduledTeeTimePlayers.length > 0) {
      let bulktee = this.teeTimeInfo.scheduledTeeTimePlayers.filter(x => x.gameFormat == TeeTimeFormat.BulkTee);
      if (bulktee && bulktee.length > 0) {
        this.bulkPlayers = await this._teeTimeBusiness.getBulkPlayers(bulktee[0].bookingId);
      }
    }
    this.enableItinerary = this.teeTimeInfo.scheduledTeeTimePlayers.length > 0 ? true : false;
    this.teeTimePlayersGuestId = this.MapPlayertoPlayerNameDetail(this.teeTimeInfo.scheduledTeeTimePlayers.filter(x => (x.playerDetail.playerCategoryId == playerTypes.hotelReservation && x.playerDetail.playerLinkId!=null ) || x.playerDetail.playerCategoryId == playerTypes.player));
    this.teeTimePlayersGuestId = this.teeTimePlayersGuestId.distinct(c => c.playerLinkId);
  }
 }

 async getData(PMSData?: ScheduledTeeTimeTimePlayer) {
  const pMSRequestInfo: GolfPMSSessionInfo = this._utilities.getPMSSessionInfo();
  await this._teeTimeBusiness.GetTeeTimeByScheduleId(this.slideInput.teeTimeId).then(async result => {
    if(result){
      if (PMSData && result.scheduledTeeTimePlayers && result.scheduledTeeTimePlayers.length < 4) {
        PMSData.playerSlotPosition = result.scheduledTeeTimePlayers.length + 1;
        if ((pMSRequestInfo.stayFromDate === undefined && pMSRequestInfo.stayToDate === undefined)
          || (PMSData.playerDetail.playerStayDetail !== null)) {
          result.scheduledTeeTimePlayers.push(PMSData);
        }
      }
        result.scheduledTeeTimePlayers = result.scheduledTeeTimePlayers?.sort((left, right) => left.playerSlotPosition - right.playerSlotPosition);
      await this.patchData(result);
    }
  });

}

  private MapPlayertoPlayerNameDetail(players: ScheduledTeeTimeTimePlayer[]): PlayerNameDetail[] {
    return players.map((player) => {
      return {
        id: player.playerDetail.id,
        playerName: this._utilities.formatGuestName(player.playerDetail.firstName, player.playerDetail.lastName),
        playerLinkId: player.playerDetail.playerLinkId
      }
    }) as PlayerNameDetail[];

  }

  private UpdatePlayerPosition(scheduledTeeTimePlayers) {
    if (scheduledTeeTimePlayers && scheduledTeeTimePlayers.length) {
      scheduledTeeTimePlayers.map(x => {
        x.playerPosition = x.playerSlotPosition;
        let playerDetailsFromSliders = this.slideInput && this.slideInput.playerDetail;
        if (playerDetailsFromSliders) {
          let playerDetail = playerDetailsFromSliders.find(y => y.scheduledTeeTimePlayerFee && y.scheduledTeeTimePlayerFee.scheduledTeeTimePlayerId == x.id);
          x.playerPosition = playerDetail && playerDetail.playGroup ? playerDetail.playGroup.listOrder :
            playerDetail ? playerDetail.playPos : x.playerPosition;
        }
      });
    }
  }

  async MapStayRateDetailsToPMSData(activityId:string = ""): Promise<ScheduledTeeTimeTimePlayer> {
    let greenFee: number = 0;
    let cartFee: number = 0;
    this.disableSave(false);
    let result = await this.getMasterData();
    let rateTypeId = this.rateSetup && this.rateSetup.length > 0 && this.defaultSettings && this.rateSetup.find(x => x.rateTypeId == this.defaultSettings.resortRateType);
    if (rateTypeId) {
      greenFee = (rateTypeId ? rateTypeId.greenFee : 0);
      cartFee = rateTypeId ? rateTypeId.cartFee : 0;
    }
    this.scheduledTeeTimePlayer.scheduledTeeTimePlayerFee.allocationBlockId = this.slideInput.allocation ? this.slideInput.allocation.id : 0;
    this.scheduledTeeTimePlayer.scheduledTeeTimePlayerFee.playerTypeId = this.defaultSettings.resortPlayerType;
    this.scheduledTeeTimePlayer.scheduledTeeTimePlayerFee.rateTypeId = this.defaultSettings.resortRateType;
    this.scheduledTeeTimePlayer.scheduledTeeTimePlayerFee.greenFee = this.localization.currencyToSQLFormat(greenFee.toString());
    this.scheduledTeeTimePlayer.scheduledTeeTimePlayerFee.cartFee = this.localization.currencyToSQLFormat(cartFee.toString());
    this.scheduledTeeTimePlayer.scheduledTeeTimePlayerFee.isGreenFeeNegotiable = (rateTypeId) ? false : true;
    this.scheduledTeeTimePlayer.scheduledTeeTimePlayerFee.isCartFeeNegotiable = (rateTypeId) ? false : true;
    this.playerStayDetail = await this._TeeTimesActionService.GetPlayerStayDetail(this.scheduledTeeTimePlayer.playerDetail.playerLinkId, this.localization.ConvertDateToISODateTime(this.localization.getDate(this.slideInput.time)), "", activityId);
    this.scheduledTeeTimePlayer.playerDetail.playerStayDetail = this.playerStayDetail;
    this.scheduledTeeTimePlayer.packageCode = this.playerStayDetail ? this.playerStayDetail.packageName : null;
    return this.scheduledTeeTimePlayer;
  }

  ngAfterViewInit() {
    this.tabs._handleClick = this.interceptTabChange.bind(this);
    this.updateUserAccessInfo();
    if (this.isEdit) {
      this.TeeTimeFormGroup.controls.playerInformation['controls']?.allocationCode.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
        if (this.TeeTimeFormGroup.controls.playerInformation.value.allocationCode && this.TeeTimeFormGroup.controls.playerInformation.value.startHole) {
          if (this.TeeTimeFormGroup.controls.playerInformation.dirty) {
            this.disableSave(false);
          } else {
            this.disableSave(true);
          }
        }
      });
      this.TeeTimeFormGroup.controls.playerInformation['controls']?.startHole.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
        if (this.TeeTimeFormGroup.controls.playerInformation.value.startHole && this.TeeTimeFormGroup.controls.playerInformation.value.allocationCode) {
          if (this.TeeTimeFormGroup.controls.playerInformation.dirty) {
            this.disableSave(false);
          } else {
            this.disableSave(true);
          }
        }
      });
    }
  }

  // Event Handlers
  teeTimeInfoChange(arg: TeeTimeModel) {
    this.isAnyOnePlayerDetailModified = arg.isModified;
    if (this.isAnyOnePlayerDetailModified != undefined && this.isAnyOnePlayerDetailModified == true && arg.scheduledTeeTimePlayers && arg.scheduledTeeTimePlayers.length > 0) {
      let isDisableSave = this.TeeTimeFormGroup.controls.playerInformation['controls']?.allocationCode?.valid && this.TeeTimeFormGroup.controls.playerInformation['controls']?.startHole?.valid ? false : true;
      //isDisableSave = (!this.isMixedPlayersSlot && this.bulkEditUserAccess && !this.bulkEditUserAccess.isAllow) || (!this.isMixedPlayersSlot && this.normalBookUserAccess && !this.normalBookUserAccess.isAllow) || (this.isMixedPlayersSlot && ((this.normalBookUserAccess && !this.normalBookUserAccess.isAllow) && (this.bulkEditUserAccess && !this.bulkEditUserAccess.isAllow))) ? true : isDisableSave;
      this.disableSave(isDisableSave);
    }
  }
  async closeHandler(arg) {
    if (this.teeTimeAllocationBlock && this.teeTimeAllocationBlock != null) {
      this.teeTimeAllocationBlock.isDefault = false;
      this.teeTimeAllocationBlock.holeNumber = this.slideInput.hole;
      this.teeTimeAllocationBlock.viewedByStarter = this.slideInput.viewedByStarter;
      // await this.saveTeeTimeAllocationBlock(this.teeTimeAllocationBlock);
    }
    this.isSaveClose = true;
    this.closePopUp();
  }
  async cancelHandler(arg) {
    this.isSaveClose = true;
    this.closePopUp();
  }
  async saveCloseHandler(arg) {
    this.isSaveClose = true;
    this.saveHandler(arg, true, false);
  }

  async showAlertToUpdatePlayer(isDirty): Promise<boolean> {
    let bReturn: boolean;
    let resultOfBP = await this._teeTimesActionBusiness.validateBreakPointAccess(UserAccessBreakPoints.BOOKTEETIME, false);
    if (this.selectedIndex == 1 && !resultOfBP.isAllow)
      return false;
    if (this.bDirtyPlayerDetails && this.TeeTimeFormGroup.get('playerInformation').dirty && this.TeeTimeFormGroup.get('playerInformation').touched) {
      let message = this.localization.captions.teetime.updateNotClicked;
      var res = await this._utilities.showAlert(message, AlertType.Info, ButtonType.YesNo, (res) => {
        if (res === AlertAction.NO) {
          bReturn = true;
        }
      });
      await res.afterClosed().toPromise();
    }
    else { bReturn = false };
    return bReturn;
  }


  async SaveFromPackageEntitlement(addToCart) {

    var teeTimeSetting = JSON.parse(sessionStorage.getItem('TEETIMESETTING'));
    this.bNotificationReturn = false;
    console.log(this.TeeTimeFormGroup.getRawValue());
    let playerType = this.getPlayerType(this.teeTimeInfo);
    let rateType = this.getRateType(this.teeTimeInfo);
    if (playerType || rateType) {
      let canOverrideDaysout = await this._TeeSheetCommonBusiness.checkOverridePlayerTypeRateTypeDaysOut(playerType, rateType, this.overridePlayerTypeDaysoutUserAccess, this.overrideRateTypeDaysoutUserAccess, this.teeTimeInfo.scheduleDateTime);
      if (canOverrideDaysout) {
        this.teeTimeInfo.scheduledTeeTimePlayers.forEach(element => {
          let overrideDetails: any[] = [];
          if (element.isPlayerDaysOutFailed) {
            overrideDetails.push({
              id: 0,
              overrideType: OverrideType.playerType,
              scheduledTeeTimePlayerId: 0,
              userId: this._TeeSheetCommonBusiness.overRideDetails.playerTypeDaysOutOverrideduserID
            });
          }
          if (element.isRateDaysOutFailed) {
            overrideDetails.push({
              id: 0,
              overrideType: OverrideType.rateType,
              scheduledTeeTimePlayerId: 0,
              userId: this._TeeSheetCommonBusiness.overRideDetails.rateTypeDaysOutOverrideduserID
            });
          }
          if (overrideDetails?.length > 0 && (!element.overrideDetails || element.overrideDetails.length == 0)) {
            element.overrideDetails = overrideDetails;
          }
        });
      }
      else {
        return;
      }
      if (!this.overrideRateTypeForDynamicAvailability) {
        this.teeTimeInfo.scheduledTeeTimePlayers.forEach(x => {
          let overrideObj: OverrideDetails = {
            id: 0,
            scheduledTeeTimePlayerId: x.id,
            overrideType: OverrideType.rateTypeOverridePerDynamicAvailability,
            userId: 0
          }
          x.overrideDetails.push(overrideObj);
        });
      }   
    }
    if (teeTimeSetting && teeTimeSetting != null && teeTimeSetting.isNoShowCancellationPolicyConfigured) {
      let playersWithoutPaymentInformation = this.teeTimeInfo.scheduledTeeTimePlayers.filter(x=>!x.isBlocked && !(x.playerDetail.paymentReferenceId != 0)&& (x.playerStatus & PlayerPaymentstatus.paid) == 0 && (x.playerStatus & PlayerPaymentstatus.checkIn) == 0 && (x.playerStatus & PlayerPaymentstatus.checkOut) == 0);
      if(playersWithoutPaymentInformation && playersWithoutPaymentInformation.length >0)
      {
        var players:any = playersWithoutPaymentInformation.map(x => this._utilities.formatGuestName(x.playerDetail.firstName, x.playerDetail.lastName)).join(this.SPLITCHAR);
        players = players.replaceAll(this.SPLITCHAR,'<br>');
        console.log(players);
        let message = this.localization.captions.teetime.cancellationNoshowWarning;
        message = message+ "<br><br>" + "<b>"+players+"</b>";
        var res = await this._utilities.showAlert(message, AlertType.Info, ButtonType.YesNo, (res) => {
          if (res == AlertAction.NO) {
            this.bNotificationReturn = true;
          } else {
            this.bNotificationReturn = false;
          }
        });
        await res.afterClosed().toPromise().then(result => {
          if (result == AlertAction.NO) {
            this.disableSaveFromTeesheet(false);
          }
        });
      }
    }
    if (this.bNotificationReturn) {
      return;
    }
    const canShowPopup = (this._teetimeService.isBulkEdit ? EventNotification.BulkBooking : (this.isEdit ? EventNotification.TeeTimeUpdate : EventNotification.TeeTimeConfirmation));
    this.eventNotificationGroup = await this._NotificationConfigurationService.GetEventNotificationGroupByEventId(canShowPopup);

    if (this.eventNotificationGroup && this.eventNotificationGroup.length > 0) {
      this.bDirtyPlayerDetails = false;
      let playerswithoutContactInfo = this.teeTimeInfo.scheduledTeeTimePlayers.filter(x => !x.isBlocked && (!(x.playerDetail?.contactInformation?.some(y => y.name.toUpperCase() == "EMAIL" && y.value))
        || !(x.playerDetail.contactInformation.some(y => y.name.toUpperCase() == "PHONE" && y.value))));
      if (playerswithoutContactInfo && playerswithoutContactInfo.length > 0) {
        let players = playerswithoutContactInfo.map(x => this._utilities.formatGuestName(x.playerDetail.firstName, x.playerDetail.lastName)).toString();
        console.log(players);
        let message = this.localization.captions.teetime.emailConfirmation;
        message = message.interpolate({ players: players });
        var res = await this._utilities.showAlert(message, AlertType.Info, ButtonType.YesNo, (res) => {
          if (res == AlertAction.NO) {
            this.bNotificationReturn = true;
          } else {
            this.bNotificationReturn = false;
          }
        });
        await res.afterClosed().toPromise().then(result => {
          if (result == AlertAction.NO) {
            this.disableSaveFromTeesheet(false);
          }
        });
      }
    }

    if (this.bNotificationReturn) {
      return;
    }

    if (!this.isEdit && this._cartUtilities.isEmbed()) {
      // V-cart check and alert if the guest has any other activities in the Cart for the same time
      let guestIdsToValidate: string[] = [];
      if (this.teeTimeInfo && this.teeTimeInfo.scheduledTeeTimePlayers && this.teeTimeInfo.scheduledTeeTimePlayers.length) {
        this.teeTimeInfo.scheduledTeeTimePlayers.forEach(sp => {
          if (sp && sp.playerDetail && sp.playerDetail.playerLinkId)
            guestIdsToValidate.push(sp.playerDetail.playerLinkId);
        });
      }
      if (guestIdsToValidate && guestIdsToValidate.length) {
        if (await this._cartUtilities.checkIfAnyActivityPresentInCart(this.teeTimeInfo.scheduleDateTime, this.teeTimeInfo.scheduleDateTime, guestIdsToValidate)) {
          return;
        }
      }
    }
    this._utilities.ToggleLoader(true);
    this.disableSaveFromTeesheet(true);
    this.disableOnSave(true);
    // build API data from the form group
    // make API call to save
    let x = this.teeTimeAllocationBlock;
    // Added for saving Tee Time allocation Block 
    var teeTimeSetting = JSON.parse(sessionStorage.getItem(SettingScreen.TeeTime)) ;
    let defaultSettings = JSON.parse(sessionStorage.getItem(CacheConstants.DefaultSettings));  
    if(this.teeTimeAllocationBlock && this.teeTimeAllocationBlock != null){
      let teeTimeAllocationBlock :TeeTimeAllocationBlock = {
        courseId: Number(x.courseId),
        allocationDateTime: x.allocationDateTime,
        holeNumber: String(x.holeNumber),
        originalHoleNumber: x.originalHoleNumber,
        viewedByStarter: x.viewedByStarter,
        allocationBlockId: 0,
        id:0,
        isDefault: false
      };
      teeTimeAllocationBlock.allocationBlockId = defaultSettings.blockingCode 
      ? defaultSettings.blockingCode : this.teeTimeInfo.scheduledTeeTimePlayers[0]?.scheduledTeeTimePlayerFee?.allocationBlockId;     
    }      
    this.teeTimeInfo.viewedByStarter = this.teeTimeAllocationBlock?.viewedByStarter;   
    if (this.isEdit) {
      await this._teeTimeBusiness.Update(this.TeeTimeFormGroup, this.teeTimeInfo, this._teetimeService.isBulkEdit, addToCart).then(async x => {

        if(this.teeTimeInfo?.scheduledTeeTimePlayers?.length < 4)
        {
          this.showWaitlistPopup = this.isPlayerCleared;
        }
        if (this._teetimeService.isBulkEdit && this.teeTimeAllocationBlock && this.teeTimeAllocationBlock != null) {
          if (Array.isArray(x) && x.length) {                 
            await this.updateBulkTeetimeAllocationBlock(x, this.teeTimeAllocationBlock.allocationBlockId, this.teeTimeAllocationBlock, this.teeTimeAllocationBlock.viewedByStarter);
          }                    
        } 
        else {
            if (this.teeTimeAllocationBlock && this.teeTimeAllocationBlock != null) {
              this.teeTimeAllocationBlock.isDefault = false;
              await this.saveTeeTimeAllocationBlock(this.teeTimeAllocationBlock);
          }
        }
        
        if (x == null) { // this will come for Vcart Update
          this.dialogRef.close('save');
          this.quickLoginUtils.resetQuickIdDetails();
          return;
        }
        if(this.teeTimeInfo && this.teeTimeInfo.scheduledTeeTimePlayers && this.teeTimeInfo.scheduledTeeTimePlayers.length == 0){
          this.closePopUp();
        }
        this.bookingId = this.teeTimeInfo && this.teeTimeInfo.scheduledTeeTimePlayers && this.teeTimeInfo.scheduledTeeTimePlayers[0].bookingId;
        let result = false;
        const bookTeeTimesRuleResult: BookTeeTimesRuleResult[] = x as BookTeeTimesRuleResult[];
        result = await this._teeTimeBusiness.showRuleFailure(bookTeeTimesRuleResult, this._teetimeService.isBulkEdit);
        if (result) {
          const pMSRequestInfo: GolfPMSSessionInfo = this._utilities.getPMSSessionInfo();
          this.closePopUp();
          this.quickLoginUtils.resetQuickIdDetails();
        } else {
          this.onErrorNavigateToTabBasedOnErrorCodes(this._teeTimeBusiness.getErrorCodesFromRuleResult(bookTeeTimesRuleResult));
          this.disableSaveFromTeesheet(false);
        }
        this._utilities.ToggleLoader(false);
      });
    } else {      
      await this._teeTimeBusiness.Save(this.TeeTimeFormGroup, this.teeTimeInfo, addToCart, this.dialogData.cartEditDetail).then(async x => {
          if (this.teeTimeAllocationBlock && this.teeTimeAllocationBlock != null) {
            this.teeTimeAllocationBlock.isDefault = false;
            this.teeTimeAllocationBlock.viewedByStarter = this.teeTimeAllocationBlock.viewedByStarter != null ?
              this.teeTimeAllocationBlock.viewedByStarter : false;
            await this.saveTeeTimeAllocationBlock(this.teeTimeAllocationBlock);
        }
        if (x == null) { // this will come for Vcart Add
          this.dialogRef.close('save');
          this.quickLoginUtils.resetQuickIdDetails();
          return;
        }
        let result = false;
        const bookTeeTimesRuleResult: BookTeeTimesRuleResult[] = x as BookTeeTimesRuleResult[];
        result = await this._teeTimeBusiness.showRuleFailure(bookTeeTimesRuleResult, this._teetimeService.isBulkEdit);
        const pMSRequestInfo: GolfPMSSessionInfo = this._utilities.getPMSSessionInfo();
        if (result) {
          this._teeTimeBusiness.updateCustomFields(this.utilizedCustomFields);
          this.closePopUp(addToCart);
          this.quickLoginUtils.resetQuickIdDetails();
        } else {
          this.onErrorNavigateToTabBasedOnErrorCodes(this._teeTimeBusiness.getErrorCodesFromRuleResult(bookTeeTimesRuleResult));
          this.disableSaveFromTeesheet(false);
        }
        this._utilities.ToggleLoader(false);
      });
    }

    if (this.playerDetailsTab.playerAddedOrUpdated && this.playerDetailsTab.playerListInfo.length > 0) {

      this._teeTimeBusiness.UpdatePlayerToGPSInterface(this.teeTimeInfo, this.playerDetailsTab.updatePlayersToGps);
    }

    if (this._teetimeService.isBulkEdit && this.bookingId != '') {
      await this.callNotificationPopup();
    }

  }  

  handleTeeTimeRuleResults(bookTeeTimesRuleResult: BookTeeTimesRuleResult[]){

  }


  async updateBulkTeetimeAllocationBlock(teetimeRules: any[], allocationBlockId: number,
    teeTimeAllocationBlock: TeeTimeAllocationBlock, viewedByStarter: boolean): Promise<void> {


    let allocationDictionary = {};
    teetimeRules.forEach(element => {
      allocationDictionary[element.scheduledTeeTime.courseId] ?
        allocationDictionary[element.scheduledTeeTime.courseId].push(element.scheduledTeeTime.scheduleDateTime) :
        (allocationDictionary[element.scheduledTeeTime.courseId] = [element.scheduledTeeTime.scheduleDateTime])
    });
    let allocationBlocks = await this._teeTimeBusiness.getTeeTimeAllocationBlock(allocationDictionary);
    if (Array.isArray(allocationBlocks) && allocationBlocks.length) {
      let allocationListToConcat: TeeTimeAllocationBlock[] = []
      teetimeRules.forEach(y => {
        let eachBlock = allocationBlocks.find(z => z.courseId == y.courseId && z.originalHoleNumber == y.scheduledTeeTime.originalHoleNumber && z.allocationDateTime == y.scheduleDateTime);

        if (teeTimeAllocationBlock.allocationDateTime ==
          eachBlock.allocationDateTime) {
          eachBlock.viewedByStarter = viewedByStarter;
        }
        let canUpdateHole = teeTimeAllocationBlock.courseId == y.courseId && teeTimeAllocationBlock.allocationDateTime == y.scheduleDateTime
          && teeTimeAllocationBlock.originalHoleNumber == y.scheduledTeeTime.originalHoleNumber;

        if (eachBlock) {

          eachBlock.allocationBlockId = allocationBlockId;
          eachBlock.holeNumber = (canUpdateHole) ? teeTimeAllocationBlock.holeNumber : eachBlock.holeNumber
        }
        else {

          allocationListToConcat.push({
            courseId: y.courseId,
            allocationBlockId: allocationBlockId,
            id: 0,
            isDefault: false,
            holeNumber: (canUpdateHole) ? teeTimeAllocationBlock.holeNumber : y.holeNumber,
            viewedByStarter: viewedByStarter,
            allocationDateTime: y.scheduleDateTime,
            originalHoleNumber: y.originalHoleNumber // to do
          })
        }
      })
      if (allocationListToConcat && allocationListToConcat.length) {
        allocationBlocks = allocationBlocks.concat(allocationListToConcat);
      }
      await this._teeTimeBusiness.saveTeeTimeAllocationBlockList(allocationBlocks,TeeTimeAllocationBlockEvent.FromTeeTime | TeeTimeAllocationBlockEvent.FromBookPopup);
    }
  }

  async addToCartHandler(arg) {
    this.saveHandler(arg, true, true);
  }

  async saveHandler(arg, isFromSaveClose: boolean, addToCart: boolean = false, payParam?) {
    try {
      this.isSaveClose = isFromSaveClose;
      this.teeTimePlayersGuestId = this.MapPlayertoPlayerNameDetail(this.teeTimeInfo.scheduledTeeTimePlayers.filter(x => (x.playerDetail.playerCategoryId ==  playerTypes.hotelReservation && x.playerDetail.playerLinkId!=null ) || x.playerDetail.playerCategoryId == playerTypes.player || (x.playerDetail.playerCategoryId == playerTypes.newPlayer && x.playerDetail.activityId != DefaultGUID)));
      this.teeTimePlayersGuestId = this.teeTimePlayersGuestId.distinct(c => c.playerLinkId);
      
      this.disableSaveFromTeesheet(true);
      
      const isTabValid = this._teeTimeBusiness.validateCurrentTab(this.selectedIndex, this.TeeTimeFormGroup, this.teeTimeInfo);
      if (!isTabValid) {
        this.disableSaveFromTeesheet(false);
        return;
      }
      const bReturn = await this.showAlertToUpdatePlayer(this.bDirtyPlayerDetails);
      if (bReturn) {
        this.disableSaveFromTeesheet(false);
        return;
      };
      this._teeTimeBusiness.saveRainChecks(this.teeTimeInfo, this.slideInput.course);
      const nextTab = this._teeTimeBusiness.FindNextTab(this.selectedIndex, this.TeeTimeFormGroup, this.hasUserAccess, this.isSaveClose);

      if (nextTab === this.Tabs.feeInformation) { // rain check user access
        const isValidAccess = await this.validateBreakPointForTabWithCallback(nextTab, addToCart);
        this.hasUserAccess[this.Tabs.feeInformation] = isValidAccess.isAllow;
        if (!isValidAccess.isAllow && !isValidAccess.isViewOnly) {
          this.disableSaveFromTeesheet(false);
          return;
        }
      }     
      if (nextTab === this.Tabs.packageEntitlement || (payParam && this.isEdit && this.isAnyOnePlayerDetailModified)) {
        this.isSaveClose = nextTab === this.Tabs.packageEntitlement;
        if (this.quickIdPopUpEnable && !addToCart) {
          await this._teeTimesActionBusiness.tempHoldTeeTime(this.teeTimeInfo, TempHoldStatus.release);
          const quickLoginDialogRef = this.quickLoginUtils.QuickLogin();
          quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
            if (quickLoginDialogResult.isLoggedIn) {
              const quickRoleId = this.quickLoginUtils.GetQuickLoginRoleId();
              const roleId = this.localization.GetUserInfo("roleId");
              let isAllocationAllowed = false;
              if (quickRoleId != roleId) {
                const allocationCodePermissionsforQuickId = await this._teeTimeBusiness.GetAllocationBlockPermissionByRole(quickRoleId);
                isAllocationAllowed = this.checkAlloctionBlockAndPlayerTypePermissionOnSave(allocationCodePermissionsforQuickId);
              }
              if (!isAllocationAllowed) {
                await this.SaveFromPackageEntitlement(addToCart);
                if(this.bNotificationReturn )
                return;
              }
            }
            else {
              this.disableSaveFromTeesheet(false);
              return;
            }
          });
        }
        else {
          await this.SaveFromPackageEntitlement(addToCart);
          if(this.bNotificationReturn )
          return;
        }
      }
      if (nextTab === this.Tabs.itinerary) {
        this.enableItinerary = (this.teeTimeInfo.scheduledTeeTimePlayers.length > 0) ? true : false;
        this.selectedIndex = nextTab;
        this.disableOnSave(false);
      }
      else if (payParam) {
        this.pay(payParam);
      }
      else if (!this.isSaveClose) {
        this.selectedIndex = nextTab;
      }
      // this.disableSaveFromTeesheet(false);
    }
    finally {
      this._utilities.ToggleLoader(false);
      // this.disableOnSave(false);
    }
  }

  async callNotificationPopup() {

    this.eventNotificationGroup = await this._NotificationConfigurationService.GetEventNotificationGroupByEventId(EventNotification.BulkUpdate);
    if (this.eventNotificationGroup && this.eventNotificationGroup.length > 0) {
      await this.calltriggeremailsmspopupfunction();
    }
  }

  async calltriggeremailsmspopupfunction() {
    var objPhone: any = null;
    var email = null;
    if (this.isBulkEdit && this.bulkPlayers.length > 0) {
      var playerIds = this.bulkPlayers.map((x) => { return x.playerId });
      let playerContactInfo = playerIds && playerIds != null && playerIds?.length > 0 ? await this._retailBusiness.GetPlayerContactInfoByPlayerIdList(playerIds) : null;
      objPhone = playerContactInfo && playerContactInfo != null && playerContactInfo?.length > 0 ? this._utilities.GetPhoneNumber(playerContactInfo) : null;
      email = playerContactInfo && playerContactInfo != null && playerContactInfo?.length > 0 ? this._utilities.GetEmail(playerContactInfo) : null;
    }
    this.popUpComponentDetails = {
      componentName: SendNotificationMailSmsComponent,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent',
        bindData: ''
      }
    };
    this._dialog.open(SendNotificationMailSmsComponent, {
      width: "40%",
      height: "49%",
      panelClass: "",
      data: {
        title: this.localization.captions.common.notificationTitle,
        save: this.localization.captions.common.PopUpSend,
        cancel: this.localization.captions.common.PopUpSkip,
        componentDetails: this.popUpComponentDetails,
        info: this.bookingId,
        mailId: email && email != null && email?.value ? email.value : null,
        countryCode: objPhone && objPhone != null && objPhone != "" && objPhone?.value && objPhone?.value != null && objPhone?.value != "" ? this._utilities.getCountryCodeFromValue(Number(objPhone?.type), objPhone?.value) : null,
        phone: objPhone && objPhone != null && objPhone != "" && objPhone?.value && objPhone?.value != null && objPhone?.value != "" ? this._utilities.getPhoneNumberFromValue(Number(objPhone?.type), objPhone?.value) : null,
        action: "BulkUpdate",
        showStaticBool: false,
        fromComponent: 'fromComponent',
        actionType: ButtonAction.save
      },
      disableClose: true
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(result => {
      this.disableOnSave(false);
    });
  }

  formInitialized(arg, tab) {
    switch (tab) {
      case TeeTimeTabs.playerInformation:
        this.TeeTimeFormGroup.setControl('playerInformation', arg);
        break;
      case TeeTimeTabs.generalInformation:
        this.TeeTimeFormGroup.setControl('generalInformation', arg);
        break;
      case TeeTimeTabs.feeInformation:
        this.TeeTimeFormGroup.setControl('feeInformation', arg);
        break;
      case TeeTimeTabs.otherInformation:
        this.TeeTimeFormGroup.setControl('otherInformation', arg);
        break;
    }
  }

  private async interceptTabChange(tab?: MatTab, tabHeader?: MatTabHeader, idx?: number) {
    let restrictTabNavigationValid = this.isEdit && this.teeTimeInfo?.scheduledTeeTimePlayers?.length == 0;
    if(restrictTabNavigationValid) return;
    const isTabValid = (idx != 4) ? this._teeTimeBusiness.validateCurrentTab(this.selectedIndex, this.TeeTimeFormGroup, this.teeTimeInfo) ||
      (this.isEdit || this.isPartialEdit) : this.enableItinerary;

    const blockedCount = this.teeTimeInfo?.scheduledTeeTimePlayers?.filter(x => x?.isBlocked)?.length > 0 ? this.teeTimeInfo?.scheduledTeeTimePlayers?.filter(x => x?.isBlocked)?.length : this.slideInput.playerDetail.filter(x => x.isBlocked)?.length;
    // Currently package entitlement is out of scope
    if (idx === this.Tabs.packageEntitlement || blockedCount === this._teetimeService.totalPlayerCount) {
      return;
    }
    const isValidAccess = await this.validateBreakPointForTab(idx);
    if (!isValidAccess.isAllow && !isValidAccess.isViewOnly) {
      return;
    }
    if (isTabValid) {
      this.selectedIndex = idx;
      if (this.isHistorical(this.dialogData.componentDetails.popUpDetails.bindData[1])) {
        this.disableSave(true);
      }
      else this.disableSave(false);
      return true;
    }
  }

  private async validateBreakPointForTabWithCallback(tab, addToCart: boolean) {
    return await this.validateBreakPointForTab((tab), (res) => {
      if (res === AlertAction.CONTINUE) {
        this.saveHandler(true, this.isSaveClose,addToCart);
      }
    });
  }

  private async validateBreakPointForTab(tab: TeeTimeTabs, callback?: any) {
    let breakPointNumber = 0;
    switch (tab) {
      case TeeTimeTabs.playerInformation:
        break;
      case TeeTimeTabs.generalInformation:
        break;
      case TeeTimeTabs.feeInformation:
        //breakPointNumber = UserAccessBreakPoints.RAINCHECKISSUE;
        break;
      case TeeTimeTabs.otherInformation:
        break;
    }
    if (this.isEdit) var isAccess = this._teeTimesActionBusiness.ValidateGeneralInfoBreakPoint(UserAccessBreakPoints.EDITGENERALINFO, this.tabAccessOnEdit)
    let isValidAccess = breakPointNumber ?
      await this._teeTimeBusiness.validateBreakPoints(breakPointNumber, callback, isAccess) : { isAllow: true, isViewOnly: false };
    if (this.isEdit) {
      isValidAccess = this._teeTimesActionBusiness.validateBreakPointForTabEdit(this.isBulkEdit, this.isMixedPlayersSlot, this.bulkEditUserAccess, this.normalBookUserAccess, this.tabAccessOnEdit, tab)
    }
    return isValidAccess;
  }

  private async validateUserAccess(breakPointNumber: number, isEditGeneralAccess) {
    await this._teeTimeBusiness.validateBreakPoints(breakPointNumber, null, isEditGeneralAccess);
    this.disableControls();
  }
  private async validateUserBreakpoint(breakPointNumber: number, result: any, isEditGeneralAccess) {
    if (isEditGeneralAccess && !isEditGeneralAccess.isAllow && !isEditGeneralAccess.isViewOnly)
      await this._teeTimeBusiness.ShowBreakpointError(breakPointNumber, result);
    this.disableControls();
  }

  private disableControls() {
    if (this._teeTimeBusiness.isViewOnly) {
      this.isViewOnly = true;
    }
  }
  receivedCustomfields(arg) {
    this.utilizedCustomFields = arg;
  }
  playerAsDirty(arg: boolean) {
    this.bDirtyPlayerDetails = arg;
  }
  isplayerLoaded(arg: boolean){
      this.playersLoadedInPlayerCard = true;
      this._utilities.ToggleLoader(false);

  }
  playerCleared(arg: boolean)
  {
    if(arg)
    {
      this.isPlayerCleared = true;
    }
  }
  isSlideInputLoaded(arg: boolean)
  {
    this.slideInputLoaded = true;
      this._utilities.ToggleLoader(false);
  }
  private validateMixedBreakPoints() {

    if (this.isMixedPlayersSlot && this.bulkEditUserAccess && (!this.bulkEditUserAccess.isAllow || this.bulkEditUserAccess.isViewOnly) && this.normalBookUserAccess && (!this.normalBookUserAccess.isAllow || this.normalBookUserAccess.isViewOnly)) {
      this._teeTimeBusiness.isViewOnly = true;
      this.isViewOnly = true;
    }
  }

  private validateRefundCheck() {
    if (this.isRefundCheck) {
      this.isViewOnly = true;
    }
  }

  private updateUserAccessInfo() {
    var toShowValidation = this.isEdit || this.isMixedPlayersSlot ? true : false;
    if (toShowValidation && this.bulkEditUserAccess && this.normalBookUserAccess && (!this.bulkEditUserAccess.isAllow || !this.normalBookUserAccess.isAllow)) {
      const parentEl = this.infoEle._elementRef.nativeElement;
      let infoWrapper;
      var isBulkBooking = true;
      var isNormalBooking = true;
      if (!this.isMixedPlayersSlot) {
        var blockPlayers = this.slideInput.playerDetail.filter(x => !x.isBlocked);
        isBulkBooking = (this.slideInput.playerDetail && blockPlayers && blockPlayers.length > 0) ? blockPlayers.map(x => x.teeTimeFormat).every(x => x == TeeTimeFormat.BulkTee) : false;
        isNormalBooking = (this.slideInput.playerDetail && blockPlayers && blockPlayers.length > 0) ? blockPlayers.map(x => x.teeTimeFormat).every(x => x !== TeeTimeFormat.BulkTee) : false;
      }
      if (!this.bulkEditUserAccess.isAllow && !this.normalBookUserAccess.isAllow && this.isMixedPlayersSlot) {
        const message = this.localization.captions.breakpoint[UserAccessBreakPoints.BOOKTEETIME] + ", " + this.localization.captions.breakpoint[UserAccessBreakPoints.BULKTEETIMESEDIT];
        infoWrapper = this._utilities.GetInfoStrip(message);
      }
      else if (isBulkBooking && !this.bulkEditUserAccess.isAllow) {
        const message = this.localization.captions.breakpoint[UserAccessBreakPoints.BULKTEETIMESEDIT];
        infoWrapper = this._utilities.GetInfoStrip(message);
      } else if ((isNormalBooking && !this.normalBookUserAccess.isAllow) || (isBulkBooking && this.bulkEditUserAccess.isAllow && !this.normalBookUserAccess.isAllow)) {
        const message = this.localization.captions.breakpoint[UserAccessBreakPoints.BOOKTEETIME];
        infoWrapper = this._utilities.GetInfoStrip(message);
      }
      this.rendrer.insertBefore(parentEl, infoWrapper, parentEl.childNodes[1]);
    }
  }
  //
  slideAllocationSave(e) {
    console.log("Save Allocation Change and Refresh Grid", e);
    this.teeTimeAllocationBlock = e;
  }

  async saveTeeTimeAllocationBlock(teeTimeAllocationBlock: TeeTimeAllocationBlock) {
    await this._teeTimeBusiness.saveTeeTimeAllocationBlockList([teeTimeAllocationBlock],TeeTimeAllocationBlockEvent.FromTeeTime | TeeTimeAllocationBlockEvent.FromBookPopup);
  }

  async saveTeeTimeAllocationBlock_bkp(teeTimeAllocationBlock: TeeTimeAllocationBlock) {
    let teeTimeAllocationBlockList: TeeTimeAllocationBlock[] = [];
    if (teeTimeAllocationBlock && teeTimeAllocationBlock != null) {
      let teeTimeAllocationBlocks = await this._teeTimeBusiness.GetTeeTimeAllocationBlocksByDateRange(teeTimeAllocationBlock.courseId, this.localization.LocalizeDate(teeTimeAllocationBlock.allocationDateTime), this.localization.LocalizeDate(teeTimeAllocationBlock.allocationDateTime));
      if (teeTimeAllocationBlocks != null && teeTimeAllocationBlocks.length > 0) {
        let existingAllocationBlock = teeTimeAllocationBlocks.find(x => x.courseId == teeTimeAllocationBlock.courseId && x.allocationDateTime == teeTimeAllocationBlock.allocationDateTime &&
          (x.holeNumber == teeTimeAllocationBlock.holeNumber + 'A' || x.holeNumber == teeTimeAllocationBlock.holeNumber + 'B' || x.holeNumber == teeTimeAllocationBlock.holeNumber));
        if (existingAllocationBlock) {
          let hole = existingAllocationBlock.holeNumber.includes('A') || existingAllocationBlock.holeNumber.includes('B');
          if (hole) {
            this._utilities.showAlert('Duplicate Value for Double start', AlertType.Error, ButtonType.Ok);
            return;
          }
          else {
            teeTimeAllocationBlock.holeNumber = teeTimeAllocationBlock.holeNumber + 'A';
            existingAllocationBlock.holeNumber = existingAllocationBlock.holeNumber + 'B';
            teeTimeAllocationBlockList.push(teeTimeAllocationBlock);
            teeTimeAllocationBlockList.push(existingAllocationBlock);
          }
        }
        else {
          teeTimeAllocationBlockList.push(teeTimeAllocationBlock);
        }
      }
      else {
        teeTimeAllocationBlockList.push(teeTimeAllocationBlock);
      }
      await this._teeTimeBusiness.saveTeeTimeAllocationBlockList(teeTimeAllocationBlockList, TeeTimeAllocationBlockEvent.FromTeeTime | TeeTimeAllocationBlockEvent.FromBookPopup);
    }
  }

  private checkAlloctionBlockAndPlayerTypePermissionOnSave(allocationcodeRolePermission: allocationBlockPlayerTypePermission[]): boolean {
    let res = false;
    const modifiedslots = this.teeTimeInfo.scheduledTeeTimePlayers.filter(x => (x.isPlayerDetailModified != undefined)).map(x => x.scheduledTeeTimePlayerFee);
    const playerInfoDetails = _.chain(modifiedslots).groupBy('allocationBlockId')
      .map((value, key) => (
        {
          allocationBlockId: Number(key),
          playerInfo: value as ScheduledTeeTimePlayerFee[]
        })
      ).value();
    let nonaccessibleAllocationBlock = [];
    let nonaccessiblePlayer = [];
    if (allocationcodeRolePermission.length > 0) {
      playerInfoDetails.forEach(allocationBlock => {
        const allocationBlockWithPlayerType = allocationcodeRolePermission.filter(x => x.allocationBlockId == allocationBlock.allocationBlockId);
        const allocationBlockPlayerTypes = this.allocationBlockPlayerTypes.find(x => x.id == allocationBlock.allocationBlockId);
        if (allocationBlockWithPlayerType.length == 0) {
          if (allocationBlockPlayerTypes && allocationBlockPlayerTypes != undefined) {
            nonaccessibleAllocationBlock.push(allocationBlockPlayerTypes.name);
          }
        } else {
          allocationBlock.playerInfo.forEach(player => {
            if (player.playerTypeId) {
              const allocationPlayer = allocationBlockWithPlayerType.filter(x => x.playerTypeId == player.playerTypeId);
              if (allocationPlayer.length == 0) {
                if (allocationBlockPlayerTypes && allocationBlockPlayerTypes != undefined) {
                  const playerType = allocationBlockPlayerTypes.playerTypes.find(x => x.id === player.playerTypeId);
                  if (playerType) {
                    nonaccessiblePlayer.push(playerType.type);
                  }
                }
              }
            }
          });
        }
      });
    } else if (allocationcodeRolePermission.length == 0) {
      playerInfoDetails.forEach(allocationBlock => {
        const allocationBlockPlayerTypes = this.allocationBlockPlayerTypes.find(x => x.id == allocationBlock.allocationBlockId);
        nonaccessibleAllocationBlock.push(allocationBlockPlayerTypes.name);
      });
    }
    if (nonaccessibleAllocationBlock.length > 0 || nonaccessiblePlayer.length > 0) {
      res = true;
      const uniqueNonAccessAllocationBlock = _.uniq(nonaccessibleAllocationBlock);
      const uniqueNonAccessPlayer = _.uniq(nonaccessiblePlayer);
      let name = ''
      if (uniqueNonAccessAllocationBlock.length > 0) {
        name = uniqueNonAccessAllocationBlock.join(', ') + ' ';
      }
      if (uniqueNonAccessPlayer.length > 0) {
        name = name + uniqueNonAccessPlayer.join(', ')
      }
      this._utilities.showAllocationCodePermissionDeniedPopup(name);
    }
    return res;
  }

  onErrorNavigateToTabBasedOnErrorCodes(errorCode: number) {
    let defaultIndex = 0;
    switch (errorCode) {
      case RuleCodes.CADDY_TYPE_REQUIREMENT_FAILED:
        defaultIndex = 1; // general information tab
        this.selectedIndex = defaultIndex;
        break;
      default:
        defaultIndex = 0;
        break;
    }

  }

  getPlayerType(teeTimeInfo: TeeTimeModel): PlayerType {
    let selectedPlayerType;
    if (teeTimeInfo && teeTimeInfo.scheduledTeeTimePlayers.length > 0) {
      teeTimeInfo.scheduledTeeTimePlayers.forEach(player => {
        let playerType = this.allPlayerTypes.find(x => x.id == player.scheduledTeeTimePlayerFee.playerTypeId);
        let playerDaysOutResut = this._TeeSheetCommonBusiness.isPlayerDaysout(playerType, teeTimeInfo.scheduleDateTime)
        if ((!player.overrideDetails || player.overrideDetails.length == 0) && playerDaysOutResut && (playerDaysOutResut == PlayerDaysOutResut.StartDateFail || playerDaysOutResut == PlayerDaysOutResut.EndDateFail)) {
          selectedPlayerType = playerType;
          player.isPlayerDaysOutFailed = true;
        }
      });
    }
    return selectedPlayerType;
  }
  getRateType(teeTimeInfo: TeeTimeModel): RateType {
    let selectedRateType;
    if (teeTimeInfo && teeTimeInfo.scheduledTeeTimePlayers.length > 0) {
      teeTimeInfo.scheduledTeeTimePlayers.forEach(player => {
        let rateType = this.allRateTypes.find(x => x.id == player.scheduledTeeTimePlayerFee.rateTypeId);
        let rateTypeDaysOutResult = this._TeeSheetCommonBusiness.isRateDaysout(rateType, teeTimeInfo.scheduleDateTime)
        if ((!player.overrideDetails || player.overrideDetails.length == 0) && rateTypeDaysOutResult && (rateTypeDaysOutResult == RateTypeDaysOutResult.StartDateFail || rateTypeDaysOutResult == RateTypeDaysOutResult.EndDateFail)) {
          selectedRateType = rateType;
          player.isRateDaysOutFailed = true;
        }
      });
    }
    return selectedRateType;
  }

  disableOnSave(status: boolean) {
    this.buttonObj = {
      isEdit: this.isEdit,
      disabled: status,
      showAddToCartButton: this._cartUtilities.isEmbed()  && this.slotPlayerCount < 4
    };
    setTimeout(() => this.cdk.detectChanges(), 1);
  }

  redirectToBulkBooking(eve) {
    if (eve) {
      let obj = {
        from: 'bulk',
        eve
      }
      this.dialogRef.close(obj);
    }
  }
  async payClick(eve) {
    var teeTimeSetting = JSON.parse(sessionStorage.getItem('TEETIMESETTING'));
    if (teeTimeSetting && teeTimeSetting != null && teeTimeSetting.requireCheckInCheckOut) {
      var width = '60%';
      var height = '60%';
      var toOpenDefaultOutlet = !await this._retailBusiness.onClickValidateSelectedOutlet(eve?.bindData?.courseId, false, true);
      this._utilities.ToggleLoader(false);
      var req = this._teeTimeSharedBusiness.buildCallBackObject(PaymentModalComponent, eve.compData, width, height, "", this.localization.captions.teeActions.payTitle, this.localization.captions.teeActions.pay, ButtonAction.pay,
        false, undefined, TeeTimeActions.payment, undefined, undefined, undefined, undefined, toOpenDefaultOutlet,false,false,true);
      if (teeTimeSetting.allowPaymentInCheckIn && (((eve?.compData?.playerStatus & PlayerPaymentstatus.checkIn) == 0))) {

        this._utilities.showAlert(this.localization.captions.settings.allowPaymentInCheckInValidation, AlertType.Info, ButtonType.YesNo, (res, req) => { this._teeTimeSharedBusiness.openPaymentModal(res, req) }, [{ extraParams: req }]);
        return
      }
      else {
        this._teeTimeSharedBusiness.validatePaymentByRequireCheckIn(req, true);
        return;
      }
    }
    if (!await this._retailBusiness.onClickValidateSelectedOutlet(eve?.bindData?.courseId, false, true)) {
      this.payCaption = this.localization.captions.teetime;
      let selectOutletPopup = SelectOutletPopupComponent;
      let selectOutletTitle = this.payCaption.selectOutletPopupTitle;
      let selectOutletSaveText = this.payCaption.saveData;
      let selectOutletPopUpWidth = '35%';
      let selectOutletPopupHeight = '45%';
      let eventType = ButtonAction.pay;
      this._utilities.ToggleLoader(false);
      this.openDefaultOutletPopup(selectOutletPopup, eve, selectOutletPopUpWidth, selectOutletPopupHeight, selectOutletTitle, selectOutletSaveText, eventType, TeeTimeActions.payment);
    }
    else {
      this._utilities.ToggleLoader(false);
      this.saveHandler('', false,false, eve)
    }
  }
  openDefaultOutletPopup(modalComponentName, arg, popUpWidth, popUpHeight, modalTitle, saveText, eventType, actionType?) {
    this.popUpComponentDetails = {
      componentName: modalComponentName,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent',
        bindData: arg
      }
    };
    const selectDefaultDialogRef = this.dialog.open(SelectOutletPopupComponent, {
      width: popUpWidth,
      height: popUpHeight,
      data: {
        title: modalTitle,
        update: saveText,
        cancel: this.payCaption ? this.payCaption?.cancel : '',
        componentDetails: this.popUpComponentDetails,
        info: arg,
        actionType: eventType,
        teeTimeType: actionType,
        isSaveClose: true
      },
      disableClose: true
    });
    selectDefaultDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(result => {
      this._retailSharedVariableService.SelectedOutletId = result.outlet;
      this.pay(result.info)
    });
  }

  private pay(eve) {
    this.payIconEnable = false;
    const componentDetails = {
      componentName: PaymentModalComponent,
      popUpDetails: {
        isStepper: false,
        bindData: eve.bindData,
        eventName: 'notifyParent'
      }
    };
    this._teeTimesActionBusiness.tempHoldTeeTime(this.teeTimeInfo, TempHoldStatus.release);
    const payDialogRef = this.dialog.open(CommonPopupComponent, {
      maxWidth: '90vw',
      width: '60%',
      height: '60%',
      disableClose: true,
      data: {
        title: this.localization.captions.teeActions.payTitle,
        update: this.localization.captions.teeActions.pay,
        cancel: this.localization.captions.teeActions.cancel,
        componentDetails: componentDetails,
        actionType: ButtonAction.pay,
        info: eve.compData,
        isEditPay : true
      }
    });
    payDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(result => {
      if (result !== 'close') {
        console.log('save');
      }
      if (!this.isEdit && this.TeeTimeFormGroup.controls.playerInformation.dirty && (this.TeeTimeFormGroup.controls.playerInformation.valid || !this.TeeTimeFormGroup.controls.playerInformation.touched)) this.disableSave(false);
      this.payIconEnable = true;
    });
  }

  async getPlayerProfileBreakPointAccess() {
    this._teeTimesActionBusiness.validateBreakPointAccess(UserAccessBreakPoints.PLAYERPROFILE, false).then(x => {
      this.playerProfileBKAccess = x;
    });
  }

  private async closePopUp(addToCart: boolean = false) {
    if (this.isSaveClose && !addToCart && !this.isEditCart) {
      await this._teeTimesActionBusiness.tempHoldTeeTime(this.teeTimeInfo, TempHoldStatus.release);
    }
    this.dialogRef.close({from:'save', showWaitlistPopup : this.showWaitlistPopup});
  }
  async getRentalItems(eve) {
    var retails = await this._teeTimesActionBusiness.getScheduledPlayerRetailItemsByActivityId([eve.activityId]);
    this.rentalRetailItems = [...retails];
  }
}
