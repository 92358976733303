import { Injectable } from "@angular/core";
import { RateType } from 'src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal';
import { CancelPlayer, CancelPlayerCaption } from './cancel-player-model';
import {CancelReason} from 'src/app/settings/golf-setup/code-setup/cancel-reasons/cancel-reasons.model'
import { CancelReasonsDataService } from 'src/app/shared/data-services/golfmanagement/cancel-reasons.data.service';
import { PlayerType } from 'src/app/settings/golf-setup/code-setup/player-type/player-type.modal';
import { PlayerTypeService } from 'src/app/shared/data-services/golfmanagement/playertype.data.service';
import { RateTypeDataService } from 'src/app/shared/data-services/golfmanagement/ratetype.data.service';
import { TeeTimeDataService } from 'src/app/shared/data-services/golfschedule/TeeTime.data.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { TeeTimesActionService } from 'src/app/shared/data-services/golfschedule/teeTimesAction.data.service';
import { CheckboxTableHeader, AlertType, ButtonType, TeeTimeAllocationBlock } from 'src/app/shared/shared-models';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { ScheduleStatus, ScheduledPlayer } from 'src/app/shared/models/teesheet.form.models';
import { PlayerPaymentstatus, TeeTimeAllocationBlockEvent } from 'src/app/tee-time/search/search-model';
import { Course } from 'src/app/settings/golf-setup/code-setup/course/create-course-modal/create-course-model';
import { CourseDataService } from 'src/app/shared/data-services/golfschedule/course.data.service';
import { CancelPlayers } from '../move-player-modal/move-player-modal.model';
import { TeeTimesActionBusiness } from 'src/app/tee-time/shared/tee-action.business';
import { API } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { SettingsDataService } from 'src/app/shared/data-services/golfschedule/settings.data.service';
import { SettingModule, SettingScreen } from 'src/app/shared/global.constant';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';
import { allocationBlockPlayerTypePermission } from "src/app/settings/user-setup/booking-permission/booking-permission.model";
import { AllocationBlockDataService as AllocationBlockManagementDataService } from 'src/app/shared/data-services/golfmanagement/allocationblock.data.service';
import { UserAccessModel } from "src/app/common/dataservices/authentication/useraccess-model.model";
import { GolfNowConfigAPI } from 'src/app/settings/interfaces/thirdparty-integration/golfnow-setup/golfnow-setup.model';
import { TeeTimeCancellationNoShowFeeUtilities } from "../noshow-cancellation-fees-tee-time-utilities";
import { CancellationNoShowLedger } from "src/app/retail/shared/shared.modal";
import { RentalEventService } from "src/app/shared/service/rental-event-service";

@Injectable()

export class cancelPlayerBusiness {
  public readonly cancelPlayerCaption: CancelPlayerCaption;
  constructor(
    private _courseDataService: CourseDataService,
    private _rateTypeDataService: RateTypeDataService,
    private _cancelReasonDataService: CancelReasonsDataService,
    private _playerTypeService: PlayerTypeService,
    private _teeTimeDataService: TeeTimeDataService,
    private _teeTimesActionService: TeeTimesActionService,
    private _localization: GolfLocalization,
    private _utilities: GolfUtilities,
    private _teeTimesActionBusiness: TeeTimesActionBusiness,
    private _settingService: SettingsDataService,
    private _userAccessBusiness: UserAccessBusiness,
    private allocationCodeManagementService: AllocationBlockManagementDataService,
    private _settingSerivice: SettingsDataService,
    private _teeTimeCancellationNoShowFeeUtilities: TeeTimeCancellationNoShowFeeUtilities,
    private _retailEventService: RentalEventService
  ) {

    this.cancelPlayerCaption = this._localization.captions.settings;
  }

  public playersCount: number = 0;
  public checkInCheckOutCount: number = 0;
  public paidPlayersCount: number = 0;
  public unPaidPlayersCount: number = 0;
  private _scheduledPlayers: ScheduledPlayer[] = [];
  isAllocationCodePermissionEnabled : boolean = false;
  alloc: number[] = [];
  public get ScheduledPlayers() {
    return this._scheduledPlayers;
  }

  async getPlayerDetails(scheduleTeeTimeInfo: any, bulkBookingId: string): Promise<CancelPlayer[]> {
    const teeTimeSetting = await this._settingService.getTeeTimeSetting();
    if (bulkBookingId === 'back') { return []; }
    const courses: Promise<Course[]> = this._courseDataService.getCoursesWithUserAccess();
    const rateTypes: Promise<RateType[]> = this._rateTypeDataService.getAllRateTypes(true);
    const playerTypes: Promise<PlayerType[]> = this._playerTypeService.getAllPlayerTypes(false);
    let scheduledPlayers: Promise<ScheduledPlayer[]> = null;
    let dateA: any;
    let dateB: any;
    let isBulk = false;
    if (bulkBookingId && bulkBookingId.length > 0) {
      isBulk = true;
      scheduledPlayers = this._teeTimeDataService.getScheduledPlayersByBookingId(bulkBookingId, true);
    } 
    else if(scheduleTeeTimeInfo.isFromTeeSheetForCancel || !teeTimeSetting.allowCancellationAcrossSlots)
    {
      scheduledPlayers = this._teeTimeDataService.getScheduledPlayers(scheduleTeeTimeInfo.scheduledTeeTimeId);
    }
    else if(teeTimeSetting.allowCancellationAcrossSlots) {
      scheduledPlayers = this._teeTimeDataService.GetTeeTimePlayersByDateAndCourse(scheduleTeeTimeInfo.course.id, this._localization.convertDateToAPIdate(scheduleTeeTimeInfo.time), ScheduleStatus.booked);
    }

    const responses = Promise.all([courses, rateTypes, playerTypes, scheduledPlayers]);
    if (!isBulk)
    {
      this.alloc = (await this.GetAllocationBlockPermissionByRole()).map(x=> x.allocationBlockId);
      this.isAllocationCodePermissionEnabled = this._utilities.IsAllocationCodePermissionEnabled();
    }
    const players = await responses.then(p => {
      // p[3].sort((a, b) => {
      //     dateA = new Date(a.scheduledDateTime);
      //     dateB = new Date(b.scheduledDateTime);
      //     return dateA - dateB;
      // });
      if(scheduleTeeTimeInfo.isFromTeeSheetForCancel){
        console.log(scheduledPlayers);
        p[3] = p[3].filter(x=> !x.isBlocked && x.playerId == scheduleTeeTimeInfo.playerDetail[0].playerId && (!this.isAllocationCodePermissionEnabled || !scheduleTeeTimeInfo.allocation?.id || this.alloc.includes(scheduleTeeTimeInfo.allocation.id)))
      }
      else
      {
        p[3] = p[3].filter(x => !x.isBlocked && (!this.isAllocationCodePermissionEnabled || !x.teeTimeAllocationBlockId || this.alloc.includes(x.teeTimeAllocationBlockId)));
      }
      this._scheduledPlayers = p[3];
      return p[3].map(o => {
        const course = p[0] && p[0].find(c => c.id === o.courseId);
        const rateType = p[1] && p[1].find(r => r.id === o.rateTypeId);
        const playerType = p[2] && p[2].find(p => p.id === o.playerTypeId);
        return {
          id: o.playerId,
          player: this._utilities.formatGuestName(o.firstName, o.lastName),
          teeTime: this._localization.LocalizeShortDate(this._localization.getDate(o.scheduledDateTime)) + ' ' + this._localization.getTime(o.scheduledDateTime as Date, 12).toUpperCase(),
          course: course ? course.name : '',
          amount: this._localization.localizeCurrency((o.greenFee ? o.greenFee : 0) + (o.cartFee ? o.cartFee : 0), false),
          playerType: playerType ? playerType.type : '',
          playerTypeId: playerType ? playerType.id : 0,
          rateType: rateType ? rateType.type : '',
          deposits: o.deposits,
          playerStatus: o.playerStatus,
          ticketNumber: o.ticketNumber,
          checked: isBulk || scheduleTeeTimeInfo.playerDetail.some(pd=> pd.playerId == o.playerId) ,
          teeTimeFormat: o.teeTimeFormat,
          playerPosition: o.playerSlotPosition,
          scheduledTeeTimePlayerId: o.scheduledTeeTimePlayerId,  
          isWaivedOff: false,
          isIconClicked: false,
          tokenTransId: o.tokenTransId
        }
      });
    });
    this.checkInCheckOutCount = players.filter(o=> o.checked && ((o.playerStatus & PlayerPaymentstatus.CheckIn) != 0 || (o.playerStatus & PlayerPaymentstatus.CheckOut) != 0))?.length ?? 0;
    this.paidPlayersCount = players.filter(o=> o.checked && (o.playerStatus & PlayerPaymentstatus.paid) != 0)?.length ?? 0;
    let unpaidPlayers = await this._teeTimeCancellationNoShowFeeUtilities.IsNoShowCancellationPolicyConfigured() ?  
    players.filter(u => ((u.playerStatus & PlayerPaymentstatus.paid) != 0 || (u.playerStatus & PlayerPaymentstatus.unPaid) != 0 || (u.playerStatus & PlayerPaymentstatus.refund) != 0) && (u.playerStatus & PlayerPaymentstatus.CheckIn) == 0 && (u.playerStatus & PlayerPaymentstatus.CheckOut) == 0) :
    players.filter(u => ((u.playerStatus & PlayerPaymentstatus.unPaid) != 0 || (u.playerStatus & PlayerPaymentstatus.refund) != 0) && (!u.deposits || u.deposits.length === 0) && (u.playerStatus & PlayerPaymentstatus.CheckIn) == 0 && (u.playerStatus & PlayerPaymentstatus.CheckOut) == 0) ;
    this.playersCount = unpaidPlayers.filter(player => player.checked)?.length ?? 0;
    let checkedplayers = unpaidPlayers.filter(player => player.checked);
    let uncheckedplayers = unpaidPlayers.filter(player => !player.checked);
    unpaidPlayers = checkedplayers.concat(uncheckedplayers);
    this.unPaidPlayersCount = unpaidPlayers?.length ?? 0;
    return unpaidPlayers;
  }

  public async getCancelReasons(showInactiveRecords: boolean): Promise<CancelReason[]>
  {
    return await this._cancelReasonDataService.GetCancelReasons(showInactiveRecords);      
  }

  public async cancelTeeTimePlayers(selectedPlayersList: number[],
    courseId: number, scheduledDateTime: Date, action: string, formValue: any,
    callback: any, teeTimeConfig?: any, selectedScheduledTeeTimePlayerIds?:number[]): Promise<void> {
    const body: CancelPlayers = {
      playerIds: selectedPlayersList,
      reason: formValue.cancelComment,
      cancelReasonId: (formValue.cancelReason && formValue.cancelReason != 0) ? formValue.cancelReason :  0,
      action: action
    }
    this.CancelTeeTime(selectedPlayersList,courseId,
      scheduledDateTime,action,formValue,body, callback, teeTimeConfig, selectedScheduledTeeTimePlayerIds);
  }

  async ApplyCancellationCharges(body: CancelPlayers,
     selectedPlayersList,
    courseId: number, scheduledDateTime: Date, action: string, formValue: any,
    callback: any, teeTimeConfig?: any, selectedScheduledTeeTimePlayerIds?:number[]
  ) 
  {
    this.CancelTeeTime(selectedPlayersList,courseId, scheduledDateTime,
      action, formValue, body, callback,teeTimeConfig, selectedScheduledTeeTimePlayerIds);
  }

  async CancelTeeTime( selectedPlayersList: number[],
    courseId: number, scheduledDateTime: Date, action: string, formValue: any,body: CancelPlayers,
    callback: any, teeTimeConfig?: any, selectedScheduledTeeTimePlayerIds?:number[]): Promise<void> {
    let isCancellationProcessedcaptionShown = await this._teeTimeCancellationNoShowFeeUtilities.IsNoShowCancellationPolicyConfigured();
    await this._teeTimesActionService.cancelTeeTimePlayers(body).then(async (response) => {
      this._utilities.ToggleLoader(false);
      if (response) {
        this._utilities.showAlert( isCancellationProcessedcaptionShown? this.cancelPlayerCaption.cancellationProcessed : this.cancelPlayerCaption.cancelSuccessMessage, AlertType.WellDone, ButtonType.Ok, callback);
        this.SaveTeeTimeAllocation(selectedPlayersList, formValue, courseId, teeTimeConfig);

        // for this case, rental item deletion will happen from backend
        // since, only after payment collection, the rental items needs to deleted
        // else, normal flow
        if(await this._teeTimeCancellationNoShowFeeUtilities.IsNoShowCancellationPolicyConfigured() == false)
          this._retailEventService.deleteRentalItems(selectedScheduledTeeTimePlayerIds);
      } else {
        this._utilities.showAlert(this.cancelPlayerCaption.cancelNotAllowed, AlertType.Error, ButtonType.Ok);
      }
    });
  }

 
  async SaveTeeTimeAllocation(selectedPlayersList: number[], formValue: any,
    courseId: number, teeTimeConfig?: any) {
    let playerId = teeTimeConfig.playerDetail?.
      map(x => { return selectedPlayersList?.some(y => y == x.playerId) });
    const cancelAllSlotPlayer = playerId?.every(x => x);

    if ((formValue.allCheckBox && teeTimeConfig.viewedByStarter) ||
      (teeTimeConfig.viewedByStarter && cancelAllSlotPlayer)) {
      let teeTimeAllocationBlock = new TeeTimeAllocationBlock();
      teeTimeAllocationBlock.id = teeTimeConfig.teeGridSlotId;
      teeTimeAllocationBlock.viewedByStarter = false;
      teeTimeAllocationBlock.courseId = courseId;
      teeTimeAllocationBlock.originalHoleNumber = teeTimeConfig.originalHoleNumber;
      teeTimeAllocationBlock.allocationBlockId = teeTimeConfig.allocation?.id;
      teeTimeAllocationBlock.allocationDateTime = teeTimeConfig.allocationDateTime;
      await this._teeTimesActionService.saveTeeTimeAllocationBlock([teeTimeAllocationBlock],TeeTimeAllocationBlockEvent.FromTeeTime);
    }

  }

  public checkSelectedPlayersInCart(selectedPlayersList: number[]): boolean {
    return this._teeTimesActionBusiness.checkSelectedPlayersInCart(selectedPlayersList);
  }


  public checkSelectedPlayersHasAnyPendingTransaction(selectedPlayersList: number[], callBackfn: any, dialogRef: any): boolean {
    const isCancelAllowed = this._teeTimesActionBusiness.IsPlayersHaveAnyPendingTransactions(selectedPlayersList, this.ScheduledPlayers)
    if (isCancelAllowed) {
      this._teeTimesActionBusiness.ShowTransactionPendingDialog(selectedPlayersList, callBackfn, dialogRef);
    }
    return isCancelAllowed;
  }

  public async allowCancellationOfGolfNowPlayers(): Promise<boolean> {
     let res = await this._settingSerivice.getSettings<GolfNowConfigAPI>(
      SettingModule.SystemSetup,
      SettingScreen.GolfNowIntegration);
      return res.configValue.allowCancellationOfGolfNowBooking;
  }

  showAlertMessage(message: string, alertType: AlertType, buttonType: ButtonType, callback: any, extraParam?: any, alertHeader?: string) {
    this._utilities.showAlert(
      message,
      alertType, buttonType,
      callback, extraParam, alertHeader
    );
  }

  public getHeaderOption(): CheckboxTableHeader[] {
    return [
      { key: 'playerPosition', description: this.cancelPlayerCaption.cancelHeaderTitlePlayerPosition, alignment: 'textLeft' },
      { key: 'player', description: this.cancelPlayerCaption.cancelHeaderTitlePlayer, alignment: 'textLeft' },
      { key: 'teeTime', description: this.cancelPlayerCaption.cancelHeaderTitleTeeTime, alignment: 'textLeft' },
      { key: 'playerType', description: this.cancelPlayerCaption.cancelHeaderTitlePlayerType, alignment: 'textLeft' },
      { key: 'rateType', description: this.cancelPlayerCaption.cancelHeaderTitleRateType, alignment: 'textLeft' },
      { key: 'course', description: this.cancelPlayerCaption.cancelHeaderTitleCourseName, alignment: 'textLeft' },
      { key: 'amount', description: this._localization.replacePlaceholders(this.cancelPlayerCaption.cancelHeaderTitleAmount, ["option"], [this._localization.currencySymbol]), alignment: 'textRight' }];
  }
  async GetEmailConfigurationSetting(): Promise<API.Settings<API.EmailConfigurationSettingConfig>> {
    return this._settingService.getSettings<API.EmailConfigurationSettingConfig>(SettingModule.SystemSetup, SettingScreen.EmailConfiguration);
  }
  async validateMixedBreakPoints(breakPointNumber: number, showUserMessage: boolean, callback?: any): Promise<UserAccessModel.BreakPointResult> {
    return await this._userAccessBusiness.getUserAccess(breakPointNumber, showUserMessage, callback);

  }

  public showBreakPointError(breakPointNumber: number)
  {
    this._userAccessBusiness.showBreakPointError(breakPointNumber);
  }

  getTeeSheeetID() {
    return {
      tableID: 'TableCopy'
    };
  }

  getTeeSheetCustomTableData() {
    return {
      isMultiView: false,
      isShowAvailableOnly: true,
      isHideCrossover: true,
      isDetailView: false,
      isOrderByHoleTime: true,
      isHoleNotificationEnabled: false,
      isCommentNotificationEnabled: false,
      isActionAvailable: true,
      isMenuEnabledOnEllipsis: false,
      isPlayerDetailIconsEnabled: true,
      isDragDisabled: true,
      isBulkMoveEnabled: false,
      isTooltipDisabled: true,
      isLoadedinPopup: true
    };
  }

  public async GetAllocationBlockPermissionByRole(roleId?: string): Promise<allocationBlockPlayerTypePermission[]> {
    return await this.allocationCodeManagementService.getAllocationBlockPermissionByRole(roleId);
  }

  public async CreateCancellationNoShowLedger(cancellationNoShowLedger: CancellationNoShowLedger[])
  {
    if(cancellationNoShowLedger != null)
      return await this._teeTimesActionService.CreateCancellationNoShowLedger(cancellationNoShowLedger);
  }
}

