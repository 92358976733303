import { Component, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertType, ButtonType } from '../../shared-models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';


@Component({
  selector: 'app-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss']
})
export class AlertPopupComponent   {
  continueText = this._Localization.captions.alertPopup.continue;
  cancelText = this._Localization.captions.alertPopup.cancel;
  YES = this._Localization.captions.alertPopup.yes;
  NO = this._Localization.captions.alertPopup.no;
  okText = this._Localization.captions.alertPopup.okay;
  success = this._Localization.captions.alertPopup.success;
  warning = this._Localization.captions.alertPopup.warning;
  error = this._Localization.captions.alertPopup.error;
  wellDone = this._Localization.captions.alertPopup.welldone;
  info = this._Localization.captions.alertPopup.info;
  accessDenied = this._Localization.captions.alertPopup.accessDenined;
  overRideText = this._Localization.captions.alertPopup.overRide;
  closeAllText=this._Localization.captions.alertPopup.closeAll;
  public alertType = AlertType;
  public buttonType = ButtonType;
  productVersion: string;


  
  useSourceRate:string = "Use Source Tee Fee";
  useDestinationRate:string = "Use Destination Tee Fee";
  cancelMove:string = "Cancel";
  isCloseAll: boolean;
 
  constructor(public dialogRef: MatDialogRef<AlertPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any,private _Localization:GolfLocalization, public dialog: MatDialog) {

    let appver = sessionStorage.getItem('userProductVersion');

    this.productVersion = appver? appver :'12.2';
   
    let checkAltert=this.dialog.openDialogs.filter(x=>x.componentInstance  instanceof AlertPopupComponent)
    
    this.isCloseAll=checkAltert?.length>0;
    
  }

  DialogClose(result?: any): void {
    this.dialogRef.close(result);
  }

  DialogCloseAll(result?: any)
  {
  let checkAltert=this.dialog.openDialogs.filter(x=>x.componentInstance  instanceof AlertPopupComponent)
  if(checkAltert?.length>0)
  {
    checkAltert.forEach(x=>{
      x.close()
    })
   
  }

  }

  


}
