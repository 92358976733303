import { Injectable } from '@angular/core';
import { RetailmanagementCommunication } from 'src/app/shared/communication/services/retailmanagement.service';
import { DefaultUserConfiguration } from 'src/app/settings/utilities/manager-utilities/default-user-config/default-user-config-model';
import { PropertyApiRoutes } from 'src/app/common/common-route';
import { TenantManagementCommunication } from 'src/app/common/communication/services/tenantmanagement-communication-service';
import { DefaultUserConfigurationTenant, DefaultUserConfigurationTenantModel } from 'src/app/common/shared/shared.modal';

@Injectable({providedIn:"root"})
export class DefaultUserConfigDataService{
    constructor(private _retailmanagementCommunication: RetailmanagementCommunication, private _authenticationCommunication: TenantManagementCommunication){
    }

    public CreateDefaultUserConfiguration(defaultUserConfiguration : DefaultUserConfiguration):Promise<any>{
        return this._retailmanagementCommunication.postActualPromise({
            route: GolfApiRoute.CreateDefaultUserConfiguration,           
            body: defaultUserConfiguration
        })
    }
    
    public UpdateDefaultUserConfiguration(defaultUserConfiguration : DefaultUserConfiguration){
        return this._retailmanagementCommunication.putPromise({
            route: GolfApiRoute.UpdateDefaultUserConfiguration,           
            body: defaultUserConfiguration
        })
    }

    public GetDefaultUserConfiguration(userId: string):Promise<DefaultUserConfiguration>{
        return this._retailmanagementCommunication.getPromise({
            route: GolfApiRoute.GetDefaultUserConfiguration,           
            uriParams: {'userId' : userId}
        })
    }    

    public UpdateTenantDefaultUserConfiguration(defaultUserConfiguration : DefaultUserConfigurationTenant<DefaultUserConfigurationTenantModel>){
        return this._authenticationCommunication.putPromise({
            route: PropertyApiRoutes.UpdateDefaultTenantUserConfiguration  ,
              body: defaultUserConfiguration
        })
    }

    public GetTenantDefaultUserConfiguration(configName:string,propertyId : number,productId:number,userId: string):Promise<DefaultUserConfigurationTenant<DefaultUserConfigurationTenantModel>>{
        return this._authenticationCommunication.getPromise({
            route: PropertyApiRoutes.GetDefaultTenantUserConfiguration  ,          
            uriParams: {"configurationName" : configName ,"propertyId":propertyId,"productId":productId, 'userId' : userId}
        })
    } 
}




