import { ContactDetailListOrder } from './shared-models';

export const GOLF = 'GOLF';
export const RETAIL = 'RETAIL';
export const COMPRESSION_LIMIT = 500;
// ALLOWED_IMAGE_SIZE is the max file size allowed.
// Size in MB
export const ALLOWED_IMAGE_SIZE = 2;

export const DefaultTimeRange = {
  startTime: 9,
  endTime: 21,
  interval: 3
};

export const OVERRIDEPLAYERTYPEANDRATETYPEDAYSOUTERROR = -195165;
export const MAX_DATE_RANGE_SELECTION_GRAPHICALVIEW = 45;
export const DEFAULT_GRAPHICAL_RESET_DATE_RANGE = 0;
export const MAX_PLAYERS_PER_SLOT = 4;
export const DEFAULT_TEESHEET_REFRESH_TIME_IN_MINUTES = 5;
export const DEFAULT_GRAPHICAL_REFRESH_TIME_IN_MINUTES = 0.5;
export const DEFAULT_GUID = '00000000-0000-0000-0000-000000000000';
export const DEFAULT_TEE_SHEET_SLOT_BACKGROUND_COLOR = '#ffffff';
export const PRIMARYTEETIMEERRCODE = 30416;
export const GUID_REGEXP = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const LESSON_BOOK = 'LESSONBOOK';
export const HISTORY_DETAILS_DAYS_RANGE = 30;
export const OPEN_TEE_TIME = 1000;
export const CANCELLATION_NOSHOW_POLICY_MAPPING_OVERLAPS = 54;
export enum PaymentStatus {
  paid = 'paid',
  unpaid = 'unpaid'
}

export enum ButtonAction {
  save = 'save',
  update = 'update',
  process = 'process',
  convert = 'convert',
  confirm = 'confirm',
  pay = 'pay',
  print = 'print',
  reinstate = 'reinstate',
  login = 'login',
  markAsPaid = 'markAsPaid',
  noShow = 'noShow',
  unMarkNoShow = 'unMarkNoShow',
  checkIn = "checkIn",
  link = "link",
  unlink = "unlink"
}

export const enum SettingModule {
  SystemSetup = 'SYSTEMSETUP',
  Utilities = 'UTILITIES'
}

export const enum SettingScreen {
  TeeTime = 'TEETIMESETTING',
  Defaults = 'DEFAULTSSETTING',
  rGuestBook = 'RGUESTBOOKSETTING',
  Tournament = 'TOURNAMENTSETTING',
  GolfSetting = 'GOLFSETTING',
  EmailConfiguration = 'EMAILCONFIGURATIONSETTING',
  QuickIdConfigSetting = 'QUICKIDCONFIGSETTING',
  AdmIntegration = 'ADMINTEGRATIONSETUP',
  PMSIntegration = 'PMSINTEGRATIONSETUP',
  GolfNowIntegration = 'GOLFNOWINTEGRATIONSETUP'
}
export const enum CacheConstants {
  DefaultSettings = 'defaultSettings',
  TeeTimeSetting = 'TEETIMESETTING',
  GolfSetting = 'GOLFSETTING',
}
export const enum PropertyConfigurations {
  ApplicationFormat = 'APPLICATIONFORMAT'
}

export const enum TenantConfigurations {
  TenantConfiguration = 'TENANTCONFIGURATION'
}

export const enum GolfSessionKey {
  PmsRequestInfo = 'PMSRequestInfo',
  GolfNowEnabled = 'golfNowEnabled',
  IsCustomFeeEnabled = 'isCustomFeeEnabled',
  IsNoShowCancellationPolicyConfigured = 'IsNoShowCancellationPolicyConfigured',
  TEETIMESETTING = "TEETIMESETTING",
  NoOfDecimalDigits = "noOfDecimalDigits",
  BackgroundServiceTeeActionCompleted = "BackgroundServiceTeeActionCompleted"
}

export enum PatchOperation {
  add = 'add',
  remove = 'remove',
  replace = 'replace',
  copy = 'copy',
  move = 'move',
  test = 'test'
}

export enum FromAction {
  TeeGrid,
  Cancellation,
  BulkCancellation,
  NoShow,
  DayEnd,
  DepositRefund,
  RetailTransaction,
  PendingSettlement,
  None
}

export enum CheckBoxTableTemplate {
  inputText = 'inputText',
  fieldWithPopover = 'fieldWithPopover',
  playerdropdown = 'playerdropdown',
  ratedropdown = 'ratedropdown',
  negotiate = 'negotiate'
}

export enum GuaranteeType {
  None = 'None',
  Member = 'Member',
  Resort = 'Resort',
  CreditCard = 'Credit Card'
}

export interface PatchJson {
  op: PatchOperation;
  path: string;
  value: any;
}


// supported languages
export const Languages = [
  { id: 1, value: 'English', code: 'en-US' },
  { id: 2, value: 'Danish', code: 'da-DK' },
  { id: 3, value: 'French', code: 'fr-FR' },
  { id: 4, value: 'Finnish', code: 'fi-FI' }
];

export enum playerTypes {
  newPlayer = 1,
  hotelReservation = 2,
  member = 3,
  player = 4,
  guest = 5
}

export enum HotelReservationTypes {
  guestName = 0,
  confirmationNumber = 1,
  roomNumber = 2,
  linkCode = 3,
  contactDetail = 4
}

export enum ExistingPlayerTypes {
  firstName = 0,
  lastName = 1,
  fullName = 2,
  email = 3,
  phoneNumber = 4
}

export enum MemberSearchParams {
  firstName = 1,
  lastName = 2,
  memberNo = 3,
  phoneNumber = 4,
  email = 5,
  hotelReservationNo = 6
}
export enum PlayersSearchGuestTab {
  firstName = 0,
  lastName = 1,
  fullName = 2,
  email = 3,
  phoneNumber = 4,
  patronId = 5
}

export enum Gender {
  none = 0,
  male = 1,
  female = 2,
  junior = 3
}

export enum ContactType {
  email = 'Email',
  phone = 'Phone'
}

export enum PhoneTypes {
  mobile = 1,
  home = 2,
  office = 3,
  business = 12,
  work = 13
}

export enum MailTypes {
  personal = 9,
  office = 10,
  home = 14,
  business = 15
}

export enum DisplayCustomField {
  notDisplayed = 1,
  onTeeTime = 2
}
export enum TempHoldStatus {
  hold = 1,
  release = 2
}

declare global {
  interface Window {
    PasswordCredential: any;
    FederatedCredential: any;
  }
}

export const ALLOWED_URL: Array<any> = [false, 'report'];

export enum RentalAgreement {
  CartAgreement = 1,
  ClubAgreement = 2,
  ShoeAgreement = 3,
  RetailSaleChit = 4
}

export enum BookingSource {
  rGuestGolf = 0,
  rGuestBook = 1,
  Engage = 2,
  ACR = 3,
  GolfNow = 4
}

export const enum Product {
  SPA = 1,
  RETAIL = 2,
  GOLF = 3
}

export const enum PatronInfoSearchResultType {
  EDITEXISTINGPATRON = 0,
  PATRONNOTFOUND,
  PATRONFOUND,
  UPDATECMSDATAONEXISTING
}

export const PhoneDetailListOrder: ContactDetailListOrder[] = [
  { type: PhoneTypes.mobile, listOrder: 1 },
  { type: PhoneTypes.home, listOrder: 2 },
  { type: PhoneTypes.office, listOrder: 3 }
];

export const EmailDetailListOrder: ContactDetailListOrder[] = [
  { type: MailTypes.personal, listOrder: 1 },
  { type: MailTypes.office, listOrder: 2 }
];

export const RuleCodes = {
  CADDY_TYPE_REQUIREMENT_FAILED: 30429
};

export enum InitiateSearch {
  roomNumber = 1,
  default = 3,
  confirmationNumber = 3
}

export const enum ElasticApm {
  UseApmServer = 'UseApmServer',
  ApmServerUrl = 'ApmServerUrl',
  DistributedTracingOrigins = 'DistributedTracingOrigins',
  ServiceName = 'Golf-UI',
  UseLogViewer = 'UseLogViewer'
};

export const enum LessonBookingStatus {
  Booked = 1,
  Blocked,
  Cancelled
};

export const enum PlayerAdditionalDetailsEnum {
  MemberAccountNumber = 1,
  CartConfirmationNumber = 2,
  MemberCorpId = 3,
  DiscountType = 4
}

export const enum ExternalPMSSystem {
  Stay = "stay",
  LMS = "lms"
}


export const enum TeetimeTabTypes {
  crossover = "crossover",
  interval = 'interval',
  shortgun = 'shortgun'
}

export enum TournamentSearchParameters {
  EventCode = 0,
  EventName = 1,
  EventCourse = 2
}

export enum RetailInterfaceSourceTypes {
  RETAIL = 0,
  SPA = 1,
  GOLF = 2,
  SNC = 4,
  PMS = 5,
  ACCOUNTING = 6,
  EXTERNALSYSTEM = 7
}
export enum AutoRetailTransactionType {
  SPAAppoinmentCheckout = 1,
  GolfTeeTimeCheckout = 2,
  RetailShopSettleOpenTransaction = 3,
  RetailShopCreateTransaction = 4,
  RetailShopReturnWithTicketTransaction = 5,
  RetailShopReturnWithOutTicketTransaction = 6,
  RetailShopVoidTransaction = 7,
  DepositRefund = 8,
  GolfMarkAsPaidCreateTransaction = 9,
  GolfMarkAsPaidSettleTransaction = 10,
  GolfMarkAsRefundTransaction = 11
}
