export interface viewRefundDeposit {
    id: number;
    checked: boolean;
    ticket: string;
    playerName: string[];
    depositCollected: number;
    depositCollectedString: string;
    transactionDate: string;
    playerType: string;
    rateType: string;
    packageCode: string;
    remainingAmount: number;
    remainingAmountString: string;
    refundedAmount: number;
    refundedAmountString: string;
    playerId: number[];
    type: viewBy;
  }

  export enum viewBy{
    byTicket = 0,
    byPlayer = 1,
}