<section class='create-staff-reader-board-wrapper ag_height--100'>
    <form class='ag_container--padding-md ag_display--flex' [formGroup]='createStaffReaderForm' autocomplete="off">
        <div class="roles-section ag_container--padding-md">
            <div>
                <label class='ag_form--label'>{{captions.lbl_notesentTo}}</label>
                <mat-radio-group class='ag_form-control--2' formControlName="mode" [attr.automationId]="'Rdo_createStaffReaderBoard_mode'">
                    <mat-radio-button class='ag_display--inblock  ag_form--radio-button' [value]='opt.value'
                        *ngFor="let opt of modeOptions" (change)="modeChange($event)">
                        {{opt.viewValue}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div *ngIf="selectedMode == 1" class="ag_height--100">
                <app-chip-search [maxChips]="10" class="ag_form-control--sm" [allData]="rolesData"
                    [searchKey]="searchKey" [searchMaxCharLength]="10" [autoCompleteKeys]="autoCompleteKeys"
                    [selectedChipKey]="selectedChipKey" (searchTextEmit)="SearchRolesTextHandler($event)"
                    (selectedChipDataEmit)="selectedRolesChipDataEmit($event)"
                    [placeholderText]="captions.lbl_searchbyroles" automationId='createStaffReaderBoard{{selectedMode}}'></app-chip-search>
                <div class="selectedRoleData">
                    <div *ngFor="let x of selectedRoleData;let i=index;" class="ag_pb--6">
                        <div class="ag_display--flex-wrap align-items-baseline">
                            <h4 class="ag_group--headers">{{x.parentName}}</h4>
                            <app-simple-search class='ag_pl--2' *ngIf="x.data?.length>0" [placeholder]="captions.lbl_searchbyusers"
                                (searchChange)="searchUser($event,x)" automationId='createStaffReaderBoard{{i}}'></app-simple-search>
                            <h4 class="ag_group--headers ag_link custom-color ag_pl--4 ag_ml--auto" (click)="selectUnselectUser(x)">
                                {{x.label}}</h4>
                        </div>
                        <div class="ag_display--flex-wrap">
                           <span  *ngIf="x.data?.length == 0" > {{captions.lbl_nousersfound}}</span>
                            <div *ngFor='let category of x.data;let j=index'>
                                <mat-checkbox *ngIf="category?.isVisible || (category.isVisible==undefined) "
                                    class='ag_display--block ag_pr--3 checkbox-align text-ellipsis'
                                    [checked]="category.selected" [matTooltip]="category.name"
                                    (change)='userChangeEvent($event,category,x)' attr.automationId='Chk_createStaffReaderBoard_category{{j}}'>
                                    {{category.name}}
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="no-data-img" *ngIf="selectedRoleData.length == 0">
                        <img src="./assets/images/SearchIllustration.png" alt="No Data">
                        <span class="ag_mt--2">{{captions.lbl_searchandaddusers}}</span>
                    </div>
                </div>
            </div>

            <div *ngIf="selectedMode == 2">
                <app-chip-search [maxChips]="10" class="ag_form-control--sm" [allData]="userData"
                    [searchKey]="searchKey" [searchMaxCharLength]="10" [autoCompleteKeys]="autoCompleteKeys"
                    [selectedChipKey]="selectedChipKey" (searchTextEmit)="SearchTextHandler($event)"
                    (selectedChipDataEmit)="selectedChipDataEmit($event)" automationId='createStaffReaderBoard{{selectedMode}}'
                    [placeholderText]="captions.lbl_searchbyusers"></app-chip-search>

                <div *ngIf="selectedData.length == 0" class="user-no-data">
                    <div class="no-data-img">
                        <img src="./assets/images/SearchIllustration.png" alt="No Data">
                        <span class="ag_mt--2">{{captions.lbl_searchandaddusers}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="users-section ag_height--100 ag_ml--5 ag_container--padding-md">
            <h4 class="ag_group--headers">{{captions.lbl_addedusers}}</h4>
            <div class="ag_display--flex-wrap height-align">
                <div class="ag_display--flex-wrap">
                    <div *ngFor="let x of selectedData;let i=index" class="ag_mr--3">
                        <div class="expenseBackground  ag_display--flex-wrap">
                            <span class=" ag_mr--3 selected-data text-ellipsis"
                                [matTooltip]="x.receivedByUserName">{{x.receivedByUserName}}</span>
                            <i class='icon-close ag_ml--auto ag_cursor--pointer closeicon' aria-hidden="true"
                                (click)="removeUser(x,i)" attr.automationId='Icn_createStaffReaderBoard_remove{{i}}'>&nbsp;</i>
                        </div>
                    </div>
                    <div class="no-data-img" *ngIf="selectedData.length == 0">
                        <img src="./assets/images/Summary_Illustration.png" alt="No Data">
                        <span class="ag_mt--2">{{captions.lbl_noaddusers}}</span>
                    </div>
                </div>
                
            </div>
        </div>

        <div class="note-details-section ag_height--100 ag_ml--5 ag_container--padding-md"
            [ngClass]="{'ag_section--disable': this.selectedData.length == 0 }">
            <h4 class="ag_group--headers detail-header">{{captions.lbl_notedetails}}</h4>
            <mat-form-field [floatLabel]="floatLabel" class="txt-area-height ag_w--100">
                <textarea matInput formControlName="notedetails" [attr.automationId]="'Ta_createStaffReaderBoard_notedetails'"
                    [placeholder]="captions.lbl_createnotelabel"></textarea>
            </mat-form-field>
            <div class="ag_pt--3">
                <ag-dropdown class="ag_form-control--1" [placeholder]="captions.lbl_priority" [source]="priorityList"
                    formControlName="priority" [automationId] = "'Dd_createStaffReaderBoard_priority'">
                </ag-dropdown>
            </div>
            <div class="">
                <label class="ag_mb--2">{{captions.lbl_shownote}}</label>
                <app-ag-toggle [toggleInputs]="showNoteToggleInput"></app-ag-toggle>
            </div>
            <div class="ag_display--flex-wrap">
                <app-ag-date-picker class="" [inputs]="popupValidFromDateInput"
                    (datePickerChange)='startDateChange($event)'>
                </app-ag-date-picker>
                <app-ag-time-picker class ='' [inputs]="popupValidFromTimeInput" (timeChangeEvent)="timeChanged($event, 'fromTime')"> 
                </app-ag-time-picker>

            </div>
            <div class="ag_display--flex-wrap">
                <app-ag-date-picker class="" [inputs]="popupValidToDateInput"
                    (datePickerChange)='endDateChange($event)'>
                </app-ag-date-picker>
                <app-ag-time-picker class ='' [inputs]="popupValidToTimeInput" (timeChangeEvent)="timeChanged($event, 'toTime')"> 
                </app-ag-time-picker>

            </div>
        </div>

    </form>
    <div class='ag_footer--actions ag_display--flex'>
        <app-button [buttontype]='actionButton' [attr.automationId]="'Btn_createStaffReaderBoard_create'" (valueChange)='onAction($event)'></app-button>
        <app-button [buttontype]='cancelButton' [attr.automationId]="'Btn_createStaffReaderBoard_cancel'" (valueChange)='onCancel($event)'></app-button>
    </div>
</section>