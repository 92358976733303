<section class="createTerminalPopUp h-100">
  <form [formGroup]="outletTerminalFormGrp" autocomplete="off" class="outletTerminalForm">
    <div class="d-flex h-100">
      <div class="left-section p-4">
        <ng-scrollbar [autoHide]="true">
          <div class="">
            <mat-form-field [ngClass]="isTerminalInUse ? 'button_invalid' : ''" [floatLabel]="floatLabel">
              <mat-select [attr.automationId]="'Dd_createOutletTerminal_outletName'" [placeholder]="captions.OutletName" name="outlet" formControlName="outlet" required [disabled]="isTerminalInUse ? 'button_invalid' : 'false'">
                <mat-option [value]="value.subPropertyID" *ngFor="let value of activeOutlets"
                  (click)="OutletSelectionChange(value)">{{value.subPropertyName}}</mat-option>
              </mat-select>
              <mat-error *ngIf="outletTerminalFormGrp.controls['outlet'].invalid">
                {{captions.MissingOutlet}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="pt-3">
            <mat-form-field class="button_invalid" [floatLabel]="floatLabel">
              <input [attr.automationId]="'Txt_createOutletTerminal_storeId'" matInput [placeholder]="captions.StoreID" name="storeid" formControlName="storeid" readonly
                inputtype="nospecailchar" />
            </mat-form-field>
          </div>

          <div class="pt-3">
            <mat-form-field [floatLabel]="floatLabel">
              <input [attr.automationId]="'Txt_createOutletTerminal_terminalName'" matInput [placeholder]="captions.TerminalName" name="terminalname" class="text-capitalize"
                formControlName="terminalname" maxlength="50" required />
              <mat-error *ngIf="outletTerminalFormGrp.controls['terminalname'].invalid">
                {{captions.MissingTerminalName}}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="pt-3">
            <mat-form-field [ngClass]="isTerminalInUse ? 'button_invalid' : ''" [floatLabel]="floatLabel">
              <input [attr.automationId]="'Txt_createOutletTerminal_terminalId'" matInput [placeholder]="captions.TerminalId" name="terminalid" formControlName="terminalid"
                maxlength="10" inputtype="nonnegative" required  [readonly]="isTerminalInUse ? 'button_invalid' : 'false'"/>
              <mat-error *ngIf="outletTerminalFormGrp.controls['terminalid'].invalid">
                {{captions.MissingTerminalID}}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="pt-3" [ngClass]="isTerminalInUse ? 'button_invalid' : ''">
            <label class="form-label-normal toggle-label d-block">{{captions.Active}}</label>
            <app-retail-toggle-switch [attr.automationId]="'Txt_createOutletTerminal_isActive'" class="toggle-switch" formControlName="isactive"></app-retail-toggle-switch>
          </div>
        </ng-scrollbar>
      </div>

      <div class="p-4 right-section">
        <label for="taxIn" class="LW16 blckClr">{{captions.POSConfiguration}}</label>
        <div class="pt-3">
          <mat-form-field [floatLabel]="floatLabel">
            <input [attr.automationId]="'Txt_createOutletTerminal_userId'" matInput [placeholder]="captions.UserID" name="userid" maxlength="50" formControlName="userid"
              inputtype="nospecailchar" />
          </mat-form-field>
        </div>
        <div class="pt-3">
          <mat-form-field [floatLabel]="floatLabel">
            <input [attr.automationId]="'Txt_createOutletTerminal_password'" matInput [placeholder]="captions.Password" name="password" maxlength="50"
              formControlName="password" />
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
  <div mat-dialog-actions class="actions-div">
    <div class="pop-button pl-4">
      <button [attr.automationId]="'Btn_createOutletTerminal_save'"
        [ngClass]="( outletTerminalFormGrp.valid && outletTerminalFormGrp.dirty && outletTerminalFormGrp.controls.storeid.value) ? 'button--primary-save' : 'button--disabled'"
        mat-button [disabled]="!outletTerminalFormGrp.valid && outletTerminalFormGrp.dirty"
        (click)="saveOutletTerminal(outletTerminalFormGrp.value)">{{clickbutton}}</button>
      <button [attr.automationId]="'Btn_createOutletTerminal_cancel'" class="spa-button-cancel" mat-button (click)="onCancel()">{{allCaptions.setting.cancel}}</button>
    </div>
  </div>
</section>
