import { Injectable } from '@angular/core';
import { PrintModalService } from './print-modal-service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { ReportDownloadFormat } from 'src/app/reports/report.model';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { printModel } from './print-model';
import { AlertType, ButtonType } from 'src/app/shared/shared-models';
import { ReceiptBusinessService } from 'src/app/retail/shop/receipt/business/receipt-business.service';
import * as GlobalConst from 'src/app/retail/shared/globalsContant';
import { PaymentBusinessService } from 'src/app/shared/data-services/payment/payment-business.service';
import { TeeTicketBusiness } from 'src/app/reports/business/golfReports/teetime/tee-ticket.business.service';
import { RetailBussinessService } from 'src/app/shared/retail.bussiness';
import { RetailDataAwaiters } from 'src/app/retail/shared/events/awaiters/retail.data.awaiters';
import { GolfPoints } from 'src/app/retail/shared/business/shared.modals';
import { PlayerCategory} from 'src/app/common/enums/shared-enums';
import { CartCardBusiness } from 'src/app/reports/business/golfReports/teetime/cart-card.business.service';

@Injectable()
export class PrintModalBusiness {
	captions: any;
	printFormat: ReportDownloadFormat = 'PDF';

	constructor(
		private _PrintModalService: PrintModalService,
		private _localization: GolfLocalization,
		private _utilities: GolfUtilities,
		private receiptService: ReceiptBusinessService,
		private _paymentBusinessService: PaymentBusinessService,
		private _retailService: RetailBussinessService,
		private _teeTicketBusiness: TeeTicketBusiness,
		private _cartCardBusiness: CartCardBusiness
	) {
		this.captions = this._localization.captions.reports;
	}

	getprintOptions(): printModel[] {
		return this._PrintModalService.getPrintOptions();
	}

	printTeeTickets(
		courseIds: number[],
		scheduleTeeTimeId: number,
		dateTime: string,
		scheduledTeeTimeIds: number[],
		playerIds: number[],
		callBack: any
	) {
		if (scheduledTeeTimeIds && !scheduledTeeTimeIds.some((r) => r === scheduleTeeTimeId)) {
			scheduledTeeTimeIds.push(scheduleTeeTimeId);
		}
		this._teeTicketBusiness.printTeeTickets(courseIds, scheduledTeeTimeIds, playerIds, dateTime, false,() => {
			this._utilities.showAlert(this.captions.successfullyPrinted, AlertType.WellDone, ButtonType.Ok, callBack);
		});
	}

	printCartCards(
		courseName: string,
		courseId: number,
		scheduleTeeTimeId: number,
		dateTime: string,
		scheduledTeeTimeIds: number[],
		isPrintLandscape: boolean,
		callBack: any
	) {
		if (scheduledTeeTimeIds && !scheduledTeeTimeIds.some((r) => r === scheduleTeeTimeId)) {
			scheduledTeeTimeIds.push(scheduleTeeTimeId);
		}
		this._cartCardBusiness.printCartCards(courseName, courseId, scheduledTeeTimeIds, dateTime, isPrintLandscape, () => {
			this._utilities.showAlert(this.captions.successfullyPrinted, AlertType.WellDone, ButtonType.Ok, callBack);
		});
	}
	printBagTags(
		courseIds: number[],
		playerIds: number[],
		playerLinkIds: string[],
		startDate: string,
		endDate: string,
		callBack: any)
	{
		this._cartCardBusiness.printBagTags(courseIds, playerIds, playerLinkIds, startDate, endDate, () => {
			this._utilities.showAlert(this.captions.successfullyPrinted, AlertType.WellDone, ButtonType.Ok, callBack);
		});
	}

	async printReceipt(transactionId: number, playerId?: number[], callBack?: any) {
		let transactionDetail = await this._paymentBusinessService.GetTransactionById(transactionId);
		let ticketNumber: string = transactionDetail.ticketNumber;
		let clientName: string = '';
		let isHotelGuest  : boolean =false;
		let outletId: number = 0;
		outletId = transactionDetail && transactionDetail.outletId;


		this._retailService.printAgreements(transactionId, playerId);
		var availableRounds = 0;
		var IsMemberTransaction = false;
		if (transactionDetail && transactionDetail?.guestId) {
			let clientInfo = await this._retailService.GetPlayerById(transactionDetail.guestId);
			if (clientInfo && clientInfo?.name && clientInfo?.playerCategoryId) {
				clientName = clientInfo.name;
				isHotelGuest = (clientInfo.playerCategoryId == PlayerCategory.HotelReservation)
			}
		}
		if (transactionDetail && transactionDetail.memberId && transactionDetail.memberId!="0") {
			let memInfo = await RetailDataAwaiters.getMemberInfo(
				transactionDetail.memberId,
				new Date(transactionDetail.transactionDate).toISOString()
			);
			if (memInfo && memInfo?.golfPoints) {
				const uniqueBucketCode: GolfPoints[] = [];
				memInfo.golfPoints.forEach((value, key) => {
					if (uniqueBucketCode.find((x) => x.bucketCode == value.bucketCode) == undefined) {
						uniqueBucketCode.push(value);
						availableRounds += value.eligibleRounds;
					}
				});
				IsMemberTransaction = true;
			}
			if (memInfo && memInfo?.name) clientName = memInfo.name
		}
		this.receiptService.GenerateReceipt(
			ticketNumber,
			outletId,
			GlobalConst.ReceiptType.sales,
			transactionId,
			transactionDetail.memberId,
			clientName,
			undefined,
			false,
			false,
			undefined,
			() => {
				this._utilities.showAlert(
					this.captions.successfullyPrinted,
					AlertType.WellDone,
					ButtonType.Ok,
					callBack
				);
			},
			false,
			IsMemberTransaction,
			availableRounds,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			isHotelGuest
		);
	}
}
