<div class="dialog-container">
    <div mat-dialog-title class="dialog-container__header">
      <span class="dialog-container__header--title">{{captions.confirmPlayers}}</span>
      <span attr.automationId='Btn_confirmPlayers_Close' class="dialog-container__header--close icon-Cancel" (click)="cancel()"></span>
    </div>
    <div class="dialog-container__content ag_container--padding-md" [formGroup]="guestTypeForm">
     <label>{{captions.pleaseSelectProfile}}</label>
     <mat-radio-group class="ag_display--block" formControlName="guestType" (change)="guestTypeChange($event)">
        <mat-radio-button class="golf--form-radio-button"
            *ngFor="let guest of guestTypes" [value]="guest.id">
            {{guest?.description}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <mat-dialog-actions class="dialog-container__actions">
        <app-golfbutton class="" [buttontype]="applyButton" (valueChange)="apply()"></app-golfbutton>  
        <app-golfbutton class="ml-3" [buttontype]="cancelButton" (valueChange)="cancel()"></app-golfbutton>  
    </mat-dialog-actions>
  </div>