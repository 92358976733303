import { Component, OnInit, Inject, ComponentFactoryResolver, ViewChild, ViewContainerRef, ViewEncapsulation, OnDestroy,  ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StepperService } from '../stepper/stepper.service';
import { Subscription } from 'rxjs';
import { DialogCloseObj, TeeTimeActions } from '../../shared-models';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';


@Component({
  selector: 'app-common-popup',
  templateUrl: './common-popup.component.html',
  styleUrls: ['./common-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CommonPopupComponent implements OnInit, OnDestroy {
  @ViewChild('vc', { static: true, read: ViewContainerRef }) vc: ViewContainerRef;
  @ViewChild('backButton', { static: true, read: ViewContainerRef }) backButton: ElementRef;
  value: boolean;
  componentName: any;
  enableCancel: boolean;
  text = 'next';
  componentSubscription$: Subscription = new Subscription();
  backSubscription$: Subscription = new Subscription();
  dialogObj: DialogCloseObj;
  prevClickThrottleTime: number;
  nextClickThrottleTime: number;
  saveClickThrottleTime: number;
  teeTimeAction = TeeTimeActions;
  backEnable = false;
  captions: any;
  isFormValid = false;
  automationId:string="";


  constructor(public dialogRef: MatDialogRef<CommonPopupComponent>, private _Localization: GolfLocalization,
    @Inject(MAT_DIALOG_DATA) public dialogData,
    public cf: ComponentFactoryResolver, public stepperService: StepperService,private _Utilities:GolfUtilities) {
      this.captions = this._Localization.captions;
      this.automationId = this.dialogData?.automationId ? this.dialogData?.automationId:'';
      this.stepperService.linkButtonText = dialogData?.buttonText;
    }

  ngOnInit() {
    this.loadComponent();
    if (this.dialogData.componentDetails.popUpDetails.isStepper) {
      this.stepperService.selectedIndex = 0;
      this.stepperService.enablePrevious = false;
      this.enableCancel = true;
      this.stepperService.enableNext = false;
      this.stepperService.stepperNextEnable.subscribe(value =>
        this.stepperService.enableNext = value
      );
    }
    this.backSubscription$ = this.stepperService.stepperBackEnable.subscribe(value => {
      console.log(value);
      this.backEnable = value;
    });
    this.prevClickThrottleTime = this.dialogData.componentDetails.popUpDetails.prevClickThrottleTime ? this.dialogData.componentDetails.popUpDetails.prevClickThrottleTime : null;
    this.nextClickThrottleTime = this.dialogData.componentDetails.popUpDetails.nextClickThrottleTime ? this.dialogData.componentDetails.popUpDetails.nextClickThrottleTime : null;
    this.saveClickThrottleTime = this.dialogData.componentDetails.popUpDetails.saveThrottletime ? this.dialogData.componentDetails.popUpDetails.saveThrottletime : null;
  }

  loadComponent() {
    this.componentName = this.dialogData.componentDetails.componentName;
    const componentFactory = this.cf.resolveComponentFactory(this.componentName);
    const componentRef = this.vc.createComponent(componentFactory);
    const eventSpecified = this.dialogData.componentDetails.popUpDetails.eventName;
    if (componentRef.instance[eventSpecified]) {
      this.componentSubscription$ = componentRef.instance[eventSpecified].subscribe((val) => {
        if (this.stepperService.isSaveSubmitDisabled){
          this.isFormValid = val;
          this.stepperService.enableSave = val && !this.stepperService.isSubmitted;
        } else {
          this.stepperService.enableSave = val;
        }

      });
    }
  }

  close() {
    if (this.dialogData.disableClose) {
      this.dialogObj = {
        type: 'close',
        dialogRef: this.dialogRef,
        selectedIndex: this.stepperService.selectedIndex
      };
      this.stepperService.valueChange.emit(this.dialogObj);
    } else {
      this.dialogRef.close('close');
      this.stepperService.selectedIndex = 0;
    }


  }

  save(e: string) {
    if (this.isFormValid){
      this.stepperService.isSubmitted = true;
    }

    this.dialogObj = {
      type: e.toLowerCase(),
      dialogRef: this.dialogRef,
      selectedIndex: this.stepperService.selectedIndex
    };
    this.stepperService.valueChange.emit(this.dialogObj);

  }
  clickAction(e: string) {
    this.dialogObj = {
      type: e.toLowerCase(),
      dialogRef: this.dialogRef,
      selectedIndex: this.stepperService.selectedIndex
    };
    this.stepperService.valueChange.emit(this.dialogObj);

  }
  stepChange(selectedBtn) {
    this.dialogObj = {
      type: selectedBtn,
      dialogRef: this.dialogRef
    };
    this.stepperService.stepperChange.emit(this.dialogObj);


  }

  back(){
    this.dialogObj = {
      type: 'backClick',
      dialogRef: this.dialogRef
    };
    this.stepperService.valueChange.emit(this.dialogObj);
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    if (this.componentSubscription$) {
      this.componentSubscription$.unsubscribe();
    }
    if (this.backSubscription$) {
      this.backSubscription$.unsubscribe();
    }
  }

}
