import {Inject, Component, OnInit } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StepperService } from 'src/app/shared/components/stepper/stepper.service';
import { BlockTeeTimeBusiness } from './block-tee-time.business';
import { SlotType, TeeTimePlayer } from './block-tee-time.model';
import { ButtonAction } from 'src/app/shared/global.constant';
import { takeUntil } from 'rxjs/operators';
import { DialogCloseObj } from 'src/app/shared/shared-models';
import { ReplaySubject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Localization } from "src/app/common/localization/localization";

@Component({
  selector: 'app-block-tee-time',
  templateUrl: './block-tee-time.component.html',
  styleUrls: ['./block-tee-time.component.scss'],
  providers: [BlockTeeTimeBusiness]
})

export class BlockTeeTimeComponent implements OnInit {

  time: string;
  data: any;
  slotType = SlotType.selectedSlot;
  slotTypeConst = SlotType;
  captions: any;
  bookedPlayers: TeeTimePlayer[];
  blockablePlayers: TeeTimePlayer[];
  players: TeeTimePlayer[];
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  blockTeeTimeform:UntypedFormGroup;
  floatLabel: string;
  
  constructor(
    private blockTeeTimeBusiness: BlockTeeTimeBusiness,
    public dialogRef: MatDialogRef<BlockTeeTimeComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private formBuilder: UntypedFormBuilder,
    public stepperService: StepperService,
    private _localization: Localization
  ) {
    console.log(this.dialogData);   
    this.floatLabel = this._localization.setFloatLabel;
  }

  ngOnInit() {
    this.blockTeeTimeform = this.formBuilder.group({
      bulkBlockHoldComment: ''
    });
    this.data = this.dialogData;
    this.captions = this.blockTeeTimeBusiness.captions;
    this.time = this.data.info.time;
    this.bindPlayer();
    this.enableSave();
    this.stepperService.valueChange.pipe(takeUntil(this.destroyed$)).subscribe((data: DialogCloseObj) => {
      //save goes here
      if(data.type == ButtonAction.save){
        this.blockTeeTimeBusiness.saveBlockedSlots(this.dialogData.info, this.players,this.blockTeeTimeform.get('bulkBlockHoldComment')?.value).then(y=> {
          if(y){
            data.dialogRef.close(data.type);
          }          
        });
      }
    })
  }

  slotSelectionTypeChange() {
    if(this.slotType == SlotType.allSlot) {
      this.players.filter(x=> x.playerId === 0).forEach(x => x.selected = true);
    } else {
      this.players.filter(x=> x.playerId === 0).forEach(x => x.selected = false);
    }
    this.enableSave();
  }

  selectUnselectPlayer(event, index) {
    const playerInfo = this.players[index];
    playerInfo.selected = !playerInfo.selected;
    this.enableSave();
  }

  bindPlayer() {
    this.players = this.blockTeeTimeBusiness.SetBookedPlayer(this.data.info.playerDetail);
    this.dialogData.playerInfo = this.players;
  }

  enableSave() {
    this.stepperService.enableSave = this.players.filter(x=> (x.playerId === 0 && x.selected===true)).length > 0;
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

}
