import { Injectable } from '@angular/core';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';
import { TeeTimeSearchData, TeeTimeFilterData } from 'src/app/tee-time/search/search-model';
import { TeeTimeDetail } from '../../models/teesheet.api.models';

@Injectable()
export class TeetimeSearchDataService {

    constructor(private _http: GolfScheduleCommunication) {

    }  
    async GetTeeTimesScheduleSearch(_courseId:number,_playerstatus:number,_fromDate:Date|string,_todate:Date|string,_filter: TeeTimeFilterData[], pmsLinkedActivityId: string = "", isChangePlayerCategory : boolean): Promise<TeeTimeSearchData[]> {
      
        return await this._http.putPromise<TeeTimeSearchData[]>({
            route: GolfApiRoute.GetTeeTimesScheduleSearch,
            uriParams: { courseId:_courseId,playerstatus:_playerstatus,fromDate:_fromDate,toDate:_todate, pmsActivityId:pmsLinkedActivityId , isChangePlayerCategory : isChangePlayerCategory},
            body:_filter
        }); 
    }
    async GetTeeTimeDetails(_courseId:number,_playerId:number,_date:Date|string):Promise<TeeTimeDetail>
    {
        return await this._http.getPromise<TeeTimeDetail>({
            route:GolfApiRoute.GetTeeTimesDetails,
            uriParams:{courseId:_courseId,playerId:_playerId,date:_date}
        });
    }
}