import { BaseValidator } from 'src/app/shared/utilities/BaseValidator';
import { TournamentPlayer } from 'src/app/shared/models/tournamentplayer.model';
import { PlayerType } from 'src/app/settings/golf-setup/code-setup/player-type/player-type.modal';
import { RateType } from 'src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal';
import { GuaranteeTypes } from 'src/app/shared/shared-models';
import { playerTypes } from '../../tee-time/tournaments/tournaments-modal/players/players-model';
import { Injectable } from '@angular/core';
import { GolfUtilities } from './golf-utilities';
import { ScheduledTeeTimeTimePlayer } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { ScheduledLessonPlayerInformation } from '../models/lessons.modal';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Injectable({
    providedIn: 'root'
})
export class GuaranteeTypeValidator extends BaseValidator<TournamentPlayer | ScheduledTeeTimeTimePlayer | ScheduledLessonPlayerInformation> {

    private _rateType: RateType;
    private _playerType: PlayerType;
    private _playerAddedFrom: PlayerAddedFrom;
    private _errorMessage: string;
    private _errorCode: number;

    private GuaranteeErrors =
        {
            PlayerType_Guarantees_Member: this._localization.getError(14001),
            PlayerType_Guarantees_Resort: this._localization.getError(14002),
            PlayerType_Guarantees_CreditCard: this._localization.getError(14003),
            RateType_Guarantees_Member: this._localization.getError(14004),
            RateType_Guarantees_Resort: this._localization.getError(14005),
            RateType_Guarantees_CreditCard: this._localization.getError(14006),
            PlayerType_Guarantees_MemberCreditCard: this._localization.getError(14007),
            RateType_Guarantees_MemberCreditCard: this._localization.getError(14008)
        }

    constructor(private _utils: GolfUtilities, private _localization: GolfLocalization) {
        super();
        this.buildRules();
    }

    public getErrorMessage(): string {
        return this._errorMessage;
    }

    public getErrorCode(): number {
        return this._errorCode;
    }

    /**
     *Initialize data to validate
     *
     * @param {PlayerType} playerType - player type assigned to player
     * @param {RateType} rateType - player type assigned to player
     * @param {PlayerAddedFrom} from - player added from
     * @memberof GuaranteeTypeValidator
     */
    public initialize(playerType: PlayerType, rateType: RateType, from: PlayerAddedFrom) {
        this._playerType = playerType;
        this._rateType = rateType;
        this._playerAddedFrom = from;
        this._errorMessage = "";
        this._errorCode = 0;
    }

    private buildRules(): void {
        this.validateIf(x => x).fulfills(this.guaranteeValidation.bind(this));
    }

    private guaranteeValidation(player: TournamentPlayer | ScheduledTeeTimeTimePlayer | ScheduledLessonPlayerInformation): boolean {
        // return false if any of the given object is null
        if (!player) {
            return false;
        }
        // validating both player type and rate type
        if(this._playerType && this._playerType.guaranteeType == GuaranteeTypes.None && this._rateType &&this._rateType.guaranteeType == GuaranteeTypes.None)
        {
            return true;
        }
        if(this._playerType){
            if(this._playerType.guaranteeType == GuaranteeTypes.None){
                return true;
            }
            let result = this.validateGuarantee(<GuaranteeTypes>(this._playerType.guaranteeType)
                , ValidationFrom.PlayerType
                , player);
            this._errorMessage = result ? result.errorMsg : '';
            this._errorCode = result ? result.errorCode : 0;
            return !this._errorMessage;
        }
        if(this._rateType){
            if(this._rateType.guaranteeType == GuaranteeTypes.None){
                return true;
            }
            let result = this.validateGuarantee(<GuaranteeTypes>(this._rateType.guaranteeType)
                , ValidationFrom.RateType
                , player);
            this._errorMessage = result ? result.errorMsg : '';
            this._errorCode = result ? result.errorCode : 0;
            return !this._errorMessage;
        }        
    }


    /**
     *returns error message for guarantee
     *
     * @param {GuaranteeTypes} guarantee - guarantee type
     * @param {ValidationFrom} from - playertype/ratetype
     * @param {TournamentPlayer} player - player data
     * @returns {string} - error message to be thrown
     * @memberof GuaranteeTypeValidator
     */
    private validateGuarantee(guarantee: GuaranteeTypes, from: ValidationFrom, player: TournamentPlayer | ScheduledTeeTimeTimePlayer | ScheduledLessonPlayerInformation): { errorCode: number, errorMsg: string } {

        let playerCategoryId: number = 0;
        let paymentReferenceId: number = 0;

        switch (this._playerAddedFrom) {
            case PlayerAddedFrom.TeeTimes:
                playerCategoryId = (<ScheduledTeeTimeTimePlayer>player).playerDetail.playerCategoryId;
                paymentReferenceId = (<ScheduledTeeTimeTimePlayer>player).playerDetail.paymentReferenceId;
                break;
            case PlayerAddedFrom.Tournament:
                playerCategoryId = (<TournamentPlayer>player).player.playerCategoryId;
                paymentReferenceId = (<TournamentPlayer>player).player.paymentReferenceId;
                break;

            case PlayerAddedFrom.ScheduledLessons:
                playerCategoryId = (<ScheduledLessonPlayerInformation>player).player.playerCategoryId;
                paymentReferenceId = (<ScheduledLessonPlayerInformation>player).player.paymentReferenceId;
                break;
        }

        if (guarantee == GuaranteeTypes.Member && playerCategoryId != playerTypes.member)
            return this.returnError(from == ValidationFrom.PlayerType ? this.GuaranteeErrors.PlayerType_Guarantees_Member : this.GuaranteeErrors.RateType_Guarantees_Member);

        if (guarantee == GuaranteeTypes.Resort && playerCategoryId != playerTypes.hotelReservation)
            return this.returnError(from == ValidationFrom.PlayerType ? this.GuaranteeErrors.PlayerType_Guarantees_Resort : this.GuaranteeErrors.RateType_Guarantees_Resort);

        if (guarantee == GuaranteeTypes.CreditCard && !paymentReferenceId) {
            if (playerCategoryId == playerTypes.member) {
                return this.returnError(from == ValidationFrom.PlayerType ? this.GuaranteeErrors.PlayerType_Guarantees_MemberCreditCard : this.GuaranteeErrors.RateType_Guarantees_MemberCreditCard);
            }
            else {
                return this.returnError(from == ValidationFrom.PlayerType ? this.GuaranteeErrors.PlayerType_Guarantees_CreditCard : this.GuaranteeErrors.RateType_Guarantees_CreditCard);
            }
        }
        return { errorCode: 0, errorMsg: '' };
    }
    returnError(error): { errorCode: number, errorMsg: string } {

        switch (error) {
            case this.GuaranteeErrors.PlayerType_Guarantees_Member:
                {
                    return { errorCode: 14001, errorMsg: error }
                }
            case this.GuaranteeErrors.RateType_Guarantees_Member:
                {
                    return { errorCode: 14004, errorMsg: error }
                }
            case this.GuaranteeErrors.PlayerType_Guarantees_Resort:
                {
                    return { errorCode: 14002, errorMsg: error }
                }
            case this.GuaranteeErrors.RateType_Guarantees_Resort:
                {
                    return { errorCode: 14005, errorMsg: error }
                }
            case this.GuaranteeErrors.PlayerType_Guarantees_MemberCreditCard:
                {
                    return { errorCode: 14007, errorMsg: error }
                }
            case this.GuaranteeErrors.RateType_Guarantees_MemberCreditCard:
                {
                    return { errorCode: 14008, errorMsg: error }
                }
            case this.GuaranteeErrors.PlayerType_Guarantees_CreditCard:
                {
                    return { errorCode: 14003, errorMsg: error }
                }
            case this.GuaranteeErrors.RateType_Guarantees_CreditCard:
                {
                    return { errorCode: 14006, errorMsg: error }
                }
        }
    }
}

export enum ValidationFrom {
    PlayerType,
    RateType
}

export enum PlayerAddedFrom {
    TeeTimes,
    Tournament,
    ScheduledLessons
}
