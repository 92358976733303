<section class="info">
  <div class="ag_display--flex align-items-center info__header">
  <div class="pl-2" [style.color]="info?.color">{{info?.title}}</div>
  <div class="player-notifications ml-auto"
                  *ngIf="customTableData?.isPlayerDetailIconsEnabled && !info?.player.isBlocked">
                  <i [attr.automationId]="'Icn_TeeSheetDetails_checkIn'" tee-player-details class="icon-checkIn" *ngIf="info?.player && info?.player.isCheckedIn"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_checkOut'" class="icon-checkOut" *ngIf="info?.player && info?.player.isCheckedOut"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_dashboardflag'" class="icon-dashboard-flag" *ngIf="info?.player && info?.player.isFlagDay"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_adddeposit1'" class="icon-adddeposit1"
                    *ngIf="(((info?.player && info?.player.deposits && info?.player.deposits.length) > 0)  && !info?.player.isPaidPlayer) || (info?.player.multiPackTransactionDetailId && info?.player.multiPackTransactionDetailId != 0)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_rate'" *ngIf="player && player.isPaidPlayer"
                    [ngClass]="info?.player.isMarkAsPaid?'icon-Mark-Paid':''"></i><!--icon-rate-->
                  <i [attr.automationId]="'Icn_TeeSheetDetails_Membernew'" class="icon-Member-new" *ngIf="info?.player && (info?.player.memberNumber != '')"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_tournamentplayer'" class="icon-tournament-player" *ngIf="info?.player && (info?.player.tournamentId > 0)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_sphere'" class="icon-sphere"
                    *ngIf="info?.player && (info?.player.bookingSource === bookingSource.sourcerGuestBook)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_GolfNow'" class="icon-GolfNow" *ngIf="info?.player && (info?.player.bookingSource === bookingSource.golfNow)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_bagnumber'" class="icon-bag-number" *ngIf="info?.player && info?.player.bagNumber">{{info?.player?.bagNumber}}</i>
                  <!-- This condition is set to false, because we are not receiving any bagnumbers from the API-->
                  <i [attr.automationId]="'Icn_TeeSheetDetails_BulkTeeTime'" class="icon-Bulk-Tee-Time" *ngIf="info?.player && (info?.player.teeTimeFormat == teeTimeFormat.BulkTee)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_GroupPlayer'" class="icon-Group-Player" *ngIf="info?.player && (info?.player.teeTimeFormat == teeTimeFormat.GroupTee)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_noshow'" class="icon-no-show" *ngIf="info?.player && (info?.player && info?.player.playerStatus == 2)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_caddynew'" class="icon-caddy_new" *ngIf="info?.player && (info?.player && info?.player.caddyType != null)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_golfcart'" class="icon-golf-cart" *ngIf="info?.player && (info?.player && info?.player.cartType?.length > 0)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_Guest'" class="icon-Hotel_Guest" *ngIf="info?.player && (info?.player && info?.player.playerCategoryId == playerCategory.HotelReservation)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_Comments'" class="icon-Player_Comments" *ngIf="info?.player && (info?.player && info?.player.playerComment != null && info?.player.playerComment != '')"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_EForm'" class="icon-View-E-Form" *ngIf="info?.isEformsEnabled && info?.player && (info?.player && info?.player.eformCount && info?.player.eformCount!= '0/0' )">{{info?.player?.eformCount}}</i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_RetailItems'" class="icon-add_retail_item_24px" *ngIf="info?.player && (info?.player && info?.player.isRetailItemsAvailable)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_RentalItems'" class="icon-Rentals" *ngIf="info?.player && (info?.player &&  info?.player.isRentalItemsAvailable)"></i>
                  <i [attr.automationId]="'Icn_TeeSheetDetails_VIP'" class="icon-vip" *ngIf="info?.player && (info?.player && info?.player.vip != null && info?.player.vip != '')"></i>
                </div>
  </div>
  <ng-container *ngTemplateOutlet="info?.bodyInfo?playerInfo:commentOnly;context: {$implicit: info}">
  </ng-container>
</section>

<ng-template #playerInfo let-info>
  <section class="info__container new-infoContainer" *ngIf="info?.bodyInfo.length > 0">
    <div class="info__body">
      <span *ngFor="let legentInfo of info?.bodyInfo">
        <span *ngIf="legentInfo.description" class="legend">
          <i aria-hidden="true" [ngClass]="legentInfo.icon" [style.color]="legentInfo.iconColor"></i>
          <span [style.color]="legentInfo.color">{{legentInfo.description}}</span>
          <span *ngIf="legentInfo.isPaidPlayer||legentInfo.isMarkAsPaid" [style.font-weight]="'bold'" class="ag_pl--2" [ngClass]="legentInfo.isMarkAsPaid?'icon-Mark-Paid':'icon-rate'"> </span>    
        </span>
      </span>
      <div *ngIf="info?.groupBookingName != ''">
        <span>{{info?.groupBookingName}}</span>
      </div>
    </div>
    <div class="info__comment" *ngIf="info?.comment">
      <span class="golf-form--label ag-pt-1 font-bold">{{info?.commentLabel}}</span>
      {{info?.comment}}
    </div>
  </section>
</ng-template>

<ng-template #commentOnly let-info>
  <section class="info__container">
    <div class="info__comment">
      {{info?.comment}}
    </div>
  </section>
</ng-template>