import { Component,  Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { TeeGridService } from '../tee-grid/tee-grid.service';
import { DragOrigin, TeeSheetTableID } from '../../models/teesheet.form.models';
import _ from 'lodash';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Component({
  selector: 'app-bulk-player-list',
  templateUrl: './bulk-player-list.component.html',
  styleUrls: ['./bulk-player-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BulkPlayerListComponent  {

  @Input() playersList;
  @Input() playerCollectionList;
  @Input() isAutoBookAvailable;
  @Input() isAutoBookDisabled;
  @Input() isFromGroupTeeTime;
  @Output() onDragPlayers = new EventEmitter();
  @Output() onAutoBook = new EventEmitter();
  @Input() teeSheetID: TeeSheetTableID;
  @Input() customTableData;
  @Input() isDragDropNotAllowed:boolean = false;
  automationId:string="";
  @Input('automationId')
  set setAutomationId(value: string) {
    this.automationId = value ? value : '';
  }
  PlayerCollectionList: string[];
  isDragging: boolean = false;
  isAllItemsSelected: boolean = false;
  captions: any;
  setSelectAllFlag:boolean = true;
  playersListData: any[];

  constructor(public _TeeGridService: TeeGridService,  private _localization: GolfLocalization) {
    this.captions = this._localization.captions.teetime;
   }

  

  ngOnChanges(){
    if(this.playersList && this.playersList.length > 0){
      if(this.setSelectAllFlag){
        this.setSelectAllFlag = false;
        this.selectAll();
      }     
    }
    let uniq = [];
    let sortedData = [];
    uniq = _.uniqBy(this.playersList, 'scheduledDateTime');
    uniq = uniq.sort((d1, d2) => new Date(d1.scheduledDateTime).getTime() - new Date(d2.scheduledDateTime).getTime());
    uniq.forEach((data) => {
      const filteredData = this.playersList.filter(res => res.scheduledDateTime === data.scheduledDateTime);
      filteredData.sort((d1, d2) => d1.playPos - d2.playPos);
      filteredData.map((data) => { data.scheduledTime = data.scheduledDateTime ?  this._localization.LocalizeTime(data.scheduledDateTime): ''; 
      filteredData.scheduledDateTime = this._localization.LocalizeShortDate(data.scheduledDateTime);
      return data; });
      sortedData.push(filteredData);
    });
    this.playersListData = sortedData;
  }

  autoBook(e) {
    this.onAutoBook.emit(e);
  }

  copyPlayers(player) {
    player.playerClicked = !player.playerClicked;
    if (player.playerClicked) {
      this._TeeGridService.selectedPlayers.push(player);
    }
    else {
      for (let index = 0; index < this._TeeGridService.selectedPlayers.length; index++) {
        if (_.isEqual(this._TeeGridService.selectedPlayers[index], player))
          this._TeeGridService.selectedPlayers.splice(index, 1);
      }
    }
    console.log(this._TeeGridService.selectedPlayers);    
  }

  onDragStart(e, player) {
    this.isDragging = true;
    player.origin = DragOrigin.playerlist;
    this.onDragPlayers.emit(this._TeeGridService.selectedPlayers);
  }

  onTeeGridDragMove(e){
    this._TeeGridService.scrollTeeGrid(e, this.customTableData, this.teeSheetID);
  }

  onDragDropped(e) {
    this.isDragging = false;
  }

  selectAll(e?){
    this.playersList.forEach(player => {
      player.playerClicked = true;
    });
    this._TeeGridService.selectedPlayers = [...this.playersList];   
  }

  unSelectAll(e?){
    this.playersList.forEach(player => {
      player.playerClicked = false;
    });
    this._TeeGridService.selectedPlayers = [];    

  }
  


}
