
import { HttpMethod, HttpServiceCall } from "../shared/service/http-call.service";
import * as GlobalConst from '../shared/globalsContant';
import { Validators } from "@angular/forms";
import { HandleRequest, IDTech, IDTechHandle, PaymentMethods, Service, TransactionLog, SurchargeCalculationRequest, SurchargeDetailsResponse } from "../shared/business/shared.modals";
import { FolioInvoiceCheckZoomTheme, HandleResponse, HttpResponseStatus, PaymentErrorCodes, PMSPostRequest, ResortFinanceFolio, SaleResponse, ServiceChargeGratuityUpdate, TaxDetailsUpdate, TransactionDetailUpdate, TransactionUpdate, ValidatePayResponse } from "../shared/service/payment/payment-model";
import { ARPostPaymentResponse, CardEntryModeDialogAction, CardEntryModeDialogResult, CardInputMethod, EnableZeroPosting, GuestRoomSearchResult, PaymentHistory, PayRequest, PMSIntegrationHostId, ResortFinanceFolioInvoicePrintRequest, SaleByTokenRequest, SaleByTokenResponse, SaleRequest, TransactionInfoforCheckZoom, TryPayRequest, TryPayResponse, ValidatePayRequest, SourceTypeConstant, TipPresetValueType } from "../shared/service/payment/payment-business.model";
import { ShopDialogPopUp } from "../shop/shop-dialog-popup/shop-dialog-popup.component";
import { takeUntil } from "rxjs/operators";
import { NegativeValueValidator } from "../shared/Validators/NegativeValueValidator";
import { AgilysysGCMethods, CardPaymentMethods, ExternalGCMethods, GiftCardMethods, MethodsWhichHasPaymentConfirmationPopup, NonPayAgentMethods, PaymentAPIFlow, PaymentOptionType, PaymentTransactionState, RetailScreenType, RoomGrpChargeMethod, V1GiftCardMethods } from "./PaymentFlowHelpers";
import { UserSessionConfiguration } from "src/app/common/shared/retail.modals";
import { Injectable } from "@angular/core";
import { RetailLocalization } from "../common/localization/retail-localization";
import { RetailUtilities } from "../shared/utilities/retail-utilities";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { RetailPropertyInformation } from "../common/services/retail-property-information.service";
import { RetailFeatureFlagInformationService, SearchTypes } from "../shared/service/retail.feature.flag.information.service";
import { ShopBussinessService } from "../shop/shop-business.service";
import { CommonVariablesService, Gratuities, LineItem, ServiceCharge, ServiceCharges, TaxDetails } from "../shared/service/common-variables.service";
import { PayAgentService } from "../shared/service/payagent.service";
import { RetailService } from "../retail.service";
import { ARPostingBusinessService } from "../shared/business/AR-Posting.business.service";
import { CMSBusinessService } from "../shared/business/CMS-business.service";
import { GiftCardBusiness } from "../sytem-config/gift-cards/gift-cards.business";
import { PaymentComponent } from "./payment.component";
import { ActionMode, AlertType, ButtonType, PlayerCategory } from "src/app/common/enums/shared-enums";
import { BaseResponse, FinancialBinLevel, PostError, RetailItemType } from '../retail.modals';
import { RetailDataAwaiters } from '../shared/events/awaiters/retail.data.awaiters';
import { CardEntryModeComponent } from "../shared/card-entry-mode/card-entry-mode.component";
import * as _ from 'lodash';
import { ReceiptType } from "../shared/globalsContant";
import { PaymentCardOnFileSelectComponent } from "./payment-card-on-file-select/payment-card-on-file-select.component";
import { RetailCommunication } from "../retailcommunication.service";
import { RetailRoutes } from "../retail-route";
import { MemberPayeeInfo } from "../shared/business/Member-business.service";
import { CommonPropertyInformation } from "src/app/common/shared/services/common-property-information.service";
import { SourceType } from "../Folio/Model/folioDetails-model";
import { PaymentCommunication } from "../payment-communication.service";
import { CarddetailsPopupComponent } from "../shared/carddetails-popup/carddetails-popup.component";
//import { TransactionLog } from "src/app/common/shared/shared/business/shared.modals";
@Injectable()
export class PaymentBusinessLogicHandler {
	$scope: PaymentComponent;
	dialogRef: MatDialogRef<any>;
	postingDeleted: boolean = false;
	paymentSuccess: boolean = false;
	tokenTransId: number;
	confirmationNumber: string = '';
	IsExpireDateVisible: boolean = false;
	constructor(public http: HttpServiceCall
		, public localization: RetailLocalization
		, public utils: RetailUtilities
		, public dialog: MatDialog
		, public PropertyInfo: RetailPropertyInformation
		, public _featureFlagInfo: RetailFeatureFlagInformationService
		, public _sbs: ShopBussinessService
		, public _ss: CommonVariablesService
		, public payAgentService: PayAgentService
		, public _ams: RetailService
		, public _cmsBusiness: CMSBusinessService
		, public _giftcardBusiness: GiftCardBusiness
		, public _arPostingBusiness: ARPostingBusinessService
		, private _retailCommunication: RetailCommunication
		, private _paymentCommunication: PaymentCommunication
	) { }

	SelectedPaymentMethodEquals(method: PaymentMethods) {
		return (this.$scope.selectedpayment.paymentTypeId === method || this.$scope.selectedpayment.parentTypeId === method)
	}

	get IsFolioPosting() {
		return this.$scope.ScreenName == RetailScreenType.FolioPosting
			|| this.$scope.ScreenName == RetailScreenType.IndividualCheckOut
			|| this.$scope.ScreenName == RetailScreenType.FolioMakePayment
			|| this.$scope.ScreenName == RetailScreenType.FolioDefinition
	}

	get IsResortFinanceFolioPosting() {
		return this.IsFolioPosting && this.PropertyInfo.isResortFinanceEnabled;
	}

	get CurrentOutletId() {
		return this.$scope.CEDS_ByTerminal ? this.payAgentService.extractOutletFromCEDS(this.$scope.CEDS_ByTerminal) : this._ss.SelectedOutletId
	}

	get IsMemberShipPaymentSupportedProduct() {
		return (this.$scope.productId == GlobalConst.Product.GOLF || this.$scope.productId == GlobalConst.Product.RETAIL || this.$scope.productId == GlobalConst.Product.PMS || this.$scope.productId == GlobalConst.Product.SPA)
	}

	get IsFullPaymentWithRoomCharge() {
		return (this.SelectedPaymentMethodEquals(PaymentMethods.RoomCharge) && this.$scope.remainingAmount == this.$scope.totalAmount);
	}

	get CMSPin() {
		return this.$scope.IsCMSRequirePin ? this.$scope.CMSForm?.controls?.cmsPin?.value : ""
	}
	get OrderNumberForGateway() {
        let resortFinanceFolioId = (this.IsResortFinanceFolioPosting || this.$scope.IsResortFinanceFolioAuthFlow) ? this.$scope.AdditionalInputs?.folioId.toString() : '';
        let isSncBEO = this._ss && this._ss.isFromSncBeo;
        let transactionReference = this.$scope && this.$scope.CreateRetailItemResponse?.transactionData?.transactionAdditionalReference;
        let transactionReferenceConversion = transactionReference && !isNaN(transactionReference) ? parseInt(transactionReference) : 0;
        if (isSncBEO && isSncBEO === true && transactionReference && transactionReference.length > 0 && isNaN(transactionReference)) {
            let comma: number = transactionReference.indexOf(",");
            if (comma !== -1) {
                let newTransactionReference = transactionReference.substring(0, comma);
                transactionReferenceConversion = newTransactionReference && !isNaN(newTransactionReference) ? parseInt(newTransactionReference) : 0;
            }
        }
        const sourceTypeWithUniq = `${this.$scope.AdditionalInputs?.sourceTypeId?.toString()}${this.utils.GetRandomNumber(6)}`?.slice(0, 9);
        const retailTicketNumber = this.$scope.CreateRetailItemResponse?.transactionData?.retailTicketNumber;
        const sourceTypeId = this.$scope.AdditionalInputs?.sourceTypeId?.toString();
        let defaultOrderNumber = this.IsFolioPosting ? ExternalGCMethods.includes(this.$scope.selectedPaymentMethod) ? sourceTypeWithUniq : sourceTypeId : retailTicketNumber;
        return resortFinanceFolioId ? resortFinanceFolioId : (isSncBEO && transactionReferenceConversion > 0) ? transactionReferenceConversion : defaultOrderNumber;
    }
	async CheckForAnyPrevSettlements() {
		if (this._ss.settleOpenTransaction) {
			let transactionHistory = await this._sbs.GetPaymentHistory(this._ss.transactionId);
			if (transactionHistory && transactionHistory.length > 0) {
				this.$scope.totalAmount = this._ss.selectedTransaction?.totalAmount;
				transactionHistory.forEach(t => {
					t.paymentMethod = this._sbs.FormSettlementHistoryLabel(t, this.$scope.paymentMethods);
					t.disableDelete = (t.giftcardTransactionType == GlobalConst.GiftCardTransactionType.Issue);
				});
				this.$scope.ShowSettlemtHist = true;
				this.$scope.SettlementHistory = transactionHistory;
				this.$scope.SettlementHistory.forEach(t => {
					t.tenderReducesDiscount = t.discountExemptRatio > 0;
					t.tenderReducesTax = t.taxExemptRatio > 0
				});
				this.$scope.PaymentHistoryEmit.emit(this.$scope.SettlementHistory);
				this.AlterSettlementHistory();
				this.AlterSettleFlowPayments();
			}
		}
	}

	private async AlterSettleFlowPayments() {
		if (this.$scope.SettlementHistory.length > 0) {
			this.$scope.transitionDetails.controls.paymentMode.setValue(PaymentOptionType.MultiPayment);
			this.$scope.multiplePayment = true;
			this.CheckSettlementStatusAndUpdateButtons();
			this.PatchRemainingAmountAndHighlight(this.$scope.remainingAmount);
			this.$scope.transactionState = PaymentTransactionState.NotStarted;
			this.$scope.disabledRadiobool = true;
			this.$scope.AllowCancel = true;
			this.$scope.paymentMethodsClone = this.$scope.paymentMethodsClone.filter((r) => r.id != PaymentMethods.PendingSettlement);
			this.$scope.paymentMethods = this.$scope.paymentMethods.filter((r) => r.id != PaymentMethods.PendingSettlement);
		}
	}

	MakeSale(validateTryPayResponse = false) {
		//if ((this.$scope.IsCheckOutScreen && this.$scope.IsCheckOutPaymentProcess) || this.$scope.IsPaymentScreen) {
		//	this.InitiateSaleReqBasedOnPaymentMethod(this.$scope.getAmountPaid());
		//	return;
		//}

		if (!this.$scope.CreateRetailItemResponse) {
			this.$scope.isAPICallInProgress = true;
			this.beginTransaction();
			return;
		}

		if (this.SelectedPaymentMethodEquals(PaymentMethods.PendingSettlement)) {
			this.$scope.TransactionCompleted.emit({ selectedPayment: this.$scope.selectedpayment });
			this.$scope.isAPICallInProgress = false;
			return;
		}

		//Only If Split Payment Or Partial Payment and Not the 1st Payment
		if (this.$scope.CreateRetailItemResponse) {
			if (this.$scope._memberService.callbackForMultipleMember) {
				this.$scope._memberService.callbackForMultipleMember(this);
				return;
			}

			if (this.isUpdateTransaction()) {
				if (this._ss.transactionUpdated == false) {
					this.$scope.isAPICallInProgress = true;
					this.beginTransaction();
					return false;
				} else {
					//*** OPEN CONFIRMATION DIALOG BASED ON SELECTED PAYMENT
					if (!this.$scope.ConfirmationPopupShown && (MethodsWhichHasPaymentConfirmationPopup.includes(this.$scope.selectedPaymentMethod) && !this.$scope.IsRefundFlow)
						|| this.$scope.IsRefundToCOF || this.$scope.IsMemberRefund || this.isAdyenWithAllEntryMode()) {
						this.OpenPaymentDialog();
						return;
					}
				}
			}

			if ((validateTryPayResponse && !this.$scope.CurrentTryPayResponse) || !validateTryPayResponse) {
				this.SendTryPayRequest(
					this.$scope.CreateRetailItemResponse.transactionDetails[0].transactionId,
					this.$scope.selectedpayment.paymentTypeId
				);
			}			
		}
	}

	isUpdateTransaction() {
		return this.$scope.tempSettlementHistory && this.$scope.tempSettlementHistory.length > 0 &&
			this.$scope.tempSettlementHistory.length == this.$scope.tempSettlementHistory.filter(x => x.isReversed).length
	}

	ProcessTransaction() {
		this.SendTryPayRequest(
			this.$scope.CreateRetailItemResponse.transactionDetails[0].transactionId,
			this.$scope.selectedpayment.paymentTypeId
		);
		if (this.$scope.retailValidationService.transactionLockId == 0) {
			this.$scope.retailValidationService.LockTransaction(
				this.$scope.CreateRetailItemResponse.transactionDetails[0].transactionId
			);
		}
	}
	async SendTryPayRequest(TransactionId: number, TenderId: number, skipSale: boolean = false, salesResponse = null, selectedMember: MemberPayeeInfo = null) {
		let SaleAmount: number = 0;
		if (this.$scope.multiplePayment) {
			SaleAmount = this.localization.currencyToSQLFormat(this.$scope.transitionDetails.controls.amounttendered.value);
		} else {
			SaleAmount = this.$scope.totalAmount;
		}
		if (this.$scope.multipleMemberPayment && selectedMember) {
			SaleAmount = selectedMember.memberAmount;
		}

		SaleAmount = this._sbs.ValidateSaleAmount(SaleAmount, this.$scope);

		if (this.$scope.CurrentTryPayResponse && (this.$scope.IsRoomOrGroupCharge || this.$scope.IsOfferOrCompSlipPaymentMethod
			|| this.utils.GetOriginalTenderId(this.$scope.selectedpayment.paymentTypeId, this.$scope.selectedpayment.parentTypeId) === PaymentMethods.ARAcctPost
			|| this.utils.GetOriginalTenderId(this.$scope.selectedpayment.paymentTypeId, this.$scope.selectedpayment.parentTypeId) === PaymentMethods.PostToFolio
		)) {
			this.InitiateSaleReqBasedOnPaymentMethod(SaleAmount);
			return;
		}

		if (this._ss.isReturnWithoutTicket && SaleAmount < 0) {
			TenderId = this.$scope.selectedpayment.paymentTypeId; // Proceeding with Cash
		}

		const finBinSplitUp = this.$scope.paymentMethods.find(x => x.paymentTypeId === this.$scope.selectedPaymentMethod);
		const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
        const payMethodAdditionalConfig = this.$scope?.selectedpayment?.additionalConfigurations? JSON.parse(this.$scope.selectedpayment.additionalConfigurations) : null;
        const PayMethodPMSIntegrationHostId =  payMethodAdditionalConfig?.find(x => x.Key === PMSIntegrationHostId)
		let pMsIntegrationHostId = PayMethodPMSIntegrationHostId? PayMethodPMSIntegrationHostId.Value : propConfig?.PMSIntegrationHostId??'1'	
          
		let RequestObj: TryPayRequest = {
			transactionId: TransactionId,
			paymentMethodId: this.$scope.selectedpayment.id,
			tenderId: this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile) ? this.$scope.CurrentActiveCard.accessUrl? PaymentMethods.NonIntegratedCreditCard :PaymentMethods.CreditCard : TenderId,
			parentTenderId: this.$scope?.selectedpayment?.parentTypeId ?? 0,
			amount: SaleAmount,
			allowEarn: this.$scope.selectedpayment.allowEarn,
			surcharge: this.$scope.surchargeAmount,
			financialBinLevel: finBinSplitUp && finBinSplitUp.financialBinLevel || FinancialBinLevel.Category,
			multiPMSPropCode: this.$scope?.selectedGuestRoom?.propertyInfo?.PropCode,
			pMSIntegrationHostId: pMsIntegrationHostId,
			isAutoRemoveTax: this.$scope.selectedpayment.isAutoRemoveTax,
			tenderReducesDiscount: this.$scope.selectedpayment.tenderReducesDiscount,
			isAllowZeroPosting: this.$scope?.selectedpayment?.allowZeroPosting,
			discountExemptRatio: this._ss.Ticket.tenderReducesDiscountRatio,
			taxExemptRatio: this._ss.Ticket.compTaxExemptRatio
		};		

		var uriParams = {
			ticketNumber: this.$scope.CreateRetailItemResponse.transactionData.retailTicketNumber,
			outletId: this.$scope.SelectedOutletId,
			checkHandleGuid: this.utils.generateGUID(), //To be  removed from the model 
			terminalId: this.$scope.SelectedTerminalId
		};

		this.$scope.isAPICallInProgress = true;
		let Response: any = await this.http.CallApiAsync<any>({
			host: GlobalConst.Host.retailPOS,
			callDesc: 'TryPay',
			method: HttpMethod.Post,
			body: RequestObj,
			uriParams: uriParams
		});
		console.log(Response);
		this.$scope.isAPICallInProgress = false;
		if (Response && Response.successStatus) {
			this.$scope.CurrentTryPayResponse = Response.result;
			this.$scope.MemDiscFlowCurrentTryPayResponse = _.cloneDeep(this.$scope.CurrentTryPayResponse);
			this.$scope.EnableCloseTranBtn = true;
			if (!skipSale) {
				await this.InitiateSaleReqBasedOnPaymentMethod(SaleAmount);
			} else {
				if (
					this.$scope.selectedPaymentMethod === PaymentMethods.CreditCard ||
					this.$scope.selectedPaymentMethod === PaymentMethods.IDTECH
				) {
					this.ValidatePayRequest(salesResponse);
				}
			}
		}
	}

	async InitiateSaleReqBasedOnPaymentMethod(SaleAmount) {
		try {
			if (!this.$scope.ConfirmationPopupShown) {
				if (this.$scope.multiplePayment && this.$scope.transitionDetails.controls.amounttendered.value == '') {
					this.$scope.transitionDetails.controls.amounttendered.updateValueAndValidity();
					return;
				}

				if (this.$scope.IsRefundFlow) {
					// For refund flow popup will be shown for adyen with both entry mode selected
					if (this.isAdyenWithAllEntryMode() || this.$scope.IsRefundToCOF || this.$scope.IsMemberRefund) {
						this.OpenPaymentDialog();
						return;
					}
					// For refund flow no popup will be shown
					this.$scope.ConfirmationPopupShown = true;
				} else {
					// *** OPEN CONFIRMATION DIALOG BASED ON SELECTED PAYMENT
					if (MethodsWhichHasPaymentConfirmationPopup.includes(this.$scope.selectedPaymentMethod) || this.isAdyenWithAllEntryMode()) {
						this.OpenPaymentDialog();
						return;
					}
				}
			}

			if (SaleAmount >= 0) {
				this.PerformSale(SaleAmount);
			} else if (SaleAmount < 0 && this.$scope.IsRefundFlow) {
				this.PerformRefund(SaleAmount);
			}

			// For Non PMAgent Payment methods ValidatePay API need to be called
			if (
				(this.$scope.selectedPaymentMethod === PaymentMethods.Cash)
				&& (this.$scope.ConfirmationPopupShown)|| this.$scope.isCustomPaymentMethod 
				|| this.$scope.selectedPaymentMethod === PaymentMethods.NonIntegratedCreditCard
			) {
				this.ValidatePayRequest();
			}

		} catch (error) {
			if (this.IsFolioPosting) {
				const clickReturnValue = {
					from: ActionMode.cancel
				};
				this.dialogRef.close();
				this.utils.ToggleLoader(false);
				this.$scope.TransactionCompleted.emit(clickReturnValue);
				this.$scope.isAPICallInProgress = false;
			}
			this.HandleSaleFailure(error);
		}
	}

	async OpenPaymentDialog() {
		switch (this.$scope.selectedPaymentMethod) {
			case PaymentMethods.Cash:
				this.openCashPaymentPopup();
				break;
			case PaymentMethods.CardOnFile:
				if ((this.$scope.productId != GlobalConst.Product.PMS || this.$scope.ScreenName == RetailScreenType.Shop) && !this.IsResortFinanceFolioPosting) {
					if (this.CheckReturnReason())
						this.ShowCardOnFileConfirmationPopup();
				} else {
					await this.ShowCardOnFileSelectionPopup();
				}
				break;
			case PaymentMethods.V1GiftCard:
			case PaymentMethods.V1GiftCardIdTech:
			case PaymentMethods.ExternalGiftCard:
			case PaymentMethods.ExternalGiftCardIdTech:
			case PaymentMethods.AgilysysGiftCard:
			case PaymentMethods.AgilysysGiftCardIdTech:
				this._giftcardBusiness.PerformGiftCardRedemption(this.$scope, this.$scope.getAmountPaid());
				break;
			case PaymentMethods.ARPost:
				this.$scope.Memberpin = '';
			const isResortFinancePayFlow = this.$scope.IsResortFinanceMakePaymentFlow;
				if (this.$scope.GridItems && this._ss.memberCardNumber != "0") {
					this.$scope.isAPICallInProgress = true;
					const corpId = this._ss.sourceCorpId ? this._ss.sourceCorpId : 0;
					if (await this.$scope._memberService.ValidateInactiveMember(this._ss.memberCardNumber, this._ss.SelectedPlayers, isResortFinancePayFlow, corpId)) {
						let members = this._ss.SelectedPlayers.length > 0 ? this._ss.SelectedPlayers.filter(p => p.playerCategoryId == PlayerCategory.Member)
							: this._ss.selectedProducts.filter(x => x.clientType == GlobalConst.ClientType.Member && !(x.ItemType == RetailItemType.Deposit) && !x.MultiPack);
						if (this._ss.allowMultipleMembersInTransaction() && this.$scope._memberService.AllowMultiMemberInTransaction && !this.$scope.IsRefundFlow) {
							this.$scope._memberService.ShowMemberSelectionAndPinPopup(this, members, this.$scope.totalAmount, this.$scope.getAmountPaid(), this.$scope.payeeInfo);
						} else {
							this.$scope._memberService.openmembershippopup(this.$scope, this.$scope._memberService.SetMemberPin.bind(this.$scope), isResortFinancePayFlow);
						}
					}
				}
				else { this.$scope._memberService.openmembershippopup(this.$scope, this.$scope._memberService.SetMemberPin.bind(this.$scope), isResortFinancePayFlow); }
				break;
			case PaymentMethods.CreditBook:
				if (!this.CheckReturnReason()) break;
				const isResortFinancePaymentFlow = this.$scope.IsResortFinanceMakePaymentFlow;
				const corpId = this._ss.sourceCorpId ? this._ss.sourceCorpId : 0;
				//Validating If creditbook balance before creating the transaction
				if (!this.$scope._memberService.ValidateCreditBook(
					this.$scope.AmountEntered
					, this.$scope.payeeInfo?.balanceList
					, this.$scope.selectedpayment
					, isResortFinancePaymentFlow
				)) { return; }
				this.$scope.Memberpin = '';
				if (this.$scope.GridItems && this._ss.memberCardNumber != "0") {
					this.$scope.isAPICallInProgress = true;
					if (await this.$scope._memberService.ValidateInactiveMember(this._ss.memberCardNumber, this._ss.SelectedPlayers, isResortFinancePaymentFlow, corpId)) {
						this.$scope._memberService.openmembershippopup(this.$scope, this.$scope._memberService.SetMemberPin.bind(this.$scope), isResortFinancePaymentFlow);
					}
				}
				break;
			case PaymentMethods.RedeemPoint:
				break;
			case PaymentMethods.CreditCard:
				if (this.isAdyenWithAllEntryMode()) {
					this.proceedForAdyenGatewayPayment();
				}
				break;
			default:
				break;
		}
	}

	CheckReturnReason() {
		if (this.$scope.IsRefundFlow && this.$scope.returnReasonField?.nativeElement && !this.$scope.returnReasonField.nativeElement?.value) {
			this.$scope.returnReasonField.nativeElement.focus();
			this.$scope.EnableCloseTranBtn = false;
			return false;
		}
		return true;
	}

	isAdyenWithAllEntryMode() {
		return this.$scope.GatewayConfiguration &&
			this.$scope.GatewayConfiguration?.payGatewayID &&
			this.$scope.GatewayConfiguration?.payGatewayID.trim().toLowerCase() === 'adyen' &&
			this.$scope.GatewayConfiguration?.cardInputMethod === CardInputMethod.All &&
			this.SelectedPaymentMethodEquals(PaymentMethods.CreditCard);
	}

	/**
* @method Function
* @function openPaymentPopup
* @param e <obj>
* @type any
* @description open popup choose the popup method or cash type based
* @return none
* @change active the select class compare to id
*
*/
	openCashPaymentPopup() {
		const fullpaymentAmount = this.localization.localizeCurrency(this.$scope.getSaleAmt(true).toString());
		const partialPaymentAmount = this.localization.localizeCurrency(
			this.localization.currencyToSQLFormat(this.$scope.transitionDetails.controls.amounttendered.value).toString()
		);
		const totalAmountInt = this.$scope.getSaleAmt(true);
		const tenderAmt = this.localization.currencyToSQLFormat(this.$scope.transitionDetails.controls.amounttendered.value);

		const dialogRef = this.dialogRef = this.dialog.open(ShopDialogPopUp, {
			width: '500px',
			height: 'auto',
			maxHeight: '700px',
			disableClose: true,
			hasBackdrop: true,
			data: {
				isEdit: false,
				headername: this.$scope.ShopCaptions.cashPayment,
				closebool: true,
				templatename: 'shopCashPayment',
				data: {
					totalamount: this.$scope.multiplePayment ? partialPaymentAmount : fullpaymentAmount,
					totalAmountInt: this.$scope.multiplePayment ? tenderAmt : totalAmountInt,
					paymenttype: this.$scope.FullPayment ? 'fullpayment' : 'partialpayment',
					patchTotalAmountInCashPopup: this.$scope.patchTotalAmountInCashPopup
				}
			}, //, templatename: templateName
			panelClass: 'shop-payment'
		});

		dialogRef.afterClosed().pipe(takeUntil(this.$scope.$destroyed)).subscribe((cashPaymenHandle) => {
			this.$scope.cashPaymenHandleData = cashPaymenHandle;
			if (cashPaymenHandle?.paymentStaus === 'cashpaid') {
				if ((this.$scope.getSaleAmt() == this.$scope.remainingAmount
					|| (this._ss.settleOpenTransaction && !this.$scope.CreateRetailItemResponse))) {
					this.$scope.ConfirmationPopupShown = true;					
					this.MakeSale();
					return;
				}
				this.ValidatePayRequest();
			} else {
				this.$scope.isAPICallInProgress = false;
			}
		});
	}

	async proceedForAdyenGatewayPayment(promptForPreferenceOverride = false) {
		if (this.$scope.GatewayConfiguration?.cardInputMethod === CardInputMethod.All && this.$scope.GatewayConfiguration?.preferredCardEntryMode == 0 || promptForPreferenceOverride) {
			const dialogRef = this.dialogRef = this.openCardEntryModeSelectionPopup();
			dialogRef.afterClosed().pipe(takeUntil(this.$scope.$destroyed)).subscribe((result: CardEntryModeDialogResult) => {
				if (result.action === CardEntryModeDialogAction.Proceed) {
					this.$scope.defaultCardInputMethod = result.cardInputMethod;
					this.$scope.ConfirmationPopupShown = true;
					this.MakeSale();
				}
			});
		} else {
			this.$scope.defaultCardInputMethod = this.$scope.GatewayConfiguration?.preferredCardEntryMode;
			this.$scope.ConfirmationPopupShown = true;
			this.MakeSale();
		}
	}

	openCardEntryModeSelectionPopup() {
		return this.dialog.open(CardEntryModeComponent, {
			width: '550px',
			height: '300px',
			hasBackdrop: true,
			panelClass: 'small-popup',
			data: {
				headername: this.$scope.oCaptions.common.Warning,
				headerIcon: 'icon-warning-icon',
				headerMessage: this.$scope.oCaptions.shop.CorrectionTransactionMsg,
				buttonName: this.$scope.oCaptions.common.Yes,
				noButton: true,
				noButtonName: this.$scope.oCaptions.common.No,
				type: 'message'
			},
			disableClose: true
		});

	}

	ShowCardOnFileConfirmationPopup() {
		this.IsExpireDateVisible = (this.$scope.productId == GlobalConst.Product.PMS) ? this.IsExpireDateVisible : false;
			const dialogRef = this.dialog.open(CarddetailsPopupComponent, {
			  height: 'auto',
			  width: '40%',
			  data: { CurrentActiveCard: this.$scope.CurrentActiveCard, IsExpireDateVisible: this.IsExpireDateVisible },
			  panelClass: 'small-popup',
			  disableClose: true,
			  hasBackdrop: true
			});
			dialogRef.afterClosed().pipe(takeUntil(this.$scope.$destroyed)).subscribe(resultData => {
				this.ProceedCardOnFile(resultData.action);
			});
		 
	}
	MapsurchargeCalculationRequest(tokenTransactionIds: number[], amount: number): SurchargeCalculationRequest{
		return <SurchargeCalculationRequest>{
			tokenTransactionIds: tokenTransactionIds,
			amount: amount - Math.abs(this.$scope.surchargeAmount ?? 0)
		}
	} 
	private IncludeSurchage(totalAmount: number, surchargeAmount: number ){
		this.$scope.surchargeAmount = surchargeAmount;
		this.$scope.totalAmount = totalAmount;
		this.$scope.remainingAmount = totalAmount;
		this.$scope._ss.remainingAmount = totalAmount;
		this.$scope.transitionDetails.controls.amounttendered.setValue(totalAmount);
	}
	async ShowCardOnFileSelectionPopup() {
		let  surchargeDetailsResponse: SurchargeDetailsResponse[];
		if(this.$scope.SurchargeEnabled && this.$scope.totalAmount > 0){
			surchargeDetailsResponse =  await this.$scope._surchargeConfigService.GetSurchargeByTokenTransactionId(this.MapsurchargeCalculationRequest(this.$scope.ActiveCreditCards.map(x=>x.paymentTransactionId), this.$scope.getAmountPaid()));
		}		
		const dialogRef = this.dialogRef = this.dialog.open(PaymentCardOnFileSelectComponent, {
			width: '700px',
			height: '300px',
			hasBackdrop: true,
			panelClass: 'small-popup',
			data: {
				ccData: this.$scope.ActiveCreditCards,
				IsExpireDateVisible: this.IsExpireDateVisible,
				isSurchargeEnabled: this.$scope.SurchargeEnabled,
				surchargeDetail: surchargeDetailsResponse,
			},
			disableClose: true
		});
		dialogRef.afterClosed().pipe(takeUntil(this.$scope.$destroyed)).subscribe(async (result: { action: CardEntryModeDialogAction, tokenId: number, surchargeDetail: SurchargeDetailsResponse, includeSurchage: boolean}) => {
			if (result.action === CardEntryModeDialogAction.Proceed) {
				this.$scope.ConfirmationPopupShown = true;
				const selectedpaymentData = this.$scope.selectedpayment;
				const selectedCard = this.$scope.COFCardsData.find(x => x.paymentTransactionId == result.tokenId);
				this.$scope.AdditionalInputs.postTypeId = this.$scope.paymentMethods.find(x => x.id == selectedCard?.paymentMethodId)?.postTypeId;			
				await this.$scope.CreateCheck();
				selectedpaymentData.paymentMethodId = selectedCard?.paymentMethodId;
				this.tokenTransId = result.tokenId;
				if(result?.surchargeDetail?.surcharge > 0){
					!result.includeSurchage ? this.IncludeSurchage(result.surchargeDetail.requestAmount, 0) : this.IncludeSurchage(result.surchargeDetail.totalAmount, result.surchargeDetail.surcharge);
				}
				if (this.IsFolioPosting) {
					this.$scope.CurrentActiveCard.cof_PaymentRef = this.tokenTransId;
					await this.beginTransaction(selectedpaymentData);
				}
				else {
					await this.ProceedCardOnFile(GlobalConst.ButtonOptions.Yes);
				}
			} else {
				this.$scope.isAPICallInProgress = false;
				this.$scope.EnableCloseTranBtn = false;
			}
		});
	}

	async beginTransaction(selectedpaymentData = null) {
		const PostAmount = this.localization.currencyToSQLFormat(this.$scope.transitionDetails.controls.amounttendered.value) - this.$scope.surchargeAmount;
		const saleamount = PostAmount;
		if (selectedpaymentData == null) {
			selectedpaymentData = this.$scope.selectedpayment;
		}
		const returnValue = {
			from: ActionMode.create,
			formValues: this.$scope.transitionDetails.getRawValue(),
			form: this.$scope.transitionDetails,
			transactionId: 0,
			maskedCardNumber: '',
			totalAuthorizedAmount: this.$scope.totalAmount,
			checkReponse: this.$scope.checkResponse,
			selectedPayment: selectedpaymentData,
			saleAmount: saleamount,
			arAccountNumber: this.$scope.selectedARAccount ? this.$scope.selectedARAccount.accountNumber : '',
			surcharge: this.$scope.surchargeAmount,
			logType: "Posting",
			patronId: this.$scope.selectedCMSPlayer ? this.$scope.selectedCMSPlayer.PatronId : '',
			offerDetails: this.$scope.selectedVoucher ? this.$scope.selectedVoucher : ''
		};
		const clickReturnValue = _.cloneDeep(returnValue);
		this.$scope.BeginTransaction.emit(_.cloneDeep(clickReturnValue));
	}

	async ProceedCardOnFile(result: string) {
		if (result.toLowerCase() == GlobalConst.ButtonOptions.Yes.toLowerCase()) {
			if (this.$scope.totalAmount == this.$scope.remainingAmount || (this._ss.settleOpenTransaction && !this.$scope.CreateRetailItemResponse)) {
				this.$scope.ConfirmationPopupShown = true;
				if (this.IsFolioPosting) {
					this.InitiateSaleReqBasedOnPaymentMethod(this.$scope.totalAmount);
				} else {
					this.MakeSale();
				}
				return;
			}
			this.$scope.ConfirmationPopupShown = true;
			// this.SaleByToken();
			this.InitiateSaleReqBasedOnPaymentMethod(this.$scope.totalAmount);
		} else {
			this.$scope.EnableCloseTranBtn = false;
			this.$scope.ResetSelectedPayment();
		}
	}

	isCalcBasedOnTender(){
		return this.$scope.selectedpayment.isAutoRemoveTax || this.$scope.selectedpayment.tenderReducesDiscount;
	}

	CheckSettlementStatusAndUpdateButtons() {
		this._sbs.AutoScrollToBottom();
		this.CalculateSettledAmount();
		if (this._giftcardBusiness.CashBackCallRequired) {
			this._giftcardBusiness.PerformGiftCardCashBack(this.$scope.selectedGiftCard
				, this.$scope.CreateRetailItemResponse?.transactionData?.retailTicketNumber);
		}
		this._ss.TempTicket = this._ss.Ticket;
		if (this.$scope.selectedpayment.isAutoRemoveTax || this.$scope.selectedpayment.tenderReducesDiscount) {
			this._ss.totalTaxExemptRatio += this._ss.Ticket.compTaxExemptRatio;
			this._ss.totalTenderReducesDiscountRatio += this._ss.Ticket.tenderReducesDiscountRatio;
			this._ss.totalTenderReducesTaxRatio +=  this._ss.Ticket.compTaxExemptRatio;
			this._ss.Ticket.compTaxExemptRatio =0;
			this._ss.Ticket.tenderReducesDiscountRatio = 0;
			this._ss.isTaxRecalculated = true;	
			this._ss.selectedTaxExemptPayment = {
				id: 0,
				paymentTypeId: 0,
				paymentMethodId: 0,
				postTypeId: 0,
			};
		}

		this._ss.remainingAmountAfterPayment = this.$scope.remainingAmount;
		if (this.$scope.SettledAmt == this.$scope.getSaleAmt()) {
			this.$scope.ShowTenderField = false;
			this.$scope.transitionDetails.controls.amounttendered.disable();
			this.$scope.paymentText = this.$scope.oCaptions.shop.CloseTransaction;
			this.$scope.ShowPaymentMethods = false;
			this.$scope.AllowCancel = false;
			this.$scope.multiplePayment = false;
			this.$scope.showAllPaymentMethods = false;
			this.$scope.transactionState = PaymentTransactionState.Closed;
			this.$scope.isEnablePaymentComment = false;
			this.$scope.AssignPaymentText();
			this.$scope.TransactionCompleted.emit({ selectedPayment: this.$scope.selectedpayment });
			this.$scope.isAPICallInProgress = false;
			this._ss.transactionUpdated = false;
			const memberConfig = this.PropertyInfo.getMemberConfig, accrualProvider = memberConfig?.accrualProvider, accrualProviderUrl = memberConfig?.accrualProviderUrl; 
			const isEnhancedAccrualFlow: boolean = Boolean(accrualProvider && accrualProviderUrl);
			if (this.IsMemberShipPaymentSupportedProduct && this.$scope._memberService.IsTeeTimePayment && this.$scope._ss.memberCardNumber != "0" && this.$scope.totalAmount >= 0 && !isEnhancedAccrualFlow) {
				let transactionData: TransactionInfoforCheckZoom =
				{
					id: this.$scope.CreateRetailItemResponse?.id,
					outletId: this.$scope.CreateRetailItemResponse?.transactionData?.outletId,
					isVoided: this.$scope.CreateRetailItemResponse?.transactionData?.isVoided,
					retailTicketNumber: this.$scope.CreateRetailItemResponse?.transactionData?.retailTicketNumber,
					transactionDate: this.$scope.CreateRetailItemResponse?.transactionData?.transactionDate
				};
				this.$scope._memberService.ProcessOrderForMember(
					this.$scope.payeeInfo
					, this.$scope.GridItems
					, this.$scope.CreateRetailItemResponse?.transactionData?.retailTicketNumber
					, this.$scope.getSaleAmt()
					, this.$scope.totalAmount
					, this.$scope.SettlementHistory
					, transactionData
				);
			}
			//Settlement is completed at this point, we can remove the cart items so that user can start with an empty cart(Fix for Bug - 52256)
			this._ss.selectedProducts = [];
			this._ss.ticketDiscount = null;
			this._ss.transactionTicketDiscount = [];
			this._ss.memberCardNumber = "0";
			this.$scope.ResetSelectedPayment();
			if (this.$scope.retailValidationService.transactionLockId > 0) {
				this.$scope.retailValidationService.RemoveTransactionLock(this.$scope.retailValidationService.transactionLockId);
			}
		} else {
			if (this.$scope.multiplePayment) {
				this.$scope.transitionDetails.controls.amounttendered.patchValue('');
				this.$scope.transitionDetails.controls.selectedDevice.patchValue('');
				this.CalculateRemainingAmount();
				this.PatchRemainingAmountAndHighlight(this.$scope.remainingAmount);
				this.$scope.ContinuePayment = true;
				this.$scope.disabledRadiobool = true;
				this.$scope.ResetSelectedPayment();
			}
			this.$scope.EnableCloseTranBtn = true;
			this.$scope.AllowCancel = !this.$scope.IsRefundFlow;
			this.$scope.paymentText = this.$scope.IsRefundFlow
				? this.$scope.oCaptions.shop.ContinueRefund
				: this.$scope.oCaptions.shop.ContinuePayment;
			this.$scope.transactionState = PaymentTransactionState.InProgress;
		}
		this.$scope.cmsPlayerHandles = [];
	}

	CalculateSettledAmount() {
		this.$scope.SettledAmt = 0;
		//check if previous settlements are there?
		if (this.$scope.SettlementHistory.length > 0) {
			//Get the settled amounts from all settlements
			this.$scope.SettlementHistory.forEach(
				(settlemt) => {
					if (!settlemt.isReversed) {
						(this.$scope.SettledAmt = this.$scope.RoundOffTwo(
							this.$scope.RoundOffTwo(this.$scope.SettledAmt) + this.$scope.RoundOffTwo(settlemt.amount)
						))
					}
				});
			this.CalculateRemainingAmount();
		}
		this.$scope.SettledAmt = this.$scope.RoundOffTwo(this.$scope.SettledAmt);
	}

	CalculateRemainingAmount() {
		if (this.$scope.IsRefundFlow) {
			this.$scope.remainingAmount = -Number(
				(this.$scope.getSaleAmt(true) - (isNaN(this.$scope.SettledAmt) ? 0 : this.$scope.SettledAmt) * -1).customToFixed()
			);
			this.$scope.transitionDetails.controls.amounttendered.setValidators([
				Validators.min(this.$scope.remainingAmount),
				Validators.max(0),
				Validators.required,
				NegativeValueValidator(this.$scope)
			]);
			this.$scope.transitionDetails.controls.amounttendered.updateValueAndValidity();
		} else {
			this.$scope.remainingAmount = Number(
				(this.$scope.getSaleAmt(true) - (isNaN(this.$scope.SettledAmt) ? 0 : this.$scope.SettledAmt)).customToFixed()
			);
			this.$scope.transitionDetails.controls.amounttendered.setValidators([
				Validators.max(this.$scope.remainingAmount),
				Validators.required,
				NegativeValueValidator(this.$scope)
			]);
			this.$scope.transitionDetails.controls.amounttendered.updateValueAndValidity();
		}
	}


	PerformSale(SaleAmount) {
		let ticketNumber: string; let isNonShop: boolean;
		const checkNumber = (this.IsFolioPosting) ? (this.$scope.AdditionalInputs.checkNumber) : (this._ss.Ticket?.checkData?.checkNumber);
		switch (this.$scope.selectedPaymentMethod) {
			case PaymentMethods.CreditCard:
			case PaymentMethods.V1GiftCard:
			case PaymentMethods.V1GiftCardIdTech:
			case PaymentMethods.ExternalGiftCard:
			case PaymentMethods.ExternalGiftCardIdTech:
			case PaymentMethods.AgilysysGiftCard:
			case PaymentMethods.AgilysysGiftCardIdTech:
				if (this.$scope.selectedDeviceHandle && (this.IsFolioPosting || this.$scope.CreateRetailItemResponse)) {
					this.RequestForSale(this.$scope.CreateRetailItemResponse, SaleAmount);
				}
				break;
			case PaymentMethods.RoomCharge:
			case PaymentMethods.GroupCharge:
			case PaymentMethods.HotelComp:
				if(this.IsResortFinanceFolioPosting)
				{
					this.GetSelectedGuestHandleAndProceedForResortFinance(SaleAmount, this.$scope.selectedGuestRoom);
				}
				else if (this.$scope.selectedDeviceHandle && this.$scope.selectedGuestRoom) {
					if (this.$scope.SkipRoomChargePosting) {
						this.GetSelectedGuestHandleAndProceed(SaleAmount, this.$scope.selectedpayment, this.$scope.CurrentTryPayResponse);
					} else {
						this.RequestForSale(this.$scope.CreateRetailItemResponse, SaleAmount);
					}
				}
			
				break;
			case PaymentMethods.IDTECH:
				if (!this.$scope.EncryptedCardData) {
					this.CaptureCardWithIDTechDevice();
				}
				break;
			case PaymentMethods.CardOnFile:
				this.SaleByToken();
				break;

			case PaymentMethods.CompRedemption:
				if (this._featureFlagInfo.SkipPMAgentCMS) {
					this.ShowTransactionInprogressDialog();
					this.$scope._cmsBusiness.PerformPointRedemption(this.$scope, this.$scope.CreateRetailItemResponse, SaleAmount);
				}
				else {
					this.RequestForSale(this.$scope.CreateRetailItemResponse, SaleAmount);
				}
				break;
			case PaymentMethods.OfferRedemption:
			case PaymentMethods.CompSlipRedemption:
				this.ShowTransactionInprogressDialog();
				this.$scope.selectedVoucher.playerPin = this.CMSPin;
				this.$scope._cmsBusiness.PerformVoucherRedemption(this.$scope, this.$scope.CreateRetailItemResponse);
				return;
			case PaymentMethods.RainCheck:
				if (this.canRainCheckBeRedeemed()) {
					this.RedeemRainCheck(SaleAmount);
				}
				break;
			case PaymentMethods.ARAcctPost:
				this.PerformARPosting();
				break;
			case PaymentMethods.ARPost:
				this.ShowTransactionInprogressDialog();
				ticketNumber = this.$scope.CreateRetailItemResponse?.transactionData?.retailTicketNumber;
				if (this.$scope.isFromPMS && this.$scope.sourceTypeId) {
					ticketNumber = this.$scope.sourceTypeId.toString();
					isNonShop = this.$scope.isNonShop;
				}
				this.$scope._memberService.ACESMemberPayment(this.$scope.Memberpin
					, this.$scope.payeeInfo
					, this.$scope.GridItems
					, SaleAmount
					, ticketNumber
					, checkNumber
					, _.cloneDeep(this.$scope.CurrentTryPayResponse?.financialBins)
					, this.$scope.multiplePayment
					, this.$scope.SettlementHistory
					, this.$scope.selectedpayment
					, this.ValidatePayRequest.bind(this)
					, false
					, null
					, this.$scope.multipleMemberPayment
					, this.$scope.selectedMember
					, this
					, false
					, isNonShop);
				this.$scope.ConfirmationPopupShown = false;
				break;
			case PaymentMethods.CreditBook:
				this.ShowTransactionInprogressDialog();
				ticketNumber = this.$scope.CreateRetailItemResponse?.transactionData?.retailTicketNumber;
				if (this.$scope.isFromPMS && this.$scope.sourceTypeId) {
					ticketNumber = this.$scope.sourceTypeId.toString();
				}
				this.$scope._memberService.MembershipACESCreditBook(this.$scope.Memberpin
					, this.$scope.payeeInfo
					, this.$scope.GridItems
					, checkNumber
					, ticketNumber
					, this.$scope.selectedpayment
					, SaleAmount
					, this.$scope.totalAmount
					, this.ValidatePayRequest.bind(this)
					, this);
				this.$scope.ConfirmationPopupShown = false;
				break;
			case PaymentMethods.PostToFolio:
				this.ShowTransactionInprogressDialog();
				this.$scope._folioBusiness.PostFolio(
					this.$scope, SaleAmount, this.$scope.CreateRetailItemResponse?.id, this, this.ValidatePayRequest.bind(this)).catch(e => {
						this.dialog.closeAll();
						this.$scope.EnableCloseTranBtn = false;
						this.$scope.isAPICallInProgress = false;
						this.$scope.ConfirmationPopupShown = false;
					});
				this.$scope.ConfirmationPopupShown = false;
				break;
			case PaymentMethods.Wallet:
				this.RedeemWallet(SaleAmount);
				break;
			default:
				break;
		}
	}

	PerformRefund(refundAmount: number) {
		let ticketNumber: string; let isNonShop: boolean;
		ticketNumber = this.$scope.CreateRetailItemResponse?.transactionData?.retailTicketNumber;
		if (this.$scope.isFromPMS && this.$scope.sourceTypeId) {
			ticketNumber = this.$scope.sourceTypeId.toString();
			isNonShop = this.$scope.isNonShop;
		}
		const checkNumber = (this.IsFolioPosting) ? (this.$scope.AdditionalInputs.checkNumber) : (this._ss.Ticket.checkData.checkNumber);
		switch (this.$scope.selectedPaymentMethod) {
			case PaymentMethods.CreditCard:
			case PaymentMethods.ExternalGiftCard:
			case PaymentMethods.ExternalGiftCardIdTech:
				if (this.$scope.selectedGiftCard && !this.$scope.selectedGiftCard?.isCardActive) {
					this.$scope.isAPICallInProgress = true;
					this._giftcardBusiness.PerformGiftCardCredit(this, this.$scope, this.$scope.getAmountPaid(), this.$scope.selectedGiftCard);
				} else
					this.RequestForCredit(this.$scope.CreateRetailItemResponse, refundAmount); //Refund process (Device will light up)
				break;
			case PaymentMethods.V1GiftCard:
			case PaymentMethods.V1GiftCardIdTech:
			case PaymentMethods.AgilysysGiftCard:
			case PaymentMethods.AgilysysGiftCardIdTech:
				if (this.$scope.selectedGiftCard) {
					this.$scope.isAPICallInProgress = true;
					this._giftcardBusiness.PerformGiftCardCredit(this, this.$scope, this.$scope.getAmountPaid(), this.$scope.selectedGiftCard);
				}
				break;
			case PaymentMethods.RoomCharge:
			case PaymentMethods.GroupCharge:
			case PaymentMethods.HotelComp:
				if (this.IsResortFinanceFolioPosting) {
					this.GetSelectedGuestHandleAndProceedForResortFinance(refundAmount, this.$scope.selectedGuestRoom);
				}
				else if (this.$scope.selectedDeviceHandle != '' && this.$scope.selectedGuestRoom) {
					if (this.$scope.SkipRoomChargePosting) {
						this.GetSelectedGuestHandleAndProceed(refundAmount, this.$scope.selectedpayment, this.$scope.CurrentTryPayResponse);
					} else {
						this.RequestForSale(this.$scope.CreateRetailItemResponse);
					}
				}
				break;
			case PaymentMethods.CardOnFile:
				this.CreditByToken();
				break;
			case PaymentMethods.IDTECH:
				if (!this.$scope.EncryptedCardData) {
					this.CaptureCardWithIDTechDevice();
				}
				break;
			case PaymentMethods.ARAcctPost:
				this.PerformARPosting();
				break;
			case PaymentMethods.ARPost:
				this.ShowTransactionInprogressDialog();
				this.$scope.isAPICallInProgress = true;
				this.$scope._memberService.ACESMemberPayment(this.$scope.Memberpin
					, this.$scope.payeeInfo
					, this.$scope.GridItems
					, refundAmount
					, ticketNumber
					, checkNumber
					, _.cloneDeep(this.$scope.CurrentTryPayResponse?.financialBins)
					, this.$scope.multiplePayment
					, this.$scope.SettlementHistory
					, this.$scope.selectedpayment
					, this.ValidatePayRequest.bind(this)
					, false
					, null
					, false
					, null
					, null
					, false
					, isNonShop);
				this.$scope.ConfirmationPopupShown = false;
				break;
			case PaymentMethods.CreditBook:
				this.ShowTransactionInprogressDialog();
				this.$scope.isAPICallInProgress = true;
				this.$scope._memberService.MembershipACESCreditBook(this.$scope.Memberpin
					, this.$scope.payeeInfo
					, this.$scope.GridItems
					, checkNumber
					, ticketNumber
					, this.$scope.selectedpayment
					, refundAmount
					, this.$scope.totalAmount
					, this.ValidatePayRequest.bind(this));
				this.$scope.ConfirmationPopupShown = false;
				break;
			case PaymentMethods.PostToFolio:
				this.ShowTransactionInprogressDialog();
				this.$scope._folioBusiness.PostFolio(
					this.$scope, refundAmount, this.$scope.CreateRetailItemResponse?.transactionData?.id, this, this.ValidatePayRequest.bind(this));
				this.$scope.ConfirmationPopupShown = false;
				break;
			default:
				break;
		}
	}

	FormRequestBody(RetailResponse, saleAmount?, receiptText: string = ""): SaleRequest {
		// let checkData;
		// if (this.$scope.IsCheckOutScreen && this.$scope.IsCheckOutPaymentProcess) {
		// 	checkData = this.$scope.checkResponse && this.$scope.checkResponse.transaction && JSON.parse(this.$scope.checkResponse.transaction.checkJSON);
		// } else {
		// 	checkData = RetailResponse && JSON.parse(RetailResponse.transactionData.checkData);
		// }
		let SaleAmount: number = 0;
		let pmsIntegrationHostId = '1';
		if (this.$scope.multiplePayment) {
			let AmountTendered = this.localization.currencyToSQLFormat(
				this.$scope.transitionDetails.controls.amounttendered.value
			);
			SaleAmount = AmountTendered < 0 ? AmountTendered * -1 : AmountTendered;
		} else {
			SaleAmount = this.$scope.getSaleAmt(true);
		}
		SaleAmount = saleAmount && this.$scope.isGiftCardSelected ? Number(Math.abs(saleAmount).customToFixed())
			: Number(SaleAmount.customToFixed());
		let FinancialBins = [];
		if (this.$scope.CurrentTryPayResponse && (this.$scope.IsRoomOrGroupCharge || this.$scope.IsHotelCompPayment)) {
			const tryPayResponse: TryPayResponse = this.$scope.CurrentTryPayResponse;
			tryPayResponse?.financialBins.forEach((element) => {
				FinancialBins.push({
					Id: element.id,
					Amount: element.amount,
					Quantity: element.quantity,
					Type: element.type
				});
			});
		}
		let outletDetail = this._ss.GetSelectedOutletDetail();
		let outletProfitCenter = (outletDetail && outletDetail?.profitCenter) ? outletDetail.profitCenter : "0";
		let tempProfitCenter = this.SelectedPaymentMethodEquals(PaymentMethods.CompRedemption) && this._featureFlagInfo.CMSProfitCenterId
			? this._featureFlagInfo.CMSProfitCenterId : outletProfitCenter, profitCenter = (this.$scope.IsRoomOrGroupCharge || this.$scope.IsHotelCompPayment) ? this.$scope.profitCenter
				: tempProfitCenter,
			tempProfitCenterName = this._ss.AllOutlets && this._ss.AllOutlets.some(o => o.subPropertyID == this.CurrentOutletId)
				? this._ss.AllOutlets.find(o => o.subPropertyID == this.CurrentOutletId).subPropertyName : "",
			profitCenterName = this.SelectedPaymentMethodEquals(PaymentMethods.CompRedemption) ? this._featureFlagInfo.CMSProfitCenterName
				: tempProfitCenterName;

		let userId = this.localization.GetPropertyInfo('UserId');
		const retailTicketNumber = this.$scope.CreateRetailItemResponse?.transactionData?.retailTicketNumber;
		const checkNumVal = this.IsFolioPosting && this.$scope.AdditionalInputs ? this.$scope.AdditionalInputs?.sourceTypeId.toString() : retailTicketNumber;
		const sourceTypeWithUniq = `${this.$scope.AdditionalInputs?.sourceTypeId?.toString()}${this.utils.GetRandomNumber(6)}`?.slice(0, 9);
		const sourceTypeId = this.$scope.AdditionalInputs?.sourceTypeId?.toString();
		let transactionReference = this.$scope && this.$scope.CreateRetailItemResponse?.transactionData?.transactionAdditionalReference;
		let transactionComment = this.$scope && this.$scope.CreateRetailItemResponse?.transactionData?.transactionAdditionalComment;
		let transactionReferenceConversion = transactionReference && !isNaN(transactionReference) ? parseInt(transactionReference) : 0;
		let isSncBEO = this._ss && this._ss.isFromSncBeo;
		if (isSncBEO && isSncBEO === true && transactionReference && transactionReference.length > 0 && isNaN(transactionReference)) {
			let comma: number = transactionReference.indexOf(",");
			if (comma !== -1) {
				let newTransactionReference = transactionReference.substring(0, comma);
				transactionReferenceConversion = newTransactionReference && !isNaN(newTransactionReference) ? parseInt(newTransactionReference) : 0;
			}
		}

		let resortFinanceFolioId = (this.IsResortFinanceFolioPosting || this.$scope.IsResortFinanceFolioAuthFlow) ? this.$scope.AdditionalInputs?.folioId.toString() : '';
		let defaultOrderNumber = this.IsFolioPosting ? ExternalGCMethods.includes(this.$scope.selectedPaymentMethod) ? sourceTypeWithUniq : sourceTypeId : retailTicketNumber;
		
		const originalTenderId = this.utils.GetOriginalTenderId(this.$scope.selectedpayment.paymentTypeId, this.$scope.selectedpayment.parentTypeId);
		const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
        const payMethodAdditionalConfig = this.$scope?.selectedpayment?.additionalConfigurations? JSON.parse(this.$scope.selectedpayment.additionalConfigurations) : null;
		
		if(originalTenderId == PaymentMethods.RoomCharge || originalTenderId == PaymentMethods.GroupCharge || originalTenderId == PaymentMethods.HotelComp){
			const multiPropertyPMSIntegrationHostId = this.$scope?.selectedGuestRoom?.propertyInfo?.pMSIntegrationHostId;
			const PayMethodPMSIntegrationHostId = payMethodAdditionalConfig?.find(x => x.Key === PMSIntegrationHostId);
			pmsIntegrationHostId = multiPropertyPMSIntegrationHostId? multiPropertyPMSIntegrationHostId : PayMethodPMSIntegrationHostId? PayMethodPMSIntegrationHostId?.Value : propConfig?.PMSIntegrationHostId??'1'
		  }

		  let searchType = this.$scope?.selectedGuestRoom?.SearchType;

		return {
			handle: this.$scope.selectedDeviceHandle,
			originalTenderId: this.$scope.selectedPaymentMethod,
			inquirerInfo: {
				terminalId: "1",
				store: this.CurrentOutletId,
				ticketNumber: retailTicketNumber? retailTicketNumber : "",
				stayId: sourceTypeId ? sourceTypeId : "",
				orderNumber: resortFinanceFolioId? resortFinanceFolioId : (isSncBEO && transactionReferenceConversion > 0) ? transactionReferenceConversion : defaultOrderNumber,
				profitCenter: outletProfitCenter,
				profitCenterName: profitCenterName,
				financialBins: FinancialBins,
				zipcode: this.$scope.GatewayConfiguration && this.$scope.GatewayConfiguration?.isAVSActive || this._giftcardBusiness.IsShift4Giftcard ? this.getZipCodeValue() : '',
				isPartialTenderAllowed: this.$scope.GatewayConfiguration && this.$scope.GatewayConfiguration?.isPartialPayAllowed,
				tenderId:
					this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile)
						? PaymentMethods.CreditCard.toString()
						: this.$scope.selectedpayment.paymentTypeId.toString(),
				id: this.$scope.isGiftCardSelected && this.$scope.selectedGiftCard ? this.$scope.selectedGiftCard.cardNumber : "",
				zoneOffset: this.$scope.isGiftCardSelected && this.$scope._featureFlagInfo.ZoneOffset ? this.$scope._featureFlagInfo.ZoneOffset : "",
				employeeId: userId ? userId : '0',
				customer: userId ? userId : '0',
				enterprise: String(this.PropertyInfo.PropertyId),
				manualCardEntry: this.isManualKeyInCardEntryMode(),
				ReceiptTextImage: receiptText,
				pin: this.CMSPin,
				postingId: isSncBEO && transactionReference ? transactionReference : " - #" + checkNumVal,
				clientId: isSncBEO && transactionComment ? transactionComment + " - #" + checkNumVal + " " + profitCenterName : profitCenterName,
				posTransactionId: resortFinanceFolioId? resortFinanceFolioId : this.IsFolioPosting ? this.$scope.AdditionalInputs?.sourceTypeId : this.$scope.CreateRetailItemResponse.transactionDetails[0]?.transactionId,
				propertyInfo: this.$scope?.selectedGuestRoom?.propertyInfo,
				isFolioPosting: this.IsFolioPosting ? true : false,
				sourceTypeId: this.IsFolioPosting ? this.$scope.AdditionalInputs?.sourceTypeId : "",
				postId:  this.IsFolioPosting ? this.$scope.AdditionalInputs?.postId?.toString() : "",

				//Tip Properties
				tipBasisAmount : SaleAmount,
				tipPresetValueType : this.$scope.tipConfig?.isPercentage ? TipPresetValueType.Percent : TipPresetValueType.Amount,
				tipPreset1: this.$scope.tipConfig?.presetValue[0] ?? 0,
				tipPreset2: this.$scope.tipConfig?.presetValue[1] ?? 0,
				tipPreset3: this.$scope.tipConfig?.presetValue[2] ?? 0,
				tipPreset4: this.$scope.tipConfig?.presetValue[3] ?? 0
			},
			paymentDetails:{
				firstName: this.$scope.isGiftCardSelected ? this.$scope.selectedGiftCard.firstName : "",
				lastName: this.$scope.isGiftCardSelected ? this.$scope.selectedGiftCard.lastName : "",
				email: this.$scope.isGiftCardSelected ? this.$scope.selectedGiftCard.email : "",
				phoneNumber: this.$scope.isGiftCardSelected ? this.$scope.selectedGiftCard.phoneNumber : "",
				parentTenderId: this.$scope?.selectedpayment?.parentTypeId ?? 0,
				paymentMethodId: this.$scope.selectedPaymentMethod,
				paymentMethod: String(this.$scope?.selectedpayment?.id),
				roomNumber: this.IsFolioPosting ? this.$scope.AdditionalInputs?.roomNumber : "",
				screenRoute: this.$scope.route.url,
				amount: SaleAmount,
				sourceType: this.$scope.AdditionalInputs?.sourceType ?? "",
				sourceTypeId: this.$scope.AdditionalInputs?.sourceTypeId ?? "",
				folioNumber: this.$scope.AdditionalInputs?.folioNumber ?? "",
				postId: this.$scope.AdditionalInputs?.postId ?? ""
			},
			amount: {
				requestAmount:
					this.$scope.IsRefundFlow && (this.$scope.IsRoomOrGroupCharge || this.$scope.IsHotelCompPayment)
						? -SaleAmount
						: SaleAmount
			},
			transactionAmount: this.$scope.IsRefundFlow && (this.$scope.IsRoomOrGroupCharge || this.$scope.IsHotelCompPayment)
								? -SaleAmount
								: SaleAmount,
			transactionId: resortFinanceFolioId ? resortFinanceFolioId : this.$scope.CreateRetailItemResponse?.id,
			ticketNumber: resortFinanceFolioId ? resortFinanceFolioId : Number(this.$scope?.CreateRetailItemResponse?.transactionData?.retailTicketNumber ?? "0"),
			paymentMethodId: this.$scope.selectedpayment.paymentTypeId,
			transactionPaymentId: this.$scope?.CurrentTryPayResponse?.transactionPaymentId ?? 0,
			paymentMethodTableId: this.$scope?.selectedpayment?.id ?? 0,
			paymentTypeId: this.$scope?.selectedpayment?.paymentTypeId ?? 0,
			parentTypeId: this.$scope?.selectedpayment?.parentTypeId ?? 0,
			roomNumber: this.$scope?.selectedGuestRoom?.RoomNumber ?? '',
			roomReference: this.$scope?.selectedGuestRoom?.RoomReference ?? '',
			guestName: this.$scope?.selectedGuestRoom?.GuestName ?? '',
			paymentReferenceComments: this.$scope?.paymentComments ?? '',
			agentVersion: this.$scope.agentVersion,
			hostName: this.$scope.hostName,
			ipAddress: this.$scope.ipAddress,
			postingRecords: this.$scope.CurrentTryPayResponse?.postingRecords,
			pMSIntegrationHostId: pmsIntegrationHostId,
			paymentMethodAdditionalConfigurations: payMethodAdditionalConfig,
			searchValue : this.$scope?.selectedGuestRoom?.SearchValue,	
			searchType: searchType != null ? searchType : SearchTypes.None,
			bookingId: this.$scope?.selectedGuestRoom?.HotelId ?? ''
		};
	}

	canRainCheckBeRedeemed(): boolean {
		return (
			this.SelectedPaymentMethodEquals(PaymentMethods.RainCheck) ||
			// With IG interface
			(this.PropertyInfo.UseRetailInterface &&
				// Raincheck section is active
				this.$scope.showRainCheckSectionForIG &&
				// rain check is selected
				(this.$scope.SelectedRainCheck != null && this.$scope.rainCheckSelection > 0))
		);
	}

	async RedeemRainCheck(SaleAmount: number) {
		if (this.$scope.SelectedRainCheck && this.$scope.CreateRetailItemResponse && this.$scope.CreateRetailItemResponse.transactionData) {
			this.$scope.SelectedRainCheck.ticketNumber = Number(this.$scope.CreateRetailItemResponse.transactionData.ticketNumber);
			if (!this._ss.AllOutlets || this._ss.AllOutlets.length <= 0) {
				await this._ss.SetAllOutletsData();
				this._ss.AllOutlets = this._ss.outlets;
			}

			this.$scope.SelectedRainCheck.outletName = this._ss.AllOutlets.find(
				(o) => o.subPropertyID == this.CurrentOutletId
			).subPropertyName;
			this.$scope.SelectedRainCheck.isSettled = true;
			this.$scope.SelectedRainCheck.settledAmount = SaleAmount;
			let updateRedeemResponse = RetailDataAwaiters.updateRainCheck(this.$scope.SelectedRainCheck);
			updateRedeemResponse
				.then((res) => {
					if (this.$scope.SelectedRainCheck.rainCheckValue < this.$scope.getAmountPaid()) {
						this.SwitchToMultiPayment({ amount: { authorisedAmount: this.$scope.SelectedRainCheck.rainCheckValue } }, true);
					}
					this.ValidatePayRequest();
				})
				.catch((err) => {
					this.utils.ShowErrorMessage(
						this.localization.captions.common.Error,
						this.localization.replacePlaceholders(
							this.$scope.ShopCaptions.PaymentTransactionFailureMsg,
							['paymentmethod'],
							[this.localization.captions.shop.paymentMethods[this.$scope.selectedPaymentMethod]]
						)
					);
				});
		}
	}

	isManualKeyInCardEntryMode(): boolean {
		let isManualEntry = false;
		if (this.$scope.GatewayConfiguration &&
			this.$scope.GatewayConfiguration?.payGatewayID &&
			this.$scope.GatewayConfiguration?.payGatewayID.trim().toLowerCase() === 'adyen') {
			if (this.$scope.GatewayConfiguration?.cardInputMethod !== CardInputMethod.All) {
				this.$scope.defaultCardInputMethod = this.$scope.GatewayConfiguration?.cardInputMethod;
			}
			isManualEntry = this.$scope.defaultCardInputMethod === CardInputMethod.KeyIn;
		}
		return isManualEntry;
	}

	getZipCodeValue() {
		let ZipcodeValue = this._giftcardBusiness.IsShift4Giftcard ? this.PropertyInfo.PropertyZipCode : '';
		if (this.$scope.transitionDetails.controls.zipcode.value) {
			ZipcodeValue = this.$scope.transitionDetails.controls.zipcode.value;
		}
		return ZipcodeValue;
	}

	async RequestForSale(RetailResponse, saleAmount?) {
		console.log(RetailResponse);
		let receiptText = "";
		if (this.$scope.isCheckZoomEnabled && this.$scope.IsRoomOrGroupCharge) {
			if(this._featureFlagInfo.IsSkipPMAgent(this.$scope.selectedpayment)){
				receiptText = this.$scope.CurrentTryPayResponse.checkZoomString;
			}
			else{
				receiptText = await this._sbs.GetReceiptTextImage(
					this.$scope.CreateRetailItemResponse.transactionData.retailTicketNumber,					
					this.$scope.CreateRetailItemResponse.transactionData.id,					
				);
			}
			const END_OF_LINE_SEQ = "\f"
			receiptText = receiptText?.replace(END_OF_LINE_SEQ, '');
		}
		let RequestBody: SaleRequest = this.FormRequestBody(RetailResponse, saleAmount, receiptText);
		if (this.$scope.selectedpayment.paymentTypeId != PaymentMethods.IDTECH) {
			this.utils.ToggleLoader(false);
			this.ShowCardSwipeDialog();
		}

		if (this.$scope.isGiftCardSelected && this.$scope.IsShift4GC && this.$scope.selectedGiftCard) {
			this.$scope.selectedGiftCard.zipcode = this.PropertyInfo.PropertyZipCode;
		}
		try {			
			//Skip PMAgent Logic
			if (this.$scope.SkipPMAgent || this.$scope.IsHotelCompPayment) {
				if (this.$scope.IsRoomOrGroupCharge || this.$scope.IsHotelCompPayment) {
					const postingResponse = await this._sbs.PostChargesToPMSViaPMSCommunicationSender(RequestBody, Number(this.CurrentOutletId), this._sbs.CEDS_ByTerminal);
					this._sbs.InvokePayOnPMSPostingViaPMSCommunicationSender(this.$scope.CreateRetailItemResponse?.transactionData?.retailTransactionType, postingResponse.paymentBaseResponse, this.$scope, this.$scope.selectedpayment, postingResponse.errorMessage)
					return;
				} else if (this.$scope.isGiftCardSelected) {
					this.GiftcardPayment(RetailRoutes.RedeemGiftcard, RequestBody, saleAmount);
					return;
				}
			}
			
			let SaleResponseData: Promise<any> = this.$scope.PaymentProcessor.RequestSale(
				RequestBody,
				Number(this.CurrentOutletId),
				this.$scope.CEDS_ByTerminal
			);			

			// Log Data to track the sale by device data before payment initiation
			const transLogData = this.TransactionLog("Payment Initiated", 0, 0, "SaleByDevice")
			this.CreateTransLog(transLogData);
			this.$scope.isAPICallInProgress = true;
			SaleResponseData.then((response) => {
				console.log(response);
				//this.utils.ToggleLoader(false);
				let SaleResult: SaleResponse = response;
				if (SaleResult.status.toLocaleLowerCase() == HttpResponseStatus.Success) {
					if (
						this.SelectedPaymentMethodEquals(PaymentMethods.CreditCard) ||
						this.SelectedPaymentMethodEquals(PaymentMethods.IDTECH)
					) {
						this.$scope.cardDetailsBool = true;
						this.$scope.transitionDetails.patchValue({
							cardtype: SaleResult.cardInfo.issuer.toUpperCase(),
							cardnumber: this.$scope.payAgentService.MaskCreditCardNumber(SaleResult.account.id),
							transactionnumber: this.localization.localizeCurrency(SaleResult.amount.requestAmount)
						});
					}
					//Check for partial tender flow
					//Requested amouut is taken from request since rGuest reponse is not having request amount
					if (
						SaleResult.amount &&
						SaleResult.amount.authorisedAmount != RequestBody.amount.requestAmount &&
						this.$scope.GatewayConfiguration && this.$scope.GatewayConfiguration?.isPartialPayAllowed && !this.IsFolioPosting
					) {
						this.SwitchToMultiPayment(SaleResult);
						return;
					}
					this.ValidatePayRequest(SaleResult, false, 0, "", SaleResult.cmsTransactionId);
				} else {
					if (this.IsFolioPosting) {
						const clickReturnValue = {
							from: ActionMode.cancel
						};
						this.dialogRef.close();
						this.utils.ToggleLoader(false);
						this.$scope.TransactionCompleted.emit(clickReturnValue);
						this.$scope.isAPICallInProgress = false;
						let transLogData = this.TransactionLog(`${this.$scope.AdditionalInputs.postId} - posting deleted`, clickReturnValue, 0, "SaleByDevice")
						this.CreateTransLog(transLogData);
					}
					this.HandleSaleFailure(response);
				}
			});
			SaleResponseData.catch((errorResponse) => {
				if (this.IsFolioPosting) {
					const clickReturnValue = {
						from: ActionMode.cancel
					};
					this.dialogRef.close();
					this.utils.ToggleLoader(false);
					this.$scope.TransactionCompleted.emit(clickReturnValue);
					this.$scope.isAPICallInProgress = false;
					let transLogData = this.TransactionLog(`${this.$scope.AdditionalInputs.postId} - posting deleted`, clickReturnValue, 0, "SaleByDevice")
					this.CreateTransLog(transLogData);
				}
				this.HandleSaleFailure(errorResponse);
			});
		} catch (errorResponse) {
			if (this.IsFolioPosting) {
				const clickReturnValue = {
					from: ActionMode.cancel
				};
				this.dialogRef.close();
				this.utils.ToggleLoader(false);
				this.$scope.TransactionCompleted.emit(clickReturnValue);
				this.$scope.isAPICallInProgress = false;
				let transLogData = this.TransactionLog(`${this.$scope.AdditionalInputs.postId} - posting deleted`, clickReturnValue, 0, "SaleByDevice")
				this.CreateTransLog(transLogData);
			}
			console.log(errorResponse);
			this.HandleSaleFailure(errorResponse);
		}
	}

	async RequestForCredit(RetailResponse, refundAmount?) {
		let RequestBody: SaleRequest = this.FormRequestBody(RetailResponse);

		if (this.$scope.selectedpayment.paymentTypeId != PaymentMethods.IDTECH) {
			this.ShowCardSwipeDialog();
		}

		// Log Data to track the credit by device data before payment initiation

		let transLogData = this.TransactionLog("Payment Initiated", 0, 0, "CreditByDevice")
		this.CreateTransLog(transLogData);

		try {
			//SkipPMAgent flow for Giftcard
			if (this.$scope.SkipPMAgent && this.$scope.isGiftCardSelected) {
				this.GiftcardPayment(RetailRoutes.GiftcardCredit, RequestBody, refundAmount);
				return;
			}
			
			let CreditResponse: Promise<any> = this.$scope.PaymentProcessor.RequestCredit(
				RequestBody,
				Number(this.CurrentOutletId),
				this.$scope.CEDS_ByTerminal
			);

			CreditResponse.then((response) => {
				console.log(response);
				let CreditResult: SaleResponse = response;
				if (CreditResult.status.toLocaleLowerCase() == HttpResponseStatus.Success) {
					if (
						this.SelectedPaymentMethodEquals(PaymentMethods.CreditCard) ||
						this.SelectedPaymentMethodEquals(PaymentMethods.IDTECH)
					) {
						this.$scope.cardDetailsBool = true;
						this.$scope.transitionDetails.patchValue({
							cardtype: CreditResult.cardInfo.issuer.toUpperCase(),
							cardnumber: this.$scope.payAgentService.MaskCreditCardNumber(CreditResult.account.id),
							transactionnumber: this.localization.localizeCurrency(CreditResult.amount.requestAmount)
						});
					}

					this.ValidatePayRequest(CreditResult);
				}
			});

			CreditResponse.catch((errorResponse) => {
				if (this.IsFolioPosting) {
					const clickReturnValue = {
						from: ActionMode.cancel
					};
					this.dialogRef.close();
					this.utils.ToggleLoader(false);
					this.$scope.TransactionCompleted.emit(clickReturnValue);// posting deletion for refund when user pressed the cancel button on device
					this.$scope.isAPICallInProgress = false;
					let transLogData = this.TransactionLog(`${this.$scope.AdditionalInputs.postId} - posting deleted`, clickReturnValue, 0, "CreditByDevice")
					this.CreateTransLog(transLogData);
				}
				this.HandleSaleFailure(errorResponse);
			});
		} catch (errorResponse) {
			if (this.IsFolioPosting) {
				const clickReturnValue = {
					from: ActionMode.cancel
				};
				this.dialogRef.close();
				this.utils.ToggleLoader(false);
				this.$scope.TransactionCompleted.emit(clickReturnValue);// posting deletion for refund when user pressed the cancel button on device
				this.$scope.isAPICallInProgress = false;
				let transLogData = this.TransactionLog(`${this.$scope.AdditionalInputs.postId} - posting deleted`, clickReturnValue, 0, "CreditByDevice")
				this.CreateTransLog(transLogData);
			}
			console.log(errorResponse);
			this.HandleSaleFailure(errorResponse);

		}
	}

	async GiftcardPayment(route: RetailRoutes, RequestBody, saleAmount) {
		try {
			const redeemResponse: ValidatePayResponse = await this._paymentCommunication.postPromise<ValidatePayResponse>({
				route: route,
				body: RequestBody
			});
			const maskCardNumber = (cardNumber: string): string => { return cardNumber?.replace(/.(?=.{4})/g, '*') }
			if (redeemResponse.status) {
				const maskedCardNumber = maskCardNumber(redeemResponse?.paymentManagerResponse?.clientInfo?.accountNumber);
				if (this.$scope.isGiftCardSelected && this.$scope?.selectedGiftCard?.cardType == GlobalConst.GiftCardType.ExternalGiftCard && this.$scope.IsShift4GC) {
					this.$scope.selectedGiftCard.maskedCardNumber = maskedCardNumber;
				}
				const SaleResult = {
					cardInfo: {
						maskedCardNumber: maskedCardNumber,
						amount: redeemResponse.paymentManagerResponse.amount,
						account: {
							id: redeemResponse.paymentManagerResponse.clientInfo.accountNumber
						}
					}
				}
				const currentSaleAmt = this.$scope.getAmountPaid(redeemResponse ? redeemResponse.paymentManagerResponse.amount : '', 'authorisedAmount', saleAmount);
				if (!this.IsFolioPosting) {
					this.ConstructSettlementHistory(redeemResponse, SaleResult);
				}
				if (this.IsFolioPosting) {
					if (this.$scope.IsMakePaymentScreen && this.dialogRef) {
						this.dialogRef.close();
					} else {
						this.dialog.closeAll();
					}

					const clickReturnValue = {
						from: ActionMode.create,
						formValues: this.$scope.transitionDetails.getRawValue(),
						form: this.$scope.transitionDetails,
						transactionId: redeemResponse.transactionId,
						maskedCardNumber: maskedCardNumber,
						totalAuthorizedAmount: redeemResponse.paymentManagerResponse && redeemResponse.paymentManagerResponse.amount,
						checkReponse: this.$scope.checkResponse,
						selectedPayment: this.$scope.selectedpayment,
						saleAmount: currentSaleAmt,
						arAccountNumber: this.$scope.selectedARAccount ? this.$scope.selectedARAccount.accountNumber : '',
						logType: "device transaction"
					};

					if (this._giftcardBusiness.CashBackCallRequired) {
						const sourceTypeWithUniq = `${this.$scope.AdditionalInputs?.sourceTypeId?.toString()}${this.utils.GetRandomNumber(6)}`?.slice(0, 9);
						const sourceTypeId = this.$scope.AdditionalInputs?.sourceTypeId?.toString();
						const orderNumber = ExternalGCMethods.includes(this.$scope.selectedPaymentMethod) ? sourceTypeWithUniq : sourceTypeId;
						this._giftcardBusiness.PerformGiftCardCashBack(this.$scope.selectedGiftCard
							, orderNumber, this.$scope.AdditionalInputs);
					}

					this.$scope.cardDetailsBool = false;
					this.$scope.TransactionCompleted.emit(clickReturnValue);
					this.$scope.ResetSelectedPayment();
					this.$scope.isAPICallInProgress = false;

					if (this.IsResortFinanceFolioPosting) {
						this.$scope.CancelResortFinancePaymentMethodValues();
					}
					return;
				}
				this.HandlePayRequest(redeemResponse, SaleResult);
			}
		}
		catch (error) {
			if (this.IsFolioPosting) {
				const clickReturnValue = {
					from: ActionMode.cancel
				};
				this.$scope.TransactionCompleted.emit(clickReturnValue);
				this.$scope.isAPICallInProgress = false;

				if (this.IsResortFinanceFolioPosting) {
					this.$scope.CancelResortFinancePaymentMethodValues();
				}
			}
			console.log('Validate Pay Response:' + error.error);
			this.HandlePostSaleFailure(error);
		}
	}

	async PerformARPosting() {
		let postResponse: ARPostPaymentResponse;
		this.ShowTransactionInprogressDialog();
		const finBinSplitUp = this.$scope.paymentMethods.find(x => x.paymentTypeId === PaymentMethods.ARAcctPost);
		if (!this._ss.AllOutlets || this._ss.AllOutlets.length <= 0) {
			await this._ss.SetAllOutletsData();
			this._ss.AllOutlets = this._ss.outlets;
		}

		
		if((this.$scope.IsMakePaymentScreen ||  this.$scope.IsCheckOutScreen) && !this.$scope.IsResortFinanceMakePaymentFlow && !this.$scope.IsResortFinanceFolioAuthFlow){			
			postResponse = await this.$scope._arPostingBusiness.ConstructARPostingRecord(this.$scope, this.$scope.getAmountPaid(),
			finBinSplitUp && finBinSplitUp.financialBinLevel || FinancialBinLevel.Category);			
		}
		else{
			postResponse = await this.$scope._arPostingBusiness.PerformARPosting(this.$scope, this.$scope.getAmountPaid(),
				finBinSplitUp && finBinSplitUp.financialBinLevel || FinancialBinLevel.Category);
		}

		if (postResponse && postResponse.postingStatus && (postResponse.postingStatus.toLowerCase() == HttpResponseStatus.Success || postResponse.postingStatus.toLowerCase() === 'true')) {
			this.$scope.currentARPostResponse = postResponse;
			this.ValidatePayRequest();
		} else {
			const clickReturnValue = {
				from: ActionMode.cancel
			};
			this.$scope.TransactionCompleted.emit(clickReturnValue);// posting deletion for refund when user pressed the cancel button on device
			this.HandlePostSaleFailure("");
		}
	}

	async RedeemWallet(saleAmount: number) {
		this.ShowTransactionInprogressDialog();
		let cardNumber = this.$scope.transitionDetails.controls.walletInput.value;
		let retailTicketNumber = this.$scope.CreateRetailItemResponse?.transactionData?.retailTicketNumber;
		let tenderId = this.$scope.selectedpayment.paymentTypeId;
		let paymentMethodId = this.$scope.selectedpayment.id;

		const postResponse = await this.$scope._walletBusiness.RedeemWallet(cardNumber, saleAmount, retailTicketNumber, tenderId, paymentMethodId);
		if (postResponse && postResponse.status) {
		const validatePayResponse: ValidatePayResponse = {
				paymentManagerResponse: null,
				status: postResponse.status,
				transactionId: postResponse.transactionId
			};

			this.$scope.transitionDetails.controls.walletInput.patchValue('');
			this.HandlePayRequest(validatePayResponse, postResponse);
		} 
		else if (postResponse && postResponse?.debugMessage != null) {
			this.dialog.closeAll();
			this.utils.ShowErrorMessage(this.localization.captions.common.Error, postResponse?.debugMessage);
		}
		else {			
			this.HandlePostSaleFailure("");
		}
	}

	async GetSelectedGuestHandleAndProceed(postingAmount: number, selectedPayment, currentTryPayResponse) {
		const propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
		if (this.$scope.selectedDeviceHandle && this.$scope.selectedGuestRoom) {
			const isSkipPMAgent = this._featureFlagInfo.IsSkipPMAgent(this.$scope.selectedpayment);
			this.$scope.isAPICallInProgress = true;
			this.ShowTransactionInprogressDialog();
			console.log("Before GetWebProxyHealthCheckStatus");
			let isWebProxyup = isSkipPMAgent? true : await this._ss.GetWebProxyHealthCheckStatusForPMSPostings(true, false, isSkipPMAgent);
			if (isWebProxyup) {
				console.log("WebProxy HealthCheck success!, firing PostChargesToPMS");
				let isPostingSuccess = await this._sbs.PostChargesToPMS(
					this.$scope.CreateRetailItemResponse?.id
					, this.$scope.selectedPaymentMethod
					, postingAmount
					, this.$scope.selectedGuestRoom
					, this.$scope.CurrentTryPayResponse?.transactionPaymentId
					, this.$scope.selectedpayment.paymentTypeId
					, this.$scope.CreateRetailItemResponse?.transactionData?.retailTicketNumber
					, this.$scope.CreateRetailItemResponse?.transactionData?.retailTransactionType
					, this.$scope
					, selectedPayment
					, currentTryPayResponse
				);
				this.$scope.isAPICallInProgress = false;
				console.log("PostChargesToPMS response:" + isPostingSuccess);
				if (isPostingSuccess) {
					this.ValidatePayRequest('', false, 0, '');
				} else {
					this.HandlePostSaleFailure("");
				}
			}
			else {
				console.log("WebProxy HealthCheck failed, hence PostChargesToPMS not fired!");
				this.dialog.closeAll();
				this.$scope.isAPICallInProgress = false;
			}

		}
	}

	async GetSelectedGuestHandleAndProceedForResortFinance(postingAmount: number, selectedGuestRoom) {
		if (this.$scope.selectedDeviceHandle && this.$scope.selectedGuestRoom) {
			this.$scope.isAPICallInProgress = true;
			this.ShowTransactionInprogressDialog();
			const resortFinanceFolioCheckZoom = await this.GenerateResortFinanceCheckZoom();
			let isPostingSuccess = await this._sbs.PostChargesToPMSForResortFinance(
					this.$scope.selectedPaymentMethod
					, postingAmount
					, selectedGuestRoom
					, this.$scope.selectedpayment.paymentTypeId
					, this.$scope.AdditionalInputs.FolioInvoiceNumber
					, this.$scope.AdditionalInputs.roomChargePostTypeNumber
					, ResortFinanceFolio
					, resortFinanceFolioCheckZoom
				);
				this.$scope.isAPICallInProgress = false;
				console.log("PostChargesToPMS response:" + isPostingSuccess);
				if (isPostingSuccess) {
					this.ValidatePayRequest('', false, 0, '');
				} else {
					this.HandlePostSaleFailure("");
				}
		}
	}

	async GenerateResortFinanceCheckZoom() : Promise<string>{
		let resortFinanceCheckZoom = '';
		
		let resortFinanceFolioInvoicePrintRequest : ResortFinanceFolioInvoicePrintRequest = {
			printFolioIds : '['+(this.$scope?.AdditionalInputs?.folioId??0).toString()+']',
			resortFinanceFolioInvoicePrintOption : 0,
			isStatementBySplit : true,
			folioInvoiceNumber : this.$scope?.AdditionalInputs?.FolioInvoiceNumber??'',
			folioInvoiceGuestName : this.$scope.currentPaymentPostfolioData?.selectedGuest?.name,
			invoicePrintedAtProperty : this.localization.GetPropertyInfo('PropertyName'),
			invoicePrintedByClerkId : this.localization.GetPropertyInfo('UserId'),
			invoicePrintedByClerkName : this.localization.GetUserInfo('userName'),
			invoiceColourTheme : FolioInvoiceCheckZoomTheme[0],
			sourceType : this.$scope?.AdditionalInputs?.sourceType??'',
			sourceTypeId : this.$scope?.AdditionalInputs?.sourceTypeId??''		
		}
		
	    resortFinanceCheckZoom = (await this.http.CallApiAsync<string>({
			host: GlobalConst.Host.CommonGateway,
			callDesc: RetailRoutes.GetResortFinanceFolioCheckZoomHtml,
			method: HttpMethod.Post,
			body: resortFinanceFolioInvoicePrintRequest
		  })).result;

		return resortFinanceCheckZoom;
	}

	ShowCardSwipeDialog() {
		if (
			this.SelectedPaymentMethodEquals(PaymentMethods.CreditCard) ||
			this.SelectedPaymentMethodEquals(PaymentMethods.IDTECH)
		) {
			const Popupmessage = this.isManualKeyInCardEntryMode() ?
				this.localization.captions.shop.KeyInCardMessage : this.localization.captions.shop.SwipeCardMessage;
			const isIDTech: boolean = this.SelectedPaymentMethodEquals(PaymentMethods.IDTECH);
			const dataObj = {
				text: Popupmessage,
				buttonname: isIDTech ? this.localization.captions.common.Close : '',
				headertext: '',
				isloaderenable: true,
				isHiddenFieldRequired: isIDTech,
				cardpayment: true,
				isManualEntry: this.isManualKeyInCardEntryMode()
			};
			this.roomOpenDialog(dataObj);
		} else {
			this.ShowTransactionInprogressDialog("", this.$scope.IsRefundFlow ? PaymentAPIFlow.Credit : PaymentAPIFlow.Sale)
		}
	}

	ShowTransactionInprogressDialog(customTransactionMsg: string = "", apiFlow?: PaymentAPIFlow) {
		if (this.$scope.IsMakePaymentScreen && this.dialogRef) {
			this.dialogRef.close();
		} else {
			this.dialog.closeAll();
		}
		let Popupmessage = customTransactionMsg ? customTransactionMsg : this.localization.captions.shop.TransactionInprogressMsg;
		if (apiFlow && !customTransactionMsg) {
			Popupmessage = this._sbs.GetTransactionInProgressMsg(apiFlow, this.$scope.selectedPaymentMethod);
		}
		const dataObj = { text: Popupmessage, buttonname: '', headertext: '', isloaderenable: true };
		this.roomOpenDialog(dataObj);
	}

	/**
   *@method pop-up
   *@function roomOpenDialog()
   *@param input <obj>
   *@description In the popup open the roompayment method
   *@param output <null>
   */

	roomOpenDialog(e: any) {
		this.dialogRef = this.$scope.utils.roomOpenDialog(e, this.onRoomOpenDialogClosed.bind(this));
	}

	onRoomOpenDialogClosed(data: any) {
		if (data) {
			if (
				this.SelectedPaymentMethodEquals(PaymentMethods.IDTECH) &&
				this._sbs.ValidateIDTechCardSwipeData(data) && data != this.localization.captions.common.OK
			) {
				this.$scope.EncryptedCardData = data;
				this.GetHandles();
				this.ShowTransactionInprogressDialog();
				this.$scope.ManualClosePerformed = true;
			} else if (
				this.SelectedPaymentMethodEquals(PaymentMethods.IDTECH) &&
				!this.$scope.EncryptedCardData &&
				data == this.localization.captions.common.Close
			) {
				if (this.IsFolioPosting) {
					this.postingDeleted = true;
					clearTimeout(this.$scope.NoCardSwipeTimeOut);
					const clickReturnValue = {
						from: ActionMode.cancel
					};
					this.$scope.TransactionCompleted.emit(clickReturnValue);
					this.$scope.isAPICallInProgress = false;
				}
				this.$scope.ManualClosePerformed = true;
				return;
			} else {
				this.$scope.utils.ShowErrorMessage(
					this.localization.captions.common.Error,
					this.localization.captions.shop.CardVerificationFailed
				);
				if (this.IsFolioPosting) {
					clearTimeout(this.$scope.NoCardSwipeTimeOut);
					const clickReturnValue = {
						from: ActionMode.cancel
					};
					this.$scope.TransactionCompleted.emit(clickReturnValue);
					this.$scope.isAPICallInProgress = false;
				}
			}
		} else if (data == "") {
			this.$scope.utils.ShowErrorMessage(
				this.localization.captions.common.Error,
				this.localization.captions.shop.CardVerificationFailed
			);
			if (this.IsFolioPosting) {
				clearTimeout(this.$scope.NoCardSwipeTimeOut);
				const clickReturnValue = {
					from: ActionMode.cancel
				};
				this.$scope.EncryptedCardData = "";
				this.$scope.TransactionCompleted.emit(clickReturnValue);
				this.$scope.isAPICallInProgress = false;
			}
		}
		else {
			if (this.IsFolioPosting) {
				this.$scope.EncryptedCardData = "";
				this.$scope.isAPICallInProgress = false;
			}
		}
	}

	FormGetHandleRequest() {
		let RequestBody: HandleRequest;
		switch (this.$scope.selectedPaymentMethod) {
			case PaymentMethods.CreditCard:
				RequestBody = {
					tenderId: this.$scope.selectedpayment.paymentTypeId.toString(),
					originalTenderId: this.$scope.selectedpayment.parentTypeId
				};
				break;
			case PaymentMethods.IDTECH:
				RequestBody = {
					tenderId: this.$scope.selectedpayment.paymentTypeId.toString(),
					originalTenderId: this.$scope.selectedpayment.parentTypeId,
					inquiryInfo: {
						cardData: {
							encryptedData: JSON.stringify({
								deviceType: 'idtechkb',
								encryptedData: this.$scope.EncryptedCardData
							})
						}
					}
				};
				break;
			case PaymentMethods.CompRedemption:
				const userId = this.localization.GetPropertyInfo('UserId');
				RequestBody = {
					tenderId: this.$scope.selectedpayment.paymentTypeId.toString(),
					inquiryInfo: {
						id: this.$scope.CMSForm.controls['cmsSearch'].value,
						type: this.$scope._featureFlagInfo.CMSSearchType,
						cardData: {
							pin: this.CMSPin,
							encryptedData: ""
						}
					},
					inquirerInformation: {
						profitCenterName: this._featureFlagInfo.CMSProfitCenterName,
						profitCenter: this._featureFlagInfo.CMSProfitCenterId,
						employeeId: userId ? userId : ""
					},
					profitCenterId: this._featureFlagInfo.CMSProfitCenterId
				};
				break;
			default:
				break;
		}

		return RequestBody;
	}

	async GetHandles(isOnLoad = false) {
		//Folio Definition will have retail capture card component so no need to invoke GetHandles Call
		if (this.$scope.IsFolioDefinitionScreen) {
			this.$scope.showNonIntegratedCreditCard = false; //To invoke the Onchange in Capture card component
			return;
		} 
		let RequestBody: HandleRequest = this.FormGetHandleRequest();
		this.$scope.TempRoomNo = this.$scope.transitionDetails.controls.roomnumber.value;
		this.$scope.deviceList = [];
		const isIDTechEnabled = (this.$scope.userSessionConfiguration?.isIdtechSred
			|| this.$scope.userSessionConfiguration?.defaultPaymentDevice == IDTech.id);
		if (CardPaymentMethods.includes(this.$scope.selectedPaymentMethod) && isIDTechEnabled) {
			if (!this.$scope.deviceList.includes(IDTechHandle))
				this.$scope.deviceList.push(IDTechHandle);
			if (this.$scope.userSessionConfiguration?.defaultPaymentDevice == IDTech.id) {
				this.$scope.transitionDetails.controls.selectedDevice.patchValue(IDTechHandle.name);
				this.$scope.selectDevice(IDTechHandle);
				//If Default device is IDTech, then no need fetch device list
				if (!this.$scope.EncryptedCardData)
					return;
			}
		}

		if (this.$scope.selectedpayment.paymentTypeId != PaymentMethods.IDTECH) {
			let loaderMsg = {
				[PaymentMethods.CreditCard]: this.$scope.ShopCaptions.RetrieveDeviceList,
				[PaymentMethods.CompRedemption]: this.$scope.ShopCaptions.RetrievePatronDetails
			};
			this._ams.loaderEnable.next(loaderMsg[this.$scope.selectedPaymentMethod]);
		}

		let handleResult: Promise<HandleResponse> = this.$scope.PaymentProcessor.GetHandles(
			RequestBody,
			Number(this.CurrentOutletId),
			this.$scope.CEDS_ByTerminal
		);
		handleResult
			.then((response) => {
				if (response.status) {
					if (response.status.toLocaleLowerCase() == HttpResponseStatus.Success) {
						this.$scope.hostName = response?.hostName;
						this.$scope.ipAddress = response?.ipAddress;
						this.$scope.agentVersion = response?.agentVersion;
						if (CardPaymentMethods.includes(this.$scope.selectedPaymentMethod)
							&& response.paymentHandle?.length > 0) {
							let availableIngenicoDevices = response.paymentHandle;
							availableIngenicoDevices.forEach((device) => {
								if (device?.name && !this.$scope.deviceList.some((x) => x.name == device.name)) {
									this.$scope.deviceList.push(device);
								}
							});
						}
						if (this.$scope.selectedpayment.paymentTypeId === PaymentMethods.IDTECH && this.$scope.EncryptedCardData) {
							this.$scope.selectedDeviceHandle = response.paymentHandle[0].handle;
							if (this.$scope.IsRefundFlow) {
								this.RequestForCredit(this.$scope.CreateRetailItemResponse);
							} else {
								this.RequestForSale(this.$scope.CreateRetailItemResponse);
							}
						} else if (this.$scope.selectedPaymentMethod === PaymentMethods.CompRedemption) {
							let patronId = this.$scope.CMSForm.controls['cmsSearch'].value;
							this.$scope._cmsBusiness.CreatePatronGuest(patronId);
							this.$scope.cmsPlayerHandles = response.paymentHandle;
							this.$scope.cmsPlayerHandles = this._sbs.FilterCMSApplicableBuckets(this.$scope.cmsPlayerHandles);
							this.$scope.cmsPlayerHandles = this.$scope.cmsPlayerHandles.filter(x => this.$scope.ShopCaptions.CMS.handleType[x.type]);
							this.$scope.selectedCMSPlayer = {
								PlayerName: this.$scope.cmsPlayerHandles[0] && this.$scope.cmsPlayerHandles[0].name,
								PatronId: this.$scope.cmsPlayerHandles[0] && this.$scope.cmsPlayerHandles[0].inquiryInfo && this.$scope.cmsPlayerHandles[0].inquiryInfo.id
							}
						}
					} else {
						let getHandlesErrorMessage = response?.errorMessage ?? this.localization.captions.shop.NoDevicesFound;
						let notFoundMsg = {
							[PaymentMethods.CreditCard]: this.localization.captions.shop.NoDevicesFound,
							[PaymentMethods.CompRedemption]: this.localization.captions.shop.NoPlayersFound
						};
						if (!isOnLoad) {
							if (CardPaymentMethods.includes(this.$scope.selectedPaymentMethod)) {
								if (!isIDTechEnabled)
									this.$scope.utils.ShowErrorMessage(
										this.localization.captions.common.Error,
										getHandlesErrorMessage
									);
							} else {
								this.$scope.cmsPlayerHandles = [];
								this.$scope.utils.ShowErrorMessage(
									this.localization.captions.common.Error,
									response.errorMessage ? response.errorMessage
										: notFoundMsg[this.$scope.selectedPaymentMethod]

								);
							}
						}
						this.$scope.EnableCloseTranBtn = false;
					}
				} else {
					this.utils.ToggleLoader(false);
					if (this.IsFolioPosting) {
						const clickReturnValue = {
							getHandles: true,
							from: ActionMode.cancel
						};
						this.$scope.TransactionCompleted.emit(clickReturnValue);
						this.$scope.isAPICallInProgress = false;
					}
				}
				this.SelectDefaultDevice(this.$scope.userSessionConfiguration);
				setTimeout(() => {
					this._ams.loaderEnable.next('');
				}, 1000);
			})
			.catch((err) => {
				if (this.dialogRef) {
					this.dialogRef?.close();
				}
				setTimeout(() => {
					this._ams.loaderEnable.next('');
				}, 1000);
				console.log('Error during GetHandles:' + err.error);
				if (isOnLoad) { return }
				//this.$scope.ResetSelectedPayment();
				//Show Payment Error Prompt
				if (err) {
					if (this.IsFolioPosting && !this.$scope.IsResortFinanceFolioAuthFlow) {
						this.utils.ToggleLoader(false);
						const clickReturnValue = {
							getHandles: true,
							from: ActionMode.cancel
						};
						this.$scope.TransactionCompleted.emit(clickReturnValue);
						this.$scope.isAPICallInProgress = false;
					}
					if (err.error[0] != null && err.error[0].Code != null && err.error[0].Code != '')
						this.payAgentService.PaymentErrorPrompt(err.error[0].Code);
					else if (err.error[0] != null && err.error[0].Message == this.payAgentService.INVALID_TENDER_MSG) {
						return this.utils.ShowErrorMessage(
							this.localization.captions.common.Error,
							this.localization.captions.shop.PayAgentConfigError
						);
					}
					else if (err.error[0] != null && err.error[0].Message) {
						return this.utils.ShowErrorMessage(
							this.localization.captions.common.Error,
							err.error[0].Message
						);
					}
					else {
						return this.utils.ShowErrorMessage(
							this.localization.captions.common.Error,
							this.localization.captions.shop.PayAgentUnavailable
						);
					}
				}
			});

	}

	SelectDefaultDevice(userSessionConfiguration) {
		if (userSessionConfiguration && userSessionConfiguration?.defaultPaymentDevice && CardPaymentMethods.includes(this.$scope.selectedPaymentMethod)) {
			if (userSessionConfiguration?.defaultPaymentDevice.toLowerCase() == IDTech.id) {
				this.$scope.transitionDetails.controls.selectedDevice.patchValue(IDTechHandle.name);
				this.$scope.selectDevice(IDTechHandle);
			} else {
				this.$scope.transitionDetails.controls.selectedDevice.patchValue(
					userSessionConfiguration?.defaultDeviceName
				);
				let defaultDevice = this.$scope.deviceList.filter((x) => {
					return x.name == userSessionConfiguration?.defaultDeviceName;
				})[0];
				if (defaultDevice) {
					this.$scope.selectDevice(defaultDevice);
				}
			}
		}
	}

	async ValidatePayRequest(SaleResult?
		, Ismember: boolean = false
		, saleAmount = 0
		, voucherRedeemTransactionId: string = ""
		, compRedeemTransactionId: string = ""
	) 
	{		
		if(this.$scope.IsResortFinanceMakePaymentFlow || !(this.$scope.IsRoomOrGroupCharge && this._featureFlagInfo.IsSkipPMAgent(this.$scope.selectedpayment))){
			try 
			{	
				const originalSaleRes = _.cloneDeep(SaleResult);
				if (Array.isArray(SaleResult) && SaleResult.length) SaleResult = SaleResult[0];
				this.$scope.disabledRadiobool = true;
				const currentSaleAmt = this.$scope.getAmountPaid(SaleResult ? SaleResult.amount : '', 'authorisedAmount', saleAmount);
				let roomNo = this.$scope.AdditionalInputs?.roomNumber ?? "", guestName = null, roomReference = null, searchValue = null, searchType = null;
				if (this.$scope.IsRoomOrGroupCharge && this.$scope.selectedGuestRoom) {
					roomNo = this.$scope.selectedGuestRoom.RoomNumber;
					guestName = this.$scope.selectedGuestRoom.GuestName;
					roomReference = this.$scope.selectedGuestRoom.RoomReference;
					searchValue = this.$scope?.selectedGuestRoom?.SearchValue;
					searchType = this.$scope?.selectedGuestRoom?.SearchType;
				}
				if (this._ss.isAutomaticMemberDiscFlow) {
					this.$scope.CurrentTryPayResponse = this.$scope.MemDiscFlowCurrentTryPayResponse;
					this._ss.isAutomaticMemberDiscFlow = false;
				}
				if (this.$scope.CurrentTryPayResponse != null || this.IsFolioPosting) {
					let validatePayReq: ValidatePayRequest = {
						paymentMethodId: this.$scope.selectedpayment.id,
						tenderId: this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile) ? PaymentMethods.CreditCard : this.$scope.selectedpayment.paymentTypeId,
						parentTenderId: this.$scope.selectedpayment.parentTypeId,
						payAgentResponse: NonPayAgentMethods.includes(this.$scope.selectedPaymentMethod)
						|| this.$scope.isCustomPaymentMethod || !SaleResult || this.$scope.SkipRoomChargePosting 
						|| (this.$scope.selectedPaymentMethod == PaymentMethods.CompRedemption && this._featureFlagInfo.SkipPMAgentCMS)
						|| this.$scope.SkipPMAgent ? null : {
							payAgentID: SaleResult.payAgentId,
							status: SaleResult.status,
							transactionDetails: SaleResult.transactionDetails,
							transactionKey: SaleResult.transactionKey,
							errorMessage: SaleResult.errorMessage
						},
						gatewayResponse: this.$scope.SkipPMAgent && SaleResult ? SaleResult.transactionDetails : "",
						cardInfo: this.SelectedPaymentMethodEquals(PaymentMethods.CreditCard) || this.SelectedPaymentMethodEquals(PaymentMethods.IDTECH) ? {
							cardNumber: SaleResult.account.id,
							cardHolderName: SaleResult.account.name,
							entryMode: SaleResult.cardInfo.entryMode,
							issuerType: SaleResult.cardInfo.issuer,
							cardExpiration: SaleResult.cardInfo.cardExpiration,
							cardType: SaleResult.cardInfo.cardType
						} : null,
						amount: currentSaleAmt,
						surcharge: this.$scope.surchargeAmount,
						aCESPaymentRecord: Ismember && originalSaleRes ? originalSaleRes : null,
						cMSPaymentRecord: this._cmsBusiness.ConstructCMSPaymentRecord(this.$scope, this.$scope, SaleResult, voucherRedeemTransactionId, compRedeemTransactionId),
						giftcardPaymentRecord: this._giftcardBusiness.ConstructGiftcardPaymentRecord(this.$scope.selectedGiftCard, currentSaleAmt),
						resortFinanceFolioPostingPaymentRecord: this.SelectedPaymentMethodEquals(PaymentMethods.PostToFolio) && this.$scope.folioSection ? SaleResult : null,
						aRPostingPaymentRecord: this.$scope.currentARPostResponse,
						roomNumber: roomNo ? roomNo : '0',
						reservationName: guestName,
						referenceComment: this.$scope.paymentComments,
						roomReference: roomReference,
						userId: this.$scope.authorizedUserId ? this.$scope.authorizedUserId : '',
						sourceType: this.$scope.AdditionalInputs.sourceType ? this.$scope.AdditionalInputs.sourceType : SourceTypeConstant.RetailTransaction.toString() ,
						sourceTypeId: this.$scope.AdditionalInputs.sourceTypeId ? this.$scope.AdditionalInputs.sourceTypeId : this.$scope.CreateRetailItemResponse?.transactionData?.retailTicketNumber,
						folioNumber: this.$scope.AdditionalInputs.folioNumber,
						retailTicketNumber: this.OrderNumberForGateway,
						machineId: this.localization.GetMachineId(),
						hostName: SaleResult?.hostName,
						agentVersion: SaleResult?.agentVersion,
						cashPaymentDetails: !this.$scope.IsRefundFlow && this.$scope.selectedpayment.paymentTypeId == PaymentMethods.Cash || this.$scope.selectedpayment.parentTypeId == PaymentMethods.Cash
							&& this.$scope.cashPaymenHandleData?.paymentStaus == 'cashpaid' ? {
							amountDue: this.$scope.cashPaymenHandleData?.amtRequested,
							cashReceived: this.$scope.cashPaymenHandleData?.amtReceived,
							changeDue: this.$scope.cashPaymenHandleData?.amtBalanceDue,
							isReturn: false
						} : null,
						ipAddress: SaleResult?.ipAddress,
						postId: this.$scope.AdditionalInputs.postId,
						requestId: SaleResult?.requestId,
						guestGuid: this.$scope?.payeeInfo?.guestProfileId,
						transactionState: SaleResult?.transactionState,
						propertyId: this.PropertyInfo.PropertyId, //Current PropertyId, Passing this value to override RF PropertyId
						lodgingData: SaleResult?.lodgingData,
						searchValue: searchValue,
						searchType: searchType != null ? searchType : SearchTypes.None,
						tokenRefId: this.$scope.nonIntegratedPaytransId > 0 && this.$scope.isNonIntegratedCreditCard ? this.$scope.nonIntegratedPaytransId : this.$scope.CurrentActiveCard?.cof_PaymentRef,  
					}
	
					this.ShowTransactionInprogressDialog('', PaymentAPIFlow.ValidatePay);
					this.$scope.isSurchargedCollected = (this.$scope.surchargeAmount > 0);
					
					let validatePayRoute = "";
					const isSkipPMAgentCardPayment = this.$scope.SkipPMAgent && CardPaymentMethods.includes(this.$scope.selectedPaymentMethod);
					if (this.IsResortFinanceFolioPosting) {
						validatePayRoute = isSkipPMAgentCardPayment ? RetailRoutes.FolioValidatePayV2 : RetailRoutes.FolioValidatePay
					} else {
						validatePayRoute = isSkipPMAgentCardPayment ? RetailRoutes.ValidatePayV2 : RetailRoutes.RetailValidatePay
					}

					let validatePayResponse: any = this.http.CallApiAsync<any>({
						host: GlobalConst.Host.payment,
						callDesc: validatePayRoute,
						method: HttpMethod.Post,
						body: validatePayReq,
						uriParams: { outletId: this.CurrentOutletId },
						showError: true
					});
					console.log(validatePayResponse.result);
	
					validatePayResponse
						.then((response) => {
							let validatePayResult: ValidatePayResponse = response.result;
							if (validatePayResult.status) {								
								//Update the Gratuity as the Creditcard Payment is realized
								if (this.$scope.isTipApplicable && this.SelectedPaymentMethodEquals(PaymentMethods.CreditCard) && SaleResult.amount?.tipAmount > 0) {
									this.$scope.ApplyTipAsGratuity.emit({
										paymentTransactionId: validatePayResult.transactionId,
										quickUserId: this.$scope.authorizedUserId,
										tipAmount: SaleResult.amount?.tipAmount
									});
								}
								if (this.$scope.isGiftCardSelected && this.$scope?.selectedGiftCard?.cardType == GlobalConst.GiftCardType.ExternalGiftCard && this.$scope.IsShift4GC) {
									const maskCardNumber = (cardNumber: string): string => { return cardNumber?.replace(/.(?=.{4})/g, '*') }
									this.$scope.selectedGiftCard.maskedCardNumber = maskCardNumber(validatePayResult?.paymentManagerResponse?.clientInfo?.accountNumber)
								}
								if (!this.IsFolioPosting) {
									this.ConstructSettlementHistory(validatePayResult, SaleResult);
								}
								if (this.IsFolioPosting) {
									if (this.$scope.IsMakePaymentScreen && this.dialogRef) {
										this.dialogRef.close();
									} else {
										this.dialog.closeAll();
									}
	
									if (this.$scope.AdditionalInputs?.isMemberPayment) {
										let transactionData: TransactionInfoforCheckZoom =
										{
											id: this.$scope.CreateRetailItemResponse?.id,
											outletId: this.$scope.CreateRetailItemResponse?.transactionData?.outletId,
											isVoided: this.$scope.CreateRetailItemResponse?.transactionData?.isVoided,
											retailTicketNumber: this.$scope.CreateRetailItemResponse?.transactionData?.retailTicketNumber,
											transactionDate: this.$scope.CreateRetailItemResponse?.transactionData?.transactionDate
										};
										this.$scope._memberService.ProcessOrderForMember(
											this.$scope.payeeInfo
											, this.$scope.GridItems
											, this.$scope.AdditionalInputs?.checkNumber
											, currentSaleAmt
											, this.$scope.totalAmount
											, this.$scope.tempSettlementHistory
											, transactionData
										);
									}
	
									const clickReturnValue = {
										from: ActionMode.create,
										formValues: this.$scope.transitionDetails.getRawValue(),
										form: this.$scope.transitionDetails,
										transactionId: validatePayResult.transactionId,
										maskedCardNumber: SaleResult && SaleResult.account && SaleResult.account.id,
										totalAuthorizedAmount: validatePayResult.paymentManagerResponse && validatePayResult.paymentManagerResponse.amount,
										checkReponse: this.$scope.checkResponse,
										selectedPayment: this.$scope.selectedpayment,
										saleAmount: currentSaleAmt,
										surcharge: this.$scope.surchargeAmount,
										arAccountNumber: this.$scope.selectedARAccount ? this.$scope.selectedARAccount.accountNumber : '',
										logType: "device transaction"
									};
									
									if (this._giftcardBusiness.CashBackCallRequired) {
										const sourceTypeWithUniq = `${this.$scope.AdditionalInputs?.sourceTypeId?.toString()}${this.utils.GetRandomNumber(6)}`?.slice(0, 9);
										const sourceTypeId = this.$scope.AdditionalInputs?.sourceTypeId?.toString();
										const orderNumber = ExternalGCMethods.includes(this.$scope.selectedPaymentMethod) ? sourceTypeWithUniq : sourceTypeId;
										this._giftcardBusiness.PerformGiftCardCashBack(this.$scope.selectedGiftCard
											, orderNumber, this.$scope.AdditionalInputs);
									}

									this.$scope.cardDetailsBool = false;
									this.$scope.TransactionCompleted.emit(clickReturnValue);
									this.$scope.ResetSelectedPayment();
									this.$scope.isAPICallInProgress = false;
														
									if(this.IsResortFinanceFolioPosting){
										this.$scope.CancelResortFinancePaymentMethodValues();
									}
									return;
								}
								this.HandlePayRequest(validatePayResult, SaleResult);
							}
						})
						.catch((error) => {
							if (this.IsFolioPosting) {
								const clickReturnValue = {
									from: ActionMode.cancel
								};
								this.$scope.TransactionCompleted.emit(clickReturnValue);
								this.$scope.isAPICallInProgress = false;
								
								if(this.IsResortFinanceFolioPosting){
									this.$scope.CancelResortFinancePaymentMethodValues();
								}
							}
							console.log('Validate Pay Response:' + error.error);
							this.HandlePostSaleFailure(error);
						});
				}			
			} catch (error) {
				console.log(error);
			}
		}	
	}

	ConstructSettlementHistory(validatePayResult: ValidatePayResponse, SaleResult) {
		const CardMethods = [PaymentMethods.CreditCard, PaymentMethods.IDTECH];
		const IsCardType: boolean = CardMethods.includes(this.$scope.selectedPaymentMethod);
		const patronId: string = this.$scope.selectedCMSPlayer && this.$scope.selectedCMSPlayer.PatronId;
		const voucherName: string = this.$scope.selectedVoucher && this.$scope.selectedVoucher.name;
		let maskedtWalletNumber: string = SaleResult?.cardInfo?.maskedCardNumber;
		const tempEntryMode = this.utils.GetOriginalTenderId(this.$scope.selectedpayment.paymentTypeId, this.$scope.selectedpayment.parentTypeId) === PaymentMethods.CardOnFile
			? this.$scope.CurrentActiveCard.entryMode
			: '';
		const tempCardNumber = this.utils.GetOriginalTenderId(this.$scope.selectedpayment.paymentTypeId, this.$scope.selectedpayment.parentTypeId) === PaymentMethods.CardOnFile
			? this.payAgentService.MaskCreditCardNumber(this.$scope.CurrentActiveCard.cardNumber)
			: '';
		const tempIssuerType = this.utils.GetOriginalTenderId(this.$scope.selectedpayment.paymentTypeId, this.$scope.selectedpayment.parentTypeId) === PaymentMethods.CardOnFile
			? this.$scope.CurrentActiveCard.issuerType
			: '';

		if (this.$scope.isGiftCardSelected && this.$scope.selectedGiftCard?.isCardActive) {
			let saleAmount = this.$scope.getAmountPaid(SaleResult ? SaleResult.amount : '', 'authorisedAmount');
			this.$scope.selectedGiftCard.UpdatedBalance = this._giftcardBusiness.CashBackCallRequired == true ? 0 : Math.abs(this.$scope.selectedGiftCard.amount - saleAmount);
		}
		if (this.$scope.multipleMemberPayment && this.$scope.selectedMember && !this.$scope.payeeInfo.arAccountNumber) {
			this.$scope.payeeInfo.arAccountNumber = SaleResult.arAccountNumber;
		}


		const SettleMentObject: PaymentHistory = {
			paymentReferenceId: validatePayResult?.transactionId,
			paymentMethodId: this.$scope?.selectedpayment?.paymentTypeId,
			parentTenderId: this.$scope?.selectedpayment?.parentTypeId,
			paymentMethod: this.$scope?.IsFolioPaymentMethod ? this.$scope?.selectedpayment?.paymentMethod :
				this._sbs?.FormatPaymentMethodLabelForSettlementHistory(
					this.$scope?.selectedpayment,
					this.$scope?.paymentMethods,
					patronId,
					this.$scope?.CMSForm?.controls['cmsPaymentSelection'].value,
					voucherName,
					this.$scope?.selectedGiftCard,
					this.$scope?.selectedARAccount,
					this.$scope?.selectedGuestRoom,
					this.$scope?.payeeInfo,
					maskedtWalletNumber
				),
			amount: this.$scope.getAmountPaid(SaleResult ? SaleResult?.amount : '', 'authorisedAmount'),
			surcharge: this.$scope?.surchargeAmount,
			issuerType: IsCardType ? SaleResult?.cardInfo?.issuer.toUpperCase() : tempIssuerType,
			entryMode: IsCardType ? SaleResult?.cardInfo?.entryMod : tempEntryMode,
			cardNumber: IsCardType ? this.payAgentService.MaskCreditCardNumber(SaleResult.account.id) : tempCardNumber,
			additionalDetails: this._sbs.GetAdditionalPaymentDetails(this.$scope),
			paymentReferenceComment: this.$scope.paymentComments,
			cashPaymenHandle: this.SelectedPaymentMethodEquals(PaymentMethods.Cash) ? this.$scope.cashPaymenHandleData : null,
			memberInfo: (this.$scope.selectedPaymentMethod === PaymentMethods.ARPost) ? this._sbs.MapPaymentForMember(
				this.$scope.payeeInfo, validatePayResult.transactionId, this.$scope.memberGuestList) : null,
			paymentMethodInfo: this.$scope.selectedpayment,			
			folioInvoiceNumber: this.$scope?.currentPaymentPostfolioData?.selectedGuest?.folios ? this.$scope?.currentPaymentPostfolioData?.selectedGuest?.folios[0]?.folioInvoiceNumber??'' : this.$scope.postfolioData?.folioInvoiceNumber??'',
			folioSourceType: this.$scope?.currentPaymentPostfolioData?.selectedGuest?.folios ? this.$scope?.currentPaymentPostfolioData?.selectedGuest?.folios[0]?.sourceType??SourceType.AddOn.toString() : this.$scope.postfolioData?.sourceType??SourceType.AddOn.toString(),
			folioSourceTypeId: this.$scope?.currentPaymentPostfolioData?.selectedGuest?.guestId??'',
			folioGuestName: this.$scope?.currentPaymentPostfolioData?.selectedGuest?.name??'',
			interfaceGuestId: this.$scope?.currentPaymentPostfolioData?.selectedFolio?.interfaceGuestId??'0',
			tenderReducesDiscount: this.$scope?.selectedpayment?.tenderReducesDiscount,
			tenderReducesTax:  this.$scope.selectedpayment.isAutoRemoveTax,
			discountExemptRatio:  this._ss.Ticket.tenderReducesDiscountRatio,
			taxExemptRatio:  this._ss.Ticket.compTaxExemptRatio,
		};
		this.$scope.tempSettlementHistory.unshift(SettleMentObject);
		this.$scope.PaymentHistoryEmit.emit(this.$scope.tempSettlementHistory);
	}

	ConstructPayRequest(Response: ValidatePayResponse, saleResult = null) {
		if (this.$scope.CreateRetailItemResponse) {
			const amount = this.$scope.multipleMemberPayment && saleResult ? saleResult.amount : (Response.paymentManagerResponse ? Response.paymentManagerResponse.amount : '');
			return {
				paymentManagerResponse: {
					amount: {
						requestAmount: this.$scope.getAmountPaid(
							amount,
							'requestAmount'
						),
						authorisedAmount: this.$scope.getAmountPaid(
							amount,
							'authorisedAmount'
						),
						tipAmount: this.$scope.getAmountPaid(
							amount,
							'tipAmount'
						)
					},
					inquirerInfo: NonPayAgentMethods.includes(this.$scope.selectedPaymentMethod)
						|| Response.status && Response.paymentManagerResponse == null //To avoid Pay call failure when ValidatePay API failures, status will still be true and payment mgr response will be null
						|| V1GiftCardMethods.includes(this.$scope.selectedPaymentMethod) || this.$scope.isCustomPaymentMethod || this.$scope.SkipRoomChargePosting || (this.$scope.selectedPaymentMethod == PaymentMethods.CompRedemption && this._featureFlagInfo.SkipPMAgentCMS)
						? {
							terminalId: "1",
							orderNumber: "1", //To be Removed
							profitCenter: this.$scope.profitCenter,
							isPartialTenderAllowed: this.$scope.GatewayConfiguration && this.$scope.GatewayConfiguration?.isPartialPayAllowed,
							tenderId: this.$scope.selectedpayment.paymentTypeId.toString(),
							clientId: "",
							postingId: ""
						}
						: Response.paymentManagerResponse && Response.paymentManagerResponse.inquirerInfo,
					transactionTime: NonPayAgentMethods.includes(this.$scope.selectedPaymentMethod) || this.$scope.isCustomPaymentMethod || this.$scope.SkipRoomChargePosting || (this.$scope.selectedPaymentMethod == PaymentMethods.CompRedemption && this._featureFlagInfo.SkipPMAgentCMS)
						? this.PropertyInfo.CurrentDTTM
						: Response?.paymentManagerResponse?.transactionTime
				},
				transactionId: Response.transactionId
			};
		}
	}

	async HandlePayRequest(validatePayResult: ValidatePayResponse, SaleResult, refundedAsGiftcard: boolean = false) {
		validatePayResult = this.ConstructPayRequest(validatePayResult, SaleResult);
		const CardMethods = [PaymentMethods.CreditCard, PaymentMethods.IDTECH];
		const IsCardType: boolean = CardMethods.includes(this.$scope.selectedPaymentMethod);
		const payRequestResult = await this.PayRequest(validatePayResult)
		if (!payRequestResult) {
			this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(10724));
			return;
		} else {
			let patronId: string = this.$scope.selectedCMSPlayer && this.$scope.selectedCMSPlayer.PatronId;
			let voucherName: string = this.$scope.selectedVoucher && this.$scope.selectedVoucher.name;
			let maskedWalletNumber: string = SaleResult?.cardInfo?.maskedCardNumber;
			const tempEntryMode = this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile)
				? this.$scope.CurrentActiveCard.entryMode
				: '';
			const tempCardNumber = this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile)
				? this.payAgentService.MaskCreditCardNumber(this.$scope.CurrentActiveCard.cardNumber)
				: '';
			const tempIssuerType = this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile)
				? this.$scope.CurrentActiveCard.issuerType
				: '';

			if (this.$scope.isGiftCardSelected && this.$scope.selectedGiftCard.isCardActive) {
				let saleAmount = this.$scope.getAmountPaid(SaleResult ? SaleResult.amount : '', 'authorisedAmount');
				this.$scope.selectedGiftCard.UpdatedBalance = this._giftcardBusiness.CashBackCallRequired == true ? 0 : Math.abs(this.$scope.selectedGiftCard.amount - saleAmount);
			}

			let SettleMentObject: PaymentHistory;
			
			if((this._sbs.IsRoomOrGroupCharge && this._featureFlagInfo.IsSkipPMAgent(this.$scope.selectedpayment)) || this.$scope.IsHotelCompPayment){
				SettleMentObject = {
					paymentReferenceId: validatePayResult.transactionId,
					paymentMethodId: this.$scope.selectedpayment.paymentTypeId,
					parentTenderId: this.$scope.selectedpayment?.parentTypeId,
					paymentMethod: this.$scope.IsFolioPaymentMethod ? this.$scope.selectedpayment.paymentMethod :
						this._sbs.FormatPaymentMethodLabelForSettlementHistory(
							this.$scope.selectedpayment,
							this.$scope.paymentMethods,
							patronId,
							this.$scope.CMSForm.controls['cmsPaymentSelection'].value,
							voucherName,
							this.$scope.selectedGiftCard,
							this.$scope.selectedARAccount,
							this.$scope.selectedGuestRoom,
							this.$scope.payeeInfo, 
							this.$scope.postfolioData?.folioInvoiceNumber,
							maskedWalletNumber
						),
					amount: this.$scope.getAmountPaid(SaleResult ? SaleResult.amount : '', 'authorisedAmount'),
					surcharge: this.$scope.surchargeAmount,
					tipAmount: SaleResult?.amount?.tipAmount,
					additionalDetails: this._sbs.GetAdditionalPaymentDetails(this.$scope),
					paymentReferenceComment: this.$scope.paymentComments,
					cashPaymenHandle: this.SelectedPaymentMethodEquals(PaymentMethods.Cash) ? this.$scope.cashPaymenHandleData : null,
					disableDelete: refundedAsGiftcard,
					memberInfo: (this.$scope.selectedPaymentMethod === PaymentMethods.ARPost) ? this._sbs.MapPaymentForMember(
						this.$scope.payeeInfo, validatePayResult.transactionId, this.$scope.memberGuestList) : null,
					paymentMethodInfo: this.$scope.selectedpayment,
					folioInvoiceNumber: this.$scope?.currentPaymentPostfolioData?.selectedGuest?.folios ? this.$scope?.currentPaymentPostfolioData?.selectedGuest?.folios[0]?.folioInvoiceNumber??'' : this.$scope.postfolioData?.folioInvoiceNumber??'',
					folioSourceType: this.$scope?.currentPaymentPostfolioData?.selectedGuest?.folios ? this.$scope?.currentPaymentPostfolioData?.selectedGuest?.folios[0]?.sourceType??SourceType.AddOn.toString() : this.$scope.postfolioData?.sourceType??SourceType.AddOn.toString(),
					folioSourceTypeId: this.$scope.currentPaymentPostfolioData?.selectedGuest?.guestId,
					folioGuestName: this.$scope.currentPaymentPostfolioData?.selectedGuest?.name,
					interfaceGuestId: this.$scope.currentPaymentPostfolioData?.selectedFolio?.interfaceGuestId,
					tenderReducesDiscount: this.$scope?.selectedpayment?.tenderReducesDiscount,
					tenderReducesTax: this.$scope.selectedpayment.isAutoRemoveTax,
					discountExemptRatio:  this._ss.Ticket.tenderReducesDiscountRatio,
			        taxExemptRatio:  this._ss.Ticket.compTaxExemptRatio,
				};
			}
			else{
				SettleMentObject = {
					paymentReferenceId: validatePayResult.transactionId,
					paymentMethodId: this.$scope.selectedpayment.paymentTypeId,
					parentTenderId: this.$scope.selectedpayment?.parentTypeId,
					paymentMethod: this.$scope.IsFolioPaymentMethod ? this.$scope.selectedpayment.paymentMethod :
						this._sbs.FormatPaymentMethodLabelForSettlementHistory(
							this.$scope.selectedpayment,
							this.$scope.paymentMethods,
							patronId,
							this.$scope.CMSForm.controls['cmsPaymentSelection'].value,
							voucherName,
							this.$scope.selectedGiftCard,
							this.$scope.selectedARAccount,
							this.$scope.selectedGuestRoom,
							this.$scope.payeeInfo, 
							this.$scope.postfolioData?.folioInvoiceNumber,
							maskedWalletNumber
						),
					amount: this.$scope.getAmountPaid(SaleResult ? SaleResult.amount : '', 'authorisedAmount'),
					surcharge: this.$scope.surchargeAmount,
					tipAmount: SaleResult?.amount?.tipAmount,
					issuerType: IsCardType ? SaleResult.cardInfo.issuer.toUpperCase() : tempIssuerType,
					entryMode: IsCardType ? SaleResult.cardInfo.entryMod : tempEntryMode,
					cardNumber: IsCardType ? this.payAgentService.MaskCreditCardNumber(SaleResult.account.id) : tempCardNumber,
					additionalDetails: this._sbs.GetAdditionalPaymentDetails(this.$scope),
					paymentReferenceComment: this.$scope.paymentComments,
					cashPaymenHandle: this.SelectedPaymentMethodEquals(PaymentMethods.Cash) ? this.$scope.cashPaymenHandleData : null,
					disableDelete: refundedAsGiftcard,
					memberInfo: (this.$scope.selectedPaymentMethod === PaymentMethods.ARPost) ? this._sbs.MapPaymentForMember(
						this.$scope.payeeInfo, validatePayResult.transactionId, this.$scope.memberGuestList) : null,
					paymentMethodInfo: this.$scope.selectedpayment,
					folioInvoiceNumber: this.$scope?.currentPaymentPostfolioData?.selectedGuest?.folios ? this.$scope?.currentPaymentPostfolioData?.selectedGuest?.folios[0]?.folioInvoiceNumber??'' : this.$scope.postfolioData?.folioInvoiceNumber??'',
					folioSourceType: this.$scope?.currentPaymentPostfolioData?.selectedGuest?.folios ? this.$scope?.currentPaymentPostfolioData?.selectedGuest?.folios[0]?.sourceType??SourceType.AddOn.toString() : this.$scope.postfolioData?.sourceType??SourceType.AddOn.toString(),
					folioSourceTypeId: this.$scope.currentPaymentPostfolioData?.selectedGuest?.guestId,
					folioGuestName: this.$scope.currentPaymentPostfolioData?.selectedGuest?.name,
					interfaceGuestId: this.$scope.currentPaymentPostfolioData?.selectedFolio?.interfaceGuestId,
					discountExemptRatio:  this._ss.Ticket.tenderReducesDiscountRatio,
					taxExemptRatio:  this._ss.Ticket.compTaxExemptRatio,
					tenderReducesDiscount: this.$scope?.selectedpayment?.tenderReducesDiscount,
					tenderReducesTax: this.$scope.selectedpayment.isAutoRemoveTax,
				};
			}
			
			this.$scope.SettlementHistory.unshift(SettleMentObject);
			this.AlterSettlementHistory();
			this.$scope.PaymentHistoryEmit.emit(this.$scope.SettlementHistory);
			this.$scope.ConfirmationPopupShown = false;
			this.dialog.closeAll();
			this.$scope.isAPICallInProgress = false;
			this.$scope.CurrentTryPayResponse = null;
			/* PAYMENT SUCCESS DIALOG - COMMENTED - TO REDUCE THE NO OF CLICKS PER TRANSACTION
			if (
				this.$scope.selectedpayment.paymentTypeId == PaymentMethods.CreditCard ||
				this.$scope.selectedpayment.paymentTypeId == PaymentMethods.IDTECH ||
				this.$scope.selectedpayment.paymentTypeId == PaymentMethods.CompRedemption
			) {
				const Popupmessage = this.localization.captions.shop.TrnsactionSuccessMsg;
				const dataObj = {
					text: Popupmessage,
					buttonname: this.localization.captions.common.okay,
					headertext: this.localization.captions.shop.Sale,
					icon: 'icon-success-icon'
				};
				this.roomOpenDialog(dataObj);
			} else if (this.$scope.selectedpayment.paymentTypeId === PaymentMethods.RoomCharge) {
				const fullPayment = this.$scope.localization.localizeCurrency(this.$scope.getSaleAmt().toString());
				const partialPaymentAmount = this.localization.localizeCurrency(
					this.$scope.transitionDetails.controls.amounttendered.value
				);
				const paymentBalanceText = this.localization.replacePlaceholders(
					this.$scope.ShopCaptions.AddedToRoomChrgSuccessMsg,
					['amount', 'roomNumber'],
					[this.$scope.multiplePayment ? partialPaymentAmount : fullPayment, this.$scope.selectedGuestRoom.RoomNumber]
				);
				const dataObj = {
					text: paymentBalanceText,
					buttonname: this.localization.captions.common.okay,
					headertext: this.localization.captions.userConfig.wellDone,
					icon: 'icon-success-icon'
				};
				this.roomOpenDialog(dataObj);
			}*/

			if (this.$scope.multipleMemberPayment) {
				if (this.$scope.selectedMember) {
					if (this.$scope.selectedMember.playerId == 0) {
						const member = this.$scope.memberGuestList.find(x => x.memberCardNumber === this.$scope.selectedMember.memberCardNumber);
						if (member) { this.$scope.memberGuestList.find(x => x.memberCardNumber === this.$scope.selectedMember.memberCardNumber).processed = true; }
					}
					else {
						const member = this.$scope.memberGuestList.find(x => x.playerId === this.$scope.selectedMember.playerId);
						if (member) { this.$scope.memberGuestList.find(x => x.playerId === this.$scope.selectedMember.playerId).processed = true; }
					}
					if (this.$scope.memberGuestList.some(x => !x.processed)) {
						this.$scope.transitionDetails.controls.amounttendered.patchValue('');
						this.CalculateSettledAmount();
						this.PatchRemainingAmountAndHighlight(this.$scope.remainingAmount);
						this.$scope._memberService.ProceedMultiplePaymentsForMembers(this);
						return;
					} else {
						this.$scope.ConfirmationPopupShown = false;
						this.$scope.multipleMemberPayment = false;
						this.$scope.payeeInfo = this.$scope.original_payeeInfo;
					}
				}
			}

			if (this.$scope.multiplePayment && this.$scope.folioSection) {
				this.$scope.folioSection = false;
				this.$scope.postfolioData = null;
				this.$scope.postToFolioInfo = null;
			}
			this.CheckSettlementStatusAndUpdateButtons();
			this.$scope._memberService.EnableDisableARPostandRedeemRoundPaymentMethod(this.$scope);
		}
	}

	HandleSaleFailure(errorResponse) {
		this.dialog.closeAll();
		//this.$scope.ResetSelectedPayment();		
		this.$scope.EnableCloseTranBtn = false;
		this.$scope.isAPICallInProgress = false;
		this.$scope.ConfirmationPopupShown = false;
		console.log(`Error during RequestSale :${errorResponse.error}`);
		//Show Payment Error Prompt
		if (
			errorResponse.error &&
			errorResponse.error[0] != null &&
			errorResponse.error[0].Code != null &&
			errorResponse.error[0].Code != ''
		) {
			this.CreateInterfacePostingError(this.GetPaymentErrorMessage(errorResponse.error[0].Code), 'Sale Failure', 0, '16');
			this.$scope.EnableCloseTranBtn = (errorResponse.error?.[0]?.Code == PaymentErrorCodes.DeviceUserPressedCancel);
			this.payAgentService.PaymentErrorPrompt(errorResponse.error[0].Code, errorResponse.error[0].Message);
		}
		else {
			this.CreateInterfacePostingError(this.localization.captions.shop.PMUnexpectedError, 'Sale Failure', 0, '16');
			this._sbs.ExtractAndDisplayPaymentMgrError(errorResponse);
		}
	}

	PatchRemainingAmountAndHighlight(remainingAmount: number, clearCurrentValBeforePatch = false) {
		if (clearCurrentValBeforePatch) { this.$scope.transitionDetails.controls.amounttendered.setValue('', { emitEvent: false }); }
		const amountEntered = this.$scope.transitionDetails.controls.amounttendered.value;
		//If value already present then retain same value
		if (Math.abs(amountEntered) != 0 && Math.abs(amountEntered) != Math.abs(this.$scope.remainingAmount)) {
			remainingAmount = amountEntered;
		}
		this.$scope.transitionDetails.controls.amounttendered.setValue(this.localization.localizeCurrency(remainingAmount, false), { emitEvent: false });
		setTimeout(() => {
			if (this.$scope.amounttenderedField?.nativeElement) {
				this.$scope.tenderedAmount(false);
				this.$scope.amounttenderedField?.nativeElement?.select();
				this.$scope.amounttenderedField?.nativeElement?.focus();
			}
		}, 100);
	}

	SwitchToMultiPayment(SaleData: any, isPartialPaymentCardOnFile: boolean = false) {
		this.$scope.disabledRadiobool = true;
		this.$scope.PaymentOption({ id: 2 });
		this.$scope.transitionDetails.controls.paymentMode.setValue(2);
		if (this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile)) {
			this.PatchRemainingAmountAndHighlight(SaleData.paymentManagerResponse.amount.authorisedAmount);
		} else {
			this.PatchRemainingAmountAndHighlight(SaleData.amount.authorisedAmount);
		}
		if (!isPartialPaymentCardOnFile) {
			this.SendTryPayRequest(
				this.$scope.CreateRetailItemResponse?.transactionDetails[0]?.transactionId,
				this.$scope.selectedpayment.paymentTypeId,
				true,
				SaleData
			);
		}
	}

	switchToMultiMemberPayment(member: MemberPayeeInfo) {
		this.$scope.disabledRadiobool = true;
		this.$scope.multiplePayment = true;
		this.$scope.selectedMember = member;
		this.$scope.Memberpin = member.memberPin;
		this.$scope.payeeInfo = member.payeeInfo
		this.$scope.ConfirmationPopupShown = true;
		this.$scope.multipleMemberPayment = true;
		this.SendTryPayRequest(
			this.$scope.CreateRetailItemResponse.transactionDetails[0]?.transactionId,
			this.$scope.selectedpayment.paymentTypeId,
			false,
			null,
			member
		);
	}

	giftcardPostingDeletion() {
		if (this.IsFolioPosting) {
			const clickReturnValue = {
				from: ActionMode.cancel
			};
			this.dialogRef.close();
			this.utils.ToggleLoader(false);
			this.$scope.TransactionCompleted.emit(clickReturnValue);
			this.$scope.isAPICallInProgress = false;
		}
	}

	CaptureCardWithIDTechDevice() {
		this.ShowCardSwipeDialog();
		this.$scope.NoCardSwipeTimeOut = setTimeout(() => {
			if (
				this.$scope.selectedpayment.paymentTypeId === PaymentMethods.IDTECH &&
				!this.$scope.EncryptedCardData &&
				!this.$scope.ManualClosePerformed
			) {
				this.dialog.closeAll();
				this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(10725));
				this.giftcardPostingDeletion();
				//this.$scope.ResetSelectedPayment();
			}
		}, GlobalConst.IDTechCardSwipeTimeOut * 1000);
	}

	mapTransactionDetail(lineItems: LineItem[]) {
		let details: TransactionDetailUpdate[] = [];
		lineItems.forEach(item => {
			details.push({
				baseTax: item.baseTax,
				tax: item.tax,
				itemId: item.itemId,
				linkedTax: item.linkedTax,
				transactionDetailId: this._ss.CreateItemResponse.transactionDetails.find(x => x.lineNumber == item.index).id,
				serviceChargeGratuity: (item.gratuities && item.gratuities.length > 0) || (item.serviceCharges && item.serviceCharges.length > 0) ? this.mapServiceChargeGratuity(item, item.serviceChargeTax, item.gratuityTax) : null
			})
		})
		return details;
	}

	mapServiceChargeGratuity(lineItem: LineItem, serviceChargeTax, gratTax) {
		let res: ServiceChargeGratuityUpdate[] = [];
		lineItem.gratuities?.forEach(elem => {
			res.push({
				gratuityTax: gratTax,
				isServiceCharge: false,
				serviceChargeTax: 0,
				staffId: elem.therapistId,
				staffType: elem.staffType,
				gratuity: elem.gratuityValue,
				additionalGratuity: elem.additionalGratuityValue,
				taxDetails: elem.taxDetails != null ? this.mapTaxDetailsforGratuity(elem.taxDetails, lineItem.index, elem) : null
			})
		});
		lineItem.serviceCharges?.forEach(elem => {
			res.push({
				gratuityTax: 0,
				isServiceCharge: true,
				serviceChargeTax: serviceChargeTax,
				staffId: elem.therapistId,
				staffType: elem.staffType,
				taxDetails: elem.taxDetails != null ? this.mapTaxDetailsforSC(elem.taxDetails, lineItem.index, elem) : null
			})
		})
		return res;
	}

	mapTaxDetailsforSC(tax: TaxDetails[], lineNumber, serviceCharge: ServiceCharges): TaxDetailsUpdate[] {
		let res: TaxDetailsUpdate[] = [];
		tax?.forEach(x => {
			res.push({
				taxAmount: x.value,
				taxId: x.id,
				originalTaxAmount: this._ss.OriginalTicket.lineItems.find(y => y.index == lineNumber).serviceCharges?.find(y => y.staffType == serviceCharge.staffType && y.therapistId == serviceCharge.therapistId).taxDetails?.find(y => y.id == x.id).baseTaxValue,
			})
		})
		return res;
	}

	mapTaxDetailsforGratuity(tax: TaxDetails[], lineNumber, gratuity: Gratuities): TaxDetailsUpdate[] {
		let res: TaxDetailsUpdate[] = [];
		tax?.forEach(x => {
			res.push({
				taxAmount: x.value,
				taxId: x.id,
				originalTaxAmount: this._ss.OriginalTicket.lineItems.find(y => y.index == lineNumber).gratuities?.find(y => y.staffType == gratuity.staffType && y.therapistId == gratuity.therapistId).taxDetails?.find(y => y.id == x.id).baseTaxValue,
			})
		})
		return res;
	}

	mapLinkedTaxDetails(tax: TaxDetails[], baseTax: TaxDetails): TaxDetailsUpdate[] {
		let res: TaxDetailsUpdate[] = [];
		tax?.forEach(x => {
			res.push({
				taxAmount: x.value,
				taxId: x.id,
				baseTaxAmount: x.baseTaxValue,
				linkedTaxAmount: x.linkedTaxValue,
				originalTaxAmount: baseTax.linkedTaxDetails.find(tax => tax.id == x.id).baseTaxValue,
				transactionLinkedTaxDetailUpdate: x.linkedTaxDetails ? this.mapLinkedTaxDetails(x.linkedTaxDetails, baseTax.linkedTaxDetails.find(tax => tax.id == x.id)) : null
			})
		})
		return res;
	}

	mapLineItemTaxDetails(lineItems: LineItem[]) {
		let res: TaxDetailsUpdate[] = [];
		lineItems.forEach(item => {
			item.taxDetails.forEach(x => {
				let transDetailId = this._ss.CreateItemResponse.transactionTaxDetails.find(y => y.taxId == x.id && y.lineNumber == item.index).transactionDetailId;
				res.push({
					taxAmount: x.value,
					transactionDetailId: transDetailId,
					taxId: x.id,
					baseTaxAmount: x.baseTaxValue,
					linkedTaxAmount: x.linkedTaxValue,
					originalTaxAmount: this._ss.OriginalTicket.lineItems.find(y => y.index == item.index).taxDetails.find(y => y.id == x.id).baseTaxValue,
					transactionLinkedTaxDetailUpdate: x.linkedTaxDetails ? this.mapLinkedTaxDetails(x.linkedTaxDetails, this._ss.OriginalTicket.lineItems.find(y => y.index == item.index).taxDetails.find(y => y.id == x.id)) : null
				})
			})
		})
		return res;
	}

	async PayRequest(pMPayResponse: ValidatePayResponse): Promise<boolean> {
		let PayRequestObj: PayRequest = {
			tryPayResponse: this.$scope.CurrentTryPayResponse,
			payResponse: pMPayResponse.paymentManagerResponse,
		};
		PayRequestObj.tryPayResponse.paymentReferenceId = pMPayResponse.transactionId;
		if (this.$scope.IsShopScreen && this.$scope.selectedpayment.isAutoRemoveTax && !this.$scope.isTaxExempted) {
			let checkData = this._ss.Ticket.checkData;
			let transaction: TransactionUpdate = {
				totalAmount: checkData.totalAmount,
				totalTax: checkData.totalTax,
				totalGratuity: checkData.gratuity,
				transactionId: this._ss.CreateItemResponse.id,
				transactionDetailUpdate: this.mapTransactionDetail(this._ss.Ticket.lineItems),
				transactionTaxDetailUpdate: this.mapLineItemTaxDetails(this._ss.Ticket.lineItems)
			}
			PayRequestObj.transactionUpdate = transaction;
		}
		let Response: any = await this.http.CallApiAsync<any>({
			host: GlobalConst.Host.retailPOS,
			callDesc: 'Pay',
			method: HttpMethod.Post,
			body: PayRequestObj,
			uriParams: { outletId: this.CurrentOutletId, terminalId: this._ss.SelectedTerminalId }
		});
		if (Response && Response.successStatus) {
			return true;
		} else {
			return false;
		}
	}

	FormSaleByTokenRequestBody(): SaleByTokenRequest {
		if (this.IsFolioPosting) {
			return this.FormSaleByTokenRequestBodyFromFolio(
				PaymentMethods.CreditCard.toString(),
				this.$scope.totalAmount,
				this.$scope.AdditionalInputs,
				this.tokenTransId,
				this.$scope.authorizedUserId
			);
		}

		let SaleAmount: number = 0;
		if (this.$scope.multiplePayment) {
			let AmountTendered = this.localization.currencyToSQLFormat(
				this.$scope.transitionDetails.controls.amounttendered.value
			);
			SaleAmount = AmountTendered < 0 ? AmountTendered * -1 : AmountTendered;
		} else {
			SaleAmount = this.$scope.getSaleAmt(true);
		}
		let outletDetail = this._ss.GetSelectedOutletDetail();

		return {
			inquirerInfo: {
				terminalId: "1",
				orderNumber: this.IsFolioPosting ? this.$scope.AdditionalInputs?.sourceTypeId.toString() : this.$scope.CreateRetailItemResponse?.transactionData?.retailTicketNumber,
				profitCenter: (outletDetail && outletDetail?.profitCenter) ? outletDetail.profitCenter : "0",
				zipcode: this.$scope.GatewayConfiguration && this.$scope.GatewayConfiguration?.isAVSActive ? this.getZipCodeValue() : '',
				isPartialTenderAllowed: this.$scope.GatewayConfiguration && this.$scope.GatewayConfiguration?.isPartialPayAllowed,
				tenderId:
					this.SelectedPaymentMethodEquals(PaymentMethods.CardOnFile)
						? PaymentMethods.CreditCard.toString()
						: this.$scope.selectedpayment.paymentTypeId.toString(), // == PaymentMethods.CreditCard ? "1":"23" //this.selectedpayment
				clientId: "",
				postingId: " - #" + this.$scope.CreateRetailItemResponse?.transactionData?.retailTicketNumber,				
				enterprise: String(this.PropertyInfo.PropertyId),
			},
			transactionId: ((this.$scope.productId != GlobalConst.Product.PMS || this.$scope.ScreenName == RetailScreenType.Shop) && !this.IsResortFinanceFolioPosting)  ? this.$scope.CurrentActiveCard.cof_PaymentRef : this.tokenTransId,
			amount: SaleAmount,
			surcharge: this.$scope.surchargeAmount,
			hostName: this.$scope.hostName,
			agentVersion: this.$scope.agentVersion,
			ipAddress: this.$scope.ipAddress,
			guestGuid: this.$scope?.payeeInfo?.guestProfileId,
			sourceType:SourceTypeConstant.RetailTransaction.toString(),
			sourceTypeId: this.$scope.CreateRetailItemResponse?.transactionData?.retailTicketNumber
		};
	}

	/**
	 * @function FormSaleByTokenRequestBody
	 * @description Perform the sale using the credit card token
	 */
	FormSaleByTokenRequestBodyFromFolio(tenderId, saleAmount: number, inquirerInfo, tokenTransactiond, authorizeUserId): SaleByTokenRequest {
		let userId = this.localization.GetPropertyInfo('UserId');
		userId = authorizeUserId ? authorizeUserId : userId;
		let saleAmountUpdated: number = 0;
		if (this.$scope.multiplePayment) {
			let AmountTendered = this.localization.currencyToSQLFormat(this.$scope.transitionDetails.controls.amounttendered.value);
			saleAmountUpdated = AmountTendered < 0 ? AmountTendered * -1 : AmountTendered;
		} else {
			saleAmountUpdated = this.$scope.getSaleAmt(true);
		}
		let outletDetail = this._ss.GetSelectedOutletDetail();
		let outletProfitCenter = (outletDetail && outletDetail?.profitCenter) ? outletDetail.profitCenter : "0";
		let tempProfitCenter = this.SelectedPaymentMethodEquals(PaymentMethods.CompRedemption) && this._featureFlagInfo.CMSProfitCenterId
			? this._featureFlagInfo.CMSProfitCenterId : outletProfitCenter, profitCenter = this.$scope.IsRoomOrGroupCharge ? this.$scope.profitCenter
				: tempProfitCenter,
			tempProfitCenterName = this._ss.AllOutlets && this._ss.AllOutlets.some(o => o.subPropertyID == this.CurrentOutletId)
				? this._ss.AllOutlets.find(o => o.subPropertyID == this.CurrentOutletId).subPropertyName : "",
			profitCenterName = this.SelectedPaymentMethodEquals(PaymentMethods.CompRedemption) ? this._featureFlagInfo.CMSProfitCenterName
				: tempProfitCenterName;
		let checkNumVal = this.IsFolioPosting && this.$scope.AdditionalInputs ? this.$scope.AdditionalInputs?.sourceTypeId.toString()
			: this.$scope.CreateRetailItemResponse?.transactionData?.retailTicketNumber;

		
		let resortFinanceFolioId = (this.IsResortFinanceFolioPosting || this.$scope.IsResortFinanceFolioAuthFlow) ? this.$scope.AdditionalInputs?.folioId.toString() : '';

		return {
			inquirerInfo: {
				terminalId: "1",
				orderNumber: resortFinanceFolioId? resortFinanceFolioId : AgilysysGCMethods.includes(this.$scope.selectedpayment.paymentTypeId) ?
					this.IsFolioPosting ? this.$scope.AdditionalInputs?.sourceTypeId.toString() :
						`${this.$scope.CreateRetailItemResponse?.transactionData?.retailTicketNumber}${this.$scope.utils.GetRandomNumber(6)}` : checkNumVal,
				profitCenter: outletProfitCenter,
				profitCenterName: profitCenterName,
				zipcode: inquirerInfo.zipcode ? inquirerInfo.zipcode : '',
				isPartialTenderAllowed: inquirerInfo.isPartialPayAllowed ? inquirerInfo.isPartialPayAllowed : false,
				tenderId: tenderId,
				employeeId: userId ? userId : '0',
				customer: userId ? userId : '0',
				enterprise: String(this.PropertyInfo.PropertyId),
				clientId: profitCenterName,
				postingId: " - #" + (resortFinanceFolioId? resortFinanceFolioId : checkNumVal)
			},
			transactionId: tokenTransactiond,
			amount: saleAmountUpdated,
			surcharge: this.$scope.surchargeAmount,
			sourceType: inquirerInfo.sourceType,
			sourceTypeId: inquirerInfo.sourceTypeId,
			folioNumber: inquirerInfo.folioNumber,
			roomNumber: inquirerInfo.roomNumber ? inquirerInfo.roomNumber : '0',
			hostName: this.$scope.hostName,
			agentVersion: this.$scope.agentVersion,
			ipAddress: this.$scope.ipAddress,
			postId: this.$scope.AdditionalInputs.postId,
			guestGuid: this.$scope?.payeeInfo?.guestProfileId
		};
	}

	TransactionLog(description: string, OldData: any, newData: any, logType: string): TransactionLog {
		return {
			description: description,
			OldData: OldData,
			NewData: newData,
			LogType: logType,
			StayId: this.$scope.AdditionalInputs.sourceTypeId
		} as TransactionLog
	}
	public async CreateTransLog(transLogData: TransactionLog) {
		await this._retailCommunication.postPromise<boolean>(
			{ route: RetailRoutes.CreateTransLog, body: transLogData }, true);
	}

	SaleByToken() {

		//Non Integrated Credit Card Implementation
		if (this.$scope.CurrentActiveCard.accessUrl) {
			let nonIntegratedPaymentMethod = this.$scope.paymentMethods.filter(x => x.paymentTypeId === PaymentMethods.NonIntegratedCreditCard);
			this.$scope.selectedpayment = {
				id: nonIntegratedPaymentMethod[0].id,
				paymentTypeId: nonIntegratedPaymentMethod[0].paymentTypeId,
				parentMethodId: nonIntegratedPaymentMethod[0].parentMethodId,
				isActive: nonIntegratedPaymentMethod[0].isActive,
				isDefault: nonIntegratedPaymentMethod[0].isDefault,
				noOfReceipts: nonIntegratedPaymentMethod[0].noOfReceipts,
				paymentMethod: nonIntegratedPaymentMethod[0].paymentMethod,
				type: nonIntegratedPaymentMethod[0].type,
			}
			return
		}

		const RequestBody = this.FormSaleByTokenRequestBody();
		this.ShowTransactionInprogressDialog();

		// Log Data to track the sale by token data before payment initiation
		let transLogData = this.TransactionLog(`${RequestBody.transactionId} | ${RequestBody.amount} | Payment Initiated`, 0, 0, "SaleByToken")
		this.CreateTransLog(transLogData);

		// call to the payment service sale by token API
		const currentSaleAmt = this.$scope.getAmountPaid();
		const outletId = this.IsFolioPosting ? this.$scope.AdditionalInputs.outletId : this.CurrentOutletId;
		const saleByTokenRoute = this.IsResortFinanceFolioPosting ? RetailRoutes.FolioSaleByToken : RetailRoutes.RetailSaleByToken;
		let SaleResponseDataObj: any = this.http.CallApiAsync<any>({
			host: GlobalConst.Host.payment,
			callDesc: saleByTokenRoute,
			method: HttpMethod.Post,
			body: RequestBody,
			uriParams: { outletId: outletId },
			showError: true
		});
		console.log(SaleResponseDataObj);

		SaleResponseDataObj.then(async (reponse) => {

			if (reponse && reponse.result.status) {
				let saleByTokenResponse: SaleByTokenResponse = reponse.result;

				if (this.IsFolioPosting && saleByTokenResponse) {
					this.paymentSuccess = true;
					const clickReturnValue = {
						from: ActionMode.create,
						formValues: this.$scope.transitionDetails.getRawValue(),
						form: this.$scope.transitionDetails,
						transactionId: saleByTokenResponse?.transactionId,
						maskedCardNumber: saleByTokenResponse?.paymentManagerResponse?.clientInfo?.accountNumber,
						checkReponse: this.$scope.checkResponse,
						selectedPayment: this.$scope.selectedpayment,
						saleAmount: saleByTokenResponse?.paymentManagerResponse?.amount?.authorisedAmount,
						surcharge: this.$scope.surchargeAmount,
						transactionIdGeneratedFromToken: saleByTokenResponse?.transactionIdGeneratedFromToken,
						tokenTransId: this.tokenTransId != null ? this.tokenTransId : 0,
						logType: "SaleByToken"
					};
					this.$scope.cardDetailsBool = false;
					this.utils.ToggleLoader(false);
					this.dialogRef.close();
					this.$scope.TransactionCompleted.emit(clickReturnValue);
					this.paymentSuccess = false;
					this.$scope.isAPICallInProgress = false;
					return;
				}

				saleByTokenResponse = this.ConstructPayRequest(saleByTokenResponse);

				//Check for partial tender flow for New Try Pay
				if (
					saleByTokenResponse.paymentManagerResponse.amount.authorisedAmount !=
					saleByTokenResponse.paymentManagerResponse.amount.requestAmount &&
					this.$scope.GatewayConfiguration && this.$scope.GatewayConfiguration?.isPartialPayAllowed
				) {
					this.$scope.multiplePayment = true;
					this.PatchRemainingAmountAndHighlight(saleByTokenResponse.paymentManagerResponse.amount.authorisedAmount);
					await this.SendTryPayRequest(
						this.$scope.CreateRetailItemResponse.transactionDetails[0].transactionId,
						this.$scope.selectedpayment.paymentTypeId,
						true,
						SaleResponseDataObj
					);
				}				
				const payRequestResult = await this.PayRequest(saleByTokenResponse);
				if (!payRequestResult) {
					this.utils.ShowErrorMessage(this.localization.captions.common.Error, this.localization.getError(10724));
					return;
				} else {
					let SettleMentObject: PaymentHistory = {
						paymentReferenceId: saleByTokenResponse.transactionId,
						paymentMethodId: this.$scope.selectedpayment.paymentTypeId,
						parentTenderId: this.$scope.selectedpayment?.parentTypeId,
						paymentMethod: this.localization.captions.shop.paymentMethods[this.$scope.selectedpayment.paymentTypeId],
						amount: saleByTokenResponse.paymentManagerResponse.amount.authorisedAmount,
						surcharge: this.$scope.surchargeAmount,
						issuerType:
							this.$scope.CurrentActiveCard == null ? '' : this.$scope.CurrentActiveCard.issuerType.toUpperCase(),
						entryMode: this.$scope.CurrentActiveCard == null ? '' : this.$scope.CurrentActiveCard.entryMode,
						cardNumber: this.$scope.CurrentActiveCard == null ? '' : this.$scope.CurrentActiveCard.cardNumber,
						paymentReferenceComment: this.$scope.paymentComments,
						paymentMethodInfo: this.$scope.selectedpayment,
						folioInvoiceNumber: this.$scope.currentPaymentPostfolioData?.selectedGuest?.folios[0].folioInvoiceNumber,
						folioSourceType: this.$scope.currentPaymentPostfolioData?.selectedGuest?.folios[0].sourceType,
						folioSourceTypeId: this.$scope.currentPaymentPostfolioData?.selectedGuest?.guestId,
						folioGuestName: this.$scope.currentPaymentPostfolioData?.selectedGuest?.name,
						interfaceGuestId: this.$scope.currentPaymentPostfolioData?.selectedFolio?.interfaceGuestId,
						discountExemptRatio:  this._ss.Ticket.tenderReducesDiscountRatio,
						taxExemptRatio:  this._ss.Ticket.compTaxExemptRatio,
						tenderReducesDiscount: this.$scope?.selectedpayment?.tenderReducesDiscount,
						tenderReducesTax: this.$scope.selectedpayment.isAutoRemoveTax,
					};
					this.$scope.ConfirmationPopupShown = false;
					this.$scope.SettlementHistory.unshift(SettleMentObject);
					this.AlterSettlementHistory();
					this.$scope.PaymentHistoryEmit.emit(this.$scope.SettlementHistory);
					//Check for partial tender flow
					if (
						saleByTokenResponse.paymentManagerResponse.amount.authorisedAmount !=
						saleByTokenResponse.paymentManagerResponse.amount.requestAmount &&
						this.$scope.GatewayConfiguration && this.$scope.GatewayConfiguration?.isPartialPayAllowed
					) {
						this.SwitchToMultiPayment(saleByTokenResponse, true);
					}
				}

				this.dialogRef.close();


				/* PAYMENT SUCCESS DIALOG - COMMENTED - TO REDUCE THE NO OF CLICKS
				if (this.$scope.selectedpayment.paymentTypeId == PaymentMethods.CardOnFile) {
					const Popupmessage = this.localization.captions.shop.TrnsactionSuccessMsg;
					const dataObj = {
						text: Popupmessage,
						buttonname: 'ok',
						headertext: this.localization.captions.shop.Sale,
						icon: 'icon-success-icon'
					};
					this.roomOpenDialog(dataObj);
				}*/

				this.CheckSettlementStatusAndUpdateButtons();
			}
			else {
				if (this.IsFolioPosting) {
					const clickReturnValue = {
						from: ActionMode.cancel
					};
					this.dialogRef.close();
					this.utils.ToggleLoader(false);
					this.$scope.TransactionCompleted.emit(clickReturnValue);
					this.$scope.isAPICallInProgress = false;
					// Log Data to track the sale by token data after paposting deleted
					transLogData = this.TransactionLog(`${this.$scope.AdditionalInputs.postId} - posting deleted`, clickReturnValue, 0, "SaleByToken")
					this.CreateTransLog(transLogData);
					this.HandleSaleFailure(reponse);
				}
			}
		}).catch((errorResponse) => {
			if (this.IsFolioPosting && !this.paymentSuccess) {
				this.paymentSuccess = false;
				const clickReturnValue = {
					from: ActionMode.cancel
				};
				this.dialogRef.close();
				this.utils.ToggleLoader(false);
				this.$scope.TransactionCompleted.emit(clickReturnValue);
				this.$scope.isAPICallInProgress = false;
				// Log Data to track the sale by token data after paposting deleted
				transLogData = this.TransactionLog(`${this.$scope.AdditionalInputs.postId} - posting deleted`, clickReturnValue, 0, "SaleByToken")
				this.CreateTransLog(transLogData);
			}
			this.paymentSuccess = false
			this.utils.ToggleLoader(false);
			this.HandleSaleFailure(errorResponse);
		});
	}

	CreditByToken() {

		//Non Integrated Credit Card Implementation
		if (this.$scope.CurrentActiveCard.accessUrl) {
			let nonIntegratedPaymentMethod = this.$scope.paymentMethods.filter(x => x.paymentTypeId === PaymentMethods.NonIntegratedCreditCard);
			this.$scope.selectedpayment = {
				id: nonIntegratedPaymentMethod[0].id,
				paymentTypeId: nonIntegratedPaymentMethod[0].paymentTypeId,
				parentMethodId: nonIntegratedPaymentMethod[0].parentMethodId,
				isActive: nonIntegratedPaymentMethod[0].isActive,
				isDefault: nonIntegratedPaymentMethod[0].isDefault,
				noOfReceipts: nonIntegratedPaymentMethod[0].noOfReceipts,
				paymentMethod: nonIntegratedPaymentMethod[0].paymentMethod,
				type: nonIntegratedPaymentMethod[0].type,
			}
			return
		}
		
		const RequestBody = this.FormSaleByTokenRequestBody();
		this.ShowTransactionInprogressDialog();

		// Log Data to track the credit by token data before payment initiation
		let transLogData = this.TransactionLog(`${RequestBody.transactionId} | ${RequestBody.amount} | Payment Initiated`, 0, 0, "CreditByToken")
		this.CreateTransLog(transLogData);

		const outletId = this.IsFolioPosting ? this.$scope.AdditionalInputs.outletId : this.CurrentOutletId;
		const creditByTokenRoute = this.IsResortFinanceFolioPosting ? RetailRoutes.FolioCreditByToken : RetailRoutes.RetailCreditByToken;
		let CreditByTokenResponse: any = this.http.CallApiAsync<any>({
			host: GlobalConst.Host.payment,
			callDesc: creditByTokenRoute,
			method: HttpMethod.Post,
			body: RequestBody,
			uriParams: { outletId: outletId },
			showError: true
		});
		console.log(CreditByTokenResponse);
		CreditByTokenResponse.then((response) => {
			if (response && response.result.status) {
				let creditByTokenResult: ValidatePayResponse = response.result;
				if (this.IsFolioPosting) {
					const clickReturnValue = {
						from: ActionMode.create,
						formValues: this.$scope.transitionDetails.getRawValue(),
						form: this.$scope.transitionDetails,
						transactionId: creditByTokenResult?.transactionId,
						maskedCardNumber: creditByTokenResult.paymentManagerResponse.clientInfo.accountNumber,
						checkReponse: this.$scope.checkResponse,
						selectedPayment: this.$scope.selectedpayment,
						saleAmount: creditByTokenResult?.paymentManagerResponse?.amount?.authorisedAmount,
						transactionIdGeneratedFromToken: creditByTokenResult?.transactionIdGeneratedFromToken,
						tokenTransId: this.tokenTransId != null ? this.tokenTransId : 0
					};
					this.$scope.cardDetailsBool = false;
					this.dialogRef.close();
					this.utils.ToggleLoader(false);
					this.$scope.TransactionCompleted.emit(clickReturnValue);
					this.$scope.isAPICallInProgress = false;
					return;
				}
				this.HandlePayRequest(creditByTokenResult, creditByTokenResult.paymentManagerResponse);
			}
			else {
				if (this.IsFolioPosting) {
					const clickReturnValue = {
						from: ActionMode.cancel
					};
					this.dialogRef.close();
					this.utils.ToggleLoader(false);
					this.$scope.TransactionCompleted.emit(clickReturnValue);
					this.$scope.isAPICallInProgress = false;
					// Log Data to track the credit by token data after paposting deleted
					transLogData = this.TransactionLog(`${this.$scope.AdditionalInputs.postId} - posting deleted`, clickReturnValue, 0, "CreditByToken")
					this.CreateTransLog(transLogData);
					this.HandleSaleFailure(response);
				}
			}
		}).catch((errorResponse) => {
			if (this.IsFolioPosting) {
				const clickReturnValue = {
					from: ActionMode.cancel
				};
				this.dialogRef.close();
				this.utils.ToggleLoader(false);
				this.$scope.TransactionCompleted.emit(clickReturnValue);
				this.$scope.isAPICallInProgress = false;
				// Log Data to track the sale by token data after paposting deleted
				transLogData = this.TransactionLog(`${this.$scope.AdditionalInputs.postId} - posting deleted`, clickReturnValue, 0, "CreditByToken")
				this.CreateTransLog(transLogData)
			}
			this.HandleSaleFailure(errorResponse);
		});
	}

	AlterSettlementHistory() {
		this.$scope.ShowSettlemtHist = this.$scope.SettlementHistory.some(x => !x.isReversed);
		this._ams.paymentProcessing = this.$scope.SettlementHistory.some(x => !x.isReversed);
		this._sbs.AutoScrollToBottom();
		this._cmsBusiness.DisableOfferOrCompSlipIfVoucherRedeemed(this.$scope);
		this.UpdateCardPaymentOrder();
		this.$scope.AdjustPaymentMethods();
	}

	UpdateCardPaymentOrder() {
		let settledInCard = this.$scope.SettlementHistory.filter((r) => (r.paymentMethodId == PaymentMethods.CreditCard || r.paymentMethodId == PaymentMethods.CardOnFile));
		settledInCard.forEach(s => s.paymentMethod = this.$scope.ShopCaptions.Card);
		settledInCard = settledInCard.filter(x => !x.isReversed);
		if (settledInCard && settledInCard.length > 1) {
			for (let i = settledInCard.length; i > 0; i--) {
				settledInCard[settledInCard.length - i].paymentMethod = `${this.$scope.ShopCaptions.Card} ${i}`;
			}
		}
	}

	HandlePostSaleFailure(errorResponse) {
		this.dialog.closeAll();
		//Payment Manager is not reachable.
		if (
			errorResponse &&
			errorResponse.error &&
			errorResponse.error.result &&
			errorResponse.error.result.errorCode
		) {
			this.CreateInterfacePostingError(this.GetPaymentErrorMessage(errorResponse.error.result.errorCode), 'PostSale Failure', 0, '16');
			return this.payAgentService.PaymentErrorPrompt(errorResponse.error.result.errorCode);
		} else {
			this.CreateInterfacePostingError(this.localization.captions.shop.PMUnexpectedError, 'PostSale Failure', 0, '16');
			let paymentTypeId = this.$scope.selectedpayment.paymentTypeId;
			if (this.$scope.selectedpayment?.parentTypeId != undefined && this.$scope.selectedpayment?.parentTypeId != 0) {
				paymentTypeId = this.$scope.selectedpayment.parentTypeId;
			}
			if (errorResponse?.error?.result?.errorMessage) {
				this.utils.ShowErrorMessage(this.localization.captions.common.Error, errorResponse?.error?.result?.errorMessage);
			} else {
				this.utils.ShowErrorMessage(
					this.localization.captions.common.Error,
					this.localization.replacePlaceholders(
						this.$scope.ShopCaptions.PaymentTransactionFailureMsg,
						['paymentmethod'],
						[this.localization.captions.shop.paymentMethods[paymentTypeId]]
					)
				);
			}
			
		}
		this.$scope.ResetSelectedPayment(); //Reset Payment method selection
	}

	//Log the interface posting errors
	public async CreateInterfacePostingError(errorMessage, comment, postTypeId, reference) {
		try {
			if (this.IsFolioPosting) {
				var saleAmount: number = 0;
				if (this.$scope.multiplePayment) {
					let AmountTendered = this.localization.currencyToSQLFormat(
						this.$scope.transitionDetails.controls.amounttendered.value
					);
					saleAmount = AmountTendered < 0 ? AmountTendered * -1 : AmountTendered;
				} else {
					saleAmount = this.$scope.getSaleAmt(true);
				}

				const tempRoomNumber = this.$scope.selectedGuestRoom?.RoomNumber ? this.$scope.selectedGuestRoom.RoomNumber : '0';
				let postError: PostError = {
					id: 0,
					logDate: this.localization.convertDateTimeToAPIDateTime(this.localization.getCurrentDate()),
					postTypeId: postTypeId,
					subType: 'N',
					resolved: false,
					amount: saleAmount,
					errorMessage: errorMessage,
					comment: comment,
					guest: '1',
					reference: reference,
					guestId: '00000000-0000-0000-0000-000000000000',
					roomNumber: this.$scope.transitionDetails.controls.roomnumber.value ? this.$scope.transitionDetails.controls.roomnumber.value : tempRoomNumber,
					stayId: '0'
				}
				return await RetailDataAwaiters.CreateInterfacePostingError(postError);
			}
		} catch (e) {
			this.http.exceptionHandle(e);
		}
	}

	GetPaymentErrorMessage(errcodes) {
		switch (parseInt(errcodes)) {
			case PaymentErrorCodes.ClientInvalidRequest:
				return this.localization.captions.shop.PMInvalidRequest;
			case PaymentErrorCodes.DeviceNotAvailable:
				return this.localization.captions.shop.PMDeviceNotAvailable;
			case PaymentErrorCodes.DeviceNotReady:
				return this.localization.captions.shop.PMDeviceBusy;
			case PaymentErrorCodes.DeviceUserPressedCancel:
				return this.localization.captions.shop.PMDeviceCancel;
			case PaymentErrorCodes.TransactionTimeOut:
				return this.localization.captions.shop.PMTransactionTimeOut;
			case PaymentErrorCodes.DeviceError:
			case PaymentErrorCodes.CardErrorInvalidAccountNumber:
			case PaymentErrorCodes.CardErrorInvalidExpirationDate:
			case PaymentErrorCodes.CardError:
			case PaymentErrorCodes.CardErrorUnsupportedCardType:
			case PaymentErrorCodes.CardErrorUnsupportedCardIssuer:
				return this.localization.getError(10721);
			case PaymentErrorCodes.DeviceOperationAborted:
				return this.localization.captions.shop.PMDeviceTimeOut;
			case PaymentErrorCodes.CardDeclinedLimit:
				return this.localization.captions.shop.InsufficientFunds;
			case PaymentErrorCodes.GatewayAuthenticationFailed:
				return this.localization.captions.shop.PMAuthFailed;
			case PaymentErrorCodes.CardDeclinedChipDecline:
				return this.localization.captions.shop.PMcardDeclinedChipDecline;
			case PaymentErrorCodes.PaymentManagerTimedOut:
				return this.localization.captions.shop.PMAgentUnavailable;
			case PaymentErrorCodes.DeviceInvalidData:
			default:
				return this.localization.captions.shop.PMUnexpectedError;
		}
	}

	CloseAllDialog() {
		if (this.$scope.IsMakePaymentScreen && this.dialogRef) {
			this.dialogRef.close();
		} else {
			this.dialog.closeAll();
		}
	}
}
