import { Component, Input, Output, EventEmitter, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-golfsimple-search',
  templateUrl: './simple-search.component.html',
  styleUrls: ['./simple-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GolfSimpleSearchComponent implements AfterViewInit {

  searchText = '';
  width: number;
  searchPlaceholder: string;
  searchDisabled: boolean = false;
  automationId:string="";
  @ViewChild('searchInput') searchInput: ElementRef;
  @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() directiveType;
  @Input('disabled')
  set setDisabled(value: boolean) {
    this.searchDisabled = value;
  }
  @Input('automationId')
  set setAutomationId(value: string) {
    this.automationId = value ? value : 'SimpleSearch';
  }
  @Input('searchTextValue')
  set setsearchTextValue(value: string) {
    this.searchText = value;
    this.searchValueChange();
  }
  @Input('placeholder')
  set setPlaceHolder(value: string) {
    if (value) {
      this.searchPlaceholder = value;
      this.width = value.length + 5; // added 5 character length because of the icon space and padding
    }
  }

  ngAfterViewInit(): void {
    this.calculateSearchBoxWidth();
  }
  calculateSearchBoxWidth() {
    let pageHeader = document.getElementsByClassName('header-wrapper')[0] ?
      document.getElementsByClassName('header-wrapper')[0]['offsetWidth'] : 0;
    let searchInput = document.getElementsByClassName('searchbox')[0];
    if (pageHeader > 0) {
      pageHeader = pageHeader - this.setMatformWidth() - 60;
    }
    let inputLength = this.searchInput ? this.searchInput.nativeElement.attributes['data-placeholder'].value.length : 1;
    let inputWidth = inputLength <= 30 ? inputLength * 10 : inputLength * 9 + 20;
    if (searchInput && pageHeader > 0) {
      searchInput['style'].width = (pageHeader > inputWidth) ? inputWidth + 'px' : pageHeader + 'px';
    }

  }

  setMatformWidth() {
    return document.getElementsByClassName('right-action-section')[0] ?
      document.getElementsByClassName('right-action-section')[0]['offsetWidth'] : 300; //min-300 max-470      
  }

  // Event Handlers
  searchValueChange() {
    this.searchChange.emit(this.searchText);
  }
  clearText() {
    this.searchText = '';
    this.searchValueChange();
  }
}
