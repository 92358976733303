import { EventActorsDataProvider, IEventActorDataProvider, EventActorCollection, Users } from './common/Models/notification.model';
import { caddies } from './settings/caddy-setup/caddy-setup/caddy-setup.model';
import { API as Instructor } from './settings/instructor-setup/instructor-setup/instructor-setup.model';
import { GolfUtilities } from './shared/utilities/golf-utilities';
import { UserRole } from './shared/models/tenant.models';
import { GolfLocalization } from './core/localization/golf-localization';
import { Product } from './shared/global.constant';
import { localizationJSON } from './shared/shared-models';
import { environment } from 'src/environments/environment';

export class GolfEventActorDataProvider implements EventActorsDataProvider {
    propertyId: string;
    caption: localizationJSON;

    constructor(private utils: GolfUtilities, private localization: GolfLocalization) {
        this.propertyId = this.localization.GetPropertyInfo('PropertyId');
        this.caption = this.localization.captions;
    }
    get providers(): IEventActorDataProvider[] {
        const caption = this.caption.settings.utilities.distributionlist;
        return [
            {
                actor: 'TeeTimePlayer',
                actorDescription: caption.TeeTimePlayer
            },
            {
                actor: 'TeeTimeCaddy',
                actorDescription: caption.TeeTimeCaddy
            },
            {
                actor: 'LeasonInstructor',
                actorDescription: caption.LessonInstructor
            },
            {
                actor: 'Caddy',
                actorDescription: caption.Caddy,
                host: environment["GolfManagement"],
                route: GolfApiRoute.GetActiveCaddies,
                uiMapper: (apiValue: caddies[]) => {
                    return apiValue.map(a => {
                        return <EventActorCollection>{
                            id: a.id,
                            desc: this.utils.formatGuestName(a.caddyContactDetails.firstName,  a.caddyContactDetails.lastName)
                        }

                    });
                }
            },
            {
                actor: 'Instructor',
                actorDescription: caption.Instructor,
                host: environment["GolfManagement"],
                route: GolfApiRoute.GetInstructors,
                uiMapper: (apiValue: Instructor.InstructorConfiguration[]) => {
                    const activeInstructors = apiValue.filter(x=> x.isActive);
                    if(activeInstructors && activeInstructors.length > 0){
                        return activeInstructors.map(a => {
                            return <EventActorCollection>{
                                id: a.id,
                                desc: this.utils.formatGuestName(a.propertyContactPerson.firstName,a.propertyContactPerson.lastName)
                            }
                        });
                    }                 
                }
            },

            {
                actor: 'UserRoles',
                actorDescription: caption.UserRoles,
                host: environment["Authentication"],
                route: `UserRole/GetActiveUserRolesByPropertyId/${this.localization.GetPropertyInfo('PropertyId')}/false`,
                uiMapper: (apiValue: UserRole[]) => {
                    return apiValue.filter(o=>o.productId.includes(Product.GOLF)).map(a => {
                        return <EventActorCollection>{
                            id: a.id,
                            desc: a.description
                        }
                    });
                }
            },
            {
                actor: 'Users',
                actorDescription: caption.Users,
                host: environment["Authentication"],
                route: `User/GetAllUserbyTenantId/${this.localization.GetPropertyInfo('TenantId')}?inActive=false`,
                uiMapper: (apiValue: Users[]) => {
                    return apiValue.map(a => {
                        return <EventActorCollection>{
                            id: a.userId,
                            desc: this.utils.formatGuestName(a.firstName , a.lastName)
                        }
                    });
                }
            }
        ]
    }



}

