import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { Addresscomponent } from 'src/app/retail/shared/business/shared.modals';
import { AgAddressFieldConfig, GoogleAddressOutput } from '../../shared-models';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Component({
  selector: 'app-ag-golfaddress',
  templateUrl: './ag-address.component.html',
  styleUrls: ['./ag-address.component.scss']
})
export class AgGolfAddressComponent {

  contactForm: UntypedFormGroup;
  customClass: string;
  errorMessage: string;
  controlName: string;
  arrayName: string;
  placeHolder: string;
  address: UntypedFormArray;
  addresslength = 0;
  IconDisableClass: boolean = false;
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  captions: any;
  floatLabel: string;
  automationId:string="";
  @Input('inputs')
  set inputOptions(value: AgAddressFieldConfig) {
    this.customClass = value.className;
    this.errorMessage = value.errorMessage;
    this.contactForm = value.form;
    this.controlName = value.formControlName;
    this.placeHolder = value.placeHolder ? value.placeHolder : this.captions.address;
    this.arrayName = value.formArrayName ? value.formArrayName : 'address';
    this.address = this.contactForm.get(this.arrayName) as UntypedFormArray;
    this.IconDisableClass = value.disabled ? true : false;
    this.automationId = value.automationId ?  value.automationId: '';
  }
  @Input('values')
  set addressValues(value: string[]) {
    this.SetEditValue(value);
  }

  @Input() isViewOnly: boolean = false;
  @Output() onAddressChange: EventEmitter<GoogleAddressOutput> = new EventEmitter<GoogleAddressOutput>();
  constructor(private fb: UntypedFormBuilder, private localization: GolfLocalization) {
    this.captions = this.localization.captions.common;
    this.floatLabel = this.localization.setFloatLabel;
  }


  bindAddressFromGoogle(res: any, IsAutoComplete?, index?: number) {
    if (IsAutoComplete) {
      this.updateAddressField(res.address_components, true, index);
      if (!res.status) {
        this.googleAutoCompleteAddressLineBinding(index);
      }
      return;
    }
    if (res.status === 'OK') {
      if (IsAutoComplete) {
        this.updateAddressField(res.address_components, true, index);
      } else {
        this.updateAddressField(res.results[0].address_components, false, index);
      }
    }
    if (res.status === 'ZERO_RESULTS') {
      this.clearAddressFields();
    }
    if (res.status === 'OVER_QUERY_LIMIT') {
      //  OVER_QUERY_LIMIT  
    }
    if (!res.status) {
      this.googleAutoCompleteAddressLineBinding(index);
    }
  }

  googleAutoCompleteAddressLineBinding(index) {
    // google auto complete from search
    const addressFormArr = this.contactForm.get('address') as UntypedFormArray;
    // populating auto completed value from text box(work around for angular issue)
    const addressCtl: any = index === 0 ? document.getElementById('AddressInput') : document.getElementById('AddressInput' + index );
    const addressAutoPopulated: string = addressCtl.value;
    if (addressCtl.value !== '') {
      addressFormArr.at(index).patchValue({ addressDetails: addressAutoPopulated });
    }   
    const ctrl: any = addressFormArr.at(index);
    ctrl.controls.addressDetails.setErrors(null);
  }

  updateAddressField(add: Addresscomponent[], IsAutoComplete: boolean, index?: number) {
    let country = '';
    let state = '';
    let city = '';
    let googlePostalCode: string;
    const addressFormArr = this.contactForm.get('address') as UntypedFormArray;
    if (add) {
      const ctrl: any = addressFormArr.at(index);
      ctrl.controls.addressDetails.setErrors(null);
    }
    for (const address of add) {
      const element = address;
      if (element.types[0] === 'administrative_area_level_1') {
        state = element.long_name;
      }
      if (element.types[0] === 'locality') {
        city = element.long_name;
      }
      if (element.types[0] === 'country') {
        country = element.long_name;
      }
      if (element.types[0] === 'postal_code') {
        googlePostalCode = element.long_name;
      }
    }

    let chosenAddress: GoogleAddressOutput = {
      city,
      country,
      state,
      zipCode: googlePostalCode
    };
    this.onAddressChange.emit(chosenAddress);

    if ((this.contactForm.get('postalCode') && googlePostalCode === this.contactForm.get('postalCode').value) || IsAutoComplete) {
      const addressValue = `${(add[0] && add[0].long_name) ? add[0].long_name : ''} ${(add[1] && add[1].long_name) ? add[1].long_name : ''}`;
      addressFormArr.at(0).patchValue({
        addressDetails: addressValue
      });
      this.contactForm.patchValue({
        city,
        country,
        state,
        postalCode: googlePostalCode
      });
    } else {
      this.clearAddressFields();
    }
  }
  // Dynamic Address field addition Logic
  addAddressArray(addressDetails): UntypedFormGroup {
    return this.fb.group({
      addressDetails: [addressDetails, Validators.maxLength(255)]
    });
  }

  addAddress(addressDetails: any) {
    if (!this.isViewOnly) {
      this.address.push(this.addAddressArray(addressDetails));
      this.addresslength = this.address.length;
    }
  }

  removeAddress(index: number) {
    if (!this.isViewOnly) {
      this.contactForm.markAsDirty();
      this.address.removeAt(index);
      this.addresslength--;
    }
  }

  clearAddressFields() {
    this.contactForm.patchValue({
      city: '',
      country: '',
      state: ''
    });
  }

  SetEditValue(value) {
    if (value && value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        if (value[i] && value[i][this.controlName]) {
          if (i === 0) {
            this.address.controls[i].patchValue(value[i]);
          } else {
            this.address.push(this.addAddressArray(value[i][this.controlName]));
          }
        }
      }
    }
    this.addresslength = this.address && this.address.length >= 0 ? this.address.length : 0;
  }

}
