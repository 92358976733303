import { Injectable } from '@angular/core';
import { PlayerInformationService } from './player-information.service';


@Injectable()
export class PlayerInformationBusiness {

  constructor(private _PlayerInformationService: PlayerInformationService) { }

  GetCaptions() {
    return this._PlayerInformationService.getCaptions();
  }

  GetTitleList() {
    return this._PlayerInformationService.getTitleList();
  }

  GetGenders() {
    return this._PlayerInformationService.getGenders();
  }

  getDate()
  {
    return this._PlayerInformationService.getCurrentdate()
  }
  GetErrorMessage()
  {
    return this._PlayerInformationService.getErrorCaptions()
  }
}
