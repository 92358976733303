import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SaveButtonObj } from '../../shared-models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { NotificationData, smsmailresponse } from './send-notification-mail-sms.modal';
import { SendNotificationMailSMSBusiness } from './send-notification-mail-sms.business';
import { GolfUtilities } from '../../utilities/golf-utilities';
import { SendNotificationMailSMSService } from './send-notification-mail-sms.service';

@Component({
  selector: 'app-send-notification-mail-sms',
  templateUrl: './send-notification-mail-sms.component.html',
  styleUrls: ['./send-notification-mail-sms.component.scss'],
  providers : [SendNotificationMailSMSBusiness,SendNotificationMailSMSService ]
})
export class SendNotificationMailSmsComponent implements OnInit {

  emailsmsForm: UntypedFormGroup;
  buttonObj: SaveButtonObj;
  captions:any;
  textMaskPhone: any;
  bookingId : string;
  action : string;
  mailId : string;
  countryCode : string;
  phone : string;
  playerIds: number[] =[];
  floatLabel: string;
  automationId:string="SendNotificationMailSMS";
  
  constructor(  @Inject(MAT_DIALOG_DATA) public dialogData,private fb:UntypedFormBuilder ,private dialogRef: MatDialogRef<SendNotificationMailSmsComponent>,private _localization:GolfLocalization
  , private _utils : GolfUtilities,private _SendNotificationMailSMSBusiness : SendNotificationMailSMSBusiness) {
    this.floatLabel = this._localization.setFloatLabel;
   }

  ngOnInit() {
    this.bookingId = this.dialogData.info;
    this.action = this.dialogData.action;
    this.captions =this._localization.captions.common;
    this.mailId = this.dialogData.mailId ? this.dialogData.mailId : null;
    this.countryCode = this.dialogData.countryCode ?  this.dialogData.countryCode : null;
    this.phone = this.dialogData.phone ? this.dialogData.phone : null;
    this.textMaskPhone = this.maskPhoneNo();
    this.playerIds = this.dialogData.playerIds? this.dialogData.playerIds: [];
    this.createemailsmsForm();    
    this.buttonObj = {
      isEdit: false,
      disabled: true,
      customSaveText: this.dialogData.save,
      customCancelText:this.dialogData.cancel
    };
    this.emailsmsForm.statusChanges.subscribe(x => {
        this.buttonObj.disabled = (!this.emailsmsForm.valid || ((this.emailsmsForm.controls['emailId'].value=='') && 
        ((this.emailsmsForm.controls['phoneNo'].value=='') && (this.emailsmsForm.controls['countryCode'].value=='')))); 
        this.buttonObj = {...this.buttonObj};
    });
   
  }

  ngAfterViewInit()
  {
    this.patch()

  }

  patch()
  {
    this.emailsmsForm.patchValue({
      emailId:this.mailId,
      phoneNo:this.phone,
      countryCode:this.countryCode
    })
  }

  createemailsmsForm() {
    this.emailsmsForm = this.fb.group({
      emailId:'',
      phoneNo:'',
      countryCode:['', Validators.required]
    });
  }

  closeHandler(arg) {
    let obj = {
      'fromtype': smsmailresponse.skip,
      'obj': this.emailsmsForm
    }
    this.dialogRef.close(obj);
  }

  saveHandler(event) {

    let emailId : string = this.emailsmsForm.controls["emailId"].value === "" ? null : this.emailsmsForm.controls["emailId"].value;
    let phoneNo : string= this.emailsmsForm.controls["phoneNo"].value === "" ?  null : this.emailsmsForm.controls["phoneNo"].value;
    let countryCode : string = this.emailsmsForm.controls["countryCode"].value === "" ? null : this.emailsmsForm.controls["countryCode"].value;
    let phonewithcountryCode : string = (phoneNo != null) ? countryCode + this._utils.getNumberString(phoneNo) :  null;
    let notificationData: NotificationData = {playerIds: this.playerIds};
    this._SendNotificationMailSMSBusiness.bulkSMSMailNotification(emailId, phonewithcountryCode, this.action,this.bookingId,notificationData);
    
    let obj = {
      'fromtype': smsmailresponse.send,
      'obj': this.emailsmsForm
    }
    this.dialogRef.close(obj);
  }
  private maskPhoneNo(): string {
    return this._localization.captions.common.PhoneFormat ?
      this._localization.captions.common.PhoneFormat : '999999999999999999';
  }
  setmandatoryField(eve,curr, altcontrol, formGroup: UntypedFormGroup) {
    if(eve.target.value){ 
      formGroup.controls[altcontrol].setValidators([Validators.required]);
    }else{
      formGroup.controls[altcontrol].clearValidators();
    } 
    formGroup.controls[altcontrol].markAsTouched();
    formGroup.controls[altcontrol].updateValueAndValidity();
  }

}