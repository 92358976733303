import { PlayerRetailItem } from "src/app/retail/shop/shop.modals";
import { RetailItemType } from 'src/app/retail/retail.modals';
import { EditRetailItemService } from "../data-services/golfschedule/edit-retail-item.service";
import { GolfScheduleCommunication } from "../communication/services/golfschedule.service";
import { RentalSourceType } from "src/app/retail/shared/service/common-variables.service";
import { TeeTimesActionService } from "../data-services/golfschedule/teeTimesAction.data.service";
import { Injectable } from "@angular/core";
import { CancelPlayers } from "src/app/tee-time-actions/move-player-modal/move-player-modal.model";
import { FromAction } from "../global.constant";


@Injectable({ providedIn: 'root' })
export class RentalEventService {
    constructor(private _retailItemService: EditRetailItemService,
        private _golfScheduleCommunication: GolfScheduleCommunication,private _teeTimesActionService:TeeTimesActionService) 
    {}

    async RemoveRentalItems(scheduledTeeTimePlayerIds: number[],cancelPlayers: CancelPlayers,fromAction: FromAction) 
    {
        if(fromAction == FromAction.NoShow)
        {
            await this.RemoveRentalItemOnTeeAction(scheduledTeeTimePlayerIds);
            return;
        }

        if(fromAction == FromAction.RetailTransaction)
        {
            let res = await this._teeTimesActionService.UpdatePlayerStatusAsNoShowReBook(cancelPlayers,true);
            if(res.successOperation)
                await this.RemoveRentalItemOnTeeAction(scheduledTeeTimePlayerIds);
        }
    }

    async RemoveRentalItemOnTeeAction(scheduledTeeTimePlayerIds: number[]){
        await this.deleteRentalItemsFromGolf(scheduledTeeTimePlayerIds).then(x => {
            if (x) {
                this.deleteRentalItems(scheduledTeeTimePlayerIds);
            }
        });
    }

    public async deleteRentalItemsFromGolf(noShowPlayerIds: number[]): Promise<boolean> {
        var playerRentalItems: PlayerRetailItem[] = [];
        this._retailItemService.getPlayerRetailItems(noShowPlayerIds).then(x => {
            if (x?.length > 0) {
                playerRentalItems = x.filter(x => x.retailItemType = RetailItemType.PMSAddOnsRentalItem);
            }
            playerRentalItems.forEach(element => {
                element.retailItems = '';
            });
            return this._golfScheduleCommunication.putPromise<boolean>({
                route: GolfApiRoute.UpdateRetailItemsForPlayers,
                body: playerRentalItems
            });
        });
        return true;
    }

    async deleteRentalItems(scheduledTeeTimePlayerIds)
    {
        let rentalItems = this.FormRentalItems(scheduledTeeTimePlayerIds);
        await this._teeTimesActionService.deleteRentalItems(rentalItems);
    }

    FormRentalItems(selectedPlayersList:number[]): any 
    {
        let rentalItems = null;
        if(selectedPlayersList != null && selectedPlayersList.length > 0 && selectedPlayersList.every(o => o != null))
        {
            rentalItems = { sourceType: RentalSourceType.ScheduledTeeTimePlayer, sourceTypeIds: selectedPlayersList }
        }
        return rentalItems;
    }
}