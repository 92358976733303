import { Injectable } from "@angular/core";
import { Localization } from "src/app/common/localization/localization";
import { HttpMethod, HttpServiceCall } from "src/app/common/shared/shared/service/http-call.service";
import * as GlobalConst from 'src/app/common/shared/shared/globalsContant';
import { ElasticTopExceptionDetails, TransactionDetails } from "../../Models/logViewer.model";
import { SPAConfig } from "src/app/common/shared/config/SPA-config";

@Injectable({
  providedIn: "root"
})

export class LogMessagesBussines {
  captions: any;
  constructor(private localization: Localization, public http: HttpServiceCall,public spaConfig: SPAConfig) {
    this.captions = this.localization.captions;
  }

  async callAPIGetAllTransactions(filterData, recordStart) {
    let uriParams = {
      startDate: this.localization.ConvertDateToISODateTime(filterData.fromdateValue),
      endDate: this.localization.ConvertDateToISODateTime(filterData.todateValue),
      recordStart: recordStart
    };
    let response = await this.http.CallApiAsync<TransactionDetails>({
      host: GlobalConst.Host.golfManagement,
      callDesc: "GetTransactionsByDate",
      uriParams: uriParams,
      method: HttpMethod.Get
    });
    return response.result;
  }

  async callAPIGetTopUserTransaction(filterData) {
    let uriParams = {
      startDate: this.localization.ConvertDateToISODateTime(filterData.fromdateValue),
      endDate: this.localization.ConvertDateToISODateTime(filterData.todateValue)
    };
    let response = await this.http.CallApiAsync<ElasticTopExceptionDetails>({
      host: GlobalConst.Host.golfManagement,
      callDesc: "GetTopTransactionsByUsers",
      uriParams: uriParams,
      method: HttpMethod.Get
    });
    return response.result;
  }

  async callAPIGetTopFrequentTransaction(filterData) {
    let uriParams = {
      startDate: this.localization.ConvertDateToISODateTime(filterData.fromdateValue),
      endDate: this.localization.ConvertDateToISODateTime(filterData.todateValue)
    };
    let response = await this.http.CallApiAsync<ElasticTopExceptionDetails>({
      host: GlobalConst.Host.golfManagement,
      callDesc: "GetTopFrequentTransactions",
      uriParams: uriParams,
      method: HttpMethod.Get
    });
    return response.result;
  }


  async callAPIGetAllTransactionsForUser(filterData, userName) {
    let uriParams = {
      startDate: this.localization.ConvertDateToISODateTime(filterData.fromdateValue),
      endDate: this.localization.ConvertDateToISODateTime(filterData.todateValue),
      userName: userName
    };
    let response = await this.http.CallApiAsync<TransactionDetails>({
      host: GlobalConst.Host.golfManagement,
      callDesc: "GetAllTransactionsForUser",
      uriParams: uriParams,
      method: HttpMethod.Get
    });
    return response.result;
  }

  async callAPIGetAllTransactionsForScreen(filterData, screenName) {
    let body = {
      startDate: this.localization.ConvertDateToISODateTime(filterData.fromdateValue),
      endDate: this.localization.ConvertDateToISODateTime(filterData.todateValue),
      screenName: screenName
    };
    let url = this.spaConfig.getUrl("host." + GlobalConst.Host.golfManagement) + this.spaConfig.getUrl("GetAllTransactionsForScreen");
    let data = await this.http.InvokeApiAsync<any>(url,HttpMethod.Post, body)
    return data.result;
  }

}