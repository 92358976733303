import { Injectable } from "@angular/core";
import { ActionTypeEnum, HdrActionTypeEnum, SorTypeEnum } from "src/app/common/components/cdkvirtual/cdkvirtual.model";
import { TableHeaderOptions, TableOptions } from "src/app/common/Models/ag-models";
import { GolfLocalization } from "src/app/core/localization/golf-localization";
import { RateType } from "src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal";
import { RateTypeDataService } from "src/app/shared/data-services/golfschedule/ratetype.data.service";
import { TeeSheetsDataService } from "src/app/shared/data-services/golfschedule/teesheets.data.service";
import { UnPaidPlayersService } from "src/app/shared/data-services/golfschedule/unpaid-players.service";
import { ScheduledTeeTimeUnPaidPlayer } from "src/app/shared/models/unpaid-players.model";
import { GolfUtilities } from "src/app/shared/utilities/golf-utilities";
import { PlayerPaymentstatus } from "src/app/tee-time/search/search-model";
import { memberPlayerData, TeetimeMemberDetail } from "./redeem-model";
import { API as settingsAPI } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { DefaultsSettingConfig } from "../teetime/player-details/player-info.model";
import { SettingsDataService } from "src/app/shared/data-services/golfschedule/settings.data.service";
import { SettingModule, SettingScreen } from "src/app/shared/global.constant";
import { CourseDataService } from "src/app/shared/data-services/golfschedule/course.data.service";
import { Course } from 'src/app/settings/golf-setup/code-setup/course/create-course-modal/create-course-model';
import { PaymentBusinessService } from "src/app/shared/data-services/payment/payment-business.service";
import _ from "lodash";
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';

@Injectable()
export class RedeemModalBussiness {
  isToContinueWithOldCartItems:boolean= false;
  captions:any;
  constructor(private localization: GolfLocalization
    , private _Utilities: GolfUtilities
    , private _courseDataService: CourseDataService
    , private rateTypeDataService: RateTypeDataService
    , private _settingSerivice: SettingsDataService
    , private teeSheetsDataService: TeeSheetsDataService
    , private _unPaidPlayerService: UnPaidPlayersService
    , private _paymentBusinessService: PaymentBusinessService
    , private _PropertyInformation:RetailPropertyInformation
    ) {
    this.captions=this.localization.captions;
  }

  public getHeaderOptions(dropOption:any): TableHeaderOptions[] {
    let headerOptions =  [
      {
        key: 'checked',
        displayNameId: '',
        displayName: '',
        hdrtemplateName: HdrActionTypeEnum.hdrCheckbox,
        templateName: ActionTypeEnum.checkbox,
        isCheckboxDisabled: false
      },
      {
        key: 'playPos',
        displayNameId: 'Player Position',
        displayName: this.captions.tbl_hdr_playpos,
        sortingKey: ''
      },
      {
        key: 'playerName',
        displayNameId: 'tbl_hdr_playerName',
        displayName: this.captions.tbl_hdr_playerName ,
        sortingKey: ''
      },
      {
        key: 'teeTime',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_teeTime,
        sortingKey: 'teeTime'
      },
      {
        key: 'rateType',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_rateType,
        sortingKey: 'rateType'
      },
      {
        key: 'course',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_course,
        sortingKey: 'course'
      },
      {
        key: 'holes',
        displayNameId: '',
        displayName: this.captions.tbl_hdr_hole,
        sortingKey: 'holes'
      },
      {
        key: 'member',
        displayNameId: 'tbl_hdr_member',
        displayName: this.captions.tbl_hdr_member,
        sortingKey: 'member',
        templateName: ActionTypeEnum.custom,
        inputs: {selectOptions: dropOption}
      }
    ];
    
    const memberConfig = this._PropertyInformation.getMemberConfig;
    if(memberConfig && memberConfig.applicableCorporates!= null && memberConfig.applicableCorporates){
      headerOptions.splice(5,0,{ 
        key: 'corpName',
        displayNameId: '',
        displayName: this.captions.settings.corpName,
        sortingKey: 'corpName'
      });
    }
    return headerOptions;
  }
    
  public getTableOptions(): TableOptions {
    return {
      actions: [
      ],
      defaultsortingColoumnKey: 'room',
      showTotalRecords: false,
      defaultSortOrder: SorTypeEnum.asc,
      columnFreeze: {
        firstColumn: false,
        lastColumn: false
      },
      isDragDisabled: false,
      isRowClick: true,
      isHeaderCheckboxAllowed: true,
      checkboxKey: 'checked',
      ignoreSort: true
    };
  }

  public getRateTypes(): Promise<RateType[]> {
    return this.rateTypeDataService.getAllRateTypes(false);
  }

  public async GetAllMemberPlayerDate(courseId: number, date: Date): Promise<TeetimeMemberDetail[]> {
    return this.teeSheetsDataService.getAllMemberTeeTimesOnDate(courseId, date);
  }

  public async GetPackageItemsByRetailItemIds(itemIds: number[]): Promise<any[]>
  {
    let retailPackagedItems = [];
		let packageItemsList = [];
    if (itemIds?.length > 0) {
      retailPackagedItems = await this._paymentBusinessService.GetRetailItemsDetailedInfoByIds(itemIds);
      if (retailPackagedItems) {
        const packageItems = retailPackagedItems.map(x => x.packagedItem);
          packageItems.filter(x => x.length > 0).map(c => c).forEach(d => {
            d.forEach(element => {
              packageItemsList.push(element);
            });
          });
      }
    }
    return packageItemsList;
  }

  public async GetAllUnPaidPlayers(courseId: number, date: Date):  Promise<ScheduledTeeTimeUnPaidPlayer[]>  {
    const [unPaid, refund] = await Promise.all([
      this._unPaidPlayerService.getScheduledTeeTimePlayers(courseId, this.localization.convertDateTimeToAPIDateTime(date), PlayerPaymentstatus.unPaid),
      this._unPaidPlayerService.getScheduledTeeTimePlayers(courseId, this.localization.convertDateTimeToAPIDateTime(date), PlayerPaymentstatus.refund)      
    ]);
    return Promise.resolve([...unPaid,...refund]);
  }

  public async getDefaultSettings(): Promise<DefaultsSettingConfig> {
    let defaultsApiData: settingsAPI.Settings<settingsAPI.DefaultsSettingConfig> = await this._settingSerivice.getSettings<settingsAPI.DefaultsSettingConfig>(SettingModule.SystemSetup, SettingScreen.Defaults);
    return this.mapDefaultData(defaultsApiData.configValue);
  }

  public async GetCourseDetailsById(courseId: number): Promise<Course> {
    return await this._courseDataService.getCourse(courseId);
  }

  private mapDefaultData(configValue: settingsAPI.DefaultsSettingConfig): DefaultsSettingConfig {
    return {
        blockingCode: configValue.blockingCode,
        memberStatus: configValue.memberStatus,
        memberPlayerType: configValue.memberPlayerType,
        memberRateType: configValue.memberRateType,
        nonMemberPlayerType: configValue.nonMemberPlayerType,
        nonMemberRateType: configValue.nonMemberRateType,
        resortPlayerType: configValue.resortPlayerType,
        resortRateType: configValue.resortRateType,
        defaultOutletType: configValue.defaultOutletType,
        memberGuestPlayerType: configValue.memberGuestPlayerType,
        memberGuestRateType: configValue.memberGuestRateType,
        memberGuestsToAdd: configValue.memberGuestsToAdd,
        memberGuestPlayerFirstName: configValue.memberGuestPlayerFirstName,
        memberGuestPlayerLastName: configValue.memberGuestPlayerLastName,
        markAsPaidPaymentMethod: configValue.markAsPaidPaymentMethod,
        markAsPaidPaymentMethodReceiptComment: configValue.markAsPaidPaymentMethodReceiptComment,
        enableNonZeroRateForMarkAsPaid: configValue.enableNonZeroRateForMarkAsPaid,
        zeroMemberChargePaymentMethod: configValue.zeroMemberChargePaymentMethod,
        zeroMemberChargePaymentMethodReceiptComment: configValue.zeroMemberChargePaymentMethodReceiptComment
    }
}

  public mapToUIData(memberDetail, rateTypes:RateType[], course) {
    const result= memberDetail.map(x=>{
      return {
        memberCardNumber: x.memberCardNumber,
        member: x.memberCardNumber,
        course: course,
        teeTime: this.localization.LocalizeShortDateTime(x.scheduleDateTime).toUpperCase(),
        teeTimeId: x.teeTimeId,
        playerName: this._Utilities.formatGuestName(x.firstName, x.lastName),
        playerId: x.playerId,
        rateType: rateTypes.find(rateType=> rateType.id ==x.rateTypeId)?.type,
        holes: x.holeNumber,
        teeTimeApiDate: x.scheduleDateTime,
        checked: (x.memberCardNumber && x.memberCardNumber !== '') ? true : false,
        playPos: x.playerPosition,
        playerTypeId : x.playerTypeId,
        corpId : x.corpId
      }
    });
    result.sort((a,b)=> {
      return  new Date(a.teeTimeApiDate).getTime() - new Date(b.teeTimeApiDate).getTime() || a.playPos-b.playPos
    });
    return result;
  }
}
