import { Injectable } from "@angular/core";
import { GolfManagementCommunication } from 'src/app/shared/communication/services/golfmanagement.service';
import { RateType } from 'src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal';
import { API } from 'src/app/settings/golf-setup/code-linking/code-link-modal';
import { GuaranteeType } from "../../global.constant";


@Injectable()
export class RateTypeDataService {

    constructor(private _golfManagementCommunication: GolfManagementCommunication) {

    }

    public  getAllRateTypes(showInactiveRateTypes: boolean): Promise<RateType[]> {
       let ratetype = this._golfManagementCommunication.getPromise<RateType[]>(
            { route: GolfApiRoute.GetAllRateTypes,uriParams: { showInactive: showInactiveRateTypes }  });
            return ratetype;
    }

    public async  getAllMemberRateTypes(showInactiveRateTypes: boolean): Promise<RateType[]> {
        let ratetype = await this._golfManagementCommunication.getPromise<RateType[]>(
             { route: GolfApiRoute.GetAllRateTypes,uriParams: { showInactive: showInactiveRateTypes }  });
             return ratetype.filter(c=>c.guaranteeType == GuaranteeType.Member);
     }

    public  getRateType(rateTypeId: number): Promise<RateType> {
        return this._golfManagementCommunication.getPromise<RateType>(
            { route: GolfApiRoute.GetRateType,uriParams: { id: rateTypeId }  });
        
    }

    public async getNextListOrder(): Promise<number> {
        return await this._golfManagementCommunication.getPromise<number>(
            { route: GolfApiRoute.GetNextListOrder  });
        
    }

    public createRateType(body: RateType): Promise<RateType[]> {
        return this._golfManagementCommunication.postPromise<RateType[]>({ route: GolfApiRoute.CreateRateType,body: body });
       
    }

    public updateRateType(body: RateType): Promise<RateType[]> {
        return this._golfManagementCommunication.putPromise<RateType[]>({ route: GolfApiRoute.UpdateRateType,body: body }, false);
        
    }

    public async deleteRateType(id: number): Promise<RateType[]> {
        return  await this._golfManagementCommunication.deletePromise<RateType[]>({ route: GolfApiRoute.DeleteRateType,uriParams: { id: id } }, false);
        
    }

    public async getPlayerTypeRateTypes(): Promise<API.PlayerTypeRateType[]> {
        return this._golfManagementCommunication.getPromise<API.PlayerTypeRateType[]>({
            route: GolfApiRoute.GetPlayerTypeRateTypes
        });
    }

}

