import { Injectable } from "@angular/core";

@Injectable()

export class DepositeService {  
  getInfo = [{
    player: 'Jannet  Daily',
    playerType: 'Public Member',
    rateType: '100.00',
    deposit: '90.00',
    amount: '90.00',
    balance: '20.00'
  },
  {
    player: 'Jennifer Aniston',
    playerType: 'Public Member ',
    rateType: '90.00',
    deposit: '90.00',
    amount: '90.00',
    balance: '20.00'
  },
  {
    player: 'Jennifer Aniston',
    playerType: 'Public Member ',
    rateType: '108.00',
    deposit: '90.00',
    amount: '90.00',
    balance: '20.00'
  },
  {
    player: 'Jennifer Aniston',
    playerType: 'Public Member ',
    rateType: '90.00',
    deposit: '90.00',
    amount: '90.00',
    balance: '20.00'
  }, {
    player: 'Jennifer Aniston',
    playerType: 'Public Member ',
    rateType: '100.00',
    deposit: '90.00',
    amount: '90.00',
    balance: '20.00'
  }
  ];

  

}