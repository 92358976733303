import { Injectable } from '@angular/core';
import * as ConvertWaitlistModalInterface from './convert-waitlist-modal';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Injectable()
export class ConvertWaitlistModalService {

  captions: any;
  contactPhoneType: ConvertWaitlistModalInterface.ContactPhoneType[];
  contactEmailType: ConvertWaitlistModalInterface.ContactEmailType[];
  playerType: ConvertWaitlistModalInterface.playerType[];
  rateType: ConvertWaitlistModalInterface.rateType[];
  course: ConvertWaitlistModalInterface.course[];
 
  
  constructor(private _Localization: GolfLocalization) { }

  captionsGenerator() {
    this.captions = this._Localization.captions.settings;
    return this.captions;
  }

  getPhoneType() {
    return this.contactPhoneType = [
      { id: 1, description: this.captions.home, type: "Phone" },
      { id: 2, description: this.captions.work, type: "Phone" },
      { id: 3, description: this.captions.mobile, type: "Phone" }
    ];
  }
  getEmailType() {
    return this.contactEmailType = [
      { id: 9, description: this.captions.personal, type: "Email" },
      { id: 10, description: this.captions.work, type: "Email" }
    ];
  }
  getPlayerType() {
    return this.playerType = [
      { id: 1, description: 'playerType 1', type: "playerType" },
      { id: 2, description: 'playerType 2', type: "playerType" }
    ];
  }
  getRateType() {
    return this.rateType = [
      { id: 1, description: 'rateType 1', type: "rateType" },
      { id: 2, description: 'rateType 2', type: "rateType" }
    ];
  }
  getCourse() {
    return this.course = [
      { id: 1, description: 'course 1', type: "course" },
      { id: 2, description: 'course 2', type: "course" }
    ];
  }

  getPlayerList() {
    return [
      {
        id: 1,
        playerName: 'John Smith',
        memberId: 123446,
        requestedTime: '08/23/2018 09:14AM',
        numberOfPlayers: '4',
        course: 'Jones',
        phoneNumber: '123-123-1234',
        emailAddress: 'jssmith@gmail.com',
        comments: 'Comments'
      },
      {
        id: 2,
        playerName: 'Shane Grief',
        memberId: 123446,
        requestedTime: '08/23/2018 09:14AM',
        numberOfPlayers: '4',
        course: 'Jones',
        phoneNumber: '123-123-1234',
        emailAddress: 'jssmith@gmail.com',
        comments: 'Comments'
      },
      {
        id: 3,
        playerName: 'Camarin debai',
        memberId: 123446,
        requestedTime: '08/23/2018 09:14AM',
        numberOfPlayers: '4',
        course: 'Jones',
        phoneNumber: '123-123-1234',
        emailAddress: 'jssmith@gmail.com',
        comments: 'Comments'
      },
      {
        id: 4,
        playerName: 'Catherine Statuck',
        memberId: 123446,
        requestedTime: '08/23/2018 09:14AM',
        numberOfPlayers: '4',
        course: 'Jones',
        phoneNumber: '123-123-1234',
        emailAddress: 'jssmith@gmail.com',
        comments: 'Comments'
      },
      {
        id: 5,
        playerName: 'Aaron Calvert',
        memberId: 123446,
        requestedTime: '08/23/2018 09:14AM',
        numberOfPlayers: '4',
        course: 'Jones',
        phoneNumber: '123-123-1234',
        emailAddress: 'jssmith@gmail.com',
        comments: 'Comments'
      },{
        id: 6,
        playerName: 'John Smith',
        memberId: 123446,
        requestedTime: '08/23/2018 09:14AM',
        numberOfPlayers: '4',
        course: 'Jones',
        phoneNumber: '123-123-1234',
        emailAddress: 'jssmith@gmail.com',
        comments: 'Comments'
      },{
        id: 7,
        playerName: 'John Smith',
        memberId: 123446,
        requestedTime: '08/23/2018 09:14AM',
        numberOfPlayers: '4',
        course: 'Jones',
        phoneNumber: '123-123-1234',
        emailAddress: 'jssmith@gmail.com',
        comments: 'Comments'
      },
    ]
  }


}
