import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AgFieldConfig } from '../../shared-models';

@Component({
  selector: 'app-aggolf-currency',
  templateUrl: './ag-currency.component.html'  
})
export class AgGolfCurrencyComponent  {
  customClass: string;
  errorMessage: string;
  groupName: UntypedFormGroup;
  controlName: string;
  placeHolder: string;
  value: string|number;
  automationId:string="";

  @Input('inputs')
  set inputOptions(value: AgFieldConfig) {
    this.customClass = value.className;
    this.errorMessage = value.errorMessage;
    this.groupName = value.form;
    this.controlName = value.formControlName;
    this.placeHolder = value.placeHolder;
    this.value = value.value;
    this.automationId = value.automationId ?  value.automationId: '';
  }

}
