import { Injectable } from '@angular/core';
import { RateSetupCaption, CourseOption, RateTypeOption, AvailableDay, RateSetupData, RateSetupDataUI, RateSetupModalData, TimeType } from './rate-setup.model';
import { RateTypeDataService } from 'src/app/shared/data-services/golfmanagement/ratetype.data.service';
import { RateType } from '../golf-setup/code-setup/rate-type/rate-type.modal';
import { CourseDataService } from 'src/app/shared/data-services/golfmanagement/course.data.service';
import { RateSetupDataService } from 'src/app/shared/data-services/golfmanagement/rate-setup.data.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { RetailItem } from '../settings-shared/assign-retail-item/assign-retail-item.model';
import { AssignRetailItemService } from '../settings-shared/assign-retail-item/assign-retail-item.service';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { UserdefaultsInformationService } from 'src/app/core/services/userdefaults-information.service';
import { DropdownOptions } from 'src/app/common/Models/ag-models';

@Injectable({ providedIn: 'root' })
export class RateSetupBusiness {
  public readonly RateCaptions: RateSetupCaption;
  public isAllow: boolean = false;
  public isViewOnly: boolean = false;
  public defaultDate: Date;

  constructor(
    private _rateSetupDataService: RateSetupDataService,
    private _rateTypeDataService: RateTypeDataService,
    private _courseDataService: CourseDataService,
    private _retailItemService: AssignRetailItemService,
    private _localization: GolfLocalization,
    private _userAccessBusiness: UserAccessBusiness,
    private _propertyInformation: GolfPropertyInformation,
    private _userDefaultService: UserdefaultsInformationService,
    private _assignRetailItemService: AssignRetailItemService) {
    this.RateCaptions = this._localization.captions.settings;
  }

  public getDefaultDate() {
    this.defaultDate = this._propertyInformation.CurrentDTTM;
  }

  public async loadRateSetupData(fromDate: Date, toDate: Date): Promise<RateSetupData[]> {
    var fromDateToAPI = this._localization.convertDateObjToAPIdate(fromDate),
      toDatetoAPI = this._localization.convertDateObjToAPIdate(toDate);
    return await this._rateSetupDataService.getRateCourseTeeFeesByDateFilter(fromDateToAPI, toDatetoAPI);
  }

  async validateBreakPoints(): Promise<boolean> {
    const result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.RATESETUP, true);
    this.isViewOnly = result.isViewOnly;
    this.isAllow = result.isAllow;
    return result.isAllow;
  }



  public async saveRateSetupData(rateSetupModalData: RateSetupModalData): Promise<RateSetupData[]> {
    let rateSetupData = this.mapToAPIModel(rateSetupModalData);
    return await this._rateSetupDataService.createRateCourseTeeFee(rateSetupData);
  }

  public async updateRateSetupData(rateSetupModalData: RateSetupModalData): Promise<RateSetupData[]> {
    let rateSetupData = this.mapToAPIModel(rateSetupModalData);
    return await this._rateSetupDataService.updateRateCourseTeeFee(rateSetupData);
  }

  public deleteMultipleRateSetupData(ids: number[]): Promise<RateSetupData[]> {
    return this._rateSetupDataService.deleteMultipleRateSetupData(ids);
  }


  public async getCourses(): Promise<CourseOption[]> {
    const course: any = await this._courseDataService.getCourses();

    return course.map(c => {
      return {
        id: c.id,
        name: c.name,
        description: c.name,
        defaultOutletId: c.defaultOutletId
      }
    });   
  }

    public async getRateTypes(): Promise<RateTypeOption[]> {
        const rateType: RateType[] = await this._rateTypeDataService.getAllRateTypes(false);
       return rateType.map(r => {
            return {
                id: r.id,
                name: r.type,
                description: r.type,
                guaranteeType:r.guaranteeType
            }
        });        
    }

  public getDaysData(): AvailableDay[] {
    return this._rateSetupDataService.GetDays();
  }

  public mapToAPIModel(rateSetupModalData: RateSetupModalData): RateSetupData {
    return {
      id: rateSetupModalData.id,
      courseId: rateSetupModalData.rateCourse.length > 0 ? rateSetupModalData.rateCourse.map(x=> x.id) : rateSetupModalData.rateCourse,
      rateTypeId: rateSetupModalData.rateRateType,
      startDate: this._localization.convertDateObjToAPIdate(rateSetupModalData.rateStartDate),
      endDate: this._localization.convertDateObjToAPIdate(rateSetupModalData.rateEndDate),
      startTime: this.setDateAndTime(rateSetupModalData.rateStartDate, rateSetupModalData.rateStartTime, TimeType.STARTTIME),
      endTime: this.setDateAndTime(rateSetupModalData.rateEndDate, rateSetupModalData.rateEndTime, TimeType.ENDTIME),
      dayOfWeek: this.setDaysOfWeek(rateSetupModalData.rateAvailableDays),
      greenFee: this._localization.currencyToSQLFormat(rateSetupModalData.rateGreenFee),
      cartFee: this._localization.currencyToSQLFormat(rateSetupModalData.rateCartFee),
      entryFee: this._localization.currencyToSQLFormat(rateSetupModalData.rateEntryFee),
      overrideCourseTeeFee: rateSetupModalData.overrideCourseTeeFee,
      greenFeeRetailItemId: rateSetupModalData.rateGreenFeeRetailItemId,
      cartFeeRetailItemId: rateSetupModalData.rateCartFeeRetailItemId,
      entryFeeRetailItemId: rateSetupModalData.rateEntryFeeRetailItemId
    }   
  }

  public async mapToGridUI(rateSetupData: RateSetupData[], courses: CourseOption[], rateTypes: RateTypeOption[], retailItems: RetailItem[]): Promise<RateSetupDataUI[]> {

    let retailItemIdList: number[] = [];
    let retailItemList: RetailItem[]
    rateSetupData.sort((left: RateSetupData, right: RateSetupData) => this.sortRateTypeByDate(left, right)).forEach(individualRate => {
      if (individualRate.cartFeeRetailItemId)
        retailItemIdList.push(individualRate.cartFeeRetailItemId);
      if (individualRate.entryFeeRetailItemId)
        retailItemIdList.push(individualRate.entryFeeRetailItemId);
      if (individualRate.greenFeeRetailItemId)
        retailItemIdList.push(individualRate.greenFeeRetailItemId);
    });

    if (retailItemIdList && retailItemIdList.length > 0) {
      retailItemList = await this._assignRetailItemService.GetRetailItemsByIds(retailItemIdList);
    }

    return rateSetupData.map(r => {
      const greenFeeRetailItem = retailItemList.find(c => c.id == r.greenFeeRetailItemId);
      const cartFeeRetailItem = retailItemList.find(c => c.id == r.cartFeeRetailItemId);
      const entryFeeRetailItem = retailItemList.find(c => c.id == r.entryFeeRetailItemId);
      return {
        id: r.id,
        courseId: r.courseId,
        courseName: courses.find(c => c.id == r.courseId) && courses.find(c => c.id == r.courseId).name,
        rateTypeId: r.rateTypeId,
        rateTypeName: rateTypes.find(c => c.id == r.rateTypeId) && rateTypes.find(c => c.id == r.rateTypeId).name,
        configDate: this._localization.GetFormattedDateDDMMYY(this._localization.getDate(r.startDate)),
        orgConfigDate: this._localization.getDate(r.startDate),
        startDate: r.startDate,
        endDate: r.endDate,
        startTime: r.startTime,
        endTime: r.endTime,
        timeRange: this._localization.LocalizeTime(r.startTime).toUpperCase() + " - " + this._localization.LocalizeTime(r.endTime).toUpperCase(),
        dayOfWeek: r.dayOfWeek,
        greenFee: this._localization.localizeCurrency(r.greenFee, false, 2),
        cartFee: this._localization.localizeCurrency(r.cartFee, false, 2),
        entryFee: this._localization.localizeCurrency(r.entryFee, false, 2),
        orgGreenFee: r.greenFee,
        orgCartFee: r.cartFee,
        orgEntryFee: r.entryFee,
        greenFeeRetailItemId: r.greenFeeRetailItemId,
        greenFeeRetailItemName: greenFeeRetailItem && greenFeeRetailItem.itemDescription,
        greenFeeRetailItemNumber: greenFeeRetailItem && (greenFeeRetailItem.itemNumber),
        cartFeeRetailItemId: r.cartFeeRetailItemId,
        cartFeeRetailItemName: cartFeeRetailItem && cartFeeRetailItem.itemDescription,
        cartFeeRetailItemNumber: cartFeeRetailItem && (cartFeeRetailItem.itemNumber),
        entryFeeRetailItemId: r.entryFeeRetailItemId,
        entryFeeRetailItemName: entryFeeRetailItem && entryFeeRetailItem.itemDescription,
        entryFeeRetailItemNumber: entryFeeRetailItem && (entryFeeRetailItem.itemNumber),
        isRestrictDelete: this.isHistoricalDataRecord(r.startTime) || this.isViewOnly,
        isRestrictEdit: this.isViewOnly,
        isRestrictCheckbox: this.isViewOnly
      }
    });     
  }
  private sortRateTypeByDate(left: RateSetupData, right: RateSetupData): number {
    return this._localization.getDate(left.startDate).getTime() - this._localization.getDate(right.startDate).getTime()
  }



  public mapToModalFormData(rateSetupDataUI: RateSetupDataUI, courses: CourseOption[], rateTypes: RateTypeOption[], availableDays: AvailableDay[]): RateSetupModalData {
    return {
      id: rateSetupDataUI.id,
      rateCourse: courses.find(c => c.id == rateSetupDataUI.courseId) && courses.find(c => c.id == rateSetupDataUI.courseId).id,
      rateRateType: rateTypes.find(r => r.id == rateSetupDataUI.rateTypeId) && rateTypes.find(r => r.id == rateSetupDataUI.rateTypeId).id,
      rateStartDate: this._localization.getDate(rateSetupDataUI.startDate),
      rateEndDate: this._localization.getDate(rateSetupDataUI.endDate),
      rateAvailableDays: this.setAvailableDayData(rateSetupDataUI.dayOfWeek, availableDays),
      rateStartTime: this._localization.LocalizeTime(rateSetupDataUI.startTime),
      rateEndTime: this._localization.LocalizeTime(rateSetupDataUI.endTime),
      rateGreenFee: rateSetupDataUI.greenFee,
      rateCartFee: rateSetupDataUI.cartFee,
      rateEntryFee: rateSetupDataUI.entryFee,
      rateGreenFeeRetailItemId: rateSetupDataUI.greenFeeRetailItemId,
      rateGreenFeeRetailItemName: rateSetupDataUI.greenFeeRetailItemName,
      rateGreenFeeRetailItemNumber: rateSetupDataUI.greenFeeRetailItemNumber ? rateSetupDataUI.greenFeeRetailItemNumber.toString() : '',
      rateCartFeeRetailItemId: rateSetupDataUI.cartFeeRetailItemId,
      rateCartFeeRetailItemName: rateSetupDataUI.cartFeeRetailItemName,
      rateCartFeeRetailItemNumber: rateSetupDataUI.cartFeeRetailItemNumber ? rateSetupDataUI.cartFeeRetailItemNumber.toString() : '',
      rateEntryFeeRetailItemId: rateSetupDataUI.entryFeeRetailItemId,
      rateEntryFeeRetailItemName: rateSetupDataUI.entryFeeRetailItemName,
      rateEntryFeeRetailItemNumber: rateSetupDataUI.entryFeeRetailItemNumber ? rateSetupDataUI.entryFeeRetailItemNumber.toString() : '',
      overrideCourseTeeFee: false
    };   
  }

  public setAvailableDayData(dayOfWeek: string[], availableDays: AvailableDay[]): AvailableDay[] {
    let selectedDays: AvailableDay[] = [];

    dayOfWeek.forEach((d) => availableDays.forEach(a => {
      if (d === a.value) {
        selectedDays.push(a);
      }
    }));

    return selectedDays;
  }

  public setDaysOfWeek(availableDays: AvailableDay[]): string[] {
    let selectedDaysofWeek: string[] = [];
    availableDays.forEach(a => {
      selectedDaysofWeek.push(a.value);
    });
    return selectedDaysofWeek;
  }


  public loadFilter(course, rateType) {
    return [
      {
        id: 1,
        name: 'Course',
        key: 'courseId',
        title: this.RateCaptions.course,
        filters: course
      },
      {
        id: 2,
        name: 'RateType',
        key: 'rateTypeId',
        title: this.RateCaptions.rateType,
        filters: rateType
      }];
  }

  private setDateAndTime(date: any, time: any, type: string): string {
    if (!time) {
      if (type === TimeType.STARTTIME)
        time = TimeType.DEFAULTSTARTTIME;
      else if (type === TimeType.ENDTIME)
        time = TimeType.DEFAULTENDTIME;
    }
    var dateTime = this._localization.AddTimeToDate(date, this.dummyDateForSelectedTime(time));
    return this._localization.ConvertDateToISODateTime(dateTime);
  }

  private dummyDateForSelectedTime(time: string): any {
    return `2000/01/01 ${time}`;
  }

  private isHistoricalDataRecord(dateTime: string): boolean {
    var propertyCurrentDate = this._propertyInformation.CurrentDate,
      rateDateTime = this._localization.getDate(dateTime);

    return propertyCurrentDate.getTime() > rateDateTime.getTime() ? true : false;
  }

  courseMapper(data) {
    return data.map(x => this.uiMapper(x));
  }

  uiMapper(option): DropdownOptions {
    return {
      id: option.id,
      value: option.name,
      viewValue: option.name,
      checked: true
    }
  }


}
