<div class="measures-header d-flex flex-wrap ">
  <form class="formWidth h-100" [formGroup]="setupFormGroup">
    <mat-form-field [floatLabel]="floatLabel" class="float-left" [ngClass]="{'button_invalid': isViewOnly}">
      <input matInput placeholder="{{setupName}}" (input)="checkValid($event)" formControlName="setupCodeName"
      [maxlength]="maxInputLength" [minlength]="minInputLength" autocomplete="off" inputtype = "nospecailchar"
       [skipInputValidation]="skipValidation" [showErrPopup] ="showValidationErrPopup" >
      <mat-error
        *ngIf="setupFormGroup.controls['setupCodeName'].hasError('required') && !setupFormGroup.controls['setupCodeName'].valid">
        {{errorText}}
      </mat-error>
      <mat-error
      *ngIf="setupFormGroup.controls['setupCodeName'].errors?.minlength">
        {{minmumValidationErrMsg}}
    </mat-error>
    </mat-form-field>
    <mat-form-field [floatLabel]="floatLabel" class="float-left" *ngIf="enableTerminal" [ngClass]="{'button_invalid': isViewOnly}">
        <mat-select placeholder="{{terminalID}}" formControlName="terminalId"  (selectionChange)="terminalChange($event)" [disabled]="inlineEditFlag">
            <mat-option  [value]='' ></mat-option>
            <mat-option *ngFor='let Header of headerOptions' [value]='Header.id' >{{Header.id}}</mat-option>
        </mat-select>
        <mat-error *ngIf="setupFormGroup.controls['terminalId'].hasError('required') && !setupFormGroup.controls['terminalId'].valid">
        {{terminalMissing}}
      </mat-error>
    </mat-form-field>
    <div class="mr-3 float-left" >
        <div><label class="LW12 pr-2 active-label label-color form-label-normal">{{active}}</label></div>
        <app-common-toggle-switch formControlName="activetoggle" [disabled]="inlineEditFlag" (changeToggleEvent)="checkValidState($event)" class="toggle-switch"></app-common-toggle-switch>
    </div>
    <button mat-raised-button type="button" class="LW14 mr-2 button_height"
      [ngClass]="setupFormGroup.valid || isValidRoleName? 'button--primary' : 'button--disabled'"
      [disabled]="!setupFormGroup.valid || !isValidRoleName || isViewOnly || inlineEditFlag" (click)="onButtonClick()">{{headerButtonName}}</button>
    <a *ngIf="isCancelButtonAvailable" class=" LW14 ml-2 search_button_width cancelButton button_height"
      (click)="onButtonCancelClick($event)">{{cancelButtonName}}</a>
  </form>
  <div class="d-flex align-items-end width-class custSearchbox" >
  <mat-form-field  *ngIf="!isEditFlag" class="searchArea pb-1 spa-searchbox ml-auto" [floatLabel]="floatLabelNever"
    [ngClass]="{'retailSearchWidth': enableToggleButton}">
    <input matInput autocomplete="off" class="search-input" [(ngModel)]="searchText"
      [ngModelOptions]="{standalone: true}" type="text" placeholder="{{searchPlaceholderValue}}" [maxlength]="maxInputLength"
      value="" (keypress)="searchValue()" (ngModelChange)="searchValueChanged($event)">
    <i  aria-hidden="true" [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'" class="cursor" (click)="resetValue()"></i>
  </mat-form-field>
  <i  aria-hidden="true" class="align-self-center icon-Search button_invalid ml-auto" *ngIf="isEditFlag"></i>
</div>
</div>
