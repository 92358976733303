
    <form class='player-infor-form h-100 newVersionWrapper' [formGroup]="additionalInfoFormGrp" autocomplete="off">
        <div *ngIf = "isGuestRecordPurged && showClose">
            <app-warning-bar  *ngIf = "isGuestRecordPurged && showClose" (closeWarning)="closeWarning()" [message]="allCaptions.lbl_guestRecordPurged" [showClose]="showClose">
            </app-warning-bar>
        </div>
        <div class="ag_display--flex h-100">
            <div class="w-70 left-wrapper" [ngClass]="isGuestRecordPurged  ? 'ag_link--disabled opacity-spa' : ''">
                <div>
                    <h6 class="form--headers ag_pb--3">{{captions.vipTypeInfoHeader}}</h6>
                    <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--xs">
                        <mat-select [attr.automationId]="'Dd_PlayerInfo_vipType'" [placeholder]="captions.vipType" name="vipType" formControlName="vipType" [compareWith]="comparetSelects">
                            <mat-option *ngIf="!guestData || !guestData.isExternalGuest"></mat-option>
                            <mat-option *ngFor="let vipType of vipTypes" [matTooltip]="vipType?.name" [value]="vipType">
                            {{vipType?.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <app-retail-capture-card [isShowPMAgentValidationMessage]="false" [PaymentReferenceID]="PaymentReferenceID"
                    (NewPaymentReferenceID)="SaveReferenceId($event)" (removeDetails)="RemoveCardDetail($event)" ></app-retail-capture-card>
            </div>
            <div class="right-wrapper pl-3 w-30">
                <div class="ag_display--flex wrapper" *ngIf="IsGDPREnabled">
                    <div [attr.automationId]="'Btn_PlayerInfo_openGuestPolicyDialog1'" (click)="openGuestPolicyDialog(1);" class="divider" [ngClass]="isGuestRecordPurged  ? 'ag_link--disabled opacity-spa' : ''">
                        <span class="boxed-icon" >
                            <i class="icon-policy"></i>
                            <i *ngIf="policyType == 2" class="icon-dashboard-tick"></i>
                        </span>
                        <span class="span-label">{{captions.consentManagement}}</span>
                    </div>
                    <div [attr.automationId]="'Btn_PlayerInfo_openGuestPolicyDialog2'" (click)="openGuestPolicyDialog(2);" class="divider" [ngClass]="(guestData && guestData.isPurged == true) || guestGuidId == '' || !IsGDPREnabled || isGuestRecordPurged ? 'ag_link--disabled opacity-spa' : ''" >
                        <span class="boxed-icon">
                            <i class="icon-retention"></i>
                            <i *ngIf="guestData && guestData.isPurged == true" class="icon-dashboard-tick"></i>
                        </span>
                        <span class="span-label">{{captions.retentionManagement}}</span>
                    </div>
                    <div [attr.automationId]="'Btn_PlayerInfo_openGuestPolicyDialog3'" (click)="openGuestPolicyDialog(3);" class="divider border-right-0" [ngClass]=" !IsGDPREnabled || guestGuidId ==''  ? 'ag_link--disabled opacity-spa' : ''">
                        <span class="boxed-icon">
                            <i class="icon-export"></i>
                            <i *ngIf="guestData && guestData.isPurged == true"  class="icon-dashboard-tick"></i> 
                        </span>
                        <span class="span-label">{{captions.exportSend}}</span>
                    </div>
                </div>
                <!-- <div class="notification mt-3">
                    <label class="LW12 ag_display--block">{{captions.notificationPreference}}</label>
                    <mat-radio-group class='ag_form-control--2 custom-radio' formControlName="notification">
                        <mat-radio-button class='ag_display--inblock  ag_form--radio-button LW12 w-140px' [value]='opt.id'
                            *ngFor="let opt of noftificationConfig" (change)="modeChange($event)">
                            {{opt.viewValue}}
                        </mat-radio-button>
                        <br>
                    </mat-radio-group>
                    <mat-checkbox class='ag_display--block LW12' [formControlName]="category.control" (change)='OnChage($event)'
                        *ngFor='let category of checkBoxnNotiPref' [value]='category.isSelected'>
                        {{category.name }}
                    </mat-checkbox>
                </div> -->
            </div>
        </div>
    </form>
