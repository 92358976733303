<div class="dialog-container newVersionWrapper">
        <app-golfdialog-header [automationId]="automationId" [title]="title" (closeDialog)="closeHandler($event)">   
                <span class="custom-teetime-next" *ngIf="isFromTeeSheet">
                        <button [attr.automationId]="'Icn_TeeTime_leftArrow'" mat-icon-button class="golf--secondarygreen switch__nav switch__btn" (click)="decreaseSlot($event)"
                        [disabled]='!(isPreviousSlotAvailable && playersLoadedInPlayerCard && slideInputLoaded)'>
                        <i aria-hidden="true" class='icon-left-arrow'></i>
                        </button>
                        <button  [attr.automationId]="'Icn_TeeTime_rightArrow'" mat-icon-button class="golf--secondarygreen switch__btn mr-4" (click)="increaseSlot($event)"
                        [disabled]='!(isNextSlotAvailable && playersLoadedInPlayerCard && slideInputLoaded)'>
                        <i aria-hidden="true" class='icon-right-arrow'></i>
                        </button>           
                </span>
        </app-golfdialog-header>
        <ng-container *ngIf="!(playersLoadedInPlayerCard && slideInputLoaded)">
          <div id="tee-time-spinner">
                        <div class="customspinnerimg">
                          <img src="./assets/images/agil_favicon.ico">
                        </div>
                        <div id="default-message">Processing...</div>
                       </div>
        </ng-container>
        <div class="dialog-container__content matTabWrap" [formGroup]="TeeTimeFormGroup" [ngClass]="{'button_invalid': !(playersLoadedInPlayerCard && slideInputLoaded)}">
                <mat-tab-group #tabGroup [selectedIndex]="selectedIndex" class="teetime" #infoElement>
                        <mat-tab [label]="captions.playerDetails">
                                <div class="ag_height--100">
                                        <app-player-details #playerDetailsTab class="ag_height--100"
                                                (formReady)="formInitialized($event, Tabs.playerInformation)"
                                                [slideInput]="slideInput" 
                                                [(teeTimeInfo)]="teeTimeInfo" [valueChanged] = "teeTimeData"
                                                [slotChange]="slotChange"
                                                (teeTimeInfoChange)="teeTimeInfoChange($event)"
                                                [cancellationNoShowPolicies]="cancellationNoShowPolicies"
                                                [isMixedPlayersSlot]="isMixedPlayersSlot"
                                                [normalBookUserAccess]="normalBookUserAccess"
                                                [bulkEditUserAccess]="bulkEditUserAccess" 
                                                [lessonPlayerDetail]="lessonPlayerDetail"
                                                (customfieldEmitter)="receivedCustomfields($event)"
                                                (playerAsDirty)="playerAsDirty($event)"
                                                (isplayerLoaded)="isplayerLoaded($event)"
                                                (isSlideInputLoaded) = "isSlideInputLoaded($event)"
                                                (isPlayerCleared)="playerCleared($event)"
                                                (emitAllocationChange)="slideAllocationSave($event)"
                                                [allocationcodeRolePermission]="allocationcodeRolePermission"
                                                [playerProfileUserAccess]="playerProfileBKAccess"
                                                (bulkEmit)="redirectToBulkBooking($event)"
                                                (payEmit)="payClick($event)"
                                                [tabAccessOnEdit]="tabAccessOnEdit" [isRefundCheck]="isRefundCheck"
                                                [isBulkEdit]="isBulkEdit" [isHitoricalOnEdit]="isHitoricalOnEdit"
                                                [isEdit]="isEdit" [defaultPlayerId]="defaultPlayerId" [isEditFromGrid]="isEditFromGrid"
                                                [isViewOnly]="isViewOnly" [payIconEnable]="payIconEnable">
                                        </app-player-details>
                                </div>
                        </mat-tab>
                        <mat-tab [label]="captions.generalInformation">
                                <ng-template matTabContent>
                                        <div>
                                                <app-general-information [teeTimeInfo]="teeTimeInfo"
                                                        [isMixedPlayersSlot]="isMixedPlayersSlot"
                                                        [normalBookUserAccess]="normalBookUserAccess"
                                                        [bulkEditUserAccess]="bulkEditUserAccess"
                                                        [bulkPlayers]="bulkPlayers" (disableSave)="disableSave($event)"
                                                        [tabAccessOnEdit]="tabAccessOnEdit" [isBulkEdit]="isBulkEdit"
                                                        (formReady)="formInitialized($event, Tabs.generalInformation)"
                                                        [isHitoricalOnEdit]="isHitoricalOnEdit">
                                                </app-general-information>
                                        </div>
                                </ng-template>
                        </mat-tab>
                        <mat-tab [label]="captions.feeInformation">
                                <ng-template matTabContent>
                                        <div>
                                                <app-fee-information [teeTimeInfo]="teeTimeInfo"
                                                        [isMixedPlayersSlot]="isMixedPlayersSlot"
                                                        [normalBookUserAccess]="normalBookUserAccess"
                                                        [bulkEditUserAccess]="bulkEditUserAccess"
                                                        [tabAccessOnEdit]="tabAccessOnEdit" [isBulkEdit]="isBulkEdit"
                                                        (formReady)="formInitialized($event, Tabs.feeInformation)"
                                                        [isHitoricalOnEdit]="isHitoricalOnEdit">
                                                </app-fee-information>
                                        </div>
                                </ng-template>
                        </mat-tab>
                        <mat-tab [label]="captions.otherInformation">
                                <ng-template matTabContent>
                                        <div>
                                                <app-other-information [teeTimeInfo]="teeTimeInfo"
                                                        [isMixedPlayersSlot]="isMixedPlayersSlot"
                                                        [normalBookUserAccess]="normalBookUserAccess"
                                                        [bulkEditUserAccess]="bulkEditUserAccess"
                                                        [tabAccessOnEdit]="tabAccessOnEdit" [isBulkEdit]="isBulkEdit"
                                                        (formReady)="formInitialized($event, Tabs.otherInformation)"
                                                        [isHitoricalOnEdit]="isHitoricalOnEdit">
                                                </app-other-information>
                                        </div>
                                </ng-template>
                        </mat-tab>
                        <mat-tab [label]="captions.itinerary">
                                <ng-template matTabContent>
                                        <div class="pl-3 pr-3">
                                                <app-common-itinerary [guestId]="guestId"
                                                        [teeTimePlayersGuestId]="teeTimePlayersGuestId"
                                                        [fromTeeTime]="fromTeeTime"
                                                        [isHitoricalOnEdit]="isHitoricalOnEdit"
                                                        (viewRentalRetail) = "getRentalItems($event)"
                                                        [rentalRetailItems] = "rentalRetailItems"></app-common-itinerary>
                                        </div>
                                </ng-template>
                        </mat-tab>
                </mat-tab-group>
        </div>
        <div>
                <app-golfdialog-footer [automationId]="automationId" [buttonObj]="buttonObj" [vcartObj]="vcartObj" [disableSaveOnly]="disableSaveOnly"
                        (save)="saveHandler($event, false, false)" (cancel)="cancelHandler($event)" (addToCart)="addToCartHandler($event)"
                        (saveCancel)="saveCloseHandler($event)" [enableSaveCancel]="true" [clickThrottleTime]=5>
                </app-golfdialog-footer>
        </div>
</div>