<section class="golf-container--padding" id="print">
  <div class="printWrapper" >
    <form autocomplete="off" [formGroup]="refundForm">    
      <!-- <div *ngIf="!isMultple">
       <mat-radio-group class="golf__radio" formControlName="refund" >
         <mat-radio-button class="golf--form-radio-button golf-radio--horizontal" *ngFor="let x of refundArray" [value]="x.value"
           [checked]="x.checked">
           {{x.value}}
         </mat-radio-button>
       </mat-radio-group>
      </div> -->
    </form>
    <div *ngIf="isMultple" class="table-container">     
        <app-cdkvirtual [headerOptions]="headerOptions" [options]="options"
                 [tableContent]="tableData"  (EmittedData)='tableAction($event)'
                   ></app-cdkvirtual>
    </div>
 
  </div>
  
 </section>
 