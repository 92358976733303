import { Injectable } from "@angular/core";
import { ClearPlayerService } from 'src/app/tee-time-actions/clear-player-modal/clear-player-service';
import { CheckboxTableHeader, AlertType, ButtonType } from 'src/app/shared/shared-models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { ClearPlayer } from 'src/app/tee-time-actions/clear-player-modal/clear-player-model';
import { TeeTimeDataService } from 'src/app/shared/data-services/golfschedule/TeeTime.data.service';
import { PlayerTypeService } from 'src/app/shared/data-services/golfmanagement/playertype.data.service';
import { RateTypeDataService } from 'src/app/shared/data-services/golfmanagement/ratetype.data.service';
import { RateType } from 'src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal';
import { PlayerType } from 'src/app/settings/golf-setup/code-setup/player-type/player-type.modal';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { ScheduledPlayer } from 'src/app/shared/models/teesheet.form.models';
import { PlayerPaymentstatus } from 'src/app/tee-time/search/search-model';
import { CourseDataService } from 'src/app/shared/data-services/golfschedule/course.data.service';
import { Course } from 'src/app/settings/golf-setup/code-setup/course/create-course-modal/create-course-model';
import { TeeTimesActionBusiness } from 'src/app/tee-time/shared/tee-action.business';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';
import { allocationBlockPlayerTypePermission } from "src/app/settings/user-setup/booking-permission/booking-permission.model";
import { AllocationBlockDataService as AllocationBlockManagementDataService } from 'src/app/shared/data-services/golfmanagement/allocationblock.data.service';
import { UserAccessModel } from "src/app/common/dataservices/authentication/useraccess-model.model";
import { GolfNowConfigAPI } from 'src/app/settings/interfaces/thirdparty-integration/golfnow-setup/golfnow-setup.model';
import { SettingsDataService } from 'src/app/shared/data-services/golfschedule/settings.data.service';
import { SettingModule, SettingScreen } from 'src/app/shared/global.constant';

@Injectable()


export class ClearBusiness {
  public readonly clearPlayerCaption: any;
  constructor(
    private _clearPlayerService: ClearPlayerService,
    private _courseDataService: CourseDataService,
    private _teeTimeDataService: TeeTimeDataService,
    private _playerTypeService: PlayerTypeService,
    private _rateTypeDataService: RateTypeDataService,
    private _localization: GolfLocalization,
    private _utilities: GolfUtilities,
    private _teeTimesActionBusiness: TeeTimesActionBusiness,
    private _userAccessBusiness: UserAccessBusiness,
    private _settingService: SettingsDataService,
    private allocationCodeManagementService: AllocationBlockManagementDataService) {
    this.clearPlayerCaption = this._localization.captions.settings;
  }

  public playersCount: number = 0;
  public checkInCheckOutCount: number = 0;
  public paidPlayersCount: number = 0;
  private _scheduledPlayers: ScheduledPlayer[] = [];
  public get ScheduledPlayers() {
    return this._scheduledPlayers;
  }

  async getGeneralInformation(scheduleId: number, courseName: string, bulkBookingId: string): Promise<ClearPlayer[]> {
    if (bulkBookingId === 'back') { return []; }
    const courses: Promise<Course[]> = this._courseDataService.getCoursesWithUserAccess();
    const rateTypes: Promise<RateType[]> = this._rateTypeDataService.getAllRateTypes(true);
    const playerTypes: Promise<PlayerType[]> = this._playerTypeService.getAllPlayerTypes(false);
    let scheduledPlayers: Promise<ScheduledPlayer[]> = null;
    var lenCheckInCheckOut: number = 0;
    var lenPaidPlayers: number = 0;
    let dateA: any;
    let dateB: any;
    if (bulkBookingId && bulkBookingId.length > 0) {
      scheduledPlayers = this._teeTimeDataService.getScheduledPlayersByBookingId(bulkBookingId);
    } else {
      scheduledPlayers = this._teeTimeDataService.getScheduledPlayers(scheduleId);
    }

    const responses = Promise.all([courses, rateTypes, playerTypes, scheduledPlayers]);
    const players = await responses.then(p => {
      p[3].sort((a, b) => {
        dateA = new Date(a.scheduledDateTime);
        dateB = new Date(b.scheduledDateTime);
        return dateA - dateB;
      });
      this._scheduledPlayers = p[3];
      return p[3].map(o => {
        const course = p[0] && p[0].find(c => c.id === o.courseId);
        const rateType = p[1] && p[1].find(r => r.id === o.rateTypeId);
        const playerType = p[2] && p[2].find(r => r.id === o.playerTypeId);
        this._scheduledPlayers = p[3];
        let isCheckedInOutExist = (o.playerStatus & PlayerPaymentstatus.CheckIn) != 0 || (o.playerStatus & PlayerPaymentstatus.CheckOut) != 0
        isCheckedInOutExist ? lenCheckInCheckOut++ : lenCheckInCheckOut;
        lenPaidPlayers = (o.playerStatus & PlayerPaymentstatus.paid) != 0 ? lenPaidPlayers++ : lenPaidPlayers;
        return {
          id: o.playerId,
          player: this._utilities.formatGuestName(o.firstName, o.lastName),
          teeTime: this._localization.LocalizeShortDate(this._localization.getDate(o.scheduledDateTime)) + ' ' + this._localization.getTime(o.scheduledDateTime as Date, 12),
          course: course ? course.name : '',
          amount: this._localization.localizeCurrency((o.greenFee ? o.greenFee : 0) + (o.cartFee ? o.cartFee : 0), false),
          playerType: playerType ? playerType.type : '',
          playerTypeId: playerType ? playerType.id : 0,
          rateType: rateType ? rateType.type : '',
          deposits: o.deposits,
          playerStatus: o.playerStatus,
          ticketNumber: o.ticketNumber,
          checked: true,
          isBlocked: o.isBlocked,
          teeTimeFormat: o.teeTimeFormat,
          bookingSource: o.bookingSource,
          scheduledTeeTimePlayerId : o.scheduledTeeTimePlayerId
        }
      });
    });
    this.checkInCheckOutCount = lenCheckInCheckOut;
    this.paidPlayersCount = lenPaidPlayers;
    const unpaidPlayers = players.filter(u => ((u.playerStatus & PlayerPaymentstatus.unPaid) != 0 || (u.playerStatus & PlayerPaymentstatus.refund) != 0) && (!u.deposits || u.deposits.length === 0) && (u.playerStatus & PlayerPaymentstatus.CheckIn) == 0 && (u.playerStatus & PlayerPaymentstatus.CheckOut) == 0);
    this.playersCount = unpaidPlayers.length;
    return unpaidPlayers;
  }

  public GetHeaderOption(): CheckboxTableHeader[] {
    return [
      { key: 'player', description: this.clearPlayerCaption.cancelHeaderTitlePlayer, alignment: 'textLeft' },
      { key: 'teeTime', description: this.clearPlayerCaption.cancelHeaderTitleTeeTime, alignment: 'textLeft' },
      { key: 'playerType', description: this.clearPlayerCaption.cancelHeaderTitlePlayerType, alignment: 'textLeft' },
      { key: 'rateType', description: this.clearPlayerCaption.cancelHeaderTitleRateType, alignment: 'textLeft' },
      { key: 'course', description: this.clearPlayerCaption.cancelHeaderTitleCourseName, alignment: 'textLeft' },
      { key: 'amount', description: this._localization.replacePlaceholders(this.clearPlayerCaption.cancelHeaderTitleAmount, ["option"], [this._localization.currencySymbol]), alignment: 'textRight' }];
  }

  clearPlayers(scheduleId: number, playerIds: number[], callback: any, data?: any, selectedScheduledTeeTimePlayerIds?:number[]) {
    return this._clearPlayerService.clearPlayers(scheduleId, playerIds, callback, data,selectedScheduledTeeTimePlayerIds);
  }

  showAlertMessage(message: string, alertType: AlertType, buttonType: ButtonType, callback: any, extraParams?: any, alertHeader?: string) {
    this._utilities.showAlert(
      message,
      alertType, buttonType,
      callback, extraParams,
      alertHeader
    );
  }

  public checkSelectedPlayersInCart(selectedPlayersList: number[]): boolean {
    return this._teeTimesActionBusiness.checkSelectedPlayersInCart(selectedPlayersList);
  }
  public async allowCancellationOfGolfNowPlayers(): Promise<boolean> {
    let res = await this._settingService.getSettings<GolfNowConfigAPI>(
     SettingModule.SystemSetup,
     SettingScreen.GolfNowIntegration);
     if(res?.configValue?.allowCancellationOfGolfNowBooking)
      return res?.configValue?.allowCancellationOfGolfNowBooking;
    return false;
 }

 public checkSelectedPlayersHasAnyPendingTransaction(selectedPlayersList: number[], callBackfn: any, dialogRef: any): boolean {
    const isClearAllowed = this._teeTimesActionBusiness.IsPlayersHaveAnyPendingTransactions(selectedPlayersList, this.ScheduledPlayers)
    if (isClearAllowed) {
      this._teeTimesActionBusiness.ShowTransactionPendingDialog(selectedPlayersList, callBackfn, dialogRef);
    }
    return isClearAllowed;
  }

  async validateMixedBreakPoints(breakPointNumber: number, showUserMessage: boolean, callback?: any): Promise<UserAccessModel.BreakPointResult> {
    return await this._userAccessBusiness.getUserAccess(breakPointNumber, showUserMessage, callback);

  }

  public async GetAllocationBlockPermissionByRole(roleId?: string): Promise<allocationBlockPlayerTypePermission[]> {
    return await this.allocationCodeManagementService.getAllocationBlockPermissionByRole(roleId);
  }

}
