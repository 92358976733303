import { Injectable } from '@angular/core';
import { RetailmanagementCommunication } from 'src/app/shared/communication/services/retailmanagement.service';
import { DefaultUserConfiguration } from 'src/app/settings/utilities/manager-utilities/default-user-config/default-user-config-model';
import { Outlet } from 'src/app/retail/retail.modals';
import { Course } from 'src/app/settings/golf-setup/code-setup/course/create-course-modal/create-course-model';
import { GolfLocalization } from '../localization/golf-localization';
import { LoginCommunicationService } from 'src/app/login/login-communication.service';
import { GolfManagementCommunication } from 'src/app/shared/communication/services/golfmanagement.service';

@Injectable({
  providedIn: 'root'
})

export class UserdefaultsInformationService {

  private outlets: Outlet[];
  private courses: Course[];
  private captions: any;
  private defaultUserConfig: DefaultUserConfiguration;
  constructor(
    private _retailmanagementCommunication: RetailmanagementCommunication,
    private _golfManagementCommunication: GolfManagementCommunication,
    private _loginCommunication: LoginCommunicationService,
    private _localization: GolfLocalization) {
    this.captions = this._localization.captions.settings.utilities;
  }

  public syncDefaultValues(userId) {
    this.defaultConfig(userId);
    this.setOutlets();
    this.setCourses();
  }

  public get DefaultUserConfig() : DefaultUserConfiguration{
    return this.defaultUserConfig;
  }


  private GetDefaultUserConfiguration(userId: string): Promise<DefaultUserConfiguration> {
    return this._retailmanagementCommunication.getPromise({
      route: GolfApiRoute.GetDefaultUserConfiguration,
      uriParams: { 'userId': userId }
    })
  }
  private defaultConfig(userId) {
    this.GetDefaultUserConfiguration(userId).then((d) => {
      this.defaultUserConfig = d;
      sessionStorage.setItem("userDefaults", JSON.stringify(d));
    });
  }
  private setOutlets() {
    this.fetchOutlets().then((o) => {
      this.outlets = o;
      sessionStorage.setItem("outlets", JSON.stringify(o));
    });
  }

  private setCourses() {
    this.fetchCourses().then((c) => {
      this.courses = c;
      sessionStorage.setItem("courses", JSON.stringify(c));
    });

  }

  private fetchCourses(IncludeInActive: boolean = false): Promise<Course[]> {
    return this._golfManagementCommunication.getPromise<Course[]>(
      { route: GolfApiRoute.GetCourses, uriParams: { "includeInactive": IncludeInActive } }, false);
    
  }

  private async fetchOutlets() {
    let serviceParams = {
      route: GolfApiRoute.GetOutletsByProperty,
      uriParams: { PropertyId: Number(this._localization.GetPropertyInfo('PropertyId')) },
      header: '',
      body: undefined,
      showError: true,
      baseResponse: true
    };
    let response: any = await this._retailmanagementCommunication.getPromise(serviceParams);
    return this.mapSubpropertyToOutlet(response);
  }

  private mapSubpropertyToOutlet(subP): Outlet[] {
    return subP.map(s => {
       return <Outlet>{
        id: s.subPropertyId,
        isActive: s.isActive,
        outletNumber: s.subPropertyId,
        outletName: s.subPropertyName
      }
    });    
  }

  public getPaymentDevices() {
    return[
      { id: '', description: '' },
      { id: 'rguestpay', description: this.captions.RGuestPay },
      { id: 'idtech', description: this.captions.Idtech }
    ];    
  }

  sync() {
    if (this.defaultUserConfig == undefined) {
      this.defaultUserConfig = JSON.parse(sessionStorage.getItem('userDefaults'));
    }
    if (this.courses == undefined) {
      this.courses = JSON.parse(sessionStorage.getItem('courses'));
    }
    if (this.outlets == undefined) {
      this.outlets = JSON.parse(sessionStorage.getItem('outlets'));
    }

  }
  GetDefaultOutlet() {
    this.sync();
    const configuredOutlet = this.outlets && this.outlets.find(x => this.defaultUserConfig && x.id == this.defaultUserConfig.defaultOutletId);
    const description = configuredOutlet && configuredOutlet.outletName;
    let outletId = this.defaultUserConfig && this.defaultUserConfig.defaultOutletId;
    return { id: (outletId == 0) ? null : outletId, type: description };
  }
  GetDefaultCourses() {
    this.sync();
    const configuredCourse = this.courses && this.courses.find(x => this.defaultUserConfig && x.id === this.defaultUserConfig.defaultCourseId);
    const description = configuredCourse && configuredCourse.name;
    let courseId = this.defaultUserConfig && this.defaultUserConfig.defaultCourseId;
    return { id: (courseId == 0) ? null : courseId, type: description };
  }
  GetDefaultPaymentDevice() {
    this.sync();
    return this.defaultUserConfig.defaultPaymentDevice;
  }
  GetDefaultDeviceName() {
    this.sync();
    return this.defaultUserConfig.defaultDeviceName;
  }

  SetDefaults(_defaults)
  {
    this.defaultUserConfig = _defaults;
    sessionStorage.setItem("userDefaults", JSON.stringify(_defaults));
  }
}
