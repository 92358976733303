import { Injectable } from "@angular/core";
import { PaymentBusinessService } from 'src/app/shared/data-services/payment/payment-business.service';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonPopupComponent } from 'src/app/shared/components/common-popup/common-popup.component';
import { Router } from '@angular/router';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { UnPaidPlayersService } from 'src/app/shared/data-services/golfschedule/unpaid-players.service';
import * as GlobalConst from '../../retail/shared/globalsContant';
import { PlayerPaymentstatus } from 'src/app/tee-time/search/search-model';
import * as _ from 'lodash';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { RetailBussinessService } from 'src/app/shared/retail.bussiness';
import { ScheduledTeeTimeUnPaidPlayer, AlertType} from 'src/app/retail/shared/shared.modal';
import { ButtonType, CheckboxTableHeader } from 'src/app/shared/shared-models';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { CommonVariablesService, TransactionStatus } from 'src/app/retail/shared/service/common-variables.service';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { CourseDataService } from 'src/app/shared/data-services/golfschedule/course.data.service';
import { SettingsDataService } from 'src/app/shared/data-services/golfschedule/settings.data.service';
import { DefaultUserConfigDataService } from 'src/app/settings/utilities/manager-utilities/default-user-config/default-user-config.data.service';
import { TableHeaderOptions } from "src/app/common/Models/ag-models";
import { ActionTypeEnum, aligment, SorTypeEnum } from "src/app/common/components/cdkvirtual/cdkvirtual.model";
import { RefundPlayers } from "./refund-model";
import { PlayerTypeService } from "src/app/shared/data-services/golfschedule/playertype.data.service";
import { RateTypeDataService } from "src/app/shared/data-services/golfschedule/ratetype.data.service";
@Injectable()

export class RefundBussiness {
    constructor(private _paymentBusinessService: PaymentBusinessService
        , private _retailService: RetailSharedVariableService
        , private _retailBusinessService: RetailBussinessService
        //, public dialogRef: MatDialogRef<CommonPopupComponent>
        , private router: Router
        , private _localization: GolfLocalization
        , private _unpaiPlayerService: UnPaidPlayersService
        , private _utils: GolfUtilities
        , private _propertyInfo: GolfPropertyInformation
        , private _courseDataService: CourseDataService
        , private _settingSerivice: SettingsDataService
        , private _commonVariable: CommonVariablesService
        , private _defaultUserConfig: DefaultUserConfigDataService,
        private _playerTypeService: PlayerTypeService,
        private _rateTypeService: RateTypeDataService
    ) { this.captions = _localization.captions.teetime; }
    captions: any;
    refundArray() {
        return [
            {
                id: '1',
                value: this.captions.Refund,
                checked: true
            },
            {
                id: '2',
                value: this.captions.RefundWithExchange,
                checked: false
            }
        ]
    }

    async ProceedRefund(teeTimeModelData) {
       
        this._retailService.selectedProducts = [];
        this._retailService.SelectedPlayers = [];
        this._retailService.selectedTransaction = null;
        this._retailService.TaxValue = 0;
        if (teeTimeModelData && teeTimeModelData.playerDetail) {
            let transaction = teeTimeModelData.playerDetail.find(p => p.transactionId > 0 && (p.playerStatus & PlayerPaymentstatus.paid) != 0); //TO-DO Need to provide an UI to select user if multiple transaction IDs involved
            let distinctTransactionIds: any = [...new Set(teeTimeModelData.playerDetail.filter(x => (x.playerStatus & PlayerPaymentstatus.paid) != 0).map(x => x.transactionId))];
            distinctTransactionIds = distinctTransactionIds.filter(x => x > 0);
            
               /* Bug - 28853 - With IG bug - Fix
                   Ticket status check is added before navigating to Refund screen.
                */
                   if (this._propertyInfo.UseRetailInterface) {
                    let isClosedTicket: boolean = await this.checkTransactionIsClosed(distinctTransactionIds[0]);
                    if (!isClosedTicket) {
                        let alertMsg = this._localization.captions.teetime.TicketNotClosedWarningMsg;
                        this._utils.showAlert(alertMsg, AlertType.Info, ButtonType.Ok);
                        return;
                    }
                }              

              
                let ApiResponses = await Promise.all([this.getTransactionById(transaction.transactionId), [],
                this.GetRetailItemByTransactionId(transaction.transactionId), this.GetPaidPlayers(teeTimeModelData)
                ]);
                if (ApiResponses) {
                    let paidPlayers = ApiResponses[3];
                    let selectedPlayers = paidPlayers.filter(pp => teeTimeModelData.playerDetail.some(x => x.playerId == pp.playerId));
                    ApiResponses[2] = _.sortBy(ApiResponses[2], 'lineNumber');
                    let transactionItems = ApiResponses[2];
                    selectedPlayers.forEach(s => {
                        this.SetPlayerNamesForProduct(s, transactionItems);
                    });

                    //this._retailService.selectedTransaction = ApiResponses[0];
                    this._retailService.payeeId = ApiResponses[0].guestId;
                    this._retailService.sectionName = "RTID"; //Retail transaction Item Detail  
                    this._retailService.settleOpenTransaction = false;
                    this._retailService.reOpenTransaction = false;
                    this._retailService.selectedRetailItem = ApiResponses[0];
                    this._retailService.isReturnWithoutTicket = true;
                    this._commonVariable.selectedTransactionRetailProducts =[];
                    this._commonVariable.selectedclient = null;
                    let ExchangeType = {
                        [RefundMethod.ReturnWithExchange]: 'item_WE',
                        [RefundMethod.ReturnWithoutExchange]: 'item_WOE',
                    }
                    this._retailService.returnExchangeType = ExchangeType[this._retailService.RefundMethod]
                    this._retailService.transactionId = transaction.transactionId;
                    
                    let allRetailItemIds = [...selectedPlayers.map(x => x.cartFeeRetailItemId)]
                    .concat([...selectedPlayers.map(x => x.greenFeeRetailItemId)])
                    .concat([...selectedPlayers.map(x => x.entryFeeRetailItemId)]);
                    let packageItemIds:number[] = [];
                    selectedPlayers.forEach(player => { player.otherItems.forEach(p => packageItemIds.push(p.retailItemId)) });
                    allRetailItemIds = allRetailItemIds.concat(packageItemIds);
                    let distinctItemsIds = [... new Set(allRetailItemIds)];
                    distinctItemsIds = distinctItemsIds.filter(id => id > 0);    
                    let retailItems = await this._paymentBusinessService.GetRetailItemsByIds(distinctItemsIds);
                    this.SetRetailItemDetails(selectedPlayers, retailItems);         
                    if (selectedPlayers.length > 0 && selectedPlayers.length > 0) {
                        this._retailService.SelectedOutletId = ApiResponses[2] ? ApiResponses[2][0].outletId : 0;
                        this._retailService.isTeeTimeAsPaid = true; 
                    }
                    this._commonVariable.SelectedOutletId = this._retailService.SelectedOutletId;
                    this._retailService.selectedProducts = [];
                    if (!await this._retailBusinessService.CheckForUserOutletAccessAndDefaultOutlet('', true)) {
                        return;
                    }
                    this._retailService.receiptComment = ApiResponses[0].receiptComment;
                    await this._retailBusinessService.LoadSelectedProducts(ApiResponses[2], ApiResponses[1], 'Return');
                    this._retailService.selectedTransaction = ApiResponses[0];
                    this._retailService.isFromRefund = true;
                    this._retailService.canSelectProducts = true;
                    this._commonVariable.canSelectProducts = true;
                    this._retailService.RefundTicketTransactionIDs = [];
                    this._retailService.SeletedCourseId = teeTimeModelData.course.id;
                    this._retailService.SelectedDate = teeTimeModelData.time;
                    if(this.router.url == '/tee-time/teesheet/graphicalView'){
                        this._retailService.isFromTeeTimeGraphicalView = true;
                    }
                    else if(this.router.url == 'tee-time/search'){
                      this._retailService.isFromTeetimeSearch = true;
                    }
                    this.router.navigate(['/shop/viewshop/shopItemDetails']);                 
                    
                }
        }
    }

    SetPlayerNamesForProduct(player: ScheduledTeeTimeUnPaidPlayer, transactionItems) {
        let GreenFeeItem = transactionItems.find(t => t.itemId == player.greenFeeRetailItemId && !t.playerName);
        if (GreenFeeItem) {
            GreenFeeItem.playerName = player.playerName
        }
        let CartFeeItem = transactionItems.find(t => t.itemId == player.cartFeeRetailItemId && !t.playerName);
        if (CartFeeItem) {
            CartFeeItem.playerName = player.playerName
            let deposit = transactionItems.find(t => t.lineNumber == (CartFeeItem.lineNumber + 1) && !t.playerName);
            if (deposit && player.deposits && player.deposits.some(d => Math.abs(d.amount) == Math.abs(deposit.totalAmount))) {
                deposit.playerName = player.playerName;
            }
        }
    }

    SetRetailItemDetails(unPaidPlayers: ScheduledTeeTimeUnPaidPlayer[], retailItems: any[]) {
        if (unPaidPlayers.length > 0 && retailItems.length > 0) {
          unPaidPlayers.forEach(player => {
            if (player.cartFeeRetailItemId != 0)
              player.cartFeeRetailItem = retailItems.find(x => x.id == player.cartFeeRetailItemId);
            if (player.greenFeeRetailItemId != 0)
              player.greenFeeRetailItem = retailItems.find(x => x.id == player.greenFeeRetailItemId);
            if (player.entryFeeRetailItemId != 0)
              player.entryFeeRetailItem = retailItems.find(x => x.id == player.entryFeeRetailItemId);
            player.otherItems.forEach(item => {
              if (item.retailItemId != 0) {
                item.retailItemDetail = retailItems.find(x => x.id == item.retailItemId);  
              }          
            });
          });
        }
      }

    async GetPaidPlayers(data) {
        return await this._unpaiPlayerService.getScheduledTeeTimePlayers(data.course.id, data.time, PlayerPaymentstatus.paid);
    }


    async GetRetailItemByTransactionId(transactionId: number) {
        return await this._paymentBusinessService.GetTransactionDetails(transactionId, 3);
    }

    async getTransactionById(transactionId) {
        return await this._paymentBusinessService.GetTransactionById(transactionId);
    }

    async checkTransactionIsClosed(transactionId): Promise<boolean> {
        let transactionIsOpen: boolean = false;
        let transaction = await this._paymentBusinessService.GetTransactionsByIds([transactionId]);
        if (transaction[0]) {
            console.log(transaction);
            transactionIsOpen = (transaction[0] && transaction[0].transactionData && transaction[0].transactionData.status == TransactionStatus.CLOSED);
        }
        return transactionIsOpen;
    }

    

    async getUserDefaultOutlet(userId: string) {
        let userDefaultConfig = await this._defaultUserConfig.GetDefaultUserConfiguration(userId);
    
        if (userDefaultConfig) {
          return userDefaultConfig.defaultOutletId;
        }
        return undefined;
    }

    GetHeaderOption(): TableHeaderOptions[] {
        return [{
          displayName: '',
          key: 'selected',
          searchable: false,
          sorting: false,
          templateName: ActionTypeEnum.radioButton,
          radioKey: 'id'
        },{
          key: 'playerName',
          displayName:  this._localization.captions.teetime.playerName,
          searchable: false,
          sorting: false,
          alignment: aligment.left
        }, {
          key: 'scheduledDateTime',
          displayName: this._localization.captions.teetime.teetime,
          alignment: aligment.left,
          searchable: false,
          sorting: false,
        }, {
            key: 'ticketNumber',
            displayName: this._localization.captions.teetime.ticketNumber,
            alignment: aligment.left,
            searchable: false,
            sorting: false,
          },  {
            key: 'totalAmount',
            displayName: this._localization.captions.teetime.TotalValue,
            alignment: aligment.left,
            searchable: false,
            sorting: false,
          }, {
          key: 'playerType',
          displayName: this._localization.captions.teetime.playerType,
          alignment: aligment.left,
          searchable: false,
          sorting: false,
        }, {
          key: 'rateType',
          displayName: this._localization.captions.teetime.rateType,
          alignment: aligment.left,
          searchable: false,
          sorting: false,
        }, {
          key: 'course',
          displayName: this._localization.captions.teetime.course,
          alignment: aligment.left
        }, {
          key: 'packageCode',
          displayName: this._localization.captions.teetime.packageCode,
          alignment: aligment.left,
          searchable: false,
          sorting: false,
        }, {
          key: 'walk',
          displayName: this._localization.captions.teetime.walk,
          searchable: false,
          sorting: false,
          alignment: aligment.left
        }, {
          key: 'trail',
          displayName: this._localization.captions.teetime.trail,
          searchable: false,
          sorting: false,
          alignment: aligment.left
        }, {
          key: 'holes',
          displayName: this._localization.captions.teetime.holes,
          searchable: false,
          sorting: false,
          alignment: aligment.left
        }];
      }

      getTableOptions() {
        return {
            defaultsortingColoumnKey: 'playerName',
            defaultSortOrder: SorTypeEnum.asc,
            showTotalRecords: false,
            columnFreeze: {
                firstColumn: true,
                lastColumn: false
            }
        };
    }

    async mapTableDataFromDialogData(dialogData): Promise<RefundPlayers[]> {
      let playerTypesTask  =  this._playerTypeService.getAllPlayerTypes();
      let rateTypesTask = this._rateTypeService.getAllRateTypes(true);
      let courseTask = this._courseDataService.getCourse(dialogData.info.course?.id);
      let transactionIds = dialogData.info.playerDetail.filter(p => p.transactionId > 0)?.map(z=> z.transactionId);
      let transactionsTask = this._paymentBusinessService.GetTransactionsByIds(transactionIds);

      await Promise.all([playerTypesTask, rateTypesTask, courseTask, transactionsTask]);

      let playerTypes = await playerTypesTask;
      let rateTypes = await rateTypesTask;
      let course = await courseTask;
      let transactions = await transactionsTask;

        let refundPlayers: RefundPlayers[] = [];
        let playerDetail = (dialogData.info && dialogData.info.playerDetail && dialogData.info.playerDetail.length > 0) ?
        dialogData.info.playerDetail.filter(x=> (x.playerStatus & PlayerPaymentstatus.paid) != 0) : null;
        if (playerDetail) {
            refundPlayers = playerDetail.map(r => {
                return {
                    id: r.playerId,
                    playerName: r.firstName + " " + r.lastName,
                    scheduledDateTime: this._localization.ConvertDateToTime(dialogData.info.time),
                    playerType: playerTypes && playerTypes.length > 0 ? playerTypes.find(x=> x.id == r.scheduledTeeTimePlayerFee.playerTypeId)?.type : '' ,
                    rateType: rateTypes && rateTypes.length > 0 ? rateTypes.find(x=> x.id == r.scheduledTeeTimePlayerFee.rateTypeId)?.type : '',
                    course:  course ? course.name : '',
                    packageCode: r.packageCode ? r.packageCode : '',
                    walk: r.isWalk ? "Yes" : "No",
                    trail: r.isTrail ? "Yes" : "No",
                    holes: r.holes,
                    ticketNumber: r.ticketNumber,
                    totalAmount : (r.transactionId > 0 && transactions && transactions.length > 0) ? this._localization.localizeCurrency(transactions.find(x=> x.id == r.transactionId)?.transactionData?.totalAmount, true) : 
                    this._localization.localizeCurrency(0, true)
                }
            });
        }
        return refundPlayers;
    }

}

enum RefundMethod {
    ReturnWithoutExchange = 1,
    ReturnWithExchange
}
