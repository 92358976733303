import { Injectable } from '@angular/core';
import { GolfManagementCommunication } from '../../communication/services/golfmanagement.service';
import { LessonLocation } from 'src/app/settings/golf-setup/code-setup/lesson-location/lesson-location.model';
import { GolfRoutes } from 'src/app/core/extensions/golf-route';

@Injectable()
export class LessonLocationDataService{

    constructor(private _http: GolfManagementCommunication){

    }

    public async GetLessonLocations(showInactive: boolean):Promise<LessonLocation[]>
    {
        return this._http.getPromise<LessonLocation[]>({
            route:GolfRoutes.GetLessonLocations,
            uriParams :{showInactive : showInactive }
        });
    }

    public async CreateLessonLocation<T>(_lessonLocation:string):Promise<LessonLocation[]>
    {
        return this._http.postPromise<LessonLocation[]>({
            route:GolfRoutes.CreateLessonLocation,
            uriParams:{lessonLocation :_lessonLocation}
        });
    }
    public async UpdateLessonLocation<T>(_lessonLocation:LessonLocation):Promise<LessonLocation[]>
    {
        return this._http.putPromise<LessonLocation[]>({
            route:GolfRoutes.UpdateLessonLocation,
            body:_lessonLocation
        });
    }
    public async DeleteLessonLocation(_id:number):Promise<LessonLocation[]>
    {
        return this._http.deletePromise<LessonLocation[]>({
            route:GolfRoutes.DeleteLessonLocation,
            uriParams: {id :_id}
        });
    }
}