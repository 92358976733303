import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, CanActivateChild } from '@angular/router';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GolfPMSSessionInfo } from 'src/app/shared/shared-models';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { PMSAction } from 'src/app/common/external-request/pms-request.model';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import { UserAccessModel } from 'src/app/common/dataservices/authentication/useraccess-model.model';
import { CartUtilities } from 'src/app/common/components/menu/vcart/cart.utilities';
import { RetailService } from 'src/app/retail/retail.service';

@Injectable({
    providedIn: 'root'
})
export class RouteGuardService implements CanActivate, CanActivateChild, OnDestroy {
    activatedChildresults: UserAccessModel.BreakPointResult[];
    destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(private _userAccessBusiness: UserAccessBusiness, private router: Router, private _utilities : GolfUtilities, private cartUtils: CartUtilities,
        public _ams: RetailService) {
        this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(x => {
            if (this.router.url === '/login') {
                this.activatedChildresults = null;
            }
        });
    }

    ngOnDestroy() {
        if (this.destroyed$) {
            this.destroyed$.next(true);
            this.destroyed$.complete();
        }
    }

    /**
     * @function GetCurrentUserBreakPointDetails
     * @param CurrentRoute ActivatedRouteSnapshot
     * @returns CurrentUserAccess UserBreakPoint
     * @description returns the breakpoint based on the BreakPoint number from route
     */
    async GetCurrentUserBreakPointDetails(CurrentRoute: ActivatedRouteSnapshot, ShowPopup?: boolean) {
        let CurrentUserAccess: Promise<UserAccessModel.BreakPointResult>;
        if (CurrentRoute.data && CurrentRoute.data.BreakPointNumber) {
            CurrentUserAccess = this._userAccessBusiness.getUserAccess(CurrentRoute.data.BreakPointNumber,
                ShowPopup ? ShowPopup : CurrentRoute.data.ShowPopup);
        }
        return CurrentUserAccess;
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return await this.checkAccess(route, state);
    }

    async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return await this.checkAccess(route, state);
    }

    async checkPMSAccess(pmsSessioninfo: GolfPMSSessionInfo){
        let result : UserAccessModel.BreakPointResult;
        const searchActions: PMSAction[] = [PMSAction.CancelReservation, PMSAction.ReInstateReservation, PMSAction.RescheduleReservation, PMSAction.MoveReservation];
        if(pmsSessioninfo.action == PMSAction.Reservation){
            result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.TeeSheet, true);
        }
        else if(searchActions.includes(pmsSessioninfo.action)){
            result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.TeeTimeSearch, true);    
        }
        if(!(result.isAllow || result.isViewOnly)){
            this.router.navigateByUrl('home');
            return false;
        }
    }

    private async checkAccess(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let CurrentUserAccess: UserAccessModel.BreakPointResult;
        const extras = this.router.getCurrentNavigation().extras;
        let redirectURL = route.data.redirectTo;
        let showPopup = route.data.ShowPopup || (extras.state && extras.state.ShowPopup);
        let lastBreakpointNo = route.data.lastBreakPointNumber;
        if( redirectURL == 'pmsIntegration')
        {
            let jsonPropConfig = sessionStorage.getItem('propConfig');
            let pmsSystem = jsonPropConfig ? JSON.parse(jsonPropConfig)?.PMSSystem : null;
            if (pmsSystem && pmsSystem.toLowerCase() != 'stay') {
                redirectURL = '';
                showPopup = true;
                lastBreakpointNo = UserAccessBreakPoints.DATAMAGINE_CONFIGURATION;
            }  
        }
        const breakpoint = route.data.BreakPointNumber;
        const checkSibling = route.data.checkAllSiblings;
        const isModule = route.data.isModule; //True if the current route is module level screen
        const isSubmodule = route.data.isSubmodule; //True if the current route is submodule level screen
        const routeHasChildAttr = checkSibling ? route.parent.data.hasChild : false;
        const ShowPopup = showPopup;
        const isSubModuleChange = extras.state && extras.state.onSubmoduleChange && isSubmodule; //True when current menu is submodule and it is clicked
        const isModuleChange = extras.state && extras.state.onModuleChange && isModule; //True when current menu is module and it is clicked
        const redirectLocation = redirectURL;
        const lastBreakpoint = lastBreakpointNo;
        const isFromPMS = this.router.url.indexOf('/Pms') !== -1;
        const pmsSessioninfo: GolfPMSSessionInfo = this._utilities.getPMSSessionInfo();


        CurrentUserAccess = this.activatedChildresults && this.activatedChildresults.find(x => x.breakPointNumber === breakpoint);

        // V-cart iframe breakpoint check
        if(this.cartUtils.isEmbed()){
            const result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.TeeSheet, true);
            if(!(result.isAllow || result.isViewOnly)){
                // to remove the orphan retail loader from the screen
                const loadingContainer = document.getElementById('cover-spin-retail');
                const loadingContainerMessage = document.getElementById('default-message');
                loadingContainer.style.display = 'none';
                loadingContainerMessage.innerText = '';
                return false;                
            }
        }

        // Fetch all the current and child break point access detail
        if (!CurrentUserAccess || route.data.syncAccess) {
            await this.getChildBreakPointNos(route, breakpoint);
        }
        if (isFromPMS) {    
           await this.checkPMSAccess(pmsSessioninfo);
        }

        // Check if all the siblings and current menu dont have access
        if (routeHasChildAttr &&  (!isSubModuleChange && !isModuleChange)) {
            const childBreakPoints = this.getChildBreakpoints(route);
            const isAnyHasAccess = childBreakPoints.some(x => {
                const currentChild = this.activatedChildresults.find(result => result.breakPointNumber === x.data.BreakPointNumber);
                return currentChild.isAllow || currentChild.isViewOnly;
            });
            if (!isAnyHasAccess && isModule) {
                this.redirectToBetterPath(route.parent.data.redirectTo, route.parent, state);
                return false;
            }
            if (!isAnyHasAccess && isSubmodule) {
                this.redirectToBetterPath(redirectLocation, route, state);
                return false;
            }
        }

        // Check if all the siblings and current menu dont have access
        if (routeHasChildAttr && (isModuleChange || isSubModuleChange)) {
            const childBreakPoints = this.getChildBreakpoints(route);
            const isAnyHasAccess = childBreakPoints.some(x => {
                const currentChild = this.activatedChildresults.find(result => result.breakPointNumber === x.data.BreakPointNumber);
                return currentChild.isAllow || currentChild.isViewOnly;
            });
          if (!isAnyHasAccess) {
            this._userAccessBusiness.showBreakPointError(lastBreakpoint);
            return false;
          }
        }

        // Unless route has breakpoint, allow access to the page
        if (!breakpoint) {
            return true;
        }
        // Show alert if break point alert set in route data
        CurrentUserAccess = this.activatedChildresults && this.activatedChildresults.find(x => x.breakPointNumber === breakpoint);
        if (CurrentUserAccess && !CurrentUserAccess.isAllow && !CurrentUserAccess.isViewOnly && ShowPopup) {
          this._userAccessBusiness.showBreakPointError(breakpoint);
        }

        // CurrentUserAccess = await this.GetCurrentUserBreakPointDetails(route, ShowPopup);
        if (CurrentUserAccess && (CurrentUserAccess.isViewOnly || CurrentUserAccess.isAllow)) {
            return (CurrentUserAccess.isViewOnly || CurrentUserAccess.isAllow);
        }
        if (redirectLocation && !ShowPopup) {
            this.redirectToBetterPath(redirectLocation, route, state);
        }
        return false;
    }

    async getChildBreakPointNos(route: ActivatedRouteSnapshot, breakpoint) {
        const breakPointNumbers = breakpoint ? [breakpoint] : [];
        if (route && route.routeConfig && route.routeConfig.children && route.routeConfig.children.length > 0) {
            route.routeConfig.children.forEach(child => {                
                    if (child && child.data && child.data.BreakPointNumber) {
                        breakPointNumbers.push(child.data.BreakPointNumber);
                    }
                    if (child && child.children && child.children.length > 0) {
                        child.children.forEach(y => {
                            if (y && y.data && y.data.BreakPointNumber) {
                                breakPointNumbers.push(y.data.BreakPointNumber);
                            }
                        });
                    }
                
            });
        }
        const result = breakPointNumbers && breakPointNumbers.length > 0 &&
            await this._userAccessBusiness.getUserAccesses(breakPointNumbers);
        if (result) {
            if (!this.activatedChildresults) {
                this.activatedChildresults = result;
            } else {
                result.forEach(breakPt => {
                    const existingIdx = this.activatedChildresults.findIndex(x => x.breakPointNumber === breakPt.breakPointNumber);
                    if (existingIdx > -1) {
                        this.activatedChildresults[existingIdx] = breakPt;
                    } else {
                        this.activatedChildresults.push(breakPt);
                    }
                });
            }
        }

    }

    private redirectToBetterPath(redirectLocation, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const parentUrl = state.url
            .slice(0, state.url.indexOf(route.url[route.url.length - 1].path));
            if(redirectLocation.indexOf('/') > -1){
                this.router.navigate([redirectLocation]);
            } else {
                this.router.navigate([parentUrl, redirectLocation]);
            }
    }


    //Get all the child breakpoint data from parent route
    private getChildBreakpoints(route : ActivatedRouteSnapshot): any {
        let routes: any = [];
            let routeChildrens = route.routeConfig.children;
            if(routeChildrens && routeChildrens.length > 0) {
                routeChildrens.forEach(routeChildren => {
                if(routeChildren.data && routeChildren.data.BreakPointNumber) {
                    routes.push(routeChildren);
                }
                const nestedChildrens =  routeChildren.children;
                if(nestedChildrens && nestedChildrens.length > 0) {
                    nestedChildrens.forEach(nestedChildren => {
                        if(nestedChildren.data && nestedChildren.data.BreakPointNumber) {
                            routes.push(nestedChildren);
                        }
                    });
                }
            });
        }
        return routes;
    }
    backTrack() {
        let sessionFromURLArray = sessionStorage.getItem('fromURLPath');
        let sessionFromURLRouteStateArray = sessionStorage.getItem('fromURLRouteState');
        let fromURLPathArray = sessionFromURLArray ? JSON.parse(sessionFromURLArray) : [];
        let fromURLRouteStateArray = sessionFromURLRouteStateArray ? JSON.parse(sessionFromURLRouteStateArray) : [];
        if (fromURLPathArray.length > 0) {
            let stateValue = fromURLRouteStateArray[fromURLPathArray.length - 1];
            
            if (stateValue) {
                stateValue.fromBack = true;
                this.router.navigateByUrl(fromURLPathArray[fromURLPathArray.length - 1], {
                    state: stateValue 
                });
            }
            else {
                if(fromURLPathArray[fromURLPathArray.length - 1] != "" && fromURLPathArray[fromURLPathArray.length - 1] != null){
                    this.router.navigateByUrl(fromURLPathArray[fromURLPathArray.length - 1], {
                        state: { fromBack: true }
                    });
                }
            }
           
            fromURLPathArray.pop();
            fromURLRouteStateArray.pop();
            sessionStorage.setItem('fromURLPath', JSON.stringify(fromURLPathArray));
            sessionStorage.setItem('fromURLRouteState', JSON.stringify(fromURLRouteStateArray));
        }
    }
}
