import { Injectable } from '@angular/core';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfImgRefType } from 'src/app/shared/shared-models';
import { ImageData } from '../../models/image.model';
import { ImageDataService } from './Image.data.services';

@Injectable({
  providedIn: 'root'
})
export class GolfImageService {

  isEdit: boolean;
  url: any;
  editImageId: any;
  sequenceNo: any;

  ImageUploaded: boolean = false;
  base64textString: any;
  selectedFile: File;
  thumbnailImg: any;
  oldMargin: any;

  constructor(public localization: GolfLocalization,
    private imgService: ImageDataService) {
  }


  async updateItemImage(playerId: string, imageID, imgRefId: string, isImageRemoved, base64textString, thumbnailImg) {
    if (base64textString || isImageRemoved) {
      const base64result = isImageRemoved ? ['', ''] : base64textString.split(',');
      const base64Thumbnail = isImageRemoved ? ['', ''] : thumbnailImg.split(',');
      const imageDataObj: ImageData = {
        referenceId: 0,
        referenceType: GolfImgRefType.guest,
        data: base64result[1],
        id: imageID ? imageID : 0,
        thumbnailData: base64Thumbnail[1],
        contentType: base64result[0],
        sequenceNo: this.sequenceNo,
        imageReferenceId: playerId
      };
      await this.imgService.updateImage([imageDataObj]);
    }
  }

  async saveImage(playerId: string, base64textString, thumbnailImg): Promise<string> {
    if (base64textString) {
      const base64result = base64textString.split(',');
      const base64Thumbnail = thumbnailImg.split(',');
      const imageDataObj: ImageData = {
        referenceId: 0,
        referenceType: GolfImgRefType.guest,
        data: base64result[1],
        id: 0,
        thumbnailData: base64Thumbnail[1],
        contentType: base64result[0],
        sequenceNo: 0,
        imageReferenceId: playerId
      };
      return await this.imgService.saveImage([imageDataObj]);
    }
  }
  async getImageForPlayer(imgRefId: string, isthumbnailonly: boolean): Promise<ImageData> {
    return await this.imgService.GetImagesByReferenceId(imgRefId, isthumbnailonly);

  }
  async getImagesForPlayers(imgRefIds: string[], isthumbnailonly: boolean): Promise<ImageData[]> {
    return imgRefIds && imgRefIds.length > 0 ? await this.imgService.GetAllImagesByReference(imgRefIds, isthumbnailonly) : [];
  }

  //Get List of Profile Images
  async getProfileImages(profileList, imageReferenceId) {
    let playerList = profileList.map(p => p[imageReferenceId]);
    playerList = playerList.filter(x => x && x != '00000000-0000-0000-0000-000000000000')
    let imageList = playerList.length > 0 ? await this.getImagesForPlayers(playerList, true) : [];
    this.mapProfileImages(profileList, imageList, imageReferenceId);

  }

  // Map Profile Images to the respective Profiles
  mapProfileImages(profileList, imageList, imageReferenceId) {
    if (profileList && profileList.length > 0) {
      profileList.forEach(element => {
        element.profileImage = imageList ? imageList.find((image) => image.imageReferenceId.toLowerCase() == element[imageReferenceId].toLowerCase()) : '';
      });
    }
  }

  async updateItemImageCommon(referenceType: GolfImgRefType, referenceId: string, imageID, imgRefId: string, isImageRemoved, base64textString, thumbnailImg) {
    if (base64textString || isImageRemoved) {
      const base64result = isImageRemoved ? ['', ''] : base64textString.split(',');
      const base64Thumbnail = isImageRemoved ? ['', ''] : thumbnailImg.split(',');
      const imageDataObj: ImageData = {
        referenceId: 0,
        referenceType: referenceType,
        data: base64result[1],
        id: imageID ? imageID : 0,
        thumbnailData: base64Thumbnail[1],
        contentType: base64result[0],
        sequenceNo: this.sequenceNo,
        imageReferenceId: referenceId
      };
      await this.imgService.updateImage([imageDataObj]);
    }
  }

  async saveImageCommon(type: GolfImgRefType, referenceId: string, base64textString, thumbnailImg): Promise<string> {
    if (base64textString) {
      const base64result = base64textString.split(',');
      const base64Thumbnail = thumbnailImg.split(',');
      const imageDataObj: ImageData = {
        referenceId: 0,
        referenceType: type,
        data: base64result[1],
        id: 0,
        thumbnailData: base64Thumbnail[1],
        contentType: base64result[0],
        sequenceNo: 0,
        imageReferenceId: referenceId
      };
      return await this.imgService.saveImage([imageDataObj]);
    }
  }

}