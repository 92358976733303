import { Component, OnInit, EventEmitter, Output, Inject, ViewEncapsulation, ViewChild, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { CopytomanyPlayerBusiness } from './copytomany-player-modal.business';
import { CopytomanyPlayerService } from './copytomany-player-modal.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DatePickerInputs, Captions, PlayerDetails, CopyBulkTeeTimeConfig, RateSetupData } from './copytomany-player-modal.model';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject, Observable } from 'rxjs';
import { StepperService } from 'src/app/shared/components/stepper/stepper.service';
import { TeeSheetTableID, TeeSheetCustomData, TeeSheetSkeletonData, TeeSheetGridContent, TeeSheetPlayerDetails, TeeSheetModications, TeeSheetDragDropEvt, DragOrigin, TeeTimeFormat, ScheduledPlayer, MoveRateTypeChangeSelection } from 'src/app/shared/models/teesheet.form.models';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { AlertType, ButtonType, AlertAction, DialogCloseObj, SqueezeRefType } from 'src/app/shared/shared-models';
import { Course } from 'src/app/settings/golf-setup/code-setup/course/create-course-modal/create-course-model'
import { CourseDataService } from 'src/app/shared/data-services/golfschedule/course.data.service';
import { TeeSheetCopyToMany } from 'src/app/tee-time/shared/tee-sheet/tee-sheet.copyToMany';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { TeeTimesActionService } from 'src/app/shared/data-services/golfschedule/teeTimesAction.data.service';
import { PlayerTeeTimeSlot } from '../move-player-modal/move-player-modal.model';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { TeeGridService } from 'src/app/shared/components/tee-grid/tee-grid.service';
import { TeeTimesActionBusiness } from 'src/app/tee-time/shared/tee-action.business';
import { CopyMoveResult, PlayersCaddyInfo, TeeTimeAction, ValidationResult } from 'src/app/shared/models/teesheet.api.models';
import { NotifierBar } from 'src/app/shared/components/note/note.model';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { ButtonAction } from 'src/app/shared/global.constant';
import { GolfImageService } from 'src/app/shared/data-services/Image/golf.Image.service';
import { AllocationCode, Bulkstatus, status } from 'src/app/tee-time/tee-sheet/bulk-tee-time/bulk-tee-time.model';
import { TeeTimeDataService } from 'src/app/shared/data-services/golfschedule/TeeTime.data.service';
import { TeeTimesBulkGroupBusiness } from 'src/app/tee-time/shared/tee-bulkGroup.business';
import { TeeSheetBulk } from 'src/app/tee-time/shared/tee-sheet/tee-sheet.bulk';
import { DefaultsSettingConfig, TeeTimeConfig, PlayerTypes as PlayerType, RateType } from 'src/app/tee-time-actions/teetime/player-details/player-info.model';
import { AllocationBlockWithPlayerType } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import { PlayerTypeRateType } from 'src/app/tee-time/tournaments/tournaments-modal/tournament-details/tournament-details.model';
import { PlayerTypeService } from 'src/app/shared/data-services/golfschedule/playertype.data.service';
import { CodeLinkingDataService } from 'src/app/shared/data-services/golfschedule/codelinking.data.service';
import { SendNotificationMailSmsComponent } from 'src/app/shared/components/send-notification-mail-sms/send-notification-mail-sms.component';
import { NotificationConfigurationService } from 'src/app/common/templates/notification-configuration/notification-configuration.service';
import { EventNotificationGroup, EventNotification } from 'src/app/common/templates/notification-configuration/notification-configuration.model';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import { CourseCommentAPI } from 'src/app/settings/golf-setup/course-comment/course-comment-modal/course-comment-model';
import { CourseCommentDataService } from 'src/app/shared/data-services/golfschedule/courseComment.data.service';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { allocationBlockPlayerTypePermission } from 'src/app/settings/user-setup/booking-permission/booking-permission.model';
import { OverrideDetails, OverrideType } from '../teetime/tee-time.model';
import { UserAccessModel } from 'src/app/common/dataservices/authentication/useraccess-model.model';
import { TeeSheetUtilities } from 'src/app/shared/utilities/teesheet.utilities';
import { LinkMultipackBusiness } from 'src/app/tee-time-actions/link-multipack/link-multipack-business';
import { guestType } from 'src/app/lessons/lessons-modal/player-info/player-info.model';
import { RetailBussinessService } from 'src/app/shared/retail.bussiness';
import { CartUtilities } from 'src/app/common/components/menu/vcart/cart.utilities';
import { TeeSheetSharedBusiness } from 'src/app/tee-time/shared/teesheet.shared.business';

@Component({
  selector: 'app-copytomany-player-modal',
  templateUrl: './copytomany-player-modal.component.html',
  styleUrls: ['./copytomany-player-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CodeLinkingDataService, CopytomanyPlayerBusiness, CopytomanyPlayerService, CourseDataService, TeeSheetCopyToMany, TeeTimeDataService, TeeSheetBulk, TeeTimesBulkGroupBusiness, TeeTimesActionService, PlayerTypeService, NotificationConfigurationService, CourseCommentDataService]
})
export class CopytomanyPlayerModalComponent implements OnInit {
  @Output() notifyParent = new EventEmitter();

  captions: Captions;
  errorCaptions: any;
  copytomanyPlayersForm: UntypedFormGroup;
  courses: Promise<Course[]>;
  dateInputs: DatePickerInputs;
  date: Date;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  minDate: Date;
  playerDetails: PlayerDetails[];
  playerList: TeeSheetPlayerDetails[];
  teeSheetID: TeeSheetTableID;
  customTableData: TeeSheetCustomData;
  skeletonData: Observable<TeeSheetSkeletonData[]>;
  playerData: Observable<TeeSheetGridContent[]>;
  draggedPlayersList: any;
  courseId: number;
  PlayerTeeTimeSlot: PlayerTeeTimeSlot[];
  draggedPlayersCaddyInfoTemp: PlayerTeeTimeSlot[]=[];
  existingDate: any;
  existingCourse: any;
  lblcaptions: any;
  playerCollectionList: any[] = [];
  notificationbar: NotifierBar;
  notificationFlag: boolean = false;
  lastUpdatedSlots: TeeSheetSkeletonData[];
  courseTooltip: string;
  bFailure: boolean = false;
  unHandledErrorCode: -2;
  teeTimeFormat: TeeTimeFormat = TeeTimeFormat.None;
  isBulkSectionDisabled: boolean = true;
  bulkStatus: Bulkstatus;
  squeezeSlots: TeeSheetSkeletonData[];
  modifiedSlots: TeeSheetSkeletonData[] = [];
  dragDropObj: any;
  playersPerGroup: number = 4;
  bookingId: string;
  scheduledPlayers: ScheduledPlayer[];
  playerInfo: TeeSheetPlayerDetails[];
  bulkTee: TeeTimeFormat = TeeTimeFormat.BulkTee;
  allocationCodes: AllocationCode[];
  playerTypes: PlayerType[];
  rateTypes: RateType[];
  allRateTypes: RateType[];
  rateSetup: RateSetupData[];
  defaultSettings: DefaultsSettingConfig;
  teeTimeSettings: TeeTimeConfig;
  allocationCodesWithPlayerTypes: AllocationBlockWithPlayerType[];
  playerTypesWithRateTypes: PlayerTypeRateType[];
  selectedPlayerType: PlayerType;
  defaultHole: string = '1';
  selectedAllocationcode: AllocationCode;
  selectedRateType: RateType;
  existingRateType: RateType;
  settingsCaptions: any;
  rateTypesClone: RateType[];
  reloadStore: TeeSheetPlayerDetails[];
  popUpComponentDetails: { componentName: any; popUpDetails: { isStepper: boolean; eventName: string; bindData: any; }; };
  eventNotificationGroup: EventNotificationGroup[] = [];
  action: TeeTimeAction;
  mixedPlayerSlot: boolean = false;
  bulkPlayerUserAccess: UserAccessModel.BreakPointResult;
  normalPlayerUserAccess: UserAccessModel.BreakPointResult;
  infoStripAvailable: boolean = false;
  @ViewChild('infoElement') infoEle: any;
  courseComments: Promise<CourseCommentAPI[]>;
  selectedCourseComment: string;
  pageLoaded = false;
  private initialTeeTimes: TeeSheetSkeletonData[] = [];
  notifierBar: NotifierBar;
  userBreakpointAccess: UserAccessModel.BreakPointResult;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  allocationCodePermissions: allocationBlockPlayerTypePermission[] = [];
  isAllocationBlockEnabled: boolean;
  allocationBlockPlayerTypes: AllocationBlockWithPlayerType[] = [];
  quickloginUserId: number;
  overridePlayerTypeDaysoutUserAccess: UserAccessModel.BreakPointResult;
  overrideRateTypeDaysoutUserAccess: UserAccessModel.BreakPointResult;
  playerTypesLst: PlayerType[] = [];
  playersCaddyInfo: PlayersCaddyInfo[] = [];
  bulkDraggedPlayersCaddyInfoTemp : PlayerTeeTimeSlot[]=[];
  playerResponse: any;
  multiPackResponse: any;
  activeMultipacks: any;
  isMultipackAccess: boolean = false;
  floatLabel: string;
  currentValidationResult: ValidationResult<CopyMoveResult[]>;


  constructor(private fb: UntypedFormBuilder
    , @Inject(MAT_DIALOG_DATA) public dialogData: any
    , private _CopytomanyPlayerBusiness: CopytomanyPlayerBusiness
    , private _StepperService: StepperService
    , private _propertyInformation: GolfPropertyInformation
    , private _localization: GolfLocalization
    , private _utilities: GolfUtilities
    , private _retailService: RetailBussinessService
    , public _TeeGridService: TeeGridService
    , private _teeTimesActionBusiness: TeeTimesActionBusiness
    , private cdr: ChangeDetectorRef
    , private _PropertyInformation: GolfPropertyInformation
    , private _imageService: GolfImageService
    , private _NotificationConfigurationService: NotificationConfigurationService
    , public _dialog: MatDialog
    , private rendrer: Renderer2
    , public quickLoginUtils: QuickLoginUtilities
    , private propertyInformation: RetailPropertyInformation
    , public _teeSheetUtilities: TeeSheetUtilities
    , private _linkMultipackBusiness: LinkMultipackBusiness
    , private cartUtils: CartUtilities
    , private _teeSheetSharedBusiness: TeeSheetSharedBusiness) {
    this.floatLabel = this._localization.setFloatLabel;
  }

  async ngOnInit() {
    this.captions = this._CopytomanyPlayerBusiness.getCaptions();
    if (this.dialogData.info.playerDetail && this.dialogData.info.playerDetail.length > 0) {
      this.dialogData.info.playerDetail.forEach(element => {
        element.isDragDisabled = false;
      });
    }
    this.loadAllocationPermissions();
    this.getErrorCaptions();
    this.getSettingsCaptions();

    this.allRateTypes = await this._CopytomanyPlayerBusiness.getRateTypes();
    this.playerDetails = this._CopytomanyPlayerBusiness.getPlayersDetails(_.cloneDeep(this.dialogData.info));
    this.isMultipackAccess = await this._CopytomanyPlayerBusiness.validateBreakPointAccess(UserAccessBreakPoints.LINKUNLINKMULTIPACK, false);
    let bulktee = this.dialogData.info.playerDetail.filter(x => !x.isBlocked && x.teeTimeFormat === TeeTimeFormat.BulkTee);
    let normalTee = this.dialogData.info.playerDetail.filter(x => !x.isBlocked && x.teeTimeFormat !== TeeTimeFormat.BulkTee);
    const distinctThings = _.uniq(_.map(this.dialogData.info.playerDetail.filter(x => !x.isBlocked), 'confirmationNumber'));
    this.bulkPlayerUserAccess = this.dialogData.bulkPlayerUserAccess;
    this.normalPlayerUserAccess = this.dialogData.normalPlayerUserAccess;

    if (bulktee.length > 0 && normalTee.length > 0 || (bulktee.length > 0 && distinctThings && distinctThings.length > 1)) {
      this.mixedPlayerSlot = true;
      this.teeTimeFormat = TeeTimeFormat.None;
    }
    else if (bulktee.length > 0 && normalTee.length == 0) {
      this.teeTimeFormat = TeeTimeFormat.BulkTee;
      this.playersPerGroup = bulktee[0].playersPerGroup;
    }
    this.createCopytomanyPlayersForm();
    this.minDate = this._PropertyInformation.CurrentDTTM;
    this.existingDate = this.date = this._localization.getDate(this.dialogData.info.time);
    await this.getPlayers();
    await this.getMasterData();
    this.existingCourse = this.courseId = this.dialogData.info.course.id;
    this.copytomanyPlayersForm.controls["dateControl"].setValue(this.date);
    this.lblcaptions = this._localization.captions.teetime;
    this.courses = this._CopytomanyPlayerBusiness.getCourses();
    this.teeSheetID = this._CopytomanyPlayerBusiness.getTeeSheeetID();

    this.pageSubscriptions();
    this.onPageLoad();

    this.customTableData = this._CopytomanyPlayerBusiness.getTeeSheetCustomTableData(this.dialogData.info);
    this.defaultSettings = await this._CopytomanyPlayerBusiness.getDefaultSettings();
    if (this.teeTimeFormat === TeeTimeFormat.BulkTee) {
      this.customTableData.isBulkMoveEnabled = true;
      this.customTableData = { ... this.customTableData };
      await this.getDataForBulkCopy();
    }

    this.notificationbar = {
      class: '',
      value: this.lblcaptions.playersCopyUpdatednotifications,
      color: "#fff16e",
      isRemovable: true
    }

    setInterval(() => {
      this.notificationFlag = false;
    }, 7000);
    // Call this method to get Profile Images - Parameters(list of player that needs profile images, reference id from API response)
    this._imageService.getProfileImages(this.playerList, "imageReferenceId");
    this.action = TeeTimeAction.copyToMany;
    this.autoSelectPlayers();
    if (this.teeTimeFormat === TeeTimeFormat.BulkTee) {
      this.copytomanyPlayersForm.get('allocationCode').valueChanges.subscribe(allocationCode => {
        if (this.userBreakpointAccess && !this.userBreakpointAccess.isAllow && allocationCode && allocationCode !== this.copytomanyPlayersForm.value.allocationCode) {
          this.copytomanyPlayersForm.get('allocationCode').patchValue(this.copytomanyPlayersForm.value.allocationCode);
          let bpMessage = this._localization.captions.breakpoint[UserAccessBreakPoints.CHANGEALLOCATIONBLOCK];
          this._CopytomanyPlayerBusiness.showBreakPointPopup(bpMessage);
          return;
        }
      });
    }
    this.rateSetup = await this._CopytomanyPlayerBusiness.getTeeFeesForCourseAndDate(this.courseId, this._localization.getDate(this.date));
  }

  async getMultiPackDetails(player: any) {
    const session: any[] = [];
    player.forEach(element => {
      session.push(
        {
          guestId: element.playerLinkId,
          guestType: element.playerCategoryId == 3 ? guestType.member : guestType.guest
        }
      );
    });
    return await this._linkMultipackBusiness.GetMultiPackDetails(session);
  }

  async loadAllocationPermissions() {
    this.isAllocationBlockEnabled = this._utilities.IsAllocationCodePermissionEnabled();
    if (this.isAllocationBlockEnabled) {
      this.allocationBlockPlayerTypes = await this._CopytomanyPlayerBusiness.GetAllocationsBlockWithPlayerTypes();
      this.allocationCodePermissions = this.dialogData.allocationCodePermissions;
    }
  }

  async getAllocationCodeBreakpoint() {
    this.userBreakpointAccess = await this._CopytomanyPlayerBusiness.ValidateUserBreakPoints();
  }

  preSelectPlayersInSlot() {
    let slotPlayers = this.dialogData.info.playerDetail.filter(x => !x.isBlocked).map(x => { return x.playerId; });
    this._TeeGridService.selectedPlayers = [];

    this.playerList.forEach(element => {
      element['playerClicked'] = false;
      if (slotPlayers.includes(element.playerId)) {
        element['playerClicked'] = true;
        this._TeeGridService.selectedPlayers.push(element);
      }
    });
  }
  async getDataForBulkCopy() {
    let matchedPlayerType = null;
    if (this.dialogData.info.allocation != null) {
      this.selectedAllocationcode = this._CopytomanyPlayerBusiness.mapAllocationBlockData(this.dialogData.info.allocation);
    }
    let unblockedPlayer = this.dialogData.info.playerDetail.filter(x => !x.isBlocked);
    if (unblockedPlayer) {
      this.selectedRateType = this._CopytomanyPlayerBusiness.mapPlayerRateTypetoRateType(unblockedPlayer[0].rateType);
      matchedPlayerType = unblockedPlayer[0].playerTypeId;
    }
    this.allocationCodes = await this._CopytomanyPlayerBusiness.getAllocationCode();
    if (this.dialogData.info.allocation != null) {
      this.copytomanyPlayersForm.controls['allocationCode'].setValue(this.selectedAllocationcode);
      if(this.selectedAllocationcode && this.selectedAllocationcode.id !=0)
        this.isBulkSectionDisabled = false;
      this.allocationCodeChange({ value: { id: this.selectedAllocationcode.id } });
    }
    await this.getAllocationCodeBreakpoint();
    this.playerTypes = await this._CopytomanyPlayerBusiness.getPlayerTypes();
    this.rateTypes = await this._CopytomanyPlayerBusiness.getRateTypes();
    this.rateTypesClone = _.cloneDeep(this.rateTypes);
    this.playerTypesWithRateTypes = await this._CopytomanyPlayerBusiness.getAllPlayerTypeWithRateType();
    this.selectedPlayerType = matchedPlayerType && matchedPlayerType > 0 ? this.playerTypes.find(x => x.id == matchedPlayerType) : null;
    this.copytomanyPlayersForm.controls["courseControl"].setValue(this.courseId);
    if (this.selectedPlayerType) {
      this.copytomanyPlayersForm.controls['playerType'].setValue(this.selectedPlayerType);
      this.PlayerTypeChange({ value: this.selectedPlayerType });
    }
    this.copytomanyPlayersForm.controls['rateType'].setValue(this.selectedRateType);
    this.allocationCodesWithPlayerTypes = await this._CopytomanyPlayerBusiness.getAllocationsBlockWithPlayerTypes();
    this.rateSetup = await this._CopytomanyPlayerBusiness.getTeeFeesForCourseAndDate(this.courseId, this._localization.getDate(this.date));
    // this.teeTimeSettings = await this._CopytomanyPlayerBusiness.getTeeTimeSettings();
    // this.preSelectPlayersInSlot();
    // this.setDefaults(this.teeTimeSettings, this.defaultSettings);
    // this.defaultRatePlayerPatch(this.copytomanyPlayersForm.get('playerType').value) 

  }

  private setDefaults(defaultTeetime: TeeTimeConfig, defaultSetting: DefaultsSettingConfig) {
    const playerTypeControl = this.copytomanyPlayersForm.get('playerType');
    const rateTypeControl = this.copytomanyPlayersForm.get('rateType');
    if (defaultTeetime.requirePlayerType) {
      playerTypeControl.setValidators(Validators.required);
      playerTypeControl.updateValueAndValidity();
    }
    if (defaultTeetime.requireRateType) {
      rateTypeControl.setValidators(Validators.required);
      rateTypeControl.updateValueAndValidity();
    }
    if (defaultSetting.blockingCode) {
      this.allocationValue(defaultSetting.blockingCode);
      this.playerTypes = this.allocationCodesWithPlayerTypes.find(x => x.id == defaultSetting.blockingCode).playerTypes;
      this.playerTypes = this.playerTypes.filter(x => x.isActive);
    }
  }

  allocationValue(code: number) {
    let allocationblockpatchs = this.allocationCodes.find(x => x.id === code);
    this.copytomanyPlayersForm.get('allocationCode').setValue(allocationblockpatchs ? allocationblockpatchs : '');
  }

  defaultRatePlayerPatch(arg) {
    this.setPlayerValues(arg);
  }


  setPlayerValues(arg) {
    let defaultPlayerType;
    let defaultRateType;
    switch (arg) {
      case (1):
        {
          // get from service
          defaultPlayerType = this.defaultSettings.nonMemberPlayerType ? this.defaultSettings.nonMemberPlayerType : '';
          defaultRateType = this.defaultSettings.nonMemberRateType ? this.defaultSettings.nonMemberRateType : '';
        }
        break;
      case (2): {
        // get from service
        defaultPlayerType = this.defaultSettings.resortPlayerType ? this.defaultSettings.resortPlayerType : '';
        defaultRateType = this.defaultSettings.resortRateType ? this.defaultSettings.resortRateType : '';
      }
        break;
      case (3): {
        // get from service
        defaultPlayerType = this.defaultSettings.memberPlayerType ? this.defaultSettings.memberPlayerType : '';
        defaultRateType = this.defaultSettings.memberRateType ? this.defaultSettings.memberRateType : '';
      }
        break;
      case (4): {
        // get from service
        defaultPlayerType = this.defaultSettings.nonMemberPlayerType ? this.defaultSettings.nonMemberPlayerType : '';
        defaultRateType = this.defaultSettings.nonMemberRateType ? this.defaultSettings.nonMemberRateType : '';
      }
    }
    this.patchDefaults(defaultPlayerType, defaultRateType);
  }



  patchDefaults(playerID, rateId) {
    if (playerID) {
      let playerType = this.playerTypes.find(x => x.id == playerID);
      if (playerType) {
        this.rateTypes = this.playerTypesWithRateTypes.find(x => x.id == playerID).rateTypes.filter(x => x.isActive);
      }
      else {
        this.rateTypes = this.rateTypesClone;
      }
      this.copytomanyPlayersForm.patchValue({
        playerType: playerType ? playerType : ''
      });
    }
    if (rateId) {
      let rateType = this.rateTypes.find(x => x.id === rateId);
      this.copytomanyPlayersForm.patchValue({
        rateType: rateType ? rateType : ''
      });
    }
  }
  getErrorCaptions() {
    this.errorCaptions = this._CopytomanyPlayerBusiness.GetErrorCaptions();
  }
  getSettingsCaptions() {
    this.settingsCaptions = this._CopytomanyPlayerBusiness.GetSettingsCaptions();
  }

  async GetBulkPlayers(bookingId?: string) {
    this.bookingId = bookingId ? bookingId : this.dialogData.info.playerDetail.filter(x => !x.isBlocked && x.teeTimeFormat == TeeTimeFormat.BulkTee)[0].bookingId;
    await this.getBulkPlayers(this.bookingId);
    let bulkplayers = this._CopytomanyPlayerBusiness.mapTeeSheetplayerDetails(this.scheduledPlayers, this.allRateTypes);
    this.playerInfo = this.playerList = this.reloadStore = this._CopytomanyPlayerBusiness.getPlayerList(bulkplayers);
  }

  async getPlayers(isSaveClicked?) {
    let players: TeeSheetPlayerDetails[];
    if (this.teeTimeFormat === TeeTimeFormat.BulkTee) {
      await this.GetBulkPlayers();
    }
    else {
      if (isSaveClicked && this.modifiedSlots && this.modifiedSlots.length > 0) {
        players = this.modifiedSlots.find(x => x.time === this.dialogData.info.time && x.course.id === this.dialogData.info.course.id
          && x.hole === this.dialogData.info.hole)[0].playerDetail;
      }
      else {
        let fullPlayers = _.cloneDeep(this.dialogData.info.playerDetail);
        players = fullPlayers.filter(x => !x.isBlocked);
      }
      players.forEach(x=>x.time = this.dialogData.info.time);
      players.forEach(x=>x.courseId = this.dialogData.info.course.id);
      this.playerInfo = this.playerList = this.reloadStore = this._CopytomanyPlayerBusiness.getPlayerList(_.cloneDeep(players));
      this.playerList.forEach(players => {
        players['playerClicked'] = false;
      });
    }
    this._StepperService.setBackEnable(false);
    this.playerList = _.cloneDeep(this.playerList);
    this.mixedPlayerSlot = _.cloneDeep(this.mixedPlayerSlot);
    await this.setPlayerdataforMultipackValidation(_.cloneDeep(this.playerList));
    // Call this method to get Profile Images - Parameters(list of player that needs profile images, reference id from API response)
    this._imageService.getProfileImages(this.playerList, "imageReferenceId");
  }

  async getBulkPlayers(bookingId: string) {
    this.scheduledPlayers = await this._CopytomanyPlayerBusiness.getBulkPlayers(bookingId);
  }
  async setPlayerdataforMultipackValidation(playerList : TeeSheetPlayerDetails[] )
  {
    this.playerResponse = await this._linkMultipackBusiness.GetPlayersByIds(playerList.map(p=>p.playerId));
    this.multiPackResponse = await this.getMultiPackDetails(this.playerResponse);
    this.multiPackResponse.map(m => {
                              m.usingCount = 0;
                          });
  }
  onSqueezeClose(data) {
    if (data.fromtype === SqueezeRefType.squeeze) {
      let selectedMins = data.obj.value.squeezeminutes;
      this.squeezeSlots = this._CopytomanyPlayerBusiness.getSqueezeSlots(this.modifiedSlots, this.bulkStatus, selectedMins);
      if (this.squeezeSlots && this.squeezeSlots.length > 0) {
        const FinalSlots = this._CopytomanyPlayerBusiness.addNewSlots(this.squeezeSlots, this.modifiedSlots);
        if (FinalSlots && FinalSlots.length > 0) {
          this._CopytomanyPlayerBusiness.updateTeeSlots(FinalSlots);
          this.onDragDrop(this.dragDropObj);
        }
      } else {
        //to do error case
        this.autoSelectPlayers();
      }
    } else {
      this.autoSelectPlayers();
    }
  }

  ngAfterViewInit() {
    // By Default on Page Load Set NotifyParent to false to disable Save Button
    this.notifyParent.emit(false);
    this.updateUserAccessInfo();
  }

  createCopytomanyPlayersForm() {
    if (this.teeTimeFormat == this.bulkTee) {
      this.copytomanyPlayersForm = this.fb.group({
        dateControl: '',
        courseControl: '',
        allocationCode: ['', Validators.required],
        playerType: '',
        rateType: '',
        hole: this.defaultHole
      });
    }
    else {
      this.copytomanyPlayersForm = this.fb.group({
        dateControl: '',
        courseControl: ''
      });
    }
    this.dateInputs = {
      className: 'copytoMany-form-control',
      form: this.copytomanyPlayersForm,
      formControlName: 'dateControl',
      minDate: this.minDate,
      placeHolder: this.captions.date,
      value: this._PropertyInformation.CurrentDTTM,
      automationId: "copyTomany"
    };
    this.pageLoaded = true;
  }

  addPlayerstoList() {
    let fullPlayers = _.cloneDeep(this.dialogData.info.playerDetail);
    this.playerList = fullPlayers.filter(x => !x.isBlocked);
    this.playerList = this.playerList.map(players => {
      players['isRemovable'] = true;
      return players;
    });
  }

  showAlert(date, course) {
    let message = this.lblcaptions.PleaseSavethechanges;
    this._utilities.showAlert(message, AlertType.Warning, ButtonType.Ok, (res) => {

      this.date = this.existingDate;
      this.courseId = this.existingCourse;
      this.copytomanyPlayersForm.controls["dateControl"].setValue(this.date);
      this.copytomanyPlayersForm.controls["courseControl"].setValue(this.courseId);

    });
  }

  async dateChanged(e) {
    let bModified: boolean = this.isTeeGridModified();
    if (this._teeTimesActionBusiness.validateUserDaysOut(e[0].value.dateControl)) {
      if (e[0].value.dateControl != this._localization.getDate(this.date) && bModified) {
        this.showAlertToSaveData();
      }
      else {
        this.date = this.existingDate = e[0].value.dateControl;
        this.getData(this.courseId, this.date);
      }
    }
    else {
      this._utilities.showError(this._localization.getError(30414));
      this.date = this.existingDate;
      this.courseId = this.existingCourse;
      this.copytomanyPlayersForm.controls["dateControl"].setValue(this.date);
      this.copytomanyPlayersForm.controls["courseControl"].setValue(this.courseId);
      this.autoSelectPlayers();
    }
    this.rateSetup = await this._CopytomanyPlayerBusiness.getTeeFeesForCourseAndDate(this.courseId, this._localization.getDate(this.date));
  }

  courseChanged(e) {
    let bModified: boolean = this.isTeeGridModified();
    if (e != this.courseId && bModified) {
      this.showAlertToSaveData();
    }
    else {
      this.courseId = this.existingCourse = e;
      this.getData(this.courseId, this.date);
    }
  }

  showAlertToSaveData() {
    this._utilities.showAlert(this._localization.captions.common.SaveChangesFirst, AlertType.Info, ButtonType.Ok, (res) => {
      if (res === AlertAction.CONTINUE) {
        this.date = this.existingDate;
        this.courseId = this.existingCourse;
        this.copytomanyPlayersForm.controls["dateControl"].setValue(this.date);
        this.copytomanyPlayersForm.controls["courseControl"].setValue(this.courseId);
      }
    });
  }

  isTeeGridModified(): boolean {
    if (this.initialTeeTimes.length > 0) {
      let modifiedSlots: PlayerTeeTimeSlot[] = this._CopytomanyPlayerBusiness.generateModifiedPlayersTeeSlots(this.initialTeeTimes, this.modifiedSlots);
      if (modifiedSlots.length > 0) {
        return true;
      }
      else {
        return false;
      }
    }
    return false;

  }

  async SetNormalView() {
    this.notifyParent.emit(false);
    this._StepperService.setBackEnable(false);
    this.playerInfo = this.playerList = [];    
    //this.customTableData.isBulkMoveEnabled = false;//Bug 91541
    this.customTableData = { ... this.customTableData };
    this.createCopytomanyPlayersForm();
    this.copytomanyPlayersForm.controls["courseControl"].setValue(this.courseId);
    await this.getPlayers();
    this.autoSelectPlayers();
  }

  async saveTeeTime(x: any, modifiedSlots: PlayerTeeTimeSlot[]) {

    const linkData: any[] = [];
    let isMultipackNeeded = false;
    const partialMultipack = modifiedSlots.filter(x => !x.multiPackTransactionDetailId || x.multiPackTransactionDetailId == 0);
    if (this.isMultipackAccess) {
      if (partialMultipack && partialMultipack.length == 0) {
        var res = this._utilities.showAlert(this.lblcaptions.linkMultipackOnCopy, AlertType.Warning, ButtonType.YesNo, (res) => {
          if (res === AlertAction.YES) {
            isMultipackNeeded = true;
          }
        });
        await res.afterClosed().toPromise();
      }
      else if (partialMultipack && partialMultipack.length > 0 && partialMultipack.length < modifiedSlots.length) {
        var res = this._utilities.showAlert(this.lblcaptions.linkPartialMultipackOnCopy, AlertType.Warning, ButtonType.YesNo, (res) => {
          isMultipackNeeded = res === AlertAction.YES;
        });
        await res.afterClosed().toPromise();
      }
    }
    else if (partialMultipack && partialMultipack.length < modifiedSlots.length) {
      var res = this._utilities.showAlert(this.lblcaptions.breakpointWarningOnMultipackTeetimeCopy, AlertType.AccessDenied, ButtonType.Ok);
      await res.afterClosed().toPromise();
    }
    if (isMultipackNeeded) {
      this.multiPackResponse.filter(m => !m.clientMultiPacks[0].isUnlimited && m.usingCount > 0).map(m => {
        linkData.push({
          transactionDetailId: m.transactionDetailId,
          count: m.usingCount
        })
      });
      if (linkData && linkData.length > 0) {
        this.linkMultiPack(linkData);
      }
    }
    else {
      modifiedSlots.map(val => {
        val.multiPackTransactionDetailId = 0;
        val.multiPackGreenFeeValue = 0;
        val.multiPackCartFeeValue = 0;
      });
    }
    this.saveCopyTeeTimes(modifiedSlots).then(y => {
      if (y) {

        // Disable Save Button after save success
        this.notifyParent.emit(false);
        this._StepperService.setBackEnable(false);
        // Refresh Grid
        if (this.mixedPlayerSlot) {
          this.teeTimeFormat = TeeTimeFormat.None;
          this.customTableData.isBulkMoveEnabled = false;
          this.customTableData = { ... this.customTableData };
          this.getPlayers();
          this.createCopytomanyPlayersForm();
          this.copytomanyPlayersForm.controls["courseControl"].setValue(this.courseId);
        }
        else {
          this.playerList = this.reloadStore;
        }
        this.getData(this.courseId, this.date);
        if (this.teeTimeFormat === TeeTimeFormat.BulkTee) {
          this.callNotificationPopup(modifiedSlots.map(f => f.playerId));
        }
        this.notificationFlag = true;
      }
      else {
        this.bFailure = true;
        if (linkData && linkData.length > 0) {
          this.unLinkMultiPack(linkData);
        }
      }
    }).catch(error => {
      this.bFailure = true;
      if (!(error.name != undefined && error.name === "HttpErrorResponse")) {
        const error: string = this._localization.getError(this.unHandledErrorCode);
        this._utilities.showError(error);
      }
    }).finally(() => {
      this.quickLoginUtils.resetQuickIdDetails();
      if (this.bFailure) {
        this.playerList = this.reloadStore;
        this.getData(this.courseId, this.date);
        this.notifyParent.emit(false);
      }
    });
  }

  GetOverrideDetails(userId: number): OverrideDetails[] {
    let overrideDetails: OverrideDetails[] = [];
    var playerTypeOverrideDetail: OverrideDetails = {
      id: 0,
      scheduledTeeTimePlayerId: 0,
      overrideType: OverrideType.playerType,
      userId: userId
    }
    overrideDetails.push(playerTypeOverrideDetail);
    return overrideDetails;
  }

  async getMasterData() {
    this.overridePlayerTypeDaysoutUserAccess = await this._teeTimesActionBusiness.validateBreakPointAccess(UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT, false);
    this.playerTypesLst = await this._teeTimesActionBusiness.getPlayerTypes();
    this.overrideRateTypeDaysoutUserAccess = await this._teeTimesActionBusiness.validateBreakPointAccess(UserAccessBreakPoints.OVERRIDERATETYPEDAYSOUT, false);
  }


  pageSubscriptions() {
    this.copytomanyPlayersForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe();
    this._StepperService.valueChange.pipe(takeUntil(this.destroyed$)).subscribe(async (x: DialogCloseObj) => {
      if (x.type == 'backClick') {
        this.SetNormalView();
        this.getData(this.courseId, this.date);
        if(this.copytomanyPlayersForm.get('allocationCode'))
          this.isBulkSectionDisabled = false;
      }
      if (x.type == ButtonAction.save) {
        this.notifyParent.emit(false); // Disable save once clicked
        let modifiedSlots: PlayerTeeTimeSlot[] = this._CopytomanyPlayerBusiness.generateModifiedPlayersTeeSlots(this.initialTeeTimes, this.lastUpdatedSlots);
        const teeTimeAction: TeeTimeAction = this.teeTimeFormat == TeeTimeFormat.BulkTee ? TeeTimeAction.bulkCopy : TeeTimeAction.copy;
        let selectedplayerTypeId = this.selectedPlayerType ? this.selectedPlayerType.id : 0;
        modifiedSlots.forEach(x => {
          if (this.playersCaddyInfo && this.playersCaddyInfo.length > 0) {
            let newCaddy = this.playersCaddyInfo.find(p => p.playerId == x.playerId && (this._utilities.getDate(p.scheduledDateTime).getTime() == this._utilities.getDate(x.scheduledDateTime).getTime()) && p.playerSlotPosition == x.playerSlotPosition);
            x.caddyId = newCaddy ? newCaddy.caddyId : x.caddyId;
            x.caddyTypeId = newCaddy ? newCaddy.caddyTypeId : x.caddyTypeId;
            x.isUpdateCaddy = newCaddy ? newCaddy.isUpdateCaddy : false;
          }
          x.playerTypeId = (teeTimeAction == TeeTimeAction.bulkCopy) ? selectedplayerTypeId : x.playerTypeId
        });

        let result = await this._teeTimesActionBusiness.checkOverridePlayerTypeRateTypeDaysOut(this.selectedPlayerType,this.selectedRateType,this.playerTypesLst, modifiedSlots, this.overridePlayerTypeDaysoutUserAccess,this.overrideRateTypeDaysoutUserAccess, this.date);
        if (!result) {
          this.playerList = this.reloadStore;
          this.getData(this.courseId, this.date);
          this.notifyParent.emit(false);
          return;
        }


        let quickIdConfig = this.propertyInformation.getQuickIdConfig;
        if (quickIdConfig && quickIdConfig.teeTimeCopy) {
          const quickLoginDialogRef = this.quickLoginUtils.QuickLogin();
          quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
            if (quickLoginDialogResult.isLoggedIn) {
              let isAllocationNotAllowed = false;
              const quickRoleId = this.quickLoginUtils.GetQuickLoginRoleId();
              const roleId = this._localization.GetUserInfo("roleId");
              if (quickRoleId != roleId) {
                let playerSchedules = this.getScheduledPlayers();
                let sourceSlot = this._teeTimesActionBusiness.getSourceTeeTimeInfo(playerSchedules, this.draggedPlayersList);

                const allocationCodePermissionsforQuickId = await this._CopytomanyPlayerBusiness.GetAllocationBlockPermissionByRole(quickRoleId);
                const isDragAllocationAllowed = isAllocationNotAllowed = this._teeTimesActionBusiness.CheckAlloctionBlockAndPlayerTypePermissionOnSave(sourceSlot,
                  allocationCodePermissionsforQuickId, this.allocationBlockPlayerTypes, this._localization.captions.common.CopyNotAllowed);
                if (!isDragAllocationAllowed) {
                  let destinationSlot = this._teeTimesActionBusiness.getDestinationTimeInfo(this.initialTeeTimes, this.squeezeSlots, playerSchedules, modifiedSlots);
                  isAllocationNotAllowed = this._teeTimesActionBusiness.CheckAlloctionBlockAndPlayerTypePermissionOnSave(destinationSlot, allocationCodePermissionsforQuickId,
                    this.allocationBlockPlayerTypes, this._localization.captions.common.CopyNotAllowed);
                }
              }
              if (!isAllocationNotAllowed) {
                this.saveTeeTime(x, modifiedSlots);
              }
              else {
                this._StepperService.enableSave = true;
              }
            } else {
              this._StepperService.enableSave = true;
            }
          });
        } else {
          this.saveTeeTime(x, modifiedSlots);
        }
      }
    });
  }

  async callNotificationPopup(playerids) {
    this.eventNotificationGroup = await this._NotificationConfigurationService.GetEventNotificationGroupByEventId(EventNotification.BulkBooking);
    if (this.eventNotificationGroup && this.eventNotificationGroup.length > 0) {
      this.calltriggeremailsmspopupfunction(playerids);
    }
  }
  private onPageLoad(): void {
    this.copytomanyPlayersForm.controls["courseControl"].setValue(this.courseId);
    this.getData(this.courseId, this.date);
  }

  async getData(course, date) {
    this.skeletonData = this._CopytomanyPlayerBusiness.getTeeSheetSkeletonData(course, date,await this.courses,this.allRateTypes);
    this.skeletonData.pipe(takeUntil(this.destroyed$)).subscribe(s => {
      this.modifiedSlots = _.cloneDeep(s);
      this.initialTeeTimes = this._CopytomanyPlayerBusiness.getInitialTeeTimes();
      if (this.teeTimeFormat === TeeTimeFormat.BulkTee && this.modifiedSlots && this.modifiedSlots.length > 0 && !this.selectedAllocationcode) {
        let allocationcode = this.modifiedSlots.filter(x => x.course.id == this.dialogData.info.course.id && x.time == this.dialogData.info.time)
        const selectedAllocation = allocationcode && allocationcode.length > 0 ? allocationcode[0].allocation : null;
        if (selectedAllocation != null) {
          this.selectedAllocationcode = this._CopytomanyPlayerBusiness.mapAllocationBlockData(selectedAllocation);
          this.copytomanyPlayersForm.controls['allocationCode'].setValue(this.selectedAllocationcode);
          if(this.selectedAllocationcode && this.selectedAllocationcode.id !=0)
            this.isBulkSectionDisabled = false;
          this.allocationCodeChange({ value: { id: this.selectedAllocationcode.id } });
        }
      }
      this.copytomanyPlayersForm.controls["dateControl"].setValue(this.date);
    });
    let courseList = await this.courses;
    let comment = await this._CopytomanyPlayerBusiness.getCourseComments(course, date);
    this.selectedCourseComment = comment ? comment.comment : '';
    this.notifierBar = this._CopytomanyPlayerBusiness.getCourseComment(this.selectedCourseComment);
    this.courseTooltip = courseList.find(x => x.id === course).name;
    this.resetPlayerList();
  }

  resetPlayerList() {
    this.autoSelectPlayers();
  }


  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
    this.quickLoginUtils.resetQuickIdDetails();
  }

  onRemovePlayer(playerDetails) {
    if (this.teeTimeFormat == this.bulkTee) {
      playerDetails.rowData.playerDetail = playerDetails.rowData.playerDetail.filter(x => !(x.playerId == playerDetails.playerData.playerId && x.playPos == playerDetails.playerData.playPos));
      this.draggedPlayersList = this.draggedPlayersList.filter(x => x.playerId !== playerDetails.playerData.playerId);
    }
    else {
      _.remove(playerDetails.rowData.playerDetail, playerDetails.playerData);
      _.remove(this.draggedPlayersList, playerDetails.playerData);
    }
    if (playerDetails.playerData.multiPackTransactionDetailId && playerDetails.playerData.multiPackTransactionDetailId != 0) {
      this.multiPackResponse.find(m => !m.clientMultiPacks[0].isUnlimited && m.transactionDetailId == playerDetails.playerData.multiPackTransactionDetailId).usingCount--;
    }
    playerDetails.playerData.playerClicked = false;
    if (this.teeTimeFormat == this.bulkTee) this.playerList.push(playerDetails.playerData);
    this.reOrderPlayers(playerDetails.rowData.playerDetail);
    this.teeSheetModifications = {
      initialState: playerDetails.initialState, currentState: playerDetails.currentState
    };
    this._CopytomanyPlayerBusiness.updateTeeSlots(playerDetails.currentState);
    this.lastUpdatedSlots = _.cloneDeep(playerDetails.currentState);
    let isModified = this.isTeeGridModified();
    this.removeCaddyInfo(playerDetails.playerData.playerId, playerDetails.playerData.scheduledDateTime, playerDetails.playerData.playerSlotPosition);
    this.autoSelectPlayers();
    this.notifyParent.emit(isModified);
  }

  onDropFailed(eve) {
    if (eve) {
      this.autoSelectPlayers();
    }
  }

  // Reorder Player Numbers Here
  reOrderPlayers(playersArray) {
    playersArray.forEach((element, index) => {
      element.playPos = index + 1;
    });
  }

  onmoreEvent(e) {
    console.log("on More Click", e);
  }

  async onDragDrop(dragDropObj: any) {
    if(this.cartUtils.isEmbed()){
      this.lastUpdatedSlots = dragDropObj.currentState;
      let modifiedSlots: PlayerTeeTimeSlot[] = this._CopytomanyPlayerBusiness.generateModifiedPlayersTeeSlots(this.initialTeeTimes, this.lastUpdatedSlots);
      let dataForVCartSlot = [{}];
      dataForVCartSlot.push(
        {
          course:{
            id: modifiedSlots[0]?.courseId
          },
          time: modifiedSlots[0]?.scheduledDateTime,
          originalHoleNumber: modifiedSlots[0]?.originalHoleNumber
        }
      );
      let slotPresentInSlot = this._teeSheetSharedBusiness.IsSlotPresentInCart(dataForVCartSlot);
      if(slotPresentInSlot){
        this.resetTeeData(dragDropObj);
        return;
      }
    }
    this.dragDropObj = dragDropObj;
    if (dragDropObj.dragOrigin === DragOrigin.playerlist) {
      dragDropObj.dragData = this.draggedPlayersList;
    }
    else
    {
      this.dragDropObj.dragData = [_.cloneDeep(this.dragDropObj.dragData)] ;
      if ((this.teeTimeFormat == TeeTimeFormat.None || this.teeTimeFormat == TeeTimeFormat.Individual) && this.dragDropObj.dragData[0].multiPackTransactionDetailId && this.dragDropObj.dragData[0].multiPackTransactionDetailId != 0) {
        this.multiPackResponse.find(m => !m.clientMultiPacks[0].isUnlimited && m.transactionDetailId == this.dragDropObj.dragData[0].multiPackTransactionDetailId).usingCount--;
      }
    }
    let draggedPlayers: PlayerTeeTimeSlot[];
    this.teeSheetModifications = {
      initialState: dragDropObj.initialState, currentState: dragDropObj.currentState
    };
    if (dragDropObj.dragOrigin === DragOrigin.playerlist && this.teeTimeFormat === TeeTimeFormat.BulkTee) {
      let isdropAllowed: boolean = this.isDropAllowed(dragDropObj.dragData, dragDropObj.dropPositionObject.playerDetail);
      const isDropValid: boolean = this._teeTimesActionBusiness.validateDrop(dragDropObj.dropPositionObject, TeeTimeAction.copy);
      if (!isdropAllowed || !isDropValid) {
        this.isBulkSectionDisabled = false;
        this.autoSelectPlayers();
        return;
      }
      const playerDetails = [];
      this.dragDropObj.dragData.forEach(x => {
        const playerDetail = this.scheduledPlayers.find(players => players.playerId == x.playerId);
        playerDetails.push(playerDetail);
      });
      const dragAllocationBlock = this.dialogData.info.allocation;
      if (dragAllocationBlock) {
        const dragValidation = this.checkAlloctionBlockAndPlayerTypePermission(dragAllocationBlock, playerDetails);
        if (dragValidation) {
          this.resetTeeData(dragDropObj);
          return;
        }
      }
      const dropAllocationBlock = this.dragDropObj.dropPositionObject.allocation;
      if (dropAllocationBlock) {
        const dropValidation = this.checkAlloctionBlockAndPlayerTypePermission(dropAllocationBlock, playerDetails);
        if (dropValidation) {
          this.resetTeeData(dragDropObj);
          return;
        }
      }

      this.isBulkSectionDisabled = true;
      await this.handleBulkCopyDrop(dragDropObj);
      if(this.copytomanyPlayersForm.get('allocationCode'))
        this.isBulkSectionDisabled = false;
      return;
    }
    else {
      dragDropObj.dragPositionObject = this.dialogData.info;
      const isDropValid: boolean = this._teeTimesActionBusiness.validateDrop(dragDropObj.dropPositionObject, TeeTimeAction.copy);
      if (!isDropValid) {
        this.resetTeeData(dragDropObj);
        return;
      }
      const dragValidation = this.checkAlloctionBlockAndPlayerTypePermission({ id: dragDropObj.dragData[0].allocationBlockId, name: '' }, dragDropObj.dragData);
      if (dragValidation) {
        this.resetTeeData(dragDropObj);
        return;
      }
      const dropValidation = this.checkAlloctionBlockAndPlayerTypePermission(dragDropObj.dropPositionObject.allocation, dragDropObj.dropPositionObject.playerDetail);
      if (dropValidation) {
        this.resetTeeData(dragDropObj);
        return;
      }

      try {
        this._CopytomanyPlayerBusiness.updateTeeSlots(dragDropObj.currentState);
        this.lastUpdatedSlots = dragDropObj.currentState;
        draggedPlayers = this._teeTimesActionBusiness.mapPlayerData(dragDropObj.dropPositionObject, dragDropObj.dragData);
        this._utilities.ToggleLoader(true);
        const teeTimeAction: TeeTimeAction = this.teeTimeFormat == TeeTimeFormat.BulkTee ? TeeTimeAction.bulkCopy : TeeTimeAction.copy;
        var validationResult = await this._teeTimesActionBusiness.validateTeeTimeAction(this.dialogData.info, teeTimeAction, draggedPlayers);
        this.currentValidationResult = validationResult; 
        this._utilities.ToggleLoader(false);        
        if (validationResult && validationResult.result && validationResult.result.length > 0) {
          let draggedPlayersCaddyInfo: PlayerTeeTimeSlot[] = [];       
          draggedPlayersCaddyInfo = await this._teeSheetUtilities.showCaddyNotAvailableAlert(validationResult, draggedPlayers,this.draggedPlayersCaddyInfoTemp);
          draggedPlayersCaddyInfo.forEach(x=>{
            this.draggedPlayersCaddyInfoTemp.push(x);
          })
          this.playersCaddyInfo = this._teeTimesActionBusiness.updatePlayerCaddyInfo(draggedPlayersCaddyInfo, this.playersCaddyInfo);
          if(this.teeTimeFormat == TeeTimeFormat.None || this.teeTimeFormat == TeeTimeFormat.Individual)
          {
            this.validateMulitpack();
            this._CopytomanyPlayerBusiness.updateTeeSlots(dragDropObj.currentState);
            this.lastUpdatedSlots = dragDropObj.currentState;
          }
        
          validationResult.result.forEach((element) => {
            if (element.result && draggedPlayersCaddyInfo.length != 0 && (!element.isDestinationSlotRateChanged || (element.isDestinationSlotRateChanged))) {
              this.onDropSuccess(dragDropObj, element.playerTeeTimeSlot);
            } else {
              this.resetTeeData(dragDropObj, element.playerTeeTimeSlot);
            }
          });
        }
      } catch (error) {
        if (!(error.name != undefined && error.name === "HttpErrorResponse")) {
          const error: string = this._localization.getError(this.unHandledErrorCode);
          this._utilities.showError(error);
        }
        this.resetTeeData(dragDropObj);
      }
      finally {
        this._utilities.ToggleLoader(false);
      }
    }
  }
  validateMulitpack()
  {
    let currentValidationDate = new Date(_.cloneDeep(this.date).setHours(0,0,0,0));
    this.dragDropObj.dragData.forEach(x => {
      x.multiPackGreenFeeValue = 0;
      x.multiPackCartFeeValue = 0;
      const playerLinkId = this.playerResponse.find(player => player.id == x.playerId).playerLinkId;
      let playerRateSetup = this.rateSetup.find(rate => rate.rateTypeId == x.scheduledTeeTimePlayerFee.rateTypeId);
      if (playerRateSetup) {
        let multipackDetails = this.multiPackResponse.filter(m => m.guestId == playerLinkId && !m.clientMultiPacks[0].isExpired && !m.clientMultiPacks[0].isReturned
          && (m.clientMultiPacks[0].isUnlimited || (m.clientMultiPacks[0].remaining - m.usingCount) > 0)
          && this._localization.getDate(m.clientMultiPacks[0].startDate) <= currentValidationDate && this._localization.getDate(m.clientMultiPacks[0].expirationDate) >= currentValidationDate);
        let linkedMultipackDetail = multipackDetails.find(m => m.transactionDetailId == x.multiPackTransactionDetailId);
        if (linkedMultipackDetail && this.haslinkedMultipack(linkedMultipackDetail, playerRateSetup)) {
          linkedMultipackDetail.usingCount++;
          x.multiPackGreenFeeValue = linkedMultipackDetail.clientMultiPacks.find(cm=> cm.linkedRetailItemId == playerRateSetup.greenFeeRetailItemId).salePrice;
          let linkCartFee = linkedMultipackDetail.clientMultiPacks.find(cm=> cm.linkedRetailItemId == playerRateSetup.cartFeeRetailItemId);
          x.multiPackCartFeeValue = linkCartFee ? linkCartFee.salePrice : 0;
          
        }
        else {
          let availableMultipacks: any[] = [];
          multipackDetails.filter(m => m.transactionDetailId != x.multiPackTransactionDetailId).forEach(mp => {
            if (this.haslinkedMultipack(mp, playerRateSetup)) {
              availableMultipacks.push(mp);
            }
          }
          )
          if (availableMultipacks && availableMultipacks.length > 0) {
            let multipackToLink = (availableMultipacks.sort(
              (objA, objB) => this._localization.getDate(objA.clientMultiPacks[0].expirationDate).getTime() - this._localization.getDate(objB.clientMultiPacks[0].expirationDate).getTime()))[0];
            if(multipackToLink)
            {
              multipackToLink.usingCount++;
              x.multiPackGreenFeeValue = multipackToLink.clientMultiPacks.find(cm=> cm.linkedRetailItemId == playerRateSetup.greenFeeRetailItemId).salePrice;
              let linkCartFee = multipackToLink.clientMultiPacks.find(cm=> cm.linkedRetailItemId == playerRateSetup.cartFeeRetailItemId);
              x.multiPackCartFeeValue = linkCartFee ? linkCartFee.salePrice : 0;

            }
            x.multiPackTransactionDetailId = multipackToLink.transactionDetailId;
          }
          else {
            x.multiPackTransactionDetailId = 0;
          }
        }

      }
      else{
        x.multiPackTransactionDetailId = 0;
      }
    });
  }
  haslinkedMultipack(linkedMultipackDetail, playerRateSetup) {
    const linkedMultipackRetailItem = linkedMultipackDetail.clientMultiPacks.map(c => c.linkedRetailItemId);
    let haslinkedMultipack = (
      (linkedMultipackRetailItem.includes(playerRateSetup.greenFeeRetailItemId) &&
        (playerRateSetup.cartFeeRetailItemId == 0 || linkedMultipackRetailItem.includes(playerRateSetup.cartFeeRetailItemId))
      )) ? true : false;
    return haslinkedMultipack;
  }
  async linkMultiPack(params: any[]): Promise<boolean> {
    return await this._linkMultipackBusiness.LinkMultiPack(params);
  }
  async unLinkMultiPack(params: any[]): Promise<boolean> {
    return await this._linkMultipackBusiness.UnLinkMultiPack(params);
  }

  private useDestinationRateFees: boolean;
  private cancelCopy: boolean;
  async showRateChangeAlert(validationResult: ValidationResult<CopyMoveResult[]>) {
    // check destination change 
    let teesheetGridContent = [];
    let destinationChanged = validationResult.result.filter(x => x.isDestinationSlotRateChanged);
    if (destinationChanged && destinationChanged.length > 0) {
      if(this.playerInfo != null && this.playerInfo.length > 0){
        teesheetGridContent = this._teeSheetUtilities.mapPlayersTobeDroppedToTeeFeeGridData(validationResult,this.playerInfo);
      }
      let message = this.lblcaptions.DestinationRateChanged;
      var res = this._teeSheetUtilities.showTeeFeeAlert(message, AlertType.Warning, 
        ButtonType.CourseFeeSelection,teesheetGridContent, (res: any) => {
        if (res === MoveRateTypeChangeSelection.CancelMove) {
          this.cancelCopy = true;
        }
        else if (res === MoveRateTypeChangeSelection.UseDestinationTeeFee) {
          this.useDestinationRateFees = true;
          this.cancelCopy = false;
        }
        else {
          this.useDestinationRateFees = false;
          this.cancelCopy = false;
        }
      },false);
      await res.afterClosed().toPromise();
    }
  }

  async handleBulkCopyDrop(dragDropObj: any) {
    let draggedPlayers: PlayerTeeTimeSlot[];
    let isDestinationSlotRateChanged: boolean = false;
    dragDropObj.dragData = this.draggedPlayersList;
    let allocationblock: AllocationCode = this._CopytomanyPlayerBusiness.mapAllocationBlockData(dragDropObj.dropPositionObject.allocation);
    let teeSlots = this._CopytomanyPlayerBusiness.buildTeeSlots(_.cloneDeep(this.modifiedSlots), dragDropObj.dragData, dragDropObj.dropPositionObject.time, this.playersPerGroup, allocationblock, dragDropObj.dropPositionObject.originalHoleNumber);
    if (teeSlots && teeSlots.TeeSlots) {
      this.bulkStatus = this._CopytomanyPlayerBusiness.getBulkStatus(teeSlots, _.cloneDeep(this.modifiedSlots));

      if (this.bulkStatus.status === status.failure) {
        this._utilities.showAlert(this._localization.captions.teetime.SlotsNotAvailableForBulkBook, AlertType.Info, ButtonType.Ok);
        this.autoSelectPlayers();
      } else if (this.bulkStatus.status === status.squeeze) {
        let isAllow: boolean = await this._CopytomanyPlayerBusiness.validateBreakPointAccess(UserAccessBreakPoints.SQUEEZETEETIME, false);
        if (isAllow) {
          this._TeeGridService.squeezeTeeRows(this.bulkStatus.maxSqueezeMins, "Copy Component", this.onSqueezeClose.bind(this));
        } else {
          this._utilities.showAlert(this._localization.captions.teetime.SlotsNotAvailableForBulkBook, AlertType.Info, ButtonType.Ok);
          this.autoSelectPlayers();
        }
      }

      else {
        let modifiedTeeTimes = this._CopytomanyPlayerBusiness.buildTeeSheetSkeletonData(_.cloneDeep(this.modifiedSlots), teeSlots.TeeSlots, dragDropObj.dragData, this.playersPerGroup, allocationblock, true, this.squeezeSlots);
        this.lastUpdatedSlots = _.cloneDeep(modifiedTeeTimes);
        draggedPlayers = this._CopytomanyPlayerBusiness.generateModifiedPlayersTeeSlots(this.modifiedSlots, this.lastUpdatedSlots);
        draggedPlayers.forEach(x => x.overrideDetails = this.GetOverrideDetails(0));
        try {
          this._utilities.ToggleLoader(true);
          var validationResult = await this._teeTimesActionBusiness.validateTeeTimeAction(this.dialogData.info, TeeTimeAction.bulkCopy, draggedPlayers);
          this.currentValidationResult = validationResult;
          this._utilities.ToggleLoader(false);
          if (validationResult && validationResult.result && validationResult.result.length > 0) {
            draggedPlayers = await this._teeSheetUtilities.showCaddyNotAvailableAlert(validationResult, draggedPlayers,this.bulkDraggedPlayersCaddyInfoTemp);
            this.playersCaddyInfo = this._teeTimesActionBusiness.updatePlayerCaddyInfo(draggedPlayers, this.playersCaddyInfo);           
            draggedPlayers.forEach(x=>{
              this.bulkDraggedPlayersCaddyInfoTemp.push(x);
            })
            const failedRules = validationResult.result.filter(o => !o.result).length > 0;
            this.resetPlayerList();
            if (failedRules || isDestinationSlotRateChanged) {
              return;
            }
            this._CopytomanyPlayerBusiness.updateTeeSheetGridContent(this.lastUpdatedSlots);
            this.onDropSuccessForBulk(dragDropObj, draggedPlayers);
          }
        }
        finally {
          this._utilities.ToggleLoader(false);
        }
      }

    }
  }
  isDropAllowed(draggedPlayers, dropObj): boolean {
    return this._TeeGridService.isDropAllowedOnBulkMove(draggedPlayers, dropObj);
  }
  onDropSuccess(dragDropObj: TeeSheetDragDropEvt, validPlayer: PlayerTeeTimeSlot) {
    this.notifyParent.emit(true);
    this._StepperService.setBackEnable(true);
    let fullPlayers = _.cloneDeep(this.dialogData.info.playerDetail);
    let blockPlayers = fullPlayers.filter(x => !x.isBlocked);
    this.playerList = this.teeTimeFormat == TeeTimeFormat.BulkTee ? this.playerList :this._CopytomanyPlayerBusiness.getPlayerList(blockPlayers);
    this._TeeGridService.selectedPlayers = [];
    if (dragDropObj.dragOrigin === DragOrigin.playerlist && this.playerList.length === 0) {
      this.notificationFlag = true;
    }
    this.resetPlayerList();
    // Call this method to get Profile Images - Parameters(list of player that needs profile images, reference id from API response)
    this._imageService.getProfileImages(this.playerList, "imageReferenceId");
  }

  onDropSuccessForBulk(dragDropObj: TeeSheetDragDropEvt, validPlayer: PlayerTeeTimeSlot[]) {
    this.notifyParent.emit(true);
    this._StepperService.setBackEnable(true);
    // validPlayer.forEach(element => {
    //   this.playerList = this.playerList.filter(p => p.playerId !== element.playerId);
    // });
    this._TeeGridService.selectedPlayers = [];
    if (dragDropObj.dragOrigin === DragOrigin.playerlist) {
      this.notificationFlag = true;
    }
    this.resetPlayerList();
  }
  allocationCodeChange(arg) {
    if (this.userBreakpointAccess && this.userBreakpointAccess.isAllow) {
      this.selectedAllocationcode = this.copytomanyPlayersForm.controls.allocationCode.value;
      if(this.selectedAllocationcode && this.selectedAllocationcode.id != 0)
        this.isBulkSectionDisabled = false;
      this.copytomanyPlayersForm.get('playerType').reset();
      this.copytomanyPlayersForm.get('rateType').reset();
      let codesWithPlayerTypes = this.allocationCodesWithPlayerTypes.find(x => x.id == arg.value.id);
      if (codesWithPlayerTypes) {
        this.playerTypes = codesWithPlayerTypes.playerTypes.sort((a, b) => (a.type.toLocaleLowerCase() > b.type.toLocaleLowerCase()) ? 1 : -1);
        this.playerTypes = this.playerTypes.filter(x => x.isActive);
      }
    }
  }
  validateAllocationBlockDaysOutEnd(daysOut: number, teeTimeDate: Date, errMessage: string, allocationCodeId: Number) {
    if (daysOut > 0 && this._localization.getDateDifference(teeTimeDate, this._PropertyInformation.CurrentDate) > daysOut) {
      this._utilities.showError(errMessage);
      this.copytomanyPlayersForm.get('allocationCode').setValue(this.defaultSettings.blockingCode == this.selectedAllocationcode.id ? undefined : this.selectedAllocationcode);
    } else {
      this.selectedAllocationcode = this.copytomanyPlayersForm.controls.allocationCode.value;
      this.copytomanyPlayersForm.get('playerType').reset();
      this.copytomanyPlayersForm.get('rateType').reset();
      let codesWithPlayerTypes = this.allocationCodesWithPlayerTypes.find(x => x.id == allocationCodeId);
      if (codesWithPlayerTypes) {
        this.playerTypes = codesWithPlayerTypes.playerTypes.sort((a, b) => (a.type.toLocaleLowerCase() > b.type.toLocaleLowerCase()) ? 1 : -1);
        this.playerTypes = this.playerTypes.filter(x => x.isActive);
      }
    }
  }

  PlayerTypeChange(arg) {
    const selectedOption = arg.value;
    const daysOutStart = selectedOption.daysOutStart;
    const daysOutEnd = selectedOption.daysOutEnd;
    const currentDate = this._PropertyInformation.CurrentDate;
    const teeTimeDate = this._localization.getDate(this.date);
    const daysOutStartDate = this._localization.GetFormattedDateDDMMYY(this._localization.AddDays(currentDate, daysOutStart));
    const daysOutEndDate = this._localization.GetFormattedDateDDMMYY(this._localization.AddDays(currentDate, daysOutEnd));
    const startErrorMessage: string = this.settingsCaptions.playerDaysOutStartError + ' ' + daysOutStartDate;
    const endErrorMessage: string = this.settingsCaptions.playerDaysOutEndError + ' ' + daysOutEndDate;
    this.handlePlayersDaysOut(daysOutStart, daysOutEnd, teeTimeDate, startErrorMessage, endErrorMessage, selectedOption.id);
  }

  handlePlayersDaysOut(daysOutStart: number, daysOutEnd: number, teeTimeDate: Date, startErrorMessage: string, endErrorMessage: string, playerTypeId: number) {
    this.selectedPlayerType = this.copytomanyPlayersForm.controls.playerType.value;
    this.copytomanyPlayersForm.get('rateType').reset();
    this.rateTypes = this.playerTypesWithRateTypes.find(x => x.id == playerTypeId).rateTypes.sort((a, b) => (a.type.toLocaleLowerCase() > b.type.toLocaleLowerCase()) ? 1 : -1).filter(x => x.isActive);


  }

  RateTypeChange(arg) {
    if (arg.value) {
      this.selectedRateType = this.existingRateType = this.copytomanyPlayersForm.controls.rateType.value;
    }
  }

  FeeCheck(rateTypeId): boolean {
    const rateSetupItem = this.rateSetup.find(x => x.courseId === this.courseId && x.rateTypeId === rateTypeId);
    return (rateSetupItem) ? true : false;
  }

  comparetSelects = (val1, val2) => {
    return val1 && val2 && val1.id === val2.id;
  }
  resetTeeData(dragDropObj, resetData?: PlayerTeeTimeSlot) {
    if (dragDropObj.dragOrigin === DragOrigin.playerlist) {
      this.draggedPlayersList.forEach(element => {
        if (!(resetData) || element.playerId === resetData.playerId) {
          // Remove is commented out as it will only remove when the objects are exactly equal. So we are updating it with filter based on Player ID.
          _.remove(dragDropObj.currentState[dragDropObj.dropPositionRowIndex].playerDetail, element);

        }
      });
      this.reOrderPlayers(dragDropObj.currentState[dragDropObj.dropPositionRowIndex].playerDetail);
    }
    if (dragDropObj.dragOrigin === DragOrigin.grid) {
      dragDropObj.dragData.forEach(element => {
        dragDropObj.currentState[dragDropObj.dragPositionRowIndex].playerDetail.push(element);
        _.remove(dragDropObj.currentState[dragDropObj.dropPositionRowIndex].playerDetail, element);
      });
      this.reOrderPlayers(dragDropObj.currentState[dragDropObj.dragPositionRowIndex].playerDetail);
    }
    this.resetPlayerList();
    this.resetCaddyInfo(resetData, this.playersCaddyInfo);
    this._CopytomanyPlayerBusiness.updateTeeSlots(dragDropObj.currentState);
  }

  resetCaddyInfo(resetData: PlayerTeeTimeSlot, playersCaddyInfo: PlayersCaddyInfo[]) {
    if (resetData && playersCaddyInfo && playersCaddyInfo.length > 0) {
      playersCaddyInfo = playersCaddyInfo && playersCaddyInfo.length > 0 ? playersCaddyInfo.filter(x => x.playerId != resetData.playerId || (this._utilities.getDate(resetData.scheduledDateTime).getTime() != this._utilities.getDate(x.scheduledDateTime).getTime()) || resetData.playerSlotPosition != x.playerSlotPosition) : [];
    }
  }
  removeCaddyInfo(removeData: number, scheduledDateTime: string, playerSlotPosition : number) {
    if (removeData && this.playersCaddyInfo && this.playersCaddyInfo.length > 0) {
      let index = this.playersCaddyInfo.findIndex(x => x.playerId == removeData && (this._utilities.getDate(scheduledDateTime).getTime() == this._utilities.getDate(x.scheduledDateTime).getTime()) && playerSlotPosition == x.playerSlotPosition);
      this.playersCaddyInfo.splice(index, 1);
    }
  }
  onDragPlayers(playersDragged) {
    playersDragged.map(res => res.bookingSource = 0);
    this.draggedPlayersList = playersDragged;
  }

  onDragGridPlayers(e) {
    this._TeeGridService.selectedPlayers.forEach(element => {
      element.playerClicked = e;
    });
  }

  private teeSheetModifications: TeeSheetModications;

  private async saveCopyTeeTimes(modifiedSlots: PlayerTeeTimeSlot[]): Promise<boolean> {
    if (!this.teeSheetModifications) { return; }
    const teeTimeAction: TeeTimeAction = this.teeTimeFormat == TeeTimeFormat.BulkTee ? TeeTimeAction.bulkCopy : TeeTimeAction.copy;
    await this.showRateChangeAlert(this.currentValidationResult);
    if (this.cancelCopy)
      return;
    modifiedSlots.forEach(x => x.useDestinationTeeFees = this.useDestinationRateFees)
    if (teeTimeAction == TeeTimeAction.bulkCopy) {
      let copyConfig: CopyBulkTeeTimeConfig = {
        courseId: this.courseId,
        allocationBlockId: this.selectedAllocationcode ? this.selectedAllocationcode.id : 0,
        playerTypeId: this.selectedPlayerType ? this.selectedPlayerType.id : 0,
        rateTypeId: this.selectedRateType ? this.selectedRateType.id : 0,
        bulkPlayerTeeTimeSlots: modifiedSlots
      }
      var res = this._teeTimesActionBusiness.saveBulkCopyTeeTime(this.dialogData.info, copyConfig, teeTimeAction);
      const resp:any = await res;
      this._teeTimesActionBusiness.copyBookingId.pipe(takeUntil(this.destroyed$)).subscribe(x => {
        this.bookingId = x;
      })
      return resp;
    }
    return this._teeTimesActionBusiness.saveTeeTimeAction(this.dialogData.info, modifiedSlots, teeTimeAction);
  }
  onPlayerListEmit(playerCollectionList) {
    this.playerCollectionList = playerCollectionList;
    this.cdr.detectChanges();
  }

  removeNotification(event) {
    this.notificationFlag = event;
  }
  async calltriggeremailsmspopupfunction(playerids) {

    let userinfo = playerids != null && playerids?.length > 0 ? await this._retailService.GetPlayerContactInfoByPlayerIdList(playerids) : null;
    let objPhone: any = userinfo != null && userinfo?.length > 0 ? this._utilities.GetPhoneNumber(userinfo) : null;
    let email = userinfo != null && userinfo?.length > 0 ? this._utilities.GetEmail(userinfo) : null;

    this.popUpComponentDetails = {
      componentName: SendNotificationMailSmsComponent,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent',
        bindData: ''
      }
    };
    this._dialog.open(SendNotificationMailSmsComponent, {
      width: "40%",
      height: "49%",
      panelClass: "",
      data: {
        title: this._localization.captions.common.notificationTitle,
        save: this._localization.captions.common.PopUpSend,
        cancel: this._localization.captions.common.PopUpSkip,
        componentDetails: this.popUpComponentDetails,
        info: this.bookingId,
        mailId: email && email != null && email?.value ? email.value : null,
        countryCode: objPhone && objPhone != null && objPhone != "" && objPhone?.value && objPhone?.value != null && objPhone?.value != "" ? this._utilities.getCountryCodeFromValue(Number(objPhone?.type), objPhone?.value) : null,
        phone: objPhone && objPhone != null && objPhone != "" && objPhone?.value && objPhone?.value != null && objPhone?.value != "" ? this._utilities.getPhoneNumberFromValue(Number(objPhone?.type), objPhone?.value) : null,
        action: "BulkBooking",
        showStaticBool: false,
        fromComponent: 'fromComponent',
        actionType: ButtonAction.save
      },
      disableClose: true
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe();
  }

  async CanChangeBulkView(arg) {
    let bResult: boolean;
    if (arg && arg.isBulk) {
      var response = this._utilities.showAlert(this.lblcaptions.changeBulkView, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res === AlertAction.YES) {
          bResult = true;
        }
      });
      await response.afterClosed().toPromise();
      if (bResult) {
        await this.SetDefaultValuesForBulkView(arg.player);
      }
    }
  }

  async SetDefaultValuesForBulkView(player) {
    this.notifyParent.emit(false);
    this._StepperService.setBackEnable(false);
    this.playerInfo = this.playerList = [];
    this.teeTimeFormat = TeeTimeFormat.BulkTee;
    this.playersPerGroup = player.playersPerGroup ? player.playersPerGroup : this.playersPerGroup;
    this.customTableData.isBulkMoveEnabled = true;
    this.customTableData = { ... this.customTableData };
    this.createCopytomanyPlayersForm();
    this.copytomanyPlayersForm.get('allocationCode').valueChanges.subscribe(allocationCode => {
      if (this.userBreakpointAccess && !this.userBreakpointAccess.isAllow && allocationCode && allocationCode !== this.copytomanyPlayersForm.value.allocationCode) {
        this.copytomanyPlayersForm.get('allocationCode').patchValue(this.copytomanyPlayersForm.value.allocationCode);
        let bpMessage = this._localization.captions.breakpoint[UserAccessBreakPoints.CHANGEALLOCATIONBLOCK];
        this._CopytomanyPlayerBusiness.showBreakPointPopup(bpMessage);
        return;
      }
    });
    await this.GetBulkPlayers(player.bookingId);
    await this.getData(this.courseId, this.date);
    await this.getDataForBulkCopy();
    this._imageService.getProfileImages(this.playerList, "imageReferenceId");
    this.cdr.detectChanges();
  }

  private updateUserAccessInfo() {
    if (this.mixedPlayerSlot && this.bulkPlayerUserAccess && this.normalPlayerUserAccess && (!this.bulkPlayerUserAccess.isAllow || !this.normalPlayerUserAccess.isAllow)) {
      this.infoStripAvailable = true;
      const parentEl = this.infoEle.nativeElement;
      let infoWrapper;
      if (!this.bulkPlayerUserAccess.isAllow) {
        const message = this._localization.captions.breakpoint[UserAccessBreakPoints.BULKCOPY];
        infoWrapper = this._utilities.GetInfoStrip(message);
      } else if (!this.normalPlayerUserAccess.isAllow) {
        const message = this._localization.captions.breakpoint[UserAccessBreakPoints.CopytoMany];
        infoWrapper = this._utilities.GetInfoStrip(message);
      }
      this.rendrer.insertBefore(parentEl, infoWrapper, parentEl.childNodes[0]);
    }
  }

  private checkAlloctionBlockAndPlayerTypePermission(allocationBlock: { id: number, name: string }, playerDetails: TeeSheetPlayerDetails[]): boolean {
    let res = false;
    if (this.isAllocationBlockEnabled) {
      if (allocationBlock && allocationBlock.id) {
        const allocationBlocks = this.allocationCodePermissions.filter(x => x.allocationBlockId == allocationBlock.id);
        const allocationBlockPlayerTypes = this.allocationBlockPlayerTypes.find(x => x.id == allocationBlock.id);
        if (allocationBlocks.length == 0) {
          this._utilities.showAllocationCodePermissionDeniedPopup(allocationBlock.name, this._localization.captions.common.CopyNotAllowed);
          res = true;
        } else {
          let nonaccessiblePlayer = [];
          playerDetails.forEach(playerInfo => {
            if (playerInfo.playerTypeId) {
              const allocationPlayer = allocationBlocks.filter(x => x.playerTypeId == playerInfo.playerTypeId);
              if (allocationPlayer.length == 0) {
                const playerType = allocationBlockPlayerTypes.playerTypes.find(x => x.id === playerInfo.playerTypeId);
                if (playerType) {
                  nonaccessiblePlayer.push(playerType.type);
                }
              }
            }
          });
          if (nonaccessiblePlayer.length > 0) {
            const uniqueNonAccessPlayer = _.uniq(nonaccessiblePlayer);
            let name = '';
            if (uniqueNonAccessPlayer.length > 0) {
              name = uniqueNonAccessPlayer.join(', ')
            }
            this._utilities.showAllocationCodePermissionDeniedPopup(name, this._localization.captions.common.CopyNotAllowed);
            res = true;
          }
        }
      }
    }
    return res;
  }

  getScheduledPlayers(): TeeSheetPlayerDetails[] {
    let allScheduleInfo: TeeSheetPlayerDetails[] = [];
    if (this.scheduledPlayers && this.scheduledPlayers.length > 0) {
      let bulkplayers = this._CopytomanyPlayerBusiness.mapTeeSheetplayerDetails(this.scheduledPlayers, this.allRateTypes);
      allScheduleInfo.push(...this._CopytomanyPlayerBusiness.getPlayerList(bulkplayers));
    }
    if (this.playerInfo && this.playerInfo.length > 0) {
      allScheduleInfo.push(...this.playerInfo);
    }
    return allScheduleInfo;
  }

  selectAll(eve) {
    this.selectPlayers(eve);
  }

  private autoSelectPlayers() {
    if (this.defaultSettings && this.defaultSettings.autoSelectPlayers) {
      this.selectPlayers(true);
    } else {
      this.selectPlayers(false);
    }
  }

  private selectPlayers(value: boolean) {
    this._TeeGridService.selectedPlayers = [];
    if (value) {
      if (this.playerList.length > 0) {
        const isAllBulk = (this.playerList.filter(x => x.teeTimeFormat == TeeTimeFormat.BulkTee).length == this.playerList.length
          && this.teeTimeFormat == TeeTimeFormat.BulkTee);
        if (isAllBulk) {
          this.playerList.forEach(element => {
            element['playerClicked'] = true;
            this._TeeGridService.selectedPlayers.push(element);
          });
        } else {
          this.playerList.forEach(element => {
            if (element.teeTimeFormat == TeeTimeFormat.None ||
              element.teeTimeFormat == TeeTimeFormat.GroupTee || element.teeTimeFormat == TeeTimeFormat.Tournament) {
              element['playerClicked'] = true;
              this._TeeGridService.selectedPlayers.push(element);
            } else {
              element['playerClicked'] = false;
            }
          });
        }
      }
    } else {
      this.playerList.forEach(element => {
        element['playerClicked'] = false;
      });
    }
  }
}
