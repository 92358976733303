<div class="ag_display--flex h-100">
<form class='player-infor-form golf-container--padding bodyWrap' [formGroup]="playerInfoFormGrp" autocomplete="off">
  
  <ag-golfimage-uploader [attr.automationId]="'UpId_PlayerInfo_UploadyourPhoto'" [emptyImgCaption]="captions.UploadyourPhoto" [imgData]="url" (fileDeleted)="fileDeleted()"
    (fileUploaded)="fileUploaded($event)"></ag-golfimage-uploader>
  <div class='right-section ag-pl-4'>
    <div class="ag_display--flex title-wrap w-100">
      <!-- <mat-form-field [floatLabel]="floatLabel" class='golf-form-control--xs'>
        <mat-select [attr.automationId]="'Dd_PlayerInfo_title'" [placeholder]="captions.title" formControlName="title">
          <mat-option *ngFor="let title of titles" [value]="title.key">
            {{title.value}}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
      <app-dropdown-search  [inputs]="titledropdownInput" [filteredOptions]="titles"></app-dropdown-search>
      <div *ngIf = "showViewEform" class="icon-container ml-3" [ngClass]="isGuestRecordPurged || isFromCreateOrCopy ? 'ag_link--disabled opacity-spa' : ''">
        <i  [attr.automationId]="'Icn_PlayerInfo_openEforms'" class="icon-Datamagine cursor" [matTooltip]="captions.dataMagineForms" (click)="openEForms()"></i>
      </div> 
      <div class="ml-3 w-90" *ngIf = "isGuestRecordPurged && showClose">
        <app-warning-bar [attr.automationId]="'Icn_PlayerInfo_closeWarning'" (closeWarning)="closeWarning()" [message]="allCaptions.lbl_guestRecordPurged" [showClose]="showClose">
        </app-warning-bar>
      </div>
    </div>
    <div>
      <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--xs">
        <input [attr.automationId]="'Txt_PlayerInfo_fname'" matInput [placeholder]="captions.firstName" maxlength="256" formControlName="firstName" 
         (blur) ="firstNameValidation()" required>
        <mat-error *ngIf="playerInfoFormGrp.get('firstName').hasError('required')">
          {{errorMessage.firstNameError}}
        </mat-error>
      </mat-form-field>
      <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--xs">
        <input [attr.automationId]="'Txt_PlayerInfo_lname'" matInput [placeholder]="captions.lastName" maxlength="256" formControlName="lastName" 
        (blur) ="lastNameValidation()" required>
        <mat-error *ngIf="playerInfoFormGrp.get('lastName').hasError('required')">
          {{errorMessage.lastNameError}}
        </mat-error>
      </mat-form-field>
      <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--xs">
        <input [attr.automationId]="'Txt_PlayerInfo_pronounced'" matInput [placeholder]="captions.pronounced" formControlName="pronounced" maxlength="25">
      </mat-form-field>
    </div>
    <div>
      <div class='gender-toggle'>
        <label class="golf-form--label">{{captions.gender}}</label>
        <mat-button-toggle-group [attr.automationId]="'Mt_PlayerInfo_gender'" class="ag-mr-3" formControlName="gender">
          <mat-button-toggle *ngFor='let gender of genders' [value]="gender.value">{{gender.text}}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <app-golf-date-picker [inputs]="dateInputs"></app-golf-date-picker>

      <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--xs">
        <mat-select [attr.automationId]="'Dd_PlayerInfo_playerType'" [placeholder]="captions.playerType" name="playerType" formControlName="playerType"
          [compareWith]="comparetSelects" (selectionChange)="playerTypeChange($event)">
          <mat-option></mat-option>
          <mat-option *ngFor="let playerType of playerTypes" [matTooltip]="playerType?.type" [value]="playerType">
            {{playerType?.type}}
          </mat-option>
        </mat-select>
        <mat-error>{{errorMessage.missingPlayerType}}</mat-error>
      </mat-form-field>
    </div>


    <div>
        <div class="patron-section" [ngClass]="isGuestRecordPurged  ? 'ag_link--disabled opacity-spa' : ''">
          <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--xs" [ngClass]="!isCMSConfigured || isPatronIdAvailable ? 'button_invalid' : ''">
            <input [attr.automationId]="'Txt_PlayerInfo_patronId'" matInput [placeholder]="captions.PatronId" (focusout)="checkPatronValidation()" [readonly]="!isCMSConfigured || isPatronIdAvailable" formControlName="patronid" >
            <mat-error class="" *ngIf="isCMSConfigured && !isPatronIdAvailable">
              {{captions.PlayerLookupNotHappened}}
            </mat-error>
          </mat-form-field>
          <div class="form-section golf-form-control--xs">
            <button mat-button [attr.automationId]="'Btn_PlayerInfo_searchPatron'" class="body-bgcolor patron-search cursor" type="button" [disabled]="!isCMSConfigured || isPatronIdAvailable" [ngClass]="!isCMSConfigured || isPatronIdAvailable ? 'button_invalid' : ''" (click)="searchPatron()">
              <i  aria-hidden="true" class="icon-Search whitecolor"></i>
            </button>
            <button mat-button (click)="playerWorthDetails($event)" class="player-worth cursor" [disabled]="!isCMSConfigured || !isPatronIdAvailable" [ngClass]="!isCMSConfigured || !isPatronIdAvailable ? 'button_invalid' : ''"
                matTooltip="{{captions.PlayerWorth}}" >
              <ng-container *ngIf="isPatronIdAvailable">
                <span class="icon-Player-worth_enabled"><span class="path1"></span><span class="path2"></span></span>
              </ng-container>
              <ng-container *ngIf="!isPatronIdAvailable">
                <span class="icon-Player-worth_disabled"><span class="path1"></span><span class="path2"></span></span>
              </ng-container>
            </button>
          </div>
          <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--xs">
            <mat-select [attr.automationId]="'Txt_PlayerInfo_rateType'" [placeholder]="captions.rateType" name="rateType" formControlName="rateType"
              [compareWith]="comparetSelects" (selectionChange)="rateTypeChange($event)">
              <mat-option></mat-option>
              <mat-option *ngFor="let rateType of rateTypes" [value]="rateType">
                {{rateType?.type}}
              </mat-option>
            </mat-select>
            <mat-error>{{errorMessage.missingRateType}}</mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--xs button_invalid">
            <input [attr.automationId]="'Txt_PlayerInfo_rank'" matInput [placeholder]="captions.Rank" readonly formControlName="rank">
          </mat-form-field>
        </div>
      </div>

    <div [ngClass]="isGuestRecordPurged  ? 'ag_link--disabled opacity-spa' : ''">
      <h4 class='golf-form--headers'>{{captions.contact}}</h4>
      <app-ag-golfphone-number [inputs]="phoneInputs" [values]="phoneInfo"></app-ag-golfphone-number>
    </div>
    <div [ngClass]="isGuestRecordPurged  ? 'ag_link--disabled opacity-spa' : ''">
      <app-ag-golfmail-id [inputs]="mailInputs" [values]="mailInfo" [validateMail]="validateMail"></app-ag-golfmail-id>
    </div>
    <div class="position--relative fixed-width--wrapper in-block" [ngClass]="isGuestRecordPurged  ? 'ag_link--disabled opacity-spa' : ''">
      <h4 class='golf-form--headers text-uppercase'>{{captions.address}}</h4>
      <app-ag-golfaddress [inputs]="addressInput" [values]="addressValue" (onAddressChange)="addresschange($event)"></app-ag-golfaddress>
    </div>
    <div class="private-address in-block " [ngClass]="isGuestRecordPurged  ? 'ag_link--disabled opacity-spa' : ''">
        <label class="golf-form--label">{{captions.PrivateInformation}}</label>
        <ui-switch [attr.automationId]="'Txt_PlayerInfo_privateAddress'" formControlName="privateAddress" [checkedLabel]="captions.yes" [uncheckedLabel]="captions.no">
        </ui-switch>
      </div>
    <div class="fixed-width--wrapper" [ngClass]="isGuestRecordPurged  ? 'ag_link--disabled opacity-spa' : ''">
      <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--sm">
        <input [attr.automationId]="'Txt_PlayerInfo_postalCode'" matInput [placeholder]="captions.postalCode" name="postal_code" maxlength="10" formControlName="postal_code">
      </mat-form-field>
      <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--sm">
        <input [attr.automationId]="'Txt_PlayerInfo_city'" matInput [placeholder]="captions.city" maxlength="25" name="city" formControlName="city">
      </mat-form-field>
    </div>
    <div class="fixed-width--wrapper">
      <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--sm">
        <input [attr.automationId]="'Txt_PlayerInfo_state'" matInput [placeholder]="captions.state" maxlength="25" name="state" formControlName="state">
      </mat-form-field>
      <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--sm">
        <input matInput [attr.automationId]="'Txt_PlayerInfo_country'"
               type="text"
               formControlName="country"
               [matAutocomplete]="auto"
               [placeholder]="captions.country"
               autocomplete="off"
               [maxlength]="25"
               name="country">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let country of filteredCountries | async" [value]="country.CountryName" [matTooltip]="country.CountryName">
            <span>{{country.CountryName}}</span>
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="!playerInfoFormGrp.get('country').value && playerInfoFormGrp.get('country').hasError('required')">
          {{captions.missingCountryName}}
        </mat-error>
        <mat-error *ngIf="playerInfoFormGrp.get('country').value && playerInfoFormGrp.get('country').hasError('invalid')">
          {{captions.invalidCountryName}}
        </mat-error>
      </mat-form-field>
    </div>

  </div>
</form>
<div *ngIf="isExpand" class="bodyWrap link-profiles ag_container--padding-xs" [formGroup]="linkProfile">
  <mat-button-toggle-group class="ag_mb--3 w-100" [attr.automationId]="'Mt_PlayerInfo_profiles'" formControlName="profiles">
    <mat-button-toggle class="w-50" [value]="1">{{captions.similarProfiles}}</mat-button-toggle>
      <mat-button-toggle class="w-50" [value]="2">{{captions.searchProfiles}}</mat-button-toggle>
  </mat-button-toggle-group>

  <div *ngIf="linkProfile.get('profiles').value==1">
    <div *ngIf="similarProfiles && similarProfiles.length == 0">
      <app-no-data-found [isSearch]='false'></app-no-data-found>
    </div>
    <div *ngFor="let profile of similarProfiles">
      <div class="profile-section cursor" (click)="onProfileSelection(profile,similarProfiles)" [ngClass]="[profile.isSelected ? 'selected' : '']">
        <div class="ag_display--flex align-items-baseline">
          <span class=""><app-img-thumbnail [imageContent]="profile.basicInformation.image" [thumbnail]="true" imageType="client">
            </app-img-thumbnail></span>
                <span class='ml-2 font-bold' [matTooltip]="profile.basicInformation | formatName: 'firstName': 'lastName' ">
                  {{profile.basicInformation | formatName: 'firstName': 'lastName'}}
                </span>
        </div>
        <div class="ag_display--flex mt-2">
          <div *ngIf="profile.phoneNumber != ''" class="ag_display--flex align-items-center w-40">
            <span class="icon-phone_24px"></span>
            <span matTooltip="{{profile.phone}}" class="ml-1 w-90">{{profile.phoneNumber}}</span>
          </div>
          <div *ngIf="profile.basicInformation.email != ''" class="ag_display--flex align-items-center w-60">
            <span class="icon-email_24px ml-3"></span>
            <span matTooltip="{{profile.mail}}" class="ml-1 w-90">{{profile.basicInformation.email}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="linkProfile.get('profiles').value==2">
    <mat-radio-group formControlName="searchBy" (change)="profileSearchChange($event.value)">
      <mat-radio-button class="golf--form-radio-button" *ngFor="let searchBy of profileSearchBy" [value]="searchBy.id">
        {{searchBy?.description}}
      </mat-radio-button>
    </mat-radio-group>
    <div class="ag_display--flex align-items-center mb-3">
      <app-simple-search [placeholder]="searchPlaceholder" [inputdirectiveParam]="inputType"  [clearValue]="clearsimilarSearchValue" 
        (keydown.enter)="searchProfile()" (searchChange)="searchValueChange($event)"></app-simple-search>
      <app-golfbutton class="ml-auto" [buttontype]="actionButton" (valueChange)="searchProfile()"></app-golfbutton>
    </div>
    <div *ngIf="searchProfiles && searchProfiles.length == 0 && isSearchCallCompleted">
      <app-no-data-found [isSearch]='false'></app-no-data-found>
    </div>
    <div *ngFor="let profile of searchProfiles">
      <div class="profile-section mt-2 cursor" (click)="onProfileSelection(profile,searchProfiles)"
        [ngClass]="[profile.isSelected ? 'selected' : '']">
        <div class="ag_display--flex align-items-baseline">
          <span class=""><app-img-thumbnail [imageContent]="profile.basicInformation.image" [thumbnail]="true"
              imageType="client">
            </app-img-thumbnail></span>
                <span class='ml-2 font-bold' [matTooltip]="profile.basicInformation | formatName: 'firstName': 'lastName' ">
                  {{profile.basicInformation | formatName: 'firstName': 'lastName'}}
                </span>
        </div>
        <div class="ag_display--flex mt-2">
          <div *ngIf="profile.phoneNumber != ''" class="ag_display--flex align-items-center w-40">
            <span class="icon-phone_24px"></span>
            <span matTooltip="{{profile.phone}}" class="ml-1 w-90">{{profile.phoneNumber}}</span>
          </div>
          <div *ngIf="profile.basicInformation.email != ''" class="ag_display--flex align-items-center w-60">
            <span class="icon-email_24px ml-3"></span>
            <span matTooltip="{{profile.mail}}" class="ml-1 w-90">{{profile.basicInformation.email}}</span>
          </div>
        </div>
      </div>
    </div>
  </div> 
  </div>