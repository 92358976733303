import { ReportGroup, ReportCode, ReportBreakPoint,JasperReportUrlPath } from './report.constants';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { ReportSelector_ } from '../report.model';
import { ReportDataService } from '../data/report.data.service';
import { Subject } from 'rxjs';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';



export class ReportSelectorBuilder {
	private captions: any = this.localize.captions.reports;
	private JasperBagTagsFilePath : string = JasperReportUrlPath.BagTagsUrlPath;
	public reportList = new Subject<any[]>();
	
	constructor(private localize: GolfLocalization, private reportDataService?: ReportDataService,private _retailFeatureFlag?: RetailFeatureFlagInformationService) {
		this.captions = localize.captions.reports;
	}
	private readonly reportSelector: ReportSelector_[] = [
		{
			id: 1,
			group: ReportGroup.TeeTime,
			code: ReportCode.Teesheet,
			value: this.captions.teesheet,
			breakPointNumber: ReportBreakPoint.TeeSheet
		},
		{
			id: 2,
			group: ReportGroup.TeeTime,
			code: ReportCode.CancelledTeeTimes,
			value: this.captions.cancelledTeeTimes,
			breakPointNumber: ReportBreakPoint.CancellationReport,
			enableJasperReports : true,
			reportUrlpath : JasperReportUrlPath.CancellationTeeTimesUrlPath
		},
		{
			id: 3,
			group: ReportGroup.TeeTime,
			code: ReportCode.NoShow,
			value: this.captions.noShow,
			breakPointNumber: ReportBreakPoint.NoShowReport,
			enableJasperReports : true,
			reportUrlpath : JasperReportUrlPath.NoShowTeeTimeUrlPath
		},
		{
			id: 4,
			group: ReportGroup.TeeTime,
			code: ReportCode.WaitList,
			value: this.captions.waitList,
			breakPointNumber: ReportBreakPoint.WaitList,
			enableJasperReports : true,
			reportUrlpath : JasperReportUrlPath.WaitListUrlPath
		},
		{
			id: 5,
			group: ReportGroup.TeeTime,
			code: ReportCode.BagTags,
			value: this.captions.bagTags,
			breakPointNumber: ReportBreakPoint.BagTags,
			enableJasperReports : true,
			reportUrlpath : this.GetJasperBagTagsFilePath()
		},
		{
			id: 6,
			group: ReportGroup.TeeTime,
			code: ReportCode.TeeTickets,
			value: this.captions.teeTickets,
			breakPointNumber: ReportBreakPoint.TeeTickets
		},
		{
			id: 6,
			group: ReportGroup.TeeTime,
			code: ReportCode.RainChecksIssued,
			value: this.captions.rainCheckStatus,
			breakPointNumber: ReportBreakPoint.RainChecksIssued
		},
		// {
		// 	id: 7,
		// 	group: ReportGroup.Member,
		// 	code: ReportCode.Memberlist,
		// 	value: this.captions.memberlist,
		// 	breakPointNumber: ReportBreakPoint.MemberList
		// },
		// {
		// 	id: 8,
		// 	group: ReportGroup.Member,
		// 	code: ReportCode.MemberRenewal,
		// 	value: this.captions.memberRenewal,
		// 	breakPointNumber: ReportBreakPoint.MemberRenewal
		// },
		{
			id: 9,
			group: ReportGroup.Member,
			code: ReportCode.MemberRoundsPlayed,
			value: this.captions.memberRoundsPlayed,
			breakPointNumber: ReportBreakPoint.MemberRoundsPlayed
		},
		{
			id: 10,
			group: ReportGroup.Tournament,
			code: ReportCode.TouranmentList,
			value: this.captions.touranmentList,
			breakPointNumber: ReportBreakPoint.TournamentList
		},
		{
			id: 11,
			group: ReportGroup.Tournament,
			code: ReportCode.ScoreCards,
			value: this.captions.scoreCards,
			breakPointNumber: ReportBreakPoint.ScoreCards
		},
		{
			id: 12,
			group: ReportGroup.Tournament,
			code: ReportCode.CartCards,
			value: this.captions.cartCards,
			breakPointNumber: ReportBreakPoint.CartCards
		},
		{
			id: 13,
			group: ReportGroup.Statistics,
			code: ReportCode.TeetimeUtilization,
			value: this.captions.teetimeUtilization,
			breakPointNumber: ReportBreakPoint.TeeTimeUtilization
		},
		{
			id: 14,
			group: ReportGroup.Statistics,
			code: ReportCode.TeetimeAnalysis,
			value: this.captions.teetimeAnalysis,
			breakPointNumber: ReportBreakPoint.TeeTimeAnalysis
		},
		{
			id: 15,
			group: ReportGroup.Statistics,
			code: ReportCode.TeetimeStatisticsByMonth,
			value: this.captions.teetimeStatisticsByMonth,
			breakPointNumber: ReportBreakPoint.TeeTimeStatisticsbyMonth
		},
		{
			id: 16,
			group: ReportGroup.Statistics,
			code: ReportCode.RoundsByRateType,
			value: this.captions.roundsByRateType,
			breakPointNumber: ReportBreakPoint.RoundsbyRateType
		},
		{
			id: 17,
			group: ReportGroup.Statistics,
			code: ReportCode.RoundsByRateTypeForecast,
			value: this.captions.roundsByRateTypeForecast,
			breakPointNumber: ReportBreakPoint.RoundsbyRateType
		},
		{
			id: 18,
			group: ReportGroup.Statistics,
			code: ReportCode.RoundsByPlayerType,
			value: this.captions.roundsByPlayerType,
			breakPointNumber: ReportBreakPoint.RoundsbyPlayerType
		},
		{
			id: 19,
			group: ReportGroup.Instructor,
			code: ReportCode.Lesson,
			value: this.captions.lesson,
			breakPointNumber: ReportBreakPoint.Lessons
		},
		{
			id: 20,
			group: ReportGroup.Instructor,
			code: ReportCode.InstructorSchedule,
			value: this.captions.instructorSchedule,
			breakPointNumber: ReportBreakPoint.InstructorSchedules,
			enableJasperReports : true,
			reportUrlpath : JasperReportUrlPath.InstructorScheduleUrlPath
		},
		{
			id: 21,
			group: ReportGroup.TransactionLog,
			code: ReportCode.GolfTransactionLog,
			value: this.captions.transactionLog,
			breakPointNumber: ReportBreakPoint.GolfTransactionLog
		},
		{
			id: 22,
			group: ReportGroup.Statistics,
			code: ReportCode.RoundsByCustomField1,
			value: '',
			breakPointNumber: ReportBreakPoint.RoundsbyRateType
		},
		{
			id: 23,
			group: ReportGroup.Statistics,
			code: ReportCode.RoundsByCustomField2,
			value: '',
			breakPointNumber: ReportBreakPoint.RoundsbyRateType
		},
		{
			id: 24,
			group: ReportGroup.Statistics,
			code: ReportCode.RoundsByCustomField3,
			value: '',
			breakPointNumber: ReportBreakPoint.RoundsbyRateType
		},
		{
			id: 25,
			group: ReportGroup.Statistics,
			code: ReportCode.RoundsByCustomField4,
			value: '',
			breakPointNumber: ReportBreakPoint.RoundsbyRateType
		},
		{
			id: 26,
			group: ReportGroup.Statistics,
			code: ReportCode.RoundsByCustomField5,
			value: '',
			breakPointNumber: ReportBreakPoint.RoundsbyRateType
		},
		{
			id: 27,
			group: ReportGroup.Statistics,
			code: ReportCode.RoundsByRateTypeConsolidated,
			value: this.captions.roundsByRateTypeConsolidated,
			breakPointNumber: ReportBreakPoint.RoundsbyRateType
		},
		{
			id: 28,
			group: ReportGroup.TeeTime,
			code: ReportCode.CartCardsForTeeTime,
			value: this.captions.cartCards,
			breakPointNumber: ReportBreakPoint.CartCardsForTeeTime
		},
		{
			id: 29,
			group: ReportGroup.TeeTime,
			code: ReportCode.CaddyTypeAssignment,
			value:  this.captions.CaddyTypeAssignment,
			breakPointNumber: ReportBreakPoint.CaddyTypeAssignment,
			enableJasperReports : true,
			reportUrlpath : JasperReportUrlPath.CaddyTypeAssignmentUrlPath
		},
		{
			id: 30,
			group: ReportGroup.TeeTime,
			code: ReportCode.CaddyAssignment,
			value:  this.captions.CaddyAssignment,
			breakPointNumber: ReportBreakPoint.CaddyAssignment
		},
		{
			id: 31,
			group: ReportGroup.TeeTime,
			code: ReportCode.DepositsDue,
			value:  this.captions.DepositsDue,
			breakPointNumber: ReportBreakPoint.DepositsDue
		},
		{
			id: 32,
			group: ReportGroup.TeeTime,
			code: ReportCode.DepositsApplied,
			value:  this.captions.DepositsApplied,
			breakPointNumber: ReportBreakPoint.DepositsApplied
		},
		{
			id: 33,
			group: ReportGroup.TeeTime,
			code: ReportCode.OnlineTeeTime,
			value:  this.captions.OnlineTeeTime,
			breakPointNumber: ReportBreakPoint.OnlineTeeTime
		},
		{
			id: 34,
			group: ReportGroup.TeeTime,
			code: ReportCode.NegotiatedRates,
			value:  this.captions.NegotiatedRates,
			breakPointNumber: ReportBreakPoint.NegotiatedRates
		},
		{
			id: 35,
			group: ReportGroup.Statistics,
			code: ReportCode.RoundsByPlayerTypeForecast,
			value: this.captions.roundsByPlayerTypeForecast,
			breakPointNumber: ReportBreakPoint.RoundsbyPlayerType
		},
		{
			id: 36,
			group: ReportGroup.TeeTime,
			code: ReportCode.BookedBySource,
			value:  this.captions.BookedBySOurce,
			breakPointNumber: ReportBreakPoint.BookedBySource
		}
		,
		{
			id: 37,
			group: ReportGroup.TeeTime,
			code: ReportCode.TeeTimeBookedByUser,
			value:  this.captions.TeeTimeBookedByUser,
			breakPointNumber: ReportBreakPoint.TeeTimeBookedByUser
		},
		{
			id: 38,
			group: ReportGroup.TeeTime,
			code: ReportCode.ManualMarkAsPaid,
			value:  this.captions.ManualMarkAsPaid,
			breakPointNumber: ReportBreakPoint.ManualMarkAsPaid
		},
		{
			id: 39,
			group: ReportGroup.TransactionLog,
			code: ReportCode.AuditReport,
			value:  'Auto Night Audit',
			breakPointNumber: ReportBreakPoint.GolfTransactionLog
		},
		{
			id: 40,
			group: ReportGroup.TransactionLog,
			code: ReportCode.AllocationBlockHistory,
			value:  this.captions.AllocationBlockHistory,
			breakPointNumber: ReportBreakPoint.AllocationBlockHistory
		},
		{
			id: 41,
			group: ReportGroup.Statistics,
			code: ReportCode.RetailItemsUtilization,
			value:  this._retailFeatureFlag !=undefined && this._retailFeatureFlag.IsRentalEnabled ? this.captions.RentalUtilization : this.captions.RetailUtilization,
			breakPointNumber: ReportBreakPoint.RentalUtilization
		}
	];

	public getReportSelections(group: any): ReportSelector_[] {
		if (typeof (group) === "string") {
			return this.reportSelector.filter((o) => o.code === group);
		}
		else{
			if(this.localize.isFromJobScheduler){
				return this.reportSelector.filter((o) => o.group === group - 1);				
			}else{
				return this.reportSelector.filter((o) => o.group === group);
			}
		}
	}

	public getAllReportSelections():ReportSelector_[]{
		return this.reportSelector;
	}

	public GetJasperBagTagsFilePath()
	{
		const config = sessionStorage.getItem('propConfig');
        let propertyConfig = config && JSON.parse(config);
		if(propertyConfig != null && propertyConfig['JasperBagTagsFilePath'] != null)
		{
			this.JasperBagTagsFilePath = propertyConfig['JasperBagTagsFilePath'];
		}
		return this.JasperBagTagsFilePath;
	}
}
