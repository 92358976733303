<section class="h-100 d-flex templates-section" *ngIf="hideCrudWindow && hideCrudSmsWindow && hideCrudReportWindow" [formGroup]="templateForm">
  
  <div [ngSwitch]="selectedCategory" class="template-definition">
    

    <app-template-email *ngSwitchCase="1" [showTableRecord]="showTableRecords" [isCopyEnabled]="isCopyEnabled" [istoggleDirectEmit]="istoggleDirectEmit" (emittedEmaiTempldata)=crudemailTemplateEmitter($event) (copyEmailEventEmitter)="copyEmailfunc($event)" (toggleActiveEmit)="toggleEvent($event)"></app-template-email>
    <app-template-sms *ngSwitchCase="2" [showTableRecord]="showTableRecords" [isCopyEnabled]="isCopyEnabled" [istoggleDirectEmit]="istoggleDirectEmit" (emittedSmsTempldata)=crudsmsTemplateEmitter($event) (copySmsEventEmitter)="copySmsfunc($event)"></app-template-sms>
    <app-template-report *ngSwitchCase="3" [showTableRecord]="showTableRecords" [isCopyEnabled]="isCopyEnabled" [istoggleDirectEmit]="istoggleDirectEmit" (emittedReportTempldata)=crudreportTemplateEmitter($event) (copyReportEventEmitter)="copyReportfunc($event)"></app-template-report>
  </div>
</section>

<!-- Crud Email Template Screen-->
<ng-container *ngIf="!hideCrudWindow">
  <app-crud-email-template [showTableRecord]="showTableRecords" [isCopyInput]="isEmailCopyInputs" [editableObj]="editEmailObj" [isViewOnly]="isViewOnly"  (emittedEmaildata)="getEmitedEmailData($event)"></app-crud-email-template>
</ng-container>

<!-- Crud SMS Template Screen-->
<ng-container *ngIf="!hideCrudSmsWindow">
  <app-crud-sms-template [showTableRecord]="showTableRecords" [isCopyInput]="isSmsCopyInputs" [editableObj]="editSmsObj" [isViewOnly]="isViewOnly" (emittedSmsdata)="getEmitedSmsData($event)"></app-crud-sms-template>
</ng-container>

<!-- Crud Report Template Screen-->
<ng-container *ngIf="!hideCrudReportWindow">
  <app-crud-report-template [showTableRecord]="showTableRecords" [isCopyInput]="isEmailCopyInputs" [editableObj]="editReportObj" [isViewOnly]="isViewOnly" (emittedReportdata)="getEmitedReportData($event)"></app-crud-report-template>
</ng-container>
