import { HandleRetailEvents } from "../handle-retailevent";
import { Injectable } from "@angular/core";
import { RetailEventParameters} from "../../../retail/shared/events/event.model";
import { FromAction } from "../../global.constant";

@Injectable({
    providedIn: "root"
})
export class SubscriptionHandler {
    constructor(private _handleRetailEvents: HandleRetailEvents) { }

    public async handleVoid(eventParams: RetailEventParameters<any>) {
        this._handleRetailEvents.VoidPlayerTransactions(eventParams);
    }

    public async handleCorrect(eventParams: RetailEventParameters<any>) {
        this._handleRetailEvents.CorrectPlayerTransactions(eventParams);
    }

    public async handleDeposit(eventParams: RetailEventParameters<any>) {
        this._handleRetailEvents.UpdateTeeTimePlayerDeposit(eventParams);
    }

    public async handleCancel(eventParams: RetailEventParameters<any>) {
        this._handleRetailEvents.CancelPlayerTransactions(eventParams);
    }

    public async handleSyncUp(eventParams: RetailEventParameters<any>) {
        this._handleRetailEvents.SyncUpTransactions();
    }

    public async handleTeeTimeRetailItem(eventParams: RetailEventParameters<any>){
        this._handleRetailEvents.AddRetailItemToTeeTime(eventParams);
    }
    public async handledTeeTimeCancellation(eventParams: RetailEventParameters<any>){
        this._handleRetailEvents.TeeTimeCancellation(eventParams, FromAction.Cancellation);
    }
    public async handledTeeTimeNoShow(eventParams: RetailEventParameters<any>){
        this._handleRetailEvents.TeeTimeNoShow(eventParams,FromAction.NoShow,[]);
    }
    public async handledTeeTimePendingSettlement(eventParams: RetailEventParameters<any>){
        this._handleRetailEvents.TeeTimePendingSettlement(eventParams);
    }
}