<app-dialog-header [title]="captions.lbl_holdCart" (closeDialog)="close()"></app-dialog-header>

<div class="cart-timer-wrapper ag_container--padding-md">
   <form [formGroup]="cartForm">
    <div class="ag_display--flex-wrap">
        <!-- <ag-input  formControlName="days"  inputtype='nonnegative' type='NUMBER' [placeholder]="captions.lbl_days"
         class="ag_display--inblock ag_pr--3"  ></ag-input>
        <ag-input  formControlName="hours" [max]="23"  inputtype='nonnegative' type='NUMBER' [placeholder]="captions.lbl_hours"
         class="ag_display--inblock ag_pr--3"  ></ag-input> -->
        <ag-input  formControlName="setminutes" [min]="1" [max]="maxHoldtime"  inputtype='nonnegative' type='NUMBER' [placeholder]="captions.lbl_holdmins"
        class="ag_display--inblock ag_pr--3"  ></ag-input>
    </div>  
 
   </form>
</div>

<app-dialog-footer [buttonObj]="buttonObj" (cancel)="close()" (save)="save($event)"></app-dialog-footer>