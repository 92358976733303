<ng-container [formGroup]="contactForm" *ngIf="arrayName; else singleEmail">
  <div [formArrayName]="arrayName" class="ag_position--relative"
    *ngFor="let email of contactForm.get(arrayName)['controls']; let i= index; let first = first">
    <div [formGroupName]="i" class="mail-wrapper">
      <div class="fixed-width--wrapper">
        <mat-form-field [floatLabel]="floatLabel" [ngClass]="optionClass"   (click)="onclick()">
          <mat-select [placeholder]="placeHolder"  [attr.LiteralID]="placeHolderId" id="email{{i}}" [formControlName]="typeControlName"
            (selectionChange)="typeChange($event, email)" [required]='isEmailRequired' attr.automationId='Dd_{{automationId}}_typeControlName{{i}}'>
            <mat-option></mat-option>
            <mat-option *ngFor="let emailtype of options" [value]="emailtype.id">
              {{emailtype.description}}
            </mat-option>
          </mat-select>
          <mat-error class="Textnowrap" *ngIf="email.get(typeControlName).hasError('required')" [attr.LiteralID]="'typeControlName'" >
            {{requiredErrorMessage}}
         </mat-error>
        </mat-form-field>
        <mat-form-field [floatLabel]="floatLabel" [ngClass]="customClass">
          <input matInput [name]="controlName" [formControlName]="controlName" inputtype="email" [duplicateCheck]="controlName"
            [currentIndex]="i" [maxlength]="maxLength" [placeholder]="emailPlaceHolder" [required]='isEmailRequired'  attr.automationId='Txt_{{automationId}}_controlName{{i}}'>
          <mat-error *ngIf="isEmailRequired && email.get(controlName).hasError('required')">
              {{requiredErrorMessage}}
            </mat-error>
          <mat-error *ngIf="email.get(controlName).hasError('incorrect')"   [attr.LiteralID]="errorMessageId">
            {{errorMessage}}
          </mat-error>
          <mat-error *ngIf="email.get(controlName).hasError('duplicate')">
            {{duplicateError}}
          </mat-error>
        </mat-form-field>
        <span class="icon-plus"  [ngClass]="{'ag_section--disable':isEmailDisabled}" (click)="addEmailItem(i,defaultMailTypeValue,'')" *ngIf="allowMultiple && first" 
        attr.automationId='Icn_{{automationId}}_plus{{i}}'></span>
        <span class="icon-minus" [ngClass]="{'ag_section--disable':isEmailDisabled}" (click)="removeEmailItem(i)" *ngIf="allowMultiple && !first"
        attr.automationId='Icn_{{automationId}}_minus{{i}}'></span>
        <!-- <ng-container *ngIf="showSwitches">
          <div class="ag-mail-switch primary-info-toggle ag_display--inblock">
            <label class="ag_form--label ag_common-label-width">{{primaryLabel}}</label>
            <mat-slide-toggle [formControlName]="primarySwitchName" disableRipple="true" (change)="togglePrimaryContact(i)"></mat-slide-toggle>
          </div>
          <div class="ag_pl--5 private-info-toggle ag_display--inblock">
            <label class="ag_form--label ag_common-label-width">{{privateLabel}}</label>
            <mat-slide-toggle [formControlName]="privateSwitchName" disableRipple="true" (change)="togglePrivateContact(i)"></mat-slide-toggle>
          </div>
        </ng-container> -->
      </div>
      <ng-container *ngIf="showSwitches">
        <div class="ag-mail-switch primary-info-toggle"  attr.automationId='Tog_{{automationId}}_primarySwitchName{{i}}'>
          <label class="ag_form--label ag_common-label-width">{{primaryLabel}}</label>
          <mat-slide-toggle [formControlName]="primarySwitchName" disableRipple="true" [disabled]="isOnlyOneContact" (change)="togglePrimaryContact(i)"></mat-slide-toggle>
        </div>
        <div class="ag_pl--5 private-info-toggle"  attr.automationId='Tog_{{automationId}}_privateSwitchName{{i}}'>
          <label class="ag_form--label ag_common-label-width">{{privateLabel}}</label>
          <mat-slide-toggle [formControlName]="privateSwitchName" disableRipple="true" (change)="togglePrivateContact(i)"></mat-slide-toggle>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #singleEmail >
  <ng-container [formGroup]="contactForm">
    <mat-form-field [floatLabel]="floatLabel" [ngClass]="optionClass"   (click)="onclick()">
      <mat-select [placeholder]="placeHolder" [formControlName]="typeControlName" attr.automationId='Dd_{{automationId}}_typeControlName'
        (selectionChange)="typeChange($event, contactForm)"  [attr.LiteralID]="placeHolderId">
        <mat-option></mat-option>
        <mat-option *ngFor="let emailtype of options" [value]="emailtype.id">
          {{emailtype.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field [floatLabel]="floatLabel" [ngClass]="customClass">
      <input matInput [name]="controlName" [formControlName]="controlName" inputtype="email" [maxlength]="maxLength"
      attr.automationId='Dd_{{automationId}}_controlName'>
      <mat-error *ngIf="contactForm.get(controlName).hasError('incorrect')" [attr.LiteralID]="errorMessageId">
        {{errorMessage}}
      </mat-error>
    </mat-form-field>
  </ng-container>
</ng-template>