import { Injectable } from '@angular/core';
import * as CreateWaitlistModalInterface from './create-waitlist-modal';
import { GolfLocalization } from '../../../core/localization/golf-localization';
import { GolfScheduleCommunication } from 'src/app/shared/communication/services/golfschedule.service';
import { WaitList } from '../waitlist.model';

@Injectable()
export class CreateWaitlistModalService {

  captions: CreateWaitlistModalInterface.Captions;
  radioArray: CreateWaitlistModalInterface.RadioArray;
  contactPhoneType: CreateWaitlistModalInterface.ContactType[];
  contactEmailType: CreateWaitlistModalInterface.ContactType[];
  playerType: CreateWaitlistModalInterface.playerType[];
  rateType: CreateWaitlistModalInterface.rateType[];
  course: CreateWaitlistModalInterface.course[];

  constructor(private _Localization: GolfLocalization, private _http: GolfScheduleCommunication) { }

  captionsGenerator() {
    this.captions = this._Localization.captions.teetime;
    return this.captions;
  }

  playerCaptionsGenerator() {
    return this._Localization.captions.guest.players;
  }  

  getPlayerType() {
    return this.playerType = [
      { id: 0, description: 'playerType 1', type: "playerType" },
      { id: 1, description: 'playerType 2', type: "playerType" }
    ];
  }
  getRateType() {
    return this.rateType = [
      { id: 0, description: 'rateType 1', type: "rateType" },
      { id: 1, description: 'rateType 2', type: "rateType" }

    ];
  }
  getCourse() {
    return this.course = [
      { id: 0, description: 'course 1', type: "course" },
      { id: 1, description: 'course 2', type: "course" }

    ];
  }

  public maskPhoneNo(): string {
    return this._Localization.captions.common.PhoneFormat != '' ? this._Localization.captions.common.PhoneFormat : '9999';
  }

public CreateWaitList(value: WaitList): Promise<boolean> {
  return this._http.postPromise({
      route: GolfApiRoute.CreateWaitList, body: value
  }, false); 
}

  public UpdateWaitList(value: WaitList): Promise<CreateWaitlistModalInterface.WaitlistResponse> {
    return this._http.putPromise({
      route: GolfApiRoute.UpdateWaitList, body: value
    }, false);    
  }
}

