import { Injectable } from '@angular/core';
import _ from 'lodash';
import { AlertType, ButtonType } from 'src/app/shared/shared-models';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { SlotDetails, TeeTimeBulkAction } from 'src/app/tee-time/tee-sheet/hold-tee-time/hold-tee-time.model';
import { HoldTeeTimeService } from 'src/app/tee-time/tee-sheet/hold-tee-time/hold-tee-time.service';
@Injectable()
export class HoldConfirmationTeeTimeBusiness {

  
  constructor(private _localization: GolfLocalization, private _bulkService: HoldTeeTimeService,
    private _utils: GolfUtilities) {
  }

  slotDetail: SlotDetails[];
  untillHoles: any[];
  async GetUntills(courseId, start: Date): Promise<DropdownOptions[]> {
    const startDate = this._localization.convertDateObjToAPIdate(this._localization.getDate(start));
    const  val = await this._bulkService.GetScheduledTeeTimesSlotDetails(courseId, startDate, startDate);
    const  remainingEmptySlots = val.filter((value) => this._localization.getDate(value.scheduleDateTime).getTime() >= start.getTime() && value.isAvailableForHold);
    this.slotDetail = _.cloneDeep(remainingEmptySlots);
    this.untillHoles = remainingEmptySlots.map(item => item.holeNumber)
    .filter((value, index, self) => self.indexOf(value) === index);

    const gr = remainingEmptySlots.map(item => item.scheduleDateTime)
      .filter((value, index, self) => self.indexOf(value) === index).map(a => {
        return <DropdownOptions>{
          value: a,
          viewValue: a.toString(),
        }
      });
    gr.unshift({
      value: '',
      viewValue: ''
    });
    return (gr);
  }

  async saveBulkHoldTeeTime(holdTeeTimeForm, selectedScheduleDate, selectedSlotInfo) {
    let result = true;
    let data = [];
    if (!holdTeeTimeForm.untill) {
      const teeSlot = {} as TeeTimeBulkAction;
      teeSlot.courseId = selectedSlotInfo.course.id,
        teeSlot.holeNumber = selectedSlotInfo.hole,
        teeSlot.originalHoleNumber = selectedSlotInfo.originalHoleNumber,
        teeSlot.scheduledDateTime = selectedSlotInfo.time,
        teeSlot.comment = holdTeeTimeForm.comment
      data.push(teeSlot);
    }
    else {
      data = this.mapTeeTimeBulk(selectedScheduleDate, holdTeeTimeForm);
    }
    if (data.length > 0) {
      await this._bulkService.saveBulkHoldTeeTime(data).then(r => { result = true }).catch(async e => {
        let errorMsg = '';
        if (e.error && e.error.errorCode && e.error.errorCode) {
          errorMsg = this._localization.getError(e.error.errorCode);
          errorMsg = e.error.errorDescription ? errorMsg.interpolate({ user: e.error.errorDescription }) : "";
        } else {
          errorMsg = this._localization.getError(11014);
        }
        let  res = this._utils.showAlert(errorMsg, AlertType.Error, ButtonType.Ok);
        await res.afterClosed().toPromise();
        result = false;
      });;
    }
    return result;
  }

  mapTeeTimeBulk(selectedScheduleDate, holdTeeTimeForm) {
    let data = [];
    const untillDate = this._localization.getDate(holdTeeTimeForm.untill);
    let selectedRangeSlots = [];
    this.slotDetail.forEach(f => {
      const slotDate = this._localization.getDate(f.scheduleDateTime).getTime();
      if (slotDate >= selectedScheduleDate.getTime() && slotDate <= untillDate.getTime() 
      //&& (!holdTeeTimeForm.untillHole || holdTeeTimeForm.untillHole.includes(f.holeNumber))
      ) {
        selectedRangeSlots.push(f);
      }
    });
    selectedRangeSlots.forEach(e => {
      const teeSlot = {} as TeeTimeBulkAction;
      teeSlot.courseId = e.courseId,
        teeSlot.holeNumber = e.holeNumber,
        teeSlot.originalHoleNumber = e.originalHoleNumber,
        teeSlot.scheduledDateTime = e.scheduleDateTime,
        teeSlot.comment = holdTeeTimeForm.comment
      data.push(teeSlot);
    });
    return data;
  }
}