import { Injectable } from '@angular/core';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';
import { SqueezeAPIData } from 'src/app/tee-time/tee-time-model';
import { PlayerTeeTimeSlot, CancelPlayers } from 'src/app/tee-time-actions/move-player-modal/move-player-modal.model';
import { CopyMoveResult, TeeTimeAction, ValidationResult } from '../../models/teesheet.api.models';
import { ReinstatePlayerAndSlotDetail, ReinstateResult, ReinstatePlayer } from 'src/app/tee-time-actions/reinstate-modal/reinstate-player-model';
import { PlayerStayDetail, TeeTimeModel, TeeTimeTempHold, TeeTimeTempHoldResult } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { GolfGatewayCommunication } from '../../communication/services/golfGateway';
import { ScheduledPlayer } from '../../models/teesheet.form.models';
import { PlayerTransactionDetail, TeeTimeAllocationBlock } from '../../shared-models';
import { GolfManagementCommunication } from '../../communication/services/golfmanagement.service';
import { CopyBulkTeeTimeConfig } from 'src/app/tee-time-actions/copy-player-modal/copy-player-modal.model';
import { TenantManagementCommunication } from 'src/app/common/communication/services/tenantmanagement-communication-service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { UserModel } from '../../models/tenant.models';
import { UserAccessModel } from '../authentication/useraccess-model.model';
import { UserAccessBusiness } from '../authentication/useraccess.business';
import { PlayerRetailItem,ScheduledTeeTimePlayerRetailItem } from 'src/app/tee-time-actions/edit-retail-item/edit-retail-item-model'
import { TeeTimeAllocationBlockEvent } from 'src/app/tee-time/search/search-model';
import { RetailRoutes } from 'src/app/retail/retail-route';
import { RetailPosCommunication } from 'src/app/shared/communication/services/retailpos.service';
import { CancellationNoShowLedger } from 'src/app/retail/shared/shared.modal';

@Injectable({
    providedIn: 'root'
})

export class TeeTimesActionService {

  constructor(private _http: GolfScheduleCommunication, private _httpGolfGateWayCommunication: GolfGatewayCommunication,
    private _httpGolfMgmt: GolfManagementCommunication, private _tenantManagementCommunication: TenantManagementCommunication,
    private _localization: GolfLocalization, public _userAccessBusiness: UserAccessBusiness,private _retailPOSCommunication: RetailPosCommunication,) {
  }


  public holdTeeTime(courseId: number, scheduledDateTime: string, holeNumber: string, originalHoleNumber: string): Promise<boolean> {
    return this._http.putPromise({
      route: GolfApiRoute.HoldTeeTime, uriParams: {
        "courseId": courseId, "scheduledDateTime": scheduledDateTime, "holeNumber": holeNumber,
        "originalHoleNumber": originalHoleNumber
      }
    });
  }

  public releaseTeeTime(courseId: number, scheduledDateTime: string, holeNumber: string): Promise<boolean> {
    return this._http.putPromise({
      route: GolfApiRoute.ReleaseTeeTime, uriParams: { "courseId": courseId, "scheduledDateTime": scheduledDateTime, "holeNumber": holeNumber }
    });
  }

  public squeezeTeeTime(squeezeTeeTime: SqueezeAPIData): Promise<number> {
    return this._http.postPromise({
      route: GolfApiRoute.SqueezeTeeTime, body: squeezeTeeTime
    });
  }

  public updateTeeTime(teeTimeModel: TeeTimeModel): Promise<boolean> {
    return this._http.putPromise({
      route: GolfApiRoute.UpdateTeeTimeSlots, body: teeTimeModel
    });
  }

  public async saveTeeTimeAllocationBlock(body: TeeTimeAllocationBlock[], teeTimeAllocationBlockEvent: TeeTimeAllocationBlockEvent = TeeTimeAllocationBlockEvent.None) {
    try {
      return await this._httpGolfMgmt.postPromise<TeeTimeAllocationBlock[]>(
        { route: GolfApiRoute.SaveTeeTimeAllocationBlocks, body: body, uriParams: { teeTimeAllocationBlockEvent: teeTimeAllocationBlockEvent } }, true);
    } catch (error) {
      console.error(error);
    }
  }


  public copyMoveTeeTime(scheduleId: number, body: PlayerTeeTimeSlot[], teeTimeAction: TeeTimeAction): Promise<CopyMoveResult[]> {
    return this._http.putPromise<CopyMoveResult[]>(
      { route: teeTimeAction == TeeTimeAction.move ? GolfApiRoute.MoveTeeTime : GolfApiRoute.CopyTeeTime, uriParams: { "scheduleId": scheduleId }, body: body });
  }

  public bulkCopyTeeTime(body: CopyBulkTeeTimeConfig, teeAction: TeeTimeAction): Promise<CopyMoveResult[]> {
    return this._http.putPromise<CopyMoveResult[]>(
      { route: teeAction == TeeTimeAction.bulkMove ? GolfApiRoute.BulkMoveTeeTime : GolfApiRoute.BulkCopyTeeTime, body: body });
  }

  public bulkMoveTeeTime(body: PlayerTeeTimeSlot[], teeAction: TeeTimeAction): Promise<CopyMoveResult[]> {
    return this._http.putPromise<CopyMoveResult[]>(
      { route: teeAction == TeeTimeAction.bulkMove ? GolfApiRoute.BulkMoveTeeTime : GolfApiRoute.BulkCopyTeeTime, body: body });
  }

  public validateTeeTimeAction<T>(teeAction: TeeTimeAction, scheduleId: number, body: PlayerTeeTimeSlot[]): Promise<ValidationResult<T>> {
    return this._http.putPromise<ValidationResult<T>>({
      route: GolfApiRoute.ValidateTeeTimeAction, uriParams: { teeAction: teeAction, scheduleId: scheduleId }, body: body
    });
  }

  public BulkTeeTimeActionValidate<T>(teeAction: TeeTimeAction, body: PlayerTeeTimeSlot[]): Promise<ValidationResult<T>> {
    return this._http.putPromise<ValidationResult<T>>({
      route: GolfApiRoute.ValidateBulkTeeTimeAction, uriParams: { teeAction: teeAction }, body: body
    });
  }

  public frostDelayTeeTime(courseId: number, scheduledDateTime: string, holeNumber: string): Promise<boolean> {
    return this._http.putPromise({
      route: GolfApiRoute.frostDelayTeeTime, uriParams: { "courseId": courseId, "scheduledDateTime": scheduledDateTime, "holeNumber": holeNumber }
    });
  }

  public cancelTeeTimePlayers( body: CancelPlayers): Promise<boolean> {
    return this._http.putPromise<boolean>({
      route: GolfApiRoute.BulkCancelTeeTimePlayers,
      body: body
    });
  }
  public clearTeeTimePlayers(scheduleId: number, playerIds: number[]): Promise<boolean> {
    return this._http.putPromise<boolean>({
      route: GolfApiRoute.ClearPlayerTeeTime,
      uriParams: { scheduleId: scheduleId },
      body: playerIds
    });
  }
  public deleteTeeTimePlayers(scheduleId: number, playerIds: number[]): Promise<boolean> {
    return this._http.putPromise<boolean>({
      route: GolfApiRoute.ClearPlayerTeeTime,
      uriParams: { scheduleId: scheduleId },
      body: playerIds
    });
  }

  public async deleteRentalItems(body: any): Promise<boolean> {
    return this._retailPOSCommunication.postPromise<boolean>({
      route: RetailRoutes.removeRentalItemPurchase,
      body: body
    });
  }
  public checkInOutStatus(action: string, arrPlayerId: number[]): Promise<boolean> {
    return this._http.postPromise<boolean>({
      route: GolfApiRoute.UpdateCheckInOutStatus,
      uriParams: { teeTimeAction: action },
      body: arrPlayerId
    });
  }
  public reinstateCancelledTeeTimePlayers(body: ReinstatePlayerAndSlotDetail, callback?: any): Promise<ReinstateResult[]> {
    return this._http.putPromise<ReinstateResult[]>({ route: GolfApiRoute.ReinstatePlayers, body: body }, true, (err) => {
      /** callback to perform action after error catch */
      if (callback && typeof (callback) == 'function') callback(err);
    });
  }

  public noShowTeeTimePlayer(scheduleId: number, playerId: number): Promise<boolean> {
    return this._http.putPromise({
      route: GolfApiRoute.NoShowTeeTimePlayer, uriParams: { "scheduleId": scheduleId, "playerId": playerId }
    });
  }

  public reBookTeeTimePlayer(scheduleId: number, playerId: number): Promise<boolean> {
    return this._http.putPromise({
      route: GolfApiRoute.ReBookTeeTimePlayer, uriParams: { "scheduleId": scheduleId, "playerId": playerId }
    });
  }
  public GetPlayerStayDetail(playerLinkId: string, scheduleDateTime: Date | string, confirmationNumber: any, activityId:string=""): Promise<PlayerStayDetail> {
    return this._httpGolfGateWayCommunication.getPromise({
      route: GolfApiRoute.GetPlayerStayDetail, uriParams: { "guestId": playerLinkId, "scheduleDateTime": scheduleDateTime, "confirmationNumber": confirmationNumber , "activityId": activityId}
    });
  }
  public GetConfirmationNo(): Promise<string> {
    return this._httpGolfGateWayCommunication.getPromise({
      route: GolfApiRoute.GetConfirmationNo
    });
  }

  public getConfirmationNumberByPlayerIds(playerIds: number[]): Promise<string[]> {
    return this._httpGolfGateWayCommunication.putPromise({
      route: GolfApiRoute.GetConfirmationNumberByPlayerIds, body: playerIds
    });
  }
  public getReinstatePlayers(playerIds: number[]): Promise<string[]> {
    return this._httpGolfGateWayCommunication.putPromise({
      route: GolfApiRoute.GetConfirmationNumberByPlayerIds, body: playerIds
    });
  }

  public async getScheduledPlayers(scheduleId: number): Promise<ScheduledPlayer[]> {
    return await this._http.getPromise<ScheduledPlayer[]>(
      {
        route: GolfApiRoute.GetScheduledTeeTimePlayersByScheduledId,
        uriParams: { scheduleId: scheduleId }
      });
  }

  public async getCancelTeeTimePlayers(courseId: number, bookedDateTime: string): Promise<ReinstatePlayer[]> {
    return await this._http.getPromise<ReinstatePlayer[]>(
      {
        route: GolfApiRoute.GetCancelTeeTimePlayers,
        uriParams: { courseId: courseId, bookedDateTime: bookedDateTime }
      });
  }

  public async TempHoldPlayers(playerIds: number[]): Promise<boolean> {
    return await this._http.postPromiseHandleValidation<boolean>({
      route: GolfApiRoute.PayPlayersTempHold,
      body: playerIds
    });
  }

  public async releasePlayersInHold(playerIds: number[]): Promise<boolean> {
    return await this._http.putPromise<boolean>({
      route: GolfApiRoute.ReleasePlayerTempHold,
      body: playerIds
    });
  }

  public async GetTempHoldPlayers(playerIds: number[]): Promise<TeeTimeTempHoldResult[]> {
    var tempHoldData = await this._http.putPromise<TeeTimeTempHoldResult[]>({
      route: GolfApiRoute.CheckPlayersTempHold,
      body: playerIds
    });
    if (tempHoldData.length > 0) {
      tempHoldData = await this.FillUserData(tempHoldData);
    }
    return tempHoldData;
  }

  private async FillUserData(tempHoldData: TeeTimeTempHoldResult[]) {
    let tenantId: number = Number(this._localization.GetPropertyInfo("TenantId"));
    tenantId = tenantId ? tenantId : 1;
    var users: UserModel[] = await this._tenantManagementCommunication.getPromise({
      route: GolfApiRoute.GetAllUsers,
      uriParams: { tenantId: tenantId }
    });
    tempHoldData.forEach(u => {
      u.userName = users.find(uo => uo.userId == u.createdBy).userName;
    });
    return tempHoldData;
  }

  async ValidateBreakPoint(breakPointNumber: number, showPopup: boolean): Promise<UserAccessModel.BreakPointResult> {
    return await this._userAccessBusiness.getUserAccess(breakPointNumber, showPopup);
  }
  public showBreakPointError(breakPointNumber: number)
  {
    this._userAccessBusiness.showBreakPointError(breakPointNumber);
  }
  public async getPlayerRetailItems(scheduledTeeTimePlayerIds: number[]): Promise<PlayerRetailItem[]> {
    return this._http.postPromise<PlayerRetailItem[]>(
            { route: GolfApiRoute.GetPlayerRetailItemsForPlayers,
            body: scheduledTeeTimePlayerIds});
     
  }

  public async getScheduledPlayerRetailItems(scheduledTeeTimePlayerIds: number[]): Promise<ScheduledTeeTimePlayerRetailItem[]> {
    return this._http.postPromise<ScheduledTeeTimePlayerRetailItem[]>(
            { route: GolfApiRoute.GetScheduledTeeTimePlayerRetailItemsForPlayers,
            body: scheduledTeeTimePlayerIds});  
  }

  public async getScheduledPlayerRetailItemsByActivityId(activityIds: string[]): Promise<ScheduledTeeTimePlayerRetailItem[]> {
    return this._http.postPromise<ScheduledTeeTimePlayerRetailItem[]>(
            { route: GolfApiRoute.GetScheduledTeeTimePlayerRetailItemsForPlayersByActivityId,
            body: activityIds});  
  }

  public CreateCancellationNoShowLedger(cancellationNoShowLedger: CancellationNoShowLedger[]) : Promise<any> {
    return this._http.postPromise<any>({
        route: GolfApiRoute.CreateCancellationNoShowLedger,
        body: cancellationNoShowLedger
    });
  }
  public async UpdatePlayerStatusAsNoShowReBook(_dataForNoShow,isNoshowAction : boolean): Promise<any> {
    let result = null;
    if(isNoshowAction){  
      result = await this._http.putPromise<PlayerTransactionDetail[]>({
        route: GolfApiRoute.NoShowTeeTimePlayerForMultiple,
        body: _dataForNoShow
      });
      return {successOperation : result ? true : false};
    }
      result = await this._http.putPromise<PlayerTransactionDetail[]>({
        route: GolfApiRoute.ReBookTeeTimePlayerForMultiple,
        body: _dataForNoShow
      });
    
    return {successOperation : result ? true : false};
  }
}


