<div class="audit-trail-wrapper golf-container--padding">
    <div class="players-list" *ngIf = "showHeader">
        <div attr.automationId='Lbl_AuditTrail_SelectPosition{{position?.id}}' *ngFor="let position of positions" class="players-list--item"
        [ngClass]="selectedPosition && selectedPosition.id==position.id ? 'clicked': ''"
        (click)="onPositionClick(position);">
            <label *ngIf = "position.firstName!='' && position.lastName != ''" class="player-name mt-2" matTooltip="{{'(' +'P'+position.id + ') ' }}{{ position | formatName: 'firstName': 'lastName' }}">{{'(' +'P'+position.id + ') ' }}{{ position | formatName: 'firstName': 'lastName'}}</label>
            <label *ngIf = "position.firstName =='' && position.lastName == ''" class="player-name mt-2" matTooltip="{{'(' +'P'+position.id + ')'}}">{{'('+'P'+position.id+ ')'}}</label>
            <label *ngIf = "position.firstName=='BLOCKED' && position.firstName!='' && position.lastName == ''" class="player-name mt-2" matTooltip="{{'(' +'P'+position.id + ') ' + position.firstName }}">{{'('+'P'+position.id+ ')' + position.firstName}}</label>
            <label *ngIf = "position.firstName!='' && position.lastName == ''" class="player-name mt-2" matTooltip="{{'(' +'P'+position.id + ') ' + position.firstName }}">{{'('+'P'+position.id+ ')' + position.firstName}}</label>

        </div>
        <!-- <div *ngFor="let player of playersList" class="players-list--item" [ngClass]="selectedPlayer && selectedPlayer.playerId == player.playerId ? 'clicked': ''" (click)="onPlayerClick(player);">
            <label *ngIf="player.firstName!='BLOCKED'" class="player-name mt-2" matTooltip="{{player | formatName: 'firstName': 'lastName' }}">{{player | formatName: 'firstName': 'lastName'}}</label>
            <label *ngIf="player.firstName=='BLOCKED'" class="player-name mt-2" matTooltip="{{ player.firstName }}">{{player.firstName}}</label>
        
        </div> -->
    </div>
    <div class="player-details mt-3" *ngIf="playerData !=null && showDetails && showHeader">
        <div class="player-info--details ml-3">
            <label>{{captions.tbl_lbl_playerName}}</label>
        <div class="ag_display--flex">
            <label class="player-info-values name-sect" matTooltip="{{playerData | formatName: 'firstName': 'lastName' }}">
                {{playerData | formatName: 'firstName': 'lastName'}}
            </label>
            <span *ngIf="playerData.gender== 1" class="icon-male-staff ag-ml-2"></span>
            <span *ngIf="playerData.gender== 2" class="icon-female-staff ag-ml-2"></span>
        </div>
        </div>
        <div class="player-info--details">
            <label class="">{{captions.lbl_email}}</label>
            <label class="player-info-values" matTooltip="{{playerData.mailId}}">{{playerData.mailId}}</label>
        </div>
        <div class="player-info--details">
            <label class="">{{captions.lbl_phone}}</label>
            <label class="player-info-values" matTooltip="{{playerData.phone}}">{{playerData.phone}}</label>
        </div>
        <div class="player-info--details">
            <label class="">{{captions.lbl_playerComment}}</label>
            <label class="player-info-values" matTooltip="{{playerData.playerComment}}">{{playerData.playerComment}}</label>
        </div>
         
    </div>
    <div class="padding-align" *ngIf="initializeTableOption; else noData">
        <label class='ag_font--bold'>{{this.captions.lbl_groupByDate}}</label>
        <mat-radio-group class='golf__radio d-block' [attr.automationId]="'Rdo_AuditTrail_mode'">
            <mat-radio-button class="golf--form-radio-button" [checked]="opt.checked" [value]='opt.value'
                *ngFor="let opt of modeOptions" (change)="modeChange($event)">
                {{opt.viewValue}}
            </mat-radio-button>
        </mat-radio-group>

        <div class="group-by-user--outerwrapper" *ngIf="isGroupbyUser">
            <div *ngFor="let grouped of groupByUser;let i=index" class="group-by-user--innerwrapper ag_mb--4">
                <label class="Table-header-bg">{{groupHeader[i]?.userName | uppercase}} - {{groupHeader[i]?.logDate}}</label>
                <div class="tableHeight">
                    <app-cdkvirtual attr.automationId='Tbl_AuditTrail_AuditTrailTable{{i}}' [options]="groupByUserTableOptions[i]" [headerOptions]="groupByUserheaderOptions"  [tableContent]="grouped" [childTemplate]="childTemplate">
                    </app-cdkvirtual>
                </div>
            </div>
        </div>

        <div class="group-by-user--outerwrapper" *ngIf="!isGroupbyUser">
            <div *ngFor="let grouped of groupByDateArr;let i=index" class="group-by-user--innerwrapper ag_mb--4">
                <label class="Table-header-bg">{{dateGroupHeader[i]?.logDate}}</label>
                <div class="tableHeight">
                    <app-cdkvirtual attr.automationId='Tbl_AuditTrail_AuditTrailTable{{i}}' [options]="groupByDateTableOptions[i]" [headerOptions]="headerOptions"  [tableContent]="grouped" [childTemplate]="childTemplate">
                    </app-cdkvirtual>
                </div>
            </div>
        </div>
    </div>

    <ng-template #childTemplate let-element="element" let-key="key" let-data="data" let-row="row" let-index="idx">
        <ng-container [ngSwitch]="key">
          <ng-container *ngSwitchCase="'description'" [ngClass]="element.expanded ? '' : 'customClass'">
            <span class="text-wrap" [matTooltip]="element.description">{{element.description}}</span>&nbsp;
            <a attr.automationId='Lbl_AuditTrail_showMore' href="javascript:void(0)" (click)="showMore(element)" *ngIf="element.expanded && element.comments!=''">{{captions.lbl_showMore}}</a>
            <a attr.automationId='Lbl_AuditTrail_showLess' href="javascript:void(0)" (click)="showMore(element)" *ngIf="!element.expanded && element.comments!=''"> {{captions.lbl_showLess}}</a>
                <ng-container *ngIf="!element.expanded && element.comments!=''">
                    <div class="text-wrap" [innerHtml]="element.comments"></div>
                </ng-container>
          </ng-container>
        </ng-container>
    </ng-template>
    <ng-template #noData>
        <div *ngIf="tableContent" class="norecords-alignment">
            <div class='icon-container'>
                <div class="icon-img">
                    <span class="icon-No-Search-Results"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span></span>
                    <div class="icon-text">
                        <span>{{ captions.NoDataFound }}</span>
                      </div>
                </div>
              </div>
        </div>
    </ng-template>
</div>

  