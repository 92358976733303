import { Injectable } from '@angular/core';
import { GolfGatewayCommunication } from '../../communication/services/golfGateway';
import { ACESPaymentRecord, ACESPayment } from '../../../retail/shared/shared.modal';
import { batchMemberPayemnt } from 'src/app/tee-time-actions/redeem-round/redeem-model';
@Injectable({ providedIn: 'root' })
export class ACESPaymentService
{
    constructor(private _http: GolfGatewayCommunication){        
    }
    public async GetAllPaymentRecords() : Promise<ACESPaymentRecord[]> {
        return await this._http.getPromise<ACESPaymentRecord[]>(
            {
                route:GolfApiRoute.GetAllPaymentRecords
            }
            ,false
        );       
     }
     public async GetPaymentRecordsByTransactionId(transactionid : string) : Promise<ACESPaymentRecord[]> {
        return await this._http.getPromise<ACESPaymentRecord[]>(
            {
                route:GolfApiRoute.GetPaymentRecordsbyTransactionId,
                uriParams:{TransactionId : transactionid}
            }
            ,false
        );         
     }
     public async CreatePaymentRecord(paymentRecord : ACESPaymentRecord) : Promise<any> {
        return await this._http.postPromise<any>(
            {
                route:GolfApiRoute.CreateACESPaymentRecord,
                body:paymentRecord
            }
            ,false
        );         
     }
     public async ARPost(paymentModel : ACESPayment) : Promise<any> {
        return await this._http.postPromise<any>(
            {
                route:GolfApiRoute.ARPost,
                body:paymentModel
            }
            ,false
        );         
     }

     public async RedeemPoint(redeemPoint) : Promise<any> {
        return await this._http.postPromise<any>(
            {
                route:GolfApiRoute.RedeemPoint,
                body:redeemPoint
            }
            ,false
        );        
     }

     public async MemberSummary(paymentModel : ACESPayment) : Promise<any> {
        return await this._http.postPromise<any>(
            {
                route:GolfApiRoute.MemberSummary,
                body:paymentModel
            }
            ,false
        );
        
     }

     public async ACESRefund(transactionid : string, pin:string) : Promise<any>{
        return await this._http.postPromise<any>(
            {
                route:GolfApiRoute.AcesRefund,
                uriParams:{TransactionId : transactionid, pin: pin}
            }
            ,false
        );        
     }


     public async BatchMemberPayment(memberpaymentModel: batchMemberPayemnt) : Promise<any>{
        return await this._http.putPromise<any>(
            {
                route:GolfApiRoute.BatchMemberPayment,
                body:memberpaymentModel
            }
            ,false
        );        
     }
}