import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ReinstateBusiness } from './reinstate-business';
import { AlertType, ButtonType, CheckboxTableHeader, DialogCloseObj } from '../../shared/shared-models';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { StepperService } from '../../shared/components/stepper/stepper.service';
import { ReinstatePlayerUI } from './reinstate-player-model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReinstateTeetimeModalComponent } from '../reinstate-teetime-modal/reinstate-teetime-modal.component';
import { CommonPopupComponent } from '../../shared/components/common-popup/common-popup.component';
import { PlayerDataService } from '../../shared/data-services/golfmanagement/player.data.service';
import { TeeTimeDataService } from '../../shared/data-services/golfschedule/TeeTime.data.service';
import { TeeTimeSearchData } from 'src/app/tee-time/search/search-model';
import { TeeTimesActionService } from 'src/app/shared/data-services/golfschedule/teeTimesAction.data.service';
import { ButtonAction, CacheConstants } from 'src/app/shared/global.constant';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import { PlayerDaysOutResut, RateTypeDaysOutResult } from 'src/app/shared/models/teesheet.form.models';
import { TeeTimesActionBusiness } from 'src/app/tee-time/shared/tee-action.business';
import { PlayerTypes as PlayerType} from '../teetime/player-details/player-info.model';
import { UserAccessModel } from 'src/app/common/dataservices/authentication/useraccess-model.model';
import * as _ from 'lodash';
import { OverrideDetails, OverrideType } from '../teetime/tee-time.model';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';

@Component({
  selector: 'app-reinstate-modal',
  templateUrl: './reinstate-modal.component.html',
  styleUrls: ['./reinstate-modal.component.scss'],
  providers: [ReinstateBusiness, PlayerDataService, TeeTimeDataService, TeeTimesActionService]
})
export class ReinstateModalComponent implements OnInit {
  _dialog: MatDialog;
  popUpComponentDetails: { componentName: typeof ReinstateTeetimeModalComponent; popUpDetails: { isStepper: boolean; eventName: string; }; };
  teeTimeInfo: TeeTimeSearchData;
  captions: any;
  playersData: ReinstatePlayerUI[];
  headerOption: CheckboxTableHeader[];
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  reinstateModalForm: UntypedFormGroup;
  @Output() notifyParent = new EventEmitter();
  pmsActivityId: string;
  overridePlayerTypeDaysoutUserAccess: UserAccessModel.BreakPointResult;
  overrideRateTypeDaysoutUserAccess: UserAccessModel.BreakPointResult;
  userBreakpointAccess: UserAccessModel.BreakPointResult;
  playerTypes: PlayerType[] = [];
  playerTypeOverride : number;
  rateTypeOverride : number;
  playerDaysoutErrorMessage: any;
  rateDaysoutErrorMessage: any;
  overrideRateTypeForDynamicAvailability: boolean = false;
  golfSetting;

  constructor(private _FormBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ReinstateModalComponent>,
    private dialog: MatDialog,
    private _reinstateBusiness: ReinstateBusiness,
    public _StepperService: StepperService,
    private _localization: GolfLocalization,
    private _propertyInformation: GolfPropertyInformation,
    private _utilities: GolfUtilities,
    private propertyInformation: RetailPropertyInformation,
    public quickLoginUtils: QuickLoginUtilities,
    private _teeTimesActionBusiness: TeeTimesActionBusiness,
    private _userAccessBusiness: UserAccessBusiness,
    @Inject(MAT_DIALOG_DATA) public dialogData) {
    this._dialog = dialog;
  }

  ngOnInit() {

    this.loadData();
    this._StepperService.valueChange.pipe(takeUntil(this.$destroyed)).subscribe((x: DialogCloseObj) => {

      if (x.type === ButtonAction.reinstate) {
        this.submitForms(x);
      }
    });
  }

  loadData() {
    this.formGenerator();
    this.golfSetting = JSON.parse(sessionStorage.getItem('GOLFSETTING'));
    if (this.golfSetting && this.golfSetting.enableDynamicPricing) {
      this.checkOverrideAccessforDynamicAvailability();
    }    
    this.teeTimeInfo = this.dialogData.info;
    this.pmsActivityId = this.dialogData.pmsActivityId;
    this.headerOption = this._reinstateBusiness.getHeaderOption();
    this._reinstateBusiness.getPlayerDetails(this.teeTimeInfo, this.pmsActivityId).then(response => {
      this.playersData = response;
    });
    this.getMasterData();
    this.captions = this._reinstateBusiness.reinstatePlayerCaption;
    //const checkedData = this.reinstateModalForm.value.checkBoxItems && this.reinstateModalForm.value.checkBoxItems.some(x => x.checkBox);
    this.notifyParent.emit(false);
    
    this.reinstateModalForm.markAsDirty();
    this.reinstateModalForm.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(x => {
      const checkedData = this.reinstateModalForm.value.checkBoxItems && this.reinstateModalForm.value.checkBoxItems.some(x => x.checkBox);
      this.notifyParent.emit(this.reinstateModalForm.valid && this.reinstateModalForm.dirty && checkedData);
    });
  }

  async checkOverrideAccessforDynamicAvailability() {
    await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.OVERRIDE_UNAVAILABLE_RATETYPE_PER_DYNAMICAVAILABILITY, false).then(x => {
      this.overrideRateTypeForDynamicAvailability = x.isAllow;
    });
  }

  async getMasterData() {
    this.overridePlayerTypeDaysoutUserAccess = await this._teeTimesActionBusiness.validateBreakPointAccess(UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT, false);
    this.playerTypes = await this._teeTimesActionBusiness.getPlayerTypes();
    this.overrideRateTypeDaysoutUserAccess = await this._teeTimesActionBusiness.validateBreakPointAccess(UserAccessBreakPoints.OVERRIDERATETYPEDAYSOUT, false);
  }


  formGenerator() {
    this.reinstateModalForm = this._FormBuilder.group({
      selectNewCombination: false
    });
  }

    public async checkOverridePlayerTypeRateTypeDaysOut(playerTypes: any[], selectedPlayerIds: ReinstatePlayerUI[], overridePlayerTypeDaysoutUserAccess: UserAccessModel.BreakPointResult, overrideRateTypeDaysoutUserAccess: UserAccessModel.BreakPointResult, date: any): Promise<boolean> {
      this.playerTypeOverride = 0;
      this.rateTypeOverride = 0;
      let rateTypes = await this._teeTimesActionBusiness.getRateTypes();
      selectedPlayerIds = await this.validatePlayerTypeRateTypeDaysout(selectedPlayerIds, playerTypes, rateTypes, overridePlayerTypeDaysoutUserAccess, overrideRateTypeDaysoutUserAccess, date);
      let userId = Number(this._localization.GetUserInfo('userId'));
      let override = true;
      
          if(this.playerTypeOverride > 0 && this.rateTypeOverride > 0)
          {
              override = await this._teeTimesActionBusiness.showPlayerTypeRateTypeQuickIdPopup(this._localization.captions.settings.playerTypeRateTypeDaysOutError);
              if(override)
              {
                  let playerTypeDaysOutOverrideduserID = this._teeTimesActionBusiness.quickloginUserId;
                  let rateTypeDaysOutOverrideduserID = this._teeTimesActionBusiness.quickloginUserId;
                  this.updateModifiedSlots(selectedPlayerIds, playerTypeDaysOutOverrideduserID, rateTypeDaysOutOverrideduserID);
                  this._teeTimesActionBusiness.quickloginUserId = 0;
              }
          }
          else if(this.playerTypeOverride > 0)
          {
            this.playerDaysoutErrorMessage = this.playerTypeOverride == 1 ? this.playerDaysoutErrorMessage : this._localization.captions.settings.playerTypeDaysOutError;
            override = await this._teeTimesActionBusiness.showQuickIdPopup(this.playerDaysoutErrorMessage);
            if(override)
            {
                let playerTypeDaysOutOverrideduserID = this._teeTimesActionBusiness.quickloginUserId;
                let rateTypeDaysOutOverrideduserID = userId;
                this.updateModifiedSlots(selectedPlayerIds, playerTypeDaysOutOverrideduserID, rateTypeDaysOutOverrideduserID);
                this._teeTimesActionBusiness.quickloginUserId = 0;
            }
          }
          else if(this.rateTypeOverride > 0)
          {
              this.rateDaysoutErrorMessage = this.rateTypeOverride == 1 ? this.rateDaysoutErrorMessage : this._localization.captions.settings.rateTypeDaysOutError;
              override = await this._teeTimesActionBusiness.showRateTypeQuickIdPopup(this.rateDaysoutErrorMessage);
              if(override)
              {
                  let playerTypeDaysOutOverrideduserID = userId;
                  let rateTypeDaysOutOverrideduserID = this._teeTimesActionBusiness.quickloginUserId;
                  this.updateModifiedSlots(selectedPlayerIds, playerTypeDaysOutOverrideduserID, rateTypeDaysOutOverrideduserID);
                  this._teeTimesActionBusiness.quickloginUserId = 0;
              }
          }
          else
          {
              let playerTypeDaysOutOverrideduserID = userId;
              let rateTypeDaysOutOverrideduserID = userId;
              this.updateModifiedSlots(selectedPlayerIds, playerTypeDaysOutOverrideduserID, rateTypeDaysOutOverrideduserID);
          }
          return override;
  }

  async validatePlayerTypeRateTypeDaysout(selectedPlayerIds: ReinstatePlayerUI[], playerTypes: any[], rateTypes: any[],overridePlayerTypeDaysoutUserAccess, overrideRateTypeDaysoutUserAccess,  date: string): Promise<ReinstatePlayerUI[]> {
    selectedPlayerIds.forEach(slot => {
        let playerType = playerTypes.find(x => x.id == slot.playerTypeId);
        let rateType = rateTypes.find(x => x.id == slot.rateTypeId);
        slot.playerTypeDaysOutFailed = false;
        slot.playerTypeDaysOutResult = PlayerDaysOutResut.Pass;
        slot.rateTypeDaysOutFailed = false;
        slot.rateTypeDaysOutResult = RateTypeDaysOutResult.Pass;
        if (playerType)
        {
            let result = this._teeTimesActionBusiness.validatePlayerDaysOut(this._localization.getDate(date), playerType.daysOutStart, playerType.daysOutEnd);
            if (result && (result == PlayerDaysOutResut.StartDateFail || result == PlayerDaysOutResut.EndDateFail)) {
                slot.playerTypeDaysOutFailed = true;
                slot.playerTypeDaysOutResult = result;
                if(!(overridePlayerTypeDaysoutUserAccess && overridePlayerTypeDaysoutUserAccess.isAllow))
                {
                    this.playerTypeOverride++;
                    if(this.playerTypeOverride == 1)
                    {
                        this.playerDaysoutErrorMessage = this._teeTimesActionBusiness.GetplayerDaysoutErrorMessage(playerType, slot.playerTypeDaysOutResult);
                    }
                }
            }
        }
        if(rateType)
        {
            let result = this._teeTimesActionBusiness.validateRateTypeDaysOut(this._localization.getDate(date), rateType.daysOutStart, rateType.daysOutEnd);
            if (result && (result == RateTypeDaysOutResult.StartDateFail || result == RateTypeDaysOutResult.EndDateFail)) {
                slot.rateTypeDaysOutFailed = true;
                slot.rateTypeDaysOutResult = result;
                if(!(overrideRateTypeDaysoutUserAccess && overrideRateTypeDaysoutUserAccess.isAllow))
                {
                    this.rateTypeOverride++;
                    if(this.rateTypeOverride == 1)
                    {
                        this.rateDaysoutErrorMessage = this._teeTimesActionBusiness.GetrateDaysoutErrorMessage(rateType, slot.rateTypeDaysOutResult);
                    }
                }
            }
        }
    });
    return selectedPlayerIds;
  }

  public updateModifiedSlots(selectedPlayerIds: ReinstatePlayerUI[], playerTypeDaysOutOverrideduserID: number, rateTypeDaysOutOverrideduserID: number) {
    selectedPlayerIds.forEach(x => {
      x.overrideDetails = this._teeTimesActionBusiness.GetOverrideDetails(x.playerTypeDaysOutFailed, x.rateTypeDaysOutFailed, playerTypeDaysOutOverrideduserID, rateTypeDaysOutOverrideduserID);
      if (!this.overrideRateTypeForDynamicAvailability && this.golfSetting && this.golfSetting.enableDynamicPricing) {
        var overrideRatetypeDetail: OverrideDetails = {
             id: 0,
             scheduledTeeTimePlayerId: 0,
             overrideType: OverrideType.rateTypeOverridePerDynamicAvailability,
             userId: 0
           }
           x.overrideDetails.push(overrideRatetypeDetail);
       }   
    });
  }

  async submitForms(x) {
    this._StepperService.enableSave = false;
    var selectedPlayerIds: ReinstatePlayerUI[] = this.reinstateModalForm.value.checkBoxItems.filter(x => x.checkBox === true).map(a => a);
    let playerLinkIds = selectedPlayerIds.filter(o => o.linkingId != '' && o.linkingId != null).map(p => p.linkingId);
    let overlapping;
    if(this.playersData != null && this.playersData.length > 0)
    if(selectedPlayerIds.length > 0)
    selectedPlayerIds.forEach(element =>{
      element.bookingSource = this.playersData.find(s => s.id == element.id).bookingSource; 
    });
    const setting = JSON.parse(sessionStorage.getItem(CacheConstants.TeeTimeSetting));
    if(setting.checkForTeeTimeOverlaps != null && Boolean(setting.checkForTeeTimeOverlaps) == true )
    {   
      if (this.reinstateModalForm.get('selectNewCombination').value) 
      {
        this.reinstateWithNewCombination(selectedPlayerIds);
      }
      else {
        if(playerLinkIds != null && playerLinkIds.length > 0)
        {
          overlapping = await this._teeTimesActionBusiness.CheckOverLappingBookingForGuest(playerLinkIds,null,
            this._localization.convertDateTimeToAPIDateTime(selectedPlayerIds[0].bookedDateTime),selectedPlayerIds[0].courseId);
          overlapping = overlapping.filter(x=>!(x.scheduledTeeTimeOfPlayer === this._localization.convertDateTimeToAPIDateTimeSec(selectedPlayerIds[0].bookedDateTime)));
          if(overlapping!= null && overlapping?.length > 0 && playerLinkIds != null && playerLinkIds?.length > 0)
          {
            let overlappingGuestData = this._teeTimesActionBusiness.getOverlappingGuestDatas(overlapping);
            this._teeTimesActionBusiness.showOverlappingPlayersPopup(overlappingGuestData).then(async res=>{
              if(res == true){
                await this.InvokeReinstateResult(selectedPlayerIds);
              }
            });
          }
          else{
            await this.InvokeReinstateResult(selectedPlayerIds);
          }       
        }
        else{
          await this.InvokeReinstateResult(selectedPlayerIds);
        }
      } 
    }
    else 
    {
      if (this.reinstateModalForm.get('selectNewCombination').value) 
      {
        this.reinstateWithNewCombination(selectedPlayerIds);
      }
      else
      {
        await this.InvokeReinstateResult(selectedPlayerIds);
      }    
    }
  }
  public async InvokeReinstateResult(selectedPlayerIds)
  {
    let userDaysOut = Number.parseInt(this._localization.GetUserSettings("daysOut"));
    let daysOutDate = this._localization.AddDays(this._propertyInformation.CurrentDate, userDaysOut);
    let bookingDate = this._localization.getDate(selectedPlayerIds[0].bookedDateTime);   
    let result = await this.checkOverridePlayerTypeRateTypeDaysOut(this.playerTypes, selectedPlayerIds, this.overridePlayerTypeDaysoutUserAccess,this.overrideRateTypeDaysoutUserAccess, bookingDate);
    if (!result) {
      return;
    }
    if (bookingDate <= daysOutDate || userDaysOut === 0)
    {
      let quickIdConfig = this.propertyInformation.getQuickIdConfig;
      if (quickIdConfig && quickIdConfig.teeTimeReinstate){
        const quickLoginDialogRef = this.quickLoginUtils.QuickLogin();
        quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
            if (quickLoginDialogResult.isLoggedIn) {
              const quickRoleId = this.quickLoginUtils.GetQuickLoginRoleId();
              return this._reinstateBusiness.reinstateWithExistingCombination(selectedPlayerIds, quickRoleId, (err) => {
                /** Checkbox of New combination set true when there is no tee time interval  */
                if (err && err.errorCode == 21000) this.reinstateModalForm.controls.selectNewCombination.setValue(true);
              }).then(
                o => {
                  if (o)
                    this.showRuleErrors(o, true);
                }
              );
            }
            else {
              this.quickLoginUtils.resetQuickIdDetails();
              this._StepperService.enableSave = true;
            }
        });
      }
      else {
        const roleId = this._localization.GetUserInfo("roleId"); 
        return this._reinstateBusiness.reinstateWithExistingCombination(selectedPlayerIds, roleId, (err) => {
          /** Checkbox of New combination set true when there is no tee time interval  */
          if (err && err.errorCode == 21000) this.reinstateModalForm.controls.selectNewCombination.setValue(true);
        }).then( o => { if (o) this.showRuleErrors(o); });
      }
    }
    else if (bookingDate > daysOutDate || userDaysOut !== 0) {
      this._utilities.showError(this._localization.getError(30414));
      return false;
    }
    else {
      return false;
    }
  }

  showRuleErrors(resp, isQuickId: boolean = false) {
    const failedRules = resp.filter(r => !r.result);
    if (failedRules && failedRules.length > 0) {
      var errorCode = this._reinstateBusiness.showRuleErrors(failedRules[0].ruleResults);
      if (errorCode == 30426) {
        /** Checkbox of New combination set true when there is no tee time slot  */
        this.reinstateModalForm.controls.selectNewCombination.setValue(true);
      }
    }
    else {
      this._utilities.showAlert(this._reinstateBusiness.reinstatePlayerCaption.reinstateSuccessMsg, AlertType.Success, ButtonType.Ok);
      this.dialogRef.close('saved');
      if (isQuickId) this.quickLoginUtils.resetQuickIdDetails();
    }
  }
  reinstateWithNewCombination(selectedPlayerIds: ReinstatePlayerUI[]) {
    this.popUpComponentDetails = {
      componentName: ReinstateTeetimeModalComponent,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      }
    };

    this._dialog.open(CommonPopupComponent, {
      width: '80%',
      height: '80%',
      data: {
        title: this.captions.reinstateTitleTeetime,
        update: this.captions.save,
        cancel: this.captions.cancel,
        componentDetails: this.popUpComponentDetails,
        selectedPlayerIds,
        actionType: ButtonAction.save
      },
      disableClose: true
    }).afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(result => {
      if (result === 'save') {
        this.dialogRef.close('saved');
      }
      else {
        this.loadData();
      }
    });
  }

  ngOnDestroy(): void {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

}
