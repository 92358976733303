import { Injectable } from '@angular/core';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Injectable()
export class ItneraryService {
  captions: any;

  constructor(private _Localization: GolfLocalization) { }

  getCaptions() {
    this.captions = this._Localization.captions.guest.itenrary;
    return this.captions;
  }
  getSelectionButtons() {
    return [
      { id: 1, value: this.captions.day },
      { id: 2, value: this.captions.week },
      { id: 3, value: this.captions.month },
    ]
  }

  

  getItneraryDetails() {
    return [
      {
        id: '5a4026b4-7222-413c-a6b9-fbd0647779d6',
        name: 'Add Appointment153',
        details: [
          {
            name: 'Add Appointment 153',
            description: 'Add Appointment 153',
            value: 'Add Appointment 15'
          }
        ],
        notes: [],
        start: '2019-10-29T09:00:00',
        end: '2019-10-29T09:15:00',
        packageName: 'Facial',
        interfaces: [],
        roomNumber: null,
        busy: true,
        confirmationNumber: null,
        status: 0,
        guests: [
          '70774e3d-b869-43e0-aedc-829abe97a2f0'
        ],
        modified: '2019-10-18T13:20:34.3985925',
        created: '2019-09-10T12:20:35.5583018',
        lastChangeId: '88f9dcd7-0a23-471e-3d3f-08d7539aa962',
        tenantId: '8548b193-890a-4874-9f62-fedf4db87b5'
      },
      {
        id: '82094d15-f172-469e-b57d-53f6248b6128',
        name: '312',
        details: [
          {
            name: ' 312',
            description: ' 312',
            value: ' 31'
          }
        ],
        notes: [],
        start: '2019-11-01T13:00:00',
        end: '2019-11-01T13:15:00',
        packageName: 'MCMT',
        interfaces: [],
        roomNumber: null,
        busy: true,
        confirmationNumber: null,
        status: 1,
        guests: [
          '70774e3d-b869-43e0-aedc-829abe97a2f0'
        ],
        modified: '2019-09-23T06:29:33.8249515',
        created: '2019-09-23T06:29:28.7510297',
        lastChangeId: '0ef29a1f-7c2a-4f5c-c29f-08d73feef22d',
        tenantId: '8548b193-890a-4874-9f62-fedf4db87b5'
      },
      {
        id: '82094d15-f172-469e-b57d-53f6248b6128',
        name: '312',
        details: [
          {
            name: ' 312',
            description: ' 312',
            value: ' 31'
          }
        ],
        notes: [],
        start: '2019-11-25T10:00:00',
        end: '2019-11-25T10:15:00',
        packageName: 'Pedicure',
        interfaces: [],
        roomNumber: null,
        busy: true,
        confirmationNumber: null,
        status: 1,
        guests: [
          '70774e3d-b869-43e0-aedc-829abe97a2f0'
        ],
        modified: '2019-09-23T06:29:33.8249515',
        created: '2019-09-23T06:29:28.7510297',
        lastChangeId: '0ef29a1f-7c2a-4f5c-c29f-08d73feef22d',
        tenantId: '8548b193-890a-4874-9f62-fedf4db87b5'
      }
    ]
  }
}
