import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, NgForm } from '@angular/forms';
import { PlayerDetailService } from './player-detail.service';
import { PlayerDetailBusiness } from './player-detail.business';
import { CodeLinkingDataService } from 'src/app/shared/data-services/golfmanagement/codelinking.data.service';
import { API } from 'src/app/settings/golf-setup/code-linking/code-link-modal';
import { LessonLocation } from 'src/app/settings/golf-setup/code-setup/lesson-location/lesson-location.model';
import { LessonLocationDataService } from 'src/app/shared/data-services/golfmanagement/lesson-location.data.service';
import { SystemdefaultsInformationService } from '../../../core/services/systemdefaults-information.service';
import { PhoneDetail, GuaranteeTypes, ButtonType, AlertType, ComponentDetails, DateInput } from '../../../shared/shared-models';
import { Tournament } from 'src/app/tee-time/tournaments/tournaments.model';
import { PlayerDataService } from 'src/app/shared/data-services/golfmanagement/player.data.service';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import _, { cloneDeep } from 'lodash';
import { ButtonAction, PlayerAdditionalDetailsEnum, playerTypes } from 'src/app/shared/global.constant';
import { GuestService } from 'src/app/guest/guest.service';
import { CreatePlayerBusiness } from 'src/app/shared/create-player/create-player.business';
import { PlayerInformationService } from 'src/app/common/shared/shared/service/player.information.service';
import { PlayersBusiness } from 'src/app/guest/players/players.business';
import { PlayerProfileDataService } from 'src/app/shared/data-services/golfmanagement/PlayerProfile.data.services';
import { SharedService } from 'src/app/shared/shared.service';
import { CreatePlayerService } from 'src/app/shared/create-player/create-player.service';
import { GuestPlayersService } from 'src/app/guest/players/players.service';
import { ContactService } from 'src/app/tee-time/tournaments/tournaments-modal/contact/contact.service';

import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import { allocationBlockPlayerTypePermission } from 'src/app/settings/user-setup/booking-permission/booking-permission.model';
import { TeeTimesActionBusiness } from 'src/app/tee-time/shared/tee-action.business';
import * as PlayerTypeNew from 'src/app/settings/golf-setup/code-setup/player-type/player-type.modal';
import * as RateTypeNew from 'src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal';
import { UserAccessModel } from 'src/app/common/dataservices/authentication/useraccess-model.model';
import { MatDialog } from '@angular/material/dialog';
import { CommonPopupComponent } from 'src/app/shared/components/common-popup/common-popup.component';
import { CreatePlayerComponent } from 'src/app/shared/create-player/create-player.component';
import { DefaultsSettingConfig } from 'src/app/tee-time-actions/teetime/player-details/player-info.model';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PlayerAdditionalDetails } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { LessonBookingDataService } from 'src/app/shared/data-services/golfschedule/lesson-booking.data.service';
import { ButtonTypes } from 'src/app/common/enums/shared-enums';
import { RecurringDetail } from 'src/app/lessons/lesson-landing/lesson-landing.model';
import { AlertMessagePopupComponent } from 'src/app/shared/alert-message-popup/alert-message-popup.component';
import { TeeSheetSharedBusiness } from 'src/app/tee-time/shared/teesheet.shared.business';

@Component({
  selector: 'app-player-detail',
  templateUrl: './player-detail.component.html',
  styleUrls: ['./player-detail.component.scss'],
  providers: [PlayerDetailBusiness, PlayerDetailService
    , TeeTimesActionBusiness
    , CodeLinkingDataService
    , LessonLocationDataService
    , PlayerDataService
    , CreatePlayerBusiness
    , SharedService
    , ContactService
    , PlayersBusiness
    , CreatePlayerService
    , GuestPlayersService
    , PlayerProfileDataService
    , LessonBookingDataService
    , TeeSheetSharedBusiness
  ],
  encapsulation: ViewEncapsulation.None
})
export class PlayerDetailComponent implements OnInit {
  @Output() formReady = new EventEmitter();
  @Output() PlayerInfoFormEmit = new EventEmitter();
  @Output() ContactData = new EventEmitter();
  @Input() parentForm;
  allocationCodes: API.AllocationBlockPlayerType[];
  allocationBlockPlayerType: API.AllocationBlockPlayerType[];
  playerTypeRateType: API.PlayerTypeRateType[];
  captions: any;
  errorCaptions: any;
  lessonLocations: LessonLocation[];
  playerTypes: API.PlayerTypeRateType[];
  playerInfoForm: UntypedFormGroup;
  rateTypes: RateTypeNew.RateType[];
  existingrateTypes: RateTypeNew.RateType[];
  viewOnly = false;
  allPlayerTypes: PlayerTypeNew.PlayerType[];
  breakpointAccess: UserAccessModel.BreakPointResult;
  selectedData: any[] = [];
  autoCompleteKeys: string[] = ['firstName', 'lastName'];
  selectedChipKey: string[] = ['firstName', 'lastName'];
  searchKey: string[] = ['firstName', 'lastName', 'confirmationNumber'];
  showSearch = false;
  searchParameters = "";
  allData: Tournament.UI.ContactInformation[] = [];
  maxAllowed = 1;
  searchText = "";
  PaymentReferenceID = 0;
  playerCategoryInput: { form: any; controlName: string; scheduleDate?: Date };
  isSearchResultFieldDisabled: boolean = false;
  public isCardOnFileDisabled: boolean = false;
  currentSelectedGuestType: any;
  selectedGuestType = playerTypes;
  isPatronIdAvailable = false;
  playerCaptions: any;
  isSearchResultNameFieldDisabled: boolean = false;
  playerCategorytype: boolean = false;
  isMemberCategorySelected: boolean = false;
  defaultPlayercategory: number = 1;
  defaultHotelReservationCategory: number;
  isPayementRequired: boolean = false;  //Added
  allCaptions: any;
  caption: any;
  cmsConfigured: boolean = false;
  userBreakpointAccess: UserAccessModel.BreakPointResult;
  IsMemberCategory = false;
  settingsCaptions: any;
  memberShipDetails = null;
  allocationCodePermissions: allocationBlockPlayerTypePermission[] = [];
  isAllocationBlockEnabled: boolean;
  packageRateTypes: RateTypeNew.RateType[];
  packagePlayerTypes: PlayerTypeNew.PlayerType[];
  originalPlayerTypes: PlayerTypeNew.PlayerType[];
  packageCode: string;
  componentDetails: ComponentDetails;
  defaultPlayerType: PlayerTypeNew.PlayerType;
  defaultRateType: RateTypeNew.RateType;
  createPlayerPopupSubscription: any;
  resetPlayerSearchCategory: boolean = false;
  lastUpdatePlayerCategoryType;
  defaultSettings: DefaultsSettingConfig;
  //recurringData: any;
  recurringInfo: RecurringDetail;
  displayRecurring = true;
  recurringStartDate: string | Date;
  recurringEndDate: string | Date;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  startDateInputs: DateInput;
  endDateInputs: DateInput;
  setMaxValidator = true;
  isRecurringDisable = false;
  instructorId: number;
  lessonDetailForm: UntypedFormGroup;
  @Input() hideHeader: boolean = false;
  @Input() displayToggle = true;
  @Input() lessonDate;
  editData;
  @ViewChild('playerForm', { static: true }) personalForm: NgForm;
  @Input('inputData')
  set formData(value: any) {
    if (value?.data && value?.action != 'reset') {
      this.editData = value.data;
      this.initialize(false);
    } else {
      this.editData = null;
      this.initialize(true);
    }
  };
  constructor(
    private fb: UntypedFormBuilder, private _PlayerDetailBusiness: PlayerDetailBusiness
    , private _SystemdefaultsInformationService: SystemdefaultsInformationService
    , private _utilities: GolfUtilities
    , private _guestService: GuestService
    //Added to get CMS Values - For Adding guest Implementation
    , private _createPlayerBusiness: CreatePlayerBusiness
    , private _playerService: PlayerInformationService
    , private _playersBussiness: PlayersBusiness
    , private _PropertyInformation: GolfPropertyInformation
    , private _playerProfileDataService: PlayerProfileDataService
    , private _sharedService: SharedService
    , private _createPlayerService: CreatePlayerService
    , private localizationService: GolfLocalization
    , private _featureSwitch: RetailFeatureFlagInformationService
    , private _playerDataService: PlayerDataService
    , private teeTimesActionBusiness: TeeTimesActionBusiness
    , public dialog: MatDialog
    , public _cdr: ChangeDetectorRef
    , private _playerDetailService: PlayerDetailService
  ) {
    this.caption = this.GetCaptions();
    this.allCaptions = this.localizationService.captions;
    this.cmsConfigured = this._featureSwitch.IsCMSConfigured;

  }

  GetCaptions() {
    return this._createPlayerService.getCaptions();
  }

  ngOnInit() {
    this.initialize(true);
  }

  async initialize(isFromOnInit: boolean = false) {
    // this.disableControls();
    this.captions = this._PlayerDetailBusiness.captions;
    this.settingsCaptions = this._PlayerDetailBusiness.settingsCaptions;
    this.playerCaptions = this._PlayerDetailBusiness.playerCaptions;
    this.errorCaptions = this._PlayerDetailBusiness.captions;
    this.defaultSettings = this._PlayerDetailBusiness.getDefaultSettings();
    await this.createPlayerInfoForm();
    this._PlayerDetailBusiness.lessonScheduledDate = this.lessonDate;
    this.isAllocationBlockEnabled = this._utilities.IsAllocationCodePermissionEnabled();
    if (this.isAllocationBlockEnabled) {
      this.allocationCodePermissions = await this._PlayerDetailBusiness.GetAllocationBlockPermissionByRole();
    }
    this.playerInfoForm.get('playerType').valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      if (!this.viewOnly && this.isAllocationBlockEnabled) {
        if (value && value !== this.playerInfoForm.value.playerType) {
          const playerTypeId = this.playerInfoForm.controls['playerType'].value;
          if (playerTypeId) {
            const playerPermissionDetail = this.allocationCodePermissions.filter(x => x.playerTypeId == playerTypeId);
            if (playerPermissionDetail.length === 0) {
              this.showPlayerTypeError();
              this.playerInfoForm.controls['playerType'].patchValue('');
              return;
            }
          }
        }
      }
    });
    this.playerInfoForm.valueChanges.subscribe(val => {
        this.parentForm.controls.playerInfoForm.controls = this.playerInfoForm.controls;
        this.parentForm.controls.playerInfoForm.updateValueAndValidity()
        this.parentForm.markAsDirty();
    });

    if (this.editData) {
      this.bindPlayerInfo(this.editData);
    } else {
      this.OnResetPlayerInfo();
    }
    this.getAllPlayerTypes();
    this.playerInfoForm.get('allocationCode').valueChanges.subscribe(allocationCode => {
      if (this.userBreakpointAccess && !this.userBreakpointAccess.isAllow && allocationCode && allocationCode !== this.playerInfoForm.value.allocationCode) {
        this.playerInfoForm.get('allocationCode').patchValue(this.playerInfoForm.value.allocationCode);
        let bpMessage = this.localizationService.captions.breakpoint[UserAccessBreakPoints.CHANGEALLOCATIONBLOCK];
        this._PlayerDetailBusiness.showBreakPointPopup(bpMessage);
        return;
      }
      const playerTypeId = this.playerInfoForm.controls['playerType'].value;
      const allocationCodeId = this.playerInfoForm.controls['allocationCode'].value;
      if (!this.viewOnly && this.isAllocationBlockEnabled) {
        if (allocationCode && allocationCode !== this.playerInfoForm.value.allocationCode) {
          if (allocationCodeId) {
            const allocationPermissionDetail = this.allocationCodePermissions.filter(x => x.allocationBlockId == allocationCodeId);
            if (allocationPermissionDetail.length === 0) {
              const allocationName = this.allocationCodes.find(x => x.id == allocationCodeId);
              this._utilities.showAllocationCodePermissionDeniedPopup(allocationName.name);
              this.playerInfoForm.controls['allocationCode'].patchValue('');
              return;
            }
            if (playerTypeId) {
              const playerPermissionDetail = allocationPermissionDetail.filter(x => x.playerTypeId == playerTypeId);
              if (playerPermissionDetail.length === 0) {
                const playerDetails = this.playerTypes.find(x => x.id == allocationCodeId);
                if (playerDetails && playerDetails.name) {
                  this.showPlayerTypeError();
                }
                this.playerInfoForm.controls['playerType'].patchValue('');
                this.assignPlayerAndRateTypesBasedOnAllocation(allocationCodeId);
                return;
              }
            }
          }
        }
      }
      this.assignPlayerAndRateTypesBasedOnAllocation(allocationCodeId);
    });
    if (isFromOnInit) {
      await this.SetDefaultPlayerCategory();
    }
    let lessonBookingFormValues = this._playerDetailService.lessonBookingFormValues;
    if (lessonBookingFormValues) {
      this.playerInfoForm.markAsPristine();
    }
  }

  emitValueChange(isCategoryChanged) {
    this.PlayerInfoFormEmit.emit({
      paymentReferenceId: this.PaymentReferenceID,
      isCardOnFileDisabled: this.isCardOnFileDisabled,
      isRequired: this.isPayementRequired,
      isCategoryChanged: isCategoryChanged,
    })
  }

  async SetDefaultPlayerCategory() {
    var playerCategory;
    if (!(this._playerDetailService.lessonBookingFormValues && this._playerDetailService.lessonBookingFormValues && this._playerDetailService.lessonBookingFormValues?.playerInfoForm.playerTypeRadio && this._playerDetailService.lessonBookingFormValues?.playerInfoForm.playerTypeRadio != null)) {
      playerCategory = this.defaultSettings && this.defaultSettings?.defaultPlayerCategory ? this.defaultSettings?.defaultPlayerCategory : playerTypes.newPlayer
      this.defaultPlayercategory = this.lastUpdatePlayerCategoryType = playerCategory = (await this.ValidateBreakPointPlayerCategory(playerCategory)).playerCategory;
      this.playerInfoForm.controls.playerTypeRadio.setValue(playerCategory);
      this.setSearchFilter(playerCategory);
    }
    else this.defaultPlayercategory = this.lastUpdatePlayerCategoryType = this._playerDetailService.lessonBookingFormValues.playerInfoForm.playerTypeRadio;
    this.playerCategoryInput = {
      form: this.playerInfoForm,
      controlName: 'playerTypeRadio',
      scheduleDate: this.lessonDate
    };
  }

  async getAllocationCodeBreakpoint() {
    this.userBreakpointAccess = await this._PlayerDetailBusiness.ValidateUserBreakPoints();
  }

  async getAllPlayerTypes() {
    this.allPlayerTypes = await this._PlayerDetailBusiness.getAllPlayerTypes();
    this._playerDetailService.allPlayerTypes = _.cloneDeep(this.allPlayerTypes);
  }

  assignPlayerAndRateTypesBasedOnAllocation(allocationBlockId: number) {
    this.playerTypes = [];
    this.rateTypes = [];
    let rateTypes: any;
    const allocationBlock = this.allocationBlockPlayerType.find(x => x.id == allocationBlockId);
    if (allocationBlock) {
      const playerTypes = allocationBlock.playerTypes;
      if (playerTypes.length > 0) {
        this.playerTypes = this.playerTypeRateType.filter(x => playerTypes.some(y => y.id == x.id));
        if (this.playerTypes.length > 0) {
          const playerId = this.playerInfoForm.get('playerType').value;
          if (playerId) {
            rateTypes = this._PlayerDetailBusiness.getRateTypesForPlayer(this.playerTypeRateType, playerId);
            if (this.playerTypes.filter(x => x.id == playerId).length == 0) {
              this.playerInfoForm.get('playerType').setValue(null);
            }
          }
          rateTypes = rateTypes ? rateTypes : this.playerTypes.map(x => x.rateTypes).flat();
          rateTypes = rateTypes ? rateTypes.filter(x => x.isActive) : rateTypes;
          if (rateTypes.length > 0) {
            const playerRateTypes: any = _.uniqBy(rateTypes, 'id');
            this.rateTypes = playerRateTypes;
            const rateType = this.playerInfoForm.get('rateType').value;
            if (rateType) {
              if (this.rateTypes.filter(x => x.id == rateType).length == 0) {
                this.playerInfoForm.get('rateType').setValue(null);
              }
            }
          }
        }
      } else {
        this.playerInfoForm.get('playerType').setValue(null);
        this.playerInfoForm.get('rateType').setValue(null);
      }
    } else {
      this.playerTypes = this.playerTypeRateType;
      let selectedPlayer = this.playerInfoForm.get('playerType').value;
      if (selectedPlayer)
        this.rateTypes = this._PlayerDetailBusiness.getRateTypesForPlayer(this.playerTypeRateType, selectedPlayer);
    }
  }

  assignPlayerBasedAssociation(playerTypeId: number) {
    this.rateTypes = this._PlayerDetailBusiness.getRateTypesForPlayer(this.playerTypeRateType, playerTypeId);
    this._playerDetailService.rateTypes = _.cloneDeep(this.rateTypes);
    this.emitValueChange(false);
    if (!this.rateTypes || (this.rateTypes && !this.rateTypes.find(x => x.id == this.playerInfoForm.get('rateType').value)))
      this.playerInfoForm.get('rateType').setValue(null);
  }

  playerTypeChanged(event) {
    if (event.value)
      this.assignPlayerBasedAssociation(event.value);
  }

  rateTypeChanged(event) {
    this.emitValueChange(false);
  }

  bindPlayerTypes() {
    if (this.packageCode && this.packageCode != '' && this.packagePlayerTypes && this.packagePlayerTypes.length > 0)
      return this.playerTypes.filter(x => this.packagePlayerTypes.find(y => y.id === x.id));
    else
      return this.playerTypes;
  }


  openAddplayer() {

  }

  async createPlayerInfoForm() {
    this.playerInfoForm = this.fb.group({
      playerType: '',
      rateType: '',
      price: '',
      allocationCode: '',
      lessonLocation: '',
      bookLessonOnTeeSheet: false,
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      pronounced: '',
      id: [0],
      playerId: [0],
      playerLinkId: '',
      playerTypeRadio: this.defaultPlayercategory,
      recurring: false,
      bagNumber: '',
      patronid: '',
      rank: '',
      playerAdditionalDetails: [],
      arAccountNumber: '',
      availableRounds: 0
    });
    if (this.parentForm) {
      this.parentForm.addControl('playerInfoForm', this.playerInfoForm);
    }
    this.defaultHotelReservationCategory = this.defaultSettings && this.defaultSettings.defaultHotelReservationCategory ? this.defaultSettings.defaultHotelReservationCategory : null;
    this.playerCategoryInput = {
      form: this.playerInfoForm,
      controlName: 'playerTypeRadio',
      scheduleDate: this.lessonDate
    };
    this.formReady.emit(this.playerInfoForm);
    this.playerTypeRateType = await this._PlayerDetailBusiness.generatePlayerType();
    this.playerTypes = _.cloneDeep(this.playerTypeRateType);
    this.rateTypes = this.existingrateTypes = await this._PlayerDetailBusiness.generateRateType();
    this._playerDetailService.rateTypes = _.cloneDeep(this.rateTypes);
    this.allocationBlockPlayerType = await this._PlayerDetailBusiness.generateAllocationCode();
    this.allocationCodes = _.cloneDeep(this.allocationBlockPlayerType);
    this.lessonLocations = await this._PlayerDetailBusiness.generateLessonLocation();
    // systemDefaults oninit patch for ratetype player type

    if (!(this._playerDetailService.lessonBookingFormValues)) {
      this.allocationCodesPatch();
      this.setdefaultRatePlayer(this.playerInfoForm.get('playerTypeRadio').value);
      let selectedPlayer = this.playerInfoForm.get('playerType').value;
      if (selectedPlayer)
        this.assignPlayerBasedAssociation(selectedPlayer);
    }

    await this.getAllocationCodeBreakpoint();
  }
  setRecurringInfo() {
    let lessonBookingFormValues = this._playerDetailService.lessonBookingFormValues.playerInfoForm;
    let currentSlotDate: any;
    if (this.lessonDetailForm && this.lessonDetailForm.controls.date.value) {
      currentSlotDate = this.lessonDetailForm.controls.date.value;
    }
    if (lessonBookingFormValues &&
      lessonBookingFormValues &&
      lessonBookingFormValues.recurringEnabled && lessonBookingFormValues.recurringId > 0) {
      this.recurringInfo = lessonBookingFormValues.recurringDetail;
      this.recurringStartDate = this.recurringInfo.startDate;
      this.recurringEndDate = this.recurringInfo.endDate;
      if (this.startDateInputs.selectableDates) {
        let time = new Date(this.localizationService.convertDateObjToAPIdate(this.recurringStartDate, '/')).getTime();
        let isStartDateValid = this.startDateInputs.selectableDates.filter(x => x.getTime() == time).length > 0;

        time = new Date(this.localizationService.convertDateObjToAPIdate(this.recurringEndDate, '/')).getTime();
        let isEndDateValid = this.startDateInputs.selectableDates.filter(x => x.getTime() == time).length > 0

        if (!isStartDateValid || !isEndDateValid) {
          this.recurringStartDate = this.recurringEndDate = currentSlotDate;
        }
      }
    }
    else {
      this.recurringStartDate = currentSlotDate;
      this.recurringEndDate = currentSlotDate;
    }
    this.setStartDate();
    this.setEndDate();
  }

  bindPlayerInfo(value) {
    let lessonBookingFormValues = value;
    if (lessonBookingFormValues && lessonBookingFormValues) {
      this.isRecurringDisable = lessonBookingFormValues.recurringId > 0 && !lessonBookingFormValues.isUpdateAllRecurring;
      if (lessonBookingFormValues.recurringEnabled && lessonBookingFormValues.recurringId > 0) {
        this.recurringInfo = lessonBookingFormValues.recurringDetail;
      }
    }
    if (lessonBookingFormValues) {
      this.playerInfoForm.patchValue(lessonBookingFormValues);
      this.playerCategoryChange({ value: lessonBookingFormValues.playerTypeRadio }, true)
      this.PaymentReferenceID = lessonBookingFormValues.paymentReferenceId;
      this.isCardOnFileDisabled = lessonBookingFormValues.playerTypeRadio == playerTypes.member ? true : false;
      this.IsMemberCategory = lessonBookingFormValues.playerTypeRadio == playerTypes.member ? true : false;
      this.playerCategorytype = (lessonBookingFormValues.playerTypeRadio == playerTypes.hotelReservation ||
        lessonBookingFormValues.playerTypeRadio == playerTypes.player);
      if (this.IsMemberCategory) {
        this._playerDataService.getMemberInfo(lessonBookingFormValues.playerLinkId, this.localizationService.convertDateToAPIdate(lessonBookingFormValues.date)).then(result => {
          console.log(result);
          this.setMemberShipDetails(result);
        });
      }
      this.selectedData = [lessonBookingFormValues];
      if (lessonBookingFormValues.allocationCode) {
        this.assignPlayerAndRateTypesBasedOnAllocation(lessonBookingFormValues.allocationCode);
      }
    }
  }

  async selectedChipDataEmit(arg) {
    this.playerInfoForm.controls.firstName.reset();
    this.playerInfoForm.controls.lastName.reset();
    this.playerInfoForm.controls.pronounced.reset();
    this.playerInfoForm.controls.playerLinkId.reset();
    this.playerInfoForm.controls.vipType.reset();
    this.playerInfoForm.controls.patronid.reset();
    this.playerInfoForm.controls.rank.reset();
    this.memberShipDetails = null;
    this.PaymentReferenceID = 0;
    if (arg && arg.length > 0) {
      if (this.IsMemberCategory || this.isMemberCategorySelected)
        this.setMemberShipDetails(arg[0]);
      //Added to get CMS Values
      const loyaltyDetail = arg[0].loyaltyDetail && arg[0].loyaltyDetail.length > 0 ? arg[0].loyaltyDetail[0] : null;
      let playerInfo: any;
      this._utilities.ToggleLoader(true);
      if (loyaltyDetail != null && this.cmsConfigured && loyaltyDetail?.patronId && loyaltyDetail.patronId != "") {
        let patronId = loyaltyDetail.patronId;
        playerInfo = await this._playerService.GetPlayerInformation(patronId);
        let cmsHasChange: boolean = false;
        cmsHasChange = this._playersBussiness.isCMSDataChanged(arg[0], playerInfo);

        if (cmsHasChange) {
          this._playersBussiness.UpdateCMSDetailOnExistingGuest(arg[0], playerInfo);
          this._playersBussiness.updateGuestDetails(arg);
        }
      }
      this.setSelectedPlayer(arg[0]);

      //End
      this._utilities.ToggleLoader(false);
      this.playerInfoForm.controls.patronid.setValue(arg[0]?.loyaltyDetail?.length > 0 ? arg[0].loyaltyDetail[0].patronId : '');
      this.playerInfoForm.controls.rank.setValue(arg[0]?.loyaltyDetail?.length > 0 ? arg[0].loyaltyDetail[0].rank : '');
      if (!this.playerInfoForm.dirty) this.playerInfoForm.markAsDirty();
    }
    else
      this.OnResetPlayerInfo();

    this.clearFilters();
    this.isSearchResultNameFieldDisabled = true;
    if (arg && arg.length > 0 && this.playerCategorytype)
      this.isSearchResultNameFieldDisabled = false;

    this.emitValueChange(true);
  }

  onChipRemove($event) {

  }

  setSelectedPlayer(selectedInfo: Tournament.UI.ContactInformation) {
    let phoneArr = [1, 2, 3];
    let emailArr = [9, 10];
    let phoneValues: PhoneDetail[] = [];
    let emailValues: any[] = [];
    let addressValues: any[] = [];
    let showPopup = (selectedInfo.playerContactLogs && selectedInfo.playerContactLogs.length > 0);
    if(showPopup){
        this.loadPlayerContactLogs(selectedInfo.playerContactLogs)
    }
    this.playerInfoForm.controls.firstName.setValue(selectedInfo.firstName);
    this.playerInfoForm.controls.lastName.setValue(selectedInfo.lastName);
    this.playerInfoForm.controls.playerLinkId.setValue(selectedInfo.playerLinkId);
    this.playerInfoForm.controls.activityId.setValue(selectedInfo.activityId);
    this.playerInfoForm.controls.bagNumber.setValue(selectedInfo.bagNumber);
    this.playerInfoForm.controls.pronounced.setValue(selectedInfo.pronounced);
    if (selectedInfo.playerType) {
      const playerType = this.playerTypes.find(x => x.id == Number(selectedInfo.playerType));
      if (playerType) {
        this.playerInfoForm.controls.playerType.setValue(selectedInfo.playerType);
        if (selectedInfo.rateType) {
          const rateType = this.rateTypes.find(x => x.id == Number(selectedInfo.playerType));
          if (rateType) {
            this.playerInfoForm.controls.rateType.setValue(selectedInfo.rateType);
          }
        }
      } else {
        this.showPlayerTypeError();
        this.playerInfoForm.controls.playerType.setValue('');
      }
    }

    this.packageCode = selectedInfo.packageName ? selectedInfo.packageName : "";
    this.PaymentReferenceID = selectedInfo.paymentReferenceId;
    let previousAddressId: number = this._playerDetailService?.lessonBookingFormValues?.playerContactForm && this._playerDetailService?.lessonBookingFormValues?.playerContactForm?.addressId;
    let PlayerAddress = {} as Tournament.UI.PlayerAddress;
    if (this._playerDetailService?.lessonBookingFormValues?.playerContactForm)
      this._playerDetailService.lessonBookingFormValues.playerContactForm = (selectedInfo.playerAddress) ? selectedInfo.playerAddress : PlayerAddress;
    this.setMemberDetails(selectedInfo);
    if (selectedInfo.contactInformation && selectedInfo.contactInformation.length > 0) {
      selectedInfo.contactInformation.forEach((element, i) => {
        if (phoneArr.indexOf(element.type) > -1 && element?.value && element.value != "" && element.value != null) {
          phoneValues.push({
            id: element.id,
            phoneType: element.type,
            countryCode: this._utilities.getCountryCodeFromValue(element.type, element.value).toString(),
            phoneNumber: this._utilities.getPhoneNumberFromValue(element.type, element.value).toString(),
            extension: this._utilities.getExtensionFromValue(element.type, element.value),
            isPrimary: element.isPrimary,
            isPrivate: element.isPrivateInfo,
            primaryPhone: element.isPrimary,
            privateInformtionPhone: element.isPrivateInfo
          });
        }
        if (emailArr.indexOf(element.type) > -1 && element?.value && element.value != "" && element.value != null) {
          emailValues.push({
            id: element.id,
            emailType: element.type,
            emailId: element.value,
            primaryEmail: element.isPrimary,
            privateInformtionEmail: element.isPrivateInfo
          });
        }
      });
    }
    if (selectedInfo.playerAddress) {
      addressValues = [{ "addressDetails": selectedInfo.playerAddress.addressLine1 }
        , { "addressDetails": selectedInfo.playerAddress.addressLine2 },
      { "addressDetails": selectedInfo.playerAddress.addressLine3 }];
    }
    this._playerDetailService.lessonBookingFormValues = {
      playerInfoForm: {},
      playerContactForm: {
        phone: phoneValues,
        email: emailValues,
        address: addressValues,
        addressId: previousAddressId,
        postalCode: (selectedInfo.playerAddress) ? selectedInfo.playerAddress.zip : '',
        state: (selectedInfo.playerAddress) ? selectedInfo.playerAddress.state : '',
        country: (selectedInfo.playerAddress) ? selectedInfo.playerAddress.country : '',
        city: (selectedInfo.playerAddress) ? selectedInfo.playerAddress.city : '',
      },
      paymentInfoForm: {}
    };
    this.playerInfoForm.markAsDirty();
    this.ContactData.emit(this._playerDetailService.lessonBookingFormValues.playerContactForm);
  }

  loadPlayerContactLogs(playerContactLogs) {
    let filteredContactLog : any[] = [];
    playerContactLogs.forEach((contactLog,i)=>{
        let productId = parseInt(this.localizationService.GetPropertyInfo('ProductId'));
        let dueDate = contactLog.dueDate ? this._utilities.GetDateWithoutTime(this._utilities.getDate(contactLog.dueDate)) : null;
        let expiryDate = contactLog.expiryDate ? this._utilities.GetDateWithoutTime(this._utilities.getDate(contactLog.expiryDate)) : null;
        let propertyDate = this._utilities.GetDateWithoutTime(this._PropertyInformation.CurrentDate);
        if(contactLog.isAlert && dueDate && (expiryDate >= propertyDate || expiryDate == null)){
            if(contactLog.isPrivate && contactLog.productId == productId && dueDate.toDateString() == propertyDate.toDateString()){//&& contactLog.productId != 1) || !contactLog.isPrivate
                filteredContactLog.push(contactLog);
            }
            else if(!contactLog.isPrivate && dueDate.toDateString() == propertyDate.toDateString()){
                filteredContactLog.push(contactLog);
            }
        }
        else if(contactLog.isAlert && contactLog.dueDate == null && (expiryDate >= propertyDate || expiryDate == null)){
            if(contactLog.isPrivate && contactLog.productId == productId){
                filteredContactLog.push(contactLog);
            }
            else if(!contactLog.isPrivate){
                filteredContactLog.push(contactLog);
            }
        }
    });
    let isContactLogShow = filteredContactLog.length > 0;
    if (isContactLogShow) {
        let data;
        data = { headername : this.localizationService.captions.common.KindInformationAlart, headerIcon: 'icon-info-icon', buttonName: this.localizationService.captions.common.OK, type: 'CL', isPurged:false, contactLogs:filteredContactLog};
        const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
            width: '60%',
            height: '60%',
            hasBackdrop: true,
            panelClass: 'small-popup',
            data: data,
            disableClose: true,
        });
    }
}
  async setMemberDetails(selectedInfo) {
    if (selectedInfo.playerCategoryId == playerTypes.member) {
      let memPlayerType;
      let memRateType;
      if (selectedInfo.playerType)
        memPlayerType = this.playerTypes.find(p => p.name.toLowerCase() == selectedInfo.playerType.toLowerCase());
      let selectedPlayerType = memPlayerType ? memPlayerType : '';
      if (selectedInfo.rateType) {
        this.rateTypes = this.playerTypes.find(x => x.id == selectedPlayerType?.id)?.rateTypes;
        this.rateTypes = this.rateTypes?.filter(x => x.isActive);
        memRateType = this.rateTypes?.find(p => p.type.toLowerCase() == selectedInfo.rateType.toLowerCase());
      }
      let selectedRateType = memRateType ? memRateType : '';
      this.patchDefault(selectedPlayerType.id, selectedRateType.id);
      if (selectedInfo?.arAccountNumber && selectedInfo?.arAccountNumber != undefined) {
        this.playerInfoForm.get('arAccountNumber').setValue(selectedInfo?.arAccountNumber);
      }
    }
    else if (selectedInfo.playerCategoryId == playerTypes.player || selectedInfo.playerCategoryId == playerTypes.hotelReservation || selectedInfo.playerCategoryId == playerTypes.guest) {
      let extPlayerType, selectedPlayerType, extRateType, selectedRateType;
      if (selectedInfo.playerType) {
        extPlayerType = this.playerTypes.find(p => p.id == selectedInfo.playerType);
        selectedPlayerType = extPlayerType ? extPlayerType : '';
        if (selectedPlayerType) {
          let extRateType;
          this.rateTypes = selectedPlayerType ? this.playerTypes.find(x => x.id == selectedPlayerType.id).rateTypes : this.existingrateTypes;

          if (selectedPlayerType)
            this.rateTypes = this.rateTypes.filter(x => x.isActive);

          if (selectedInfo.rateType) {
            extRateType = this.rateTypes.find(p => p.id == selectedInfo.rateType);
            selectedRateType = extRateType ? extRateType : '';
          }
        }
      }
      else if (selectedInfo.rateType) {
        extRateType = this.existingrateTypes.find(x => x.id == selectedInfo.rateType);
        if (extRateType) {
          this.rateTypes = this.existingrateTypes;
          let previous = (this.playerInfoForm.controls.rateType.value == "") ? '' : this.playerInfoForm.controls.rateType.value;
          selectedRateType = extRateType ? extRateType : previous;
        }
      }
      if (this.packageCode && this.packageCode != "") {
        let packageLinkedTypes = await this._PlayerDetailBusiness.getPackageLinkedPlayerTypesRateTypes(this.packageCode);
        if (packageLinkedTypes) {
          this.packagePlayerTypes = packageLinkedTypes.playerTypes;
          this.packageRateTypes = packageLinkedTypes.rateTypes;
          let selectedData = this.SetPackageLinkedPlayerTypesRateTypes();
          selectedPlayerType = selectedData.selectedPlayerType;
          this.playerInfoForm.controls.playerType.setValue(selectedData.selectedPlayerType.id);
          this.playerInfoForm.controls.rateType.setValue(selectedData.selectedRateType.id);
        }
      }
      if (selectedPlayerType)
        this.playerInfoForm.controls.playerType.setValue(selectedPlayerType.id);
      else
        this.playerInfoForm.controls.playerType.reset();
      if (selectedRateType)
        this.playerInfoForm.controls.rateType.setValue(selectedRateType.id);
      else
        this.playerInfoForm.controls.rateType.reset();
    }
  }
  GetPackageLinkedRateType(rateTypes: RateTypeNew.RateType[], packageLinedRateType: RateTypeNew.RateType[]) {
    let extRateType: RateTypeNew.RateType;
    let selectedRateType;
    if (packageLinedRateType && packageLinedRateType.length == 1) {
      extRateType = packageLinedRateType[0];
    }
    let packageLinkedSingleRateType = rateTypes.find(x => extRateType && x.id == extRateType.id);
    if (packageLinkedSingleRateType) {
      selectedRateType = packageLinkedSingleRateType;
      return selectedRateType;
    }
    else {
      return null;
    }
  }

  SetPackageLinkedPlayerTypesRateTypes() {
    let selectedPlayerType, selectedRateType;
    let extPlayerType: PlayerTypeNew.PlayerType;
    if (this.packagePlayerTypes && this.packagePlayerTypes.length == 1) {
      extPlayerType = this.packagePlayerTypes[0];
      let isPlayerTypeLinked = this.playerTypes.find(x => x.id == extPlayerType.id);
      if (isPlayerTypeLinked) {
        selectedPlayerType = extPlayerType;
        this.rateTypes = this.playerTypeRateType.find(x => x.id == extPlayerType.id).rateTypes.filter(x => x.isActive);
        selectedRateType = this.GetPackageLinkedRateType(this.rateTypes, this.packageRateTypes);
      }
    }

    return {
      selectedPlayerType: selectedPlayerType,
      selectedRateType: selectedRateType
    };
  }


  setdefaultRatePlayer(obtData) {
    let defaultPlayertype;
    let defaultRatetype;
    switch (obtData) {
      case (1):
      case (4):
        {
          defaultPlayertype = this._SystemdefaultsInformationService.GetDefaultNonMemberPlayerType();
          defaultRatetype = this._SystemdefaultsInformationService.GetDefaultNonMemberRateType();
        }
        break;

      case (2): {
        defaultPlayertype = this._SystemdefaultsInformationService.GetDefaultResortPlayerType();
        defaultRatetype = this._SystemdefaultsInformationService.GetDefaultResortRateType();
      }
        break;

      case (3): {
        defaultPlayertype = this._SystemdefaultsInformationService.GetDefaultMemberPlayerType();
        defaultRatetype = this._SystemdefaultsInformationService.GetDefaultMemberRateType();
      }
        break;
    }
    this.patchDefault(defaultPlayertype.id, defaultRatetype.id);
  }

  patchDefault(plyerId, rateId) {
    let playerTypes = this.playerTypes.find(x => x.id === plyerId);

    if (playerTypes && playerTypes.id)
      this.rateTypes = this._PlayerDetailBusiness.getRateTypesForPlayer(this.playerTypeRateType, playerTypes.id);

    let rateTypes = this.rateTypes?.find(x => x.id === rateId);
    this.playerInfoForm.patchValue({
      playerType: playerTypes && playerTypes.id ? playerTypes.id : '',
      rateType: rateTypes && rateTypes.id ? rateTypes.id : ''
    });
  }

  allocationCodesPatch() {
    let getAllocation = this.allocationCodes && this.allocationCodes.find(x => x.id === (this._SystemdefaultsInformationService.GetDefaultBlockingCode() && this._SystemdefaultsInformationService.GetDefaultBlockingCode().id))
    this.playerInfoForm.get('allocationCode').setValue(getAllocation ? getAllocation.id : '');
  }

  async playerCategoryChange(arg, isFromEdit: boolean = false) {
    var playerCategoryObj = await this.ValidateBreakPointPlayerCategory(arg.value, true);
    var playerCategory = this.lastUpdatePlayerCategoryType = playerCategoryObj.playerCategory;
    var isValidBreakPoint = playerCategoryObj.isValid;
    this.resetPlayerSearchCategory = !this.resetPlayerSearchCategory;//Resets Player search category in Hotel reservation,Existing Player
    this.IsMemberCategory = (playerCategory == playerTypes.member) ? true : false;
    this.playerCategoryInput.scheduleDate = this.lessonDate;
    if (isValidBreakPoint && !isFromEdit) this.OnResetPlayerInfo();
    this.playerCategoryInput.form.controls.playerTypeRadio.setValue(playerCategory);
    this.playerInfoForm.controls.playerTypeRadio.setValue(playerCategory);
    this.playerCategoryInput = _.cloneDeep(this.playerCategoryInput);
    this.isMemberCategorySelected = this.playerInfoForm.controls.playerTypeRadio.value === this.selectedGuestType.member
    this.setSearchFilter(playerCategory);
    if (this.editData) {
      this.playerInfoForm.patchValue({
        playerType: this.editData.playerType,
        rateType: this.editData.rateType,
      });
    }
    else {
      this.setdefaultRatePlayer(playerCategory);
    }
    this.playerTypeChanged({ value: this.playerInfoForm.controls.playerType.value });
  }
  async ValidateBreakPointPlayerCategory(playerCategory, isFromEdit: boolean = false) {
    var isValid: boolean = true;
    if (playerCategory === playerTypes.newPlayer && !isFromEdit) {
      this.breakpointAccess = await this._playersBussiness.ValidateBreakPoint(UserAccessBreakPoints.PLAYERINFORMATION, false);
      if (this.breakpointAccess && !this.breakpointAccess.isAllow) {
        const bpMessage = this.localizationService.captions.breakpoint[UserAccessBreakPoints.PLAYERINFORMATION];
        this._playersBussiness.showBreakPointPopup(bpMessage);
        playerCategory = this.lastUpdatePlayerCategoryType // this.selectedData.length > 0 ? this.lastUpdatePlayerCategoryType : (this._playerBusiness?.defaultSettings && this._playerBusiness.defaultSettings?.defaultPlayerCategory != playerTypes.newPlayer ? this._playerBusiness.defaultSettings.defaultPlayerCategory : playerTypes.player);
        isValid = false;
      }
    }
    return { playerCategory: playerCategory ? playerCategory : playerTypes.player, isValid };
  }
  setSearchFilter(selectedType) {
    switch (selectedType) {
      case playerTypes.newPlayer: {
        this.resetFilters()
        this.isSearchResultNameFieldDisabled = false;
        break;
      }
      case playerTypes.member: {
        this.clearFilters();
        this.isCardOnFileDisabled = true;
        this.IsMemberCategory = true;
        this.playerCategorytype = false;
        break;
      }
      case playerTypes.hotelReservation: {
        this.clearFilters()
        this.playerCategorytype = true;
        break;
      }
      case playerTypes.player: {
        this.clearFilters()
        this.playerCategorytype = true;
        break;
      }
    }
    this.emitValueChange(true);
  }

  //Forsearchfilters

  clearFilters() {
    this.isSearchResultFieldDisabled = (this.selectedData.length > 0) ? false : true;
    this.isSearchResultNameFieldDisabled = (this.selectedData.length > 0) ? false : true;
    //this.isCardOnFileDisabled = (this.selectedData.length > 0) ? true : false;
    this.IsMemberCategory = (this.selectedData.length > 0) ? true : false;
  }

  resetFilters() {
    this.isSearchResultFieldDisabled = false;
    this.isSearchResultNameFieldDisabled = false;
    this.isCardOnFileDisabled = false;
    this.IsMemberCategory = false;
  }
  //Forsearchfilters

  OnResetPlayerInfo() {
    this.playerInfoForm.controls.firstName.reset();
    this.playerInfoForm.controls.lastName.reset();
    this.playerInfoForm.controls.pronounced.reset();
    this.playerInfoForm.controls.playerLinkId.reset();
    this.playerInfoForm.controls.patronid.reset();
    this.playerInfoForm.controls.rank.reset();
    this.playerInfoForm.controls.arAccountNumber.reset();
    this.memberShipDetails = null;
    this.selectedData = [];//reset setced ite in searchBox
    let obj = {
      address: [],
      addressId: 0,
      city: "",
      country: "",
      email: [],
      isCategoryChanged: false,
      phone: [],
      postalCode: undefined,
      state: ""
    }
    this.ContactData.emit(obj);
    if (this._playerDetailService.lessonBookingFormValues && this._playerDetailService.lessonBookingFormValues.playerContactForm) {
      this._playerDetailService.lessonBookingFormValues.playerContactForm.phone = [];
      this._playerDetailService.lessonBookingFormValues.playerContactForm.email = [];
      this._playerDetailService.lessonBookingFormValues.playerContactForm.address = [];
      this._playerDetailService.lessonBookingFormValues.playerContactForm.addressLine1 = '';
      this._playerDetailService.lessonBookingFormValues.playerContactForm.addressLine2 = '';
      this._playerDetailService.lessonBookingFormValues.playerContactForm.addressLine3 = '';
      this._playerDetailService.lessonBookingFormValues.playerContactForm.city = '';
      this._playerDetailService.lessonBookingFormValues.playerContactForm.state = '';
      this._playerDetailService.lessonBookingFormValues.playerContactForm.country = '';
      this._playerDetailService.lessonBookingFormValues.playerContactForm.postalCode = '';
      // this._playerDetailService.lessonBookingFormValues.playerContactForm.addressId = '';
      this._playerDetailService.lessonBookingFormValues.playerContactForm.zip = '';
    }
  }

  selectedChipMemberDataEmit(eve) {
    console.log('selectedChipMemberDataEmit', eve);
  }

  selectedChipHotelDataEmit(eve) {
    console.log('selectedChipHotelDataEmit', eve);
  }
  chipSearchMemberTextEmit(eve) {
    console.log('chipSearchMemberTextEmit', eve);
  }
  chipSearchHotelTextEmit(eve) {
    console.log('chipSearchHotelTextEmit', eve);
  }

  async onRecurringChangeEvent(eve) {
    if (eve) {
      this.addRecurringControl();
      if (this.instructorId && this.instructorId > 0) {
        await this.setRecurringAvailableDates();
      } else {
        this.setRecurringInfo();
      }
    } else {
      this.removeRecurringControl();
      //this.playerInfoForm.setControl('recurringData', null);
      this.recurringInfo = null;
    }
  }

  async setRecurringAvailableDates() {
    const availableDays = await this._PlayerDetailBusiness.getInstructorSchedules(this.instructorId);
    this.startDateInputs.minDate = availableDays[0];
    this.startDateInputs.maxDate = availableDays[availableDays.length - 1];
    this.startDateInputs.selectableDates = availableDays;
    this.startDateInputs = { ...this.startDateInputs };
    setTimeout(() => {
      this.setRecurringInfo();
    }, 1000);
  }

  recurringSelected(event) {
    //this.playerInfoForm.setControl('recurringData', event);
  }

  onChangeEvent(eve) {
    if (eve) {
      //considering 0 as valid value in required validator so patching ''
      if (this.playerInfoForm.controls['allocationCode'].value == 0) { this.playerInfoForm.controls['allocationCode'].setValue(''); }
      if (this.playerInfoForm.controls['playerType'].value == 0) { this.playerInfoForm.controls['playerType'].setValue(''); }
      if (this.playerInfoForm.controls['rateType'].value == 0) { this.playerInfoForm.controls['rateType'].setValue(''); }

      this.playerInfoForm.controls['playerType'].setValidators([Validators.required]);
      this.playerInfoForm.controls['rateType'].setValidators([Validators.required]);
      this.playerInfoForm.controls['allocationCode'].setValidators([Validators.required]);
      this.playerInfoForm.controls['playerType'].updateValueAndValidity();
      this.playerInfoForm.controls['rateType'].updateValueAndValidity();
      this.playerInfoForm.controls['allocationCode'].updateValueAndValidity();
    }
    else {
      this.playerInfoForm.controls['playerType'].setValidators([]);
      this.playerInfoForm.controls['rateType'].setValidators([]);
      this.playerInfoForm.controls['allocationCode'].setValidators([]);
      this.playerInfoForm.controls['playerType'].updateValueAndValidity();
      this.playerInfoForm.controls['rateType'].updateValueAndValidity();
      this.playerInfoForm.controls['allocationCode'].updateValueAndValidity();
    }
    this.emitValueChange(false);
    this._cdr.detectChanges();
  }

  private disableControls() {
    if (this._PlayerDetailBusiness.isViewOnly) {
      this.viewOnly = true;
    }
  }

  playerWorthDetails(event) {
    this._guestService.openDialogPopup(this.playerInfoForm.controls.patronid.value);
  }


  setMemberShipDetails(data) {
    let availableRounds: number = this._utilities.getAvailableRounds(data);
    let creditBookBalance: number = this._utilities.getCreditBookBalance(data);
    this.memberShipDetails = {
      membershipExpiryDate: data.membershipExpiryDate,
      membershipRenewalDate: data.membershipRenewalDate,
      membershipStatus: data.membershipStatus,
      membershipType: data.membershipType,
      golfPoints: availableRounds,
      creditBookBalance: creditBookBalance
    }
  }

  private checkAlloctionBlockAndPlayerTypePermission(): boolean {
    let res = false;
    if (this.isAllocationBlockEnabled) {
      const allocationBlockId = this.playerInfoForm.controls['allocationCode'].value;
      const playerTypeId = this.playerInfoForm.controls['playerType'].value;
      if (allocationBlockId || playerTypeId) {
        if (allocationBlockId) {
          const allocationBlocks = this.allocationCodePermissions.filter(x => x.allocationBlockId == allocationBlockId);
          if (allocationBlocks.length == 0) {
            const allocationBlockName = this.allocationCodes.find(x => x.id === allocationBlockId);
            this._utilities.showAllocationCodePermissionDeniedPopup(allocationBlockName.name, this.localizationService.captions.common.MoveNotAllowed);
            res = true;
          } else {
            if (playerTypeId) {
              const allocationPlayer = allocationBlocks.filter(x => x.playerTypeId == playerTypeId);
              if (allocationPlayer.length == 0) {
                const playerType = this.playerTypes.find(x => x.id === playerTypeId);
                this._utilities.showAllocationCodePermissionDeniedPopup(playerType.name, this.localizationService.captions.common.MoveNotAllowed);
                res = true;
              }
            }
          }
        } else {
          const allocationPlayerBlocks = this.allocationCodePermissions.filter(x => x.playerTypeId == playerTypeId);
          if (allocationPlayerBlocks.length == 0) {
            const playerType = this.playerTypes.find(x => x.id === playerTypeId);
            this._utilities.showAllocationCodePermissionDeniedPopup(playerType.name, this.localizationService.captions.common.MoveNotAllowed);
            res = true;
          }
        }
      }
    }
    return res;
  }

  async CreatePlayerPopup(arg) {
    let popUpTitle = this.localizationService.captions.guest.players.createPlayers;
    var defaultSettings = this.defaultSettings ? this.defaultSettings : await this._playersBussiness.getDefaultSettings()
    this.componentDetails = {
      componentName: CreatePlayerComponent,
      popUpDetails: {
        isStepper: false,
        bindData: arg,
        eventName: 'notifyParent'
      }
    };
    this._cdr.detach()
    const dialogRef = this.dialog.open(CommonPopupComponent, {
      maxWidth: '95vw',
      width: '95%',
      height: '90%',
      disableClose: true,
      data: {
        title: popUpTitle,
        update: popUpTitle === 'EDIT PLAYER' ? this.localizationService.captions.settings.editPopSave : this.localizationService.captions.settings.createPopSave,
        cancel: this.localizationService.captions.guest.players.cancel, componentDetails: this.componentDetails,
        actionType: popUpTitle === 'EDIT PLAYER' ? ButtonAction.update : ButtonAction.save,
        defaultSettings: defaultSettings
      }
    });

    this.createPlayerPopupSubscription = dialogRef.afterClosed().subscribe(result => {
      this._cdr.reattach();
      if (result[0] === ButtonAction.save) {
        // Handle After Saving functionality here
        this.GetPlayerByLinkId(result[1]);
      }
    }
    );
  }

  async GetPlayerByLinkId(playerLinkID) {
    let isTeeTimeBook : boolean = true;
    const playerDetail = await this._playerDataService.GetPlayerByPlayerLinkId(playerLinkID,isTeeTimeBook);
    this.selectedData = [playerDetail];
    this.selectedChipDataEmit([playerDetail]);
  }

  private addRecurringControl() {
    this.playerInfoForm.addControl("startDate", new UntypedFormControl('', [Validators.required]));
    this.playerInfoForm.addControl("endDate", new UntypedFormControl('', [Validators.required]));
    this.intializeControls();
  }

  private removeRecurringControl() {
    this.startDateInputs = {};
    this.endDateInputs = {};
    this.playerInfoForm.removeControl("startDate");
    this.playerInfoForm.removeControl("endDate");
  }

  private intializeControls() {
    this.startDateInputs = {
      className: 'golf-form-control--lg',
      errorMessage: this.errorCaptions.startDateError,
      inValidDate: this.errorCaptions.invalidDateError,
      form: this.playerInfoForm,
      formControlName: 'startDate',
      placeHolder: this.captions.startDate,
      isDateRequired: true
    };
    this.endDateInputs = {
      className: 'golf-form-control--lg',
      errorMessage: this.errorCaptions.endDateError,
      inValidDate: this.errorCaptions.invalidDateError,
      form: this.playerInfoForm,
      formControlName: 'endDate',
      placeHolder: this.captions.endDate,
      isDateRequired: true
    };
  }

  startDateChanged(eve) {
    if (eve && eve.length == 2) {
      this.recurringStartDate = eve[0].controls[eve[1]].value;
    }
    this.setEndDate();
  }

  endDateChanged(eve) {
    if (eve && eve.length == 2) {
      this.recurringEndDate = eve[0].controls[eve[1]].value;
    }
  }

  setStartDate() {
    const startDate = new Date(this.localizationService.convertDateToAPIdate(this.recurringStartDate, '/'));
    this.playerInfoForm.controls.startDate.setValue(startDate);
    this.playerInfoForm.controls.startDate.markAsTouched();
    this.playerInfoForm.controls.startDate.updateValueAndValidity();
  }

  setEndDate() {
    const startDate = new Date(this.localizationService.convertDateToAPIdate(this.recurringStartDate, '/'));
    if (this.recurringEndDate) {
      const endDate = new Date(this.localizationService.convertDateToAPIdate(this.recurringEndDate, '/'));
      if (startDate.getTime() > endDate.getTime()) {
        this.recurringEndDate = startDate;
      }
    } else {
      this.recurringEndDate = startDate;
    }
    if (this.playerInfoForm.controls.startDate.valid) {
      this.endDateInputs.minDate = startDate;
      this.endDateInputs.maxDate = this.startDateInputs.maxDate;
      this.endDateInputs.selectableDates = this.startDateInputs.selectableDates;
      this.endDateInputs = { ...this.endDateInputs };
    } else {
      this.endDateInputs.minDate = this.startDateInputs.minDate;;
      this.endDateInputs.maxDate = this.startDateInputs.maxDate;
      this.endDateInputs = { ...this.endDateInputs };
    }
    setTimeout(() => {
      const endDate = new Date(this.localizationService.convertDateToAPIdate(this.recurringEndDate, '/'));
      this.playerInfoForm.controls.endDate.setValue(endDate);
      this.playerInfoForm.controls.endDate.markAsTouched();
      this.playerInfoForm.controls.endDate.updateValueAndValidity();
    }, 1000);
  }

  showPlayerTypeError() {
    this._utilities.showCommonAlert(this.errorCaptions.allocationMappingMissing, AlertType.Warning, ButtonTypes.Ok);
  }

  copyPlayer(e) {

  }

}
