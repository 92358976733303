import { Injectable } from "@angular/core";
import { TeeGridService } from './tee-grid.service';
import { TeeSheetSkeletonData, ScheduleType, ScheduleStatus, TeeSheetPlayerDetails } from '../../models/teesheet.form.models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { MAX_PLAYERS_PER_SLOT } from '../../global.constant';
import { Query } from 'src/app/core/utilities/query.utility';
import { GolfUtilities } from '../../utilities/golf-utilities';


@Injectable()
export class TeeGridBusiness {




    constructor(private _TeeGridService: TeeGridService, private _localization: GolfLocalization, private _utilities: GolfUtilities) {

    }
    getCaptions() {
        return this._localization.captions.teetime;
    }

    getRestrictedDropSlots() {
        return this._TeeGridService.getRestrictedDropSlots();
    }

    public sortTees(left: TeeSheetSkeletonData, right: TeeSheetSkeletonData, sortBy: 'TIME' | 'HOLE'): number {
        let compare: number;

        if (sortBy === 'HOLE') {
            compare = this.sortByTeeHoles(left, right);
        } else if (sortBy === 'TIME') {
            compare = this.sortByTeeTime(left, right);
        }
        return compare;
    }


    public applyFullyTeeSheetFilter(hideCrossOverBlocks: boolean = false, showAvailableSlots: boolean = false) {
        const query: Query<TeeSheetSkeletonData> = new Query<TeeSheetSkeletonData>();
        query.and(this.hideCrossOverExpr(hideCrossOverBlocks))
            .and(this.showFullyAvailableSlots(showAvailableSlots))
        return query;
    }

    public slotsShouldAccomodateGivenPlayers(noOfPlayers: number[]) {
        let expressionToFilter = (s: TeeSheetSkeletonData) => true;
        if (noOfPlayers && noOfPlayers.length > 0) {
            expressionToFilter = (s: TeeSheetSkeletonData) => noOfPlayers.some(n =>
                s.playerDetail.length + n <= MAX_PLAYERS_PER_SLOT);
        }
        return expressionToFilter;
    }
    public formatDisplayName(player: TeeSheetPlayerDetails): string {
        return this._utilities.formatGuestName(player.firstName, player.lastName);
    }

    private sortByTeeHoles(left: TeeSheetSkeletonData, right: TeeSheetSkeletonData): number {
        const holeToInt = (hole: string) => parseInt(hole.replace(/\D/g, ''));    // func to convert tee hole to an integer 
        return holeToInt(left.hole) - holeToInt(right.hole);
    }

    private sortByTeeTime(left: TeeSheetSkeletonData, right: TeeSheetSkeletonData): number {
        const isoDateToTime = (date: string) => this._localization.getDate(date).getTime();  // func to convert API dateTime to time in milliseconds
        return isoDateToTime(left.time) - isoDateToTime(right.time);
    }

    public applyTeeSheetFilter(hideCrossOverBlocks: boolean = false, showAvailableSlots: boolean = false, noOfPlayers: number[] = []) {
        const query: Query<TeeSheetSkeletonData> = new Query<TeeSheetSkeletonData>();
        query.and(this.hideCrossOverExpr(hideCrossOverBlocks))
            .and(this.showAvailableOnlyExpr(showAvailableSlots))
            .and(this.playerFilterExpr(noOfPlayers));

        return query;
    }

    private hideCrossOverExpr(hideCrossOverBlocks: boolean): (s: TeeSheetSkeletonData) => boolean {
        return (s: TeeSheetSkeletonData) => (!hideCrossOverBlocks || s.type !== ScheduleType.crossOverBlock);
    }

    private showAvailableOnlyExpr(showAvailableSlots: boolean): (s: TeeSheetSkeletonData) => boolean {
        return (s: TeeSheetSkeletonData) => 
            (!showAvailableSlots || 
            (s.allocation && !s.allocation.closed && (s.status.scheduleStatus === ScheduleStatus.open || (s.status.scheduleStatus === ScheduleStatus.booked && s.playerDetail.length < 4))) ||
            (s.allocation && s.allocation.closed && s.playerDetail.length > 0 && s.playerDetail.length < 4))
            
     
    }

    private showFullyAvailableSlots(showAvailableSlots: boolean): (s: TeeSheetSkeletonData) => boolean {
        return (s: TeeSheetSkeletonData) => (!showAvailableSlots || s.status.scheduleStatus === ScheduleStatus.open)
    }

    private playerFilterExpr(noOfPlayers: number[] = []): (s: TeeSheetSkeletonData) => boolean {
        return (s: TeeSheetSkeletonData) => ((noOfPlayers.length === 0)
            || (s.type === ScheduleType.crossOverBlock) || (s.status.scheduleStatus === ScheduleStatus.hold) ||
            (s.playerDetail.length === 4) || (s.status.scheduleStatus === ScheduleStatus.frostDelay) || (noOfPlayers.some(n => s.playerDetail.length + n <= MAX_PLAYERS_PER_SLOT)));
    }


}
