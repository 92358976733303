<form [formGroup]="mailForm" class="send-email-wrapper h-100">
  <h1 class='ag_p--4 ag_m--0 ag_display--flex' mat-dialog-title>{{captions.hdr_SendEmail}}
    <i class='icon-close ag_ml--auto ag_cursor--pointer' aria-hidden="true"
      (click)="closePopUp(DialogCloseEnum.Close)">&nbsp;</i>
  </h1>
  <div class="ag_p--5" *ngIf="false">
    <app-ag-mail-id [inputs]="mailInputs" [values]="mailInfo"></app-ag-mail-id>
  </div>
  <div class="ag_p--5 send-email-inner-wrapper">
    <div class="w-100">
      <label [ngClass]="!mailInfo.length > 0
      ? 'text-danger'
      : ''" class="ag_mandatory-lbl"  [attr.LiteralID]="'lbl_showEmailCustomLabel'" *ngIf="showEmailCustomLabel">
        {{captions.lbl_EnterTheEmail}}
      </label>
      <app-multi-mail-search [emails]="mailInfo" (onChipRemove)="removedatafromChip($event)"
        (onChipAdd)="AddDatatoChip($event)" (onError)="validateScreen($event)" [AddMailonChecked]="mailtochip"
        [RemoveMailonchecked]="removefromChip">
      </app-multi-mail-search>
      <div class="ag_display--flex">
        <div *ngFor='let data of nameListArray; let i= index' class="w-50">
          <mat-checkbox class="example-margin ag_mr--4 text-ellipsis" formControlName="guestName"
            [checked]="data.checked" (change)='onCheckSelection($event,data)'>
            {{data.name}}
          </mat-checkbox>
        </div>
      </div>

    </div>
  </div>
  <div class="ag_footer--actions ag_display--flex">
    <app-button [buttontype]='actionButton' (valueChange)='onAction($event)'></app-button>
    <app-button [buttontype]='cancelButton' (valueChange)='onCancel($event)'></app-button>
  </div>
</form>
