import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Localization } from 'src/app/common/localization/localization';
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
@Component({
  selector: 'app-over-lapping-player-details',
  templateUrl: './over-lapping-player-details.component.html',
  styleUrls: ['./over-lapping-player-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class OverLappingPlayerDetailsComponent implements OnInit {
  tableData: { playerName:string, playerLinkId: string, course: string, teeTime: string ,proceedBooking: boolean}[] = [];
  tableHeaders: TableHeaderOptions[];
  tableOptions: TableOptions;
  title: string = '';
  buttonObj: { customSaveText: any; disabled: boolean; isEdit: boolean; };
  dialogData: any;
  proceedMsg: any;

  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<OverLappingPlayerDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private localization: Localization,) 
  {
    this.dialogData = data;
    this.title = this.localization.captions.teetime.overlappingPlayers;
    this.proceedMsg = this.localization.captions.teetime.overlapProceedMessage;
    this.tableData = data?.guestDataWithOverLap;
    this.tableData = [...this.tableData];
  
  }

  ngOnInit(): void 
  {
    this.tableHeaders = this.dialogData.tableHeaders;
    this.tableOptions = this.dialogData.tableOptions;
    this.buttonObj = {
      customSaveText: this.localization.captions.teetime.btn_proceed,
      disabled: false,
      isEdit: false
    };
  }

  close() {
    this.dialogRef.close(false);
  }
  
  save(event){
    console.log(event);
    this.dialogRef.close(true);
  }

}
