interface String {
    interpolate(object: any): string;
    containsSpecialChar(): boolean;
}


(function () {

    String.prototype.interpolate = function (object: any): string {
        object = object ? object : {};
        let keys: string[] = Object.keys(object);
        var _this = this;
        for (let i = 0; i < keys.length; i++) {
            var regEx = new RegExp("{" + keys[i] + "}", "ig");
            _this = _this.replace(regEx, object[keys[i]]);
        }
        return _this;
    }

    String.prototype.containsSpecialChar = function (): boolean {
        //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
        var regex = /^[A-Za-z0-9 ]+$/
        return !regex.test(this);
    }

})();