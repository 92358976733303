import { Injectable } from '@angular/core';
import { GolfLocalization } from '../core/localization/golf-localization';

@Injectable()
export class TeeTimeService {

  constructor(private _Localization: GolfLocalization) { }

  getCaptions() {
    return this._Localization.captions.subMenu;
  }
}
