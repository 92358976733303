<app-dialog-header [title]="title" (closeDialog)="close()"></app-dialog-header>

<div class="CreateMasterComponentLinking-wrapper ag_container--padding-md">
    <form class="CreateMasterComponentLinking-form" [formGroup]="createForm" autocomplete="off">
        <ag-input class='ag_display--block ag_form-control--sm' formControlName='key' [placeholder]="captions.lbl_key"
            [required]='true'></ag-input>
        <ag-input class='ag_display--block ag_form-control--sm ag_mt--4' formControlName='value'
            [placeholder]="captions.lbl_value" [required]='true'>
        </ag-input>
        <ag-input class='ag_display--block ag_form-control--sm ag_mt--4' formControlName='typeId' type='number'
            [placeholder]='captions.lbl_typeId'></ag-input>
    </form>
</div>

<app-dialog-footer [buttonObj]="buttonObj" (cancel)="close()" (save)="save($event)"></app-dialog-footer>