import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GolfRoutes } from 'src/app/core/extensions/golf-route';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { rateType } from 'src/app/settings/golf-setup/code-linking/code-link-modal';
import { player } from 'src/app/settings/golf-setup/tournament-setup/tournament-format/create-tournament-format-modal/create-tournament-model';
import { RetailItem } from 'src/app/settings/settings-shared/assign-retail-item/assign-retail-item.model';
import { GolfScheduleCommunication } from 'src/app/shared/communication/services/golfschedule.service';
import { RetailmanagementCommunication } from 'src/app/shared/communication/services/retailmanagement.service';
import { RetailPosCommunication } from 'src/app/shared/communication/services/retailpos.service';
import { ContactInformation } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { API } from 'src/app/tee-time/tournaments/tournaments-modal/contact/contact.model';
import { Tournament } from 'src/app/tee-time/tournaments/tournaments.model';
import { RateSetupData } from 'src/app/settings/rate-setup/rate-setup.model';



@Injectable({ providedIn: 'root' })
export class LinkMultipackDataService {
constructor(private localization: GolfLocalization, private _golfScheduleCommunication: GolfScheduleCommunication,private _retailCommunication:RetailmanagementCommunication, private _retailPos: RetailPosCommunication) { }

async GetPlayersByIds(lstPlayerId: number[]): Promise<player[]> {
    let result: player[] = await this._golfScheduleCommunication.postPromise<player[]>({
        route: GolfRoutes.GetPlayersByIds,
        body: lstPlayerId
    });
    return result;
}  

async GetRateType(showInactiveRateTypes: boolean): Promise<rateType[]>{
    let result: rateType[] = await this._golfScheduleCommunication.getPromise<rateType[]>({
        route: GolfRoutes.GetAllRateTypes,
        uriParams: { showInactive: showInactiveRateTypes }
    });
    return result;
}

async GetRetailItemsByIds(retailItemIds:number[]): Promise<RetailItem[]> {
    let result: RetailItem[] = await this._retailCommunication.putPromise<RetailItem[]>({
        route : GolfApiRoute.GetAllActiveRetailItemsByIds,
        body : retailItemIds
    });
    return result;
}

async LinkMultiPack(params:any[])
{
    return await this._retailCommunication.putPromise<boolean>({
        route : GolfApiRoute.LinkMultiPack,
        body : params
    });
}

async UnLinkMultiPack(params:any[])
{
    return await this._retailCommunication.putPromise<boolean>({
        route : GolfApiRoute.UnLinkMultiPack,
        body : params
    });
}

async GetMultiPackDetails(session : any[])
{
    let result: any[] = await this._retailPos.putPromise<any[]>({
        route : GolfApiRoute.GetPlayerMultiPack,
        body : session
    })
    return result;
}

async UpdatePlayerMultipackDetails(playerMultipackDetails: any[])
{
    return await this._golfScheduleCommunication.putPromise<any[]>({
        route : GolfApiRoute.UpdatePlayerMultipack,
        body : playerMultipackDetails
    })
}

public async getTeeFeesForCourseAndDate(courseId: number, date: Date): Promise<RateSetupData[]> {
    const _date: string = this.localization.ConvertDateToISODateTime(date);
    return await this._golfScheduleCommunication.getPromise<RateSetupData[]>(
        { route: GolfApiRoute.GetTeeFeeForCourseAndDate,
            uriParams: { date: _date, courseId: courseId } });
   
}    

}