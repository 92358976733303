import { UntypedFormGroup } from '@angular/forms';

export interface Captions {
    playerType: string;
    newPlayer: string;
    member: string;
    hotelReservation: string;
    hotelReservationNo: string;
    playerInfo: string;
    firstName: string;
    missingfirstName: string;
    lastName: string;
    missinglastName: string;
    phone: string;
    home: string;
    work: string;
    email: string;
    personal: string;
    rateType: string;
    course: string;
    requestedDate: string;
    requestedTime: string;
    noOfPlayers: string;
    comments: string;
    memberNo: string;
    searchByMemNameMemNo: string;
    searchByGuestNameHotelReservationNo: string;
    mobile: string;
    phoneNumber: string;
    missingRatetype: string;
    missingPlayertype: string;
    missingCourse: string;
    enterNumberofplayers: string;
    enterDate: string;
    enterTime: string;
    invalidEmail: string;
    extension: string;
    searchByConfNoAndGuestName: string;
    searchByPlayerName: string;
    player: string;
}

export interface Options {
    id: any;
    label: any;
    ischecked?: boolean;
    value?: any;
}


export interface RadioArray {
    [index: number]: RadioArrayCategory
}

export interface RadioArrayCategory {
    id: number;
    value: string;
    checked: boolean;
    viewValue: string
}
export interface ContactType {
    id?: number;
    description?: string;
    type?: string;
}

export interface playerType {
    id?: number;
    description?: string;
    type?: string;
}

export interface WaitlistResponse {
    isWaitListSuccess: boolean;
    showMessageInUI: boolean;
    infoCode: number;
}
export interface rateType {
    id?: number;
    description?: string;
    type?: string;
}
export interface course {
    id?: number;
    description?: string;
    type?: string;
    name?:string
}

export interface DatePickerInputs {
    className?: string;
    form?: UntypedFormGroup;
    formControlName?: string;
    errorMessage?: string;
    minDate?: Date;
    placeHolder?: string;
    value?: Date;
}


export enum playerRdioTypeenum {
    newPlayer = 1,
    member = 2,
    hotelReservation = 4


}

export enum contactenum {
    Phone = "Phone",
    Email = "Email"

}
