import { Pipe, PipeTransform } from '@angular/core';
import { GolfUtilities } from '../utilities/golf-utilities';
@Pipe({
  name: 'itnerary'
})
export class ItneraryPipe implements PipeTransform {
  constructor(private _Utilities: GolfUtilities) { }
  transform(value: any, slotTime: any, day: any, type: number, calenderValue: any, dateRangeObj: any): any {
    const serviceTime = this._Utilities.format24HrTime(value.orgStartDateTime);
    const startTime = this._Utilities.format24HrTime(slotTime.startTime);
    let endTime = this._Utilities.format24HrTime(slotTime.endTime);
    if (startTime === '23:00') {
      endTime = '23:59';
    }
    let dayId;
    let serviceDay;
    let startDate;
    let endDate;
    let serviceDate;
    let preCondition = false;
    switch (type) {
      case 2:
        serviceDate = value.orgStartDateTime.getTime();
        startDate = dateRangeObj.start.getTime();
        endDate = dateRangeObj.end.getTime();
        preCondition = serviceDate >= startDate && serviceDate <= endDate;
        serviceDay = this.datetoDay(value.orgStartDateTime);
        dayId = day.code;
        break;
      case 3:                      
        preCondition = true;
        serviceDay = value.orgStartDateTime.getDate();
        dayId = Number(day);
        break;
    }
    if (preCondition && serviceTime >= startTime && serviceTime < endTime && serviceDay === dayId) {
      return value.packageName;
    }
  }

  datetoDay(e) {
    let dayValue;
    const dow = e.getDay();
    switch (dow) {
        case 0:
            dayValue = 'Sun';
            break;
        case 1:
            dayValue = 'Mon';
            break;
        case 2:
            dayValue = 'Tue';
            break;
        case 3:
            dayValue = 'Wed';
            break;
        case 4:
            dayValue = 'Thu';
            break;
        case 5:
            dayValue = 'Fri';
            break;
        case 6:
            dayValue = 'Sat';
            break;
        default:
            dayValue = 'Sat'
    }
    return dayValue;
}

}
