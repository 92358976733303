import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { cloneDeep, uniq } from 'lodash';
import { UserAccessBreakPoints } from 'src/app/common/constants/useraccess.constants';
import { UserAccessBusiness } from 'src/app/common/dataservices/authentication/useraccess.business';
import { EventDataServices } from '../../dataservices/notification/event.data.service';
import { TemplateDataServices } from '../../dataservices/notification/template-data-service';
import { Localization } from '../../localization/localization';
import { AlertAction, AlertType, ButtonTypes, DmConfig } from '../../Models/common.models';
import { CommonDataAwaiters } from '../../shared/events/awaiters/common.data.awaiters';
import { GridType } from '../../shared/shared/globalsContant';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { EventNotification, Events } from '../notification-configuration/notification-configuration.model';
import { NotificationAPITemplate, NotificationTemplate, NotificationType } from '../template-email/crud-email-template/crud-email-template.model';
import { TemplateServiceCommunication } from '../templates.service';
import { CrudSMSTemplatesBusiness } from './crud-sms-template/crud-sms-template.business';

@Component({
  selector: 'app-template-sms',
  templateUrl: './template-sms.component.html',
  styleUrls: ['./template-sms.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CrudSMSTemplatesBusiness, TemplateDataServices, TemplateServiceCommunication, EventDataServices]
})
export class TemplateSmsComponent implements OnInit {
  @Output() emittedSmsTempldata: EventEmitter<any> = new EventEmitter();
  @Output() copySmsEventEmitter: EventEmitter<any> = new EventEmitter();
  @Input() isCopyEnabled;
  @Input() istoggleDirectEmit:boolean;
  
  events: Events[];
  smstemplateCaptions: any;
  tableoptions: any;
  tabledata: NotificationTemplate[];
  hideCrudSmsWindow: boolean = true;
  IsViewOnly: boolean = false;
  onEditDisableFunction: boolean = true;
  showInactiveToggle = false;
  smstemplateSettingsCaptions: any;
  doneEventObj: any;
  selectedFilterData: any;
  defaultData: any = {
    id: 0,
    code: 'ALL',
    description: this.localization.captions.common.all
  };
  productId: number;
  originalTemplateData: NotificationTemplate[];
  uniqueFilterData: any;
  modifiedEvents: Events[] = [];
  SMSFilterselectionData: any[] = [];
  @Input() showTableRecord: boolean;
  dmConfig: DmConfig;
  isIntakeForm: boolean;
  isToggleActive: boolean = false;
  isViewOnly = true;
  constructor(public localization: Localization, private _CMB: CrudSMSTemplatesBusiness, private _templateServiceCommunication: TemplateServiceCommunication
    , private _eventBusiness: EventDataServices, private commonUtils: CommonUtilities,private _userAccessBusiness: UserAccessBusiness) {
    this.smstemplateCaptions = this.localization.captions.settings.utilities;
    this.smstemplateSettingsCaptions = this.localization.captions.settings;
  }

  ngOnInit() {
    this.productId = Number(this.localization.GetPropertyInfo('ProductId'));
    this.getSMSData(false);
  }
  async getSMSData(event) {
    await this.ValidateBreakPoint();
    await this.GetEvents();
    this.dmConfig = await this._CMB.getDmconfigSession();
    this.isIntakeForm = await CommonDataAwaiters.GetIntakeDetails();
    const templatedata = await this._CMB.getSMSTemplateData(NotificationType.SMS, event);
    for (let index = 0; index < templatedata.length; index++) {
      const data = templatedata[index];
      if (!this.dmConfig?.dmEformsConfig?.enableEforms && data.eventIds.some(id => id == 75)) {
        templatedata.splice(templatedata.indexOf(data), 1);
        index--;
      }
      if (!this.isIntakeForm && data.eventIds.some(id => id == 17)) {
        templatedata.splice(templatedata.indexOf(data), 1);
        index--;
      }
    }
    this.tabledata = this.MaptoUIMpdel(templatedata);
    this.originalTemplateData = cloneDeep(this.tabledata);
    let originalTemplateDataTemp:any = this.originalTemplateData.map(x=>x.eventIds)
    let singlearrayobj= originalTemplateDataTemp.flat();
    let uniqueFilterData:any=  uniq(singlearrayobj);
    this.modifiedEvents = this.events.filter(x=> {
      return (uniqueFilterData.indexOf(x.id)!=-1) 
    }) 
    this.BindsmsTableData(this.tabledata);
  }
  async ValidateBreakPoint() {
    const result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.SMSTemplate, true);
    this.IsViewOnly = result.isViewOnly;
  }
  async GetEvents() {
    let filterEvents: Events[] = await this._eventBusiness.getAllEventsByProductId(this.productId);    
    this.events = filterEvents.filter(o => o.id != EventNotification.IntakeForm
      && o.id != EventNotification.GolfNightAuditReminder
      && o.id != EventNotification.SpaNightAuditReminder
      && o.id != EventNotification.RetailNightAuditReminder
      && o.id != EventNotification.GolfNightAuditCompletion
      && o.id != EventNotification.SpaNightAuditCompletion
      && o.id != EventNotification.RetailNightAuditCompletion
      && o.id != EventNotification.GolfNightAuditFailure
      && o.id != EventNotification.SpaNightAuditFailure
      && o.id != EventNotification.RetailNightAuditFailure
      && o.id != EventNotification.GolfGuestDataRequest
      && o.id != EventNotification.SPAGuestDataRequest
      );
    this.modifiedEvents = cloneDeep(this.events)
  }


  MaptoUIMpdel(tabledata): NotificationTemplate[] {
    let NotificationTemplates: NotificationTemplate[] = [];
    tabledata.forEach(element => {
      let NotificationTemplate: any = {
        eventIds: element.eventIds,
        id: element.id,
        isActive: element.isActive,
        isDefault: element.isDefault,
        listOrder: element.listOrder,
        templateCode: element.templateCode,
        templateName: element.templateName,
        templateId: element.templateId,
        typeId: NotificationType.EMAIL,
        eventName: this.GetEventNames(element.eventIds)
      };
      NotificationTemplates.push(NotificationTemplate);
    });
    return NotificationTemplates;
  }

  GetEventNames(eventIds:number[]){
    const arr = this.events
    .filter(c => eventIds.includes(c.id))
    .map(dlc => dlc.eventName)
    .join(',');
    return arr.split(',');
  }

  BindsmsTableData(tabledata) {
    this.tableoptions = [{
      TableHdrData: this.GetsmsHeader(),
      TablebodyData: tabledata,
      pagination: false,
      sortable: true,
      CustomColumn: true,
      PlaceHoldertext: this.smstemplateCaptions.smsSrchTxt,
      EnableActions: true,
      SelectRows: false,    
      Searchable: GridType.customfield,
      EditMoreOption: true,
      Sortable: "listOrder",    
      disableDelete: false,
      showToggle: false,
      IsViewOnly: this.IsViewOnly,
      disableEditButton: false,
      TableDraggable: true,
      showTableRecords: this.showTableRecord,
      isCopyEnabled: this.isCopyEnabled,
      istoggleDirectEmit: this.istoggleDirectEmit,
      disableCopy: this.IsViewOnly
    }];

   
  }
  private GetsmsHeader() {
    return [
      { "title": this.smstemplateCaptions.event, "jsonkey": "eventName", "sortcolumndatatype": "string", "searchable": true, "sortable": true },
      { "title": this.smstemplateCaptions.smsTempCode, "jsonkey": "templateCode", "sortcolumndatatype": "string", "searchable": true, "sortable": true },
      { "title": this.smstemplateCaptions.smsTempNme, "jsonkey": "templateName", "searchable": true, "sortable": true },
      { "title": this.smstemplateCaptions.listOrder, "jsonkey": "listOrder", "searchable": false, "sortable": true, "alignType": "right" },
      { "title": this.smstemplateCaptions.default, "jsonkey": "isDefault", "searchable": false, "sortable": true, "type": "toggle" },
      { "title": this.smstemplateCaptions.active, "jsonkey": "isActive", "searchable": false, "sortable": true, "type": "toggle" }
    ];    
  }
  crudsmsTemplate() {
    this.emittedSmsTempldata.emit({ editSmsObj: null, closeCrudWindow: false, isViewOnly: this.IsViewOnly });
  }
  async DeleteRecords(eve) {
    if (this.showTableRecord) {
      const deleteWarningMessage = `${this.smstemplateCaptions.lbl_delete} ${eve[0].eventName}`;
      this.commonUtils.showCommonAlert(deleteWarningMessage, AlertType.Warning, ButtonTypes.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.delete(eve);
        }
        else {
          return;
        }
      });
    }
    else {
      this.delete(eve);
    }

  }
  async delete(eve) {
    this.tabledata = await this._CMB.DeleteTemplate(eve[0].id);
    await this.getSMSData(this.showInactiveToggle);
  }
  
  EditRecords(eve) {

    this.emittedSmsTempldata.emit({ editSmsObj: eve[0], closeCrudWindow: false, isViewOnly: this.IsViewOnly });
  }

  async showInactiveToggleEvent(event) {
    this.showInactiveToggle = event[0];
    await this.getSMSData(this.showInactiveToggle);
  }

  async DoneEvent(event) {
    this.isToggleActive = false;
    this.doneEventObj = event;
    if (event.isDefault) {
      let result = await this._CMB.DefaultTemplateExists(event.eventIds, event.id, NotificationType.SMS);
      if (result) {
        this._templateServiceCommunication.showConfirmationPopup(this.showConfirmationSuccessCallback.bind(this), this.showConfirmationErrorCallback.bind(this));
      } else {
        await this.SaveTemplate();
      }
    } else {
      await this.SaveTemplate();
    }
  }

  copyEvent(event) {
    this.copySmsEventEmitter.emit({ editSmsObj: event, closeCrudWindow: false });
  }

  toggleEvtEmit(event){
    this.isToggleActive = true;
  }

  CancelEvent(event){
    this.isToggleActive = event;
  }

  async showConfirmationSuccessCallback(res) {
    await this.SaveTemplate();
  }

  async showConfirmationErrorCallback() {
    this.doneEventObj.isDefault = false;
    await this.SaveTemplate();
  }

  async SaveTemplate() {
    let notificationTemplate = this.MaptoAPIMpdel(this.doneEventObj);
    let result = await this._CMB.UpdateTemplate(this.doneEventObj.id, notificationTemplate);
    if (result) {
      await this.getSMSData(this.showInactiveToggle);
    }
  }

  MaptoAPIMpdel(event): NotificationAPITemplate {
    return {
      eventIds: event.eventIds,
      id: event.id,
      isactive: event.isActive,
      isdefault: event.isDefault,
      listorder: event.listOrder,
      templateCode: event.templateCode,
      templateName: event.templateName,
      templateId: event.templateId,
      typeId: NotificationType.SMS
    }   
  }

  filterTableData(event, selectedData) {
    if (event.checked) {
      if (selectedData.id === 0) {
        this.SMSFilterselectionData = [];
        this.modifiedEvents.forEach(x => {
          this.SMSFilterselectionData.push(x);
        })
      } else {
        this.SMSFilterselectionData.push(selectedData);
      }
    } else {
      if (selectedData.id === 0) {
        this.SMSFilterselectionData = [];
      } else {
        let index = this.SMSFilterselectionData.indexOf(selectedData);
        this.SMSFilterselectionData.splice(index, 1)
      }
    }
    this.filterSMSDisplaytabledata();
  }
  filterSMSDisplaytabledata() { 
    let eventidArr = this.SMSFilterselectionData.map(x => x.id);
    let filteredtabledata = [];
    if (eventidArr.length > 0) {
      this.originalTemplateData.forEach(x => {
        if (eventidArr.some(r=> x.eventIds.includes(r))) {
          filteredtabledata.push(x);
        }
      })
    } else {
      filteredtabledata = this.originalTemplateData;
    } 
    this.BindsmsTableData(filteredtabledata);
  }

  async DragDropEvent(event) {
    let result = await this._CMB.UpdateListOrder(event[0], event[1], NotificationType.SMS);
    if (result) {
      await this.getSMSData(this.showInactiveToggle);
    }
  }
}
