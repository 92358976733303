<div class="KakaoMapping-wrapper ag_height--100">
    <div class="ag_container--padding-md ag_modal--form-height">
            <form class="KakaoMapping-formwrapper" [formGroup]="CreateKakoTemplateMappingForm"  autocomplete="off"> 
                <div class="bg-white ag_mb--3 ag_container--padding-md">
                        <div class="ag_display--flex">                    
                    <app-ag-dropdown [inputs]="kakaoTemplatesInput"></app-ag-dropdown>
                    <app-ag-dropdown [inputs]="smsTemplatesInput"></app-ag-dropdown>
                    <app-ag-dropdown [inputs]="fallBackInput"></app-ag-dropdown>
                </div>
                </div>
                <div class="bg-white ag_container--padding-md">
                    <div class="ag_form_wrapper--sm"  cdkDropList class="dragger-list" (cdkDropListDropped)="drop($event)">
                        <h4 class="ag_group--headers ag_w--100">{{captions.lbl_ButtonConfig}}</h4>
                        <div cdkDrag class="dragger-box" formArrayName="items" *ngFor="let item of CreateKakoTemplateMappingForm.get('items')['controls']; let i = index;let first = first;let last = last">
                        <div class="ag_display--flex align-left" [formGroupName]="i" >
                            <div class="ag_display--flex ag_position--relative">
                                <ag-input formControlName="buttonDisplayName" [placeholder]="captions.ButtonDisplay" class="ag_form-control--lg"></ag-input>
                                <ag-dropdown formControlName="buttonType" [placeholder]="captions.ButtonType" class="ag_form-control--lg" [source]="buttonTypeselectedList"></ag-dropdown>
                                <ag-input formControlName="buttonURLForMobile" [placeholder]="captions.ButtonUrlMob" class="ag_form-control--lg"></ag-input>
                                <ag-input formControlName="buttonUrlForPc" [placeholder]="captions.ButtonUrlPC" class="ag_form-control--lg"></ag-input>
                                <span class="ag-mr--2 align-self-center align-drag-handler" cdkDragHandle>
                                    <i class="icon-drag-drop"></i>
                                </span>
                                <span class="icon-minus align-self-center" *ngIf="!(first && last)" (click)="removeItem($event,i)"></span>
                                <span class="icon-plus align-self-center" *ngIf="last" (click)="add($event,i)"></span>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    <div class="Building-footer ag_footer--actions">
        <app-button [buttontype]="actionButton" (valueChange)='onAction($event)'></app-button>
        <app-button [buttontype]="cancelButton" class="ag_ml--2" (valueChange)='onCancel($event)'></app-button>
    </div>
</div>