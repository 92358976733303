import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APIThrottleService } from "./api.throttler.service";

@Injectable()
export class APIThrottleInterceptor implements HttpInterceptor {
    constructor(
        private apiThrottleService: APIThrottleService
    ) {}
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return this.apiThrottleService.intercept(req, next);
    }
}