import { Injectable } from '@angular/core';
import { GolfGatewayCommunication } from '../../communication/services/golfGateway';
import { ImageData } from '../../models/image.model';

@Injectable()
export class ImageDataService {
    constructor(
        private _golfGatewayCommunication: GolfGatewayCommunication
    ) { }


    public async saveImage(imageData: ImageData[]): Promise<string> {
        return this._golfGatewayCommunication.postPromise({
            route: GolfApiRoute.saveImage,
            body: imageData
        });
    }

    public async updateImage(imageData: ImageData[]): Promise<number> {
        return this._golfGatewayCommunication.putPromise({
            route: GolfApiRoute.updateImage,
            body: imageData
        });
    }

    public async DeleteImageByReference(referenceid: string) {
        return this._golfGatewayCommunication.deletePromise({
            route: GolfApiRoute.DeleteImageByReference,
            uriParams: { guid: referenceid }

        });
    }

    public async GetImagesByReferenceId(imageReferenceId: string, isThumbnailOnly: boolean): Promise<ImageData> {
        return await this._golfGatewayCommunication.getPromise({
            route: GolfApiRoute.GetImagesByReferenceId,
            uriParams: { imageReferenceId: imageReferenceId, isThumbnailOnly: isThumbnailOnly }
        });
    }

    public async GetAllImagesByReference(imageReferenceids: any, isthumbnailonly: boolean): Promise<ImageData[]> {
        return this._golfGatewayCommunication.putPromise({
            route: GolfApiRoute.GetAllImagesByReferenceId,
            body: imageReferenceids,
            uriParams: { isThumbnailOnly: isthumbnailonly }
        });
    }

}