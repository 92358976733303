import { Injectable } from "@angular/core";
import { PaymentModalService } from './payment-modal-service';
import { CheckboxTableHeader, AlertType, ButtonType, AlertAction, PlayerTransactionDetail } from 'src/app/shared/shared-models';
import { Router } from '@angular/router';
import _ from 'lodash';
import { UnPaidPlayersBusiness } from 'src/app/shared/data-services/golfschedule/unpaid-players.business';
import { MatDialog } from '@angular/material/dialog';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { RetailBussinessService } from 'src/app/shared/retail.bussiness';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { ScheduledTeeTimeUnPaidPlayer } from 'src/app/shared/models/unpaid-players.model';
import * as GlobalConst from '../../retail/shared/globalsContant';
import { CourseDataService } from 'src/app/shared/data-services/golfschedule/course.data.service';
import { API as settingsAPI } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { SettingsDataService } from 'src/app/shared/data-services/golfschedule/settings.data.service';
import { AutoRetailTransactionType, FromAction, RetailInterfaceSourceTypes, SettingModule, SettingScreen, playerTypes } from 'src/app/shared/global.constant';
import { DefaultOutletType } from 'src/app/common/shared/shared/enums/enums';
import { DefaultUserConfigDataService } from 'src/app/settings/utilities/manager-utilities/default-user-config/default-user-config.data.service';
import { CommonVariablesService } from 'src/app/retail/shared/service/common-variables.service';
import { DefaultsSettingConfig } from '../teetime/player-details/player-info.model';
import { DefaultUserConfiguration } from "src/app/settings/utilities/manager-utilities/default-user-config/default-user-config-model";
import { TeeTimeFormat } from "src/app/shared/models/teesheet.form.models";
import { PlayerPaymentstatus } from "src/app/retail/shared/shared.modal";
import { TeeTimeDetail } from "src/app/shared/models/teesheet.api.models";
import { TeeSheetMapper } from "src/app/tee-time/tee-sheet/business/tee-sheet.mapper";
import { TeeSheetsDataService } from "src/app/shared/data-services/golfschedule/teesheets.data.service";
import { TeeTimesActionService } from "src/app/shared/data-services/golfschedule/teeTimesAction.data.service";
import { RetailService } from "src/app/retail/retail.service";
import { PaymentBusinessService } from "src/app/shared/data-services/payment/payment-business.service";
import { PlayerCategory } from "src/app/common/enums/shared-enums";
import { PlayerTypeService } from "src/app/shared/data-services/golfschedule/playertype.data.service";
import { RateTypeDataService } from "src/app/shared/data-services/golfschedule/ratetype.data.service";
import { CodeLinkingDataService } from "src/app/shared/data-services/golfschedule/codelinking.data.service";
import { AllocationBlockDataService } from "src/app/shared/data-services/golfschedule/allocationblock.data.service";
import {  RateType, PlayerTypes } from '../teetime/player-details/player-info.model';
import {  PlayerType } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import { TeeTimeCancellationNoShowFeeUtilities } from "../noshow-cancellation-fees-tee-time-utilities";

@Injectable()
export class PaymentModalBussiness {
  isToContinueWithOldCartItems: boolean = false;
  depositItemNotInOutlet: boolean = false;
  selectedProductsArrayReassigned = [];
  private mapper: TeeSheetMapper;
  constructor(
    private _paymentBusinessService: PaymentBusinessService,
    private _PaymentModalService: PaymentModalService
    , private _retailService: RetailSharedVariableService
    , private _rs: RetailService
    , private _retailBusinessService: RetailBussinessService
    , private _teeTimeActionService: TeeTimesActionService
    , private _unPaidPlayersBusiness: UnPaidPlayersBusiness
    , private router: Router
    , private dialog: MatDialog
    , private localization: GolfLocalization
    , private _utils: GolfUtilities
    , private _teeSheetDataService: TeeSheetsDataService
    , private _courseDataService: CourseDataService
    , private _settingSerivice: SettingsDataService
    , private _defaultUserConfig: DefaultUserConfigDataService
    , private _commonVariable: CommonVariablesService
    , private _playerTypeService: PlayerTypeService
    , private _rateTypeService: RateTypeDataService
    , private _codeLinkingDataService: CodeLinkingDataService
    , private _allocationBlockService: AllocationBlockDataService
    , private _teeTimeCancellationNoShowFeeUtilities: TeeTimeCancellationNoShowFeeUtilities
  ) {
  }

  public async getAllocationsBlockWithPlayerTypes() {
    return await this._allocationBlockService.getAllocationsBlockWithPlayerTypes();
  }
  public async getAllPlayerTypeWithRateType() {
    return await this._codeLinkingDataService.getPlayerTypeRateTypes();
  }

  public async getPlayerTypes(): Promise<PlayerTypes[]> {
    let playerTypeApiData: PlayerType[] = await this._playerTypeService.getAllPlayerTypes();
    let playerTypeApiDataClone = playerTypeApiData.map(x => this.mapPlayerTypeData(x));
    return playerTypeApiDataClone.sort((a, b) => (a.type.toLocaleLowerCase() > b.type.toLocaleLowerCase()) ? 1 : -1);
  }
  private mapPlayerTypeData(playerTypeApiData: PlayerType): PlayerTypes {
    return {
      id: playerTypeApiData.id,
      type: playerTypeApiData.type,
      daysOutStart: playerTypeApiData.daysOutStart,
      daysOutEnd: playerTypeApiData.daysOutEnd,
      guaranteeType: playerTypeApiData.guaranteeType
    } as PlayerTypes;
  }

  public async getRateTypes(): Promise<RateType[]> {
    let rateTypeApiDataSortByName = await this._rateTypeService.getAllRateTypes(false);
    rateTypeApiDataSortByName = rateTypeApiDataSortByName.sort((a, b) => (a.type.toLocaleLowerCase() > b.type.toLocaleLowerCase()) ? 1 : -1);
    return rateTypeApiDataSortByName;
  }

  GetHeaderOption(flagValue: boolean, isMarkAsPaid: boolean, isPlayerTypeRateTypeChange:boolean = false, isNoShow:boolean =false,isNoshowChargesApplicable: boolean = false): CheckboxTableHeader[] {
    return this._PaymentModalService.GetHeaderOption(flagValue, isMarkAsPaid, isPlayerTypeRateTypeChange, isNoShow,isNoshowChargesApplicable);
  }

  async GetPlayer(scheduledTeeTimePlayerId, courseId, scheduledDate, allocationBlockId){
    let unpaidPlayer: ScheduledTeeTimeUnPaidPlayer[] = await this._unPaidPlayersBusiness.getPlayer(scheduledTeeTimePlayerId, courseId, this.localization.convertDateTimeToAPIDateTime(scheduledDate), PlayerPaymentstatus.unPaid | PlayerPaymentstatus.refund | PlayerPaymentstatus.checkIn);
    if(unpaidPlayer && unpaidPlayer.length>0){
      unpaidPlayer.map(element => { element.checked = true, element.scheduledTeeTimePlayerId = element.id; element.id = element.playerId; element.isHold = false; element.isReleaseAllow = false;element.playerPosition = element.playerSlotPosition, element.allocationBlockId = allocationBlockId });
    }
    return unpaidPlayer;
  }

  async GetPlayersForNoShow(data, callBackFn,  isNoShow: boolean = false, isReBook: boolean = false) : Promise<ScheduledTeeTimeUnPaidPlayer[]>
  {
    let teeSheetData = await this._teeSheetDataService.getTeeTimes(data.course.id, data.time);

    let playerStatus = (isNoShow && await this._teeTimeCancellationNoShowFeeUtilities.IsNoShowCancellationPolicyConfigured()) ? 
    PlayerPaymentstatus.unPaid | PlayerPaymentstatus.refund | PlayerPaymentstatus.checkIn | PlayerPaymentstatus.paid : 
    PlayerPaymentstatus.unPaid | PlayerPaymentstatus.refund | PlayerPaymentstatus.checkIn;

    let unpaidPlayers = await this._unPaidPlayersBusiness
    .getPlayers(data.course.id, this.localization.convertDateTimeToAPIDateTime(data.time), isReBook ? 
    PlayerPaymentstatus.noShow : playerStatus, false,false, false);

    if(isReBook)
      unpaidPlayers = unpaidPlayers.filter(x => (x.playerStatus & PlayerPaymentstatus.noShow) != 0);
    if(isNoShow)
      unpaidPlayers = unpaidPlayers.filter(x => (x.playerStatus & PlayerPaymentstatus.noShow) == 0);
    
    unpaidPlayers = await this.SetUnPaidPlayerDetails(teeSheetData, unpaidPlayers, data, isNoShow, isReBook, callBackFn);
    return unpaidPlayers;
  }

  async SetUnPaidPlayerDetails(teeSheetData, unpaidPlayers, data, isNoShow, isReBook,callBackFn)
  {
    this.UpdatePlayerPosition(teeSheetData, unpaidPlayers);
    unpaidPlayers.forEach(element => { element.scheduledTeeTimePlayerId = element.id; element.id = element.playerId; element.isHold = false; element.isReleaseAllow = false; });
    unpaidPlayers.forEach(p => { p.checked = (p.scheduledTeeTimeId == data.scheduledTeeTimeId) });
    const filteredObj = unpaidPlayers.find(x => x.isTournamentPlayersUnPaid && x.tournamentId > 0);
    if (filteredObj) {
      let res = this._utils.showAlert((isNoShow || isReBook) ? this.localization.captions.teetime.NoShowForBulkBookingConfirmationMsg : this.localization.captions.teetime.PayByTournamentConfirmationMsg, AlertType.Info, ButtonType.YesNo, callBackFn, [unpaidPlayers, filteredObj.tournamentId]);
      await res.afterClosed().toPromise();
    }
    const bulkFilteredObj = this.GetBulkPlayers(data, unpaidPlayers, teeSheetData);
    var isLoadBulkPlayer;
    if (bulkFilteredObj && bulkFilteredObj.length) {
      let res = this._utils.showAlert((isNoShow || isReBook) ? this.localization.captions.teetime.NoShowForTournamentConfirmationMsg : this.localization.captions.teetime.PayByBulkBookingConfirmationMsg, AlertType.Info, ButtonType.YesNo, callBackFn, [unpaidPlayers, bulkFilteredObj]);
      isLoadBulkPlayer = await res.afterClosed().toPromise();
    }
    if (data.schedulePlayerID && !data.isFromSearch && !data.isFromPopup && bulkFilteredObj && !bulkFilteredObj.length) {
      unpaidPlayers = unpaidPlayers.filter(p => p.playerId == data.schedulePlayerID);
    } 
    else if (data.isFromPopup) 
    {
      if(isLoadBulkPlayer === AlertAction.YES && bulkFilteredObj && bulkFilteredObj.length)
      {
        unpaidPlayers = bulkFilteredObj;
      }
      else{
        unpaidPlayers = unpaidPlayers.filter(p => p.scheduledTeeTimeId == data.scheduledTeeTimeId);
      }
      unpaidPlayers.forEach(p => { p.checked = true; });
    }
    return unpaidPlayers;
  }

  async GetPlayers(data, callBackFn, isFromMarkAsPaid: boolean = false, isFromPaymentModal: boolean = false, isNoShow: boolean = false, isReBook: boolean = false) {
    let teeSheetData$ = this._teeSheetDataService.getTeeTimes(data.course.id, data.time);
    let unpaidPlayers$ = this._unPaidPlayersBusiness.getPlayers(data.course.id, this.localization.convertDateTimeToAPIDateTime(data.time), isReBook ? PlayerPaymentstatus.noShow : PlayerPaymentstatus.unPaid | PlayerPaymentstatus.refund | PlayerPaymentstatus.checkIn, isFromMarkAsPaid,false, isFromPaymentModal);
    const responses = await Promise.all([teeSheetData$, unpaidPlayers$]);
    let teeSheetData = responses[0];
    let unpaidPlayers: ScheduledTeeTimeUnPaidPlayer[] = responses[1];
    if(await this._teeTimeCancellationNoShowFeeUtilities.IsNoShowCancellationPolicyConfigured() == false)
    {
      unpaidPlayers = unpaidPlayers.filter(x => (x.playerStatus & PlayerPaymentstatus.paid) == 0);
    }  
    this.UpdatePlayerPosition(teeSheetData, unpaidPlayers);

    unpaidPlayers.forEach(element => { element.scheduledTeeTimePlayerId = element.id; element.id = element.playerId; element.isHold = false; element.isReleaseAllow = false; });
    unpaidPlayers.forEach(p => { p.checked = (p.scheduledTeeTimeId == data.scheduledTeeTimeId) });

    const filteredObj = data.isFromPopup ? unpaidPlayers.find(x => x.isTournamentPlayersUnPaid && x.tournamentId > 0 && x.playerId == data.playerId) : unpaidPlayers.find(x => x.isTournamentPlayersUnPaid && x.tournamentId > 0);

    var isLoadtournamentPlayer;
    if (filteredObj) {
      let res = this._utils.showAlert((isNoShow || isReBook) ? this.localization.captions.teetime.NoShowForBulkBookingConfirmationMsg : this.localization.captions.teetime.PayByTournamentConfirmationMsg, AlertType.Info, ButtonType.YesNo, callBackFn, [unpaidPlayers, filteredObj.tournamentId]);
      isLoadtournamentPlayer = await res.afterClosed().toPromise();
    }
    const bulkFilteredObj = this.GetBulkPlayers(data, unpaidPlayers, teeSheetData);//data.playerDetail && unpaidPlayers.filter(x => x.teeTimeFormat === TeeTimeFormat.BulkTee && data.playerDetail.map(s => s.confirmationNumber).includes(x.confirmationNumber));
    var isLoadBulkPlayer;
    if (bulkFilteredObj && bulkFilteredObj.length) {
      let res = this._utils.showAlert((isNoShow || isReBook) ? this.localization.captions.teetime.NoShowForTournamentConfirmationMsg : this.localization.captions.teetime.PayByBulkBookingConfirmationMsg, AlertType.Info, ButtonType.YesNo, callBackFn, [unpaidPlayers, bulkFilteredObj]);
      isLoadBulkPlayer = await res.afterClosed().toPromise();
    }

    if (data.schedulePlayerID && !data.isFromSearch && !data.isFromPopup && bulkFilteredObj && !bulkFilteredObj.length) {
      unpaidPlayers = unpaidPlayers.filter(p => p.playerId == data.schedulePlayerID);
    } else if (data.isFromPopup) {
      if(isLoadBulkPlayer === AlertAction.YES && bulkFilteredObj && bulkFilteredObj.length)
      {
        unpaidPlayers = bulkFilteredObj;
        unpaidPlayers.forEach(p => { p.checked = true});
      }
      else{
        unpaidPlayers = (isLoadtournamentPlayer === AlertAction.YES && filteredObj) ? unpaidPlayers.filter(x => x.tournamentId == filteredObj.tournamentId) : unpaidPlayers.filter(p => p.scheduledTeeTimeId == data.scheduledTeeTimeId);
        (isLoadtournamentPlayer === AlertAction.YES && filteredObj) ? unpaidPlayers.forEach(p => { p.checked = true}) : unpaidPlayers.forEach(p => { p.checked = !(p.playerId != data.playerId) });
      }      
    }
    // var unpaidPlayers = [{
    // }]
    return unpaidPlayers;
  }

  private GetBulkPlayers(data, unpaidPlayers, teeSheetData)
  {
    if(data.playerDetail){
      if(data.isFromSearch)
      {
        let teeSheetPlayerData = teeSheetData.filter(x=> x.playerDetail!=null);
        let bulkPlayerIds = teeSheetPlayerData?.map(x=> x.playerDetail).flat().filter(x=>  x.bookingId==data.playerDetail[0].bookingId).map(z=> z.teeTimePlayerId);console.log(bulkPlayerIds.join()); 
        return unpaidPlayers.filter(x=> x.teeTimeFormat === TeeTimeFormat.BulkTee && bulkPlayerIds.includes(x.id));
      }
      else
      {
        return data.playerDetail && unpaidPlayers.filter(x => x.teeTimeFormat === TeeTimeFormat.BulkTee && data.playerDetail.map(s => s.confirmationNumber).includes(x.confirmationNumber));
      }
    }
    else if(data.isFromPopup && data.playerId && unpaidPlayers.filter(x=> x.teeTimeFormat === TeeTimeFormat.BulkTee && x.id==data.playerId).length>0)
    {
       let bulkPlayerConfirmation = unpaidPlayers.filter(x=> x.teeTimeFormat === TeeTimeFormat.BulkTee && x.id==data.playerId)[0].confirmationNumber;
       return unpaidPlayers.filter(x => x.teeTimeFormat === TeeTimeFormat.BulkTee && x.confirmationNumber == bulkPlayerConfirmation);
    }
    else return []
  }

  private UpdatePlayerPosition(teeSheetData: TeeTimeDetail[], unpaidPlayers: ScheduledTeeTimeUnPaidPlayer[]) {
    if (unpaidPlayers && unpaidPlayers.length) {
      unpaidPlayers.map(x => x.playerPosition = x.playerSlotPosition);
      if (teeSheetData && teeSheetData.length) {

        let playersDetails = teeSheetData.map(x=> x.playerDetail).flat(1).filter(x=> x!=null);
        unpaidPlayers.map(u=>{
          if(playersDetails.findIndex(f=> f.scheduledTeeTimePlayerFee?.scheduledTeeTimePlayerId == u.id) !=-1)
            {
              u.allocationBlockId = playersDetails.find(f=> f.scheduledTeeTimePlayerFee?.scheduledTeeTimePlayerId == u.id)?.allocationBlockId;
            }
        });

        this.mapper = new TeeSheetMapper(this.localization);
        let groupedPlayerByScheduledTeeTime = _.groupBy(unpaidPlayers, 'scheduledTeeTimeId');
        let teeSheetGridContent = this.mapper.mapToTeeGridContent(teeSheetData, [], [], [], []);
        let teeSheetPlayerDetails = teeSheetGridContent && teeSheetGridContent.length ? teeSheetGridContent.flatMap(x => x.playerDetail) : null;
        if (teeSheetPlayerDetails && teeSheetPlayerDetails.length) {
          for (let scheduleTeeTimeId in groupedPlayerByScheduledTeeTime) {
            groupedPlayerByScheduledTeeTime[scheduleTeeTimeId].map(x => {
              let teeTimePlayer = teeSheetPlayerDetails.find(y => y.scheduledTeeTimePlayerFee.scheduledTeeTimePlayerId == x.id);
              if (teeTimePlayer) {
                x.playerPosition = teeTimePlayer && teeTimePlayer.playGroup ? teeTimePlayer.playGroup.listOrder : teeTimePlayer.playPos;
              }
            });
          }
        }
      }
    }
  }

   async HoldPlayers(table) : Promise<boolean> {
    let paymentForm = table.PaymentForm;
    let checkedItems: any[] = paymentForm.value.checkBoxItems.filter(c => c.checkBox);
    const selectedPlayerIds = [] = checkedItems.filter(x => x.id).map(a => a.id);
    let result = false;
    await this._teeTimeActionService.TempHoldPlayers(selectedPlayerIds).then(r => { result = true }).catch(async e => {
      let errorMsg = "";
      if(!e.result && e.errorCode)
      {
        errorMsg = this.localization.getError(e.errorCode);
        if(e.errorCode == 41001)
        {          
          errorMsg = e.errorDescription ? errorMsg.interpolate({ slots : e.errorDescription}) : "";
        }        
      }
      var res = this._utils.showAlert(errorMsg, AlertType.Error, ButtonType.Ok);
      await res.afterClosed().toPromise();
      result = false;
    });
    return result;
  }

  async releasePlayersInHold(playerIds: number[]) {
    await this._teeTimeActionService.releasePlayersInHold(playerIds);
  }



  async ProceedToOrderSummary(compScope, isNavigateToOrderSummary: boolean = false, isFromCheckInCheckOut: boolean = false, isFromPaymentModal: boolean = true) {
    let defaultConfig = await this.getDefaultSettings();
    this._retailService.DeletePreviousPlayer = false;
    this._retailService.fromTeeTimeNoShow = false;
    this._retailService.fromTeeTimeCancellation = false;
    if (defaultConfig.defaultOutletType == DefaultOutletType.course) {
      let course = await this._courseDataService.getCourse(compScope.data.course.id);
      await this.settingDefaultOutlet(course.defaultOutletId);
      this._retailService.isTeeTimeAsPaid = true;
      this._retailBusinessService.ValidateSelectedTerminalExistsInSelectedOutlet();
    } else {
      const defaultConfig = await this.getUserDefaultConfig(this.localization.GetPropertyInfo("UserId"));
      let defaultOutletId = defaultConfig.defaultOutletId > 0 ? defaultConfig.defaultOutletId : this._retailService.SelectedOutletId;
      await this.settingDefaultOutlet(defaultOutletId);
      this._retailService.isTeeTimeAsPaid = false;
    }
    this._retailService.isFromCheckInCheckOutPopUP = isFromCheckInCheckOut
    this._retailService.isToMarkCheckIn = compScope?.dialogData?.isToMarkCheckIn
    this._commonVariable.SelectedOutletId = this._retailService.SelectedOutletId;
    this._commonVariable.isFromCheckInCheckOutPopUP = isFromCheckInCheckOut;
    let dialogRef = compScope.dialogRef, tableData = isFromPaymentModal ? compScope.PaymentForm.value.checkBoxItems :  compScope.tableData, data = compScope.data, PaymentForm;
    if ((isFromCheckInCheckOut || this._retailService.isToMarkCheckIn) && !isFromPaymentModal) PaymentForm = compScope.checkInCheckOutFormGroup
    else PaymentForm = compScope.PaymentForm

    let checkedItems: any[] = PaymentForm.value.checkBoxItems.filter(c => c.checkBox);
    let SelectedPlayers: ScheduledTeeTimeUnPaidPlayer[] = (isFromCheckInCheckOut || this._retailService.isToMarkCheckIn) && !isFromPaymentModal ? tableData.filter(t => checkedItems.find(x => x.id == t.playerId && (x.playerStatus & PlayerPaymentstatus.paid) == 0)) : tableData.filter(t => checkedItems.find(x => x.id == t.playerId));

    let playerIds: number[] = SelectedPlayers.map(c => c.playerId);

    if (!this.isToContinueWithOldCartItems) {
      if (!await this._retailBusinessService.CheckForUserOutletAccessAndDefaultOutlet(dialogRef, data.isFromPopup)) {
        await this._teeTimeActionService.releasePlayersInHold(playerIds);
        return;
      }
      let customFeeLinkedItemIds = _.uniq(SelectedPlayers.flatMap( o => o.scheduledTeeTimePlayerCustomFees?.flatMap(o => o.linkedItemId)));
      let selectedProducts = await this._retailBusinessService.getSelectedProducts(SelectedPlayers,customFeeLinkedItemIds);
      try{
        this._utils.ToggleLoader(true);
        let items = await this._teeTimeActionService.getPlayerRetailItems(SelectedPlayers.map(x=>x.scheduledTeeTimePlayerId));
        this._utils.ToggleLoader(false);
        var retailProducts: any[] =[];
        items?.map(y=>y.retailItems).forEach(x=>{
          var y =JSON.parse(x);
          y.forEach(z=>{
            z.id = z.ItemId;
            retailProducts.push(z);
            selectedProducts.push(z);
          });
        });
      }
      catch(ex){
        this._utils.ToggleLoader(false);
      }
      if (SelectedPlayers.length > 0 && selectedProducts.length > 0) {
        if (this._retailBusinessService.CheckPlayersHaveAnyPendingSettlements(SelectedPlayers)) {
          compScope._StepperService.enableCancel = true;
          await this._teeTimeActionService.releasePlayersInHold(playerIds);
          return;
        }
        const golfItems = this._retailBusinessService.GetRetailItemsFromPlayers(SelectedPlayers);
        retailProducts?.forEach(x=>{
          golfItems.push(x);
        });
        if (!(await this._retailBusinessService.CheckItemsAreFromSelectedOutlet(golfItems))) {
          await this._teeTimeActionService.releasePlayersInHold(playerIds);
          return;
        }
        this._retailService.isFromEditTeeTime = true;
        this._retailService.SelectedPlayers = SelectedPlayers;
        let dependentTransactions = this._retailService.SelectedPlayers
          .filter(i => i.dependentTransactionIds != null)
          .map(o => o.dependentTransactionIds);
        if(dependentTransactions?.length > 0)
        {
          let dependTrans = new Map<number,number[]>();
          dependentTransactions.forEach(o => dependTrans.set(Number(Object.keys(o)[0]), Object.values(o)[0]));
          this._retailService.dependentTransactions = dependTrans;
        }  
        this._retailService.payeeId = this._retailBusinessService.SetP1PlayerId();
        this._retailService.sourceCorpId = Number( SelectedPlayers.filter(x => x.playerCategoryId === PlayerCategory.Member)?.map(x => x.corpId)[0] ?? 0);
        this._retailService.selectedProducts.push(...selectedProducts);
        const selectedProductsArrayReassigned = [];
        if (this._retailService.selectedProducts.length > 0) {
          this._retailService.selectedProducts.forEach((x) => {
            if (x.payeeId) {
              if (x.GroupingParentId > 0) {
                selectedProductsArrayReassigned.push(x);
              }
              else if (!selectedProductsArrayReassigned.some(item => item.payeeId === x.payeeId && x.GroupingParentId <= 0 &&
                item.ExternalPOSItemId === x.ExternalPOSItemId) || x.ItemType === GlobalConst.DepositRetailItemType
              ) {
                selectedProductsArrayReassigned.push(x);
              }
            }
            else {
              selectedProductsArrayReassigned.push(x);
            }
          });
        }
        this._retailService.selectedProducts = selectedProductsArrayReassigned;
        this._retailService.SeletedCourseId = data.course.id;
        this._retailService.SelectedDate = data.time;
        this._retailService.selectedScheduledTeeTimeId = data.scheduledTeeTimeId;
        this._retailService.memberCardNumber = "0";
      }
    } else {
      this.isToContinueWithOldCartItems = false;
      this._retailService.isFromEditTeeTime = true;
    }
    if (this.router.url === '/tee-time/teesheet/graphicalView') {
      this._retailService.isFromTeeTimeGraphicalView = true;
    }
    isNavigateToOrderSummary ? this.router.navigate(['/shop/viewshop/order']) : this.router.navigate(['/shop/viewshop']);
    if (data.isFromPopup) {
      this.dialog.closeAll();
    } else {
      dialogRef.close();
    }
  }
  public async ProceedMarkAsPaid(compScope, callback?: any) {
    this.selectedProductsArrayReassigned = [];
    this._utils.ToggleLoaderWithMessage(true, this.localization.captions.teetime.markAsPaidLoaderMessage);
    let errorMessageReceived: boolean = false;
    var defaultConfig = await this.getDefaultSettings();
    if (defaultConfig.defaultOutletType == DefaultOutletType.course) {
      let course = await this._courseDataService.getCourse(compScope.data.course.id);
      await this.settingDefaultOutlet(course.defaultOutletId);
      this._retailService.isTeeTimeAsPaid = true;
      this._retailBusinessService.ValidateSelectedTerminalExistsInSelectedOutlet();
    } else {
      const defaultConfig = await this.getUserDefaultConfig(this.localization.GetPropertyInfo("UserId"));
      let defaultOutletId = defaultConfig.defaultOutletId > 0 ? defaultConfig.defaultOutletId : this._retailService.SelectedOutletId;
      await this.settingDefaultOutlet(defaultOutletId);
      this._retailService.isTeeTimeAsPaid = false;
    }
    this._commonVariable.SelectedOutletId = this._retailService.SelectedOutletId;
    let dialogRef = compScope.dialogRef, PaymentForm = compScope.PaymentForm, tableData = compScope.tableData, data = compScope.data;

    let checkedItems: any[] = PaymentForm.value.checkBoxItems.filter(c => c.checkBox);
    let SelectedPlayers: ScheduledTeeTimeUnPaidPlayer[] = tableData.filter(t => checkedItems.find(x => x.id == t.playerId));

    let playerIds: number[] = SelectedPlayers.map(c => c.playerId);

    if (!(defaultConfig?.markAsPaidPaymentMethod && defaultConfig.markAsPaidPaymentMethod > 0)) {
      this._utils.showError(this.localization.captions.teetime.defaultPaymentWarning);
      await this._teeTimeActionService.releasePlayersInHold(playerIds);
      errorMessageReceived = true;
      this._utils.ToggleLoaderWithMessage(false);
    }
    else {
      this._paymentBusinessService.getUserDefinedPaymentMethod().subscribe(async res => {
        let isPaymentAvailable = res && res?.length > 0 ? res.find(x => x.paymentTypeId == defaultConfig.markAsPaidPaymentMethod && x.isActive) : null;
        if (!isPaymentAvailable) {
          this._utils.showError(this.localization.captions.teetime.defaultPaymentWarning);
          await this._teeTimeActionService.releasePlayersInHold(playerIds);
          errorMessageReceived = true;
          this._utils.ToggleLoaderWithMessage(false);
        }
        if (!this.isToContinueWithOldCartItems) {
          if (!await this._retailBusinessService.CheckForUserOutletAccessAndDefaultOutlet(dialogRef, data.isFromPopup)) {
            errorMessageReceived = true;
            await this._teeTimeActionService.releasePlayersInHold(playerIds);
            this._utils.ToggleLoaderWithMessage(false);
          }
          else {
            let customFeeLinkedItemIds = _.uniq(SelectedPlayers.flatMap( o => o.scheduledTeeTimePlayerCustomFees?.flatMap(o => o.linkedItemId)));
            let selectedProducts = await this._retailBusinessService.getSelectedProducts(SelectedPlayers, customFeeLinkedItemIds);
            if (selectedProducts.length > 0) {
              selectedProducts.forEach((x) => {
                if (x.payeeId) {
                  if (x.GroupingParentId > 0) {
                    this.selectedProductsArrayReassigned.push(x);
                  }
                  else if (!this.selectedProductsArrayReassigned.some(item => item.payeeId === x.payeeId && x.GroupingParentId <= 0 &&
                    item.ExternalPOSItemId === x.ExternalPOSItemId) || x.ItemType === GlobalConst.DepositRetailItemType
                  ) {
                    this.selectedProductsArrayReassigned.push(x);
                  }
                }
                else {
                  this.selectedProductsArrayReassigned.push(x);
                }
              });
            }
            if (SelectedPlayers.length > 0 && selectedProducts.length > 0) {
              const golfItems = this._retailBusinessService.GetRetailItemsFromPlayers(SelectedPlayers);
              var golfInvalidItems = await this._retailBusinessService.CheckItemsAreFromSelectedOutletMarkAsPaid(golfItems);
              if (golfInvalidItems && golfInvalidItems.length > 0) {
                var filterPlayerIds = SelectedPlayers.filter(i => golfInvalidItems.includes(i.cartFeeRetailItemId) || golfInvalidItems.includes(i.entryFeeRetailItemId) || golfInvalidItems.includes(i.greenFeeRetailItemId)).map(c => c.playerId).distinct();
                var tempCheckedItems = checkedItems.filter(c => !filterPlayerIds.includes(c.playerId));
                if (tempCheckedItems.length == 0) {
                  const errorMsg = this.localization.getError(10726);
                  this._utils.ToggleLoaderWithMessage(false);
                  await this.releaseHoldPlayer(playerIds);
                  this._utils.showAlert(errorMsg,
                    AlertType.Info, ButtonType.Ok, () => {
                      // this.dialog.closeAll();
                    });
                }
                else {
                  this._utils.ToggleLoaderWithMessage(false);
                  await this._utils.showAlert(this.localization.captions.common.confirmProceedMarkAsPaidPartially, AlertType.Info,
                    ButtonType.YesNo, async res => {
                      if (res === AlertAction.YES) {
                        this._utils.ToggleLoaderWithMessage(true);
                        var filterPlayerIds = SelectedPlayers.filter(i => golfInvalidItems.includes(i.cartFeeRetailItemId) || golfInvalidItems.includes(i.greenFeeRetailItemId)).map(c => c.playerId).distinct();
                        checkedItems = checkedItems.filter(c => !filterPlayerIds.includes(c.playerId));
                        await this.MarkTeeTimeAsPaid(errorMessageReceived, checkedItems, defaultConfig, compScope, playerIds, callback);
                      }
                      else {
                        checkedItems = [];
                        this.releaseHoldPlayer(playerIds);
                        await this.MarkTeeTimeAsPaid(errorMessageReceived, checkedItems, defaultConfig, compScope, playerIds, callback);
                      }
                    });
                }

              }
              else {
                await this.MarkTeeTimeAsPaid(errorMessageReceived, checkedItems, defaultConfig, compScope, playerIds, callback);
              }
            }
          }

        } else {
          this.isToContinueWithOldCartItems = false;
          this._retailService.isFromEditTeeTime = true;
          await this.MarkTeeTimeAsPaid(errorMessageReceived, checkedItems, defaultConfig, compScope, playerIds, callback);
        }
      })
    }
  }

  async releaseHoldPlayer(playerIds) {
    await this._teeTimeActionService.releasePlayersInHold(playerIds);
    this._utils.ToggleLoaderWithMessage(false);
  }

  async MarkTeeTimeAsPaid(errorMessageReceived, checkedItems, defaultConfig, compScope, playerIds, callback) {
    let SelectedPlayers: ScheduledTeeTimeUnPaidPlayer[] = compScope.tableData.filter(x=>x.checked && (x.playerCategoryId == playerTypes.hotelReservation || x.playerCategoryId == playerTypes.player));
    const defaultGuestId = SelectedPlayers.length > 0 ? SelectedPlayers[0].playerLinkId : "";
    if (errorMessageReceived == false) {
      let arrData = await this.MapUIMarkAsPaidToAPIModel(checkedItems, defaultConfig, compScope.PaymentForm.controls.paymentComment.value,defaultGuestId);
      if (this.depositItemNotInOutlet) {
        this._utils.ToggleLoaderWithMessage(false);
        await this._utils.showAlert(this.localization.captions.common.confirmProceedMarkAsPaidWithoutDepositPlayer,
          AlertType.Info, ButtonType.YesNo, async (res) => {
            if (res === AlertAction.NO) {
              await this._teeTimeActionService.releasePlayersInHold(playerIds);
            }
            else {
              this._utils.ToggleLoaderWithMessage(true);

              arrData.forEach(c => {
                c.AutoRetailTransactionRequest.AutoRetailItems.forEach(element => {
                  if (element.isDepositNotExist) {
                    const guestId = element.GuestId;
                    c.AutoRetailTransactionRequest.AutoRetailItems = c.AutoRetailTransactionRequest.AutoRetailItems.filter(ab => ab.GuestId != guestId);
                  }
                });
              })
              arrData.forEach(c => {
                c.AutoRetailTransactionRequest.AutoRetailItems.forEach((element, i) => {
                  element.LineNumber = i + 1;
                });
              })


              await this.SubmitMarkAsPaid(arrData, playerIds, callback);
            }
          });
      }
      else {
        if (arrData && arrData.length > 0) {
          await this.SubmitMarkAsPaid(arrData, playerIds, callback);
        }
      }
    }
    else {
      await this._teeTimeActionService.releasePlayersInHold(playerIds);
      this._utils.ToggleLoaderWithMessage(false);
    }
  }

  async SubmitMarkAsPaid(arrData, playerIds, callback) {
    await this._retailBusinessService.MarkAsPaidPlayer(arrData).then(async arrRetailResult => {
      if (arrRetailResult && (arrRetailResult).length > 0) {
        let objArrMarkAsPaidToSave = await this.MapRetailResponseToScheduleMarkAsPaid(arrRetailResult);
        if (objArrMarkAsPaidToSave && objArrMarkAsPaidToSave != null) {
          if (objArrMarkAsPaidToSave.arrData && objArrMarkAsPaidToSave.arrData?.length > 0) {
            await this._unPaidPlayersBusiness.setMarkAsPaidPlayer(objArrMarkAsPaidToSave.arrData).then(async x => {
              this._rs.MapTransactionDetailWithPlayer(objArrMarkAsPaidToSave.arrTransDetail);
              await this._teeTimeActionService.releasePlayersInHold(playerIds);
              this._utils.ToggleLoaderWithMessage(false);
              if (!objArrMarkAsPaidToSave.isMarkAsPaidFailed) {
                var teeTimeSetting = JSON.parse(sessionStorage.getItem('TEETIMESETTING'));
                if (teeTimeSetting && teeTimeSetting != null && teeTimeSetting?.autoCheckInCheckOutZeroCharges) {
                  var indexOfS = Object.values(PlayerPaymentstatus).indexOf(PlayerPaymentstatus.checkOut as unknown as PlayerPaymentstatus);
                  let strCheckOut = Object.keys(PlayerPaymentstatus)[indexOfS];
                  await this._teeTimeActionService.checkInOutStatus(strCheckOut, playerIds)
                }
                this._utils.showAlert(this.localization.captions.teetime.markAsPaidSuccessMessage,
                  AlertType.Success, ButtonType.Ok, () => {
                    this.dialog.closeAll();
                  });
              }
              else {
                await this._utils.showAlert(this.localization.captions.teetime.markAsPaidFail, AlertType.Info, ButtonType.Ok, callback)
              }
            });
          }
          else {
            await this._teeTimeActionService.releasePlayersInHold(playerIds);
            this._utils.ToggleLoaderWithMessage(false);
            await this._utils.showAlert(this.localization.captions.teetime.markAsPaidFail, AlertType.Info, ButtonType.Ok, callback)
          }
        }
        else {
          await this._teeTimeActionService.releasePlayersInHold(playerIds);
          this._utils.ToggleLoaderWithMessage(false);
        }
      }
      else {
        await this._teeTimeActionService.releasePlayersInHold(playerIds);
        this._utils.ToggleLoaderWithMessage(false);
      }

    })
      .catch(async x => {
        await this._teeTimeActionService.releasePlayersInHold(playerIds);
        this._utils.ToggleLoaderWithMessage(false);
      });
  }

  public async GetTransactionDepositDetails(tableData) {
    let arrTransactionId = tableData.filter(x => x.transactionId > 0).map(x => x.transactionId).distinct();
    if (arrTransactionId && arrTransactionId?.length > 0)
      return await this._retailBusinessService.GetTransactionDepositDetails(arrTransactionId);
    else
      return null;
  }
  public async getDefaultSettings(): Promise<DefaultsSettingConfig> {
    let defaultsApiData: settingsAPI.Settings<settingsAPI.DefaultsSettingConfig> = await this._settingSerivice.getSettings<settingsAPI.DefaultsSettingConfig>(SettingModule.SystemSetup, SettingScreen.Defaults);
    return this.mapDefaultData(defaultsApiData.configValue);
  }
  private mapDefaultData(configValue: settingsAPI.DefaultsSettingConfig): DefaultsSettingConfig {
    return {
      blockingCode: configValue.blockingCode,
      memberStatus: configValue.memberStatus,
      memberPlayerType: configValue.memberPlayerType,
      memberRateType: configValue.memberRateType,
      nonMemberPlayerType: configValue.nonMemberPlayerType,
      nonMemberRateType: configValue.nonMemberRateType,
      resortPlayerType: configValue.resortPlayerType,
      resortRateType: configValue.resortRateType,
      defaultOutletType: configValue.defaultOutletType,
      memberGuestPlayerType: configValue.memberGuestPlayerType,
      memberGuestRateType: configValue.memberGuestRateType,
      memberGuestsToAdd: configValue.memberGuestsToAdd,
      memberGuestPlayerFirstName: configValue.memberGuestPlayerFirstName,
      memberGuestPlayerLastName: configValue.memberGuestPlayerLastName,
      markAsPaidPaymentMethod: configValue.markAsPaidPaymentMethod,
      markAsPaidPaymentMethodReceiptComment: configValue.markAsPaidPaymentMethodReceiptComment,
      enableNonZeroRateForMarkAsPaid: configValue.enableNonZeroRateForMarkAsPaid
    }
  }

  async getUserDefaultConfig(userId: string): Promise<DefaultUserConfiguration> {
    let userDefaultConfig = await this._defaultUserConfig.GetDefaultUserConfiguration(userId);
    if (userDefaultConfig) {
      return userDefaultConfig;
    }
    return undefined;
  }

  async settingDefaultOutlet(defaultOutletId: number) {
    if (this._retailService.selectedProducts.length > 0) {
      if (this._retailService.SelectedOutletId != defaultOutletId) {
        var res = this._utils.showAlert(this.localization.captions.teetime.differentOutletSelection, AlertType.Warning, ButtonType.YesNo, (res) => {
          if (res === AlertAction.YES) {
            this._retailService.SelectedOutletId = defaultOutletId;
            this._retailService.selectedProducts = [];
            this._retailService.DeletePreviousPlayer = true;
          } else {
            this.isToContinueWithOldCartItems = true;
            this._retailService.DeletePreviousPlayer = false;
          }
        });
        await res.afterClosed().toPromise();
      }
    } else {
      this._retailService.SelectedOutletId = defaultOutletId;
    }
  }
  async MapUIMarkAsPaidToAPIModel(arrUiData: any[], defaultSetting, comment, defaultGuestId) {
    this.depositItemNotInOutlet = false;
    const items = await this._paymentBusinessService.GetRetailItemByItemType(GlobalConst.DepositRetailItemType);
    var depositItemList = items.filter(c => c.retailItemDetail.itemType == GlobalConst.DepositRetailItemType && c.outletItem.some(x => x.outletId == this._retailService.SelectedOutletId));
    let depositItem: any;
    if (depositItemList && depositItemList.length > 0) {
      depositItem = depositItemList[0];
    }
    let arrAPIData: any[] = [];
    let arrUIDataWithTransactionId = arrUiData.filter(x => x.transactionId > 0 && !(x?.deposits && x.deposits?.length > 0)).distinct(x => x.transactionId);
    let arrUIDataWithOutTransactionId = arrUiData.filter(x => x.transactionId == 0 || (x.transactionId > 0 && x?.deposits && x.deposits?.length > 0));
    if (arrUIDataWithOutTransactionId && arrUIDataWithOutTransactionId?.length > 0) {
      let data: any = {};
      data.OutletId = this._retailService.SelectedOutletId;
      data.SourceId = arrUIDataWithOutTransactionId[0].scheduledTeeTimeId;
      data.SourceType = RetailInterfaceSourceTypes.GOLF;
      data.AutoRetailTransactionComments = comment;
      data.AutoRetailTransactionType = AutoRetailTransactionType.GolfMarkAsPaidCreateTransaction;
      data.isTraceRequired = true;
      data.AutoRetailTransactionDetails = {}
      data.AutoRetailTransactionRequest = {}
      data.AutoRetailTransactionRequest.ClerkId = Number(this.localization.GetUserInfo('userId'));
      data.AutoRetailTransactionRequest.AutoRetailItems = [];
      data.AutoRetailTransactionRequest.UserId = Number(this.localization.GetUserInfo('userId'));
      data.AutoRetailTransactionRequest.GuestGuid = defaultGuestId;
      data.AutoRetailTransactionReason = "Tee Time Mark As Paid. TeeTimeId(s): " + JSON.stringify(arrUIDataWithOutTransactionId.map(x => x.scheduledTeeTimeId).distinct());
      let i = 0;
      arrUIDataWithOutTransactionId.forEach((x) => {
        if (x?.greenFeeRetailItemId && x.greenFeeRetailItemId > 0) {
          let tempVal = [];
          tempVal = this.MapRetailItem(++i, x.greenFeeRetailItemId, x.playerId, x.greenFee);
          tempVal.forEach(z => {
            data.AutoRetailTransactionRequest.AutoRetailItems.push(z);
          });
        }
        if (x?.cartFeeRetailItemId && x.cartFeeRetailItemId > 0 && x.walk === 'No' && x.trail === 'No') {
          let tempVal = [];
          tempVal = this.MapRetailItem(++i, x.cartFeeRetailItemId, x.playerId, x.cartFee);
          tempVal.forEach(z => {
            data.AutoRetailTransactionRequest.AutoRetailItems.push(z);
          });
        }
        if (x?.entryFeeRetailItemId && x.entryFeeRetailItemId > 0) {
          let tempVal = [];
          tempVal = this.MapRetailItem(++i, x.entryFeeRetailItemId, x.playerId, x.entryFee);

        }
        if (x?.deposits && x.deposits?.length > 0) {

          if (!depositItem) {
            this.depositItemNotInOutlet = true;
          }
          x.deposits.forEach(d => {

            let tempVal = [];
            tempVal = this.MapRetailItem(++i, depositItem?.id, x.playerId, -(d.amount), this.depositItemNotInOutlet);

            tempVal.forEach(z => {
              data.AutoRetailTransactionRequest.AutoRetailItems.push(z);
            });
          })
        }
        // added for custom fees
        if(x?.scheduledTeeTimePlayerCustomFees && x?.scheduledTeeTimePlayerCustomFees?.length > 0){
          x.scheduledTeeTimePlayerCustomFees.forEach(customFee => {
          let tempVal = [];
          let customRetailItem = this.MapScheduledTeeTimeCustomFee(++i, customFee, x.playerId,this.depositItemNotInOutlet,data.AutoRetailTransactionRequest.AutoRetailItems[0].LineNumber);
          tempVal.push(customRetailItem);
          tempVal.forEach(z => {
            data.AutoRetailTransactionRequest.AutoRetailItems.push(z);
            });
          });
        }
        data.AutoRetailPaymentDetails = {
          AutoRetailPaymentMethodIDs: [defaultSetting.markAsPaidPaymentMethod]
        }
      })
      arrAPIData.push(data)
    }
    if (arrUIDataWithTransactionId && arrUIDataWithTransactionId?.length > 0 && !this.depositItemNotInOutlet) {
      arrUIDataWithTransactionId.forEach((x) => {
        let data: any = {};
        data.OutletId = this._retailService.SelectedOutletId;
        data.SourceId = x.scheduledTeeTimeId;
        data.SourceType = RetailInterfaceSourceTypes.GOLF;
        data.AutoRetailTransactionComments = comment;
        data.AutoRetailTransactionType = AutoRetailTransactionType.GolfMarkAsPaidSettleTransaction;
        data.isTraceRequired = true;
        data.AutoRetailTransactionRequest = {};
        data.AutoRetailTransactionRequest.ClerkId = Number(this.localization.GetUserInfo('userId'));
        data.AutoRetailTransactionRequest.UserId = Number(this.localization.GetUserInfo('userId'));
        data.AutoRetailTransactionRequest.GuestGuid = defaultGuestId;
        data.AutoRetailTransactionReason = "Tee Time Mark As Paid. TeeTimeId: " + x.scheduledTeeTimeId;
        if (x?.transactionId && x.transactionId > 0) {
          data.AutoRetailTransactionDetails = {}
          data.AutoRetailTransactionDetails.RetailTicketNumber = x.ticketNumber
          data.AutoRetailTransactionDetails.TransactionId = x.transactionId
          data.AutoRetailTransactionRequest.AutoRetailItems = [];
        }
        data.AutoRetailPaymentDetails = {
          AutoRetailPaymentMethodIDs: [defaultSetting.markAsPaidPaymentMethod]
        }
        arrAPIData.push(data)
      })
    }
    return arrAPIData;
    // if (allItemNotInOutlet) {
    //     this._utils.ToggleLoaderWithMessage(false);
    //     this._utils.showAlert(this.localization.captions.common.confirmProceedMarkAsPaidWithoutDepositPlayer,
    //         AlertType.Info, ButtonType.YesNo, async (res) => {
    //             if (res === AlertAction.NO) {
    //                 arrAPIData = [];
    //                 await this._teeTimeActionService.releasePlayersInHold(arrUIDataWithOutTransactionId.map(c => c.playerId));
    //                 return arrAPIData;
    //             }
    //             else {
    //                 this._utils.ToggleLoaderWithMessage(true);
    //                 return arrAPIData;
    //             }
    //         });
    // }
    // else {
    //     return arrAPIData;
    // }
  }
  async MapRetailResponseToScheduleMarkAsPaid(arrRetailResponse: any) {
    let arrData: any[] = [];
    let arrTransDetail: PlayerTransactionDetail[] = [];
    let isMarkAsPaidFailed: boolean = false;
    var lstTransId = arrRetailResponse.filter(x => { if (x.transactionDetails.find(z => z.guestId == null)) return x.transactionId }).map(x => x.transactionId)
    if (lstTransId && lstTransId?.length > 0) {
      var transDetail_pendingsettlement = await this._PaymentModalService.GetPlayerTransactionDetailListById(lstTransId)
    }
    arrRetailResponse.forEach(x => {
      if (x?.isAutoRetailTransactionSuccess) {
        if (x?.transactionDetails && x.transactionDetails?.length > 0) {
          x.transactionDetails.forEach(y => {
            if (y?.transactionDetailId && y.transactionDetailId > 0) {
              if (!y.guestId || y.guestId == null) {
                y.guestId = transDetail_pendingsettlement.find(a => a.transactionDetailId == y.transactionDetailId)?.playerId;
              }
              if (y?.guestId && y.guestId > 0) {
                let detail = {} as PlayerTransactionDetail;
                detail.id = 0;
                detail.playerId = y?.guestId;
                detail.transactionDetailId = y.transactionDetailId;
                arrTransDetail.push(detail);
                if (arrData && arrData?.length > 0) {
                  let isPlayerExist = arrData.find(z => z.playerId == y?.guestId);
                  if (!isPlayerExist) {
                    arrData.push(this.MapTransInfo(x, y));
                  }
                  else {
                    //update the rate
                    if (y?.totalAmount) {
                      arrData.map(z => {
                        if (z.playerId == y?.guestId) {
                          z.rate += y.totalAmount;
                        }
                        return z;
                      })
                    }
                  }
                }
                else {
                  arrData.push(this.MapTransInfo(x, y));
                }
              }
            }
          });
        }
      }
      else if (!x?.isAutoRetailTransactionSuccess && !isMarkAsPaidFailed) {
        isMarkAsPaidFailed = true;
      }
    });
    return { arrData: arrData, arrTransDetail: arrTransDetail, isMarkAsPaidFailed: isMarkAsPaidFailed };
  }
  /**
   * MapTransInfo method to map transaction info
   * @param transactionInfo 
   * @param transDetailInfo 
   * @returns 
   */
  MapTransInfo(transactionInfo, transDetailInfo) {
    let data: any = {};
    data.id = 0
    data.playerId = transDetailInfo?.guestId
    data.ticketNumber = transactionInfo?.retailTicketNumber ? transactionInfo.retailTicketNumber : 0;
    data.transactionId = transactionInfo.transactionId;
    data.rate = transDetailInfo?.totalAmount ? transDetailInfo.totalAmount : 0
    return data;
  }
  MapRetailItem(index, id, playerId, amount, isDepositNotExist: boolean = false) {
    var groupItemList = this.selectedProductsArrayReassigned.filter(c => c.GroupingParentId && c.GroupingParentId != null && c.GroupingParentId == id && c.payeeId == playerId);
    let data = [];
    if (groupItemList.length > 0) {
      groupItemList.forEach(x => {
        let tempList = {}
        tempList = {
          "RetailItemId": x.ItemId,
          "RetailItemPrice": x.SalesPrice,
          "Quantity": 1,
          "LineNumber": index,
          "GuestId": playerId,
          "isDepositNotExist": isDepositNotExist,
          "sourceType": GlobalConst.CustomFeeSourceType.TeeTime,
          "sourceTypeId": playerId,
        }
        data.push(tempList);
      });
    }
    else {
      let tempList = {}
      tempList = {
        "RetailItemId": id,
        "RetailItemPrice": amount,
        "Quantity": 1,
        "LineNumber": index,
        "GuestId": playerId,
        "isDepositNotExist": isDepositNotExist,
        "sourceType": GlobalConst.CustomFeeSourceType.TeeTime,
        "sourceTypeId": playerId,
      }
      data.push(tempList);
    }
    return data;
  }
  MapScheduledTeeTimeCustomFee(index, customFee, playerId,isDepositNotExist,linkedCustomFeeItemLineNumber)
  {
    let customFeeItem = 
    {
      "RetailItemId": customFee.linkedItemId,
      "RetailItemPrice": customFee.amount,
      "Quantity": 1,
      "LineNumber": index,
      "GuestId": playerId,
      "isDepositNotExist": isDepositNotExist,
      "sourceType": GlobalConst.CustomFeeSourceType.TeeTime,
      "sourceTypeId": playerId,
      "linkedItemLineNumber": linkedCustomFeeItemLineNumber,
      "customFee": 
      {
        "Id": 0,
        "CustomFeeId": customFee.customFeeId,
        "Value": customFee.customFeeAmountAtBooking,
        "IsPercentage": customFee.isPercentage,
        "IsOverWritten": customFee.isOverWritten
      },
      "linkedCustomFeeItemLineNumber":linkedCustomFeeItemLineNumber,
    };
    return customFeeItem;
  }
}
