import { Injectable } from "@angular/core";
import { rateType } from "src/app/settings/golf-setup/code-linking/code-link-modal";
import { player } from "src/app/settings/golf-setup/tournament-setup/tournament-format/create-tournament-format-modal/create-tournament-model";
import { RetailItem } from "src/app/settings/settings-shared/assign-retail-item/assign-retail-item.model";
import { LinkMultipackDataService } from "./link-multipack-service";
import * as RateSetupAPI from 'src/app/settings/rate-setup/rate-setup.model';
import { RateSetupData } from "../teetime/player-details/player-info.model";

@Injectable({ providedIn: 'root' })

export class LinkMultipackBusiness {

    constructor(
        private _linkedMultipackDataService: LinkMultipackDataService
    ){}

    public GetPlayersByIds(playerIds: number[]) : Promise<player[]>
    {
        return this._linkedMultipackDataService.GetPlayersByIds(playerIds);
    }

    public async getTeeFeesForCourseAndDate(courseId: number, date: Date): Promise<RateSetupData[]> {
        let rateSetupApiData: RateSetupAPI.RateSetupData[] = await this._linkedMultipackDataService.getTeeFeesForCourseAndDate(courseId, date);
        return rateSetupApiData.map(x => this.mapRateSetupData(x));
    }

    private mapRateSetupData(rateSetupApiData: RateSetupAPI.RateSetupData): RateSetupData {
        return {
            id: rateSetupApiData.id,
            courseId: rateSetupApiData.courseId,
            rateTypeId: rateSetupApiData.rateTypeId,
            cartFee: rateSetupApiData.cartFee,
            greenFee: rateSetupApiData.greenFee,
            greenFeeRetailItemId:  rateSetupApiData.greenFeeRetailItemId,
            cartFeeRetailItemId: rateSetupApiData.cartFeeRetailItemId
        } as RateSetupData;
    }

    public GetRateType() : Promise<rateType[]>
    {
        return this._linkedMultipackDataService.GetRateType(false);
    }

    public GetRetailItemsByIds(itemIds: number[]) : Promise<RetailItem[]>
    {
        return this._linkedMultipackDataService.GetRetailItemsByIds(itemIds);
    }

    public LinkMultiPack(params: any[]) : Promise<boolean>
    {
        return this._linkedMultipackDataService.LinkMultiPack(params);
    }

    public UnLinkMultiPack(params: any[]) : Promise<boolean>
    {
        return this._linkedMultipackDataService.UnLinkMultiPack(params);
    }    

    public UpdatePlayerMultipackDetails(playerMultipackDetails : any[])
    {
        return this._linkedMultipackDataService.UpdatePlayerMultipackDetails(playerMultipackDetails);
    }

    public GetMultiPackDetails(session: any[])
    {
        return this._linkedMultipackDataService.GetMultiPackDetails(session);
    }
}