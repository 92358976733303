import { Injectable } from "@angular/core";
import {  API, APILanguage } from './property-info.model';
import { AuthenticationCommunication } from 'src/app/shared/communication/services/authentication.service';
import { PropertyApiRoutes } from "src/app/common/common-route";
import { TenantManagementCommunication } from "src/app/common/communication/services/tenantmanagement-communication-service";


@Injectable()
export class PropertyDataService {

    constructor(private _authenticationCommunication: AuthenticationCommunication, private commongateway: TenantManagementCommunication) {
    }


    public getPropertyInformation(propertyId: number): Promise<API.PropertyInformation> {
        return this._authenticationCommunication.getPromise({
            route: GolfApiRoute.GetProperty, uriParams: { id: propertyId }
        });        
    }

    public updatePropertyInformation(value: API.PropertyInformation): Promise<boolean> {
        return this._authenticationCommunication.postPromise({
            route: GolfApiRoute.UpdateProperty, body: value
        });       
    }

    public async getLanguages() {
        return await this._authenticationCommunication.getPromise<APILanguage[]>({ route: GolfApiRoute.GetAllLanguages });
    }

    public async GetPropertyDifferentiatorConfigurationSettingTenantByPropertyId(propertyDifferentiatorConfigurationSettings: API.PropertyDifferentiatorConfigurationSettings<any>) {
        return this.commongateway.getPromise<API.PropertyDifferentiatorConfigurationSettings<any>>(
            {
                route: PropertyApiRoutes.GetAllPropertyConfigurationSettings,
                uriParams: {
                    configurationName: propertyDifferentiatorConfigurationSettings.configurationName,
                    propertyId: propertyDifferentiatorConfigurationSettings.propertyId,
                    productId: propertyDifferentiatorConfigurationSettings.productId
                }
            });
    }
    
    public async savePropertyDifferentiatorConfigurationSettingTenantByPropertyId(data: API.PropertyDifferentiatorConfigurationSettings<any>) {
        let config: API.PropertyDifferentiatorConfigurationSettings<any> = this.apiMapper(data);
        return this.commongateway.putPromise<API.PropertyDifferentiatorConfigurationSettings<any>[]>(
            {
                route: PropertyApiRoutes.SavePropertyConfiguration,
                body: config
            });
    }

    apiMapper(data: API.PropertyDifferentiatorConfigurationSettings<any>): API.PropertyDifferentiatorConfigurationSettings<any>{
        return {
            id: data.id,
            propertyId: data.propertyId,
            productId: data.productId,
            configurationName: data.configurationName,
            configValue: JSON.stringify(data.configValue),
            defaultValue: JSON.stringify(data.defaultValue), 
            lastModifiedBy: data.lastModifiedBy,
            lastModifiedDate: data.lastModifiedDate
        };
    }
}