import { Injectable} from "@angular/core";
import { RateType } from 'src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';


@Injectable(
    { providedIn: 'root' }  
)
export class RateTypeDataService {

    constructor(private _http: GolfScheduleCommunication) {

    }

    public getAllRateTypes(showInactiveRateTypes: boolean): Promise<RateType[]> {
        return this._http.getPromise<RateType[]>(
            { route: GolfApiRoute.GetAllRateTypes, uriParams: { showInactive: showInactiveRateTypes } });
        
    }
}

