<div class="swipe-elmt-container" [ngClass]="isTopUp ? 'button_invalid':''">
    <mat-form-field class="golf-form-control--sm" [class.w-75]="isOrderSummary" [floatLabel]="floatLabel">
        <mat-select attr.automationId='Dd_{{automationId}}_selectDevice' [placeholder]="captions.SelectDevice" name="device" [(ngModel)]="CurrentDevice"
            (click)="GetDevices()">
            <mat-option *ngFor="let device of availableDevices" (click)="SelectDevice(device)" [value]="device.name">
                {{device.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button attr.automationId='Btn_{{automationId}}_connectDevice' mat-raised-button class="swipe-btn icon-Device" [class.w-36]="!isOrderSummary"
        [class.swipe-icon-btn]="isOrderSummary"
        [ngClass]="selectedDevice?.name == '' ? 'secondary-disabled':'secondary-default-btn'"
        (click)="ConnectDevice()">{{swipeTxt}}</button>
</div>
