import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { PaymentBusinessService } from 'src/app/shared/data-services/payment/payment-business.service';
import { playerTypes } from 'src/app/shared/global.constant';
import { PaymentInformationBusiness } from 'src/app/lessons/lessons-modal/payment-information/payment-information.business';
import { PlayerDetailService } from '../player-detail/player-detail.service';
import { PlayerPaymentInfoBusiness } from './player-payment-info.business';

@Component({
  selector: 'app-player-payment-info',
  templateUrl: './player-payment-info.component.html',
  styleUrls: ['./player-payment-info.component.scss'],
  providers: [PlayerDetailService, PaymentInformationBusiness, PlayerPaymentInfoBusiness]
})
export class PlayerPaymentInfoComponent implements OnInit {

  captions: any;
  paymentInfoForm: UntypedFormGroup;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  PaymentReferenceID = 0;
  viewOnly = false;
  @Output() formReady = new EventEmitter();
  cardOnFileDisabled: boolean = false;
  @Input() parentForm: UntypedFormGroup;
  @Input('inputData')
  set formData(value) {
    if (value) {
      this.PaymentReferenceID = value.paymentReferenceId;
      this.cardOnFileDisabled = value.isCardOnFileDisabled;
      if (!this.paymentInfoForm) {
        this.generatePaymentInfoFormGrp(value);
      }
      if (value && value.isRequired)
        this.paymentInfoForm.controls['paymentReferenceId'].setValidators([Validators.required]);
      else
        this.paymentInfoForm.controls['paymentReferenceId'].setValidators([]);
        this.setPaymentValue();
    } else {
      if (this.paymentInfoForm) {
        // this._playerDetailService.lessonBookingFormValues =  null;
        // this.paymentInfoForm.reset();
        this.initialize();
      }     
    }   
  }

  @Output() PaymentInfoFormEmit = new EventEmitter();

  constructor(private _PaymentInformationBusiness: PaymentInformationBusiness
    , private Form: UntypedFormBuilder
    , public _cdr: ChangeDetectorRef
    , private _utils: GolfUtilities
    , private _paymentService: PaymentBusinessService
    , private _localization: GolfLocalization
    , private _playerDetailService: PlayerDetailService,
    private _playerPaymentInfoBusiness: PlayerPaymentInfoBusiness) { }

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    this.viewOnly = this._playerPaymentInfoBusiness.isViewOnly;
    this.captions = this._PaymentInformationBusiness.GetCaptions();
    this.generatePaymentInfoFormGrp(null);
  }

  setPaymentValue()
  {
    this.paymentInfoForm.get('paymentReferenceId').setValue(this.PaymentReferenceID);
    this.paymentInfoForm.controls['paymentReferenceId'].updateValueAndValidity();
    this._cdr.detectChanges();
  }

  SaveReferenceId(event: number) {
    this.paymentInfoForm.markAsDirty();
    this.paymentInfoForm.markAsTouched();
    this.PaymentReferenceID = event;
    this.paymentInfoForm.controls.paymentReferenceId.setValue(event);
  }

  generatePaymentInfoFormGrp(value) {
    this.paymentInfoForm = this.Form.group({
      tokenReferenceId: [''],
      paymentReferenceId: '',
    });
    this.formReady.emit(this.paymentInfoForm);
    if (this.parentForm) {
      this.parentForm.addControl('paymentInfoForm', this.paymentInfoForm);
    }   
    let lessonBookingFormValues = value;
    if (lessonBookingFormValues && lessonBookingFormValues.playerInfo) {
      this.cardOnFileDisabled = lessonBookingFormValues.playerInfo.playerTypeRadio == playerTypes.member ? true : false;
      this.PaymentReferenceID = lessonBookingFormValues.playerInfo.paymentReferenceId;
      if (lessonBookingFormValues.playerInfo.paymentReferenceId === 0) {
        this.paymentInfoForm.get('paymentReferenceId').setValue('');
      }else
      {
        this.setPaymentValue();
      }
    }
    this.paymentInfoForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      this.PaymentInfoFormEmit.emit(['paymentInfoForm', this.paymentInfoForm]);
    });
  }

}
