import { Injectable } from '@angular/core';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { KeyValuePair } from 'src/app/retail/shared/business/shared.modals';

@Injectable()
export class PlayerInformationService {

  constructor(private _Localization: GolfLocalization) { }
  getCurrentdate()
  {
   return this._Localization.getCurrentDate()
  }
  getCaptions() {
    return this._Localization.captions.guest.players
  }
  getErrorCaptions(){
    return this._Localization.captions.settings;
  }

  getTitleList():KeyValuePair[] {
    return [
      {key : "DR",value : this._Localization.captions.titles.DR},
      {key : "FR",value : this._Localization.captions.titles.FR},
      {key : "MISS",value : this._Localization.captions.titles.MISS},     
      {key : "MR",value : this._Localization.captions.titles.MR},
      {key : "MRS",value : this._Localization.captions.titles.MRS},
      {key : "MS",value : this._Localization.captions.titles.MS},
      {key : "PROF",value : this._Localization.captions.titles.PROF},
      {key : "REV",value : this._Localization.captions.titles.REV},      
    ]
  }
  getGenders() {
    return [
      {
        id: 1,
        text: this._Localization.captions.common.Male,
        value: 'Male'
      },
      {
        id: 2,
        text: this._Localization.captions.common.Female,
        value: 'Female' 
      }
    ]
  }
}
