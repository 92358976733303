import { Type } from '@angular/compiler';
import { UntypedFormGroup } from '@angular/forms';
import { Time } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonPopupComponent } from './components/common-popup/common-popup.component';
import { CheckBoxTableTemplate, BookingSource, PhoneTypes, MailTypes, RentalAgreement } from './global.constant';
import { ScheduleStatus, TeeTimeFormat } from './models/teesheet.form.models';
import { PlayerPaymentstatus } from '../tee-time/search/search-model';
import { PMSRequestInfo } from '../common/external-request/pms-request.model';
import { Observable } from 'rxjs';

export interface StepperModel {
  stepperParentForm?: UntypedFormGroup;
  steps: StepsModel[];
  buttonText: string;
  isLinear: boolean;
}

export interface StepsModel {
  stepperLabel: string;
  stepperComponent: Type;
  stepControlValue?: string;
}

export interface TabConfig {
  tabLabel: string;
  tabComponent: Type;
}

export interface ComponentDetails {
  componentName: any;
  popUpDetails: PopUpDetails;
}

export interface PopUpDetails {
  isStepper: boolean;
  eventName: string;
  bindData?: any;
  headerTabData?: boolean;
  prevClickThrottleTime?: number;
  nextClickThrottleTime?: number;
  cmsPatronId?: any;
  isBulk?: boolean;
  defaultMovePlayerOption?: number;
  eventNotifydata?: any;
  hideSaveButton? : boolean;
  showActionButton? : boolean;
  saveThrottletime? : number;
  isEdit?:boolean;
  isFromTeeSheet?: boolean,
}

// Aside Filter Panel data model
export interface FilterGroup {
  id: number;
  name: string;
  title: string;
  filters: Filter[];
  filtered?: Filter[];
  value?: Filter; // Value to be selected for single filter by default
  isDisabled?: boolean;
  expand?: boolean;
}

export interface Filter {
  id: number;
  name: string;
  class?: string;
  isSelected?: boolean;
  extraParam?: any;
  isDisabled?: boolean;
}

export interface AsideFilterConfig {
  filterText: string;
  resetText: string;
  displayCount: number;
  alphabets?: string[];
  isMultiSelect: boolean;
  viewMoreConfig?: ViewMoreConfig;
}

export interface DateFilter {
  name: string;
  minDate?: Date;
  placeholder: string;
  defaultValue?: Date;
  value?: Date;
  maxDate?: Date;
  disable?: boolean;
}

export interface AsideDateFilter {
  name: string;
  isDateRange?: boolean;
  filters: DateFilter[];
}

export interface ViewMoreConfig {
  apply: string;
  cancel: string;
  searchByPlaceHolder: string;
  alphabets?: string[];
}

export interface NavMenuOption {
  id: string;
  viewValue: string;
  breakPointNumber?: number;
  hasChild?: boolean;
}

export interface SlideInputs {
  pageId?: string;
  status?: ScheduleStatus;
  scheduleStatus?: ScheduleStatus;
  statusColor?: string;
  details?: { label: string; text: string; colorCode?: string; startHole?: number; }[];
  dateTime?: { dateLabel: string; dateText: string; timeLabel: string; timeText: string };
  actions?: { id: string; icon: string; label: string; isDisable?: boolean; }[];
  checkInCheckOutActions?: { type: string; label: string; disabledproperty: boolean; teeTimeAction: any;class:any }[];
  actionTitle?: string;
  buttonText?: string;
  players?: Players[];
  deposit?: string | number;
  data?: any;
  allocationBlockOptions?: AllocationCode[];
  teeTimeAllocationBlock?: TeeTimeAllocationBlock;
  isDisable?: boolean;
  originalHoleNumber?: string;
  holeNumber?: string;
  scheduledTeeTimeId?: number;
  viewedByStarter?: boolean;
  teeGridSlotId?: number;
  allocationDateTime?: Date;
  isRefundedPlayersAvailable?: boolean;
  endTime?: { dateLabel: string; dateText: string; timeLabel: string; timeText: string };
}

export interface AllocationCode {
  id: number;
  allocationBlockCode: string;
  allocationBlockName: string;
  colorCode?: string;
  daysOut: number;
  isActive?: boolean;
  listOrder?: number;
}

export interface Players {
  name: string;
  imageReferenceId: string;
  isBlocked?: boolean;
}

export interface TableSearchHeader {
  createBtnLabel?: string;
  showInactive?: boolean;
  searchPlaceHolder: string;
  toggleLabel?: string;
  createBtnDisabled?: boolean;
  toggleYes?: string;
  toggleNo?: string;
  toggleDisabled?: boolean;
  hasHeaderAction?: boolean;
  searchDisabled?: boolean;
  headerActionDisabled?: boolean;
  automationId?:string;
}

// footer button component object
export interface SaveButtonObj {
  isEdit: boolean;
  disabled: boolean;
  customSaveText?: string;
  customCancelText?: string;
  showAddToCartButton?: boolean;
  automationId?:string;
}

export interface ContactDetails {
  id: number;
  type: number;
  value: string;
  isPrimary: boolean;
  isPrivateInfo: boolean;
  name: string;
  countryCode?: string | number;
  extension: string;
  description?: string;
  playerId?: number;
  listOrder?: number;
}

export interface ContactLog {
  id : number;
  productId? : number;
  logTypeId : number;
  logNotes : string;
  logDate : Date | string;
  dueDate? : Date | string;
  expiryDate? : Date | string;
  isAlert : boolean;
  isPrivate : boolean;
  isGroup : boolean;
  createdAt? : Date;
  createdBy? : number;
  modifiedAt? : Date;
  modifiedBy? : number;
}

export interface ContactDetailListOrder {
  type: PhoneTypes | MailTypes;
  listOrder: number;
}

export interface DateInput {
  className?: string;
  errorMessage?: string;
  form?: UntypedFormGroup;
  formControlName?: string;
  hidden?: boolean;
  maxDate?: Date | string;
  minDate?: Date | string;
  placeHolder?: string;
  value?: Date | string;
  isDisabled?: boolean;
  isDateRequired?: boolean;
  selectableDates?: Date[];
  inValidDate?: string;
  automationId?: string;
}

export interface TimeInput {
  className?: string;
  errorMessage?: string;
  form?: UntypedFormGroup;
  formControlName?: string;
  floatLabel?: string;
  hidden?: boolean;
  maxTime?: string;
  minTime?: string;
  placeHolder?: string;
  value?: string;
  defaultTime?: string;
  customErrorMessage?: string;
  isTimeRequired?: boolean;
  automationId?: string;
  index?:number;
}

export interface PhoneDetail {
  id: number;
  phoneType: number;
  countryCode: string | number;
  phoneNumber: string;
  extension: string;
  isPrimary: boolean;
  isPrivate: boolean;
  primaryPhone?: boolean;
  privateInformtionPhone?: boolean;
}

export interface PhoneAttributes {
  id: number;
  phoneNumber?: string;
  phoneType?: number;
  extension: string;
}

export interface EmailAttributes {
  id: number;
  emailType?: number;
  emailValue?: string;
}

export enum PlayerTypes {
  NonMember = 1,
  Resort = 2,
  Member = 3,
  Player = 4
}

export enum ContactType {
  phone = 1,
  email = 2,
  office = 3
}

export interface AgFieldConfig {
  id?: string;
  className?: string;
  errorMessage?: string;
  form?: UntypedFormGroup;
  formControlName?: string;
  placeHolder?: string;
  value?: string | number;
  maxlength?: string | number;
  automationId?:string;
}

export interface AgAddressFieldConfig {
  className?: string;
  errorMessage?: string;
  form?: UntypedFormGroup;
  formArrayName?: string;
  formControlName?: string;
  placeHolder?: string;
  value?: string;
  disabled?: boolean;
  automationId?:string;
}

export interface AgContactFieldConfig {
  typeControlName?: string;
  typeClass?: string;
  className?: string;
  extensionClass?: string;
  extnPlaceHolder?: string;
  errorMessage?: string;
  form?: UntypedFormGroup;
  formArrayName?: string;
  formControlName?: string;
  placeHolder?: string;
  value?: string | number;
  options?: any[];
  showSwitches?: boolean;
  isPrimaryName?: string;
  isPrivateName?: string;
  emailPlaceHolder?: string;
  isEmailRequired?: boolean;
  requiredErrorMessage?: string;
  fromScreen?: string;
  disabled? : boolean;
  automationId? :string;
}

export enum ButtonType {
  YesNo = 1,
  YesNoCancel = 2,
  OkCancel = 3,
  Ok = 4,
  SaveCancel = 5,
  Continue = 6,
  ContinueCancel = 7,
  AddCancel = 8,
  viewFailedCancel = 9,
  CourseFeeSelection = 10,// TO DO need to remove
  OkOverride = 11
}

export const OVERRIDE_ALERT_WIDTH = 310;
export const OVERLAPPING_PLAYER_WIDTH = 450;

export const WAITLIST_CADDY_ERROR_CODE = 30430;


export enum SearchType {
  Guest = 1,
  Player,
  GuestAndPlayer,
  GuestAndConfNo,
  ConfNo,
  Member
}

export enum AlertType {
  Success = 1,
  Warning = 2,
  Error = 3,
  WellDone = 4,
  Info = 5,
  AccessDenied = 6
}


export enum RefundMethod {
  ReturnWithoutExchange = 1,
  ReturnWithExchange
}

export enum AlertAction {
  CONTINUE = 'CONTINUE',
  CANCEL = 'CANCEL',
  YES = 'YES',
  NO = 'NO',
  OVERRIDE = 'OVERRIDE'
}

export interface DataType {
  id: number;
  name: string;
}

export class Settings { }

export interface localizationJSON {
  Tournament: any;
  common: any;
  calendar: Calendar;
  home: any;
  teetime: any;
  guest: any;
  header: any;
  shop: any;
  lessons: any;
  lostfound: any;
  reports: any;
  settings: any;
  breakpoint: any;
  allocationblock: any;
  userConfig: any;
  teetimeIntervalConfig: any;
  tournamentformat: any;
  tournamentScore: any;
  subMenu: any;
  customField: any;
  tournamentContact: any;
  dashBoard: any;
  teeActions: any;
  contactTypes: any;
  dayend: any;
  lesson: any;
  lessonBooking: any;
  virtualTable: any;
  lostandfound: any;
  guestTable: any;
  tooltips: any;
  resolutionNotFound: any;
  GroupBookTeeTime: any;
  BulkBookTeeTime: any;
  alertPopup: any;
  PlayerPaymentStatus: any;
  CaddySelect: any;
  titles: any;
  bookAppointment: any;
  utilities: any;
  retailsetup: any;
}

export interface MoreFilterOptions {
  ShowMoreFilters: boolean;
  moreData: any;
  selectedMoreData: any;
  top: Number;
  left: Number;
  belongto: string;
  fromPage: string;
}

export interface Calendar {
  Sun: string;
  Mon: string;
  Tue: string;
  Wed: string;
  Thu: string;
  Fri: string;
  Sat: string;
  Sunday: string;
  Monday: string;
  Tuesday: string;
  Wednesday: string;
  Thursday: string;
  Friday: string;
  Saturday: string;
  January: string;
  February: string;
  March: string;
  April: string;
  May: string;
  June: string;
  July: string;
  August: string;
  September: string;
  October: string;
  November: string;
  December: string;
  Jan: string;
  Feb: string;
  Mar: string;
  Apr: string;
  Jun: string;
  Jul: string;
  Aug: string;
  Sep: string;
  Oct: string;
  Nov: string;
  Dec: string;
}

export interface LocalizedMonthsModel {
  id: number;
  short: string;
  long: string;
  code: string;
}

export interface UserAlertsModal {
  id: number;
  message: string;
  priority: number;
}

export interface DaysModel {
  id: number;
  short: string;
  long: string;
  code: string;
  longCode?: string;
}

export interface DaysModel {
  id: number;
  short: string;
  long: string;
  code: string;
  longCode?: string;
}

// Player information card
export interface PlayerCardInfo {
  id: number;
  playerId: number;
  firstName: string;
  lastName: string;
  countryCode: string | Number;
  phoneNo: string;
  extension: string;
  emailId: string;
  membershipNo: string;
  groupId: number;
  confirmationNo: string;
  sequenceNo: string;
  holes: number;
  playerType: string;
  rateType?: string;
  isValid: boolean;
  playerPosition?: number;
  playerTypeId: number;
  rateTypeId: number;
  vipType: string;
  index?: number;
  localPlayerId?: number;
  isTeeTimeBooked?: boolean;
  gender?: number;
  playerStatus?: number;
  paymentReferenceId?: number;
  bookingSource?: BookingSource;
  patronid?: string;
  rank?: string;
  ticketNumber?: string;
  gameFormat: TeeTimeFormat;
  hasUserAccess: boolean;
  isBlocked: boolean;
  playerLinkId: string;
  playerCategory: number;
  isPaidPlayer?: boolean;
  isPlayerEditable?: boolean;
  isDisabled?: boolean;
  multiPackTransactionDetailId?: number;
  courseId: number;
}

export interface CardMenu {
  id: number;
  name: string;
  isDisabled?: boolean;
}

export interface MoreMenuResult {
  actionMenu: CardMenu;
  playerInfo: PlayerCardInfo;
  playerIndex?: number;
}

export interface DialogCloseObj {
  type: string;
  dialogRef: MatDialogRef<CommonPopupComponent>;
  selectedIndex?: number;
}

export interface ToggleConfig {
  label?: string;
  name?: string;
  value?: any;
  group: UntypedFormGroup;
  formControlName?: string;
  className?: string;
  disabled?: boolean;
  horizontal?: boolean;
}

export interface ButtonData {
  description: string;
  disabled?: boolean;
  id: number;
  name: string;
}
export interface CheckboxTableHeader {
  key: string;
  description: string;
  alignment: string;
  template?: CheckBoxTableTemplate;
  templateName?: string;
}

export interface LegendInfo {
  id: number;
  icon: string;
  name: string;
  color?: string;
}

export interface TeeSlotInfo {
  title: string;
  color: string;
  bodyInfo?: { icon: string; iconColor: string; description: string; color: string }[];
  commentLabel: string;
  comment: string;
  groupBookingName?: string;
  player? : any; 
  isEformsEnabled ? : boolean;
}

export interface PlayerTransaction {
  id: number;
  playerId: number;
  transactionId: number;
  ticketNumber: string;
  greenFeeTax: number;
  cartFeeTax: number;
  entryFeeTax: number;
  amountPaid: number;
  remainingDue: number;
  playerStatus: PlayerPaymentstatus;
  status: TransactionStatus;
}

export enum TransactionStatus {
  Pending = 1,
  Closed
}

export interface PlayerTransactionDetail {
  id: number;
  playerId: number;
  playerName: string;
  transactionId: number;
  transactionDetailId: number;
  playerTransactionId: number;
  greenFeeTax: number;
  cartFeeTax: number;
  entryFeeTax: number;
}

export interface TeeTicketDetail {
  courseId: number;
  scheduleDateTime: Date;
  scheduledTeeTimeIds: number[];
}

export interface TransactionDetailAggregate {
  playerTransactionDetails: PlayerTransactionDetail[];
  teeTicketDetail: TeeTicketDetail;
}

export enum ConvertWaitlist {
  teeSheet = 'teeSheet',
  waitlist = 'waitlist'
}
export enum ActionPageId {
  lesson = 'lesson',
  teeSheet = 'teeSheet',
  graphicalView = 'graphicalView'
}

export interface ImageData {
  id: number;
  referenceType: string;
  referenceId: number;
  sequenceNo: number;
  contentType: string;
  data: any[];
  thumbnailData: any[];
}

export interface Addresscomponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface GoogleAddressOutput {
  fullAddress?: Addresscomponent;
  city: string;
  state: string;
  country: string;
  zipCode: string;
}

export interface TitleTooltipData {
  allowTooltip: boolean;
  formcontrolName: string;
  titleDescription: string;
}

export enum GuaranteeTypes {
  None = 'None',
  Member = 'Member',
  Resort = 'Resort',
  CreditCard = 'Credit Card'
}

export interface GuaranteeType {
  id: number;
  name: string;
  description: string;
  disabled?: boolean;
}

export interface RateTypeGuarantee extends GuaranteeType {
  // Add if any other properties needed
}

export interface PlayerTypeGuarantee extends GuaranteeType {
  // Add if any other properties needed
}

export interface GuestTableHeader {
  key: string;
  value: any;
  type: string;
  isSortable: boolean;
  sortKey?: string;
  titleKey?: string;
}

export interface GolfImageData {
  id: number;
  referenceType: string;
  referenceId: number;
  sequenceNo: number;
  contentType: string;
  data: any[];
  thumbnailData: any[];
}

export const enum GolfImgRefType {
  player = 'PLAYER',
  guest = 'GUEST',
  retailItem = 'RETAILITEM',
  property = 'PROPERTYINFO'
}

export const enum SqueezeRefType {
  squeeze,
  cancel
}

export class GolfPMSSessionInfo extends PMSRequestInfo {
  stayFromDate: Date | undefined;
  stayToDate: Date | undefined;
}

export enum menuTypes {
  primary,
  secondary,
  tertiary,
  lowerLevel
}

export class TeeTimeAllocationBlock {
  id: number;
  courseId: number;
  allocationBlockId: number;
  allocationDateTime: Date;
  holeNumber: string;
  isDefault: boolean;
  originalHoleNumber: string;
  viewedByStarter: boolean;
}

export interface DropdownOptions {
  id?: number | string;
  value?: string | any;
  viewValue: string;
  disabled?: boolean;
  otherData?: any;
}
export interface AgDropdownConfig<T = any> {
  data?: T;
  className?: string;
  errorMessage?: string;
  errorMessageId?: string;
  form: UntypedFormGroup;
  formControlName: string;
  placeHolder?: string;
  placeHolderId?: string;
  selectOptions: Observable<DropdownOptions[]> | Promise<DropdownOptions[]>;
  isFirstEmpty?: boolean;
  isSelect?: boolean;
  showRequired?: boolean;
  disabled?: boolean;
  isMultiSelect?: boolean;
  customErrorMessage?: string;
  customErrorMessageId?: string;
  defaultSelectededOptionValue?: DropdownOptions;
  isAll?: boolean;
  isAllSelected?: boolean;
  selectedIds?: number[];
  automationId?:string;
}

export interface OverlappingSlotsForGuest{
  scheduledTeeTimeOfPlayer: string;
  firstName: string;
  lastName: string;
  courseName: string;
  courseId: number;
  playerStatus: number;
  playerLinkId: string;
  playerId: number;
}

export enum TeeTimeActions {
  Scheduled = 'Scheduled',
  copy = 'copy',
  refund = 'refund',
  copyToMany = 'copyToMany',
  move = 'move',
  squeeze = 'squeeze',
  print = 'print',
  payment = 'payment',
  rate = 'rate',
  cancelPlayers = 'cancelPlayers',
  clearPlayers = 'clearPlayers',
  bulkMove = 'bulkMove',
  holdTeeTime = 'holdTeeTime',
  frostDelay = 'frostDelay',
  waitlist = 'waitlist',
  noshow = 'noshow',
  reinstate = 'reinstate',
  notify = 'notify',
  blockTeeTime = 'blockTeeTime',
  deleteTeeTimeSlot = "deleteTeeTimeSlot"
}
export interface TemplateTags {
  title: string;
  tags: Tags[];
  showGroupTitle: boolean;
  titleCode: string;
}

export interface Tags {
  id: number;
  code: string;
  name: string;
  categoryCode: string;
  categoryName: string;
  entity?: string;
  field?: string;
  conversion?: string;
  tagCode: string;
  properties: TagProperty[];
}

export interface TagProperty {
  key: string;
  value: string;
}

export interface EditorInputs {
  formGroup: UntypedFormGroup;
  formControlName: string;
  showTags: boolean;
  showCollapse?: boolean;
  showSearch?: boolean;
  isViewOnly?: boolean;
}
export enum TagKeys {
  FontFamily = 'font-family',
  FontSize = 'font-size',
  FontStyle = 'font-style',
  FontWeight = 'font-weight',
  TextDecoration = 'text-decoration'
}
export interface PrintRentalAgreement {
  RentalAgreement: RentalAgreement;
  RetailItemId?: number;
}

export enum PaymentModalType{
  save,
  pay,
  shop,
  update,
  cancel,
  close
}