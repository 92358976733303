<div class="client-popup hide-scrollbar h-100">
 
    <div id="cust-header-id">
        <app-dialog-header [title]=captions.EnterCardDetails (closeDialog)="closeDialog()"></app-dialog-header>
    </div>
      

    <div class=" ag_display--flex note-message">
        <span class="mr-2"><i class="icon-warning-icon"></i></span>
        <span>{{captions.NonIntegratedCreditCardMessage}}</span>
    </div>
    <mat-dialog-content class="nicc_iframe_Content newVersionWrapper">
        <div class="clientTabLayout tab-styling-versiontwo">
            <div>
                <iframe id="myIframe" class="iframe_paymethod iframe_width" type="text/html" width="100%" height="100%"
                    frameborder="0" [src]="iframeSrc" (load)="onIframeLoad()"> </iframe>
            </div>

        </div>
    </mat-dialog-content>
</div>