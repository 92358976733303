import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StepperService } from '../stepper/stepper.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

import { SaveButtonObj, SqueezeRefType } from '../../shared-models';

@Component({
  selector: 'app-minutessqueeze',
  templateUrl: './minutessqueeze.component.html',
  styleUrls: ['./minutessqueeze.component.scss']
})
export class MinutessqueezeComponent implements OnInit {
  minutes: number[];
  squeezeMinutesForm: UntypedFormGroup;
  captions: any;
  buttonObj: SaveButtonObj;
  constructor(public fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public dialogData, private _StepperService: StepperService, private _Localization: GolfLocalization,
  private dialogRef: MatDialogRef<MinutessqueezeComponent>,) {
    this.captions = this._Localization.captions;
  }

  ngOnInit() {
    this.createSqueezeMinutes();
    this.createSqueezeMinutesForm();
    this.setDefaultMinutes();
    this.buttonObj = {
      isEdit: false,
      disabled: false,
      customSaveText: this.dialogData.save
    };
  }  

  createSqueezeMinutesForm() {
    this.squeezeMinutesForm = this.fb.group({
      squeezeminutes: ''
    });
  }

  createSqueezeMinutes() {
    if (this.dialogData.info) {
      this.minutes = [];
      for (let index = 1; index <= this.dialogData.info; index++) {
        this.minutes.push(index);
      }
    }
  }

  setDefaultMinutes() {
    if (this.minutes.length > 0) {
      this.squeezeMinutesForm.controls['squeezeminutes'].setValue(this.minutes[0]);
      this._StepperService.enableSave = true;
    }
  }

  closeHandler(arg) {
    let obj = {
      'fromtype': SqueezeRefType.cancel,
      'obj': this.squeezeMinutesForm
    }
    this.dialogRef.close(obj);
  }

  saveHandler(event) {
    let obj = {
      'fromtype': SqueezeRefType.squeeze,
      'obj': this.squeezeMinutesForm
    }
    this.dialogRef.close(obj);
  }

}
