import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, timer } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import * as _ from "lodash";
import { JWT_TOKEN, USERS_SESSSIONS_INFO } from '../app-constants';
import { UserProperty } from '../common/Models/common.models';
import { SignalrService } from 'src/app/common/communication/signalR/signalr.service';
import { GolfUtilities } from '../shared/utilities/golf-utilities';
import { NotificationFailureType } from '../shared/components/menu/menu.model';
import { Host } from '../retail/shared/globalsContant';
import { HttpMethod, HttpServiceCall } from '../retail/shared/service/http-call.service';
import { RetailPropertyInformation } from '../retail/common/services/retail-property-information.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { ADB2CAuthConfiguration } from 'src/app/common/shared/auth.config';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { RetailRoutes } from 'src/app/retail/retail-route';

@Injectable({
    providedIn: 'root'
})
export class ManageSessionService implements OnDestroy {

    public resetOnTrigger: boolean = false;
    public timeoutExpired: Subject<number> = new Subject<number>();
    private count = 0;
    private logOffAfter: number;
    private timeoutSeconds: number;
    private timerSubscription: Subscription;
  private timer: Observable<number>;
  private triggerTimeout: any;
  captions: any;
  logOutWaitingtime: number = 120000; //milliseconds
  tokenTimerSubscription: Subscription;
  private tokenTimer: Observable<number>;
    token = {
        refresh_token: 'refreshtokencode',
        exp: '',
        access_token: {
            username: 'user',
            roles: ['Admin', 'RegisteredUser', 'Super User']
        }
    };

    scope: string = "Spa"
    state: string = Date.now() + "" + this.util.getRandomDecimal();
    tokenKey: string = "a5smm_utoken"
    propertyKey: string = "propertyInfo"
    url: string = "";
    tenantId: any = 1;
    locations: any[];
    propertyValues: any[];
    userSessionId: string = "userSession";
    rememberDetail:any[] = [{name:""}];
    public timerSubscriptionForNotification: Subscription;
    public timerForNotification: Observable<number>;
    public transactionCount: BehaviorSubject<{ id: number, count: number,message?: string }[]> = new BehaviorSubject([]);
    private sessionSubject = new BehaviorSubject<any>(null);

    constructor(private router: Router, public dialogRef: MatDialog, private util: GolfUtilities,
                public http: HttpServiceCall, private propertyInformation: RetailPropertyInformation 
                , private oauthService: OAuthService
                , private adb2cAuthConfiguration: ADB2CAuthConfiguration, private signalR: SignalrService,private localize: GolfLocalization) {
                  this.captions = this.localize.captions;
        this.timeoutExpired.subscribe();
        if (this.tokenTimerSubscription) {
            this.tokenTimerSubscription.unsubscribe();
          }
        if (this.timerSubscriptionForNotification) {
            this.timerSubscriptionForNotification.unsubscribe();
        }
        this.start();
        
      }
    goToLogin() {
        this.router.navigate(['login']);
    }
    ngOnDestroy() {
        this.timeoutExpired.unsubscribe();
    }

    logout() {
      this.closeSignalRConnection();
      const bodyTag = document.getElementsByTagName('body')[0];
      bodyTag.removeAttribute("id");
        this.stopTimerForNotification();
        this.removeToken();
        this.dialogRef.closeAll();
        this.clearLocalStore();
        this.changeTitle();
        if(this.adb2cAuthConfiguration.ADB2CAuthFeatureEnabled)
        {
            console.log('adb2c logout');
            this.oauthService.logOut(); //ADB2C logout 
        }
        this.navigationAfterLogOut();
    }

    navigationAfterLogOut(){
      if(sessionStorage.getItem('supportUserMailId')){
        this.router.navigate(['supportlogin']);
      }
      else{
        this.router.navigate(['login']);
      }
    }

    async closeSignalRConnection(){
      this.signalR.stopConnection();
    }  
  
    public GetPropertyInfo(name: string) {
        let nameEQ = name + "=";
        let propertyInfo = sessionStorage.getItem("propertyInfo")
        if (propertyInfo != null) {
            let ca = propertyInfo.split(";");
            for(let element of ca)
            {
              let c = element.trim();
              while (c.charAt(0) == ' ') c = c.substring(1, c.length);
              if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }

        }
        return null;
    }


    getToken() {
        return JSON.parse(sessionStorage.getItem(this.tokenKey));
    }

    setToken(token = this.token) {
        sessionStorage.setItem(this.tokenKey, JSON.stringify(token));
    }

    getAccessToken() {
        return JSON.parse(sessionStorage.getItem(this.tokenKey))['access_token'];
    }

    isAuthenticated() {
        let token = sessionStorage.getItem(this.tokenKey);

        if (token) {
            return true;
        }
        else {
            return false;
        }
    }
   

    removeToken() {
        sessionStorage.removeItem(this.tokenKey);
        sessionStorage.removeItem("_jwt");
    }

    clearLocalStore() {
        let rememberDetails = sessionStorage.getItem("_rememberInfo");
        sessionStorage.clear();
        sessionStorage.setItem("_rememberInfo", rememberDetails);
    }

    StoreUser(user)
    {
      let rememberList = this.GetRememberedUsers();
      if(rememberList.find(x=> x.name == user)) return;
       rememberList = [{name:user}];
      sessionStorage.setItem('_rememberInfo', JSON.stringify(rememberList));
    }
    RemoveUser(user)
    {
      let storedUsers = this.GetRememberedUsers();
     let updatedStore =  _.remove(storedUsers, (u) => {
          return u.name!= user;
      });
      sessionStorage.setItem('_rememberInfo', JSON.stringify(updatedStore));
    }

    GetRememberedUsers(): any[]
    {
      let rememberList = JSON.parse(sessionStorage.getItem('_rememberInfo'));
      rememberList = (rememberList!=null) ? rememberList:[];
      return  rememberList;
    }

    CheckRememberDetails():boolean
    {
      let rememberList = JSON.parse(sessionStorage.getItem('_rememberInfo'));
      rememberList = (rememberList!=null) ? rememberList:[];
      return rememberList.length > 0;
    }

    public startTimer(logOffAfter: any, tokenExpiry: number) {
   
        if (logOffAfter == 0) {
          if (tokenExpiry > 122) {
            tokenExpiry = tokenExpiry - 122; // buffer time for token expiry
          }
          this.tokenTimer = timer(tokenExpiry * 1000);
          this.timerSubscription = this.tokenTimer.subscribe();
         
        }
        else {
          if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
          }
          this.timeoutSeconds = logOffAfter * 60;
          this.timer = timer(this.timeoutSeconds * 1000);
          this.timerSubscription = this.timer.subscribe();        
        }
      }
      public forceLogOff() {
        let jwtExpiryTime: any = sessionStorage.getItem('jwtExpiryTime');
        jwtExpiryTime = new Date(jwtExpiryTime);
        let currentTime: any = new Date();
        let expirySeconds = jwtExpiryTime - currentTime;
        if (!sessionStorage.getItem('popupEnabled')) {
          if (this.tokenTimerSubscription) {
            this.tokenTimerSubscription.unsubscribe();
          }
          if (expirySeconds > this.logOutWaitingtime) {
            expirySeconds = expirySeconds - this.logOutWaitingtime;
          }
          this.tokenTimer = timer(expirySeconds);
          this.tokenTimerSubscription = this.tokenTimer.subscribe();
        }
        else {
          if (expirySeconds > 0) {
            this.triggerTimeout = setTimeout(() => {
              this.logout();
            }, expirySeconds);
          }
          else {
            this.logout();
          }
    
        }
      }
    
        public stopTimer() {
            if (this.timerSubscription) {
                this.timerSubscription.unsubscribe();
          }
          if (this.tokenTimerSubscription) {
            this.tokenTimerSubscription.unsubscribe();
          }
        }
    
        public resetTimer() {
            if (this.timerSubscription) {
                this.timerSubscription.unsubscribe();
            }
    
            this.timer = timer(this.timeoutSeconds * 1000);
            this.timerSubscription = this.timer.subscribe();
        }

       

        public UpdateUserSessionsInfo(loginDetails) {
            let userSessionDetails;
            userSessionDetails = this.mapLoginDetailsToLocalModel(loginDetails);
            this.SetUserSessionsInfo(userSessionDetails);
        }
    
        public SetUserSessionsInfo(userSessionDetails) {
            this.setUserSessionsInfoItem(USERS_SESSSIONS_INFO, JSON.stringify(userSessionDetails));
        }
    
        public GetUserSessionsInfo() {
            let userSessions: any;
            const sessionDetails = this.getUserSessionsInfoItem(USERS_SESSSIONS_INFO);
            if (sessionDetails) {
                userSessions = JSON.parse(sessionDetails);
            }
            return userSessions;
        }

        public changeTitle() {
          const title = document.getElementsByTagName('title')[0];
          title.innerText = this.getPropertyName() ? this.getPropertyName() + ' - ' + this.captions.app_title :
              this.captions.app_title;
        }
        private getPropertyName() {
          return this.localize.GetsessionStorageValue('propertyInfo', 'PropertyName');
      }
    private getUserSessionsInfoItem(key: string): string | null {
        return sessionStorage.getItem(key);
    }

    private setUserSessionsInfoItem(key: string, value: string): void {
        localStorage.setItem(key, value);
        return sessionStorage.setItem(key, value);
    }

        private mapLoginDetailsToLocalModel(loginDetails) {
            return {
                token: loginDetails.token,
                expiresOn: new Date(),
                userLoginInfo: {
                    firstName: loginDetails.userLoginInfo.firstName,
                    lastName: loginDetails.userLoginInfo.lastName,
                    isNewUser: loginDetails.userLoginInfo.isNewUser,
                    isPasswordExpired: loginDetails.userLoginInfo.isPasswordExpired,
                    languageCode: loginDetails.userLoginInfo.languageCode,
                    productId: loginDetails.userLoginInfo.productId,
                    propertyId: loginDetails.userLoginInfo.propertyId,
                    tenantCode: loginDetails.userLoginInfo.tenantCode,
                    tenantId: loginDetails.userLoginInfo.tenantId,
                    userId: loginDetails.userLoginInfo.userId,
                    isPropertyChangeAllow: loginDetails.userLoginInfo.isPropertyChangeAllow,
                    userName: loginDetails.userLoginInfo.userName
                },
                userProperties: loginDetails.userProperties?.map(userProperty => {
                    return {
                        autoLogOff: userProperty.autoLogOff,
                        currencyCode: userProperty.currencyCode,
                        isActive: userProperty.isActive,
                        languageCode: userProperty.languageCode,
                        logOffAfter: userProperty.logOffAfter,
                        platformPropertyId: userProperty.platformPropertyId,
                        platformTenantId: userProperty.platformTenantId,
                        productId: userProperty.productId,
                        profitCenter: userProperty.profitCenter,
                        propertyCode: userProperty.propertyCode,
                        propertyDate: userProperty.propertyDate,
                        propertyId: userProperty.propertyId,
                        propertyName: userProperty.propertyName,
                        roleId: userProperty.roleId,
                        roleName: userProperty.roleName,
                        subPropertyCode: userProperty.subPropertyCode,
                        subPropertyId: userProperty.subPropertyId,
                        subPropertyName: userProperty.subPropertyName,
                        tenantId: userProperty.tenantId,
                        timeZone: userProperty.timeZone,
                        userId: userProperty.userId,
                        sessionId: null,
                        arrivalTime: userProperty.arrivalTime,
                        checkOutTime: userProperty.checkOutTime
                    } as UserProperty;
                })
            };
        }
  async createSession() {
    /**
  * createSession
  */
  }

  public startTimerForNotification(notifyin: number) {
    if (notifyin && notifyin > 0) {
        this.stopTimerForNotification();
        this.timerForNotification = timer(notifyin * 60 * 1000);
        this.timerSubscriptionForNotification = this.timerForNotification.subscribe(n => {
            this.timerCompleteForNotification(n);
        });
    }
}

private async timerCompleteForNotification(n: number) {
  try {
    if (this.propertyInformation.HasRevenuePostingEnabled) {
        const revenuePostingFailures = await this.getRevenuePostingCount();
        this.transactionCount.next([{ id : NotificationFailureType.revenuePostingFailure, count : revenuePostingFailures }]);
    }
    const paymenentFailures = await this.getTransactionLogCount();
    this.transactionCount.next([{ id : NotificationFailureType.paymentTransactionFailure, count : paymenentFailures }]);
    if(this.propertyInformation.IsDMPostingEnabled){
      const dMPostingFailure = await this.getFailedDMPostingCount();
      this.transactionCount.next([{ id : NotificationFailureType.dMPostingFailure, count : dMPostingFailure }]);
    }
    this.startTimerForNotification(10);
  }
  catch (err){
        if (err && err.status === 401) {
            this.stopTimerForNotification();
            return ;
        }
  }
}


public async getRevenuePostingCount(): Promise<number> {
    const response = await this.http.CallApiAsync<number>({
        callDesc: 'GetFailureRevenuePosting',
        host: Host.retailPOS,
        method: HttpMethod.Get,
        showError: true
    });
    return response.result;
}
public async getTransactionLogCount(): Promise<number> {
    const response = await this.http.CallApiAsync<number>({
        callDesc: 'GetFailureDetails',
        host: Host.payment,
        method: HttpMethod.Get,
        showError: true
    });
    return response.result;
}

public async getFailedDMPostingCount(): Promise<number> {
  const response = await this.http.CallApiAsync<number>({
    callDesc: RetailRoutes.FailedDMPostingCount,
    host: Host.retailPOS,
    method: HttpMethod.Get,
    showError: true
  });
  return response.result;
}

public stopTimerForNotification() {
    if (this.timerSubscriptionForNotification) {
        this.timerSubscriptionForNotification.unsubscribe();
    }
}
start(): void {
  window.addEventListener("storage", this.storageEventListener.bind(this));
}
// Logout only when key is 'logout-event'
storageEventListener(event: StorageEvent) {
  if (event.storageArea == localStorage) {
    if (event?.key && event.key == 'logout-event') {
      const jwt = sessionStorage.getItem(JWT_TOKEN);
      const propertyInfo = this.propertyInformation.GetPropertyInfoByKey('PropertyId');
      const localData = JSON.parse(event.newValue);
      if(jwt == localData['jwt'] && propertyInfo == localData['propertyInfo'] && this.propertyInformation.GetPropertyInfoByKey('ProductId') == localData['Product']){
          this.logout()  
      }
    }
  }
}

   // Set session value and notify subscribers
   setSessionValue(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
    this.sessionSubject.next({ key, value });
  }

  // Get session value
  getSessionValue(key: string): any {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }

  // Subscribe to session value changes
  getSessionChanges() {
    return this.sessionSubject.asObservable();
  }
}
