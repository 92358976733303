<section class="select-eForms-wrapper">
    <app-dialog-header class="header" [title]="captions.hdr_selectEForm" (closeDialog)="close($event)"></app-dialog-header>
    <div class="content" [formGroup]="eFormGroup">
        <div class="d-flex wrapper">
            <app-simple-search [placeholder]="captions.searchPlaceholder_eForm" (searchChange)="searchValueChange($event)"
            ></app-simple-search>
            <app-ag-date-picker [inputs]="fromDateInput" (datePickerChange)="fromDateChange($event)">
            </app-ag-date-picker>
            <app-ag-date-picker [inputs]="endDateInput" (datePickerChange)="endDateChange($event)">
            </app-ag-date-picker>
        </div>
        <div class="table-container">
            <app-cdkvirtual [headerOptions]="headerOptions"
            [tableContent]="tableContent" [options]="options" (EmittedData)='tableAction($event)'></app-cdkvirtual>
        </div>
    </div>
    <div class="footer ag_footer--actions">
        <app-button [buttontype]="saveButton" (valueChange)='onSave($event)'></app-button>
        <app-button [buttontype]="cancelButton" (valueChange)='onCancel($event)'></app-button>
    </div>
</section>
