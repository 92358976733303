import { NgModule  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreRoutingModule } from './core-routing.module';
import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoaderInterceptor } from './services/loader.interceptor.service';
import { DynamicFormBuilderModule } from '../dynamicforms/dynamic-forms.module';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from './localization/custom.dateAdapter';
import { GolfLocalization } from './localization/golf-localization';
import { Platform, PlatformModule } from '@angular/cdk/platform';
import { AuthGuardService } from '../retail/common/services/auth-guard.service';
import { DeactivateGuard } from './services/Route-Guards/deactivate.guard.service';
import { TitleTooltip } from './tooltip-generator/title-tooltip';
import { RouteLoaderService } from './services/route-loader.service';
import { UserMachineConfigurationService } from '../retail/common/services/user-machine-configuration.service';
import { APIThrottleService } from './services/api.throttler/api.throttler.service';
import { APIThrottleInterceptor } from './services/api.throttler/api.throttler.interceptor';
import { CanDeactivateGuardService } from './services/can-component-deactivate.service';
import { RouteGuardService } from './services/route-gaurd.service';
import { CacheInterceptor } from '../common/services/cache/cache-interceptor';
import { HttpCacheService } from '../common/services/cache/http-cache.service';
import { ErrorInterceptor } from '@coreModels/interceptors/error.interceptor';


@NgModule({
    imports: [
        CommonModule,
        CoreRoutingModule,
        LayoutModule,
        SharedModule,
        HttpClientModule,
        DynamicFormBuilderModule,
        PlatformModule,
        //CommonSharedModule
    ],
    providers: [
        AuthGuardService,
        DeactivateGuard,
        APIThrottleService,
        TitleTooltip,
        CanDeactivateGuardService,
        RouteGuardService,
        HttpCacheService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: APIThrottleInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheInterceptor,
            multi: true,
        },  
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: DateAdapter, useClass: CustomDateAdapter, deps: [GolfLocalization, Platform]
        },
        RouteLoaderService,
        UserMachineConfigurationService
    ],
    exports: [DynamicFormBuilderModule]
})
export class CoreModule {
}

