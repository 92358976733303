import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GolfRoutes } from 'src/app/core/extensions/golf-route';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { PlayerProfileAPI } from 'src/app/shared/create-player/create-player.model';
import { ContactInformation } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { API } from 'src/app/tee-time/tournaments/tournaments-modal/contact/contact.model';
import { Tournament } from 'src/app/tee-time/tournaments/tournaments.model';
import { GolfGatewayCommunication } from '../../communication/services/golfGateway';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';
import { PaymentServiceCommunication } from '../../communication/services/payment-communication-services';
import { ContactDetails } from '../../shared-models';


@Injectable({ providedIn: 'root' })
export class PlayerDataService {
    constructor(private _golfScheduleCommunication: GolfScheduleCommunication
        , private _golfGatewayCommunication: GolfGatewayCommunication, private _paymentHttp: PaymentServiceCommunication,private _localization : GolfLocalization) { }
    async GetPlayers(): Promise<API.Player[]> {
        return this._golfScheduleCommunication.getPromise({
            route: GolfRoutes.GetAllPlayers
        });
    }
    async GetPlayerById(Id: number): Promise<API.Player> {
        return this._golfGatewayCommunication.getPromise({
            route: GolfRoutes.GetPlayerById,
            uriParams: { id: Id }
        });
    }
    async GetPlayerContactInfoByPlayerIdList(lstPlayerId: number[]): Promise<ContactDetails[]> {
        let result: ContactInformation[] = await this._golfScheduleCommunication.postPromise<ContactInformation[]>({
            route: GolfRoutes.GetPlayerContactInfoByPlayerIdList,
            body: lstPlayerId
        });
        return result && result != null && result?.length > 0 ? this.MapToContactDetails(result) : null;
    }    
    MapToContactDetails(lstContactInfo) {
        var lstContactDetail: ContactDetails[] = [];
        lstContactInfo.map(x => {
            lstContactDetail.push({
                id: x.id,
                type: x.type,
                value: x.value,
                name: x.name,
                extension: x.extension,
                description: x.description,
                isPrimary: x.isPrimary,
                isPrivateInfo: x.isPrivateInfo
            })
        })
        return lstContactDetail;
    }
    async GetPlayerByPlayerLinkId(Id: string, IsTeeTimeBook?: boolean): Promise<API.Player> {
        return this._golfGatewayCommunication.getPromise({
            route: GolfRoutes.GetPlayerByPlayerLinkId,
            uriParams: { id: Id, isTeeTimeBook :IsTeeTimeBook }
        });
    }


    async ValidateOutletReference(outletId: number) {
        return this._golfGatewayCommunication.getPromise({
            route: GolfRoutes.ValidateOutletReference,
            uriParams: { id: outletId }
        });
    }

    async GetPlayerByGuestId(guestId: string): Promise<Tournament.UI.ContactInformation> {
        let result: Tournament.UI.ContactInformation = await this._golfScheduleCommunication.getPromise<Tournament.UI.ContactInformation>({
          route: GolfRoutes.GetPlayerByGuestId,
          uriParams: { guestId }
        });
        return result;
      } 
      
    async CreatePlayer(requestBody: API.Player): Promise<number> {
        return this._golfScheduleCommunication.postPromise({
            route: GolfRoutes.CreatePlayer,
            body: requestBody
        });
    }
    async UpdatePlayer(requestBody: API.Player): Promise<API.Player> {
        return this._golfScheduleCommunication.putPromise({
            route: GolfRoutes.UpdatePlayer,
            body: requestBody
        });
    }
    async DeletePlayer(Id: number): Promise<boolean> {
        return this._golfScheduleCommunication.deletePromise({
            route: GolfRoutes.DeletePlayer,
            uriParams: { id: Id }
        });
    }

    public async updateTournamentContactInfo(playerId: number, tournamentId: number): Promise<void> {
        this._golfScheduleCommunication.putPromise<void>(
            {
                route: GolfApiRoute.UpdateTournamentContactInfo,
                uriParams: { playerId: playerId, tournamentId: tournamentId }
            });
    }

    public async getExistingGuestsInfo(searchKey: string, searchCriteria: number, isExternalGuestSearch: boolean, isTeeTimeBook: boolean, isPlatformGuestSearch = false, notifier: Subject<void> = new Subject()): Promise<Tournament.UI.ContactInformation[]> {
        return this._golfScheduleCommunication.getPromiseCancellable({
            route: GolfRoutes.SearchExistingGuests,
            uriParams: { key: encodeURIComponent(searchKey), searchCriteria: searchCriteria, isTeeTimeBook: isTeeTimeBook, isExternalGuestSearch: isExternalGuestSearch, isPlatformGuestSearch: isPlatformGuestSearch }
        }, true, notifier);
    }

    public async getGuestsInfo(searchKey: string, searchType: number, isExternalGuestSearch: boolean = false, isPlatformGuestSearch = false, notifier: Subject<void> = new Subject()): Promise<Tournament.UI.ContactInformation[]> {
        return this._golfScheduleCommunication.getPromiseCancellable({
            route: GolfRoutes.SearchGuests,
            uriParams: { key: encodeURIComponent(searchKey), searchType: searchType, isExternalGuestSearch: isExternalGuestSearch, isPlatformGuestSearch: isPlatformGuestSearch }
        }, true, notifier);
    }

    public async getInHouseGuestsInfo(searchKey: string, PMSStayDate: string, searchFilter: number, notifier: Subject<void>): Promise<API.InHousePlayerSearchResponse[]> {
        return this._golfScheduleCommunication.getPromiseCancellable({
            route: GolfRoutes.SearchInHouseGuests,
            uriParams: { key: encodeURIComponent(searchKey), PMSStayDate: PMSStayDate, searchFilter }
        }, true, notifier);
    }

    public async getMemberInfo(cardNo: string, scheduleDateTime: string, corpId? : number): Promise<Tournament.UI.ContactInformation> {
        return this._paymentHttp.getPromise({
            route: GolfRoutes.GetMemberInfoByCardNo,
            uriParams: { cardNo: cardNo, scheduleDateTime: scheduleDateTime, corpId : corpId }
        });
    }

    public async GetExternalGuestProfile(externalGuestId: string,createGuestProfile: boolean = false): Promise<PlayerProfileAPI.PlayerProfile> {
        return this._golfScheduleCommunication.postPromise({
            route: GolfRoutes.GetExternalGuestProfile,
            uriParams: { guestId : externalGuestId ,createGuestProfile : createGuestProfile }
        });
    }
    public async searchPlayer(searchKey: string): Promise<API.Player[]> {
        return this._golfScheduleCommunication.getPromise({
            route: GolfRoutes.SearchPlayer,
            uriParams: { pattern: searchKey }
        });
    }
    public async getInStayGuestsInfo(searchKey: string, PMSStayDate: string, searchFilter: number, notifier: Subject<void>): Promise<API.InHousePlayerSearchResponse[]> {
        let guestRequest : any = {
            key: searchKey,
            PMSStayDate: PMSStayDate,
            searchFilter: searchFilter
        };
        return this._golfScheduleCommunication.postPromise({
            route: GolfRoutes.SearchInStayGuests,
            //uriParams: { key: encodeURIComponent(searchKey), PMSStayDate: PMSStayDate, searchFilter }
            body: guestRequest
        }, true, notifier);
    }
    public fetchGolfSearchJson():any{
        const golfSearchUrl = './assets/json/GolfSearchJson.json';
        return this._localization.jsonReader.getJSON(golfSearchUrl);
      }
}