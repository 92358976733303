<section id="notification-schedule-wrpper" class="ag_container--padding-md" [formGroup]="notificationScheduleForm">
    <h4 class="ag_group--headers">{{captions.pg_title_notificationSchedule}}</h4>
    <mat-radio-group class='radio-group ag_form-control--2' formControlName="event">
        <mat-radio-button class='radio-group--button ag_mb--4' [value]='opt.id'
            *ngFor="let opt of eventOptions" (change)="eventChangingOption($event)">
            {{opt.viewValue}}
        </mat-radio-button>
    </mat-radio-group>
    <ng-container>
        <div class="ag_w--100 mb-3">
            <mat-button-toggle-group class="ag_mt--3" formControlName="scheduleType" [value]="selectedScheduleType"
            (change)="onToggleChange($event, true)">
                <mat-button-toggle [disabled]="data.disabled" *ngFor="let data of scheduleOptions"
                [ngClass]="[data.disabled ? '' :'body-bordercolor', data.isSelected? 'bg-color': '']"
                value={{data.value}}>{{data.viewValue}}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div *ngIf="isDaily" class="flexClass">
            <!-- <app-ag-incremental class="days" [inputs]="daysInput"></app-ag-incremental> -->
            <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--33">
                <input matInput autocomplete="off" [type]="'number'"
                min="1" inputtype="nonnegative" max="30" maxLength="2"
                    formControlName="days" [placeholder]="captions.lbl_everyDays" numMaxlength required>
            </mat-form-field>
        </div>
        <div *ngIf="isWeekly" class="flexClass">
            <!-- <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--33">
                <input matInput type="number" maxLength="2" min="1" max="30"
                    formControlName="recurEveryWeek" [placeholder]="captions.lbl_everyWeeksOn" numMaxlength>
            </mat-form-field> -->
            <!-- <app-ag-incremental class="days" [inputs]="recurEveryWeeksOnInput"></app-ag-incremental> -->
            <ng-container formArrayName="specificDays">
                <span class='ag_display--flex ag_form-control--sm ag_pb--2'>
                    <mat-checkbox class="ag_mr--4" [checked]='isAllSelected' (change)='onAllCheck($event)'>
                        {{captions.lbl_all}}
                    </mat-checkbox>
                    <mat-checkbox *ngFor='let day of days; let i= index' class="example-margin ag_mr--4"
                        [formControlName]="i" (change)='updateAll($event)'>
                        {{day.short}}
                    </mat-checkbox>
                </span>
            </ng-container>
        </div>
        <div *ngIf="isMonthly" class="flexClass">
            <mat-radio-group class='days' formControlName="dayMonth">
                <mat-radio-button class='ag_display--inblock  ag_form--radio-button ag_mb--2' [value]='opt.id'
                    *ngFor="let opt of monthOptions" (change)="monthChangingOption($event)">
                    {{opt.viewValue}}
                </mat-radio-button>
            </mat-radio-group>
            <mat-form-field *ngIf="isMonthFirstDay" [floatLabel]="floatLabel" class="ag_form-control--33">
                <input matInput type="number" maxLength="2" min="1" max="30"
                    formControlName="monthDays" [placeholder]="captions.tbl_hdr_days" numMaxlength>
            </mat-form-field>
            <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--33">
                <input matInput type="number" maxLength="2" min="1" max="30"
                    formControlName="ofEveryMonth" [placeholder]="captions.lbl_everyMonths" numMaxlength>
            </mat-form-field>
            <!-- <app-ag-incremental *ngIf="isMonthFirstDay" [inputs]="monthDayInput"></app-ag-incremental> -->
            <!-- <app-ag-incremental [inputs]="ofEveryMonthInput"></app-ag-incremental> -->
        </div>
        <div *ngIf="isYearly" class="flexClass">
            <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--33">
                <input matInput type="number" maxLength="2" min="1" max="30"
                    formControlName="ofEveryYear" [placeholder]="captions.lbl_everyYearOf" numMaxlength>
            </mat-form-field>
            <mat-form-field [floatLabel]="floatLabel" class="ag_form-control--33">
                <input matInput type="number" maxLength="2" min="1" max="30"
                    formControlName="yearlyMonths" [placeholder]="captions.lbl_everyMonthOf" numMaxlength>
            </mat-form-field>
            <!-- <app-ag-incremental class="days" [inputs]="ofEveryYearlyInput"></app-ag-incremental>
            <app-ag-incremental [inputs]="yearlyMonthsInput"></app-ag-incremental> -->
            <mat-radio-group class='' formControlName="yearlyDays">
                <mat-radio-button class='ag_display--inblock  ag_form--radio-button ag_mb--2' [value]='opt.id'
                    *ngFor="let opt of monthOptions" (change)="monthChangingOptionYear($event)">
                    {{opt.viewValue}}
                </mat-radio-button>
            </mat-radio-group>
            <app-ag-incremental *ngIf="isMonthFirstForYear" [inputs]="yearlyDaysInput"></app-ag-incremental>
        </div>
        <div>
            <app-ag-date-picker [inputs]="startDateInput" (datePickerChange)="startDateChange($event)">
            </app-ag-date-picker>
            <app-ag-time-picker class ='ag-ml-4' [inputs]="StartTimeInput"> </app-ag-time-picker>
            <label class="ag_form--label">{{captions.lbl_end}}</label>
            <mat-radio-group class='ag_display--block' formControlName="end" (change)='onEndChange($event)'>
                <mat-radio-button class='ag_display--inblock  ag_form--radio-button ag_mb--2' [value]='opt.id'
                    *ngFor="let opt of endOptions">
                    {{opt.viewValue}}
                </mat-radio-button>
            </mat-radio-group>
            <app-ag-date-picker *ngIf='isEndDate' [inputs]="endDateInput" (datePickerChange)="endDateChange($event)">
            </app-ag-date-picker>
            <!-- <ag-input *ngIf='isOccurance' class='ag_display--inblock ag_form--control--33'
                formControlName='noOfOccurance' [placeholder]='captions.lbl_noOfOccurance' type='number' required>
            </ag-input> -->
        </div>
    </ng-container>

</section>