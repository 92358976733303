export interface SelectionButton {
    id: number;
    value: any;
}

export interface TimeInterval {
    startTime: string;
    endTime: string;
}


export interface Itinerary {
  id: string;
  name: string;
  status: string;
  start: string;
  end: string;
  packageName: string;
  orgStartDateTime: Date;
  orgEndDateTime: Date;
  activityLocation : string;
}

export enum ActivityStatus  {
  Reserved = 'Reserved',
  CheckedIn = 'CheckedIn',
  CheckedOut = 'CheckedOut',
  NoShow = 'NoShow',
  Cancelled = 'Cancelled',
  Unknown = 'Unknown'
}

export interface ActivityResponse {
  id: string;
  description: string;
  status: string;
  startDateTime;
  endDateTime;
  displayStartTime: string;
  displayEndTime: string;
  packageName: string;
  platformActivityStatus:string;
  activityLocation : string;
}