import { Pipe, PipeTransform } from '@angular/core';
import { GolfLocalization } from './golf-localization';

@Pipe({
    name: 'localizeTime'
})
export class LocalizeTimePipe implements PipeTransform {

    constructor(private localization: GolfLocalization) {

    }
    transform(value: any, includeAmPm: boolean = true,isCapital=true): any {
        let time: string = value ? this.localization.LocalizeTime(value,isCapital) : '';
        if (!includeAmPm) {
          time = time.replace(/[^\d:]/g, '');
        }

        return time;
    }

}
