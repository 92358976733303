<section class="golf-container--padding" id="notifyPlayers">
    <div [formGroup]="notifyplayers">
        <div>
            <label class="form-label-normal unset-noWrap">{{captions.emailsmsNotification}}</label>            
            <div class="d-flex align">
                <label class="form-label-singleline mr-2">{{captions.email}}</label>
                <ui-switch class="mr-5" [checkedLabel]="captions.yes" [uncheckedLabel]="captions.no"
                    formControlName="enableSendEmail" (change)="emailsmstoggle($event,'enableSendEmail','enableSemdSMS')">
                </ui-switch>
                <label class="form-label-singleline mr-2">{{captions.sms}}</label>
                <ui-switch class="mr-5" [checkedLabel]="captions.yes" [uncheckedLabel]="captions.no"
                    formControlName="enableSemdSMS" (change)="emailsmstoggle($event,'enableSemdSMS','enableSendEmail')">
                </ui-switch>
                <div *ngIf="isEformsEnabled">
                    <label class="form-label-singleline mr-2">{{captions.eForm}}</label>
                    <ui-switch
                        [disabled]="!notifyplayers.controls['enableSemdSMS'].value&&!notifyplayers.controls['enableSendEmail'].value"
                        [checkedLabel]="captions.yes" [uncheckedLabel]="captions.no"
                        (change)="onEformToggleChange($event)">
                    </ui-switch>
                </div>            
            </div>          
        </div>
        <div *ngIf="shownotifySection">
            <div formArrayName="playerEmailList">
                <div *ngFor="let PD of notifyplayers.get('playerEmailList')['controls'];let i=index">
                    <div [formGroupName]="i">
                        <div class="d-inline-block w-33">
                            <mat-checkbox formControlName="ischecked" class="example-margin"
                                [checked]="PD.value.ischecked" (change)="checkclicked($event.checked,i)">
                                {{PD.value.name}}
                            </mat-checkbox>
                        </div>
                        <div class="d-inline-block w-60">
                            <mat-form-field [floatLabel]="floatLabel" class="w-30 mr-3">
                                <input matInput golfinputtype="email" [placeholder]="captions.emailAdd"
                                    formControlName="emailId" maxlength="75">
                                <mat-error class=""
                                    *ngIf="notifyplayers.controls['playerEmailList'].controls[i].controls['emailId'].touched  && (notifyplayers.controls['playerEmailList'].controls[i].controls['emailId'].value!='') && !notifyplayers.controls['playerEmailList'].controls[i].controls['emailId'].valid">
                                    {{captions.invalid}} {{captions.emailAdd}}
                                </mat-error>
                                <mat-error class=""
                                    *ngIf="notifyplayers.controls['playerEmailList'].controls[i].controls['emailId'].touched && (notifyplayers.controls['playerEmailList'].controls[i].controls['emailId'].value=='')">
                                    {{captions.missing}} {{captions.emailAdd}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field [floatLabel]="floatLabel" class="w-20 mr-2">
                                <span *ngIf="CountryCodeRef.value" class="position-absolute">+</span>
                                <input matInput name="countryCode"
                                    [ngClass]="{'countryCodeInput': CountryCodeRef.value}" formControlName="countryCode"
                                    [placeholder]="captions.countryCode" #CountryCodeRef [maxlength]="countryCodeLength"
                                    golfinputtype='nonnegative'>
                                <mat-error class=""
                                    *ngIf="notifyplayers.controls['playerEmailList'].controls[i].controls['countryCode'].touched && !notifyplayers.controls['playerEmailList'].controls[i].controls['countryCode'].valid">
                                    {{captions.missing}} {{captions.countryCode}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field [floatLabel]="floatLabel" class="w-40">
                                <input matInput [placeholder]="captions.phoneNo" formControlName="phoneNo"
                                    [textmask]="textMaskPhone">
                                <mat-error class=""
                                    *ngIf="notifyplayers.controls['playerEmailList'].controls[i].controls['phoneNo'].touched && !notifyplayers.controls['playerEmailList'].controls[i].controls['phoneNo'].valid">
                                    {{captions.missing}} {{captions.phoneNo}}
                                </mat-error>
                                <mat-error
                                    *ngIf="notifyplayers.controls['playerEmailList'].controls[i].controls['emailId'].hasError('duplicate')">
                                    {{duplicateError}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                </div>
            </div>
            <div>
                <label class="form-label-normal unset-noWrap">{{captions.saveupdateEmailsms}}</label>
                <ui-switch [checkedLabel]="captions.yes" [uncheckedLabel]="captions.no"
                    formControlName="enableSaveEmail">
                </ui-switch>
            </div>
        </div>

    </div>
</section>