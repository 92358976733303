import { Injectable } from '@angular/core';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { SystemdefaultsInformationService } from '../core/services/systemdefaults-information.service';
import { PropertyFeaturesConfigurationService } from '../retail/sytem-config/payment-features-config/property-feature-config.service';
import { SettingModule, SettingScreen } from './global.constant';
import { TeeTimeCustomFeeUtilities } from '../tee-time-actions/tee-time-custom-fee.utilities';

@Injectable({providedIn: 'root'})

export class RefurbishSessionStorage
{
    changedPropertyId: string = '';
    constructor( 
        private _golfLocalization:GolfLocalization,
        private _systemdefaultsInformationService:SystemdefaultsInformationService,
        private _propertyFeaturesConfigurationService: PropertyFeaturesConfigurationService,
        private _teeTimeCustomFeeUtilities: TeeTimeCustomFeeUtilities
        )
    {
        this.changedPropertyId = this._golfLocalization.GetPropertyInfo('PropertyId');

    }

    async InitializeSessionStorage()
    {
      await this._systemdefaultsInformationService.syncDefaultSettings();
      await this._propertyFeaturesConfigurationService.SetQuickIdConfigSettingForGolf(SettingModule.Utilities,SettingScreen.QuickIdConfigSetting);
      await this._teeTimeCustomFeeUtilities.IsCustomFeeConfigured();
    }

}