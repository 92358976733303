export enum playerTypes {
    newPlayer = 1,
    hotelReservation = 2,
    member = 3,
    player = 4,
    guest = 5
}

export interface ContactType {
    id?: number;
    description?: string;
    type?: string;
}

export interface PlayerInfoCaptions {
    edit: string;
    clear: string;
    cancel: string;
    delete: string;
    bulkAdd: string;
    bulkNoOfPlayers: string;
    importPlayers: string;
    cart: string;
    group: string;
    selectDate: string;
    selectTime: string;
    playerType: string;
    rateType: string;
    firstName: string;
    lastName: string;
    pronounced: string;
    startingHole: string;
    teamNumber: string;
    greenFee: string;
    cartFee: string;
    phoneType: string;
    mailType: string;
    device: string;
    customField1: string;
    customField2: string;
    customField3: string;
    customField4: string;
    customField5: string;

    playerInfoHeader: string;
    playerListHeader: string;
    paymentInfoHeader: string;

    addPlayer: string;
    updatePlayer: string;
    reset: string;
    connect: string;
    savedCardDetail: string;
    toggleYes: string;
    toggleNo: string;
    selectDevice: string;
    searchByConfNoAndGuestName: string;
    searchByPlayerName: string;
    downloadSampleCSV:string;
    downloadSampleCSVFileName:string;
}
// Model can be changed by the time of API integration


export interface TournamentPlayerErrorCaptions {
    missingFirstName: string;
    missingLastName: string;
    missingNoOfPlayersInBulkAdd:string;
    missingRateType: string;
    missingPlayerType: string;
    OverrideRatePlayerType: string;
    playerLimitExceeds: string;
    signupDateClosed: string;
    groupNumberExceeds: string;
    cartNumberExceeds: string;
    startingHoleWrong: string;
    teamNumberWrong: string;
    firstNameLengthExceeds: string;
    lastNameLengthExceeds: string;
    pronouncedNameLengthExceeds: string;
    startDateMissing: string;
    playerInfoMissing: string;
    signupDateNotStarted: string;
    tournamentNotFound: string;
    nameContainsSpecialChar: string;
    memberLinkedPlayertypeUnavailable: string;
    allocationCodePlayerLinkNotAvailable:string;
}

export interface PhoneType {
    id: number;
    type: string;
    description: string;
}

export interface MailType {
    id: number;
    type: string;
    description: string;
}

export interface PlayerCategory {
    id: number;
    name: string;
    description: string;
}
export interface HoleInfo {
    id: number;
    name: string;
    description: string;
}

export interface AllocationCode {
    id: number;
    name: string;
    description: string;
}
