import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { StepperService } from 'src/app/shared/components/stepper/stepper.service';
import { DatePickerInputs } from './print-itenary.model';
import { ButtonAction } from 'src/app/shared/global.constant';
import { DialogCloseObj } from 'src/app/shared/shared-models';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { GolfGuestItineraryBuissiness } from 'src/app/reports/business/golfReports/teetime/GolfGuestItinerary.bussiness.service';
import { AlertType, ButtonType } from 'src/app/shared/shared-models';
import { HttpMethod,  HttpServiceCall } from 'src/app/common/shared/shared/service/http-call.service';
import { Host } from 'src/app/common/shared/shared/globalsContant';
import { ReportParams } from 'src/app/retail/retail.modals';

@Component({
  selector: 'app-print-itenary',
  templateUrl: './print-itenary.component.html',
  styleUrls: ['./print-itenary.component.scss']
})
export class PrintItenaryComponent implements OnInit {

  @Output() notifyParent = new EventEmitter();

  printIternaryForm: UntypedFormGroup;
  date: Date | string;
  dateInputs: DatePickerInputs;
  minDate: Date;
  maxDate: Date;
  PrintFormSubscribe: Subscription;
  saveSubscriber: Subscription;
  formData: UntypedFormGroup;
  templateData:any;
  selectedDate: Date | string;
  selectedData: any;
  propertyDate: Date | String;

  constructor(private fb: UntypedFormBuilder, private _localization: GolfLocalization,@Inject(MAT_DIALOG_DATA) public dialogData: any,public _StepperService: StepperService,
  private _PropertyInformation: GolfPropertyInformation, private _GolfGuestItineraryBuissiness:GolfGuestItineraryBuissiness,private _utilities:GolfUtilities,private http: HttpServiceCall) { }

  ngOnInit(): void {
    this.date = this._localization.getDate(this.dialogData.info.time);
    this.minDate = this._PropertyInformation.CurrentDTTM;
    this.propertyDate = this._PropertyInformation.CurrentDate;
    this.createItineryForm();
    this.dateInputs = {
      className: 'golf-form-control--sm',
      form: this.printIternaryForm,
      formControlName: 'dateControl',
      minDate: this.date,
      maxDate: this.maxDate,
      placeHolder: this._localization.captions.teetime.ItenaryDate,
      value: this._PropertyInformation.CurrentDTTM
    };
    this.formGenertor();
    this.selectedDate = this.date;         
    this.PrintFormSubscribe = this.printIternaryForm.valueChanges.subscribe((x) => {
        this.formData = x.dateControl;
        this.notifyParent.emit(this.printIternaryForm.valid && this.printIternaryForm.dirty);
    });
    this._StepperService.enableSave = true;
    this.saveSubscriber = this._StepperService.valueChange.subscribe((x: DialogCloseObj) => {
        if (x.type == ButtonAction.print) {
          this.submitForm(x);
        }
    });
  }

  formGenertor() {
		this.printIternaryForm = this.fb.group({
			print: ''
		});
	}

  createItineryForm() {
    this.printIternaryForm = this.fb.group({
      dateControl: this.date,
      courseControl: ''
    });
  }

  dateChanged(e){
    this.selectedDate = e[0].value.dateControl;

  }

  submitForm(x: DialogCloseObj) {
    let  propDate: any;
    propDate = this.propertyDate,
    this.selectedData ={
      'FromDate':this._localization.convertDateObjToAPIdate(this.dialogData.info.time),
      'EndDate': this._localization.convertDateObjToAPIdate(this.selectedDate),
      'playerId': this.dialogData.info.playerDetail[0].playerId,
      'FirstName': this.dialogData.info.playerDetail[0].firstName,
      'LastName': this.dialogData.info.playerDetail[0].lapstName,
      'FullName': this._utilities.formatGuestName(this.dialogData.info.playerDetail[0].firstName, this.dialogData.info.playerDetail[0].lastName)
    }
    console.log(this.selectedData);
    this.printGuestItinerary(() => {
      x.dialogRef.close();
    });
	}

  ngOnDestroy(): void {
		this.PrintFormSubscribe.unsubscribe();
		this.saveSubscriber.unsubscribe();
	}

  printGuestItinerary(callBack) {	
    this.getTemplateDetails('guest').then(result=>{
      this.templateData=result;
      this._GolfGuestItineraryBuissiness.printGuestItinerary(this.selectedData.FromDate,this.selectedData.EndDate,this.selectedData.playerId,this.selectedData.FullName,this.templateData,this.selectedData.PlayerLinkId, () => {
        this._utilities.showAlert(this._localization.captions.reports.successfullyPrinted, AlertType.WellDone, ButtonType.Ok, callBack);
      });
    });
		
    
	}

  async getTemplateDetails(eventType) { 
		let templateDetail = await this.http.CallApiAsync<any>({
            host: Host.golfSchedule,
            callDesc: "GetDefaultTemplateInfo",
            method: HttpMethod.Put,
            showError: true,
            uriParams: { type: eventType },
            body: this.formReportTags()
        });
		return templateDetail.result;
	}
  
  private formReportTags():ReportParams {
		return{
		"{{{PropertyName}}}":this._localization.GetPropertyInfo('PropertyName'),
		"{{{PropertyDate}}}":this._localization.ConvertDateToISODate(this._PropertyInformation.CurrentDTTM),
    "{{{GuestName}}}":this.selectedData.FullName
		};
	}

}
