import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GolfUtilities } from '../../utilities/golf-utilities';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpCallService } from '../common/http-call.service';
import { ServiceParams } from '../../models/http.model';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';

@Injectable({ providedIn: "root" })
/**
 * Communication layer for pay agent
 * HttpCalls can be overridden here
*/
export class PayAgentCommunication extends HttpCallService {

    constructor(private _localization: GolfLocalization,
        private utils: GolfUtilities,
        private httpclient: HttpClient,
        private _sanitizer: DomSanitizer,
        PropertyInfo: GolfPropertyInformation
    ) {        
        super("", httpclient, _localization, utils, PropertyInfo);
    }


    public async putPromise<T>(params: ServiceParams, handleErr: boolean = true) {
        let response$: Promise<T> = super.putPromise<T>(params);

        //on error =>
        response$.catch(err => this.error(err, handleErr));

        //on success =>
        return await response$;        
    }

    public async postPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        let response$: Promise<T> = super.postPromise<T>(params);

        //on error =>
        response$.catch(err => this.error(err, handleErr));

        //on success =>
        return await response$;       
    }

    private error(err: HttpErrorResponse, handleErr: boolean) {
        if (handleErr) {
            super.errorHandler(err);
        }
        else {
            throw err;
        }
    }

}
