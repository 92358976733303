import { PlayerStayDetail, TeeTimeModel } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { Deposit } from '../payment-model';
import { TeeTimeFormat } from './teesheet.form.models';
import { PlayerPaymentstatus } from 'src/app/tee-time/search/search-model';
import { PlayerTeeTimeSlot } from 'src/app/tee-time-actions/move-player-modal/move-player-modal.model';
import { PlayerCategory} from 'src/app/common/enums/shared-enums';
// Grid Layout
export interface TeeSheet {
  slots: TeeSheetSlotAPI[];
  date?: string;
  courseId?: number;
}

export interface TeeSheetSlotAPI {

  scheduleDateTime: string;
  holeNumber: string;
  originalHoleNumber: string;
  scheduleType: number;
  allocationBlockId: number;
  allocationBlockCode: string;
  allocationBlockColorCode: string;
  alloctionBlockName: string;
  displayOnTeeSheet: boolean;
  closed: boolean;
  viewedByStarter: boolean;
  teeGridSlotId: number;
  allocationDateTime: Date;
}

export interface TeeTimeDetail {
  id: number;
  courseId: number;
  scheduleDateTime: string;
  holeNumber: string;
  scheduleStatus: number;
  playerId: number;
  tournamentId: number;
  comment: string;
  deposit: number;
  playerDetail: TeeTimePlayerDetail[];
  createdDateTime?: string;
  userName?: string;
  originalHoleNumber: string;
  viewedByStarter: boolean;
  eformTotalCount?: number;
  eformSubmittedCount?: number;
  eformCount?: number;
  eformSubmitted?: boolean;
}

export interface TeeTimePlayerDetail {
  teeTimePlayerId: number;
  playerPosition: number;
  firstName: string;
  lastName: string;
  gender: string;
  amountPaid: number;
  memberNumber: string;
  playerComment: string;
  tournamentId?: number;
  deposits: Deposit[];
  transactionId: number;
  playerStatus: PlayerPaymentstatus;
  groupId: number;
  bookingId: string;
  playersPerGroup: number;
  gameFormat: TeeTimeFormat;
  rateType: number;
  holes: number;
  imageReferenceId: string;
  bookingSource?: number;
  allocationBlockId: number;
  confirmationNumber: string;
  cartId: any;
  caddy?: any;
  caddyType?: any;
  caddyId?: any;
  caddyTypeId?: any;
  isFlagDay: boolean;
  isBlocked: boolean;
  ticketNumber?: string;
  scheduledTeeTimePlayerFee?: ScheduledTeeTimePlayerFee;
  entryFee?: number;
  cartType?: any;
  playerStayDetail?: PlayerStayDetail;
  multiPackTransactionDetailId: number;
  multiPackGreenFeeValue: number;
  multiPackCartFeeValue: number;
  isMarkAsPaid?: boolean;
  patronId?: string;
  roomNumber?: string;
  arrivalDate?: string;
  departureDate?: string;
  packageCode?: string;
  customField1?: string;
  customField2?: string;
  customField3?: string;
  customField4?: string;
  customField5?: string;
  eformTotalCount?: number;
  eformSubmittedCount?: number;
  eformCount?: number;
  eformSubmitted?: boolean;
  vip?: string;
  playerCategoryId? : PlayerCategory;
  playerLinkId?: string;
  isTrail?: boolean;
  isWalk?: boolean;
  daysInAdvanceCount?: number;
  scheduledTeeTimePlayerId: number;
  isRetailItemsAvailable?: boolean;
  isRentalItemsAvailable?: boolean;
  tokenTransId?:number;
}
export interface ScheduledTeeTimePlayerFee {
  id: number;
  scheduledTeeTimePlayerId: number;
  allocationBlockId: number;
  playerTypeId: number;
  rateTypeId: number;
  greenFee: number;
  isGreenFeeNegotiable: boolean;
  cartFee: number;
  isCartFeeNegotiable: boolean;
  viewedByStarter: boolean;
  isExternal: boolean;
}
export interface ReinstateResult {
  playerTeeTimeSlot: PlayerTeeTimeSlot;
  ruleResults: RuleResult[];
  result: boolean;
  isDestinationSlotRateChanged: boolean;
  isCaddyAvailableForAllPlayers: boolean;
}


export interface RuleResult {
  ruleName: string;
  isValid: boolean;
  errorCodes: number[];
  data: { [key: string]: any };
}

export const enum RulePriority {
  Low,
  Medium,
  High,
}


export interface ValidationResult<T> {
  result: T;
}

export enum TeeTimeAction {
  copy,
  move,
  bulkMove,
  reinstate,
  bulkCopy,
  copyToMany,
  checkIn=15,
  checkOut = 16,
  checkInCheckOut=17,
  undoCheckIn=18,
  undoCheckOut=19,
  none=20
}

export class RuleNames {
  ALLOCATION_CODE_DAYS_OUT_RULE = "AllocationCodeDaysOutRule";
  TEETIME_SLOT_AVAILABLE_RULE = "TeeTimeSlotAvailableRule";
  PLAYER_TYPE_DAYS_OUT_RULE = "PlayerTypeDaysOutRule";
  RATE_TYPE_REQUIRED_RULE = "RateTypeRequiredRule";
  PLAYER_TYPE_REQUIRED_RULE = "PlayerTypeRequiredRule";
  PLAYERTYPE_ALLOCATION_CODE_LINK_RULE = "PlayerTypeAllocationCodeLinkRule";
  GUARANTEE_RULE = "GuaranteeRule";
  COURSE_FEE_RULE = "CourseFeeRule";
  TEETIME_PLAYER_COUNT_RULE = "TeeTimePlayerCountRule";
}

export interface BulkBookTeeTimeResult {
  result: boolean;
  bookTeeTimesRuleResults: BookTeeTimesRuleResult[];
}

export interface GroupBookTeeTimeResult {
  result: boolean;
  bookTeeTimesRuleResults: BookTeeTimesRuleResult[];
}

export interface BookTeeTimesRuleResult {
  courseId: number;
  scheduleDateTime: Date | string;
  holeNumber: number;
  ruleResults: RuleResult[];
  teeTimeModel?: TeeTimeModel;
}

export interface CopyMoveResult {
  playerTeeTimeSlot: PlayerTeeTimeSlot;
  isDestinationSlotRateChanged: boolean;
  isDestinationSlotHasDifferentRateType: boolean;
  isCaddyAvailable: boolean;
  isTeeItemChanged: boolean;
  ruleResults: RuleResult[];
  result: boolean;
  bookingId:string;
  destinationSlotRate?: DestinationSlotRate;

}
export interface DestinationSlotRate{
  destinationRateTypeId: number;
  destinationGreenFee: number;
  destinationCartFee: number;
  scheduledTeeTimePlayerFeeId: number;
}
export interface GuaranteeRuleResponse {
  isSuccess: boolean;
  errorCode: number;
}
export interface GuaranteeRuleRequest {
  internalGroupId: string | null;
  playerTypeId: number;
  rateTypeId: number;
  paymentReferenceId: number;
  playerCategory: number;
}

export interface PlayersNewRate {
  playerId: number;
  playerTypeId: number;
  rateTypeId: number;
  greenFee: number;
  cartFee: number;
}

export interface PlayerRateDetails {
  courseId: number;
  date: string;
  playerId: number;
  firstName: string;
  lastName: string;
  playerTypeId: number;
  destinationSlotRates: DestinationSlotRate[];
}
export interface DestinationSlotRate {
  rateTypeId: number;
  rateTypeName: string;
  greenFee: number;
  cartFee: number;
  totalFee: number;
}
export interface PlayersCaddyInfo {
  playerId: number;
  isUpdateCaddy: boolean;
  caddyId?: number;
  caddyTypeId?: number;
  scheduledDateTime: string;
  playerSlotPosition: number;
}

export interface CaddyType {
  id: number,
  type: string,
  bagValue: number,
  isActive: boolean,
  listOrder: number
}

export interface GenderOption {
  id: number;
  name: string;
  value: string;
}

export interface AvailableCaddy {
  id: number;
  availableName: string;
  scheduleTime: string;
  caddyType: string;
  caddyNumber: number;
  caddyTypeId: number[];
  isAvailableFor18Holes: boolean;
  playerIds: number[];
}
export namespace API {
  export interface AvailableCaddy {
    courseId: number;
    scheduleId: number;
    caddyId: number;
    caddyName: string;
    caddyNumber: number;
    caddyTypeId: number[];
    caddyTypeName: string;
    scheduleStartTime: Date;
    scheduleEndTime: Date;
    isAvailableFor18Holes: boolean;
    playerIds: number[]
  }
}


