import { Component, OnInit, EventEmitter, Output, Inject, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StepperService } from '../../shared/components/stepper/stepper.service';
import { Subscription } from 'rxjs';
import { PrintModalBusiness } from './print-modal-bussiness';
import { PrintModalService } from './print-modal-service';
import { printModel , printArr } from './print-model';
import { DateInput, DialogCloseObj } from '../../shared/shared-models';
import { ReceiptBusinessService } from 'src/app/retail/shop/receipt/business/receipt-business.service';
import { ReceiptService } from 'src/app/retail/shop/receipt/business/receipt.service';
import { SPAConfig } from 'src/app/retail/common/config/SPA-config';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailTransactionService } from 'src/app/retail/shop/view-categories/retail-transactions/retail.transactions.service';
import { ApplyDiscountService } from 'src/app/retail/shop/apply-discount/appply-discount.service';
import { ShopBussinessService } from 'src/app/retail/shop/shop-business.service';
import { AssignCommissionService } from 'src/app/retail/shop/assign-commission-popup/assign-commission-popup.service';
import { CommonVariablesService } from 'src/app/retail/shared/service/common-variables.service';
import { SessionManagerService } from 'src/app/retail/common/services/session-manager.service';
import { BreakPointAccess } from 'src/app/retail/shared/service/breakpoint.service';
import { UserAlerts } from 'src/app/retail/common/config/alerts-config';
import { UserMachineConfigurationService } from 'src/app/retail/common/services/user-machine-configuration.service';
import { PayAgentService } from 'src/app/retail/shared/service/payagent.service';
import { ImageProcessorService } from 'src/app/retail/shared/service/image-processor-service';
import { MoreSectionServiceService } from 'src/app/retail/shared/more-section/more-section-service.service';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { RetailUtilities as RetailUtils } from 'src/app/retail/shared/utilities/retail-utilities';
import { AuthGuardService } from 'src/app/retail/common/services/auth-guard.service';
import { RetailServiceRegistry } from 'src/app/retail/shared/service/base.service';
import { HttpServiceCall } from 'src/app/retail/shared/service/http-call.service';
import { RetailSetupService } from 'src/app/retail/retail-setup/retail-setup.service';
import { RetailService } from 'src/app/retail/retail.service';
import { HttpRequestService } from 'src/app/retail/shared/service/http-request.service';
import { MessageService } from 'src/app/retail/shared/service/messageservice.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { FormatText } from 'src/app/retail/shared/pipes/formatText-pipe.pipe';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { PaymentBusinessService } from 'src/app/shared/data-services/payment/payment-business.service';
import { ButtonAction } from 'src/app/shared/global.constant';
import { PlayerPaymentstatus } from 'src/app/tee-time/search/search-model';
import { PaymentMethodBusiness } from 'src/app/retail/retail-payment-method/payment-methods.business';
import { DatamagineConfigBusiness } from 'src/app/common/data-magine/data-magine-config/data-magine-config.business';
import { dataMagineBusiness } from "src/app/common/data-magine/data-magine-integration/data-magine-integration-business";
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { PlayerCategory } from 'src/app/common/enums/shared-enums';
@Component({
	selector: 'app-print-modal',
	templateUrl: './print-modal.component.html',
	styleUrls: ['./print-modal.component.scss'],
	providers: [
		PrintModalBusiness,
		PrintModalService,
		RetailTransactionService,
		ApplyDiscountService,
		ShopBussinessService,
		ReceiptBusinessService,
		AssignCommissionService,
		CommonVariablesService,
		RetailLocalization,
		SessionManagerService,
		BreakPointAccess,
		UserAlerts,
		UserMachineConfigurationService,
		PayAgentService,
		ImageProcessorService,
		ReceiptService,
		MoreSectionServiceService,
		GolfUtilities,
		AuthGuardService,
		RetailServiceRegistry,
		SPAConfig,
		HttpServiceCall,
		RetailSetupService,
		RetailService,
		HttpRequestService,
		MessageService,
		NgxImageCompressService,
		FormatText,
		RetailPropertyInformation,
		RetailUtils,
		PaymentBusinessService,PaymentMethodBusiness,
		DatamagineConfigBusiness, dataMagineBusiness
	]
})
export class PrintModalComponent implements OnInit {
	saveSubscriber: Subscription;
	formData: string;
	displayLandscape: UntypedFormGroup;
	PrintFormSubscribe: Subscription;
	printForm: UntypedFormGroup;
	printArray: printModel[];
	courseId: number;
	course: string;
	scheduleTeeTimeId: number;
	dateTime: string;
	captions: any;
	transactionId: number;
	scheduledTeeTimeIds: number[] = [];
	playerIds: number[] = [];
	P: boolean;
	cartId: number;
	isPaid: boolean;
	isPrintLandscape: boolean=false;
	isCartCard: boolean=false;
	isBagTags:boolean = false;
	showPlayers:boolean = false;
	startDateInputs: DateInput;
	endDateInputs: DateInput;
	players = [];
	selectedPlayers = [];
	currentDate: Date;

	@Output() notifyParent = new EventEmitter();
	constructor(
		private _FormBuilder: UntypedFormBuilder,
		@Inject(MAT_DIALOG_DATA) public dialogData,
		private cdr: ChangeDetectorRef,
		public _StepperService: StepperService,
		private _PrintModalBusiness: PrintModalBusiness,
		private _localization: RetailLocalization,
		private _PropertyInformation: GolfPropertyInformation,
		private _utilitites: GolfUtilities
	) {
		this.captions = this._localization.captions.reports;
		this.courseId = dialogData.info.course.id;
		this.course = dialogData.info.course.course;
		this.scheduleTeeTimeId = dialogData.info.scheduledTeeTimeId;
		this.players = dialogData.info.playerDetail.filter(x => !x.isBlocked);
		this.players.forEach(x => x['isSelected'] = true);
		this.selectedPlayers = this.players;
		this.isPaid = this.players.some(p => (((p.playerStatus & PlayerPaymentstatus.paid) != 0)
			|| ((p.playerStatus & PlayerPaymentstatus.refund) != 0)) && p.transactionId > 0);		
		
		dialogData.info.playerDetail && dialogData.info.playerDetail.length == 0
				? []: dialogData.info.playerDetail.forEach(p=>{
					this.playerIds.push(p.playerId);
				});
		this.dateTime = dialogData.info.time;
		const playerTransactions = this.players.filter(p => (((p.playerStatus & PlayerPaymentstatus.paid) != 0)
			|| ((p.playerStatus & PlayerPaymentstatus.refund) != 0)) && p.transactionId > 0);
		this.transactionId = dialogData.info.playerDetail && playerTransactions.length > 0 ? playerTransactions[0].transactionId : 0;
		this.P = true;
		dialogData.info.playerDetail &&
			dialogData.info.playerDetail.forEach((x) => {
				if (x.cartId == '0') {
					return (this.cartId = -1);
				}
			});
		this.scheduledTeeTimeIds = dialogData.info.scheduledTeeTimeIds ? dialogData.info.scheduledTeeTimeIds : [];
	}

	ngOnInit() {
		if (sessionStorage.getItem("SelectedTeetimeStartDate")) {
			this.currentDate = this._utilitites.getDate(sessionStorage.getItem("SelectedTeetimeStartDate"));
		}
		else
		{
			this.currentDate = this._PropertyInformation.CurrentDTTM;
		}
        this.printArray = this._PrintModalBusiness.getprintOptions();
		if((JSON.parse(sessionStorage.propConfig)?.EnableJasperReports ?? 'false').toLocaleLowerCase() != 'true')
		{
			let index = this.printArray.findIndex((x) => x.id == printArr.bagTags);
            this.printArray.splice(index, 1);
		}
        if (!this.isPaid) {
            let index1 = this.printArray.findIndex((x) => x.id == printArr.teeTickets);
            this.printArray.splice(index1, 1);
            let index2 = this.printArray.findIndex((x) => x.id == printArr.receipt);
            this.printArray.splice(index2, 1);
			if (this.printArray.length >= 1) {
				this.printArray[0].checked = true;
				if(this.printArray[0].id == printArr.cartCards)
				{
					this.isCartCard = true;
				}
			}
        }
		let checkedPlayer = this.printArray?.find(x=> x.checked);
		this.showPlayers = checkedPlayer.id == printArr.bagTags; //add this to show players to select for tee tickets //|| checkedPlayer.id == printArr.teeTickets;
        this.formGenertor();
        this.notifyParent.emit(this.printArray.filter(x=>x.checked).length>0);         
        this.PrintFormSubscribe = this.printForm.valueChanges.subscribe((x) => {
            this.formData = x.print;
            this.notifyParent.emit(this.printForm.valid && this.printForm.dirty);
        });
        this.saveSubscriber = this._StepperService.valueChange.subscribe((x: DialogCloseObj) => {
            if (x.type == ButtonAction.print) {
                this.submitForm(x);
            }
        });
        this.formData = checkedPlayer.value;
    }

	ontoggleRange(e){
		this.isPrintLandscape = e;
		if(this.isPrintLandscape){
			this.displayLandscape = this.captions.PrintInLandscape;
		}		
	}

	onRadioChange(data){
		this.printForm.get('startDate').setValue(this.currentDate);
		this.printForm.get('endDate').setValue(this.currentDate);
		this.endDateInputs.minDate = this.printForm.controls.startDate.value;
		this.endDateInputs = { ...this.endDateInputs };
		if(data.id == printArr.cartCards){
			this.isCartCard = true;
			this.isBagTags = false;
		}
		else if(data.id == printArr.bagTags){
			this.isBagTags = true;
			this.isCartCard = false;
		}
		else{
			this.isCartCard = false;
			this.isBagTags = false;
		}
		this.showPlayers = data.id == printArr.bagTags;//add this to show players to select for tee tickets // || data.id == printArr.teeTickets;
	}
	formGenertor() {
		this.printForm = this._FormBuilder.group({
			print: '',
			printToggle : '',
			startDate:[this._localization.getCurrentDate(), Validators.required],
			endDate:[this._localization.getCurrentDate(), Validators.required]
		});
		this.startDateInputs = {
			className: 'golf-form-control--lg',
			form: this.printForm,
			formControlName: 'startDate',
			errorMessage: this._localization.captions.teetime.missing + this._localization.captions.teetime.fromDate,
			placeHolder: this._localization.captions.teetime.fromDate
		};
	
		this.endDateInputs = {
			className: 'golf-form-control--lg',
			form: this.printForm,
			formControlName: 'endDate',
			errorMessage: this._localization.captions.teetime.missing + this._localization.captions.teetime.toDate,
			minDate: this.printForm.controls.startDate.value,
			placeHolder: this._localization.captions.teetime.toDate
		};
	}

	dateChanged(arg) {
		switch (arg[1]) {
			case 'startDate': {
			  let startDate = this.printForm.controls.startDate.value;
			  let endDate = this.printForm.controls.endDate.value;
			  if(startDate.getTime() >= endDate.getTime())
			  {
				this.printForm.controls.endDate.setValue( arg[0].controls[arg[1]].value);  
			  }
			  this.endDateInputs.minDate = arg[0].controls[arg[1]].value;
			  this.endDateInputs = { ...this.endDateInputs };
			  this.printForm.controls.endDate.markAsTouched();
			  break;
			}
			case 'endDate': break;
		  }
	}

	submitForm(x: DialogCloseObj) {
		if (this.formData === this.captions.teeTicketsPrint) {
			this._PrintModalBusiness.printTeeTickets(
				[this.courseId],
				this.scheduleTeeTimeId,
				this.dateTime,
				this.scheduledTeeTimeIds,
				this.playerIds, // pass this to generate tee tickets for only selected player //this.selectedPlayers.map(x=> x.playerId),
				() => {
					x.dialogRef.close();
				}
			);
		} else if (this.formData === this.captions.receiptPrint) {
			if (this.transactionId != 0) {
				this._PrintModalBusiness.printReceipt(this.transactionId,[],() => {
					x.dialogRef.close();
				});
			}
		} else if (this.formData === this.captions.cartCards || this.displayLandscape === this.captions.PrintInLandscape) {
			this._PrintModalBusiness.printCartCards(
				this.course,
				this.courseId,
				this.scheduleTeeTimeId,
				this.dateTime,
				this.scheduledTeeTimeIds,
				this.isPrintLandscape,
				() => {
					x.dialogRef.close();
				}
			);
		}
		else if(this.formData === this.captions.bagTags){
			this._PrintModalBusiness.printBagTags(
				[this.courseId],
				this.selectedPlayers.filter(x => x.playerCategoryId == PlayerCategory.Player).map(x=> x.playerId),
				this.selectedPlayers.filter(x => x.playerCategoryId != PlayerCategory.Player && !this.checNullOrEmpty(x.linkingId)).map(x=> x.linkingId),
				this.printForm.controls.startDate.value,
				this.printForm.controls.endDate.value,
				() => {
					x.dialogRef.close();
				}
			);
		}		
	}

	private checNullOrEmpty(data: any): boolean {
        if(data == null || data === '' || data === undefined) {
            return true;
        } else {
            return false;
        }
    }

	ngOnDestroy(): void {
		this.PrintFormSubscribe.unsubscribe();
		this.saveSubscriber.unsubscribe();
	}

	selectPlayers(eve) {
		if(eve?.isSelected) {
			eve['isSelected'] = false;
			this.selectedPlayers = this.selectedPlayers.filter(x=> x.playerId != eve.playerId)
		} else {
			this.players.forEach (x=> {
			if(x.playerId === eve.playerId) {
				x['isSelected'] = true;
				this.selectedPlayers.push(eve)
			}
		  })
		}
	}
}
