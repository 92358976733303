export enum SelectType {
    single = 1,
    multiple = 2
}

export interface ButtonData {
    description: string;
    disabled?: boolean;
    id: number;
    name: string;
}

export const single = 'single';
export const multiple = 'multiple';
export const days = 'days';
