<section class="player-selection-wrapper">
  <form [formGroup]="playerSelectionForm" class="h-100 w-100"> 
    <div class="dialog-container">
      <div mat-dialog-title class="header">
        <span class="header--title">{{dialogData.modalTitle}}</span>
        <span class="header--close icon-Cancel" (click)="close()"></span>
      </div>
      <div class="content">   
        <label class="ag_mb--5">{{captions.player}}</label> 
        <div class="d-block">
            <mat-radio-group formControlName='player' class="radio-container">
                <mat-radio-button *ngFor="let x of playersList; let i=index" class='ag_form--radio-button  ag_mb--2 d-block'
                    [value]="x" (change)="playersChange($event, x, i)">
                    {{x.firstName}}  -  {{x.lastName}}
                </mat-radio-button>
            </mat-radio-group>
        </div>          
        </div>
        <div class="actions">
          <div class="action-wrapper">
            <button mat-button mat-flat-button color="primary" class="actions__save float-right" (click)="onSave()"
            [disabled]="isSaveDisabled">{{dialogData.saveText}}</button>
            <button mat-button  (click)="close()">{{dialogData.cancel}}</button>
          </div>          
        </div>
    </div>
  </form>
  </section>