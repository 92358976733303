import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Injectable()

export class CopytomanyPlayerService{
    private readonly captions:any;
    constructor(private _HttpClient: HttpClient, private _localization: GolfLocalization){
        this.captions = this._localization.captions.BulkBookTeeTime;
    }
    
    getCaptions() {
        return {
            date: "Select Date",
            course: "Course"
        };
    }
    getErrorCaptions() {
        return {
            missingAllocationCode: this.captions.missingAllocationCode,
            missingAutoBook: this.captions.missingAutoBook,
            missingCourse: this.captions.missingCourse,
            missingPlayerInformation: this.captions.missingPlayerInformation,
            missingPlayerType: this.captions.missingPlayerType,
            missingRateType: this.captions.missingRateType,
            missingDate: this.captions.missingDate,
            missingTime: this.captions.missingTime
        }
    }
    getPlayersDetails(playerInfo) {
        return [
            {
                label: "Course",
                value: playerInfo ? playerInfo.course.course : ''
            },
            {
                label: "Tee Time",
                value: playerInfo ? this._localization.LocalizeTime(playerInfo.time) : ''
            },
            {
                label: "Players",
                value: playerInfo ? playerInfo.playerDetail.length : ''
            }
        ];
    }

    getPlayerList(playerList) {
        return playerList.map(players => {
            players['isRemovable'] = true;
            return players;
        });
    }

    getCourses() {
        return [
            {
                value: 'jones',
                viewValue: 'Jones'
            },
            {
                value: 'jones',
                viewValue: 'Jones'
            },
            {
                value: 'jones',
                viewValue: 'Jones'
            },

        ];
    }

    getTeeSheeetID() {
        return {
            tableID: 'TableCopyMany'
        };
    }

    getTeeSheetCustomTableData(teeDialogInfo) {
        return {
            isMultiView: false,
            isShowAvailableOnly: true,
            isHideCrossover: true,
            isDetailView: false,
            isOrderByHoleTime: true,
            isHoleNotificationEnabled: false,
            isCommentNotificationEnabled: false,
            isActionAvailable: true,
            isMenuEnabledOnEllipsis: false,
            isPlayerDetailIconsEnabled: true,
            isDragDisabled: true,
            isBulkMoveEnabled:false,
            isTooltipDisabled: true,
            isLoadedinPopup: true,
            teeTimeAction: 'copyToMany',
            teeActionPerformedCourse: teeDialogInfo ? teeDialogInfo.course.id : 0
        };
    }

    getTeeSheetSkeletonData() {
        return this._HttpClient.get('assets/json/tee-sheet-skeleton.json');
    }
    getTeeSheetPlayerData() {
        return this._HttpClient.get('assets/json/tee-sheet-playerdata.json');
    }
}