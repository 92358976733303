import { Injectable } from '@angular/core';
import { HttpMethod,  HttpServiceCall } from 'src/app/common/shared/shared/service/http-call.service';
import { Host } from 'src/app/common/shared/shared/globalsContant';

@Injectable(
  { providedIn: "root"}
)
export class LogTypeBussines {
  constructor( public http: HttpServiceCall) {
  }

  async callAPIGetAllLog(includeinactive) {
     const results = await this.http.CallApiAsync<any[]>({
      host: Host.golfManagement,
      callDesc: "GetAllLogType",
      method: HttpMethod.Get,
      uriParams: { "isIncludeInactive": includeinactive }
    });
    return results.result;
  }

  async callAPICreateLog(logtype) {
   return await this.http.CallApiAsync<any[]>({
        host: Host.golfManagement,
        callDesc: "CreateLogType",
        method: HttpMethod.Post,
        body: logtype,
        showError: true
      });
  }

  async callAPIEditLog(logtype, id) {
    let updateResult = await this.http.CallApiAsync<any[]>({
        host: Host.golfManagement,
        callDesc: "UpdateLogType",
        method: HttpMethod.Put,
        body: logtype,
        uriParams: { "id": id },
        showError: true
      });
      return updateResult;
  }

  async callAPIUpdateLog(inputdata) {
   const logType: any = {
      logTypeInstance: inputdata.logTypeInstance,
      isActive: inputdata.isActive
    };
     await this.http.CallApiAsync<any[]>({
      host: Host.golfManagement,
      callDesc: "UpdateLogType",
      method: HttpMethod.Put,
      body: logType,
      uriParams: { "id": inputdata.id },
      showError: true
    });
  }

  async callAPIDeleteLog(id) {
    let deletestatus = await this.http.CallApiAsync<any[]>({
      host: Host.golfManagement,
      callDesc: "DeleteLogType",
      method: HttpMethod.Delete,
      uriParams: {"id":id},
      showError: true
    });
    return deletestatus.result;
  }

 async GetConfirmationSetting()
 {
  return JSON.parse(sessionStorage.getItem('defaultSettings')).showConfirmationDialog;
 } 

}
