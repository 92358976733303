<div class="staff-reader-board-wrapper w-100 h-100" [formGroup]="staffreaderForm">
    <div class="ag_display--flex  ag_height--100">
        <div class="w-100">
            <div class="m-3">
                <app-table-search-header [searchHeaderOption]="searchHeaderOption" (searchChange)="searchChange($event)"
                    (createType)="createEvent($event)">
                    <span class="Total_count font-weight-bold pr-3"> {{this.captions.totalnotes}} <label class=" mb-0">{{this.totalnotes}}</label> </span>
                    <div class="filter-btn additional-btn">
                        <span class="boxed-icon">
                            <i class="icon-filter_filled cursor" (click)="openFilter($event)" attr.automationId='Icn_{{automationId}}_filter' ></i>
                        </span>
                    </div>
               </app-table-search-header>
            </div>
           
            <section class="notes-section-height">
                <div class="notes_container position-notes-header ag_m--3 p-2 ag_mb--0" >
                    <div class="div-cell-width"></div>
                    <div class="ag_font--bold div-cell-notes ag_pl--8">{{captions.lbl_notes}}</div>
                    <div class="ag_display--flex">
                        <div class="div-cell-label-priority ag_ml--auto ag_font--bold">{{captions.lbl_priority}}</div>
                        <div class="div-cell-width-sent ag_font--bold">{{captions.lbl_users_sent}}</div>
                        <div class="div-cell-width-acknowledge ag_font--bold">{{captions.lbl_usersAcknowledged}}</div>
                    </div>
                   
                    <div class="div-cell-width-action ag_font--bold">{{captions.lbl_actions}}</div>
                </div>
              <div *ngFor="let note of filteredNotes; let i=index" class="notes_container ag_m--3 p-2 ag_cursor--pointer" >
               
                <!-- <div>
                    <mat-checkbox class="ag_ml--4 ag_mr--4 div-cell-width"></mat-checkbox>
                 </div> -->
                 <div (click)="openSlide(note)" class="div-cell-notes ag_pl--2">
                    <span class="font-weight-bold "  [matTooltip]='note.notes'>{{note.notes}}</span>

                 </div>
                 <div class="ag_display--flex ag_ml--auto"  >
                   <span >
                    <div class="priority div-cell-width-priority" [ngClass]="'_priority_'+note.priority">
                        {{note.priorityName}}
                    </div></span>
                   <span class=" div-cell-width-sent font-weight-bold ag_p--2">{{note.noOfSentUsers}}</span>
                   <span class=" div-cell-width-acknowledge font-weight-bold ag_p--2">{{note.noOfAcknowledgedUsers}}</span>
                 </div>
                 <div class="div-cell-width-action">
                    <i  aria-hidden="true" attr.automationId='Icn_{{automationId}}_edit{{i}}'  class="icon-edit1"  [ngClass]="{'disabled': isViewOnly}" (click)="editEvent(note.id)"  ></i>
                    <i  aria-hidden="true" attr.automationId='Icn_{{automationId}}_delete{{i}}' class="icon-delete ag_pl--2"  [ngClass]="{'disabled': isViewOnly}" (click)="deleteNote(note.id)"></i>
                 </div>
              </div>
              <div *ngIf="this.totalnotes == 0">
                <div class="no-data-img">
                    <img src="./assets/images/SearchIllustration.png" alt="No Data">
                </div>
              </div>
                
             </section>
        </div>
        <div class="right-sec ag_mt--4 p-1 ag_posi" *ngIf="isSlideOpen">
            <app-aside-filter [config]="filterConfig" [automationId]="automationId"
                (dateChangedEmitter)="dateChanged($event)" (filterEvent)="CategoryFilter($event)"
                [dateSelection]="dateConfig" (collapsePanel)="collapsePanel($event)" >
                <div class="ag_container--padding-md">
                    <label >{{captions.lbl_priority}}</label>
                    <div class="ag_display--flex-wrap">
                    <div  class="ag_mt--4 ag_display--flex ag_cursor--pointer" (click)="selectedPriority(0)">
                        <span class="priority mr-4 priority_All"  [ngClass]="funtionCheck(0)">{{captions.lbl_all_priority}}</span>
                    </div>  
                    <div *ngFor="let item of priorityList;let i=index" class="ag_mt--4 ag_display--flex ag_cursor--pointer" (click)="selectedPriority(item.id)" attr.automationId='Lbl_{{automationId}}_selectedPriority{{i}}'>
                            <span class="priority mr-4 {{'_priority_'+item.id}}"  [ngClass]="funtionCheck(item.id)">{{item.viewValue}}</span>
                        </div>
                        <div class="ag_mt--5">
                            <label class="ag_mb--2">{{captions.lbl_staffnoteack}}</label>
                            <app-ag-toggle [toggleInputs]="showNoteAckToggleInput"></app-ag-toggle>
                        </div>

                    </div>
                </div>

                <div class="ag_display--flex-wrap ag_footer--actions aside-button">
                    <app-button class="asideButton" attr.automationId='Btn_{{automationId}}_save' [buttontype]="saveButton" (valueChange)='onFilter($event)'></app-button>          
                    <app-button class="asideButton" attr.automationId='Btn_{{automationId}}_cancel' [buttontype]="cancelButton" (valueChange)='onCancel($event)'></app-button>          
                </div>
            </app-aside-filter>
        </div>
    </div>
     <app-slide-action [ngClass]="[showSlide ? 'show':'hide']" (closeSlide)="closeSlide()" [header]="header">
        <app-view-note [inputs]="selectedNote"></app-view-note>
     </app-slide-action>
</div>

<div *ngIf="creationToggler">
    <app-create-modal [input]="createModalData" (clickEvent)="onBack()">
        <app-create-staff-reader-board [noteId]="this.noteId" (handleClickEvent)="handleClick($event)"></app-create-staff-reader-board>
    </app-create-modal>
</div>