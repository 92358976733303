
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { ClientMultipack } from 'src/app/retail/retail.modals';
import { ScheduledTeeTimePlayerCustomFee } from 'src/app/retail/shared/shared.modal';
import { AllocationBlockWithPlayerType } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import { DomainRetailItem } from 'src/app/settings/settings-shared/assign-retail-item/assign-retail-item.model';
import { Deposit } from 'src/app/shared/payment-model';
import { DynamicPricingPlayerFee, PlayerTypes, RateSetupData, RateType } from 'src/app/tee-time-actions/teetime/player-details/player-info.model';
import { ScheduledTeeTimePlayerFeeDynamicPricing } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { PlayerTypeRateType } from 'src/app/tee-time/tournaments/tournaments-modal/tournament-details/tournament-details.model';

export interface ScheduledTeeTimeUnPaidPlayer {
  id?: number;
  scheduledTeeTimeId: number;
  playerId: number;
  playerLinkId? : string;
  playerName: string;
  playerType: string;
  playerTypeId: number;
  playerSlotPosition: number;
  scheduledDateTime: string;
  scheduledAPIDateTime?: string;
  course: string;
  rateTypeId: number;
  rateType: string;
  packageCode: string;
  walk: string;
  trail: string;
  walk_trial?: string;
  pos_holes?: string;
  holes: number;
  greenFee: number ;
  cartFee: number ;
  entryFee: number ;
  greenFeeCurrency?:string;
  cartFeeCurrency?:string;
  totalFeeCurrency?:string;
  cartFeeRetailItemId: number;
  greenFeeRetailItemId: number;
  entryFeeRetailItemId: number;
  negotiateCartFeeRetailItemId: number;
  negotiateGreenFeeRetailItemId: number;
  negotiateEntryFeeRetailItemId: number;
  tournamentId: number;
  otherItems: TournamentPackageItem[];
  cartFeeRetailItem: DomainRetailItem;
  greenFeeRetailItem: DomainRetailItem;
  entryFeeRetailItem: DomainRetailItem;
  negotiateCartFeeRetailItem: DomainRetailItem;
  negotiateGreenFeeRetailItem: DomainRetailItem;
  negotiateEntryFeeRetailItem: DomainRetailItem;
  deposits: Deposit[];
  checked?: boolean;
  playerPosition?: number;
  isTournamentPlayersUnPaid?: boolean;
  ticketNumber?: string;
  playerCategoryId?: number;
  multiPackTransactionDetailId?: number;
  oldMultiPackTransactionDetailId?: number;
  multiPackRetailItem?: DomainRetailItem[];
  greenFeeTax: number;
  cartFeeTax: number;
  entryFeeTax: number;
  corpId?: string;
  corpName?: string;
  discountType?: string;
  scheduledTeeTimePlayerFeeId? : number;
  scheduledTeeTimePlayerCustomFees?: ScheduledTeeTimePlayerCustomFee[];
  scheduledTeeTimePlayerId?: number;
  isHold?: boolean;
  totalCustomFeeTax?: number;
  isReleaseAllow?: boolean;
  playerStatus?:any;
  rateTypeInfo?: RateTypeInfo;
  playerTypeInfo?: PlayerTypeInfo;
  playerTypeRateTypes?: PlayerTypeRateType[];
  allocationBlockWithPlayerTypes?: AllocationBlockWithPlayerType;
  allocationBlockId?: number;
  isNotDepositWithPendingSettlement?: boolean;
  deposit?:number;
  blockByUser?: string;
  transactionId?: number;
  teeTimeFormat?: number;
  confirmationNumber?: string;
  courseListOrder?: number;
  courseId?: number;
  isDisableDropdown?:boolean;
  isNegotiated?:boolean;
  overrideDetail?:OverrideDetails[];
  isDynamicPricingApplied?:boolean;
  dynamicPricing?:DynamicPricingPlayerFee;
  isCustomFeeEnabled?:boolean;
  isDirty?:boolean;
  isPlayerTypeRequired?:boolean;
  isRateTypeRequired?:boolean;
  isRateTypeDirty?:boolean;
  isMultiPackUpdated?:boolean;
  isGreenFeeNegotiated?:boolean;
  isCartFeeNegotiated?:boolean;
  isMultiPackApplied?:boolean;
  isAllowPay?:boolean;
  dependentTransactionIds?: Map<number, number[]>;
  tokenTransId?:number;
}


export interface OriginalFeeDetail
{
  greenFee:number;
  cartFee:number;
  cartFeeRetailItemId: number;
  greenFeeRetailItemId: number;
  cartFeeRetailItem: DomainRetailItem;
  greenFeeRetailItem: DomainRetailItem;
}

export interface OverrideDetails {
  id: number;
  scheduledTeeTimePlayerId: number;
  overrideType: number;
  userId: number;
}

export enum OverrideType {
    playerType = 1,
    rateType = 2
}

export interface RateTypeInfo{
  value:number;
  options:DropdownOptions[];
  filteroptions: DropdownOptions[];
}

export interface PlayerTypeInfo{
  value:number;
  options:DropdownOptions[];
  filteroptions: DropdownOptions[];
}

export interface RateSetupFees{
  startDateTime:Date;
  endDateTime:Date;
  courseId:number;
  rateSetups: RateSetupData[];
}

export interface DynamicPricingFees{
  date:Date;
  courseId:number;
  dynamicPricings:DynamicPricingPlayerFee[];
}

export interface UpdatePlayerFee{
  scheduledTeeTimePlayerFeeId :number;
  scheduledDateTime:Date;
  playerTypeId:number;
  rateTypeId:number;
  greenFee:number;
  cartFee:number;
  isCustomFeeEnabled?:boolean;
  scheduledTeeTimePlayerCustomFees?: ScheduledTeeTimePlayerCustomFee[];
  isDynamicPrice:boolean;
  dynamicPricingId:number;
  scheduledTeeTimePlayerFeeDynamicPricing? : ScheduledTeeTimePlayerFeeDynamicPricing;
  overrideDetail?:OverrideDetails[];
  scheduledTeeTimeId: number;
  multiPackTransactionDetailId?:number;
  oldMultiPackTransactionDetailId?:number;
  isMultiPackUpdated?:boolean;
  isGreenFeeNegotiated?:boolean;
  isCartFeeNegotiated?:boolean;
  //custome fee yet to add
}

export interface CustomFeeData{
  date:Date;
  scheduledTeeTimePlayerFeeId:number;
  customFeeConfigs:ScheduledTeeTimePlayerCustomFee[];
  totalCustomFeeTax?: number;
}

export interface MultiPackDetails {
    multiPackTransactionDetailId: number;
    multiPackRetailItemId: number;
    clientMultiPacks: ClientMultipack[];
    multiPackPrice: number;
    quantityPerSale: number;
    multiPackPerSalePrice: number;
}

export interface Course {
  id: number;
  name: string;
}

export interface TournamentPackageItem {
  tournamentId: number;
  tournamentPackageId: number;
  tournamentComponentId: number;
  retailItemId: number;
  retailItemDetail: DomainRetailItem;
  price: number;
}

export interface DiscountConfiguration{
  discountTypeId: number;
  discountTymeName: string;
  discountValue: number;
  isPercentage: boolean;
  minimumAmountInCart: number;
  isExclusive: boolean;
  tenderReducesDiscount: boolean;
  allowAdditionalFlatDiscount: boolean;
}