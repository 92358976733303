import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../login/login-component/login.component';
import { LayoutComponent } from '../layout/layout.component';
import { StyleGuideComponent } from '../style-guide/style-guide.component';
import { AuthGuard } from '../login/auth.guard';
import { PmsLoginResolver } from '../common/external-request/route-resolvers/pms-login.resolver';
import { SetPropertyComponent } from '../login/set-property/set-property.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent 
  },
  {
    path: 'supportlogin',
    component: LoginComponent
  },
  {
    path: 'setProperty',
    component: SetPropertyComponent
  },
  {
    path: 'Pms',
    component: LoginComponent,
    resolve:{
      loginData: PmsLoginResolver
    }
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate:[AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('../home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'tee-time',
        loadChildren: () => import('../tee-time/tee-time.module').then(m => m.TeeTimeModule)
      },
      {
        path: 'guest',
        loadChildren: () => import('../guest/guest.module').then(m => m.GuestModule)
      },
      {
        path: 'shop',
        loadChildren: () => import('../retail/shop/shop.module').then(m => m.ShopModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('../reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('../settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'lessons',
        loadChildren: () => import('../lessons/lessons.module').then(m => m.LessonsModule)
      },
      {
        path: 'lost-and-found',
        loadChildren: () => import('../lost-and-found/lost-and-found.module').then(m => m.LostAndFoundModule)
      },
      {
        path: 'audit',
        loadChildren: () => import('../audit/audit.module').then(m => m.AuditModule)
      },
      {
        path: 'styleguide',
        component: StyleGuideComponent
      },
      {
        path: 'logviewer',
        loadChildren: () => import('../common/logviewer/logviewer.module').then(m => m.LogviewerModule)
      },
      {
        path: 'accounting',
        loadChildren: () => import('../golf-accounting/golf-accounting.module').then(m => m.GolfAccountingModule)
      },
      {
        path: 'folio',
        loadChildren: () => import('../folio/resort-finance/resort-finance.module').then(m => m.ResortFinanceModule)
      },
      {
        path: 'exportimport',
        loadChildren: () => import('../common/export-import/export-import.module').then(m=>m.ExportImportModule)
      },
      {
        path: 'all',
        loadChildren: () => import('src/app/common/all/all.module').then(m => m.AllModule)
      },
      {
        path: 'allReport',
        loadChildren: () => import('src/app/common/report-menu/report-menu.module').then(m => m.reportMenuModule)
      }
    ]
  },
  {path:'**', redirectTo:'/home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
