import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { GolfLocalization } from '../localization/golf-localization';
import { API } from '../../shared/models/property-setting.model';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';

@Injectable({
    providedIn: 'root'
})
export class GolfPropertyInformation extends CommonPropertyInformation {


    constructor(public localization: GolfLocalization) {
        super(localization);
        this.SetDefaultDataOnLoad();
    }

    public set UpdateDate(newDate: Date) {
        this._currentDate = this.localization.getDate(newDate);
        this._currentDate.setHours(0, 0, 0, 0);
    }

    public get CurrentDTTM() {
        const now = this.localization.getCurrentDate();
        return new Date(this._currentDate.getFullYear(), this._currentDate.getMonth(), this._currentDate.getDate(), now.getHours(), now.getMinutes());
    }

    public SetPropertyDate(newDate: Date, updatelocalstore: boolean = true) {
        this.UpdateDate = newDate;
        if (updatelocalstore) {
            sessionStorage.setItem('propertyDate', _.cloneDeep(this._currentDate).toString());
        }
    }
    public GetDefaultCountryCode(): string {
        if (sessionStorage.getItem('defaultCountryCode') === 'null' || sessionStorage.getItem('defaultCountryCode') === 'undefined') {
            return '';
        }
        else {
            return sessionStorage.getItem('defaultCountryCode');
        }
    }

    public SetPropertySetting(settings: API.PropertySetting) {
        if (settings) {
            this._useRetailInterface = settings.activateRetailInterface;
            this._VATEnabled = settings.vatEnabled;
        }
        else {
            this._useRetailInterface = false;
            this._VATEnabled = false;
        }
        sessionStorage.setItem('useRetailInterface', _.cloneDeep(this._useRetailInterface).toString());
        sessionStorage.setItem('VATEnabled', _.cloneDeep(this._VATEnabled).toString());
        sessionStorage.setItem('productVersion', settings.productVersion);
        sessionStorage.setItem('userProductVersion', settings.userProductVersion);
        sessionStorage.setItem('defaultCountryCode', settings.defaultCountryCode);
        localStorage.setItem('userProductVersion', settings.userProductVersion);
        sessionStorage.setItem('newDate',settings.propertyDate);
    }

    public SetPaymentConfiguration(payConfig: API.PaymentConfiguration[]) {
        this._paymentConfiguration = [];
        if (payConfig && payConfig.length > 0) {
            this._paymentConfiguration = payConfig;
        }
        sessionStorage.setItem('paymentConfiguration', _.cloneDeep(JSON.stringify(this._paymentConfiguration)));
    }

    public SetAuthorizeConfiguration(authConfig: any[]) {
        this._authorizeConfiguration = [];
        if (authConfig && authConfig.length > 0) {
            this._authorizeConfiguration = authConfig;
        }
        sessionStorage.setItem('authorizeConfiguration', _.cloneDeep(JSON.stringify(this._authorizeConfiguration)));
    }

    public GetPaymentConfigValueByKey(configKey: string, outletId: number): string {
        let payConfigValue = '';
        // Payment Configuration
        let paymentConfig: string = sessionStorage.getItem('paymentConfiguration');
        if (paymentConfig && JSON.parse(paymentConfig)) {
            this._paymentConfiguration = JSON.parse(paymentConfig);
            this._paymentConfiguration = _.sortBy(this._paymentConfiguration, "outletId");
        }
        var payConfig = this._paymentConfiguration.find(r => r.propertyId == this._propertyId && r.outletId == outletId && r.configKey == configKey);
        if (!payConfig) { // Get default pay configuration
            payConfigValue = this._paymentConfiguration.find(r => r.propertyId == 0 && r.outletId == 0 && r.configKey == configKey).configValue;
        }
        else {
            payConfigValue = payConfig.configValue;
        }

        //Removing trailing slash since Golf Service doesn't need them
        payConfigValue = payConfigValue.substr(0, payConfigValue.length - 1);
        return payConfigValue;
    }

    public SetDefaultDataOnLoad() {
        // Setting propety Date
        let propertyData: any = sessionStorage.getItem('propertyDate');
        if (propertyData) {
            this._currentDate = this.localization.getDate(propertyData);
        }
        else {
            this._currentDate = this.localization.getCurrentDate();
        }
        this._currentDate.setHours(0, 0, 0, 0);

        // Setting Retail-Interface switch value
        let retailSwitch: string = sessionStorage.getItem('useRetailInterface');
        if ((retailSwitch && retailSwitch.toLowerCase() == 'true')) {
            this._useRetailInterface = true;
        }
        else {
            this._useRetailInterface = false;
        }

        // Payment Configuration
        let paymentConfig: string = sessionStorage.getItem('paymentConfiguration');
        if (paymentConfig && JSON.parse(paymentConfig)) {
            this._paymentConfiguration = JSON.parse(paymentConfig);
        }
        else {
            this._paymentConfiguration = [];
        }

        // Set PropertyId
        var propertyId = this.GetPropertyInfoByKey('PropertyId');
        if (propertyId) {
            this._propertyId = Number(propertyId);
        }
    }

    public SetSupportedPMAgentVersion(supportedPMAgentVersion) {
        sessionStorage.setItem('supportedPMAgentVersion', _.cloneDeep(JSON.stringify(supportedPMAgentVersion)));
    }
}
