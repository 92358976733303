<section class="MMsrch">
  <mat-form-field class="multi-chip-list" [floatLabel]="floatLabel" >
    <mat-chip-list #chipList>
      <mat-chip *ngFor="let data of emails" [selectable]="selectable"
               [removable]="removable" (removed)="remove(data)">
        {{data.mail}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input 
             [placeholder]="placeHolder"
             [matChipInputFor]="chipList"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             [matChipInputAddOnBlur]="addOnBlur"
             (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
  </mat-form-field>
  <div *ngIf="showError"  class="errposition text-danger">{{captions.lbl_errEmail}}</div>
  <div *ngIf="showDuplicate"  class=" errposition text-danger">{{captions.err_duplicateEmail}}</div>
</section>