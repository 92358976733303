<section class="session-expired-popup">
    <header>
      <h1 mat-dialog-title>{{data.PopupHeaderContent}}</h1>
      <!-- <i aria-hidden="true" class="close-icon icon-requisition-denied" (click)="this.dialogRef.close('Cancel');"></i> -->
    </header>
    <main mat-dialog-content>
        {{data.PopUpContent}}
    </main>
    <footer mat-dialog-actions>
        <lib-agilysys-button [agilysysButtonModel]="agilysysCofirmationButton"></lib-agilysys-button>
    </footer>
</section>