<section class="view-note p-3">
    <!-- <div class="ag_display--inblock pb-2 w-100">
        <i class="icon-Printer-New cursor float-right"></i>
    </div> -->
    <div class="ag_display--flex pb-3 align-items-baseline">
        <label>{{captions.lbl_staff_note}}</label>
        <span class="ml-auto priority" [ngClass]="'_priority_'+note?.priority">{{note?.priorityName}}</span>
    </div>
    <h4 class="ag_group--headers notes-custom-class" [matTooltip]="note?.notes">{{note?.notes}}</h4>
    <label class="popuptxt">{{captions.lbl_staffnote_popup_txt}} {{note?.validToDate}}</label>
    <div class="box-div ag_display--flex p-3 mt-3 mb-4">
        <div class="w-50 border-right">
            <div>{{captions.lbl_users_sent}}</div>
            <label class="mt-2">{{note?.noOfSentUsers}}</label> 
        </div>
        <div class="ml-auto">
            <div>{{captions.lbl_usersAcknowledged}}</div>
            <label class="mt-2">{{note?.noOfAcknowledgedUsers}}</label> 
        </div>
    </div>
    <div class="user-div">
        <div class="ag_display--flex ag_mb--3">
            <h4 class="ag_group--headers setmb ag_pr--2">{{captions.lbl_Users}}</h4>
            <label class="count">{{note?.noOfSentUsers}}</label>
        </div>

        <div class="notes-height">
        <div class="headertxt ag_pt--4" *ngFor="let item of note?.userNameAndRoles" >
                <div>
                    <h4 class="ag_group--headers">{{item.roleName}}</h4>
                <div class="ag_display--flex-wrap">
                    <div *ngFor="let user of item?.users">
                        <div class="manager">
                            <div>{{user.userName}}</div>
                            <div class="ag_pt--2">
                            <label><i *ngIf="user.isAcknowledged" class="icon-seen"></i>{{user.acknowledgedOn}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                
                
            </div>
           
        </div>
    </div>
</section>