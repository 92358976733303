import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';

import { Captions, PlayerInformation, PlayerDetails, Courses } from './bulk-move-modal.model';
import { BulkMoveModalBusiness } from './bulk-move-modal.business';
import { BulkMoveModalService } from './bulk-move-modal.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { DateInput } from 'src/app/shared/shared-models';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TeeSheetCustomData } from 'src/app/shared/models/teesheet.form.models';
import { Localization } from "src/app/common/localization/localization";
@Component({
  selector: 'app-bulk-move-modal',
  templateUrl: './bulk-move-modal.component.html',
  styleUrls: ['./bulk-move-modal.component.scss'],
  providers: [BulkMoveModalBusiness, BulkMoveModalService]
})
export class BulkMoveModalComponent implements OnInit, AfterViewInit {
  captions: Captions;
  bulkMovePlayersForm: UntypedFormGroup;
  courses: Courses[];
  dateInputs: DateInput;
  date: Date;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  minDate: Date;
  playerInformation: PlayerInformation[];
  playerDetails: PlayerDetails[];
  customTableData: TeeSheetCustomData;
  allSelected: boolean;
  @Output() notifyParent = new EventEmitter();
  floatLabel: string;
  automationId:string="BulkMoveModal";
  constructor(private fb: UntypedFormBuilder, private _BulkMoveModalBusiness: BulkMoveModalBusiness,  private _localization: Localization) {
    this.floatLabel = this._localization.setFloatLabel;
   }

  ngOnInit() {
    this.captions = this._BulkMoveModalBusiness.GetCaptions();
    this.playerDetails = this._BulkMoveModalBusiness.GetPlayersDetails();
    this.createBulkMovePlayersForm();
    this.minDate = new Date();
    this.date = this.minDate;
    this.allSelected = false;
    this.dateInputs = {
      className: 'golf-form-control--sm',
      form: this.bulkMovePlayersForm,
      formControlName: 'dateControl',
      minDate: this.minDate,
      placeHolder: this.captions.date,
      value: this.date,
      automationId: this.automationId
    };
    this.courses = this._BulkMoveModalBusiness.GetCourses();
    this.playerInformation = this._BulkMoveModalBusiness.GetPlayerInformation();
  }

  ngAfterViewInit() {
    this.bulkMovePlayersForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.notifyParent.emit(this.bulkMovePlayersForm.valid);
    });
  }

  createBulkMovePlayersForm() {
    this.bulkMovePlayersForm = this.fb.group({
      dateControl: '',
      courseControl: ''
    });
  }  

  checkbox(event, from, player?) {
    if (from === 'all') {
      this.playerInformation.forEach(x => x['checked'] = event.target.checked);
      this.allSelected = event.target.checked;
    } else {
      this.playerInformation.find(x => x.playerId === player.playerId)['checked'] = event.target.checked;
      this.allSelected = this.playerInformation.every(x => x.checked);
    }
  }
}
