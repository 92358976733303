<mat-dialog-actions class="dialog-container__actions">
  <div>
    <button attr.automationId='Btn_{{automationId}}_SaveClose' mat-button mat-flat-button color="primary" *ngIf="isenableSaveCancel && !isVcartEdit" [disabled]="isDisabled"
      class="actions__save" [appDisableDoubleClick]="clickThrottleTime"
      (throttleClick)="saveCancelHandler()">{{saveCloseButtonText}}</button>
    <button attr.automationId='Btn_{{automationId}}_Save' mat-button mat-flat-button color="primary" *ngIf="!isVcartEdit" [disabled]="isDisabled||isDisableSaveOnly" class="actions__save"
      [appDisableDoubleClick]="clickThrottleTime" (throttleClick)="saveHandler()">{{saveButtonText}}</button>
    <button attr.automationId='Btn_{{automationId}}_AddToCart' mat-button mat-flat-button color="primary" *ngIf="enableAddToCart" [disabled]="isDisabled || isDisableSaveOnly"
      class="actions__save" [appDisableDoubleClick]="clickThrottleTime" (throttleClick)="addToCartHandler()">
      {{cartCaption}}
    </button>
    <button attr.automationId='Btn_{{automationId}}_Cancel' mat-button class="actions__cancel" [appDisableDoubleClick]=1
      (throttleClick)="cancelHandler()">{{cancelButtonText}}</button>
  </div>
</mat-dialog-actions>
