import { TenantManagementCommunication } from 'src/app/common/communication/services/tenantmanagement-communication-service';
import { Injectable } from '@angular/core';
import { GolfUtilities } from '../../utilities/golf-utilities';
import { API } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { PropertyConfigurations } from '../../global.constant';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class PropertyConfigurationDataService {
    constructor(private _tenantManagement: TenantManagementCommunication, private _utilities: GolfUtilities, private _localization: GolfLocalization) { }

    public getApplicationFormatConfiguration(): Promise<API.PropertyConfig<API.ApplicationFormat>> {

        return this._tenantManagement.getPromise<API.PropertyConfig<API.ApplicationFormat>>(
            {
                route: GolfApiRoute.ApplicationFormatConfiguration,
                uriParams: {
                    configurationName: PropertyConfigurations.ApplicationFormat,
                    productId: this._localization.GetPropertyInfo('ProductId')
                }
            }
        )
    }


    public updateSettings<T>(data: API.PropertyConfig<T>): Promise<boolean> {
        const setting: API.PropertyConfig<string> = {
            id: data.id,
            propertyId: data.propertyId,
            productId: data.productId,
            configurationName: data.configurationName,
            configValue: JSON.stringify(data.configValue),
            defaultValue: JSON.stringify(data.defaultValue)
        };
        return this._tenantManagement.putPromise<boolean>(
            { route: GolfApiRoute.UpdatePropertyConfiguration, body: setting });
         
    }


}