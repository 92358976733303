import { Injectable } from '@angular/core';
import {
	ReportDownloadFormat,
	ReportAPIOptions,
	ReportAPIModel,
	JasperReportDetails
} from 'src/app/reports/report.model';
import { ReportCode } from 'src/app/reports/common/report.constants';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { FastReportBusinessService } from 'src/app/reports/fast-report/fastreport.business.service';
import { SettingsDataService } from 'src/app/shared/data-services/golfschedule/settings.data.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ComponentDetails } from 'src/app/shared/shared-models';
import { ReplaySubject } from 'rxjs';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { ReportSelectorBuilder } from 'src/app/reports/common/report.selector';

@Injectable({
	providedIn: 'root'
})
export class CartCardBusiness {
	captions: any;
	printFormat: ReportDownloadFormat = 'PDF';
	_dialog: MatDialog;
	popUpComponentDetails: ComponentDetails;
	$destroyed: ReplaySubject<boolean> = new ReplaySubject(1);

	constructor(
		private _localization: GolfLocalization,
		private _fastReport: FastReportBusinessService,
		private _settingsDataService: SettingsDataService,
		private router: Router,
		private dialog: MatDialog,
		private _utils: GolfUtilities
	) {
		this._dialog = dialog;
		this.captions = this._localization.captions.teeActions;
	}

	printCartCards(
		courseName: string,
		courseId: number,
		scheduleTeeTimeIds: number[],
		startDate: string,
		isPrintLandscape: boolean,
		callBack?: any
	) {
		const propertyName = this._localization.GetPropertyInfo('PropertyName');
		const propertyDate = this._localization.ConvertDateToISODateTime(this._localization.getCurrentDate());
		const Course = courseName;
		const StartDate = this._localization.convertDateObjToAPIdate(startDate);
		let _reportOptions: ReportAPIOptions = {
			code: isPrintLandscape ? ReportCode.CartCardsForTeeTimeLandscape : ReportCode.CartCardsForTeeTime,
			filters: {
				courseId: courseId,
				scheduledTeeTimeIds: scheduleTeeTimeIds,
				startDate: StartDate.toString(),
				endDate: StartDate.toString()
			},
			format: this.printFormat,
			params: [
				{ pDate: propertyDate },
				{ pPropertyName: propertyName },
				{ pCourse: Course },
				{ pStartDate: StartDate },
				{ pEndDate: StartDate }
			],
			URIParams: null,
			pageBreak: true
		};

		this._fastReport.downloadReport(this.printFormat, _reportOptions, true, callBack);
	}
	printBagTags(
		courseIds: number[],
		playerIds: number[],
		playerLinkIds: string[],
		startDate: string,
        endDate: string,
		callBack?: any
	){
		const StartDate = this._localization.convertDateObjToAPIdate(startDate);
        const EndDate = this._localization.convertDateObjToAPIdate(endDate);
		let _reportAPIModel: ReportAPIModel = {
			code: ReportCode.BagTags,
			filterBody: {
                StartDate: StartDate,
                EndDate: EndDate,
                CourseIds: JSON.stringify(courseIds),
                PlayerLinkIds: JSON.stringify(playerLinkIds),
                PlayerIds: JSON.stringify(playerIds),
                pCourseNames: "",
                pPlayerNames: "",
                SortByName: false
            },
            downloadFileName: "Download",
			format: this.printFormat,
			parameters: {},
			uRIParams: {},
			dateFormat: this._localization.dateFormat
		};

        let reportselector = new ReportSelectorBuilder(this._localization);
        let data = reportselector.getAllReportSelections().find((x) => x.code.toString() == ReportCode.BagTags);
        if(data!=undefined || data!=null){
            var selectedreports =  {
                    reportCode : data.code,
                    isJaspersoftReport : data.enableJasperReports,
                    reportUrlPath : data.reportUrlpath
                } as JasperReportDetails
        }
          
        this._fastReport.printJasperReport(_reportAPIModel, selectedreports, callBack);
	}
}
