import { Injectable } from '@angular/core';
import { ServiceParams, BaseResponse } from '../shared/models/http.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfUtilities } from '../shared/utilities/golf-utilities';
import { HttpCallService } from '../shared/communication/common/http-call.service';
import { AlertType } from '../shared/shared-models';
import { GolfPropertyInformation } from '../core/services/golf-property-information.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginCommunicationService extends HttpCallService {

  private utils:GolfUtilities;
  private localize: GolfLocalization;
  constructor(httpclient: HttpClient, localization: GolfLocalization, utilities: GolfUtilities, PropertyInfo: GolfPropertyInformation) {
    super(environment["Authentication"], httpclient, localization, utilities, PropertyInfo);
    this.utils = utilities;
    this.localize = localization;
  }

  public async makePostCall<T>(params: ServiceParams, handleErr: boolean = true) {
    let response$: Promise<BaseResponse<T>> = super.postPromise<BaseResponse<T>>(params);
    //on error =>
    response$.catch(err => this.error(err, handleErr));
    //on success =>
    let response: any = await response$;
    return response;
  }
  public async makePutCall<T>(params: ServiceParams, handleErr: boolean = true) {
    let response$: Promise<BaseResponse<T>> = super.putPromise<BaseResponse<T>>(params);
    //on error =>
    response$.catch(err => this.error(err, handleErr));
    //on success =>
    let response: any = await response$;
    return response;
  }
  public async makeGetCall<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
    let response$: Promise<BaseResponse<T>> = super.getPromise<BaseResponse<T>>(params);

    //on error =>
    response$.catch(err => this.error(err, handleErr));

    //on success =>
    let response: any = await response$;
    return response;
}
  private error(err: HttpErrorResponse, handleErr: boolean) {
    if (handleErr) {
      this.errorHandler(err);
    }
    else {
      throw err;
    }
  }
  protected errorHandler(err: HttpErrorResponse): void {
    let code = parseInt(err.error.errorCode);
    let message: string = this.localize.getError(isNaN(code) ? undefined : code);
    this.utils.showAlert(message, AlertType.Error);
}

}
