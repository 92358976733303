import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login-component/login.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { DynamicFormBuilderModule } from '../dynamicforms/dynamic-forms.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoaderInterceptor } from '../core/services/loader.interceptor.service';
import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../shared/shared.module';
import { PlatformModule } from '@angular/cdk/platform';
import { PropertySettingDataService } from '../shared/data-services/authentication/propertysetting.data.service';
import { BrowserModule } from '@angular/platform-browser';
import { SetPropertyComponent } from './set-property/set-property.component';

@NgModule({
    declarations: [LoginComponent, SetPasswordComponent, SetPropertyComponent],
    imports: [
        CommonModule,
        LayoutModule,
        SharedModule,
        DynamicFormBuilderModule,
        HttpClientModule,
        PlatformModule,
        BrowserModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true,
        },
        PropertySettingDataService
    ],
    exports: [LoginComponent, DynamicFormBuilderModule]
})
export class LoginModule { }
