import { Component, OnInit, Output, EventEmitter, AfterViewInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormArray } from '@angular/forms';
import { PlayerInformationService } from './player-information.service';
import { PlayerInformationBusiness } from './player-information.business';
import { Gender } from './player-information.model';
import { DateInput, AgAddressFieldConfig, AgContactFieldConfig, PhoneDetail, GoogleAddressOutput } from 'src/app/shared/shared-models';
import { ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContactType, PatronInfoSearchResultType, ExistingPlayerTypes } from 'src/app/shared/global.constant';
import { KeyValuePair } from 'src/app/retail/shared/business/shared.modals';
import { GuestService } from 'src/app/guest/guest.service';
import { PlayerProfileDataService } from 'src/app/shared/data-services/golfmanagement/PlayerProfile.data.services';
import { CreatePlayerBusiness } from '../create-player.business';
import { PlayerProfileUI, GuestType } from '../create-player.model';
import { ContactService } from 'src/app/tee-time/tournaments/tournaments-modal/contact/contact.service';
import { SharedService } from 'src/app/shared/shared.service';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { startWith } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Localization } from "src/app/common/localization/localization";
import { SelectEFormsComponent } from 'src/app/common/shared/shared/select-e-forms/select-e-forms.component';
import { DmConfig } from 'src/app/common/Models/common.models';
import { DMConfigDataService } from 'src/app/common/dataservices/datamagine-config.data.service';
import { MatDialog } from '@angular/material/dialog';
import { ButtonValue } from 'src/app/shared/models/button-type.model';
import { ConfirmPlayersPopupComponent } from '../confirm-players-popup/confirm-players-popup.component';
import { PlayersBusiness } from 'src/app/guest/players/players.business';
import { GuestSearchData } from 'src/app/shared/components/ag-player-type-search/golf-search-model';
import { EXTERNALGUESTINTERFACENAME } from 'src/app/common/constants';
import { API as settingsAPI } from 'src/app/settings/system-setup/tee-times/tee-times.modal';

@Component({
  selector: 'app-player-information',
  templateUrl: './player-information.component.html',
  styleUrls: ['./player-information.component.scss'],
  providers: [PlayerInformationBusiness, PlayerInformationService, PlayerProfileDataService, CreatePlayerBusiness,
    ContactService, SharedService]
})
export class PlayerInformationComponent implements OnInit, AfterViewInit {

  captions: any;
  errorMessage: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  playerInfoFormGrp: UntypedFormGroup;
  base64textString: any;
  thumbnailImg: any;
  isImageRemoved: boolean;
  ImageUploaded: any = false;
  titles: any = [];
  genders: Gender[];
  dateInputs: DateInput;
  url: string;
  addressInput: AgAddressFieldConfig;
  phoneInputs: AgContactFieldConfig;
  mailInputs: AgContactFieldConfig;
  phoneInfo: PhoneDetail[];
  mailInfo: any = [];
  showClose = true;
  addressValue;
  imageId: number;
  playerInfoFormGrpValues: any;
  isImageReadOnly: boolean = false;
  isPatronIdAvailable = false;
  playerDataChanged: Subscription;
  isCMSConfigured: boolean = false;
  cmsPatronId: any;
  playerTypes: PlayerProfileUI.PlayerType[];
  rateTypes: PlayerProfileUI.RateType[];
  allRateTypes: PlayerProfileUI.RateType[];
  selectedPlayerType: any;
  selectedRateType: any;
  playerTypesWithRateTypes: any;
  inputData: any;
  filteredCountries: Observable<any>;
  floatLabel: string;
  // defaultSettings: DefaultSettings[] = [];
  dmConfig: DmConfig;
  showViewEform: boolean = false;
  dmResponse: any;
  headerOptions;
  allCaptions: any;
  tableData;
  isGuestRecordPurged: boolean;
  isFromCreateOrCopy: boolean = false;
  validateMail: boolean = true;
  similarProfiles = [];
  searchProfiles = [];
  profileSearchBy: { id: ExistingPlayerTypes; name: string; description: any; }[];
  searchPlaceholder;
  inputType;
  linkProfile: UntypedFormGroup;
  actionButton: ButtonValue;
  searchValue: string;
  isExpand: boolean;
  initialGuestData: any;
  golfGuestData: any;
  isSearchCallCompleted: boolean = false;
  titledropdownInput: { form: UntypedFormGroup; formControlName: string; isdisabled: boolean; placeholderName: string; className: string; };
  clearsimilarSearchValue;
  @Input('isExpand')
  set similarguests(value) {
    this.isExpand = value;
    if (value && this.similarProfiles.length == 0) {
      this.getMatchingGuests();
    }
  }

  @Input('inputData')
  set formData(value) {
    if (value) {
      if (value.id == "" || value.id == undefined) {
        this.isFromCreateOrCopy = true;
      }
      this.initialGuestData = this.initialGuestData ? this.initialGuestData : value;
      this.patchFormValue(value);
    }
  }

  @Input() isPlayerEdited = false;

  @Input() guestId;
  //Added to load data
  @Input('inputCmsPatronId')
  set formCmsPlayerData(value) {
    this.cmsPatronId = value;
  }
  @Input() defaultSettings;
  @Output() PlayerInfoFormEmit = new EventEmitter();
  @Output() imageUpdateEmit = new EventEmitter();
  @Output() convertToEdit = new EventEmitter();
  constructor(private _PlayerInformationBusiness: PlayerInformationBusiness
    , private Form: UntypedFormBuilder
    , private _guestService: GuestService
    , private _utilities: GolfUtilities
    , public _createPlayerBussiness: CreatePlayerBusiness
    , private _contactService: ContactService
    , private _sharedService: SharedService
    , private _featureSwichService: RetailFeatureFlagInformationService, private localization: Localization
    , private dmConfigDataService: DMConfigDataService
    , public dialog: MatDialog
    , private _playersBusiness: PlayersBusiness) {
    this.isCMSConfigured = this._featureSwichService.IsCMSConfigured;
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this._playersBusiness.isExistingGuestSelectedForLinking = false;
    this.captions = this._PlayerInformationBusiness.GetCaptions();
    this.errorMessage = this._PlayerInformationBusiness.GetErrorMessage();
    this.allCaptions = this.localization.captions;
    this.profileSearchBy = this.getProfileSearch();
    this.generateFormGroup();
    this.getTitleList();
    this.getGenders();
    this.loadPlayerTypesRateTypes();
    this._sharedService.loaderEnable.pipe(takeUntil(this.destroyed$)).subscribe(loader => {
      const loadingContainer = document.getElementById('custom-cover-spin');
      const loadingContainerMessage = document.getElementById('custom-cover-message');
      if (loadingContainer && loadingContainerMessage) {
        if (loader) {
          loadingContainer.style.display = 'block';
        }
        else {
          loadingContainer.style.display = 'none';
        }
      }
    });
    this._utilities.geCountriesJSON().then(res => {
      this.filteredCountries = this.playerInfoFormGrp.controls.country.valueChanges.pipe(
        startWith(''),
        debounceTime(100),
        distinctUntilChanged(),
        map((country: string) => country ? this._utilities.FilterCountry(country, this._utilities.countryDetails) : [])
      );
      this.playerInfoFormGrp.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
        if (this.playerInfoFormGrp.controls['city'].value) {
          this.playerInfoFormGrp.controls.country.setErrors({ required: true });
        } else {
          this.playerInfoFormGrp.controls.country.setErrors(null);
        }
        if (this.playerInfoFormGrp.controls['country'].value &&
          !this._utilities.FilterCountryValueFromData(this.playerInfoFormGrp.controls['country'].value)) {
          this.playerInfoFormGrp.controls.country.setErrors({ invalid: true });
        } else if ((this.playerInfoFormGrp.controls['city'].value &&
          this._utilities.FilterCountryValueFromData(this.playerInfoFormGrp.controls['country'].value) &&
          this.playerInfoFormGrp.controls['country'].value) || (!this.playerInfoFormGrp.controls['city'].value &&
            !this.playerInfoFormGrp.controls['country'].value)) {
          this.playerInfoFormGrp.controls.country.setErrors(null);
        }
        this.playerInfoFormGrp.controls['country'].markAsTouched();
      });
    });
    this.initializeInputs();
    this.getDmconfig();
    this.searchPlaceholder = this.allCaptions.searchByFirstName;
    this.actionButton = { type: 'primary', label: this.localization.captions.teetime.search, disabledproperty: true };
    this.linkProfile.get('profiles')?.valueChanges.subscribe(() => {
      this.isSearchCallCompleted = false;
      this.searchProfiles = [];
    });
    this.setDefaultExistingPlayerCategory();
  }

  getProfileSearch() {
    return [
      { id: ExistingPlayerTypes.firstName, name: 'firstName', description: this.captions.firstName },
      { id: ExistingPlayerTypes.lastName, name: 'lastName', description: this.captions.lastName },
      { id: ExistingPlayerTypes.fullName, name: 'fullName', description: this.allCaptions.teetime.fullName },
      { id: ExistingPlayerTypes.email, name: 'email', description: this.captions.email },
      { id: ExistingPlayerTypes.phoneNumber, name: 'phoneNumber', description: this.captions.phoneNo }
    ];
  }

  profileSearchChange(value) {
    this.clearsimilarSearchValue = '';
    this.clearsimilarSearchValue = { ...this.clearsimilarSearchValue };
    switch (value) {
      case ExistingPlayerTypes.firstName:
        this.searchPlaceholder = this.allCaptions.searchByFirstName;
        this.inputType = ''
        break;
      case ExistingPlayerTypes.lastName:
        this.searchPlaceholder = this.allCaptions.searchByLastName;
        this.inputType = ''
        break;
      case ExistingPlayerTypes.fullName:
        this.searchPlaceholder = this.allCaptions.searchByFullName;
        this.inputType = ''
        break;
      case ExistingPlayerTypes.email:
        this.searchPlaceholder = this.allCaptions.searchByEmail;
        this.inputType = ''
        break;
      case ExistingPlayerTypes.phoneNumber:
        this.searchPlaceholder = this.allCaptions.searchByPhoneNumber;
        this.inputType = 'onlynumber'
        break;
      default:
        break;
    }
  }

  async onProfileSelection(profile, array) {
    var newProfileSelected = !this.golfGuestData || profile.basicInformation.id != this.golfGuestData.id;
    array.forEach(element => {
      element.isSelected = false;
      if (newProfileSelected)
        element['selectedGuestType'] = GuestType.GolfGuest;
    });
    profile.isSelected = !profile.isSelected;
    if (profile.isSelected) {
      this.dialog.open(ConfirmPlayersPopupComponent, {
        width: '20%',
        height: '27%',
        disableClose: true,
        data: profile.selectedGuestType
      })
        .afterClosed().subscribe(async res => {
          if (res > 0) {
            profile.selectedGuestType = res;
            if (res == GuestType.GolfGuest) {
              if (newProfileSelected) {
                this.golfGuestData = await this._playersBusiness.GenerateFormData(profile.basicInformation.id);
                this.mapInterfaceDetails(this.golfGuestData);
              }
              this.convertToEdit.emit(this.golfGuestData);
              newProfileSelected = false;
            }
            if (res == GuestType.OperaGuest) {
              this.initialGuestData.id = profile.basicInformation.id;
              this.convertToEdit.emit(this.initialGuestData);
            }
            this._playersBusiness.isExistingGuestSelectedForLinking = true;
          }
        })
    }
  }

  mapInterfaceDetails(guestData: any) {
    var ExternalInterface = this.inputData.interfaces?.find(i => i.name === this.localization.GetPropertyConfig(EXTERNALGUESTINTERFACENAME));
    guestData.interfaces = guestData.interfaces?.filter(i => i.name != this.localization.GetPropertyConfig(EXTERNALGUESTINTERFACENAME));
    if (ExternalInterface)
      guestData.interfaces.push(ExternalInterface);
  }

  searchValueChange(e) {
    if (e.length >= 3) {
      this.actionButton.disabledproperty = false;
      this.searchValue = e;
    }
    else {
      this.actionButton.disabledproperty = true;
    }
  }

  async searchProfile() {
    this._utilities.ToggleLoader(true);
    this.searchProfiles = await this._playersBusiness.SearchPlayerProfileByKeyAndValue(this.linkProfile.get('searchBy').value, this.searchValue, true, false, false);
    this.searchProfiles = await this._playersBusiness.filterAlreadyLinkedGuests(this.searchProfiles);
    this.isSearchCallCompleted = true;
    this._utilities.ToggleLoader(false);
  }

  async getMatchingGuests() {
    this._utilities.ToggleLoader(true);
    this.similarProfiles = await this._playersBusiness.GetMatchingGuests(this.buildGuestSearchData());
    this.similarProfiles = await this._playersBusiness.filterAlreadyLinkedGuests(this.similarProfiles);
    this._utilities.ToggleLoader(false);
  }

  buildGuestSearchData(): GuestSearchData {
    var bodyObj: GuestSearchData = {
      firstName: this.inputData.firstName,
      lastName: this.inputData.lastName,
      phoneNumber: this.phoneInfo.map(p => this._utilities.removePhoneFormat(p.phoneNumber)),
      emailAddress: this.mailInfo.map(m => m.emailId)
    }
    return bodyObj;
  }

  ngAfterViewInit() {
    this.playerInfoFormGrp.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      this.playerInfoFormGrp.markAllAsTouched();
      this.PlayerInfoFormEmit.emit([this.playerInfoFormGrpValues, (this.playerInfoFormGrp.valid && this.playerInfoFormGrp.dirty), 'playerInfo', this.playerInfoFormGrp]);
    });
    this.playerInfoFormGrp.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      data.base64textString = this.base64textString;
      data.thumbnailImg = this.thumbnailImg;
      data['imageId'] = this.imageId;
      data.isImageRemoved = this.isImageRemoved;
      this.playerInfoFormGrpValues = data;
    });

    if (this.cmsPatronId && this.playerInfoFormGrp) {
      this.playerInfoFormGrp.controls.patronid.setValue(this.cmsPatronId);
      this._createPlayerBussiness.patronLookup(this.cmsPatronId, this.searchPatronCallBack.bind(this));
    }
  }

  async getDmconfig() {
    this.dmConfig = await this.dmConfigDataService.getDataMagineConfig();
    if (this.dmConfig && this.dmConfig.enableDataMagine) {
      let isEformsEnabled = this.dmConfig?.dmEformsConfig?.enableEforms ?? false;
      if (isEformsEnabled && this.inputData) {
        this.showViewEform = true;
      }
    }
  }
  generateFormGroup() {
    this.playerInfoFormGrp = this.Form.group({
      firstName: ['', [Validators.required, Validators.pattern(/(?!\s*$)/gm)]],
      lastName: ['', [Validators.required, Validators.pattern(/(?!\s*$)/gm)]],
      pronounced: '',
      dob: '',
      pincode: '',
      title: '',
      gender: '',
      playerImg: this.Form.group({
        base64textString: '',
        thumbnailImg: ''
      }),
      phone: this.Form.array([
        this.Form.group({
          phoneType: this?.defaultSettings && this.defaultSettings?.defaultPhoneTypeOption ? this?.defaultSettings?.defaultPhoneTypeOption : '',
          contactType: ContactType.phone,
          phoneNumber: '',
          primaryPhone: false,
          privateInformtionPhone: false,
          platformContactUuid: '',
          countryCode: this?.defaultSettings && this.defaultSettings?.defaultCountryCode ? this?.defaultSettings?.defaultCountryCode : '',
        })
      ]),
      email: this.Form.array([
        this.Form.group({
          emailType: this?.defaultSettings && this.defaultSettings?.defaultEmailTypeOption ? this?.defaultSettings?.defaultEmailTypeOption : '',
          contactType: ContactType.email,
          emailId: '',
          primaryEmail: false,
          privateInformtionEmail: false,
          platformContactUuid: ''
        })
      ]),
      addressId: 0,
      address: this.Form.array([
        this.Form.group({
          addressDetails: ''
        })
      ]),
      addressList: [],
      privateAddress: false,
      state: '',
      city: '',
      country: '',
      platformAddressUuid: '',
      interfaces: '',
      postal_code: '',
      imageReferenceId: '',
      patronid: '',
      rank: '',
      playerType: '',
      rateType: ''
    });
    this.addressInput = {
      className: 'golf-form-control--lg',
      form: this.playerInfoFormGrp,
      formControlName: 'addressDetails',
      automationId: "PlayerInformation"
    };
    this.mailInputs = {
      form: this.playerInfoFormGrp,
      formArrayName: 'email',
      showSwitches: true,
      automationId: "PlayerInformation"
    };
    this.phoneInputs = {
      form: this.playerInfoFormGrp,
      formArrayName: 'phone',
      showSwitches: true,
      automationId: "PlayerInformation"
    };
    this.linkProfile = this.Form.group({
      profiles: 1,
      searchBy: 0
    });
  }

  getTitleList() {
    this.titles = [{ id: 1, value: 'Dr', viewValue: 'Dr' }, { id: 2, value: 'Fr', viewValue: 'Fr' }, { id: 3, value: 'Miss', viewValue: 'Miss' },
    { id: 4, value: 'Mr', viewValue: 'Mr' }, { id: 5, value: 'Mrs', viewValue: 'Mrs' }, { id: 6, value: 'Ms', viewValue: 'Ms' },
    { id: 7, value: 'Prof', viewValue: 'Prof' }, { id: 8, value: 'Rev', viewValue: 'Rev' }, { id: 9, value: 'Mx', viewValue: 'Mx' }];
  }

  getGenders() {
    this.genders = this._PlayerInformationBusiness.GetGenders();
  }
  initializeInputs() {
    this.dateInputs = {
      className: 'golf-form-control--xs',
      form: this.playerInfoFormGrp,
      formControlName: 'dob',
      placeHolder: this.captions.dateOfBirth,
      maxDate: this._PlayerInformationBusiness.getDate(),
      automationId: "PlayerInformation"
    };
    this.titledropdownInput={
      form: this.playerInfoFormGrp,
      formControlName: 'title',
      isdisabled:false,
      placeholderName:this.captions.CTitle,
      className:'ag_w--100 ag_ml--1'
    }
  }

  fileUploaded(data) {
    this.base64textString = data['orgImg'];
    this.thumbnailImg = data['tmbImg'];
    this.imageId = data['imageID'];
    this.isImageRemoved = false;
    this.playerInfoFormGrp.controls.playerImg.patchValue({
      base64textString: data['orgImg'],
      thumbnailImg: data['tmbImg']

    });
    this.imageUpdateEmit.emit(this.playerInfoFormGrp.valid);


  }

  fileDeleted() {
    this.isImageRemoved = true;
    this.ImageUploaded = false;
    this.playerInfoFormGrp.controls.playerImg.patchValue({
      base64textString: '',
      thumbnailImg: ''
    });
    this.imageUpdateEmit.emit(this.playerInfoFormGrp.valid);
  }

  patchFormValue(data) {
    console.log('patchValue ', data);
    if (data) {
      this.inputData = data;
      if (this.playerInfoFormGrp) {
        if (data && data.patronid) {
          this.isPatronIdAvailable = true;
        }
        data.title = data?.title && data.title !== "" ? data.title :"";
        if(this.isPlayerEdited && data.phone.length > 0 && data.email.length > 0) {
          let phoneData = this.playerInfoFormGrp.get('phone') as FormArray;
          phoneData.clear();
          let mailData = this.playerInfoFormGrp.get('email') as FormArray;
          mailData.clear();
        }
        this.playerInfoFormGrp.patchValue(data);
        this.phoneInfo = data.phone;
        this.mailInfo = data.email;
        this.addressValue = data.address;

        this.imageId = data['imageId'];
        if (data.url == ',')
          this.ImageUploaded = false;
        else
          this.url = data.url;

        if (data.interfaceGuestId == '-1') {
          this.isImageReadOnly = true;
        }
        else {
          this.isImageReadOnly = false;
        }
      }
      if (this.playerTypes) {
        this.selectedPlayerType = this.inputData && this.inputData?.playerType > 0 ? this.playerTypes.find(y => y.id == this.inputData.playerType) : null;
        if (this.selectedPlayerType) {
          this.playerInfoFormGrp.controls.playerType.setValue(this.selectedPlayerType);
          this.rateTypes = this.playerTypesWithRateTypes.find(x => x.id == this.selectedPlayerType.id).rateTypes;
        }
      }
      if (this.rateTypes) {
        this.selectedRateType = this.inputData && this.inputData?.rateType > 0 ? this.rateTypes.find(z => z.id == this.inputData.rateType) : null;
        if (this.selectedRateType) {
          this.playerInfoFormGrp.controls.rateType.setValue(this.selectedRateType);
        }
      }
      if (data.isPurged) {
        this.playerInfoFormGrp.disable();
        this.isGuestRecordPurged = true;
      }
      this.playerInfoFormGrp.markAsPristine();
      if (!this.isPlayerEdited) {
        this.playerInfoFormGrp.markAsDirty();
        this.playerInfoFormGrp.updateValueAndValidity();
      }
    }
  }

  closeWarning() {
    this.showClose = false;
  }

  addresschange(address: GoogleAddressOutput): void {
    if (this.playerInfoFormGrp && address) {
      this.playerInfoFormGrp.controls.state.setValue(address.state);
      this.playerInfoFormGrp.controls.city.setValue(address.city);
      this.playerInfoFormGrp.controls.country.setValue(address.country);
      this.playerInfoFormGrp.controls.postal_code.setValue(address.zipCode);
    }
  }

  playerWorthDetails(event) {
    this._guestService.openDialogPopup(this.playerInfoFormGrp.controls.patronid.value);
  }


  searchPatron() {
    let patronId = this.playerInfoFormGrp.controls.patronid.value;
    if (patronId && this.isCMSConfigured) {
      this._createPlayerBussiness.patronLookup(patronId, this.searchPatronCallBack.bind(this));
    }
  }

  searchPatronCallBack(result, data) {
    if (result == PatronInfoSearchResultType.EDITEXISTINGPATRON) {
      this.convertToEdit.emit(data);
    }
    else if (result == PatronInfoSearchResultType.PATRONNOTFOUND) {
      this.isPatronIdAvailable = false;
      this.playerInfoFormGrp.controls.patronid.setValue('');
      this.playerInfoFormGrp.controls.patronid.markAsDirty();
    }
    else if (result == PatronInfoSearchResultType.PATRONFOUND) {
      this.playerInfoFormGrp.controls.rank.setValue(data[0].playerRank);
      this.isPatronIdAvailable = true;
    }
    else if (result == PatronInfoSearchResultType.UPDATECMSDATAONEXISTING) {
      this.playerInfoFormGrp.controls.firstName.setValue(data[0].firstName);
      this.playerInfoFormGrp.controls.lastName.setValue(data[0].lastName);
      this.playerInfoFormGrp.controls.pronounced.setValue(data[0].pronounced);
      this.playerInfoFormGrp.controls.rank.setValue(data[0].playerRank);
      this.playerInfoFormGrp.controls.dob.setValue(data[0].dateOfBirth);
      let isFemale = data[0].gender == 'F' ? 'Female' : '';
      this.playerInfoFormGrp.controls.gender.setValue(data[0].gender == 'M' ? 'Male' : isFemale)
      if (data[0].address) {
        this.addressValue = [{ addressDetails: data[0].address.addressLine1 }];
        this.playerInfoFormGrp.controls.postal_code.setValue(data[0].address.postalCode);
        this.playerInfoFormGrp.controls.state.setValue(data[0].address.state);
        this.playerInfoFormGrp.controls.city.setValue(data[0].address.city);
        this.playerInfoFormGrp.controls.country.setValue(data[0].address.country);
      }
      this.phoneInfo = this.mapPhone(data[0].phone)
      this.mailInfo = this.mapEmail(data[0].email)
      this.isPatronIdAvailable = true;
    }
    this.clearPatronValidationError()
  }

  mapEmail(cmsEmail): any {
    let emailArray: PlayerProfileUI.Email[] = [];
    var mailTypes = this._contactService.getEmailType();
    cmsEmail.forEach(e => {
      const mail = mailTypes.filter(x => x.id == e.emailTypeId)[0];
      emailArray.push({
        id: e.id ? e.id : 0,
        contactType: mail && mail.description,
        emailType: e.emailTypeId,
        emailLabel: String(e.emailTypeId),
        emailId: e.emailAddress,
        privateInformtionEmail: false,
        primaryEmail: false,
        platformContactUuid: e.platformContactUuid ? e.platformContactUuid : ''
      })
    });

    return emailArray;
  }

  mapPhone(cmsPhone): any {
    let phoneArray: PlayerProfileUI.Phone[] = [];

    var phoneTypes = this._contactService.getPhoneType();
    cmsPhone.forEach(e => {
      let number = (e.countryCode ? e.countryCode : '') + '|' + e.extension + e.phoneNumber
      phoneArray.push({
        id: e.id ? e.id : 0,
        contactType: phoneTypes.filter(x => x.id == e.phoneTypeId)[0]?.description,
        phoneType: e.phoneTypeId,
        phoneLabel: String(e.phoneTypeId),
        countryCode: this._utilities.getCountryCodeFromValue(e.phoneTypeId, number).toString(),
        phoneNumber: this._utilities.getPhoneNumberFromValue(e.phoneTypeId, number).toString(),
        extension: e.extension,
        privateInformtionPhone: false,
        primaryPhone: e.isPrimary,
        platformContactUuid: e.platformContactUuid ? e.platformContactUuid : ''
      })
    });
    return phoneArray;

  }


  checkPatronValidation() {
    let patronValue = this.playerInfoFormGrp.controls.patronid.value;
    if (patronValue) {
      this.playerInfoFormGrp.controls.patronid.setValidators(Validators.required);
      if (!this.isPatronIdAvailable) {
        this.playerInfoFormGrp.controls.patronid.setErrors({ invalid: true });
      }
    } else {
      this.playerInfoFormGrp.controls.patronid.clearValidators();
      this.playerInfoFormGrp.controls.patronid.updateValueAndValidity();
    }
  }

  clearPatronValidationError() {
    this.playerInfoFormGrp.controls.patronid.clearValidators();
    this.playerInfoFormGrp.controls.patronid.updateValueAndValidity();
  }

  firstNameValidation() {
    this.playerInfoFormGrp.get('firstName').setValue(this.playerInfoFormGrp.get('firstName').value.trim())
  }
  lastNameValidation() {
    this.playerInfoFormGrp.get('lastName').setValue(this.playerInfoFormGrp.get('lastName').value.trim())
  }


  comparetSelects = (val1, val2) => {
    return val1 && val2 && val1.id === val2.id;
  }

  rateTypeChange(arg) {
    const selectedOption = arg.value;
    this.selectedRateType = selectedOption;
  }
  playerTypeChange(arg) {
    this.rateTypes = [];
    if (arg.value) {
      const selectedOption = arg.value;
      this.selectedPlayerType = selectedOption;
      this.playerInfoFormGrp.controls.rateType.reset();
      this.rateTypes = this.playerTypesWithRateTypes.find(x => x.id == this.selectedPlayerType.id).rateTypes;
    }
    else {
      this.rateTypes = this.allRateTypes;
    }

  }
  async loadPlayerTypesRateTypes() {
    this.playerTypes = await this._createPlayerBussiness.getPlayerTypes();
    this.rateTypes = await this._createPlayerBussiness.getRateTypes();
    this.allRateTypes = this.rateTypes
    if (!this.playerTypesWithRateTypes)
      this.playerTypesWithRateTypes = await this._createPlayerBussiness.getAllPlayerTypeWithRateType();
    if (this.playerTypes) {
      this.selectedPlayerType = this.inputData && this.inputData?.playerType > 0 ? this.playerTypes.find(y => y.id == this.inputData.playerType) : null;
      if (this.selectedPlayerType) {
        this.playerInfoFormGrp.controls.playerType.setValue(this.selectedPlayerType);
        this.rateTypes = this.playerTypesWithRateTypes.find(x => x.id == this.selectedPlayerType.id).rateTypes;
      }
    }
    if (this.rateTypes) {
      this.selectedRateType = this.inputData && this.inputData?.rateType > 0 ? this.rateTypes.find(z => z.id == this.inputData.rateType) : null;
      if (this.selectedRateType) {
        this.playerInfoFormGrp.controls.rateType.setValue(this.selectedRateType);
      }
    }
  }

  openEForms() {
    let res = this.dialog.open(SelectEFormsComponent, {
      width: '85%',
      height: '85%',
      hasBackdrop: true,
      data: {
        guestId: this.guestId
      }
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(s => {

    });
  }
  async setDefaultExistingPlayerCategory() {
    let defaultsApiData: settingsAPI.DefaultsSettingConfig = JSON.parse(sessionStorage.getItem('defaultSettings'));
    this.linkProfile.controls.searchBy.setValue(defaultsApiData.defaultExistingPlayerCategory);
    this.profileSearchChange(defaultsApiData.defaultExistingPlayerCategory);
  }
}