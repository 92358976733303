
import { Injectable } from '@angular/core';
import { Course } from 'src/app/settings/golf-setup/code-setup/course/create-course-modal/create-course-model';
import { GolfManagementCommunication } from 'src/app/shared/communication/services/golfmanagement.service';



@Injectable({
    providedIn: 'root'
})
export class CourseDataService {

  constructor(private _golfManagementCommunication: GolfManagementCommunication) { 
  }

  public  getCourses(IncludeInActive:boolean = false, filterUserAccessedCourse: boolean = false): Promise<Course[]> {
    return  this._golfManagementCommunication.getPromise<Course[]>(
              { route: GolfApiRoute.GetCoursesWithAccess, uriParams: {"includeInactive": IncludeInActive, filterUserAccessedCourse: filterUserAccessedCourse }}, false);         
  }
  public  getCourse(id:number): Promise<Course> {
    return  this._golfManagementCommunication.getPromise<Course>(
              { route: GolfApiRoute.GetCourse, uriParams: {"id": id }}, false);
        
  }
  public  createCourse(body: Course): Promise<Course[]> {
    return  this._golfManagementCommunication.postPromise<Course[]>(
            { route: GolfApiRoute.CreateCourse, body: body }, true);
       
  }
  public  updateCourse(body: Course): Promise<Course[]> {
    return  this._golfManagementCommunication.putPromise<Course[]>(
            { route: GolfApiRoute.UpdateCourse, body: body }, false);
       
  }
  public  deleteCourse(id : number): Promise<Course[]> {
    return  this._golfManagementCommunication.deletePromise<Course[]>(
            { route: GolfApiRoute.DeleteCourse, uriParams: { "id": id }}, false);
       
  }
  public async getNextListOrder(): Promise<number> {
    return await this._golfManagementCommunication.getPromise<number>(
        { route: GolfApiRoute.GetNextCourseListOrder  });
   }
}
