
export const TeaPot: string = "I'm a teapot!";

declare global {
    /**
     * returns a nameof the given property
     * Throws compile time error if the given property does not belongs to specified T
     * @param name Property name of T
     */
    export function nameof<T>(name: keyof T);

    interface Window {
        nameof<T>(name: keyof T);
    }
}

Window.prototype.nameof = <T>(name: keyof T) => name;