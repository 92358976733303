<section class="agilysys-error-popup product-popup-container">
    <header class="product-popup-header">
      <h1 class="product-header-3" mat-dialog-title>{{data.PopupHeaderContent}}</h1>
      <i aria-hidden="true" class="icon-requisition-denied-eatec product-popup-icon" (click)="dialogRef.close('cancel');"></i>
    </header>
    <main mat-dialog-content class="product-popup-content">
        {{data.PopUpContent}}  <button class="error-information-btn"  *ngIf="data.EnableInfo" (click)="showErrorInfo()" mat-button color="primary">Show {{ data.InfoBtnState }}</button>
        <p class="agilysys-pop-error-message" *ngIf="data.EnableInfo && VisibleInfoMessage">{{data.InfoMessage}}</p>
    </main>
    <div *ngIf="data.PopUpContent === null || data.PopUpContent === ''">
        <div class='no-items'>
          <div class='no-items-content'>
            <span class='no-items-icon'>
              <img src="assets/images/noitems.svg" alt="No Items Found" class="noItemImg">
            </span>
            <span class='no-items-msg'>{{'Common.Nomemoavailabletoshow' | translate}}</span>
          </div>
        </div>
      </div>
    <footer  class="product-popup-footer">
        <app-agilysys-button  [AgilysysButton]="agilysyserrorlogButton"></app-agilysys-button>
    </footer>
</section>