<ng-container *ngIf="players?.length;else empty_card">
  <section [ngClass]="{'player-disable':(!player.hasUserAccess)||isDisable}"
    [class]="player.isValid ? 'player-card' : 'player-card player-card--invalid' "
    *ngFor="let player of players; let idx= index; trackBy: trackByFn">
    <div class="player-card-player">{{player.sequenceNo}}</div>
    <ng-container *ngIf="player && player.isPaidPlayer"><div class="paidsec"></div><span class="paidsectext">{{globCaption.Paid}}</span></ng-container>
    <div class="player-card-info">
      <div *ngIf="!player.isBlocked;else block_content" class="player-card-info-detail">
        <span class="name">{{player | formatName: 'firstName': 'lastName'}}</span>
        <span class="card-detail" *ngIf="player.phoneNo">
          <i class='icon-Call' aria-hidden="true">&nbsp;</i>
          <span *ngIf="player.countryCode" class="mr-1">{{player.countryCode}} - </span>
          <span> {{player.phoneNo}} </span>
          <span *ngIf="player.extension" class="ml-1"> : {{player.extension}}</span>
        </span>
        <span class="card-detail" *ngIf="player.emailId">
          <i class='icon-mail' aria-hidden="true">&nbsp;</i>
          <span>{{player.emailId}}</span>
        </span>
        <span class="card-detail" *ngIf="player.vipType">
          <i class='icon-vip' aria-hidden="true">&nbsp;</i>
          <span>{{player.vipType}}</span>
        </span>
        <span class="card-detail" *ngIf="player.membershipNo">
          <i class='icon-Member-new' aria-hidden="true">&nbsp;</i>
          <span>{{player.membershipNo}}</span>
        </span>
        <div class="player-card-info-position">
          <span class="confirmation-section">{{player.confirmationNo}}
            <span *ngIf="player.isPaidPlayer||player.isMarkAsPaid"><span
                matTooltip="{{_localization.captions.teetime.Paid}}"><i class="ml-2"
                  [ngClass]="player.isMarkAsPaid?'icon-Mark-Paid':''"></i></span></span><!--icon-rate-->
          </span>
          <span *ngIf="player.id > 0 && player.playerPosition" class="player-position">{{captions.position}}
            {{player.playerPosition}}</span>
        </div>
        <div class="player-card-info-detail-holes"
          [ngClass]="{'holes-background':player.isTeeTimeBooked && isFromTournament}">
          <span>{{player.holes}}</span>
          <span>{{captions.Holes}}</span>
        </div>
      </div>
      <ng-template #block_content>
        <div class="player-card-info-detail">
          <div class="block-content">{{lblBlocked}}</div>
        </div>
      </ng-template>
      <div class="player-card-info-action">
        <span class="ratetype-section" title="{{player.rateType}}">{{player.rateType}}</span>
        <section *ngIf="!player.isBlocked;else block_action" class="player-card-info-action-icons ag_display--flex">
          <button attr.automationId='Btn_{{automationId}}_playerWorth' mat-button (click)="playerWorthDetails($event, player.patronid)" class="player-worth cursor"
            [ngClass]="!player.patronid ? 'button_invalid' : ''" title="{{captions.PlayerWorth}}">
            <ng-container *ngIf="player.patronid">
              <span class="icon-Player-worth_enabled"><span class="path1"></span><span class="path2"></span></span>
            </ng-container>
            <ng-container *ngIf="!player.patronid">
              <span class="icon-Player-worth_disabled"><span class="path1"></span><span class="path2"></span></span>
            </ng-container>
          </button>
          <i aria-hidden="true" class="icon-gratuity" matTooltip="{{globCaption.Pay}}"
            [ngClass]="{'disabled': (player.id == 0 || player.isPaidPlayer|| player.isMarkAsPaid || player.paymentReferenceId == 0 || !payEnabled),'enabled': payEnabled }" *ngIf=isFromTournament></i>
          <i attr.automationId='Btn_{{automationId}}_payForPlayer' aria-hidden="true" class="icon-gratuity" matTooltip="{{globCaption.Pay}}"
            [ngClass]="{'disabled':!isEdit || player.id == 0 || player.isPlayerEditable || player.isDisabled || player.isPaidPlayer|| player.isMarkAsPaid || !payEnabled, 'enabled':payEnabled}"
            (click)="payForPlayer(player)" *ngIf=!isFromTournament></i>
          <i attr.automationId='Btn_{{automationId}}_copyPlayer' aria-hidden="true" class="icon-copy" title="{{captions.copy}}"
            [ngClass]="{'disabled':player.playerStatus == noShow || player.isPlayerEditable || player.isDisabled}"
            (click)="copyPlayer(player, idx)"></i>
          <ng-container *ngIf=!isFromTournament>
            <ng-container *ngFor="let menu of matMenu">
              <i attr.automationId='Btn_{{automationId}}_edit' aria-hidden="true" class="icon-edit" title="{{captions.edit}}"
                (click)="moreOptionList(menu,player, idx)" [ngClass]="{'disabled':menu.isDisabled || player.isDisabled  }"
                *ngIf="menu.id==0"></i>
              <i attr.automationId='Btn_{{automationId}}_clear' aria-hidden="true" class="icon-clear-players" title="{{captions.clear}}"
                (click)="moreOptionList(menu,player, idx)"
                [ngClass]="{'disabled': menu.isDisabled || player.isPlayerEditable || player.isDisabled }"
                *ngIf="menu.id==1"></i>
            </ng-container>
          </ng-container>
          <ng-container *ngIf=isFromTournament>
            <ng-container *ngFor="let menu of matMenu">
              <i attr.automationId='Btn_{{automationId}}_edit' aria-hidden="true" class="icon-edit" title="{{captions.edit}}"
                (click)="moreOptionList(menu,player, idx)" [ngClass]="{'disabled':menu.isDisabled || player.isDisabled}"
                *ngIf="menu.id==1"></i>
              <i attr.automationId='Btn_{{automationId}}_clear' aria-hidden="true" class="icon-clear-players" title="{{captions.clear}}"
                (click)="moreOptionList(menu,player, idx)"
                [ngClass]="{'disabled':menu.isDisabled || player.isPlayerEditable || player.isDisabled}"
                *ngIf="menu.id==2"></i>
              <i attr.automationId='Btn_{{automationId}}_cancel' aria-hidden="true" class="icon-cancel-players" title="{{captions.cancel}}"
                (click)="moreOptionList(menu,player, idx)"
                [ngClass]="{'disabled':menu.isDisabled || player.isPlayerEditable || player.isDisabled}"
                *ngIf="menu.id==3"></i>
            </ng-container>
          </ng-container>
        </section>
        <ng-template #block_action>
          <section class="player-card-info-action-icons">
            <ng-container *ngIf=!isFromTournament>
              <ng-container *ngFor="let menu of matMenu">
                <i attr.automationId='Btn_{{automationId}}_clear' aria-hidden="true" class="icon-clear-players" title="{{captions.clear}}"
                  (click)="moreOptionList(menu,player, idx)"
                  [ngClass]="{'disabled':menu.isDisabled || player.isPlayerEditable || player.isDisabled}"
                  *ngIf="menu.id==1"></i>
              </ng-container>
            </ng-container>
            <ng-container *ngIf=isFromTournament>
              <ng-container *ngFor="let menu of matMenu">
                <i attr.automationId='Btn_{{automationId}}_clear' aria-hidden="true" class="icon-clear-players" title="{{captions.clear}}"
                  (click)="moreOptionList(menu,player, idx)"
                  [ngClass]="{'disabled':menu.isDisabled || player.isPlayerEditable || player.isDisabled}"
                  *ngIf="menu.id==2"></i>
              </ng-container>
            </ng-container>
          </section>
        </ng-template>
      </div>
    </div>
  </section>
</ng-container>

<ng-template #empty_card>
  <section class="player-card player-card--empty">
    {{captions.noDataAvailable}}
  </section>
</ng-template>

<mat-menu #appMenu="matMenu" xPosition="before">
  <button attr.automationId='Btn_{{automationId}}_more' mat-menu-item *ngFor="let menu of matMenu" [disabled]="menu.isDisabled"
    (click)="moreOption(menu)">{{menu.name}}</button>
</mat-menu>