import { Injectable } from '@angular/core';
import { GolfLocalization } from '../../core/localization/golf-localization';
import { ScheduledTeeTimeUnPaidPlayer } from '../../retail/shared/shared.modal';
import { TeeSheetsDataService } from '../../shared/data-services/golfschedule/teesheets.data.service';
import { UnPaidPlayersBusiness } from '../../shared/data-services/golfschedule/unpaid-players.business';
import { TeeTimeAction, TeeTimeDetail } from '../../shared/models/teesheet.api.models';
import { TeeSheetMapper } from '../../tee-time/tee-sheet/business/tee-sheet.mapper';
import _ from 'lodash';
import { AlertType, ButtonType, CheckboxTableHeader } from '../../shared/shared-models';
import { GolfUtilities } from '../../shared/utilities/golf-utilities';
import { TeeTimeFormat } from '../../shared/models/teesheet.form.models';
import { TeeTimesActionService } from '../../shared/data-services/golfschedule/teeTimesAction.data.service';
import { MatDialog } from '@angular/material/dialog';
import { PlayerPaymentstatus } from 'src/app/tee-time/search/search-model';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';

@Injectable()
export class CheckInOutUndoCheckInOutModalBussiness {
  private mapper: TeeSheetMapper;
  constructor(private _teeSheetDataService: TeeSheetsDataService
    , private dialog: MatDialog
    , private _unPaidPlayersBusiness: UnPaidPlayersBusiness
    , private localization: GolfLocalization
    , private _utils: GolfUtilities
    , private _teeTimeActionService: TeeTimesActionService
    , private _PropertyInformation:RetailPropertyInformation
  ) {

  }
  GetHeaderOption(flagValue: boolean = false): CheckboxTableHeader[] {
    let checkbox: CheckboxTableHeader[];
    checkbox = [
      {
        key: 'playerPosition',
        description: this.localization.captions.teetime.playerPosition,
        alignment: 'textLeft'
      }, 
      {
        key: 'playerName',
        description: this.localization.captions.teetime.playerName,
        alignment: 'textLeft'
      },
      {
        key: 'scheduledDateTime',
        description: this.localization.captions.teetime.teetime,
        alignment: 'textLeft'
      }, 
      {
        key: 'playerType',
        description: this.localization.captions.teetime.playerType,
        alignment: 'textLeft'
      }, 
      {
        key: 'rateType',
        description: this.localization.captions.teetime.rateType,
        alignment: 'textLeft'
      },
      {
        key: 'course',
        description: this.localization.captions.teetime.course,
        alignment: 'textLeft'
      }, 
      {
        key: 'packageCode',
        description: this.localization.captions.teetime.packageCode,
        alignment: 'textLeft'
      },
      {
        key: 'walk',
        description: this.localization.captions.teetime.walk,
        alignment: 'textLeft'
      }, 
      {
        key: 'trail',
        description: this.localization.captions.teetime.trail,
        alignment: 'textLeft'
      }, 
      {
        key: 'holes',
        description: this.localization.captions.teetime.holes,
        alignment: 'textLeft'
      }];
    
      const memberConfig = this._PropertyInformation.getMemberConfig;
      if(memberConfig && memberConfig.applicableCorporates!= null && memberConfig.applicableCorporates != '')
      {
        checkbox.splice(7,0,{ key: 'corpName',description: this.localization.captions.teetime.corpName,alignment: 'textLeft' });
      }
    if (flagValue) {
      checkbox.push({
        key: 'blockByUser',
        description: this.localization.captions.teetime.blockByUser,
        alignment: 'textLeft'
      }, {
        key: 'action',
        description: this.localization.captions.teetime.action,
        alignment: 'textCenter',
        templateName: "custom"
      })
    }
    return checkbox;
  }
  /**
   * UpdatePlayerCheckInOUtStatus Method to trigger API call to update the check In/Out status
   * @param data
   * @param action
   */
  async UpdatePlayerCheckInOutStatus(data, lstPlayerId: number[]) {
    if (lstPlayerId && lstPlayerId?.length > 0) {
      return await this._teeTimeActionService.checkInOutStatus(data.dialogData.teeTimeType, lstPlayerId);
    }
  }
  /**
   * GetPlayers method to Fetch Player details
   * @param data
   * @param callBackFn
   */
  async GetPlayers(data, action: TeeTimeAction, callBackFn, teeTimeSettings): Promise<ScheduledTeeTimeUnPaidPlayer[]> {    
    let teeSheetData$ = this._teeSheetDataService.getTeeTimes(data.course.id, data.time);
    var unpaidPlayers$ = this._unPaidPlayersBusiness.getPlayersPaidUnPaid(data.course.id, this.localization.convertDateTimeToAPIDateTime(data.time), action);
    const responses = await Promise.all([teeSheetData$, unpaidPlayers$]);
    let teeSheetData = responses[0];
    let unpaidPlayers = responses[1];
    this.UpdatePlayerPosition(teeSheetData, unpaidPlayers);
    let playerLinkIds = [];
    let linkIds = [];
    unpaidPlayers.forEach(element => { element.scheduledTeeTimePlayerId = element.id;});
    data.playerDetail.map(x => {
        if (x.playerCategoryId === 3) {
          linkIds.push(x.linkingId);
          playerLinkIds = this.getDistinctMember(linkIds);
        } else {
          linkIds.push(x.imageReferenceId);
          playerLinkIds = this.getDistinctGUIDs(linkIds);
        }        
    });
    unpaidPlayers = unpaidPlayers.filter(element => {
      if ((element.playerStatus & PlayerPaymentstatus.noShow) == 0) {
        element.id = element.playerId;
        element.isHold = false;
        element.isReleaseAllow = false;
        element.checked =  (teeTimeSettings?.includePlayersAcrossCoursesForCheckIn && playerLinkIds?.length > 0) ? 
                            (playerLinkIds.includes(element.playerLinkId)) : (element.scheduledTeeTimeId == data.scheduledTeeTimeId);
        return element;
      }
    });
    let players$ = unpaidPlayers.filter(x => x.scheduledTeeTimeId === data.scheduledTeeTimeId);
    let filteredObj = players$?.find(x => x.isTournamentPlayersUnPaid && x.tournamentId > 0);
    if (filteredObj) {
      let res = this._utils.showAlert(this.localization.captions.teetime.NoShowForBulkBookingConfirmationMsg, AlertType.Info, ButtonType.YesNo, callBackFn, [unpaidPlayers, filteredObj.tournamentId]);
      await res.afterClosed().toPromise();
    }
    let bulkFilteredObj = data.playerDetail && unpaidPlayers.filter(x => x.teeTimeFormat === TeeTimeFormat.BulkTee && data.playerDetail.map(s => s.confirmationNumber).includes(x.confirmationNumber));
    if (bulkFilteredObj && bulkFilteredObj.length) {
      let res = this._utils.showAlert(this.localization.captions.teetime.BulkBookActionAlert, AlertType.Info, ButtonType.YesNo, callBackFn, [unpaidPlayers, bulkFilteredObj]);
      await res.afterClosed().toPromise();
    }
    if (data.schedulePlayerID && !data.isFromPopup && bulkFilteredObj && !bulkFilteredObj.length) {
      unpaidPlayers = unpaidPlayers.filter(p => p.playerId == data.schedulePlayerID);
    } else if (data.isFromPopup) {
      unpaidPlayers = unpaidPlayers.filter(p => p.scheduledTeeTimeId == data.scheduledTeeTimeId);
      unpaidPlayers.forEach(p => { p.checked = false; });
    }
    
    // To sort checked and unchecked, filter is used here
    //TODO: unpaidPlayers = users.sort((a, b) => b.checked - a.checked);
    if(teeTimeSettings.includePlayersAcrossCoursesForCheckIn){
      let courseOrderedUnpaidPlayers = unpaidPlayers?.sort((a,b) => a.courseListOrder - b.courseListOrder);
      let originCourseData = courseOrderedUnpaidPlayers.filter(x => x.courseId === data.course.id);
      let otherCoursedata = courseOrderedUnpaidPlayers.filter(x => x.courseId !== data.course.id);
      unpaidPlayers = originCourseData.concat(otherCoursedata);
    }
    let checkedplayers = unpaidPlayers.filter(player => player.checked);
    let uncheckedplayers = unpaidPlayers.filter(player => !player.checked);
    unpaidPlayers = checkedplayers.concat(uncheckedplayers);
   
    return unpaidPlayers;
  }

  getDistinctGUIDs(guidArray) {
    const nonEmptyGUIDs = guidArray.filter(guid => guid !== "00000000-0000-0000-0000-000000000000");
    // Get distinct GUIDs
    const distinctGUIDs = [...new Set(nonEmptyGUIDs)];
    return distinctGUIDs;
  }
  getDistinctMember(arr) {
    return arr.filter((value, index, self) => self.indexOf(value) === index);
  }
  /**
   * HoldPlayers method to create temp hold against player
   * @param table
   */
  //TODO
  async HoldPlayers(lstUnPaidPlayer:number[]) {
    await this._teeTimeActionService.TempHoldPlayers(lstUnPaidPlayer);
  }
  async ReleaseTempHoldByType(playerIds: number[]) {
    this._teeTimeActionService.releasePlayersInHold(playerIds);
  }
  /**
   * Internal method to map position for the player
   * @param teeSheetData
   * @param unpaidPlayers
   */
  private UpdatePlayerPosition(teeSheetData: TeeTimeDetail[], unpaidPlayers: ScheduledTeeTimeUnPaidPlayer[]) {
    if (unpaidPlayers && unpaidPlayers.length) {
      unpaidPlayers.map(x => x.playerPosition = x.playerSlotPosition);
      if (teeSheetData && teeSheetData.length) {
        this.mapper = new TeeSheetMapper(this.localization);
        let groupedPlayerByScheduledTeeTime = _.groupBy(unpaidPlayers, 'scheduledTeeTimeId');
        let teeSheetGridContent = this.mapper.mapToTeeGridContent(teeSheetData, [], [], [], []);
        let teeSheetPlayerDetails = teeSheetGridContent && teeSheetGridContent.length ? teeSheetGridContent.flatMap(x => x.playerDetail) : null;
        if (teeSheetPlayerDetails && teeSheetPlayerDetails.length) {
          for (let scheduleTeeTimeId in groupedPlayerByScheduledTeeTime) {
            groupedPlayerByScheduledTeeTime[scheduleTeeTimeId].map(x => {
              let teeTimePlayer = teeSheetPlayerDetails.find(y => y.scheduledTeeTimePlayerFee.scheduledTeeTimePlayerId == x.id);
              if (teeTimePlayer) {
                x.playerPosition = teeTimePlayer && teeTimePlayer.playGroup ? teeTimePlayer.playGroup.listOrder : teeTimePlayer.playPos;
              }
            });
          }
        }
      }
    }
  }

}
