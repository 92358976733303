<section class="Errorpop-container">
  <div class="pop-head">
    <label class="inner-header pl-4">{{data.headername}}</label>
    <i [attr.automationId]="'Icn_contactLog_close'" aria-hidden="true"
      class="material-icons inner-close icon-close float-md-right cursPointer" (click)="DialogClose('e')"></i>
  </div>
  <div class="ag_container--padding-md formWrapper">
    <form [formGroup]="FormGrp" autocomplete="off" class="">
      <div>
        <mat-form-field  [floatLabel]="floatLabel"
          class="w-25 d-inline-block">
          <mat-select [attr.automationId]="'Dd_contactLog_logType'" placeholder="{{captions.tbl_hdr_logType}}" formControlName="logType"
            (selectionChange)="OnlogTypeChange($event)">
            <!-- <mat-option value="select">{{captions.lbl_select}}</mat-option> -->
            <mat-option *ngFor="let log of logTypeArray" [value]=log.id>{{log.logTypeInstance}}</mat-option>
          </mat-select>
          <mat-error *ngIf="FormGrp.controls['logType'].hasError('required') && FormGrp.controls['logType'].touched && !FormGrp.controls['logType'].valid">{{captions.missingLogType}}</mat-error>
        </mat-form-field>
      </div>
      <!-- <div class="from-date">
                <div class="LW14 d-inline-block w-50">
                  <mat-form-field [attr.automationId]="'Txt_contactLog_logDate'" [floatLabel]="floatLabel" class="LW14 d-inline-block w-50 date-picker-width" appearance="legacy">
                    <mat-label>{{captions.tbl_hdr_logDate}}</mat-label>
                    <input matInput [matDatepicker]="picker" dateRestricter formControlName="logDate" [placeholder]="placeholderFormat">
                    <mat-datepicker #picker></mat-datepicker>
                    <i  aria-hidden="true" matSuffix class="icon-Calender cursor fs-xlarge position-relative d-inline-block customCalendar"
                    (click)="picker.open()"></i>
                  </mat-form-field>
                </div>
              </div> -->
      <!-- <div class="">
                <mat-form-field [attr.automationId]="'Txt_contactLog_logNotes'" [floatLabel]="floatLabel" class="w-25">
                  <input matInput placeholder={{captions.tbl_hdr_logNotes}} formControlName="logNotes" [maxlength]="40">
                   <mat-error class="" *ngIf="FormGrp.controls['description'].hasError('required') && FormGrp.controls['description'].touched && !FormGrp.controls['description'].valid">
                    {{vDesc}}
                  </mat-error>
                </mat-form-field>
              </div> -->
      <div class="d-inline-block w-50">
        <mat-form-field  [floatLabel]="floatLabel"
          class="w-75 txt-area-height">
          <textarea [attr.automationId]="'Ta_contactLog_logNotes'" class="" placeholder="{{captions.tbl_hdr_logNotes}}" matInput formControlName="logNotes"
            name="comments" [maxlength]="250"></textarea>
          <mat-error class=""
            *ngIf="FormGrp.controls['logNotes'].hasError('required') && FormGrp.controls['logNotes'].touched && !FormGrp.controls['logNotes'].valid">
            {{captions.missingLogNotes}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="from-date ag_mt--4">
        <div class="LW14 d-inline-block w-50">
          <mat-form-field  [floatLabel]="floatLabel"
            class="LW14 d-inline-block w-50 date-picker-width" appearance="legacy">
            <mat-label>{{captions.tbl_hdr_dueDate}}</mat-label>
            <input [attr.automationId]="'Txt_contactLog_dueDate'" matInput [matDatepicker]="dueDate" dateRestricter formControlName="dueDate"
              [placeholder]="placeholderFormat" [min]="currentDate">
            <mat-datepicker
              [startAt]="FormGrp.controls['dueDate'].value == '' ? currentDate : FormGrp.controls['dueDate'].value"
              #dueDate></mat-datepicker>
            <i aria-hidden="true" matSuffix
              class="icon-Calender cursor fs-xlarge position-relative d-inline-block customCalendar"
              (click)="dueDate.open()"></i>
              <mat-error class=""
              *ngIf="FormGrp.controls['dueDate'].status =='INVALID'">
              {{captions.invalidDueDate}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="to-date">
        <div class="LW14 d-inline-block w-50">
          <mat-form-field [floatLabel]="floatLabel" class="LW14 d-inline-block w-50 date-picker-width"
            appearance="legacy">
            <mat-label>{{captions.tbl_hdr_expiryDate}}</mat-label>
            <input [attr.automationId]="'Txt_contactLog_expireDate'" matInput [matDatepicker]="expireDate" formControlName="expireDate" [placeholder]="placeholderFormat"
              [min]="FormGrp.controls['dueDate'].value == '' ? currentDate : (FormGrp.controls['dueDate'].value < currentDate ? currentDate : FormGrp.controls['dueDate'].value)">
            <mat-datepicker
              [startAt]="FormGrp.controls['expireDate'].value == '' ? (FormGrp.controls['dueDate'].value == '' ? currentDate : (FormGrp.controls['dueDate'].value < currentDate ? currentDate : FormGrp.controls['dueDate'].value)) : (FormGrp.controls['expireDate'].value)"
              #expireDate></mat-datepicker>
            <i aria-hidden="true" matSuffix
              class="icon-Calender cursor fs-xlarge position-relative d-inline-block customCalendar"
              (click)="expireDate.open()"></i>
              <mat-error class=""
              *ngIf="FormGrp.controls['expireDate'].status =='INVALID'">
              {{captions.expiryDateShouldNotBeLesserThanDueDate}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>


      <div class="ag_display--flex">
        <label class="LW12 label-color ag_mr--2 ag_mt--3">{{captions.lbl_showAlert}}</label>
        <app-toggle-switch [automationId]="automationId" class="d-block ag_mr--7 ag_mt--2"
          (changeToggleEvent)="onAlertToggleChange($event)" formControlName="isAlert">
        </app-toggle-switch>
        <label class="LW12 label-color ag_mr--2 ag_mt--3">{{captions.lbl_showPrivate}}</label>
        <app-toggle-switch [automationId]="automationId" class="d-block ag_mr--7 ag_mt--2"
          (changeToggleEvent)="onPrivateToggleChange($event)" formControlName="isPrivate">
        </app-toggle-switch>
        <!-- <label class="LW12 label-color ag_mr--8 ag_mt--4">{{this.captions.lbl_showGroup}}</label>
        <app-toggle-switch [attr.automationId]="'Tog_logType_showGroup'" class="d-block ag_mr--7 ag_mt--2"
          (changeToggleEvent)="onGroupToggleChange($event)" formControlName="isGroup">
        </app-toggle-switch> -->
      </div>

    </form>

  </div>
  <mat-dialog-actions class="dialog_actions_style pl-3">
    <div class="pop-button">
      <button [attr.automationId]="'Btn_contactLog_saveBtn'"
        [ngClass]="FormGrp.valid && FormGrp.dirty  ? 'spa-primary-default-save' : 'spa-primary-disabled'" mat-button
        color="primary" (click)="saveForm()">{{clickbutton}}</button>
      <a [attr.automationId]="'Lbl_contactLog_cancelBtn'" class="spa-button-cancel" (click)="DialogClose('Cancel')">
        {{this.captions.setting.cancel}} </a>
    </div>
  </mat-dialog-actions>
</section>
