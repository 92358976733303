<app-dialog-header [title]="confirmationID" (closeDialog)="close()"></app-dialog-header>

<div class="card-type-wrapper ag_container--padding-md">
   <div class="table-wrpper">
      <mat-radio-group  class="ag_display--flex optionOuterWrapper">
        <div  class="optionWrapper" >
            <mat-radio-button (change)="radioChange(x)" [checked]="x.isActive" *ngFor="let x of cartFolioAuthorizations ; let i = index" attr.automationId='Rdo_cardType_cartFolioAuthorizations_{{i}}' class="ag_w--100 ag_mb--2" [value]="x.id">
                <div class="ag_display--flex optLabelWrapper">
                    <div  class='card-type ag_mr--2' [ngSwitch]="x.cardCode">
                        <span *ngSwitchCase="'visa'" [matTooltip]="x.cardMasked" class="icon-visa "><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span></span>
                        <span *ngSwitchCase="'mastercard'" [matTooltip]="x.cardMasked" class="icon-master-card"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span><span class="path21"></span><span class="path22"></span></span>
                        <span *ngSwitchCase="'maestro'" [matTooltip]="x.cardMasked" class="icon-Maestro"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
                        <span *ngSwitchCase="'jcb'" [matTooltip]="x.cardMasked" class="icon-JCB"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>
                        <span *ngSwitchCase="'interac'" [matTooltip]="x.cardMasked" class="icon-Interac"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span></span>
                        <span *ngSwitchCase="'discover'" [matTooltip]="x.cardMasked" class="icon-Discover"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span><span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span><span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span><span class="path29"></span></span>
                        <span *ngSwitchCase="'dinersclub'" [matTooltip]="x.cardMasked" class="icon-Diners-Club"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span><span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span><span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span><span class="path29"></span><span class="path30"></span><span class="path31"></span><span class="path32"></span><span class="path33"></span><span class="path34"></span><span class="path35"></span><span class="path36"></span><span class="path37"></span><span class="path38"></span><span class="path39"></span></span>
                        <span *ngSwitchCase="'chinaunionpay'" [matTooltip]="x.cardMasked" class="icon-China-UnionPay"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span></span>
                        <span *ngSwitchCase="'americanexpress'" [matTooltip]="x.cardMasked" class="icon-AMEX"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span></span>
                        <span *ngSwitchDefault><span class="icon-Unkown-Card" [matTooltip]="x.cardMasked"><span class="path1"></span><span class="path2"></span></span></span>
                    </div>
                    <div class="optLabel">
                     {{x.cardMasked}}
                    </div>
                </div>

            </mat-radio-button>
        </div>
    </mat-radio-group>
   </div> 

</div>

<app-dialog-footer [buttonObj]="buttonObj" (cancel)="close()" (save)="save($event)"></app-dialog-footer>