<section id="event-detail-wrpper" class="ag_container--padding-md" [formGroup]="eventDetailForm">
    <div class="ag_display--flex">

        <div class=" align">
            <h4 class="ag_group--headers">{{captions.pg_title_eventDetails}}</h4>
            <div class="wrap ag_display--flex ag_form-control--1">
                <app-ag-dropdown [inputs]="eventSourceConfig" (selectChange)='toggleReportOption($event)'></app-ag-dropdown>
                <div *ngIf="isAutoARInvoiceEnabled" class="ag_display--flex">
                    <ag-input class='ag_display--block ag_form-control--2' formControlName='schedulerCode' required="true"
                    [placeholder]="captions.lbl_schedulerCode"></ag-input>
                    <ag-input class='ag_display--block ag_form-control--2' formControlName='schedulerName' required="true"
                    [placeholder]="captions.lbl_schedulerDescription"></ag-input>
                </div>
            </div>
            <div *ngIf="isReportSelected">
                <h4 class="ag_page--headers ag_display--inblock">{{captions.lbl_Reports}}</h4>
                <!-- <label *ngIf="editdynamicForm.length>0" class="LW14 ag-ml-1">({{editdynamicForm.length}})</label> -->
                <a *ngIf="editdynamicForm.length<1" class="ag_link ag_pl--4" (click)="openSlide()">+
                    {{captions.lbl_add_report}}</a>
                <div *ngIf="editdynamicForm.length>6" class="ag_display--flex ag_float--right"><a class="ag_link ag_pl--4"
                        (click)="viewAll();">{{captions.lnk_viewAll}}</a></div>
                </div>
                <div class="wrap ag_display--flex ag_form-control--1" *ngIf="isAPISelected">
                    <app-ag-dropdown [inputs]="apiScheduleInput" (selectChange)='apiScheduleChange($event)'>
                    </app-ag-dropdown>
                    </div>
                     <div class="wrap ag_display--flex ag_form-control--1" *ngIf="isReportSelected || isAPISelected  || isAdhocReportSelected">
                    <app-ag-dropdown [inputs]="reportTypeConfig"></app-ag-dropdown>
                    </div>
                    <div class="wrap ag_display--flex ag_form-control--1" *ngIf="isGenericExtractSelected">
                    <app-ag-dropdown [inputs]="genericTypeConfig"></app-ag-dropdown>
                    </div>
                    <div class="wrap ag_display--flex ag_form-control--1" *ngIf="isExecuteQuerySelected">
                    <app-ag-dropdown [inputs]="customQueryInput" (selectChange)='customQueryChange($event)'>
                        <app-shared-create-event-schedule [executeQueryConfig]="executeQueryConfig"></app-shared-create-event-schedule>
                    </app-ag-dropdown>
                    </div>
                    <div class="wrap ag_display--flex ag_form-control--1" *ngIf="isAdhocReportSelected">
                      <app-ag-dropdown [inputs]="adhocReportInput" (selectChange)='adhocReportChange($event)'>
                      </app-ag-dropdown>
                    </div>
        </div>


        <div class="reportContainer">
            <div *ngIf="showCards && isReportSelected" class="ag_display--flex display-flex-wrap">
                <div class='detailsBox ag-p-4 ag-mr-8 ag_mb--4 ag_display--inblock'
                *ngFor="let x of editdynamicForm | slice:0:sliceValue;let i = index;">
                    <div>
                    <h4 class="LW16 ag_font--bold ag_display--inflex">{{x.report}}</h4>
                    <span class="icon-more custtab-pr5 custtab-pointer ag_float--right"
                        [matMenuTriggerFor]="menu"></span>
                    </div>
                    <label class="ag_form--label text-ellipsis">{{x.reportLabel}}</label>
                    <div class="ag_display--flex">
                    <h4 class="LW16 ag_font--bold ag_display--inflex" *ngIf='x.fromWildCard'>Start Wild Card</h4>
                </div>
                <div>
                    <label *ngIf='x.fromWildCard' class="ag_form--label text-ellipsis">{{x.fromWildCard}} - {{x.fromWildCardCount}}</label>
                </div>
                <div class="ag_display--flex">
                    <h4 class="LW16 ag_font--bold ag_display--inflex" *ngIf='x.toWildCard'>To Wild Card</h4>
                </div>
                <div>
                    <label *ngIf='x.toWildCard' class="ag_form--label text-ellipsis">{{x.toWildCard}} - {{x.toWildCardCount}}</label>
                </div>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class='split-button-list'
                        (click)="editItem(x,i)">{{captions.lbl_view}}</button>
                    <button mat-menu-item class='split-button-list'
                        (click)="removeItem(i)">{{captions.lbl_delete}}</button>
                </mat-menu>
            </div>
        </div>
        </div>
        </div>
        <div class="ag_display--flex ag_w--100">
            <div class="ag_display--block wrap">
                <div class="guestBox">
                    <div class="ag_display--flex ag_w--100 ag_height--100 ag-pb-0">
                        <label>{{captions.lbl_email}}</label>
                        <div class="ag_float--right ag_ml--auto">
                            <app-ag-toggle [toggleInputs]="userEmailToggleInput"
                                (valueChange)="onEmailToggleChange($event)">
                            </app-ag-toggle>
                        </div>
                    </div>
                    <div *ngIf="isUserEmail" class="">
                        <ag-textbox class="ag_form-control--1" [config]="userEmailSubjConfig"></ag-textbox>
                        <app-ag-dropdown id="ag-automation-select-user-email-dllist" [inputs]="userEmailDLSourceConfig">
                        </app-ag-dropdown>
                        <app-ag-dropdown id="ag-automation-select-user-email" [inputs]="userEmailTemplateSourceConfig">
                        </app-ag-dropdown>
                    </div>
                </div>
            </div>
            <div class="ag_display--block wrap">
                <div class="guestBox">
                    <div class="ag_display--flex ag_w--100 ag_height--100 ag-pb-0">
                        <label>{{captions.lbl_sftp}}</label>
                        <div class="ag_float--right ag_ml--auto">
                            <app-ag-toggle [toggleInputs]="sftpToggleInput" (valueChange)="onSFTPToggleChange($event)">
                            </app-ag-toggle>
                        </div>
                    </div>
                    <div *ngIf="isSFTP" class="wrap ag_display--flex ag_form-control--1">
                        <app-ag-dropdown [inputs]="SFTPSetupSourceConfig"> </app-ag-dropdown>
                    </div>
                    <div *ngIf="isSFTP" class="">
                        <ag-textbox class="ag_form-control--1" [config]="sftpRemoteDirectory"></ag-textbox>
                        <!-- <span class="error-red">{{captions.lbl_sftpNote}}</span> -->
                        <span class="error-red">{{captions.lbl_ftpNote}}</span>
                    </div>
                </div>
                <br>
                <!-- <div class="guestBox">
                    <div class="ag_display--flex ag_w--100 ag_height--100 ag-p-4 ag-pb-0">
                        <label>{{captions.lbl_ftp}}</label>
                        <div class="ag_float--right ag_ml--auto">
                            <app-ag-toggle [toggleInputs]="ftpToggleInput" (valueChange)="onFTPToggleChange($event)">
                            </app-ag-toggle>
                        </div>
                    </div>
                    <div *ngIf="isFTP" class="ag-p-4 ag-pt-2">
                        <ag-textbox class="ag_form-control--1" [config]="ftpRemoteDirectory"></ag-textbox>
                        <span class="error-red">{{captions.lbl_ftpNote}}</span>
                    </div>
                </div> -->
            </div>
        </div>
</section>
