import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ButtonAction, FromAction, GolfSessionKey, TempHoldStatus } from 'src/app/shared/global.constant';
import { StepperService } from '../../shared/components/stepper/stepper.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import {CancellationNoShowLedger, RateType, ScheduledTeeTimeUnPaidPlayer } from 'src/app/retail/shared/shared.modal';
import { API } from 'src/app/settings/golf-setup/code-linking/code-link-modal';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AlertAction, AlertType, ButtonType, CheckboxTableHeader, DialogCloseObj } from 'src/app/shared/shared-models';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import { TeeTimesActionService } from 'src/app/shared/data-services/golfschedule/teeTimesAction.data.service';
import { ButtonValue, RetailItemType } from 'src/app/retail/retail.modals';
import { RateTypeDataService } from 'src/app/shared/data-services/golfmanagement/ratetype.data.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PaymentModalBussiness } from '../payment-modal/payment-modal-bussiness';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RateSetupBusiness } from 'src/app/settings/rate-setup/rate-setup.business';
import { AssignRetailItemService } from 'src/app/settings/settings-shared/assign-retail-item/assign-retail-item.service';
import { RateSetupDataService } from 'src/app/shared/data-services/golfschedule/rate-setup.data.service';
import { UnPaidPlayersBusiness } from 'src/app/shared/data-services/golfschedule/unpaid-players.business';
import { UnPaidPlayersService } from 'src/app/shared/data-services/golfschedule/unpaid-players.service';
import { PaymentModalService } from '../payment-modal/payment-modal-service';
import { DefaultsBusinessService } from 'src/app/settings/system-setup/defaults/defaults.business.service';
import { GolfUserConfigDataService } from 'src/app/shared/data-services/golfmanagement/golfuser.config.data';
import { CourseDataService } from 'src/app/shared/data-services/golfschedule/course.data.service';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { TeeTimeFormat } from 'src/app/shared/models/teesheet.form.models';
import {MatDialog} from '@angular/material/dialog';
import _ from 'lodash';
import { AllocationBlockWithPlayerType } from 'src/app/tee-time/tournaments/tournaments-modal/tournament-details/tournament-details.model';
import { TeeTimeActions } from '../tee-time-action.base';
import { PlayerPaymentstatus } from 'src/app/tee-time/search/search-model';
import { SettingsDataService } from 'src/app/shared/data-services/golfmanagement/settings.data.service';
import { API as TeeTimeSetting } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { TeeTimeCancellationNoShowFeeUtilities } from '../noshow-cancellation-fees-tee-time-utilities';
import { TeeTimeDataService } from 'src/app/shared/data-services/golfschedule/TeeTime.data.service';
import { PlayerTypeRateTypeChangeService } from 'src/app/shared/service/playerTypeRateTypeChange.service';
import { TeeTimesActionBusiness } from 'src/app/tee-time/shared/tee-action.business';
import { EditRetailItemService } from 'src/app/shared/data-services/golfschedule/edit-retail-item.service';
import { CancelPlayers } from '../move-player-modal/move-player-modal.model';
import { RentalEventService } from 'src/app/shared/service/rental-event-service';
import { ManageSessionService } from 'src/app/login/manage-session.service';

@Component({
  selector: 'app-no-show-modal',
  templateUrl: './no-show-modal.component.html',
  styleUrls: ['./no-show-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers : [
    PaymentModalBussiness,
    UnPaidPlayersBusiness,
    UnPaidPlayersService,
    AssignRetailItemService,
    RateSetupBusiness,
    RateSetupDataService,
    RateTypeDataService,
    CourseDataService,
    GolfUserConfigDataService,
    DefaultsBusinessService,
    TeeTimesActionService,
    GolfLocalization,
    SettingsDataService,
    TeeTimesActionBusiness,
    EditRetailItemService,
    PaymentModalService
  ]
})
export class NoShowModalComponent implements OnInit {
  NoShowForm : UntypedFormGroup;
  data: any;
  isPlayerPayReleaseBP: boolean = false;
  headerOption : CheckboxTableHeader[];
  disableActionBtn = false;
  rateTypes: RateType[] = [];
  activityButton: ButtonValue;
  captions: any;
  defaultSettings: any;
  tableData :ScheduledTeeTimeUnPaidPlayer[];
  dateA:any;
  isReBook:boolean = false;
  isNoShow : boolean = false;
  dateB:any;
  checkboxTrueArray: ScheduledTeeTimeUnPaidPlayer[];
  checkboxFalseArray: ScheduledTeeTimeUnPaidPlayer[];
  allocationBlockPlayerType: API.AllocationBlockPlayerType[];
  playerTypeRateType: API.PlayerTypeRateType[];
  $destroyed: Subject<boolean> = new Subject();
  allocationCodesWithPlayerTypes: AllocationBlockWithPlayerType[];
  allocationCodesWithPlayerTypesClone: AllocationBlockWithPlayerType[];
  isAllocationBlockEnabled : boolean;
  allocationCodes: any;
  playerTypes: any;
  teeConfig: TeeTimeSetting.TeeTimeConfig;
  IsNoShowCancellationPolicyConfigured: boolean;
  isWaiveOffAccess: boolean = false;
  constructor(private _FormBuilder: UntypedFormBuilder,
    private _teeTimeActionService: TeeTimesActionService,
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private _rateTypeDataService: RateTypeDataService,
    public _StepperService: StepperService,
    private _paymentModalBussiness :PaymentModalBussiness,
    private localization: GolfLocalization,
    private _golfUtilities: GolfUtilities,
    private _matDialog:MatDialog,
    private _settingsDataService: SettingsDataService,
    private _teeTimeDataService: TeeTimeDataService,
    private _teeTimeCancellationNoShowFeeUtilities: TeeTimeCancellationNoShowFeeUtilities,
    private _playerTypeRateTypeChangeService:PlayerTypeRateTypeChangeService,
    private _unPaidPlayersService:UnPaidPlayersService,
    private _rentalEventService: RentalEventService,
    private sessionService: ManageSessionService) 
    {
      this.data = dialogData.info;
      this.captions = this.localization.captions;
    }

    @Output() notifyParent = new EventEmitter();

    async ngOnInit() {
      this.isNoShow = this.dialogData.actionType == TeeTimeActions.noShow;
      this.isReBook = this.dialogData.actionType == TeeTimeActions.unMarkNoShow;
      await this._teeTimeActionService.ValidateBreakPoint(UserAccessBreakPoints.WAIVEOFFCHARGES, false).then(
        value => this.isWaiveOffAccess = value.isAllow
      );
      this.getPlayerProfileBreakPointAccess();
      this.formGenerator();
      this._StepperService.valueChange.pipe(takeUntil(this.$destroyed)).subscribe((x: DialogCloseObj) => {
        if (x.type.toLowerCase() === ButtonAction.noShow.toLowerCase()) {
          this.submitFormForNoShow();
        }
        else if(x.type.toLowerCase() === ButtonAction.unMarkNoShow.toLowerCase()){
          this.submitFormForNoShow();
        }
      });
      this.getPlayersForNoShow();
      this._rateTypeDataService.getAllRateTypes(false).then(resp => {
      this.rateTypes = resp;
      });
      this.allocationCodes = _.cloneDeep(this.allocationBlockPlayerType);
      this.allocationCodesWithPlayerTypesClone = this.allocationCodesWithPlayerTypes ? _.cloneDeep(this.allocationCodesWithPlayerTypes) : [];
      this.NoShowForm.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(x => {
        let checkBoxItems = this.NoShowForm.value.checkBoxItems;
        let isCheckBoxChecked = checkBoxItems && checkBoxItems?.length > 0 && checkBoxItems.some(y => y.checkBox) ? true : false
        if (this.NoShowForm.valid && isCheckBoxChecked && !this.disableActionBtn) {
          this.notifyParent.emit(true);
        }
        else {
          this.notifyParent.emit(false);
        }
      });
      this.activityButton = {
        label: this.captions.btn_bookActivity,
        type: 'primary'
      } 
      this.teeConfig = await this._settingsDataService.getTeeTimeSetting();
      this.IsNoShowCancellationPolicyConfigured =  await this._teeTimeCancellationNoShowFeeUtilities.IsNoShowCancellationPolicyConfigured();
      this.headerOption = this._paymentModalBussiness.GetHeaderOption(false,false,false,true, this.IsNoShowCancellationPolicyConfigured);
    }
    async getPlayerProfileBreakPointAccess() {
      this._teeTimeActionService.ValidateBreakPoint(UserAccessBreakPoints.NOSHOW, false).then(x => {});
      this._teeTimeActionService.ValidateBreakPoint(UserAccessBreakPoints.UNMARKNOSHOW, false).then(x => {});
    }

    formGenerator() {
      this.NoShowForm = this._FormBuilder.group({
      });
    }
  
    ngOnDestroy(): void {
      this.disableActionBtn = false;
      this._StepperService.enableCancel = true;
      this.$destroyed.next(true);
      this.$destroyed.complete();
    }

    async getPlayersForNoShow(){
        const systemDateTime = this.localization.getCurrentDate();
        const systemDateOnly = this._golfUtilities.resetTime(_.clone(systemDateTime));
        const propertyDateTime = new Date(this.localization.LocalizeDateTimeFormatSecondsDDMMMYYYYheader(systemDateTime));
      try
      {
        let tempTableData  = await this._paymentModalBussiness.GetPlayersForNoShow(this.data,this.refreshGrid.bind(this),this.isNoShow,this.isReBook);
        const bulkFilteredObj = tempTableData && tempTableData.filter(x => x.teeTimeFormat === TeeTimeFormat.BulkTee && tempTableData.map(s => s.confirmationNumber).includes(x.confirmationNumber));
        if (bulkFilteredObj && bulkFilteredObj.length) {
        }
        if(tempTableData && tempTableData.length > 0){        
        this.tableData = this.teeConfig && this.teeConfig.allowNoShowPriortoPlay ? 
        tempTableData.filter(
          x => 
           systemDateOnly.getDate() === this._golfUtilities.resetTime(new Date(_.clone(x.scheduledAPIDateTime))).getDate() 
            && !(x.playerStatus & (PlayerPaymentstatus.CheckIn | PlayerPaymentstatus.CheckOut)) 
          ) : 
          tempTableData.filter(
            x => 
              systemDateOnly.getTime() === this._golfUtilities.resetTime(new Date(_.clone(x.scheduledAPIDateTime))).getTime() 
              && systemDateOnly.getDate() === this._golfUtilities.resetTime(new Date(_.clone(x.scheduledAPIDateTime))).getDate() 
              && this.localization.getTime(propertyDateTime, 24) > this.localization.getTime(new Date(x.scheduledAPIDateTime), 24) 
              && !(x.playerStatus & (PlayerPaymentstatus.CheckIn | PlayerPaymentstatus.CheckOut)) 
            );
        if(this.IsNoShowCancellationPolicyConfigured == false)
        {
          this.tableData = tempTableData.filter(x => x.ticketNumber?.length == 0 && x.deposits?.length == 0);
          
        }
        this.tableData = tempTableData;
        this.tableData.forEach(a => {
            a.checked = a.isHold == true ? false : a.checked;
            a.isWaivedOff = false;
            a.isIconClicked = false;
          })
          if(this.tableData.filter(x=> x.checked).length==0)
          {
            this._golfUtilities.showAlert(this.captions.settings.noshowNoPlayersAvailable, AlertType.Warning, ButtonType.Ok, (res) => {
            });
          }
        }
        this.tableData = [...this.tableData];
        this.setCheckedAndUncheckItems();
        this.reArrangeTable();
      }
      catch(error)
      {
        console.log(error);
      }
    }

    reArrangeTable(){
      if(this.tableData && this.tableData.length > 0 && this.tableData.filter(x => x.isHold).length > 0){
        let checkedData = this.tableData.filter(x => x.checked);
        let enabledData = this.tableData.filter(x => !x.isHold);
        let disabledData = this.tableData.filter(x => x.isHold);
        this.sortArray(checkedData);
        this.sortArray(enabledData);
        this.sortArray(disabledData);
        let tempTable = checkedData.concat(enabledData.concat(disabledData));
        let myset = [...new Set(tempTable)];
        this.tableData = myset;
      }
    }

    private sortArray(playerArray) {
      if (playerArray && playerArray.length) {
        playerArray.sort((a, b) => {
          this.dateA = new Date(a.scheduledAPIDateTime);
          this.dateB = new Date(b.scheduledAPIDateTime);
          return this.dateA - this.dateB || (a.playerPosition > b.playerPosition ? 1 : -1);
        });
      }
    }


    setCheckedAndUncheckItems(){
      if(this.tableData !=null && this.tableData?.length > 0){
        this.checkboxTrueArray = this.tableData.filter((el) => {
          return el.checked;
        });
        this.sortArray(this.checkboxTrueArray);
        this.checkboxFalseArray = this.tableData.filter((el) => {
          return !el.checked;
        });
        this.sortArray(this.checkboxFalseArray);
        this.tableData = this.checkboxTrueArray.concat(this.checkboxFalseArray);
        this.tableData = [...this.tableData];
      }
    }

    async submitFormForNoShow(){
      this._StepperService.enableSave = false;
      const playerDetails = [];
      this.dialogData.info.playerDetail.forEach(x => {
          const playerDetail = this.tableData.find(players => players.playerId == x.playerId);
          playerDetails.push(playerDetail);
        });
        let dataForNoShow = this.NoShowForm.value.checkBoxItems;
        this.tableData.forEach(x => {
          x.checked = dataForNoShow.find(c => c.id == x.playerId).checkBox == true ? true : false;
        })
        let plyrsToBeNoShowedOrRebooked = this.tableData.filter(x => x.checked).map(x => x.playerId);
        if(this.isNoShow && (this.IsNoShowCancellationPolicyConfigured))
        {
          this.sessionService.setSessionValue(GolfSessionKey.BackgroundServiceTeeActionCompleted,false);
          let isCancellationNoShowItemPresent = await this._teeTimeCancellationNoShowFeeUtilities.CheckIfCancellationOrNoShowItemPresentOnProperty(RetailItemType.NoShowFee);
          if(isCancellationNoShowItemPresent == false)
          {
            this._golfUtilities.showAlert(this.localization.captions.teetime.noShowItemNotSetup,
              AlertType.Warning, ButtonType.Ok, () => {
                  this._matDialog.closeAll();
              });
          }
          else
          {
            if(this.isNoShow && plyrsToBeNoShowedOrRebooked.length == 1)
            {
              await this.ApplyNoShowCharges(plyrsToBeNoShowedOrRebooked,
                dataForNoShow.filter(o => o.playerId == plyrsToBeNoShowedOrRebooked[0])[0]);
            }
            else if(plyrsToBeNoShowedOrRebooked.length > 1)
            {
              let isNoShowCOFPopupShown = this.tableData.find(x => x.checked && x.tokenTransId == 0 && !x.isIconClicked);
              if (isNoShowCOFPopupShown){
                this._golfUtilities.showAlert(this.captions.settings.noShowCOFValidation, AlertType.Info, ButtonType.Ok, async (res) => {
                  if (res && this.tableData.filter(x => x.isIconClicked ? x.checked && x.isIconClicked : x.checked && x.tokenTransId != 0).length > 0){
                    await this.ApplyNoShowChargesForMultiplePlayers();
                  }
                });
              }
              else{
                await this.ApplyNoShowChargesForMultiplePlayers();
              }
            }
          }
        }
        else 
        {
          let cancelPlayers: CancelPlayers;
          cancelPlayers = {
            action: '',
            cancelReasonId: 0,
            playerIds: plyrsToBeNoShowedOrRebooked,
            reason: '',
            applyCancellationCharges: false,
            applyNoShowCharges: false,
            deletePlayerTransaction: false,
            fromRetail: false,
            outletId: 0,
            playerWaivedOff: {}
          };
          this._golfUtilities.ToggleLoader(true);
          let resultForNoShow = await this._teeTimeActionService.UpdatePlayerStatusAsNoShowReBook(cancelPlayers,this.isNoShow);
          this._golfUtilities.ToggleLoader(false);
          let MsgShownInUI = null;
          this.setCheckedAndUncheckItems();
          if(resultForNoShow.successOperation){
            await this.RemoveRentalItemsOnNoShow(plyrsToBeNoShowedOrRebooked);
            this._StepperService.enableSave = false;
            MsgShownInUI = this.isNoShow ?  this.localization.captions.teetime.noShowactionSuccessMsg : this.localization.captions.teetime.reBookactionSuccessMsg; 
            this._golfUtilities.showAlert(MsgShownInUI,
              AlertType.Success, ButtonType.Ok, () => {
                  this._matDialog.closeAll();
              });
          }
          else{
            MsgShownInUI = this.isNoShow ? this.localization.captions.teetime.noShowFailureMessage : this.localization.captions.teetime.reBookFailureMessage
            this._golfUtilities.showAlert(this.localization.captions.teetime.noShowFailureMessage,
              AlertType.Error, ButtonType.Ok, () => {
                  this._matDialog.closeAll();
              });
          }
        }
        this._StepperService.enableCancel = true;      
    }
  async ApplyNoShowCharges(plyrsToBeNoShowedOrRebooked: number[],noShowPlyr:ScheduledTeeTimeUnPaidPlayer)
  {
    if(plyrsToBeNoShowedOrRebooked && plyrsToBeNoShowedOrRebooked.length == 1 && noShowPlyr)
      {
        if(noShowPlyr.scheduledAPIDateTime == '' || noShowPlyr.scheduledAPIDateTime == null)
          {
            noShowPlyr.scheduledAPIDateTime = this.localization.convertDateToAPIdate(noShowPlyr.scheduledDateTime);
          }
        let outletId = await this._unPaidPlayersService.getOutLetId();
        this._golfUtilities.ToggleLoader(true);
        let noshowCharges = await this._teeTimeDataService.ApplyNoShowCharges(plyrsToBeNoShowedOrRebooked[0],outletId);
        let lineNumber = (noShowPlyr.cartFeeRetailItemId > 0 ? 1 : 0) + (noShowPlyr.greenFeeRetailItemId > 0 ? 1 : 0)
                        + (noshowCharges?.depositsRetailItemCnt > 0 ? noshowCharges?.depositsRetailItemCnt : 0);;
        let noshowChargeLinkedItem;
        if(noshowCharges?.charges > 0)
        {
          noshowChargeLinkedItem = await this._teeTimeCancellationNoShowFeeUtilities.FormNoShowRetailItem(noshowCharges,
          lineNumber,noshowCharges?.deposits?.length > 0,noShowPlyr);
        }
        this._golfUtilities.ToggleLoader(false);
        if((noShowPlyr.playerStatus & PlayerPaymentstatus.paid) != 0)
        {
          this.dialogData.isFromPopup = true;
          this._matDialog.closeAll();
          noshowChargeLinkedItem = noShowPlyr.isIconClicked ? null : (noshowChargeLinkedItem ? [noshowChargeLinkedItem] : null);
          this._playerTypeRateTypeChangeService.ProceedRefund(this.data, noshowChargeLinkedItem, true,this._matDialog, FromAction.NoShow,[noShowPlyr]);
        }
        else if(noShowPlyr.deposits?.length == 0)
        {
          if(noshowCharges != null)
          {
            if(noshowChargeLinkedItem != null && noShowPlyr.isIconClicked == false)
            {
              this.dialogData.isFromPopup = true;
              this._teeTimeCancellationNoShowFeeUtilities.RemoveGreenFeeItemCartFeeItem(noShowPlyr);  
              this._matDialog.closeAll();
              this._playerTypeRateTypeChangeService.ProceedToOrderSummary([noShowPlyr],this._matDialog,FromAction.NoShow,true,true,[noshowChargeLinkedItem]);
            }
            else 
            {
              let data  = this._teeTimeCancellationNoShowFeeUtilities.FormNoShowCancelPlayers(plyrsToBeNoShowedOrRebooked,null,"",
                await this._unPaidPlayersService.getOutLetId(),true);
              data.playerIds = plyrsToBeNoShowedOrRebooked;
              data.applyNoShowCharges = false;
              this._golfUtilities.ToggleLoader(true);            
              this._teeTimeActionService.UpdatePlayerStatusAsNoShowReBook(data,this.isNoShow);
              this._golfUtilities.ToggleLoader(false);
              let cancellationNoShowLedger = this._teeTimeCancellationNoShowFeeUtilities.FormCancellationNoShowLedger(noshowCharges,noShowPlyr.isIconClicked);
              this.CreateCancellationNoShowLedger(cancellationNoShowLedger);
              await this.RemoveRentalItemsOnNoShow([noShowPlyr.playerId]);
              this._matDialog.closeAll();
              return;
            }
          }
        }
        else if(noShowPlyr.deposits && noShowPlyr.deposits?.length > 0)
        {
          let depositAmount = 0;
          this.dialogData.isFromPopup = false;
          noShowPlyr.deposits.forEach(deposit => { depositAmount += deposit.amount; });
          noShowPlyr.deposit = depositAmount;
          this._matDialog.closeAll();
          noshowChargeLinkedItem = noShowPlyr.isIconClicked ? null : (noshowChargeLinkedItem ? noshowChargeLinkedItem : null);
          this._playerTypeRateTypeChangeService.ProceedDepositRefund([noShowPlyr], 
            (this.data?.course?.id ?? noShowPlyr.courseId), this.data.time, true, [noshowChargeLinkedItem],null, FromAction.NoShow);
        }        
      }
      return;
  }

  async ApplyNoShowChargesForMultiplePlayers()
  {
    let noShowPLyrs = this.tableData.filter(x => x.isIconClicked ? x.checked && x.isIconClicked : x.checked && x.tokenTransId != 0);
    let noShowPlayersIds = this.tableData.filter(x => x.isIconClicked ? x.checked && x.isIconClicked : x.checked && x.tokenTransId != 0).map(o => o.playerId );
    let cancelPlayers = this._teeTimeCancellationNoShowFeeUtilities.FormNoShowCancelPlayers(null,null,"",
    await this._unPaidPlayersService.getOutLetId(),true);
    cancelPlayers.playerIds = noShowPlayersIds;
    noShowPLyrs.forEach(o => { cancelPlayers.playerWaivedOff[o.id] = o.isIconClicked; });
    this._golfUtilities.ToggleLoader(true);
    let resultForNoShow = await this._teeTimeActionService.UpdatePlayerStatusAsNoShowReBook(cancelPlayers, this.isNoShow);
    this._golfUtilities.ToggleLoader(false);
    if(resultForNoShow.successOperation)
    {
      this._StepperService.enableSave = false;
      let MsgShownInUI = null;
      MsgShownInUI = this.localization.captions.teetime.noShowactionSuccessMsg; 
      this._golfUtilities.showAlert(MsgShownInUI,
        AlertType.Success, ButtonType.Ok, () => {
            this._matDialog.closeAll();
        });
    }
    else
    {
      this._StepperService.enableSave = false;
      let MsgShownInUI = null;
      MsgShownInUI = this.localization.captions.teetime.noShowactionInitiatedMsg; 
      this._golfUtilities.showAlert(MsgShownInUI,
        AlertType.Success, ButtonType.Ok, () => {
            this._matDialog.closeAll();
        });
    }
  }

    async loadAllocationPermissions() {
      this.isAllocationBlockEnabled = this._golfUtilities.IsAllocationCodePermissionEnabled();
      if (this.isAllocationBlockEnabled) {
      }
    }
    
    async refreshGrid(res, extraParam){
      let noshowPlayers = extraParam[0];
        if(noshowPlayers && noshowPlayers.length > 0){
        if(res === AlertAction.YES){
          if (noshowPlayers.find(x => x.isTournamentPlayersUnPaid && x.tournamentId > 0)) {
            noshowPlayers = noshowPlayers.filter(x => x.tournamentId == extraParam[1]);
            noshowPlayers.forEach(p => { p.checked = (p.tournamentId == extraParam[1]); });
            this.sortArray(noshowPlayers);
          }
          else if (noshowPlayers.find(x => x.teeTimeFormat === TeeTimeFormat.BulkTee && extraParam[1].map(s => s.confirmationNumber).includes(x.confirmationNumber))){
            noshowPlayers.forEach(p => { p.checked = (p.teeTimeFormat === TeeTimeFormat.BulkTee && extraParam[1].map(s => s.confirmationNumber).includes(p.confirmationNumber)); });
            let checkedplayers = noshowPlayers.filter(player => player.checked);
            this.sortArray(checkedplayers);
            let uncheckedplayers = noshowPlayers.filter(player => !player.checked);
            this.sortArray(uncheckedplayers);
            noshowPlayers = checkedplayers.concat(uncheckedplayers);
          }
          this.tableData = noshowPlayers;
        }
        else {
          let checkedplayers = noshowPlayers.filter(player => player.checked);
          this.sortArray(checkedplayers);
          let uncheckedplayers = noshowPlayers.filter(player => !player.checked);
          this.sortArray(uncheckedplayers);
          noshowPlayers = uncheckedplayers && uncheckedplayers.length ? checkedplayers.concat(uncheckedplayers) : checkedplayers;
          this.tableData = noshowPlayers;
        }
      }
    }
    iconClick(eve) {

      if(this.isWaiveOffAccess)
      {
        this.tableData.forEach(x=>{
          if(x.id == eve.id) {
            x.isIconClicked = !x.isIconClicked;
          }
        })
        this.tableData = [...this.tableData]
      }
      else
      {
        this._teeTimeActionService.showBreakPointError(UserAccessBreakPoints.WAIVEOFFCHARGES);
      }
    }

    checkBoxChange(rowData?,from?) {
      let dataForNoShow = this.NoShowForm.value.checkBoxItems;
      this.tableData.forEach(x => {
        x.checked = dataForNoShow.find(c => c.id == x.playerId).checkBox == true ? true : false;
        x.isIconClicked = (from == 'uncheck' && x.id == rowData.id) ? false : x.isIconClicked
      })
      this.tableData = [...this.tableData]
    }
    async alertIconClick(eve) {
      let outletId = await this._unPaidPlayersService.getOutLetId();
      let playerNoShowFees = await this._teeTimeDataService.ApplyNoShowCharges(eve.playerId,outletId);
      this.tableData.forEach(s => 
        {
          if(s.playerId == eve.playerId)
          {
            s.charges = this.localization.localizeCurrency(playerNoShowFees.charges, false);
          }
        });
      this.tableData = [...this.tableData];
    }
    async RemoveRentalItemsOnNoShow(selectedPlayerIds: number[]){
      this._rentalEventService.RemoveRentalItems(this.tableData.filter(x => x.checked && selectedPlayerIds.includes(x.playerId)).map(x => x.scheduledTeeTimePlayerId),null,FromAction.NoShow);
    }
    async CreateCancellationNoShowLedger(cancellationNoShowLedger: CancellationNoShowLedger) 
    {
      this._teeTimeActionService.CreateCancellationNoShowLedger([cancellationNoShowLedger]);
    }
}