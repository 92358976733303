import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, OnDestroy, Renderer2, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { cancelPlayerBusiness } from './cancel-player-business';
import { StepperService } from '../../shared/components/stepper/stepper.service';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { ReplaySubject,of } from 'rxjs';
import { CancelPlayerCaption, CancelPlayer, PlayerCancellationFees } from './cancel-player-model';
import { CheckboxTableHeader, DialogCloseObj, AlertType, ButtonType, ComponentDetails, AlertAction } from 'src/app/shared/shared-models';
import { takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TeeTimeDataService } from 'src/app/shared/data-services/golfschedule/TeeTime.data.service';
import { ConvertWaitlistModalComponent } from '../convert-waitlist-modal/convert-waitlist-modal.component';
import { CommonPopupComponent } from 'src/app/shared/components/common-popup/common-popup.component';
import { WaitlistBusiness } from 'src/app/tee-time/waitlist/waitlist.business';
import { WaitlistService } from 'src/app/tee-time/waitlist/waitlist.service';
import { PlayerTypeService } from 'src/app/shared/data-services/golfschedule/playertype.data.service';
import { RateTypeDataService } from 'src/app/shared/data-services/golfschedule/ratetype.data.service';
import { CourseDataService } from 'src/app/shared/data-services/golfmanagement/course.data.service';
import { CreateWaitlistModalService } from 'src/app/tee-time/waitlist/create-waitlist-modal/create-waitlist-modal.service';
import * as WaitlistInterface from 'src/app/tee-time/waitlist/waitlist.model';
import {CancelReason} from 'src/app/settings/golf-setup/code-setup/cancel-reasons/cancel-reasons.model'
import { BookingSource,ButtonAction, FromAction, GolfSessionKey } from 'src/app/shared/global.constant';
import { Observable, from } from 'rxjs';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { SettingsDataService } from 'src/app/shared/data-services/golfschedule/settings.data.service';
import { BulkTeeTimeService } from 'src/app/tee-time/tee-sheet/bulk-tee-time/bulk-tee-time.service';
import { TeeTimeService } from 'src/app/tee-time/tee-time.service';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { SendNotificationMailSmsComponent } from 'src/app/shared/components/send-notification-mail-sms/send-notification-mail-sms.component';
import { NotificationConfigurationService } from 'src/app/common/templates/notification-configuration/notification-configuration.service';
import { EventNotificationGroup, EventNotification } from 'src/app/common/templates/notification-configuration/notification-configuration.model';
import { CancelReasonsDataService } from 'src/app/shared/data-services/golfmanagement/cancel-reasons.data.service';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import _ from 'lodash';
import { TeeSheetCustomData, TeeSheetTableID, TeeTimeFormat } from 'src/app/shared/models/teesheet.form.models';
import { TeeTimeAction } from 'src/app/shared/models/teesheet.api.models';
import { GolfImageService } from 'src/app/shared/data-services/Image/golf.Image.service';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { Actions } from '../notify/notify.model';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { allocationBlockPlayerTypePermission } from 'src/app/settings/user-setup/booking-permission/booking-permission.model';
import { TeeTimesActionBusiness } from 'src/app/tee-time/shared/tee-action.business';
import { UserAccessModel } from 'src/app/common/dataservices/authentication/useraccess-model.model';
import { TeeSheetSingleCourse } from 'src/app/tee-time/shared/tee-sheet/tee-sheet.singleCourse';
import { TeeSheetMultiCourse } from 'src/app/tee-time/shared/tee-sheet/tee-sheet.mulitCourse';
import { SystemdefaultsInformationService } from 'src/app/core/services/systemdefaults-information.service';
import { RetailBussinessService } from 'src/app/shared/retail.bussiness';
import { NotifierBar } from 'src/app/shared/components/note/note.model';
import { TeeTimeCancellationNoShowFeeUtilities } from '../noshow-cancellation-fees-tee-time-utilities';
import { UnPaidPlayersService } from 'src/app/shared/data-services/golfschedule/unpaid-players.service';
import { PlayerTypeRateTypeChangeService } from 'src/app/shared/service/playerTypeRateTypeChange.service';
import { CancellationNoShowLedger, ScheduledTeeTimeUnPaidPlayer } from 'src/app/retail/shared/shared.modal';
import { PlayerPaymentstatus, RetailItem, RetailItemType } from 'src/app/retail/retail.modals';
import { ManageSessionService } from 'src/app/login/manage-session.service';


@Component({
  selector: 'app-cancel-player-modal',
  templateUrl: './cancel-player-modal.component.html',
  styleUrls: ['./cancel-player-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    cancelPlayerBusiness,
    CancelReasonsDataService,
    TeeTimeDataService,
    WaitlistBusiness,
    WaitlistService,
    PlayerTypeService,
    RateTypeDataService,
    UnPaidPlayersService,
    CourseDataService,
    CreateWaitlistModalService,
    SettingsDataService,
    BulkTeeTimeService,
    TeeTimeService,
    NotificationConfigurationService,
    TeeTimesActionBusiness,
    TeeTimeCancellationNoShowFeeUtilities,
    RetailSharedVariableService
  ]

})
export class CancelPlayerModalComponent implements OnInit, OnDestroy {
  teeTimeInfo: any;
  defaultCancelReason: CancelReason;
  captions: CancelPlayerCaption;
  cancelPlayerForm: UntypedFormGroup;
  notifierBar: NotifierBar;
  reasonNameTitle: string;
  headerOption: CheckboxTableHeader[] = [];
  bulkBookingId = '';
  defaultCancelReasonId: number;
  @Output() notifyParent = new EventEmitter();
  public $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  playersData: CancelPlayer[];
  popUpComponentDetails: ComponentDetails;
  waitList: WaitlistInterface.WaitListViewModel[];
  notifyPopUpComponentDetails: { componentName: any; popUpDetails: { isStepper: boolean; eventName: string; bindData: any; }; };
  eventNotificationGroup: EventNotificationGroup[] = [];
  bulkPlayerUserAccess: UserAccessModel.BreakPointResult;
  normalPlayerUserAccess: UserAccessModel.BreakPointResult;
  mixedPlayerSlot = false;
  isBulk = false;
  @ViewChild('infoElement') infoEle: any;
  mixedBulkPlayerGroup: boolean = false;
  infoStripAvailable: boolean;
  action: TeeTimeAction;
  teeSheetID: TeeSheetTableID;
  customTableData: TeeSheetCustomData;
  playerList: any[];
  playerCollectionList: any[] = [];
  draggedPlayersList: any;
  lblcaptions: any;
  teeTimeFormat = TeeTimeFormat;
  selectedPlayer = null;
  playerCaptions: any;
  cancelReasons$: Observable<CancelReason[]>;
  cancelReasons: CancelReason[];
  isPlayerSelected: boolean;
  $destroy: ReplaySubject<boolean> = new ReplaySubject(1);
  allocationCodePermissions: allocationBlockPlayerTypePermission[] = [];
  isAllocationBlockEnabled: boolean;
  requiredReasonTeeTimeCancellation: boolean
  floatLabel: string;
  valueSelected: any;
  allowCancellationOfGolfNowBooking : boolean = false;
  isGolfNowEnabled: boolean = false; 
  automationId:string = "CancelPlayerModal";
  IsNoShowCancellationPolicyConfigured: boolean = false;
  isWaiveOffAccess: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<CancelPlayerModalComponent>,
    private _FormBuilder: UntypedFormBuilder,
    private _teeSheetSingleCourse: TeeSheetSingleCourse,
    private _teeSheetMultiCourse: TeeSheetMultiCourse,
    private _cancelPlayerBusiness: cancelPlayerBusiness,
    @Inject(MAT_DIALOG_DATA) public dialogData,
    public _StepperService: StepperService, private _dialog: MatDialog, private waitlistBusiness: WaitlistBusiness,
    private _localization: GolfLocalization, private _NotificationConfigurationService: NotificationConfigurationService,
    private _Utilities: GolfUtilities,
    private rendrer: Renderer2,
    private cdr: ChangeDetectorRef, private teeTimesActionBusiness: TeeTimesActionBusiness,
    private _SystemdefaultsInformationService: SystemdefaultsInformationService, private _retailService: RetailBussinessService,
    private _imageService: GolfImageService, public quickLoginUtils: QuickLoginUtilities, private propertyInformation: RetailPropertyInformation,
    private _teeTimeCancellationNoShowFeeUtilities:TeeTimeCancellationNoShowFeeUtilities,
    private _settingsDataService : SettingsDataService,
    private _retailSharedVariableService: RetailSharedVariableService,
    private _unPaidPlayersService: UnPaidPlayersService,
    private _TeeTimeDataService: TeeTimeDataService,
    private _playerTypeRateTypeChangeService:PlayerTypeRateTypeChangeService,
    private sessionService: ManageSessionService
  ) {
    this.floatLabel = this._localization.setFloatLabel;

  }

  async ngOnInit() {
    this.teeTimeInfo = this.dialogData.info;
    this.isBulk = this.dialogData.isBulk;
    this.bulkBookingId = this.dialogData.info.bulkBookingId ? this.dialogData.info.bulkBookingId : this.bulkBookingId;
    this.lblcaptions = this._localization.captions.teetime;
    this.captions = this._cancelPlayerBusiness.cancelPlayerCaption;
    this.playerCaptions = this._localization.captions.settings.players;
    this.headerOption = this._cancelPlayerBusiness.getHeaderOption();
    await this._cancelPlayerBusiness.validateMixedBreakPoints(UserAccessBreakPoints.WAIVEOFFCHARGES, false).then(
      value => this.isWaiveOffAccess = value.isAllow
    );
    this.cancelFormGenerator();
    await this._SystemdefaultsInformationService.GetDefaultsSetting();
    this.requiredReasonTeeTimeCancellation = this._SystemdefaultsInformationService.GetRequiredReasonTeeTimeCancellationStatus();
    await this._cancelPlayerBusiness.getCancelReasons(false).then(res=>{
      this.cancelReasons$ = of(res);
      this.cancelReasons = res;
    });    
    this.setDefaultCancelReason();
    this.CheckMixedPlayers(this.dialogData, this.isBulk);
    await this.validateUserAccess(this.mixedPlayerSlot, this.isBulk);
    this.notifyParent.emit(false);
    if (!this.mixedBulkPlayerGroup) {
      await this.getPlayerDetails();
      await this.validatePlayerCount();
      this.isAllocationBlockEnabled = this._Utilities.IsAllocationCodePermissionEnabled();
      if (this.isAllocationBlockEnabled) {
        this.allocationCodePermissions = this.dialogData.allocationCodePermissions;
      }
    }

    if (this.requiredReasonTeeTimeCancellation) {
      this.cancelPlayerForm.controls['cancelReason'].setValidators([Validators.required]);
    }

    this._StepperService.valueChange.pipe(takeUntil(this.$destroyed)).subscribe(async (x: DialogCloseObj) => {
      if (x.type.toLowerCase() === ButtonAction.process) {
        await this.submitForm(x);
      }
    });

    this._StepperService.valueChange.pipe(takeUntil(this.$destroyed)).subscribe((x: DialogCloseObj) => {
      if (x.type == 'backClick') {
        this.getPlayerDetails('back');
        this.selectedPlayer = null;
        this.isPlayerSelected = false;        
      }
      else if (x.type === ButtonAction.save) {
        this.notifyParent.emit(false); // Disable save once clicked
      }
    });

    await this.setCancellationNote();
    this.teeSheetID = this._cancelPlayerBusiness.getTeeSheeetID();
    this.customTableData = this._cancelPlayerBusiness.getTeeSheetCustomTableData();
    this._imageService.getProfileImages(this.playerList, "imageReferenceId");
    this.IsNoShowCancellationPolicyConfigured = await this._teeTimeCancellationNoShowFeeUtilities.IsNoShowCancellationPolicyConfigured();
    if(this.IsNoShowCancellationPolicyConfigured)
    {
      this.headerOption.push({
        key: 'cancellationCharge',
        description: this._localization.replacePlaceholders(this._localization.captions.settings.cancellationFee, ["option"], [this._localization.currencySymbol]),
        alignment: 'textRight',
        templateName: "custom"
      });
      this.headerOption.push({
        key: 'action',
        description: this._localization.captions.teetime.isWaiveOff,
        alignment: 'textCenter',
        templateName: "custom"
      });
      this.headerOption = [...this.headerOption];
    }
    
  }
  private async validatePlayerCount() {
    if (this._cancelPlayerBusiness.playersCount <= 0) {
      if (this._cancelPlayerBusiness.isAllocationCodePermissionEnabled) {
        let defaultAllocationCodePermissions = [];
        if (this._cancelPlayerBusiness.alloc && this._cancelPlayerBusiness.alloc.length > 0)
        {
          defaultAllocationCodePermissions = this._cancelPlayerBusiness.alloc.filter((r) => r == this.teeTimeInfo.allocation?.id);
        }
        if (this.teeTimeInfo.allocation?.id && (!defaultAllocationCodePermissions || defaultAllocationCodePermissions.length == 0)) {
          this._Utilities.showAllocationCodePermissionDeniedPopup(this.teeTimeInfo.allocation.name, this._localization.captions.common.CancelNotAllowed, async (res) => {
            if(this._cancelPlayerBusiness.unPaidPlayersCount <= 0)
            {
              this.dialogRef.close('saved');
            }
          });
          return;
        }
      }
      if (this._cancelPlayerBusiness.checkInCheckOutCount > 0) {
        this._Utilities.showError(`${this._localization.captions.common.CancelNotAllowed} ${this.lblcaptions.CheckInCheckOutRestrictedValidation}`);
          if(this._cancelPlayerBusiness.unPaidPlayersCount <= 0)
          {
            this.dialogRef.close('saved');
          }
      }
      else {
        // this validation should not happen as cancellation policy is set to true at prop level.
        // cancellation should be allowed as the player would be redirected to order summary screen. 
        if((await this._teeTimeCancellationNoShowFeeUtilities.IsNoShowCancellationPolicyConfigured()))
        {
          return;
        }
        this._cancelPlayerBusiness.showAlertMessage(this.captions.cancelNoPlayersAvailable, AlertType.Warning, ButtonType.Ok, (res) => {
          if (res === AlertAction.CONTINUE && this._cancelPlayerBusiness.unPaidPlayersCount <= 0) {
            this.dialogRef.close('saved');
          }
        });
      }
    }
  }
  private CheckMixedPlayers(dialogData, isBulk: boolean) {
    if (dialogData.info.playerDetail && dialogData.info.playerDetail.length > 0) {
      let bulktee = dialogData.info.playerDetail.filter(x => !x.isBlocked && x.teeTimeFormat === TeeTimeFormat.BulkTee);
      let normalTee = dialogData.info.playerDetail.filter(x => !x.isBlocked && x.teeTimeFormat !== TeeTimeFormat.BulkTee);
      this.playerList = this.dialogData.info.playerDetail.filter(x => !x.isBlocked && x.teeTimeFormat === TeeTimeFormat.BulkTee);
      const bulkDistinctGroup = _.uniq(_.map(this.dialogData.info.playerDetail.filter(x => !x.isBlocked && x.teeTimeFormat === TeeTimeFormat.BulkTee), 'confirmationNumber'));
      this._StepperService.setBackEnable(false);
      if (bulktee.length > 0 && normalTee.length > 0) {
        this.mixedPlayerSlot = true;
      }
      if (isBulk && bulkDistinctGroup && bulkDistinctGroup.length > 1) {
        this.mixedBulkPlayerGroup = true;
        this._StepperService.setBackEnable(true);
      }

    }
  }
  setDefaultCancelReason()
  {
    this.defaultCancelReason = this.cancelReasons.find(x=> x.isDefault);
    if(this.defaultCancelReason)
    {
    this.reasonNameTitle=this.defaultCancelReason.cancelReason;
    this.defaultCancelReasonId = this.defaultCancelReason.id;
    this.valueSelected = this.defaultCancelReason.id;
    }
    
  }

  cancelFormGenerator() {
    this.cancelPlayerForm = this._FormBuilder.group({
      cancelReason:[''],
      cancelComment: ''
    });
    this.cancelPlayerForm.statusChanges.pipe(takeUntil(this.$destroyed)).subscribe(x => {
      const checkedData = this.cancelPlayerForm.value.checkBoxItems ? this.cancelPlayerForm.value.checkBoxItems.some(x => x.checkBox) : false;
      this.notifyParent.emit(this.cancelPlayerForm.valid && checkedData);
    });
  }

  async getPlayerDetails(bulkBookingId?) {
   this.playersData = await this._cancelPlayerBusiness
        .getPlayerDetails(this.teeTimeInfo, bulkBookingId ? bulkBookingId : this.bulkBookingId).then(res => {
          return res.map(x => {
            if (this.mixedPlayerSlot && this.bulkPlayerUserAccess && this.normalPlayerUserAccess && (!this.bulkPlayerUserAccess.isAllow || !this.normalPlayerUserAccess.isAllow)) 
            {
              x.hasAccessDisabled = x.teeTimeFormat === TeeTimeFormat.BulkTee ?
                !this.bulkPlayerUserAccess.isAllow : !this.normalPlayerUserAccess.isAllow;
              x.checked = !x.hasAccessDisabled;
              //check this brakpoint will not work
              // added the following for waiving of the cancellation charge
              x.isWaivedOff = false;
              x.isIconClicked = false;
              x.isHold = x.hasAccessDisabled; 
            }
            return x;
          });
      })
      this.playersData = [...this.playersData];
  }

  async submitForm(x) {
    var selectedPlayerIds = this.cancelPlayerForm.value.checkBoxItems.filter(x => x.checkBox).map(a => a.id);
    var selectedScheduledTeeTimePlayerIds = this.cancelPlayerForm.value.checkBoxItems.filter(x => x.checkBox).map(a => a.scheduledTeeTimePlayerId);

    if (selectedPlayerIds.length > 0 && this.checkAllocationBlockPermission()) {
      // this validation should not happen as cancellation policy is set to true at prop level.
      // cancellation should be allowed as the player would be redirected to order summary screen. 
      if(! (await this._teeTimeCancellationNoShowFeeUtilities.IsNoShowCancellationPolicyConfigured()))
      {  
        this.sessionService.setSessionValue(GolfSessionKey.BackgroundServiceTeeActionCompleted,false);
        if (this._cancelPlayerBusiness.checkSelectedPlayersHasAnyPendingTransaction(selectedPlayerIds, this.CancelPlayers.bind(this), this.dialogRef)) 
        {
          return;
        }
      }
      if (this._cancelPlayerBusiness.checkSelectedPlayersInCart(selectedPlayerIds)) {
        this._cancelPlayerBusiness.showAlertMessage(this.captions.clearCartMessage,
          AlertType.Warning, ButtonType.Ok, null);
      } else {
        return this.CancelPlayers(selectedPlayerIds,selectedScheduledTeeTimePlayerIds);
      }
    }
  }

  async CancelTeeTime(selectedPlayerIds: any,selectedScheduledTeeTimePlayerIds?: any) {
    this._Utilities.ToggleLoader(true);

    if(await this._teeTimeCancellationNoShowFeeUtilities.IsNoShowCancellationPolicyConfigured())
    {

      let isCancellationNoShowItemPresent = await this._teeTimeCancellationNoShowFeeUtilities
                                            .CheckIfCancellationOrNoShowItemPresentOnProperty(RetailItemType.CancellationFee);
      if(isCancellationNoShowItemPresent == false)
      {
        this._cancelPlayerBusiness.showAlertMessage(this.captions.cancelItemNotConfigured, AlertType.Warning, ButtonType.Continue, (res) => {
          if (res === AlertAction.CONTINUE) {
            this.dialogRef.close();
          }
        });
      }
      else 
      {
        let plyrsToBeCancelled = this.cancelPlayerForm.value.checkBoxItems;  
        if(selectedPlayerIds.length == 1)
        {
          let selectedPlayer = plyrsToBeCancelled.find(o => o.id == selectedPlayerIds[0]);
          let scheduledTeeTimeUnPaidPlayer:ScheduledTeeTimeUnPaidPlayer[] = await this._unPaidPlayersService
          .getScheduledTeeTimePlayer(selectedPlayer.scheduledTeeTimePlayerId, 
            this.teeTimeInfo.course.id, this.teeTimeInfo.time,selectedPlayer.playerStatus);
          if(plyrsToBeCancelled != null)
          {
            let cancelPlyr = plyrsToBeCancelled.filter(o => o.id == selectedPlayerIds[0]);
            await this.ApplyCancellationCharges(selectedPlayerIds[0], 
              scheduledTeeTimeUnPaidPlayer,selectedPlayerIds,selectedScheduledTeeTimePlayerIds,
              cancelPlyr);
          }
        }
        else if(selectedPlayerIds.length > 1) 
        {
          let isCancelCOFPopupShown = this.cancelPlayerForm.value.checkBoxItems.find(x => x.checkBox && x.tokenTransId == 0 && !x.isIconClicked);
          if (isCancelCOFPopupShown){
            this._cancelPlayerBusiness.showAlertMessage(this.captions.cancellationCOFValidation, AlertType.Info, ButtonType.Ok, async (res) => {
              if (res && this.cancelPlayerForm.value.checkBoxItems.filter(x => x.isIconClicked ? x.checkBox && x.isIconClicked : x.checkBox && x.tokenTransId != 0).length > 0) {
                this._Utilities.ToggleLoader(true);
                await this.ApplyCancellationCharges(selectedPlayerIds[0],null,selectedPlayerIds,
                  selectedScheduledTeeTimePlayerIds,plyrsToBeCancelled);
              }
            });
          }
          else{
            await this.ApplyCancellationCharges(selectedPlayerIds,null,selectedPlayerIds,
              selectedScheduledTeeTimePlayerIds,plyrsToBeCancelled);
          }
        }
      }
    }
    else
    {  
      this._cancelPlayerBusiness.cancelTeeTimePlayers(
      selectedPlayerIds,
      this.teeTimeInfo.course.id,
      this.teeTimeInfo.time,
      (this.isBulk) ? Actions.BULKCANCEL : "",
      this.cancelPlayerForm.value,
      this.convertWaitlist.bind(this, selectedPlayerIds), this.teeTimeInfo,selectedScheduledTeeTimePlayerIds).then(
        o => {
          this.dialogRef.close('saved');
          this.quickLoginUtils.resetQuickIdDetails();
        });
    }
  }

  CancelPlayers(selectedPlayerIds,selectedScheduledTeeTimePlayerIds?:any) {
    let quickIdConfig = this.propertyInformation.getQuickIdConfig;
    if (quickIdConfig && quickIdConfig.teeTimeCancel) {
      const quickLoginDialogRef = this.quickLoginUtils.QuickLogin();
      quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroy)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
        if (quickLoginDialogResult.isLoggedIn) {
          const quickRoleId = this.quickLoginUtils.GetQuickLoginRoleId();
          const roleId = this._localization.GetUserInfo("roleId");
          let isAllocationAllowed = true;
          if (quickRoleId != roleId) {
            const allocationCodePermissionsforQuickId = await this._cancelPlayerBusiness.GetAllocationBlockPermissionByRole(quickRoleId);
            isAllocationAllowed = this.checkAllocationBlockPermission(allocationCodePermissionsforQuickId);
          }
          if (isAllocationAllowed) {
            this.CancelTeeTime(selectedPlayerIds,selectedScheduledTeeTimePlayerIds);
          }
        } else {
          this._StepperService.enableSave = true;
        }
      });
    } else {
      this.CancelTeeTime(selectedPlayerIds,selectedScheduledTeeTimePlayerIds);
    }
  }

  ngOnDestroy(): void {
    this.$destroyed.next(true);
    this.$destroyed.complete();
    this.$destroy.complete();
    this.quickLoginUtils.resetQuickIdDetails();
  }


  public async convertWaitlist(playerIds: number[]) {
    this.dialogRef.close();
    this.waitList = await this.waitlistBusiness.GetWaitListByDate(this.teeTimeInfo.time, this.teeTimeInfo.course.id, this.teeTimeInfo.originalHoleNumber)

    if (this.waitList && this.waitList.length > 0) {
      this._cancelPlayerBusiness.showAlertMessage(this.captions.waitlistAvailableMessage,
        AlertType.Info, ButtonType.YesNo,
        this.invokeWaitlistPopup.bind(this, playerIds), this.captions.waitlistAvailableHeader);
    } else {
      if (this.bulkBookingId != '') {
        this.callNotificationPopup(false, '', playerIds);
      }
    }


  }

  public invokeWaitlistPopup(playerIds, res, arg) {
    switch (res) {
      case 'YES':
        if (this.bulkBookingId != '') {
          this.callNotificationPopup(true, arg, playerIds);
        } else {
          this.callwaitlistmodelpopup(arg);
        }
        break;
      case 'NO':
        if (this.bulkBookingId != '') {
          this.callNotificationPopup(false, arg, playerIds);
        }
        break
    }
  }

  async callwaitlistmodelpopup(arg) {
    this.popUpComponentDetails = {
      componentName: ConvertWaitlistModalComponent,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent',
        bindData: this.waitList
      }
    };
    this._dialog.open(CommonPopupComponent, {
      width: '80%',
      height: '80%',
      data: {
        title: this.captions.convertWaitList,
        update: this.captions.convertToTeeTime,
        cancel: this.captions.cancel,
        componentDetails: this.popUpComponentDetails,
        info: arg,
        showStaticBool: false,
        actionType: ButtonAction.convert
      },
      disableClose: true
    }).afterClosed().pipe(takeUntil(this.$destroyed)).subscribe();
  }
  async callNotificationPopup(OW, arg?, playerIds = []) { //OW-open wailist
    this.eventNotificationGroup = await this._NotificationConfigurationService.GetEventNotificationGroupByEventId(EventNotification.BulkCancel);
    if (this.eventNotificationGroup && this.eventNotificationGroup.length > 0) {
      await this.calltriggeremailsmspopupfunction(playerIds);
      if (OW) { this.callwaitlistmodelpopup(arg); }
    } else {
      if (OW) { this.callwaitlistmodelpopup(arg); }
    }
  }

  async calltriggeremailsmspopupfunction(playerids: number[]) {
    let userinfo = playerids != null && playerids?.length > 0 ? await this._retailService.GetPlayerContactInfoByPlayerIdList(playerids) : null;
    let objPhone: any = userinfo != null && userinfo?.length > 0 ? this._Utilities.GetPhoneNumber(userinfo) : null;
    let email = userinfo != null && userinfo?.length > 0 ? this._Utilities.GetEmail(userinfo) : null;
    this.notifyPopUpComponentDetails = {
      componentName: SendNotificationMailSmsComponent,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent',
        bindData: ''
      }
    };
    this._dialog.open(SendNotificationMailSmsComponent, {
      width: "40%",
      height: "49%",
      panelClass: "",
      data: {
        title: this._localization.captions.common.notificationTitle,
        save: this._localization.captions.common.PopUpSend,
        cancel: this._localization.captions.common.PopUpSkip,
        componentDetails: this.notifyPopUpComponentDetails,
        info: this.bulkBookingId,
        playerIds: playerids,
        mailId: email && email != null && email?.value ? email.value : null,
        countryCode: objPhone && objPhone != null && objPhone != "" && objPhone?.value && objPhone?.value != null && objPhone?.value != "" ? this._Utilities.getCountryCodeFromValue(Number(objPhone?.type), objPhone?.value) : null,
        phone: objPhone && objPhone != null && objPhone != "" && objPhone?.value && objPhone?.value != null && objPhone?.value != "" ? this._Utilities.getPhoneNumberFromValue(Number(objPhone?.type), objPhone?.value) : null,
        action: "BulkCancel",
        showStaticBool: false,
        fromComponent: 'fromComponent',
        actionType: ButtonAction.save
      },
      disableClose: true
    }).afterClosed().pipe(takeUntil(this.$destroyed)).subscribe();
  }

  private async validateUserAccess(mixedPlayerslot: boolean, isBulk: boolean) {
    if (mixedPlayerslot && !isBulk) {
      this.bulkPlayerUserAccess = await this._cancelPlayerBusiness.validateMixedBreakPoints(UserAccessBreakPoints.BULKCANCEL, false);
      this.normalPlayerUserAccess = await this._cancelPlayerBusiness.validateMixedBreakPoints(UserAccessBreakPoints.CANCELPLAYERS, false);
      this.updateUserAccessInfo();
    }
    return;
  }

  reasonSelect(arg)
  {
    this.reasonNameTitle = this.cancelReasons.find(res=>res.id === arg.value).cancelReason;
    this.valueSelected = this.cancelReasons.find(res=>res.id === arg.value).id;
    this.cancelPlayerForm.controls.cancelReason.setValue(this.valueSelected);
    
  }

  private updateUserAccessInfo() {
    if (this.mixedPlayerSlot && this.bulkPlayerUserAccess && this.normalPlayerUserAccess && (!this.bulkPlayerUserAccess.isAllow || !this.normalPlayerUserAccess.isAllow)) {
      const parentEl = this.infoEle.nativeElement;
      this.infoStripAvailable = true;
      let infoWrapper;
      if (!this.bulkPlayerUserAccess.isAllow) {
        const message = this._localization.captions.breakpoint[UserAccessBreakPoints.BULKCANCEL];
        infoWrapper = this._Utilities.GetInfoStrip(message);
      } else if (!this.normalPlayerUserAccess.isAllow) {
        const message = this._localization.captions.breakpoint[UserAccessBreakPoints.CANCELPLAYERS];
        infoWrapper = this._Utilities.GetInfoStrip(message);
      }
      this.rendrer.insertBefore(parentEl, infoWrapper, parentEl.childNodes[0]);
    }
  }

  onDragPlayers(playersDragged) {
    this.draggedPlayersList = playersDragged;
  }

  async CanChangeBulkView(arg) {
    this.selectedPlayer = null;
    this.selectedPlayer = arg;
    await this.getPlayerDetails(arg.bookingId);
    this.isPlayerSelected = true;
    await this.validatePlayerCount();
  }

  async SetDefaultValuesForBulkView(player) {
    this.notifyParent.emit(false);

    this.cdr.detectChanges();
  }

  private checkAllocationBlockPermission(allocationCodePermissions?: allocationBlockPlayerTypePermission[]): boolean {
    let res = true;
    if (this.isAllocationBlockEnabled) {
      let nonaccessiblePlayer = [];
      const allocationBlock = this.teeTimeInfo.allocation;
      const cancelPlayerInfo = this.cancelPlayerForm.value.checkBoxItems.filter(x => x.checkBox).filter(x => x.playerTypeId != 0);
      if (allocationBlock && allocationBlock != null && this.isAllocationBlockEnabled && cancelPlayerInfo && cancelPlayerInfo.length > 0) {
        let allocationBlocks = [];
        if (allocationCodePermissions && allocationCodePermissions != undefined) {
          allocationBlocks = allocationCodePermissions.filter(x => x.allocationBlockId == (allocationBlock && allocationBlock.id));
        } else {
          allocationBlocks = this.allocationCodePermissions.filter(x => x.allocationBlockId == (allocationBlock && allocationBlock.id));
        }
        cancelPlayerInfo.forEach(playerInfo => {
          const allocationPlayer = allocationBlocks.filter(x => x.playerTypeId == playerInfo.playerTypeId);
          if (allocationPlayer.length == 0) {
            nonaccessiblePlayer.push(playerInfo.playerType);
          }
        });
        if (nonaccessiblePlayer.length > 0) {
          const uniqueNonAccessPlayer = _.uniq(nonaccessiblePlayer);
          let name = '';
          if (uniqueNonAccessPlayer.length > 0) {
            name = uniqueNonAccessPlayer.join(', ')
          }
          this._Utilities.showAllocationCodePermissionDeniedPopup(name, this._localization.captions.common.CancelNotAllowed);
          res = false;
        }
      }
    }
    return res;
  }

  private async setCancellationNote(){
    let tobeCancelledPlayers;
    let golfNowPlyrs= 0;
    let breakItiration = false;

      this._cancelPlayerBusiness.allowCancellationOfGolfNowPlayers().then(x => {
          this.isGolfNowEnabled = Boolean(sessionStorage.getItem(GolfSessionKey.GolfNowEnabled));
          this.allowCancellationOfGolfNowBooking= x;
    });
    
    tobeCancelledPlayers = await this.playersData.map(o => o.id);
    let currentSlotPlyrs = this.teeTimeInfo.playerDetail.filter(o => o.bookingSource === BookingSource.GolfNow).map(o => o.playerId);
    let message: NotifierBar = {
      class: "icon-servicecharge",
      value: '',
      color: "#fff16e"
    };
    
    tobeCancelledPlayers.forEach(element => {
      if(currentSlotPlyrs && currentSlotPlyrs.length > 0 ){
        if(breakItiration) 
          return;
        currentSlotPlyrs.find(o => o == element) != -1 ? golfNowPlyrs++ : '';
          if(golfNowPlyrs > 0) { 
            message.value = this.lblcaptions.GolfNowPlayersExcluded;
            breakItiration = true;
          }
        }
    });
    if(breakItiration)
      this.notifierBar = message;
  }

  async ApplyCancellationCharges(playerDetails:number[], scheduledTeeTimeUnPaidPlayer:ScheduledTeeTimeUnPaidPlayer[], 
    selectedPlayerIds:number[] = [],selectedScheduledTeeTimePlayerIds:number[] = [],cancelPlyr:any)
  {
    let outletId = await this._unPaidPlayersService.getOutLetId();  
    if(selectedPlayerIds.length == 1 && scheduledTeeTimeUnPaidPlayer)
    {
      if(scheduledTeeTimeUnPaidPlayer[0].scheduledAPIDateTime == '' ||scheduledTeeTimeUnPaidPlayer[0].scheduledAPIDateTime == null)
      {
        scheduledTeeTimeUnPaidPlayer[0].scheduledAPIDateTime = this._localization.convertDateToAPIdate(scheduledTeeTimeUnPaidPlayer[0].scheduledDateTime);
      }
      let cancellationChargeLinkedItem;
      let cancelPlayers = this._teeTimeCancellationNoShowFeeUtilities.FormNoShowCancelPlayers(playerDetails, 
        this.cancelPlayerForm.value.cancelComment,(this.isBulk) ? Actions.BULKCANCEL : "",outletId, false);
      this._retailSharedVariableService.SelectedOutletId = outletId;
      this._retailSharedVariableService.SelectedPlayers = scheduledTeeTimeUnPaidPlayer;
      this._Utilities.ToggleLoader(true);
      let playerCancellationFees = await this._TeeTimeDataService.ApplyCancellationCharges(cancelPlayers);
      let lineNumber = (playerCancellationFees?.cartFeeRetailItemId > 0 ? 1 : 0) + (playerCancellationFees?.greenFeeRetailItemId > 0 ? 1 : 0) + 
      (playerCancellationFees?.depositsRetailItemCnt > 0 ? playerCancellationFees?.depositsRetailItemCnt : 0);
      if(playerCancellationFees?.charges > 0)
      {
        cancellationChargeLinkedItem = await this._teeTimeCancellationNoShowFeeUtilities.FormCancellationRetailItem(playerCancellationFees,
        lineNumber,playerCancellationFees?.deposits?.length > 0,scheduledTeeTimeUnPaidPlayer[0]);
      }
      this._Utilities.ToggleLoader(false);
      if(scheduledTeeTimeUnPaidPlayer != null && scheduledTeeTimeUnPaidPlayer.length == 1)
      {
        cancellationChargeLinkedItem = cancelPlyr[0].isIconClicked ? null : (cancellationChargeLinkedItem ? [cancellationChargeLinkedItem] : null);
        if((scheduledTeeTimeUnPaidPlayer[0].playerStatus & PlayerPaymentstatus.paid) != 0)
        {
          this.dialogData.isFromPopup = true;
          this.dialogRef.close();
          this._playerTypeRateTypeChangeService.ProceedRefund(this.teeTimeInfo,  
            (cancellationChargeLinkedItem), true,this.dialogRef, FromAction.Cancellation,scheduledTeeTimeUnPaidPlayer);
        }
        else if(scheduledTeeTimeUnPaidPlayer[0].deposits?.length == 0)
        {
          if(playerCancellationFees != null)
          {
            if(cancellationChargeLinkedItem != null)
            {
              this._retailSharedVariableService.selectedProducts.push(cancellationChargeLinkedItem[0]);
              this._retailSharedVariableService.selectedProducts = [...this._retailSharedVariableService.selectedProducts];
              this.dialogData.isFromPopup = true;
              this._teeTimeCancellationNoShowFeeUtilities.RemoveGreenFeeItemCartFeeItem(scheduledTeeTimeUnPaidPlayer[0]);  
              this.dialogRef.close();
              this._playerTypeRateTypeChangeService.ProceedToOrderSummary(scheduledTeeTimeUnPaidPlayer,this.dialogRef,
              FromAction.Cancellation,true,true,[cancellationChargeLinkedItem]);
            }
            else 
            {
              this._retailSharedVariableService.selectedProducts  = [];
              this._retailSharedVariableService.selectedProducts = [...this._retailSharedVariableService.selectedProducts];
              this.CancelTeeTimes(selectedPlayerIds,selectedScheduledTeeTimePlayerIds);
              let cancellationNoShowLedger = this._teeTimeCancellationNoShowFeeUtilities.FormCancellationNoShowLedger(playerCancellationFees,cancelPlyr[0].isIconClicked);
              this.CreateCancellationNoShowLedger(cancellationNoShowLedger);
              this.dialogRef.close();
              return;
            }
          }
        }
        else if(scheduledTeeTimeUnPaidPlayer[0].deposits?.length > 0)
        {
          let depositAmount = 0;
          this.dialogData.isFromPopup = false;
          scheduledTeeTimeUnPaidPlayer[0].deposits.forEach(deposit => { depositAmount += deposit.amount; });
          scheduledTeeTimeUnPaidPlayer[0].deposit = depositAmount;
          this.dialogRef.close();
          cancellationChargeLinkedItem =  cancelPlyr[0].isIconClicked ? null : (cancellationChargeLinkedItem ? cancellationChargeLinkedItem : null);
          this._playerTypeRateTypeChangeService.ProceedDepositRefund(scheduledTeeTimeUnPaidPlayer, 
            (this.teeTimeInfo?.course?.Id ?? scheduledTeeTimeUnPaidPlayer[0].courseId), this.teeTimeInfo.time, true, cancellationChargeLinkedItem,null, 
            FromAction.Cancellation);
        }
      }
    }
    else if(selectedPlayerIds.length > 1)
    {
      let modifiedPlayerIds = this.cancelPlayerForm.value.checkBoxItems.filter(x => x.isIconClicked ? x.checkBox && x.isIconClicked : x.checkBox && x.tokenTransId != 0).map(a => a.id);
      let modifiedScheduledTeeTimePlayerIds = this.cancelPlayerForm.value.checkBoxItems.filter(x => x.isIconClicked ? x.checkBox && x.isIconClicked : x.checkBox && x.tokenTransId != 0).map(a => a.scheduledTeeTimePlayerId);
      this.ApplyCancellationChargesForMultiplePlayers(modifiedPlayerIds, outletId, cancelPlyr, 
        modifiedScheduledTeeTimePlayerIds);
    }
  }
  async ApplyCancellationChargesForMultiplePlayers(selectedPlayerIds: number[] = [],outLetId:number,cancelPlyr,selectedScheduledTeeTimePlayerIds: number[]) {
    let cancelPlayers = this._teeTimeCancellationNoShowFeeUtilities.FormNoShowCancelPlayers(selectedPlayerIds, this.cancelPlayerForm.value.cancelComment,(this.isBulk) ? Actions.BULKCANCEL : "",outLetId);
    cancelPlayers.playerIds = selectedPlayerIds;
    cancelPlayers.fromRetail = false;
    cancelPlyr.forEach(o => { cancelPlayers.playerWaivedOff[o.id] = o.isIconClicked; });
    
    this._cancelPlayerBusiness.ApplyCancellationCharges(cancelPlayers,
      selectedPlayerIds,this.teeTimeInfo.course.id,this.teeTimeInfo.time,(this.isBulk) ? Actions.BULKCANCEL : "",
      this.cancelPlayerForm.value,this.convertWaitlist.bind(this, selectedPlayerIds), 
      this.teeTimeInfo,selectedScheduledTeeTimePlayerIds);
  }

  async CancelTeeTimes(selectedPlayerIds = [],selectedScheduledTeeTimePlayerIds = [])
  {
    this._cancelPlayerBusiness.cancelTeeTimePlayers(
      selectedPlayerIds,
      this.teeTimeInfo.course.id,
      this.teeTimeInfo.time,
      (this.isBulk) ? Actions.BULKCANCEL : "",
      this.cancelPlayerForm.value,
      this.convertWaitlist.bind(this, selectedPlayerIds), this.teeTimeInfo,selectedScheduledTeeTimePlayerIds).then(
        o => {
          this.dialogRef.close('saved');
          this.quickLoginUtils.resetQuickIdDetails();
        });
  }

  async iconClick(eve) {
    if(this.isWaiveOffAccess)
    {
      await this.playersData.forEach(s => 
          {
            if(s.id == eve.id)
            {
              s.isIconClicked = !s.isIconClicked;
            }
          })
      this.playersData = [...this.playersData];
    }
    else
    {
      this._cancelPlayerBusiness.showBreakPointError(UserAccessBreakPoints.WAIVEOFFCHARGES);
    }
  }

  async checkBoxChange(rowData?,from?) {
    let cancelledTeeTimePlayers = this.cancelPlayerForm.value.checkBoxItems;
    await this.playersData.forEach
      (
        x => {
          x.checked = cancelledTeeTimePlayers.find(c => c.id == x.id).checkBox == true ? true : false;
          x.isIconClicked = (from == 'uncheck' && x.id == rowData.id) ? false : x.isIconClicked
        }
      )
    this.playersData = [...this.playersData];
  }

  async alertIconClick(eve) {
    let outletId = await this._unPaidPlayersService.getOutLetId();
    let playerDetail = eve.id;
    let cancelPlayers = this._teeTimeCancellationNoShowFeeUtilities.FormNoShowCancelPlayers(playerDetail, this.cancelPlayerForm.value.cancelComment,"",outletId);
    let playerCancellationFees = await this._TeeTimeDataService.ApplyCancellationCharges(cancelPlayers);
    this.playersData.forEach(s => 
      {
        if(s.id == eve.id)
        {
          s.cancellationCharge = this._localization.localizeCurrency(playerCancellationFees.charges, false);
        }
      });
    this.playersData = [...this.playersData];
  }

  async CreateCancellationNoShowLedger(cancellationNoShowLedger: CancellationNoShowLedger) 
  {
    this._cancelPlayerBusiness.CreateCancellationNoShowLedger([cancellationNoShowLedger]);
  }
}

