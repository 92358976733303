import { Pipe, PipeTransform } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';

@Pipe({
  name: 'totalcountPipe'
})
export class CommontableCountPipe implements PipeTransform {
    captions;
    totalMsg: string;
    constructor(public localization: Localization, )
    {
     this.captions=this.localization.captions;
    }
  transform(inputArray: any[], Value: string, headerKey: any[]): string {
    if (!inputArray) return   this.totalMsg = this.localization.replacePlaceholders(this.captions.lbl_totalRecordsFoundMsg, ['count'], [inputArray.length]);
    if (!Value) return this.totalMsg = this.localization.replacePlaceholders(this.captions.lbl_totalRecordsFoundMsg, ['count'], [inputArray.length]);;
    let filterArray=inputArray.filter(result => {
      for (let key in result) {
        if (typeof (result[key]) == 'string' && result[key].toLowerCase().includes(Value.toLowerCase())) {
          if (headerKey.indexOf(key) != -1) {
            return result[key].toLowerCase().includes(Value.toLowerCase());
          }
        }
        else if (typeof (result[key]) == 'number') {
          if (headerKey.indexOf(key) != -1) {
            let matchedValue = Number(result[key].toString().toLowerCase().includes(Value.toLowerCase()));
            if (matchedValue) {
              return matchedValue;
            }
          }
        }
      }
    });
    return this.totalMsg = this.localization.replacePlaceholders(this.captions.lbl_totalRecordsFoundMsg, ['count'], [filterArray.length]);
  }
}


