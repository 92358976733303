import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { UI } from '../event-scheduler.model';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { ActionMode } from 'src/app/common/enums/shared-enums';
import { ConstantPool } from '@angular/compiler';
import { ReportAPIOptions } from 'src/app/reports/report.model';
import { Localization } from 'src/app/common/localization/localization';
import { ExecuteQuery, ModuleName} from 'src/app/common/shared/shared/event-scheduler/event-scheduler.model'

@Component({    
  selector: 'app-shared-create-event-schedule',
  templateUrl: './create-event-schedule.component.html',
  styleUrls: ['./create-event-schedule.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateEventScheduleComponent implements OnInit {
  @Output() handleClickEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @Output() formReady = new EventEmitter();
  @Output() notifyReportAPIOptions: EventEmitter<ReportAPIOptions> = new EventEmitter();
  @Output() reportOptionChange: EventEmitter<UntypedFormGroup> = new EventEmitter();
  @Output() editSlider = new EventEmitter();
  @Output() emitReportSelector = new EventEmitter();
  @Output() slideEmit = new EventEmitter();
  @Output() retailReportEmitter = new EventEmitter();

  @Input() crudInput: UI.CrudInput;
  createButton: ButtonValue;
  cancelButton: ButtonValue;
  captions;
  eventDetailsForm: UntypedFormGroup;
  notificationScheduleForm: UntypedFormGroup;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  eventDetailsData: any;
  notificationData: any;
  report : any;
  dropDownEmit : any;
  saveReportConfig: any;
  executeQuery : ExecuteQuery;
  isReportAdded: boolean = false;
  @Input('report') 
  set setInputs(value){
    this.report = value;
  }
  @Input('dropDownEmit') 
  set setdropdownConfig(value){
    this.dropDownEmit = value;
  }
  @Input('saveReportConfig') 
  set setsaveReportConfig(value){
    this.saveReportConfig = value;
  }
  constructor(private localization: Localization) {
    this.captions = this.localization.captions;
  }

  @Input('executeQueryConfig')
  set executeQueryConfig(value)
  {
  if(value){
    this.executeQuery = value;
  }
}

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.createButton = {
      type: 'primary',
      label: this.crudInput.actionButton,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel
    };
    this.eventDetailsData = this.crudInput.form && this.crudInput.form.eventDetails;
    this.notificationData = this.crudInput.form && this.crudInput.form.notificationDetails;
  }

  onEventDetailsReady(event) {
    this.eventDetailsForm = event;
    this.eventDetailsForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.updateCreateButton();
    });
  }

  onNotificationsScheduleReady(e) {
    this.notificationScheduleForm = e;
    this.notificationScheduleForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.updateCreateButton();
    });
  }

  updateCreateButton() {
    if (this.eventDetailsForm && this.notificationScheduleForm) {
      this.createButton.disabledproperty = !(this.eventDetailsForm.valid && this.notificationScheduleForm.valid && 
                      (this.notificationScheduleForm.dirty || this.eventDetailsForm.dirty) && this.isReportAdded);
      this.createButton = { ...this.createButton };
    }
  }

  create() {
    const returnObj = {
      mode: this.crudInput.mode,
      eventDetailsData: this.eventDetailsForm,
      notificationScheduleData: this.notificationScheduleForm,
      formValid: (this.eventDetailsForm.valid && this.notificationScheduleForm.valid),
      executeQuery :  this.mapExecuteQuery(this.eventDetailsForm.value.customQuery ? this.eventDetailsForm.value.customQuery : ''),
      scheduleOperationDetailConfig: this.eventDetailsForm.value.operationSchedule ? this.eventDetailsForm.value.operationSchedule : '',
      scheduleadhocReportConfiguration: this.eventDetailsForm.value.adhocReportScheduler ? this.eventDetailsForm.value.adhocReportScheduler : '',
    };
    this.handleClickEvent.emit(returnObj);
  }

  mapExecuteQuery(data)
  {
    if(data)
    {
      const obj = this.executeQuery = {
        queryId : data.value,
        queryName : data.viewValue,
        moduleName : data.otherData
      }
      return obj;
     }
  }

  cancel() {
    this.handleClickEvent.emit({
      mode: ActionMode.cancel,
      form: (this.eventDetailsForm.valid && this.notificationScheduleForm.valid)
    });
  }

  notifyEventScheduler(e: ReportAPIOptions){
    if (e) {
      this.isReportAdded = true;
    } else {
      this.isReportAdded = false;
    }
    this.notifyReportAPIOptions.emit(e);
    this.updateCreateButton();
  }

  onReportOptionChange(e: UntypedFormGroup){
    this.reportOptionChange.emit(e);
  }
  edittedValues(e){
    this.editSlider.emit(e);
  }
  reportSelector(e){
    this.emitReportSelector.emit(e);
  }
  slideSelector(e){
    this.slideEmit.emit(e);
  }
  retailReport(e) {
    this.retailReportEmitter.emit(e);
  }
}
