import { Component, OnInit, Output, EventEmitter, OnDestroy, Inject, ChangeDetectorRef, ViewChild, Renderer2, ViewEncapsulation } from '@angular/core';
import { ClearBusiness } from './clear-player-business';
import { ClearPlayerService } from './clear-player-service';
import { ReplaySubject } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { StepperService } from '../../shared/components/stepper/stepper.service';
import { takeUntil } from 'rxjs/operators';
import { CheckboxTableHeader, DialogCloseObj, AlertType, ButtonType, ComponentDetails, AlertAction } from 'src/app/shared/shared-models';
import { ClearPlayer } from './clear-player-model';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TeeSheetSingleCourse } from 'src/app/tee-time/shared/tee-sheet/tee-sheet.singleCourse';
import { TeeSheetMultiCourse } from 'src/app/tee-time/shared/tee-sheet/tee-sheet.mulitCourse';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { TeeTimeDataService } from 'src/app/shared/data-services/golfschedule/TeeTime.data.service';
import { WaitlistBusiness } from 'src/app/tee-time/waitlist/waitlist.business';
import { WaitlistService } from 'src/app/tee-time/waitlist/waitlist.service';
import { PlayerTypeService } from 'src/app/shared/data-services/golfschedule/playertype.data.service';
import { RateTypeDataService } from 'src/app/shared/data-services/golfschedule/ratetype.data.service';
import { CourseDataService } from 'src/app/shared/data-services/golfmanagement/course.data.service';
import { CreateWaitlistModalService } from 'src/app/tee-time/waitlist/create-waitlist-modal/create-waitlist-modal.service';
import * as WaitlistInterface from 'src/app/tee-time/waitlist/waitlist.model';
import { ConvertWaitlistModalComponent } from '../convert-waitlist-modal/convert-waitlist-modal.component';
import { CommonPopupComponent } from 'src/app/shared/components/common-popup/common-popup.component';
import {BookingSource, ButtonAction, GolfSessionKey } from 'src/app/shared/global.constant';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import _ from 'lodash';
import { TeeTimeFormat } from 'src/app/shared/models/teesheet.form.models';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { GolfImageService } from 'src/app/shared/data-services/Image/golf.Image.service';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { allocationBlockPlayerTypePermission } from 'src/app/settings/user-setup/booking-permission/booking-permission.model';
import { TeeTimesActionBusiness } from 'src/app/tee-time/shared/tee-action.business';
import { UserAccessModel } from 'src/app/common/dataservices/authentication/useraccess-model.model';
import { NotifierBar } from 'src/app/shared/components/note/note.model';

@Component({
  selector: 'app-clear-player-modal',
  templateUrl: './clear-player-modal.component.html',
  styleUrls: ['./clear-player-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ClearBusiness, ClearPlayerService, TeeTimeDataService, WaitlistBusiness, WaitlistService, PlayerTypeService, RateTypeDataService, CourseDataService, CreateWaitlistModalService, TeeTimesActionBusiness]
})
export class ClearPlayerModalComponent implements OnInit, OnDestroy {

  clearPlayerForm: UntypedFormGroup;
  @Output() notifyParent = new EventEmitter();
  public $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  headerOption: CheckboxTableHeader[];
  tableData: ClearPlayer[]; data: any;
  selectedPlayers: [];
  bodyContentdata: Promise<ClearPlayer[]>;
  captions: any;
  bulkBookingId: string = '';
  popUpComponentDetails: ComponentDetails;
  waitList: WaitlistInterface.WaitListViewModel[];
  bulkEditUserAccess: UserAccessModel.BreakPointResult;
  normalBookUserAccess: UserAccessModel.BreakPointResult;
  mixedPlayerSlot: boolean = false;
  isBulk: boolean = false;
  mixedBulkPlayerGroup: boolean = false;
  @ViewChild('infoElement') infoEle: any;
  playerCaptions: any;
  selectedPlayer: any;
  teeTimeFormat = TeeTimeFormat;
  isPlayerSelected: boolean;
  $destroy: ReplaySubject<boolean> = new ReplaySubject(1);
  allocationCodePermissions: allocationBlockPlayerTypePermission[] = [];
  isAllocationBlockEnabled: boolean;
  notifierBar: NotifierBar;
  lblcaptions: any;
  allowCancellationOfGolfNowBooking : boolean = false;
  automationId:string="ClearPlayerModal";
  isGolfNowEnabled: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ClearPlayerModalComponent>,
    private _FormBuilder: UntypedFormBuilder,
    private _teeSheetSingleCourse: TeeSheetSingleCourse,
    private _teeSheetMultiCourse: TeeSheetMultiCourse,
    private _localization: GolfLocalization,
    private _clearPlayerBusiness: ClearBusiness,
    public _StepperService: StepperService,
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private cdr: ChangeDetectorRef,
    private _dialog: MatDialog,
    private waitlistBusiness: WaitlistBusiness,
    private _utilities: GolfUtilities,
    private rendrer: Renderer2,
    private _imageService: GolfImageService, private propertyInformation: RetailPropertyInformation,
    public quickLoginUtils: QuickLoginUtilities,
    private teeTimesActionBusiness: TeeTimesActionBusiness
  ) { }

  async ngOnInit() {
    this.captions = this._localization.captions.settings;
    this.lblcaptions = this._localization.captions.teetime;
    this.data = this.dialogData.info;
    this.isBulk = this.dialogData.isBulk;
    this.bulkBookingId = this.dialogData.info.bulkBookingId ? this.dialogData.info.bulkBookingId : '';
    this.headerOption = this._clearPlayerBusiness.GetHeaderOption();
    this.playerCaptions = this._localization.captions.settings.players;
    this.clearFormGenerator();
    this.notifyParent.emit(true);
    this.CheckMixedPlayers(this.dialogData, this.isBulk);
    await this.validateUserAccess(this.mixedPlayerSlot, this.isBulk);
    this.clearPlayerForm.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(x => {
      const checkedData = this.clearPlayerForm.value.checkBoxItems ? this.clearPlayerForm.value.checkBoxItems.some(x => x.checkBox) : false;
      this.notifyParent.emit(this.clearPlayerForm.valid && checkedData);
    });

    this._StepperService.valueChange.pipe(takeUntil(this.$destroyed)).subscribe((x: DialogCloseObj) => {
      if (x.type.toLowerCase() == 'process') {
        this.submitForm(x);
      }
      if (x.type == 'backClick') {
        this.getGeneralInformation(this.data.scheduledTeeTimeId, this.data.course.course, 'back').then(() => {
          this.selectedPlayer = null;
          this.isPlayerSelected = false;
        });
      }
    });

    if (!this.mixedBulkPlayerGroup) {
      this.bulkBookingId = this.dialogData.info.bulkBookingId ? this.dialogData.info.bulkBookingId : '';
      this.getGeneralInformation(this.data.scheduledTeeTimeId, this.data.course.course).then(() => {
        this.validatePlayerCount();
      });
    }
    this._imageService.getProfileImages(this.playerList, "imageReferenceId");
    this.isAllocationBlockEnabled = this._utilities.IsAllocationCodePermissionEnabled();
    if (this.isAllocationBlockEnabled) {
      this.allocationCodePermissions = this.dialogData.allocationCodePermissions;
    }
  }
  playerList(playerList: any, arg1: string) {
    throw new Error('Method not implemented.');
  }
  private validatePlayerCount() {
    if (this._clearPlayerBusiness.playersCount <= 0) {
      if (this._clearPlayerBusiness.checkInCheckOutCount > 0) {
        this._utilities.showError(`${this._localization.captions.common.ClearNotAllowed} ${this._localization.captions.teetime.CheckInCheckOutRestrictedValidation}`);
        this.dialogRef.close('saved');
      }
      else {
        this._clearPlayerBusiness.showAlertMessage(this.captions.clearNoPlayersAvailable, AlertType.Warning, ButtonType.Ok, (res) => {
          if (res === AlertAction.CONTINUE) {
            // this.dialogRef.close('saved'); - dev identified bug
          }
        });
      }
    }
  }
  private CheckMixedPlayers(dialogData, isBulk: boolean) {
    if (dialogData.info.playerDetail && dialogData.info.playerDetail.length > 0) {
      let bulktee = dialogData.info.playerDetail.filter(x => !x.isBlocked && x.teeTimeFormat === TeeTimeFormat.BulkTee);
      let normalTee = dialogData.info.playerDetail.filter(x => !x.isBlocked && x.teeTimeFormat !== TeeTimeFormat.BulkTee);
      this.playerList = this.dialogData.info.playerDetail.filter(x => !x.isBlocked && x.teeTimeFormat === TeeTimeFormat.BulkTee);
      const bulkDistinctGroup = _.uniq(_.map(this.dialogData.info.playerDetail.filter(x => !x.isBlocked && x.teeTimeFormat === TeeTimeFormat.BulkTee), 'confirmationNumber'));
      this._StepperService.setBackEnable(false);
      if (bulktee.length > 0 && normalTee.length > 0) {
        this.mixedPlayerSlot = true;
      }
      if (isBulk && bulkDistinctGroup && bulkDistinctGroup.length > 1) {
        this.mixedBulkPlayerGroup = true;
        this._StepperService.setBackEnable(true);
      }
    }
  }

  private async validateUserAccess(mixedPlayerSlot: boolean, isBulk: boolean) {
    if (mixedPlayerSlot && !isBulk) {
      this.bulkEditUserAccess = await this._clearPlayerBusiness.validateMixedBreakPoints(UserAccessBreakPoints.BULKCLEAR, false);
      this.normalBookUserAccess = await this._clearPlayerBusiness.validateMixedBreakPoints(UserAccessBreakPoints.ClearPlayers, false);
      this.updateUserAccessInfo();
    }
  }

  clearFormGenerator() {
    this.clearPlayerForm = this._FormBuilder.group({});
  }

  async getGeneralInformation(scheduleId: number, courseName: string, bulkBookingId?) {
    this.tableData = await this._clearPlayerBusiness.getGeneralInformation(scheduleId, courseName, bulkBookingId ? bulkBookingId : this.bulkBookingId);
    this.tableData.forEach(x => {
      if (this.mixedPlayerSlot && this.bulkEditUserAccess && this.normalBookUserAccess && (!this.bulkEditUserAccess.isAllow || !this.normalBookUserAccess.isAllow)) {
        x.hasAccessDisabled = x.teeTimeFormat === TeeTimeFormat.BulkTee ?
          !this.bulkEditUserAccess.isAllow : !this.normalBookUserAccess.isAllow;
        x.checked = !x.hasAccessDisabled;
      }
      if (x.isBlocked) {
        x.player = this._localization.captions.teetime.lblBlocked;
        x.amount = '';
        x.playerType = '';
        x.rateType = '';
      }
      return x;
    });
    console.log(this.tableData);


      this._clearPlayerBusiness.allowCancellationOfGolfNowPlayers().then(x => {
          this.isGolfNowEnabled = Boolean(sessionStorage.getItem(GolfSessionKey.GolfNowEnabled));
          this.allowCancellationOfGolfNowBooking= x;
    });

    if(this.data.playerDetail.some(s => s.bookingSource === BookingSource.GolfNow)){
      let message: NotifierBar = {
        class: "icon-servicecharge",
        value: this.lblcaptions.GolfNowPlayersExcluded,
        color: "#fff16e",
      }
      this.notifierBar = message;
    }
  }

  submitForm(x) {
    // TBD Null check
    let scheduledPlayerIds = this.clearPlayerForm.value.checkBoxItems.filter(x => x.checkBox).map(a => a.id);
    let selectedScheduledTeeTimePlayerIds = this.clearPlayerForm.value.checkBoxItems.filter(x => x.checkBox).map(a => a.scheduledTeeTimePlayerId);
    if (scheduledPlayerIds.length > 0 && this.checkAllocationBlockPermission()) {
      if (this._clearPlayerBusiness.checkSelectedPlayersHasAnyPendingTransaction(scheduledPlayerIds, this.ClearPlayers.bind(this), this.dialogRef)) {
        return;
      }
      if (this._clearPlayerBusiness.checkSelectedPlayersInCart(scheduledPlayerIds)) {
        this._clearPlayerBusiness.showAlertMessage(this.captions.clearCartMessage,
          AlertType.Warning, ButtonType.Ok, null);
      }
      else {
        return this.ClearPlayers(scheduledPlayerIds,selectedScheduledTeeTimePlayerIds);
      }
    }
  }

  async Clear(scheduledPlayerIds: any, selectedScheduledTeeTimePlayerIds?:number[]) {
    this._utilities.ToggleLoader(true);
    this._clearPlayerBusiness.clearPlayers(this.data.scheduledTeeTimeId, scheduledPlayerIds,
      this.convertWaitlist.bind(this), this.data,selectedScheduledTeeTimePlayerIds).then(
        o => {
          this.quickLoginUtils.resetQuickIdDetails();
          this.dialogRef.close('saved');
          const _date: Date = this._localization.getDate(this.data.time);
        });
  }

  ClearPlayers(scheduledPlayerIds,selectedScheduledTeeTimePlayerIds) {
    let quickIdConfig = this.propertyInformation.getQuickIdConfig;
    if (quickIdConfig && quickIdConfig.teeTimeClear) {
      const quickLoginDialogRef = this.quickLoginUtils.QuickLogin();
      quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroy)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
        if (quickLoginDialogResult.isLoggedIn) {
          const quickRoleId = this.quickLoginUtils.GetQuickLoginRoleId();
          const roleId = this._localization.GetUserInfo("roleId");
          let isAllocationAllowed = true;
          if (quickRoleId != roleId) {
            const allocationCodePermissionsforQuickId = await this._clearPlayerBusiness.GetAllocationBlockPermissionByRole(quickRoleId);
            isAllocationAllowed = this.checkAllocationBlockPermission(allocationCodePermissionsforQuickId);
          }
          if (isAllocationAllowed) {
            this.Clear(scheduledPlayerIds,selectedScheduledTeeTimePlayerIds);
          }
        }
      });
    } else {
      this.Clear(scheduledPlayerIds,selectedScheduledTeeTimePlayerIds);
    }
  }

  public async convertWaitlist(res) {
    this.waitList = await this.waitlistBusiness.GetWaitListByDate(this.data.time, this.data.course.id,this.data.originalHoleNumber)
    if (this.waitList && this.waitList.length > 0) {
      this._clearPlayerBusiness.showAlertMessage(this.captions.waitlistAvailableMessageForCleared,
        AlertType.Info, ButtonType.YesNo,
        this.invokeWaitlistPopup.bind(this), this.captions.waitlistAvailableHeader);
    }

  }

  public invokeWaitlistPopup(res, arg) {
    if (res == 'YES') {
      this.popUpComponentDetails = {
        componentName: ConvertWaitlistModalComponent,
        popUpDetails: {
          isStepper: false,
          eventName: 'notifyParent',
          bindData: this.waitList
        }
      };
      this._dialog.open(CommonPopupComponent, {
        width: '80%',
        height: '80%',
        data: {
          title: this.captions.convertWaitList,
          update: this.captions.convertToTeeTime,
          cancel: this.captions.cancel,
          componentDetails: this.popUpComponentDetails,
          info: arg,
          showStaticBool: false,
          actionType: ButtonAction.convert


        },
        disableClose: true
      }).afterClosed().pipe(takeUntil(this.$destroyed)).subscribe();
    }
  }

  ngOnDestroy(): void {
    this.$destroyed.next(true);
    this.$destroyed.complete();
    this.quickLoginUtils.resetQuickIdDetails();
  }

  private updateUserAccessInfo() {
    if (this.mixedPlayerSlot && this.bulkEditUserAccess && this.normalBookUserAccess && (!this.bulkEditUserAccess.isAllow || !this.normalBookUserAccess.isAllow)) {
      const parentEl = this.infoEle.nativeElement;
      let infoWrapper;
      if (!this.bulkEditUserAccess.isAllow) {
        const message = this._localization.captions.breakpoint[UserAccessBreakPoints.BULKCLEAR];
        infoWrapper = this._utilities.GetInfoStrip(message);
      } else if (!this.normalBookUserAccess.isAllow) {
        const message = this._localization.captions.breakpoint[UserAccessBreakPoints.ClearPlayers];
        infoWrapper = this._utilities.GetInfoStrip(message);
      }
      this.rendrer.insertBefore(parentEl, infoWrapper, parentEl.childNodes[0]);
    }
  }

  async CanChangeBulkView(arg) {
    this.selectedPlayer = null;
    this.selectedPlayer = arg;
    this.getGeneralInformation(this.data.scheduledTeeTimeId, this.data.course.course, arg.bookingId).then(() => {
      this.isPlayerSelected = true;
      this.validatePlayerCount();
    });
  }

  private checkAllocationBlockPermission(allocationCodePermissions?: allocationBlockPlayerTypePermission[]): boolean {
    let res = true;
    if (this.isAllocationBlockEnabled) {
      let nonaccessiblePlayer = [];
      const allocationBlock = this.data.allocation;
      const cancelPlayerInfo = this.clearPlayerForm.value.checkBoxItems.filter(x => x.checkBox).filter(x => x.playerTypeId != 0);
      if (allocationBlock && allocationBlock != null && this.isAllocationBlockEnabled && cancelPlayerInfo && cancelPlayerInfo.length > 0) {
        let allocationBlocks = [];
        if (allocationCodePermissions) {
          allocationBlocks = allocationCodePermissions.filter(x => x.allocationBlockId == allocationBlock.id);
        } else {
          allocationBlocks = this.allocationCodePermissions.filter(x => x.allocationBlockId == allocationBlock.id);
        }
        cancelPlayerInfo.forEach(playerInfo => {
          const allocationPlayer = allocationBlocks.filter(x => x.playerTypeId == playerInfo.playerTypeId);
          if (allocationPlayer.length == 0) {
            nonaccessiblePlayer.push(playerInfo.playerType);
          }
        });
        if (nonaccessiblePlayer.length > 0) {
          const uniqueNonAccessPlayer = _.uniq(nonaccessiblePlayer);
          let name = '';
          if (uniqueNonAccessPlayer.length > 0) {
            name = uniqueNonAccessPlayer.join(', ')
          }
          this._utilities.showAllocationCodePermissionDeniedPopup(name, this._localization.captions.common.ClearNotAllowed);
          res = false;
        }
      }
    }
    return res;
  }

  
}
