import { ActionMode } from 'src/app/common/enums/shared-enums';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { JobType } from 'src/app/common/Models/notification.model';

export enum EventSchedulerInterval {
    Daily = 1,
    Weekly,
    Monthly,
    Yearly
}
export enum DaysOfWeek {
    Sunday = 1,
    Monday = 2,
    Tuesday = 4,
    Wednesday = 8,
    Thursday = 16,
    Friday = 32,
    Saturday = 64
}
export enum ReportFetchInterval {
    Day = 1,
    Week,
    Month,
    Year
}
export enum GroupName{
Guest,
User
}

export enum PatternType {
    Daily = 1,
    Weekly,
    Monthly,
    Yearly,
    RelativeMonthly,
    RelativeYearly
}

export enum RangeType {
    EndDate = 1,
    Numbered,
    NoEnd
}

export enum Position {
    First,
    Second,
    Third,
    Fourth,
    Last
}
export enum USERPREFERENCE_SCREENTYPE
{
    guestAdvanceSearchConfigure = 1,
    guestScreenConfig = 2,
    guestGeneralInformation = 5,
    guestAdditionalInformation = 6,
    guestPreference = 7,
    guestPaymentInformation = 8,
    reservationDetail = 9,
    stayDetails = 10,
    commentsSpecialRequests = 11,
    reservationPaymentInformation = 12,
    reservationMiscellaneous = 13,
    reservationAdvanceSearch = 14,
    userTheme = 15,
    homeWidget = 16,
    ScheduleConfigure = 17,
    stayDefaults = 18
}
export enum ReportType {
    PDF,
    WORD
}

// tslint:disable-next-line:no-namespace
export namespace UI {
    export interface ScheduleOperationDetail {
        id: number;
        methodName: string;
        methodDescription: string;
    }
   export interface ScheduleAdhocReport {
        labelName: string;
        adhocReportURI: string;
    }
    export interface ScheduleGrid{
        id: number;
        referenceId: number;
        eventName: string;
        distributionList: string[];
        guestIsMessage: boolean;
        guestIsEmail: boolean;
        userIsMessage: boolean;
        userIsEmail: boolean;
        fileUpload: boolean;
        schedule: string;
        recurring: boolean;
        reportName: string;
        menuList: ScheduleActions[];
        status?: number;
    }

    export enum ScheduleActionOptions {
        update,
        delete,
        refreshDistributionList,
        pause
    }

    export enum ScheduleState{
        Running,
        Paused
    }

    export interface ScheduleActions {
        id: ScheduleActionOptions;
        menu: string;
        isDisabled?: boolean;
    }

    export interface CrudInput {
        mode: ActionMode;
        actionButton: any;
        form: any;
        listOrder?: number;
        isEdit?: boolean;
        isViewOnly?: boolean;
        isFrom?: string;
    }

    export interface ScheduleParticulars {
        id: number;
        schedulerName? : string;
        schedulerCode ?: string;
        jobSchedulerId?: number;
        event: DropdownOptions;
        active: boolean;
        guestEmail: boolean;
        guestSms: boolean;
        userEmail: boolean;
        userSMS: boolean;
        guestEmailDL: DropdownOptions;
        guestSMSDL: DropdownOptions;
        guestEmailTemplate: DropdownOptions;
        guestSMSTemplate: DropdownOptions;
        userEmailDL: DropdownOptions;
        userSMSDL: DropdownOptions;
        userEmailTemplate: DropdownOptions;
        userSMSTemplate: DropdownOptions;
        report: DropdownOptions;
        reportType: DropdownOptions;
        reportName: string;
        guestEmailSubj: string;
        userEmailSubj: string;
        type: DropdownOptions;
        sftpRemoteDirectory: string;
        reportCard: ReportCard;
        isSFTP:boolean;
        ftpRemoteDirectory: string;
        isFTP?: boolean;
        sftpSetupId?: number;
        patchReportConfiguration : PatchReportConfiguration;
        patchExecuteQueryConfiguration : PatchExecuteQueryConfig;
       scheduleOperationConfiguration?: ScheduleOperationDetail;
    scheduleadhocReportConfiguration?: ScheduleAdhocReport;
    }

    export interface ReportCard{
        id:number;
        value: any;
        reportModule: DropdownOptions;
        reportName: string;
        reportModuleName: string;
    }
    export interface RecurrenceRule{
        dayMonth: number;
        days: number;
        end: number;
        endDate: Date;
        event: number;
        monthDays: number;
        noOfOccurance: number;
        ofEveryMonth: number;
        ofEveryYear: number;
        recurEveryWeek: number;
        scheduleType: string;
        specificDays: boolean[];
        startDate: Date;
        yearlyMonths: number;
        yearlyDay: number;
        yearlyDays: number;
        startTime:string;
    }
    export interface PatchReportConfiguration{
        reportGroup : number;
        reportAPIOptions : ReportQuery;
    }

    export interface PatchExecuteQueryConfig{
        filterBody :  string;
    }

    export interface ReportQuery{
        code : string;
        format : string;
        downloadFileName : string;
        parameters :  string;
        uRIParams :  string;
        params :  string;
        filterBody :  string;
        dateFormat :  string;
    }
}

// tslint:disable-next-line:no-namespace
export namespace API {
    export interface ScheduleJobConfiguration {
        id: number;
        scheduleJobId: number;
        scheduleJobName: string;
        scheduleCustomUrlConfigurationId?: number;
        description? : string;
        code? : string;
        scheduleJobParticular: ScheduleJobParticular[];
        recurrenceDefinition: string;
        reportConfiguration: string;
        executeQueryConfiguration : string;
        scheduleOperationConfiguration: string;
        outputFileName?: string;
        isRunOnDemand?: boolean;
        fileFormat: number;
        sftpDirectory: string;
        isSFTP?: boolean;
        ftpDirectory: string;
        isFTP?: boolean;
        isJasperReport?: boolean;
        sftpSetupId?: number;
        scheduleState?: number;
    }

    export interface ScheduleJobParticular {
        groupName: string;
        sendMail: boolean;
        sendSMS: boolean;
        emailTemplateId: number;
        smsTemplateId: number;
        emailDistributionId: number;
        smsDistributionId: number;
        scheduleJobConfigurationId: number;
        subject: string;
    }

    export interface ScheduleJob {
        id: number;
        jobName: string;
        isBackGroundJob: boolean;
        isEmail: boolean;
        isSMS: boolean;
        isFileTransfer: boolean;
        isReport: boolean;
        productId: number;
    }

    export interface JobDefaultConfiguration{
        id: number;
        jobName: string;
        jobType: JobType;
    }

    export interface JobCustomUrlConfiguration {
        id: number;
        jobCustomConfigurationId: number;
        jobDefaultConfigurationId: number;
        route: string;
    }

    export interface ScheduleJobWildCard{
        id: number;
        wildCard: string;
        isActive: boolean;
    }

    export interface RecurrenceRule {
        pattern: Pattern;
        range: Range;
    }

    export interface Pattern {
        patternType: number;
        interval: number;
        position: Position;
        dayOfMonth: number;
        month: number;
        daysOfWeek: string[];
        daysOfWeekTracker: string[];
    }

    export interface Range {
        runDate: string;
        rangeType: RangeType;
        startDate: string;
        endDate: string;
        noOfOccurence: number;
        noOfOccurenceCounter: number;
    }

    export interface ScheduleOperationDetail {
        id: number;
        methodName: string;
        methodDescription: string;
        code?: string;
        description?:string;
  }
  export interface ScheduleAdhocReport {
    labelName: string;
    adhocReportURI: string;
  }
}
export interface ReorderFieldDetailsSetup {
    userId: number;
    reorderFieldName: string;
    listOrderLinkId: number;
    isChecked: boolean;
    screenTypeId: number;
}
export interface ReorderFieldDetailsSetup {
    userId: number;
    reorderFieldName: string;
    listOrderLinkId: number;
    isChecked: boolean;
    screenTypeId: number;
    widgetConfig?: string;
}
export interface FormattedReorderFieldDetailsSetup {
    order: number;
    checked: boolean;
    screenTypeId: number;
    displayName: string;
    key?: string;
    disabled: boolean;
    show: boolean;
    templateName?: string;
    widgetConfig?: string;
}
export interface ReportAPIModel {
    code: string;
    format: ReportDownloadFormat;
    downloadFileName: string;
    parameters: { [key: string]: string };  //Dictionary<string,string>
    uRIParams: { [key: string]: string };   //Dictionary<string,string>
    filterBody: any;
    dateFormat: string;
    isJasperReport?: boolean;
    name?: string;
    sftpSetupId? : number
}
export type ReportDownloadFormat = 'PDF' | 'WORD' | 'EXCEL' | 'IMAGE' | 'HTML' | 'CSV' |'RAWDATA';
export interface DaysModel {
    id: number;
    short: string;
    long: string;
    code: string;
    longCode?: string;
}
export class ReportAPIOptions {
    code: string;
    format?: ReportDownloadFormat = 'HTML';
    params: ReportParams[];
    URIParams: ReportParams[];
    filters: any;
    pageBreak: boolean;
    rawDataExport?: boolean = false;
}
export interface ReportParams {
    [parameter: string]: string | number | boolean;
  }

  export class ExecuteQuery
  {
      queryId : string;
      queryName : string
      moduleName : string
  }

  export enum ScheduleJobConfig {
    GenericExtract = 1,
    Reports,
    ExecuteQuery,
    API,
    AdhocReport = 6
}
  export enum ApiConfig {
      ProfitSwordReservationData,
      ProfitSwordRevenueData,
      CasinoCashTrackReservationData,
      CasinoCashTrackPMSData,
      AutoARInvoice
  }

export enum ModuleName {
    PACKAGESETUP = 'PACKAGESETUP'
}

export enum ExtractList {
    Folio = 'Folio',
    GenericExtract= 'GenericExtract',
    Group= 'Group',
    Guest= 'Guest',
    Reservations= 'Reservations',
    Retail= 'Retail',
    RoomInventory= 'RoomInventory',
    Accounting= 'Accounting',
    Membership= 'Membership',
   FrontDesk = 'FrontDesk',
   Sales = 'Sales',
   Default = 'Default'
 }

 export namespace CustomQueryUI {
    export interface CustomQueryStore {
        //  index:number,
        id: number,
        queryId: string,
        name: string,
        modelId: string,
        queryJson: string,
        isSelected: boolean,
        filePrefix: string,
        delimiter: string,
        dateFormat: string,
        isQuotesEnclosed: boolean,
        excludeDecimals: boolean,
        decimalLength: number,
        currency: string,
        depositLedger: string,
        isHeaderNeeded: boolean,
        extractGLAccountNumber: string,
        extractDetails: ExtractDetails[]
    }

    export interface ExtractDetails {
        id: number,
        customQueryStoreId: number,
        columnId: string,
        field: string,
        isEnabled: boolean,
        order: number,
        length: number,
        displayName: string,
    }

    export interface customExportForm {
        format: string | number | null,
        tableDirty: string,
        encloseQuotes: boolean,
        excludeDecimal: boolean,
        filenamePrefix: string,
        dateFormat: string | number,
        enableHeader: boolean,
        customDelimiter: string
    }

    export interface extractDetailTable {
        id: number,
        checked: boolean,
        columnId: string,
        fields: string,
        length: string,
        order: string,
        visibility: boolean,
        orderNumber: number,
        displayName: string,
        dataType: string
    }

    export interface CustomQueryGroup {
        groupName: string,
        queryListItem: QueryListItem[],
    }

    export interface QueryListItem {
        id: string,
        name: string,
        text: string,
        description: string,
    }

    export interface CustomQueryExtractData {
        columnDetails: string,
        reportData: string
    }
}


export namespace CustomQueryAPI {
    export interface CustomQueryStore {
        id: number,
        queryId: string,
        name: string,
        modelId: string,
        queryJson: string,
        isSelected: boolean,
        filePrefix: string,
        delimiter: string,
        dateFormat: string,
        isQuotesEnclosed: boolean,
        excludeDecimals: boolean,
        decimalLength: number,
        currency: string,
        depositLedger: string,
        isHeaderNeeded: boolean,
        extractGLAccountNumber: string,
        extractDetails: ExtractDetails[]
    }

    export interface ExtractDetails {
        id: number,
        customQueryStoreId: number,
        columnId: string,
        field: string,
        displayName: string,
        isEnabled: boolean,
        order: number,
        length: number,
    }

    export interface CustomQueryGroup {
        groupName: string,
        queryListItem: QueryListItem[],
    }

    export interface QueryListItem {
        id: string,
        name: string,
        text: string,
        description: string,
    }

    export interface CustomQueryExtractData {
        columnDetails: string,
        reportData: string
    }
}

export namespace AdhocReportAPI {

    export interface AdhocViewReportPathDeatils {
        resourceType: string,
        version:number | null,
        permissionMask: number,
        creationDate:string,
        updateDate:string,
        label:string,
        description:string,
        uri:string
    }
}
