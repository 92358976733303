import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, ViewEncapsulation, Input, OnInit, EventEmitter, Output, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { DataType } from '../../shared-models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
@Component({
  selector: 'app-golfchip-search',
  templateUrl: './chip-search.component.html',
  styleUrls: ['./chip-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GolfChipSearchComponent implements OnInit, OnChanges {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  chipCtrl = new UntypedFormControl();
  filteredData: DataType[];
  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  captions: any;
  placeholderrefresh : string;
  searchLength = 3;
  @Input('placeholderText')
  set _placeHolder(value: string) {
    this.placeholder = value;
    this.placeholderrefresh = value;  
  }
  
  @Input() directiveType;
  @Input() allowPaste?: boolean = false;
  @Input() searchKey: string[];
  @Input() selectedChipKey: string[];
  @Input() selectedChipKeySeperator = ' ';
  @Input() autoCompleteKeys: string[];
  @Input() autoCompleteKeysSeperator = '|';
  @Input() maxChips = 1;
  @Input() disabled: boolean;
  @Input() showMoreData: boolean;
  @Input() showSeperator = true;
  @Input()
  set initiateSearchValue(value: number) {
    this.searchLength = value ? value : 3;
  }
  chipData: any[] = [];
  searchedText:string;
  @Input('allData')
  set _allData(data: any[]) {
    this.chipData = ((this.searchedText && this.searchedText.length > 0) && (data && data.length>0 )) ? data : [];
    let searchHasLength = (this.searchedText && this.searchedText.length > 0) && (data && data.length === 0) ? []: null;
    this.filteredData = ((this.searchedText && this.searchedText.length > 0) && (data && data.length>0 )) ? data : searchHasLength;
  }

  @Input() selectedData: any[] = [];
  @Output() selectedChipDataEmit: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() searchTextEmit: EventEmitter<string> = new EventEmitter<string>();
  @Output() onChipRemove: EventEmitter<any> = new EventEmitter<any>();
  placeholder: string;
  constructor(private localization: GolfLocalization) {
    this.captions = this.localization.captions.common;
  }

  public searchText(value: string): void {
    const txt = value.trim();
    this.searchedText = txt;
    this.searchTextEmit.emit(txt);
  }

  remove(selectedElement: DataType): void {
    if (this.disabled) {
      return;
    }
    const index = this.selectedData.indexOf(selectedElement);

    if (index >= 0) {
      this.selectedData.splice(index, 1);
    }
    this.disabled = false;
    this.filterData();
    this.checkPlaceHolder();
    this.selectedChipDataEmit.emit(this.selectedData);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.selectedData.length < this.maxChips) {
      this.selectedData.push(event.option.value);
      this.chipInput.nativeElement.value = '';
      this.chipCtrl.setValue(null);
      this.checkPlaceHolder();
      this.filterData();
      this.selectedChipDataEmit.emit(this.selectedData);
    }
  }

  private filterData() {
    this.filteredData = this.chipData.filter(data => (!this.selectedData.find(existingData => existingData.id === data.id)));
  }

  checkPlaceHolder() {
    if (this.selectedData.length > 0) {
      this.placeholder = '';
    } else {
      this.placeholder = this.placeholder ? this.placeholder : this.placeholderrefresh;
    }
  }

  ngOnInit() {
    this.checkPlaceHolder();
  }

  ngOnChanges() {
    this.checkPlaceHolder();
  }

  onRemove(event) {
    this.onChipRemove.emit(event);
  } 
}
