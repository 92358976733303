import { PMSAction } from 'src/app/common/external-request/pms-request.model';

export interface Captions {
  scheduleDateTime: string;
  courseName: string;
  playerName: string;
  phoneNumber: string;
  iDNumber: string;
  status: string;
  action: string;
}

export enum TeeTimeSearchFilterType {
  All = 0
}

export interface SearchResolverData {
  stayFromDate: Date;
  stayToDate: Date;
  courseId: number;
  action: PMSAction;
  playerStatus: number;
  pmsActivityId: string;
}

export interface SearchAPIParameters {
  courseId: number;
  playerstatus: number;
  fromDate: string | Date;
  toDate: string | Date;
  pmsActivityId?: string;
}
export interface TeeTimeSearchData {
  lastName: string;
  firstName: string;
  scheduleDateTime: Date;
  scheduleDatetimeString: string;
  courseName: string;
  playerName: string;
  phoneNumber: string;
  status: string;
  action: string;
  scheduleTeeTimeID: number;
  schedulePlayerID: number;
  courseID: number;
  iDNumber: string;
  statusID: number;
  playerLinkId: string;
  confirmationNumber: string;
  holeNumber: string;
  originalHoleNumber: string;
  packageCode: string;
  rateTypeId: number;
  rateTypeName: string;
  playerTypeId: number;
  playerTypeName: string;
  playerCategoryId: number;
  multiPackTransactionDetailId: number;
  isNegotiated: boolean;
  email: string;
}
export interface FilterCollection {
  TeeTimeFilterData?: TeeTimeFilterData[];
}
export interface TeeTimeFilterData {
  searchKey?: string;
  SearchCriteria?: string[];
}

export interface StatusArray {
  id: number;
  name: string;
  isSelected?: boolean;
}

export enum PlayerPaymentstatus {
  booked = 1,
  noShow = 2,
  cancelled = 4,
  paid = 8,
  unPaid = 16,
  refund = 32,
  CheckIn = 64,
  CheckOut = 128
}

export interface course {
  id?: number;
  description?: string;
  type?: string;
} 

export enum TeeTimeAllocationBlockEvent {
  None = 0,
  FromTeeTime = 1,
  FromBookPopup = 2
}