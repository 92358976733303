<section class="CrudReportSec h-100">

    <form [formGroup]="CrudReportTempForm" class="h-100" autocomplete="off">
        <div class="CrudReportNav LW20">
            <i aria-hidden="true" class="icon-left-arrow cursor" (click)="promptForUnSavedChanges()"></i>
            <label class="pl-1 pt-1 text-capital cursor" (click)="promptForUnSavedChanges()">{{HeaderLabel}}</label>
        </div>


        <div class="CRUD-report-Template-wrapper p-4">
            <div *ngIf="isShowCopy">
                <div class="warning-bar ag_p--3 ag_display--flex"><i aria-hidden="true"
                        class="icon-warning-icon ag_pr--2"></i><span [matTooltip]="message">{{message}}</span><i
                        aria-hidden="true" *ngIf="showClose" class="icon-close ag_ml--auto" (click)="closeBar()"></i>
                </div>
            </div>
            <h4 class="LWB16">{{this.crudereporttemplateCaptions.tempDet}}</h4>
            <div>
                <mat-form-field [floatLabel]="floatLabel" class="crud-w30">
                    <mat-select name="action" formControlName="eventId" required class="LW14"
                        [placeholder]="crudereporttemplateCaptions.event" (selectionChange)="actionChanged($event)"
                        [disabled]="editableObj">
                        <mat-option *ngFor="let type of events" [value]="type.id" [disabled]="type.disabled">
                            {{type.eventName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field [floatLabel]="floatLabel" class="crud-w30">
                    <input matInput class="LW14" [maxlength]="templateCodeMaxlength" inputtype="reservedchar" required
                        [placeholder]="crudereporttemplateCaptions.reportTempCode" [readonly]="editableObj && !isShowCopy"
                        name="templateCode" formControlName="templateCode">
                    <mat-error>
                        {{crudereporttemplateCaptions.missingtempCode}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field [floatLabel]="floatLabel" class="crud-w30">
                    <input matInput class="LW14" [maxlength]="50" inputtype="nospecailchar" required
                        [placeholder]="crudereporttemplateCaptions.reportTempName" [readonly]="isTemplateInUse"
                        name="templateName" formControlName="templateName">
                    <mat-error>
                        {{crudereporttemplateCaptions.missingtempName}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field [floatLabel]="floatLabel" class="crud-w30">
                    <input matInput class="LW14" [placeholder]="crudereporttemplateCaptions.listOrder" name="listOrder"
                        formControlName="listOrder" [maxlength]="4" inputtype="nonnegative,nodecimal">
                    <mat-error>
                        {{crudereporttemplateCaptions.missinglistOrder}}
                    </mat-error>
                </mat-form-field>               
                <div class="crud-w30 d-inline-block">
                    <div class="mr-5 d-inline-block">
                        <div><label class="LW12 pr-2 label-color">{{crudereporttemplateCaptions.setDefault}}</label></div>
                        <app-common-toggle-switch [ngClass]="isViewOnly? 'ag_section--disable' : ''"  formControlName="isDefault" class="toggle-switch">
                        </app-common-toggle-switch>
                    </div>                   
                </div>
            </div>
            <h4 class="LWB16 mb-1 d-inline-block">{{crudereporttemplateCaptions.reportMsg}}</h4>
            <a class="ag_link ag-ml-4 pl-3" (click)="showHtmlContent()">{{this.crudereporttemplateCaptions.preview}}</a>
            <div class="editorsec" [ngClass]="isViewOnly? 'ag_section--disable' : ''"  >
                <div class="leftSection h-100 d-inline-block">
                    <ckeditor class="crudTempEditor"  #ComposeReportTemp [editor]="Editor" [config]="Config"
                        (ready)="onReady($event)" formControlName="htmlContent" (focus)="onChange(ComposeReportTemp)">
                    </ckeditor>
                </div>
                <div class="rightSection h-100 d-inline-block">
                    <ng-container *ngIf="!isGroupingenabled">
                        <h4 class="LWB14 mb-1">{{this.crudereporttemplateCaptions.tags}}</h4>
                        <!-- ( {{filteredkeywords.length}}/{{Entire_keywords.length}} ) -->
                        <mat-form-field class="spa-searchbox w-100" [floatLabel]="floatLabelNever" id="searchInput">
                            <input matInput autocomplete="off" [(ngModel)]="searchText"
                                [ngModelOptions]="{standalone: true}" type="text"
                                [placeholder]="crudereporttemplateCaptions.srchByTag" (input)="enteredSrchText()">
                            <i aria-hidden="true" [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'" class="cursor"
                                (click)="searchText='';enteredSrchText()"></i>
                        </mat-form-field>
                        <hr class="mt-0" />
                        <div class="keywordSec">
                            <button mat-raised-button *ngFor="let item of filteredkeywords" [matTooltip]="item.name"
                                class="crudDetails-btn body-bgcolor whitecolor textellipsis"
                                (click)="elementClick(item.name)">
                                {{item.description}}
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isGroupingenabled">
                        <h4 class="LWB14 mb-1">{{this.crudereporttemplateCaptions.tags}}</h4>
                        <!-- ( {{filteredkeywords.length}}/{{Entire_keywords.length}} ) -->
                        <mat-form-field class="spa-searchbox w-100" [floatLabel]="floatLabelNever" id="searchInput">
                            <input matInput autocomplete="off" [(ngModel)]="searchText"
                                [ngModelOptions]="{standalone: true}" type="text"
                                [placeholder]="crudereporttemplateCaptions.srchByTag" (input)="enteredgrpSrchText()">
                            <i aria-hidden="true" [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'" class="cursor"
                                (click)="searchText='';enteredgrpSrchText()"></i>
                        </mat-form-field>
                        <hr class="mt-0" />
                        <div class="keywordSec grouping-overflow">
                            <ng-container *ngFor="let item of filteredkeywords|sortPipe : ordertype : groupBy;;let i = index">
                                <label  class="w-100 d-block c-grp-label" (click)="openClose($event,item)" *ngIf="(i==0 || item['groupId']!==filteredkeywords[i-1]['groupId'])">{{item.groupName ? item.groupName : crudereporttemplateCaptions.General}} <i *ngIf="!item.isExpanded" aria-hidden="true" class="template-icon-plus">+</i> <i *ngIf="item.isExpanded"  class="template-icon-minus" aria-hidden="true">-</i> </label>
                                <ng-container *ngIf="item.isExpanded">
                                    <button mat-raised-button [matTooltip]="item.name"
                                        class="crudDetails-btn body-bgcolor whitecolor textellipsis"
                                        (click)="elementClick(item.name)">
                                        {{item.description}}
                                    </button>
                                </ng-container>

                            </ng-container>

                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="actions pl-4 pr-2">
            <button mat-button class="text_capitalize LW14"
                [ngClass]="EnablesaveUpdBtn ? 'spa-primary-default' : 'spa-primary-disabled'"
                (click)="save()">{{saveUpdLabel}}</button>
            <button mat-button class="ml-2 text_capitalize LW14 " (click)="promptForUnSavedChanges()">
                {{this.crudereporttemplateCaptions.cancel}}</button>
        </div>
    </form>
</section>