import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AgFieldConfig } from '../../shared-models';

@Component({
  selector: 'app-ag-golfpercentage',
  templateUrl: './ag-percentage.component.html'
  
})
export class AgGolfPercentageComponent  {
  customClass: string;
  errorMessage: string;
  groupName: UntypedFormGroup;
  controlName: string;
  placeHolder: string;
  value: string|number;

  @Input('inputs')
  set inputOptions(value: AgFieldConfig) {
    this.customClass = value.className;
    this.errorMessage = value.errorMessage;
    this.groupName = value.form;
    this.controlName = value.formControlName;
    this.placeHolder = value.placeHolder;
    this.value = value.value;
  }

 
}
