export interface Events{
  id : number;
  eventName : string;
  productId : number;
  isReminder : boolean;
  disabled?: boolean;
}

export interface EventUI{
  id : number;
  description : string;
  reminder : boolean;
}

export interface EventConfiguration {
  eventId: number;
  eventNotificationsGroup: EventNotificationGroup[];
}

export interface VIPType{
  id:number;
  code:string;
  name:string;
}

export interface Collection {  
  list: VIPType[];
}

export interface EventNotificationGroup {
  id: number;
  eventId: number;
  groupName: string;
  emailTemplateId: number;
  smsTemplateId: number;
  emailDistributionId: number;
  smsDistributionId: number;
  sendMail: boolean;
  sendSMS: boolean;
  status : statusFlag;
  eventScheduler : EventScheduler;
  scheduledAt? : Date;
  intakeForm :boolean;  
  vipCodeId :number;
  additionalConfig?: AdditionalConfig;
}

export interface AdditionalConfig{
  guestTypeIds: number[];
  buildingIds: number[];
  emailGuestCategories?: number[]
  smsGuestCategories?: number[],
  authorize: boolean,
  enableEForm :boolean,
  eFormData: EFormData[]
}

export interface EventScheduler{
  id : number;
  eventId : number;
  scheduleType : ScheduleType;
  scheduleAt : Date;
  eventNotificationGroupId : number;
}

export enum ScheduleType {
  None = 0,
  Hourly,
  Weekly,
  Monthly,
  Yearly,
  Custom
}

export interface EventConfigurationUI {
  guestId: number,
  userId : number,
  events: number,
  authorize: boolean,
  guestEmail: boolean,
  guestSms: boolean,
  guestEmailDistLst: number,
  guestSMSDistLst: number,
  guestEmailTemp: number,
  guestSmsTemp: number,
  userEmail: boolean,
  userSms: boolean,
  userEmailDistLst: number,
  userSMSDistLst: number,
  userEmailTemp: number,
  userSmsTemp: number,
  hrs: number,
  min: number,
  days: number,
  sec?: number,
  ISdbdata?:boolean
  status : statusFlag;
  guestEventScheduler : EventScheduler;
  userEventScheduler : EventScheduler;
  intakeForm :boolean,
  enableEForm :boolean;
  vipCodeId :number,
  guestType: number[],
  building: number[],
  guestEmailCategory: number[]
  guestSmsCategory: number[]
  eFormData: EFormData[];
}

export interface NotificationEventTemplate {
  id: number;
  eventId: number;
  templateId: string;
  isDefault: boolean;
  isActive: boolean;
  listOrder: number;
  templateCode: string;
  templateName: string;
  typeId : number;
}

export interface Distribution{
  id : number;
  distributionName : string;
  distributionType : number;
  fromAddress : string;
  distributionConfigs : any;
}

export interface GuestCategory{
  id: number;
  categoryId: number;
  categoryName: string;
}

export enum TemplateType{
  Email = 1,
  SMS = 2
}

export enum DistributionType{
  SMS = 2,
  EMAIL = 1,
  BOTH = 3
}

export enum statusFlag{
  Insert = 1,
  Delete = 2,
  Update = 3
}

export enum DefaultLanguage {
  Default = 0,
  English = 1
}

export enum EventNotification {
  AppointmentBooking=1,
  AppointmentCancellation=2,
  AppointmentReminder=3,
  AppointmentNoShow=4,
  AppointmentUpdate=5,
  AppointmentCheckIn=6,
  AppointmentCheckOut=7,
  SPARetailReceipts=8,
  TeeTimeConfirmation = 9,
  BulkBooking = 10,
  GroupBooking = 11,
  TeeTimeUpdate = 12,
  TeeTimeCancellation = 13,
  TeeTimeNoShow = 14,
  TeeTimeReminder = 15,
  RetailReceipts = 16,
  IntakeForm = 17,
  TeeTimeCompletion = 18,
  RetailProductRetailReceipts = 19,
  BulkUpdate = 42,
  BulkCancel = 43,
  PackageConfirmation = 44,  
  OnlineConfirmation = 47,
  OnlineCompletion = 48,
  OnlineAppointmentConfirmation = 49,
  PreArrivals=20,
  WelcomeMessage=21,
  BookingConfirmation=22,
  ConfirmationLetter=23,
  Consents=24,
  Invoices=25,
  GuestStayFeedback=26,
  Birthday=27,
  Anniversary=28,
  GDPROptInGuest=29,
  BookingModification=30,
  BookingCancellation=31,
  Reactivation=32,
  NoShow=33,
  Groups=34,
  EventsGroups=35,
  EventsReminder=36,
  WeddingsGroups=37,
  PreCheckIn=38,
  GuestLetter=39,
  StayLetter=40,
  VIPArrivals=41,
  BookingCreated=45,
  AccountCreated=46,
  CheckIn=50,
  TransportationInformation =51,
  RMSEvent = 52,
  rGuestBookConfirmation = 53,
  rGuestBookModification = 54,
  rGuestBookCancellation = 55,
  ExpressCheckIn = 56,
  ExpressCheckOut = 57,
  OTABookingConfirmation = 58,
  OTABookingModification = 59,
  OTABookingCancellation = 60,
  AgilysysAuthorize = 61,
  ExpressRoomReady = 62,
  ExpressPreCheckIn = 63,
  ExpressPreCheckOut = 64,
  GenericExtract = 65,
  ADMFailureEvent = 66,
  AgilysysAuthorizeAR = 67,
  GuestStayItinerary = 68,
  FolioStatement = 69,
  BulkReminder = 70,
  BulkCompletion = 71,
  TeeTimeCheckIn = 72,
  TeeTimeCheckOut = 73,

  Eform = 74,
  EForm = 75,
  EFormGolf=76,

  GolfNightAuditReminder = 77,
  SpaNightAuditReminder = 78,
  RetailNightAuditReminder = 79,
  GolfNightAuditCompletion = 80,
  SpaNightAuditCompletion = 81,
  RetailNightAuditCompletion = 82,
  GolfNightAuditFailure = 83,
  SpaNightAuditFailure = 84,
  RetailNightAuditFailure = 85,

  Enquiry = 86,
  Lost = 87,
  Returned = 88,
  GuestDataRequest = 89,
  AuthorizePaymentSpa = 91,
  AuthorizePaymentRetail = 92,
  AuthorizePaymentGolf = 93,
  Concierge =90, 
  JobScheduler = 94,
  GolfGuestDataRequest = 95,
  SPAGuestDataRequest = 96,
  FrostDelay = 97,
  CaddyAssign = 98,
  CaddyUpdate = 99,
  GroupAppointmentConfirmation = 100,
  General = 101,
  AppointmentConfirmation=102,
  CheckOut = 103,
  InquiryConfirmation = 104,
  NightAuditReminder = 105,
  NightAuditCompletion = 106,
  NightAuditFailure = 107,

  
  //Accounting
  PurchaseRequisitionApprovalNotification =108,
  ExportSend = 109,
  CondoMTDStatements =110,

  //Classes
  ClassBooking=111 ,
  ClassCancellation=112,
  ClassCheckIn=113,
  ClassCheckOut=114,
  ClassNoShow=115,
  ClassUpdate=116,
  ClassReminder=117,
  OnlineClassBooking=118,
  NotifyStaff=119

  }

  export interface DmEFormInformation{
    EForm : boolean;
    EFormData: EFormData[];    
  } 

  export interface EFormData{
    eFormID : string;
    linkName: string;  
  } 

  export interface GuestItineraryRequest{
    guestIdList : string[];
    startDate: string;
    endDate: string;  
  } 
