<div class="w-100 ag-pt-2 multi-search" [ngClass]="[auto.isOpen ? 'show' : 'hide']">
  <div class='overlay-div'></div>
  <mat-form-field class="" appearance="outline">
    <mat-chip-list #chipList aria-label="Chip selection">
      <mat-chip *ngFor="let data of selectedData" [selectable]="selectable" [removable]="removable"
        (removed)="remove(data)">
        <span *ngFor="let key of selectedChipKey;let last=last">
          {{data[key]}}
          <span *ngIf="!last" class="mr-2"> {{selectedChipKeySeperator}} </span>
        </span>
        <span *ngIf="data.vipType && data.vipType != ''" class="icon-vip ag_ml--2 ag_mr--1" ></span>
        <i  aria-hidden="true" class="icon-Cancel" matChipRemove *ngIf="removable" (click)="onRemove($event);"></i>
      </mat-chip>
      <input attr.automationId='Txt_ChipSearch_SearchText' [placeholder]="placeholder" #chipInput [formControl]="chipCtrl" [matAutocomplete]="auto" [golfinputtype]="directiveType"
        [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [allowPaste]="allowPaste"
        (input)="searchText(chipInput.value)" [matChipInputAddOnBlur]="addOnBlur"
        [disabled]="selectedData.length >= maxChips || disabled">
      <i  aria-hidden="true" matSuffix class="icon-search"></i>
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" >
      <mat-option *ngIf="filteredData?.length == 0 && chipInput.value.length >= searchLength" disabled="true">
        {{this.captions.NoDataFound}}
      </mat-option>
      <mat-option *ngIf="filteredData === null && chipInput.value.length >= searchLength" disabled="true">
        {{this.captions.Loading}}
      </mat-option>
      <mat-option *ngFor="let data of filteredData" [value]="data" [ngClass]="{'show-more-data': showMoreData}">
        <span *ngFor="let key of autoCompleteKeys;let last=last; let index = index">
          {{data[key]}}
          <span *ngIf="showSeperator && ((index == 1 && data[autoCompleteKeys[index - 1]]) || (index != 1 && key==='' && data[autoCompleteKeys[index + 1]]!==''))">{{autoCompleteKeysSeperator}}</span>
        </span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>