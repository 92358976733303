import { Component, OnInit, Output, EventEmitter, Inject, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StepperService } from '../../shared/components/stepper/stepper.service';
import { Subscription } from 'rxjs';
import { DialogCloseObj, TimeInput } from '../../shared/shared-models';
import { TeeTimesActionBusiness } from 'src/app/tee-time/shared/tee-action.business';
import { GolfLocalization } from '../../core/localization/golf-localization';
import { ButtonAction } from 'src/app/shared/global.constant';
import { AgTimeConfig } from 'src/app/common/Models/ag-models';

@Component({
  selector: 'app-squeeze-time-modal',
  templateUrl: './squeeze-time-modal.component.html'  
})
export class SqueezeTimeModalComponent implements OnInit {

  captions: any;
  formData: UntypedFormGroup;
  saveSubscriber: Subscription;
  SqueezeFormSubscribe: Subscription;
  squeezeForm: UntypedFormGroup;
  squeezeData: any;
  startTimeInputs: AgTimeConfig;
  @Output() notifyParent = new EventEmitter();
  constructor(
    private _FormBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private cdr: ChangeDetectorRef,
    public _StepperService: StepperService,
    private dialogRef: MatDialogRef<SqueezeTimeModalComponent>,
    public _teeTimesActionBusiness: TeeTimesActionBusiness,
    private _Localization: GolfLocalization) { }

  ngOnInit() {
    this.formGenerator();
    this.captions = this._Localization.captions.teetime
    this.buildDateInput();
    this.squeezeData = this.dialogData.info;
    this.notifyParent.emit(false);

    this.SqueezeFormSubscribe = this.squeezeForm.valueChanges.subscribe(x => {
      this.formData = x;
      this.notifyParent.emit(this.squeezeForm.valid);
    });
    this.saveSubscriber = this._StepperService.valueChange.subscribe((x: DialogCloseObj) => {
      if (x.type === ButtonAction.save)
        this.submitForm();
    });
  }

  ngAfterViewInit(): void {
    this.bindData(); // call it where api call is used
  }

  bindData() {
    if (this.squeezeData) {
      let modalFormData = this._teeTimesActionBusiness.mapToModalFormData(this.squeezeData);

      this.squeezeForm.patchValue(modalFormData);
    }

    this.cdr.detectChanges();
    this.notifyParent.emit(true);
  }

  formGenerator() {
    this.squeezeForm = this._FormBuilder.group({
      id: 0,
      courseId: 0,
      squeezeDateTime: "",
      startTime: "",
      holes: ""
    });
  }

  private buildDateInput() {
    this.startTimeInputs = {
      className: 'golf-form-control--lg',
      errorMessage: 'Missing End Time',
      form: this.squeezeForm,
      formControlName: 'startTime',
      placeHolder: this.captions.selectSqueeze,
      automationId:"Txt_SqueezeTimeModal_startTime"
    };
  }


  submitForm() {
    this._teeTimesActionBusiness.squeezeTeeTime(this.squeezeForm.value, null, this.closeDialog.bind(this));
  }

  private closeDialog() {
    this.dialogRef.close('save');
  }

  ngOnDestroy(): void {
    this.SqueezeFormSubscribe.unsubscribe();
    this.saveSubscriber.unsubscribe();
  }


}
