<section class='bulk-move-players--wrapper golf-container--padding'>
  <app-players-info [playerInputData]="playerDetails"></app-players-info>
  <div class="bulk-move-players-form--wrapper">
    <form [formGroup]="bulkMovePlayersForm">
      <app-golf-date-picker [inputs]="dateInputs"></app-golf-date-picker>
      <mat-form-field [floatLabel]="floatLabel" class="golf-form-control--sm">
        <mat-select attr.automationId='Dd_{{automationId}}_Course' placeholder="{{captions.course}}" name="Course" formControlName="courseControl">
            <mat-option *ngFor="let course of courses" [value]="course.value">
             {{course.viewValue}}
            </mat-option>
          </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div class='bulk-move-tee-sheet-wrapper'>
    <div class='player-list'>
      <div class='all-players'>
          <golfcustom-checkbox [automationId]="automationId" class="golfcustom-checkbox" [customCBxChecked]="allSelected" (changeEvent)="checkbox($event, 'all')"></golfcustom-checkbox>
        <span class='title'>{{captions.allPlayers}}</span>
      </div>
      <div class='players' *ngFor= 'let player of playerInformation; let playerIndex = index'> 
          <golfcustom-checkbox [automationId]="automationId" class="golfcustom-checkbox" [customCBxChecked]="player.checked" (changeEvent)="checkbox($event, 'single', player)"></golfcustom-checkbox>
          <div class = 'player-detail'>
              <span class='player-index'>P{{playerIndex}}</span>
              <span class='player-name' [matTooltip]='player.playerName'>{{player.playerName}}</span>
              <span class='player-icon'><i  aria-hidden="true" class='icon-drag-drop'>&nbsp;</i></span>
            </div>
      </div>
      
    </div>
  </div>
  
</section>
