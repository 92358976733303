


import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { AlertAction, ButtonTypes } from 'src/app/common/enums/shared-enums';
import { Localization } from 'src/app/common/localization/localization';
import { HtmlPopupComponent } from 'src/app/common/shared/shared/html-popup/html-popup.component';
import { NotificationKeyword } from 'src/app/common/templates/template-email/crud-email-template/crud-email-template.model';
import * as DecoupledEditor from '../../../../ckeditor5-build-decoupled-document-master/build/ckeditor';
import { AlertType } from '../../enums/enums';
import { CommonUtilities } from '../../utilities/common-utilities';
import { Language } from './language-template-editor.model';
import juice from 'juice';


@Component({
  selector: 'app-language-template-editor',
  templateUrl: './language-template-editor.component.html',
  styleUrls: ['./language-template-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LanguageTemplateEditorComponent implements OnInit {
  editorForm: UntypedFormGroup;
  Editor = DecoupledEditor;
  Config = {
    startupFocus: true,
    toolbar: [
      'bold', 'italic', 'underline', 'strikethrough', 'fontfamily', 'fontsize',
      'fontcolor', 'fontbackgroundcolor', 'heading', 'alignment', 'numberedList',
      'bulletedList', 'todoList', 'outdent', 'indent', 'link', 'insertTable', 'imageUpload',
      'undo', 'redo', 'htmlEmbed'
    ],
    fontColor: {
      colors: this._utilities.getColors()
    },
    fontBackgroundColor: {
      colors: this._utilities.getColors()
    },
    fontSize: {
      options: this._utilities.getFontSize(),
      supportAllValues: true
    },
    table: {
      tableProperties: {
        borderColors: this._utilities.getColors(),
        backgroundColors: this._utilities.getColors()
      },
      tableCellProperties: {
        borderColors: this._utilities.getColors(),
        backgroundColors: this._utilities.getColors()
      }
    },
    extraPlugins: [],//Base64UploaderPlugin
    placeholder: 'Type here',
    toolbarLocation: ['bottom'],
  };
  @ViewChild('ComposeEmailTemp') ComposeEmailTemp: CKEditorComponent;
  @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup;
  @Input() form;
  @Input() filteredkeywords: NotificationKeyword[];
  Entire_keywords: NotificationKeyword[];
  selectedContentindex: number;
  languages: Language[];
  languageSelectorval: number = 1;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  addlanguageButton: { type: string; label: any; disabledproperty: boolean; };
  disableTabs: boolean = true;
  resetButton: { type: string; label: string; disabledproperty: boolean; };
  removeIdx: number;
  isDeleteButtonEnable: boolean = false;
  @Input('entireKeywords')
  set key(value: NotificationKeyword[]) {
    this.Entire_keywords = value;
  }

  @Input('languages')
  set setLanguage(value: Language[]) {
    this.languages = value;
  }

  @Output() selectedtabindex = new EventEmitter();
  @Output() RemovedLanguage = new EventEmitter();

  currentEditor: any;
  crudemailtemplateCaptions: any;
  captions: any;
  floatLabel: string;
  floatLabelNever: string;
  isGroupingenabled = true;
  searchText = "";
  ordertype = SorTypeEnum.asc;
  groupBy = 'groupId';

  constructor(private fb: UntypedFormBuilder, private localization: Localization, public dialog: MatDialog, private _utilities: CommonUtilities) {
    this.crudemailtemplateCaptions = this.localization.captions.settings.utilities;
    this.Config.placeholder = this.crudemailtemplateCaptions.typehere;
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }

  ngOnInit(): void {
    this.addlanguageButton = {
      type: 'primary',
      label: "Add Language+",
      disabledproperty: false
    }

    this.resetButton = {
      type: 'secondary',
      label: "Reset",
      disabledproperty: true
    }
  }

  ngAfterViewInit() {
    this.languageCascading()
  }

  onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
    this.currentEditor = this.ComposeEmailTemp;
  }

  onChange(currentEditorInstance) {
    this.currentEditor = currentEditorInstance;
  }

  enteredSrchText() {
    let val = this.searchText.toLowerCase();
    this.filteredkeywords = this.Entire_keywords.filter(x => {
      return (x.description.toLowerCase().indexOf(val) != -1);
    })
  }

  elementClick(arg) {
    const appendData = '{{{' + arg + '}}}';
    const selection = this.currentEditor.editorInstance.model.document.selection;
    const range = selection.getFirstRange();
    this.currentEditor.editorInstance.model.change(writer => {
      writer.insert(appendData, range.start);
    });
  }

  enteredgrpSrchText() {
    let searchValue = this.searchText.toLowerCase();
    let tempArray = [];
    this.Entire_keywords.forEach(searchKey => {
      const result = searchKey.description.toLowerCase().indexOf(searchValue) > -1
      if (result) {
        if (searchValue == "") {
          searchKey.isExpanded = false;
        } else {
          searchKey.isExpanded = true;
        }
        tempArray.push(searchKey);
      }
    })
    this.filteredkeywords = tempArray;
  }

  openClose(e, obj) {
    this.filteredkeywords.forEach((x, i) => {
      if (obj.groupId == x.groupId) {
        x.isExpanded = !x.isExpanded
      }
    })
  }

  submitButton() {

  }

  onLangchange(e) {

    this.languageSelectorval = e.value;
    this.disableLangbutton(false)
  }

  disableLangbutton(val: boolean) {
    this.addlanguageButton.disabledproperty = val;
    this.addlanguageButton = { ...this.addlanguageButton }
  }

  addLanguage(tabGroup: MatTabGroup) {
    let langTitle = this.languages.find(x => x.id == this.languageSelectorval).viewValue;
    let frmArray = this.form.get('editorHtml') as UntypedFormArray;
    let index = frmArray.value.findIndex(x => x.languageSelector == this.languageSelectorval);
    let data = this.fb.group({
      tabTitle: langTitle ? langTitle : 'English',
      htmlContents: '',
      languageSelector: this.languageSelectorval
    })
    frmArray.push(data);
    //  tabGroup.selectedIndex = (frmArray.length);

    this.languageCascading()
  }

  languageCascading() {
    let frmArray = this.form.get('editorHtml') as UntypedFormArray;
    frmArray.controls.forEach(x => {
      this.languages.forEach(y => {
        if (x['controls']['tabTitle'].value == y.viewValue) {
          y.isDisabled = true;
        }
      });
    })
    this.disableLangbutton(true)
  }


  showHtmlContent(obj) {
    let modifyHTML = juice.inlineContent(this._utilities.getCkeditorImgWidth(obj?.value.htmlContents),this._utilities.getCssStringForCkEditor());
    const dialogRef = this.dialog.open(HtmlPopupComponent, {
      height: '90%',
      width: '90%',
      data: { headername: this.crudemailtemplateCaptions.composed, datarecord: modifyHTML, Type: "HTML" },
      panelClass: 'ag_dialog--lg',
      disableClose: true,
      hasBackdrop: true
    });
  }

  removeLang(i, obj) {
    this.form.get('editorHtml').markAsPristine()
    let value = this.form.get('editorHtml').controls[this.selectedContentindex].value;
    this.RemovedLanguage.emit(value);
    this.languageCascadingenable(obj);
  }

  languageCascadingenable(obj) {
    let frmArray = this.form.get('editorHtml') as UntypedFormArray;
    this.languages.find(x => x.viewValue == obj['controls']['tabTitle'].value).isDisabled = false;
    this.languages = [...this.languages]

  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }
  resetForm(formObj) {

    formObj['controls']['htmlContents'].setValue('');
    this.form.get('editorHtml').markAsPristine()

  }
  tabclicked(e) {
    this.selectedContentindex = e.index;
    let value = this.form.get('editorHtml').controls[this.selectedContentindex].value;
    this.isDeleteButtonEnable = !(value.languageSelector == 1);
    this.selectedtabindex.emit(value)

  }




}
