import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { menuTypes } from 'src/app/shared/shared-models';
import { AppService } from 'src/app/common/app-service';
import { TenantManagementCommunication } from 'src/app/common/communication/services/tenantmanagement-communication-service';
import { CommonControllersRoutes } from 'src/app/common/communication/common-route';
import { menuTypes as newMenuTypes } from 'src/app/common/components/menu/menu.constant'
import { Observable, of } from 'rxjs';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailConstants, RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { AuthorizeTokenBySession } from 'src/app/common/shared/retail.modals';
import { Localization } from 'src/app/common/localization/localization';
@Injectable()
export class RouteLoaderService {

  currentSettings: any;
  menu = '';
  menuList: MenuObj[];
  private readonly ProductID: number;
  constructor(
    private httpClient: HttpClient,
    private injector: Injector,
    private _appservice: AppService,
    private _tenantMngmt: TenantManagementCommunication,
    private propertyInfo : RetailPropertyInformation,
    private localization: Localization,
    public featureFlagInfo: RetailFeatureFlagInformationService
  ) {
    this.ProductID = this._appservice.productId;
  }

  loadSettings(): Promise<any> {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const router = this.injector.get(Router);
            console.log(router);
              this.getProductMenus().then(
            //  this.getSettings().subscribe(
                    response => {
                        console.log('is Dynamic Menu available?:'+(!Array.isArray(response) || 0==response.length)? 'DM-No!':'DM-Yes!');
                        this.currentSettings =  response;
                        console.log('current settings',this.currentSettings);
                        if(this.localization.GetPropertyInfo('PlatFormExtendedSearchRequired') == "false")
                        {
                            let settingsMenu = this.currentSettings.find(x => x.text.includes("SETTINGS"))
                            if (settingsMenu) {
                                let utilitiesMenu = settingsMenu.linkedElement?.find(x => x.text.includes("UTILITIES"))

                                if(utilitiesMenu){
                                    let cgpsFailedMenu = utilitiesMenu.linkedElement?.find(x => x.text.includes("CGPS Failed Profile"))  

                                    if (cgpsFailedMenu) {
                                        cgpsFailedMenu.visibility = false;
                                    }
                                }
                            }
                        }
                      this.featureFlagInfo.GetFeaturesCompleted.subscribe(x => {
                          if (!this.featureFlagInfo.IsRentalEnabled) {
                              let shopMenu = this.currentSettings.find(x => x.text.includes("SHOP"))
                              if (shopMenu) {
                                  let rentalItemControl = shopMenu.linkedElement?.find(x => x.text.includes("RENTAL ITEM CONTROL"))
                                  if (rentalItemControl) {
                                      rentalItemControl.visibility = false;
                                  }
                              }
                          }
                      });                      
                        resolve(true);
                    },
                    err => {
                        console.log(err+' and so, loading default menu');                      
                        reject(false);
                    }
                );
        });
    });
}

  public GetChildMenu(currentRoute, menuType?: menuTypes | newMenuTypes) {
    const enableResortFinance = sessionStorage.getItem(RetailConstants.EnableResortFinance);
    if (enableResortFinance?.toLowerCase() == 'false') {
      const configsToRemove = ['/folio', '/accounting'];
      this.currentSettings = this.currentSettings.filter(r => !configsToRemove.includes(r.routePath));
    }
    else {
        const folioEnabled = sessionStorage.getItem(AuthorizeTokenBySession.FolioUserSession);
        if(!folioEnabled || folioEnabled == '') {
            const configsToRemove = ['/folio'];
            this.currentSettings = this.currentSettings.filter(r => !configsToRemove.includes(r.routePath));
        }
        const accEnabled = sessionStorage.getItem(AuthorizeTokenBySession.AccUserSession);
        if(!accEnabled || accEnabled == '') {
            const configsToRemove = ['/accounting'];
            this.currentSettings = this.currentSettings.filter(r => !configsToRemove.includes(r.routePath));
        }
    }
    const menuList = this.currentSettings;
    if (menuList) {
      if (currentRoute === '/') {
        return menuList;
      }      
      let currentMenu = menuList.find(x => x.routePath === currentRoute);
      if (currentMenu) {
        return currentMenu;
      } else {
        for (const x of menuList) {
          const obj: any[] = x.linkedElement ? x.linkedElement : null;          
          currentMenu = obj && obj.find(sub => sub.routePath === currentRoute);
          if (currentMenu) {
            return currentMenu;
          }
          if ((menuType === menuTypes.lowerLevel || menuType === newMenuTypes.vertical) && obj) {
            for (const childMenu of obj) {
              const childObj: any[] = childMenu.linkedElement ? childMenu.linkedElement : null;              
              const currentChildMenu = childObj && childObj.find(sub => sub.routePath === currentRoute);
              if (currentChildMenu) {
                return currentChildMenu;
              }

            }
          }
        }
      }
    }

  }

  async getProductMenus(): Promise<MenuObj[]> {
    let menus: MenuObj[] = [];
    menus = await this._tenantMngmt.getPromise({
      route: CommonControllersRoutes.GetMenus,
      uriParams: ""
    });
    this.propertyInfo.setICRoutes(menus.find(x => x.routePath === '/settings')?.linkedElement);
    return menus.filter(x => x.productID == this.ProductID);
  }
  
  public getSettings(): Observable<any> {
   const menuList = [
    {
        "elementID": 2001,
        "tenantID": 0,
        "propertyID": 0,
        "productID": 3,
        "textID": 2001,
        "text": "HOME",
        "routePath": "/home",
        "imgPath": "icon-home",
        "order": 1,
        "visibility": true,
        "disable": false,
        "parentID": 0,
        "menuPosition": "Primary",
        "menuAlignment": "Horizontal",
        "externalLink": false,
        "linkedElement": [],
        "breakPointNumber": 0
    },
    {
        "elementID": 2002,
        "tenantID": 0,
        "propertyID": 0,
        "productID": 3,
        "textID": 2002,
        "text": "TEE TIME",
        "routePath": "/tee-time",
        "imgPath": "icon-tee-time",
        "order": 2,
        "visibility": true,
        "disable": false,
        "parentID": 0,
        "menuPosition": "Primary",
        "menuAlignment": "Horizontal",
        "externalLink": false,
        "linkedElement": [
            {
                "elementID": 2003,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2003,
                "text": "TEE SHEET",
                "routePath": "/tee-time/teesheet",
                "imgPath": "",
                "order": 1,
                "visibility": true,
                "disable": false,
                "parentID": 2002,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 2004,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2004,
                        "text": "Tee Sheet",
                        "routePath": "/tee-time/teesheet/teeSheet",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2003,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 10010
                    },
                    {
                        "elementID": 2005,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2005,
                        "text": "Group Tee Time",
                        "routePath": "/tee-time/teesheet/groupTeeTime",
                        "imgPath": "",
                        "order": 3,
                        "visibility": false,
                        "disable": true,
                        "parentID": 2003,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 10150
                    },
                    {
                        "elementID": 2006,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2006,
                        "text": "Bulk Tee Time",
                        "routePath": "/tee-time/teesheet/bulkTeeTime",
                        "imgPath": "",
                        "order": 4,
                        "visibility": false,
                        "disable": true,
                        "parentID": 2003,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 10155
                    },
                    {
                        "elementID": 2007,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2007,
                        "text": "Graphical View",
                        "routePath": "/tee-time/teesheet/graphicalView",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2003,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 10010
                    }
                ],
                "breakPointNumber": 0
            },
            {
                "elementID": 2008,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2008,
                "text": "SEARCH",
                "routePath": "/tee-time/search",
                "imgPath": "",
                "order": 2,
                "visibility": true,
                "disable": false,
                "parentID": 2002,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 10080
            },
            {
                "elementID": 2009,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2009,
                "text": "TOURNAMENTS",
                "routePath": "/tee-time/tournaments",
                "imgPath": "",
                "order": 3,
                "visibility": true,
                "disable": false,
                "parentID": 2002,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 13015
            },
            {
                "elementID": 2010,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2010,
                "text": "WAITLIST",
                "routePath": "/tee-time/waitlist",
                "imgPath": "",
                "order": 4,
                "visibility": true,
                "disable": false,
                "parentID": 2002,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 10090
            }
        ],
        "breakPointNumber": 0
    },
    {
        "elementID": 2011,
        "tenantID": 0,
        "propertyID": 0,
        "productID": 3,
        "textID": 2011,
        "text": "GUEST",
        "routePath": "/guest",
        "imgPath": "icon-guest",
        "order": 3,
        "visibility": true,
        "disable": false,
        "parentID": 0,
        "menuPosition": "Primary",
        "menuAlignment": "Horizontal",
        "externalLink": false,
        "linkedElement": [
            {
                "elementID": 2012,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2012,
                "text": "PLAYERS",
                "routePath": "/guest/players/players",
                "imgPath": "",
                "order": 1,
                "visibility": true,
                "disable": false,
                "parentID": 2011,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 700
            },
            {
                "elementID": 2516,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2116,
                "text": "MEMBERS",
                "routePath": "/guest/members/members",
                "imgPath": "",
                "order": 2,
                "visibility": true,
                "disable": false,
                "parentID": 2011,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 2517,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 1116,
                        "text": "Members",
                        "routePath": "/home/Members",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2516,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2518,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 1117,
                        "text": "Group Configuration",
                        "routePath": "/home/GroupConfiguration",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2516,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2519,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 1118,
                        "text": "MembershipType",
                        "routePath": "/home/MembershipType",
                        "imgPath": "",
                        "order": 3,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2516,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2520,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 1119,
                        "text": "Rounds Configuration",
                        "routePath": "/home/RoundsConfiguration",
                        "imgPath": "",
                        "order": 4,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2516,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2521,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 1120,
                        "text": "Member Renewal Report",
                        "routePath": "/home/MemberRenewalReport",
                        "imgPath": "",
                        "order": 5,
                        "visibility": false,
                        "disable": false,
                        "parentID": 2516,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2522,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 1121,
                        "text": "Member Rounds Played Report",
                        "routePath": "/home/MemberRoundsPlayedReport",
                        "imgPath": "",
                        "order": 6,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2516,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2523,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 1122,
                        "text": "Golf Courses",
                        "routePath": "/home/GolfCourses",
                        "imgPath": "",
                        "order": 7,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2516,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2527,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 1127,
                        "text": "Rate Type",
                        "routePath": "/home/RateType",
                        "imgPath": "",
                        "order": 8,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2516,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2528,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 1128,
                        "text": "Player Type",
                        "routePath": "/home/PlayerType",
                        "imgPath": "",
                        "order": 9,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2516,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2531,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2701,
                        "text": "Credit Book Report",
                        "routePath": "/home/CreditBookReport",
                        "imgPath": "",
                        "order": 11,
                        "visibility": false,
                        "disable": false,
                        "parentID": 2516,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 6867,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 10746,
                        "text": "Fixed Charge",
                        "routePath": "/home/FixedCharge",
                        "imgPath": "",
                        "order": 10,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2516,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    }
                ],
                "breakPointNumber": 700
            }
        ],
        "breakPointNumber": 0
    },
    {
        "elementID": 2013,
        "tenantID": 0,
        "propertyID": 0,
        "productID": 3,
        "textID": 2013,
        "text": "SHOP",
        "routePath": "/shop/viewshop",
        "imgPath": "icon-shop",
        "order": 4,
        "visibility": true,
        "disable": false,
        "parentID": 0,
        "menuPosition": "Primary",
        "menuAlignment": "Horizontal",
        "externalLink": false,
        "linkedElement": [
            {
                "elementID": 2014,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2075,
                "text": "RETAIL ITEMS",
                "routePath": "/shop/viewshop/retailitems",
                "imgPath": "",
                "order": 1,
                "visibility": true,
                "disable": false,
                "parentID": 2013,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 0
            },
            {
                "elementID": 2015,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2076,
                "text": "RETAIL TRANSACTIONS",
                "routePath": "/shop/viewshop/retailtransactions",
                "imgPath": "",
                "order": 2,
                "visibility": true,
                "disable": false,
                "parentID": 2013,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 2016,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2077,
                        "text": "Open Transactions",
                        "routePath": "/shop/viewshop/retailtransactions/opentransactions",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2015,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 5000
                    },
                    {
                        "elementID": 2017,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2078,
                        "text": "Correct/Void",
                        "routePath": "/shop/viewshop/retailtransactions/correctvoid",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2015,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2018,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2079,
                        "text": "Return with Ticket",
                        "routePath": "/shop/viewshop/retailtransactions/returnwithticket",
                        "imgPath": "",
                        "order": 3,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2015,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 5010
                    },
                    {
                        "elementID": 2019,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2080,
                        "text": "Return without Ticket",
                        "routePath": "/shop/viewshop/retailtransactions/returnwithoutticket",
                        "imgPath": "",
                        "order": 4,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2015,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 5015
                    },
                    {
                        "elementID": 2020,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2081,
                        "text": "Modify Posted Commissions",
                        "routePath": "/shop/viewshop/retailtransactions/modifypostedcommission",
                        "imgPath": "",
                        "order": 5,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2015,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 5020
                    },
                    {
                        "elementID": 2021,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2082,
                        "text": "Reprint Ticket",
                        "routePath": "/shop/viewshop/retailtransactions/reprintticket",
                        "imgPath": "",
                        "order": 6,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2015,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 5025
                    },
                    {
                        "elementID": 6737,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 10036,
                        "text": "Payments Log",
                        "routePath": "/shop/viewshop/retailtransactions/transactionslog",
                        "imgPath": "",
                        "order": 7,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2015,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 6741,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 10041,
                        "text": "Revenue Postings Log",
                        "routePath": "/shop/viewshop/retailtransactions/revenuepostingslog",
                        "imgPath": "",
                        "order": 8,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2015,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 7692,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 12211,
                        "text": "Unsettled Payments",
                        "routePath": "/shop/viewshop/retailtransactions/unsettledpayments",
                        "imgPath": "",
                        "order": 9,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2015,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    }
                ],
                "breakPointNumber": 0
            },
            {
                "elementID": 2022,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2083,
                "text": "UNPAID PLAYERS",
                "routePath": "/shop/viewshop/unpaidplayers",
                "imgPath": "",
                "order": 3,
                "visibility": true,
                "disable": false,
                "parentID": 2013,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 0
            },
            {
                "elementID": 2023,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2023,
                "text": "GIFT CARDS",
                "routePath": "/shop/viewshop/giftcards",
                "imgPath": "",
                "order": 4,
                "visibility": true,
                "disable": false,
                "parentID": 2013,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 0
            }
        ],
        "breakPointNumber": 0
    },
    {
        "elementID": 2024,
        "tenantID": 0,
        "propertyID": 0,
        "productID": 3,
        "textID": 2014,
        "text": "REPORTS",
        "routePath": "/reports",
        "imgPath": "icon-reports",
        "order": 5,
        "visibility": true,
        "disable": false,
        "parentID": 0,
        "menuPosition": "Primary",
        "menuAlignment": "Horizontal",
        "externalLink": false,
        "linkedElement": [
            {
                "elementID": 2025,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2002,
                "text": "TEE TIME",
                "routePath": "/reports/teetime",
                "imgPath": "",
                "order": 1,
                "visibility": true,
                "disable": false,
                "parentID": 2024,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 0
            },
            {
                "elementID": 2026,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2016,
                "text": "MEMBER",
                "routePath": "/reports/member",
                "imgPath": "",
                "order": 2,
                "visibility": true,
                "disable": false,
                "parentID": 2024,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 0
            },
            {
                "elementID": 2027,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2017,
                "text": "TOURNAMENT",
                "routePath": "/reports/tournament",
                "imgPath": "",
                "order": 3,
                "visibility": true,
                "disable": false,
                "parentID": 2024,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 0
            },
            {
                "elementID": 2028,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2018,
                "text": "INSTRUCTOR",
                "routePath": "/reports/instructor",
                "imgPath": "",
                "order": 4,
                "visibility": true,
                "disable": false,
                "parentID": 2024,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 0
            },
            {
                "elementID": 2029,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2019,
                "text": "STATISTICS",
                "routePath": "/reports/statistics",
                "imgPath": "",
                "order": 5,
                "visibility": true,
                "disable": false,
                "parentID": 2024,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 0
            },
            {
                "elementID": 2030,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2020,
                "text": "TRANSACTION LOG",
                "routePath": "/reports/transactionlog",
                "imgPath": "",
                "order": 6,
                "visibility": true,
                "disable": false,
                "parentID": 2024,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 0
            },
            {
                "elementID": 2031,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2021,
                "text": "RETAIL",
                "routePath": "/reports/retail",
                "imgPath": "",
                "order": 7,
                "visibility": true,
                "disable": false,
                "parentID": 2024,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 0
            },
            {
                "elementID": 2032,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2022,
                "text": "COMMISSION/GRATUITY/SERVICE CHARGE",
                "routePath": "/reports/commissiongratuity",
                "imgPath": "",
                "order": 8,
                "visibility": true,
                "disable": false,
                "parentID": 2024,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 0
            },
            {
                "elementID": 2033,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2023,
                "text": "GIFT CARDS",
                "routePath": "/reports/giftcards",
                "imgPath": "",
                "order": 9,
                "visibility": true,
                "disable": false,
                "parentID": 2024,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 0
            },
            {
                "elementID": 7080,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 11631,
                "text": "INVENTORY CONTROL",
                "routePath": "/reports/inventorycontrol",
                "imgPath": "",
                "order": 10,
                "visibility": true,
                "disable": false,
                "parentID": 2024,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 0
            }
        ],
        "breakPointNumber": 0
    },
    {
        "elementID": 2034,
        "tenantID": 0,
        "propertyID": 0,
        "productID": 3,
        "textID": 2024,
        "text": "SETTINGS",
        "routePath": "/settings",
        "imgPath": "icon-settings",
        "order": 6,
        "visibility": true,
        "disable": false,
        "parentID": 0,
        "menuPosition": "Primary",
        "menuAlignment": "Horizontal",
        "externalLink": false,
        "linkedElement": [
            {
                "elementID": 2035,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2025,
                "text": "SYSTEM SETUP",
                "routePath": "/settings/systemsetup",
                "imgPath": "",
                "order": 1,
                "visibility": true,
                "disable": false,
                "parentID": 2034,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 2036,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2026,
                        "text": "Property Information",
                        "routePath": "/settings/systemsetup/propertyinfo",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2035,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2037,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2027,
                        "text": "Tee Times",
                        "routePath": "/settings/systemsetup/teetimes",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2035,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2038,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2028,
                        "text": "Tournament",
                        "routePath": "/settings/systemsetup/tournament",
                        "imgPath": "",
                        "order": 3,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2035,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2039,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2029,
                        "text": "Defaults",
                        "routePath": "/settings/systemsetup/defaults",
                        "imgPath": "",
                        "order": 4,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2035,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2040,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2030,
                        "text": "Miscellaneous",
                        "routePath": "/settings/systemsetup/miscellaneous",
                        "imgPath": "",
                        "order": 5,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2035,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2041,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2031,
                        "text": "Notify Configurations",
                        "routePath": "/settings/systemsetup/emailConfiguration",
                        "imgPath": "",
                        "order": 6,
                        "visibility": false,
                        "disable": true,
                        "parentID": 2035,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2042,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2032,
                        "text": "Agilysys Book Setup",
                        "routePath": "/settings/systemsetup/rguestBook",
                        "imgPath": "",
                        "order": 7,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2035,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2043,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2033,
                        "text": "Golf Setting",
                        "routePath": "/settings/systemsetup/golfSetting",
                        "imgPath": "",
                        "order": 8,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2035,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2044,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2034,
                        "text": "Notifications",
                        "routePath": "/settings/systemsetup/NoitifyConfiguration",
                        "imgPath": "",
                        "order": 9,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2035,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    }
                ],
                "breakPointNumber": 12001
            },
            {
                "elementID": 2045,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2035,
                "text": "GOLF SETUP",
                "routePath": "/settings/golfsetup",
                "imgPath": "",
                "order": 2,
                "visibility": true,
                "disable": false,
                "parentID": 2034,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 2046,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2036,
                        "text": "Code Setup",
                        "routePath": "/settings/golfsetup/codesetup",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2045,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 2047,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2051,
                                "text": "Course",
                                "routePath": "/settings/golfsetup/codesetup/course",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2046,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 12000
                            },
                            {
                                "elementID": 2048,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2037,
                                "text": "Lesson Location",
                                "routePath": "/settings/golfsetup/codesetup/lessonLocation",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2046,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 12045
                            },
                            {
                                "elementID": 2049,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2038,
                                "text": "Tee Color",
                                "routePath": "/settings/golfsetup/codesetup/teeColor",
                                "imgPath": "",
                                "order": 3,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2046,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 12005
                            },
                            {
                                "elementID": 2050,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2039,
                                "text": "Tee Time Allocation Block",
                                "routePath": "/settings/golfsetup/codesetup/teeTimeAllocationBlock",
                                "imgPath": "",
                                "order": 4,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2046,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 12010
                            },
                            {
                                "elementID": 2051,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2040,
                                "text": "Player Type",
                                "routePath": "/settings/golfsetup/codesetup/playerType",
                                "imgPath": "",
                                "order": 5,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2046,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 12015
                            },
                            {
                                "elementID": 2052,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2041,
                                "text": "Rate Type",
                                "routePath": "/settings/golfsetup/codesetup/rateType",
                                "imgPath": "",
                                "order": 6,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2046,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 12020
                            },
                            {
                                "elementID": 2053,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2042,
                                "text": "Caddy Type",
                                "routePath": "/settings/golfsetup/codesetup/caddyType",
                                "imgPath": "",
                                "order": 7,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2046,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 12030
                            },
                            {
                                "elementID": 2564,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 12146,
                                "text": "Cancel Reasons",
                                "routePath": "/settings/golfsetup/codesetup/cancelReasons",
                                "imgPath": "",
                                "order": 8,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2046,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 12080
                            },
                            {
                                "elementID": 2565,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 12146,
                                "text": "Log Type",
                                "routePath": "/settings/golfsetup/codesetup/logType",
                                "imgPath": "",
                                "order": 9,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2046,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 12100
                            }
                        ],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2054,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2043,
                        "text": "Player Type / Allocation  Block",
                        "routePath": "/settings/golfsetup/playertype",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2045,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 12025
                    },
                    {
                        "elementID": 2054,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2043,
                        "text": "Season Setup",
                        "routePath": "/settings/golfsetup/seasonSetup",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2045,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 12025
                    },
                    {
                        "elementID": 2055,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2044,
                        "text": "Rain Check Setup",
                        "routePath": "/settings/golfsetup/rainCheckSetup",
                        "imgPath": "",
                        "order": 3,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2045,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 12035
                    },
                    {
                        "elementID": 2056,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2045,
                        "text": "Tee Time Interval Setup",
                        "routePath": "/settings/golfsetup/teetimeIntervalSetup",
                        "imgPath": "",
                        "order": 4,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2045,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 12060
                    },
                    {
                        "elementID": 2057,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2046,
                        "text": "Tournament Setup",
                        "routePath": "/settings/golfsetup/tournamentSetup",
                        "imgPath": "",
                        "order": 5,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2045,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 2058,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2047,
                                "text": "Tournament Format",
                                "routePath": "/settings/golfsetup/tournamentSetup/tournamentFormat",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2057,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 13000
                            },
                            {
                                "elementID": 2059,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2048,
                                "text": "Tournament Packages",
                                "routePath": "/settings/golfsetup/tournamentSetup/tournamentPackages",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2057,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 13005
                            },
                            {
                                "elementID": 2060,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2049,
                                "text": "Tournament Components",
                                "routePath": "/settings/golfsetup/tournamentSetup/tournamentComponents",
                                "imgPath": "",
                                "order": 3,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2057,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 13010
                            },
                            {
                                "elementID": 2061,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2050,
                                "text": "Plan Components",
                                "routePath": "/settings/golfsetup/tournamentSetup/planComponents",
                                "imgPath": "",
                                "order": 4,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2057,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 13035
                            }
                        ],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2062,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2052,
                        "text": "Course Comment Setup",
                        "routePath": "/settings/golfsetup/courseComment",
                        "imgPath": "",
                        "order": 6,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2045,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 12040
                    },
                    {
                        "elementID": 2063,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2053,
                        "text": "Custom Fields",
                        "routePath": "/settings/golfsetup/customFields",
                        "imgPath": "",
                        "order": 7,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2045,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 12050
                    },
                    {
                        "elementID": 2064,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2054,
                        "text": "Tee Time Interval Blocks",
                        "routePath": "/settings/golfsetup/teetimeIntervalBlocks",
                        "imgPath": "",
                        "order": 8,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2045,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 12065
                    },
                    {
                        "elementID": 4083,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 8239,
                        "text": "Package Mapping",
                        "routePath": "/settings/golfsetup/packageBlocks",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2045,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 1034,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 1011,
                        "text": "VIP TYPE",
                        "routePath": "/settings/golfsetup/vipType",
                        "imgPath": "",
                        "order": 11,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2045,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 12040                      
                    }
                ],
                "breakPointNumber": 0
            },
            {
                "elementID": 2065,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2055,
                "text": "COMMISSION SETUP",
                "routePath": "/settings/commission/commission",
                "imgPath": "",
                "order": 3,
                "visibility": true,
                "disable": false,
                "parentID": 2034,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 800
            },
            {
                "elementID": 2066,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2056,
                "text": "INSTRUCTOR SETUP",
                "routePath": "/settings/instructorsetup",
                "imgPath": "",
                "order": 4,
                "visibility": true,
                "disable": false,
                "parentID": 2034,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 2067,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2057,
                        "text": "Instructors Setup",
                        "routePath": "/settings/instructorsetup/instructorsSetup",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2066,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 11000
                    },
                    {
                        "elementID": 2068,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2058,
                        "text": "Instructors Schedule",
                        "routePath": "/settings/instructorsetup/instructorSchedule",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2066,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 11005
                    }
                ],
                "breakPointNumber": 0
            },
            {
                "elementID": 2069,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2059,
                "text": "CADDY SETUP",
                "routePath": "/settings/caddysetup",
                "imgPath": "",
                "order": 5,
                "visibility": true,
                "disable": false,
                "parentID": 2034,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 2070,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2060,
                        "text": "Caddy Setup",
                        "routePath": "/settings/caddysetup/caddySetup",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2069,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 14000
                    },
                    {
                        "elementID": 2071,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2061,
                        "text": "Caddy Schedule",
                        "routePath": "/settings/caddysetup/caddySchedule",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2069,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 14005
                    },
                    {
                        "elementID": 2150,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2676,
                        "text": "Caddy Requirements",
                        "routePath": "/settings/caddysetup/caddyRequirements",
                        "imgPath": "",
                        "order": 3,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2069,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 14010
                    }
                ],
                "breakPointNumber": 0
            },
            {
                "elementID": 2072,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2062,
                "text": "RATE",
                "routePath": "/settings/rate",
                "imgPath": "",
                "order": 6,
                "visibility": true,
                "disable": false,
                "parentID": 2034,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 2072,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2062,
                        "text": "Rate Setup",
                        "routePath": "/settings/rate/ratesetup",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2034,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [ ],
                        "breakPointNumber": 12055
                    },
                    {
                        "elementID": 2072,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2062,
                        "text": "Dynamic Pricing",
                        "routePath": "/settings/rate/dynamicpricing",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2034,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [ ],
                        "breakPointNumber": 12055
                    },
                    
                ],
                "breakPointNumber": 12055
            },
           
            {
                "elementID": 2073,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2063,
                "text": "USER SETUP",
                "routePath": "/settings/usersetup",
                "imgPath": "",
                "order": 7,
                "visibility": true,
                "disable": false,
                "parentID": 2034,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 2074,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2064,
                        "text": "User Setup",
                        "routePath": "/settings/usersetup/userSetup",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2073,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 2300
                    },
                    {
                        "elementID": 2075,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2065,
                        "text": "Role Setup",
                        "routePath": "/settings/usersetup/roleSetup",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2073,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 2305
                    },
                    {
                        "elementID": 2076,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2066,
                        "text": "User Role Configuration",
                        "routePath": "/settings/usersetup/userRoleConfig",
                        "imgPath": "",
                        "order": 3,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2073,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 2310
                    },
                    {
                        "elementID": 2525,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2118,
                        "text": "Booking Permissions",
                        "routePath": "/settings/usersetup/bookingPermissions",
                        "imgPath": "",
                        "order": 4,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2073,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 12070
                    }
                ],
                "breakPointNumber": 0
            },
            {
                "elementID": 2077,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2067,
                "text": "UTILITIES",
                "routePath": "/settings/utilities",
                "imgPath": "",
                "order": 8,
                "visibility": true,
                "disable": false,
                "parentID": 2034,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 2078,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2068,
                        "text": "Manager Utilities",
                        "routePath": "/settings/utilities/managerUtilities",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2077,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 2079,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2069,
                                "text": "Default User Config",
                                "routePath": "/settings/utilities/managerUtilities/defaultUserConfig",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2078,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 11505
                            },
                            {
                                "elementID": 2524,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2117,
                                "text": "Quick ID Config",
                                "routePath": "/settings/utilities/managerUtilities/quickidconfig",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2078,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 2529,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 10031,
                                "text": "Printer Default Configuration",
                                "routePath": "/settings/utilities/managerUtilities/printerDefaultConfiguration",
                                "imgPath": "",
                                "order": 3,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2078,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 15065
                            }
                        ],
                        "breakPointNumber": 11505
                    },
                    {
                        "elementID": 2080,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2070,
                        "text": "Receipt Configuration",
                        "routePath": "/settings/utilities/receiptConfiguration",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2077,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 7080
                    },
                    {
                        "elementID": 2081,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2071,
                        "text": "Templates",
                        "routePath": "/settings/utilities/templates",
                        "imgPath": "",
                        "order": 3,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2077,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 2109,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2111,
                                "text": "Email Templates",
                                "routePath": "/settings/utilities/templates/email",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2081,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 2110,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2112,
                                "text": "SMS Templates",
                                "routePath": "/settings/utilities/templates/sms",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2081,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 2530,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 11206,
                                "text": "Report Templates",
                                "routePath": "/settings/utilities/templates/report",
                                "imgPath": "",
                                "order": 3,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2081,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            }
                        ],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2082,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2072,
                        "text": "Distribution List",
                        "routePath": "/settings/utilities/distributionlist",
                        "imgPath": "",
                        "order": 4,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2077,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2526,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2682,
                        "text": "Combine Guest Records",
                        "routePath": "/settings/utilities/combineguest",
                        "imgPath": "",
                        "order": 5,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2077,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2526,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2682,
                        "text": "Dedupe Guest Profiles",
                        "routePath": "/settings/utilities/dedupeguest",
                        "imgPath": "",
                        "order": 5,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2077,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 1099,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1124,
                        "text": "Player Type/ Discount Type",
                        "routePath": "/settings/utilities/playerTypeDiscountType",
                        "imgPath": "",
                        "order": 10,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1051,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 6967,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 11196,
                        "text": "Components Linking Config",
                        "routePath": "/settings/utilities/configComponentLinking",
                        "imgPath": "",
                        "order": 6,
                        "visibility": false,
                        "disable": false,
                        "parentID": 2077,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 6968,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 11201,
                        "text": "Components Linking",
                        "routePath": "/settings/utilities/componentLinking",
                        "imgPath": "",
                        "order": 7,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2077,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 7647,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 12111,
                        "text": "Inventory Sync",
                        "routePath": "/settings/utilities/inventorysync",
                        "imgPath": "",
                        "order": 8,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2077,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 15170
                    },
                    {
                        "elementID": 7647,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 12111,
                        "text": "Inventory Sync",
                        "routePath": "/settings/utilities/cgpsFailedProfile",
                        "imgPath": "",
                        "order": 8,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2077,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 15170
                    },
                    {
                        "elementID": 7689,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 12151,
                        "text": "Staff Reader Board",
                        "routePath": "/settings/utilities/staffreaderboard",
                        "imgPath": "",
                        "order": 9,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2077,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 20035
                    },
                    {
                        "elementID": 7690,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 12151,
                        "text": "Job Scheduler",
                        "routePath": "/settings/utilities/jobScheduler",
                        "imgPath": "",
                        "order": 10,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2077,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 8300,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 11991,
                                "text": "SFTP",
                                "routePath": "/settings/utilities/jobScheduler/sftp",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 8299,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 85255
                            },
                            {
                                "elementID": 8301,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 101725,
                                "text": "JOB SCHEDULER CONFIGRURATION",
                                "routePath": "/settings/utilities/jobScheduler/jobSchedulerConfiguration",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 8299,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 100776
                            },                           
                            {
                                "elementID": 8302,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 101730,
                                "text": "JOB SCHEDULER",
                                "routePath": "/settings/utilities/jobScheduler/eventScheduler",
                                "imgPath": "",
                                "order": 3,
                                "visibility": true,
                                "disable": false,
                                "parentID": 8299,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 100775 
                            }
                        ],
                        "breakPointNumber": 20035
                    },
                    {
                        "elementID": 2078,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2068,
                        "text": "Cancellation/No Show",
                        "routePath": "/settings/utilities/cancellationNoShowFee",
                        "imgPath": "",
                        "order": 11,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2077,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 2044,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2034,
                                "text": "Cancellation/No Show Policy",
                                "routePath": "/settings/utilities/cancellationNoShowFee/policy",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2035,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 2044,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2034,
                                "text": "Cancellation/No Show Policy Mapping",
                                "routePath": "/settings/utilities/cancellationNoShowFee/policyMapping",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2035,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            }
                        ]
                    },
                    {
                        "elementID": 1099,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1124,
                        "text": "Other Components/ Discount Mapping",
                        "routePath": "/settings/utilities/discountMapping",
                        "imgPath": "",
                        "order": 10,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1051,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                ],
                "breakPointNumber": 0
            },
            {
                "elementID": 2083,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2021,
                "text": "RETAIL",
                "routePath": "/settings/retailsetup",
                "imgPath": "",
                "order": 9,
                "visibility": true,
                "disable": false,
                "parentID": 2034,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 2084,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2036,
                        "text": "Code Setup",
                        "routePath": "/settings/retailsetup/codesetup",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2083,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Combo",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 2085,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2087,
                                "text": "Credit Card Terminals",
                                "routePath": "/settings/retailsetup/codesetup/creditcardterminals",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 7025
                            },
                            {
                                "elementID": 2086,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2088,
                                "text": "Outlets",
                                "routePath": "/settings/retailsetup/codesetup/outlets",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 7000
                            },
                            {
                                "elementID": 2087,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2089,
                                "text": "Category Groups",
                                "routePath": "/settings/retailsetup/codesetup/categorygroups",
                                "imgPath": "",
                                "order": 4,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 7030
                            },
                            {
                                "elementID": 2088,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2090,
                                "text": "Retail Categories",
                                "routePath": "/settings/retailsetup/codesetup/retailcategories",
                                "imgPath": "",
                                "order": 5,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 7035
                            },
                            {
                                "elementID": 2089,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2091,
                                "text": "Retail Sub Categories",
                                "routePath": "/settings/retailsetup/codesetup/retailsubcategories",
                                "imgPath": "",
                                "order": 6,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 7040
                            },
                            {
                                "elementID": 2090,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2092,
                                "text": "Unit of Measure",
                                "routePath": "/settings/retailsetup/codesetup/unitofmeasure",
                                "imgPath": "",
                                "order": 7,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 7015
                            },
                            {
                                "elementID": 2091,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2093,
                                "text": "Taxes",
                                "routePath": "/settings/retailsetup/codesetup/taxes",
                                "imgPath": "",
                                "order": 8,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 7050
                            },
                            {
                                "elementID": 2092,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2094,
                                "text": "Discount Types",
                                "routePath": "/settings/retailsetup/codesetup/discounttypes",
                                "imgPath": "",
                                "order": 9,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 7060
                            },
                            {
                                "elementID": 2093,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2095,
                                "text": "Payment Methods",
                                "routePath": "/settings/retailsetup/codesetup/paymentmethods",
                                "imgPath": "",
                                "order": 10,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 7070
                            },
                            {
                                "elementID": 2094,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2096,
                                "text": "Quick Sale Categories",
                                "routePath": "/settings/retailsetup/codesetup/quicksalecategories",
                                "imgPath": "",
                                "order": 11,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 7010
                            },
                            {
                                "elementID": 2095,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2097,
                                "text": "VAT Configuration",
                                "routePath": "/settings/retailsetup/codesetup/vatconfiguration",
                                "imgPath": "",
                                "order": 12,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 2096,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2098,
                                "text": "Retail Feature Configuration",
                                "routePath": "/settings/retailsetup/codesetup/retailfeatureconfiguration",
                                "imgPath": "",
                                "order": 13,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 2097,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2099,
                                "text": "Credit Cards",
                                "routePath": "/settings/retailsetup/codesetup/creditcards",
                                "imgPath": "",
                                "order": 14,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 2098,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2100,
                                "text": "Gift Cards",
                                "routePath": "/settings/retailsetup/codesetup/giftcards",
                                "imgPath": "",
                                "order": 15,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 2112,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2114,
                                "text": "Store Terminals",
                                "routePath": "/settings/retailsetup/codesetup/storeterminals",
                                "imgPath": "",
                                "order": 3,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 7090
                            },
                            {
                                "elementID": 2113,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2115,
                                "text": "Vendor Type",
                                "routePath": "/settings/retailsetup/codesetup/vendortypes",
                                "imgPath": "",
                                "order": 15,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 4081,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 4081,
                                "text": "Post Type Mapping",
                                "routePath": "/settings/retailsetup/codesetup/posttypemapping",
                                "imgPath": "",
                                "order": 16,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 4085,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 2681,
                                "text": "Machine Names",
                                "routePath": "/settings/retailsetup/codesetup/machinename",
                                "imgPath": "",
                                "order": 17,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 6560,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 8714,
                                "text": "Discount Reason",
                                "routePath": "/settings/retailsetup/codesetup/discountreason",
                                "imgPath": "",
                                "order": 16,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 7055
                            },
                            {
                                "elementID": 7099,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 11697,
                                "text": "Member Bucket Mapping",
                                "routePath": "/settings/retailsetup/codesetup/memberAccrualMapping",
                                "imgPath": "",
                                "order": 16,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2084,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1072,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1051,
                                "text": "Tier Level",
                                "routePath": "/settings/retailsetup/codesetup/tierLevel",
                                "imgPath": "",
                                "order": 17,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1063,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 7060
                            },
                        ],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2099,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2101,
                        "text": "Retail Setup",
                        "routePath": "/settings/retailsetup/retailsetup",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2083,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Combo",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2100,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2102,
                        "text": "Quick Sale Setup",
                        "routePath": "/settings/retailsetup/quicksalesetup",
                        "imgPath": "",
                        "order": 3,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2083,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Combo",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 7010
                    },
                    {
                        "elementID": 2101,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2103,
                        "text": "Commission Template",
                        "routePath": "/settings/retailsetup/commissionsetup",
                        "imgPath": "",
                        "order": 4,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2083,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Combo",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 7020
                    },
                    {
                        "elementID": 2102,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2104,
                        "text": "Discount Configuration",
                        "routePath": "/settings/retailsetup/discountconfiguration",
                        "imgPath": "",
                        "order": 5,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2083,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Combo",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 7061
                    },
                    {
                        "elementID": 2103,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2105,
                        "text": "Category Linking",
                        "routePath": "/settings/retailsetup/categorylinking",
                        "imgPath": "",
                        "order": 6,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2083,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Combo",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 7041
                    },
                    {
                        "elementID": 2111,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2113,
                        "text": "Vendor Setup",
                        "routePath": "/settings/retailsetup/vendorsetup",
                        "imgPath": "",
                        "order": 7,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2083,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Combo",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 6721,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 9976,
                        "text": "Letter Setup",
                        "routePath": "/settings/retailsetup/lettersetup",
                        "imgPath": "",
                        "order": 6,
                        "visibility": false,
                        "disable": false,
                        "parentID": 2083,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 6722,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 9981,
                                "text": "Club Agreement",
                                "routePath": "/settings/retailsetup/lettersetup/clubagreement",
                                "imgPath": "",
                                "order": 1,
                                "visibility": false,
                                "disable": false,
                                "parentID": 6721,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 6723,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 9986,
                                "text": "Cart Agreement",
                                "routePath": "/settings/retailsetup/lettersetup/cartagreement",
                                "imgPath": "",
                                "order": 2,
                                "visibility": false,
                                "disable": false,
                                "parentID": 6721,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 6724,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 9991,
                                "text": "Shoe Agreement",
                                "routePath": "/settings/retailsetup/lettersetup/shoeagreement",
                                "imgPath": "",
                                "order": 3,
                                "visibility": false,
                                "disable": false,
                                "parentID": 6721,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 6726,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 10001,
                                "text": "Retail Sale Chit",
                                "routePath": "/settings/retailsetup/lettersetup/retailsalechit",
                                "imgPath": "",
                                "order": 4,
                                "visibility": false,
                                "disable": false,
                                "parentID": 6721,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            }
                        ],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 7632,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 12086,
                        "text": "Payment Manager",
                        "routePath": "/settings/retailsetup/paymentmanager",
                        "imgPath": "",
                        "order": 8,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2083,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Combo",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 7633,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 12091,
                                "text": "Outlets",
                                "routePath": "/settings/retailsetup/paymentmanager/outlets",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 7632,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 7634,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 12096,
                                "text": "PMAgents",
                                "routePath": "/settings/retailsetup/paymentmanager/payagents",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 7632,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 7635,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 12101,
                                "text": "PMSettlers",
                                "routePath": "/settings/retailsetup/paymentmanager/settlers",
                                "imgPath": "",
                                "order": 3,
                                "visibility": true,
                                "disable": false,
                                "parentID": 7632,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            }
                        ],
                        "breakPointNumber": 0
                    }
                ],
                "breakPointNumber": 0
            },
            {
                "elementID": 2104,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2074,
                "text": "INVENTORY",
                "routePath": "/settings/inventory/inventory",
                "imgPath": "",
                "order": 10,
                "visibility": true,
                "disable": false,
                "parentID": 2034,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 8722
            },
            {
                "elementID": 2114,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 1103,
                "text": "INVENTORY CONTROL",
                "routePath": "/settings/enhancedInventory",
                "imgPath": "",
                "order": 11,
                "visibility": true,
                "disable": false,
                "parentID": 2034,
                "menuPosition": "Ternary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 2115,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 1104,
                        "text": "Inventory Management",
                        "routePath": "/settings/enhancedInventory/inventory",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2114,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Combo",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 2117,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 1105,
                                "text": "Inventory",
                                "routePath": "/settings/enhancedInventory/masterlist/inventorylist",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2115,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 8731
                            },
                            {
                                "elementID": 2118,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 1106,
                                "text": "Transfer",
                                "routePath": "/settings/enhancedInventory/transfer/transfer",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2115,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 8732
                            },
                            {
                                "elementID": 2121,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 1110,
                                "text": "Requisitions",
                                "routePath": "/settings/enhancedInventory/requisition/viewrequisition",
                                "imgPath": "",
                                "order": 3,
                                "visibility": false,
                                "disable": false,
                                "parentID": 2115,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 2122,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 1111,
                                "text": "Physical Inventory",
                                "routePath": "/settings/enhancedInventory/transaction/view-physicalinventory",
                                "imgPath": "",
                                "order": 4,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2115,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 8733
                            }
                        ],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2116,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 1107,
                        "text": "Procurement",
                        "routePath": "/settings/enhancedInventory/procurement",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2114,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Combo",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 2123,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 1112,
                                "text": "Blanket Purchase Order",
                                "routePath": "/settings/enhancedInventory/blanketpurchaseorder/bpolanding",
                                "imgPath": "",
                                "order": 1,
                                "visibility": false,
                                "disable": false,
                                "parentID": 2116,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 2124,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 1109,
                                "text": "Receiving",
                                "routePath": "/settings/enhancedInventory/transaction/view-receiving",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2116,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 8734
                            },
                            {
                                "elementID": 2125,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 1108,
                                "text": "Purchase Order",
                                "routePath": "/settings/enhancedInventory/transaction/view-purchaseorder",
                                "imgPath": "",
                                "order": 3,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2116,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 8735
                            },
                            {
                                "elementID": 2126,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 1113,
                                "text": "Purchase Requests",
                                "routePath": "/settings/enhancedInventory/purchaserequest/pr-landing",
                                "imgPath": "",
                                "order": 4,
                                "visibility": false,
                                "disable": false,
                                "parentID": 2116,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            }
                        ],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2119,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 1114,
                        "text": "Reports",
                        "routePath": "/settings/enhancedInventory/reports",
                        "imgPath": "",
                        "order": 3,
                        "visibility": false,
                        "disable": false,
                        "parentID": 2114,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 2127,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 1114,
                                "text": "Reports",
                                "routePath": "/settings/enhancedInventory/reports",
                                "imgPath": "",
                                "order": 1,
                                "visibility": false,
                                "disable": false,
                                "parentID": 2119,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            }
                        ],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2120,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 1115,
                        "text": "Pending Approvals",
                        "routePath": "/settings/enhancedInventory/pendingapproval/allpendingapprovals",
                        "imgPath": "",
                        "order": 4,
                        "visibility": false,
                        "disable": false,
                        "parentID": 2114,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 2128,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 1115,
                                "text": "Pending Approvals",
                                "routePath": "/settings/enhancedInventory/pendingapproval/allpendingapprovals",
                                "imgPath": "",
                                "order": 1,
                                "visibility": false,
                                "disable": false,
                                "parentID": 2120,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            }
                        ],
                        "breakPointNumber": 0
                    }
                ],
                "breakPointNumber": 0
            },
            {
                "elementID": 2532,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 7578,
                "text": "INTERFACES",
                "routePath": "/settings/interfaces",
                "imgPath": "",
                "order": 12,
                "visibility": true,
                "disable": false,
                "parentID": 2034,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 2533,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 2706,
                        "text": "ADM Integration",
                        "routePath": "/settings/interfaces/admIntegration",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2532,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Vertical",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 2534,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 9470,
                                "text": "ADM Integration Setup",
                                "routePath": "/settings/interfaces/admIntegration/admIntegrationSetUp",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2533,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            }
                        ],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2535,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 10861,
                        "text": "DataMagine Integration",
                        "routePath": "/settings/interfaces/dataMagine",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2532,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Combo",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 2536,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 10011,
                                "text": "DataMagine Configuration",
                                "routePath": "/settings/interfaces/dataMagine/datamagineConfiguration",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2535,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 2537,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 10856,
                                "text": "DataMagine Settings",
                                "routePath": "/settings/interfaces/dataMagine/datamagineSettings",
                                "imgPath": "",
                                "order": 2,
                                "visibility": false,
                                "disable": false,
                                "parentID": 2535,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            }
                        ],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2538,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 11961,
                        "text": "PMS Integration",
                        "routePath": "/settings/interfaces/pmsIntegration",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2532,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Combo",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 2539,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 11966,
                                "text": "PMS Setup",
                                "routePath": "/settings/interfaces/pmsIntegration/pmsSetup",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2538,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 2539,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 11966,
                                "text": "PMS Integration Setup",
                                "routePath": "/settings/interfaces/pmsIntegration/pmsIntegrationSetup",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2538,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            }
                        ],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2540,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 11961,
                        "text": "Authorize Integration Setup",
                        "routePath": "/settings/interfaces/authorizeIntegration",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2532,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Combo",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 2541,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 11966,
                                "text": "Authorize Setup",
                                "routePath": "/settings/interfaces/authorizeIntegration/authorizeSetup",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2540,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            }
                        ],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2540,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textID": 11961,
                        "text": "Third Party Integration",
                        "routePath": "/settings/interfaces/thirdPartyIntegration",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2532,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Combo",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 2541,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textID": 11966,
                                "text": "Golf Now Integration",
                                "routePath": "/settings/interfaces/thirdPartyIntegration/golfNowSetup",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2540,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            }
                        ],
                        "breakPointNumber": 0
                    },
                ],
                "breakPointNumber": 0
            }
        ],
        "breakPointNumber": 0
    },
    {
        "elementID": 2105,
        "tenantID": 0,
        "propertyID": 0,
        "productID": 3,
        "textID": 2106,
        "text": "LESSONS",
        "routePath": "/lessons",
        "imgPath": "icon-lessons",
        "order": 7,
        "visibility": true,
        "disable": false,
        "parentID": 0,
        "menuPosition": "Primary",
        "menuAlignment": "Horizontal",
        "externalLink": false,
        "linkedElement": [],
        "breakPointNumber": 10115
    },
    {
        "elementID": 2106,
        "tenantID": 0,
        "propertyID": 0,
        "productID": 3,
        "textID": 2107,
        "text": "Lost & Found",
        "routePath": "/lost-and-found",
        "imgPath": "icon-lost_found",
        "order": 8,
        "visibility": true,
        "disable": false,
        "parentID": 0,
        "menuPosition": "Primary",
        "menuAlignment": "Horizontal",
        "externalLink": false,
        "linkedElement": [],
        "breakPointNumber": 11510
    },
    {
        "elementID": 2107,
        "tenantID": 0,
        "propertyID": 0,
        "productID": 3,
        "textID": 2108,
        "text": "AUDIT",
        "routePath": "/audit",
        "imgPath": "icon-Audit",
        "order": 9,
        "visibility": true,
        "disable": false,
        "parentID": 0,
        "menuPosition": "Primary",
        "menuAlignment": "Horizontal",
        "externalLink": false,
        "linkedElement": [
            {
                "elementID": 2108,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 2109,
                "text": "MANUAL AUDIT",
                "routePath": "/audit/dayend",
                "imgPath": "",
                "order": 1,
                "visibility": true,
                "disable": false,
                "parentID": 2107,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 11500
            },
            {
                "elementID": 7654,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 3,
                "textID": 12141,
                "text": "AUTO NIGHT AUDIT",
                "routePath": "/audit/nightaudit",
                "imgPath": "",
                "order": 2,
                "visibility": true,
                "disable": false,
                "parentID": 2107,
                "menuPosition": "Secondary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 16025
            }
        ],
        "breakPointNumber": 0
    },
    {

        tenantID: 0,
        propertyID: 0,
        productID: 6,
        textId: 6281,
        elementId: 6281,
        text: 'ACCOUNTING',
        routePath: '/accounting',
        imgPath: 'icon-accounting',
        order: 6,
        visibility: true,
        disable: false,
        parentID: 0,
        menuPosition: 'Primary',
        menuAlignment: 'Horizontal',
        externalLink: false,
        linkedElement: [
            {
                tenantID: 0,
                propertyID: 0,
                productID: 6,
                textId: 6286,
                elementId: 6286,
                text: 'CUSTOMER MAINTENANCE',
                routePath: '/accounting/receivable',
                imgPath: '',
                order: 1,
                visibility: true,
                disable: false,
                parentID: 6003,
                menuPosition: 'Secondary',
                menuAlignment: 'Horizontal',
                externalLink: false,
                linkedElement: []
            },
            {
                tenantID: 0,
                propertyID: 0,
                productID: 6,
                textId: 6286,
                elementId: 6286,
                text: 'CHARGES',
                routePath: '/accounting/charges',
                imgPath: '',
                order: 1,
                visibility: true,
                disable: false,
                parentID: 6003,
                menuPosition: 'Secondary',
                menuAlignment: 'Horizontal',
                externalLink: false,
                linkedElement: [{
                    elementId: 6184,
                    tenantID: 0,
                    propertyID: 0,
                    productID: 6,
                    textId: 6912,
                    text: "APPLY CREDIT CARD CHARGES",
                    routePath: "/accounting/charges/applycreditcharges",
                    imgPath: "",
                    order: 1,
                    visibility: true,
                    disable: false,
                    parentID: 6005,
                    menuPosition: "Ternary",
                    menuAlignment: "Combo",
                    externalLink: false,
                    breakPointNumber: 0,
                    linkedElement: []
                },
                {
                    elementId: 6184,
                    tenantID: 0,
                    propertyID: 0,
                    productID: 6,
                    textId: 6912,
                    text: "FINANCE CHARGES",
                    routePath: "/accounting/charges/financecharges",
                    imgPath: "",
                    order: 1,
                    visibility: true,
                    disable: false,
                    parentID: 6005,
                    menuPosition: "Ternary",
                    menuAlignment: "Combo",
                    externalLink: false,
                    breakPointNumber: 0,
                    linkedElement: []
                }
            ]
            },
            {

                tenantID: 0,
                propertyID: 0,
                textId: 6286,
                elementId: 6286,
                text: 'GENERAL',
                routePath: '/accounting/general',
                imgPath: '',
                order: 2,
                visibility: true,
                disable: false,
                parentID: 6003,
                menuPosition: 'Secondary',
                menuAlignment: 'Horizontal',
                externalLink: false,
                linkedElement: [
                    {
                        elementId: 6184,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 10,
                        textId: 6912,
                        text: "VIEW ALL UNPOSTED BATCHES",
                        routePath: "/accounting/general/viewAllUnpostedBatches",
                        imgPath: "",
                        order: 1,
                        visibility: true,
                        disable: false,
                        parentID: 6005,
                        menuPosition: "Ternary",
                        menuAlignment: "Combo",
                        externalLink: false,
                        breakPointNumber: 0,
                        linkedElement: [
                        ]
                    },
                    {
                        elementId: 6184,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 10,
                        textId: 6912,
                        text: "DELETE INACTIVE/TEMPORARY ACCOUNTS",
                        routePath: "/accounting/general/deleteInactive",
                        imgPath: "",
                        order: 2,
                        visibility: true,
                        disable: false,
                        parentID: 6005,
                        menuPosition: "Ternary",
                        menuAlignment: "Combo",
                        externalLink: false,
                        breakPointNumber: 0,
                        linkedElement: []
                    },
                    {
                        elementId: 6184,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 10,
                        textId: 6912,
                        text: "BANK ACCOUNT MAINTENANCE",
                        routePath: "/accounting/general/bankAccountMaintenance",
                        imgPath: "",
                        order: 3,
                        visibility: true,
                        disable: false,
                        parentID: 6005,
                        menuPosition: "Ternary",
                        menuAlignment: "Combo",
                        externalLink: false,
                        breakPointNumber: 0,
                        linkedElement: []
                    },
                    {
                       elementId: 6184,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 10,
                        textId: 6912,
                        text: "VIEW UNCREATED SCHEDULED BATCHES",
                        routePath: "/accounting/general/viewuncreatedschedulebatches",
                        imgPath: "",
                        order: 5,
                        visibility: true,
                        disable: false,
                        parentID: 6005,
                        menuPosition: "Ternary",
                        menuAlignment: "Combo",
                        externalLink: false,
                        breakPointNumber: 0,
                        linkedElement: []
                    },
                    {
                        elementId: 6184,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 10,
                        textId: 11516,
                        text: "BACS/EFT ACCOUNTS SETUP",
                        routePath: "/accounting/general/BacsEftAccountSetup",
                        imgPath: "",
                        order: 7,
                        visibility: true,
                        disable: false,
                        parentID: 6286,
                        menuPosition: "Ternary",
                        menuAlignment: "Combo",
                        externalLink: false,
                        breakPointNumber: 0,
                        linkedElement: []
                    },
                ],

                breakPointNumber: 0

            },
            {
                tenantID: 0,
                propertyID: 0,
                productID: 6,
                textId: 6286,
                elementId: 6286,
                text: 'TRACE MAINTENANCE',
                routePath: '/accounting/traceMaintenance',
                imgPath: '',
                order: 1,
                visibility: true,
                disable: false,
                parentID: 6003,
                menuPosition: 'Secondary',
                menuAlignment: 'Horizontal',
                externalLink: false,
                linkedElement: []
            },
            {

                tenantID: 0,
                propertyID: 0,
                productID: 10,
                textId: 6286,
                elementId: 6286,
                text: 'CREDIT CARD PAYMENT',
                routePath: '/accounting/creditcardpayment',
                imgPath: '',
                order: 8,
                visibility: true,
                disable: false,
                parentID: 6003,
                menuPosition: 'Secondary',
                menuAlignment: 'Horizontal',
                externalLink: false,
                linkedElement: [],
                breakPointNumber: 0
            },
            {

                tenantID: 0,
                propertyID: 0,
                productID: 10,
                textId: 6286,
                elementId: 6286,
                text: 'PAYMENT MAINTENANCE',
                routePath: '/accounting/paymentmaintenance',
                imgPath: '',
                order: 7,
                visibility: true,
                disable: false,
                parentID: 6003,
                menuPosition: 'Secondary',
                menuAlignment: 'Horizontal',
                externalLink: false,
                linkedElement: [],
                breakPointNumber: 0
            },
            {

                tenantID: 0,
                propertyID: 0,
                productID: 10,
                textId: 6286,
                elementId: 6286,
                text: 'TRANSACTION MAINTENANCE',
                routePath: '/accounting/transactionmaintenance',
                imgPath: '',
                order: 2,
                visibility: true,
                disable: false,
                parentID: 6003,
                menuPosition: 'Secondary',
                menuAlignment: 'Horizontal',
                externalLink: false,
                linkedElement: [],
                breakPointNumber: 0
            },
            {

                tenantID: 0,
                propertyID: 0,
                productID: 6,
                textId: 6286,
                elementId: 6286,
                text: 'VIEW CUSTOMER TRANSACTIONS',
                routePath: '/accounting/viewcustomertransaction',
                imgPath: '',
                order: 1,
                visibility: true,
                disable: false,
                parentID: 6003,
                menuPosition: 'Secondary',
                menuAlignment: 'Horizontal',
                externalLink: false,
                linkedElement: [],
                breakPointNumber: 0
            },
            {
                tenantID: 0,
                propertyID: 0,
                productID: 6,
                textId: 6286,
                elementId: 6286,
                text: 'DISPUTED TRANSACTIONS',
                routePath: '/accounting/disputedtransactions',
                imgPath: '',
                order: 1,
                visibility: true,
                disable: false,
                parentID: 6003,
                menuPosition: 'Secondary',
                menuAlignment: 'Horizontal',
                externalLink: false,
                linkedElement: []
            },
            {

                tenantID: 0,
                propertyID: 0,
                productID: 10,
                textId: 6286,
                elementId: 6286,
                text: 'A/R CHECK PAYMENT PROCESS',
                routePath: '/accounting/checkpayment',
                imgPath: '',
                order: 8,
                visibility: true,
                disable: false,
                parentID: 6003,
                menuPosition: 'Secondary',
                menuAlignment: 'Horizontal',
                externalLink: false,
                linkedElement: [],
                breakPointNumber: 0
            },
            {
                tenantID: 0,
                propertyID: 0,
                productID: 6,
                textId: 6286,
                elementId: 6286,
                text: 'ACCOUNTS RECEIVABLE',
                routePath: '/accounting/accountsreceivable',
                imgPath: '',
                order: 1,
                visibility: true,
                disable: false,
                parentID: 6003,
                menuPosition: 'Secondary',
                menuAlignment: 'Horizontal',
                externalLink: false,
                linkedElement: [
                    {
                        elementId: 6184,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 6,
                        textId: 6912,
                        text: "ACCOUNTS RECEIVABLE CODE SETUP",
                        routePath: "/accounting/accountsreceivable/codesetup",
                        imgPath: "",
                        order: 1,
                        visibility: true,
                        disable: false,
                        parentID: 6005,
                        menuPosition: "Ternary",
                        menuAlignment: "Combo",
                        externalLink: false,
                        breakPointNumber: 0,
                        linkedElement: [
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 6,
                                textId: 6912,
                                text: "A/R Transaction Codes",
                                routePath: "/accounting/accountsreceivable/codesetup/artransactioncodes",
                                imgPath: "",
                                order: 1,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Vertical",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: []
                            },
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 6,
                                textId: 6912,
                                text: "A/R Account Categories",
                                routePath: "/accounting/accountsreceivable/codesetup/araccountcategories",
                                imgPath: "",
                                order: 1,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Vertical",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: []
                            },
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 6,
                                textId: 6912,
                                text: "A/R Account Categories 2",
                                routePath: "/accounting/accountsreceivable/codesetup/araccountcategories2",
                                imgPath: "",
                                order: 1,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Vertical",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: []
                            },
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 6,
                                textId: 6912,
                                text: "A/R Miscellaneous Categories",
                                routePath: "/accounting/accountsreceivable/codesetup/armiscellaneoustypes",
                                imgPath: "",
                                order: 1,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Vertical",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: []
                            },
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 6,
                                textId: 6912,
                                text: "A/R Tax Code Maintenance",
                                routePath: "/accounting/accountsreceivable/codesetup/artaxcodemaintenance",
                                imgPath: "",
                                order: 1,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Vertical",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: []
                            },
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 10,
                                textId: 6912,
                                text: "Credit Card Payment Setup",
                                routePath: "/accounting/accountsreceivable/codesetup/creditcardpaymentsetup",
                                imgPath: "",
                                order: 6,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Vertical",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: []
                            },
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 10,
                                textId: 6912,
                                text: "A/R Discount Categories",
                                routePath: "/accounting/accountsreceivable/codesetup/ardiscountcategories",
                                imgPath: "",
                                order: 5,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Vertical",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: []
                            },
                        ]

                    },
                    {
                        elementId: 6184,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 6,
                        textId: 6912,
                        text: "A/R CONTROL SETUP",
                        routePath: "/accounting/accountsreceivable/arcontrolsetup",
                        imgPath: "",
                        order: 1,
                        visibility: true,
                        disable: false,
                        parentID: 6005,
                        menuPosition: "Ternary",
                        menuAlignment: "Combo",
                        externalLink: false,
                        breakPointNumber: 0,
                        linkedElement: [
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 6,
                                textId: 6912,
                                text: "A/R Option",
                                routePath: "/accounting/accountsreceivable/arcontrolsetup/aroption",
                                imgPath: "",
                                order: 1,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Vertical",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: []
                            },
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 6,
                                textId: 6912,
                                text: "Invoice/Statement and aging",
                                routePath: "/accounting/accountsreceivable/arcontrolsetup/invoicestatementaging",
                                imgPath: "",
                                order: 1,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Vertical",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: []
                            },
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 6,
                                textId: 6912,
                                text: "Other Option and BACS/EFT",
                                routePath: "/accounting/accountsreceivable/arcontrolsetup/otheroption",
                                imgPath: "",
                                order: 1,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Vertical",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: []
                            },
                        ]
                    },
                    {
                        elementId: 6184,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 6,
                        textId: 6912,
                        text: "A/R BATCH TEMPLATES",
                        routePath: "/accounting/accountsreceivable/arbatchtemplates",
                        imgPath: "",
                        order: 1,
                        visibility: true,
                        disable: false,
                        parentID: 6005,
                        menuPosition: "Ternary",
                        menuAlignment: "Combo",
                        externalLink: false,
                        breakPointNumber: 0,
                        linkedElement: [
                        ]
                    },
                    {
                        elementId: 6184,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 10,
                        textId: 6912,
                        text: "A/R LETTERS MAINTENANCE",
                        routePath: "/accounting/accountsreceivable/arlettersmaintenance",
                        imgPath: "",
                        order: 3,
                        visibility: true,
                        disable: false,
                        parentID: 6005,
                        menuPosition: "Ternary",
                        menuAlignment: "Combo",
                        externalLink: false,
                        breakPointNumber: 0,
                        linkedElement: [
                        ]
                    },
                    {
                        elementId: 6184,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 10,
                        textId: 6912,
                        text: "A/R Archiving",
                        routePath: "/accounting/accountsreceivable/ararchiving",
                        imgPath: "",
                        order: 4,
                        visibility: true,
                        disable: false,
                        parentID: 6005,
                        menuPosition: "Ternary",
                        menuAlignment: "Combo",
                        externalLink: false,
                        breakPointNumber: 0,
                        linkedElement: [
                        ]
                    },
                    {
                        elementId: 6184,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 10,
                        textId: 6912,
                        text: "DUNNING LETTERS",
                        routePath: "/accounting/accountsreceivable/ardunningletter",
                        imgPath: "",
                        order: 6,
                        visibility: true,
                        disable: false,
                        parentID: 6005,
                        menuPosition: "Ternary",
                        menuAlignment: "Combo",
                        externalLink: false,
                        breakPointNumber: 0,
                        linkedElement: [
                        ]
                    },
                    {
                        elementId: 6184,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 10,
                        textId: 6912,
                        text: "AR CLIENT LETTERS",
                        routePath: "/accounting/accountsreceivable/arclientletter",
                        imgPath: "",
                        order: 7,
                        visibility: true,
                        disable: false,
                        parentID: 6005,
                        menuPosition: "Ternary",
                        menuAlignment: "Combo",
                        externalLink: false,
                        breakPointNumber: 0,
                        linkedElement: [
                        ]
                    }
                ],

                breakPointNumber: 0
            },
            {

                tenantID: 0,
                propertyID: 0,
                productID: 6,
                textId: 6286,
                elementId: 6286,
                text: 'VIEW INVOICES',
                routePath: '/accounting/viewinvoices',
                imgPath: '',
                order: 3,
                visibility: true,
                disable: false,
                parentID: 6003,
                menuPosition: 'Secondary',
                menuAlignment: 'Horizontal',
                externalLink: false,
                linkedElement: [],
                breakPointNumber: 0
            },
            {

                tenantID: 0,
                propertyID: 0,
                productID: 6,
                textId: 6286,
                elementId: 6286,
                text: 'INVOICES',
                routePath: '/accounting/invoices',
                imgPath: '',
                order: 3,
                visibility: true,
                disable: false,
                parentID: 6003,
                menuPosition: 'Secondary',
                menuAlignment: 'Horizontal',
                externalLink: false,
                linkedElement: [
                    {
                        elementId: 6184,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 6,
                        textId: 6912,
                        text: "GENERATE INVOICE",
                        routePath: "/accounting/invoices/generate",
                        imgPath: "",
                        order: 1,
                        visibility: true,
                        disable: false,
                        parentID: 6005,
                        menuPosition: "Ternary",
                        menuAlignment: "Combo",
                        externalLink: false,
                        breakPointNumber: 0,
                        linkedElement: []
                    },
                    {
                        elementId: 6184,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 6,
                        textId: 6912,
                        text: "Re-Print Invoice",
                        routePath: "/accounting/invoices/reprintInvoice",
                        imgPath: "",
                        order: 1,
                        visibility: true,
                        disable: false,
                        parentID: 6005,
                        menuPosition: "Ternary",
                        menuAlignment: "Combo",
                        externalLink: false,
                        breakPointNumber: 0,
                        linkedElement: [{
                            elementId: 6184,
                            tenantID: 0,
                            propertyID: 0,
                            productID: 6,
                            textId: 6912,
                            text: "Re-Print Invoices",
                            routePath: "/accounting/invoices/reprintInvoice/reprintInvoices",
                            imgPath: "",
                            order: 1,
                            visibility: true,
                            disable: false,
                            parentID: 6005,
                            menuPosition: "Ternary",
                            menuAlignment: "Vertical",
                            externalLink: false,
                            breakPointNumber: 0,
                            linkedElement: []
                        }],
                    },
                ],
            }
        ],
        breakPointNumber: 0
    },
    {
        elementID: 1030,
        tenantID: 0,
        propertyID: 0,
        productID: 1,
        textID: 1009,
        text: "Folio",
        routePath: "/folio",
        imgPath: "icon-folio",
        order: 8,
        visibility: true,
        disable: false,
        parentID: 0,
        menuPosition: "Primary",
        menuAlignment: "Horizontal",
        externalLink: false,
        linkedElement: [
            {
                elementID: 1031,
                tenantID: 0,
                propertyID: 0,
                productID: 1,
                textID: 1010,
                text: "FOLIO SEARCH",
                routePath: "/folio/foliosearch",
                imgPath: "",
                order: 1,
                visibility: true,
                disable: false,
                parentID: 1030,
                menuPosition: "Secondary",
                menuAlignment: "Horizontal",
                externalLink: false,
                linkedElement: []
            },
            {
                elementID: 1032,
                tenantID: 0,
                propertyID: 0,
                productID: 1,
                textID: 1011,
                text: "UNSETTLED FOLIO",
                routePath: "/folio/unsettled",
                imgPath: "",
                order: 1,
                visibility: true,
                disable: false,
                parentID: 1030,
                menuPosition: "Secondary",
                menuAlignment: "Horizontal",
                externalLink: false,
                linkedElement: []
            },
            {
                elementID: 1031,
                tenantID: 0,
                propertyID: 0,
                productID: 1,
                textID: 1010,
                text: "SETTINGS",
                routePath: "/folio/settings",
                imgPath: "",
                order: 1,
                visibility: true,
                disable: false,
                parentID: 1030,
                menuPosition: "Secondary",
                menuAlignment: "Horizontal",
                externalLink: false,
                linkedElement: [
                    {
                        elementID: 1086,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 1,
                        textID: 1065,
                        text: "POST TYPE",
                        routePath: "/folio/settings/posttypes",
                        imgPath: "",
                        order: 1,
                        visibility: true,
                        disable: false,
                        parentID: 1085,
                        menuPosition: "Ternary",
                        menuAlignment: "Vertical",
                        externalLink: false,
                        linkedElement: [],
                        breakPointNumber: 0
                    },
                    {
                        elementID: 1086,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 1,
                        textID: 1065,
                        text: "DEPARTMENT",
                        routePath: "/folio/settings/department",
                        imgPath: "",
                        order: 2,
                        visibility: true,
                        disable: false,
                        parentID: 1085,
                        menuPosition: "Ternary",
                        menuAlignment: "Vertical",
                        externalLink: false,
                        linkedElement: [],
                        breakPointNumber: 0
                    },
                    {
                        elementID: 1086,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 1,
                        textID: 1065,
                        text: "PAYMENT METHODS",
                        routePath: "/folio/settings/payment",
                        imgPath: "",
                        order: 3,
                        visibility: true,
                        disable: false,
                        parentID: 1085,
                        menuPosition: "Ternary",
                        menuAlignment: "Vertical",
                        externalLink: false,
                        linkedElement: [],
                        breakPointNumber: 0
                    },
                    {
                        elementID: 1086,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 1,
                        textID: 1065,
                        text: "Miscellaneous",
                        routePath: "/folio/settings/miscellaneous",
                        imgPath: "",
                        order: 4,
                        visibility: true,
                        disable: false,
                        parentID: 1085,
                        menuPosition: "Ternary",
                        menuAlignment: "Vertical",
                        externalLink: false,
                        linkedElement: [],
                        breakPointNumber: 0
                    }
                ]
            },
        ]
    },
    {
        elementID: 2109,
        tenantID: 0,
        propertyID: 0,
        productID: 2,
        textID: 4001,
        text: "All",
        routePath: "/all",
        imgPath: "icon-all-menu",
        order: 9,
        visibility: true,
        disable: false,
        parentID: 0,
        menuPosition: "Primary",
        menuAlignment: "Horizontal",
        externalLink: false,
        linkedElement: [

        ],
        breakPointNumber: 0
      },
      {
        elementID: 2110,
        tenantID: 0,
        propertyID: 0,
        productID: 2,
        textID: 4001,
        text: "Search Report",
        routePath: "/allReport",
        imgPath: "icon-Search-Reports",
        order: 10,
        visibility: true,
        disable: false,
        parentID: 0,
        menuPosition: "Primary",
        menuAlignment: "Horizontal",
        externalLink: false,
        linkedElement: [

        ],
        breakPointNumber: 0
      }
]
    return of(menuList);
  }
}
export interface MenuObj {
  elementID: number; //guid
  textType?: string; //Primary
  textId?: number | string; //guid
  text?: string; //guid
  routePath?: string;
  imgPath?: string;
  order?: number;
  visibility?: boolean;
  disable?: boolean;
  parentId?: string; //guid
  linkedElement?: MenuObj[] | any[];
  routeTree?: string;
  tenantID?: number;
  propertyID?: number;
  productID?: number;
  parentID?: number;
  menuPosition?: string;
  menuAlignment?: string;
  externalLink?: boolean;
  breakPointNumber?:number;
  parentElementText?:string;
}