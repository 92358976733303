import { Component, OnInit, EventEmitter, Inject, ViewEncapsulation, ChangeDetectorRef, ViewChild, Renderer2 } from '@angular/core';
import { ConvertWaitlistModalService } from './convert-waitlist-modal.service';
import { Players, ContactPhoneType, ContactEmailType, rateType } from './convert-waitlist-modal';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DefaultGUID } from 'src/app/common/shared/shared/globalsContant';
import { StepperService } from 'src/app/shared/components/stepper/stepper.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TimeInput, DateInput, DialogCloseObj, AgContactFieldConfig, AlertType, ButtonType, AlertAction, OVERRIDE_ALERT_WIDTH, WAITLIST_CADDY_ERROR_CODE } from 'src/app/shared/shared-models';
import { KeyValue } from '@angular/common';
import { ConvertWaitlistModalBusiness } from './convert-waitlist-modal.business';
import { CreateWaitlistModalBusiness } from 'src/app/tee-time/waitlist/create-waitlist-modal/create-waitlist-modal.business';
import { PlayerTypeService } from 'src/app/shared/data-services/golfschedule/playertype.data.service';
import { RateTypeDataService } from 'src/app/shared/data-services/golfschedule/ratetype.data.service';
import { CreateWaitlistModalService } from 'src/app/tee-time/waitlist/create-waitlist-modal/create-waitlist-modal.service';
import { CourseDataService } from 'src/app/shared/data-services/golfmanagement/course.data.service';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { WaitlistBusiness } from 'src/app/tee-time/waitlist/waitlist.business';
import { WaitlistService } from 'src/app/tee-time/waitlist/waitlist.service';
import { TeeTimeDataService } from 'src/app/shared/data-services/golfschedule/TeeTime.data.service';
import { TeeTimesActionBusiness } from 'src/app/tee-time/shared/tee-action.business';
import { TeeSheetSingleCourse } from 'src/app/tee-time/shared/tee-sheet/tee-sheet.singleCourse';
import { TeeSheetMultiCourse } from 'src/app/tee-time/shared/tee-sheet/tee-sheet.mulitCourse';
import { Options } from 'src/app/dynamicforms/form-type.entity';
import { CodeLinkingDataService } from 'src/app/shared/data-services/golfmanagement/codelinking.data.service';
import { AllocationBlockDataService } from 'src/app/shared/data-services/golfmanagement/allocationblock.data.service';
import { PlayerType } from 'src/app/settings/golf-setup/code-setup/player-type/player-type.modal';
import { ButtonAction, OVERRIDEPLAYERTYPEANDRATETYPEDAYSOUTERROR, CacheConstants, SettingScreen} from 'src/app/shared/global.constant';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { RateSetupDataService } from 'src/app/shared/data-services/golfschedule/rate-setup.data.service';
import { TeeSheetUtilities } from 'src/app/shared/utilities/teesheet.utilities';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { allocationBlockPlayerTypePermission } from 'src/app/settings/user-setup/booking-permission/booking-permission.model';
import { cloneDeep } from 'lodash';
import { AllocationBlockWithPlayerType, API as allocation } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import { PlayerDaysOutResut, RateTypeDaysOutResult } from 'src/app/shared/models/teesheet.form.models';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import { UserAccessModel } from 'src/app/shared/data-services/authentication/useraccess-model.model';
import { PlayerAdditionalDetails } from '../teetime/tee-time.model';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';
import _ from 'lodash';
import { RateType } from 'src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal';
import {  TeeSheetSlotAPI } from 'src/app/shared/models/teesheet.api.models';
import { AgTimeConfig } from 'src/app/common/Models/ag-models';
import { DynamicPricingPlayerFee, GolfSettingsConfig, RateSetupData } from '../teetime/player-details/player-info.model';
import { Course } from 'src/app/settings/golf-setup/code-setup/course/create-course-modal/create-course-model';
@Component({
  selector: 'app-convert-waitlist-modal',
  templateUrl: './convert-waitlist-modal.component.html',
  styleUrls: ['./convert-waitlist-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConvertWaitlistModalService, ConvertWaitlistModalBusiness, CreateWaitlistModalBusiness, PlayerTypeService, RateTypeDataService, CodeLinkingDataService, CreateWaitlistModalService, CourseDataService, WaitlistBusiness, WaitlistService, TeeTimeDataService, TeeTimesActionBusiness, TeeSheetSingleCourse, TeeSheetMultiCourse, AllocationBlockDataService, RateSetupDataService, UserAccessBusiness],
})
export class ConvertWaitlistModalComponent implements OnInit {

  isDiplayRateTrue: boolean;
  forRateCheck: boolean;
  isRateTrue: boolean;
  notifyParent = new EventEmitter();
  captions: any;
  contactPhoneType: ContactPhoneType[] = [];
  contactEmailType: ContactEmailType[] = [];
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  email: UntypedFormArray;
  convertWaitlistForm: UntypedFormGroup;
  phone: UntypedFormArray;
  allPlayerTypes: PlayerType[];
  courses: Course[];
  requestedDateInputs: DateInput;
  requestedTimeInputs: AgTimeConfig;
  staticDate: string;
  staticTime: string;
  staticName: string;
  timeSlots:TeeSheetSlotAPI[] =null;
  staticCourse: string;
  staticPlayers: string;
  staticComments: string;
  hideForm: boolean = true;
  header: KeyValue<string, string>[];
  playerList: Players[];
  radioTableForm: UntypedFormGroup;
  selectedItemId: string | number;
  selectedItem: Players;
  showStatic: boolean;
  phoneTypeInputs: AgContactFieldConfig;
  mailInputs: AgContactFieldConfig;
  valuePatched: boolean;
  rateTypeList$: Options[];
  playerTypeList$: Promise<Options[]>;
  noOfplayers: number;
  numberOfPlayersError: string;
  allocationBlockId: Number = 0;
  allocationName = '';
  allocationBlockPlayerTypePermission: allocationBlockPlayerTypePermission[];
  allocationBlocks: allocation.TeeTimeAllocationBlock[];
  allocationBlockError: boolean = false;
  possibleHole: any;
  allocationteeslot: any;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  overridePlayerTypeDaysoutUserAccess: UserAccessModel.BreakPointResult;
  overrideRateTypeDaysoutUserAccess: UserAccessModel.BreakPointResult;
  playerTypeArray = [];
  allPlayers: PlayerType[];
  rateTypeArray : RateType[];
  playerAdditionalDetails: PlayerAdditionalDetails[] = [];
  existingAdditionalDetails: PlayerAdditionalDetails[] = [];
  _access: UserAccessModel.BreakPointResult;
  negotiateDisabled = false;
  @ViewChild('infoElement') infoEle: any;
  floatLabel: string;
  selectedRateType: RateType;
  allocationCheck: boolean = true;
  automationId:string="ConvertWaitListModal";
  golfSettings : GolfSettingsConfig;
  dynamicPricingRateSetup : DynamicPricingPlayerFee[]=[];
  rateSetup: RateSetupData[] = [];
  courseId: number;
  overrideRateTypeForDynamicAvailability: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private _ConvertWaitlistModalService: ConvertWaitlistModalService,
    private _StepperService: StepperService,
    private cdr: ChangeDetectorRef,
    private _ConvertWaitlistModalBusiness: ConvertWaitlistModalBusiness,
    private CreateWaitlistModalBusiness: CreateWaitlistModalBusiness,
    private _teeTimesActionBusiness: TeeTimesActionBusiness,
    public _utilities: GolfUtilities,
    public _localization: GolfLocalization,
    private waitlistBusiness: WaitlistBusiness,
    private _teeSheetSingleCourse: TeeSheetSingleCourse, private _teeSheetMultiCourse: TeeSheetMultiCourse,
    private codeLinkingDataService: CodeLinkingDataService,
    private _PropertyInformation: GolfPropertyInformation,
    private _teeSheetUtilities: TeeSheetUtilities,
    private propertyInformation: RetailPropertyInformation,
    public quickLoginUtils: QuickLoginUtilities,
    private _userAccess: UserAccessBusiness,
    private rendrer: Renderer2  ) { 
    this.floatLabel = this._localization.setFloatLabel;
  }

  async ngOnInit() {
    this._access = await this._userAccess.getUserAccess(UserAccessBreakPoints.NEGOTIATEDRATE, false);
    this.negotiateDisabled = !this._access.isAllow;
    this.captions = this._ConvertWaitlistModalService.captionsGenerator();
    this.contactPhoneType = this._ConvertWaitlistModalService.getPhoneType();
    this.contactEmailType = this._ConvertWaitlistModalService.getEmailType();
    this.createConvertWaitlistForm();
    this.notifyParent.emit(false);
    this.initializeDateTimePicker();
    this.pageSubscriptions();
    this.showStatic = false;
    this.setValidations();
    this.setAllocationBlockPermissions();
    this.manualOverideBreakPointAccess();
    this.getAllPlayers();
    this.golfSettings = JSON.parse(sessionStorage.getItem(SettingScreen.GolfSetting))
    this.convertWaitlistForm.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(x => {
      this.notifyParent.emit(this.convertWaitlistForm.valid && this.convertWaitlistForm.get('requestedDate').value && this.convertWaitlistForm.get('requestedTime').value);
    });
    if (this.negotiateDisabled) {
      this.convertWaitlistForm.controls.greenFee.disable();
      this.convertWaitlistForm.controls.cartFee.disable();
    }
    let requestedDateTime = this._localization.AddTimeToDate(this._localization.getDate(this.convertWaitlistForm.value.requestedDate), this._localization.TimeToDate(this.convertWaitlistForm.value.requestedTime));
    await this._ConvertWaitlistModalBusiness.getCourseTeeFee(this.convertWaitlistForm.value.course, requestedDateTime).then(x => {
      this.rateSetup = x;
    });
    await this._teeTimesActionBusiness.validateBreakPointAccess(UserAccessBreakPoints.OVERRIDE_UNAVAILABLE_RATETYPE_PER_DYNAMICAVAILABILITY, false).then(x => {
      this.overrideRateTypeForDynamicAvailability = x.isAllow;
     });
  }

  async setValidations() {
    const settings = await this._ConvertWaitlistModalBusiness.getSettings();
    this.convertWaitlistForm.controls['playerType'].setValidators(settings.configValue.requirePlayerType ? [Validators.required] : []);
    this.convertWaitlistForm.controls['rateType'].setValidators(settings.configValue.requireRateType ? [Validators.required] : []);
    this.convertWaitlistForm.controls['playerType'].updateValueAndValidity();
    this.convertWaitlistForm.controls['rateType'].updateValueAndValidity();
    this.isRateTrue = settings.configValue.requireRateType;
    this.isDiplayRateTrue = this.isRateTrue;
  }

  async ngAfterViewInit() {
    this.courses = await this.CreateWaitlistModalBusiness.getCourses();
    await this.bindApiData();
    await this.emptyDateTimeError();
    this.generateOptions(null);
    this.setMinTime();
    if (this.negotiateDisabled) {
      let infoWrapper;
      const parentEl = this.infoEle.nativeElement;
      const message = this._localization.captions.breakpoint[UserAccessBreakPoints.NEGOTIATEDRATE];
      infoWrapper = this._utilities.GetInfoStrip(message);
      this.rendrer.insertBefore(parentEl, infoWrapper, parentEl.childNodes[1]);
    }
  }

  emptyDateTimeError()
  {
      this.convertWaitlistForm.get('requestedDate').setErrors(null);
      this.convertWaitlistForm.get('requestedDate').clearValidators();
      this.convertWaitlistForm.get('requestedDate').markAsTouched();
      this.convertWaitlistForm.get('requestedDate').updateValueAndValidity();
      this.requestedDateInputs.errorMessage = '';
      this.requestedDateInputs = { ...this.requestedDateInputs };
      

      this.convertWaitlistForm.get('requestedTime').setErrors(null);
      this.convertWaitlistForm.get('requestedTime').clearValidators();
      this.convertWaitlistForm.get('requestedTime').markAsTouched();
      this.convertWaitlistForm.get('requestedTime').updateValueAndValidity();
      this.requestedTimeInputs.customErrorMessage = '';
      this.requestedTimeInputs = { ...this.requestedTimeInputs };
  }

  setGreencart(val) {
    if (!this.isRateTrue) {
      let checkTrue = this.convertWaitlistForm.get('rateType').value;
      if (checkTrue || val) {
        this.convertWaitlistForm.get('greenFee').clearValidators();
        this.convertWaitlistForm.get('cartFee').clearValidators();
        this.convertWaitlistForm.get('greenFee').setErrors(null);
        this.convertWaitlistForm.get('cartFee').setErrors(null);
        this.isDiplayRateTrue = true;
      } else {
        this.convertWaitlistForm.get('greenFee').setValidators(Validators.required)
        this.convertWaitlistForm.get('cartFee').setValidators(Validators.required);

        this.isDiplayRateTrue = false;
      }
    } else {
      this.convertWaitlistForm.get('greenFee').clearValidators();
      this.convertWaitlistForm.get('cartFee').clearValidators();
      this.convertWaitlistForm.get('greenFee').setErrors(null);
      this.convertWaitlistForm.get('cartFee').setErrors(null);
      this.isDiplayRateTrue = true;
    }
  }

  clearGreenCart() {
    if (!this.isRateTrue) {
      this.convertWaitlistForm.get('greenFee').reset();
      this.convertWaitlistForm.get('cartFee').reset();
    }
  }


  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
    this.quickLoginUtils.resetQuickIdDetails();
  }

  createConvertWaitlistForm() {
    this.radioTableForm = this.fb.group({});
    this.convertWaitlistForm = this.fb.group({
      id: 0,
      editToggle: false,
      firstName: [{ value: '', disabled: true }, Validators.required],
      lastName: [{ value: '', disabled: true }, Validators.required],
      phoneType: '',
      countryCode: '',
      phoneNumber: '',
      extension: '',
      emailType: '',
      emailId: '',
      playerType: '',
      rateType: '',
      allocationBlockId: 0,
      greenFee: '',
      cartFee: '',
      course: '',
      requestedDate: '',
      requestedTime: '',
      noOfPlayers: ['', [Validators.required]],
      originalNoOfPlayers:'',
      comments: '',
      playerTypeRadio: '',
      playerLinkId: '',
      paymentReferenceId: 0,
      playerTypeDaysOutOverrideduserID: '',
      isPlayerTypeDaysOutOverrided: false,
      rateTypeDaysOutOverrideduserID: '',
      isRateTypeDaysOutOverrided: false,
      address: null,
      addressId: 0,
      playerId: 0,
      state: '',
      city: '',
      country: '',
      postal_code: '',
      vipType: null
    });

  }
  initializeDateTimePicker() {
    this.phoneTypeInputs = {
      form: this.convertWaitlistForm,
      automationId:this.automationId
    };
    this.mailInputs = {
      form: this.convertWaitlistForm,
      automationId:this.automationId
    };

    this.requestedDateInputs = {
      className: 'golf-form-control--lg',
      form: this.convertWaitlistForm,
      formControlName: 'requestedDate',
      placeHolder: this.captions.date,
      minDate: this._PropertyInformation.CurrentDTTM,
      errorMessage: "",
      automationId:this.automationId
    };

    this.requestedTimeInputs = {
      className: 'golf-form-control--lg',
      form: this.convertWaitlistForm,
      formControlName: 'requestedTime',
      placeHolder: this.captions.time,
      errorMessage: "Enter Time",
      automationId:this.automationId
    };

  }

  async pageSubscriptions() {
    this.convertWaitlistForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if (this.convertWaitlistForm.controls.phoneNumber.value && !this.convertWaitlistForm.controls.countryCode.value) {
        this.convertWaitlistForm.controls.countryCode.setErrors({ required: true });
      }
      this.convertWaitlistForm.controls['countryCode'].markAsTouched();
    });
    this.convertWaitlistForm['controls']['editToggle'].valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(async () => {
      this.hideForm = !this.hideForm;
      if (this.hideForm) {
        console.log("edittoggleonchange");
        await this.TeeSheetvalidation();
        await this.playerchange(null);
        this.setMinTime();
      }

    });
    this._StepperService.valueChange.pipe(takeUntil(this.destroyed$)).subscribe(async (x: DialogCloseObj) => {
      const setting = JSON.parse(sessionStorage.getItem(CacheConstants.TeeTimeSetting));
      let quickIdConfig = this.propertyInformation.getQuickIdConfig;
      if (x.type === ButtonAction.confirm) {
        let response;
        if(this.convertWaitlistForm.get('playerLinkId').value != DefaultGUID && this.convertWaitlistForm.get('playerLinkId').value != "" && setting.checkForTeeTimeOverlaps != null && Boolean(setting.checkForTeeTimeOverlaps) == true)
        {
          await this.getOverlappingSlots([this.convertWaitlistForm.get('playerLinkId').value]).then(overlapMessage=>
            {
              if(overlapMessage!="" && overlapMessage!=undefined )
              {
                response= this._utilities.showAlert(overlapMessage, AlertType.Warning, ButtonType.YesNo).afterClosed().toPromise();
              }
              else{
                this.confirmWaitlist(x,quickIdConfig);
              }
            });
        }
        else{
          this.confirmWaitlist(x,quickIdConfig);
        }
        response.then(y => {
          if(y==AlertAction.YES)
          {
            this.confirmWaitlist(x,quickIdConfig);
          }
        });
        
      }

          
      if (x.type === ButtonAction.convert) {
        let response;
        if(this.convertWaitlistForm.get('playerLinkId').value != DefaultGUID && this.convertWaitlistForm.get('playerLinkId').value != "" && setting.checkForTeeTimeOverlaps != null && Boolean(setting.checkForTeeTimeOverlaps) == true )
        {
          await this.getOverlappingSlots([this.convertWaitlistForm.get('playerLinkId').value]).then(overlapMessage=>
            {
              if(overlapMessage!="" && overlapMessage!=undefined )
              {
                response= this._utilities.showAlert(overlapMessage, AlertType.Warning, ButtonType.YesNo).afterClosed().toPromise();
              }
              else{
                this.convertWaitlist(x,quickIdConfig);
              }
            });
        }
        else{
          this.convertWaitlist(x,quickIdConfig);
        }
        response.then(y => {
          if(y==AlertAction.YES)
          {
            this.convertWaitlist(x,quickIdConfig);
          }
        });
      }
    });
  }

  async convertWaitlist(x,quickIdConfig)
  {
    const playerTypeId = this.convertWaitlistForm.get('playerType').value;
    const selectedPlayerType = this.allPlayerTypes.find(x => x.id == playerTypeId);
    const rateTypeId = this.convertWaitlistForm.get('rateType').value;
    const selectedRateType = this.rateTypeArray?.find(x => x.id == rateTypeId);
    if (!await this.checkOverridePlayerTypeRateTypeDaysOut(selectedPlayerType, selectedRateType)) {
      return;
    }
    if (quickIdConfig && quickIdConfig.teeTimeBook) {
      const quickLoginDialogRef = this.quickLoginUtils.QuickLogin();
      quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
        if (quickLoginDialogResult.isLoggedIn) {
          const quickRoleId = this.quickLoginUtils.GetQuickLoginRoleId();
          const roleId = this._localization.GetUserInfo("roleId");
          let allocationCodePermissionsforQuickId = [];
          if (quickRoleId != roleId) {
            allocationCodePermissionsforQuickId = await this._ConvertWaitlistModalBusiness.GetAllocationBlockPermissionByRole(quickRoleId);
          } else {
            allocationCodePermissionsforQuickId = this.allocationBlockPlayerTypePermission;
          }
          if (await this.hasPermissionForPlayerType(this.allocationBlockId, playerTypeId, allocationCodePermissionsforQuickId)) {
            this._utilities.ToggleLoader(true);
            this.submitContactForm().then(() => {
              x.dialogRef.close();
              this._teeSheetUtilities.triggerTeeSheetRefresh();
            })
              .catch(() => {
                //do business error here
              }).finally(() => {
                this.quickLoginUtils.resetQuickIdDetails();
                this._utilities.ToggleLoader(false);
              });
          } else {
            this.quickLoginUtils.resetQuickIdDetails();
          }
        }
      })

    }
    else {
      if (await this.hasPermissionForPlayerType(this.allocationBlockId, playerTypeId, this.allocationBlockPlayerTypePermission)) {
        this._utilities.ToggleLoader(true);
        this.submitContactForm().then(() => {
          x.dialogRef.close();
          this._teeSheetUtilities.triggerTeeSheetRefresh();
        })
          .catch(() => {
            //do business error here
          }).finally(() => {
            this._utilities.ToggleLoader(false);
          });
      }
    }
  }

  async confirmWaitlist(x,quickIdConfig)
  {
    const playerTypeId = this.convertWaitlistForm.get('playerType').value;
    const selectedPlayerType = this.allPlayerTypes.find(x => x.id == playerTypeId);
    const rateTypeId = this.convertWaitlistForm.get('rateType').value;
    const selectedRateType = this.rateTypeArray?.find(x => x.id == rateTypeId);
    
    if (!await this.checkOverridePlayerTypeRateTypeDaysOut(selectedPlayerType, selectedRateType)) {
      return;
    }
    if (quickIdConfig && quickIdConfig.teeTimeBook) {
      const quickLoginDialogRef = this.quickLoginUtils.QuickLogin();
      quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
        if (quickLoginDialogResult.isLoggedIn) {
          const quickRoleId = this.quickLoginUtils.GetQuickLoginRoleId();
          const roleId = this._localization.GetUserInfo("roleId");
          let allocationCodePermissionsforQuickId = [];
          if (quickRoleId != roleId) {
            allocationCodePermissionsforQuickId = await this._ConvertWaitlistModalBusiness.GetAllocationBlockPermissionByRole(quickRoleId);
          } else {
            allocationCodePermissionsforQuickId = this.allocationBlockPlayerTypePermission;
          }
          if (await this.hasPermissionForPlayerType(this.allocationBlockId, playerTypeId, allocationCodePermissionsforQuickId)) {
            this._utilities.ToggleLoader(true);
            this.submitContactForm().then(() => {
              x.dialogRef.close();
            }).catch(() => {
              //do business error here
            }).finally(() => {
              this.quickLoginUtils.resetQuickIdDetails();
              this._utilities.ToggleLoader(false);
            });
          } else {
            this.quickLoginUtils.resetQuickIdDetails();
          }
        }
      })
    }
    else {
        if (await this.hasPermissionForPlayerType(this.allocationBlockId, playerTypeId, this.allocationBlockPlayerTypePermission)) {
          this._utilities.ToggleLoader(true);
        this.submitContactForm().then(() => {
          x.dialogRef.close();
        })
          .catch(() => {
            //do business error here
          }).finally(() => {
            this._utilities.ToggleLoader(false);
        });
      }
    }
  }
  
  async  getOverlappingSlots(playerLinkIds)
  {
    let requestedDateTime = this._localization.AddTimeToDate(this._localization.getDate(this.convertWaitlistForm.value.requestedDate), this._localization.TimeToDate(this.convertWaitlistForm.value.requestedTime));
    var messageForPlayer ="";
    let playersOverLappings = await this._teeTimesActionBusiness
    .CheckOverLappingBookingForGuest(playerLinkIds, null, 
      this._localization.convertDateTimeToAPIDateTime(requestedDateTime),this.convertWaitlistForm.get('course').value);
    playersOverLappings = playersOverLappings.filter(x=>!(x.scheduledTeeTimeOfPlayer == this._localization.convertDateTimeToAPIDateTimeSec(requestedDateTime)));
    if (playersOverLappings != null && playersOverLappings.length > 0) {
      var message =this._teeTimesActionBusiness.getOverlappingGuestDatas(playersOverLappings,true,true);
      messageForPlayer = message[0].messageToBeshown;
      }
      return messageForPlayer;
    }

  async checkOverridePlayerTypeRateTypeDaysOut(selectedPlayerType, selectedRateType): Promise<boolean> {
    let result = false;
    const date = this.convertWaitlistForm.get('requestedDate').value;
    let playerDaysOutResut : PlayerDaysOutResut;
    if(selectedPlayerType)
    {
      playerDaysOutResut = this._teeTimesActionBusiness.validatePlayerDaysOut(this._localization.getDate(date), selectedPlayerType.daysOutStart, selectedPlayerType.daysOutEnd);
    }
    //get selectoption for ratetype
    let rateTypeDaysOutResult : RateTypeDaysOutResult;
    let rateTypeDaysOutValidation = true;
    if(selectedRateType)
    {
      rateTypeDaysOutResult = this._teeTimesActionBusiness.validateRateTypeDaysOut(this._localization.getDate(date), selectedRateType.daysOutStart, selectedRateType.daysOutEnd);
      rateTypeDaysOutValidation = this.validateRateTypeDaysOut(rateTypeDaysOutResult);
    }
    if(playerDaysOutResut && (playerDaysOutResut == PlayerDaysOutResut.StartDateFail || playerDaysOutResut == PlayerDaysOutResut.EndDateFail)) {
      if (this.overridePlayerTypeDaysoutUserAccess && this.overridePlayerTypeDaysoutUserAccess.isAllow)
      {
        let userId = this._localization.GetUserInfo('userId');
        this.setOverrideValue(Number(userId), true);
        // check ratetypeDaysOut
        if(rateTypeDaysOutValidation)
        {
          await this.setPlayerTypeValue();
          result = true;
        }
        else
        {
          result = await this.handleRateTypeDaysOut(selectedRateType, rateTypeDaysOutResult);
        }
      }
      else
      {
        result = await this.handlePlayerTypeRateTypeDaysOut(selectedPlayerType, playerDaysOutResut, rateTypeDaysOutValidation);
      }
    }
    else
    {
      // check ratetypeDaysOut
      if(rateTypeDaysOutValidation)
      {
        await this.setPlayerTypeValue();
        result = true;
      }
      else
      {
        result = await this.handleRateTypeDaysOut(selectedRateType, rateTypeDaysOutResult);
      }
      
    }
    return result;
  }
  
  validateRateTypeDaysOut(rateTypeDaysOutResult: RateTypeDaysOutResult)
  {
    let result = false;
    if (rateTypeDaysOutResult && (rateTypeDaysOutResult == RateTypeDaysOutResult.StartDateFail || rateTypeDaysOutResult == RateTypeDaysOutResult.EndDateFail))
    {
      if (this.overrideRateTypeDaysoutUserAccess && this.overrideRateTypeDaysoutUserAccess.isAllow) {
        let userId = this._localization.GetUserInfo('userId');
        this.setRateTypeOverrideValue(Number(userId), true);
        result = true;
      }
    }
    else {
      result = true;
    }
    return result;
  }


  async handlePlayerTypeRateTypeDaysOut(selectedOption, playerDaysOutResut: PlayerDaysOutResut, rateTypeDaysOutValidation: boolean): Promise<boolean> {
    let result = false;
    if(rateTypeDaysOutValidation)
    {
      const currentDate = this._PropertyInformation.CurrentDate;
      const daysOutStartDate = this._localization.GetFormattedDateDDMMYY(this._localization.AddDays(currentDate, selectedOption.daysOutStart));
      const daysOutEndDate = this._localization.GetFormattedDateDDMMYY(this._localization.AddDays(currentDate, selectedOption.daysOutEnd));
      const startErrorMessage: string = this.captions.playerDaysOutStartError + ' ' + daysOutStartDate;
      const endErrorMessage: string = this.captions.playerDaysOutEndError + ' ' + daysOutEndDate;
      let errorMessage = (playerDaysOutResut == PlayerDaysOutResut.StartDateFail) ? startErrorMessage : endErrorMessage;
      result = await this.showQuickIdPopup(errorMessage);
    }
    else
    {
      let errorMessage = this.captions.playerTypeRateTypeDaysOutError;
      result = await this.showPlayerTypeRateTypeQuickIdPopup(selectedOption.Id, errorMessage);
    }
    return result;
  }

  async showPlayerTypeRateTypeQuickIdPopup(playerTypeId: number, errorMessage: string): Promise<boolean> {
    const width = OVERRIDE_ALERT_WIDTH;
    return new Promise<boolean>(async (resolve, reject) => {
      let res = this._utilities.showAlertWithCustomWidth(errorMessage, AlertType.Info, ButtonType.OkOverride, width, async (alertRes) => {
        if (alertRes === AlertAction.OVERRIDE) { // click override
          const quickLoginDialogRef = this.quickLoginUtils.QuickLogin({ breakPointNumber: UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT }, true, [UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT, UserAccessBreakPoints.OVERRIDERATETYPEDAYSOUT], OVERRIDEPLAYERTYPEANDRATETYPEDAYSOUTERROR);
          quickLoginDialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
            if (quickLoginDialogResult.isLoggedIn && quickLoginDialogResult.userDetails && quickLoginDialogResult.userDetails.breakPointAccess) {  //Quickid user has breakpoint #10195                
              await this.setPlayerTypeValue();
              this.quickLoginUtils.resetQuickIdDetails();
              this.setOverrideValue(quickLoginDialogResult.userDetails.userId, true);
              this.setRateTypeOverrideValue(quickLoginDialogResult.userDetails.userId, true);
              resolve(true);
            } else { //Quickid user doesn't have access for #10195
              await this.resetPlayerTypeRateType();
              this.setOverrideValue(0, false);
              this.setRateTypeOverrideValue(0, false);
              resolve(false);
            }
          });
        } else { // click Ok
          await this.resetPlayerTypeRateType();
          this.setOverrideValue(0, false);
          this.setRateTypeOverrideValue(0, false);
          resolve(false);
        }
      });
      await res.afterClosed().toPromise();
    });
  }

  async handleRateTypeDaysOut(selectedOption, rateTypeDaysOutResult): Promise<boolean> {
    let result = false;
    const currentDate = this._PropertyInformation.CurrentDate;
    const daysOutStartDate = this._localization.GetFormattedDateDDMMYY(this._localization.AddDays(currentDate, selectedOption.daysOutStart));
    const daysOutEndDate = this._localization.GetFormattedDateDDMMYY(this._localization.AddDays(currentDate, selectedOption.daysOutEnd));
    const startErrorMessage: string = this.captions.rateDaysOutStartError + ' ' + daysOutStartDate;
    const endErrorMessage: string = this.captions.rateDaysOutEndError + ' ' + daysOutEndDate;
    let errorMessage = (rateTypeDaysOutResult == RateTypeDaysOutResult.StartDateFail) ? startErrorMessage : endErrorMessage;
    result = await this.showRateTypeQuickIdPopup(errorMessage);
    return result;
  }

  async showRateTypeQuickIdPopup(errorMessage: string): Promise<boolean> {
    const width = OVERRIDE_ALERT_WIDTH;
    return new Promise<boolean>(async (resolve, reject) => {
      let res = this._utilities.showAlertWithCustomWidth(errorMessage, AlertType.Info, ButtonType.OkOverride, width, (res) => {
        if (res === AlertAction.OVERRIDE) { // click override
          const quickLoginDialogRef = this.quickLoginUtils.QuickLogin({ breakPointNumber: UserAccessBreakPoints.OVERRIDERATETYPEDAYSOUT });

          quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
            if (quickLoginDialogResult.isLoggedIn && quickLoginDialogResult.userDetails && quickLoginDialogResult.userDetails.breakPointAccess) {  //Quickid user has breakpoint #OVERRIDERATETYPEDAYSOUT
              this.quickLoginUtils.resetQuickIdDetails();
              this.setRateTypeOverrideValue(quickLoginDialogResult.userDetails.userId, true);
              resolve(true);
            }
            else { //Quickid user doesn't have access for #OVERRIDERATETYPEDAYSOUT
              this.resetRateType();
              this.setRateTypeOverrideValue(0, false);
              resolve(false);
            }
          });
        }
        else { // click Ok
          this.resetRateType();
          this.setRateTypeOverrideValue(0, false);
          resolve(false);
        }
      });
      await res.afterClosed().toPromise();
    });
  }

  resetRateType() {
    this.convertWaitlistForm.get('rateType').setValue('');
    this.setGreencart(this.convertWaitlistForm.get('rateType').value);
  }

  setRateTypeOverrideValue(userId: number, bFlag: boolean) {
    this.convertWaitlistForm.get('isRateTypeDaysOutOverrided').setValue(bFlag);
    this.convertWaitlistForm.get('rateTypeDaysOutOverrideduserID').setValue(userId);
  }
  
  async resetPlayerTypeRateType()
  {
    this.convertWaitlistForm.get('playerType').setValue('');
    this.convertWaitlistForm.get('rateType').setValue('');
    await this.setRateTypes();
    //FIXME:
    //this.resetRateType(); --> reset rate type changed to set rate after development, check while debugging
  }

  async showQuickIdPopup(errorMessage: string): Promise<boolean> {
    const width = OVERRIDE_ALERT_WIDTH;
    return new Promise<boolean>(async (resolve, reject) => {
      let res = this._utilities.showAlertWithCustomWidth(errorMessage, AlertType.Info, ButtonType.OkOverride, width, async (alertRes) => {
        if (alertRes === AlertAction.OVERRIDE) { // click override
          const quickLoginDialogRef = this.quickLoginUtils.QuickLogin({ breakPointNumber: UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT });
          quickLoginDialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
            if (quickLoginDialogResult.isLoggedIn && quickLoginDialogResult.userDetails && quickLoginDialogResult.userDetails.breakPointAccess) {  //Quickid user has breakpoint #10195                
              await this.setPlayerTypeValue();
              this.quickLoginUtils.resetQuickIdDetails();
              this.setOverrideValue(quickLoginDialogResult.userDetails.userId, true);
              resolve(true);
            } else { //Quickid user doesn't have access for #10195
              await this.resetPlayerType();
              this.setOverrideValue(0, false);
              resolve(false);
            }
          });
        } else { // click Ok
          await this.resetPlayerType();
          this.setOverrideValue(0, false);
          resolve(false);
        }
      });
      await res.afterClosed().toPromise();
    });
  }

  async setPlayerTypeValue() {
    await this.setRateTypes();
  }

  async resetPlayerType() {
    this.convertWaitlistForm.get('playerType').setValue('');
    await this.setRateTypes();
  }

  setOverrideValue(userId: number, bFlag: boolean) {
    this.convertWaitlistForm.get('isPlayerTypeDaysOutOverrided').setValue(bFlag);
    this.convertWaitlistForm.get('playerTypeDaysOutOverrideduserID').setValue(userId);
  }

  submitContactForm() {
    this.convertWaitlistForm.get('allocationBlockId').setValue(this.allocationBlockId);
    return this.CreateWaitlistModalBusiness.UpdateWaitList(this.convertWaitlistForm.getRawValue(), true, this.possibleHole, null, this.playerAdditionalDetails, this._access.isAllow).then(response => {
      return new Promise((resolve, reject) => {
        if (response.showMessageInUI) {
          const errorMsg = this._localization.getError(WAITLIST_CADDY_ERROR_CODE);
          this._utilities.showAlert(errorMsg, AlertType.Info, ButtonType.Ok, res => {
            resolve(res);
          });
        } else {
          resolve(true);
        }
      })
    }).catch(ex => {
      if (!ex.error.result && ex.error.errorCode) {
        let errorMsg = this._localization.getError(ex.error.errorCode);
        if (ex.error.errorCode === 11010 || ex.error.errorCode === 11015) {
          errorMsg = errorMsg.interpolate({ user: ex.error.errorDescription ? ex.error.errorDescription : '' });
        }
        this._utilities.showAlert(errorMsg, AlertType.Error);
      }
    })
  }

  async bindApiData() {
    let checkBindData = this.dialogData.componentDetails.popUpDetails.bindData;
    this.showStatic = this.dialogData.showStaticBool;
    if (!this.showStatic) {
      this.buildTableData();
      this.notifyParent.emit(true);
    }
    if (checkBindData) {
      if (checkBindData && checkBindData.playerAdditionalDetails && this.showStatic) {
        this.existingAdditionalDetails = _.cloneDeep(checkBindData.playerAdditionalDetails)
        this.playerAdditionalDetails = checkBindData.playerAdditionalDetails
      }
      this.convertWaitlistForm.patchValue(checkBindData);
      this.valuePatched = true;
      this.courseId =  this.courses.filter(x => x.id == (Array.isArray(checkBindData) ? checkBindData[0].courseId : checkBindData.course))[0]?.id;
      this.staticDate = checkBindData.staticDate;
      this.staticTime = checkBindData.staticTime;
      this.staticName = checkBindData.staticName;
      this.staticCourse = checkBindData.staticCourse;
      this.staticPlayers = checkBindData.staticPlayers;
      this.staticComments = checkBindData.staticComments;
      this.hideForm = checkBindData.editToggle;
    }
    await this.checkTeeTimeAllocationTime();
  }

  async checkTeeTimeAllocationTime() {
    this.possibleHole = await this._ConvertWaitlistModalBusiness.getPossibleHole(this.convertWaitlistForm.value.course, this.convertWaitlistForm.value.requestedDate, this.convertWaitlistForm.value.requestedTime, this.convertWaitlistForm.value.noOfPlayers);
    let teeTimeNumbers: number = this.possibleHole.playercount;
    if (this.allocationBlockId == 0 && teeTimeNumbers == 0) {
      this.allocationBlockError = true;
      this.requestedTimeInputs.customErrorMessage = this.captions.Allocationblockisnotavailable;
      this.requestedTimeInputs = { ...this.requestedTimeInputs }
      this.setInvalidateFormControl('requestedTime');
    }
    else if (teeTimeNumbers == 0 && this.possibleHole.holeNumber == "" && this.possibleHole.originalHoleNumber == "") {
      this.allocationBlockError = false;
      this.requestedTimeInputs.customErrorMessage = this.captions.teeSlotisnotavailablefortheselectedtime;
      this.requestedTimeInputs = { ...this.requestedTimeInputs }
      this.setInvalidateFormControl('requestedTime');
    }
  }

  async generateOptions(event) {
    this.TeeSheetvalidation();
    this.setMinTime();
  }


  async TeeSheetvalidation() 
  {
    const customerror = this;
    customerror.requestedDateInputs.errorMessage = '';
    customerror.requestedDateInputs = { ...customerror.requestedDateInputs };
    if(!this.convertWaitlistForm.get('requestedDate').value)
    {
      this.allocationBlockError = false;
      this.requestedDateInputs.errorMessage = this.captions.invalidDateError;
      this.requestedDateInputs = { ...this.requestedDateInputs }
      this.setInvalidateFormControl('requestedDate');
      this.notifyParent.emit(false);
      return;
    }
    if(!this.convertWaitlistForm.get('requestedDate').valid)
    {
      this.notifyParent.emit(false);
      return;
    }
    const result = await this._ConvertWaitlistModalBusiness.getTeeTimeSheet(this.convertWaitlistForm.value);
    if (!result) {
      customerror.requestedDateInputs.errorMessage = customerror.captions.TeeSheetNotAvailable;
      customerror.requestedDateInputs = { ...customerror.requestedDateInputs };
      customerror.convertWaitlistForm.get('requestedDate').setErrors({ 'incorrect': true })
      customerror.convertWaitlistForm.get('requestedDate').markAsTouched();
      customerror.convertWaitlistForm.get('noOfPlayers').setErrors(null);
      customerror.convertWaitlistForm.get('noOfPlayers').setErrors({ 'required': true });
      customerror.convertWaitlistForm.get('noOfPlayers').updateValueAndValidity();
      // Bug 100271
      // if (!customerror.convertWaitlistForm.valid) {
      //   customerror.convertWaitlistForm.get('editToggle').patchValue(true);
      // }
    }
    else{
      this.timeChange(null, null, result.slots);
    }
  }

  setInvalidateFormControl(control: string, makeError: boolean = true) {
    if (makeError) {
      this.convertWaitlistForm.get(control).setErrors({ 'incorrect': true })
    }
    this.convertWaitlistForm.get(control).markAsTouched();
    if (!this.convertWaitlistForm.valid && !this.convertWaitlistForm.get('editToggle').value) {
      this.convertWaitlistForm.get('editToggle').patchValue(true);
    }
  }


  async playerchange(event) {
    if (this.timeSlots == null)
      this.timeSlots = await this._ConvertWaitlistModalBusiness.getTeeTimeSlot(this.convertWaitlistForm.value);
    if (event != null) {
      this.noOfplayers = event.target.value;
    }
    this.notifyParent.emit(false);
    console.log(this.convertWaitlistForm.value);
    let requestdate: Date = this._localization.ConvertStringDateTimeToDate(this.convertWaitlistForm.value.requestedDate, this.convertWaitlistForm.value.requestedTime);
    let stringdate = this._localization.ConvertDateToISODateTime(requestdate);
    this.possibleHole = await this._ConvertWaitlistModalBusiness.getPossibleHole(this.convertWaitlistForm.value.course, this.convertWaitlistForm.value.requestedDate
      , this.convertWaitlistForm.value.requestedTime, this.noOfplayers);
    let teeTimeNumbers: number = this.possibleHole.playercount;
    if (teeTimeNumbers == 4 || (teeTimeNumbers == 0 && this.possibleHole.holeNumber == "" && this.possibleHole.originalHoleNumber == "")) {
      this.requestedTimeInputs.customErrorMessage = this.captions.teeSlotisnotavailablefortheselectedtime;
      this.requestedTimeInputs = { ...this.requestedTimeInputs }
      this.setInvalidateFormControl('requestedTime');
    }
    else if (teeTimeNumbers + Number(this.noOfplayers) > 4) {
      this.numberOfPlayersError = this.captions.cannotconvertwaitlist;
      this.numberOfPlayersError = this.numberOfPlayersError.interpolate({ players: (4 - teeTimeNumbers) })
      this.setInvalidateFormControl('noOfPlayers');

    }
    else {
      this.convertWaitlistForm.get('requestedTime').setErrors(null);
      this.convertWaitlistForm.get('requestedTime').setErrors({ 'required': true });
      this.convertWaitlistForm.get('requestedTime').updateValueAndValidity();
      this.convertWaitlistForm.get('noOfPlayers').setErrors(null);
      this.convertWaitlistForm.get('noOfPlayers').setErrors({ 'required': true });
      this.convertWaitlistForm.get('noOfPlayers').updateValueAndValidity();
    }
    let teeTimeSlot = this.timeSlots != null ? this.timeSlots.find(x=>x.scheduleDateTime == stringdate && x.originalHoleNumber ==this.possibleHole.originalHoleNumber) : null;
    var allocation = teeTimeSlot != null ? teeTimeSlot.allocationBlockId : 0;
    let isSlotAvailable = this._ConvertWaitlistModalBusiness.checkIsSlotExist(this.convertWaitlistForm.value.requestedDate, this.convertWaitlistForm.value.requestedTime, this.timeSlots);
    if (!isSlotAvailable) {
      this.allocationBlockError = false;
      this.requestedTimeInputs.customErrorMessage = this.captions.teeSlotisnotavailablefortheselectedtime;
      this.requestedTimeInputs = { ...this.requestedTimeInputs }
      this.setInvalidateFormControl('requestedTime');
    }
    else if (this.timeSlots != null && allocation==0) {
      this.requestedTimeInputs.customErrorMessage = this.captions.Allocationblockisnotavailable;
      this.requestedTimeInputs = { ...this.requestedTimeInputs }
      this.setInvalidateFormControl('requestedTime');
    }
    this.notifyParent.emit(this.convertWaitlistForm.valid);
  }


  private async buildTableData() {
    this.header = [{
      key: 'playerName',
      value: this.captions.playerName,
    },
    {
      key: 'memberId',
      value: this.captions.memberNo,
    },
    {
      key: 'requestedTime',
      value: this.captions.requestedTime,
    },
    {
      key: 'numberOfPlayers',
      value: this.captions.noOfPlayer,
    },
    {
      key: 'course',
      value: this.captions.course,
    },
    {
      key: 'phoneNumber',
      value: this.captions.phoneNumber,
    },
    {
      key: 'emailId',
      value: this.captions.emailAdress,
    },
    {
      key: 'comments',
      value: this.captions.comments,
    }
    ];

    this.playerList = this.dialogData.componentDetails.popUpDetails.bindData;
    if (this.playerList && this.playerList.length > 0) {
      if (!this.selectedItemId) {
        this.selectedItemId = this.playerList[0].id;
      }
      this.selectedItem = this.playerList.find(x => x.id === this.selectedItemId);
      const checkBindData = this.waitlistBusiness.ConvertWaitList(this.selectedItem);
      this.convertWaitlistForm.patchValue(checkBindData);
      this.staticDate = checkBindData.staticDate;
      this.staticTime = checkBindData.staticTime;
      this.staticName = checkBindData.staticName;
      this.staticCourse = checkBindData.staticCourse;
      this.staticPlayers = checkBindData.staticPlayers;
      this.staticComments = checkBindData.staticComments;
      this.hideForm = checkBindData.editToggle;
      this.existingAdditionalDetails = checkBindData?.playerAdditionalDetails ? _.cloneDeep(checkBindData.playerAdditionalDetails) : null;
      this.playerAdditionalDetails = checkBindData?.playerAdditionalDetails ? checkBindData?.playerAdditionalDetails : null
    }
  }
  async onSelectionChange(event) {
    console.log("onSelectionChange");
    const checkBindData = this.waitlistBusiness.ConvertWaitList(event);
    this.convertWaitlistForm.patchValue(checkBindData);
    this.TeeSheetvalidation();
    this.noOfplayers = Number(checkBindData.noOfPlayers);
    await this.playerchange(null);
    this.convertWaitlistForm.controls.editToggle.setValue(this.convertWaitlistForm.invalid);
    this.staticDate = checkBindData.staticDate;
    this.staticTime = checkBindData.staticTime;
    this.staticName = checkBindData.staticName;
    this.staticCourse = checkBindData.staticCourse;
    this.staticPlayers = checkBindData.staticPlayers;
    this.staticComments = checkBindData.staticComments;
    this.hideForm = checkBindData.editToggle;
  }



  //#region  player based
  async onPlayerTypeChange() {
    let playerTypeId = this.convertWaitlistForm.get('playerType').value;
    if (!await this.hasPermissionForPlayerType(this.allocationBlockId, playerTypeId, this.allocationBlockPlayerTypePermission)) {
      this.convertWaitlistForm.get('playerType').setValue('');
      return;
    }
    await this.setRateTypes();
  }

  noOfPlayersKeyup(event)
  {
    if(event.key == '0' && (this.convertWaitlistForm.controls['noOfPlayers'].value==0 || this.convertWaitlistForm.controls['noOfPlayers'].value==null)) 
    {
      this.convertWaitlistForm.controls.noOfPlayers.setValue(1);
    }
  }



  async timeChange(event, type, teetSlots) {
    this.requestedTimeInputs.customErrorMessage = '';
    this.requestedTimeInputs = { ...this.requestedTimeInputs };
    // this.notifyParent.emit(false);
    var teeSheetSlots;
    if(!this.convertWaitlistForm.get('requestedTime').value)
    {
      this.allocationBlockError = false;
      this.requestedTimeInputs.customErrorMessage = this.captions.invalidTimeError;
      this.requestedTimeInputs = { ...this.requestedTimeInputs }
      this.setInvalidateFormControl('requestedTime');
      this.notifyParent.emit(false);
      return;
    }
    if(!this.convertWaitlistForm.get('requestedTime').valid)
    {
      this.notifyParent.emit(false);
      return;
    }
    if (!teetSlots)
      teeSheetSlots = await this._ConvertWaitlistModalBusiness.getTeeTimeSlot(this.convertWaitlistForm.value);
    else {
      teeSheetSlots = teetSlots;
    }
    this.timeSlots = teeSheetSlots;
    let isSlotAvailable = this._ConvertWaitlistModalBusiness.checkIsSlotExist(this.convertWaitlistForm.value.requestedDate, this.convertWaitlistForm.value.requestedTime, teeSheetSlots);
    if (!isSlotAvailable) {
      this.allocationBlockError = false;
      this.requestedTimeInputs.customErrorMessage = this.captions.teeSlotisnotavailablefortheselectedtime;
      this.requestedTimeInputs = { ...this.requestedTimeInputs }
      this.setInvalidateFormControl('requestedTime');
    } else {
      this.allocationteeslot = this._ConvertWaitlistModalBusiness.getAllocationBlockId(this.convertWaitlistForm.value.requestedDate, this.convertWaitlistForm.value.requestedTime, teeSheetSlots);
      this.allocationBlockId = this.allocationteeslot.allocationblockId;
      this.allocationName = this.allocationteeslot.allocationName;
      if (!this.allocationteeslot.teeslotAvail) {
        if (this.allocationteeslot.allocationblockId == 0) {
          this.allocationBlockError = true;
          this.requestedTimeInputs.customErrorMessage = this.captions.Allocationblockisnotavailable;
          this.requestedTimeInputs = { ...this.requestedTimeInputs }
          this.setInvalidateFormControl('requestedTime');
        }
      }
      else if (this.allocationteeslot.allocationblockId == 0) {
        this.allocationBlockError = true;
        this.requestedTimeInputs.customErrorMessage = this.captions.Allocationblockisnotavailable;
        this.requestedTimeInputs = { ...this.requestedTimeInputs }
        this.setInvalidateFormControl('requestedTime');
      } else {
        this.allocationBlockError = false;
        this.requestedTimeInputs.customErrorMessage = '';
        this.requestedTimeInputs = { ...this.requestedTimeInputs };
        this.noOfplayers = this.convertWaitlistForm.value.noOfPlayers
      }
    }
    await this.playerchange(null);
    await this.setPlayerTypes();

    this.setMinTime();
    let playerTypeId = this.convertWaitlistForm.get('playerType').value;
    if (!await this.hasPermissionForPlayerType(this.allocationBlockId, playerTypeId, this.allocationBlockPlayerTypePermission)) {
      this.convertWaitlistForm.get('playerType').setValue('');
    }
  }

  async setAllocationBlockPermissions() {
    if (this._utilities.IsAllocationCodePermissionEnabled()) {
      [this.allocationBlockPlayerTypePermission, this.allocationBlocks] = await Promise.all([
        this._ConvertWaitlistModalBusiness.GetAllocationBlockPermissionByRole(),
        this._ConvertWaitlistModalBusiness.GetAllAllocationBlocks()]);
    }
  }

  async manualOverideBreakPointAccess() {
    this.overridePlayerTypeDaysoutUserAccess = await this._ConvertWaitlistModalBusiness.ValidateUserBreakPoint(UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT, false);
    this.overrideRateTypeDaysoutUserAccess = await this._ConvertWaitlistModalBusiness.ValidateUserBreakPoint(UserAccessBreakPoints.OVERRIDERATETYPEDAYSOUT, false);
  }

  async getAllPlayers() {
    this.allPlayerTypes = await this._ConvertWaitlistModalBusiness.getAllPlayers();
  }

  async hasPermissionForPlayerType(allocationBlockId, playerTypeId, playerTypePermission: allocationBlockPlayerTypePermission[]) {
    let hasAccess = true;
    if (this._utilities.IsAllocationCodePermissionEnabled() && allocationBlockId > 0) {
      let permissions = playerTypePermission ? cloneDeep(playerTypePermission) : [];
      permissions = permissions.filter(r => r.allocationBlockId == allocationBlockId);
      if (!permissions || permissions.length == 0) {
        let alloc = this.allocationBlocks ? this.allocationBlocks.find(r => r.id == allocationBlockId) : null;
        if(this.allocationCheck){
          this.allocationCheck = false;
          this._utilities.showAllocationCodePermissionDeniedPopup(alloc ? alloc.allocationBlockName : this.allocationName, '', (x)=>{
            this.allocationCheck = true;
          });
        }
        return false;
      }
      if (playerTypeId > 0) {
        permissions = permissions.filter(r => r.playerTypeId == playerTypeId);
      }
      if (!permissions || permissions.length == 0) {
        let allPlayerTypes = await this.playerTypeList$;
        let playerType = allPlayerTypes.find(r => r.key == playerTypeId);
        if(this.allocationCheck){
          this.allocationCheck = false;
          this._utilities.showAllocationCodePermissionDeniedPopup(playerType.label,'', (x)=>{
            this.allocationCheck = true;
          });
        }
        return false;
      }
    }
    return hasAccess;
  }

  async setPlayerTypes() {
    let allocationBlockWithPlayerType: AllocationBlockWithPlayerType;
    this.playerTypeArray = [];
    if (this.allocationBlockId) {
      const allAllocationCodes = await this._ConvertWaitlistModalBusiness.GetPlayerTypeByAllocationBlock();
      allocationBlockWithPlayerType = allAllocationCodes.find(s => s.id === this.allocationBlockId);
    }
    if (allocationBlockWithPlayerType) {
      this.playerTypeArray = allocationBlockWithPlayerType.playerTypes;
    }
    else {
       this.playerTypeArray = this.allPlayerTypes.map(c => {
        return {
          id: c.id,
          description: c.type,
          type: c.type,
        }
      }); 
    }
    this.playerTypeList$ = this.reMapPlayerType(this.playerTypeArray);
    let playerTypeId = this.convertWaitlistForm.get('playerType').value;
    if (this.playerTypeArray != null && !this.playerTypeArray.find(x => x.id == playerTypeId)) {
      this.convertWaitlistForm.get('playerType').setValue('');
      this.setInvalidateFormControl('playerType', false);
    }
    await this.setRateTypes();
  }

  async setRateTypes() {
    let playerTypeId = this.convertWaitlistForm.get('playerType').value;
    let rateTypeArray: RateType[];
    if (playerTypeId) {
      let AllPlayerTypes = await this.codeLinkingDataService.getPlayerTypeRateTypes();
      let playerType = AllPlayerTypes.find(s => s.id == playerTypeId);
      if (playerType) {
        rateTypeArray = playerType.rateTypes
      }
    }
    else {
      rateTypeArray = await this.waitlistBusiness.GetAllRateTypes(false);
    }
    let requestedDateTime = this._localization.AddTimeToDate(this._localization.getDate(this.convertWaitlistForm.value.requestedDate), this._localization.TimeToDate(this.convertWaitlistForm.value.requestedTime));
    this.rateSetup = await this._ConvertWaitlistModalBusiness.getCourseTeeFee(this.convertWaitlistForm.value.course, requestedDateTime);
    if (this.rateSetup && rateTypeArray) {
      rateTypeArray = rateTypeArray.filter(rt => this.rateSetup.find(tf => (tf.rateTypeId == rt.id) &&
        this._ConvertWaitlistModalBusiness.isDateTimeInRange(this.convertWaitlistForm.value.requestedDate,
          this.convertWaitlistForm.value.requestedTime, tf.startTime, tf.endTime)));
    }
    this.rateTypeList$ = this.reMapRateType(rateTypeArray);

    let dateTime = this._localization.ConvertStringDateTimeToDate(this.convertWaitlistForm.value.requestedDate.toString() ,this.convertWaitlistForm.value.requestedTime.split('(')[0].trim())  
    // await this._ConvertWaitlistModalBusiness.getTeeFeesForCourseAndDate(this.courseId, dateTime);
    if(this.rateSetup.length >0) {
      await this.getDynamicRates(this._localization.convertDateTimeToAPIDateTime(dateTime));   
    }

    let rateTypeId = this.convertWaitlistForm.get('rateType').value;
    this.rateTypeArray =  rateTypeArray;
    this.selectedRateType = rateTypeArray.find(x => x.id == rateTypeId);
    if (rateTypeArray != null && !this.selectedRateType) {
      this.convertWaitlistForm.get('rateType').setValue('');
      this.setInvalidateFormControl('rateType', false);
    }
    if(this.selectedRateType && this.rateTypeList$.find(x => x.key == rateTypeId)?.disabled == true)
    {
      this.convertWaitlistForm.get('rateType').setValue('');
      this.convertWaitlistForm.get('editToggle').patchValue(true);
      this._utilities.showAlert(this.captions.rateTypeNotAvailableErrorMsg, AlertType.Info, ButtonType.Ok);
    }
    this.setGreencart(this.convertWaitlistForm.get('rateType').value);
  }

  reMapRateType(types: rateType[]): Options[] {
    let mappedOptions: Options[] = types.map((r: rateType) => {
      return {
        key: r.id, label: r.type
      }

    });

    return (mappedOptions);
  }

  reMapPlayerType(types: PlayerType[]): Promise<Options[]> {
    const mappedOptions: Options[] = types.map((r: PlayerType) => {
      return {
        key: r.id, label: r.type
      };
    });

    return Promise.resolve(mappedOptions);
  }

  setMinTime() {
    const validateDate =
      this.CreateWaitlistModalBusiness.compareDate(this.convertWaitlistForm.value.requestedDate, this._PropertyInformation.CurrentDate);
    const timeFormat = this._localization.timeFormat === 'HH:mm' ? 24 : 12;
    if (validateDate) {
      this.requestedTimeInputs.minTime = this._localization.getTime(this._localization.getCurrentDate(), timeFormat);
      this.requestedTimeInputs = { ... this.requestedTimeInputs };
    } else {
      if (timeFormat === 24) {
        this.requestedTimeInputs.minTime = '00:00';
      } else {
        this.requestedTimeInputs.minTime = '00:00 AM';
      }
      this.requestedTimeInputs = { ... this.requestedTimeInputs };
    }
  }

  async getDynamicRates(dateTime){
    if(this.golfSettings.enableDynamicPricing){
      await this._ConvertWaitlistModalBusiness.GetDynamicPricingForCourseAndScheduledDate(this.courseId, dateTime.toString()).then(res=>{
        this.dynamicPricingRateSetup = res;
        this.rateTypeList$.forEach(x => {
              x.disabled = false;
        });
        if (res.length > 0 && !this.overrideRateTypeForDynamicAvailability) {
          let rateTypes;
            if(this.rateTypeList$.length > 0) {
             this.rateTypeList$.forEach(x => {
                res.forEach(y => {
                  if (x.key === y.rateTypeId) {
                    x.disabled = y.stopAvailability;
                  }
                });
              });
              this.rateTypeList$ = [...this.rateTypeList$];
            } 
        }       
      });
    }else{
    this.dynamicPricingRateSetup =[];
    }
  }
  //endregion

}
