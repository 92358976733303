import { Component, OnInit, Output, EventEmitter, Inject, ChangeDetectorRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StepperService } from '../../shared/components/stepper/stepper.service';
import { refund, RefundPlayers } from './refund-model';
import { RefundBussiness } from './refund-bussiness';
import { RefundService } from './refund-service';
import { CheckboxTableHeader, DialogCloseObj } from 'src/app/shared/shared-models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { UnPaidPlayersService } from 'src/app/shared/data-services/golfschedule/unpaid-players.service';
import { TempHoldStatus } from 'src/app/shared/global.constant';
import { ScheduledTeeTimeUnPaidPlayer } from 'src/app/common/shared/shared.modal';
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';

@Component({
  selector: 'app-refund-modal',
  templateUrl: './refund-modal.component.html',
  styleUrls: ['./refund-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [RefundBussiness, RefundService, UnPaidPlayersService]
})
export class RefundModalComponent implements OnInit, OnDestroy {


  refundForm: UntypedFormGroup;
  saveSubscriber: Subscription;
  formData: UntypedFormGroup;
  refundFormSubscribe: Subscription;
  refundArray: refund[];
  isMultple = false;
  headerOptions: TableHeaderOptions[];
  tableData: RefundPlayers[];
  options: TableOptions;
  @Output() notifyParent = new EventEmitter();
  selectedRefundPlayer : RefundPlayers;

  constructor(
    private _FormBuilder: UntypedFormBuilder
    , @Inject(MAT_DIALOG_DATA) public dialogData
    , private cdr: ChangeDetectorRef
    , public _StepperService: StepperService
    , private _refundBussiness: RefundBussiness
    , private _localization: GolfLocalization
  ) { 
    if (dialogData) {
      this.isMultple = dialogData.isMultiple;
    }
  }

  ngOnInit() {
    if (this.isMultple) {
      this.formGenertor();
      this.notifyParent.emit(true);
      this.refundFormSubscribe = this.refundForm.valueChanges.subscribe(x => {
        this.formData = x;
        this.notifyParent.emit(this.refundForm.valid);
      });
      this.refundArray = this._refundBussiness.refundArray();
      this.refundForm.controls.refund.setValue(this._localization.captions.teetime.Refund);
      this._StepperService.enableSave = false;
      this.saveSubscriber = this._StepperService.valueChange.subscribe((x: DialogCloseObj) => {
        this.submitForm(x);
      });
      this.headerOptions = this._refundBussiness.GetHeaderOption();
      this.options = this._refundBussiness.getTableOptions();
      this._refundBussiness.mapTableDataFromDialogData(this.dialogData).then(result => {
        this.tableData = result;
      });
    }
  }

  formTableData(dialogData){

  }

  formGenertor() {
    this.refundForm = this._FormBuilder.group({
      refund: this._localization.captions.teetime.Refund
    });
  }

  async submitForm(x : DialogCloseObj) {
    console.log("formData =", this.formData);
    let playerDetail = (this.selectedRefundPlayer && this.dialogData.info.playerDetail && this.dialogData.info.playerDetail.length > 0) ?
    this.dialogData.info.playerDetail.find(x => x.playerId == this.selectedRefundPlayer.id) : this.dialogData.info.playerDetail;
    this.dialogData.info.playerDetail =  playerDetail ? [playerDetail] : this.dialogData.info.playerDetail;
    await this._refundBussiness.ProceedRefund(this.dialogData.info);
    x.dialogRef.close();
  }

  tableAction(e) {
    this.selectedRefundPlayer = e.Obj;
    this._StepperService.enableSave = this.selectedRefundPlayer ?  true : false;
  }

  ngOnDestroy(): void {
    this.refundFormSubscribe.unsubscribe();
    this.saveSubscriber.unsubscribe();
  }

}
