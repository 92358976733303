
import { Injectable } from "@angular/core";
import {PlayerDetailService} from './player-detail.service';
import { RateTypeDataService } from 'src/app/shared/data-services/golfmanagement/ratetype.data.service';
import { CodeLinkingDataService } from 'src/app/shared/data-services/golfmanagement/codelinking.data.service';
import { RateType } from 'src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { LessonLocationDataService } from 'src/app/shared/data-services/golfmanagement/lesson-location.data.service';
import { LessonLocation } from 'src/app/settings/golf-setup/code-setup/lesson-location/lesson-location.model';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { PlayerTypeService } from 'src/app/shared/data-services/golfmanagement/playertype.data.service';
import { PlayerType } from 'src/app/settings/golf-setup/code-setup/player-type/player-type.modal';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import { AllocationBlockDataService as AllocationBlockManagementDataService } from 'src/app/shared/data-services/golfmanagement/allocationblock.data.service';
import { allocationBlockPlayerTypePermission } from "src/app/settings/user-setup/booking-permission/booking-permission.model";
import * as CodeLinkingAPI from 'src/app/settings/golf-setup/code-linking/code-link-modal';
import { UserAccessModel } from "src/app/common/dataservices/authentication/useraccess-model.model";
import { API } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import * as PlayerTypeNew from 'src/app/tee-time-actions/teetime/player-details/player-info.model';
import { SettingsDataService } from "src/app/shared/data-services/golfschedule/settings.data.service";
import { SettingModule, SettingScreen } from "src/app/shared/global.constant";
import { LessonBookingDataService } from "src/app/shared/data-services/golfschedule/lesson-booking.data.service";
import { Schedule } from "src/app/settings/settings-shared/models/schedule.model";

@Injectable()
export class PlayerDetailBusiness {

    public readonly captions;
    playerCaptions: any;
    settingsCaptions: any;
    public isAllow: boolean = false;
    public isViewOnly: boolean = false;
    public lessonScheduledDate: Date;
    constructor(private _PlayerInfoService:PlayerDetailService,private _codeLink:CodeLinkingDataService,private _rateType : RateTypeDataService,private _utils : GolfUtilities,private _lessonLocation : LessonLocationDataService,
                private _localization : GolfLocalization,private _settingsDataService: SettingsDataService
                , private _playerTypeDataService: PlayerTypeService
                , private _userAccessBusiness: UserAccessBusiness
                , private _lessonService: LessonBookingDataService
                , private allocationCodeManagementService: AllocationBlockManagementDataService
                ,  private _codeLinkingDataService: CodeLinkingDataService){
            this.captions = this._localization.captions.lessonBooking;
            this.settingsCaptions = this._localization.captions.settings;
            this.playerCaptions = this._localization.captions.guest.players;
    }

    initializeUserAccessVariables() {
        this.isAllow = false;
        this.isViewOnly = false;
    }

    async getAllPlayerTypes(): Promise<PlayerType[]> {        
            let _playerTypes = await this._playerTypeDataService.getAllPlayerTypes(true);
        return _playerTypes ? _playerTypes : [];
    }

    async getInstructorSchedules(instructorId:number):Promise<Date[]>{
        let _scheduleDetail = await this._lessonService.getInstructorSchedule(instructorId, 365);
        return _scheduleDetail.map(g=> { return this._localization.getDate(g.scheduleDate)})
    }

    async generatePlayerType(): Promise<CodeLinkingAPI.API.PlayerTypeRateType[]>{
        let players = await this._codeLink.getPlayerTypeRateTypes();
        return   players ? players : [];
    }
    async generateRateType(){
        let ratetype = await  this._rateType.getAllRateTypes(false);
        return ratetype ? ratetype : [];
    }
     async generateAllocationCode():Promise<CodeLinkingAPI.API.AllocationBlockPlayerType[]>{
        let allocation = await this._codeLink.getAllocationBlockPlayerTypes();
        return allocation ? allocation : [];
    }
    async generateLessonLocation(): Promise<LessonLocation[]>{
        let lessons = await this._lessonLocation.GetLessonLocations(false);
        return lessons ? lessons : [];
    }

    getRateTypesForPlayer(playerRateTypes : CodeLinkingAPI.API.PlayerTypeRateType[],playerId : any):RateType[]{
        let playerRateType = playerRateTypes.find(player => player.id == playerId);
        if(playerRateType && playerRateType.rateTypes)
        {
            playerRateType.rateTypes=playerRateType.rateTypes.filter(x=>x.isActive);
        }
        return playerRateType?.rateTypes;
    }

    getAllocationcodeforPlayer(allocationPlayer : CodeLinkingAPI.API.AllocationBlockPlayerType[],playerId : any){
        let allocationList : CodeLinkingAPI.API.AllocationBlockPlayerType[] = [];
        allocationPlayer.forEach(allocation=>{
          let mappedAllocation =  allocation.playerTypes.filter(allocation=> allocation.id == playerId);
            if(mappedAllocation && mappedAllocation.length > 0)
                allocationList.push(allocation);
        });
        return allocationList;
    }

    getPlayerTypesForRateType(playerRateTypes : CodeLinkingAPI.API.PlayerTypeRateType[],rateTypeId : any) :CodeLinkingAPI.API.PlayerTypeRateType[]{
        let playerTypeRateType : CodeLinkingAPI.API.PlayerTypeRateType[] = [];
        playerRateTypes.forEach(player =>{
            let assRates = player.rateTypes.filter(rate => rate.id == rateTypeId);
            if(assRates && assRates.length > 0)
                playerTypeRateType.push(player);
        })
        return playerTypeRateType;
    }

    public getDefaultSettings(): PlayerTypeNew.DefaultsSettingConfig {
        let defaultsApiData: API.DefaultsSettingConfig = JSON.parse(sessionStorage.getItem('defaultSettings'));
        return this.mapDefaultData(defaultsApiData);
    }
    
    private mapDefaultData(configValue: API.DefaultsSettingConfig): PlayerTypeNew.DefaultsSettingConfig {
      return {
          blockingCode: configValue.blockingCode,
          memberStatus: configValue.memberStatus,
          memberPlayerType: configValue.memberPlayerType,
          memberRateType: configValue.memberRateType,
          nonMemberPlayerType: configValue.nonMemberPlayerType,
          nonMemberRateType: configValue.nonMemberRateType,
          resortPlayerType: configValue.resortPlayerType,
          resortRateType: configValue.resortRateType,
          memberGuestPlayerType: configValue.memberGuestPlayerType,
          memberGuestRateType: configValue.memberGuestRateType,
          memberGuestsToAdd: configValue.memberGuestsToAdd,
          memberGuestPlayerFirstName: configValue.memberGuestPlayerFirstName,
          memberGuestPlayerLastName: configValue.memberGuestPlayerLastName,
          defaultPlayerCategory: configValue.defaultPlayerCategory,
          defaultHotelReservationCategory:configValue.defaultHotelReservationCategory,
          defaultPhoneTypeOption:configValue.defaultPhoneTypeOption,
          defaultEmailTypeOption:configValue.defaultEmailTypeOption,
          defaultCountryCode:configValue.defaultCountryCode
      }        
    }
    

    async ValidateUserBreakPoints() : Promise<UserAccessModel.BreakPointResult>{
        return await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.CHANGEALLOCATIONBLOCK, false);
        
      }
    
      public showBreakPointPopup(functionName?: string, callBack?: any) {
        this._userAccessBusiness.showBreakPointPopup(functionName);
    }

    public async GetAllocationBlockPermissionByRole():Promise<allocationBlockPlayerTypePermission[]> {
        return await this.allocationCodeManagementService.getAllocationBlockPermissionByRole();
    }

    public async getPackageLinkedPlayerTypesRateTypes(packageCode:string): Promise<CodeLinkingAPI.API.PackagePlayerTypesRateTypes> {
        return await this._codeLinkingDataService.getPackagePlayerTypesRateTypes(packageCode);
         
    }

}
