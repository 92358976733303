<section class='golf-actions'>
  <div [attr.automationId]="'Btn_GolfActions_slideMenuClose'" class='overlay-div' (click)='slideMenuClose()'></div>
  <div class='actions-container'>
    <div class='action-content' [ngClass]="addCheckInCheckOutClass?'action-content-checkInCheckOut':''" *ngIf='inputs'>
      <div class='close'>
        <i [attr.automationId]="'Btn_GolfActions_slideMenuClose'" aria-hidden="true" class='icon-Cancel'
          (click)='slideMenuClose()'>&nbsp;</i>
      </div>
      <div class='status' [style.background-color]="inputs.statusColor">
        <label
          [className]="(inputs?.statusColor | colorChecker)? 'dark-bg-light-color': 'light-bg-dark-color'">{{inputs.status}}</label>
      </div>
      <div class='top-content'>
        <div class='details' *ngFor="let detail of inputs.details">
          <label class="mb-1">{{detail.label}}</label>
          <div class='details-container'>
            <p *ngIf="!detail.colorCode && !detail.startHole" class=' ag-mb-0'
              [ngClass]="{'w-100': !detail.colorCode}">
              {{detail.text}}
            </p>
            <span *ngIf='detail.colorCode && !isAllocationEdit' class='color-code'
              [appBgColor]="detail.colorCode"></span>
            <p *ngIf="detail.colorCode && !isAllocationEdit" class=' ag-mb-0'
              [ngClass]="{'w-100': !detail.colorCode}">
              {{detail.text}}
            </p>
            <i [attr.automationId]="'Btn_GolfActions_editAllocationCode'" aria-hidden="true"
              *ngIf='this.inputs.scheduleStatus !== scheduleStatus.frostDelay && detail.colorCode && !isAllocationEdit' class="icon-Edit cursor"
              (click)="editAllocationCode($event)"></i>
            <p *ngIf="detail.startHole && !isStartHoleEdit" class=' ag-mb-0'
              [ngClass]="{'w-100': !detail.startHole}">
              {{detail.text}}
            </p>
            <i [attr.automationId]="'Btn_GolfActions_editStartHoleCode'" aria-hidden="true"
              *ngIf='this.inputs.scheduleStatus !== scheduleStatus.frostDelay && detail.startHole && !isStartHoleEdit' class="icon-Edit cursor"
              (click)="editStartHoleCode($event)"></i>
            <form class="allocation-form" *ngIf='(detail.colorCode) && (isAllocationEdit)' [formGroup]="allocationForm"
              autocomplete="off">
              <div class="input-container">
                <mat-form-field class="controls layout w-80" [floatLabel]="floatLabel">
                  <span *ngIf="allocationForm.get('allocationCode')?.value"
                    [style.background-color]="allocationForm.get('allocationCode')?.value?.colorCode" matPrefix
                    class='allocationCode--color'></span>
                  <mat-select [attr.automationId]="'Tog_GolfActions_allocationCode'" name="allocationCode"
                    formControlName="allocationCode" (selectionChange)="allocationCodeChange($event)" floatLabel="never"
                    [compareWith]="comparetSelects">
                    <mat-option *ngFor="let allocationCode of inputs.allocationBlockOptions" [value]="allocationCode">
                      {{allocationCode?.allocationBlockName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="action-container">
                  <i [attr.automationId]="'Btn_GolfActions_saveAllocationCode'" aria-hidden="true"
                    class="icon-done ag-mr-2 cursor" [ngClass]="{'button_invalid': !isAllocationChanged}"
                    (click)="saveAllocationCode($event)"></i>
                  <i [attr.automationId]="'Btn_GolfActions_cancelAllocationCode'" aria-hidden="true"
                    class="icon-Cancel cursor" (click)="cancelAllocationCode($event)"></i>
                </div>
              </div>
            </form>

            <form class="allocation-form" *ngIf='(detail.startHole) && (isStartHoleEdit)' [formGroup]="allocationForm"
              autocomplete="off">
              <div class="input-container">
                <mat-form-field class="controls layout w-80" [floatLabel]="floatLabel">
                  <input matInput type='number' min="1" max="18" numberMaxlength maxlength="2"
                    formControlName="startHole" (input)="startHoleChange($event)">
                </mat-form-field>
                <div class="action-container">
                  <i [attr.automationId]="'Btn_GolfActions_saveStartHole'" aria-hidden="true"
                    class="icon-done ag-mr-2 cursor" [ngClass]="{'button_invalid': !isStartHoleChanged}"
                    (click)="saveStartHole($event)"></i>
                  <i [attr.automationId]="'Btn_GolfActions_cancelStartHole'" aria-hidden="true"
                    class="icon-Cancel cursor" (click)="cancelStartHole($event)"></i>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class='date-time' *ngIf='inputs.dateTime'>
          <span class='date'>
            <label class=' mb-1'>{{inputs.dateTime?.dateLabel}}</label>
            <p class=' ag-mb-0 '>{{inputs.dateTime?.dateText}}</p>
          </span>
          <span class='time mb-1 ag-ml-5'>
            <label class='mb-1'>{{inputs.dateTime?.timeLabel}}</label>
            <p class='ag-mb-0 '>{{inputs.dateTime?.timeText}}</p>
          </span>
        </div>
        <div class='players' *ngIf='showPlayers'>
          <label class='mb-0'>{{captions.players}}</label>
          <div class='player' *ngFor='let player of inputs.players'>
            <span class='player-image'>
              <ag-img-golfthumbnail [imageContent]="player['profileImage']" [thumbnail]="true" imageType="client">
              </ag-img-golfthumbnail>
            </span>
            <span class='player-name'>{{player.name}}</span>
          </div>
        </div>

        <div class='deposit' *ngIf='showDeposit'>
          <div class="ag_display--flex">
            <label  class='mb-0'>{{captions.DepositAmount}}</label>
            <span class="ag_link" *ngIf="isDepositRefundEnabled" (click)="viewRefundDeposit($event)">{{captions.teetime.lbl_viewRefundDeposit}}</span>
          </div>
          <div class='deposit-details'>
            <span class='deposit-amount'>{{inputs.deposit}}</span>
            <app-golfbutton [automationId]="'GolfActions'" *ngIf="showDepositAdd" [buttontype]="addButton"
              class="app-golfbutton in-block golf__link--sm" (valueChange)='addDeposit($event)'>
            </app-golfbutton>
          </div>
        </div>
      </div>

      <div class='actions-group' [ngClass]="addCheckInCheckOutClass?'actions-check-in-check-out-group':''"
        *ngIf='inputs.actions'>
        <label class='action-label'>{{inputs.actionTitle}}</label>
        <div class='action-button ag-mt-4'>
          <span attr.automationId='Btn_GolfActions_{{action.label}}' class='action'
            [ngClass]="{'border-top-none':(i > 2), 'border-side':(i+1)%3 == 2 , 'last-row-child':last, 'action--disabled': action.isDisable}"
            *ngFor="let action of inputs.actions; let i = index; let last = last" (click)="actionClick(action.id)">
            <i aria-hidden="true" class="ag-mb-2" [ngClass]="['icon-' + action.icon]">&nbsp;</i>
            <p class="ag-mb-0">{{action.label}}</p>
          </span>
        </div>
      </div>
    </div>
    <div class="checkInCheckOutContainer" *ngIf="isRequireCheckInCheckOut&&inputs?.checkInCheckOutActions">
      <section class="w-100" *ngFor="let action of inputs.checkInCheckOutActions">
        <section class="checkInCheckOutSection" [ngClass]="action.class">
          <app-golfbutton [automationId]="'GolfActions'" [buttontype]="action"
            class="app-golfbutton  checkInCheckOutAction"
            (valueChange)='onCheckInCheckOut(action.teeTimeAction)'></app-golfbutton>
        </section>
      </section>
    </div>
    <div>
      <app-golfbutton [throttleTime]="1000" [automationId]="'GolfActions'" [buttontype]="actionButton" class="app-golfbutton in-block actions"
        (valueChange)='buttonClick($event)'></app-golfbutton>
    </div>
  </div>

</section>