import { Injectable } from "@angular/core";
import { printModel , printArr } from './print-model';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Injectable()

export class PrintModalService
{
    captions: any;
    constructor(private _localization: GolfLocalization){
      this.captions = this._localization.captions.reports;
    }

    getPrintOptions(): printModel[] {
    return [
        {
          id: printArr.teeTickets,
          value:this.captions.teeTicketsPrint,
          checked:false
        },
        {
          id: printArr.receipt,
          value: this.captions.receiptPrint,
          checked:true
        },
        {
          id: printArr.cartCards,
          value: this.captions.cartCards,
          checked:false
        },
        {
          id: printArr.bagTags,
          value: this.captions.bagTags,
          checked:false
        },
      ]    
    }
}