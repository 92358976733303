import { Injectable } from '@angular/core';
import { API, TeeTimeData, tableArray } from './tee-times.modal';
import { SettingsDataService } from 'src/app/shared/data-services/golfmanagement/settings.data.service';
import * as _ from 'lodash';
import { SettingModule, SettingScreen } from 'src/app/shared/global.constant';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { UntypedFormGroup } from '@angular/forms';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';
import { DaysModel } from 'src/app/shared/shared-models';
import { SystemdefaultsInformationService } from 'src/app/core/services/systemdefaults-information.service';


@Injectable()
export class TeeTimeBusinessService {

  public readonly captions: Partial<string>;
  public unModifiedData: API.Settings<API.TeeTimeConfig>;
  public unModifiedFormValue: TeeTimeData;
  public isAllow: boolean = false;
  public isViewOnly: boolean = false;

  constructor(private _settingsDataService: SettingsDataService
    , private _systemdefaultsInformationService: SystemdefaultsInformationService
    , public _localization: GolfLocalization
    , public _userAccessBusiness: UserAccessBusiness) {
    this.captions = this._localization.captions.settings;
  }


  async validateBreakPoints(): Promise<boolean> {
    const result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.SYSTEMSETUP, true);
    this.isViewOnly = result.isViewOnly;
    this.isAllow = result.isAllow;
    return result.isAllow;
  }

  public saveTeeTimesSettings(value: TeeTimeData): void {
    let _dataToAPI: API.TeeTimeConfig = this.mapToAPI(value);
    let _setting: API.Settings<API.TeeTimeConfig> = {
      id: this.unModifiedData.id,
      moduleName: this.unModifiedData.moduleName,
      screenName: this.unModifiedData.screenName,
      configValue: _dataToAPI,
      defaultValue: this.unModifiedData.defaultValue
    }
    this._settingsDataService.updateSettings(_setting);
    this._systemdefaultsInformationService.UpdateTeeTimeSetting(_dataToAPI);
    this.unModifiedData = _.cloneDeep(_setting);
    this.unModifiedFormValue = _.cloneDeep(value);
  }

  public async fetchTeeTimeData(settingsData?: API.Settings<API.TeeTimeConfig>): Promise<TeeTimeData> {
    let apiData: API.Settings<API.TeeTimeConfig> = (settingsData) ? settingsData : await this._settingsDataService.getSettings<API.TeeTimeConfig>(SettingModule.SystemSetup, SettingScreen.TeeTime);
    let formData: TeeTimeData = this.mapToUI(apiData.configValue);
    this.unModifiedData = _.cloneDeep(apiData);
    this.unModifiedFormValue = _.cloneDeep(formData);
    return formData;
  }

  public generateDaysArray(): DaysModel[] {
    return this._localization.getDaysModel(false);
  }

  public generateMinutesToRefreshTableData(days: DaysModel[]): tableArray[] {
    return <tableArray[]>days.map(d => {
      return <tableArray>{
        id: d.id,
        autoRefresh: false,
        minInput: 0,
        dayCode: d.code
      }
    });
  }

  public shouldDisableSave(form: UntypedFormGroup): boolean {
    return !(form.dirty && form.valid);
  }

  public mapToUI(apiData: API.TeeTimeConfig): TeeTimeData {
    return {
      tableArray: this.mapTeeSheetRefresh(apiData.refreshTime),
      fieldGroup: {
        rainCheckNote: apiData.rainCheckNote,
        tempHoldDuration: apiData.tempHoldDuration,
        teeTimeTicketNote: apiData.ticketNote,
        firstDeposit: apiData.firstDeposit.isApplicable ?
          this._localization.localizePercentage(apiData.firstDeposit.depositPercentage.toString()) : '',
        secondDeposit: apiData.secondDeposit.isApplicable ?
          this._localization.localizePercentage(apiData.secondDeposit.depositPercentage.toString()) : '',
        depositRefund: apiData.depositRefund.isApplicable ?
          this._localization.localizePercentage(apiData.depositRefund.depositPercentage.toString()) : '',
        dueDaysAfterBooking: apiData.firstDeposit.dueDays,
        dueDaysPriorToPlay1: apiData.secondDeposit.dueDays,
        dueDaysPriorToPlay2: apiData.depositRefund.dueDays
      },
      toggleGroup: {
        requirePlayerType: apiData.requirePlayerType,
        requireRateType: apiData.requireRateType,
        showMemberSignOnTeeGrid: apiData.showMemberSignOnTeeGrid,
        defaultToGraphicalTeeSheet: apiData.defaultToGraphicalTeeSheet,
        promptUserToPrintTeeTickets: apiData.promptUserToPrintTeeTickets,
        printTeeTicketsPaidAgainstOneSlot: apiData.printTeeTicketsPaidAgainstOneSlot,
        autoPrintTeeTickets: apiData.autoPrintTeeTickets,
        requireTeeTimesToBePaidNoShowedByDayEnd: apiData.requireTeeTimesToPaid,
        setPaidOnDayOfPlay: apiData.setPaidOnDayOfPlay,
        requireCheckInCheckOut: apiData.requireCheckInCheckOut,
        enableAutoCheck: apiData.enableAutoCheck,
        allowPaymentInCheckIn: apiData.allowPaymentInCheckIn,
        allowPlayerAllocationBlock: apiData.allowPlayerAllocationBlock,
        autoCheckInCheckOutZeroCharges: apiData.autoCheckInCheckOutZeroCharges,
        editCaddyInfoOnMoveCopy: apiData.editCaddyInfoOnMoveCopy,
        requireEformOnPaymentCheckIn: apiData.requireEformOnPaymentCheckIn,
        cancelPlayerFromTeeSheet: apiData.cancelPlayerFromTeeSheet,
        restrictPayAfterRefundForPreviousDay: apiData.restrictPayAfterRefundForPreviousDay,
        updateCustomFeeForExistingBookings: apiData.updateCustomFeeForExistingBookings,
        updateRatesForExistingBooking: apiData.updateRatesForExistingBooking,
        includePlayersAcrossCoursesForCheckIn: apiData.includePlayersAcrossCoursesForCheckIn,
        checkForTeeTimeOverlaps: apiData.checkForTeeTimeOverlaps,
        overlapHours: apiData.overlapHours,
        allowNoShowPriortoPlay: apiData.allowNoShowPriortoPlay,
        allowCancellationAcrossSlots: apiData.allowCancellationAcrossSlots,
        isNoShowCancellationPolicyConfigured: apiData.isNoShowCancellationPolicyConfigured
      },
      defaultValues: {
        address: apiData.address,
        cell: apiData.cell,
        city: apiData.city,
        country: apiData.country,
        dateOfBirth: apiData.dateOfBirth,
        home: apiData.home,
        office: apiData.office,
        packageCode: apiData.packageCode,
        patronId: apiData.patronId,
        personal: apiData.personal,
        postelCode: apiData.postelCode,
        primaryEmail: apiData.primaryEmail,
        primaryPhone: apiData.primaryPhone,
        pronounced: apiData.pronounced,
        rank: apiData.rank,
        roomNumber: apiData.roomNumber,
        state: apiData.state,
        work: apiData.work,
        stayDates: apiData.stayDates,
        businessEmail: apiData.businessEmail,
        workPhone: apiData.workPhone,
        businessPhone: apiData.businessPhone,
        homeEmail: apiData.homeEmail
      }
    }
  }

  private mapSheetRefresh(data: tableArray[]): API.RefreshTime[] {
    let days: DaysModel[] = this._localization.getDaysModel(false);
    return data.map((o, index: number) => {
      return {
        dayOfWeek: days[index].id,
        dayOfWeekCode: days[index].code,
        minutesToRefresh: o.minInput ? o.minInput : 0,
        autoRefresh: o.autoRefresh

      } as API.RefreshTime
    });
  }

  private mapToAPI(value: TeeTimeData): API.TeeTimeConfig {
    return {
      refreshTime: this.mapSheetRefresh(value.tableArray),
      rainCheckNote: value.fieldGroup.rainCheckNote,
      ticketNote: value.fieldGroup.teeTimeTicketNote,
      tempHoldDuration: value.fieldGroup.tempHoldDuration,
      firstDeposit: this.mapDeposit(value.fieldGroup.firstDeposit, value.fieldGroup.dueDaysAfterBooking),
      secondDeposit: this.mapDeposit(value.fieldGroup.secondDeposit, value.fieldGroup.dueDaysPriorToPlay1),
      depositRefund: this.mapDeposit(value.fieldGroup.depositRefund, value.fieldGroup.dueDaysPriorToPlay2),
      requirePlayerType: value.toggleGroup.requirePlayerType,
      requireRateType: value.toggleGroup.requireRateType,
      showMemberSignOnTeeGrid: value.toggleGroup.showMemberSignOnTeeGrid,
      defaultToGraphicalTeeSheet: value.toggleGroup.defaultToGraphicalTeeSheet,
      promptUserToPrintTeeTickets: value.toggleGroup.promptUserToPrintTeeTickets,
      autoPrintTeeTickets: value.toggleGroup.autoPrintTeeTickets,
      printTeeTicketsPaidAgainstOneSlot: value.toggleGroup.printTeeTicketsPaidAgainstOneSlot,
      requireTeeTimesToPaid: value.toggleGroup.requireTeeTimesToBePaidNoShowedByDayEnd,
      setPaidOnDayOfPlay: value.toggleGroup.setPaidOnDayOfPlay,
      requireCheckInCheckOut: value.toggleGroup.requireCheckInCheckOut,
      enableAutoCheck: value.toggleGroup.enableAutoCheck,
      allowPaymentInCheckIn: value.toggleGroup.allowPaymentInCheckIn,
      allowPlayerAllocationBlock: value.toggleGroup.allowPlayerAllocationBlock,
      autoCheckInCheckOutZeroCharges: value.toggleGroup.autoCheckInCheckOutZeroCharges,
      editCaddyInfoOnMoveCopy: value.toggleGroup.editCaddyInfoOnMoveCopy,
      cancelPlayerFromTeeSheet: value.toggleGroup.cancelPlayerFromTeeSheet,
      checkForTeeTimeOverlaps: value.toggleGroup.checkForTeeTimeOverlaps,
      allowNoShowPriortoPlay: value.toggleGroup.allowNoShowPriortoPlay,
      allowCancellationAcrossSlots: value.toggleGroup.allowCancellationAcrossSlots,
      overlapHours: value.toggleGroup.checkForTeeTimeOverlaps ? value.toggleGroup.overlapHours : 0,
      address: value.defaultValues.address,
      cell: value.defaultValues.cell,
      city: value.defaultValues.city,
      country: value.defaultValues.country,
      dateOfBirth: value.defaultValues.dateOfBirth,
      home: value.defaultValues.home,
      office: value.defaultValues.office,
      packageCode: value.defaultValues.packageCode,
      patronId: value.defaultValues.patronId,
      personal: value.defaultValues.personal,
      postelCode: value.defaultValues.postelCode,
      primaryEmail: value.defaultValues.primaryEmail,
      primaryPhone: value.defaultValues.primaryPhone,
      pronounced: value.defaultValues.pronounced,
      rank: value.defaultValues.rank,
      roomNumber: value.defaultValues.roomNumber,
      state: value.defaultValues.state,
      work: value.defaultValues.work,
      requireEformOnPaymentCheckIn: value.toggleGroup.requireEformOnPaymentCheckIn,
      stayDates: value.defaultValues.stayDates,
      restrictPayAfterRefundForPreviousDay: value.toggleGroup.restrictPayAfterRefundForPreviousDay,
      updateRatesForExistingBooking: value.toggleGroup.updateRatesForExistingBooking,
      includePlayersAcrossCoursesForCheckIn: value.toggleGroup.includePlayersAcrossCoursesForCheckIn,
      updateCustomFeeForExistingBookings: value.toggleGroup.updateCustomFeeForExistingBookings,
      isNoShowCancellationPolicyConfigured: value.toggleGroup.isNoShowCancellationPolicyConfigured,
      businessPhone: value.defaultValues.businessPhone,
      workPhone: value.defaultValues.workPhone,
      homeEmail: value.defaultValues.homeEmail,
      businessEmail: value.defaultValues.businessEmail
    };
  }

  private mapDeposit(deposit: string, days: number): API.Deposit {
    return {
      depositPercentage: deposit == '' ? 0 : parseFloat(deposit),
      dueDays: days ? days : 0,
      isApplicable: deposit != ''
    };

  }


  private mapTeeSheetRefresh(value: API.RefreshTime[]): tableArray[] {
    return value.map((r, index) => {
      return {
        id: index,
        autoRefresh: r.autoRefresh,
        minInput: r.minutesToRefresh ? r.minutesToRefresh : 0
      } as tableArray;
    });
  }

}
