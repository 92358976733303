import { Injectable, Inject } from "@angular/core";
import { Observable, from } from 'rxjs';

import { Options } from 'src/app/dynamicforms/form-type.entity';
import { map } from 'rxjs/operators';
import { PlayerType } from '../../golf-setup/code-setup/player-type/player-type.modal';
import { RateTypeDataService } from '../../../shared/data-services/golfmanagement/ratetype.data.service';
import { PlayerTypeService } from '../../../shared/data-services/golfmanagement/playertype.data.service';
import { AllocationBlockDataService } from 'src/app/shared/data-services/golfmanagement/allocationblock.data.service';
import { API } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import { SettingsDataService } from 'src/app/shared/data-services/golfmanagement/settings.data.service';
import { DefaultSetting, DefaultsSettingConfig, DefaultsCaptions, NameFormatDropDown, MultiCourseViewPlayerNameFormat } from 'src/app/shared/models/default-settings.models';
import { API as settingsAPI, DefaultOutlet } from '../tee-times/tee-times.modal';
import { SettingModule, SettingScreen, PropertyConfigurations, ExistingPlayerTypes } from 'src/app/shared/global.constant';
import * as _ from 'lodash';
import { RateType } from '../../golf-setup/code-setup/rate-type/rate-type.modal';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { UntypedFormGroup } from '@angular/forms';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { PropertyConfigurationDataService } from 'src/app/shared/data-services/tenantmanagement/propertyConfiguration.data.service';
import { SystemdefaultsInformationService } from 'src/app/core/services/systemdefaults-information.service';
import { DefaultOutletType } from 'src/app/common/shared/shared/enums/enums';
import { AllocationCodeSwitchToOptions, AllocationCodeSwitch, MovePlayerOption } from './defaults-model';
import { Player } from "src/app/tee-time-actions/player-option/player-option.modal";
import { PaymentMethod } from "src/app/retail/retail.modals";
import { PaymentBusinessService } from "src/app/shared/data-services/payment/payment-business.service";

Injectable()
@Injectable()
export class DefaultsBusinessService {
    public readonly captions: DefaultsCaptions;
    public unModifiedData: settingsAPI.Settings<settingsAPI.DefaultsSettingConfig>;
    public unModifiedFormData: DefaultSetting;
    public isAllow: boolean = false;
    public isViewOnly: boolean = false;
    unModifiedConfigData: settingsAPI.PropertyConfig<settingsAPI.ApplicationFormat>;

    constructor(public _playerTypeService: PlayerTypeService,
        public _rateTypeDataService: RateTypeDataService,
        public _allocationBlockDataService: AllocationBlockDataService,
        @Inject(SettingsDataService) public _settingsDataService: SettingsDataService,   //work around barrel issue
        private _localization: GolfLocalization, public _userAccessBusiness: UserAccessBusiness,
        private _utilities: GolfUtilities, public _propertyConfigurationDataService: PropertyConfigurationDataService, public _defaultsInfoService: SystemdefaultsInformationService,
        public _paymentService: PaymentBusinessService) {
        this.captions = this._localization.captions.settings;
    }

    async validateBreakPoints(): Promise<boolean> {
        const result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.SYSTEMSETUP, true);
        this.isViewOnly = result.isViewOnly;
        this.isAllow = result.isAllow;
        return result.isAllow;
    }

    public getRateType$(): Observable<Options[]> {

        let pRateType$: Observable<RateType[]> = from(this._rateTypeDataService.getAllRateTypes(false));
        return pRateType$.pipe(
            map((r: RateType[]) =>
                r.map(o => {
                    return {
                        key: o.id,
                        label: o.type
                    }
                }
                )));
    }

    public getPlayerType$(): Observable<Options[]> {
        let pRateType$: Observable<PlayerType[]> = from(this._playerTypeService.getAllPlayerTypes());
        return pRateType$.pipe(
            map((r: PlayerType[]) =>
                r.map(o => {
                    return {
                        key: o.id,
                        label: o.type
                    }
                }
                )));
    }

    public getPlayerCategory(): Options[] {
        let playerCategoryOptions: Options[] = [
            { key: 1, label: this.captions.player },
            { key: 2, label: this.captions.hotelReservation },
            { key: 3, label: this.captions.member },
            { key: 4, label: this.captions.existingPlayer }
        ];

        return playerCategoryOptions;
    }
    public getHotelReservationCategory(): Options[] {
        let HotelReservationCategoryOptions: Options[] = [
            { key: 1, label: this.captions.confiramtionNumber },
            { key: 2, label: this.captions.roomNumber },
            { key: 0, label: this.captions.guestName },
            { key: 3, label: this.captions.linkCode },
            { key: 4, label: this.captions.contactDetail }
        ];

        return HotelReservationCategoryOptions;
    }

    public getExistingPlayerCategory(): Options[] {
        return [
            { key: ExistingPlayerTypes.firstName, label: this.captions.FirstName },
            { key: ExistingPlayerTypes.lastName,  label: this.captions.LastName },
            { key: ExistingPlayerTypes.fullName,  label: this.captions.fullName },
            { key: ExistingPlayerTypes.email,  label: this.captions.emailAddress },
            { key: ExistingPlayerTypes.phoneNumber,  label: this.captions.phoneNumbertext }
          ];
    }

    public getPhoneTypeOption(): Options[] {
        let phoneTypeOptions: Options[] = [           
            { key: 1, label: this.captions.phoneTypeCell },
            { key: 2, label: this.captions.phoneTypeHome },
            { key: 3, label: this.captions.phoneTypeOffice },
            { key: 12, label: this.captions.phoneTypeBusiness },
            { key: 13, label: this.captions.phoneTypeWork },
        ];
        return phoneTypeOptions;
    }
    public getEmailTypeOption(): Options[] {
        let EmailTypeOption: Options[] = [{ key: 9, label: this.captions.emailTypePersonal }, { key: 10, label: this.captions.emailTypeOffice },{key: 14, label: this.captions.emailTypeHome},{key: 15, label: this.captions.emailTypeBusiness} ];
        return EmailTypeOption;
    }
    public getAllocationBlocks$(): Observable<Options[]> {
        let pBlockType$: Observable<API.TeeTimeAllocationBlock[]> = from(this._allocationBlockDataService.getAllocationBlocks(false));
        return pBlockType$.pipe(
            map((r: API.TeeTimeAllocationBlock[]) =>
                r.map(o => {
                    return {
                        key: o.id,
                        label: o.allocationBlockCode
                    }
                }
                )));
    }

    public shouldDisableSave(form: UntypedFormGroup): boolean {
        return !(form.dirty && form.valid);
    }

    public async saveDefaults(value: DefaultSetting, orgstate): Promise<boolean> {
        let mappedValues: DefaultsSettingConfig = this.mapToAPI(value);
        let _setting: settingsAPI.Settings<settingsAPI.DefaultsSettingConfig> = {
            id: this.unModifiedData.id,
            moduleName: this.unModifiedData.moduleName,
            screenName: this.unModifiedData.screenName,
            configValue: mappedValues,
            defaultValue: this.unModifiedData.defaultValue
        }
        this.saveApplicationFormatConfig(value);
        this.unModifiedData = _.cloneDeep(_setting);
        this.unModifiedFormData = _.cloneDeep(value);
        let result = await this._settingsDataService.updateSettings(_setting);
        if (result) this._defaultsInfoService.UpdateDefaultSetting(mappedValues);
        return result;
    }

    public saveApplicationFormatConfig(value: DefaultSetting) {
        const config: settingsAPI.ApplicationFormat = this.mapApplicationFomatToAPI(value);
        let _format: settingsAPI.PropertyConfig<settingsAPI.ApplicationFormat> = {
            id: this.unModifiedConfigData.id,
            configurationName: this.unModifiedConfigData.configurationName,
            productId: this.unModifiedConfigData.productId,
            configValue: config,
            defaultValue: this.unModifiedConfigData.defaultValue,
            propertyId: this.unModifiedConfigData.propertyId
        }
        sessionStorage.setItem(PropertyConfigurations.ApplicationFormat, JSON.stringify(_format.configValue));
        this._propertyConfigurationDataService.updateSettings(_format);
    }

    mapApplicationFomatToAPI(value: DefaultSetting): settingsAPI.ApplicationFormat {
        const sourceArr: NameFormatDropDown = this.getNameFormatOptions().find(o => o.id == value.defaultNameFormat);
        return <settingsAPI.ApplicationFormat>{
            id: value.defaultNameFormat,
            firstNameIndex: sourceArr.configuration.firstNameIndex,
            lastNameIndex: sourceArr.configuration.lastNameIndex,
            nameSeperator: sourceArr.configuration.nameSeperator
        }
    }



    public async fetchDefaultsSettings(): Promise<DefaultSetting> {
        const defaultSetting$ = this._settingsDataService.getSettings<settingsAPI.DefaultsSettingConfig>(SettingModule.SystemSetup, SettingScreen.Defaults);
        const propertyConfig$ = this._propertyConfigurationDataService.getApplicationFormatConfiguration();
        const apiData = await Promise.all([defaultSetting$, propertyConfig$]);
        let formData: DefaultSetting = this.mapToUI(apiData[0].configValue, apiData[1].configValue);
        this.unModifiedFormData = _.cloneDeep(formData);
        this.unModifiedData = _.cloneDeep(apiData[0]);
        this.unModifiedConfigData = _.cloneDeep(apiData[1]);
        return formData;
    }


    public async releaseTeeTimes(value: DefaultSetting): Promise<boolean> {
        return await this._settingsDataService.releaseTeeTime(value.autotimehold);
    }

    public async removeAutoRelease(): Promise<boolean> {
        return await this._settingsDataService.removeAutoRelease();
    }

    public getNameFormatOptions(): NameFormatDropDown[] {
        return this._utilities.getNameFormatOptions();
    }

    public async updateDefaultTeeBlocks(value: number): Promise<boolean> {
        return await this._allocationBlockDataService.updateDefaultTeeAllocationBlocks(value);
    }

    private mapToUI(configValue: settingsAPI.DefaultsSettingConfig, propertyConfig: settingsAPI.ApplicationFormat): DefaultSetting {
        return {
            defaultBlockingcode: configValue.blockingCode,
            defaultMemberstatus: configValue.memberStatus,
            defaultMemberPlayerType: configValue.memberPlayerType,
            defaultMemberratetype: configValue.memberRateType,
            defaultnonMemberplayertype: configValue.nonMemberPlayerType,
            defaultnonMemberrateType: configValue.nonMemberRateType,
            DefaultResort: configValue.resortPlayerType,
            DefaultResortratetype: configValue.resortRateType,
            memberGuestPlayerType: configValue.memberGuestPlayerType,
            memberGuestRateType: configValue.memberGuestRateType,
            memberGuestsToAdd: configValue.memberGuestsToAdd,
            memberGuestPlayerFirstName: configValue.memberGuestPlayerFirstName,
            memberGuestPlayerLastName: configValue.memberGuestPlayerLastName,
            enableauto: configValue.autoTeeTimeHoldConfig.isEnabled,
            autotimehold: configValue.autoTeeTimeHoldConfig.holdReleaseMinutes,
            checkinginterval: configValue.autoTeeTimeHoldConfig.holdCheckingIntervalInSeconds,
            showConfirmationDialog: configValue.showConfirmationDialog,
            showAvailableOnly: configValue.showAvailableOnly,
            defaultNameFormat: propertyConfig.id,
            defaultOutletType: configValue.defaultOutletType,
            defaultAllocationCodeSwitchTo: configValue.defaultAllocationCodeSwitchTo,
            defaultPlayerCategory: configValue.defaultPlayerCategory,
            autoSelectPlayers: configValue.autoSelectPlayers,
            requiredReasonTeeTimeCancellation: configValue.requiredReasonTeeTimeCancellation,
            enableExtentedSearchByDefault: configValue.enableExtentedSearchByDefault,
            enableNonZeroRateForMarkAsPaid: configValue.enableNonZeroRateForMarkAsPaid,
            defaultMovePlayerOption: configValue.defaultMovePlayerOption,
            defaultHotelReservationCategory: configValue.defaultHotelReservationCategory,
            defaultPhoneTypeOption: configValue.defaultPhoneTypeOption,
            defaultEmailTypeOption: configValue.defaultEmailTypeOption,
            defaultCountryCode: configValue.defaultCountryCode,
            markAsPaidPaymentMethod: configValue.markAsPaidPaymentMethod,
            zeroMemberChargePaymentMethod: configValue.zeroMemberChargePaymentMethod,
            markAsPaidPaymentMethodReceiptComment: configValue.markAsPaidPaymentMethodReceiptComment,
            zeroMemberChargePaymentMethodReceiptComment: configValue.zeroMemberChargePaymentMethodReceiptComment,
            disableHolesWhenMemberEdit: configValue.disableHolesWhenMemberEdit,
            bulkPlayersPerGroup: configValue.bulkPlayersPerGroup,   
            rainCheckExpiry : configValue.rainCheckExpiry,         
            enableHideCrossOverTeeSheet : configValue.enableHideCrossOverTeeSheet,
            enableHideCrossOverGraphicalView : configValue.enableHideCrossOverGraphicalView,
            defaultExistingPlayerCategory: configValue.defaultExistingPlayerCategory,
            defaultMultiCourseViewPlayerNameFormat: configValue.defaultMultiCourseViewPlayerNameFormat == undefined || configValue.markAsPaidPaymentMethod == null ? MultiCourseViewPlayerNameFormat.FullNameFirstLetter : configValue.defaultMultiCourseViewPlayerNameFormat 
        }
    }

    private mapToAPI(value: DefaultSetting): DefaultsSettingConfig {
        let checkVal = (value) => value ? value : 0
        return {
            blockingCode: value.defaultBlockingcode,
            memberStatus: value.defaultMemberstatus,
            memberPlayerType: value.defaultMemberPlayerType,
            memberRateType: value.defaultMemberratetype,
            nonMemberPlayerType: value.defaultnonMemberplayertype,
            nonMemberRateType: value.defaultnonMemberrateType,
            resortPlayerType: value.DefaultResort,
            resortRateType: value.DefaultResortratetype,
            memberGuestPlayerType: value.memberGuestPlayerType,
            memberGuestRateType: value.memberGuestRateType,
            memberGuestsToAdd: value.memberGuestsToAdd && value.memberGuestsToAdd != null ? value.memberGuestsToAdd : 0,
            memberGuestPlayerFirstName: value.memberGuestPlayerFirstName,
            memberGuestPlayerLastName: value.memberGuestPlayerLastName,
            autoTeeTimeHoldConfig: {
                isEnabled: value.enableauto,
                holdReleaseMinutes: value.enableauto ? checkVal(value.autotimehold) : 0,
                holdCheckingIntervalInSeconds: value.enableauto ? checkVal(value.checkinginterval) : 0
            },
            showConfirmationDialog: value.showConfirmationDialog,
            showAvailableOnly: value.showAvailableOnly,
            autoSelectPlayers: value.autoSelectPlayers,
            requiredReasonTeeTimeCancellation: value.requiredReasonTeeTimeCancellation,
            enableExtentedSearchByDefault: value.enableExtentedSearchByDefault,
            enableNonZeroRateForMarkAsPaid: value.enableNonZeroRateForMarkAsPaid,
            defaultOutletType: value.defaultOutletType,
            defaultAllocationCodeSwitchTo: value.defaultAllocationCodeSwitchTo,
            defaultPlayerCategory: value.defaultPlayerCategory,
            defaultMovePlayerOption: value.defaultMovePlayerOption,
            defaultHotelReservationCategory: value.defaultHotelReservationCategory,
            defaultPhoneTypeOption: value.defaultPhoneTypeOption,
            defaultEmailTypeOption: value.defaultEmailTypeOption,
            defaultCountryCode: value.defaultCountryCode,
            markAsPaidPaymentMethod: value.markAsPaidPaymentMethod,
            zeroMemberChargePaymentMethod: value.zeroMemberChargePaymentMethod,
            markAsPaidPaymentMethodReceiptComment: value.markAsPaidPaymentMethodReceiptComment,
            zeroMemberChargePaymentMethodReceiptComment: value.zeroMemberChargePaymentMethodReceiptComment ? value.zeroMemberChargePaymentMethodReceiptComment : '',
            disableHolesWhenMemberEdit: value.disableHolesWhenMemberEdit,
            bulkPlayersPerGroup: value.bulkPlayersPerGroup,
            rainCheckExpiry: value.rainCheckExpiry ? value.rainCheckExpiry : 0,
            enableHideCrossOverTeeSheet : value.enableHideCrossOverTeeSheet,
            enableHideCrossOverGraphicalView : value.enableHideCrossOverGraphicalView,
            defaultExistingPlayerCategory: value.defaultExistingPlayerCategory,
            defaultMultiCourseViewPlayerNameFormat: value.defaultMultiCourseViewPlayerNameFormat
        } as DefaultsSettingConfig
    }

    public getDefaultOutletTypes(): DefaultOutlet[] {
        let userViewValue = this._localization.captions.settings.drpTextUser;
        let courseViewValue = this._localization.captions.settings.drpTextCourse;

        return [
            {
                id: DefaultOutletType.user,
                value: 'User',
                viewValue: userViewValue
            },
            {
                id: DefaultOutletType.course,
                value: 'Course',
                viewValue: courseViewValue
            },
        ];
    }

    public getDefaultSwitchToOptions(): AllocationCodeSwitch[] {
        let unbooked = this._localization.captions.settings.UnBooked;
        let booked = this._localization.captions.settings.Booked;
        let both = this._localization.captions.settings.Both;

        return [{
            id: AllocationCodeSwitchToOptions.UnBooked,
            value: 'UnBooked',
            viewValue: unbooked
        },
        {
            id: AllocationCodeSwitchToOptions.Booked,
            value: 'Booked',
            viewValue: booked
        },
        {
            id: AllocationCodeSwitchToOptions.Both,
            value: 'Booked',
            viewValue: both
        }];
    }
    public getDefaultMovePlayerOption(): MovePlayerOption[] {
        return [
            {
                id: Player.Selectedplayer,
                value: 'Selectedplayer',
                viewValue: this._localization.captions.settings.Selectedplayer
            },
            {
                id: Player.GroupPlayers,
                value: 'GroupPlayers',
                viewValue: this._localization.captions.settings.GroupPlayers
            },
            {
                id: Player.AllPlayers,
                value: 'AllPlayers',
                viewValue: this._localization.captions.settings.AllPlayers
            }];
    }

    public getUserDefinedPaymentMethods(): Observable<PaymentMethod[]> {
        let userDefinedPaymentMethods =  this._paymentService.getUserDefinedPaymentMethod();
        return userDefinedPaymentMethods;
    }
    public getDefaultTeeSheetViewOptions(): Options[] {
        let defaultTeeSheetViewOptions: Options[] = [
            { key: 1, label: this._localization.captions.settings.lbl_detailView },
            { key: 2, label: this._localization.captions.settings.lbl_advanceDetailView }
        ];
        return defaultTeeSheetViewOptions;
    }
    public getDefaultMultiCourseViewPlayerNameFormat(): Options[]{
        const MultiCourseViewPlayerNameFormatKey = Object.keys(MultiCourseViewPlayerNameFormat);
        const MultiCourseViewPlayerNameFormatValue = Object.values(MultiCourseViewPlayerNameFormat);
        let multiCourseViewPlayerNameFormat = MultiCourseViewPlayerNameFormatKey.filter(k => !isNaN(Number(k)) && (Number(k) == MultiCourseViewPlayerNameFormat.FullNameFirstLetter || Number(k) == MultiCourseViewPlayerNameFormat.FullName)).map((key, index) =>({
            key: Number(key),
            value: MultiCourseViewPlayerNameFormatValue[index],
            label: this._localization.captions.settings.MultiCourseViewPlayerNameFormat[MultiCourseViewPlayerNameFormatValue[index]],          
        }) as Options);
        return multiCourseViewPlayerNameFormat;
    }
}
