import { PlayerPaymentstatus } from 'src/app/tee-time/search/search-model';
import { ContactDetails } from 'src/app/shared/shared-models';
import { BookingSource } from 'src/app/shared/global.constant';

export interface TabCaption {
  playerDetails: string;
  generalInformation: string;
  feeInformation: string;
  otherInformation: string;
  packageEntitlement: string;
  itinerary: string;
  editTitle: string;
  teeTimeDetail: string;
  msgTeeTimeAddedToCart: string;
  bookTeeTime: string;
}

export interface StayInformation {
  Date: Date;
  Package: string;
  Tariff: string;
  ClientId: number;
}

export interface Components {
  ServiceName: string;
  Date: Date;
  Floating: boolean;
  RemainingValue: number;
  TotalValue: number;
  ClientId: number;
}

export interface Booking {
  Location: string;
  Service: string;
  Date: Date;
  AllowedOnPackage: boolean;
  Appointment: string;
}

export interface BookingInfo {
  Name: string;
  ClientId: number;
  Booking: Booking[];
}

export interface ClientInfo {
  ClientName: string;
  ClientId: number;
  Age: number;
  Gender: string;
  ArrivalDate: Date;
  DepartureDate: Date;
  Room: number;
}

export interface TeeTimeModel {
  id: number | string;
  courseId: number | string;
  scheduleDateTime: string;
  holeNumber: number | string;
  scheduleStatus?: number | string;
  playerId: number;
  tournamentId?: number;
  comment: string;
  scheduledTeeTimePlayers: ScheduledTeeTimeTimePlayer[];
  createdDateTime?: string;
  ticketNumber?: string;
  originalHoleNumber: string;
  viewedByStarter?: boolean;
  playerIds?: number[];
  isModified?:boolean;
}

export enum TeeSlotNavigation{
  None = 0,
  IsNext = 1,
  IsPrevious = 2
}

export interface ScheduledTeeTimeTimePlayer {
  id: number;
  isPlayerDetailModified: boolean;
  scheduledTeeTimeId: number;
  playerId: number;
  playerDetail: PlayerDetail;
  gameFormat?: number;
  groupId: number,
  playersPerGroup: number,
  bookingId: string,
  caddyId: number;
  holes: number;
  holesPlayed: number;
  cartId: any;
  isWalk: boolean;
  isTrail: boolean;
  playerComment: string;
  packageCode: string;
  playerStatus?: PlayerPaymentstatus;
  playerSlotPosition: number;
  playerPosition?: number;
  scheduledTeeTimePlayerFee: ScheduledTeeTimePlayerFee;
  confirmationNumber?: string;
  caddyType?: number | string;
  caddySearch?: number | string;
  caddyNo?: number;
  gender?: number | string;
  lastUpdatedBy?: number;
  lastUpdatedByName?: string;
  lastUpdatedDateTime?: string;
  createdBy?: number;
  createdByName?: string;
  createdDateTime?: string;
  deposit?: string
  bookingSource?: BookingSource;
  loyaltyDetail?: LoyaltyDetail[];
  ticketNumber?: string;
  isPMSData?: boolean;
  caddyTypeId: number;
  isBlocked: boolean;
  availableRounds: number;
  overrideDetails: OverrideDetails[];
  isPlayerDaysOutFailed?: boolean;
  isRateDaysOutFailed?: boolean;
  internalGroupId?: string;
  multiPackTransactionDetailId?: number;
  cartType?: any;
  isMarkAsPaid?: boolean;
}

export interface OverrideDetails {
  id: number;
  scheduledTeeTimePlayerId: number;
  overrideType: number;
  userId: number;
}

export enum OverrideType {
    playerType = 1,
    rateType = 2,
    rateTypeOverridePerDynamicAvailability = 3
}

export interface PlayerDetail {
  id: number;
  firstName: string;
  lastName: string;
  pronounced: string;
  customField1: string;
  customField2: string;
  customField3: string;
  customField4: string;
  customField5: string;
  playerCategoryId: number;
  playerAddress: Address;
  playerAdditionalDetails?: PlayerAdditionalDetails[];
  gender?: number;
  contactInformation: ContactDetails[];
  paymentReferenceId?: number;
  playerLinkId?: string;
  imageReferenceId?: string;
  playerStayDetail: PlayerStayDetail;
  bagNumber?: string;
  loyaltyDetail: LoyaltyDetail[];
  activityId: string;
  extProfileId?: string;
  platformUuId?: string;
  vipType: string;
}

export interface PlayerNameDetail {
  id: number;
  playerName: string;
  playerLinkId?: string;
}

export interface LoyaltyDetail {
  patronId: string;
  rank: string;
}

export interface PlayerStayDetail {
  id: number;
  playerId: number;
  bookStartDate: string;
  bookEndDate: string;
  reservationNumber: string;
  groupBookingId: string;
  linkId: string;
  packageName: string;
  groupBookingName?: string;
  linkedPMSActivityId: string;
  activityStatus: number;
  roomNumber:string
}

export interface ScheduledTeeTimePlayerFee {
  id: number;
  scheduledTeeTimePlayerId: number;
  allocationBlockId: number;
  playerTypeId: number;
  rateTypeId: number;
  greenFee: number;
  isGreenFeeNegotiable: boolean;
  cartFee: number;
  isCartFeeNegotiable: boolean;
  rainCheck?: string | number;
  deposit?: number;
  advertisementType?: string | number;
  viewedByStarter: boolean;
  isDynamicPrice? : boolean;
  dynamicPricingId? : number;
  scheduledTeeTimePlayerFeeDynamicPricing? : ScheduledTeeTimePlayerFeeDynamicPricing;
}

export interface ScheduledTeeTimePlayerFeeDynamicPricing {
  id : number;
  scheduledTeeTimePlayerFeeId : number;
  rateTypeId : number;
  occupancy : number;
  daysInAdvance : number;
  isCartFeeIncluded : boolean;
  originalGreenFee : number;
  originalCartFee : number;
  greenFee : number;
  cartFee : number;
  utilizationGreenFee : number;
  daysInAdvanceGreenFee : number;
  utilizationCartFee : number;
  daysInAdvanceCartFee : number;
}

export interface Address {
  id: number;
  playerId: number;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}
export interface PlayerAdditionalDetails {
  id: number;
  playerId: number;
  key: number;
  value: string;
}
export interface ContactInformation {

  id: number;
  type: ContactType;
  name?: string;
  description?: string;
  value: string;
}

export interface ContactType {
  id: number;
  contactTypeId: number;
  name: string;
  description: string;
}

export enum TeeTimeTabs {
  playerInformation,
  generalInformation,
  feeInformation,
  otherInformation,
  itinerary,
  packageEntitlement
}

export interface PlayerCount {
  id: number,
  courseId: number,
  scheduleDateTime: Date | string,
  numberOfPlayers: number,
  holeNumber: string,
  originalHoleNumber: string
}


export interface TeeTimeSearchResult {
  teeTimeScheduleId: number;
  teeTimeSchedulePlayerId: number;
  playerName: string;
  teeTimeDate: Date | string;
  courseId: number;
  course: string;
  confirmationNumber: string;
  firstName: string;
  lastName: string;
  vipType: string;
}

export interface TeeTimeTempHold {
  id: number;
  courseId: number;
  scheduleDateTime: string;
  holeNumber: number | string;
  releaseDateTime: string;
  createdOn: string;
  createdBy: number;
  originalHoleNumber: string;
  
}

export interface TeeTimeTempHoldResult {
  id: number;
  courseId: number;
  scheduleDateTime: string;
  holeNumber: number | string;
  releaseDateTime: string;
  createdOn: string;
  createdBy: number;
  originalHoleNumber: string;
  playerId: number;
  userName?: string;
}
export class TeeTimeCheckInOutModel {
  isCheckIn: boolean = false;
  isCheckInCheckOut: boolean = false;
  isCheckOut: boolean = false;
  isUndoCheckIn: boolean = false;
  isUndoCheckOut: boolean = false;
  lenCheckIn: number = 0;
  lenCheckOut: number = 0;
}
