import { Injectable } from '@angular/core';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Injectable()
export class CreatePlayerService {
  isEdit: boolean;
  
  constructor(private _Localization: GolfLocalization) { }

  getCaptions() {
    return this._Localization.captions.guest.players
  }


  getSettingCaption() {
    return this._Localization.captions.settings;     
  }
  
  createPlayer(details, action) {
    console.log(details, action);
  }
}
