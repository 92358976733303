import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertPopupComponent } from './components/alert-popup/alert-popup.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  dialogSubscription$: any;
  loaderEnable: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(private dialog: MatDialog) { }

  ShowError(alerttype: number, alertButtonType: any, alertMessage: any, callback?: (result: string, extraParams?: any[] | any) => void) {
   
    const dialogRef = this.dialog.open(AlertPopupComponent, {
        height: 'auto',
        width: '300px',
        data: { type: alerttype, message: alertMessage, buttontype: alertButtonType },
        panelClass: 'small-popup',
        disableClose: true,
    });
    this.dialogSubscription$ = dialogRef.afterClosed().subscribe(res => {
      this.dialogSubscription$.unsubscribe();
        if (callback)
            callback(res);
    });
}

}
