import { Injectable } from '@angular/core';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { UserAccessBusiness } from '../data-services/authentication/useraccess.business';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { GolfUtilities } from '../utilities/golf-utilities';
import { PlayerTypeService } from '../data-services/golfschedule/playertype.data.service';
import { RateTypeDataService } from '../data-services/golfschedule/ratetype.data.service';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { TeeTimesActionService } from '../data-services/golfschedule/teeTimesAction.data.service';
import { TeeTimeService } from 'src/app/tee-time-actions/teetime/tee-time.service';
import { CodeLinkingDataService } from '../data-services/golfschedule/codelinking.data.service';
import { RateSetupDataService } from '../data-services/golfschedule/rate-setup.data.service';
import { MatDialog } from '@angular/material/dialog';
import { PlayerProfileDataService } from '../data-services/golfmanagement/PlayerProfile.data.services';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { TeeTimesActionBusiness } from 'src/app/tee-time/shared/tee-action.business';
import { UserAccessBreakPoints } from '../constants/useraccess.constants';
import { PlayerTypeRateTypeChangeConfig } from 'src/app/tee-time/tee-time-model';
import { ButtonAction, CacheConstants, FromAction, GolfSessionKey, GuaranteeType, OVERRIDEPLAYERTYPEANDRATETYPEDAYSOUTERROR, playerTypes, SettingModule, SettingScreen, TempHoldStatus } from '../global.constant';
import { CustomFeeRequest, DefaultsSettingConfig, DynamicPricingPlayerFee, PlayerTypes, RateSetupData, RateType } from 'src/app/tee-time-actions/teetime/player-details/player-info.model';
import { DropdownOptions, GuaranteeTypes } from '../shared-models';
import { AllocationBlockWithPlayerType, PlayerType } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import { AllocationBlockDataService } from '../data-services/golfschedule/allocationblock.data.service';
import { PlayerDaysOutResut, RateTypeDaysOutResult, TeeSheetPlayerDetails } from '../models/teesheet.form.models';
import { CustomFeeData, DynamicPricingFees, MultiPackDetails, OverrideDetails, OverrideType, PlayerTypeInfo, RateSetupFees, RateTypeInfo, ScheduledTeeTimeUnPaidPlayer, UpdatePlayerFee } from '../models/unpaid-players.model';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { takeUntil } from 'rxjs/operators';
import { AlertType, ButtonType, AlertAction, TeeTimeAllocationBlock, OVERRIDE_ALERT_WIDTH, OverlappingSlotsForGuest } from 'src/app/shared/shared-models';
import { ReplaySubject } from 'rxjs';
import * as _ from 'lodash';
import * as RateSetupAPI from 'src/app/settings/rate-setup/rate-setup.model';
import { PlayerDetailBusiness } from 'src/app/tee-time-actions/teetime/player-details/player-details.business';
import { DynamicPricingService } from '../data-services/golfschedule/dynamicpricing.data.service';
import { PaymentBusinessService } from '../data-services/payment/payment-business.service';
import { TeeSheetBusiness } from 'src/app/tee-time/tee-sheet/tee-sheet-booking/tee-sheet-booking.business';
import { TeeTimeBusiness } from 'src/app/tee-time-actions/teetime/tee-time.business';
import { allocationBlockPlayerTypePermission } from 'src/app/settings/user-setup/booking-permission/booking-permission.model';
import { AllocationBlockDataService as AllocationBlockManagementDataService } from 'src/app/shared/data-services/golfmanagement/allocationblock.data.service';
import { HttpMethod, HttpServiceCall } from 'src/app/common/shared/shared/service/http-call.service';
import { Host } from 'src/app/common/shared/shared/globalsContant';
import { GolfScheduleCommunication } from '../communication/services/golfschedule.service';
import { GolfRoutes } from 'src/app/core/extensions/golf-route';
import { ScheduledTeeTimePlayerFeeDynamicPricing, TeeTimeTempHold } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { CustomFeeService } from '../data-services/golfschedule/customfee.data.service';
import { Deposit, PlayerPaymentstatus, ScheduledTeeTimePlayerCustomFee } from 'src/app/retail/shared/shared.modal';
import { TeeTimeCustomFeeUtilities } from 'src/app/tee-time-actions/tee-time-custom-fee.utilities';
import { LinkMultipackDataService } from 'src/app/tee-time-actions/link-multipack/link-multipack-service';
import { PlayerTypeRateType } from 'src/app/tee-time/tournaments/tournaments-modal/tournament-details/tournament-details.model';
import { UnPaidPlayersBusiness } from '../data-services/golfschedule/unpaid-players.business';
import { SelectOutletPopupComponent } from '../components/select-outlet-popup/select-outlet-popup.component';
import { RetailBussinessService } from '../retail.bussiness';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { TeeTimeActions } from 'src/app/tee-time-actions/tee-time-action.base';
import { CommonVariablesService, TransactionStatus } from 'src/app/retail/shared/service/common-variables.service';
import { DefaultOutletType } from 'src/app/common/shared/shared/enums/enums';
import { CourseDataService } from '../data-services/golfschedule/course.data.service';
import * as GlobalConst from '../../retail/shared/globalsContant';
import { DefaultUserConfiguration } from 'src/app/settings/utilities/manager-utilities/default-user-config/default-user-config-model';
import { DefaultUserConfigDataService } from 'src/app/settings/utilities/manager-utilities/default-user-config/default-user-config.data.service';
import { Router } from '@angular/router';
import { PlayerCategory } from 'src/app/common/enums/shared-enums';
import { RefundMethod } from 'src/app/shared/shared-models';
import { UnPaidPlayersService } from '../data-services/golfschedule/unpaid-players.service';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { MemberBusinessService } from 'src/app/retail/shared/business/Member-business.service';
import { SettingsDataService } from '../data-services/golfschedule/settings.data.service';
import { API } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { PlayerDeposit } from 'src/app/tee-time-actions/add-deposite-modal/add-deposite-model';
import { DepositEventModel } from 'src/app/retail/shared/events/event.model';
import { RedirectToModules } from 'src/app/retail/shared/utilities/retail-utilities';
import { RetailItemType } from 'src/app/common/shared/retail.modals';
import { DomainRetailItem } from 'src/app/settings/settings-shared/assign-retail-item/assign-retail-item.model';

@Injectable({ providedIn: "root" })
export class PlayerTypeRateTypeChangeService {
  breakPoints: any;
  isAllowEditTeeTime: boolean;
  isDynamicPricingEnabled: boolean;
  isIncludeCartFeeForRateCalculation: boolean;
  isIncludePlayersAcrossCoursesForCheckIn: boolean;
  isPlayerTypeRateTypeChangeEnable: boolean = false;
  isRequirePlayerType: boolean;
  isRequireRateType: boolean;
  isUserDaysOut: boolean;
  isMultipackAccess:boolean;
  overridePlayerTypeDaysoutUserAccess: boolean;
  overrideRateTypeDaysoutUserAccess: boolean;
  isNegotiateAccess:boolean;
  overrideRateTypeForDynamicAvailability:boolean;
  isDisableDropdownEdit: boolean = true;
  retailItems: any = [];
  isBulkEdit: boolean = false;
  isQuickIdPopupEnableForSave: boolean = false;
  isAllocationCodePermissionByRoleCheck:boolean = false;
  isCustomFeeEnabled:boolean = false;
  isPlayerTypeRateTypeDaysOut:boolean = false;
  isPlayerTypeDaysOut:boolean = false;
  isRateTypeDaysOut:boolean = false;
  customFeeData:CustomFeeData[] = [];
  teeTimeMultiPackDetails: MultiPackDetails[] = [];
  currentUserId;
  quickloginUserId: number = 0;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  RateSetups: RateSetupAPI.RateSetupData[] = [];
  DynamicPricingFees: DynamicPricingFees[] = [];
  allocationBlockPlayerTypes: AllocationBlockWithPlayerType[] = [];
  multipackDetails:any[] = [];
  rateTypes: RateType[] = [];
  playerTypes: PlayerTypes[] = [];
  playerTypeRateTypes: PlayerTypeRateType[] = [];
  allocationBlockWithPlayerTypes: AllocationBlockWithPlayerType[]= [];
  allocationCodePermissions: allocationBlockPlayerTypePermission[] = [];
  isToContinueWithOldCartItems: boolean = false;

  playerTypeOverride: number;
  rateTypeOverride: number;
  playerDaysoutErrorMessage: any;
  rateDaysoutErrorMessage: any;
  defaultConfig: any;
  constructor(
    private _localization: GolfLocalization,
    private _utils: GolfUtilities,
    private _playerTypeService: PlayerTypeService,
    private _rateTypeService: RateTypeDataService,
    public quickLoginUtils: QuickLoginUtilities,
    private _codeLinkingDataService: CodeLinkingDataService,
    public _dialog: MatDialog,
    private router: Router,
    private rPropertyInformation: RetailPropertyInformation,
    private _teeActionBusiness: TeeTimesActionBusiness,
    private _allocationBlockService: AllocationBlockDataService,
    private _rateSetupDataService: RateSetupDataService,
    private _dynamicPricingService: DynamicPricingService,
    private _paymentBusinessService: PaymentBusinessService,
    private _allocationCodeManagementService: AllocationBlockManagementDataService,
    private _customFeeService:CustomFeeService,
    private _teeTimeCustomFeeUtilities: TeeTimeCustomFeeUtilities,
    public http: GolfScheduleCommunication,
    private _linkedMultipackDataService: LinkMultipackDataService,
    private _unPaidPlayersBusiness: UnPaidPlayersBusiness,
    private _retailBusinessService: RetailBussinessService,
    private _retailService: RetailSharedVariableService,
    private _commonVariable: CommonVariablesService,
    private _courseDataService: CourseDataService,
    private _defaultUserConfig: DefaultUserConfigDataService,
    private _teeTimeActionService: TeeTimesActionService,
    private _propertyInfo:GolfPropertyInformation,
    private _unPaidPlayersService:UnPaidPlayersService,
    private func: RetailFunctionalityBusiness, 
    private _memberService: MemberBusinessService,
    private _settingsDataService: SettingsDataService,
    private _userAccessBussiness:UserAccessBusiness,
    private _teeTimeService: TeeTimeService,
  ) {
  }

  /*Initial Validation and Getting Configuration*/
  public async getPlayerTypeRateTypeBreakPoints(isResetBP: boolean = false, isBulkEdit: boolean = false) {
    this.isBulkEdit = isBulkEdit;
    if(isResetBP) this.breakPoints = [];
    if(!this.breakPoints || this.breakPoints.length == 0){
      let breakPointNumbers = [
        UserAccessBreakPoints.UPADTEPLAYERRATETYPEDURINGPAY,
        UserAccessBreakPoints.BOOKTEETIME,
        UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT,
        UserAccessBreakPoints.OVERRIDERATETYPEDAYSOUT,
        UserAccessBreakPoints.OVERRIDE_UNAVAILABLE_RATETYPE_PER_DYNAMICAVAILABILITY,
        UserAccessBreakPoints.LINKUNLINKMULTIPACK,
        UserAccessBreakPoints.NEGOTIATEDRATE,
        UserAccessBreakPoints.SHOP
      ];
      if (this.isBulkEdit) breakPointNumbers.splice(1, 0, UserAccessBreakPoints.BULKTEETIMESEDIT)
      this.breakPoints = await this._teeActionBusiness.validateBreakPointAccesses(breakPointNumbers);
    }
  }

  public async validatePayBreakPointAccess(): Promise<any> {
    return await this._userAccessBussiness.getUserAccess(UserAccessBreakPoints.SHOP, true);
  }

  public CheckPayTeeTimeDayOfPlay(isPayTeeTimeDayOfPlayEnabled, selectedDate: Date): boolean {
    if (isPayTeeTimeDayOfPlayEnabled && !this._utils.IsPropertyDateGreaterThenDate(selectedDate)) {
      this._utils.showAlert(this._localization.captions.teetime.dayOnPlayInfo, AlertType.Info);
      return false;
    }
    return true;
  }

  public async setPlayerTypeRateTypeChange(selectedDate: Date)
  {
    //await this.getPlayerTypeRateTypeBreakPoints();
    if(this.isPlayerTypeRateTypeChangeAllowed())
    {
      this.setPlayerTypeRateTypeChangeConfig(selectedDate);
      if(this.rateTypes.length==0){
        this.getRateTypes().then(res=>{
          this.rateTypes = res
        });
      }
      if(this.playerTypes.length==0){
        this.getPlayerTypes().then(res=>{
          this.playerTypes = res
        });
      }
      if(this.playerTypeRateTypes.length==0){
        this.getAllPlayerTypeWithRateType().then(res=>{
          this.playerTypeRateTypes = res
        });
      }
      if (this.allocationCodePermissions.length == 0 && this.isAllocationCodePermissionByRoleCheck) {
        this.GetAllocationBlockPermissionByRole().then(res=>{
            this.allocationCodePermissions = res
        });
      }
      if(this.allocationBlockWithPlayerTypes.length==0){
        this.getAllocationsBlockWithPlayerTypes().then(res=>{
          this.allocationBlockWithPlayerTypes = res
        });
      }
    }
  }

  public setPlayerTypeRateTypeChangeConfig(selectedDate: Date) {
    let quickIdConfig = this.rPropertyInformation.getQuickIdConfig;
    let golfSetting = JSON.parse(sessionStorage.getItem(CacheConstants.GolfSetting));
    let teeTimeSetting = JSON.parse(sessionStorage.getItem(CacheConstants.TeeTimeSetting));
    this.isUserDaysOut = this._teeActionBusiness.validateUserDaysOut(selectedDate);
    this.isQuickIdPopupEnableForSave = quickIdConfig && quickIdConfig.teeTimeEdit ? true : false;
    this.isAllowEditTeeTime = this.getIsAllowByBreakPoint(this.isBulkEdit ? UserAccessBreakPoints.BULKTEETIMESEDIT : UserAccessBreakPoints.BOOKTEETIME);
    this.overridePlayerTypeDaysoutUserAccess = this.getIsAllowByBreakPoint(UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT);
    this.overrideRateTypeDaysoutUserAccess = this.getIsAllowByBreakPoint(UserAccessBreakPoints.OVERRIDERATETYPEDAYSOUT);
    this.overrideRateTypeForDynamicAvailability = this.getIsAllowByBreakPoint(UserAccessBreakPoints.OVERRIDE_UNAVAILABLE_RATETYPE_PER_DYNAMICAVAILABILITY);
    this.isMultipackAccess = this.getIsAllowByBreakPoint(UserAccessBreakPoints.LINKUNLINKMULTIPACK);
    this.isNegotiateAccess = this.getIsAllowByBreakPoint(UserAccessBreakPoints.NEGOTIATEDRATE),
    this.isDynamicPricingEnabled = golfSetting && golfSetting.enableDynamicPricing;
    this.isAllocationCodePermissionByRoleCheck = golfSetting && golfSetting.enableAllocationCodePermissionByRole;
    this.isIncludeCartFeeForRateCalculation = golfSetting && golfSetting.includeCartFeeForRateCalculation;
    this.isIncludePlayersAcrossCoursesForCheckIn = teeTimeSetting && teeTimeSetting.includePlayersAcrossCoursesForCheckIn;
    this.isRequirePlayerType = teeTimeSetting && teeTimeSetting.requirePlayerType;
    this.isRequireRateType = teeTimeSetting && teeTimeSetting.requireRateType;
    this.isDisableDropdownEdit = !this.isUserDaysOut || !this.isAllowEditTeeTime;
    this.isCustomFeeEnabled = sessionStorage.getItem(GolfSessionKey.IsCustomFeeEnabled) && teeTimeSetting && teeTimeSetting.updateCustomFeeForExistingBookings;
    this.currentUserId = this._localization.GetUserInfo('userId');
  }

  public isPlayerTypeRateTypeChangeAllowed() {
    this.isPlayerTypeRateTypeChangeEnable = this.getIsAllowByBreakPoint(UserAccessBreakPoints.UPADTEPLAYERRATETYPEDURINGPAY);
    return this.isPlayerTypeRateTypeChangeEnable
  }

  public getIsAllowByBreakPoint(breakPoint: UserAccessBreakPoints) {
    return this.breakPoints && this.breakPoints.filter(b => b.breakPointNumber == breakPoint).length > 0 && this.breakPoints.filter(b => b.breakPointNumber == breakPoint)[0].isAllow;
  }

  public async getAllocationsBlockWithPlayerTypes() {
    return await this._allocationBlockService.getAllocationsBlockWithPlayerTypes();
  }

  public async GetAllocationBlockPermissionByRole():Promise<allocationBlockPlayerTypePermission[]> {
    return await this._allocationCodeManagementService.getAllocationBlockPermissionByRole();
}
  public async getAllPlayerTypeWithRateType() {
    return await this._codeLinkingDataService.getPlayerTypeRateTypes();
  }

  public async getPlayerTypes(): Promise<PlayerTypes[]> {
    let playerTypeApiData: PlayerType[] = await this._playerTypeService.getAllPlayerTypes();
    let playerTypeApiDataClone = playerTypeApiData.map(x => this.mapPlayerTypeData(x));
    return playerTypeApiDataClone.sort((a, b) => (a.type.toLocaleLowerCase() > b.type.toLocaleLowerCase()) ? 1 : -1);
  }
  private mapPlayerTypeData(playerTypeApiData: PlayerType): PlayerTypes {
    return {
      id: playerTypeApiData.id,
      type: playerTypeApiData.type,
      daysOutStart: playerTypeApiData.daysOutStart,
      daysOutEnd: playerTypeApiData.daysOutEnd,
      guaranteeType: playerTypeApiData.guaranteeType
    } as PlayerTypes;
  }

  public async getRateTypes(): Promise<RateType[]> {
    let rateTypeApiDataSortByName = await this._rateTypeService.getAllRateTypes(false);
    rateTypeApiDataSortByName = rateTypeApiDataSortByName.sort((a, b) => (a.type.toLocaleLowerCase() > b.type.toLocaleLowerCase()) ? 1 : -1);
    return rateTypeApiDataSortByName;
  }

  public getPlayerTypeOptions(playerTypes: PlayerTypes[], selectedPlayerTypeId = 0): DropdownOptions[] {
    return playerTypes.map((x) => {
      return {
        id: x.id,
        value: x,
        viewValue: x.type,
        checked: (selectedPlayerTypeId > 0 && selectedPlayerTypeId == x.id) ? true : false
      } as DropdownOptions
    });
  }

  public getRateTypeOptions(rateTypes: RateType[], selectedRateTypeId = 0): DropdownOptions[] {
    return rateTypes.map((x) => {
      return {
        id: x.id,
        value: x,
        viewValue: x.type,
        checked: (selectedRateTypeId > 0 && selectedRateTypeId == x.id) ? true : false,
        disabled:false
      } as DropdownOptions
    });
  }
  /*Initial Validation and Getting Configuration*/

  /*Player Type And Rate Type Change Validations*/
  private resetDaysOutFlags(){
    this.isPlayerTypeDaysOut = false;
    this.isRateTypeDaysOut = false;
    this.isPlayerTypeRateTypeDaysOut = false;
  }

  public async validatePlayerTypeRateTypeDaysOut(selectedDate, selectedRow: ScheduledTeeTimeUnPaidPlayer, playerType: PlayerTypes, rateType: RateType)
  {
    this.resetDaysOutFlags();
    let playerTypeResult = this._teeActionBusiness.validatePlayerDaysOut(this._localization.getDate(selectedDate), playerType.daysOutStart, playerType.daysOutEnd);
    let rateTypeResult = this._teeActionBusiness.validateRateTypeDaysOut(this._localization.getDate(selectedDate), rateType.daysOutStart, rateType.daysOutEnd);
    if (playerTypeResult && (playerTypeResult == PlayerDaysOutResut.StartDateFail || playerTypeResult == PlayerDaysOutResut.EndDateFail) && rateTypeResult && (rateTypeResult == RateTypeDaysOutResult.StartDateFail || rateTypeResult == RateTypeDaysOutResult.EndDateFail) && !this.overridePlayerTypeDaysoutUserAccess && !this.overrideRateTypeDaysoutUserAccess) {
      let errorMessage = this._localization.captions.settings.playerTypeRateTypeDaysOutError;
      selectedRow.overrideDetail = selectedRow.overrideDetail.filter(x=> x.overrideType != OverrideType.playerType);
      selectedRow.overrideDetail = selectedRow.overrideDetail.filter(x=> x.overrideType != OverrideType.rateType);
      if (this.overridePlayerTypeDaysoutUserAccess) {
        selectedRow.overrideDetail.push(this.getOverrideDetail(OverrideType.playerType, selectedRow.scheduledTeeTimePlayerId, this.currentUserId));
        selectedRow.overrideDetail.push(this.getOverrideDetail(OverrideType.rateType, selectedRow.scheduledTeeTimePlayerId, this.currentUserId));
        return true;
      }
      else{
        let override = await this.showPlayerTypeRateTypeQuickIdPopup(errorMessage);
        if (override) {
          selectedRow.overrideDetail.push(this.getOverrideDetail(OverrideType.playerType, selectedRow.scheduledTeeTimePlayerId, this.quickloginUserId));
          selectedRow.overrideDetail.push(this.getOverrideDetail(OverrideType.rateType, selectedRow.scheduledTeeTimePlayerId, this.quickloginUserId));
          this.quickloginUserId = 0;
          this.isPlayerTypeRateTypeDaysOut = true;
          return true;
        }
        else {
          this.isPlayerTypeRateTypeDaysOut = false;
          return false;
        }
      }
    }
    else if(playerTypeResult && (playerTypeResult == PlayerDaysOutResut.StartDateFail || playerTypeResult == PlayerDaysOutResut.EndDateFail)){
      return await this.validatePlayerTypeDaysOut(selectedDate, selectedRow, playerType);
    }
    else if(rateTypeResult && (rateTypeResult == RateTypeDaysOutResult.StartDateFail || rateTypeResult == RateTypeDaysOutResult.EndDateFail)){
      return await this.validatePlayerTypeDaysOut(selectedDate, selectedRow, rateType);
    }
    return true
  }

  public async validatePlayerTypeDaysOut(selectedDate, selectedRow: ScheduledTeeTimeUnPaidPlayer, playerType: PlayerTypes): Promise<boolean> {
    this.resetDaysOutFlags();
    let result = this._teeActionBusiness.validatePlayerDaysOut(this._localization.getDate(selectedDate), playerType.daysOutStart, playerType.daysOutEnd);
    if (result && (result == PlayerDaysOutResut.StartDateFail || result == PlayerDaysOutResut.EndDateFail)) {
      let errorMessage = this._teeActionBusiness.GetplayerDaysoutErrorMessage(playerType, result);
      selectedRow.overrideDetail = selectedRow.overrideDetail.filter(x=> x.overrideType != OverrideType.playerType);
      if (this.overridePlayerTypeDaysoutUserAccess) {
        selectedRow.overrideDetail.push(this.getOverrideDetail(OverrideType.playerType, selectedRow.scheduledTeeTimePlayerId, this.currentUserId));
      }
      else {
        let override = await this.showQuickIdPopup(errorMessage, OverrideType.playerType);
        if (override) {
          selectedRow.overrideDetail.push(this.getOverrideDetail(OverrideType.playerType, selectedRow.scheduledTeeTimePlayerId, this.quickloginUserId));
          this.quickloginUserId = 0;
          this.isPlayerTypeDaysOut = true;
          return true;
        }
        else {
          this.isPlayerTypeDaysOut = false;
          return false;
        }
      }
    }
    this.isPlayerTypeDaysOut = true;
    return true;
  }

  public async validateRateTypeDaysOut(selectedDate, selectedRow: ScheduledTeeTimeUnPaidPlayer, rateType: RateType) {
    this.resetDaysOutFlags();
    let result = this._teeActionBusiness.validateRateTypeDaysOut(this._localization.getDate(selectedDate), rateType.daysOutStart, rateType.daysOutEnd);
    if (result && (result == RateTypeDaysOutResult.StartDateFail || result == RateTypeDaysOutResult.EndDateFail)) {
      let errorMessage = this._teeActionBusiness.GetrateDaysoutErrorMessage(rateType, result);
      selectedRow.overrideDetail = selectedRow.overrideDetail.filter(x=> x.overrideType != OverrideType.rateType);
      if (this.overrideRateTypeDaysoutUserAccess) {
        selectedRow.overrideDetail.push(this.getOverrideDetail(OverrideType.rateType, selectedRow.scheduledTeeTimePlayerId, this.currentUserId));
      }
      else {
        let override = await this.showQuickIdPopup(errorMessage, OverrideType.rateType);
        if (override) {
          selectedRow.overrideDetail.push(this.getOverrideDetail(OverrideType.rateType, selectedRow.scheduledTeeTimePlayerId, this.quickloginUserId));
          this.quickloginUserId = 0;
          this.isRateTypeDaysOut = true;
          return true;
        }
        else {
          this.isRateTypeDaysOut = false;
          return false;
        }
      }
    }
    this.isRateTypeDaysOut = true;
    return true;
  }

  private getOverrideDetail(overrideType: OverrideType, scheduledTeeTimePlayerId, userId) {
    return {
      id: 0,
      scheduledTeeTimePlayerId: scheduledTeeTimePlayerId,
      overrideType: overrideType,
      userId: userId
    } as OverrideDetails
  }

  public async showPlayerTypeRateTypeQuickIdPopup(errorMessage: string): Promise<boolean> {
    const width = OVERRIDE_ALERT_WIDTH;
    return new Promise<boolean>(async (resolve, reject) => {
      let res = this._utils.showAlertWithCustomWidth(errorMessage, AlertType.Info, ButtonType.OkOverride, width, (res) => {
        if (res === AlertAction.OVERRIDE) { // click override
          const quickLoginDialogRef = this.quickLoginUtils.QuickLogin({ breakPointNumber: UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT }, true, [UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT, UserAccessBreakPoints.OVERRIDERATETYPEDAYSOUT], OVERRIDEPLAYERTYPEANDRATETYPEDAYSOUTERROR);
          quickLoginDialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
            if (quickLoginDialogResult.isLoggedIn && quickLoginDialogResult.userDetails && quickLoginDialogResult.userDetails.breakPointAccess) {  //Quickid user has breakpoint #10195                              
              this.quickloginUserId = quickLoginDialogResult.userDetails.userId;
              this.quickLoginUtils.resetQuickIdDetails();
              resolve(true);
            } else { //Quickid user doesn't have access for #10195
              resolve(false);
            }
          });
        } else { // click Ok
          resolve(false);
        }
      });
      await res.afterClosed().toPromise();
    });
  }

  public async showQuickIdPopup(errorMessage: string, overrideType: OverrideType): Promise<boolean> {
    const width = OVERRIDE_ALERT_WIDTH;
    this._utils.ToggleLoader(false);
    return new Promise<boolean>(async (resolve, reject) => {
      let res = this._utils.showAlertWithCustomWidth(errorMessage, AlertType.Info, ButtonType.OkOverride, width, (res) => {
        if (res === AlertAction.OVERRIDE) { // click override
          const quickLoginDialogRef = this.quickLoginUtils.QuickLogin({ breakPointNumber: (overrideType == OverrideType.playerType ? UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT : UserAccessBreakPoints.OVERRIDERATETYPEDAYSOUT) });
          quickLoginDialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
            if (quickLoginDialogResult.isLoggedIn && quickLoginDialogResult.userDetails && quickLoginDialogResult.userDetails.breakPointAccess) {                                
              this.quickloginUserId = quickLoginDialogResult.userDetails.userId;
              this.quickLoginUtils.resetQuickIdDetails();
              resolve(true);
            } else { //Quickid user doesn't have access 
              resolve(false);
            }
          });
        } else { // click Ok
          resolve(false);
        }
      });
      await res.afterClosed().toPromise();
    });
  }
  /*Player Type And Rate Type Change Validations*/

  /*Get CourseTeeFee*/
  public async getTeeFeesForCourseAndDate(courseId: number, date: Date): Promise<RateSetupData[]> {
    console.log(this.RateSetups);
    if (this.RateSetups.filter(x => this._localization.getDate(x.startTime) <= date && this._localization.getDate(x.endTime) >= date && x.courseId == courseId).length == 0) {
      this.RateSetups = await this._rateSetupDataService.getTeeFeesForCourseAndDate(courseId, date);
    }
    let response = this.RateSetups.filter(x => this._localization.getDate(x.startTime) <= date && this._localization.getDate(x.endTime) >= date && x.courseId == courseId).map(x => this.mapRateSetupData(x));
    console.log(this.RateSetups);
    return response;
  }

  public async getDynamicPricing(courseId: number, date: Date): Promise<DynamicPricingPlayerFee[]> {
    console.log(this.DynamicPricingFees);
    if (this.DynamicPricingFees.filter(x => x.date.getTime() == date.getTime() && x.courseId == courseId).length == 0) {
      let dynamicPricing: any = await this._dynamicPricingService.GetDynamicPricingForCourseAndScheduledDate(courseId, date);
      this.DynamicPricingFees.push({
        date: date,
        courseId: courseId,
        dynamicPricings: dynamicPricing
      });
    }
    console.log(this.DynamicPricingFees);
    return this.DynamicPricingFees.filter(x => x.date.getTime() == date.getTime() && x.courseId == courseId)[0].dynamicPricings;
  }

  public async getCustomFees(date:Date, scheduledTeeTimePlayerFeeId:number, totalAmount:number){
    console.log(this.customFeeData);
    if(this.customFeeData.filter(x=> x.date.getTime() == date.getTime() && x.scheduledTeeTimePlayerFeeId == scheduledTeeTimePlayerFeeId).length == 0){
      let customFees: ScheduledTeeTimePlayerCustomFee[] = await this._customFeeService.GetCustomFees(this.mapCustomFeeRequest(date, scheduledTeeTimePlayerFeeId, totalAmount))
      this.customFeeData.push(this.mapCustomFeeData(date, scheduledTeeTimePlayerFeeId, totalAmount, customFees));
    }
    console.log(this.customFeeData);
    return this.calculateCustomFees(totalAmount, this.customFeeData.find(x=> x.date.getTime() == date.getTime() && x.scheduledTeeTimePlayerFeeId == scheduledTeeTimePlayerFeeId)?.customFeeConfigs)
  }

  private calculateCustomFees(totalAmount:number,customFees :ScheduledTeeTimePlayerCustomFee[]){
    if(customFees?.length>0){
      customFees.map(r=>{
        r.id = 0;
        r.scheduledTeeTimePlayerFeeId = 0;
        r.amount = this._teeTimeCustomFeeUtilities.CalculateCustomFee(totalAmount, r);
        r.customFeeAmountAtBooking = this._teeTimeCustomFeeUtilities.CalculateCustomFeeAtBooking(r);
      });
    }
    console.log(customFees);
    return customFees
  }

  public mapCustomFeeData(date:Date, scheduledTeeTimePlayerFeeId:number, totalAmount:number, customFees: ScheduledTeeTimePlayerCustomFee[]){
    return {
      date : date,
      scheduledTeeTimePlayerFeeId:scheduledTeeTimePlayerFeeId,
      customFeeConfigs:customFees
    } as CustomFeeData
  }

  private mapCustomFeeRequest(date:Date, scheduledTeeTimePlayerFeeId:number, totalAmount:number)
  {
    return {
      scheduledDate : date,
      scheduledTeeTimePlayerFeeId:scheduledTeeTimePlayerFeeId,
      totalAmount:totalAmount
    } as CustomFeeRequest
  }

  private mapRateSetupData(rateSetupApiData: RateSetupAPI.RateSetupData): RateSetupData {
    return {
      id: rateSetupApiData.id,
      courseId: rateSetupApiData.courseId,
      rateTypeId: rateSetupApiData.rateTypeId,
      cartFee: rateSetupApiData.cartFee,
      greenFee: rateSetupApiData.greenFee,
      greenFeeRetailItemId: rateSetupApiData.greenFeeRetailItemId,
      cartFeeRetailItemId: rateSetupApiData.cartFeeRetailItemId
    } as RateSetupData;
  }

  public async getRetailItems(retailItemIds: number[]): Promise<any[]> {
    let response: any = [];
    let retailIds: number[] = [];
    console.log(this.retailItems);
    retailItemIds = retailItemIds.filter(x => x != null);
    retailItemIds.forEach(v => {
      if (this.retailItems.filter(u => u.id == v).length == 0) retailIds.push(v);
    });

    if (retailIds.length > 0) {
      let items = await this._paymentBusinessService.GetRetailItemsByIds(retailIds);
      if (items.length > 0) {
        items.forEach(i => {
          if (this.retailItems.filter(j => j.id == i.id).length == 0) this.retailItems.push(i);
        });
      }
    }

    if (this.retailItems.filter(x => retailItemIds.includes(x.id)).length > 0) {
      this.retailItems.forEach(r => {
        if (retailItemIds.includes(r.id)) response.push(r);
      });
    }
    console.log(this.retailItems);
    return response;
  }

  public async CheckQuickIdForSave(tableData: ScheduledTeeTimeUnPaidPlayer[]) {
    let res = false;
    if (this.isQuickIdPopupEnableForSave) {
      return await this.showSaveQuickIdPopup(tableData);
    }
    return res;
  }

  public async showSaveQuickIdPopup(tableData: ScheduledTeeTimeUnPaidPlayer[]): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      const quickLoginDialogRef = this.quickLoginUtils.QuickLogin();
      quickLoginDialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
        if (quickLoginDialogResult.isLoggedIn) {
          const quickRoleId = this.quickLoginUtils.GetQuickLoginRoleId();
          const roleId = this._localization.GetUserInfo("roleId");
          let isAllocationAllowed = false;
          if (quickRoleId != roleId && this.isAllocationCodePermissionByRoleCheck) {
            isAllocationAllowed = await this.checkAlloctionBlockAndPlayerTypePermissionOnSave(quickRoleId, tableData);
          }
          if (!isAllocationAllowed) {
            resolve(false);
          }
          else resolve(true);
        }
        else {
          resolve(false);
        }
      });
    });
  }

  public async checkAlloctionBlockAndPlayerTypePermissionOnSave(roleId, tableData: ScheduledTeeTimeUnPaidPlayer[]): Promise<boolean> {
    let res = true;
    let allocationcodeRolePermission = await this._allocationCodeManagementService.getAllocationBlockPermissionByRole(roleId);
    if (this.allocationBlockPlayerTypes.length == 0) this.allocationBlockPlayerTypes = await this._allocationCodeManagementService.getAllocationsBlockWithPlayerTypes();
    let nonaccessibleAllocationBlock = [];
    let nonaccessiblePlayer = [];
    if (allocationcodeRolePermission.length > 0) {
      tableData.forEach(allocationBlock => {
        const allocationBlockWithPlayerType = allocationcodeRolePermission.filter(x => x.allocationBlockId == allocationBlock.allocationBlockId);
        const allocationBlockPlayerTypes = this.allocationBlockPlayerTypes.find(x => x.id == allocationBlock.allocationBlockId);
        if (allocationBlockWithPlayerType.length == 0) {
          if (allocationBlockPlayerTypes && allocationBlockPlayerTypes != undefined) {
            nonaccessibleAllocationBlock.push(allocationBlockPlayerTypes.name);
          }
        } else {
          tableData.forEach(player => {
            if (player.playerTypeId) {
              const allocationPlayer = allocationBlockWithPlayerType.filter(x => x.playerTypeId == player.playerTypeId);
              if (allocationPlayer.length == 0) {
                if (allocationBlockPlayerTypes && allocationBlockPlayerTypes != undefined) {
                  const playerType = allocationBlockPlayerTypes.playerTypes.find(x => x.id === player.playerTypeId);
                  if (playerType) {
                    nonaccessiblePlayer.push(playerType.type);
                  }
                }
              }
            }
          });
        }
      });
    } else if (allocationcodeRolePermission.length == 0) {
      tableData.forEach(allocationBlock => {
        const allocationBlockPlayerTypes = this.allocationBlockPlayerTypes.find(x => x.id == allocationBlock.allocationBlockId);
        nonaccessibleAllocationBlock.push(allocationBlockPlayerTypes.name);
      });
    }
    if (nonaccessibleAllocationBlock.length > 0 || nonaccessiblePlayer.length > 0) {
      res = false;
      const uniqueNonAccessAllocationBlock = _.uniq(nonaccessibleAllocationBlock);
      const uniqueNonAccessPlayer = _.uniq(nonaccessiblePlayer);
      let name = ''
      if (uniqueNonAccessAllocationBlock.length > 0) {
        name = uniqueNonAccessAllocationBlock.join(', ') + ' ';
      }
      if (uniqueNonAccessPlayer.length > 0) {
        name = name + uniqueNonAccessPlayer.join(', ')
      }
      this._utils.showAllocationCodePermissionDeniedPopup(name);
    }
    return res;
  }

  public mapUpdatePlayerFee(data: ScheduledTeeTimeUnPaidPlayer[]) {
    let response: UpdatePlayerFee[] = []
    response = data.map(d => {
      return {
        scheduledTeeTimePlayerFeeId: d.scheduledTeeTimePlayerFeeId,
        scheduledTeeTimePlayerId:d.scheduledTeeTimePlayerId,
        playerTypeId: d.playerTypeId,
        rateTypeId: Number(d.rateTypeId),
        greenFee: d.greenFee,
        cartFee: d.cartFee,
        isDynamicPrice: d.isDynamicPricingApplied ,
        scheduledTeeTimePlayerFeeDynamicPricing:d.isDynamicPricingApplied? this.mapDynamicPricing(d.dynamicPricing, Number(d.rateTypeId)): {},
        dynamicPricingId: d.isDynamicPricingApplied ? d.dynamicPricing.dynamicPricingId : 0,
        overrideDetail:d.overrideDetail,
        isCustomFeeEnabled: d.isCustomFeeEnabled,
        scheduledTeeTimePlayerCustomFees : d.scheduledTeeTimePlayerCustomFees,
        scheduledDateTime:this._localization.getDate(d.scheduledAPIDateTime),
        scheduledTeeTimeId:d.scheduledTeeTimeId,
        multiPackTransactionDetailId: d.multiPackTransactionDetailId,
        oldMultiPackTransactionDetailId: d.oldMultiPackTransactionDetailId,
        isMultiPackUpdated: d.isMultiPackUpdated,
        isGreenFeeNegotiated: d.isGreenFeeNegotiated,
        isCartFeeNegotiated: d.isCartFeeNegotiated
      } as UpdatePlayerFee
    });
    return response;
  }

  mapDynamicPricing(data:DynamicPricingPlayerFee, rateTypeId){
    return {
      id:0,
      scheduledTeeTimePlayerFeeId : 0,
      rateTypeId :rateTypeId,
      occupancy : data.occupancy,
      daysInAdvance : data.daysInAdvance,
      isCartFeeIncluded : data.isCartFeeIncluded,
      originalGreenFee : data.originalGreenFee,
      originalCartFee : data.originalCartFee,
      greenFee : data.greenFee,
      cartFee : data.cartFee,
      utilizationGreenFee : data.utilizationGreenFee,
      daysInAdvanceGreenFee : data.daysInAdvanceGreenFee,
      utilizationCartFee : data.utilizationCartFee,
      daysInAdvanceCartFee : data.daysInAdvanceCartFee
    } as ScheduledTeeTimePlayerFeeDynamicPricing
  }
  
  public async UpdatePlayerFees(UpdatePlayerFees: UpdatePlayerFee[]): Promise<boolean> {
    return await this.http.putPromise<boolean>({
      route: GolfApiRoute.UpdatePlayerFees,body: UpdatePlayerFees}, true);
  }

  public async GetMultiPackDetails(guestIds: any[]) {
    console.log(this.multipackDetails)
    if(this.multipackDetails.filter(x => x?.guestId == guestIds[0].guestId && x?.guestType == guestIds[0].guestType).length == 0){
      let multiPacks = await this._linkedMultipackDataService.GetMultiPackDetails(guestIds);
      multiPacks.forEach(m=>{
        this.multipackDetails.push(m);
      });
    }
    console.log(this.multipackDetails);
    return this.multipackDetails.filter(x => x?.guestId == guestIds[0].guestId && x?.guestType == guestIds[0].guestType);
  }

  public async GetTeeTimeMultiPack(multiPackTransactionDetailId:number){
    console.log(this.teeTimeMultiPackDetails);
    if(this.teeTimeMultiPackDetails.filter(x=> x.multiPackTransactionDetailId == multiPackTransactionDetailId).length == 0){
      let multiPackDetails = await this._paymentBusinessService.GetTeeTimeMultiPack([multiPackTransactionDetailId]);
      multiPackDetails.forEach(m=>{
        this.teeTimeMultiPackDetails.push(m);
      });
    }
    console.log(this.teeTimeMultiPackDetails);
    return this.teeTimeMultiPackDetails.filter(x=> x.multiPackTransactionDetailId == multiPackTransactionDetailId);
  }

  /*Tee Grid Flow*/


  public mapTeeTimeDetailToUnpaidPlayer(teeTimeDetail: TeeSheetPlayerDetails){
    //this.customFeeData.push(this.mapCustomFeeData(this._localization.getDate(teeTimeDetail?.time), teeTimeDetail?.scheduledTeeTimePlayerFee?.id, (teeTimeDetail?.greenFee + teeTimeDetail?.cartFee), teeTimeDetail?.scheduledTeeTimePlayerFee?.scheduledTeeTimePlayerCustomFees));
    return {
      id: teeTimeDetail.playerId,
      checked:true,
      scheduledTeeTimeId: teeTimeDetail.scheduleTeeTimeId,
      scheduledDateTime: this._localization.LocalizeLongTime(this._localization.getDate(teeTimeDetail.time)).toUpperCase(),
      playerName: teeTimeDetail.fullName,
      playerId: teeTimeDetail.playerId,
      playerType: teeTimeDetail?.playerType?.name,
      playerTypeId: teeTimeDetail?.playerType?.id,
      playerSlotPosition: teeTimeDetail?.playerPos,
      holes: teeTimeDetail?.holes,
      walk: teeTimeDetail?.isWalk.toString(),
      trail: teeTimeDetail?.isTrail.toString(),
      //course
      rateType: teeTimeDetail?.rateType?.name,
      rateTypeId: teeTimeDetail?.rateType?.id,
      packageCode: teeTimeDetail?.packageCode,
      cartFeeRetailItemId: 0,
      cartFeeRetailItem: {} as DomainRetailItem,
      cartFee: teeTimeDetail?.scheduledTeeTimePlayerFee?.cartFee,
      isCartFeeNegotiated: teeTimeDetail?.scheduledTeeTimePlayerFee?.isCartFeeNegotiable,
      negotiateCartFeeRetailItemId:0,
      negotiateCartFeeRetailItem:{} as DomainRetailItem,
      greenFeeRetailItemId: 0,
      greenFeeRetailItem: {} as DomainRetailItem, 
      greenFee: teeTimeDetail?.scheduledTeeTimePlayerFee?.greenFee,
      isGreenFeeNegotiated: teeTimeDetail?.scheduledTeeTimePlayerFee?.isGreenFeeNegotiable,
      negotiateGreenFeeRetailItemId: 0,
      negotiateGreenFeeRetailItem: {} as DomainRetailItem,
      entryFeeRetailItemId: 0,
      entryFeeRetailItem:{} as DomainRetailItem,
      //otherItems
      tournamentId: teeTimeDetail?.tournamentId,
      deposits: teeTimeDetail?.deposits,
      //isTournamentPlayersUnPaid
      ticketNumber: teeTimeDetail?.ticketNumber,
      //jsonTicketNumber
      playerCategoryId: teeTimeDetail?.playerCategoryId,
      playerLinkId: teeTimeDetail?.linkingId,
      //firstName
      //lastName
      confirmationNumber: teeTimeDetail?.confirmationNumber,
      teeTimeFormat: teeTimeDetail?.teeTimeFormat,
      multiPackTransactionDetailId: teeTimeDetail?.multiPackTransactionDetailId,
      transactionId: teeTimeDetail?.transactionId,
      playerStatus: teeTimeDetail?.playerStatus,
      greenFeeTax: 0,
      cartFeeTax: 0,
      entryFeeTax: 0,
      totalCustomFeeTax:0,
      //corpId
      //courseListOrder
      courseId: teeTimeDetail?.courseId,
      isNegotiated: (teeTimeDetail?.scheduledTeeTimePlayerFee?.isCartFeeNegotiable ||  teeTimeDetail?.scheduledTeeTimePlayerFee?.isGreenFeeNegotiable),
      scheduledTeeTimePlayerFeeId: teeTimeDetail?.scheduledTeeTimePlayerFee?.id,
      scheduledTeeTimePlayerCustomFees: teeTimeDetail?.scheduledTeeTimePlayerFee?.scheduledTeeTimePlayerCustomFees,
      //discountType
      scheduledAPIDateTime: teeTimeDetail?.time,
      //corpName
      playerPosition: teeTimeDetail?.playerPos,
      allocationBlockId: teeTimeDetail?.allocationBlockId,
      scheduledTeeTimePlayerId: teeTimeDetail?.scheduledTeeTimePlayerId,
      isHold: false,
      isReleaseAllow: false,
      isDirty: false,
      isRateTypeDirty: false,
      isMultiPackUpdated: false,
      isMultiPackApplied: teeTimeDetail?.multiPackTransactionDetailId > 0,
      oldMultiPackTransactionDetailId: teeTimeDetail?.multiPackTransactionDetailId,
      isPlayerTypeRequired: this.isRequirePlayerType,
      isRateTypeRequired: this.isRequireRateType,
      allocationBlockWithPlayerTypes: {} as AllocationBlockWithPlayerType,
      playerTypeInfo : {} as PlayerTypeInfo,
      rateTypeInfo: {} as RateTypeInfo,
      playerTypeRateTypes:[],
      isDisableDropdown :this.isDisableDropdownEdit,
      totalFeeCurrency: this._localization.localizeCurrency((teeTimeDetail?.scheduledTeeTimePlayerFee?.greenFee + teeTimeDetail?.scheduledTeeTimePlayerFee?.cartFee), false, 2),
      greenFeeCurrency: this._localization.localizeCurrency(teeTimeDetail?.scheduledTeeTimePlayerFee?.greenFee),
      cartFeeCurrency: this._localization.localizeCurrency(teeTimeDetail?.scheduledTeeTimePlayerFee?.cartFee),
      overrideDetail: [],
      multiPackRetailItem:  [] as DomainRetailItem[],
      dynamicPricing: {} as DynamicPricingPlayerFee,
      isDynamicPricingApplied: false,
      isCustomFeeEnabled:  this.isCustomFeeEnabled,
      isAllowPay: teeTimeDetail?.isAllowPay,
      tokenTransId: teeTimeDetail?.tokenTransId
    } as ScheduledTeeTimeUnPaidPlayer
  }

  public mapEditDetailToUnpaidPlayer(eve: ScheduledTeeTimeUnPaidPlayer, unpaidPlayer:ScheduledTeeTimeUnPaidPlayer[]) : ScheduledTeeTimeUnPaidPlayer[]
  {
    unpaidPlayer.map(u => {
      u.playerTypeId = eve.playerTypeId,
      u.rateTypeId = eve.rateTypeId,
      u.scheduledTeeTimePlayerFeeId = eve.scheduledTeeTimePlayerFeeId,
      u.greenFee = eve.greenFee,
      u.greenFeeRetailItemId = eve.greenFeeRetailItemId == 0 ? u.greenFeeRetailItemId : eve.greenFeeRetailItemId,
      u.greenFeeRetailItem = eve.greenFeeRetailItemId == 0 ? u.greenFeeRetailItem : eve.greenFeeRetailItem,
      u.cartFeeRetailItemId = eve.cartFeeRetailItemId == 0? u.cartFeeRetailItemId : eve.cartFeeRetailItemId,
      u.cartFeeRetailItem = eve.cartFeeRetailItemId == 0 ? u.cartFeeRetailItem : eve.cartFeeRetailItem,
      u.multiPackRetailItem = eve.isMultiPackUpdated ? eve.multiPackRetailItem : u.multiPackRetailItem,
      u.cartFee = eve.cartFee,
      u.isDynamicPricingApplied = eve.isDynamicPricingApplied,
      u.dynamicPricing = eve.isDynamicPricingApplied ? eve.dynamicPricing : u.dynamicPricing,
      u.overrideDetail = eve.overrideDetail.length > 0 ? eve.overrideDetail : u.overrideDetail,
      u.isCustomFeeEnabled = eve.isCustomFeeEnabled,
      u.scheduledTeeTimePlayerCustomFees = eve.scheduledTeeTimePlayerCustomFees,
      u.multiPackTransactionDetailId = eve.multiPackTransactionDetailId,
      u.oldMultiPackTransactionDetailId = eve.oldMultiPackTransactionDetailId,
      u.isMultiPackUpdated = eve.isMultiPackUpdated,
      u.isGreenFeeNegotiated = eve.isGreenFeeNegotiated,
      u.isCartFeeNegotiated = eve.isCartFeeNegotiated,
      u.tokenTransId = eve.tokenTransId
    });
    return unpaidPlayer;
  }

  updatePlayerTypeRateTypeTableDetails(data:ScheduledTeeTimeUnPaidPlayer)
  {
    data.isDirty = false;
    data.isRateTypeDirty = false;
    data.isMultiPackUpdated = false;
    data.isMultiPackApplied = data.multiPackTransactionDetailId > 0;
    data.oldMultiPackTransactionDetailId = data.multiPackTransactionDetailId;
    data.isPlayerTypeRequired = this.isRequirePlayerType;
    data.isRateTypeRequired = this.isRequireRateType;
    data.allocationBlockWithPlayerTypes = {} as AllocationBlockWithPlayerType;
    data.playerTypeInfo = {} as PlayerTypeInfo;
    data.rateTypeInfo = {} as RateTypeInfo;
    data.playerTypeRateTypes = [];
    data.isDisableDropdown = this.isDisableDropdownEdit; // (data.checked && !this._playerTypeRateTypeChangeService.isDisableDropdownEdit)? false: true;
    data.walk_trial = data.walk + '/' + data.trail; 
    data.pos_holes = data.playerPosition + '/' + data.holes;
    data.totalFeeCurrency = this._localization.localizeCurrency((data.greenFee + data.cartFee), false, 2)  ;
    data.greenFeeCurrency = this._localization.localizeCurrency(data.greenFee , false, 2) ;
    data.cartFeeCurrency = this._localization.localizeCurrency(data.cartFee, false, 2) ;
    data.scheduledDateTime = this._localization.LocalizeLongTime(this._localization.getDate(data.scheduledAPIDateTime)).toUpperCase();
    data.overrideDetail = data.overrideDetail ? data.overrideDetail : [];
    data.multiPackRetailItem = data.multiPackRetailItem ? data.multiPackRetailItem: []; 
    data.dynamicPricing = {} as DynamicPricingPlayerFee;
    data.isDynamicPricingApplied = false;
    data.dynamicPricing = {} as DynamicPricingPlayerFee;
    data.isCustomFeeEnabled = this.isCustomFeeEnabled;
    this.customFeeData.push(this.mapCustomFeeData(this._localization.getDate(data.scheduledAPIDateTime), data.scheduledTeeTimePlayerFeeId, (data.greenFee + data.cartFee), data.scheduledTeeTimePlayerCustomFees));
    if(data.greenFeeRetailItemId == 0) data.greenFeeRetailItem = {} as DomainRetailItem;
    if(data.cartFeeRetailItemId == 0) data.cartFeeRetailItem = {} as DomainRetailItem;
    if(data.entryFeeRetailItemId == 0) data.entryFeeRetailItem = {} as DomainRetailItem;
    if(data.greenFeeRetailItemId > 0 && this.retailItems.filter(x=> x.id == data.greenFeeRetailItemId).length==0)this.retailItems.push(data.greenFeeRetailItem);
    if(data.cartFeeRetailItemId > 0 && this.retailItems.filter(x=> x.id == data.cartFeeRetailItemId).length==0)this.retailItems.push(data.cartFeeRetailItem);
    if(data.tournamentId > 0 && data.entryFeeRetailItemId > 0 && this.retailItems.filter(x=> x.id == data.entryFeeRetailItemId).length==0)this.retailItems.push(data.entryFeeRetailItem);
  }


  public async GetPlayer(scheduledTeeTimePlayerId, courseId, scheduledDate, allocationBlockId){
    let unpaidPlayer: ScheduledTeeTimeUnPaidPlayer[] = await this._unPaidPlayersBusiness.getPlayer(scheduledTeeTimePlayerId, courseId, this._localization.convertDateTimeToAPIDateTime(scheduledDate), PlayerPaymentstatus.unPaid | PlayerPaymentstatus.refund | PlayerPaymentstatus.checkIn);
    if(unpaidPlayer && unpaidPlayer.length>0){
      unpaidPlayer.map(element => { element.checked = true, element.scheduledTeeTimePlayerId = element.id; element.id = element.playerId; element.isHold = false; element.isReleaseAllow = false;element.playerPosition = element.playerSlotPosition, element.allocationBlockId = allocationBlockId });
    }
    return unpaidPlayer;
  }

  async validateSelectedOutlet(courseId): Promise<boolean>
  {
    if (!await this._retailBusinessService.onClickValidateSelectedOutlet(courseId, false, true,true)) {
      this._utils.ToggleLoader(false);
      let payCaption = this._localization.captions.teetime;
      let selectOutletPopup = SelectOutletPopupComponent;
      let selectOutletTitle = payCaption.selectOutletPopupTitle;
      let selectOutletSaveText = payCaption.saveData;
      let selectOutletPopUpWidth = '35%';
      let selectOutletPopupHeight = '45%';
      let eventType = ButtonAction.pay;
      return this.openDefaultOutletPopup(selectOutletPopup, {}, selectOutletPopUpWidth, selectOutletPopupHeight, selectOutletTitle, selectOutletSaveText, eventType, TeeTimeActions.payment);
    }
    else return true;
  }

  async openDefaultOutletPopup(modalComponentName, arg, popUpWidth, popUpHeight, modalTitle, saveText, eventType, actionType?): Promise<boolean> {
    let payCaption = this._localization.captions.teetime;
    let popUpComponentDetails = {
      componentName: modalComponentName,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent',
        bindData: arg
      }
    };
    const selectDefaultDialogRef = this._dialog.open(SelectOutletPopupComponent, {
      width: popUpWidth,
      height: popUpHeight,
      data: {
        title: modalTitle,
        update: saveText,
        cancel: payCaption ? payCaption?.cancel : '',
        componentDetails: popUpComponentDetails,
        info: arg,
        actionType: eventType,
        teeTimeType: actionType,
        isSaveClose: true
      },
      disableClose: true
    });
    return new Promise<boolean>(async (resolve, reject) => {
      selectDefaultDialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (result) => {
        if (result.from === AlertAction.YES){
          this._retailService.SelectedOutletId = result.outlet;
          resolve(true);
        }
        else resolve (false);
      });
    });
  }

  async tempHoldTeeTime(courseId, holeNumber, originalHoleNumber, time, status: number): Promise<boolean> {
    let result = false;
    const dt = this._localization.ConvertDateToISODateTime(this._utils.getDate(time));
    const teeTimeTempHold: TeeTimeTempHold = {
      courseId: Number(courseId),
      createdBy: sessionStorage.getItem('quickIdUser') ? parseInt(sessionStorage.getItem('quickIdUser')) : Number(this._localization.GetPropertyInfo('UserId')),
      createdOn: dt,
      holeNumber: holeNumber,
      id: 0,
      releaseDateTime: dt,
      scheduleDateTime: dt,
      originalHoleNumber: originalHoleNumber
    };
    if (status === TempHoldStatus.hold) {
      var res;
      await this._teeTimeService.TempHoldTeeTime(teeTimeTempHold).then(r => { result = true;}).catch(async e => {
        let errorMsg = '';
        if (!e.result && e.errorCode) 
        {
          errorMsg = this._localization.getError(e.errorCode);          
          errorMsg = e.errorDescription ? errorMsg.interpolate({ user: e.errorDescription }) : "";
        }
        else 
        {
          errorMsg = this._localization.getError(11014);
        }
        if (e.errorCode === 11010) {
          res = this._utils.showAlert(errorMsg, AlertType.Info, ButtonType.Ok);
        } else {
          res = this._utils.showAlert(errorMsg, AlertType.Error, ButtonType.Ok);
        }        
        await res.afterClosed().toPromise();
        result = false;               
      });
    } else {
      await this._teeTimeService.ReleaseTempHoldTeeTime(teeTimeTempHold).then(r => { result = true }).catch(e => {
        result = false;
      });
    }
    return result;
  }

  async ProceedToOrderSummary(unpaidPlayer: ScheduledTeeTimeUnPaidPlayer[], dialogRef = null, action: FromAction,
    isNavigateToOrderSummary: boolean = false, isFromPopup:boolean =false, selectedProducts = []) {
    
      this._utils.ToggleLoader(true);
      let defaultConfig = JSON.parse(sessionStorage.getItem(CacheConstants.DefaultSettings));
    this._retailService.DeletePreviousPlayer = false;
    let fromCancelScreen = action == FromAction.Cancellation || action == FromAction.BulkCancellation;
    let fromNoShow = action == FromAction.NoShow;
    let allowProductReassignment = ((action == FromAction.Cancellation || action == FromAction.BulkCancellation) || (action == FromAction.NoShow));

    if (defaultConfig.defaultOutletType == DefaultOutletType.course) {
      let course = await this._courseDataService.getCourse(unpaidPlayer[0].courseId);
      await this.settingDefaultOutlet(course.defaultOutletId);
      this._retailService.isTeeTimeAsPaid = true;
      this._retailBusinessService.ValidateSelectedTerminalExistsInSelectedOutlet();
    } else {
      const defaultConfig = await this.getUserDefaultConfig(this._localization.GetPropertyInfo("UserId"));
      let defaultOutletId = defaultConfig.defaultOutletId > 0 ? defaultConfig.defaultOutletId : this._retailService.SelectedOutletId;
      await this.settingDefaultOutlet(defaultOutletId);
      this._retailService.isTeeTimeAsPaid = false;
    }
    this._retailService.isFromCheckInCheckOutPopUP = false;
    this._retailService.isToMarkCheckIn = false;
    this._commonVariable.SelectedOutletId = this._retailService.SelectedOutletId;
    this._retailService.isFromEditTeeTime = (action == FromAction.TeeGrid);
    this._commonVariable.isFromCheckInCheckOutPopUP = false;
    let SelectedPlayers: ScheduledTeeTimeUnPaidPlayer[] = unpaidPlayer;
    let playerIds: number[] = SelectedPlayers.map(c => c.playerId);

    if (!this.isToContinueWithOldCartItems) {
      if (!await this._retailBusinessService.CheckForUserOutletAccessAndDefaultOutlet(dialogRef, isFromPopup)) {
        await this._teeTimeActionService.releasePlayersInHold(playerIds);
        return;
      }
      let customFeeLinkedItemIds = _.uniq(SelectedPlayers.flatMap( o => o.scheduledTeeTimePlayerCustomFees?.flatMap(o => o.linkedItemId)));
      if(selectedProducts && selectedProducts.length == 0){
        selectedProducts = await this._retailBusinessService.getSelectedProducts(SelectedPlayers,customFeeLinkedItemIds);
      } 

      var retailProducts: any[] =[];

      if(!allowProductReassignment){
        let items = await this._teeTimeActionService.getPlayerRetailItems(SelectedPlayers.map(x=>x.scheduledTeeTimePlayerId));
        items?.map(y=>y.retailItems).forEach(x=>{
          var y =JSON.parse(x);
          y.forEach(z=>{
            z.id = z.ItemId;
            retailProducts.push(z);
            selectedProducts.push(z);
          });
        });
      }

      if (SelectedPlayers.length > 0 && selectedProducts?.length > 0) {
        if (this._retailBusinessService.CheckPlayersHaveAnyPendingSettlements(SelectedPlayers)) {
          return;
        }
        const golfItems = this._retailBusinessService.GetRetailItemsFromPlayers(SelectedPlayers);
        retailProducts?.forEach(x=>{
          golfItems.push(x);
        });
        if (!(await this._retailBusinessService.CheckItemsAreFromSelectedOutlet(golfItems))) {
          await this._teeTimeActionService.releasePlayersInHold(playerIds);
          return;
        }
        this._retailService.SelectedPlayers = SelectedPlayers;
        this._retailService.payeeId = this._retailBusinessService.SetP1PlayerId();
        this._retailService.sourceCorpId = Number( SelectedPlayers.filter(x => x.playerCategoryId === PlayerCategory.Member)?.map(x => x.corpId)[0] ?? 0);
        if(!fromNoShow && action != FromAction.TeeGrid)
        {
          this._retailService.selectedProducts.push(...selectedProducts[0]);
        }
        else
        {
          this._retailService.selectedProducts.push(...selectedProducts);
        }
        const selectedProductsArrayReassigned = [];
        if (this._retailService.selectedProducts.length > 0) {
          this._retailService.selectedProducts.forEach((x) => {
            if (x.payeeId) {
              if (x.GroupingParentId > 0) {
                selectedProductsArrayReassigned.push(x);
              }
              else if (!selectedProductsArrayReassigned.some(item => item.payeeId === x.payeeId && x.GroupingParentId <= 0 &&
                item.ExternalPOSItemId === x.ExternalPOSItemId) || x.ItemType === GlobalConst.DepositRetailItemType
              ) {
                selectedProductsArrayReassigned.push(x);
              }
            }
            else {
              selectedProductsArrayReassigned.push(x);
            }
          });
        }
        // product assignment should not happen when payment initiated from cancel screen / no show screen
        if(!allowProductReassignment)
        {
          this._retailService.selectedProducts = selectedProductsArrayReassigned;
        }
        this._commonVariable.selectedProducts = this._retailService.selectedProducts;
        this._commonVariable.fromTeeTimeCancellation = this._retailService.fromTeeTimeCancellation = fromCancelScreen;
        this._commonVariable.fromTeeTimeNoShow = this._retailService.fromTeeTimeNoShow = fromNoShow;
        this._retailService.SeletedCourseId = SelectedPlayers[0].courseId;
        this._retailService.SelectedDate = SelectedPlayers[0].scheduledAPIDateTime;
        this._retailService.selectedScheduledTeeTimeId = SelectedPlayers[0].scheduledTeeTimeId;
        this._retailService.memberCardNumber = "0";
      }
    } else {
      this.isToContinueWithOldCartItems = false;
      this._retailService.isFromEditTeeTime = true;
    }
    if (this.router.url === '/tee-time/teesheet/graphicalView') {
      this._retailService.isFromTeeTimeGraphicalView = true;
    }
    this._retailService.RedirectModule =  this.router.url == '/tee-time/teesheet/graphicalView' ? RedirectToModules.TeeTimeGraphicalView : RedirectToModules.TeeTime;
    this._utils.ToggleLoader(false);
    this.NavigateToOrderSummary(isNavigateToOrderSummary, isFromPopup, dialogRef);
  }

  public NavigateToOrderSummary(isNavigateToOrderSummary, isFromPopup, dialogRef)
  {
    isNavigateToOrderSummary ? this.router.navigate(['/shop/viewshop/order']) : this.router.navigate(['/shop/viewshop']);
    if(dialogRef) {
      if (isFromPopup) {
        dialogRef.close();
      }
    }
  }

  async settingDefaultOutlet(defaultOutletId: number) {
    if (this._retailService.selectedProducts.length > 0) {
      if (this._retailService.SelectedOutletId != defaultOutletId) {
        var res = this._utils.showAlert(this._localization.captions.teetime.differentOutletSelection, AlertType.Warning, ButtonType.YesNo, (res) => {
          if (res === AlertAction.YES) {
            this._retailService.SelectedOutletId = defaultOutletId;
            this._retailService.selectedProducts = [];
            this._retailService.DeletePreviousPlayer = true;
          } else {
            this.isToContinueWithOldCartItems = true;
            this._retailService.DeletePreviousPlayer = false;
          }
        });
        await res.afterClosed().toPromise();
      }
    } else {
      this._retailService.SelectedOutletId = defaultOutletId;
    }
  }

  async getUserDefaultConfig(userId: string): Promise<DefaultUserConfiguration> {
    let userDefaultConfig = await this._defaultUserConfig.GetDefaultUserConfiguration(userId);
    if (userDefaultConfig) {
      return userDefaultConfig;
    }
    return undefined;
  }

  async holdPlayers(selectedPlayerIds) : Promise<boolean> {
    let result = false;
    await this._teeTimeActionService.TempHoldPlayers(selectedPlayerIds).then(r => {
      this._utils.ToggleLoader(true);
       result = true;  
      }).catch(async e => {
      this._utils.ToggleLoader(false);
      let errorMsg = "";
      if(!e.result && e.errorCode)
      {
        errorMsg = this._localization.getError(e.errorCode);
        if(e.errorCode == 41001)
        {          
          errorMsg = e.errorDescription ? errorMsg.interpolate({ slots : e.errorDescription}) : "";
        }        
      }
      var res = this._utils.showAlert(errorMsg, AlertType.Error, ButtonType.Ok);
      await res.afterClosed().toPromise();
      result = false;
    });
    return result;
  }

  async releaseHold(playerIds){
    await this._teeTimeActionService.releasePlayersInHold(playerIds);
  }

  /** 
   *  Methods to refund a player who has 1 or more deposits. Does 1 player refund. 
   * @param - tee time dialog data (component's dialog data from which this is called) 
   */
  async ProceedRefund(teeTimeModelData,selectedProducts, isfromPopup = false, dialogRef, action: FromAction,scheduledTeeTimeUnPaidPlayer) {
    this._retailService.SelectedPlayers = scheduledTeeTimeUnPaidPlayer;
    this._retailService.selectedTransaction = null;
    this._retailService.TaxValue = 0;
    await this.setDefaultOutlet(teeTimeModelData.course.id)
    this._utils.ToggleLoader(true);
    if (teeTimeModelData && teeTimeModelData.playerDetail) {
      let transactionId = 0;
      let transaction = teeTimeModelData.playerDetail.find(p => p.transactionId > 0 && (p.playerStatus & PlayerPaymentstatus.paid) != 0 
      && p.playerId == scheduledTeeTimeUnPaidPlayer[0].playerId); 
      let distinctTransactionIds: any = [...new Set(teeTimeModelData.playerDetail.filter(x => (x.playerStatus & PlayerPaymentstatus.paid) != 0).map(x => x.transactionId))];
      distinctTransactionIds = distinctTransactionIds.filter(x => x > 0);
      if(transaction == null){
        transactionId = scheduledTeeTimeUnPaidPlayer[0].dependentTransactionIds[scheduledTeeTimeUnPaidPlayer[0].playerId][0];
      }
      //Ticket status check is added before navigating to Refund screen.
      if (this._propertyInfo.UseRetailInterface) {
        let isClosedTicket: boolean = await this.checkTransactionIsClosed(distinctTransactionIds[0]);
        if (!isClosedTicket) {
          let alertMsg = this._localization.captions.teetime.TicketNotClosedWarningMsg;
          this._utils.showAlert(alertMsg, AlertType.Info, ButtonType.Ok);
          return;
        }
      }
      let dependentTransactions = scheduledTeeTimeUnPaidPlayer.map(o => o.dependentTransactionIds);
      if(dependentTransactions?.length > 0 && dependentTransactions.every(p => p != null)){
        let dependTrans = new Map<number, number[]>().set(scheduledTeeTimeUnPaidPlayer[0].playerId, dependentTransactions[0][scheduledTeeTimeUnPaidPlayer[0].playerId]);
        this._retailService.dependentTransactions = dependTrans;
      }
      // put loader
      let transactionPlayerDetails = await Promise.all([this.getTransactionById(transaction?.transactionId ?? transactionId), [],
      this.GetRetailItemByTransactionId(transaction?.transactionId ?? transactionId), this.GetPaidPlayers(teeTimeModelData)
      ]);
      if (transactionPlayerDetails) {
        let paidPlayers = transactionPlayerDetails[3];
        let selectedPlayers = paidPlayers.filter(pp => scheduledTeeTimeUnPaidPlayer[0].playerId == pp.playerId);
        transactionPlayerDetails[2] = _.sortBy(transactionPlayerDetails[2], 'lineNumber').filter(o => o.sourceTypeId == scheduledTeeTimeUnPaidPlayer[0].playerId && o.itemType != RetailItemType.Deposit);
        // Added to remove custom fee transaction when invoking a call from retail 
        transactionPlayerDetails[2].forEach(o => 
        {
          if(o.transactionCustomFee != null)
          o.transactionCustomFee = null
        });
        let transactionItems = transactionPlayerDetails[2];
        selectedPlayers.forEach(s => {
          this.SetPlayerNamesForProduct(s, transactionItems);
        });
        let ExchangeType = {
          [RefundMethod.ReturnWithExchange]: 'item_WE',
          [RefundMethod.ReturnWithoutExchange]: 'item_WOE',
        }
        
        let allRetailItemIds = [...selectedPlayers.map(x => x.cartFeeRetailItemId)]
          .concat([...selectedPlayers.map(x => x.greenFeeRetailItemId)])
          .concat([...selectedPlayers.map(x => x.entryFeeRetailItemId)]);
        let packageItemIds: number[] = [];

        selectedPlayers.forEach(player => { player.otherItems.forEach(p => packageItemIds.push(p.retailItemId)) });
        allRetailItemIds = allRetailItemIds.concat(packageItemIds);
        let distinctItemsIds = [... new Set(allRetailItemIds)];
        distinctItemsIds = distinctItemsIds.filter(id => id > 0);
        let retailItems = await this._paymentBusinessService.GetRetailItemsByIds(distinctItemsIds);
        this.SetRetailItemDetails(selectedPlayers, retailItems);
        if (selectedPlayers && selectedPlayers.length > 0) 
        {
          if(this._retailService.SelectedOutletId == 0)
          this._retailService.SelectedOutletId = (transactionPlayerDetails[2] && transactionPlayerDetails[2].length && 
              transactionPlayerDetails[2][0].outletId > 0) ? transactionPlayerDetails[2][0].outletId : 0;
          this._retailService.isTeeTimeAsPaid = true;
        }
        if (!await this._retailBusinessService.CheckForUserOutletAccessAndDefaultOutlet('', true)) {
          return;
        }
        transactionPlayerDetails[2].forEach(trasnx => 
        {
          trasnx.unitPrice = -(this._localization.currencyToSQLFormat(trasnx.unitPrice));
          trasnx.totalAmount = -(this._localization.currencyToSQLFormat(trasnx.totalAmount));
        });

        transactionPlayerDetails[2] = [...transactionPlayerDetails[2]];  
        this._retailService.payeeId = scheduledTeeTimeUnPaidPlayer[0].playerId;
        this._retailService.sectionName = "RTID"; //Retail transaction Item Detail  
        this._retailService.settleOpenTransaction = false;
        this._retailService.reOpenTransaction = false;
        this._retailService.selectedRetailItem = transactionPlayerDetails[0];
        this._retailService.isReturnWithoutTicket = true;
        this._commonVariable.selectedTransactionRetailProducts =[];
        this._commonVariable.selectedclient = null;
        this._retailService.returnExchangeType = ExchangeType[this._retailService.RefundMethod]
        this._retailService.transactionId = transaction?.transactionId ?? transactionId;
        this._retailService.receiptComment = transactionPlayerDetails[0].receiptComment;
        await this._retailBusinessService.LoadSelectedProducts(transactionPlayerDetails[2], transactionPlayerDetails[1], 'Return');
        this._retailService.selectedTransaction = transactionPlayerDetails[0];
        this._retailService.isFromRefund = true;
        this._retailService.fromTeeTimeNoShow = (action ==  FromAction.NoShow);
        this._commonVariable.fromTeeTimeCancellation = this._retailService.fromTeeTimeCancellation = (action == FromAction.Cancellation || action == FromAction.BulkCancellation);
        this._retailService.canSelectProducts = false;
        this._retailService.RefundTicketTransactionIDs = [];
        this._retailService.SeletedCourseId = teeTimeModelData.course.id;
        this._retailService.SelectedDate = teeTimeModelData.time;
        this._commonVariable.selectedTransactionRetailProducts = [];
        this._commonVariable.canSelectProducts = false;
        this._commonVariable.SelectedOutletId = this._retailService.SelectedOutletId;
        if(selectedProducts != null && selectedProducts.length > 0)
          this._retailService.selectedProducts.push(selectedProducts[0]);
        this._utils.ToggleLoader(false);
        this._retailService.selectedProducts = [...this._retailService.selectedProducts]
        this._retailService.RedirectModule =  this.router.url == '/tee-time/teesheet/graphicalView' ? RedirectToModules.TeeTimeGraphicalView : RedirectToModules.TeeTime;
        this.router.navigate(['/shop/viewshop/order']);
      }
    }
  }

  async ProceedDepositRefund(selectedPlayers, courseId, time,  isfromPopup, cancellationProduct = null, dialogRef = null, fromAction: FromAction = FromAction.None){ //, teeTimeInfo
    this._utils.ToggleLoader(true);
    const scheduledTeeTimeUnPaidPlayers: any[] = selectedPlayers;
    if (scheduledTeeTimeUnPaidPlayers && scheduledTeeTimeUnPaidPlayers.length && this.rateTypes && this.rateTypes.length) {
      let corpIds = _.uniq(scheduledTeeTimeUnPaidPlayers.map(o => o.corpId));
      corpIds = corpIds.filter(o => o != null && o != '');
      if(corpIds && corpIds.length > 1){
        this._utils.showAlert(this._localization.captions.teetime.memberdifferentcorporates,AlertType.Error);
        return false;
      }
      let totalMemberGuaranteedPlayerAssociated = 0;
      scheduledTeeTimeUnPaidPlayers.forEach(player => {
        const isValidMember = this.isValidMember(player.rateTypeId, player.playerCategoryId);
        if (isValidMember &&
          (++totalMemberGuaranteedPlayerAssociated) > 1
        ) {
          return false;
        }
      });
      // Continue booking scenario handling to restrict one member per transaction
      this._retailService.SelectedPlayers.forEach(player => {
        const isValidMember = this.isValidMember(player.rateTypeId, player.playerCategoryId);
        if (isValidMember &&
          (++totalMemberGuaranteedPlayerAssociated) > 1
        ) {
          return false;
        }
      });
      if (totalMemberGuaranteedPlayerAssociated > 1 && !(this.allowMultipleMembersInTransaction() && this._memberService.AllowMultiMemberInTransaction)) {
        this._utils.showAlert(this._localization.captions.teetime.RestrictMultiMemberInATransaction, AlertType.Info, ButtonType.Ok);
        return false;
      }
    }

    await this.setDefaultOutlet(courseId);
    // if (outletId> 0) {
    //   this._retailService.SelectedOutletId = outletId;
    // }
    if (!await this._retailBusinessService.CheckForUserOutletAccessAndDefaultOutlet(dialogRef, isfromPopup)) {
      return;
    }

    let SelectedPlayers = selectedPlayers.filter(t => scheduledTeeTimeUnPaidPlayers.find(x => x.id == t.id));
    let dependentTransactions = SelectedPlayers.map(o => o.dependentTransactionIds);
    if (dependentTransactions?.length > 0 && dependentTransactions.every(p => p != null)) {
      let dependTrans = new Map<number, number[]>().set(selectedPlayers[0].playerId, dependentTransactions[0][selectedPlayers[0].playerId]);
      this._retailService.dependentTransactions = dependTrans;
    }

    if (SelectedPlayers) {
      if (this._retailBusinessService.checkPlayersHavePendingSettlementInDeposit(SelectedPlayers)) {
        return;
      }
      SelectedPlayers.forEach(s => s.deposit = scheduledTeeTimeUnPaidPlayers.find(c => c.id == s.id).deposit);

      const shopItems = await this.getRetailItemByItemType(RetailItemType.Deposit);
      await this.GetDepositRetailItem(SelectedPlayers, time, courseId, [...shopItems],SelectedPlayers);
      this._utils.ToggleLoader(false);
      await this.setSelectedProducts([...shopItems],cancellationProduct,fromAction);
    }
  }

  async GetPaidPlayers(data) {
    return await this._unPaidPlayersService.getScheduledTeeTimePlayers(data.course.id, data.time, PlayerPaymentstatus.booked | PlayerPaymentstatus.unPaid);
  }

  async GetRetailItemByTransactionId(transactionId: number) {
    return await this._paymentBusinessService.GetTransactionDetails(transactionId, GlobalConst.Product.GOLF);
  }

  async checkTransactionIsClosed(transactionId): Promise<boolean> {
    let transactionIsOpen: boolean = false;
    let transaction = await this._paymentBusinessService.GetTransactionsByIds([transactionId]);
    if (transaction[0]) {
      console.log(transaction);
      transactionIsOpen = (transaction[0] && transaction[0].transactionData && transaction[0].transactionData.status == TransactionStatus.CLOSED);
    }
    return transactionIsOpen;
  }

  async getTransactionById(transactionId) {
    return await this._paymentBusinessService.GetTransactionById(transactionId);
  }

  SetPlayerNamesForProduct(player: ScheduledTeeTimeUnPaidPlayer, transactionItems) {
    let GreenFeeItem = transactionItems.find(t => t.itemId == player.greenFeeRetailItemId && !t.playerName);
    if (GreenFeeItem) {
      GreenFeeItem.playerName = player.playerName
    }
    let CartFeeItem = transactionItems.find(t => t.itemId == player.cartFeeRetailItemId && !t.playerName);
    if (CartFeeItem) {
      CartFeeItem.playerName = player.playerName
      let deposit = transactionItems.find(t => t.lineNumber == (CartFeeItem.lineNumber + 1) && !t.playerName);
      if (deposit && player.deposits && player.deposits.some(d => Math.abs(d.amount) == Math.abs(deposit.totalAmount))) {
        deposit.playerName = player.playerName;
      }
    }
    if(player?.scheduledTeeTimePlayerCustomFees?.length > 0)
    {
      player?.scheduledTeeTimePlayerCustomFees.forEach(o => 
        {
          let customFeeItem = transactionItems.find(t => t.itemId == o.linkedItemId && !t.playerName);
          if(customFeeItem != null) 
            customFeeItem.playerName = player?.playerName ?? '';
        })
    }
  }
  SetRetailItemDetails(unPaidPlayers: ScheduledTeeTimeUnPaidPlayer[], retailItems: any[]) {
    if (unPaidPlayers.length > 0 && retailItems.length > 0) {
      unPaidPlayers.forEach(player => {
        if (player.cartFeeRetailItemId != 0)
          player.cartFeeRetailItem = retailItems.find(x => x.id == player.cartFeeRetailItemId);
        if (player.greenFeeRetailItemId != 0)
          player.greenFeeRetailItem = retailItems.find(x => x.id == player.greenFeeRetailItemId);
        if (player.entryFeeRetailItemId != 0)
          player.entryFeeRetailItem = retailItems.find(x => x.id == player.entryFeeRetailItemId);
        player.otherItems.forEach(item => {
          if (item.retailItemId != 0) {
            item.retailItemDetail = retailItems.find(x => x.id == item.retailItemId);
          }
        });
      });
    }
  }

  isValidMember(rateTypeId, playerCategoryId): boolean {
    return (rateTypeId > 0 && (this.rateTypes.find(y => y.id == rateTypeId) || ({} as any)).guaranteeType == GuaranteeType.Member)
      || (playerCategoryId === 3);
  }

  public async allowMultipleMembersInTransaction() {
    const func: { [key: string]: boolean } = await this.func.getRetailFunctionality();
    return func && func.allowMultipleMembersInTransaction ? func.allowMultipleMembersInTransaction : false;
  }

  async setDefaultOutlet(courseId: number) {
    //Setting the outlet based on the teetime default
    await this.GeDefaultConfig();
    if (this.defaultConfig.configValue.defaultOutletType == DefaultOutletType.course) {
      let course = await this._courseDataService.getCourse(courseId);
      if (this._retailService.SelectedOutletId > 0 && this._retailService.SelectedOutletId != course.defaultOutletId) {
        //Resetting the SelectedTerminal since there is no terminal selection at course level
        this._commonVariable.SelectedTerminalId = this._retailService.SelectedTerminalId = 0;
      }
      this._commonVariable.SelectedOutletId = this._retailService.SelectedOutletId = course.defaultOutletId;
      this._retailService.isTeeTimeAsPaid = true;

    } else {
      await this._defaultUserConfig.GetDefaultUserConfiguration(this._localization.GetPropertyInfo("UserId"));

      this._retailService.isTeeTimeAsPaid = false;
    }
    this._retailBusinessService.ValidateSelectedTerminalExistsInSelectedOutlet();
  }
  async GeDefaultConfig() {
    this.defaultConfig = await this._settingsDataService.getSettings<API.DefaultsSettingConfig>(SettingModule.SystemSetup, SettingScreen.Defaults);
  }
  public async getRetailItemByItemType(depositItemType: any) {
    return await this._paymentBusinessService.GetRetailItemByItemType(depositItemType);
  }
  async GetDepositRetailItem(SelectedPlayers: ScheduledTeeTimeUnPaidPlayer[], time, courseId, shopItems: any, scheduledTeeTimeUnPaidPlayer) 
  {
    if (!await this._retailBusinessService.CheckDepositItemsAreFromSelectedOutlet(shopItems)) {
      return;
    }
    let depositAll: Deposit[] = [];
    if (SelectedPlayers) {
      SelectedPlayers.forEach(player => {
        depositAll.push({
          playerId: player.playerId,
          amount: -(player.deposit),
          //Since user can select only one deposit at a time, Assigning the first deposit TransactionId
          depositTransactionId: player.deposits[0].depositTransactionId
        })
      })
    }

    this._retailService.depositArray = [];
    this._retailService.SelectedPlayers = scheduledTeeTimeUnPaidPlayer;
    this._retailService.payeeId = this._retailBusinessService.SetP1PlayerId();
    this._retailService.SelectedDate = time;
    this._retailService.SeletedCourseId = courseId;
    this._retailService.depositArray = this.MapToDepositModel(depositAll);
    // should be set to true, since on the order summary screen it would be used to show data in negative value
    this._retailService.isFromDeposit = true;
  }

  MapToDepositModel(playerDeposit: Deposit[]) {
    let result: DepositEventModel[] = [];
    playerDeposit.forEach((x) => {
      result.push({
        amount: x.amount,
        typeId: x.playerId,
        depositTransactionId: x.depositTransactionId,
        gratuity: 0,
        isVoided: false,
        id: 0,
        refundAmount: 0,
        refundTransactionId: 0,
        serviceCharge: 0
      });
    });
    return result;
  }
  async setSelectedProducts(shopItemData: any,noShowCancellationProduct,fromAction) {
    shopItemData = shopItemData.filter(x => x.outletItem.some(x => x.outletId == this._retailService.SelectedOutletId));

    if (shopItemData != null && this._retailService.depositArray && this._retailService.depositArray.length > 0) {
      this._retailService.selectedProducts = [];
      let playerIndex = 0;
      let lineNumber = 1;
      this._retailService.depositArray.forEach(deposit => {
        this._retailService.selectedProducts.push({
          ItemId: shopItemData[0].retailItemDetail.id,
          ExternalPOSItemId: shopItemData[0].retailItemDetail.externalPOSId,
          ItemDescription: shopItemData[0].retailItemDetail.itemDescription,
          ItemType: shopItemData[0].retailItemDetail.itemType,
          ProductName: shopItemData[0].retailItemDetail.itemDescription,
          ServiceId: 0,
          ProductPrice: deposit.amount,
          SalesPrice: deposit.amount,
          Noofitems: 1,
          Discount: 0,
          DiscountPercentage: 0,
          DiscountTypeId: 0,
          category: shopItemData[0].retailItemDetail.category,
          isEditDisabled: true,
          isModificationRestricted: true,
          isGroupingKey: shopItemData[0].retailItemDetail.isGroupingKey,
          isPackagedItem: shopItemData[0].retailItemDetail.isPackagedItem,
          PackageItemId: 0,
          MultiPack: shopItemData[0].retailItemDetail.isMultiPack,
          ClientMultiPackId: 0,
          PackageGroupId: 0,
          isOpenPricedItem: true,
          playerName: this._retailService.SelectedPlayers[playerIndex].playerName,
          id: this._retailService.SelectedPlayers[playerIndex].playerId,
          LineNumber: lineNumber,
          payeeId: this._retailService.SelectedPlayers[playerIndex].playerId,
          costPrice: shopItemData[0].retailItemDetail.costPrice,
          marginPercentage: shopItemData[0].retailItemDetail.marginPercentage,
          isRequireComments: shopItemData[0].retailItemDetail.isRequireComments,
          isRequestName: shopItemData[0].retailItemDetail.isRequestName,
          itemComments: '',
          isDepositOnly: true,
          allowEarn: shopItemData[0].retailItemDetail.allowEarn,
          discountComments: '',
          discountReason: 0
        });
        playerIndex++;
        lineNumber++;
      });
      if(noShowCancellationProduct != null && noShowCancellationProduct.length > 0 && noShowCancellationProduct.every(o => o != null))
      {
        noShowCancellationProduct[0].LineNumber = lineNumber;
        noShowCancellationProduct[0].linkedItemLineNumber = lineNumber;
        this._retailService.selectedProducts.push(noShowCancellationProduct[0]);
      }
      // should be set to true, since at order summary screen it would be used to show data in negative value 
      this._retailService.isFromDeposit = true;
      this._commonVariable.fromTeeTimeCancellation = this._retailService.fromTeeTimeCancellation = (fromAction == FromAction.Cancellation || fromAction == FromAction.BulkCancellation);
      this._retailService.fromTeeTimeNoShow = (fromAction == FromAction.NoShow);
      this._retailService.isFromEditTeeTime = false;
      this._retailService.RedirectModule =  this.router.url == '/tee-time/teesheet/graphicalView' ? RedirectToModules.TeeTimeGraphicalView : RedirectToModules.TeeTime;
      this.router.navigate(['/shop/viewshop/order']);
    }
  }

  mergeMaps(mapArray: Map<number, number[]>[]): Map<number, number[]> 
  {
    const mergedMap = new Map<number, number[]>();
    mapArray?.forEach(map => {
      mergedMap[Object.keys(map)[0]] = Object.values(map)[0];
    });
    return mergedMap;
  }


}
