import { Injectable } from '@angular/core';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Injectable()
export class PaymentInformationService {

  constructor(private _Localization: GolfLocalization) { }

  getCaptions() {
    return this._Localization.captions.guest.payment
  }

  getAvailableDevices() {
    return [
      { name: 'Master', handle: 'Master' },
      { name: 'Visa', handle: 'Visa' }
    ];
  }

  connectToDevice(device) {
    console.log('Connect to Device', device);
  }
}
