import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SaveButtonObj } from 'src/app/common/Models/ag-models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { PlayerRateDetails, PlayersNewRate } from 'src/app/shared/models/teesheet.api.models';

@Component({
  selector: 'app-player-rate-modal',
  templateUrl: './player-rate-modal.component.html',
  styleUrls: ['./player-rate-modal.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class PlayerRateModalComponent implements OnInit {
  buttonObj: SaveButtonObj;
  captions: any;
  ratePlayersForm: UntypedFormGroup;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  playerRateDetails: PlayerRateDetails[];

  constructor(private fb: UntypedFormBuilder, public dialogRef: MatDialogRef<PlayerRateModalComponent>, @Inject(MAT_DIALOG_DATA) public dialogData: any, private localization: GolfLocalization) {
    this.playerRateDetails = this.dialogData && this.dialogData.playerRateDetails;
  }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.captions = this.localization.captions;
    this.buttonObj = {
      customSaveText: this.localization.captions.common.Save,
      customCancelText: this.localization.captions.common.Cancel,
      disabled: true,
      isEdit: false
    };
    this.ratePlayersForm = this.fb.group({});
    if (this.playerRateDetails && this.playerRateDetails.length > 0) {
      this.playerRateDetails.forEach((x, index) => {
        this.ratePlayersForm.addControl('player_' + index, new UntypedFormControl('', [Validators.required]))
      })
    }
    this.ratePlayersForm.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      if (this.ratePlayersForm.valid && this.ratePlayersForm.dirty) {
        this.buttonObj.disabled = false;
        this.buttonObj = { ...this.buttonObj };
      }
    });
  }
  savePlayerRate() {
    let returnRateArray: PlayersNewRate[] = [];
    this.playerRateDetails.forEach((player, index) => {
      let rateFormValue = this.ratePlayersForm.get('player_'+ index).value;
      let playerNewRate: PlayersNewRate = {
        playerId : player.playerId,
        playerTypeId : player.playerTypeId,
        rateTypeId : rateFormValue.rateTypeId,
        greenFee : rateFormValue.greenFee,
        cartFee : rateFormValue.cartFee
      }
      returnRateArray.push(playerNewRate);
    });
    console.log("RateFormValue",returnRateArray);
    this.dialogRef.close(returnRateArray);
  }

  cancelPlayerRate(e) {
    this.dialogRef.close([]);
  }

  close(e) {
    this.dialogRef.close([]);
  }

}
