import { Injectable } from '@angular/core';
import { TeeTimesActionService } from 'src/app/shared/data-services/golfschedule/teeTimesAction.data.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { SqueezeTeeTimeData, SqueezeAPIData, PlayerTypeRateTypeChangeConfig } from '../tee-time-model';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';
import { GolfUtilities, stringFormat } from 'src/app/shared/utilities/golf-utilities';
import { TeeSheetSkeletonData, TeeSheetPlayerDetails, TeeSheetModications, ScheduleStatus, ScheduleType, PlayerDaysOutResut, ScheduledPlayer, RateTypeDaysOutResult } from 'src/app/shared/models/teesheet.form.models';
import { CopyMoveResult, TeeTimeAction, RuleResult, ValidationResult, ReinstateResult, PlayersNewRate, PlayerRateDetails, DestinationSlotRate, PlayersCaddyInfo } from 'src/app/shared/models/teesheet.api.models';
import * as _ from 'lodash';
import { AlertType, ButtonType, AlertAction, TeeTimeAllocationBlock, OVERRIDE_ALERT_WIDTH, OverlappingSlotsForGuest } from 'src/app/shared/shared-models';
import { PlayerTeeTimeSlot } from 'src/app/tee-time-actions/move-player-modal/move-player-modal.model';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { TeeTimeModel, TeeTimeTempHold, OverrideDetails, OverrideType, TeeTimeTabs } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { CacheConstants, OVERRIDEPLAYERTYPEANDRATETYPEDAYSOUTERROR, TempHoldStatus } from 'src/app/shared/global.constant';
import { TeeTimeService } from 'src/app/tee-time-actions/teetime/tee-time.service';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { PlayerPaymentstatus, TeeTimeAllocationBlockEvent } from '../search/search-model';
import { ReinstatePlayer } from 'src/app/tee-time-actions/reinstate-modal/reinstate-player-model';
import { allocationBlockPlayerTypePermission } from 'src/app/settings/user-setup/booking-permission/booking-permission.model';
import { AllocationBlockWithPlayerType } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import { ReplaySubject , BehaviorSubject} from 'rxjs';
import { PlayerTypeService } from 'src/app/shared/data-services/golfschedule/playertype.data.service';
import { takeUntil } from 'rxjs/operators';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { UserAccessModel } from 'src/app/shared/data-services/authentication/useraccess-model.model';
import { CopyBulkTeeTimeConfig } from 'src/app/tee-time-actions/copy-player-modal/copy-player-modal.model';
import { API } from 'src/app/settings/golf-setup/code-linking/code-link-modal';
import { RateSetupData } from 'src/app/settings/rate-setup/rate-setup.model';
import { CodeLinkingDataService } from 'src/app/shared/data-services/golfschedule/codelinking.data.service';
import { RateSetupDataService } from 'src/app/shared/data-services/golfschedule/rate-setup.data.service';
import { PlayerRateModalComponent } from 'src/app/tee-time-actions/player-rate-modal/player-rate-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { SystemdefaultsInformationService } from 'src/app/core/services/systemdefaults-information.service';
import { CaddyTypeRequirement } from 'src/app/ag-common/constants';
import { RateTypeDataService } from 'src/app/shared/data-services/golfschedule/ratetype.data.service';
import { ConsentManagementComponent } from '../../common/consent-management/consent-management.component';
import { PolicyCategoryType } from '../../common/shared/shared.modal';
import { ButtonTypes } from '../../common/enums/shared-enums';
import { PlayerProfileDataService } from '../../shared/data-services/golfmanagement/PlayerProfile.data.services';
import { cloneDeep } from 'lodash';
import { DynamicPricingPlayerFee, PlayerTypes as PlayerType, RateType} from 'src/app/tee-time-actions/teetime/player-details/player-info.model';
import { OverLappingPlayerDetailsComponent } from 'src/app/shared/components/tee-grid/tee-player-details/over-lapping-player-details/over-lapping-player-details.component';
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { PlayerRetailItem } from 'src/app/retail/shop/shop.modals';
import { RetailItemType } from 'src/app/retail/retail.modals';
import { GolfScheduleCommunication } from 'src/app/shared/communication/services/golfschedule.service';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { promise } from 'protractor';
import { RentalSourceType } from 'src/app/retail/shared/service/common-variables.service';
import { ScheduledTeeTimePlayerRetailItem } from 'src/app/tee-time-actions/edit-retail-item/edit-retail-item-model';

@Injectable()
export class TeeTimesActionBusiness {
  playerTypeOverride: number;
  rateTypeOverride: number;
  playerDaysoutErrorMessage: any;
  rateDaysoutErrorMessage: any;
  copyBookingId: BehaviorSubject<string> = new BehaviorSubject<string>(null); 
  isPlayerTypeRateTypeChangeEnable:boolean = false;
  isAllowPlayerTypeRateTypeChange:boolean = false;
  constructor(private _teeTimesActionService: TeeTimesActionService,
    private _localization: GolfLocalization,
    private _userAccessBusiness: UserAccessBusiness,
    private _propertyInformation: GolfPropertyInformation,
    private _utils: GolfUtilities,  
    private _teeTimeService: TeeTimeService,
    private _retailService: RetailSharedVariableService,
    private _playerTypeService: PlayerTypeService,
    private _rateTypeService: RateTypeDataService,
    public quickLoginUtils: QuickLoginUtilities,
    private _codeLinkingDataService: CodeLinkingDataService,
    private _RateSetupDataService: RateSetupDataService,
    private _SystemdefaultsInformationService: SystemdefaultsInformationService,
    public _dialog: MatDialog,
    private _playerProfileDataService: PlayerProfileDataService,
    private _golfSchedule: GolfScheduleCommunication,
    private rPropertyInformation: RetailPropertyInformation
  ) {
  }
  public mapToModalFormData(data: any): SqueezeTeeTimeData {
    return {
      id: 0,
      courseId: data.course.id,
      squeezeDateTime: data.unmodifiedSlotData.time,
      startTime: this.addMinutes(data.unmodifiedSlotData.time),
      holes: data.unmodifiedSlotData.hole
    }
  }

  private addMinutes(scheduledDate: any): string {
    let addedDateTime = this._localization.AddMinutes(this._localization.getDate(scheduledDate), 1);
    return this._localization.LocalizeTime(addedDateTime);
  }

  public mapToAPIModel(squeezeData: SqueezeTeeTimeData): SqueezeAPIData {
    return {
      id: squeezeData.id,
      holes: squeezeData.holes,
      squeezeDateTime: this.setDateAndTime(squeezeData.squeezeDateTime, squeezeData.startTime),
      courseId: squeezeData.courseId
    };
  }

  private setDateAndTime(date: any, time: any): string {
    var dateTime = this._localization.AddTimeToDate(date, this.dummyDateForSelectedTime(time));
    return this._localization.ConvertDateToISODateTime(dateTime);
  }

  private dummyDateForSelectedTime(time: string): any {
    return `2000/01/01 ${time}`;
  }

  public async validateBreakPointAccesses(breakPointNumbers: number[]) {
    return await this._userAccessBusiness.getUserAccesses(breakPointNumbers);

  }

  public async validateBreakPointAccess(breakPointNumber: number, showPopup: boolean = true): Promise<any> {
    return await this._userAccessBusiness.getUserAccess(breakPointNumber, showPopup);

  }
  async ShowBreakpointError(breakPointNumber, result): Promise<any> {
    if (!result.isAllow && !result.isViewOnly) {
      let bpMessage = this._localization.captions.breakpoint[breakPointNumber];
      this._userAccessBusiness.showBreakPointPopup(bpMessage);
    }
    return Promise.resolve(result);
  }

  public async isPlayerTypeRateTypeChangeEnabled()
  {
    return await this.validateBreakPointAccess(UserAccessBreakPoints.UPADTEPLAYERRATETYPEDURINGPAY, false);
  }

  public async getPlayerTypeRateTypeChangeConfig(selectedDate: Date): Promise<PlayerTypeRateTypeChangeConfig>
  {
    let quickIdConfig = this.rPropertyInformation.getQuickIdConfig;
    let golfSetting = JSON.parse(sessionStorage.getItem(CacheConstants.GolfSetting));
    let teeTimeSetting = JSON.parse(sessionStorage.getItem(CacheConstants.GolfSetting));
    let breakPoints = await this.validateBreakPointAccesses([UserAccessBreakPoints.BOOKTEETIME, UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT, UserAccessBreakPoints.OVERRIDERATETYPEDAYSOUT]);
    return {
      isUserDaysOut : this.validateUserDaysOut(selectedDate),
      isQuickId : quickIdConfig && quickIdConfig.teeTimeEdit ? true : false,
      isAllowEditTeeTime : breakPoints && breakPoints[0].isAllow,
      overridePlayerTypeDaysoutUserAccess : breakPoints && breakPoints[1].isAllow,
      overrideRateTypeDaysoutUserAccess : breakPoints && breakPoints[2].isAllow,
      isDynamicPricingEnabled : golfSetting && golfSetting.enableDynamicPricing,
      isIncludePlayersAcrossCoursesForCheckIn : teeTimeSetting && teeTimeSetting.includePlayersAcrossCoursesForCheckIn,
      isRequirePlayerType: teeTimeSetting && teeTimeSetting.requirePlayerType,
      isRequireRateType: teeTimeSetting && teeTimeSetting.requireRateType
    } as PlayerTypeRateTypeChangeConfig
  }

  public playerTypeRateTypeChangeDisplayValidation(playerTypeRateTypeChangeConfig: PlayerTypeRateTypeChangeConfig)
  {
      return (playerTypeRateTypeChangeConfig.isUserDaysOut && playerTypeRateTypeChangeConfig.isAllowEditTeeTime);
  }

  public async holdTeeTime(courseId: number, scheduledDateTime: any, holeNumber: string, originalHoleNumber: string, teeSheetCallBack?: any): Promise<void> { // to do need to change date type
    let convertedDateTime = this._localization.ConvertDateToISODateTime(scheduledDateTime)
    await this._teeTimesActionService.holdTeeTime(courseId, convertedDateTime, holeNumber, originalHoleNumber).then(() => {
      if (teeSheetCallBack) { teeSheetCallBack(true); return; }


    });
  }

  public async noShowTeeTimePlayer(scheduleId: number, playerId: number): Promise<boolean> {
    return this._teeTimesActionService.noShowTeeTimePlayer(scheduleId, playerId);
  }

  public async reBookTeeTimePlayer(scheduleId: number, playerId: number): Promise<boolean> {
    return this._teeTimesActionService.reBookTeeTimePlayer(scheduleId, playerId);
  }

  public async releaseTeeTime(courseId: number, scheduledDateTime: Date, holeNumber: string, teeSheetCallBack?: any): Promise<void> {
    let convertedDateTime = this._localization.ConvertDateToISODateTime(scheduledDateTime);
    await this._teeTimesActionService.releaseTeeTime(courseId, convertedDateTime, holeNumber).then(() => {
      teeSheetCallBack(true);
    });
  }

  public async squeezeTeeTime(squeezeTeeTimeData: SqueezeTeeTimeData, graphicalViewTeeSheetId?: number, teeSheetCallBack?: any): Promise<void> {
    let squeezeAPIData: SqueezeAPIData = this.mapToAPIModel(squeezeTeeTimeData);
    await this._teeTimesActionService.squeezeTeeTime(squeezeAPIData).then(() => {
      teeSheetCallBack(true);
      let teeTimeAllocationBlock = {
        id: 0,
        courseId: squeezeAPIData.courseId,
        allocationBlockId: 0,
        allocationDateTime: squeezeAPIData.squeezeDateTime,
        holeNumber: squeezeAPIData.holes,
        isDefault: true,
        originalHoleNumber: squeezeAPIData.holes // to do
      };
      let teeTimeAllocationBlocklst = [];
      teeTimeAllocationBlocklst.push(teeTimeAllocationBlock);
      this._teeTimesActionService.saveTeeTimeAllocationBlock(teeTimeAllocationBlocklst, TeeTimeAllocationBlockEvent.FromTeeTime);
    });
  }

  public mapPlayerTeeTimeSlots(modifiedTeeSlots: TeeSheetSkeletonData[]): PlayerTeeTimeSlot[] {
    let mappedPlayerDetail: PlayerTeeTimeSlot[] = [];

    modifiedTeeSlots.forEach(slot => {
      const value = slot.playerDetail.map(o => {
        return {
          courseId: slot.course.id,
          scheduledDateTime: slot.time,
          playerId: o.playerId,
          playerSlotPosition: o.playPos,
          holeNumber: slot.hole,
          destinationScheduledTeeTimeId: slot.scheduledTeeTimeId ? slot.scheduledTeeTimeId : 0,
          playerTypeId: o.playerTypeId ? o.playerTypeId : 0,
          rateTypeId: 0,
          multiPackTransactionDetailId: o.multiPackTransactionDetailId,
          multiPackGreenFeeValue: o.multiPackGreenFeeValue,
          multiPackCartFeeValue: o.multiPackCartFeeValue
        } as PlayerTeeTimeSlot;
      }
      );
      mappedPlayerDetail = mappedPlayerDetail.concat(value);
    });
    return mappedPlayerDetail;
  }
  async SaveTeeTimeAllocation(sourceSlot: TeeSheetSkeletonData) {
    let teeTimeAllocationBlock = new TeeTimeAllocationBlock();
    teeTimeAllocationBlock.id = sourceSlot.teeGridSlotId;
    teeTimeAllocationBlock.viewedByStarter = false;
    teeTimeAllocationBlock.courseId = sourceSlot.course?.id;
    teeTimeAllocationBlock.originalHoleNumber = sourceSlot.originalHoleNumber;
    teeTimeAllocationBlock.allocationBlockId = sourceSlot.allocation?.id;
    teeTimeAllocationBlock.allocationDateTime = sourceSlot.allocationDateTime;

    await this._teeTimesActionService.saveTeeTimeAllocationBlock([teeTimeAllocationBlock], TeeTimeAllocationBlockEvent.FromTeeTime);

  }
  async copyormoveTeeTime(sourceSlot: TeeSheetSkeletonData, modifiedSlots: PlayerTeeTimeSlot[], bulkModifiedSlots: CopyBulkTeeTimeConfig, teeTimeAction: TeeTimeAction): Promise<boolean> {
    let resp$: Promise<CopyMoveResult[]>;
    if (teeTimeAction == TeeTimeAction.bulkMove) {
      resp$ = this._teeTimesActionService.bulkMoveTeeTime(modifiedSlots, teeTimeAction);
    }
    else if (teeTimeAction == TeeTimeAction.bulkCopy) {
      resp$ = this._teeTimesActionService.bulkCopyTeeTime(bulkModifiedSlots, teeTimeAction);
    }
    else {
      resp$ = this._teeTimesActionService.copyMoveTeeTime(sourceSlot.scheduledTeeTimeId, modifiedSlots, teeTimeAction);
    }
    const resp: CopyMoveResult[] = await resp$;

    const FailedRules = resp.filter(r => !r.result);
    if (FailedRules.length > 0) {
      this.showRuleErrors(FailedRules[0].ruleResults, teeTimeAction);
      return false;
    }
    else {
      let playerId = sourceSlot?.playerDetail?.
        map(x => { return modifiedSlots?.some(y => y.playerId == x.playerId) });
      const movellAllPlayer = playerId?.every(x => x);
      if ((sourceSlot?.viewedByStarter && teeTimeAction != TeeTimeAction.bulkCopy
        && teeTimeAction != TeeTimeAction.copyToMany && teeTimeAction != TeeTimeAction.copy)
        && (sourceSlot?.playerDetail.length == 0
          || movellAllPlayer)) {
        await this.SaveTeeTimeAllocation(sourceSlot);
      }
      this.copyBookingId.next(resp[0].bookingId);
      return true;
    }
  }

  public draggedPlayers: PlayerTeeTimeSlot[] = [];

  public async validateTeeTimeAction(sourceSlot: TeeSheetSkeletonData, teeTimeAction: TeeTimeAction, draggedPlayers: PlayerTeeTimeSlot[]): Promise<ValidationResult<CopyMoveResult[]>> {

    this.draggedPlayers = [];
    let validationResult: any;
    if (teeTimeAction === TeeTimeAction.bulkCopy || teeTimeAction === TeeTimeAction.bulkMove) {
      teeTimeAction = (teeTimeAction === TeeTimeAction.bulkMove ? TeeTimeAction.move : TeeTimeAction.copy);
      validationResult = await this._teeTimesActionService.BulkTeeTimeActionValidate<CopyMoveResult[]>(teeTimeAction, draggedPlayers);
    }
    else {
      validationResult = await this._teeTimesActionService.validateTeeTimeAction<CopyMoveResult[]>(teeTimeAction, sourceSlot.scheduledTeeTimeId, draggedPlayers);
    }
    //Skip CaddyTypeRequirement as we added new popup to select it.
    let caddyPopupSwitch: boolean = this._SystemdefaultsInformationService.GetEditCaddyInfoOnMoveCopySwitch();
    let FailedRules;
    if (caddyPopupSwitch) {
      FailedRules = validationResult.result.filter(r => !r.result && r.ruleResults.some(rr => rr.ruleName != CaddyTypeRequirement && rr.isValid == false));
    }
    else {
      FailedRules = validationResult.result.filter(r => !r.result);
    }
    if (FailedRules.length > 0) {
      this.showRuleErrors(FailedRules[0].ruleResults, teeTimeAction);
    }

    return validationResult;
  }

  public async validateReinstateAction(teeTimeAction: TeeTimeAction, draggedPlayers: PlayerTeeTimeSlot[]): Promise<ValidationResult<ReinstateResult[]>> {
    this.draggedPlayers = [];
    let validationResult: any;
    validationResult = await this._teeTimesActionService.validateTeeTimeAction<ReinstateResult[]>(teeTimeAction, 0, draggedPlayers);
    const FailedRules = validationResult.result.filter(r => !r.result);
    if (FailedRules.length > 0) {
      this.showRuleErrors(FailedRules[0].ruleResults, teeTimeAction);
    }

    return validationResult;
  }

  public showRuleErrors(ruleResults: RuleResult[], teeTimeaction: TeeTimeAction): void {
    let error: string;
    if (ruleResults) {
      let ruleResult = ruleResults.filter(x => !x.isValid);
      if (ruleResult.length > 0) {
        let failedRuleResult = ruleResult[0];
        error = this._localization.getError(failedRuleResult.errorCodes[0]);
        if (failedRuleResult.errorCodes[0] == 30401) // players day out rule
        {
          error = this._localization.getError(failedRuleResult.data.DaysOutData[0].errorCode);
          error = error.interpolate({ date: this._localization.localizeDisplayDate(failedRuleResult.data.DaysOutData[0].date) });
        }
        else if (failedRuleResult.errorCodes[0] == 11010 || failedRuleResult.errorCodes[0] == 11015) {
          error = error.interpolate({ user: failedRuleResult.data.UserName ? failedRuleResult.data.UserName : "" });
        }
          this._utils.showError(error);
      }
    }
    else {
      const error: string = this._localization.getError(-2);
      this._utils.showError(error);
    }
  }

  public getPlayers(): PlayerTeeTimeSlot[] {
    return this.draggedPlayers;
  }

  public showRuleError(ruleResult: RuleResult, course: string, scheduledDate: string, player: string): void {
    let error: string;
    if (ruleResult) {
      error = this._localization.getError(ruleResult.errorCodes[0]);
      if (ruleResult.errorCodes[0] == 30400) {
        error = error.interpolate({ course: course, scheduledDate: this._localization.convertMomentDateToSQLDate(scheduledDate), player: player });
      }
      else if (ruleResult.errorCodes[0] == 30401) {
        error = this._localization.getError(ruleResult.data.DaysOutData[0].errorCode);
        error = error.interpolate({ date: this._localization.localizeDisplayDate(ruleResult.data.DaysOutData[0].date) });
      }
      this._utils.showError(error);
    }
    else {
      const error: string = this._localization.getError(-2);
      this._utils.showError(error);
    }
  }

  public async frostTeetime(courseId: number, scheduledDateTime: Date, holeNumber: string, teeSheetCallBack: any): Promise<void> {
    let convertedDateTime = this._localization.ConvertDateToISODateTime(scheduledDateTime);
    await this._teeTimesActionService.frostDelayTeeTime(courseId, convertedDateTime, holeNumber).then(() => {
      teeSheetCallBack(true);
    });
  }

  frostDelayLogic(arg, teeSheetCallBack?): void {
    this._utils.showAlert(this._localization.captions.settings.addFrostDelay, AlertType.Warning, ButtonType.ContinueCancel, (res) => {
      if (res === AlertAction.CONTINUE) {
        return this.frostTeetime(arg.course.id, arg.time, arg.originalHoleNumber, teeSheetCallBack)
      }
    });
  }

  public saveTeeTimeAction(sourceSlot: TeeSheetSkeletonData, validTeeTimes: PlayerTeeTimeSlot[], teeTimeAction: TeeTimeAction): Promise<boolean> {
    return this.copyormoveTeeTime(sourceSlot, validTeeTimes, null, teeTimeAction).finally(() => { this.draggedPlayers = [] });
  }
  public saveBulkCopyTeeTime(sourceSlot: TeeSheetSkeletonData, validTeeTimes: CopyBulkTeeTimeConfig, teeTimeAction: TeeTimeAction): Promise<boolean> {
    return this.copyormoveTeeTime(sourceSlot, validTeeTimes.bulkPlayerTeeTimeSlots, validTeeTimes, teeTimeAction).finally(() => { this.draggedPlayers = [] });
  }
  public async validateTeeSheetDragAndDrop(sourceSlot: TeeSheetSkeletonData, teeTimeAction: TeeTimeAction, draggedPlayers: PlayerTeeTimeSlot[]): Promise<ValidationResult<CopyMoveResult[]>> {
    let validationResult: any;
    if (sourceSlot) {
      validationResult = await this._teeTimesActionService.validateTeeTimeAction<CopyMoveResult[]>(teeTimeAction, sourceSlot.scheduledTeeTimeId, draggedPlayers);
    }
    return validationResult;
  }

  public generateModifiedPlayersTeeSlots(initialTeeSlots: TeeSheetSkeletonData[], modifiedTeeSlots: TeeSheetSkeletonData[],playerRetailItems : PlayerRetailItem[] = []): PlayerTeeTimeSlot[] {
    let modifiedPlayers: TeeSheetPlayerDetails[] = [];
    let modifiedPlayerTeeTimeSlots: PlayerTeeTimeSlot[] = [];
    let isSqueezed: boolean = false;
    let modifiedPlayersFromInitial: TeeSheetPlayerDetails[];

    modifiedTeeSlots.forEach((o, index) => {
      const InitialSlotindex = initialTeeSlots.findIndex(x => x.id == o.id);
      if (InitialSlotindex >= 0) {
        modifiedPlayersFromInitial = _.differenceWith(o.playerDetail, initialTeeSlots[InitialSlotindex].playerDetail, this.pullModifiedPlayers.bind(this));
        isSqueezed = false;
      }
      else {
        modifiedPlayersFromInitial = o.playerDetail;
        isSqueezed = true;
      }

      modifiedPlayers = modifiedPlayersFromInitial.concat(modifiedPlayers);
      modifiedPlayerTeeTimeSlots = modifiedPlayerTeeTimeSlots.concat(this.mapToAPIPlayerTeeTimeSlots(o, modifiedPlayersFromInitial, isSqueezed, playerRetailItems));
    }
    );

    return modifiedPlayerTeeTimeSlots;
  }

  public checkSqueeze(squeezedSlots: TeeSheetSkeletonData[], element: TeeSheetSkeletonData): boolean {
    let isSqueezed: boolean = false;
    if (squeezedSlots && squeezedSlots.length > 0) {
      const newSlot = squeezedSlots.filter(x => x.time === element.time && x.course.id === element.course.id &&
        x.hole === element.hole);
      if (newSlot && newSlot.length > 0) {
        isSqueezed = true;
      }
    }
    return isSqueezed;
  }

  public mapPlayerData(dragDropObj, draggedPlayersList: TeeSheetPlayerDetails[],playerRetailItems : PlayerRetailItem[] = [] ): PlayerTeeTimeSlot[] {
    let overrideDetails: OverrideDetails[] = [];
    var playerTypeOverrideDetail: OverrideDetails = {
      id: 0,
      scheduledTeeTimePlayerId: 0,
      overrideType: OverrideType.playerType,
      userId: 0
    };
    overrideDetails.push(playerTypeOverrideDetail);
    return draggedPlayersList.map(o => {
      return {
        courseId: dragDropObj.course.id,
        scheduledDateTime: dragDropObj.time,
        playerId: o.playerId,
        playerSlotPosition: o.playPos,
        holeNumber: dragDropObj.hole,
        destinationScheduledTeeTimeId: dragDropObj.scheduledTeeTimeId ? dragDropObj.scheduledTeeTimeId : 0,
        isValid: true,
        isDestinationSlotRateChanged: false,
        sourceScheduledTeeTimeId: o.sourceScheduledTeeTimeId,
        isPaid: o.isPaidPlayer,
        overrideDetails: overrideDetails,
        playerTypeId: o.playerTypeId,
        rateTypeId: 0,
        useNewRateType: false,
        firstName: o.firstName,
        lastName: o.lastName,
        originalHoleNumber: dragDropObj.originalHoleNumber,
        holes: o.holes,
        confirmationNumber: o.confirmationNumber,
        caddyId: o.caddyId,
        caddyTypeId: o.caddyTypeId,
        multiPackTransactionDetailId: o.multiPackTransactionDetailId,
        multiPackGreenFeeValue: o.multiPackGreenFeeValue,
        multiPackCartFeeValue: o.multiPackCartFeeValue,
        playerLinkId: o.linkingId,
        retailItems : playerRetailItems && playerRetailItems.length &&  playerRetailItems.filter(x=>x.scheduledTeeTimePlayerId == o.scheduledTeeTimePlayerId) != null ? 
        this.mapRetailItem(playerRetailItems,o.scheduledTeeTimePlayerId) : null
      } as PlayerTeeTimeSlot;
    });
  }

  private mapRetailItem(retailItems:PlayerRetailItem[],scheduledTeeTimePlayerId  :Number ) : PlayerRetailItem{

    var retailItemForPlayer = retailItems?.find(x=>x.scheduledTeeTimePlayerId == scheduledTeeTimePlayerId &&
                                             x.retailItemType == RetailItemType.RetailItemRetailPOSOnly)?.retailItems;
    if(retailItemForPlayer != null ){
    return {
      id: 0,
      scheduledTeeTimePlayerId: 0,
      retailItems: retailItemForPlayer,
      retailItemType: RetailItemType.RetailItemRetailPOSOnly
    }
  }
  else 
  return null;
  }

  private pullModifiedPlayers(modifiedTeeTime: TeeSheetPlayerDetails, initialTeeTime: TeeSheetPlayerDetails): boolean {
    return modifiedTeeTime.playerId == initialTeeTime.playerId && modifiedTeeTime.playPos == initialTeeTime.playPos;
  }

  public mapToAPIPlayerTeeTimeSlots(modifiedTeeSlot: TeeSheetSkeletonData, playerInfo: TeeSheetPlayerDetails[], isSqueezed: boolean, playerRetailItems : PlayerRetailItem[] = []): PlayerTeeTimeSlot[] {
    return playerInfo.map(o => {
      return {
        courseId: modifiedTeeSlot.course.id,
        scheduledDateTime: modifiedTeeSlot.time,
        playerId: o?.playerId,
        playerSlotPosition: o?.playPos,
        holeNumber: modifiedTeeSlot.hole,
        destinationScheduledTeeTimeId: modifiedTeeSlot.scheduledTeeTimeId ? modifiedTeeSlot.scheduledTeeTimeId : 0,
        isValid: true,
        isDestinationSlotRateChanged: false,
        sourceScheduledTeeTimeId: o?.sourceScheduledTeeTimeId ? o?.sourceScheduledTeeTimeId : 0,
        isSqueezed: isSqueezed,
        isPaid: o.isPaidPlayer,
        originalHoleNumber: modifiedTeeSlot.originalHoleNumber,
        allocationBlockId: o?.allocationBlockId,
        playerTypeId: o?.playerTypeId,
        sourceAllocation: modifiedTeeSlot.allocation,
        sourcePlayerTypeId: modifiedTeeSlot.playerDetail.find(x => x.playerId == o.playerId) ?
          modifiedTeeSlot.playerDetail.find(x => x.playerId == o.playerId).playerTypeId : 0,
        overrideDetails: o?.overrideDetails,
        rateTypeId: o?.rateType?.id,
        useNewRateType: false,
        firstName: o?.firstName,
        lastName: o?.lastName,
        holes: o?.holes,
        confirmationNumber: o?.confirmationNumber,
        caddyId: o?.caddyId,
        caddyTypeId: o?.caddyTypeId,
        multiPackTransactionDetailId: o?.multiPackTransactionDetailId,
        multiPackCartFeeValue: o?.multiPackCartFeeValue,
        multiPackGreenFeeValue: o?.multiPackGreenFeeValue,
        linkingId : o.linkingId,
        retailItems : playerRetailItems && playerRetailItems.length &&  playerRetailItems.filter(x=>x.scheduledTeeTimePlayerId == o.scheduledTeeTimePlayerId) != null ? 
        this.mapRetailItem(playerRetailItems,o.scheduledTeeTimePlayerId) : null
      } as PlayerTeeTimeSlot;
    });
  }

  public ShowSuccessInfo() {
    return this._utils.showAlert('success', AlertType.WellDone);
  }

  public validateDrop(dropSlot: TeeSheetSkeletonData, action: any, isAllocationClosedValidationRequired: boolean = true, isBulkPlayerDragged: boolean = false): boolean {
    let errorCode: number = 0;
    if (dropSlot.status.scheduleStatus === ScheduleStatus.hold) {
      errorCode = (action == TeeTimeAction.copy) ? 20413 : 20416;
    } else if (dropSlot.status.scheduleStatus === ScheduleStatus.frostDelay) {
      errorCode = (action == TeeTimeAction.copy) ? 20414 : 20417;
    } else if (dropSlot.status.scheduleStatus === ScheduleStatus.tempHold) {
      errorCode = (action == TeeTimeAction.copy) ? 20418 : 20419;
    } else if (!isBulkPlayerDragged && dropSlot.playerDetail.length > 4) {
      errorCode = 30407;
    } else if (!this.validateUserDaysOut(this._localization.getDate(dropSlot.time))) {
      errorCode = 30414;
    } else if (dropSlot.type === ScheduleType.crossOverBlock) {
      errorCode = (action == TeeTimeAction.copy) ? 30421 : 30420;
    }
    else if (isAllocationClosedValidationRequired && (!dropSlot.scheduledTeeTimeId || dropSlot.scheduledTeeTimeId == 0) && dropSlot.allocation && dropSlot.allocation.closed) {
      let isCopyOrCopyToMany = ((action == TeeTimeAction.copy || action == TeeTimeAction.copyToMany) ? 30601 : 30602);
      errorCode = (action == TeeTimeAction.move) ? 30600 : isCopyOrCopyToMany;
    }

    if (errorCode > 0) {
      const errorMessage: string = this._localization.getError(errorCode);
      this._utils.showError(errorMessage);
    }
    return errorCode === 0;

  }


  public isTeeGridModified(teeSheetModifications: TeeSheetModications) {

    return !(_.isEqual(teeSheetModifications.initialState, teeSheetModifications.currentState));
  }

  public setEmptyDraggedList() {
    this.draggedPlayers = [];
  }

  public validatePlayerDaysOut(inputDate: Date, daysOutStart: number, daysOutEnd: number): PlayerDaysOutResut {
    inputDate = this._utils.GetDateWithoutTime(inputDate);
    if (daysOutStart > 0 && this._localization.getDateDifference(inputDate, this._propertyInformation.CurrentDate) < daysOutStart) {
      return PlayerDaysOutResut.StartDateFail;
    } else if (daysOutEnd > 0 && this._localization.getDateDifference(inputDate, this._propertyInformation.CurrentDate) > daysOutEnd) {
      return PlayerDaysOutResut.EndDateFail;
    }
    return PlayerDaysOutResut.Pass;
  }
  public validateRateTypeDaysOut(inputDate: Date, daysOutStart: number, daysOutEnd: number): RateTypeDaysOutResult {
    inputDate = this._utils.GetDateWithoutTime(inputDate);
    if (daysOutStart > 0 && this._localization.getDateDifference(inputDate, this._propertyInformation.CurrentDate) < daysOutStart) {
      return RateTypeDaysOutResult.StartDateFail;
    } else if (daysOutEnd > 0 && this._localization.getDateDifference(inputDate, this._propertyInformation.CurrentDate) > daysOutEnd) {
      return RateTypeDaysOutResult.EndDateFail;
    }
    return RateTypeDaysOutResult.Pass;
  }

  public validateUserDaysOut(inputDate: Date): boolean {
    inputDate = this._utils.GetDateWithoutTime(inputDate);
    const userDaysOut = Number.parseInt(this._localization.GetUserSettings("daysOut"));
    if (userDaysOut == 0)
      return true;
    const daysOutDate = this._localization.AddDays(this._propertyInformation.CurrentDate, userDaysOut)
    return inputDate <= daysOutDate;
  }

  async tempHoldTeeTime(teeTimeData: TeeTimeModel, status: number): Promise<boolean> {
    let result = false;
    const dt = this._localization.ConvertDateToISODateTime(this._utils.getDate(teeTimeData.scheduleDateTime));
    const teeTimeTempHold: TeeTimeTempHold = {
      courseId: Number(teeTimeData.courseId),
      createdBy: sessionStorage.getItem('quickIdUser') ? parseInt(sessionStorage.getItem('quickIdUser')) : Number(this._localization.GetPropertyInfo('UserId')),
      createdOn: dt,
      holeNumber: teeTimeData.holeNumber,
      id: 0,
      releaseDateTime: dt,
      scheduleDateTime: dt,
      originalHoleNumber: teeTimeData.originalHoleNumber
    };
    if (status === TempHoldStatus.hold) {
      var res;
      await this._teeTimeService.TempHoldTeeTime(teeTimeTempHold).then(r => { result = true;}).catch(async e => {
        let errorMsg = '';
        if (!e.result && e.errorCode) 
        {
          errorMsg = this._localization.getError(e.errorCode);          
          errorMsg = e.errorDescription ? errorMsg.interpolate({ user: e.errorDescription }) : "";
        }
        else 
        {
          errorMsg = this._localization.getError(11014);
        }
        if (e.errorCode === 11010) {
          res = this._utils.showAlert(errorMsg, AlertType.Info, ButtonType.Ok);
        } else {
          res = this._utils.showAlert(errorMsg, AlertType.Error, ButtonType.Ok);
        }        
        await res.afterClosed().toPromise();
        result = false;               
      });
    } else {
      await this._teeTimeService.ReleaseTempHoldTeeTime(teeTimeTempHold).then(r => { result = true }).catch(e => {
        result = false;
      });
    }
    return result;
  }
  async getScheduledPlayerRetailItems(playerIds){
    return await this._teeTimesActionService.getScheduledPlayerRetailItems(playerIds);
  }
  async tempHoldTeeTimes(teeTimeTempHolds: TeeTimeTempHold[], status: TempHoldStatus): Promise<boolean> {
    let result = false;
    if (teeTimeTempHolds && teeTimeTempHolds.length > 0) {
      if (status === TempHoldStatus.hold) {
        await this._teeTimeService.TempHoldTeeTimes(teeTimeTempHolds).then(r => { result = true }).catch(async e => {
          let errorMsg = '';
          if (!e.result && e.errorCode) {
            errorMsg = this._localization.getError(e.errorCode);
            errorMsg = e.errorDescription ? errorMsg.interpolate({ user: e.errorDescription }) : "";            
          } else {
            errorMsg = this._localization.getError(11014);
          }
          var res = this._utils.showAlert(errorMsg, AlertType.Error, ButtonType.Ok);
          await res.afterClosed().toPromise();
          result = false;
        });
      } else {
        await this._teeTimeService.ReleaseTempHoldTeeTimes(teeTimeTempHolds).then(r => { result = true }).catch(e => {
          result = false;
        });
      }
    }
    return result;
  }

  public checkSelectedPlayersInCart(selectedPlayersList: number[]): boolean {
    if (this._retailService && this._retailService.selectedProducts && this._retailService.selectedProducts.every(o => (o != null))) {
      return this._retailService.selectedProducts.some(s => selectedPlayersList.includes(s.id));
    }
    else
      return false;
  }


  IsPlayersHaveAnyPendingTransactions(selectedPlayersList, scheduledPlayers: ScheduledPlayer[]) {
    return selectedPlayersList.some(x => scheduledPlayers.some(y => y.playerId == x && ((y.playerStatus & PlayerPaymentstatus.refund) == 0) && y.ticketNumber != "" && y.ticketNumber != null));
  }

  ShowTransactionPendingDialog(scheduledPlayerIds, callbackfn: any, dialogRef: any) {
    this._utils.showAlert(this._localization.captions.settings.playersHasPendingTransactionInvolvedMsg,
      AlertType.Warning, ButtonType.YesNo, this.ConfirmationPopupCallback.bind(this), [scheduledPlayerIds, callbackfn, dialogRef]);
  }

  ConfirmationPopupCallback(action: AlertAction, params: any) {
    if (action == AlertAction.YES) {
      if (params[1] && params[0]) {
        params[1](params[0]);
      }
    } else {
      if (params[2]) {
        params[2].close();
      }
    }
    console.log(params);
  }

  async getScheduledPlayer(id) {
    return await this._teeTimesActionService.getScheduledPlayers(id);
  }

  public async getCancelTeeTimePlayers(courseId: number, bookedDateTime: string): Promise<ReinstatePlayer[]> {
    return await this._teeTimesActionService.getCancelTeeTimePlayers(courseId, bookedDateTime);
  }



  async updateTeeTime(teeTime: TeeTimeModel): Promise<boolean> {
    return await this._teeTimesActionService.updateTeeTime(teeTime);
  }

  public getSourceTeeTimeInfo(scheduledPlayers: TeeSheetPlayerDetails[], draggedPlayersList): any[] {
    let result = [];
    if (!scheduledPlayers || scheduledPlayers.length == 0) {
      return result;
    }
    for (let dragged of draggedPlayersList) {
      let hasScheduledTime = dragged.scheduleTeeTimeId ? dragged.scheduleTeeTimeId : 0;
      let scheduleId = dragged.sourceScheduledTeeTimeId > 0 ? dragged.sourceScheduledTeeTimeId : hasScheduledTime;
      let originalSchedule = scheduledPlayers.find(r => r.scheduleTeeTimeId == scheduleId || r.sourceScheduledTeeTimeId == scheduleId);
      if (originalSchedule) {
        result.push({ allocationBlockId: originalSchedule.allocationBlockId, playerTypeId: originalSchedule.playerTypeId });
      }
    }
    return result;
  }

  public getDestinationTimeInfo(initialTeeTimes: TeeSheetSkeletonData[], squeezeSlots: TeeSheetSkeletonData[], scheduledPlayers: TeeSheetPlayerDetails[], modifiedSlots): any[] {
    let result = [];
    let playerTypeId = 0;
    let allocationId = 0;
    let allSlots: TeeSheetSkeletonData[] = [];
    if (initialTeeTimes && initialTeeTimes.length > 0) {
      allSlots.push(...initialTeeTimes);
    }
    if (squeezeSlots && squeezeSlots.length > 0) {
      allSlots.push(...squeezeSlots);
    }
    if (allSlots.length == 0) {
      return result;
    }
    for (let modfied of modifiedSlots) {
      playerTypeId = allocationId = 0;
      let originalSchedule = scheduledPlayers.find(r => r.playerId == modfied.playerId);
      if (originalSchedule) {
        playerTypeId = originalSchedule.playerTypeId;
      }
      let slotAllocationInfo = allSlots.find(r => this._utils.getDate(r.time).getTime() == this._utils.getDate(modfied.scheduledDateTime).getTime());
      if (slotAllocationInfo) {
        allocationId = slotAllocationInfo.allocation.id ? slotAllocationInfo.allocation.id : 0;
      }
      result.push({ allocationBlockId: allocationId, playerTypeId: playerTypeId });
    }
    return result;
  }

  public CheckAlloctionBlockAndPlayerTypePermissionOnSave(playerInfoDetails: any[], allocationCodePermissions: allocationBlockPlayerTypePermission[],
    allocationBlockPlayerTypes: AllocationBlockWithPlayerType[], actionErrorText: string): boolean {
    let res = false;
    if (!playerInfoDetails || playerInfoDetails.length == 0) {
      return res;
    }
    let nonaccessibleAllocationBlock = [];
    let nonaccessiblePlayer = [];
    playerInfoDetails.forEach(allocationBlock => {
      if (allocationBlock.allocationBlockId > 0) {
        const allocationBlockWithPlayerType = allocationCodePermissions.filter(x => x.allocationBlockId == allocationBlock.allocationBlockId);
        const allocationBlockPlayerType = allocationBlockPlayerTypes.find(x => x.id == allocationBlock.allocationBlockId);
        if (allocationBlockWithPlayerType.length == 0 && allocationBlockPlayerType) {
          nonaccessibleAllocationBlock.push(allocationBlockPlayerType.name);
        }
        else {
          if (allocationBlock.playerTypeId > 0) {
            const allocationPlayer = allocationBlockWithPlayerType.filter(x => x.playerTypeId == allocationBlock.playerTypeId);
            if (allocationPlayer.length == 0 && allocationBlockPlayerType) {
              const playerType = allocationBlockPlayerType.playerTypes.find(x => x.id === allocationBlock.playerTypeId);
              if (playerType) {
                nonaccessiblePlayer.push(playerType.type);
              }
            }
          }
        }
      }
    });
    if (nonaccessibleAllocationBlock.length > 0 || nonaccessiblePlayer.length > 0) {
      res = true;
      const uniqueNonAccessAllocationBlock = _.uniq(nonaccessibleAllocationBlock);
      const uniqueNonAccessPlayer = _.uniq(nonaccessiblePlayer);
      let name = ''
      if (uniqueNonAccessAllocationBlock.length > 0) {
        name = uniqueNonAccessAllocationBlock.join(', ') + ' ';
      }
      if (uniqueNonAccessPlayer.length > 0) {
        name = name + uniqueNonAccessPlayer.join(', ')
      }
      this._utils.showAllocationCodePermissionDeniedPopup(name, actionErrorText);
    }
    return res;
  }
  public async getPlayerTypes(): Promise<PlayerType[]> {
    return await this._playerTypeService.getAllPlayerTypes();
  }
  public async getRateTypes(): Promise<RateType[]> {
    return await this._rateTypeService.getAllRateTypes(false);
  }

  public GetOverrideDetails(playerTypeDaysOutFailed, rateTypeDaysOutFailed, playerTypeDaysOutOverrideduserID: number, rateTypeDaysOutOverrideduserID: number): OverrideDetails[] {
    let overrideDetails: OverrideDetails[] = [];
    if (playerTypeDaysOutFailed) {
      var playerTypeOverrideDetail: OverrideDetails = {
        id: 0,
        scheduledTeeTimePlayerId: 0,
        overrideType: OverrideType.playerType,
        userId: playerTypeDaysOutOverrideduserID
      }
      overrideDetails.push(playerTypeOverrideDetail);
    }
    if (rateTypeDaysOutFailed) {
      var rateTypeOverrideDetail: OverrideDetails = {
        id: 0,
        scheduledTeeTimePlayerId: 0,
        overrideType: OverrideType.rateType,
        userId: rateTypeDaysOutOverrideduserID
      }
      overrideDetails.push(rateTypeOverrideDetail);
    }
    return overrideDetails;
  }

  public quickloginUserId: number = 0;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public async showQuickIdPopup(errorMessage: string): Promise<boolean> {
    const width = OVERRIDE_ALERT_WIDTH;
    return new Promise<boolean>(async (resolve, reject) => {
      let res = this._utils.showAlertWithCustomWidth(errorMessage, AlertType.Info, ButtonType.OkOverride, width, (res) => {
        if (res === AlertAction.OVERRIDE) { // click override
          const quickLoginDialogRef = this.quickLoginUtils.QuickLogin({ breakPointNumber: UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT });
          quickLoginDialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
            if (quickLoginDialogResult.isLoggedIn && quickLoginDialogResult.userDetails && quickLoginDialogResult.userDetails.breakPointAccess) {  //Quickid user has breakpoint #10195                              
              this.quickloginUserId = quickLoginDialogResult.userDetails.userId;
              this.quickLoginUtils.resetQuickIdDetails();
              resolve(true);
            } else { //Quickid user doesn't have access for #10195
              resolve(false);
            }
          });
        } else { // click Ok
          resolve(false);
        }
      });
      await res.afterClosed().toPromise();
    });
  }
  public async showRateTypeQuickIdPopup(errorMessage: string): Promise<boolean> {
    const width = OVERRIDE_ALERT_WIDTH;
    return new Promise<boolean>(async (resolve, reject) => {
      let res = this._utils.showAlertWithCustomWidth(errorMessage, AlertType.Info, ButtonType.OkOverride, width, (res) => {
        if (res === AlertAction.OVERRIDE) { // click override
          const quickLoginDialogRef = this.quickLoginUtils.QuickLogin({ breakPointNumber: UserAccessBreakPoints.OVERRIDERATETYPEDAYSOUT });
          quickLoginDialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
            if (quickLoginDialogResult.isLoggedIn && quickLoginDialogResult.userDetails && quickLoginDialogResult.userDetails.breakPointAccess) {  //Quickid user has breakpoint #10195                              
              this.quickloginUserId = quickLoginDialogResult.userDetails.userId;
              this.quickLoginUtils.resetQuickIdDetails();
              resolve(true);
            } else { //Quickid user doesn't have access for #10195
              resolve(false);
            }
          });
        } else { // click Ok
          resolve(false);
        }
      });
      await res.afterClosed().toPromise();
    });
  }
  public async showPlayerTypeRateTypeQuickIdPopup(errorMessage: string): Promise<boolean> {
    const width = OVERRIDE_ALERT_WIDTH;
    return new Promise<boolean>(async (resolve, reject) => {
      let res = this._utils.showAlertWithCustomWidth(errorMessage, AlertType.Info, ButtonType.OkOverride, width, (res) => {
        if (res === AlertAction.OVERRIDE) { // click override
          const quickLoginDialogRef = this.quickLoginUtils.QuickLogin({ breakPointNumber: UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT }, true, [UserAccessBreakPoints.OVERRIDEPLAYERTYPEDAYSOUT, UserAccessBreakPoints.OVERRIDERATETYPEDAYSOUT], OVERRIDEPLAYERTYPEANDRATETYPEDAYSOUTERROR);
          quickLoginDialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {
            if (quickLoginDialogResult.isLoggedIn && quickLoginDialogResult.userDetails && quickLoginDialogResult.userDetails.breakPointAccess) {  //Quickid user has breakpoint #10195                              
              this.quickloginUserId = quickLoginDialogResult.userDetails.userId;
              this.quickLoginUtils.resetQuickIdDetails();
              resolve(true);
            } else { //Quickid user doesn't have access for #10195
              resolve(false);
            }
          });
        } else { // click Ok
          resolve(false);
        }
      });
      await res.afterClosed().toPromise();
    });
  }

  public updateModifiedSlots(modifiedSlots: PlayerTeeTimeSlot[], playerTypeDaysOutOverrideduserID: number, rateTypeDaysOutOverrideduserID: number) {
    modifiedSlots.forEach(x => {
      x.overrideDetails = this.GetOverrideDetails(x.playerTypeDaysOutFailed, x.rateTypeDaysOutFailed, playerTypeDaysOutOverrideduserID, rateTypeDaysOutOverrideduserID);
    });
  }

  public async checkOverridePlayerTypeRateTypeDaysOut(selectedPlayerType: PlayerType, selectedRateType: RateType, playerTypes: any[], modifiedSlots: PlayerTeeTimeSlot[], overridePlayerTypeDaysoutUserAccess: UserAccessModel.BreakPointResult, overrideRateTypeDaysoutUserAccess: UserAccessModel.BreakPointResult, date: any): Promise<boolean> {
    this.playerTypeOverride = 0;
    this.rateTypeOverride = 0;
    let rateTypes = await this.getRateTypes();
    modifiedSlots = await this.validatePlayerTypeRateTypeDaysout(selectedPlayerType,selectedRateType,modifiedSlots, playerTypes, rateTypes, overridePlayerTypeDaysoutUserAccess, overrideRateTypeDaysoutUserAccess, date);
    let userId = Number(this._localization.GetUserInfo('userId'));
    let override = true;
    if (this.playerTypeOverride > 0 && this.rateTypeOverride > 0) {
      override = await this.showPlayerTypeRateTypeQuickIdPopup(this._localization.captions.settings.playerTypeRateTypeDaysOutError);
      if (override) {
        let playerTypeDaysOutOverrideduserID = this.quickloginUserId;
        let rateTypeDaysOutOverrideduserID = this.quickloginUserId;
        this.updateModifiedSlots(modifiedSlots, playerTypeDaysOutOverrideduserID, rateTypeDaysOutOverrideduserID);
        this.quickloginUserId = 0;
      }
    }
    else if (this.playerTypeOverride > 0) {
      this.playerDaysoutErrorMessage = this.playerTypeOverride == 1 ? this.playerDaysoutErrorMessage : this._localization.captions.settings.playerTypeDaysOutError;
      override = await this.showQuickIdPopup(this.playerDaysoutErrorMessage);
      if (override) {
        let playerTypeDaysOutOverrideduserID = this.quickloginUserId;
        let rateTypeDaysOutOverrideduserID = userId;
        this.updateModifiedSlots(modifiedSlots, playerTypeDaysOutOverrideduserID, rateTypeDaysOutOverrideduserID);
        this.quickloginUserId = 0;
      }
    }
    else if (this.rateTypeOverride > 0) {
      this.rateDaysoutErrorMessage = this.rateTypeOverride == 1 ? this.rateDaysoutErrorMessage : this._localization.captions.settings.rateTypeDaysOutError;
      override = await this.showRateTypeQuickIdPopup(this.rateDaysoutErrorMessage);
      if (override) {
        let playerTypeDaysOutOverrideduserID = userId;
        let rateTypeDaysOutOverrideduserID = this.quickloginUserId;
        this.updateModifiedSlots(modifiedSlots, playerTypeDaysOutOverrideduserID, rateTypeDaysOutOverrideduserID);
        this.quickloginUserId = 0;
      }
    }
    else {
      let playerTypeDaysOutOverrideduserID = userId;
      let rateTypeDaysOutOverrideduserID = userId;
      this.updateModifiedSlots(modifiedSlots, playerTypeDaysOutOverrideduserID, rateTypeDaysOutOverrideduserID);
    }
    return override;
  }

  public GetplayerDaysoutErrorMessage(selectedOption, playerDaysOutResut: PlayerDaysOutResut): string {
    const daysOutStart = selectedOption.daysOutStart;
    const daysOutEnd = selectedOption.daysOutEnd;
    const currentDate = this._propertyInformation.CurrentDate;
    const daysOutStartDate = this._localization.GetFormattedDateDDMMYY(this._localization.AddDays(currentDate, daysOutStart));
    const daysOutEndDate = this._localization.GetFormattedDateDDMMYY(this._localization.AddDays(currentDate, daysOutEnd));
    const startErrorMessage: string = this._localization.captions.settings.playerDaysOutStartError + ' ' + daysOutStartDate;
    const endErrorMessage: string = this._localization.captions.settings.playerDaysOutEndError + ' ' + daysOutEndDate;
    return (playerDaysOutResut == PlayerDaysOutResut.StartDateFail) ? startErrorMessage : endErrorMessage;

  }
  public GetrateDaysoutErrorMessage(selectedOption, rateDaysOutResut: RateTypeDaysOutResult): string {
    const daysOutStart = selectedOption.daysOutStart;
    const daysOutEnd = selectedOption.daysOutEnd;
    const currentDate = this._propertyInformation.CurrentDate;
    const daysOutStartDate = this._localization.GetFormattedDateDDMMYY(this._localization.AddDays(currentDate, daysOutStart));
    const daysOutEndDate = this._localization.GetFormattedDateDDMMYY(this._localization.AddDays(currentDate, daysOutEnd));
    const startErrorMessage: string = this._localization.captions.settings.rateDaysOutStartError + ' ' + daysOutStartDate;
    const endErrorMessage: string = this._localization.captions.settings.rateDaysOutEndError + ' ' + daysOutEndDate;
    return (rateDaysOutResut == RateTypeDaysOutResult.StartDateFail) ? startErrorMessage : endErrorMessage;
  }

  async validatePlayerTypeRateTypeDaysout(selectedPlayerType: PlayerType, selectedRateType: RateType,modifiedSlots: PlayerTeeTimeSlot[], playerTypes: any[], rateTypes: any[], overridePlayerTypeDaysoutUserAccess, overrideRateTypeDaysoutUserAccess, date: string): Promise<PlayerTeeTimeSlot[]> {
    modifiedSlots.forEach(slot => {
      let playerType = selectedPlayerType ? selectedPlayerType : (playerTypes.find(x => x.id == slot.playerTypeId));
      let rateType = selectedRateType ? selectedRateType : (rateTypes.find(x => x.id == slot.rateTypeId));
      slot.playerTypeDaysOutFailed = false;
      slot.playerTypeDaysOutResult = PlayerDaysOutResut.Pass;
      slot.rateTypeDaysOutFailed = false;
      slot.rateTypeDaysOutResult = RateTypeDaysOutResult.Pass;
      if (playerType) {
        let result = this.validatePlayerDaysOut(this._localization.getDate(date), playerType.daysOutStart, playerType.daysOutEnd);
        if (result && (result == PlayerDaysOutResut.StartDateFail || result == PlayerDaysOutResut.EndDateFail)) {
          slot.playerTypeDaysOutFailed = true;
          slot.playerTypeDaysOutResult = result;
          if (!(overridePlayerTypeDaysoutUserAccess && overridePlayerTypeDaysoutUserAccess.isAllow)) {
            this.playerTypeOverride++;
            if (this.playerTypeOverride == 1) {
              this.playerDaysoutErrorMessage = this.GetplayerDaysoutErrorMessage(playerType, slot.playerTypeDaysOutResult);
            }
          }
        }
      }
      if (rateType) {
        let result = this.validateRateTypeDaysOut(this._localization.getDate(date), rateType.daysOutStart, rateType.daysOutEnd);
        if (result && (result == RateTypeDaysOutResult.StartDateFail || result == RateTypeDaysOutResult.EndDateFail)) {
          slot.rateTypeDaysOutFailed = true;
          slot.rateTypeDaysOutResult = result;
          if (!(overrideRateTypeDaysoutUserAccess && overrideRateTypeDaysoutUserAccess.isAllow)) {
            this.rateTypeOverride++;
            if (this.rateTypeOverride == 1) {
              this.rateDaysoutErrorMessage = this.GetrateDaysoutErrorMessage(rateType, slot.rateTypeDaysOutResult);
            }
          }
        }
      }
    });
    return modifiedSlots;
  }

  mapDestinationSlotRates(associatedRateSetupData: RateSetupData[], rateTypes: RateType[]): DestinationSlotRate[] {
    return associatedRateSetupData.map(x => {
      let rateName = rateTypes.find(r => r.id == x.rateTypeId);
      return {
        rateTypeId: x.rateTypeId,
        rateTypeName: rateName ? rateName.type : '',
        greenFee: x.greenFee,
        cartFee: x.cartFee,
        totalFee: x.greenFee + x.cartFee
      } as DestinationSlotRate;
    });
  }

  mapPlayerRateTypeToPlayerRateDetails(playerTypeRateTypes: API.PlayerTypeRateType[], courseTeeFee: RateSetupData[], copyMoveResult: CopyMoveResult[]): PlayerRateDetails[] {
    let playerRateDetails: PlayerRateDetails[] = [];
    let slotsWithDifferentRateType = copyMoveResult.filter(x => x.isDestinationSlotHasDifferentRateType);
    if (slotsWithDifferentRateType && slotsWithDifferentRateType.length > 0 && playerTypeRateTypes && playerTypeRateTypes.length > 0 && courseTeeFee && courseTeeFee.length > 0) {
      slotsWithDifferentRateType.forEach(element => {
        let playerTypeRateType = playerTypeRateTypes.find(x => x.id == element.playerTeeTimeSlot.playerTypeId);
        if (playerTypeRateType && playerTypeRateType.rateTypes && playerTypeRateType.rateTypes.length > 0) {
          let associatedRateSetupData = courseTeeFee.filter(x => playerTypeRateType.rateTypes.some(y => y.id == x.rateTypeId));
          let playerRateDetail: PlayerRateDetails = {
            courseId: element.playerTeeTimeSlot.courseId,
            date: element.playerTeeTimeSlot.scheduledDateTime,
            playerId: element.playerTeeTimeSlot.playerId,
            playerTypeId: element.playerTeeTimeSlot.playerTypeId,
            firstName: element.playerTeeTimeSlot.firstName,
            lastName: element.playerTeeTimeSlot.lastName,
            destinationSlotRates: this.mapDestinationSlotRates(associatedRateSetupData, playerTypeRateType.rateTypes)
          }
          playerRateDetails.push(playerRateDetail);
        }
      });
      return playerRateDetails;
    }
    else {
      return [];
    }
  }

  async handleDestinationSlotWithDifferentRateTypes(courseId: number, date: string | Date, validationResult: CopyMoveResult[]): Promise<PlayersNewRate[]> {
    return new Promise<PlayersNewRate[]>(async (resolve, reject) => {
      let playerTypeRateTypesTask = this._codeLinkingDataService.getPlayerTypeRateTypes();
      let courseTeeFeeTask = this._RateSetupDataService.getTeeFeesForCourseAndDate(courseId, this._localization.getDate(date));
      let [playerTypeRateTypes, courseTeeFee] = await Promise.all([playerTypeRateTypesTask, courseTeeFeeTask]);
      let playerRateDetails = this.mapPlayerRateTypeToPlayerRateDetails(playerTypeRateTypes, courseTeeFee, validationResult);
      let playersNewRate = await this.showDestinationSlotsRateTypes(playerRateDetails);
      resolve(playersNewRate);
    });
  }

  mapPlayerRateDetailsToPlayersNewRate(playerRateDetails: PlayerRateDetails[]): PlayersNewRate[] {
    return playerRateDetails.map(x => {
      return {
        rateTypeId: x.destinationSlotRates[0].rateTypeId,
        rateTypeName: x.destinationSlotRates[0].rateTypeName,
        greenFee: x.destinationSlotRates[0].greenFee,
        cartFee: x.destinationSlotRates[0].cartFee,
        playerTypeId: x.playerTypeId,
        playerId: x.playerId
      } as PlayersNewRate;
    });
  }
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);

  public async showDestinationSlotsRateTypes(playerRateDetails: PlayerRateDetails[]): Promise<PlayersNewRate[]> {
    let playersWithNewRate: PlayersNewRate[] = [];
    let message = this._localization.captions.teetime.newRateTypeConfirmation;
    let result = new Promise<PlayersNewRate[]>(async (resolve, reject) => {
      let res = this._utils.showAlert(message, AlertType.Info, ButtonType.YesNo, (res) => {
        if (res == AlertAction.YES) {
          // call back
          let multipleRateTypes = playerRateDetails.filter(x => x.destinationSlotRates.length > 1);
          let singleRateType = playerRateDetails.filter(x => x.destinationSlotRates.length == 1);
          if (multipleRateTypes && multipleRateTypes.length > 0) {
            //show popup
            this._dialog.open(PlayerRateModalComponent, {
              width: "80%",
              height: "80%",
              data: {
                playerRateDetails: multipleRateTypes
              },
              disableClose: true
            }).afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(result => {
              if (result) {
                console.log(result);
                playersWithNewRate.push(...result);
                if (singleRateType && singleRateType.length > 0) {
                  playersWithNewRate.push(...this.mapPlayerRateDetailsToPlayersNewRate(singleRateType));
                }
                resolve(playersWithNewRate);
              }
            });
          }
          else {
            playersWithNewRate = this.mapPlayerRateDetailsToPlayersNewRate(playerRateDetails);
            resolve(playersWithNewRate);
          }
        }
        else {
          resolve(playersWithNewRate);
        }
      });
      await res.afterClosed().toPromise();
    });
    return result;
  }

  public mapPlayerDetailsInPlayerTeeTimeSlot(draggedPlayers: PlayerTeeTimeSlot[], copyMoveResult: CopyMoveResult[]): CopyMoveResult[] {
    copyMoveResult.forEach(element => {
      let player = draggedPlayers.find(x => x.playerId == element.playerTeeTimeSlot.playerId);
      element.playerTeeTimeSlot.playerTypeId = player.playerTypeId;
      element.playerTeeTimeSlot.firstName = player.firstName;
      element.playerTeeTimeSlot.lastName = player.lastName;
    });
    return copyMoveResult;
  }

  public updatePlayerNewRate(playersNewRate: PlayersNewRate[], existingplayersNewRate: PlayersNewRate[]): PlayersNewRate[] {
    playersNewRate.forEach(element => {
      let player = existingplayersNewRate.find(x => x.playerId == element.playerId);
      if (player) {
        player.rateTypeId = element.rateTypeId;
        player.greenFee = element.greenFee;
        player.cartFee = element.cartFee;
      }
      else {
        existingplayersNewRate.push(element);
      }
    });
    return existingplayersNewRate;
  }
  public updatePlayerCaddyInfo(playersInfo: PlayerTeeTimeSlot[], existingplayersCaddyInfo: PlayersCaddyInfo[]): PlayersCaddyInfo[] {
    playersInfo.forEach(element => {
      let player = existingplayersCaddyInfo.find(x => x.playerId == element.playerId && (this._utils.getDate(x.scheduledDateTime).getTime() == this._utils.getDate(element.scheduledDateTime).getTime()) && x.playerSlotPosition == element.playerSlotPosition);
      if (player && element.isUpdateCaddy) {
        player.isUpdateCaddy = element.isUpdateCaddy;
        player.caddyId = element.caddyId;
        player.caddyTypeId = element.caddyTypeId;
      }
      else if (element.isUpdateCaddy) {
        let newPlayercaddyInfo: PlayersCaddyInfo = {
          playerId: element.playerId,
          isUpdateCaddy: element.isUpdateCaddy,
          caddyId: element.caddyId,
          caddyTypeId: element.caddyTypeId,
          scheduledDateTime: element.scheduledDateTime,
          playerSlotPosition: element.playerSlotPosition
        }
        existingplayersCaddyInfo.push(newPlayercaddyInfo)
      }
    });
    return existingplayersCaddyInfo;
  }
  public validateBreakPointForTabEdit(isBulkEdit: boolean, isMixedPlayersSlot: boolean, bulkEditUserAccess: UserAccessModel.BreakPointResult, normalBookUserAccess: UserAccessModel.BreakPointResult, tabAccessOnEdit: UserAccessModel.BreakPointResult[], tab: TeeTimeTabs) {
    let breakPointNumber = 0;
    switch (tab) {
      case TeeTimeTabs.playerInformation:
        break;
      case TeeTimeTabs.generalInformation:
        breakPointNumber = UserAccessBreakPoints.EDITGENERALINFO;
        break;
      case TeeTimeTabs.feeInformation:
        break;
      case TeeTimeTabs.otherInformation:
        break;
    }
    let superAccess: UserAccessModel.BreakPointResult = { isAllow: false, isViewOnly: false };
    superAccess.isAllow = isMixedPlayersSlot && (bulkEditUserAccess.isAllow || normalBookUserAccess.isAllow) ? true : isBulkEdit ? bulkEditUserAccess.isAllow : normalBookUserAccess.isAllow;
    superAccess.isViewOnly = isMixedPlayersSlot && (bulkEditUserAccess.isViewOnly || normalBookUserAccess.isViewOnly) ? true : isBulkEdit ? bulkEditUserAccess.isViewOnly : normalBookUserAccess.isViewOnly;
    const isValidAccess = breakPointNumber && tabAccessOnEdit && tabAccessOnEdit.length > 0 ? _.cloneDeep(tabAccessOnEdit.find(t => t.breakPointNumber == breakPointNumber)) : superAccess;
    if (breakPointNumber) {
      isValidAccess.isAllow = isValidAccess.isAllow ? isValidAccess.isAllow : superAccess.isAllow ? superAccess.isAllow : isValidAccess.isAllow;
      isValidAccess.isViewOnly = isValidAccess.isViewOnly ? isValidAccess.isViewOnly : (isValidAccess.isAllow ? !isValidAccess.isAllow : superAccess.isViewOnly ? superAccess.isViewOnly : isValidAccess.isViewOnly);
    }
    return isValidAccess;
  }
  /**
   * ValidateGeneralInfoBreakPoint method to return the breaakpoint access
   * @param breakPointNumber 
   * @param tabAccessOnEdit 
   * @param superAccess 
   * @returns ---isAllow & isViewOnly
   */
  public ValidateGeneralInfoBreakPoint(breakPointNumber, tabAccessOnEdit, superAccess?: any) {
    return tabAccessOnEdit && tabAccessOnEdit.length > 0 ? 
    _.cloneDeep(tabAccessOnEdit.find(t => t.breakPointNumber == breakPointNumber)) 
    : superAccess ? superAccess : { isAllow: false, isViewOnly: false };
  }
  public validateViewOnlyOnEdit(isBulkEdit: boolean, isMixedPlayersSlot: boolean, bulkEditUserAccess: UserAccessModel.BreakPointResult, normalBookUserAccess: UserAccessModel.BreakPointResult, isAccessOnEdit: UserAccessModel.BreakPointResult) {
    if (!isMixedPlayersSlot && !isBulkEdit && !normalBookUserAccess.isAllow && (isAccessOnEdit && !isAccessOnEdit.isAllow && isAccessOnEdit.isViewOnly)) {
      return true;
    }
    else if (!isMixedPlayersSlot && isBulkEdit && !bulkEditUserAccess.isAllow && (isAccessOnEdit && !isAccessOnEdit.isAllow && isAccessOnEdit.isViewOnly)) {
      return true;
    }
    else if (isMixedPlayersSlot && (!bulkEditUserAccess.isAllow || bulkEditUserAccess.isViewOnly) && (!normalBookUserAccess.isAllow || normalBookUserAccess.isViewOnly) && (isAccessOnEdit && !isAccessOnEdit.isAllow && isAccessOnEdit.isViewOnly)) {
      return true;
    }
    return false;
  }

  getOverlappingSlots(playersOverLappings,sourceTime,destinationTime,courseId,destinationcourseId = 0){
    if(playersOverLappings && playersOverLappings.length && sourceTime && destinationTime && courseId && destinationcourseId)
    {
      playersOverLappings = playersOverLappings.filter(o => !((o.courseId == courseId && o.scheduledTeeTimeOfPlayer== sourceTime)));
      playersOverLappings = playersOverLappings.filter(o => !((o.courseId == destinationcourseId && o.scheduledTeeTimeOfPlayer== destinationTime)));
      return playersOverLappings;
    }
    return [];
  }

  async CheckOverLappingBookingForGuest(playerLinkingIds = [],sourceSlot = null,scheduleDate = null,courseId:number = 0) : Promise<OverlappingSlotsForGuest[]>
  {
    let overlapHours: number = 0;
    // For walk in player no API call should go
    playerLinkingIds = playerLinkingIds.filter(o => o != "" && o != undefined); 
    scheduleDate = sourceSlot != null ? sourceSlot.unmodifiedSlotData.time : scheduleDate;
    const setting = JSON.parse(sessionStorage.getItem(CacheConstants.TeeTimeSetting));
    if(setting.checkForTeeTimeOverlaps != null 
      && Boolean(setting.checkForTeeTimeOverlaps) == true 
      && setting.overlapHours && 
        Number(setting.overlapHours) > 0)
    {
        overlapHours = setting.overlapHours;
    }
    if(playerLinkingIds != null && playerLinkingIds.length > 0)
    {
      return await this._teeTimeService.ValidateOverLappingTeeTimeSlotsForGuest(playerLinkingIds , overlapHours, scheduleDate,courseId);
    }
    return [];
  }

  public getOverlappingGuestDatas(playersOverLappings,isFromTeeSheet = false,isNotTeeAction = false) : { playerName:string, playerLinkId: string, messageToBeshown: string, proceedBooking: boolean, course: string, teeTime: string }[]
  {
    let guestsToAddRemove = [];
    if (playersOverLappings != null && playersOverLappings.length > 0) {
      let playerLinkIds = Array.from(new Set(playersOverLappings.map(o => o.playerLinkId)));
      let nonDuplicatePlayerLength = playerLinkIds.length;
      console.log(playersOverLappings);
      for (let idx = 0; idx < nonDuplicatePlayerLength; idx++) {
        let playerOverLapping = playersOverLappings.find(o => o.playerLinkId == playerLinkIds[idx]);
        let playerCoursesOverLapping = playersOverLappings.filter(o => o.playerLinkId == playerLinkIds[idx]);
        if (playerOverLapping != null && playerCoursesOverLapping != null && playerCoursesOverLapping.length > 0) {
          if(isNotTeeAction){
            let messageForPlayer = this.formatOverlappingMessge(playerOverLapping.firstName,
              playerOverLapping.lastName, playerCoursesOverLapping, playerCoursesOverLapping.length,isFromTeeSheet);  
              if (messageForPlayer != null && messageForPlayer.length > 0) {
                guestsToAddRemove.push
                ({
                  playerName:playerOverLapping.firstName + ' ' + playerOverLapping.lastName,
                  playerLinkId: playerOverLapping.playerLinkId,
                  messageToBeshown: messageForPlayer,
                  proceedBooking: false
                });
              }           
          } else{
              for(let x=0; x<playerCoursesOverLapping.length; x++){
                  guestsToAddRemove.push
                  ({
                  playerName:playerCoursesOverLapping[x].firstName + ' ' + playerCoursesOverLapping[x].lastName,
                  playerLinkId: playerCoursesOverLapping[x].playerLinkId,
                  course: playerCoursesOverLapping[x].courseName,
                  teeTime :  this._localization.LocalizeShortDate(this._localization.getDate(playerCoursesOverLapping[x].scheduledTeeTimeOfPlayer)) + ' ' + this._localization.LocalizeTime(playerCoursesOverLapping[x].scheduledTeeTimeOfPlayer),
              });
            }                
          }   
        }
      }
      return guestsToAddRemove;
    }
  }

  public formatOverlappingMessge(firstName : string = '', lastName : string = '',
  apiReponseForGuest = [],apiReponseLen: number = 0,isFromTeeSheet:boolean,)
  {
    let message = '';
    let separator = isFromTeeSheet ? '<br/>' : '\r\n';
    if(firstName != null && firstName.length > 0 && lastName != null && lastName.length > 0)
    {
        message += this
                ._localization
                .replacePlaceholders(this._localization.captions.settings.overlappingBookings,['guestName'],
                [firstName + " " + lastName]) + separator+ ' ';
    }
    if(apiReponseLen > 0 && apiReponseForGuest != null && apiReponseForGuest.length > 0)
    {
      for(let idx = 0; idx < apiReponseLen; idx++)
      {
          message += this
          ._localization
          .replacePlaceholders
          (this._localization.captions.settings.overlappingTeeTimeBooking,
          ['CourseName'],
          [apiReponseForGuest[idx].courseName])+' '+ this._localization.LocalizeTime(apiReponseForGuest[idx].scheduledTeeTimeOfPlayer)
          + separator +' ';
      }
    }
    if(message != '')
    return message + separator + this._localization.captions.settings.overlappingWouldYouLikeToProceed;
  }

  public showOverlappingPlayersPopup(guestsToAddRemove)
  {
    if(guestsToAddRemove != null && guestsToAddRemove.length > 0)
    {
      let messagesObj :{playerName:string,course:string, teeTime:string}[] = [];
      guestsToAddRemove.forEach(element => {
        messagesObj.push({playerName: element.playerName, course: element.course, teeTime: element.teeTime});
      });
      
      return this._dialog.open(OverLappingPlayerDetailsComponent,{
        height: '60%',
        width: '40%',
        disableClose: true,
        data:{
          tableHeaders: this.GetMultiDragDropPlayersTableHeader(),
          tableOptions: this.GetMultiDragDropPlayersTableOptions(),
          guestDataWithOverLap: messagesObj,
        }
      }).afterClosed().toPromise();
    }
  }
  
  public openConsentManagmentModal(arrGuestId, policyCategoryType = PolicyCategoryType.Guest,popupComment : string = '', callback: any = null) {
    this._dialog.open(ConsentManagementComponent, {
      width: '80%',
      height: '80%',
      disableClose: true,
      data: {
        isFromDialog: true,
        policyCategoryType: policyCategoryType,
        arrGuestId: arrGuestId,
        popupComment : popupComment,
        callback: (applyPolicy, arrGuestId, dialoref) => {
          var arrApplypolicy = [];
          arrGuestId.forEach(x => {
            let data = cloneDeep(applyPolicy);
            data.guestId = x;
            arrApplypolicy.push(data);
          })
          this._playerProfileDataService.UpdateConsentPolicyDetailsByGuestIdList(arrApplypolicy).then(x => {
            let successmsg = this._localization.replacePlaceholders(this._localization.captions.consentPolicyEnabled, ['consentPolicy'], ['']);
            this._utils.showCommonAlert(successmsg, AlertType.WellDone, ButtonTypes.Ok, async (res) => {
              if (res === AlertAction.CONTINUE) {
                dialoref.close();
                if (callback) {
                  callback(AlertAction.YES);
                }
              }
            })
          });
        }
      },
    }).afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(res => {
      if (callback) {
        callback(res);
      }
    });

  }
  public GetMultiDragDropPlayersTableHeader(): TableHeaderOptions[]
    {
        return [            
            {
              key: 'playerName',
              displayName: this._localization.captions.teetime.playerName
            },
            {
              key: 'course',
              displayName: this._localization.captions.teetime.course        
            },
            {
              key: 'teeTime',
              displayName: this._localization.captions.teetime.teetime,     
            }
        ];
    }
    
  public GetMultiDragDropPlayersTableOptions(): TableOptions 
    {
        return {
          defaultSortOrder: SorTypeEnum.desc,
          defaultsortingColoumnKey: 'id',
        }
    }

    public GetDynamicPricingForCourseAndScheduledDate(courseId: number, scheduleDate: string): Promise<DynamicPricingPlayerFee[]> {
        return this._golfSchedule.getPromise<DynamicPricingPlayerFee[]>({
          route: GolfApiRoute.GetDynamicPricingForCourseAndScheduledDate 
          , uriParams: { courseId: courseId , scheduleDate: scheduleDate }
        });
      }
    
    
  deleteRentalItems(selectedPlayersList:number[]): any 
  {
    let body = null;
    if(selectedPlayersList != null && selectedPlayersList.length > 0)
    {
      body = {
        sourceType: RentalSourceType.ScheduledTeeTimePlayer,
        sourceTypeIds: selectedPlayersList
      }
    }
    return body;
  }

  async getScheduledPlayerRetailItemsByActivityId(activityIds): Promise<ScheduledTeeTimePlayerRetailItem[]>{
    const result = await this._teeTimesActionService.getScheduledPlayerRetailItemsByActivityId(activityIds);
    return result;
  }
}
