import { TeeSheetsDataService } from "src/app/shared/data-services/golfschedule/teesheets.data.service";
import { GolfLocalization } from "src/app/core/localization/golf-localization";
import { GolfUtilities } from "src/app/shared/utilities/golf-utilities";
import { TeeSheetMapper } from "src/app/tee-time/tee-sheet/business/tee-sheet.mapper";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import {
  TeeSheetSkeletonData,
  TeeSheetGridContent,
  TimeRange,
  filterType,
} from "src/app/shared/models/teesheet.form.models";
import { BaseError } from "src/app/shared/models/http.model";
import {
  TeeTimeDetail,
  TeeSheet,
} from "src/app/shared/models/teesheet.api.models";
import { API } from "src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model";
import { AllocationBlockDataService } from "src/app/shared/data-services/golfschedule/allocationblock.data.service";
import { FilterGroup, Filter } from "src/app/shared/shared-models";
import * as _ from "lodash";
import { CourseDataService } from "src/app/shared/data-services/golfschedule/course.data.service";
import { Course } from "src/app/settings/golf-setup/code-setup/course/create-course-modal/create-course-model";
import { DefaultTimeRange } from "src/app/shared/global.constant";
import { RateTypeDataService } from "src/app/shared/data-services/golfschedule/ratetype.data.service";
import { RateType } from "src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal";
import { PlayerType } from "src/app/settings/golf-setup/code-setup/player-type/player-type.modal";
import { PlayerTypeService } from "src/app/shared/data-services/golfschedule/playertype.data.service";

export abstract class TeeSheetBase {
  public cancellationNotifier: Subject<void> = new Subject<void>();
  public coursePromise: Promise<Course[]>;
  public allocationPromise: Promise<API.TeeTimeAllocationBlock[]>;
  public teeSlotsCached: TeeSheetSkeletonData[] = [];

  private readonly mapper: TeeSheetMapper;
  private teeSlotsPromise: Promise<TeeSheet>;
  private teeSlotsSkeletonPromise: Promise<TeeSheetSkeletonData[]>;
  private teeSlotsContentsPromise: Promise<TeeSheetGridContent[]>;
  private _teeSlots = new BehaviorSubject<TeeSheetSkeletonData[]>(undefined);
  private readonly captions: any;

  constructor(
    public _teeSheetsDataService: TeeSheetsDataService,
    public _localization: GolfLocalization,
    public _utilities: GolfUtilities,
    public _allocationBlockDataService: AllocationBlockDataService,
    public _courseDataService: CourseDataService,
    public _playerTypeDataService: PlayerTypeService,
    public _rateTypeDataService: RateTypeDataService,
    public allocations: API.TeeTimeAllocationBlock[] = [],
    public courses: Course[] = [],
    public rateTypes: RateType[] = [],
    public playerTypes: PlayerType[] = []
  ) {
    this.mapper = new TeeSheetMapper(_localization);
    this.captions = this._localization.captions.teetime;
  }

    /**
     * Fetches Tee Sheet Data
     * @param {number} course
     * @param {Date} date
     * @returns {Observable<TeeSheetSkeletonData[]>}
     * @memberof TeeSheetBase
     */
    public getTeeSheet(course: number, date: Date,isAdvancedView : boolean = false,activeCourses?:any, allocationBlocks? : API.TeeTimeAllocationBlock[], rateTypes? : RateType[], playerTypes? : PlayerType[]): Observable<TeeSheetSkeletonData[]> {
      if (course && course > 0 && date) {
        this.getTeeSheetSkeleton(course, date, activeCourses, allocationBlocks);
        this.getTeeSheetGridContent(course, date, isAdvancedView, rateTypes, playerTypes);
        return this._teeSlots.asObservable();
      }
    }

  /**
   * Get Tee sheet layout information
   * @param {number} course
   * @param {Date} date
   * @returns {Observable<TeeSheetSkeletonData[]>}
   * @memberof TeeSheetBase
   */
  protected getTeeSheetSkeleton(
    course: number,
    date: Date,
    activeCourses?: any,
    allocationBlocks?: API.TeeTimeAllocationBlock[]
  ): Observable<TeeSheetSkeletonData[]> {
    this.teeSlotsSkeletonPromise = this.syncTeeSlot(
      course,
      date,
      activeCourses,
      allocationBlocks
    );
    this.teeSlotsSkeletonPromise.catch((err) => this.handleError(err.error));
    return this._teeSlots.asObservable();
  }


    /**
     * Get tee sheet's tee times information and merges (right outer join) with layout
     * @protected
     * @param {number} course
     * @param {Date} date
     * @memberof TeeSheetBase
     */
    protected getTeeSheetGridContent(course: number, date: Date,isAdvancedView : boolean = false, rateTypes? : RateType[], playerTypes? : PlayerType[]): void {
        this.teeSlotsContentsPromise = this.syncTeeSheetGridContent(course, date, isAdvancedView,rateTypes, playerTypes);
        this.mergeTeeSheetSkeletonTeeTime();
    }

  private async mergeTeeSheetSkeletonTeeTime(): Promise<void> {
    const responses = await Promise.all([
      this.teeSlotsSkeletonPromise,
      this.teeSlotsContentsPromise,
    ]);
    const mapped: TeeSheetSkeletonData[] = this.mapper.mergeTeeSlotsAndTeeTime(
      responses[0],
      responses[1]
    );
    this.teeSlotsCached = _.cloneDeep(mapped);
    this.addTeeSlots(mapped);

    // Unsubscribes the cancellable http get call
    this.cancelTeeSheetPendingRequest();
  }

  /**
   * Adds new set of tee slots
   * Replaces old slots with new , can be used to update slots also
   * @param {TeeSheetSkeletonData[]} slots
   * @memberof TeeSheetBase
   */
  public addTeeSlots(slots: TeeSheetSkeletonData[]): void {
    this._teeSlots.next(slots);
  }

  /**
   * Updates a slots in existing Tee Sheet View
   * @param {TeeSheetSkeletonData} slot
   * @memberof TeeSheetBase
   */
  public updateTeeSlot(slot: TeeSheetSkeletonData): void {
    this.teeSlotsCached.forEach((s) => {
      if (s.id === slot.id) {
        s = { ...slot };
      }
    });
    this.addTeeSlots(this.teeSlotsCached);
  }

  /**
   * Tee Sheet Filter Data
   * Return array of allocation filter and timeRange filter in case interval setup for the day/course.
   * Return array of allocation filter alone in case interval not set up for the day/course.
   * @returns {Promise<FilterGroup[]>}
   * @memberof TeeSheetBase
   */
  public async getTeeSheetFilters(): Promise<FilterGroup[]> {
    // waits for tee-sheet slots api data to find the ranges of filter
    const apiResp = Promise.all([
      this.allocationPromise,
      this.teeSlotsPromise,
      this.teeSlotsContentsPromise,
    ]);

    return apiResp
      .then((apiResponses) => {
        const allocationsForADay = this.fetchAllocationsAllotedForTeeSheet();
        const filterOptns: FilterGroup[] = [];
        const allocationFilterGrp: FilterGroup =
          this.populateAllocationFilter(allocationsForADay);
        const timeRangeFilterGrp: FilterGroup = this.populatTimeRangeFilter(
          apiResponses[1]
        );

        filterOptns.push(timeRangeFilterGrp); // filter data for time range filter
        filterOptns.push(allocationFilterGrp); // filter data for Allocation filter

        return filterOptns;
      })
      .catch(async (err: BaseError) => {
        const filterOptns: FilterGroup[] = [];
        const allocations = await this.allocationPromise;
        const allocationFilterGrp: FilterGroup =
          this.populateAllocationFilter(allocations);
        const timeRangeFilterGrp: FilterGroup =
          this._utilities.getTimeRangeFilter(
            filterType.timeRangeFilter,
            true,
            DefaultTimeRange.interval,
            DefaultTimeRange.startTime,
            DefaultTimeRange.endTime
          ) as FilterGroup; // filter data for Time Range in case of Error
        filterOptns.push(timeRangeFilterGrp);
        filterOptns.push(allocationFilterGrp); // filter data for Allocation filter
        return filterOptns;
      });
  }

  /**
   * Cached Tee Sheet Slots
   * @returns {TeeSheetSkeletonData[]}
   * @memberof TeeSheetBase
   */
  public getInitialTeeSlots(): TeeSheetSkeletonData[] {
    return _.cloneDeep(this.teeSlotsCached);
  }

  /**
   * Handles error , by default shows popup as per error codes
   * Override to modified error handling
   * @protected
   * @param {BaseError} err
   * @memberof TeeSheetBase
   */
  protected handleError(err: BaseError) {
    if (err?.errorCode && err?.errorCode > 0) {
      this.showError(err);
      this.addTeeSlots([]);
    }
  }

  protected showError(error: BaseError) {
    const err: string = this._localization.getError(error.errorCode);
    this._utilities.showError(err);
    this._utilities.ToggleLoader(false);
  }

  /**
   * Call This method to cancel pending requests for TeeSlots and TeeTimes API call
   * @protected
   * @memberof TeeSheetBase
   */
  protected cancelTeeSheetPendingRequest(): void {
    if (this.cancellationNotifier) {
      this.cancellationNotifier.next();
      this.cancellationNotifier.complete();
      this.cancellationNotifier.unsubscribe();
    }
    this.cancellationNotifier = new Subject<void>();
  }

  /**
   * Syncs tee slots from server
   * @private
   * @memberof TeeSheetStore
   */
  public syncTeeSlot(
    course: number,
    date: Date,
    activeCourses?: any,
    allocationBlocks?: API.TeeTimeAllocationBlock[]
  ): Promise<TeeSheetSkeletonData[]> {
    this.teeSlotsPromise = this._teeSheetsDataService.getTeeSlotsCancellable(
      course,
      date,
      this.cancellationNotifier
    );

    //dependencies for building tee slots
    this.allocationPromise =
      allocationBlocks && allocationBlocks.length > 0
        ? Promise.resolve(allocationBlocks)
        : this.getAllocationBlocks();
    this.coursePromise =
      activeCourses && activeCourses.length > 0
        ? Promise.resolve(activeCourses)
        : this.getCourses();

    const dependenciesResponses = Promise.all([
      this.allocationPromise,
      this.teeSlotsPromise,
      this.coursePromise,
    ]);
    return dependenciesResponses.then(
      (s: [API.TeeTimeAllocationBlock[], TeeSheet, Course[]]) => {
        const _mappedToForm = this.mapper.mapToTeeGridSkeleton(
          s[1],
          s[0],
          s[2]
        );
        this.teeSlotsCached = _mappedToForm;
        this.addTeeSlots(_mappedToForm);
        return _mappedToForm;
      }
    );
  }

  /**
   * Prepares the Tee Sheet Skeleton
   * @private
   * @memberof TeeSheetStore
   */
  public getTeeSheetSkeletonData(
    course: number,
    date: Date,
    activeCourses?: any,
    allocationBlocks?: API.TeeTimeAllocationBlock[]
  ): Promise<TeeSheetSkeletonData[]> {
    this.teeSlotsPromise = this._teeSheetsDataService.getTeeSlotsCancellable(
      course,
      date,
      this.cancellationNotifier
    );

    //dependencies for building tee slots
    const allocationPromise =
      allocationBlocks && allocationBlocks.length > 0
        ? Promise.resolve(allocationBlocks)
        : this.getAllocationBlocks();
    const coursePromise =
      activeCourses && activeCourses.length > 0
        ? Promise.resolve(activeCourses)
        : this.getCourses();

    const dependenciesResponses = Promise.all([
      allocationPromise,
      this.teeSlotsPromise,
      coursePromise,
    ]);
    return dependenciesResponses.then(
      (s: [API.TeeTimeAllocationBlock[], TeeSheet, Course[]]) => {
        const _mappedToForm = this.mapper.mapToTeeGridSkeleton(
          s[1],
          s[0],
          s[2],
          false
        );
        this.teeSlotsCached = _mappedToForm;
        this.addTeeSlots(_mappedToForm);
        return _mappedToForm;
      }
    );
  }

  private getAllocationBlocks(): Promise<API.TeeTimeAllocationBlock[]> {
    let allocationPromise: Promise<API.TeeTimeAllocationBlock[]>;
    if (this.allocations && this.allocations.length > 0) {
      allocationPromise = Promise.resolve(this.allocations);
    } else {
      allocationPromise =
        this._allocationBlockDataService.getAllocationBlocks(false);
    }
    return allocationPromise;
  }

  private getCourses(): Promise<Course[]> {
    let coursePromise: Promise<Course[]>;
    if (this.courses && this.courses.length > 0) {
      coursePromise = Promise.resolve(this.courses);
    } else {
      coursePromise = this._courseDataService.getCoursesWithUserAccess(false);
    }
    return coursePromise;
  }

  private getRateType(): Promise<RateType[]> {
    let rateTypesPromise: Promise<RateType[]>;
    if (this.rateTypes && this.rateTypes.length > 0) {
      rateTypesPromise = Promise.resolve(this.rateTypes);
    } else {
      rateTypesPromise = this._rateTypeDataService.getAllRateTypes(false);
    }
    return rateTypesPromise;
  }

    private getPlayerType(): Promise<PlayerType[]> {
        let playerTypesPromise: Promise<PlayerType[]>;
        if (this.playerTypes && this.playerTypes.length > 0) {
            playerTypesPromise = Promise.resolve(this.playerTypes);
        } else {
            playerTypesPromise = this._playerTypeDataService.getAllPlayerTypes(false);
        }
        return playerTypesPromise;
    }
    /**
  * Syncs tee slot contents from server
  * @private
  * @param {number} course
  * @param {Date} date
  * @returns
  * @memberof TeeSheetBase
  */
    private syncTeeSheetGridContent(course: number, date: Date,isAdvancedView : boolean = false, rateTypes? : RateType[], playerTypes? : PlayerType[]): Promise<TeeSheetGridContent[]> {
        const teeTimePromise = this._teeSheetsDataService.getTeeTimesCancellable(course, date, this.cancellationNotifier,isAdvancedView);
        const playerTypesPromise: Promise<PlayerType[]> = (playerTypes && playerTypes.length > 0) ?  Promise.resolve(playerTypes): this.getPlayerType();
        const rateTypesPromise: Promise<RateType[]> = (rateTypes && rateTypes.length > 0) ?  Promise.resolve(rateTypes): this.getRateType();
        const dependenciesResponses = Promise.all([teeTimePromise, this.allocationPromise, this.coursePromise, rateTypesPromise, playerTypesPromise]);
        return dependenciesResponses.then(
            (resp: [TeeTimeDetail[], API.TeeTimeAllocationBlock[], Course[], RateType[], PlayerType[] ]) => {
                return this.mapper.mapToTeeGridContent(resp[0], resp[1], resp[2], resp[3], resp[4]);
            }
        );
    }

  private fetchSlotsRange(teeSheetSlotsAPIresponse: TeeSheet): TimeRange {
    const totalSlots: number = teeSheetSlotsAPIresponse.slots.length;
    return {
      from: this._localization.getDate(this.teeSlotsCached[0].time),
      to: this._localization.getDate(this.teeSlotsCached[totalSlots - 1].time),
    };
  }

  private fetchAllocationsAllotedForTeeSheet(): Partial<API.TeeTimeAllocationBlock>[] {
    // fix for Bug 24966

    let allocationsOfADay: Partial<API.TeeTimeAllocationBlock>[] =
      this.teeSlotsCached
        .map((slot) => {
          return {
            id: slot.allocation.id,
            allocationBlockName: slot.allocation.name,
          } as Partial<API.TeeTimeAllocationBlock>;
        })
        .filter((allocation) => allocation.id != 0);

    allocationsOfADay = _.uniqBy(allocationsOfADay, "id");
    return allocationsOfADay;
  }

  private populateAllocationFilter(
    allocBlocks: Partial<API.TeeTimeAllocationBlock>[]
  ): FilterGroup {
    return {
      id: filterType.allocationFilter,
      name: "Allocation Code",
      title: this._localization.captions.teetime.AllocationCode,
      filters: allocBlocks.map((a) => {
        return {
          id: a.id,
          name: a.allocationBlockName,
        } as Filter;
      }),
    } as FilterGroup;
  }

  private populatTimeRangeFilter(teeTimeSlots: TeeSheet): FilterGroup {
    const timeRange: TimeRange = this.fetchSlotsRange(teeTimeSlots);
    return this._utilities.getTimeRangeFilter(
      filterType.timeRangeFilter,
      true,
      DefaultTimeRange.interval,
      timeRange.from.getHours(),
      timeRange.to.getHours()
    ) as FilterGroup;
  }
}
