<section id="create-event-scheduler" class="ag_w--100 ag_height--100">    
    <div class="event-schedule-container ag_display--flex">
        <div class="ag_w--60 ag_height--100 event-schedule-border">
            <div class="ag_height--100">
                <app-shared-event-details [saveReportConfig]="saveReportConfig" [dropDownEmit]="dropDownEmit" [crudInput]="crudInput"
                 [report]="report" (slideEmit)="slideSelector($event)" (emitReportSelector)="reportSelector($event)"
                 (retailReportEmitter)="retailReport($event)" (editSlider)="edittedValues($event)" (formReady)='onEventDetailsReady($event)' (notifyReportAPIOptions) = "notifyEventScheduler($event)" (reportOptionChange) = "onReportOptionChange($event)" [inputData]='eventDetailsData'></app-shared-event-details>
            </div>
        </div>
        <div class="ag_w--40 ag_height--100">
            <div class="notification-wrapper">
                <app-shared-notification-schedule (formReady)='onNotificationsScheduleReady($event)' [crudInput]="crudInput"
                 [inputData]='notificationData'></app-shared-notification-schedule>
            </div>
        </div>
    </div>    
    <div class="footer ag_footer--actions ag_display--flex">
        <app-button [buttontype]='createButton' (valueChange)='create()'></app-button>
        <app-button [buttontype]='cancelButton' (valueChange)='cancel()'></app-button>
    </div>   
</section>