import { Injectable } from '@angular/core';
import { GolfRoutes } from 'src/app/core/extensions/golf-route';
import { GolfManagementCommunication } from '../../communication/services/golfmanagement.service';

@Injectable({ providedIn: 'root' })
export class NegotiatedFeeDataService {
    constructor(private _golfScheduleCommunication: GolfManagementCommunication) { }


    async GetNegotiatedFee(outletId: number): Promise<NegotiatedFee> {
        return this._golfScheduleCommunication.getPromise({
            route: GolfRoutes.GetNegotiatedFeeByOutletId,
            uriParams: { outletId }
        });
    }

}

export interface NegotiatedFee {
    outletId: number;
    greenFeeRetailItemId: number;
    cartFeeRetailItemId: number;
    entryFeeRetailItemId: number;
    refundFeeRetailItemId: number;
    rainCheckGreenFeeRetailItemId: number;
    rainCheckCartFeeRetailItemId: number;
}