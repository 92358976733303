<div class="dialog-container">
  <app-golfdialog-header [automationId]="'CadddySelect'" [title]="captions.title" (closeDialog)="closeHandler($event)"></app-golfdialog-header>
  <div class="dialog-container__content">
    <form [formGroup]="caddySelectForm" class="golf-container--padding caddyform">
      <app-golf-date-picker [inputs]="selectDate" class="controls" (datePickerChange)="dateChanged($event)">
      </app-golf-date-picker>
      <app-radio-table [headers]="header" [tableData]="caddies" [selectedItemId]="selectedCaddyId" *ngIf="caddies"
        [radioForm]="caddySelectForm" controlName="radioControl" (optionChange)="onSelectionChange($event)">
      </app-radio-table>
    </form>
  </div>
  <app-golfdialog-footer [automationId]="'CadddySelect'" [buttonObj]="buttonObj" (save)="saveHandler($event)" (cancel)="closeHandler($event)" [clickThrottleTime]=1>
  </app-golfdialog-footer>
</div>