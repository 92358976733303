import { Injectable } from '@angular/core';
import { BulkMoveModalService } from './bulk-move-modal.service';

@Injectable()
export class BulkMoveModalBusiness {

  constructor(private _BulkMoveModalService: BulkMoveModalService) { }

  GetCaptions() {
    return this._BulkMoveModalService.getCaptions();
  }

  GetPlayersDetails() {
    return this._BulkMoveModalService.getPlayersDetails();
  }

  GetPlayerInformation() {
    return this._BulkMoveModalService.getPlayerInformation();
  }

  GetCourses() {
    return this._BulkMoveModalService.getCourses();
  }

  
}
