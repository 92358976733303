<section class="common-itinerary-wrapper newVersionWrapper">
  <div class="ag_d-cwflex flx-wrap algn-centre"
    [ngClass]="{'disabled':(fromTeeTime && (playersData?.length === 0 || isHitoricalOnEdit))?true:false}">
    <form [formGroup]="itineraryForm">
      <div *ngIf="fromTeeTime" class="filt-date">
        <label class="ag_form--label">{{captions.TeeTimePlayers}}</label>
        <mat-form-field  [floatLabel]="floatLabel" class="ml-3">
          <mat-select [attr.automationId]="'Dd_commmonItinerary_players'" formControlName="players" (selectChange)="onPlayerSelectionChange($event)">
            <mat-option *ngFor="let player of playersData"
            (click)="onPlayerSelectionChange($event,player)"
            [value]="player">{{player.viewValue}}</mat-option>
          </mat-select>
        </mat-form-field>
        <label class="ml-5 ag_form--label">{{captions.Products}}</label>
        <app-ag-dropdown [attr.automationId]="'Dd_commmonItinerary_productOptions'" id="ag-automation-select-product" [inputs]="productOptions" (selectChange)="onChange($event)">
        </app-ag-dropdown>
      </div>
      <div class="filt-date">
        <mat-form-field  [floatLabel]="floatLabel" class="LW14 cpr--15 d-inline-block date-picker-width" appearance="legacy">
          <mat-label>{{captions.FromDate}}</mat-label>
          <input [attr.automationId]="'Txt_commmonItinerary_startDate'" formControlName="startDate" dateRestricter (dateChange)="dateChange($event)" class="LW14" matInput
            [matDatepicker]="picker3" [placeholder]="placeHolderFormat" name="date">
          <mat-datepicker #picker3></mat-datepicker>
          <i aria-hidden="true" matSuffix class="icon-Calender" (click)="picker3.open()"></i>
          <mat-error *ngIf="itineraryForm.controls['startDate'].hasError('matDatepickerParse')">
            {{captionsCommon.dateFormat}}</mat-error>
          <mat-error
            *ngIf="itineraryForm.controls['startDate'].hasError('required') && !itineraryForm.controls['startDate'].hasError('matDatepickerParse')">
            {{captionsCommon.Missing}} {{captions.FromDate}}</mat-error>
          <mat-error *ngIf="itineraryForm.controls['startDate'].hasError('matDatepickerMin')">{{captionsCommon.minimum}}
            {{captions.FromDate}}</mat-error>
          <mat-error *ngIf="itineraryForm.controls['startDate'].hasError('matDatepickerMax')">{{captionsCommon.maximum}}
            {{captions.FromDate}}</mat-error>
        </mat-form-field>
        <mat-form-field [floatLabel]="floatLabel" class="LW14  d-inline-block date-picker-width" appearance="legacy">
          <mat-label>{{captions.ToDate}}</mat-label>
          <input [attr.automationId]="'Txt_commmonItinerary_endDate'" formControlName="endDate" [min]="startDate" dateRestricter (dateChange)="dateChange($event)"
            class="LW14" matInput [matDatepicker]="picker2" [placeholder]="placeHolderFormat" name="date">
          <mat-datepicker #picker2></mat-datepicker>
          <i aria-hidden="true" matSuffix class="icon-Calender" (click)="picker2.open()"></i>
          <mat-error *ngIf="itineraryForm.controls['endDate'].hasError('matDatepickerParse')">
            {{captionsCommon.dateFormat}}</mat-error>
          <mat-error
            *ngIf="itineraryForm.controls['endDate'].hasError('required') && !itineraryForm.controls['endDate'].hasError('matDatepickerParse')">
            {{captionsCommon.dateFormat}}</mat-error>
          <mat-error *ngIf="itineraryForm.controls['endDate'].hasError('matDatepickerMin')">{{captionsCommon.minimum}}
            {{captions.ToDate}}</mat-error>
          <mat-error *ngIf="itineraryForm.controls['endDate'].hasError('matDatepickerMax')">{{captionsCommon.maximum}}
            {{captions.ToDate}}</mat-error>
        </mat-form-field>
        <div *ngIf="fromTeeTime" class="ml-4">
          <app-button [attr.automationId]="'Btn_commmonItinerary_searchButton'" [buttontype]="searchButton" (valueChange)='search($event)' class="app-button"></app-button>
        </div>
        <div  *ngIf="(productId == golf || productId == spa) && !fromTeeTime" class="ml-4 opacity-spa mt-1">
          <label class="ag_display--block">{{captions.ViewCancelledBookings}}</label>
          <app-common-toggle-switch [checkedLabel]="captions.yes" [automationId]="'CommonItinerary'"  [uncheckedLabel]="captions.no"
              formControlName="viewCancelledBookings" (changeToggleEvent)="dateChange($event)">
          </app-common-toggle-switch>
      </div>
      <div  *ngIf="productId == golf && fromTeeTime" class="ml-4 opacity-spa">
        <label class="ag_display--block">{{captions.ViewCancelledBookings}}</label>
        <app-common-toggle-switch [checkedLabel]="captions.yes" [automationId]="'CommonItinerary'" [uncheckedLabel]="captions.no"
            formControlName="viewCancelledBookings" (changeToggleEvent)="search($event)">
        </app-common-toggle-switch>
    </div>
      </div>
      <div *ngIf="fromTeeTime && (this.playersData.length === 0 || this.isResult)" class="text-align-center ag-mt-8">
        <mat-label>{{captions.NoItinerary}}</mat-label>
      </div>
      <div class="ag_d-cwflex flx-wrap  cw-100 primary-looper-sect" *ngFor="let x of data">
        <span class="date-sect" [ngClass]="{'current-date':x.iscurrenDate}">
          {{x.date}}
        </span>
        <div class="separator ag_d-cwflex flx-wrap  ">
          <div class="secondary-looper-sect" *ngFor="let y of x.custDetails">
            <p class="title-name ag_display--flex"> <span class="sep-left">
                <span *ngIf="y.usercolor" class="color" [style.background-color]="y.usercolor"></span>
                <span [matTooltip]="y.title" class="title-separator">
                  {{y.title}}
                </span>
              </span>
              <span class="edit-wrapper ag_mr--2" [attr.automationId]="'Icn_commmonItinerary_showEdit'" *ngIf="y.showEdit"><i class="icon-edit-new cursor" (click)="openEdit($event, y)"></i></span>
              <span *ngIf="y.activityStatus !== 5" class="custom-tag mr-2" [matTooltip]="y.status">{{y.status}}</span>
              <!-- <span *ngIf = "y.showEdit" class="icon-Edit" (click)="onItiEdit($event, y)"></span> -->
            </p>
            <div class="ag_display--flex" *ngIf="y.package"> 
              <i aria-hidden="true" [matTooltip]="captions.PackageCode" matSuffix class="icon-Packages">&nbsp;&nbsp;</i>
                        <p>  {{y.package}}</p>
            </div> <br><br>          
            <div *ngIf="y.isRoomBooking; then thenBlock; else elseBlock"></div>

            <ng-template #thenBlock>
              <p class="arrivaltime">
                <span *ngIf="y.bookingtime">{{y.bookingtime}}</span>
              </p>
            </ng-template>
            <ng-template #elseBlock>
              <p class="time">
                <span *ngIf="y.fromtime">{{y.fromtime}}</span>
                <span *ngIf="y.totime" class="time-seperator">-</span>
                <span *ngIf="y.totime">{{y.totime}}</span>
              </p>
            </ng-template>
            <p class="prf-name">
              <span class="profile-name" [matTooltip]="y.name">{{y.name}}</span>
            </p>
            <div class="more-details cw-100 cmt-15">
              <div class="cw-100 booking-title" [ngClass]="{'set-bdr': y.showmoreList.length > 0 && y.showmoreList[0]?.value}"><span *ngIf =y.bookingId>{{captions.BookingId}} - {{y.bookingId}}</span></div>
              <div class="ag_d-cwflex shw-list  cw-100 flx-wrap ">
                <div *ngFor="let z of y.showmoreList" class="cw-50 cpr--15">
                  <label *ngIf="z.value" class="cpb-10 ct-label">{{z.label}}</label>
                  <p *ngIf="z.value" [matTooltip]="z.value">{{z.value}}</p>
                </div>
              </div>
              <div *ngIf="productId == golf && (y.activityStatus !== 3 && y.activityStatus !== 4)"  class="l_sec3">
                <div class="ag_link"  (click)="getRetailOrRentalItems(y)" [matMenuTriggerFor]="rentalGuestMatMenu">{{isRentalEnabled ? captionsCommon.lbl_viewRentalorRetail : captionsCommon.lbl_viewRetail}}</div>
                <mat-menu #rentalGuestMatMenu="matMenu" class="update-Player"  [hasBackdrop]="true">
                  <div class="ag_p--4" >
                      <div class="align-items-center ag_display--flex">
                            <h6 class="ag_font--bold ag_pr--5">{{isRentalEnabled ? captionsCommon.lbl_viewRentalorRetail : captionsCommon.lbl_viewRetail}}</h6>
                            <h6 class="icon-close common-itinerary-close ag_cursor--pointer float-right ag_ml--auto ml-4"></h6>
                      </div>
                      <div class="ag_display--flex" *ngIf="rentalItems.length>0 || retailItems.length>0">
                        <div class="ag_pr--7 rentals-sec">
                          <div class="ag_font--bold">{{captionsCommon.lbl_retail}}</div>
                          <div *ngFor="let item of retailItems">{{item?.itemDescription}} {{item?.quantity}}</div>
                        </div>
                        <div class="rentals-sec" *ngIf="isRentalEnabled">
                          <div class="ag_font--bold">{{captionsCommon.rentals}}</div>
                          <div *ngFor="let item of rentalItems">{{item?.itemDescription}} {{item?.quantity}}</div>
                        </div>
                      </div>
                      <div class="ag_display--flex" *ngIf="rentalItems.length == 0 && retailItems.length == 0 && this.isRentalRetailLoaded">
                        <span>{{captionsCommon.lbl_rental_none}}</span>
                      </div>
                  </div>
              </mat-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

  </div>
</section>