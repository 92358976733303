<div class="copy-players--wrapper golf-container--padding newVersionWrapper" #infoElement>
  <div class="notification-message"  [ngClass]="{'bulk-on': teeTimeFormat == 2}" *ngIf="notificationFlag && notificationbar && notificationbar.value">
    <app-note [noteMessage]="notificationbar" (removeNotification)="removeNotification($event)"></app-note>
  </div>
  <app-players-info [playerInputData]="playerDetails"></app-players-info>

  <div class="message-notification" *ngIf="notifierBar && notifierBar.value">
    <app-note [noteMessage]="notifierBar">
    </app-note>
  </div>

    <div class="copy-players-form--wrapper" *ngIf="pageLoaded">
    <form [formGroup]="copyPlayersForm">
      <app-golf-date-picker [inputs]="dateInputs" (datePickerChange)="dateChanged($event)"></app-golf-date-picker>
      <mat-form-field [floatLabel]="floatLabel" class="copy-control">
        <mat-select [attr.automationId]="'Dd_copyPlayerModal_course'" placeholder="{{captions.course}}" name="Course" formControlName="courseControl" [matTooltip]="courseTooltip" (selectionChange) ="courseChanged($event.value)">
          <mat-option *ngFor="let course of courses  | async" [value]="course.id">
            {{course.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field [floatLabel]="floatLabel" *ngIf="teeTimeFormat === bulkTee" class='copy-control'>
        <mat-select [attr.automationId]="'Dd_copyPlayerModal_allocationCode'" placeholder="{{captions.allocationCode}}" formControlName="allocationCode" required (selectionChange)="allocationCodeChange($event)"
        [compareWith]="comparetSelects" >
          <mat-option *ngFor="let allocationCode of allocationCodes" [value]="allocationCode">
            {{allocationCode?.allocationBlockName}}
          </mat-option>
        </mat-select>
        <mat-error  *ngIf="copyPlayersForm.get('allocationCode').hasError('required')">
          {{errorCaptions.missingAllocationCode}}</mat-error>
      </mat-form-field>
      <mat-form-field [floatLabel]="floatLabel" *ngIf="teeTimeFormat === bulkTee" class='copy-control'>
        <mat-select [attr.automationId]="'Dd_copyPlayerModal_playerType'" placeholder="{{captions.playerType}}" formControlName="playerType"  [compareWith]="comparetSelects" (selectionChange)="PlayerTypeChange($event)">
          <mat-option *ngFor="let playerType of playerTypes" [matTooltip]="playerType.type" [value]="playerType">
            {{playerType.type}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="copyPlayersForm.get('playerType').hasError('required')">{{errorCaptions.missingPlayerType}}
        </mat-error>
      </mat-form-field>
      <mat-form-field [floatLabel]="floatLabel" *ngIf="teeTimeFormat === bulkTee" class='copy-control'>
        <mat-select [attr.automationId]="'Dd_copyPlayerModal_rateType'" placeholder="{{captions.rateType}}" formControlName="rateType" [compareWith]="comparetSelects" (selectionChange)='RateTypeChange($event)'  >
          <mat-option *ngFor="let rateType of rateTypes" [value]="rateType">
            {{rateType.type}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="copyPlayersForm.get('rateType').hasError('required')">{{errorCaptions.missingRateType}}
        </mat-error>
      </mat-form-field>
    </form>
  </div>
  <app-players-list class="app-players-list" *ngIf="teeTimeFormat !== bulkTee" [ngClass]="{'info-strip':infoStripAvailable}" [action] ="action"
  [mixedPlayerSlot] = "mixedPlayerSlot" [teeSheetID]="teeSheetID" [customTableData]="customTableData" [playersList]="playerList" [playerCollectionList]="playerCollectionList"
  (onDragPlayers)="onDragPlayers($event)" (IsBulk)="CanChangeBulkView($event)" (onSelectAll)="selectAll($event)"
  [normalPlayerUserAccess]="normalPlayerUserAccess"
  [bulkPlayerUserAccess]="bulkPlayerUserAccess" [automationId]="'CopyPlayerModal'"></app-players-list>
  <div class="golf-tee-sheet--wrapper" id={{teeSheetID?.tableID}} [ngClass]="notificationFlag? 'notification-on': ''">
    <app-bulk-player-list [automationId]="'CopyPlayerModal'" class="app-bulk-player-list" [ngClass]="{'golf-section__disable': isBulkSectionDisabled, 'info-strip-grid': infoStripAvailable}" *ngIf="teeTimeFormat == 2" [teeSheetID]="teeSheetID"
    [customTableData]="customTableData" [isAutoBookAvailable]="isAutoBookAvailable" [playersList]="playerList"
    [playerCollectionList]="playerCollectionList" (onDragPlayers)="onDragPlayers($event)">
    </app-bulk-player-list>
    <app-tee-grid class="app-tee-grid" [ngClass]="{'bulk-teesheet': teeTimeFormat == 2, 'info-strip-grid': infoStripAvailable, 'has-no-player': playerList?.length == 0}" [teeSheetID]="teeSheetID" [customTableData]="customTableData" [teeData]="skeletonData | async"
      (moreEvent)="onmoreEvent($event)" (dragDropEvent)="onDragDrop($event)" [courseId] = 'courseId' (onDragGridPlayers)="onDragGridPlayers($event)"
      (onRemovePlayer)="onRemovePlayer($event)" (onDropValidationFailed)="onDropFailed($event)" (onPlayerCollectionListEmit)="onPlayerListEmit($event)"></app-tee-grid>
  </div>
</div>
