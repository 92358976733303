import { Injectable } from "@angular/core";
import { CopyPlayerService } from './copy-player-modal.service';
import { PlayerTeeTimeSlot } from '../move-player-modal/move-player-modal.model';
import { CourseDataService } from 'src/app/shared/data-services/golfschedule/course.data.service';
import { TeeSheetCopy } from 'src/app/tee-time/shared/tee-sheet/tee-sheet.copy';
import { TeeTimesActionBusiness } from 'src/app/tee-time/shared/tee-action.business';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { TeeSheetSkeletonData,TeeSheetPlayerDetails, ScheduledPlayer, Allocation, PlayerRateType } from 'src/app/shared/models/teesheet.form.models';
import { AllocationCode, TeeSlotDetails, Bulkstatus } from 'src/app/tee-time/tee-sheet/bulk-tee-time/bulk-tee-time.model';
import { TeeTimesBulkGroupBusiness } from 'src/app/tee-time/shared/tee-bulkGroup.business';
import { TeeTimeDataService } from 'src/app/shared/data-services/golfschedule/TeeTime.data.service';
import { TeeSheetBulk } from 'src/app/tee-time/shared/tee-sheet/tee-sheet.bulk';
import { DefaultsSettingConfig, TeeTimeConfig, PlayerTypes as PlayerType, RateType } from 'src/app/tee-time-actions/teetime/player-details/player-info.model';
import { AllocationBlockWithPlayerType, API as AllocationBlockAPI } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import * as PlayerTypeAPI from 'src/app/settings/golf-setup/code-setup/player-type/player-type.modal';
import * as RateTypeAPI from 'src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal';
import * as RateSetupAPI from 'src/app/settings/rate-setup/rate-setup.model';
import { AllocationBlockDataService } from 'src/app/shared/data-services/golfschedule/allocationblock.data.service';
import { PlayerTypeService } from 'src/app/shared/data-services/golfschedule/playertype.data.service';
import { RateTypeDataService } from 'src/app/shared/data-services/golfschedule/ratetype.data.service';
import { RateSetupDataService } from 'src/app/shared/data-services/golfschedule/rate-setup.data.service';
import { rateType } from 'src/app/tee-time/waitlist/create-waitlist-modal/create-waitlist-modal';
import { CodeLinkingDataService } from 'src/app/shared/data-services/golfschedule/codelinking.data.service';
import {API, API as settingsAPI } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { SettingsDataService } from 'src/app/shared/data-services/golfschedule/settings.data.service';
import { SettingModule, SettingScreen } from 'src/app/shared/global.constant';
import * as _ from 'lodash';
import { RateSetupData } from './copy-player-modal.model';
import { BulkTeeTimeService } from 'src/app/tee-time/tee-sheet/bulk-tee-time/bulk-tee-time.service';
import { TeeTimeService } from 'src/app/tee-time-actions/teetime/tee-time.service';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import { NotifierBar } from "src/app/shared/components/note/note.model";
import { CourseCommentDataService } from 'src/app/shared/data-services/golfschedule/courseComment.data.service';
import { allocationBlockPlayerTypePermission } from "src/app/settings/user-setup/booking-permission/booking-permission.model";
import { AllocationBlockDataService as AllocationBlockManagementDataService } from 'src/app/shared/data-services/golfmanagement/allocationblock.data.service';
import { UserAccessModel } from "src/app/common/dataservices/authentication/useraccess-model.model";

@Injectable()

export class CopyPlayerBusiness {

    rateTypes: rateType[];
    private readonly captions: any;
    constructor(
        private _CopyPlayerService: CopyPlayerService, 
        private _courseDataService: CourseDataService, 
        private _TeeSheetCopy: TeeSheetCopy, 
        public _TeeSheetBulk: TeeSheetBulk,
        private _teeTimeDataService: TeeTimeDataService, 
        private _TeeTimesActionBusiness: TeeTimesActionBusiness, 
        private _TeeTimesBulkGroupBusiness:TeeTimesBulkGroupBusiness,
        private _allocationBlockService: AllocationBlockDataService,
        private _playerTypeService: PlayerTypeService,
        private _rateTypeService: RateTypeDataService, 
        private _rateSetupDataService: RateSetupDataService,
        private _codeLinkingDataService: CodeLinkingDataService,
        private _settingService: SettingsDataService,
        private _localization: GolfLocalization, 
        private _BulkTeeTimeService : BulkTeeTimeService,
        private _teeTimeService: TeeTimeService,
        private _userAccessBusiness: UserAccessBusiness,
        private _courseCommentDataService: CourseCommentDataService,
        private allocationCodeManagementService: AllocationBlockManagementDataService) {
        this.captions = this._localization.captions.teetime;
    }

    getCaptions() {
        return {
            date: this.captions.selectdate,
            course: this.captions.course,
            allocationCode: this.captions.allocationCode,
            playerType: this.captions.playerType,
            rateType: this.captions.rateType
        };
    }
     GetErrorCaptions() {
        return this._CopyPlayerService.getErrorCaptions();
      }
      GetSettingsCaptions() {
        return this._localization.captions.settings;
      }

    getPlayersDetails(playerInfo) {
        return [
            {
                label: this.captions.course,
                value: playerInfo ? playerInfo.course.course : ''
            },
            {
                label: this.captions.teetime,
                value: playerInfo ? this._localization.LocalizeTime(playerInfo.time) : ''
            },
            {
                label: this.captions.players,
                value: playerInfo ? playerInfo.playerDetail.length : ''
            }
        ];
    }

    getPlayerList(playerList) {
        return this._CopyPlayerService.getPlayerList(playerList);
    }

    getTeeSheeetID() {
        return this._CopyPlayerService.getTeeSheeetID();
    }

    getTeeSheetCustomTableData(teeDialogInfo) {
        return this._CopyPlayerService.getTeeSheetCustomTableData(teeDialogInfo);
    }

    public getInitialTeeTimes(): TeeSheetSkeletonData[] {
        return this._TeeSheetBulk.getInitialTeeSlots();
    }

    public getCourses() {
      return this._courseDataService.getCoursesWithUserAccess();
             
    }

    public getCourseComments(courseId: number, scheduleDate: Date){
      return this._courseCommentDataService.getCourseCommentByCourseIdandDate(courseId, scheduleDate);
     
    }


    public getTeeSheetSkeletonData(course, date,allCourses,allRatetypes) {
        return this._TeeSheetBulk.getTeeSheet(course, date,undefined,allCourses,undefined,allRatetypes);
    }

    public updateTeeSlots(slots: TeeSheetSkeletonData[]): void {
        this._TeeSheetBulk.addTeeSlots(_.cloneDeep(slots));
    }
    

    mapTeeSheetplayerDetails(ScheduledPlayers: ScheduledPlayer[], rateTypes: RateType[]): TeeSheetPlayerDetails[] {
        return this._TeeTimesBulkGroupBusiness.mapToPlayerDetails(ScheduledPlayers, rateTypes);
    }

    getBulkPlayers(bookingId: string): Promise<ScheduledPlayer[]> {
        return this._teeTimeDataService.getScheduledPlayersByBookingId(bookingId);
    }

    public mapPlayerTeeTimeSlots(modifiedSlots: TeeSheetSkeletonData[]): PlayerTeeTimeSlot[] {
        return this._TeeTimesActionBusiness.mapPlayerTeeTimeSlots(modifiedSlots);
    }

    public generateModifiedPlayersTeeSlots(initialTeeSlots: TeeSheetSkeletonData[], modifiedTeeSlots: TeeSheetSkeletonData[],) : PlayerTeeTimeSlot[]{
        return this._TeeTimesActionBusiness.generateModifiedPlayersTeeSlots(initialTeeSlots, modifiedTeeSlots);
    }

    public mapAllocationBlockDataAPI(allocationBlockApiData: AllocationBlockAPI.TeeTimeAllocationBlock): AllocationCode {
        return {
          id: allocationBlockApiData.id,
          allocationBlockCode: allocationBlockApiData.allocationBlockCode,
          allocationBlockName: allocationBlockApiData.allocationBlockName,
          colorCode: allocationBlockApiData.colorCode,
          daysOut: allocationBlockApiData.daysOut
        } as AllocationCode;
      }

    public mapAllocationBlockData(allocationBlockApiData: Allocation): AllocationCode {
        return {
            id: allocationBlockApiData.id,
            allocationBlockCode: allocationBlockApiData.name,
            allocationBlockName: allocationBlockApiData.name,
            colorCode: allocationBlockApiData.colorCode,
            daysOut: 0
        } as AllocationCode;
    }

    public buildTeeSlots(teeSheetSkeletonData: TeeSheetSkeletonData[], playerDetails: TeeSheetPlayerDetails[], startDateTime: Date | string, playersperGroup: number, allocation: AllocationCode, hole : string): TeeSlotDetails {
        return this._TeeTimesBulkGroupBusiness.buildTeeSlots(teeSheetSkeletonData, playerDetails, startDateTime, playersperGroup, true, hole);
    }

    public getBulkStatus(teeSlotDetails: TeeSlotDetails, teeSheetdata: TeeSheetSkeletonData[]): Bulkstatus {
        return this._TeeTimesBulkGroupBusiness.getStatus(teeSlotDetails,teeSheetdata);
    }

    public getSqueezeSlots(currentTeeSheetSkeleton: TeeSheetSkeletonData[], bulkstatus : Bulkstatus, squeezeMins : number ): TeeSheetSkeletonData[]{
      return this._TeeTimesBulkGroupBusiness.getSqueezeSlots(currentTeeSheetSkeleton,bulkstatus, squeezeMins);
        
      }
    
      public addNewSlots(squeezedTeeSlots: TeeSheetSkeletonData[], initialTeeSlots: TeeSheetSkeletonData[]): TeeSheetSkeletonData[] {
        return this._TeeTimesBulkGroupBusiness.addNewSlots(squeezedTeeSlots, initialTeeSlots);
      }

    public buildTeeSheetSkeletonData(currentTeeSheetSkeleton: TeeSheetSkeletonData[], teeSlots: TeeSheetSkeletonData[], playerDetails: TeeSheetPlayerDetails[], playersPerGroup: number, allocation: AllocationCode, isRemovable: boolean, squeezeSlots : TeeSheetSkeletonData[]): TeeSheetSkeletonData[] {
        return this._TeeTimesBulkGroupBusiness.buildTeeSheetSkeletonData(currentTeeSheetSkeleton, teeSlots, playerDetails, playersPerGroup, allocation, isRemovable, squeezeSlots);
    }

    public updateTeeSheetGridContent(modifiedTeeSheetSkeletonData: TeeSheetSkeletonData[]) {
        return this._TeeTimesBulkGroupBusiness.updateTeeSheetGridContent(modifiedTeeSheetSkeletonData);
    }

    public async getRateTypes(): Promise<RateType[]> {
        let rateTypeApiData: RateTypeAPI.RateType[] = await this._rateTypeService.getAllRateTypes(false);
        this.rateTypes = rateTypeApiData;      
        return rateTypeApiData.map(x => this.mapRateTypeData(x));
      }
      private mapRateTypeData(rateTypeApiData: RateTypeAPI.RateType): RateType {
        return {
          id: rateTypeApiData.id,
          type: rateTypeApiData.type,
          daysOutStart: rateTypeApiData.daysOutStart,
          daysOutEnd: rateTypeApiData.daysOutEnd
        } as RateType;
      }
      public mapPlayerRateTypetoRateType(rateTypePlayerData: PlayerRateType): RateType
      {
        return {
          id: rateTypePlayerData.id,
          type: rateTypePlayerData.name,
          daysOutStart: rateTypePlayerData.daysOutStart,
          daysOutEnd: rateTypePlayerData.daysOutEnd
        } as RateType;
      }
      public async getPlayerTypes(): Promise<PlayerType[]> {
        let playerTypeApiData: PlayerTypeAPI.PlayerType[] = await this._playerTypeService.getAllPlayerTypes();
        return playerTypeApiData.map(x => this.mapPlayerTypeData(x));
      }
      private mapPlayerTypeData(playerTypeApiData: PlayerTypeAPI.PlayerType): PlayerType {
        return {
          id: playerTypeApiData.id,
          type: playerTypeApiData.type,
          daysOutStart: playerTypeApiData.daysOutStart,
          daysOutEnd: playerTypeApiData.daysOutEnd
        } as PlayerType;
      }

      public async getAllocationCode(): Promise<AllocationCode[]> {
        let allocationBlockApiData: AllocationBlockAPI.TeeTimeAllocationBlock[] = await this._allocationBlockService.getAllocationBlocks(false);
        return allocationBlockApiData.map(x => this.mapAllocationBlockDataAPI(x));
      }

      public async getAllocationsBlockWithPlayerTypes() {
        return await this._allocationBlockService.getAllocationsBlockWithPlayerTypes();
      }
      public async getAllPlayerTypeWithRateType() {
        return await this._codeLinkingDataService.getPlayerTypeRateTypes();
      }

      public async getTeeFeesForCourseAndDate(courseId: number, date: Date): Promise<RateSetupData[]> {
        let rateSetupApiData: RateSetupAPI.RateSetupData[] = await this._rateSetupDataService.getTeeFeesForCourseAndDate(courseId, date);
        return rateSetupApiData.map(x => this.mapRateSetupData(x));
      }
      public async getDefaultSettings(): Promise<DefaultsSettingConfig> {
        let defaultsApiData: settingsAPI.Settings<settingsAPI.DefaultsSettingConfig> = await this._settingService.getSettings<settingsAPI.DefaultsSettingConfig>(SettingModule.SystemSetup, SettingScreen.Defaults);
        return this.mapDefaultData(defaultsApiData.configValue);
      }
      public async getTeeTimeSettings(): Promise<TeeTimeConfig> {
        let teeTimeApiData: settingsAPI.Settings<settingsAPI.TeeTimeConfig> = await this._settingService.getSettings<settingsAPI.TeeTimeConfig>(SettingModule.SystemSetup, SettingScreen.TeeTime);
        return this.mapTeeTimeData(teeTimeApiData.configValue);
    
      }
      private mapRateSetupData(rateSetupApiData: RateSetupAPI.RateSetupData): RateSetupData {
        return {
            id: rateSetupApiData.id,
            courseId: rateSetupApiData.courseId,
            rateTypeId: rateSetupApiData.rateTypeId,
            cartFee: rateSetupApiData.cartFee,
            greenFee: rateSetupApiData.greenFee,
            greenFeeRetailItemId: rateSetupApiData.greenFeeRetailItemId,
            cartFeeRetailItemId: rateSetupApiData.cartFeeRetailItemId
        } as RateSetupData;
    }
      private mapDefaultData(configValue: settingsAPI.DefaultsSettingConfig): DefaultsSettingConfig {
        return {
          blockingCode: configValue.blockingCode,
          memberStatus: configValue.memberStatus,
          memberPlayerType: configValue.memberPlayerType,
          memberRateType: configValue.memberRateType,
          nonMemberPlayerType: configValue.nonMemberPlayerType,
          nonMemberRateType: configValue.nonMemberRateType,
          resortPlayerType: configValue.resortPlayerType,
          resortRateType: configValue.resortRateType,
          memberGuestPlayerType: configValue.memberGuestPlayerType,
          memberGuestRateType: configValue.memberGuestRateType,
          memberGuestsToAdd: configValue.memberGuestsToAdd,
          memberGuestPlayerFirstName: configValue.memberGuestPlayerFirstName,
          memberGuestPlayerLastName: configValue.memberGuestPlayerLastName,
          autoSelectPlayers: configValue.autoSelectPlayers
        }      
      }
      private mapTeeTimeData(apiData: settingsAPI.TeeTimeConfig): TeeTimeConfig {
        return {
          rainCheckNote: apiData.rainCheckNote,
          ticketNote: apiData.ticketNote,
          requirePlayerType: apiData.requirePlayerType,
          requireRateType: apiData.requireRateType,
          showMemberSignOnTeeGrid: apiData.showMemberSignOnTeeGrid,
          defaultToGraphicalTeeSheet: apiData.defaultToGraphicalTeeSheet,
          promptUserToPrintTeeTickets: apiData.promptUserToPrintTeeTickets,
          autoPrintTeeTickets: apiData.autoPrintTeeTickets,
          requireTeeTimesToPaid: apiData.requireTeeTimesToPaid,
          editCaddyInfoOnMoveCopy : apiData.editCaddyInfoOnMoveCopy
        };    
      }
      async GetEmailConfigurationSetting() : Promise<API.Settings<API.EmailConfigurationSettingConfig>>{
        return this._settingService.getSettings<API.EmailConfigurationSettingConfig>(SettingModule.SystemSetup, SettingScreen.EmailConfiguration);      
     }

      ShowAlert(errorMessage, errorType, buttonType,  callback) {
        this._BulkTeeTimeService.showAlert(errorMessage, errorType, buttonType,  callback);
    }
    
    getCourseComment(comment: string): NotifierBar {
      let message: NotifierBar = {
          class: "icon-servicecharge",
          value: '',
          color: "#fff16e"
      }
      message.value = comment ? comment : '';
      return message;
  }

    async ValidateUserBreakPoints() : Promise<UserAccessModel.BreakPointResult>{
      return await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.CHANGEALLOCATIONBLOCK, false);
     
    }
  
    public showBreakPointPopup(functionName?: string, callBack?: any) {
        this._userAccessBusiness.showBreakPointPopup(functionName);
    }

    public async validateBreakPointAccess(breakPointNumber: number, showPopup: boolean = true): Promise<boolean> {
        let breakPointResult: UserAccessModel.BreakPointResult = await this._userAccessBusiness.getUserAccess(breakPointNumber, showPopup);
        return breakPointResult && breakPointResult.isAllow;
    }

    public async GetAllocationBlockPermissionByRole(roleId?: string):Promise<allocationBlockPlayerTypePermission[]> {
      return await this.allocationCodeManagementService.getAllocationBlockPermissionByRole(roleId);
    }

    public async GetAllocationsBlockWithPlayerTypes():Promise<AllocationBlockWithPlayerType[]> {
      return await this.allocationCodeManagementService.getAllocationsBlockWithPlayerTypes();
    }
}