import { Component, OnInit, EventEmitter, Input, Output, ViewEncapsulation, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActionMode } from "src/app/common/enums/shared-enums";
import { Localization } from "src/app/common/localization/localization";
import { StaffReaderBoardBusiness } from '../staff-reader-board.business';
import {
  AgDateConfig,
  AgTimeConfig,
  AgToggleConfig,
  ButtonValue,
  DropdownOptions
} from "src/app/common/Models/ag-models";
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { takeUntil } from 'rxjs/operators';
import { of, ReplaySubject } from 'rxjs';
import { PropertyInformation } from 'src/app/common/shared/services/property-information.service';
import { UI, API } from 'src/app/common/Models/staff-reader-board-model';
import moment from 'moment';

@Component({
  selector: 'app-create-staff-reader-board',
  templateUrl: './create-staff-reader-board.component.html',
  styleUrls: ['./create-staff-reader-board.component.scss'],
  providers: [StaffReaderBoardBusiness]
})
export class CreateStaffReaderBoardComponent implements OnInit {
  captions: any;
  createStaffReaderForm: UntypedFormGroup;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  @Input() noteId: number;
  @Input() crudInput: any;
  @Output() handleClickEvent = new EventEmitter();
  floatLabel: any;
  priorityList: any = [];
  showNoteToggleInput: AgToggleConfig;
  popupValidFromDateInput: AgDateConfig;
  popupValidFromTimeInput: AgTimeConfig;
  popupValidToDateInput: AgDateConfig;
  popupValidToTimeInput: AgTimeConfig;
  modeOptions: any;
  selectedMode: any = 1;
  selectedRoleData: any[] = [];
  searchKey: string[] = ["id", "viewValue"];
  autoCompleteKeys: string[] = ["viewValue"];
  selectedChipKey: string[] = ["viewValue"];
  selectedNewChipKey: string[] = ["viewValue"];
  setChipError: boolean;
  allUserData: DropdownOptions[] = [];
  userData: DropdownOptions[] = [];
  allRolesData: DropdownOptions[] = [];
  rolesData: DropdownOptions[] = [];
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  propertyDate: Date;
  selectedData: UI.StaffReaderBoardDetails[] = [];
  timeformat: any;

  constructor(
    private localization: Localization,
    private fb: UntypedFormBuilder,
    private staffReaderBoardBusiness: StaffReaderBoardBusiness,
    private utils: CommonUtilities,
    private _propertyInformation: PropertyInformation
  ) {
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
    this.propertyDate = this._propertyInformation.CurrentDate;
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  async initializeForm() {
    this.modeOptions = this.staffReaderBoardBusiness.GetModeTypes();
    this.priorityList = this.staffReaderBoardBusiness.getPriorityList();
    this.timeformat = this.localization.getTimeFormat();

    this.createStaffReaderForm = this.fb.group({
      id: 0,
      notedetails: ['', Validators.required],
      priority: 1,
      shownote: false,
      popupValidFromDate: this.propertyDate,
      popupValidFromTime: ['', Validators.required],
      popupValidToDate: this.propertyDate,
      popupValidToTime: ['',Validators.required],
      mode: 1,
      selectedData: ''
    });

    this.showNoteToggleInput = {
      group: this.createStaffReaderForm,
      formControlName: "shownote",
      label: "",
      automationId: "createStaffReaderBoard"
    };

    this.popupValidFromDateInput = {
      form: this.createStaffReaderForm,
      formControlName: "popupValidFromDate",
      placeHolderId: "lbl_startDate",
      className: "time-width ag_pr--6",
      placeHolder: this.captions.lbl_showpopupStartDate,
      isDateRequired: true,
      errorMessage: this.captions.MissingStartDate,
      minDate: this.propertyDate,
      //maxDate: this.propertyDate,
      automationId: "createStaffReaderBoard"
    };

    this.popupValidFromTimeInput = {
      form: this.createStaffReaderForm,
      formControlName: "popupValidFromTime",
      placeHolder: this.captions.lbl_showpopupStartTime,
      placeHolderId: "lbl_startTime",
      className: "time-width",
      isTimeRequired: true,
      errorMessageId: 'err_missingStartTime',
      errorMessage: this.captions.err_missingStartTime,
      defaultTime: this.localization.getTime(this.propertyDate, this.timeformat),
      minTime: this.setminTime(),
      automationId: "createStaffReaderBoard"
    };

    this.popupValidToDateInput = {
      form: this.createStaffReaderForm,
      formControlName: "popupValidToDate",
      placeHolderId: "lbl_endDate",
      className: "time-width ag_pr--6",
      placeHolder: this.captions.lbl_showpopupEndDate,
      isDateRequired: true,
      errorMessage: this.captions.MissingEndDate,
      minDate: this.propertyDate,
      automationId: "createStaffReaderBoard"
    };

    this.popupValidToTimeInput = {
      form: this.createStaffReaderForm,
      formControlName: "popupValidToTime",
      placeHolder: this.captions.lbl_showpopupEndTime,
      placeHolderId: "lbl_endTime",
      className: "time-width",
      isTimeRequired: true,
      errorMessageId: 'err_missingendtime',
      errorMessage: this.captions.err_missingendtime,
      defaultTime: this.timeformat == 12 ? '11:59 PM' : '23:59',
      automationId: "createStaffReaderBoard"
    };


    this.actionButton = {
      type: "primary",
      label: this.captions.btn_create,
      disabledproperty: true,
    };
    this.cancelButton = {
      type: "tertiary",
      label: this.captions.btn_cancel,
    };

    await this.getUserNameAndRoles();

    this.createStaffReaderForm.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
        this.actionButton.disabledproperty = !(this.createStaffReaderForm.dirty && this.createStaffReaderForm.valid);
      });

    if (this.noteId > 0) {
      this.fillStaffReaderBoard(this.noteId);

      this.actionButton = {
        type: "primary",
        label: this.captions.btn_update,
        disabledproperty: true,
      };
    }
  }

  setminTime() {
    if(this.createStaffReaderForm.controls.popupValidFromDate.value.getDate() > this.propertyDate.getDate() || this.createStaffReaderForm.controls.popupValidFromDate.value.getMonth() > this.propertyDate.getMonth() || this.createStaffReaderForm.controls.popupValidFromDate.value.getFullYear() > this.propertyDate.getFullYear()){
      this.popupValidFromTimeInput.value = this.popupValidFromTimeInput.form.value.popupValidFromTime;
      this.popupValidFromTimeInput.minTime = null;
      return convertedTime;
    }
    if(this.popupValidFromTimeInput != undefined && this.createStaffReaderForm.controls.popupValidFromDate.value.getDate() == this.propertyDate.getDate() && this.createStaffReaderForm.controls.popupValidFromDate.value.getMonth() == this.propertyDate.getMonth() && this.createStaffReaderForm.controls.popupValidFromDate.value.getFullYear() == this.propertyDate.getFullYear() && this.popupValidFromTimeInput.form.value.id == 0){
      let totalhours = this.utils.getPropertyTime()?.split(',')[1]?.split(':')[0];
      let totlmins = this.utils.getPropertyTime()?.split(',')[1]?.split(':')[1];
      let ampm = this.utils.getPropertyTime()?.split(',')[1]?.split(':')[2]?.split(' ')[1];
      let overalTime = totalhours + ':' + totlmins + ' ' + ampm
      var convertedTime = moment(overalTime, 'hh:mm A').format('HH:mm');
      this.popupValidFromTimeInput.minTime = convertedTime;
      this.popupValidFromTimeInput = {...this.popupValidFromTimeInput}
      return convertedTime;
      
    }
    if(this.popupValidFromTimeInput != undefined && this.createStaffReaderForm.controls.popupValidFromDate.value.getDate() == this.propertyDate.getDate() && this.createStaffReaderForm.controls.popupValidFromDate.value.getMonth() == this.propertyDate.getMonth() && this.createStaffReaderForm.controls.popupValidFromDate.value.getFullYear() == this.propertyDate.getFullYear() && this.popupValidFromTimeInput.form.value.id > 0){
      
      let totalhours = this.utils.getPropertyTime()?.split(',')[1]?.split(':')[0];
      let totlmins = this.utils.getPropertyTime()?.split(',')[1]?.split(':')[1];
      let ampm = this.utils.getPropertyTime()?.split(',')[1]?.split(':')[2]?.split(' ')[1];
      let overalTime = totalhours + ':' + totlmins + ' ' + ampm
      var convertedTime = moment(overalTime, 'hh:mm A').format('HH:mm');
      this.popupValidFromTimeInput.minTime = convertedTime;
      this.popupValidFromTimeInput.maxTime=null;
      this.popupValidFromTimeInput = {...this.popupValidFromTimeInput}
      return convertedTime;
    }
  }

  async getUserNameAndRoles() {
    this.utils.ToggleLoader(true);
    let result= await Promise.all([this.staffReaderBoardBusiness.getActiveuserRoles(),this.staffReaderBoardBusiness.getAllUserId()]);
    this.allRolesData = result[0];
    this.allUserData = result[1];
    this.utils.ToggleLoader(false);
  }

  async fillStaffReaderBoard(noteId: number) {
    let staffReaderBoard = await this.staffReaderBoardBusiness.GetStaffReaderBoardById(this.noteId);

    this.createStaffReaderForm.patchValue(staffReaderBoard);

    this.selectedData = staffReaderBoard.selectedData;

    this.selectedData.forEach(x => {
      x.receivedByUserName = this.allUserData.find(y => y.id == x.receivedByUserId)?.viewValue;
    });

    this.createStaffReaderForm.controls['selectedData'].setValue(this.selectedData);

    this.startDateChange('');
    this.endDateChange('');
    this.setminTime();
    if (this.createStaffReaderForm.controls.popupValidToDate.value.getDate() == this.createStaffReaderForm.controls.popupValidFromDate.value.getDate() && this.createStaffReaderForm.controls.popupValidToDate.value.getMonth() == this.createStaffReaderForm.controls.popupValidFromDate.value.getMonth() && this.createStaffReaderForm.controls.popupValidToDate.value.getFullYear() == this.createStaffReaderForm.controls.popupValidFromDate.value.getFullYear()) {
      this.popupValidToTimeInput.minTime = this.utils.addMinsInTime(this.createStaffReaderForm.value.popupValidFromTime, 1);
      this.popupValidToTimeInput = { ...this.popupValidToTimeInput };
      this.popupValidFromTimeInput.maxTime = this.utils.subMinsInTime(this.createStaffReaderForm.value.popupValidToTime, 1);
      this.popupValidFromTimeInput = { ...this.popupValidFromTimeInput };
    }    
  }

  timeChanged(eve, from) {
    if (this.createStaffReaderForm.controls[eve[1]].value) {
      switch (from) {
        case 'fromTime':
          if(this.createStaffReaderForm.controls.popupValidToDate.value.getDate() == this.createStaffReaderForm.controls.popupValidFromDate.value.getDate() && this.createStaffReaderForm.controls.popupValidToDate.value.getMonth() == this.createStaffReaderForm.controls.popupValidFromDate.value.getMonth() && this.createStaffReaderForm.controls.popupValidToDate.value.getFullYear() == this.createStaffReaderForm.controls.popupValidFromDate.value.getFullYear())
          {
          this.popupValidToTimeInput.minTime = this.utils.addMinsInTime(this.createStaffReaderForm.value.popupValidFromTime, 1);
         
          if(this.popupValidToTimeInput.minTime.includes('12:00 am') || this.popupValidToTimeInput.minTime.includes('00:00') )
          {
            this.popupValidToDateInput.minDate = this.utils.AddDays( this.createStaffReaderForm.controls.popupValidFromDate.value,1);
            this.createStaffReaderForm.controls.popupValidToDate.setValue(this.utils.AddDays(this.createStaffReaderForm.controls.popupValidFromDate.value,1))
          }          
          this.popupValidToDateInput = { ...this.popupValidToDateInput };
          this.popupValidToTimeInput = { ...this.popupValidToTimeInput };
          }   
          else{
            this.popupValidToDateInput.minDate =  this.createStaffReaderForm.controls.popupValidFromDate.value;
            this.popupValidToDateInput = { ...this.popupValidToDateInput };
          } 
          break;
        case 'toTime':
          if(this.createStaffReaderForm.controls.popupValidToDate.value.getDate() == this.createStaffReaderForm.controls.popupValidFromDate.value.getDate() && this.createStaffReaderForm.controls.popupValidToDate.value.getMonth() == this.createStaffReaderForm.controls.popupValidFromDate.value.getMonth() && this.createStaffReaderForm.controls.popupValidToDate.value.getFullYear() == this.createStaffReaderForm.controls.popupValidFromDate.value.getFullYear())
          {          
          this.popupValidFromTimeInput.maxTime = this.utils.subMinsInTime(this.createStaffReaderForm.value.popupValidToTime, 1);
          this.popupValidFromTimeInput = { ...this.popupValidFromTimeInput };
          }
          else{
            this.popupValidFromTimeInput.maxTime = null;
            this.popupValidFromTimeInput = { ...this.popupValidFromTimeInput };
          }
          break;
      }
    }
  }

  SearchTextHandler(eve) {
    if (eve != "" && eve.length > 0){
      this.searchUsers(eve);
    }
    else{
      this.userData=[];
    }
  }


  searchUsers(eve) {
    var enteredSearchId = eve;
    var filtered = this.allUserData.filter(str => { return (str.id.toString().includes(enteredSearchId) || str.viewValue.toUpperCase().includes(enteredSearchId.toUpperCase())) && !(this.selectedData.filter(x => x.receivedByUserId == str.id).length > 0) });
    this.userData = filtered;
  }

  SearchRolesTextHandler(eve) {
    if (eve != "" && eve.length > 0){
      this.searchRoles(eve);
    }
    else{
      this.rolesData=[];
    }
 
  }

  searchRoles(eve) {
    var enteredSearchId = eve;
    var filtered = this.allRolesData.filter(str => { return (str.id.toString().includes(enteredSearchId) || str.viewValue.toUpperCase().includes(enteredSearchId.toUpperCase())) && !(this.selectedRoleData.filter(x => x.id == str.id).length > 0) });
    this.rolesData = filtered;
  }

  removeUser(value, i) {
    let idx = this.selectedData.findIndex(x => x.receivedByUserId == value.receivedByUserId);

    if (idx > -1)
      this.selectedData.splice(idx, 1);

    if (this.createStaffReaderForm.value.mode == 1) {
      this.selectedRoleData.filter(x => x.data.filter(y => y.id == value.receivedByUserId).length > 0).forEach(x => {
        x.data.filter(y => y.id == value.receivedByUserId).forEach(y => y.selected = false);
      });
    }
  }

  selectedChipDataEmit(e) {
    let usersAdded = e.filter(x => !this.selectedData.map(y => y.receivedByUserId).includes(x.id));

    usersAdded.forEach(x => {
      this.selectedData.push({ id: 0, receivedByUserId: x.id, receivedByUserName: x.viewValue } as UI.StaffReaderBoardDetails);
    });
  }

  selectedRolesChipDataEmit(e) {
    let rolesAdded = e.filter(x => !this.selectedRoleData.map(y => y.id).includes(x.id));
    let rolesRemoved = this.selectedRoleData.filter(x => !e.map(y => y.id).includes(x.id));

    if (rolesAdded.length > 0) {
      let userList = this.staffReaderBoardBusiness.allUserData.filter(x => x.userPropertyAccesses.filter(y => y.roleId == rolesAdded[0].id).length > 0)
        .map(z => { return { id: z.userId, name: z.firstName + " " + z.lastName, selected: this.selectedData.filter(x => x.receivedByUserId == z.userId).length > 0 } });

      var data = {
        id: rolesAdded[0].id,
        parentName: rolesAdded[0].viewValue,
        isAllChecked: false,
        label: this.captions.lnk_selectAll,
        data: userList
      }
      this.selectedRoleData.push(data);
    }

    if (rolesRemoved.length > 0) {
      let index = this.selectedRoleData.findIndex(x => x.id == rolesRemoved[0].id);

      if (index > -1)
        this.selectedRoleData.splice(index, 1);
    }
  }

  modeChange(eve) {
    this.setminTime();
    this.selectedMode = eve.value;
    this.selectedRoleData = [];
  }

  onCancel(eve) {
    this.handleClickEvent.emit({
      form: this.createStaffReaderForm,
      mode: ActionMode.cancel
    });
  }

  userChangeEvent(event, selectUserObj, selectObj) {
    selectUserObj.selected = event.checked;
    this.setminTime();
    if (selectUserObj.selected && !this.selectedData.map(x => x.receivedByUserId).includes(selectUserObj.id)) {
      this.selectedData.push({ id: 0, receivedByUserId: selectUserObj.id, receivedByUserName: selectUserObj.name } as UI.StaffReaderBoardDetails);
    }
    if (!selectUserObj.selected) {
      selectObj.isAllChecked = false
      const index = this.selectedData.findIndex(x => x.receivedByUserId == selectUserObj.id);

      if (index > -1)
        this.selectedData.splice(index, 1);
    }
  }

  searchUser(event, searchObj) {
    searchObj.data.forEach(d => {
      d.isVisible = d.name.toLocaleLowerCase().includes(event.toLocaleLowerCase());
    });
  }


  selectUnselectUser(data) {
    data.isAllChecked = !data.isAllChecked;
    if (data.isAllChecked) {
      data.data.forEach((x) => {
        if (!x.selected) {
          this.selectedData.push({ id: 0, receivedByUserId: x.id, receivedByUserName: x.name } as UI.StaffReaderBoardDetails)
        }
        x.selected = true;
      });
      data.label = this.captions.lbl_unSelectAll;
    }
    else {
      data.data.forEach((x) => {
        x.selected = false;
        const index = this.selectedData.findIndex(y => y.receivedByUserId == x.id);

        if (index > -1)
          this.selectedData.splice(index, 1);
      });
      data.label = this.captions.lnk_selectAll;
    }
  }

  onAction(eve) {
    this.createStaffReaderForm.controls['selectedData'].setValue(this.selectedData);
    this.handleClickEvent.emit({
      form: this.createStaffReaderForm,
      mode: ActionMode.create
    });
  }

  startDateChange(eve) {
    if (this.createStaffReaderForm.controls.popupValidFromDate.value.getDate() >= this.createStaffReaderForm.controls.popupValidToDate.value.getDate()) {
      this.popupValidToDateInput.minDate = this.createStaffReaderForm.controls.popupValidFromDate.value;
      this.createStaffReaderForm.controls.popupValidToDate.setValue(this.createStaffReaderForm.controls.popupValidFromDate.value)
    } else {
      this.popupValidToDateInput.minDate = this.createStaffReaderForm.controls.popupValidFromDate.value;
    }
    this.setminTime();
    if (this.createStaffReaderForm.controls.popupValidToDate.value.getDate() == this.createStaffReaderForm.controls.popupValidFromDate.value.getDate() && this.createStaffReaderForm.controls.popupValidToDate.value.getMonth() == this.createStaffReaderForm.controls.popupValidFromDate.value.getMonth() && this.createStaffReaderForm.controls.popupValidToDate.value.getFullYear() == this.createStaffReaderForm.controls.popupValidFromDate.value.getFullYear()) {
      this.popupValidToTimeInput.minTime = this.utils.addMinsInTime(this.createStaffReaderForm.value.popupValidFromTime, 1);
      if(this.popupValidToTimeInput.minTime.includes('12:00 am') || this.popupValidToTimeInput.minTime.includes('00:00') )
          {
            this.popupValidToDateInput.minDate = this.utils.AddDays( this.createStaffReaderForm.controls.popupValidFromDate.value,1);
            this.createStaffReaderForm.controls.popupValidToDate.setValue(this.utils.AddDays(this.createStaffReaderForm.controls.popupValidFromDate.value,1))
          }          
          this.popupValidToDateInput = { ...this.popupValidToDateInput };
      this.popupValidToTimeInput = { ...this.popupValidToTimeInput };      
    }     
    else
    {
      this.popupValidToDateInput.minDate =  this.createStaffReaderForm.controls.popupValidFromDate.value;
      this.popupValidToDateInput = { ...this.popupValidToDateInput };
      this.popupValidToTimeInput.minTime = null;
      this.popupValidToTimeInput = { ...this.popupValidToTimeInput };
    }
    this.popupValidFromTimeInput = { ...this.popupValidFromTimeInput};
    this.popupValidToDateInput = { ...this.popupValidToDateInput };
  }

  endDateChange(eve) {
    if (this.createStaffReaderForm.controls.popupValidToDate.value.getDate() == this.createStaffReaderForm.controls.popupValidFromDate.value.getDate() && this.createStaffReaderForm.controls.popupValidToDate.value.getMonth() == this.createStaffReaderForm.controls.popupValidFromDate.value.getMonth() && this.createStaffReaderForm.controls.popupValidToDate.value.getFullYear() == this.createStaffReaderForm.controls.popupValidFromDate.value.getFullYear()) {
      this.popupValidToTimeInput.minTime = this.utils.addMinsInTime(this.createStaffReaderForm.value.popupValidFromTime, 1);
      this.popupValidToTimeInput = { ...this.popupValidToTimeInput };
      this.popupValidFromTimeInput.maxTime = this.utils.subMinsInTime(this.createStaffReaderForm.value.popupValidToTime, 1);
      this.popupValidFromTimeInput = { ...this.popupValidFromTimeInput };
    } 
    else{
      this.popupValidToTimeInput.minTime = null;
      this.popupValidToTimeInput = { ...this.popupValidToTimeInput };
      this.popupValidFromTimeInput.maxTime = null;
      this.popupValidFromTimeInput = { ...this.popupValidFromTimeInput };
    }
  }
}
