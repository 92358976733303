import { GiftCardType } from '../../globalsContant';
import { CardInputMethod, POSPostingRecord, PaymentDetails } from './payment-business.model';
import { PaymentMethods, TokentransactionInfo } from '../../business/shared.modals';
import { IntegrationOperationsTransactionType } from 'src/app/retail/retail.modals';
import { SearchTypes } from '../retail.feature.flag.information.service';


/**
 * contains the interfaces related to Payment requests
 */

//-----------------------------------------------------------------------------------------
//Request Model for Auth/Sale:

export interface SaleRequest {
    handle: string; //(Required)(Encrypted data)
    amount: Amount; //(Required)
    inquirerInfo: InquirerInformation; //(Required)
    hostName?: string;
    agentVersion?: string;
    ipAddress?: string;
    searchType: SearchTypes;
}

export interface Amount {
    requestAmount: number; // (Required)
    authorisedAmount?: number;
    tipAmount?: number;
    taxAmount?: number;
    saleAmount?: number;
}

export interface InquirerInformation {
    terminalId: string; // (Required)
    store?: string;
    division?: string;
    enterprise?: string;
    customer?: string;
    mealPeriod?: string;
    coverCount?: string;
    orderType?: string;
    orderNumber: string; // (Required)
    employeeId?: string;
    profitCenter: string; //(Required)
    tenderId: string; //(Required)
    zipcode?: string;
    isPartialTenderAllowed: boolean;
    financialBins?: Array<FinancialBin>;
    clientId: string;
    postingId: string;
    manualCardEntry?: boolean;
    posTransactionId?: number;
    isFolioPosting?: boolean;
    sourceTypeId?: string;
}

export interface FinancialBin {
    Id: string;
    type: string;
    amt: string;
}

//-----------------------------------------------------------------------------------------
//Response Model for Auth/Sale:

export interface SaleResponse {
    payAgentId: string;
    status: string;
    account: DisplayInformation;
    amount: Amount;
    transactionDetails: string; //(Encrypted)
    transactionKey: string;
    errorMessage: string;
    cardInfo: PayAgentCardInfo;
    cmsTransactionId?: string;
    requestId?: string;
    lodgingData?: string;
}

export interface DisplayInformation {
    id: string;
    name: string;
    type?: string;
}

export interface TransactionDetails {
    handle: HandleData;
    configurationId: number;
    allowVoidSale: boolean;
    status: number;
    Amount: Amount;
    transactionTime: string;
}

//-----------------------------------------------------------------------------------------

export interface PaymentMethod {
    id: number;
    isActive?: boolean;
    isDefault?: boolean;
    listOrder?: number;
    paymentMethod?: string;
    paymentTypeId: number;
    type?: string;
}
export const PaymentMethodIconConst = {
    '1': 'Cash',
    '2': 'Card',
    '3': 'hotel-guest',
    '4': 'Others'
};
export const PaymentMethodDisplayLabel = {
    1: 'Cash',
    2: 'Credit Card',
    3: 'Room Charge',
    5: 'Credit Card',
    22: 'CardOnFile'
};

export interface PayAgentResponse {
    payAgentID: string;
    status: string;
    transactionDetails: string;
    transactionKey: string;
    errorMessage?: string;
}

export const HttpResponseStatus = {
    Success: 'success',
    Failure: 'failed'
};

export interface GuestStayDetail {
    guestId: string;
    roomNo: string;
    confirmationNumber: string;
    status: ActivityStatus;
}


export enum ActivityStatus {
    Unknown = -1,
    Reserved,
    CheckedIn,
    CheckedOut,
    NoShow,
    Cancelled
}

export interface PaymentHistory {
    paymentMethodId: number;
    paymentMethod: string;
    issuerType?: string;
    cardNumber?: string;
    entryMode?: string;
    amount: number;
}

export interface DomainGatewayValue {
    id: number;
    configurationId: number;
    configKey: string;
    configValue: string;
    gatewayConfiguration: GatewayConfiguration;
}

export interface SaleByTokenRequest {
    inquirerInfo: InquirerInformation;
    transactionId: number;
    amount: number;
}

export interface SaleByTokenResponse {
    transactionId?: number;
    status?: boolean;
    errorMessage?: string;
    errorCode?: string;
    paymentManagerResponse: PMPayResponse;
}

export interface ValidatePayRequest {
    tenderId: number;
    payAgentResponse: PayAgentResponse;
    cardInfo: CardInfo;
    amount: number;
}

export interface ValidatePayResponse {
    transactionId?: number;
    status?: boolean;
    errorMessage?: string;
    paymentManagerResponse: PMPayResponse;
    transactionIdGeneratedFromToken?: number;   
    }

export interface TransactionUpdate
{
    transactionId: number;
    totalTax: number;
    totalAmount: number;
    totalGratuity: number;
    transactionTaxDetailUpdate: TaxDetailsUpdate[];
    transactionDetailUpdate: TransactionDetailUpdate[];
} 
export interface TransactionDetailUpdate
{
    tax: number;
    baseTax: number;
    linkedTax: number;
    transactionDetailId: number
    itemId: number;
    serviceChargeGratuity: ServiceChargeGratuityUpdate[]
} 
export interface TaxDetailsUpdate
{
    taxId: number;
    transactionDetailId?: number;
    taxAmount: number;
    baseTaxAmount?: number;
    linkedTaxAmount?: number;
    originalTaxAmount: number;
    transactionLinkedTaxDetailUpdate?: TaxDetailsUpdate[]
} 
export interface ServiceChargeGratuityUpdate
{
     isServiceCharge: boolean;
     serviceChargeTax: number;
     gratuityTax: number;
     staffId: number;
     staffType: string;
     taxDetails :TaxDetailsUpdate[];
     gratuity?: number;
     additionalGratuity?: number;
} 
export interface PMSPostRequest {
    guestName: string;
    roomNumber: string;
    roomReference: string;
    bookingId: string;
    amount: number;
    transactionId: number;
    transactionPaymentId: number;
    paymentMethodId: PaymentMethods;
    tenderId: number;
    paymentTypeId?: number;
    parentTypeId?: number;
    paymentMethodTableId?: number;
    multiPMSPropertyIndex: string;
    folioInvoiceNumber?: string;
    roomChargePostTypeNumber?: string;
    multiPropertyPropCode?: string;
    pMSIntegrationHostId?: string;
    clerkId?: number;
    clerkUserName?: string;
    outletId?: number;
    outletName?: string;
    retailTicketNumber?: string;
    retailTransactionType?: string;
    clientId?: string;
    postingId?: string;
    postingCheckNumber?: string;
    paymentReferenceComments?: string;
    transactionAdditionalReference?: string;
    transactionAdditionalComment?: string;
    financialBins?: FinancialBin[];
    postingRecords?: POSPostingRecord[];
    checkZoomString?: string;
    multiPropertyFeatureConfigurations?: any[];
    paymentMethodAdditionalConfigurations?: any[];
    sourceSystem?: string;
    checkZoom?: string;
}

export interface PMPayResponse {
    status?: string;
    transactionId?: string;
    transactionTime: Date | string;
    amount: Amount;
    inquirerInfo: InquirerInformation;
    clientInfo?: ClientInfo;
    errorMessage?: string;
}

export interface ClientInfo {
    accountName: string;
    accountNumber: string;
    authCode: string;
    roomNumber: string;
}


export interface UserSessionConfiguration {
    defaultCourseId: number;
    defaultDeviceName: string;
    defaultOutletId: number;
    defaultPaymentDevice: string;
    hangingTicketsPrinter: string;
    id: number;
    isIdtechSred: boolean;
    propertyId: number;
    smallStickersPrinter: string;
    subPropertyId: number;
    userId: number;
}

export const ResortFinanceFolio = "ResortFinanceFolio"

export const FolioInvoiceCheckZoomTheme = {
    0: 'GREY',
    1: 'ORANGE',
    2: 'BROWN',
    3: 'GREEN',
    6: 'BLUE'
};

//Request Model for GetPaymentHandles:
export interface HandleRequest {
    tenderId: string; // (Required)
    inquiryInfo?: Inquiry; // (Required)
}

export interface Inquiry {
    id?: string;
    type?: string; //(Required)
    name?: string;
    cardData?: CardData;
    TenderId?: string;
}

export interface CardData {
    track1?: string;
    track2?: string;
    encryptedData: string;
}

//-----------------------------------------------------------------------------------------
//Response Model for GetPaymentHandles:

export interface HandleResponse {
    status: string;
    errorMessage: string;
    paymentHandle: PayHandle[];
    hostName?: string;
    agentVersion?: string;
    ipAddress?: string;
}

export interface GiftCardPMRequest {
    handle:       string;
    Amount:       BalanceAmount;
    InquirerInfo: InquirerInfo;
    PaymentDetails?: PaymentDetails;
    isCardActive?: boolean;
}

export interface BalanceAmount {
    RequestAmount: string;
}

export interface InquirerInfo {
    TenderId:     string;
    TerminalId:   string;
    OrderNumber:  string;
    Profitcenter: string;
    ZoneOffset:   string;
    id?: string;
    EmployeeId?: string;
    customer?: string;
    doActivateOnly?: boolean;
    store?: number;
}

export interface GiftCardTransactionItem {
    id?: number;
    transactionDetailId?: number;
    paymentReferenceId?: number;
    PaymentMethodId: number;
    amount: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    cardType: GiftCardType;
    cardNumber: string;
    expiryDate: Date | string | null;
    handleInfo: string;
    isVoided?: boolean;
    isIssue?: boolean;
    status?: GiftCardTransactionStatus;
    retailTicketNumber?: string;
    handleFromGetHandles?: string;
}

export enum GiftCardTransactionStatus {
    TryLoad = 1,
    Loaded = 2,
    TryUnLoad = 3,
    UnLoaded = 4,
    TryIssue = 5,
    Issued = 6
}

export interface GiftcardSearchFilters {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    isExternal: boolean;
}    

export interface GiftcardDetails {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    cardNumber: string;
    status: string;
    availableBalance: string;
    expiryDate: string;
    soldDate?: string;
}


export interface PayHandle {
    handle: string;
    inquiryInfo: Inquiry; //(as passed in request)
    name: string;
    type: string;
    balance: number;
    isBalanceVisible: boolean;
    isPartialTenderAllowed: boolean;
    isRefundable: boolean;
    additionalAttributes: object;
    allowedAPIs: string[];
    referenceNumber1: string;
    referenceNumber2: string;
    playerId?: string,
    playerPin?: string
}

export interface HandleData {
    configurationId: number;
    adapter: string;
    id: string;
    type: string;
    name: string;
}

export interface ClientCreditCardInfo {
    id: number;
    clientId: number;
    tokenTransId: number;
    isActive: boolean;
    createdTime: Date | string | null;
}
export interface CreateTokenRequest {
    handle: string;
    inquirerInfo: Inquiry;
}

export interface PaymentTransactionInfo {
    payAgentId: string;
    status: string;
    account: Account;
    transactionDetails: string;
    transactionKey: string;
}

export interface StoreTokenRequest {
    payAgentResponse: PaymentTransactionInfo;
    cardInfo: CardInfo;
    tenderId: number;
    sourceType?: string;
    sourceTypeId?: string;
    folioNumber?: string;
    roomNumber?: string;
    hostName?: string;
    agentVersion?: string;
    ipAddress?: string;
    requestId?: string;
    gatewayResponse?: string;
    outletId?: number;
    propertyId?: number;
}

export interface TokenRequest {
    PayAgentResponse: TokentransactionInfo;
}

export interface Account {
    id: string;
    name: string;
    type: string;
}

export interface PayAgentCardInfo {
    entryMode: string;
    issuer: string;
    cardExpiration: string;
    cardType: string;
    token?: string;
}

export interface CardInfo {
    cardNumber: string;
    cardHolderName: string;
    entryMode: string;
    issuerType: string;
    cardExpiration: string;
    cardType: string;
    accessUrl?:string;
    token?:string;
}

export interface PaymentBaseResponse {
    transactionId: number;
    status: boolean;
    errorMessage: string;
}

export const IDTechHandle = {
    handle: 'IDTech SRED Key',
    name: 'IDTech SRED Key'
};

export const GiftcardIDTechHandle = {
    handle: 'IDTech SRED Key',
    name: 'IDTech SRED Key / MSR'
};

export const IDTech = {
    id: 'idtech'
};

export enum PaymentErrorCodes {
    ClientInvalidRequest = 9000,
    ClientInvalidConfiguration = 9001,
    ClientInvalidDeviceGuid = 9002,
    ClientInvalidTransactionId = 9003,
    ClientInvalidToken = 9004,
    ClientInvalidTransactionState = 9005,
    ClientInvalidTransactionFollowOnData = 9006,
    ClientAbortedRequest = 9099,
    CardError = 9500,
    CardErrorUnsupportedCardType = 9501,
    CardErrorInvalidAccountNumber = 9502,
    CardErrorInvalidExpirationDate = 9503,
    CardErrorUnsupportedCardIssuer = 9504,
    CardDeclined = 9600,
    CardDeclinedExpired = 9601,
    CardDeclinedLimit = 9602,
    CardDeclinedReferral = 9603,
    CardDeclinedCvv = 9604,
    CardDeclinedAvs = 9605,
    CardDeclinedChipDecline = 9606,
    DeviceError = 9200,
    DeviceInvalidConfiguration = 9201,
    DeviceNotAvailable = 9202,
    DeviceNotReady = 9203,
    DeviceOperationUnsupported = 9204,
    DeviceOperationAborted = 9205,
    DeviceReadWithoutPrompt = 9206,
    DeviceNoDataAvailable = 9207,
    DeviceInvalidData = 9208,
    DeviceUserPressedCancel = 9209,
    GatewayError = 9300,
    GatewayAuthenticationFailed = 9301,
    GatewayUnavailable = 9302,
    GatewayProcessorUnavailable = 9303,
    GatewayUnsupportedRequest = 9304,
    ServerError = 9100,
    ServerUnsupportedRequest = 9101,
    ServerInvalidConfiguration = 9102,
    TransactionError = 9400,
    TransactionNotFound = 9401,
    TransactionInvalidState = 9402,
    TransactionDuplicate = 9403,
    //Added for IG PMAgent Timeout
    TransactionTimeOut = 408,
    PaymentManagerTimedOut = 10060
}

export interface Device {
  name: string;
  handle: string;
  deviceGuid?: string;
}


//** Credit Card configuration Screen Models */

export interface GatewayConfiguration {
    id?: number;
    payAgentURL: string;
    payGatewayID: string;
    isInterfaceActive: boolean;
    isPartialPayAllowed: boolean;
    isAVSActive: boolean;
    restrictCreditToGuest: boolean;
    restrictPartialRefund: boolean;
    voidAuthAfterCO: boolean;
    gatewayValues: GatewayValues[];
    type: number;
    cardInputMethod?: CardInputMethod;
    preferredCardEntryMode?: CardInputMethod;
}

export interface GiftCardConfiguration {
    id: number;
    activateGiftCardInterface: boolean;
    allowExpiration: boolean;
    expireDays: number;
    giftCardType: GiftCardType;
    allowCashback: boolean;
    retailItemId: number;
    giftCardDefaultValues: GiftCardDefaultValues[];
    allowSearchByGuestName: boolean;
}

export interface IssueGiftCardPopupModel {
    thirdparty?: boolean;
    GiftCardNumber?: string;
    Amount: number;
    openItem: boolean;
    NeverExpire?: boolean;
    ExpiryDate?: string;
    FirstName?: string;
    LastName?: string;
    PhoneNumber?: string;
    EmailId?: string;
    HandleInfo: HandleResponse | string;
    isTopUp?: boolean;
    isFromBalanceScreen?: boolean;
    itemComments?: string;
    isRequestName?: boolean;
}

export interface GiftCardConfigurationStore {
    activateGiftCardInterface: boolean;
    giftCardType: GiftCardType;
}

export interface GatewayDeviceConfigurationStore {
    DetectDeviceOnLogin : boolean;
    OutletIdForDeviceDetection: number;
}

export interface DeviceDetails {
    deviceId : string;
    deviceName: string;
}

export interface GiftCardDefaultValues {
    id: number;
    giftCardConfigurationId: number;
    amount: number;
}

export interface GatewayValues {
    configurationId: number;
    configKey: string;
    configValue: string;
}

export interface Deposit {
    id?: number;
    playerId: number;
    amount: number;
    gratuity?: number;
    serviceCharge?: number;
    refundAmount?: number;
    depositTransactionId: number;
    refundTransactionId?: number;
    isVoided?: boolean;
}

enum RefundMethod {
    ReturnWithoutExchange = 1,
    ReturnWithExchange
}

export namespace UI {
    export interface CreditCardConfiguration {
        id?: number;
        activateAllPABPRecommendation: boolean
        alwaysRequestSwipeAtCheckIn: boolean
        automaticallyDeferCCAdvanceDepositPost: boolean
        batchWaitTimeSec: number
        enableActivateAVS: boolean
        restrictCreditToGuest: boolean;
        restrictPartialRefund: boolean;
        enableActivateInterface: boolean
        enableCCTracking: boolean
        enableChipAndPin: boolean
        enableChipPinTest: boolean
        enableHouseNumber: boolean
        enablePartialPayment: boolean
        enablePrintCCAuthorization: boolean
        enableRequestCardSwipe: boolean
        incrementalAuthWhenNotSupported: boolean
        voidAuthAfterCO: boolean
        noOfSvcReceipts: number
        pbfeNetpfDelay: number
        purgeArchivedCCDaysAfterCO: number
        purgeBookingCCDaysAfterCO: number
        purgeGuestCCDaysAfterCO: number
        purgePostingCCDaysAfterCO: number
        purgeStayCCDaysAfterCO: number
        rGuestPayAgentURL: string
        rGuestPayGatewayID: string
        setCCOverAuthAdjustThreshold: number | string
        skipCCFauxNoPurgeDuringZOut: boolean
        skipDecreaseAuth: boolean
        GatewayPairs: GateWayValues[];
        gatewayId: number;
        defaultConfigValue?: string;
        enableCCAuthLimit: boolean;
        showCreditCardExpDate: boolean;
    }
    
    export interface GateWayValues{
      gateway_value : string;
      gateway_key : string;
      gateway_index : number;
    }
  }

export interface RedemptionHistoryUI {
    sno?: number;
    ticketno: number;
    date: string;
    amount: number;
}

export interface RedemptionHistoryAPI {
    ticketNumber: number;
    amount: number;
    date: string;
}

export enum GiftCardTypeNames {
    None = 0,
    V1GiftCard = 1,
    Givex = 2,
    Vantiv = 3,
    Shift4 = 4,
    Agilysys = 5
}

export interface GiftcardSearchResult {
    cardNumber: string;
    firstName: string;
    lastName: string;
    email?: string;
    phoneNumber?: string;
    expiryDate: string;
    vendor: string;
    cardType: GiftCardType;
    amount: number;
    handle: string;
    tenderId: PaymentMethods;
    locExpiryDate?: string;
    zipcode?: string;
    maskedCardNumber: string;
    isCardActive?: boolean;
    UpdatedBalance?: number;
    paymentMethodId?: number;
}

export interface GiftCardBalance {
    cardType: GiftCardType;
    UpdatedBalance?: number;
    paymentReferenceId: number;
}

export interface CashBackRequest {
    paymentMethodId: number;
    tenderId: number;
    payAgentResponse: PayAgentResponse;
    giftCardCashBackPaymentRecord: GiftCardCashBackPaymentRecord;
    retailTicketNumber: string;
    machineId: number;
    hostName: string;
    agentVersion: string;
    ipAddress: string;
}

export interface GiftCardCashBackPaymentRecord {
    id?: number;
    paymentTransactionId?: number;
    cardNumber: string;
    type: number;
    cashBackAmount: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    expiryDate: Date | string;
    cashBackDate?: Date | string;
    productId?: number;
    propertyId?: number;
    subPropertyId?: number;
    
}


export interface V1GiftCardBaseRequest {
    cardNumber: string;
    pinNumber?: string;
    cashierId: number;
    cashierName: string;
    rechargeValue: number;
    rechargeCurrency?: string;
    invoiceId?: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
}

export interface ReversalRequestParams {
    transactionId: number;
    ticketNumber: string;
    outletId: number;
    checkHandleGuid: string;
    terminalId: number;
}


export interface RGuestPayRequestBuildParams {
    deviceGuid?: string;
    tenderId: number;
    outletId: number;
    sourceTypeId?: string;
    sourceType?: number;
    ticketNumber?: string;
    amount?: number;
    zipcode?: string;
    manualCardEntry: boolean;
    encryptedCardData?: string;
    actionType: string;
    integrationOperationType: IntegrationOperationsTransactionType;
    params?: { [key: string]: string };
}

export interface RGuestPayRequestDetails {
    url: string;
    requestPayload: string;
    actionMethod: string;
    headers: { [key: string]: string };
    status: boolean;
    errorMessage: string;
    errorCode: number;
}

export const PMAgentActionType =
{
    GetHandles: "GETHANDLES",
    CreateToken: "CREATETOKEN",
    Sale: "SALE",
    Credit: "CREDIT",
    Auth: "AUTH",
    GetBalance: "GETBALANCE",
    Load: "LOAD",
    Issue: "ISSUE"
}

export interface NonIntegratedCreditCardConfiguration {
    activateInterface: boolean;
    enableIdTech: boolean;
    gatewayValue: string;
}

export const NonIntegratedCreditCardConstants = {
    IFRAMEURL: "IFRAMEURL",
    CLIENTID: "CLIENTID",
    APIUSERNAME: "APIUSERNAME",
    NONCE: "NONCE",
    PAYAPIURL: "PAYAPIURL",
    PAYTENANTID: "PAYTENANTID"
}
export interface StoreTokenForNonIntegratedCreditCard {
    token: string,
    accessUrl: string,
    cardInfo: CardInfo,
    payResponse: string
}

export enum TransactionState {
    Authorised = 1,
    AuthorizationVoid = 2,
    Settled = 3,
    Sale = 4,
    VoidSale = 5,
    Refund = 6,
    Rejected = 7,
    Declined = 8,
    Success = 9,
    Failed = 10,
    Cancelled = 11,
    TokenGenerated = 12,
    TokenisedSale = 13,
    TokenisedCredit = 14,
    Credit = 15,
    VoidTransaction = 16,
    Load = 17,
    Issue = 18,
    Balance = 19,
    CashOut = 20,
    IssueReversal = 21,
    CreditReversal = 22,
    LoadReversal = 23,
    DeActivate = 24,
    SignatureCapture = 25,
    TipCapture = 26,
    ForceCaptureTransactionState = 27,
    AuthTransactionState = 28,
    TokenisedAuth = 29,
    ModifyAuthorization = 30,
    DecreaseAuthorization = 31,
    ForceCaptureToken = 32,
    CreateCheck = 33,
    UpdateCheck = 34,
    GetAllChecks = 35,
    InquiryCheck = 36,
    InquiryCheckPayments = 37,
    CheckPaymentsAcknowledge = 38,
    CloseCheck = 39,
    Notification = 40,
    Message = 41,
    SecurityCode = 42
}

export interface NonIntegratedTokenTransactionSearchRequest {
    startDate: string;
    endDate: string;
    amount?: number;
    ticketNumber?: string;
    status?: string;
    cardNumber?: string;
    cardType?: string;
    outlet: number;
    userId?: number;
    pageStart?: number;
}

export interface NonIntegratedTransaction {
    id: number;
    cardNumber: string;
    cardType: string;
    amount: number;
    status: string;
    transactionDateTime: Date;
    transactionDateTimeUTC: Date;
    transactionTypeId: number;
    tenderId: number;
    tokenRefId: number;
    roomNumber: string;
    reservationName: string;
    sourceType: string;
    sourceTypeId: string;
    folioNumber: string;
    roomReference: string;
    referenceComment: string;
    isAuthActive: boolean;
    outletId: number;
    userId: number;
    token: string;
    accessUrl: string;
    updatedBy: number;
    updatedDateTime: Date;
    guestId: number;
    memberId: number;
    issuerType:string;
}
