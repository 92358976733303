import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class StepperService {
  @Output() public valueChange = new EventEmitter();
  @Output() public stepperChange = new EventEmitter();
  @Output() public stepperNextEnable = new EventEmitter();
  @Output() public stepperBackEnable = new EventEmitter();
  enableSave:boolean = false;
  enableCancel:boolean = true;
  enableBack = false;
  tab:boolean = false;
  numberOfSteps: number;
  selectedIndex: number =0;
  enablePrevious: boolean;
  enableNext: boolean = false;
  captions: any = this._localization.captions.common;
  buttonText: string = this.captions.NEXT;
  linkButtonText: string = this._localization.captions.teetime.LINK;
  previousButton: string = this.captions.PREVIOUS;
  isSaveSubmitDisabled = false;
  isSubmitted = false;
  closeplayerPopUp = true;
  fromPaymentActions = false;
  paymentActionButton: string;
  paymentActionShop: string;
  isExternalGuestSearch: boolean = false;
  constructor(private _localization: GolfLocalization) { 
    this.captions = this._localization.captions.common;
  }


  stepperSelection(buttonText){
    switch (this.selectedIndex) {
      case 0:
        this.enablePrevious = false;
        this.buttonText = this.captions.NEXT;
        break;
      case (this.numberOfSteps - 1):
        this.buttonText = buttonText;
        this.enablePrevious = true;
        break;
      default:
        this.enablePrevious = true;
        this.buttonText = this.captions.NEXT;
        break;
    }
    this.stepperChange.emit('headerChange');
  }

  public setBackEnable(backEnable: boolean) {
    this.stepperBackEnable.emit(backEnable);
  }

}
