import { Injectable } from '@angular/core';
import { API } from '../../../settings/golf-setup/code-linking/code-link-modal';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service'
@Injectable({
    providedIn:"root"
})
export class CodeLinkingDataService {
        constructor(private _http: GolfScheduleCommunication) {
            
            }
        async getAllocationBlockPlayerTypes(): Promise<API.AllocationBlockPlayerType[]> {  
            return this._http.getPromise<API.AllocationBlockPlayerType[]>({
                route: GolfApiRoute.GetAllocationBlockPlayerTypes
            });            
            }

        async getPlayerTypeRateTypes(): Promise<API.PlayerTypeRateType[]>{
            return this._http.getPromise<API.PlayerTypeRateType[]>({
                route: GolfApiRoute.GetPlayerTypeRateTypes
            });
            }

        public async getPackagePlayerTypesRateTypes(packageCode:string): Promise<API.PackagePlayerTypesRateTypes> {
            return await this._http.getPromise<API.PackagePlayerTypesRateTypes>(
                  { route: GolfApiRoute.GetPackageLinkedPlayerTypesRateTypes, uriParams: { "code": packageCode } });
                 
              } 
        
}