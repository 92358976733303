import { Injectable } from '@angular/core';
import { RainCheckIssue } from '../../../retail/shared/shared.modal';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Injectable({ providedIn: 'root' })

export class RedeemRainCheckService {

    constructor(private _http: GolfScheduleCommunication, private _localization: GolfLocalization) {
    }

    public async GetRainChecks(issueddate: Date, pattern: string): Promise<RainCheckIssue[]> {
        return await this._http.getPromise<RainCheckIssue[]>(
            {
                route: GolfApiRoute.GetRainChecks,
                uriParams: { issuedDate: this._localization.convertDateObjToAPIdate(issueddate), searchText: pattern }
            }
            , false
        );      
    }

    public async UpdateRainCheck(settledRainCheck: RainCheckIssue): Promise<boolean> {
        return this._http.putPromise<boolean>(
            {
                route: GolfApiRoute.UpdateRainCheck,
                body: settledRainCheck
            }
            , false);       
    }

    public async GetRainChecksByTeeTime(teeTime: number): Promise<RainCheckIssue[]> {
        return await this._http.getPromise<RainCheckIssue[]>(
            {
                route: GolfApiRoute.GetRainCheckIssueByTeeTime,
                uriParams: { teeTimeId: teeTime }
            }
            , false
        );       
    }

    public async GetAllRainChecksByTeeTime(teeTime: number): Promise<RainCheckIssue[]> {
        return await this._http.getPromise<RainCheckIssue[]>(
            {
                route: GolfApiRoute.GetAllRainCheckIssueByTeeTime,
                uriParams: { teeTimeId: teeTime }
            }
            , false
        );       
    }
}
