export enum UserAccessBreakPoints {
  TOURNAMENTS = 13015,
  USERSETUP = 2300,
  TOURNAMENTFORMAT = 13000,
  TOURNAMENTPACKAGE = 13005,
  TOURNAMENTCOMPONENTS = 13010,
  TOURNAMENTPLAYERS = 13020,
  TOURNAMENTTEETIMES = 13025,
  TOURNAMENTSCORING = 13030,
  TOURNAMENTPLANCOMPONENTS = 13035,
  CADDYSETUP = 14000,
  TOURNAMENTLISTREPORT = 9050,
  SCORECARDREPORT = 9055,
  CADDYSCHEDULE = 14005,
  RAINCHECKREDEEM = 14500,
  Utilization = 15000,
  TeeTimes = 15005,
  UpcomingTournaments = 15010,
  DashboardWaitList = 15015,
  Lessons = 15020,
  SalesRevenue = 15025,
  Revenue = 15030,
  SalesTop5Items = 15035,
  SalesTop5Categories = 15040,
  MEMBERPROFILE = 16000,
  MEMBERSTATUS = 16005,
  CREDITBOOK = 16010,
  HOLDTEETIME = 10020,
  LESSONLOCATION = 12045,
  RELEASEHOLD = 10070,
  SQUEEZETEETIME = 10025,
  RATETEETIME = 10035,
  BOOKTEETIME = 10015,
  ROLESETUP = 2305,
  RATETYPE = 12020,
  PLAYERTYPE = 12015,
  CANCELREASONS = 12080,
  DYNAMICPRICINGOVERRIDE = 22000,
  DYNAMICPRICING= 12105,
  SEASONSETUP = 12095,
  UPDATERATESFOREXISTINGBOOKING = 12125,
  VIPTYPE = 12130,
  LOSTORDENIEDREASONS = 12090,
  SYSTEMSETUP = 12001,
  COMMISIONSETUP = 800,
  RECEIPTCONFIGURATION = 7080,
  INVENTORYMANAGEMENT = 8722,
  CODELINKING = 12025,
  COURSE = 12000,
  ALLOCATIONBLOCKASSIGNMENT = 12065,
  RATESETUP = 12055,
  RAINCHECKSETUP = 12035,
  TeeTimeAllocationBlock = 12010,
  CourseCommentSetup = 12040,
  TimeIntervalSetup = 12060,
  TeeColor = 12005,
  TeeSheet = 10010,
  InstructorsSetup = 11000,
  ClearPlayers = 10060,
  Copy = 10045,
  CopytoMany = 10050,
  Move = 10040,
  DragDrop = 10145,
  FROSTDELAY = 10030,
  InstructorSchedule = 11005,
  DefaultUserConfig = 11505,
  CUSTOMFIELD = 12050,
  INSTRUCTORSCHEDULEREPORT = 9070,
  CANCELPLAYERS = 10055,
  TeeTimeSearch = 10080,
  RAINCHECKISSUE = 10140,
  CADDYTYPE = 12030,
  LOGTYPE = 12100,
  TeeTicketReport = 9025,
  VIEWWAITLIST = 10090,
  ADDWAITLIST = 10085,
  DELETEWAITLIST = 10095,
  CONVERTWAITLIST = 10100,
  LOSTANDFOUND = 11510,
  ROUNDSBYRATETYPE = 9090,
  ROUNDSBYPLAYERTYPE = 9095,
  TeeTimeUtilizationReport = 9075,
  RAINCHECKISSUEDREPORT = 9030,
  CancellationTeeTime = 9005,
  NoShowTeeTime = 9010,
  WaitList = 9015,
  BagTags = 9020,
  BOOKLESSON = 10115,
  CANCELLESSON = 10120,
  BULKMOVE = 10165,
  BULKCANCEL = 10170,
  BULKCLEAR = 10175,
  BULKCOPY = 10180,
  BULKTEETIMESBOOK = 10155,
  BULKTEETIMESEDIT = 10160,
  PRINT = 10075,
  PRINTINVENTRYITEMAGRREMENTS = 10076,
  GROUPTEETIMES = 10150,
  SHOP = 10065,
  RETAILORRENTAL_ITEM = 15205,
  WAIVEOFFCHARGES = 15210,
  TEETIMEREFUND = 10135,
  USERROLECONFIGURATION = 2310,
  DAYEND = 11500,
  TRANSACTIONLOGREPORT = 9120,
  PLAYERPROFILE = 700,
  COPYPLAYERPROFILE = 705,
  REOPENTRASACTION = 5000,
  ADD_DEPOSIT = 10105,
  DEPOSITREFUND = 10110,
  MARKASPAID = 10125,
  CHANGEALLOCATIONBLOCK = 10185,
  ALLOWBLOCKTEETIME = 10190,
  MEMBERCONFIGURATION = 16015,
  BOOKINGPERMISSIONS = 12070,
  OVERRIDEPLAYERTYPEDAYSOUT = 10195,
  CADDYREQUIREMENTS = 14010,
  PACKAGE_ALLOCATIONCODE = 12075,
  COMBINEGUESTRECORDS = 2435,
  DEDUPEGUESTRECORDS = 12120,
  ZEROMEMBERCHARGE = 10200,
  PRINTERDEFAULTCONFIGURATION = 15065,
  CLUBAGREEMENT = 15045,
  CARTAGREEMENT = 15050,
  SHOEAGREEMENT = 15055,
  RETAILSALECHIT = 15060,
  BULKHOLDBLOCK = 10205,
  PLAYERINFORMATION = 15070,
  LessonCopy = 15075,
  LessonCopyToMany = 15080,
  COMPONENTSLINKING = 11525,
  EDITGENERALINFO = 15085,
  GUESTITINERARYREPORT = 9170,
  BLOCKLESSON = 15090,
  PLAYERPAYRELEASE = 15095,
  NEGOTIATEDRATE = 15100,
  MANUALMARKASPAID=15105,
  DELETETEETIMESLOT = 15110,
  LINKUNLINKMULTIPACK = 15115,
  NOSHOW = 15120,
  UNMARKNOSHOW = 15125,
  //DataMagine
  DATAMAGINE_CONFIGURATION = 85160,
  DATAMAGINE_SETTINGS = 85165,
  DOCUMENTCODES = 85175,
  DOCUMENTTYPES = 85170,
  PMSINTEGRATION = 15130,
  //Golf Checkin Checkout
  CHECKIN = 15140,
  UNDO_CHECKIN = 15145,
  CHECKOUT = 15150,
  UNDO_CHECKOUT = 15155,
  CHECKIN_CHECKOUT = 15160,
  //QUICKIDCONFIG
  QUICKIDCONFIG = 16020,
  OVERRIDERATETYPEDAYSOUT = 15165,
  INVENTORYSYNC = 15170,
  NIGHTAUDITCONFIG = 16025,
  ADMINTEGRATIONSETUP = 12085,
  STAFFREADERBOARD = 20035,
  ProjectJobCode = 190260,
  MonthEndProcessing = 190295,
  FinancialReportAutoPrint=190355,
  AUTHORIZEPAYMENT = 3100,
  //Authorize Integration
  AUTHORIZE_CONFIGURATION = 12110,

  //cgpsFailedProfile
  cgpsFailedProfile = 101546,

  AUDIT_TRAIL = 15180,

  //Dynamic Availability
  OVERRIDE_UNAVAILABLE_RATETYPE_PER_DYNAMICAVAILABILITY = 15190,
  DYNAMIC_PRICING_OVERRIDE = 22000,
  DYNAMIC_PRICING = 12105,
	GOLFNOWSETUP = 12115,

  //BULK NOTIFY
  BULK_NOTIFY = 15185,
 //Update PlayerType And RateType
  UPADTEPLAYERRATETYPEDURINGPAY = 15195,
  //CancellationNoShow
  CANCELLATIONNOSHOWPOLICY = 20065,
  CANCELLATIONNOSHOWPOLICYMAPPING = 20070
}
