import { Injectable } from "@angular/core";
import { GolfScheduleCommunication } from "src/app/shared/communication/services/golfschedule.service";
import { CheckboxTableHeader, PlayerTransactionDetail } from 'src/app/shared/shared-models';
import { GolfLocalization } from '../../core/localization/golf-localization';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { CheckBoxTableTemplate } from "src/app/shared/global.constant";

@Injectable()

export class PaymentModalService {
  isNoShow : boolean = false;

  constructor(private _Localization: GolfLocalization, 
    private _golfSchedule: GolfScheduleCommunication,
    private _PropertyInformation:RetailPropertyInformation) { }
  getInfo = [];
  GetHeaderOption(flagValue: boolean, isMarkAsPaid: boolean, isPlayerTypeRateTypeChange:boolean = false, isNoShow:boolean =false,
    isNoshowChargesApplicable: boolean = false
  ): CheckboxTableHeader[] {
    let checkbox: CheckboxTableHeader[];
    checkbox = [
      {
        key: 'playerPosition',
        description: this._Localization.captions.teetime.playerPositionShort,
        alignment: 'textLeft'
      }, 
      {
        key: 'playerName',
        description: this._Localization.captions.teetime.playerName,
        alignment: 'textLeft'
      }, 
      {
        key: 'scheduledDateTime',
        description: this._Localization.captions.teetime.teetime,
        alignment: 'textLeft'
      },
      {
        key: 'course',
        description: this._Localization.captions.teetime.course,
        alignment: 'textLeft'
      },
      {
        key: 'playerType',
        description: this._Localization.captions.teetime.playerType,
        alignment: 'textLeft'
      }, 
      {
        key: 'rateType',
        description: this._Localization.captions.teetime.rateType,
        alignment: 'textLeft'
      },
      // { 
      //   key: 'greenFeeCurrency', 
      //   description: this._Localization.replacePlaceholders(this._Localization.captions.teetime.greenFeeWithSymbol, ['currencySymbol'], [this._Localization.currencySymbol]),
      //   alignment: 'textRight'
      // },
      // { 
      //   key: 'cartFeeCurrency',
      //   description: this._Localization.replacePlaceholders(this._Localization.captions.teetime.cartFeeWithSymbol, ['currencySymbol'], [this._Localization.currencySymbol]), 
      //   alignment: 'textRight' 
      // },
      // { 
      //   key: 'totalFeeCurrency', 
      //   description: this._Localization.replacePlaceholders(this._Localization.captions.teetime.totalWithSymbol, ['currencySymbol'], [this._Localization.currencySymbol]), 
      //   alignment: 'textRight' , template: CheckBoxTableTemplate.negotiate
      // }, 
      {
        key: 'packageCode',
        description: this._Localization.captions.teetime.packageCode,
        alignment: 'textLeft'
      }, 
      {
        key: 'walk_trial',
        description: this._Localization.captions.teetime.walk + '/' + this._Localization.captions.teetime.trail,
        alignment: 'textLeft'
      },
      {
        key: 'holes',
        description: this._Localization.captions.teetime.holes,
        alignment: 'textLeft'
      }
    ];

    if(!isNoShow){
      checkbox.splice(6,0, { key: 'greenFeeCurrency', description: this._Localization.replacePlaceholders(this._Localization.captions.teetime.greenFeeWithSymbol, ['currencySymbol'], [this._Localization.currencySymbol]), alignment: 'textRight'});
      checkbox.splice(7,0, { key: 'cartFeeCurrency', description: this._Localization.replacePlaceholders(this._Localization.captions.teetime.cartFeeWithSymbol, ['currencySymbol'], [this._Localization.currencySymbol]), alignment: 'textRight' });
      checkbox.splice(8,0, { key: 'totalFeeCurrency', description: this._Localization.replacePlaceholders(this._Localization.captions.teetime.totalWithSymbol, ['currencySymbol'], [this._Localization.currencySymbol]), alignment: 'textRight' , template: CheckBoxTableTemplate.negotiate});
    }
    let corpIdx = 7;
    if(isPlayerTypeRateTypeChange)
    {
      checkbox.splice(4,2);
      checkbox.splice(4,0, { key: 'playerType', description: this._Localization.captions.teetime.playerType, alignment: 'textLeft', template:CheckBoxTableTemplate.playerdropdown });
      checkbox.splice(5,0, { key: 'rateType', description: this._Localization.captions.teetime.rateType, alignment: 'textLeft', template:CheckBoxTableTemplate.ratedropdown });
      // checkbox.splice(6,0, { key: 'greenFeeCurrency', description: this._Localization.replacePlaceholders(this._Localization.captions.teetime.greenFeeWithSymbol, ['currencySymbol'], [this._Localization.currencySymbol]), alignment: 'textRight'});
      // checkbox.splice(7,0, { key: 'cartFeeCurrency', description: this._Localization.replacePlaceholders(this._Localization.captions.teetime.cartFeeWithSymbol, ['currencySymbol'], [this._Localization.currencySymbol]), alignment: 'textRight' });
      // checkbox.splice(8,0, { key: 'totalFeeCurrency', description: this._Localization.replacePlaceholders(this._Localization.captions.teetime.totalWithSymbol, ['currencySymbol'], [this._Localization.currencySymbol]), alignment: 'textRight' , template: CheckBoxTableTemplate.negotiate});
      corpIdx = 10;
    }
    if(isNoshowChargesApplicable && isNoShow)
    {
      checkbox.push({
        key: 'charges',
        description: this._Localization.replacePlaceholders(this._Localization.captions.settings.noShowFee, ["option"], [this._Localization.currencySymbol]),
        alignment: 'textRight',
        templateName: "custom"
      })
      checkbox.push({
        key: 'action',
        description: this._Localization.captions.teetime.isWaiveOff,
        alignment: 'textCenter',
        templateName: "custom"
      })
    }

    
    const memberConfig = this._PropertyInformation.getMemberConfig;
    if(memberConfig && memberConfig.applicableCorporates!= null && memberConfig.applicableCorporates != '')
    {
      checkbox.splice(corpIdx,0, { key: 'corpName', description: this._Localization.captions.teetime.corpName, alignment: 'textLeft' });
    }
    

    if (isMarkAsPaid) {
      checkbox.push({
        key: 'jsonTicketNumber',
        description: this._Localization.captions.teetime.ticketNumber,
        alignment: 'textLeft'
      })
    }
    if (flagValue) {
      checkbox.push({
        key: 'blockByUser',
        description: this._Localization.captions.teetime.blockByUser,
        alignment: 'textLeft'
      }, {
        key: 'action',
        description: this._Localization.captions.teetime.action,
        alignment: 'textCenter',
        templateName: "custom"
      })
    }
    return checkbox;
  }
  public async GetPlayerTransactionDetailListById(lstTransactionId: Number[]): Promise<PlayerTransactionDetail[]> {
    return await this._golfSchedule.postPromise<PlayerTransactionDetail[]>({
      route: GolfApiRoute.GetPlayerTransactionDetailListById,
      body: lstTransactionId
    });
  }
  public async UpdatePlayerStatusAsNoShowReBook(_dataForNoShow,isNoshowAction : boolean): Promise<any> {
    let result = null;
    if(isNoshowAction){  
      result = await this._golfSchedule.putPromise<PlayerTransactionDetail[]>({
        route: GolfApiRoute.NoShowTeeTimePlayerForMultiple,
        body: _dataForNoShow
      });
      return {successOperation : result ? true : false};
    }
      result = await this._golfSchedule.putPromise<PlayerTransactionDetail[]>({
        route: GolfApiRoute.ReBookTeeTimePlayerForMultiple,
        body: _dataForNoShow
      });
    
    return {successOperation : result ? true : false};
  }
}