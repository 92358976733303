<section class="golf-container--padding" id="reinstateTeeTime"> 
  <div class="TeeTimeModal" >
    <form [formGroup]="reinstateTeeTimeForm" autocomplete="off">
      <div class="selectDates">
        <div>
          <div class="w-33 in-block">
            <app-golf-date-picker [inputs]="requestedDateInputs" (datePickerChange)="dateChanged($event)">
            </app-golf-date-picker>
            <!-- <app-ag-date-picker [inputs]="requestedDateInputs" (datePickerChange)="dateChanged($event)">
            </app-ag-date-picker> -->
          </div>
          <div class="w-33 in-block">
            <mat-form-field class="golf-form-control--lg">
              <mat-select [placeholder]="captions.courses" name="course" formControlName="courses" (selectionChange) ="courseChanged($event.value)">
                <mat-option *ngFor="let x of courseType | async" [value]="x.id"> 
                  {{x.description}}
                </mat-option>
              </mat-select>              
            </mat-form-field>
          </div>
        </div>
        
      </div>
      <div class="notification-message" *ngIf="notificationFlag">
        <app-note [noteMessage]="notificationbar" (removeNotification)="removeNotification($event)"></app-note>
      </div>
      <div class="Tee-time-reinstate-grid">
         <div class="player_list">
            <label class="golf-form--label w-100">{{captions.playerList}}</label>
          <app-reinstate-players class="app-reinstate-players" [teeSheetID]="teeSheetID" [customTableData]="customTableData" [playersList]="playerList" [playerCollectionList]="playerCollectionList" (onDragPlayers)="onDragPlayers($event)"></app-reinstate-players>
         </div>
         <div class="Appoitment">
            <label class="golf-form--label w-100">{{captions.appointment}}</label>
            <div class="w-100" id={{teeSheetID?.tableID}}>
                <app-tee-grid class="app-tee-grid" [teeSheetID]="teeSheetID" [customTableData]="customTableData" [teeData]="skeletonData | async"
                (dragDropEvent)="onDragDrop($event)" (onDragGridPlayers)="onDragGridPlayers($event)" [courseId] = "courseId"
                (onRemovePlayer)="onRemovePlayer($event)" (onPlayerCollectionListEmit)="onPlayerListEmit($event)"></app-tee-grid> 
            </div>
        </div>
      </div>

    </form>
  </div> 
</section>