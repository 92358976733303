<section class="golf__container showApp">
   
    <div class="golf__header_custom"> <!-- //_custom added -->
        <div class="theme-color-wrapper" [style.background-color]="themeColor+ ' !important'" *ngIf="menuList?.menu">
            <div class="text-font" [ngClass]="[(themeColor | colorPickerModifier)? 'light-text-color': 'dark-text-color']">
                  <!-- <a class="ag_ml--4" (click)="toggleStyle($event)">Toggle Style</a> -->
                {{propertyName}} | {{propertyDateTime}}</div>
        </div>
        
        <div class="menu-wrapper ag_position--relative" *ngIf="menuList?.menu">
        <app-menu [menu]="menuList" *ngIf="menuList"></app-menu>
        </div>
    </div>  
</section>
