import { Injectable } from '@angular/core';
import { PlayerType } from 'src/app/settings/golf-setup/code-setup/player-type/player-type.modal';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';

@Injectable({
  providedIn:"root"
})

export class PlayerTypeService {

  constructor(private _http: GolfScheduleCommunication) {
  }
  
  public async getAllPlayerTypes(bShowActivePlayerTypes: boolean = true): Promise<PlayerType[]> {
    return await this._http.getPromise<PlayerType[]>(
      { route: GolfApiRoute.GetAllPlayerTypes, uriParams: { "isActive": bShowActivePlayerTypes } });
   
  } 
  public async getPlayerType(id: number): Promise<PlayerType> {
    return await this._http.getPromise<PlayerType>(
      { route: GolfApiRoute.GetPlayerType, uriParams: { "id": id } });
  
  }

}