<ng-container *ngIf="skipLoginFormLoad; else loadLoginForm">
    <div class="d-flex h-100 align-items-center">
        <div id="cover-spin-retail">
            <div class="customspinnerimg">
                <img src="./assets/images/agil_favicon.ico" style="width: 100%;">
            </div>
            <div id="default-message">{{customSpinner.lbl_customSpinnerMsg}}</div>
        </div>
    </div>
</ng-container>
<ng-template #loadLoginForm>
    <section class="login">
        <div class="login__formgroup" *ngIf="!hideLoginForm">
            <div class="login__page">
                <div class="login__img">
                    <img src="./assets/images/Golf_Logo.png" alt="" class="login__product-logo">
                    <img src="./assets/images/rguest-golf-background.jpg" alt="" class="login__logo-img">
                </div>
                <div class="login__area">
                    <div class="login__details">
                        <label class="login__label-style">{{this.captions.Login}}</label>
                        <div class="login__message golf-LatoRegular-14">
                            {{ loginSuccess? this.captions.SelectYourLocation : this.captions.WelcomeToGolf}}</div>

                        <div class="login__form-details">
                            <div class="login_form">
                                <form [formGroup]="loginForms" *ngIf='!skipLoginFormLoad' autocomplete="off">
                                    <div *ngIf="!loginSuccess" class="overall_login_wrapper">
                                        <div *ngIf="!showCustomerID && cusId">
                                            <div>
                                                <i class="icon-user pr8 " aria-hidden="true"></i>
                                                <mat-form-field class="w-50">
                                                    <input matInput #userid_one type="text" [placeholder]="captions.userid"
                                                        class="text-uppercase" golfinputtype="capitalise,notallowspace"
                                                        formControlName="userId"
                                                        (keydown.enter)="getbuttonEmitvalue($event)">
                                                    <mat-error *ngIf="loginForms.get('userId').hasError('required')">
                                                        {{errorMessage.userId}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="hide-view">
                                                <i class="icon-password pr8 " aria-hidden="true"></i>
                                                <mat-form-field class="w-50">

                                                    <input matInput #fcs_pwd [type]="hidePassword ? 'text' : 'password'"
                                                        [placeholder]="captions.password" formControlName="password"
                                                        (keydown.enter)="getbuttonEmitvalue($event)"
                                                        autocomplete="new-password">
                                                    <i matSuffix class="IC18"
                                                        [ngClass]="hidePassword ? 'icon-password-show' : 'icon-password-hide '"
                                                        (click)="hidePassword=!hidePassword" ></i>
                                                    <mat-error *ngIf="loginForms.get('password').hasError('required')">
                                                        {{errorMessage.password}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <ng-container *ngIf="cusId==0">
                                            <div>
                                                <i class="icon-guest ag_pr--2"></i>
                                                <mat-form-field class="w-50 ag_section--disable" [floatLabel]="floatLabel" [ngClass]="{'ag_section--disable' : !showCustomerID}">
                                                    <input matInput #custid inputtype='nonnegative' golfinputtype="notallowspace,nospecailchar"  [maxlength]="maxLength"
                                                        [placeholder]="captions.customerid" class="text-uppercase"
                                                        formControlName="customerId"
                                                        (keydown.enter)="loginForms.controls.customerId.value != '' ? getbuttonEmitvalue($event) : '' ">
                                                    <mat-error *ngIf="loginForms.get('customerId').hasError('required')">
                                                        {{errorMessage.customerId}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div>
                                                <a class="ml-4" (click)="clearLclCookie('appGolfCustID')"> {{ captions.changeCustomerID}}</a>
                                            </div>
                                        </ng-container>
                                        </div>
                                        <div *ngIf="showCustomerID && cusId">
                                            <div *ngIf="!showCustomerID">
                                                <i class="icon-user pr8 " aria-hidden="true"></i>
                                                <mat-form-field class="w-50">

                                                    <input matInput #userId_two type="text" [placeholder]="captions.userid"
                                                        class="text-uppercase" golfinputtype="capitalise,notallowspace"
                                                        formControlName="userId"
                                                        (keydown.enter)="getbuttonEmitvalue($event)">
                                                    <mat-error *ngIf="loginForms.get('userId').hasError('required')">
                                                        {{errorMessage.userId}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div *ngIf="!showCustomerID" class="hide-view">
                                                <i class="icon-password pr8 " aria-hidden="true"></i>
                                                <mat-form-field class="w-50">

                                                    <input matInput #fcs_pwd  [type]="hidePassword ? 'text' : 'password'"
                                                        [placeholder]="captions.password" formControlName="password"
                                                        (keydown.enter)="getbuttonEmitvalue($event)"
                                                        autocomplete="new-password">
                                                    <i matSuffix class="IC18"
                                                        [ngClass]="hidePassword ? 'icon-password-show' : 'icon-password-hide '"
                                                         (click)="hidePassword=!hidePassword"  ></i>
                                                    <mat-error *ngIf="loginForms.get('password').hasError('required')">
                                                        {{errorMessage.password}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                            <div>
                                                <i class="icon-guest pr8 " aria-hidden="true"></i>
                                                <mat-form-field class="w-50">

                                                    <input matInput #custid inputtype='nonnegative' golfinputtype="notallowspace,nospecailchar" [maxlength]="maxLength"
                                                        [placeholder]="captions.customerid" class="text-uppercase"
                                                        formControlName="customerId"
                                                        (keydown.enter)="loginForms.controls.customerId.value != '' ? getbuttonEmitvalue($event) : '' ">
                                                    <mat-error
                                                        *ngIf="loginForms.get('customerId').hasError('required')">
                                                        {{errorMessage.customerId}}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div>

                                            <span *ngIf="loginError"
                                                class="errorText">&nbsp;&nbsp;{{errResponse}}</span>
                                        </div>
                                    </div>
                                    <div *ngIf="loginSuccess" class="overall_login_wrapper">
                                        <i class="icon-location-login pr8" aria-hidden="true" *ngIf="!isSupportUser"></i>
                                        <mat-form-field class="w-50" *ngIf="!isSupportUser">

                                            <mat-select formControlName="location" (click)="loadProperties()"
                                                [disabled]="multipleProperties.length ==1"
                                                (selectionChange)="onPropertyChange($event)"
                                                placeholder="{{this.captions.Location}}">
                                                <mat-option *ngFor="let location of multipleProperties"
                                                    [value]="location">
                                                    {{location.name}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{this.captions.MissingLocation}}
                                            </mat-error>

                                        </mat-form-field>
                                        <div *ngIf="isSupportUser">
                                            <div>
                                                <i class="icon-guest ag_pr--2" aria-hidden="true"></i>
                                                <mat-form-field class="w-50" >
                                                    <mat-select formControlName="tenantId" (selectionChange)="onTenantIdChange($event)" (openedChange)="openedChange($event)"
                                                        [attr.placeholderId]="lbl_customer" [placeholder]="captions.lbl_customer">
                                                        <div class="dropdown-search-tenant">
                                                          <input #myInput [placeholder]="captions.SearchByCustomer"  [(ngModel)]="inputSearch" [ngModelOptions]="{standalone: true}" (input)='filterOptions($event)' (keydown)="$event.stopPropagation()">
                                                        </div>
                                                        <ng-container *ngIf="tenantIdList.length > 0 ; else noData">
                                                          <mat-option  matTooltip="{{tenant.id}} - {{tenant.viewValue}}" *ngFor="let tenant of tenantIdList" [value]="tenant.id">
                                                            {{tenant?.id}} - {{tenant.viewValue}}
                                                         </mat-option>
                                                        </ng-container>
                                                        <ng-template #noData>
                                                          <mat-option>
                                                            {{captions.lbl_noDataFound}}
                                                         </mat-option>
                                                        </ng-template>
                                                    </mat-select>
                                                    <mat-error [attr.LiteralID]="'MissingTenantId'">
                                                        {{captions.err_missingtenantId}}
                                                    </mat-error>

                                                </mat-form-field>
                                            </div>

                                            <div>
                                                <i class="icon-location-login ag_pr--2" aria-hidden="true"></i>
                                                <mat-form-field class="w-50" >
                                                    <mat-select formControlName="propertyId" (selectionChange)="onPropertyIdChange($event)"
                                                        [attr.placeholderId]="lbl_property" [placeholder]="captions.lbl_property">
                                                        <mat-option *ngFor="let property of propertyIdListForATenant" [value]="property.id">
                                                            {{property.viewValue}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error [attr.LiteralID]="'MissingpropertyId'">
                                                        {{captions.err_propertyID}}
                                                    </mat-error>

                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <mat-form-field class="w-50 machine-name"
                                            *ngIf="isPromptOnLoginEnabled && machineNames.length > 0">
                                            <mat-select formControlName="machineName" (click)="loadMachineNames()"
                                                [compareWith]="compareSelect" placeholder="{{this.captions.Machine}}"
                                                required>
                                                <mat-option *ngFor="let machineName of machineNames"
                                                    [value]="machineName">
                                                    {{machineName.name}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                {{this.captions.MissingMachine}}
                                            </mat-error>

                                        </mat-form-field>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="margintop--10" *ngIf="cusId"><button color="primary" mat-flat-button
                                class=" golfbutton  w-307px"  [ngClass]="{'ag_section--disable': !this.loginForms.controls.customerId.value && showCustomerID || showLoginloader ||
                                (!isSupportUser && !showCustomerID && (!loginForms.controls.userId.value || !loginForms.controls.password.value)) || (!isSupportUser && loginSuccess && !loginForms.controls.location.value?.id) || (!isSupportUser && loginSuccess && !loginForms.controls.machineName.value)}"
                                (click)="!this.loginForms.controls.customerId.value && showCustomerID ? '' :getbuttonEmitvalue($event)">{{loginSuccess?captions.ENTER:captions.Login}}
                                <div *ngIf="showLoginloader" class="loginloadingSpinner"></div>
                            </button>
                        </div>
                    </div>


                </div>
                <div class="login__footer">
                    <img src="./assets/images/Agilsys_Logo.png" alt="" class="login__footer-agilLogo">
                    <div class="login__resolution-footer-hint mb15dpx">{{captions.copyRight}} &#169;
                        {{preYear}}-{{currYear}} {{captions.copyrightText}}
                    </div>
                    <div class="login__resolution-footer-hint">{{captions.resolutionNotFound}}</div>
                </div>
            </div>
        </div>

    </section>
</ng-template>
