import { Injectable } from '@angular/core';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { UserAccessBusiness } from '../authentication/useraccess.business';
import { CustomFeeRequest } from 'src/app/tee-time-actions/teetime/player-details/player-info.model';
import { ScheduledTeeTimePlayerCustomFee } from 'src/app/retail/shared/shared.modal';

@Injectable({
    providedIn: 'root'
})

export class CustomFeeService {

  constructor(private _http: GolfScheduleCommunication, public _userAccessBusiness: UserAccessBusiness) {
  }

  public GetCustomFees(body: CustomFeeRequest): Promise<ScheduledTeeTimePlayerCustomFee[]> {
    let response = this._http.putPromise<ScheduledTeeTimePlayerCustomFee[]>({
      route: GolfApiRoute.GetCustomFees 
      ,body: body
    });
    return response;
  }
}