import { OnDestroy } from '@angular/core';
import { Component,HostListener, OnInit } from '@angular/core';
import { PropertyService } from 'src/app/common/services/property.service';
import { GolfLocalization } from '../core/localization/golf-localization';
import { GolfPropertyInformation } from '../core/services/golf-property-information.service';
import { RouteLoaderService } from '../core/services/route-loader.service';
import { ManageSessionService } from '../login/manage-session.service';
import { menuTypes } from '../shared/shared-models';
import { Localization } from 'src/app/common/localization/localization';
import moment from 'moment';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { SignalrService } from 'src/app/common/communication/signalR/signalr.service';
import { NotificationModel, SignalRMessage, SignalRNotificationType, SystemDateChangeMessage } from '../common/communication/signalR/signalR.model';
import { SnackBarType } from '../common/shared/shared/enums/enums';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { ButtonType } from 'src/app/common/enums/shared-enums';
import { PropertySettingDataService } from '../common/dataservices/authentication/propertysetting.data.service';
import { RetailFeatureFlagInformationService } from '../retail/shared/service/retail.feature.flag.information.service';
import { CommonUtilities, SignalRMessages } from '../common/shared/shared/utilities/common-utilities';
import { AlertNewPopupComponent } from 'src/app/common/components/alert-new-popup/alert-new-popup.component';
import { StaffReaderBoardBusiness } from 'src/app/common/staff-reader-board/staff-reader-board.business';
import { StaffReaderBoardDataService } from 'src/app/common/dataservices/staff-reader-board-data.service';
import { UserRoleDataService } from 'src/app/common/dataservices/user-roles-data.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar,MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { GolfSessionKey, Product } from '../shared/global.constant';
import { ChangePropertySevice } from 'src/app/common/services/change-property.service';
import { VCartMessageHandler } from '../common/components/menu/vcart/vcart.MessageHandler';
import { CartUtilities } from '../common/components/menu/vcart/cart.utilities';
import { IcartDataService } from '../common/dataservices/icart.data.service';
import { JWT_TOKEN } from '../app-constants';
import { NotificationFailureType } from '../common/components/menu/menu.model';
import { CartWindowMessageType } from '../common/components/menu/vcart/vcart.modal';
import { HttpCacheService } from '../common/services/cache/http-cache.service';
import { RefurbishSessionStorage } from '../shared/refurbish-session-storage';
import * as FullStory from '@fullstory/browser';
import { FULL_STORY_ORG_ID } from 'src/app/app-constants';
import { JasperServerCommonDataService } from '../common/dataservices/jasperServerCommon.data.service';
import {PROPERTYDIFFERENTIATORCONFIGURATION } from 'src/app/common/constants';
import { API } from '../settings/system-setup/property-info/property-info.model';
import { PropertyDataService } from '../settings/system-setup/property-info/property.data.service';
import { TeeTimeCancellationNoShowFeeUtilities } from '../tee-time-actions/noshow-cancellation-fees-tee-time-utilities';



@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [StaffReaderBoardBusiness, StaffReaderBoardDataService, UserRoleDataService]
})
export class LayoutComponent implements OnInit, OnDestroy {
  title = 'golf-rewrite';
  captions: any;
  menuList: any;
  propertyName: string;
  logOutClicked = false;
  propertyDateTime: any;
  entityName:string;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private _autoLogOff: any = false;
  private _logOffAfter: number = 1;
  themeColor: string = '';

  constructor(private _Localization: GolfLocalization, private sessionManageService: ManageSessionService,
    private _routeDataService: RouteLoaderService, private propertyInfo: GolfPropertyInformation, private localization: Localization, private router: Router, private signalR: SignalrService,
    private utils: CommonUtilities,
    private PropertySettingService: PropertySettingDataService,
    private _propertyDataService: PropertyDataService,
    private retailFeatureInformationService: RetailFeatureFlagInformationService,
    private sessionService: ManageSessionService, public dialog: MatDialog,
    private staffReaderBoardBusiness: StaffReaderBoardBusiness,
    private snackBar: MatSnackBar,private changePropertySevice: ChangePropertySevice,private propertyService: PropertyService,
    private vcartMessageHandler: VCartMessageHandler, private cartUtils: CartUtilities, private cartDataService: IcartDataService,
    private httpCacheService: HttpCacheService,private _refurbishSessionStorage:RefurbishSessionStorage,
    private jasperServerCommonDataService:JasperServerCommonDataService,private _teeTimeCancellationNoShowFeeUtilities:TeeTimeCancellationNoShowFeeUtilities) {
    this._routeDataService.loadSettings().then(result => {
      if (result) {
        const value = this._routeDataService.GetChildMenu('/');
        this.menuList = {
          menuType: menuTypes.primary,
          menu: value
        };
        this._refurbishSessionStorage.InitializeSessionStorage();
      }
    });
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      sessionStorage.setItem('isChangesMade', 'false');
    });
    this.notificationTrigger();
  }

  @HostListener('window:message', ['$event'])
  onMessage({ origin, data }) {
    if (data) {
      try {
        var messageData = JSON.parse(data);
        if (messageData.channel == 'VCART') {
          this.vcartMessageHandler.processWindowMessage(messageData);
        } else {
          // other window messages can be handled here
        }
      } catch (e) {
      }
      // other window messages can be handled here, if not JSON
    }
  }

  triggerNotification() {
    if (!this.propertyInfo.UseRetailInterface) {
      this.sessionManageService.startTimerForNotification(1);
    }
  }

  ngOnInit() {
    window.onbeforeunload = (ev) => {
      if(sessionStorage.getItem('isChangesMade') == 'true'){
        let dialogText = this.localization.captions.common.RefreshMessage;
        ev.returnValue = dialogText;
        return dialogText;
      } else {
        return null;
      }
    };
    let Config: API.PropertyDifferentiatorConfigurationSettings<any> = {
      configurationName: PROPERTYDIFFERENTIATORCONFIGURATION,
      propertyId: Number(this.localization.GetPropertyInfo("PropertyId")),
      productId: Number(this.localization.GetPropertyInfo("ProductId"))
    } as API.PropertyDifferentiatorConfigurationSettings<any>;
    this.toggleStyle();
    this.jasperServerCommonDataService.setauthTokenProvider();
    this.captions = this._Localization.captions.resolutionNotFound;
    this.propertyName = this._Localization.GetPropertyInfo('PropertyName');
    this.propertyService.changeTitle();
    let propConfig = JSON.parse(sessionStorage.getItem("propConfig")); 
    let enableSignalR = propConfig?.EnableSignalR;
    let enableUICache = propConfig?.UICacheEnabled;
    this.time();
    this.setAutoLogOff();
    this._propertyDataService.GetPropertyDifferentiatorConfigurationSettingTenantByPropertyId(Config).then(res => {
      this.themeColor = res.configValue?.propertyDifferentiatorColor;
    })
    if(!FullStory){
      this.setFullStory();
    }
    this.triggerNotification();
    if (this.cartUtils.isEmbed()) {
      this.cartUtils.setEmbedScreenUIClasses();
    }

    this.cartDataService.CartItemEdited.pipe(takeUntil(this.destroyed$)).subscribe(async x => {
      if (x?.cartDetail?.cartPayload) {
        if (x.cartDetail.productId == Product.GOLF) {
          debugger;
          this.router.navigate(['/tee-time/teesheet']);
        }
      }
    });
    this.staffReaderBoardBusiness.GetRecentNotesCount(true).then(x=> {
      if(x >0)
        this.openNotificationAlert()
    });
    if(enableSignalR && enableSignalR.toLowerCase() == "true")
    {
    this.StartSignalrConnection(); 
    }
    if (enableUICache && enableUICache.toLowerCase() == "true") {
      this.setUICache();
    }
    setTimeout(() => {
      this.checkPropertyDate();
    }, 5000);
   
  }
  async setUICache() {
    await this.propertyService.readUICacheJsonData().then((result) => {
      this.localization.uiCacheData = result;
    });
  }

  
  private StartSignalrConnection() {
    this.signalR.startConnection();
    this.signalR.startedConnection.then(res => {
      this.addPropertyListener();
      this.addTenantUserListener();
      this.addCacheListener();
    });
  }

  private addPropertyListener() {
    this.signalR.addPropertyListener(this, this.signalRPropertyListener)
      .catch((err) => console.log('Failure error ' + err));

    this.signalR.hubConnection.onreconnected((reconnect)=>{
      const list=this.signalR.GetSignalREvents();
      list.forEach(e=>{this.signalR.subscribeToEvent(e);});
      });
  }
  
  async signalRPropertyListener(message: SignalRMessage<NotificationModel>): Promise<void> {
    if(message && message.content && message.content.notificationType==SignalRNotificationType.ToasterNotification)
    {
      const content =JSON.parse(message.content.notificationObjectString);
      if (message.name == "Change System Date") {
        await this.UpdatePropertyDateCache(content);
      }
      else if(this._teeTimeCancellationNoShowFeeUtilities.IsNoShowCancellationPolicyConfigured() && 
      (message.name == "Tee Time Cancellation Completed" || message.name == "Tee Time No Show Completed"))
      {
        this.utils.showToastMessage(content.message, SnackBarType.Info,15000);
        this.sessionService.setSessionValue(GolfSessionKey.BackgroundServiceTeeActionCompleted,true);
      }
      else
      {
        this.utils.showToastMessage(content.message, SnackBarType.Success);
      }
    }
    else if(message && message.content && message.content.notificationType==SignalRNotificationType.NotificationIcon){
    
      if(message.content.title == "Note Sent")
      {
        this.utils.showToastMessage(this.captions.lbl_received_new_note, SnackBarType.Success);
      }
      else
      {
      //push to storage
       this.pushToNotificationStorage(message.content);
      }
    }

  }

  private addTenantUserListener(){
    this.signalR.addUserListener(this, this.signalRTenantUserListener)
      .catch((err) => console.log('Failure error ' + err));

      this.signalR.hubConnection.onreconnected((reconnect)=>{
        const list=this.signalR.GetSignalREvents();
        list.forEach(e=>{this.signalR.subscribeToEvent(e);});
        });
  }

  private addCacheListener(){
    this.signalR.addCacheListener(this, this.signalRCacheListener)
    .catch((err) => console.log('Failure error ' + err));
 
  this.signalR.hubConnection.onreconnected((reconnect)=>{
    const list=this.signalR.GetSignalREvents();
    list.forEach(e=>{this.signalR.subscribeToEvent(e);});
    });
 
  }

  async signalRTenantUserListener(message: SignalRMessage<NotificationModel>): Promise<void> {
    if(message && message.content && message.content.notificationType==SignalRNotificationType.NotificationIcon) {
      const content =JSON.parse(message.content.notificationObjectString);
      if(message.name == SignalRMessages.PlatformSyncFailed){
        this.pushToNotificationStorage(message.content);
        this.sessionManageService.transactionCount.next([{ id : NotificationFailureType.cgpsLog, count : 0, message: content.message }]);
      }
       }
  }
  async signalRCacheListener(message: SignalRMessage<NotificationModel>):Promise<void>{
    const signalRCacheDelete = "Clear Cache";
    if (message.name === signalRCacheDelete) {
      if (message.content.notificationObjectString != null) {
        this.entityName = message.content.notificationObjectString;
          if (this.entityName != null) {
            this.httpCacheService.cacheDelete(this.entityName);
          }
        return null;
      }
    }
  }

  pushToNotificationStorage(notification:NotificationModel){
    this.SetNotificationDetails(notification);
  }
  
  public SetNotificationDetails(notification: NotificationModel)
  {
    let PropertyNotification =this.GetNotificationDetails();
    if(PropertyNotification){
      PropertyNotification.push(notification);
    }
    else
    {
      PropertyNotification=[notification];
    }
    sessionStorage.setItem('PropertyNotification', JSON.stringify(PropertyNotification));
  }

  public GetNotificationDetails() : NotificationModel[]{
    const PropertyNotification =JSON.parse(sessionStorage.getItem('PropertyNotification'));
    return PropertyNotification;
  }

  async UpdatePropertyDateCache(message: SystemDateChangeMessage): Promise<void> {    
      let newSystemDate = await this.GetPropertyDate();
      let localizedDate = this.localization.localizeDisplayDate(newSystemDate);
      if (newSystemDate != undefined && newSystemDate != null) {       
          this.utils.showAlert(message.message + ' to ' + localizedDate, AlertType.Success, ButtonType.Ok, (res) => {
            if (res) {
              this.logoutHandler(true);
            }
          });             
      }
    
  }

  async GetPropertyDate() {
    const propertityConfig = await this.PropertySettingService.getAllPropertySetting(Number(this.getPropertyId()));
    return this.localization.getDate(propertityConfig.propertyDate);
  }
  private getPropertyId() {
    return this.localization.GetsessionStorageValue('propertyInfo', 'PropertyId');
  }
  async checkPropertyDate(){
    const propertityConfig = await this.PropertySettingService.getAllPropertySetting(Number(this.getPropertyId()));
    const newDate=this.localization.getDate(propertityConfig.propertyDate);
    const localizeNewDate= new Date(this._Localization.getformattedDateMMDDYYYY(newDate));
    const localizeCurrentDate = new Date(this._Localization.getformattedDateMMDDYYYY(this.propertyInfo.CurrentDate));
    const loggOffCaption =this._Localization.captions.common;
    if(localizeNewDate>localizeCurrentDate)
    {
      this.utils.showAlert(loggOffCaption.lbl_systemDateChange+this.localization.localizeDisplayDate(localizeNewDate),AlertType.Success,ButtonType.Ok,(res) => {
        if (res) {
          this.logoutHandler(true);
        }
      });
      }    
  }


  logoutHandler(arg) {
    this.logOutClicked = true;
    this.retailFeatureInformationService.reset();
    this.sessionService.logout();
    const bodyTag = document.getElementsByTagName('body')[0];
    bodyTag.removeAttribute("id");
  }

  ngOnDestroy() {
    if (this.destroyed$) {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
  }

  LocalizeDateTimeFormatSecondsDDMMMYYYY(value: Date): string {
    if (typeof value == 'string') { value = this._Localization.getDate(value); }
    const separator = '-';
    const localizedMonth: string = this._Localization.getLocalizedMonthShort(value);
    return this._Localization.localizeDisplayDate(value);
  }

  time() {
    if (!this.logOutClicked) {
      if(this.sessionService.GetPropertyInfo('TimeZone')){
      this.propertyDateTime = this._Localization.LocalizeDateTimeFormatSecondsDDMMMYYYYheader(this.propertyInfo.CurrentDate);
      }
    }
  }

  ngAfterViewInit() {
    if (!this.logOutClicked) {
      if(this.sessionService.GetPropertyInfo('TimeZone')){
      setInterval(() => this.time(), 500);
      }
    }
    try{
    this.cartUtils.pushMessageToParentWindow(CartWindowMessageType.IFrameLoaded);
    }
    catch(e){
      console.log(e);
    }
    try{
    this.cartUtils.pushMessageToParentWindow(CartWindowMessageType.IFrameLoaded);
    }
    catch(e){
      console.log(e);
    }
  }

  toggleStyle() {
    this.localization.isNewStyle = true
    // this.localization.isNewStyle = !this.localization.isNewStyle;
    this.setView();
  }

  setView() {
    const bodyTag = document.getElementsByTagName('body')[0];

    if (this.localization.isNewStyle) {
      bodyTag.setAttribute("id", "new-mat-view");
      this.localization.setFloatLabel = 'always';
      this.localization.setFloatLabelNever = 'never';
    } else {
      bodyTag.removeAttribute("id");
      this.localization.setFloatLabel = 'never';
    }
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
      console.log(currentUrl);
    });
  }
  // toastMessage() {
  //   this.utilities.showToastMessage();
  //   showToastMessage(content.message, SnackBarType.Success)
  // }
  
  openNotificationAlert(){
   
    const dialogRef = this.dialog.open(AlertNewPopupComponent, {
      width: '50%',
      height: '50%',
      maxWidth: '50%',
      data:{
        from:'login'
      }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(result => {
      
    });
  }

  notificationTrigger(){
    this.localization.notification$.pipe(takeUntil(this.destroyed$)).subscribe(x => {
        if(x){
          const dialogRef = this.dialog.open(AlertNewPopupComponent, {
            minWidth: '30%',
            height: '40%',
            maxWidth: '40%',
            data:{
              from:'notify'
            },
          panelClass:'alert-notify-class',
        });
        dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(result => {
          
        });
        } 
     })
  }
  setAutoLogOff() {
    this._autoLogOff = this.localization.GetUserSettings('AutoLogOff');
    const tokenDuration = parseInt(sessionStorage.getItem('loginDuration'));
    if (this._autoLogOff == "true") {
      this.sessionService.resetOnTrigger = true;
      this._logOffAfter = +this.localization.GetUserSettings('LogOffAfter');
      this.sessionService.startTimer(this._logOffAfter, tokenDuration);
    } else {
      this.sessionService.resetOnTrigger = false;
    }
  }
  setFullStory(){
    let propertyConfig = JSON.parse(sessionStorage.getItem('propConfig'));
    let userInfo = JSON.parse(sessionStorage.getItem('userInformation'));
    if (propertyConfig.configValue != undefined && propertyConfig.configValue[FULL_STORY_ORG_ID] != undefined) {
      FullStory.init({ orgId: propertyConfig.configValue[FULL_STORY_ORG_ID] });
      FullStory.identify('GOLF-' + userInfo.userName, {
        "displayName": 'GOLF-' + userInfo.userName,
        "productId": Product.GOLF.toString(),
        "productName": "GOLF",
        "tenantId": userInfo.tenantId?.toString() ?? "",
        "tenantCode": userInfo.tenantCode?.toString() ?? "",
        "propertyId": propertyConfig.propertyId?.toString() ?? "",
        "propertyName": this.propertyInfo.GetPropertyInfoByKey('PropertyName')
      });
    }
  }  
}
