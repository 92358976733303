<section class="teefee-wrapper">
  <app-golfdialog-header [title]="rateDetails" (closeDialog)="DialogClose('CancelMove')"></app-golfdialog-header>
  
  <div class="pop-contents ag_p--2">
    <mat-dialog-content >
      <div class="notification-message ag_mt--3" *ngIf="notificationbar && notificationbar.value && notificationbar.value.length > 0">
        <app-note [noteMessage]="notificationbar" ></app-note>
      </div><br>
      <div class="source-destination-table" >
        <app-cdkvirtual [headerOptions]="tableHeaders" [tableContent]="tableContent"></app-cdkvirtual>
      </div>      
    </mat-dialog-content> 
  </div>
  <mat-dialog-actions class="ag_pl--2 ag_pr--2 ag_footer--actions">

    <ng-container [ngSwitch]="data?.buttontype">
          <ng-template [ngSwitchCase]="buttonType.CourseFeeSelection">
            <div>
            <button mat-button class="button--secondary tee-fee-button text-uppercase" (click)="DialogClose('UseSourceTeeFee')">{{useSourceRate}}</button>
          </div>
          <div> 
            <button mat-button class="button--secondary tee-fee-button text-uppercase" [ngClass]="{'disable-control':disableUseDestination}" (click)="DialogClose('UseDestinationTeeFee')">{{useDestinationRate}}</button>
          </div>
            <mat-error class="tee-fee-display pt-3" *ngIf="disableUseDestination">{{destinationSlotFeeNotAllowed}}</mat-error>
            <button mat-button class="button--tertiary text-uppercase" (click)="DialogClose('CancelMove')">{{cancelMove}}</button>
          </ng-template>
    </ng-container>

  </mat-dialog-actions>
</section>
