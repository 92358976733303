import { Injectable } from '@angular/core';
import { ServiceParams ,BaseResponse} from '../../models/http.model';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { HttpCallService } from '../common/http-call.service';
import { HttpErrorResponse,HttpClient } from '@angular/common/http';
import { GolfUtilities } from '../../utilities/golf-utilities';
import { Observable } from 'rxjs';
import { SharedModule } from '../../shared.module';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn : SharedModule
})
export class ReportCommunication extends HttpCallService{

  constructor(httpclient: HttpClient,localization:GolfLocalization,utilities: GolfUtilities, PropertyInfo: GolfPropertyInformation) {
    super(environment["Report"], httpclient,localization,utilities, PropertyInfo);
}

public async getPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
    let response$: Promise<BaseResponse<T>> = super.getPromise<BaseResponse<T>>(params);

    //on error =>
    response$.catch(err => this.error(err, handleErr));

    //on success =>
    let response: BaseResponse<T> = await response$;
    return response.result;
}

public async putPromise<T>(params: ServiceParams, handleErr: boolean = true) {
    let response$: Promise<BaseResponse<T>> = super.putPromise<BaseResponse<T>>(params);

    //on error =>
    response$.catch(err => this.error(err, handleErr));

    //on success =>
    let response: BaseResponse<T> = await response$;
    return response.result;
}

public async postPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
    let response$: Promise<BaseResponse<T>> = super.postPromise<BaseResponse<T>>(params);

    //on error =>
    response$.catch(err => this.error(err, handleErr));

    //on success =>
    let response: BaseResponse<T> = await response$;
    return response.result;
}

public async deletePromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
    let response$: Promise<BaseResponse<T>> = super.deletePromise<BaseResponse<T>>(params);

    //on error =>
    response$.catch(err => this.error(err, handleErr));

    //on success =>
    let response: BaseResponse<T> = await response$;
    return response ? response.result : undefined;
}

putHTTPBlobData(params: ServiceParams, responseType: string): Observable<any> {
    return super.reportPut(params, responseType);
}


private error(err: HttpErrorResponse, handleErr: boolean) {
    if (handleErr) {
        super.errorHandler(err);
    }
    else {
        throw err;
    }
}


}
