import { Component, OnInit, EventEmitter, Output, OnDestroy, Inject } from '@angular/core';
import { DepositeBusiness } from './add-deposite-business';
import { DepositeService } from './add-deposite-service';
import { ReplaySubject } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { StepperService } from '../../shared/components/stepper/stepper.service';
import { takeUntil } from 'rxjs/operators';
import { CheckboxTableHeader, DialogCloseObj, AlertAction, AlertType, ButtonType } from 'src/app/shared/shared-models';
import { PlayerDeposit } from './add-deposite-model';
import { UnPaidPlayersBusiness } from 'src/app/shared/data-services/golfschedule/unpaid-players.business';
import { UnPaidPlayersService } from 'src/app/shared/data-services/golfschedule/unpaid-players.service';
import { AssignRetailItemService } from 'src/app/settings/settings-shared/assign-retail-item/assign-retail-item.service';
import { RateSetupBusiness } from 'src/app/settings/rate-setup/rate-setup.business';
import { RateSetupDataService } from 'src/app/shared/data-services/golfmanagement/rate-setup.data.service';
import { RateTypeDataService } from 'src/app/shared/data-services/golfmanagement/ratetype.data.service';
import { CourseDataService } from 'src/app/shared/data-services/golfmanagement/course.data.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonPopupComponent } from 'src/app/shared/components/common-popup/common-popup.component';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { ButtonAction, GuaranteeType } from 'src/app/shared/global.constant';
import { GolfUserConfigDataService } from 'src/app/shared/data-services/golfmanagement/golfuser.config.data';
import { RetailBussinessService } from 'src/app/shared/retail.bussiness';
import { RateType } from '../teetime/player-details/player-info.model';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { MemberBusinessService } from 'src/app/retail/shared/business/Member-business.service';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import _ from 'lodash';
import { TeeTimeCustomFeeUtilities } from '../tee-time-custom-fee.utilities';


@Component({
  selector: 'app-add-deposite-modal',
  templateUrl: './add-deposite-modal.component.html',
  styleUrls: ['./add-deposite-modal.component.scss'],
  providers: [
    DepositeBusiness,
    DepositeService,
    UnPaidPlayersBusiness,
    UnPaidPlayersService,
    AssignRetailItemService,
    RateSetupBusiness,
    RateSetupDataService,
    RateTypeDataService,
    CourseDataService,
    GolfUserConfigDataService
  ]
})
export class AddDepositeModalComponent implements OnInit, OnDestroy {

  addDepositForm: UntypedFormGroup;
  @Output() notifyParent = new EventEmitter();
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  tableData: PlayerDeposit[];
  headerOption: CheckboxTableHeader[];
  data: any;
  captions: any;
  AddValidators: boolean = false;
  rateTypes: RateType[] = [];

  constructor(
    private _FormBuilder: UntypedFormBuilder
    , private _addDepositBusiness: DepositeBusiness
    , @Inject(MAT_DIALOG_DATA) public dialogData
    , public dialogRef: MatDialogRef<CommonPopupComponent>
    , public _StepperService: StepperService
    , private _retailBusinessService: RetailBussinessService
    , private _localization: GolfLocalization
    , private _rateTypeDataService: RateTypeDataService
    , private _Utilities: GolfUtilities
    , private _memberService: MemberBusinessService
    , private _retailSharedVarService: RetailSharedVariableService
    , private func: RetailFunctionalityBusiness
    , private _teeTimeCustomFeeUtilities: TeeTimeCustomFeeUtilities
  ) {
    this.data = dialogData.info;
    this.captions = this._localization.captions.teetime;
  }

  ngOnInit() {
    this.headerOption = this._addDepositBusiness.GetHeaderOption();
    this.formGenerator();
    this.GetDepositPlayers();
    this._rateTypeDataService.getAllRateTypes(false).then(resp => {
      this.rateTypes = resp;
    });
    this.notifyParent.emit(false);

    this.addDepositForm.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(x => {
      console.log('gurenteee', x);
      if (!this.AddValidators) {
        this.AddMaxValidator();
      }
      if (this.addDepositForm.valid && x.checkBoxItems && x.checkBoxItems.some(x => x.checkBox) && this.checkDepositHasValue(x.checkBoxItems.filter(a => a.checkBox)) && !this._addDepositBusiness.CheckDepositExceedsAmountOwed(x.checkBoxItems.filter(a => a.checkBox))) {
        this.notifyParent.emit(true);
      } else {
        if (this.addDepositForm.touched && x.checkBoxItems && this._addDepositBusiness.CheckDepositExceedsAmountOwed(x.checkBoxItems.filter(a => a.checkBox))) {
          this._addDepositBusiness.ShowValidationPopup();
        }
        this.notifyParent.emit(false);
      }
    });
    this._StepperService.valueChange.pipe(takeUntil(this.$destroyed)).subscribe((x: DialogCloseObj) => {
      if (x.type.toLowerCase() === ButtonAction.process) {
        this.submitForm();
      }
    });
    this._teeTimeCustomFeeUtilities.IsCustomFeeConfigured();
  }

  checkDepositHasValue(players: PlayerDeposit[]) {
    return players.every(p => this._localization.currencyToSQLFormat(p.deposit) > 0);
  }

  ngAfterViewInit() {
    console.log(this.addDepositForm);
  }

  AddMaxValidator() {
    if (this.addDepositForm && this.addDepositForm.controls && this.addDepositForm.controls.checkBoxItems) {
      let formArr = this.addDepositForm.controls.checkBoxItems['controls'] as UntypedFormGroup[];
      if (formArr) {
        formArr.forEach(form => {
          form.controls.deposit.setValidators([Validators.max(Number(form.controls.balance.value)), Validators.min(0)]);
        });
        this.AddValidators = true;
      }
    }
  }

  async GetDepositPlayers() {
    this.tableData = await this._addDepositBusiness.GetDepositPlayers(this.data, this.RefreshGrid.bind(this));
  }

  async RefreshGrid(res, gridData) {
    if (res === AlertAction.YES) {
      this.tableData = gridData;
    }
  }

  formGenerator() {
    this.addDepositForm = this._FormBuilder.group({
    });
  }

  async submitForm() {
    const checkedItems: any[] = this.addDepositForm.value.checkBoxItems.filter(c => c.checkBox);
    if (checkedItems && checkedItems.length && this.rateTypes && this.rateTypes.length) {
      let corpIds = _.uniq(checkedItems.map(o => o.corpId));
      corpIds = corpIds.filter(o => o != null && o != '');
      if(corpIds && corpIds.length > 1){
        this._Utilities.showAlert(this.captions.memberdifferentcorporates,AlertType.Error);
        return false;
      }
      let totalMemberGuaranteedPlayerAssociated = 0;
      checkedItems.forEach(player => {
        const isValidMember = this.isValidMember(player.rateTypeId, player.playerCategoryId);
        if (isValidMember &&
          (++totalMemberGuaranteedPlayerAssociated) > 1
        ) {
          return false;
        }
      });
      // Continue booking scenario handling to restrict one member per transaction
      this._retailSharedVarService.SelectedPlayers.forEach(player => {
        const isValidMember = this.isValidMember(player.rateTypeId, player.playerCategoryId);
        if (isValidMember &&
          (++totalMemberGuaranteedPlayerAssociated) > 1
        ) {
          return false;
        }
      });
      if (totalMemberGuaranteedPlayerAssociated > 1 && !(this.allowMultipleMembersInTransaction() && this._memberService.AllowMultiMemberInTransaction)) {
        this._Utilities.showAlert(this._localization.captions.teetime.RestrictMultiMemberInATransaction, AlertType.Info, ButtonType.Ok);
        return false;
      }
    }

    await this._addDepositBusiness.setDefaultOutlet(this.data.course.id);
    if (this.data.outletId > 0) {
      this._retailSharedVarService.SelectedOutletId = this.data.outletId;
    }
    if (!await this._retailBusinessService.CheckForUserOutletAccessAndDefaultOutlet(this.dialogRef, false, true)) {
      return;
    }

    let SelectedPlayers = this.tableData.filter(t => checkedItems.find(x => x.id == t.id));

    if (SelectedPlayers) {
      if (this._retailBusinessService.checkPlayersHavePendingSettlementInDeposit(SelectedPlayers)) {
        this._StepperService.enableCancel = true;
        return;
      }
      SelectedPlayers.forEach(s => s.deposit = checkedItems.find(c => c.id == s.id).deposit);

      const shopItems = await this._addDepositBusiness.getRetailItemByItemType(this._addDepositBusiness.DepositRetailItemType);
      this._retailSharedVarService.fromTeeTimeCancellation = false;
      this._retailSharedVarService.fromTeeTimeNoShow= false;
      await this._addDepositBusiness.GetDepositRetailItem(SelectedPlayers, this.data, [...shopItems]);
      await this._addDepositBusiness.setSelectedProducts([...shopItems], this.data?.isFromPopup);
    }
  }

  isValidMember(rateTypeId, playerCategoryId): boolean {
    return (rateTypeId > 0 && (this.rateTypes.find(y => y.id == rateTypeId) || ({} as any)).guaranteeType == GuaranteeType.Member)
      || (playerCategoryId === 3);
  }

  ngOnDestroy(): void {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  public async allowMultipleMembersInTransaction() {
    const func: { [key: string]: boolean } = await this.func.getRetailFunctionality();
    return func && func.allowMultipleMembersInTransaction ? func.allowMultipleMembersInTransaction : false;
  }

}
