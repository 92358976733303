
export interface UserBreakPoint {
    userRoleId: number;
    breakPointNumber: number;
    allow: boolean;
    view: boolean;
}

export enum BreakPoint{
    ShopScreen = 3000,

    //user module
    UserSetup = 2300
}

export const enum ReportBreakPoint {
    TeeSheet = 9000,
    CancellationReport = 9005,
    NoShowReport= 	9010,
    WaitList= 	9015,
    BagTags	= 9020,
    TeeTickets= 	9025,
    RainChecksIssued	= 9030,   
    MemberRenewal	= 9040, 
    TournamentList= 	9050,
    ScoreCards= 	9055,
    CartCards	= 9060,
    Lessons= 	9065,
    InstructorSchedules= 	9070,
    TeeTimeUtilization= 	9075,
    TeeTimeAnalysis= 	9080,
    TeeTimeStatisticsbyMonth= 	9085,
    RoundsbyRateType= 	9090,
    RoundsbyPlayerType= 	9095,
    MemberList= 	9105,
    MemberRenewalList= 	9110,
    MemberRoundsPlayed= 	9115,
    TransactionLog= 	9120
}

export const CipherKey:string = "AGYS Golf Key";