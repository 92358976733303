import { Injectable } from '@angular/core';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { UserAccessBusiness } from '../authentication/useraccess.business';
import { DynamicPricingPlayerFee, RateSetupData } from 'src/app/tee-time-actions/teetime/player-details/player-info.model';

@Injectable({
    providedIn: 'root'
})

export class DynamicPricingService {

  constructor(private _http: GolfScheduleCommunication,
    private _localization: GolfLocalization, public _userAccessBusiness: UserAccessBusiness) {
  }

  public GetDynamicPricingForCourseAndScheduledDateAndCourseTeeFee(courseId: number, scheduleDate: Date, courseTeeFee: RateSetupData[] = null): Promise<DynamicPricingPlayerFee[]> {
    const _date: string = this._localization.ConvertDateToISODateTime(scheduleDate).toString();
    let response = this._http.putPromise<DynamicPricingPlayerFee[]>({
      route: GolfApiRoute.GetDynamicPricingForCourseDateAndTeeFee 
      ,body: courseTeeFee, uriParams: { courseId: courseId , scheduleDate: _date }
    });
    return response;
  }

  public GetDynamicPricingForCourseAndScheduledDate(courseId: number, scheduleDate: Date): Promise<DynamicPricingPlayerFee[]> {
    const _date: string = this._localization.ConvertDateToISODateTime(scheduleDate).toString();
    return this._http.getPromise<DynamicPricingPlayerFee[]>({
      route: GolfApiRoute.GetDynamicPricingForCourseAndScheduledDate 
      , uriParams: { courseId: courseId , scheduleDate: _date }
    });
  }
}