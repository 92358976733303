import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonValue, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization';
import { CheckboxTableHeader } from 'src/app/shared/shared-models';
import { ViewRefundDepositBusiness } from './view-refund-deposit.business';
import { viewBy, viewRefundDeposit } from './view-refund-deposit.model';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { PlayerTypeRateTypeChangeService } from 'src/app/shared/service/playerTypeRateTypeChange.service';

@Component({
  selector: 'app-view-refund-deposit',
  templateUrl: './view-refund-deposit.component.html',
  styleUrls: ['./view-refund-deposit.component.scss'],
  providers: [ViewRefundDepositBusiness, PlayerTypeRateTypeChangeService]
})
export class ViewRefundDepositComponent implements OnInit {

  captions: any;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  addDepositForm: UntypedFormGroup;
  TicketHeaderOptions: TableHeaderOptions[];
  playerHeaderOptions: TableHeaderOptions[];
  ticketTableContent: viewRefundDeposit[];
  playerTableContent: viewRefundDeposit[];
  ticketTableOptions: TableOptions;
  playerTableOptions: TableOptions;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  IsEditModeEnabledFlag: boolean;
  viewBy = viewBy;
  viewByOption: number = viewBy.byTicket;
  selectedCustomer:viewRefundDeposit[] = [];
  teeTimeDate:any;

  constructor(private localization: Localization, @Inject(MAT_DIALOG_DATA) public data: any, 
  private dialogRef: MatDialogRef<ViewRefundDepositComponent>, private business : ViewRefundDepositBusiness,
  private _FormBuilder: UntypedFormBuilder) {
    this.captions = this.localization.captions;
    this.teeTimeDate = this.localization.LocalizeDateTimeFormatDDMMMYYYY(new Date(this.data.dateTime));
   }

  ngOnInit(): void {
    this.initialize();
    this.playerHeaderOptions = this.business.getPlayerHeaderOptions();
    this.TicketHeaderOptions = this.business.getTicketHeaderOptions();
    this.playerTableOptions = this.business.getPlayerTableOptions();
    this.ticketTableOptions = this.business.getTicketTableOptions();
    this.GetDepositPlayers();
    //this.playerTableContent = this.business.getPlayerTableContent();
    //this.ticketTableContent  = this.business.getTicketTableContent();
  }

  initialize() {
    this.actionButton = {
      type: 'primary',
      label: this.captions.lbl_RefundDeposit,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
  }
  async GetDepositPlayers() {
    await this.business.GetDepositPlayers(this.data);
    this.playerTableContent = this.business.playerTableContent;
    this.ticketTableContent = this.business.ticketTableContent;
  }

  cancel(eve) {
    let obj = {
      mode: "Cancel"
    }
    this.dialogRef.close(obj);
  }

  save(eve) {
    this.business.proceedToDepositRefund(this.selectedCustomer[0], this.data);
    let obj = {
      // data: this.copiedLessons,
      mode: "Save"
    }
    this.dialogRef.close(obj);
  }

  tableAction(eve) {
    switch (eve.fromType) {
      case FromTypeEnum.rowcheck:
      case FromTypeEnum.allcheckbox:
      {
        this.selectedCustomer = eve.array.filter(item => item.checked);
        console.log(this.selectedCustomer);
        break;
      }
      case FromTypeEnum.radioButton:
      {
        if (this.selectedCustomer.length > 0){
          this.selectedCustomer = [];
        }
        this.selectedCustomer = eve?.selectedData;
        break;
      }
    }
    if(this.selectedCustomer.length > 0 && this.selectedCustomer.some(x => x.remainingAmount > 0)){
      if (this.selectedCustomer.length > 0 && this.business.CheckDepositExceedsAmountOwed(this.selectedCustomer)){
        this.business.ShowValidationPopup();
        this.actionButton.disabledproperty = true;
        this.actionButton = { ...this.actionButton };
      }
      else{
        this.actionButton.disabledproperty = false;
        this.actionButton = { ...this.actionButton };
      }
    }
    else{
      this.actionButton.disabledproperty = true;
      this.actionButton = { ...this.actionButton };
    }
  }

  radioButtonChage(eve){
    this.selectedCustomer = [];
  }

  inputChange(e,ele){
    ele.remainingAmount = Number(ele.remainingAmountString);
    if(this.selectedCustomer.find(x => ele.ticket == x.ticket) && ele.remainingAmount > 0){
      if (this.business.CheckDepositExceedsAmountOwed([ele])){
        this.business.ShowValidationPopup();
        this.actionButton.disabledproperty = true;
        this.actionButton = { ...this.actionButton };
      }
      else{
        this.actionButton.disabledproperty = this.selectedCustomer.length>0 ? false : true;
        this.actionButton = { ...this.actionButton };
      }
    }
    else{
      this.actionButton.disabledproperty = true;
      this.actionButton = { ...this.actionButton };
    }
  }

}
