<section class="hold-tee-time golf-container--padding">
    <div class="time-details">
      {{time | localizeTime}} 
    </div>   
    <div class="hold-tee-time-selection">
        <form [formGroup]="holdTeeTimeForm">
            <div>
                <mat-form-field [floatLabel]="floatLabel" class="golf-form-control">
                <mat-select [placeholder]="captions.teetime.untill" name="Untill" formControlName="untill">
                    <mat-option *ngFor="let untill of untills | async" [value]="untill.value">
                    {{untill.viewValue | formatTime: 'LT'}}
                    </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- <mat-form-field class="golf-form-control">
                    <mat-select [placeholder]="captions.teetime.hole" multiple name="untillHole" formControlName="untillHole">
                        <mat-option *ngFor="let untill of untillHoles | async" m [value]="untill.value">
                        {{untill.viewValue}}
                        </mat-option>
                        </mat-select>
                    </mat-form-field> -->
            </div>
            <div>
                <mat-form-field [floatLabel]="floatLabel" class="comment-section golf-form-control--md">                
                    <input matInput [placeholder]="captions.teetime.comment" formControlName="comment" maxlength="100">
                </mat-form-field>               
            </div>
        </form>
    </div>
  </section>
