<section class="golf-container--padding" id="print">
 <div class="printWrapper">
  <div *ngIf="showPlayers" class="ag_display--flex">
    <span *ngFor="let item of players" [ngClass]=" item.isSelected ? 'player-border-selected': 'player-border'" (click)="selectPlayers(item)">
      {{item.fullName}}
    </span>
  </div>
   <form autocomplete="off" [formGroup]="printForm" class="ag_mt--8">
     <div class="ag_display--flex">
      <div>
        <mat-radio-group  formControlName="print"  class="golf__radio">
          <mat-radio-button class="golf--form-radio-button golf-radio--horizontal" *ngFor="let bagArr of printArray" [value]="bagArr.value"
            [checked]="bagArr.checked"  (change)="onRadioChange(bagArr)">
            {{bagArr.value}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div *ngIf="isCartCard" class="ag_display--flex ml-auto" [ngClass]="[printArray.length>2?  'togglePosition': '']" >     
        <ui-switch (change)="ontoggleRange($event)" [checkedLabel]="captions.checkedLable" [uncheckedLabel]="captions.uncheckedLable" formControlName="printToggle"></ui-switch>
        <label class="golf-form--label mt-1 ml-2">{{captions.PrintInLandscape}}</label>
      </div>
      <div *ngIf="isBagTags" class="ag_display--flex ag_ml--8 align-self-end">     
        <app-golf-date-picker [inputs]="startDateInputs" (datePickerChange)="dateChanged($event)">
        </app-golf-date-picker>
        <app-golf-date-picker [inputs]="endDateInputs" (datePickerChange)="dateChanged($event)">
        </app-golf-date-picker>
      </div>
     </div>
   </form>
 </div>
</section>
