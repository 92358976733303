import { Injectable } from "@angular/core";
import { GolfLocalization } from "src/app/core/localization/golf-localization";
import { CaddyTypeValues } from "src/app/settings/golf-setup/code-setup/caddy-type/caddy-type.modal";
import { CaddyScheduleDataService } from "src/app/shared/data-services/golfschedule/caddySchedule.data.service";
import { CaddyTypeDataService } from "src/app/shared/data-services/golfschedule/caddyType.data.service";
import { API, AvailableCaddy } from "src/app/shared/models/teesheet.api.models";
import { TeeTimeService } from "src/app/tee-time/tee-time.service";

@Injectable()

export class CaddyTypeSelectionPopupBusiness {
    private readonly captions: any;
    constructor(
        private _localization: GolfLocalization, 
        private _caddyTypeDataService: CaddyTypeDataService, 
        private _caddyScheduleDataService: CaddyScheduleDataService) {
        this.captions = this._localization.captions.teetime;
    }

    public async getCaddyTypes(courseId, slotTime, showInActive): Promise<CaddyTypeValues> {
        let caddyTypeAPIData = await this._caddyTypeDataService.getAllCaddyTypes(courseId, slotTime, showInActive);
        return caddyTypeAPIData;
    }


    public async getCaddySchedulesForTeetime(courseId: number, teeTimeDateTime: Date, scheduleId: number): Promise<AvailableCaddy[]>{          
        let caddyScheduleAPIData: API.AvailableCaddy[] = await this._caddyScheduleDataService.getCaddySchedulesForTeetime(courseId, teeTimeDateTime, scheduleId);
        return caddyScheduleAPIData.map(x => this.mapSchedulesToUI(x));
    }

    private mapSchedulesToUI(caddyScheduleAPIData: API.AvailableCaddy): AvailableCaddy {
        return {
            id: caddyScheduleAPIData.caddyId,
            availableName: caddyScheduleAPIData.caddyName,
            scheduleTime: this._localization.formatAMPM(caddyScheduleAPIData.scheduleStartTime, true, true) + " - " + this._localization.formatAMPM(caddyScheduleAPIData.scheduleEndTime, true, true),
            caddyType: caddyScheduleAPIData.caddyTypeName,
            caddyNumber: caddyScheduleAPIData.caddyNumber,
            caddyTypeId: caddyScheduleAPIData.caddyTypeId,
            isAvailableFor18Holes: caddyScheduleAPIData.isAvailableFor18Holes, 
            playerIds: caddyScheduleAPIData.playerIds                                  
        } as AvailableCaddy;       
    }
}