<div class="BGoverlay" (click)="closeWindow()"></div>

<section class="more-dialog-container" #moreSection [style.top.px]="top" [style.left.px]="left">

  <section mat-dialog-title class="more-header dialog-container__header">
    <div [formGroup]="searchForm">
      <div class="header-wrapper">   
        <mat-form-field class=" search-box" [floatLabel]="floatLabelNever">        
          <input type="text" [placeholder]="searchHeaderOption.searchPlaceHolder" matInput formControlName="searchText"
            (input)="searchValueChange()" autocomplete="off" attr.automationId='Srch_{{automationId}}_searhText'>
          <i  aria-hidden="true" [ngClass]="hasValue ? 'icon-Cancel': 'icon-search'" attr.automationId='Icn_{{automationId}}_searchCancel' (click)="hasValue && clearText()"></i>
        </mat-form-field>
      </div>
    </div>  
    <section class="more-alphabets-wrapper">
      <section class="more-alphabets">
        <swiper >
          <span class="charSpan" *ngFor="let char of charArr;let i = index" (click)="charClicked(char,i)" attr.automationId='Lbl_{{automationId}}_charClicked{{i}}'
            [ngClass]="[disableFirstLetter[i] ? '':'disableChar',changeCharBg == i ? 'add-bgcolor':'']">
            {{char}}
          </span>
        </swiper>
      </section>
    </section>
    <span class="dialog-container__header--close icon-Cancel" (click)="close()" attr.automationId='Icn_{{automationId}}_close'></span>
  </section>

  <section class="more-container">
    <custom-checkbox *ngFor="let filteredData of filteredDataArr;let i = index"
      [customCBxDisabled]="filteredData.moreCustomCBxDisabled" [customCBxChecked]="filteredData.moreCustomCBxChecked"
      [customCBxId]="filteredData.moreCustomCBxId" [customCBxwrapperClass]="filteredData.moreCustomCBxwrapperClass"
      [customCBxlabelClass]="filteredData.moreCustomCBxlabelClass"
      [customCBxInputClass]="filteredData.moreCustomCBxInputClass"
      [customCBxlabelText]="filteredData.moreCustomCBxlabelText" [automationId]="automationId"
      (changeEvent)="filteredCheckboxClicked(i,filteredData,$event)" class="custom-checkbox"></custom-checkbox>
  </section>

  <section class="ag_pl--6 more-footer">
      <mat-dialog-actions class="dialog-container__actions">
          <div>
            <button mat-button mat-flat-button color="primary" [disabled]="isDisabled" class="actions__save" attr.automationId='Btn_{{automationId}}_save'
              (click)="getApplyEmitvalue($event)">{{saveButtonText}}</button>
            <button mat-button class="actions__cancel" attr.automationId='Btn_{{automationId}}_cancel' (click)="getCancelEmitvalue($event)">{{cancelButtonText}}</button>
          </div>
        </mat-dialog-actions>   
  </section>
</section>