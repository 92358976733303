<section class="slide-action newVersionWrapper">
    <div class="overlay-div" (click)='slideMenuClose("fromOverlay")'> 
    </div>
    <div class="actions-container">
        <div class="action-content">
            <div class="slide-action-header ag_display--flex alignCenter">
                <h4 class="ag_group--headers ag_pl--4">{{header}}</h4>
                <div class='close ag_ml--auto ag_pr--2'>
                    <i  aria-hidden="true" class='icon-Cancel' [attr.automationId]="'Icn_slideAction_icon-Cancel'" (click)='slideMenuClose()'>&nbsp;</i>
                </div>
            </div>
            <div class="content-container">
                <ng-content></ng-content>
            </div>
        </div>  
    </div>
</section>