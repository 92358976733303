import { Injectable } from "@angular/core";
import { MovePlayerService } from './move-player-modal.service';
import { PlayerTeeTimeSlot } from './move-player-modal.model';
import { CourseDataService } from 'src/app/shared/data-services/golfschedule/course.data.service';
import { TeeSheetMove } from 'src/app/tee-time/shared/tee-sheet/tee-sheet.move';
import { TeeSheetBulk } from 'src/app/tee-time/shared/tee-sheet/tee-sheet.bulk';
import { TeeTimesActionBusiness } from 'src/app/tee-time/shared/tee-action.business';
import { TeeSheetSkeletonData, TeeSheetPlayerDetails, ScheduledPlayer, Allocation } from 'src/app/shared/models/teesheet.form.models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import * as _ from 'lodash';
import { TeeTimesBulkGroupBusiness } from 'src/app/tee-time/shared/tee-bulkGroup.business';
import { AllocationCode, TeeSlotDetails, Bulkstatus } from 'src/app/tee-time/tee-sheet/bulk-tee-time/bulk-tee-time.model';
import { TeeTimeDataService } from 'src/app/shared/data-services/golfschedule/TeeTime.data.service';
import { TeeGridService } from 'src/app/shared/components/tee-grid/tee-grid.service';
import { SettingModule, SettingScreen } from 'src/app/shared/global.constant';
import { API } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { SettingsDataService } from 'src/app/shared/data-services/golfschedule/settings.data.service';
import { BulkTeeTimeService } from 'src/app/tee-time/tee-sheet/bulk-tee-time/bulk-tee-time.service';
import { TeeTimeService } from 'src/app/tee-time-actions/teetime/tee-time.service';
import { CourseCommentDataService } from 'src/app/shared/data-services/golfschedule/courseComment.data.service';
import { NotifierBar } from "src/app/shared/components/note/note.model";
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';
import { allocationBlockPlayerTypePermission } from "src/app/settings/user-setup/booking-permission/booking-permission.model";
import { AllocationBlockDataService as AllocationBlockManagementDataService } from 'src/app/shared/data-services/golfmanagement/allocationblock.data.service';
import { AllocationBlockWithPlayerType } from "src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model";
import { UserAccessModel } from "src/app/common/dataservices/authentication/useraccess-model.model";
import { RateType } from "../teetime/player-details/player-info.model";
import * as RateTypeAPI from 'src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal';
import { RateTypeDataService } from "src/app/shared/data-services/golfschedule/ratetype.data.service";
import { PlayerRetailItem } from "../edit-retail-item/edit-retail-item-model";

@Injectable()

export class MovePlayerBusiness {

    private readonly captions: any;

    constructor(private _MovePlayerService: MovePlayerService, private _courseDataService: CourseDataService, public _TeeSheetMove: TeeSheetMove, public _TeeSheetBulk: TeeSheetBulk,
        private _TeeTimesActionBusiness: TeeTimesActionBusiness, private _localization: GolfLocalization, private _TeeTimesBulkGroupBusiness: TeeTimesBulkGroupBusiness,
        private _teeTimeDataService: TeeTimeDataService, private _TeeGridService: TeeGridService,
        private _settingsDataService: SettingsDataService,private _BulkTeeTimeService : BulkTeeTimeService,
        private _teeTimeService: TeeTimeService, private _courseCommentDataService: CourseCommentDataService,
        private _userAccessBusiness : UserAccessBusiness, private allocationCodeManagementService: AllocationBlockManagementDataService,
        private _rateTypeService: RateTypeDataService) {
        this.captions = this._localization.captions.teetime;
    }

    getCaptions() {
        return {
            date: this.captions.selectdate,
            course: this.captions.course
        };        
    }

    getPlayersDetails(playerInfo) {
        return [
            {
                label: this.captions.course,
                value: playerInfo ? playerInfo.course.course : ''
            },
            {
                label: this.captions.teetime,
                value: playerInfo ? this._localization.LocalizeTime(playerInfo.time,true) : ''
            },
            {
                label: this.captions.players,
                value: playerInfo ? playerInfo.playerDetail.length : ''
            }
        ];        
    }

    public getInitialTeeTimes(): TeeSheetSkeletonData[] {
        return this._TeeSheetBulk.getInitialTeeSlots();
    }

    getPlayerList(playerList) {
        return this._MovePlayerService.getPlayerList(playerList);
    }

    getTeeSheeetID() {
        return this._MovePlayerService.getTeeSheeetID();
    }

    getTeeSheetCustomTableData(teeDialogInfo) {
        return this._MovePlayerService.getTeeSheetCustomTableData(teeDialogInfo);
    }

    public getCourses() {
        return this._courseDataService.getCoursesWithUserAccess();
        
    }

    public getCourseComments(courseId: number, scheduleDate: Date){
        return this._courseCommentDataService.getCourseCommentByCourseIdandDate(courseId, scheduleDate);
        
      }

    public mapPlayerTeeTimeSlots(modifiedSlots: TeeSheetSkeletonData[]): PlayerTeeTimeSlot[] {
        return this._TeeTimesActionBusiness.mapPlayerTeeTimeSlots(modifiedSlots);
    }

    public updateTeeSlots(slots: TeeSheetSkeletonData[]): void {
        this._TeeSheetBulk.addTeeSlots(_.cloneDeep(slots));
    }
    
    getTeeSheetSkeleton(course: number, date: Date, allCourses: any, allRatetypes: any) {
        return this._TeeSheetBulk.getTeeSheet(course, date, undefined,allCourses,undefined,allRatetypes);
    }

    mapTeeSheetplayerDetails(ScheduledPlayers: ScheduledPlayer[], rateTypes: RateType[]): TeeSheetPlayerDetails[] {
        return this._TeeTimesBulkGroupBusiness.mapToPlayerDetails(ScheduledPlayers, rateTypes);
    }

    getBulkPlayers(bookingId: string): Promise<ScheduledPlayer[]> {
        return this._teeTimeDataService.getScheduledPlayersByBookingId(bookingId);
    }

    public mapAllocationBlockData(allocationBlockApiData: Allocation): AllocationCode {
        return {
            id: allocationBlockApiData.id,
            allocationBlockCode: allocationBlockApiData.name,
            allocationBlockName: allocationBlockApiData.name,
            colorCode: allocationBlockApiData.colorCode,
            daysOut: 0
        } as AllocationCode;
    }

    public buildTeeSlots(teeSheetSkeletonData: TeeSheetSkeletonData[], playerDetails: TeeSheetPlayerDetails[], startDateTime: Date | string, playersperGroup: number, allocation: AllocationCode, hole : string): TeeSlotDetails {
        return this._TeeTimesBulkGroupBusiness.buildTeeSlots(teeSheetSkeletonData, playerDetails, startDateTime, playersperGroup, true, hole,true);
    }

    public getBulkStatus(teeSlotDetails: TeeSlotDetails, teeSheetdata: TeeSheetSkeletonData[]): Bulkstatus {
        return this._TeeTimesBulkGroupBusiness.getStatus(teeSlotDetails,teeSheetdata);
    }

    public getSqueezeSlots(currentTeeSheetSkeleton: TeeSheetSkeletonData[], bulkstatus : Bulkstatus, squeezeMins : number ): TeeSheetSkeletonData[]{
        return this._TeeTimesBulkGroupBusiness.getSqueezeSlots(currentTeeSheetSkeleton,bulkstatus, squeezeMins);
       
      }
    
      public addNewSlots(squeezedTeeSlots: TeeSheetSkeletonData[], initialTeeSlots: TeeSheetSkeletonData[]): TeeSheetSkeletonData[] {
        return this._TeeTimesBulkGroupBusiness.addNewSlots(squeezedTeeSlots, initialTeeSlots);
      }

    public buildTeeSheetSkeletonData(currentTeeSheetSkeleton: TeeSheetSkeletonData[], teeSlots: TeeSheetSkeletonData[], playerDetails: TeeSheetPlayerDetails[], playersPerGroup: number, allocation: AllocationCode, isRemovable: boolean, squeezeSlots : TeeSheetSkeletonData[]): TeeSheetSkeletonData[] {
        return this._TeeTimesBulkGroupBusiness.buildTeeSheetSkeletonData(currentTeeSheetSkeleton, teeSlots, playerDetails, playersPerGroup, allocation, isRemovable, squeezeSlots);
    }

    public updateTeeSheetGridContent(modifiedTeeSheetSkeletonData: TeeSheetSkeletonData[]) {
        return this._TeeTimesBulkGroupBusiness.updateTeeSheetGridContent(modifiedTeeSheetSkeletonData);
    }

    public ShowError(errorMessage, errorType, buttonType) {
        this._MovePlayerService.showError(errorMessage, errorType, buttonType);
    }

    public generateModifiedPlayersTeeSlots(initialTeeSlots: TeeSheetSkeletonData[], modifiedTeeSlots: TeeSheetSkeletonData[],playerRetailItems : PlayerRetailItem[] = []): PlayerTeeTimeSlot[] {
        return this._TeeTimesActionBusiness.generateModifiedPlayersTeeSlots(initialTeeSlots, modifiedTeeSlots, playerRetailItems);
    }

    public getPlayersList(): PlayerTeeTimeSlot[] {
        return this._TeeTimesBulkGroupBusiness.getPlayersList();
    }

    public mapToScheduledPlayer(teeSheetPlayerDetails: TeeSheetPlayerDetails[], selectedSlot: TeeSheetSkeletonData): ScheduledPlayer[] {
        return teeSheetPlayerDetails.map(o => {
            return {
                scheduleTeeTimeId: o.sourceScheduledTeeTimeId,
                scheduledDateTime: selectedSlot.time,
                playerId: o.playerId,
                playerTypeId: 0,
                playerSlotPosition: o.playPos,
                firstName: o.firstName,
                lastName: o.lastName,
                allocationBlockId: selectedSlot.allocation.id,
                rateTypeId: 0,
                greenFee: 0,
                cartFee: 0,
                deposits: o.deposits,
                playerStatus: o.playerStatus
            } as ScheduledPlayer;
        });
    }

    public isDropAllowed(draggedPlayers, dropObj): boolean {
        return this._TeeGridService.isDropAllowedOnBulkMove(draggedPlayers, dropObj);
    }
    async GetEmailConfigurationSetting() : Promise<API.Settings<API.EmailConfigurationSettingConfig>>{
        return this._settingsDataService.getSettings<API.EmailConfigurationSettingConfig>(SettingModule.SystemSetup, SettingScreen.EmailConfiguration);      
     }
     ShowAlert(errorMessage, errorType, buttonType,  callback) {
        this._BulkTeeTimeService.showAlert(errorMessage, errorType, buttonType,  callback);
    }
    
    getCourseComment(comment: string): NotifierBar {
        let message: NotifierBar = {
            class: "icon-servicecharge",
            value: '',
            color: "#fff16e"
        }
        message.value = comment ? comment : '';
        return message;
    }

    public async validateBreakPointAccess(breakPointNumber: number, showPopup: boolean = true): Promise<boolean> {
        let breakPointResult: UserAccessModel.BreakPointResult = await this._userAccessBusiness.getUserAccess(breakPointNumber, showPopup);
        return breakPointResult && breakPointResult.isAllow;
    }

    public async GetAllocationBlockPermissionByRole(roleId?: string):Promise<allocationBlockPlayerTypePermission[]> {
        return await this.allocationCodeManagementService.getAllocationBlockPermissionByRole(roleId);
    }

    public async GetAllocationsBlockWithPlayerTypes():Promise<AllocationBlockWithPlayerType[]> {
        return await this.allocationCodeManagementService.getAllocationsBlockWithPlayerTypes();
    }
    public async getRateTypes(): Promise<RateType[]> {
        let rateTypeApiData: RateTypeAPI.RateType[] = await this._rateTypeService.getAllRateTypes(false);      
        return rateTypeApiData.map(x => this.mapRateTypeData(x));
      }
      private mapRateTypeData(rateTypeApiData: RateTypeAPI.RateType): RateType {
        return {
          id: rateTypeApiData.id,
          type: rateTypeApiData.type,
          daysOutStart: rateTypeApiData.daysOutStart,
          daysOutEnd: rateTypeApiData.daysOutEnd
        } as RateType;
      }
}
