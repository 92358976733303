import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { AvailableCaddyCaption } from './caddy-select.model';
import { CaddySelectService } from './caddy-select.service';
import { SaveButtonObj, DateInput } from 'src/app/shared/shared-models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyValue } from '@angular/common';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { AvailableCaddy } from 'src/app/shared/models/teesheet.api.models';

@Component({
  selector: 'app-caddy-select',
  templateUrl: './caddy-select.component.html',
  styleUrls: ['./caddy-select.component.scss'],
  providers: [CaddySelectService],
  encapsulation: ViewEncapsulation.None
})
export class CaddySelectComponent implements OnInit {
  buttonObj: SaveButtonObj;
  caddySelectForm: UntypedFormGroup;
  captions: AvailableCaddyCaption;
  selectDate : DateInput;
  teetimeDateTime : any;
  selectedCaddy: AvailableCaddy;
  selectedCaddyId: number | string;
  header: KeyValue<string, string>[];
  caddies: AvailableCaddy[];

  constructor(private formBuilder: UntypedFormBuilder,
    private _caddySelectService: CaddySelectService,
    private localization: GolfLocalization,
    @Inject(MAT_DIALOG_DATA) public data: { availableCaddy: AvailableCaddy[], selectedCaddy: string ,  teetimeDateTime: any},
    public dialogRef: MatDialogRef<CaddySelectComponent>) { }

  ngOnInit() {
    this.captions = this._caddySelectService.getCaptions();    
    this.selectedCaddyId = this.data.selectedCaddy ? this.data.selectedCaddy : this.data.availableCaddy[0].id;
    this.selectedCaddy = this.data.availableCaddy.find(x=>x.id === this.selectedCaddyId);
    this.teetimeDateTime = this.data.teetimeDateTime;
    this.buttonObj = {
      isEdit: false,
      disabled: false
    };
    this.caddySelectForm = this.formBuilder.group({
      date: [{value: '', disabled: true}],
      radioControl: this.selectedCaddyId
    });
    this.selectDate = {
      form: this.caddySelectForm,
      formControlName: 'date',
      errorMessage: 'Missing Date',
      minDate: new Date(),
      placeHolder: this.captions.date,
      value: this.localization.getDate(this.teetimeDateTime),
      automationId:"'CaddySelect'"
    };
    this.caddySelectForm.get('date').patchValue(this.localization.getDate(this.teetimeDateTime));
    this.buildTableData();
  }

  // Event Handler
  onSelectionChange(arg) {
    this.selectedCaddy = arg;
  }

  closeHandler(arg) {
    this.dialogRef.close();
  }

  saveHandler(arg) {
    this.dialogRef.close(this.selectedCaddy);
  }

  // Public methods
  trackByFn(index, item) {
    return index; // or item.id
  }

  // private methods
  private buildTableData() {
    this.header = [{
      key: 'availableName',
      value: this.captions.availableName,
    },
    {
      key: 'scheduleTime',
      value: this.captions.scheduleTime,
    },
    {
      key: 'caddyType',
      value: this.captions.caddyType,
    }];
    this.caddies = this.data.availableCaddy;
  }
  dateChanged(arg) {
    console.log('timeChange', arg);
  }
}
