import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { StepperService } from 'src/app/shared/components/stepper/stepper.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuditTrailBusiness } from './audit-trail.business';
import { AuditTrailFilterData, AuditTrail, AuditTrailHeader, PlayerData } from './audit-trail.model';
import { ButtonValue, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { AuditTrailDataService } from "src/app/shared/data-services/golfschedule/audit-trail.data.service";
import { ContactDetails } from 'src/app/shared/shared-models';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import _ from 'lodash';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.scss'],
  providers: [AuditTrailBusiness, AuditTrailDataService],
  encapsulation: ViewEncapsulation.None
})
export class AuditTrailComponent implements OnInit {
  floatLabel: any;
  captions: any;
  playersList: PlayerData[] = [];
  showHeader: boolean = false;
  tableOptions: TableOptions;
  groupByUserTableOptions: TableOptions[] = [];
  groupByDateTableOptions: TableOptions[] = [];
  initializeTableOption: boolean = true;
  headerOptions: TableHeaderOptions[];
  groupByUserheaderOptions : TableHeaderOptions[];
  tableContent: any[] = [];
  positions : any[] = [{id: 1, value: 1, selected: false,firstName : '',lastName : ''}, {id: 2, value: 2, selected: false,firstName : '',lastName : ''},{id: 3, value: 3, selected: false,firstName : '',lastName : ''},{id: 4, value: 4, selected: false,firstName : '',lastName : ''}];
  selectedPlayer: any;
  selectedPosition: any;
  auditTrailFilterData: AuditTrailFilterData;
  bodyContentdata: AuditTrail[] = null;
  auditTrails: AuditTrail[];
  playerData: AuditTrailHeader;
  playerIds: number[];
  contactInformation: ContactDetails[];
  contactDetail: ContactDetails[];
  originalData = [];
  proceedButton: ButtonValue;
  showDetails: boolean = false;
  isGroupbyUser: boolean = false;
  modeOptions: any;
  groupByUserName: _.Dictionary<AuditTrail[]>;
  groupByUser: any[] = [];
  groupHeader: any[] = [];
  dateGroupHeader: any[] = [];
  groupByDateArr: any[] = [];
  groupByDate:  _.Dictionary<AuditTrail[]>;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData, private formBuilder: UntypedFormBuilder, public _StepperService: StepperService, public localization: GolfLocalization, private business: AuditTrailBusiness, private dialogRef: MatDialogRef<AuditTrailComponent>, private _utilities: GolfUtilities) {
    this.floatLabel = this.localization.setFloatLabel;
    this.captions = this.localization.captions;

  }

  ngOnInit(): void {
    this.modeOptions = this.getRadioOptions();
    this.initialize();
    this.proceedButton = {
      type: 'primary',
      label: this.captions.btn_process,
      disabledproperty: false
    }
  }

  initialize() {

    this.tableOptions = this.business.getTableOptions();
    this.headerOptions = this.business.getHeaderOptions();
    this.groupByUserheaderOptions = this.headerOptions.filter( x=> x.key != "userName");
    var playerData = this.dialogData.info.playerDetail;
    playerData.forEach(x => {
      const player: PlayerData = {
        firstName: x.firstName == "" ? "BLOCKED" : x.firstName,
        lastName: x.lastName,
        playerId: x.playerId,
        gender: x.gender,
        playerComment: x.playerComment,
        transactionId: x.transactionId,
        confirmationNumber: x.confirmationNumber,
        logDate: x.logDate,
        scheduleTeeTimeId: x.scheduleTeeTimeId,
        playPos: x.playPos
      }
      this.positions[player.playPos-1].firstName = player.firstName;
      this.positions[player.playPos-1].lastName = player.lastName;
      this.playersList.push(player);
    });
    if (this.playersList.length > 0) {
      this.showHeader = true;
      this.selectedPlayer = this.playersList[0];
      this.selectedPosition = this.positions[0];
      this.playerIds = this.playersList.map(x => x.playerId);
      this.auditTrailFilterData = {
        firstName: this.playersList[0].firstName,
        lastName: this.playersList[0].lastName,
        transactionId: this.playersList[0].transactionId,
        confirmationNumber: this.playersList[0].confirmationNumber,
        logDate: this.dialogData.info.allocationDateTime
      }
      this._utilities.ToggleLoader(true);
      this.business.GetAuditTrailData(this.auditTrailFilterData, this.playersList[0].scheduleTeeTimeId,false,this.dialogData.info.course.id,this.dialogData.info.time,this.dialogData.info.originalHoleNumber).then(res => {
        this.auditTrails = res;
        this.onAction(this.selectedPosition.id);
        this._utilities.ToggleLoader(false);
        this.business.GetContactInformationByPlayerIds(this.playerIds).then(res => {
          this.contactInformation = res;
          this.initializeContactData();
        }
        );
      }
      ).catch(e => {
        this._utilities.ToggleLoader(false);
        if (e && e.error && e.error.errorCode == -1)
          this._utilities.showError(e.message);
      });
    }
    else {
      this._utilities.ToggleLoader(true);
      this.business.GetAuditTrailData(null,0,true,this.dialogData.info.course.id, this.dialogData.info.time, this.dialogData.info.originalHoleNumber).then(res => {
        this.auditTrails = res;
        this.onAction(0);
        this._utilities.ToggleLoader(false);
      }
      ).
        catch(e => {
          this._utilities.ToggleLoader(false);
          if (e && e.error && e.error.errorCode == -1)
            this._utilities.showError(e.message);
        })
    }
  }

  getRadioOptions() {
    return [
      {
        id: 1,
        value: 1,
        viewValue: this.captions.lbl_date,
        checked: true
      },
      {
        id: 2,
        value: 2,
        viewValue: this.captions.lbl_user,
        checked: false
      }
    ]
  }
  modeChange(eve) {
    this.isGroupbyUser = (eve.value == 2) ? true : false;
  }
  initializeContactData() {
    if (this.showHeader && this.selectedPlayer != undefined) {
      this.showDetails = true;
      this.contactDetail = this.contactInformation.filter(x => x.playerId == this.selectedPlayer.playerId);
      this.playerData = {
        firstName: this.selectedPlayer.firstName,
        lastName: this.selectedPlayer.lastName,
        gender: this.selectedPlayer.gender,
        mailId: this.contactDetail != null ? this.contactDetail.find(x => x.name == "Email")?.value : '',
        phone: this.contactDetail != null ? this.contactDetail.find(x => x.name == "Phone")?.value : '',
        playerComment: this.selectedPlayer.playerComment,
        playPos: this.selectedPlayer.playPos
      }
    }
    else{
      this.showDetails = false;
      this.playerData = null;
    }
  }

  onPositionClick(position) {
    this.positions.forEach(x => {
      if (x.id === position.id) 
      { x.selected = true}
    });
    this.selectedPosition = this.positions?.find(x => x.id === position.id);
    this.selectedPlayer = this.playersList?.find(x=>x.playPos == position.id);
    this.initializeContactData();
    this.onAction(position.id);
    this.modeOptions = this.getRadioOptions();
  }
  onAction(pos : number) {
    this.initializeTableOption = true;
    this.isGroupbyUser = false;
    if (this.auditTrails != null && this.showHeader) {
      var position = "Position " + pos;
      this.bodyContentdata = [...this.auditTrails.filter(x => x.playerSlotPosition.includes(pos) || x.playerSlotPosition.length == 0 || (x.playerSlotPosition.length==1 && x.playerSlotPosition[0]==0))];
    }
    else {
      this.bodyContentdata = this.auditTrails;
    }

    this.groupByUser = [];
    this.groupByDateArr = [];
    this.groupByUserTableOptions = [];
    this.groupByDateTableOptions = [];
    this.groupHeader = [];
    this.dateGroupHeader = [];
    if (this.bodyContentdata == undefined || this.bodyContentdata == null || this.bodyContentdata.length <= 0) {
      this.initializeTableOption = false;
      this.initializeTableOption = this.initializeTableOption;
    }
    else
    {
      this.groupByUserName = _.groupBy(this.bodyContentdata, item => `${item.userName}_${item.date}`);
      this.groupByDate = _.groupBy(this.bodyContentdata, item => `${item.date}`);

      for (let x in this.groupByUserName) {
        // code block to be executed
        let userName = x.toString().split('_')[0];
        let logDate= x.toString().split('_')[1];
        this.groupHeader.push({userName, logDate});
        this.groupByUser.push(this.groupByUserName[x]);
        let tableHeightOptions = _.cloneDeep(this.tableOptions);
        tableHeightOptions.maxRecordCount = this.groupByUserName[x].length == 1 ? this.groupByUserName[x].length : this.groupByUserName[x].length - 1;
        this.groupByUserTableOptions.push(tableHeightOptions);
      }
      for (let x in this.groupByDate) {
        // code block to be executed
        let logDate = x.toString();
        this.dateGroupHeader.push({logDate});
        this.groupByDateArr.push(this.groupByDate[x]);
        let tableHeightOptions = _.cloneDeep(this.tableOptions);
        tableHeightOptions.maxRecordCount = this.groupByDate[x].length == 1 ? this.groupByDate[x].length : this.groupByDate[x].length - 1;
        this.groupByDateTableOptions.push(tableHeightOptions);
      }
    }
  }
  showMore(data) {
    data.expanded = !data.expanded;
    return data.expanded;
  }

}
