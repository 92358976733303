import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-golfdialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss']
})
export class GolfDialogHeaderComponent {

  @Input() title: string;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();
  automationId:string="";
  @Input('automationId')
  set setAutomationId(value: string) {
    this.automationId = value ? value : '';
  }

  close() {
    this.closeDialog.emit();
  }
}
