export enum teeSheetMenu {
    bulkTeeTime = 'bulkTeeTime',
    teesheet = 'teeSheet',
    groupTeeTime = 'groupTeeTime',
    graphicalView = 'graphicalView',
    holdBlockTeeTime = 'holdBlockTeeTime',
    bulkNotify = 'bulkNotify'
}

export class TeeSheetSessionConstants {
    public static LAST_VISITED_TEE_SLOT = 'LastVisitedTeeSlot'
}
export interface LastVisitedTeeDetail {
    courseId: number,
    date: string,
    time: string,
    hole: string
}

export const SHOW_AVAILABLE_GV = 'showAvailableGV';