import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { recurringTypeConst, monthlyType, RecurringType } from './recurring.modal';
import { RecurringService } from './recurring.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { RecurringDetail } from 'src/app/lessons/lesson-landing/lesson-landing.model';

@Component({
  selector: 'app-recurring',
  templateUrl: './recurring.component.html',
  styleUrls: ['./recurring.component.scss'],  
  providers: [RecurringService]
})
export class RecurringComponent implements OnInit, OnChanges {

  captions: any;
  daily: boolean;
  defaultRecurringData: RecurringType;
  errorCaptions: any;
  isDayMissing: boolean;
  monthSelectionType: any;
  monthly: boolean;
  months: RecurringType[];
  onoccurence: any;
  recurringFormGroup: UntypedFormGroup;
  recurringType: number;
  recurringTypeConst: any;
  startDateInputs: { className: string; form: any; formControlName: string; minDate: Date | string; maxDate?: Date | string; placeHolder: any; value: Date; isDateRequired: boolean;};
  weekly: boolean;
  weeklyData: RecurringType[];
  yearly: boolean;
  dateDiff: number = 0;
  monthDiff: number = 0;
  yearDiff: number = 0;
  recurringDetail: RecurringDetail;
  selectedWeeklyDays = [];
  selectedMonthlyDays = [];
  selectedYearlyDays = [];
  @Input() recurringData: RecurringType[];
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() maxValidator: boolean;
  floatLabel: string;
  @Input('recurringInfo')
  set recurringInfo(value: RecurringDetail) {
    this.recurringDetail = value;
    this.patchRecurringData();    
  }
  automationId:string="";
  @Input('automationId')
  set setAutomationId(value: string) {
    this.automationId = value ? value : '';
  }
  @Output() recurringSelected: EventEmitter<any> = new EventEmitter();

  constructor(private FormBuilder: UntypedFormBuilder, private _RecurringService: RecurringService, private localization: GolfLocalization) {
    this.floatLabel = this.localization.setFloatLabel;
    this.recurringTypeConst = recurringTypeConst;
    this.recurringFormGroup = this.FormBuilder.group({
      recurring: [],
      daily: this.FormBuilder.group({
        every: ['', Validators.required]
      }),
      weekly: this.FormBuilder.group({
        every: '',
        weeklyData: []
      }),
      monthly: this.FormBuilder.group({
        every: '',
        monthlyData: this.FormBuilder.group({
          monthlytype: 1,
          date: '',
          onoccurence: '',
          weeklyData: ''
        })
      }),
      yearly: this.FormBuilder.group({
        every: '',
        onoccurence: '',
        weeklyData: ''
      })
    });

    this.captions = this._RecurringService.generateCaptions();
    this.errorCaptions = this._RecurringService.generateErrorCaptions();
  }

  ngOnChanges(): void {
    this.enableDefaultRecurringType(this.startDate, this.endDate);
    setTimeout(() => {
      this.startDateInputs.minDate = this.startDate;
      this.startDateInputs.maxDate = this.endDate;
      this.startDateInputs.value = this.startDate;
      this.startDateInputs = {...this.startDateInputs};
    },1000);
    if(this.startDateInputs) {
      if(this.recurringType == recurringTypeConst.monthly) {
        this.recurringFormGroup.controls.monthly['controls'].monthlyData['controls'].date.setValue(new Date(this.startDate));      
        this.startDateInputs.isDateRequired = true;
      } else {
        this.startDateInputs.isDateRequired = false;
      }
      this.startDateInputs = {...this.startDateInputs};
    }
  }

  ngOnInit() {
    this.startDateInputs = {
      className: 'golf-form-control--xs',
      form: this.recurringFormGroup.controls.monthly['controls'].monthlyData,
      formControlName: 'date',
      minDate:this.startDate,
      placeHolder: this.captions.Chooseadate,
      value: this.startDate,
      isDateRequired: false
    };
    this.defaultRecurringData = this._RecurringService.defaultRecurringData;
    this.weeklyData = this._RecurringService.weeklyData;
    this.monthSelectionType = this._RecurringService.monthSelectionType;
    if (this.monthSelectionType && this.monthSelectionType.length > 0) {
      this.monthSelectionType[0].checked = true;
    }    
    this.onoccurence = this._RecurringService.onoccurence;
    this.months = this._RecurringService.months;
    this.recurringType = recurringTypeConst.daily;
    this.recurringFormGroup.controls.daily['controls'].every.setValidators([Validators.required]);
    this.recurringFormGroup.controls.daily['controls'].every.updateValueAndValidity();
    this.patchRecurringData();
    this.emitData();
  }

  buttonSelectionChange(event) {    
    switch (event.value.id) {
      case 1:
          this.resetValues();
          this.recurringType = recurringTypeConst.daily;
          this.recurringFormGroup.controls.daily['controls'].every.setValidators([Validators.required]);
          this.recurringFormGroup.controls.daily['controls'].every.updateValueAndValidity();      
        break;
      case 2:
          this.resetValues();
          this.recurringType = recurringTypeConst.weekly;
          this.recurringFormGroup.controls.weekly['controls'].every.setValidators([Validators.required]);
          this.recurringFormGroup.controls.weekly['controls'].weeklyData.setValidators([Validators.required]);
        break;
      case 3:
          this.resetValues();
          this.recurringType = recurringTypeConst.monthly;
          this.recurringFormGroup.controls.monthly['controls'].every.setValidators([Validators.required]);
          this.recurringFormGroup.controls.monthly['controls'].every.updateValueAndValidity();
          if (this.recurringFormGroup.controls.monthly['controls'].monthlyData['controls'].monthlytype == 2) {
            this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.onoccurence.setValidators([Validators.required]);
            this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.onoccurence.updateValueAndValidity();
            this.startDateInputs.isDateRequired = false;
          } else {
            this.startDateInputs.isDateRequired = true;
            this.recurringFormGroup.controls.monthly['controls'].monthlyData['controls'].date.setValue(new Date(this.startDate));
          }
          this.startDateInputs = {...this.startDateInputs};       
        break;
      case 4:
          this.resetValues();
          this.recurringType = recurringTypeConst.yearly;
          this.recurringFormGroup.controls.yearly['controls'].every.setValidators([Validators.required]);
          this.recurringFormGroup.controls.yearly['controls'].onoccurence.setValidators([Validators.required]);
          this.recurringFormGroup.controls.yearly['controls'].weeklyData.setValidators([Validators.required]);
          this.recurringFormGroup.controls.yearly['controls'].weeklyData.updateValueAndValidity();
        break;
    }
    this.recurringFormGroup.controls.recurring.setValue(event.value);
    if(this.recurringDetail && this.recurringDetail.recurringType==this.recurringType) {
      this.patchRecurringData();
    }    
    this.setValidation();
  }

  clearValidators() {
    this.selectedWeeklyDays = [];
    this.selectedMonthlyDays = [];
    this.selectedYearlyDays = [];
    this.recurringFormGroup.controls.daily['controls'].every.setValidators(null);
    this.recurringFormGroup.controls.weekly['controls'].every.setValidators(null);
    this.recurringFormGroup.controls.weekly['controls'].weeklyData.setValidators(null);
    this.recurringFormGroup.controls.monthly['controls'].every.setValidators(null);
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.onoccurence.setValidators(null);
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.setValidators(null);
    this.recurringFormGroup.controls.yearly['controls'].every.setValidators(null);
    this.recurringFormGroup.controls.yearly['controls'].onoccurence.setValidators(null);
    this.recurringFormGroup.controls.yearly['controls'].weeklyData.setValidators(null);
    this.recurringFormGroup.controls.daily['controls'].every.updateValueAndValidity();
    this.recurringFormGroup.controls.weekly['controls'].every.updateValueAndValidity();
    this.recurringFormGroup.controls.weekly['controls'].weeklyData.updateValueAndValidity();
    this.recurringFormGroup.controls.monthly['controls'].every.updateValueAndValidity();
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.onoccurence.updateValueAndValidity();
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.updateValueAndValidity();
    this.recurringFormGroup.controls.yearly['controls'].every.updateValueAndValidity();
    this.recurringFormGroup.controls.yearly['controls'].onoccurence.updateValueAndValidity();
    this.recurringFormGroup.controls.yearly['controls'].weeklyData.updateValueAndValidity();
  }

  resetValues() {
    this.clearValidators();
    this.recurringFormGroup.controls.daily.reset();
    this.recurringFormGroup.controls.weekly.reset();
    this.recurringFormGroup.controls.monthly.reset();
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.monthlytype.setValue(1);
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.date.setValue(this.localization.getDate(this.startDate));
    this.recurringFormGroup.controls.yearly.reset();
    this.isDayMissing = false;
  }

  yearlyonoccurence() {
    this.recurringFormGroup.controls.yearly['controls'].weeklyData.setValidators([Validators.required]);
    if (!this.recurringFormGroup.controls.yearly['controls'].weeklyData.value ||
      (this.recurringFormGroup.controls.yearly['controls'].weeklyData.value &&
        this.recurringFormGroup.controls.yearly['controls'].weeklyData.value.length === 0)) {
      this.isDayMissing = true;
    } else {
      this.isDayMissing = false;
    }
  }

  monthlyonoccurence() {
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.setValidators([Validators.required]);
    if (!this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.value ||
      (this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.value &&
        this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.value.length === 0)) {
      this.isDayMissing = true;
    } else {
      this.isDayMissing = false;
    }
  }

  weeklyonoccurence() {
    this.recurringFormGroup.controls.weekly['controls'].weeklyData.setValidators([Validators.required]);
    if (!this.recurringFormGroup.controls.weekly['controls'].weeklyData.value ||
      (this.recurringFormGroup.controls.weekly['controls'].weeklyData.value &&
        this.recurringFormGroup.controls.weekly['controls'].weeklyData.value.length === 0)) {
      this.isDayMissing = true;
    } else {
      this.isDayMissing = false;
    }
  }

  dailyInputChange(event) {
    this.emitData();
  }

  weeklyButtonSelectionChange(event) {
    this.recurringFormGroup.controls.weekly['controls'].weeklyData.setValue(event.value);
    this.emitData();
    this.weeklyonoccurence();
  }

  monthlyButtonSelectionChange(event) {
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.setValue(event.value);
    this.emitData();
    this.monthlyonoccurence();
  }

  YearlyButtonSelectionChanged(event) {
    this.recurringFormGroup.controls.yearly['controls'].weeklyData.setValue(event.value);
    this.emitData();
    this.yearlyonoccurence();
  }

  YearlyButtonSelectionChange(event) {
    this.emitData();
  }

  radioclick(eve) {
    if (this.recurringFormGroup.controls['monthly'].get('monthlytype').value.id === monthlyType.date) {
      this.recurringFormGroup.controls.monthly['controls'].monthlytype.addControl('date', new UntypedFormControl());
    } else if (this.recurringFormGroup.controls['monthly'].get('monthlytype').value.id === monthlyType.day) {
      this.recurringFormGroup.controls.monthly['controls'].monthlytype.addControl('onoccurence', new UntypedFormControl());
      this.recurringFormGroup.controls.monthly['controls'].monthlytype.addControl('weekly', new UntypedFormControl());
    }
  }

  emitData() {
    this.recurringSelected.emit(this.recurringFormGroup as UntypedFormGroup);
  }

  enableDefaultRecurringType(selectedStartDate, selectedEndDate) {
    selectedStartDate = new Date(this.localization.convertDateToAPIdate(selectedStartDate, '/'));
    selectedEndDate = new Date(this.localization.convertDateToAPIdate(selectedEndDate, '/'));
    if(selectedStartDate && selectedEndDate) {
      this.dateDiff = this._RecurringService.dateDiff(selectedStartDate, selectedEndDate)+1;
      this.monthDiff = this._RecurringService.monthDiff(selectedStartDate, selectedEndDate);
      this.yearDiff = this._RecurringService.yearDiff(selectedStartDate, selectedEndDate);
    } else {
      this.dateDiff = this.monthDiff = this.yearDiff = 0; 
    }
    this.recurringData.forEach(repeatType => {
      switch (repeatType.id) {
        case 1:
          repeatType.disabled = (this.dateDiff <= 1);
          break;
        case 2:
          repeatType.disabled = (this.dateDiff <= 7);
          break;
        case 3:
          repeatType.disabled = (this.monthDiff < 1);
          break;
        case 4:
          repeatType.disabled = (this.yearDiff < 1);
          break;
      }
    });
    const selectedOccuranceValues = this.recurringData.filter(selectedOccurances =>
      this.recurringFormGroup.controls.recurring.value && selectedOccurances.id === this.recurringFormGroup.controls.recurring.value.id
    );
    if (selectedOccuranceValues && selectedOccuranceValues.length > 0 && selectedOccuranceValues[0].disabled) {
      this.recurringType = recurringTypeConst.daily;
      this.defaultRecurringData = this._RecurringService.defaultRecurringData;
      this.recurringData = [...this.recurringData];
      this.recurringDetail = null;
    }
    this.setValidation();
  }

  monthlytypeChange(event) {
    this.resetValues();
    this.recurringFormGroup.controls.monthly['controls'].every.setValidators([Validators.required]);
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.monthlytype.setValue(event.value);
    switch (event.value) {
      case 1:
        this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.patchValue([]);
        this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.onoccurence.setValidators(null);
        this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.setValidators(null);
        this.startDateInputs.minDate = this.startDate;
        this.startDateInputs.maxDate = this.endDate;
        this.startDateInputs.isDateRequired = true;
        this.startDateInputs = {...this.startDateInputs};
        this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.date.setValue(this.startDate);
        this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.date.setValidators([Validators.required]);
      break;
      case 2:
        this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.setValidators([Validators.required]);
        this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.onoccurence.setValidators([Validators.required]);
        this.startDateInputs.isDateRequired = false;
        this.startDateInputs = {...this.startDateInputs};
        this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.date.setValidators(null);
      break;
    }
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.weeklyData.updateValueAndValidity();
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.onoccurence.updateValueAndValidity();
    this.recurringFormGroup.controls.monthly['controls'].monthlyData.controls.date.updateValueAndValidity();
    this.setValidation();
    this.emitData();
  }

  setValidation() {
    if(this.maxValidator) {
      this.setMaxValidator();
    }
  }

  setMaxValidator() {
    let validatorArray = [Validators.required];
    const isDateAvailable = (this.startDate && this.endDate);
    switch (this.recurringType) {
      case recurringTypeConst.daily:
        if(isDateAvailable) {
          validatorArray.push(Validators.max(this.dateDiff));
        }
        this.recurringFormGroup.controls.daily['controls'].every.setValidators(validatorArray);
        this.recurringFormGroup.controls.daily['controls'].every.updateValueAndValidity();
        break;
      case recurringTypeConst.weekly:
        const weekDiff = Math.floor(this.dateDiff/7);
        if(isDateAvailable) {
          validatorArray.push(Validators.max(weekDiff));
        }       
        this.recurringFormGroup.controls.weekly['controls'].every.setValidators(validatorArray);
        this.recurringFormGroup.controls.weekly['controls'].every.updateValueAndValidity();
        break;
      case recurringTypeConst.monthly:
        if(isDateAvailable) {
          validatorArray.push(Validators.max(this.monthDiff));
        }
        this.recurringFormGroup.controls.monthly['controls'].every.setValidators(validatorArray);
        this.recurringFormGroup.controls.monthly['controls'].every.updateValueAndValidity();
        break;
      case recurringTypeConst.yearly:
        // if(isDateAvailable) {
        //   validatorArray.push(Validators.max(this.yearDiff));
        // }
        // this.recurringFormGroup.controls.yearly['controls'].every.setValidators(validatorArray);
        this.recurringFormGroup.controls.yearly['controls'].every.updateValueAndValidity();
        break;

    }
  }

  patchRecurringData() {
    let value = this.recurringDetail;
    if(value) {
      this.recurringType = value.recurringType;
      switch (value.recurringType) {
        case recurringTypeConst.daily:
          let dailyForm = {
            every: value.every
          };
          this.recurringFormGroup.controls.daily.patchValue(dailyForm);
          break;
        case recurringTypeConst.weekly:
          if(this.recurringDetail.dayOfWeek) {
            this.selectedWeeklyDays = JSON.parse(this.recurringDetail.dayOfWeek);
          }
          let weeklyForm = {
            every: value.every,
            weeklyData: this.selectedWeeklyDays
          };
          this.recurringFormGroup.controls.weekly.patchValue(weeklyForm);
          break;
        case recurringTypeConst.monthly:
          if(this.recurringDetail.dayOfWeek) {
            this.selectedMonthlyDays = JSON.parse(this.recurringDetail.dayOfWeek);
          }
          let monthlyForm = {
            every: value.every,
            monthlyData: {
              monthlytype: this.recurringDetail.byDate? monthlyType.date: monthlyType.day,
              date:new Date(this.recurringDetail.date) ,
              onoccurence: value.weekNo,
              weeklyData: this.selectedMonthlyDays
            }
          };
          this.recurringFormGroup.controls.monthly.patchValue(monthlyForm);
          break;
        case recurringTypeConst.yearly:
          if(this.recurringDetail.dayOfWeek) {
            this.selectedYearlyDays = JSON.parse(this.recurringDetail.dayOfWeek);
          }
          let yearlyForm = {
            every: value.every,
            onoccurence: value.weekNo,
            weeklyData: this.selectedYearlyDays
          };
          this.recurringFormGroup.controls.yearly.patchValue(yearlyForm);
          break;        
      }
      this.defaultRecurringData = this.recurringData.find(x=>x.id == value.recurringType);
    } else {
      this.recurringFormGroup.reset();
      this.defaultRecurringData = this._RecurringService.defaultRecurringData;
    }
  }
}
