import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfScheduleCommunication } from 'src/app/shared/communication/services/golfschedule.service';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { ScheduledTeeTimePlayerRetailItem,PlayerRetailItem } from 'src/app/tee-time-actions/edit-retail-item/edit-retail-item-model'

@Injectable({ providedIn: 'root' })
export class EditRetailItemService {

    constructor(private _golfScheduleCommunication: GolfScheduleCommunication, private _HttpClient: HttpClient, private _localization: GolfLocalization,
        private utils: GolfUtilities) {

    }

    public getScheduledTeetimePlayerRetailItems(scheduledTeeTimePlayerIds: number[]): Promise<ScheduledTeeTimePlayerRetailItem[]> {
        return this._golfScheduleCommunication.postPromise<ScheduledTeeTimePlayerRetailItem[]>(
                { route: GolfApiRoute.GetScheduledTeeTimePlayerRetailItemsForPlayers,
                body: scheduledTeeTimePlayerIds});
         
    }

    public getPlayerRetailItems(scheduledTeeTimePlayerIds: number[]): Promise<PlayerRetailItem[]> {
        return this._golfScheduleCommunication.postPromise<PlayerRetailItem[]>(
                { route: GolfApiRoute.GetScheduledTeeTimePlayerRetailItemsForPlayers,
                body: scheduledTeeTimePlayerIds});
         
    }
}