import { VIPType } from '../templates/notification-configuration/notification-configuration.model';



export interface EventActorUIModel {
    id: number;
    description: string;
    code: string;
    collection: Collection[];
}

export interface Collection {
    addtoTabView: boolean;
    actorCode: string;
    listTabName: string;
    id: number;
    selectedList: EventActorCollection[];
    list: EventActorCollection[];
}

export interface UpdatedCollection {
    collection: Collection[];
    type: CollectionType;
}

export interface EventActorCollection {
    id: number;
    desc: string;
}

export interface EventActor {
    id: number;
    actor: string;
    productId: number;
    actorType: number;
}

export interface EventActorsDataProvider {
    providers: IEventActorDataProvider[];
}

export interface IEventActorDataProvider {
    actor: string;
    actorDescription: string;
    host?: string;
    route?: string;
    uiMapper?(apiValues): EventActorCollection[];
}


export interface NotificationDataProvider {
    providers: INotificationDataProvider[];
}
export interface INotificationDataProvider {
    host?: string;
    route?: string;
    uiMapper?(apiValues): VIPType[];
    }


export interface DistributionListUi {
    eventActorsTo?: string;
    eventActorsCC?: string;
    eventActorsBcc?: string;
}

export interface DistributionConfigUi {
    eventActorName?: string;
}

export interface DistributionListApi extends DistributionListUi {
    id?: number;
    distributionName: string;
    distributionType: DistributionListType;
    fromAddress: string;
    productId: number;
    distributionConfigs: DistributionConfigApi[];

}
export interface DistributionConfigApi extends DistributionConfigUi {
    id?: number;
    eventActorId: number;
    value: string;
    collectionType: CollectionType;
}


export interface DistributionListFormGroup {
    distributionlistname: string;
    email: boolean;
    sms: boolean;
    fromEmail: string;
    To: string[];
    CC: string[];
    Bcc: string[];
    ToCollection: EventActorUIModel[];
    CcCollection: EventActorUIModel[];
    BccCollection: EventActorUIModel[];
}

export enum DistributionListType {
    Email = 1,
    Sms,
    Both
}

export enum CollectionType {
    To = 1,
    Cc,
    Bcc
}





export interface Therapist {
    id: number;
    code: string;
    firstName: string;
    lastName: string;
    gender: string;
    dateOfHire: string;
    seniorityLevel: string;
    alsoKnownAs: string;
    allowGratuity: boolean;
    allowServiceCharge: boolean;
    allowCommission: boolean;
    isActive: boolean;
    listOrder: number;
    commissionClassId: number;
}


export interface Users {
    tenantId: 1;
    userId: 1;
    userName: string;
    firstName: string;
    lastName: string;
    password: null;
    isActive: boolean;
  }

  export enum JobType{
    GenericExtract = 1,
    Reports,
    ExecuteQuery,
    API,
    AdhocReport
  }