<!-- <section class="CrudDLSec newVersionWrapper crud-distribution-list-wrapper h-100">
    <form [formGroup]="distributionListForm" class="h-100" autocomplete="off">
        <div class="CrudDLNav LW20">
            <i  aria-hidden="true" class="icon-left-arrow cursor" (click)="promptForUnSavedChanges()"></i>
            <label class="pl-1 text-capital cursor" (click)="promptForUnSavedChanges()">{{HeaderLabel}}</label>
        </div>
        <div class="CRUD-sms-Template-wrapper p-4">
            <div class="DL-LeftSec h-100 d-inline-block">
                <div class="ag_mb--3">
                    <mat-form-field [floatLabel]="floatLabel" class="DL_CRUD_w40">
                        <input [attr.automationId]="'Txt_crudDistributionList_listName'" matInput [placeholder]="crudDLtemplateCaptions.DistributionListName"
                            required formControlName="distributionlistname" [maxlength]="25" />
                        <mat-error>
                            {{crudDLtemplateCaptions.missingDistributionListName}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="d-flex ag_mb--3">
                    <div>
                        <label
                            class="LW12 d-block label-color toggle-label mb7rem">{{crudDLtemplateCaptions.Email}}</label>
                        <app-toggle-switch [attr.automationId]="'Txt_crudDistributionList_emailEnable'" class="mr-4" formControlName="email"
                            (changeToggleEvent)=emailEnabled($event[0])>
                        </app-toggle-switch>
                    </div>
                    <div>
                        <label
                            class="LW12 d-block label-color toggle-label mb7rem">{{crudDLtemplateCaptions.SMS}}</label>
                        <app-toggle-switch [attr.automationId]="'Txt_crudDistributionList_sms'" formControlName="sms" (changeToggleEvent)=smsEnabled($event[0])>
                        </app-toggle-switch>
                    </div>
                </div>
                <div class="ag_mb--3">
                    <mat-form-field [floatLabel]="floatLabel" class="DL_CRUD_w40">
                        <input [attr.automationId]="'Txt_crudDistributionList_from'" matInput formControlName="fromEmail" inputtype="email"
                            [placeholder]="crudDLtemplateCaptions.From" [maxlength]="100" />
                        <mat-error
                            *ngIf="distributionListForm.get('fromEmail').hasError('incorrect') && !distributionListForm.get('fromEmail').hasError('required')">
                            {{crudDLtemplateCaptions.invalidemailFormat}}
                        </mat-error>

                        <mat-error *ngIf="distributionListForm.get('fromEmail').hasError('required')">
                            {{crudDLtemplateCaptions.missingFrom}}
                        </mat-error>

                    </mat-form-field>
                </div>

                <mat-form-field [floatLabel]="floatLabel" class="DL_CRUD_w40 d-block" id="ag-automation-select-to">
                    <mat-select [attr.automationId]="'Dd_crudDistributionList_toDate'" [placeholder]="crudDLtemplateCaptions.To" required formControlName="To" multiple>
                        <mat-option *ngFor="let todata of ToDD_Data" [value]="todata.code"
                            (onSelectionChange)="DDclicked($event,todata,'To',ToListCollection,ToListCollectionFlag)">
                            {{todata.description}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{crudDLtemplateCaptions.missingTo}}
                    </mat-error>
                </mat-form-field>

                <mat-tab-group [ngClass]="isViewOnly? 'ag_section--disable' : ''" [attr.automationId]="'Mt_crudDistributionList_toList'" class="DLcustomfilterSec mb-3"
                    *ngIf='ToListCollectionFlag && ToListCollection && ToListCollection.length > 0'
                    (selectedTabChange)='totabChanged($event)'>
                    <ng-container *ngFor="let Totab of ToListCollection; let index = index">
                        <mat-tab [label]="Totab.listTabName" [id]="Totab.id" *ngIf="Totab.addtoTabView">
                            <ng-template matTabContent>
                                <app-filter-data-section [inputSelectedListData]="Totab.selectedList"
                                    [inputData]="Totab.list" [tabname]="Totab.listTabName"
                                    (emittedData)="filterdataSectionEmit($event,Totab)">
                                </app-filter-data-section>
                            </ng-template>
                        </mat-tab>
                    </ng-container>
                </mat-tab-group>

                <mat-form-field [floatLabel]="floatLabel" class="DL_CRUD_w40 d-block" id="ag-automation-select-CC">
                    <mat-select [attr.automationId]="'Dd_crudDistributionList_ccData'" [placeholder]="crudDLtemplateCaptions.CC" formControlName="CC" multiple>
                        <mat-option *ngFor="let ccdata of ccDD_Data" [value]="ccdata.code"
                            (onSelectionChange)="DDclicked($event,ccdata,'CC',ccListCollection,ccListCollectionFlag)">
                            {{ccdata.description}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{crudDLtemplateCaptions.missingCC}}
                    </mat-error>
                </mat-form-field>

                <mat-tab-group [ngClass]="isViewOnly? 'ag_section--disable' : ''" [attr.automationId]="'Mt_crudDistributionList_toTab'" class="DLcustomfilterSec mb-3"
                    *ngIf='ccListCollectionFlag && ccListCollection && ccListCollection.length > 0'
                    (selectedTabChange)='totabChanged($event)'>
                    <ng-container *ngFor="let cctab of ccListCollection; let index = index">
                        <mat-tab *ngIf="cctab.addtoTabView" [label]="cctab.listTabName" [id]="cctab.id">
                            <ng-template matTabContent>
                                <app-filter-data-section [inputSelectedListData]="cctab.selectedList"
                                    [inputData]="cctab.list" [tabname]="cctab.listTabName"
                                    (emittedData)="filterdataSectionEmit($event,cctab)">
                                </app-filter-data-section>
                            </ng-template>
                        </mat-tab>
                    </ng-container>
                </mat-tab-group>

                <mat-form-field [floatLabel]="floatLabel" class="DL_CRUD_w40 d-block" id="ag-automation-select-bcc">
                    <mat-select [attr.automationId]="'Dd_crudDistributionList_bcc'" [placeholder]="crudDLtemplateCaptions.Bcc" formControlName="Bcc" multiple>
                        <mat-option *ngFor="let bccdata of BccDD_Data" [value]="bccdata.code"
                            (onSelectionChange)="DDclicked($event,bccdata,'Bcc',BccListCollection,BccListCollectionFlag)">
                            {{bccdata.description}}</mat-option>
                    </mat-select>
                    <mat-error>
                        {{crudDLtemplateCaptions.missingBcc}}
                    </mat-error>
                </mat-form-field>

                <mat-tab-group [ngClass]="isViewOnly? 'ag_section--disable' : ''" [attr.automationId]="'Mt_crudDistributionList_addToTab'" class="DLcustomfilterSec mb-3"
                    *ngIf='BccListCollectionFlag && BccListCollection && BccListCollection.length > 0'
                    (selectedTabChange)='totabChanged($event)'>
                    <ng-container *ngFor="let Bcctab of BccListCollection; let index = index">
                        <mat-tab *ngIf="Bcctab.addtoTabView" [label]="Bcctab.listTabName" [id]="Bcctab.id">
                            <ng-template matTabContent>
                                <app-filter-data-section [inputSelectedListData]="Bcctab.selectedList"
                                    [inputData]="Bcctab.list" [tabname]="Bcctab.listTabName"
                                    (emittedData)="filterdataSectionEmit($event,Bcctab)">
                                </app-filter-data-section>
                            </ng-template>
                        </mat-tab>
                    </ng-container>
                </mat-tab-group>
            </div>
            <div class="DL-RightSec pl-1 h-100 d-inline-block">
                <label class="form-label-primary mb7rem">{{crudDLtemplateCaptions.SelectedRecipients}}</label>
                <div class="listDetSec p-2" [ngClass]="isViewOnly? 'ag_section--disable' : ''">
                    <div class="eachListSec">
                        <div class="DL-Hdr" (click)="opencloseAccordian($event)">
                            {{crudDLtemplateCaptions.To}}
                            <i  aria-hidden="true" class="icon-filled_up_arrow arrowstyle deg180"></i>
                        </div>
                        <div class="defaultaccordHght">
                            <div class="ELSecLst" *ngFor="let TLCdata of ToListCollection">
                                <div>
                                    <label (click)="expClpList($event)" class="exp custstyle">+</label>
                                    <label (click)="expClpList($event)" class="collpse custstyle"
                                        *ngIf="TLCdata.selectedList.length>0">-</label>
                                    <label> {{TLCdata.listTabName}}</label>
                                </div>
                                <div class="pt-1 pb-1 list-group-item-light"
                                    *ngFor="let TLCLstdata of TLCdata.selectedList;let l=last;let f=first"
                                    [ngClass]="{'borderBtm':!l,'mt-2':f}">
                                    <label>{{TLCLstdata.desc}}</label>
                                    <label class="deleteListData" (click)="removeRecp(TLCLstdata, TLCdata)">X</label>
                                </div>
                            </div>
                            <div class="norcpthght" *ngIf="(ToListCollection && ToListCollection.length <1 )">

                                {{crudDLtemplateCaptions.NoReceipients}}
                            </div>
                        </div>
                    </div>
                    <div class="eachListSec">
                        <div class="DL-Hdr" (click)="opencloseAccordian($event)">
                            {{crudDLtemplateCaptions.CC}}
                            <i  aria-hidden="true" class="icon-filled_up_arrow arrowstyle deg180"></i>
                        </div>
                        <div class="defaultaccordHght">
                            <div class="ELSecLst" *ngFor="let CCLdata of ccListCollection">
                                <div>
                                    <label (click)="expClpList($event)" class="exp custstyle">+</label>
                                    <label (click)="expClpList($event)" class="collpse custstyle" *ngIf="CCLdata.selectedList.length>0">-</label>
                                    <label> {{CCLdata.listTabName}}</label>
                                </div>
                                <div class="pt-1 pb-1 list-group-item-light"
                                    *ngFor="let CCLLstdata of CCLdata.selectedList;let f=first"
                                    [ngClass]="{'borderBtm':!l,'mt-2':f}">
                                    <label>{{CCLLstdata.desc}}</label>
                                    <label class="deleteListData" (click)="removeRecp(CCLLstdata, CCLdata)">X</label>
                                </div>
                            </div>
                            <div class="norcpthght" *ngIf="(ccListCollection && ccListCollection.length <1 )">

                                {{crudDLtemplateCaptions.NoReceipients}}
                            </div>
                        </div>
                    </div>
                    <div class="eachListSec">
                        <div class="DL-Hdr" (click)="opencloseAccordian($event)">
                            {{crudDLtemplateCaptions.Bcc}}
                            <i  aria-hidden="true" class="icon-filled_up_arrow arrowstyle deg180"></i>
                        </div>
                        <div class="defaultaccordHght">
                            <div class="ELSecLst" *ngFor="let BCCLdata of BccListCollection">
                                <div>
                                    <label (click)="expClpList($event)" class="exp custstyle">+</label>
                                    <label (click)="expClpList($event)" class="collpse custstyle"
                                        *ngIf="BCCLdata.selectedList.length>0">-</label>
                                    <label> {{BCCLdata.listTabName}}</label>
                                </div>
                                <div class="pt-1 pb-1 list-group-item-light"
                                    *ngFor="let BCCLLstdata of BCCLdata.selectedList;let f=first"
                                    [ngClass]="{'borderBtm':!l,'mt-2':f}">
                                    <label>{{BCCLLstdata.desc}}</label>
                                    <label class="deleteListData" (click)="removeRecp(BCCLLstdata, BCCLdata)">X</label>
                                </div>
                            </div>
                            <div class="norcpthght" *ngIf="(BccListCollection && BccListCollection.length <1 )">

                                {{crudDLtemplateCaptions.NoReceipients}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="savecancelsec pl-4 pr-2">
            <button [attr.automationId]="'Btn_crudDistributionList_save'" mat-button class="text_capitalize LW14"
                [ngClass]="EnablesaveUpdBtn ? 'spa-primary-default' : 'spa-primary-disabled'"
                (click)="save()">{{saveUpdLabel}}</button>
            <button [attr.automationId]="'Btn_crudDistributionList_cancel'" mat-button class="ml-2 text_capitalize LW14 " (click)="promptForUnSavedChanges()">
                {{this.commoncaptions.CANCEL}}</button>
        </div>
    </form>
</section> -->

<!-- New Layout-->
<section class="CrudDLSec newVersionWrapper crud-distribution-list-wrapper h-100">
    <form [formGroup]="distributionListForm" class="h-100" autocomplete="off">
        <div class="CrudDLNav LW20">
            <i  aria-hidden="true" class="icon-left-arrow ag_cursor--pointer" (click)="promptForUnSavedChanges()"></i>
            <label class="ag_pl--1 text-capital ag_cursor--pointer" (click)="promptForUnSavedChanges()">{{HeaderLabel}}</label>
        </div>
        <div class="CRUD-template-section ag_container--padding-xs">
            <div class="ag_display--flex">
                <!--CRUD Left side section contains dropdown to,cc,bcc, To mail, From Mail List, userRoles Tab-->
                <div class="CRUD-left-section ag_w--70 ag_container--padding-xs ag_mr--4">
                    <div class="ag_display--flex align-items-center">
                        <mat-form-field [floatLabel]="floatLabel" class="custom-ag_w--25">
                            <input [attr.automationId]="'Txt_crudDistributionList_listName'" matInput [placeholder]="crudDLtemplateCaptions.DistributionListName"
                                required formControlName="distributionlistname" [maxlength]="25" />
                            <mat-error>
                                {{crudDLtemplateCaptions.missingDistributionListName}}
                            </mat-error>
                        </mat-form-field>
    
                        <div class="ag_pl--8" *ngIf="productId != sncId">
                            <label
                                class="ag_form--label ag_pb--2">{{crudDLtemplateCaptions.Email}}</label>
                            <app-toggle-switch [attr.automationId]="'Txt_crudDistributionList_emailEnable'" class="mr-4" formControlName="email"
                                (changeToggleEvent)=emailEnabled($event[0])>
                            </app-toggle-switch>
                        </div>
                        <div class="ag_pl--8" *ngIf="productId != sncId">
                            <label
                                class="ag_form--label ag_pb--2">{{crudDLtemplateCaptions.SMS}}</label>
                            <app-toggle-switch [attr.automationId]="'Txt_crudDistributionList_sms'" formControlName="sms" (changeToggleEvent)=smsEnabled($event[0])>
                            </app-toggle-switch>
                        </div>
                    </div>

                    <div class="ag_display--flex ag_pt--2">
                        <mat-form-field [floatLabel]="floatLabel" class="custom-ag_w--25 ag_pl--0">
                            <input [attr.automationId]="'Txt_crudDistributionList_from'" matInput formControlName="fromEmail" inputtype="email"
                                [placeholder]="crudDLtemplateCaptions.From" [maxlength]="100" />
                            <mat-error
                                *ngIf="distributionListForm.get('fromEmail').hasError('incorrect') && !distributionListForm.get('fromEmail').hasError('required')">
                                {{crudDLtemplateCaptions.invalidemailFormat}}
                            </mat-error>
    
                            <mat-error *ngIf="distributionListForm.get('fromEmail').hasError('required')">
                                {{crudDLtemplateCaptions.missingFrom}}
                            </mat-error>
    
                        </mat-form-field>

                        <div class="ag_pl--8 ag_mr--8">
                            <label class="ag_form--label">{{crudDLtemplateCaptions.lbl_sendTo}}</label>
                            <div class="ag_display--flex ag_pt--2">

                                <div *ngFor="let sendOption of sendToOptionList;let i = index" [ngClass]="i == sendToOptionList.length-1 ? '':'ag_mr--4'" class=" {{sendOption.isSelected ? 'sendOptionSelected' :''}} sendOptionSect  ag_cursor--pointer" (click)="selectSendToOption(sendOption)">
                                    <span>{{sendOption.viewValue}}</span>
                                </div>

                            </div>

                        </div>

                        <div class="custom-ag_w--25">
                            <mat-form-field [floatLabel]="floatLabel" class="ag_w--100" id="ag-automation-select-to" *ngIf="defaultSelectedSendTo == 0">
                                <mat-select [attr.automationId]="'Dd_crudDistributionList_toDate'" [placeholder]="crudDLtemplateCaptions.To" required formControlName="To" multiple>
                                    <mat-option *ngFor="let todata of ToDD_Data" [value]="todata.code"
                                        (onSelectionChange)="DDclicked($event,todata,'To',ToListCollection,ToListCollectionFlag)">
                                        {{todata.description}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    {{crudDLtemplateCaptions.missingTo}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field [floatLabel]="floatLabel" class="ag_w--100" id="ag-automation-select-CC" *ngIf="defaultSelectedSendTo == 1">
                                <mat-select [attr.automationId]="'Dd_crudDistributionList_ccData'" [placeholder]="crudDLtemplateCaptions.CC" formControlName="CC" multiple>
                                    <mat-option *ngFor="let ccdata of ccDD_Data" [value]="ccdata.code"
                                        (onSelectionChange)="DDclicked($event,ccdata,'CC',ccListCollection,ccListCollectionFlag)">
                                        {{ccdata.description}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>
                                    {{crudDLtemplateCaptions.missingCC}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field [floatLabel]="floatLabel" class="ag_w--100" id="ag-automation-select-bcc" *ngIf="defaultSelectedSendTo == 2">
                                <mat-select [attr.automationId]="'Dd_crudDistributionList_bcc'" [placeholder]="crudDLtemplateCaptions.Bcc" formControlName="Bcc" multiple>
                                    <mat-option *ngFor="let bccdata of BccDD_Data" [value]="bccdata.code"
                                        (onSelectionChange)="DDclicked($event,bccdata,'Bcc',BccListCollection,BccListCollectionFlag)">
                                        {{bccdata.description}}</mat-option>
                                </mat-select>
                                <mat-error>
                                    {{crudDLtemplateCaptions.missingBcc}}
                                </mat-error>
                            </mat-form-field>

                        </div>
                    </div>

                    <div>
                        <div *ngIf="defaultSelectedSendTo == 0">
                            <mat-tab-group  [ngClass]="isViewOnly? 'ag_section--disable' : ''" [attr.automationId]="'Mt_crudDistributionList_toList'" class="DLcustomfilterSec mb-3"
                            *ngIf='ToListCollectionFlag && ToListCollection && ToListCollection.length > 0'
                            (selectedTabChange)='totabChanged($event)'>
                        <ng-container *ngFor="let Totab of ToListCollection; let index = index">
                        <mat-tab [id]="Totab.id" *ngIf="Totab.addtoTabView">
                            <ng-template mat-tab-label>
                                {{Totab.listTabName}} ({{Totab.selectedList.length}})
                            </ng-template>
                        <ng-template matTabContent>
                            <app-filter-data-section [inputSelectedListData]="Totab.selectedList"
                                [inputData]="Totab.list" [tabname]="Totab.listTabName"
                                (emittedData)="filterdataSectionEmit($event,Totab,selectedCategory.To)">
                            </app-filter-data-section>
                        </ng-template>
                        </mat-tab>
                        </ng-container>
                            </mat-tab-group>
                        </div>
                       

                        <div *ngIf="defaultSelectedSendTo == 1">
                            <mat-tab-group  [ngClass]="isViewOnly? 'ag_section--disable' : ''" [attr.automationId]="'Mt_crudDistributionList_toTab'" class="DLcustomfilterSec mb-3"
                            *ngIf='ccListCollectionFlag && ccListCollection && ccListCollection.length > 0'
                            (selectedTabChange)='totabChanged($event)'>
                            <ng-container *ngFor="let cctab of ccListCollection; let index = index">
                                <mat-tab *ngIf="cctab.addtoTabView"  [id]="cctab.id">
                                    <ng-template mat-tab-label>
                                        {{cctab.listTabName}} ({{cctab.selectedList.length}})
                                    </ng-template>
                                    <ng-template matTabContent>
                                        <app-filter-data-section [inputSelectedListData]="cctab.selectedList"
                                            [inputData]="cctab.list" [tabname]="cctab.listTabName"
                                            (emittedData)="filterdataSectionEmit($event,cctab,selectedCategory.CC)">
                                        </app-filter-data-section>
                                    </ng-template>
                                </mat-tab>
                            </ng-container>
                            </mat-tab-group>
                        </div>

                        <div *ngIf="defaultSelectedSendTo == 2">
                            <mat-tab-group [ngClass]="isViewOnly? 'ag_section--disable' : ''" [attr.automationId]="'Mt_crudDistributionList_addToTab'" class="DLcustomfilterSec mb-3"
                            *ngIf='BccListCollectionFlag && BccListCollection && BccListCollection.length > 0'
                            (selectedTabChange)='totabChanged($event)'>
                            <ng-container *ngFor="let Bcctab of BccListCollection; let index = index">
                                <mat-tab *ngIf="Bcctab.addtoTabView" [id]="Bcctab.id">
                                    <ng-template mat-tab-label>
                                        {{Bcctab.listTabName}} ({{Bcctab.selectedList.length}})
                                    </ng-template>
                                    <ng-template matTabContent>
                                        <app-filter-data-section [inputSelectedListData]="Bcctab.selectedList"
                                            [inputData]="Bcctab.list" [tabname]="Bcctab.listTabName"
                                            (emittedData)="filterdataSectionEmit($event,Bcctab,selectedCategory.BCC)">
                                        </app-filter-data-section>
                                    </ng-template>
                                </mat-tab>
                            </ng-container>
                        </mat-tab-group>
                        </div>

                       

                        

                    </div>
                    

                </div>

            <!--CRUD Right side section contains To, CC, BCC Mail view section-->

                <div class="CRUD-right-section custom-ag_w--28">
                    <div class="ag_display--flex align-items-baseline ag_container--padding-xs">
                        <label class="ag_font--bold">{{crudDLtemplateCaptions.lbl_emailWillSendTo}}</label>
                        <span class="ag_ml--auto" [ngClass]="ToTotalCount || ccTotalCount || bccTotalCount ? 'ag_link--sm' : 'ag_section--disable'" (click)="clearAllSelectedList()">{{crudDLtemplateCaptions.lbl_clearAll}}</span>
                    </div>
                    <div class="view-To-section ag_container--padding-xs">
                        <div class="ag_display--flex align-items-center" (click)="opencloseAccordian($event,selectedCategory.To)">
                            <i  aria-hidden="true" class="icon-Up-Arrow ag_mr--2"></i>
                            <span class="ag_mr--2">{{crudDLtemplateCaptions.To}}</span>
                            <span class="count-label ag_cursor--pointer text-ellipsis">{{ToTotalCount}}</span>
                        </div>
                        <ng-container *ngIf="ToExpadCollapse">
                            <div *ngFor="let TLCdata of ToListCollection" class="ag_container--padding-xs" >
                                <div class="ag_display--flex align-items-center">
                                    <span class="ag_pr--2 ag_font--bold">{{TLCdata.listTabName}}</span>
                                    <span class="icon-information" *ngIf="TLCdata.listTabName == 'InquiryContact' && productId == sncId" [matTooltip]="captions.lbl_inquiryContact"></span>
                                    <span [ngClass]="TLCdata.addtoTabView ? '':'ag_display--none'">({{TLCdata.selectedList.length}})</span>
                                </div>
                                <div class="ag_display--flex-wrap" *ngIf="ToListCollection.length > 0">
                                    <div *ngFor="let TLCLstdata of TLCdata.selectedList" (click)="removeRecp(TLCLstdata, TLCdata,selectedCategory.To)" class="ag_cursor--pointer selected-list-section ag_mt--2 ag_mr--2">
                                        <span class="ag_pr--4">{{TLCLstdata.desc}}</span>
                                        <i class="icon-close"></i>
                                    </div>
                                </div>   
                            </div>
                            <div *ngIf="ToListCollection.length == 0" class="no-receipients ag_container--padding-lg">
                                {{crudDLtemplateCaptions.NoReceipients}}
                            </div>
                        </ng-container>

                    </div>

                    <div class="view-cc-section ag_container--padding-xs">
                        <div class="ag_display--flex align-items-center" (click)="opencloseAccordian($event,selectedCategory.CC)">
                            <i  aria-hidden="true" class="icon-Up-Arrow  ag_mr--2"></i>
                            <span class="ag_mr--2">{{crudDLtemplateCaptions.CC}}</span>
                            <span class="count-label ag_cursor--pointer text-ellipsis">{{ccTotalCount}}</span>
                        </div>
                        <ng-container *ngIf="ccExpandCollapse">
                            <div *ngFor="let CCLdata of ccListCollection" class="ag_container--padding-xs" >
                                <div class="ag_display--flex align-items-center">
                                    <span class="ag_pr--2 ag_font--bold">{{CCLdata.listTabName}}</span>
                                    <span class="icon-information" *ngIf="CCLdata.listTabName == 'InquiryContact' && productId == sncId" [matTooltip]="captions.lbl_inquiryContact"></span>
                                    <span [ngClass]="CCLdata.addtoTabView ? '':'ag_display--none'">({{CCLdata.selectedList.length}})</span>
                                </div>
                                <div class="ag_display--flex-wrap" *ngIf="ccListCollection.length > 0">
                                    <div *ngFor="let CCLLstdata of CCLdata.selectedList" (click)="removeRecp(CCLLstdata, CCLdata, selectedCategory.CC)" class="ag_cursor--pointer selected-list-section ag_mt--2 ag_mr--2">
                                        <span class="ag_pr--4">{{CCLLstdata.desc}}</span>
                                        <i class="icon-close"></i>
                                    </div>
                                </div>    
                            </div>
                            <div *ngIf="ccListCollection.length == 0" class="no-receipients ag_container--padding-lg">
                                {{crudDLtemplateCaptions.NoReceipients}}
                            </div>
                        </ng-container>
                    </div>

                    <div class="view-bcc-section ag_container--padding-xs">
                        <div class="ag_display--flex align-items-center" (click)="opencloseAccordian($event,selectedCategory.BCC)">
                            <i  aria-hidden="true" class="icon-Up-Arrow ag_mr--2"></i>
                            <span class="ag_mr--2">{{crudDLtemplateCaptions.Bcc}}</span>
                            <span class="count-label ag_cursor--pointer text-ellipsis">{{bccTotalCount}}</span>
                        </div>
                        <ng-container *ngIf="bccExpandCollapse">
                            <div *ngFor="let BCCLdata of BccListCollection" class="ag_container--padding-xs" >
                                <div class="ag_display--flex align-items-center">
                                    <span class="ag_pr--2 ag_font--bold">{{BCCLdata.listTabName}}</span>
                                    <span class="icon-information" *ngIf="BCCLdata.listTabName == 'InquiryContact' && productId == sncId" [matTooltip]="captions.lbl_inquiryContact"></span>
                                    <span [ngClass]="BCCLdata.addtoTabView ? '':'ag_display--none'">({{BCCLdata.selectedList.length}})</span>
                                </div>
                                <div class="ag_display--flex-wrap" *ngIf="BccListCollection.length > 0">
                                    <div *ngFor="let BCCLLstdata of BCCLdata.selectedList" (click)="removeRecp(BCCLLstdata, BCCLdata,selectedCategory.BCC)" class="ag_cursor--pointer selected-list-section ag_mt--2 ag_mr--2">
                                        <span class="ag_pr--4">{{BCCLLstdata.desc}}</span>
                                        <i class="icon-close"></i>
                                    </div>
                                </div>    
                            </div>
                            <div *ngIf="BccListCollection.length== 0" class="no-receipients ag_container--padding-lg">
                                {{crudDLtemplateCaptions.NoReceipients}}
                            </div>
                        </ng-container>
                    </div>

                </div>
            </div>
        </div>
        <div class="ag_container--padding-xs button-section">
            <button [attr.automationId]="'Btn_crudDistributionList_save'" mat-button class="text_capitalize LW14"
                [ngClass]="EnablesaveUpdBtn ? 'spa-primary-default' : 'spa-primary-disabled'"
                (click)="save()">{{saveUpdLabel}}</button>
            <button [attr.automationId]="'Btn_crudDistributionList_cancel'" mat-button class="ml-2 text_capitalize LW14 " (click)="promptForUnSavedChanges()">
                {{this.commoncaptions.CANCEL}}</button>
        </div>
    </form> 
</section>
