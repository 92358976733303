import { UntypedFormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import * as _ from 'lodash'
import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from "@angular/core";
import { GolfLocalization } from "../../core/localization/golf-localization";
import { Router } from "@angular/router";
import { AlertType, ButtonType, FilterGroup, Filter, AlertAction, LegendInfo, ContactType, GolfPMSSessionInfo, ContactDetails } from '../shared-models';

import { HttpClient } from '@angular/common/http';
import { AlertPopupComponent } from '../components/alert-popup/alert-popup.component';
import { TimeRange } from '../models/teesheet.form.models';
import { PlayerPaymentstatus } from 'src/app/tee-time/search/search-model';
import { CardSwipePopupComponent } from '../components/card-swipe-popup/card-swipe-popup.component';
import { Tournament } from 'src/app/tee-time/tournaments/tournaments.model';
import { PropertyConfigurations, GolfSessionKey, SettingScreen, CacheConstants } from '../global.constant';
import { DefaultSetting, MultiCourseViewPlayerNameFormat, NameFormatDropDown } from '../models/default-settings.models';
import { MailTypes, PhoneTypes } from 'src/app/shared/global.constant';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { HttpServiceCall } from 'src/app/common/shared/shared/service/http-call.service';
import { MoreSectionServiceService } from 'src/app/common/shared/shared/more-section/more-section-service.service';
import { FormatText } from 'src/app/common/shared/shared/pipes/formatText-pipe.pipe';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { BalanceList, GolfPoints } from "src/app/retail/shared/business/shared.modals";
import { API, API as setting } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { PlayerType } from "src/app/settings/golf-setup/code-setup/player-type/player-type.modal";
import { takeUntil } from "rxjs/operators";
import { ReplaySubject } from "rxjs";
import moment from "moment";
import { AgSnackBarComponent } from 'src/app/common/components/ag-snack-bar/ag-snack-bar.component';
import { SnackBarType } from "src/app/common/shared/shared/enums/enums";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";



export enum RedirectToModules {
  retail,
  order,
  appointment,
  settings,
  Utilities,
  exchange,
  Dayend,
  home
}

export interface PatchJson {
  op: PatchOperation;
  path: string;
  value: any;
}

export interface AppointmentColors {
  BackGround: string;
  BackGroundLight: string;
  Color: string;
  ColorLight: string;
  Border: string;
}

export enum PatchOperation {
  add = "add",
  remove = "remove",
  replace = "replace",
  copy = "copy",
  move = "move",
  test = "test"
}

export enum RecurringType {
  Daily = 0,
  Weekly = 1,
  Monthly = 2,
  Yearly = 3
}



export enum InputTypeNumbers {
  NUMBERS = "onlynumber",
  ONLYPOSITIVE = "nonnegative",
  ONLYNEGATIVE = "onlynegative",
  NODECIMAL = "nodecimal",
  DECIMAL = "decimal",
  ROUNDOFF = "roundoff2",
  PERCENT = "validPercentage",
  POSITIVEDECIMAL = "onlyPositiveDecimal",
  POSITIVEDECIMALORNUMERIC = 'PositiveDecimalOrNumeric',
  NUMBERWITHSEPARATOR = "numberWithSeparator"
}

export enum InputTypeText {
  CAP = "capitalise",
  TEXT = "textonly",
  NOSPL = "nospecailchar",
  NOSPACE = "notallowspace",
  EMAIL = "email",
  FIRST_CHAR_CAP = "firstcharcapitalise",
  NOPRESPACE = "noprespace",
  RESTRICTSCRIPT = "restrictscript"
}


export interface CssProp {
  property: string;
  value: string;
}


export function stringFormat(input: string, appendBy?: string) {
  if (appendBy) {
    let hasInputAppend = (input == "" ? "" : input + appendBy);
    return input ? hasInputAppend : "";
  } else {
    let hasInput = (input == "" ? "" : input);
    return input ? hasInput : "";
  }
}



export function convertPhoneNoToUiNo(num: string): string {
  if (num != null || num == "") {
    return (
      num.substring(3, 0) +
      " - " +
      num.substring(6, 3) +
      " - " +
      num.substring(num.length, 6)
    );
  } else {
    return "";
  }
}

export function tConvert(tt) {
  var time = tt.substring(tt.indexOf("T") + 1, tt.length);
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

export function addMinutesToGivenTime(time, minTobeAdded) {
  let dummyDate: Date = this.getDate("2019-01-01T" + time);
  let dateTimeValue = dummyDate.setMinutes(
    dummyDate.getMinutes() + minTobeAdded
  );
  let dateTimeWithAddedMinutes = this.getDate(dateTimeValue);

  let hours = dateTimeWithAddedMinutes.getHours();
  let min = dateTimeWithAddedMinutes.getMinutes();

  return (hours < 9 ? "0" + hours.toString() : hours.toString()) + ":" + (min < 9 ? "0" + min.toString() : min.toString());
}

@Injectable(
  {
    providedIn: 'root'
  }
)
export class GolfUtilities extends CommonUtilities implements OnDestroy {
  subscription: any;
  isDoubleClickDisabled: boolean = true;
  private rendrer: Renderer2;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  countryDetails = [];
  constructor(public localization: GolfLocalization, public dialog: MatDialog,
    private rendererFactory: RendererFactory2,
    public route: Router,public snackBar: MatSnackBar,
    public http: HttpClient, public httpServiceCall: HttpServiceCall,
    public _MoreSectionServiceService: MoreSectionServiceService, public PropertyInfo: GolfPropertyInformation, public CommonPropertyInfo: CommonPropertyInformation, public formatphno: FormatText) {
    super(localization, dialog, httpServiceCall, route,
      _MoreSectionServiceService, CommonPropertyInfo, formatphno,snackBar);
    this.rendrer = rendererFactory.createRenderer(null, null);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public ShouldDisableSave(form: UntypedFormGroup, initialValue: any = undefined, otherValidations: boolean = true): boolean {
    let validity: boolean;

    if (initialValue) {
      validity = !(form.dirty && !_.isEqual(form.value, initialValue) && form.valid && otherValidations);
    }
    else {
      validity = !(form.dirty && form.valid && otherValidations);
    }
    return validity;
  }

  GetFormattedDateDDMMYYYY(dt: Date) {
    return this.localization.GetFormattedDateDDMMYYYY(dt);
  }


  localizeDateWithFormat(input: Date, formatType: string) {
    const localeCode = this.GetPropertyInfo('Language');
    moment.locale(localeCode ? localeCode : 'en-US');
    return moment(input).format(formatType);
  }

  /**
   * rGuest-Golf Alert popup to show 'Warning' , 'Error' , 'Success'
   * @param {string} message  - Content
   * @param {AlertType} type  - Type of Popup
   * @param {ButtonType} [btnType=ButtonType.Ok] - Button Actions Type( by default 'Ok')
   * @param {(result: string, extraParams?: any[]) => void} [callback] - CallBack ( optional )
   * @returns - Dialog Reference of the modal with Result of enum AlertAction
   * @memberof Utilities
   */
  public showAlert(message: string, type: AlertType, btnType: ButtonType = ButtonType.Ok,
    callback?: (result: AlertAction, extraParams?: any[]) => void, extraParams?: any[], headerText?: string): MatDialogRef<AlertPopupComponent, AlertAction> {

    const dialogRef = this.dialog.open(AlertPopupComponent, {
      height: 'auto',
      minWidth: '300px',
      maxWidth: '400px',
      data: { type: type, message: message, buttontype: btnType, header: headerText },
      panelClass: 'small-popup',
      disableClose: true,
    });
    this.subscription = dialogRef.afterClosed().subscribe(res => {
      if (callback)
        callback(res, extraParams);
    });
    return dialogRef;
  }

  public showAlertWithCustomWidth(message: string, type: AlertType, btnType: ButtonType = ButtonType.Ok, width: number,
    callback?: (result: AlertAction, extraParams?: any[]) => void, extraParams?: any[], headerText?: string): MatDialogRef<AlertPopupComponent, AlertAction> {
    let cusWidth = width ? width : 300;
    const dialogRef = this.dialog.open(AlertPopupComponent, {
      height: 'auto',
      width: cusWidth + 'px',
      data: { type: type, message: message, buttontype: btnType, header: headerText },
      panelClass: 'medium',
      disableClose: true,
    });
    this.subscription = dialogRef.afterClosed().subscribe(res => {
      if (callback)
        callback(res, extraParams);
    });
    return dialogRef;
  }

  /**
*
* @param popupData
* @param callback
* @returns - Dialog Reference of the modal with Result
*/
  OpenCardSwipeDialog(popupData: any, callback?: (x: any) => void): MatDialogRef<any, any> {
    const data = {
      headername: popupData.headertext,
      headerIcon: popupData.icon,
      headerMessage: popupData.text,
      buttonName: popupData.buttonname,
      type: 'message',
      isloaderenable: popupData.isloaderenable,
      isHiddenFieldRequired: popupData.isHiddenFieldRequired,
      cardpayment: popupData.cardpayment,
      isManualEntry: popupData.isManualEntry
    }
    const dialogRef = this.dialog.open(CardSwipePopupComponent, {
      width: '350px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: data,
      disableClose: true
    });
    const subscription = dialogRef.afterClosed().subscribe((x) => {
      if (callback) {
        callback(x);
      }
      if (subscription) {
        subscription.unsubscribe();
      }
    });
    return dialogRef;
  }

  /**
  * Shows rGuest-Golf Error Popup
  * @param {string} message content
  * @memberof Utilities
  */
  public showBreakPointError(breakPointNumber: number): void {
    let errorMessage: string = this.localization.captions.common.BreakPointAccessDeniedMsg;
    let description = this.localization.captions.breakpoint[breakPointNumber];
    let message = `${errorMessage} <br/> Breakpoint: ${breakPointNumber} - ${description}`;
    this.showAlert(message, AlertType.Error, ButtonType.Ok);
  }

  public getPhoneType() {
    return [
      { id: 2, description: this.localization.captions.contactTypes.home, type: "Phone" },
      { id: 3, description: this.localization.captions.contactTypes.work, type: "Phone" },
      { id: 1, description: this.localization.captions.contactTypes.mobile, type: "Phone" }
    ];
  }
  public getEmailType() {
    return [
      { id: 9, description: this.localization.captions.contactTypes.personal, type: "Email" },
      { id: 10, description: this.localization.captions.contactTypes.work, type: "Email" }
    ];
  }

  /**
   * Shows rGuest-Golf Error Popup
   * @param {string} message content
   * @memberof Utilities
   */
  public showError(message: string): void {
    this.showAlert(message, AlertType.Error, ButtonType.Ok);
  }

  /**
   * Checks whether given date lies in range
   * @param {Date} dateToCheck
   * @param {Date} from
   * @param {Date} to
   * @returns {boolean}
   * @memberof Utilities
   */
  public isDateInGivenRange(dateToCheck: Date, from: Date, to: Date): boolean {
    return (dateToCheck.getTime() <= to.getTime() && dateToCheck.getTime() >= from.getTime());
  }

  /**
   * Generates localized time range filter
   * @param {number} id
   * @param {boolean} [includeAll=true] // includes All option
   * @param {number} [interval=3]  // interval between each range
   * @param {number} [startingHour=9]  // starting time by default 9
   * @param {number} [endingHour=24]   // starting time by default 24
   * @returns {FilterGroup}
   * @memberof Utilities
   */
  public getTimeRangeFilter(id: number, includeAll: boolean = true, interval: number = 3, startingHour: number = 9, endingHour: number = 24): FilterGroup {

    const _dte: Date = this.localization.getDate(new Date);
    let _filters: Filter[] = [];

    if (includeAll) _filters.push({ id: 0, name: this.localization.captions.teetime.All });

    let startHr: number = startingHour;
    let index: number = 1;

    while (startHr < endingHour) {
      let timeStr: Filter;
      if (startHr + interval >= 24) {
        timeStr = this.createTimeFilter(index, _dte.setHours(startHr, 0, 0, 0), _dte.setMinutes(((24 - startHr) * 60) - 1));
      } else {
        timeStr = this.createTimeFilter(index, _dte.setHours(startHr, 0, 0, 0), _dte.setHours((startHr + interval), 0, 0, 0));
      }
      _filters.push(timeStr);
      startHr = startHr + interval;
      index++;
    }

    return {
      id: id,
      title: this.localization.captions.teetime.TimeRange,
      name: 'Time Range',
      filters: _filters
    }
  }


  private createTimeFilter(id: number, start: number, end: number): Filter {

    const startDte = this.localization.getDate(start);
    const endDte = this.localization.getDate(end);

    return {
      id: id, name: `${this.localization.LocalizeTime(startDte, true)} - ${this.localization.LocalizeTime(endDte, true)}`
      , extraParam: { from: startDte, to: endDte } as TimeRange
    }
  }

  public generateDateRanges(startDate: Date, endDate: Date): Date[] {
    const start: Date = this.resetTime(_.cloneDeep(startDate));
    const end: Date = this.resetTime(_.cloneDeep(endDate));
    const dateArray = new Array();
    let currentDate = start;
    while (currentDate <= end) {
      dateArray.push(new Date(currentDate));
      currentDate = currentDate.addDays(1);
    }
    return dateArray;
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  FormatAppointmentTime(startTime: any, endTime: any, isCapital = true): string {
    let StartApptTime: Date = this.getDate(startTime);
    let EndApptTime: Date = this.getDate(endTime);
    return this.localization.LocalizeTime(StartApptTime, isCapital) + " - " + this.localization.LocalizeTime(EndApptTime, isCapital);

  }

  /**
   * Function to reset time part of date to 00:00:000
   * @param {Date} date
   * @returns {Date}
   * @memberof Utilities
   */
  resetTime(date: Date): Date {
    return this.localization.getDate(date.setHours(0, 0, 0, 0));
  }

  public appendFormat(value: string | number, format: string): string {
    if (!value) {//for null exception
      return "";
    }
    let userInput = value.toString();
    let returnVal: string = "";
    let indexOfUserValue: number = 0;
    if (format == "") {
      return value as string;
    }
    for (let i = 0; i < format.length; i++) {
      const char = format[i];
      let charCode = char.toString().charCodeAt(0);
      const IsNumber: boolean = ((charCode >= 48) && (charCode <= 57));
      if (!IsNumber) {
        returnVal = returnVal + format[i];
      } else {
        if (userInput[indexOfUserValue]) {
          returnVal = returnVal + userInput[indexOfUserValue];
          indexOfUserValue++;
        } else {
          break;
        }
      }
    }
    return returnVal;
  }

  public phoneNumberFormatWithExtension(value: string) {
    let hasValueColon = !value ? null : (value.indexOf(':') != -1) ? this.phonenumberWithExtn(value) : this.phonenumberWithOutExtn(value);
    return value ? hasValueColon : '';
  }
  public phonenumberWithExtn(value) {
    return this.concateCCodePhone(value.split(':')[1]) + " ext:" + value.split(':')[0].toString();
  }
  public phonenumberWithOutExtn(value) {
    return this.concateCCodePhone(value);
  }
  public concateCCodePhone(value) {
    let ccode, pno;
    if (value.indexOf('|') == -1) {
      ccode = '';
      pno = value;
    } else {
      let splitnumbers = value.split('|');
      ccode = splitnumbers[0] + ' - ';
      pno = splitnumbers[1];
    }
    return ccode + this.appendFormat(pno, this.localization.captions.common.PhoneFormat).toString()
  }

  public formatPhoneNumber(value: string): string {

    value = value ? this.concateCCodePhone(value) : "";
    return <string>value;
  }

  public removePhoneFormat(value: string) {
    value = value ? value : '';
    value = value.toString();
    return value.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
  }


  public sortObj<T>(list: T[], key: keyof T): T[] {
    function compare(a, b) {
      a = a[key];
      b = b[key];
      var type = (typeof (a) === 'string' ||
        typeof (b) === 'string') ? 'string' : 'number';
      var result;
      if (type === 'string') result = a.localeCompare(b);
      else result = a - b;
      return result;
    }
    return list.sort(compare);
  }

  getFullName(firstName: string, lastName: string) {
    return this.formatGuestName(firstName, lastName);
  }

  getNumberString(input: string): string {
    '67:3442|(09)(3443)(234234-23423)'
    return input.replace(/[^0-9]/g, '');
  }

  buildPhoneNumberToolTip(phoneType: number, phoneNumber: string): string | number {
    return this.phoneNumberFormatWithExtension(phoneNumber);
  }

  buildPhoneNumber(phoneType: number, extension: string, phoneNumber: string, countrycode?: string | number): string {
    phoneNumber = this.localization.validateString(phoneNumber) ? phoneNumber : '';
    if (phoneNumber == "") {
      return "";
    }
    else {
      if (countrycode) {
        countrycode = countrycode.toString();
        return (phoneType == ContactType.office) && extension ? extension + ":" + this.getNumberString(countrycode) + "|" + this.getNumberString(phoneNumber) : this.getNumberString(countrycode) + "|" + this.getNumberString(phoneNumber);
      } else {
        return (phoneType == ContactType.office) && extension ? extension + ":" + this.getNumberString(phoneNumber) : this.getNumberString(phoneNumber);
      }
    }
  }

  getPhoneNumberFromValue(phoneType: number, phoneNumber: string, fromScreen?): string {
    const captions = (fromScreen && fromScreen === 'PI' ? this.localization.propertyCaptions : this.localization.captions);
    if (phoneNumber) {
      let result: string = '';
      if (phoneNumber.indexOf('|') == -1) {
        result = ((phoneType === ContactType.office && phoneNumber.split(':')[1]) ? this.appendFormat(phoneNumber.split(':')[1], captions.common.PhoneFormat)
          : this.appendFormat(phoneNumber, captions.common.PhoneFormat));
      } else {
        result = ((phoneType === ContactType.office && phoneNumber.split(':')[1]) ? this.appendFormat(phoneNumber.split(':')[1].split('|')[1], captions.common.PhoneFormat)
          : this.appendFormat(phoneNumber.split('|')[1], captions.common.PhoneFormat));
      }
      return result;
    }
    return ''
  }

  getCountryCodeFromValue(phoneType: number, phoneNumber: string): string {
    let result: string = '';    
    if (phoneNumber) {
      let defaultCountryCode: string = '';
      let defaultsApiData: setting.DefaultsSettingConfig = JSON.parse(sessionStorage.getItem('defaultSettings'));      
      defaultCountryCode = defaultsApiData.defaultCountryCode ? defaultsApiData.defaultCountryCode : '';
      if (phoneNumber.indexOf('|') == -1) {
        result = "";
      } else {
        result = ((phoneType === ContactType.office && phoneNumber.split(':')[1]) ? this.appendFormat(phoneNumber.split(':')[1].split('|')[0], '')
          : this.appendFormat(phoneNumber.split('|')[0], ''));
      }  
      return result != "" ? result : defaultCountryCode;    
    }
    return "";
  }


  ValidateMemberRound(courseName: string, RoundsTodeduct: number, balanceLists: Tournament.UI.GolfPoints[]): boolean {
    let isValid = false;
    var bucketInfo = balanceLists && balanceLists.find(x => (x.bucketName && x.bucketName.toLowerCase()) == (courseName && courseName.toLowerCase()));
    var availableRounds = '';

    availableRounds = this.localization.captions.common.EligibleRoundsForMember;
    availableRounds = availableRounds + ' ' + this.getAvailableRounds({ golfPoints: balanceLists });
    if (bucketInfo && bucketInfo.eligibleRounds < RoundsTodeduct) {
      this.showAlertWithCustomWidth(this.localization.captions.common.MemberExceededAllowedRounds + availableRounds, AlertType.Info, ButtonType.Ok, 500);
    }
    else if (bucketInfo == undefined) {
      this.showAlertWithCustomWidth(this.localization.captions.common.CourseNotAvailableForMember, AlertType.Info, ButtonType.Ok, 500);
    }
    else {
      isValid = true;
    }
    return isValid;
  }

  getAvailableRounds(memberInfo) {
    let availableRounds = 0;
    if (memberInfo.golfPoints) {
      const uniqueBucketCode: GolfPoints[] = [];
      memberInfo.golfPoints.forEach((value, key) => {
        if (uniqueBucketCode.find((x) => x.bucketCode == value.bucketCode) == undefined) {
          uniqueBucketCode.push(value);
          availableRounds += value.eligibleRounds;
        }
      });
    }
    return availableRounds;
  }
  getCreditBookBalance(memberInfo) {
    let creditBookBalance = 0;
    if (memberInfo.balanceList) {
      let creditbook: BalanceList = memberInfo.balanceList.find(x => x.BucketName.replace(/\s/g, '').toLowerCase() == 'creditbook');
      if (creditbook) {
        creditBookBalance = creditbook.AccrualCash
      }
    }
    return creditBookBalance;
  }
  ValidateActiveMember(member: Tournament.UI.ContactInformation) {
    if (member.cardStatus.toUpperCase() != "ACTIVE") {
      this.showAlert(this.localization.captions.common.InActiveMember, AlertType.Info, ButtonType.Ok);
      return false;
    }
    return true;
  }

  getExtensionFromValue(phoneType: number, phoneNumber: string): string {
    let phoneExtension = '';
    if (phoneType && phoneNumber) {
      let hasPhoneColon = phoneNumber.split(':')[1] ? phoneNumber.split(':')[0] : "";
      phoneExtension = phoneType === ContactType.office ? hasPhoneColon : "";
    }
    return phoneExtension;
  }

  getUserInfoByKey(name: string) {
    const nameEQ = name + '=';

    const userInfo = sessionStorage.getItem('_userInfo')
    if (userInfo != null) {
      const ca = userInfo.split(';');

      for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  findUserAgent() {
    var objAgent = navigator.userAgent;
    var objbrowserName = navigator.appName;

    if ((objAgent.indexOf("Edge")) != -1) {
      objbrowserName = "Microsoft Edge";
    } // In Edge
    else if ((objAgent.indexOf("Chrome")) != -1) {
      objbrowserName = "Chrome";
    } // In Microsoft internet explorer
    else if ((objAgent.indexOf("Trident/7.0")) != -1) {
      objbrowserName = "IE11 ";
    } // In IE11
    else if ((objAgent.indexOf("Trident/6.0")) != -1) {
      objbrowserName = "Internet Explorer 10";
    } // In Internet Explorer 10
    else if ((objAgent.indexOf("Trident/5.0")) != -1) {
      objbrowserName = "Internet Explorer 9";
    } // In Internet Explorer 9
    else if ((objAgent.indexOf("Trident/4.0")) != -1) {
      objbrowserName = "Internet Explorer 8";
    } // In  Internet Explorer 8

    else if ((objAgent.indexOf("Firefox")) != -1) {
      objbrowserName = "Firefox";
    } // In Safari
    else if ((objAgent.indexOf("Safari")) != -1) {
      objbrowserName = "Safari";
    }

    return objbrowserName;
  }


  getLegends(): LegendInfo[] {
    return [
      { id: 4, icon: 'icon-Member-new', name: this.localization.captions.teetime.member },
      // #46309 - Hide unused fields            
      { id: 6, icon: 'icon-clock', name: this.localization.captions.teetime.time },
      { id: 7, icon: 'icon-course-flag', name: this.localization.captions.teetime.course },
      { id: 8, icon: 'icon-rate', name: this.localization.captions.teetime.Paid },
      { id: 9, icon: 'icon-user', name: this.localization.captions.teetime.players },
      // #46309 - Hide unused fields            
      { id: 11, icon: 'icon-male-staff', name: this.localization.captions.settings.male },
      { id: 12, icon: 'icon-female-staff', name: this.localization.captions.settings.female },
      { id: 13, icon: 'icon-kids-couple', name: this.localization.captions.settings.junior },
      { id: 14, icon: 'icon-tournament-player', name: this.localization.captions.settings.tournamentPlayer },
      { id: 15, icon: 'icon-sphere', name: this.localization.captions.teetime.onlineBooking },
      { id: 16, icon: 'icon-dashboard-flag', name: this.localization.captions.teetime.flagDay },
      { id: 17, icon: 'icon-Bulk-Tee-Time', name: this.localization.captions.teetime.bulkTeeTime },
      { id: 18, icon: 'icon-circle2', name: this.localization.captions.teetime.TenthHoleStart, color: '#eb0707' },
      { id: 20, icon: 'icon-Viewed-By-Starter-Fill', name: this.localization.captions.settings.viewedByStarter },
      { id: 21, icon: 'icon-Group-Player', name: this.localization.captions.teetime.GroupTeeTime },
      { id: 22, icon: 'icon-adddeposit1', name: this.localization.captions.bookAppointment.Deposit },
      { id: 23, icon: 'icon-Mark-Paid', name: this.localization.captions.teetime.markAsPaid },
      { id: 24, icon: 'icon-no-show', name: this.localization.captions.teetime.noshow },
      { id: 25, icon: 'icon-checkIn', name: this.localization.captions.teetime.checkInActionTitle },
      { id: 26, icon: 'icon-checkOut', name: this.localization.captions.teetime.checkOutActionTitle },
      { id: 27, icon: 'icon-caddy_new', name: this.localization.captions.teetime.caddyType },
      { id: 28, icon: 'icon-golf-cart', name: this.localization.captions.teetime.cart },
      { id: 29, icon: 'icon-View-E-Form', name: this.localization.captions.teetime.eForms },
      { id: 31, icon: 'icon-ratetype', name: this.localization.captions.teetime.playerTypeRateType },
      { id: 32, icon: 'icon-vip1', name: this.localization.captions.teetime.vip },
      { id: 33, icon: 'icon-Player-worth_enabled', name: this.localization.captions.teetime.patronId },
      { id: 34, icon: 'icon-Hotel_Guest', name: this.localization.captions.teetime.hotelGuest },
      { id: 34, icon: 'icon-Audit-Trail', name: this.localization.captions.teetime.auditTrail },
      { id: 35, icon: 'icon-Player_Comments', name: this.localization.captions.teetime.PlayerComments },
      { id: 36, icon: 'icon-GolfNow', name: this.localization.captions.teetime.golfNowBooking },
      { id: 37, icon: 'icon-Days-in-Advance', name: this.localization.captions.teetime.lbl_daysInAdvance },
      { id: 38, icon: 'icon-add_retail_item_24px', name: this.localization.captions.teetime.lbl_retailItem_added },
      { id: 39, icon: 'icon-Rentals', name: this.localization.captions.teetime.lbl_rentalItem_added},
      { id: 40, icon: 'icon-edit-new', name: this.localization.captions.common.Edit},
      { id: 41, icon: 'icon-gratuity', name: this.localization.captions.teetime.Pay},
    ];
  }


  getPlayerPaymentStatus(playerstatus: PlayerPaymentstatus) {
    if ((PlayerPaymentstatus.CheckOut & playerstatus) != 0)
      return this.localization.captions.PlayerPaymentStatus.CheckOut;
    else if ((PlayerPaymentstatus.CheckIn & playerstatus) != 0)
      return this.localization.captions.PlayerPaymentStatus.CheckIn;
    else if ((PlayerPaymentstatus.paid & playerstatus) != 0)
      return this.localization.captions.PlayerPaymentStatus.Paid;
    else if ((PlayerPaymentstatus.cancelled & playerstatus) != 0)
      return this.localization.captions.PlayerPaymentStatus.Cancelled;
    else if ((PlayerPaymentstatus.noShow & playerstatus) != 0)
      return this.localization.captions.PlayerPaymentStatus.NoShow;
    else if ((PlayerPaymentstatus.refund & playerstatus) != 0)
      return this.localization.captions.PlayerPaymentStatus.Refunded;
    else if ((PlayerPaymentstatus.booked & playerstatus) != 0)
      return this.localization.captions.PlayerPaymentStatus.Booked;   
  }
  calcAge(dateString) {
    return dateString != null ? ~~((Date.now() - +this.localization.getDate(dateString)) / (31557600000)) : 0;
  }

  /**
   *
   * @param id
   * @param positon
   */
  scrolltoId(tableid, id, positon?) {
    if (tableid && id && document.getElementById(id)) {
      let scrollposition = document.getElementById(id).offsetTop;
      document.getElementById(tableid).style.scrollBehavior = "smooth";
      document.getElementById(tableid).scrollTop = scrollposition - 61;
    }
  }
  /**
       * scrollTopByPosition method to scroll top based on position value
       * @param tableid 
       * @param position 
       */
  scrollTopByPosition(tableid, position) {
    if (tableid && position) {
      document.getElementById(tableid).style.scrollBehavior = "smooth";
      document.getElementById(tableid).scrollTop = position;
    }
  }
  scrollTo(tableid, id, positon?) {
    var pEl = document.getElementById(tableid);
    if (tableid && id && pEl !== null) {
      var el = pEl.getElementsByTagName('tbody')[0].getElementsByTagName('tr');
      for (var i = 0; i < el.length; i++) {
        var res = el[i].getAttribute('id');
        if (res === id) {
          let scrollposition = el[i].offsetTop;
          document.getElementById(tableid).style.scrollBehavior = "smooth";
          document.getElementById(tableid).scrollTop = scrollposition - 61;
        }
      }
    }
  }

  public getFormattedFullName(searchValue: string): string
  {
    const config: API.ApplicationFormat = JSON.parse(sessionStorage.getItem(PropertyConfigurations.ApplicationFormat));
    searchValue = searchValue.trim();
    let name = searchValue.split(/\s+/);
    let pattern = "";
    if(name.length>1)
    {
      pattern = name[config.firstNameIndex].trim()+" "+name[config.lastNameIndex].trim();
      return pattern
    }
    return searchValue;
  }

  public formatGuestName(firstName: string, lastName: string, isMultiCourseEnabled: boolean = false): string {
    firstName = firstName ? firstName : '';
    lastName = lastName ? lastName : '';
    if (isMultiCourseEnabled)
    {
      let defaultSettings: DefaultSetting = JSON.parse(sessionStorage.getItem(CacheConstants.DefaultSettings));
      return this.getMultiCourseViewPlayerNameFormat(firstName, lastName, defaultSettings && defaultSettings.defaultMultiCourseViewPlayerNameFormat ? defaultSettings.defaultMultiCourseViewPlayerNameFormat : MultiCourseViewPlayerNameFormat.FullNameFirstLetter)
    }
    const config: API.ApplicationFormat = JSON.parse(sessionStorage.getItem(PropertyConfigurations.ApplicationFormat));
    const nameFormatOptns: NameFormatDropDown = this.getNameFormatOptions().find(o => o.id == config.id);
    return nameFormatOptns.nameFormatter(firstName, lastName);
  }

  getNameFormatOptions(): NameFormatDropDown[] {
    const firstName = this.localization.captions.settings.FirstName;
    const lastName = this.localization.captions.settings.LastName;

    return [
      {
        id: 1, value: `${firstName} ${lastName}`,
        configuration: {
          firstNameIndex: 0,
          lastNameIndex: 1,
          nameSeperator: ' '
        },
        nameFormatter: (fName, lName) => `${fName} ${lName}`
      },
      {
        id: 2, value: `${lastName} ${firstName}`,
        configuration: {
          firstNameIndex: 1,
          lastNameIndex: 0,
          nameSeperator: ' '
        },
        nameFormatter: (fName, lName) => `${lName} ${fName}`
      },
      {
        id: 3, value: `${firstName} , ${lastName}`,
        configuration: {
          firstNameIndex: 0,
          lastNameIndex: 1,
          nameSeperator: ','
        },
        nameFormatter: (fName, lName) => `${fName} , ${lName}`
      },
      {
        id: 4, value: `${lastName} , ${firstName}`,
        configuration: {
          firstNameIndex: 1,
          lastNameIndex: 0,
          nameSeperator: ','
        },
        nameFormatter: (fName, lName) => `${lName} , ${fName}`
      }
    ]
  }
  getMultiCourseViewPlayerNameFormat(firstName: string, lastName: string, multiCourseNameFormat: MultiCourseViewPlayerNameFormat )
  {
    const config: API.ApplicationFormat = JSON.parse(sessionStorage.getItem(PropertyConfigurations.ApplicationFormat));
    const nameFormatOptns: NameFormatDropDown = this.getNameFormatOptions().find(o => o.id == config.id);
    switch (multiCourseNameFormat)
    {
      case MultiCourseViewPlayerNameFormat.FullName:
        return nameFormatOptns.nameFormatter(firstName, lastName);
      case MultiCourseViewPlayerNameFormat.FullNameFirstLetter:
        return this.getFormattedName(config,firstName.charAt(0),lastName.charAt(0),true);
      case MultiCourseViewPlayerNameFormat.LastName:
        return lastName && lastName != '' ? lastName : firstName;
      case MultiCourseViewPlayerNameFormat.FirstName:
        return firstName && firstName != '' ? firstName : lastName;
      default:
        return this.getFormattedName(config, firstName.charAt(0), lastName.charAt(0), true);
    }
  }
  getFormattedName(format: API.ApplicationFormat, fName: string, lName: string, IsFirstLetterOnly: boolean = false): string {
    if (IsFirstLetterOnly)
    {
      switch (format.id) {
        case 1:
          return `${fName}${lName}`;
        case 2:
          return `${lName}${fName}`;
        case 3:
          return `${fName},${lName}`;
        case 4:
          return `${lName},${fName}`;
        default:
          return `${fName}${lName}`;
      }
    }
    switch (format.id)
    {
      case 1:
        return `${fName} ${lName}`;
      case 2:
        return `${lName} ${fName}`;
      case 3:
        return `${fName} , ${lName}`;
      case 4:
        return `${lName} , ${fName}`;
      default:
        return `${fName} ${lName}`;
    }
  }
  public getPMSSessionInfo(): GolfPMSSessionInfo | undefined {
    const session = sessionStorage.getItem(GolfSessionKey.PmsRequestInfo);
    let pmsSessionInfo: GolfPMSSessionInfo = undefined;
    if (session) {
      pmsSessionInfo = JSON.parse(session) as GolfPMSSessionInfo;
      pmsSessionInfo.stayFromDate = (pmsSessionInfo.stayFromDate) ? this.getDate(pmsSessionInfo.stayFromDate) : undefined;
      pmsSessionInfo.stayToDate = (pmsSessionInfo.stayToDate) ? this.getDate(pmsSessionInfo.stayToDate) : undefined;
    }
    return pmsSessionInfo;
  }

  public clearPMSSessionInfo(): void {
    sessionStorage.removeItem(GolfSessionKey.PmsRequestInfo);
  }

  GetEmail(email: ContactDetails[]): any {
    if (email.some(x => x.type == MailTypes.personal || x.type == MailTypes.office)) {
      let primaryEmail = email.find(x => x.isPrimary && (x.type == MailTypes.personal || x.type == MailTypes.office));
      let personalEmail = email.find(x => x.type == MailTypes.personal);
      let officeEmail = email.find(x => x.type == MailTypes.office);
      if (primaryEmail) {
        return primaryEmail;
      } else if (personalEmail) {
        return personalEmail;
      } else if (officeEmail) {
        return officeEmail;
      } else {
        return email[0];
      }
    }
    else {
      return null;
    }
  }

  GetPhoneNumber(phoneNumber: ContactDetails[]): any {
    if (phoneNumber.some(x => x.type == PhoneTypes.mobile || x.type == PhoneTypes.home || x.type == PhoneTypes.office)) {
      let primaryPhone = phoneNumber.find(x => x.isPrimary && (x.type == PhoneTypes.mobile || x.type == PhoneTypes.home));
      let cellphone = phoneNumber.find(x => x.type == PhoneTypes.mobile);
      let homephone = phoneNumber.find(x => x.type == PhoneTypes.home);
      if (primaryPhone) {
        return primaryPhone;
      } else if (cellphone) {
        return cellphone;
      } else if (homephone) {
        return homephone;
      } else {
        return phoneNumber[0];
      }
    }
    else {
      return null;
    }

  }

  GetInfoStrip(textMessage: string) {
    const infoWrapper = this.rendrer.createElement('div');
    this.rendrer.addClass(infoWrapper, 'info-wrapper');
    const infoIcon = this.rendrer.createElement('i');
    this.rendrer.addClass(infoIcon, 'icon-information');
    this.rendrer.appendChild(infoWrapper, infoIcon);
    const infoSpan = this.rendrer.createElement('span');
    this.rendrer.addClass(infoSpan, 'info-text');
    const infotext = this.rendrer.createText(' ' + this.localization.captions.common.BreakPointAccessDeniedMsg + ' ' + textMessage);
    this.rendrer.appendChild(infoSpan, infotext);
    this.rendrer.appendChild(infoWrapper, infoSpan);
    return infoWrapper;
  }
  IsPropertyDateGreaterThenDate(date: Date) {
    const propertyData = new Date(this.PropertyInfo.CurrentDate);
    return (propertyData >= new Date(date.toDateString()));
  }

  IsAllocationCodePermissionEnabled(): boolean {
    const setting: setting.GolfSetting = JSON.parse(sessionStorage.getItem(SettingScreen.GolfSetting));
    return setting && setting.enableAllocationCodePermissionByRole;
  }

  public showAllocationCodePermissionDeniedPopup(name: string, optionalText?: string, callback?:(result: AlertAction) => void) {
    let message = this.localization.replacePlaceholders(this.localization.captions.common.AllocationCodeAccessDeniedMsg, ['name'], [name]);
    message = `${message}<br><br>`;
    if (optionalText) {
      message = `${message} ${optionalText}`
    } else {
      message = `${message} ${this.localization.captions.common.BookingNotAllowed}`
    }
    this.showAlert(message, AlertType.AccessDenied, ButtonType.Ok,(x)=>{
      callback(x);
    });
  }

  public getPlayerTypeNameByIds(playerTypeId: number[], playertypes: PlayerType[]): string {
    let playerTypeName = '';
    if (playertypes && playertypes.length > 0) {
      playertypes = Array.from(new Set(playertypes));
      for (let pl of playerTypeId) {
        let playerType = playertypes.find(r => r.id == pl);
        if (playerType) {
          playerTypeName = playerTypeName ? `${playerTypeName}, ${playerType.type}` : playerType.type;
        }
      }
    }
    return playerTypeName;
  }

  async geCountriesJSON() {
    let countryDetails: any = await this.http.get(`assets/i18n/Countries/en-US.Countries.json`).toPromise();
    this.countryDetails = countryDetails.Countries;
    return this.countryDetails;
  }


  FilterCountryValueFromData(country) {
    let countryValue = '';
    if (country && this.FilterCountryByGlobalSearch(country, this.countryDetails).length) {
      countryValue = country;
    } else {
      countryValue = '';
    }
    return countryValue;
  }

  generateUUIDUsingMathRandom() {
    var d = new Date().getTime();//Timestamp
    var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
  showToastMessage(
    snackMessage: string,
    snackType: SnackBarType = SnackBarType.Info,
    timeout: number = 5000,
    horizontalPos: MatSnackBarHorizontalPosition = "right",
    verticalPos: MatSnackBarVerticalPosition = "top"
  ) {
    return this.snackBar.openFromComponent(AgSnackBarComponent, {
      duration: timeout,
      horizontalPosition: horizontalPos,
      verticalPosition: verticalPos,
      data: {
        message: snackMessage,
        type: snackType,
      },
      panelClass: ["ag-snackbar-container"],
    });
  }
  getDistinctGUIDs(guidArray) {
    const nonEmptyGUIDs = guidArray.filter(guid => guid !== "00000000-0000-0000-0000-000000000000");
    // Get distinct GUIDs
    const distinctGUIDs = [...new Set(nonEmptyGUIDs)];
    return distinctGUIDs;
  }

  isHistoricDate(currentPropertyDate, date) {
    var propertyCurrentDateTime = this.GetDateWithoutTime(currentPropertyDate),
      teeRowDataDateTime = this.GetDateWithoutTime(this.localization.getDate(date));

    return propertyCurrentDateTime.getTime() <= teeRowDataDateTime.getTime() ? false : true;
  }
}
