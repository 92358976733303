import { Injectable } from "@angular/core";
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { DefaultsSettingConfig } from "src/app/tee-time-actions/teetime/player-details/player-info.model";
import { API as settingsAPI } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { PlayerContactService } from "src/app/lessons/lessons-modal/player-contact/player-contact.service";

@Injectable()

export class PlayerContactSharedBusiness {

    public readonly captions;
    public isViewOnly: boolean = false;
    constructor(private _PlayerContactService: PlayerContactService, private _localization: GolfLocalization) {
        this.captions = _localization.captions.lessonBooking;
    }
    public getDefaultSettings(): DefaultsSettingConfig {
        let defaultsApiData: settingsAPI.DefaultsSettingConfig = JSON.parse(sessionStorage.getItem('defaultSettings'));
        return this.mapDefaultData(defaultsApiData);
    }
    private mapDefaultData(configValue: settingsAPI.DefaultsSettingConfig): DefaultsSettingConfig {
        return {
            blockingCode: configValue.blockingCode,
            memberStatus: configValue.memberStatus,
            memberPlayerType: configValue.memberPlayerType,
            memberRateType: configValue.memberRateType,
            nonMemberPlayerType: configValue.nonMemberPlayerType,
            nonMemberRateType: configValue.nonMemberRateType,
            resortPlayerType: configValue.resortPlayerType,
            resortRateType: configValue.resortRateType,
            memberGuestPlayerType: configValue.memberGuestPlayerType,
            memberGuestRateType: configValue.memberGuestRateType,
            memberGuestsToAdd: configValue.memberGuestsToAdd,
            memberGuestPlayerFirstName: configValue.memberGuestPlayerFirstName,
            memberGuestPlayerLastName: configValue.memberGuestPlayerLastName,
            defaultPlayerCategory: configValue.defaultPlayerCategory,
            defaultHotelReservationCategory: configValue.defaultHotelReservationCategory,
            defaultPhoneTypeOption: configValue.defaultPhoneTypeOption,
            defaultEmailTypeOption: configValue.defaultEmailTypeOption,
            defaultCountryCode: configValue.defaultCountryCode
        }
    }
}