export interface DefaultSetting {
    defaultBlockingcode: number;
    defaultMemberstatus: number;
    defaultMemberPlayerType: number;
    defaultMemberratetype: number;
    defaultnonMemberplayertype: number;
    defaultnonMemberrateType: number;
    DefaultResort: number;
    DefaultResortratetype: number;
    memberGuestPlayerType: number;
    memberGuestRateType: number;
    memberGuestPlayerFirstName: string;
    memberGuestPlayerLastName: string;
    memberGuestsToAdd: number;
    enableauto: boolean;
    autotimehold: any;
    checkinginterval: any;
    showConfirmationDialog?: boolean;
    showAvailableOnly?: boolean;
    autoSelectPlayers?: boolean;
    requiredReasonTeeTimeCancellation?: boolean;
    enableExtentedSearchByDefault?: boolean;
    enableNonZeroRateForMarkAsPaid?: boolean;
    defaultNameFormat?: number;
    defaultOutletType?: number;
    defaultAllocationCodeSwitchTo: number;
    defaultPlayerCategory: number;
    defaultMovePlayerOption: number;
    defaultHotelReservationCategory: number;
    defaultPhoneTypeOption: number;
    defaultEmailTypeOption:number;
    defaultCountryCode:string;
    markAsPaidPaymentMethod: number;
    zeroMemberChargePaymentMethod:number;
    markAsPaidPaymentMethodReceiptComment: string;
    zeroMemberChargePaymentMethodReceiptComment: string;
    disableHolesWhenMemberEdit?: boolean;
    bulkPlayersPerGroup:number;    
    rainCheckExpiry: number;
    enableHideCrossOverTeeSheet : boolean;
    enableHideCrossOverGraphicalView : boolean;
    defaultExistingPlayerCategory: number;
    defaultMultiCourseViewPlayerNameFormat: MultiCourseViewPlayerNameFormat
}

export enum MultiCourseViewPlayerNameFormat{
    FullNameFirstLetter,
    FullName,
    FirstName,
    LastName
}
export interface ApplicationNameFormatConfig {
    firstNameIndex: number;
    lastNameIndex: number;
    nameSeperator: string;
}

export interface NameFormatDropDown {
    id: number;
    value: string;
    configuration: ApplicationNameFormatConfig;
    nameFormatter: (fName: string, lName: string) => string;
}

export interface DefaultsSettingConfig {
    blockingCode: number;
    memberStatus: number;
    memberPlayerType: number;
    memberRateType: number;
    nonMemberPlayerType: number;
    nonMemberRateType: number;
    resortPlayerType: number;
    resortRateType: number;
    autoTeeTimeHoldConfig: AutoTeeTimeHoldConfig;
    showConfirmationDialog?: boolean;
    showAvailableOnly?: boolean;
    autoSelectPlayers?: boolean;
    requiredReasonTeeTimeCancellation?: boolean;
    enableExtentedSearchByDefault?: boolean;
    enableNonZeroRateForMarkAsPaid?: boolean;
    memberGuestPlayerType: number;
    memberGuestRateType: number;
    memberGuestPlayerFirstName: string;
    memberGuestPlayerLastName: string;
    memberGuestsToAdd: number;
    defaultAllocationCodeSwitchTo: number;
    defaultPlayerCategory: number;
    defaultMovePlayerOption: number;
    defaultHotelReservationCategory: number;
    defaultPhoneTypeOption: number;
    defaultEmailTypeOption:number;
    defaultCountryCode:string;
    markAsPaidPaymentMethod: number;
    zeroMemberChargePaymentMethod:number;
    markAsPaidPaymentMethodReceiptComment: string;
    zeroMemberChargePaymentMethodReceiptComment: string;
    disableHolesWhenMemberEdit?: boolean;
    bulkPlayersPerGroup:number;
    rainCheckExpiry: number,
    enableHideCrossOverTeeSheet : boolean;
    enableHideCrossOverGraphicalView : boolean;
    defaultExistingPlayerCategory: number;
    defaultMultiCourseViewPlayerNameFormat: MultiCourseViewPlayerNameFormat
}


export interface AutoTeeTimeHoldConfig {
    isEnabled: boolean;
    holdReleaseMinutes: number;
    holdCheckingIntervalInSeconds: number;
}

export interface DefaultsCaptions {
    LastName: any;
    FirstName: any;
    defaultBlockingcode: string;
    defaultMemberstatus: string;
    defaultMemberPlayerType: string;
    defaultMemberratetype: string;
    defaultnonMemberplayertype: string;
    defaultnonMemberrateType: string;
    DefaultResort: string;
    DefaultResortratetype: string;
    checked: string;
    unchecked: string;
    holdconfig: string;
    enableAuto: string;
    AutoTeeTimeHoldReleaseMinutes: string;
    AutoTeeTimeHoldCheckingIntervalSeconds: string;
    defaultAllocationCodeSwitchTo: string;
    existingPlayer: string;
    hotelReservation: string;
    member: string;
    player: string;
    defaultPlayerCategory: string;
    defaultHotelReservationCategory: string;
    defaultMovePlayerOption: string;
    contactDetail: string;
    confiramtionNumber: string;
    roomNumber: string;
    linkCode: string;
    guestName: string;
    defaultPhoneTypeOption: string;
    phoneTypeOffice: string;
    phoneTypeCell: string;
    phoneTypeHome: string;
    phoneTypeBusiness: string;
    phoneTypeWork: string;
    defaultEmailTypeOption:string;
    emailTypeOffice:string;
    emailTypePersonal:string;
    emailTypeHome:string;
    emailTypeBusiness:string;
    defaultCountryCode:string;
    bulkPlayersPerGroup:number;
    enableHideCrossOver :string;
    fullName: string;
    emailAddress: string;
    phoneNumbertext: string;
    defaultExistingPlayerCategory: string;
}




