import { Injectable } from '@angular/core';
import { ScheduledTeeTimeUnPaidPlayer, Course } from '../../../retail/shared/shared.modal';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';
import { GolfManagementCommunication } from '../../communication/services/golfmanagement.service';
import { DefaultUserConfiguration } from 'src/app/common/shared/shared.modal';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { TeeTimeAction } from '../../models/teesheet.api.models';

@Injectable({ providedIn: 'root' })

export class UnPaidPlayersService {
  private defaultUserConfig: DefaultUserConfiguration;

  constructor(private _golfScheduleService: GolfScheduleCommunication, private _golfManagementCommunication: GolfManagementCommunication, private _retailService: RetailSharedVariableService) { }

  public async getScheduledTeeTimePlayers(courseId: number, dateTime: string, playerStatus: number, isFromMarkAsPaid: boolean = false, isFromDeposit: boolean = false, isFromPaymentModal: boolean = false): Promise<ScheduledTeeTimeUnPaidPlayer[]> {    
    this.defaultUserConfig = JSON.parse(sessionStorage.getItem('userDefaults'));    
    if(this.defaultUserConfig.defaultOutletId == 0 && this._retailService.SelectedOutletId == 0 && Number(sessionStorage.getItem('defaultOutlet')) != 0)
    {
      this._retailService.SelectedOutletId = Number(sessionStorage.getItem('defaultOutlet'));
    }
    let userDefaultOutletId = this.defaultUserConfig && this.defaultUserConfig.defaultOutletId > 0 ? this.defaultUserConfig.defaultOutletId : this._retailService.SelectedOutletId;
    try {
      return this._golfScheduleService.getPromise({ route: GolfApiRoute.GetScheduledTeeTimePlayersByPaymentStatus, uriParams: { courseId: courseId, scheduledDate: dateTime, status: playerStatus, userDefaultOutletId: userDefaultOutletId, isFromMarkAsPaid: isFromMarkAsPaid , isFromDeposit: isFromDeposit, isFromPaymentModal: isFromPaymentModal } });
    } catch (error) {
      console.error(error);
    }
  }
  public async getOutLetId()
  {
    this.defaultUserConfig = JSON.parse(sessionStorage.getItem('userDefaults'));    
    if(this.defaultUserConfig.defaultOutletId == 0 && this._retailService.SelectedOutletId == 0 && Number(sessionStorage.getItem('defaultOutlet')) != 0)
    {
      this._retailService.SelectedOutletId = Number(sessionStorage.getItem('defaultOutlet'));
    }
    return this.defaultUserConfig && this.defaultUserConfig.defaultOutletId > 0 ? this.defaultUserConfig.defaultOutletId : this._retailService.SelectedOutletId; 
  }

  public async getScheduledTeeTimePlayer(playerId:number, courseId: number, scheduledDate: string, playerStatus: number): Promise<ScheduledTeeTimeUnPaidPlayer[]> {    
    this.defaultUserConfig = JSON.parse(sessionStorage.getItem('userDefaults'));    
    if(this.defaultUserConfig.defaultOutletId == 0 && this._retailService.SelectedOutletId == 0 && Number(sessionStorage.getItem('defaultOutlet')) != 0)
    {
      this._retailService.SelectedOutletId = Number(sessionStorage.getItem('defaultOutlet'));
    }
    let userDefaultOutletId = this.defaultUserConfig && this.defaultUserConfig.defaultOutletId > 0 ? this.defaultUserConfig.defaultOutletId : this._retailService.SelectedOutletId;
    try {
      return this._golfScheduleService.getPromise(
        { 
          route: GolfApiRoute.GetScheduledTeeTimePlayerByPaymentStatus, 
          uriParams: { playerId:playerId, courseId: courseId, scheduledDate: scheduledDate, playerStatus: playerStatus, userDefaultOutletId: userDefaultOutletId} 
        });
    } catch (error) {
      console.error(error);
    }
  }
  public async getScheduledTeeTimePlayersPaidUnpaid(courseId: number, dateTime: string, teeTimeAction: TeeTimeAction = TeeTimeAction.none): Promise<ScheduledTeeTimeUnPaidPlayer[]> {
    this.defaultUserConfig = JSON.parse(sessionStorage.getItem('userDefaults'));
    let userDefaultOutletId = this.defaultUserConfig && this.defaultUserConfig.defaultOutletId > 0 ? this.defaultUserConfig.defaultOutletId : this._retailService.SelectedOutletId;
    return this._golfScheduleService.getPromise({ route: GolfApiRoute.GetScheduledTeeTimePlayersPaidAndUnPaid, uriParams: { courseId: courseId, scheduledDate: dateTime, userDefaultOutletId: userDefaultOutletId, teeTimeAction: teeTimeAction } });
  }
  public getCourses(filterUserAccessedCourse): Promise<Course[]> {

    let isactive = false;
    try {
      return this._golfManagementCommunication.getPromise({ route: GolfApiRoute.GetCoursesWithAccess, uriParams: { includeInactive: isactive, filterUserAccessedCourse: filterUserAccessedCourse } });
    } catch (error) {
      console.error(error);
    }
  }

  public getCourse(id: number): Promise<Course> {
    return this._golfManagementCommunication.getPromise<Course>(
      { route: GolfApiRoute.GetCourse, uriParams: { "id": id } }, false);

  }
  public async setMarkAsPaidPlayer(lstMarkAsPaid: any[]) {
    return this._golfScheduleService.postPromise<any[]>({
      route: GolfApiRoute.MarkAsPaidPlayer, body: lstMarkAsPaid
    }, true)
  }
}
