<aside class="filter-wrapper h-100">
  <ng-scrollbar [autoHide]="true" #asidefilterscroll>
    <div class="d-flex p-3">
      <span class="spa-filter-title textellipsis" [isEllipsis]="localization.captions.common.filters">{{localization.captions.common.filters}}</span>
      <a attr.automationId='Lbl_{{automationId}}_resetAll' href="javascript:void(0)" (click)="resetFilter()" class="golf__link spa-filter-link ml-auto" [ngClass]="(filteredDataLength > 0 || dateChanged || radioChanged) ? 'cursor text-color' : 'disable-reset' ">{{localization.captions.common.ResetAll}}</a>
    </div>
      


    <div class="dob date-filter m_datepicker m_b_datepicker ml-4 mb-3" *ngIf="dateSelection">
      <mat-form-field class="form-group w-75 date-picker-width" appearance="legacy" [floatLabel]="floatLabel">
        <mat-label>{{localization.captions.shop.Date}}</mat-label>
        <input attr.automationId='Txt_{{automationId}}_datePicker' #dateModelShop="ngModel" matInput dateRestricter [(ngModel)]="dateDefaultValue" [min]="minFromDate" [matDatepicker]="picker1" [placeholder]="placeholderFormat"
        (dateChange)="onDateChange($event, 'FromDate')" class="picker1" [max]="dateMaxValue">
        <mat-datepicker #picker1></mat-datepicker>
        <i  attr.automationId='Icn_{{automationId}}_datePicker' aria-hidden="true" matSuffix class="icon-Calender customCalendar" (click)="picker1.open()"></i>
        <mat-error *ngIf="dateModelShop.hasError('matDatepickerParse')">{{commonCaptions.dateFormat}}</mat-error>   
        <mat-error *ngIf="dateModelShop.hasError('matDatepickerMin')">{{commonCaptions.minimum}} {{localization.captions.shop.Date}}</mat-error>
        <mat-error *ngIf="dateModelShop.hasError('matDatepickerMax')">{{commonCaptions.maximum}} {{localization.captions.shop.Date}}</mat-error>
      </mat-form-field>

    </div>

    <!-- ---for logviewer--- -->
    <div class="dob date-filter m_datepicker m_b_datepicker ml-4 mb-3" *ngIf="todateSelection">
      <mat-form-field class="form-group w-75" appearance="legacy" [floatLabel]="floatLabel">
        <mat-label>{{localization.captions.shop.Date}}</mat-label>
        <input attr.automationId='Txt_{{automationId}}_logDatePicker' #dateToModel="ngModel" matInput dateRestricter [min]="mintoDate" [matDatepicker]="picker1" [placeholder]="placeholderFormat"
        (dateChange)="ontoDateChange($event, 'toDate')" class="picker1" [(ngModel)]="todateDefaultValue" [max]="todateMaxValue">
        <mat-datepicker #picker1></mat-datepicker>
        <i  attr.automationId='Icn_{{automationId}}_logDatePicker' aria-hidden="true" matSuffix class="icon-Calender customCalendar" (click)="picker1.open()"></i>
        <mat-error *ngIf="dateToModel.hasError('matDatepickerParse')">{{commonCaptions.dateFormat}}</mat-error>   
        <mat-error *ngIf="dateToModel.hasError('matDatepickerMin')">{{commonCaptions.minimum}} {{localization.captions.shop.Date}}</mat-error>
        <mat-error *ngIf="dateToModel.hasError('matDatepickerMax')">{{commonCaptions.maximum}} {{localization.captions.shop.Date}}</mat-error>
      </mat-form-field>
        <!-- ---for logviewer--- -->
    </div>
    <mat-accordion *ngFor="let category of Categories; let i = index">
      <mat-expansion-panel class="filter-panel">
        <mat-expansion-panel-header [expandedHeight]="'40px'">
          <mat-panel-title>
            <ng-container *ngIf="category&&category.type!=='single'">
              <span class="filter-title spa-filter-label">{{category.title}} ({{category.filtered.indexOf(0) != -1 ? (category.filtered.length - 1) : category.filtered.length}})</span>
            </ng-container> 
            <ng-container *ngIf="category&&category.type=='single'">
              <span class="filter-title spa-filter-label">{{category.title}}</span>
            </ng-container>   
            
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

      <ng-container *ngIf="category&&category.type!=='single'">
        <div class="categorieslist" >
          <span *ngFor="let filter of category.filters; let j = index">
            <button attr.automationId='Btn_{{automationId}}_category{{j}}' type="button" mat-raised-button *ngIf="j<3" [ngClass]="category.filtered.indexOf(filter.id) != -1 ?  'body-bgcolor whitecolor button--primary': 'button--secondary'" class="body-bordercolor spa-filter-button"
            title="{{filter[buttonTextColumn]}}" (click)="updateCategoryArr(category,filter,i)">
            <span class="buttonVA1">{{filter[buttonTextColumn]}}</span>
          </button>
          </span>
          <div *ngIf="category.filters && category.filters.length > 3">
            <a attr.automationId='Lbl_{{automationId}}_category{{j}}' [id]="category.title" class="golf__link cursor spa-filter-link text-color" (click)="categoryOpenDialog($event,category)"
              href="javascript:void(0)">
              {{category.filters.length > 2 ? category.filters.length -3 : 0 }} {{localization.captions.common.More}}</a>
          </div>
        </div>
      </ng-container>  

       <!-- ---for logviewer--- -->
        <ng-container  *ngIf="category&&category.type=='single'">
          <div class="categorieslist">
            <mat-radio-group attr.automationId='TRdo_{{automationId}}_radioButton' >
              <mat-radio-button (change)="onRadioChange($event,category )" *ngFor="let filter of category.filters; let j = index"
                  [value]="filter" [checked]="filter.isSelected">{{filter.name}}
              </mat-radio-button>
          </mat-radio-group>
          </div>
        </ng-container>
            <!-- ---for logviewer--- -->
    
      </mat-expansion-panel>
    </mat-accordion>
  </ng-scrollbar>
</aside>
<app-retail-more-section [automationId] = "'retailVendorSetup'" sectionfrom="AF" [filterOptions]="this.filterOptions" (resultData)="filteredDataResponse($event)"></app-retail-more-section>
