import { Injectable } from '@angular/core';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { NotificationData } from './send-notification-mail-sms.modal';
@Injectable()

export class SendNotificationMailSMSService {
    constructor(private _golfScheduleCommunication: GolfScheduleCommunication, private _localization: GolfLocalization) {
    }

    public  SMSMailNotification(eMailId: string,phoneNumber:string,teeAction:string,bookingId: string,notificationData?: NotificationData):any {
        return this._golfScheduleCommunication.putPromise({
          route: GolfApiRoute.BulkSendMailNotification, 
          uriParams:{ eMailId: eMailId,phoneNumber:phoneNumber,teeAction:teeAction,bookingId:bookingId },
          body: notificationData
        });
    }
}

