<section id="export-common-wrapper" class="h-100">
    <ng-container>
        <app-dialog-header [automationId]="'exportSend'"  [title]="captions.hdr_exportAndSend" (closeDialog)="close()">
        </app-dialog-header>
    </ng-container>
    <div class="table_wrapper ag_container--padding-md" [formGroup]="exportForm">
        <!-- <div>
            <mat-button-toggle-group class="matGroup" formControlName='exportType' (change)="buttonSelectionChange($event)">
                <mat-button-toggle class="toggle" [value]='ExportTypeEnum.Email'>{{captions.lbl_email}}
                </mat-button-toggle>
                <mat-button-toggle class="toggle" [value]='ExportTypeEnum.Message'>{{captions.lbl_message}}
                </mat-button-toggle>
            </mat-button-toggle-group>
            <i class="icon-print p-3 float-right ag_cursor--pointer" (click)="downloadFile()"></i>
        </div> -->
        <!-- <div class="file-name-header ag_mt--4" copy-clipboard>
            <label class='ag_form--label'>{{captions.lbl_copyUrl}}</label>
            <div class='ag_display--flex align-items-center'>
                <span class="file-name-content ag_display--flex align-items-center">
                    <div class="ag_display--flex">
                        <i aria-hidden="true"  class="icon-link ag_mr--2 ag_pl--2 textColor"></i>
                        <p class='ag_mb--0 ag_pr--2 textColor' id="link"> {{link}}</p>
                    </div>
                    <i aria-hidden="true" class="icon-copy ag_p--2"  (click)="onCopy(link)"></i>
                </span>
            </div>
        </div> -->
        <div *ngIf="exportForm.value.exportType ===  ExportTypeEnum.Email" class="align-items-center ag_display--flex">
            <div class="ag_form-control--4">
                <ag-input formControlName="emailID" [attr.automationId]="'Txt_exportSend_emailId'" [placeholder]="captions.lbl_emailID">
                </ag-input>
                <div *ngIf="showError"  class="text-danger">{{captions.lbl_errEmail}}</div>
            </div>
            <div class="boxed-icon mt-1" [matTooltip]="captions.lbl_Download" matTooltipClass="tooltipClass" ><i [attr.automationId]="'Icn_exportSend_downloadFile'" class="icon-Download---Grid ag_cursor--pointer" (click)="downloadFile()" ></i></div>
            
        </div>
        <div *ngIf="exportForm.value.exportType ===  ExportTypeEnum.Message" class="ag_w--50">
            <ag-input formControlName="phoneNumber" [attr.automationId]="'Txt_exportSend_phoneNumber'" [placeholder]="captions.lbl_phoneNumber">
            </ag-input>
        </div>
       
    </div>
    <div class="create-guest-footer ag_footer--actions">
        <app-button [buttontype]="actionButton" [automationId]="'exportSend'" (valueChange)='onAction($event)'></app-button>
        <app-button [buttontype]="cancelButton" [automationId]="'exportSend'"class="ag_ml--2" (valueChange)='onCancel($event)'></app-button>
    </div>
</section>

