import { Injectable } from '@angular/core';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { PlayerDataService } from '../../data-services/golfmanagement/player.data.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { API } from 'src/app/tee-time/tournaments/tournaments-modal/contact/contact.model';
import { Subject } from 'rxjs';
import { ExistingPlayerTypes, HotelReservationTypes, InitiateSearch, playerTypes, SettingModule, SettingScreen } from '../../global.constant';
import { GolfPlayerCategory, GolfSearchDetail, SearchFilter } from './golf-search-model';
import { SettingsDataService } from '../../data-services/golfmanagement/settings.data.service';
import { API as settingsAPI } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
@Injectable()
export class AgPlayerTypeSearchService {
    captions : any;
    allCaptions : any;
    golfSettings: any;
    public golfSearchFilter : GolfSearchDetail[];
    constructor(private localization: GolfLocalization,
        private _playerDataService: PlayerDataService, private _utilities: GolfUtilities,
        private _settingService: SettingsDataService) {
            this.captions =  this.localization.captions.common;
            this.allCaptions = this.localization.captions;
    }

    public async fetchInHousePlayers(searchKey: string, scheduleTime: Date, searchFilter: API.InHouseGuestSearchFilter, notifier: Subject<void>): Promise<API.InHousePlayer[]> {
        const scheduleDateTimeISO: string = this.localization.convertDateTimeToAPIDateTime(scheduleTime);
        if (searchKey.trim().length === 0) {
            return Promise.resolve([]);
        }

        const inHousePlayers: API.InHousePlayerSearchResponse[] = await this._playerDataService.getInHouseGuestsInfo(searchKey, scheduleDateTimeISO, searchFilter, notifier);
        const mappedResp: API.InHousePlayer[] = inHousePlayers.map(p => {
            return {
                ...p.player,
                pmsStayConfirmationNumber: p.pmsStayConfirmationNumber,
                guestId: p.guestId,
                packageName: p.packageName,
                roomNumber: p.roomNumber,
                linkId: p.linkId,
                arrivalDate: p.stayStartDate,
                departureDate: p.stayEndDate
            }
        });
        return mappedResp;
    }

    public getFormattedFullName(searchValue : string): string
    {
      return this._utilities.getFormattedFullName(searchValue.replace(',',' '));
    }

    public async fetchInStayPlayers(searchKey: string, scheduleTime: Date, searchFilter: API.InHouseGuestSearchFilter, notifier: Subject<void>): Promise<API.InHousePlayer[]> {
        const scheduleDateTimeISO: string = this.localization.convertDateTimeToAPIDateTime(scheduleTime);
        if (searchKey.trim().length === 0) {
            return Promise.resolve([]);
        }

        const inHousePlayers: API.InHousePlayerSearchResponse[] = await this._playerDataService.getInStayGuestsInfo(searchKey, scheduleDateTimeISO, searchFilter, notifier);
        const mappedResp: API.InHousePlayer[] = inHousePlayers.map(p => {
            return {
                ...p.player,
                pmsStayConfirmationNumber: p.pmsStayConfirmationNumber,
                guestId: p.guestId,
                packageName: p.packageName,
                roomNumber: p.roomNumber,
                linkId: p.linkId,
                arrivalDate: p.stayStartDate,
                departureDate: p.stayEndDate
            }
        });
        return mappedResp;
    }
    public getTypesOfPlayers(pmsStay? : string) {
      let searchFilter =  this.golfSearchFilter.find(p=>p.integratedPms == pmsStay);
      if(!pmsStay || !searchFilter)
      {
        return this.getDefaultTypesOfPlayers()
      }
      else{
        return this.MapSearchFilters(searchFilter.searchComponent);
      }
    }
    public getDefaultTypesOfPlayers() {
        return [
          { id: playerTypes.newPlayer, name: 'newPlayer', description: this.captions.newPlayer },
          { id: playerTypes.hotelReservation, name: 'hotelReservation', description: this.captions.hotelReservation },
          { id: playerTypes.member, name: 'member', description: this.captions.member },
          { id: playerTypes.player, name: 'player', description: this.captions.player }];
    }
    public getTypesOfHotelReservation(pmsStay? : string){
      if(pmsStay != undefined && pmsStay.trim().length > 0 && pmsStay.toLocaleLowerCase() == 'stay')
      {
        return this.getTypesOfHotelReservationStay();
      }
      let searchFilter =  this.golfSearchFilter.find(p=>p.integratedPms == pmsStay);
      if(!pmsStay || !searchFilter)
      {
        return this.getDefaultTypesOfHotelReservation()
      }
      else{
        return this.MapSearchFilters(searchFilter.searchComponent.find(s=>s.playerCategory == playerTypes.hotelReservation)?.searchFilter);
      }
  }
  public getSearchKeyLengthForExternalPMSIntegration(playerType: playerTypes,searchKey : number, pmsStay?: string) : number
  {
    let searchFilter = this.golfSearchFilter.find(p => p.integratedPms == pmsStay);
    if (playerType == playerTypes.hotelReservation) {
      let hotelReservationValues = searchFilter && searchFilter.searchComponent.find(s => s.playerCategory == playerTypes.hotelReservation) ?  searchFilter.searchComponent.find(s => s.playerCategory == playerTypes.hotelReservation).searchFilter.find(h => h.id == searchKey) : undefined;
      switch (searchKey) {
        case HotelReservationTypes.confirmationNumber:
          return searchFilter && pmsStay && hotelReservationValues && hotelReservationValues.searchKeyLength ? hotelReservationValues.searchKeyLength :  InitiateSearch.confirmationNumber;
        case HotelReservationTypes.roomNumber:
          return searchFilter && pmsStay && hotelReservationValues && hotelReservationValues.searchKeyLength ? hotelReservationValues.searchKeyLength : InitiateSearch.roomNumber;
        case HotelReservationTypes.guestName:
          return searchFilter && pmsStay && hotelReservationValues && hotelReservationValues.searchKeyLength ? hotelReservationValues.searchKeyLength : InitiateSearch.default;
        case HotelReservationTypes.linkCode:
          return searchFilter && pmsStay && hotelReservationValues && hotelReservationValues.searchKeyLength ? hotelReservationValues.searchKeyLength : InitiateSearch.default;
        case HotelReservationTypes.contactDetail:
          return searchFilter && pmsStay && hotelReservationValues && hotelReservationValues.searchKeyLength ? hotelReservationValues.searchKeyLength : InitiateSearch.default;
      }
    }
  }
      public getDefaultTypesOfHotelReservation() {
        return [
          { id: HotelReservationTypes.confirmationNumber, name: 'confirmationNumber', description: this.captions.ConfirmationNumber },
          { id: HotelReservationTypes.roomNumber, name: 'roomNumber', description: this.captions.RoomNumber },
          { id: HotelReservationTypes.guestName, name: 'guestName', description: this.captions.GuestName },
          { id: HotelReservationTypes.linkCode, name: 'linkCode', description: this.captions.LinkCode },
          { id: HotelReservationTypes.contactDetail, name: 'contactDetail', description: this.captions.ContactDetail }
        ];
      }
    
      public getTypesOfHotelReservationStay(pmsStay? : string) {
        return [
          { id: HotelReservationTypes.confirmationNumber, name: 'confirmationNumber', description: this.captions.ConfirmationNumber },
          { id: HotelReservationTypes.roomNumber, name: 'roomNumber', description: this.captions.RoomNumber },
          { id: HotelReservationTypes.guestName, name: 'guestName', description: this.captions.GuestName },
          { id: HotelReservationTypes.contactDetail, name: 'contactDetail', description: this.captions.ContactDetail }
        ];
      }
      public getExistingPlayerTypes(pmsStay? : string) {
        let searchFilter =  this.golfSearchFilter.find(p=>p.integratedPms == pmsStay);
        if(!pmsStay || !searchFilter)
        {
          return this.getDefaultExistingPlayerTypes();
        }
        else{
          return this.MapSearchFilters(searchFilter.searchComponent.find(s=>s.playerCategory == playerTypes.player)?.searchFilter);
        }
      }
      public getDefaultExistingPlayerTypes(pmsStay? : string) {
        return [
          { id: ExistingPlayerTypes.firstName, name: 'firstName', description: this.localization.captions.setting.FirstName },
          { id: ExistingPlayerTypes.lastName, name: 'lastName', description: this.localization.captions.setting.LastName },
          { id: ExistingPlayerTypes.fullName, name: 'fullName', description: this.localization.captions.teetime.fullName },
          { id: ExistingPlayerTypes.email, name: 'email', description: this.localization.captions.setting.EmailAddress },
          { id: ExistingPlayerTypes.phoneNumber, name: 'phoneNumber', description: this.localization.captions.teetime.phoneNumbertext }
        ];
  }

      fetchGolfSearchJson():any{
        this.golfSearchFilter =  this._playerDataService.fetchGolfSearchJson();
        return this.golfSearchFilter;
      }
      MapSearchFilters(searchComponent: GolfPlayerCategory[] | SearchFilter[])
      {
          if(searchComponent)
          {
            return searchComponent.map((s)=>{ 
                return {
                  id : s.id , name : s.name , description : this.captions[s.placeHolder]
              }
            });
          }
      }

      public async getDefaultSettings(): Promise<settingsAPI.DefaultsSettingConfig> {
        let golfSettingsApiData: settingsAPI.Settings<settingsAPI.DefaultsSetting> = await this._settingService.getSettings<settingsAPI.DefaultsSetting>(SettingModule.SystemSetup, SettingScreen.Defaults);
        this.golfSettings = golfSettingsApiData.configValue;
        return this.golfSettings;
      }

}
