<section class="menu-search">
  <section class="menuSearchWrapper">
    <section class="menuSearch-input"> 
      <form [formGroup]="menuSearchForm">
        <mat-form-field [floatLabel]="floatLabelNever" class="menu-searchField">
          <input autofocus #menuSearchText type="text" matInput formControlName="searchGroup" required
            [matAutocomplete]="autoGroup" (input)="filterGroup(menuSearchText.value)">
          <mat-autocomplete #autoGroup="matAutocomplete" [displayWith]="valueMapper">
            <mat-option *ngIf="(searchGroupOptions)?.length == 0 && menuSearchText.value.length >= 3">
              {{this.captions.NoMatchesFound}}
            </mat-option>
            <ng-container *ngIf="isPms">
              <mat-optgroup *ngIf="searchGroupOptions?.length > 0"  class="opt-grp-label">
                <div class="ag_display--flex align-items-center">
                  <span [matTooltip]="captions.lbl_shortcut_keys"  class="short-cut-label mr-3 text-ellipsis">{{this.captions.lbl_shortcut_keys}}</span> 
                  <span>{{this.captions.Menus}}</span>
                </div>
                 
              </mat-optgroup>
              <ng-container  *ngFor="let group of searchGroupOptions">
                <mat-option class="opt-grp" *ngFor="let data of group.dataCollection" [value]="data.id"
                  (onSelectionChange)="linkClicked(group.title,data,$event)">
                  <div class="ag_display--flex align-items-center justify-content-between w-100">
                    <div class="ag_display--flex align-items-center menu-value-width">
                      <span *ngIf="(data?.keyboardShortcut || this.isPms)"  class="keyboard mr-3">
                         <span *ngIf="data?.keyboardShortcut" [matTooltip]="data?.keyboardShortcut" class="keyboard-shortcut text-ellipsis font-weight-bold">{{data?.keyboardShortcut}}</span> 
                      </span> 
                      <span [matTooltip]="data?.parentValue" *ngIf="data.parentValue">{{data.parentValue}} 
                       <span class="menu-arrow">>&nbsp;</span> </span>
                      <span [matTooltip]="data?.value" class="text-ellipsis">{{ data.value}}</span>
                    </div>
                    <i  aria-hidden="true" *ngIf="isDesignAuthor" class="icon-Edit ag_float--right" (click)="edit(data)"></i>
                    <i  aria-hidden="true" *ngIf = "data?.isReportUrl" class="icon-reports ag_float--right"></i>
                  </div>
                </mat-option>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="!isPms">
              <mat-optgroup class="menu-search-optGroup" *ngFor="let group of searchGroupOptions"
              [label]="this.captions.Menus">
              <mat-option *ngFor="let data of group.dataCollection" [value]="data.id"
                (onSelectionChange)="linkClicked(group.title,data,$event)">
                <span *ngIf="data.parentValue">{{data.parentValue}} <span class="menu-arrow">></span> </span>
                 <span> {{data.value}}</span>
                <i  aria-hidden="true" *ngIf="isDesignAuthor" class="icon-Edit ag_float--right ag_mt--10" (click)="edit(data)"></i>
                <i  aria-hidden="true" *ngIf = "data?.isReportUrl" class="icon-reports ag_float--right ag_mt--10"></i>
              </mat-option>
            </mat-optgroup>
            </ng-container>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </section>
  </section>
