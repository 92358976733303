import { Injectable } from "@angular/core";
import { RetailBussinessService } from '../../retail.bussiness';
import { RetailDataAwaiters } from '../../../retail/shared/events/awaiters/retail.data.awaiters';
import { ExceptionBussines } from "src/app/logviewer/logviewer/exceptions/exceptions.business";
import { LogMessagesBussines } from "src/app/logviewer/logviewer/log-messages/log-messages.business";
import { PerfomanceBussines } from "src/app/logviewer/logviewer/perfomance/perfomance.business";
import { TraceBussines } from "src/app/logviewer/logviewer/trace-modal/trace-modal.business";
import { CommonDataAwaiters } from "src/app/common/shared/events/awaiters/common.data.awaiters";
import { LogTypeBussines } from "../log-type.service";
import { BreakPointAccess } from 'src/app/retail/shared/service/breakpoint.service';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';
import { notifyService } from 'src/app/tee-time-actions/notify/notify.service';
import { API, GuestExportNotificationModel } from 'src/app/common/shared/shared.modal';
import { MatDialog } from "@angular/material/dialog";
import { CommonPopupComponent } from "../../components/common-popup/common-popup.component";
import { ComponentDetails } from "../../shared-models";
import { ButtonAction } from "src/app/common/shared/shared/globalsContant";
import { GolfLocalization } from "src/app/core/localization/golf-localization";
import { PlayerDataService } from 'src/app/shared/data-services/golfmanagement/player.data.service';
import { PlayersBusiness } from 'src/app/guest/players/players.business';
import { promise } from "protractor";
import { playerCategory } from "src/app/lessons/lessons-modal/player-info/player-info.model";
import { CreatePlayerComponent } from "src/app/shared/create-player/create-player.component";
import { VipTypBusiness } from "../vip-type.service";
//import { CreatePlayerComponent } from "../../create-player/create-player.component";
//import { MatDialog } from "@angular/material/dialog";
// import { CommonPopupComponent } from "../../components/common-popup/common-popup.component";
// import { ButtonAction } from "../../global.constant";
// import { GolfLocalization } from "src/app/core/localization/golf-localization";
@Injectable({
  providedIn: "root"
})
export class SetDataAwaiters {
  componentDetails: ComponentDetails;
  captions: any;
  constructor(private _retailBusiness: RetailBussinessService,
    private exceptionBussines: ExceptionBussines,
    private logMessagesBussines: LogMessagesBussines,
    private perfomanceBussines: PerfomanceBussines,
    private logTypeBusiness: LogTypeBussines,
    private traceBussines: TraceBussines,
    public _userAccessBusiness: UserAccessBusiness,
    public _notifyService : notifyService,
    private breakPoint: BreakPointAccess,
    private dialog: MatDialog,
    private localization: GolfLocalization,
    private _playerDataService: PlayerDataService,
    private _playerBusiness: PlayersBusiness,
    private vipTypeBusiness: VipTypBusiness) {
      this.captions = this.localization.captions.shop;
    this.setAwaiters();
  }

  private setAwaiters(): void {
    RetailDataAwaiters.openAddPlayerPopup = this.openAddPlayer.bind(this);
    RetailDataAwaiters.GetPlayerNames = this._retailBusiness.GetPlayerNamesByTransactionId.bind(this._retailBusiness);
    RetailDataAwaiters.SetP1PlayerId = this._retailBusiness.SetP1PlayerId.bind(this._retailBusiness);
    RetailDataAwaiters.PrintAgreement = this._retailBusiness.printAgreements.bind(this._retailBusiness);
    RetailDataAwaiters.GetAllCourses = this._retailBusiness.GetCourses.bind(this._retailBusiness);
    RetailDataAwaiters.GetDefaultCourses = this._retailBusiness.GetDefaultCourses.bind(this._retailBusiness);
    RetailDataAwaiters.getPayeeInfo = this._retailBusiness.GetPlayerById.bind(this._retailBusiness);
    RetailDataAwaiters.GetUnpaidPlayers = this._retailBusiness.GetUnpaidPlayers.bind(this._retailBusiness);
    RetailDataAwaiters.GetUnpaidPlayersByPlayerStatus = this._retailBusiness.GetUnpaidPlayersByPlayerStatus.bind(this._retailBusiness);
    RetailDataAwaiters.TempHoldPlayers = this._retailBusiness.TempHoldPlayers.bind(this._retailBusiness);
    RetailDataAwaiters.GetTempHoldPlayers = this._retailBusiness.GetTempHoldPlayers.bind(this._retailBusiness);
    RetailDataAwaiters.ValidateBreakPoint = this._retailBusiness.ValidateBreakPoint.bind(this._retailBusiness);
    RetailDataAwaiters.ReleasePlayersInHold = this._retailBusiness.ReleasePlayersInHold.bind(this._retailBusiness);
    RetailDataAwaiters.GetUserCourse = this._retailBusiness.GetUserCourse.bind(this._retailBusiness);
    RetailDataAwaiters.searchPayee = this._retailBusiness.searchGuest.bind(this._retailBusiness);
    RetailDataAwaiters.searchTransactionGuest = this._retailBusiness.searchTransactionGuest.bind(this._retailBusiness);
    RetailDataAwaiters.GetSelectedProducts = this._retailBusiness.getSelectedProducts.bind(this._retailBusiness);
    RetailDataAwaiters.getRainChecks = this._retailBusiness.GetRainChecks.bind(this._retailBusiness);
    RetailDataAwaiters.GetRainChecksByTeeTime = this._retailBusiness.GetRainChecksByTeeTime.bind(this._retailBusiness);
    RetailDataAwaiters.updateRainCheck = this._retailBusiness.UpdateRainCheck.bind(this._retailBusiness);
    RetailDataAwaiters.GetPlayerNamesByTransactionId = this._retailBusiness.GetPlayerNamesByTransactionId.bind(this._retailBusiness);
    RetailDataAwaiters.PrintTeeTicket = this._retailBusiness.printTeeTickets.bind(this._retailBusiness);
    RetailDataAwaiters.GetDefaultOutlet = this._retailBusiness.GetDefaultOutlet.bind(this._retailBusiness);
    RetailDataAwaiters.CheckForUserOutletAccessAndDefaultOutlet = this._retailBusiness.CheckForUserOutletAccessAndDefaultOutlet.bind(this._retailBusiness);
    RetailDataAwaiters.ValidateSelectedTerminalExistsInSelectedOutlet = this._retailBusiness.ValidateSelectedTerminalExistsInSelectedOutlet.bind(this._retailBusiness);
    RetailDataAwaiters.GetRetailItemsFromPlayers = this._retailBusiness.GetRetailItemsFromPlayers.bind(this._retailBusiness);
    RetailDataAwaiters.CheckPlayersHaveAnyPendingSettlements = this._retailBusiness.CheckPlayersHaveAnyPendingSettlements.bind(this._retailBusiness);
    RetailDataAwaiters.getPayeeDetails = this._retailBusiness.GetPlayerDetailsByIds.bind(this._retailBusiness);
    RetailDataAwaiters.ShowConfirmation = this._retailBusiness.ShowConfirmation.bind(this._retailBusiness);
    RetailDataAwaiters.CreatePlayer = this._retailBusiness.createPlayer.bind(this._retailBusiness);
    RetailDataAwaiters.GetGuestByPlatformGuestGuid = this._retailBusiness.GetGuestByPlatformGuestGuid.bind(this._retailBusiness);
    RetailDataAwaiters.GetExtendedProfileSearchConfig = this.GetExtendedProfileSearchConfig.bind(this);
    //Added
    RetailDataAwaiters.GetExistingPlayer = this._retailBusiness.getExistingPlayer.bind(this._retailBusiness);
    RetailDataAwaiters.openGuestPatronPopup = this._retailBusiness.openGuestPatronPopup.bind(this._retailBusiness);
    RetailDataAwaiters.getRainCheckRedeemIds = this._retailBusiness.getRainCheckRedeemIds.bind(this._retailBusiness);
    RetailDataAwaiters.ARPost = this._retailBusiness.ARPost.bind(this._retailBusiness);
    RetailDataAwaiters.RedeemPoint = this._retailBusiness.RedeemPoint.bind(this._retailBusiness);
    RetailDataAwaiters.CreatePaymentRecord = this._retailBusiness.CreatePaymentRecord.bind(this._retailBusiness);
    RetailDataAwaiters.GetPaymentRecordByTransactionId = this._retailBusiness.GetPaymentRecordByTransactionId.bind(this._retailBusiness);
    RetailDataAwaiters.GetAllPaymentRecords = this._retailBusiness.GetAllPaymentRecords.bind(this._retailBusiness);
    RetailDataAwaiters.getMemberInfo = this._retailBusiness.GetMemberInfo.bind(this._retailBusiness);
    RetailDataAwaiters.MemberOrderSummary = this._retailBusiness.MemberorderSummary.bind(this._retailBusiness);
    RetailDataAwaiters.ACESRefund = this._retailBusiness.ACESRefund.bind(this._retailBusiness);
    RetailDataAwaiters.updateGuestProfile = this._retailBusiness.updateGuestProfile.bind(this._retailBusiness);
    RetailDataAwaiters.GetPlayers = this._retailBusiness.GetPlayers.bind(this._retailBusiness);
    RetailDataAwaiters.GetChildMenu = this._retailBusiness.GetChildMenu.bind(this._retailBusiness);
    RetailDataAwaiters.getGuestStayDetails = this._retailBusiness.GetAllRoomsForGuest.bind(this._retailBusiness);
    RetailDataAwaiters.GetSettings = this._retailBusiness.GetSettings.bind(this._retailBusiness);
    RetailDataAwaiters.getDefaultsSetting = this._retailBusiness.getDefaultsSetting.bind(this._retailBusiness);
    RetailDataAwaiters.UnRedeemRainChecks = this._retailBusiness.UnRedeemRainChecks.bind(this._retailBusiness);
    RetailDataAwaiters.getTeeTimeSetting = this._retailBusiness.getTeeTimeSetting.bind(this._retailBusiness);
    RetailDataAwaiters.GetCourse = this._retailBusiness.GetCourse.bind(this._retailBusiness);
    RetailDataAwaiters.CheckItemsAreFromSelectedOutlet = this._retailBusiness.CheckItemsAreFromSelectedOutlet.bind(this._retailBusiness);
    RetailDataAwaiters.GetAllRateTypes = this._retailBusiness.GetRateType.bind(this._retailBusiness);
    RetailDataAwaiters.SendNotification = this._retailBusiness.SendRetailNotification.bind(this._retailBusiness); //todo
    RetailDataAwaiters.PrintCaddyShack = this._retailBusiness.printCaddyShack.bind(this._retailBusiness);
    RetailDataAwaiters.ReleasePlayerTempHold = this._retailBusiness.ReleasePlayerTempHold.bind(this._retailBusiness);
    RetailDataAwaiters.GetClientMultiPack = this._retailBusiness.getClietnMultiPacksBytransactionId.bind(this._retailBusiness);
    RetailDataAwaiters.UpdateMultiPack = this._retailBusiness.updateMultiPack.bind(this._retailBusiness);    
    RetailDataAwaiters.GenerateGuestData = this.GenerateGuestData.bind(this);
    RetailDataAwaiters.ValidateOutletReference = this.ValidateOutletReference.bind(this);
    RetailDataAwaiters.ReleaseTempHoldTeeTime = this._retailBusiness.ReleaseTempHoldTeeTime.bind(this._retailBusiness);
    RetailDataAwaiters.IsCancelationNoShowEnabled = this.IsCancelationNoShowEnabled.bind(this);
    CommonDataAwaiters.GetAllExceptions = this.getAllExceptions.bind(this);
    CommonDataAwaiters.GetTopUserException = this.getTopUserException.bind(this);
    CommonDataAwaiters.GetTopFrequentException = this.getTopFrequentException.bind(this);
    CommonDataAwaiters.GetAllTransactions = this.getAllTransactions.bind(this);
    CommonDataAwaiters.GetTopUserTransactions = this.getTopUserTransactions.bind(this);
    CommonDataAwaiters.GetTopFrequentTransactions = this.getTopFrequentTransactions.bind(this);
    CommonDataAwaiters.GetAllTransactionsForUser = this.getAllTransactionsForUser.bind(this);
    CommonDataAwaiters.GetAllTransactionsForScreen = this.getAllTransactionsForScreen.bind(this);
    CommonDataAwaiters.GetAllPerformance = this.getAllPerformance.bind(this);
    CommonDataAwaiters.GetTraceDetails = this.getTraceDetails.bind(this);
    CommonDataAwaiters.GetAllContactLogType = this.getAllLogType.bind(this);
    CommonDataAwaiters.GetAllLogType = this.getAllLogType.bind(this);
    CommonDataAwaiters.CreateLogType = this.CreateLogType.bind(this);
    CommonDataAwaiters.EditLogType = this.EditLogType.bind(this);
    CommonDataAwaiters.UpdateLogType = this.UpdateLogType.bind(this);
    CommonDataAwaiters.DeleteLogType = this.DeleteLogType.bind(this);
    CommonDataAwaiters.CheckForAccess = this.CheckLogTypeForAccess.bind(this);
    CommonDataAwaiters.isViewOnly = this.CheckLogTypeIsViewOnly.bind(this);
    CommonDataAwaiters.GetConfirmationSetting = this.GetConfirmationSetting.bind(this);
    CommonDataAwaiters.IsContactLogViewOnly = this.CheckContactLogIsViewOnly.bind(this);
    CommonDataAwaiters.SendGuestExportEmail = this.SendGuestExportNotification.bind(this);
    //VipType
    CommonDataAwaiters.GetAllVipType = this.getAllVipType.bind(this);
    CommonDataAwaiters.CreateVipType = this.createVipType.bind(this);
    CommonDataAwaiters.UpdateVipType = this.updateVipType.bind(this);
    CommonDataAwaiters.DeleteVipType = this.deleteVipType.bind(this);
    CommonDataAwaiters.GetNextListOrderofVipType = this.getNextListOrderofVipType.bind(this);
    CommonDataAwaiters.DragDropVipType = this.dragDropVipType.bind(this);
    CommonDataAwaiters.GetVipTypeBreakpoint = this.GetVipTypeBreakpoint.bind(this);
  }

  async getAllExceptions(filterData) {
    return this.exceptionBussines.callAPIGetAllExceptions(filterData);
  }
  async getTopUserException(filterData) {
    return this.exceptionBussines.callAPIGetTopUserException(filterData);
  }
  async getTopFrequentException(filterData) {
    return this.exceptionBussines.callAPIGetTopFrequentException(filterData);
  }
  async getAllTransactions(filterData, recordStart) {
    return this.logMessagesBussines.callAPIGetAllTransactions(filterData, recordStart);
  }
  async getTopUserTransactions(filterData) {
    return this.logMessagesBussines.callAPIGetTopUserTransaction(filterData);
  }
  async getTopFrequentTransactions(filterData) {
    return this.logMessagesBussines.callAPIGetTopFrequentTransaction(filterData);
  }
  async getAllTransactionsForUser(filterData, userName) {
    return this.logMessagesBussines.callAPIGetAllTransactionsForUser(filterData, userName);
  }
  async getAllTransactionsForScreen(filterData, screenName) {
    return this.logMessagesBussines.callAPIGetAllTransactionsForScreen(filterData, screenName);
  }
  async getAllPerformance(filterData, seconds) {
    return this.perfomanceBussines.callAPIGetAllPerformance(filterData, seconds);
  }
  async getTraceDetails(traceId) {
    return this.traceBussines.callAPI(traceId);
  }
  async getAllLogType(includeinactive) {
    return this.logTypeBusiness.callAPIGetAllLog(includeinactive);
  }

  async CreateLogType(logtype) {
  return this.logTypeBusiness.callAPICreateLog(logtype);
  }

  async EditLogType(logtype, id) {
  return this.logTypeBusiness.callAPIEditLog(logtype, id);
  }

  async UpdateLogType(inputdata) {
  return this.logTypeBusiness.callAPIUpdateLog(inputdata);
  }

  async DeleteLogType(id) {
  return this.logTypeBusiness.callAPIDeleteLog(id);
  }

  async CheckLogTypeForAccess(){
    return this.breakPoint.CheckForAccess([UserAccessBreakPoints.LOGTYPE], false);
  }
  async CheckLogTypeIsViewOnly(){
    return this.breakPoint.IsViewOnly(UserAccessBreakPoints.LOGTYPE);
  }

  async GetConfirmationSetting(){
    return this.logTypeBusiness.GetConfirmationSetting();
  }

  async CheckContactLogIsViewOnly(): Promise<boolean> {
    let result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.PLAYERPROFILE, false);
    return result.isViewOnly;
  }

  async SendGuestExportNotification(notifyModel:GuestExportNotificationModel):Promise<boolean>{
    return await this._notifyService.sendGuestExportNotification(notifyModel);
  }

  async GetExtendedProfileSearchConfig()
  {
    return JSON.parse(sessionStorage.getItem('defaultSettings'))?.enableExtentedSearchByDefault ?? false;
  }

  async GenerateGuestData(filterData) {
    return await this._playerBusiness.GenerateFormData(filterData);
  }

  openAddPlayer(callback: Function,popUpTitle?: string, data?:any){
    this.componentDetails = {
      componentName: CreatePlayerComponent,
      popUpDetails: {
        isStepper: false,
        bindData: data,
        eventName: 'notifyParent',
        // cmsPatronId: id
       }
    };
    const dialogRef = this.dialog.open(CommonPopupComponent, {
      maxWidth: '90vw',
      width: '90%',
      height: '90%',
      disableClose: true,
      data: {
        title: popUpTitle,
        update: popUpTitle === 'EDIT PLAYER' ? this.localization.captions.settings.editPopSave : this.localization.captions.settings.createPopSave,
        cancel: this.captions.cancel,
        componentDetails: this.componentDetails,
        actionType: popUpTitle === 'EDIT PLAYER' ? ButtonAction.update : ButtonAction.save
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result[0] === ButtonAction.save || result[0] === ButtonAction.update ) {
       this.GetPlayerByLinkId(result[1]).then(result=>{
        callback(this.BuildPayeeData(result));
         }); 
      }
    });
  }

  private BuildPayeeData(player) {
    return {
        id: player.id,
        name: player.firstName + ' ' + player.lastName,
        address: player.playerAddress.addressLine1?player.playerAddress.addressLine1: '' + ' ' + player.playerAddress.state ?player.playerAddress.state: '',
        country: player.playerAddress.country ? player.playerAddress.country : '' ,
        zip: player.playerAddress.zip ? player.playerAddress.zip : '' ,
        city: player.playerAddress.city ? player.playerAddress.city : '',
        guestProfileId: player.playerLinkId,
        guestId : player.id,
    };
  }

  async GetPlayerByLinkId(playerLinkID: string):Promise<API.Player>{
    let isTeeTimeBook : boolean = true;
    return await this._playerDataService.GetPlayerByPlayerLinkId(playerLinkID,isTeeTimeBook);
  }

  private async getAllVipType(includeInactive) {
      return await this.vipTypeBusiness.getAllVipType(includeInactive);
  }

  private async createVipType(vipType) {
      return this.vipTypeBusiness.createVipType(vipType);
  }

  private async updateVipType(vipType, id) {
      return this.vipTypeBusiness.updateVipType(vipType, id);
  }

  private async deleteVipType(id){
      return this.vipTypeBusiness.deleteVipType(id);
  }

  private async getNextListOrderofVipType(){
      return this.vipTypeBusiness.getNextListOrderofVipType();
  }

  private async dragDropVipType(fromOrder, toOrder, includeInactive){
      return this.vipTypeBusiness.dragDropVipType(fromOrder, toOrder, includeInactive);
  }
  private async GetVipTypeBreakpoint(){
    return UserAccessBreakPoints.VIPTYPE;
  }

  async ValidateOutletReference(outletId: number) {
    return await this._playerDataService.ValidateOutletReference(outletId);
  }

  private async IsCancelationNoShowEnabled() {
    return JSON.parse(sessionStorage.getItem('TEETIMESETTING'))?.isNoShowCancellationPolicyConfigured;
  }

}
