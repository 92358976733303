<section class="CGR combine-guest-records-wrapper newVersionWrapper" *ngIf="!showcombinemember">
  <div class="srchSec">
    <form class='ag_w--100 ag_display--flex ag_align-items--center' [formGroup]='CGSform' autocomplete="off">
      <ag-textbox [config]='lastNameInput'></ag-textbox>
      <ag-textbox [config]='firstNameInput' ></ag-textbox>

      <ag-textbox [config]='postalCodeInput' ></ag-textbox>
      <ag-textbox [config]='patronIdInput' ></ag-textbox>
      <app-button [attr.automationId]="'Btn_combineGuestRecords_search'" class='d-inline-block searchButton' [buttontype]="searchButton" (valueChange)='searchGuestRecords($event)'>
      </app-button>

    </form>

  </div>
  <section class="gridSec">
    <div class="ag_display--flex ag_pt--2">
      <h4 class="ag_group--headers">{{this.captions.lbl_Guest}}</h4>
      <div class="buttonSet">
        <button class="ag-button .secondary-btn-default cust_mr--0" color="secondary" mat-stroked-button
                [popover]="configurePopover" popoverPlacement="bottom" [popoverOnHover]="false"
                [popoverCloseOnMouseOutside]="false" [attr.automationId]="'Btn_combineGuestRecords_filter'"
                [popoverCloseOnClickOutside]="true">
          {{this.captions.btn_selectFieldsToFilter}}
        </button>
        <span class="custBadge" [ngClass]="{'opacity0':!showBadge}">&nbsp;</span>
        <app-button [attr.automationId]="'Btn_combineGuestRecords_combineGuest'"  class='d-inline-block' [buttontype]="combineguestBTN" (valueChange)='combineguest($event)'>
        </app-button>
      </div>
    </div>
    <div class="CGR_gridContent">
      <div class="table-container h-100 ag_pr--0 ag_pl--0"
           *ngIf="tableContent && tableContent.length !==0; else nodatafound">
        <app-cdkvirtual [attr.automationId]="'Tbl_combineGuestRecords_combineGuest'" [headerOptions]="headerOptions" [IsEditModeEnabled]="IsEditModeEnabledFlag"
                        [tableContent]="tableContent" [options]="options" (EmittedData)='tableAction($event)'
                        [isViewMode]='isViewOnly'></app-cdkvirtual>
      </div>
    </div>

  </section>
</section>
<ng-container *ngIf="showcombinemember">
  <ag-combine-member [inputs]='combinememberInputs' (handleClickEvent)='closeModelAction($event)'
                      (formReadyEvent)='onFormReady($event)'></ag-combine-member>
</ng-container>
<ng-template #nodatafound>
  <div class="ag_text--center noDataDiv">
    <img src="./assets/images/Summary_Illustration.png" alt="No Summary">
    <div class="ag_mt--7 ag_mb--7" [attr.LiteralID]="'lbl_noResults'">
      {{captions.lbl_resultswillbedisplayedhere}}
    </div>
  </div>
</ng-template>

<popover-content #configurePopover class="configure-popover popover-content" title="" placement="bottom auto" [animation]="true"
                 [closeOnClickOutside]="true">
  <ng-container>
    <div class="configureCheckbox-wrapper">
      <div>
        <div *ngFor="let headerOption of _configureHeaderOptions;let i = index">
          <div class="configureCheckbox-div" *ngIf="headerOption.show">

            <mat-checkbox class="configureCheckbox" attr.automationId='Chk_combineGuestRecords_configure{{i}}'
                          [ngClass]="{'ag_section--disable':headerOption.disabled}"
                          (change)="configureOptionClick($event,_configureHeaderOptions, headerOption, i)"
                          [disabled]="headerOption.disabled" [checked]="headerOption.checked">

              <span [matTooltip]="headerOption.displayName" [matTooltip]="headerOption.displayName">{{headerOption.displayName}}</span>
            </mat-checkbox>

          </div>
        </div>
      </div>
    </div>
  </ng-container>
</popover-content>
