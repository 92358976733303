import { Injectable } from '@angular/core';
import { TokentransactionInfo, ClientCreditCardInfo, CardInfo, StoreTokenRequest, PaymentBaseResponse, HandleRequest, PaymentMethods, HandleResponse, Device } from 'src/app/shared/payment-model';
import { PaymentBusinessService } from 'src/app/shared/data-services/payment/payment-business.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { PaymentInformationService } from 'src/app/lessons/lessons-modal/payment-information/payment-information.service';

@Injectable()
export class PlayerPaymentInfoBusiness {
    isViewOnly: boolean = false;
  constructor(private _PaymentInformationService: PaymentInformationService
    , private _paymentService: PaymentBusinessService
    , private _localization: GolfLocalization
    , private _utils: GolfUtilities) { }

  GetCaptions() {
    return this._PaymentInformationService.getCaptions();
  }

  GetAvailableDevices() {
    return this._PaymentInformationService.getAvailableDevices();
  }

  ConnectToDevice(device) {
    this._PaymentInformationService.connectToDevice(device);
  }

  CreateToken(handle, tenderID): Promise<TokentransactionInfo> {
    // Lights up the connected Device and asks for the card swipe
    const tokentransactionInfo = this._paymentService.CreateToken(handle, tenderID);
    tokentransactionInfo.then((response) => {
        if (response.status.toLocaleLowerCase() === 'success') {
            return tokentransactionInfo;
        }
    }).catch((err) => {
        if (err.error[0] != null && err.error[0].Code != null) {
            this._paymentService.PaymentErrorPrompt(err.error[0].Code);
        }
        else {
            return this._utils.showError(this._localization.captions.shop.PMUnexpectedError);
        }
    });
    return tokentransactionInfo;
}

async StoreAndGetSwippedCardInfo(tokentransactionInfo: TokentransactionInfo, tenderID: number): Promise<[CardInfo, number]> {
  let swipedcardInfo: ClientCreditCardInfo;
  let newCardInfo: CardInfo;
  newCardInfo = {
      cardNumber: tokentransactionInfo.account.id,
      cardHolderName: tokentransactionInfo.account.name,
      entryMode: tokentransactionInfo.cardInfo.entryMode,
      issuerType: tokentransactionInfo.cardInfo.issuer.toLowerCase(),
      cardExpiration: tokentransactionInfo.cardInfo.cardExpiration,
      cardType: tokentransactionInfo.cardInfo.cardType
  }

  if (!this._paymentService.ValidateCreditCard(newCardInfo)) {
      this._utils.CloseAllOpenedPopups();
      return;
  }

  let storeTokenReq: StoreTokenRequest =
  {
      cardInfo: newCardInfo,
      payAgentResponse: {
          account: tokentransactionInfo.account,
          payAgentId: tokentransactionInfo.payAgentId,
          status: tokentransactionInfo.status,
          transactionDetails: tokentransactionInfo.transactionDetails,
          transactionKey: tokentransactionInfo.transactionKey,
      },
      tenderId: tenderID
  }
  let baseResponse: PaymentBaseResponse = await this.StoreToken(storeTokenReq);
  if (baseResponse && baseResponse !== null) {
      swipedcardInfo = {
          id: 0,
          tokenTransId: baseResponse.transactionId,
          isActive: true,
          clientId: 0,
          createdTime: Date.now().toString()
      };
  } else {      
      return;
  }

  newCardInfo.cardNumber = this._paymentService.MaskCreditCardNumber(newCardInfo.cardNumber);
  return [newCardInfo, baseResponse.transactionId];
}

async GetDevices() {
  let availableDevices: Device[] = [];
  let RequestBody: HandleRequest = {
      tenderId: PaymentMethods.CreditCard.toString()
  };
  const Handles: Promise<HandleResponse> = this._paymentService.GetHandles(RequestBody);
  Handles.then(response => {
      if (response && response.status.toLocaleLowerCase() === 'success' && response.paymentHandle.length > 0) {
          let availableIngenicoDevices = response.paymentHandle;
          availableIngenicoDevices.forEach(device => {
              if (availableDevices.filter(x => x.name == device.name).length == 0) {
                  availableDevices.push(device);
              }
          });
      }
  });
  return availableDevices;
}

async StoreToken(storeTokenRequest: StoreTokenRequest): Promise<PaymentBaseResponse> {
    return await this._paymentService.StoreToken(storeTokenRequest);   
}
}
