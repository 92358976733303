import { AvailableCaddyCaption } from './caddy-select.model';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { Injectable } from '@angular/core';

@Injectable()
export class CaddySelectService {
    captions:any;
    constructor(private _localization:GolfLocalization){}
    getCaptions(): AvailableCaddyCaption {
        this.captions = this._localization.captions.CaddySelect;
        return this.captions;
    }
}
