import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PlayerCategory } from '../../common/components/component-linking-base/component-linking-base.model';
import { ButtonTypes } from '../../common/enums/shared-enums';
import { Localization } from '../../common/localization/localization';
import { PolicyCategoryType } from '../../common/shared/shared.modal';
import { MemberBusinessService } from '../../retail/shared/business/Member-business.service';
import { RetailSharedVariableService } from '../../retail/shared/retail.shared.variable.service';
import { CommonVariablesService } from '../../retail/shared/service/common-variables.service';
import { PlayerPaymentstatus, ScheduledTeeTimeUnPaidPlayer } from '../../retail/shared/shared.modal';
import { StepperService } from '../../shared/components/stepper/stepper.service';
import { PlayerProfileDataService } from '../../shared/data-services/golfmanagement/PlayerProfile.data.services';
import { RateTypeDataService } from '../../shared/data-services/golfmanagement/ratetype.data.service';
import { TeeTimesActionService } from '../../shared/data-services/golfschedule/teeTimesAction.data.service';
import { GuaranteeType } from '../../shared/global.constant';
import { TeeTimeAction } from '../../shared/models/teesheet.api.models';
import { TeeTimeFormat } from '../../shared/models/teesheet.form.models';
import { AlertAction, AlertType, ButtonType, CheckboxTableHeader, DialogCloseObj } from '../../shared/shared-models';
import { GolfUtilities } from '../../shared/utilities/golf-utilities';
import { TeeTimesActionBusiness } from '../../tee-time/shared/tee-action.business';
import { PaymentModalBussiness } from '../payment-modal/payment-modal-bussiness';
import { PaymentModalService } from '../payment-modal/payment-modal-service';
import { TeeTimeActions } from '../tee-time-action.base';
import { TeeTimeTempHoldResult } from '../teetime/tee-time.model';
import { CheckInOutUndoCheckInOutModalBussiness } from './check-in-out-undo-check-in-out-modal.bussiness';

@Component({
  selector: 'check-in-out-undo-check-in-out-modal',
  templateUrl: './check-in-out-undo-check-in-out-modal.component.html',
  styleUrls: ['./check-in-out-undo-check-in-out-modal.component.scss'],
  providers: [CheckInOutUndoCheckInOutModalBussiness, PaymentModalBussiness, PaymentModalService,
    RateTypeDataService, TeeTimesActionBusiness],
    encapsulation: ViewEncapsulation.None
})
export class CheckInOutUndoCheckInOutModalComponent implements OnInit {

  @Output() notifyParent = new EventEmitter();
  actionType: string;
  action: TeeTimeAction;
  checkInCheckOutFormGroup: UntypedFormGroup;
  captions: any;
  data: any;
  flagValue: boolean = false;
  isPlayerPayReleaseBP: boolean = false;
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  headerOption: CheckboxTableHeader[];
  tableData: ScheduledTeeTimeUnPaidPlayer[];
  checkboxTrueArray: ScheduledTeeTimeUnPaidPlayer[];
  checkboxFalseArray: ScheduledTeeTimeUnPaidPlayer[];
  tempHoldPlayer: TeeTimeTempHoldResult[];
  teeTimeSettings: any;
  isToShowConsentManagment: boolean = false;
  checkInForm: UntypedFormGroup;
  canShow = false;
  popRes = false;
  multiSelectPlayerIds = [];
  automationId:string="CheckInOutUndoCheckInOutModal";
  constructor(
    private _FormBuilder: UntypedFormBuilder
    , public localization: Localization
    , private _checkInOutUndoCheckInOutModalBusiness: CheckInOutUndoCheckInOutModalBussiness
    , @Inject(MAT_DIALOG_DATA) public dialogData
    , public dialogRef: MatDialogRef<any>
    , public _StepperService: StepperService
    , public _ss: CommonVariablesService
    , private _memberService: MemberBusinessService
    , private _teeTimeActionService: TeeTimesActionService
    , private _rateTypeDataService: RateTypeDataService
    , private _retailSharedVarService: RetailSharedVariableService
    , private _utilities: GolfUtilities
    , private _payPlayerBusiness: PaymentModalBussiness
    , private _teeTimeActionBusiness: TeeTimesActionBusiness
    , public _dialog: MatDialog,
    public _playerProfileDataService: PlayerProfileDataService
  ) {
    this.data = dialogData.info;
    this.captions = this.localization.captions;
  }

  ngOnInit(): void {
    this.teeTimeSettings = JSON.parse(sessionStorage.getItem('TEETIMESETTING'));
    let isGdprEnabled = JSON.parse(sessionStorage.getItem('isGdprEnabled'));
    this.actionType = this.dialogData.teeTimeType;
    this.action = TeeTimeAction[this.actionType];
    this.isToShowConsentManagment = isGdprEnabled && (this.action == TeeTimeAction.checkIn || this.action == TeeTimeAction.checkInCheckOut)
    this.headerOption = this._checkInOutUndoCheckInOutModalBusiness.GetHeaderOption();
    this.checkInForm = this._FormBuilder.group({
      autoSelectPlayers: false
    });
    this.getPlayer();    
    this.notifyParent.emit(false);   
    this.checkInCheckOutFormGroup = this._FormBuilder.group({});
    this.funNotifyParent();
    this.stepperService();   
  }
  async getPlayer() {
    this._utilities.ToggleLoader(true);
    console.log(this.teeTimeSettings);
    this.canShow = this.teeTimeSettings.includePlayersAcrossCoursesForCheckIn;
    this.checkInForm.controls.autoSelectPlayers.setValue(this.canShow);    
    this.checkInForm.updateValueAndValidity();
    this.tableData = await this._checkInOutUndoCheckInOutModalBusiness.GetPlayers(this.data, this.action, this.RefreshGrid.bind(this), this.teeTimeSettings);
    let playerIds = this.tableData.map(c => c.playerId);
    if (this.actionType == TeeTimeActions.checkOut || this.actionType == TeeTimeActions.checkInCheckOut || (this.actionType == TeeTimeActions.checkIn && this.teeTimeSettings && this.teeTimeSettings != null && this.teeTimeSettings.allowPaymentInCheckIn)) await this.getHoldPlayers(playerIds);
    const loginUserId = this.localization.GetUserInfo('userId');
    if (this.tempHoldPlayer?.length > 0) {

      this.tableData.forEach(a => {
        let playerLength = this.tempHoldPlayer.filter(c => c.playerId == a.playerId).length > 0 ? true : false;
        const blockByUserName = playerLength == true ? this.tempHoldPlayer.find(c => c.playerId == a.playerId).userName : "";
        const blockByUserId = playerLength == true ? this.tempHoldPlayer.find(c => c.playerId == a.playerId).createdBy : 0;
        a.blockByUser = blockByUserName;
        a.isHold = blockByUserName != "" && blockByUserName != undefined ? true : false;
        a.isReleaseAllow = blockByUserId && blockByUserId.toString() == loginUserId ? true : this.isPlayerPayReleaseBP;
        a.checked = a.isHold == true ? false : a.checked;
      });
    }
    this.flagValue = this.tableData.filter(x => x.isHold)?.length > 0 ? true : false;
    if(!this.canShow){  
      if (this.tableData != null) {
      this.checkboxTrueArray = this.tableData.filter((el) => {
        return el.checked;
      });
      this.sortArray(this.checkboxTrueArray);
      this.checkboxFalseArray = this.tableData.filter((el) => {
        return !el.checked;
      });
      this.sortArray(this.checkboxFalseArray);
      this.tableData = this.checkboxTrueArray.concat(this.checkboxFalseArray);
    }}
    this.headerOption = this._checkInOutUndoCheckInOutModalBusiness.GetHeaderOption(this.flagValue,);
    this.tableData = [...this.tableData];   
    if (this.checkInForm.controls.autoSelectPlayers.value) {
      console.log(this.tableData);
      console.log(this.data.scheduledTeeTimeId);
      this.tableData.map(x => {
       x.checked = (x.scheduledTeeTimeId === this.data.scheduledTeeTimeId) ? true : false
      });   
      this.tableData = [...this.tableData];
      console.log(this.tableData);
      this.checkedData(null, true);
    }
    this._utilities.ToggleLoader(false);
  }

  UpdateAutoSelect(val, multiSelectedPlayerIds){
    if (this.canShow) {
      this.popRes = true;
      this.multiSelectPlayerIds = multiSelectedPlayerIds;
      this.checkInForm.controls.autoSelectPlayers.setValue(val);
      this.checkInForm.updateValueAndValidity();
    }
  }

  async RefreshGrid(res, extraParam) {
    let unpaidPlayers = extraParam[0];
    if (res === AlertAction.YES) {
      if (unpaidPlayers.find(x => x.isTournamentPlayersUnPaid && x.tournamentId > 0)) {
        unpaidPlayers.map(p => { p.checked = false;});
        unpaidPlayers = unpaidPlayers.filter(x => x.tournamentId == extraParam[1]);
        unpaidPlayers.map(p => { p.checked = (p.tournamentId == extraParam[1]); });
        this.sortArray(unpaidPlayers);    
        this.UpdateAutoSelect(false, unpaidPlayers.filter(x=> x.checked).map(x=> x.playerId));
        // this._StepperService.enableCancel = false; // 113571
      } else if (this.data.playerDetail.find(x => x.teeTimeFormat === TeeTimeFormat.BulkTee && extraParam[1].map(s => s.confirmationNumber).includes(x.confirmationNumber))) {
        unpaidPlayers.map(p => { p.checked = (p.teeTimeFormat === TeeTimeFormat.BulkTee && extraParam[1].map(s => s.confirmationNumber).includes(p.confirmationNumber)); });
        let checkedplayers = unpaidPlayers.filter(player => player.checked);
        this.sortArray(checkedplayers);
        let uncheckedplayers = unpaidPlayers.filter(player => !player.checked);
        this.sortArray(uncheckedplayers);
        unpaidPlayers = checkedplayers.concat(uncheckedplayers);
        console.log(this.canShow);
        this.UpdateAutoSelect(false, checkedplayers.map(x=> x.playerId));
      }
      this.tableData = unpaidPlayers;

    } else {
      let checkedplayers = unpaidPlayers.filter(player => player.checked);
      this.sortArray(checkedplayers);
      let uncheckedplayers = unpaidPlayers.filter(player => !player.checked);
      this.sortArray(uncheckedplayers);
      unpaidPlayers = uncheckedplayers && uncheckedplayers.length ? checkedplayers.concat(uncheckedplayers) : checkedplayers;
      this.tableData = unpaidPlayers;
      console.log(this.canShow);
      if (this.canShow) {
        // this.tableData.map(x => {
        //   return {
        //     ...x,
        //     checked: (x.scheduledTeeTimeId === this.data.scheduledTeeTimeId) ? true : false
        //   };
        // });   
        // this.tableData = [...this.tableData];
        this.checkedData(null, false);
      }
    }
  }
  async getHoldPlayers(playerIds: number[]) {
    this.tempHoldPlayer = await this._teeTimeActionService.GetTempHoldPlayers(playerIds);
  }
  async addPlayersInHold(lstUnPaidPlayer: number[]) {
    await this._checkInOutUndoCheckInOutModalBusiness.HoldPlayers(lstUnPaidPlayer);
  }
  async activityAction(data) {
    let playerIds = [];
    playerIds.push(data.playerId);
    this.releasePlayersInHold(playerIds);
    let checkedItems: any[] = this.checkInCheckOutFormGroup.value.checkBoxItems;
    this.tableData.forEach(x => {
      if (x.playerId == data.playerId) {
        x.isHold = false;
        x.blockByUser = "";
      }
      x.checked = checkedItems.find(c => c.id == x.playerId).checkBox == true ? true : false;
    })
    this.tableData = [...this.tableData];
    data.isHold = false;
    this.flagValue = this.tableData.filter(x => x.isHold)?.length > 0 ? true : false;
    this.headerOption = this._checkInOutUndoCheckInOutModalBusiness.GetHeaderOption(this.flagValue);

  }
  async submitForm() {
    var lstPlayerId: number[] = [];
    var lstUnPaidPlayer: number[] = [];
    let checkedItems = this.checkInCheckOutFormGroup.value.checkBoxItems.filter(o => o.checkBox);
    
    if(checkedItems && checkedItems.length > 0){
      let corpIds = _.uniq(checkedItems.map(o => o.corpId));
      corpIds = corpIds.filter(o => o != null && o != '');
      if(corpIds && corpIds.length > 1){
        this._utilities.showAlert(this.captions.teetime.memberdifferentcorporates,AlertType.Error);
        return false;
      }
    }

    this.checkInCheckOutFormGroup.value.checkBoxItems.forEach(c => {
      if (c.checkBox) {
        if (this.actionType == TeeTimeActions.checkOut || this.actionType == TeeTimeActions.checkInCheckOut || (this.actionType == TeeTimeActions.checkIn && this.teeTimeSettings && this.teeTimeSettings != null && this.teeTimeSettings.allowPaymentInCheckIn)) {
          if ((c.playerStatus & PlayerPaymentstatus.paid) == 0) {
            lstUnPaidPlayer.push(c.playerId)
          }
          else if ((c.playerStatus & PlayerPaymentstatus.paid) != 0) { lstPlayerId.push(c.playerId) }
        }
        else {
          lstPlayerId.push(c.playerId)
        }
      }
    });
    if ((this.actionType == TeeTimeActions.checkOut || this.actionType == TeeTimeActions.checkInCheckOut || (this.actionType == TeeTimeActions.checkIn && this.teeTimeSettings && this.teeTimeSettings != null && this.teeTimeSettings.allowPaymentInCheckIn)) && lstUnPaidPlayer.length > 0) {
      var isvalid = this.validateMember(lstUnPaidPlayer)
      if (isvalid) {
        var res = this._utilities.showAlert(this.actionType == TeeTimeActions.checkIn ? this.localization.captions.teetime.checkInPartialPaymentMsg : this.localization.captions.teetime.checkOutPartialPaymentMsg, AlertType.Warning, ButtonType.YesNo, async (res) => {
          if (res === AlertAction.YES) {
            let arr = lstUnPaidPlayer.concat(lstPlayerId).distinct();
            this.UpdatePlayerCheckInOutStatus(arr, lstUnPaidPlayer);
            this.addPlayersInHold(lstUnPaidPlayer).then(async resp => {
              let isToMarkCheckOut = (this.actionType == TeeTimeActions.checkOut || this.actionType == TeeTimeActions.checkInCheckOut) ?? false;
              this.dialogData.isToMarkCheckIn = !isToMarkCheckOut ?? false;
              await this._payPlayerBusiness.ProceedToOrderSummary(this, false, isToMarkCheckOut, false);
              this._StepperService.enableCancel = true;
              return;
            }).catch(err => {
              this.getPlayer();
              this.tableData = [...this.tableData];
              return;
            });
          }
        });
        await res.afterClosed().toPromise();
      }
    }
    else {
      this.UpdatePlayerCheckInOutStatus(lstPlayerId, lstUnPaidPlayer);
    }
  }
  async UpdatePlayerCheckInOutStatus(lstPlayerId: number[], lstUnPaidPlayer: number[] = []) {
    this.notifyParent.emit(true);
    this._StepperService.enableCancel = false;
    await this._checkInOutUndoCheckInOutModalBusiness.UpdatePlayerCheckInOutStatus(this, lstPlayerId).then(x => {
      if (lstUnPaidPlayer.length <= 0) {
        this._utilities.showAlert(`${this.localization.captions.teetime.checkInCheckOutSuccessMsg}`,
          AlertType.Success, ButtonType.Ok, () => {
            this.dialogRef.close('saved');
          });
      }
      this._StepperService.enableCancel = true;
    })
  }
  async releasePlayersInHold(playerIds: number[]) {
    await this._checkInOutUndoCheckInOutModalBusiness.ReleaseTempHoldByType(playerIds);
  }
  validateMember(unpaidPlayers: Array<any> = null) {
    var rateTypes;
    this._rateTypeDataService.getAllRateTypes(false).then(resp => {
      rateTypes = resp;
    });
    if (this._ss.allowMultipleMembersInTransaction() && this._memberService.AllowMultiMemberInTransaction) {
      return true;
    }
    if (unpaidPlayers && unpaidPlayers.length && rateTypes && rateTypes.length) {
      let totalMemberGuaranteedPlayerAssociated = 0;
      unpaidPlayers.forEach(player => {
        const isValidMember = this.isValidMember(player.rateTypeId, player.playerCategoryId, rateTypes);
        if (isValidMember &&
          (++totalMemberGuaranteedPlayerAssociated) > 1
        ) {
          return false;
        }
      });

      // Continue booking scenario handling to restrict one member per transaction
      this._retailSharedVarService.SelectedPlayers.forEach(player => {
        const isValidMember = this.isValidMember(player.rateTypeId, player.playerCategoryId, rateTypes);
        if (isValidMember &&
          (++totalMemberGuaranteedPlayerAssociated) > 1
        ) {
          return false;
        }
      });
      if (totalMemberGuaranteedPlayerAssociated > 1) {
        this._StepperService.enableCancel = true;
        this._utilities.showAlert(this.localization.captions.teetime.RestrictMultiMemberInATransaction, AlertType.Info, ButtonType.Ok);
        this._teeTimeActionService.releasePlayersInHold(unpaidPlayers.map(c => c.playerId));
        return false;
      }
    }
    return true;
  }
  isValidMember(rateTypeId, playerCategoryId, rateTypes: any): boolean {
    return (rateTypeId > 0 && (rateTypes.find(y => y.id == rateTypeId) || ({} as any)).guaranteeType == GuaranteeType.Member)
      || (playerCategoryId === 3);
  }
  private sortArray(playerArray) {
    var dateA, dateB
    if (playerArray && playerArray.length) {
      playerArray.sort((a, b) => {
        dateA = new Date(a.scheduledAPIDateTime);
        dateB = new Date(b.scheduledAPIDateTime);
        return dateA - dateB || (a.playerPosition > b.playerPosition ? 1 : -1);
      });
    }
  }
  private funNotifyParent() {
    this.checkInCheckOutFormGroup.statusChanges.pipe(takeUntil(this.$destroyed)).subscribe(x => {
      let checkBoxItems = this.checkInCheckOutFormGroup.value.checkBoxItems;
      let isCheckBoxChecked = checkBoxItems && checkBoxItems?.length > 0 && checkBoxItems.some(y => y.checkBox) ? true : false
      if (this.checkInCheckOutFormGroup.valid && isCheckBoxChecked) {        
        this.notifyParent.emit(true);
      }
      else {
        this.notifyParent.emit(false);
      }
    });
  }
  
  private stepperService() {
    this._StepperService.valueChange.pipe(takeUntil(this.$destroyed)).subscribe((x: DialogCloseObj) => {
      this.submitForm();
    });
  }

  ngOnDestroy(): void {
    this._StepperService.enableCancel = true;
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }
  openConsentManagment() {
    var arrGuestId = [];
    let popupComment : string = '';
    this.checkInCheckOutFormGroup.value.checkBoxItems.forEach(c => {
      if (c.checkBox) {
        if (c.playerLinkId && (c.playerCategoryId == PlayerCategory.ExistingPlayer || c.playerCategoryId == PlayerCategory.HotelReservation))
          arrGuestId.push(c.playerLinkId)
        else
          popupComment = this.localization.captions.consentCommentOnGuest
      }
    });
    arrGuestId = arrGuestId.distinct();
    if (arrGuestId?.length > 0)
      this._teeTimeActionBusiness.openConsentManagmentModal(arrGuestId, PolicyCategoryType.Guest,popupComment, (res) => {
        
      });
    else {
      this._utilities.showCommonAlert(this.localization.captions.teetime.consentManagmentValidation, AlertType.Info, ButtonTypes.Ok
      )
    }
  }

  checkAllData(eve)
  {
    if (this.canShow) {
      this.tableData.map(element => {
          element.checked = eve;   
      });
    }
  }

  autoSelectPlayertoggleChange(eve) {
    this.checkInForm.controls.autoSelectPlayers.setValue(eve);
    this._utilities.ToggleLoader(true);
    if (this.canShow) {
      if (eve) {
        let playerLinkIds = [];
        let linkIds = [];
        this.data.playerDetail.map(x => {
            if (x.playerCategoryId === 3) {
              linkIds.push(x.linkingId);
            } else {
              linkIds.push(x.imageReferenceId);
            }
            playerLinkIds = this._utilities.getDistinctGUIDs(linkIds);       
        });
        this.tableData = this.tableData.filter(element => {
          element.checked = (element.scheduledTeeTimeId == this.data.scheduledTeeTimeId) || (playerLinkIds?.length > 0 && playerLinkIds.includes(element.playerLinkId));;
          return element;
        });
      } else {
        this.tableData = this.tableData.filter(element => {
          element.checked = this.popRes ? this.multiSelectPlayerIds.includes(element.playerId) : (element.scheduledTeeTimeId == this.data.scheduledTeeTimeId);
          return element;
        });
        this.tableData = [...this.tableData];
      }
    }   
    this._utilities.ToggleLoader(false);
  }

  checkedData(eve, fromOnInit = false) {
    if (this.canShow && this.checkInForm.controls.autoSelectPlayers.value) {
      this.mapCheckedData(fromOnInit);
    }    
  }

  unCheckedData(eve) {
    if (this.canShow && this.checkInForm.controls.autoSelectPlayers.value) { 
      console.log(eve);
      let playerLinkId = eve.value.playerLinkId;
      if (playerLinkId) {
        this.tableData.map(element => {
          if(playerLinkId === element.playerLinkId) {
            element.checked = false;
            return element;
          }       
        });
      } else {
        let scheduleTeeTimePlayerId = eve.value.playerId;
        this.tableData.map(element => {
          if(scheduleTeeTimePlayerId === element.playerId) {
            element.checked = false;
            return element;
          }       
        });
      }     
      this.tableData = [...this.tableData];
    }    
  }

  private mapCheckedData(fromOnInit) {
    let checkedItems = fromOnInit ?  this.tableData.filter(x => x.checked) : this.checkInCheckOutFormGroup.value.checkBoxItems?.filter(x => x.checkBox);
    let otherCategoryCheckedList = checkedItems?.filter(x => x.playerCategoryId != 1 &&  x.playerCategoryId != 0);
    let playerCategoryCheckedList = checkedItems?.filter(x => x.playerCategoryId == 1 || x.playerCategoryId == 0);
    if (playerCategoryCheckedList?.length > 0 || otherCategoryCheckedList?.length>0 ) {
      let checkedPlayerIds = playerCategoryCheckedList.map(x => x.playerId);
      let checkedLinkIds = otherCategoryCheckedList.map(x => x.playerLinkId);
      this.tableData.map(element => {
        if (!element.checked) {
          element.checked = (checkedPlayerIds.includes(element.playerId)) || (checkedLinkIds.includes(element.playerLinkId))
        }             
      });
    }
    this.tableData = [...this.tableData];    
  }
}
