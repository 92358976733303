<section class='guest-itnerary golf-container--padding'>
  <form [formGroup]='itneraryFormGrp' autocomplete="off">
    <div class='guest-itnerary-header'>
      <mat-button-toggle-group formControlName='searchParams'>
        <mat-button-toggle *ngFor='let button of selectionButtons' [value]="button"
          (change)='selectionButtonClick(button)'>{{button.value}}</mat-button-toggle>
      </mat-button-toggle-group>
      <div class='date-swipper-wrapper ag-ml-4 in-block'>
        <span class='date-swipper'>
          <i  aria-hidden="true" class="icon-left-arrow" (click)="changemonthdateYear('prev')">&nbsp;</i>
          <span>{{calenderValue}}</span>
          <i  aria-hidden="true" class="icon-right-arrow" (click)="changemonthdateYear('next')">&nbsp;</i>
        </span>
        <app-golf-date-picker [inputs]="dateInputs" (datePickerChange)='onDateChange($event)'></app-golf-date-picker>
      </div>
    </div>
  </form>
  <div class="golf-iteneray-content mt-4">
    <div *ngIf="itneraryFormGrp.value.searchParams.id == 1 " class="day-view">
      <table aria-describedby="tneraryform-table">
        <tbody>
          <tr *ngFor="let ltenary of itenaryarrayList">

            <td>
              <div class="date-round">
                <label class="textellipsis">{{ltenary.orgStartDateTime | localizeTime}}</label>
                <label class="textellipsis">{{ltenary.orgEndDateTime | localizeTime}}</label>
              </div>
            </td>
            <td>
              {{ltenary.packageName ? ltenary.packageName : ltenary.name}} <br>
              {{ltenary.platformActivityStatus ? ltenary.platformActivityStatus: ltenary.status}} <br>
              {{ltenary.activityLocation}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>   
    <div *ngIf="itneraryFormGrp.value.searchParams.id != 1 " class="month-view mr-3 h-100">
      <table class="w-100 mb-5" aria-describedby="itnerary-table">
        <thead>
          <tr>
            <th scope="col" class="borderBottomNone pl-2 pb-4"></th>
            <th scope="col" *ngFor="let timeList of timeIntervals">
              <div  class="pl-2 pb-4">
                <label class="text-uppercase">{{timeList.startTime | localizeTime}} - {{timeList.endTime| localizeTime}}</label>
              </div>
            </th>
          </tr>
        </thead>
       
        <tbody>
          <tr *ngFor="let sideBar of sidebarArray">            
            <td>
             
              <div class="date-round">
                <label *ngIf="itneraryFormGrp.value.searchParams.id == 2; else monthLabel" for="" class="text-capitalize">{{sideBar.short}}</label>
                <ng-template #monthLabel>
                  <label  for="" class="text-capitalize">{{sideBar}}</label>
                </ng-template>
              </div>
            </td>
            <td class="p-0 position-relative pr-2 pl-2 " *ngFor="let timeSlot of timeIntervals">
              <span class="d-block"
              *ngFor="let record of itenaryarrayList">{{record | itnerary: timeSlot :sideBar: itneraryFormGrp.value.searchParams.id: calenderDate: weekChgObj }}</span>
            </td>

          </tr>
        </tbody>
      </table>

    </div>
  </div>
</section>