<section class="block-tee-time golf-container--padding">
    <div class="time-details">
      {{time | localizeTime}} 
    </div>
    <div class="time-slot-selection">
      <mat-radio-group [attr.automationId]="'Rdo_BlockTeeTime_slotType'" [(ngModel)]="slotType" (ngModelChange)="slotSelectionTypeChange()">
        <mat-radio-button [value]="slotTypeConst.selectedSlot">{{captions.selectedSlots}}</mat-radio-button>
        <mat-radio-button [value]="slotTypeConst.allSlot" class="pl-4">{{captions.allSlots}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="players-section" >
      <div attr.automationId='Rdo_BlockTeeTime_selectPlayer{{i}}' *ngFor="let player of players; let i = index" class="player player-booked" (click)="selectUnselectPlayer($event, i)"
        [ngClass]="{'player-selected': player.selected, 'player-booked': player.playerId > 0 }" >
          <span *ngIf = "player.playerId > 0">{{player|formatName: 'firstName': 'lastName'}}</span>
          <span *ngIf = "player.playerId ===0">{{player.firstName}}</span>
        </div>
    </div>
    <div [formGroup]="blockTeeTimeform">
      <mat-form-field [floatLabel]="floatLabel" class="comment-section golf-form-control">
        <input attr.automationId='Txt_BlockTeeTime_bulkBlockHoldComment' matInput [placeholder]="captions.comment" formControlName="bulkBlockHoldComment" maxlength="100">
      </mat-form-field>
    </div>
  </section>
  