<section class="pop-container">
  <div class="pop-head">
    <label class="inner-header pl-4">{{data.headername | uppercase}}</label>
    <i [attr.automationId]="'Icn_shopDialogPopup_close'" aria-hidden="true" *ngIf="data.closebool" class="material-icons inner-close float-md-right cursPointer" (click)="onNoClick()">close</i>
  </div>
  <div class="pop-contents" [ngSwitch]="data?.templatename">
    <ng-template [ngSwitchCase]="'AC'">
      <app-assign-commission-popup></app-assign-commission-popup>
    </ng-template>
    <ng-template [ngSwitchCase]="'SD'">
      <shipping-info></shipping-info>
    </ng-template>
    <ng-template [ngSwitchCase]="'VR'">
      <app-void-reason></app-void-reason>
    </ng-template>
    <ng-template [ngSwitchCase]="'shopCashPayment'">
      <app-shop-payment [datainput]="data.data"></app-shop-payment>
    </ng-template>
    <ng-template [ngSwitchCase]="'GC'">
      <app-retail-gift-cards-form [config]="data.data"></app-retail-gift-cards-form>
    </ng-template>
    <ng-template [ngSwitchCase]="'GiftCardPayment'">
      <app-gift-card-payment-popup [datainput]="data.data"></app-gift-card-payment-popup>
    </ng-template>
    <ng-template [ngSwitchCase]="'CMS'">
      <app-cmspatron-selection-dialog [datainput]="data.data"></app-cmspatron-selection-dialog>
    </ng-template>
    <ng-template [ngSwitchCase]="'Wallet'">
      <app-walletcard-selection-dialog [datainput]="data.data"></app-walletcard-selection-dialog>
    </ng-template>
    <ng-template [ngSwitchCase]="'GCPin'">
      <app-gift-card-pinpopup></app-gift-card-pinpopup>
    </ng-template>
    <ng-template [ngSwitchCase]="'RTOS'">
      <app-return-settlement-info-dialog [datainput]="data.data"></app-return-settlement-info-dialog>
    </ng-template>
    <ng-template [ngSwitchCase]="'folio'">
      <app-folio-dialog-popup [datainput]="data.data"></app-folio-dialog-popup>
    </ng-template>
    <ng-template [ngSwitchCase]="'MultiPackRedeem'">
      <app-redeem-multipack-popup [datainput]="data.data"></app-redeem-multipack-popup>
    </ng-template>
  </div>
</section>
