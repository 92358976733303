import { Injectable } from '@angular/core';
import { API } from 'src/app/settings/golf-setup/custom-fields/custom-fields-model';
import { GolfManagementCommunication } from '../../communication/services/golfmanagement.service';

@Injectable()
export class CustomFieldDataService {
    constructor(private _http: GolfManagementCommunication) {

    }
    public async GetAllCustomFieldsAndValues(): Promise<API.CustomField[]> {
        return this._http.getPromise<API.CustomField[]>({
            route: GolfApiRoute.GetAllCustomFieldsAndValues
        });
    }
    public async GetCustomFieldAndValuesById(): Promise<API.CustomField> {
        return this._http.getPromise<API.CustomField>({
            route: GolfApiRoute.GetCustomFieldAndValuesById
        });
    }

    public async UpdateCustomFieldAndValues(customField: API.CustomField): Promise<API.CustomField> {
        return this._http.putPromise<API.CustomField>({
            route: GolfApiRoute.UpdateCustomFieldAndValues,
            body: customField
        });
    }
    public async UpdateCustomFieldsIsUtilized(customField: API.CustomField[]): Promise<API.CustomField> {
        return this._http.putPromise<API.CustomField>({
            route: GolfApiRoute.UpdateCustomFieldsIsUtilized,
            body: customField
        });
    }
    
}