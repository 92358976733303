<section id="individual-datacommon-wrapper" class="h-100">
    <ng-container>
        <app-dialog-header [automationId]="automationId" [title]="captions.lbl_purgeOrAnonymize" (closeDialog)="onCancel($event)">
        </app-dialog-header>
    </ng-container>
    <div class="table_wrapper ag_container--padding-md">
        <div class="item-container">
            <div class="item-list">
                <h4 class="ag_group--headers">{{captions.lbl_fields}}</h4>
                <!-- <div class="item-search ml-1">
                    <div class="item-title">{{captions.lbl_fields}}</div>
                </div> -->
                
                <div class="item-checkbox-group p-2">
                    <mat-checkbox attr.automationId='Chk_{{automationId}}_all' *ngIf="fieldList?.length>0" class="ag_w--25" [checked]='isAllSelected' (change)='onAllCheck($event)'>
                        {{captions.lbl_all}}
                    </mat-checkbox>
                    <mat-checkbox attr.automationId='Chk_{{automationId}}_{{item?.viewValue+j}}' [matTooltipClass]="'AgysMatCustTooltip'" matTooltip="{{item.viewValue}}" class="checkbox ag_w--25" *ngFor="let item of fieldList;let j = index" [disabled]="item.disabled" [checked]="item.checked"
                        (change)="onFieldsCheck($event,item)">{{item.viewValue}}
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </div>
    <div class="create-guest-footer ag_footer--actions">
        <app-button [automationId]="automationId"  [buttontype]="actionButton" (valueChange)='onSave($event)'></app-button>
        <app-button [automationId]="automationId"  [buttontype]="cancelButton" class="ag_ml--2" (valueChange)='onCancel($event)'></app-button>
    </div>
</section>