<div class="w-100 ag-pt-2 multi-search newVersionWrapper" [ngClass]="[selectedData.length > 0 ? 'set-chip-style': '', auto.isOpen ? 'show' : 'hide']">
  <div class='overlay-div'></div>
  <mat-form-field [floatLabel]="floatLabel" class="" appearance="outline" autocomplete="off">
    <mat-chip-list #chipList aria-label="Chip selection">
      <ng-container *ngFor="let data of selectedData;let i=index;">
        <mat-chip *ngIf="data" [selectable]="selectable" [removable]="removable" (removed)="remove(data, fromComponentNumber)">
          <span class="wordBreak" *ngFor="let key of selectedChipKey;let last=last;" [matTooltip]='data[key]'>
            {{data[key]}}
            <span *ngIf="!last" class="mr-2"> {{selectedChipKeySeperator}} </span>
          </span>
          <i  aria-hidden="true" class="icon-Cancel" matChipRemove *ngIf="removable" (click)="onRemove($event);" attr.automationId='Icn_{{automationId}}_cancel{{i}}'></i>
        </mat-chip>
      </ng-container>
      <input [placeholder]="placeholder" #chipInput [formControl]="chipCtrl" [matAutocomplete]="auto"
        [matAutocompleteDisabled]='isAutoCompleteDisabled' [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (input)="searchText(chipInput.value)" [matChipInputAddOnBlur]="addOnBlur" attr.automationId='Txt_{{automationId}}_chipInput'
        [disabled]="selectedData.length >= maxChips || disabled" [maxlength]="searchMaxCharLength" (blur)="onBlur($event)" (focus)="onFocus()">
      <i  *ngIf="!loader" aria-hidden="true" matSuffix (click)="iconClick($event)" class="icon-search"></i>
      <div *ngIf="loader && chipInput.value.length >= minimumCharacterSearchLength" id="chipsearch-cover-spin"></div>
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event, fromComponentNumber)">
      <mat-option *ngIf="filteredData?.length == 0 && chipInput.value.length >= 3 && !loader" disabled="true">
        {{this.captions.NoDataFound}}
      </mat-option>
      <mat-option *ngFor="let data of filteredData" [value]="data" [ngClass]="{'show-more-data': showMoreData}">
        <span class="tool-section" [matTooltip]="data|titlecompose:autoCompleteKeys:autoCompleteKeysSeperator">
          <span class="chip-search-text-ellipsis"  *ngFor="let key of autoCompleteKeys;let last=last; let index = index">
            {{data[key]}}
            <span *ngIf="key==='' && data[autoCompleteKeys[index + 1]] !==''">{{autoCompleteKeysSeperator}}</span>
          </span>
          <i *ngIf="data.isIconEnable" class="ag_ml--1 " [ngClass]="[iconclassName]"></i>
          <i *ngIf="data.isBlocked" class="ag_ml--1 " [ngClass]="[iconclassNameForBlockedGuest]" style="color: red !important;"></i>
        </span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
