import { Component, OnInit, Output, EventEmitter, AfterViewInit, ViewChild, OnDestroy, Inject, ViewEncapsulation} from '@angular/core';
import { TabsArray } from './create-player.model';
import { PlayerInformationComponent } from './player-information/player-information.component';
import { AdditionalInformationComponent } from './additional-information/additional-information.component';
import { HistoryComponent } from './history/history.component';
import { ItneraryComponent } from './itnerary/itnerary.component';
import { StepperService } from 'src/app/shared/components/stepper/stepper.service';
import { ButtonAction, DEFAULT_GUID } from 'src/app/shared/global.constant';
import { CreatePlayerService } from './create-player.service';
import { CreatePlayerBusiness } from './create-player.business';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabGroup, MatTab, MatTabHeader } from '@angular/material/tabs';
import { Subscription, ReplaySubject } from 'rxjs';
import { PlayerProfileDataService } from 'src/app/shared/data-services/golfmanagement/PlayerProfile.data.services';
import { GolfImageService } from 'src/app/shared/data-services/Image/golf.Image.service';
import { ImageDataService } from 'src/app/shared/data-services/Image/Image.data.services';
import { UntypedFormGroup } from '@angular/forms';
import { DefaultGUID } from 'src/app/retail/shared/globalsContant';
import { ContactService } from 'src/app/tee-time/tournaments/tournaments-modal/contact/contact.service';
import { SharedService } from 'src/app/shared/shared.service';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { takeUntil } from 'rxjs/operators';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { GuestPlayersService } from 'src/app/guest/players/players.service';
import { PlayersBusiness } from 'src/app/guest/players/players.business';
import { ButtonType } from 'src/app/common/shared/shared/globalsContant';
import { CommonItineraryDataService } from 'src/app/common/shared/shared/components/common-itinerary/common-itinerary.data.service';
import { ApplyPolicy } from 'src/app/common/consent-management/consent-management.model';
import { PolicyType } from 'src/app/common/shared/shared.modal';
import { ContactLogService } from 'src/app/common/contact-log/contact-log.service';
import * as Courses from 'src/app/settings/golf-setup/code-setup/course/create-course-modal/create-course-model';
import { Options } from 'src/app/dynamicforms/form-type.entity';
import { API } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import { Guid } from 'guid-typescript';
import { ContactLogWrapperComponent } from './contact-log-wrapper/contact-log-wrapper.component';
import { TeeSheetBusiness } from 'src/app/tee-time/tee-sheet/tee-sheet-booking/tee-sheet-booking.business';
import { TeeSheetSharedBusiness } from 'src/app/tee-time/shared/teesheet.shared.business';
import { GolfUtilities } from '../utilities/golf-utilities';
import { AlertType } from '../shared-models';
import  * as ButtonTypes from '../shared-models';
import { TeeTimeDataService } from '../data-services/golfschedule/TeeTime.data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TeeSheetService } from 'src/app/tee-time/tee-sheet/tee-sheet-booking/tee-sheet-booking-service';
import { TeeSheetSingleCourse } from 'src/app/tee-time/shared/tee-sheet/tee-sheet.singleCourse';
import { TeeSheetMultiCourse } from 'src/app/tee-time/shared/tee-sheet/tee-sheet.mulitCourse';
import { UserAccessModel } from '../data-services/authentication/useraccess-model.model';
import { UserAccessBusiness } from '../data-services/authentication/useraccess.business';
import { UserAccessBreakPoints } from '../constants/useraccess.constants';
import { EXTERNALGUESTINTERFACENAME } from 'src/app/common/constants';
@Component({
  selector: 'app-create-player',
  templateUrl: './create-player.component.html',
  styleUrls: ['./create-player.component.scss'],
  providers: [CreatePlayerBusiness, CreatePlayerService, PlayerProfileDataService,
    ImageDataService, GolfImageService, GuestPlayersService, PlayersBusiness, ContactService,
    SharedService, CommonItineraryDataService,ContactLogService,UserAccessBusiness,
    TeeTimeDataService, TeeSheetBusiness, TeeSheetService,TeeSheetSingleCourse, TeeSheetMultiCourse],
    encapsulation: ViewEncapsulation.None
})
export class CreatePlayerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  tabsArrayDatas: TabsArray;
  captions: any;
  PlayerInfoInput: any;
  PlayerInfoFormData: any; //dynamic  data
  PlayerInfoFormnInputData: any; //dynamic  data
  AdditionalInfoFormData: any; //dynamic  data
  PlayerContactLogInputList:any;
  PlayerContactLogFormList : any;
  HistoryFormData: any;
  ItneraryFormData: any;
  saveSubscriber: Subscription;
  selectedIndex = 0;
  @Output() notifyParent = new EventEmitter();
  PlayerInfoFormInputData: any;
  playerForm: UntypedFormGroup;
  additionalForm: UntypedFormGroup;
  contactLogForm: UntypedFormGroup;
  isEdit: boolean;
  updateExistingPaymentReferenceId: boolean;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  cmsPatronId: any;
  guestId : any;
  IsGDPREnabled : boolean = false;
  policyType : number = 0;
  removeCardDetail: boolean = false;
  prodId:number;
  courses$: Promise<Options[]>;
  course: Courses.Course[] = [];
  allocationBlocks: API.TeeTimeAllocationBlock[] = [];
  isPlayerEdited : boolean = false;
  isExpand: boolean = false;
  rentalRetailItems = [];

  constructor(public _CreatePlayerBusiness: CreatePlayerBusiness, public _utilities: GolfUtilities,
    private _stepperService: StepperService, public _imageService: GolfImageService,
    public _createPlayerService: CreatePlayerService, private _localization: RetailLocalization,
    private _sharedService: SharedService, private _featureSwitchService: RetailFeatureFlagInformationService,
    @Inject(MAT_DIALOG_DATA) public dialogData,private dialogReference: MatDialogRef<CreatePlayerComponent>,
    private _router: Router,  private _route: ActivatedRoute, public _userAccessBusiness: UserAccessBusiness) { }

  ngOnInit() {
    var data = this.dialogData.componentDetails.popUpDetails.bindData;
    this._stepperService.isSaveSubmitDisabled = this._stepperService.isSubmitted = data && data.isExternalGuest && data.id == DefaultGUID && !data.interfaces?.find(i => i.name === this._localization.GetPropertyConfig(EXTERNALGUESTINTERFACENAME))?.isSubscribed;
    this._CreatePlayerBusiness.disableGuestLink = data && data.isExternalGuest && data.interfaces?.find(i => i.name === this._localization.GetPropertyConfig(EXTERNALGUESTINTERFACENAME))?.isSubscribed;
    if (data) {
      this.guestId = data.id;
      if(data.consentPolicyId > 0)
      {
        this.getPolicyTypebyPolicyId(data.consentPolicyId);
      }
    }
    this.prodId =Number(this._localization.GetsessionStorageValue('propertyInfo', 'ProductId'));
    this.captions = this._CreatePlayerBusiness.GetCaptions();
    this.isPlayerEdited = this.dialogData.title === this.captions.editPlayers;
    this._createPlayerService.isEdit = this.isEdit = false;
    this.tabsArrayDatas = [
      { label: this.captions.personalInformation, component: PlayerInformationComponent, link: 'personalInformation' },
      { label: this.captions.additionalInformation, component: AdditionalInformationComponent, link: 'additionalInformation' },
      { label: this.captions.history, component: HistoryComponent, link: 'history' },
      { label: this.captions.itnerary, component: ItneraryComponent, link: 'itnerary' },
      { label: this.captions.contactLog, component: ContactLogWrapperComponent, link: 'contactLog' }
    ];
    this.notifyParent.emit(false);
    this._sharedService.loaderEnable.pipe(takeUntil(this.$destroyed)).subscribe(loader => {
      const loadingContainer = document.getElementById('custom-cover-spin');
      const loadingContainerMessage = document.getElementById('custom-cover-message');
      if (loadingContainer && loadingContainerMessage) {
        if (loader) {
          loadingContainer.style.display = 'block';
        }
        else {
          loadingContainer.style.display = 'none';
        }
      }
    });
    const cmsPatronId = this.dialogData.componentDetails.popUpDetails.cmsPatronId;
    if (cmsPatronId) {
      this._createPlayerService.isEdit = this.isEdit = false;
      this.cmsPatronId = cmsPatronId;
    }
    // this.getCourses();
    // this.getAllocationBlocks();
  }

  ngAfterViewInit() {
    this.tabGroup._handleClick = this.handleTabChange.bind(this);
    this.saveSubscriber = this._stepperService.valueChange.subscribe(x => {
      if (x.type === ButtonAction.save || x.type === ButtonAction.update) {
        this.submitForm(x);
      } 
      else if(x.type == ButtonAction.link || x.type === ButtonAction.unlink)
      {
        this.save(x);
      }
    });
    this.bindApiData();
  }

  ngOnDestroy() {
    if (this.saveSubscriber) {
      this.saveSubscriber.unsubscribe();
      this._stepperService.tab = false;
    }


  }

  async bindApiData() {
    const data = this.dialogData.componentDetails.popUpDetails.bindData;
    if (data) {
      let isExistingGuestDataChanged: boolean;
      if (data.patronid && this._featureSwitchService.IsCMSConfigured) {
        isExistingGuestDataChanged = await this._CreatePlayerBusiness.UpdateCMSDetailOnExistingGuest(data);
        setTimeout(() => this.notifyParent.emit(isExistingGuestDataChanged), 1);

      }
      this._createPlayerService.isEdit = this.isEdit = true;
      this.PlayerInfoInput = data;
      this.PlayerInfoFormData = data;
      this.PlayerContactLogInputList = data?.playerContactLogList !=null ? data?.playerContactLogList : [];
    }
    this.setGdprConfiguredFlag();
  }
  setGdprConfiguredFlag()
  {
    this._CreatePlayerBusiness.getIsGdprConfiguredFlag().then(
      res=>
      {
        this.IsGDPREnabled = !!res;
      }
    );   
  }
  getPolicyTypebyPolicyId(consentPolicyId : number)
  {
    this._CreatePlayerBusiness.getPolicyTypeUsingPolicyId(consentPolicyId).then(
      res=>
      {
        this.policyType = res;
      }
    );
  }
  handleTabChange(tab?: MatTab, tabHeader?: MatTabHeader, idx?: number) {
    this.selectedIndex = idx;
    if(this.dialogData.componentDetails.popUpDetails.bindData?.isPurged){
      this.playerForm = this.additionalForm;
    }
    this.notifyParent.emit(false);
  }

  async submitForm(obj: any) {
    const data = this.dialogData.componentDetails.popUpDetails.bindData;    
    const existingPaymentReferenceId = data && data != null && data.paymentReferenceId ? data.paymentReferenceId : 0;
    const paymentReferenceId = this.AdditionalInfoFormData ? this.AdditionalInfoFormData.paymentReferenceID : 0;
    if(existingPaymentReferenceId != 0 && paymentReferenceId != 0 && existingPaymentReferenceId != paymentReferenceId)
    {
      this._utilities.showAlert(this._localization.captions.common.UpdateCardOnFile, AlertType.Info, ButtonTypes.ButtonType.YesNo, (res) => {
        if (res === ButtonTypes.AlertAction.YES) {
          this.updateExistingPaymentReferenceId = true;
        }        
        this.save(obj);     
      })       
    }
    else if(existingPaymentReferenceId != 0 && paymentReferenceId == 0)  
    {
      this._utilities.showAlert(this._localization.captions.common.RemoveCardOnFile, AlertType.Info, ButtonTypes.ButtonType.YesNo, (res) => {
        if (res === ButtonTypes.AlertAction.YES) {
          this.updateExistingPaymentReferenceId = true;
        }        
        this.save(obj);     
      }) 
    } 
    else
    {
      this.save(obj);
    }    
  }
  async clickAction(obj: any)
  {
  await this.save(obj)
  }
  async save(obj: any)
  {
    let action: ButtonAction = obj.type;
    this.PlayerInfoFormData.paymentReferenceId = this.AdditionalInfoFormData ? this.AdditionalInfoFormData.paymentReferenceID : 0;
    this.PlayerInfoFormData.updateExistingPaymentReferenceId = this.updateExistingPaymentReferenceId;
    this.PlayerInfoFormData.id = this.PlayerInfoInput ? this.PlayerInfoInput.id : "";
    this.PlayerInfoFormData.lastChangeId = this.PlayerInfoInput ? this.PlayerInfoInput.lastChangeId : "";
    this.PlayerInfoFormData.interfaceGuestId = this.PlayerInfoInput ? this.PlayerInfoInput.interfaceGuestId : 0;
    this.PlayerInfoFormData.imageReferenceId = DefaultGUID;
    this.PlayerInfoFormData.consent = this.AdditionalInfoFormData && this.AdditionalInfoFormData.guestPolicyDetail ? this.AdditionalInfoFormData.guestPolicyDetail.consentDate : '';
    this.PlayerInfoFormData.consentPolicyId = this.AdditionalInfoFormData && this.AdditionalInfoFormData.guestPolicyDetail ? this.AdditionalInfoFormData.guestPolicyDetail.policyId : 0;
    this.PlayerInfoFormData.consentExpiryDate = this.AdditionalInfoFormData && this.AdditionalInfoFormData.guestPolicyDetail ? this.AdditionalInfoFormData.guestPolicyDetail.consentExpiryDate :'' ;
    this.PlayerInfoFormData.comments = this.AdditionalInfoFormData && this.AdditionalInfoFormData.guestPolicyDetail ? this.AdditionalInfoFormData.guestPolicyDetail.comments : '';
    this.PlayerInfoFormData.vipType = this.AdditionalInfoFormData && this.AdditionalInfoFormData.vipType;
    this.PlayerInfoFormData.platformBussinessCardRevUuid = this.PlayerInfoInput ? this.PlayerInfoInput.platformBussinessCardRevUuid : null;
    this.PlayerInfoFormData.platformBussinessCardUuid = this.PlayerInfoInput ? this.PlayerInfoInput.platformBussinessCardUuid : null;
    this.PlayerInfoFormData.platformGuestUuid = this.PlayerInfoInput ? this.PlayerInfoInput.platformGuestUuid : null;
    this.PlayerInfoFormData.platformRevUuid = this.PlayerInfoInput ? this.PlayerInfoInput.platformRevUuid : null;
    this.PlayerInfoFormData.isExternalGuest = this.PlayerInfoInput ? this.PlayerInfoInput.isExternalGuest : false;
    this.PlayerInfoFormData.playerContactLogList = this.PlayerContactLogFormList ? this.PlayerContactLogFormList : [];
    this.PlayerInfoFormData.externalReferenceNumber =  this.PlayerInfoInput ? this.PlayerInfoInput.externalReferenceNumber : null;

    try {
      this._utilities.ToggleLoader(true);
      var createPromise = await this._CreatePlayerBusiness.SavePlayer(this.PlayerInfoFormData, action);
      this.isExpand = createPromise ? false : this.isExpand;
    }
    catch (err) {
      this._utilities.ToggleLoader(false);
      if (err && err.error) {
        let errMsg = this._localization.getError(err.error.errorCode);

        if (err.error.errorCode == 310002) {
          await this._utilities.ShowError(this._localization.captions.common.Warning, errMsg, ButtonType.Ok)
            .afterClosed().toPromise();
          obj.dialogRef.close(["clientReload", this.PlayerInfoFormData.id, this.PlayerInfoFormData.platformGuestUuid]);
          this._stepperService.tab = false;
          return;
        }
        if(err.error.errorCode == 310001)
        {
          await this._utilities.ShowError(this._localization.captions.common.Error, errMsg, ButtonType.Ok)
          .afterClosed().toPromise();
          return;
        }
        else {
          this._utilities.showError(errMsg);
        }
      } else {
        this._utilities.showError(this._localization.getError(-2));
      }

    }
    if (this.PlayerInfoInput && this.PlayerInfoInput.id && this.PlayerInfoInput.imageId != '' && this.PlayerInfoInput.id != '00000000-0000-0000-0000-000000000000' || (this.PlayerInfoFormData && this.PlayerInfoFormData?.id && this.PlayerInfoFormData.id != "" && this.PlayerInfoFormData.id != DEFAULT_GUID &&this.PlayerInfoFormData.isImageRemoved)) {
      await this._imageService.updateItemImage(createPromise.toString(), this.PlayerInfoInput?.imageId, this.PlayerInfoFormData?.imageReferenceId, this.PlayerInfoFormData?.isImageRemoved, this.PlayerInfoFormData?.base64textString, this?.PlayerInfoFormData?.thumbnailImg);
    }
    else if (this.PlayerInfoFormData.base64textString && !this.PlayerInfoFormData.isImageRemoved) {
     await this._imageService.saveImage(createPromise.toString(), this.PlayerInfoFormData.base64textString, this.PlayerInfoFormData.thumbnailImg);
    }
    if(this.IsGDPREnabled && this.PlayerInfoFormData.consentPolicyId != 0 && (this.PlayerInfoFormData.id == '' || this.PlayerInfoFormData.id == '' || this.PlayerInfoFormData.id == '00000000-0000-0000-0000-000000000000'))
    {
      let applyPolicy : ApplyPolicy = {
        guestId: createPromise.toString(),
        consentDate : this.PlayerInfoFormData.consent,
        consentExpiryDate : this.PlayerInfoFormData.consentExpiryDate,
        policyId : this.PlayerInfoFormData.consentPolicyId,
        policyType: PolicyType.ConsentPolicy
      }
      this._CreatePlayerBusiness.updatePolicyDetailsForGuestId(applyPolicy);
    }
    this._utilities.ToggleLoader(false);
    if (this._stepperService.closeplayerPopUp) {
      obj.dialogRef.close([obj.type, createPromise]);
      this._stepperService.tab = false;
    }
  }

  getCreatePlayerFormEmit(e) {
    switch (e[2]) {
      case 'playerInfo':
        this.PlayerInfoFormData = e[0];
        this.playerForm = e[3];
        break;
      case 'additionalInfo':
        this.AdditionalInfoFormData = e[0];
        this.additionalForm = e[3];
        break;
      case 'history':
        this.HistoryFormData = e[0];
        break;
      case 'itnerary':
        this.ItneraryFormData = e[0];
        break;
      case 'contactLog':
        this.PlayerContactLogFormList = e[0];
        this.contactLogForm = e[1];
        break;  

    }
    if(this.dialogData.componentDetails.popUpDetails.bindData?.isPurged) {
      this.notifyParent.emit(false);
      
    }
    else {
      if(this.playerForm && this.playerForm.valid && (this.additionalForm?.dirty || 
        this.playerForm.dirty || this.contactLogForm)) {
          this.notifyParent.emit(true);
        }
      else {
        this.notifyParent.emit(false);
      }
    }
  }

  removeCardDetails(event)
  {
    this.removeCardDetail = true;
  }
  onImageUpdates(isPlayerFormValid) {
    this.notifyParent.emit(isPlayerFormValid);
  }

  tabChanged(event) {
    switch (event.index) {
      case 0: (this.isEdit || this._createPlayerService.isEdit) ? 
      this.notifyParent.emit(this.playerForm && this.playerForm.valid && (this.playerForm.dirty || this.additionalForm?.dirty || this.contactLogForm)
        &&  !this.dialogData.componentDetails.popUpDetails.bindData?.isPurged) 
      : this.notifyParent.emit(this.playerForm && this.playerForm.valid  && (this.playerForm.dirty || this.additionalForm?.dirty || this.contactLogForm) ); 
      break;
      case 1: (this.isEdit || this._createPlayerService.isEdit) ? 
      this.notifyParent.emit(this.playerForm && this.playerForm.valid && (this.additionalForm?.dirty || this.playerForm.dirty || this.contactLogForm)
        &&  !this.dialogData.componentDetails.popUpDetails.bindData?.isPurged) 
      : this.notifyParent.emit(this.playerForm && this.playerForm.valid  && (this.playerForm.dirty || this.additionalForm?.dirty || this.contactLogForm)); 
      break;
      default: (this.isEdit || this._createPlayerService.isEdit) ? 
      this.notifyParent.emit(this.playerForm && this.playerForm.valid && (this.additionalForm?.dirty || this.playerForm.dirty || this.contactLogForm)
        &&  !this.dialogData.componentDetails.popUpDetails.bindData?.isPurged) 
      : this.notifyParent.emit(this.playerForm && this.playerForm.valid  && (this.playerForm.dirty || this.additionalForm?.dirty || this.contactLogForm)); 
      break;
    }
  }

  convertToEdit(data) {
    this.isEdit = true;
    this.dialogData.title = this.captions.editPlayers;
    this.dialogData.update = this._localization.captions.settings.editPopSave;
    this.dialogData.actionType = ButtonAction.update;
    this.dialogData.componentDetails.popUpDetails.bindData = data;
    this.bindApiData();
  }

  async openGuestTeeTime(eve) {
    if (eve) {
      if (this.playerForm.dirty) {
        this._utilities.showAlert(this.captions.WarningMessage, AlertType.Warning, ButtonTypes.ButtonType.YesNo, (res) => {
          if (res === ButtonTypes.AlertAction.YES) {
            this.dialogReference.close();
            this._router.navigateByUrl(`/tee-time/teesheet/teeSheet`, { 
              state: {activityId:eve}
            }); 
          } else {
            return;
          }   
        });
      }  else {
        this.dialogReference.close();
        this._router.navigateByUrl(`/tee-time/teesheet/teeSheet`, { 
          state: {activityId:eve}
        }); 
      }    
    }    
  }

  mapper(data) {
    let obj = {
      allocation: data.allocationBlockId,
      allocationDateTime: data.allocatedDateTime,
      comments: data.comment,
      course: data.courseId,
      createdDateTime: data.createdDateTime,
      hole: data.holeNumber,
      holes: data.holeNumber, // 1 or 18 holes      
      id: Guid.create().toString(),   // guid
      originalHoleNumber: data.holeNumber,
      playerDetail: data.teeTimePlayerDetails,
      scheduledTeeTimeId: data.id,
      slotBackgroundInfo: data?.slotBackgroundInfo,
      status: data.scheduleStatus,   // by default
      teeGridSlotId: data?.teeGridSlotId,      
      time: data.scheduleDateTime, // ISO datetime string      
      type: data.scheduleType,  
      unmodifiedSlotData: this.bindUnmodifiedSlot(data),  // to be replaced after move , copy
      viewedByStarter: data.viewedByStarter, 
      isFromSearch: false,
      isFromTeeSheetForCancel:false
    }
    return obj;
  }

  bindUnmodifiedSlot(data) {
    return {
      allocation: this.allocationBlocks.filter(x => x.id === data.allocationBlockId)[0],
      allocationDateTime: data.allocatedDateTime,
      comments: data.comment,
      course: this.course.filter(x => x.id === data.courseId)[0],
      hole: data.hole,
      holes: data.holeNumber,
      id: 0,
      isDropAllowed: true,
      originalHoleNumber: "1",
      playerDetail: data.teeTimePlayerDetails,
      slotBackgroundInfo: {id: 8, name: 'Peaky blinders', colorCode: '#8e3333', icon: '', displayOnTeesheet: false},
      status: {name: 'Booked', icon: '', colorCode: '', scheduleStatus: 1},
      teeGridSlotId: data.teeGridSlotId,
      time:data.scheduleDateTime,
      type:  data.scheduleType,  
      viewedByStarter: data.viewedByStarter
    }
  }

  expand(){
    this.isExpand = !this.isExpand
  }
  
  // async getCourses() {
  //   this.courses$ = this._TeeSheetBusiness.getCourses();
  //   const allUserPermissibleCourses = await this.courses$;
  //   this.course = this._TeeSheetBusiness._courses;
  // }

  // async getAllocationBlocks() {
  //   this._TeeSheetBusiness.GetAllocationBlocks(false).then(result => {
  //     this.allocationBlocks = result;
  //   });
  // }

  async getRentalItems(eve) {
    var retails = await this._CreatePlayerBusiness.getScheduledPlayerRetailItemsByActivityId([eve.activityId]);
    this.rentalRetailItems = [...retails];
  }
}
