import { Injectable } from '@angular/core';
import { ItneraryService } from './itnerary.service';
import moment from 'moment';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { PlayerProfileDataService } from 'src/app/shared/data-services/golfmanagement/PlayerProfile.data.services';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { Itinerary, ActivityResponse, ActivityStatus } from './itnerary.model';
import { DEFAULT_GUID } from 'src/app/shared/global.constant';

@Injectable()
export class ItneraryBusiness {

  public itenaryarrayList: any = [];
  public itenaryarrayListFull: Itinerary[] = [];
  itenaryArray = [];
  private _thisRef = this;
  public calenderDate: Date;

  constructor(private _ItneraryService: ItneraryService,
    private _Utilities: GolfUtilities,
    private _playerProfileDataService: PlayerProfileDataService,
    private localization: GolfLocalization) { }

  GetCaptions() {
    return this._ItneraryService.getCaptions();
  }

  GetSelectionButtons() {
    return this._ItneraryService.getSelectionButtons();
  }

  async DateChanged(e, calenderValue, playerId) {
    let calenderDate = moment(calenderValue, 'DD MMM, YYYY').add(e, 'days').toDate();
    calenderValue = moment(calenderValue, 'DD MMM, YYYY').add(e, 'days').format('DD MMM, YYYY');
    calenderDate = this._thisRef._Utilities.GetDateWithoutTime(calenderDate);
    let fromDate = calenderDate;
    await this.GetItneraryforPlayer(playerId,  fromDate, fromDate.addDays(1)).then(()=>{
      this.itenaryarrayListFull = this.itenaryArray;
    });

    return {
      value: calenderValue,
      array: []
    };
  }

  async WeekChanged(e, inputWeekStart, inputWeekEnd, playerId) {
    let calenderValue;
    let outputWeekStart;
    let outputWeekEnd;
    calenderValue =
      `${moment(inputWeekStart).startOf('week').add(e, 'weeks').format('DD MMM, YYYY')} -
        ${moment(inputWeekEnd).endOf('week').add(e, 'weeks').format('DD MMM, YYYY')}`;
    outputWeekStart = moment(inputWeekStart).startOf('week').add(e, 'weeks').toDate();
    outputWeekEnd = moment(inputWeekEnd).endOf('week').add(e, 'weeks').toDate();
    await this.GetItneraryforPlayer(playerId, outputWeekStart, outputWeekEnd.addDays(1)).then(() =>{
    this.itenaryarrayListFull = this.itenaryArray;
    });
    return {
      value: calenderValue,
      weekStart: outputWeekStart,
      weekEnd: outputWeekEnd,
      array: []
    };
  }

  async MonthChanged(e, inputDate, playerId) {
    let calenderValue;
    let calenderDate;    
    let sidebarArray;
    let fromDate; let toDate;
    calenderValue = moment(inputDate, 'MMM, YYYY').add(e, 'months').format('MMM, YYYY');
    calenderDate = moment(calenderValue, 'MMM, YYYY').toDate();    
    sidebarArray = this.GetSideBarArray(calenderDate);
    fromDate = calenderDate;
    toDate = moment(fromDate).endOf('month').toDate().addDays(1);
    await this.GetItneraryforPlayer(playerId, fromDate, toDate).then(() => {
      this.itenaryarrayListFull = this.itenaryArray;
    });
    return {
      value: calenderValue,
      sidebarArray,
      calenderDate
    };
  }

  GetTimeInterval(fromDate, intervalInHH, loopCount) {
    const timeArr = [];
    for (let count = 0; count <= loopCount; count++) {
      const startCount = count;
      const endCount = startCount + intervalInHH;
      const startTime = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), (fromDate.getHours() + startCount), 0, 0);
      const endTime = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), (fromDate.getHours() + endCount), 0, 0);
      timeArr.push({ startTime, endTime });
    }
    return timeArr;
  }


  GetAllItneraryDetails() {
    return this.itenaryArray;
  }

  GetDateDisplayFormat(date) {
    return moment(date).format('DD MMM, YYYY');
  }

  GetSideBarArray(date) {
    const monthinDays = moment(date).daysInMonth();
    const sidebarArray = [];
    for (let index = 0; index < monthinDays; index++) {
      const dayCountIndex = index + 1;
      const dayCount = ('0' + dayCountIndex).slice(-2);
      sidebarArray.push(dayCount);
    }
    return sidebarArray;
  }


  GetDays() {
    return this.localization.getDaysModel(false);
  }

  async GetItneraryforPlayer(playerId: string, from: Date = null, to: Date = null) {
    if(playerId && playerId != DEFAULT_GUID){
      var fromDateToAPI = from ? this.localization.convertDateObjToAPIdate(from):'',
      toDatetoAPI = to ? this.localization.convertDateObjToAPIdate(to) : '';
      let clientIteneraries:ActivityResponse[] = await this._playerProfileDataService.getItneraryforPlayer(playerId, fromDateToAPI, toDatetoAPI);
      clientIteneraries = clientIteneraries.filter(a=> a.status != ActivityStatus.Cancelled);
      this.itenaryArray = [];
      for (const clientItenerary of clientIteneraries) {
        this.itenaryArray.push({
          id: clientItenerary.id,
          name: clientItenerary.description,
          status: clientItenerary.status,
          start: this.localization.LocalizeShortDateTime(clientItenerary.startDateTime),
          end: this.localization.LocalizeShortDateTime(clientItenerary.endDateTime),
          packageName: clientItenerary.packageName ? clientItenerary.packageName : clientItenerary.description,
          orgStartDateTime: this.localization.getDate(clientItenerary.startDateTime),
          orgEndDateTime: this.localization.getDate(clientItenerary.endDateTime),
          platformActivityStatus: clientItenerary.platformActivityStatus ? clientItenerary.platformActivityStatus : '',
          activityLocation :clientItenerary.activityLocation ? clientItenerary.activityLocation : ''
        });
      }
      this.itenaryarrayListFull = this.itenaryArray;
    }
  }
}
