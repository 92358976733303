<ng-container [formGroup]="contactForm">
  <div class="ag-address position--relative" [formArrayName]="arrayName"
    *ngFor="let addressDetail of contactForm.get(arrayName)['controls']; let i = index; let first = first">
    <div [formGroupName]="i">
      <mat-form-field [floatLabel]="floatLabel" [ngClass]="customClass">
        <input attr.automationId='Txt_{{automationId}}_bindAddressFromGoogle{{i}}'  matInput *ngIf="first" ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
          (onAddressChange)="bindAddressFromGoogle($event,true,i)" [maxlength]="255" id="AddressInput"
          [formControlName]="controlName" [placeholder]="placeHolder">
        <input attr.automationId='Txt_{{automationId}}_bindAddressFromGoogle{{i}}' matInput *ngIf="!first" (onAddressChange)="bindAddressFromGoogle($event,true,i)" [maxlength]="255"
          id="AddressInput{{i}}" [formControlName]="controlName" [placeholder]="placeHolder">
      </mat-form-field>
      <span attr.automationId='Btn_{{automationId}}_addAddress{{i}}' class="icon-plus" [ngClass]="IconDisableClass ? 'golf-section__disable' : ''" (click)="addAddress('')"
        *ngIf="first && addresslength != 3"></span>
      <span attr.automationId='Btn_{{automationId}}_removeAddress{{i}}' class="icon-minus" [ngClass]="IconDisableClass ? 'golf-section__disable' : ''" (click)="removeAddress(i)" *ngIf="!first"></span>
    </div>
  </div>
</ng-container>