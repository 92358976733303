import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplyPolicy } from 'src/app/common/consent-management/consent-management.model';
import { DataPurge } from 'src/app/common/data-retention/data-retention.model';
import { AlertAction, AlertType, ButtonTypes } from 'src/app/common/enums/shared-enums';
import { GuestPolicyDetail } from 'src/app/common/shared/shared.modal';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { PlayerProfileDataService } from 'src/app/shared/data-services/golfmanagement/PlayerProfile.data.services';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';

@Component({
  selector: 'app-guest-policy-wrapper',
  templateUrl: './guest-policy-wrapper.component.html',
  styleUrls: ['./guest-policy-wrapper.component.scss'],
})
export class GuestPolicyWrapperComponent implements OnInit {
  captions: any;
  guestPolicyDetail : GuestPolicyDetail;
  constructor(private _localization: GolfLocalization, private dialogRef: MatDialogRef<any>, private _playerProfileDataService: PlayerProfileDataService
    , @Inject(MAT_DIALOG_DATA) public dialogData , private _utils: GolfUtilities) {
    this.captions = this._localization.captions;
    this.guestPolicyDetail = dialogData.guestPolicyDetail as GuestPolicyDetail;
  }
  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close();
  }
  onCancel(e) {
    this.dialogRef.close();
  }
  updatePolicyDetailsForGuestId(applyPolicy: ApplyPolicy) {
    let successMsg = this._localization.replacePlaceholders(this.captions.consentPolicyEnabled, ['consentPolicy'], ['']);
    if(applyPolicy.guestId == undefined || applyPolicy.guestId == '')
    {
      this._utils.showCommonAlert(successMsg, AlertType.WellDone, ButtonTypes.Ok, async (res) => {
        if (res === AlertAction.CONTINUE) {
          this.dialogRef.close(applyPolicy);
        }
      })
    }
    else{
      this._playerProfileDataService.UpdatePolicyDetailsForGuestId(applyPolicy).then(x => {
        this._utils.showCommonAlert(successMsg, AlertType.WellDone, ButtonTypes.Ok, async (res) => {
          if (res === AlertAction.CONTINUE) {
            this.dialogRef.close(applyPolicy);
          }
        })
      });
    }
  }
  applyDataPolicyPurgeForGuestId(dataPurge: DataPurge) {
    let success = false;
    let dataPurgingCompleted = this._localization.replacePlaceholders(this.captions.lbl_dataPurgingCompleted, ['okay'], [this.captions.common.okay]);
    this._utils.showCommonAlert(this.captions.lbl_doYouWantToPurge, AlertType.CustomDefault, ButtonTypes.YesNo, async (res) => {
      if (res === AlertAction.YES) {
        await this._playerProfileDataService.ApplyDataPolicyPurgeForGuestId(dataPurge).then(x => {
          if (x) {
            success = true;
            this._utils.showCommonAlert(dataPurgingCompleted, AlertType.WellDone, ButtonTypes.Ok, async (res) => {
              if (res === AlertAction.CONTINUE) {
                this.dialogRef.close(success);
              }
            });
          }
          else {
            this._utils.showCommonAlert(this.captions.lbl_ErrWilePurging, AlertType.Warning, ButtonTypes.Ok);
            this.dialogRef.close(success);
          }
        });

      }
    })
  }
  exportSendMail(value) {

  }
}
