import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from "@angular/forms";

@Directive({
  selector: '[numberMaxlength]'
})
export class NummaxLength {
  public numberValue: any;
  private minValue: number;
  private maxValue: number;

  constructor(el: ElementRef,private control: NgControl) {
    this.numberValue = el.nativeElement;
    this.minValue = parseInt(el.nativeElement.min);
    this.maxValue = parseInt(el.nativeElement.max);
  }

  @HostListener('keydown', ['$event']) keydown($event: KeyboardEvent) {
    let arrayKeys: any = [27, 13, 110, 190, 17, 86]; //removed 9 from array -  for tab fix
    let server = arrayKeys.find(x => x == $event.keyCode);
    if (server ||
      (($event.keyCode == 65 || $event.keyCode == 86 || $event.keyCode == 67) && ($event.ctrlKey === true || $event.metaKey === true)) ||
      ($event.keyCode >= 35 && $event.keyCode <= 40)) {
      $event.preventDefault();
    }
    if (($event.shiftKey || ($event.keyCode < 48 || $event.keyCode > 57)) && ($event.keyCode < 96 || $event.keyCode > 105) && ([9, 8, 46].indexOf($event.keyCode) == -1)) {
      $event.preventDefault();
    }

  }
  @HostListener('paste', ['$event']) blockPaste(e: ClipboardEvent) {
    let valuePasted = e.clipboardData.getData('text/plain');
    if (this.ValidateNumbers(valuePasted)) {
      if (parseInt(valuePasted) > this.maxValue) {
        e.preventDefault();
      }
    }
  }

  @HostListener('input', ['$event']) oninput(event) {
    let value = event.target.value;
    // Added length check condition to support backspace
    if (value.length > 0) {
      if (this.minValue && value < this.minValue) {
        event.currentTarget.value = this.minValue;
      }
      else if (this.maxValue && value > this.maxValue) {
        event.currentTarget.value = value.slice(0, value.length - 1);        
        this.control.control.setValue('');
      }
    }
  }

  ValidateNumbers(text) {
    let reg = /^-?[\d]+$/;
    return reg.test(String(text).toLowerCase())
  }

} 