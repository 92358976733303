import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { StepperService } from 'src/app/shared/components/stepper/stepper.service';
import { ButtonAction } from 'src/app/shared/global.constant';
import { AlertType, ButtonType, DialogCloseObj } from 'src/app/shared/shared-models';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { DeleteTeeTimeSlotBusiness } from './delete-tee-time-slot.business';
import { Caption, DeleteTeeTimeSlotModel, ModalInfo } from './delete-tee-time-slot.modal.model';
import { DeleteTeeTimeSlotService } from './delete-tee-time-slot.service';

@Component({
  selector: 'delete-tee-time-slot-modal',
  templateUrl: './delete-tee-time-slot-modal.component.html',
  styleUrls: ['./delete-tee-time-slot-modal.component.scss'],
  providers: [DeleteTeeTimeSlotBusiness, DeleteTeeTimeSlotService]
})
export class DeleteTeeTimeSlotModalComponent implements OnInit {
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  currentCourseSlotInfo: DeleteTeeTimeSlotModel;
  isOpenSlotsNotExistOnShowMore: boolean = false;
  isShowAllOpenSlotChecked: boolean = false;
  modalHeaderInfo: ModalInfo[];
  objCaption: Caption;
  openedSlotDetails: DeleteTeeTimeSlotModel[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any,
    private _deleteTeeTimeSlotBusiness: DeleteTeeTimeSlotBusiness,
    private _deleteTeeTimeSlotService: DeleteTeeTimeSlotService,
    private stepperService: StepperService,
    private _utilities: GolfUtilities,
    private _localatization: GolfLocalization
  ) { }

  async ngOnInit(): Promise<void> {
    await this.OnInit();
    this.OnSaveTrigger();
  }
  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }
  private async OnInit() {
    this.objCaption = this._deleteTeeTimeSlotBusiness.GetCaption();
    this.modalHeaderInfo = this._deleteTeeTimeSlotBusiness.GetModalHeaderInfo(this.dialogData.info);
    this.currentCourseSlotInfo = this._deleteTeeTimeSlotBusiness.GetCurrentCourseSlotModel(this.dialogData.info);
    this.currentCourseSlotInfo.isTempHold = await this._deleteTeeTimeSlotService.IsTempHoldExist(this.currentCourseSlotInfo);
    if (this.currentCourseSlotInfo && !this.currentCourseSlotInfo?.isTempHold) {
      this._deleteTeeTimeSlotBusiness.setOpenedSlotToDelete(this.currentCourseSlotInfo);
      this.stepperService.enableSave = true;
    }
    else if (this.currentCourseSlotInfo?.isTempHold) {
      this.AddCurrentSlotDisable(true)
      this.stepperService.enableSave = false;
    }
  }
  /**
   * OnSaveTrigger method to save deleted tee time slots
   */
  private OnSaveTrigger() {
    this.stepperService.valueChange.pipe(takeUntil(this.destroyed$)).subscribe((data: DialogCloseObj) => {
      //save goes here
      if (data.type == ButtonAction.save) {
        this.stepperService.enableSave = false;
        const lstSlotToDelete = this._deleteTeeTimeSlotBusiness.getOpenedSlotToDelete();
        if (lstSlotToDelete != null && lstSlotToDelete?.length > 0) {
          this._deleteTeeTimeSlotService.DeleteTeeTiemSlot(lstSlotToDelete, (err?: any) => {
            if (err?.error && err.error?.errorCode) {
              if (err.error.errorCode == 20520) {
                this._utilities.showAlert(this._localatization.getError(err.error.errorCode), AlertType.Info)
                if (this.isShowAllOpenSlotChecked) {
                  this.OnChangeShowAllOpenSlots({ checked: this.isShowAllOpenSlotChecked }, true).then(() => {
                    let updateRecordList = this._deleteTeeTimeSlotBusiness.RetainChosenOpenedSlot(this.openedSlotDetails, !this.currentCourseSlotInfo.isTempHold ? this.currentCourseSlotInfo : null);
                    this.openedSlotDetails = updateRecordList.openedSlotDetails;
                    if (updateRecordList.openedSlotToDelete?.length < 1) {
                      this.stepperService.enableSave = false;
                    }
                  });
                }
                else {
                  this.AddCurrentSlotDisable(true)
                  this.stepperService.enableSave = false;
                  this._deleteTeeTimeSlotBusiness.setOpenedSlotToDelete(this.currentCourseSlotInfo, true)
                }
              }
              else {
                this._utilities.showError(this._localatization.getError(err.error.errorCode))
              }
            }
            else {
              this._utilities.showError(err.message);
            }
          }).then(r => {
            this._utilities.showAlert(this.objCaption.deleteTeeTimeSlotSuccessMessage, AlertType.WellDone, ButtonType.Ok);
            data.dialogRef.close();
          });
        }
      }
    })
  }
  /**
   * OnChangeShowAllOpenSlots method to trigger change event when show all opened slots is updated
   * @param event 
   */
  async OnChangeShowAllOpenSlots(event: any = null, isToRefresh: boolean = false) {
    if (event && event?.source) event.source.disabled = true;
    this.isShowAllOpenSlotChecked = event?.checked
    if (event && event?.checked && this.currentCourseSlotInfo) {
      this.openedSlotDetails = await this._deleteTeeTimeSlotBusiness.GetOpenSlot(this.currentCourseSlotInfo);
      if (isToRefresh) {
        var currentSlot = this.openedSlotDetails.find(x => x.courseId == this.currentCourseSlotInfo.courseId && x.scheduleDateTime == this.currentCourseSlotInfo.scheduleDateTime && x.originalHoleNumber == this.currentCourseSlotInfo.originalHoleNumber);
        if ((currentSlot && currentSlot != null && currentSlot.isTempHold) || (!currentSlot || currentSlot == null)) {
          this.AddCurrentSlotDisable(true)
          this.currentCourseSlotInfo.isTempHold = true;
          this._deleteTeeTimeSlotBusiness.setOpenedSlotToDelete(this.currentCourseSlotInfo, true)
        }
        else if (currentSlot && currentSlot != null && !currentSlot.isTempHold) {
          this.AddCurrentSlotDisable(false)
          this.currentCourseSlotInfo.isTempHold = false;
        }
      }
      this.openedSlotDetails = this.openedSlotDetails.filter(x => !(x.courseId == this.currentCourseSlotInfo.courseId && x.scheduleDateTime == this.currentCourseSlotInfo.scheduleDateTime && x.originalHoleNumber == this.currentCourseSlotInfo.originalHoleNumber));
      if (this.openedSlotDetails?.length < 1) {
        this.isOpenSlotsNotExistOnShowMore = true;
      } else {
        this.isOpenSlotsNotExistOnShowMore = false;
      }
    }
    else {
      this.openedSlotDetails = [];
      this._deleteTeeTimeSlotBusiness.ResetList_deleteTeeTimeSlots(this.currentCourseSlotInfo);
    }
    if (event && event?.source) event.source.disabled = false;
    const lstSlotToDelete = this._deleteTeeTimeSlotBusiness.getOpenedSlotToDelete();
    if (lstSlotToDelete && lstSlotToDelete?.length > 0) this.stepperService.enableSave = true;
  }
  /**
   * OnMultiSelectbuttonSelected method to set selected bit & update the slots to delete tee time slots
   * @param data 
   */
  OnMultiSelectbuttonSelected(data: DeleteTeeTimeSlotModel) {
    data.isSelected = !data.isSelected;
    var lstSlotToDelete = this._deleteTeeTimeSlotBusiness.getOpenedSlotToDelete();
    if (data.isSelected) {
      this._deleteTeeTimeSlotBusiness.setOpenedSlotToDelete(data)
    }
    else if (lstSlotToDelete && lstSlotToDelete?.length > 0) {
      lstSlotToDelete = this._deleteTeeTimeSlotBusiness.setOpenedSlotToDelete(data, true);
    }
    if (lstSlotToDelete && lstSlotToDelete?.length > 0) this.stepperService.enableSave = true;
    else
      this.stepperService.enableSave = false;
  }
  /**
   * AddCurrentSlotDisable method to add / remove class to change background for current slot
   * @param isAdd 
   */
  private AddCurrentSlotDisable(isAdd: boolean) {
    if (isAdd) $(".players-info--wrapper").addClass("players-info--wrapper--disable");
    else $(".players-info--wrapper").removeClass("players-info--wrapper--disable");
  }
}
