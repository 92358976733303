import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DateInput } from '../../shared-models';
import { UntypedFormGroup } from '@angular/forms';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
@Component({
  selector: 'app-golf-date-picker',
  templateUrl: './golf-date-picker.component.html'
})
export class GolfDatePickerComponent implements OnInit {
  className: string;
  errorMessage: string;
  form: UntypedFormGroup;
  formControlName: string;
  hidden: boolean = false;
  maxDate: string | Date;
  minDate: string | Date;
  placeHolder: string;
  value: string | Date;
  captions = {
    invalid: "Invalid"
  };
  isDateRequired: boolean;
  placeHolderFormat: string;
  captionsLocalization: any;
  availableDays: Date[] = [];
  dateInvalidError: string;
  floatLabel: string;
  @Output() datePickerChange = new EventEmitter();
  automationId: string="";
  @Input('inputs')
  set inputOptions(value: DateInput) {
    this.floatLabel = this.localization.setFloatLabel? this.localization.setFloatLabel: '';
    if(value){
    this.className = value.className;
    this.errorMessage = value.errorMessage;
    this.form = value.form;
    this.formControlName = value.formControlName;
    this.hidden = value.hidden ? value.hidden : false;
    this.maxDate = value.maxDate ? new Date(this.localization.convertDateToAPIdate(value.maxDate, '/')) : value.maxDate;
    const minimumDate = value.minDate ? value.minDate : new Date('01/01/1900');
    this.minDate = new Date(this.localization.convertDateToAPIdate(minimumDate, '/'));
    this.placeHolder = value.placeHolder;
    this.value = value.value;
    this.availableDays = value.selectableDates;
    this.isDateRequired = value.isDateRequired ? value.isDateRequired : false;
    this.dateInvalidError = value.inValidDate;
    this.automationId = value.automationId ? value.automationId : '';
    }
  }
  constructor(private localization: GolfLocalization) {
    this.captionsLocalization = this.localization.captions.common;
  }

  ngOnInit() {
    this.placeHolderFormat = this.localization.inputDateFormat;
  }

  dateChanged(event) {
    this.datePickerChange.emit([this.form, this.formControlName]);
  }

  // dateFilter = (d: Date): boolean => {
  //   let res = false;
  //   const time = new Date(this.localization.convertDateObjToAPIdate(d, '/')).getTime();
  //   if (this.minDate) {
  //     const minTime = typeof (this.minDate) == "object" ? this.minDate.getTime() : new Date(this.minDate).getTime();
  //     if (this.maxDate) {
  //       const maxTime = typeof (this.maxDate) == "object" ? this.maxDate.getTime() : new Date(this.maxDate).getTime();
  //       res = (time >= minTime && time <= maxTime);
  //     } else {
  //       res = time >= minTime;
  //     }
  //     if (res && this.availableDays && this.availableDays.length > 0) {
  //       res = this.availableDays.filter(x => x.getTime() == time).length > 0;
  //     }
  //   }
  //   else { res = true; }
  //   return res;
  // }

  errorCount(): number {
    let count = 0;
    const prop = this.form.controls[this.formControlName].errors;
    if(prop) {
      count = Object.keys(prop).length;
    }
    return count;
  }

}
