import { Component, OnInit, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { ManageSessionService } from './login/manage-session.service';
import { ExpireSessionService } from './login/expire-session.service';
import { AppBusiness } from './app.business';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',  
  providers: [AppBusiness]
})
export class AppComponent implements OnInit {
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private translate: TranslateService,
    private oauthService: OAuthService,
    private localization: GolfLocalization,
    private sessionService: ManageSessionService,
    private expireSession: ExpireSessionService,
    private appBusiness: AppBusiness,
    private router: Router,
    ) {
    // this.translate.setDefaultLang('en-US');
  }
  title = 'golf-rewrite';

  ngOnInit() {
    /*Kindly uncomment and commit*/ 
    // To maintain consistency, we are commenting this code because of the bug-82609

//      this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(x => {

//      console.log("this.router.url",this.router.url)
//      if(this.router.url!=='/login')
//      {
//       window.onbeforeunload = (ev) => {
//         let dialogText = this.localization.captions.common.RefreshMessage;
//         ev.returnValue = dialogText;
//         return dialogText;
//       }; 
//      }else{
//       console.log("this.router.url",this.router.url);
//      }
 
//  });
   
}

  ngDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  resetTrigger(){
    if (this.expireSession.resetOnTrigger) {
      this.expireSession.resetTimer();
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    this.resetTrigger();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {    
this.resetTrigger();
  }
}
