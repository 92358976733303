import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfScheduleCommunication } from 'src/app/shared/communication/services/golfschedule.service';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';

@Injectable()

export class MovePlayerService {

    constructor(private _golfScheduleCommunication: GolfScheduleCommunication, private _HttpClient: HttpClient, private _localization: GolfLocalization,
        private utils: GolfUtilities) {

    }


    getCaptions() {
        return {
            date: "Select Date",
            course: "Course"
        };
    }

    getPlayersDetails(playerInfo) {
        return [
            {
                label: "Course",
                value: playerInfo ? playerInfo.course.course : ''
            },
            {
                label: "Tee Time",
                value: playerInfo ? this._localization.LocalizeTime(playerInfo.time) : ''
            },
            {
                label: "Players",
                value: playerInfo ? playerInfo.playerDetail.length : ''
            }
        ];
    }

    getPlayerList(playerList) {
        return playerList.map(players => {
            players['isRemovable'] = true;
            return players;
        });
    }

    getCourses() {
        return [
            {
                value: 'jones',
                viewValue: 'Jones'
            },
            {
                value: 'jones',
                viewValue: 'Jones'
            },
            {
                value: 'jones',
                viewValue: 'Jones'
            },

        ];
    }

    getTeeSheeetID() {
        return {
            tableID: 'TableMove'
        };
    }

    getTeeSheetCustomTableData(teeDialogInfo) {
        return {
            isMultiView: false,
            isShowAvailableOnly: true,
            isHideCrossover: true,
            isDetailView: false,
            isOrderByHoleTime: true,
            isHoleNotificationEnabled: true,
            isCommentNotificationEnabled: false,
            isActionAvailable: true,
            isMenuEnabledOnEllipsis: false,
            isPlayerDetailIconsEnabled: true,
            isDragDisabled: true,
            isTooltipDisabled: true,
            isBulkMoveEnabled: false,
            isDropRestrictedOnSameSlot: true,
            isLoadedinPopup: true,
            teeTimeAction: 'move',
            teeActionPerformedCourse: teeDialogInfo ? teeDialogInfo.course.id : 0
        };
    }

    getTeeSheetSkeletonData() {
        return this._HttpClient.get('assets/json/tee-sheet-skeleton.json');
    }

    getTeeSheetPlayerData() {
        return this._HttpClient.get('assets/json/tee-sheet-playerdata.json');
    }

    showError(errorMessage, alertType, buttonType) {
        this.utils.showAlert(errorMessage, alertType, buttonType);
    }
}