import { Injectable } from '@angular/core';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Injectable()
export class GuestPlayersService {

  constructor(private _Localization: GolfLocalization) { }

  getCaptions() {
    return this._Localization.captions.guest.players
  }

  editPlayerDetails() {
    return {
      firstName: 'Thenmozhi',
      lastName: 'Devaraj',
      pronounced: 'Thenmozi',
      dob: '2019-10-16T18:30:00.000Z',
      pincode: '605008',
      title: 'Dr.',
      gender: 'Female',
      phone: [
        {
          contactType: 'Phone',
          phoneType: 1,
          phoneNumber: '5656556565656',
          primaryPhone: true,
          privateInformtionPhone: true
        },
        {
          contactType: 'Phone',
          phoneType: 2,
          phoneNumber: '444444444444444444444',
          primaryPhone: false,
          privateInformtionPhone: false
        },
        {
          contactType: 'Phone',
          phoneType: 3,
          phoneNumber: '6666666666666666666666666',
          primaryPhone: false,
          privateInformtionPhone: false
        }
      ],
      email: [
        {
          contactType: 'Email',
          emailType: 10,
          emailId: 'thenmozhidevaraj92@gmail.com',
          primaryEmail: true,
          privateInformtionEmail: true
        },
        {
          contactType: 'Email',
          emailType: 9,
          emailId: 'mobile@test.com',
          primaryEmail: false,
          privateInformtionEmail: false
        },
        {
          contactType: 'Email',
          emailType: 9,
          emailId: 'home@test.com',
          primaryEmail: false,
          privateInformtionEmail: false
        }
      ],
      address: [
        {
          addressDetails: 'Nethaji Street, Ashok Nagar'
        }
      ],
      privateAddress: false,
      emailPrimary: false,
      emailPrivate: false,
      phonePrivate: false,
      phonePrimary: false,
      state: 'Puducherry',
      city: 'Puducherry',
      country: 'India',
      postal_code: '605008'
    }
  }

  getPlayerList() {
    return [
      {
        basicInformation: {
          id: "sdfghjk",
          firstName: 'Grant',
          lastName: 'Marshall',
          email: 'marshall@yahoo.com',
          image: ''
        },
        memberId: 'M92653WIF3',
        gender: 'Male',
        age:45,
        dateOfBirth: '28/08/1994',
        phoneNumber: '+1 (861) 550‑2796',
        profileStatus: 'Active',
        playerType: 'Member',
        memberStatus: 'Indiv Weekend',
        rateType: 'Public 18',
        address: '221, Baker Street, Springfields, USA ‑100034',
        lastVisitedDate: '15/07/2017'
      }]
  }
}
