import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { HistoryBusiness } from './history.business';
import { HistoryService } from './history.service';
import { HistoryTypes } from './history.model';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import * as _ from 'lodash';
import { ActivityType, ActivityStatus } from 'src/app/common/shared/shared/globalsContant';
import { AgToggleConfig,TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { MultipackAPIModel } from './history.model';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  providers: [HistoryService, HistoryBusiness],
  encapsulation: ViewEncapsulation.None
})
export class HistoryComponent implements OnInit {
  captions: any;
  historyTypes: HistoryTypes[];
  historyFormGrp: UntypedFormGroup;
  tableDataArray: any = [];
  tableHeaderArray: any = [];
  itemArray: any = [];
  clientAppointments: any[];
  selectedRowItem: any;
  playerGuid: string;
  expiredToggleInput: AgToggleConfig;
  clientMultipackDetails: MultipackAPIModel[];
  floatLabel: string;
  tableOptions: TableOptions;
  headerOptions: TableHeaderOptions[];
  tableContent= [];
  tableContent$ = [];


  @Input('inputData')
  set formData(value) {
    if (value && value.id) {
      this.playerGuid = value.id;
      this._HistoryBusiness.GetMultipackDetails(this.playerGuid).then(x=>{
        this.clientMultipackDetails = x;            
      });
      this._HistoryBusiness.InitializeData(this.playerGuid);
    }
  }

  constructor(private _HistoryBusiness: HistoryBusiness, private Form: UntypedFormBuilder, public localization: GolfLocalization) { 
    this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.captions = this._HistoryBusiness.GetCaptions();
    this.generateHistoryFormGrp();
    this.getHistoryType();   
  }

  generateHistoryFormGrp() {
    this.historyFormGrp = this.Form.group({
      historyType: '',
      expired: false
    });
    this.expiredToggleInput = {
      group: this.historyFormGrp,
      horizontal: false,
      label: this.captions.UpdateInfo,
      formControlName: 'expired',
      disabled: false,
      automationId:"'Tog_History_expired'"
    };
  }

  getHistoryType() {
    this.historyTypes = this._HistoryBusiness.GetHistoryTypes();
    this.historyFormGrp.get('historyType').patchValue(this.historyTypes[0]);
    this.changeType();

  }

  async changeType() {
    this.tableDataArray = [];
    this.tableHeaderArray = [];  

    switch (this.historyFormGrp.value.historyType.id) {
      case 1:
        this.tableHeaderArray = [{ tableHeader: this.captions.Quantity, keyValue: 'quantity', alignType: 'right' },
        { tableHeader: this.captions.ItemNumber, keyValue: 'itemNumber', alignType: 'right' },
        { tableHeader: this.captions.description, keyValue: 'description', alignType: 'left' }];
        this.tableDataArray = this._HistoryBusiness.GetFrequentlyPurchasedData();
        break;

      case 2:
        this.tableHeaderArray = [{ tableHeader: this.captions.Date, keyValue: 'date', alignType: 'left' },
        { tableHeader: this.captions.TransactionNo, keyValue: 'transaction', alignType: 'right' },
        { tableHeader: this.captions.GrandTotal + ` (${this.localization.currencySymbol})`, keyValue: 'grandTotal', alignType: 'left' },
        { tableHeader: this.captions.SubTotal + ` (${this.localization.currencySymbol})`, keyValue: 'subTotal', alignType: 'left' },
        { tableHeader: this.captions.Tax + ` (${this.localization.currencySymbol})`, keyValue: 'tax', alignType: 'left' },
        { tableHeader: this.captions.Gratuity + ` (${this.localization.currencySymbol})`, keyValue: 'gratuity', alignType: 'left' },
        { tableHeader: this.captions.Discount + ` (${this.localization.currencySymbol})`, keyValue: 'discount', alignType: 'left' }];

        this.tableDataArray = this._HistoryBusiness.GetSalesHistory();
        this.selectedRow(this.tableDataArray[0]);
        break;

      case 3:
        this.tableHeaderArray = [{ tableHeader: this.captions.Date, keyValue: 'appointmentTime', alignType: 'left' },
        { tableHeader: this.captions.TransactionNo, keyValue: 'transaction', alignType: 'right' },
        { tableHeader: this.captions.Service, keyValue: 'service', alignType: 'left' },
        { tableHeader: this.captions.TherapistName, keyValue: 'therapist', alignType: 'left' }];
      
        this.clientAppointments = this._HistoryBusiness.GetAppointments().filter(s=> s.activityType.map(a => a.toLowerCase()).includes(ActivityType.SPA.toLowerCase()));
        this.tableDataArray = this.clientAppointments ; //&& this.clientAppointments.filter(x => x.status == 'Reserved' || x.status == 'CheckedIn' || x.status == 'CheckedOut');
        _.sortBy(this.tableDataArray, [function (data) {
          return data.appointmentTime;
        }]);
        if (this.tableDataArray) {
          this.tableDataArray.reverse();
        }
        break;

      case 4:
        this.tableHeaderArray = [{ tableHeader: this.captions.Date, keyValue: 'appointmentTime', alignType: 'left' },
        { tableHeader: this.captions.TransactionNo, keyValue: 'transaction', alignType: 'right' },
        { tableHeader: this.captions.Service, keyValue: 'service', alignType: 'left' },
        { tableHeader: this.captions.TherapistName, keyValue: 'therapist', alignType: 'left' },
        { tableHeader: this.captions.CancellationNumber, keyValue: 'cancelId', alignType: 'left' },
        { tableHeader: this.captions.CancellationComments, keyValue: 'cancelComments', alignType: 'left' }];
      
        this.clientAppointments = this._HistoryBusiness.GetAppointments().filter(a=>a.service != 'TeeTime');
        this.tableDataArray = this.clientAppointments && this.clientAppointments.filter(x => x.status == 'Cancelled' || x.status == 'NoShow');
        _.sortBy(this.tableDataArray, [function (data) {
          return data.appointmentTime;
        }]);
        if (this.tableDataArray) {
          this.tableDataArray.reverse();
        }
        break;

      case 5:
        this.tableHeaderArray = [{ tableHeader: this.captions.Date, keyValue: 'appointmentTime', alignType: 'left' },
        { tableHeader: this.captions.TransactionNo, keyValue: 'transaction', alignType: 'right' },
        { tableHeader: this.captions.Course, keyValue: 'activityDetailDescription', alignType: 'left' },
        { tableHeader: this.captions.Status, keyValue: 'status', alignType: 'left' }];
        this.tableDataArray = this._HistoryBusiness.GetAppointments()
              .filter(s=> s.activityType.map(a => a.toLowerCase()).includes(ActivityType.GOLF.toLowerCase()))
              .map(o=> {o.status = this.mapActivityStatusToGolfStatus(o.status); return o});
        break;

      case 6:
        this.tableHeaderArray = [{ tableHeader: this.captions.dateandTime, keyValue: "DateRedeemed", alignType: "left" },
        { tableHeader: this.captions.multipack, keyValue: "MultipackDescription", alignType: "left" },
        { tableHeader: this.captions.dateofsale, keyValue: "DateOfSale", alignType: "left" },
        { tableHeader: this.captions.redeemSession, keyValue: "RedeemedCount", alignType: "left" },
        { tableHeader: this.captions.remainingsession, keyValue: "RemainingCount", alignType: "left" },
        { tableHeader: this.captions.dateofexpiry, keyValue: "DateOfExpiry", alignType: "left" },
        { tableHeader: this.captions.product, keyValue: "Product", alignType: "left" }];
        this.tableDataArray = this._HistoryBusiness.mapMultipackToUI(this.clientMultipackDetails,this.historyFormGrp.value.expired);
        break;
      
      case 7:                                                                                                 // Rentals History
          let playerLinkIds = [];
          playerLinkIds.push(this.playerGuid);
          this.headerOptions = this._HistoryBusiness.getRentalsTableHeaderOptions();
          this.tableOptions = this._HistoryBusiness.getRentalsTableOptions();
          await this._HistoryBusiness.getRentalsTableContent(playerLinkIds).then(x => {
            this.tableContent = x;
            this.tableContent$ = cloneDeep(this.tableContent);
          });
      break;
    }

  }

  searchChange(e){
    this.tableContent = this.tableContent$.filter(x=>x.RentalItemName.toLowerCase().includes(e.toLowerCase()));
    this.tableContent = [...this.tableContent];
  }

  private mapActivityStatusToGolfStatus(value: string): string {      
    switch (value) {
      case ActivityStatus.Reserved:
        return this.captions.Booked;       
      case ActivityStatus.Cancelled:
        return this.captions.Cancelled;      
      case ActivityStatus.NoShow:
        return this.captions.NoShow;      
      default:
        return value;       
    }
   
  }

  selectedRow(item) {
    if (this.historyFormGrp.value.historyType.id === 2) {
      this.itemArray = [];
      this.itemArray = item && item.transactionDetails.items;
    }
    this.selectedRowItem = item;
  }

  showExpiredMultipacks(event){
    if(this.clientMultipackDetails){
      this.tableDataArray = this._HistoryBusiness.mapMultipackToUI(this.clientMultipackDetails,event.checked); 
    }  
  }
}
