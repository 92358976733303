import { CancelPlayerModalComponent } from './cancel-player-modal/cancel-player-modal.component';
import { ClearPlayerModalComponent } from './clear-player-modal/clear-player-modal.component';
import { AddDepositeModalComponent } from './add-deposite-modal/add-deposite-modal.component';
import { PrintModalComponent } from './print-modal/print-modal.component';
import { PrintItenaryComponent } from './print-itenary/print-itenary.component';
import { SqueezeTimeModalComponent } from './squeeze-time-modal/squeeze-time-modal.component';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { RateModalComponent } from './rate-modal/rate-modal.component';
import { LinkMultipackComponent } from './link-multipack/link-multipack.component'
import { CopyPlayerModalComponent } from './copy-player-modal/copy-player-modal.component';
import { CopytomanyPlayerModalComponent } from './copytomany-player-modal/copytomany-player-modal.component';
import { MovePlayerModalComponent } from './move-player-modal/move-player-modal.component';
import { RefundModalComponent } from './refund-modal/refund-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonPopupComponent } from '../shared/components/common-popup/common-popup.component';
import { BulkMoveModalComponent } from './bulk-move-modal/bulk-move-modal.component';
import { TeeTimeComponent } from './teetime/tee-time.component';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { ConvertWaitlistModalComponent } from './convert-waitlist-modal/convert-waitlist-modal.component';
import { ComponentDetails, ConvertWaitlist, AlertType, ButtonType, AlertAction } from '../shared/shared-models';
import { TeeTimeFormat } from '../shared/models/teesheet.form.models';
import { GolfUtilities } from '../shared/utilities/golf-utilities';
import { BookingSource, ButtonAction, TempHoldStatus } from '../shared/global.constant';
import { NotifyComponent } from './notify/notify.component';
import { GolfLocalization } from '../core/localization/golf-localization';
import { BlockTeeTimeComponent } from './block-tee-time/block-tee-time.component';
import { UserAccessModel } from '../common/dataservices/authentication/useraccess-model.model';
import { Injectable } from '@angular/core';
import { RedeemRoundComponent } from './redeem-round/redeem-round.component';
import { HoldConfirmationTeeTimeComponent } from './hold-confirmation-tee-time/hold-confirmation-tee-time.component';
import { DeleteTeeTimeSlotModalComponent } from './delete-tee-time-slot-modal/delete-tee-time-slot-modal.component';
import { NoShowModalComponent } from './no-show-modal/no-show-modal.component';
import { SelectOutletPopupComponent } from '../shared/components/select-outlet-popup/select-outlet-popup.component';
import { RetailSharedVariableService } from '../retail/shared/retail.shared.variable.service';
import { RetailPropertyInformation } from '../retail/common/services/retail-property-information.service';
import { CheckInOutUndoCheckInOutModalComponent } from './check-in-out-undo-check-in-out-modal/check-in-out-undo-check-in-out-modal.component';
import { PlayerPaymentstatus } from '../common/shared/shared.modal';
import { PlayerSelectionComponent } from './player-selection/player-selection.component';
import { EformsBusiness } from 'src/app/common/data-magine/dm-eforms/dm-eforms.business';
import { DmConfig, DmDocument } from 'src/app/common/Models/common.models';
import { API as Settings } from 'src/app/settings/system-setup/tee-times/tee-times.modal'
import { AuthorizeComponent } from './authorize/authorize.component';
import { AuditTrailComponent } from './audit-trail/audit-trail.component';
import { allocationBlockPlayerTypePermission } from '../settings/user-setup/booking-permission/booking-permission.model';
import { Router } from '@angular/router';
import {GroupRetailItems} from 'src/app/retail/shared/service/common-variables.service'
import { TeeTimeModel, TeeTimeTempHold } from './teetime/tee-time.model';
import { TeeTimeService } from './teetime/tee-time.service';

export enum TeeTimeActions {
  Scheduled = 'Scheduled',
  copy = 'copy',
  refund = 'refund',
  copyToMany = 'copyToMany',
  move = 'move',
  squeeze = 'squeeze',
  print = 'print',
  payment = 'payment',
  rate = 'rate',
  cancelPlayers = 'cancelPlayers',
  clearPlayers = 'clearPlayers',
  bulkMove = 'bulkMove',
  holdTeeTime = 'holdTeeTime',
  frostDelay = 'frostDelay',
  waitlist = 'waitlist',
  reinstate = 'reinstate',
  notify = 'notify',
  blockTeeTime = 'blockTeeTime',
  ViewedByStarter = "ViewedByStarter",
  Redeem = "Redeem",
  PrintItenary = "PrintItenary",
  deleteTeeTimeSlot = "deleteTeeTimeSlot",
  linkmultipack = "multipack",
  UnlinkMultipack = "unlinkmultipack",
  markAsPaid = "markAsPaid",
  checkIn = "checkIn",
  checkOut = "checkOut",
  checkInCheckOut = "checkInCheckOut",
  undoCheckOut = "undoCheckOut",
  undoCheckIn = "undoCheckIn",
  noShow = "noShow",
  unMarkNoShow = "unMarkNoShow",
  viewEform = "viewEForm",
  authorize = "authorize",
  auditTrail = "auditTrail",
  addRetailItem = "addRetailItem",
  editRetailItem = "editRetailItem"
}

@Injectable()
export class TeeTimeActionsBase {
  popUpComponentDetails: ComponentDetails;
  _dialog: MatDialog;
  dialogObj: MatDialog;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  captions;
  teeTimeConfig: Settings.TeeTimeConfig;
  token: string = '';
  dmConfig: DmConfig;
  DataMagineLogin : any;
  DMSubmittedEform: any;
  DMFetchEform: any;
  constructor(dialog: MatDialog, public _Utilities: GolfUtilities, private localization: GolfLocalization,
    public _retailSharedVariableService: RetailSharedVariableService,
    public propertyInfo: RetailPropertyInformation,
    public _teeTimeService: TeeTimeService,
    public business: EformsBusiness,public router: Router) {
    this._dialog = dialog;
    this.dialogObj = dialog;
  }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  async PerformAction(actionType: TeeTimeActions, arg, from?, isBulk?: boolean, bulkPlayerUserAccess?: UserAccessModel.BreakPointResult, 
    normalPlayerUserAccess?: UserAccessModel.BreakPointResult, callback?: () => boolean, canNavigateToShop?: boolean, eventNotifydata?, 
    allocationCodePermissions?: allocationBlockPlayerTypePermission[], playerRetailItems?: GroupRetailItems[], playerRentalItems?: 
    GroupRetailItems[],isViewOnly?: boolean) {
    console.log('Data ', arg, "---actionType---", actionType);
    let popUpWidth = '40%';
    let popUpHeight = '40%';
    let saveText = this.captions.save;
    let modalComponentName;
    let modalTitle;
    let className = "";
    let showStatic = false;
    let eventType = ButtonAction.save;
    let isNormal = this.CheckForNormalBooking(arg);
    let HasBulkPlayers = this.HasBulkPlayers(arg);
    let isMultiple = this.HasRefundedPlayersWithMultipleTicket(arg);
    var teeTimeSetting = JSON.parse(sessionStorage.getItem('TEETIMESETTING'));
    let saveThrottletime = 0;
    switch (actionType) {
      case TeeTimeActions.copy:
        modalComponentName = CopyPlayerModalComponent;
        modalTitle = this.captions.copyPlayers;
        popUpWidth = '80%';
        popUpHeight = '80%';
        arg.bulkBookingId = this.getBulkBookingId(arg);
        if (arg.bulkBookingId != '' && !isNormal) {
          this.ShowBulkBookingAlert(modalComponentName, arg, popUpWidth, popUpHeight, className, modalTitle, saveText, eventType, showStatic, callback, actionType,undefined,allocationCodePermissions);
          return;
        }
        break;
      case TeeTimeActions.refund:
        modalComponentName = RefundModalComponent;
        modalTitle = this.captions.refund;
        saveText = this.captions.continue;
        popUpWidth = '60%';
        popUpHeight = '60%';
        break;
      case TeeTimeActions.Redeem:
        modalComponentName = RedeemRoundComponent;
        modalTitle = this.captions.zeroMemberCharge;
        saveText = this.captions.continue;
        popUpWidth = '80%';
        popUpHeight = '80%';
        if (canNavigateToShop) {
          let selectOutletPopup = SelectOutletPopupComponent;
          let selectOutletTitle = this.captions.selectOutletPopupTitle;
          let selectOutletSaveText = this.captions.saveData;
          let selectOutletPopUpWidth = '35%';
          let selectOutletPopupHeight = '45%';
          this.openDefaultOutletPopup(selectOutletPopup, arg, selectOutletPopUpWidth, selectOutletPopupHeight, className, selectOutletTitle, selectOutletSaveText, eventType, bulkPlayerUserAccess, normalPlayerUserAccess, showStatic, callback, TeeTimeActions.Redeem, isBulk);
          return;
        }
        break;
      case TeeTimeActions.copyToMany:
        modalComponentName = CopytomanyPlayerModalComponent;
        modalTitle = this.captions.copyToMany;
        popUpWidth = '80%';
        popUpHeight = '80%';
        arg.bulkBookingId = this.getBulkBookingId(arg);
        if (arg.bulkBookingId != '' && !isNormal) {
          this.ShowBulkBookingAlert(modalComponentName, arg, popUpWidth, popUpHeight, className, modalTitle, saveText, eventType, showStatic, callback, actionType,undefined,allocationCodePermissions);
          return;
        }
        break;
      case TeeTimeActions.move:
        modalComponentName = MovePlayerModalComponent;
        modalTitle = this.captions.movePlayers;
        popUpWidth = '80%';
        popUpHeight = '80%';
        arg.bulkBookingId = this.getBulkBookingId(arg);
        if (arg.bulkBookingId != '' && !isNormal) {
          this.ShowBulkBookingAlert(modalComponentName, arg, popUpWidth, popUpHeight, className, modalTitle, saveText, eventType, showStatic, callback, actionType,undefined,allocationCodePermissions);
          return;
        }
        break;
      case TeeTimeActions.squeeze:
        modalComponentName = SqueezeTimeModalComponent;
        modalTitle = this.captions.squeeze;
        break;
      case TeeTimeActions.print:
        modalComponentName = PrintModalComponent;
        modalTitle = this.captions.print;
        saveText = this.captions.printSave;
        eventType = ButtonAction.print;
        popUpWidth = '50%';
        popUpHeight = '50%';
        break;
      case TeeTimeActions.payment:
        modalComponentName = PaymentModalComponent;
        modalTitle = this.captions.payTitle;
        saveText = this.captions.pay;
        eventType = ButtonAction.pay;
        popUpWidth = '80%';
        popUpHeight = '80%';
        var lenCheckIn = arg && arg?.playerDetail ? arg.playerDetail.filter(x => (x.playerStatus & PlayerPaymentstatus.checkIn) != 0 || (x.playerStatus & PlayerPaymentstatus.checkOut) != 0)?.length : 0;
        if (teeTimeSetting && teeTimeSetting != null && teeTimeSetting.requireCheckInCheckOut) {
          var req = this.buildCallBackObject(modalComponentName, arg, popUpWidth, popUpHeight, className, modalTitle, saveText, eventType, showStatic, callback, actionType, isBulk, bulkPlayerUserAccess, normalPlayerUserAccess,
            isMultiple, canNavigateToShop);
          if (teeTimeSetting.allowPaymentInCheckIn && ((from == 'search' && (arg?.playerStatus & PlayerPaymentstatus.checkIn) == 0) || lenCheckIn < arg?.playerDetail?.length)) {
            this._Utilities.showAlert(this.localization.captions.settings.allowPaymentInCheckInValidation, AlertType.Info, ButtonType.YesNo, this.openPaymentModal.bind(this), [{ extraParams: req }]);
            return
          }
          else {
            this.validatePaymentByRequireCheckIn(req, from == 'search' ? true : false);
            return;
          }
        }
        else
          if (canNavigateToShop) {
            var req = this.buildCallBackObject(modalComponentName, arg, popUpWidth, popUpHeight, className, modalTitle, saveText, eventType, showStatic, callback, actionType, isBulk, bulkPlayerUserAccess, normalPlayerUserAccess,
              isMultiple, canNavigateToShop);
            this.onNavigateShop(req);
            return;
          }
        break;
      case TeeTimeActions.markAsPaid:
        modalComponentName = PaymentModalComponent;
        modalTitle = this.captions.markAsPaidTitle;
        saveText = this.captions.markAsPaidTitle;
        eventType = ButtonAction.markAsPaid;
        popUpWidth = '60%';
        popUpHeight = '70%';
        if (canNavigateToShop) {
          var req = this.buildCallBackObject(modalComponentName, arg, popUpWidth, popUpHeight, className, modalTitle, saveText, eventType, showStatic, callback, actionType, isBulk, bulkPlayerUserAccess, normalPlayerUserAccess,
            isMultiple)
          this.onNavigateShop(req);
          return;
        }
        break;
      case TeeTimeActions.noShow:
        modalComponentName = NoShowModalComponent;
        modalTitle = this.captions.noShowIconDescription;
        saveText = this.captions.ok;
        eventType = ButtonAction.noShow;
        popUpWidth = '80%';
        popUpHeight = '70%';
        if (canNavigateToShop) 
        {
          let selectOutletPopup = SelectOutletPopupComponent;
          let selectOutletTitle = this.captions.selectOutletPopupTitle;
          let selectOutletSaveText = this.captions.saveData;
          let selectOutletPopUpWidth = '35%';
          let selectOutletPopupHeight = '45%';
          this.openDefaultOutletPopup(selectOutletPopup, arg, selectOutletPopUpWidth, 
          selectOutletPopupHeight, className, selectOutletTitle, 
          selectOutletSaveText, eventType, bulkPlayerUserAccess, normalPlayerUserAccess, showStatic, callback, TeeTimeActions.noShow, isBulk);
          return;
        }
        break;
      case TeeTimeActions.unMarkNoShow:
        modalComponentName = NoShowModalComponent;
        modalTitle = this.captions.UnMarkNoShowDescription;
        saveText = this.captions.save;
        eventType = ButtonAction.unMarkNoShow;
        popUpWidth = '80%';
        popUpHeight = '70%';
        break;
      case TeeTimeActions.rate:
        modalComponentName = RateModalComponent;
        modalTitle = this.captions.rateTitle;
        saveText = this.captions.pay;
        popUpWidth = '60%';
        popUpHeight = '60%';
        className = 'rate-modal-changes';
        break;
      case TeeTimeActions.linkmultipack:
        modalComponentName = LinkMultipackComponent;
        modalTitle = this.captions.linkTitle;
        saveText = this.captions.link;
        popUpWidth = '80%';
        popUpHeight = '80%';
        break;
      case TeeTimeActions.UnlinkMultipack:
        modalComponentName = LinkMultipackComponent;
        modalTitle = this.captions.unlinkTitle;
        saveText = this.captions.unlink;
        popUpWidth = '80%';
        popUpHeight = '80%';
        break;
      case TeeTimeActions.cancelPlayers:
        modalComponentName = CancelPlayerModalComponent;
        modalTitle = this.captions.cancelPlayers;
        saveText = this.captions.process;
        eventType = ButtonAction.process;
        popUpWidth = '80%';
        popUpHeight = '80%';
        arg.bulkBookingId = this.getBulkBookingId(arg);
        arg.bulkBookingId = isBulk ? arg.bulkBookingId : '';
        if (arg.bulkBookingId != '' && !isNormal) {
          let alert = this._Utilities.showAlert(this.localization.captions.teetime.BulkBookActionAlert, AlertType.Info, ButtonType.YesNo, async (res)=>
          {
            if (res === AlertAction.NO) {
              arg.bulkBookingId = '';
              isBulk = false;
              isNormal = true;
            }
          }, modalTitle);
          await alert.afterClosed().toPromise();
        }
        if(arg.playerDetail.every(o => o.bookingSource === BookingSource.GolfNow)){
          this._Utilities.showAlert(this.captions.lbl_ErrorGolfNowCancelError,AlertType.Warning);
          return;
        }
        if (canNavigateToShop) 
        {
          let selectOutletPopup = SelectOutletPopupComponent;
          let selectOutletTitle = this.captions.selectOutletPopupTitle;
          let selectOutletSaveText = this.captions.saveData;
          let selectOutletPopUpWidth = '35%';
          let selectOutletPopupHeight = '45%';
          this.openDefaultOutletPopup(selectOutletPopup, arg, selectOutletPopUpWidth, 
          selectOutletPopupHeight, className, selectOutletTitle, 
          selectOutletSaveText, eventType, bulkPlayerUserAccess, normalPlayerUserAccess, showStatic, callback, TeeTimeActions.cancelPlayers, isBulk);
          return;
        }
        break;
      case TeeTimeActions.clearPlayers:
        modalComponentName = ClearPlayerModalComponent;
        modalTitle = this.captions.clearTitle;
        saveText = this.captions.process;
        eventType = ButtonAction.process;
        popUpWidth = '60%';
        popUpHeight = '60%';
        arg.bulkBookingId = this.getBulkBookingId(arg);
        arg.bulkBookingId = isBulk ? arg.bulkBookingId : '';
        if (arg.bulkBookingId != '' && !isNormal && !HasBulkPlayers) {
          this.ShowBulkBookingAlert(modalComponentName, arg, popUpWidth, popUpHeight, className, modalTitle, saveText, eventType, showStatic, callback, actionType, isBulk,allocationCodePermissions);
          return;
        }
        if(arg.playerDetail.every(o => o.bookingSource === BookingSource.GolfNow)){
          this._Utilities.showAlert(this.captions.lbl_ErrorGolfNowClearError,AlertType.Warning);
          return;
        }
        break;
      case TeeTimeActions.bulkMove:
        modalComponentName = BulkMoveModalComponent;
        modalTitle = this.captions.movePlayers;
        popUpWidth = '70%';
        popUpHeight = '70%';

        break;
      case TeeTimeActions.holdTeeTime:
        modalComponentName = HoldConfirmationTeeTimeComponent;
        modalTitle = this.captions.holdTeeTime;
        saveText = this.captions.okay;
        popUpWidth = '50%';
        popUpHeight = '380px';
        break;
      case TeeTimeActions.waitlist:
        if (from === ConvertWaitlist.teeSheet) {
          showStatic = false;
          saveText = this.captions.convertToTeeTime;
          eventType = ButtonAction.convert;
        } else if (from === ConvertWaitlist.waitlist) {
          showStatic = true;
          saveText = this.captions.confirm;
          eventType = ButtonAction.confirm;
        }
        modalComponentName = ConvertWaitlistModalComponent;
        modalTitle = this.captions.convertWaitList;
        popUpWidth = '80%';
        popUpHeight = '80%';
        break;
      case TeeTimeActions.notify:
        modalComponentName = NotifyComponent;
        modalTitle = this.captions.notifyTitle;
        popUpWidth = '70%';
        popUpHeight = '70%';
        saveText = this.captions.send;
        arg.eventNotifydata = eventNotifydata;
        break;
      case TeeTimeActions.blockTeeTime:
        modalComponentName = BlockTeeTimeComponent;
        modalTitle = this.captions.blockTitle;
        saveText = this.captions.okay;
        popUpWidth = '50%';
        popUpHeight = '400px';
        saveThrottletime = 5000;
        break;
      case TeeTimeActions.PrintItenary:
        modalComponentName = PrintItenaryComponent;
        modalTitle = this.captions.ItenaryPrint.toUpperCase();;
        saveText = this.captions.printSave;
        eventType = ButtonAction.print;
        popUpWidth = '30%';
        popUpHeight = '40%';
        break;
      case TeeTimeActions.deleteTeeTimeSlot:
        modalComponentName = DeleteTeeTimeSlotModalComponent;
        modalTitle = this.captions.deleteTeeTimeSlot.toUpperCase();;
        saveText = this.captions.ok;
        eventType = ButtonAction.save;
        popUpWidth = '50%';
        popUpHeight = '50%';
        break;
      case TeeTimeActions.checkIn:
      case TeeTimeActions.checkOut:
      case TeeTimeActions.checkInCheckOut:
      case TeeTimeActions.undoCheckIn:
      case TeeTimeActions.undoCheckOut:
        modalComponentName = CheckInOutUndoCheckInOutModalComponent;
        if (actionType == TeeTimeActions.checkOut || actionType == TeeTimeActions.checkInCheckOut || (actionType == TeeTimeActions.checkIn && teeTimeSetting && teeTimeSetting != null && teeTimeSetting.allowPaymentInCheckIn)) {
          if (canNavigateToShop) {
            let selectOutletPopup = SelectOutletPopupComponent;
            let selectOutletTitle = this.captions.selectOutletPopupTitle;
            let selectOutletSaveText = this.captions.saveData;
            let selectOutletPopUpWidth = '35%';
            let selectOutletPopupHeight = '45%';
            this.openDefaultOutletPopup(selectOutletPopup, arg, selectOutletPopUpWidth, selectOutletPopupHeight, className,
              selectOutletTitle, selectOutletSaveText, eventType, bulkPlayerUserAccess, normalPlayerUserAccess, showStatic, callback,
              actionType, isBulk);
            return;
          }
          modalTitle = actionType == TeeTimeActions.checkInCheckOut ? this.captions.checkInCheckOutHdr : (actionType == TeeTimeActions.checkIn ? this.captions.checkInHdr : this.captions.checkOutHdr);
        }
        else if (actionType == TeeTimeActions.checkIn) {
          modalTitle = this.captions.checkInHdr;
          saveThrottletime = 10000;
        }
        else if (actionType == TeeTimeActions.undoCheckIn)
          modalTitle = this.captions.undoCheckInHdr;
        else if (actionType == TeeTimeActions.undoCheckOut)
          modalTitle = this.captions.undoCheckOutHdr;
        saveText = this.captions.okay;
        eventType = ButtonAction.save;
        popUpWidth = '80%';
        popUpHeight = '80%';
        break;
      case TeeTimeActions.viewEform:
        this._dialog.open(PlayerSelectionComponent, {
          width: '50%',
          height: '50%',
          data: {
            modalTitle: "Player Selection",
            saveText: this.captions.ok,
            cancel: this.captions.cancel,
            eventType: ButtonAction.save,
            playerDetail: arg
          }
        }).afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(result => {
          console.log(result);
          if (result) {
            this.viewEform(result.player.scheduledTeeTimePlayerFee.scheduledTeeTimePlayerId);
          }
        });
        return;
      case TeeTimeActions.authorize:
        modalComponentName = AuthorizeComponent,
        modalTitle= this.captions.Authorize,
        saveText= this.captions.send,
        eventType= ButtonAction.save
        popUpWidth = '80%';
        popUpHeight = '80%';
        break;
      case TeeTimeActions.auditTrail:
        modalComponentName = AuditTrailComponent,
        modalTitle= this.captions.hdr_auditTrail,
        popUpWidth = '80%';
        popUpHeight = '80%';
        className = 'audit-modal-changes';
        break;
      case TeeTimeActions.addRetailItem:
      case TeeTimeActions.editRetailItem:
      let teeTimeInfo = {
        scheduleDateTime : arg.time,
        courseId: arg?.course?.id,
        holeNumber: arg?.hole,
        originalHoleNumber: arg?.originalHoleNumber
      } as TeeTimeModel;
      this
      .tempHoldTeeTime(teeTimeInfo, TempHoldStatus.hold)
      .then((isSuccess) => {
        if(isSuccess){
          if (canNavigateToShop) {
            let selectOutletPopup = SelectOutletPopupComponent;
            let selectOutletTitle = this.captions.selectOutletPopupTitle;
            let selectOutletSaveText = this.captions.saveData;
            let selectOutletPopUpWidth = '35%';
            let selectOutletPopupHeight = '45%';
            this.openDefaultOutletPopup(selectOutletPopup, arg, selectOutletPopUpWidth, selectOutletPopupHeight, className, selectOutletTitle, selectOutletSaveText, eventType, bulkPlayerUserAccess, normalPlayerUserAccess, showStatic, callback, actionType, isBulk,undefined,undefined,undefined,playerRetailItems, playerRentalItems, isViewOnly);
          }
          else
          {
            this.router.navigate(['/shop/viewgolfretailitem'],{
              state: {
                routePathFrom: this.router.url,
                title: actionType ==TeeTimeActions.addRetailItem ? this.captions.addRetailOrRentals : this.captions.editRetailOrRentals,
                playerInfo : arg,
                playerRetailItems: playerRetailItems,
                playerRentalItems: playerRentalItems,
                isViewOnly: isViewOnly
              }
            });
          }
        }
      });
      break;
      //   modalComponentName = EditRetailItemComponent,
      //   modalTitle= this.captions.hdr_editRetailItem,
      //   saveText= this.captions.saveAndClose,
      //   popUpWidth = '100%';
      //   popUpHeight = '100%';
      //   className = 'audit-modal-changes';
      // break;
      default:
        modalComponentName = '';
        modalTitle = actionType;
        popUpWidth = '60%';
        popUpHeight = '60%';
        break;
    }
    this.openTeeActionPopup(modalComponentName, arg, popUpWidth, popUpHeight, className, modalTitle,
      saveText, eventType, showStatic, bulkPlayerUserAccess, normalPlayerUserAccess, isBulk, isMultiple, callback, actionType, false , saveThrottletime,allocationCodePermissions);

  }

  async tempHoldTeeTime(teeTimeData: TeeTimeModel, status: number): Promise<boolean> {
    let result = false;
    const dt = this.localization.ConvertDateToISODateTime(this._Utilities.getDate(teeTimeData.scheduleDateTime));
    const teeTimeTempHold: TeeTimeTempHold = {
      courseId: Number(teeTimeData.courseId),
      createdBy: sessionStorage.getItem('quickIdUser') ? parseInt(sessionStorage.getItem('quickIdUser')) : Number(this.localization.GetPropertyInfo('UserId')),
      createdOn: dt,
      holeNumber: teeTimeData.holeNumber,
      id: 0,
      releaseDateTime: dt,
      scheduleDateTime: dt,
      originalHoleNumber: teeTimeData.originalHoleNumber
    };
    if (status === TempHoldStatus.hold) {
      var res;
      await this._teeTimeService.TempHoldTeeTime(teeTimeTempHold).then(r => { result = true;}).catch(async e => {
        let errorMsg = '';
        if (!e.result && e.errorCode) 
        {
          errorMsg = this.localization.getError(e.errorCode);          
          errorMsg = e.errorDescription ? errorMsg.interpolate({ user: e.errorDescription }) : "";
        }
        else 
        {
          errorMsg = this.localization.getError(11014);
        }
        if (e.errorCode === 11010) {
          res = this._Utilities.showAlert(errorMsg, AlertType.Info, ButtonType.Ok);
        } else {
          res = this._Utilities.showAlert(errorMsg, AlertType.Error, ButtonType.Ok);
        }        
        await res.afterClosed().toPromise();
        result = false;               
      });
    } else {
      await this._teeTimeService.ReleaseTempHoldTeeTime(teeTimeTempHold).then(r => { result = true }).catch(e => {
        result = false;
      });
    }
    return result;
  }

  // Call DataMagine to get the submitted EForms :
  async viewEform(scheduledTeeTimePlayerId: number) {
    let playerId = "0";

    let data: DmDocument;

    let request: DmDocument = {
      PCname: "V1PC",
      Viewname: "GOLF EFORMS",
      Indexvaluelist: [
        {
          Name: "Player Id",
          Operator: "eq",
          Value: scheduledTeeTimePlayerId.toString(),
          Connector: "And"
        }
      ],
      Pagesize: 500,
      Pagenum: 1,
      DateDelimiter: ""
    };
    let propConfig = JSON.parse(sessionStorage.getItem("propConfig")); 
    this.DataMagineLogin = propConfig?.DMServerLogin;
    this.DMSubmittedEform = propConfig?.DMSubmittedEform;
    this.DMFetchEform = propConfig?.DMFetchEform;

    let token:any;
    if(this.DataMagineLogin)
    {
      token = await this.business.getDataMagineLoginToken(this.dmConfig.serverURL, this.dmConfig.userName, this.dmConfig.password);
    }
    else{
      token = await this.business.getDMLoginToken(this.dmConfig.serverURL, this.dmConfig.userName, this.dmConfig.password);
    }


    if (token && token?.Result?.access_token) {
      this.token = token?.Result?.access_token;
      let rs: any;
      if(this.DMSubmittedEform)
      {
        rs = await this.business.getDMSubmittedEforms(this.DMSubmittedEform, this.token, request);
      }
      else{
        rs = await this.business.getSubmittedEforms(this.dmConfig.serverURL, this.token, request);
      }
      if (rs.Result.length > 0) {
        rs.Result.forEach(async element => {
          let folderId = element.FolderNumber;
          let docId = element.DocumentNumber;
          let result: any;
          if(this.DMFetchEform)
          {
            result = await this.business.getDMSubmittedEformsById(this.DMFetchEform, this.token, folderId, docId);
          }
          else
          {
             result = await this.business.getSubmittedEformsById(this.dmConfig.serverURL, this.token, folderId, docId);
          }
        });
      }
      else {
        const message = this.captions.NoEformSUbmitted;
        this._Utilities.showAlert(message, AlertType.Info, ButtonType.Ok);

      }
    }

  }

  openTeeActionPopup(modalComponentName, arg, popUpWidth, popUpHeight, className, modalTitle, saveText,
    eventType, showStatic, bulkPlayerUserAccess, normalPlayerUserAccess, 
    isBulk, isMultiple, callback, actionType?: TeeTimeActions, 
    isToMarkCheckIn: boolean = false, saveThrottletime?: number,allocationCodePermissions?:allocationBlockPlayerTypePermission[],isEditPay: boolean = false) {
    this.popUpComponentDetails = {
      componentName: modalComponentName,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent',
        bindData: arg,
        saveThrottletime: saveThrottletime
      }
    };
    this._dialog.open(CommonPopupComponent, {
      width: popUpWidth,
      height: popUpHeight,
      panelClass: className,
      data: {
        title: modalTitle,
        update: saveText,
        cancel: this.captions.cancel,
        componentDetails: this.popUpComponentDetails,
        info: arg,
        showStaticBool: showStatic,
        actionType: eventType,
        bulkPlayerUserAccess,
        normalPlayerUserAccess,
        isBulk,
        teeTimeType: actionType,
        isMultiple: isMultiple,
        isToMarkCheckIn: isToMarkCheckIn,
        allocationCodePermissions : allocationCodePermissions,
        isEditPay : isEditPay,
        automationId:modalTitle.split("_")[0].split(" ").join("").toLowerCase()
      },
      disableClose: true
    }).afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(result => {
      if (callback) {
        callback(result);
      }
    });
  }

  ShowBulkBookingAlert(modalComponentName, arg, popUpWidth, popUpHeight, className, modalTitle, saveText, eventType, showStatic, callback, actionType?, isBulk?: boolean, allocationCodePermissions?: allocationBlockPlayerTypePermission[]) {
    let moveObject = {
      modalComponent: modalComponentName,
      rowObject: arg,
      popupWidth: popUpWidth,
      popupHeight: popUpHeight,
      classname: className,
      modaltitle: modalTitle,
      savetext: saveText,
      actionType: eventType,
      showstatic: showStatic,
      callbackfunc: callback,
      teeTimeType: actionType,
      isBulk: isBulk,
      allocationCodePermissions: allocationCodePermissions
    }
    this._Utilities.showAlert(this.localization.captions.teetime.BulkBookActionAlert, AlertType.Info, ButtonType.YesNo, this.openBulkModal.bind(this), [moveObject], moveObject.modaltitle);
  }

  openDefaultOutletPopup(modalComponentName, arg, popUpWidth, popUpHeight, className, modalTitle, saveText, eventType, bulkPlayerUserAccess, normalPlayerUserAccess, showStatic, callback, actionType?, isBulk?: boolean,
    isMultiple?: boolean, isDeposit: boolean = false, isToMarkCheckIn: boolean = false, playerRetailItems?, playerRentalItems?,isViewOnly?) {
    let obj = {
      modalComponent: modalComponentName,
      rowObject: arg,
      popupWidth: popUpWidth,
      popupHeight: popUpHeight,
      classname: className,
      modaltitle: modalTitle,
      savetext: saveText,
      actionType: eventType,
      showstatic: showStatic,
      callbackfunc: callback,
      teeTimeType: actionType,
      isMultiple: isMultiple,
      isBulk: isBulk,
      isDeposit: isDeposit,
      isToMarkCheckIn: isToMarkCheckIn
    }
    this.popUpComponentDetails = {
      componentName: modalComponentName,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent',
        bindData: arg
      }
    };
    const selectDefaultDialogRef = this.dialogObj.open(SelectOutletPopupComponent, {
      width: popUpWidth,
      height: popUpHeight,
      panelClass: className,
      data: {
        title: modalTitle,
        update: saveText,
        cancel: this.captions.cancel,
        componentDetails: this.popUpComponentDetails,
        info: arg,
        showStaticBool: showStatic,
        actionType: eventType,
        bulkPlayerUserAccess,
        normalPlayerUserAccess,
        isBulk,
        teeTimeType: actionType,
        isMultiple: isMultiple,
        isSaveClose: true,
        isDeposit: isDeposit,
        isToMarkCheckIn: isToMarkCheckIn
      },
      disableClose: true
    })
    selectDefaultDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(res => {
      var teeTimeSetting = JSON.parse(sessionStorage.getItem('TEETIMESETTING'));
      if (res.from === AlertAction.YES && (res.actionType == TeeTimeActions.markAsPaid || res.actionType == TeeTimeActions.payment || res.actionType == TeeTimeActions.Redeem|| res.actionType == TeeTimeActions.addRetailItem || res.actionType == TeeTimeActions.editRetailItem || res.actionType == TeeTimeActions.checkOut || res.actionType == TeeTimeActions.checkInCheckOut || res.actionType == TeeTimeActions.cancelPlayers || res.actionType == TeeTimeActions.noShow ||(teeTimeSetting && teeTimeSetting != null && teeTimeSetting.allowPaymentInCheckIn && res.actionType == TeeTimeActions.checkIn))) {
        this._retailSharedVariableService.SelectedOutletId = res.outlet;
        modalComponentName = PaymentModalComponent;
        if (res.actionType == TeeTimeActions.markAsPaid) {
          modalTitle = this.captions.markAsPaidTitle;
          saveText = this.captions.markAsPaidTitle;
          eventType = ButtonAction.markAsPaid;
        }
        else if (res.actionType == TeeTimeActions.payment) {
          if (res.isDeposit) {
            this.addDeposit(obj.rowObject);
            return;
          }
          modalTitle = this.captions.payTitle;
          saveText = this.captions.pay;
          eventType = ButtonAction.pay;
        }
        else if (res.actionType == TeeTimeActions.Redeem) {
          modalComponentName = RedeemRoundComponent;
          modalTitle = this.captions.zeroMemberCharge;
          saveText = this.captions.continue;
        }
        else if (res.actionType == TeeTimeActions.checkOut) {
          modalComponentName = CheckInOutUndoCheckInOutModalComponent;
          modalTitle = this.captions.checkOutHdr;
          saveText = this.captions.okay;
        }
        else if (res.actionType == TeeTimeActions.checkIn) {
          modalComponentName = CheckInOutUndoCheckInOutModalComponent;
          modalTitle = this.captions.checkInHdr;
          saveText = this.captions.okay;
        }
        else if (res.actionType == TeeTimeActions.checkInCheckOut) {
          modalComponentName = CheckInOutUndoCheckInOutModalComponent;
          modalTitle = this.captions.checkInCheckOutHdr;
          saveText = this.captions.okay;
        }
        else if(res.actionType == TeeTimeActions.cancelPlayers)
        {
          modalComponentName = CancelPlayerModalComponent;
          modalTitle = this.captions.cancelPlayers;
          saveText = this.captions.process;
        }
        else if(res.actionType == TeeTimeActions.noShow)
        {
          modalComponentName = NoShowModalComponent;
          modalTitle = this.captions.noShowIconDescription;
          saveText = this.captions.process;
        }
        popUpWidth = '80%';
        popUpHeight = '80%';
        // this.propertyInfo.SetDefaultOutlet(res.outlet);
        if(res.actionType == TeeTimeActions.addRetailItem || res.actionType == TeeTimeActions.editRetailItem)
        {
          this.router.navigate(['/shop/viewgolfretailitem'],{
            state: {
              routePathFrom: this.router.url,
              title: res.actionType == TeeTimeActions.addRetailItem ?this.captions.addRetailOrRentals : this.captions.editRetailOrRentals, 
              playerInfo : arg,
              outletId : res.outlet,
              playerRetailItems: playerRetailItems,
              playerRentalItems: playerRentalItems,
              isViewOnly: isViewOnly
            }
          });
        }
        if(res.actionType != TeeTimeActions.addRetailItem && res.actionType != TeeTimeActions.editRetailItem)
          this.openTeeActionPopup(modalComponentName, obj.rowObject, popUpWidth, popUpHeight,
          obj.classname, modalTitle, saveText, eventType, obj.showstatic, null, null, obj.isBulk, obj.isMultiple, obj.callbackfunc, obj.teeTimeType, obj.isToMarkCheckIn);
      }
    });
  }
  buildCallBackObject(modalComponentName, arg, popUpWidth, popUpHeight, className, modalTitle, saveText, eventType, showStatic, callback, actionType, isBulk, bulkPlayerUserAccess, normalPlayerUserAccess,
    isMultiple?: boolean, canNavigateToShop: boolean = false, isDeposit: boolean = false, isToMarkCheckIn: boolean = false, isEditPay: boolean = false) {
    let obj = {
      modalComponent: modalComponentName,
      arg: arg,
      popupWidth: popUpWidth,
      popupHeight: popUpHeight,
      classname: className,
      modaltitle: modalTitle,
      savetext: saveText,
      actionType: actionType,
      showstatic: showStatic,
      callbackfunc: callback,
      teeTimeType: actionType,
      isMultiple: isMultiple,
      isBulk: isBulk,
      canNavigateToShop: canNavigateToShop,
      eventType: eventType,
      bulkPlayerUserAccess: bulkPlayerUserAccess,
      normalPlayerUserAccess: normalPlayerUserAccess,
      isDeposit: isDeposit,
      isToMarkCheckIn: isToMarkCheckIn,
      isEditPay: isEditPay
    }
    return obj;
  }
  openBulkModal(res, ref) {
    let obj = ref[0];
    if (res === AlertAction.YES) {
      this.openTeeActionPopup(obj.modalComponent, obj.rowObject, obj.popupWidth, obj.popupHeight,
        obj.classname, obj.modaltitle, obj.savetext, obj.actionType, obj.showstatic, null, null, obj.isBulk, obj.isMultiple, obj.callbackfunc, obj.teeTimeType,undefined,undefined,obj.allocationCodePermissions);
    }
    ref.close();
  }
  openPaymentModal(res, callBackData, isPayWithOutCheckIn: boolean = false) {
    var modalData = callBackData[0].extraParams
    if (res === AlertAction.YES) {
      modalData.isToMarkCheckIn = !isPayWithOutCheckIn ? true : false;
      if (modalData?.arg?.isFromSearch)
        modalData.arg.isFromSearch = false;
      if (modalData?.arg?.isFromPopup && modalData?.arg?.isToFilterCheckInPlayer)
        modalData.arg.isToFilterCheckInPlayer = false
      if (modalData?.canNavigateToShop) {
        this.onNavigateShop(modalData);
      }
      else {
        this.openTeeActionPopup(modalData.modalComponent, modalData.arg, modalData.popupWidth, modalData.popupHeight, modalData.className, modalData.modaltitle,
          modalData.savetext, modalData.eventType, modalData.showstatic, modalData.bulkPlayerUserAccess, modalData.normalPlayerUserAccess, modalData.isBulk,
          modalData.isMultiple, modalData.callbackfunc, modalData.actionType, modalData.isToMarkCheckIn,undefined,undefined,modalData.isEditPay);
      }
    }
    else {
      this.checkDepositValidation(modalData)
    }
  }
  validatePaymentByRequireCheckIn(req, isSearch: boolean = false) {
    var length: any = {
      paid: 0,
      checkIn: 0,
      unPaid: 0,
      checkedInPaid: 0,
      checkedInUnpaidRefund: 0
    };
    var scheduleTeeTime = req.arg;
    if (!isSearch && scheduleTeeTime?.playerDetail && scheduleTeeTime.playerDetail?.length > 0) {
      scheduleTeeTime.playerDetail.forEach(x => {        
        if (((x.playerStatus & PlayerPaymentstatus.paid) != 0) && ((x.playerStatus & PlayerPaymentstatus.checkIn) != 0)) {
          length.checkedInPaid = ++length.checkedInPaid;
        }
        else if (!x.isBlocked && ((x.playerStatus & PlayerPaymentstatus.refund) != 0) && ((x.playerStatus & PlayerPaymentstatus.checkOut) != 0)) {
          length.checkedInUnpaidRefund == ++length.checkedInUnpaidRefund;
        }
        else {
          length.checkIn = (x.playerStatus & PlayerPaymentstatus.checkIn) != 0 ? ++length.checkIn : length.checkIn
          length.paid = (x.playerStatus & PlayerPaymentstatus.paid) != 0 ? ++length.paid : length.paid
        }
        length.unPaid = !x.isBlocked && (x.playerStatus & (PlayerPaymentstatus.unPaid | PlayerPaymentstatus.refund)) && (x.playerStatus & PlayerPaymentstatus.checkIn) == 0 && (x.playerStatus & PlayerPaymentstatus.checkOut) == 0 ? ++length.unPaid : length.unPaid
      });
      if ((length.unPaid > 0 && length.checkIn > 0) || (length.unPaid > 0 && length.checkedInUnpaidRefund > 0)) {
        this._Utilities.showAlert(this.localization.captions.settings.validatePaymentRequireCheckIn, AlertType.Info, ButtonType.YesNo, (yesNo, extraParams) => { this.openPaymentModal(yesNo, extraParams, true) }, [{ extraParams: req }]);
        return;
      }
      else if (length.unPaid > 0) {
        this.checkDepositValidation(req)
        return;
      }
      else if (length.checkIn > 0 || length.checkedInUnpaidRefund > 0) {
        this.validateOutletBeforePayment(req);
        return;
      }
    }
    else if (isSearch) {
      if (((scheduleTeeTime.playerStatus & PlayerPaymentstatus.checkIn) != 0) || ((scheduleTeeTime.playerStatus & (PlayerPaymentstatus.unPaid | PlayerPaymentstatus.refund)) && (scheduleTeeTime.playerStatus & PlayerPaymentstatus.checkOut) != 0)) {
        this.validateOutletBeforePayment(req);
        return;
      }
      else if (scheduleTeeTime.playerStatus & (PlayerPaymentstatus.unPaid | PlayerPaymentstatus.refund)) {
        this.checkDepositValidation(req)
        return;
      }
    }
  }
  public async checkDepositValidation(modalData) {
    if (modalData?.canNavigateToShop) {
      modalData.isDeposit = true;
      this.onNavigateShop(modalData);
    }
    else {
      this.addDeposit(modalData.arg)
    }
  }
  validateOutletBeforePayment(req) {
    if (req?.canNavigateToShop) {
      this.onNavigateShop(req);
    }
    else {
      this.openTeeActionPopup(req.modalComponent, req.arg, req.popupWidth, req.popupHeight, req.className, req.modaltitle,
        req.savetext, req.eventType, req.showstatic, req.bulkPlayerUserAccess, req.normalPlayerUserAccess, req.isBulk,
        req.isMultiple, req.callbackfunc, req.actionType, req.isToMarkCheckIn,undefined,undefined,req.isEditPay);
    }
  }
  onNavigateShop(req) {
    let selectOutletPopup = SelectOutletPopupComponent;
    let selectOutletTitle = this.captions.selectOutletPopupTitle;
    let selectOutletSaveText = this.captions.saveData;
    let selectOutletPopUpWidth = '35%';
    let selectOutletPopupHeight = '45%';
    this.openDefaultOutletPopup(selectOutletPopup, req.arg, selectOutletPopUpWidth, selectOutletPopupHeight, req.className, selectOutletTitle, selectOutletSaveText, req.eventType, req.bulkPlayerUserAccess, req.normalPlayerUserAccess, req.showStatic, req.callback, req.actionType, req.isBulk, req.isMultiple, req.isDeposit, req.isToMarkCheckIn
    );
    return;
  }
  getBulkBookingId(actionArg): string {
    let bulkBookingId = '';
    if (actionArg.playerDetail) {
      let bulkPlayers = actionArg.playerDetail.filter(x => !x.isBlocked && x.teeTimeFormat == TeeTimeFormat.BulkTee);
      if (bulkPlayers && bulkPlayers.length > 0) {
        return bulkPlayers[0].bookingId;
      }
    }
    return bulkBookingId;
  }

  CheckForNormalBooking(actionArg): boolean {
    let isNormal: boolean = false;
    if (actionArg.playerDetail) {
      let normalPlayers = actionArg.playerDetail.filter(x => !x.isBlocked && x.teeTimeFormat !== TeeTimeFormat.BulkTee);
      if (normalPlayers && normalPlayers.length > 0) {
        isNormal = true;
      }
    }
    return isNormal;
  }

  HasBulkPlayers(actionArg): boolean {
    let isBlocked: boolean = false;
    if (actionArg.playerDetail) {
      isBlocked = actionArg.playerDetail.some(x => x.isBlocked);
    }
    return isBlocked;
  }

  HasRefundedPlayersWithMultipleTicket(actionArg): boolean {
    let hasMultipleTickets: boolean = false;
    if (actionArg.playerDetail) {
      let ticketNumbers = actionArg.playerDetail.map(x => x.ticketNumber).distinct();
      hasMultipleTickets = ticketNumbers && ticketNumbers.length > 1 ? true : false;
    }
    return hasMultipleTickets;
  }


  addDeposit(event) {
    if (this._retailSharedVariableService?.SelectedOutletId > 0) {
      event.outletId = this._retailSharedVariableService.SelectedOutletId;
    }
    this.popUpComponentDetails = {
      componentName: AddDepositeModalComponent,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      }
    };
    this._dialog.open(CommonPopupComponent, {
      width: '70%',
      height: '60%',
      data: {
        title: this.captions.addDeposit,
        update: this.captions.process,
        cancel: this.captions.cancel,
        componentDetails: this.popUpComponentDetails,
        info: event,
        actionType: ButtonAction.process,
        automationId:"TeeTimeAction"
      },
      disableClose: true
    });
  }
}
