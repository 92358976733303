import { Component, Input, Output, EventEmitter } from "@angular/core";
import { DialogFooterService } from "./dialog-footer.service";
import { SaveButtonObj } from "../../shared-models";
import { GolfUtilities } from "src/app/shared/utilities/golf-utilities";
import { CartUtilities } from "src/app/common/components/menu/vcart/cart.utilities";

@Component({
  selector: "app-golfdialog-footer",
  templateUrl: "./dialog-footer.component.html",
  styleUrls: ["./dialog-footer.component.scss"],
  providers: [DialogFooterService],
})
export class GolfDialogFooterComponent {
  isEdit: boolean;
  isDisabled: boolean;
  saveButtonText: string;
  cancelButtonText: string;
  captions: { save: string; update: string; cancel: string; saveClose: string };
  @Input("clickThrottleTime") clickThrottleTime: number;
  saveCloseButtonText: string;
  isenableSaveCancel: boolean;
  enableAddToCart: boolean = false;
  isEmbedded: boolean;
  isVcartEdit: boolean = false;
  isDisableSaveOnly: boolean = false;
  cartCaption : string = '';
  @Input("buttonObj")
  set buttonObj(value: SaveButtonObj) {
    this.isEdit = value.isEdit;
    this.isDisabled = value.disabled;
    this.getSaveButtonText(value.customSaveText);
    this.getCancelButtonText(value.customCancelText);
    if(this.cartUtils.isEmbed() && value.showAddToCartButton){
          this.enableAddToCart = true;
      }
    else
      this.enableAddToCart = false;
  }
  @Input("vcartObj")
  set vcartObj(value: { isVcartEdit: boolean }) {
    if (value != undefined) 
      this.isVcartEdit = value.isVcartEdit;
    else
      this.isVcartEdit = false;
    this.cartCaption = this.isVcartEdit ? this._Utilities.localization.captions.teetime.updateCart : this._Utilities.localization.captions.teetime.addToCart;
  }
  @Input("enableSaveCancel")
  set enableSaveCancel(value: boolean) {
    this.isenableSaveCancel = value;
    this.saveCloseButtonText = value ? this.captions.saveClose : null;
    let saveCloseButtonText = this.captions.saveClose;
    value ? (this.saveCloseButtonText = saveCloseButtonText) : null;
  }
  @Input("disableSaveOnly")
  set disableSaveOnly(value: boolean) {
    this.isDisableSaveOnly = value ? value : false;
  }
  automationId:string="";
  @Input('automationId')
  set setAutomationId(value: string) {
    this.automationId = value ? value : '';
  }
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() saveCancel: EventEmitter<any> = new EventEmitter();
  @Output() addToCart: EventEmitter<any> = new EventEmitter();
  constructor(
    private _footerService: DialogFooterService,
    private _Utilities: GolfUtilities,
    private cartUtils: CartUtilities
  ) {
    this.captions = this._footerService.getCaptions();
  }

  // Event Handlers
  saveHandler() {
    this.save.emit();
  }

  cancelHandler() {
    this.cancel.emit();
  }

  saveCancelHandler() {
    this.saveCancel.emit();
  }

  addToCartHandler() {
    this.addToCart.emit();
  }

  // private Methods
  private getSaveButtonText(customButtonText: string) {
    if (customButtonText) {
      this.saveButtonText = customButtonText;
    } else {
      this.saveButtonText = this.isEdit
        ? this.captions.update
        : this.captions.save;
    }
  }

  private getCancelButtonText(customButtonText: string) {
    if (customButtonText) {
      this.cancelButtonText = customButtonText;
    } else {
      this.cancelButtonText = this.captions.cancel;
    }
  }
}
