
import { Component, Inject, OnInit,ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SetPropertyBusiness } from 'src/app/login/set-property/set-property.business';
import { DialogCloseOption } from 'src/app/shared/enums/shared-enums';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JWT_TOKEN, USERS_SESSSIONS_INFO } from 'src/app/app-constants';
import { PropertyService } from 'src/app/common/services/property.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { PropertyInformationDataService } from 'src/app/common/dataservices/authentication/property-information.data.service';
import { ManageSessionService } from 'src/app/login/manage-session.service';
import { UserProperty } from 'src/app/common/Models/common.models';
import { AuthenticationService } from 'src/app/common/shared/services/authentication.service';
import { Host } from 'src/app/common/shared/shared/globalsContant';
import { HttpMethod, HttpServiceCall } from 'src/app/common/shared/shared/service/http-call.service';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { GolfRoutes } from 'src/app/core/extensions/golf-route';
import { ExpireSessionService } from '../expire-session.service';
import { GolfManagementCommunication } from 'src/app/shared/communication/services/golfmanagement.service';
import { LoginRoutes } from '../login-routes';
import { LoginCommunicationService } from '../login-communication.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.html',
  styleUrls: ['./set-property.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ProgressBarComponent {

  DialogCloseOptionEnum = DialogCloseOption;
  captions: any;

  constructor(
    public dialogRef: MatDialogRef<ProgressBarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localization: GolfLocalization) {
    this.captions = this.localization.captions;
  }

  closeDialog(res) {
    this.dialogRef.close(res);
  }
}

@Component({
  selector: 'app-set-property',
  templateUrl: './set-property.component.html',
  styleUrls: ['./set-property.component.scss'],
  providers: [SetPropertyBusiness, PropertyInformationDataService]
})

export class SetPropertyComponent implements OnInit {

  token: string;
  propertyId: number;
  userDetails: any;
  selectedPropertyDetails: any;
  propertyName: string;
  routeParam: string;
  state$: any;
  captions: any;
  propertyValues: any;
  userInfo : any;
  userName: any;
  propertyKey: string = "propertyInfo";
  usersSessionsInfo:any;
  selectedPropertyValues : any;
  private _autoLogOff: any = false;
  private _logOffAfter: number = 1;
  constructor(
    private router: Router,
    private expireSessionService: ExpireSessionService,
    private golfService: GolfManagementCommunication,
    private setPropertyBusiness: SetPropertyBusiness,
    private propertyService: PropertyService,
    private sessionService: ManageSessionService,
    private authentication: AuthenticationService,
    public localization: GolfLocalization,
    public http: HttpServiceCall,
    private utils: GolfUtilities,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private loginService: LoginCommunicationService) {
      const currentNavigation = this.router.getCurrentNavigation();
      const tempState = this.getTempoaryState();
      if (currentNavigation || tempState) {
        this.state$ =  currentNavigation.extras.state || tempState;
        this.setTempoaryState(this.state$);
        this.token = this.state$.token;
        this.propertyId = Number(this.state$.propertyId);
        this.propertyName = this.state$.propertyName;
        this.routeParam = this.state$.routeParam || '/home';
      } else {
        this.route.queryParams.subscribe(params => {
          this.token = params.token;
          this.propertyId = Number(params.propertyId);
          this.propertyName = params.propertyName;
          this.routeParam = params.routeParam || '/home';
        });
      }
      this.routeParam = this.routeParam.replace("/Golf","");
  }

  async ngOnInit() {
    this.captions = this.localization.captions;
    this.dialog.open(ProgressBarComponent, {
      width: '40%',
      height: '40%',
      disableClose: true,
      data: {
        propertyName: this.propertyName
      }
    });
    // sessionStorage.setItem(USER_SESSION, null);
    if (this.token) {
      this.userDetails = await this.setPropertyBusiness.GetLoginDetailsByToken(this.token);
    } else {
      this.userDetails = this.GetUserSessionsInfo();
    }
    sessionStorage.setItem(JWT_TOKEN, this.userDetails.token);
    localStorage.setItem(JWT_TOKEN, this.userDetails.token);
    const propertyDetails: UserProperty = this.userDetails.userProperties.find(
      item => item.propertyId === this.propertyId
    );
    this.selectedPropertyDetails = {
      id: propertyDetails.propertyCode,
      name: propertyDetails.propertyName
    };
    this.propertyService.isGoogleApiLoaded = false;
    this.propertyService.SetUserInfo(this.userDetails);
   
    let usersessionId = '';
    if (propertyDetails.sessionId !== null) {
      usersessionId = propertyDetails.sessionId;
    }else{
      const utcDate: Date = this.localization.getUTCDateTimeNow();
    const token = sessionStorage.getItem('_jwt');
    let sessionData = {
      id: 0,
      userId: Number(propertyDetails["userId"]),
      startTime: this.localization.ConvertDateToISODateTime(utcDate),
      propertyId: Number(propertyDetails['propertyId']),
      productId: Number(propertyDetails['productId']),
      timeZone: this.utils.GetClientTimeZone(),
      token: token
    };

      let response  = (await this.CreateSession(sessionData,false)).result;
      usersessionId = response;
      try{
        if(this.localization.GetSupportUserMailId())
        {
          let data = {
            userEmail : this.localization.GetSupportUserMailId(),
            sessionId : usersessionId
          };
      
          const auditParams = {
            route: LoginRoutes.AuditSupportUser,
            uriParams: '',
            header: '',
            body: data,
            showError: true,
            baseResponse: true
          };
      
          await this.loginService.makePutCall(auditParams, false);
        }
      }
      catch{
        console.log("Error in auditing support user")
      }
    }
    this.propertyService.UpdateUserSessionInfo(this.selectedPropertyDetails, this.userDetails, usersessionId);
    // this.propertyService.setUserSession(String(usersessionId));
     sessionStorage.removeItem('MultiPropertySession');
    this.propertyService.changeTitle();
    this.setpropertyvalues(this.selectedPropertyDetails);
    setTimeout(() => {
      this.dialog.closeAll();
      this.setUserSettings(this.selectedPropertyValues);
      this.router.navigate([this.routeParam], {
        state: { applyFilter: true },
      });
    }, 5000);
  }
 
  async CreateSession(sessionData,isFromPMS: boolean) {
    try {
    return await this.http.CallApiAsync<any>({
      host: Host.authentication,
      callDesc: "CreateSession",
      body: sessionData,
      method: HttpMethod.Post,
      showError: false,
      uriParams:''
    });
  }catch (e) {
    this.http.exceptionHandle(e);
  }
  }

  private setTempoaryState(value) {
    localStorage.setItem('tempState', JSON.stringify(value));
    sessionStorage.setItem('tempState', JSON.stringify(value));
  }

  private getTempoaryState() {
    const tempState = sessionStorage.getItem('tempState');
    if (tempState) {
      return JSON.parse(tempState);
    }
    return null;
  }

  

  public GetUserSessionsInfo(): any  {
    let userSessions: any;
    const sessionDetails = this.getUserSessionsInfoItem(USERS_SESSSIONS_INFO);
    if (sessionDetails) {
        userSessions = JSON.parse(sessionDetails);
    }
    return userSessions;
}

async setpropertyvalues(Selectedproperty: any) {
  //this.usersSessionsInfo = sessionStorage.getItem('usersSessionsInfo');
  this.usersSessionsInfo = JSON.parse(sessionStorage.getItem('usersSessionsInfo'));
  this.propertyValues = this.usersSessionsInfo.userProperties;
  this.userInfo = this.usersSessionsInfo.userLoginInfo;
  this.userName = this.usersSessionsInfo.userLoginInfo.userName;
  let result = this.propertyValues.find(item => item.propertyCode === Selectedproperty.id);
  this.selectedPropertyValues = this.propertyValues.find(item => item.propertyCode === Selectedproperty.id);
  let maxDecimalPlace = result["maximumDecimalPlaces"] ? result["maximumDecimalPlaces"] : 2;
  let userLanguageCode = this.userInfo != null && this.userInfo.languageCode != "" ? this.userInfo.languageCode : result["languageCode"];
  let PropertyValues = "Language=" + result["languageCode"] + "; PropertyCode=" + result["propertyCode"] +
    "; SubPropertyCode=" + result["subPropertyCode"] + "; Currency=" + result["currencyCode"] + "; TenantId=" + result["tenantId"] + "; userName=" + this.userName
    + "; UserId=" + this.userInfo.userId + "; PropertyId=" + result['propertyId'] + "; SubPropertyId=" + result['subPropertyId'] + "; PlatformTenantId=" + result['platformTenantId'] + "; PropertyDate=" + result['propertyDate']
    + "; TimeZone=" + result['timeZone'] + "; PropertyName=" + result['propertyName']
    + "; UserLanguage=" + userLanguageCode + "; ProductId=" + result['productId'] + "; MaxDecimalPlaces=" + maxDecimalPlace;
  sessionStorage.setItem(this.propertyKey, PropertyValues);
  //this.setUserSettings(result);
}

async setUserSettings(properties) {
  /*To get the daysout value*/
  let serviceParams = {
    route: GolfRoutes.GetGolfUserConfig,
    uriParams: { id: this.userInfo.userId },
    header: '',
    body: '',
    showError: true,
    baseResponse: true
  };
  let userConfig: any = await this.golfService.getPromise(serviceParams);

  const userSettings = `daysOut=${userConfig && userConfig.userGolfConfiguration ? userConfig.userGolfConfiguration.daysOut : ''};
        AutoLogOff=${properties ? properties['autoLogOff'] : ''};
        LogOffAfter=${properties ? properties['logOffAfter'] : ''};
        TeeSheetView=${userConfig && userConfig.userGolfConfiguration ? userConfig.userGolfConfiguration.teeSheetView : ''}`;
  sessionStorage.setItem('userSettings', userSettings);
  this.setAutoLogOff();
}

setAutoLogOff() {
  this._autoLogOff = this.localization.GetUserSettings('AutoLogOff');
  const tokenDuration = parseInt(sessionStorage.getItem('loginDuration'));
  if (this._autoLogOff == "true") {
    this.expireSessionService.resetOnTrigger = true;
    this._logOffAfter = +this.localization.GetUserSettings('LogOffAfter');
    this.expireSessionService.startTimer(this._logOffAfter, tokenDuration);
  } else {
    this.expireSessionService.resetOnTrigger = false;
  }
}
private getUserSessionsInfoItem(key: string): string | null {
    return sessionStorage.getItem(key);
}

}
