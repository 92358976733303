<section class="view-refund-deposit-wrapper newVersionWrapper">
    <div mat-dialog-title class="dialog-container__header golf-container--padding">
      <span class="dialog-container__header--title">{{captions.hdr_viewRefundDeposit}}</span>
      <span [attr.automationId]="'Btn_copyLesson_cancel'"  class="dialog-container__header--close icon-Cancel" (click)="cancel($event)"></span>
    </div>
    <div class="dialog-container__content ag_p--5">
        <div class="top-section bodyWrap mb-2 p-2">
          <div class="ag_display--flex align-items-center pb-2">
            <span class="ag_mr--2">{{captions.lbl_teetimeDateTime}}:</span>
            <span class="ag_font--bold ag_mr--4">{{teeTimeDate}}</span>
            <span class="ag_mr--2">{{captions.lbl_courses}}:</span>
            <span class="ag_font--bold ag_mr--2">{{data.course.course}}</span>
          </div>
          <div>
            <mat-radio-group [(ngModel)]="viewByOption">
              <mat-radio-button class='ag_display--inblock ag_form--radio-button' [value]="viewBy.byTicket" (change)="radioButtonChage($event)" [checked] = "true">
                  {{captions.lbl_byTicket}}</mat-radio-button>
              <mat-radio-button class='ag_display--inblock ag_form--radio-button' [value]="viewBy.byPlayer" (change)="radioButtonChage($event)">
                  {{captions.lbl_byPlayer}}</mat-radio-button>
          </mat-radio-group>
          </div>
        </div>
        <div *ngIf="viewByOption == viewBy.byTicket" class="table-height bodyWrap">
          <app-cdkvirtual [options]="ticketTableOptions" [headerOptions]="TicketHeaderOptions" 
                [tableContent]="ticketTableContent" [childTemplate]="childTemplate"
                (EmittedData)='tableAction($event)'>
          </app-cdkvirtual>
        </div>
        <div *ngIf="viewByOption == viewBy.byPlayer" class="table-height bodyWrap">
          <app-cdkvirtual  [options]="playerTableOptions" [headerOptions]="playerHeaderOptions"
                [tableContent]="playerTableContent" [childTemplate]="childTemplate"
                (EmittedData)='tableAction($event)'>
          </app-cdkvirtual>
        </div>
    </div>
    <div class="ag_mt--auto ag_display--flex footer golf-container--padding dialog-container__actions">
        <app-button [automationId]="'viewRefundDeposit'" [buttontype]="actionButton" (valueChange)='save($event)'></app-button>
        <app-button [automationId]="'viewRefundDeposit'" [buttontype]="cancelButton" class="ag_ml--2" (valueChange)='cancel($event)'></app-button>
      </div>
</section>

<ng-template #childTemplate let-element="element" let-key="key" let-index="idx">
  <ng-container [ngSwitch]="key">
    <ng-container *ngSwitchCase="'playerName'">
      <span class="ag_pr--2">{{element[key][0]}}</span>
      <span class="ag_link" *ngIf="element[key].length > 1" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{'element':element[key]}" >+{{element[key].length-1}} {{captions.lbl_more}}</span>
    </ng-container>
    <ng-container *ngSwitchCase="'remainingAmountString'">
      <input class='table-input alignRight' [(ngModel)]="element['remainingAmountString']" [ngClass]="[element['checked'] ? '' : 'ag_section--disable']"
      (input)="inputChange($event,element)" (click)="$event.stopPropagation();" CurrencyFormatter />
    </ng-container>
  </ng-container>
</ng-template>

<mat-menu #menu="matMenu">
  <ng-template let-element="element" matMenuContent>
    <div class="p-2">
      <div *ngFor="let item of element; let i=index">
        <div *ngIf="i != 0 ">{{item}}</div>
      </div>
    </div>
  </ng-template>
</mat-menu>