<section class="filterdataSection">
    <!-- Search Filter Section -->
    <div class="FDS-srchHeader mr-3  mt-2">
        <div class="align-self-center search-content ag_display--flex align-items-baseline">
            <mat-form-field class="FDS-searchbox searchpt custSearchbox ag_w--90" [floatLabel]="floatLabelNever">
                <input matInput type="text" [(ngModel)]="searchText" class="pr-4 pl-1"
                    placeholder="{{CommonplaceHolder}}" (keyup)="SearchCategories($event,'search')">
                <i  aria-hidden="true" [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'"
                    (click)="searchText='';SearchCategories($event,'search')"></i>
            </mat-form-field>
            <span class="ag_ml--auto" [ngClass]="selecteddatalist?.length > 0 ? 'ag_link--sm' :'ag_section--disable'" (click)="clearAllSelectedList()">{{captions.lbl_clearAll}}</span>
        </div>
        <div class="filterAlpbts d-inline-block position-relative" style="display: none !important;">
            <swiper [config]="config">
                <span class="hiddenOverFlow"><button mat-button class="LW12 blckClr"
                        title="{{this.captions.common.all}}"
                        [ngClass]="{'body-bgcolor whitecolor':  (selectedAlphabets==captions.common.all)}"
                        (click)="searchText='';SearchCategories($event,'search')"> {{captions.common.all}}</button></span>
                <span class="hiddenOverFlow" *ngFor="let alphabet of alphabets;let i = index">
                    <button mat-button class="LW12 blckClr" (click)="SearchCategories($event,'click', alphabet)"
                        [ngClass]="{'body-bgcolor whitecolor': (selectedAlphabets==alphabet) }">{{alphabet}}</button>
                   
                </span>
            </swiper>
        </div>
    </div>
    <!-- Content To be Displayed in More -->
    <div class="MS-moreCtnt mr-3">
        <div class="d-inline-block checkboxdata"  *ngIf="datalist?.length != 0" [matTooltip]="captions.common.all">
            <mat-checkbox [checked]="allChecked" 
            (change)="allCheckboxClick($event)" class=" filtercheckbox LW14 legend_color all-day mr-2">
                {{captions.common.all}}</mat-checkbox>
        </div>
        <div *ngFor="let DL of datalist;let i=index;" class="d-inline-block checkboxdata" [matTooltip]="DL['desc']">
            <mat-checkbox   [checked]="(selecteddatalist.indexOf(DL.id) != -1)" (change)="checkboxClick($event,DL)" class=" filtercheckbox LW14 legend_color all-day mr-2">
                {{DL['desc']}}</mat-checkbox>
        </div>
    </div>
</section>