import { PubSub } from "pubsub-ts";
import { RetailEventParameters, RetailEventType } from "../../../retail/shared/events/event.model";
import { BaseEventSubscriber } from "../../../retail/shared/events/pubsub/base.event.subscriber";
import { retailPublisher } from "../../../retail/shared/events/pubsub/retail.publishers";
import { SubscriptionHandler } from "./subscription.handler";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class RetailEventSubscriber extends BaseEventSubscriber {

    constructor(private _subscriptionHandler: SubscriptionHandler) {
        super();
    }

    registerMe() {
        retailPublisher.addSubscriber(this);
        this.addSubscriber(this.onRetailEmit.bind(this));
        this.start();
    }

    unregisterMe() {
        retailPublisher.delete(this);
    }

    /**
     * Subscriber
     * @param {PubSub.Notification} notification
     * @memberof RetailEventSubscriber
     */
    async onRetailEmit(notification: PubSub.Notification): Promise<void> {

        let eventParams: RetailEventParameters<any> = notification.body;

        // Add only function calls - avoid implementations here
        switch (eventParams.eventType) {
            case RetailEventType.Void:            
                await this._subscriptionHandler.handleVoid(eventParams);
                break;
            case RetailEventType.Correct:
                await this._subscriptionHandler.handleCorrect(eventParams);
                break;
            case RetailEventType.Deposit:
                await this._subscriptionHandler.handleDeposit(eventParams);
                break;
            case RetailEventType.Cancel:
                await this._subscriptionHandler.handleCancel(eventParams);
                break;
            case RetailEventType.SyncUpTransaction:
                await this._subscriptionHandler.handleSyncUp(eventParams);
                break;
            case RetailEventType.TeeTimeLinkRetailItem:
                await this. _subscriptionHandler.handleTeeTimeRetailItem(eventParams);
                break;
            case RetailEventType.TeeTimeCancellation:
                await this._subscriptionHandler.handledTeeTimeCancellation(eventParams);
                break;
            case RetailEventType.TeeTimeNoShow:
                await this._subscriptionHandler.handledTeeTimeNoShow(eventParams);
                break;
            case RetailEventType.UpdateCancellationNoShowCharge:
                    await this._subscriptionHandler.handledTeeTimePendingSettlement(eventParams);
                    break;
            default:
                break;
        }

        // trigger callback
        if (eventParams.callBack && typeof eventParams.callBack == "function") {
            eventParams.callBack(eventParams);
        }
    }

}