import { Injectable } from '@angular/core';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { SubPropertyDataService } from 'src/app/retail/retail-code-setup/retail-outlets/subproperty-data.service';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { GolfNowConfigAPI, GolfNowConfigUI, Settings } from './golfnow-setup.model';
import { SettingsDataService } from 'src/app/shared/data-services/golfmanagement/settings.data.service';
import { cloneDeep } from 'lodash';
import { SettingModule, SettingScreen } from 'src/app/shared/global.constant';
import { PlayerTypeService } from 'src/app/shared/data-services/golfmanagement/playertype.data.service';
import { PlayerType } from '../../../golf-setup/code-setup/player-type/player-type.modal';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { UserDataService } from 'src/app/shared/data-services/tenantmanagement/user.data.service';
import { Observable, from } from 'rxjs';
import { PaymentBusinessService } from "src/app/shared/data-services/payment/payment-business.service";
import { Outlet ,PaymentMethod } from "src/app/retail/retail.modals";
import { UntypedFormGroup } from '@angular/forms';



@Injectable()
export class GolfNowSetupBusiness {
    captions: any;
    unModifiedData: any;
    unModifiedFormValue: GolfNowConfigUI;

    constructor(private localization: GolfLocalization,
        private _utils: GolfUtilities,
        private _subPropertyDataService: SubPropertyDataService,
        private _userAccessBusiness: UserAccessBusiness,
        private _settingsDataService: SettingsDataService,
        private _playerTypeService: PlayerTypeService,
        private _userDataService: UserDataService,
        public _paymentService: PaymentBusinessService ) {
    }

    public GetCaptions() {
        this.captions = this.localization.captions.settings;
        return this.captions;
    }

    public async getOutlets(): Promise<Outlet[]> {
        return await this._subPropertyDataService.getOutletsByPropertyAndProduct();
    }
    
    public async GetPlayerTypes(): Promise<PlayerType[]> {
        return await this._playerTypeService.getAllPlayerTypes();
    }

    public async GetPlayerTypesForRBook(): Promise<PlayerType[]> {
        var playerTypes =  await this._playerTypeService.getAllPlayerTypesForRBook();
        return playerTypes.filter(x=> x.isAvailableOnWeb);
    }

    public shouldDisableSave(form: UntypedFormGroup): boolean {
        return !(form.dirty && form.valid);
    }

    public getUserDefinedPaymentMethods(): Observable<PaymentMethod[]> {
        let userDefinedPaymentMethods =  this._paymentService.getUserDefinedPaymentMethod();
        return userDefinedPaymentMethods;
    }

    playerTypeMapper(data) {
        return data.map(x => this.uiPlayerTypeMapper(x));
      }
    
    uiPlayerTypeMapper(option): DropdownOptions {
    return {
        id: option.id,
        value: option.type,
        viewValue: option.type,
        checked: true
    }
    }

    uiPaymentMethodMapper(option): DropdownOptions {
        return {
            id: option.paymentTypeId,
            value: option.paymentTypeId,
            viewValue: option.paymentMethod,
        }
    }

    uiOutletOptionMapper(option): DropdownOptions {
        return {
            id: option.id,
            value: option.id,
            viewValue: option.outletName,
        }
    }

    public async fetchData(playerTypeDetail): Promise<GolfNowConfigUI> {
        const apiData: Settings<GolfNowConfigAPI> = await this._settingsDataService.getSettings<GolfNowConfigAPI>(
            SettingModule.SystemSetup,
            SettingScreen.GolfNowIntegration);
        let formData: GolfNowConfigUI;
        if (apiData) {
            formData = this.uiMapper(apiData.configValue);
            this.unModifiedData = cloneDeep(apiData);
            this.unModifiedFormValue = cloneDeep(formData);
        }
        return formData;
    }


    async validateBreakPoints(form): Promise<boolean> {
        const result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.GOLFNOWSETUP, true);
        if (!result.isAllow) {
            this._utils.disableControls(form);
        }
        return result.isAllow;
    }

    public saveGolfNowSetup(value): void {
        console.log(value);
        const dataToAPI: GolfNowConfigAPI = this.apiMapper(value);
        const settings: Settings<GolfNowConfigAPI> = {
            id: this.unModifiedData ? this.unModifiedData.id : 0,
            moduleName: SettingModule.SystemSetup,
            screenName: SettingScreen.GolfNowIntegration,
            configValue: dataToAPI,
            defaultValue: this.unModifiedData ? this.unModifiedData.defaultValue : null
        };
        this._settingsDataService.updateSettings(settings);
        this.unModifiedData = cloneDeep(settings);
        this.unModifiedFormValue = cloneDeep(value);
    }

    private apiMapper(formData: GolfNowConfigUI): GolfNowConfigAPI {
        let data: GolfNowConfigAPI;
        if (formData) {
            let defaultPlayerAry = [];
            let selectedDefaultPlayerType:any =  formData.defaultPlayerType;
            defaultPlayerAry.push(selectedDefaultPlayerType);
            let arr = formData.configPairs;
            let configDatas = [];
            arr.forEach(x => {
            let configData = {};
            configData[x['config_key']] = x['config_value'];
            configDatas.push(configData);
            });
            data = {
                defaultOutlet: formData.defaultOutlet,
                bookingDaysOut: formData.bookingDaysOut,
                defaultPlayerTypes: defaultPlayerAry, //formData.defaultPlayerTypes.length>0 ? formData.defaultPlayerTypes.map(x=>x.id) : formData.defaultPlayerTypes,
                paymentMethod : formData.paymentMethod,
                allowCancellationOfGolfNowBooking: formData.allowCancellationOfGolfNowBooking,
                enableDynamicPricing : formData.enableDynamicPricing,
                isPaidAsDeposit : formData.isPaidAsDeposit,
                ignoreDueOnlineFee : formData.ignoreDueOnlineFee,
                paymentMethodReceiptComment : formData.paymentMethodReceiptComment,
                configPairs : configDatas
            };
        }
        return data;
    }

    private uiMapper(apiData: GolfNowConfigAPI): GolfNowConfigUI {
        let data: GolfNowConfigUI;
        if (apiData) {
            data = {
                defaultOutlet: apiData.defaultOutlet,
                bookingDaysOut: apiData.bookingDaysOut,
                defaultPlayerType: apiData.defaultPlayerTypes, //formData.defaultPlayerTypes.length>0 ? formData.defaultPlayerTypes.map(x=>x.id) : formData.defaultPlayerTypes,
                paymentMethod : apiData.paymentMethod,
                allowCancellationOfGolfNowBooking: apiData.allowCancellationOfGolfNowBooking,
                enableDynamicPricing : apiData.enableDynamicPricing,
                isPaidAsDeposit : apiData.isPaidAsDeposit,
                ignoreDueOnlineFee : apiData.ignoreDueOnlineFee,
                paymentMethodReceiptComment : apiData.paymentMethodReceiptComment,
                configPairs : apiData.configPairs
            };
        }
        return data;
    }
}
