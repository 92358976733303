import { Injectable } from "@angular/core";
import {
  TeeTimeActions,
  TeeTimeActionsBase,
} from "src/app/tee-time-actions/tee-time-action.base";
import { UserAccessBreakPoints } from "src/app/shared/constants/useraccess.constants";
import {
  TeeActionsClick,
  TeeSheetSkeletonData,
  TeeSheetGridContent,
  Allocation,
  ScheduleStatus,
  Status,
  TeeTimeFormat,
  TeeSheetPlayerDetails,
  BookingSource
} from "src/app/shared/models/teesheet.form.models";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GolfLocalization } from "src/app/core/localization/golf-localization";
import {
  SlideInputs,
  ComponentDetails,
  AlertType,
  ButtonType,
  AlertAction,
  ActionPageId,
  TeeTimeAllocationBlock,
} from "src/app/shared/shared-models";
import { GolfUtilities } from "src/app/shared/utilities/golf-utilities";
import { CourseDataService } from 'src/app/shared/data-services/golfmanagement/course.data.service';
import { TeeTimeComponent } from "src/app/tee-time-actions/teetime/tee-time.component";
import { Subject, Subscription } from "rxjs";
import { PlayerPaymentstatus, TeeTimeAllocationBlockEvent } from "../search/search-model";
import { TeeTimesActionBusiness } from "./tee-action.business";
import { SettingsDataService } from "src/app/shared/data-services/golfmanagement/settings.data.service";
import {
  DEFAULT_TEESHEET_REFRESH_TIME_IN_MINUTES,
  ButtonAction,
  TempHoldStatus,
  Product,
} from "src/app/shared/global.constant";
import _ from "lodash";
import { API as Settings } from "src/app/settings/system-setup/tee-times/tee-times.modal";
import { ReinstateModalComponent } from "src/app/tee-time-actions/reinstate-modal/reinstate-modal.component";
import { CommonPopupComponent } from "src/app/shared/components/common-popup/common-popup.component";
import { takeUntil } from "rxjs/operators";
import { GolfPropertyInformation } from "src/app/core/services/golf-property-information.service";
import {
  TeeTimeModel,
  ScheduledTeeTimeTimePlayer,
  TeeTimeTabs,
  TeeTimeCheckInOutModel,
} from "src/app/tee-time-actions/teetime/tee-time.model";
import { PaymentMethods } from "src/app/retail/shared/business/shared.modals";
import { AllocationBlockDataService } from "src/app/shared/data-services/golfschedule/allocationblock.data.service";
import { GolfTeeTimeAllocationBlockService } from "src/app/settings/golf-setup/golf-tee-time-allocation-block/golf-tee-time-allocation-block.service";
import { API } from "src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model";
import { RedeemRainCheckService } from "src/app/shared/data-services/payment/raincheckredeem.data.service";
import { PropertySettingDataService } from "src/app/retail/sytem-config/property-setting.data.service";
import { Amount, SystemConfiguration } from "src/app/retail/retail.modals";
import { CMSBusinessService } from "src/app/retail/shared/business/CMS-business.service";
import { AllocationBlockDataService as AllocationBlockManagementDataService } from "src/app/shared/data-services/golfmanagement/allocationblock.data.service";
import { allocationBlockPlayerTypePermission } from "src/app/settings/user-setup/booking-permission/booking-permission.model";
import { cloneDeep } from "lodash";
import { RefundBussiness } from "src/app/tee-time-actions/refund-modal/refund-bussiness";
import { Router } from "@angular/router";
import { UserAccessModel } from "src/app/common/dataservices/authentication/useraccess-model.model";
import { TeeTimeService } from "src/app/tee-time-actions/teetime/tee-time.service";
import { Guid } from "guid-typescript";
import { guestType } from "src/app/lessons/lessons-modal/player-info/player-info.model";
import { LinkMultipackBusiness } from "src/app/tee-time-actions/link-multipack/link-multipack-business";
import { RetailSharedVariableService } from "src/app/retail/shared/retail.shared.variable.service";
import { RetailBussinessService } from "src/app/shared/retail.bussiness";
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";
import { TeeTimesActionService } from "../../shared/data-services/golfschedule/teeTimesAction.data.service";
import { CommonUtilities } from "../../common/shared/shared/utilities/common-utilities";
import { DMConfigDataService } from "src/app/common/dataservices/datamagine-config.data.service";
import { EformsBusiness } from "src/app/common/data-magine/dm-eforms/dm-eforms.business";
import { CartUtilities } from "src/app/common/components/menu/vcart/cart.utilities";
import { PlayerDetail } from "src/app/tee-time-actions/teetime/tee-time.model";
import { GolfUserConfigDataService } from "src/app/shared/data-services/golfmanagement/golfuser.config.data";
import { TeeSheetSingleCourse } from "./tee-sheet/tee-sheet.singleCourse";
import { TeeSheetsDataService } from "src/app/shared/data-services/golfschedule/teesheets.data.service";
import { TeeSheetMapper } from "../tee-sheet/business/tee-sheet.mapper";
import * as Courses from 'src/app/settings/golf-setup/code-setup/course/create-course-modal/create-course-model';
import { Options } from 'src/app/dynamicforms/form-type.entity';
import { TeeTimeSlotDetail } from "../tee-sheet/tee-sheet-booking/tee-sheet-booking-model";
import {GroupRetailItems,SelectedProducts} from 'src/app/retail/shared/service/common-variables.service';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { RetailItemType } from "src/app/retail/retail.modals";
import { TeeTimeCancellationNoShowFeeUtilities } from 'src/app/tee-time-actions/noshow-cancellation-fees-tee-time-utilities';

@Injectable()
export class TeeSheetSharedBusiness extends TeeTimeActionsBase {
  teeTimeConfig: Settings.TeeTimeConfig;
  token: string = "";
  courses$: Promise<Options[]>;
  course: Courses.Course[] = [];
  allocationBlocks: API.TeeTimeAllocationBlock[] = [];
  retainWithNoActionClearCancel:boolean = false;

  constructor(
    private _teeTimesActionBusiness: TeeTimesActionBusiness,
    private _propertyInformation: GolfPropertyInformation,
    private _settingsDataService: SettingsDataService,
    private dialog: MatDialog,
    private _localization: GolfLocalization,
    private _utilities: GolfUtilities,
    private _courseDataService:CourseDataService,
    private _allocationBlockService: AllocationBlockDataService,
    private _golfTeeTimeAllocationBlockService: GolfTeeTimeAllocationBlockService,
    private _rainCheckRedeemCheck: RedeemRainCheckService,
    private _propertySettingDataService: PropertySettingDataService,
    private _cmsService: CMSBusinessService,
    private _Router: Router,
    private _allocationCodeManagementService: AllocationBlockManagementDataService,
    private _refundBussiness: RefundBussiness,
    public _teeTimeService: TeeTimeService,
    private _linkMultipackBusiness: LinkMultipackBusiness,
    public _retailSharedService: RetailSharedVariableService,
    private _retailBusinessService: RetailBussinessService,
    public _retailSharedVariableService: RetailSharedVariableService,
    private dmConfigDataService: DMConfigDataService,
    public propertyInfo: RetailPropertyInformation,
    public _teeTimesActionService: TeeTimesActionService,
    private utils: CommonUtilities,
    public business: EformsBusiness,
    private _cartUtils: CartUtilities,
    private _golfUserConfigDataService: GolfUserConfigDataService,
    private _teeSheetSingleCourse: TeeSheetSingleCourse,
    private _TeeSheetsDataService:TeeSheetsDataService,
  private _teeTimeCancellationNoShowFeeUtilities: TeeTimeCancellationNoShowFeeUtilities,
    private _retailFeatureFlag: RetailFeatureFlagInformationService ) {
    super(
      dialog,
      _utilities,
      _localization,
      _retailSharedVariableService,
      propertyInfo,
      _teeTimeService,
      business,_Router,
          );
    this.captions = this._localization.captions.teetime;
  }

  sliderInputs: SlideInputs;
  componentDetails: ComponentDetails;
  private triggerForRefresh = new Subject<void>();
  bookingPopupSubscription: Subscription;
  allocationBlockDataOptions: API.TeeTimeAllocationBlock[];

  public async getGridRefreshTime$(
    date,
    allocationBlocks?: API.TeeTimeAllocationBlock[]
  ): Promise<number | null> {
    date = date ? new Date(date) : new Date();
    const currentDay: number = this._localization.getDate(date).getDay();
    const currentDayCode: string = this._localization.daysArray.find(
      (d) => d.id === currentDay
    ).code;
    const milliSecondToMinute = (timeInMillisecond: number) =>
      timeInMillisecond * 60000;
    const teeConfig = await this._settingsDataService.getTeeTimeSetting();
    this.allocationBlockDataOptions =
      await this.GetAllocationBlocksWithEmptyEntry(allocationBlocks);
    this.teeTimeConfig = teeConfig;
    let minutesToRefresh = teeConfig.refreshTime.find(
      (s) => s.dayOfWeekCode === currentDayCode
    );
    let intervalTime: number =
      this.generateRefreshIntervalInMinutes(minutesToRefresh);

    return minutesToRefresh.autoRefresh
      ? milliSecondToMinute(intervalTime)
      : null;
  }

  async getDmconfig() {
    this.dmConfig = await this.dmConfigDataService.getDataMagineConfig();
    return this.dmConfig;
  }

  private async GetAllocationBlocksWithEmptyEntry(
    allAllocationBlocks?: API.TeeTimeAllocationBlock[]
  ): Promise<API.TeeTimeAllocationBlock[]> {
    let newAllocationBlocks: API.TeeTimeAllocationBlock[] = [];
    let allocationBlocks =
      allAllocationBlocks != null && allAllocationBlocks.length > 0
        ? allAllocationBlocks
        : await this._allocationBlockService.getAllocationBlocks(false);
    let emptyAllocationBlock = {} as API.TeeTimeAllocationBlock;
    emptyAllocationBlock.id = 0;
    newAllocationBlocks.push(emptyAllocationBlock);
    if (allocationBlocks && allocationBlocks.length > 0) {
      newAllocationBlocks.push(...allocationBlocks);
    }
    return newAllocationBlocks;
  }

  private generateRefreshIntervalInMinutes(value: Settings.RefreshTime) {
    let intervalInMin: number = DEFAULT_TEESHEET_REFRESH_TIME_IN_MINUTES;
    // In case of 0 minutes or error in config , refresh time defaults to Configuration
    if (value && value.minutesToRefresh && value.minutesToRefresh > 0) {
      intervalInMin = value.minutesToRefresh;
    }
    return intervalInMin;
  }

  async GetAllocationBlocks(): Promise<API.TeeTimeAllocationBlock[]> {
    return await this._allocationBlockService.getAllocationBlocks(false);
  }

  async GetTeeTimeAllocationBlockByCourseIdAllocationDateTime(
    teeRowData: TeeSheetSkeletonData
  ): Promise<TeeTimeAllocationBlock> {
    return await this._golfTeeTimeAllocationBlockService.getTeeTimeAllocationBlockByCourseIdAllocationDateTime(
      teeRowData.course.id,
      teeRowData.time,
      teeRowData.originalHoleNumber
    );
  }

  async GetTeeTimeAllocationBlocksByDateRange(
    courseId: number,
    startTime: Date | string,
    endTime: Date | string
  ): Promise<TeeTimeAllocationBlock[]> {
    return await this._golfTeeTimeAllocationBlockService.getTeeTimeAllocationBlocksByCourseDateRange(
      courseId,
      startTime,
      endTime
    );
  }

  async getTeeTimeAllocationBlocksByDateRange(
    courseId: number,
    startDate: Date | string,
    endDate: Date | string
  ): Promise<TeeTimeAllocationBlock[]> {
    return await this._golfTeeTimeAllocationBlockService.getTeeTimeAllocationBlocksByCourseDateRange(
      courseId,
      startDate,
      endDate
    );
  }
  public getUseRetailInterface() {
    return this._propertyInformation.UseRetailInterface;
  }
  async mapSliderInputWithRowData(teeRowData: TeeSheetSkeletonData): Promise<SlideInputs> {
    let teeTimeSettings = JSON.parse(sessionStorage.getItem("TEETIMESETTING"));
    const data = teeRowData.unmodifiedSlotData as TeeSheetGridContent;
    let blockedPlayers = teeRowData.playerDetail.filter((x) => !x.isBlocked);
    let disableShop: boolean = teeRowData.playerDetail
      .filter((x) => !x.isBlocked)
      .every(
        (p) =>
          (p.playerStatus & PlayerPaymentstatus.paid) != 0 &&
          p.transactionId > 0
      );
    let teeTimeDetail: boolean =
      teeRowData.playerDetail.length == 4 &&
      teeRowData.playerDetail.every(
        (p) => (p.playerStatus & PlayerPaymentstatus.paid) != 0
      );
    let isRefundRequired =
      blockedPlayers && blockedPlayers.length > 0
        ? blockedPlayers.some(
            (p) =>
              (p.playerStatus & PlayerPaymentstatus.paid) != 0 &&
              p.transactionId > 0
          )
        : false; //TO-DO Check this player has paid or has any deposits
    let isRefunded = teeRowData.playerDetail
      .filter((x) => !x.isBlocked)
      .every(
        (p) =>
          (p.playerStatus & PlayerPaymentstatus.refund) != 0 &&
          p.transactionId > 0
      );
    let isRefundedPlayersAvailable = teeRowData.playerDetail
      .filter((x) => !x.isBlocked)
      .some(
        (p) =>
          (p.playerStatus & PlayerPaymentstatus.refund) != 0 &&
          p.transactionId > 0
      );    
    let restrictPayAfterRefundForPreviousDay = teeTimeSettings && teeTimeSettings?.restrictPayAfterRefundForPreviousDay;
    let noShow: boolean =
      teeRowData.playerDetail.length > 0 &&
      blockedPlayers &&
      blockedPlayers.length > 0 &&
      blockedPlayers.every((p) => p.playerStatus == PlayerPaymentstatus.noShow)
        ? true
        : false;
    let paidNoShow: boolean =
      teeRowData.playerDetail.length > 0 &&
      blockedPlayers &&
      blockedPlayers.length > 0 &&
      blockedPlayers.every(
        (p) =>
          p.playerStatus == PlayerPaymentstatus.noShow && p.transactionId > 0
      )
        ? true
        : false;
    let startHole =
      teeRowData.hole &&
      (teeRowData.hole.includes("A") || teeRowData.hole.includes("B"))
        ? teeRowData.hole.slice(0, -1)
        : teeRowData.hole;
    startHole = startHole ? startHole : "1";
    let isHistoricalData = this.isHistoricalRecord(teeRowData);
    let isLinkMultipack =
      teeRowData.playerDetail.filter(
        (x) =>
          !x.isBlocked &&
          x.multiPackTransactionDetailId == 0 &&
          (x.imageReferenceId != Guid.EMPTY || x.memberNumber != "") &&
          x.scheduledTeeTimePlayerFee.isCartFeeNegotiable == false &&
          x.scheduledTeeTimePlayerFee.isGreenFeeNegotiable == false &&
          (x.playerStatus & PlayerPaymentstatus.paid) == 0
      ).length > 0
        ? false
        : true;
    let isUnLinkMultipack =
      teeRowData.playerDetail.filter(
        (x) =>
          !x.isBlocked &&
          x.multiPackTransactionDetailId != 0 &&
          (x.imageReferenceId != Guid.EMPTY || x.memberNumber != "") &&
          x.scheduledTeeTimePlayerFee.isCartFeeNegotiable == false &&
          x.scheduledTeeTimePlayerFee.isGreenFeeNegotiable == false &&
          (x.playerStatus & PlayerPaymentstatus.paid) == 0
      ).length > 0
        ? false
        : true;
    let isMarkAsPaidAvailable =
      teeRowData.playerDetail.length > 0
        ? !teeRowData.playerDetail.some(
            (c) =>
              !c.isBlocked &&
              !(
                c.playerStatus & PlayerPaymentstatus.paid ||
                c.playerStatus & PlayerPaymentstatus.noShow
              ) &&
              c.memberNumber == "" &&
              c.multiPackTransactionDetailId == 0
          )
        : true;
    let isDiableAuthorize = teeRowData.playerDetail.length > 0 &&
      blockedPlayers &&
      blockedPlayers.length > 0 &&
      teeRowData.playerDetail.length > 0
        ? teeRowData.playerDetail.every(
            (c) =>
              (
                c.isCheckedIn || c.isCheckedOut
              )
          )
        : true;
    this.sliderInputs = {
      pageId: ActionPageId.teeSheet,
      buttonText: this.sliderButtonTextGenerator(teeRowData, teeTimeDetail),
      status: this.assignStatus(teeRowData).name,
      statusColor: this.assignStatus(teeRowData).colorCode,
      scheduleStatus: this.assignStatus(teeRowData).scheduleStatus,
      viewedByStarter: teeRowData.viewedByStarter,
      teeGridSlotId: teeRowData.teeGridSlotId,
      allocationDateTime: teeRowData.allocationDateTime,
      checkInCheckOutActions: [],
      details: [
        {
          label: this.captions.course,
          text: teeRowData.course.course,
        },
        {
          label: this.captions.allocationBlock,
          text: teeRowData.allocation.name,
          colorCode:
            teeRowData.allocation.colorCode != ""
              ? teeRowData.allocation.colorCode
              : "#ffffff",
        },
        {
          label: this._localization.captions.settings.startHole,
          text: startHole,
          startHole: Number(startHole),
        },
      ],
      dateTime: {
        dateLabel: this.captions.date,
        dateText: this._localization.LocalizeDate(teeRowData.time),
        timeLabel: this.captions.time,
        timeText: this._localization.LocalizeTime(teeRowData.time, true),
      },

      players: this.getPlayers(data ? data.playerDetail : []),
      deposit:
        teeRowData.unmodifiedSlotData &&
        teeRowData.unmodifiedSlotData.depositAmount
          ? teeRowData.unmodifiedSlotData.depositAmount
          : "",
      actionTitle: this.captions.teeActions,
      data: teeRowData,
      allocationBlockOptions: this.allocationBlockDataOptions,
      isDisable: this.isHistoricalRecord(teeRowData),
      originalHoleNumber: teeRowData.originalHoleNumber,
      holeNumber: teeRowData.hole,
      scheduledTeeTimeId: teeRowData.scheduledTeeTimeId
        ? teeRowData.scheduledTeeTimeId
        : 0,
      isRefundedPlayersAvailable: teeRowData.playerDetail.some(
        (x) => (x.playerStatus & PlayerPaymentstatus.refund) != 0
      ),
    };
    var scheduledStatus = this.assignStatus(teeRowData).scheduleStatus;
    if (
      this.sliderInputs.players.length > 0 &&
      this.sliderInputs.players.length ===
        this.sliderInputs.players.filter((x) => x.isBlocked === true).length
    ) {
      this.sliderInputs.actions = this.getActionItems(
        ScheduleStatus.open,
        this.isHistoricalRecord(teeRowData),
        isRefundRequired,
        disableShop,
        isRefunded,
        noShow,
        paidNoShow,
        isRefundedPlayersAvailable,
        isMarkAsPaidAvailable,
        restrictPayAfterRefundForPreviousDay
      );
      this.sliderInputs.actions.find((x) => x.id === "clearPlayers").isDisable =
        false;
      this.sliderInputs.actions.find((x) => x.id === "holdTeeTime").isDisable =
        true;
      if(isDiableAuthorize && this.sliderInputs.actions.filter((x) => x.id == "authorize").length>0)this.sliderInputs.actions.find((x) => x.id === "authorize").isDisable =
      true;
      if (
        this.sliderInputs.players?.filter((x) => x.isBlocked === true)?.length >
        0
      ) {
        this.sliderInputs.actions.find(
          (x) => x.id === "deleteTeeTimeSlot"
        ).isDisable = true;
      }
    } else {
      this.sliderInputs.actions = this.getActionItems(
        scheduledStatus,
        this.isHistoricalRecord(teeRowData),
        isRefundRequired,
        disableShop,
        isRefunded,
        noShow,
        paidNoShow,
        isRefundedPlayersAvailable,
        isMarkAsPaidAvailable,
        restrictPayAfterRefundForPreviousDay
      );
      if(isDiableAuthorize && this.sliderInputs.actions?.filter((x) => x.id == "authorize").length > 0)
        this.sliderInputs.actions.find((x) => x.id === "authorize").isDisable = true;
    }
    if (teeRowData.playerDetail && teeRowData.playerDetail.length > 0){
      if(teeRowData.playerDetail.filter(x=>x.isRetailItemsAvailable || x.isRentalItemsAvailable).length>0)
        {
          this.sliderInputs.actions = this.sliderInputs.actions.filter(x=>x.id !== TeeTimeActions.addRetailItem);
      }
      else{
          this.sliderInputs.actions = this.sliderInputs.actions.filter(x=>x.id !== TeeTimeActions.editRetailItem);
      }
    }
    //CheckIn
    if (
      this.utils.ValidateDatesAreEqual(
        this._Utilities.getDate(sessionStorage.getItem("propertyDate")),
        this._Utilities.getDate(teeRowData.time)
      ) &&
      teeTimeSettings &&
      teeTimeSettings?.requireCheckInCheckOut &&
      teeRowData.playerDetail.length > 0 &&
      !this.getUseRetailInterface()
    ) {
      var checkInOutModel = new TeeTimeCheckInOutModel();
      var lenPlayer = teeRowData.playerDetail.length;
      let noShowPlayerCount = 0;
      teeRowData.playerDetail.forEach((x) => {
        if (x.playerStatus & PlayerPaymentstatus.noShow) {
          noShowPlayerCount++;
        }
        if ((x.playerStatus & PlayerPaymentstatus.CheckIn) != 0) {
          if (!checkInOutModel.isCheckIn) {
            checkInOutModel.isCheckIn = true;
          }
          checkInOutModel.lenCheckIn++;
        }
        if ((x.playerStatus & PlayerPaymentstatus.CheckOut) != 0) {
          if (!checkInOutModel.isCheckOut) checkInOutModel.isCheckOut = true;
          checkInOutModel.lenCheckOut++;
        }
      });
      if (
        scheduledStatus == ScheduleStatus.booked &&
        blockedPlayers.length > 0 &&
        checkInOutModel.lenCheckIn < lenPlayer &&
        checkInOutModel.lenCheckIn +
          checkInOutModel.lenCheckOut +
          noShowPlayerCount <
          lenPlayer
      ) {
        this.addAction(
          "Checkin_Checkout",
          "checkInCheckOut",
          this.captions.checkInCheckOutActionTitle
        );
        this.addCheckInCheckOutAction(
          "secondary",
          this.captions.checkInActionTitle,
          false,
          TeeTimeActions.checkIn,
          "checkInSection"
        );
      } else
        this.addCheckInCheckOutAction(
          "secondary",
          this.captions.checkInActionTitle,
          true,
          TeeTimeActions.checkIn,
          "checkInSection"
        );
      if (checkInOutModel.isCheckIn == true) {
        this.addAction(
          "Undo-Check-In",
          "undoCheckIn",
          this.captions.undoCheckInActionTitle
        );
        if (checkInOutModel.lenCheckOut < lenPlayer) {
          this.addCheckInCheckOutAction(
            "secondary",
            this.captions.checkOutActionTitle,
            false,
            TeeTimeActions.checkOut,
            "checkOutSection"
          );
        } else {
          this.addCheckInCheckOutAction(
            "secondary",
            this.captions.checkOutActionTitle,
            true,
            TeeTimeActions.checkOut,
            "checkOutSection"
          );
        }
      } else
        this.addCheckInCheckOutAction(
          "secondary",
          this.captions.checkOutActionTitle,
          true,
          TeeTimeActions.checkOut,
          "checkOutSection"
        );
      if (checkInOutModel.isCheckOut == true) {
        this.addAction(
          "Undo-Check-Out",
          "undoCheckOut",
          this.captions.undoCheckOutActionTitle
        );
      }
    }
    if (this._propertyInformation.UseRetailInterface) {
      this.sliderInputs.actions = this.sliderInputs.actions.filter(
        (x) => x.id != "markAsPaid"
      );
    }
    if (
      this.sliderInputs.players &&
      this.sliderInputs.players.length < 4 &&
      this.sliderInputs.actions
    ) {
      this.addAction(
        "Block",
        "blockTeeTime",
        this.captions.block,
        isHistoricalData
      );
    }
    if (
      noShow &&
      this.sliderInputs.players.filter((x) => x.isBlocked) &&
      this.sliderInputs.players.filter((x) => x.isBlocked).length > 0
    ) {
      this.addAction(
        "clear-players",
        "clearPlayers",
        this.captions.clearPlayers
      );
    }
    if (
      (this.sliderInputs?.scheduleStatus != ScheduleStatus.frostDelay &&
        this.sliderInputs?.scheduleStatus == ScheduleStatus.booked) ||
      ScheduleStatus.open ||
      PlayerPaymentstatus.paid ||
      PlayerPaymentstatus.unPaid
    ) {
      this.addAction(
        "Viewed-By-Starter-Border",
        "ViewedByStarter",
        this.captions.viewedByStarter,
        this.sliderInputs.viewedByStarter
      );
    }
    this.sliderInputs.actions?.push(
      {
        icon: "link",
        id: "multipack",
        label: this.captions.multipack,
        isDisable: isHistoricalData || isLinkMultipack,
      },
      {
        icon: "link",
        id: "unlinkmultipack",
        label: this.captions.unlinkmultipack,
        isDisable: isHistoricalData || isUnLinkMultipack,
      }
    );
    if (this.removeNoShowAction(teeRowData) && !(this.sliderInputs?.scheduleStatus == ScheduleStatus.open) && !teeRowData.playerDetail.every((c) => c.playerStatus == PlayerPaymentstatus.noShow || c.playerStatus & (PlayerPaymentstatus.CheckIn | PlayerPaymentstatus.CheckOut)))
    {
      let isDisable = await this._teeTimeCancellationNoShowFeeUtilities.IsNoShowCancellationPolicyConfigured() == true 
      ? false 
      : teeRowData.playerDetail.every((p) => (p.playerStatus & PlayerPaymentstatus.paid) != 0);
      this.addAction("no-show","noShow",this.captions.noShowIconDescription,isDisable);
    }
      
    if (
      teeRowData.playerDetail.some(
        (c) => c.playerStatus == PlayerPaymentstatus.noShow
      ) &&
      this.removeNoShowAction(teeRowData)
    )
      this.addAction(
        "unmark_no_show",
        "unMarkNoShow",
        this.captions.UnMarkNoShowDescription
      );
    return this.sliderInputs;
  }
  public addAction(
    icon: string,
    id: string,
    label: string,
    isDisable: boolean = false
  ) {
    this.sliderInputs.actions?.push({
      icon: icon,
      id: id,
      label: label,
      isDisable: isDisable,
    });
  }
  public addCheckInCheckOutAction(
    type: string,
    lable: string,
    isToDisableboolean = false,
    action: TeeTimeActions,
    actionClass: string
  ) {
    this.sliderInputs.checkInCheckOutActions?.push({
      type: type,
      label: lable,
      disabledproperty: isToDisableboolean,
      teeTimeAction: action,
      class: actionClass,
    });
  }
  public removeNoShowAction(scheduledDateTime: TeeSheetSkeletonData): boolean {
    const propertyDateTime = new Date(
        this._localization.LocalizeDateTimeFormatSecondsDDMMMYYYYheader(
          this._propertyInformation.CurrentDate,
          true
        )
      );
      const systemDateTime = this._localization.getCurrentDate();
      const schDateTime = this._localization.getDate(scheduledDateTime.time);
      const systemDateOnly = this._utilities.resetTime(_.clone(systemDateTime));
      const schDateOnly = this._utilities.resetTime(_.clone(schDateTime));
      let teeConfig = JSON.parse(sessionStorage.getItem('TEETIMESETTING'));
      if (teeConfig?.allowNoShowPriortoPlay) {
        return (
          systemDateOnly.getDate() === schDateOnly.getDate() 
        );     
      } else {
        return (
          systemDateOnly.getTime() === schDateOnly.getTime() &&
          systemDateOnly.getDate() === schDateOnly.getDate() &&
          this._localization.getTime(propertyDateTime, 24) >
            this._localization.getTime(schDateTime, 24)
        );    
      }     
  }

  public isHistoricalRecord(teeRowData: TeeSheetSkeletonData): boolean {
    var propertyCurrentDateTime = this._utilities.GetDateWithoutTime(
        this._propertyInformation.CurrentDate
      ),
      teeRowDataDateTime = this._utilities.GetDateWithoutTime(
        this._localization.getDate(teeRowData.time)
      );

    return propertyCurrentDateTime.getTime() <= teeRowDataDateTime.getTime()
      ? false
      : true;
  }
  public isFutureRecord(teeRowData: TeeSheetSkeletonData): boolean {
    var propertyCurrentDateTime = this._utilities.GetDateWithoutTime(
        this._propertyInformation.CurrentDate
      ),
      teeRowDataDateTime = this._utilities.GetDateWithoutTime(
        this._localization.getDate(teeRowData.time)
      );

    return propertyCurrentDateTime.getTime() < teeRowDataDateTime.getTime()
      ? true
      : false;
  }

  sliderButtonTextGenerator(teeRowData, teeTimeDetail: boolean) {
    if (
      teeRowData.unmodifiedSlotData.status.scheduleStatus ===
      ScheduleStatus.hold
    ) {
      return this.captions.releaseHold;
    }
    if (teeTimeDetail) {
      return this.captions.teeTimeDetail;
    }
    return teeRowData.playerDetail.length > 0
      ? this.captions.editTeeTime
      : this.captions.bookTeeTimeSlider;
  }

  private assignStatus(teeRowData: TeeSheetSkeletonData): Status | any {
    const data = teeRowData.unmodifiedSlotData as TeeSheetGridContent; // throws error kindly revisit the declaration  and return type
    return data
      ? this.MapScheduleStatus(teeRowData)
      : {
          // throws error kindly revisit the declaration and return type
          name: ScheduleStatus.booked,
          icon: "",
          colorCode: "",
          scheduleStatus: ScheduleStatus.open,
        };
  }

  private MapScheduleStatus(data: TeeSheetSkeletonData): Status {
    data.status.name = this.getEnumKeyByEnumValue(
      ScheduleStatus,
      data.status.scheduleStatus
    );
    let scheduleStatus: Status = cloneDeep(data.status);
    if (
      data &&
      data.playerDetail &&
      data.playerDetail.length > 0 &&
      data.playerDetail.every(
        (p) => (p.playerStatus & PlayerPaymentstatus.paid) != 0
      )
    ) {
      scheduleStatus = {
        name: this.captions.ScheduleStatus.Paid,
        icon: "",
        colorCode:
          data.allocation.colorCode != ""
            ? data.allocation.colorCode
            : "#ffffff",
        scheduleStatus: ScheduleStatus.booked,
      };
    } else {
      scheduleStatus.colorCode =
        data.allocation.colorCode != "" ? data.allocation.colorCode : "#ffffff";
    }
    const actionScheduleStatus =
      data.status.scheduleStatus == ScheduleStatus.open ||
      data.status.scheduleStatus == ScheduleStatus.booked;
    if (!actionScheduleStatus) {
      scheduleStatus.colorCode =
        data.status.colorCode != "" ? data.status.colorCode : "#ffffff";
    }
    return scheduleStatus;
  }

  private getEnumKeyByEnumValue(myEnum, enumValue) {
    const key = Object.keys(myEnum).find((x) => myEnum[x] === enumValue);
    return this._localization.captions.teetime[key];
  }

  IsSlotPresentInCart(event) {
    let presentInCart = false;
    let itineraryList = this._cartUtils.getCartItineraryList();
    if (itineraryList && itineraryList.length > 0) {
      itineraryList?.flatMap((itinerary) => {
        itinerary.cartDetails?.forEach((cartDetail) => {
          if (cartDetail.cartPayload.productId == Product.GOLF) {
            let pL = JSON.parse(cartDetail.cartPayload?.bookAPI?.payload);
            if (event && event[1]) {
              if (
                event[1].course.id == pL.courseId &&
                event[1].time == pL.scheduleDateTime &&
                event[1].originalHoleNumber == pL.originalHoleNumber
              ) {
                const warnMessage = this._localization.replacePlaceholders(
                  this._localization.captions.teetime.msg_slotPresentInCart,
                  ["name"],
                  [itinerary.confirmationId]
                );
                this._Utilities.showAlert(warnMessage, AlertType.Info);
                presentInCart = true;
                return;
              }
            }
          }
        });
      });
    }
    return presentInCart;
  }

  async bookTeeSheet(
    event,
    teeSheetCallBack,
    scheduledTeeTimePlayer?: ScheduledTeeTimeTimePlayer,
    overridePlayerTypeDaysoutUserAccess?: any,
    isEditFromGrid?: boolean,
    playerId?: number,
    fromTeeTime?: boolean,
    isEditFromSearchScreen?: boolean,
    isFromTeeSheet:boolean=false,
    teeTimeSlotDetails?:TeeTimeSlotDetail[]
  ): Promise<boolean | object>{
    let isBulkEdit = false;
    let bulkEditUserAccess;
    let normalBookUserAccess;
    let tabAccessOnEdit: UserAccessModel.BreakPointResult[];
    if (event && event[0] === this.captions.releaseHold) {
      let validateBreakPointAccess =
        await this._teeTimesActionBusiness.validateBreakPointAccess(
          UserAccessBreakPoints.RELEASEHOLD
        );
      if (validateBreakPointAccess && validateBreakPointAccess.isAllow) {
        if (
          !(await this.userRoleHasPermissionForAllocation(event[1].allocation))
        ) {
          this._utilities.showAllocationCodePermissionDeniedPopup(
            event[1].allocation.name,
            this._localization.captions.common.ReleaseHoldNotAllowed
          );
          return false;
        }
        this._teeTimesActionBusiness.releaseTeeTime(
          event[1].course.id,
          event[1].time,
          event[1].originalHoleNumber,
          teeSheetCallBack
        );
        return;
      } else {
        return false;
      }
    }

    if (
      !this._teeTimesActionBusiness.validateUserDaysOut(
        event[1].unmodifiedSlotData.time
      )
    ) {
      this._utilities.showError(this._localization.getError(30414));
      return;
    }
    tabAccessOnEdit =
      event[0] === this.captions.editTeeTime
        ? await this.validateAllBreakPointTabOnEdit()
        : [];
    normalBookUserAccess =
      await this._teeTimesActionBusiness.validateBreakPointAccess(
        UserAccessBreakPoints.BOOKTEETIME,
        false
      );
    bulkEditUserAccess =
      await this._teeTimesActionBusiness.validateBreakPointAccess(
        UserAccessBreakPoints.BULKTEETIMESEDIT,
        false
      );
    const blockplayers = event[1].playerDetail.filter((x) => !x.isBlocked);
    const blockplayersCount =
      event[1]?.playerDetail && event[1]?.playerDetail?.length > 0
        ? event[1].playerDetail.filter((x) => x?.isBlocked)?.length
        : 0;
    const isBulkBooking =
      event[1].playerDetail &&
      event[1].playerDetail.length > 0 &&
      blockplayers &&
      blockplayers.length > 0
        ? blockplayers
            .map((y) => y.teeTimeFormat)
            .every((a) => a == TeeTimeFormat.BulkTee)
        : false;
    const isNormalBooking =
      event[1].playerDetail &&
      event[1].playerDetail.length > 0 &&
      blockplayers &&
      blockplayers.length > 0
        ? blockplayers
            .map((x) => x.teeTimeFormat)
            .every((x) => x !== TeeTimeFormat.BulkTee)
        : false;
    event[1].isBulkBooking = isBulkBooking;

    if (this._cartUtils.isEmbed()) {
      if (this.IsSlotPresentInCart(event))
        return;
    }
    
    if (
      event &&
      (event[0] === this.captions.bookTeeTimeSlider ||
        event[0] === this.captions.editTeeTime) &&
      (isBulkBooking || isNormalBooking || event[1].playerDetail.length == 0)
    ) {
      const validateBreakPointAccess = isBulkBooking
        ? bulkEditUserAccess
        : normalBookUserAccess;
      if (
        validateBreakPointAccess &&
        !validateBreakPointAccess.isAllow &&
        !validateBreakPointAccess.isViewOnly
      ) {
        if (
          tabAccessOnEdit &&
          tabAccessOnEdit.filter((a) => a.isAllow || a.isViewOnly).length == 0
        ) {
          isBulkBooking
            ? await this._teeTimesActionBusiness.ShowBreakpointError(
                UserAccessBreakPoints.BULKTEETIMESEDIT,
                bulkEditUserAccess
              )
            : await this._teeTimesActionBusiness.ShowBreakpointError(
                UserAccessBreakPoints.BOOKTEETIME,
                normalBookUserAccess
              );
          return false;
        }
      }
      if (
        isBulkBooking &&
        !(
          this._Utilities.getDate(event[1].time) <
          this._Utilities.getDate(sessionStorage.getItem("propertyDate"))
        )
      ) {
        var res = this._Utilities.showAlert(
          this._localization.getError(20517),
          AlertType.Warning,
          ButtonType.YesNo,
          (res) => {
            if (res === AlertAction.YES) {
              isBulkEdit = true;
            }
          }
        );
        await res.afterClosed().toPromise();
      }
    } else if (
      event &&
      (event[0] === this.captions.bookTeeTimeSlider ||
        event[0] === this.captions.editTeeTime) &&
      !isBulkBooking &&
      !isNormalBooking
    ) {
      if (
        blockplayersCount === this._teeTimeService.totalPlayerCount &&
        normalBookUserAccess &&
        !normalBookUserAccess.isAllow &&
        !normalBookUserAccess.isViewOnly
      ) {
        let bpMessage =
          this._localization.captions.breakpoint[
            UserAccessBreakPoints.BOOKTEETIME
          ];
        this.showBreakPointPopup(bpMessage);
        return false;
      } else if (
        bulkEditUserAccess &&
        normalBookUserAccess &&
        !bulkEditUserAccess.isAllow &&
        !bulkEditUserAccess.isViewOnly &&
        !normalBookUserAccess.isAllow &&
        !normalBookUserAccess.isViewOnly &&
        tabAccessOnEdit &&
        tabAccessOnEdit.filter((a) => a.isAllow || a.isViewOnly).length == 0
      ) {
        let bpBulkMessage =
          this._localization.captions.breakpoint[
            UserAccessBreakPoints.BULKTEETIMESEDIT
          ];
        this.showBreakPointPopup(bpBulkMessage);
        return false;
      }
    }

    let teeSheetGridContent: TeeSheetGridContent =
      this.GetDataFromSlideInput(event);
    let teeTimeInfo = this.buildTeeTimeModelFromSlide(teeSheetGridContent);

    // Validate Allocation block Role Access
    let allocationCodePermissions: allocationBlockPlayerTypePermission[];
    if (this._utilities.IsAllocationCodePermissionEnabled() && event) {
      allocationCodePermissions =
        await this._allocationCodeManagementService.getAllocationBlockPermissionByRole();
      if (event[0] === this.captions.bookTeeTimeSlider) {
        // Book Teetime
       
        let defaultAllocationCodePermissions = [];
        if (
          allocationCodePermissions &&
          allocationCodePermissions.length > 0 &&
          event[1].allocation &&
          event[1].allocation.id
        ) {
          defaultAllocationCodePermissions = allocationCodePermissions.filter(
            (r) => r.allocationBlockId == event[1].allocation.id
          );
        }
        if (
          !defaultAllocationCodePermissions ||
          (defaultAllocationCodePermissions.length == 0 &&
            event[1].allocation &&
            event[1].allocation.id)
        ) {
          // Show error
          this._utilities.showAllocationCodePermissionDeniedPopup(
            event[1].allocation.name
          );
          return;
        }
      }
    }
    return await this._teeTimesActionBusiness
      .tempHoldTeeTime(teeTimeInfo, TempHoldStatus.hold)
      .then((isSuccess) => {
        if (isSuccess) {
          var teeTimeComponentData = this.OpenTeeTimeComponent(
            event,
            teeSheetCallBack,
            isBulkEdit,
            scheduledTeeTimePlayer,
            bulkEditUserAccess,
            normalBookUserAccess,
            event[1].allocation,
            allocationCodePermissions,
            overridePlayerTypeDaysoutUserAccess,
            tabAccessOnEdit,
            isEditFromGrid,
            playerId,
            isEditFromSearchScreen,
            isFromTeeSheet,
            fromTeeTime,
            teeTimeSlotDetails
          );
          if(fromTeeTime)
          {
            return teeTimeComponentData;
          }
        } else {
          teeSheetCallBack(true);
        }
      });
  }

  private async validateAllBreakPointTabOnEdit() {
    let breakPointNumbers: number[] = [];
    for (const [propertyKey, propertyValue] of Object.entries(TeeTimeTabs)) {
      if (!Number.isNaN(Number(propertyKey))) {
        continue;
      }
      switch (propertyValue) {
        case TeeTimeTabs.playerInformation:
          break;
        case TeeTimeTabs.generalInformation:
          breakPointNumbers.push(UserAccessBreakPoints.EDITGENERALINFO);
          break;
        case TeeTimeTabs.feeInformation:
          break;
        case TeeTimeTabs.otherInformation:
          break;
        case TeeTimeTabs.packageEntitlement:
          break;
      }
    }
    const isValidAccess =
      breakPointNumbers.length > 0
        ? await this._teeTimesActionBusiness.validateBreakPointAccesses(
            breakPointNumbers
          )
        : null;
    return isValidAccess;
  }
  private OpenTeeTimeComponent(
    event: [string, TeeSheetSkeletonData],
    teeSheetCallBack,
    isBulkEdit,
    scheduledTeeTimePlayer?,
    bulkEditUserAccess?,
    normalBookUserAccess?,
    allocation?,
    allocationcodeRolePermission?,
    overridePlayerTypeDaysoutUserAccess?,
    tabAccessOnEdit?,
    isEditFromGrid?,
    playerId?,
    isEditFromSearchScreen?: boolean,
    isFromTeeSheet?:boolean,
    fromTeeTime?:boolean,
    teeTimeSlotDetails?
  ) {
    this.componentDetails = {
      componentName: TeeTimeComponent,
      popUpDetails: {
        isStepper: false,
        bindData: event,
        eventName: "notifyParent",
      },
    };
    var teeTimeComponentData = {
      title: `${event[0]} - ${event[1].course.course}`,
        update: "SAVE",
        cancel: "CANCEL",
        componentDetails: this.componentDetails,
        isBulkEdit: isBulkEdit,
        scheduledTeeTimePlayer: scheduledTeeTimePlayer,
        bulkEditUserAccess: bulkEditUserAccess,
        normalBookUserAccess: normalBookUserAccess,
        allocation: allocation,
        allocationcodeRolePermission: allocationcodeRolePermission,
        overridePlayerTypeDaysoutUserAccess: overridePlayerTypeDaysoutUserAccess,
        tabAccessOnEdit: tabAccessOnEdit,
        isEdit: event[0] === this.captions.editTeeTime,
        playerId,
        isEditFromGrid: isEditFromGrid,
        isFromTeeSheet:isFromTeeSheet,
        teeTimeSlotDetails:teeTimeSlotDetails,
        teeSheetCallBack: teeSheetCallBack
    }
    console.log(teeTimeComponentData);
    if(fromTeeTime)
      {
        return teeTimeComponentData;
      }
    const dialogRef = this.dialog.open(TeeTimeComponent, {
      width: "95%",
      height: "90%",
      maxWidth: "95vw",
      disableClose: true,
      data: teeTimeComponentData
    });

    this.bookingPopupSubscription = dialogRef
      .afterClosed()
      .subscribe((result) => {
        if (result?.from === "save") {
          if(teeSheetCallBack != null)
          teeSheetCallBack(true, true, result.showWaitlistPopup);
        } else if (result?.from === "bulk") {
          this._Router.navigate(["tee-time/teesheet/bulkTeeTime"], {
            state: result,
          });
        }
      });
  }

  private buildTeeTimeModelFromSlide(arg: TeeSheetGridContent): TeeTimeModel {
    return {
      id: 0,
      courseId: arg.course.id, // To do
      scheduleDateTime: this._localization.getDate(arg.time).toString(),
      holeNumber: arg.hole,
      scheduleStatus: 0,
      playerId: 0,
      tournamentId: 0,
      comment: "",
      scheduledTeeTimePlayers: [],
      originalHoleNumber: arg.originalHoleNumber,
      playerIds: arg.playerDetail.map(c=>c.playerId)

    };
  }

  private GetDataFromSlideInput(popupData): TeeSheetGridContent {
    let obj: TeeSheetGridContent;
    if (popupData && popupData.length > 1) {
      obj = popupData[1].unmodifiedSlotData;
      obj.teeTimeId = popupData[1].scheduledTeeTimeId;
      obj.isBulkBooking = popupData[1].playerDetail
        ? popupData[1].playerDetail.filter(
            (x) => !x.isBlocked && x.teeTimeFormat == TeeTimeFormat.BulkTee
          ).length > 0
        : false;
    }
    return obj;
  }

  async IsOriginalPaymentReturnOnly() {
    const configValue: SystemConfiguration =
      await this._propertySettingDataService.GetReturnOriginalPaymentConfigurationSetting();
    if (configValue && configValue.value === "true") {
      return configValue.value === "true";
    }
    return false;
  }

  getPlayers(players: TeeSheetPlayerDetails[]) {
    if (!players) return []; // need to modify
    const playersList = [];
    players.forEach((element) => {
      playersList.push({
        name: !element.isBlocked
          ? this._utilities.formatGuestName(element.firstName, element.lastName)
          : this.captions.lblBlocked,
        imageReferenceId: !element.isBlocked ? element.imageReferenceId : "",
        isBlocked: element.isBlocked,
      });
    });
    return playersList;
  }

  getActionItems(
    status: ScheduleStatus,
    isHistoricalData,
    isRefundRequired: boolean,
    isShopRequired: boolean,
    isRefunded: boolean,
    isNoShow: boolean,
    isPaidNoShow: boolean,
    isRefundedPlayersAvailable: boolean,
    isMarkAsPaidAvailable: boolean,
    restrictPayAfterRefundForPreviousDay: boolean
  ) {
    if (isNoShow) {
      return [
        {
          icon: "rate",
          label: this.captions.rate,
          id: "rate",
        },
        {
          icon: "copy",
          id: "copy",
          label: this.captions.copy,
          isDisable: isHistoricalData || isRefunded,
        },
        {
          icon: "move",
          id: "copyToMany",
          label: this.captions.copytoMany,
          isDisable: isHistoricalData || isRefunded,
        },
        {
          icon: "move",
          id: "move",
          label: this.captions.move,
          isDisable: isHistoricalData || isRefunded,
        },

        {
          icon: "print",
          label: this.captions.print,
          id: "print",
          isDisable: !isPaidNoShow,
        },
        {
          icon: "sendmail1",
          label: this.captions.notifyTitle,
          id: "notify",
          isDisable: false, //need to be set with player count
        },
      ];
    } else {
      switch (status) {
        case ScheduleStatus.open:
          return [
            {
              icon: "hold",
              label: this.captions.holdTeeTime,
              id: "holdTeeTime",
              isDisable: isHistoricalData,
            },
            {
              icon: "sqeeze",
              label: this.captions.sqeeze,
              id: "squeeze",
              isDisable: isHistoricalData,
            },
            {
              icon: "frost-delay",
              label: this.captions.frostDelay,
              id: "frostDelay",
              isDisable: isHistoricalData,
            },
            {
              icon: "rate",
              label: this.captions.rate,
              id: "rate",
            },
            {
              icon: "clear-players",
              id: "clearPlayers",
              label: this.captions.clearPlayers,
              isDisable: true,
            },
            {
              icon: "delete",
              id: "deleteTeeTimeSlot",
              label: this.captions.deleteTeeTimeSlot,
              isDisable: isHistoricalData,
            },
            {
              icon: "Audit-Trail",
              id: "auditTrail",
              label: this.captions.auditTrail,
              isDisable: false,
            },
          ];
        case ScheduleStatus.booked:
          return [
            {
              icon: "move",
              id: "Redeem",
              label: this.captions.ZeroMemberCharge,
              isDisable: false,
            },
            {
              icon: "gratuity",
              label: this.captions.Pay,
              id: "payment",
              isDisable:
                isShopRequired ||
                (isHistoricalData && !isRefundedPlayersAvailable)
                || (isHistoricalData && isRefundedPlayersAvailable && restrictPayAfterRefundForPreviousDay),
            },
            {
              icon: "MarkasPaid",
              label: this.captions.markAsPaid,
              id: "markAsPaid",
              isDisable: isMarkAsPaidAvailable,
            },
            {
              icon: "cancel",
              label: this.captions.cancelPlayer,
              id: "cancelPlayers",
              isDisable: isHistoricalData,
            },
            {
              icon: 'clear-players',
              id: 'clearPlayers',
              label: this.captions.clearPlayers,
              isDisable: isHistoricalData
            },
            {
              icon: 'rate',
              label: this.captions.rate,
              id: "rate",
            },
            {
              icon: "copy",
              id: "copy",
              label: this.captions.copy,
              isDisable: isHistoricalData,
            },
            {
              icon: "move",
              id: "copyToMany",
              label: this.captions.copytoMany,
              isDisable: isHistoricalData,
            },
            {
              icon: "move",
              id: "move",
              label: this.captions.move,
              isDisable: isHistoricalData,
            },
            {
              icon: 'sqeeze',
              label: this.captions.sqeeze,
              id: "squeeze",
              isDisable: isHistoricalData || isRefunded,
            },
            {
              icon: "frost-delay",
              label: this.captions.frostDelay,
              id: "frostDelay",
              isDisable: isHistoricalData || isRefunded,
            },
            {
              icon: "paid1",
              label: this.captions.refunds,
              id: "refund",
              isDisable: !isRefundRequired,
            },
            {
              icon: "print",
              label: this.captions.print,
              id: "print",
              isDisable: false,
            },
            {
              icon: "sendmail1",
              label: this.captions.notifyTitle,
              id: "notify",
              isDisable: false, //need to be set with player count
            },
            {
              icon: "delete",
              id: "deleteTeeTimeSlot",
              label: this.captions.deleteTeeTimeSlot,
              isDisable: true,
            },
            {
              icon: "View-E-Form",
              id: "viewEForm",
              label: this.captions.viewEForm,
              isDisable:
                sessionStorage.getItem("IsEformsEnabled") === "true"
                  ? false
                  : true,
            },
            {
              icon: "Audit-Trail",
              id: "auditTrail",
              label: this.captions.auditTrail,
              isDisable: false,
            },
            {
              icon: "Authorize",
              id: "authorize",
              label: this.captions.Authorize,
              isDisable: false,
            },
            {
              icon: "add_retail_item_24px",
              id: "addRetailItem",
              label: this._retailFeatureFlag.IsRentalEnabled ? this.captions.addRetailOrRentals : this.captions.addRetail,
              isDisable:
              isShopRequired ||
              (isHistoricalData && !isRefundedPlayersAvailable)
              || (isHistoricalData && isRefundedPlayersAvailable && restrictPayAfterRefundForPreviousDay),
            },
            {
              icon: "add_retail_item_24px",
              id: "editRetailItem",
              label: this._retailFeatureFlag.IsRentalEnabled ? this.captions.editRetailOrRentals : this.captions.editRetail,
              isDisable:
                isShopRequired ||
                (isHistoricalData && !isRefundedPlayersAvailable)
                || (isHistoricalData && isRefundedPlayersAvailable && restrictPayAfterRefundForPreviousDay),
            }
          ];
        case ScheduleStatus.tempHold:
          return [
            {
              icon: "delete",
              id: "deleteTeeTimeSlot",
              label: this.captions.deleteTeeTimeSlot,
              isDisable: true,
            },
          ];
      }
    }
  }

  public async checkDepositRedirection(
    teeTimeAction,
    dateTime,
    courseId,
    arg,
    isFromEditPay: boolean = false
  ) {
    if (teeTimeAction === TeeTimeActions.payment) {
      var teeTimeSetting = JSON.parse(sessionStorage.getItem("TEETIMESETTING"));
      if (
        !this._Utilities.IsPropertyDateGreaterThenDate(new Date(dateTime)) &&
        teeTimeSetting &&
        teeTimeSetting != null &&
        teeTimeSetting.requireCheckInCheckOut
      ) {
        if (
          !(await this._retailBusinessService.onClickValidateSelectedOutlet(
            courseId,
            undefined,
            isFromEditPay
          ))
        ) {
          this.onNavigateShop({
            actionType: TeeTimeActions.payment,
            arg: arg,
            isDeposit: true,
          });
          return true;
        } else {
          this.addDeposit(arg);
          return true;
        }
      }
    }
    return false;
  }
  private async ShowAlertForMixedPlayers(isCancel: boolean): Promise<boolean> {
    let bResult: boolean = false;
    this.retainWithNoActionClearCancel = false;
    var response = this._utilities.showAlert(
      this.captions.mixedCancelClearPopup,
      AlertType.Info,
      ButtonType.YesNoCancel,
      (res) => {
        if (res === AlertAction.YES) {
          bResult = true;
        } else if(res === AlertAction.NO) {
          bResult = false;
        } else {
          this.retainWithNoActionClearCancel = true;
        }
      }
    );
    await response.afterClosed().toPromise();
    return bResult;
  }
  HasRefundedPlayersWithMultipleTicket(actionArg): boolean {
    let hasMultipleTickets: boolean = false;
    if (actionArg.playerDetail) {
      let ticketNumbers = actionArg.playerDetail
        .filter((x) => (x.playerStatus & PlayerPaymentstatus.paid) != 0)
        ?.map((x) => x.ticketNumber)
        .distinct();
      hasMultipleTickets =
        ticketNumbers && ticketNumbers.length > 1 ? true : false;
    }
    return hasMultipleTickets;
  }

  public async teeActionClick(
    event: TeeActionsClick,
    teeSheetCallBack,
    extraParams?,
    eventNotifydata?
  ): Promise<boolean> {
    var teeTimeSetting = JSON.parse(sessionStorage.getItem("TEETIMESETTING"));
    const isBulkBooking = this.isBulkBooking(event[1]);
    const isBulkBookingWithBlockPlayers = this.isBulkBookingWithBlockPlayers(
      event[1]
    );
    const isNormalBooking = this.isNormalBooking(event[1]);
    const isAllblockPlayers = this.isAllblockPlayers(event[1]);
    let validateBreakPointAccess: any = null,
      breakPointNumber = 0,
      isBulk: boolean = true;
    let normalPlayersBreakpointNumber = 0,
      bulkPlayersBreakPointNumber = 0;
    let bulkPlayerUserAccess: any = null,
      normalPlayerUserAccess: any = null;
    switch (event[0]) {
      case TeeTimeActions.holdTeeTime:
        breakPointNumber = UserAccessBreakPoints.HOLDTEETIME;
        break;
      case TeeTimeActions.squeeze:
        breakPointNumber = UserAccessBreakPoints.SQUEEZETEETIME;
        break;
      case TeeTimeActions.rate:
        breakPointNumber = UserAccessBreakPoints.RATETEETIME;
        break;
      case TeeTimeActions.clearPlayers:
        if (
          isAllblockPlayers ||
          isNormalBooking ||
          isBulkBookingWithBlockPlayers
        ) {
          breakPointNumber = isBulkBookingWithBlockPlayers
            ? UserAccessBreakPoints.BULKCLEAR
            : UserAccessBreakPoints.ClearPlayers;
          isBulk = isBulkBooking;
        } else {
          isBulk = await this.ShowAlertForMixedPlayers(false);
          breakPointNumber = isBulk ? UserAccessBreakPoints.BULKCLEAR : 0;
          normalPlayersBreakpointNumber = UserAccessBreakPoints.ClearPlayers;
          bulkPlayersBreakPointNumber = UserAccessBreakPoints.BULKCLEAR;
        }
        break;
      case TeeTimeActions.copy:
        let normalBookingCheckerCopy = isNormalBooking
          ? UserAccessBreakPoints.Copy
          : 0;
        breakPointNumber = isBulkBooking
          ? UserAccessBreakPoints.BULKCOPY
          : normalBookingCheckerCopy;
        if (breakPointNumber == 0) {
          normalPlayersBreakpointNumber = UserAccessBreakPoints.Copy;
          bulkPlayersBreakPointNumber = UserAccessBreakPoints.BULKCOPY;
        }
        break;
      case TeeTimeActions.copyToMany:
        let normalBookingCheckerCopytoMany = isNormalBooking
          ? UserAccessBreakPoints.CopytoMany
          : 0;
        breakPointNumber = isBulkBooking
          ? UserAccessBreakPoints.BULKCOPY
          : normalBookingCheckerCopytoMany;
        if (breakPointNumber == 0) {
          normalPlayersBreakpointNumber = UserAccessBreakPoints.CopytoMany;
          bulkPlayersBreakPointNumber = UserAccessBreakPoints.BULKCOPY;
        }
        break;
      case TeeTimeActions.move:
        let normalBookingCheckerMove = isNormalBooking
          ? UserAccessBreakPoints.Move
          : 0;
        breakPointNumber = isBulkBooking
          ? UserAccessBreakPoints.BULKMOVE
          : normalBookingCheckerMove;
        if (breakPointNumber == 0) {
          normalPlayersBreakpointNumber = UserAccessBreakPoints.Move;
          bulkPlayersBreakPointNumber = UserAccessBreakPoints.BULKMOVE;
        }
        break;
      case TeeTimeActions.frostDelay:
        breakPointNumber = UserAccessBreakPoints.FROSTDELAY;
        break;
      case TeeTimeActions.cancelPlayers:
        if (!isBulkBooking && !isNormalBooking) {
          isBulk = await this.ShowAlertForMixedPlayers(true);
          breakPointNumber = isBulk ? UserAccessBreakPoints.BULKCANCEL : 0;
          normalPlayersBreakpointNumber = UserAccessBreakPoints.CANCELPLAYERS;
          bulkPlayersBreakPointNumber = UserAccessBreakPoints.BULKCANCEL;
        } else {
          breakPointNumber = isBulkBooking
            ? UserAccessBreakPoints.BULKCANCEL
            : UserAccessBreakPoints.CANCELPLAYERS;
          isBulk = isBulkBooking;
        }
        break;
      case TeeTimeActions.print:
        breakPointNumber = UserAccessBreakPoints.PRINT;
        break;
      case TeeTimeActions.refund:
        breakPointNumber = UserAccessBreakPoints.TEETIMEREFUND;
        break;
      case TeeTimeActions.payment:
        breakPointNumber = UserAccessBreakPoints.SHOP;
        break;
      case TeeTimeActions.reinstate:
        breakPointNumber = isBulkBooking
          ? UserAccessBreakPoints.BULKCANCEL
          : UserAccessBreakPoints.CANCELPLAYERS;
        break;
      case TeeTimeActions.noShow:
        breakPointNumber = UserAccessBreakPoints.NOSHOW;
        break;
      case TeeTimeActions.notify:
        break;
      case TeeTimeActions.blockTeeTime:
        breakPointNumber = UserAccessBreakPoints.ALLOWBLOCKTEETIME;
        break;
      case TeeTimeActions.Redeem:
        breakPointNumber = UserAccessBreakPoints.ZEROMEMBERCHARGE;
        break;
      case TeeTimeActions.PrintItenary:
        breakPointNumber = UserAccessBreakPoints.GUESTITINERARYREPORT;
        break;
      case TeeTimeActions.markAsPaid:
        breakPointNumber = UserAccessBreakPoints.MANUALMARKASPAID;
        break;
      case TeeTimeActions.deleteTeeTimeSlot:
        breakPointNumber = UserAccessBreakPoints.DELETETEETIMESLOT;
        break;
      case TeeTimeActions.linkmultipack:
        breakPointNumber = UserAccessBreakPoints.LINKUNLINKMULTIPACK;
        break;
      case TeeTimeActions.UnlinkMultipack:
        breakPointNumber = UserAccessBreakPoints.LINKUNLINKMULTIPACK;
        break;
      case TeeTimeActions.unMarkNoShow:
        breakPointNumber = UserAccessBreakPoints.UNMARKNOSHOW;
        break;
      case TeeTimeActions.checkIn:
        breakPointNumber = UserAccessBreakPoints.CHECKIN;
        break;
      case TeeTimeActions.checkInCheckOut:
        breakPointNumber = UserAccessBreakPoints.CHECKIN_CHECKOUT;
        break;
      case TeeTimeActions.checkOut:
        breakPointNumber = UserAccessBreakPoints.CHECKOUT;
        break;
      case TeeTimeActions.undoCheckIn:
        breakPointNumber = UserAccessBreakPoints.UNDO_CHECKIN;
        break;
      case TeeTimeActions.undoCheckOut:
        breakPointNumber = UserAccessBreakPoints.UNDO_CHECKOUT;
        break;
      case TeeTimeActions.auditTrail:
        breakPointNumber = UserAccessBreakPoints.AUDIT_TRAIL;
        break;
      case TeeTimeActions.addRetailItem:
      case TeeTimeActions.editRetailItem:
        breakPointNumber = UserAccessBreakPoints.RETAILORRENTAL_ITEM;
        break;
    }
    if (breakPointNumber > 0)
      validateBreakPointAccess = await this._teeTimesActionBusiness.validateBreakPointAccess(breakPointNumber);
    
    else if(event[0] == this.captions.editTeeTime){
      
      this._retailSharedService.isFromTeetimeSearch = true;
      this.utils.ToggleLoader(true);
      const mapper: TeeSheetMapper = new TeeSheetMapper(this._localization);
      let scheduleTeeTime = await this._teeTimeService.GetTeeTimeByScheduleId(event[1]?.scheduledTeeTimeId);
      if(scheduleTeeTime != undefined && scheduleTeeTime.scheduledTeeTimePlayers.length > 0) {
        let uiScheduleObj = await this.UIMapperForAPIScheduleTeetimeToUITeeTimeModel(scheduleTeeTime);
          let userId = this._Utilities.GetPropertyInfo('UserId');
          const userConfiguration =  await this._golfUserConfigDataService.getUserConfig(Number(userId));
          const courseConfiguration = userConfiguration.userCourseConfiguration;
          const courseId = uiScheduleObj.courseId;
          let courseTime = this._localization.getDate(uiScheduleObj.scheduleDateTime);
          let courseIdForScheduleTeetime = Number(uiScheduleObj.courseId);
          const skeletonData = await this._teeSheetSingleCourse.getTeeSheetSkeletonData(courseIdForScheduleTeetime, courseTime);
          const teeTimeData = skeletonData.find(o => o.course.id == uiScheduleObj.courseId && o.time == uiScheduleObj.scheduleDateTime);
          let scheduledTeeTimeId = event[1]?.scheduledTeeTimeId;
          if(scheduledTeeTimeId > 0){
            let teeTimeDetails = await (await this._TeeSheetsDataService.getTeeTimes(courseIdForScheduleTeetime,courseTime)).find(o => o.id == scheduledTeeTimeId);
            let playerDetailsForSlot :TeeSheetPlayerDetails[]  = mapper.mapPlayerDetails(teeTimeDetails.playerDetail,extraParams?.rateTypes,extraParams?.playerTypes,scheduledTeeTimeId); 
            teeTimeData.playerDetail = playerDetailsForSlot;
            teeTimeData.unmodifiedSlotData.playerDetail = playerDetailsForSlot;
            teeTimeData.unmodifiedSlotData.scheduledTeeTimeId = scheduledTeeTimeId;
            teeTimeData.scheduledTeeTimeId = scheduledTeeTimeId;
          }
          
          if(teeTimeData){
            if (courseConfiguration.some(x => x.courseId == courseId)) {
              let inputToBookTeeTime = [this.captions.editTeeTime, teeTimeData];
              this.bookTeeSheet(inputToBookTeeTime,teeSheetCallBack,null,null,false,extraParams?.editedPlayer[0].playerId,false,true);
              return;
            }
            else{
              console.log('The user does not have access to the course');
            }
          }
        }
      }
    else if (
      event[0] == TeeTimeActions.move ||
      event[0] == TeeTimeActions.copy ||
      event[0] == TeeTimeActions.copyToMany ||
      event[0] == TeeTimeActions.clearPlayers ||
      event[0] == TeeTimeActions.cancelPlayers
    ) {
      if(!this.retainWithNoActionClearCancel){
        bulkPlayerUserAccess =
          await this._teeTimesActionBusiness.validateBreakPointAccess(
            bulkPlayersBreakPointNumber,
            false
          );
        normalPlayerUserAccess =
          await this._teeTimesActionBusiness.validateBreakPointAccess(
            normalPlayersBreakpointNumber,
            false
          );

        if (
          bulkPlayerUserAccess &&
          !bulkPlayerUserAccess.isAllow &&
          normalPlayerUserAccess &&
          !normalPlayerUserAccess.isAllow &&
          !isBulkBooking &&
          !isNormalBooking &&
          !isBulk
        ) {
          let bpMessage =
            this._localization.captions.breakpoint[normalPlayersBreakpointNumber];
          this.showBreakPointPopup(bpMessage);
          let bpBulkMessage =
            this._localization.captions.breakpoint[bulkPlayersBreakPointNumber];
          this.showBreakPointPopup(bpBulkMessage);
          return;
        }
      }
    } else if (event[0] == TeeTimeActions.Redeem) {
      normalPlayerUserAccess =
        await this._teeTimesActionBusiness.validateBreakPointAccess(
          normalPlayersBreakpointNumber,
          false
        );
      if (normalPlayerUserAccess && !normalPlayerUserAccess.isAllow) {
        let bpMessage =
          this._localization.captions.breakpoint[normalPlayersBreakpointNumber];
        this.showBreakPointPopup(bpMessage);
        return;
      }
    }
    if (
      (validateBreakPointAccess &&
        (validateBreakPointAccess.isAllow ||
          validateBreakPointAccess.isViewOnly)) ||
      breakPointNumber === 0
    ) {
      if (event[0] === TeeTimeActions.frostDelay) {
        this._teeTimesActionBusiness.frostDelayLogic(
          event[1],
          teeSheetCallBack
        );
        return;
      } else if (event[0] === TeeTimeActions.holdTeeTime) {
        if (
          !(await this.userRoleHasPermissionForAllocation(event[1].allocation))
        ) {
          this._utilities.showAllocationCodePermissionDeniedPopup(
            event[1].allocation.name,
            this._localization.captions.common.HoldNotAllowed
          );
          return;
        }
        this.PerformAction(
          event[0],
          event[1],
          undefined,
          isBulk,
          bulkPlayerUserAccess,
          normalPlayerUserAccess,
          teeSheetCallBack
        );
      } else if (event[0] === TeeTimeActions.refund) {
        if(event[1].playerDetail.every((x) => x.bookingSource === BookingSource.golfNow && x.isExternal == true)){
          this._utilities.showAlert(
            this.captions.lbl_ErrorGolfNowRefundError,
            AlertType.Warning,
            ButtonType.Ok
          );
          return;
        }
        if (event[1] && event[1].playerDetail) {
          event[1].playerDetail = event[1].playerDetail.filter(o => (o.bookingSource !== BookingSource.golfNow) || (o.bookingSource === BookingSource.golfNow && o.isExternal == false));
          let distinctTransactionIds = [
            ...new Set(event[1].playerDetail.map((x) => x.transactionId)),
          ];
          distinctTransactionIds = distinctTransactionIds.filter((x) => x > 0);
          if (distinctTransactionIds.length == 1) {
            let isreturnorginalpaymentonly =
              await this._propertySettingDataService.GetReturnOriginalPaymentConfigurationSetting();
            if (
              isreturnorginalpaymentonly &&
              isreturnorginalpaymentonly.value === "true"
            ) {
              let transactionId = distinctTransactionIds.find((x) => x > 0);
              let transactionpayments =
                await this._cmsService.GetPaymentTransaction(transactionId);
              if (
                isreturnorginalpaymentonly &&
                isreturnorginalpaymentonly.value === "true" &&
                transactionpayments &&
                transactionpayments.some(
                  (t) =>
                    Number(t.paymentMethod) == PaymentMethods.RainCheck &&
                    t.paymentStatus == 1 &&
                    !t.isRefunded
                )
              ) {
                this._utilities.showAlert(
                  this._localization.captions.common
                    .RefundTeeTimeWithRainCheckRedeemed,
                  AlertType.Error,
                  ButtonType.Ok
                );
                return;
              }
            }
          }
          if (event[1].scheduledTeeTimeId) {
            let rainCheckDetail =
              await this._rainCheckRedeemCheck.GetAllRainChecksByTeeTime(
                event[1].scheduledTeeTimeId
              );
            if (
              rainCheckDetail &&
              rainCheckDetail.length &&
              distinctTransactionIds.length == 1
            ) {
              if (extraParams && extraParams.frompage == "teetimesearch") {
                let playerDetails =
                  await this._teeTimesActionBusiness.getScheduledPlayer(
                    event[1].scheduledTeeTimeId
                  );
                const selectedPlayer = playerDetails.filter(
                  (x) =>
                    x.playerId == event[1].playerDetail[0].playerId &&
                    x.ticketNumber != null
                );
                let transactions = Array.from(
                  new Set(selectedPlayer.map((x) => x.ticketNumber))
                );
                if (transactions && transactions.length == 1) {
                  this._utilities.showAlert(
                    this._localization.captions.common
                      .RefundTeeTimeWithRainCheckIssue,
                    AlertType.Error,
                    ButtonType.Ok
                  );
                  return;
                }
              } else {
                this._utilities.showAlert(
                  this._localization.captions.common
                    .RefundTeeTimeWithRainCheckIssue,
                  AlertType.Error,
                  ButtonType.Ok
                );
                return;
              }
            }
          }
        }
        let PopupData = {
          course: {
            id: event[1].course.id,
          },
          time: event[1].time,
          scheduledTeeTimeId: event[1].scheduledTeeTimeId,
          frompage: extraParams ? extraParams.frompage : "",
          playerDetail: [],
        };
        PopupData.playerDetail.push(...event[1].playerDetail);
        let isMultiple = this.HasRefundedPlayersWithMultipleTicket(event[1]);
        if (!isMultiple) {
          this._refundBussiness.ProceedRefund(event[1]);
          return;
        }
        await this.PerformAction(
          TeeTimeActions.refund,
          PopupData,
          "",
          teeSheetCallBack
        );
      } else if (event[0] === TeeTimeActions.payment && extraParams) {
        if (
          !(await this._retailBusinessService.onClickValidateSelectedOutlet(
            event[1]?.course.id
          ))
        ) {
          event[1].isFromSearch = true;
          this.PerformAction(
            event[0],
            event[1],
            undefined,
            undefined,
            undefined,
            undefined,
            teeSheetCallBack,
            true
          );
          return;
        }
        let shopPopupData = {
          course: {
            id: extraParams.Obj.courseID,
          },
          time: extraParams.Obj.scheduleDateTime,
          scheduledTeeTimeId: extraParams.Obj.scheduleTeeTimeID,
          schedulePlayerID: extraParams.Obj.schedulePlayerID,
          playerStatus: extraParams.Obj.playerStatus,
          playerDetail: event[1].playerDetail,
          isFromSearch: true,
        };
        await this.PerformAction(
          TeeTimeActions.payment,
          shopPopupData,
          "search",
          teeSheetCallBack
        );
      } else if (event[0] === TeeTimeActions.reinstate) {
        if (
          !(await this.userRoleHasPermissionForAllocation(event[1].allocation))
        ) {
          this._utilities.showAllocationCodePermissionDeniedPopup(
            event[1].allocation.name,
            this._localization.captions.common.ReInstateNotAllowed
          );
          return;
        }
        this.popUpComponentDetails = {
          componentName: ReinstateModalComponent,
          popUpDetails: {
            isStepper: false,
            eventName: "notifyParent",
          },
        };
        this._dialog
          .open(CommonPopupComponent, {
            width: "60%",
            height: "60%",
            data: {
              title: this.captions.reinStateTitle,
              update: this.captions.reinStateSave,
              cancel: this.captions.reinStateCancel,
              componentDetails: this.popUpComponentDetails,
              info: extraParams.Obj,
              pmsActivityId: extraParams.pmsActivityId
                ? extraParams.pmsActivityId
                : null,
              actionType: ButtonAction.reinstate,
            },
            disableClose: true,
          })
          .afterClosed()
          .pipe(takeUntil(this.$destroyed))
          .subscribe((result) => {
            teeSheetCallBack();
          });
      } else if (event[0] === TeeTimeActions.noShow) {
        let validateBreakPointAccess =
          await this._teeTimesActionBusiness.validateBreakPointAccess(
            UserAccessBreakPoints.NOSHOW
          );
        if (
          !(await this.userRoleHasPermissionForAllocation(event[1].allocation))
        ) {
          this._utilities.showAllocationCodePermissionDeniedPopup(
            event[1].allocation.name,
            this._localization.captions.common.NoShowNotAllowed
          );
          return;
        }
        if (validateBreakPointAccess && !validateBreakPointAccess.isAllow) {
          return;
        }
        if(this._teeTimeCancellationNoShowFeeUtilities.IsNoShowCancellationPolicyConfigured()
          && !(await this._retailBusinessService.onClickValidateSelectedOutlet(
            event[1]?.course.id)))
        {
          this.PerformAction(
            event[0],
            event[1],
            undefined,
            isBulk,
            bulkPlayerUserAccess,
            normalPlayerUserAccess,
            teeSheetCallBack,
            true
          );
        }
        else
        {
          this.PerformAction(
            event[0],
            event[1],
            undefined,
            isBulk,
            bulkPlayerUserAccess,
            normalPlayerUserAccess,
            teeSheetCallBack
          );
        }
        
      } else if (event[0] === TeeTimeActions.unMarkNoShow) {
        let validateBreakPointAccess =
          await this._teeTimesActionBusiness.validateBreakPointAccess(
            UserAccessBreakPoints.UNMARKNOSHOW
          );
        if (
          !(await this.userRoleHasPermissionForAllocation(event[1].allocation))
        ) {
          this._utilities.showAllocationCodePermissionDeniedPopup(
            event[1].allocation.name,
            this._localization.captions.common.BookingNotAllowed
          );
          return;
        }
        if (validateBreakPointAccess && !validateBreakPointAccess.isAllow) {
          return;
        }
        this.PerformAction(
          event[0],
          event[1],
          undefined,
          isBulk,
          bulkPlayerUserAccess,
          normalPlayerUserAccess,
          teeSheetCallBack
        );
      } else if (event[0] === TeeTimeActions.notify) {
        if (
          extraParams &&
          extraParams.Obj.status == this.captions.Cancelled
        ) {
          this.PerformAction(
            event[0],
            extraParams,
            undefined,
            teeSheetCallBack,
            eventNotifydata
          );
          return;
        } else {
          this.PerformAction(
            event[0],
            event[1],
            undefined,
            teeSheetCallBack,
            undefined,
            undefined,
            undefined,
            undefined,
            eventNotifydata
          );
          return;
        }
      } else if (event[0] === TeeTimeActions.blockTeeTime) {
        if (
          !(await this.userRoleHasPermissionForAllocation(event[1].allocation))
        ) {
          this._utilities.showAllocationCodePermissionDeniedPopup(
            event[1].allocation.name,
            this._localization.captions.common.BlockNotAllowed
          );
          return;
        }
        this.PerformAction(
          event[0],
          event[1],
          undefined,
          isBulk,
          bulkPlayerUserAccess,
          normalPlayerUserAccess,
          teeSheetCallBack
        );
      } else if (event[0] === TeeTimeActions.clearPlayers) {
        if(!this.retainWithNoActionClearCancel){
            var isNotValid = !isBulkBooking
            ? await this.ValidateCheckInCheckOut(
                event[1].playerDetail,
                this._localization.captions.common.ClearNotAllowed
              )
            : false;
          if (isNotValid) return;
          var allocationCodePermissions = await this.userRoleHasPermissionForAllocation(event[1].allocation);
          if ( !allocationCodePermissions )  {
            this._utilities.showAllocationCodePermissionDeniedPopup(
              event[1].allocation.name,
              this._localization.captions.common.ClearNotAllowed
            );
            return;
          }
          this.PerformAction(
            event[0],
            event[1],
            undefined,
            isBulk,
            bulkPlayerUserAccess,
            normalPlayerUserAccess,
            teeSheetCallBack,
            undefined,
            undefined,
            allocationCodePermissions
          );
        }
      } else if (event[0] === TeeTimeActions.cancelPlayers) {
        if(!this.retainWithNoActionClearCancel){
          var allocationCodePermissions = await this.userRoleHasPermissionForAllocation(event[1].allocation);
          if (isBulk && !allocationCodePermissions)
          {
            this._utilities.showAllocationCodePermissionDeniedPopup(
              event[1].allocation.name,
              this._localization.captions.common.CancelNotAllowed
            );
            return;
          }
          if( this._teeTimeCancellationNoShowFeeUtilities.IsNoShowCancellationPolicyConfigured() 
            && !(await this._retailBusinessService.onClickValidateSelectedOutlet(event[1]?.course.id)))
          {
            this.PerformAction(
              event[0],
              event[1],
              undefined,
              isBulk,
              bulkPlayerUserAccess,
              normalPlayerUserAccess,
              teeSheetCallBack,
              true,
              allocationCodePermissions
            );
          }
          else 
          {
            this.PerformAction(
              event[0],
              event[1],
              undefined,
              isBulk,
              bulkPlayerUserAccess,
              normalPlayerUserAccess,
              teeSheetCallBack,
              undefined,
              undefined,
              allocationCodePermissions
            );
          }
        }
      } else if (event[0] === TeeTimeActions.move) {
        var isNotValid = !isBulkBooking
          ? await this.ValidateCheckInCheckOut(
              event[1].playerDetail,
              this._localization.captions.common.MoveNotAllowed
            )
          : false;
        if (isNotValid) return;
        var allocationCodePermissions = await this.userRoleHasPermissionForAllocation(event[1].allocation);
        if ( !allocationCodePermissions )  {
          this._utilities.showAllocationCodePermissionDeniedPopup(
            event[1].allocation.name,
            this._localization.captions.common.MoveNotAllowed
          );
          return;
        }
        this.PerformAction(
          event[0],
          event[1],
          undefined,
          isBulk,
          bulkPlayerUserAccess,
          normalPlayerUserAccess,
          teeSheetCallBack,
          undefined,
          undefined,
          allocationCodePermissions
        );
      } else if (event[0] === TeeTimeActions.copy) {
        var allocationCodePermissions = await this.userRoleHasPermissionForAllocation(event[1].allocation);
        if ( !allocationCodePermissions )  {
          this._utilities.showAllocationCodePermissionDeniedPopup(
            event[1].allocation.name,
            this._localization.captions.common.CopyNotAllowed
          );
          return;
        }
        this.PerformAction(
          event[0],
          event[1],
          undefined,
          isBulk,
          bulkPlayerUserAccess,
          normalPlayerUserAccess,
          teeSheetCallBack,
          undefined,
          undefined,
          allocationCodePermissions
        );
      } else if (event[0] === TeeTimeActions.copyToMany) {
        var allocationCodePermissions = await this.userRoleHasPermissionForAllocation(event[1].allocation);
        if (!allocationCodePermissions)    
        {
          this._utilities.showAllocationCodePermissionDeniedPopup(
            event[1].allocation.name,
            this._localization.captions.common.CopyNotAllowed
          );
          return;
        }
        this.PerformAction(
          event[0],
          event[1],
          undefined,
          isBulk,
          bulkPlayerUserAccess,
          normalPlayerUserAccess,
          teeSheetCallBack,
          undefined,
          undefined,
          allocationCodePermissions
        );
      } else if (event[0] === TeeTimeActions.ViewedByStarter) {
        let teeTimeAllocationBlock;
        teeTimeAllocationBlock = new TeeTimeAllocationBlock();
        teeTimeAllocationBlock.viewedByStarter = !event[1]?.viewedByStarter;
        teeTimeAllocationBlock.id = event[1]?.teeGridSlotId;
        teeTimeAllocationBlock.allocationDateTime =
          event[1]?.allocationDateTime;
        teeTimeAllocationBlock.originalHoleNumber =
          event[1]?.originalHoleNumber;
        teeTimeAllocationBlock.allocationBlockId = event[1]?.allocation.id;
        teeTimeAllocationBlock.courseId = event[1]?.course.id;
        teeTimeAllocationBlock.holeNumber = event[1]?.hole;
        this.saveTeeTimeAllocationBlock(teeTimeAllocationBlock, TeeTimeAllocationBlockEvent.FromTeeTime);
        event[1].viewedByStarter = !event[1].viewedByStarter;
        if (
          teeTimeSetting &&
          teeTimeSetting?.requireCheckInCheckOut &&
          teeTimeSetting?.enableAutoCheck &&
          this._utilities.ValidateDatesAreEqual(
            this._Utilities.getDate(sessionStorage.getItem("propertyDate")),
            this._Utilities.getDate(event[1]?.time)
          )
        ) {
          var lstPlayerId: number[] = [];
          lstPlayerId = event[1].playerDetail
            ?.filter(
              (x) =>
                (x.playerStatus & PlayerPaymentstatus.CheckIn) == 0 &&
                (x.playerStatus & PlayerPaymentstatus.CheckOut) == 0
            )
            .map((y) => y.playerId);
          if (lstPlayerId?.length > 0) {
            this._teeTimesActionService
              .checkInOutStatus(TeeTimeActions.checkIn, lstPlayerId)
              .then((x) => {
                teeSheetCallBack(true);
              });
          }
        }
        this.triggerForRefresh.next();
        return;
      } else if (event[0] == TeeTimeActions.viewEform) {
        if (this.dmConfig) {
          this.PerformAction(
            event[0],
            event[1],
            undefined,
            undefined,
            undefined,
            undefined,
            teeSheetCallBack,
            true
          );
          return;
        }
      } else if (event[0] === TeeTimeActions.Redeem) {
        if (
          !(await this._retailBusinessService.onClickValidateSelectedOutlet(
            event[1]?.course.id
          ))
        ) {
          this.PerformAction(
            event[0],
            event[1],
            undefined,
            undefined,
            undefined,
            undefined,
            teeSheetCallBack,
            true
          );
          return;
        }
        if (
          event[1].playerDetail.some(
            (x) =>
              (x.playerStatus ==
                (PlayerPaymentstatus.booked | PlayerPaymentstatus.unPaid) ||
                x.playerStatus ==
                  (PlayerPaymentstatus.booked | PlayerPaymentstatus.refund) ||
                x.playerStatus ==
                  (PlayerPaymentstatus.booked | PlayerPaymentstatus.unPaid | PlayerPaymentstatus.CheckIn)) &&
              x.amountPaid == 0
          ) &&
          event[1].playerDetail.some(
            (x) => x.memberNumber != "" && x.amountPaid == 0
          ) &&
          event[1].playerDetail.some((x) => x.amountPaid == 0)
        ) {
          this.PerformAction(
            event[0],
            event[1],
            undefined,
            isBulk,
            bulkPlayerUserAccess,
            normalPlayerUserAccess,
            teeSheetCallBack
          );
          return;
        } else {
          this._utilities.showAlert(
            this.captions.memberAction,
            AlertType.Warning,
            ButtonType.Ok
          );
        }
      } else if (event[0] === TeeTimeActions.linkmultipack) {
        const session: any[] = [];
        event[1].playerDetail.forEach((element) => {
          session.push({
            guestId:
              element.memberNumber != ""
                ? element.memberNumber
                : element.imageReferenceId,
            guestType:
              element.memberNumber != "" ? guestType.member : guestType.guest,
          });
        });
        if (
          (await (
            await this._linkMultipackBusiness.GetMultiPackDetails(session)
          ).length) == 0
        ) {
          this._utilities.showAlert(
            this.captions.NoMultipackAssociatedWithTheSelectedPlayer,
            AlertType.Info,
            ButtonType.Ok
          );
        } else {
          this.PerformAction(
            event[0],
            event[1],
            undefined,
            isBulk,
            bulkPlayerUserAccess,
            normalPlayerUserAccess,
            teeSheetCallBack
          );
          return;
        }
      } else if (event[0] === TeeTimeActions.addRetailItem || event[0] === TeeTimeActions.editRetailItem){
        if(event[0] === TeeTimeActions.editRetailItem)
        {
          var response = await this._teeTimesActionService.getPlayerRetailItems(event[1].playerDetail.map(x=>x.scheduledTeeTimePlayerId));
          var playerRetailItems = response.filter(y=>y.retailItemType==RetailItemType.RetailItemRetailPOSOnly)?.map(x => this.mapPlayerRetailItems(x,event[1].playerDetail.find(y=>y.scheduledTeeTimePlayerId==x.scheduledTeeTimePlayerId)));
          var playerRentalItems = response.filter(y=>y.retailItemType==RetailItemType.PMSAddOnsRentalItem)?.map(x => this.mapPlayerRetailItems(x,event[1].playerDetail.find(y=>y.scheduledTeeTimePlayerId==x.scheduledTeeTimePlayerId)));
        }
        
        if (
          !(await this._retailBusinessService.onClickValidateSelectedOutlet(
            event[1]?.course.id,
            true
          ))
        ) {
          this.PerformAction(
            event[0],
            event[1],
            undefined,
            undefined,
            undefined,
            undefined,
            teeSheetCallBack,
            true,
            undefined,
            undefined,
            playerRetailItems,
            playerRentalItems,
            validateBreakPointAccess.isViewOnly
          );
          return;
        }
        else
        {
          this.PerformAction(
            event[0],
            event[1],
            undefined,
            undefined,
            undefined,
            undefined,
            teeSheetCallBack,
            undefined,
            undefined,
            undefined,
            playerRetailItems,
            playerRentalItems,
            validateBreakPointAccess.isViewOnly
          );
          return;
        }
      } 
      else if (event[0] === TeeTimeActions.markAsPaid) {
        if (
          !(await this._retailBusinessService.onClickValidateSelectedOutlet(
            event[1]?.course.id,
            true
          ))
        ) {
          this.PerformAction(
            event[0],
            event[1],
            undefined,
            undefined,
            undefined,
            undefined,
            teeSheetCallBack,
            true
          );
          return;
        }
        let defaultConfig = JSON.parse(
          sessionStorage.getItem("defaultSettings")
        );
        if (
          !(
            defaultConfig?.markAsPaidPaymentMethod &&
            defaultConfig.markAsPaidPaymentMethod > 0
          )
        ) {
          this._utilities.showError(
            this._localization.captions.teetime.defaultPaymentWarning
          );
          return;
        } else if (
          (this._retailSharedService?.selectedProducts &&
            this._retailSharedService.selectedProducts?.length > 0) ||
          (this._retailSharedService?.SelectedPlayers &&
            this._retailSharedService.SelectedPlayers?.length > 0)
        ) {
          this._utilities.showError(
            this._localization.captions.teetime.validationExistingRetailItem
          );
          return;
        } else {
          this.PerformAction(
            event[0],
            event[1],
            undefined,
            isBulk,
            bulkPlayerUserAccess,
            normalPlayerUserAccess,
            teeSheetCallBack
          );
          return;
        }
      } else {
        if (
          event[0] === TeeTimeActions.payment ||
          event[0] === TeeTimeActions.checkOut ||
          event[0] === TeeTimeActions.checkInCheckOut ||
          (event[0] === TeeTimeActions.checkIn &&
            teeTimeSetting &&
            teeTimeSetting != null &&
            teeTimeSetting.allowPaymentInCheckIn)
        ) {
          if (
            !(await this._retailBusinessService.onClickValidateSelectedOutlet(
              event[1]?.course.id,
              true
            ))
          ) {
            this.PerformAction(
              event[0],
              event[1],
              undefined,
              undefined,
              undefined,
              undefined,
              teeSheetCallBack,
              true
            );
            return;
          }
        }
        this.PerformAction(
          event[0],
          event[1],
          undefined,
          isBulk,
          bulkPlayerUserAccess,
          normalPlayerUserAccess,
          teeSheetCallBack
        );
        return;
      }
    }
  }
  /**
   * ValidateCheckInCheckOut to check if player CheckedIn / Checked out
   * @param playerDetails
   */

  public mapPlayerRetailItems(playerRetailItems,playerDetail): GroupRetailItems{
    return {
      groupId : playerRetailItems.scheduledTeeTimePlayerId,
      retailItems : JSON.parse(playerRetailItems.retailItems),
      
      groupName : `${this.captions.p}${playerDetail.playPos} - ${playerDetail.fullName}`,
    }
  }

  public async ValidateCheckInCheckOut(
    playerDetails: TeeSheetPlayerDetails[],
    actionMessage: string,
    isFromDragDrop: boolean = false
  ) {
    var isNotValid: boolean = false;
    var lstIsCheckInCheckOut = playerDetails?.filter(
      (x) => x.isCheckedIn || x.isCheckedOut
    );
    if (
      lstIsCheckInCheckOut?.length === playerDetails.length ||
      (isFromDragDrop && lstIsCheckInCheckOut?.length > 0)
    ) {
      this._utilities.showError(
        `${actionMessage} ${this.captions.CheckInCheckOutRestrictedValidation}`
      );
      isNotValid = true;
    }
    return isNotValid;
  }
  /**
   * showValidationCheckInCheckOut Method to restrict clear, cancel, move for Check In/Check Out player(s)
   * @param playerDetail
   * @param callback
   */
  public async showValidationCheckInCheckOut(
    playerDetail: TeeSheetPlayerDetails,
    actionMessage: string
  ) {
    var isNotValid: boolean = false;
    if (playerDetail?.isCheckedIn) {
      this._utilities.showError(
        `${actionMessage} ${this.captions.CheckInRestrictedValidation}`
      );
      return (isNotValid = true);
    } else if (playerDetail?.isCheckedOut) {
      this._utilities.showError(
        `${actionMessage} ${this.captions.CheckOutRestrictedValidation}`
      );
      return (isNotValid = true);
    }
    return isNotValid;
  }
  async userRoleHasPermissionForAllocation(
    allocation: Allocation
  ): Promise<allocationBlockPlayerTypePermission[]> {
    let allocationCodePermissions: allocationBlockPlayerTypePermission[] = [];
    if (!allocation || !allocation.id) {
      return allocationCodePermissions;
    }
    let allocationId = allocation.id;
    if (this._utilities.IsAllocationCodePermissionEnabled()) {
      allocationCodePermissions =
        await this._allocationCodeManagementService.getAllocationBlockPermissionByRole();
      let defaultAllocationCodePermissions = [];
      if (allocationCodePermissions && allocationCodePermissions.length > 0) {
        defaultAllocationCodePermissions = allocationCodePermissions.filter(
          (r) => r.allocationBlockId == allocationId
        );
      }
      if (
        !defaultAllocationCodePermissions ||
        defaultAllocationCodePermissions.length == 0
      ) {
        allocationCodePermissions = null;
      }
    }
    return  allocationCodePermissions ;
  }

  public validatePlayerPostionConflicts(
    players: TeeSheetPlayerDetails[]
  ): TeeSheetPlayerDetails[] {
    const playerPosition: number[] = players.map((p) => p.playPos);
    // checking dropped players having duplicate position / conflicts
    const isPlayerPositionConflicts: boolean = playerPosition.some(
      (o, index) => playerPosition.lastIndexOf(o) != index
    );

    let sortedPlayers = players;
    if (isPlayerPositionConflicts) {
      // Deduplicating player position by ranking based on initial player slot position
      let sortedPlayersByInitialPosition = players.sort(
        (left, right) =>
          left.initialPlayerSlotPosition - right.initialPlayerSlotPosition
      );
      sortedPlayersByInitialPosition.forEach((o, index: number) => {
        o.playPos = index + 1;
      });
      sortedPlayers = sortedPlayersByInitialPosition;
    }
    return sortedPlayers;
  }

  private isBulkBooking(teeData: TeeSheetSkeletonData): boolean {
    let isBulkBooking = false;
    let blockPlayers =
      teeData &&
      teeData.playerDetail &&
      teeData.playerDetail.length > 0 &&
      teeData.playerDetail.filter((x) => !x.isBlocked);
    if (
      teeData &&
      teeData.playerDetail &&
      teeData.playerDetail.length > 0 &&
      blockPlayers &&
      blockPlayers.length > 0
    ) {
      isBulkBooking = blockPlayers
        .map((x) => x.teeTimeFormat)
        .every((y) => y == TeeTimeFormat.BulkTee);
    }
    return isBulkBooking;
  }

  private isBulkBookingWithBlockPlayers(
    teeData: TeeSheetSkeletonData
  ): boolean {
    let isBulkBooking = false;
    if (teeData && teeData.playerDetail && teeData.playerDetail.length > 0) {
      isBulkBooking = teeData.playerDetail
        .map((x) => x.teeTimeFormat)
        .every((y) => y == TeeTimeFormat.BulkTee);
    }
    return isBulkBooking;
  }

  private isNormalBooking(teeData: TeeSheetSkeletonData): boolean {
    let isNormalBooking = false;
    let blockPlayers =
      teeData &&
      teeData.playerDetail &&
      teeData.playerDetail.length > 0 &&
      teeData.playerDetail.filter((x) => !x.isBlocked);
    if (
      teeData &&
      teeData.playerDetail &&
      teeData.playerDetail.length > 0 &&
      blockPlayers &&
      blockPlayers.length > 0
    ) {
      isNormalBooking = blockPlayers
        .map((x) => x.teeTimeFormat)
        .every((y) => y !== TeeTimeFormat.BulkTee);
    }
    return isNormalBooking;
  }

  private isAllblockPlayers(teeData: TeeSheetSkeletonData): boolean {
    let blockPlayers = false;
    if (teeData && teeData.playerDetail && teeData.playerDetail.length > 0) {
      blockPlayers = teeData.playerDetail
        .map((x) => x.isBlocked)
        .every((x) => x);
    }
    return blockPlayers;
  }

  public async saveTeeTimeAllocationBlock(
    teeTimeAllocationBlock: TeeTimeAllocationBlock, teeTimeAllocationBlockEvent: TeeTimeAllocationBlockEvent = TeeTimeAllocationBlockEvent.None
  ) {
    return await this._golfTeeTimeAllocationBlockService.saveTeeTimeAllocationBlock(
      [teeTimeAllocationBlock], teeTimeAllocationBlockEvent
    );
  }

  public showBreakPointPopup(functionName?: string, callBack?: any) {
    let message: string;
    message = `${this._localization.captions.common.BreakPointAccessDeniedMsg}
             <br><br>${this._localization.captions.common.Breakpoint}: ${functionName}`;

    this._utilities.showAlert(
      message,
      AlertType.AccessDenied,
      ButtonType.Ok,
      callBack
    );
  }

  public async GetAllocationBlockPermissionByRole(): Promise<
    allocationBlockPlayerTypePermission[]
  > {
    return await this._allocationCodeManagementService.getAllocationBlockPermissionByRole();
  }

  public async GetAllAllocationBlocks(): Promise<API.TeeTimeAllocationBlock[]> {
    return await this._allocationCodeManagementService.getAllocationBlocks(
      false
    );
  }

  async updateTeeTime(teeTime: TeeTimeModel): Promise<boolean> {
    return await this._teeTimesActionBusiness.updateTeeTime(teeTime);
  }
  // for 103018 && 79273 => Mapped schedule teetime data for a slot to bookteesheet function's param (event) 
  public async MapSlotScheduledTeeTimeToBookTeeSheet(data):Promise<TeeSheetSkeletonData> {
    let obj: TeeSheetSkeletonData = {
      allocation: data.allocationBlockId,
      allocationDateTime: data.allocationDateTime,
      comments: data.comment,
      course:data.courseId,
      createdDateTime: data.createdDateTime,
      hole: data.holeNumber,
      holes: data.holeNumber, // 1 or 18 holes      
      id: Guid.create().toString(),   // guid
      originalHoleNumber: data.originalHoleNumber,
      playerDetail: this.mapPlayerDetails(data.teeTimePlayerDetails),
      scheduledTeeTimeId: data.id,
      slotBackgroundInfo: data?.slotBackgroundInfo,
      status: data.scheduleStatus,   // by default
      teeGridSlotId: data?.teeGridSlotId,      
      time: data.scheduleDateTime, // ISO datetime string      
      type: data.scheduleType,  
      unmodifiedSlotData: this.bindUnmodifiedSlot(data),  // to be replaced after move , copy
      viewedByStarter: data.viewedByStarter,
      isFromSearch: false,
      isFromTeeSheetForCancel:false,
      isNextSlotAvailable:data.isNextSlotAvailable,
      isPreviousSlotAvailable:data.isPreviousSlotAvailable,
      isSlotsSkipped:data.isSlotsSkipped
    }
    await this.getCourses().then(result=>{
      var courseTemp = result.filter(x=>x.id === data.courseId)[0];

      obj.unmodifiedSlotData.course = {
        id: courseTemp.id,
        course: courseTemp.name
      };
    });
    await this.getAllocationBlocks().then(result=>{
      obj.unmodifiedSlotData.allocation = result.filter(x=>x.id === data.allocationBlockId)[0];
    });
    obj.course = obj.unmodifiedSlotData.course;
    return obj;
  }
 
  bindUnmodifiedSlot(data) {
    return {
      allocation: this.allocationBlocks.filter(x => x.id === data.allocationBlockId)[0],
      allocationDateTime: data.allocationDateTime,
      comments: data.comment,
      course: this.course.filter(x => x.id === data.courseId)[0],
      hole: data.holeNumber,
      holes: data.holeNumber,
      id: 0,
      isDropAllowed: true,
      originalHoleNumber: data.originalHoleNumber,
      playerDetail: this.mapPlayerDetails(data.teeTimePlayerDetails),
      slotBackgroundInfo: {id: 8, name: 'Peaky blinders', colorCode: '#8e3333', icon: '', displayOnTeesheet: false},
      status: {name: 'Booked', icon: '', colorCode: '', scheduleStatus: 1},
      teeGridSlotId: data.teeGridSlotId,
      time:data.scheduleDateTime,
      type:  data.scheduleType,  
      viewedByStarter: data.viewedByStarter
    }
  }
  async getCourses() {
    return await this._courseDataService.getCourses();
  }
  async getAllocationBlocks() {
    return await this.GetAllocationBlocks();
  }

  mapPlayerDetails(teeTimePlayerDetails): TeeSheetPlayerDetails[]{
    let teeTimePlayers: TeeSheetPlayerDetails[] = [];
    teeTimePlayerDetails.forEach((player, index) => {
        let teeTimePlayer: TeeSheetPlayerDetails = {
          isDragDisabled: false,
          scheduledTeeTimePlayerId: player.scheduledTeeTimePlayerId,
          playPos: player.playerPosition,
          firstName: player.firstName,
          lastName: player.lastName,
          pronounced: player.pronounced,
          gender: player.gender,
          amountPaid: player.amountPaid,
          memberNumber: player.memberNumber,
          playerComment: player.playerComment,
          tournamentId: player.tournamentId,
          playerId: player.teeTimePlayerId,
          bagNumber: player.bagNumber,
          playerAddress: player.playerAddress,
          fullName: '',
          deposits:player.deposits,
          transactionId:player.transactionId,
          playerStatus: player.playerStatus,
          groupId: player.groupId,
          bookingId: player.bookingId,
          createdBy: player.createdBy,
          lastUpdatedDateTime: player.lastUpdatedDateTime,
          playersPerGroup: player.playersPerGroup,
          teeTimeFormat: player.gameFormat,
          rateType: player.rateType, // TO Do
          playerType: player.playerType, // TO Do
          holes: player.holes,
          playerPos: player.playerPosition,
          imageReferenceId: player.imageReferenceId,
          bookingSource: player.bookingSource,
          allocationBlockId: player.allocationBlockId,
          confirmationNumber: player.confirmationNumber,
          cartId: player.cartId,
          caddy: player.caddy,
          caddyType: player.caddyType,
          caddyId: player.caddyId,
          caddyTypeId: player.caddyTypeId,
          isFlagDay: player.isFlagDay,
          isBlocked: player.isBlocked,
          ticketNumber: player.ticketNumber,
          scheduledTeeTimePlayerFee: player.scheduledTeeTimePlayerFee,
          entryFee: player.entryFee,
          playerTypeId:player.platerType,
          cartType: player.cartType,
          linkingId: player.playerLinkId,
          playerStayDetail: player.playerStayDetail,
          multiPackTransactionDetailId: player.multiPackTransactionDetailId,
          multiPackGreenFeeValue: null,
          multiPackCartFeeValue: null,
          isMarkAsPaid: player.isMarkAsPaid,
          patronId: player.patronId,
          roomNumber: player.roomNumber,
          arrivalDate: player.arrivalDate,
          departureDate: player.departureDate,
          packageCode: player.packageCode,
          customField1: player.customField1,
          customField2: player.customField2,
          customField3: player.customField3,
          customField4: player.customField4,
          customField5: player.customField5,
          eformTotalCount: player.eformTotalCount,
          eformSubmittedCount: player.eformSubmittedCount,
          eformCount: player.eformCount,
          eformSubmitted: player.eformSubmitted,
          vip: player.vip,
          playerCategoryId: player.playerCategoryId,
          isTrail: player.isTrail,
          isWalk: player.isWalk,
          activityId: player.activityId,
          isExternal: player.isExternal,
          contactInformation: player.contactInformation
        }
        teeTimePlayers.push(teeTimePlayer);
    });
    return teeTimePlayers;
  }

  async UIMapperForAPIScheduleTeetimeToUITeeTimeModel(scheduledTeeTime) {
    let uiScheduledTeeTime : TeeTimeModel;
      let playerDetails : PlayerDetail[] = scheduledTeeTime.scheduledTeeTimePlayers as PlayerDetail[];
      uiScheduledTeeTime = {
        id: scheduledTeeTime.id,
        comment: scheduledTeeTime.comment,
        courseId: scheduledTeeTime.courseId,
        holeNumber: scheduledTeeTime.holeNumber,
        originalHoleNumber: scheduledTeeTime.originalHoleNumber,
        playerId: 0,
        scheduleDateTime: this._localization.ConvertDateToISODateTime(this._localization.getDate(scheduledTeeTime.scheduleDateTime)),
        scheduledTeeTimePlayers: scheduledTeeTime.scheduledTeeTimePlayers,
        createdDateTime: scheduledTeeTime.createdDateTime,
        playerIds: playerDetails.map(o => o.id),
        scheduleStatus: scheduledTeeTime.scheduleStatus,
        tournamentId: scheduledTeeTime.tournamentId ? scheduledTeeTime.tournamentId : 0,
        isModified: scheduledTeeTime.isModified != undefined ? scheduledTeeTime.isModified : '',
        ticketNumber: scheduledTeeTime.ticketNumber != undefined && scheduledTeeTime.ticketNumber.length > 0 ? scheduledTeeTime.ticketNumber : '',
        viewedByStarter:  scheduledTeeTime.viewedByStarter != undefined ? scheduledTeeTime.viewedByStarter : undefined 
      };
      return uiScheduledTeeTime;
  }
}
