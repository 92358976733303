import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { AlertType, ButtonType, AlertAction, } from 'src/app/shared/shared-models';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TeefeeAlertPopupComponent } from 'src/app/tee-time-actions/tee-fee-selection-modal/teefee.component';
import { LastVisitedTeeDetail, TeeSheetSessionConstants } from 'src/app/tee-time/tee-sheet/tee-sheet-constants';
import { TeeFeeGridContent, TeeSheetSkeletonData } from '../models/teesheet.form.models';
import { GolfUtilities } from './golf-utilities';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { PlayerTeeTimeSlot } from 'src/app/tee-time-actions/move-player-modal/move-player-modal.model';
import { CaddyTypeSelectionPopupComponent } from 'src/app/tee-time-actions/caddytype-selection-modal/caddytype-selection.component';
import _ from 'lodash';
import { CaddyTypeRequirement } from 'src/app/ag-common/constants';
import { SystemdefaultsInformationService } from 'src/app/core/services/systemdefaults-information.service';
import { TableHeaderOptions } from 'src/app/common/Models/ag-models';
import { CopyMoveResult, ValidationResult } from '../models/teesheet.api.models';
@Injectable()
export class TeeSheetUtilities {
    private refresher: Subject<void> = new Subject<void>();

    /**
     *
     */
    constructor(private dialog: MatDialog, private _utilities: GolfUtilities, private _localization: GolfLocalization, private _SystemdefaultsInformationService: SystemdefaultsInformationService) {

    }
    /**
     * Use this subscribe for tee sheet refresh
     */
    public teeSheetRefresher(): Observable<void> {
        return this.refresher.asObservable();
    }


    /**
     * Use this trigger teesheet refresh
     */
    public triggerTeeSheetRefresh(): void {
        this.refresher.next();
    }

    public showTeeFeeAlert(message: string, type: AlertType, 
      btnType: ButtonType = ButtonType.Ok,teeFeeGridContent = [],
      callback: (result: AlertAction, extraParams?: any[]) => void, disableUseDestinationTeeFee: boolean): MatDialogRef<TeefeeAlertPopupComponent, AlertAction> {
      const dialogRef = this.dialog.open(TeefeeAlertPopupComponent, {
          height: '65%',
          width: '50%',
          data: { 
            type: type, 
            message: message, 
            buttontype: btnType, 
            header: "", 
            disableUseDestinationTeeFee: disableUseDestinationTeeFee,
            tableContent: teeFeeGridContent,
            tableHeaders: this.getHeaderOptionsForTeeFeeAlert()
           },
          panelClass: 'small-popup',
          disableClose: true,
      });
      dialogRef.afterClosed().subscribe(res => {
          if (callback)
              callback(res);
      });
      return dialogRef;
  }
    public showCaddySelectionPopup(playerTeeTimeSlotCaddyInfo : PlayerTeeTimeSlot[],isCaddyAvailableFromSource,callback: (result: boolean, extraParams?: PlayerTeeTimeSlot[]) => void, draggedPlayersCaddyInfo?:PlayerTeeTimeSlot[]):MatDialogRef<CaddyTypeSelectionPopupComponent, boolean> 
    {
        const dialogRef = this.dialog.open(CaddyTypeSelectionPopupComponent, {
            height: '50%',
            width: '50%',
            data: { 
            title: this._localization.captions.common.UpdateCaddyInfo,
            save: this._localization.captions.common.save,
            cancel: this._localization.captions.common.cancel,
            isCaddyAvailableFromSource : isCaddyAvailableFromSource,
            scheduledDateTime : playerTeeTimeSlotCaddyInfo[0].scheduledDateTime ,
            courseId: playerTeeTimeSlotCaddyInfo[0].courseId,
            scheduledTeeTimeId:playerTeeTimeSlotCaddyInfo[0].destinationScheduledTeeTimeId,
            players: playerTeeTimeSlotCaddyInfo,
            playersCaddyInfo : draggedPlayersCaddyInfo,
           },
            panelClass: 'small-popup',
            disableClose: true,
        });
        dialogRef.afterClosed().toPromise().then(res => {

            if (callback)
                callback(res.isCaddyAssigned,res.playerTeeTimeSlotCaddyInfo);
        });
        return dialogRef;
    }
    updateLastVisitedTeeSlot(teeRowData: TeeSheetSkeletonData) {
        let dateTime = this._localization.getDate(teeRowData.time);
        let dateWithoutTime = this._utilities.GetDateWithoutTime(dateTime);
        let isoDate = this._localization.ConvertDateToISODateTime(dateWithoutTime)
        let time = this._localization.LocalizeTime(dateTime);
        let detail: LastVisitedTeeDetail = { courseId: teeRowData.course.id, date: isoDate, hole: teeRowData.hole, time: time };
        sessionStorage.setItem(TeeSheetSessionConstants.LAST_VISITED_TEE_SLOT, JSON.stringify(detail));
    }

    setLastVisitedTeeSlot(detail: LastVisitedTeeDetail) {
        sessionStorage.setItem(TeeSheetSessionConstants.LAST_VISITED_TEE_SLOT, JSON.stringify(detail));
    }

    resetLastVisitedTeeSlot() {
        sessionStorage.setItem(TeeSheetSessionConstants.LAST_VISITED_TEE_SLOT, null);
    }

    getLastVisitedTeeSlot(): LastVisitedTeeDetail {
        let detail = sessionStorage.getItem(TeeSheetSessionConstants.LAST_VISITED_TEE_SLOT);
        if (detail) {
            return JSON.parse(detail);
        }
        return null;
    }
    async showCaddyNotAvailableAlert(validationResult,playerTeeTimeSlotCaddyInfo : PlayerTeeTimeSlot[],draggedPlayersCaddyInfoTemp? :PlayerTeeTimeSlot[]) {
        // check caddy available
        let playerTeeTimeSlotCaddyInfoClone : PlayerTeeTimeSlot[]= _.clone(playerTeeTimeSlotCaddyInfo);
        let caddyPopupSwitch : boolean = this._SystemdefaultsInformationService.GetEditCaddyInfoOnMoveCopySwitch();
        let caddyTypeRequirement : boolean [] = validationResult.result.map(r=> 
          {
            let ruleResult: Boolean = true;
            if(r && r.ruleResults && r.ruleResults.length > 0)
              ruleResult = r.ruleResults.find(rr=>rr.ruleName == CaddyTypeRequirement ) ? r.ruleResults.find(rr=>rr.ruleName == CaddyTypeRequirement).isValid : true;
          return ruleResult;
            });
        let isCaddyAvailableFromSource = validationResult.result.filter(x => !x.isCaddyAvailable);
        if (caddyPopupSwitch && (caddyTypeRequirement.some(c=> !c) || (isCaddyAvailableFromSource && isCaddyAvailableFromSource.length > 0))) {
             playerTeeTimeSlotCaddyInfoClone =  await this.showCaddySeletionPopup(validationResult,isCaddyAvailableFromSource,playerTeeTimeSlotCaddyInfoClone,draggedPlayersCaddyInfoTemp);
        }
        else if(!caddyPopupSwitch && isCaddyAvailableFromSource && isCaddyAvailableFromSource.length > 0)
        {
          var res = this._utilities.showAlert(this._localization.captions.teetime.AllCaddiesNotAvailable, AlertType.Info, ButtonType.Ok);
          await res.afterClosed().toPromise();
        }
        return playerTeeTimeSlotCaddyInfoClone;
      }
     async showCaddySeletionPopup(validationResult,isCaddyAvailableFromSource,playerTeeTimeSlotCaddyInfo : PlayerTeeTimeSlot[],draggedPlayersCaddyInfoTemp? :PlayerTeeTimeSlot[])
     {
      let playerTeeTimeSlotCaddyInfoClone = _.clone(playerTeeTimeSlotCaddyInfo);
      let draggedPlayersCaddyInfo :  PlayerTeeTimeSlot[]= _.clone(draggedPlayersCaddyInfoTemp);
      let caddyNotAvailablePlayer : number []= validationResult.result.filter(x => !x.isCaddyAvailable || !x.ruleResults.find(rr=>rr.ruleName == CaddyTypeRequirement)?.isValid ).map(p=>p.playerTeeTimeSlot.playerId);
      let playerTeeTimeSlotCaddyNotAvailable :  PlayerTeeTimeSlot[] = _.clone(playerTeeTimeSlotCaddyInfo.filter(p=>caddyNotAvailablePlayer.includes(p.playerId)));
      await this.showCaddySelectionPopup(playerTeeTimeSlotCaddyNotAvailable,isCaddyAvailableFromSource,(res,playerInfo)=>
      {
        if(res)
        {
          playerInfo.forEach(pc=>
            {
              let index = playerTeeTimeSlotCaddyInfoClone.findIndex(p=>p.playerId == pc.playerId);
              if(index != null)
              {
                playerTeeTimeSlotCaddyInfoClone[index].caddyId = pc.caddyId;
                playerTeeTimeSlotCaddyInfoClone[index].caddyTypeId = pc.caddyTypeId;
                playerTeeTimeSlotCaddyInfoClone[index].isUpdateCaddy = true;
              }
              let playerResult = validationResult.result.find(r => r.playerTeeTimeSlot.playerId == pc.playerId);
              if(!playerResult.ruleResults.some(rr=>rr.ruleName != CaddyTypeRequirement && rr.isValid == false))
              {
                playerResult.result = true;
              }
            })
        
        }
        else{
          playerTeeTimeSlotCaddyInfoClone = [];//playerTeeTimeSlotCaddyInfoClone.filter(p=> !caddyNotAvailablePlayer.includes(p.playerId) );
        }
      },draggedPlayersCaddyInfo).afterClosed().toPromise();
      return playerTeeTimeSlotCaddyInfoClone;
     }

     mapPlayersTobeDroppedToTeeFeeGridData(validationResult : ValidationResult<CopyMoveResult[]>, players = []) : TeeFeeGridContent []
     {
       let teeFeeGridData : TeeFeeGridContent []= [];
       let rateTypesPresent = JSON.parse(sessionStorage.getItem('rateTypes'));
       if(players!= null && players.length > 0 && 
        validationResult != null && 
        validationResult.result != null && validationResult.result.length > 0)
        {
          for(let idx = 0; idx < validationResult.result.length; idx++)
          {
            let rateTypeForPlayer = '';
            
            let playerDetail = players.find(o => o.playerId == validationResult.result[idx].playerTeeTimeSlot.playerId);
            if(rateTypesPresent != null && rateTypesPresent.length > 0){
              rateTypeForPlayer = rateTypesPresent.find(o => o.id == playerDetail.rateType.id)?.type;
            }
            let teegrid : TeeFeeGridContent = {
              destinationGreenFee: this._localization.localizeCurrency(validationResult.result[idx].destinationSlotRate?.destinationGreenFee,true,2),
              destinationCartFee: this._localization.localizeCurrency(validationResult.result[idx].destinationSlotRate?.destinationCartFee,true,2),
              sourceGreenFee: this._localization.localizeCurrency(playerDetail.scheduledTeeTimePlayerFee?playerDetail.scheduledTeeTimePlayerFee.greenFee: playerDetail?.greenFee,true,2),
              sourceCartFee: this._localization.localizeCurrency(playerDetail.scheduledTeeTimePlayerFee?playerDetail.scheduledTeeTimePlayerFee.cartFee:playerDetail?.cartFee,true,2),
              playerName: playerDetail.firstName + ' ' + playerDetail.lastName,
              rateType:  rateTypeForPlayer != undefined && rateTypeForPlayer != ''? rateTypeForPlayer : '',
              playerPosition: playerDetail.playPos,
            };
            teeFeeGridData.push(teegrid);
          }
        }
        return teeFeeGridData;
    }

     getHeaderOptionsForTeeFeeAlert() : TableHeaderOptions[]
     {
       return [
                {
                  key: 'playerPosition',
                  displayName: this._localization.captions.teetime.ratechanges_playerPosition        
                },
                {
                  key: 'playerName',
                  displayName: this._localization.captions.teetime.playerName
                },
                {
                  key: 'rateType',
                  displayName: this._localization.captions.teetime.rateType
                },
                {
                    key: 'sourceGreenFee',
                    displayName: this._localization.captions.teetime.sourceGreenFee
                },
                {
                    key: 'sourceCartFee',
                    displayName: this._localization.captions.teetime.sourceCartFee        
                },
                {
                  key: 'destinationGreenFee',
                  displayName: this._localization.captions.teetime.destinationGreenFee
                },
                {
                    key: 'destinationCartFee',
                    displayName: this._localization.captions.teetime.destinationCartFee        
                }
            ];
     }
}