
import { Injectable } from '@angular/core';
import { TeeSheetBase } from '../tee-sheet/common/tee-sheet.base';
import { TeeSheetsDataService } from 'src/app/shared/data-services/golfschedule/teesheets.data.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { AllocationBlockDataService } from 'src/app/shared/data-services/golfschedule/allocationblock.data.service';
import { CourseDataService } from 'src/app/shared/data-services/golfschedule/course.data.service';
import { Observable } from 'rxjs';
import { TeeSheetSkeletonData } from 'src/app/shared/models/teesheet.form.models';
import { RateTypeDataService } from 'src/app/shared/data-services/golfschedule/ratetype.data.service';
import { Course } from 'src/app/settings/golf-setup/code-setup/course/create-course-modal/create-course-model';
import { API } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import { PlayerTypeService } from 'src/app/shared/data-services/golfschedule/playertype.data.service';
@Injectable()
export class TeeSheetSingleCourse extends TeeSheetBase {
    private selectedDate: Date;
    private selectedCourse: number;

    constructor(public _teeSheetsDataService: TeeSheetsDataService, public _localization: GolfLocalization, public _utilities: GolfUtilities, public _allocationBlockDataService: AllocationBlockDataService, public _courseDataService: CourseDataService, public _playerTypeDataService: PlayerTypeService, public _rateTypeDataService: RateTypeDataService) {
        super(_teeSheetsDataService, _localization, _utilities, _allocationBlockDataService, _courseDataService,_playerTypeDataService, _rateTypeDataService);

    }

    /**     
     * Refreshes Tee Sheets Single Course Main Grid
     * @param {number} course
     * @param {Date} date
     * @memberof TeeSheetSingleCourse
     */
    public refreshTeeSheet(): void {
        if(this.selectedDate && this.selectedCourse) {
        this.getTeeSheet(this.selectedCourse, this.selectedDate);
        }
    }

    public getTeeSheet(course: number, date: Date,isAdvancedView : boolean = false, allcourses? : Course[], allocationBlocks? : API.TeeTimeAllocationBlock[]): Observable<TeeSheetSkeletonData[]> {
        this.selectedDate = date;
        this.selectedCourse = course;
        return super.getTeeSheet(course, date,isAdvancedView, allcourses, allocationBlocks);
    }

    public reset(): void {
        super.addTeeSlots([]);
    }

    public unsubscribePending() {
        super.cancelTeeSheetPendingRequest();
    }

}
