export interface captions {
    defaultBlockingcode: string;
    defaultMemberstatus: string;
    defaultMemberPlayerType: string;
    defaultMemberratetype: string;
    defaultnonMemberplayertype: string;
    defaultnonMemberrateType: string;
    DefaultResort: string;
    DefaultResortratetype: string;
    checked: string;
    unchecked: string;
    holdconfig: string;
    enableAuto: string
}

export enum AllocationCodeSwitchToOptions {
    UnBooked = 1,
    Booked = 2,   
    Both = 3
}

export interface AllocationCodeSwitch {
    id: number;
    value: string;
    viewValue: string;
}

export interface MovePlayerOption {
    id: number;
    value: string;
    viewValue: string;
}

