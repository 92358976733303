import { Localization } from 'src/app/common/localization/localization';
import { Injectable } from '@angular/core';
import { JSONReaderService } from '../../common/shared/services/load-json.service';
/**
 * Golf Localization Functionalities 
 * @export
 * @class GolfLocalization
 * @extends {Localization}
 */
@Injectable(
  {
    providedIn: 'root'
  }
)
export class GolfLocalization extends Localization {
  constructor(public jsonReader: JSONReaderService){
    super(jsonReader);
  }
}