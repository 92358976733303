import {
  Directive,
  AfterViewInit,
  ElementRef,
  Output,
  EventEmitter,
  Input
} from '@angular/core';

@Directive({
  selector: '[appViewmore]'
})
export class ViewmoreDirective implements AfterViewInit {
  @Input() appViewmore: number;
  @Output() noOfChipsEmit: EventEmitter<number> = new EventEmitter<number>();
  buttonMargin = 8;
  constructor(private ele: ElementRef) {}

  ngAfterViewInit() {
    setTimeout(x => this.calculateElementsToPresent(), 1);
  }

  calculateElementsToPresent() {
    const numberOfData = this.calculateNumberOfData();
    this.noOfChipsEmit.emit(numberOfData);
  }

  calculateNumberOfData(): number {
    let componentContainerWidth = this.ele.nativeElement.offsetWidth;
    const moreElement: HTMLElement = this.ele.nativeElement.getElementsByClassName(
      'moreelement'
    );
    if (moreElement[0]) {
      componentContainerWidth =
        componentContainerWidth - moreElement[0].offsetWidth - this.buttonMargin;
    }

    const children = this.ele.nativeElement.children;
    let noOfButtons = 0;
    let totalWidth = 0;

    for (let i = 0; i < children.length; i++) {
      totalWidth = totalWidth + children[i].offsetWidth + this.buttonMargin;
      if (componentContainerWidth > totalWidth) {
        noOfButtons++;
      } else {
        break;
      }
    }
    return noOfButtons > 0 ? noOfButtons : 0;
  }
}
