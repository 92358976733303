<div class="common-CustomDataTable common-CustomDataTable-theme" id="table_{{SelectedSettingId}}" (window:resize)="viewCheckedFlag = false;calcShowMore();">

  <form class="w-100 h-100" [formGroup]="table">
    <div class="page-header" id="pageHeader" (window:resize)="calculateWidth()">
        <mat-form-field  *ngIf="options ? options[0].Searchable : false" class="spa-searchbox searchpt custSearchbox" [floatLabel]="floatLabelNever" id="searchInput">
            <input #tableInput matInput autocomplete="off" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" type="text" placeholder="{{PlaceHoldertext}}"
              value="" (keypress)="currentPage=1" (ngModelChange)="viewCheckedFlag=false" [disabled]="isEditModeSet">
            <i  aria-hidden="true" [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'" class="cursor" (click)="searchText='';calwidthwithtimeout();viewCheckedFlag = false"></i>
          </mat-form-field>
          <ng-content select=".right_content--section"></ng-content>
      <!--Added the following for common Pages-->
      <div *ngIf="customHeader" class="custom-common-inputs">
        <app-common-table-header [headerName]="pageTitle" [setupFormGroup]="formGroupName" [isViewOnly]="IsViewOnly"
          [maxInputLength]="retailHeaderMaxLength" [minInputLength]="retailHeaderMinLength" [setupName]="setupName" [errorText]="errorMessage" [enableToggleButton]="enableToggleButton"
          [active]="captions.setting.Active" [isValidRoleName]="isValidRoleName" (buttonClick)="onButtonClick()"
          [headerButtonName]="customHeaderButton" [isCancelButtonAvailable]="isCancelAvailable" (inputChange)="checkValid($event)"
          (validToggleState)="isValidRoleName = true;" (buttonCancelClick)="onButtonCancelClick($event)" [searchText]="searchText"
          [cancelButtonName]="captions.retailsetup.CANCEL" [searchPlaceholderValue]="PlaceHoldertext" (searchInputChange)="searchInputValue($event)" [isEditFlag]="isEditModeSet" [inlineEditFlag]="inlineEditOn"
         (validSelectState)="isValidRoleName = true;" (searchKeyPress)="currentPage=1" (resetSearchValue)="clearSearchText()" [enableTerminal]="enableTerminal" [headerOptions]="headerOptions" [terminalID]="captions.setting.TerminalID" [terminalMissing]="captions.setting.MissingTerminalID"
         [skipValidation]="skipValidationHeaderComp" [showValidationErrPopup]="showErrForValidationHeaderComp">
        </app-common-table-header>
      </div>
      <!--- end -->
    </div>
    <div class="totalRecord"  *ngIf="showTableRecord" >
      <label class="total-count-label"> {{bodyArray|totalcountPipe: searchText : hdrkeyArray}}</label>
    </div>
    <div class="fixed-table-container" id="fixed-table-container" [ngClass]="{'noSort': bodyArray.length < 1, 'custom-table-height': customHeader, 'table-height': !customHeader, 'margin-top': showTableRecord}">
      <div class="w-100 grid-table" id="SPACustomTable" >
        <ng-scrollbar [autoHide]="true">
          <table cellspacing="0" class="table mb-0" aria-describedby="common-table">
            <thead>
              <tr id="SPAHeaderRow">
                <th scope='col'  *ngIf="EditMoreOption && SelectRow" class="pr-3 table-checkbox noellipsis">
                  <div class="th-inner">
                    <mat-checkbox [disabled]="isReadOnly || ((bodyArray | filterPipe: searchText : hdrkeyArray).length <= 0)"
                      (change)="RowSelect($event,bodyArray,'All')" formControlName="IsCheckAll"></mat-checkbox>
                  </div>
                </th>
                <th scope='col'  *ngFor="let hdrCnt of hdrArray;let hi=index" id="header{{hi}}" [ngClass]="[hdrCnt.alignType=='right' ? 'text-right' : hdrCnt.alignType=='center' ? 'text-center':'',hdrCnt.sortable != undefined ? (hdrCnt.sortable ? '': 'pointerevents-none') : '']"
                  [class.cursordefault]="hdrCnt.sortable != undefined ? (hdrCnt.sortable ? false: true) : false"
                  [class.cursor]="hdrCnt.sortable != undefined ? (hdrCnt.sortable ? true: false) : true" (click)="(hdrCnt.jsonkey != '' && hdrCnt.sortable) && sortingFunc(hdrCnt.jsonkey,hi,'Frmtable', hdrCnt.sortcolumn, hdrCnt.sortcolumndatatype,hdrCnt.groupSort,hdrCnt.groupSortType,hdrCnt.groupSortJsonKey)">
                  <div class="th-inner" [ngClass]="((bodyArray | filterPipe: searchText : hdrkeyArray).length != 0 && (selectedDefaultHeader == hdrCnt.jsonkey) && (hdrCnt.sortable != undefined ? hdrCnt.sortable : (selectedDefaultHeader == hdrCnt.jsonkey && bodyArray.length > 0)) ) ? 'font-weight':''">
                    {{hdrCnt.title}}
                    <span class="pl-1" *ngIf="(hdrCnt.jsonkey != '' && hdrCnt.sortable) && ((bodyArray | filterPipe: searchText : hdrkeyArray).length != 0)">
                      <i  aria-hidden="true" id="sortArrow{{hi}}" *ngIf="hdrCnt.jsonkey != '' && hdrCnt.sortable != undefined ? hdrCnt.sortable : true && (bodyArray | filterPipe: searchText : hdrkeyArray).length != 0"
                        [ngStyle]="{'opacity':selectedDefaultHeader == hdrCnt.jsonkey && !isEditModeSet ? '1' : '0.3' }"
                        [ngClass]="selectedDefaultHeader == hdrCnt.jsonkey && orderType == 'asc' ? 'icon-filled_up_arrow deg0 IC6': selectedDefaultHeader == hdrCnt.jsonkey && orderType =='desc' ?
                        'icon-filled_up_arrow deg180 IC6': 'icon-sortArrow sort-icon'"
                        >
                      </i>
                    </span>
                  </div>
                </th>
                <th scope='col'  style="width: 25px;" *ngIf="SelectedSettingId != GridType.common"></th>
                <th scope='col'  *ngIf="!isRoleSetUpReadOnly && EnableActions" [ngClass]="{'column-sticky': sticky, 'minwidth177':DoneCancel, 'minwidth100':!DoneCancel}">
                  <div class="th-inner  " [attr.LiteralID]="'setting.Actions'" >{{captions.setting.Actions}}</div>
                </th>
              </tr>
            </thead>
            <tbody class=" h-100" flex formArrayName="tablebody">
              <tr draggable [dragEnabled]="blnDraggable" [dragData]="bodyCnt" [dragHandle]="'.draggable'" [dropEnabled]="blnDraggable" droppable (OnDragOver)="onDragOver($event,bodyCnt,bodyArray)"
              (onDrop)="onItemDrop($event,bodyCnt,bodyArray)" [ngClass]="{'highlight' :((NewerData && NewerData[0] === bodyCnt) || (SelectedData === bodyCnt) || bodyCnt['isEdit']), 'onEdit':isEdit, 'editRow':editableRow == bodyCnt.id, 'rowDisabled': (enableRowCheck | enablerow : bi : options : editEvent) }"
                id="{{bodyCnt.id}}" *ngFor="let bodyCnt of (bodyArray | filterPipe: searchText : hdrkeyArray | sortPipe : orderType : selectedDefaultHeader : overriddenSortColumn : sortColumnDataType : groupSort : groupSortType : groupSortJsonKey);let bi=index;"
                [formGroupName]="bi">
                <td *ngIf="EditMoreOption && SelectRow " class="pr-3 table-checkbox noellipsis">
                  <mat-checkbox (change)="RowSelect($event,bodyCnt,'Single')" [checked]="(SelectedData.indexOf(bodyCnt) != -1)"
                    [disabled]="isReadOnly"></mat-checkbox>
                </td>
                <td *ngFor="let hdrCnt of hdrArray;let hi=index" [ngClass]="[hdrCnt.alignType=='right' ? 'text-right ' : 'hello',hdrCnt.alignType=='center' ? 'text-center' : '']"
                  class="table-data">
                  <span class="content d-block textellipsis" id="content" [matTooltipDisabled]="bodyCnt[hdrCnt.jsonkey] ? hdrCnt.jsonkey == 'inactive' || hdrCnt.jsonkey == 'active'|| (hdrCnt.type && hdrCnt.type == 'toggle') || (hdrCnt.jsonkey == 'phoneDetails') || (bodyCnt[hdrCnt.jsonkey].toString()).length < 25 : true"
                    matTooltip="{{hdrCnt.datatype && hdrCnt.datatype == 'money' ? localization.localizeCurrency(bodyCnt[hdrCnt.jsonkey], false):bodyCnt[hdrCnt.jsonkey]}}"
                    matTooltipClass="tooltipClass">
                    <div class="" [ngClass]="IsViewOnly || isRoleSetUpReadOnly || hdrCnt.jsonkey == 'isInActive' ? 'button_invalid' : ''"
                      *ngIf="hdrCnt.jsonkey == 'inactive' || hdrCnt.jsonkey == 'active' || (hdrCnt.type && hdrCnt.type == 'toggle')">

                      <ui-switch class="LW14 switch-toggle ui-switch" [(ngModel)]="bodyCnt[hdrCnt.jsonkey]" [ngModelOptions]="{standalone: true}" [disabled]="(IsViewOnly || (isEditModeSet && edittedIndex != bi) || ((hdrCnt?.isDisabled ? hdrCnt?.isDisabled : false) )) ? true : false"  size="small" checkedLabel={{localization.captions.common.Yes}} uncheckedLabel={{localization.captions.common.No}} (change)="showInactiveRoles($event,hdrCnt.jsonkey,bodyCnt[hdrCnt.jsonkey], bodyCnt, bi)" [name]="toggleDisplayText"></ui-switch>

                    </div>
                    <!-- For quick sale items -->
                    <span class='quick-sale-select' *ngIf="hdrCnt.jsonkey == 'category' && SelectedSettingId == GridType.quickSale">
                      <mat-form-field [floatLabel]="floatLabel" class="mt-1" [ngClass]="{hidePlaceHolder: bodyCnt.category != ''}">
                        <mat-select (selectionChange)="optionChange($event, SelectedSettingId,bodyCnt,'category')" [(value)]="bodyCnt[hdrCnt.jsonkey]">
                          <mat-option [value]="option.viewValue" *ngFor="let option of dropdownOptions">
                            {{option.viewValue}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </span>
                    <span *ngIf="!(CheckInnerBoolValue | checkinnerbool : bodyCnt[hdrCnt.jsonkey])">
                      <span *ngIf="hdrCnt.datatype && hdrCnt.datatype == 'decimal' && !(IfBooleanCheck | ifboolean : bodyCnt[hdrCnt.jsonkey])">
                          {{bodyCnt[hdrCnt.jsonkey] | loaddecimalvalue}}
                        </span>
                        <span *ngIf="hdrCnt.datatype && hdrCnt.datatype == 'money' && !(IfBooleanCheck | ifboolean : bodyCnt[hdrCnt.jsonkey])">
                          {{bodyCnt[hdrCnt.jsonkey] | Currency}}
                        </span>
                        <span *ngIf="hdrCnt.datatype && hdrCnt.datatype == 'percentage' && !(IfBooleanCheck | ifboolean : bodyCnt[hdrCnt.jsonkey])">
                          {{bodyCnt[hdrCnt.jsonkey] | Currency : false}}%
                        </span>
                        <span *ngIf="hdrCnt.jsonkey == 'taxAmount'">
                          <span *ngIf="bodyCnt['taxAmountType'] == 'perc'">
                            {{bodyCnt[hdrCnt.jsonkey]}}%
                          </span>
                          <span *ngIf="bodyCnt['taxAmountType'] == 'amount'">
                            {{bodyCnt[hdrCnt.jsonkey] | Currency}}
                          </span>
                        </span>
                      <span *ngIf="SelectedSettingId != 'markdown_add'  && hdrCnt.jsonkey !== 'eventName' && !hdrCnt.datatype && !(IfBooleanCheck | ifboolean : bodyCnt[hdrCnt.jsonkey]) && hdrCnt.jsonkey != 'printQty' && hdrCnt.jsonkey != 'category' && hdrCnt.jsonkey != 'taxAmount'">
                        <span [ngClass]="{'anchor-like': hdrCnt.type == 'clickable'}" (click)="lblclick(bodyCnt, bi , hdrCnt.type, hdrCnt)">
                          {{(SetColumnGridValue | setcolumnvalue : bodyCnt : hdrCnt.jsonkey)}}
                        </span>
                      </span>
                      <span *ngIf="SelectedSettingId == 'markdown_add' && hdrCnt.datatype != 'money' &&  hdrCnt.datatype != 'percentage'">
                          <span [ngClass]="{'anchor-like': hdrCnt.type == 'clickable'}" (click)="lblclick(bodyCnt, bi , hdrCnt.type, hdrCnt)">
                              {{bodyCnt[hdrCnt.jsonkey]}}
                          </span>
                        </span>
                    </span>



                    <!-- For supplier show more and show less -->
                    <span *ngIf="hdrCnt.jsonkey == 'phoneDetails' && SelectedSettingId == GridType.retailSuppliers" class="noellipsis">
                      <!-- Ellipsis will be shown -->
                      <div *ngIf="showLess[bi]"  class="float-left left textellipsis">
                        <label *ngFor="let phone of bodyCnt[hdrCnt.jsonkey];let Index=index;">{{phone.PhoneNumber}}<span *ngIf="Index!= (bodyCnt[hdrCnt.jsonkey].length -1)">, </span></label>
                      </div>
                      <!-- Value will be in listed  -->
                      <div *ngIf="!showLess[bi]" class="float-left left pt-1">
                          <label class="pt-1 d-block" *ngFor="let phone of bodyCnt[hdrCnt.jsonkey];let Index=index;">
                              {{phone.PhoneNumber}}
                          </label>
                        </div>
                      <div  class="float-right right anchor-like showMoreClass pos-absolute " [ngStyle]="{'visibility':!showItem[bi]  ? 'hidden' : 'visible' }"   [ngClass]="showText[bi].includes('Show Less')?'bottom-0 right-0':'right-0'" (click)="showMore(bi)">
                        {{showText[bi]}}
                      </div>
                    </span>
                    <!-- For supplier need to show - if value is null -->
                    <span  *ngIf="hdrCnt.jsonkey == 'ap' && SelectedSettingId == GridType.retailSuppliers">
                        {{bodyCnt[hdrCnt.jsonkey] == "" ? '-' :bodyCnt[hdrCnt.jsonkey] }}
                    </span>
                  </span>
                  <span *ngIf="hdrCnt.jsonkey === 'eventName'">
                    {{bodyCnt[hdrCnt.jsonkey]?bodyCnt[hdrCnt.jsonkey][0]:''}}
                    <span class="plus-color" *ngIf="bodyCnt[hdrCnt.jsonkey].length>1" matTooltip="{{bodyCnt[hdrCnt.jsonkey]}}"
                      matTooltipClass="tooltipClass">
                      {{ '(' + (bodyCnt[hdrCnt.jsonkey].length - 1) +  '+' + ')'}}
                    </span>
                  </span>
                </td>
                <td style="width: 25px" *ngIf="SelectedSettingId != GridType.common"></td>
                <td *ngIf="!isRoleSetUpReadOnly && EnableActions && !(SelectedSettingId == GridType.password)"
                  [ngClass]="{'column-sticky': sticky,'minwidth177':DoneCancel}" class="noellipsis">
                    <!-- Icon Edit -->
                  <i  aria-hidden="true" class="icon-Edit cursor" [ngClass]="(IsViewOnly && IsRetailCodeSetup)||disableEditButton || isEditModeSet ? 'button_invalid' : ''"
                    *ngIf="editable && !table.value.tablebody[bi].donecancel" title="{{captions.common.Edit}}"
                    (click)="editRow($event,bodyCnt,'edit',options[0].ServiceId)"></i>

                    <!-- ---icon view--- -->
                    <a  *ngIf="enableView && !table.value.tablebody[bi].donecancel"  matTooltip="{{captions.lbl_view}}" (click)="viewRow($event,bodyCnt,'view',options[0].ServiceId)">{{captions.lbl_view}}</a>

                    <!-- Icon Delete -->
                  <i  aria-hidden="true" *ngIf="!disableDelete && !table.value.tablebody[bi].donecancel" class="icon-Delete cursor"
                    [ngClass]="IsViewOnly || isEditModeSet || (bodyCnt.relationshipId != undefined ? bodyCnt.relationshipId == -1 : false)  ? 'button_invalid' : ''" title="{{captions.common.Delete}}" (click)="DeleteRecords(bodyCnt)"></i>

                    <!-- Icon Copy -->
                    <span *ngIf="showCopy && !disableCopy && !table.value.tablebody[bi].donecancel" class="copy cursor" (click)="copy(bodyCnt, bi)" [ngClass]="{'button_invalid': disableCopy || IsViewOnly || isEditModeSet}">
                      <i aria-hidden="true"  class="icon-copy pr-1" title="{{captions.common.cCopy}}"></i></span>
                  <i  aria-hidden="true" class="icon-DragAndDrop icon-drag-drop movecursor draggable" [ngClass]="{'button_invalid': IsViewOnly || bodyArray.length == 1 || isEditModeSet}" *ngIf="blnDraggable && !table.value.tablebody[bi].donecancel" title="{{captions.common.Drag}}"></i>
                  <span *ngIf="table.value.tablebody[bi].donecancel" class="done" (click)="Done(bodyCnt, bi)">
                    <i aria-hidden="true"  class="icon-done pr-1"></i>{{captions.common.cDone}}</span>
                  <span *ngIf="table.value.tablebody[bi].donecancel" class="cancel" (click)="Cancel(bodyCnt, bi)">
                    <i  aria-hidden="true" class="icon-Cancel pr-1"></i>{{captions.common.cCancel}}</span>

                </td>
              </tr>
              <tr *ngIf="(bodyArray | filterPipe: searchText : hdrkeyArray).length == 0 && !isDataLoading">
                <td [colSpan]="SelectRow ? originalHdrKeyArray.length+3 : originalHdrKeyArray.length+2" class="text-center">
                  {{captions.common.NoDataFound}} </td>
              </tr>
              <!-- <tr *ngIf="isDataLoading">
                <td [colSpan]="SelectRow ? originalHdrKeyArray.length+3 : originalHdrKeyArray.length+2" class="text-center">{{captions.common.loadingData}}</td>
              </tr> -->
            </tbody>
          </table>
        </ng-scrollbar>
      </div>
    </div>
  </form>
</div>
