<section class="password">
  <div class="password__details">
    <label *ngIf="data.setPassword" class="golf-LatoWebBold-20">{{ this.captions.SetPassword }}</label>
    <label *ngIf="!data.setPassword" class="golf-LatoWebBold-20">{{ this.captions.ChangePassword }}</label>
    <div class="password__setup">        

        <div class="login_form">
            <form [formGroup]="setPasswordForms" autocomplete="off">
                <div class="overall_login_wrapper">
                    <div class="hide-view" *ngIf="!data.setPassword">
                        <i class="icon-password pr8 " aria-hidden="true"></i>
                        <mat-form-field class="w-80">

                            <input matInput [type]="hideOldPassword ? 'text' : 'password'" [placeholder]="captions.OldPassword" formControlName="oldpassword" [maxlength]="maxCharacter" autocomplete="new-password">
                            <i matSuffix class="IC18" [ngClass]="hideOldPassword ? 'icon-password-show' : 'icon-password-hide '" (click)="hideOldPassword=!hideOldPassword" ></i>
                            <mat-error *ngIf="setPasswordForms.get('oldpassword').hasError('required')">
                                {{this.captions.OldPasswordRequired}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="hide-view">
                        <i class="icon-password pr8 " aria-hidden="true"></i>
                        <mat-form-field class="w-80">

                            <input matInput [type]="hiddenPassword ? 'text' : 'password'" [placeholder]="captions.NewPassword" formControlName="newpassword" [maxlength]="maxCharacter" autocomplete="new-password">
                            <i matSuffix class="IC18" [ngClass]="hiddenPassword ? 'icon-password-show' : 'icon-password-hide '" (click)="hiddenPassword=!hiddenPassword" ></i>
                            <mat-error *ngIf="setPasswordForms.get('newpassword').hasError('required')">
                                {{this.captions.NewPasswordRequired}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="hide-view">
                        <i class="icon-password pr8 " aria-hidden="true"></i>
                        <mat-form-field class="w-80">
                            <input matInput [type]="hideConfirmPassword ? 'text' : 'password'"  [placeholder]="captions.ConfirmPassword" formControlName="confirmpassword" [maxlength]="maxCharacter" autocomplete="new-password">                           
                            <i matSuffix class="IC18" [ngClass]="hideConfirmPassword ? 'icon-password-show' : 'icon-password-hide '" (click)="hideConfirmPassword=!hideConfirmPassword" ></i>

                            <mat-error *ngIf="setPasswordForms.get('confirmpassword').hasError('required')">
                                {{this.captions.ConfirmPasswordRequired}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                </div>
            </form>
        </div>
    </div>
    <div class="password__done-button">
        <app-golfbutton [buttontype]="buttonValuePrimary" (valueChange)='DoneClick($event)' ></app-golfbutton>
    </div>
    <div class="password__cancel-button">
        <app-golfbutton [buttontype]="buttonValueSecondary" (valueChange)='CancelClick($event)'></app-golfbutton>
    </div>
  </div>
  <div class="password__validation">
    <label class="golf-LatoWebBold-16">{{ this.captions.PasswordRequirements }}</label>

    <div class="password__list">
      <div class="golf-LatoRegular-14 password__error-div">
        <i [ngClass]="IsLengthValid ? 'icon-confirmed' : 'icon-cancel'" class="pr5" aria-hidden="true"></i>
        <div class="password__error-msg golf-LatoRegular-12">{{ this.captions.PasswordMustContain}}{{this.minCharacter}}
          {{this.captions.characters}}
        </div>
      </div>
      <div class="golf-LatoRegular-14 password__error-div" *ngIf="this.formatingType != 1">
        <i [ngClass]="IsHavingAllTypes ? 'icon-confirmed' : 'icon-cancel'" class="pr5" aria-hidden="true" *ngIf="this.formatingType != 1"></i>
        <div class="password__error-msg golf-LatoRegular-12">{{ this.characterValidationMsg}}</div>
      </div>
      <div class="golf-LatoRegular-14 password__error-div">
        <i [ngClass]="!IsLastPassword ? 'icon-confirmed' : 'icon-cancel'" aria-hidden="true" class="pr5"></i>
        <div class="password__error-msg golf-LatoRegular-12">{{ this.captions.CannotBeSameAsTheLast}}
          {{allowReuse}}{{this.captions.PasswordsUsed}}
        </div>
      </div>
      <div class="golf-LatoRegular-14 password__error-div" *ngIf="this.formatingType != 1 && !allowUserName">
        <i [ngClass]="IsSameAsUserName ? 'icon-confirmed' : 'icon-cancel'" aria-hidden="true" class="pr5"></i>
        <div class="password__error-msg golf-LatoRegular-12">{{this.captions.SameUser}}</div>
      </div>
      <div class="golf-LatoRegular-14 password__error-div" *ngIf="!data.setPassword">
        <i [ngClass]="IsOldPassword ? 'icon-confirmed' : 'icon-cancel'" aria-hidden="true" class="pr5"></i>
        <div class="password__error-msg golf-LatoRegular-12">{{ this.captions.OldPasswordVerified}}</div>
      </div>
      <div class="golf-LatoRegular-14 password__error-div" *ngIf="!allowSpecialCharacters">
        <i [ngClass]="isSpecialChar ? 'icon-confirmed' : 'icon-cancel'" aria-hidden="true" class="pr5"></i>
        <div class="password__error-msg golf-LatoRegular-12">{{ captions.noSpecialCharacters}}</div>
      </div>
      <div class="golf-LatoRegular-14 password__error-div" *ngIf="isConfirmPassword">
        <i [ngClass]="IsConfirmed ? 'icon-confirmed' : 'icon-cancel'" aria-hidden="true" class="pr5"></i>
        <div class="password__error-msg golf-LatoRegular-12">{{ captions.confirmPasswordSame}}</div>
      </div>
    </div>
  </div>
</section>
