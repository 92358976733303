import { Injectable } from '@angular/core';
import { GolfLocalization } from '../core/localization/golf-localization';
import { ReplaySubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { CommonAlertMessagePopupComponent } from '../common/shared/shared/alert-message-popup/alert-message-popup.component';
import { RetailFeatureFlagInformationService } from '../retail/shared/service/retail.feature.flag.information.service';

@Injectable({providedIn: 'root'})
export class GuestService {
  captions: any;
  commonCaptions: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private _Localization: GolfLocalization
    , private dialog: MatDialog
    , private _featureInfo: RetailFeatureFlagInformationService
    ) {
    this.captions = this._Localization.captions.guest.players;
    this.commonCaptions = this._Localization.captions.common;
  }

  getCaptions() {
    return this._Localization.captions.subMenu;
  }

  openDialogPopup(data) {
    const isCMSRequirePin = this._featureInfo?.CMSRequirePin;
    let dialogRef = this.dialog.open(CommonAlertMessagePopupComponent, {
      width: '350px',
      maxWidth: '1000px',
      height: '272px',
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'action-dialog-overlay',
      backdropClass: 'Overlay-Alerts',
      data: { headername: this.captions.PlayerWorthDetails, closebool: true, type: 'PW',
        datarecord: { patronId: data, isCMSRequirePin: isCMSRequirePin, isPlayerPointsView: true, isPlayerCompsView: true}
       , buttonName: '' },
  });
  dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe( );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
