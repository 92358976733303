export const routeJSON = {
    "host": {
      "image": "/VisualOne/retailgateway/",
      "spaManagement": "/VisualOne/spagateway/",
      "retailManagement": "/VisualOne/retailgateway/",
      "schedule": "/VisualOne/retailgateway/",
      "retailPOS": "/VisualOne/retailgateway/",
      "authentication": "/commongateway/",
      "report": "/golfGateway/",
      "commission": "/VisualOne/retailgateway/",
      "payment": "/VisualOne/retailgateway/",
      "golfSchedule": "/golfGateway/",
      "golfManagement": "/golfGateway/Management/",
      "commonGateway": "/commongateway/",
      "Common": "/golfGateway/Golf/Services/Notification",
      "signalrService": "/commongateway/SignalRService",
      "CommonGateway": "/commongateway/"
    },
    "Login": "Property/GetLoginDetails",
    "LogOut": "User/LogOutByUserId/{Username}/{TenantId}/{PropertyId}",
    "QuickLoginByUserId": "User/Login",
    "QuickLoginByQuickId": "Login/LoginByQuickId",
    "SavePassword": "User/SavePassword/{UserId}/{NewPassword}/{TenantId}/{PropertyId}",
    "CreateSession": "User/session",
    "UpdateSession": "User/session/sessionId/{sessionId}",
    "CheckPassword": "User/CheckPasswordExists/{UserId}/{NewPassword}/{TenantId}",
    "VerifyPassword": "User/VerifyPassword/{UserId}/{NewPassword}/{TenantId}",
    "PasswordSetting": "Property/GetPasswordSettings/{TenantId}",
    "GetGolfSettings": "settings/{module}/{screen}",
    "GetAllLoc": "locations",
    "GetAllLocByUserId": "locations/useraccess",
    "GetSpecLoc": "locations",
    "CreateLoc": "locations",
    "UpdateLoc": "locations",
    "deleteLoc": "locations",
    "GetAllEquip": "equipments",
    "CreateEquip": "equipments",
    "UpdateEquip": "equipments",
    "deleteEquip": "equipments",
    "LocationDragDrop": "Locations/listorder",
    "GetAllServiceGrp": "serviceGroups",
    "GetAllServiceGrpByUserId": "serviceGroups/useraccess",
    "CreateServiceGrp": "serviceGroups",
    "UpdateServiceGrp": "serviceGroups",
    "DeleteServiceGrp": "serviceGroups",
    "ServiceGrpDragDrop": "serviceGroups/listorder",
    "GetAllMC": "medicalConditions",
    "CreateMC": "medicalConditions",
    "UpdateMC": "medicalConditions",
    "deleteMC": "medicalConditions",
    "maxListOrderLocation": "locations/nextlistorder",
    "maxListOrderGroup": "serviceGroups/nextlistorder",
    "GetAllAddOns": "addOns",
    "CreateAddOn": "addOns",
    "UpdateAddOn": "AddOnsAction/{id}",
    "DeleteAddOn": "AddOnsAction/{id}",
    "GetAllSpaService": "services",
    "GetAllSpaServiceByUserId": "services/useraccess",
    "GetServices": "services/list",
    "GetSpecificService": "services/{id}",
    "DeleteSpaService": "services/{id}",
    "CreateSpaService": "services",
    "updateSpaService": "services/{id}",
    "getAllMedicalConditions": "medicalConditions",
    "CreateNewAppointment": "appointments",
    "CreateTempHoldAppointment": "appointments/temphold/overbooktherapist/{istherapistoverbook}/overbooklocation/{islocationoverbook}",
    "UpdateAppointment": "appointments/{id}/create/{isCreateAppointment}",
    "GetAppointments": "appointments/from/{FromDate}/to/{ToDate}",
    "GetAppointmentsWithTempCreated": "appointments/temp/{id}",
    "GetAppointmentById": "appointments/{id}",
    "GetAllTherapist": "therapists/detail",
    "GetTherapistDetailById": "therapists/detail/{id}",
    "AccesibleTherapist": "therapists/useraccess",
    "CreateTherapistDetails": "therapists",
    "getActiveTherapist": "therapists/active",
    "GetSpecificTherapist": "therapists",
    "createTherapistContact": "therapists/{id}/contacts",
    "getTherapistCertification": "therapists/{id}/services/{includeServiceDetail}",
    "getMaxListOrderTherapist": "therapists/nextlistorder",
    "dragAndDropActiveTherapist": "therapists/active/listorder/from/{fromorder}/to/{toorder}",
    "dragAndDropTherapist": "therapists/listorder/from/{fromorder}/to/{toorder}",
    "DeleteTherapist": "therapists",
    "getTherapistContact": "therapists/{id}/contacts",
    "getTherapistAddOn": "therapists/{id}/addOns",
    "saveImage": "images",
    "getImagesByReference": "images/type/{referencetype}/{referenceid}",
    "getAllImagesByReference": "images/type/{referencetype}/{referenceids}/thumbnailonly/{isthumbnailonly}",
    "GetImagesByGuid": "v2/images?guid={imageReferenceId}&isThumbnailOnly={isThumbnailOnly}",
    "deleteImagesByReference": "images/type/{referencetype}/{referenceid}",
    "updateImageById": "images/{id}",
    "GetAllImagesByIds": "images/type/{referencetype}/thumbnailonly/{isthumbnailonly}",
    "AppointmentStatusData": "dashboard/appointment/statistics/date/{datetime}",
    "AppointmentDataScheduled": "dashboard/nearing/schedule/time/{datetime}",
    "AppointmentDataCheckOut": "dashboard/nearing/checkout/time/{datetime}",
    "ClientData": "dashboard/client/statistics/date/{datetime}",
    "AvailableTherapistInt": "dashboard/therapist/count/date/{datetime}",
    "updateTherapistDetails": "therapists/{id}",
    "updateTherapistContacts": "therapists/{id}/contacts",
    "updateTherapistServiceDetails": "therapists/{id}/services",
    "LinkAppointmentTherapist": "appointments/{id}/therapist",
    "LinkAppointmentClient": "appointments/{id}/client",
    "ValidateLocation": "appointments/validate/location/{id}/date/{date}",
    "getClientRecentAppointmentInfo": "appointments/client/{clientID}",
    "GetCommissions": "CommissionSetup/class/{classId}/productId/{productId}/outlet/{outletId}",
    "GetCommissionSetupByClassId": "CommissionSetup/class/{classId}/productId/{productId}",
    "UpdateCommission": "CommissionSetup/class/{classId}",
    "createNewClient": "clients",
    "GetContactTypes": "clients/contacttypes/{type}",
    "GetContactPhoneTypes": "clients/contacttypes/Phone",
    "GetClientConfiguration": "settings/module/Client",
    "clientSearch": "clients/search/{name}",
    "getClientInfo": "clients/{id}",
    "getClientInfoByGuid": "clients/guid/{guid}",
    "getClientMedicalConditions": "clients/medicalConditions/{id}",
    "getAppointmentFilters": "appointments/{id}/filter/overbooktherapist/{isTherapistOverbook}/overbooklocation/{isLocationOverbook}/timeavailable/{timeAvailable}/date/{date}/tempholdlist/{tempIds}",
    "GetAllTherapistSchedules": "therapistschedule/from/{fromDate}/to/{toDate}",
    "getTherapistSchedule": "therapistschedule/{therapistId}/from/{fromDate}/to/{toDate}",
    "createTherapistSchedule": "therapistschedule/overwrite/{isOverwiteExiting}",
    "GetLinkCodes": "linkCodes/date/{propertyDate}/expired/{showInActive}",
    "GetAllPackagesForAppointment": "packages/available/date/{dateTime}/day/{day}/maxpeople/{maxPeople}",
    "GetPackageComponents": "packages/{id}/components",
    "GetTherapistPackageAppt": "appointmentpackage/filter/service/{serviceId}/date/{date}/client/{clientId}/excludeappointment/{excludeAppointmentId}",
    "MoveCopyFilter": "appointmentpackage/filter/service/{serviceId}/date/{date}",
    "GetPackageServiceLocation": "appointmentpackage/filterlocation/service/{serviceId}/date/{dateTime}/therapist/{therapistId}",
    "CreateAppointmentPackage": "appointmentpackage/overbook/{overbook}",
    "GetAllSpaPackage": "packages/active",
    "AddPackage": "packages",
    "GetSpecificPackage": "packages/{id}",
    "DeletePackage": "packages/{id}",
    "GetPackageYield": "packages/yield/from/{fromDate}/to/{toDate}",
    "UpdatePackageYield": "packages/yield",
    "UpdatePackage": "packages/{id}",
    "GetSpaPackages": "packages",
    "CopyPackage": "packages/{id}/copy",
    "GetAllRecapAppointments": "appointments/{id}/recap/client/{clientId}/date/{date}/showall/{isShowAll}",
    "GetRecapAppointmentInfo": "appointments/{id}/recap",
    "PackageYieldCountUpdate": "packages/{id}/yield/{appointmentId}",
    "CreateTherapistBreak": "appointments/break/therapist/{therapistId}",
    "GetAppointmentsByStatus": "appointments/client/{clientId}/status/{status}/date/{date}",
    "CancelAppointment": "appointments/cancel",
    "AppointmentSearchClientFilter": "appointments/search/{searchValue}",
    "GetChangeServiceAppointments": "appointments/{id}/changeservice/filter/time/{requestDTTM}",
    "ChangeAppointmentService": "appointments/{id}/changeservice",
    "StandardPackAvailLocation": "appointmentpackage/availablelocations/service/{serviceId}/date/{dateTime}/therapist/{therapistId}",
    "UpdateTempHoldAppointment": "appointments/temphold",
    "SearchAppointments": "appointments/search/{fromDate}/{toDate}/{propertyDate}/{serviceId}/{packageId}/{staffId}/{locationId}/{Id}/{type}/{value}",
    "GetCustomFields": "customfields",
    "GetCustomSpecificField": "customfields/{id}",
    "GetCustomFieldsWithValues": "customfields/withvalues",
    "IsCustomFieldUsed": "customfields/validate/inuse/{id}",
    "DeleteTempHold": "appointments",
    "GetSpaPackageClass": "packageClass",
    "GetGuid": "appointmentpackage/guid",
    "GetAllSettingByModule": "Settings/module/{module}",
    "GetPackageAppointmentDate": "packages/{id}/appointmentrange",
    "GetTherapistBreak": "appointments/therapist/{therapistId}/break/{breakTime}",
    "ModifyTherapistBreak": "appointments/{id}/therapist/{therapistId}/break",
    "DeleteTherapistBreak": "appointments/{id}/therapist/{therapistId}/break/{breakTime}",
    "GetBreakTypes": "breakTypes",
    "UpdateCustomeField": "customfields/{id}",
    "MoveAppointment": "appointments/move",
    "CreateBreakType": "breakTypes",
    "GetAllBreakTypes": "breakTypes",
    "GetBreakType": "breakTypes/{id}",
    "DeleteBreakType": "breakTypes/{id}",
    "UpdateBreakType": "breakTypes/{id}",
    "GetValidServices": "services/from/{startDate}/to/{endDate}",
    "CreateRecurringAppointment": "appointments/{id}/recurring/pickanylocation/{anyLocation}/redeemmultipcak/{redeemMultipack}",
    "CopyAppointment": "appointments/{id}/copy",
    "GetAvailableLunchSlots": "lunch/slot/date/{date}",
    "CreateClientLunch": "appointments/lunch/client/{clientId}",
    "GetSwappableAppointments": "appointments/{id}/swap/filter",
    "SwapAppointment": "appointments/swap",
    "ReInstateAppointment": "appointments/reinstate",
    "GetAppointmentsForMultiMove": "appointments/scheduled/from/{fromDate}/to/{toDate}/therapist/{therapistId}/location/{locationId}",
    "GetAvailableTherapists": "appointments/availabletherapists/service/{serviceId}/from/{startTime}/to/{endTime}/{appointmentId}",
    "GetAvailableLocations": "appointments/availablelocations/service/{serviceId}/from/{startTime}/to/{endTime}/therapist/{therapistId}",
    "UndoCheckInAppointment": "appointments/{id}/checkin/undo",
    "ChangeAppointmentStatus": "appointments/{appointmentId}/changestatus/{toStatus}",
    "UndoCheckOutAppointment": "appointments/checkout/undo",
    "UndoCheckOutAppointmentByTransactionId": "appointments/checkout/undo/transaction/{transactionId}",
    "CheckinAppointment": "appointments/checkin/tracknoshow/{isNoShow}",
    "updateClient": "clients/{id}",
    "createClientByGuestId": "clients/guid/{guid}",
    "GetItenerary": "itinerary/{clientId}",
    "GetItineraryForAppointment": "itinerary/{clientId}/time/{datetime}",
    "GetConflictingActivities": "itinerary/{clientId}/conflict/schedule/from/{startTime}/to/{endTime}",
    "MoveMultipleAppointments": "appointments/movemultiple/therapist/{therapistId}/from/{fromDate}/to/{toDate}/deleteschedule/{isDeleteSchedule}",
    "GetAppointmentAddons": "appointments/{id}/addon/service/{serviceId}",
    "AddOrUpdateAppointmentAddons": "appointments/{id}/addon",
    "CreatePriceType": "priceTypes",
    "UpdatePriceType": "priceTypes/{id}",
    "GetAllPriceTypes": "priceTypes",
    "DeletePriceType": "priceTypes/{id}",
    "CreateLinkCode": "linkCodes",
    "UpdateLinkCode": "linkCodes/{id}",
    "GetLinkCode": "linkCodes/{id}",
    "GetAllLinkCodes": "linkCodes",
    "DeleteLinkCode": "linkCodes/{id}",
    "CreateServiceYield": "serviceYield",
    "GetMonthlyYield": "serviceYield/service/{serviceId}/month/{month}/year/{year}",
    "GetDateYield": "serviceYield/service/{serviceId}/date/{date}",
    "GetAllLocationMaintenance": "locations/maintenance",
    "GetAllEquipmentMaintenance": "equipments/maintenance",
    "DeleteLocationMaintenance": "locations/maintenance",
    "DeleteEquipmentMaintenance": "equipments/maintenance",
    "GetClients": "clients/list",
    "GetClientsByGuestIds": "clients/guids",
    "GetAppointmentConfiguration": "settings/module/Appointment",
    "ConfirmAppointment": "appointments/{id}/confirm/{isConfirm}",
    "GetAllCancelReasons": "cancelReasons",
    "CreateCancelReason": "cancelReasons",
    "UpdateCancelReason": "cancelReasons/{id}",
    "DeleteCancelReason": "cancelReasons/{id}",
    "GetActiveCancelReason": "cancelReasons/active",
    "GetAllPackageClasses": "packageClass",
    "CreatePackageClass": "packageClass",
    "UpdatePackageClass": "packageClass/{id}",
    "DeletePackageClass": "packageClass/{id}",
    "LinkCodeDragDown": "linkCodes/listorder/from/{fromorder}/to/{toorder}",
    "maxListOrderLinkCode": "linkCodes/nextlistorder",
    "getTherapistUnavailability": "therapistunavailability",
    "SaveTherapistUnavailability": "therapistunavailability/{byPassAppointments}",
    "DeleteTherapistUnavailability": "therapistunavailability",
    "UpdateTherapistUnavailability": "therapistunavailability/{id}/{byPassAppointments}",
    "CreateLunchSetup": "lunch",
    "GetAllLunch": "lunch/from/{fromdate}/to/{toDate}",
    "GetLocationConflictAppointments": "appointments/conflict/location/{locationId}",
    "GetEquipmentConflictAppointments": "appointments/conflict/equipment/{equipmentId}",
    "CreateLocationMaintenance": "locations/maintenance",
    "CreateEquipmentMaintenance": "equipments/maintenance",
    "CreateTherapistBreakList": "appointments/break/therapist/{therapistId}/overwrite/{isOverWrite}",
    "DeleteLunchSetup": "lunch/{id}/slot/{lunchTime}",
    "UpdateLunchSetup": "lunch/{id}",
    "GetLunchData": "lunch/{id}",
    "GetServicePriceBasedOnYield": "appointments/yieldprice/service/{serviceId}/date/{appointmentDate}/baseprice/{originalPrice}",
    "GetServicePriceBasedOnPriceTypeYield": "appointments/yieldprice/service/{serviceId}/date/{appointmentDate}",
    "CreatePackageYield": "packages/yield",
    "DeletePackageYield": "packages/{id}/yield",
    "GetAllAppointmentsByStatus": "appointments/date/{date}/status/{status}",
    "GetTherapistAggregate": "therapists/{id}",
    "ReinstateAllAppointment": "appointments/reinstate/all",
    "CancelAllAppointment": "appointments/cancel",
    "GetAllDepositAppointments": "appointments/{id}/deposits/client/{clientIds}/from/{fromDate}/to/{toDate}/linkcode/{linkId}",
    "GetAllClients": "clients/detail",
    "UpdateAppointmentDeposit": "appointments/deposit/transaction/{transactionId}",
    "GetUserConfiguration": "settings/module/OtherSettings",
    "CancelDepositAppointment": "appointments/cancel/deposit/transaction/{transactionId}",
    "CreateRetailTransaction": "transaction/create/{transactionType}",
    "UpdateRetailTransaction": "transaction/update/{id}",
    "SettleRetailTransaction": "transaction/settle/{transactionType}",
    "ReOpenRetailTransaction": "transaction/reopen/{transactionType}",
    "CorrectRetailTransaction": "transaction/correct/voidedTransactionId/{voidedTransactionId}",
    "Outlet": "outlet",
    "ActiveOutlet": "outlet/active",
    "ActiveOutlets": "outlets/active",
    "OutletWithId": "outlet/{id}",
    "GetDefaultOutlet": "Outlets/GetDefaultOutlet",
    "GetShopItems": "retailitems",
    "CreateRetailItem": "retailItems",
    "GetShopItem": "retailitemsAggregate/{itemId}",
    "UpdateRetailItem": "retailItems/{id}",
    "ImportRetailItem": "retailItems/ImportRetailItem",
    "SyncRetailItems": "retailitems/sync/outlets/{outletId}",
    "SyncInventory": "retailitems/syncinventory/outlets/{outletId}",
    "PosFullSync": "POSSync/SyncOutletItems/{outletId}",
    "GetOutletShopItems": "retailitems/outlet/{outletId}/{isInActive}",
    "GetRetailItemsByOutletId": "retailitems/outletId/{outletId}",
    "GetAllShopItems": "retailitems/detail",
    "GetActiveRetailItems": "retailitems/active",
    "GetRetailItemByItemType": "retailitems/type/{type}",
    "GetCommissionItems": "retailItems/commissionable/outlet/{outletId}",
    "GetAllCommissionItemsByOutlet": "retailItems/commissionable",
    "GenerateItemNumber": "retailItems/generateItemNumber",
    "GetShopItemByName": "retailitems/name/{itemName}",
    "GlobalSearchRetailItems": "retailitems/search/name",
    "GetItemByItemNumber": "RetailItems/itemNumber/{itemnumber}",
    "UpdateRetailItemStatus": "RetailItems/{id}/{status}",
    "QuickSaleCategoryAndItems": "quicksale",
    "QuickSaleCategory": "quicksale/category",
    "AllQuickSale": "quicksale",
    "AddQuickSaleItem": "quicksale/item/{outletId}",
    "QuickSaleActiveCategory": "quicksale/category/active",
    "QuickSaleCategoryWithId": "quicksale/category/{id}",
    "UpdateQuickSaleCategoryListOrder": "quicksale/category/listorder/from/{fromorder}/to/{toorder}",
    "CategoryItemAndListOrderChange": "QuickSale/items/Categoryandlistorder",
    "GetAllTransactions": "transaction/{status}/{outletId}/{transactionDate}",
    "getCommissionableTherapist": "GetCommissionableStaffs/item/{itemId}",
    "GetCommissionableStaffs": "GetCommissionableStaffs/item/{itemId}/productId/{productId}",
    "SyncUpOpenTransactions": "transaction/SyncUpOpenTransactions/outletId/{outletId}/productId/{productId}",
    "GetCommissionbyType": "CommissionSetup/type/{referenceType}/id/{referenceId}/productId/{productId}/outlet/{outletId}",
    "getTherapists": "therapists/list",
    "RemoveCommission": "CommissionSetup",
    "GetGuestSalesHistoryTransaction": "transaction/guest/{id}",
    "GetAllCommissionByItem": "CommissionSetup/items/{itemId}/productId/{productId}/outlet/{outletId}",
    "GetCommissionByItem": "CommissionSetup/items/{itemId}/productId/{productId}",
    "CommissionTemplate": "commissiontemplates",
    "CommissionTemplateById": "commissiontemplates/{id}",
    "GetCommissionTemplateById": "commissiontemplates/{id}/productId/{productId}",
    "GetCommissionTemplate": "commissiontemplates/productId/{productId}",
    "DeleteCommissionTemplateById": "commissiontemplates/{id}/productId/{productId}",
    "GetTransactionDetails": "transaction/GetTransactionDetails/{transactionId}/productId/{productId}",
    "GetTransactionsForDay": "transaction/outlet/{outletId}/status/{status}/date/{transactionDate}/IncludeVoid/{IncludeVoid}",
    "GetTransactionByRange": "transaction/outlet/{outletId}/status/{status}/range/{startDate}/{endDate}",
    "SearchTransaction": "transaction/search",
    "GetMultiAppointment": "appointments/{id}/multiclient",
    "GetMultiPacks": "multipack/getMultiPacks",
    "GetAllMultiPacks": "multipack/getAllMultiPacks",
    "GetAciveMultipackforClient": "multipack/query/active/list",
    "LinkMultiPack": "appointments/{id}/link/multipack/{multipackId}/price/{multipackPrice}",
    "LinkMultiPacks": "appointments/link/multipack/list",
    "UnlinkMultiPack": "appointments/unlink/multipack",
    "UnlinkMultiPacks": "appointments/unlink/multipack/list",
    "RedeemMultiPack": "multipack/redeemMultiPack",
    "ReinstateMultiPack": "multipack/reinstateMultiPackSession",
    "GetMultiPack": "multipack/getMultiPack/{id}",
    "ReturnWithoutTicket": "transaction/return/withoutticket/{transactionType}",
    "RetunWithticket": "transaction/return/withticket/{transactionId}/productId/{productId}",
    "VoidTransaction": "transaction/void",
    "GetConfiguration": "settings/module/{module}/switch/{Switch}",
    "GetSeasonalSetting": "settings/seasonalhour",
    "UpdateSeasonalSetting": "settings/seasonalhour",
    "GetAllSetting": "settings/AllSetting/All",
    "GetAllBreakPoint": "user/GetAllUserClaimsAsync",
    "GetBreakPoint": "user/GetUserClaimsAsync/",
    "GetAllUserRole": "userRole/GetUserRoleByTenantId/{tenantId}",
    "GetUserRoleById": "UserRole/GetUserCountsById/{id}",
    "GetActiveUserRole": "UserRole/GetActiveUserRoleByTenantId/{tenantId}/{includeInActive}",
    "GetActiveUserRolesByPropertyId": "UserRole/GetActiveUserRolesByPropertyId/{propertyId}/{includeInActive}",
    "GetUserRoleByPropertyId": "UserRole/GetUserRoleByPropertyId/{propertyId}",
    "UpdateUserRole": "UserRole/UpdateUserRole",
    "CreateUserRole": "UserRole/CreateUserRole",
    "DeleteUserRole": "userRole/DeleteUserRole",
    "CheckUserRoleExist": "userRole/CheckUserRoleExist/{tenantId}/{roleName}",
    "CheckDupicateUserRoleExist": "UserRole/CheckDupicateUserRoleExist/{propertyId}/{roleCode}/{roleName}",
    "GetUserCountsByRoleId": "userRole/GetUserCountsByRoleId/{RoleId} ",
    "GetUserRoleConfiguration": "userRole/GetUserClaimsByRoleIdAsync/{userRoleId}",
    "GetProducts": "Product/GetAllProducts",
    "GetStandAloneProducts": "Product/GetAllStandAloneProducts",
    "GetProductsByPropertyId": "Product/GetProductsByPropertyId/{propertyId}",
    "GetOutlets": "Outlets/property/{propertyId}",
    "GetAllUsers": "User/GetAllUserbyTenantId/{tenantId}",
    "GetAllUsersByPropertyId": "User/GetAllUserbyPropertyId/{propertyId}/{productId}",
    "GetAllUsersbyUserIds": "User/GetAllUserbyUserIds/{tenantId}",
    "GetPropLanguages": "Property/GetAllLanguageByPropertyId/{propertyId}",
    "GetUserRetailConfiguration": "UserRetailConfiguration/{id}",
    "GetAllUserRetailConfiguration": "UserRetailConfiguration/all",
    "GetUserSpaConfiguration": "spausers/{id}/servicegroup",
    "CreateUser": "user/CreateUser/{PropertyId}",
    "UpdateUser": "user/UpdateUser/{PropertyId}",
    "CreateUserRetailConfig": "UserRetailConfiguration/create",
    "CreateUserSpaConfig": "spausers/{id}/servicegroup",
    "UpdateUserRetailConfig": "UserRetailConfiguration/update",
    "UpdateUserSpaConfig": "spausers/{id}/servicegroup",
    "UpdateSetting": "settings",
    "IsLocationInUse": "appointments/validate/inuse/service/{serviceId}/location/{locationId}",
    "IsServiceInUse": "appointments/validate/inuse/Service/{serviceId}/from/{startDate}/to/{endDate}/dayofWeek/{dayOfWeek}",
    "CopyUserRoles": "user/CopyUserClaimsAsync/{from}/{to}",
    "UpdateUserRoles": "user/UpdateUserClaimsAsync",
    "GetPropertyInfoByPropertyId": "Property/GetPropertyInfoByPropertyId/{Id}",
    "UpdatePropertyInfoByPropertyId": "Property/UpdatePropertyInfo",
    "CheckTherapistAssociatedInAppointment": "appointments/Validate/resource/Therapist/{id}/action/Update",
    "GetTransactionStatus": "transaction/appointment/{id}",
    "GetAppointmentWithDeposit": "appointments/status/{status}/date/{appointmentDate}",
    "GetAppointmentWithoutTransaction": "appointments/notransaction/checkedout/date/{appointmentDate}",
    "PerformDayEnd": "dayend/process/date/{currentDate}/{isAllowFutureDate}",
    "GetManagementData": "Rules/ManagementData",
    "CheckOutAppointment": "appointments/checkout",
    "GetReport": "report",
    "GetOutletsByProperty": "Outlets",
    "TransferInventoryItem": "Inventory/transfer",
    "AddOutletsToProperty": "Outlets",
    "UpdateOutletsOfProperty": "Outlets/{id}",
    "RemoveOutletsOfProperty": "Outlets/{id}",
    "GetPackageDetailbyId": "packages/{id}/detail",
    "LockAppointment": "appointments/{id}/lock/{sessionId}",
    "ReleaseAppointmentLock": "appointments/lock/{sessionId}/remove",
    "GetAppointmentLock": "appointments/{id}/enablelock/{lockAppointment}/{sessionId}",
    "ValidateRule": "appointments/validate/rule",
    "GlobalSearchClients": "clients/searchByKey",
    "GlobalSearchTherapist": "therapists/search/name",
    "GlobalSearchAppointments": "services/search/appointments",
    "SearchClientInformation": "Clients/search/{isVip}/{clientGuid}",
    "RecentClientInformation": "Clients/recent/{propertyDate}/search",
    "IsTherapistServiceInUse": "appointments/validate/inuse/therapist/{therapistId}/Service/{serviceId}",
    "IsTherapistAddonInUse": "appointments/validate/inuse/therapist/{therapistId}/addon/{addonId}",
    "PackageMoveValidations": "AppointmentPackage/packagemove/validation/{id}/packagegroupid/{guid}",
    "IsPackageYieldAvailable": "packages/{packageId}/yieldcheck/date/{date}",
    "UpdatePackageYieldOnMove": "packages/{packageId}/updateyield/appointment/{packageGroupId}/from/{fromDate}/to/{toDate}",
    "GlobalSearchAppointmentsIds": "services/search/appointmentId",
    "WaitList": "appointmentwaitlists",
    "WaitListFilter": "appointmentwaitlists/filter/date/{date}",
    "WaitListWithId": "appointmentwaitlists/{id}",
    "GetAllWaitListByDate": "appointmentwaitlists/from/{fromdate}/to/{todate}",
    "GetServicePriceTypes": "Services/ServicePriceType/{serviceid}",
    "AppointmentTherapistServiceLimitation": "appointments/validatetherapistservicelimit/therapistid/{therapistid}/serviceid/{serviceid}/servicecount/{servicecount}",
    "GetMedicalCondition": "services/{id}/getmedicalcondition",
    "GetMedicalWarning": "services/{id}/getmedicalwarning",
    "ConfirmWaitList": "appointmentwaitlists/{id}/confirm",
    "GetClientByIds": "Clients/query",
    "CreateCategoryGroup": "CategoryGroups",
    "DeleteCategoryGroup": "CategoryGroups/{id}",
    "GetAllActiveCategoryGroups": "CategoryGroups/active",
    "GetAllCategoryGroups": "CategoryGroups",
    "UpdateCategoryGroup": "CategoryGroups/{id}",
    "CategoryGroupDragDrop": "CategoryGroups/listorder",
    "GetAllCategories": "Categories",
    "GetActiveCategories": "Categories/active",
    "CreateCategory": "Categories",
    "CreateCatSubCatAndLinkAndMeasure": "Categories/AddCatSubCatAndLinkAndMeasure",
    "UpdateCategory": "Categories/{id}",
    "DeleteCategory": "Categories/{id}",
    "CategoryDragDrop": "Categories/listorder",
    "GetAllSubCategories": "SubCategories",
    "CreateSubCategory": "SubCategories",
    "EditSubCategory": "SubCategories/{id}",
    "DeleteSubCategory": "SubCategories/{id}/{levelId}?forceDelete={forceDelete}",
    "SubCategoryDragDrop": "SubCategories/listorder",
    "LinkCategoryAndSubCategory": "SubCategories/{level}/{parentId}",
    "GetCategoryAndSubCategoryLink": "SubCategories/categorySubCategoryLink",
    "GetActiveUnitOfMeasures": "UnitsOfMeasure/active",
    "GetUnitOfMeasures": "UnitsOfMeasure",
    "CreateUnitOfMeasure": "UnitsOfMeasure",
    "UpdateUnitOfMeasure": "UnitsOfMeasure/{id}",
    "DeleteUnitOfMeasure": "UnitsOfMeasure/{id}",
    "UnitOfMeasureDragDrop": "UnitsOfMeasure/listorder/from/{fromOrder}/to/{toOrder}",
    "GetMachineNames": "MachineName",
    "GetActiveMachineNames": "MachineName/active",
    "CreateMachineName": "MachineName",
    "UpdateMachineName": "MachineName/{id}",
    "DeleteMachineName": "MachineName/{id}",
    "MachineNameDragDrop": "MachineName/listorder/from/{fromOrder}/to/{toOrder}",
    "GetMachineNamesByPropertyId": "MachineName/property/{propertyId}",
    "GetDiscountTypes": "DiscountTypes",
    "GetActiveDiscountTypes": "DiscountTypes/active",
    "CreateDiscountType": "DiscountTypes",
    "UpdateDiscountType": "DiscountTypes/{id}",
    "DeleteDiscountType": "DiscountTypes/{id}",
    "DiscountTypeDragDrop": "DiscountTypes/listorder/from/{fromOrder}/to/{toOrder}",
    "GetDiscountConfiguration": "DiscountConfigurations",
    "UpdateDiscountConfiguration": "DiscountConfigurations/{id}",
    "ResetDiscountConfiguration": "DiscountConfigurations/{id}",
    "ApplyAndUpdateDiscountConfiguration": "DiscountConfigurations",
    "GetPaymentConfiguration": "GetPaymentConfiguration",
    "PaymentMethod": "GetPaymentMethodsByProductId",
    "AddPaymentMethod": "paymentmethods",
    "PaymentMethodWithId": "paymentmethods/{id}",
    "UpdatePaymentMethodListOrder": "paymentmethods/listorder/from/{fromorder}/to/{toorder}",
    "getAllCCTerminals": "CreditCardTerminals",
    "createCCTerminal": "CreditCardTerminals",
    "updateCCTerminal": "CreditCardTerminals/{id}",
    "deleteCCTerminal": "CreditCardTerminals/{id}",
    "UpdateCCTerminalListOrder": "CreditCardTerminals/listorder/from/{fromorder}/to/{toorder}",
    "getAllOutletTerminals": "OutletTerminal",
    "createOutletTerminal": "OutletTerminal",
    "updateOutletTerminal": "OutletTerminal/{id}",
    "deleteOutletTerminal": "OutletTerminal/{id}",
    "GetOutletItemWithStatus": "Inventory/outlet/{outletId}/items",
    "StartNewInventoryCount": "Inventory/{outletId}/counts",
    "UpdateInventoryCountType": "Inventory/counts/{countId}/type",
    "UpdateInventoryCount": "Inventory/counts/{countId}",
    "UpdateInventoryStatus": "Inventory/{outletId}/status",
    "GetTaxes": "TaxConfiguration/grid",
    "CreateTax": "TaxConfiguration",
    "UpdateTax": "TaxConfiguration/{id}",
    "DeleteTax": "TaxConfiguration/{id}",
    "OutletGet": "GetOutlets/{propertyId}",
    "OutletCreate": "CreateOutlet",
    "OutletDelete": "DeleteOutlet/{propertyId}/{subPropertyId}/{outletTerminalLinkId}",
    "OutletUpdate": "UpdateOutlets/{outletTerminalLinkId}",
    "GetActiveTaxes": "TaxConfiguration/active",
    "CreateTicket": "transaction/create",
    "ModifyServiceCharge": "transaction/modifyServiceCharge/outletId/{outletId}/terminalId/{terminalId}",
    "ModifyGratuity": "transaction/modifyGratuity/outletId/{outletId}/terminalId/{terminalId}",
    "ModifyServiceChargeAndGratuity": "transaction/modifyServiceChargeAndGratuity/outletId/{outletId}/terminalId/{terminalId}",
    "ModifyItemQuantity": "transaction/modifyItemQuantity",
    "CancelTicket": "transaction/cancelTicket/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/terminalId/{terminalId}",
    "RemoveItems": "transaction/removeItems/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/terminalId/{terminalId}",
    "getUserOutlet": "outlet/user/{userid}",
    "TransactionDetailWithPostedCommission": "transaction/{transactionId}/commissions",
    "GetCCConfiguration": "GatewayConfiguration/{type}",
    "UpdateCCConfiguration": "GatewayConfiguration",
    "GetGCConfiguration": "GiftCardConfiguration",
    "UpdateGCConfiguration": "GiftCardConfiguration",
    "getAllPropertySettings": "Property/GetPropertySetting/{propertyId}",
    "updatePropertySetting": "Property/PatchUpdatePropertySetting/{PropertyId}",
    "getReceiptInfo": "ReceiptConfiguration",
    "createReceipt": "ReceiptConfiguration",
    "GetReceiptConfigurationByOutletId": "ReceiptConfiguration/{id}",
    "GetAllRetailItemAndOutletInfo": "retailItems/AllRetailItemInfo",
    "GetRetailItemDetails": "retailItems/query",
    "getCommissionForItem": "commissions/getCommissionForItem/item/{itemId}/staff/{staffId}/{staffType}/{commissionClass}/product/{productId}/outlet/{outletId}",
    "AddOrModifyPostedCommission": "commissions/modifypostedcommission/{isCreate}",
    "GetSubPropertyAccessByUser": "UserOutlets/user/{userId}",
    "GetOutletsAccessByPropertyId": "UserOutlets/property",
    "CreateUserOutletAccess": "UserOutlets",
    "UpdateUserOutletAccess": "UserOutlets",
    "LinkTransactionId": "transaction/link/transactionId/{transactionId}/transactionLinkId/{transactionLinkId}",
    "LinkOutletsWithRetailItem": "LinkOutlet",
    "getAllMultiPacks": "MultiPack/GetAllMultiPacks",
    "ApplyDiscount": "transaction/applyDiscount/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/terminalId/{terminalId}",
    "UpdateCategoryLinking": "retailitems/UpdateCategoryLinking",
    "GetTicket": "transaction/getTicket/{ticketId}/outletId/{outletId}/terminalId/{terminalId}",
    "GetHandles": "GetHandles",
    "CreateToken": "CreateToken",
    "StoreToken": "Payment/StoreToken",
    "GetCardInfoByTransaction": "Payment/GetCardInfoByTransaction/{tokenTransId}",
    "Sale": "sale",
    "SaleByToken": "Payment/SaleByToken/outletId/{outletId}",
    "CreditByToken": "Payment/CreditByToken/outletId/{outletId}",
    "ValidateSale": "Payment/ValidateSale/outletId/{outletId}",
    "ValidatePay": "Payment/ValidatePay/outletId/{outletId}",
    "TryPay": "transaction/trypay/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/terminalId/{terminalId}",
    "TryPayForRefund": "transaction/trypayForRefund/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/terminalId/{terminalId}",
    "CloseTransaction": "transaction/close/{transactionId}/transactionDate/{transactionDate}/outletId/{outletId}/productId/{productId}",
    "DeleteTransaction": "transaction/delete/{transactionId}",
    "GetTransactionCount": "transaction/outlets/{outletId}/count",
    "UpdateInventoryAuditOnDayEnd": "Inv/property/{propertyId}/dayend/audit",
    "TenderRetainedData": "transaction/{transactionId}/tenderRetainedData/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/terminalId/{terminalId}",
    "OldRefund": "transaction/{transactionId}/refund",
    "PaymentHistory": "transaction/{transactionId}/payment/history?getAll={getAll}",
    "PaymentHistoryDetails": "PointOfSaleService/GetPaymentHistoryDetails/{transactionId}/payment/history",
    "GetUserSessionConfiguration": "UserSessionConfiguration/user/{userId}",
    "CreateUserSessionConfiguration": "UserSessionConfiguration/create",
    "UpdateUserSessionConfiguration": "UserSessionConfiguration/update",
    "SaveTicket": "transaction/save/{ticketNumber}/checkHandle/{checkHandleGuid}/outletId/{outletId}/terminalId/{terminalId}",
    "GetAllRoomsWithGuestID": "Itinerary/clients/{clientId}/stay",
    "AddTicketTax": "transaction/AddTicketTax/ticket/{ticketNumber}/checkHandle/{checkHandleGuid}/outletId/{outletId}/terminalId/{terminalId}",
    "GetPaymentConfigurationByProperty": "Payment/GetPaymentConfigurationByProperty/{propertyId}",
    "SyncItemAndTax": "Inventory/Syncup/outlets/{outletId}/type/{type}/operation/{operation}/id/{id}",
    "CloseReturnTransaction": "transaction/closeReturnTransaction/{transactionId}/transactionDate/{transactionDate}/productId/{productId}",
    "GetTransactionInfoById": "transaction/transactionInfoById/{transactionId}",
    "GetTransactionDetailsForReceipt": "transaction/GetTransactionDetailsForReceipt/{transactionId}",
    "Pay": "Transaction/pay/outletId/{outletId}/terminalId/{terminalId}",
    "Refund": "transaction/{transactionId}/refund/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/tenderId/{tenderId}/paymentReferenceId/{paymentReferenceId}/terminalId/{terminalId}",
    "GetLinkedTransactions": "transaction/{transactionId}/outlet/{outletId}/linkedTransactions",
    "CheckVoidForMultipack": "transaction/checkVoidForMultiPack/transactionId/{transactionId}",
    "IsTherapistServicesInUse": "appointments/validate/inuse/therapist/{therapistId}",
    "GetFinancialBins": "transaction/getFinancialBins/parentTransactionId/{parentTransactionId}",
    "SyncUpTransactions": "transaction/SyncTransactions/outletId/{outletId}/productId/{productId}",
    "CancelTransaction": "transaction/cancel/{transactionId}",
    "CheckTicketStatus": "transaction/{transactionId}/CheckTicketStatus",
    "UpdateTransactionsWithPlayerStatus": "TeeTimes/UpdateTransactionsWithPlayerStatus/{payeeId}",
    "UpdatePlayerCustomFees":"TeeTimes/UpdatePlayerCustomFees",
    "NoShowTeeTimePlayerForMultiple": 'TeeTimes/schedules/players/noshow',
    "BulkCancelTeeTimePlayers":"TeeTimes/players/BulkCancel",
    "MapTransactionDetailWithPlayer": "TeeTimes/MapTransactionDetailWithPlayer",
    "GetCommissionableUsers": "users/commissionable/item/{itemId}/productId/{productId}/outlet/{outletId}",
    "CreateTransactionLock": "transaction/lock/{transactionId}/overrideExistingLock/{overrideExisting}",
    "GetTransactionLock": "transaction/lock/{transactionId}",
    "RemoveTransactionLock": "transaction/lock/{lockId}",
    "OutletTransLog": "TransLog",
    "GetVatConfiguration": "Property/GetVatConfiguration/{propertyId}",
    "UpdateVatConfiguration": "Property/UpdateVatConfiguration",
    "AddAndRemoveTicketItems": "Transaction/addAndRemoveItems/outletId/{outletId}/terminalId/{terminalId}",
    "GetTicketDetail": "transaction/getTicketDetail/outlet/{outletId}/terminalId/{terminalId}",
    "GetStoreTerminal": "Outlets/GetStoreTerminal/{outletId}",
    "SearchMembers": "Membership/SearchGuests/{pattern}/{searchType}?isSearchNameOnly={isSearchNameOnly}",
    "GetMemberInfoByCardNo": "Membership/GetMemberByCardNo/{cardNo}/{scheduleDateTime}",
    "GetAllPaymentRecords": "Membership/GetAllPaymentRecords",
    "GetPaymentRecordsbyTransactionId": "Membership/GetPaymentRecord/{TransactionId}",
    "CreateACESPaymentRecord": "Membership/CreatePaymentRecord",
    "ARPost": "Membership/ARPost",
    "MemberPayment": "Membership/MemberPayment",
    "RedeemPoint": "Membership/RedeemPoint",
    "MemberSummary": "Membership/CreateACESPaymentSummary",
    "ACESPaymentRecords": "Membership/GetACESPaymentsRecord",
    "GetMember": "transaction/GetPaymentById/{transactionId}",
    "GetOutletsByPropertyAndProduct": "Outlets/GetOutlets/property/{propertyId}/product/{productId}",
    "UpdateGuestRequest": "Players/UpdateGuestRequest/{guestId}/{playerId}",
    "GetPropertyFeatures": "PropertyFeature/GetPropertyFeatures/property/{propertyId}/product/{productId}",
    "UpdatePropertyFeature": "PropertyFeature/UpdatePropertyFeature/Id/{id}/updatedvalue/{value}",
    "GetPropertyFeatureConfiguration": "FeaturesConfiguration/GetFeaturesConfigurationsForDefaultValues/FeaturesConfigurationWithDefaultValues/{PropertyFeatureId}",
    "GetRetailPropertyFeatureConfiguration": "RetailFeatureConfiguration/GetFeaturesConfigurationsForDefaultValues/{PropertyFeatureId}",
    "SaveFeatureConfiguration": "FeaturesConfiguration/SaveFeaturesConfigurations",
    "SaveRetailFeatureConfiguration": "RetailFeatureConfiguration/SaveFeaturesConfigurations",
    "GetOutletTerminals": "Outlets/GetStoreTerminals",
    "CreateOutletTerminal": "Outlets/CreateStoreTerminal",
    "UpdateOutletTerminal": "Outlets/UpdateStoreTerminal",
    "DeleteOutletTerminal": "Outlets/DeleteStoreTerminal/{id}",
    "GetTransactionPayment": "Transaction/getTransactionPayment/{transactionId}",
    "GetTransactionPaymentWithAdditionalDetails": "Transaction/getTransactionPaymentWithAdditionalDetails/{transactionId}",
    "UpdateSuccessfulPayment": "Transaction/{transactionId}/UpdateSuccessfulPayment",
    "VoidCMSPayment": "Transaction/{transactionId}/VoidCMSPayments/patronId/{patronId}/redemptionType/{redemptionType}/paymentMethodType/{paymentMethodType}",
    "SendManualNotification": "EmailConfiguration/{CanSendEmail}/{CanSendSMS}/{bSaveOrUpdateContact}",
    "CheckIsTerminalInUse": "Transaction/IsOpenTransactionExistForTerminal/Outlet/{outletId}/Terminal/{terminalId}",
    "GetFeatureConfiguration": "GetFeatureConfiguration/propertyId/{propertyId}/productId/{productId}",
    "GetFeatureConfigurationById": "GetFeatureConfigurationById/propertyId/{propertyId}/productId/{productId}",
    "GetRetailFeatureConfiguration": "GetRetailFeatureConfiguration/propertyId/{propertyId}/productId/{productId}",
    "GetClientByPatronId": "Clients/getClientByPatronId/{patronId}",
    "GetPatronInformation": "Payment/GetPlayerInformation",
    "RedeemVoucher": "Payment/RedeemVoucher/{patronId}",
    "GetAccountInformation": "Payment/GetAccountInformation",
    "RedeemPoints": "Payment/RedeemPoints",
    "GetTopicsByType": "Notification/GetTopicsByNotificationType/{NotificationTypeId}",
    "GetKeywordsByTopicId": "Notification/GetKeywordsByTopicId/{TopicId}",
    "GetTemplatesByTopic": "Notification/topic/{topic}/templatetype/{templatetype}",
    "CreateorUpdateTemplate": "Notification/CreateorUpdateTemplate",
    "GetTemplateDetailsByTopicId": "Notification/GetTemplateDetailsByTopicId/{TopicId}",
    "UpdateDefaultTemplate": "Notification/UpdateDefaultTemplate",
    "GetGiftcardItemsForLoad": "Transaction/getGiftCardTransactionItemsForLoad/{transactionId}",
    "GetGiftcardItemsForIssue": "Transaction/getGiftCardTransactionItemsForIssue/{transactionId}",
    "UpdateGiftCardLoadSuccess": "Transaction/updateGiftCardLoadSuccess/giftcardTransactionItemId/{giftcardTransactionItemId}/paymentReferenceId/{paymentReferenceId}",
    "ValidateGiftCard": "Payment/ValidateGiftCard/outletId/{outletId}",
    "GetTransactions": "Transaction/GetTransactionsByIds",
    "GetGiftCardPaymentRecords": "GiftCardPaymentRecord/cardnumber/{giftcardNumber}",
    "GetPaymentTicketDetail": "Transaction/getpaymentTicketdetail",
    "GetLatestPaymentRecordDetailByCardNumber": "GiftCardPaymentRecord/{cardNumber}/tenderId/{tenderId}",
    "GetRetailItemById": "RetailItems/{itemId}",
    "GetRetailItemDetailsByIds": "RetailItems/query",
    "GetGiftCardItemsForUnLoad": "Transaction/getGiftCardTransactionItemsForUnLoad/{transactionId}",
    "UpdateGiftCardUnLoadSuccess": "Transaction/updateGiftCardUnLoadSuccess/giftcardTransactionItemId/{giftcardTransactionItemId}/unloadPaymentReferenceId/{paymentReferenceId}",
    "UpdateGiftCardIssueSuccess": "Transaction/updateGiftCardIssueSuccess/giftcardTransactionItemId/{giftcardTransactionItemId}/paymentReferenceId/{paymentReferenceId}",
    "GetGiftcardItems": "Transaction/getGiftCardTransactionItems/{transactionId}",
    "ValidateGiftCardCashback": "Payment/ValidateGiftCardCashback/outletId/{outletId}",
    "GetClosedTransactionByDate": "Transaction/closeddate/{closedDate}",
    "GetARPostingConfiguration": "Payment/ARPostingConfiguration",
    "ARAccountLookup": "AccountsReceivable/Lookup/ByName/{byname}",
    "ARPaymentPost": "AccountsReceivable/PostPayment",
    "ARPaymentCredit": "AccountsReceivable/CreditPayment",
    "GetVendors": "Vendor",
    "GetVendorInfo": "RetailManagementService/Vendor/GetVendorDetails",
    "CreateVendor": "Vendor",
    "UpdateVendor": "Vendor",
    "DeleteVendor": "Vendor/{id}",
    "SearchVendors": "Vendor/search/{pattern}",
    "GetVendorCode": "Vendor/{id}",
    "GetVendorTypes": "VendorType",
    "CreateVendorType": "VendorType",
    "UpdateVendorType": "VendorType",
    "DeleteVendorType": "VendorType/{id}",
    "GetPropertyConfiguration": "PropertyConfiguration/configurationName/{configName}/propertyId/{propertyId}/productId/{productId}",
    "GetTenantConfiguration": "TenantConfiguration/configurationName/{configurationName}",
    "GetItemByPagination": "RetailItems/items?outletId={outletId}&includeInactive={includeInactive}&pageStart={pageStart}&pageLength={pageLength}&sortBy={sortBy}&isSortByAscending={isSortByAscending}&searchText={searchText}&category={category}&subcategory1={subcategory1}&subcategory2={subcategory2}&subcategory3={subcategory3}&subcategory4={subcategory4}&subcategory5={subcategory5}&vendorId={vendorId}",
    "GetShopItemByPagination": "RetailItems/shop/items?outletId={outletId}&includeInactive={includeInactive}&pageStart={pageStart}&pageLength={pageLength}&sortBy={sortBy}&isSortByAscending={isSortByAscending}&searchText={searchText}&searchByBarcode={searchByBarcode}&quickSaleCategory={quickSaleCategory}&itemId={itemId}&itemType={itemType}",
    "RechargeV1Giftcard": "Payment/RechargeV1GiftCard",
    "ReverseRechargeV1GiftCard": "Payment/ReverseRechargeV1GiftCard/transactionId/{paymentTransactionId}",
    "IssueV1GiftCard": "Payment/IssueV1GiftCard",
    "ReturnV1GiftCard": "Payment/ReturnV1GiftCard",
    "RedeemV1GiftCard": "Payment/RedeemV1GiftCard",
    "GetCEDSByOutletId": "Outlets/GetCEDSByOutletId/{outletId}",
    "SearchRetailItemsByType": "retailitems/search",
    "GetInventoryOutletStatus": "Inventory/outlet/{outletId}/status",
    "GetRetailInventoryItemByPagination": "RetailItems/retail-inventory/items?outletId={outletId}&includeInactive={includeInactive}&pageStart={pageStart}&pageLength={pageLength}&sortBy={sortBy}&isSortByAscending={isSortByAscending}&searchText={searchText}&category={category}&subcategory1={subcategory1}&subcategory2={subcategory2}&subcategory3={subcategory3}&subcategory4={subcategory4}&subcategory5={subcategory5}&useInventory=true",
    "GetCommissionSetup": "retailitems/getcommissionsetup",
    "UpdateCommissionSetupByFilter": "CommissionSetup/updatecommissionsetupbyfilter",
    "UpdateRetaCommissionSetupByFilter": "retailitems/updatecommissionsetupbyfilter",
    "IsBarcodeExists": "retailitems/validate/barcode/{barcode}/{itemId}",
    "GetRetailItemDetailedInfo": "retailitems/detailed-info?id={id}",
    "GetRetailItemDetailedInfoList": "retailitems/detailed-infolist",
    "GetTransactionCountByPaymentMethod": "Transaction/paymentmethod/{paymentmethodId}/count",
    "GetAllPostTypes": "RevenuePosting/GetPostTypes",
    "CreateRevenuePostMapping": "RevenuePosting/AddPostTypeMappingDetails",
    "DeleteRevenuePostMapping": "RevenuePosting/RemovePostTypeMapping",
    "GetRevenuePostings": "RevenuePosting/GetPostTypeMappingDetails",
    "GetMemberBucketMappingsDetails": "RetailManagementService/MemberBucket/GetMemberBucketMappingsDetails",
    "CreateMemberBucketMapping": "RetailManagementService/MemberBucket/AddMemberBucketMappingDetails",
    "DeleteMemberBucketMapping": "RetailManagementService/MemberBucket/RemoveMemberBucketMappingDetails",
    "SyncPosting": "RevenuePosting/Sync",
    "WebProxyHealthCheck": "Transaction/WebCommunicationProxy/HealthCheck",
    "GetAllCreditCardIssuerType": "Payment/GetAllCreditCardIssuerType",
    "ReversePayment": "transaction/{transactionId}/reversepayment/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/tenderId/{tenderId}/paymentReferenceId/{paymentReferenceId}/terminalId/{terminalId}",
    "CancelAllPayments": "transaction/{transactionId}/cancelpayments/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/terminalId/{terminalId}",
    "UpdateCaddyShackComment": "transaction/{transactionId}/UpdateCaddyShackComment",
    "GetMiscConfiguration": "settings/AllSetting/Misc",
    "GetMiscConfigurationByPropertyId": "settings/AllSetting/{propertyId}/Misc",
    "GetMachineNamesConfigurationInfo": "MachineName/GetMachineNames/ConfigInfo/{productId}/{userId}",
    "GetAggregateTransactionByIds": "Transaction/GetTransactionAggregateByIds",
    "GetPaymentTransaction": "Payment/GetPaymentTransaction",
    "RefundAllPayments": "Transaction/{transactionId}/returnpayments/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/terminalId/{terminalId}",
    "RefundPartialPayments": "Transaction/{transactionId}/returnpartialpayments/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/terminalId/{terminalId}/amountToRefund/{amountToRefund}",
    "MembershipRefundPayment": "Transaction/{transactionId}/membershiprefund/{ticketNumber}/outletId/{outletId}/checkHandle/{checkHandleGuid}/tenderId/{tenderId}/terminalId/{terminalId}",
    "GetCaddyRequirements": "CaddyRequirements/active/{includeInactive}",
    "GetGuestItinerary": "Activity/GetActivitiesByGuestAndDate",
    "GetClearedPlayersFromCancelledActivityIds": "TeeTimes/GetClearedPlayersFromCancelledActivityIds",
    "GetActivitiesForTeeTimeItinerary": "Activity/GetActivitiesForTeeTimeItinerary",
    "RetailFunctionalitiesByProduct": "RetailManagementService/RetailFunctionalities/product/{productId}",
    "GenderOverrideTransLog": "TransLog/GenderPreference",
    "GetTaxesV2": "RetailManagementService/Tax",
    "GetPaymentSettingByModuleAndScreen": "Setting/{module}/{screen}",
    "PaymentSetting": "Setting",
    "GetDiscountReason": "RetailManagementService/DiscountReason",
    "CreateDiscountReason": "RetailManagementService/DiscountReason",
    "UpdateDiscountReason": "RetailManagementService/DiscountReason",
    "DeleteDiscountReason": "RetailManagementService/DiscountReason/{id}",
    "CategoryOutletListOrderChange": "QuickSale/category/listorder/outlet/{outletId}/from/{fromOrder}/to/{toOrder}",
    "ItemListOrderChange": "QuickSale/items/listorder/outlet/{outletId}/category/{categoryId}/from/{fromOrder}/to/{toOrder}",
    "getCCTerminalNextListOrder": "CreditCardTerminals/NextListOrder",
    "RoomLookupWithRoomKeyData": "Payment/GetRoomDetailsFromRoomkeyData",
    "CombineGuestInformation": "GuestCombine/{primaryGuestId}",
    "UpdateGuestInformation": "GuestCombine/updateguest",
    "GetGuestInfoByGuid": "GuestCombine/{id}",
    "ImportItem": "RetailItems/ImportItems/{isAuthorised}",
    "ValidateImport": "RetailItems/ValidateImport",
    "ImportStatus": "RetailItems/ImportStatus",
    "SaveIntegrationsLog": "Payment/PostIntegrationsLog",
    "UpdateIntegrationsLog": "Payment/UpdateIntegrationsLog",
    "EmptyFilterSearch": "GuestCombine/search/{filterName}",
    "CreateSettlementHistory": "Transaction/CreateSettlementHistory",
    "GetSettlementHistory": "Transaction/GetSettlementHistoryList/outlet/{outletId}/{transactionDate}/{status}",
    "TryCloseTransaction": "Checks/TryCloseTransaction/{transactionId}",
    "ValidateCloseTransaction": "Checks/ValidateCloseTransaction/{transactionId}",
    "CreateTransLog": "CreateTransLog/transaction/{transactionId}/logtype/{logType}",
    "GetAllTemplatesForRetailDropdown": "LetterTemplate/{includeInactive}",
    "GetAllTransactionsForScreen": "elasticsearch/GetAllTransactionsForScreen",
    "GetTopExceptionsByUsers": "elasticsearch/GetTopExceptionsByUsers/{startDate}/{endDate}",
    "GetTopFrequentExceptions": "elasticsearch/GetTopFrequentExceptions/{startDate}/{endDate}",
    "GetTransactionsByDate": "elasticsearch/GetTransactionsByDate/{startDate}/{endDate}/{recordStart}",
    "GetTopTransactionsByUsers": "elasticsearch/GetTopTransactionsByUser/{startDate}/{endDate}",
    "GetTopFrequentTransactions": "elasticsearch/GetTopFrequentTransactions/{startDate}/{endDate}",
    "GetTransactionsByPerformance": "elasticsearch/GetTransactionsByPerformance/{startDate}/{endDate}/{seconds}",
    "GetAllTransactionsForUser": "elasticsearch/GetAllTransactionsForUser/{startDate}/{endDate}/{userName}",
    "GetExceptionByDate": "elasticsearch/getexceptionbydate/{fromdate}/{toDate}",
    "GetStackTraceForTraceId": "elasticsearch/getstacktracefortraceid/{traceId}",
    "UpdateRetailTransactionData": "transaction/updatetransaction/{id}",
    "GetUserInfoByPropertyId": "User/GetUserInfoByPropertyId/{propertyId}/{productId}",
    "GetFailedTransactions": "PaymentTransaction/GetFailedTransactions",
    "BatchProcessFailedTransactions": "PaymentTransaction/BatchProcessFailedTransactions/{processAll}",
    "GetFailureDetails": "PaymentTransaction/GetFailureDetails",
    "GetActiveBatchStatus": "PaymentTransaction/GetActiveBatchProcess",
    "GetFailureRevenuePosting": "POS/RevenuePosting",
    "RevenuePost": "POS/RevenuePosting/Post",
    "GetRevenuePostingLogs": "POS/RevenuePosting/GetRevenuePostingLogs",
    "IsBatchProcessingPending": "POS/RevenuePosting/IsBatchProcessingPending",
    "MachineAssociatedWithTransaction": "Transaction/IsMachineNameAssociatedWithRetailTransaction/{machineNameId}",
    "GetTemplateById": "ReportTemplate/{templateId}",
    "ReportTemplate": "ReportTemplate",
    "GetMaxListOrder": "ReportTemplate/maxListOrder",
    "DeleteTemplate": "ReportTemplate/{id}",
    "UpdateTemplate": "ReportTemplate/{id}",
    "UpdateListOrder": "ReportTemplate/listorder/{fromOrder}/{toOrder}",
    "DefaultTemplateExist": "ReportTemplate/defaultTemplateExists/id/{id}",
    "GetAllEvents": "ReportTemplate/getEvents",
    "GetDefaultTemplateInfo": "ReportTemplate/type/{type}",
    "GetTemplateKeyWords": "ReportTemplate/keyWords",
    "GetRoomRevenuePostingLogs": "POS/RevenuePosting/GetRoomRevenuePostingLogs",
    "transactionExtract": "reports/TransactionExtract",
    "GetTeeTimeMultiPack": "PointOfSaleService/MultiPack/GetMultipleItemMultipacksByTransactionDetailIds",
    "GetDataMagineConfiguration": "DataMagine/GetDmConfig",
    "CreateDataMagineConfiguration": "DataMagine/CreateDmConfig",
    "UpdateDataMagineConfiguration": "DataMagine/UpdateDmConfig",
    "NightAuditConfig": "NightAuditConfig",
    "DeleteNightAuditConfigById": "NightAuditConfig/{id}",
    "NightAuditSchedule": "NightAuditScheduleConfig",
    "DeleteNightAuditScheduleConfigById": "NightAuditScheduleConfig/{id}",
    "GetGuestScheduledTeeTime": "TeeTimes/GetGuestScheduledTeeTime",
    "CreateLogType": "LogType",
    "UpdateLogType": "LogType/{id}",
    "GetAllLogType": "LogType/active/{isIncludeInactive}",
    "GetLogType": "LogType/{id}",
    "DeleteLogType": "LogType/{id}",
    "GetWebCommunicationProxyVersion": "Payment/GetWebCommunicationProxyVersion",
    "CheckPMSCommunication": "RetailManagementService/PMSIntegrationHostConfiguration/CheckPMSCommunication",
    "GetAllPMSIntegrationHostConfigurations": "RetailManagementService/PMSIntegrationHostConfiguration/GetAllPMSIntegrationHostConfigurations",
    "SavePMSIntegrationHostConfiguration": "RetailManagementService/PMSIntegrationHostConfiguration/SavePMSIntegrationHostConfiguration",
    "GetAllPostType": "FolioService/PostTypes?includeInactive={includeInactive}",
    "GetAllPaymentMethod": "FolioService/PaymentMethods?includeInactive={includeInactive}",
    "GetPaymentMethodWithReferences": "FolioService/PaymentMethods/PaymentReferences?includeInactive={includeInactive}",
    "GetPaymentMethod": "FolioService/PaymentMethods/{id}",
    "DeletePaymentMethod": "FolioService/PaymentMethods/{id}",
    "CreatePaymentMethod": "FolioService/PaymentMethods",
    "UpdatePaymentMethod": "FolioService/PaymentMethods",
    "GetNextListOrder_PaymentMethod": "FolioService/PaymentMethods/NextListOrder",
    "GetPaymentWithRefernceById": "FolioService/PaymentMethods/GetPaymentWithRefernceById/{id}",
    "UpdatePaymentOnGridAction": "FolioService/PaymentMethods/UpdateOndDragnDrop",  
    "GetAllCreditCardTerminal": "CreditCardTerminals/includeInactive/{includeInactive}",
    "GetAllTransactionLogs" : "Report/TransactionLogs/{scheduleTeeTimeId}/{isOpenSlot}/{courseId}/{scheduledDateTime}/{originalHoleNumber}",
    "GetAuditTrailDataForOpenSlots" : "Report/TransactionLogsForOpenSlots/{courseId}/{scheduledDateTime}/{originalHoleNumber}",
    "GetARAccountByFilter": "AccountingService/ARCustomer/GetAccountsByFilter?searchText={searchText}&searchBy={searchBy}",
    "CreateDiscountTypeConfiguration" :"DiscountTypeConfiguration",
    "UpdateDiscountTypeConfiguration" : "DiscountTypeConfiguration/update",
    "DeleteDiscountTypeConfiguration" : "DiscountTypeConfiguration/{discountId}",
    "GetDiscountTypeConfigurationById" : "DiscountTypeConfiguration/{DiscountTypeId}",
    "GetDiscountConfigurationsByCategoryId" : "DiscountConfigurations/{categoryId}",
    "DeleteDiscountTypeConfigurationByIds":"DiscountTypeConfiguration/DeleteDiscountTypeConfigurationByIds",
    "FolioLookupForNightAudit": "dayend/FolioLookupForNightAudit",
    "GetSlotPlayerDetails" : "TeeSheets/GetSlotPlayerDetails?date={date}&originalHoleNumber={originalHoleNumber}&courseId={courseId}&teeSlotNavigation={teeSlotNavigation}&activityId={activityId}",
    "GetBreakpointforRoleAsync":"user/GetUserClaimsForRoleAsync/",
    //VipTypeRoutes
    "GetAllVipType": "VipType/active/{isIncludeInactive}",
    "GetVipType": "VipType/{id}",
    "CreateVipType": "VipType",
    "UpdateVipType": "VipType/{id}",
    "DeleteVipType": "VipType/{id}",
    "VipTypeDrag" : "VipType/listOrder/{fromOrder}/{toOrder}/{includeInactive}",
    "VipTypeNextListOrder" : "VipType/nextListOrder",
    //RentalItemsRoutes
    "GetScheduledTeeTimeByScheduledTeeTimePlayerIds": "TeeTimes/scheduledteetimes",
    "CreateCancellationNoShowLedger": 'CancellationNoShowLedger',
    "UpdateCancellationNoShowLedgerStatus" : "CancellationNoShowLedger/UpdateCancellationNoShowLedgerStatus/playerId/{playerId}/status/{status}/ticketNumber/{ticketNumber}"
  }