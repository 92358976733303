export interface PlayersWithContactInformation {
    playerId: number;
    action: string;
    scheduledTeeTime : any ;
    cancelTeeTimePlayer : any;
    emailAddress : string[];
    phoneNumber : string[];
    cCAddress?:string[];
    bCCAddress?:string[];
    eformLinks?: EformLinkMapping[];
}

export class EformLinkMapping
{
    eFormId: string;
    eFormName: string;
    Link: string;
}

export class Actions{
    static readonly  TEETIMEBOOKING  = "TeeTimeConfirmation";
    static readonly TEETIMEREMINDER = "TeeTimeReminder";
    static readonly TEETIMECANCELLATION  = "TeeTimeCancellation";
    static readonly TEETIMENOSHOW = "TeeTimeNoShow";
    static readonly TEETIMEEDITORMOVED = "TeeTimeUpdate";
    static readonly RETAILRECEIPTNOTIFICATION  = "GolfRetailReceipts";
    static readonly BULKBOOKING = "BulkBooking";
    static readonly GROUPBOOKING = "GroupBooking";
    static readonly BULKUPDATE = "BulkUpdate";
    static readonly BULKCANCEL = "BulkCancel";
    static readonly ONLINECONFIRMATION = "OnlineConfirmation"; 
    static readonly AUTHORIZEPAYMENT = "AuthorizePayment";   
}
