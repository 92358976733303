import { Injectable } from '@angular/core';
import { GolfScheduleCommunication } from 'src/app/shared/communication/services/golfschedule.service';
import { ApiRatesData } from './rate-model';

@Injectable()

export class RateModalService {


    constructor(private _golfScheduleService: GolfScheduleCommunication) {}

    public getRatesByCourseIdAndDateTime(courseId: number, dateTime: string): Promise<ApiRatesData[]> {
      return this._golfScheduleService.getPromise( {route: GolfApiRoute.GetRatesByCourseId, uriParams: {courseId, dateTime} } );
    }
}
