import { UntypedFormGroup } from '@angular/forms';
import { PlayerTypeRateType } from 'src/app/tee-time/tournaments/tournaments-modal/tournament-details/tournament-details.model';
import { AllocationBlockWithPlayerType } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import * as PlayerTypeNew from 'src/app/settings/golf-setup/code-setup/player-type/player-type.modal';
import * as RateTypeNew from 'src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal';
export interface PlayerInfoCations {
  selectDate: string;
  selectTime: string;
  allocationCode: string;
  playerType: string;
  rateType: string;
  playerGroup: string;
  firstName: string;
  lastName: string;
  pronounced: string;
  noofHoles: string;
  greenFee: string;
  cartFee: string;
  negotiateGreenFee: string;
  negotiatedGreenFee: string;
  negotiateCartFee: string;
  negotiatedCartFee: string;
  viewedByStarter: string;
  phoneType: string;
  mailType: string;
  device: string;
  customField1: string;
  customField2: string;
  customField3: string;
  customField4: string;
  customField5: string;
  payTitle: string;
  pay: string;
  cancel: string;
  playerInfoHeader: string;
  playerListHeader: string;
  paymentInfoHeader: string;
  availableRounds: string;
  MembershipExpiryDate: string;
  MembershipRenewalDate: string;
  MembershipType: string;
  MembershipStatus: string;
  // address: string;
  // postalCode: string;
  // city: string;
  // state: string;
  // country: string;


  addPlayer: string;
  updatePlayer: string;
  reset: string;
  connect: string;
  savedCardDetail: string;
  toggleYes: string;
  toggleNo: string;
  overlappingBookings: string;
  overlappingTeeTimeBooking: string;
  overlappingWouldYouLikeToProceed: string;
  editInfoCard: string;
  delete: string;
  searchByConfNoAndGuestName: string;
  searchByPlayerName: string;
  CreatePlayer: string;
  clearCartMessage: string;
  PatronID: string;
  PlayerWorth: string;
  Rank: string;
  startHole: string;
}

export interface CustomFieldEmitter {
  formGroup: UntypedFormGroup;
  formValue: any;
}

export interface PlayerErrorCaptions {
  missingFirstName: string;
  missingLastName: string;
  missingRateType: string;
  missingPlayerType: string;
  allocationDaysOutError: string;
  playerDaysOutStartError: string;
  playerDaysOutEndError: string;
  missingNegotiatedCartFee: string;
  missingNegotiatedGreensFee: string;
  allocationMappingMissing: string;
  allocationPlayerMissing: string;
  noRateSetup: string;
}

export interface PlayerTypes {
  id: number;
  type: string;
  daysOutStart: number;
  daysOutEnd: number;
  guaranteeType: string;
  isActive: boolean;
}

export interface PlayerCategory {
  id: number;
  description: string;
  name: string;
}

export interface HolesInfo {
  id: number;
  description: string;
  name: string;
  fromEdit: boolean;
  disabled?: boolean;
}

export interface AllocationCode {
  id: number;
  allocationBlockCode: string;
  allocationBlockName: string;
  colorCode?: string;
  daysOut: number;
  isActive?: boolean;
  listOrder?: number;
}
export interface RateSetupData {
  id: number;
  courseId: number | number[];
  rateTypeId: number;
  startDate: string; //clarification
  endDate: string; //clarification
  startTime: string; //clarification
  endTime: string; //clarification
  dayOfWeek: string[];
  selectedDays?: number;
  greenFee: number;
  cartFee: number;
  entryFee: number;
  greenFeeRetailItemId: number;
  cartFeeRetailItemId: number;
  entryFeeRetailItemId: number;
}

export interface RateType {
  id: number;
  type: string;
  daysOutStart: number;
  daysOutEnd: number;
  guaranteeType: string;
  isActive: boolean;
  isSupressReceipt: boolean;
  isNineHoles?: boolean;
  disabled?: boolean;
}

export interface CreditCardInfo {
  cardNumber: string;
  expiryDate: string;
}

export interface CustomFieldOption {
  id: number;
  name: string;
  description: string;
}

export interface DefaultsSettingConfig {
  blockingCode: number;
  memberStatus: number;
  memberPlayerType: number;
  memberRateType: number;
  nonMemberPlayerType: number;
  nonMemberRateType: number;
  resortPlayerType: number;
  resortRateType: number;
  defaultOutletType?: number;
  memberGuestPlayerType: number;
  memberGuestRateType: number;
  memberGuestPlayerFirstName: string;
  memberGuestPlayerLastName: string;
  memberGuestsToAdd: number;
  autoSelectPlayers?: boolean;
  defaultPlayerCategory?: number;
  defaultHotelReservationCategory?: number;
  defaultPhoneTypeOption?: number;
  defaultEmailTypeOption?: number;
  defaultCountryCode?: string;
  markAsPaidPaymentMethod?: number,
  markAsPaidPaymentMethodReceiptComment?: string;
  enableNonZeroRateForMarkAsPaid?: boolean;
  zeroMemberChargePaymentMethod?: number;
  zeroMemberChargePaymentMethodReceiptComment?: string;
  bulkPlayersPerGroup?: number;
  defaultExistingPlayerCategory?: number;
  enableExtentedSearchByDefault?: boolean;
}

export interface AutoTeeTimeHoldConfig {
  isEnabled: boolean;
  holdReleaseMinutes: number;
  holdCheckingIntervalInSeconds: number;
}

export interface TeeTimeConfig {
  rainCheckNote: string;
  ticketNote: string;

  requirePlayerType: boolean;
  requireRateType: boolean;
  showMemberSignOnTeeGrid: boolean;
  defaultToGraphicalTeeSheet: boolean;
  promptUserToPrintTeeTickets: boolean;
  autoPrintTeeTickets: boolean;
  requireTeeTimesToPaid: boolean;
  setPaidOnDayOfPlay?: boolean;
  requireCheckInCheckOut?: boolean;
  enableAutoCheck?: boolean;
  allowPaymentInCheckIn?:boolean;
  autoCheckInCheckOutZeroCharges?:boolean;
  editCaddyInfoOnMoveCopy?: boolean;
}

export interface GolfSettingsConfig {
  enableGroupingOfPlayers: boolean;
  playerPlayGroups: PlayerPlayGroupColor[];
  playerPositionBySlot: boolean;
  playerPositionByGroup: boolean;
  enableAddingCourseToCaddy: boolean;
  enableAllocationCodePermissionByRole: boolean;
  printerManagerURI: string;
  allowAllocationCodeOnReinstate: boolean;
  enableDynamicPricing: boolean;
  includeCartFeeForRateCalculation:boolean;
}


export interface PlayerPlayGroupColor {
  id: number;
  colorCode: string;
}

export interface Deposit {
  depositPercentage: number;
  dueDays: number;
  isApplicable: boolean;
}

export interface RefreshTime {
  dayOfWeek: number;
  dayOfWeekCode: string;
  autoRefresh: boolean;
  minutesToRefresh: number;
}
export enum MoreAction {
  edit,
  clear
}
export interface AllocationBlockPlayerTypeIds {
  allocationBlockId: number;
  playerTypeIds: number[];
}

export interface PlayerTypeRateTypeIds {
  playerTypeId: number;
  rateTypeIds: number[];
}
export interface CustomFields {
  customField1: string;
  customField2: string;
  customField3: string;
  customField4: string;
  customField5: string;
}

export interface MasterData {
  allocationCode: AllocationCode[];
  playerType: PlayerTypes[],
  rateType: RateType[],
  playerTypeRateType: PlayerTypeRateType[],
  allocationBlockWithPlayerType: AllocationBlockWithPlayerType[],
  defaultsSettingConfig: DefaultsSettingConfig,
  teeTimeConfig: TeeTimeConfig,
  rateSetupData: RateSetupData[],
  golfSettingsConfig : GolfSettingsConfig,
  dynamicPricingPlayerFee? : DynamicPricingPlayerFee[]
}

export interface PackagePlayerTypesRateTypes {
  id: number;
  packageName: string;
  packageCode: number;
  isActive: boolean;
  playerTypes: PlayerTypeNew.PlayerType[];
  rateTypes: RateTypeNew.RateType[];
}

export class UpdatePlayerToGPSInterface {
  playerId: number;
  slotPosition: number;
  playerLinkId: string;
}

export interface DynamicPricingPlayerFee{
  scheduledTeeTimePlayerFeeId : number;
  dynamicPricingId : number;
  rateTypeId : number;
  occupancy : number;
  daysInAdvance : number
  isCartFeeIncluded : boolean;
  originalGreenFee : number;
  originalCartFee :number;
  greenFee :number;
  cartFee :number;
  utilizationGreenFee :number; 
  daysInAdvanceGreenFee :number;
  utilizationCartFee :number;
  daysInAdvanceCartFee :number;  
  stopAvailability: boolean;
}

export interface CustomFeeRequest{
  scheduledTeeTimePlayerFeeId: number;
  scheduledDate: Date;
  totalAmount: number;
}

