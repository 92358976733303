import { TeeSheetPlayerDetails } from "src/app/shared/models/teesheet.form.models";

export interface TeeSheetPlayerOption extends TeeSheetPlayerDetails {
  selected: boolean;
  sourceScheduledTeeTimeId:number;
}

export enum Player {
  Selectedplayer = 1,
  AllPlayers = 2,
  GroupPlayers = 3
}
