import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { RedeemService } from '../redeem-round.service';

@Component({
  selector: 'app-redeem-progress-bar',
  templateUrl: './redeem-progress-bar.component.html',
  styleUrls: ['./redeem-progress-bar.component.scss']
})
export class RedeemProgressBarComponent implements OnInit {

  reedemService: RedeemService;
  captions: any;
  progressMessage: string;
  totalCount: number;
  processedCount: number;
  successCount: number;
  failureCount: number;
  progressPercentage: string = '10%'

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData
    , public dialogRef: MatDialogRef<RedeemProgressBarComponent>
    , private localization: GolfLocalization
  ) {
    this.captions = this.localization.captions;
    this.reedemService = dialogData.service;
   }

  ngOnInit(): void {
    this.reedemService.progressData$.subscribe(data=> {
      this.totalCount = data.totalCount;
      this.processedCount = data.processedCount;
      this.successCount = data.successCount;
      this.failureCount = data.failureCount;
      const percentage = Math.floor((this.processedCount/this.totalCount)*100);
      if(percentage > 10) {
        this.progressPercentage = percentage+'%';
      }
      this.progressMessage = this.localization.replacePlaceholders(this.captions.common.zero_Member_Progress_Count, 
        ['progressedCount', 'totalCount'], [this.processedCount, this.totalCount]);
      console.log(data);
      if(this.totalCount == this.processedCount) {
        this.dialogRef.close();
      }
    })
  }

}
