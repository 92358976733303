import { Injectable } from "@angular/core";
import { DomainRetailItem, PlayerPaymentstatus, ScheduledTeeTimeUnPaidPlayer, Course, AlertType, ButtonType } from '../../../shared/share../../../retail/shared/shared.modal';
import { UnPaidPlayersService } from './unpaid-players.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfUtilities } from '../../utilities/golf-utilities';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { PaymentBusinessService } from '../payment/payment-business.service';
import { MultiPackDetails } from "../../models/unpaid-players.model";
import { cloneDeep } from "lodash";
import { TeeTimeAction } from "../../models/teesheet.api.models";


@Injectable({ providedIn: 'root' })

export class UnPaidPlayersBusiness {
  unPaidPlayer: ScheduledTeeTimeUnPaidPlayer;
  retailItemData: { key: number, value: DomainRetailItem }[] = [];
  captions: any;

  /**
   * Class contains business logic and transformation between the UI and API model.
   * One or more data services can be injected to achieve the results for UI binding.
   */
  constructor(private _unPaidPlayerService: UnPaidPlayersService,
    private _localization: GolfLocalization,
    private utilities: GolfUtilities,
    private _PropertyInformation: GolfPropertyInformation,
    private _paymentBusinessService: PaymentBusinessService
  ) {
  }

  captionGenerator() {
    this.captions = this._localization.captions.shop.unpaidPlayers;
    return this.captions;
  }

  public async getPlayers(courseId: number, date: string, playerStatus: number = PlayerPaymentstatus.unPaid, isFromMarkAsPaid: boolean = false,  isFromDeposit: boolean = false, isFromPaymentModal: boolean = false): Promise<ScheduledTeeTimeUnPaidPlayer[]> {
    let unPaidPlayers = await this._unPaidPlayerService.getScheduledTeeTimePlayers(courseId, date, playerStatus, isFromMarkAsPaid, isFromDeposit, isFromPaymentModal);
    const multiPackTransactionDetailIds = unPaidPlayers.filter(x => x.multiPackTransactionDetailId > 0).map(x => x.multiPackTransactionDetailId);
    let multiPackDetails: MultiPackDetails[] = [];
    let multiPackRetailItemds = [];
    if (multiPackTransactionDetailIds && multiPackTransactionDetailIds.length > 0) {
      let distinctMultiPackTransactionDetailIds = [... new Set(multiPackTransactionDetailIds)];
      multiPackDetails = await this._paymentBusinessService.GetTeeTimeMultiPack(distinctMultiPackTransactionDetailIds);
      multiPackRetailItemds = multiPackDetails.flatMap(x => [x.clientMultiPacks.map(y => y.linkedRetailItemId), x.multiPackRetailItemId]).flat();
    }
    return this.mapPlayerDetails(unPaidPlayers, multiPackDetails, multiPackRetailItemds);
  }

  public async getPlayer(scheduledTeeTimePlayerId:number, courseId: number, date: string, playerStatus: number = PlayerPaymentstatus.unPaid){
    let unPaidPlayers = await this._unPaidPlayerService.getScheduledTeeTimePlayer(scheduledTeeTimePlayerId, courseId, date, playerStatus);
    const multiPackTransactionDetailIds = unPaidPlayers.filter(x => x.multiPackTransactionDetailId > 0).map(x => x.multiPackTransactionDetailId);
    let multiPackDetails: MultiPackDetails[] = [];
    let multiPackRetailItemds = [];
    if (multiPackTransactionDetailIds && multiPackTransactionDetailIds.length > 0) {
      let distinctMultiPackTransactionDetailIds = [... new Set(multiPackTransactionDetailIds)];
      multiPackDetails = await this._paymentBusinessService.GetTeeTimeMultiPack(distinctMultiPackTransactionDetailIds);
      multiPackRetailItemds = multiPackDetails.flatMap(x => [x.clientMultiPacks.map(y => y.linkedRetailItemId), x.multiPackRetailItemId]).flat();
    }
    return this.mapPlayerDetails(unPaidPlayers, multiPackDetails, multiPackRetailItemds);
  }
  private async mapPlayerDetails(unPaidPlayers: ScheduledTeeTimeUnPaidPlayer[], multiPackDetails: MultiPackDetails[] = [], multiPackRetailItemds = []) {
    
    let corporatesApplicable = {};
    let memberConfiguration = JSON.parse(sessionStorage.getItem('memberConfiguration'));
    if(memberConfiguration != null && memberConfiguration.corpIdNameMap != '' && memberConfiguration.corpIdNameMap != null)
    {
      corporatesApplicable = JSON.parse(memberConfiguration.corpIdNameMap);
      if(Object.keys(corporatesApplicable).length ?? false )
      {
        if (unPaidPlayers) {
          unPaidPlayers.forEach(player => {
            player.scheduledAPIDateTime = player.scheduledDateTime;
            player.scheduledDateTime = this._localization.LocalizeShortDateTime(this._localization.getDate(player.scheduledDateTime)).toUpperCase();
            player.playerName = this.utilities.formatGuestName(player.firstName, player.lastName);
            player.corpId = player.corpId,
            player.corpName = corporatesApplicable[player.corpId] != null ? corporatesApplicable[player.corpId] : ''
          });
        }
      }
    }
    else
    {
      if (unPaidPlayers) {
        unPaidPlayers.forEach(player => {
          player.scheduledAPIDateTime = player.scheduledDateTime;
          player.scheduledDateTime = this._localization.LocalizeShortDateTime(this._localization.getDate(player.scheduledDateTime)).toUpperCase();
          player.playerName = this.utilities.formatGuestName(player.firstName, player.lastName);
        });
      }
    }

    
    let allRetailItemIds = [...unPaidPlayers.map(x => x.cartFeeRetailItemId)]
      .concat([...unPaidPlayers.map(x => x.greenFeeRetailItemId)])
      .concat([...unPaidPlayers.map(x => x.entryFeeRetailItemId)])
      .concat([...unPaidPlayers.map(x => x.negotiateCartFeeRetailItemId)])
      .concat([...unPaidPlayers.map(x => x.negotiateGreenFeeRetailItemId)])
      .concat([...unPaidPlayers.map(x => x.negotiateEntryFeeRetailItemId)])
      .concat(multiPackRetailItemds);
    let packageItemIds: number[] = [];
    unPaidPlayers.forEach(player => { player.otherItems.forEach(p => packageItemIds.push(p.retailItemId)) });
    allRetailItemIds = allRetailItemIds.concat(packageItemIds);
    let distinctItemsIds = [... new Set(allRetailItemIds)];
    distinctItemsIds = distinctItemsIds.filter(id => id > 0);
    let retailItems = await this._paymentBusinessService.GetRetailItemsByIds(distinctItemsIds);
    this.SetRetailItemDetails(unPaidPlayers, retailItems, multiPackDetails);
    return unPaidPlayers;
  }
  public async getPlayersPaidUnPaid(courseId: number, dateTime: string, teeTimeAction: TeeTimeAction = TeeTimeAction.none): Promise<ScheduledTeeTimeUnPaidPlayer[]> {   
    var unPaidPlayers = await this._unPaidPlayerService.getScheduledTeeTimePlayersPaidUnpaid(courseId, dateTime, teeTimeAction);
    const multiPackTransactionDetailIds = unPaidPlayers.filter(x => x.multiPackTransactionDetailId > 0).map(x => x.multiPackTransactionDetailId);
    let multiPackDetails: MultiPackDetails[] = [];
    let multiPackRetailItemds = [];
    if (multiPackTransactionDetailIds && multiPackTransactionDetailIds.length > 0) {
      let distinctMultiPackTransactionDetailIds = [... new Set(multiPackTransactionDetailIds)];
      multiPackDetails = await this._paymentBusinessService.GetTeeTimeMultiPack(distinctMultiPackTransactionDetailIds);
      multiPackRetailItemds = multiPackDetails.flatMap(x => [x.clientMultiPacks.map(y => y.linkedRetailItemId), x.multiPackRetailItemId]).flat();
    }
    return this.mapPlayerDetails(unPaidPlayers, multiPackDetails, multiPackRetailItemds);
  }

  SetRetailItemDetails(unPaidPlayers: ScheduledTeeTimeUnPaidPlayer[], retailItems: any[], multiPackRetailItem?: MultiPackDetails[]) {
    if (unPaidPlayers.length > 0 && retailItems.length > 0) {
      unPaidPlayers.forEach(player => {
        if (player.multiPackTransactionDetailId > 0) {
          player.cartFeeRetailItemId = player.greenFeeRetailItemId = player.entryFeeRetailItemId = 0
          player.multiPackRetailItem = [];
          const multipack = cloneDeep(multiPackRetailItem.find(x => x.multiPackTransactionDetailId === player.multiPackTransactionDetailId));
          const parentMultiPack = cloneDeep(retailItems.find(x => x.id === multipack.multiPackRetailItemId));
          parentMultiPack.salesPrice = multipack.multiPackPerSalePrice * -1;
          parentMultiPack.itemDescription = this._localization.captions.shop.unpaidPlayers.RedeemingMultipack + ' - ' + parentMultiPack.itemDescription;
          player.multiPackRetailItem.push(parentMultiPack);
          player.multiPackRetailItem.push(...multipack.clientMultiPacks.map(x => {
            let mulltiPackRetailItem = cloneDeep(retailItems.find(item => item.id === x.linkedRetailItemId));
            mulltiPackRetailItem.salesPrice = x.salePrice;
            mulltiPackRetailItem.gratuity = x.gratuity;
            mulltiPackRetailItem.serviceCharge = x.serviceCharge;
            return mulltiPackRetailItem;
          }));
        } else {
          if (player.cartFeeRetailItemId != 0)
            player.cartFeeRetailItem = retailItems.find(x => x.id == player.cartFeeRetailItemId);
          if (player.greenFeeRetailItemId != 0)
            player.greenFeeRetailItem = retailItems.find(x => x.id == player.greenFeeRetailItemId);
          if (player.entryFeeRetailItemId != 0)
            player.entryFeeRetailItem = retailItems.find(x => x.id == player.entryFeeRetailItemId);
          player.otherItems.forEach(item => {
            if (item.retailItemId != 0) {
              item.retailItemDetail = retailItems.find(x => x.id == item.retailItemId);
            }
          });
        }
        if(player.negotiateCartFeeRetailItemId != 0) 
          player.negotiateCartFeeRetailItem = retailItems.find(x => x.id == player.negotiateCartFeeRetailItemId);
        if(player.negotiateGreenFeeRetailItemId != 0) 
          player.negotiateGreenFeeRetailItem = retailItems.find(x => x.id == player.negotiateGreenFeeRetailItemId);
        if(player.negotiateEntryFeeRetailItemId != 0) 
          player.negotiateEntryFeeRetailItem = retailItems.find(x => x.id == player.negotiateEntryFeeRetailItemId);
      });
    }
  }

  public async getCourses(): Promise<Course[]> {
    return await this._unPaidPlayerService.getCourses(true);

  }

  ShowConfirmation(errorMessage, callback, extraParam?) {
    this.utilities.showAlert(errorMessage, AlertType.Warning, ButtonType.OkCancel, callback, extraParam);
  }

  public showBusinessError(err: any) {
    if (err.error && err.error.errorCode) {
      let code: number = parseInt(err.error.errorCode);
      let message: string = this._localization.getError(code);
      this.utilities.showError(message);
    } else {
      this.utilities.showError(err.message);
    }
  }

  public getDefaultDate(): Date {
    return this._PropertyInformation.CurrentDTTM;
  }

  public async getCourse(courseId: number): Promise<Course> {
    return await this._unPaidPlayerService.getCourse(courseId);
  }
  public async setMarkAsPaidPlayer(arrMarkAsPaidPlayer: any[]) {
    return await this._unPaidPlayerService.setMarkAsPaidPlayer(arrMarkAsPaidPlayer);
  }
}
