import { Deposit } from '../payment-model';
import { Observable } from 'rxjs';
import { TeeTimeActions } from 'src/app/tee-time-actions/tee-time-action.base';
import { TeeSheetGraphical } from 'src/app/tee-time/shared/tee-sheet/tee-sheet-graphicalview';
import { PlayerPaymentstatus } from 'src/app/tee-time/search/search-model';
import { TeeSheetPlayerOption, Player } from 'src/app/tee-time-actions/player-option/player-option.modal';
import { ContactInformation, OverrideDetails, PlayerStayDetail,Address } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { PlayerCategory} from 'src/app/common/enums/shared-enums';
// Layout model
export interface TeeSheetSkeletonData {
  id: string;   // guid
  time: string; // ISO datetime string
  hole: string;
  type: number;
  playerDetail: TeeSheetPlayerDetails[];
  
  allocation: Allocation;
  status: Status;   // by default
  slotBackgroundInfo: SlotBackgroundInfo;
  unmodifiedSlotData?: any;  // to be replaced after move , copy
  course: Course;
  comments?: string;
  scheduledTeeTimeId?: number;
  isDropAllowed?: boolean;
  isBulkBooking?: boolean;
  holes: number; // 1 or 18 holes
  createdDateTime?: string;
  userName?: string;
  isTeeGridComplete?: boolean; // check TeeGridContent API call is completed
  originalHoleNumber: string;
  teeGridSlotId: number;
  viewedByStarter: boolean;
  allocationDateTime: Date;
  isFromSearch?: boolean;
  isFromTeeSheetForCancel?:boolean;
  isNextSlotAvailable?:boolean;
  isPreviousSlotAvailable?:boolean;
  isSlotsSkipped?:boolean;
}

// Content model
export interface TeeSheetGridContent {
  teeTimeId: number;
  time: string;
  hole: string;
  viewedByStarter: boolean;
  type: string;
  date: string;  //redudant
  depositAmount: string;
  playerDetail: TeeSheetPlayerDetails[];
  allocation?: Allocation;
  status: Status;
  course: Course;
  playingHole: number;  //redudant
  comments: string;
  isBulkBooking?: boolean;
  isBulkEdit?: boolean;
  createdDateTime?: string;
  userName?: string;
  originalHoleNumber: string;
}

export interface Course {
  course: string;
  id: number;
}

export interface SlotBackgroundInfo {
  name: string;
  colorCode: string;
  icon: string;
}

export class Status implements SlotBackgroundInfo {
  name: string;
  colorCode: string;
  icon: string;
  scheduleStatus: ScheduleStatus
}

export class PlayerPlayGroup implements SlotBackgroundInfo {
  id: number;
  name: string;
  colorCode: string;
  icon: string;
  listOrder: number;
}

export class Allocation implements SlotBackgroundInfo {
  id: number;
  name: string;
  colorCode: string;
  icon: string;
  displayOnTeesheet: boolean;
  closed: boolean;
}

export interface PlayerRateType {
  id: number;
  name: string;
  daysOutStart: number;
  daysOutEnd: number;
}

export interface TeeSheetPlayerDetails {
  isDragDisabled: boolean;
  playPos: number;
  firstName: string;
  lastName: string;
  pronounced?: string;
  gender: number | string;
  amountPaid: number;
  memberNumber: string;
  playerComment: string;
  tournamentId?: number;
  playerId: number;
  createdBy?: number;
  lastUpdatedDateTime?: string;
  bagNumber?: number;
  fullName: string;
  deposits: Deposit[];
  transactionId: number;
  playerStatus: PlayerPaymentstatus;
  scheduleTeeTimeId?: number;
  groupId: number;
  bookingId: string;
  playersPerGroup: number;
  teeTimeFormat: TeeTimeFormat;
  sourceScheduledTeeTimeId?: number;
  isPaidPlayer?: boolean;
  rateType?: PlayerRateType; // TO Do
  playerType?: PlayerRateType; // TO Do
  isRemovable?: boolean; //  flag used in Copy,move
  holes?: number;
  playerPos?: number;
  profileImage?: ProfileImage;
  imageReferenceId: string;
  bookingSource?: number;
  allocationBlockId?: number;
  confirmationNumber: string;
  cartId: any;
  caddy?: any;
  caddyType?: any;
  caddyId?: any;
  caddyTypeId?: any;
  playGroup?: PlayerPlayGroup;
  blockedSlotColor?: string;
  isFlagDay?: boolean;
  initialPlayerSlotPosition?: number;
  moveNotAllowed?: boolean;  // Destination Player
  isBlocked: boolean;
  scheduledDateTime?: Date | string;
  ticketNumber?: string;
  scheduledTeeTimePlayerFee?: ScheduledTeeTimePlayerFee;
  entryFee?: number;
  playerTypeId?: number;
  overrideDetails?: OverrideDetails[];
  cartType?: any;
  linkingId?: string;
  playerStayDetail?: PlayerStayDetail;
  multiPackTransactionDetailId: number;
  multiPackGreenFeeValue: number;
  multiPackCartFeeValue: number;
  isMarkAsPaid?: boolean;
  isCheckedIn?: boolean;
  isCheckedOut?: boolean;
  patronId?: string;
  roomNumber?: string;
  arrivalDate?: string;
  departureDate?: string;
  packageCode?: string;
  customField1?: string;
  customField2?: string;
  customField3?: string;
  customField4?: string;
  customField5?: string;
  eformTotalCount?: number;
  eformSubmittedCount?: number;
  eformCount?: number;
  eformSubmitted?: boolean;
  vip?: string;
  playerCategoryId?: PlayerCategory;
  isTrail?: boolean;
  isWalk?: boolean;
  activityId?: string;
  isExternal: boolean;
  contactInformation?: ContactInformation[];
  playerAddress?: Address;
  daysInAdvanceCount?: number;
  scheduledTeeTimePlayerId?: number;
  time?:string;
  courseId?:number;
  greenFee?: number;
  cartFee?: number;
  scheduleDateTime?: string;
  isRetailItemsAvailable?: boolean;
  isRentalItemsAvailable?: boolean;
  isAllowPay?: boolean;
  isAllowPlayerTypeRateTypeChange?: boolean;
  isDisablePlayerTypeRateTypeChange?: boolean;
  tokenTransId?:number;
  // rateTypeId? : number;
  // useNewRateType? : boolean;
}

export interface ScheduledTeeTimePlayerFee {
  id: number;
  scheduledTeeTimePlayerId: number;
  allocationBlockId: number;
  playerTypeId: number;
  rateTypeId: number;
  greenFee: number;
  isGreenFeeNegotiable: boolean;
  cartFee: number;
  isCartFeeNegotiable: boolean;
  viewedByStarter: boolean;
  isExternal: boolean;
  scheduledTeeTimePlayerCustomFees?: any[];
}
export interface DeletePlayerEvent {
  teeSlotDetail: TeeSheetSkeletonData;
  playerDetail: TeeSheetPlayerDetails;
}

export interface ProfileImage {
  contentType: string;
  data: string;
  id: number;
  referenceId: number;
  referenceType: string;
  sequenceNo: number;
  thumbnailData: string;
}

// enum

export enum BookingSource {
  sourceOthers = 0,
  sourcerGuestBook = 1,
  golfNow = 4
}
export enum ScheduleStatus {
  open = 0,
  booked = 1,
  frostDelay = 2,
  hold = 3,
  crossOverBlock = 4,
  waitList = 5,
  tempHold = 6,
  cancelled =7,
  noShow =8
}
export enum ScheduleType {
  none = 0,
  teeTime = 1,
  crossOver = 2,
  shotgun = 3,
  squeeze = 4,
  crossOverBlock = 5
}

export enum TeeSheetView {
  Single,
  Mulitple
}

export enum DragOrigin {
  playerlist = 0,
  grid = 1
}
//filter models

export const enum filterType {
  timeRangeFilter = 1,
  allocationFilter = 2,
  additionalDetails = 3
}
export interface TimeFilter {
  startTime: Date;
  endTime: Date;
  id: number;
  startTimeAPI: string;
  endTimeAPI: string;
}

export interface TimeRange {
  from: Date,
  to: Date
}

// drag and drop models

export interface TeeSheetDragDropEvt {
  dragOrigin: number;
  dragData: TeeSheetPlayerDetails[];
  dragPositionObject: TeeSheetSkeletonData;
  dropPositionObject: TeeSheetSkeletonData;
  dragIndex: number;
  dropIndex: number;
  initialState: TeeSheetSkeletonData[];
  currentState: TeeSheetSkeletonData[];
  dropPositionRowIndex: number;
}

export interface TeeSheetModications {
  initialState: TeeSheetSkeletonData[];
  currentState: TeeSheetSkeletonData[];
}

export type TeeActionsClick = [TeeTimeActions, TeeSheetSkeletonData]




//

export interface TeeSheetTableID {
  tableID: string;
}
export interface TeeSheetCustomData {
  isMultiView: boolean;
  dragBoundary?: string;
  isShowAvailableOnly?: boolean;
  isHideCrossover?: boolean;
  isDetailView: boolean;
  isadvDetailView?: boolean;
  isOrderByHoleTime?: boolean;
  isHoleNotificationEnabled: boolean;
  isCommentNotificationEnabled: boolean;
  isActionAvailable: boolean;
  isMenuEnabledOnEllipsis: boolean;
  isBookDisabled?: boolean;
  isResetDisabled?: boolean;
  isTooltipDisabled?: boolean;
  isTabTooltip?: boolean;
  isBookHidden?: boolean;
  isResetHidden?: boolean;
  isBulkMoveEnabled?: boolean;
  isDropRestrictedOnSameSlot?: boolean;
  isLoadedinPopup?: boolean;
}


// graphical view

export interface GraphicalViewRow {
  teeSheet: Observable<TeeSheetSkeletonData[]>;
  course: Course;
  date: Date;
  id: number;
  teeSheetStore: TeeSheetGraphical
}

export interface GraphicalView {
  rows: GraphicalViewRow[];
  date: Date;
}

export interface ScheduledPlayer {
  scheduleTeeTimeId: number;
  scheduledDateTime: Date | string;
  playerId: number;
  courseId?: number;
  holeNumber?: string;
  playerTypeId: number;
  playerSlotPosition: number;
  firstName: string;
  lastName: string;
  allocationBlockId: number;
  rateTypeId: number;
  greenFee: number;
  cartFee: number;
  bookingId: string;
  deposits: Deposit[];
  playerStatus: PlayerPaymentstatus;
  playerImageReferenceId: string;
  ticketNumber: string;
  caddyTypeId: number;
  caddyId: number;
  teeTimeFormat: number;
  isBlocked: boolean;
  playersPerGroup: number;
  confirmationNumber: string;
  multiPackTransactionDetailId: number;
  teeTimeAllocationBlockId?: number;
  bookingSource?: number;
  scheduledTeeTimePlayerId?:number;
  isRetailItemsAvailable?: boolean;
  isRentalItemsAvailable?:boolean;
  tokenTransId?: number;
}

export enum TeeTimeFormat {
  None = 0,
  Tournament = 1,
  BulkTee = 2,
  GroupTee = 3,
  Individual = 4
}

export enum PlayerDaysOutResut {
  Pass,
  StartDateFail,
  EndDateFail,
}
export enum RateTypeDaysOutResult {
  Pass,
  StartDateFail,
  EndDateFail,
}

export interface GraphicalViewDragDrop {
  container: { data: TeeSheetSkeletonData };
  previousContainer: { data: TeeSheetSkeletonData };
}

export interface PlayerSlotGroupInfo {
  lastGroupIndex: number;
  lastPlayerPosition: number;
}

export type PlayerDragDropSelection = [string, TeeSheetPlayerOption[], Player]


export enum MoveRateTypeChangeSelection {
  UseSourceTeeFee = "UseSourceTeeFee",
  UseDestinationTeeFee = "UseDestinationTeeFee",
  CancelMove = "CancelMove"
}


export class TeeFeeContent {
  sourceTeeFee: string;
  destinationTeeFee: string;
  playerFirstName: string;
  playerLastName: string;
  totalFee: number;
}

export class TeeFeeGridContent{
  sourceGreenFee: string;
  sourceCartFee: string;
  destinationGreenFee: string;
  destinationCartFee: string;
  rateType: string;
  playerName:string;
  playerPosition:number;
}

export interface EFormData{
  eFormID : string;
  eFormName: string;
}

export enum CourseDetail {
  RoomType = 1,
  MinimumNights,
  EForm
}

export interface AuthorizeTable {
  id: number;
  checked : boolean;
  name: string;
  emailId: string;
  countryCode: string;
  phoneNo : number;
  amount: string;
  balanceDue: string;
  action: string;
  confirmNumber:string;
}
