import { GolfLocalization } from "../core/localization/golf-localization";
import { AlertType, RetailItemType, ScheduledTeeTimePlayerCustomFee, ScheduledTeeTimeUnPaidPlayer, SelectedProducts } from "../retail/shared/shared.modal";
import * as GlobalConst from '../retail/shared/globalsContant';
import { TransactionCustomFee } from "../retail/shared/service/common-variables.service";
import { CommonVariablesService } from 'src/app/retail/shared/service/common-variables.service';
import { Injectable } from "@angular/core";
import { GolfSessionKey } from "../shared/global.constant";
import { GolfUtilities } from "../shared/utilities/golf-utilities";
import { ButtonTypes } from "../common/enums/shared-enums";
import { AlertAction, ButtonType } from "../shared/shared-models";
import { PlayerDeposit } from "./add-deposite-modal/add-deposite-model";

@Injectable({ providedIn: 'root' })
export class TeeTimeCustomFeeUtilities {

    constructor(
        private localization: GolfLocalization
        , private commonVariablesService: CommonVariablesService
        , private _utils: GolfUtilities) {
    }

    async IsCustomFeeConfigured() {
        let isCustomFeeEnabled: boolean = false;
        let CustomFeeEnabled = sessionStorage.getItem(GolfSessionKey.IsCustomFeeEnabled);
        if (CustomFeeEnabled == '' || CustomFeeEnabled == null) {
            isCustomFeeEnabled = await this.commonVariablesService.setCustomFeeSwitch();
            CustomFeeEnabled = isCustomFeeEnabled.toString();
            sessionStorage.setItem('isCustomFeeEnabled', CustomFeeEnabled);
        }
        isCustomFeeEnabled = CustomFeeEnabled.toLowerCase() == 'true';
        return isCustomFeeEnabled;
    }

    FormCustomFeeObj(player: ScheduledTeeTimeUnPaidPlayer, lineNumber: number,
        customFeeItemDetails: any[], commonServiceSelectedProducts, isDepositPresentForPlayer: boolean = false) {
        let playerCustomFees: SelectedProducts[] = [];
        let updatedLineNumber = ++lineNumber;
        this.fixLineNumber(lineNumber, commonServiceSelectedProducts + player.scheduledTeeTimePlayerCustomFees.length, commonServiceSelectedProducts);
        if (player && player.scheduledTeeTimePlayerCustomFees?.length > 0) {
            player.scheduledTeeTimePlayerCustomFees.forEach(customFeeProd => {
                const retailItem = customFeeItemDetails.find(x => x.id === customFeeProd.linkedItemId).retailItemDetail;
                let itemName = this.localization.replacePlaceholders(this.localization.captions.shop.customFeeAddedFor, ["CustomFeeName"], [customFeeProd.customFeeName]);
                let customFeeSelectedProduct = {
                    ItemId: customFeeProd.linkedItemId,
                    id: player.playerId,
                    payeeId: player.playerId,
                    ExternalPOSItemId: retailItem.externalPOSId,
                    ItemDescription: itemName,
                    ItemType: retailItem.itemType,
                    ServiceId: 0,
                    SalesPrice: retailItem.salesPrice,
                    ProductName: itemName,
                    ProductPrice: customFeeProd.amount,
                    Noofitems: 1,
                    Discount: 0,
                    DiscountTypeId: 0,
                    DiscountPercentage: 0,
                    category: retailItem.category,
                    isGroupingKey: false,
                    isPackagedItem: false,
                    PackageItemId: 0,
                    MultiPack: false,
                    ClientMultiPackId: 0,
                    PackageGroupId: 0,
                    isOpenPricedItem: false,
                    isReturn: false,
                    isModificationRestricted: false,
                    costPrice: retailItem.costPrice,
                    marginPercentage: retailItem.marginPercentage,
                    allowEarn: retailItem.allowEarn,
                    discountComments: '',
                    discountReason: 0,
                    clientName: player.playerName,
                    clientId: player.playerId, //for golf it is playerid
                    LineNumber: updatedLineNumber,
                    linkedItemLineNumber: player?.greenFeeRetailItem?.lineNumber ?? 1,
                    totalAmount: 0,
                    retailItemType: RetailItemType.CustomFee,
                    customFee: this.transcustomFeeMapper(customFeeProd),
                    sourceTypeId: player.playerId,
                    sourceType:  GlobalConst.CustomFeeSourceType.TeeTime
                } as SelectedProducts;
                playerCustomFees.push(customFeeSelectedProduct);
            });
        }
        return playerCustomFees;
    }

    fixLineNumber(existingLineNumber, endingNumber, commonServiceSelectedProducts) {
        for (let i = commonServiceSelectedProducts.length; i > existingLineNumber; i--) {
            let data = commonServiceSelectedProducts.find(x => x.LineNumber === i);
            if (data) {
                data.LineNumber = endingNumber--;
            }
        }
    }

    transcustomFeeMapper(customfeeconfig) {
        return {
            id: 0,
            customFeeId: customfeeconfig.customFeeId,
            isOverWritten: customfeeconfig.isOverWritten ?? false,
            value: customfeeconfig.customFeeAmountAtBooking,
            isPercentage: customfeeconfig.isPercentage,
            scheduledTeeTimePlayerCustomFeeId: customfeeconfig.id,
            scheduledTeeTimePlayerFeeId: customfeeconfig.scheduledTeeTimePlayerFeeId,
            name: '',
            transactionDetailId: 0,
            transactionId: 0,
        } as TransactionCustomFee
    }

    async applyCustomFeeForEntireBulkTeeTime() 
    {
        let applyCustomFeeForBulkTeetime: boolean = false;
        this._utils.showAlertWithCustomWidth(this.localization.captions.teetime.customFeeAppliedForBulk,AlertType.Info, ButtonType.YesNo, 300)
        .afterClosed().toPromise().then(o => {
            if (o == AlertAction.YES) {
                return true;
            }
        });
        return applyCustomFeeForBulkTeetime;
    }

    GetCustomFeeForPlayer(player: ScheduledTeeTimeUnPaidPlayer)
    {
        let customFee: number = 0;
        player.scheduledTeeTimePlayerCustomFees.forEach(custFee => { customFee += custFee.amount; });
        return customFee;
    }

    CalculateCustomFee(totalAmount:number, customFeeConfig: ScheduledTeeTimePlayerCustomFee){
        return customFeeConfig.isPercentage ? totalAmount * (customFeeConfig.customFeeAmount/100) : customFeeConfig.customFeeAmount;
    }

    CalculateCustomFeeAtBooking(customFeeConfig: ScheduledTeeTimePlayerCustomFee){
        return customFeeConfig.customFeeAmountAtBooking;
    }
}