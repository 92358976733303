import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PlayerCardInfo, CardMenu, MoreMenuResult } from '../../shared-models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { UserAccessBusiness } from '../../data-services/authentication/useraccess.business';
import { UserAccessBreakPoints } from '../../constants/useraccess.constants';
import { GuestService } from 'src/app/guest/guest.service';
import { UserAccessModel } from 'src/app/common/dataservices/authentication/useraccess-model.model';
import { RetailBussinessService } from '../../retail.bussiness';
import { PlayerPaymentstatus } from 'src/app/tee-time/search/search-model';
import { GolfUtilities } from '../../utilities/golf-utilities';

@Component({
  selector: 'app-player-info-card',
  templateUrl: './player-info-card.component.html',
  styleUrls: ['./player-info-card.component.scss']
})
export class PlayerInfoCardComponent implements OnInit {
  currentPlayer: PlayerCardInfo;
  currentPlayerIdx: number;
  isMenuEditable = false;
  @Input() matMenu: CardMenu[] = [];
  globCaption:string;
  captions: any;
  lblBlocked: string;
  players: PlayerCardInfo[];
  userAccessResult: UserAccessModel.BreakPointResult;
  isPatronIdAvailable = false;
  noShow = PlayerPaymentstatus.noShow;
  @Input() isFromTournament: boolean = false;
  @Input() isEdit: boolean = false;
  /**To disable if user have view breakpoint true / historical date */
  @Input() isDisable: boolean = false;
  @Input('players')
  set playersValue(value: PlayerCardInfo[]) {
    if (value && value.length > 0) {
      this.players = value;
    }
    else if (value == null || value.length == 0)
      this.players = [];
  };
  @Output() PayForPlayer: EventEmitter<PlayerCardInfo> = new EventEmitter();
  @Output() CopyPlayer: EventEmitter<PlayerCardInfo> = new EventEmitter();
  @Output() MorePlayerOption: EventEmitter<MoreMenuResult> = new EventEmitter();
  payEnabled = true;
  @Input('payIconEnable')
  set payEnable(value) {
    this.payEnabled = value;
  }

  constructor(private _localization: GolfLocalization, private _userAccessBusiness: UserAccessBusiness, private _guestService: GuestService, private _retailBusinessService: RetailBussinessService,
    private utilities: GolfUtilities) {
  }

  ngOnInit() {
    this.globCaption = this._localization.captions.teetime;
    this.captions = this._localization.captions.teetime.playerDetails;
    this.lblBlocked = this._localization.captions.teetime.lblBlocked;
    
    console.log("matMenu", this.matMenu)
  }

  trackByFn(index, item) {
    return index;
  }

  setCurrentPlayer(arg, idx) {
    this.currentPlayerIdx = idx;
    this.currentPlayer = arg;
  }
  enableDisableMenuOptions() {
    this.matMenu.forEach(x => {
      x.isDisabled = (x.id === 0) ? false : true;
    });
  }

  async payForPlayer(arg: PlayerCardInfo) {
    this.utilities.ToggleLoader(true);
    this.payEnabled = false;
    this.userAccessResult = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.SHOP, true);
    if (!this.userAccessResult || (this.userAccessResult && !this.userAccessResult.isAllow && !this.userAccessResult.isViewOnly)) {
      return;
    }
    if (this.userAccessResult && this.userAccessResult.isAllow) {
      this.PayForPlayer.emit(arg);
    }
  }

  async copyPlayer(arg, idx) {
    if (arg.id > 0) {
      this.userAccessResult = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.Copy, true);
      if (this.userAccessResult && this.userAccessResult.isAllow) {
        arg.index = idx;
        this.CopyPlayer.emit(arg);
      }
      return;
    }
    arg.index = idx;
    this.CopyPlayer.emit(arg);
  }

  moreOption(cardMenu: CardMenu) {
    // console.log("players",this.players);
    const obj: MoreMenuResult = {
      actionMenu: cardMenu,
      playerInfo: this.currentPlayer,
      playerIndex: this.currentPlayerIdx
    };
    this.MorePlayerOption.emit(obj);
  }
  moreOptionList(cardMenu: CardMenu, player, idx) {
    const obj: MoreMenuResult = {
      actionMenu: cardMenu,
      playerInfo: player,
      playerIndex: idx
    };
    this.MorePlayerOption.emit(obj);
  }

  playerWorthDetails(event, patronId) {
    this._guestService.openDialogPopup(patronId);
  }
}

