<section class="pop-container">
  <div class="pop-head">
    <label class="inner-header pl-4">{{globalCaptions.drp_txt_selectCard}}</label>
      <i class='icon-close  ag_cursor--pointer' aria-hidden="true"
      (click)="onCancel()">&nbsp;</i>
  </div>
  <div class="pop-contents radio-class">
    <section class=" h-100">
      <form [formGroup]="CreditCardFormGroup" class="formheight" autocomplete="off">
        <mat-dialog-content class="p-4 m-0 overflow--hidden">
          <div class="mt-1 mb-3">
            <mat-form-field class="ag_form-control--lg" [floatLabel]="floatLabel">
              <mat-select placeholder="{{globalCaptions.drp_txt_selectCard}}" (selectionChange)="CreditCardChange($event)"
                [(value)]="defaultCard">
                <mat-option class="creditcard-option" [value]="cc.value" *ngFor="let cc of CardOnFiles" [matTooltip]="cc.viewValue">
                  {{cc.viewValue}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="charges-details-list" *ngIf="selectedCardSurcharge?.surcharge > 0">        
              <div class="charges-details">
                <label class="LW12 label-color base-charges-label">{{globalCaptions.lbl_amount}}  : {{localization.localizeCurrency(selectedCardSurcharge.requestAmount)}}</label>
              </div>     
              <div class="charges-details" *ngIf="this.includeSurchage">
                <label class="LW12 label-color base-charges-label">{{globalCaptions.shop.lbl_Surcharge}}  : {{localization.localizeCurrency(selectedCardSurcharge.surcharge)}}</label>   
              </div>
              <div class="charges-details" *ngIf="this.includeSurchage">
                <label class="LW12 label-color base-charges-label">{{globalCaptions.lbl_totalAmount}} : {{localization.localizeCurrency(selectedCardSurcharge.totalAmount)}}</label>
              </div>
              <mat-checkbox [attr.automationId]="'Chk_CreditCardFormGroup_includeSurchage'" class="ag_display--inblock cctoken_popup_surcharge_checkbox" formControlName="includeSurchage" (change) = "onIncludeSurchageSelect($event)">
                {{globalCaptions.setting.include +' '+ globalCaptions.lbl_surcharge}}</mat-checkbox>
            </div>
          </div>
        </mat-dialog-content>
        <mat-dialog-actions class="pop-actions pl-4 m-0">
          <div class="pop-button">
            <button class="body-bgcolor whitecolor button_valid" mat-button color="primary"
              (click)="onSave()">{{globalCaptions.btn_proceed}}
            </button>
            <button mat-button class="ml-2 spa-button-cancel " (click)="onCancel()">{{captions.cancel}}</button>
          </div>
        </mat-dialog-actions>
      </form>
    </section>
  </div>
</section>
