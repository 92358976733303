import { Injectable } from '@angular/core';
import { GolfManagementCommunication } from 'src/app/shared/communication/services/golfmanagement.service';
import { CourseRateType, RateSetupData } from 'src/app/settings/rate-setup/rate-setup.model';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Injectable({providedIn: 'root'})
export class RateSetupDataService {
    constructor(private _golfManagementCommunication: GolfManagementCommunication, private _localization: GolfLocalization) { }

    GetDays() {

        return this._localization.getDaysArray().filter(x => x.code !== 'All')
            .map(x => ({ id: x.id, name: x.short, description: x.code, value: x.longCode }));
      
        
    }

    public async getRateCourseTeeFees(): Promise<RateSetupData[]> {
        return await this._golfManagementCommunication.getPromise<RateSetupData[]>(
            { route: GolfApiRoute.GetRateCourseTeeFees });
        
    }

    public async getRateCourseTeeFeesByDateFilter(fromDate: string, toDate: string): Promise<RateSetupData[]> {
        return await this._golfManagementCommunication.getPromise<RateSetupData[]>(
            { route: GolfApiRoute.GetRateCourseTeeFeesByDateFilter, uriParams: { "fromDate": fromDate, "toDate": toDate } });
       
    }

    public async getRateCourseTeeFeesByDateCourseRateTypeFilter(fromDate: string, toDate: string,courseRateType : CourseRateType): Promise<RateSetupData[]> {
        return await this._golfManagementCommunication.postPromise<RateSetupData[]>(
            { route: GolfApiRoute.GetRateCourseTeeFeesByDateCourseRateTypeFilter, uriParams: { "fromDate": fromDate, "toDate": toDate },body : courseRateType });
       
    }
    public async getRateCourseTeeFee(id: number): Promise<RateSetupData> {
        return  await this._golfManagementCommunication.getPromise<RateSetupData>(
            { route: GolfApiRoute.GetRateCourseTeeFee, uriParams: { "id": id } });
       
    }

    public createRateCourseTeeFee(rateDetail: RateSetupData): Promise<RateSetupData[]> {
        return this._golfManagementCommunication.postPromise<RateSetupData[]>({ route: GolfApiRoute.CreateRateMultiCourseTeeFee, body: rateDetail }, false);
        
    }

    public updateRateCourseTeeFee(rateDetail: RateSetupData): Promise<RateSetupData[]> {
        return this._golfManagementCommunication.putPromise<RateSetupData[]>({ route: GolfApiRoute.UpdateRateCourseTeeFee, body: rateDetail }, false);
       
    }

   
    public async deleteMultipleRateSetupData(ids: number[]): Promise<RateSetupData[]> {
        return await this._golfManagementCommunication.deletePromise<RateSetupData[]>(
            { route: GolfApiRoute.MultipleDeleteRateCourseTeeFee, body: ids });
      
    }
}
