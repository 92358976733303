import { Injectable } from '@angular/core';
import { TeeSheetBulk } from 'src/app/tee-time/shared/tee-sheet/tee-sheet.bulk';
import { TeeSheetSkeletonData, TeeSheetPlayerDetails, ScheduleStatus, ScheduledPlayer, TeeTimeFormat, ScheduleType, PlayerRateType } from 'src/app/shared/models/teesheet.form.models';
import _, { cloneDeep } from 'lodash';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { AllocationCode, PlayerTeeTimeSlot, TeeSlotDetails, Bulkstatus, status } from '../tee-sheet/bulk-tee-time/bulk-tee-time.model';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { PlayerPaymentstatus } from '../search/search-model';
import { TeeTimeTempHold } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { TeeTimeService } from 'src/app/tee-time-actions/teetime/tee-time.service';
import { MAX_PLAYERS_PER_SLOT, TempHoldStatus } from 'src/app/shared/global.constant';
import { TeeTimesActionBusiness } from './tee-action.business';
import { RateType } from 'src/app/tee-time-actions/teetime/player-details/player-info.model';



@Injectable()
export class TeeTimesBulkGroupBusiness {
  constructor(private _TeeSheetBulk: TeeSheetBulk, private _localization: GolfLocalization, private _utils: GolfUtilities,
    private teeTimeService: TeeTimeService, private teeTimesActionBusiness: TeeTimesActionBusiness) {
  }

  public buildTeeSlots(teeSheetdata: TeeSheetSkeletonData[], playerDetails: TeeSheetPlayerDetails[], startDateTime: Date | string, playersPerGroup: number = 4, isBulkMove: boolean, hole: string,isFromMove:boolean = false): TeeSlotDetails {
    this.validPlayers = [];
    let TeeSlotDetails = {} as TeeSlotDetails;
    let teeSlots: TeeSheetSkeletonData[] = [];
    let startDateIndex = teeSheetdata.findIndex(x => x.time == startDateTime
      && x.originalHoleNumber === hole);
    const startIndex = startDateIndex;
    let temp = 0;
    let noofSlots: number = 1;
    let isBuildTeeSheetAllowed: boolean = (isBulkMove) ? true : this.isPlayersAvailableInStartTime(teeSheetdata, startDateTime, hole); // check whether players are available in first slot
    if (startDateIndex >= 0 && isBuildTeeSheetAllowed) {
      while (noofSlots != 0) {
        if (temp < playerDetails.length) {
          let element = teeSheetdata[startDateIndex];
          if (element && (startIndex === startDateIndex || ((element.playerDetail && element.playerDetail.length === 0) || 
          (element.playerDetail && element.playerDetail.length > 0 && playerDetails[0].bookingId == element.playerDetail[0].bookingId && playerDetails[0].confirmationNumber == element.playerDetail[0].confirmationNumber && isFromMove)))) {
           
            if (element.status.scheduleStatus !== ScheduleStatus.frostDelay && element.status.scheduleStatus !== ScheduleStatus.hold
              && element.status.scheduleStatus !== ScheduleStatus.tempHold && element.type !== ScheduleType.crossOverBlock 
              && (!element.allocation || (element.allocation && !element.allocation.closed) || (element.playerDetail && element.playerDetail.length > 0)))  { //skip closed allocation code
              if(element.playerDetail && element.playerDetail.length > 0 && playerDetails[0].bookingId == element.playerDetail[0].bookingId && playerDetails[0].confirmationNumber == element.playerDetail[0].confirmationNumber && isFromMove)
              {
                element.playerDetail = [];
              }
              let availableSlots: number = (temp === 0) ? this.getAvailablePlayerSlots(element, playersPerGroup, (startIndex === startDateIndex)) : playersPerGroup;
              teeSlots.push(element);
              temp = temp + availableSlots;
            } 
            startDateIndex = startDateIndex + 1;
          }
          else {
            teeSlots = [];
            TeeSlotDetails = this.getTeeSlotDetails(teeSlots, (playerDetails.length - temp), startDateIndex, playersPerGroup);
            return TeeSlotDetails;
          }
        }
        else {
          noofSlots = 0;
        }
      }
      TeeSlotDetails = this.getTeeSlotDetails(teeSlots, (playerDetails.length - temp), startDateIndex, playersPerGroup);
    }
    return TeeSlotDetails;
  }

  public isInitialSlotBookable(teeSheetdata: TeeSheetSkeletonData[], courseId: number, hole: string, date: Date | string, time: string) {
    let startDateTime = this._localization.ConvertDateToISODateTime(this._localization.AddTimeToDate(date.toString(), this._localization.TimeToDate(time)));
    let startSlotIndex = teeSheetdata.findIndex(x => x.time == startDateTime
      && x.originalHoleNumber === hole && x.course.id == courseId);
    if (startSlotIndex > -1) {
      return teeSheetdata[startSlotIndex].playerDetail.length!=MAX_PLAYERS_PER_SLOT && teeSheetdata[startSlotIndex].type != ScheduleType.crossOverBlock;
    }
    return false;
  }

  public getTeeSlotDetails(teeSlots: TeeSheetSkeletonData[], outstandingPlayersCount: number, lastComputedIndex: number, playersPerGroup: number): TeeSlotDetails {
    let TeeSlotDetails = {} as TeeSlotDetails;
    TeeSlotDetails.TeeSlots = teeSlots;
    TeeSlotDetails.outstandingPlayersCount = outstandingPlayersCount;
    TeeSlotDetails.autoSqueezeIndex = lastComputedIndex - 1;
    TeeSlotDetails.playersPerGroup = playersPerGroup;
    return TeeSlotDetails;
  }


  public getStatus(teeSlotDetails: TeeSlotDetails, teeSheetdata: TeeSheetSkeletonData[]): Bulkstatus {
    if (!(_.isEmpty(teeSlotDetails))) {
      const teeSlots = teeSlotDetails.TeeSlots;
      if (teeSlots === [] || teeSlots && teeSlots.length === 0 || teeSlots.filter((x, index) => index != 0 && x.playerDetail.length > 0).length > 0) { // Tee Slots not available. Reached End of the time slot           
        const maxSqueezeMins = this.getSqueezeMins(teeSlotDetails, teeSheetdata);
        if (maxSqueezeMins <= 0) {
          return this.getBulkStatus(0, status.failure, teeSlotDetails);
        }
        else {
          return this.getBulkStatus(maxSqueezeMins, status.squeeze, teeSlotDetails);
        }
      }
      return this.getBulkStatus(0, status.success, teeSlotDetails);
    }
    else {
      return this.getBulkStatus(0, status.failure, teeSlotDetails);
    }
  }

  private getBulkStatus(maxSqueezeMins: number, status: status, teeSlotDetails: TeeSlotDetails): Bulkstatus {
    let bulkstatus = {} as Bulkstatus;
    bulkstatus.maxSqueezeMins = maxSqueezeMins;
    bulkstatus.status = status;
    bulkstatus.teeSlotDetails = teeSlotDetails;
    return bulkstatus;
  }

  public updateTeeSheetGridContent(modifiedTeeSheetSkeletonData: TeeSheetSkeletonData[]) {
    this._TeeSheetBulk.addTeeSlots(modifiedTeeSheetSkeletonData);
  }
  
  private validPlayers: PlayerTeeTimeSlot[] = [];

  public getSqueezeMins(teeSlotDetails: TeeSlotDetails, teeSheetdata: TeeSheetSkeletonData[]): number {
    if (teeSheetdata[teeSlotDetails.autoSqueezeIndex] && teeSheetdata[teeSlotDetails.autoSqueezeIndex + 1] && teeSheetdata[teeSlotDetails.autoSqueezeIndex].time && teeSheetdata[teeSlotDetails.autoSqueezeIndex + 1].time) {
      const startDate = this._utils.getDate(teeSheetdata[teeSlotDetails.autoSqueezeIndex].time);
      const endDate = this._utils.getDate(teeSheetdata[teeSlotDetails.autoSqueezeIndex + 1].time);
      let diffMins: number = Math.abs(((startDate.getTime() - endDate.getTime()) / 60000)) - 1;
      const requiredSlots: number = Math.ceil(teeSlotDetails.outstandingPlayersCount / teeSlotDetails.playersPerGroup);
      return Math.floor(diffMins / requiredSlots);       
    }
    else {
      return 0;
    }
  }

  private isPlayersAvailableInStartTime(teeSheetdata: TeeSheetSkeletonData[], startDateTime: Date | string, hole: string): boolean {
    let players = teeSheetdata.find(x => x.time == startDateTime && x.originalHoleNumber === hole).playerDetail;
    return players.length != MAX_PLAYERS_PER_SLOT;
  }

  public getSqueezeSlots(currentTeeSheetSkeleton: TeeSheetSkeletonData[], bulkstatus: Bulkstatus, squeezeMins: number): TeeSheetSkeletonData[] {
    let squeezeTeeSheetSkeletonDatalst: TeeSheetSkeletonData[] = [];
    if (!(_.isEmpty(bulkstatus))) {
      let sourceSlot = _.cloneDeep(currentTeeSheetSkeleton[bulkstatus.teeSlotDetails.autoSqueezeIndex]);     
      if (sourceSlot) {
        let noofSlots: number = Math.ceil(bulkstatus.teeSlotDetails.outstandingPlayersCount / bulkstatus.teeSlotDetails.playersPerGroup);
        for (let i = 1; i <= noofSlots; i++) {
          let newTime = this._localization.AddMins(this._localization.getDate(sourceSlot.time), squeezeMins);
          let squeezeTeeSheetSkeletonData = this._TeeSheetBulk.createSlot(sourceSlot, newTime);
          squeezeTeeSheetSkeletonDatalst.push(squeezeTeeSheetSkeletonData);
          sourceSlot.time = squeezeTeeSheetSkeletonData.time;
        }
      }
    }
    return squeezeTeeSheetSkeletonDatalst;
  }

  public addNewSlots(squeezedTeeSlots: TeeSheetSkeletonData[], initialTeeSlots: TeeSheetSkeletonData[]): TeeSheetSkeletonData[] {
    let initial: TeeSheetSkeletonData[] = _.cloneDeep(initialTeeSlots);
    squeezedTeeSlots.forEach(slot => {
      initial.push(slot);
    });

    initial = _.cloneDeep(initial.sort((left: TeeSheetSkeletonData, right: TeeSheetSkeletonData) =>
      this.sortByTeeTime(left, right)));
    return initial;
  }

  private sortByTeeTime(left: TeeSheetSkeletonData, right: TeeSheetSkeletonData): number {
    const isoDateToTime = (date: string) => this._localization.getDate(date).getTime();  // func to convert API dateTime to time in milliseconds
    return isoDateToTime(left.time) - isoDateToTime(right.time);
  }

  public buildTeeSheetSkeletonData(currentTeeSheetSkeleton: TeeSheetSkeletonData[], teeSlots: TeeSheetSkeletonData[], playerDetails: TeeSheetPlayerDetails[], playersPerGroup: number, allocation: AllocationCode, isRemovable: boolean, squeezedSlots: TeeSheetSkeletonData[]): TeeSheetSkeletonData[] {
    if (teeSlots && teeSlots.length > 0) {
      let modifiedTeeSheetSkeletonData = this.getUpdatedSkeletonArray(teeSlots, playerDetails, playersPerGroup, allocation, isRemovable, squeezedSlots);
      if (modifiedTeeSheetSkeletonData && modifiedTeeSheetSkeletonData.length > 0) {
        return this.appendWithExitingSlots(modifiedTeeSheetSkeletonData, currentTeeSheetSkeleton);
      }
    }
    return [];
  }

  private appendWithExitingSlots(modifiedTeeSlots: TeeSheetSkeletonData[], initialTeeSlots: TeeSheetSkeletonData[]): TeeSheetSkeletonData[] {
    const initial: TeeSheetSkeletonData[] = _.cloneDeep(initialTeeSlots);
    initial.forEach(slot => {
      const modified: TeeSheetSkeletonData = modifiedTeeSlots.find(s => s.id === slot.id);
      if (modified) {
        slot.playerDetail = modified.playerDetail;
        slot.allocation = modified.allocation;
        slot.slotBackgroundInfo.colorCode = modified.allocation.colorCode;
      }
    });
    return initial;
  }

  private getAvailablePlayerSlots(data: TeeSheetSkeletonData, playersPerGroup: number, firstIndex : boolean) {
    if (data.playerDetail) {
      let isPlayerSlotAvailableinFirstIndex : boolean = false;
      if(firstIndex){
        isPlayerSlotAvailableinFirstIndex = (MAX_PLAYERS_PER_SLOT - data.playerDetail.length) >= playersPerGroup;
      }
      let playerSlotChecker = (isPlayerSlotAvailableinFirstIndex ? playersPerGroup : (MAX_PLAYERS_PER_SLOT - data.playerDetail.length));
      return (firstIndex) ? playerSlotChecker : playersPerGroup - data.playerDetail.length;
    }
    else {
      return playersPerGroup;
    }
  }

  private updateAllocationBlockCode(element : TeeSheetSkeletonData ,allocation : AllocationCode){
      element.allocation.id = allocation.id;
      element.allocation.colorCode = allocation.colorCode;
      element.allocation.name = allocation.allocationBlockName;
  }

  private getUpdatedSkeletonArray(selectedSlots: TeeSheetSkeletonData[], playerDetails: TeeSheetPlayerDetails[], playersPerGroup: number, allocation: AllocationCode, isRemovable: boolean, squeezedSlots: TeeSheetSkeletonData[]): TeeSheetSkeletonData[] {
    let updatedData: TeeSheetSkeletonData[] = [];
    let totalPlayers: number = playerDetails.length;
    let temp: number = 0;
    this.validPlayers = [];
    selectedSlots.forEach((element, index) => {
      let isSqueezed: boolean = false;      
      this.updateAllocationBlockCode(element, allocation);
      let availablePlayerSlots: number = this.getAvailablePlayerSlots(element, playersPerGroup, (index == 0));
      for (let i = 0; i < availablePlayerSlots; i++) {
        let teeSheetPlayerDetail :TeeSheetPlayerDetails;
        let playerTeeTimeSlot = {} as PlayerTeeTimeSlot;
        if (temp < totalPlayers) {
          teeSheetPlayerDetail = _.cloneDeep(playerDetails[temp]);
          if (isRemovable) {
            teeSheetPlayerDetail['isRemovable'] = true;
          }
          let pos = element.playerDetail.length + 1;
          let positionArr = [1,2,3,4];
          let bookedPosSlotsArr: any[] = cloneDeep(element.playerDetail.filter(x => x.playPos));
          let bookedSlotNos: number[] = bookedPosSlotsArr.map(x => x.playPos);
          console.log(bookedSlotNos);
          let availableSlotnNos: number[] = positionArr.filter(x => !bookedSlotNos.includes(x));  
          console.log(availableSlotnNos);  
          let existingPos = bookedSlotNos.includes(pos);
          if (existingPos) {
           teeSheetPlayerDetail.playPos = availableSlotnNos[0];
          } else {
            teeSheetPlayerDetail.playPos = pos;
          }
          // teeSheetPlayerDetail.playPos = pos;          
          element.playerDetail.push(teeSheetPlayerDetail);
          temp = temp + 1;
          isSqueezed = (squeezedSlots && squeezedSlots.length > 0) ? (this.checkSqueeze(squeezedSlots, element)) : false;
          playerTeeTimeSlot = this.mapPlayerData(element, teeSheetPlayerDetail, isSqueezed);
          if (playerTeeTimeSlot) {
            this.validPlayers.push(playerTeeTimeSlot);
          }
        }
      }
    });
    updatedData = selectedSlots;
    return updatedData;
  }

  public checkSqueeze(squeezedSlots: TeeSheetSkeletonData[], element: TeeSheetSkeletonData): boolean {
    let isSqueezed: boolean = false;
    if (squeezedSlots && squeezedSlots.length > 0) {
      const newSlot = squeezedSlots.filter(x => x.time === element.time && x.course.id === element.course.id &&
        x.originalHoleNumber === element.originalHoleNumber);
      if (newSlot && newSlot.length > 0) {
        isSqueezed = true;
      }
    }
    return isSqueezed;
  }


  public mapPlayerData(selectedSlot: TeeSheetSkeletonData, draggedPlayer: TeeSheetPlayerDetails, isSqueezed: boolean): PlayerTeeTimeSlot {
    return {
      courseId: selectedSlot.course.id,
      scheduledDateTime: selectedSlot.time,
      playerId: draggedPlayer.playerId,
      playerSlotPosition: draggedPlayer.playPos,
      holeNumber: selectedSlot.hole,
      originalHoleNumber : selectedSlot.originalHoleNumber,
      destinationScheduledTeeTimeId: selectedSlot.scheduledTeeTimeId ? selectedSlot.scheduledTeeTimeId : 0,
      isValid: true,
      isDestinationSlotRateChanged: false,
      sourceScheduledTeeTimeId: draggedPlayer.sourceScheduledTeeTimeId,
      isSqueezed: isSqueezed,
      playerTypeId : draggedPlayer.playerTypeId,
      rateTypeId : 0,
      useNewRateType : false,
      multiPackTransactionDetailId : draggedPlayer.multiPackTransactionDetailId,
      multiPackGreenFeeValue : draggedPlayer.multiPackGreenFeeValue,
      multiPackCartFeeValue : draggedPlayer.multiPackCartFeeValue
    } as PlayerTeeTimeSlot;
  }

  public getInitialTeeTimes(): TeeSheetSkeletonData[] {
    return this._TeeSheetBulk.getInitialTeeSlots();
  }

  async CreateOrReleaseTempHolds(playerTeeTimeSlots: PlayerTeeTimeSlot[], status: TempHoldStatus): Promise<boolean> {
    return await this.teeTimesActionBusiness.tempHoldTeeTimes(this.MapToTeeTimeTempHoldModel(_.uniqBy(playerTeeTimeSlots, v => [v.scheduledDateTime, v.courseId, v.originalHoleNumber].join())), status);
  }

  private MapToTeeTimeTempHoldModel(playerTeeTimeSlots: PlayerTeeTimeSlot[]): TeeTimeTempHold[] {
    if (playerTeeTimeSlots && playerTeeTimeSlots.length > 0) {
      return playerTeeTimeSlots.map(o => {
        return {
          id: 0,
          courseId: o.courseId,
          scheduleDateTime: o.scheduledDateTime,
          holeNumber: o.holeNumber,
          releaseDateTime: o.scheduledDateTime,
          createdOn: o.scheduledDateTime,
          createdBy: 0,
          originalHoleNumber : o.originalHoleNumber
        } as TeeTimeTempHold;
      });
    }
    return [];
  }

  public getPlayersList(): PlayerTeeTimeSlot[] {
    return this.validPlayers;
  }

  public mapToPlayerDetails(playersList: ScheduledPlayer[], rateTypes: RateType[]): TeeSheetPlayerDetails[] {
    return playersList.map(o => {
      return {
        isDragDisabled: false,
        playPos: o.playerSlotPosition,
        firstName: o.firstName,
        lastName: o.lastName,
        gender: '',
        amountPaid: 0,
        memberNumber: '',
        playerComment: '',
        tournamentId: 0,
        playerId: o.playerId,
        bagNumber: 0,
        fullName:this._utils.formatGuestName(o.firstName ,o.lastName),
        deposits: null,
        transactionId: 0,
        playerStatus: PlayerPaymentstatus.unPaid,
        groupId: 0,
        bookingId: o.bookingId,
        playersPerGroup: 4,
        teeTimeFormat: TeeTimeFormat.BulkTee,
        sourceScheduledTeeTimeId: o.scheduleTeeTimeId,
        scheduledDateTime: o.scheduledDateTime,
        allocationBlockId : o.allocationBlockId,
        playerTypeId : o.playerTypeId,
        scheduleTeeTimeId : o.scheduleTeeTimeId,
        confirmationNumber : o.confirmationNumber,
        multiPackTransactionDetailId  : o.multiPackTransactionDetailId,
        rateType: this.assignRateType(o.rateTypeId, rateTypes),
        greenFee:o.greenFee,
        cartFee:o.cartFee,
        isRentalItemsAvailable: o.isRentalItemsAvailable,
        isRetailItemsAvailable: o.isRetailItemsAvailable,
        scheduledTeeTimePlayerId: o.scheduledTeeTimePlayerId,
        courseId: o.courseId
      } as TeeSheetPlayerDetails;
    });
  }
  private assignRateType(
    id: number,
    allActiveRateType: RateType[]
  ): PlayerRateType {
    let rateType: PlayerRateType = {
      id: 0,
      name: "",
      daysOutStart: 0,
      daysOutEnd: 0,
    };
    const _type = allActiveRateType.find((r) => r.id === id);
    if (_type) {
      rateType = {
        id: id,
        name: _type.type,
        daysOutStart: _type.daysOutStart,
        daysOutEnd: _type.daysOutEnd,
      };
    }
    return rateType;
  }


}
