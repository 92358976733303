<section class="Errorpop-container">
  <div class="pop-head">
    <label class="inner-header pl-4 text-uppercase">{{data.headername}}</label>
    <i  aria-hidden="true" *ngIf="data.closebool" class="material-icons inner-close float-md-right cursPointer"
      (click)="DialogClose('e')">close</i>
  </div>
  <div class="pop-contents">
    <form name="Error" autocomplete="off" [formGroup]="FormGrp">
      <mat-dialog-content>
        <div class="pt-3 pb-3 content"
          *ngIf="data.Type != 'OPENPRICE' && data.Type != 'SCALEDITEM' && data.Type != 'BC' && data.Type != 'RedeemRounds' && data.Type != 'CMSPin'"
          [innerHTML]="datRec"></div>
        <div class="pt-3 pb-3 openitem-price" *ngIf="data.Type == 'OPENPRICE'">
          <div class="w-100 itemdetails">
            <div class="item LW14 ">
             
              <div>                
                <mat-form-field class="LW14 d-block mt-4 mb-4 price" [floatLabel]="floatLabel">
                  <input matInput class = ""
                    [placeholder]="captions.ItemName"
                    formControlName="ItemName" name="ItemName" #ItemName maxlength="50" required>
                    <mat-error *ngIf="FormGrp.get('ItemName').hasError('required')">
                      {{captions.ItemNameRequiredMsg}} 
                    </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="quantity LW14">
              <div>{{captions.Quantity}}</div>
              <div>{{data.quantity}}</div>
            </div>
          </div>
          <mat-form-field class="LW14 d-block mt-4 mb-4 price" [floatLabel]="floatLabel">
            <input matInput required
              placeholder="{{localization.captions.retailsetup.Price+' '+ '(' + localization.currencySymbol + ')'}}"
              formControlName="ShopPrice" RetailCurrencyFormatter name="price" #ShopPrice>
              <mat-error *ngIf="FormGrp.get('ShopPrice').hasError('required')">
                {{captions.MissingPrice}} 
              </mat-error>
          </mat-form-field>
        </div>
        <div class="pt-3 pb-3 openitem-price" *ngIf="data.Type == 'ENTERCOMMENT'">
          <div class="w-100 itemdetails">
            <div class="item LW14 text-uppercase">
              <div>{{captions.ItemName}}</div>
              <div>{{data.data.itemDescription}}</div>
            </div>
            <div class="quantity LW14">
              <div>{{captions.Quantity}}</div>
              <div>{{data.quantity}}</div>
            </div>
          </div>
          <mat-form-field class="LW14 d-block mt-4 mb-4 price" [floatLabel]="floatLabel">
            <input matInput
              placeholder="{{localization.captions.shop.Comment}}"
              formControlName="ItemComment" name="ItemComment" #ItemComment maxLength="50">
          </mat-form-field>
        </div>
        <div class="pt-3 pb-3 openitem-price" *ngIf="data.Type == 'SCALEDITEM'">
          <div class="w-100 itemdetails">
            <div class="item">
              <div class="LW12">{{captions.ItemName}}</div>
              <div class="LW16 item-color">{{data.data.title}}</div>
            </div>
            <div class="quantity">
              <div class="LW12">{{captions.UnitofMeasure}}</div>
              <div class="LW16 item-color">{{data.quantity}}</div>
            </div>
          </div>
          <mat-form-field class="LW14 d-block mt-4 mb-4 price" [floatLabel]="floatLabel">
            <input inputtype="nonnegative,decimal,roundoff2" LimitExceed numUpto='9999' matInput
              placeholder="{{captions.EnterUnit}}" formControlName="ScaledUnit" name="unit">
          </mat-form-field>
        </div>
        <mat-checkbox *ngIf="IsConfirmationPopup" formControlName="HideDialog">{{captions.HidethisDialog}}
        </mat-checkbox>

        <div class="pt-3 pb-3" *ngIf="data.Type == 'BC'">
          <mat-form-field class="LW14 d-block mt-4 mb-4 price w-50" [floatLabel]="floatLabel">
            <input matInput placeholder="{{captions.EnterStartingLablePosition}}" formControlName="Barcodes"
              name="labelposition">
          </mat-form-field>
        </div>

        <div class="pb-3" *ngIf="data.Type == 'RedeemRounds'">
          <mat-form-field class="LW14 d-block mt-4 mb-4 price w-50" [floatLabel]="floatLabel">
            <input autofocus type="password" numMaxlength minlength="4" maxlength="4" matInput #Membershippin
              placeholder="{{captions.Membershippin}}" formControlName="Membershippin" name="Membershippin" required>
            <mat-error *ngIf="FormGrp.get('Membershippin').hasError('required')">
              {{captions.ErrorMembershippin}}
            </mat-error>
          </mat-form-field>
        </div>
        <!-- CMS Patron PIN -->
        <div class="pb-3" *ngIf="data.Type == 'CMSPin'">
          <mat-form-field class="LW14 d-block mt-4 mb-4 price w-50" [floatLabel]="floatLabel">
            <input type="password" (paste)="$event.preventDefault()" minlength="4" maxlength="10" matInput autocomplete="off"
              placeholder="{{shopCaptions.CMS.CmsPin}}" formControlName="cmsPin" (keydown.enter)="DialogClose('ok')" name="cmsPin" required>
            <mat-error *ngIf="FormGrp.get('cmsPin').hasError('required')">
              {{shopCaptions.CMS.MissingCMSPinError}}
            </mat-error>
          </mat-form-field>
        </div>
      </mat-dialog-content>
    </form>
  </div>
  <mat-dialog-actions class="actions">
    <div class="pl-4" *ngIf="this.buttonType == 3">
      <button mat-button class="body-bgcolor whitecolor actionButton"
        [ngClass]="pinFieldPopups.includes(data.Type) && !FormGrp.valid  ? 'button--disabled' : 'button--primary-save'"
        [disabled]="pinFieldPopups.includes(data.Type) && !FormGrp.valid && FormGrp.dirty"
        (click)="DialogClose('ok')">{{captions.OK}}</button>
      <button mat-button class="" (click)="DialogClose('cancel')">{{captions.CANCEL}}</button>
    </div>
    <div class="pl-4" *ngIf="this.buttonType == 1">
      <button mat-button class="body-bgcolor whitecolor actionButton"
        (click)="DialogClose('YES')">{{captions.Yes}}</button>
      <button mat-button class="" (click)="DialogClose('NO')">{{captions.No}}</button>
    </div>
    <div class="pl-4" *ngIf="this.buttonType == 2">
      <button mat-button class="body-bgcolor whitecolor actionButton"
        (click)="DialogClose('ok')">{{captions.Yes}}</button>
      <button mat-button class="" (click)="DialogClose('no')">{{captions.No}}</button>
      <button mat-button class="" (click)="DialogClose('cancel')">{{captions.Cancel}}</button>
    </div>
    <div class="pl-4" *ngIf="this.buttonType == 4">
      <button mat-button class="body-bgcolor whitecolor actionButton"
        (click)="DialogClose('ok')">{{captions.OK}}</button>
    </div>

    <div class="pl-4" *ngIf="this.buttonType == 5">
      <button mat-button class="actionButton" (click)="DialogClose('save')"
        [ngClass]="(data.Type == 'OPENPRICE' || data.Type == 'ENTERCOMMENT') && (!FormGrp.dirty || !FormGrp.valid)  ? 'button--disabled' : 'button--primary-save'">{{captions.Save}}</button>
      <button mat-button class="" (click)="DialogClose('cancel')">{{captions.Cancel}}</button>
    </div>
    <div class="pl-4" *ngIf="this.buttonType == 6">
      <button mat-button class="body-bgcolor whitecolor actionButton"
        (click)="DialogClose('YES')">{{captions.Continue}}</button>
      <button mat-button class="" (click)="DialogClose('NO')">{{captions.CANCEL}}</button>
    </div>
    <div class="pl-4" *ngIf="this.buttonType == 7">
      <button mat-button (click)="DialogClose('Add')"
        [ngClass]="data.Type == 'SCALEDITEM' && FormGrp.dirty && FormGrp.valid ? 'button--primary-save' : 'button--disabled'">{{captions.ADD}}</button>
      <button mat-button class="spa-button-cancel" (click)="DialogClose('NO')">{{captions.CANCEL}}</button>
    </div>
  </mat-dialog-actions>
</section>