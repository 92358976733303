import { Injectable } from '@angular/core';
import { TeeTimeModel, PlayerCount, TeeTimeTempHold, PlayerDetail,TeeSlotNavigation } from './tee-time.model';
import { GolfScheduleCommunication } from 'src/app/shared/communication/services/golfschedule.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { RateType } from './player-details/player-info.model';
import { RainCheckIssue } from './fee-information/fee-information.model';
import { ContactDetails, OverlappingSlotsForGuest } from 'src/app/shared/shared-models';
import { BookTeeTimesRuleResult } from 'src/app/shared/models/teesheet.api.models';
import { DEFAULT_GUID } from 'src/app/shared/global.constant';

@Injectable({
    providedIn: 'root'
})
export class TeeTimeService {
    private captions;
    RateTypes: RateType[];
    rainCheckIssues: RainCheckIssue[];
    deleteRainChecks: number[] = [];
    isBulkEdit: boolean;
    isBulkEditPopUpOpened: boolean = false;
    canEditCaddy: boolean = false;
    public totalPlayerCount = 4;
    constructor(private _golfScheduleCommunication: GolfScheduleCommunication, private _localization: GolfLocalization) {
        this.captions = this._localization.captions.settings;
    }

    getCaption() {
        return {
            playerDetails: this.captions.playerDetails,
            generalInformation: this.captions.generalInformation,
            feeInformation: this.captions.feeInformation,
            otherInformation: this.captions.otherInformation,
            itinerary: this.captions.itinerary,
            packageEntitlement: this.captions.packageEntitlement,
            title: this.captions.newTeeTime,
            editTitle: this._localization.captions.teetime.editTeeTime,
            teeTimeDetail: this._localization.captions.teetime.teeTimeDetail,
            active: this.captions.active,
            checked: this.captions.yes,
            guarentee: this.captions.guarentee,
            listOrder: this.captions.listOrder,
            rateType: this.captions.rateType,
            superRecipt: this.captions.supressOnRecipt,
            unchecked: this.captions.no,
            popTitle: this.captions.editRateType,
            popSave: this.captions.popSave,
            popCancel: this.captions.popCancel,
            createpopTitle: this.captions.createRateType,
            createpopSave: this.captions.popSave,
            createpopCancel: this.captions.popCancel,
            msgTeeTimeAddedToCart: this.captions.msg_TeeTimeAddedToCart,
            bookTeeTime: this.captions.bookTeeTime
        };
    }
    public GetAllCancellationNoShowPolicyByDate(date)
    {
        return this._golfScheduleCommunication.getPromise<any>(
            { route: GolfApiRoute.GetAllCancellationNoShowPolicyByDate,
                uriParams: { date: date }
            }, true
        );
    }
    public Save(form: any, tab: TeeTimeModel) {
        return this._golfScheduleCommunication.postPromise<any>(
            { route: GolfApiRoute.BookTeeTime, body: tab }, true);

    }
    public Update(form: any, tab: TeeTimeModel) {
        return this._golfScheduleCommunication.putPromise<any>(
            { route: GolfApiRoute.UpdateTeeTime, body: tab }, true);

    }
    public UpdateBulkTeeTime(form: any, tab: TeeTimeModel, canEditCaddy: boolean) {
        return this._golfScheduleCommunication.putPromise<any>(
            { route: GolfApiRoute.BulkEditTeeTime, body: tab, uriParams: { canEditCaddy: canEditCaddy } }, true);

    }

    public GetScheduledTeeTimes(courseId: number, startDate: Date | string, endDate: Date | string): Promise<PlayerCount[]> {
        try {
            return this._golfScheduleCommunication.getPromise<PlayerCount[]>(
                {
                    route: GolfApiRoute.GetScheduledTeeTimesByDateRange,
                    uriParams: { courseId, startDate, endDate }
                }, false);
        } catch (error) {
            console.error(error);
        }
    }

    public async GetSlotPlayerDetails(_scheduledTeeTimeDateTime ,_originalHoleNumber, _courseId ,slotNavigation:TeeSlotNavigation){
         return await this._golfScheduleCommunication.getPromise<any>(
            {
                route: GolfApiRoute.GetSlotPlayerDetails,
                uriParams: { date:_scheduledTeeTimeDateTime,courseId:_courseId,teeSlotNavigation:slotNavigation ,activityId:DEFAULT_GUID,originalHoleNumber: _originalHoleNumber}
            }, true);
    }    

    public GetTeeTimesofCourseByDate(courseId: any, scheduledDate: string) {
        return this._golfScheduleCommunication.getPromise<any>(
            {
                route: GolfApiRoute.GetTeeTimesofCourseByDate,
                uriParams: { courseId, scheduledDate }
            }, true);
    }

    public GetTeeTimeByScheduleId(scheduleId: number) {
        return this._golfScheduleCommunication.getPromise<any>(
            {
                route: GolfApiRoute.GetTeeTimeById,
                uriParams: { scheduleId }
            }, true);
    }

    public TempHoldTeeTime(teeTimeTempHold: TeeTimeTempHold) {
        return this._golfScheduleCommunication.postPromiseHandleValidation<any>(
            { route: GolfApiRoute.TempHoldTeeTime, body: teeTimeTempHold }, false);

    }
    public ReleaseTempHoldTeeTime(teeTimeTempHold: TeeTimeTempHold) {
        return this._golfScheduleCommunication.postPromiseHandleValidation<any>(
            { route: GolfApiRoute.ReleaseTempHoldTeeTime, body: teeTimeTempHold }, false);

    }

    public TempHoldTeeTimes(teeTimeTempHolds: TeeTimeTempHold[]) {
        return this._golfScheduleCommunication.postPromise<any>(
            { route: GolfApiRoute.TempHoldTeeTimes, body: teeTimeTempHolds }, false);

    }
    public ReleaseTempHoldTeeTimes(teeTimeTempHolds: TeeTimeTempHold[]) {
        return this._golfScheduleCommunication.postPromise<any>(
            { route: GolfApiRoute.ReleaseTempHoldTeeTimes, body: teeTimeTempHolds }, false);

    }

    public async GetDepositInfo(scheduleTeeTimeId: any) {
        return await this._golfScheduleCommunication.getPromise<any>(
            {
                route: GolfApiRoute.GetDepositInfo,
                uriParams: { "scheduleTeeTimeId": scheduleTeeTimeId }
            }, true);

    }
    public SMSMailNotification(eMailId: string, phoneNumber: string, teeAction: string, bookingId: string): any {
        return this._golfScheduleCommunication.putPromise({
            route: GolfApiRoute.BulkSendMailNotification, uriParams: { eMailId: eMailId, phoneNumber: phoneNumber, teeAction: teeAction, bookingId: bookingId },
        });
    }

    public GetContactInformationByPlayerIds(playerIds: number[]): Promise<ContactDetails[]> {
        return this._golfScheduleCommunication.putPromise({
            route: GolfApiRoute.GetContactInformationByPlayerIds, body: playerIds
        }, true);
    }

    public UpdatePlayerToGPSInterface(playerDetail: PlayerDetail[]) {
        return this._golfScheduleCommunication.putPromise<any>(
            { route: GolfApiRoute.UpdateTeeTimePlayerToGuest, body: playerDetail }, true);
    }

    public ValidateBookTeeTimeRules(scheduleTeeTime : TeeTimeModel, moduleCode: string ):Promise<BookTeeTimesRuleResult[]> {
        return this._golfScheduleCommunication.putPromise<any>(
            { route: GolfApiRoute.ValidateBookTeeTimeRules, body: [scheduleTeeTime], uriParams:{ModuleCode : moduleCode}}, true);
    }

    public async ValidateOverLappingTeeTimeSlotsForGuest(playerLinkIds:string[],timeOfHours:number = 0,bookingDateTime:string,courseId:number) : Promise<OverlappingSlotsForGuest[]> {
        return this._golfScheduleCommunication.putPromise<any>(
            {
                route: GolfApiRoute.OverLappingTeeTimeSlotsForGuest,
                body: playerLinkIds,
                uriParams: 
                {
                    timeOfHours: timeOfHours,
                    bookingDateTime:bookingDateTime,
                    courseId: courseId
                } 
            }, true);
    }
}
