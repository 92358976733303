import { Injectable } from '@angular/core';
import { AppService } from 'src/app/common/app-service';
import { ServiceParams } from 'src/app/common/Models/http.model';
import { EventDataServices } from 'src/app/common/dataservices/notification/event.data.service';
import { EventActor, EventActorUIModel, Collection, IEventActorDataProvider, EventActorCollection, DistributionListFormGroup, DistributionListApi, DistributionListType, DistributionConfigApi, CollectionType, UpdatedCollection } from 'src/app/common/Models/notification.model';
import { RguestCommunication } from 'src/app/common/communication/common/rguest-communication';
import { HttpClient } from '@angular/common/http';
import { CommonUtilities } from '../../../shared/shared/utilities/common-utilities';
import { Localization } from '../../../localization/localization';
import { CommonPropertyInformation } from '../../../shared/services/common-property-information.service';
import { DistributionListDataService } from 'src/app/common/dataservices/notification/distributionList.service';
import _ from 'lodash';
import { SendToMail } from './crud-distribution-list.model';



@Injectable()
export class CRUDDLBusiness {
    private readonly productId: number;
    captions: any;
    constructor(private _distributionListDataService: DistributionListDataService, private _eventDataServices: EventDataServices, private _appService: AppService,
        private httpclient: HttpClient, private localization: Localization, private utilities: CommonUtilities, private PropertyInfo: CommonPropertyInformation) {
        this.productId = this._appService.productId;
        this.captions = this.localization.captions.settings.utilities.distributionlist;
    }


    mapToUI(apiData: DistributionListApi, actorsUiData: EventActorUIModel[]): DistributionListFormGroup {

        const filterCollection = (type: CollectionType) => apiData.distributionConfigs.filter(d => d.collectionType == type);
        const smsType = [DistributionListType.Sms, DistributionListType.Both];
        const emailType = [DistributionListType.Email, DistributionListType.Both];
        return {
            distributionlistname: apiData.distributionName,
            fromEmail: apiData.fromAddress,
            sms: smsType.includes(apiData.distributionType),
            email: emailType.includes(apiData.distributionType),
            To: this.mapEventActorsToUiModel(filterCollection(CollectionType.To), actorsUiData).map(o => o.code),
            CC: this.mapEventActorsToUiModel(filterCollection(CollectionType.Cc), actorsUiData).map(o => o.code),
            Bcc: this.mapEventActorsToUiModel(filterCollection(CollectionType.Bcc), actorsUiData).map(o => o.code),
            ToCollection: this.mapEventActorsToUiModel(filterCollection(CollectionType.To), actorsUiData),
            CcCollection: this.mapEventActorsToUiModel(filterCollection(CollectionType.Cc), actorsUiData),
            BccCollection: this.mapEventActorsToUiModel(filterCollection(CollectionType.Bcc), actorsUiData)
        }
    }
    mapEventActorsToUiModel(value: DistributionConfigApi[], actors: EventActorUIModel[]): EventActorUIModel[] {
        let selectedActors: EventActorUIModel[] = []
        actors.forEach(a => {
            const selectedValues = value.find(api => api.eventActorId == a.id);
            const isHavingSelectedValues: boolean = typeof selectedValues != 'undefined';
            if (isHavingSelectedValues) {
                const apiSelectedValues: number[] = JSON.parse(selectedValues.value);
                const collection: EventActorCollection[] = this.mapEventActorCollection(apiSelectedValues, a.collection[0].list)
                a.collection[0].selectedList = collection;
                selectedActors.push(a);
            }

        });
        return _.cloneDeep(selectedActors);
    }

    private mapEventActorCollection(selected: number[], source: EventActorCollection[]): EventActorCollection[] {
        return selected.map(s => {
            const sourceActor = source.find(o => o.id == s);
            return <EventActorCollection>{
                id: s,
                desc: sourceActor ? sourceActor.desc : ''
            }
        });
    }



    async getEventActors(): Promise<EventActorUIModel[]> {
        let actors = await this._eventDataServices.getAllEventActors(this.productId);
        return this.mapEventActorsMembers(actors);

    }

    async mapEventActorsMembers(actor: EventActor[]): Promise<EventActorUIModel[]> {
        const dataProviders: IEventActorDataProvider[] = this._appService.notificationEventDataProvider.providers;
        this.validateActorsData(actor, dataProviders);
        let providers$: Promise<Collection[]>[] = [];
        dataProviders.forEach(p => {
            let _provider: Promise<Collection[]>;
            if (typeof p.host == 'undefined') {
                _provider = this.getDynamicEventActors(p, actor);
            } else {
                _provider = this.getStaticEventActors(p, actor)
            }
            providers$.push(_provider);
        });
        

        const result: Collection[][] = await Promise.all(providers$);
        const actorsArr: any = result;   // Workaround to use Flat()
        let actorsUi: Collection[] = actorsArr.flat() as Collection[];
        actorsUi = actorsUi.filter(a=> a.id != 0);
        return actorsUi.map(a => {
            return <EventActorUIModel>{
                id: this.assignActorId(actor, a),
                description: a.listTabName,
                code: a.actorCode,
                collection: [a]
            }
        });

    }
    validateActorsData(actor: EventActor[], dataProviders: IEventActorDataProvider[]):void {
        actor.forEach(a => {
            if (!dataProviders.some(d => d.actor == a.actor)) {
              alert(`Data provider not configured in UI for ${a.actor}`);
            }
        });
    }
    private assignActorId(actorApi: EventActor[], actorUi: Collection): number {
        const apiValue = actorApi.find(a => a.actor == actorUi.actorCode);
        return apiValue.id;
    }

    private getDynamicEventActors(provider: IEventActorDataProvider, actor: EventActor[]): Promise<Collection[]> {
        return Promise.resolve([
            <Collection>{
                addtoTabView: false,
                listTabName: provider.actorDescription,
                actorCode: provider.actor,
                selectedList: [],
                id: this.tryGetEventActorId(actor,provider),
                list: []
            }
        ]);
    }


    async getStaticEventActors(provider: IEventActorDataProvider, actor: EventActor[]): Promise<Collection[]> {
        const host = this.validateAndResolveHost(provider.host);
        const communication = this.createRguestCommunication(host);
        const params: ServiceParams = { route: provider.route }
        const result = await communication.getPromise<any[]>(params);
        return [
            <Collection>{
                addtoTabView: true,
                listTabName: provider.actorDescription,
                actorCode: provider.actor,
                selectedList: [],
                id: this.tryGetEventActorId(actor,provider),
                list: provider.uiMapper(result)
            }];
    }

    private tryGetEventActorId(actorMasterData: EventActor[], provider: IEventActorDataProvider): number {
        const actor = actorMasterData.find(a => a.actor == provider.actor);
        return actor ? actor.id : 0;
    }
    private validateAndResolveHost(host: string) {
        let validhost = host;
        const lastCharIndex = host.length - 1;
        if (host[lastCharIndex] == '/') {
            validhost = host.slice(0, lastCharIndex);
        }
        return validhost;
    }

    createRguestCommunication(host: string): RguestCommunication {
        return new RguestCommunication(host, this.httpclient, this.localization, this.utilities, this.PropertyInfo,this._appService);
    }

    public updateDistributionList(value: DistributionListFormGroup, updatedCollections: UpdatedCollection[], id: number): Promise<boolean> {

        const distributionType: DistributionListType = value.email && value.sms ? DistributionListType.Both :
            (value.email && DistributionListType.Email) || (value.sms && DistributionListType.Sms);

        let apimapped: DistributionListApi = this.mapToApi(value, distributionType, updatedCollections);
        apimapped.id = id;

        return this._distributionListDataService.updateDistributionList(apimapped, id);
    }

    public saveDistributionList(value: DistributionListFormGroup, updatedCollections: UpdatedCollection[]): Promise<boolean> {

        const distributionType: DistributionListType = value.email && value.sms ? DistributionListType.Both :
            (value.email && DistributionListType.Email) || (value.sms && DistributionListType.Sms);

        const apimapped: DistributionListApi = this.mapToApi(value, distributionType, updatedCollections);
        return this._distributionListDataService.createDistributionList(apimapped);
    }

    private mapToApi(value: DistributionListFormGroup, type: DistributionListType, updatedCollections: UpdatedCollection[]): DistributionListApi {
        let config: any = [];
        value.To && config.push(this.mapEventActorUiToApi(value.To, CollectionType.To, updatedCollections));
        value.CC && config.push(this.mapEventActorUiToApi(value.CC, CollectionType.Cc, updatedCollections));
        value.Bcc && config.push(this.mapEventActorUiToApi(value.Bcc, CollectionType.Bcc, updatedCollections));

        return {
            distributionName: value.distributionlistname.trim(),
            distributionType: type,
            fromAddress: value.fromEmail ? value.fromEmail.trim() : '',
            productId: this.productId,
            distributionConfigs: config.flat() as unknown as DistributionConfigApi[]
        }
    }

    private mapEventActorUiToApi(value: string[], collectionType: CollectionType, updatedCollections: UpdatedCollection[]): DistributionConfigApi[] {
        let mapped: DistributionConfigApi[] = []
        if (value) {
            mapped = value.map(a => {
                const currentCollection = updatedCollections.find(u => u.type == collectionType).collection.find(c => c.actorCode == a);
                return <DistributionConfigApi>{
                    eventActorId: currentCollection.id,
                    value: JSON.stringify(currentCollection.selectedList.map(o => o.id)),
                    collectionType: collectionType
                }
            });
            return mapped;
        }




    }

    /**
     * 
     * @function - getSendToMailOptions()
     * 
     * @returns - Sent To Options like To,cc,bcc in mail
     *  
     */


    getSendToMailOptions(){
        return [
            {
                id: SendToMail.To,
                value: SendToMail.To,
                viewValue: this.captions.To,
                isSelected: true
            },
            {
                id: SendToMail.CC,
                value: SendToMail.CC,
                viewValue: this.captions.CC,
                isSelected: false
            },
            {
                id: SendToMail.BCC,
                value: SendToMail.BCC,
                viewValue: this.captions.Bcc,
                isSelected: false
            }
        ]
    }
}