import { Pipe, PipeTransform } from '@angular/core';
import { GolfUtilities } from '../utilities/golf-utilities';

@Pipe({
    name: 'formatName'
})
export class FormatNamePipe implements PipeTransform {

    /**
     *
     */
    constructor(private _utilities: GolfUtilities) {

    }

    transform<T>(value: T, firstName: keyof T, lastName: keyof T): any {      
        return this._utilities.formatGuestName(value[firstName] as unknown as string, value[lastName] as unknown as string);
    }

}
