import { Injectable } from '@angular/core';
import { GolfManagementCommunication } from '../../communication/services/golfmanagement.service';
import { API } from '../../../settings/golf-setup/code-linking/code-link-modal';


@Injectable({
    providedIn:"root"
})
export class CodeLinkingDataService {
    constructor(private _http: GolfManagementCommunication) {

    }
    async getAllocationBlockPlayerTypes(): Promise<API.AllocationBlockPlayerType[]> {
        return this._http.getPromise<API.AllocationBlockPlayerType[]>({
            route: GolfApiRoute.GetAllocationBlockPlayerTypes
        });
    }

    async getPlayerTypeRateTypes(): Promise<API.PlayerTypeRateType[]> {
        return this._http.getPromise<API.PlayerTypeRateType[]>({
            route: GolfApiRoute.GetPlayerTypeRateTypes
        });
    }
    async createAllocationTypePlayerTypeMapping(_id: number, _mappingPatchRequestIds: API.MappingPatchRequestIds): Promise<API.AllocationBlockPlayerType[]> {
        return await this._http.putPromise<API.AllocationBlockPlayerType[]>({
            route: GolfApiRoute.CreateAllocationBlockPlayerType,
            uriParams: { id: _id },
            body: _mappingPatchRequestIds
        });
    }

    async createPlayerTypeRateTypeMapping(_id: number, _mappingPatchRequestIds: API.MappingPatchRequestIds): Promise<API.PlayerTypeRateType[]> {
        return await this._http.putPromise<API.PlayerTypeRateType[]>({
            route: GolfApiRoute.CreatePlayerTypeRateType,
            uriParams: { id: _id },
            body: _mappingPatchRequestIds
        });
    }

    public async getPackagePlayerTypesRateTypes(packageCode:string): Promise<API.PackagePlayerTypesRateTypes> {
       return await this._http.getPromise<API.PackagePlayerTypesRateTypes>(
          { route: GolfApiRoute.GetPackageLinkedPlayerTypesRateTypes, uriParams: { "code": packageCode } });        
      } 
}