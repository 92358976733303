import { Injectable } from '@angular/core';
import * as ContactInterface from './contact.model';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Injectable({ providedIn: 'root' })
export class ContactService {

  captions: any;
  contactTypes:any;
  radioArray: ContactInterface.RadioArray;
  contactPhoneType: ContactInterface.ContactPhoneType[] = [];
  contactEmailType: ContactInterface.ContactEmailType[] = [];
  constructor(private _localization : GolfLocalization) { }


  errorCaptionGenerator() {
    return {
      instructorCodeError: 'Missing Instructor Code',
      firstNameError: 'Missing First Name',
      lastNameError: 'Missing Last Name',
    };
  }
  getRadioArray() {
    this.captions = this._localization.captions.tournamentContact;
    return this.radioArray = [
      { id: 0, value: "member", checked: true, viewValue: this.captions.member },
      { id: 1, value: "guest", checked: false, viewValue: this.captions.guest }
    ];
  }

  getPhoneType() {
    this.contactTypes = this._localization.captions.contactTypes;
    return this.contactPhoneType = [
      { id: 13, description: this.contactTypes.workPhone, type: "Phone" },
      { id: 12, description: this.contactTypes.businessPhone, type: "Phone" },
      { id: 2, description: this.contactTypes.home, type: "Phone" },
      { id: 3, description: this.contactTypes.work, type: "Phone" },
      { id: 1, description: this.contactTypes.mobile, type: "Phone" }
    ];
  }
  getEmailType() {
    this.contactTypes = this._localization.captions.contactTypes;
    return this.contactEmailType = [
      { id: 9, description: this.contactTypes.personal, type: "Email" },
      { id: 10, description: this.contactTypes.work, type: "Email" },
      { id: 14, description: this.contactTypes.homeEmail, type: "Email" },
      { id: 15, description: this.contactTypes.businessEmail, type: "Email" }
    ];
  }



}
