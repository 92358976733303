import { Injectable } from '@angular/core';
import { PlayerProfileTeeTime } from '../../models/player.model';
import { GolfGatewayCommunication } from '../../communication/services/golfGateway';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { Transaction } from 'src/app/common/shared/shared/business/shared.modals'
import { RetailmanagementCommunication } from '../../communication/services/retailmanagement.service';
import { CommonApiRoutes } from '../../../common/common-route';
import { DataPurge } from 'src/app/common/data-retention/data-retention.model';
import { ApplyPolicy } from 'src/app/common/consent-management/consent-management.model';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';
import { Dictionary } from 'lodash';
import { PlayerProfileAPI, SubscribeProfile } from 'src/app/shared/create-player/create-player.model';
import { ActivityResponse } from 'src/app/shared/create-player/itnerary/itnerary.model';
import { GuestSearchData } from 'src/app/shared/components/ag-player-type-search/golf-search-model';
@Injectable()
export class PlayerProfileDataService {
  constructor(
    private _golfGatewayCommunication: GolfGatewayCommunication,
    private _golfScheduleCommunication: GolfScheduleCommunication,
    private _retailmanagementCommunication: RetailmanagementCommunication,
    private _localization: GolfLocalization
  ) { }


  public async CreatePlayerProfile(requestBody: PlayerProfileAPI.PlayerProfile, handleError: boolean = true): Promise<string> {
    return this._golfScheduleCommunication.postPromise({
      route: GolfApiRoute.CreatePlayerProfile,
      body: requestBody
    }, handleError);
  }

  public async UpatePlayerProfile(requestBody: PlayerProfileAPI.PlayerProfile, handleError: boolean = true): Promise<string> {
    return this._golfScheduleCommunication.putPromise({
      route: GolfApiRoute.UpdatePlayerProfile,
      body: requestBody,
    }, handleError);
  }

  public async GetPlayerProfileById(requestBody: string): Promise<PlayerProfileAPI.PlayerProfile> {
    return this._golfScheduleCommunication.getPromise({
      route: GolfApiRoute.GetPlayerProfileById,
      uriParams: { id: requestBody }

    });
  }

  public async GetAppointmentsByStatus(clientId: string, startDate: Date, endDate: Date): Promise<any> {
    const toAPI = this._localization.convertDateObjToAPIdate;
    return this._golfScheduleCommunication.getPromise({
      route: GolfApiRoute.GetAppointmentsByStatus,
      uriParams: { id: clientId, fromDate: toAPI(startDate), toDate: toAPI(endDate) }

    });
  }

  public async GetGuestSalesHistoryTransaction(clientId: string): Promise<any> {
    return this._golfScheduleCommunication.getPromise({
      route: GolfApiRoute.GetGuestSalesHistoryTransaction,
      uriParams: { id: clientId }

    });
  }

  public async GetSalesHistoryTransactionByGuestGuids(clientId: string[]): Promise<Transaction[]> {
    return this._retailmanagementCommunication.putPromise({
      route: GolfApiRoute.GetSalesHistoryTransactionByGuestGuids,
      body: clientId
    });
  }

  public async getItneraryforPlayer(clientId: string, fromDate: any, toDate: any): Promise<ActivityResponse[]> {
    return this._golfGatewayCommunication.getPromise({
      route: GolfApiRoute.GetItneraryforPlayer,
      uriParams: { id: clientId, "fromDate": fromDate, "toDate": toDate }
    });
  }

  public async searchPlayerProfileByName(pattern: string, isExternalGuestSearch: boolean = false, isPlatformGuestSearch: boolean = false): Promise<PlayerProfileAPI.PlayerProfile[]> {
    return this._golfScheduleCommunication.getPromise({
      route: GolfApiRoute.SearchPlayerProfileByName,
      uriParams: { pattern: encodeURI(pattern) ,isExternalGuestSearch : isExternalGuestSearch ,isPlatformGuestSearch : isPlatformGuestSearch}
    });
  }
  public async SearchPlayerProfileByKeyAndValue(searchKey: number, pattern: string, IncludePurgedData: boolean, isExternalGuestSearch: boolean = false, isPlatformGuestSearch: boolean = false): Promise<PlayerProfileAPI.PlayerProfile[]> {
    return this._golfScheduleCommunication.putPromise({
      route: GolfApiRoute.SearchPlayerProfileByKeyAndValue,
      body: {pattern: pattern},
      uriParams: { searchKey: searchKey, IncludePurgedData: IncludePurgedData, isExternalGuestSearch: isExternalGuestSearch ? true : false, isPlatformGuestSearch: isPlatformGuestSearch }
    });
  }

  public async getTeeTimesForPlayer(clientId: string): Promise<PlayerProfileTeeTime[]> {
    return this._golfScheduleCommunication.getPromise({
      route: GolfApiRoute.GetTeeTimeForPlayer,
      uriParams: { id: clientId }
    });

  }

  public async getExistingPlayerUsingPatronId(patronId: string): Promise<PlayerProfileAPI.PlayerProfile> {
    return this._golfScheduleCommunication.getPromise({
      route: GolfApiRoute.GetPlayerUsingPatronID,
      uriParams: { patronId: patronId }
    });
  }
  public async ApplyDataPolicyPurgeForGuestId(dataPurge: DataPurge): Promise<boolean> {
    const result = await this._golfGatewayCommunication.postPromise<boolean>(
      { route: CommonApiRoutes.ApplyDataPolicyPurgeForGuestId, body: dataPurge });
    return result;
  }

  public async UpdatePolicyDetailsForGuestId(applyPolicy: ApplyPolicy): Promise<boolean> {
    const result = await this._golfGatewayCommunication.postPromise<boolean>(
      { route: CommonApiRoutes.UpdateConsentPolicyDetailsForGuestId, body: applyPolicy }, false);
    return result;
  }
  public async UpdateConsentPolicyDetailsByGuestIdList(applyPolicy: ApplyPolicy[]): Promise<boolean> {
    const result = await this._golfScheduleCommunication.postPromise<boolean>(
      { route: CommonApiRoutes.UpdateConsentPolicyDetailsByGuestIdList, body: applyPolicy }, false);
    return result;
  }

  public async GetPolicyTypeUsingPolicyId(policyId: number): Promise<number> {
    const result = await this._golfGatewayCommunication.getPromise<number>(
      { route: CommonApiRoutes.GetPolicyTypeUsingPolicyId, uriParams: { policyId: policyId } }, false);
    return result;
  }
  public async GetExternalGuestProfile(externalGuestId: string,createGuestProfile: boolean = false): Promise<PlayerProfileAPI.PlayerProfile> {
    return this._golfScheduleCommunication.postPromise({
        route: GolfApiRoute.GetExternalGuestProfile,
        uriParams: { guestId : externalGuestId ,createGuestProfile : createGuestProfile }
    });
  } public async GetGuestProfileByPlatformGuid(platformGuid : string): Promise<PlayerProfileAPI.PlayerProfile> {
    return this._golfScheduleCommunication.getPromise({
      route: GolfApiRoute.GetPlayerProfilebyPlatformGuid,
      uriParams: { platformGuid: platformGuid }
    });
  }
  public async UpdatePlayerProfileLink(subscribeProfile: SubscribeProfile ): Promise<string> {
    return this._golfScheduleCommunication.postPromise({
        route: GolfApiRoute.UpdatePlayerProfileLink,
        body : subscribeProfile
    });
  }
  public async GetMatchingGuests(guestSearchData: GuestSearchData): Promise<PlayerProfileAPI.PlayerProfile[]> {
    return this._golfScheduleCommunication.putPromise({
      route: GolfApiRoute.getMatchingGuests,
      body: guestSearchData
    })
  }
}
