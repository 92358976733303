<mat-form-field *ngIf="form" [floatLabel]="floatLabel" [ngClass]="className" [formGroup]="form" appearance="legacy">
  <mat-label>{{placeHolder}}</mat-label>
  <input matInput attr.automationId='Txt_{{automationId}}_{{formControlName}}' [min]="minDate" dateRestricter [max]="maxDate"
  [matDatepicker]="datePicker" (dateChange)="dateChanged($event)" [placeholder]="placeHolderFormat" 
  [formControlName]="formControlName"  [hidden]="hidden"  [required]="isDateRequired">
 <mat-datepicker-toggle matSuffix [for]="datePicker">
   <i  aria-hidden="true" class="icon-calendar" matDatepickerToggleIcon></i>
 </mat-datepicker-toggle>
 <mat-datepicker #datePicker></mat-datepicker>
 <mat-error *ngIf="form.controls[formControlName].hasError('matDatepickerParse')">{{captionsLocalization.dateFormat}}</mat-error>   
 <mat-error *ngIf="(form.controls[formControlName].hasError('required') || form.controls[formControlName].hasError('incorrect')) && !form.controls[formControlName].hasError('matDatepickerParse')">{{errorMessage}}</mat-error>
 <mat-error *ngIf="form.controls[formControlName].hasError('matDatepickerMin')">{{captionsLocalization.minimum}} {{placeHolder}}</mat-error>
 <mat-error *ngIf="form.controls[formControlName].hasError('matDatepickerMax')">{{captionsLocalization.maximum}} {{placeHolder}}</mat-error>
 <mat-error *ngIf=" errorCount() == 1 && form.controls[formControlName].hasError('matDatepickerFilter')">
   {{dateInvalidError}}
  </mat-error>
</mat-form-field> 
