import { Injectable } from '@angular/core';
import { AssignRetailItemCaptions, RetailItem, DomainRetailItem, CategoryOption } from './assign-retail-item.model';
import { RetailmanagementCommunication } from 'src/app/shared/communication/services/retailmanagement.service';
import { GolfLocalization } from '../../../core/localization/golf-localization';
import { Subject } from 'rxjs';
import { ServiceParams } from 'src/app/shared/models/http.model';

@Injectable({providedIn: 'root'})
export class AssignRetailItemService {
   
     constructor(private _http:RetailmanagementCommunication,private _Localization:GolfLocalization){

     }

    GetCaption(): AssignRetailItemCaptions {
        return {
            assign: this._Localization.captions.settings.assign,
            itemNo: this._Localization.captions.settings.items,
            itemDescription: this._Localization.captions.settings.itemDescription,
            category: this._Localization.captions.settings.category,
            subCategory1: this._Localization.captions.settings.subCategory1,
            subCategory2: this._Localization.captions.settings.subCategory2,
            subCategory3: this._Localization.captions.settings.subCategory3,
            subCategory4: this._Localization.captions.settings.subCategory4,
            subCategory5: this._Localization.captions.settings.subCategory5,
            price: this._Localization.captions.settings.prices,
            title: this._Localization.captions.settings.selectItem,
            searchPlaceholder:  this._Localization.captions.settings.searchPlace,
            advancedSearch: this._Localization.captions.settings.advancedSearch
        };
    }

    GetOutletRetailItems(outletId : number): Promise<RetailItem[]> {

        return this._http.getPromise<RetailItem[]>({
            route :GolfApiRoute.GetOutletRetailItems,
            uriParams : {outletId : outletId}
        });        
        
    }

    GetAllRetailItems(): Promise<RetailItem[]> {

        return this._http.getPromise<RetailItem[]>({
             route :GolfApiRoute.GetAllActiveRetailItems
         });         
         
     }

    getRetailItemById(id:number):Promise<DomainRetailItem>{
        return this._http.getPromise<DomainRetailItem>({
             route : GolfApiRoute.GetRetailItemById,
             uriParams :  {"itemId": id }
         });
    }

    getCategory():Promise<CategoryOption[]> {
        return this._http.getPromise<CategoryOption[]>({
            route : GolfApiRoute.GetAllCategories
        });
    }
    getSubCategories():Promise<CategoryOption[]>  {
        return this._http.getPromise<CategoryOption[]>({
            route : GolfApiRoute.GetAllSubCategories
        });
    }

    GetRetailItemsByIds(retailItemIds:number[]) {
        return this._http.putPromise<RetailItem[]>({
            route : GolfApiRoute.GetAllActiveRetailItemsByIds,
            body : retailItemIds
        });
    }

    GetRetailItemsByPagination(serviceParams: ServiceParams, notifier: Subject<void>): Promise<any> {             
        return this._http.postCancellablePromise(serviceParams, notifier);
 }

}
