import { Injectable } from '@angular/core';
import {AuditTrailFilterData , AuditTrail,AuditTrailAPI } from 'src/app/tee-time-actions/audit-trail/audit-trail.model';
import { ReportCommunication } from 'src/app/shared/communication/services/report.service';
import { ContactDetails } from 'src/app/shared/shared-models';
import { GolfScheduleCommunication } from 'src/app/shared/communication/services/golfschedule.service';

@Injectable()
export class AuditTrailDataService {

  constructor(private _http: ReportCommunication, private _golfScheduleCommunication: GolfScheduleCommunication ) {
  }
  
  public GetAuditTrailData(auditTrailFilterData: AuditTrailFilterData,scheduleTeeTimeId:number, isOpenSlot:boolean,courseId:number,scheduledDateTime : Date, originalHoleNumber :number): Promise<AuditTrailAPI[]>
  {
    return this._http.putPromise<AuditTrailAPI[]>(
      { route: GolfApiRoute.GetAllTransactionLogs, 
        uriParams: { "scheduleTeeTimeId":scheduleTeeTimeId,"isOpenSlot":isOpenSlot,"courseId":courseId , "scheduledDateTime": scheduledDateTime , "originalHoleNumber" : originalHoleNumber } ,
        body: auditTrailFilterData
      },false);
  }
  public GetAuditTrailDataForOpenSlots(courseId:number,scheduledDateTime:Date,originalHoleNumber:string)
  {
    return this._http.putPromise<AuditTrailAPI[]>(
      { route: GolfApiRoute.GetAuditTrailDataForOpenSlots, 
        uriParams: { "courseId":courseId , "scheduledDateTime": scheduledDateTime , "originalHoleNumber" : originalHoleNumber} ,
      },false);
  }
  public GetContactInformationByPlayerIds(playerIds: number[]): Promise<ContactDetails[]> {
    return this._golfScheduleCommunication.putPromise({
        route: GolfApiRoute.GetContactInformationByPlayerIds, body: playerIds
    }, true);
}
}