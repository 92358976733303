import { Injectable } from "@angular/core";
import { HttpMethod, HttpServiceCall } from "src/app/common/shared/shared/service/http-call.service";
import * as GlobalConst from 'src/app/common/shared/shared/globalsContant';
import {  TraceDetails } from "../../Models/logViewer.model";

@Injectable({
  providedIn: "root"
})

export class TraceBussines{
    constructor(public http: HttpServiceCall){

    }

    async callAPI(traceId){
        let uriParams = {
          traceId: traceId
        };
          let response = await this.http.CallApiAsync<TraceDetails>({
            host: GlobalConst.Host.golfManagement,
            callDesc: "GetStackTraceForTraceId",
            uriParams: uriParams,
            method: HttpMethod.Get
          });
          return response.result;
    }
}