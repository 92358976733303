<div class="submodule-container">
  <app-table-search-header [searchHeaderOption]="searchHeaderOption" (searchChange)="searchChange($event)"
                           (showInactive)="showInactiveToggler($event)" (createType)="createEvent($event)">
  </app-table-search-header>
  <div class="table-container">
    <app-cdkvirtual [headerOptions]="headerOptions" [tableContent]="tableContent | async" [options]="tableOptions"
                    [searchOptions]="searchText" (EmittedData)='tableAction($event)'>
                </app-cdkvirtual>
  </div>
</div>
<div *ngIf="CreationToggler">
  <app-create-modal [input]="createModalData" (clickEvent)="back($event)">
    <app-Create-KakaoMapping [crudInput]="crudActionInput" (handleClickEvent)="handleClick($event)"
                        (formReady)='formReady($event)'>
    </app-Create-KakaoMapping>
  </app-create-modal>
</div>
