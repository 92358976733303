<section class="Errorpop-container">
    <div class="pop-head">
        <label class="inner-header pl-4 text-uppercase">{{commonCaption.alertPopup.warning}}</label>
        <i aria-hidden="true" *ngIf="data.closebool" class="material-icons inner-close float-md-right cursPointer"
            (click)="DialogClose('e')">close</i>
    </div>
    <mat-dialog-content class="pop-contents">
        <div class="pt-3 pb-3 content">
            <div>{{commonCaption.shop.ProceedWithCardOnFile}}</div>
            <div class="saved-card pt-3 pb-3 content">
                <div class="saved-card-detailsForPayment">
                    <span>{{CurrentActiveCard.cardNumber}} | {{CurrentActiveCard.issuerType}} |
                        {{CurrentActiveCard.cardHolderName}}
                        <span *ngIf="CurrentActiveCard.cardExpiration">
                            {{ ' | ' + CurrentActiveCard.cardExpiration }}
                        </span>
                        <span *ngIf="CurrentActiveCard.accessUrl">
                            | <a [href]="CurrentActiveCard.accessUrl" target="_blank"
                                class="ag_link golf__link spa-filter-link">{{caption.shop.GetCardDetail}}</a>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="actions">
        <button [attr.automationId]="'Btn_carddetails_popup_save'" mat-button
            class="body-bgcolor whitecolor LW14 ml-3 text-capital" (click)="save()">{{commonCaption.alertPopup.yes}}</button>
        <button [attr.automationId]="'Btn_carddetails_popup_cancel'" mat-button class="LW14 ml-2 text-capital"
            (click)="onCancelClick()">{{commonCaption.alertPopup.no}}</button>
    </mat-dialog-actions>
</section>