import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class RedeemService {

    progressData$:Subject<any>;

    public invokeProgress() {
        this.progressData$ = new Subject<any>();
    }

    public completeProgress() {
        this.progressData$.complete();
    }
}