import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RguestCommunication } from 'src/app/common/communication/common/rguest-communication';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { Localization } from 'src/app/common/localization/localization';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import { AppService } from 'src/app/common/app-service';
@Injectable(
    { providedIn: 'root' }
)
export class PaymentServiceCommunication extends RguestCommunication {

    constructor(appService :AppService, utils: CommonUtilities, httpclient: HttpClient, localization: Localization, PropertyInfo: CommonPropertyInformation) {
        super(environment["Payment"], httpclient, localization, utils, PropertyInfo,appService);
    }
}