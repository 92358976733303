<section class="errorpop-container">
    <div class="pop-head">
        <div class="alert-icon">
          <i aria-hidden="true" class="icon-alert"></i>
        </div>
        <div class="alert-type">
          <label class="inner-header">{{captions.alertPopup.warning}}</label>
        </div>
      </div>
    <div class="pop-contents">
        <label>{{captions.idleAutologoffWarn}}<strong> {{timer$ | async | UITime}} </strong>{{captions.idleCompleteYourAction}}</label>
    </div>
    
    <mat-dialog-actions class="actions">
        <button [disabled]="isButtonDisabled" [attr.automationId]="'Btn_alertpopup_continueSession'" mat-button class="ag_button--primary" (click)="continue()">{{captions.btn_continueSession}}</button>
        <button [disabled]="isButtonDisabled" [attr.automationId]="'Btn_alertpopup_logout'" mat-buttonx class="ag_button--secondary" (click)="logout()">{{captions.btn_logout}}</button>
    </mat-dialog-actions>
</section>

