import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ViewChild,  ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { TeePlayerDetailsBusiness } from './tee-player-details.business';
import { TeePlayerDetailsService } from './tee-player-details.service';
import { TeeSlotInfo, AlertType, ButtonType, AlertAction, OVERLAPPING_PLAYER_WIDTH, PaymentModalType } from 'src/app/shared/shared-models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { DragOrigin, ScheduleStatus, TeeSheetSkeletonData, TeeSheetTableID, ScheduleType, TeeSheetPlayerDetails, BookingSource, TeeTimeFormat, Allocation, TeeActionsClick } from 'src/app/shared/models/teesheet.form.models';
import { TeeGridService } from '../tee-grid.service';
import { PlayerPaymentstatus } from 'src/app/tee-time/search/search-model';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { CacheConstants, FromAction, Gender, SettingModule, SettingScreen, TempHoldStatus } from 'src/app/shared/global.constant';
import { TeeTimesActionBusiness } from 'src/app/tee-time/shared/tee-action.business';
import { DMConfigDataService } from 'src/app/common/dataservices/datamagine-config.data.service';
import { EformsBusiness } from 'src/app/common/data-magine/dm-eforms/dm-eforms.business';
import { DmConfig } from 'src/app/common/Models/common.models';
import { PlayerCategory} from 'src/app/common/enums/shared-enums';
import _, { cloneDeep } from 'lodash';

import { ReplaySubject } from 'rxjs';
import { TeeTimeActions } from 'src/app/tee-time-actions/tee-time-action.base';
import { Router } from '@angular/router';
import { TeeSheetSharedBusiness } from 'src/app/tee-time/shared/teesheet.shared.business';
import { RefundBussiness } from 'src/app/tee-time-actions/refund-modal/refund-bussiness';
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { UntypedFormBuilder } from '@angular/forms';
import { DefaultsSettingConfig } from 'src/app/tee-time-actions/teetime/player-details/player-info.model';
import { DefaultOutletType } from 'src/app/common/shared/shared/enums/enums';
import { DefaultUserConfiguration } from "src/app/settings/utilities/manager-utilities/default-user-config/default-user-config-model";
import { API as settingsAPI } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { DefaultUserConfigDataService } from 'src/app/settings/utilities/manager-utilities/default-user-config/default-user-config.data.service';
import { RetailItemType } from 'src/app/retail/retail.modals';
import { PlayerRetailItem } from 'src/app/tee-time-actions/edit-retail-item/edit-retail-item-model'
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { SettingsDataService } from 'src/app/shared/data-services/golfschedule/settings.data.service';
import { CourseDataService } from 'src/app/shared/data-services/golfschedule/course.data.service';
import { TeeTimesActionService } from 'src/app/shared/data-services/golfschedule/teeTimesAction.data.service';
import { RetailBussinessService } from 'src/app/shared/retail.bussiness';
import { DynamicPricingPlayerFee } from 'src/app/tee-time-actions/teetime/player-details/player-info.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { PlayerTypeRateTypeChangeService } from 'src/app/shared/service/playerTypeRateTypeChange.service';
import { ScheduledTeeTimeUnPaidPlayer } from 'src/app/shared/models/unpaid-players.model';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
@Component({
  selector: 'app-tee-player-details',
  templateUrl: './tee-player-details.component.html',
  styleUrls: ['./tee-player-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TeePlayerDetailsBusiness, TeePlayerDetailsService, TeeTimesActionBusiness, DMConfigDataService, EformsBusiness,TeeSheetSharedBusiness,RefundBussiness],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeePlayerDetailsComponent implements OnInit {
  @Input() teeSheetID: TeeSheetTableID;
  @Input() PlayerCollection: TeeSheetSkeletonData;
  @Input() PlayerCollectionList;
  @Input() customTableData;
  @Output() DrapDropHandler = new EventEmitter();
  @Output() gridRefresh = new EventEmitter();
  @Output() OnRemovePlayer = new EventEmitter();
  @Output() OnEditPlayer = new EventEmitter();
  @Output() onDeletePlayer = new EventEmitter();
  @Output() OnGridDragStart = new EventEmitter();
  @Output() OnDropFailed = new EventEmitter<boolean>();
  @Output() updatePopoverEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() RefreshGrid = new EventEmitter<boolean>();
  @Input() fromTeeSheet: false;  
  @Input() courseId: number;
  @Input() isFromMove: boolean = false;

  isHoteReserv:boolean = true;
  isCustomFiels:boolean = true;
  captions: any;
  apiResponse: boolean;
  dragData: any;
  draggedPositionData: any;
  currentSlotInfo: TeeSlotInfo;
  scheduleStatus = ScheduleStatus;
  scheduleType = ScheduleType;
  bookingSource = BookingSource;
  restrictedSlots: any[] = [];
  PlayerStatusLables: any;
  tableHeaders: TableHeaderOptions[];
  tableOptions: TableOptions;
  tableContent: any[];
  guestsToAddRemove: { playerName:string, playerLinkId: string, messageToBeshown: string, proceedBooking: boolean, course: string, teeTime: string }[] = [];
  slotBoundaries: any; //event data
  @ViewChild('infoPopover') infoPopover;
  @ViewChild('popOverTarget') popOverTarget;
  isEditDisabled = false;
  rowDataObject: any;
  gender = Gender;
  teeTimeFormat = TeeTimeFormat;
  playerCategory = PlayerCategory;
  @Input() isMultiCourse: boolean = false;
  dmConfig: DmConfig;
  isEformsEnabled : boolean = false;
  $destroy: ReplaySubject<boolean> = new ReplaySubject(1);
  isAllocationBlockEnabled: boolean;
  commonCaptions: any;
  dynamicPricingRateSetup: DynamicPricingPlayerFee[] = []; 
  isDynamicPricingEnabled: boolean = false;
  rentalItems = [];
  retailItems = [];
  palyerInfo: ScheduledTeeTimeUnPaidPlayer;
  errorCaptions: any;
  public isHistoricDate: boolean = false;
  @ViewChild(MatMenuTrigger) trigger:MatMenuTrigger;
  @Output() isPayFormDirty = new EventEmitter<boolean>();
  @Input() overrideRateTypePerDynamicAvailability: boolean = false;
  isUpdatePlayerFlag: boolean = false;
  isSetScreenDisable: boolean;
  isRentalEnabled: boolean;
  @Input('FiltSec')
  set setValue(value) {
    if(value && (value.length>=2 || value.length==0)){
        this.isHoteReserv=true;
        this.isCustomFiels=true;
    }
    else {
      if(value){
        value.forEach(element => {
          if(element.id==1){
            this.isHoteReserv=true;
            this.isCustomFiels=false;
          }
          else{
            this.isHoteReserv=false;
            this.isCustomFiels=true;
          }
        });
      }
      
    }
  }
  constructor(
    private _TeePlayerDetailsBusiness: TeePlayerDetailsBusiness, public localization: GolfLocalization, public _TeeGridService: TeeGridService,private _Router: Router, private _teeSheetSharedBusiness: TeeSheetSharedBusiness,
    public _utilities: GolfUtilities, private teeActionBusiness: TeeTimesActionBusiness, private fb: UntypedFormBuilder,
    private _retailSharedVariableService : RetailSharedVariableService,
    private _settingDataService: SettingsDataService,
    private _defaultUserConfig: DefaultUserConfigDataService,
    private _courseDataService: CourseDataService,
    private cdr:ChangeDetectorRef,
    private _teetimeActionService : TeeTimesActionService,
    private _playerTypeRateTypeChangeService: PlayerTypeRateTypeChangeService,
    private _retailBusinessService : RetailBussinessService,
    private _retailFeatureFlag: RetailFeatureFlagInformationService,
    private _propertyInformation: GolfPropertyInformation) {
      this.isRentalEnabled = _retailFeatureFlag.IsRentalEnabled;
    }

  ngOnInit() {
    this.captions = this._TeePlayerDetailsBusiness.getCaptions();
    this.errorCaptions = this.localization.captions.settings;
    this.commonCaptions = this.localization.captions;
    this.initialize();
    this.PlayerStatusLables = {
      [PlayerPaymentstatus.booked]: this.captions.Booked,
      [PlayerPaymentstatus.paid]: this.captions.Paid,
      [PlayerPaymentstatus.unPaid]: this.captions.Booked,
      [PlayerPaymentstatus.booked | PlayerPaymentstatus.unPaid]: this.captions.Booked,
      [PlayerPaymentstatus.booked | PlayerPaymentstatus.refund]: this.captions.Refunded,
      [PlayerPaymentstatus.booked | PlayerPaymentstatus.paid]: this.captions.Paid,
      [PlayerPaymentstatus.booked | PlayerPaymentstatus.unPaid | PlayerPaymentstatus.CheckIn]: this.captions.checkInPopOver,
      [PlayerPaymentstatus.booked | PlayerPaymentstatus.paid | PlayerPaymentstatus.CheckIn]: this.captions.checkInPopOver,
      [PlayerPaymentstatus.booked | PlayerPaymentstatus.refund | PlayerPaymentstatus.CheckIn]: this.captions.checkInPopOver,
      [PlayerPaymentstatus.booked | PlayerPaymentstatus.unPaid | PlayerPaymentstatus.CheckOut]: this.captions.checkOutPopOver,
      [PlayerPaymentstatus.booked | PlayerPaymentstatus.paid | PlayerPaymentstatus.CheckOut]: this.captions.checkOutPopOver,
      [PlayerPaymentstatus.booked | PlayerPaymentstatus.refund | PlayerPaymentstatus.CheckOut]: this.captions.checkOutPopOver,
      [PlayerPaymentstatus.CheckIn]: this.captions.checkInPopOver,
      [PlayerPaymentstatus.CheckOut]: this.captions.checkOutPopOver,
      [PlayerPaymentstatus.noShow]: this.captions.NoShow,
      [PlayerPaymentstatus.cancelled]: this.captions.Cancelled
    }
    if (this.scheduleStatus) {
      this.restrictedSlots = [this.scheduleStatus.hold, this.scheduleStatus.frostDelay];
    }
    this.isHistoricDate = this._utilities.isHistoricDate(this._propertyInformation.CurrentDate, this.PlayerCollection.time);
    this.isEformsEnabled = (sessionStorage.getItem("IsEformsEnabled") === "true") ? true : false;
    this.isAllocationBlockEnabled = this._utilities.IsAllocationCodePermissionEnabled();
    const setting = JSON.parse(sessionStorage.getItem('GOLFSETTING'));
    this.isDynamicPricingEnabled = setting?.enableDynamicPricing;   
  }
 
  async drop(event: CdkDragDrop<string[]>) {

    this.dragData = event.previousContainer.data[event.previousIndex];
    this.dragData.origin = (this.dragData.origin) ? this.dragData.origin : 0;

    if (this.customTableData.isBulkMoveEnabled && this.dragData.origin === DragOrigin.playerlist) {
      this.dropEmitter(this.dragData, event,event.previousContainer.data);
    }
    
    else {
      let dragPlayerList = this._TeeGridService.selectedPlayers;
      // If Drag Origin is from grid, then clear the selected Players.
      if (this.dragData.origin === DragOrigin.grid) {
        this._TeeGridService.selectedPlayers = [];
        dragPlayerList = [this.dragData];
      }

      // Drop Should emit only when the slot is droppable -- Flag Name - isDropAllowed
      if (this.PlayerCollection.isDropAllowed && this._TeeGridService.isDropAllowedOnBulkMove(dragPlayerList, event.container.data)) {
        let teeTimeSetting = sessionStorage.getItem(CacheConstants.TeeTimeSetting);
        let ischeckingOverlappingAllowed = Boolean(JSON.parse(teeTimeSetting).checkForTeeTimeOverlaps);
        let isFromTeeSheet;
        let isSameSlot; 
       
        //Restricting API call for overlaps checking when player is booked from Bulk / Group  / 
        //When in same slot no API call will be sent for checking overlaps
        if(this.isPlayerExistInSlot(event.container.data, this.dragData) )
        {
          this.showSameSlotDragDropError();
          return;
        }
        isFromTeeSheet = (this._TeeGridService.selectedPlayers == null || this._TeeGridService.selectedPlayers.length == 0);
        isSameSlot = this.isSameSlot(event.container.data, this.dragData);
        
         //check whteher ratetype is available per dynamic availability if dynamic pricing is enabled
        if (this.isDynamicPricingEnabled && !this.overrideRateTypePerDynamicAvailability) {
          let result = await this.getDynamicAvailabilityRates(this.dragData.courseId, this.PlayerCollection.time, this.dragData);
          if (result) {
            this._utilities.showAlert(this.captions.Ratetype_unavailable, AlertType.Warning,ButtonType.Ok);
            return;
          }
        }

        if(!this.customTableData.isBulkMoveEnabled && ischeckingOverlappingAllowed)
        {
          let playerLinkIds = [];
          if(!isFromTeeSheet) {
            playerLinkIds = _.uniq(this._TeeGridService.selectedPlayers.filter(o => (o.linkingId != undefined && o.linkingId != "" && o.teeTimeFormat!=TeeTimeFormat.GroupTee)).map(o => o.linkingId));
          }
          else {
            playerLinkIds = _.uniq(dragPlayerList.filter(o => (o.teeTimeFormat!=TeeTimeFormat.GroupTee)).map(o => o.linkingId));
          }
          if(!isSameSlot)
            await this.getOverlappingSlots(playerLinkIds,this.dragData.courseId, isFromTeeSheet);
        }
        // Temp Fix for Not allowing more than 4 players    
        let playersCount = (event.container.data ? event.container.data.length : 0) + (this._TeeGridService.selectedPlayers ? this._TeeGridService.selectedPlayers.length : 0);
        if (playersCount < 5) {

          if (event.previousContainer === event.container) {
            // For previousContainer,
          } else {
            let selectedPlayersLen = this._TeeGridService.selectedPlayers.length;
            // For Multi Drag and Drop, if should work, For Single Drop Else should work
            if (this._TeeGridService.selectedPlayers && selectedPlayersLen > 0) {
              
              // Added for dropping multiple Players into a slot. (Only for walk in players)
              if((!ischeckingOverlappingAllowed) || (this._TeeGridService.selectedPlayers.every(o => o.playerCategoryId == PlayerCategory.Player) || this.guestsToAddRemove.length == 0))
              {
                this.TransferPlayerArray(event,selectedPlayersLen,event.previousContainer.data);
              }
              else
              {  
                let allowAllOverlappingPlayers = this.teeActionBusiness.showOverlappingPlayersPopup(this.guestsToAddRemove);
                allowAllOverlappingPlayers.then(res => { 
                    // console.log(res);
                    if(res == true)
                      this.TransferPlayerArray(event,selectedPlayersLen,event.previousContainer.data); 
                });
                this.guestsToAddRemove = [];
              }
            }
            else {
              let isDataAlreadyExists = this.isPlayerExistInSlot(event.container.data, this.dragData);
              let currentDraggedPlayer: any = event.previousContainer.data[event.previousIndex];
              if(((isFromTeeSheet) && (!isDataAlreadyExists && (!ischeckingOverlappingAllowed || currentDraggedPlayer.playerCategoryId == PlayerCategory.Player  || (currentDraggedPlayer.teeTimeFormat == TeeTimeFormat.BulkTee || currentDraggedPlayer.teeTimeFormat == TeeTimeFormat.GroupTee || currentDraggedPlayer.teeTimeFormat == TeeTimeFormat.Tournament)))))
              {
                transferArrayItem<any>(event.previousContainer.data,
                  event.container.data,
                  event.previousIndex,
                  event.container.data.length);
                this.dropEmitter(this.dragData, event,event.previousContainer.data);
              }
              else 
              {
                if (!isDataAlreadyExists && !isFromTeeSheet) 
                {
                  let playersListio = (this._TeeGridService.selectedPlayers == null || selectedPlayersLen == 0) ? event.previousContainer.data[event.previousIndex] : this._TeeGridService.selectedPlayers;
                  if(playersListio)
                  {
                    let playersDragged = playersListio.length == undefined ? [playersListio] :  playersListio;
                    if(playersDragged.length > 0)
                    {
                      let indx  = this.guestsToAddRemove && this.guestsToAddRemove.length > 0 
                                  ? this.guestsToAddRemove.findIndex(o => o.playerLinkId == playersDragged[0].linkingId) 
                                  : -1;
                      
                      if (indx != -1 && playersDragged[0] != null && playersDragged[0].linkingId != '') {
                        let overLappingMessage = this.guestsToAddRemove.find(o => o.playerLinkId == playersDragged[0].linkingId);
                        if (overLappingMessage && overLappingMessage.messageToBeshown != undefined &&
                          overLappingMessage.messageToBeshown != '') 
                        {
                          let proceedBooking = this._utilities.showAlertWithCustomWidth(overLappingMessage.messageToBeshown, AlertType.Warning, ButtonType.YesNo,OVERLAPPING_PLAYER_WIDTH)
                          .afterClosed().toPromise();
                          proceedBooking.then(res => 
                            {
                              if(res === AlertAction.YES)
                              {
                                if (!isDataAlreadyExists) 
                                {
                                  transferArrayItem<any>(event.previousContainer.data,event.container.data,event.previousIndex,event.container.data.length);
                                  this.dropEmitter(this.dragData, event,event.previousContainer.data);
                                }
                              }
                          }); 
                        }
                        else if(isSameSlot){
                          transferArrayItem<any>(event.previousContainer.data,event.container.data,event.previousIndex,event.container.data.length);
                          this.dropEmitter(this.dragData, event,event.previousContainer.data);
                        }
                      }
                    }
                  }
                }
                else if(isFromTeeSheet) {
                  if(this.guestsToAddRemove && this.guestsToAddRemove.length > 0){
                    this.dragData.guestsToAddRemove = this.guestsToAddRemove;
                  }
                  transferArrayItem<any>(event.previousContainer.data,event.container.data,event.previousIndex,event.container.data.length);
                  this.dropEmitter(this.dragData, event,event.previousContainer.data);
                }
                else {
                  this.showSameSlotDragDropError();
                }
              }
            }
          }
        }
        else {
          this._utilities.showAlert(this.captions.NumberExceedsMaxDrop, AlertType.Warning, ButtonType.Ok);
          this.OnDropFailed.emit(true);
        }
      } else {
        this.OnDropFailed.emit(true);
      }
    }

  }

  dropEmitter(dragData, event, players?) {
    this.reOrderPlayers(event.previousContainer.data, event.container.data);
      let playerItem = [];
      let scheduledTeeTimePlayerIds=[];
      let isRetailItemsAvailable = players!= undefined && players.length >1 ? players.some(x=>x.isRetailItemsAvailable): dragData.isRetailItemsAvailable;
      let isRentalItemsAvailable = players!= undefined && players.length >1 ? players.some(x=>x.isRentalItemsAvailable): dragData.isRentalItemsAvailable;
      if((isRetailItemsAvailable || isRentalItemsAvailable) && this.isFromMove){
        scheduledTeeTimePlayerIds = players!= undefined && players.length >1 ? players.map(x=>x.scheduledTeeTimePlayerId):[dragData.scheduledTeeTimePlayerId];
        if(!scheduledTeeTimePlayerIds.includes(dragData.scheduledTeeTimePlayerId)){
          scheduledTeeTimePlayerIds.push(dragData.scheduledTeeTimePlayerId);
        }
        this.SetDefaultOutlet(dragData.courseId,event,dragData,scheduledTeeTimePlayerIds);        
      }  else {
        this.DrapDropHandler.emit({
          dragData: dragData,
          dragIndex: event.previousIndex,
          dragPositionID: event.previousContainer.id,
          dropPositionID: event.container.id,
          dragPositionObject: this._TeeGridService.rowDataObject,
          dropIndex: event.container.data.length,
          dragOrigin: dragData.origin,
          playerRetailItems : playerItem
        });
      }
      
  }

  reOrderPlayers(dragSlotData, dropSlotData) {
    dragSlotData.forEach((element, index) => {
      element.playPos = index + 1;
    });
    // dropSlotData.forEach((element, index) => {
    //   element.playPos = index + 1;
    // });
  }

  async CheckItemsAreFromSelectedOutletForMove(dragData,event,scheduledTeeTimePlayerIds){
    let retailItems = [];
    let rentalItems = [];
    let res : boolean = false;
    let retailItemForPlayer : PlayerRetailItem[];
    retailItemForPlayer = await this._teetimeActionService.getPlayerRetailItems(scheduledTeeTimePlayerIds);
    rentalItems = retailItemForPlayer?.filter(x=>x.retailItemType == RetailItemType.PMSAddOnsRentalItem);    
    retailItems = retailItemForPlayer?.filter(x=>x.retailItemType == RetailItemType.RetailItemRetailPOSOnly);
    if(rentalItems?.length > 0){
      const errorMsg = this.localization.getError(20701);
      await this._utilities.showAlert(errorMsg, AlertType.Warning, ButtonType.Ok, (res => {
        if(res){
          if(retailItems?.length > 0){
          this.retailItemsOutletValidation(retailItems).then(x => {
            retailItemForPlayer = x;  
            this.DrapDropHandler.emit({
              dragData: dragData,
              dragIndex: event.previousIndex,
              dragPositionID: event.previousContainer.id,
              dropPositionID: event.container.id,
              dragPositionObject: this._TeeGridService.rowDataObject,
              dropIndex: event.container.data.length,
              dragOrigin: dragData.origin,
              playerRetailItems : retailItemForPlayer
            });          
          });
        }
        else{
          this.DrapDropHandler.emit({
            dragData: dragData,
            dragIndex: event.previousIndex,
            dragPositionID: event.previousContainer.id,
            dropPositionID: event.container.id,
            dragPositionObject: this._TeeGridService.rowDataObject,
            dropIndex: event.container.data.length,
            dragOrigin: dragData.origin,
            playerRetailItems : []
          });    
        }
        }
    })).afterClosed().toPromise();
  }
  else if(rentalItems?.length == 0 && retailItems?.length > 0){
    await this.retailItemsOutletValidation(retailItems).then(x => {
      retailItemForPlayer = x;    
      this.DrapDropHandler.emit({
        dragData: dragData,
        dragIndex: event.previousIndex,
        dragPositionID: event.previousContainer.id,
        dropPositionID: event.container.id,
        dragPositionObject: this._TeeGridService.rowDataObject,
        dropIndex: event.container.data.length,
        dragOrigin: dragData.origin,
        playerRetailItems : retailItemForPlayer
      }); 
    });
  }

}

async retailItemsOutletValidation(retailItems:any) : Promise<PlayerRetailItem[]>{
  let CheckItems = false;
  let retailItemForPlayer = [];
  if(retailItems?.length > 0){
    var retailProducts: any[] =[];
    retailItems?.forEach(x=>{
      var y =JSON.parse(x.retailItems);
      y.forEach(z=>{
        z.id = z.ItemId;
        retailProducts.push(z);
      });
    });
   CheckItems =  await this._retailBusinessService.CheckItemsAreFromSelectedOutlet(retailProducts,true);
   if (!CheckItems) {
     retailItemForPlayer = [];
   } else {
     retailItemForPlayer = retailItems;
   }
   return retailItemForPlayer;
  }
}

async SetDefaultOutlet(courseId,event,dragData,scheduledTeeTimePlayerIds){
  let defaultConfig = await this.getDefaultSettings();
    this._retailSharedVariableService.DeletePreviousPlayer = false;
    if (defaultConfig.defaultOutletType == DefaultOutletType.course) {
      let course = await this._courseDataService.getCourse(courseId);
      await this.settingDefaultOutlet(course?.defaultOutletId);
      this._retailBusinessService.ValidateSelectedTerminalExistsInSelectedOutlet();
      this.CheckItemsAreFromSelectedOutletForMove(dragData,event,scheduledTeeTimePlayerIds);
    } else {
      const defaultConfig = await this.getUserDefaultConfig(this.localization.GetPropertyInfo("UserId"));
      let defaultOutletId = defaultConfig.defaultOutletId > 0 ? defaultConfig.defaultOutletId : this._retailSharedVariableService.SelectedOutletId;
      await this.settingDefaultOutlet(defaultOutletId);
      this.CheckItemsAreFromSelectedOutletForMove(dragData,event,scheduledTeeTimePlayerIds);
    }   
}

async settingDefaultOutlet(defaultOutletId: number) {
  if (this._retailSharedVariableService.selectedProducts.length > 0) {
    if (this._retailSharedVariableService.SelectedOutletId != defaultOutletId) {
      var res = this._utilities.showAlert(this.localization.captions.teetime.differentOutletSelection, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this._retailSharedVariableService.SelectedOutletId = defaultOutletId;
          this._retailSharedVariableService.selectedProducts = [];
          this._retailSharedVariableService.DeletePreviousPlayer = true;
        } else {
          this._retailSharedVariableService.DeletePreviousPlayer = false;
        }
      });
      await res.afterClosed().toPromise();
    }
  } else {
    this._retailSharedVariableService.SelectedOutletId = defaultOutletId;
  }
}
public async getDefaultSettings(): Promise<DefaultsSettingConfig> {
  let defaultsApiData: settingsAPI.Settings<settingsAPI.DefaultsSettingConfig> = await this._settingDataService.getSettings<settingsAPI.DefaultsSettingConfig>(SettingModule.SystemSetup, SettingScreen.Defaults);
  return this.mapDefaultData(defaultsApiData.configValue);
}

async getUserDefaultConfig(userId: string): Promise<DefaultUserConfiguration> {
  let userDefaultConfig = await this._defaultUserConfig.GetDefaultUserConfiguration(userId);
  if (userDefaultConfig) {
    return userDefaultConfig;
  }
  return undefined;
}

private mapDefaultData(configValue: settingsAPI.DefaultsSettingConfig): DefaultsSettingConfig {
  return {
    blockingCode: configValue.blockingCode,
    memberStatus: configValue.memberStatus,
    memberPlayerType: configValue.memberPlayerType,
    memberRateType: configValue.memberRateType,
    nonMemberPlayerType: configValue.nonMemberPlayerType,
    nonMemberRateType: configValue.nonMemberRateType,
    resortPlayerType: configValue.resortPlayerType,
    resortRateType: configValue.resortRateType,
    defaultOutletType: configValue.defaultOutletType,
    memberGuestPlayerType: configValue.memberGuestPlayerType,
    memberGuestRateType: configValue.memberGuestRateType,
    memberGuestsToAdd: configValue.memberGuestsToAdd,
    memberGuestPlayerFirstName: configValue.memberGuestPlayerFirstName,
    memberGuestPlayerLastName: configValue.memberGuestPlayerLastName,
    markAsPaidPaymentMethod: configValue.markAsPaidPaymentMethod,
    markAsPaidPaymentMethodReceiptComment: configValue.markAsPaidPaymentMethodReceiptComment,
    enableNonZeroRateForMarkAsPaid: configValue.enableNonZeroRateForMarkAsPaid
  }
}

  // On Grid Drag Start Set Drag Origin and Emit to Copy, Move and Copy to Many Page to unselect the Selected Player.
  onDragStart(e, player, rowDataObject) {
    player.origin = DragOrigin.grid;
    this._TeeGridService.rowDataObject = rowDataObject;
    this.OnGridDragStart.emit(false);
  }

  onTeeGridDragScroll(e) {
    this._TeeGridService.scrollTeeGrid(e, this.customTableData, this.teeSheetID, undefined, 'TableTeeTime', 'TableTeeTimeRight');
  }

  async removePlayer(event) {
    this.OnRemovePlayer.emit(event);
  }



  async deletePlayer(event, rawData) {

    console.log(event);
    console.log(rawData);
    if(event.bookingSource === BookingSource.golfNow){
      this._utilities.showAlert(this.captions.lbl_ErrorGolfNowCancelError,AlertType.Warning);
          return;
    }
    let isDestinationSlotRateChanged: boolean = false;
    var teeTimeSettingss = JSON.parse(sessionStorage.getItem("TEETIMESETTING"));
    if(!event.isBlocked && teeTimeSettingss &&  teeTimeSettingss.cancelPlayerFromTeeSheet)
    {
      let teeSheetSkeletonData = cloneDeep(rawData);
      teeSheetSkeletonData.playerDetail = teeSheetSkeletonData.playerDetail.filter(x=> x.playerId == event.playerId);
      teeSheetSkeletonData.isFromTeeSheetForCancel = true;
      let arg:TeeActionsClick = [TeeTimeActions.cancelPlayers, teeSheetSkeletonData];
      this._teeSheetSharedBusiness.teeActionClick(arg, this.actionSuccessCallback.bind(this));
      return isDestinationSlotRateChanged;
    }
    else {
      let message = this.captions.deleteConfirmationMessage;
      this._utilities.showAlert(message, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res === AlertAction.NO) {
          isDestinationSlotRateChanged = true;
        }
        else {
          if ((event.playerStatus & PlayerPaymentstatus.unPaid) != 0 && event.transactionId > 0) {
            this.teeActionBusiness.ShowTransactionPendingDialog(event, this.DeletePlayer.bind(this), false);
          } else {
            this.ClearPlayers(event);
          }
        }
        return isDestinationSlotRateChanged;
      });
    }
  }

  private actionSuccessCallback(actionFlag: boolean): void {
    console.log(actionFlag);
    // let params = this._Router.parseUrl(this._Router.url).queryParams;
    // if (params && params["action"]) {
    //   let action: number = parseInt(params["action"]);
    //   if (action == 2 || action == 3)
    //     this._Router.navigate(['tee-time/teesheet/teeSheet']);
    // }
  }
  ClearPlayers(event) {
    this.DeletePlayer(event);
  }

  DeletePlayer(event) {
    this.onDeletePlayer.emit(event);
  }

  isPlayerExistInSlot(slotData, dragData) {
    if (this.customTableData.isDropRestrictedOnSameSlot) {
      return slotData.filter(x => x["playerId"] === dragData.playerId).length === 0 ? false : true;
    }
    else {
      return false;
    }
  }
  // FOR POPOVER

  mouseOver(e, info: TeeSheetPlayerDetails) {

    this.slotBoundaries = e.currentTarget.getBoundingClientRect();
    this.currentSlotInfo = {
      title: info.isBlocked ? this.captions.lblBlocked : this.PlayerStatusLables[info.playerStatus],//this.PlayerCollection.status.name,
      color: "#000000", // Color is set to default Black Color.
      bodyInfo: [
        {
          icon: 'icon-user', iconColor: '',
          description: info.isBlocked ? this.captions.lblBlocked : this._utilities.formatGuestName(info.firstName, info.lastName), color: ''
        },
        { icon: 'icon-clock', iconColor: '', description: this.localization.LocalizeTime(this.PlayerCollection.time, true), color: '' },
        { icon: 'icon-course-flag', iconColor: '', description: this.PlayerCollection.unmodifiedSlotData.course.course, color: '' },
        {
          icon: info.isMarkAsPaid ? 'icon-Mark-Paid' : info.isPaidPlayer ? 'icon-rate' : '', iconColor: '', description: info.isPaidPlayer ?
            (`${this.localization.localizeCurrency(info.amountPaid)} - ${this.captions.PAID} - ${info.ticketNumber}`) : (!info.isPaidPlayer && ((info.playerStatus & PlayerPaymentstatus.refund) == 0) && info.transactionId && info.transactionId > 0 && info.ticketNumber && info.ticketNumber != "" && !(info.deposits && info.deposits?.length > 0 && info.deposits.some(x => x.depositTransactionId == info.transactionId)) && !(info.deposits && info.deposits?.length > 0 && info.deposits.map(x => x.refundAmount).reduce((acc, cur) => acc + cur, 0)==0) ? `${this.captions.UnPaid} - ${info.ticketNumber}` : ""), color: 'green'
        },
        { icon: 'icon-Member-new', iconColor: '', description: info.memberNumber, color: '' },
        { icon: 'icon-hash', iconColor: '', description: info.confirmationNumber, color: '' }
      ],
      commentLabel: 'Comment',
      comment: (info.playerComment == null || info.playerComment == '') ? '-' : info.playerComment,
      groupBookingName: (info.playerStayDetail && info.playerStayDetail.groupBookingName != null) ? info.playerStayDetail.groupBookingName : '',
      player : info,
      isEformsEnabled : this.isEformsEnabled
    };
  }

  onShown(e) {
    let popOverTargetPosition = this.popOverTarget.nativeElement.getBoundingClientRect();
    let windowWidth = window.innerWidth;
    let popOverWidth = this.infoPopover.element.nativeElement.childNodes[0].offsetWidth;
    let cdkOverlayPaneWidth = 0;
    let cdkOverlayPaneOffsetLeft = 0;
    let offsetRight = 0;
    let actualLeft = e.content.left;
    let targetLeft = popOverTargetPosition.left;
    let cdkOverlayPane = document.getElementsByClassName('cdk-overlay-pane')[0] as HTMLElement;
    if (this.customTableData.isTabTooltip) {
      cdkOverlayPaneWidth = cdkOverlayPane.offsetWidth;
      cdkOverlayPaneOffsetLeft = cdkOverlayPane.offsetLeft;
      offsetRight = cdkOverlayPaneWidth + cdkOverlayPaneOffsetLeft;
    } else {
      offsetRight = windowWidth;
    }
    let checkRightPosition = offsetRight - popOverWidth;

    if (this.customTableData.isTabTooltip) {
      if (actualLeft < 0) {
        e.content.left = 0;
        let calcArrow = 20;
        this.checkArrowTimeout(calcArrow, 0);
      } else if ((actualLeft + popOverWidth + cdkOverlayPaneOffsetLeft + 5) > offsetRight) {
        e.content.left = offsetRight - (popOverWidth + cdkOverlayPaneOffsetLeft);
        let calcArrow = (((windowWidth - popOverTargetPosition.left) / popOverWidth) - ((popOverTargetPosition.width / 2) / popOverWidth));
        if ((calcArrow * 100) < 10) {
          this.checkArrowTimeout((1 - ((popOverTargetPosition.width / 2) / popOverWidth)) * 100, 0);
        } else {
          this.checkArrowTimeout((calcArrow) * 100, 0);
        }
      }
    } else {
      if (targetLeft < (popOverWidth / 2)) {
        e.content.left = 0;
        let calcArrow = 20;
        this.checkArrowTimeout(calcArrow, 0);
      } else if (targetLeft > checkRightPosition) {
        e.content.left = checkRightPosition;
        let calcArrow = (((windowWidth - popOverTargetPosition.left) / popOverWidth) - ((popOverTargetPosition.width / 2) / popOverWidth));
        if ((calcArrow * 100) < 10) {
          this.checkArrowTimeout((1 - ((popOverTargetPosition.width / 2) / popOverWidth)) * 100, 0);
        } else {
          this.checkArrowTimeout((1 - calcArrow) * 100, 0);
        }
      }
    }
  }

  checkArrowTimeout(arrowPosition, arrIndex) {
    let checkArrow = setTimeout(() => {
      let popOverArrow = document.getElementsByClassName('infoPopover')[arrIndex].querySelector('.arrow') as HTMLElement;
      if (popOverArrow) {
        popOverArrow.style.left = arrowPosition + '%';
        clearTimeout(checkArrow);
      }
    }, 0);
  }

  getTeeSlotText(slotInfo: TeeSheetSkeletonData): string {
    let text: string = '';
    if (slotInfo.type == ScheduleType.crossOverBlock) {
      text = this.captions.crossover;
    }
    else {
      text = this.GetAllocationCodeName(slotInfo.allocation);
    }
    return text;
  }

  GetAllocationCodeName(allocation: Allocation) {
    let displayName: string = this.captions?.bookTeeTime;
    if (allocation != null) {
      displayName = (allocation.closed || allocation.displayOnTeesheet) ? allocation.name.toUpperCase() : displayName;
    }
    return displayName;
  }

  getLocalizedPlayerPosition(listOrder) {
    if (listOrder) {
      return this.localization.replacePlaceholders("P{listOrder}", ["listOrder"], [listOrder])
    } else {
      // return this.captions.PlayerSlot;as discussed with BA #114390 'P' remains same across any language. 
      return 'P';
    }

  }

  getplayerName(player) {
    return player ? this._utilities.formatGuestName(player.firstName, player.lastName, this.isMultiCourse) : ""

  }

  getIsOpenTransactionExist(isDetailView, player: any) {
    return isDetailView && player && !player.isPaidPlayer && ((player.playerStatus & PlayerPaymentstatus.refund) == 0) && player.transactionId && player.transactionId > 0 && player.ticketNumber && player.ticketNumber != '' && !(player.deposits && player.deposits?.length > 0 && player.deposits.some(x => x.depositTransactionId == player.transactionId))
  }



  editPlayer(data, idx) {
    console.log(data);
    this.isEditDisabled = true;
    this.OnEditPlayer.emit([data, idx]);
  }

  LocalizeDate(date: Date){
    var localizedDate = this.localization.LocalizeDate(date);
    return localizedDate
  }

  async getOverlappingSlots(playerLinkIds,courseId,isFromTeeSheet)
  {
    this._utilities.ToggleLoader(true);
    if(courseId == null || courseId == 0)
      courseId = this.customTableData?.teeActionPerformedCourse;
    let playersOverLappings = await this.teeActionBusiness
    .CheckOverLappingBookingForGuest(playerLinkIds, null, this.localization.convertDateTimeToAPIDateTime(new Date(this.PlayerCollection.time)),this.courseId);
    // when dragged and dropped on tee sheet
    if(isFromTeeSheet)
    {
      this.courseId = this.dragData.courseId;
    }

    playersOverLappings = this.teeActionBusiness.getOverlappingSlots(playersOverLappings,this.dragData.time,
      this.PlayerCollection.time,this.dragData.courseId,this.courseId);
    
    if (playersOverLappings != null && playersOverLappings.length > 0) {
      console.log(playersOverLappings);
      this.guestsToAddRemove = this.teeActionBusiness.getOverlappingGuestDatas(playersOverLappings,isFromTeeSheet,isFromTeeSheet);
    }
    this._utilities.ToggleLoader(false);
  }

  
  TransferPlayerArray(event,selectedPlayersLen,players?)
  {
    let isDataAlreadyExists:boolean;
    for (let index = 0; index < selectedPlayersLen; index++) 
    {
      const data = this._TeeGridService.selectedPlayers[index];
      isDataAlreadyExists = this.isPlayerExistInSlot(event.container.data, data); 
      if (!isDataAlreadyExists) 
      {
        let eventData : any[] = event.container.data;
        let pos = event.container.data.length + 1;
        let positionArr = [1,2,3,4];
        let bookedPosSlotsArr: any[] = cloneDeep(eventData.filter(x=>x.playPos));
        let bookedSlotNos: number[] = bookedPosSlotsArr.map(x => x.playPos);
        console.log(bookedSlotNos);
        let availableSlotnNos: number[] = positionArr.filter(x => !bookedSlotNos.includes(x));  
        console.log(availableSlotnNos);
        let existingPos = bookedSlotNos.includes(pos);
        if (existingPos) {
        data.playPos = availableSlotnNos[0];
        } else {
        data.playPos = pos;
        }
        //data.playPos = event.container.data.length + 1;
        transferArrayItem<any>([data],
        event.container.data,
        event.previousIndex,
        event.container.data.length + index);
      }
      else 
        {
          this._utilities.showAlert(this.captions.RestrictPlayersOnSameSlot, AlertType.Warning, ButtonType.Ok);
          break;
        }
      }
      if (!isDataAlreadyExists) 
      {
        this.dropEmitter(this.dragData, event,players);
      }
  }

  showSameSlotDragDropError(){
    this._utilities.showAlert(this.captions.RestrictPlayersOnSameSlot, AlertType.Warning, ButtonType.Ok);
    this.OnDropFailed.emit(true);
  }

  private isSameSlot(dragData, dropData): boolean {
    if(dragData != null && dragData.length  && dropData != null)
      return dragData[0].scheduleTeeTimeId == dropData.scheduleTeeTimeId;
  }

  getColorcode(playerdata) {
    if (playerdata.isBlocked) {
      return playerdata.blockedSlotColor;
    }
  }

  initialize() {
  }
  payForm(formValues) {

  }
  async getDynamicAvailabilityRates(courseId, slotDateTime, draggedData): Promise<boolean> {
    let result = false;
    await this.teeActionBusiness.GetDynamicPricingForCourseAndScheduledDate(courseId, slotDateTime).then(x => {
      this.dynamicPricingRateSetup = x;
      let rates = this.dynamicPricingRateSetup.filter(z => z.rateTypeId === draggedData.rateType.id && z.stopAvailability);
      if (rates.length > 0) {
        result = true;
      }
    });
    return result;
  }

  async getRetailOrRentalItems(data){
    if(data.isRentalItemsAvailable || data.isRetailItemsAvailable)
    {
      this.retailItems = [];
      this.rentalItems = [];
      this.teeActionBusiness.getScheduledPlayerRetailItems([data.scheduledTeeTimePlayerId]).then(response=>{
      this.retailItems = response.filter(y=>y.retailItemType==RetailItemType.RetailItemRetailPOSOnly)?.map(x => this.mapRetailOrRentalItems(x));
      this.rentalItems = response.filter(y=>y.retailItemType==RetailItemType.PMSAddOnsRentalItem)?.map(x => this.mapRetailOrRentalItems(x));
      this.retailItems = [...this.retailItems]
      this.rentalItems = [... this.rentalItems]
      this.cdr.detectChanges();
      });
      
    }
  }
mapRetailOrRentalItems(item)
{
  return {
    itemDescription : item.itemDescription,
    quantity: `(${item.totalQuantityCount})`
  }
}
  
  async onAction(eve){
    this.isSetScreenDisable = false;
    this.updatePopoverEmitter.emit(this.isSetScreenDisable);
    let isRefreshGrid:boolean = false;
    if(eve.emitFrom != PaymentModalType.close && eve.emitFrom != PaymentModalType.cancel)
    {
      let allowPay:boolean = eve.emitFrom == PaymentModalType.pay || eve.emitFrom == PaymentModalType.shop;
      let allowsave:boolean = eve.form.dirty && eve.form.valid;
      if(allowsave)
      {
        let isAllowPay = allowPay;
        if(this._playerTypeRateTypeChangeService.isQuickIdPopupEnableForSave)isAllowPay = allowsave = await this._playerTypeRateTypeChangeService.showSaveQuickIdPopup([eve.rowObject]);
        if(allowsave){
          let updatePlayerFees = this._playerTypeRateTypeChangeService.mapUpdatePlayerFee([eve.rowObject]);
          this._utilities.ToggleLoader(true);
          isRefreshGrid = isAllowPay = await this._playerTypeRateTypeChangeService.UpdatePlayerFees(updatePlayerFees);
          //Commented for tee time temp hold for playertype ratetype update
          // this.tempHold(TempHoldStatus.release); 
          this._utilities.ToggleLoader(false);
          if(!isAllowPay) {
            this._utilities.showAlert(this.errorCaptions.playerTypeRateTypeUpdateError, AlertType.Error, ButtonType.Ok);
          }
        }
        allowPay = allowPay ? isAllowPay : allowPay;
      }
      if(allowPay)
      {
        //Commented for tee time temp hold for playertype ratetype update
        // if(!allowsave)this.tempHold(TempHoldStatus.release);
        this._utilities.ToggleLoader(true);
        var isPayHaveAccess = await this._playerTypeRateTypeChangeService.validatePayBreakPointAccess();
        this._utilities.ToggleLoader(true);
        if(isPayHaveAccess.isAllow && await this._playerTypeRateTypeChangeService.validateSelectedOutlet(eve.rowObject.courseId)){
          this._playerTypeRateTypeChangeService.holdPlayers([eve.rowObject.playerId]).then(async (res)=>{
            if(res){
              this._utilities.ToggleLoader(true);
              var tableDatas = await this.getPlayerDetail(eve);
              this._utilities.ToggleLoader(true);
              this._playerTypeRateTypeChangeService.ProceedToOrderSummary(tableDatas, null,FromAction.TeeGrid, eve.emitFrom == PaymentModalType.pay).then(res=>{
                this._utilities.ToggleLoader(false);
              }).catch(e=>{
                this._utilities.ToggleLoader(false);
              });
            }
            else {
              this._utilities.ToggleLoader(false);
            }
          }).catch(e=>{
            this._utilities.ToggleLoader(false);
          });
            
        }
      }
    }
    this.closePopover(isRefreshGrid);  
    
  }

  async getPlayerDetail(eve)
  {
    try{
      this._utilities.ToggleLoader(true);
      let tableDatas = await this._playerTypeRateTypeChangeService.GetPlayer(eve.rowObject.scheduledTeeTimePlayerId, eve.rowObject.courseId, eve.rowObject.scheduledAPIDateTime, eve.rowObject.allocationBlockId);
      tableDatas.forEach(x=>{
        this._playerTypeRateTypeChangeService.updatePlayerTypeRateTypeTableDetails(x);
      });
      return this._playerTypeRateTypeChangeService.mapEditDetailToUnpaidPlayer(eve.rowObject, tableDatas);
    }
    catch(ex){
      this._utilities.ToggleLoader(false);
    }
  }

  async isUpdatePlayerPopover(player){
    try{
      console.log(player);
      console.log(this.PlayerCollection);
      //Commented for tee time temp hold for playertype ratetype update
      // if(await this.tempHold(TempHoldStatus.hold)){
        this.isSetScreenDisable = true;
        this.updatePopoverEmitter.emit(this.isSetScreenDisable);
        this.palyerInfo = this._playerTypeRateTypeChangeService.mapTeeTimeDetailToUnpaidPlayer(player);
        this.isUpdatePlayerFlag = true;
      // }
    }
    catch(ex){
      //Commented for tee time temp hold for playertype ratetype update
      // await this.tempHold(TempHoldStatus.release);
      this._utilities.ToggleLoader(false);
    }
  }

  async tempHold(status:TempHoldStatus){
    return await this._playerTypeRateTypeChangeService.tempHoldTeeTime(this.PlayerCollection.course.id, this.PlayerCollection.hole, this.PlayerCollection.originalHoleNumber, this.PlayerCollection.allocationDateTime, status);
  }

  async payNow(player)
  {
    try{
      let teeTimeSetting = sessionStorage.getItem(CacheConstants.TeeTimeSetting);
      let setPaidOnDayOfPlay = Boolean(JSON.parse(teeTimeSetting).setPaidOnDayOfPlay);
      let isAllow = this._playerTypeRateTypeChangeService.CheckPayTeeTimeDayOfPlay(setPaidOnDayOfPlay, new Date(player.time));
      if(isAllow){
        this._utilities.ToggleLoader(true);
        var isPayHaveAccess = await this._playerTypeRateTypeChangeService.validatePayBreakPointAccess();
        this._utilities.ToggleLoader(true);
        if(isPayHaveAccess.isAllow && isAllow && await this._playerTypeRateTypeChangeService.validateSelectedOutlet(player.courseId)){
          this._playerTypeRateTypeChangeService.holdPlayers([player.playerId]).then(async (res)=>{
            if(res){
              this._utilities.ToggleLoader(true);
              let tableDatas = await this._playerTypeRateTypeChangeService.GetPlayer(player.scheduledTeeTimePlayerId, player.courseId, player.time, player.allocationBlockId);
              this._utilities.ToggleLoader(true);
              this._playerTypeRateTypeChangeService.ProceedToOrderSummary(tableDatas, null,FromAction.TeeGrid, true).then(res=>{
              this._utilities.ToggleLoader(false);
              }).catch(e=>{
                this._utilities.ToggleLoader(false);
              });
            }
            else{
              this._utilities.ToggleLoader(false);
            }
          }).catch(e=>{
            this._utilities.ToggleLoader(false);
          });
        }
        else this._utilities.ToggleLoader(false);
      }
    }
    catch(ex){
      this._utilities.ToggleLoader(false);
    }
  }

  closePopover(isRefreshGrid:boolean = false){
    this.isUpdatePlayerFlag = false;
    this.trigger.closeMenu();
    if(isRefreshGrid) this.gridRefresh.emit(true);
  }

}