import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { ActionMode, ButtonType } from 'src/app/common/enums/shared-enums';
import { TableHeaderOptions, TableOptions, TableSearchHeader } from 'src/app/common/Models/ag-models';
import { AlertAction, AlertType } from 'src/app/common/Models/common.models';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from "src/app/common/shared/shared/utilities/common-utilities";
import { SftpBusiness } from '../sftp.business';
import { API,UI, FtpProtocols } from 'src/app/common/Models/sftp.model';

@Component({
  selector: 'app-sftp-landing',
  templateUrl: './sftp-landing.component.html',
  styleUrls: ['./sftp-landing.component.scss'],
  providers: [SftpBusiness]
})
export class SftpLandingComponent implements OnInit {
  searchHeaderOption: TableSearchHeader;
  captions: any;
  searchText: string;
  showInactiveTog: boolean = false;
  CreationToggler: boolean;
  crudActionInput: { mode: any; actionButton: any; form: any; listOrder: any };
  createModalData: { title: any; };
  IsEditModeEnabledFlag = false;
  headerOptions: TableHeaderOptions[];
  tableOptions: TableOptions;
  isViewOnly: boolean;
  tableContent:any[] = [];
  originalData:any[] = [];
  createform: UntypedFormGroup;



constructor(private localization: Localization,
    private business : SftpBusiness,
    private utils: CommonUtilities) {
    this.captions = this.localization.captions;

   }

  ngOnInit(): void {
    this.searchHeaderOption = {
      createBtnLabel: this.captions.create_btn_sftp,
      searchPlaceHolder: this.captions.srch_userName_hostName,
      showPrint: false,
      hideAdvanceSearch: true,
      toggleLabel: this.captions.tog_lbl_showInactive,
      showInactive: true,
      toggleDisabled: false,
      createBtnDisabled: false,
      hasHeaderAction: false,
      advancedSearch: this.captions.lbl_advancedSearch,
      searchDisabled: false,
      maxLength: 12
    };
    this.initialize();
  }

  async initialize() {
    this.tableOptions = this.business.getTableOptions(this.isViewOnly);
    this.headerOptions = this.business.getHeaderOptions();
    await this.getTableData();

  }
  searchChange(e) {
    this.searchText = e;
  }

 tableAction(event) {
    switch (event.fromType) {
      case FromTypeEnum.edit:
        this.IsEditModeEnabledFlag = true;
        this.editForm(event.Obj)
        break;
      case FromTypeEnum.delete:
        this.delete(event.Obj)
        break;
      case FromTypeEnum.switch:
        const ftpConnection = {
            id: event.Obj.id,
            connectionName: event.Obj.connectionName,
            hostName: event.Obj.hostName,
            userName: event.Obj.userName,
            password: event.Obj.password,
            port: event.Obj.port,
            ftpProtocol: Number(event.Obj.ftpProtocol),
            remotePath: event.Obj.remotePath,
            isEnabled: event.Obj.isEnabled.value
        };
         this.business.updateSftpConfig(ftpConnection).then(result => {
            if(result){
                this.initialize();
            }
         });
        break;
    }
  }


  async editForm(rowData) {
    const editForm = {
      hostName: rowData.hostName,
      port: rowData.port,
      remotePath: rowData.remotePath,
      userName: rowData.userName,
      id: rowData.id,
      connectionName: rowData.connectionName,
      password:rowData.password,
      isEnabled :rowData.isEnabled,
      ftpProtocol : rowData.ftpProtocol

    }
    this.CreationToggler = true;
    this.crudActionInput = {
      mode: ActionMode.update,
      actionButton: this.captions.btn_update,
      form: editForm,
      listOrder: ''
    };
    this.createModalData = {
      title: this.captions.lbl_edit_sftp
    };
  }

  async createEvent(e) {
    this.CreationToggler = true;
    this.crudActionInput = {
      mode: ActionMode.create,
      actionButton: this.captions.btn_create,
      form: undefined,
      listOrder: ''
    };
    this.createModalData = {
      title: this.captions.create_btn_sftp
    };
  }



  handleClick(event) {
    switch (event.from) {
      case ActionMode.create:
        this.back(event);
        this.initialize();
        break;
      case ActionMode.update:
        this.back(event);
        this.initialize();
        break;
      case ActionMode.cancel:
        this.back(event);
        break;
    }
  }

  onBack() {
    this.onCancelAction(this.createform);
  }

  onCancelAction(form) {
    if (form.dirty) {
      this.utils.showAlert(this.captions.warn_datalost, AlertType.Warning, ButtonType.YesNo, (res => {
        if (res === AlertAction.YES) {
          this.backEmit();
        }
      }));
    } else {
      this.backEmit();
    }
  }



  async backEmit(e?) {
    if (this.createform) {
      this.createform.reset();
    }
    this.IsEditModeEnabledFlag = false;
    this.CreationToggler = false;
    await this.getTableData();
   }

   async getTableData()
   {
    this.originalData = await  this.business.getTableContent();
    this.tableContent = this.showInactiveTog ? this.originalData : this.originalData.filter(t =>  t.isEnabled.value == true);
   }


  back(e) {
    if ((this.createform && this.createform.dirty) ||
        (this.createform && this.createform.valid)) {

       if(!this.isViewOnly)
       {
        this.utils.showAlert(this.captions.warn_datalost, AlertType.Warning, ButtonType.YesNo, (res) => {
            if (res === AlertAction.YES) {
                this.backEmit(e);
            }
        });
       }
       else {
        this.backEmit(e);
    }
    } else {
        this.backEmit(e);
    }
}

async delete(e) {
  await this.business.deleteSetupConfig(e.id);
  this.initialize();
}

  showInactiveToggler(e) {
    if (e.checked) {
      this.showInactiveTog = true;
      this.tableContent = this.originalData;
    } else {
      this.showInactiveTog = false;
      this.tableContent = this.originalData.filter(t => t.isEnabled.value == true); //&& this.originalData.pipe(map(x => x.filter(t => t.isActive.value)));
    }
  }
}
