<section id="html-Popup-container">
    <h1 class='ag_p--4 ag_m--0 ag_display--flex text-capital' mat-dialog-title>{{data.headername}}
        <i class='icon-close ag_ml--auto ag_cursor--pointer' aria-hidden="true"
            (click)="dialogClose()">&nbsp;</i>
    </h1>
    <div class="content-container">    
        <div class="pt-3 pb-3" [innerHTML]="datRec | safeHtml"></div>
    </div>

    <div class="footer ag_footer--actions">
        <app-button [buttontype]="cancelButton" (valueChange)='dialogClose();'>
        </app-button>
    </div>
</section>