import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortQuantity'
})
export class SortQuantityPipe implements PipeTransform {
  orderBy;
  transform(value: any, key: string): any {
    this.orderBy = 'desc';
    if (!value) { return value; }
    value.sort((a, b) => {
      const data1 = Number(a[key]);
      const data2 = Number(b[key]);
      if (this.orderBy === 'desc') {
        if (data2 < data1) {
          return -1;
        }
        if (data2 > data1) {
          return 1;
        }
        return 0;
      } else {
        if (data1 < data2) {
          return -1;
        }
        if (data1 > data2) {
          return 1;
        }
        return 0;
      }
    });
    return value;
  }

}
