import { Injectable} from "@angular/core";
import { API } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { SettingModule, SettingScreen } from '../../global.constant';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';


@Injectable({
    providedIn: 'root'
})
export class SettingsDataService {

    constructor(private _golfScheduleCommunication: GolfScheduleCommunication) {

    }

    /**
 * Tee Time Settings of Module SYSTEMSETUP
 * @returns {Promise<API.TeeTimeConfig>}
 * @memberof SettingsDataService
 */
    public async getTeeTimeSetting(): Promise<API.TeeTimeConfig> {
        const result = this._golfScheduleCommunication.getPromise<API.Settings<API.TeeTimeConfig>>(
            { route: GolfApiRoute.GetTeeSettings });
        const settings: API.Settings<API.TeeTimeConfig> = await result;
        return settings.configValue;
    }

    /**
     * rguest Golf Defaults Settings of Module SYSTEMSETUP
     * @returns {Promise<API.DefaultsSettingConfig>}
     * @memberof SettingsDataService
     */
    public async getDefaultsSetting(): Promise<API.DefaultsSettingConfig> {
        const result = this._golfScheduleCommunication.getPromise<API.Settings<API.DefaultsSettingConfig>>(
            { route: GolfApiRoute.GetDefaultsSettings });
        const settings: API.Settings<API.DefaultsSettingConfig> = await result;
        return settings.configValue;

    }

    public getSettings<T>(_module: SettingModule, _screen: SettingScreen): Promise<API.Settings<T>> {
        return this._golfScheduleCommunication.getPromise<API.Settings<T>>(
            { route: GolfApiRoute.GetSettings, uriParams: { module: _module, screen: _screen } });      
    }
}

