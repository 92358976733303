import { Injectable } from '@angular/core';
import { ConvertWaitlistModalService } from './convert-waitlist-modal.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { TeeSheetsDataService } from 'src/app/shared/data-services/golfschedule/teesheets.data.service';
import { TeeTimeDetail, TeeSheet, TeeSheetSlotAPI } from 'src/app/shared/models/teesheet.api.models';
import { CreateWaitlistApiData } from 'src/app/tee-time/waitlist/waitlist.model';
import { AllocationBlockDataService } from 'src/app/shared/data-services/golfmanagement/allocationblock.data.service';
import { PlayerType } from 'src/app/settings/golf-setup/code-setup/player-type/player-type.modal';
import { PlayerTypeService } from 'src/app/shared/data-services/golfschedule/playertype.data.service';
import { SettingsDataService } from '../../shared/data-services/golfschedule/settings.data.service';
import { API } from '../../settings/system-setup/tee-times/tee-times.modal';
import { SettingModule, SettingScreen } from '../../shared/global.constant';
import { RateSetupDataService } from 'src/app/shared/data-services/golfschedule/rate-setup.data.service';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { allocationBlockPlayerTypePermission } from 'src/app/settings/user-setup/booking-permission/booking-permission.model';
import { API as allocation } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';
import { UserAccessModel } from 'src/app/shared/data-services/authentication/useraccess-model.model';
import { GolfScheduleCommunication } from 'src/app/shared/communication/services/golfschedule.service';
import * as RateSetupAPI from 'src/app/settings/rate-setup/rate-setup.model';
import { DynamicPricingPlayerFee, RateSetupData } from '../teetime/player-details/player-info.model';

@Injectable()
export class ConvertWaitlistModalBusiness {


  caps: { name: string; email: string; };
  DynamicPricingSetUp: DynamicPricingPlayerFee[];
  constructor(private _ConvertWaitlistModalService: ConvertWaitlistModalService
    , private localization: GolfLocalization
    , private teeSheetsDataService: TeeSheetsDataService
    , private _AllocationBlockDataService: AllocationBlockDataService
    , private playerTypeService: PlayerTypeService
    , private _userAccessBusiness: UserAccessBusiness
    , private _settingsDataService: SettingsDataService
    , private rateSetupDataService: RateSetupDataService
    , private _utilitites: GolfUtilities
    ,  private _http: GolfScheduleCommunication) { }
  private teeSlotsPromise: TeeSheet;
  GetPlayerList() {
    return this._ConvertWaitlistModalService.getPlayerList();
  }

  public async getSettings() {
    return await this._settingsDataService.getSettings<API.TeeTimeConfig>(SettingModule.SystemSetup, SettingScreen.TeeTime);
    
  }

  public getCourseTeeFee(courseId: string, date: Date) {
    return this.rateSetupDataService.getTeeFeesForCourseAndDate(parseInt(courseId), this.localization.getDate(date));
  }


  public async getTeeTimeSlot(data: CreateWaitlistApiData): Promise<TeeSheetSlotAPI[]> {   
    this._utilitites.ToggleLoader(true) 
    const requestdate = this.localization.getDate(data.requestedDate);
    this.teeSlotsPromise = await this.teeSheetsDataService.getTeeSlots(parseInt(data.course), requestdate)
    return this.teeSlotsPromise.slots;
  }

  public async getTeeTimeSlotByCourse(course: number, date: string): Promise<TeeSheetSlotAPI[]> {
    const requestdate = this.localization.getDate(date);
    this.teeSlotsPromise = await this.teeSheetsDataService.getTeeSlots(course, requestdate)
    return this.teeSlotsPromise.slots;
  }


  public async getTeeTimeSheet(data: CreateWaitlistApiData) {
    
    const requestdate = this.localization.getDate(data.requestedDate);
    return await this.teeSheetsDataService.getTeeSlots(parseInt(data.course), requestdate);
  }

  public async GetPlayerTypeByAllocationBlock() {
    return await this._AllocationBlockDataService.getAllocationsBlockWithPlayerTypes();
  }



  public async getAllPlayers(): Promise<PlayerType[]> {
    return await this.playerTypeService.getAllPlayerTypes();
  }

  public async getPlayerTypeId(id: number): Promise<PlayerType> {
    return await this.playerTypeService.getPlayerType(id);    
  }

  public async getPossibleHole(courseid: number, gdate: string, time: string, noOfPlayers: number): Promise<{ playercount: number, holeNumber: string, originalHoleNumber: string }> {

    if (!noOfPlayers || noOfPlayers.toString() == "") {
      return { playercount: 0, holeNumber: "", originalHoleNumber: "" };
    }
   
    let _noOfPlayers: number = parseInt(noOfPlayers.toString());
    let requestdate: Date = this.localization.ConvertStringDateTimeToDate(gdate, time);
    let stringdate = this.localization.ConvertDateToISODateTime(requestdate);
    let teeTimeDetails: TeeTimeDetail[] = await this.teeSheetsDataService.getTeeTimes(courseid, requestdate) || [];
    let filledSlots: TeeTimeDetail[] = teeTimeDetails.filter(x => x.scheduleDateTime == stringdate && x.scheduleStatus == 1)
    let sheet = await this.getTeeTimeSlotByCourse(courseid, gdate);
    let emptySlots = sheet.filter(x => x.scheduleDateTime == stringdate);
    let withAllocationBlockEmptySlots = emptySlots.filter(x => x.allocationBlockId!=0);
    if(withAllocationBlockEmptySlots!=null && withAllocationBlockEmptySlots.length>0)
    {
      for (let index = 0; index < withAllocationBlockEmptySlots.length; index++) {
        const slot = withAllocationBlockEmptySlots[index];
        let s = filledSlots?.find(x => x.scheduleDateTime == slot.scheduleDateTime && x.originalHoleNumber == slot.originalHoleNumber);
        // if matching already booked tee times found look up the players, else
        // the slot is empty and ok for book
        if (s!=null) {
          if (s.playerDetail.length + _noOfPlayers <= 4) {
            return { playercount: s.playerDetail.length, holeNumber: s.holeNumber, originalHoleNumber: s.originalHoleNumber };
          }
        }
        else{
          return { playercount: 0, holeNumber: slot.holeNumber, originalHoleNumber: slot.originalHoleNumber };
        }
        // no possible match found, notify ui as slots cannot accomodate
        if (index == emptySlots.length - 1) {
          return { playercount: s ? s.playerDetail.length : 5, holeNumber: s.holeNumber, originalHoleNumber: s.originalHoleNumber };
        }
      }
    }
    return { playercount: 4, holeNumber: "0", originalHoleNumber: "0" };
  }
  getAllocationBlockId(requestedDate: string, requestedTime: string, teeSlotDetails: TeeSheetSlotAPI[]): { allocationblockId: number, teeslotAvail: boolean, allocationName: string } {
    let allocationblockId = 0;
    let allocationName = '';
    let teeslot = false;
    if (teeSlotDetails) {
      let selectedTime: Date = this.localization.TimeToDate(this.localization.DeLocalizeTime(requestedTime));
      let requestedDateTime = this.localization.AddTimeToDate(this.localization.convertDateObjToAPIdate(this.localization.getDate(requestedDate)), selectedTime).getTime();
      let requestedDateTimeslot = this.localization.convertDateTimeToAPIDateTimeSec(this.localization.AddTimeToDate(this.localization.convertDateObjToAPIdate(this.localization.getDate(requestedDate)), selectedTime));
      if (this.isTimeInRange(requestedDateTime, teeSlotDetails[0].scheduleDateTime, teeSlotDetails[teeSlotDetails.length - 1].scheduleDateTime)) {
        if (teeSlotDetails.some(x => x.scheduleDateTime == requestedDateTimeslot)) {
          let teeSlot = teeSlotDetails.find(x => x.scheduleDateTime == requestedDateTimeslot);
          allocationblockId = teeSlot.allocationBlockId;
          allocationName = teeSlot.allocationBlockCode;
        }
        else {
          let tee = teeSlotDetails.filter(x => this._utilitites.getDate(x.scheduleDateTime).getTime() < requestedDateTime)
          allocationblockId = tee[tee.length - 1].allocationBlockId;
          allocationName = tee[tee.length - 1].allocationBlockCode;
        }
      }
      if (teeSlotDetails.find(x => this._utilitites.getDate(x.scheduleDateTime).getTime() == requestedDateTime)) {
        teeslot = true;
      }
    }
    return { allocationblockId: allocationblockId, teeslotAvail: teeslot, allocationName: allocationName };
  }
  checkIsSlotExist(requestedDate: string, requestedTime: string, teeSlotDetails: TeeSheetSlotAPI[]) {
    let teeslot = false;
    if (teeSlotDetails) {
      let selectedTime: Date = this.localization.TimeToDate(this.localization.DeLocalizeTime(requestedTime));     
      let requestedDateTimeslot = this.localization.convertDateTimeToAPIDateTimeSec(this.localization.AddTimeToDate(this.localization.convertDateObjToAPIdate(this.localization.getDate(requestedDate)), selectedTime));    
      if (teeSlotDetails.find(x => x.scheduleDateTime == requestedDateTimeslot)) {
        teeslot = true;
      }
    }
    return  teeslot;
  }
  public isDateTimeInRange(currentDate: string, currentTime: string, startTime: string, endTime: string): boolean {
    let selectedTime: Date = this.localization.TimeToDate(this.localization.DeLocalizeTime(currentTime));
    let currentTimeAsNumber = this.localization.AddTimeToDate(this.localization.convertDateObjToAPIdate(this.localization.getDate(currentDate)), selectedTime).getTime();
    return this.isTimeInRange(currentTimeAsNumber, startTime, endTime);
  }

  isTimeInRange(currentTime: number, startTime: string, endTime: string): boolean {
    let startTimeAsNumber = this.localization.getDate(startTime).getTime();
    let endTimeAsNumber = this.localization.getDate(endTime).getTime();

    return startTimeAsNumber <= currentTime && currentTime <= endTimeAsNumber;
  }


  public async GetAllocationBlockPermissionByRole(roleId?: string): Promise<allocationBlockPlayerTypePermission[]> {
    return await this._AllocationBlockDataService.getAllocationBlockPermissionByRole(roleId);
  }

  public async GetAllAllocationBlocks(): Promise<allocation.TeeTimeAllocationBlock[]> {
    return await this._AllocationBlockDataService.getAllocationBlocks(false);
  }

  public async ValidateUserBreakPoint(breakpointNumber : number, showPopup : boolean) : Promise<UserAccessModel.BreakPointResult>{
    return await this._userAccessBusiness.getUserAccess(breakpointNumber, showPopup);
   
  }


  GetFormData(data) {
    console.log(data);
    return {

      editToggle: false,
      firstName: data.playerName.split(' ')[0],
      lastName: data.playerName.split(' ')[0],
      phoneType: 'Work',
      phoneNumber: data.phoneNumber,
      emailType: 'Personal',
      emailId: data.emailAddress,
      playerType: 'playerType 2',
      rateType: 'rateType 2',
      course: data.course,
      requestedDate: '10/10/2019',
      requestedTime: '12:00 am',
      noOfPlayers: data.numberOfPlayers,
      comments: data.comments,
      staticDate: '10/10/2019',
      staticTime: '12:00 am',
      staticName: 'from api',
      staticCourse: 'from api',
      staticPlayers: 'from api',
      staticComments: 'from api from apifrom apifrom apifrom api from api',
      playerTypeRadio: ''
    }
  }

  public GetDynamicPricingForCourseAndScheduledDate(courseId: number, scheduleDate: string): Promise<DynamicPricingPlayerFee[]> {
    return this._http.getPromise<DynamicPricingPlayerFee[]>({
      route: GolfApiRoute.GetDynamicPricingForCourseAndScheduledDate 
      , uriParams: { courseId: courseId , scheduleDate: scheduleDate }
    });
  }

  public async getTeeFeesForCourseAndDate(course: number, date: Date): Promise<RateSetupData[]> {
    let rateSetupApiData: RateSetupAPI.RateSetupData[] = await this.rateSetupDataService.getTeeFeesForCourseAndDate(course, date);
    console.log(rateSetupApiData)
    return rateSetupApiData.map(x => this.mapRateSetupData(x));
  }

  public mapRateSetupData(rateSetupApiData: RateSetupAPI.RateSetupData): RateSetupData {
    return {
      id: rateSetupApiData.id,
      courseId: rateSetupApiData.courseId,
      rateTypeId: rateSetupApiData.rateTypeId,
      cartFee: rateSetupApiData.cartFee,
      greenFee: rateSetupApiData.greenFee,
      greenFeeRetailItemId: rateSetupApiData.greenFeeRetailItemId,
      cartFeeRetailItemId: rateSetupApiData.cartFeeRetailItemId
    } as RateSetupData;
  }
}
