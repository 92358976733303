import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { AgDropdownConfig, DropdownOptions } from 'src/app/common/Models/ag-models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { RetailBussinessService } from '../../retail.bussiness';
import { DialogCloseObj } from '../../shared-models';
import { StepperService } from '../stepper/stepper.service';
import { SelectOutletPopupBusiness } from './select-outlet-popup.business';

@Component({
  selector: 'app-select-outlet-popup',
  templateUrl: './select-outlet-popup.component.html',
  styleUrls: ['./select-outlet-popup.component.scss'],
  providers: [SelectOutletPopupBusiness],
  encapsulation: ViewEncapsulation.None
})
export class SelectOutletPopupComponent implements OnInit {
  captions;
  OutletConfig: AgDropdownConfig;
  outletForm: UntypedFormGroup;
  outlets: Promise<any[]>;
  isSaveDisabled = true;
  dialogObj;
  floatLabel: string;
  constructor(private localization: GolfLocalization, private fb: UntypedFormBuilder, private business: SelectOutletPopupBusiness,
              private stepperService: StepperService, public dialogRef: MatDialogRef<SelectOutletPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData) {
                this.floatLabel = this.localization.setFloatLabel;
               }

  ngOnInit(): void {
    this.captions = this.localization.captions;
    this.buildForm();
    this.initializeInputs();
  }

  buildForm() {
    this.outletForm = this.fb.group({
      defaultOutlet: ['', Validators.required]
    });
    this.outletForm.valueChanges.subscribe(x => {
      this.isSaveDisabled = !(this.outletForm.valid && this.outletForm.dirty);
    })
  }

  initializeInputs() {
    this.outlets = this.business.getOutlets();
    this.outlets.then(x => {
      console.log(x);
    })
    // this.OutletConfig.selectOptions = of(this.outlets);
    // this.OutletConfig = {...this.OutletConfig};
  }

  close() {
    if (this.dialogData.disableClose) {
      this.dialogObj = {
        type: 'close',
        dialogRef: this.dialogRef,
        selectedIndex: this.stepperService.selectedIndex
      };
      this.stepperService.valueChange.emit(this.dialogObj);
    } else {
      this.dialogRef.close('close');
      this.stepperService.selectedIndex = 0;
    }
   
  
  }

  outletChange(eve) {

  }

  onSave() {
    let obj = {
      from: 'YES',
      outlet: this.outletForm.controls.defaultOutlet.value,
      actionType:this.dialogData.teeTimeType,
      info: this.dialogData.info,
      isDeposit: this.dialogData.isDeposit
    }
    this.dialogRef.close(obj);
  }

  onCancel() {
    let obj = {
      from: 'NO'
    }
    this.dialogRef.close(obj);
  }

}
