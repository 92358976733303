import { InstructorSetupDataService } from '../../data-services/golfmanagement/instructor-setup.data.service';
import { Injectable } from '@angular/core';
import { from, Observable, forkJoin } from 'rxjs';
import * as Instructor from '../../../settings/instructor-setup/instructor-setup/instructor-setup.model';
import { searchtitleenum, GlobalSearchModel, GlobalSearchData, GlobalSearchAggregate } from './global-search.model';
import { RetailItemDataService } from '../../data-services/retailmanagement/retailitem.data.service';
import { RetailItemSearchModel } from '../../models/retail-item.models';
import { GolfUtilities } from '../../utilities/golf-utilities';
import { API } from 'src/app/tee-time/tournaments/tournaments-modal/contact/contact.model';
import { TeeTimeDataService } from '../../data-services/golfschedule/TeeTime.data.service';
import { TeeTimeSearchResult } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { LessonBookingDataService } from '../../data-services/golfschedule/lesson-booking.data.service';
import { ScheduledLessonSearchResult } from '../../models/lessons.modal';
import { PlayerProfileDataService } from '../../data-services/golfmanagement/PlayerProfile.data.services';
import { ContactService } from 'src/app/tee-time/tournaments/tournaments-modal/contact/contact.service';
import { GlobalSearchDataService } from './global-search.data.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfImageService } from '../../data-services/Image/golf.Image.service';

@Injectable()
export class GlobalSearchBusiness {
    imageList;
    constructor(private _instructorService: InstructorSetupDataService
        , private _retailItemDataService: RetailItemDataService
        , private _teeTimeDataService: TeeTimeDataService
        , private _lessonBookingService: LessonBookingDataService
        , private _playerProfileDataService: PlayerProfileDataService
        , private _contactService: ContactService
        , private _globalSearchService: GlobalSearchDataService
        , private _utils: GolfUtilities
        , private _localization: GolfLocalization
        , private _imageService: GolfImageService) {

    }


    public async globalSearch(pattern: string): Promise<{isSearched: boolean, data: GlobalSearchModel[]}> {
        var filterData = {
            isSearched: false,
            data: []
        };
        let searchables: Observable<any>[] = [];

        let data = from(this.searchPattern(pattern));
        searchables.push(data);
        // Items
        let items = from(this.searchRetailItem(pattern));
        searchables.push(items);
        
        forkJoin(searchables).subscribe(result => {
            filterData.isSearched = true;
            // instructor
            let globalSearchResult: GlobalSearchAggregate = result[0];
            if (globalSearchResult && globalSearchResult.instructor && globalSearchResult.instructor.length > 0)
                filterData.data.push(this.formInstructorData(globalSearchResult.instructor));
            // player
            if (globalSearchResult && globalSearchResult.players  && globalSearchResult.players.length > 0)
                filterData.data.push(this.formPlayerData(globalSearchResult.players));
            // tee times
            if (globalSearchResult && globalSearchResult.teeTimes  && globalSearchResult.teeTimes.length > 0)
                filterData.data.push(this.formTeeTimesData(globalSearchResult.teeTimes));
            // lessons
            if (globalSearchResult && globalSearchResult.scheduledLesson  && globalSearchResult.scheduledLesson.length > 0)
                filterData.data.push(this.formScheduledLessonData(globalSearchResult.scheduledLesson));
            // retail items
            let retailItemResult: RetailItemSearchModel[] = result[1];
            if (retailItemResult && retailItemResult.length > 0)
                filterData.data.push(this.formRetailItemData(retailItemResult));
        });

        return filterData;
    }

    private async searchPattern(pattern: string,isExternalGuestSearch : boolean = false): Promise<GlobalSearchAggregate> {
        pattern = encodeURIComponent(pattern);
        let data = await this._globalSearchService.globalSearch(pattern, isExternalGuestSearch);
        let ids = data.players.filter(x => x.id != null).map(x => x.id.toString());
        this.imageList = await this._imageService.getImagesForPlayers(ids, false);
        return data ? data : <any>{};
    }

    private async searchLessons(pattern: string) {
        pattern = encodeURIComponent(pattern);
        let lessons = await this._lessonBookingService.searchScheduledLesson(pattern);
        return lessons ? lessons : [];
    }

    private async searchInstructor(pattern: string) {
        pattern = encodeURIComponent(pattern);
        let instructors = await this._instructorService.searchInstructorConfiguration(pattern);
        return instructors ? instructors : [];
    }

    private async searchRetailItem(pattern: string) {
        let retailItems = await this._retailItemDataService.searchRetailItems(pattern);
        return retailItems && retailItems.itemDetails ? retailItems.itemDetails : [];
    }

    private async searchPlayer(pattern: string,isExternalGuestSearch : boolean = false) {
        pattern = encodeURIComponent(pattern);
        let players = await this._playerProfileDataService.searchPlayerProfileByName(pattern,isExternalGuestSearch);
        return players ? players : [];
    }

    private async searchTeeTimes(pattern: string) {
        pattern = encodeURIComponent(pattern);
        let teeTimes = await this._teeTimeDataService.searchScheduledTeeTime(pattern);
        return teeTimes ? teeTimes : [];
    }

    private formScheduledLessonData(lessonResult: ScheduledLessonSearchResult[]): GlobalSearchModel {
        return <GlobalSearchModel>{
            displayname:this._localization.captions.dashBoard.lessons,
            title: { name: searchtitleenum.Lessons },
            dataCollection: lessonResult.map(iterator => {
                var time = this._utils.getTime(this._utils.getDate(iterator.date), this._localization.getTimeFormat());
                return <GlobalSearchData>{
                    data: iterator,
                    id: iterator.id,
                    region: iterator.playerName,
                    course: iterator.locationName ? iterator.locationName : "NA",
                    timestamp: `${this._localization.LocalizeShortDate(this._utils.getDate(iterator.date))} - ${time}`,
                    item: iterator.instructorName,
                    vipType: iterator.vipType
                }
            })
        };
    }

    private formInstructorData(instructorResult: Instructor.API.InstructorSearchResult[]): GlobalSearchModel {
        return <GlobalSearchModel>{
            displayname: this._localization.captions.common.Instructors,
            title: { name: searchtitleenum.Instructors },
            dataCollection: instructorResult.map(iterator => {
                return <GlobalSearchData>{
                    id: iterator.instructorConfiguration.id,
                    timestamp: this._localization.LocalizeShortDate(this._utils.getDate(iterator.instructorBooking ? iterator.instructorBooking.date : this._localization.GetPropertyInfo("PropertyDate"))),
                    session: `${iterator.instructorBooking ? iterator.instructorBooking.noOfBooking : 0}`,
                    code: iterator.instructorConfiguration.instructorCode,
                    course: "",
                    region: this._utils.formatGuestName(iterator.instructorConfiguration.propertyContactPerson.firstName,iterator.instructorConfiguration.propertyContactPerson.lastName),
                    vipType: null
                }
            })
        };
    }

    private formRetailItemData(retailItemResult: RetailItemSearchModel[]): GlobalSearchModel {
        return <GlobalSearchModel>{
            displayname: this._localization.captions.common.RetailItems,
            title: { name: searchtitleenum.RetailItems },
            dataCollection: retailItemResult.map(iterator => {
                return <GlobalSearchData>{
                    id: iterator.itemId,
                    item: `${iterator.name}`
                }
            })
        };
    }

    private formPlayerData(playerResult: API.PlayerProfile[]): GlobalSearchModel {
        var phoneType = this._contactService.getPhoneType();
        return <GlobalSearchModel>{
            displayname: this._localization.captions.settings.players,
            title: { name: searchtitleenum.PlayerName },
            dataCollection: playerResult.map(iterator => {
                let phNo = iterator.playerProfileContactDetail.find(x => phoneType && phoneType.map(a => a.id).includes(x.type) && x.isPrimary);
                let loyalty = iterator.loyaltyDetail && iterator.loyaltyDetail.length > 0 ? iterator.loyaltyDetail[0] : null;
                return <GlobalSearchData>{
                    id: iterator.id,
                    region: this._utils.formatGuestName(iterator.firstName,iterator.lastName),
                    code: "NA",
                    phNo: phNo ? phNo.value : "",
                    patronId: loyalty ? loyalty.patronId : "",
                    image: this.imageList ? this.imageList.filter(c => c.imageReferenceId === iterator.id):'',
                    vipType: iterator.vipType
                }
            })
        };
    }

    private formTeeTimesData(teeTimeResult: TeeTimeSearchResult[]): GlobalSearchModel {
        return <GlobalSearchModel>{
            displayname: this._localization.captions.settings.TeeTimes,
            title: { name: searchtitleenum.TeeTimes },
            dataCollection: teeTimeResult.map(iterator => {
                return <GlobalSearchData>{
                    id: iterator.teeTimeScheduleId,
                    region: this._utils.formatGuestName(iterator.firstName,iterator.lastName),
                    timestamp: this._localization.LocalizeShortDate(this._utils.getDate(iterator.teeTimeDate)),
                    course: iterator.course,
                    code: iterator.confirmationNumber ? iterator.confirmationNumber : "NA",
                    data: iterator,
                    vipType: iterator.vipType
                }
            })
        };
    }

}