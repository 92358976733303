import { Component, OnInit, Input } from '@angular/core';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Component({
  selector: 'app-no-data-found',
  templateUrl: './no-data-found.component.html',
  styleUrls: ['./no-data-found.component.scss']
})
export class NoDataFoundComponent implements OnInit {
@Input() isSearch;
@Input() schedule;
@Input() title;
@Input() text;
@Input() dpNoDataFound;

captions: any;
  constructor(private _Localization: GolfLocalization) { }

  ngOnInit() {
    this.captions = this._Localization.captions.common;
  }

}
