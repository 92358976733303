import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  TeeSheetPlayerDetails } from 'src/app/shared/models/teesheet.form.models';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { EmptyValueValidator } from 'src/app/retail/shared/Validators/EmptyValueValidator';
import { TeeTimeService } from '../teetime/tee-time.service';
import { notifyBusinessService } from './notify.business';
import { StepperService } from 'src/app/shared/components/stepper/stepper.service';
import { takeUntil } from 'rxjs/operators';
import { DialogCloseObj, ContactDetails } from 'src/app/shared/shared-models';
import { ReplaySubject } from 'rxjs';
import { ButtonAction } from 'src/app/shared/global.constant';
import { notifyService } from './notify.service';
import { PlayerPaymentstatus } from 'src/app/tee-time/search/search-model';
import { Actions, EformLinkMapping } from './notify.model';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { DmEformsSpaComponent } from 'src/app/common/components/dm-eforms-spa/dm-eforms-spa.component';
import { DMConfigDataService } from 'src/app/common/dataservices/datamagine-config.data.service';
import { EformsBusiness } from 'src/app/common/data-magine/dm-eforms/dm-eforms.business';
import { DmConfig } from 'src/app/common/Models/common.models';

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss'],
  providers: [TeeTimeService, notifyBusinessService, notifyService, DMConfigDataService, EformsBusiness]
})
export class NotifyComponent implements OnInit {

  @Output() notifyParent = new EventEmitter();

  textMaskPhone: string;
  notifyplayers: UntypedFormGroup;
  playerdetail: TeeSheetPlayerDetails[];
  captions: any = this._Localization.captions.teetime;
  scheduledTeeTime: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  shownotifySection: any;
  countryCodeLength:number = 3;
  action: string;
  playerIds : number[] = [];
  contactInfo : ContactDetails[];
  isCancelPlayer : boolean;
  floatLabel: string;
  eformLinkList: EformLinkMapping[];
  dmConfig: DmConfig;
  isEformsEnabled = false;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: { info: any }, private _FormBuilder: UntypedFormBuilder, public _Localization: GolfLocalization, public _notifyBusinessService: notifyBusinessService
    , private _StepperService: StepperService, private _utilities:GolfUtilities, public dialog: MatDialog,
      private dmConfigDataService: DMConfigDataService) {
      this.floatLabel = this._Localization.setFloatLabel;
     }

  async ngOnInit() {
    
    this.textMaskPhone = this.maskPhoneNo();
    this.playerdetail = this.dialogData.info.playerDetail;
    this.notifyplayers = this._FormBuilder.group({
      enableSendEmail: false,
      enableSemdSMS: false,
      enableSaveEmail: false,
      playerEmailList: this._FormBuilder.array([]),
    });

    if(this.dialogData.info.playerDetail != null){
      this.playerIds = this.dialogData.info.playerDetail.filter(x=> !x.isBlocked).map(x=> x.playerId);
      this.isCancelPlayer = false;
    }
    else if(this.dialogData.info.Obj && this.dialogData.info.Obj.status == this.captions.Cancelled){
      this.playerIds.push(this.dialogData.info.Obj.schedulePlayerID); 
      this.isCancelPlayer = true;
    }
    let name : string;
    let status : PlayerPaymentstatus;
    if(this.playerIds.length > 0){
      this.contactInfo = await this.getContactInfo(this.playerIds);
        this.playerIds.forEach((x,i)=> {
          if(this.isCancelPlayer){
            name = this.dialogData.info.Obj.playerName;
            status = PlayerPaymentstatus.cancelled;
          }
          else{
            let playerDetail = this.dialogData.info.playerDetail.find(a=> a.playerId == x);
            if(playerDetail){
                name = this._utilities.formatGuestName(playerDetail.firstName, playerDetail.lastName);
              status = playerDetail.playerStatus; 
            }                      
          }
          let emailInfo =this.contactInfo &&this.contactInfo.length>0 && this.contactInfo.find(y => y.name == "Email" && y.playerId == x);
          let phonenoInfo =this.contactInfo &&this.contactInfo.length>0 && this.contactInfo.find(y => y.name == "Phone" && y.playerId == x);
          let phoneExtension:any[]=phonenoInfo &&(phonenoInfo != null) ? phonenoInfo.value.split(':') : [];
          let hasPhoneExtension = (phoneExtension!=null && phoneExtension.length==1)?phoneExtension[0]:'';
          let phoneInfomArray=phoneExtension &&(phoneExtension!=null&&phoneExtension.length==2) ?
          phoneExtension[1] : hasPhoneExtension;
          let phoneInfoArray : any[] = phoneInfomArray &&(phoneInfomArray != null) ? phoneInfomArray.split('|') : [];
          let hasPhoneInfoArray = ((phoneInfoArray !=null && phoneInfoArray.length == 1) ? phoneInfoArray[0] : '');
          let phone= phoneInfoArray &&(phoneInfoArray != null && phoneInfoArray.length == 2) ? phoneInfoArray[1] : hasPhoneInfoArray;
          let countrycode = phoneInfoArray &&(phoneInfoArray != null && phoneInfoArray.length == 2) ? phoneInfoArray[0] : ''; 
          let email = emailInfo &&(emailInfo != null) ? emailInfo.value : '';
          this.action = this.getAction(status);
          this.createMailingList(i, x, name, email, countrycode,phone, this.action);          
        });
    }
  
    this.pageSubscriptions();
    this.notifyplayers.statusChanges.subscribe(x => {
      console.log("this.notifyplayers", this.notifyplayers);
      this.emitnotify();
    });
    this.getDmconfig();
  }

  pageSubscriptions() {
    this._StepperService.valueChange.pipe(takeUntil(this.destroyed$)).subscribe((x: DialogCloseObj) => {
      if (x.type == ButtonAction.save) {       
        this.Sendnotify();
        x.dialogRef.close(x.type);
      }
      else {
        x.dialogRef.close(x.type);
      }
    });
  }

  async getDmconfig()
  {
    this.dmConfig = await this.dmConfigDataService.getDataMagineConfig();
    if (this.dmConfig && this.dmConfig?.enableDataMagine) {
      this.isEformsEnabled = this.dmConfig?.dmEformsConfig?.enableEforms ?? false;
    }
  }

  async getData(scheduledTeeTimeId): Promise<any> {
    return this._notifyBusinessService.GetTeeTimeByScheduleId(scheduledTeeTimeId);
  }

  async getContactInfo(playerIds : number[]) : Promise<ContactDetails[]>{
    return this._notifyBusinessService.GetContactInfo(playerIds);
  }

  async Sendnotify() {
    let checkedplayer = this.notifyplayers.value.playerEmailList.filter((pl, i) => {
      return pl.ischecked;
    });
   
    let enableSendEmail = this.notifyplayers.controls.enableSendEmail.value;
    let enableSemdSMS = this.notifyplayers.controls.enableSemdSMS.value;
    let enableSaveEmail = this.notifyplayers.controls.enableSaveEmail.value;
    await this._notifyBusinessService.sendNotification(checkedplayer, enableSendEmail,enableSemdSMS, enableSaveEmail, this.action, this.eformLinkList);
  }

  async emitnotify() {
    let checkedplayer = this.notifyplayers.value.playerEmailList.filter((pl, i) => {
      return pl.ischecked;
    });
    this.notifyParent.emit(this.notifyplayers.valid && (checkedplayer.length > 0) && ((this.notifyplayers.controls['enableSendEmail'].value) || (this.notifyplayers.controls['enableSemdSMS'].value)));
  }


  createMailingList(i: number, _obj?: any, _name?: string, _emailID?: string,_countryCode?:string |number, _phoneNo?: string, _action? :string) {
    const creds = this.notifyplayers.controls.playerEmailList as UntypedFormArray;
    creds.push(this._FormBuilder.group({
      name: _name,
      emailId: _emailID,
      countryCode:_countryCode,
      phoneNo: _phoneNo,
      obj: _obj,
      id: _obj,
      ischecked: (_emailID != '') || (_phoneNo != ''),
      action: _action
    })
    );
    let e = (_emailID != '') || (_phoneNo != '');
    this.checkclicked(e, i);
    this.emitnotify();
  }

  getAction(playerstatus: PlayerPaymentstatus) {
    if ((PlayerPaymentstatus.paid & playerstatus) != 0) {
      return Actions.TEETIMEBOOKING;
    } else if ((PlayerPaymentstatus.cancelled & playerstatus) != 0) {
      return Actions.TEETIMECANCELLATION;
    } else if ((PlayerPaymentstatus.noShow & playerstatus) != 0) {
      return  Actions.TEETIMENOSHOW;
    } else if (((PlayerPaymentstatus.booked & playerstatus) != 0) || ((PlayerPaymentstatus.refund & playerstatus) != 0)) {
      return Actions.TEETIMEBOOKING;
    }
  }

  checkclicked(e, i) {
    let currentControl = this.notifyplayers.controls['playerEmailList']['controls'][i];
    if (e) {
      if (this.notifyplayers.controls['enableSendEmail'].value) {
        currentControl.controls['emailId'].enable();
        currentControl.get("emailId").markAsTouched();
        currentControl.get("emailId").setValidators([Validators.required, EmptyValueValidator, Validators.email]);
        currentControl.get("emailId").updateValueAndValidity();
      } else {
        currentControl.controls['emailId'].disable();
      }
      if (this.notifyplayers.controls['enableSemdSMS'].value) {
        currentControl.controls['phoneNo'].enable();
        currentControl.get("phoneNo").markAsTouched();
        currentControl.get("phoneNo").setValidators([Validators.required]);
        currentControl.get("phoneNo").updateValueAndValidity();
        
        currentControl.controls['countryCode'].enable();
        currentControl.get("countryCode").markAsTouched();
        currentControl.get("countryCode").setValidators([Validators.required]);
        currentControl.get("countryCode").updateValueAndValidity();

        
      } else {
        currentControl.controls['countryCode'].disable();
        currentControl.controls['phoneNo'].disable();
      }
    } else {
      currentControl.controls['emailId'].disable();
      currentControl.controls['countryCode'].disable();
      currentControl.controls['phoneNo'].disable();
      currentControl.get("emailId").clearValidators();
      currentControl.get("emailId").updateValueAndValidity();
    }
  }

  private maskPhoneNo(): string {
    return this._Localization.captions.common.PhoneFormat ?
      this._Localization.captions.common.PhoneFormat : '999999999999999999';
  }
  emailsmstoggle(e, currcontrol,altcontrol) {
    let alttoggleflag = this.notifyplayers.controls[altcontrol].value;
    this.notifyplayers.controls[currcontrol].setValue(e);
    this.notifyplayers.value.playerEmailList.forEach((x, i) => {
      this.checkclicked(x.ischecked, i);
    })
    this.shownotifySection = (e ||alttoggleflag );
  }
  onEformToggleChange(e){
    if(e){
      this.eformLinkList  = this.dialogData.info?.eventNotifydata;
      this.openDMEforms();
    }
  }
  async openDMEforms() {
    let appointmentId : any =[];
    let guestId = 1;
    const tempApptList = [];
    appointmentId = [];
    this.eformLinkList = this.dialogData.info?.eventNotifydata;
    this.dialog.open(DmEformsSpaComponent, {
      width: '50%',
      height: '570px',
      hasBackdrop: true,
      data: {
        screenId: 'MANUAL_NOTIFY_SPA',
        appointmentId: appointmentId,
        //confNo: "",
        guestId: guestId,
        isFromNotiicationConfig: false,
        dataSource: this.eformLinkList,
        skipDMCall: true
      }
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(s => {
      if (s) {
        this.eformLinkList = s;
      }
    });
  }
  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }
}
