import { Injectable } from '@angular/core';
import { GuestPlayersService } from './players.service';
import { PlayerProfileDataService } from 'src/app/shared/data-services/golfmanagement/PlayerProfile.data.services';
import { PlayerProfileAPI, PlayerProfileUI, PlayerProfileGridData, PlayerProfileBasicInformation, contactType } from 'src/app/shared/create-player/create-player.model';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { ContactService } from 'src/app/tee-time/tournaments/tournaments-modal/contact/contact.service';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';
import { UserAccessBreakPoints } from 'src/app/shared/constants/useraccess.constants';
import { GolfImageService } from 'src/app/shared/data-services/Image/golf.Image.service';
import { ImageData } from 'src/app/shared/models/image.model';
import { DefaultGUID } from 'src/app/retail/shared/globalsContant';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { DefaultsSettingConfig } from 'src/app/tee-time-actions/teetime/player-details/player-info.model';
import { API as settingsAPI } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { UserAccessModel } from 'src/app/shared/data-services/authentication/useraccess-model.model';
import { AlertType, ButtonType } from 'src/app/shared/shared-models';
import { PlayerAdditionalDetails } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { DEFAULT_GUID, PlayerAdditionalDetailsEnum, PlayersSearchGuestTab, playerTypes } from 'src/app/shared/global.constant';
import { PlayerDataService } from 'src/app/shared/data-services/golfmanagement/player.data.service';
import { GolfSearchDetail, SearchFilter, GuestSearchData} from 'src/app/shared/components/ag-player-type-search/golf-search-model';
import { EXTERNALGUESTINTERFACENAME } from 'src/app/common/constants';

@Injectable()
export class PlayersBusiness {
  isViewOnly: boolean;
  imageList: ImageData[];
  phoneformat: any;
  captions: any;
  createPlayerPopupSubscription: Subscription;
  searchValue: string;
  playerList: PlayerProfileGridData[];
  golfSearchFilter : GolfSearchDetail[];
  allCaptions : any;
  isExistingGuestSelectedForLinking: boolean = false;
  constructor(private _PlayersService: GuestPlayersService
    , private _playerProfileDataService: PlayerProfileDataService
    , private _contactService: ContactService
    , public _utilities: GolfUtilities,
    private _localization: GolfLocalization,
    public _imageService: GolfImageService,
    public _userAccessBusiness: UserAccessBusiness,
    private _retailFeature: RetailFeatureFlagInformationService,
    private _playerDataService : PlayerDataService) {
    this.phoneformat = this._localization.captions.common.PhoneFormat;
    this.captions = this.GetCaptions();
    this.allCaptions = this._localization.captions;
  }

  GetCaptions() {
    return this._PlayersService.getCaptions();
  }

  async editPlayerDetails(id: string) {
    var playerProfile = await this._playerProfileDataService.GetPlayerProfileById(id);

    return this.mapPlayerProfileAPIToUI(playerProfile);
  }
  editPlayerDetailsDumy() {
    return this._PlayersService.editPlayerDetails();
  }
  async ValidateBreakPoint(breakPointNumber: number, showPopup: boolean): Promise<UserAccessModel.BreakPointResult> {
    return await this._userAccessBusiness.getUserAccess(breakPointNumber, showPopup);
  }
  public showBreakPointPopup(functionName?: string, callBack?: any) {
    let message: string;
    message = `${this._localization.captions.common.BreakPointAccessDeniedMsg}
         <br><br>${this._localization.captions.common.Breakpoint}: ${functionName}`;

    this._utilities.showAlert(message, AlertType.AccessDenied, ButtonType.Ok, callBack);
  }
  public async searchPlayerProfileByName(pattern: string, isExternalGuestSearch: boolean, isPlatformGuestSearch: boolean): Promise<PlayerProfileGridData[]> {
    let playerProfiles: PlayerProfileAPI.PlayerProfile[] = await this._playerProfileDataService.searchPlayerProfileByName(pattern,isExternalGuestSearch,isPlatformGuestSearch);
    let imageRefIds = playerProfiles.map(p => p.id).filter(x => x != null && x != DefaultGUID);
    if (imageRefIds.length > 0) {
     this.imageList = await this._imageService.getImagesForPlayers(imageRefIds, false);
    }
    return playerProfiles!.map(x => this.mapPlayerProfileToGridData(x));
  }
  public async SearchPlayerProfileByKeyAndValue(searchKey : number,pattern: string,IncludePurgedData:boolean,isExternalGuestSearch : boolean,isPlatformGuestSearch : boolean): Promise<PlayerProfileGridData[]> {
    let playerProfiles: PlayerProfileAPI.PlayerProfile[] = await this._playerProfileDataService.SearchPlayerProfileByKeyAndValue(searchKey,pattern,IncludePurgedData,isExternalGuestSearch,isPlatformGuestSearch);
    let imageRefIds = playerProfiles.map(p => p.id).filter(x => x != null && x != DefaultGUID);
    if (imageRefIds.length > 0) {
      this.imageList = await this._imageService.getImagesForPlayers(imageRefIds, false);
    }
    this._utilities.ToggleLoader(false)
    return playerProfiles!.map(x => this.mapPlayerProfileToGridData(x));
  }
  
  public async GetMatchingGuests(guestSearchData: GuestSearchData)
  {
   let playerProfiles: PlayerProfileAPI.PlayerProfile[] = await this._playerProfileDataService.GetMatchingGuests(guestSearchData);
   let imageRefIds = playerProfiles.map(p => p.id).filter(x => x != null && x != DefaultGUID);
    if (imageRefIds.length > 0) {
      this.imageList = await this._imageService.getImagesForPlayers(imageRefIds, false);
    }
    this._utilities.ToggleLoader(false);
    return playerProfiles?.map(x => this.mapPlayerProfileToGridData(x));
  }

  public async filterAlreadyLinkedGuests(playerProfiles: PlayerProfileGridData[]): Promise<PlayerProfileGridData[]> {
    return playerProfiles?.filter(p => !p.isLinkedWithExternalProfile);
  }

  private mapPlayerProfileToGridData(playerProfile: PlayerProfileAPI.PlayerProfile): PlayerProfileGridData {
    let address = '';
    const contacts = playerProfile.playerProfileContactDetail;
    address += playerProfile.playerProfileAddress && playerProfile.playerProfileAddress.addressLine1 ? `${playerProfile.playerProfileAddress.addressLine1}, ` : '';
    address += playerProfile.playerProfileAddress && playerProfile.playerProfileAddress.city ? `${playerProfile.playerProfileAddress.city}, ` : '';
    address += playerProfile.playerProfileAddress && playerProfile.playerProfileAddress.state ? `${playerProfile.playerProfileAddress.state}, ` : '';
    address += playerProfile.playerProfileAddress && playerProfile.playerProfileAddress.country ? `${playerProfile.playerProfileAddress.country}, ` : '';
    address += playerProfile.playerProfileAddress && playerProfile.playerProfileAddress.zipCode ? ` - ${playerProfile.playerProfileAddress.zipCode}` : '';
    let phoneTypes = this._contactService.getPhoneType().map(x => x.id);
    let mailTypes = this._contactService.getEmailType().map(x => x.id);
    let phone: any = contacts ? contacts.filter(x => (phoneTypes.includes(x.type))) : '';
    let email: any = contacts ? contacts.filter(x => mailTypes.includes(x.type)) : '';
    let img = this.imageList ? this.imageList.find((image) => playerProfile.id && (image.imageReferenceId.toLocaleLowerCase() === playerProfile.id.toLocaleLowerCase())) : '';
    return {
      basicInformation:
        {
          id: playerProfile.id != DEFAULT_GUID ? playerProfile.id : '',
          lastChangeId: playerProfile.lastChangeId,
          email: email.length > 0 ? this.getPrimaryDetail(email, contactType.email) : '',
          tooltipemail: email.length > 0 ? this.getTooltipContact(email, contactType.email) : '',
          firstName: playerProfile.firstName,
          lastName: playerProfile.lastName,
          image: img
        } as PlayerProfileBasicInformation,
      memberId: '',
      gender: playerProfile.gender,
      dateOfBirth: playerProfile.dateOfBirth ? this._localization.LocalizeShortDate(playerProfile.dateOfBirth) : '',
      orgDateOfBirth: playerProfile.dateOfBirth,
      age: this._utilities.calcAge(playerProfile.dateOfBirth),
      phoneNumber: phone.length > 0 ? this.getPrimaryDetail(phone, contactType.phone) : '',
      profileStatus: '',
      playerType: playerProfile.playerType,
      memberStatus: '',
      rateType: playerProfile.rateType,
      address,
      lastVisitedDate: playerProfile.lastVisitedDate ? this._localization.LocalizeShortDate(playerProfile.lastVisitedDate) : '',
      orgLastVisitedDate: playerProfile.lastVisitedDate,
      tooltipphone: phone.length > 0 ? this.getTooltipContact(phone, contactType.phone) : '',
      patronId: playerProfile.loyaltyDetail && playerProfile.loyaltyDetail.length > 0 ? playerProfile.loyaltyDetail[0].patronId : '',
      externalGuestId : playerProfile.interfaces?.find(i=>i.name === this._localization.GetPropertyConfig(EXTERNALGUESTINTERFACENAME))?.interfaceGuestId,
      isSubscribed : playerProfile.interfaces?.find(i=>i.name === this._localization.GetPropertyConfig(EXTERNALGUESTINTERFACENAME))?.isSubscribed,
      isExternalGuest: playerProfile.isExternalGuest,
      platformBussinessCardRevUuid: playerProfile.platformBussinessCardRevUuid,
      platformGuestUuid: playerProfile.platformGuestUuid,
      platformBussinessCardUuid: playerProfile.platformBussinessCardUuid,
      platformRevUuid:playerProfile.platformRevUuid,
      playerProfileContactDetail: playerProfile.playerProfileContactDetail,
      playerProfileAddress: playerProfile.playerProfileAddress,
      playerProfileAddressList: playerProfile.playerProfileAddressList,
      externalReferenceNumber:playerProfile.externalReferenceNumber,
      isLinkedWithExternalProfile: playerProfile.interfaces?.find(i=>i.name === this._localization.GetPropertyConfig(EXTERNALGUESTINTERFACENAME))?.isSubscribed,
      vipType: playerProfile.vipType
    } as PlayerProfileGridData;
  }
  private getTooltipContact(playerProfileContactDetails: PlayerProfileAPI.PlayerProfileContactDetails[], attributeType: number): string[] {
    let toolTipValues;
    const primaryIndex = playerProfileContactDetails.findIndex(x => x.isPrimary);
    toolTipValues = playerProfileContactDetails.map(r => {
      if (attributeType === contactType.phone) {
        return this._utilities.buildPhoneNumberToolTip(Number(r.type), r.value);
      }
      else if (attributeType === contactType.email) {
        return r.value;
      }
    });
    primaryIndex != -1 ? toolTipValues.splice(primaryIndex, 1) : toolTipValues.shift();
    return toolTipValues;
  }
  public getPrimaryDetail(playerProfileContactDetails: PlayerProfileAPI.PlayerProfileContactDetails[], attributeType: number): string {
    const Index = playerProfileContactDetails.findIndex(x => x.isPrimary);
    if (attributeType === contactType.phone) {
      if (Index != -1) {
        return this._utilities.phoneNumberFormatWithExtension(playerProfileContactDetails[Index].value);
      }
      else {
        return this._utilities.phoneNumberFormatWithExtension(playerProfileContactDetails[0].value);
      }
    }
    else if (attributeType === contactType.email) {
      if (Index != -1) {
        return playerProfileContactDetails[Index]?.value ? playerProfileContactDetails[Index].value.toString() : null;
      }
      else {
        return playerProfileContactDetails[0]?.value ? playerProfileContactDetails[0].value.toString() : null;
      }
    }

  }
  mapPlayerProfileAPIToUI(playerProfileAPI: PlayerProfileAPI.PlayerProfile): PlayerProfileUI.Player {
    let playerUI: PlayerProfileUI.Player;

    var address = playerProfileAPI.playerProfileAddress != null ? this.addressMapper(playerProfileAPI.playerProfileAddress) : null;
    var addressList = playerProfileAPI.playerProfileAddressList != null ? this.addressListMapper(playerProfileAPI.playerProfileAddressList) : [];
    var contactLogList = playerProfileAPI.playerProfileContactLogList !=null ? this.contactLogListMapper(playerProfileAPI.playerProfileContactLogList): [];
    var patronInfo = playerProfileAPI.loyaltyDetail && playerProfileAPI.loyaltyDetail.length > 0 ? playerProfileAPI.loyaltyDetail[0] : null;
    playerUI = {
      id: playerProfileAPI.id,
      lastChangeId: playerProfileAPI.lastChangeId,
      firstName: playerProfileAPI.firstName,
      lastName: playerProfileAPI.lastName,
      pronounced: playerProfileAPI.pronounce,
      title: playerProfileAPI.title,
      dob: playerProfileAPI.dateOfBirth,
      addressId :address!=null? address.id : 0,
      city: address != null ? address.city : '',
      state: address != null ? address.state : '',
      postal_code: address != null ? address.postalCode : '',
      country: address != null ? address.country : '',
      platformAddressUuid: address != null ? address.platformAddressUuid : '',
      address: address != null ? address.addressDetails : [],
      addressList: playerProfileAPI.playerProfileAddressList ? addressList : [],
      email: this.mapEmail(playerProfileAPI.playerProfileContactDetail),
      phone: this.mapPhone(playerProfileAPI.playerProfileContactDetail),
      playerCategoryId: 0,
      privateAddress: address != null ? address.isPrivate : false,
      gender: playerProfileAPI.gender,
      paymentReferenceId: playerProfileAPI.paymentReferenceId,
      updateExistingPaymentReferenceId: false,
      interfaceGuestId: playerProfileAPI.interfaceGuestId ? playerProfileAPI.interfaceGuestId : 0,
      url: '',
      imageId: 0,
      imageReferenceId: playerProfileAPI.imageReferenceId,
      interfaces: playerProfileAPI.interfaces,
      lastVisitedDate: playerProfileAPI.lastVisitedDate,
      patronid: patronInfo ? patronInfo.patronId : '',
      rank: patronInfo ? patronInfo.rank : '',
      playerType: playerProfileAPI.playerType,
      rateType: playerProfileAPI.rateType,
      vipType: playerProfileAPI.vipType,
      consentPolicyId:playerProfileAPI.consentPolicyId,
      consentExpiryDate : playerProfileAPI.consentExpiryDate,
      consent: playerProfileAPI.consent,
      isPurged:playerProfileAPI.isPurged,
      comments:playerProfileAPI.comments,
      isExternalGuest:playerProfileAPI.isExternalGuest,
      playerContactLogList: contactLogList,
      platformBussinessCardRevUuid: playerProfileAPI.platformBussinessCardRevUuid,
      platformBussinessCardUuid: playerProfileAPI.platformBussinessCardUuid,
      platformGuestUuid: playerProfileAPI.platformGuestUuid,
      platformRevUuid : playerProfileAPI.platformRevUuid,
      externalReferenceNumber: playerProfileAPI.externalReferenceNumber
    };

    return playerUI;

  }

  mapGridDataToPlayerProfile(data: PlayerProfileGridData)
  {
    let playerUI: PlayerProfileUI.Player;

    let address = data.playerProfileAddress != null ? this.addressMapper(data.playerProfileAddress, true) : null;
    let addressList = data.playerProfileAddressList != null ? this.addressListMapper(data.playerProfileAddressList, true) : [];
    playerUI = {
      id: "",
      lastChangeId: '',
      firstName: "",
      lastName: data.basicInformation.lastName,
      pronounced: '',
      title: '',
      dob: null,
      addressId : 0,
      city: address != null ? address.city : '',
      state: address != null ? address.state : '',
      postal_code: address != null ? address.postalCode : '',
      country: address != null ? address.country : '',
      platformAddressUuid: '',
      address: address != null ? address.addressDetails : [],
      addressList: data.playerProfileAddressList ? addressList : [],
      email: this.mapEmail(data.playerProfileContactDetail, true),
      phone: this.mapPhone(data.playerProfileContactDetail, true),
      playerCategoryId: 0,
      privateAddress: address != null ? address.isPrivate : false,
      gender: '',
      paymentReferenceId: 0,
      updateExistingPaymentReferenceId: false,
      interfaceGuestId: 0,
      url: '',
      imageId: 0,
      imageReferenceId: '',
      interfaces: [],
      lastVisitedDate: null,
      patronid: '',
      rank: '',
      playerType: data.playerType,
      rateType: data.rateType,
      vipType: '',
      consentPolicyId: 0,
      consentExpiryDate : null,
      consent: null,
      isPurged: false,
      comments: '',
      isExternalGuest: data.isExternalGuest,
      playerContactLogList : [],
      platformBussinessCardRevUuid: null,
      platformBussinessCardUuid: null,
      platformGuestUuid: null,
      platformRevUuid: null,
      externalReferenceNumber: ''
    };
    return playerUI;
  }

  mapPlayerProfileAPIToUIForCopy(playerProfileAPI: PlayerProfileAPI.PlayerProfile): PlayerProfileUI.Player {
    let playerUI: PlayerProfileUI.Player;

    let address = playerProfileAPI.playerProfileAddress != null ? this.addressMapper(playerProfileAPI.playerProfileAddress, true) : null;
    let addressList = playerProfileAPI.playerProfileAddressList != null ? this.addressListMapper(playerProfileAPI.playerProfileAddressList, true) : [];
    playerUI = {
      id: "",
      lastChangeId: '',
      firstName: "",
      lastName: playerProfileAPI.lastName,
      pronounced: '',
      title: '',
      dob: null,
      addressId : 0,
      city: address != null ? address.city : '',
      state: address != null ? address.state : '',
      postal_code: address != null ? address.postalCode : '',
      country: address != null ? address.country : '',
      platformAddressUuid: '',
      address: address != null ? address.addressDetails : [],
      addressList: playerProfileAPI.playerProfileAddressList ? addressList : [],
      email: this.mapEmail(playerProfileAPI.playerProfileContactDetail, true),
      phone: this.mapPhone(playerProfileAPI.playerProfileContactDetail, true),
      playerCategoryId: 0,
      privateAddress: address != null ? address.isPrivate : false,
      gender: '',
      paymentReferenceId: playerProfileAPI.paymentReferenceId,
      updateExistingPaymentReferenceId: false,
      interfaceGuestId: 0,
      url: '',
      imageId: 0,
      imageReferenceId: '',
      interfaces: [],
      lastVisitedDate: null,
      patronid: '',
      rank: '',
      playerType: playerProfileAPI.playerType,
      rateType: playerProfileAPI.rateType,
      vipType: playerProfileAPI.vipType,
      consentPolicyId: 0,
      consentExpiryDate : null,
      consent: null,
      isPurged: false,
      comments:playerProfileAPI.comments,
      isExternalGuest: false,
      playerContactLogList : [],
      platformBussinessCardRevUuid: playerProfileAPI?.platformBussinessCardRevUuid,
      platformBussinessCardUuid: playerProfileAPI?.platformBussinessCardUuid,
      platformGuestUuid: playerProfileAPI?.platformGuestUuid,
      platformRevUuid: playerProfileAPI?.platformRevUuid,
      externalReferenceNumber: ''
    };
    return playerUI;
  }

  maptitle(title: string) {
    switch (title) {
      case 'MR':
      case 'Mr.':
        return 'MR';
      case 'MRS':
      case 'Mrs.':
        return 'MRS';
      case 'MS':
      case 'Ms.':
        return 'MS';
      case 'REV':
      case 'Rev.':
        return 'REV';
      case 'MISS':
      case 'Miss':
        return 'MISS';
      case 'DR':
      case 'Dr.':
        return 'DR';
      case 'PROF':
      case 'Prof.':
        return 'PROF';
      case 'FR':
      case 'Fr.':
        return 'FR';
      default:
        return '';
    }
  }

  addressMapper(playerProfileAddress: PlayerProfileAPI.PlayerProfileAddress, isFromCopy : boolean = false): any {
    return {
      id: isFromCopy ? 0 : playerProfileAddress.id,
      addressDetails: [{ addressDetails: playerProfileAddress.addressLine1 },
      { addressDetails: playerProfileAddress.addressLine2 },
      { addressDetails: playerProfileAddress.addressLine3 }],
      city: playerProfileAddress.city,
      state: playerProfileAddress.state,
      postalCode: playerProfileAddress.zipCode,
      country: playerProfileAddress.country,
      isPrivate: playerProfileAddress.isPrivate,
      platformAddressUuid: isFromCopy ? '' : playerProfileAddress.platformAddressUuid
    }
  }

  addressListMapper(addressList: PlayerProfileAPI.PlayerProfileAddress[], isFromCopy : boolean = false): any {
    let mappedAddressLists: any = [];
    addressList?.forEach(x => {
      let address = {
        addressDetails: [{ addressDetails: x.addressLine1 },
        { addressDetails: x.addressLine2 },
        { addressDetails: x.addressLine3 }],
        city: x.city,
        state: x.state,
        postalCode: x.zipCode,
        country: x.country,
        isPrivate: x.isPrivate,
        platformAddressUuid: isFromCopy ? '' : x.platformAddressUuid
      }
      mappedAddressLists.push(address);
    });
    return mappedAddressLists;
  }

  contactLogListMapper(contactLogs: PlayerProfileAPI.PlayerProfileContactLog[], isFromCopy : boolean = false): PlayerProfileUI.PlayerContactLog[] {
    let contactLogList: PlayerProfileUI.PlayerContactLog[] = [];
    if(contactLogs!=null && contactLogs.length>0)
    {
        contactLogList = contactLogs.map(x=> {
          return {
            id : isFromCopy ? 0 : x.id, 
            productId : x.productId,
            logTypeId : x.logTypeId,
            logNotes : x.logNotes,
            logNotes_short : x.logNotes_short,
            logDate : x.logDate,
            dueDate : x.dueDate,
            expiryDate : x.expiryDate,
            isAlert : x.isAlert,
            isPrivate : x.isPrivate,
            isGroup : x.isGroup
          } as PlayerProfileUI.PlayerContactLog
        });
    }
    return contactLogList;
  }
  

  mapEmail(playerProfileContactDetail: PlayerProfileAPI.PlayerProfileContactDetails[], isFromCopy : boolean = false): any {
    let emailArray: PlayerProfileUI.Email[] = [];
    var mailTypes = this._contactService.getEmailType().map(x => x.id);
    if (playerProfileContactDetail && playerProfileContactDetail.length > 0) {
      playerProfileContactDetail.filter(c => mailTypes.indexOf(c.type) >= 0).forEach(e => emailArray.push({
        id : isFromCopy ? 0 : e.id,
        contactType: String(e.name),
        emailType: e.type,
        emailLabel: String(e.type),
        emailId: e.value,
        privateInformtionEmail: e.isPrivate,
        primaryEmail: e.isPrimary,
        platformContactUuid: isFromCopy ? '' : e.platformContactUuid
      }))
    }
    return emailArray;
  }

  mapPhone(playerProfileContactDetail: PlayerProfileAPI.PlayerProfileContactDetails[], isFromCopy : boolean = false): any {
    let phoneArray: PlayerProfileUI.Phone[] = [];

    var phoneTypes = this._contactService.getPhoneType().map(x => x.id);
    if (playerProfileContactDetail && playerProfileContactDetail.length > 0) {
      playerProfileContactDetail.filter(c => phoneTypes.indexOf(c.type) >= 0).forEach(e => phoneArray.push({
        id: isFromCopy ? 0 : e.id,
        contactType: String(e.name),
        phoneType: e.type,
        phoneLabel: String(e.type),
        countryCode: this._utilities.getCountryCodeFromValue(e.type, e.value).toString(),
        phoneNumber: this._utilities.getPhoneNumberFromValue(e.type, e.value).toString(),
        extension: this._utilities.getExtensionFromValue(e.type, e.value),
        privateInformtionPhone: e.isPrivate,
        primaryPhone: e.isPrimary,
        platformContactUuid: isFromCopy ? '' : e.platformContactUuid
      }))
    }
    return phoneArray;
  }



  mapContactTypetoUImodel(type: number): string {
    switch (type) {
      case 2:
        return 'Home';
      case 10:
        return 'Office';
      case 1:
        return 'Cell';
      case 9:
        return 'Personel';
    }

  }


  async GenerateFormData(guid: string) {
    var playerProfile = await this._playerProfileDataService.GetPlayerProfileById(guid);
    var mappedData = this.mapPlayerProfileAPIToUI(playerProfile);
    var imageData: ImageData;

    if (playerProfile.id && playerProfile.id != DefaultGUID) {
    imageData = await this._imageService.getImageForPlayer(playerProfile.id, true);
    }

    var url = `${imageData && imageData[0] ? imageData[0].contentType : ''},${imageData && imageData[0] ? imageData[0].thumbnailData : ''}`
    mappedData.url = url;
    mappedData.imageId = imageData && imageData[0] ? imageData[0].id : '';
    mappedData.imageReferenceId = playerProfile.id;
    return mappedData;

  }
    async GenerateFormDataForCopy(guid: string) {
        var playerProfile = await this._playerProfileDataService.GetPlayerProfileById(guid);
        var mappedData = this.mapPlayerProfileAPIToUIForCopy(playerProfile);
        return mappedData;
    }

    public async GetExternalGuestProfile(externalGuestId: string, createGuestProfile: boolean = false): Promise<PlayerProfileUI.Player>
  {
    var playerProfile = await this._playerProfileDataService.GetExternalGuestProfile(externalGuestId,createGuestProfile);
    var mappedData = this.mapPlayerProfileAPIToUI(playerProfile);
    return mappedData;
  }
  public async GetGuestProfileByPlatformGuid(PlatformGuid: string): Promise<PlayerProfileUI.Player> {
    var playerProfile = await this._playerProfileDataService.GetGuestProfileByPlatformGuid(PlatformGuid);
    var mappedData = this.mapPlayerProfileAPIToUI(playerProfile);
    return mappedData;
  }
  public async validateBreakPoints(): Promise<boolean> {
    let result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.PLAYERPROFILE, false);
    this.isViewOnly = result.isViewOnly;
    return result.isAllow;
  }

  UpdateCMSDetailOnExistingGuest(existingPlayer, cmsPlayer) {
    if (cmsPlayer && cmsPlayer.personalDetails) {
      if (this._retailFeature.UpdateGuestInfoAsPerCMS) {
        let playerDetail = cmsPlayer.personalDetails;
        existingPlayer.firstName = playerDetail.firstName;
        existingPlayer.lastName = playerDetail.lastName;
        existingPlayer.pronounced = playerDetail.pronounced;
        existingPlayer.dob = this._utilities.convertDateFormat(this._utilities.getDate(playerDetail.dateOfBirth));
        if (existingPlayer.loyaltyDetail && existingPlayer.loyaltyDetail[0]) {
          existingPlayer.loyaltyDetail[0].rank = playerDetail.playerRank;
        }

        if (playerDetail.gender && playerDetail.gender != 'U') {
          existingPlayer.gender = playerDetail.gender == 'M' ? 1 : 2;
        }

        if (playerDetail.address && existingPlayer.playerAddress) {
          existingPlayer.playerAddress.addressLine1 = playerDetail.address.addressLine1;
          existingPlayer.playerAddress.city = playerDetail.address.city;
          existingPlayer.playerAddress.country = playerDetail.address.countryName;
          existingPlayer.playerAddress.state = playerDetail.address.state;
          existingPlayer.playerAddress.zip = playerDetail.address.postalCode;
        }

        if ((playerDetail.phone && playerDetail.phone.length > 0) || (playerDetail.email && playerDetail.email.length > 0)) {
          existingPlayer.contactInformation = [];
          if (playerDetail.phone && playerDetail.phone.length) {
            var phoneTypes = this._contactService.getPhoneType();
            let defaultCountryCode: string = '';
            let defaultsApiData: settingsAPI.DefaultsSettingConfig = JSON.parse(sessionStorage.getItem('defaultSettings'));      
            defaultCountryCode = defaultsApiData.defaultCountryCode;
            playerDetail.phone.forEach(element => {
              let number = (element.countryCode ? element.countryCode : defaultCountryCode) + '|' + element.extension + element.phoneNumber;
              existingPlayer.contactInformation.push({
                description: null,
                id: 0,
                isPrimary: element.isPrimary,
                isPrivateInfo: false,
                name: phoneTypes.filter(x => x.id == element.phoneTypeId ? element.phoneTypeId : 1)[0].description,
                playerId: 0,
                type: element.phoneTypeId ? element.phoneTypeId : 1,
                value: number
              })
            });

            if (playerDetail.email && playerDetail.email.length > 0) {
              var mailTypes = this._contactService.getEmailType();
              playerDetail.email.forEach(element => {
                existingPlayer.contactInformation.push({
                  description: null,
                  id: 0,
                  isPrimary: false,
                  isPrivateInfo: false,
                  name: mailTypes.filter(x => x.id == element.emailTypeId ? element.emailTypeId : 9)[0].description,
                  playerId: 0,
                  type: element.emailTypeId ? element.emailTypeId : 9,
                  value: element.emailAddress
                })
              });
            }
          }
        }
      }
      else {
        if (existingPlayer.loyaltyDetail && existingPlayer.loyaltyDetail[0]) {
          existingPlayer.loyaltyDetail[0].rank = cmsPlayer.personalDetails.playerRank;
        }
      }
    }
    else {
      existingPlayer.loyaltyDetail = [];
      this._utilities.ShowError(this._localization.captions.common.Error, this.captions.PatronNotFound);
    }
  }

  isCMSDataChanged(existingData, cmsData): boolean {
    if (cmsData && cmsData.personalDetails) {
      let personalData = cmsData.personalDetails;
      if (this._retailFeature.UpdateGuestInfoAsPerCMS) {
        if (existingData.firstName !== personalData.firstName ||
          existingData.lastName !== personalData.lastName ||
          existingData.pronounced !== personalData.pronounced ||
          (existingData.loyaltyDetail && existingData.loyaltyDetail[0] &&
            existingData.loyaltyDetail[0].rank !== personalData.playerRank)) {
          return true;
        }

        if (personalData.gender !== '' && personalData.gender !== 'U' && personalData.gender !== (existingData.gender == 1 ? 'M' : 'F')) {
          return true;
        }

        if (personalData.address && (personalData.address.addressLine1 != existingData.playerAddress.addressLine1 ||
          personalData.address.city != existingData.playerAddress.city ||
          personalData.address.state != existingData.playerAddress.state ||
          personalData.address.postalCode != existingData.playerAddress.zip ||
          personalData.address.country != existingData.playerAddress.country)) {
          return true;
        }

        if ((personalData.phone && personalData.phone.length > 0) || (personalData.email && personalData.email.length > 0)) {
          let contactInformation = [];
          if (personalData.phone && personalData.phone.length) {
            var phoneTypes = this._contactService.getPhoneType();
            let defaultCountryCode: string = '';
            let defaultsApiData: settingsAPI.DefaultsSettingConfig = JSON.parse(sessionStorage.getItem('defaultSettings'));      
            defaultCountryCode = defaultsApiData.defaultCountryCode;
            personalData.phone.forEach(element => {
              let number = (element.countryCode ? element.countryCode : defaultCountryCode) + '|' + element.extension + element.phoneNumber;
              contactInformation.push({
                description: null,
                id: 0,
                isPrimary: element.isPrimary,
                isPrivateInfo: false,
                name: phoneTypes.filter(x => x.id == element.phoneTypeId ? element.phoneTypeId : 1)[0].description,
                playerId: 0,
                type: element.phoneTypeId ? element.phoneTypeId : 1,
                value: number
              })
            });

            if (personalData.email && personalData.email.length > 0) {
              var mailTypes = this._contactService.getEmailType();
              personalData.email.forEach(element => {
                contactInformation.push({
                  description: null,
                  id: 0,
                  isPrimary: false,
                  isPrivateInfo: false,
                  name: mailTypes.filter(x => x.id == element.emailTypeId ? element.emailTypeId : 9)[0].description,
                  playerId: 0,
                  type: element.emailTypeId ? element.emailTypeId : 9,
                  value: element.emailAddress
                })
              });
            }
          }
          let existingContact = _.orderBy(_.cloneDeep(existingData.contactInformation), 'value', 'asc');
          contactInformation = _.orderBy(contactInformation, 'value', 'asc');
          if (contactInformation && contactInformation.length > 0 && contactInformation.length == existingContact.length) {
            for (let index = 0; index < contactInformation.length; index++) {
              if (existingContact[index].value !== contactInformation[index].value ||
                existingContact[index].type !== contactInformation[index].type ||
                existingContact[index].isPrimary !== contactInformation[index].isPrimary) {
                return true;
              }
            }
          }
          else {
            return true;
          }
        }
      }
      else {
        return existingData.loyaltyDetail && existingData.loyaltyDetail[0] &&
          existingData.loyaltyDetail[0].rank == personalData.playerRank ? false : true;
      }
      return false;
    } else {
      return true;
    }
  }


  async updateGuestDetails(eve, suppressErrorMessage: boolean = true) {
    let data = await this._playerProfileDataService.GetPlayerProfileById(eve[0].playerLinkId);
    let result: PlayerProfileAPI.PlayerProfile = {
      firstName: eve[0].firstName,
      lastName: eve[0].lastName,
      pronounce: eve[0].pronounced.trim(),
      playerProfileAddress: {
        id: data.playerProfileAddress.id,
        addressLine1: eve[0].playerAddress.addressLine1,
        addressLine2: eve[0].playerAddress.addressLine2,
        addressLine3: eve[0].playerAddress.addressLine3,
        city: eve[0].playerAddress.city,
        state: eve[0].playerAddress.state,
        country: eve[0].playerAddress.country,
        zipCode: eve[0].playerAddress.zip,
        isPrivate: eve[0].privateAddress,
        platformAddressUuid: eve[0].platformAddressUuid,
      },
      playerProfileAddressList: data.playerProfileAddressList,
      playerProfileContactDetail: eve[0].contactInformation,
      title: "",
      dateOfBirth: data.dateOfBirth,
      gender: "",
      paymentReferenceId: eve[0].paymentReferenceId,
      updateExistingPaymentReferenceId: false,
      imageReferenceId: eve[0].imageReferenceId,
      interfaces: data.interfaces,
      lastVisitedDate: data.lastVisitedDate ? data.lastVisitedDate : null,
      loyaltyDetail: eve[0].loyaltyDetail,
      interfaceGuestId: eve[0].id,
      id: eve[0].playerLinkId,
      lastChangeId: data.lastChangeId,
      playerType: data.playerType,
      rateType: data.rateType,
      vipType: data.vipType,
      consent:data.consent,
      consentExpiryDate:data.consentExpiryDate,
      comments:data.comments,
      isPurged:data.isPurged,
      consentPolicyId:data.consentPolicyId,
      isExternalGuest: data.isExternalGuest,
      platformGuestUuid: data.platformGuestUuid,
      platformBussinessCardUuid: data.platformBussinessCardUuid,
      platformBussinessCardRevUuid: data.platformBussinessCardRevUuid,
      platformRevUuid : data.platformRevUuid,
      externalReferenceNumber: data.externalReferenceNumber
    };

    try {
      await this._playerProfileDataService.UpatePlayerProfile(result, !suppressErrorMessage);
    }
    catch (err) {
      if (suppressErrorMessage) {
        return;
      }
    }
  }

  public getFormattedFullName(searchValue : string): string
  {
    return this._utilities.getFormattedFullName(searchValue.replace(',',' '));
  }

  public getDefaultSettings(): DefaultsSettingConfig {
    let defaultsApiData: settingsAPI.DefaultsSettingConfig = JSON.parse(sessionStorage.getItem('defaultSettings'));
    return this.mapDefaultData(defaultsApiData);
  }
  private mapDefaultData(configValue: settingsAPI.DefaultsSettingConfig): DefaultsSettingConfig {
    return {
      blockingCode: configValue.blockingCode,
      memberStatus: configValue.memberStatus,
      memberPlayerType: configValue.memberPlayerType,
      memberRateType: configValue.memberRateType,
      nonMemberPlayerType: configValue.nonMemberPlayerType,
      nonMemberRateType: configValue.nonMemberRateType,
      resortPlayerType: configValue.resortPlayerType,
      resortRateType: configValue.resortRateType,
      memberGuestPlayerType: configValue.memberGuestPlayerType,
      memberGuestRateType: configValue.memberGuestRateType,
      memberGuestsToAdd: configValue.memberGuestsToAdd,
      memberGuestPlayerFirstName: configValue.memberGuestPlayerFirstName,
      memberGuestPlayerLastName: configValue.memberGuestPlayerLastName,
      defaultPlayerCategory: configValue.defaultPlayerCategory,
      defaultHotelReservationCategory: configValue.defaultHotelReservationCategory,
      defaultPhoneTypeOption: configValue.defaultPhoneTypeOption,
      defaultEmailTypeOption: configValue.defaultEmailTypeOption,
      defaultCountryCode: configValue.defaultCountryCode,
      defaultExistingPlayerCategory: configValue.defaultExistingPlayerCategory,
      enableExtentedSearchByDefault: configValue.enableExtentedSearchByDefault
    }
  }
  /**MapPlayerAdditionalDetails method to map selected player's additional info  */
  MapPlayerAdditionalDetails(playerInfo: any, existPlayerAdditionalDetails?: PlayerAdditionalDetails): PlayerAdditionalDetails {
    let playerAdditionalDetails: any = {};
    playerAdditionalDetails.playerId = playerInfo.id;
    playerAdditionalDetails.id = existPlayerAdditionalDetails && existPlayerAdditionalDetails?.id ? existPlayerAdditionalDetails.id : 0;
    playerAdditionalDetails.key = PlayerAdditionalDetailsEnum.MemberAccountNumber;
    playerAdditionalDetails.value = existPlayerAdditionalDetails && existPlayerAdditionalDetails?.id ? existPlayerAdditionalDetails.value : (playerInfo.arAccountNumber != undefined ? playerInfo.arAccountNumber : "");
    return playerAdditionalDetails;
  }

  MapCorpIdToPlayerAddtionalDetails(playerInfo: any) : PlayerAdditionalDetails{
    let playerAdditionalDetails :any = {};
    playerAdditionalDetails.playerId = playerInfo.id;
    playerAdditionalDetails.key = PlayerAdditionalDetailsEnum.MemberCorpId;
    playerAdditionalDetails.value = playerInfo.corpId;
    return playerAdditionalDetails;
  }

  MapPlayerAdditionalDetail(additionDetailsEnum: PlayerAdditionalDetailsEnum, playerInfo: any) : PlayerAdditionalDetails{
    let playerAdditionalDetails: any = {};

    switch (additionDetailsEnum) {
      case PlayerAdditionalDetailsEnum.MemberCorpId:
        playerAdditionalDetails.playerId = playerInfo.id;
        playerAdditionalDetails.key = PlayerAdditionalDetailsEnum.MemberCorpId;
        playerAdditionalDetails.value = playerInfo.corpId;
        return playerAdditionalDetails;
      case PlayerAdditionalDetailsEnum.DiscountType:
        playerAdditionalDetails.playerId = playerInfo.id;
        playerAdditionalDetails.key = PlayerAdditionalDetailsEnum.DiscountType;
        playerAdditionalDetails.value = playerInfo.discountType;
        return playerAdditionalDetails;
    }
  }

  GetSearchFilters(pmsSystem? : string)
  {
    this.golfSearchFilter = this._playerDataService.fetchGolfSearchJson();
    let playerSearchFilter =  this.golfSearchFilter?.find(p=>p.integratedPms == pmsSystem);
      if(!pmsSystem || !playerSearchFilter)
      {
        return this.GetDefaultSearchFilters()
      }
      else{
        let searchFilter = playerSearchFilter.searchComponent.find(s=>s.playerCategory == playerTypes.player)?.searchFilter
        return this.MapSearchFilters(searchFilter);
      }
  }
  GetDefaultSearchFilters()
  {
    return [
      { Id: PlayersSearchGuestTab.firstName, viewValue: this.allCaptions.teetime.firstName },
      { Id: PlayersSearchGuestTab.lastName, viewValue: this.allCaptions.teetime.lastName },
      { Id: PlayersSearchGuestTab.fullName, viewValue: this.allCaptions.teetime.fullName },
      { Id: PlayersSearchGuestTab.email, viewValue: this.allCaptions.common.EmailAddress },
      { Id: PlayersSearchGuestTab.phoneNumber, viewValue: this.allCaptions.common.phoneNumbertext },      
      { Id: PlayersSearchGuestTab.patronId, viewValue: this.allCaptions.lbl_patronId }
    ]
  }
  MapSearchFilters(searchComponent: SearchFilter[])
  {
      if(searchComponent)
      {
        return searchComponent.map((s : SearchFilter)=>{ 
            return {
              Id : s.id , viewValue : this.allCaptions.common[s.placeHolder]
          }
        });
      }
  }
}
