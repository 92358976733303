<div class="dialog-container">
  <div mat-dialog-title class="dialog-container__header">
    <span class="dialog-container__header--title">{{dialogData.title}}</span>
    <span attr.automationId='Btn_{{automationId}}_Close' class="dialog-container__header--close icon-Cancel" (click)="close()"></span>
  </div>
  <div class="dialog-container__content">
    <ng-container #vc></ng-container>
  </div>
  <mat-dialog-actions class="dialog-container__actions">
    <div *ngIf="!this.dialogData.componentDetails.popUpDetails.isStepper">
      <button attr.automationId='Btn_{{automationId}}_Action' mat-button  *ngIf="this.dialogData.componentDetails.popUpDetails.showActionButton"  mat-flat-button color="primary" class="actions__button" appDisableDoubleClick (throttleClick)="clickAction(stepperService.linkButtonText)"
      >{{stepperService.linkButtonText}}</button>
      <button attr.automationId='Btn_{{automationId}}_PaymentAction' mat-button *ngIf="stepperService.fromPaymentActions" [disabled]="!stepperService.enableSave" mat-flat-button color="primary" class="actions__save" [appDisableDoubleClick]="saveClickThrottleTime" (throttleClick)="clickAction(stepperService.paymentActionButton)"
      >{{stepperService.paymentActionButton}}</button>
      <button attr.automationId='Btn_{{automationId}}_Save' mat-button [disabled]="!stepperService.enableSave" mat-flat-button color="primary" class="actions__save" [appDisableDoubleClick]="saveClickThrottleTime" (throttleClick)="save(dialogData.actionType)"
      >{{stepperService.fromPaymentActions? stepperService.paymentActionShop :dialogData.update}}</button>
      <button attr.automationId='Btn_{{automationId}}_Cancel' mat-button [disabled]="!stepperService.enableCancel" class="actions__cancel" appDisableDoubleClick (throttleClick)="close()">{{dialogData.cancel}}</button>
      <a [ngClass]='backEnable ?  "back-btn": "back-btn back-disable"'  *ngIf="this.dialogData.teeTimeType==teeTimeAction.move || this.dialogData.teeTimeType==teeTimeAction.copy || this.dialogData.teeTimeType==teeTimeAction.copyToMany" (click)="back()"> {{captions.back}} </a>
    </div>
    <div class="w-100" *ngIf="this.dialogData.componentDetails.popUpDetails.isStepper">
      <button attr.automationId='Btn_{{automationId}}_Next' mat-button mat-flat-button color="primary" class="actions__save float-right" [appDisableDoubleClick]="nextClickThrottleTime" (throttleClick)="stepChange(stepperService.buttonText)"
      [disabled]="!stepperService.enableNext">{{stepperService.buttonText}}</button>
      <button attr.automationId='Btn_{{automationId}}_Previous' mat-button *ngIf="stepperService.enablePrevious" [appDisableDoubleClick]="prevClickThrottleTime" (throttleClick)="stepChange(stepperService.previousButton)" class="golfbutton golf--secondarygreen float-right" mat-stroked-button color="secondary" [disabled]="!stepperService.enablePrevious">{{stepperService.previousButton}}</button>
      <button attr.automationId='Btn_{{automationId}}_Close' mat-button [appDisableDoubleClick]=1 (throttleClick)="close()">{{dialogData.cancel}}</button>
      <a attr.automationId='Btn_{{automationId}}_Back' [ngClass]='backEnable ? "back-btn": "back-btn back-disable"'  *ngIf="this.dialogData.teeTimeType==teeTimeAction.move || this.dialogData.teeTimeType==teeTimeAction.copy || this.dialogData.teeTimeType==teeTimeAction.copyToMany" (click)="back()"> {{captions.back}} </a>
    </div>
  </mat-dialog-actions>
</div>
