<section class="pop-container">
  <div class="pop-head" [ngClass]="(data?.templatename != 'TS' && data?.templatename != 'SP' && data?.templatename != 'SS') ? 'border-top': '' ">
    <label class="inner-header pl-4 LW20">{{data.headername | uppercase}}</label>
    <i  aria-hidden="true" *ngIf="data.closebool" class="material-icons inner-close IC30 float-md-right cursPointer" (click)="onNoClick()">close</i>
  </div>
  <div class="pop-contents" [ngSwitch]="data?.templatename">
     <ng-template [ngSwitchCase]="'CO'">
      <app-retail-commission-values [dataInput]="datRec" [popupConfigs]="popupConfigs"></app-retail-commission-values>
    </ng-template>
    <ng-template [ngSwitchCase]="'NT'">
      <app-retail-create-new-tax [dataInput]="datRec" [existingTaxConfigs]="tableData" [popupConfigs]="popupConfigs"></app-retail-create-new-tax>
    </ng-template>
    <ng-template [ngSwitchCase]="'OT'">
      <app-create-outlet-terminal [dataInput]="datRec" [popupConfigs]="popupConfigs"></app-create-outlet-terminal>
    </ng-template>
    <ng-template [ngSwitchCase]="'VENDOR'">
      <app-create-retail-vendor-setup></app-create-retail-vendor-setup>
    </ng-template>
  </div>
</section>
