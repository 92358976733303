import { GolfGatewayCommunication } from 'src/app/shared//communication/services/golfGateway';
import { Injectable } from '@angular/core';
import { RecurringLessonResponse, RecurringScheduledLesson, ScheduledLesson, ScheduledLessonSearchResult } from '../../models/lessons.modal';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';
import { Schedule } from 'src/app/settings/settings-shared/models/schedule.model';
@Injectable({providedIn: 'root'})
export class LessonBookingDataService {
    
    constructor(private _gateway: GolfGatewayCommunication,private _schedule: GolfScheduleCommunication) {
    }

    public getLessonBookingById(id: number) {
        return this._gateway.getPromise({
            route: GolfApiRoute.GetLessonBooking,
            uriParams: { lessonScheduledId: id },
        })
    }

    public GetNewGroupId() {
        return this._gateway.getPromise<string>({
            route: GolfApiRoute.GenerateNewId,
        })
    }

    public updateLessonBooking(lessonBooking: ScheduledLesson) {
        return this._gateway.putPromise({
            route: GolfApiRoute.UpdateLesson,
            body: lessonBooking
        });
    }

    public searchScheduledLesson(pattern: string): Promise<ScheduledLessonSearchResult[]> {
        return this._gateway.getPromise({
            route: GolfApiRoute.SearchScheduledLesson,
            uriParams: { pattern }
        });
    }

    getInstructorSchedule(personId:number,noOfDays:number):Promise<Schedule[]>{
        return this._schedule.getPromise({
            route : GolfApiRoute.GetInstructorScheduleById,
            uriParams:  {'instructorId': personId,'noOfDays':noOfDays}
        })
    }

    public BookRecurringLesson(lessonBooking: RecurringScheduledLesson):Promise<RecurringLessonResponse[]> {
        return this._schedule.postPromise({
            route: GolfApiRoute.BookRecurringLesson,
            body: lessonBooking
        });
    }

    public UpdateRecurringLesson(lessonBooking: RecurringScheduledLesson):Promise<RecurringLessonResponse[]> {
        return this._schedule.putPromise({
            route: GolfApiRoute.UpdateRecurringLesson,
            body: lessonBooking
        });
    }

    public CancelRecurringLesson(lessonBooking: RecurringScheduledLesson) {
        return this._schedule.putPromise({
            route: GolfApiRoute.CancelRecurringBooking,
            body: lessonBooking
        });
    }

    public ValidateLessonOverlap(lessonBooking: ScheduledLesson) {
        return this._schedule.putPromise({
            route: GolfApiRoute.IsLessonOverlap,
            body: lessonBooking
        });
    }

    public BookBulkLesson(lessonBookings: ScheduledLesson[]) {
        return this._schedule.postPromise({
            route: GolfApiRoute.BookBulkLesson,
            body: lessonBookings
        });
    }

    public EditBulkLesson(lessonBookings: ScheduledLesson[]) {
        return this._schedule.postPromise({
            route: GolfApiRoute.EditBulkLesson,
            body: lessonBookings
        });
    }
}