import { Injectable } from "@angular/core";
import { FromAction, GolfSessionKey, RetailInterfaceSourceTypes } from "../shared/global.constant";
import {  CancellationNoShowChargeStatus, CancellationNoShowLedger, CancelPlayers, SelectedProducts } from "../retail/shared/shared.modal";
import { PlayerCancellationFees } from "./cancel-player-modal/cancel-player-model";
import { PaymentBusinessService } from "../shared/data-services/payment/payment-business.service";
import { RetailItemType } from "../retail/retail.modals";
import { SettingsDataService } from "../shared/data-services/golfschedule/settings.data.service";
import { ScheduledTeeTimeUnPaidPlayer } from "src/app/retail/shared/shared.modal";
import { CustomFeeSourceType } from "../retail/shared/globalsContant";
import { GolfLocalization } from "../core/localization/golf-localization";
import { OrderSummaryComponenet } from "../retail/shop/order-summary/order-summary.component";

@Injectable({ providedIn: 'root' })
export class TeeTimeCancellationNoShowFeeUtilities {
  isToContinueWithOldCartItems: boolean;
  constructor(private _paymentBusinessService: PaymentBusinessService,
    private _localization: GolfLocalization,
    private _settingSerivice: SettingsDataService) {
  }
  public async IsNoShowCancellationPolicyConfigured() : Promise<boolean> {
    let teeTimeSetting =  JSON.parse(sessionStorage.getItem(GolfSessionKey.TEETIMESETTING));
    let noShowCancellationPolicyConfigured = teeTimeSetting != null ? teeTimeSetting.isNoShowCancellationPolicyConfigured : false;
    if (teeTimeSetting == null) {
      let teeTimeConfig = await this._settingSerivice.getTeeTimeSetting();
      let teeTimeSettings = teeTimeConfig ?? null;
      if (teeTimeSettings != null) {
        noShowCancellationPolicyConfigured = teeTimeSettings.isNoShowCancellationPolicyConfigured?.toString() == "true";
        sessionStorage.setItem(GolfSessionKey.IsNoShowCancellationPolicyConfigured, noShowCancellationPolicyConfigured);
      }
    }
    return noShowCancellationPolicyConfigured;
  }
  
  public FormNoShowCancelPlayers(selectedPlayersList, formValue, action = "",outletId = 0, fromNoShow = false) : CancelPlayers
  {
    const body: CancelPlayers = 
    {
      playerIds: [selectedPlayersList],
      reason: formValue?.cancelComment ?? "",
      cancelReasonId: formValue?.cancelReasonId ?? 0,
      action: action,
      outletId: outletId,
      applyCancellationCharges: fromNoShow ? false : true,
      applyNoShowCharges : fromNoShow,
      playerWaivedOff: {}
    }
    return body;
  }

  getRetailItemDetail(retailItem)
  {
    if(retailItem && retailItem.length > 0)
      return retailItem[0].retailItemDetail;
  }

  FormRetailItem(retailItem, player, scheduledTeeTimeUnPaidPlayer, updatedLineNumber, 
    isDepositPresentForPlayer, ItemDescription, isModificationRestricted = false, retailItemType) 
  {
    let retailItemToBeProcessed = {
      ItemId: retailItem.linkedItemId,
      id: retailItem.linkedItemId,
      payeeId: scheduledTeeTimeUnPaidPlayer.playerId,
      ExternalPOSItemId: retailItem.externalPOSId,
      ItemDescription: ItemDescription,
      ItemType: retailItem.itemType,
      ServiceId: 0,
      SalesPrice: retailItem.salesPrice,
      ProductName: ItemDescription,
      ProductPrice: player.charges,
      Noofitems: 1,
      Discount: 0,
      DiscountTypeId: 0,
      DiscountPercentage: 0,
      category: retailItem.category,
      isGroupingKey: false,
      isPackagedItem: false,
      PackageItemId: 0,
      MultiPack: false,
      ClientMultiPackId: 0,
      PackageGroupId: 0,
      isOpenPricedItem: false,
      isReturn: false,
      isModificationRestricted: isModificationRestricted,
      costPrice: retailItem.costPrice,
      marginPercentage: retailItem.marginPercentage,
      allowEarn: retailItem.allowEarn,
      discountComments: '',
      discountReason: 0,
      clientName: scheduledTeeTimeUnPaidPlayer.playerName,
      clientId: player.playerId,
      LineNumber: updatedLineNumber,
      linkedItemLineNumber: updatedLineNumber,
      totalAmount: 0,
      retailItemType: retailItemType,
      customFee: null,
      ServiceCharge: [],
      sourceTypeId: player.playerId,
      sourceType: CustomFeeSourceType.TeeTime
    } as SelectedProducts;
    return retailItemToBeProcessed;
  }

  public async FormCancellationRetailItem(player: PlayerCancellationFees, lineNumber: number, isDepositPresentForPlayer: boolean, scheduledTeeTimeUnPaidPlayer) {
    let cancellationProduct: SelectedProducts;
    let updatedLineNumber = ++lineNumber;
    let cancellationRetailItems = await this._paymentBusinessService.GetRetailItemByItemType(RetailItemType.CancellationFee);
    let retailItem = this.getRetailItemDetail(cancellationRetailItems);

    if (retailItem != null) {
      cancellationProduct = this.FormRetailItem(retailItem, player, scheduledTeeTimeUnPaidPlayer,updatedLineNumber, isDepositPresentForPlayer, 
        retailItem.itemDescription,true, RetailItemType.CancellationFee);
    }
    cancellationProduct.ItemId = retailItem.id;
    cancellationProduct.policyId = player.policyId;
    return cancellationProduct;
  }

  public async CheckIfCancellationOrNoShowItemPresentOnProperty(itemType : RetailItemType) : Promise<boolean>
  {
    let cancellationNoShowItem = await this._paymentBusinessService.GetRetailItemByItemType(itemType);
    if(cancellationNoShowItem == null || cancellationNoShowItem?.length == 0)
    {
      return false;
    }
    return true;
  }

  public async FormNoShowRetailItem(player: PlayerCancellationFees, lineNumber: number, isDepositPresentForPlayer: boolean, scheduledTeeTimeUnPaidPlayer) {
    let noShowChargesItem: SelectedProducts;
    let updatedLineNumber = ++lineNumber;
    let cancellationRetailItems = await this._paymentBusinessService.GetRetailItemByItemType(RetailItemType.NoShowFee);
    let retailItem = this.getRetailItemDetail(cancellationRetailItems);

    if (retailItem != null) {
      noShowChargesItem = this.FormRetailItem(retailItem, player, scheduledTeeTimeUnPaidPlayer,updatedLineNumber, isDepositPresentForPlayer,
        retailItem.itemDescription, true, RetailItemType.NoShowFee);
    }
    noShowChargesItem.ItemId = retailItem.id;
    noShowChargesItem.policyId = player.policyId;
    return noShowChargesItem;
  }
  

  public async FormDepositRetailItem(scheduledTeeTimeUnPaidPlayer) 
  {
    let depositItem: SelectedProducts;
    let updatedLineNumber = 0;
    let depositRetailItems = await this._paymentBusinessService.GetRetailItemByItemType(RetailItemType.Deposit);
    let retailItem = this.getRetailItemDetail(depositRetailItems);
    if(retailItem != null)
    {
      depositItem = this.FormRetailItem(retailItem,0,scheduledTeeTimeUnPaidPlayer,updatedLineNumber,true,retailItem.itemDescription,false, RetailItemType.Deposit);
    }
    return depositItem;
  }

  public async FormDepositItems(scheduledTeeTimeUnPaidPlayer)
  {
    let depositRetailItems = [];
    let depositItem;
    let lineNumber = 1;
    for(let depoist of scheduledTeeTimeUnPaidPlayer?.deposits)
    {
      depositItem = await this.FormDepositRetailItem(scheduledTeeTimeUnPaidPlayer);
      depositItem.ProductPrice = depoist.amount;
      depositItem.lineNumber = lineNumber;
      depositItem.linkedItemLineNumber = lineNumber;
      depositRetailItems.push(await depositItem);
      lineNumber += 1;
    }
    return depositRetailItems;
  }

  FormCancellationNoShowLedger(playerCancellationFees: PlayerCancellationFees,isWaivedOff = false) : CancellationNoShowLedger
  {
    let apiDate = this._localization.convertDateTimeToAPIDateTime(new Date());
    let cancellationNoShowLedger = 
    {
      actualAmount: playerCancellationFees?.charges, 
      isWavedOff: isWaivedOff,
      isCancellation: true,
      chargedDate: new Date(apiDate),
      chargedAmount: isWaivedOff ? 0 : playerCancellationFees?.charges,
      playerId: playerCancellationFees.playerId,
      status: isWaivedOff ? CancellationNoShowChargeStatus.WavedOff : CancellationNoShowChargeStatus.Success_OrderSummary,
      ticketNumber:  isWaivedOff ? '' : '',
      policyId: playerCancellationFees?.policyId ?? 0
    } as CancellationNoShowLedger;
    return cancellationNoShowLedger;
  }

  FormCancelPlayers(playerIds: number[],cancellationNoShowLedger:CancellationNoShowLedger ) : CancelPlayers
  {
    let cancelPlayers = {
      action: "",
      cancelReasonId: 0,
      playerIds: playerIds,
      applyCancellationCharges: false,
      fromRetail: true,
      applyNoShowCharges: true,
      reason: "",
      cancellationNoShowLedger: cancellationNoShowLedger
  } as CancelPlayers;
  return cancelPlayers;
  }

  CancellationNoShowLedger(comScope: OrderSummaryComponenet,fromAction: FromAction) : CancellationNoShowLedger
  {
      if(comScope?.CreateRetailItemResponse?.transactionDetails?.length > 0)
      {
          let apiDate = this._localization.convertDateTimeToAPIDateTime(new Date());
          let transactionDetails = comScope?.CreateRetailItemResponse?.transactionDetails;
          let itemType = fromAction == FromAction.NoShow  ? RetailItemType.NoShowFee : RetailItemType.CancellationFee; 
          let retailItem = transactionDetails.find(o => o.itemType == itemType);
          let selectedProducts = comScope?._ss?.selectedProducts;
          if(retailItem)
              {
                  let chargesItem = selectedProducts.find(o => o.ItemId == retailItem.itemId);
                  let cancellationNoShowLedger = {
                  playerId: retailItem.sourceTypeId,
                  actualAmount: retailItem.netPrice,
                  chargedAmount: retailItem.netPrice,
                  chargedDate: new Date(apiDate),
                  status: CancellationNoShowChargeStatus.Success_OrderSummary,
                  ticketNumber:  comScope?.CreateRetailItemResponse.transactionData.ticketNumber,
                  policyId: chargesItem?.policyId ?? 0,
                  isCancellation: (fromAction == FromAction.Cancellation || fromAction == FromAction.BulkCancellation),
                  isWavedOff: false
                  } as CancellationNoShowLedger;
                      return cancellationNoShowLedger;
              }
          }
  }

  async RemoveGreenFeeItemCartFeeItem(player: ScheduledTeeTimeUnPaidPlayer) 
  {
    if (player.greenFeeRetailItem)
    {
      player.greenFeeRetailItem = null;
      player.greenFeeRetailItemId = 0;
    }
    if (player.cartFeeRetailItem)
    {
      player.cartFeeRetailItem = null;
      player.cartFeeRetailItemId = 0;
    }
    if (player.entryFeeRetailItem)
    {
      player.entryFeeRetailItem = null;
      player.entryFeeRetailItemId = 0;
    }
    return player;
  }
}