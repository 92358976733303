<section class="SVC">
    <form class='ag_w--200 ag_height--100' [formGroup]='SVCform' autocomplete="off">
        <div class="ag_display--flex ag_pt--2">
            <h4 class="ag_group--headers">{{captions.lbl_fieldvalues}}</h4>
            <mat-checkbox [attr.automationId]="'Chk_ValueToCombine_PrimarydefaultSet'" class="ag_ml--auto" disableRipple="true" (change)="setPrimaryguestvalue($event.checked)" [checked]="PrimarydefaultSet">
             
                {{captions.lbl_defaultPrimaryGuestValue}}</mat-checkbox>
        </div>
        <div class="srcharea  ag_p--5">
            <ng-container *ngFor="let OC of renderingScreenObj">
                <div [ngClass]="OC.columnSplitclass" class="d-inline-block"> 
                    <div [ngTemplateOutlet]="this[OC.objectTemplateType]"
                        [ngTemplateOutletContext]="{seedobj:OC.seedObj,headerdata:null,value:''}">
                    </div>
                </div>
            </ng-container>
        </div>
        
    </form>
</section>

<!-- //// Templates //// -->

<ng-template #VCT2 let-seed="seedobj" let-HdrData="headerdata">
    <app-ag-dropdown [inputs]="seed" (selectChange)="setPrimaryguestvalue(false)" ></app-ag-dropdown>    
</ng-template>

<!--textBox-->
<ng-template #VCT3 let-seed="seedobj" let-HdrData="headerdata">
    <ag-textbox [config]='seed'></ag-textbox>
</ng-template>