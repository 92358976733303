<form [formGroup]="multiSelectFrmGrp" class="multi-select">
  <label class="golf-form--label" *ngIf="title">{{title}}</label>
  <ng-container *ngIf="type === 'single'">
    <mat-form-field class="d-none">
      <mat-select attr.automationId='Dd_{{automationId}}_controlname' formControlName="controlname">
        <mat-option *ngFor="let button of buttonData" [value]="button">{{button.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button attr.automationId='Btn_{{automationId}}_{{button?.name}}Btn' mat-button *ngFor="let button of buttonData"
      [ngClass]="[multiSelectFrmGrp.controls['controlname'].value && multiSelectFrmGrp.controls['controlname'].value.id === button.id ? 'button--primary' : 'button--singleselect', button.disabled ? 'button--disabled' : '']"
      [disabled]="button.disabled" (click)="buttonSelectionChange(button)">{{button.name}}</button>
  </ng-container>
  <ng-container *ngIf="type === 'multiple'">
    <mat-form-field class="d-none">
      <mat-select attr.automationId='Dd_{{automationId}}_{{button?.name}}' formControlName="controlname" multiple>
        <mat-option *ngFor="let button of buttonData" [value]="button">{{button.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div id="viewmore-button--wrapper" class="view-more" [ngClass]="morepopover ? 'visibility-hide' : ''" [appViewmore]="buttonData && buttonData.length" (noOfChipsEmit)="noOfChipsEmit($event)">
      <button attr.automationId='Btn_{{automationId}}_{{button?.name}}Btn' mat-button [disabled]="isAllDisabled || disableButtonsIncludingAll"
        [ngClass]="[multiSelectFrmGrp.controls['controlname'].value && multiSelectFrmGrp.controls['controlname'].value.length === buttonData.length || multiSelectFrmGrp.controls['controlname'].value && multiSelectFrmGrp.controls['controlname'].value.length === actionBtn.length? 'button--primary' : 'button--secondary', isAllDisabled ? 'button--disabled' : '']"
        (click)="allButtonSelectionChange(allData)">{{allData.name}}</button>
      <button attr.automationId='Btn_{{automationId}}_{{button?.name}}Btn' mat-button *ngFor="let button of (buttonData | slice : 0 : (availableWidth? endNos: (noOfButtons > 2 ? noOfButtons - 2 : 0)))"
        [ngClass]="[ button['disabled'] ? 'button--disabled' : (changedData | objectindexof: multiSelectFrmGrp.controls['controlname'].value && multiSelectFrmGrp.controls['controlname'].value : button)  !== -1 ? (button['disabled'] ? '' : 'button--primary') : 'button--secondary']"
        (click)="buttonSelectionChange(button)" [disabled]="button['disabled'] || disableButtonsIncludingAll">{{button['name']}}</button>
      <a attr.automationId='Btn_{{automationId}}_{{captions.more}}Btn' [popover]="myPopover" [popoverOnHover]="false" *ngIf="(availableWidth? (buttonData && buttonData.length - endNos > 0) : (buttonData && buttonData.length + 2 > noOfButtons) )|| morepopover"
        class='moreelement golf__link more-link ml-2'>+{{availableWidth? (buttonData ? buttonData.length - endNos : 0): (noOfButtons > 2 ? (buttonData ? buttonData.length : 0) + 2 - noOfButtons : (buttonData ? buttonData.length : 0))}} {{captions.more}}</a>
    </div>
  </ng-container>
  <ng-container *ngIf="type === 'days'">
    <mat-form-field class="d-none">
      <mat-select attr.automationId='Db_{{automationId}}_controlname' formControlName="controlname" multiple>
        <mat-option *ngFor="let button of buttonData" [value]="button">{{button.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="view-more" [ngClass]="morepopover ? 'visibility-hide' : ''" [appViewmore]="buttonData && buttonData.length" (noOfChipsEmit)="noOfChipsEmit($event)">
      <button attr.automationId='Btn_{{automationId}}_{{allData?.name}}' mat-button [disabled]="isAllDisabled || disableButtonsIncludingAll"
        [ngClass]="[multiSelectFrmGrp.controls['controlname'].value && multiSelectFrmGrp.controls['controlname'].value.length === buttonData.length || multiSelectFrmGrp.controls['controlname'].value && multiSelectFrmGrp.controls['controlname'].value.length === actionBtn.length? 'button--primary' : 'button--secondary', isAllDisabled ? 'button--disabled' : '']"
        (click)="allButtonSelectionChange(allData)">{{allData.name}}</button>
      <button attr.automationId='Btn_{{automationId}}_{{button?.name}}' mat-button *ngFor="let button of buttonData"
        [ngClass]="[ button['disabled'] ? 'button--disabled' : (changedData | objectindexof: multiSelectFrmGrp.controls['controlname'].value && multiSelectFrmGrp.controls['controlname'].value : button)  !== -1 ? (button['disabled'] ? '' : 'button--primary') : 'button--secondary']"
        (click)="buttonSelectionChange(button)" [disabled]="button['disabled'] || disableButtonsIncludingAll">{{button['name']}}</button>
      <!-- <a [popover]="myPopover" [popoverOnHover]="false" *ngIf="buttonData && buttonData.length + 2 > noOfButtons || morepopover"
        class='moreelement golf__link more-link ml-2'>+{{noOfButtons > 2 ? (buttonData ? buttonData.length : 0) + 2 - noOfButtons : (buttonData ? buttonData.length : 0)}} {{captions.more}}</a> -->
    </div>
  </ng-container>
</form>

<popover-content popoverPlacement="right" #myPopover placement="bottom" [animation]="true" [closeOnClickOutside]="true"
  id="PopoverID" style="height:300px">
  <div class="popover-data">
    <button attr.automationId='Btn_{{automationId}}_{{button?.name}}Btn' mat-button
      [ngClass]="[(changedData | objectindexof: multiSelectFrmGrp.controls['controlname'].value && multiSelectFrmGrp.controls['controlname'].value : button)  !== -1 ? 'button--primary' : 'button--secondary', button['disabled'] ? 'button--disabled' : '']"
      (click)="buttonSelectionChange(button)" [disabled]="button['disabled']"
      *ngFor="let button of (buttonData | slice : (availableWidth? (endNos? endNos : 0):(noOfButtons > 2 ? noOfButtons - 2 : 0)) : (buttonData ? buttonData.length : 0) )">{{button['name']}}
    </button>
  </div>
</popover-content>
