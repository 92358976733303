import { Compiler, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginResponse, PMSAction, PMSRequestInfo, TokenLoginModel } from 'src/app/common/external-request/pms-request.model';
import { GolfRoutes } from 'src/app/core/extensions/golf-route';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { SystemdefaultsInformationService } from 'src/app/core/services/systemdefaults-information.service';
import { UserdefaultsInformationService } from 'src/app/core/services/userdefaults-information.service';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { GolfManagementCommunication } from 'src/app/shared/communication/services/golfmanagement.service';
import { PropertySettingDataService } from 'src/app/shared/data-services/authentication/propertysetting.data.service';
import { PropertySettingDataService as RetailPropertySettingDataService } from 'src/app/retail/sytem-config/property-setting.data.service';
import * as GlobalConst from 'src/app/shared/global.constant';
import * as CONSTANTS from 'src/app/common/constants';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { CryptoUtility } from '../../core/utilities/crypto.utility';
import { FormType } from '../../dynamicforms/form-type.entity';
import { ButtonValue, ReturnButtonValue } from '../../shared/models/button-type.model';
import { ComponentDetails } from '../../shared/shared-models';
import { ExpireSessionService } from '../expire-session.service';
import { LoginCommunicationService } from '../login-communication.service';
import { ManageSessionService } from '../manage-session.service';
import { SetPasswordComponent } from '../set-password/set-password.component';
import { SELECTION_ON_LOGIN, TRANSACTION_BY_MACHINENAME } from 'src/app/common/shared/shared/globalsContant';
import { UserMachineConfigurationService } from 'src/app/retail/common/services/user-machine-configuration.service';
import { UserMachineInfo } from 'src/app/common/shared/shared.modal';
import { SettingsDataService } from 'src/app/shared/data-services/golfmanagement/settings.data.service';
import { API as setting } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { CommonVariablesService } from 'src/app/retail/shared/service/common-variables.service';
import { ApmService } from '@elastic/apm-rum-angular';
import { ElasticApm } from 'src/app/shared/global.constant';
import { PayAgentService } from 'src/app/retail/shared/service/payagent.service';
import { ConfigKeys, RetailConstants } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { PropertyFeaturesConfigurationService } from 'src/app/retail/sytem-config/payment-features-config/property-feature-config.service';
import { FeatureName, RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { PropertyService } from 'src/app/common/services/property.service';
import * as FullStory from '@fullstory/browser';
import { JWT_TOKEN, FULL_STORY_ORG_ID , NO_OF_DECIMAL_DIGITS, SUPPORT_TENANT, GOLF_PRODUCT_ID, SUPPORT_USERNAME } from 'src/app/app-constants';
import { Product } from 'src/app/shared/enums/shared-enums';
import { ComponentAccessTypeLinkConfigProvider } from 'src/app/common/services/component-access-type-linking-config-provider.service';
import { DefaultBucketName } from 'src/app/retail/shared/globalsContant';
import { OAuthService, NullValidationHandler, OAuthEvent } from 'angular-oauth2-oidc';
import { AlertType, ButtonType } from 'src/app/shared/shared-models';
import { ADB2CAuthConfiguration } from 'src/app/common/shared/auth.config';
import { LoginRoutes } from '../login-routes';
import { GuestDataPolicyDataService } from '../../common/dataservices/guest-datapolicy.data.service';
import { CartUtilities } from 'src/app/common/components/menu/vcart/cart.utilities';
import { AuthorizeConfigurationDataService } from 'src/app/common/dataservices/authorizeIntegrationConfiguration.data.service';
import { Product as AllProducts } from 'src/app/common/Models/common.models';
import { cloneDeep } from 'lodash';
import { Localization } from 'src/app/common/localization/localization';
import { PropertySettingDataService as CommonPropertySettingDataService } from 'src/app/common/dataservices/authentication/propertysetting.data.service';
import { DMConfigDataService } from 'src/app/common/dataservices/datamagine-config.data.service';
import { SubPropertyDataService } from 'src/app/retail/retail-code-setup/retail-outlets/subproperty-data.service';
import { GolfNowSetupBusiness } from 'src/app/settings/interfaces/thirdparty-integration/golfnow-setup/golfnow-setup.business';
import { UTempDataUtilities } from 'src/app/common/shared/shared/utilities/utempdata-utilities';
import { TenantConfigurationDataService } from 'src/app/retail/shared/service/data- services/tenantConfiguration.data.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [CryptoUtility, UserMachineConfigurationService, UserMachineConfigurationService,GolfNowSetupBusiness]
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForms: UntypedFormGroup;
  errorMessage: { userId: string; password: string; customerId: string };
  captions: any;
  customSpinner: any;
  buttonValueprimary1: ButtonValue;
  loginForm: UntypedFormGroup;
  groupName: string = 'LoginForm';
  fields: FormType[];
  password: string;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  userId: string;
  maxLength: number = CONSTANTS.CUSTOMERID_LENGTH;
  customerId: number;
  rememberMe: boolean;
  componentDetails: ComponentDetails;
  setPassword: boolean = true;
  buttonValueprimary2: ButtonValue;
  loginSuccess: boolean = false;
  hideLoginForm = false;
  disableLoginBtn: any;
  errResponse: string = '';
  loginError: boolean = false;
  propertyValues: any[];
  userInfo: any;
  propertyKey: string = "propertyInfo";
  userKey: string = "_userInfo";
  propertyDateKey: string = "propertyDate";
  userName: string;
  hidePassword = false;
  userProperties: any = [];
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  multipleProperties: any = [{
    id: 1, name: "Agilysys"
  }];
  mySubscription: Subscription;
  private _autoLogOff: any = false;
  private _logOffAfter: number = 1;
  tenantId: number;
  cusId: any;
  showCustomerID: boolean = false;
  useridText: string;
  uname: string;
  muname: string;
  tenantCode: string;
  enablePropertySelection: boolean = false;
  useridSubscribe: any; passwordSubscribe: any;
  skipLoginFormLoad: boolean = false;
  currYear: number = 2024;
  preYear: number = 2019;
  apmElasticService: any;
  userDetail: any;
  passwordSetting: any;
  //Machine Name
  isMachineNameEnabled: boolean;
  isPromptOnLoginEnabled: boolean;
  defaultMachineId: number = 0;
  machineNames = [];
  userMachineInfo: UserMachineInfo;
  uTempDataPrimary: string;
  uTempDataSecondary: string;
  defaultcustomerId: string;
  ADB2CAuthenticationEnabled: boolean = false;
  @ViewChild('userid_one') userid_one: ElementRef;
  @ViewChild('userId_two') userId_two: ElementRef;
  @ViewChild('custid') custid: ElementRef;
  @ViewChild('fcs_pwd') fcs_pwd: ElementRef;
  errorvalue: string;
  errordescription: string;
  isSupportUser: boolean = false;
  allTenantDetails: any[] = [];
  tenantIdList: any[] = [];
  initialTenantIdList: any[] = [];
  propertyIdListForATenant: any[] = [];
  allPropertyDetails: any[] = [];
  @ViewChild('myInput') myInput: ElementRef;
  inputSearch;
  showLoginloader:boolean=false;
  private intervalId: any; // Type 'any' can be replaced with 'number'
  private elapsedTime: number = 0;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private crypto: CryptoUtility,
    private loginService: LoginCommunicationService,
    private localize: GolfLocalization,
    private utils: GolfUtilities,
    private sessionService: ExpireSessionService,
    private systemDefaultsService: SystemdefaultsInformationService,
    private userDefaultsService: UserdefaultsInformationService,
    private golfService: GolfManagementCommunication,
    private propertyInfo: GolfPropertyInformation,
    private PropertySettingService: PropertySettingDataService,
    private retailPropertySettingDataService: RetailPropertySettingDataService,
    private sessionManageService: ManageSessionService,
    private userMachineConfigurationService: UserMachineConfigurationService,
    private _compiler: Compiler,
    private cartUtils: CartUtilities,
    private route: ActivatedRoute,
    private retailLocalization: RetailLocalization,
    private settingsDataService: SettingsDataService,
    private userSessionConfig: UserMachineConfigurationService,
    private shopService: CommonVariablesService,
    private payAgentService: PayAgentService,
    private propertyFeatureService: PropertyFeaturesConfigurationService,
    private retailpropertyInfo: RetailPropertyInformation,
    private propertyService: PropertyService,
    @Inject(ApmService) apmService: ApmService,
    private componentAccessConfigService: ComponentAccessTypeLinkConfigProvider,
    private oauthService: OAuthService,
    private authIntegrationService: AuthorizeConfigurationDataService,
    private adb2cAuthConfiguration: ADB2CAuthConfiguration, private guestDataPolicyDataService: GuestDataPolicyDataService,
    private commonLocalize: Localization,
    private commonPropertySettingService: CommonPropertySettingDataService,
    private dmConfigDataService: DMConfigDataService,
    private _subPropertyDataService: SubPropertyDataService,
    private _golfNowSetupBusiness: GolfNowSetupBusiness,
    private utempdatautils: UTempDataUtilities ,
    private configuration: TenantConfigurationDataService
  ) {
    this.loginForm = this.formBuilder.group({});
    this.captions = this.localize.getCaptions().login;
    this.customSpinner = this.localize.captions
    this.apmElasticService = apmService;

    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      if (this.router.url.includes("custId")) {
        this.defaultcustomerId = this.router.url ? this.router.url.split("custId")[1].split("=")[1] : '';
      }

    });
  }
  title = 'golf-rewrite';

  /**
   * @function getbuttonEmitvalue;
   * @Param Input <object>
   * @param output <Nothing>
   * @description Get the return value of button emit
   */
  async getbuttonEmitvalue(e): Promise<void> {
    if(!this.showLoginloader){
    window.onbeforeunload = null;
    if (e) {
      this.enableLoginloader(true);
      e.preventDefault();
      this.loginForms.markAsUntouched();
    }
    this.localize.setLocalCookie('appGolfCustID', this.loginForms.get('customerId').value);

    if (this.showCustomerID) {
      localStorage.setItem('TenantId', this.loginForms.get('customerId').value);
      let tenantId = localStorage.getItem('TenantId');
      await this.configureAuth(tenantId);
      localStorage.setItem('ADB2CAuthenticationEnabled', this.ADB2CAuthenticationEnabled.toString());
      this.loginForms.get('customerId').markAsTouched();
      this.removeVal();
      if (this.ADB2CAuthenticationEnabled) {
        this.removeGeneralLoginVal();
        await this.adb2cAuthValidation();
      }
      else {
        this.enableLoginloader(false);
        this.showCustomerID = false;
        this.loginForms?.controls["customerId"].disable();
        setTimeout(() => {
          this.userid_one.nativeElement.focus();
        }, 0);

      }
    }
    else if(this.ADB2CAuthenticationEnabled && this.isSupportUser)
    {
        this.removeGeneralLoginVal();
        this.validateAdb2cCredentialsForSupportUser();
    }
    else if (this.ADB2CAuthenticationEnabled) {
      this.removeGeneralLoginVal();
      await this.adb2cAuthValidation();
    }
    else {
      this.loginForms.controls['userId'].markAsTouched();
      this.loginForms.controls['password'].markAsTouched();
      this.setGeneralLoginVal();
      this.generalAuthValidation();
    }
  }
  }

  setGeneralLoginVal() {
    const userIdCtrl = this.loginForms.get('userId');
    userIdCtrl.setValidators([Validators.required]);
    userIdCtrl.updateValueAndValidity();
    userIdCtrl.markAsDirty();

    const passwordCtrl = this.loginForms.get('password');
    passwordCtrl.setValidators([Validators.required]);
    passwordCtrl.updateValueAndValidity();
    passwordCtrl.markAsDirty();
  }

  removeGeneralLoginVal() {
    const userIdCtrl = this.loginForms.get('userId');
    userIdCtrl.clearValidators();
    userIdCtrl.updateValueAndValidity();
    userIdCtrl.markAsDirty();

    const passwordCtrl = this.loginForms.get('password');
    passwordCtrl.clearValidators();
    passwordCtrl.updateValueAndValidity();
    passwordCtrl.markAsDirty();
  }

  async adb2cAuthValidation() {
    if (this.loginForms.valid) {
      if (!this.loginSuccess) {//  && !this.enableLocation
        localStorage.setItem('TenantId', this.loginForms.get('customerId').value);
        this.adb2cLogin();
      }
      else {
        let claims = this.adb2cClaims;
        const credentials = {
          Property: this.loginForms.get('location').value,
          ProductId: GlobalConst.Product.GOLF
        };
        const result = this.propertyValues.find(item => item.propertyCode === credentials.Property.id);
        await this.CreateUserSession(result, sessionStorage.getItem("_jwt"));
        await this.setpropertyvalues(credentials.Property);
        this.systemDefaultsService.syncDefaultSettings();
        this.userDefaultsService.syncDefaultValues(this.userInfo.userId);
        this.setMachineDetails();
        this.router.navigate(['/home']);
      }
    }
  }

  generalAuthValidation() {
    this.tenantCode = "";
    if (this.loginForms.valid) {
      let id: any = this.loginForms.controls.customerId.value != "" ? this.loginForms.controls.customerId.value : this.cusId;
      this.uname = this.loginForms.controls["userId"].value;

      if (this.uname.includes('@')) {
        this.muname = this.uname.substring(0, this.uname.indexOf('@'));
        this.tenantCode = this.uname.substring(this.uname.indexOf('@') + 1);

      } else {
        this.muname = this.uname;
      }

      this.tenantId = parseInt(id);
      this.ValidateCredentials({ UserName: this.muname, Password: this.loginForms.controls["password"].value, TenantId: this.tenantId != null ? this.tenantId : 0, Property: this.loginForms.controls["location"].value, ProductId: GlobalConst.Product.GOLF, TenantCode: this.tenantCode });
      this.userName = this.loginForms.controls["userId"].value;
    }
    else {
      this.loginForms.controls['userId'].markAsTouched();
      this.loginForms.controls['password'].markAsTouched();
      this.loginForms.controls['machineName'].markAsTouched();
      if (this.showCustomerID) {
        this.loginForms.get('customerId').markAsTouched();
      }
    }
  }

  /**
   * @function ngOnInit;
   * @Param Input <Nothing>
   * @param output <Nothing>
   * @description Every component without any change dedection "ngOnInit" Injected first
   */
  async ngOnInit() {
    document.querySelectorAll('body')[0].setAttribute('id',"bodyId");
    this.enableLoginloader(false);
    this._compiler.clearCache();
    let resolvedRouteData: TokenLoginModel = this.route.snapshot.data.loginData;
    this.enablePropertySelection = false;
    if (resolvedRouteData) {
      this.resolvePMSRequest(resolvedRouteData)
    } else {
      this.initializeLoginComponent();
    }

    if (this.router.url && this.router.url === '/supportlogin') {
      this.isSupportUser = true;
      this.loginForms.controls['customerId'].setValue(SUPPORT_TENANT);
      this.loginForms?.controls["customerId"].disable();
      this.getbuttonEmitvalue('');
    }

    this.route.queryParams.subscribe(params => {
      this.errorvalue = params.error;
      this.errordescription = params.error_description;
      if (this.errorvalue != undefined && this.errordescription != undefined && this.errorvalue != '' && this.errordescription != '') {
        this.utils.showAlert(this.errorvalue + "<br>" + this.errordescription, AlertType.Info, ButtonType.Ok, (res => {
          window.location.href = window.location.origin + '/Golf/login';
        }));
        return false;
      }
    });
  }
  ngDestroy() {
    this.stopInterval();
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }
  private async initializeLoginComponent() {
    //this.hideLoginForm = true;
    let _token = sessionStorage.getItem("_jwt");
    if (this.localize.validateString(_token)) {
      this.router.navigate(["/home"]);
    }
    this.formGenerator()
    this.captionGenerator()
    this.errorGenerator()
    await this.getCustomerId();
    this.buttonValueprimary1 = {
      type: 'primary',
      label: this.captions.SetPassword,
      customclass: 'w-307px'
    };

    this.buttonValueprimary2 = {
      type: 'primary',
      label: 'login.ChangePassword',
      customclass: 'w-307px'
    };

    if (!this.ADB2CAuthenticationEnabled) {
      let getrememberresult = this.sessionManageService.GetRememberedUsers();
      let rememberedUser = (getrememberresult.length > 0) ? getrememberresult[0].name : "";

      this.loginForms.controls["userId"].setValue(rememberedUser ? rememberedUser : "");
      this.loginForms.controls["rememberme"].setValue(rememberedUser ? true : false);
      this.loginForms.controls['password'].setValue('');
    }
    let custId = this.localize.getLocalCookie('appGolfCustID');
    if (custId != '') {
      this.loginForms.controls['customerId'].setValue(custId);
      if (!this.ADB2CAuthenticationEnabled) {
        this.getbuttonEmitvalue('');
        this.loginForms?.controls["customerId"].disable();
      }
    }
  }

  async resolvePMSRequest(loginResult: TokenLoginModel) {
    if (!loginResult.loginStatus) {
      this.handleFailedLoginFromPMS(loginResult.errorCode);
      return;
    }

    this.skipLoginFormLoad = true;
    loginResult.userLoginInfo.isNewUser = false;
    const loginResponseModel: LoginResponse = { result: loginResult, successStatus: true }
    const request = this.utils.getPMSSessionInfo();
    sessionStorage.setItem('_jwt', loginResponseModel.result.token);
    this.userName = loginResponseModel.result.userLoginInfo.userName;
    this.userInfo = loginResponseModel.result.userLoginInfo;
    this.propertyValues = loginResponseModel.result.userProperties;
    this.setUserInfo(loginResponseModel);
    const result = this.propertyValues.find(item => item.propertyCode === request.propertyCode);
    await this.CreateUserSession(result, sessionStorage.getItem("_jwt"));
    let tokenDuration = sessionStorage.getItem('loginDuration') ? parseInt(sessionStorage.getItem('loginDuration')) : 0;
    if (tokenDuration <= 0) {
      tokenDuration = 36000;
    }
    this.setJwtTimer(tokenDuration);
    this.setpropertyvalues({
      id: request.propertyCode,
      name: 'IRC Golf'
    });
    this.systemDefaultsService.syncDefaultSettings();
    this.userDefaultsService.syncDefaultValues(this.userInfo.userId);
    const settingData = await this.settingsDataService.getSettings<setting.TeeTimeConfig>(GlobalConst.SettingModule.SystemSetup, GlobalConst.SettingScreen.TeeTime);
     
    this.redirectByPMSAction(request, settingData);
  }

  IsGolfNowEnabled(){
    try{
      this._golfNowSetupBusiness.fetchData(undefined).then(
        response => {
          if(response != null){
            let playerTypes = response.defaultPlayerType;
            if(playerTypes && playerTypes.length > 0){
              sessionStorage.setItem('golfNowEnabled','true');
            }
            else{
              sessionStorage.setItem('golfNowEnabled','false');
            }
          }
        }
      )
    }
    catch(ex){
      console.log(ex);
    }
  }
  async getCustomerId() {
    const serviceParams = {
      route: GolfRoutes.EnvironmentConfig,
      uriParams: '',
      header: '',
      body: '',
      showError: true,
      baseResponse: true
    };
    await this.loginService.makeGetCall(serviceParams).then((res: any) => {
      this.cusId = res ? res.result : 0;
      if (this.cusId == '0' || this.ADB2CAuthenticationEnabled) {
        //this.userIdDir = 'capitalise,notallowspace';
        this.showCustomerID = true;
        this.setVal();
        this.OnFormValueChanges();
      } else {
        this.enableLoginloader(false);
        //this.userIdDir = 'capitalise,notallowspace,nospecailchar';
        this.showCustomerID = false;
        this.loginForms?.controls["customerId"].disable();
        this.removeVal();
      }
      this.setValues();
    });
    let tenantId = localStorage.getItem('TenantId');
    let adb2cEnabled = localStorage.getItem('ADB2CAuthenticationEnabled');
    if (adb2cEnabled != null && adb2cEnabled.toLowerCase() == "true") {
      await this.configureAuth(tenantId);
    }
    //To load form for general authentication
    if (adb2cEnabled == null || (adb2cEnabled != null && adb2cEnabled.toLowerCase() == "false")) {
      this.hideLoginForm = false;
    }
  }

  private handleFailedLoginFromPMS(errorCode: number) {
    let error: string = (errorCode == 401) ? "You don’t have access to this application" : this.localize.getError(errorCode);
    this.utils.showError(error);
    sessionStorage.clear();

    // Return back to login component , in case of failure from PMS request.
    this.initializeLoginComponent();
  }

  private redirectByPMSAction(request: PMSRequestInfo, settingData: any): void {
    switch (request.action) {
      case PMSAction.Reservation:
        if (settingData.configValue.defaultToGraphicalTeeSheet) {
          this.router.navigateByUrl('tee-time/teesheet/graphicalView');
        }
        else {
          this.router.navigateByUrl('tee-time/teesheet/teeSheet');
        }
        break;
      case PMSAction.CancelReservation:
        this.router.navigateByUrl('tee-time/search');
        break;
      case PMSAction.ReInstateReservation:
        this.router.navigateByUrl('tee-time/search');
        break;
      case PMSAction.RescheduleReservation:
        this.router.navigateByUrl('tee-time/search');
        break;
      case PMSAction.MoveReservation:
        this.router.navigateByUrl('tee-time/search');
        break;
      case PMSAction.DashBoard:
        this.router.navigateByUrl('home');
        break;
      default:
        this.router.navigate(['login']);
        break;
    }
  }

  setVal() {
    this.loginForms.controls['customerId'].setValidators([Validators.required]);
    this.loginForms.controls['customerId'].updateValueAndValidity();
    this.loginForms.controls['customerId'].markAsDirty();
  }

  removeVal() {
    this.loginForms.controls['customerId'].clearValidators();
    this.loginForms.controls['customerId'].updateValueAndValidity();
    this.loginForms.controls['customerId'].markAsDirty();
  }
  OnFormValueChanges(): any {
    if (!this.ADB2CAuthenticationEnabled) {
      this.useridSubscribe = this.loginForms.get('userId').valueChanges.subscribe(r => {

        this.useridText = r;
        // if (this.useridText.includes("@")) {
        //   this.showCustomerID = false;
        //   this.removeVal();
        // }
        // else {
        //   this.showCustomerID = true;
        //   this.setVal();
        // }

        this.loginError = false;
        this.errResponse = '';
      });
      this.passwordSubscribe = this.loginForms.get('password').valueChanges.subscribe(r => {
        this.loginError = false;
        this.errResponse = '';
      });
    }
  }
  formGenerator() {

    this.loginForms = this.formBuilder.group({
      userId: ['', Validators.required],
      password: ['', Validators.required],
      customerId: ['', Validators.required],
      rememberme: false,
      location: ['Agilysys', Validators.required],
      machineName: ['0', Validators.required],
      tenantId:[''],
      propertyId:['']
    });

    if (this.defaultcustomerId) {
      this.loginForms.get('customerId').setValue(this.defaultcustomerId);
    }

  }

  errorGenerator() {
    this.errorMessage = {
      userId: "Please Enter the User ID",
      password: this.captions.enterPassword,
      customerId: "Please Enter the Customer ID"
    }
  }

  captionGenerator() {
    this.captions = this.localize.getCaptions().login;
  }

  loadProperties() {
    if (!this.ADB2CAuthenticationEnabled) {
      const customerId: any = (this.loginForms.controls.customerId.value !== '' && this.loginForms.controls.customerId.value !== undefined) ? this.loginForms.controls.customerId.value : this.cusId;
    }
  }

  onPropertyChange(eve) {
    console.log(eve);
    const propertyInfo = this.propertyValues.find(item => item.propertyCode === eve.value.id);
    this.setMachineInfo(propertyInfo.propertyId);
  }

  loadMachineNames() {
    //loadMachineNames
  }

  getReturnFormValue(event) {
    this.password = event.password[0].password;
    this.userId = event.userId[0].userId;
    this.rememberMe = event.RememberMe[0].remember;
  }

  setJwtTimer(tokenDuration: number) {
    this.sessionService.startTimer(0, tokenDuration);
    let currentDateTime = new Date();
    let jwtExpiryTime = this.cartUtils.isEmbed() ? '' :  new Date(currentDateTime.getTime() + tokenDuration * 1000);
    sessionStorage.setItem('jwtExpiryTime', jwtExpiryTime.toString());
  }

  async ValidateCredentials(credentials) {
    let serviceParams = {
      route: GolfRoutes.Login,
      uriParams: '',
      header: '',
      body: credentials,
      showError: true,
      baseResponse: true
    };
    if (!this.loginSuccess && !this.enablePropertySelection) {
      if (this.uTempDataPrimary && this.uTempDataSecondary) {
        serviceParams.body.Password = this.crypto.EncryptString(credentials.Password, this.uTempDataPrimary, this.uTempDataSecondary);
        serviceParams.route = GolfRoutes.LoginEncrypted;
      }
      let loginDetails = await this.loginService.makePostCall(serviceParams);
      if (loginDetails.successStatus) {
        this.userName = credentials.UserName;
        const loginResponse: any = loginDetails;
        if (loginResponse.result.loginDuration) {
          sessionStorage.setItem('loginDuration', loginResponse.result.loginDuration);
          const tokenDuration = parseInt(sessionStorage.getItem('loginDuration'));
          this.setJwtTimer(tokenDuration);
        }
        this.successCallBack(loginDetails);
        this.rememberUser();
      } else {
        if (loginDetails.errorCode == 5001) {
          this.loginError = true;
          this.enableLoginloader(false);
          this.errResponse = loginDetails.errorDescription;
        }
        else {
          this.utils.showError(loginDetails.errorDescription);
        }
      }
    }
    else {
      const result = this.propertyValues.find(item => item.propertyCode === credentials.Property.id);
      await this.CreateUserSession(result, sessionStorage.getItem("_jwt"));
      await this.setpropertyvalues(credentials.Property);
      await this.systemDefaultsService.syncDefaultSettings();
      this.userDefaultsService.syncDefaultValues(this.userInfo.userId);
      this.setMachineDetails();
      this.router.navigate(['/home']);
      this.propertyService.Checkfordeployment();
      let userDetails = await this.sessionManageService.GetUserSessionsInfo();
      const userProperty = userDetails.userProperties.find(item => item.propertyId === result.propertyId);
      await this.propertyService.setJasperAttributes(userProperty?.roleId);
    }

  }

  // async isFeatureFlagEnabled(featureName:string) : Promise<boolean>
  // {
  //   const isEnabled = await this.PropertySettingService.IsFeatureEnabled(featureName);
  //   return isEnabled;
  // }

  private async configureAuth(tenantId: string) {
    await this.GetADB2CAuthConfig(tenantId);
    this.ADB2CAuthenticationEnabled = this.adb2cAuthConfiguration.ADB2CAuthFeatureEnabled;
    if (this.ADB2CAuthenticationEnabled) {
      this.hideLoginForm = true;
      this.oauthService.configure(this.adb2cAuthConfiguration.authConfig);
      this.oauthService.customQueryParams = {
        'customerId': tenantId,
        'productId': GlobalConst.Product.GOLF
      };
      this.oauthService.tokenValidationHandler = new NullValidationHandler();
      this.oauthService.setStorage(localStorage);
      //Event Subscription
      this.oauthService.events.subscribe(({ type }: OAuthEvent) => {
        switch (type) {
          case 'token_received':
            console.log([this.oauthService.state]);
            break;
          default:
            console.log([this.oauthService.state]);
            break;
        }
      });

      this.oauthService.loadDiscoveryDocument(this.adb2cAuthConfiguration.DiscoveryDocumentConfigUrl)
        .then(async doc => {
          console.log(doc);
          this.oauthService.tryLogin().then(async res => {
            if (this.oauthService.hasValidIdToken()) {
              this.hideLoginForm = true;
              await this.adb2cAuthLogin();
              //this.setUserSessionInfo(this.claims,this.oauthService.getIdToken());
            }
            else {
              this.hideLoginForm = false;
            }
            let _token = this.oauthService.getIdToken()
            //let _token = sessionStorage.getItem(JWT_TOKEN);
            console.log(_token);
          });
        });
    }
    else {
      this.hideLoginForm = false;
    }
  }

  public adb2cLogin() {
    this.oauthService.initCodeFlow();
  }

  public adb2cLogout() {
    this.oauthService.logOut();
  }

  public get adb2cClaims() {
    let claims = this.oauthService.getIdentityClaims();
    return claims;
  }

  async validateAdb2cCredentials(credentials, claims: any, strTenantId: string) {
    const serviceParams = {
      route: LoginRoutes.ADB2CLogin,
      uriParams: '',
      header: '',
      body: credentials,
      showError: true,
      baseResponse: true
    };
    if (!this.loginSuccess) {//  && !this.enableLocation
      // Validate credentials
      let token = this.oauthService.getIdToken();
      const tenantId = Number(strTenantId);
      const loginDetails = await this.loginService.makePostCall(serviceParams);
      if (loginDetails.successStatus) {
        loginDetails.result.token = token;
        this.userName = credentials.UserName;
        const loginResponse: any = loginDetails;
        const jwtToken = JSON.parse(atob(token.split('.')[1]));
        const jwtExpiryTime = new Date(jwtToken.exp * 1000);
        const timeout = jwtExpiryTime.getTime() - Date.now();
        const loginDuration = Math.round(timeout/1000);
        if (loginResponse.result.loginDuration) {
          loginResponse.result.loginDuration = loginDuration;
          sessionStorage.setItem('loginDuration', loginResponse.result.loginDuration);
          localStorage.setItem('loginDuration', loginResponse.result.loginDuration);
          const tokenDuration = parseInt(sessionStorage.getItem('loginDuration'));
          this.sessionService.startTimer(0, tokenDuration);
          sessionStorage.setItem('jwtExpiryTime', jwtExpiryTime.toString());
          localStorage.setItem('jwtExpiryTime', jwtExpiryTime.toString());
        }
        await this.successCallBack(loginDetails);

      } else {
        if (loginDetails.errorCode == 5001) {
          this.enableLoginloader(false);
          this.loginError = true;
          //this.loginButton.disabledproperty = false;
          this.errResponse = loginDetails.errorDescription;
          this.hideLoginForm = false;
        }
        this.utils.showAlert(loginDetails.errorDescription, AlertType.Error, null, async (res) => {
          this.adb2cLogout();
        });
      }
    }
  }

  async getADB2CEmailClaim(claims,tenantId) {
    let email = "";
    let user;
    let userName = claims['name'];

    if(claims != null && claims != undefined)
    {
      if(claims['emails'] != null && claims['emails'].length > 0)
      {
        email = claims['emails'][0];
      }
      else if (claims['email'] != null)
      {
        email = claims['email'];
      }
      else if (userName != null) {
        const serviceParams = {
          route: LoginRoutes.GetUserByTenantId,
          uriParams: { "UserName": userName, "tenantId": tenantId },
          header: '',
          body: '',
          showError: true,
          baseResponse: true
        };
        let token = localStorage.getItem('id_token');
        sessionStorage.setItem('_jwt', token);
        user = await this.loginService.makeGetCall(serviceParams);
        email = user.result.email;
      }
      else{
        this.utils.showAlert(this.captions.lbl_UserTokenErrorMessage, AlertType.Error, ButtonType.Ok, (res => {
          this.adb2cLogout();
        }));
      }
    }   

    return email;
  }

  async adb2cAuthLogin() {
    let tenantId = localStorage.getItem('TenantId');
    this.loginForms.controls.customerId.setValue(tenantId);
    let claims = this.adb2cClaims;
    const credentials = {
      email: await this.getADB2CEmailClaim(claims,tenantId),
      tenantId: tenantId,
      ProductId: GlobalConst.Product.GOLF
    };
    if(!credentials.email)
    {
      this.utils.showAlert(this.captions.lbl_AzureTokenErrorMessage, AlertType.Error, ButtonType.Ok,(res=>{     
        this.adb2cLogout();
      }));  
      return false;
    }
    if(Number(tenantId) == SUPPORT_TENANT)
    {
      await this.ProcessSupportUserLogin(credentials.email);
    }
    else{
      await this.validateAdb2cCredentials(credentials, claims, tenantId);
    }
  }

  async GetADB2CAuthConfig(tenantId: string) {
    const iTenantId: number = Number(tenantId);
    const serviceParams = {
      route: LoginRoutes.GetADB2CAuthConfig,
      uriParams: { "tenantId": iTenantId, "productId": GlobalConst.Product.GOLF },
      header: '',
      body: '',
      showError: true,
      baseResponse: true
    };
    let adb2cConfig: any;
    adb2cConfig = await this.loginService.makeGetCall(serviceParams);
    this.adb2cAuthConfiguration.ADB2CAuthFeatureEnabled = adb2cConfig.result.adB2CAuthenticationEnabled;
    this.adb2cAuthConfiguration.DiscoveryDocumentConfigUrl = adb2cConfig.result.discoveryDocumentUrl;

    let adb2cUrl = this.commonLocalize.getLocalCookie('supportUserMailId') || sessionStorage.getItem('supportUserMailId') || this.isSupportUser ? '/Golf/supportlogin' : '/Golf/login';

    this.adb2cAuthConfiguration.authConfig = {
      redirectUri: window.location.origin + adb2cUrl,
      postLogoutRedirectUri: window.location.origin + adb2cUrl,
      responseType: 'code',
      issuer: adb2cConfig.result.issuer,
      strictDiscoveryDocumentValidation: false,
      tokenEndpoint: adb2cConfig.result.tokenEndPoint,
      loginUrl: adb2cConfig.result.loginUrl,
      clientId: adb2cConfig.result.clientId,
      scope: 'openid',
      skipIssuerCheck: true,
      clearHashAfterLogin: true,
      oidc: true
    };
  }

  async SetUserSessionConfiguration(userId: number) {
    let userSessionConfig = await this.userSessionConfig.getUserSessionConfiguration(userId);

    if (userSessionConfig) {
      let userSessionConfigValues =
        `Id=${userSessionConfig.id};
        UserId=${userSessionConfig.userId};
        DefaultOutletId=${userSessionConfig.defaultOutletId};
        DefaultPaymentId=${userSessionConfig.defaultPaymentId};
        DefaultCourseId=${userSessionConfig.defaultCourseId};
        DefaultPaymentDevice=${userSessionConfig.defaultPaymentDevice};
        DefaultDeviceName=${userSessionConfig.defaultDeviceName};
        IsIdtechSred=${userSessionConfig.isIdtechSred};
        HangingTicketsPrinter=${userSessionConfig.hangingTicketsPrinter};
        SmallStickersPrinter=${userSessionConfig.smallStickersPrinter};
    `;

      sessionStorage.setItem(this.userSessionConfig.userSessionConfigKey, userSessionConfigValues);

      // Set Retail Shop service - outlet dropdown value
      this.shopService.SelectedOutletId = userSessionConfig.defaultOutletId;
    }
  }

  async successCallBack(loginDetails) {
    sessionStorage.setItem('_jwt', loginDetails.result.token);

    if(this.propertyInfo.isResortFinanceEnabled){
      sessionStorage.setItem('_accJwt', loginDetails.result.token);
      sessionStorage.setItem('_folioJwt', loginDetails.result.token);
    }
    
    let data = loginDetails.result;
    this.userDetail = data;
    this.sessionManageService.UpdateUserSessionsInfo(this.userDetail);
    this.userName = loginDetails.result.userLoginInfo.userName;
    this.userInfo = loginDetails.result.userLoginInfo;
    this.setUserInfo(loginDetails);

    if (!this.ADB2CAuthenticationEnabled && loginDetails.result.userLoginInfo.isNewUser === true) {
      this.setPassword = true;
      let content = { title: "SETUP PASSWORD", userName: this.userName, tenantId: this.tenantId, userInfo: this.userInfo }
      this.setUpPassword(content, true);
    }
    else if (!this.ADB2CAuthenticationEnabled && loginDetails.result.userLoginInfo.isPasswordExpired === true) {
      this.passwordSetting = loginDetails.result.passwordSetting;
      let content = { title: "CHANGE PASSWORD", userName: this.userName, tenantId: this.tenantId, userInfo: this.userInfo, passwordSetting: this.passwordSetting  }
      this.setUpPassword(content, false);
    }
    else {
      this.propertyValues = loginDetails.result.userProperties;
      this.multipleProperties = this.propertyValues.map(x => { return { id: x.propertyCode, name: x.propertyName } });
      this.userMachineInfo = await this.retailPropertySettingDataService.GetMachineNamesAndConfigurationSetting(this.userInfo.userId, GlobalConst.Product.GOLF,
        this.propertyValues.map(x => x.propertyId));
      if (this.propertyValues.length > 1 || this.propertyValues.length == 0) {
        this.loginSuccess = !this.loginSuccess;
      }
      this.captions = this.localize.getCaptions().login;
      this.enableLoginloader(false);
      if (this.propertyValues.length == 1) {
        this.loginSuccess = false;
        this.enablePropertySelection = true;
        // GetMachineName
        await this.setMachineInfo(this.propertyValues[0].propertyId);
        if (this.isMachineNameEnabled && this.isPromptOnLoginEnabled && this.machineNames.length > 0) {
          this.enableLoginloader(false);
          this.loginSuccess = true;
          this.hideLoginForm = false;
          this.loginForms.controls["location"].setValue(this.multipleProperties[0]);
        } else {
          this.ValidateCredentials({ UserName: this.muname, Password: this.loginForms.controls["password"].value, TenantId: this.tenantId != null ? this.tenantId : 0, Property: this.multipleProperties[0], ProductId: GlobalConst.Product.GOLF, TenantCode: this.tenantCode });
        }
      }
      else {
        this.hideLoginForm = false;
      }
    }
  }

  async setUserInfo(loginDetails) {
    this.userProperties = loginDetails.result.userProperties && loginDetails.result.userProperties != null ? loginDetails.result.userProperties : {};
    let language = loginDetails.result.userLoginInfo && loginDetails.result.userLoginInfo.languageCode;
    const userInfo = `userId=${loginDetails.result.userLoginInfo.userId};
                      userName=${loginDetails.result.userLoginInfo.userName};
                      firstName=${loginDetails.result.userLoginInfo.firstName};
                      lastName=${loginDetails.result.userLoginInfo.lastName};
                      roleId=${this.userProperties && Object.keys(this.userProperties).length != 0 ? this.userProperties[0].roleId : 1};
                      roleName=${this.userProperties && Object.keys(this.userProperties).length != 0 ? this.userProperties[0].roleName : ""};
                      language=${language};
                      changePropertyEnabled=${loginDetails.result.userLoginInfo.isPropertyChangeAllow};
                    `;
    sessionStorage.setItem('_userInfo', userInfo);
  }

  async setUserSettings(properties) {
    /*To get the daysout value*/
    let serviceParams = {
      route: GolfRoutes.GetGolfUserConfig,
      uriParams: { id: this.userInfo.userId },
      header: '',
      body: '',
      showError: true,
      baseResponse: true
    };
    let userConfig: any = await this.golfService.getPromise(serviceParams);

    const userSettings = `daysOut=${userConfig && userConfig.userGolfConfiguration ? userConfig.userGolfConfiguration.daysOut : ''};
          AutoLogOff=${properties ? properties['autoLogOff'] : ''};
          LogOffAfter=${properties ? properties['logOffAfter'] : ''};
          TeeSheetView=${userConfig && userConfig.userGolfConfiguration ? userConfig.userGolfConfiguration.teeSheetView : ''}`;
    sessionStorage.setItem('userSettings', userSettings);
    this.setAutoLogOff();
  }
  async setpropertyvalues(Selectedproperty: any, userProperties?) {
    let result = userProperties? userProperties.find(item => item.propertyCode === Selectedproperty.id): this.propertyValues.find(item => item.propertyCode === Selectedproperty.id);
    let maxDecimalPlace = result["maximumDecimalPlaces"] >= 0 ? result["maximumDecimalPlaces"] : 2;
    let userLanguageCode = this.userInfo != null && this.userInfo.languageCode != "" ? this.userInfo.languageCode : result["languageCode"];
    let PropertyValues = "Language=" + result["languageCode"] + "; PropertyCode=" + result["propertyCode"] +
      "; SubPropertyCode=" + result["subPropertyCode"] + "; Currency=" + result["currencyCode"] + "; TenantId=" + result["tenantId"] + "; userName=" + this.userName
      + "; UserId=" + this.userInfo.userId + "; PropertyId=" + result['propertyId'] + "; SubPropertyId=" + result['subPropertyId'] + "; PlatformTenantId=" + result['platformTenantId'] + "; PropertyDate=" + result['propertyDate']
      + "; TimeZone=" + result['timeZone'] + "; PropertyName=" + result['propertyName']
      + "; UserLanguage=" + userLanguageCode + "; ProductId=" + result['productId'] + "; MaxDecimalPlaces=" + maxDecimalPlace + "; PropTimeFormat=" + result['propTimeFormat'] + "; PlatFormExtendedSearchRequired=" + result['platFormExtendedSearchRequired'];
    sessionStorage.setItem(this.propertyKey, PropertyValues);
    sessionStorage.setItem(this.propertyDateKey, result['propertyDate']);
    sessionStorage.setItem(RetailConstants.EnableResortFinance, this.userInfo.enableResortFinance.toString());
    await this.SetPropertyInfo(result);
    this.setUserSettings(result);
    this.propertyInfo.SetPropertyDate(this.utils.getDate(result['propertyDate']), false);
    this.localize.SetLocaleBasedProperties();
    this.retailLocalization.SetLocaleBasedProperties();
    this.UpdateUserRole(Selectedproperty.id);
    await this.propertyService.SetJasperSoftServerMenuAccess();
    this.setEatecAcesConfig();
    await this.setAcesToken();
    await this.SetUserSessionConfiguration(this.userInfo.userId);
    await this.dmConfigDataService.SetDataMagineConfig();  
  }

  async setEatecAcesConfig() {
    this.propertyFeatureService.getPropertyFeatures().then(async (feature) => {
      const eatecFeature = feature && feature.find(x => x.featureName === FeatureName.EnhancedInventory && x.isActive);
      const memberFeature = feature && feature.find(x => x.featureName === FeatureName.ACES_Membership && x.isActive);
      const pmsRevenuePosting = feature && feature.find(x => x.featureName === FeatureName.PMS_RevenuePosting && x.isActive);
      const propIds = [];

      if (eatecFeature) {
        let isEatecAsMaster = false;
        const configuration = await this.configuration.GetTenantConfiguration();
        if(configuration?.configValue) {
          isEatecAsMaster =  configuration.configValue?.IsEatecMaster ? configuration.configValue.IsEatecMaster.toLowerCase() == 'true' : false ;
          sessionStorage.setItem("isEatecAsMaster" , isEatecAsMaster.toString());
        }
        if(!isEatecAsMaster) {
          sessionStorage.setItem('isEatecEnabled', 'true');
          propIds.push(eatecFeature.id);
          await this.setEatecToken();
          const propConfig :{} = JSON.parse(sessionStorage.getItem('propConfig'));
          const enableRetailIC = propConfig? (propConfig['EnableRetailIC'] == 'true'? true: false): false;
          if(enableRetailIC) {
            const siteId = await this.retailPropertySettingDataService.GetSiteIdForIC();
            sessionStorage.setItem('LoggedInSiteId', JSON.stringify(siteId));
            let outlets = await this._subPropertyDataService.getOutlets();
            let OutletIdlist:any = outlets.map(x=>x.id);
            sessionStorage.setItem('FromLocId', JSON.stringify((OutletIdlist ? OutletIdlist : '')));
            sessionStorage.setItem('IniDateFieldFormat', this.localize.inputDateFormat);
            sessionStorage.setItem('LocalCurrencyCode', this.localize.currencyCode);
          }
        }
      
      } else {
        sessionStorage.setItem('isEatecEnabled', 'false');
        this.retailpropertyInfo.SetEatecRI('');
      }

      if (memberFeature) {
        propIds.push(memberFeature.id);
      } else {
        this.retailpropertyInfo.SetMemberConfiguration('');
      }

      if (pmsRevenuePosting) {
        propIds.push(pmsRevenuePosting.id);
      } else{
        sessionStorage.setItem('pmsSystem', '');
      }
      if (propIds.length > 0) {
        this.propertyFeatureService.GetFeatureConfigurationsById(propIds).then(featureconfigurations => {
          // Eatec
          if (eatecFeature && featureconfigurations != null && featureconfigurations.length > 0) {
            const eatecUser = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Eatec.EatecTenantUser);
            const uri = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Eatec.EatecURI);
            const EIFeature = {
              EISSOWaitPeriodInSecs: featureconfigurations.find(f => f.configurationKey === ConfigKeys.Eatec.EISSOWaitPeriodInSecs)?.configurationValue,
              EISSOTriggerAlways: featureconfigurations.find(f => f.configurationKey === ConfigKeys.Eatec.EISSOTriggerAlways)?.configurationValue,
            };
            this.retailpropertyInfo.SetEatecConfiguration(EIFeature);
            if (eatecUser && eatecUser.configurationValue && uri && uri.configurationValue) {
              this.retailpropertyInfo.SetEatecRI(uri.configurationValue);
            } else {
              this.retailpropertyInfo.SetEatecRI('');
            }
          } else {
            this.retailpropertyInfo.SetEatecRI('');
          }

          // ACES
          if (memberFeature && featureconfigurations != null && featureconfigurations.length > 0) {
            const uri = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.LoyaltyURI);
            const loyaltyServiceURI = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.LoyaltyServiceURI);
            const loyaltyTokenRequestInfo = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.LoyaltyTokenRequestInfo);
            const loyaltySourceCorpID = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.LoyaltySourceCorpID);
            const enforceMemberPayment = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.EnforceMemberPayment);
            const displayCreditBookBalance = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.DisplayCreditBookBalance);
            const allowTenderAmountOverrideForMember = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.AllowTenderAmountOverrideForMember);
            const memberBucketName = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.MemberBucketName);
            const creditBookBucketName = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.CreditBookBucketName);
            const requirePin = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.RequirePin);
            const applicableCorporates = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.ApplicableCorporates);
            const corpIdNameMap = featureconfigurations.find(f => f.configurationKey === ConfigKeys.Member.CorpIdNameMap);
            const loyalty = {
              loyaltyURI: uri && uri.configurationValue ? uri.configurationValue : '',
              loyaltyServiceURI: loyaltyServiceURI && loyaltyServiceURI.configurationValue ? loyaltyServiceURI.configurationValue : '',
              loyaltyTokenRequestInfo: loyaltyTokenRequestInfo && loyaltyTokenRequestInfo.configurationValue ?
                loyaltyTokenRequestInfo.configurationValue : '',
              loyaltySourceCorpID: loyaltySourceCorpID && loyaltySourceCorpID.configurationValue ? loyaltySourceCorpID.configurationValue : 0,
              enforceMemberPayment: (enforceMemberPayment && enforceMemberPayment.configurationValue) || "false",
              displayCreditBookBalance: (displayCreditBookBalance && displayCreditBookBalance.configurationValue) || "false",
              allowTenderAmountOverrideForMember: (allowTenderAmountOverrideForMember?.configurationValue),
              memberBucketName: (memberBucketName?.configurationValue?.trim()) || DefaultBucketName.memberBucketName,
              creditBookBucketName: (creditBookBucketName?.configurationValue?.trim()) || DefaultBucketName.creditBookBucketName,
              requirePin: (requirePin?.configurationValue?.trim()) || "false",
              redeemRoundsForGuests: featureconfigurations.find(f => f.configurationKey ===
                ConfigKeys.Member.RedeemRoundsForGuests)?.configurationValue || 'false',
              enableMultipleMember: featureconfigurations.find(f => f.configurationKey ===
                ConfigKeys.Member.EnableMultipleMember)?.configurationValue || 'false',
              showCurrentRoundsOnReprint: featureconfigurations.find(f => f.configurationKey ===
                ConfigKeys.Member.ShowCurrentRoundsOnReprint)?.configurationValue || 'false',
              applicableCorporates :  applicableCorporates && applicableCorporates.configurationValue ? applicableCorporates.configurationValue : '',
              corpIdNameMap : corpIdNameMap && corpIdNameMap.configurationValue ? corpIdNameMap.configurationValue : ''
            };
            this.retailpropertyInfo.SetMemberConfiguration(loyalty);
          } else {
            this.retailpropertyInfo.SetMemberConfiguration('');
          }
          if (pmsRevenuePosting && featureconfigurations != null && featureconfigurations.length > 0) {
            const pmsSystem = featureconfigurations.find(f => f.configurationKey === ConfigKeys.PMSRevenuePosting.PMSSystem)?.configurationValue;
            if (pmsSystem && pmsSystem != null) {
              sessionStorage.setItem('pmsSystem', pmsSystem);
            }
          } else{
            sessionStorage.setItem('pmsSystem', '');
          }
        });
      }
    });
  }

  async getSiteId() {
    try {
      const serviceParams = {
        route: GolfRoutes.EatecToken,
        showError: true
      };
      const token = await this.loginService.makePostCall(serviceParams, false);
      sessionStorage.setItem('eatecJwt', token.result);
    } catch (ex) {

    }
  }

  async setEatecToken() {
    try {
      const serviceParams = {
        route: GolfRoutes.EatecToken,
        showError: true
      };
      const token = await this.loginService.makePostCall(serviceParams, false);
      sessionStorage.setItem('eatecJwt', token.result);
    } catch (ex) {

    }
  }
  async setAcesToken() {
    try {
      const serviceParams = {
        route: GolfRoutes.AcesToken,
        showError: true
      };
      const token = await this.loginService.makePostCall(serviceParams, false);
      sessionStorage.setItem('acesJwt', token.result);
    } catch (ex) {

    }
  }

  async CreateUserSession(sessionInfo, token: string) {
    const utcDate: Date = this.localize.getUTCDateTimeNow();
    let sessionData = {
      id: 0,
      userId: Number(sessionInfo["userId"]),
      startTime: this.localize.convertDateTimeToAPIDateTime(utcDate),
      propertyId: Number(sessionInfo['propertyId']),
      productId: Number(sessionInfo['productId']),
      timeZone: this.utils.GetClientTimeZone(),
      token: token
    };
    await this.CreateSession(sessionData);
  }

  async CreateSession(data) {
    let serviceParams = {
      route: GolfRoutes.CreateSession,
      uriParams: '',
      header: '',
      body: data,
      showError: true,
      baseResponse: true
    };
    let result = await this.loginService.makePostCall(serviceParams);
    var response = <any>result.result;

    sessionStorage.setItem('userSession', response);

    if(this.propertyInfo.isResortFinanceEnabled){
      sessionStorage.setItem('_accUserSession', response);
      sessionStorage.setItem('_folioUserSession', response);
    }

    try{
      if(this.localize.GetSupportUserMailId())
      {
        let data = {
          userEmail : this.localize.GetSupportUserMailId(),
          sessionId : response
        };
    
        const auditParams = {
          route: LoginRoutes.AuditSupportUser,
          uriParams: '',
          header: '',
          body: data,
          showError: true,
          baseResponse: true
        };
    
        await this.loginService.makePutCall(auditParams, false);
      }
    }
    catch{
      console.log("Error in auditing support user")
    }
  }

  setUpPassword(e: any, isSetPassword: boolean) {
    this.componentDetails = {
      componentName: SetPasswordComponent,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      }
    };
    this.dialog.open(SetPasswordComponent, {
      width: '55%',
      height: 'auto',
      disableClose: true,
      data: { title: e.title, componentDetails: this.componentDetails, setPassword: isSetPassword, userName: e.userName, tenantId: e.tenantId, userInfo: e.userInfo, passwordSetting: e.passwordSetting, uTempData: { uTempPri: this.uTempDataPrimary, uTempSec: this.uTempDataSecondary } }
    }).afterClosed().subscribe(res => {
        this.enableLoginloader(false);
    });
    this.loginForms.controls["password"].setValue('');
    this.errResponse = '';
  }

  setAutoLogOff() {
    this._autoLogOff = this.localize.GetUserSettings('AutoLogOff');
    const tokenDuration = parseInt(sessionStorage.getItem('loginDuration'));
    if (this._autoLogOff == "true") {
      this.sessionService.resetOnTrigger = true;
      this._logOffAfter = +this.localize.GetUserSettings('LogOffAfter');
      this.sessionService.startTimer(this._logOffAfter, tokenDuration);
    } else {
      this.sessionService.resetOnTrigger = false;
    }
  }

  async SetPropertyInfo(result: any) {
    let propertyId: number = Number(result['propertyId']);
    this.propertyInfo.SetPropertyDate(result['propertyDate']);
    this.propertyInfo.SetPropertyId(propertyId);
    await this.SetPropertyConfiguration();
    this.setGdprConfiguredData();
    this.IsGolfNowEnabled();
    this.SetAuthorizeIntegrationConfiguration();
    if (!this.propertyInfo.UseRetailInterface) {
      this.SetPaymentConfiguration(propertyId);
      this.SetComponentAccessLinkConfig();
    }
  }
  async setGdprConfiguredData() {
    let userInfoTenantId = this.tenantId ? this.tenantId : this.propertyInfo.GetPropertyInfoByKey('TenantId');
    if(userInfoTenantId){
      var isGdprEnabled = await this.guestDataPolicyDataService.GetDataRetentionPolicyConfiguredFlag(Number(userInfoTenantId));
      sessionStorage.setItem("isGdprEnabled", isGdprEnabled.toString())
    }
  }
  async SetPropertyConfiguration() {
    let propertityConfig = await this.PropertySettingService.getAllPropertySetting(this.propertyInfo.PropertyId);
    this.propertyInfo.SetPropertySetting(propertityConfig);
    let propertyConfig = await this.PropertySettingService.getPropConfig(this.propertyInfo.PropertyId);
    if ((propertyConfig != null) && (Object.keys(propertyConfig.configValue).length > 0)) {
      this.propertyInfo.SetPropertyConfiguration(propertyConfig);
    }
    await this.setAccountingPropertyDate();
    await this.propertyService.setAuthorizeTokenBySession();
    this.SetNoOfDecimalDigits(propertyConfig);
    this.SetElasticApm(propertyConfig);
    this.SetFullStory(propertyConfig);
  }

  SetNoOfDecimalDigits(propertyConfig: any) {
    var noOfDecimalDigits:string='2';
    if (propertyConfig.configValue != undefined && propertyConfig.configValue[NO_OF_DECIMAL_DIGITS] != undefined){
      noOfDecimalDigits=propertyConfig.configValue[NO_OF_DECIMAL_DIGITS];
    }
    sessionStorage.setItem('noOfDecimalDigits',noOfDecimalDigits);
  }

  async SetAuthorizeIntegrationConfiguration() {
    let authorizeIntegrationconfig = await this.authIntegrationService.GetAuthorizeConfig();
    this.propertyInfo.SetAuthorizeConfiguration(authorizeIntegrationconfig);
  }

  async SetPaymentConfiguration(propertyId: number) {
    let propertyPaymentConfig = await this.PropertySettingService.GetPaymentConfigurationByProperty(propertyId);
    this.propertyInfo.SetPaymentConfiguration(propertyPaymentConfig);
    this.GetSupportedPMAgentVersionByPropertyID(propertyId);
    this.GetWebCommunicationProxyVersion();
  }

  SetComponentAccessLinkConfig() {
    this.componentAccessConfigService.SetComponentAccessTypeLinkConfig();
  }

  async GetSupportedPMAgentVersionByPropertyID(propertyId: number) {
    if (this.propertyInfo.SkipPMAgent) return; //No need to Validate the PMAgent version if SkipPMAgent is enabled
    const supportedPmAgentVersion = await this.PropertySettingService.GetSupportedPMAgentVersionByPropertyID(propertyId);
    this.propertyInfo.SetSupportedPMAgentVersion(supportedPmAgentVersion);
    this.payAgentService.ValidatePayAgentVersion();
  }

  async GetWebCommunicationProxyVersion(){
    const WebProxyCheck = await this.retailPropertySettingDataService.GetWebCommunicationProxyVersion();
    this.retailpropertyInfo.SetWebCommunicationProxyVersionCheck(WebProxyCheck)
  }

  rememberUser() {
    this.rememberMe = this.loginForms.controls["rememberme"].value; let user = this.loginForms.controls["userId"].value;
    if (this.rememberMe) this.sessionManageService.StoreUser(user);
    else this.sessionManageService.RemoveUser(user);
  }

  async setAccountingPropertyDate(){
    const enableResortFinance = sessionStorage.getItem(RetailConstants.EnableResortFinance);
    if (enableResortFinance?.toLowerCase() == 'true')
    {
      let propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
      if(propConfig && propConfig.AccountingPropertyId)
      {
        this.commonPropertySettingService.UpdatePropertySettingDateByProductId(this.localize.convertDateToAPIdate(this.localize.getCurrentDate()), propConfig.AccountingPropertyId, AllProducts.ACCOUNTING);
      }
    }
  }

  GetUserToken(result: any) {
    let bodyData = {
      TenantId: result["tenantId"],
      ProductId: result["productId"],
      UserId: result["userId"],
      PropertyId: result["propertyId"],
      SubPropertyId: result["subPropertyId"]
    };
    let serviceParams = {
      route: GolfRoutes.UserToken,
      uriParams: '',
      header: '',
      body: bodyData,
      showError: true,
      baseResponse: true
    };
    return this.loginService.makePostCall(serviceParams).then((t: any) => {
      if (this.localize.validateString(t.result)) {
        sessionStorage.setItem('_userToken', t.result);
      }
    });

  }

  UpdateUserRole(propertyId: string) {
    let user = sessionStorage.getItem('_userInfo');
    let userSelectedProperty = this.userProperties.find(x => x.propertyCode == propertyId);
    let userInfoArr = user.split(';');

    userInfoArr = userInfoArr.map(function (item) {
      if (item.includes('roleId'))
        return userSelectedProperty && userSelectedProperty.roleId ? 'roleId=' + userSelectedProperty.roleId : item;
      else if (item.includes('roleName'))
        return userSelectedProperty && userSelectedProperty.roleName ? 'roleName=' + userSelectedProperty.roleName : item;
      else
        return item;
    });

    user = userInfoArr.join(';');
    sessionStorage.setItem('_userInfo', user);
  }

  ngOnDestroy() {
    this.stopInterval();
    if (this.useridSubscribe) {
      this.useridSubscribe.unsubscribe();
    }
    if (this.passwordSubscribe) {
      this.passwordSubscribe.unsubscribe();
    }
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  private async setMachineInfo(propertyId: number) {
    this.resetMachineNameInfo();
    const userMachinePropertyInfo = this.userMachineInfo.userPropertiesMachineInfo.find(x => x.propertyId == propertyId);
    const miscConfiguration = userMachinePropertyInfo.miscConfiguration;
    // TRANSACTION_BY_MACHINENAME
    this.isMachineNameEnabled = miscConfiguration.enableTransactionByMachineName;
    // SELECTION_ON_LOGIN
    this.isPromptOnLoginEnabled = miscConfiguration.promptOnLogin;
    if (miscConfiguration.printerManagerURI) {
      this.localize.SetPrinterManagerURI(miscConfiguration.printerManagerURI);
    }
    if (this.isMachineNameEnabled) {
      this.defaultMachineId = userMachinePropertyInfo.userDefault.defaultMachineId;
      this.machineNames = userMachinePropertyInfo.machineNames.map(x => {
        return {
          id: x.id,
          name: x.name
        }
      });
      if (this.isPromptOnLoginEnabled && this.machineNames.length > 0) {
        this.loginForms.controls['machineName'].setValue('');
      }
    }
    const machineName = this.machineNames.find(x => x.id == this.defaultMachineId);
    this.defaultMachineId = machineName ? machineName.id : 0;
    if (this.defaultMachineId) {
      this.loginForms.controls['machineName'].setValue(machineName);
    }
  }

  compareSelect = (val1, val2) => {
    return val1 && val2 && val1.id === val2.id;
  }

  private resetMachineNameInfo() {
    this.isMachineNameEnabled = false;
    this.isPromptOnLoginEnabled = false;
    this.defaultMachineId = 0;
    this.machineNames = [];
    this.loginForms.controls['machineName'].setValue('0');
  }

  private setMachineDetails() {
    const userMachine = this.loginForms.value.machineName;
    if (typeof (userMachine) == 'object') {
      this.localize.SetMachineId(userMachine.id);
      this.localize.SetMachineName(userMachine.name);
      this.propertyService.SetMachinePrinterConfigForMachine(userMachine.id);
    } else {
      this.localize.SetMachineId(0);
      this.localize.SetMachineName('');
    }
  }

  SetElasticApm(propertyConfig: any) {
    if (propertyConfig.configValue != undefined && propertyConfig.configValue[ElasticApm.UseApmServer] == 'true') {
      // tslint:disable-next-line:max-line-length
      if (propertyConfig.configValue[ElasticApm.ApmServerUrl] !== undefined && propertyConfig.configValue[ElasticApm.DistributedTracingOrigins] !== undefined) {
        const apm = this.apmElasticService.init({
          serviceName: ElasticApm.ServiceName,
          serverUrl: propertyConfig.configValue[ElasticApm.ApmServerUrl],
          distributedTracingOrigins: propertyConfig.configValue[ElasticApm.DistributedTracingOrigins].split(',')
        })
        apm.setUserContext({
          'username': this.userInfo.userName,
          'id': this.propertyInfo.PropertyId
        });
      }
    }
  }

  SetFullStory(propertyConfig: any) {
    if (propertyConfig.configValue != undefined && propertyConfig.configValue[FULL_STORY_ORG_ID] != undefined) {
      FullStory.init({ orgId: propertyConfig.configValue[FULL_STORY_ORG_ID] });
      FullStory.identify('GOLF-' + this.userInfo.userName, {
        "displayName": 'GOLF-' + this.userInfo.userName,
        "productId": Product.GOLF.toString(),
        "productName": "GOLF",
        "tenantId": this.userInfo.tenantId?.toString() ?? "",
        "tenantCode": this.userInfo.tenantCode?.toString() ?? "",
        "propertyId": propertyConfig.propertyId?.toString() ?? "",
        "propertyName": this.propertyInfo.GetPropertyInfoByKey('PropertyName')
      });
    }
  }
  setValues() {
    this.uTempDataPrimary = this.utempdatautils.GetUTempData(3);
    this.uTempDataSecondary = this.utempdatautils.GetUTempData(1);
  }
  clearLclCookie(idname) {
    this.localize.clearLocalCookie(idname);
    this.loginForms.controls['customerId'].setValue('');
    this.loginForms?.controls["customerId"].enable();
    this.showCustomerID = true;
    this.loginForms.controls['customerId'].setValidators([Validators.required]);
    this.loginForms.controls['customerId'].updateValueAndValidity();
    this.loginForms.markAsUntouched();
  }

  async ProcessSupportUserLogin(email: string)
  {

    let token = this.oauthService.getIdToken();

    sessionStorage.setItem(JWT_TOKEN, token);
    localStorage.setItem(JWT_TOKEN, token);

    this.localize.SetSupportUserMailId(email);
    this.commonLocalize.setLocalCookie('supportUserMailId',email);
    const mailValidationParams = {
      route: GolfRoutes.ValidateUserByProductTenantAndEmail,
      uriParams: {  productId: Product.GOLF,tenantId : SUPPORT_TENANT, emailId: email},
      header: '',
      showError: true,
      baseResponse: true
    };

    let isUserExistsInSupportTenant : any = await this.loginService.makePutCall(mailValidationParams, false);

    if(!isUserExistsInSupportTenant?.result){
      await this.utils.showAlert(this.captions.err_userAccess_Denied_message,AlertType.Error, ButtonType.Ok).afterClosed().toPromise();
      this.sessionService.logout();
      return;
    }
    this.enableSupportUserInputElementsRequiredField(true);
    this.captionGenerator();
    // this.loginSuccessCaption = this.captions.SelectYourLoginDetails;

    const tenantParams = {
      route: GolfRoutes.GetTenantGroupDetailByProductId,
      uriParams: { productId : GlobalConst.Product.GOLF},
      header: '',
      showError: true,
      baseResponse: true
    };

    const propertyParams = {
      route: GolfRoutes.GetPropertyDetailsByProductId,
      uriParams: { productId : GlobalConst.Product.GOLF},
      header: '',
      showError: true,
      baseResponse: true
    };
    let tenantData : any = this.loginService.makeGetCall(tenantParams, false);
    let propertyData : any = this.loginService.makeGetCall(propertyParams, false);


    let responses = await Promise.all([tenantData,propertyData]);

    this.allTenantDetails = responses[0].result;
    this.allPropertyDetails = responses[1].result;

    this.tenantIdList = this.getTenantIdList(this.allTenantDetails);
    this.tenantIdList.sort((a, b) => a.viewValue.localeCompare(b.viewValue))
    this.initialTenantIdList = [...this.tenantIdList]

    if(!this.tenantIdList || this.tenantIdList.length <= 0){
      console.log('Empty tenant List to display');
      return;
    }

    this.propertyIdListForATenant = this.filterPropertyByTenant(this.allPropertyDetails,this.tenantIdList[0].id);

    if(!this.propertyIdListForATenant || this.propertyIdListForATenant.length <= 0){
      console.log('Empty property List to display');
      return;
    }

    this.loginForms?.controls['tenantId']?.setValue(this.tenantIdList[0].id);
    this.loginForms?.controls['propertyId']?.setValue(this.propertyIdListForATenant[0].id);
    this.enableLoginloader(false);
    this.showCustomerID = false;
    this.hideLoginForm = false;
    this.loginSuccess = true;
    this.isSupportUser = true;

    await this.updateSupportUserInfoOnTenantSelection(this.tenantIdList[0].id);

    this.userMachineInfo = await this.retailPropertySettingDataService.GetMachineNamesAndConfigurationSetting(this.userInfo.userId, GlobalConst.Product.GOLF, [this.propertyIdListForATenant[0].id]);
    this.setMachineInfo(this.propertyIdListForATenant[0].id);
  }

  enableSupportUserInputElementsRequiredField(isEnableRequiredField: boolean){

    if(!this.loginForms || !this.loginForms.controls['tenantId'] || !this.loginForms.controls['propertyId'] || !this.loginForms.controls['roleId']){
      return;
    }

    if(isEnableRequiredField)
    {
      this.loginForms.controls['tenantId'].addValidators(Validators.required);
      this.loginForms.controls['propertyId'].addValidators(Validators.required);
    }
    else{
      this.loginForms.controls['tenantId'].removeValidators(Validators.required);
      this.loginForms.controls['propertyId'].removeValidators(Validators.required);
    }
  }


  async validateAdb2cCredentialsForSupportUser(){

    var credentials = {
      productId: GOLF_PRODUCT_ID,
      tenantId: this.loginForms.controls['tenantId'].value,
      supportTenantId: SUPPORT_TENANT
    };

    const serviceParams = {
      route: LoginRoutes.SupportUserLogin,
      uriParams: '',
      header: '',
      body: credentials,
      showError: true,
      baseResponse: true
    };

    const loginDetails = await this.loginService.makePutCall(serviceParams, false);

    if (loginDetails.successStatus) {
      let token = this.oauthService.getIdToken();
      loginDetails.result.token = token;
      const jwtToken = JSON.parse(atob(token.split('.')[1]));
      const jwtExpiryTime = new Date(jwtToken.exp * 1000);
      const timeout = jwtExpiryTime.getTime() - Date.now();
      const loginDuration = Math.round(timeout/1000);
      const loginResponse: any = loginDetails;
      if (loginResponse.result.loginDuration) {
        loginResponse.result.loginDuration = loginDuration;
        sessionStorage.setItem('loginDuration', loginResponse.result.loginDuration);
        localStorage.setItem('loginDuration', loginResponse.result.loginDuration);
        const tokenDuration = parseInt(sessionStorage.getItem('loginDuration'));
        this.sessionService.startTimer(0, tokenDuration);
        sessionStorage.setItem('jwtExpiryTime', jwtExpiryTime.toString());
        localStorage.setItem('jwtExpiryTime', jwtExpiryTime.toString());
      }
      await this.setPropertyForSupportUser(loginDetails);

    } else {
      if (loginDetails.errorCode == 5001) {
        this.loginError = true;
        this.enableLoginloader(false);
        // this.loginButton.disabledproperty = false;
        this.errResponse = loginDetails.errorDescription;
        this.hideLoginForm = false;
      }
      this.utils.showAlert(loginDetails.errorDescription, AlertType.Error, ButtonType.Ok, async (res) => {
        this.adb2cLogout();
      });
    }
  }

  async setPropertyForSupportUser(loginDetails){

    // Need to remove this hardcoded value
    loginDetails.result.userLoginInfo.isPropertyChangeAllow = true;

    this.userName = loginDetails.result.userLoginInfo.userName;
    this.userInfo = loginDetails.result.userLoginInfo;
    this.setUserInfo(loginDetails);
    this.userDetail = loginDetails;

    let selectedProperty = this.allPropertyDetails.filter(x => x.id == this.loginForms.controls['propertyId'].value);

    if(!selectedProperty || !selectedProperty[0])
    {
      console.log('Error in property selected data');
      return;
    }

    let locationData = {
      id: selectedProperty[0].propertyCode,
      name: selectedProperty[0].propertyName
    };

    this.captionGenerator();
    this.loginForms.controls.location.setValue(locationData);
    // this.enableLocation = true;

    // Taken this from adb2cAuthValidation method
    const credentials = {
      Property: this.loginForms.get('location').value,
      ProductId: GlobalConst.Product.GOLF
    };
    const result = loginDetails.result.userProperties.find(item => item.propertyCode === credentials.Property.id);
    await this.CreateUserSession(result, sessionStorage.getItem("_jwt"));
    await this.setpropertyvalues(credentials.Property,loginDetails.result.userProperties);
    this.systemDefaultsService.syncDefaultSettings();
    this.userDefaultsService.syncDefaultValues(this.userInfo.userId);
    this.setMachineDetails();
    this.router.navigate(['/home']);

  }

  async onTenantIdChange(eve){
    this.propertyIdListForATenant = this.filterPropertyByTenant(this.allPropertyDetails, eve.value);

    await this.updateSupportUserInfoOnTenantSelection(eve.value);

    if(!this.propertyIdListForATenant || this.propertyIdListForATenant.length <= 0){
      return;
    }

    this.loginForms.controls['propertyId'].setValue(this.propertyIdListForATenant[0].id);
    this.onPropertyIdChange({value: this.propertyIdListForATenant[0].id});
  }

  async onPropertyIdChange(eve){
    if(!this.userInfo || !eve){
      return;
    }
    this.userMachineInfo = await this.retailPropertySettingDataService.GetMachineNamesAndConfigurationSetting(this.userInfo.userId, GlobalConst.Product.GOLF, [eve.value]);
    this.setMachineInfo(eve.value);
  }

  filterPropertyByTenant(data: any[], tenantId: number)
  {
    var tenantProperities =  cloneDeep(data.filter(x => x.tenantId == tenantId));
    return tenantProperities.map(x => {
      return{
        id: x.id,
        value: x.id,
        viewValue: x.propertyName
      };
    });
  }

  getTenantIdList(data: any[]){
    return data.map(x => {
      return{
        id: x.tenantId,
        value: x.tenantId,
        viewValue: x.contextName
      };
    });
  }
  async updateSupportUserInfoOnTenantSelection(tenantId: number)
  {
    const userParams = {
      route: GolfRoutes.GetUserByTenantId,
      uriParams: { UserName : SUPPORT_USERNAME, tenantId : tenantId},
      header: '',
      showError: true,
      baseResponse: true
    };

    let userData : any = await this.loginService.makeGetCall(userParams, false);
    this.userInfo = userData.result;
  }

  private _filter(value: string) {
    if(value){
      const filterValue = value.toLowerCase();
      return this.tenantIdList = this.initialTenantIdList.filter(x => (x.viewValue.toLowerCase().includes(filterValue)) || (x.id.toString().toLowerCase().includes(filterValue)))
    } else {
      return this.tenantIdList = this.initialTenantIdList;
    }

  }

  filterOptions(event) {
    this._filter(event.target.value);
  }

  openedChange(opened: boolean) {
    this.myInput.nativeElement.focus()
      if (!opened) {
      this.inputSearch = ''
      this._filter("");

    }
  }
  enableLoginloader(val:boolean)
  {
    if(this.loginForms?.valid && val)
    {
      this.showLoginloader=val?val:false;
      this.startInterval();
    }else{
      this.showLoginloader=false;
    }
  }
  startInterval() {
      // Set up the interval to execute a function every 1000 milliseconds (1 second)
      this.elapsedTime=0;
      this.intervalId = setInterval(() => {
      this.elapsedTime += 1000; // Increment elapsed time by 1 second
      // Check if 30 seconds have passed
      if ((document.getElementById("bodyId")?.getElementsByClassName("Errorpop-container-Golf").length > 0) ||
      (document.getElementById("bodyId")?.getElementsByClassName("errorpop-container").length > 0) || (document.getElementById("bodyId")?.getElementsByClassName("Errorpop-container").length > 0) ) {
      this.stopInterval(); // Clear the interval if the condition is met
      }
    if (this.elapsedTime >= 50000) {
     this.stopInterval(); // Clear the interval if 50 seconds have passed
    }}, 1000);
  }
  stopInterval() {
    // Clear the interval when called
    this.enableLoginloader(false);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }


}
