<div class="ag_p--3" (click)="$event.stopPropagation()">
    <div class="align-items-center">
          <h6 class="ag_font--bold">{{commonCaptions.hdr_updatePlayerDetails}}
          <i class="icon-close ag_cursor--pointer float-right" (click)="onAction($event,paymentModalType.close)"></i></h6>
    </div>
    <div>
      <form class="ag_display--flex-wrap" [formGroup]="payForm">
        <div class="left-sect pr-3 pt-2">
          <ag-dropdown (selectionChange)='playerTypeChange($event)' class="ag_display--block" [placeholder]="commonCaptions.lbl_searchPlayerType" formControlName="playerType"
          [source]="playerTypeOptions" [showEmptyOption]="true" [required]="rowObject?.isPlayerTypeRequired">

        </ag-dropdown>
          <ag-dropdown (selectionChange)='rateTypeChange($event)' class="ag_display--block" [placeholder]="commonCaptions.lbl_searchRateType" formControlName="rateType"
          [source]="rateTypeOptions" [isIconEnabled]="true" [iconClass]="'icon-Stop_availability'" [showEmptyOption]="true" [required]="rowObject?.isRateTypeRequired"></ag-dropdown>
        </div>
        <div class="right-sect pt-2 pl-2">
          <div class="ag_display--flex align-items-center">
            <div class="w-50 mr-4">
              <ag-input class="" [placeholder]="captions.greenFee" [maxlength]=10 [fieldUpdate]="triggerCurrencyGreenFee"
                 formControlName="greenFee" (change)="onNegotiatingFee()" [disabled]="!isNegotiateGreenFee || rowObject.isMultiPackApplied" required type="currency">
              </ag-input>
            </div>
            <div class="w-50">
              <label class="golf-form--label">{{commonCaptions.lbl_negotiateGreenFee}}</label>
              <ui-switch [attr.automationId]="'Tog_TeeSheetBooking_negotiateCartFee'" [disabled] = "isNegotiateDisabled || rowObject?.isMultiPackApplied" [checkedLabel]="captions.Yes" [uncheckedLabel]="captions.No"
                  (click)="isNegotiateAllow()" (change)="negotiateGfee($event)" formControlName="negotiateGreenFee">
              </ui-switch>
            </div>
          </div>
          <div class="ag_display--flex align-items-center">
            <div class="w-50 mr-4">
              <ag-input class="" [placeholder]="captions.cartFeeTitle" [maxlength]=10 [fieldUpdate]="triggerCurrencyCartFee"
                 formControlName="cartFee" (change)="onNegotiatingFee()" [disabled]="!isNegotiateCartfee || rowObject.isMultiPackApplied" required type="currency">
              </ag-input>
            </div>
            <div class="pt-2 w-50">
              <label class="golf-form--label">{{commonCaptions.lbl_negotiateCartFee}}</label>
              <ui-switch [attr.automationId]="'Tog_TeeSheetBooking_negotiateCartFee'" [disabled] = "isNegotiateDisabled || rowObject?.isMultiPackApplied" [checkedLabel]="captions.Yes" [uncheckedLabel]="captions.No"
                  (click)="isNegotiateAllow()" (change)="negotiateCfee($event)" formControlName="negotiateCartFee">
              </ui-switch>
            </div>
          </div>
          <div>
            <div class="pt-2">
              <div>{{commonCaptions.tbl_hdr_total}} ({{this.localization.currencySymbol}})</div>  
              <div class="pt-1">{{totalFee}}</div>
            </div> 
          </div>
        </div>
      </form>
    </div>
    <div class="bottom-sect ag_display--flex-wrap mt-2" *ngIf="from === 'fromTeeGrid'">
      <app-button [buttontype]="saveButton" (valueChange)='onAction($event,paymentModalType.save)'></app-button>
      <app-button [buttontype]="savePayButton" (valueChange)='onAction($event, paymentModalType.pay)'></app-button>
      <app-button [buttontype]="saveShopButon" (valueChange)='onAction($event, paymentModalType.shop)'></app-button>
      <app-button [buttontype]="cancelButton" (valueChange)='onAction($event, paymentModalType.cancel)'></app-button>
    </div>
    <div class="bottom-sect ag_display--flex-wrap mt-2" *ngIf="from != 'fromTeeGrid'">
      <app-button [buttontype]="updateButton" (valueChange)='onAction($event, paymentModalType.update)'></app-button>
      <app-button [buttontype]="cancelButton" (valueChange)='onAction($event, paymentModalType.cancel)'></app-button>
    </div>
</div>