import { UntypedFormGroup } from "@angular/forms";
import { DropdownOptions } from "src/app/common/Models/ag-models";

export const MAX_DATEPICKER_RANGE_IN_YEARS = 9999;

export const enum ReportBreakPoint {
	TeeSheet = 9000,
	CancellationReport = 9005,
	NoShowReport = 9010,
	WaitList = 9015,
	BagTags = 9020,
	TeeTickets = 9025,
	RainChecksIssued = 9030,
	MemberList = 9035,
	MemberRenewal = 9040,
	MemberRoundsPlayed = 9045,
	TournamentList = 9050,
	ScoreCards = 9055,
	CartCards = 9060,
	CartCardsForTeeTime = 9125,
	Lessons = 9065,
	InstructorSchedules = 9070,
	TeeTimeUtilization = 9075,
	TeeTimeAnalysis = 9080,
	TeeTimeStatisticsbyMonth = 9085,
	RoundsbyRateType = 9090,
	RoundsbyPlayerType = 9095,
	GolfTransactionLog = 9120,
	MemberRenewalList = 9110,
	ClientItinerary = 1200,
	BatchPrintClientItinerary = 1205,
	AppointmentListingbyLocation = 1210,
	AppointmentListingbyStaffMember = 1215,
	AppointmentListingbyCustomFields = 1220,
	AppointmentListing = 1225,
	StaffMemberSummaryReport = 1240,
	CommissionReport = 1245,
	GratuityReport = 1250,
	ServiceChargeReport = 1255,
	ReturnedItems = 8060,
	InventoryAudit = 8085,
	InventoryTransfer = 8080,
	InventoryDetail = 8070,
	InventorySummary = 8065,
	InventoryOnHand = 8736,
	MultiPackItems = 8055,
	CorrectVoid = 8015,
	SaleByDiscountType = 8050,
	InventoryWash = 8075,
	SaleByItem = 8035,
	SaleByCategory = 8040,
	SaleBySubCategory = 8045,
	RevenueSummary = 8010,
	TopSpenders = 8105,
	Shift = 8000,
	Transaction = 8005,
	CaddyTypeAssignment = 9140,
	CaddyAssignment = 9145,
	DepositsDue = 9130,
	DepositsApplied = 9135,
	OnlineTeeTime = 9150,
	NegotiatedRates = 9155,
  	BookedBySource = 9160,
	AccrualReport = 8020,
	TeeTimeBookedByUser =9165,
	GuestItineraryReport = 9170,
	ManualMarkAsPaid  = 9175,
	AllocationBlockHistory = 9185,
	//QUERY BUILDER  
	QueryBuilder = 101290,
	Folio = 101295,
	GenericExtract = 101300,
	Group = 101305,
	Guest = 101310,
	Reservations = 101315,
	Retail = 101320,
	RoomInventory = 101325,
	QueryBuilderDefault = 101500,
	FrontDesk = 101331,
	Sales = 101332,
	Default = 101500,
	RentalUtilization = 9185
}

export enum ReportCode {
	Teesheet = 'TeeSheet',
	CancelledTeeTimes = 'CancellationTeeTime',
	NoShow = 'NoShowTeeTime',
	WaitList = 'WaitList',
	BagTags = 'BagTags',
	RainChecksIssued = 'RainChecksIssued',
	TeeTickets = 'TeeTicket',
	TeeTicketReceipt ='TeeTicketReceipt',
	Memberlist = 'memberlist',
	MemberRenewal = 'memberRenewel',
	MemberRoundsPlayed = 'MemberRoundsPlayed',
	TouranmentList = 'TournamentsList',
	ScoreCards = 'Scorecard',
	CartCards = 'CartCards',
	Lesson = 'Lesson',
	InstructorSchedule = 'InstructorSchedule',
	TeetimeUtilization = 'TeeTimeUtilization',
	TeetimeAnalysis = 'TeeTimeAnalysis',
	TeetimeStatisticsByMonth = 'TeetimeStatisticsByMonth',
	RoundsByRateType = 'RoundsByRateType',
	RoundsByPlayerType = 'RoundsByPlayerType',
	TeetimeLog = 'teetimeLog',
	TransactionLog = 'TransLog',
	MultiPackItems = 'MultiPackItems',
	ReturnedItems = 'ReturnedItems',
	InventorySummary = 'InventorySummary',
	InventoryDetails = 'InventoryDetails',
	InventoryTransfer = 'InventoryTransfer',
	InventoryWash = 'InventoryWash',
	InventoryAudit = 'InventoryAudit',
	SalesByDiscountTypes = 'SalesByDiscountTypes',
	SalesBySubCategory = 'SalesBySubCategory',
	SalesByCategory = 'SalesByCategory',
	SaleByItem = 'SaleByItem',
	CorrectVoid = 'CorrectVoid',
	Shift = 'Shift',
	Transaction = 'Transaction',
	RevenueSummary = 'RevenueSummary',
	TopSpenders = 'TopSpenders',
	GolfTransactionLog = 'GolfTransactionLog',
	RoundsByCustomField1 = 'RoundsByCustomField1',
	RoundsByCustomField2 = 'RoundsByCustomField2',
	RoundsByCustomField3 = 'RoundsByCustomField3',
	RoundsByCustomField4 = 'RoundsByCustomField4',
	RoundsByCustomField5 = 'RoundsByCustomField5',
	RoundsByRateTypeForecast = 'RoundsByRateTypeForecast',
	RoundsByPlayerTypeForecast = 'RoundsByPlayerTypeForecast',
	RoundsByRateTypeConsolidated = 'RoundsByRateTypeConsolidated',
	CartCardsForTeeTime = 'CartCardsForTeeTime',
	CartCardsForTeeTimeLandscape = 'CartCardsTeeTime_Landscape',
	CaddyTypeAssignment = 'CaddyTypeAssignment',
	CaddyAssignment = 'CaddyAssignment',
	DepositsDue = 'DepositsDue',
	DepositsApplied = 'DepositsApplied',
	OnlineTeeTime = 'OnlineTeeTimes',
	OnlineTeeTimes_GolfNow = 'OnlineTeeTimes_GolfNow',
	NegotiatedRates = 'NegotiatedRates',
	CaddyShack = 'CaddyShack',
	BookedBySource = 'BookedBySource',
	TeeTimeBookedByUser ='TeeTimeBookedbyUser',
	GolfGuestItinerary = 'GolfGuestItinerary',
	ManualMarkAsPaid = 'ManualMarkAsPaid',
	AuditReport = "AuditReport",
	ReservationsWithPets = "ReservationsWithPets",
	ARDetailedCheckRegister = "ARDetailedCheckRegister",
	CustomerCheckPaymentPrint = 'CustomerCheckPaymentPrint',
	CheckRunPreviewReport = 'CheckRunPreviewReport',
	ARSummarizedCheckRegister = "ARSummarizedCheckRegister",
	PreviewCheckPrint = 'PreviewCheckPrint',
	CheckRegister = 'CheckRegister',
	DetailedCheckRegister = 'DetailedCheckRegister',
	TransactionsByBatch = 'TransactionsByBatch',
	EncashmentReceipt = 'CurrencyConversion',
	CurrencyConversion = 'EncashmentReceipt',
	CheckoutStatement = 'CheckoutStatement',
	FolioInvoice = 'FolioInvoice',
	VATInvoice = 'VATInvoice',
	ProFormaFolio = 'ProFormaFolio',
	ARDeletedInvoice = 'ARDeletedInvoice',
	AllocationBlockHistory = 'AllocationBlockHistory',
	RetailItemsUtilization = 'RetailItemsUtilization'

}

export enum ReportGroup {
	TeeTime,
	Member,
	Tournament,
	Instructor,
	Statistics,
	TransactionLog,
	Retail,
	CommissionGratuityServiceCharge
}

export enum ReportMenu {
	teetime = 'teetime',
	member = 'member',
	tournament = 'tournament',
	instructor = 'instructor',
	statistics = 'statistics',
	transactionlog = 'transactionlog',
	retail = 'retail',
	commissiongratuity = 'commissiongratuity',
	giftcard = 'giftcards',
	inventorycontrol='inventorycontrol',
	querybuilder = 'querybuilder'
}

export enum TransLogType {
	TeeTimeLog = 'TeeTimeLog',
	TeeTimeDetailLog = 'TeeTimeDetailLog',
	DayEndLog = 'DayEndLog',
	RetailCodeCreateLog = 'RetailCodeCreate',
	RetailCodeEditLog = 'RetailCodeEdit',
	RetailCodeDeleteLog = 'RetailCodeDelete',
	RetailTransactionsLog = 'RetailTransactionsLog',
	SystemLog = 'SystemLog',
	InventoryLog = 'Inventory',
	RatesLog = 'RatesLog',
	AuditReport = 'AuditReport',
	AllocationBlockHistory = 'AllocationBlockHistory'
	//StartUpLog = 'StartUpLog' //currently Out of scope.
}

export enum CustomTemplate {
	FormName = 'CustomTemplateForm',
	MarkAsPaid = 'MarkAsPaid',
	BagTags = 'BagTags',
	RentalUtilization = 'RentalUtilization'
}

export interface MultiDropdownInputs {
	className: string;
	errorMessage?: string;
	form: UntypedFormGroup;
	formControlName: string;
	placeHolder: string;
	searchPlaceHolder?: string;
	selectOptions: DropdownOptions[];
	showRequired: boolean;
	defaultAllSelected?: boolean;
	displayAll?: boolean;
	disabled?: boolean;
	maxSelectionLimit?: number;
	selectionLimitExceededErrorMessage?: string;
	dropdownWithSearch?: boolean;
}
export enum JasperReportUrlPath {
	CaddyTypeAssignmentUrlPath = '/public/Golf/TeeTime/CaddyTypeAssignment/CaddyTypeAssignment',
	CancellationTeeTimesUrlPath = '/public/Golf/TeeTime/CancellationTeeTime/CancellationTeeTime',
	NoShowTeeTimeUrlPath = '/public/Golf/TeeTime/NoShowTeeTime/NoShowTeeTime',
	BagTagsUrlPath = '/public/Golf/TeeTime/BagTags/BagTags',
	WaitListUrlPath = '/public/Golf/TeeTime/WaitList/WaitList',
	InstructorScheduleUrlPath = '/public/Golf/Instructor/InstructorSchedule/InstructorSchedule'
  }
  export interface ARTransactionsFilter {
	dropDownIds: number[];
	startDate: string;
	endDate: string;
	selectOptions: number;
	batchStatus: number;
	isBatchRange: boolean;
	fromBatchRange: number;
	toBatchRange: number
  
  }
  
  export interface ARDeletedInvoiceFilter {
	fromDate: string,
	toDate: string
  }
