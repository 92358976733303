import { RetailmanagementCommunication } from '../../communication/services/retailmanagement.service';
import { UserSubPropertyAccess } from '../../models/tenant.models';
import { Injectable } from '@angular/core';
import { GolfUtilities } from '../../utilities/golf-utilities';

@Injectable({ providedIn: 'root' })
export class UserOutletAccessDataService {
    constructor(private _retailManagement: RetailmanagementCommunication,private _utils:GolfUtilities) {

    }
   /**
     * @returns {Promise<UserSubPropertyAccess[]>}
     * @memberof UserOutletAccessDataService
     */
    public async GetOutletsAccessByPropertyId(): Promise<UserSubPropertyAccess[]> {
        return await this._retailManagement.getPromise<UserSubPropertyAccess[]>({
            route: GolfApiRoute.GetOutletsAccessByPropertyId,
        });       
    }
    
   /**
     * @param {number} userId
     * @returns {Promise<UserSubPropertyAccess[]>}
     * @memberof UserOutletAccessDataService
     */
    public async GetOutletsAccessByUser(userId: number): Promise<UserSubPropertyAccess[]> {
        return await this._retailManagement.getPromise<UserSubPropertyAccess[]>({
            route: GolfApiRoute.GetSubPropertyAccessByUser,
            uriParams: { id: userId }
        });       
    }

    /**
     * @param {UserOutletAccess} userRetailConfiguration
     * @returns {Promise<boolean>}
     * @memberof UserOutletAccessDataService
     */
    public async CreateUserOutletAccess(userOutlets: UserSubPropertyAccess[]): Promise<boolean> {
        return await this._retailManagement.postPromise<boolean>({
            route: GolfApiRoute.CreateUserOutletAccess,
            body: userOutlets
        });        
    }

    /**
     * @param {UserOutletAccess} userRetailConfiguration
     * @returns {Promise<boolean>}
     * @memberof UserOutletAccessDataService
     */
    public async UpdateUserOutletAccess(userOutlets: UserSubPropertyAccess[]): Promise<boolean> {
        return await this._retailManagement.putPromise<boolean>({
            route: GolfApiRoute.UpdateUserOutletAccess,
            body: userOutlets
        });        
    }
}