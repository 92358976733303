
<div class="BGoverlay" (click)="closeMoreWidow()"  *ngIf="showMoreFilterSection && (assingSectionfrom==frompage)"></div>

<section class="moreSection" *ngIf="showMoreFilterSection && (assingSectionfrom==frompage) " id="MoreSecID{{sectionfrom}}" container="body"  >
  <!-- Search Filter Section -->
  <div class="MS-srchHeader clearfix  d-flex">
    <div class="align-self-center search-content mr-3">
      <mat-form-field class="spa-searchbox searchpt custSearchbox" [floatLabel]="floatLabelNever">
          <input attr.automationId='Txt_{{automationId}}_searchBy' matInput type="text" [(ngModel)]="searchText" class="pr-4 pl-1" placeholder="{{captions.common.SearchBy}} {{categoryType}}" (keyup)="SearchCategories($event,'search')">
      <i attr.automationId='Srch_{{automationId}}_searchCategories' aria-hidden="true" [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'" (click)="searchText='';SearchCategories($event,'search')"></i>
        </mat-form-field>
    </div>
    <div class="filterAlpbts position-relative">
      <swiper [config]="config">
        <span class="hiddenOverFlow"><button attr.automationId='Btn_{{automationId}}_all' mat-button class="LW12 blckClr" title="{{this.captions.common.all}}" [ngClass]="{'body-bgcolor whitecolor': selectedAlphabets.indexOf(captions.common.all) != -1}" (click)="SearchCategories('','click', 'all')"> {{captions.common.all}}</button></span>
        <span class="hiddenOverFlow" *ngFor="let alphabet of alphabets;let i = index">
          <button attr.automationId='Btn_{{automationId}}_alphabet' mat-button class="LW12 blckClr"   (click)="SearchCategories($event,'click', alphabet)" [ngClass]="{'diableBtn': (this.moreDataContentFirstCharArr.indexOf(alphabet) == -1), 'body-bgcolor whitecolor': selectedAlphabets.indexOf(alphabet) != -1 }" >{{alphabet}}</button>
        </span>
      </swiper>
    </div>
    <div class="">
      <i  attr.automationId='Icn_{{automationId}}_closeMoreWindow' aria-hidden="true" class="icon-Cancel float-right cancel-btn blckClr IC14" (click)="closeMoreWidow()" ></i>
    </div>
  </div>
  <!-- Content To be Displayed in More -->
  <div class="MS-moreCtnt mt15" id="MS_filterContent">
      <ng-scrollbar [trackX]="false" [autoHide]="false">
          <div *ngFor="let MLA of moreDataContent;let i=index;" class="d-inline-block">
               <button attr.automationId='Btn_{{automationId}}_description' mat-button class="d-inline-block spa-filter-button body-bordercolor clickbtntxtcss mat-button" [ngClass]="(this.selectedMoreDataContent.indexOf(MLA['id']) !=-1) ? 'button--primary' : 'button--secondary'" title="{{MLA['description']?MLA['description'] : MLA['name']}}" (click)="toggleClickbtn(MLA['id'])" >{{MLA['description']?MLA['description'] : MLA['name']}}</button>
            </div>
      </ng-scrollbar>
  </div>
  <!-- OK Cancel Buttons -->
  <div class="MS-button">
    <button attr.automationId='Btn_{{automationId}}_save' mat-button class=" button--primary-save" (click)="senddataOut()">{{captions.common.Apply}}</button>
    <button attr.automationId='Btn_{{automationId}}_cancel' mat-button class=" spa-button-cancel"  (click)="closeMoreWidow()">{{captions.common.CANCEL}}</button>
  </div>
</section>
