
<section class="redeem-wrapper golf-container--padding"  id="RedeemModal">
    <section [formGroup]="RedeemForm" class="d-flex">
        <div class="w-33">
            <mat-form-field class="w-100">
                <input matInput type="text" formControlName="paymentComment"
                    [placeholder]="captions.teetime.lbl_payment_Comment" maxlength="30">
            </mat-form-field>
        </div>
        <div class="zero-member">         
            <label class="golf-form--label zero-label">{{captions.common.zero_member_label}}</label>
            <ui-switch (valueChange)="zeroMemberChange($event)" [disabled]="disableSwitch"  [checkedLabel]="captions.common.Yes" [uncheckedLabel]="captions.common.No">
            </ui-switch>
          </div>
    </section>   
    <section class="tableContainer">
        <app-cdkvirtual [headerOptions]="headerOptions" [options]="tableOptions" [tableContent]="tableContent" [childTemplate]="childTemplate"
            [searchOptions]="searchText" (EmittedData)='tableAction($event)'>
        </app-cdkvirtual>
    </section>     
</section>

<ng-template #childTemplate let-element="element" let-key="key">
    <div class="">
        <select class="table-input" [(ngModel)]='element[key]' (change)='emitDropdownData($event, element, key)'
        [disabled]='element["memberCardNumber"] !== ""'>
            <option
              *ngFor='let option of dropOptions; trackBy:trackByFn'
              [value]='option.id'>
              {{option.value}}</option>
          </select>
          <span *ngIf="element['memberCardNumber'] === ''" class="memberCard">*</span>
    </div>  
</ng-template>
<!-- <ng-template #uploadProgress> 
    <app-progress-bar [progress]="progress" [uploadMsg]="captions.lbl_uploading" class="ag_w--100 ag_p--2">
    </app-progress-bar>
</ng-template> -->