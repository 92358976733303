<section class="eforms-wrapper h-100 newVersionWrapper">
    <app-dialog-header [attr.automationId]="'Icn_DmEformsSpa_close'" [title]="captions.hdr_dm_eforms" (closeDialog)="onCancel($event)"></app-dialog-header>
        <div class="eforms ag_p--4">
            <div>
                    <div class="dm-forms-right-sect ag-p-4">
                        <div class="formHeader">
                            <app-simple-search [attr.automationId]="'Srch_DmEformsSpa_mail'" [placeholder]="captions.lbl_search_eforms_templates" [value]="searchText" (searchChange)="searchValueChange($event)">
                            </app-simple-search>
                         </div>
                        <div class="ag_pt--6 dm-checkboxContent" [cdkDropListData]="filteredItems"  cdkDropList (cdkDropListDropped)="onDrop($event)">
                            <div class="condo-unit-item ag_pb--3" *ngFor="let item of filteredItems; let i = index" cdkDrag [cdkDragData]="filteredItems">
                                <mat-checkbox attr.automationId='Chk_DmEformsSpa_mail{{i}}' (click)="$event.stopPropagation()" [disabled]="!item.checked && selectedtemplate?.max === count" (change)="toggle(item, $event.checked)" [checked]="item.checked">
                                    {{ item.code }}
                                </mat-checkbox>
                            </div>
                        </div>                        
                    </div>
            </div>
        </div>
        <div class="ag_footer--actions">
            <app-button [attr.automationId]="'Btn_DmEformsSpa_save'" [buttontype]="saveButton" (valueChange)='onSave($event)'></app-button>
            <app-button [attr.automationId]="'Btn_DmEformsSpa_cancel'" [buttontype]="cancelButton" (valueChange)='onCancel($event)'></app-button>
        </div>
</section>

