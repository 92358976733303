<div class="class-lang-sect-wrapp">
    <div class="language-selector">
        <div class="ag_display--flex align-items-end">
            <mat-form-field class="ag_w--30" class="ag_mr--4 ag_mt--4" [floatLabel]="floatLabel">
                <mat-select   [placeholder]="crudemailtemplateCaptions.lbl_languages"  [(ngModel)]="languageSelectorval"
                    (selectionChange)='onLangchange($event)'
                     [ngModelOptions]="{standalone: true}">
                     <ng-container *ngFor="let lang of languages" >
                        <mat-option [disabled]="lang.isDisabled" 
                        [value]="lang.id">
                        {{lang.viewValue}}
                    </mat-option>
                     </ng-container>
                   
                </mat-select>
            </mat-form-field>

            <app-button [buttontype]='addlanguageButton' (valueChange)='addLanguage(tabGroup)'></app-button>
        </div>
        
    </div> 
</div>
<section class="custom-editor-wrapper" [formGroup]="form"> 
    <div class="ag_display--flex editorsec">
       
       <div class="ag_w--70 ">
         <mat-tab-group  #tabGroup (selectedTabChange)="tabclicked($event)" >
             <mat-tab [disabled]="form.get('editorHtml').dirty" formArrayName="editorHtml"
             *ngFor="let obj of form.get('editorHtml')['controls']; let i = index; let first = first;">
              <ng-container [formGroupName]="i">
                 <ng-template mat-tab-label>{{obj.value.tabTitle}}</ng-template>
                 
                 <div class="ag_display--flex-wrap align-items-center ag_p--4">
                    <a class="ag_ml--auto ag_link ag_mr--2" (click)="showHtmlContent(obj)">{{this.crudemailtemplateCaptions.preview}}</a>
                    <a *ngIf="isDeleteButtonEnable" class="ag_link ag_ml--4 ag_mr--4" (click)="removeLang(i,obj)">{{crudemailtemplateCaptions.lbl_delete|uppercase}}</a>
                    <i class="icon-reset ag_cursor--pointer" (click)="resetForm(obj)" [matTooltip]="'reset'" [ngClass]="{'ag_link--disabled': !form.get('editorHtml').dirty}"></i>
                 </div>    
                 
                 <div class="content-wrapper">
                     <div class="over-lang-wrapper">
                         <div class="editorsec">
                             <div class="leftSection h-100 d-inline-block">
                                 <ckeditor class="crudTempEditor"  #ComposeEmailTemp [editor]="Editor" [config]="Config"
                                     (ready)="onReady($event)" formControlName="htmlContents" (focus)="onChange(ComposeEmailTemp)">
                                 </ckeditor>
                             </div>
                           
                         </div>
                         
                     </div>
                 </div> 
              </ng-container>       
             </mat-tab>
         </mat-tab-group>
       </div>
       <div class="ag_w--30 ">
         <div class="rightSection ag_w--100 h-100 d-inline-block">
             <ng-container *ngIf="!isGroupingenabled">
                <div class="ag_display--flex align-items-center">
                  <h4 class="LWB14 mb-0 ag_display--inblock">{{this.crudemailtemplateCaptions.tags}}</h4>
                  <a class="ag_link float-right" (click)="addLanguage()">{{crudemailtemplateCaptions.addLanguage}}</a>
                </div>  
                 <mat-form-field  class="spa-searchbox w-100" [floatLabel]="floatLabel" id="searchInput">
                     <input matInput autocomplete="off" [(ngModel)]="searchText"
                         [ngModelOptions]="{standalone: true}" type="text"
                         [placeholder]="crudemailtemplateCaptions.srchByTag" (input)="enteredSrchText()">
                     <i aria-hidden="true" [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'" class="cursor"
                         (click)="searchText='';enteredSrchText()"></i>
                 </mat-form-field>
                 <hr class="mt-0" />
                 <div class="keywordSec">
                     <button mat-raised-button *ngFor="let item of filteredkeywords" [matTooltip]="item.name"
                         class="crudDetails-btn body-bgcolor whitecolor textellipsis"
                         (click)="elementClick(item.name)">
                         {{item.description}}
                     </button>
                 </div>
             </ng-container>
             <ng-container *ngIf="isGroupingenabled">
                 <h4 class="LWB14 ag_display--inblock mb-1">{{this.crudemailtemplateCaptions.tags}}</h4>
                 <mat-form-field class="spa-searchbox w-100" [floatLabel]="floatLabelNever" id="searchInput">
                     <input matInput autocomplete="off" [(ngModel)]="searchText"
                         [ngModelOptions]="{standalone: true}" type="text"
                         [placeholder]="crudemailtemplateCaptions.srchByTag" (input)="enteredgrpSrchText()">
                     <i aria-hidden="true" [ngClass]="!searchText ? 'icon-Search' : 'icon-Cancel'" class="cursor"
                         (click)="searchText='';enteredgrpSrchText()"></i>
                 </mat-form-field>
                 <hr class="mt-0" />
                 <div class="keywordSec grouping-overflow">
                     <ng-container *ngFor="let item of filteredkeywords|sortPipe : ordertype : groupBy;;let i = index">
                         <label  class="w-100 d-block c-grp-label" (click)="openClose($event,item)" *ngIf="(i==0 || item['groupId']!==filteredkeywords[i-1]['groupId'])">{{item.groupNumber ? item.groupNumber : crudemailtemplateCaptions.General}} <i *ngIf="!item.isExpanded" aria-hidden="true" class="template-icon-plus">+</i> <i *ngIf="item.isExpanded"  class="template-icon-minus" aria-hidden="true">-</i> </label>
                         <ng-container *ngIf="item.isExpanded">
                             <button mat-raised-button [matTooltip]="item.name"
                                 class="crudDetails-btn body-bgcolor whitecolor textellipsis"
                                 (click)="elementClick(item.name)">
                                 {{item.description}}
                             </button>
                         </ng-container>
     
                     </ng-container>
     
                 </div>
             </ng-container>
         </div>
       </div>
    </div>
 </section>
 