<section class="setNotificationConfiguration newVersionWrapper notification-configuration-wrapper">
    <h2 class="LWB16" [attr.LiteralID]="'NOTIFICATIONCONFIGURATION'" >{{notifycaptions.NOTIFICATIONCONFIGURATION}}</h2>
    <form [formGroup]="notificationFormGroup" class="setNotification-form-section">
        <div *ngIf="!(notificationFormGroup.controls.notificationEvents.value.length > 0)">
            <a (click)="AddEvents(-1)" class="dtable">
                <span class="dcell themecolor icon-Plus  pr-3"></span>
                <span class="dcell themecolor" [attr.LiteralID]="'AddNewEventConfiguration'" >{{notifycaptions.AddNewEventConfiguration}}</span>
            </a>
        </div>
        <div *ngIf="(notificationFormGroup.controls.notificationEvents.value.length > 0)"
            formArrayName="notificationEvents">
            <ng-container
                *ngFor="let obj of notificationFormGroup.controls['notificationEvents']['controls'];let f=first;let l=last;let i=index">
                <div class="configurationsec mb-4 dtable">
                    <div class="greyDetSec box-style-notification-config p-3 dcell " [ngClass]="{'isExpanded':!(obj.controls['ISdbdata'].value)}" >
                        <div class="position-relative CollapsedSec">
                            <span class="icon-Down-Arrow expcollPosition"
                            attr.automationId='Icn_notificationConfiguration_DownArrow{{i}}' (click)="expColl($event,true, i)"></span>
                            <div><label class="LW18 mb-4">

                                {{Notify_eventsCollection[i].SelectedListObj.description}}
                            </label></div>
                            <div>
                                <span class="d-inline-block mr-5">
                                    <label class="LW12 mb-2">{{notifycaptions.Guest}}</label>
                                    <div class="LW16">
                                        <label  *ngIf="notificationFormGroup.controls['notificationEvents']['controls'][i].controls['guestEmail'].value">{{notifycaptions.Email}}</label>
                                        <label class="mr-1" *ngIf="obj.controls['guestEmail'].value && obj.controls['guestSms'].value">, </label>
                                        <label class="mr-1" *ngIf="notificationFormGroup.controls['notificationEvents']['controls'][i].controls['guestSms'].value">{{notifycaptions.SMS}}</label>
                                    &nbsp;</div>
                                </span>
                                <span class="d-inline-block mr-5" *ngIf="(obj.controls['events'].value!=GolfGuestDataRequestEventId && obj.controls['events'].value!=SPAGuestDataRequestEventId && obj.controls['events'].value!= GeneralEventId)">
                                    <label class="LW12 mb-2">{{notifycaptions.User}}</label>
                                    <div class="LW16">
                                        <label *ngIf="notificationFormGroup.controls['notificationEvents']['controls'][i].controls['userEmail'].value">{{notifycaptions.Email}}</label>
                                        <label class="mr-1" *ngIf="obj.controls['userEmail'].value && obj.controls['userSms'].value">, </label>
                                        <label class="mr-1" *ngIf="notificationFormGroup.controls['notificationEvents']['controls'][i].controls['userSms'].value">{{notifycaptions.SMS}}</label>
                                   &nbsp;</div>
                                </span>

                            </div>
                        </div>

                        <div [formGroupName]="i" class="position-relative ExpandedSec">
                            <span class="icon-Up-Arrow  expcollPosition" (click)="expColl($event,false)"></span>
                            <div class="d-flex">
                                <mat-form-field [floatLabel]="floatLabel" id="ag-automation-select-events" class="NC-w50 d-inline-block pr-3">
                                    <mat-select attr.automationId='Dd_notificationConfig_events{{i}}' [placeholder]="notifycaptions.Events" name="events"
                                        formControlName="events" class="LW14"
                                        (selectionChange)="actionChanged($event,'events',i)" [disabled]="obj.controls['ISdbdata'].value">
                                        <mat-option *ngFor="let type of Notify_eventsCollection[i].filteredList" [value]="type.id">
                                            {{type.description}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <span class="NC-w50 d-inline-block pr-3" [ngClass]="{'visiblityhidden':!(Notify_eventsCollection[i].SelectedListObj.reminder)}">

                                    <mat-form-field [floatLabel]="floatLabel" class="NC-w30 pr-1">
                                        <input attr.automationId ='Txt_notificationConfig_missingDays{{i}}'  type="number"  min="0" max="999" numMaxlength maxLength="2" inputtype="nonnegative" [required]="Notify_eventsCollection[i].SelectedListObj.reminder"  matInput class="LW14" [placeholder]="notifycaptions.Days" name="days"
                                            formControlName="days">
                                            <mat-error class = "errorHandling" [matTooltip] ="notifycaptions.MissingDays"  *ngIf="obj.controls['days']?.hasError('required')">
                                                {{notifycaptions.MissingDays}}
                                        </mat-error>
                                        <mat-error class = "errorHandling" [matTooltip] ="notifycaptions.InvalidDays" *ngIf="obj.controls['days']?.hasError('max')">
                                            {{notifycaptions.InvalidDays}}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field [floatLabel]="floatLabel" class="NC-w30 pr-1">
                                        <input attr.automationId = 'Txt_notificationConfig_missingHours{{i}}'  type="number"  min="0" max="23" numMaxlength maxLength="1" inputtype="nonnegative" [required]="Notify_eventsCollection[i].SelectedListObj.reminder"  matInput class="LW14" [placeholder]="notifycaptions.hrs" name="hrs"
                                            formControlName="hrs">
                                            <mat-error class = "errorHandling" [matTooltip] ="notifycaptions.MissingHours" *ngIf="obj.controls['hrs']?.hasError('required')" [attr.LiteralID]="'MissingHours'" >
                                                {{notifycaptions.MissingHours}}
                                        </mat-error>
                                        <mat-error class = "errorHandling" [matTooltip] ="notifycaptions.InvalidHours" *ngIf="obj.controls['hrs']?.hasError('max')" [attr.LiteralID]="'InvalidHours'" >
                                            {{notifycaptions.InvalidHours}}
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field [floatLabel]="floatLabel" class="NC-w30 pr-1">
                                        <input attr.automationId = 'Txt_notificationConfig_missingMinutes{{i}}'  type="number"  min="0" max="59" numMaxlength maxLength="1" inputtype="nonnegative" [required]="Notify_eventsCollection[i].SelectedListObj.reminder"  matInput class="LW14" [placeholder]="notifycaptions.min" name="min"
                                            formControlName="min">
                                            <mat-error class = "errorHandling" [matTooltip] ="notifycaptions.MissingMinutes" *ngIf="obj.controls['min']?.hasError('required')" [attr.LiteralID]="'MissingMinutes'" >
                                                {{notifycaptions.MissingMinutes}}
                                        </mat-error>
                                        <mat-error class = "errorHandling" [matTooltip] ="notifycaptions.InvalidMinutes" *ngIf="obj.controls['min']?.hasError('max')" [attr.LiteralID]="'InvalidMinutes'" >
                                            {{notifycaptions.InvalidMinutes}}
                                        </mat-error>
                                    </mat-form-field>
                                </span>
                            </div>
                            <div class="mb-1 w-50" *ngIf="isAuthorizeEnabled && (obj.controls['events'].value==1 || obj.controls['events'].value==49 ||
                            obj.controls['events'].value==9 || obj.controls['events'].value==15 || obj.controls['events'].value==5 || obj.controls['events'].value==91 ||
                            obj.controls['events'].value==93 || obj.controls['events'].value==11 || obj.controls['events'].value==10 || obj.controls['events'].value==3 ||
                            obj.controls['events'].value==6 || obj.controls['events'].value==7 || obj.controls['events'].value==47 || obj.controls['events'].value==70 || obj.controls['events'].value==102)">
                                <label class="LW14 NC-w40" [attr.LiteralID]="'authorize'" >{{notifycaptions.authorizeIntegration}}</label>
                                <app-common-toggle-switch formControlName="authorize"
                                    (changeToggleEvent)="togglechangeAuthorize($event,obj.controls['events'].value,'authorize')" class="toggle-switch">
                                </app-common-toggle-switch>
                            </div>
                            <div class="mb-3 w-50" *ngIf="!this.hideIntakeForm && this.propertyIntakeForm">
                                <label class="LW14 NC-w40" [attr.LiteralID]="'IntakeForm'" >{{notifycaptions.IntakeForm}}</label>
                                <app-common-toggle-switch attr.automationId = 'Tog_notificationConfig_intakeForm{{i}}' formControlName="intakeForm"
                                    (changeToggleEvent)="togglechange($event,'intakeForm')" class="toggle-switch">
                                </app-common-toggle-switch>
                            </div>
                            <div class="mb-1 w-50" *ngIf="dmConfig?.dmEformsConfig?.enableEforms && (obj.controls['events'].value==1 || obj.controls['events'].value==49 ||
                            obj.controls['events'].value==9 || obj.controls['events'].value==15 || obj.controls['events'].value==70 || obj.controls['events'].value==72 ||
                            obj.controls['events'].value==73 || obj.controls['events'].value==11 || obj.controls['events'].value==10 || obj.controls['events'].value==3 ||
                            obj.controls['events'].value==6 || obj.controls['events'].value==7 || obj.controls['events'].value==47 || obj.controls['events'].value==102)">
                                <label class="LW14 NC-w40" [attr.LiteralID]="'enableEForm'" >{{notifycaptions.enableEForms}}</label>
                                <app-common-toggle-switch attr.automationId = 'Tog_notificationConfig_enableEform{{i}}' formControlName="enableEForm"
                                    (changeToggleEvent)="togglechangeeform($event,obj.controls['events'].value,'enableEForm')" class="toggle-switch">
                                </app-common-toggle-switch>
                            </div>

                            

                           <div class="d-flex" *ngIf="notificationFormGroup.controls['notificationEvents']['controls'][i].controls['enableEForm'].value">
                                <div class="mb-3 w-50" *ngIf="dmConfig?.dmEformsConfig?.enableEforms && (obj.controls['events'].value==1 || obj.controls['events'].value==49 ||
                                obj.controls['events'].value==9 || obj.controls['events'].value==15 || obj.controls['events'].value==70 || obj.controls['events'].value==6 ||
                                obj.controls['events'].value==7 || obj.controls['events'].value==3 || obj.controls['events'].value==72 ||obj.controls['events'].value==73 || obj.controls['events'].value==11 || obj.controls['events'].value==10
                                || obj.controls['events'].value==47 || obj.controls['events'].value==102)">
                                    <a class="theme--textColor cursor"
                                        href="javascript:void(0)" (click)="openDMEforms(obj.controls['events'].value)">{{notifycaptions.eFormTemplatesServices}}</a>
                                </div>
                            </div>

                            <div class="mb-3 w-50" *ngIf="!this.hideVipCode && (notificationFormGroup.controls['notificationEvents']['controls'][i].controls['events'].value==41)">
                            <mat-form-field [floatLabel]="floatLabel" id="ag-automation-select-vipCodeId" class="NC-w100">
                                <mat-select attr.automationId = 'Dd_notificationConfig_Vipcode{{i}}' [placeholder]="notifycaptions.VIPCode" name="VIPCode"  [required]="(notificationFormGroup.controls['notificationEvents']['controls'][i].controls['events'].value==41)"
                                    formControlName="vipCodeId" class="LW14">
                                    <mat-option *ngFor="let type of vipcodes" [value]="type.id">
                                        {{type.code}}
                                    </mat-option>
                                </mat-select>
                                <mat-error [attr.LiteralID]="'MissingVIPCode'" >
                                    {{notifycaptions.MissingVIPCode}}
                                </mat-error>
                            </mat-form-field>
                            </div>
                            <div *ngIf="isPms" class="NC-w100 d-inline-block pr-3">
                                <mat-form-field [floatLabel]="floatLabel" id="ag-automation-select-guestType"  class="NC-w50 pr-3">
                                    <mat-select attr.automationId = 'Dd_notificationConfig_guestType{{i}}'  multiple [placeholder]="notifycaptions.guestType" name="guestType"  [required]="isPms"
                                        formControlName="guestType" class="LW14">
                                        <mat-checkbox [checked]="obj.value.guestType.length == guestTypes.length" (change)="selectAll($event,obj,i,'guestType')"  class="mat-option all-multiselect">
                                            {{captions.lbl_all}}
                                           </mat-checkbox>
                                        <mat-option *ngFor="let type of guestTypes" [value]="type.id">
                                            {{type.code}}
                                        </mat-option>
                                    </mat-select>

                                </mat-form-field>
                                <mat-form-field [floatLabel]="floatLabel" id="ag-automation-select-building" class="NC-w50">
                                    <mat-select attr.automationId ='Dd_notificationConfig_building{{i}}' multiple [placeholder]="notifycaptions.building" name="building"  [required]="isPms"
                                        formControlName="building" class="LW14">
                                        <mat-checkbox [checked]="obj.value.building.length == buildings.length" (change)="selectAll($event,obj,i,'building')"  class="mat-option all-multiselect">
                                            {{captions.lbl_all}}
                                        </mat-checkbox>
                                        <mat-option *ngFor="let type of buildings" [value]="type.id">
                                            {{type.code}}
                                        </mat-option>
                                    </mat-select>

                                </mat-form-field>
                            </div>

                            <div class="NC-w50 d-inline-block pr-3">
                                <div>
                                    <div class="LWB14 mb-3" [attr.LiteralID]="'Guest'"  [attr.LiteralID]="'Guest'" >{{notifycaptions.Guest}}</div>
                                    <div class="mb-3">
                                        <label class="LW14 NC-w40">{{notifycaptions.Email}}</label>
                                        <app-common-toggle-switch formControlName="guestEmail" attr.automationId = 'Tog_notificationConfig_guestEmail{{i}}'
                                            (changeToggleEvent)="togglechange($event,'guestEmail')" class="toggle-switch">
                                        </app-common-toggle-switch>
                                    </div>
                                    <div class="mb-3" *ngIf="((obj.controls['events'].value!=GolfGuestDataRequestEventId && obj.controls['events'].value!=SPAGuestDataRequestEventId && obj.controls['events'].value!= GeneralEventId)) && productId != sncId">
                                        <label class="LW14 NC-w40">{{notifycaptions.SMS}}</label>
                                        <app-common-toggle-switch formControlName="guestSms" attr.automationId = 'Tog_notificationConfig_guestSms{{i}}'
                                            (changeToggleEvent)="togglechange($event,'guestSms')" class="toggle-switch">
                                        </app-common-toggle-switch>
                                    </div>
                                </div>
                                <mat-form-field [floatLabel]="floatLabel" class="NC-w100" id="ag-automation-select-guestEmailDistLst">
                                    <mat-select  [placeholder]="notifycaptions.Guest_Em_distLst" name="guestEmailDistLst" attr.automationId= 'Dd_notificationConfig_guestEmail{{i}}'
                                        formControlName="guestEmailDistLst" class="LW14" [required]="(obj.controls['guestEmail'].value)"
                                        (selectionChange)="actionChanged($event,'guestEmailDistLst',i)" [disabled]="!(notificationFormGroup.controls['notificationEvents']['controls'][i].controls['guestEmail'].value)">
                                        <mat-option *ngFor="let type of G_emailDistLst" [value]="type.id">
                                            {{type.distributionName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error [attr.LiteralID]="'MissingGuestEmailDistribution'" >
                                        {{notifycaptions.MissingGuestEmailDistribution}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field *ngIf="((obj.controls['events'].value!=GolfGuestDataRequestEventId && obj.controls['events'].value!=SPAGuestDataRequestEventId && obj.controls['events'].value!= GeneralEventId)) && productId != sncId" [floatLabel]="floatLabel" class="NC-w100" id="ag-automation-select-guestSMSDistLst">
                                    <mat-select  [placeholder]="notifycaptions.Guest_Sms_distLst" name="guestSMSDistLst" attr.automationId = 'Dd_notificationConfig_guestSms{{i}}'
                                        formControlName="guestSMSDistLst" class="LW14" [required]="(obj.controls['guestSms'].value)"
                                        (selectionChange)="actionChanged($event,'guestSMSDistLst',i)"  [disabled]="!(notificationFormGroup.controls['notificationEvents']['controls'][i].controls['guestSms'].value)">
                                        <mat-option *ngFor="let type of G_smsDistLst" [value]="type.id">
                                            {{type.distributionName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error [attr.LiteralID]="'MissingGuestSMSDistribution'" >
                                        {{notifycaptions.MissingGuestSMSDistribution}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field *ngIf="(obj.controls['events'].value!= GeneralEventId)" [floatLabel]="floatLabel" class="NC-w100" id="ag-automation-select-guestEmailTemp">
                                    <mat-select [placeholder]="notifycaptions.Guest_EmailTemplate" name="guestEmailTemp" attr.automationId = 'Dd_notificationConfig_guestEmailTemp{{i}}'
                                        formControlName="guestEmailTemp" class="LW14" [required]="(obj.controls['guestEmail'].value)"
                                        (selectionChange)="actionChanged($event,'guestEmailTemp',i)" [disabled]="!(notificationFormGroup.controls['notificationEvents']['controls'][i].controls['guestEmail'].value)">
                                        <mat-option *ngFor="let type of G_emailTemp_Collection[i]" [value]="type.id">
                                            {{type.templateName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error [attr.LiteralID]="'MissingGuestEmailTemplate'" >
                                        {{notifycaptions.MissingGuestEmailTemplate}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field *ngIf="((obj.controls['events'].value!=GolfGuestDataRequestEventId && obj.controls['events'].value!=SPAGuestDataRequestEventId && obj.controls['events'].value!= GeneralEventId)) && productId != sncId" [floatLabel]="floatLabel" class="NC-w100" id="ag-automation-select-guestSmsTemp">
                                    <mat-select [placeholder]="notifycaptions.Guest_SMS_Template" name="guestSmsTemp" attr.automationId = 'Dd_notificationConfig_guestSmsTemp{{i}}'
                                        formControlName="guestSmsTemp" class="LW14" [required]="(obj.controls['guestSms'].value)"
                                        (selectionChange)="actionChanged($event,'guestSmsTemp',i)" [disabled]="!(notificationFormGroup.controls['notificationEvents']['controls'][i].controls['guestSms'].value)">
                                        <mat-option *ngFor="let type of G_smsTemp_Collection[i]" [value]="type.id">
                                            {{type.templateName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error [attr.LiteralID]="'MissingGuestSMSTemplate'" >
                                        {{notifycaptions.MissingGuestSMSTemplate}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field  [floatLabel]="floatLabel" class="NC-w100" *ngIf="guestCategory.length > 0 && obj.controls['events'].value!=GolfGuestDataRequestEventId && obj.controls['events'].value!=SPAGuestDataRequestEventId && obj.controls['events'].value!= GeneralEventId"  id="ag-automation-select-guestEmailCategory">
                                    <mat-select [placeholder]="notifycaptions.Guest_EmailCategory" name="guestEmailCategory" attr.automationId ='Dd_notificationConfig_guestEmailCategory{{i}}'
                                        formControlName="guestEmailCategory" class="LW14"
                                        [matTooltip]="getNotificationName(notificationFormGroup.controls['notificationEvents']['controls'][i].controls['guestEmailCategory'].value)"
                                        multiple (selectionChange)="actionChanged($event,'guestEmailCategory',i)" [disabled]="!(notificationFormGroup.controls['notificationEvents']['controls'][i].controls['guestEmail'].value)">
                                        <mat-option *ngFor="let type of guestCategory" [value]="type.categoryId">
                                            {{type.categoryName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error [attr.LiteralID]="'MissingGuestEmailCategory'" >
                                        {{notifycaptions.MissingGuestEmailCategory}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field  [floatLabel]="floatLabel" class="NC-w100" *ngIf="guestCategory.length > 0 && obj.controls['events'].value!=GolfGuestDataRequestEventId && obj.controls['events'].value!=SPAGuestDataRequestEventId && obj.controls['events'].value!= GeneralEventId" id="ag-automation-select-guestSmsCategory">
                                    <mat-select [placeholder]="notifycaptions.Guest_SMS_Category" name="guestSmsCategory"
                                        formControlName="guestSmsCategory" class="LW14"
                                        [matTooltip]="getNotificationName(notificationFormGroup.controls['notificationEvents']['controls'][i].controls['guestSmsCategory'].value)"
                                        multiple (selectionChange)="actionChanged($event,'guestSmsCategory',i)" attr.automationId= 'Dd_notificationConfig_guestSmsCategory{{i}}'
                                        [disabled]="!(notificationFormGroup.controls['notificationEvents']['controls'][i].controls['guestSms'].value)">
                                        <mat-option *ngFor="let type of guestCategory" [value]="type.categoryId">
                                            {{type.categoryName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error [attr.LiteralID]="'MissingGuestSMSCategory'" >
                                        {{notifycaptions.MissingGuestSMSCategory}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="NC-w50  d-inline-block pr-3"  *ngIf="(obj.controls['events'].value!=GolfGuestDataRequestEventId && obj.controls['events'].value!=SPAGuestDataRequestEventId && obj.controls['events'].value!= GeneralEventId)">
                                <div>
                                    <div class="mb-3 LWB14" [attr.LiteralID]="'User'"  [attr.LiteralID]="'User'" >{{notifycaptions.User}}</div>
                                    <div class="mb-3">
                                        <label class="LW14 NC-w40" [attr.LiteralID]="'Email'"  [attr.LiteralID]="'Email'"  [attr.LiteralID]="'Email'"  [attr.LiteralID]="'Email'" >{{notifycaptions.Email}}</label>
                                        <app-common-toggle-switch formControlName="userEmail" attr.automationId= 'Tog_notificationConfig_userEmail{{i}}'
                                            (changeToggleEvent)="togglechange($event,'userEmail')" class="toggle-switch">
                                        </app-common-toggle-switch>
                                    </div>
                                    <div class="mb-3" *ngIf="productId != sncId">
                                        <label class="LW14 NC-w40" [attr.LiteralID]="'SMS'"  [attr.LiteralID]="'SMS'"  [attr.LiteralID]="'SMS'"  [attr.LiteralID]="'SMS'" >{{notifycaptions.SMS}}</label>
                                        <app-common-toggle-switch formControlName="userSms"
                                            (changeToggleEvent)="togglechange($event,'userSms')" class="toggle-switch">
                                        </app-common-toggle-switch>
                                    </div>
                                </div>
                                <mat-form-field [floatLabel]="floatLabel" class="NC-w100" id="ag-automation-select-userEmailDistLst">
                                    <mat-select  [placeholder]="notifycaptions.User_Em_distLst" name="userEmailDistLst" attr.automationId = 'Dd_notificationConfig_userEmailDist{{i}}'
                                        formControlName="userEmailDistLst" class="LW14" [required]="(obj.controls['userEmail'].value)"
                                        (selectionChange)="actionChanged($event,'userEmailDistLst',i)" [disabled]="!(notificationFormGroup.controls['notificationEvents']['controls'][i].controls['userEmail'].value)">
                                        <mat-option *ngFor="let type of U_emailDistLst" [value]="type.id">
                                            {{type.distributionName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error [attr.LiteralID]="'MissingUserEmailDistribution'" >
                                        {{notifycaptions.MissingUserEmailDistribution}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field [floatLabel]="floatLabel" class="NC-w100"  id="ag-automation-select-userSMSDistLst" *ngIf="productId != sncId">
                                    <mat-select  [placeholder]="notifycaptions.User_Sms_distLst" name="userSMSDistLst" attr.automationId = 'Dd_notificationConfig_userSmsDist{{i}}'
                                        formControlName="userSMSDistLst" class="LW14" [required]="(obj.controls['userSms'].value)"
                                        (selectionChange)="actionChanged($event,'userSMSDistLst',i)" [disabled]="!(notificationFormGroup.controls['notificationEvents']['controls'][i].controls['userSms'].value)">
                                        <mat-option *ngFor="let type of U_smsDistLst" [value]="type.id">
                                            {{type.distributionName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error [attr.LiteralID]="'MissingUserSMSDistribution'" >
                                        {{notifycaptions.MissingUserSMSDistribution}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field [floatLabel]="floatLabel" class="NC-w100" id="ag-automation-select-userEmailTemp">
                                    <mat-select [placeholder]="notifycaptions.User_EmailTemplate" name="userEmailTemp" attr.automationId = 'Dd_notificationConfig_userEmailTemp{{i}}'
                                        formControlName="userEmailTemp" class="LW14" [required]="(obj.controls['userEmail'].value)"
                                        (selectionChange)="actionChanged($event,'userEmailTemp',i)" [disabled]="!(notificationFormGroup.controls['notificationEvents']['controls'][i].controls['userEmail'].value)">
                                        <mat-option *ngFor="let type of U_emailTemp_Collection[i]" [value]="type.id">
                                            {{type.templateName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error [attr.LiteralID]="'MissingUserEmailTemplate'" >
                                        {{notifycaptions.MissingUserEmailTemplate}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field [floatLabel]="floatLabel" class="NC-w100" id="ag-automation-select-userSmsTemp" *ngIf="productId != sncId">
                                    <mat-select [placeholder]="notifycaptions.User_SMSTemplate" name="userSmsTemp" attr.automationId = 'Dd_notificationConfig_userSmsTemp{{i}}'
                                        formControlName="userSmsTemp" class="LW14" [required]="(obj.controls['userSms'].value)"
                                        (selectionChange)="actionChanged($event,'userSmsTemp',i)" [disabled]="!(notificationFormGroup.controls['notificationEvents']['controls'][i].controls['userSms'].value)">
                                        <mat-option *ngFor="let type of U_smsTemp_Collection[i]" [value]="type.id">
                                            {{type.templateName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error [attr.LiteralID]="'MissingUserSMSTemplate'" >
                                        {{notifycaptions.MissingUserSMSTemplate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="dcell" *ngIf="productId != sncId">
                        <span class="icon-Minus themecolor" (click)="removeEvent(i)"></span>
                        <span class="icon-Plus themecolor" (click)="AddEvents(i)"
                            *ngIf="isReminder ? l : ( l && i < (Notify_events.length-1))"></span>
                    </div>

                </div>
            </ng-container>
        </div>
    </form>
    <div class="notify-actions">
        <button class="LW14 save" mat-button (click)="onSave($event)" [attr.automationId]="'Btn_notificationConfig_save'"
          [ngClass]= "saveBtnEnabled ? 'spa-primary-default-save': 'spa-primary-disabled'">{{notifycaptions.Save}}</button>
        <a [attr.automationId]="'Lbl_notificationConfig_cancel'" class="LW14 text-default-color" (click)="onCancel($event)"[ngClass]="{'disabled': isDisabled}">{{notifycaptions.Cancel}}</a>
      </div>
</section>
