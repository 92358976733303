
<div [ngSwitch]="buttontype && buttontype.type" class="custom-button clear">
    <button attr.automationId='Btn_{{automationId}}_{{buttontype.label?.split(" ").join("").toLowerCase()}}' class="ag-button ag_button--primary" [ngClass]="buttontype.customclass" #dynamicbutton *ngSwitchCase="'primary'" [disabled]='buttontype.disabledproperty' mat-flat-button color="primary" [appDisableDoubleClick]="throttleTime" (throttleClick)="handleclick(buttontype)">{{ buttontype.label }}</button>
    <button attr.automationId='Btn_{{automationId}}_{{buttontype.label?.split(" ").join("").toLowerCase()}}' class="ag-button ag_button--secondary" [ngClass]="buttontype.customclass" #dynamicbutton  *ngSwitchCase="'secondary'" [disabled]='buttontype.disabledproperty' color="secondary" mat-stroked-button [appDisableDoubleClick]="throttleTime" (throttleClick)="handleclick(buttontype)">{{buttontype.label}}</button>
    <a attr.automationId='Btn_{{automationId}}_{{buttontype.label?.split(" ").join("").toLowerCase()}}' class="ag_button ag_display--inflex"  [ngClass]="buttontype.disabledproperty ? 'stopclickfunc ' + buttontype.customclass :buttontype.customclass " #dynamicbutton *ngSwitchCase="'tertiary'" [disabled]='buttontype.disabledproperty' mat-button  href="javascript:void(0)" [appDisableDoubleClick]="throttleTime" (throttleClick)="handleclick(buttontype)">{{buttontype.label }}</a>

    <button attr.automationId='Btn_{{automationId}}_{{buttontype.label?.split(" ").join("").toLowerCase()}}' class="ag-button ag_button--secondary" [ngClass]="buttontype.customclass" #dynamicbutton  *ngSwitchCase="'multiselect'" [disabled]='buttontype.disabledproperty' mat-stroked-button color="primary">{{buttontype.label}}</button>

    <div class='split-button' *ngSwitchCase="'split'">
        <div #buttonGroup class='buttonGroup'>
            <button attr.automationId='Btn_{{automationId}}_{{buttontype.label?.split(" ").join("").toLowerCase()}}' mat-flat-button class='text' type="button" [ngClass]="buttontype.customclass?buttontype.customclass: 'ag_button--primary'" [disabled]='buttontype.disabledproperty'  #dynamicbutton [appDisableDoubleClick]="throttleTime" (throttleClick)="handleclick(buttontype)">{{ buttontype.label }}</button>
            <button attr.automationId='Btn_{{automationId}}_trigger{{buttontype.label?.split(" ").join("").toLowerCase()}}' mat-flat-button class='trigger' type="button" [ngClass]="buttontype.customclass?buttontype.customclass: 'ag_button--primary'" [disabled]='buttontype.disabledproperty' [matMenuTriggerFor]="menu"><i  aria-hidden="true"  class='icon-down-arrow'>&nbsp;</i></button>
        </div>
        <mat-menu #menu="matMenu" class='ag-split-button-menu'>
            <button attr.automationId='Btn_{{automationId}}_{{action.label?.split(" ").join("").toLowerCase()}}' mat-menu-item class='split-button-list'type="button" *ngFor= 'let action of actions' (click)='actionClicked(action)'  [disabled]='action.disabled' [matTooltip]="action.label"><i  aria-hidden="true"  [class]="action.icon"></i>{{action.label}}</button>  
        </mat-menu>
      </div>

      <button attr.automationId='Btn_{{automationId}}_{{buttontype.label?.split(" ").join("").toLowerCase()}}' class="ag-button ag_button--plain" [ngClass]="buttontype.customclass" #dynamicbutton *ngSwitchCase="'plain'" [disabled]='buttontype.disabledproperty' mat-flat-button color="plain" [appDisableDoubleClick]="throttleTime" (throttleClick)="handleclick($event)">{{ buttontype.label }}</button>
   
</div>




