export const recurringTypeConst = {
    daily: 1,
    weekly: 2,
    monthly: 3,
    yearly: 4
};

export enum monthlyType {
    date = 1,
    day
}

export interface RecurringType {
    description: string;
    disabled?: boolean;
    id: number;
    name: string;
}
