import { ReportCommunication } from 'src/app/common/communication/services/report.service';
import { Component, Input, ViewEncapsulation, AfterViewChecked, OnDestroy, ViewChild, EventEmitter, Output, ElementRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HTMLDomElement, ReportAPIOptions, ReportAPIModel, ReportDownloadFormat, sendEmailRequest, NotifyTemplateType, LettterSetup } from '../preview-report.model';
import * as saveAs from 'file-saver'
import { SubscriptionLike } from 'rxjs';
import { ScrollbarComponent } from 'ngx-scrollbar';
import { AccountingReportRoutes, ReportRoutes } from 'src/app/common/communication/common-route';
import { environment } from 'src/environments/environment';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities, Product } from 'src/app/common/shared/shared/utilities/common-utilities';
import { ButtonType, PreviewReportPrintOptions, PrintType } from 'src/app/common/shared/shared.modal';
// import { ActionType,PrintType } from 'src/app/shared/shared-models';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { AgPreviewReportContentBusiness } from 'src/app/common/components/preview-report/preview-report-content/preview-report-content.business';
import { API } from './preview-report-content.models';
import { MatDialog } from '@angular/material/dialog';
// import { AddEmailComponent } from 'src/app/accounting/receivable/view-invoices/add-email/add-email.component';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { SendEmailComponent } from 'src/app/common/components/send-email/send-email.component';
import { ContactType, MailTypes, PhoneTypes, GuestProfileMailTypes } from "src/app/common/shared/shared/enums/enums";
import { AdditionalGuestInfo } from 'src/app/common/Models/ag-models';
import { JasperReportDetails, ReportDownloadFormatOptions } from 'src/app/common/JaspersoftReport/jasperreport.model';
import { JasperReportBusiness } from 'src/app/common/JaspersoftReport/jasperreport.business';
import { cloneDeep } from 'lodash';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';

export const saveFile = (blobContent: Blob, fileName: string) => {
  const blob = new Blob([blobContent], { type: 'application/octet-stream' });
  //sendEmail(blob);
  saveAs(blob, fileName);
};

@Component({
  selector: 'ag-preview-report-content',
  templateUrl: './preview-report-content.component.html',
  styleUrls: ['./preview-report-content.component.scss'],
  providers: [AgPreviewReportContentBusiness, JasperReportBusiness],
  encapsulation: ViewEncapsulation.None
})
export class AgPreviewReportContentComponent implements AfterViewChecked, OnDestroy {
  captions: any;
  pageNumber: number = 1;
  zoomLevel: number = 1;
  reportDomStringData: string;
  reportQuerySubscription: SubscriptionLike;
  blobSubscription: SubscriptionLike;

  activeReportURL: string;
  activeReportAPIModel: ReportAPIModel;
  activeReportCode: string;
  jasperReportRequestData: any;
  reportHTML: SafeHtml = "";
  reportDOM: Document;
  fileTypeDropDown: string = '';
  options: ReportAPIOptions;
  jasperreportdata: any;
  @ViewChild('scrollable', { static: false }) scrollRef: ScrollbarComponent;
  @ViewChild('reportWrapper') reportWrap: ElementRef;
  @Output() disableGenerateEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Input() reportViewDisable: boolean = false;
  public pages: string[] = [];
  private currentPageNo: number = 0;
  FileTypes: any[] = [{ id: 1, value: "PDF" }, { id: 2, value: "WORD" }, { id: 3, value: "EXCEL" }, { id: 4, value: "RAWDATA" }];
  findText: string = "";
  ifInputShow: boolean = false;
  ifCancelBool: boolean = false;
  ispageReference: string;
  isZoomReference: string;
  ifPrintable: boolean = false;
  ifSelectPrintable: boolean = false;
  isSearchAvailable: boolean = false;
  isAPILoaded: boolean = false;
  downloadFileName?: string = "";
  previewReportPrintOptions: PreviewReportPrintOptions;
  stayContacts: API.StayGuestOccupanyInfo[];
  reportbytedata: Uint8Array;
  byteNumbersEmail;
  byteNumbersEmailstring: string[];
  htmlStringData: string;
  public emaildata: any;
  guestEmailId?: string = "";
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  enableEmail: boolean = false;
  guestEmailType?: MailTypes;
  includeInactive = false;
  templateId;
  stayId;
  floatLabel: string;
  additionalGuestInfo: AdditionalGuestInfo[] = [];
  preferredLanguageId: number;
  jasperReportDetails: JasperReportDetails
  isJasper: boolean = false;
  jasperData:SafeHtml ="";
  isAccounting: boolean = false
  isAccountingResortFinanceEnabled:boolean = false;
  stopscrolling:boolean = false;
  currentProductId: number;
  versaProductId:number=6;
  @Input('data')
  set setValue(value) {
    if (value) {
      this.options = value.options;
      this.downloadFileName = value.downloadFileName;
      this.previewReportPrintOptions = value.previewReportPrintOptions;
      this.guestEmailId = value.guestEmailId;
      this.enableEmail = value.enableEmail;
      this.guestEmailType = value.guestEmailType;
      this.stayId = value.stayId;
      this.additionalGuestInfo = value.additionalGuestInfo;
      this.preferredLanguageId = value.preferredLanguageId;
      this.jasperReportRequestData = value?.jasperReportRequestData;
      this.jasperReportDetails = value?.jasperReportDetails;
      this.isAccounting = value.isAccounting ?? false
      this.init();
    }
  }
  @Output() closeDialog = new EventEmitter();

  constructor(
    private http: ReportCommunication,
    private _sanitizer: DomSanitizer,
    private localization: Localization,
    private notificationBusiness: AgPreviewReportContentBusiness,
    private utils: CommonUtilities,
    private jasperReportBusiness: JasperReportBusiness,
    public dialog: MatDialog,
    public commonPropertyInformation: CommonPropertyInformation
  ) {
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
    this.isAccountingResortFinanceEnabled = this.commonPropertyInformation.isResortFinanceEnabled;
  }

  ngOnInit()
  {
    this.currentProductId = Number(this.utils.GetPropertyInfo('ProductId'));
  }


  reportDownload() {
    this.ifPrintable = !this.ifPrintable;
  }

  init() {
    this.ifPrintable = false;
    this.ispageReference = "";
    this.isZoomReference = "";
    this.findText = '';
    this.ifCancelBool = false;
    this.ifInputShow = false;
    if (this.options != undefined) {
      if (!this.options.format || this.options.format == "HTML") {
        this.generateReportHTML();
      } else {
        this.generateAndDownloadReport();
      }
    };
    if (this.jasperReportDetails && this.jasperReportDetails.isJaspersoftReport) {
      if (this.jasperReportRequestData.format && this.jasperReportRequestData.format == "HTML") {
        this.isJasper=true;
        this.generateReportHTML();
      } else {
        this.generateAndDownloadReport();
      }
    }
  }

  private async generateReportHTML() {
    //before report html load
    this.isAPILoaded = false;
    this.resetScrollBar();
    this.resetOldReport();
    this.disableGenerateEvtEmitter.emit(true);
    if(this.isJasper)
    {
      try{
        await this.jasperReportBusiness.sendJasperReportRequest(this.jasperReportRequestData, this.jasperReportDetails, environment['JasperReport'], false, true).then(x => {
          this.reportDomStringData = x;
          this.isAPILoaded = true;
          this.activeReportAPIModel = this.jasperReportRequestData;
          let parser = new DOMParser();
          this.reportDOM = parser.parseFromString(this.reportDomStringData, "text/html");
          let data = cloneDeep(this.reportDomStringData)
  
  
          if (document.readyState === "complete" && document.getElementById('jasperreportdata'))    
          {
            // document.getElementById('jasperreportdata').innerHTML = data;     
            this.localization.decodeHTMLEntityByID(data,'jasperreportdata') 
            this.pages = [];
            this.seperatePagesForJasper();
            this.setPage(1);
            this.disableGenerateEvtEmitter.emit(false);
            this.utils.ToggleLoader(false);
  
          }
          else
          {
            setTimeout(() => {
              // document.getElementById('jasperreportdata').innerHTML = data;
            this.localization.decodeHTMLEntityByID(data,'jasperreportdata');
              this.pages = [];
              this.seperatePagesForJasper();
              this.setPage(1);
              this.disableGenerateEvtEmitter.emit(false);
              this.utils.ToggleLoader(false);
              }, 1000);
          }
       });
      }
      catch(e){
        this.utils.ToggleLoader(false);     
        this.utils.showAlert(this.captions.warn_Statement_Generation_failed, AlertType.Error, ButtonType.Ok, async (res) => { 
          this.closeDialog.emit(true);
        });      
      }
    }
    else
    {
      this.utils.ToggleLoader(true);
      this.reportDomStringData = await this.sendReportRequest();
      let parser = new DOMParser();
      this.reportDOM = parser.parseFromString(this.reportDomStringData, "text/html");
      this.htmlStringData = new XMLSerializer().serializeToString(this.reportDOM);
  
      //once html is loaded
      this.pages = [];
      this.separatePages();
      this.setPage(1);
      this.disableGenerateEvtEmitter.emit(false);
      this.utils.ToggleLoader(false);
    }
  }
  seperatePagesForJasper(totalPages?:number): void {
    let i = 1;
    while(1){
      const element: HTMLDomElement = document.getElementById('JR_PAGE_ANCHOR_0_' + i);
      let currentHTML: string = "";
      currentHTML = element.outerHTML;
      this.pages.push(currentHTML);
      i++;
      if(!document.getElementById('JR_PAGE_ANCHOR_0_' + i)){
        this.pages.push('')
        break;
      }
    }
  }
  
  private async previewBLOBReportHTML(reportBLOB : string) {
    //before report html load
    this.utils.ToggleLoader(true);
    this.isAPILoaded = false;
    this.resetScrollBar();
    this.resetOldReport();
    this.disableGenerateEvtEmitter.emit(true);
    this.reportDomStringData = reportBLOB;

    let parser = new DOMParser();
    this.reportDOM = parser.parseFromString(this.reportDomStringData, "text/html");
    this.htmlStringData = new XMLSerializer().serializeToString(this.reportDOM);

    //once html is loaded
    this.pages = [];
    this.separatePages();
    this.setPage(1);
    this.disableGenerateEvtEmitter.emit(false);
    this.utils.ToggleLoader(false);
  }

  private generateAndDownloadReport(): void {
    this.downloadReport(this.options.format);
  }

  async downloadJasperReport(format, fileName, showPrintDialog: boolean = false) {
    this.jasperReportRequestData.format = format;
    await this.jasperReportBusiness.sendJasperReportRequest(this.jasperReportRequestData, this.jasperReportDetails, environment['JasperReport'], true, true).then(res => {
      if (showPrintDialog) {
        this.showFile(res);
      } else {
        saveFile(res, fileName);
      }
    });
  }

  private async sendReportRequest(): Promise<string> {
    const reportsKey: string = this.isAccounting ? (this.isAccountingResortFinanceEnabled ? "report": "AccountingReport" ): "PMSReport"
    const route: string = this.isAccounting && !this.isAccountingResortFinanceEnabled ?AccountingReportRoutes.GetReport : ReportRoutes.GetReport
    try {
      let body = this.createAPIOptions(this.options, "HTML");
      let response$: Promise<string> = this.http.putHTTPBlobData({
        route: environment[reportsKey] + '/' + route,
        body: body
      }, 'text').toPromise();
      response$.then(x => {
        this.isAPILoaded = true;
      })
      response$.catch(err => {
        let errorTxt: string = "";
        let errorObjStr: string = err.error;
        let errorCode = JSON.parse(errorObjStr).errorCode;
        this.disableGenerateEvtEmitter.emit(false);
        errorTxt = this.localization.getError(errorCode);
        let displayInfoPopup : boolean = false;

        //TO DO - need to remove common translog err
        if (errorCode.toString() == "-118") {
          if (this.options.code == "TransLog")
            errorTxt = this.localization.getError(10723);
          else if(this.options.code == "HouseStatusOfRoomTypes" || this.options.code == "SummaryArrivals"
                  || this.options.code == "Departures"){
            displayInfoPopup = true;
          }
          else if (this.options.code == "RePrintInvoice") {
            let invoiceBy = this.options.params.filter(x => x['pPrintInvoiceBy'])[0].pPrintInvoiceBy;
            if (invoiceBy && invoiceBy == 2)
              errorTxt = this.localization.getError(-120);
          }
        }

        if (displayInfoPopup){
            this.utils.showAlert(errorTxt, AlertType.Info, ButtonType.Ok);
        }
        else{
          this.utils.showError(errorTxt);
        }
        this.isAPILoaded = true;
      });
      this.activeReportAPIModel = body;
      return await response$;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  searchContent() {
    this.ifInputShow = !this.ifInputShow;
    if (this.ifInputShow) {
      // searchContent
    }
  }

  reportDownloadandPrint() {
    this.downloadReport('PDF', true);
  }

  reportEmail() {

    this.notificationBusiness.generateTableData(NotifyTemplateType.Email, this.includeInactive, this.preferredLanguageId).then(data => {
      if (data && data.length > 0) {
        var isDefaultTemplate = data.find(x => x.default == true);
        if (isDefaultTemplate) {
          this.templateId = isDefaultTemplate.id;
        } else {
          this.templateId = data[0].id;
        }
      }
    });

    this.dialog.open(SendEmailComponent, {
      width: '800px',
      height: '400px',
      maxWidth: '800px',
      data: {
        guestEmailId: this.guestEmailId,
        guestEmailType: this.guestEmailType,
        additionalGuestInfo: this.additionalGuestInfo
      },
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async res => {

      if (res) {
      let reportCode = this.options?.code;
      let senderEmail = this.utils.GetPropertyInfo("PropertyEmail");
        const details1 = {
          RefId: this.stayId,
          EmailTemplateId: this.templateId,
          SMSTemplateId: 0,
          LetterType: LettterSetup.FolioStatement,
          PrintType: PrintType.Pdf,
          ContactPhone: '',
          ContactEmail: res?.data,
          IsPrint: false,
          IsSendEmail: true,
          IsSendSMS: false,
          SenderEmail: !senderEmail ? 'noreply@hospitalityrevolution.com' : senderEmail,
          FromName: this.localization.GetPropertyInfo('PropertyName'),
          MailBody: reportCode =='VATInvoice' ? 'Tax Invoice Received' : reportCode == 'ProFormaFolio' ? 'Proforma Invoice Received' : 'Folio Statement Received',
          MailSubject: reportCode =='VATInvoice' ? 'Tax Invoice' : reportCode == 'ProFormaFolio' ? 'Proforma Invoice' : 'Folio Statement',
          htmlContent: this.htmlStringData
        } as sendEmailRequest;

        await this.notificationBusiness.NotifyConfirmationLetter(details1).then(value => {
          console.log('data received');
        });
      }
    })
  }


  private createAPIOptions(options: ReportAPIOptions, _format: ReportDownloadFormat): ReportAPIModel {

    return {
      code: options.code,
      format: _format,
      downloadFileName: "Download",
      parameters: this.arrayToObject(options.params),
      uRIParams: this.arrayToObject(options.URIParams),
      filterBody: options.filters,
      dateFormat: this.localization.dateFormat,
      reportData :options.reportData
    } as ReportAPIModel;
  }

  private arrayToObject(objectArr: any[]): { [key: string]: string } {
    var result = {};
    objectArr.forEach(o => {
      result[Object.keys(o)[0]] = Object.values(o)[0];
    });
    return result;   //Dictionary<string,string>
  }

  ngOnDestroy() {
    if (this.reportQuerySubscription) {
      this.reportQuerySubscription.unsubscribe();
    }
    if (this.blobSubscription) {
      this.blobSubscription.unsubscribe();
    }
  }

  private resetOldReport(): void {
    this.reportHTML = "";
    this.pages = [];
  }

  private resetScrollBar(): void {
    if (this.scrollRef) {
      this.scrollRef.scrollToTop();
      this.scrollRef.scrollToLeft();
    }
  }

  ngAfterViewChecked() {
    setTimeout(() => {
      this.calculateWidthHeight();
    }, 1);
  }

  private separatePages(): void {
    let finalHtml: string = "";
    let bodyArr: HTMLCollectionOf<Element> = this.reportDOM.getElementsByTagName('body')[0].children;
    for (let i = 0; i < bodyArr.length; i++) {
      const element: HTMLDomElement = bodyArr[i];
      let currentHTML: string = "";
      if (element.name && element.name.includes('PageN')) {
        this.pages.push(finalHtml);
        finalHtml = "";
      } else {
        currentHTML = element.outerHTML;
        finalHtml = finalHtml + currentHTML;
      }
    }
    this.pages.push(finalHtml);
  }

  getPageNo(operation: "NEXT" | "PREVIOUS" | "FIRST" | "LAST"): number {
    this.ispageReference = operation;
    switch (operation) {
      case "NEXT":
        if (this.currentPageNo < (this.pages.length - 1)) {
          this.currentPageNo++;
        }
        break;
      case "PREVIOUS":
        if (this.currentPageNo > 1) {
          this.currentPageNo--;
        }
        break;
      case "FIRST":
        this.currentPageNo = 0;
        break;
      default:
        break;
    }
    return this.currentPageNo;
  }


  public setPage(pageNo: number): void {
    pageNo = parseInt(pageNo.toString().replace(/^0+/, ''));
    this.resetScrollBar();
    this.pageNumber = pageNo;
    if (pageNo != 0 && pageNo < this.pages.length) {
      this.reportHTML = this.getPageContent(pageNo);
    } else {
      this.setPage(this.pages.length - 1);
    }
  }

  moveToNextPage(e: any): void {
    if ((this.scrollRef['_trackTopMax'] && this.scrollRef['_currYPos']) >= 0) {
      const scrollyPosition = Math.ceil(this.scrollRef['_currYPos']);
      const topMax = Math.ceil(this.scrollRef['_trackTopMax']);
      if (scrollyPosition >= topMax && (e.wheelDeltaY < 0)) {
        if (this.pageNumber < this.pages.length - 1) {
          this.setPage(this.getPageNo('NEXT'));
          this.scrollRef.scrollToTop();
        }
      } else if (this.scrollRef['_currYPos'] == 0 && (e.wheelDeltaY > 0)) {
        if (this.pageNumber != 1) {
          this.setPage(this.getPageNo('PREVIOUS'));
          this.scrollRef.scrollToBottom();
        }
      }
    }
  }

  downloadReport(format: ReportDownloadFormat, showPrintDialog: boolean = false): void {
    let downloadURL = environment["PMSReport"]+ '/' + ReportRoutes.GetReport;
    if(this.isAccounting){
      if(this.isAccountingResortFinanceEnabled){
        downloadURL = environment["report"] + '/' + ReportRoutes.GetReport;
      }
      else{
        downloadURL = environment["AccountingReport"] + '/' + AccountingReportRoutes.GetReport;
      }
    }
    this.downloadFile(downloadURL, format, showPrintDialog);
  }



  private downloadFile(url: any, type: ReportDownloadFormat, showPrintDialog: boolean): void {
    let fileName: string;
    let currentDate: Date = this.localization.getCurrentDate();
    let dateTime: string = currentDate.toLocaleDateString(this.localization.localeCode);
    let downloadSuffixDate: string = dateTime.replace(/\D/g, '');
    let jasperDownloadType: string = "";
    let downloadFileName = this.downloadFileName && this.downloadFileName != "" ? this.downloadFileName : this.activeReportAPIModel.code + "_" + downloadSuffixDate;
    // let dateTimeValue:string = dateTime.
    if (type == "PDF") {
      fileName = `${downloadFileName}.pdf`
      jasperDownloadType = ReportDownloadFormatOptions.PDF;
    }
    else if (type == "WORD") {
      fileName = `${downloadFileName}.docx`
      jasperDownloadType = ReportDownloadFormatOptions.DOCX;
    } else if (type == "EXCEL" || type == "RAWDATA") {
      fileName = `${downloadFileName}.xlsx`
      jasperDownloadType = ReportDownloadFormatOptions.XLSX;
    } else if (type == "IMAGE") {
      fileName = `${downloadFileName}.jpeg`
    }
    this.activeReportAPIModel.format = type;
    if (this.isJasper) {
      this.downloadJasperReport(jasperDownloadType, fileName, showPrintDialog);
    }
    else {
      this.blobSubscription = this.http.putHTTPBlobData({
        route: url,
        body: this.activeReportAPIModel
      }, 'blob').subscribe(res => {
        if (showPrintDialog) {
          this.showFile(res);
        } else {
          saveFile(res, fileName);
        }
      })
    }
  }

  private showFile(blob) {
    var newBlob = new Blob([blob], { type: "application/pdf" })
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(newBlob);
      return;
    }
    const data = window.URL.createObjectURL(newBlob);
    let newTab: Window = window.open(data);
    newTab.focus();
    newTab.print();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }


  zoom(action: "IN" | "OUT"): void {
    this.isZoomReference = action;
    if (action == 'IN') {
      this.zoomLevel = this.zoomLevel >= 1 && this.zoomLevel <= 4 ? this.zoomLevel + 0.5 : this.zoomLevel;
      document.getElementById("reportData").style['zoom'] = this.zoomLevel + "";
    }
    else if (action == 'OUT') {
      if (this.zoomLevel > 1) this.zoomLevel -= 0.5;
      document.getElementById("reportData").style['zoom'] = this.zoomLevel + "";
    }
  }

  print(): boolean {
    let mywindow = window.open('', 'PRINT');
    mywindow.document.write(this.reportDomStringData);
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();
    return true;
  }

  searchtext(searchdata: string): void {
    let content = document.getElementById('reportData').innerHTML;
    let regex = new RegExp("<span style=\"background-color:yellow\">", "gi");
    content = content.replace(regex, "");
    regex = new RegExp("</span>", "gi");
    content = content.replace(regex, "");
    if (searchdata) {
      this.ifCancelBool = true;
      regex = new RegExp(searchdata, "gi");
      content = content.replace(regex, "<span style='background-color:yellow'>" + searchdata + "</span>");
      // document.getElementById('reportData').innerHTML = content;
      this.localization.decodeHTMLEntityByID(content,'reportData');
    }
    else {
      this.findText = '';
      this.ifCancelBool = false;
      // document.getElementById('reportData').innerHTML = content;
      this.localization.decodeHTMLEntityByID(content,'reportData');
    }
  }

  private getPageContent(pageNo: number): string {
    let _allStylesContent: string = this.extractAllStyles();
    let _headerHTMLContent: string = '<html><body>' + (this.isJasper ? '' : this.pages[0]) + _allStylesContent;
    let _currentContent: string = "";
    _currentContent = this.isJasper ? this.pages[pageNo - 1] : this.pages[pageNo];
    this.currentPageNo = pageNo;
    return _headerHTMLContent + _currentContent + '</body></html>';
  }

  private extractAllStyles(): string {
    let _allStylesFromDom: any = this.reportDOM.getElementsByTagName('style');
    let _concatedstyles: string = "";
    for (let style of _allStylesFromDom) {
      const element = style.outerHTML;
      _concatedstyles = _concatedstyles + element;
    }
    return _concatedstyles;
  }

  calculateWidthHeight(): void {
    if(!this.isJasper){
    let reportWidth = document.getElementsByClassName('frpage')[0];
    if (reportWidth) {
      let dataWidth = reportWidth['offsetWidth'];
      reportWidth.closest("#textcenter-Web")['style']['width'] = dataWidth + 77 + 'px';
    }
    }
    else{
      if(document.getElementById('JR_PAGE_ANCHOR_0_1')){
        let reportWidth = document.getElementById('JR_PAGE_ANCHOR_0_1');
        if (reportWidth) {
          let dataWidth = reportWidth.style.cssText.split(";")[1].split(":")[1];
          let reportwidth = Number(dataWidth.replace(" ","").replace('px',''))
          if(reportWidth.closest("#textcenter-Web"))
            reportWidth.closest("#textcenter-Web")['style']['width'] = reportwidth<600? reportwidth+ 203 + 'px':reportwidth+ 300 +'px';
        }
      }
    }
  }
  public async downloadReportforPrint(format: ReportDownloadFormat, options: ReportAPIOptions, showPrintDialog: boolean = false, callBack?: any, isDownloadWithPreview?: boolean): Promise<string> {
    try {
      let downloadURL = environment["PMSReport"] + '/' + ReportRoutes.GenerateAndSaveReport;
      return await this.downloadFileforPrint(downloadURL, format, options, showPrintDialog, callBack, isDownloadWithPreview);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public async downloadAccountingReportforPrint(format: ReportDownloadFormat, options: ReportAPIOptions, showPrintDialog: boolean = false, callBack?: any, isDownloadWithPreview?: boolean): Promise<string> {
    try {
      let downloadURL = environment["AccountingReport"] + '/' + AccountingReportRoutes.GenerateAndSaveReport;
      return await this.downloadFileforPrint(downloadURL, format, options, showPrintDialog, callBack, isDownloadWithPreview);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  private async downloadFileforPrint(url: string, type: ReportDownloadFormat, options: ReportAPIOptions, showPrintDialog: boolean, callBack?: any, isDownloadWithPreview?: boolean): Promise<string> {
    let fileName: string;
    let apiModel: ReportAPIModel = this.createAPIOptionsforPrint(options);
    fileName = await this.http.putHTTPBlobData({
      route: url,
      body: apiModel
    }, 'text').toPromise();
    return fileName;
  }

  public async downloadReportforPrintForRF(format: ReportDownloadFormat, options: ReportAPIOptions, showPrintDialog: boolean = false, callBack?: any, isDownloadWithPreview?: boolean): Promise<string> {
    try {
      let downloadURL = environment["report"] + '/' + ReportRoutes.GenerateAndSaveReportForRF;
      return await this.downloadFileforPrintForRF(downloadURL, format, options, showPrintDialog, callBack, isDownloadWithPreview);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  private async downloadFileforPrintForRF(url: string, type: ReportDownloadFormat, options: ReportAPIOptions, showPrintDialog: boolean, callBack?: any, isDownloadWithPreview?: boolean): Promise<string> {
    let fileName: string;
    let apiModel: ReportAPIModel = this.createAPIOptionsforPrint(options);
    fileName = await this.http.putHTTPBlobData({
      route: environment["report"] + '/' + ReportRoutes.GenerateAndSaveReportForRF,
      body: apiModel
    }, 'text').toPromise();
    return fileName;
  }

  private createAPIOptionsforPrint(options: ReportAPIOptions): ReportAPIModel {

    return {
      code: options.code,
      format: "PDF",
      downloadFileName: "DownloadAndSave",
      parameters: this.arrayToObject(options.params),
      uRIParams: this.arrayToObject(options.URIParams),
      filterBody: options.filters,
      dateFormat: this.localization.dateFormat,
      reportData :options.reportData
    } as ReportAPIModel;
  }

  scrollPage(e){
    const scrollHeight = e.target.scrollHeight;
    const scrollTop = e.target.scrollTop;
    const offsetHeight = e.target.offsetHeight;
    if (this.stopscrolling) {
       e.preventDefault();
    } else {
      if((scrollTop + offsetHeight) >= (scrollHeight - 2)){
        if (this.pageNumber < this.pages.length - 1) {
          this.setPage(this.getPageNo('NEXT'));
          this.reportWrap.nativeElement.scrollTop = 10;
        }
      } else if(scrollTop == 0){
        if (this.pageNumber != 1) {
          this.setPage(this.getPageNo('PREVIOUS'));
          this.reportWrap.nativeElement.scrollTop = scrollHeight - offsetHeight - 5;
        }
      }
    }
  }
}

