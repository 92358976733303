import { HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { forEach } from "lodash";
import { Observable, of, Subscriber } from "rxjs";
import { catchError, concatMap } from "rxjs/operators";
import { tap } from 'rxjs/operators';

const DEFAULT_THROTTLE_LIMIT = 10;
@Injectable()
export class APIThrottleService {
    private activeCount = 0;
    private throttleLimit = DEFAULT_THROTTLE_LIMIT;
    private reqURLs = []; // pending requests
    private reqObs: { [key: string]: Subscriber<any> } = {}; // request observable tracking
    private uRLs: string[] = []; // configured URLs to be throttled

    // Check if any pending requests are still getting throttled
    public get IsThrottling() {
        return this.reqURLs && this.reqURLs.length > 0;
    }

    public Subscribe(urls: string[], throttleLimit: number = DEFAULT_THROTTLE_LIMIT) {
        this.throttleLimit = throttleLimit;
        this.uRLs = urls.map(o => o.toLocaleLowerCase());
    }

    public Reset() {
        this.unSubscribeObservables();
    }

    public UnSubscribe() {
        this.uRLs = [];
        this.unSubscribeObservables();
    }

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        let url = req.url;
        if (this.isThrottlingEnabled(url)) {
            const indx = this.reqURLs.indexOf(url);
            if (indx > -1) {
                // De-duping calls here
                // this.reqURLs.splice(indx, 1);
                // const observer = this.reqObs[url];

                // observer.error('Duplicate Call, cancelling this call')
                // delete this.reqObs[url];
                // // if (this.activeCount > 0) {
                // //     this.activeCount--;
                // // }
            }
            if (this.activeCount < this.throttleLimit) {
                this.activeCount++;
                return next.handle(req)?.pipe(
                    tap(evt => {
                        if (evt instanceof HttpResponse) {
                            this.processResponse();
                        }
                        return evt;
                    }),
                    catchError(err => {
                        this.processResponse();
                        return of(err);
                    }),
                )
            } else {
                this.reqURLs.push(url);
                this.reqObs[url] = null;
                const obs = new Observable(ob => {
                    this.reqObs[url] = ob;
                });
                return obs.pipe(
                    concatMap(_ => {
                        return next.handle(req)?.pipe(
                            tap(evt => {
                                if (evt instanceof HttpResponse) {
                                    this.processResponse();
                                }
                                return evt;
                            }),
                            catchError(err => {
                                this.processResponse();
                                return of(err);
                            }),
                        )
                    }));
            }
        } else {
            return next.handle(req);
        }
    }

    private isThrottlingEnabled(url: string): Boolean {
        let currentUrl = url.toLocaleLowerCase();
        return this.uRLs.filter(o => currentUrl.indexOf(o) > -1).length > 0;
    }

    private processResponse() {
        if (this.activeCount > 0) {
            this.activeCount--;
        }
        if (this.reqURLs.length > 0) {
            const url = this.reqURLs[0];
            const observer = this.reqObs[url];
            this.activeCount++;
            observer?.next('done!');
            observer?.complete();
            this.reqURLs = this.reqURLs.slice(1);
            delete this.reqObs[url];
        }
    }

    private unSubscribeObservables() {
        if (this.reqObs) {
            for (const [key, value] of Object.entries(this.reqObs)) {
                (value as unknown as Subscriber<any>).unsubscribe();
            }
            this.reqObs = {};
            this.reqURLs = [];
        }
        this.activeCount = 0;
    }
}