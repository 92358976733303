import { Injectable } from "@angular/core";
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { ActionTypeEnum, HdrActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { GolfLocalization } from "src/app/core/localization/golf-localization";
import {AuditTrailFilterData , AuditTrail,AuditTrailAPI } from './audit-trail.model';
import {AuditTrailDataService} from "src/app/shared/data-services/golfschedule/audit-trail.data.service";
import { ContactDetails } from 'src/app/shared/shared-models';
@Injectable()

export class AuditTrailBusiness {
    captions: any;  
    propertyConfig: any;
    constructor(private localization: GolfLocalization,
      private _auditTrailDataService: AuditTrailDataService) {
        this.captions = this.localization.captions;
    }

    public async GetAuditTrailData(auditTrailFilterData: AuditTrailFilterData,scheduleTeeTimeId:number, _isOpenSlot:boolean,courseId:number,scheduledDateTime : Date, originalHoleNumber :number)
    {
       const data =  await this._auditTrailDataService.GetAuditTrailData(auditTrailFilterData,scheduleTeeTimeId,_isOpenSlot,courseId,scheduledDateTime,originalHoleNumber);
       return this.AuditUIMapper(data);
    }
    public async GetAuditTrailDataForOpenSlots(courseId:number,scheduledDateTime:Date,originalHoleNumber:string)
    {
      const data =  await this._auditTrailDataService.GetAuditTrailDataForOpenSlots(courseId,scheduledDateTime,originalHoleNumber);
       return this.AuditUIMapper(data);
    }
    public AuditUIMapper(data: AuditTrailAPI[])
    {
      const auditTrails : AuditTrail[] = data.map(x=>
       {
        var desc="";
        var comment ="";
        var showInUI = "true";
        var restrictInUI =""
        if(x.description.includes("~^>"))
        {
          var y = x.description.split("~^>");
          desc = y[0]? y[0] : '';
          comment = y[1]? this.replaceStr(y[1]) : '';
          showInUI = y[2]? y[2] : "true";
          restrictInUI = y[3]?y[3] :"";
        }
        else
        {
          desc = x.description;
          comment = '';
        }
        return {
          dateTime : this.localization.LocalizeShortDateTime(x.dateTime),
          description : desc,
          userName : x.userName,
          confirmationNumber : x.confirmationNumber,
          ticketNumber :x.ticketNumber !=0 ?x.ticketNumber : null ,
          filterData : comment,
          comments : this.restrictShowMore(desc,comment,showInUI,restrictInUI),
          expanded : true,
          playerSlotPosition : x.playerSlotPosition,
          date : this.localization.LocalizeDate(x.dateTime),
          time : this.localization.LocalizeTime(x.dateTime),
          timeWithSecond: this.localization.GetTotalMilliSeconds(new Date(x.dateTime))
        }
       });
       return auditTrails;
    }

    restrictShowMore(desc:string,comment:string,showInUI:string,restrictInUI:string)
    {
      if(showInUI=="False")
      {
        var data="";
        if(restrictInUI!="")
        {
          var y = comment.split('<br>');
          y.forEach(x=>
            {
              if(!(restrictInUI.split(', ').some(y=> x.indexOf(y)>0)))
              {
                data = data + x + '<br>';
              }
            });
        }
        return data;
      }
      return comment;
    }
    replaceStr(val) {
      let isValid= val.includes('\r\n');
      if (isValid) {
        val = val.replaceAll('\r\n', '<br>').replaceAll('"','').replaceAll('<Blank>', '[Blank]').replaceAll('<br><br>','');
        return val;
      }      
      return val;
    }
    public async GetContactInformationByPlayerIds(playerIds: number[]): Promise<ContactDetails[]>
    {
      return this._auditTrailDataService.GetContactInformationByPlayerIds(playerIds);
    }
    
    getTableOptions(): TableOptions {
        let obj = {
          defaultSortOrder: SorTypeEnum.desc,
          defaultsortingColoumnKey: 'timeWithSecond',
          isRowClick: true,
          uniqueKey: 'timeWithSecond',
        }
        return obj;
      }
   
      getHeaderOptions(): TableHeaderOptions[] {
          const headers: TableHeaderOptions[] = [
            {
              key: 'time',
              displayName: this.captions.tbl_hdr_time,
              displayNameId: 'tbl_lbl_date',
              sorting: true
            },
            {
              key: 'description',
              displayName: this.captions.lbl_description,
              displayNameId: 'lbl_description',
              sorting: true,
              templateName: ActionTypeEnum.custom
            },
            {
              key: 'userName',
              displayName: this.captions.lbl_userId,
              displayNameId: 'lbl_userId',
              sorting: true,
            },
            {
              key: 'confirmationNumber',
              displayName: this.captions.tbl_lbl_confirmationNumber,
              displayNameId: 'tbl_lbl_confirmationNumber',
              sorting: true, 
            },
            {
              key: 'ticketNumber',
              displayName: this.captions.tbl_lbl_ticketNumber,
              displayNameId: 'tbl_lbl_ticketNumber',
              sorting: true, 
            }
          ]    
          return headers;
        }
}