
import { Injectable } from '@angular/core';
import { Course } from 'src/app/settings/golf-setup/code-setup/course/create-course-modal/create-course-model';
import { GolfScheduleCommunication } from 'src/app/shared/communication/services/golfschedule.service';

@Injectable({
  providedIn: 'root'
})
export class CourseDataService {

  constructor(private _golfScheduleCommunication: GolfScheduleCommunication) {
  }

  public getCoursesWithUserAccess(IncludeInActive: boolean = false): Promise<Course[]> {
    return this._golfScheduleCommunication.getPromise<Course[]>(
      { route: GolfApiRoute.GetCourses, uriParams: { "includeInactive": IncludeInActive } }, false);
     
  }
  public getCourse(id: number): Promise<Course> {
    return this._golfScheduleCommunication.getPromise<Course>(
      { route: GolfApiRoute.GetCourse, uriParams: { "id": id } }, false);
    
  }
}
