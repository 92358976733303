import { Component, OnInit, Inject,ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { StepperService } from 'src/app/shared/components/stepper/stepper.service';
@Component({
  selector: 'app-player-selection',
  templateUrl: './player-selection.component.html',
  styleUrls: ['./player-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlayerSelectionComponent implements OnInit {
  playerSelectionForm: UntypedFormGroup;
  playersList;
  data;
  captions;
  isSaveDisabled = false;
  constructor(private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public dialogData: any,
              public localization: GolfLocalization, public _StepperService: StepperService,
              public dialogRef: MatDialogRef<PlayerSelectionComponent>) { 
    this.data = this.dialogData.playerDetail;
  }

  ngOnInit(): void {
    this.captions = this.localization.captions.teetime;
    this.buildForm();
    this.playersList = this.data.playerDetail;
  }

  buildForm() {
    this.playerSelectionForm = this.fb.group({
      player: ''
    })
    this.playerSelectionForm.statusChanges.subscribe((x) => {
    });
  }

  playersChange(e, data, idx) {

  }

  close() {
    this.dialogRef.close();
  }

  onSave() {
    this.dialogRef.close(this.playerSelectionForm.value);
  }

}
