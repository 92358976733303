import { Component, Inject, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { takeUntil } from 'rxjs/operators';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { CaddyTypeValues } from 'src/app/settings/golf-setup/code-setup/caddy-type/caddy-type.modal';
import { TeeTimeFormat } from 'src/app/shared/models/teesheet.form.models';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { CaddySelectComponent } from '../teetime/caddy-select/caddy-select.component';
import { GeneralInformationBusiness } from '../teetime/general-information/general-information.business';
import { AvailableCaddy } from 'src/app/shared/models/teesheet.api.models';
import { GeneralInformationService } from '../teetime/general-information/general-information.service';
import { ScheduledTeeTimeTimePlayer } from '../teetime/tee-time.model';
import { TeeTimeService } from '../teetime/tee-time.service';
import _ from 'lodash';
import { CaddyTypeDataService } from 'src/app/shared/data-services/golfschedule/caddyType.data.service';
import { CaddyScheduleDataService } from 'src/app/shared/data-services/golfschedule/caddySchedule.data.service';
import { PlayerTeeTimeSlot } from '../move-player-modal/move-player-modal.model';
import { SaveButtonObj } from 'src/app/common/Models/ag-models';
import { CaddyTypeSelectionPopupBusiness } from './caddytype-selection.business';
import { NotifierBar } from 'src/app/shared/components/note/note.model';

@Component({
  selector: 'caddytype-selection-popup',
  templateUrl: './caddytype-selection.component.html',
  styleUrls: ['./caddytype-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CaddyTypeSelectionPopupBusiness, CaddyTypeDataService, CaddyScheduleDataService,]
})
export class CaddyTypeSelectionPopupComponent implements OnInit {
  caption: any;
  buttonObj: SaveButtonObj;
  displayColumns = [];
  rows: UntypedFormArray = this.fb.array([]);
  form: UntypedFormGroup;
  caddyTypes: CaddyTypeValues;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  availableCaddies: AvailableCaddy[];
  assignedCaddyIds: number[];
  assignedCaddies: AvailableCaddy[] = [];
  allCaddies: AvailableCaddy[];
  courseId: number;
  scheduleId: number;
  teeTimeDateTime: Date;
  selectedCaddyTypeId: number;
  scheduledTeeTimePlayers: ScheduledTeeTimeTimePlayer[];
  canEditCaddy: boolean = false;
  isMethodExecuted = false;
  playerTeeTimeSlotCaddyInfo: PlayerTeeTimeSlot[] = [];
  playersCaddyInfoTemp : PlayerTeeTimeSlot[]=[];
  isCaddyAvailableFromSource : any;
  notificationFlag : boolean = false;
   notificationbar: NotifierBar;
  floatLabel: string;
  automationId:string = "CaddyTypeSelection";
  constructor(public dialogRef: MatDialogRef<CaddyTypeSelectionPopupComponent>, @Inject(MAT_DIALOG_DATA) public dialogData,
    private fb: UntypedFormBuilder, private _localization: GolfLocalization,
    private _utils: GolfUtilities, private _teetimeservice: TeeTimeService, private _caddyTypeSelectionPopupBusiness: CaddyTypeSelectionPopupBusiness,
    private dialog: MatDialog) {
      this.floatLabel = this._localization.setFloatLabel;
    this.caption = _localization.captions.settings;
    this.courseId = dialogData.courseId;
    this.scheduleId = dialogData.scheduledTeeTimeId;
    this.teeTimeDateTime = dialogData.scheduledDateTime;
    this.playerTeeTimeSlotCaddyInfo = dialogData.players;
    this.playersCaddyInfoTemp =dialogData.playersCaddyInfo;
    this.isCaddyAvailableFromSource= dialogData.isCaddyAvailableFromSource;
    this.displayColumns = [this.caption.player,
    this.caption.confirmation,
    this.caption.caddyType,
    this.caption.caddySearch,
    this.caption.caddyNo]
  }
  ngOnInit(): void {
    this.buttonObj = {
      isEdit: false,
      disabled: true,
      customSaveText: this.dialogData.save,
      customCancelText: this.dialogData.cancel
    };
    this.buildDefaultForm();
    this.initializeData();
    if(this.isCaddyAvailableFromSource && this.isCaddyAvailableFromSource.length > 0)
    {
      this.notificationFlag = true;
      this.notificationFlag = this.notificationFlag;
      this.notificationbar = {
        class: '',
        value: this._localization.captions.teetime.AssignedCaddiesNotAvailable,
        color: "#fff16e",
        isRemovable: true,
        undoOption: false
      }
    }
  }
  async initializeData() {
    let caddytypes = this.getCaddyTypes(this.courseId, this.teeTimeDateTime, false);
    let availableCaddies = this._caddyTypeSelectionPopupBusiness.getCaddySchedulesForTeetime(this.courseId, this.teeTimeDateTime, this.scheduleId)
    await Promise.all([caddytypes, availableCaddies]).then(res => {
      this.caddyTypes = res[0];
      this.allCaddies = res[1];
      this.availableCaddies = res[1];
      this.buildFormfromData();
    })

  }
  buildFormfromData()
  {
    this.playerTeeTimeSlotCaddyInfo.forEach((d) => this.addRow(d));
    this.form = this.fb.group({ caddyInfo: this.rows});
    this.buttonObj.disabled = this.caddyTypes && this.caddyTypes.requirementExists && this.form.controls.caddyInfo.value.some(c=> c.caddyType == 0 || c.caddyType == null);
    this.buttonObj = {...this.buttonObj}
    this.form.controls.caddyInfo.valueChanges.subscribe(f=>{
     let disableButton : boolean = this.caddyTypes && this.caddyTypes.requirementExists ? this.form.controls.caddyInfo.value.some(c=> c.caddyType == 0 || c.caddyType == null) : false ;
        this.buttonObj.disabled = disableButton;
        this.buttonObj = {...this.buttonObj}
    });
    let unavailableCaddyIds : number []= this.playersCaddyInfoTemp.map(x=> x.caddyId);
    this.availableCaddies =this.availableCaddies.filter(d => !unavailableCaddyIds.includes(d.id));
    this.allCaddies = this.availableCaddies;
  }
  buildDefaultForm()
  {    
   let defaultArray =this.fb.array([this.fb.group({
      id: 0,
      player: [null, []],
      confirmationNo:  '',
      caddyType: '',
      caddyId: 0,
      caddySearch: null,
      caddyNo: null,
    })]);
    this.form = this.fb.group({ caddyInfo: defaultArray});
  }
  DialogClose(result?: any): void {
    this.dialogRef.close(result);
  }
  removeNotification(e : boolean){
    this.notificationFlag = e;
    this.notificationFlag = this.notificationFlag;
  }
  private addRow(info: PlayerTeeTimeSlot) {
    let currentCaddy;
    if (info.playerId) {
      currentCaddy = this.allCaddies.find(x => x.playerIds && x.playerIds.includes(info.playerId) && x.id === info.caddyId);
    }
    else currentCaddy = this.allCaddies.find(x => x.id === info.caddyId);
    let caddySearchData = this.allCaddies.find(x => x.id === info.caddyId);
    info.caddyId = caddySearchData && caddySearchData.id ? info.caddyId : 0;
    const isCurrentCaddyAvailable = info && info.caddyId > 0;
    const isCurrentCaddyTypeAvailable = info && info.caddyTypeId > 0 && this.caddyTypes.caddyTypes.find(ct=>ct.id == info.caddyTypeId);
    const defaultCaddy = this.caddyTypes && this.caddyTypes[0] ? this.caddyTypes[0].id : null;
    let isCaddyAvailable = (isCurrentCaddyAvailable && currentCaddy &&  this.caddyTypes.caddyTypes.find(ct=>ct.id == currentCaddy.caddyTypeId) ? currentCaddy.caddyTypeId : defaultCaddy);
    const row = this.fb.group({
      id: info && info.playerId ? info.playerId : 0,
      player: [info && info.firstName ? this._utils.formatGuestName(info.firstName, info.lastName) : null, []],
      confirmationNo: info && info.confirmationNumber ? info.confirmationNumber : '',
      caddyType: isCurrentCaddyTypeAvailable ? info.caddyTypeId : isCaddyAvailable,
      caddyId: info && info.caddyId ? info.caddyId : 0,
      caddySearch: (isCurrentCaddyTypeAvailable || isCaddyAvailable) && isCurrentCaddyAvailable && caddySearchData ? caddySearchData.availableName : null,
      caddyNo: (isCurrentCaddyTypeAvailable || isCaddyAvailable) && isCurrentCaddyAvailable && caddySearchData ? caddySearchData.caddyNumber : null,
    });
    row.controls.caddyType.setValidators(this.caddyTypes && this.caddyTypes.requirementExists ? [Validators.required,Validators.nullValidator] : null);
    this.rows.push(row);
  }
  // Event Handlers
  async caddyTypeChange(arg: MatSelectChange, idx) {
    this.rows.controls[idx].get('caddyType').setValue(arg.value)
    this.removeCaddy(idx);
    this.selectedCaddyTypeId = this.rows.controls[idx].get('caddyType').value;
    this.playerTeeTimeSlotCaddyInfo[idx].caddyTypeId = this.selectedCaddyTypeId;
    this.getAvailableCaddiesByType(this.selectedCaddyTypeId, idx);
  }

  removeCaddy(idx) {
    const currentCaddyId = this.rows.controls[idx].get('caddyId').value;
    this.rows.controls[idx].patchValue({
      caddySearch: '',
      caddyNo: '',
      caddyId: ''
    });
    this.playerTeeTimeSlotCaddyInfo[idx].caddyId = 0;
    this.validateCaddyAvailability();
  }
  validateCaddyAvailability()
  {    
   let assignedCaddyIds : number [] = this.rows.controls.map(c=> c.get('caddyId').value );  
      this.assignedCaddies = _.filter(this.availableCaddies, (p) => {
      return _.includes(assignedCaddyIds, p.id);
    });
   }  
  async assignCaddy(idx) {
    // Current caddy needs to be selected in the select caddy option
    const currentCaddyId = this.rows.controls[idx].get('caddyId').value;
    const currentCaddySearch = this.rows.controls[idx].get('caddySearch');

    // Get the available caddies based on the type and availability
    const currentCaddyType = this.rows.controls[idx].get('caddyType').value;
    this.selectedCaddyTypeId = this.rows.controls[idx].get('caddyType').value;
    this.getAvailableCaddiesByType(currentCaddyType, idx);
    if (!currentCaddyType || this.availableCaddies.length == 0) {
      this._utils.showError(this._localization.captions.teetime.NoCaddyAvailable)
      return;
    }
    if (this.assignedCaddies.length == 0) {
      this.assignedCaddies = _.filter(this.availableCaddies, (p) => {
        return _.includes(this.assignedCaddyIds, p.id);
      });
    }
    let filteredCaddies = this.availableCaddies.filter(c => !this.assignedCaddies.includes(c) || currentCaddyId == c.id);
    if (!currentCaddyType || filteredCaddies.length == 0) {
      this._utils.showError(this._localization.captions.teetime.NoCaddyAvailable)
      return;
    }
    const dialogRef = this.dialog.open(CaddySelectComponent, {
      width: '40%',
      height: '40%',
      disableClose: true,
      data: { availableCaddy: filteredCaddies, selectedCaddy: currentCaddyId, teetimeDateTime: this.teeTimeDateTime }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe((result: AvailableCaddy) => {
      if (result) {
        currentCaddySearch.setValue(result.availableName);
        this.rows.controls[idx].get('caddyNo').setValue(result.caddyNumber);
        this.rows.controls[idx].get('caddyId').setValue(result.id);
        this.playerTeeTimeSlotCaddyInfo[idx].caddyId = result.id;
        this.playerTeeTimeSlotCaddyInfo[idx].caddyTypeId = currentCaddyType;
        if (result) {
          this.validateCaddyAvailability();
        }
      }
    });
  }
  private async getAvailableCaddiesByType(caddyType: number, idx: number) {
    // To be filtered based on the type of caddy
    const is18Holes: boolean = this.playerTeeTimeSlotCaddyInfo[idx].holes == 18;
    this.availableCaddies = is18Holes ? this.allCaddies.filter(x => x.caddyTypeId.includes(caddyType) && x.isAvailableFor18Holes == is18Holes) : this.allCaddies.filter(x => x.caddyTypeId.includes(caddyType));
  }
  private async getCaddyTypes(courseId, slotTime, showInActive) {
    const all = {
      id: null,
      type: '',
      bagValue: 0,
      isActive: true,
      listOrder: '0'
    }
    this.caddyTypes = await this._caddyTypeSelectionPopupBusiness.getCaddyTypes(courseId, slotTime, showInActive);
    this.caddyTypes.caddyTypes.unshift(all);
    return this.caddyTypes;
  }

  isCaddyTypeColumnRequired(columnName: string) {
    const result = columnName === this.caption.caddyType && this.caddyTypes && this.caddyTypes.requirementExists
    return {
      'table-header-required': result
    };
  }

  closeHandler(arg) {
    this.dialogRef.close({ isCaddyAssigned: false, playerTeeTimeSlotCaddyInfo: this.playerTeeTimeSlotCaddyInfo });
  }
  saveHandler(event) {
    this.dialogRef.close({ isCaddyAssigned: true, playerTeeTimeSlotCaddyInfo: this.playerTeeTimeSlotCaddyInfo });
  }
}
