import { Injectable } from '@angular/core';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';
import { FeeInfoTax, RainCheckIssue } from 'src/app/tee-time-actions/teetime/fee-information/fee-information.model';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';

@Injectable()

export class IssueRainCheckService {

    constructor(private _http: GolfScheduleCommunication, private  _localization: GolfLocalization) {
    }

    public async getPlayerTaxes(teeTimeId: number): Promise<FeeInfoTax[]> {
        return await this._http.getPromise<FeeInfoTax[]>(
            { route: GolfApiRoute.GetRainCheckPlayerTaxes, uriParams: { teeTimeId } });
         
    }

    public getRainCheckByteeTimeId(teeTimeId: number): Promise<RainCheckIssue[]> {
        return this._http.getPromise<RainCheckIssue[]>(
            { route: GolfApiRoute.GetRainCheckIssueByTeeTime, uriParams: { teeTimeId } }, false);
         
    }

    public async DeleteIssuedRainCheck(rainCheckIds: number[]): Promise<string> {
        return await this._http.deletePromise<string>(
            { route: GolfApiRoute.DeleteIssueRainCheck, body: rainCheckIds }, false);
    }

    public CreateIssuedRainCheck(rainCheckIssuePlayers: RainCheckIssue[]): Promise<RainCheckIssue[]> {
        return this._http.postPromise<RainCheckIssue[]>(
            { route: GolfApiRoute.CreateIssueRainCheck, body: rainCheckIssuePlayers }, false);
       
    }
    
    public async GetRainChecks(issueddate:Date ,pattern:string) : Promise<RainCheckIssue[]> {
        return  await this._http.getPromise<RainCheckIssue[]>(
           {
               route:GolfApiRoute.GetRainChecks, 
               uriParams:{issuedDate : this._localization.convertDateObjToAPIdate(issueddate), pattern : pattern}
           }
           ,false
       );
       
    }

    public async UpdateRainCheck(settledRainCheck: RainCheckIssue) :Promise<void> {
        await this._http.putPromise<RainCheckIssue>(
        {
            route : GolfApiRoute.UpdateRainCheck,
            body : settledRainCheck
        }
        ,false);
    }
}