import { Injectable } from '@angular/core';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { API } from 'src/app/shared/models/teesheet.api.models';


@Injectable()
export class CaddyScheduleDataService {

  constructor(private _http: GolfScheduleCommunication, private localization: GolfLocalization) {
  }
  
  public async getCaddySchedulesForTeetime(courseId: number, teeTimeDateTime: Date, scheduleId: number): Promise<API.AvailableCaddy[]> {      
      const _date: string = this.localization.ConvertDateToISODateTime(teeTimeDateTime);
      return await this._http.getPromise<API.AvailableCaddy[]>(
      { route: GolfApiRoute.GetAllCaddyScheduleForTeeTime, 
        uriParams: { date: _date, courseId: courseId, scheduleId: scheduleId  } });
    
  }       
}
