<section id="linkMultipack" class="h-100">
<div *ngFor="let data of sessiondata; let j=index">
    <app-service-and-client-details [sections]="['service','client']" [showData]="data"></app-service-and-client-details>
    <form  class="linkMultipackForm">
        <div class="linkmultipack">

            <div id="SecOne" class="multipackTitle d-flex">
                <div class="pl-4 pt-3  multipackAvailable">
                    <span class="LWB14 text-uppercase">{{captions.MultipackAvailable}}</span>
                    <span>

                    </span>
                </div>
                <div class="pt-3  ml-auto showExpiredToggle" *ngIf="showExpiredToggle">
                    <span class="">
                        <label class="LW12 blckClr mr-2">{{captions.ShowExpiredMultipacks}}</label>
                        <!-- <app-toggle-switch  class="" [(ngModel)]="data.isExpired" (changeToggleEvent)=Show($event)></app-toggle-switch> -->
                        <ui-switch attr.automationId='Tog_LinkMultipack_overRidePlusArray{{j}}'  [ngModelOptions]="{standalone: true}"  [checked]="data.isExpired"
                        [(ngModel)]="data.isExpired" checkedLabel={{localization.captions.common.Yes}} uncheckedLabel={{localization.captions.common.No}} (change)="Show($event,data)"></ui-switch>
                    </span>
                </div>
            </div>
           <div class="sessionTable pl-4">
                    <div class="sessionRow" *ngFor="let session of data.sessionDetails; let i=index">
                        <div *ngIf="session.display" class="align-items-center d-inline-flex h-100 py-2 w-100"> <!--[ngClass]="[(Selectedpack.indexOf(session.id) !=-1) ? 'icon-success-icon color-numbers' : 'icon-Success unselected-icon',(isReadOnlyAccess || !session.enabled) ? 'button_invalid' : '']"-->
                            <i attr.automationId='Icn_LinkMultipack_success{{j}}' aria-hidden="true" class="multipackCheckboxSize pr-4 cursor"  [ngClass]="[(data.Selectedpack==session.id)? 'icon-success-icon color-numbers' : 'icon-Success unselected-icon',(isReadOnlyAccess || !session.enabled) ? 'button_invalid' : '']"
                                (click)="selectedItem(data,session)"></i>
                            <div class="float-right displayMultipack h-100 w-100"> 
                                <div class="float-left text-align-center header-col" [ngClass]="(data.Selectedpack==session.id) ? 'selected_bg': ''">
                                    <label class="multipackName textellipsis w-100 h-100 mb-0 LWB14" [matTooltip]="session.name">{{session.name}}</label>
                                </div>
                                <div class="float-right multipacksection-two h-100 pl-2">
                                    <!-- <div class=" col textellipsis ">
                                        <label class=" multipackKeyAlign LW12 ">{{captions.ServiceDescription}}</label>
                                        <label class="multipackValueAlign textellipsis LWB12" [matTooltip]="session.ServiceDescription">{{session.ServiceDescription}}</label>
                                    </div> -->
                                    <div class="  col textellipsis">
                                        <label class=" multipackKeyAlign LW12 ">{{captions.SoldTo}}</label>
                                        <label class="multipackValueAlign textellipsis LWB12" [matTooltip]="session.SoldTo">{{session.SoldTo}}</label>
                                    </div>
                                    <div class=" col textellipsis">
                                        <label class=" multipackKeyAlign LW12 ">{{captions.SoldOn}}</label>
                                        <label class="multipackValueAlign textellipsis LWB12" [matTooltip]="session.SoldOn">{{session.SoldOn}}</label>
                                    </div>
                                    <div class=" col textellipsis">
                                        <label class=" multipackKeyAlign LW12 ">{{captions.ValidUpto}}</label>
                                        <label class="multipackValueAlign textellipsis LWB12" [matTooltip]="session.ValidUpto">{{session.ValidUpto}}</label>
                                    </div>
                                    <div class=" col textellipsis">
                                        <label class=" multipackKeyAlign LW12 ">{{captions.RemainingSessions}}</label>
                                        <label *ngIf="session.RemainingSessions >= 0" class="multipackValueAlign textellipsis LWB12">{{session.RemainingSessions}}</label>
                                        <label *ngIf="session.RemainingSessions == -1" class="multipackValueAlign textellipsis LWB12">{{captions.Unlimited}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
            </div>

        </div>
    </form>
<hr />
</div>
</section>