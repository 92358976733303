import { Injectable } from '@angular/core';
import { playerType, WaitlistResponse } from './create-waitlist-modal';
import { PlayerTypeService } from 'src/app/shared/data-services/golfschedule/playertype.data.service';
import { CreateWaitlistApiData, WaitList, ContactInformation, Address } from '../waitlist.model';
import { CreateWaitlistModalService } from './create-waitlist-modal.service';
import { CourseDataService } from 'src/app/shared/data-services/golfschedule/course.data.service';
import { Course } from 'src/app/settings/golf-setup/code-setup/course/create-course-modal/create-course-model';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { MailTypes, PhoneTypes, ContactType, SettingModule, SettingScreen } from 'src/app/shared/global.constant';
import { SettingsDataService } from 'src/app/shared/data-services/golfschedule/settings.data.service';
import { API } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { AlertType, ButtonType, ContactDetails } from 'src/app/shared/shared-models';
import { RateTypeDataService } from 'src/app/shared/data-services/golfmanagement/ratetype.data.service';
import { OverrideType, PlayerAdditionalDetails, PlayerStayDetail } from 'src/app/tee-time-actions/teetime/tee-time.model';
import * as CodeLinkingAPI from 'src/app/settings/golf-setup/code-linking/code-link-modal';
import { CodeLinkingDataService } from 'src/app/shared/data-services/golfmanagement/codelinking.data.service';
import * as PlayerTypeAPI from 'src/app/settings/golf-setup/code-setup/player-type/player-type.modal';
import * as RateTypeAPI from 'src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal';
import * as PlayerTypeNew from 'src/app/tee-time-actions/teetime/player-details/player-info.model';
import { UserAccessBusiness } from 'src/app/shared/data-services/authentication/useraccess.business';
import { UserAccessModel } from 'src/app/shared/data-services/authentication/useraccess-model.model';
import { PlayerDetail } from 'src/app/tee-time-actions/teetime/tee-time.model';
import { TeeTimeDataService } from 'src/app/shared/data-services/golfschedule/TeeTime.data.service';
import { TeeTimesActionService } from 'src/app/shared/data-services/golfschedule/teeTimesAction.data.service';
import { PlayerProfileDataService } from 'src/app/shared/data-services/golfmanagement/PlayerProfile.data.services';
import { Tournament } from '../../tournaments/tournaments.model';

@Injectable()
export class CreateWaitlistModalBusiness {

  private _rateTypes: RateTypeAPI.RateType[] = [];

  constructor(private localization: GolfLocalization, private playerTypeService: PlayerTypeService, private createWaitlistModalService: CreateWaitlistModalService, private courseDataService: CourseDataService,
    private _settingsDataService: SettingsDataService, private _rateTypeDataService: RateTypeDataService,
    private _utlitlies: GolfUtilities, public _userAccessBusiness: UserAccessBusiness, private _codeLinkingDataService: CodeLinkingDataService,
    private _teeTimeDataService: TeeTimeDataService, private _teetimeActionService: TeeTimesActionService, private _playerProfileDataService: PlayerProfileDataService) { }

  patchData(obj) {
    let phoneChecker = obj.phoneNumber == '' ? '' : obj.apiData.contactInformation.find(y => y.value == obj.phoneNumber).type;
    let phoneType = obj ? phoneChecker : 1;
    let countryCodeSetter = (obj.phoneNumber == '' ? '' : (this._utlitlies.getCountryCodeFromValue(phoneType, obj.phoneNumber)));
    let phoneNumberSetter = (obj.phoneNumber == '' ? '' : (this._utlitlies.getPhoneNumberFromValue(phoneType, obj.phoneNumber)));
    let extensionSetter = (obj.extension == '' ? '' : (this._utlitlies.getExtensionFromValue(phoneType, obj.phoneNumber)));
    let emailTypeSetter = obj.emailId == '' ? '' : obj.apiData.contactInformation.find(y => y.value == obj.emailId).type;
    return {
      playerTypeRadio: obj ? obj.apiData.playerCategoryId : '',
      firstName: obj ? obj.apiData.firstName : '',
      lastName: obj ? obj.apiData.lastName : '',
      pronounced: obj ? obj.apiData.pronounced : '',
      phoneType: phoneType,
      countryCode: obj ? countryCodeSetter : '',
      phoneNumber: obj ? phoneNumberSetter : '',
      extension: obj ? extensionSetter : '',
      emailType: obj ? emailTypeSetter : 1,
      emailId: obj ? obj.emailId : '',
      playerType: obj ? obj.apiData.playerTypeId : '',
      rateType: obj ? obj.apiData.rateTypeId : '',
      course: obj ? obj.apiData.courseId : '',
      requestedDate: obj ? this.localization.getDate(obj.apiData.requestedDateTime) : '',
      requestedTime: obj ? this.localization.LocalizeTime(obj.apiData.requestedDateTime) : '',
      noOfPlayers: obj ? obj.numberOfPlayers : '',
      originalNoOfPlayers: obj ? obj.originalNumberOfPlayers : '',
      comments: obj ? obj.apiData.comment : '',
      hotelReservationNo: obj && obj.apiData && obj.apiData.playerCategoryId == 2 && obj.apiData.pmsConfirmationNumber ? (this.localization.captions.guest.players.ConfirmationNumber + ": " + obj.apiData.pmsConfirmationNumber) : '',
      id: obj ? obj.apiData.id : 0,
      playerLinkId: obj && obj.apiData.playerLinkId ? obj.apiData.playerLinkId : '',
      paymentReferenceId: obj && obj.apiData.paymentReferenceId ? obj.apiData.paymentReferenceId : 0,
      vipType: obj ? obj.apiData.vipType : null,
      memberNo: obj && obj.apiData.playerCategoryId == 3 && obj.memberId ? obj.memberId : '',
      bagNumer: obj && obj.apiData.playerCategoryId == 3 && obj.apiData.bagNumer ? obj.apiData.bagNumer : '',
      patronid: obj && obj.apiData && obj.apiData.loyaltyDetail && obj.apiData.loyaltyDetail.length > 0 ? obj.apiData.loyaltyDetail[0].patronId : '',
      rank: obj && obj.apiData && obj.apiData.loyaltyDetail && obj.apiData.loyaltyDetail.length > 0 ? obj.apiData.loyaltyDetail[0].rank : '',
      addressId: obj && obj.apiData && obj.apiData.playerAddress ? obj.apiData.playerAddress.id : 0,
      playerId: obj && obj.apiData && obj.apiData.playerAddress ? obj.apiData.playerAddress.playerId : 0,
      address: obj && obj.apiData && obj.apiData.playerAddress ? this.addressBindValue(obj.apiData.playerAddress) : null,
      city: obj && obj.apiData && obj.apiData.playerAddress ? obj.apiData.playerAddress.city : '',
      state: obj && obj.apiData && obj.apiData.playerAddress ? obj.apiData.playerAddress.state : '',
      country: obj && obj.apiData && obj.apiData.playerAddress ? obj.apiData.playerAddress.country : '',
      postal_code: obj && obj.apiData && obj.apiData.playerAddress ? obj.apiData.playerAddress.zip : '',
      addressLine1: obj && obj.apiData && obj.apiData.playerAddress ? obj.apiData.playerAddress?.addressLine1 : '',
      addressLine2: obj && obj.apiData && obj.apiData.playerAddress ? obj.apiData.playerAddress?.addressLine2 : '',
      addressLine3: obj && obj.apiData && obj.apiData.playerAddress ? obj.apiData.playerAddress?.addressLine3 : '',
      playerStayDetail: obj && obj.apiData && obj.apiData.playerStayDetail ? obj.apiData.playerStayDetail : null
    }
  }
  addressBindValue(arg) {
    let _addresslines = [];
    if (arg != null && arg.addressLine1 != null && arg.addressLine1.trim().length > 0) {
      _addresslines.push({ addressDetails: arg.addressLine1 });
    }
    if (arg != null && arg.addressLine2 != null && arg.addressLine2.trim().length > 0) {
      _addresslines.push({ addressDetails: arg.addressLine2 });
    }
    if (arg != null && arg.addressLine3 != null && arg.addressLine3.trim().length > 0) {
      _addresslines.push({ addressDetails: arg.addressLine3 });
    }
    return _addresslines;
  }
  patchcourse(courseid: string) {
    return {
      course: courseid
    }
  }

  public async getCourses(): Promise<Course[]> {
    const coursecollection: any = await this.courseDataService.getCoursesWithUserAccess();
    return coursecollection.map(c => {
      return {
        id: c.id,
        name: c.name
      }
    });
  }
  async ValidateBreakPoint(breakPointNumber: number, showPopup: boolean): Promise<UserAccessModel.BreakPointResult> {
    return await this._userAccessBusiness.getUserAccess(breakPointNumber, showPopup);
  }
  public showBreakPointPopup(functionName?: string, callBack?: any) {
    let message: string;
    message = `${this.localization.captions.common.BreakPointAccessDeniedMsg}
         <br><br>${this.localization.captions.common.Breakpoint}: ${functionName}`;

    this._utlitlies.showAlert(message, AlertType.AccessDenied, ButtonType.Ok, callBack);
  }

  public async getOriginalplayerType(): Promise<PlayerTypeAPI.PlayerType[]> {
    return await this.playerTypeService.getAllPlayerTypes();
  }

  async CreateWaitList(data: CreateWaitlistApiData, contactInfo: any, playerAdditionalDetails: PlayerAdditionalDetails[]): Promise<boolean> {
    const APIObj: WaitList = this.APIMapper(data, false, undefined, contactInfo, playerAdditionalDetails, true);
    return await this.createWaitlistModalService.CreateWaitList(APIObj);
  }


  async UpdateWaitList(data: CreateWaitlistApiData, ConvertWaitList: boolean, possible: any, contactInfo: any, playerAdditionalDetails: PlayerAdditionalDetails[], isFeeNegotiable : boolean): Promise<WaitlistResponse> {
    const APIObj: WaitList = this.APIMapper(data, ConvertWaitList, possible, contactInfo, playerAdditionalDetails,isFeeNegotiable);
    return await this.createWaitlistModalService.UpdateWaitList(APIObj);
  }

  private APIMapper(waitlist: CreateWaitlistApiData, ConvertWaitList: boolean, possible: any, contactInfo: any, playerAdditionalDetails: PlayerAdditionalDetails[], isFeeNegotiable : boolean): WaitList {
    let selectedTime: Date = this.localization.TimeToDate(this.localization.DeLocalizeTime(waitlist.requestedTime));
    let requestdate: Date = this.localization.AddTimeToDate(this.localization.getDate(waitlist.requestedDate), selectedTime);
    let address: Address = {
      id: 0,
      playerId: waitlist.playerId ? waitlist.playerId : 0,
      addressLine1: waitlist.address && waitlist.address[0] ? waitlist.address[0].addressDetails : '',
      addressLine2: waitlist.address && waitlist.address[1] ? waitlist.address[1].addressDetails : '',
      addressLine3: waitlist.address && waitlist.address[2] ? waitlist.address[2].addressDetails : '',
      city: waitlist.city,
      state: waitlist.state,
      country: waitlist.country,
      zip: waitlist.postal_code
    }

    let UIObj: WaitList = {
      id: waitlist.id,
      comment: waitlist.comments,
      courseId: parseInt(waitlist.course),
      firstName: waitlist.firstName,
      lastName: waitlist.lastName,
      pronounced: waitlist.pronounced,
      numberOfPlayers: parseInt(waitlist.noOfPlayers),
      originalNumberOfPlayers: waitlist.originalNoOfPlayers ? parseInt(waitlist.originalNoOfPlayers) : 0,
      rateTypeId: waitlist.rateType ? parseInt(waitlist.rateType) : 0,
      playerTypeId: waitlist.playerType ? parseInt(waitlist.playerType) : 0,
      allocationBlockId: waitlist.allocationBlockId ? waitlist.allocationBlockId : 0,
      greenFee: waitlist.greenFee ? waitlist.greenFee : 0,
      cartFee: waitlist.cartFee ? waitlist.cartFee : 0,
      isFeeNegotiable : isFeeNegotiable,
      requestedDateTime: this.localization.ConvertDateToISODateTime(requestdate),
      madeOn: this.localization.getDate(new Date),//waitlist.requestedTime,
      contactInformation: this.MapContactInfo(waitlist.phoneType, waitlist.countryCode, waitlist.phoneNumber, waitlist.extension, waitlist.emailType, waitlist.emailId, contactInfo),
      confirmationNumber: waitlist.confirmationNumber ? waitlist.confirmationNumber : "",
      //missing for controller
      playerCategoryId: waitlist.playerLinkId ? parseInt(waitlist.playerTypeRadio) : 1,
      playerLinkId: waitlist.playerLinkId ? waitlist.playerLinkId : "",
      paymentReferenceId: waitlist.paymentReferenceId ? waitlist.paymentReferenceId : 0,
      madeBy: this.GetUserInfoByKey("userName"),
      convertWaitList: ConvertWaitList,
      holeNumber: possible ? possible.holeNumber : "",
      bagNumber: waitlist.bagNumber ? waitlist.bagNumber : "",
      originalHoleNumber: possible ? possible.originalHoleNumber : "",
      overrideDetails: [],
      pmsConfirmationNumber: waitlist.pmsConfirmationNumber,
      playerAddress: address,
      playerAdditionalDetails: (playerAdditionalDetails && playerAdditionalDetails.length > 0) ? playerAdditionalDetails.map(x => {
        x.playerId = waitlist.playerId ? waitlist.playerId : 0;
        return x;
      }) : [],
      extProfileId: waitlist.extProfileId ? waitlist.extProfileId : '',
      platformUuId: waitlist.platformUuId ? waitlist.platformUuId:null,
      vipType: waitlist.vipType,
      playerStayDetail: waitlist.playerStayDetail && waitlist.playerStayDetail.reservationNumber ? this.mapPlayerStayDetail(waitlist.playerStayDetail, waitlist.playerId) : null
    }
    if (waitlist.isPlayerTypeDaysOutOverrided) {
      UIObj.overrideDetails.push({
        id: 0,
        scheduledTeeTimePlayerId: 0,
        overrideType: OverrideType.playerType,
        userId: waitlist.playerTypeDaysOutOverrideduserID
      });
    }
    if (waitlist.isRateTypeDaysOutOverrided) {
      UIObj.overrideDetails.push({
        id: 0,
        scheduledTeeTimePlayerId: 0,
        overrideType: OverrideType.rateType,
        userId: waitlist.rateTypeDaysOutOverrideduserID
      });
    }
    return UIObj;
  }

  MapContactInfo(phoneType: string, countryCode: string | number, phoneNumber: string, extension: string, emailType: string, emailId: string, contactDetails: ContactDetails[]): ContactInformation[] {
    const contactInfo: ContactInformation[] = [];
    if (phoneType && phoneNumber) {
      const phoneArr = [PhoneTypes.home, PhoneTypes.mobile, PhoneTypes.office];
      let contactInformation = contactDetails && contactDetails.length > 0 ? contactDetails.find(x => phoneArr.includes(x.type)) : undefined;
      let isPrivateInfo: boolean = (contactInformation) ? contactInformation.isPrivateInfo : false;
      contactInfo.push({
        id: 0,
        type: phoneType,
        name: ContactType.phone,
        description: `phone ${PhoneTypes[phoneType].toString()}`,
        value: this._utlitlies.buildPhoneNumber(Number(phoneType), extension, phoneNumber, countryCode),
        isPrivateInfo: isPrivateInfo
      });
    }

    if (emailType && emailId) {
      const emailArr = [MailTypes.personal, MailTypes.office];
      let contactInformation = contactDetails && contactDetails.length > 0 ? contactDetails.find(x => emailArr.includes(x.type)) : undefined;
      let isPrivateInfo: boolean = (contactInformation) ? contactInformation.isPrivateInfo : false;
      contactInfo.push({
        id: 0,
        type: emailType,
        name: ContactType.email,
        description: `email ${MailTypes[emailType].toString()}`,
        value: emailId,
        isPrivateInfo: isPrivateInfo
      });
    }

    return contactInfo;
  }

  public GetUserInfoByKey(name: string) {
    const nameEQ = name + '=';

    const propertyInfo = sessionStorage.getItem('_userInfo')
    if (propertyInfo != null) {
      const ca = propertyInfo.split(';');

      for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  public async getSystemSettings(): Promise<API.Settings<API.TeeTimeConfig>> {
    return await this._settingsDataService.getSettings<API.TeeTimeConfig>(SettingModule.SystemSetup, SettingScreen.TeeTime);

  }

  public getDefaultSettings(): PlayerTypeNew.DefaultsSettingConfig {
    let defaultsApiData: API.DefaultsSettingConfig = JSON.parse(sessionStorage.getItem('defaultSettings'));
    return this.mapDefaultData(defaultsApiData);
  }

  private mapDefaultData(configValue: API.DefaultsSettingConfig): PlayerTypeNew.DefaultsSettingConfig {
    return {
      blockingCode: configValue.blockingCode,
      memberStatus: configValue.memberStatus,
      memberPlayerType: configValue.memberPlayerType,
      memberRateType: configValue.memberRateType,
      nonMemberPlayerType: configValue.nonMemberPlayerType,
      nonMemberRateType: configValue.nonMemberRateType,
      resortPlayerType: configValue.resortPlayerType,
      resortRateType: configValue.resortRateType,
      memberGuestPlayerType: configValue.memberGuestPlayerType,
      memberGuestRateType: configValue.memberGuestRateType,
      memberGuestsToAdd: configValue.memberGuestsToAdd,
      memberGuestPlayerFirstName: configValue.memberGuestPlayerFirstName,
      memberGuestPlayerLastName: configValue.memberGuestPlayerLastName,
      defaultPlayerCategory: configValue.defaultPlayerCategory,
      defaultHotelReservationCategory: configValue.defaultHotelReservationCategory,
      defaultEmailTypeOption: configValue.defaultEmailTypeOption,
      defaultCountryCode: configValue.defaultCountryCode,
      defaultPhoneTypeOption: configValue.defaultPhoneTypeOption
    }
  }


  compareDate(date1: Date, date2: Date) {
    return this._utlitlies.ValidateDatesAreEqual(date1, date2);
  }

  async getRateTypes(): Promise<RateTypeAPI.RateType[]> {
    return await this._rateTypeDataService.getAllRateTypes(false);
  }

  public async getPackageLinkedPlayerTypesRateTypes(packageCode: string): Promise<CodeLinkingAPI.API.PackagePlayerTypesRateTypes> {
    return await this._codeLinkingDataService.getPackagePlayerTypesRateTypes(packageCode);

  }

  private mapPackageData(packageData: CodeLinkingAPI.API.PackagePlayerTypesRateTypes): PlayerTypeNew.PackagePlayerTypesRateTypes {
    return {
      id: packageData.id,
      packageCode: packageData.packageCode,
      packageName: packageData.packageName,
      playerTypes: packageData.playerTypes.map(x => this.mapPlayerTypeData(x)).sort((a, b) => (a.type.toLocaleLowerCase() > b.type.toLocaleLowerCase()) ? 1 : -1),
      rateTypes: packageData.rateTypes.map(x => this.mapRateTypeData(x)).sort((a, b) => (a.type.toLocaleLowerCase() > b.type.toLocaleLowerCase()) ? 1 : -1),
      isActive: packageData.isActive
    } as PlayerTypeNew.PackagePlayerTypesRateTypes;
  }
  private mapPlayerTypeData(playerTypeApiData: PlayerTypeAPI.PlayerType): PlayerTypeNew.PlayerTypes {
    return {
      id: playerTypeApiData.id,
      type: playerTypeApiData.type,
      daysOutStart: playerTypeApiData.daysOutStart,
      daysOutEnd: playerTypeApiData.daysOutEnd,
      guaranteeType: playerTypeApiData.guaranteeType
    } as PlayerTypeNew.PlayerTypes;
  }

  private mapRateTypeData(rateTypeApiData: RateTypeAPI.RateType): PlayerTypeNew.RateType {
    return {
      id: rateTypeApiData.id,
      type: rateTypeApiData.type,
      guaranteeType: rateTypeApiData.guaranteeType,
      daysOutStart: rateTypeApiData.daysOutStart,
      daysOutEnd: rateTypeApiData.daysOutEnd
    } as PlayerTypeNew.RateType;
  }

  public updatePlayerDetailInfoToGPS(waitlist: CreateWaitlistApiData, contactInfo: any) {
    let playerDetail: PlayerDetail = {} as PlayerDetail;
    playerDetail.firstName = waitlist.firstName;
    playerDetail.lastName = waitlist.lastName;
    playerDetail.contactInformation = [];
    let contactInformation = this.MapContactInfo(waitlist.phoneType, waitlist.countryCode, waitlist.phoneNumber, waitlist.extension, waitlist.emailType, waitlist.emailId, contactInfo);
    contactInformation.forEach(contact => {
      const _contacDetail: ContactDetails = {
        id: contact.id,
        type: parseInt(contact.type.toString()),
        value: contact.value,
        isPrimary: false,
        isPrivateInfo: contact.isPrivateInfo,
        name: contact.name,
        extension: ''
      }

      playerDetail.contactInformation.push(_contacDetail);
    });
    let address: Address = {
      id: waitlist.addressId ? waitlist.addressId : 0,
      playerId: waitlist.playerId ? waitlist.playerId : 0,
      addressLine1: waitlist.address && waitlist.address[0] ? waitlist.address[0].addressDetails : '',
      addressLine2: waitlist.address && waitlist.address[1] ? waitlist.address[1].addressDetails : '',
      addressLine3: waitlist.address && waitlist.address[2] ? waitlist.address[2].addressDetails : '',
      city: waitlist.city,
      state: waitlist.state,
      country: waitlist.country,
      zip: waitlist.postal_code
    }
    playerDetail.playerAddress = address;
    playerDetail.playerLinkId = waitlist.playerLinkId;
    playerDetail.paymentReferenceId = waitlist.paymentReferenceId;
    playerDetail.vipType = waitlist.vipType;
    playerDetail.pronounced = waitlist.pronounced;
    let playerDetails: PlayerDetail[] = [];
    playerDetails.push(playerDetail);
    const response = this._teeTimeDataService.UpdatePlayerToGPSInterface(playerDetails);
  }
  private mapPlayerStayDetail(playerStayDetail : PlayerStayDetail, playerId)
  {
    if (playerStayDetail)
    {
      playerStayDetail.playerId = playerId; 
    }
    return playerStayDetail;
  }
  public GetPlayerStayDetail(playerLinkId: string, scheduleDateTime: any, confirmationNumber: any): Promise<PlayerStayDetail> {
    return this._teetimeActionService.GetPlayerStayDetail(playerLinkId, scheduleDateTime, confirmationNumber)
  }
  public async GetExternalGuestProfile(selectedInfo: Tournament.UI.ContactInformation): Promise<Tournament.UI.ContactInformation> {
    let playerProfile = await this._playerProfileDataService.GetExternalGuestProfile(selectedInfo.extProfileId, false);
    selectedInfo.firstName = playerProfile.firstName;
    selectedInfo.lastName = playerProfile.lastName;
    selectedInfo.pronounced = playerProfile.pronounce;
    selectedInfo.gender = playerProfile.gender;
    selectedInfo.vipType = playerProfile.vipType;
    selectedInfo.guestId = playerProfile.id;
    selectedInfo.playerLinkId = playerProfile.id;
    selectedInfo.playerAddress = {
      addressLine1: playerProfile.playerProfileAddress ? playerProfile.playerProfileAddress.addressLine1 : '',
      addressLine2: playerProfile.playerProfileAddress ? playerProfile.playerProfileAddress.addressLine2 : '',
      addressLine3: playerProfile.playerProfileAddress ? playerProfile.playerProfileAddress.addressLine3 : '',
      city: playerProfile.playerProfileAddress ? playerProfile.playerProfileAddress.city : '',
      country: playerProfile.playerProfileAddress ? playerProfile.playerProfileAddress.country : '',
      state: playerProfile.playerProfileAddress ? playerProfile.playerProfileAddress.state : '',
      zip: playerProfile.playerProfileAddress ? playerProfile.playerProfileAddress.zipCode : '',
      playerId: 0
    };
    selectedInfo.contactInformation = playerProfile.playerProfileContactDetail && playerProfile.playerProfileContactDetail.length > 0 ? playerProfile.playerProfileContactDetail.map(c => {
      return {
        id: c.id,
        type: c.type,
        value: c.value,
        isPrimary: c.isPrimary,
        isPrivateInfo: c.isPrivate,
        name: c.name
      } as ContactDetails
    }) : null;
    return selectedInfo;
  }
}
