import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { LessonsModalService } from 'src/app/lessons/lessons-modal/lessons-modal.service';
import { PlayerContactService } from 'src/app/lessons/lessons-modal/player-contact/player-contact.service';
import { DefaultsSettingConfig } from '../../models/default-settings.models';
import { AgAddressFieldConfig, AgContactFieldConfig, PhoneDetail } from '../../shared-models';
import { GolfUtilities } from '../../utilities/golf-utilities';
import { PlayerDetailService } from '../player-detail/player-detail.service';
import { PlayerContactSharedBusiness } from './player-contact-shared.business';

@Component({
  selector: 'app-player-contact-shared',
  templateUrl: './player-contact-shared.component.html',
  styleUrls: ['./player-contact-shared.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [PlayerContactService, PlayerContactSharedBusiness, PlayerDetailService]
})
export class PlayerContactSharedComponent implements OnInit {

  @Output() formReady = new EventEmitter();
  captions: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  playerContactForm: UntypedFormGroup;
  addressInput: AgAddressFieldConfig;
  phoneInputs: AgContactFieldConfig;
  mailInputs: AgContactFieldConfig;
  phoneInfo: PhoneDetail[] = [];
  mailInfo: [] = [];
  addressValue;
  viewOnly = false;
  filteredCountries: Observable<any>;
  defaultSettings: any;
  automationId:string="";
  @Input('automationId')
  set setAutomationId(value: string) {
    this.automationId = value ? value : '';
  }
  @Input() parentForm: UntypedFormGroup;
  @Input('inputData')
  set formData(value : any) {
    if (value) {
      this.createPlayerContactForm();
      this.bindPlayerContact(value);
    } else {
      if (this.playerContactForm) {
        // this.playerContactForm.reset();
        this.initialize();
        this.phoneInfo = null;
        this.mailInfo = null;
        this.addressValue = null;
      }      
    }     
  }
  constructor(
    private fb: UntypedFormBuilder, private _PlayerContactBusiness: PlayerContactSharedBusiness,
    private utilities: GolfUtilities, private _playerDetailService: PlayerDetailService) { }

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    this.disableControls();
    this.defaultSettings = this._PlayerContactBusiness.getDefaultSettings();
    this.captions = this._PlayerContactBusiness.captions;
    this.createPlayerContactForm();
    // check for this.bindPlayerContact(); functionality  

    this.utilities.geCountriesJSON().then(res => 
      {
      this.filteredCountries = this.playerContactForm.controls.country.valueChanges.pipe(
        startWith(''),
        debounceTime(100),
        distinctUntilChanged(),
        map((country: string) => country ? this.utilities.FilterCountry(country, this.utilities.countryDetails) : [])
      );   
      this.playerContactForm.valueChanges.subscribe(res => {
          this.parentForm.controls.playerContactForm['controls'] = this.playerContactForm.controls;
          this.parentForm.controls.playerContactForm.updateValueAndValidity()
          this.parentForm.markAsDirty(); 
      });   
    }); 
      
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  createPlayerContactForm() {
    this.playerContactForm = this.fb.group({
      phone: this.fb.array([
        this.fb.group({
          id: [0],
          contactType: '',
          phoneType: this.defaultSettings && this.defaultSettings?.defaultPhoneTypeOption ? this.defaultSettings.defaultPhoneTypeOption : '',
          countryCode: this.defaultSettings && this.defaultSettings?.defaultCountryCode ? this.defaultSettings.defaultCountryCode : '',
          phoneNumber: '',
          primaryPhone: false,
          privateInformtionPhone: false
        })
      ]),
      email: this.fb.array([
        this.fb.group({
          id: [0],
          contactType: '',
          emailType: this.defaultSettings && this.defaultSettings?.defaultEmailTypeOption ? this.defaultSettings.defaultEmailTypeOption : '',
          emailId: '',
          primaryEmail: false,
          privateInformtionEmail: false
        })
      ]),
      address: this.fb.array([
        this.fb.group({
          addressDetails: ''
        })
      ]),
      postalCode: ['', Validators.maxLength(10)],
      state: '',
      city: ['', Validators.maxLength(50)],
      country: '',
      addressId: [0]
    });
    if (this.parentForm) {
      this.parentForm.addControl('playerContactForm', this.playerContactForm);
    }   
    this.formReady.emit(this.playerContactForm);
    this.addressInput = {
      className: 'golf-form-control--lg',
      form: this.playerContactForm,
      formControlName: 'addressDetails',
      automationId:this.automationId
    };
    this.mailInputs = {
      form: this.playerContactForm,
      formArrayName: 'email',
      showSwitches: true,
      automationId:this.automationId
    };
    this.phoneInputs = {
      form: this.playerContactForm,
      formArrayName: 'phone',
      showSwitches: true,
      automationId:this.automationId
    };
    this.playerContactForm.valueChanges.subscribe(res => {
      if (this.playerContactForm.controls['city'].value) {
        this.playerContactForm.controls.country.setErrors({ required: true });
      } else {
        this.playerContactForm.controls.country.setErrors(null);
      }
      if (this.playerContactForm.controls['country'].value &&
        !this.utilities.FilterCountryValueFromData(this.playerContactForm.controls['country'].value)) {
        this.playerContactForm.controls.country.setErrors({ invalid: true });
      } else if ((this.playerContactForm.controls['city'].value &&
        this.utilities.FilterCountryValueFromData(this.playerContactForm.controls['country'].value) &&
        this.playerContactForm.controls['country'].value) || (!this.playerContactForm.controls['city'].value &&
          !this.playerContactForm.controls['country'].value)) {
        this.playerContactForm.controls.country.setErrors(null);
      }
      this.playerContactForm.controls['country'].markAsTouched();
      if (this.playerContactForm.valid && this.playerContactForm.dirty) {
        this.parentForm.controls.playerContactForm['controls'] = this.playerContactForm.controls;
        this.parentForm.controls.playerContactForm.updateValueAndValidity()
        this.parentForm.markAsDirty(); 
      }
    });
  }

  bindPlayerContact(value) {
    let lessonBookingFormValues = value;
    const phoneArray = (this.playerContactForm.get('phone') as UntypedFormArray);
    const emailArray = (this.playerContactForm.get('email') as UntypedFormArray);
    const addressArray = (this.playerContactForm.get('address') as UntypedFormArray);
    phoneArray.clear();
    emailArray.clear();
    addressArray.clear();
    phoneArray.push(this.getPhoneGroup());
    emailArray.push(this.getMailGroup());
    addressArray.push(this.getAddressGroup());

    if (lessonBookingFormValues) {
      this.playerContactForm.patchValue(lessonBookingFormValues);
      this.phoneInfo = lessonBookingFormValues.phone;
      this.mailInfo = lessonBookingFormValues.email;
      this.addressValue = lessonBookingFormValues.address;
      this.playerContactForm.controls['phone'].updateValueAndValidity();
      // this._playerDetailService.lessonBookingFormValues.playerContactForm = this.playerContactForm.value;
      this.parentForm.controls.playerContactForm['controls'] = this.playerContactForm.controls;
      this.parentForm.controls.playerContactForm.updateValueAndValidity()
      this.parentForm.markAsDirty(); 
    }
  }

  private disableControls() {
    if (this._PlayerContactBusiness.isViewOnly) {
      this.viewOnly = true;
    }
  }

  private getPhoneGroup() {    
    return this.fb.group({
      id: [0],
      contactType: '',
      phoneType: this.defaultSettings && this.defaultSettings?.defaultPhoneTypeOption ? this.defaultSettings.defaultPhoneTypeOption : '',
      countryCode: this.defaultSettings && this.defaultSettings?.defaultCountryCode ? this.defaultSettings.defaultCountryCode : '',
      phoneNumber: '',
      primaryPhone: false,
      privateInformtionPhone: false
    });
  }

  private getMailGroup() {
    return this.fb.group({
      id: [0],
      contactType: '',
      emailType: this.defaultSettings && this.defaultSettings?.defaultEmailTypeOption ? this.defaultSettings.defaultEmailTypeOption : '',
      emailId: '',
      primaryEmail: false,
      privateInformtionEmail: false
    });
  }

  private getAddressGroup() {
    return this.fb.group({
      addressDetails: ''
    });
  }

}
