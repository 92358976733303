import { Injectable } from '@angular/core';
import { API, AllocationBlockWithPlayerType } from 'src/app/settings/golf-setup/code-setup/tee-time-allocation-block/tee-time-allocation-block.model';
import { GolfScheduleCommunication } from '../../communication/services/golfschedule.service';

@Injectable()
export class AllocationBlockDataService {

    constructor(private _http: GolfScheduleCommunication) {

    }  

    async getAllocationBlocks(_includeInactive: boolean): Promise<API.TeeTimeAllocationBlock[]> {
        return await this._http.getPromise<API.TeeTimeAllocationBlock[]>({
            route: GolfApiRoute.GetAllAllocationBlocks,
            uriParams: { includeInactive: _includeInactive + '' }
        });        
     
    }
    async getAllocationsBlockWithPlayerTypes(): Promise<AllocationBlockWithPlayerType[]> {
        return await this._http.getPromise<AllocationBlockWithPlayerType[]>({
            route: GolfApiRoute.GetAllocationBlockPlayerTypes
        });
    }
}
