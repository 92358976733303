import { DaysOfWeek, PeriodType } from "../configure-date-time/configure-date-time.model";

export interface DiscountType extends DiscountAdditionalProperties {
    id: number;
    type: string;
    isActive: boolean;
    listOrder: number;
    enableDiscountRules: boolean;
}

export interface DiscountTypeModel extends DiscountAdditionalProperties {
    id: number;
    discountType: string;
    active: boolean;
    listOrder: number;
    enableDiscountRules: boolean;
    discLevel?:string;
}

export interface DiscountAdditionalProperties{
    discountLevel: DiscountLevel;
    pricing: Pricing;
    isPercentage: boolean;
    amount?: number;
    minimumAmountInCart?: number;
    isExclusive?: boolean;
    tenderReducesDiscount?: boolean;
    allowAdditionalFlatDiscount?: boolean;
    discountTypeConfiguations ?: DiscountTypeConfiguration[];
    discountBarCode ? : DiscountBarCode[];
}

export enum DiscountLevel {
    Ticket = 1,
    Item
}

export enum Pricing {
    Open = 1,
    Fixed
}

export interface DiscountBarCode {
    id?: number;
    barCode: string;
    discountTypeId?: number;
}
export interface DiscountTypeConfiguration   {
    discountTypeId: number;
    startTime: string;
    endTime: string;
    rules: PeriodType;
    daysOfWeek: DaysOfWeek;
    isDeleted: boolean;
}

export interface DiscountConfiguration {
    id?: number;
    discountTypeId: number;
    retailCategoryId: number;
    discountValue: number;
    isPercentage: boolean;
}