import { Injectable } from '@angular/core';
import { RetailmanagementCommunication } from '../../communication/services/retailmanagement.service';
import { RetailItemSearchResults } from '../../models/retail-item.models';

@Injectable()
export class RetailItemDataService {
    constructor(private _retailManagement: RetailmanagementCommunication) {

    }

    public async searchRetailItems(pattern: string): Promise<RetailItemSearchResults> {
        return await this._retailManagement.putPromise<RetailItemSearchResults>({
            route: GolfApiRoute.SearchRetailItem,
            body: JSON.stringify(pattern)
        });       
    }

}