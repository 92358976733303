<form [formGroup]="customFormGrp">
    <div [ngSwitch]="type">
        <ng-container *ngSwitchCase="'CURRENCY'">
            <app-ag-currency (inputChange)='inputChanged($event)' (onInputBlur)="onBlur($event)" [inputs]="currencyFieldConfig">
            </app-ag-currency>

        </ng-container>

        <ng-container *ngSwitchCase="'NUMBER'">
            <app-ag-incremental (inputChange)='inputChanged($event)' (outputValue)="onBlurincremental($event)"  [inputs]="incrementalConfig">
            </app-ag-incremental>

        </ng-container>
        <ng-container *ngSwitchCase="'PERCENTAGE'">
            <app-ag-percentage (inputChange)='inputChanged($event)' (onInputBlur)="onPercentageBlur($event)"   [inputs]="percentConfig">
            </app-ag-percentage>

        </ng-container>
        <ng-container *ngSwitchCase="'EXCHANGECONVERSION'">
            <app-ag-exchangeconversion (inputChange)='inputChanged($event)' (onInputBlur)="onBlur($event)" [inputs]="exchangeConversionConfig">
            </app-ag-exchangeconversion>

        </ng-container>
        <ng-container *ngSwitchDefault>
            <ag-textbox [config]="inputConfig"></ag-textbox>

        </ng-container>
    </div>
</form>