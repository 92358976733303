import { Injectable } from "@angular/core";
import { TeeTimeSearchData, TeeTimeFilterData, course, StatusArray, PlayerPaymentstatus } from './search-model';
import { TeetimeSearchDataService } from 'src/app/shared/data-services/golfschedule/teetimesearch.data.services';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { CourseDataService } from 'src/app/shared/data-services/golfschedule/course.data.service';
import { TeeTimeDetail } from 'src/app/shared/models/teesheet.api.models';
import { TeeTimesActionService } from 'src/app/shared/data-services/golfschedule/teeTimesAction.data.service';
import _ from 'lodash';
import { GolfUserConfigDataService } from 'src/app/shared/data-services/golfmanagement/golfuser.config.data';
import { GolfPropertyInformation } from 'src/app/core/services/golf-property-information.service';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { RetailBussinessService } from 'src/app/shared/retail.bussiness';
import { API as setting } from 'src/app/settings/system-setup/tee-times/tee-times.modal';
import { SettingsDataService } from 'src/app/shared/data-services/golfschedule/settings.data.service';
import { SettingModule, SettingScreen } from 'src/app/shared/global.constant';
import { AlertType } from 'src/app/shared/shared-models';
import { RateTypeDataService } from 'src/app/shared/data-services/golfschedule/ratetype.data.service';
import { RateType } from 'src/app/settings/golf-setup/code-setup/rate-type/rate-type.modal';
import { TeeTimeActions } from "src/app/tee-time-actions/tee-time-action.base";
import { PlayerTypeService } from "src/app/shared/data-services/golfschedule/playertype.data.service";
import { PlayerType } from "src/app/settings/golf-setup/code-setup/player-type/player-type.modal";
@Injectable()
export class TeeTimeSearchBusiness {
  teeTimeSearchData: TeeTimeSearchData;
  teeTimeFilterData: TeeTimeFilterData;
  captions: any;
  statusArray: StatusArray[];
  selectedCourse: number;
  selectedDate: string;
  constructor(
    private _teetimesearchDataService: TeetimeSearchDataService,
    private _localization: GolfLocalization,
    private _utilities: GolfUtilities,
    private _courseDataService: CourseDataService,
    private _teeTimesActionService: TeeTimesActionService,
    private _golfUserDataService: GolfUserConfigDataService,
    private _propertyInformation: GolfPropertyInformation,
    private _retailService: RetailSharedVariableService,
    private _retailBusinessService: RetailBussinessService,
    private settingsDataService: SettingsDataService,
    private _rateTypeService: RateTypeDataService,
    private _playerTypeService: PlayerTypeService
  ) {
    this.captions = this._localization.captions.teetime;
    this.selectedCourse = this._retailService.SeletedCourseId;
    this.selectedDate = this._retailService.SelectedDate;
    this._retailBusinessService.ResetCourseTime();
  }

  public defaultDate: Date;

  public getDefaultDate() {
    this.defaultDate = this.selectedDate != "" ? this._localization.getDate(this.selectedDate) : this._propertyInformation.CurrentDTTM;
  }

  public async GetTeeTimesScheduleSearch(courseId: number, playerstatus: number, fromDate: Date | string, todate: Date | string, Filter: TeeTimeFilterData[], pmsActivityId: string = "", isChangePlayerCategory: boolean): Promise<TeeTimeSearchData[]> {
    let teeTimeSearchAPIModel: TeeTimeSearchData[] = await this._teetimesearchDataService.GetTeeTimesScheduleSearch(courseId, playerstatus, fromDate, todate, Filter, pmsActivityId, isChangePlayerCategory);
    teeTimeSearchAPIModel.forEach(t => t.playerName = this._utilities.formatGuestName(t.firstName, t.lastName));
    return teeTimeSearchAPIModel;
  }

  public async GetTeeTimeDetails(courseId: number, playerId: number, date: Date | string): Promise<TeeTimeDetail> {
    return await this._teetimesearchDataService.GetTeeTimeDetails(courseId, playerId, date);
  }

  public showBusinessError(err: any) {
    if (err.error && err.error.errorCode) {
      let code: number = parseInt(err.error.errorCode);
      let message: string = this._localization.getError(code);
      this._utilities.showError(message);
    } else {
      this._utilities.showError(err.message);
    }
  }

  public async getCourses(): Promise<course[]> {
    let course = await this._courseDataService.getCoursesWithUserAccess();
    course.unshift({ id: 0, defaultOutletId: 0, name: this.captions.All, avgPlay18Holes: 0, avgPlay9Holes: 0, isActive: true, listOrder: 0, resvRqd: false});
    return course.map(c => {
      return {
        id: c.id, description: c.name, type: 'course'
      }
    });
  }

  getStatusArray() {
    return this.statusArray = [
      { id: 0, name: this.captions.All },
      { id: PlayerPaymentstatus.unPaid, name: this.captions.Booked },
      { id: PlayerPaymentstatus.noShow, name: this.captions.NoShow },
      { id: PlayerPaymentstatus.cancelled, name: this.captions.Cancelled },
      { id: PlayerPaymentstatus.paid, name: this.captions.Paid },
      { id: PlayerPaymentstatus.CheckIn, name: this.captions.checkInSearchStatus },
      { id: PlayerPaymentstatus.CheckOut, name: this.captions.checkOutSearchStatus }
    ];
  }

  public removeNoShowAction(menuList: any[], scheduledDateTime: any): string[] {
    const propertyCurrentDateTime = new Date(this._localization.LocalizeDateTimeFormatSecondsDDMMMYYYYheader(this._localization.getCurrentDate()));
    const schDateTime = this._localization.getDate(scheduledDateTime);
    const propertyCurrentDateOnly = this._utilities.resetTime(_.clone(propertyCurrentDateTime));
    const schDateOnly = this._utilities.resetTime(_.clone(schDateTime));
    let teeConfig = JSON.parse(sessionStorage.getItem('TEETIMESETTING'));
    if (teeConfig?.allowNoShowPriortoPlay ? (propertyCurrentDateOnly.getDate() === schDateOnly.getDate()) :
    (propertyCurrentDateOnly.getTime() === schDateOnly.getTime() && propertyCurrentDateTime.getTime() > schDateTime.getTime()) 
    && !menuList.find(x=> x.key == TeeTimeActions.checkIn || x.key == TeeTimeActions.checkOut)) {
      return menuList;
    }
    else {
      return menuList.filter(a => a.description !== this.captions.noshow);
    }
  }

  public removeReBookAction(menuList: any[], scheduledDateTime: any): string[] {
    const propertyCurrentDateTime = new Date(this._localization.LocalizeDateTimeFormatSecondsDDMMMYYYYheader(this._localization.getCurrentDate()));
    const schDateTime = this._localization.getDate(scheduledDateTime);
    const propertyCurrentDateOnly = this._utilities.resetTime(_.clone(propertyCurrentDateTime));
    const schDateOnly = this._utilities.resetTime(_.clone(schDateTime));
    let teeConfig = JSON.parse(sessionStorage.getItem('TEETIMESETTING'));
    if (teeConfig?.allowNoShowPriortoPlay ? (propertyCurrentDateOnly.getDate() === schDateOnly.getDate()) :
      (propertyCurrentDateOnly.getTime() === schDateOnly.getTime() && propertyCurrentDateTime.getTime() > schDateTime.getTime())) {
      return menuList;
    }
    else {
      return menuList.filter(a => a.description !== this.captions.UnMarkNoShowDescription);
    }
  }

  public async GetTeeTimeDefaults(): Promise<setting.TeeTimeConfig> {
    const apiData = await this.settingsDataService.getSettings<setting.TeeTimeConfig>(SettingModule.SystemSetup, SettingScreen.TeeTime);
    return Promise.resolve(this.mapToUI(apiData.configValue));
  }

  public CheckPayTeeTimeDayOfPlay(actionClick: TeeTimeActions, isPayTeeTimeDayOfPlayEnabled, selectedDate: string): boolean {
    let infoCaption = actionClick === TeeTimeActions.payment ? this.captions.dayOnPlayInfo : this.captions.CannotMarkAsPaidUntilDayOfPlay;
        
    if (isPayTeeTimeDayOfPlayEnabled &&
        !this._utilities.IsPropertyDateGreaterThenDate(new Date(selectedDate))) {
            this._utilities.showAlert(infoCaption, AlertType.Info);
            return true;
    }
    return false;
  }

  private mapToUI(apiData: setting.TeeTimeConfig): setting.TeeTimeConfig {
    return {
      refreshTime: apiData.refreshTime,
      rainCheckNote: apiData.rainCheckNote,
      ticketNote: apiData.ticketNote,
      tempHoldDuration: apiData.tempHoldDuration,
      firstDeposit: apiData.firstDeposit,
      secondDeposit: apiData.secondDeposit,
      depositRefund: apiData.depositRefund,
      requirePlayerType: apiData.requirePlayerType,
      requireRateType: apiData.requireRateType,
      showMemberSignOnTeeGrid: apiData.showMemberSignOnTeeGrid,
      defaultToGraphicalTeeSheet: apiData.defaultToGraphicalTeeSheet,
      promptUserToPrintTeeTickets: apiData.promptUserToPrintTeeTickets,
      autoPrintTeeTickets: apiData.autoPrintTeeTickets,
      requireTeeTimesToPaid: apiData.requireTeeTimesToPaid,
      setPaidOnDayOfPlay: apiData.setPaidOnDayOfPlay,
      requireCheckInCheckOut:apiData.requireCheckInCheckOut,
      enableAutoCheck: apiData.enableAutoCheck,
      allowPaymentInCheckIn:apiData.allowPaymentInCheckIn,
      autoCheckInCheckOutZeroCharges:apiData.autoCheckInCheckOutZeroCharges,
      editCaddyInfoOnMoveCopy: apiData.editCaddyInfoOnMoveCopy,
      requireEformOnPaymentCheckIn: apiData.requireEformOnPaymentCheckIn,
      updateRatesForExistingBooking: apiData.updateRatesForExistingBooking,
      checkForTeeTimeOverlaps: apiData.checkForTeeTimeOverlaps,
      overlapHours:apiData.overlapHours
    };
  }

  public async getRateType(): Promise<RateType[]> {
    return await this._rateTypeService.getAllRateTypes(false);
  }
  public async getPlayerType(): Promise<PlayerType[]> {
    return await this._playerTypeService.getAllPlayerTypes();
  }

}
