import { GolfGatewayCommunication } from '../../communication/services/golfGateway';
import { GlobalSearchAggregate } from './global-search.model';
import { Injectable } from '@angular/core';

@Injectable()
export class GlobalSearchDataService {
    constructor(private _gateway: GolfGatewayCommunication) {

    }

    async globalSearch(pattern: string,isExternalGuestSearch : boolean = false): Promise<GlobalSearchAggregate> {
        return this._gateway.getPromise(
            {
                route: GolfApiRoute.GlobalSearch,
                uriParams: {pattern : pattern ,isExternalGuestSearch: isExternalGuestSearch}
            }
        )
    }

}