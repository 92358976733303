<section class="tee-time-slot--wrapper golf-container--padding" id="print">
    <div class="info-wrapper ng-star-inserted"><i class="icon-information"></i><span class="info-text">
            {{objCaption.deleteTeeTimeSlotWarning}}</span></div>
    <div class="p-5">
        <div class="players-info--wrapper">
            <div class="player-info--details" *ngFor="let playerData of modalHeaderInfo">
                <label class="player-info-values"><span *ngIf="playerData?.isTimeIcon"
                        class="icon-timepicker"></span>{{playerData.value}}</label>
            </div>
        </div>
        <div class="show-all-info--wrapper">
            <mat-checkbox [attr.automationId]="'Chk_deleteTeeTime_showAllopenslots'" (change)="OnChangeShowAllOpenSlots($event)" [value]="isShowAllOpenSlotChecked">
                <span><b>{{objCaption.showAllOpenSlots}}</b></span>
            </mat-checkbox>
        </div>
        <div>
            <button mat-button [attr.automationId]="'Btn_deleteTeeTime_scheduleTime'" (click)="OnMultiSelectbuttonSelected(button)" class="button--border-radius"
                *ngFor="let button of openedSlotDetails"
                [ngClass]="[button.isSelected? 'button--primary' : 'button--secondary', button.isTempHold?'golf-section__disable':'']">{{button['scheduleDateTime']
                |
                formatTime: 'LT'}} ({{button['originalHoleNumber']}})
            </button>
        </div>
        <div *ngIf="isShowAllOpenSlotChecked&&isOpenSlotsNotExistOnShowMore">
            <span class="warning"><i
                    class="icon-warning-info-icon ag_pr--2"></i>{{objCaption.noOpenSlotExistsWarning}}</span>
        </div>
    </div>
</section>