<ng-container [formGroup]="contactForm" *ngIf="arrayName; else singlePhone" class="phone-container">
  <div [formArrayName]="arrayName" class="position--relative"
    *ngFor="let phone of contactForm.get(arrayName)['controls']; let i= index; let first = first">
    <div [formGroupName]="i" class="phone-wrapper">
      <div class="ph-fixed-width--wrapper">
        <mat-form-field [floatLabel]="floatLabel" [ngClass]="typeClass">
          <mat-select attr.automationId='Dd_{{automationId}}_{{controlName+i}}' [placeholder]="placeHolder" id="phone{{i}}" [formControlName]="typeControlName"
            (selectionChange)="typeChange($event, phone, i)">
           <ng-container *ngIf="isEmpty">
            <mat-option></mat-option>
           </ng-container>
            <mat-option *ngFor="let phonetype of options" [value]="phonetype.id">
              {{phonetype.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- -->
         <label class="golf-form--label countryLabel">{{countryCodePlaceHolder}}</label>
          <mat-form-field [floatLabel]="floatLabel" [ngClass]="customClass1" *ngIf="isCountryCodeRequired"  #CountryCodeRef>
            <span *ngIf="CountryCodeRef && CountryCodeRef.value" class="position-absolute">+</span>
            <input attr.automationId='Txt_{{automationId}}_countryCode{{i}}' matInput [ngClass]="{'countryCodeInput': CountryCodeRef.value}" name="countryCode" formControlName="countryCode"
              [maxlength]="countryCodeLength" golfinputtype='nonnegative' (input)="setmandatoryField('countryCode',controlName,contactForm,i)" (blur)="setmandatoryField('countryCode',controlName,contactForm,i)">
              <mat-error class="Textnowrap" *ngIf="phone.get('countryCode').hasError('required')" title ="{{captions.Missing}} {{code}}" >
                {{captions.Missing}} {{code}}
              </mat-error>
            </mat-form-field>
            <mat-form-field [floatLabel]="floatLabel" [ngClass]="customClass">
              <input attr.automationId='Txt_{{automationId}}_{{controlName+i}}' matInput [name]="controlName" [formControlName]="controlName" [textmask]="textMaskPhone" (input)="setmandatoryField(controlName,'countryCode',contactForm,i)" (blur)="setmandatoryField(controlName,'countryCode',contactForm,i)"
                >
                <mat-error class="Textnowrap" *ngIf="phone.get(controlName).hasError('required')">
                 {{captions.missingPhone}}
                </mat-error>
                <mat-error *ngIf="phone.get(controlName).hasError('duplicate')">
                {{duplicateError}}
              </mat-error>
              <mat-error *ngIf="phone.get(controlName).hasError('minlength')">
                {{captions.invalidPhone}}
              </mat-error>
            </mat-form-field>
            <span attr.automationId='Icn_{{automationId}}_plus{{i}}' [ngClass]="{'ag_section--disable':isPhoneDisabled}" class="icon-plus" (click)="addPhoneItem(i,defaultphoneTypeObj,defaultCountrydialCode, '')" *ngIf="first"></span>
            <span attr.automationId='Icn_{{automationId}}_minus{{i}}' [ngClass]="{'ag_section--disable':isPhoneDisabled}" class="icon-minus" (click)="removePhoneItem(i)" *ngIf="!first"></span>

        <!---->


      </div>
      <ng-container *ngIf="showSwitches">
        <div class="ag-phone-switch">
          <label class="golf-form--label">{{captions.PrimaryPhone}}</label>
          <ui-switch attr.automationId='Tog_{{automationId}}_{{captions.PrimaryPhone.split(" ").join("").toLowerCase()+i}}' [ngClass]="{'ag_section--disable':isPhoneDisabled}" [formControl]="phone.get(primarySwitchName)" [checkedLabel]="captions.Yes" [uncheckedLabel]="captions.No"
            (change)="togglePrimaryContact(i)">
          </ui-switch>
        </div>
        <div class="ag-pl-5">
          <label class="golf-form--label">{{captions.PrivateInformation}}</label>
          <ui-switch attr.automationId='Tog_{{automationId}}_{{captions.PrivateInformation.split(" ").join("").toLowerCase()+i}}' [ngClass]="{'ag_section--disable':isPhoneDisabled}" [formControl]="phone.get(privateSwitchName)" [checkedLabel]="captions.Yes" [uncheckedLabel]="captions.No">
          </ui-switch>
        </div>
      </ng-container>
    </div>

    <div [formGroupName]="i" *ngIf="phone.get(typeControlName).value === phoneTypes.office && isExtensionRequired"
      class="d-flex ph-fixed-width--wrapper">
      <mat-form-field [floatLabel]="floatLabel" [ngClass]="extensionClass" class="ml-auto">
        <input attr.automationId='Txt_{{automationId}}_extension{{i}}' matInput name="extension" formControlName="extension" [placeholder]="extensionPlaceHolder"
          [maxlength]="extensionLength" golfinputtype='nonnegative'>
      </mat-form-field>
    </div>
  </div>
</ng-container>

<ng-template #singlePhone>
  <ng-container [formGroup]="contactForm">
    <div class="singleCodeWrapper">
    <mat-form-field [floatLabel]="floatLabel" [ngClass]="typeClass">
      <mat-select attr.automationId='Dd_{{automationId}}_{{controlName}}' [placeholder]="placeHolder" [formControlName]="typeControlName"
        (selectionChange)="typeChange($event, contactForm)">
        <ng-container *ngIf="isEmpty">
          <mat-option></mat-option>
         </ng-container>
        <mat-option *ngFor="let phonetype of options" [value]="phonetype.id">
          {{phonetype.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- -->
    <mat-form-field [floatLabel]="floatLabel" [ngClass]="customClass1" class="singleCode golf-form-control--xs">
      <span *ngIf="SingleCountryCodeRef.value" class="position-absolute">+</span>
      <input attr.automationId='Txt_{{automationId}}_countryCode' matInput [ngClass]="{'countryCodeInput': SingleCountryCodeRef.value}" name="countryCode" formControlName="countryCode" #SingleCountryCodeRef [placeholder]="countryCodePlaceHolder"
        [maxlength]="countryCodeLength" golfinputtype='nonnegative' (input)="setmandatoryField('countryCode',controlName,contactForm)" (blur)="setmandatoryField('countryCode',controlName,contactForm)">
        <mat-error *ngIf="contactForm.get('countryCode').hasError('required')">
          {{captions.Missing}} {{code}}
        </mat-error>
      </mat-form-field>
    <!---->
    <mat-form-field [floatLabel]="floatLabel" [ngClass]="customClass" class="singleCode golf-form-control--sm">
      <input attr.automationId='Txt_{{automationId}}_{{controlName}}' matInput [name]="controlName" [formControlName]="controlName" [textmask]="textMaskPhone"  (input)="setmandatoryField(controlName,'countryCode',contactForm)" (blur)="setmandatoryField(controlName,'countryCode',contactForm)">
      <mat-error *ngIf="contactForm.get(controlName).hasError('required')">
        {{captions.missingPhone}}
      </mat-error>
    </mat-form-field>
    <div *ngIf="contactForm.get(typeControlName).value === phoneTypes.office" class="d-flex">
      <mat-form-field [floatLabel]="floatLabel" [ngClass]="extensionClass" class="ml-auto">
        <input attr.automationId='Txt_{{automationId}}_extension' matInput name="extension" formControlName="extension" [placeholder]="extensionPlaceHolder"
          [maxlength]="extensionLength" golfinputtype='nonnegative'>
      </mat-form-field>
    </div>
  </div>
  </ng-container>
</ng-template>
