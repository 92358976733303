import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-contact-log-wrapper',
  templateUrl: './contact-log-wrapper.component.html',
  styleUrls: ['./contact-log-wrapper.component.scss']
})
export class ContactLogWrapperComponent implements OnInit {

  contactLogs : any;
  isPurged:boolean = false;
  @Input('inputData')
  set formData(value) {
    if (value) {
      this.contactLogs = value;
    }
  }

  @Output() ContactLogFormEmit = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  
  editRecord(eve){
    this.ContactLogFormEmit.emit([eve, true, 'contactLog']);
  }


  deleteRecord(eve){
    this.ContactLogFormEmit.emit([eve, true, 'contactLog']);
  }


  createRecord(eve){
    this.ContactLogFormEmit.emit([eve, true, 'contactLog']);
  }

}
