import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GolfUtilities } from 'src/app/shared/utilities/golf-utilities';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { GuestType } from '../create-player.model';
import { ButtonValue } from '../../models/button-type.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-confirm-players-popup',
  templateUrl: './confirm-players-popup.component.html',
  styleUrls: ['./confirm-players-popup.component.scss']
})
export class ConfirmPlayersPopupComponent implements OnInit {

  captions: any;
  guestTypes: { id: GuestType; name: string; description: any; }[];
  cancelButton: ButtonValue;
  applyButton: ButtonValue;
  guestTypeForm: UntypedFormGroup;
  selectedGuestType: number;

  constructor(public dialogRef: MatDialogRef<ConfirmPlayersPopupComponent>, private localization: GolfLocalization,
    @Inject(MAT_DIALOG_DATA) public dialogData,private _Utilities:GolfUtilities,private fb: UntypedFormBuilder) {
      this.captions = this.localization.captions;
    }

  ngOnInit(): void {
    this.selectedGuestType = this.dialogData;
    this.guestTypeForm = this.fb.group({
      guestType: this.selectedGuestType
    })
      this.guestTypes = [
        { id: GuestType.GolfGuest, name: 'golf', description: this.captions.golfGuest },
        { id: GuestType.OperaGuest, name: 'opera', description: this.captions.operaGuest },
      ]
    this.applyButton = { type: 'primary', label: this.captions.btn_apply, disabledproperty:false};
    this.cancelButton = { type: 'secondary', label: this.captions.cancel, disabledproperty:false};
  }

  guestTypeChange(e){
    this.selectedGuestType = e.value;
  }
  apply(){
    this.dialogRef.close(this.selectedGuestType);
  }
  cancel(){
    this.dialogRef.close(0);
  }

}
