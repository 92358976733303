import { Injectable } from '@angular/core';
import { NotificationData } from './send-notification-mail-sms.modal';
import { SendNotificationMailSMSService } from './send-notification-mail-sms.service';
@Injectable()

export class SendNotificationMailSMSBusiness {
    constructor(private _SendNotificationMailSMSService: SendNotificationMailSMSService) {
    }

    public async bulkSMSMailNotification(eMailId: string, phoneNo: string, teeAction: string, bookingId: string, notificationData: NotificationData): Promise<void> {
        return await this._SendNotificationMailSMSService.SMSMailNotification(eMailId, phoneNo, teeAction, bookingId,notificationData);
    }
}