import { Component, Input, ViewEncapsulation, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { CheckboxTableHeader, PaymentModalType } from '../../shared-models';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { RateType } from 'src/app/tee-time-actions/teetime/player-details/player-info.model';
import { DropdownOptions } from 'src/app/common/Models/ag-models';
import { MatMenuTrigger } from '@angular/material/menu';
import { TeeTimeCustomFeeUtilities } from 'src/app/tee-time-actions/tee-time-custom-fee.utilities';

@Component({
  selector: 'app-checkbox-table',
  templateUrl: './checkbox-table.component.html',
  styleUrls: ['./checkbox-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckboxTableComponent {
  _tableData;
  captions: any;
  inputSearch: string;
  isUpdatePlayer:boolean = false;
  @Input() tableForm: UntypedFormGroup;
  @Input() headers: CheckboxTableHeader[];
  @Input() directiveType;
  @Input() isCurrencyInput = false;
  @Input() isDisableEnabled = false;
  @Input() childTemplate: TemplateRef<any>;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  updatedRow: any;
  lastScrollTop = 0;
  startVal: number = 0;
  endValue: number = 30;
  listLength: number = 30;
  isSetDisable: boolean = false;
  @Input('tableData')
  set tableData(value) {    
    if (value ) {
      console.log(this.headers);
      console.log(value);
      this.tableForm.removeControl('allCheckBox');
      this.tableForm.removeControl('checkBoxItems');
      this.checkBoxItems = this.formBuilder.array([]);
      this._tableData = value;
      this._tableData.forEach((element, idx) => {
        this.addRow(element, idx);
      });
      console.log(this.checkBoxItems);
      const isEveryControlChecked = value && value.every(x => x.checked);
      this.tableForm.addControl('allCheckBox', new UntypedFormControl(isEveryControlChecked));
      this.isAllChecked = isEveryControlChecked;
      this.tableForm.addControl('checkBoxItems', this.checkBoxItems);
      this.startVal = 0;
      this.endValue = (this.tableForm.get('checkBoxItems')['controls'].length > this.listLength)? this.listLength: this.tableForm.get('checkBoxItems')['controls'].length;
    }
  }
  @ViewChild('inputText') inputText;
  @ViewChild('fieldWithPopover') fieldWithPopover;
  @ViewChild('playerdropdown') playerdropdown;
  @ViewChild('ratedropdown') ratedropdown;
  @ViewChild('negotiate') negotiate;
  checkBoxItems: UntypedFormArray;
  isAllChecked: boolean;
  isUpdatePlayerFlag: boolean = true;
  isCustomFeeEnabled: boolean = false;
  @Output() checkedData: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() unCheckedData: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() checkAllData: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() playerTypeChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() rateTypeChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() playerTypeRateTypeChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() dropdownChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() rateTypeClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updatePopoverEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  automationId: string = "CheckBoxTable";
  @Input('automationId')
  set setAutomationId(value: string) {
    this.automationId = value ? value : '';
  }

  constructor(private formBuilder: UntypedFormBuilder, 
    private localization: GolfLocalization,
  private _teeTimeCustomFeeUtilities:TeeTimeCustomFeeUtilities) {
    this.captions = this.localization.captions;
    this._teeTimeCustomFeeUtilities.IsCustomFeeConfigured().then(o => this.isCustomFeeEnabled = o);
  }

   checkTableValueWithHeader(value){
    if(value!=null){
      let tableKeys = Object.keys(value[0]);
      let headerKey = this.headers.filter(x=> x.key!= 'blockByUser' && x.key == 'Action' && !tableKeys.includes(x.key));
      console.log(headerKey);
      return headerKey.length == 0;
    }
    else false;
   }

  checkAll(arg) {
    this.checkBoxItems.controls.forEach(x=>{
      let isArgChecked =  arg.checked ; 
      if (x.get('isHold')!=null && x.get('isHold').value) {
        if(x.get('checked')!=null)x.get('checkBox').setValue(false);
        x.get('checked').setValue(false);
      } else {
        if(x.get('checked')!=null)x.get('checked').setValue(this.isDisableEnabled ? isArgChecked : arg.checked);
        x.get('checkBox').setValue(this.isDisableEnabled ? isArgChecked : arg.checked);
      }
    });
    // const value = this._tableData.map(x => {
    //   let isArgChecked = (arg.checked ? x.checked : arg.checked);
    //   if (x.isHold) {
    //     return {
    //       checkBox: false
    //     };
    //   } else {
    //     return {
    //       checkBox: this.isDisableEnabled ? isArgChecked : arg.checked
    //     };
    //   }
    // });
    this.checkBoxItems.markAsDirty();
    // this.checkBoxItems.patchValue(value);
    this.checkAllData.emit(arg.checked);
  }

  check(eve, rowData) { 
    let filteredarr = this.checkBoxItems.value.filter(x => !x.isHold)
    this.isAllChecked = filteredarr.every(x => x.checkBox);
    this.tableForm.get('allCheckBox').setValue(this.isAllChecked);
    if (!eve.checked) {
      this.unCheckedData.emit(rowData);
    } else {
      this.checkedData.emit(rowData);
    }
  }
  onclick(rowData){
    let rowidx = this._tableData.findIndex(x => x.playerId == rowData.playerId);
    let tableFormArr = this.tableForm.get('checkBoxItems') as UntypedFormArray;
    console.log(tableFormArr.controls[rowidx].get('isRateTypeDirty').value);
    let isDirty = tableFormArr.controls[rowidx].get('isRateTypeDirty').value;
    if(!isDirty)this.rateTypeClick.emit(rowData);
  }

  markRowDirty(rowData, isRateTypeChange = false) {
    let rowidx = this._tableData.findIndex(x => x.playerId == rowData.playerId);
    let tableFormArr = this.tableForm.get('checkBoxItems') as UntypedFormArray;
    tableFormArr.controls[rowidx].get('rateTypeId').markAsDirty();
    tableFormArr.controls[rowidx].get('rateTypeId').updateValueAndValidity();
    tableFormArr.controls[rowidx].get('playerTypeId').markAsDirty();
    tableFormArr.controls[rowidx].get('playerTypeId').updateValueAndValidity();
    if (isRateTypeChange) {
      this.rateTypeChange.emit(rowData);
    }
    else {
      this.playerTypeChange.emit(rowData);
    }
  }

  private addRow(x, idx) {
    const insertData = this.formBuilder.group({
      i: idx,
      id: x.id ? x.id : '',
      checkBox: x.checked ? x.checked : '',
      isIconClicked : x.isIconClicked ? x.isIconClicked : false
    });
    Object.keys(x).forEach((key) => {
      insertData.addControl(key, new UntypedFormControl(x[key]));
    });
    this.checkBoxItems.push(insertData);
  }

  emitDropdownData(event, row, header) {
    this.updatedRow = row['playerId'];
    if (header == 'playerTypeInfo') {
      this.markRowDirty(row);
    }
    else if (header == 'rateTypeInfo') {
      this.markRowDirty(row, true);
    }
    this.dropdownChange.emit(true);
  }

  getRateTypeOptions(rateTypes: RateType[], holeNumber = 0): DropdownOptions[] {
    // let isNineHole = holeNumber == 9 ? true : false;
    // .filter(r=> r.isNineHoles == isNineHole)
    return rateTypes.map((x) => {
      return {
        id: x.id,
        value: x,
        viewValue: x.type,
        checked: false
      } as DropdownOptions
    });
  }

  OnPlayerTypeRateTypeDropdownClick(row){
    this.updatedRow = row['playerId'];
    this.isSetDisable = true;
    this.updatePopoverEmitter.emit(this.isSetDisable);
  }
  
  payForm(e){

  }

  onAction(eve){
    this.isSetDisable = false;
    this.updatePopoverEmitter.emit(this.isSetDisable);
    if(eve.emitFrom == PaymentModalType.update && eve.form.dirty){
      let rowidx = this._tableData.findIndex(x => x.playerId == eve.rowObject.playerId);
      let tableFormArr = this.tableForm.get('checkBoxItems') as UntypedFormArray;
      tableFormArr.controls[rowidx].setValue(eve.rowObject);
      this.dropdownChange.emit(true);
    }
    else if(eve.emitFrom == PaymentModalType.close || eve.emitFrom == PaymentModalType.cancel){
      let rowidx = this._tableData.findIndex(x => x.playerId == eve.rowObject.playerId);
      let tableFormArr = this.tableForm.get('checkBoxItems') as UntypedFormArray;
      tableFormArr.controls[rowidx].setValue(eve.rowObject);
    }
    this.close();
  }

  close() {
    this.isUpdatePlayer = false;
    this.trigger.closeMenu(); // <-- put this in your dialog open method
  }

  updateFromScroll(event){
    // visible height + pixel scrolled >= total height
    // let scrollStart = event.target.scrollTop; 
    // //Bottom Hit
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      // if (scrollStart > this.lastScrollTop) {
        // downscroll code
        if(this.endValue < this.tableForm.get('checkBoxItems')['controls'].length - this.listLength){
          // this.startVal +=20;
          // this.startVal += this.listLength;
          this.endValue += this.listLength;
        } else {
          // this.startVal = this.endValue + 1;
          this.endValue = this.tableForm.get('checkBoxItems')['controls'].length;
        }
        // event.target.scrollTop = 0;
      // } 
    }
    // else if(event.target.scrollTop < 0){
    //   if (scrollStart < this.lastScrollTop) {
    //     // upscroll code
    //     if(this.startVal - this.listLength <= 0){
    //       this.startVal = 0;
    //       this.endValue = (this.tableForm.get('checkBoxItems')['controls'].length > this.listLength)? this.listLength: this.tableForm.get('checkBoxItems')['controls'].length;
    //     } else {
    //       if((this.startVal -  this.endValue) > this.listLength){
    //         this.startVal -= this.listLength;
    //         this.endValue -= (this.listLength + (this.startVal -  this.endValue));
    //       }
    //       else {
    //         this.startVal -= this.listLength;
    //         this.endValue -= this.listLength;
    //       }
    //     }
    //     // event.target.scrollTop = 0;
    //  }
      
    // }
    // this.lastScrollTop = scrollStart <= 0 ? 0 : scrollStart; // For Mobile or negative scrolling
  }

}
