<section>
  <form [formGroup]="recurringFormGroup">
    <app-golfmulti-select-button [automationId]="automationId" [title]="captions.Recurring" [type]="'single'" [data]="recurringData"
      [defaultSelected]="defaultRecurringData" (buttonSelected)="buttonSelectionChange($event)">
    </app-golfmulti-select-button>

    <ng-container [ngSwitch]="recurringType">
      <ng-template [ngSwitchCase]="recurringTypeConst.daily">
        <div formGroupName="daily" class="recurring-display">
          <mat-form-field class="recurring-every" [floatLabel]="floatLabel">
            <input attr.automationId='Txt_{{automationId}}_every' matInput type="text" [placeholder]="captions.Every" formControlName="every" golfinputtype="nonnegative,nodecimal"
              min="1" maxLength="15" numberMaxlength (input)="dailyInputChange($event)" />
            <mat-error
              *ngIf="recurringFormGroup.controls['daily']['controls'].every.hasError('required') && recurringFormGroup.controls['daily']['controls'].every.touched && !recurringFormGroup.controls['daily']['controls'].every.valid">
              {{errorCaptions.MissingRepeatDays}}</mat-error>
              <mat-error
              *ngIf="recurringFormGroup.controls['daily']['controls'].every.hasError('max') && 
              recurringFormGroup.controls['daily']['controls'].every.touched && 
              !recurringFormGroup.controls['daily']['controls'].every.valid">
              {{errorCaptions.ValidRangeDaily}}</mat-error>
          </mat-form-field>
          <span class="ag-p-6">{{captions.Days}}</span>
        </div>
      </ng-template>
      <ng-template [ngSwitchCase]="recurringTypeConst.weekly">
        <div formGroupName="weekly">
          <div class="recurring-display">
          <mat-form-field class="recurring-every" [floatLabel]="floatLabel">
            <input matInput [placeholder]="captions.Every" formControlName="every" golfinputtype="nonnegative,nodecimal" min="1" maxLength="15" numberMaxlength
              (input)="weeklyonoccurence()" />
            <mat-error
              *ngIf="recurringFormGroup.controls['weekly']['controls'].every.hasError('required') && recurringFormGroup.controls['weekly']['controls'].every.touched && !recurringFormGroup.controls['weekly']['controls'].every.valid">
              {{errorCaptions.MissingRepeatWeeks}}</mat-error>
              <mat-error
              *ngIf="recurringFormGroup.controls['weekly']['controls'].every.hasError('max') && recurringFormGroup.controls['weekly']['controls'].every.touched && !recurringFormGroup.controls['weekly']['controls'].every.valid">
              {{errorCaptions.ValidRangeWeekly}}</mat-error>
          </mat-form-field>
          <span class="ag-p-6">{{captions.Weeks}}</span>
        </div>
        <div>
          <app-golfmulti-select-button [isEnableAll]="true" [type]="'days'" [defaultSelected]="selectedWeeklyDays"
           [data]="weeklyData" (buttonSelected)="weeklyButtonSelectionChange($event)">
          </app-golfmulti-select-button>
           <mat-error class="mat-error--fontsize ag-mb-2" *ngIf="isDayMissing">{{errorCaptions.MissingDayofWeek}}
          </mat-error>
        </div>
        </div>
      </ng-template>
      <ng-template [ngSwitchCase]="recurringTypeConst.monthly">
        <div formGroupName="monthly">
          <div class="recurring-display">
            <mat-form-field class="recurring-every" [floatLabel]="floatLabel">
              <input matInput [placeholder]="captions.Every" formControlName="every" golfinputtype="nonnegative,nodecimal" min="1" maxLength="15" numberMaxlength
                (input)="emitData()" />
              <mat-error
                *ngIf="recurringFormGroup.controls['monthly']['controls'].every.hasError('required') && recurringFormGroup.controls['monthly']['controls'].every.touched && !recurringFormGroup.controls['monthly']['controls'].every.valid">
                {{errorCaptions.MissingRepeatMonths}}</mat-error>
                <mat-error
                *ngIf="recurringFormGroup.controls['monthly']['controls'].every.hasError('max') && recurringFormGroup.controls['monthly']['controls'].every.touched && !recurringFormGroup.controls['monthly']['controls'].every.valid">
                {{errorCaptions.ValidRangeMonthly}}</mat-error>
            </mat-form-field>
            <span class="ag-p-6">{{captions.Months}}</span>
          </div>
          <div formGroupName="monthlyData">
            <div>
              <mat-radio-group class="golf__radio" formControlName="monthlytype" (change)="monthlytypeChange($event)">
                <mat-radio-button class="golf--form-radio-button golf-radio--horizontal"
                  *ngFor="let selectionType of monthSelectionType" [value]="selectionType.id"
                  [checked]="selectionType.checked">
                  {{selectionType.name}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <ng-container
              *ngIf="this.recurringFormGroup.controls['monthly']['controls'].monthlyData.get('monthlytype').value == 1">
              <div>
                <app-golf-date-picker [inputs]="startDateInputs"></app-golf-date-picker>
              </div>
            </ng-container>

            <ng-container
              *ngIf="this.recurringFormGroup.controls['monthly']['controls'].monthlyData.get('monthlytype').value == 2">
              <div>
                <mat-form-field [floatLabel]="floatLabel">
                  <mat-select formControlName="onoccurence" [placeholder]="captions.On" (selectionChange)="emitData()"
                    (openedChange)="monthlyonoccurence()">
                    <mat-option *ngFor="let nth of onoccurence" [value]="nth.id">
                      {{nth.name}}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="recurringFormGroup.controls['monthly']['controls'].monthlyData['controls'].onoccurence.hasError('required') && recurringFormGroup.controls['monthly']['controls'].monthlyData['controls'].onoccurence.touched && !recurringFormGroup.controls['monthly']['controls'].monthlyData['controls'].onoccurence.valid">
                    {{errorCaptions.MissingRepeatDay}}</mat-error>
                </mat-form-field>
              </div>
              <div>
                <app-golfmulti-select-button [isEnableAll]="true" [type]="'days'" [data]="weeklyData" [defaultSelected]="selectedMonthlyDays"
                  (buttonSelected)="monthlyButtonSelectionChange($event)">
                </app-golfmulti-select-button>
                 <mat-error class="mat-error--fontsize ag-mb-2" *ngIf="isDayMissing">{{errorCaptions.MissingDayofWeek}}
                </mat-error>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-template>
      <ng-template [ngSwitchCase]="recurringTypeConst.yearly">
        <div formGroupName="yearly">
          <div class="recurring-display">
            <mat-form-field class="recurring-every" [floatLabel]="floatLabel">
              <mat-select formControlName="every" [placeholder]="captions.Every"
                (selectionChange)="YearlyButtonSelectionChange($event)">
                <mat-option *ngFor="let month of months" [value]="month.id">
                  {{month.name}}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="recurringFormGroup.controls['yearly']['controls'].every.hasError('required') && recurringFormGroup.controls['yearly']['controls'].every.touched && !recurringFormGroup.controls['yearly']['controls'].every.valid">
                {{errorCaptions.MissingMonth}}</mat-error>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field [floatLabel]="floatLabel">
              <mat-select formControlName="onoccurence" [placeholder]="captions.On" (selectionChange)="emitData()"
                (openedChange)="yearlyonoccurence()">
                <mat-option *ngFor="let nth of onoccurence" [value]="nth.id">
                  {{nth.name}}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="recurringFormGroup.controls['yearly']['controls'].onoccurence.hasError('required') && recurringFormGroup.controls['yearly']['controls'].onoccurence.touched && !recurringFormGroup.controls['yearly']['controls'].onoccurence.valid">
                {{errorCaptions.MissingRepeatDay}}</mat-error>
            </mat-form-field>
          </div>
          <div>
            <app-golfmulti-select-button [isEnableAll]="true" [type]="'days'" [data]="weeklyData" [defaultSelected]="selectedYearlyDays"
              (buttonSelected)="YearlyButtonSelectionChanged($event)">
            </app-golfmulti-select-button>
            <mat-error class="mat-error--fontsize ag-mb-2" *ngIf="isDayMissing">{{errorCaptions.MissingDayofWeek}}
            </mat-error>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </form>
</section>