<section class="h-100 select-popup-container w-100" [formGroup]="outletForm">
  <div class=" dialog-container">
    <div mat-dialog-title class="dialog-container__header">
      <span class="dialog-container__header--title">{{dialogData.title}}</span>
      <span class="dialog-container__header--close icon-Cancel" (click)="close()"></span>
    </div>
    <div class="dialog-container__content ag_container--padding-sm">
      <label class="ag_mb--6">{{captions.lbl_selectDefaultOutlet}}</label>
      <!-- <app-ag-dropdown id="ag-automation-select-availablecards-selectContact" [inputs]="OutletConfig">
      </app-ag-dropdown> -->
      <mat-form-field class="ag_form-control--66" [floatLabel]="floatLabel">
          <mat-select formControlName="defaultOutlet" [placeholder]="captions.shop.Outlet"
            (selectionChange)="outletChange($event)" required>
            <mat-option *ngFor="let option of outlets | async" [value]="option.subPropertyID">{{option.subPropertyName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
    </div>
      <div class="dialog-container__actions">
        <button mat-button mat-flat-button color="primary" class="actions__save float-right" (click)="onSave()"
        [disabled]="isSaveDisabled">{{dialogData.update}}</button>
        <button mat-button [appDisableDoubleClick]=1 (click)="onCancel()">{{dialogData.cancel}}</button>
      </div>
  </div>
  
</section>