<section class="header-input">
    <form [formGroup]="ActionForm" autocomplete="off">
      <mat-form-field [floatLabel]="floatLabel" class="ag_form-control">
        <input matInput formControlName="name" [inputtype]='nameInputType' nowhitespace [placeholder]="name" required [minlength]="nameMinLength"
          [maxlength]="nameMaxLength">
        <mat-error *ngIf="ActionForm.get('name').hasError('required')" [attr.LiteralID]="'lbl_missingRoleName'" >{{captions.lbl_missingRoleName}}</mat-error>
      </mat-form-field>
    </form>
    <span *ngIf="isUpdate">
      <app-ag-toggle [toggleInputs]="toggleInput">
      </app-ag-toggle>
    </span>
    <div  class="ag_pb--4 ag_pt--4 ag_display--flex">
      <app-button class="ag_form-control" [buttontype]="saveButton" (valueChange)='onSave($event)'></app-button>
      <app-button class="ag_form-control" [buttontype]="cancelButton" class="ag-ml-2" (valueChange)='onCancel($event)'>
      </app-button>
    </div>
  </section>
  
