<section class='create-player newVersionWrapper'>
    <div class="create-player-wrapper">
        <div class="create-player-form-group matTabWrap">          
          <div [matTooltip]="captions.linkExistingProfile" *ngIf=" dialogData.componentDetails.popUpDetails.showActionButton && isEdit && selectedIndex == 0" [ngClass]="[isExpand ? 'icon-summary_close_24px' : 'icon-summary_open_24px', _CreatePlayerBusiness.disableGuestLink || !_stepperService.isExternalGuestSearch ? 'ag_section--disable' : '']" class="boxed-icon cursor" (click)="expand()"></div>     
          <mat-tab-group [attr.automationId]="'Mt_CreatePlayer_tabs'" #tabGroup [selectedIndex]="selectedIndex" (selectedTabChange)='tabChanged($event)' [ngClass]="{'expand':dialogData.componentDetails.popUpDetails.showActionButton && isEdit && selectedIndex==0}">
            <mat-tab>
              <ng-template mat-tab-label> {{tabsArrayDatas[0].label}} </ng-template>
              <ng-container>
                <app-player-information [guestId]="guestId" (PlayerInfoFormEmit)='getCreatePlayerFormEmit($event)' [isExpand]="isExpand"
                 (imageUpdateEmit)="onImageUpdates($event)" (convertToEdit)="convertToEdit($event)"[defaultSettings]="dialogData.defaultSettings" [inputData]="PlayerInfoInput" [isPlayerEdited] = "isPlayerEdited" [inputCmsPatronId]="cmsPatronId">
                </app-player-information>
              </ng-container>
            </mat-tab>           
            <mat-tab>
              <ng-template mat-tab-label> {{tabsArrayDatas[1].label}} </ng-template>
              <ng-container>
                <app-additional-information (AdditionalInfoFormEmit)='getCreatePlayerFormEmit($event)' [IsGDPREnabled] = 'IsGDPREnabled' [policyType] = 'policyType' [inputData]="PlayerInfoInput" (RemoveCard)='removeCardDetails($event)'></app-additional-information>
              </ng-container>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label> {{tabsArrayDatas[2].label}} </ng-template>
              <ng-container>
                <app-history [inputData]="PlayerInfoInput"></app-history>
              </ng-container>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label> {{tabsArrayDatas[3].label}} </ng-template>
              <ng-container>
                <div class="cus-pad">
                  <app-common-itinerary [guestId]="guestId" (guestEditData)="openGuestTeeTime($event)" (viewRentalRetail) = "getRentalItems($event)" [rentalRetailItems] = "rentalRetailItems"></app-common-itinerary>
                </div>
              </ng-container>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label> {{tabsArrayDatas[4].label}} </ng-template>
              <ng-container>
                <div class="h-100">
                  <app-contact-log-wrapper [inputData]="PlayerContactLogInputList"  (ContactLogFormEmit)='getCreatePlayerFormEmit($event)'></app-contact-log-wrapper>
                </div>
              </ng-container>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>


</section>  
