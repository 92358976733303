import { Injectable } from "@angular/core";
import { Subscription, Observable, Subject, timer, ReplaySubject } from "rxjs";
import { LoginCommunicationService } from "./login-communication.service";
import { ManageSessionService } from "./manage-session.service";
import { GolfUtilities } from "src/app/shared/utilities/golf-utilities";
import { Router } from "@angular/router";
import moment from "moment";
import { GolfRoutes } from "../core/extensions/golf-route";
import { GolfLocalization } from "../core/localization/golf-localization";
import { AlertType, ButtonType } from "../shared/shared-models";
import { CartUtilities } from "../common/components/menu/vcart/cart.utilities";
import { MatDialog } from '@angular/material/dialog';
import { AlertPopupWithTimerComponent } from 'src/app/common/shared/shared/alert-popup-with-timer/alert-popup-with-timer.component';
import { AutologoffTimerService } from 'src/app/common/shared/shared/autologoff-timer-service';

@Injectable({
  providedIn: "root",
})
export class ExpireSessionService {
  private _count: number = 0;
  private _serviceId: string =
    "idleTimeoutSvc-" + Math.floor(this.utils.getRandomDecimal() * 10000);
  private _autoLogOff: boolean = false;
  private _logOffAfter: number;
  private triggerTimeout: any;
  private _timeoutSeconds: number;
  private timerSubscription: Subscription;
  private timer: Observable<number>;
  public resetOnTrigger: boolean = false;
  public timeoutExpired: Subject<number> = new Subject<number>();
  public tokenTimerSubscription: Subscription;
  private tokenTimer: Observable<number>;
  enableAlert: boolean = false;
  tenantId: any;
  userSessionId: string = "userSession";
  logOutWaitingtime: number = 120000; //milliseconds
  subject = new ReplaySubject<number>(2);
  _subscription : Subscription;
  idealTime:boolean = false;
  autoLogoffTimer:any;
  isIdealDialogOpen:boolean = false;

  constructor(
    private loginService: LoginCommunicationService,
    private manageSessionService: ManageSessionService,
    private utils: GolfUtilities,
    private cartUtils: CartUtilities,
    private router: Router,
    private localization: GolfLocalization
    , public dialog: MatDialog, private autoLogOfftimerService: AutologoffTimerService
  ) {
    this.timeoutExpired.subscribe();
    if (this.tokenTimerSubscription) {
      this.tokenTimerSubscription.unsubscribe();
    }
  }
  public startTimer(logOffAfter: any, tokenExpiry: number) {
    if (logOffAfter != 0) {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      this._timeoutSeconds = logOffAfter * 60;
      this.timer = timer(this._timeoutSeconds * 1000);
      this.timerSubscription = this.timer.subscribe((n) => {
        this.idealTime = true;
        this.IdealtimerComplete();
      });
    }

    if (logOffAfter == 0) {
      if (tokenExpiry > 122) {
        tokenExpiry = tokenExpiry - 122; // buffer time for token expiry
      }
      this.tokenTimer = timer(tokenExpiry * 1000);
      this.tokenTimerSubscription = this.tokenTimer.subscribe((n) => {
        this.timerComplete(n, (this.enableAlert = true));
      });
    }
  }

  //Below method called after refresh.
  public forceLogOff() {
    let jwtExpiryTime: any = sessionStorage.getItem("jwtExpiryTime");
    jwtExpiryTime = new Date(jwtExpiryTime);
    let currentTime: any = new Date();
    let expirySeconds = jwtExpiryTime - currentTime;
    if (!sessionStorage.getItem("popupEnabled")) {
      if (this.tokenTimerSubscription) {
        this.tokenTimerSubscription.unsubscribe();
      }
      if (expirySeconds > this.logOutWaitingtime) {
        expirySeconds = expirySeconds - this.logOutWaitingtime;
      }
      this.tokenTimer = timer(expirySeconds);
      this.tokenTimerSubscription = this.tokenTimer.subscribe((n) => {
        this.timerComplete(n, (this.enableAlert = true));
      });
    } else {
      if (expirySeconds > 0) {
        this.triggerTimeout = setTimeout(() => {
          this.logout();
        }, expirySeconds);
      } else {
        this.logout();
      }
    }
  }

  public stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    if (this.tokenTimerSubscription) {
      this.tokenTimerSubscription.unsubscribe();
    }
  }
  public resetTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    this.timer = timer(this._timeoutSeconds * 1000);
    this.timerSubscription = this.timer.subscribe((n) => {
      !this.idealTime? this.timerComplete(n,false) : this.IdealtimerComplete();
    });
  }
  private timerComplete(n: number, displayAlert: boolean) {
    if (!this.cartUtils.isEmbed()) {
      this.timeoutExpired.next(++this._count);
      this.stopTimer();
      if (!displayAlert) {
        this.logout();
      } else {
        this.utils.showAlert(
          this.localization.captions.common.AutologoffWarning,
          AlertType.Warning,
          ButtonType.Ok
        );
        sessionStorage.setItem("popupEnabled", "true");
        this.triggerTimeout = setTimeout(() => {
          this.logout();
        }, this.logOutWaitingtime);
      }
    }
  }

  logout() {
    clearTimeout(this.triggerTimeout);
    this.triggerTimeout = null;
    this.stopTimer();
    this.resetOnTrigger = false;
    let UserName = this.localization.GetUserInfo("userName");
    if (
      UserName != null &&
      UserName != undefined &&
      UserName != "null" &&
      UserName != "undefined"
    ) {
      this.ServerLogOut(UserName);
    }
    this.UpdateSession();
    if(sessionStorage.getItem('supportUserMailId')){
      this.router.navigate(["supportlogin"]);
    } else {
      this.router.navigate(["login"]);
    }
    
    this.enableAlert = false;
    this._timeoutSeconds = 0;
    this.manageSessionService.logout();
  }
  ServerLogOut(userName) {
    this.tenantId = this.localization.GetPropertyInfo("TenantId");
    let serviceParams = {
      route: GolfRoutes.LogOut,
      uriParams: {
        Username: userName,
        TenantId: this.tenantId,
        PropertyId: Number(this.localization.GetPropertyInfo("PropertyId")),
      },
      header: "",
      body: "",
      showError: false,
      baseResponse: true,
    };
    this.loginService.makePostCall(serviceParams);
  }
  UpdateSession() {
    let sessionData = {
      isActive: false,
      endTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
    };

    const sessionId: string = sessionStorage.getItem(this.userSessionId);
    if (!sessionId) {
      return;
    }
    let serviceParams = {
      route: GolfRoutes.UpdateSession,
      uriParams: { sessionId: sessionId },
      header: "",
      body: sessionData,
      showError: false,
      baseResponse: true,
    };
    this.loginService.makePutCall(serviceParams);
  }

  UpdateQuickIdSession() {
    let sessionData = {
      isActive: false,
      endTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
    };

    const sessionId: string = sessionStorage.getItem("quickIdUserSession");
    if (!sessionId) {
      return;
    }
    let serviceParams = {
      route: GolfRoutes.UpdateSession,
      uriParams: { sessionId: sessionId },
      header: "",
      body: sessionData,
      showError: false,
      baseResponse: true,
    };
    this.loginService.makePutCall(serviceParams);
    sessionStorage.removeItem("quickIdUserSession");
    sessionStorage.removeItem("quickIdJwt");
    sessionStorage.removeItem("quickIdUser");
  }
  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    if (this.tokenTimerSubscription) {
      this.tokenTimerSubscription.unsubscribe();
    }
  }
  private IdealtimerComplete() {
    let dialogref;
    if(!this.isIdealDialogOpen) {
      this.isIdealDialogOpen = true;
      this.resetOnTrigger = false;
      dialogref = this.dialog.open(AlertPopupWithTimerComponent, {
      height: 'auto',
      width: 'auto',
      panelClass: 'small-popup',
      disableClose: true,
    });
    }
    
  dialogref.afterClosed().subscribe(res => {
    this.isIdealDialogOpen = false;
    clearTimeout(this.autoLogoffTimer);
      if (res.from == 'logout') {
        this.timeoutExpired.next(++this._count);
        this.stopTimer();
        sessionStorage.setItem('popupEnabled','true');
        this.logout();
      } else {
        this.resetOnTrigger = true;
      }
  
  });
  dialogref.afterOpened().subscribe(_ => {
    this.isIdealDialogOpen = false;
    this.autoLogoffTimer = setTimeout(() => {
      this.autoLogOfftimerService.buttonDisabled.next(true);
      this.timeoutExpired.next(++this._count);
      this.stopTimer();
      sessionStorage.setItem('popupEnabled','true');
      this.logout();
    }, this.logOutWaitingtime)
  })
  }
  getTimer(){
    return this.subject.asObservable();
  }
}
