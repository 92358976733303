import { Injectable } from '@angular/core';
import { GolfManagementCommunication } from '../../communication/services/golfmanagement.service';
import {  UserConfiguration, UserCourseConfiguration } from '../../models/tenant.models';

@Injectable({ providedIn: 'root' })
export class GolfUserConfigDataService {

    constructor(private _http: GolfManagementCommunication) {
                    
    }

    public async getUserConfig(userId: number): Promise<UserConfiguration> {
        return await this._http.getPromise({
            route: GolfApiRoute.GetGolfUserConfig,
            uriParams: { id: userId }
        });
    }

    public async createUserConfig(userId: number, userConfig: UserConfiguration) {
        return await this._http.postPromise({
            route: GolfApiRoute.CreateGolfUserConfig,
            uriParams: { id: userId },
            body: userConfig
        });
    }

    public async updateUserConfig(userId: number, userConfig: UserConfiguration) {
        return await this._http.putPromise({
            route: GolfApiRoute.UpdateGolfUserConfig,
            uriParams: { id: userId },
            body: userConfig
        });
    }

    public async getUserCourses(userID:Number): Promise<UserCourseConfiguration[]> {
 
        return this._http.getPromise<UserCourseConfiguration[]>({
          route: GolfApiRoute.getAllUserCourses
          , uriParams: { userId: userID + '' }
        });      
      }

}