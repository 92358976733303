import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { cloneDeep } from 'lodash';
import { of, ReplaySubject, Subscription } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { PropertyService } from 'src/app/common/services/property.service';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { GolfLocalization } from 'src/app/core/localization/golf-localization';
import { UserdefaultsInformationService } from 'src/app/core/services/userdefaults-information.service';
import { HeaderService } from 'src/app/layout/header/header.service';
import { ExpireSessionService } from 'src/app/login/expire-session.service';
import { ManageSessionService } from 'src/app/login/manage-session.service';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { RetailServiceRegistry } from 'src/app/retail/shared/service/base.service';
import { CommonVariablesService } from 'src/app/retail/shared/service/common-variables.service';
import { ConfigKeys, FeatureName, RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { PropertyFeaturesConfigurationService } from 'src/app/retail/sytem-config/payment-features-config/property-feature-config.service';
import { SortOrderPipe } from 'src/app/shared/pipes/sort-order.pipe';
import { ButtonType, menuTypes } from 'src/app/shared/shared-models';
import { DocumentationService } from '../../communication/services/documentation.service';
import { SettingModule, SettingScreen } from '../../global.constant';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { NotificationFailureType } from './menu.model';
import {TooltipPosition} from '@angular/material/tooltip';
import { UntypedFormControl } from '@angular/forms';
import { ChangePropertyComponent } from 'src/app/common/components/change-property/change-property.component';
import { ChangePropertySevice } from 'src/app/common/services/change-property.service';
import { UserMachineConfigurationService } from 'src/app/retail/common/services/user-machine-configuration.service';
import { AuthenticationService } from 'src/app/common/shared/services/authentication.service';
import { environment } from 'src/environments/environment';
import { PropertySettingDataService } from 'src/app/common/dataservices/authentication/propertysetting.data.service';
import { JasperServerCommonDataService } from 'src/app/common/dataservices/jasperServerCommon.data.service';
import { JWT_TOKEN } from 'src/app/app-constants';
import { Product } from 'src/app/common/enums/shared-enums';
import { DMConfigDataService } from 'src/app/common/dataservices/datamagine-config.data.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [HeaderService, DocumentationService]
})
export class MenuComponent implements OnInit, AfterViewInit, OnDestroy {
  menuList: any;
  menuItems: any;
  isMenu: boolean;
  positionOptions: TooltipPosition[] = ['right', 'above', 'left', 'below'];
  position = new UntypedFormControl(this.positionOptions[0]);
  moreTextName = '';
  moreListItem: any = [];
  isSubMenu = false;
  searchOpen = false;
  menusearchOpen = false;
  headerPopOver: any;
  logOutPopOver: any;
  lowerLevelSubMenu: any;
  verticalFlag = false;
  levelMenu: menuTypes;
  menuType = menuTypes;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  leftArrowDisabled = false;
  rightArrowDisabled = false;
  widthPerTab: number;
  actualWidth = 0;
  showIcon = false;
  isEatecEnabled: boolean;
  memberConfig :any;
  loyaltyUrl:string;
  isChangePropertyEnabled: boolean;
  showJasperSoftServerMenu: boolean = false;
  openJaspersoftServer: string = "";

  @ViewChild('userPopOver') userPopUp: ElementRef;
  @ViewChild('logOutPopOver') logPopOver: ElementRef;
  @ViewChild('navBar') navBar: ElementRef;
  @ViewChild('RouterOutlet') outlet: RouterOutlet;
  @ViewChild('navView') navBarRef: any;
  @ViewChild('notificationPopOver') notificationPopOver;

  selectedItem: any;
  userName: string;
  userText: string; // change after login creation
  userRole: string;
  firstName: string;
  lastName: string;
  sortPipe: SortOrderPipe;
  captions: any;
  transactionCountSubscription: Subscription;
  notificationCount: number = 0;
  notificationInfo: {id: number , message: string }[] = [];
  commonCaptions: any;

  @Input('menu')
  set MenuValue(value) {
    this.menuList = value;
    this.levelMenu = value.menuType;
    this.menuList.menu = this.sortPipe.transform(this.menuList.menu, 'order', 'aesc');
     }

  constructor(public router: Router
    , private _localization: GolfLocalization
    , private activeRoute: ActivatedRoute
    , private _HeaderService: HeaderService
    , public dialog: MatDialog
    , private retailService: RetailSharedVariableService
    , private userdefaultsInformationService: UserdefaultsInformationService
    , private _expireSessionService: ExpireSessionService
    , private _featureFlagService: RetailFeatureFlagInformationService
    , private _propertyFeatureService: PropertyFeaturesConfigurationService
    , private _propertyInfo: RetailPropertyInformation
    , private elementRef: ElementRef
    , private _propConfig: PropertyService
    , private retailServiceRegistry: RetailServiceRegistry
    ,private manageSessionService: ManageSessionService
    , public _shopservice: CommonVariablesService,public quickLoginUtils: QuickLoginUtilities
    , private propertyService: PropertyService
    , private changePropertySevice: ChangePropertySevice
    , private userSessionConfig: UserMachineConfigurationService
    , private authentication: AuthenticationService
    ,private PropertySettingService: PropertySettingDataService
    , private jasperServerCommon : JasperServerCommonDataService
    , private _dmConfigDataService: DMConfigDataService
  ) {
    this.sortPipe = new SortOrderPipe();
  }
  async propConfig() {
    let propertyInfo = sessionStorage.getItem('propertyInfo');
    const propertyId = Number(propertyInfo.split(';').map(x => ({ key: x.split('=')[0].trim(), value: x.split('=')[1] })).find(x => x.key === 'PropertyId').value);
    await this._propConfig.SetPropertyApiConfiguration(propertyId);
  }
  ngOnInit() {
    this.captions = this._HeaderService.getCaptions();    
    this.commonCaptions = this._localization.captions;
    this.userName = this._localization.GetUserInfo("userName");
    this.firstName = this._localization.GetUserInfo("firstName");
    this.lastName = this._localization.GetUserInfo("lastName");
    this.userRole = this._localization.GetUserInfo("roleName");  
    this.moreTextName = this.commonCaptions.lbl_more;
    this.openJaspersoftServer = this.commonCaptions.common.lbl_OpenJasperSoft;

    this.handleShowAndHideJasperSoftStudioMenu();
    this.transactionCountSubscription = this.manageSessionService.transactionCount.subscribe(res => {
      const revenueresult = res && res.find(x => x.id === NotificationFailureType.revenuePostingFailure) ;
      const paymentresult = res && res.find(x => x.id === NotificationFailureType.paymentTransactionFailure) ;
      const cgpsLogResult = res && res.find(x => x.id === NotificationFailureType.cgpsLog);
      if(cgpsLogResult){
        if(this.notificationInfo && this.notificationInfo.length && !(this.notificationInfo.some(x => x.id === NotificationFailureType.cgpsLog))){
          this.notificationInfo.push({
            id: NotificationFailureType.cgpsLog,
            message: cgpsLogResult.message
          });
        }
        else{
          this.notificationInfo.push({
            id: NotificationFailureType.cgpsLog,
            message: cgpsLogResult.message
          });
        }
      }
      const dMPostingResult = res && res.find(x => x.id == NotificationFailureType.dMPostingFailure);
      if (revenueresult && revenueresult.count > 0) {
        if (this.notificationInfo && this.notificationInfo.length > 0 &&
           this.notificationInfo.some(x => x.id === NotificationFailureType.revenuePostingFailure )) {
            this.notificationInfo.find(x => x.id === NotificationFailureType.revenuePostingFailure ).message =
            this._localization.replacePlaceholders(this.commonCaptions.RevenuePostingInfo, ['count'], [ revenueresult.count]);
          }
        else {
          this.notificationInfo.push({
            id :  NotificationFailureType.revenuePostingFailure,
            message : this._localization.replacePlaceholders(this.commonCaptions.RevenuePostingInfo, ['count'], [ revenueresult.count])
          });
        }
      }
      if (paymentresult && paymentresult.count > 0) {
        if (this.notificationInfo && this.notificationInfo.length > 0 &&
          this.notificationInfo.some(x => x.id === NotificationFailureType.paymentTransactionFailure )) {
           this.notificationInfo.find(x => x.id === NotificationFailureType.paymentTransactionFailure ).message =
           this._localization.replacePlaceholders(this.commonCaptions.FailedTransLogInfo, ['count'], [ paymentresult.count]);
         }
       else {
         this.notificationInfo.push({
           id :  NotificationFailureType.paymentTransactionFailure,
           message :  this._localization.replacePlaceholders(this.commonCaptions.FailedTransLogInfo, ['count'], [ paymentresult.count])
         });
       }
      }
      if (dMPostingResult && dMPostingResult.count > 0) {
        if (this.notificationInfo && this.notificationInfo.length > 0 &&
           this.notificationInfo.some(x => x.id === NotificationFailureType.dMPostingFailure )) {
            this.notificationInfo.find(x => x.id === NotificationFailureType.dMPostingFailure ).message =
            this._localization.replacePlaceholders(this.commonCaptions.DMPostingInfo, ['count'], [ dMPostingResult.count]);
          } else {
          this.notificationInfo.push({
            id :  NotificationFailureType.dMPostingFailure,
            message : this._localization.replacePlaceholders(this.commonCaptions.DMPostingInfo, ['count'], [ dMPostingResult.count])
          });
        }
      }
      this.notificationCount = this.notificationInfo.length;
    });
    this.quickLoginUtils.resetQuickIdDetails();    
    this.propConfig();
   
    if(!this._expireSessionService.tokenTimerSubscription)
    {
      this._expireSessionService.forceLogOff();
    }   
    if(!sessionStorage.getItem("QuickIdConfig"))
    {
      this._propertyFeatureService.SetQuickIdConfigSettingForGolf(SettingModule.Utilities,SettingScreen.QuickIdConfigSetting); 
    }
    this.isChangePropertyEnabled = this.propertyService.isChangePropertyEnabled();
    this._expireSessionService.UpdateQuickIdSession();
    let outlet = this.userdefaultsInformationService.GetDefaultOutlet()
    if (this.firstName == "undefined" || this.lastName == "undefined" || this.firstName == undefined || this.lastName == undefined) {
      this.userText = this.userName ? this.userName.charAt(0).toUpperCase() : '';
    }
    else {
      this.userText = this.firstName.charAt(0).toUpperCase() + this.lastName.charAt(0).toUpperCase();
    }
    if (this.levelMenu === menuTypes.tertiary) {
      this.selectedItem = this.menuList.menu.find(x => this.router.url.indexOf(x.routePath) > -1);
      this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(x => {
        this.selectedItem = this.menuList.menu.find(menu => this.router.url.indexOf(menu.routePath) > -1);
        this.selectedItem = { ...this.selectedItem };
      });
    }

    this.RefreshConfig();
    this._dmConfigDataService.SetDataMagineConfig();
    if(!sessionStorage.getItem("enableMachineTransaction")) {
      this._propertyFeatureService.GetMiscConfig();
    }



    if (outlet && outlet.id) {
      if (!this.retailService.isTeeTimeAsPaid) {
        this._shopservice.SelectedOutletId = this.retailService.SelectedOutletId = Number(outlet.id);
      }
      if (this._propertyInfo.UseRetailInterface && this.userdefaultsInformationService.DefaultUserConfig        
        && !this.retailService.isTeeTimeAsPaid && !this.retailService.isPromptedForTerminalSelect) {
        this.retailService.SelectedTerminalId = this.userdefaultsInformationService.DefaultUserConfig.defaultTerminalId;
      }
    }    
    if (!sessionStorage.getItem("giftCardConfiguration")) {
      this._propertyFeatureService.GetGiftCardConfiguration().then((config) => {
        this._propertyInfo.SetGiftCardConfiguration(config);
      });
    }
    this._featureFlagService.RefreshConfig();
     
    this.changePropertySevice.roleName$.pipe(takeUntil(this.destroyed$)).subscribe(roleName => {
      if (roleName) {
        this.userRole = roleName;
      }
    });

    // On Property Change 
    this.changePropertySevice.propertyChanged$.pipe(takeUntil(this.destroyed$)).subscribe(propertyChanged => {
      if (propertyChanged) {
        console.log(propertyChanged);
        this.userRole = this._localization.GetUserInfo("roleName");
        this._featureFlagService.reset();
        this.RefreshConfig(propertyChanged);
        this.userdefaultsInformationService.syncDefaultValues(Number(this._localization.GetUserInfo("userId")));
        if (!this._propertyInfo.UseRetailInterface) {
          this.notificationCount = 0;
          this.notificationInfo = [];
          this.manageSessionService.startTimerForNotification(1);
        }
        this.quickLoginUtils.resetQuickIdDetails(); 
        this._propertyFeatureService.SetQuickIdConfigSettingForRetail("QuickIdConfig"); 
        this._propertyFeatureService.GetMiscConfig();

        if (!sessionStorage.getItem("acesJwt")) {
            this.setAcesToken(); //For getting Member token 
        }
        this.changePropertySevice.UnSubscribePropertyChangedEvent();
      }
    });
    setTimeout(() => {
      if(document.getElementsByClassName("active-link")?.length >= 1){
        document.getElementsByClassName("active-link")[1]?.scrollIntoView({behavior: 'smooth'});
      }
    }, 1)
    
  } 

   RefreshConfig(isFromPropertyChangeEvent : boolean = false){
    if (!sessionStorage.getItem("giftCardConfiguration") || isFromPropertyChangeEvent) {
      this._propertyFeatureService.GetGiftCardConfiguration().then((config) => {
        this._propertyInfo.SetGiftCardConfiguration(config);
      });
    }
    this._featureFlagService.RefreshConfig();
  }
  compareSelect = (val1, val2) => {
    return val1 && val2 && val1.text === val2.text;
  }

  ngAfterViewInit() {
    if (this.levelMenu === menuTypes.primary) {
      setTimeout(() => {
        this.bindHeaderData();
      }, 1);
    }

    const navChildren = this.navBarRef && this.navBarRef._elementRef.nativeElement.querySelectorAll('.tab-view-list');
    if (navChildren) {
      this.widthPerTab = navChildren[0].offsetWidth;
      for (const navChild of navChildren) {
        this.actualWidth += navChild.offsetWidth;
      }
      of(null).pipe(
        delay(0),
        takeUntil(this.destroyed$))
        .subscribe(() => {
          this.toggleSwipeIcons();
        });
    }

  }



  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
    if (this.transactionCountSubscription) {
      this.transactionCountSubscription.unsubscribe();
    }
  }
  changeProperty(e) {
    const dialogRef = this.dialog.open(ChangePropertyComponent, {
      width: '30%',
      height: '35%',
      disableClose: true,
      data: {}
    });
    dialogRef.afterClosed().subscribe(() => {
      console.log('dialog closed');
    });
    this.logOutPopOver.hide();
    e.stopPropagation();
   
  }
  // Calculation for more options
  bindHeaderData() {
    const menuItem = cloneDeep(this.menuList.menu);
    let menuTobeShown = [];
    this.moreListItem = [];
    let headerWidth = 0;
    let moreCalc = false;
    const parentelementWidth = this.navBar.nativeElement.clientWidth;
    for (let i = 0; i < menuItem.length; i++) {
      const elementWidth = this.getTextWidth(menuItem[i].text, '100 14px LatoWeb');
      headerWidth += elementWidth + 66;
      if ((parentelementWidth - headerWidth) >= elementWidth) {
        moreCalc = false;
        menuTobeShown.push(menuItem[i]);
      } else {
        moreCalc = true;
        break;
      }
    }
    if (moreCalc) {
      menuTobeShown = [];
      headerWidth = 0;
      const moreWidth = this.getTextWidth(this.moreTextName, '100 14px LatoWeb');
      const LegendsWidth = parentelementWidth - (moreWidth + 66);
      for (let i = 0; i < menuItem.length; i++) {
        const elementWidth = this.getTextWidth(menuItem[i].text, '100 14px LatoWeb');
        headerWidth += elementWidth + 66;
        if ((LegendsWidth - headerWidth) >= elementWidth) {
          menuTobeShown.push(menuItem[i]);
        } else {
          this.moreListItem.push({ imgPath: menuItem[i].imgPath, text: menuItem[i].text, routePath: menuItem[i].routePath, visibility: menuItem[i].visibility });
        }
      }
    }
    this.menuItems = menuTobeShown;
    this.onResize();
  }

  // Text width
  getTextWidth(text, font) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text.toUpperCase());
    if (canvas) {
      canvas.remove();
    }
    return Math.ceil(metrics.width);
  }

  userPop(logOutPopOver) {
    this.logOutPopOver = logOutPopOver;
    const logOut = this.logOutPopOver.element.nativeElement;
    logOut.querySelectorAll('div')[0].style.display = 'none';
    setTimeout(() => {
      const popover = logOut.querySelectorAll('div');
      const leftValue = popover[0].style.left.split('px');
      popover[0].style.left = (Number(leftValue[0])) + 'px';
      popover[0].style.top = 60 + 'px';
      popover[0].style.display = 'block';
    }, 100);
  }

  onResize() {
    // For closing the more pop over
    if (this.headerPopOver) {
      this.headerPopOver.hide();
    }

    // For closing the logout pop over
    if (this.logOutPopOver) {
      this.logOutPopOver.hide();
    }
  }

  openPopUp(popOver) {
    this.headerPopOver = popOver;
  }

  logout() {
    window.localStorage.setItem('logout-event', JSON.stringify({jwt:sessionStorage.getItem(JWT_TOKEN),Product:Product.GOLF,propertyInfo:this._propertyInfo.GetPropertyInfoByKey('PropertyId')}))
    this.logoutEatec();   
    this.logoutAces(); 
    this.retailService.SelectedOutletId = 0;
    this._expireSessionService.logout();
    this._HeaderService.removeHelpSession();
    this._featureFlagService.reset();
    this.retailServiceRegistry.resetAllServiceData();
  }

  logoutEatec() {

    let eatecUrl = this._propertyInfo.getEatecURI;
    const url = 'sso/logout';

    const eatecToken = sessionStorage.getItem('eatecJwt');

    if (!eatecUrl || !eatecToken) {
      return '';
    }

    if (!eatecUrl.endsWith('/')) {
      eatecUrl += '/';
    }

    eatecUrl = `${eatecUrl}${url}`;

    const doc = this.elementRef.nativeElement.offsetParent;
    const eatecIframe = doc.querySelector('#eatec-iframe');

    if (eatecIframe) {
      doc.removeChild(eatecIframe);
    }

    doc.insertAdjacentHTML('beforeend', '<iframe id="eatec-iframe" style="display:none" src="' + eatecUrl + '"></iframe>');
  }

  logoutAces() {

    const engageAccessToken = 'MemberToken';
    this.memberConfig = this._propertyInfo.getMemberConfig;
    this.loyaltyUrl = this.memberConfig ? this.memberConfig.loyaltyURI : "";
    const url = 'ExternalLogout';

    const memberToken = sessionStorage.getItem(engageAccessToken);

    if (!this.loyaltyUrl || !memberToken) {
      return '';
    }

    if (!this.loyaltyUrl.endsWith('/')) {
      this.loyaltyUrl += '/';
    }

    this.loyaltyUrl = `${this.loyaltyUrl}${url}`;

    const doc = this.elementRef.nativeElement.offsetParent;
    const acesIframe = doc.querySelector('#aces-iframe');

    if (acesIframe) {
      doc.removeChild(acesIframe);
    }

    doc.insertAdjacentHTML('beforeend', '<iframe id="aces-iframe" style="display:none" src="' + this.loyaltyUrl + '"></iframe>');
  }
  

  openGlobalSearch(e, type) {
    this.searchOpen = !this.searchOpen;
    this.menusearchOpen = false;
  }

  openMenuSearch() {
    this.menusearchOpen = !this.menusearchOpen;
    this.searchOpen = false;
  }

  OptionSelected(event) {
    this.searchOpen = false;
  }

  MenuOptionSelected(event) {
    this.menusearchOpen = false;
  }

  navigateTo(option) {
    if (option.value.routePath === '/settings/interfaces/admIntegration' || option.value.routePath === '/settings/interfaces/dataMagine' ) {
      this.router.navigate([option.value.routePath], { state: { ShowPopup: true, onSubmoduleChange: true } });
    } else {
      this.router.navigate([option.value.routePath], { state: { ShowPopup: !option.value.linkedElement?.length, onSubmoduleChange: true } });
    }
    
  }



  //  help about

  async openIcon() {

    let hostUrl = this._HeaderService.getDocumentationRoute();
    let help_hosturl = hostUrl + '/golf/';
    let help_page = 'AgilysysGolf_Home.htm';
    let appver = sessionStorage.getItem('productVersion');
    let dotRegEx = /\./gi;
    let productVersion = appver && appver != 'null' ? appver : '12.2';
    let _applicationVersion = productVersion.replace(dotRegEx, '_');
    let url = help_hosturl + _applicationVersion + '/' + help_page;
    let jwt = sessionStorage.getItem('_jwt');
    const session = sessionStorage.getItem('userSession');
    let isAuthorized = await this._HeaderService.createHelpSession();
    if (isAuthorized && jwt) {
      url = url + '?doc_id=' + session;
      setTimeout(() => { window.open(url, '_blank') }, 1000)
    }
  }

  openAboutDialog() {
    
    const dialogRef = this.dialog.open(AlertPopupComponent, {
      height: 'auto',
      width: '300px',
      data: { type: '', message: '', buttontype: ButtonType.Ok, inlineMsg: true },
      panelClass: 'small-popup',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe();
  }

  viewPrevMenu() {
    this.rightArrowDisabled = false;
    const element = this.navBarRef._elementRef.nativeElement;
    const scrollValue = element.scrollLeft - this.widthPerTab;
    element.scrollLeft = scrollValue;
    if (scrollValue <= 0) {
      this.leftArrowDisabled = true;
    }
  }

  viewNextMenu() {
    this.leftArrowDisabled = false;
    this.rightArrowDisabled = true;
    const element = this.navBarRef._elementRef.nativeElement;
    const scrollValue = element.scrollLeft + this.widthPerTab;
    const scrollWidth = element.scrollWidth;
    const offsetWidth = element.offsetWidth;
    element.scrollLeft = scrollValue;
    if ((scrollWidth - (scrollValue + offsetWidth)) > 0) {
      this.rightArrowDisabled = false;
    }
  }

  private toggleSwipeIcons(): void {
    this.showIcon = this.actualWidth > window.innerWidth;
  }

  removeRevenuePostInfo(){
    this.notificationInfo = this.notificationInfo?.filter(x => x.id !== NotificationFailureType.revenuePostingFailure);
  }

  removePaymentFailureInfo(){
    this.notificationInfo = this.notificationInfo?.filter(x => x.id !== NotificationFailureType.paymentTransactionFailure);
  }
  removeCgpsFailureInfo(){
    this.notificationInfo = this.notificationInfo?.filter(x => x.id !== NotificationFailureType.cgpsLog);
  }

  removeDMReceiptLogInfo(){
    this.notificationInfo = this.notificationInfo?.filter(x => x.id !== NotificationFailureType.dMPostingFailure);
  }

  routeTransc(id: number) {
    if (id === NotificationFailureType.revenuePostingFailure) {
      this.router.navigate(['/shop/viewshop/retailtransactions/revenuepostingslog']);
      this.removeRevenuePostInfo();
      this.notificationCount = this.notificationInfo?.length;
    }
    else if (id === NotificationFailureType.paymentTransactionFailure){
      this.router.navigate(['/shop/viewshop/retailtransactions/transactionslog']);
      this.removePaymentFailureInfo();
      this.notificationCount = this.notificationInfo?.length;
    }
    else if (id === NotificationFailureType.cgpsLog){
      this.router.navigate(['/settings/utilities/cgpsFailedProfile']);
      this.removeCgpsFailureInfo();
      this.notificationCount = this.notificationInfo?.length;
    }
    else if (id === NotificationFailureType.dMPostingFailure){
      this.router.navigate(['/shop/viewshop/retailtransactions/datamaginereceiptlog']);
      this.removeDMReceiptLogInfo();
      this.notificationCount = this.notificationInfo?.length;
    }
    this.notificationPopOver.hide();
  }

  handleShowAndHideJasperSoftStudioMenu() {
    let sessionValueofJasperStudio = sessionStorage.getItem('showJasperSoftServerMenu');
    if (sessionValueofJasperStudio != null) {
      this.showJasperSoftServerMenu = sessionValueofJasperStudio == 'true';
    } else {
      this.showJasperSoftServerMenu = true;
    }
  } 

  async openJasperSoftServerLink() {
    const [userattributeupdate,jasperServerURL,headers] = await  Promise.all([
       this.PropertySettingService.UpdateRoleAndAttributeToUser(),
       this.jasperServerCommon.GetJasperServerBaseURL(), 
       this.jasperServerCommon.GetJasperServerHeader()
    ]);
    let xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        var url = jasperServerURL + "/flow.html?_flowId=homeFlow";
        let jaspersoftNavigationUri = url;
        window.open(jaspersoftNavigationUri, "_blank");
      }
    });
    let data = "";
    xhr.open("GET", jasperServerURL + "/rest_v2/serverInfo");
    Object.keys(headers).forEach(key => {
      if (headers[key] !== null) {
          xhr.setRequestHeader(key, headers[key]);
      }
     });
    xhr.send(data);
  }
  async setAcesToken() {
    try {
      const token = await this.authentication.getEngageToken();
      sessionStorage.setItem('acesJwt', token.result);
    } catch (ex) {
      console.log(ex);
    }
  }
    notificationAlert()
    {
      this._localization.notification$.next(true)
    }

}
